export const embedds = [
    [
        0.05104617029428482,
        0.048775751143693924,
        0.03249157965183258,
        -0.013504384085536003,
        0.016221188008785248,
        0.04222135618329048,
        0.05355137586593628,
        -0.05473363772034645,
        -0.011851751245558262,
        0.041121065616607666,
        -0.02036631479859352,
        0.054447147995233536,
        -0.0541628934442997,
        -0.05403895676136017,
        -0.054389722645282745,
        -0.054338909685611725,
        0.01729871705174446,
        0.053675420582294464,
        0.04882054030895233,
        -0.05101742967963219,
        0.04807450622320175,
        0.05085643380880356,
        0.05471859499812126,
        -0.03460700064897537,
        0.01704278402030468,
        -0.029018176719546318,
        -0.0046613579615950584,
        0.05083734914660454,
        -0.04011191800236702,
        0.027841467410326004,
        -0.030007818713784218,
        -0.05472884327173233,
        0.04003763198852539,
        -0.05446740984916687,
        0.040230873972177505,
        -0.05456613376736641,
        -0.054379332810640335,
        -0.04932134598493576,
        0.05473949760198593,
        -0.05273064970970154,
        0.04927739128470421,
        0.05332590639591217,
        0.05122314393520355,
        0.04548283666372299,
        0.02324850857257843,
        0.04124057665467262,
        0.04195830225944519,
        0.054669447243213654,
        0.054653629660606384,
        -0.053280726075172424,
        0.053392715752124786,
        0.05410077050328255,
        -0.010101381689310074,
        0.028390849009156227,
        -0.054570186883211136,
        0.016199225559830666,
        -0.0543123260140419,
        0.0463193878531456,
        -0.053077422082424164,
        -0.05275433883070946,
        -0.05367378145456314,
        0.04117352515459061,
        -0.05456303060054779,
        -0.04927650839090347,
        0.05389044061303139,
        -0.039279527962207794,
        -0.050574515014886856,
        -0.05368209257721901,
        0.019391940906643867,
        -0.03136022016406059,
        0.048786360770463943,
        -0.05396732687950134,
        -0.040968816727399826,
        -0.023789118975400925,
        0.05459382012486458,
        -0.05120394751429558,
        0.05342884361743927,
        0.05474042519927025,
        0.052154723554849625,
        0.04482395201921463,
        -0.04929821938276291,
        -0.04690936952829361,
        -0.05453536659479141,
        0.04271518066525459,
        0.0011513878125697374,
        0.038341864943504333,
        -0.04129153862595558,
        -0.0543469674885273,
        -0.04474838078022003,
        0.021347343921661377,
        -0.05467916652560234,
        -0.047074317932128906,
        -0.015943137928843498,
        -0.04339143633842468,
        -0.03218245133757591,
        0.05158219859004021,
        -0.05052117630839348,
        -0.05089184641838074,
        0.007874451577663422,
        -0.014532268047332764,
        -0.05470868572592735,
        0.0538121834397316,
        0.05096066743135452,
        0.05461620166897774,
        -0.05164307728409767,
        0.047202251851558685,
        0.05361541360616684,
        0.018516814336180687,
        -0.03813080117106438,
        -0.04510454088449478,
        -0.05173404887318611,
        -0.036387842148542404,
        -0.053109701722860336,
        -0.050872690975666046,
        0.02404986135661602,
        0.014988589100539684,
        -0.052680354565382004,
        -0.04039578512310982,
        0.05464043468236923,
        -0.012693693861365318,
        -0.04657705873250961,
        0.01913975551724434,
        0.018448688089847565,
        -0.04764622077345848,
        -0.029315421357750893,
        0.007068856153637171,
        -0.04625923931598663,
        0.03292044624686241,
        0.05471803620457649,
        0.050082068890333176,
        -0.035305071622133255,
        -0.04264136776328087,
        0.009672238491475582,
        0.05468817427754402,
        -0.04778748005628586,
        -0.05436775088310242,
        0.05465535819530487,
        0.048171516507864,
        -0.050493329763412476,
        0.043534476310014725,
        -0.054582927376031876,
        -0.0364183746278286,
        0.04884994029998779,
        0.01838776096701622,
        0.036952730268239975,
        -0.049409180879592896,
        0.03331036865711212,
        -0.04817787557840347,
        -0.05428103357553482,
        -0.05340138077735901,
        -0.010532891377806664,
        -0.05464179441332817,
        -0.0483991801738739,
        -0.049347858875989914,
        -0.013519044034183025,
        -0.05442897230386734,
        -0.05020981281995773,
        -0.05413954704999924,
        0.007668201811611652,
        0.039774589240550995,
        -0.03157937899231911,
        0.04195982962846756,
        0.05384994298219681,
        0.05420537292957306,
        -0.052561189979314804,
        -0.034870050847530365,
        0.05390429496765137,
        -0.05174224451184273,
        0.02917555347084999,
        0.010760859586298466,
        -0.04934227094054222,
        -0.012129121460020542,
        -0.050166647881269455,
        0.029597731307148933,
        0.04481814429163933,
        0.00899833906441927,
        0.05459507182240486,
        0.05204569175839424,
        0.053487420082092285,
        -0.048922426998615265,
        -0.054568443447351456,
        0.049832433462142944,
        0.01836414635181427,
        -0.052672129124403,
        -0.02727074921131134,
        0.05340350791811943,
        -0.007257287390530109,
        0.05390208959579468,
        -0.046831101179122925,
        -0.054696761071681976,
        -0.05367453768849373,
        -0.007224580273032188,
        0.052481137216091156,
        0.04333536699414253,
        -0.0362590029835701,
        -0.04603267088532448,
        0.0511358380317688,
        -0.053945142775774,
        -0.013254639692604542,
        0.02898554317653179,
        0.04720243066549301,
        -0.05471175163984299,
        -0.02201138436794281,
        -0.05462685972452164,
        0.04232582449913025,
        -0.0529700331389904,
        0.00987847987562418,
        0.054373763501644135,
        -0.05461479350924492,
        0.05458773672580719,
        -0.054540637880563736,
        0.048551470041275024,
        -0.017451820895075798,
        0.05466020107269287,
        -0.03765280544757843,
        -0.03714277595281601,
        -0.030352285131812096,
        -0.02696162462234497,
        0.05473623424768448,
        0.05469242483377457,
        0.0056520868092775345,
        0.03886878862977028,
        -0.023275531828403473,
        -0.03308438882231712,
        0.05405769497156143,
        -0.0542515404522419,
        0.0534270741045475,
        0.0020577465184032917,
        -0.020462244749069214,
        -0.05154746398329735,
        -0.05144624412059784,
        -0.050686970353126526,
        0.05454925820231438,
        0.0520140640437603,
        -0.052943646907806396,
        0.054057527333498,
        0.04804675281047821,
        -0.04779064282774925,
        0.053290821611881256,
        0.054736651480197906,
        -0.020235467702150345,
        0.0474126972258091,
        -0.044516634196043015,
        0.05413755029439926,
        -0.044929251074790955,
        -0.05416268855333328,
        -0.04741348698735237,
        0.019969480112195015,
        0.015306005254387856,
        -0.03681749105453491,
        -0.052848342806100845,
        -0.054105404764413834,
        -0.05053548142313957,
        -0.05354710668325424,
        0.050917141139507294,
        -0.015723515301942825,
        0.019964562729001045,
        -0.026925180107355118,
        0.04230864346027374,
        -0.01353321224451065,
        -0.05391956865787506,
        0.009759518317878246,
        -0.005395013373345137,
        0.05273814499378204,
        0.01930287480354309,
        0.05467682331800461,
        0.0546046644449234,
        0.034964751452207565,
        -0.00010790024680318311,
        -0.054661549627780914,
        -0.051645420491695404,
        -0.054404228925704956,
        0.05356186255812645,
        -0.051324956119060516,
        -0.042848773300647736,
        0.025688912719488144,
        -0.038008030503988266,
        0.053029946982860565,
        -0.04039883241057396,
        0.021309973672032356,
        -0.0540403388440609,
        0.054732631891965866,
        -0.0035636217799037695,
        -0.048994340002536774,
        -0.009889045730233192,
        0.043738726526498795,
        0.05002849921584129,
        0.053917866200208664,
        -0.05195200815796852,
        0.05316730588674545,
        -0.052796486765146255,
        0.05057559162378311,
        -0.042184654623270035,
        0.008871623314917088,
        0.054534658789634705,
        -0.05017548426985741,
        0.05383988469839096,
        -0.010866754688322544,
        -0.05075381323695183,
        0.052956677973270416,
        -0.052646931260824203,
        -0.04110042378306389,
        0.002782298717647791,
        0.052579522132873535,
        -0.05385131761431694,
        -0.00043216138146817684,
        0.04585311561822891,
        0.05474052578210831,
        -0.01917935721576214,
        0.03867507725954056,
        0.05454842746257782,
        0.03249587491154671,
        0.04546298831701279,
        0.052573882043361664,
        -0.03498280048370361,
        -0.05466267839074135,
        0.017130112275481224,
        0.0417715460062027,
        0.051909953355789185,
        0.0009982077172026038,
        -0.024707244709134102,
        -0.04714084789156914,
        -0.0029664505273103714,
        -0.018055075779557228,
        0.054655157029628754,
        -0.05242128297686577,
        0.04126662015914917,
        -0.052414581179618835,
        0.047543443739414215,
        0.005101117305457592,
        -0.016272397711873055,
        -0.026278739795088768,
        0.04885297268629074,
        -0.012760134413838387,
        0.04561245068907738,
        0.025562260299921036,
        0.011444897390902042,
        0.001602331642061472,
        -0.049257129430770874,
        0.05410721153020859,
        -0.038017790764570236,
        0.04979943856596947,
        -0.04743075370788574,
        -0.04330270364880562,
        -0.02561156451702118,
        0.03634321689605713,
        0.013671484775841236,
        0.0546473003923893,
        0.053833164274692535,
        0.03096316196024418,
        0.05473542585968971,
        -0.04779016226530075,
        0.00424637645483017,
        -0.052103325724601746,
        -0.05337487906217575,
        -0.016342751681804657,
        0.047136083245277405,
        -0.05358506366610527,
        0.046999286860227585,
        -0.05326986312866211,
        0.04501241073012352,
        0.04969362914562225,
        -0.053422290831804276,
        -0.05061480030417442,
        0.046902529895305634,
        -0.0507931113243103,
        0.05465260520577431,
        -0.05085644870996475,
        0.05218382179737091,
        -0.05164414644241333,
        -0.05152036249637604,
        -0.05354633182287216,
        0.026927242055535316,
        0.050899937748909,
        0.04278257489204407,
        0.0539877824485302,
        0.05457320809364319,
        -0.04610350355505943,
        0.05467366427183151,
        0.023290881887078285,
        -0.05096930265426636,
        0.046317700296640396,
        0.014220275916159153,
        0.044079750776290894,
        0.054640110582113266,
        -0.05284906178712845,
        0.05444909632205963,
        -0.030928364023566246,
        -0.05299213528633118,
        -0.05427504703402519,
        -0.054033394902944565,
        0.05038769543170929,
        -0.052479442209005356,
        -0.0546485111117363,
        0.05191716179251671,
        0.054547760635614395,
        0.04639449715614319,
        -0.0021072051022201777,
        -0.05469116196036339,
        -0.054360613226890564,
        -0.02257813699543476,
        0.054623477160930634,
        -0.05023997649550438,
        0.016469765454530716,
        -0.03256809338927269,
        -0.02647448517382145,
        0.04562050849199295,
        -0.0387897826731205,
        0.05435716360807419,
        0.011097644455730915,
        0.03243822976946831,
        -0.04052453115582466,
        -0.05337611213326454,
        0.04721219837665558,
        0.05019381269812584,
        -0.053469471633434296,
        0.04044920578598976,
        0.05206761509180069,
        0.02603314071893692,
        0.05385785922408104,
        -0.05109205096960068,
        -0.04576152190566063,
        0.011091820895671844,
        -0.054725319147109985,
        0.045139484107494354,
        -0.024736683815717697,
        0.03823816403746605,
        0.028498400002717972,
        -0.054472580552101135,
        -0.05251660943031311,
        0.05454862117767334,
        0.025532908737659454,
        0.0002390249283052981,
        -0.05466632544994354,
        0.02747398242354393,
        -0.053587935864925385,
        -0.054722268134355545,
        0.006240152753889561,
        0.018041564151644707,
        -0.005132175050675869,
        0.012028584256768227,
        0.043144673109054565,
        0.031435079872608185,
        0.05238350108265877,
        -0.05084054172039032,
        -0.01965092122554779,
        0.053481973707675934,
        -0.05449231341481209,
        0.05156559869647026,
        0.052668750286102295,
        -0.05313960462808609,
        0.03775337338447571,
        0.053092461079359055,
        0.05312134698033333,
        0.04875881224870682,
        -0.04788096994161606,
        0.027014687657356262,
        -0.054288968443870544,
        0.04494205117225647,
        0.05455755442380905,
        0.049874745309352875,
        0.03323553502559662,
        0.05091692879796028,
        -0.030027294531464577,
        0.054463133215904236,
        0.054596658796072006,
        -0.05454983934760094,
        0.05462417006492615,
        0.04688581824302673,
        -0.052782367914915085,
        0.05140508711338043,
        0.018328120931982994,
        0.05306978151202202,
        0.026878722012043,
        -0.0498938262462616,
        0.046858012676239014,
        -0.04577450454235077,
        -0.009346939623355865,
        -0.0341823510825634,
        -0.029943140223622322,
        0.03907465934753418,
        -0.04681575670838356,
        -0.024802586063742638,
        0.019897019490599632,
        -0.053096987307071686,
        0.050661686807870865,
        0.054077133536338806,
        0.044527120888233185,
        -0.03406871110200882,
        -0.04505399987101555,
        0.04388505592942238,
        0.000764884811360389,
        0.05453021824359894,
        -0.054472096264362335,
        -0.03797311335802078,
        0.05466698110103607,
        -0.053860969841480255,
        -0.05457168072462082,
        0.04910008981823921,
        0.02599591203033924,
        -0.029989756643772125,
        0.052979182451963425,
        -0.04026510566473007,
        -0.04730802774429321,
        0.05133393406867981,
        0.04448468238115311,
        0.05424069985747337,
        -0.05084129050374031,
        0.049131203442811966,
        -0.05471310019493103,
        0.05187235772609711,
        -0.005069929175078869
    ],
    [
        0.05213351920247078,
        -0.04237433895468712,
        -0.016330279409885406,
        0.05148262903094292,
        -0.05031991004943848,
        -0.0511450469493866,
        -0.04274041950702667,
        -0.05210142210125923,
        0.0521191768348217,
        -0.05222195386886597,
        0.05094774067401886,
        0.05216717720031738,
        -0.05203681066632271,
        0.05123209208250046,
        -0.052184224128723145,
        0.05020887032151222,
        0.047622326761484146,
        0.050787728279829025,
        0.02565133199095726,
        -0.04616895690560341,
        0.052218206226825714,
        -0.005070236977189779,
        0.05125394091010094,
        -0.0007274487870745361,
        0.0003486438363324851,
        0.05172663927078247,
        0.03919799253344536,
        0.04679744318127632,
        -0.04492231458425522,
        0.02987351268529892,
        0.04801475629210472,
        0.05214466527104378,
        -0.019363142549991608,
        -0.051756735891103745,
        0.05164461210370064,
        -0.05134909972548485,
        -0.05207720398902893,
        0.02344912849366665,
        0.04900989308953285,
        -0.025628158822655678,
        0.05209577456116676,
        0.009269828908145428,
        0.04644874855875969,
        -0.0230715312063694,
        0.04859033226966858,
        0.03901447728276253,
        -0.02685610018670559,
        0.04773911088705063,
        0.05222070589661598,
        -0.04129587486386299,
        0.05220640450716019,
        0.047635771334171295,
        0.02863573282957077,
        -0.051240917295217514,
        -0.022857168689370155,
        -0.03563535213470459,
        -0.019977610558271408,
        0.041164934635162354,
        -0.052207101136446,
        -0.050788916647434235,
        -0.05169985443353653,
        0.040300238877534866,
        -0.051767464727163315,
        0.041196804493665695,
        -0.022188585251569748,
        -0.05152776837348938,
        -0.05177474766969681,
        -0.03773941099643707,
        0.04331732168793678,
        0.008621372282505035,
        -0.050940822809934616,
        -0.05029036104679108,
        0.05136658251285553,
        0.04764685034751892,
        0.05114573985338211,
        -0.05021906644105911,
        -0.007402509450912476,
        0.052222996950149536,
        0.05222205072641373,
        0.007316417060792446,
        0.026862487196922302,
        -0.0520627424120903,
        -0.05158574879169464,
        0.05176089331507683,
        -0.04601915553212166,
        0.03695239499211311,
        -0.03662172704935074,
        0.04702238366007805,
        0.043471869081258774,
        0.05112659931182861,
        -0.0480225495994091,
        -0.02413800358772278,
        -0.05222129821777344,
        -0.05219917371869087,
        0.03440849110484123,
        0.045805271714925766,
        -0.024797651916742325,
        -0.052193038165569305,
        0.04510589316487312,
        0.04164838418364525,
        -0.05221733823418617,
        0.01779339648783207,
        0.03531867638230324,
        0.003903526347130537,
        0.038963980972766876,
        -0.03850998356938362,
        -0.052189335227012634,
        0.05191926285624504,
        -0.05198928713798523,
        0.05155247822403908,
        -0.05218517780303955,
        -0.048727139830589294,
        0.010199716314673424,
        0.044528257101774216,
        0.050674498081207275,
        -0.036127276718616486,
        0.051093850284814835,
        -0.051768708974123,
        -0.04707298055291176,
        -0.05216992646455765,
        -0.03499303758144379,
        -0.051778215914964676,
        0.04342800751328468,
        -0.05221089348196983,
        -0.05085959658026695,
        0.05208621174097061,
        -0.031854480504989624,
        0.04693444073200226,
        0.051458291709423065,
        0.043183259665966034,
        0.04597071185708046,
        -0.050031326711177826,
        -0.014814254827797413,
        0.013872431591153145,
        -0.052137866616249084,
        -0.05218721181154251,
        0.052223026752471924,
        0.05207443609833717,
        0.04479405656456947,
        0.04518071562051773,
        -0.050605472177267075,
        0.047787897288799286,
        0.0479603186249733,
        0.052212998270988464,
        -0.051004987210035324,
        -0.04871206358075142,
        0.04235544428229332,
        -0.006925500929355621,
        0.05222261697053909,
        0.05222288891673088,
        -0.05079590156674385,
        -0.05210862681269646,
        -0.04780232533812523,
        0.0335657000541687,
        0.02400491200387478,
        0.015192034654319286,
        -0.010076669044792652,
        -0.04761885851621628,
        0.027711594477295876,
        -0.047409094870090485,
        0.0484541654586792,
        0.013765535317361355,
        0.050969287753105164,
        0.047924090176820755,
        0.044564224779605865,
        -0.031726714223623276,
        0.05217839404940605,
        0.04710293561220169,
        -0.03984159231185913,
        0.05125647783279419,
        -0.001920041162520647,
        -0.034441012889146805,
        -0.052092909812927246,
        0.03903137892484665,
        -0.04623641073703766,
        0.03317639231681824,
        -0.05172622948884964,
        -0.029325636103749275,
        0.051969777792692184,
        -0.05091793090105057,
        -0.05184400454163551,
        0.05217825993895531,
        0.051397304981946945,
        -0.05212174728512764,
        0.017050737515091896,
        -0.050224948674440384,
        0.04996369034051895,
        0.05061918869614601,
        0.052132800221443176,
        -0.05196113884449005,
        -0.050750818103551865,
        0.015937427058815956,
        -0.04214131459593773,
        0.0405537374317646,
        -0.051806993782520294,
        0.05209748446941376,
        0.046781472861766815,
        0.040662866085767746,
        -0.022777801379561424,
        0.0507100373506546,
        0.05222155898809433,
        -0.05181049555540085,
        -0.04912719875574112,
        0.0014867869904264808,
        -0.039523135870695114,
        0.009437981061637402,
        0.051884427666664124,
        0.03133497014641762,
        -0.03045102022588253,
        0.05221470445394516,
        -0.05181232467293739,
        -0.01545635424554348,
        0.0012627028627321124,
        0.051722921431064606,
        -0.05218013375997543,
        -0.0032553430646657944,
        0.03706614300608635,
        -0.0043962448835372925,
        0.052185025066137314,
        -0.05202892795205116,
        0.04233522340655327,
        0.04380671679973602,
        -0.006070244591683149,
        -0.051203470677137375,
        0.04984721541404724,
        -0.051796771585941315,
        0.05187644436955452,
        -0.04499184712767601,
        -0.02395664155483246,
        -0.05171336606144905,
        -0.03392600640654564,
        -0.050344791263341904,
        -0.05054285004734993,
        0.04951715096831322,
        0.013408619910478592,
        0.05218543857336044,
        0.05189083144068718,
        0.04332400858402252,
        0.03887991979718208,
        0.05222222954034805,
        -0.05118560418486595,
        0.052212491631507874,
        0.03683137893676758,
        0.03501669317483902,
        0.05136001855134964,
        0.05043244734406471,
        0.026574527844786644,
        0.05173225328326225,
        0.014762531965970993,
        0.04709203541278839,
        -0.05166986957192421,
        -0.05157965049147606,
        0.04135531187057495,
        -0.05144704133272171,
        0.02990878000855446,
        0.050292789936065674,
        0.045083969831466675,
        -0.05197944492101669,
        0.04435719922184944,
        0.04499473422765732,
        -0.05164046213030815,
        -0.048440121114254,
        -0.05138534680008888,
        0.050817809998989105,
        -0.030169768258929253,
        0.05174791440367699,
        0.052149269729852676,
        -0.01644112356007099,
        -0.0464528426527977,
        -0.008364924229681492,
        0.051421187818050385,
        -0.052036937326192856,
        0.05212174728512764,
        -0.04435983672738075,
        -0.004624404013156891,
        0.05208795890212059,
        -0.05155869573354721,
        -0.04636956378817558,
        -0.05222318321466446,
        0.04065484181046486,
        -0.050482869148254395,
        0.05219088867306709,
        -0.050181467086076736,
        0.05207853019237518,
        0.05222318321466446,
        0.016582155600190163,
        0.00797593966126442,
        0.052211057394742966,
        0.05170513316988945,
        0.03525081276893616,
        -0.04324731603264809,
        -0.04829191416501999,
        -0.050478871911764145,
        -0.029119979590177536,
        0.02187899313867092,
        -0.05093841999769211,
        0.05221692472696304,
        0.05201178789138794,
        -0.047059230506420135,
        0.052135858684778214,
        0.04809500649571419,
        -0.0135731752961874,
        -0.04835628718137741,
        0.05208289995789528,
        0.039911411702632904,
        -0.034891411662101746,
        -0.04757154360413551,
        0.051930271089076996,
        -0.039133213460445404,
        -0.04750853031873703,
        -0.04341656714677811,
        -0.052030786871910095,
        0.05218227207660675,
        0.04616457596421242,
        0.02442954294383526,
        -0.051994968205690384,
        -0.04955942928791046,
        -0.05023135244846344,
        0.05066000670194626,
        0.010001394897699356,
        0.052184153348207474,
        -0.00934133492410183,
        0.04824709892272949,
        -0.04947574436664581,
        0.052221108227968216,
        0.002618044149130583,
        0.046843528747558594,
        -0.051601286977529526,
        -0.005904491990804672,
        -0.05213839188218117,
        0.05222230777144432,
        0.041514668613672256,
        -0.0446513295173645,
        -0.04957681894302368,
        0.049264512956142426,
        0.051569826900959015,
        -0.05142877623438835,
        0.05093121901154518,
        -0.021197432652115822,
        0.04191553220152855,
        -0.04926930367946625,
        -0.04873897135257721,
        0.05220673978328705,
        0.050103750079870224,
        0.04698023200035095,
        0.043377358466386795,
        0.0015345989959314466,
        0.050386376678943634,
        -0.05022867023944855,
        0.051290299743413925,
        0.052180249243974686,
        0.052108604460954666,
        0.03442615643143654,
        -0.05168644338846207,
        -0.04435715824365616,
        0.03339044004678726,
        -0.05222222954034805,
        -0.0494348481297493,
        -0.02868073433637619,
        -0.05192979797720909,
        0.051225628703832626,
        0.03774702176451683,
        -0.014400681480765343,
        -0.023315060883760452,
        -0.05218091979622841,
        -0.05219036340713501,
        0.0519355945289135,
        -0.05192401632666588,
        -0.04022224247455597,
        -0.0521080419421196,
        -0.048711299896240234,
        0.05119122564792633,
        0.052222587168216705,
        -0.0380994975566864,
        0.05091220885515213,
        0.05078255757689476,
        -0.05208524689078331,
        0.052158135920763016,
        0.052077364176511765,
        -0.048402249813079834,
        -0.03574473410844803,
        0.04580245539546013,
        -0.051364511251449585,
        0.05121496319770813,
        -0.018699243664741516,
        -0.04083242267370224,
        -0.0160150695592165,
        -0.05133235454559326,
        -0.051364120095968246,
        -0.02409851923584938,
        -0.051921553909778595,
        -0.0036451872438192368,
        -0.051818326115608215,
        -0.010151490569114685,
        0.05068039149045944,
        0.05085783451795578,
        0.05218207463622093,
        -0.05046787112951279,
        -0.0521855354309082,
        -0.048023588955402374,
        0.03897802159190178,
        0.05144350230693817,
        -0.04751645401120186,
        0.051277510821819305,
        0.00649263197556138,
        -0.03621490299701691,
        0.02161279320716858,
        -0.0510849691927433,
        0.05221647769212723,
        -0.018495025113224983,
        0.04446407034993172,
        -0.034197550266981125,
        -0.008540955372154713,
        -0.048321064561605453,
        0.0065494924783706665,
        -0.008063680492341518,
        0.015198711305856705,
        0.01913902536034584,
        0.04704437404870987,
        0.05171053484082222,
        0.052201710641384125,
        0.052219513803720474,
        -0.037508755922317505,
        -0.052220676094293594,
        -0.04746371507644653,
        -0.04795040190219879,
        -0.050634775310754776,
        -0.05221877992153168,
        -0.046013329178094864,
        -0.028734780848026276,
        0.04909210279583931,
        -0.04965827986598015,
        0.0315910242497921,
        0.0472288578748703,
        -0.05219487100839615,
        -0.04619108512997627,
        -0.05221765488386154,
        -0.002121508354321122,
        -0.015653390437364578,
        -0.048258472234010696,
        -0.05215725302696228,
        0.051288895308971405,
        0.02585429884493351,
        0.05060398951172829,
        -0.04395463690161705,
        -0.05003756657242775,
        0.01821342669427395,
        -0.04998593032360077,
        0.03976646438241005,
        0.05222034454345703,
        -0.05132848396897316,
        0.044029854238033295,
        0.025235239416360855,
        0.045468464493751526,
        0.05162763595581055,
        -0.049177076667547226,
        -0.04372645914554596,
        0.004544930532574654,
        0.04853401705622673,
        -0.005054391920566559,
        0.051721230149269104,
        -0.05213039368391037,
        -0.04880596697330475,
        0.05213230475783348,
        0.030034638941287994,
        0.05212706699967384,
        -0.05221441015601158,
        0.045949894934892654,
        -0.051624979823827744,
        -0.05179623141884804,
        0.052212342619895935,
        0.046791523694992065,
        -0.013233610428869724,
        0.01799686625599861,
        0.05217396467924118,
        -0.00042526956531219184,
        -0.04747940972447395,
        0.05141470953822136,
        -0.05113859474658966,
        -0.050682734698057175,
        -0.051878925412893295,
        0.040979914367198944,
        -0.050326064229011536,
        0.052107471972703934,
        0.020975330844521523,
        0.052212730050086975,
        0.049356069415807724,
        0.05003688484430313,
        -0.05082280561327934,
        -0.007823529653251171,
        -0.04167456924915314,
        -0.04969393461942673,
        -0.014526335522532463,
        0.05037293583154678,
        -0.05162513628602028,
        -0.05089898034930229,
        -0.05209708213806152,
        0.017186425626277924,
        0.052102819085121155,
        0.031192168593406677,
        -0.045596227049827576,
        0.04883135110139847,
        -0.050584737211465836,
        0.014544779434800148,
        0.035455506294965744,
        -0.012765444815158844,
        0.05204639211297035,
        -0.04184368997812271,
        0.05122527852654457,
        -0.052214816212654114,
        0.05219176784157753,
        0.04985446482896805
    ],
    [
        -0.012059849686920643,
        0.05164037272334099,
        -0.05336660146713257,
        0.014380776323378086,
        0.055091481655836105,
        -0.047454386949539185,
        0.05526226758956909,
        -0.05359143763780594,
        -0.04988263547420502,
        0.05501136928796768,
        0.0064055840484797955,
        0.05507325008511543,
        -0.054980114102363586,
        0.012534287758171558,
        -0.05193088576197624,
        -0.05364058539271355,
        -0.023438652977347374,
        -0.006715311203151941,
        0.0019485983066260815,
        -0.05498931184411049,
        -0.05352243036031723,
        0.05525331571698189,
        0.055138345807790756,
        0.008081302978098392,
        0.029852481558918953,
        0.0018703463720157743,
        0.00124564568977803,
        0.05499882996082306,
        -0.02666512504220009,
        -0.03399862349033356,
        0.049759186804294586,
        -0.054955389350652695,
        0.05344877764582634,
        -0.05521461367607117,
        0.005036159884184599,
        -0.052863772958517075,
        -0.05259683355689049,
        -0.02744428999722004,
        0.008597278967499733,
        -0.04560557380318642,
        0.0018600679468363523,
        0.03494337573647499,
        0.04758724570274353,
        -0.04951927438378334,
        0.05354524031281471,
        0.014920509420335293,
        0.053845152258872986,
        0.05527092143893242,
        0.05502244085073471,
        0.008874671533703804,
        0.008178518153727055,
        -0.04964222013950348,
        0.05507660657167435,
        -0.041517943143844604,
        -0.0489041768014431,
        0.05430424213409424,
        -0.05524947866797447,
        -0.046362970024347305,
        -0.0540633425116539,
        0.0128017608076334,
        -0.05342181399464607,
        -0.02538234367966652,
        -0.05204379931092262,
        0.006100452970713377,
        0.04799024760723114,
        0.05478827655315399,
        -0.01609506458044052,
        -0.05433335900306702,
        0.009137505665421486,
        -0.055271875113248825,
        -0.03141472116112709,
        -0.05527127906680107,
        0.054293714463710785,
        -0.05445347726345062,
        0.007351103238761425,
        0.0008427557768300176,
        0.05527855455875397,
        0.05527975410223007,
        -0.031164204701781273,
        -0.014744234271347523,
        -0.0449189729988575,
        0.013375749811530113,
        -0.05404486879706383,
        0.027211260050535202,
        0.05407879129052162,
        0.03678935021162033,
        0.01855560950934887,
        0.00028314359951764345,
        -0.05482277274131775,
        0.04520142823457718,
        -0.05527062341570854,
        0.02651512436568737,
        -0.017906835302710533,
        0.02749316580593586,
        0.03842748701572418,
        0.054321032017469406,
        -0.010105409659445286,
        -0.04996030777692795,
        -0.05425872281193733,
        0.0007261636783368886,
        -0.05484650656580925,
        0.04396047443151474,
        0.054137710481882095,
        0.05345330759882927,
        0.019062047824263573,
        0.005863999482244253,
        0.044423170387744904,
        0.05404199659824371,
        -0.04313191771507263,
        0.021885724738240242,
        -0.05373836308717728,
        -0.05001413822174072,
        -0.05068613961338997,
        0.018556227907538414,
        0.040591444820165634,
        0.034278418868780136,
        -0.05478845536708832,
        -0.05456535890698433,
        -0.05341371148824692,
        -0.02522769384086132,
        -0.046815864741802216,
        0.03274786099791527,
        -0.0552620068192482,
        -0.014567830599844456,
        -0.05425335094332695,
        -0.05496418848633766,
        -0.051020000129938126,
        0.019175978377461433,
        0.03327170014381409,
        0.04491110146045685,
        -0.054328471422195435,
        -0.020926790311932564,
        0.0476049929857254,
        0.05525638908147812,
        -0.04264845326542854,
        -0.0530209094285965,
        0.05506962165236473,
        -0.04453577101230621,
        -0.042734287679195404,
        0.050486478954553604,
        0.00005752360084443353,
        -0.0018771919421851635,
        -0.007725090719759464,
        -0.055280886590480804,
        -0.036256469786167145,
        0.03217027708888054,
        0.05034313350915909,
        -0.01656629703938961,
        0.04125712811946869,
        -0.03439486399292946,
        0.0033962742891162634,
        -0.043863218277692795,
        -0.038133811205625534,
        -0.05397810414433479,
        -0.05498989298939705,
        -0.04577861726284027,
        0.0549800805747509,
        -0.05383097752928734,
        0.026197103783488274,
        0.046693626791238785,
        0.05179286748170853,
        0.050243034958839417,
        0.047306858003139496,
        0.04735959321260452,
        -0.05526529252529144,
        -0.028324205428361893,
        0.047508999705314636,
        -0.053934164345264435,
        0.048159338533878326,
        0.053442638367414474,
        -0.05445871129631996,
        0.04940848425030708,
        -0.05479113385081291,
        0.049110617488622665,
        0.05288871005177498,
        0.052967824041843414,
        -0.010457655414938927,
        0.03241240233182907,
        0.05357730761170387,
        -0.011067419312894344,
        -0.03314429894089699,
        0.006298123858869076,
        0.053395118564367294,
        0.055174872279167175,
        -0.053086817264556885,
        0.03484061732888222,
        -0.05467154085636139,
        0.050620220601558685,
        -0.02442394755780697,
        -0.023380618542432785,
        -0.024945149198174477,
        -0.03652050718665123,
        0.028877412900328636,
        -0.04186509922146797,
        0.03655773773789406,
        -0.05185037851333618,
        0.0509382039308548,
        -0.054854702204465866,
        -0.054671697318553925,
        0.014524084515869617,
        0.006075120996683836,
        -0.051734402775764465,
        0.05487772077322006,
        0.03729366511106491,
        0.05460476130247116,
        -0.04941558837890625,
        -0.05096116662025452,
        0.04168706014752388,
        -0.0535135418176651,
        -0.05527528375387192,
        -0.051142387092113495,
        0.03835121542215347,
        0.03358641639351845,
        0.05088581144809723,
        0.03297489881515503,
        0.05492033809423447,
        0.05466791242361069,
        0.05306257680058479,
        0.054768405854701996,
        -0.03263780102133751,
        0.05444012209773064,
        0.036835793405771255,
        -0.05463511496782303,
        -0.05518871545791626,
        0.052196890115737915,
        -0.05055609717965126,
        0.04683302342891693,
        0.005335062742233276,
        0.04433141276240349,
        -0.027080127969384193,
        -0.03224281594157219,
        -0.054554618895053864,
        -0.0022881776094436646,
        0.04236697405576706,
        -0.04073748737573624,
        0.052488263696432114,
        0.04913296923041344,
        0.03597642853856087,
        0.018886467441916466,
        0.05524834990501404,
        0.037174832075834274,
        0.053202398121356964,
        -0.04298252984881401,
        0.05231907591223717,
        0.038940951228141785,
        0.04126535728573799,
        -0.044160254299640656,
        0.04985056445002556,
        -0.04971734806895256,
        -0.04215066507458687,
        -0.05445237457752228,
        -0.053781140595674515,
        -0.051621709018945694,
        -0.0483834370970726,
        0.0337604321539402,
        0.05313311889767647,
        -0.05365679785609245,
        -0.026489118114113808,
        0.05307789146900177,
        0.04798617213964462,
        0.03555445745587349,
        0.054385386407375336,
        -0.045570917427539825,
        0.046663686633110046,
        -0.009808019734919071,
        0.054779425263404846,
        0.024530405178666115,
        0.05381253361701965,
        0.041897814720869064,
        -0.05506899952888489,
        -0.04551864042878151,
        -0.05497931316494942,
        0.018500536680221558,
        -0.05519326776266098,
        0.04056420922279358,
        -0.013019743375480175,
        -0.05398816242814064,
        -0.02158372476696968,
        0.016941050067543983,
        0.05121900886297226,
        -0.05513463541865349,
        -0.035371556878089905,
        0.04934179410338402,
        0.03545418754220009,
        0.03411838412284851,
        -0.02570686861872673,
        0.05460327863693237,
        -0.05525657534599304,
        -0.053126078099012375,
        0.04743705317378044,
        -0.05342559888958931,
        0.052229344844818115,
        -0.013862933032214642,
        0.020753564313054085,
        0.02357490360736847,
        0.0007280492573045194,
        0.05520248040556908,
        -0.0444362498819828,
        -0.03899407759308815,
        0.05222016200423241,
        -0.051283467561006546,
        0.038674067705869675,
        0.0017809009877964854,
        0.04968517646193504,
        0.05107579380273819,
        -0.039210621267557144,
        -0.03829864412546158,
        -0.004498938098549843,
        0.04356604069471359,
        -0.025528911501169205,
        0.05438914895057678,
        -0.05466616526246071,
        0.005617410875856876,
        0.0515856072306633,
        -0.0548299215734005,
        -0.054068829864263535,
        0.027970530092716217,
        0.05527425557374954,
        0.03994731232523918,
        -0.04910490661859512,
        -0.055258315056562424,
        -0.04673239216208458,
        0.05172068253159523,
        -0.05417516827583313,
        0.05457909405231476,
        0.05418172478675842,
        0.05428272485733032,
        0.02001175284385681,
        0.051098696887493134,
        0.0432598777115345,
        -0.014457826502621174,
        0.028104018419981003,
        -0.013293363153934479,
        -0.05511109158396721,
        0.03893627971410751,
        0.048389554023742676,
        -0.05185794085264206,
        0.054784391075372696,
        -0.05485684424638748,
        0.04577632248401642,
        -0.03869778290390968,
        -0.036402538418769836,
        0.019816739484667778,
        -0.05131297558546066,
        0.05528312548995018,
        0.05423636734485626,
        0.04359699785709381,
        0.045465946197509766,
        0.050936177372932434,
        0.04917404428124428,
        -0.05208118259906769,
        0.031102800741791725,
        0.022357245907187462,
        -0.055067677050828934,
        -0.04619593173265457,
        0.05363989248871803,
        -0.0036229107063263655,
        -0.022008009254932404,
        0.0008939483086578548,
        -0.038889285176992416,
        0.04010841250419617,
        -0.0010069545824080706,
        -0.04121521860361099,
        -0.05490540340542793,
        0.0552629679441452,
        -0.05190804973244667,
        0.05527886003255844,
        -0.013153784908354282,
        0.04654929041862488,
        -0.055278751999139786,
        -0.05421924591064453,
        -0.05524561181664467,
        0.05519621819257736,
        0.053792111575603485,
        0.05408547818660736,
        0.055119335651397705,
        0.054825376719236374,
        -0.05423710495233536,
        -0.04432510584592819,
        0.01050088182091713,
        0.029210366308689117,
        0.04554913192987442,
        -0.0544181652367115,
        0.05040762200951576,
        0.04674657806754112,
        -0.04398970678448677,
        0.05340300500392914,
        -0.04920003190636635,
        -0.043453898280858994,
        -0.04006732255220413,
        -0.054903194308280945,
        0.05005062744021416,
        -0.033017873764038086,
        0.020110415294766426,
        -0.0014413869939744473,
        0.05211459845304489,
        0.05135774984955788,
        -0.05490992218255997,
        -0.03752319887280464,
        -0.05413757637143135,
        -0.048488110303878784,
        0.041058097034692764,
        0.05204854905605316,
        0.04855098947882652,
        -0.05116375908255577,
        -0.02205267734825611,
        0.05435425415635109,
        0.05148613452911377,
        0.050984613597393036,
        -0.03946506232023239,
        0.05305856466293335,
        0.050927311182022095,
        -0.052591245621442795,
        0.050627630203962326,
        0.029532860964536667,
        -0.0376092903316021,
        0.0527857169508934,
        0.042398277670145035,
        0.05132324621081352,
        0.03492071107029915,
        -0.05264902859926224,
        -0.05520408973097801,
        0.013557899743318558,
        -0.0548364594578743,
        0.05470731481909752,
        -0.045190293341875076,
        -0.053232528269290924,
        0.05229838192462921,
        -0.0540328174829483,
        -0.013851194642484188,
        0.04421333596110344,
        -0.03818684443831444,
        -0.003573389258235693,
        -0.02804245613515377,
        0.03029746003448963,
        -0.03088269755244255,
        -0.002536256331950426,
        0.043525777757167816,
        0.0488496758043766,
        -0.05194975808262825,
        0.05403272807598114,
        0.052540846168994904,
        0.05520159751176834,
        0.051099490374326706,
        -0.036856163293123245,
        0.05144699662923813,
        0.05392367020249367,
        -0.05398281291127205,
        0.049315668642520905,
        -0.05102306231856346,
        -0.05353369191288948,
        0.04288112372159958,
        0.05518629774451256,
        0.03579791262745857,
        0.03865288570523262,
        -0.04317931458353996,
        -0.052636172622442245,
        0.017341434955596924,
        -0.015793198719620705,
        0.047264162451028824,
        0.05161227285861969,
        0.033012546598911285,
        -0.025228794664144516,
        -0.04604429006576538,
        0.05509655922651291,
        0.0549398809671402,
        -0.054905783385038376,
        0.05513385310769081,
        -0.05456312745809555,
        -0.05520549416542053,
        0.03529150411486626,
        -0.02250271663069725,
        0.03300357609987259,
        0.054994016885757446,
        0.011704743839800358,
        0.031686000525951385,
        -0.05120041221380234,
        0.04428819939494133,
        -0.0536373108625412,
        -0.05441125109791756,
        -0.006370839197188616,
        -0.039194218814373016,
        0.050232842564582825,
        0.04873040318489075,
        -0.04912023991346359,
        0.045446429401636124,
        0.054735682904720306,
        -0.021783681586384773,
        -0.040482793003320694,
        -0.05446353182196617,
        -0.03419790044426918,
        -0.04875887185335159,
        0.04975420609116554,
        -0.05259716510772705,
        0.05524688586592674,
        0.05343836545944214,
        -0.05091419816017151,
        0.0530860461294651,
        0.05150929465889931,
        0.022475719451904297,
        -0.036363597959280014,
        -0.04589509591460228,
        -0.04826442152261734,
        -0.015281876549124718,
        -0.04811913147568703,
        0.05101122334599495,
        0.04573014751076698,
        0.0032257724087685347,
        -0.04486075043678284,
        -0.030048275366425514,
        0.04857046529650688,
        0.04640207439661026
    ],
    [
        0.0469881072640419,
        -0.04779869690537453,
        -0.049757059663534164,
        0.03130197152495384,
        0.04537620395421982,
        -0.048011571168899536,
        0.05301162600517273,
        -0.05251335725188255,
        0.05227038636803627,
        0.034888673573732376,
        0.04904920235276222,
        0.053811803460121155,
        -0.05365269258618355,
        0.03896069899201393,
        -0.051834654062986374,
        0.04441218823194504,
        -0.028962384909391403,
        0.04096251726150513,
        0.005967658944427967,
        -0.05376323685050011,
        0.034935563802719116,
        0.01571122370660305,
        0.04997655376791954,
        0.04849627986550331,
        0.05243411287665367,
        0.050335802137851715,
        0.03470807895064354,
        0.05264197662472725,
        -0.05300086736679077,
        -0.011323693208396435,
        0.05229143053293228,
        -0.05274636298418045,
        -0.04417376220226288,
        -0.05099429190158844,
        -0.026756366714835167,
        -0.03343091905117035,
        -0.05203709751367569,
        -0.02718181721866131,
        -0.04554362967610359,
        0.00875626690685749,
        0.023934556171298027,
        0.048219241201877594,
        0.05312443524599075,
        -0.0347663089632988,
        -0.006452707573771477,
        0.012266634963452816,
        0.04566541686654091,
        0.05332469195127487,
        0.053809043020009995,
        0.05229485034942627,
        0.03543146327137947,
        -0.052740681916475296,
        0.05294696241617203,
        -0.0152523722499609,
        -0.053306322544813156,
        -0.05367477983236313,
        0.03213563188910484,
        -0.025804342702031136,
        -0.04892513528466225,
        -0.04554717242717743,
        -0.051597535610198975,
        -0.05099092796444893,
        -0.048044975847005844,
        0.04907890036702156,
        0.030617158859968185,
        0.016648920252919197,
        0.041218291968107224,
        -0.011237945407629013,
        0.018418889492750168,
        -0.05377045273780823,
        -0.05356230586767197,
        -0.05377921462059021,
        0.028081607073545456,
        -0.05117880925536156,
        -0.05068185552954674,
        -0.029774107038974762,
        0.038006000220775604,
        0.0537862554192543,
        0.05107789859175682,
        -0.05299196392297745,
        -0.013378247618675232,
        -0.03697730973362923,
        -0.04136262089014053,
        0.04239427670836449,
        0.039859164506196976,
        0.05113668739795685,
        -0.044591981917619705,
        0.02384447306394577,
        0.05315185338258743,
        0.05352812260389328,
        -0.05165674537420273,
        0.04444340243935585,
        -0.04870028421282768,
        -0.05325467512011528,
        -0.0519210547208786,
        0.044047702103853226,
        -0.04858874902129173,
        -0.051649682223796844,
        -0.03749127686023712,
        -0.0373353511095047,
        -0.053807638585567474,
        0.036149196326732635,
        0.05365973338484764,
        0.030291281640529633,
        -0.008331534452736378,
        -0.04648829624056816,
        -0.0524897500872612,
        0.05363650247454643,
        -0.05336565896868706,
        0.008307377807796001,
        -0.0538121722638607,
        -0.05379856377840042,
        -0.05372960492968559,
        0.0496189221739769,
        0.05028148368000984,
        0.04159810394048691,
        0.017937706783413887,
        -0.053802523761987686,
        -0.04598856717348099,
        -0.028802456334233284,
        -0.033291444182395935,
        0.047592852264642715,
        -0.005025102756917477,
        0.014417675323784351,
        -0.05357811599969864,
        0.02037026919424534,
        -0.012916735373437405,
        0.05350811779499054,
        0.05291204899549484,
        -0.035826947540044785,
        0.04827967658638954,
        0.0165970791131258,
        -0.05252449959516525,
        0.017341194674372673,
        -0.05204010009765625,
        -0.052919451147317886,
        0.05380026996135712,
        0.05354105308651924,
        -0.02313912846148014,
        0.05299433320760727,
        0.052979569882154465,
        0.05380767583847046,
        0.035267364233732224,
        0.05282234773039818,
        -0.04402516782283783,
        0.03790801018476486,
        0.04721997678279877,
        -0.018033213913440704,
        0.04804631322622299,
        0.053729068487882614,
        -0.028915075585246086,
        -0.053800247609615326,
        -0.053407881408929825,
        -0.04217522591352463,
        0.03827599063515663,
        -0.044634152203798294,
        0.052267346531152725,
        -0.05293819680809975,
        0.005704771261662245,
        0.04259493201971054,
        0.05251675844192505,
        0.05210215225815773,
        -0.005717257969081402,
        0.013905498199164867,
        -0.05188055336475372,
        -0.051172882318496704,
        0.053811006247997284,
        -0.027617814019322395,
        0.043420836329460144,
        0.04733651131391525,
        -0.05195485055446625,
        -0.05296938121318817,
        -0.05181998014450073,
        0.02667054533958435,
        -0.04748374596238136,
        0.053781088441610336,
        -0.05379093438386917,
        -0.038175277411937714,
        0.051883794367313385,
        0.016561992466449738,
        0.04864172264933586,
        -0.049210626631975174,
        0.05378207564353943,
        -0.025452647358179092,
        0.0499485582113266,
        -0.049587126821279526,
        -0.053667522966861725,
        0.03680216893553734,
        -0.0464191772043705,
        -0.05370674282312393,
        0.05223923176527023,
        -0.042495246976614,
        -0.05035208538174629,
        0.03016451559960842,
        -0.007182855159044266,
        0.002104002982378006,
        0.05201034992933273,
        0.01918479986488819,
        0.053513478487730026,
        0.049766551703214645,
        0.03271525353193283,
        -0.05337712541222572,
        0.03128672391176224,
        0.049139197915792465,
        0.038048744201660156,
        0.04720184952020645,
        0.04195205494761467,
        0.02572798915207386,
        -0.052529409527778625,
        0.05322132632136345,
        -0.04963435232639313,
        0.049951501190662384,
        -0.020711634308099747,
        -0.03947432339191437,
        0.03179527819156647,
        0.05373390391469002,
        0.0023591676726937294,
        0.05109088867902756,
        0.05380215495824814,
        -0.05340281128883362,
        -0.0460280105471611,
        -0.045550957322120667,
        -0.05379714444279671,
        -0.05357993766665459,
        0.050462447106838226,
        -0.053454864770174026,
        -0.0308357086032629,
        -0.04759112745523453,
        0.02004995569586754,
        -0.04725920408964157,
        -0.0490579679608345,
        -0.052373532205820084,
        -0.04081140458583832,
        0.051749419420957565,
        0.038489267230033875,
        -0.00017626877524890006,
        0.05343588814139366,
        -0.03893901780247688,
        0.051457226276397705,
        0.05354166030883789,
        0.018669214099645615,
        0.05380529910326004,
        0.05348343774676323,
        -0.025544747710227966,
        0.017183663323521614,
        0.05146152153611183,
        0.053225986659526825,
        0.05172766372561455,
        -0.05329475924372673,
        -0.024791868403553963,
        -0.034638602286577225,
        -0.05364619567990303,
        0.05294331908226013,
        -0.053455960005521774,
        0.04664966091513634,
        0.053687360137701035,
        -0.052754275500774384,
        -0.040658511221408844,
        0.05380259081721306,
        0.053788188844919205,
        0.044761717319488525,
        0.0438089594244957,
        0.0013337783748283982,
        0.05184759944677353,
        -0.03300091624259949,
        -0.007287777028977871,
        -0.0291647557169199,
        0.0020103659480810165,
        0.03731989860534668,
        -0.05246242880821228,
        -0.03903210908174515,
        -0.05375654995441437,
        0.04380479454994202,
        -0.053710825741291046,
        -0.038190629333257675,
        0.00588194327428937,
        -0.05288751795887947,
        0.05339982360601425,
        -0.053809795528650284,
        -0.05058465898036957,
        -0.05367497727274895,
        -0.02890416607260704,
        -0.024587122723460197,
        0.052695855498313904,
        0.053810831159353256,
        -0.04358925670385361,
        0.00933840498328209,
        -0.0036858459934592247,
        -0.0536467470228672,
        0.05096611753106117,
        -0.018550030887126923,
        0.05008486285805702,
        -0.004304052796214819,
        0.004107326734811068,
        0.04991303011775017,
        -0.05193578824400902,
        0.053793102502822876,
        0.036525242030620575,
        -0.015936125069856644,
        0.053756456822156906,
        -0.044554755091667175,
        0.05317515879869461,
        0.023695003241300583,
        0.05165944620966911,
        0.052975405007600784,
        -0.049332186579704285,
        -0.05291609466075897,
        0.027879858389496803,
        0.051122236996889114,
        -0.0535409152507782,
        -0.00704652676358819,
        -0.04986768960952759,
        0.04890840873122215,
        0.04938436672091484,
        -0.05302932858467102,
        -0.05339764431118965,
        0.04849816486239433,
        -0.007988156750798225,
        0.04814644902944565,
        -0.052774082869291306,
        -0.053342536091804504,
        0.04445355385541916,
        0.05298743396997452,
        -0.016827091574668884,
        0.05337435007095337,
        0.05226455256342888,
        0.05379319190979004,
        -0.033731140196323395,
        0.053206104785203934,
        0.02820671536028385,
        -0.0031122101936489344,
        0.05266755446791649,
        0.04780520126223564,
        -0.053484927862882614,
        0.05296565219759941,
        0.05158720165491104,
        -0.04352043569087982,
        0.05325465649366379,
        -0.0536879263818264,
        0.053758520632982254,
        0.03949449956417084,
        -0.0528717115521431,
        0.04435227811336517,
        -0.053306709975004196,
        0.05381244793534279,
        0.047321103513240814,
        0.024513131007552147,
        0.04751794785261154,
        0.03641931340098381,
        0.05340791866183281,
        -0.053530413657426834,
        0.053767796605825424,
        -0.050404708832502365,
        -0.05269915983080864,
        -0.053437404334545135,
        0.05379674211144447,
        -0.053756535053253174,
        -0.052744604647159576,
        -0.05325409770011902,
        -0.05299384146928787,
        0.05235470086336136,
        0.025323424488306046,
        -0.053699664771556854,
        -0.030311595648527145,
        -0.016929155215620995,
        -0.0488111674785614,
        0.05378861352801323,
        -0.05181273818016052,
        0.04042787849903107,
        -0.05380909517407417,
        -0.05332960560917854,
        -0.053556278347969055,
        0.05381229519844055,
        -0.004486351739615202,
        0.05261210724711418,
        0.052098967134952545,
        -0.02442334033548832,
        0.027513381093740463,
        0.012905613519251347,
        -0.052628882229328156,
        0.007571626454591751,
        0.035861238837242126,
        -0.05375523120164871,
        -0.00495267566293478,
        -0.02227216213941574,
        -0.025797953829169273,
        0.04958952218294144,
        -0.05059577897191048,
        -0.05308762937784195,
        -0.05041181668639183,
        -0.052321504801511765,
        -0.032092850655317307,
        0.03767092525959015,
        -0.04399999603629112,
        -0.006543817464262247,
        0.04403409734368324,
        0.046069324016571045,
        -0.03700968995690346,
        -0.0518529936671257,
        -0.05365634337067604,
        -0.048324860632419586,
        0.05206926167011261,
        0.05186216160655022,
        0.053244512528181076,
        -0.03478589281439781,
        0.018733197823166847,
        0.023834284394979477,
        0.0316164493560791,
        0.05377426743507385,
        -0.05241309851408005,
        0.04389861971139908,
        -0.03120299056172371,
        0.050251830369234085,
        -0.032455913722515106,
        0.006467304192483425,
        0.048609789460897446,
        -0.011975488625466824,
        -0.04092263802886009,
        0.0206658523529768,
        0.05370697006583214,
        -0.048307888209819794,
        0.04820774495601654,
        -0.05220862105488777,
        -0.05372549965977669,
        0.05199640989303589,
        -0.052091084420681,
        -0.05343063920736313,
        -0.0009167909738607705,
        -0.05348048359155655,
        -0.00838194228708744,
        0.05359189584851265,
        -0.042363978922367096,
        -0.02128990925848484,
        0.04521036893129349,
        0.05232016742229462,
        -0.016653459519147873,
        -0.053725969046354294,
        0.01764848828315735,
        0.013441625982522964,
        -0.04697057977318764,
        -0.022743672132492065,
        0.018858706578612328,
        0.041365090757608414,
        0.051854830235242844,
        0.0524691566824913,
        -0.047132521867752075,
        0.053576402366161346,
        -0.050166089087724686,
        0.053132668137550354,
        -0.012575753033161163,
        -0.05375522002577782,
        0.053730472922325134,
        0.05228431895375252,
        -0.023590655997395515,
        0.05122906342148781,
        -0.05273934826254845,
        -0.028994454070925713,
        0.03572094440460205,
        -0.04305297136306763,
        0.016424188390374184,
        0.01886511966586113,
        0.050887830555438995,
        -0.05147464573383331,
        0.024022072553634644,
        0.04989906772971153,
        0.05366726592183113,
        -0.05381249636411667,
        0.05319814756512642,
        -0.053806569427251816,
        -0.05380295589566231,
        0.05359257012605667,
        0.05064142495393753,
        -0.008516752161085606,
        0.03318171203136444,
        0.05361264571547508,
        0.04178684577345848,
        0.021798059344291687,
        0.04587557911872864,
        -0.05356049910187721,
        -0.04706912115216255,
        0.049798689782619476,
        -0.034370116889476776,
        0.053777001798152924,
        0.053708527237176895,
        -0.006562901195138693,
        0.05299268662929535,
        0.052679091691970825,
        -0.009659153409302235,
        -0.041717130690813065,
        -0.009126550517976284,
        -0.05216914042830467,
        -0.05380477383732796,
        -0.049128543585538864,
        0.050042834132909775,
        0.043654367327690125,
        0.005763901863247156,
        -0.05070691183209419,
        -0.04794042184948921,
        0.053800515830516815,
        0.04843460023403168,
        -0.04415802285075188,
        -0.05274080112576485,
        0.028199873864650726,
        -0.04554653912782669,
        -0.043465521186590195,
        -0.01497694943100214,
        0.03958320617675781,
        -0.012292342260479927,
        -0.05183517187833786,
        -0.05381067469716072,
        0.053808316588401794,
        0.05365970730781555
    ],
    [
        0.05505263805389404,
        0.05414234474301338,
        -0.031176140531897545,
        -0.04591202735900879,
        0.011143107898533344,
        -0.05347771197557449,
        -0.05116882175207138,
        -0.0542735829949379,
        0.03873893991112709,
        -0.056959204375743866,
        0.03549063205718994,
        0.0553903728723526,
        -0.05624917894601822,
        -0.040993690490722656,
        -0.05550059303641319,
        0.04534028843045235,
        0.045543815940618515,
        0.042032502591609955,
        0.05385153740644455,
        0.04638580605387688,
        0.051607731729745865,
        -0.0037964966613799334,
        0.05695851519703865,
        -0.05424860864877701,
        -0.007196344900876284,
        -0.03977688401937485,
        0.05555451661348343,
        0.046136096119880676,
        0.042763810604810715,
        0.010443329811096191,
        0.049245018512010574,
        -0.05641217902302742,
        0.052698057144880295,
        -0.05679340288043022,
        0.04840003326535225,
        -0.056900881230831146,
        -0.054554425179958344,
        -0.05673599988222122,
        0.013491363264620304,
        -0.05142185091972351,
        0.050753045827150345,
        0.0489840991795063,
        0.035522591322660446,
        0.05141299217939377,
        0.02914569526910782,
        -0.0464479923248291,
        -0.00012374529615044594,
        0.041359350085258484,
        0.056906189769506454,
        0.005061465781182051,
        0.05690986290574074,
        0.004807376768440008,
        0.007834329269826412,
        -0.043819453567266464,
        0.03950304910540581,
        -0.044755931943655014,
        -0.027988770976662636,
        0.02528649941086769,
        -0.054312270134687424,
        -0.03039092943072319,
        -0.0562121644616127,
        0.030813880264759064,
        -0.056094273924827576,
        -0.031432777643203735,
        -0.004053489770740271,
        -0.018589675426483154,
        -0.03614318370819092,
        -0.05542464926838875,
        -0.03404170647263527,
        -0.05683986842632294,
        -0.040537551045417786,
        -0.05499191954731941,
        0.0191392432898283,
        -0.05063965171575546,
        0.01801380142569542,
        -0.04823802411556244,
        0.005016453564167023,
        0.056961677968502045,
        -0.024989113211631775,
        0.05006905272603035,
        -0.04253409057855606,
        0.001445223344489932,
        -0.05524325370788574,
        0.056760236620903015,
        -0.05222134292125702,
        0.05315582454204559,
        -0.050810787826776505,
        -0.056182101368904114,
        0.040965814143419266,
        0.052613265812397,
        -0.05396512895822525,
        0.023039355874061584,
        -0.021407954394817352,
        -0.056479085236787796,
        -0.05366339161992073,
        0.051302701234817505,
        0.007431765552610159,
        -0.055702101439237595,
        0.021338123828172684,
        0.043001748621463776,
        -0.056794192641973495,
        0.015597756952047348,
        0.05523613840341568,
        0.05590531975030899,
        -0.05074505880475044,
        -0.04692445322871208,
        0.032130252569913864,
        0.04835290089249611,
        -0.052506133913993835,
        0.04309438541531563,
        0.02696797251701355,
        -0.055532798171043396,
        -0.05012355372309685,
        -0.033229559659957886,
        0.05205373466014862,
        -0.033130716532468796,
        0.05109190568327904,
        -0.04078776389360428,
        0.0534171387553215,
        -0.03363223373889923,
        -0.010398847050964832,
        0.017458446323871613,
        0.018804345279932022,
        -0.05673612654209137,
        -0.051734790205955505,
        0.05646980181336403,
        -0.045961298048496246,
        0.05175701901316643,
        0.056258250027894974,
        0.05227021127939224,
        -0.005912013351917267,
        -0.009815811179578304,
        -0.04286731034517288,
        0.050464529544115067,
        -0.05526553839445114,
        -0.05635177344083786,
        0.056798387318849564,
        0.05536572262644768,
        0.04758738353848457,
        0.055569201707839966,
        -0.0438259020447731,
        0.05691743642091751,
        0.04736238718032837,
        0.05045647174119949,
        -0.052205104380846024,
        0.00015395534865092486,
        -0.052012600004673004,
        -0.0463830791413784,
        0.05026279017329216,
        0.05691302567720413,
        -0.0058800517581403255,
        -0.056920308619737625,
        -0.05556472763419151,
        -0.014640407636761665,
        0.054212234914302826,
        -0.012440096586942673,
        -0.04914657026529312,
        -0.05179711431264877,
        0.05249127745628357,
        0.046249326318502426,
        -0.023819591850042343,
        -0.0006444085738621652,
        0.04789821803569794,
        0.054902736097574234,
        0.04618564248085022,
        -0.01872144266963005,
        0.05577600374817848,
        -0.040766287595033646,
        -0.05466637387871742,
        0.052497487515211105,
        -0.05662459135055542,
        -0.01930765062570572,
        -0.041081998497247696,
        0.031904734671115875,
        0.03677509352564812,
        0.051117364317178726,
        -0.03862453252077103,
        0.05625756084918976,
        0.055760644376277924,
        -0.0237115565687418,
        -0.05608842149376869,
        0.04060585796833038,
        0.02602444589138031,
        -0.05614304915070534,
        0.010197361931204796,
        0.009071421809494495,
        -0.034486766904592514,
        0.05453362688422203,
        -0.05540572479367256,
        0.0186883844435215,
        -0.007903492078185081,
        0.04828151687979698,
        0.030446626245975494,
        -0.0033831738401204348,
        -0.05640376731753349,
        0.030060116201639175,
        0.03494217246770859,
        0.034168679267168045,
        0.008550509810447693,
        0.048886626958847046,
        0.056788306683301926,
        -0.056795883923769,
        0.01776774227619171,
        -0.0569148026406765,
        -0.045640259981155396,
        -0.011485833674669266,
        -0.01625322550535202,
        -0.024277344346046448,
        -0.05246564373373985,
        0.05617256835103035,
        -0.046583157032728195,
        0.05671870708465576,
        0.00627689016982913,
        0.05519813671708107,
        0.028314247727394104,
        0.03526503965258598,
        -0.05433636158704758,
        0.019680768251419067,
        0.05687551572918892,
        -0.045165762305259705,
        -0.033041518181562424,
        0.04033803194761276,
        -0.04777732864022255,
        -0.05416344106197357,
        0.05424979329109192,
        -0.054065559059381485,
        0.021882707253098488,
        -0.04631252586841583,
        0.056218087673187256,
        -0.05379102751612663,
        0.04286298155784607,
        -0.05603734403848648,
        -0.03546793386340141,
        0.03930816054344177,
        -0.035861339420080185,
        0.05685972794890404,
        0.05519275367259979,
        0.04702434316277504,
        -0.014698577113449574,
        0.056870218366384506,
        -0.031802888959646225,
        0.05344901606440544,
        -0.031094294041395187,
        -0.00917008239775896,
        -0.024098051711916924,
        -0.04413817822933197,
        -0.013743598014116287,
        0.024738503620028496,
        -0.04993729665875435,
        -0.05012813210487366,
        -0.05446464940905571,
        -0.05532114580273628,
        0.054335955530405045,
        -0.05141386389732361,
        0.05671165511012077,
        0.0506153367459774,
        -0.04845654219388962,
        0.02362693101167679,
        0.046705398708581924,
        0.03038429468870163,
        -0.04701165109872818,
        0.01816197857260704,
        -0.011566010303795338,
        0.012678627856075764,
        0.031335532665252686,
        0.05605905130505562,
        0.0566142201423645,
        0.05571215972304344,
        0.003420404624193907,
        -0.0075763557106256485,
        -0.018997307866811752,
        -0.05408783629536629,
        0.05357442796230316,
        -0.05437738448381424,
        0.03359873592853546,
        -0.024395078420639038,
        -0.05172622576355934,
        0.05438486114144325,
        -0.05682063475251198,
        -0.014284097589552402,
        -0.044601500034332275,
        0.039501845836639404,
        0.04419858753681183,
        0.05676167830824852,
        0.056387659162282944,
        -0.05055586248636246,
        0.009161100722849369,
        0.053627412766218185,
        -0.0415344312787056,
        0.011212652549147606,
        -0.051838528364896774,
        0.050465501844882965,
        -0.05470220372080803,
        -0.04815835505723953,
        0.05447746068239212,
        -0.052064333111047745,
        0.05371763929724693,
        0.05642420053482056,
        -0.012078971602022648,
        0.05598219856619835,
        -0.05575156211853027,
        0.025278519839048386,
        0.0012454790994524956,
        0.05382019281387329,
        0.037668436765670776,
        0.019135311245918274,
        -0.04882574453949928,
        0.05506356060504913,
        0.024800604209303856,
        -0.04434867203235626,
        0.055648840963840485,
        -0.056273527443408966,
        0.03254600241780281,
        -0.03424101322889328,
        -0.006449887529015541,
        -0.05497950315475464,
        -0.02209445834159851,
        0.015955474227666855,
        0.05555560439825058,
        -0.05535031482577324,
        -0.019311727955937386,
        0.035217009484767914,
        0.05649561434984207,
        -0.029941683635115623,
        0.05507510155439377,
        0.006771089043468237,
        -0.05606280267238617,
        -0.056754592806100845,
        -0.00579456752166152,
        0.03824780136346817,
        0.05571160092949867,
        -0.009700289927423,
        0.04182187467813492,
        0.039256468415260315,
        0.048842743039131165,
        0.019536327570676804,
        -0.05591419339179993,
        0.03320819139480591,
        -0.04069104045629501,
        0.0456082709133625,
        0.02540576457977295,
        -0.052132561802864075,
        -0.053349174559116364,
        0.056038398295640945,
        0.030826803296804428,
        -0.01509190071374178,
        0.05173518508672714,
        0.05570360645651817,
        0.05144021660089493,
        0.04792298004031181,
        0.05193739011883736,
        0.031995318830013275,
        0.032893307507038116,
        -0.05621642619371414,
        -0.0524289608001709,
        0.051281966269016266,
        -0.0512535534799099,
        -0.05377137288451195,
        -0.02326084114611149,
        -0.05688733980059624,
        0.053014032542705536,
        0.055593669414520264,
        -0.05296230688691139,
        0.014161321334540844,
        -0.009491886012256145,
        -0.05677583068609238,
        0.056083258241415024,
        -0.027516188099980354,
        0.05669916793704033,
        -0.0567774623632431,
        -0.05158128961920738,
        -0.05636198818683624,
        0.05657821521162987,
        0.022506503388285637,
        0.052868328988552094,
        0.05682413652539253,
        0.048767831176519394,
        0.05600231885910034,
        0.055227719247341156,
        -0.05405758321285248,
        -0.05165000632405281,
        -0.040718380361795425,
        -0.05509154498577118,
        0.04914817214012146,
        -0.039646897464990616,
        -0.05567546561360359,
        -0.0054525211453437805,
        -0.049438074231147766,
        -0.05098164826631546,
        -0.05577221140265465,
        -0.055904220789670944,
        0.012219351716339588,
        -0.04308760538697243,
        -0.043772775679826736,
        0.04706808552145958,
        0.05476300045847893,
        0.046708010137081146,
        -0.0027717098128050566,
        -0.05693181976675987,
        -0.056460026651620865,
        -0.006741424556821585,
        0.05437328666448593,
        -0.0364859402179718,
        0.054452668875455856,
        0.05021033436059952,
        0.03535151109099388,
        0.05619307979941368,
        -0.05286777392029762,
        0.056031834334135056,
        0.01969088613986969,
        0.012746014632284641,
        -0.05535556375980377,
        0.051948074251413345,
        0.024343103170394897,
        0.0523267537355423,
        -0.024066628888249397,
        0.056019242852926254,
        0.05546564981341362,
        -0.03912600874900818,
        0.052313245832920074,
        -0.01820293627679348,
        0.056927070021629333,
        -0.053158845752477646,
        -0.05675207078456879,
        0.037009190768003464,
        -0.055227894335985184,
        0.02814396098256111,
        0.04370100423693657,
        -0.011561420746147633,
        -0.051354650408029556,
        0.04963892698287964,
        0.022776972502470016,
        -0.055713262408971786,
        -0.039598748087882996,
        0.02761145681142807,
        -0.04313633590936661,
        -0.0569123849272728,
        0.03681863471865654,
        0.03374353051185608,
        0.0006615817546844482,
        -0.04492511600255966,
        0.05302377790212631,
        0.011539441533386707,
        0.05515868589282036,
        0.0449313223361969,
        -0.02875761315226555,
        0.056741174310445786,
        -0.052593059837818146,
        0.05599541217088699,
        0.05274318531155586,
        -0.054866306483745575,
        0.025351377204060555,
        0.03624468296766281,
        0.05229993537068367,
        0.029269754886627197,
        -0.056690264493227005,
        -0.026986198499798775,
        -0.0520072840154171,
        0.04274982586503029,
        0.04500289261341095,
        0.05255696177482605,
        0.019317181780934334,
        -0.039954282343387604,
        0.05551502853631973,
        0.05574154481291771,
        0.05672336369752884,
        -0.03846082091331482,
        0.04000501707196236,
        -0.03143394738435745,
        -0.05674133822321892,
        0.05662042647600174,
        0.036588240414857864,
        0.04622148349881172,
        0.03399709612131119,
        -0.0517384335398674,
        0.028662152588367462,
        -0.023844383656978607,
        0.0443815253674984,
        -0.055377837270498276,
        0.052981652319431305,
        0.018585259094834328,
        -0.04173203557729721,
        -0.013087095692753792,
        0.0452163964509964,
        -0.022117722779512405,
        0.05422290787100792,
        0.05463831126689911,
        0.04807952791452408,
        -0.05661782622337341,
        -0.014985193498432636,
        0.013144880533218384,
        -0.05270332470536232,
        0.01183673832565546,
        0.0016816589049994946,
        0.007168471813201904,
        0.020528361201286316,
        0.049036815762519836,
        -0.0366017110645771,
        0.054759372025728226,
        0.04151007905602455,
        -0.032881855964660645,
        0.008727173320949078,
        -0.050976406782865524,
        -0.009917532093822956,
        0.027864651754498482,
        -0.04233315587043762,
        0.043641261756420135,
        -0.011295875534415245,
        0.04892590269446373,
        -0.05696073919534683,
        0.050982069224119186,
        0.05611489713191986
    ],
    [
        0.05678977817296982,
        0.0355503186583519,
        -0.009877749718725681,
        -0.031216533854603767,
        0.008925169706344604,
        -0.022640958428382874,
        -0.0396278090775013,
        -0.05505083128809929,
        0.0567350871860981,
        0.037033963948488235,
        0.0137308519333601,
        0.04998667910695076,
        -0.05337825044989586,
        0.05479613319039345,
        -0.03961099684238434,
        -0.04190805181860924,
        0.055503107607364655,
        0.008398511447012424,
        0.028405236080288887,
        -0.056446414440870285,
        0.012195853516459465,
        0.050878625363111496,
        0.03301272168755531,
        -0.05449318513274193,
        0.04367043450474739,
        -0.012741521932184696,
        0.044474922120571136,
        0.0543295256793499,
        -0.04708556458353996,
        0.05294043570756912,
        0.032505057752132416,
        -0.007648861967027187,
        -0.035548724234104156,
        -0.056011468172073364,
        0.042342863976955414,
        -0.05514228716492653,
        -0.0449773333966732,
        -0.032834962010383606,
        -0.01994762197136879,
        -0.040624119341373444,
        0.05266321450471878,
        0.05495564639568329,
        0.048273563385009766,
        0.05086272582411766,
        0.05677955225110054,
        -0.04167316108942032,
        0.03298814222216606,
        0.05673881247639656,
        0.055923618376255035,
        -0.051254041492938995,
        0.05588768050074577,
        -0.02402365207672119,
        -0.020469550043344498,
        0.04989860951900482,
        -0.047084711492061615,
        0.053057845681905746,
        -0.0466054268181324,
        0.04824193939566612,
        0.0019953972660005093,
        -0.042577143758535385,
        -0.04919029399752617,
        0.046467456966638565,
        -0.048801202327013016,
        0.05647179111838341,
        0.01502523198723793,
        -0.054623667150735855,
        -0.05554107576608658,
        0.002810904523357749,
        0.04599235951900482,
        -0.054170653223991394,
        0.012699698098003864,
        -0.002284119836986065,
        -0.04345037043094635,
        0.042040638625621796,
        0.023535529151558876,
        -0.026670467108488083,
        0.004207393154501915,
        0.05679081007838249,
        0.04962480813264847,
        0.04267863929271698,
        -0.04269690811634064,
        -0.04410921037197113,
        -0.05647824704647064,
        0.049624521285295486,
        0.004245230462402105,
        0.04624514654278755,
        0.05165233463048935,
        0.05426638945937157,
        -0.029326893389225006,
        -0.05517500266432762,
        -0.05603649094700813,
        -0.03941354900598526,
        -0.05660249665379524,
        -0.05354120582342148,
        -0.04109892621636391,
        0.04408138245344162,
        -0.05643513426184654,
        -0.04832836613059044,
        -0.051960960030555725,
        0.04791960120201111,
        -0.05636611953377724,
        0.03715808317065239,
        0.03017570823431015,
        0.05266985297203064,
        -0.00974507536739111,
        -0.04842521622776985,
        -0.05591215565800667,
        -0.042904723435640335,
        0.056274302303791046,
        -0.00003622271105996333,
        -0.05186759680509567,
        -0.02989453822374344,
        -0.026458747684955597,
        0.05033787712454796,
        0.0388031080365181,
        0.0064595951698720455,
        -0.009723900817334652,
        0.0007719226414337754,
        0.04797275364398956,
        0.018062803894281387,
        -0.040575675666332245,
        0.027113089337944984,
        0.05353827029466629,
        -0.05679413676261902,
        -0.011083327233791351,
        0.042168326675891876,
        -0.055652715265750885,
        0.00020729625248350203,
        0.05634691193699837,
        0.05637163668870926,
        0.037115827202796936,
        -0.053986385464668274,
        -0.018883991986513138,
        0.02894107811152935,
        -0.04324286803603172,
        -0.05572572350502014,
        0.042907338589429855,
        -0.032564420253038406,
        0.035395268350839615,
        0.049677927047014236,
        0.006320655811578035,
        0.010720046237111092,
        0.05553677678108215,
        0.053063955157995224,
        0.05609894543886185,
        -0.056084103882312775,
        -0.029660804197192192,
        -0.05527568981051445,
        -0.03015030547976494,
        -0.051794249564409256,
        -0.05382285639643669,
        -0.05672530084848404,
        0.04311516880989075,
        0.004841668996959925,
        -0.0481945276260376,
        0.03260324150323868,
        -0.05379456654191017,
        -0.053329113870859146,
        0.05668799206614494,
        0.05641031265258789,
        0.056747160851955414,
        -0.04051421210169792,
        0.05527161434292793,
        0.05676967650651932,
        -0.031213676556944847,
        -0.012891926802694798,
        0.05417666956782341,
        -0.05259973183274269,
        0.03531663864850998,
        0.03208937495946884,
        -0.0563589371740818,
        0.05378571152687073,
        -0.05500716716051102,
        0.024830445647239685,
        0.05624616518616676,
        0.04846718907356262,
        0.05650471895933151,
        0.025619344785809517,
        0.05627688020467758,
        -0.05636439844965935,
        -0.056772630661726,
        0.0568033866584301,
        -0.014703125692903996,
        0.025329850614070892,
        0.019476085901260376,
        -0.022433320060372353,
        -0.0026068086735904217,
        0.05413290113210678,
        -0.012676145881414413,
        -0.054173242300748825,
        -0.048939049243927,
        0.012679136358201504,
        -0.042606499046087265,
        0.0430946871638298,
        -0.04711250215768814,
        -0.05511261895298958,
        -0.048899296671152115,
        -0.0568046048283577,
        -0.017952552065253258,
        0.055852070450782776,
        0.036015551537275314,
        -0.027550484985113144,
        0.019165854901075363,
        -0.05380071699619293,
        -0.04433031380176544,
        0.030148914083838463,
        -0.0031649009324610233,
        0.05162643641233444,
        -0.05409635230898857,
        0.0559983029961586,
        -0.046521980315446854,
        0.034144796431064606,
        0.05448557063937187,
        0.05676790699362755,
        -0.026052718982100487,
        -0.026609955355525017,
        0.0022749551571905613,
        0.042810384184122086,
        0.05677862465381622,
        -0.04754554480314255,
        0.0272208321839571,
        0.02135549858212471,
        0.0521903894841671,
        -0.004284063819795847,
        0.05319322273135185,
        0.050513241440057755,
        -0.008547327481210232,
        -0.05553344264626503,
        -0.056557796895504,
        -0.03623330220580101,
        -0.056465987116098404,
        0.0205522570759058,
        0.05005534738302231,
        0.04091712832450867,
        0.013797799125313759,
        0.05601295456290245,
        0.050215303897857666,
        -0.05483612045645714,
        0.015521552413702011,
        0.05665081739425659,
        -0.008376982063055038,
        0.039376888424158096,
        -0.05531405285000801,
        -0.05547548085451126,
        0.03435858339071274,
        -0.05622151866555214,
        -0.05203421041369438,
        -0.05595950782299042,
        -0.03145752474665642,
        0.048527151346206665,
        -0.05545687675476074,
        -0.03002696856856346,
        -0.05654070898890495,
        -0.04929158464074135,
        -0.03364706039428711,
        -0.04219110682606697,
        0.023852482438087463,
        0.05635211244225502,
        0.048444196581840515,
        0.03319614753127098,
        0.05444313958287239,
        -0.056211262941360474,
        0.023448685184121132,
        0.004799114540219307,
        -0.04256261885166168,
        0.05606066435575485,
        0.05544731020927429,
        0.05216827243566513,
        -0.031076377257704735,
        -0.051070183515548706,
        -0.023501427844166756,
        -0.05618973821401596,
        0.056536413729190826,
        -0.05614997074007988,
        0.01777130737900734,
        0.041973039507865906,
        -0.04531251639127731,
        0.05468336492776871,
        0.05446014553308487,
        0.016654122620821,
        0.05553535744547844,
        0.056388720870018005,
        -0.027745461091399193,
        0.003899570554494858,
        0.01455027423799038,
        0.035206809639930725,
        0.05140240117907524,
        0.05646831542253494,
        -0.031643785536289215,
        0.05279163271188736,
        -0.05539478734135628,
        0.01802641712129116,
        -0.04945613443851471,
        -0.05275663733482361,
        0.05311888828873634,
        -0.05128199979662895,
        0.040900830179452896,
        -0.05248459428548813,
        -0.050206512212753296,
        0.02739732526242733,
        -0.03537100553512573,
        -0.046361081302165985,
        -0.02296890877187252,
        0.03583577647805214,
        -0.04024453088641167,
        -0.015543162822723389,
        -0.05475657060742378,
        0.051054079085588455,
        -0.05625832825899124,
        0.043882548809051514,
        0.05612901970744133,
        -0.05262569710612297,
        0.05195026472210884,
        -0.047392167150974274,
        -0.03604620695114136,
        -0.05454401671886444,
        0.013134164735674858,
        -0.05211079493165016,
        0.04713931307196617,
        0.0412360318005085,
        0.053735386580228806,
        0.000022545045794686303,
        -0.02746226079761982,
        -0.05477697774767876,
        0.05669267475605011,
        -0.02893167920410633,
        0.05361226946115494,
        -0.05213818699121475,
        0.026943301782011986,
        0.023102521896362305,
        -0.025994328781962395,
        0.0007682182476855814,
        0.05410722270607948,
        -0.02539880760014057,
        0.05231036990880966,
        -0.04845722019672394,
        0.05562135577201843,
        -0.03944440931081772,
        -0.045643482357263565,
        0.0565526969730854,
        -0.05554443225264549,
        0.02165209874510765,
        -0.02951512672007084,
        -0.0556570403277874,
        0.025840487331151962,
        0.05286069214344025,
        0.008647321723401546,
        0.04046809300780296,
        0.01109981071203947,
        0.013038617558777332,
        0.05657785013318062,
        0.03612934425473213,
        0.004361292812973261,
        -0.056712016463279724,
        -0.05365367606282234,
        -0.005351174157112837,
        -0.05620335042476654,
        -0.0562676377594471,
        -0.05548608675599098,
        0.030104028061032295,
        0.05367860198020935,
        0.05539971590042114,
        0.040592554956674576,
        -0.05631858482956886,
        -0.052974969148635864,
        -0.05593166872859001,
        0.056770384311676025,
        0.04094412550330162,
        0.04428904876112938,
        -0.05648631229996681,
        -0.044813670217990875,
        -0.03804995119571686,
        0.020729556679725647,
        -0.05475495010614395,
        0.05561215803027153,
        0.04283025860786438,
        0.047857679426670074,
        0.04474513977766037,
        0.05648627504706383,
        0.018119679763913155,
        -0.05457180365920067,
        0.05536673963069916,
        -0.03227259963750839,
        0.014370753429830074,
        0.054009810090065,
        -0.05303044989705086,
        0.05525416135787964,
        0.023823358118534088,
        0.011838157661259174,
        -0.046353016048669815,
        -0.056677863001823425,
        0.05049816891551018,
        -0.05340354144573212,
        -0.054932061582803726,
        0.056611813604831696,
        0.0524611696600914,
        0.022386586293578148,
        -0.056576039642095566,
        -0.05611826479434967,
        -0.04984469339251518,
        -0.05535702779889107,
        0.05530158802866936,
        0.03742090240120888,
        -0.05357751250267029,
        -0.037091679871082306,
        0.05368058755993843,
        0.04786841571331024,
        -0.0048685031943023205,
        0.055206675082445145,
        0.04259723052382469,
        0.007240943610668182,
        -0.05627235397696495,
        -0.04514239728450775,
        -0.019099224358797073,
        0.05578282102942467,
        -0.05622846260666847,
        0.05236335098743439,
        -0.05143842473626137,
        0.04790876433253288,
        -0.03170410171151161,
        -0.047860872000455856,
        -0.01838461309671402,
        -0.05640512332320213,
        -0.056707851588726044,
        -0.0013528517447412014,
        -0.0012663666857406497,
        -0.04569040983915329,
        0.05628499761223793,
        -0.03157814219594002,
        -0.055035583674907684,
        -0.050151508301496506,
        0.04523622989654541,
        -0.05490632727742195,
        -0.02407045103609562,
        -0.05054198205471039,
        -0.05346923694014549,
        -0.03111208789050579,
        0.05101130157709122,
        0.024664686992764473,
        -0.05509047955274582,
        0.045515093952417374,
        0.04941762983798981,
        0.05295923352241516,
        0.04340938478708267,
        0.05206023156642914,
        0.020254982635378838,
        0.052315227687358856,
        -0.05590902268886566,
        0.04574320465326309,
        0.034189678728580475,
        -0.04711856320500374,
        0.049010660499334335,
        0.05467844381928444,
        0.040833886712789536,
        0.02965286187827587,
        -0.04406872019171715,
        0.005083561409264803,
        -0.05515040084719658,
        0.02700851298868656,
        0.0564948208630085,
        0.056089386343955994,
        -0.056067682802677155,
        -0.030547622591257095,
        -0.030641663819551468,
        0.05610456317663193,
        0.05508823320269585,
        0.030455026775598526,
        0.05675002932548523,
        0.029968563467264175,
        -0.054022107273340225,
        0.0058054011315107346,
        0.05288640782237053,
        0.056041277945041656,
        0.05046234279870987,
        -0.055629830807447433,
        0.05059828981757164,
        -0.004202350974082947,
        0.0075251138769090176,
        0.01745760440826416,
        -0.017587613314390182,
        0.013847543857991695,
        0.05379128456115723,
        0.05606531351804733,
        -0.004430301953107119,
        -0.04531927779316902,
        0.04932834208011627,
        0.053174298256635666,
        0.052327655255794525,
        -0.05562999099493027,
        -0.05577521398663521,
        0.03768477961421013,
        -0.05674665793776512,
        0.01651519350707531,
        -0.05559379979968071,
        -0.05592581629753113,
        -0.001863988465629518,
        0.0455438606441021,
        -0.043250855058431625,
        0.009069220162928104,
        0.031085962429642677,
        -0.0558391772210598,
        -0.034379877150058746,
        0.02280723676085472,
        -0.05555040389299393,
        0.011546638794243336,
        0.028879452496767044,
        0.05679332837462425,
        -0.05187876150012016,
        0.01734047941863537,
        -0.051220547407865524,
        -0.05507417395710945,
        0.031467825174331665
    ],
    [
        -0.06001783534884453,
        0.05055027827620506,
        0.0426771342754364,
        -0.010597421787679195,
        0.051132868975400925,
        0.053926073014736176,
        0.06181655824184418,
        -0.050133053213357925,
        -0.04586581885814667,
        0.04577705264091492,
        0.05815455690026283,
        0.015604901127517223,
        -0.05213598906993866,
        -0.022266115993261337,
        -0.060032304376363754,
        0.046650491654872894,
        -0.01766080968081951,
        0.058928996324539185,
        -0.0009929212974384427,
        0.03660163655877113,
        -0.03326994925737381,
        0.04657343775033951,
        0.006489135790616274,
        0.04966127127408981,
        0.022635990753769875,
        0.03730589896440506,
        0.05459531769156456,
        -0.0005540980491787195,
        -0.0036276718601584435,
        -0.04066472500562668,
        0.028170328587293625,
        -0.05701811984181404,
        0.03524886071681976,
        -0.06193648278713226,
        0.04268315061926842,
        -0.0037658170331269503,
        -0.0532749705016613,
        -0.061125822365283966,
        -0.05979170650243759,
        0.0003244290710426867,
        0.03327787294983864,
        0.019450804218649864,
        0.05922241136431694,
        -0.035976774990558624,
        0.02996845915913582,
        0.025429384782910347,
        -0.008242201991379261,
        0.05338777229189873,
        0.06020406261086464,
        -0.0062063029035925865,
        0.0592225007712841,
        -0.016349541023373604,
        0.0555114671587944,
        -0.0594179630279541,
        -0.022648947313427925,
        -0.0019272654317319393,
        -0.05354941636323929,
        -0.02590297721326351,
        -0.05975260213017464,
        -0.02704504318535328,
        -0.0588129386305809,
        -0.017282484099268913,
        -0.05794752761721611,
        -0.04769684001803398,
        0.009146095253527164,
        0.05243618041276932,
        0.04464343935251236,
        -0.05707378685474396,
        0.048391055315732956,
        -0.0619671531021595,
        -0.05979832634329796,
        -0.06195547431707382,
        0.027627289295196533,
        -0.009851095266640186,
        -0.04009396582841873,
        -0.04113906994462013,
        0.05080974102020264,
        0.06144576892256737,
        -0.009452505968511105,
        -0.03539719805121422,
        -0.023719869554042816,
        -0.039369214326143265,
        -0.05840953066945076,
        0.04587378352880478,
        -0.017960544675588608,
        0.01971694454550743,
        0.0023596370592713356,
        -0.0493808314204216,
        0.05967668071389198,
        0.051441822201013565,
        -0.04876285418868065,
        0.01618783175945282,
        0.02083701267838478,
        -0.04497164860367775,
        -0.020623382180929184,
        0.008292547427117825,
        -0.0062290639616549015,
        -0.05243274196982384,
        0.00036456360248848796,
        0.015823349356651306,
        -0.061231281608343124,
        -0.02517562359571457,
        0.054398078471422195,
        0.05420197173953056,
        -0.05193811655044556,
        -0.048917558044195175,
        0.046176958829164505,
        0.025188550353050232,
        -0.0618886835873127,
        0.02443675696849823,
        -0.02482113614678383,
        0.007569991517812014,
        -0.05922885984182358,
        -0.05565524473786354,
        0.05968507006764412,
        -0.041017189621925354,
        0.012076285667717457,
        -0.05424761027097702,
        0.05306863412261009,
        0.04086340218782425,
        -0.04435871168971062,
        0.05520244687795639,
        -0.04504598677158356,
        -0.031148642301559448,
        -0.06144636496901512,
        -0.0008885810384526849,
        -0.05055033043026924,
        0.05865383893251419,
        0.052510976791381836,
        -0.010938272811472416,
        -0.00010019484034273773,
        -0.05398150160908699,
        -0.043533436954021454,
        0.05650010704994202,
        -0.05813515558838844,
        -0.05577033758163452,
        0.052884820848703384,
        -0.058560583740472794,
        0.00809269305318594,
        0.059851229190826416,
        0.05522045120596886,
        -0.0067536914721131325,
        0.05195331946015358,
        0.05054390802979469,
        0.012571258470416069,
        0.044363174587488174,
        0.054510436952114105,
        -0.05911409854888916,
        0.012460771948099136,
        0.05149204656481743,
        -0.01367508340626955,
        -0.059594281017780304,
        -0.059062354266643524,
        -0.006999288219958544,
        0.06187117099761963,
        -0.057620078325271606,
        0.06125173345208168,
        0.017868446186184883,
        -0.05634896084666252,
        0.045769233256578445,
        0.028614968061447144,
        0.04304393753409386,
        0.05167403072118759,
        -0.0054470994509756565,
        -0.05912848562002182,
        -0.0346873439848423,
        0.03567063808441162,
        -0.047300416976213455,
        -0.0447729155421257,
        0.04975677281618118,
        -0.03759144991636276,
        -0.021681858226656914,
        -0.06050066649913788,
        0.036092568188905716,
        -0.04968377947807312,
        0.058942392468452454,
        -0.060613393783569336,
        0.051436346024274826,
        0.04940180480480194,
        -0.03961101919412613,
        0.0014951069606468081,
        -0.013101719319820404,
        0.06051439791917801,
        0.01026945374906063,
        0.010856617242097855,
        0.028280053287744522,
        -0.0030742560047656298,
        0.06110703572630882,
        -0.057425357401371,
        -0.059556372463703156,
        -0.053525153547525406,
        -0.016016095876693726,
        -0.0534156933426857,
        -0.054764214903116226,
        -0.002462912816554308,
        0.02920200303196907,
        0.04101494699716568,
        0.048016175627708435,
        -0.055615779012441635,
        -0.017799366265535355,
        0.0035901437513530254,
        0.0018469416536390781,
        0.03256523981690407,
        -0.040557947009801865,
        0.010171514004468918,
        -0.025483161211013794,
        0.02484224922955036,
        -0.05028261989355087,
        -0.003921777009963989,
        -0.005889541003853083,
        -0.05331692844629288,
        0.05861759930849075,
        -0.05437769368290901,
        0.021447118371725082,
        -0.022022254765033722,
        0.019064920023083687,
        0.04693617299199104,
        0.01562575437128544,
        0.06198715418577194,
        0.00008467064617434517,
        0.05511084198951721,
        -0.03335519880056381,
        -0.010991131886839867,
        -0.05970281362533569,
        0.05982832983136177,
        -0.06039316579699516,
        0.0141112320125103,
        0.018186818808317184,
        0.017318446189165115,
        -0.06064113974571228,
        0.046178482472896576,
        -0.06059763580560684,
        -0.04773909971117973,
        0.05416572466492653,
        -0.00832234974950552,
        0.02874186262488365,
        0.06040365248918533,
        0.0327502004802227,
        0.05947808921337128,
        0.05887843668460846,
        0.030599655583500862,
        0.05200939625501633,
        0.025830864906311035,
        -0.022475993260741234,
        -0.013019036501646042,
        0.00369061715900898,
        0.028437919914722443,
        0.016543110832571983,
        -0.0550561249256134,
        -0.060283347964286804,
        -0.04559796303510666,
        -0.058387525379657745,
        0.020753098651766777,
        -0.057965174317359924,
        0.023535341024398804,
        0.06127338483929634,
        -0.03583147004246712,
        -0.038257304579019547,
        0.061924371868371964,
        0.06023581698536873,
        0.005472746677696705,
        0.06036076694726944,
        0.044510502368211746,
        0.05155899375677109,
        -0.0518561489880085,
        0.06033465266227722,
        0.005245631095021963,
        0.056983791291713715,
        0.035132329910993576,
        -0.04729500040411949,
        -0.053577836602926254,
        -0.06036071106791496,
        0.04826056584715843,
        -0.04999328777194023,
        -0.03744612634181976,
        0.006559939589351416,
        -0.0603005476295948,
        0.0594385489821434,
        -0.060173433274030685,
        -0.05947422981262207,
        -0.06193425878882408,
        -0.04466782137751579,
        0.05776817724108696,
        -0.009187768213450909,
        -0.058377139270305634,
        -0.05572078377008438,
        0.037489596754312515,
        -0.04406889155507088,
        -0.05468295142054558,
        -0.04680934548377991,
        -0.023089434951543808,
        0.0574057400226593,
        -0.045919135212898254,
        0.00017357846081722528,
        0.0395871102809906,
        -0.060961827635765076,
        0.061035074293613434,
        0.058351483196020126,
        -0.044021137058734894,
        0.05460670590400696,
        -0.045900940895080566,
        0.0568411648273468,
        -0.028745613992214203,
        0.06001536175608635,
        0.020504925400018692,
        -0.00782594084739685,
        -0.05833869427442551,
        0.05419295281171799,
        0.058597274124622345,
        -0.05889569967985153,
        0.053021062165498734,
        0.03355283662676811,
        -0.02202807180583477,
        0.06058463826775551,
        -0.06075608730316162,
        -0.05800767242908478,
        0.008434177376329899,
        -0.0028426526114344597,
        -0.00501722889021039,
        -0.06014542654156685,
        -0.013192676939070225,
        0.00445376755669713,
        0.05124187469482422,
        0.028233438730239868,
        0.06050800904631615,
        0.0029994859360158443,
        0.03450581431388855,
        -0.056455738842487335,
        0.033004432916641235,
        0.0014478095108643174,
        0.03801942616701126,
        -0.00187789392657578,
        -0.0014818216441199183,
        -0.003403827315196395,
        0.05879639461636543,
        0.04402230679988861,
        -0.053371600806713104,
        0.060465943068265915,
        -0.044354844838380814,
        0.06150335073471069,
        0.033729732036590576,
        -0.02333471179008484,
        -0.0053827459923923016,
        0.006391612812876701,
        0.06201035529375076,
        0.04678596928715706,
        0.04748275876045227,
        0.040050674229860306,
        0.017037883400917053,
        0.060482461005449295,
        -0.05613294243812561,
        0.02184794470667839,
        -0.04401775822043419,
        -0.06027420982718468,
        -0.023842377588152885,
        0.05721781775355339,
        -0.06012876331806183,
        -0.024178609251976013,
        0.05035146325826645,
        -0.060332681983709335,
        0.049486201256513596,
        0.014853530563414097,
        -0.057917509227991104,
        -0.05741375312209129,
        0.05823829397559166,
        -0.048727843910455704,
        0.06183314695954323,
        0.028425298631191254,
        0.05264091119170189,
        -0.06197647750377655,
        -0.022320333868265152,
        -0.06003735214471817,
        0.06193070113658905,
        0.042730554938316345,
        0.05621923878788948,
        0.06189076602458954,
        0.042471930384635925,
        0.05838581547141075,
        -0.03661978617310524,
        -0.0386260561645031,
        -0.047121718525886536,
        0.009193952195346355,
        -0.060881439596414566,
        -0.028319356963038445,
        0.06030702590942383,
        0.016068127006292343,
        0.0436328761279583,
        -0.06124405190348625,
        -0.058622099459171295,
        -0.04602691903710365,
        -0.06103374809026718,
        -0.028509706258773804,
        -0.019995424896478653,
        -0.048279136419296265,
        -0.05261696130037308,
        0.06082303449511528,
        0.059463098645210266,
        0.03429746627807617,
        -0.05080032721161842,
        -0.06152081862092018,
        -0.04161519184708595,
        0.05458105728030205,
        0.011745911091566086,
        0.06129352003335953,
        -0.054449278861284256,
        0.007755294907838106,
        0.0376361645758152,
        -0.02783154509961605,
        0.051502775400877,
        -0.05250050127506256,
        0.03541785478591919,
        -0.055940598249435425,
        -0.015233602374792099,
        0.04129479452967644,
        0.03504159674048424,
        -0.02197064645588398,
        -0.027691854164004326,
        0.006036750040948391,
        0.03706023469567299,
        -0.014452350325882435,
        -0.04933992400765419,
        -0.060581546276807785,
        -0.04810177534818649,
        -0.06099362298846245,
        0.056849028915166855,
        -0.050458431243896484,
        0.003750213887542486,
        -0.03212161734700203,
        -0.05674931779503822,
        -0.05128633975982666,
        0.05995134636759758,
        0.030066870152950287,
        -0.04053010419011116,
        -0.05009658262133598,
        0.0452527292072773,
        -0.05896814540028572,
        -0.058035075664520264,
        0.032765522599220276,
        0.01396290771663189,
        -0.036331627517938614,
        0.058968063443899155,
        0.05978520214557648,
        0.018234847113490105,
        0.061215512454509735,
        0.009707720018923283,
        -0.007411794271320105,
        0.06059396266937256,
        -0.024855945259332657,
        0.06051594763994217,
        0.0008385625551454723,
        -0.0497111976146698,
        0.037403661757707596,
        0.06127151474356651,
        -0.012851782143115997,
        -0.041286829859018326,
        -0.05340249836444855,
        0.005013805814087391,
        -0.021962212398648262,
        -0.05795483663678169,
        0.05536768212914467,
        -0.0021306013222783804,
        -0.00362612446770072,
        -0.054679714143276215,
        0.04445160552859306,
        0.060786064714193344,
        0.06168580800294876,
        -0.061671558767557144,
        0.05893992260098457,
        -0.025342848151922226,
        -0.061390750110149384,
        0.06161230057477951,
        0.03535984456539154,
        0.021737221628427505,
        0.043371524661779404,
        -0.03278016299009323,
        0.031320054084062576,
        0.011736867018043995,
        0.015568135306239128,
        -0.05927561596035957,
        -0.043488044291734695,
        0.057059694081544876,
        -0.05964948236942291,
        -0.05758434534072876,
        0.04023388773202896,
        -0.005730259232223034,
        0.0076309684664011,
        0.06118306890130043,
        0.010848689824342728,
        -0.05733408406376839,
        -0.01621718518435955,
        -0.004652895964682102,
        -0.02596285380423069,
        0.03780290111899376,
        0.008947217836976051,
        0.050561752170324326,
        -0.002962603699415922,
        0.022083263844251633,
        -0.04233396798372269,
        0.05947782099246979,
        0.060831308364868164,
        -0.03231487050652504,
        -0.051710233092308044,
        -0.046480271965265274,
        0.01664179563522339,
        -0.05393405631184578,
        -0.02648799493908882,
        -0.015635857358574867,
        0.0015641356585547328,
        -0.05808986723423004,
        -0.04374098777770996,
        0.054946254938840866,
        0.05563660338521004
    ],
    [
        0.05505165457725525,
        0.05053267627954483,
        -0.04664001613855362,
        0.016639476642012596,
        0.04907901957631111,
        0.029022229835391045,
        0.05736244097352028,
        -0.029216619208455086,
        0.04661818593740463,
        -0.05993495509028435,
        0.04760509356856346,
        0.05963679403066635,
        -0.059441469609737396,
        0.05310119688510895,
        -0.05939187854528427,
        0.04963940009474754,
        -0.044014863669872284,
        0.05424274504184723,
        -0.05903175100684166,
        -0.04940773546695709,
        0.00876691285520792,
        0.046807289123535156,
        0.059741560369729996,
        -0.02042723074555397,
        -0.038417767733335495,
        -0.04681231081485748,
        0.04326457530260086,
        0.05647364258766174,
        -0.04088137671351433,
        0.0593450702726841,
        0.048288047313690186,
        -0.03640546277165413,
        0.016870228573679924,
        -0.05885010585188866,
        -0.014652566984295845,
        -0.027315884828567505,
        -0.05173259228467941,
        -0.054904382675886154,
        -0.013121167197823524,
        0.029639018699526787,
        0.0513295903801918,
        0.009905988350510597,
        0.05172231048345566,
        -0.03895854577422142,
        0.05754520371556282,
        0.0065200976096093655,
        0.05503498390316963,
        0.05925314500927925,
        0.05989443510770798,
        -0.05948804318904877,
        0.05924774706363678,
        0.03948022797703743,
        0.05904736369848251,
        -0.002808652352541685,
        0.05619259923696518,
        -0.026972243562340736,
        -0.03933129087090492,
        -0.033582061529159546,
        -0.05755442753434181,
        0.055173780769109726,
        -0.057238541543483734,
        -0.037857260555028915,
        -0.05408806353807449,
        -0.04318838566541672,
        -0.02645835280418396,
        0.03125670179724693,
        0.01607821136713028,
        -0.028588512912392616,
        -0.05915195122361183,
        -0.059961412101984024,
        -0.05859614536166191,
        -0.05952205881476402,
        0.053563181310892105,
        0.01587127149105072,
        -0.02886894904077053,
        -0.014471635222434998,
        0.05641147494316101,
        0.05973898246884346,
        -0.02988455630838871,
        -0.04875155910849571,
        -0.028767351061105728,
        0.05431557446718216,
        -0.04669387638568878,
        0.04918638989329338,
        0.05910022184252739,
        0.05141713097691536,
        -0.04703075438737869,
        0.029057517647743225,
        -0.05689449608325958,
        0.05323614180088043,
        -0.059005364775657654,
        0.025406181812286377,
        -0.059450164437294006,
        -0.05775019899010658,
        -0.03612378239631653,
        -0.02244463749229908,
        0.00851563923060894,
        -0.058118585497140884,
        -0.019882582128047943,
        0.04307663440704346,
        -0.0598941333591938,
        0.0011425006669014692,
        0.05722799152135849,
        0.05743971839547157,
        -0.0296163447201252,
        -0.05075987055897713,
        0.057753514498472214,
        -0.009745180606842041,
        -0.053849536925554276,
        0.007991782389581203,
        -0.05850567668676376,
        -0.022220710292458534,
        -0.041021011769771576,
        -0.032500166445970535,
        0.021156897768378258,
        -0.033676713705062866,
        0.058835893869400024,
        -0.059210989624261856,
        -0.04322470724582672,
        -0.013886084780097008,
        0.027843741700053215,
        0.026512807235121727,
        -0.03181900084018707,
        -0.04264933988451958,
        -0.05949486792087555,
        0.03878659009933472,
        -0.021214177832007408,
        0.03481180593371391,
        0.05281124636530876,
        0.047632671892642975,
        0.04855036363005638,
        -0.05418871343135834,
        -0.000745448109228164,
        0.0009316782816313207,
        -0.058179859071969986,
        -0.05328194424510002,
        0.05860873684287071,
        0.04644017666578293,
        -0.04958995059132576,
        0.051030483096838,
        0.03163337707519531,
        0.05996900796890259,
        0.05007489025592804,
        -0.05991123244166374,
        0.0393645316362381,
        0.035813964903354645,
        0.05758201330900192,
        -0.0030844626016914845,
        0.05793461948633194,
        0.05998801440000534,
        0.034219641238451004,
        -0.05894524231553078,
        -0.051874518394470215,
        0.024515731260180473,
        -0.002006371272727847,
        -0.04745319485664368,
        0.05699951946735382,
        0.0006408376502804458,
        -0.05549827590584755,
        0.019908905029296875,
        0.05637582391500473,
        -0.0014634510735049844,
        0.04597367346286774,
        -0.00957236997783184,
        0.03199959918856621,
        0.017023805528879166,
        0.022750936448574066,
        -0.029723094776272774,
        0.04894823580980301,
        0.050363462418317795,
        0.03020840510725975,
        -0.019414005801081657,
        -0.056415408849716187,
        -0.03454746678471565,
        -0.02341141737997532,
        0.05664369463920593,
        -0.060010842978954315,
        0.052917320281267166,
        0.05246130749583244,
        -0.02709045633673668,
        -0.021280750632286072,
        0.018321504816412926,
        0.05809260159730911,
        0.05663072690367699,
        0.04245555400848389,
        -0.04512674733996391,
        -0.020302772521972656,
        0.053770627826452255,
        -0.005195594392716885,
        0.05022898688912392,
        0.012674830853939056,
        -0.04704197496175766,
        -0.030121389776468277,
        -0.04908008500933647,
        -0.052611563354730606,
        -0.051162295043468475,
        0.03809771686792374,
        -0.014242475852370262,
        -0.03559616953134537,
        0.016995349898934364,
        0.05509652569890022,
        -0.056059155613183975,
        -0.056127674877643585,
        -0.008655089884996414,
        0.01120389997959137,
        0.05856405571103096,
        0.00552832568064332,
        0.0442887544631958,
        -0.00582953030243516,
        -0.04950913041830063,
        -0.059184227138757706,
        0.037592705339193344,
        0.03014024905860424,
        -0.037091467529535294,
        -0.058700188994407654,
        -0.006226568017154932,
        0.05995837599039078,
        0.04540766030550003,
        0.05937476456165314,
        -0.05859646573662758,
        -0.0017242429312318563,
        -0.02430407702922821,
        -0.03021969459950924,
        -0.05376533418893814,
        0.05962516367435455,
        -0.035237230360507965,
        -0.016330039128661156,
        0.011406123638153076,
        -0.03518205136060715,
        -0.027886932715773582,
        0.037779875099658966,
        -0.058436013758182526,
        -0.050520159304142,
        0.05766776204109192,
        0.002369956113398075,
        0.030005324631929398,
        0.058405764400959015,
        -0.018072983250021935,
        0.007831329479813576,
        0.058916956186294556,
        0.051572930067777634,
        0.05985241010785103,
        -0.032054364681243896,
        0.05962535738945007,
        0.018926657736301422,
        -0.0015148586826398969,
        0.04780065268278122,
        0.023761512711644173,
        -0.013032706454396248,
        0.040196314454078674,
        -0.04894139617681503,
        -0.054227665066719055,
        -0.05609579011797905,
        -0.051335737109184265,
        0.04535749554634094,
        0.05315021425485611,
        -0.058400336652994156,
        -0.058125320822000504,
        0.05874202027916908,
        0.013244354166090488,
        0.043384965509176254,
        0.05913008004426956,
        -0.002106187166646123,
        0.03115304745733738,
        0.05163310095667839,
        0.031085873022675514,
        0.05976499617099762,
        0.05913601070642471,
        0.04454834759235382,
        -0.04010879248380661,
        -0.022060027346014977,
        -0.05960361659526825,
        0.052586253732442856,
        -0.05953577160835266,
        0.043856747448444366,
        0.033040519803762436,
        -0.058713000267744064,
        0.0171899925917387,
        -0.059754159301519394,
        0.03547467291355133,
        -0.05940258502960205,
        0.023714853450655937,
        -0.036816611886024475,
        0.05782179534435272,
        0.05975876376032829,
        -0.03982793912291527,
        0.008479276672005653,
        -0.03687829524278641,
        0.057273127138614655,
        0.05719086900353432,
        -0.02651195600628853,
        0.059404727071523666,
        -0.056634388864040375,
        -0.004880314227193594,
        0.05276438593864441,
        -0.04341087490320206,
        0.05991721898317337,
        -0.007571679074317217,
        -0.05234077200293541,
        0.05777005851268768,
        -0.018855761736631393,
        0.05315525829792023,
        -0.03309972584247589,
        -0.056752514094114304,
        -0.02407134510576725,
        -0.04192107543349266,
        -0.059381090104579926,
        0.012363250367343426,
        0.02510431595146656,
        -0.049530912190675735,
        0.05584670603275299,
        -0.05874071270227432,
        -0.044049132615327835,
        0.0578390471637249,
        -0.056339383125305176,
        -0.05742156505584717,
        0.014889017678797245,
        0.01511913351714611,
        0.03456653654575348,
        -0.04651714861392975,
        -0.05270230397582054,
        0.023245003074407578,
        0.05861404538154602,
        0.02165924198925495,
        0.05673319473862648,
        0.025109270587563515,
        -0.024628426879644394,
        0.05367529019713402,
        -0.020405644550919533,
        -0.04680844768881798,
        -0.02603689208626747,
        0.04403969272971153,
        0.0015474094543606043,
        -0.058543168008327484,
        0.057836879044771194,
        0.01900477707386017,
        -0.05849217250943184,
        0.0485580675303936,
        -0.02647491730749607,
        -0.004664966370910406,
        0.02673978917300701,
        0.026934565976262093,
        -0.0598088763654232,
        0.055778682231903076,
        0.05959000438451767,
        0.05161111801862717,
        0.0427311472594738,
        0.04751995950937271,
        0.033319223672151566,
        0.0565168596804142,
        -0.04809734225273132,
        -0.013210115022957325,
        -0.044232796877622604,
        -0.05886093154549599,
        0.04202151671051979,
        0.0591513030230999,
        -0.03659607097506523,
        -0.037159767001867294,
        0.0050156209617853165,
        -0.014223064295947552,
        0.05617247521877289,
        0.04435853287577629,
        -0.05034954100847244,
        -0.04328025504946709,
        -0.03699614852666855,
        -0.05677530914545059,
        0.05984257906675339,
        0.006679827347397804,
        0.05825304612517357,
        -0.05995411425828934,
        -0.047485046088695526,
        -0.04048323631286621,
        0.05536792427301407,
        0.031777895987033844,
        0.05891477316617966,
        -0.0018626857781782746,
        -0.05356631800532341,
        0.05518785119056702,
        0.04180997610092163,
        -0.058603961020708084,
        -0.007342740893363953,
        -0.029306184500455856,
        -0.059487007558345795,
        0.027810420840978622,
        0.059684719890356064,
        -0.049463532865047455,
        0.05458611622452736,
        -0.056113749742507935,
        -0.0548965260386467,
        -0.05767719820141792,
        -0.04123026877641678,
        0.02253560721874237,
        0.029724908992648125,
        0.04465014860033989,
        -0.0290940310806036,
        0.0580575093626976,
        0.05985010042786598,
        -0.006580575369298458,
        -0.03706842288374901,
        -0.05924299731850624,
        -0.05480878800153732,
        0.05090687423944473,
        0.026044290512800217,
        -0.0018639616901054978,
        0.0572262667119503,
        0.05495130270719528,
        0.05642346665263176,
        -0.04757311940193176,
        0.057199183851480484,
        -0.022726193070411682,
        0.04425982013344765,
        0.005784966982901096,
        0.010732698254287243,
        -0.02678205817937851,
        0.05564063787460327,
        0.024386076256632805,
        0.01735006831586361,
        0.05740729719400406,
        -0.015452592633664608,
        0.013828417286276817,
        -0.05667239427566528,
        0.05902950093150139,
        -0.042384784668684006,
        -0.058735836297273636,
        0.034186672419309616,
        0.038669221103191376,
        -0.05884115397930145,
        -0.0013736033579334617,
        -0.050234489142894745,
        0.006448757369071245,
        0.05990011617541313,
        -0.037122007459402084,
        0.0010168731678277254,
        0.04638918116688728,
        -0.021711567416787148,
        -0.038659416139125824,
        -0.049995094537734985,
        0.0006739149685017765,
        0.01670842431485653,
        -0.05910563841462135,
        -0.0007173237390816212,
        0.05174843221902847,
        0.026258332654833794,
        0.04585868492722511,
        -0.03747766092419624,
        0.024437738582491875,
        0.058886345475912094,
        -0.011084782890975475,
        0.0522407703101635,
        0.01603134348988533,
        -0.05857338756322861,
        0.03372268006205559,
        0.04804673790931702,
        -0.012860638089478016,
        -0.028517693281173706,
        -0.05922644957900047,
        -0.0010797150898724794,
        0.042161598801612854,
        -0.04524270445108414,
        0.049290549010038376,
        0.04325642064213753,
        0.049642592668533325,
        -0.059682127088308334,
        0.0470244437456131,
        0.05765692517161369,
        0.05919972062110901,
        -0.05993937700986862,
        0.059621721506118774,
        -0.05710034444928169,
        -0.059093914926052094,
        0.05721200257539749,
        0.05905781686306,
        -0.0049690017476677895,
        0.05995425581932068,
        0.011877737939357758,
        0.008242204785346985,
        0.0035484458785504103,
        0.004428403917700052,
        -0.019513441249728203,
        -0.0564444400370121,
        -0.04711749777197838,
        -0.023440483957529068,
        -0.03501838073134422,
        0.05584808066487312,
        -0.026329398155212402,
        0.05612718686461449,
        0.05956977605819702,
        -0.03449722006917,
        -0.0558413490653038,
        -0.05109905079007149,
        0.04700041934847832,
        0.039809469133615494,
        0.03434662148356438,
        0.04387800768017769,
        0.0565677247941494,
        -0.006536765489727259,
        -0.01972324587404728,
        0.04891195148229599,
        0.058147601783275604,
        0.04780462384223938,
        -0.046635255217552185,
        -0.001231419388204813,
        -0.03304218873381615,
        -0.030196242034435272,
        0.05945733189582825,
        -0.028695663437247276,
        0.03901224210858345,
        -0.02378162182867527,
        -0.02505621314048767,
        -0.06001412868499756,
        0.058541189879179,
        0.059554483741521835
    ],
    [
        0.00636640377342701,
        -0.04512471333146095,
        -0.06282790750265121,
        0.049150653183460236,
        0.02094591036438942,
        0.011911004781723022,
        0.06248610466718674,
        0.05863188952207565,
        0.023422271013259888,
        0.025835901498794556,
        0.05686410516500473,
        0.061077944934368134,
        -0.04596559703350067,
        0.06388334929943085,
        -0.061124444007873535,
        -0.06201331689953804,
        -0.06254328787326813,
        -0.05039479210972786,
        -0.026093142107129097,
        0.0587586872279644,
        -0.012566465884447098,
        -0.055457793176174164,
        0.05216179043054581,
        0.0330636166036129,
        0.0644070953130722,
        0.035840898752212524,
        0.05149940401315689,
        0.054278645664453506,
        -0.04370086267590523,
        -0.06010667234659195,
        0.05355504900217056,
        0.049713537096977234,
        0.06006965413689613,
        -0.0581367164850235,
        0.004866675939410925,
        0.004131971392780542,
        -0.04975855350494385,
        0.02688157930970192,
        0.052241433411836624,
        0.009441801346838474,
        -0.04992207512259483,
        0.047326259315013885,
        0.05841191112995148,
        -0.06494627892971039,
        0.002818700158968568,
        0.012987649068236351,
        0.06335771828889847,
        0.0480952151119709,
        0.0625191181898117,
        0.011218716390430927,
        0.06338875740766525,
        0.014370025135576725,
        0.04971849545836449,
        -0.03992786630988121,
        -0.02205684408545494,
        0.018667835742235184,
        -0.051946066319942474,
        0.004280615597963333,
        0.048664793372154236,
        -0.046455904841423035,
        -0.06371603161096573,
        -0.005892603658139706,
        -0.032639726996421814,
        -0.027704600244760513,
        0.01604277454316616,
        0.054799675941467285,
        0.024589113891124725,
        -0.05531061813235283,
        0.021475741639733315,
        -0.05299464613199234,
        -0.05818372964859009,
        -0.05843646451830864,
        0.036427270621061325,
        0.05735857039690018,
        0.010862897150218487,
        0.033126767724752426,
        0.049532823264598846,
        0.05635635554790497,
        0.04906929284334183,
        -0.010196290910243988,
        -0.004139758180826902,
        -0.015168298035860062,
        -0.0621204674243927,
        0.05720500648021698,
        0.006692796479910612,
        -0.008932949975132942,
        -0.03289560228586197,
        0.036181021481752396,
        0.051047708839178085,
        0.054961398243904114,
        -0.05764506384730339,
        0.04353304207324982,
        -0.027796749025583267,
        -0.013701369985938072,
        -0.011520525440573692,
        -0.05363059043884277,
        -0.061977703124284744,
        -0.04917076602578163,
        -0.020658425986766815,
        -0.002300897380337119,
        -0.0603308342397213,
        0.05913357809185982,
        0.05768492817878723,
        0.05792535841464996,
        0.03709246218204498,
        -0.044524334371089935,
        -0.053794633597135544,
        0.03221704438328743,
        -0.05964623764157295,
        0.010645421221852303,
        -0.03625320643186569,
        -0.048682957887649536,
        -0.060696396976709366,
        -0.028233610093593597,
        0.043301694095134735,
        -0.06312824785709381,
        0.025684604421257973,
        -0.056924350559711456,
        -0.04657683148980141,
        -0.03139461576938629,
        0.03423193842172623,
        -0.05352773144841194,
        -0.0637340396642685,
        -0.016882074996829033,
        -0.06345564872026443,
        0.024764584377408028,
        -0.0027470828499644995,
        0.04230933263897896,
        0.05556472763419151,
        -0.03164631500840187,
        0.02121577039361,
        -0.01660764589905739,
        -0.041100505739450455,
        -0.01362235751003027,
        -0.05606884881854057,
        -0.04057736694812775,
        0.043812189251184464,
        -0.006513360422104597,
        -0.04930916428565979,
        0.0648157075047493,
        0.0367567241191864,
        0.05508115515112877,
        0.03559116646647453,
        0.060124799609184265,
        0.015047987923026085,
        0.03255781531333923,
        0.03841429576277733,
        -0.0524958111345768,
        0.03849799931049347,
        0.01960921473801136,
        -0.06483021378517151,
        -0.06087339296936989,
        0.046312447637319565,
        0.05906316265463829,
        -0.029178980737924576,
        -0.04707648977637291,
        0.03849334642291069,
        -0.03773543983697891,
        0.04961133375763893,
        0.0588935911655426,
        -0.018679603934288025,
        0.043512362986803055,
        0.046667587012052536,
        0.011860083788633347,
        -0.04684969410300255,
        -0.03570088371634483,
        0.03859541937708855,
        -0.0067735593765974045,
        0.0649898424744606,
        0.031345583498477936,
        0.012408667244017124,
        0.05203213915228844,
        -0.0387389101088047,
        0.06297852843999863,
        -0.0632103756070137,
        -0.006100069265812635,
        -0.020380867645144463,
        0.02832757867872715,
        0.055082712322473526,
        -0.0006024045869708061,
        0.027425821870565414,
        -0.022179244086146355,
        0.05987105518579483,
        -0.0011601909063756466,
        -0.05199611559510231,
        0.036617521196603775,
        -0.009947885759174824,
        0.06255069375038147,
        0.06281986832618713,
        -0.05908617749810219,
        -0.037601035088300705,
        -0.03498781844973564,
        -0.02100823074579239,
        -0.05887792259454727,
        -0.01640128344297409,
        0.05141765624284744,
        0.06358639895915985,
        -0.06497624516487122,
        -0.0408579483628273,
        0.03496982529759407,
        0.021737372502684593,
        -0.06446737796068192,
        -0.033768460154533386,
        -0.06360433995723724,
        -0.002787030069157481,
        0.020623058080673218,
        0.057135578244924545,
        -0.04968477785587311,
        -0.03198981285095215,
        0.022051095962524414,
        -0.05200761929154396,
        0.05398465320467949,
        0.06281697005033493,
        0.04899993911385536,
        -0.045030172914266586,
        -0.0015389918116852641,
        -0.01648121513426304,
        0.025302499532699585,
        0.06034770980477333,
        -0.05380398780107498,
        0.03954828530550003,
        -0.020182134583592415,
        -0.06064064800739288,
        -0.0583125464618206,
        0.06401719152927399,
        -0.06464552879333496,
        -0.024886062368750572,
        -0.049083903431892395,
        -0.04079451411962509,
        -0.056935813277959824,
        -0.056485697627067566,
        -0.007512158714234829,
        0.03320226073265076,
        0.05783078819513321,
        -0.021022671833634377,
        0.03702060133218765,
        0.06256499886512756,
        -0.043722134083509445,
        0.009346934035420418,
        0.06108685955405235,
        0.03411971777677536,
        0.03938627243041992,
        0.035899676382541656,
        0.05538205802440643,
        -0.0008247478399425745,
        0.0012871461221948266,
        0.034435853362083435,
        -0.02723870985209942,
        -0.03007621318101883,
        -0.06247445568442345,
        -0.005918854381889105,
        -0.05950506404042244,
        -0.006440442055463791,
        -0.06209214776754379,
        -0.038588255643844604,
        0.062033213675022125,
        -0.01671786978840828,
        -0.04504692181944847,
        0.05023773014545441,
        0.06004932522773743,
        0.02473699115216732,
        0.02488129772245884,
        0.007070818450301886,
        0.06295455247163773,
        -0.06397466361522675,
        0.060842569917440414,
        0.017534656450152397,
        0.05861111357808113,
        -0.04246842861175537,
        -0.0018188186222687364,
        -0.0028734884690493345,
        -0.06378689408302307,
        -0.03824238106608391,
        -0.055739521980285645,
        -0.0431024394929409,
        0.04821173474192619,
        -0.05058320239186287,
        -0.02986447513103485,
        -0.037867408245801926,
        -0.032480642199516296,
        -0.06092614307999611,
        0.05915245786309242,
        0.04855465888977051,
        0.04026400297880173,
        0.023833347484469414,
        -0.006771374028176069,
        -0.007462427020072937,
        0.02851579152047634,
        -0.010499089024960995,
        0.05636272579431534,
        -0.06239321827888489,
        0.041398126631975174,
        0.008947456255555153,
        -0.044645074754953384,
        -0.013079026713967323,
        -0.045776840299367905,
        0.053455304354429245,
        -0.049754589796066284,
        0.026372618973255157,
        0.04887048527598381,
        -0.03897792845964432,
        0.048561859875917435,
        -0.002886279718950391,
        0.04079527407884598,
        -0.019108165055513382,
        -0.03555351495742798,
        -0.0546950027346611,
        -0.018393225967884064,
        -0.028602806851267815,
        0.005417967215180397,
        0.05735166370868683,
        0.05231539532542229,
        -0.007132927421480417,
        -0.059521712362766266,
        0.010114469565451145,
        -0.058623723685741425,
        0.05778643488883972,
        -0.06349798291921616,
        -0.007966345176100731,
        -0.05972376838326454,
        0.059111692011356354,
        0.013224275782704353,
        -0.010043507441878319,
        -0.06101763993501663,
        0.056128449738025665,
        0.06201772391796112,
        0.04963652417063713,
        -0.05432828515768051,
        -0.04014861211180687,
        0.010180660523474216,
        0.05644684284925461,
        0.05045176297426224,
        0.05522000789642334,
        -0.0633949488401413,
        0.043993011116981506,
        0.031766053289175034,
        0.028914254158735275,
        0.06352926790714264,
        0.003121281275525689,
        0.01728835515677929,
        0.01722513884305954,
        0.002119265263900161,
        -0.0246281698346138,
        0.04020816460251808,
        0.027093298733234406,
        0.02022504061460495,
        0.040286026895046234,
        0.05138959735631943,
        -0.06166871264576912,
        0.05783649906516075,
        -0.05909597873687744,
        0.04101187735795975,
        -0.04490388184785843,
        -0.06259144097566605,
        -0.04377774894237518,
        0.04793761670589447,
        -0.011798219755291939,
        0.011424819938838482,
        -0.052992768585681915,
        -0.06272199749946594,
        0.053507644683122635,
        0.059533488005399704,
        -0.06314027309417725,
        -0.025639601051807404,
        0.0641421303153038,
        -0.01692206785082817,
        0.06444957852363586,
        0.0027493818197399378,
        0.053667448461055756,
        -0.06457320600748062,
        -0.045143451541662216,
        -0.06267257034778595,
        0.06126060336828232,
        0.05753530189394951,
        0.05826418101787567,
        0.05884254723787308,
        0.06194010376930237,
        -0.0025190829765051603,
        -0.0619320347905159,
        -0.018312012776732445,
        -0.018839042633771896,
        0.06067073717713356,
        -0.05995255336165428,
        0.004450370091944933,
        -0.00031774648232385516,
        -0.032837215811014175,
        0.02704527974128723,
        -0.0629720538854599,
        -0.05613274872303009,
        0.005329125560820103,
        -0.006687138695269823,
        0.012375881895422935,
        -0.021184703335165977,
        0.06448527425527573,
        -0.06186141073703766,
        0.05102444440126419,
        0.009733607061207294,
        -0.024502597749233246,
        0.01873035728931427,
        -0.06337570399045944,
        -0.010957781225442886,
        0.06386758387088776,
        0.059675466269254684,
        0.06346020847558975,
        -0.049853987991809845,
        0.05849802866578102,
        0.003411393379792571,
        0.0015277386410161853,
        0.05762254819273949,
        -0.021208971738815308,
        0.0643954649567604,
        0.057230718433856964,
        0.05976660177111626,
        0.0031781564466655254,
        0.05902029573917389,
        0.005027862265706062,
        -0.03748853877186775,
        -0.05241793394088745,
        0.027780350297689438,
        0.06208130717277527,
        -0.057208817452192307,
        -0.027855118736624718,
        -0.030053818598389626,
        -0.06302966177463531,
        0.01711900345981121,
        -0.0035683007445186377,
        0.0019452385604381561,
        -0.053608689457178116,
        -0.046509113162755966,
        -0.021016450598835945,
        0.04826893284916878,
        -0.037282876670360565,
        0.048093993216753006,
        -0.04765640199184418,
        -0.03029288537800312,
        -0.05076625943183899,
        0.01934957131743431,
        0.05204223841428757,
        -0.044616539031267166,
        -0.05920198932290077,
        0.03990067169070244,
        0.04264334589242935,
        0.05664965137839317,
        0.050292979925870895,
        -0.03404480963945389,
        -0.01760730892419815,
        0.05125194042921066,
        -0.05674626678228378,
        0.056404292583465576,
        -0.04886294901371002,
        0.061687879264354706,
        0.007905974984169006,
        0.05244458466768265,
        -0.057460539042949677,
        0.04199638590216637,
        -0.02794191800057888,
        0.007087083999067545,
        0.05881761386990547,
        0.05345814675092697,
        0.04711916297674179,
        -0.05572868138551712,
        -0.04171435534954071,
        0.015430512838065624,
        -0.010917825624346733,
        0.062042586505413055,
        0.06422729790210724,
        -0.05312070623040199,
        0.04738615080714226,
        -0.05278090760111809,
        -0.06409550458192825,
        0.029452074319124222,
        -0.044880352914333344,
        0.028434421867132187,
        0.045615505427122116,
        0.0438883900642395,
        -0.011074736714363098,
        0.056791357696056366,
        0.04533747211098671,
        0.02969488687813282,
        -0.034745413810014725,
        -0.053064413368701935,
        -0.017170695587992668,
        -0.06256797164678574,
        0.013544042594730854,
        0.05563050135970116,
        -0.030142800882458687,
        0.020741861313581467,
        0.03272342309355736,
        -0.060258302837610245,
        -0.018795404583215714,
        -0.017416708171367645,
        0.0008764610392972827,
        -0.003710539313033223,
        0.05347220227122307,
        -0.008065272122621536,
        -0.02287118136882782,
        -0.05229872092604637,
        0.039474114775657654,
        0.05505096912384033,
        0.06230165436863899,
        -0.0384400300681591,
        0.01785210147500038,
        0.01088342908769846,
        -0.054755259305238724,
        -0.021981138736009598,
        0.01288805902004242,
        0.03253987804055214,
        0.026309126988053322,
        -0.03267868235707283,
        -0.06412084400653839,
        0.06371016055345535,
        0.042961787432432175
    ],
    [
        -0.03444833680987358,
        0.03762076050043106,
        -0.021136801689863205,
        0.018239255994558334,
        0.05188027024269104,
        -0.03640422597527504,
        0.056721676141023636,
        -0.047254566103219986,
        0.01631908304989338,
        0.04246848076581955,
        -0.028960095718503,
        0.05582132935523987,
        -0.056228503584861755,
        0.0019500923808664083,
        -0.05571923777461052,
        0.02556116133928299,
        -0.056560076773166656,
        0.05166616663336754,
        -0.05640457943081856,
        0.05294061452150345,
        -0.04934479668736458,
        -0.024705909192562103,
        0.05591714382171631,
        0.014348398894071579,
        0.04075631871819496,
        -0.03145770728588104,
        0.04714522138237953,
        0.05142001062631607,
        0.029591400176286697,
        -0.051638953387737274,
        0.02434127777814865,
        0.011905103921890259,
        -0.03465183824300766,
        -0.05481584742665291,
        -0.003637223271653056,
        -0.055206283926963806,
        -0.052768927067518234,
        -0.05652705579996109,
        -0.056721996515989304,
        0.05216066911816597,
        0.0011704431381076574,
        0.02777223289012909,
        0.052207205444574356,
        -0.0551539808511734,
        0.05286160856485367,
        -0.023882664740085602,
        0.0059411125257611275,
        0.05662449449300766,
        0.05672178789973259,
        -0.025772759690880775,
        0.024510886520147324,
        -0.039309076964855194,
        0.05585040524601936,
        -0.035672761499881744,
        -0.018133074045181274,
        -0.027612701058387756,
        -0.050603389739990234,
        -0.007956731133162975,
        -0.05215064063668251,
        -0.022098388522863388,
        -0.03900199756026268,
        0.012019314803183079,
        -0.05490874871611595,
        0.05351801589131355,
        0.040417734533548355,
        0.04737715423107147,
        0.022823968902230263,
        -0.053286317735910416,
        0.03818930312991142,
        -0.05674735829234123,
        -0.05403175950050354,
        -0.05671532079577446,
        0.054835256189107895,
        0.0192253440618515,
        -0.054118867963552475,
        0.032346490770578384,
        0.05096203833818436,
        0.04461352154612541,
        0.036105893552303314,
        -0.05073375627398491,
        0.002441661898046732,
        0.02083338424563408,
        -0.05004138872027397,
        0.04845298454165459,
        0.052576106041669846,
        0.0434131883084774,
        -0.05482647940516472,
        -0.047514185309410095,
        0.051428623497486115,
        0.05621777102351189,
        -0.054318275302648544,
        0.05612068995833397,
        -0.02346424013376236,
        -0.05433444306254387,
        -0.010977429337799549,
        -0.018815981224179268,
        -0.03825964033603668,
        -0.05310804024338722,
        -0.054017674177885056,
        0.05136939883232117,
        -0.05669467896223068,
        -0.0032617836259305477,
        0.056555844843387604,
        0.0463482141494751,
        -0.00880494061857462,
        -0.051199521869421005,
        0.006529905833303928,
        0.04591510072350502,
        -0.05573014169931412,
        0.007248012814670801,
        -0.055911485105752945,
        -0.05424576252698898,
        -0.04075101390480995,
        -0.051171962171792984,
        0.05612298846244812,
        -0.049707431346178055,
        0.03917183727025986,
        -0.04616188257932663,
        -0.05274423956871033,
        -0.0523560531437397,
        -0.011146154254674911,
        -0.015607623383402824,
        -0.054229557514190674,
        0.030344679951667786,
        -0.05611239746212959,
        -0.01227263081818819,
        -0.01372602116316557,
        0.056635770946741104,
        0.05623795464634895,
        0.0024784989655017853,
        0.051537610590457916,
        -0.03573836386203766,
        0.005482745822519064,
        0.020470408722758293,
        -0.051918234676122665,
        -0.049886904656887054,
        0.054490040987730026,
        -0.054300203919410706,
        -0.04943539947271347,
        0.056004732847213745,
        0.05597270280122757,
        0.04329242184758186,
        0.03581363707780838,
        0.029184365645051003,
        0.023114610463380814,
        0.05502032861113548,
        0.05079217255115509,
        -0.05195977911353111,
        -0.007335070986300707,
        0.05657624453306198,
        -0.038159240037202835,
        -0.05672697722911835,
        -0.054715488106012344,
        0.05589845031499863,
        -0.011153040453791618,
        -0.0566486194729805,
        0.05646888166666031,
        -0.050922684371471405,
        -0.05164579302072525,
        0.039501290768384933,
        0.056436337530612946,
        0.03587588295340538,
        0.012957127764821053,
        0.03198004513978958,
        -0.055308789014816284,
        -0.035971321165561676,
        0.05617216229438782,
        -0.0556427463889122,
        -0.026997311040759087,
        0.02831208147108555,
        -0.01748243346810341,
        -0.01125896256417036,
        -0.05587935075163841,
        0.031161487102508545,
        -0.04860985279083252,
        0.05642935261130333,
        -0.05666644126176834,
        0.04255334287881851,
        0.030933719128370285,
        -0.039364706724882126,
        0.034678567200899124,
        -0.024967987090349197,
        0.05639852210879326,
        0.04336319491267204,
        0.03271595016121864,
        -0.007008671294897795,
        -0.027264390140771866,
        0.0546712763607502,
        -0.04542500898241997,
        -0.030008459463715553,
        -0.04414540156722069,
        -0.054481592029333115,
        -0.04300137609243393,
        0.0361369363963604,
        -0.017965441569685936,
        -0.012774920091032982,
        0.05645200237631798,
        0.041071150451898575,
        -0.049007102847099304,
        0.002006249502301216,
        -0.03213455528020859,
        0.0566122829914093,
        0.04406599700450897,
        0.04805343970656395,
        -0.031967584043741226,
        0.05004046857357025,
        0.022344140335917473,
        -0.017695147544145584,
        -0.051019925624132156,
        -0.003481823718175292,
        -0.05346715822815895,
        0.048527248203754425,
        -0.05175843834877014,
        0.012511060573160648,
        0.052233774214982986,
        -0.05283932387828827,
        0.055900897830724716,
        0.03040885180234909,
        0.056743212044239044,
        -0.03129159286618233,
        -0.049987584352493286,
        -0.014146067202091217,
        -0.05593523010611534,
        -0.056687645614147186,
        0.056734632700681686,
        -0.056589171290397644,
        0.04592866823077202,
        0.05608648806810379,
        -0.011460928246378899,
        -0.05141235515475273,
        -0.007930326275527477,
        -0.05669384449720383,
        -0.012495087459683418,
        0.05524464324116707,
        -0.024963317438960075,
        0.04399040713906288,
        0.05619676038622856,
        -0.0052286856807768345,
        0.041789304465055466,
        0.05592869222164154,
        0.054782576858997345,
        0.05486845225095749,
        -0.05378524586558342,
        0.05005478858947754,
        -0.00851728767156601,
        0.005507506895810366,
        0.0472099594771862,
        0.013786598108708858,
        -0.04518905282020569,
        0.047125790268182755,
        -0.04271741211414337,
        -0.0561554916203022,
        -0.055418431758880615,
        -0.055581655353307724,
        0.053247012197971344,
        0.05651715397834778,
        -0.051766976714134216,
        -0.03777751326560974,
        0.05609336867928505,
        0.05558719485998154,
        0.05016113072633743,
        0.055890001356601715,
        -0.010325063019990921,
        0.03807311877608299,
        -0.05222555994987488,
        0.05213438719511032,
        0.05364451929926872,
        0.014202523045241833,
        -0.049225546419620514,
        -0.03259038180112839,
        -0.008459311909973621,
        -0.0565120205283165,
        -0.030120903626084328,
        -0.056656043976545334,
        -0.021471582353115082,
        -0.0036709948908537626,
        -0.05564744397997856,
        0.05456271022558212,
        -0.05669049918651581,
        -0.05124993622303009,
        -0.05536704882979393,
        -0.05577658861875534,
        0.05463298410177231,
        0.04474185034632683,
        0.053241927176713943,
        -0.056683313101530075,
        0.03858061134815216,
        -0.049834683537483215,
        -0.05527954548597336,
        0.022946737706661224,
        -0.022975442931056023,
        0.043404944241046906,
        -0.027645234018564224,
        -0.013086431659758091,
        -0.001738421618938446,
        -0.053029872477054596,
        0.05668918788433075,
        0.038235731422901154,
        -0.055575694888830185,
        0.05487130954861641,
        -0.053006611764431,
        0.051470160484313965,
        0.0160344447940588,
        0.0401088148355484,
        0.020420588552951813,
        -0.04159035161137581,
        -0.05622931942343712,
        0.039808813482522964,
        0.05421159043908119,
        -0.056660763919353485,
        0.026097364723682404,
        -0.053025271743535995,
        -0.034460000693798065,
        0.04097241535782814,
        -0.05672385171055794,
        -0.0545368455350399,
        0.044007040560245514,
        -0.04725802317261696,
        0.03919370099902153,
        -0.05655849725008011,
        -0.05090930312871933,
        0.03742596134543419,
        0.05627003312110901,
        -0.05299937725067139,
        0.05508619546890259,
        0.0542512871325016,
        0.05592137575149536,
        -0.04554881528019905,
        0.014732405543327332,
        -0.01659027859568596,
        0.021535227075219154,
        0.0509101003408432,
        0.050510454922914505,
        -0.04632754996418953,
        0.049710169434547424,
        0.046889834105968475,
        -0.05282904580235481,
        0.05606582388281822,
        0.0054338062182068825,
        0.04979637265205383,
        0.03769331052899361,
        -0.03736892715096474,
        0.03472646325826645,
        0.023572595790028572,
        0.056747376918792725,
        0.04870991036295891,
        0.005596189294010401,
        0.04753315448760986,
        0.01614224910736084,
        0.056560926139354706,
        -0.056733645498752594,
        0.04724036529660225,
        -0.04696323722600937,
        -0.05642854794859886,
        -0.054546698927879333,
        0.053837377578020096,
        -0.050817836076021194,
        -0.029759248718619347,
        -0.05492096021771431,
        -0.05278685688972473,
        0.05032818764448166,
        0.04929962381720543,
        -0.04689546301960945,
        -0.040420979261398315,
        0.01889340579509735,
        -0.048860520124435425,
        0.05669289827346802,
        -0.01965002901852131,
        0.05562182143330574,
        -0.0566931813955307,
        -0.04550943151116371,
        -0.056259095668792725,
        0.05428905785083771,
        0.037438880652189255,
        0.056611623615026474,
        0.05657524988055229,
        0.04430036619305611,
        0.04696154221892357,
        -0.05323361977934837,
        -0.0567028746008873,
        0.0029890548903495073,
        0.05448652058839798,
        -0.05474667251110077,
        0.03737194463610649,
        0.05637010186910629,
        -0.025069672614336014,
        0.05431608855724335,
        -0.054895415902137756,
        -0.04190026968717575,
        -0.04563156142830849,
        -0.05493532866239548,
        0.04886715114116669,
        0.047781117260456085,
        0.05306139960885048,
        -0.05548343062400818,
        0.05529486760497093,
        0.056659724563360214,
        0.04408358782529831,
        -0.02950184792280197,
        -0.056738387793302536,
        -0.01796722412109375,
        0.049298278987407684,
        0.054781846702098846,
        0.05649269372224808,
        -0.054493196308612823,
        -0.028965771198272705,
        0.04782124608755112,
        -0.013237855397164822,
        0.05551533028483391,
        -0.018893225118517876,
        0.05552979186177254,
        -0.010614041239023209,
        -0.04730936884880066,
        -0.021967874839901924,
        0.050465792417526245,
        0.035414643585681915,
        -0.009621545672416687,
        0.000898465805221349,
        -0.0010230769403278828,
        -0.006736767012625933,
        -0.056715819984674454,
        -0.024164089933037758,
        -0.05334177240729332,
        -0.05563574656844139,
        0.053055256605148315,
        -0.044789329171180725,
        -0.04512720927596092,
        -0.04348081722855568,
        -0.05592909827828407,
        -0.05572713539004326,
        0.05642494186758995,
        -0.005263625178486109,
        -0.008490623906254768,
        0.03677356615662575,
        0.037495143711566925,
        0.020917631685733795,
        -0.056364547461271286,
        0.03105413354933262,
        0.04432603344321251,
        -0.04489084333181381,
        0.03514591604471207,
        0.03268249332904816,
        0.02033531293272972,
        0.05216518044471741,
        0.050900090485811234,
        0.05091271549463272,
        0.051713816821575165,
        -0.05323131009936333,
        0.0541875921189785,
        -0.04537816718220711,
        -0.036755241453647614,
        0.05659644305706024,
        0.05591201037168503,
        0.04923131316900253,
        -0.01291776355355978,
        -0.043344929814338684,
        0.0374753512442112,
        0.045911289751529694,
        -0.05352915823459625,
        0.0402100495994091,
        0.029803819954395294,
        -0.045543521642684937,
        -0.05454767495393753,
        -0.05174906551837921,
        0.05665537342429161,
        0.056649111211299896,
        -0.05667734891176224,
        0.0559135377407074,
        -0.05503137782216072,
        -0.05638954043388367,
        0.05166942626237869,
        -0.009534955956041813,
        0.016376134008169174,
        0.004419754724949598,
        0.05598944425582886,
        -0.009319283068180084,
        -0.02900606580078602,
        -0.045322611927986145,
        -0.04437555372714996,
        -0.04737028479576111,
        0.040793269872665405,
        0.010578556917607784,
        -0.007074826862663031,
        0.051718760281801224,
        -0.017752831801772118,
        0.04654787480831146,
        0.05610332638025284,
        -0.0319691076874733,
        -0.05200145021080971,
        -0.031101858243346214,
        -0.013504437170922756,
        -0.05636068433523178,
        0.05366893112659454,
        -0.01038016751408577,
        0.05503718554973602,
        -0.0035545649006962776,
        -0.056707099080085754,
        -0.028646893799304962,
        0.05607365071773529,
        0.048506829887628555,
        0.05229760706424713,
        -0.013566708192229271,
        -0.012077432125806808,
        0.024702291935682297,
        0.029687387868762016,
        0.049554672092199326,
        -0.05354369804263115,
        0.01126853097230196,
        -0.0011025341227650642,
        -0.026875214651226997,
        0.05668071657419205,
        0.05660887062549591
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.05667534098029137,
        0.044355083256959915,
        0.056540850549936295,
        -0.0259495098143816,
        0.017768774181604385,
        0.05639592930674553,
        0.056093428283929825,
        -0.054447002708911896,
        0.05534525215625763,
        0.05618898570537567,
        0.05016409978270531,
        0.01859400048851967,
        -0.05347641929984093,
        0.016077596694231033,
        -0.05527951568365097,
        0.056731581687927246,
        -0.050330840051174164,
        0.030391016975045204,
        -0.05102734640240669,
        -0.010017940774559975,
        -0.056528881192207336,
        0.010081326588988304,
        -0.05148109421133995,
        0.029766472056508064,
        0.05526260659098625,
        0.0264214389026165,
        0.05222943797707558,
        0.05536927282810211,
        -0.04098408296704292,
        -0.04944160208106041,
        0.05113695189356804,
        -0.052209533751010895,
        0.02514214813709259,
        -0.04986954107880592,
        -0.0012300991220399737,
        -0.031426265835762024,
        -0.054629720747470856,
        -0.04298730939626694,
        -0.02578461356461048,
        0.05282893031835556,
        -0.012353749014437199,
        0.022703267633914948,
        0.035873737186193466,
        -0.04320120811462402,
        0.05561262369155884,
        -0.04842565208673477,
        0.02695842832326889,
        0.05655403807759285,
        0.05605531111359596,
        0.031530626118183136,
        0.04407864063978195,
        -0.011137168854475021,
        0.04869692400097847,
        -0.012380396015942097,
        0.0557180754840374,
        -0.05668966844677925,
        0.03686217591166496,
        -0.021526560187339783,
        -0.016644470393657684,
        0.009212005883455276,
        -0.05600813776254654,
        -0.04685525968670845,
        -0.05573292076587677,
        -0.026386547833681107,
        0.044073477387428284,
        0.006447003223001957,
        0.04673461243510246,
        0.03963599354028702,
        0.027178188785910606,
        -0.05676601454615593,
        -0.05423077940940857,
        -0.05671710893511772,
        0.046096984297037125,
        0.031573545187711716,
        -0.05676240473985672,
        -0.03175920620560646,
        -0.036742839962244034,
        0.05587272718548775,
        0.014386025257408619,
        -0.04627523571252823,
        -0.020661938935518265,
        0.05645500496029854,
        -0.0551082007586956,
        0.052125781774520874,
        -0.027805037796497345,
        0.051891062408685684,
        -0.012856787070631981,
        0.05611862614750862,
        -0.016218964010477066,
        0.056334782391786575,
        -0.053773414343595505,
        0.05389513820409775,
        -0.007180728949606419,
        -0.024925507605075836,
        -0.04573184251785278,
        0.006359743420034647,
        -0.0014094357611611485,
        -0.0506848469376564,
        0.0009089004015550017,
        0.037290219217538834,
        -0.056760549545288086,
        0.03917178139090538,
        0.056466516107320786,
        -0.009211037307977676,
        -0.021788937970995903,
        -0.05255133658647537,
        -0.05158518627285957,
        0.055156175047159195,
        0.004286715295165777,
        0.03559538349509239,
        0.01547419186681509,
        0.009061011485755444,
        0.00412613432854414,
        -0.0015867722686380148,
        0.05423296242952347,
        0.04888954013586044,
        -0.032160207629203796,
        -0.04287056252360344,
        -0.05449509248137474,
        -0.04286883771419525,
        0.051727768033742905,
        -0.0438779853284359,
        0.021662982180714607,
        0.019953669980168343,
        -0.056293245404958725,
        -0.0564115047454834,
        -0.04616108164191246,
        0.056444019079208374,
        0.04267371818423271,
        -0.013459756039083004,
        -0.05102747678756714,
        -0.001514841103926301,
        0.033051036298274994,
        0.001977891195565462,
        -0.05107554793357849,
        -0.04891657456755638,
        0.05665208771824837,
        -0.05667046830058098,
        -0.05611904710531235,
        0.051714200526475906,
        0.05534924194216728,
        0.025541121140122414,
        0.049031004309654236,
        -0.04760060831904411,
        -0.05122488737106323,
        0.004511837847530842,
        0.05582511052489281,
        -0.023802150040864944,
        -0.055678822100162506,
        0.0017781247152015567,
        0.054883748292922974,
        -0.05669867992401123,
        -0.04447588324546814,
        0.05270756036043167,
        0.04340590536594391,
        -0.05232706293463707,
        0.05609959363937378,
        0.01078243087977171,
        0.045333392918109894,
        0.04365639016032219,
        0.03414652496576309,
        -0.0534791499376297,
        0.011068903841078281,
        -0.05575136840343475,
        -0.026016471907496452,
        -0.04618729278445244,
        0.05566519871354103,
        -0.05646027252078056,
        -0.016408568248152733,
        0.05014042928814888,
        -0.0509764701128006,
        -0.027372771874070168,
        -0.04185798391699791,
        0.046173274517059326,
        0.05423934385180473,
        0.05649503692984581,
        -0.0327448844909668,
        -0.017438992857933044,
        0.0492461659014225,
        -0.04196878522634506,
        0.049781568348407745,
        -0.0028767180629074574,
        0.05320938304066658,
        0.052330028265714645,
        0.04653152450919151,
        0.027036085724830627,
        -0.055827751755714417,
        0.05331104248762131,
        -0.035608287900686264,
        -0.01439469214528799,
        0.042866192758083344,
        -0.035875480622053146,
        -0.01612778939306736,
        0.028653748333454132,
        0.0069205802865326405,
        -0.03749055042862892,
        0.04292330518364906,
        -0.0224323533475399,
        -0.040811315178871155,
        0.05402683466672897,
        -0.03267261013388634,
        0.056719452142715454,
        0.05108463019132614,
        0.019081449136137962,
        0.05536012351512909,
        0.02533300220966339,
        0.03376390039920807,
        -0.05568613484501839,
        -0.056121826171875,
        0.056117597967386246,
        -0.04764010012149811,
        0.049378685653209686,
        -0.048113200813531876,
        -0.03519510477781296,
        0.05649673193693161,
        -0.05225701630115509,
        0.050321150571107864,
        0.05056033283472061,
        0.05564559996128082,
        -0.056727148592472076,
        0.0038875918835401535,
        0.003995018545538187,
        -0.04367305710911751,
        -0.05335445702075958,
        0.055481888353824615,
        -0.05602600425481796,
        0.01288127526640892,
        0.041019923985004425,
        0.042629022151231766,
        -0.041485682129859924,
        -0.02357010543346405,
        -0.05473402887582779,
        -0.04101095721125603,
        0.05605579540133476,
        0.05519245192408562,
        0.04410231485962868,
        0.05484800040721893,
        -0.029147431254386902,
        -0.03885320574045181,
        0.056509144604206085,
        0.04536164551973343,
        0.006682001054286957,
        -0.004292341880500317,
        0.04049052298069,
        0.046493131667375565,
        0.027277212589979172,
        0.0038715580012649298,
        -0.02777959406375885,
        -0.021160289645195007,
        -0.04960603639483452,
        0.045007072389125824,
        -0.05665777623653412,
        0.05622219294309616,
        -0.05031616613268852,
        0.04928332567214966,
        0.056672047823667526,
        -0.0051164934411644936,
        -0.03579499572515488,
        0.05653257295489311,
        0.05461091920733452,
        0.05317620187997818,
        0.056720782071352005,
        0.032146699726581573,
        0.052987586706876755,
        0.05425620079040527,
        0.013413514010608196,
        0.033548738807439804,
        0.054733552038669586,
        -0.015506028197705746,
        0.03465953841805458,
        -0.054225385189056396,
        -0.056080736219882965,
        0.029353760182857513,
        -0.0554981529712677,
        0.04956481233239174,
        0.05510560795664787,
        -0.05574709177017212,
        0.026125576347112656,
        -0.05479718744754791,
        -0.05617372319102287,
        -0.05178885906934738,
        -0.011290249414741993,
        -0.017798835411667824,
        -0.04039456322789192,
        -0.050004709511995316,
        -0.05650541931390762,
        0.051139719784259796,
        -0.04334411025047302,
        -0.007297644391655922,
        0.033445678651332855,
        -0.036461371928453445,
        0.043094754219055176,
        0.03817814588546753,
        -0.02244243212044239,
        0.05075724422931671,
        -0.014059150591492653,
        0.05661525949835777,
        0.028622979298233986,
        -0.03057914413511753,
        0.05527506768703461,
        0.054082609713077545,
        0.05403437837958336,
        0.008945964276790619,
        0.034465618431568146,
        -0.011117920279502869,
        -0.03679398074746132,
        -0.04099477455019951,
        0.04730283096432686,
        0.05561409518122673,
        -0.05163240060210228,
        -0.056520406156778336,
        -0.049377985298633575,
        -0.025113746523857117,
        0.02199699357151985,
        -0.053876034915447235,
        -0.05413185432553291,
        0.03990466147661209,
        0.05185545235872269,
        0.055897410959005356,
        -0.05482247471809387,
        -0.05665622651576996,
        -0.047845903784036636,
        0.05604352429509163,
        -0.053697116672992706,
        0.04155978187918663,
        0.05553889274597168,
        0.05483593791723251,
        0.031442102044820786,
        0.044694144278764725,
        -0.006152119487524033,
        -0.054988425225019455,
        0.042127884924411774,
        0.04890992492437363,
        0.04597845301032066,
        0.05270034074783325,
        0.05174734815955162,
        -0.05658277869224548,
        0.055724672973155975,
        -0.04641519486904144,
        0.055614080280065536,
        0.01353135984390974,
        -0.03908182308077812,
        0.05607305094599724,
        -0.016809741035103798,
        0.05654982849955559,
        0.047167833894491196,
        0.0334114134311676,
        0.05394570156931877,
        -0.05453327298164368,
        0.05506955459713936,
        -0.056541807949543,
        0.05670664831995964,
        -0.01889849454164505,
        -0.055255476385354996,
        -0.049805913120508194,
        0.056453902274370193,
        -0.05668283626437187,
        -0.04902252182364464,
        -0.05314723029732704,
        -0.04853583872318268,
        0.05449001491069794,
        -0.005097313318401575,
        -0.05616544187068939,
        -0.048791393637657166,
        -0.016344286501407623,
        -0.05493805557489395,
        0.056703612208366394,
        0.0007784184999763966,
        0.052909694612026215,
        -0.05672890320420265,
        -0.04193587601184845,
        -0.05621561408042908,
        0.05312154442071915,
        -0.047448188066482544,
        0.05408688634634018,
        0.05176989734172821,
        0.04181115701794624,
        0.05562746524810791,
        0.030060427263379097,
        -0.03692830353975296,
        0.015131792984902859,
        0.05060816556215286,
        -0.054589927196502686,
        0.019225459545850754,
        0.051615744829177856,
        -0.05079331621527672,
        0.04950454831123352,
        -0.05383860319852829,
        -0.05406222864985466,
        -0.05674389377236366,
        -0.05615215003490448,
        -0.053547125309705734,
        -0.0078370850533247,
        -0.0066941711120307446,
        -0.05283203348517418,
        0.05507195368409157,
        0.0517859011888504,
        -0.04695453494787216,
        -0.05513441190123558,
        -0.056737516075372696,
        -0.052780769765377045,
        0.041085585951805115,
        0.056202638894319534,
        0.05599601939320564,
        0.030731745064258575,
        0.055323075503110886,
        0.02897380106151104,
        -0.002315167337656021,
        0.05642424151301384,
        -0.053816258907318115,
        -0.0016967655392363667,
        0.03468739613890648,
        -0.04453985393047333,
        0.049074504524469376,
        0.012174664065241814,
        0.045508090406656265,
        0.014937367290258408,
        0.029173076152801514,
        0.05079473555088043,
        -0.031246159225702286,
        -0.05544209107756615,
        -0.05558033287525177,
        -0.05568590760231018,
        -0.05659238249063492,
        0.04663614183664322,
        0.0009697491186670959,
        -0.054790616035461426,
        -0.054425254464149475,
        -0.05339285731315613,
        -0.05094090476632118,
        0.03853045031428337,
        -0.02530876360833645,
        -0.03963998705148697,
        -0.03052133321762085,
        0.05340217426419258,
        0.004094784613698721,
        -0.04698328673839569,
        0.05434834957122803,
        0.03111516498029232,
        -0.0245262011885643,
        -0.0015233198646456003,
        -0.002718866802752018,
        0.05475636571645737,
        0.05042314529418945,
        0.026977229863405228,
        0.051226645708084106,
        0.05612751469016075,
        0.0528290718793869,
        0.054084427654743195,
        -0.03862231597304344,
        0.05464157462120056,
        0.056465033441782,
        0.018122434616088867,
        -0.018458697944879532,
        0.053194139152765274,
        -0.0554913692176342,
        0.019483473151922226,
        0.013057532720267773,
        -0.02077260985970497,
        -0.01978769339621067,
        0.028768613934516907,
        -0.014482010155916214,
        0.019897356629371643,
        0.05661167949438095,
        0.0556221604347229,
        0.05642407014966011,
        -0.05641177296638489,
        0.05374779552221298,
        -0.056731536984443665,
        -0.05653031915426254,
        0.05665494129061699,
        0.04667677730321884,
        0.02617218717932701,
        0.025259802117943764,
        -0.043935906141996384,
        0.0555230937898159,
        -0.0004311550874263048,
        0.05413641035556793,
        -0.052373576909303665,
        -0.05418597534298897,
        0.056738995015621185,
        -0.04216776043176651,
        0.05489607900381088,
        0.05197271704673767,
        0.0033576784189790487,
        0.05512288957834244,
        0.05394626036286354,
        0.011612665839493275,
        -0.051922306418418884,
        -0.010874894447624683,
        -0.056055884808301926,
        -0.05656934157013893,
        0.04180455952882767,
        0.04651321470737457,
        0.054829299449920654,
        0.0535498671233654,
        -0.05410146713256836,
        -0.020775210112333298,
        0.05589352175593376,
        0.04040013626217842,
        0.03804697096347809,
        -0.017370210960507393,
        -0.03830728679895401,
        -0.032348308712244034,
        -0.03186863288283348,
        0.03528005629777908,
        0.02940315566956997,
        -0.014322380535304546,
        -0.050838835537433624,
        0.05456284061074257,
        0.05674970522522926,
        0.033240657299757004
    ],
    [
        -0.05663669854402542,
        0.04155133292078972,
        0.05172479897737503,
        0.017199203372001648,
        0.056548941880464554,
        0.052496038377285004,
        0.058717261999845505,
        -0.021061304956674576,
        0.02926299348473549,
        0.05835198611021042,
        0.03492855653166771,
        0.052482280880212784,
        -0.04964245855808258,
        0.050938479602336884,
        -0.05809401720762253,
        0.05559522286057472,
        -0.056702870875597,
        0.05069843307137489,
        -0.05817551910877228,
        0.05638900026679039,
        -0.016432907432317734,
        -0.02993745543062687,
        0.04309684410691261,
        -0.022802339866757393,
        0.05688096582889557,
        -0.028602203354239464,
        -0.014496147632598877,
        0.05714603513479233,
        -0.04642758518457413,
        0.004036317113786936,
        0.04458125680685043,
        -0.03746388852596283,
        0.04958708584308624,
        -0.05647903308272362,
        -0.03565153107047081,
        -0.05800113081932068,
        -0.05451043322682381,
        -0.058120161294937134,
        -0.05672292783856392,
        0.01652473956346512,
        -0.025343438610434532,
        0.02331939898431301,
        0.05459573119878769,
        -0.02345350943505764,
        0.05292811989784241,
        -0.05337122827768326,
        0.05068674683570862,
        0.0582415908575058,
        0.058611392974853516,
        0.023113874718546867,
        0.002206142758950591,
        -0.034244604408741,
        0.05668649077415466,
        -0.0417594276368618,
        0.04618081450462341,
        -0.047817740589380264,
        -0.020819833502173424,
        -0.05303477868437767,
        -0.05120064690709114,
        0.03265797346830368,
        -0.034137431532144547,
        -0.04309700429439545,
        -0.04803970083594322,
        -0.01986413076519966,
        0.05571560189127922,
        0.029417194426059723,
        -0.03290804475545883,
        -0.023453643545508385,
        -0.02255631610751152,
        -0.0587836429476738,
        -0.0526694692671299,
        -0.05862145870923996,
        0.05508873239159584,
        0.013940935023128986,
        -0.0586688295006752,
        0.0012299676891416311,
        0.04939032346010208,
        0.053261999040842056,
        -0.009339854121208191,
        -0.02855365164577961,
        -0.04426661506295204,
        -0.02395036816596985,
        -0.03391880914568901,
        0.024686191231012344,
        0.05435590073466301,
        0.026701541617512703,
        -0.05835304781794548,
        0.043134644627571106,
        0.030775971710681915,
        0.057678453624248505,
        -0.05695199593901634,
        0.05712973698973656,
        0.05086110159754753,
        -0.05539373308420181,
        -0.03556380793452263,
        -0.00620323047041893,
        -0.05168206989765167,
        -0.051521819084882736,
        -0.027211381122469902,
        0.017995335161685944,
        -0.058701805770397186,
        -0.03533940762281418,
        0.05637028068304062,
        0.05321161448955536,
        0.002536550397053361,
        0.012330434285104275,
        -0.04316631332039833,
        0.02041829563677311,
        -0.05159797519445419,
        0.00485270144417882,
        -0.04389457777142525,
        -0.004613043274730444,
        -0.04121474176645279,
        -0.01763354428112507,
        0.04292714595794678,
        -0.05514533072710037,
        -0.026735126972198486,
        -0.05756339058279991,
        -0.05675623193383217,
        0.0326523557305336,
        -0.0007402401533909142,
        -0.011874533258378506,
        -0.02486884593963623,
        -0.014318855479359627,
        -0.05681203305721283,
        -0.021294623613357544,
        -0.024069640785455704,
        0.057381000369787216,
        0.0469035804271698,
        -0.025614183396100998,
        -0.02791086584329605,
        -0.010290715843439102,
        0.016512790694832802,
        -0.015679966658353806,
        -0.052883636206388474,
        -0.05630624666810036,
        0.04900866374373436,
        -0.05620940029621124,
        -0.05603403598070145,
        0.055604804307222366,
        0.05772820860147476,
        0.04141951724886894,
        -0.0003410429635550827,
        0.017657577991485596,
        0.04357736185193062,
        0.05116068944334984,
        0.04509907588362694,
        -0.05568375810980797,
        -0.04935939982533455,
        0.04613640531897545,
        0.00997539609670639,
        -0.05812413990497589,
        -0.05542261153459549,
        0.05811675265431404,
        0.03777320310473442,
        -0.04371333867311478,
        0.05118606612086296,
        -0.0008005095296539366,
        0.051274385303258896,
        0.053955402225255966,
        0.05179372802376747,
        0.03355900198221207,
        0.03376169875264168,
        0.021362723782658577,
        -0.058212559670209885,
        -0.0024238084442913532,
        0.042781807482242584,
        -0.05615304410457611,
        -0.021150579676032066,
        0.05113315209746361,
        -0.051975518465042114,
        0.005491921678185463,
        -0.05504798889160156,
        0.05479425936937332,
        -0.0029147351160645485,
        0.05729931220412254,
        -0.05819544196128845,
        0.039017923176288605,
        0.045271653681993484,
        -0.012742510065436363,
        0.0422455333173275,
        -0.006032694596797228,
        0.05475136637687683,
        0.05007214844226837,
        0.0339738205075264,
        0.04053867608308792,
        -0.04597557708621025,
        0.054184772074222565,
        -0.057930584996938705,
        -0.054220348596572876,
        -0.016955463215708733,
        -0.055717889219522476,
        -0.04720693826675415,
        -0.026630327105522156,
        -0.05441536381840706,
        0.012550068087875843,
        0.05805134400725365,
        -0.03372427076101303,
        -0.04484351724386215,
        -0.027004018425941467,
        0.0025586436968296766,
        0.05858130380511284,
        -0.03935776650905609,
        0.007675897795706987,
        0.05450054630637169,
        0.056474924087524414,
        0.030068550258874893,
        0.002023496199399233,
        -0.04770645871758461,
        0.021465392783284187,
        -0.0541386678814888,
        0.05039030686020851,
        -0.0269567109644413,
        0.019503150135278702,
        0.05549674108624458,
        0.03256404772400856,
        0.055436279624700546,
        0.055835556238889694,
        0.05876675248146057,
        -0.05147499591112137,
        -0.02102014049887657,
        -0.01156630739569664,
        -0.05793765187263489,
        -0.0582863911986351,
        0.057936519384384155,
        -0.05815061554312706,
        -0.014638038352131844,
        -0.01943036913871765,
        0.0020426849368959665,
        -0.0492275170981884,
        0.040637824684381485,
        -0.0571465939283371,
        0.04345408454537392,
        0.05722195282578468,
        0.05523278936743736,
        0.03678504377603531,
        0.05832306668162346,
        -0.0186852365732193,
        -0.05269693583250046,
        0.05788398161530495,
        0.04850148782134056,
        0.05144983157515526,
        0.006323382258415222,
        0.055303677916526794,
        -0.01955283246934414,
        -0.007141449023038149,
        0.054013706743717194,
        0.054576244205236435,
        -0.029993940144777298,
        0.01500527560710907,
        -0.02926306240260601,
        -0.05804670229554176,
        -0.016177721321582794,
        -0.05394963175058365,
        0.05397595837712288,
        0.05841042846441269,
        -0.026692047715187073,
        -0.019667843356728554,
        0.05869365110993385,
        0.05760926753282547,
        0.05695679411292076,
        0.05848313122987747,
        0.015083636157214642,
        0.02901698835194111,
        0.04485670477151871,
        0.035580240190029144,
        0.047009944915771484,
        0.042172666639089584,
        0.038475170731544495,
        -0.019956795498728752,
        -0.049120932817459106,
        -0.05761022865772247,
        -0.04771709814667702,
        -0.05824306607246399,
        -0.00917226355522871,
        0.04464130848646164,
        -0.057560138404369354,
        0.05855516344308853,
        -0.04733658581972122,
        -0.05861392244696617,
        -0.057527072727680206,
        -0.05270710214972496,
        0.054712533950805664,
        -0.024731220677495003,
        -0.022104132920503616,
        -0.05839138478040695,
        0.049323420971632004,
        -0.03671194240450859,
        -0.038897980004549026,
        0.03540852665901184,
        -0.04958030581474304,
        0.057371191680431366,
        0.0092642642557621,
        -0.05658554285764694,
        0.014872904866933823,
        -0.025164924561977386,
        0.05824235454201698,
        0.043705448508262634,
        -0.053616832941770554,
        0.05426700413227081,
        -0.05750034376978874,
        0.057630326598882675,
        -0.007317198906093836,
        0.0014551184140145779,
        -0.037361565977334976,
        -0.04509825259447098,
        -0.05726177990436554,
        0.01538960263133049,
        0.05284937843680382,
        -0.05682865530252457,
        0.052057527005672455,
        -0.053999487310647964,
        -0.041312120854854584,
        0.032192640006542206,
        -0.05873270705342293,
        -0.04556242749094963,
        0.04457685723900795,
        -0.04862659424543381,
        0.019874870777130127,
        -0.05795878544449806,
        -0.056415293365716934,
        0.03153303265571594,
        0.030301157385110855,
        -0.05539226159453392,
        0.05435706675052643,
        0.04542887210845947,
        0.0573137067258358,
        -0.006447054911404848,
        0.021620552986860275,
        0.018607787787914276,
        -0.04370390251278877,
        0.05222512036561966,
        0.03962867334485054,
        -0.038068197667598724,
        0.05255815386772156,
        -0.01911119371652603,
        -0.033783119171857834,
        0.05124811455607414,
        -0.05115130543708801,
        0.0569145530462265,
        0.05476372316479683,
        -0.02995133586227894,
        -0.014797773212194443,
        -0.0024277043994516134,
        0.058783307671546936,
        0.04906260967254639,
        -0.004072081297636032,
        0.05062013119459152,
        -0.04971843585371971,
        0.057452693581581116,
        -0.05871497839689255,
        0.054850220680236816,
        -0.04999237507581711,
        -0.057085417211055756,
        0.054273929446935654,
        0.05294352024793625,
        -0.027157632634043694,
        -0.02826939895749092,
        -0.039465807378292084,
        -0.024645032361149788,
        0.05157483369112015,
        0.018767544999718666,
        -0.05421007052063942,
        -0.047772809863090515,
        0.019100872799754143,
        -0.05419153720140457,
        0.05873231962323189,
        0.027135107666254044,
        0.05759837478399277,
        -0.05875316634774208,
        -0.03263392299413681,
        -0.05672822147607803,
        0.05819670855998993,
        -0.04897656664252281,
        0.05833183974027634,
        0.05712366849184036,
        0.02972673997282982,
        0.044122304767370224,
        -0.052473537623882294,
        -0.058681439608335495,
        0.03836066275835037,
        0.0498833954334259,
        -0.057054657489061356,
        0.006638341583311558,
        0.05873464047908783,
        -0.03806223347783089,
        0.057218365371227264,
        -0.05773736536502838,
        -0.02148055098950863,
        -0.05843568965792656,
        -0.052249129861593246,
        -0.052056942135095596,
        0.045438654720783234,
        0.05077046528458595,
        -0.04423448443412781,
        0.05599448084831238,
        0.04217898100614548,
        -0.042694468051195145,
        0.03204101324081421,
        -0.05805838480591774,
        -0.04369029402732849,
        0.053038306534290314,
        0.057970307767391205,
        0.057344406843185425,
        -0.05026835948228836,
        0.04848320409655571,
        0.053906138986349106,
        0.03586915507912636,
        0.022182228043675423,
        -0.057298433035612106,
        0.05543995276093483,
        0.01599823497235775,
        -0.040494631975889206,
        0.0205092690885067,
        0.05480221286416054,
        0.02761036902666092,
        0.0226422231644392,
        -0.017317483201622963,
        0.032842330634593964,
        -0.01887333020567894,
        -0.05792924761772156,
        -0.04164022207260132,
        -0.05023961886763573,
        -0.058113645762205124,
        0.05605463683605194,
        -0.024626556783914566,
        -0.041713472455739975,
        -0.02828819677233696,
        -0.057098809629678726,
        -0.05507726967334747,
        0.044169023633003235,
        -0.04612989351153374,
        0.005248538218438625,
        0.023714246228337288,
        0.01607232354581356,
        -0.0012666941620409489,
        -0.048716772347688675,
        0.035847023129463196,
        0.03552454337477684,
        -0.054835811257362366,
        0.04247797280550003,
        0.042896632105112076,
        0.052432842552661896,
        0.055790599435567856,
        0.018714215606451035,
        0.03899090364575386,
        0.05757332965731621,
        -0.02863539569079876,
        0.05269602686166763,
        -0.055688757449388504,
        0.025829216465353966,
        0.05786910280585289,
        0.0575387217104435,
        -0.0441305935382843,
        0.018446480855345726,
        -0.03209451586008072,
        -0.027489127591252327,
        0.03131059557199478,
        -0.05441845953464508,
        0.02776135504245758,
        0.03654041513800621,
        -0.01933439075946808,
        -0.029552508145570755,
        0.036699291318655014,
        0.05866297334432602,
        0.05837324634194374,
        -0.05801321193575859,
        0.05236873775720596,
        -0.05483924224972725,
        -0.05852228030562401,
        0.029260901734232903,
        0.056443288922309875,
        0.012785823084414005,
        0.04976220801472664,
        0.05492490157485008,
        0.04708440229296684,
        0.03334258496761322,
        -0.04761434718966484,
        -0.04993379861116409,
        0.049581605941057205,
        0.0582771822810173,
        -0.028669172897934914,
        0.05747717246413231,
        0.05051451921463013,
        -0.006693980190902948,
        0.05353362858295441,
        0.05671466514468193,
        0.003714208723977208,
        -0.05365695804357529,
        -0.04664769023656845,
        -0.05148232355713844,
        -0.05863068625330925,
        -0.011304827407002449,
        0.03985751420259476,
        0.05245870351791382,
        0.009026985615491867,
        -0.031215336173772812,
        -0.056170690804719925,
        0.05761982873082161,
        0.02698194608092308,
        0.039362430572509766,
        -0.02973015606403351,
        -0.02308986335992813,
        0.021497882902622223,
        -0.050427038222551346,
        0.03492935374379158,
        -0.0028838987927883863,
        0.02310653030872345,
        -0.04814031720161438,
        -0.037850383669137955,
        0.05876610800623894,
        0.05665959045290947
    ],
    [
        -0.058744024485349655,
        0.028892209753394127,
        0.03459616005420685,
        0.028178829699754715,
        0.06024603173136711,
        0.051145706325769424,
        0.06255203485488892,
        -0.04579722136259079,
        -0.04123885557055473,
        0.062429770827293396,
        0.03467997536063194,
        0.0586087591946125,
        -0.05950157344341278,
        0.006113787647336721,
        -0.06111859530210495,
        -0.009348345920443535,
        -0.05552105978131294,
        0.03936608135700226,
        -0.05847647041082382,
        0.01571633107960224,
        -0.010887015610933304,
        0.02654062770307064,
        0.022446289658546448,
        0.004032899625599384,
        -0.006473281886428595,
        -0.01043775025755167,
        -0.024058330804109573,
        0.061869073659181595,
        0.02056456357240677,
        0.0281473770737648,
        0.058317869901657104,
        -0.060216568410396576,
        0.04796713963150978,
        -0.06190876662731171,
        0.03491871431469917,
        -0.048334989696741104,
        -0.058610159903764725,
        -0.04245235398411751,
        -0.05173715204000473,
        0.05954087898135185,
        -0.017885347828269005,
        0.005662983749061823,
        0.04820884019136429,
        -0.035105470567941666,
        0.05932590737938881,
        -0.010574446991086006,
        0.05110543593764305,
        0.06238055229187012,
        0.06134704127907753,
        0.0013939099153503776,
        0.018291115760803223,
        0.05746734142303467,
        0.06117347255349159,
        -0.044666122645139694,
        -0.0306683499366045,
        -0.018165627494454384,
        -0.056662097573280334,
        -0.017305932939052582,
        -0.05958640202879906,
        -0.03417893871665001,
        -0.061268776655197144,
        -0.020194577053189278,
        -0.04836155101656914,
        -0.0495920293033123,
        0.05480961129069328,
        0.057082656770944595,
        0.045983485877513885,
        0.002311727497726679,
        0.02572683058679104,
        -0.06287212669849396,
        -0.055895909667015076,
        -0.062329526990652084,
        0.023880841210484505,
        -0.04132140427827835,
        -0.0615847222507,
        -0.03172152489423752,
        0.001117365318350494,
        0.05597403272986412,
        0.041031718254089355,
        -0.019535532221198082,
        -0.03843965753912926,
        -0.03535231947898865,
        -0.04387378692626953,
        0.03508804738521576,
        0.04269612208008766,
        0.03866274282336235,
        -0.055924031883478165,
        0.035058263689279556,
        -0.03848763182759285,
        0.06016338989138603,
        -0.06163698807358742,
        0.03866386413574219,
        0.04507553577423096,
        -0.0514322891831398,
        -0.023297972977161407,
        0.04218244552612305,
        -0.046216826885938644,
        -0.05688057467341423,
        -0.05381231755018234,
        -0.02644597366452217,
        -0.060122404247522354,
        -0.021360117942094803,
        0.061656903475522995,
        0.03698160499334335,
        0.006591249722987413,
        -0.001519222860224545,
        -0.03408700227737427,
        0.05752239376306534,
        -0.03661276400089264,
        0.04805630445480347,
        -0.035349857062101364,
        -0.033497877418994904,
        -0.044014837592840195,
        -0.030771536752581596,
        0.0014618256827816367,
        -0.03901040926575661,
        -0.05283132195472717,
        -0.04178370535373688,
        -0.05788104236125946,
        0.03762754797935486,
        0.016262192279100418,
        0.02984425239264965,
        -0.06260303407907486,
        0.052493270486593246,
        -0.062107112258672714,
        0.058296218514442444,
        -0.061610303819179535,
        0.05825347453355789,
        0.02139386348426342,
        -0.018289335072040558,
        -0.006962214130908251,
        -0.025981200858950615,
        0.0038771270774304867,
        0.05632823333144188,
        -0.05570230260491371,
        -0.05590936914086342,
        0.0613723024725914,
        -0.05583448335528374,
        -0.049538690596818924,
        0.06000068038702011,
        0.060764264315366745,
        -0.009856442920863628,
        0.0451609306037426,
        -0.01611381210386753,
        -0.032612282782793045,
        0.047420140355825424,
        0.0332515724003315,
        -0.013408091850578785,
        -0.05657777190208435,
        0.056414127349853516,
        -0.022432714700698853,
        -0.06267247349023819,
        -0.05736502259969711,
        0.023178255185484886,
        -0.01801268942654133,
        -0.057686273008584976,
        0.039943479001522064,
        -0.031693704426288605,
        0.05317258834838867,
        0.045456577092409134,
        0.05061911419034004,
        0.044420354068279266,
        0.0534779392182827,
        -0.008062390610575676,
        -0.06166582927107811,
        -0.030620701611042023,
        0.051467616111040115,
        -0.05551304668188095,
        0.027310272678732872,
        0.058673352003097534,
        -0.059856269508600235,
        0.053557828068733215,
        -0.05790585279464722,
        0.022779112681746483,
        0.045058220624923706,
        0.04950312525033951,
        -0.052003808319568634,
        0.01563032530248165,
        0.060059018433094025,
        -0.023364655673503876,
        -0.0012518911389634013,
        0.0007344146724790335,
        0.05791685730218887,
        0.012146247550845146,
        -0.029737204313278198,
        0.05692277476191521,
        -0.059147387742996216,
        0.0589958131313324,
        -0.05411466956138611,
        -0.04287945479154587,
        -0.031351372599601746,
        0.007119140587747097,
        -0.045403990894556046,
        0.004790319595485926,
        -0.029102152213454247,
        -0.03679821267724037,
        0.05543119087815285,
        -0.03958073630928993,
        0.024974400177598,
        0.03887006267905235,
        0.020270735025405884,
        -0.010065581649541855,
        0.03511983901262283,
        -0.02214634045958519,
        0.014337767846882343,
        0.020935073494911194,
        0.017583919689059258,
        0.02736244723200798,
        -0.06191828474402428,
        0.04875864461064339,
        -0.05714891105890274,
        0.03695371374487877,
        -0.015111209824681282,
        0.02712382934987545,
        0.054317016154527664,
        -0.03744414821267128,
        0.05924520269036293,
        0.04281701520085335,
        0.06177113205194473,
        -0.057909514755010605,
        -0.04574327915906906,
        0.01900174841284752,
        -0.061514273285865784,
        -0.06275849044322968,
        0.055749595165252686,
        -0.06134021654725075,
        0.029327992349863052,
        -0.03810388594865799,
        -0.04865305498242378,
        -0.05260716751217842,
        0.01321396604180336,
        -0.05831660330295563,
        0.026912670582532883,
        0.03663256764411926,
        -0.015626030042767525,
        0.010823708027601242,
        0.05905335769057274,
        0.006639592349529266,
        0.02716555818915367,
        0.050495024770498276,
        0.04052268713712692,
        0.036907702684402466,
        0.004156151786446571,
        0.044751059263944626,
        -0.030317863449454308,
        -0.010659785009920597,
        0.05907437577843666,
        0.02083234302699566,
        -0.05990156903862953,
        0.006399966776371002,
        -0.004856990650296211,
        -0.060208968818187714,
        -0.05648285895586014,
        -0.05832085758447647,
        -0.014743050560355186,
        0.061916086822748184,
        0.04067619517445564,
        -0.0413067527115345,
        0.061225101351737976,
        0.037106070667505264,
        0.02230469137430191,
        0.06216345354914665,
        0.04981968551874161,
        0.028253035619854927,
        -0.004161875229328871,
        0.0563957616686821,
        0.06141648441553116,
        0.055210795253515244,
        0.028050497174263,
        -0.05045945197343826,
        -0.0538480281829834,
        -0.062488190829753876,
        -0.051858242601156235,
        -0.057158369570970535,
        -0.05931667611002922,
        0.022720227017998695,
        -0.05730340629816055,
        0.061377719044685364,
        0.020222891122102737,
        -0.060061976313591,
        -0.0553763248026371,
        -0.007547480054199696,
        0.05194057896733284,
        -0.04797305911779404,
        -0.04597523435950279,
        -0.0406530536711216,
        0.05781363695859909,
        -0.05934735760092735,
        -0.06223895773291588,
        0.016204319894313812,
        -0.04904567822813988,
        0.05357835441827774,
        0.03512512147426605,
        -0.02952711284160614,
        0.007497044745832682,
        -0.030549388378858566,
        0.06211903318762779,
        -0.0027650592382997274,
        -0.0312662236392498,
        0.056705720722675323,
        -0.06164906919002533,
        0.040226493030786514,
        0.04141322895884514,
        0.03987210616469383,
        0.051963966339826584,
        -0.04530701786279678,
        -0.05047784000635147,
        0.0005929553881287575,
        0.052503060549497604,
        -0.02409200556576252,
        -0.0054235500283539295,
        0.012159698642790318,
        -0.028331350535154343,
        0.06024237349629402,
        -0.06106995791196823,
        -0.05834031477570534,
        0.05596407502889633,
        0.054302606731653214,
        0.03710341081023216,
        -0.05474090948700905,
        -0.052347857505083084,
        -0.02925609052181244,
        0.05379628390073776,
        -0.05949174240231514,
        0.014021793380379677,
        0.06010853499174118,
        0.060943350195884705,
        0.013292290270328522,
        0.027329182252287865,
        -0.03537518158555031,
        0.01959037221968174,
        0.02685077115893364,
        0.05217890068888664,
        -0.05436078831553459,
        0.05680935084819794,
        0.0016798487631604075,
        -0.03580120950937271,
        0.05933256447315216,
        -0.009970301762223244,
        0.06138279289007187,
        0.035937920212745667,
        -0.0298782829195261,
        -0.048734720796346664,
        -0.058369964361190796,
        0.06287121772766113,
        0.024486063048243523,
        -0.00805398728698492,
        0.050142448395490646,
        0.004735184367746115,
        0.04876921325922012,
        -0.06139974668622017,
        0.05026379972696304,
        -0.050021398812532425,
        -0.05630316212773323,
        -0.03714626654982567,
        0.0554254986345768,
        -0.010765993036329746,
        -0.05382254347205162,
        0.007627359125763178,
        -0.05255825072526932,
        0.05195518955588341,
        0.033874597400426865,
        -0.05738656222820282,
        -0.03590259701013565,
        0.011969116516411304,
        -0.048713672906160355,
        0.06263944506645203,
        0.0002836876083165407,
        0.05923966318368912,
        -0.06270777434110641,
        -0.04175052046775818,
        -0.06001971662044525,
        0.06022122502326965,
        0.04259369522333145,
        0.06084822863340378,
        0.04391045123338699,
        -0.0020246857311576605,
        -0.02806892618536949,
        -0.05290951579809189,
        -0.06266174465417862,
        -0.0572616346180439,
        0.05994068458676338,
        -0.06092098727822304,
        0.021522847935557365,
        0.062050703912973404,
        -0.03966911509633064,
        0.05992325395345688,
        -0.06197884678840637,
        -0.05646674335002899,
        -0.061692651361227036,
        -0.03878454491496086,
        0.02860952541232109,
        -0.04214754328131676,
        0.03353672847151756,
        -0.007532055955380201,
        0.059813741594552994,
        0.04925902560353279,
        -0.032792847603559494,
        -0.05527454987168312,
        -0.06124185770750046,
        -0.05167294293642044,
        0.05785272270441055,
        0.05145955830812454,
        0.054246749728918076,
        -0.006076879799365997,
        0.004759230185300112,
        0.0558880940079689,
        -0.03029421903192997,
        0.05927189439535141,
        -0.05855197086930275,
        0.0544678270816803,
        0.016717588528990746,
        -0.04019608721137047,
        0.03198898211121559,
        0.05049676448106766,
        0.0060842339880764484,
        0.04885689541697502,
        0.0028063408099114895,
        -0.005348938517272472,
        -0.04460107535123825,
        -0.061138737946748734,
        -0.05766496807336807,
        -0.029168350622057915,
        -0.06126765161752701,
        0.05029686912894249,
        -0.04677148163318634,
        -0.03719030320644379,
        -0.05062853917479515,
        -0.06086155027151108,
        -0.037429191172122955,
        0.039077598601579666,
        0.008365130051970482,
        0.017027849331498146,
        -0.018333595246076584,
        0.028272321447730064,
        -0.04715414717793465,
        -0.0502585843205452,
        -0.013533598743379116,
        -0.005220381077378988,
        -0.010119779966771603,
        0.03484153747558594,
        0.01734444871544838,
        0.05641697347164154,
        0.051045794039964676,
        -0.038591619580984116,
        0.02364342287182808,
        0.062447529286146164,
        -0.059261757880449295,
        0.056996092200279236,
        -0.05572446808218956,
        -0.04685698449611664,
        0.02084304764866829,
        0.061769235879182816,
        -0.045414913445711136,
        0.035487547516822815,
        -0.04671573266386986,
        0.043758291751146317,
        0.015890326350927353,
        0.020713431760668755,
        0.045340199023485184,
        -0.0005253743729554117,
        0.05116656422615051,
        -0.02221004292368889,
        0.029971571639180183,
        0.06038803607225418,
        0.062218815088272095,
        -0.061281971633434296,
        0.061887722462415695,
        -0.015138417482376099,
        -0.06207750737667084,
        0.020247258245944977,
        0.002275571459904313,
        0.05027400329709053,
        0.051969386637210846,
        0.05593428015708923,
        0.017984339967370033,
        -0.02249142900109291,
        0.046310365200042725,
        -0.02405664138495922,
        0.044223055243492126,
        0.0412246398627758,
        -0.03716582804918289,
        0.051501546055078506,
        0.0405089370906353,
        -0.000776861677877605,
        0.05533481389284134,
        0.05391924828290939,
        -0.03165623918175697,
        -0.044782042503356934,
        -0.028300706297159195,
        -0.043715011328458786,
        -0.055751580744981766,
        0.0014296481385827065,
        0.007571662776172161,
        0.05733052268624306,
        0.05671752616763115,
        -0.06131405383348465,
        -0.04634931683540344,
        0.049688950181007385,
        0.021053045988082886,
        0.054774072021245956,
        0.028682734817266464,
        -0.04506823420524597,
        0.018795669078826904,
        0.013909476809203625,
        0.01564786583185196,
        0.02157924324274063,
        -0.046258315443992615,
        -0.040043968707323074,
        -0.03996341675519943,
        0.06256699562072754,
        -0.01026542391628027
    ],
    [
        -0.05893593654036522,
        0.02748320810496807,
        0.0584305003285408,
        0.01761360839009285,
        0.05661493539810181,
        -0.010902329348027706,
        0.05897189676761627,
        0.024926189333200455,
        -0.053175508975982666,
        0.04777419567108154,
        0.04658738523721695,
        0.05652966350317001,
        -0.05421822890639305,
        -0.054895978420972824,
        -0.051625289022922516,
        0.03756467252969742,
        -0.05959509685635567,
        0.01337306760251522,
        -0.059089310467243195,
        -0.017274726182222366,
        0.046885233372449875,
        0.05700237303972244,
        0.05918891727924347,
        0.01613224856555462,
        0.05277268961071968,
        -0.05354095250368118,
        -0.024569915607571602,
        0.0588475726544857,
        -0.030533602461218834,
        0.004916558042168617,
        0.058695193380117416,
        -0.059560492634773254,
        0.05056627467274666,
        -0.05795421451330185,
        -0.03772842511534691,
        -0.05754700303077698,
        -0.049805834889411926,
        -0.05454901605844498,
        -0.057405296713113785,
        0.04439758136868477,
        -0.04186958819627762,
        0.015602733939886093,
        0.05465000122785568,
        -0.03261198848485947,
        -0.0222331415861845,
        -0.034922461956739426,
        0.05876370891928673,
        0.05375206097960472,
        0.04700586944818497,
        0.04806126654148102,
        0.02507191151380539,
        -0.05455773323774338,
        0.05763900652527809,
        0.0035013114102184772,
        0.05203498527407646,
        -0.050668686628341675,
        -0.027368826791644096,
        0.00360495294444263,
        0.04552680253982544,
        -0.00674453703686595,
        -0.05152927711606026,
        -0.004289219155907631,
        -0.050849270075559616,
        -0.0031387500930577517,
        -0.032612867653369904,
        0.009808463044464588,
        0.03673779219388962,
        -0.05765891075134277,
        0.020426269620656967,
        -0.059613317251205444,
        -0.059100791811943054,
        -0.05878809094429016,
        -0.021728144958615303,
        -0.04711638763546944,
        -0.05952838435769081,
        0.0017486559227108955,
        -0.0047874716110527515,
        0.056546591222286224,
        -0.016478046774864197,
        -0.048232175409793854,
        -0.03588289022445679,
        0.057788100093603134,
        -0.05541610345244408,
        0.04211299121379852,
        -0.04052520543336868,
        0.014161172322928905,
        -0.057101283222436905,
        -0.027065420523285866,
        0.007078144699335098,
        0.05942049250006676,
        -0.053497299551963806,
        0.021979467943310738,
        0.054299768060445786,
        -0.05505458638072014,
        0.013923229649662971,
        -0.01341837178915739,
        -0.0433003231883049,
        -0.02266750857234001,
        -0.04554767534136772,
        -0.012461931444704533,
        -0.05875010788440704,
        -0.048065852373838425,
        0.053616687655448914,
        0.053793080151081085,
        0.02138430066406727,
        0.0013444781070575118,
        0.055850885808467865,
        0.01833580993115902,
        -0.026576217263936996,
        -0.002217183355242014,
        -0.050445958971977234,
        -0.05641184002161026,
        -0.024620607495307922,
        -0.043266359716653824,
        0.029895417392253876,
        -0.04758027568459511,
        -0.020738549530506134,
        -0.04570690914988518,
        -0.0579393096268177,
        0.00937994197010994,
        0.037079211324453354,
        0.005599097814410925,
        -0.059355463832616806,
        0.05545981973409653,
        -0.05914372205734253,
        -0.003247847780585289,
        -0.05429305508732796,
        0.05457240715622902,
        0.04861071705818176,
        0.023975268006324768,
        -0.007430234458297491,
        -0.04637230932712555,
        0.02480883151292801,
        0.0481572300195694,
        -0.03944911062717438,
        -0.04621456563472748,
        0.04834573343396187,
        -0.050166163593530655,
        -0.05266415700316429,
        0.059039048850536346,
        0.04857071489095688,
        0.05124833434820175,
        0.03533897548913956,
        0.02496025338768959,
        -0.044382162392139435,
        0.04791749641299248,
        0.049588803201913834,
        -0.02114207297563553,
        -0.059157975018024445,
        0.058424510061740875,
        0.052717726677656174,
        -0.057178795337677,
        -0.056808773428201675,
        0.04940961301326752,
        0.058543335646390915,
        -0.04281752556562424,
        0.059586167335510254,
        0.025914233177900314,
        -0.025671882554888725,
        0.011399680748581886,
        0.04880468174815178,
        0.023160690441727638,
        0.0017031219322234392,
        -0.058207105845212936,
        -0.05280166119337082,
        -0.025235896930098534,
        -0.04939853772521019,
        -0.05955561250448227,
        -0.003635719185695052,
        0.04957795888185501,
        -0.052274614572525024,
        -0.049282923340797424,
        -0.047112006694078445,
        -0.030165739357471466,
        0.005756628233939409,
        0.058478474617004395,
        -0.057458508759737015,
        0.052380360662937164,
        0.04165060445666313,
        -0.0027400085236877203,
        0.054989803582429886,
        0.0013298335252329707,
        0.03223014995455742,
        0.052740130573511124,
        0.012426765635609627,
        0.02687663771212101,
        -0.04858801141381264,
        0.05688617005944252,
        -0.049438949674367905,
        -0.032683365046978,
        0.027398113161325455,
        -0.03602196276187897,
        -0.056553397327661514,
        -0.011354970745742321,
        -0.04163256287574768,
        -0.05233323201537132,
        0.02471843920648098,
        -0.026381196454167366,
        -0.05583329126238823,
        0.02486737072467804,
        0.024956585839390755,
        0.058956392109394073,
        0.003602829063311219,
        -0.002683775033801794,
        -0.006615655031055212,
        0.05676768720149994,
        0.04308663308620453,
        -0.021677184849977493,
        -0.019373878836631775,
        -0.0017339875921607018,
        -0.04957751929759979,
        0.0395231731235981,
        -0.0221731998026371,
        -0.010134339332580566,
        0.03509189933538437,
        0.002977445488795638,
        0.053718652576208115,
        0.02274254336953163,
        0.05654429644346237,
        -0.05905387923121452,
        -0.05432061478495598,
        -0.056869007647037506,
        -0.038319312036037445,
        -0.05960221588611603,
        0.03813775256276131,
        -0.04197939112782478,
        -0.01073462888598442,
        0.048812586814165115,
        -0.007415579631924629,
        -0.02531919628381729,
        -0.049599260091781616,
        -0.05783901363611221,
        0.010686961002647877,
        0.037495192140340805,
        -0.0009196644532494247,
        -0.041913390159606934,
        0.05215999111533165,
        -0.0124156279489398,
        0.05471080541610718,
        0.05857536569237709,
        0.05601909011602402,
        0.03424718976020813,
        -0.0013654064387083054,
        0.00041741272434592247,
        -0.05394404008984566,
        -0.03357498347759247,
        0.031184829771518707,
        -0.024442117661237717,
        -0.05746173486113548,
        0.01321306824684143,
        0.057267360389232635,
        -0.057282984256744385,
        0.037879981100559235,
        -0.05671481043100357,
        0.03815717622637749,
        0.05896119028329849,
        -0.02697760798037052,
        0.01696399785578251,
        0.05901842191815376,
        0.057934898883104324,
        0.04867168143391609,
        0.0582912340760231,
        0.03362758085131645,
        0.05597070977091789,
        -0.052383147180080414,
        0.03720215708017349,
        0.055887866765260696,
        0.05673709884285927,
        0.04649626836180687,
        -0.02897971123456955,
        -0.043656107038259506,
        -0.058648236095905304,
        -0.0005252767587080598,
        -0.05557264760136604,
        0.02802545577287674,
        0.03726544603705406,
        -0.057091377675533295,
        0.005886562168598175,
        -0.058792442083358765,
        -0.050043586641550064,
        -0.05048277974128723,
        -0.057268593460321426,
        0.05675564333796501,
        0.05857080593705177,
        -0.05599800869822502,
        -0.024358805269002914,
        0.0041770110838115215,
        -0.05703701078891754,
        -0.05049441382288933,
        0.027757925912737846,
        -0.050062280148267746,
        0.029663780704140663,
        0.024658329784870148,
        -0.01749231107532978,
        0.05255662277340889,
        0.012751650996506214,
        0.0592835359275341,
        0.043163739144802094,
        -0.02301229164004326,
        0.04979187995195389,
        -0.05733421817421913,
        0.04992424696683884,
        0.005223507061600685,
        0.0382668673992157,
        -0.05635128170251846,
        -0.05773184821009636,
        -0.047881051898002625,
        0.04667254909873009,
        0.05864672735333443,
        -0.049223799258470535,
        0.05599847435951233,
        -0.017512759193778038,
        -0.05906226858496666,
        0.057907361537218094,
        -0.05889109894633293,
        -0.04316031560301781,
        0.05755353346467018,
        0.053850676864385605,
        0.009130510501563549,
        -0.04644254595041275,
        -0.052297428250312805,
        0.034830112010240555,
        0.058575186878442764,
        -0.058526407927274704,
        0.03166445717215538,
        0.05845235660672188,
        0.0071712275967001915,
        0.03810707479715347,
        0.04052108898758888,
        0.01194259524345398,
        -0.013015678152441978,
        0.05402277782559395,
        0.04067013040184975,
        0.05842581018805504,
        0.053080182522535324,
        0.04822497442364693,
        -0.0578223392367363,
        0.054753921926021576,
        -0.043448805809020996,
        0.011305226944386959,
        0.04436100646853447,
        -0.05833418667316437,
        -0.055677566677331924,
        -0.011026362888514996,
        0.05961156636476517,
        -0.0035227870102971792,
        0.02556648477911949,
        0.03547310456633568,
        -0.049226000905036926,
        0.05567767843604088,
        -0.05957451090216637,
        0.005792020354419947,
        -0.05372251942753792,
        -0.05783208832144737,
        -0.055054135620594025,
        0.0543292872607708,
        -0.004978074226528406,
        -0.04734279215335846,
        -0.05815238878130913,
        -0.050969116389751434,
        0.04959921911358833,
        0.04345342516899109,
        -0.05820561572909355,
        -0.03726206719875336,
        -0.03989759460091591,
        -0.05419456213712692,
        0.059552714228630066,
        0.01217801496386528,
        0.030039578676223755,
        -0.05949315056204796,
        -0.046631358563899994,
        -0.0591951459646225,
        0.04486243054270744,
        0.0048494222573935986,
        0.053100522607564926,
        0.05960870161652565,
        0.03494594246149063,
        0.05189404636621475,
        -0.053216662257909775,
        -0.05921027064323425,
        -0.05384497344493866,
        0.046837858855724335,
        -0.05708576738834381,
        -0.04430980607867241,
        0.05279581621289253,
        -0.0539536327123642,
        0.030604803934693336,
        -0.05830636993050575,
        -0.0568552166223526,
        -0.05770627409219742,
        -0.04006752371788025,
        0.04816026985645294,
        0.02507811039686203,
        0.050005245953798294,
        -0.05524091422557831,
        0.05339714139699936,
        0.041337739676237106,
        -0.053313806653022766,
        0.029714783653616905,
        -0.055209092795848846,
        -0.04970216751098633,
        0.034703630954027176,
        0.05614903196692467,
        0.045302990823984146,
        0.015034536831080914,
        0.056737348437309265,
        0.0589035227894783,
        -0.009534112177789211,
        0.05367192625999451,
        -0.04075677692890167,
        0.04782836511731148,
        0.03627164661884308,
        -0.048985641449689865,
        -0.02982640452682972,
        0.05330020561814308,
        0.022074388340115547,
        0.037848539650440216,
        0.05396619439125061,
        0.03748789429664612,
        -0.022578107193112373,
        -0.05885876715183258,
        -0.04712294787168503,
        -0.05160657688975334,
        -0.05305473133921623,
        0.02953779138624668,
        0.040402576327323914,
        -0.021844040602445602,
        -0.04066624492406845,
        -0.05736565589904785,
        -0.03958114609122276,
        0.059382762759923935,
        -0.010888948105275631,
        0.015344941057264805,
        0.05778945982456207,
        0.0462835431098938,
        0.015472302213311195,
        -0.04114627465605736,
        0.05427994206547737,
        0.014536798931658268,
        -0.05474082753062248,
        -0.05258353054523468,
        -0.018385862931609154,
        0.04553939402103424,
        0.04605874791741371,
        -0.048551738262176514,
        -0.031249141320586205,
        0.05889536440372467,
        -0.057534415274858475,
        0.053200189024209976,
        -0.05927284061908722,
        -0.05192238464951515,
        0.054569583386182785,
        0.057036612182855606,
        -0.019734026864171028,
        0.05165943503379822,
        -0.013536417856812477,
        0.042471978813409805,
        0.04499314725399017,
        -0.0563863143324852,
        -0.031356655061244965,
        0.03656759858131409,
        0.009671308100223541,
        -0.052267707884311676,
        -0.05010351911187172,
        0.05554238706827164,
        0.058810316026210785,
        -0.0593668669462204,
        0.05882759764790535,
        -0.0477256216108799,
        -0.058545298874378204,
        0.047179415822029114,
        0.04419171065092087,
        0.045871298760175705,
        0.059222664684057236,
        0.033104058355093,
        -0.017123857513070107,
        -0.020482420921325684,
        0.04684121534228325,
        -0.05221538618206978,
        -0.04884269833564758,
        0.05671048164367676,
        0.020126936957240105,
        0.024708136916160583,
        0.04306316748261452,
        0.013173208571970463,
        0.04302184283733368,
        0.013570942915976048,
        -0.052580736577510834,
        -0.050991304218769073,
        -0.040530018508434296,
        0.020518453791737556,
        -0.011969317682087421,
        -0.037765685468912125,
        0.052892811596393585,
        0.05911220610141754,
        -0.020602606236934662,
        -0.03645564243197441,
        -0.05604258552193642,
        0.05157000198960304,
        0.045951299369335175,
        -0.031757816672325134,
        0.04307765141129494,
        0.01709185354411602,
        -0.0532691515982151,
        0.029158590361475945,
        0.0038176951929926872,
        0.0028991932049393654,
        0.022075096145272255,
        -0.055409807711839676,
        0.025404293090105057,
        0.04591617360711098,
        0.05765380710363388
    ],
    [
        0.04850785434246063,
        -0.05966028571128845,
        0.036525093019008636,
        0.036230046302080154,
        -0.019980451092123985,
        -0.04770933464169502,
        0.04569978266954422,
        -0.05938737094402313,
        0.05277833715081215,
        -0.051993921399116516,
        0.04877824708819389,
        -0.055312011390924454,
        -0.04410240799188614,
        -0.051087770611047745,
        -0.058114420622587204,
        -0.03760511055588722,
        -0.024247070774435997,
        0.05911571532487869,
        -0.050550609827041626,
        0.015578879974782467,
        -0.010809723287820816,
        -0.03697450831532478,
        0.058216601610183716,
        -0.05711551383137703,
        -0.045440997928380966,
        0.02801614999771118,
        -0.03147842735052109,
        -0.0436801016330719,
        0.021895166486501694,
        0.0037936465814709663,
        -0.03608858585357666,
        0.05323324352502823,
        0.056439004838466644,
        -0.059528518468141556,
        0.05584311485290527,
        -0.059463709592819214,
        -0.05877567455172539,
        -0.04081711173057556,
        0.05274384841322899,
        -0.00323559925891459,
        0.012213900685310364,
        -0.0013693181099370122,
        0.05582644045352936,
        -0.04971745237708092,
        0.052986178547143936,
        0.0548965148627758,
        -0.005366940516978502,
        0.05933814495801926,
        0.05958271026611328,
        0.055698659271001816,
        0.05904782935976982,
        0.049866996705532074,
        0.047880396246910095,
        0.0103150000795722,
        -0.055994998663663864,
        -0.012092767283320427,
        -0.05697380378842354,
        -0.02488626353442669,
        -0.05427256599068642,
        -0.05933774262666702,
        -0.05966879054903984,
        0.03984103351831436,
        -0.05611760914325714,
        0.012204807251691818,
        0.03646397218108177,
        -0.05779135227203369,
        -0.03785499557852745,
        -0.02635892480611801,
        -0.04289984703063965,
        -0.05757713317871094,
        -0.009054603055119514,
        -0.05423964187502861,
        0.05955449864268303,
        0.05904537811875343,
        0.058700840920209885,
        -0.021083906292915344,
        0.05379656329751015,
        0.05966707319021225,
        0.036560602486133575,
        -0.0259931031614542,
        -0.017054881900548935,
        0.018310340121388435,
        -0.059495631605386734,
        0.059702418744564056,
        -0.008011755533516407,
        0.05778263509273529,
        -0.05248646065592766,
        -0.0034484800416976213,
        -0.03765435144305229,
        0.057795606553554535,
        -0.039426058530807495,
        -0.02758939005434513,
        -0.059170208871364594,
        -0.03673980385065079,
        -0.04532726854085922,
        0.052150048315525055,
        -0.04023279994726181,
        -0.0596177838742733,
        -0.04150567948818207,
        0.04947391524910927,
        -0.05904322490096092,
        0.0517631433904171,
        0.05283205956220627,
        -0.03564043343067169,
        -0.0491948239505291,
        -0.009886344894766808,
        0.05851094424724579,
        0.012141097337007523,
        -0.043070487678050995,
        0.02194218710064888,
        0.055713992565870285,
        -0.05054639279842377,
        -0.04401000589132309,
        0.036452457308769226,
        0.052183933556079865,
        0.0323486328125,
        -0.03767763450741768,
        -0.04112061485648155,
        0.04676930606365204,
        -0.05317828059196472,
        0.03888460993766785,
        0.04728874936699867,
        -0.018991747871041298,
        -0.01632988452911377,
        -0.030509382486343384,
        -0.04353519156575203,
        0.053661786019802094,
        0.036528218537569046,
        0.05963830277323723,
        -0.02846815250813961,
        0.05946214869618416,
        -0.05787929147481918,
        -0.015868334099650383,
        0.05609685555100441,
        -0.056748248636722565,
        -0.03945966437458992,
        0.05942017212510109,
        -0.018366076052188873,
        0.01131644006818533,
        0.05705772340297699,
        0.01503803487867117,
        -0.03743055835366249,
        0.056929249316453934,
        -0.05675521120429039,
        -0.05596878007054329,
        0.04465436190366745,
        0.0354219414293766,
        0.05914239585399628,
        -0.0550709143280983,
        -0.031950000673532486,
        0.054247867316007614,
        -0.05959237739443779,
        -0.04252016916871071,
        -0.038665320724248886,
        0.05482584238052368,
        -0.050574854016304016,
        -0.05526290088891983,
        -0.015620097517967224,
        -0.007399093359708786,
        -0.00780032854527235,
        0.05128326267004013,
        0.05292271077632904,
        0.050340987741947174,
        0.036306820809841156,
        0.05952782556414604,
        -0.022910330444574356,
        0.006054223980754614,
        0.004781794268637896,
        -0.058534886687994,
        -0.01280145812779665,
        0.04329940676689148,
        0.032174285501241684,
        -0.04635214805603027,
        -0.048675231635570526,
        -0.051601216197013855,
        0.015304929576814175,
        0.03984479606151581,
        0.053999800235033035,
        0.01893448643386364,
        -0.0002526941243559122,
        -0.04382004961371422,
        0.05968476086854935,
        -0.03732503578066826,
        -0.03558223694562912,
        -0.009374403394758701,
        -0.029406532645225525,
        0.016143733635544777,
        0.05716820806264877,
        0.04499882459640503,
        -0.0525125227868557,
        -0.04856962710618973,
        0.039780788123607635,
        0.0015458458801731467,
        0.051324550062417984,
        -0.05963921174407005,
        -0.03504467010498047,
        0.05351705849170685,
        0.05642642825841904,
        -0.044413503259420395,
        -0.013098876923322678,
        0.05805843323469162,
        0.05126212164759636,
        -0.041616566479206085,
        -0.05628610774874687,
        -0.009375927969813347,
        -0.01597645878791809,
        0.05903023108839989,
        0.04185585305094719,
        -0.028636762872338295,
        0.059203725308179855,
        -0.04769131541252136,
        0.058222074061632156,
        0.004986461251974106,
        0.053212620317935944,
        0.009012965485453606,
        -0.05928059667348862,
        -0.030160441994667053,
        -0.011651110835373402,
        0.05927396938204765,
        0.04256482422351837,
        0.017609989270567894,
        0.00042709632543846965,
        0.05579477548599243,
        -0.041751619428396225,
        0.059647295624017715,
        -0.059323519468307495,
        0.01844867318868637,
        0.023758133873343468,
        0.05716856196522713,
        -0.05392446368932724,
        -0.044727519154548645,
        -0.054561469703912735,
        0.021715600043535233,
        0.05901310220360756,
        -0.05767730250954628,
        0.050738703459501266,
        0.05429103597998619,
        -0.011202576570212841,
        0.009043078869581223,
        0.0597219355404377,
        -0.025823496282100677,
        0.024305371567606926,
        -0.05958307161927223,
        0.03536133095622063,
        0.051551252603530884,
        -0.057183172553777695,
        -0.04588332027196884,
        0.028001174330711365,
        -0.004777255468070507,
        -0.032230883836746216,
        -0.055473536252975464,
        -0.05929718539118767,
        -0.03929457440972328,
        -0.05630643293261528,
        0.04526771232485771,
        -0.01822727546095848,
        0.054161421954631805,
        -0.04818030074238777,
        0.03676079213619232,
        0.059353169053792953,
        -0.012456724420189857,
        -0.03080110065639019,
        -0.04549819603562355,
        0.05662088841199875,
        -0.057260338217020035,
        0.04688524082303047,
        0.0038123654667288065,
        0.023544102907180786,
        -0.05898328125476837,
        -0.0038932044990360737,
        0.05808194354176521,
        -0.05921244993805885,
        0.05882323160767555,
        -0.0590076744556427,
        -0.056426145136356354,
        0.00655120937153697,
        -0.036739230155944824,
        -0.024933289736509323,
        -0.0037253363989293575,
        -0.05251928046345711,
        -0.056207649409770966,
        -0.021317169070243835,
        -0.05447957664728165,
        0.008181051351130009,
        -0.05606648325920105,
        -0.04492618143558502,
        0.03661683574318886,
        0.032335199415683746,
        0.03999113664031029,
        0.0203242190182209,
        -0.022529592737555504,
        -0.05205870792269707,
        -0.022410565987229347,
        0.046258196234703064,
        0.05745641887187958,
        -0.056351520121097565,
        0.0595550499856472,
        0.04714783653616905,
        -0.04735281690955162,
        0.056996818631887436,
        -0.04237623140215874,
        0.041092317551374435,
        0.030160848051309586,
        0.055838942527770996,
        -0.05912153795361519,
        -0.055087439715862274,
        0.04672861099243164,
        0.049439121037721634,
        0.05333179235458374,
        -0.028486426919698715,
        0.020284082740545273,
        -0.03212282061576843,
        0.013314526528120041,
        0.053563278168439865,
        0.028402909636497498,
        -0.0514366440474987,
        0.0151518564671278,
        -0.019910365343093872,
        0.05915863439440727,
        -0.04134674370288849,
        -0.004520747344940901,
        -0.04371408745646477,
        0.05924411490559578,
        0.02172943763434887,
        0.04879024252295494,
        0.0218617245554924,
        0.053690046072006226,
        -0.058433033525943756,
        0.001230965950526297,
        0.0537145622074604,
        -0.037578824907541275,
        0.025652263313531876,
        -0.0523042194545269,
        0.009230788797140121,
        0.0401914156973362,
        0.013283447362482548,
        -0.056707657873630524,
        0.04585113003849983,
        -0.05438859760761261,
        -0.009242533706128597,
        -0.01701171137392521,
        0.012998522259294987,
        0.05731898173689842,
        0.002831794088706374,
        0.05324282869696617,
        0.048561517149209976,
        -0.0030581422615796328,
        0.05917523428797722,
        0.014587224461138248,
        0.02487141452729702,
        0.012412350624799728,
        -0.0445835255086422,
        -0.0492837019264698,
        -0.015590242110192776,
        -0.05735235661268234,
        -0.03205718472599983,
        -0.04537273570895195,
        -0.054123200476169586,
        -0.044409237802028656,
        -0.055453117936849594,
        0.02187245897948742,
        0.05077632516622543,
        -0.033797476440668106,
        -0.02768097259104252,
        0.01700269803404808,
        -0.02098170295357704,
        0.05880831182003021,
        -0.057024769484996796,
        0.022086625918745995,
        -0.05777614563703537,
        -0.03695674240589142,
        -0.03990912809967995,
        -0.05425236374139786,
        -0.058444391936063766,
        0.058943185955286026,
        0.03491995111107826,
        0.04230856895446777,
        -0.03046114556491375,
        0.05677718296647072,
        0.013305909000337124,
        0.039040423929691315,
        -0.0024341843090951443,
        -0.04845404997467995,
        0.05273875221610069,
        0.04755063354969025,
        -0.058402277529239655,
        -0.030037712305784225,
        0.010774768888950348,
        -0.05786691978573799,
        0.01930326037108898,
        -0.059256426990032196,
        0.045968957245349884,
        -0.026530727744102478,
        0.016930807381868362,
        0.04856524243950844,
        0.0570686012506485,
        0.056610602885484695,
        -0.017701884731650352,
        -0.043971288949251175,
        -0.059179503470659256,
        -0.056394707411527634,
        0.05971921607851982,
        0.04419325292110443,
        0.05490494519472122,
        -0.05108237639069557,
        0.04689207300543785,
        0.010358714498579502,
        -0.03112638369202614,
        0.059690579771995544,
        0.05003054067492485,
        0.03669573366641998,
        0.043640971183776855,
        0.05886251851916313,
        0.010628039948642254,
        0.057132694870233536,
        -0.057119954377412796,
        0.023719362914562225,
        0.014827328734099865,
        0.05967538058757782,
        -0.03385809063911438,
        0.05662744864821434,
        -0.0596877820789814,
        -0.04613637924194336,
        -0.059180766344070435,
        0.009794223122298717,
        0.0221572108566761,
        -0.0447579100728035,
        0.032724443823099136,
        -0.058569084852933884,
        -0.05828866735100746,
        0.030170036479830742,
        0.01929028518497944,
        -0.05773405358195305,
        -0.05857172980904579,
        -0.03490718826651573,
        -0.05772488936781883,
        -0.05967072769999504,
        0.05647975951433182,
        -0.04192446544766426,
        0.044012121856212616,
        -0.056808602064847946,
        0.05085516721010208,
        0.05804746225476265,
        0.04880262911319733,
        0.056711889803409576,
        -0.02243044227361679,
        0.04957727715373039,
        -0.034568604081869125,
        0.004761075600981712,
        0.03466853126883507,
        0.026458969339728355,
        0.05879388749599457,
        0.053315725177526474,
        0.05799626559019089,
        -0.046437107026576996,
        -0.05866820737719536,
        -0.0018857649993151426,
        -0.050159353762865067,
        0.0032229896169155836,
        0.0341644324362278,
        0.03734938055276871,
        -0.05961286649107933,
        0.046529535204172134,
        0.021706705912947655,
        0.05113428086042404,
        0.05966364964842796,
        -0.04540315270423889,
        0.059640463441610336,
        0.0023185214959084988,
        -0.0569903589785099,
        0.04692379757761955,
        0.02929028496146202,
        0.05782211944460869,
        -0.0540059432387352,
        -0.03951871022582054,
        -0.043828196823596954,
        0.04057627171278,
        0.05776806175708771,
        -0.05595632269978523,
        -0.05667120963335037,
        -0.03914084658026695,
        -0.019179243594408035,
        0.03944979980587959,
        0.0413699597120285,
        0.011468157172203064,
        0.057470206171274185,
        -0.005108221899718046,
        0.056897785514593124,
        -0.05881626904010773,
        0.009164326824247837,
        0.03215068578720093,
        -0.027734337374567986,
        0.05465402826666832,
        -0.011153968051075935,
        0.05043204501271248,
        -0.01895136758685112,
        -0.05528015270829201,
        0.056271277368068695,
        0.05567634105682373,
        -0.052336208522319794,
        0.021489078179001808,
        -0.028317507356405258,
        0.007285034749656916,
        -0.04510775953531265,
        0.05957627296447754,
        0.013207340613007545,
        0.04444548860192299,
        0.007172964047640562,
        0.05857300013303757,
        -0.022321579977869987,
        0.04496823251247406,
        -0.05649816617369652
    ],
    [
        -0.05629705637693405,
        -0.06010688841342926,
        -0.05863387882709503,
        0.02263140119612217,
        0.0005140145076438785,
        -0.0024131834506988525,
        0.05904519185423851,
        -0.05782637745141983,
        0.0031002031173557043,
        0.052367497235536575,
        0.043034546077251434,
        0.059240274131298065,
        -0.05915501341223717,
        -0.04008934646844864,
        -0.05960002541542053,
        0.032077379524707794,
        -0.021481750532984734,
        0.03641582652926445,
        -0.04619591310620308,
        0.0560363233089447,
        -0.06080516427755356,
        0.060830552130937576,
        0.058615848422050476,
        0.02804337628185749,
        -0.01604059897363186,
        0.038864970207214355,
        0.059297025203704834,
        0.05634838715195656,
        -0.0274810791015625,
        0.06086564064025879,
        -0.022891288623213768,
        0.057254355400800705,
        -0.05694383755326271,
        -0.05838558450341225,
        0.033903561532497406,
        0.06016731634736061,
        -0.053367823362350464,
        -0.059970155358314514,
        0.020993031561374664,
        0.01533135399222374,
        0.01278720609843731,
        -0.04354225844144821,
        0.05736445263028145,
        0.027755500748753548,
        0.049343571066856384,
        -0.012190221808850765,
        0.05161549523472786,
        0.05864376202225685,
        0.06028559058904648,
        -0.06085509434342384,
        0.057405631989240646,
        0.05584549531340599,
        0.05896247178316116,
        0.01297533418983221,
        0.028004800900816917,
        -0.006552599836140871,
        -0.0600644052028656,
        0.028875740244984627,
        -0.059679701924324036,
        0.060872919857501984,
        -0.04971498250961304,
        0.026802124455571175,
        -0.045194339007139206,
        -0.06068907305598259,
        0.008944181725382805,
        0.00012487835192587227,
        0.04888607934117317,
        -0.02353595197200775,
        -0.0572502464056015,
        -0.06071607396006584,
        -0.05862021446228027,
        -0.05319356173276901,
        0.04962697625160217,
        0.032679252326488495,
        0.007210993207991123,
        -0.006092171184718609,
        0.02377244457602501,
        0.060803916305303574,
        -0.030117511749267578,
        -0.028748225420713425,
        -0.02024003677070141,
        0.055407848209142685,
        -0.059605296701192856,
        0.05358319729566574,
        0.016178607940673828,
        -0.00533625902608037,
        0.049037303775548935,
        0.060270942747592926,
        -0.059934649616479874,
        0.038085322827100754,
        -0.060836322605609894,
        -0.05958237871527672,
        -0.060536086559295654,
        -0.05983070284128189,
        0.008832884952425957,
        -0.006447655614465475,
        -0.03550093621015549,
        -0.05708080157637596,
        0.008142753504216671,
        0.012135788798332214,
        -0.05949334800243378,
        0.04647994413971901,
        0.060231518000364304,
        0.026359621435403824,
        0.04834996536374092,
        -0.01867092400789261,
        0.06067999452352524,
        0.025738446041941643,
        -0.04457372426986694,
        -0.006060867104679346,
        -0.04908369854092598,
        0.05731099843978882,
        0.00961069855839014,
        0.0021015016827732325,
        -0.02644169330596924,
        -0.015810031443834305,
        -0.002712100278586149,
        -0.058997973799705505,
        -0.06037274748086929,
        0.016767526045441628,
        0.06073448434472084,
        0.04543722793459892,
        0.05389822646975517,
        -0.0008264115313068032,
        -0.059105489403009415,
        0.01205731276422739,
        0.03316011652350426,
        0.06034082919359207,
        -0.05298849940299988,
        -0.009477736428380013,
        0.05918419733643532,
        -0.029253851622343063,
        -0.055700089782476425,
        0.013425163924694061,
        -0.04339650273323059,
        -0.04204435274004936,
        0.05845755338668823,
        -0.0070989555679261684,
        -0.04295956343412399,
        -0.02201080322265625,
        -0.052797336131334305,
        -0.03421332687139511,
        0.0434204638004303,
        -0.06087889522314072,
        -0.044619034975767136,
        0.011096084490418434,
        0.04805559292435646,
        -0.05744284763932228,
        -0.005536053329706192,
        0.05662115663290024,
        -0.05864351987838745,
        -0.05751468613743782,
        0.060767240822315216,
        0.038150422275066376,
        0.04012507572770119,
        -0.05569491162896156,
        0.00784755777567625,
        0.006537947338074446,
        -0.004961191676557064,
        0.05248480290174484,
        0.027758408337831497,
        0.03669455647468567,
        0.05140720307826996,
        0.015610790811479092,
        -0.05396589636802673,
        0.03145663067698479,
        0.04265674203634262,
        -0.04536066949367523,
        0.05127047374844551,
        0.03837166354060173,
        0.04483058303594589,
        0.0014101465931162238,
        -0.03298520669341087,
        0.05841681361198425,
        -0.054525960236787796,
        0.031468335539102554,
        -0.05315053090453148,
        0.017645346000790596,
        0.05889696627855301,
        -0.06013978645205498,
        -0.02545040287077427,
        0.053495217114686966,
        0.0558912418782711,
        0.06041787192225456,
        0.0007760135922580957,
        -0.038870710879564285,
        -0.06020806357264519,
        0.0532463975250721,
        0.0323653370141983,
        -0.034723855555057526,
        -0.004462593700736761,
        -0.034015003591775894,
        0.04599662497639656,
        0.05293843895196915,
        0.038657382130622864,
        -0.02250630035996437,
        0.0006729380111210048,
        0.0524522140622139,
        -0.05284348875284195,
        0.03718144819140434,
        0.0015885966131463647,
        0.05343415215611458,
        -0.060054074972867966,
        -0.015637347474694252,
        0.011818937957286835,
        0.03630603477358818,
        -0.0005385574186220765,
        0.045105915516614914,
        -0.054857924580574036,
        0.04947144538164139,
        -0.05548068508505821,
        0.01654810644686222,
        0.05969405174255371,
        0.058625735342502594,
        -0.051163673400878906,
        0.02476353570818901,
        0.06065371632575989,
        0.0511811301112175,
        0.06057453155517578,
        0.036122940480709076,
        0.03904200345277786,
        -0.056134119629859924,
        -0.028655922040343285,
        -0.04375405237078667,
        0.060192812234163284,
        -0.05762903764843941,
        0.016038918867707253,
        -0.014361978508532047,
        -0.060609377920627594,
        -0.023958593606948853,
        0.058873407542705536,
        -0.06017895042896271,
        0.0565924197435379,
        0.0577414408326149,
        -0.05129041150212288,
        0.011297210119664669,
        0.05719931051135063,
        0.0006075435085222125,
        0.057149164378643036,
        0.05835001915693283,
        0.02662208490073681,
        0.05822061374783516,
        -0.060409434139728546,
        0.029255498200654984,
        0.05913762003183365,
        0.025152118876576424,
        0.03705858066678047,
        -0.028800757601857185,
        -0.024461833760142326,
        0.05994172394275665,
        -0.06042550131678581,
        -0.04830820858478546,
        -0.0427977479994297,
        -0.05404449626803398,
        0.04039081931114197,
        -0.007778423372656107,
        0.0051269326359033585,
        -0.05895690247416496,
        0.055480409413576126,
        -0.04425184056162834,
        0.005718073341995478,
        0.046793632209300995,
        0.04511765390634537,
        0.05301754176616669,
        -0.05585401505231857,
        0.030733050778508186,
        0.05341903865337372,
        0.048850610852241516,
        0.018351463600993156,
        -0.05549957975745201,
        0.032046351581811905,
        -0.0608624666929245,
        -0.04241184517741203,
        -0.05777394771575928,
        -0.05562082305550575,
        0.0034850251395255327,
        -0.057149872183799744,
        -0.011286900378763676,
        -0.004708924796432257,
        -0.010675105266273022,
        -0.0582713782787323,
        0.060826487839221954,
        0.029614794999361038,
        -0.03028937429189682,
        0.016399119049310684,
        -0.05636978894472122,
        0.007975034415721893,
        0.06042732298374176,
        0.00029819764313288033,
        0.0460955835878849,
        -0.052353065460920334,
        0.05887205898761749,
        -0.05079015716910362,
        0.03657310828566551,
        0.05533374473452568,
        -0.04931463673710823,
        0.06047777086496353,
        0.057075340300798416,
        -0.06087625399231911,
        0.05396854877471924,
        0.04302510991692543,
        0.03020600788295269,
        -0.03450671210885048,
        -0.06072017550468445,
        0.0016759374411776662,
        -0.05786747485399246,
        -0.06081140413880348,
        0.0515240877866745,
        -0.008528454229235649,
        0.04767446964979172,
        0.05772512033581734,
        -0.05398333817720413,
        -0.014263317920267582,
        0.048803649842739105,
        -0.059810664504766464,
        -0.05404527857899666,
        0.030919047072529793,
        -0.03741075471043587,
        0.004458651877939701,
        -0.05699988454580307,
        0.037938252091407776,
        0.05745455250144005,
        0.047227658331394196,
        -0.04334721341729164,
        0.0210934579372406,
        0.05941024795174599,
        0.05870590731501579,
        0.05913638323545456,
        -0.03199262544512749,
        -0.05906003713607788,
        0.055547069758176804,
        0.03904453292489052,
        -0.012155847623944283,
        -0.05631352216005325,
        0.051488157361745834,
        0.048024069517850876,
        -0.0431663915514946,
        -0.021337974816560745,
        0.02699759043753147,
        0.003245917148888111,
        0.01517794094979763,
        -0.02583685703575611,
        -0.05550900846719742,
        0.005844758823513985,
        -0.012804386205971241,
        0.03536064177751541,
        0.01781430095434189,
        0.02409374713897705,
        0.021441319957375526,
        0.047936540096998215,
        -0.05912725627422333,
        -0.02131299115717411,
        -0.046583909541368484,
        -0.06044558435678482,
        0.016823966056108475,
        0.05777701735496521,
        -0.05633966252207756,
        -0.022030645981431007,
        -0.037081390619277954,
        0.01006023958325386,
        0.05511171370744705,
        0.05372653156518936,
        0.03806636109948158,
        -0.04901021346449852,
        -0.0549432672560215,
        -0.05017346516251564,
        0.06043728068470955,
        0.04152608662843704,
        -0.026869317516684532,
        -0.056131623685359955,
        -0.053968098014593124,
        -0.05650143325328827,
        0.024219732731580734,
        0.054543983191251755,
        0.053176507353782654,
        0.006479265168309212,
        -0.059861619025468826,
        -0.033917807042598724,
        0.03813307359814644,
        -0.05734867602586746,
        -0.040040068328380585,
        0.05827459320425987,
        -0.05845171585679054,
        -0.0024180137552320957,
        -0.03687531128525734,
        -0.04643869027495384,
        0.06076660007238388,
        -0.04976087808609009,
        -0.057599958032369614,
        -0.04659132659435272,
        -0.05993016064167023,
        -0.05944439023733139,
        -0.052648719400167465,
        0.02900279499590397,
        -0.04072628915309906,
        0.052194174379110336,
        0.05830198526382446,
        0.004069837741553783,
        0.023277204483747482,
        -0.06009557843208313,
        -0.056317854672670364,
        0.05537714064121246,
        0.014407509937882423,
        0.04709997773170471,
        0.001490921014919877,
        0.055256955325603485,
        0.026015399023890495,
        -0.05933605879545212,
        0.053865864872932434,
        -0.02803421951830387,
        -0.025400055572390556,
        -0.05123745650053024,
        0.013517170213162899,
        -0.023388683795928955,
        -0.004391312599182129,
        0.015340304002165794,
        0.019530752673745155,
        0.04285382106900215,
        -0.05459814518690109,
        -0.05974989011883736,
        -0.05476059764623642,
        0.044765323400497437,
        0.05003312602639198,
        -0.05962985381484032,
        0.002916937693953514,
        0.03772931545972824,
        -0.05870240926742554,
        -0.05895010381937027,
        0.030343977734446526,
        0.05570780858397484,
        -0.009572203271090984,
        -0.017679177224636078,
        -0.038023728877305984,
        -0.04339845851063728,
        0.0065488494001328945,
        -0.045439381152391434,
        -0.022171055898070335,
        -0.00878978706896305,
        -0.006623448338359594,
        -0.05402246490120888,
        0.028475958853960037,
        0.04677248001098633,
        -0.055582959204912186,
        0.054079409688711166,
        0.023963341489434242,
        0.04129922762513161,
        0.051478371024131775,
        0.03590792790055275,
        0.05298537015914917,
        0.00959732010960579,
        -0.0038162097334861755,
        0.001709617325104773,
        0.058936718851327896,
        -0.03557039424777031,
        -0.05575083941221237,
        -0.0547243170440197,
        0.038363657891750336,
        -0.014186528511345387,
        0.05804283916950226,
        0.03354835882782936,
        -0.03385280445218086,
        0.03300287947058678,
        0.0528419129550457,
        -0.05088823661208153,
        0.05318935960531235,
        0.059399884194135666,
        -0.0605422779917717,
        0.06019037216901779,
        -0.05977004021406174,
        -0.05815877020359039,
        0.05302191525697708,
        0.052917905151844025,
        -0.031157398596405983,
        0.05194246768951416,
        -0.06082354858517647,
        -0.011844459921121597,
        0.01098842453211546,
        0.017434723675251007,
        -0.047767482697963715,
        -0.0540132001042366,
        -0.049063198268413544,
        -0.012520911172032356,
        -0.025267481803894043,
        0.03736111521720886,
        -0.003542230697348714,
        0.047345150262117386,
        0.057253871113061905,
        0.015264686197042465,
        -0.057782307267189026,
        -0.01622168906033039,
        0.027592897415161133,
        -0.026124749332666397,
        0.05194036662578583,
        -0.02788597159087658,
        0.003784892149269581,
        0.016292359679937363,
        -0.058934956789016724,
        0.0480184331536293,
        0.05430964007973671,
        0.022356316447257996,
        0.02895461767911911,
        0.049410171806812286,
        0.01925613358616829,
        0.015537282451987267,
        0.05456702411174774,
        0.025218242779374123,
        -0.0007484133820980787,
        0.013806842267513275,
        -0.04985136538743973,
        -0.05752577260136604,
        0.060598332434892654,
        -0.05560430511832237
    ],
    [
        0.03384014964103699,
        -0.05180170014500618,
        -0.05157552286982536,
        0.04934108629822731,
        -0.026443952694535255,
        -0.04897409677505493,
        0.029468150809407234,
        -0.051783669739961624,
        0.050950855016708374,
        0.04820030555129051,
        0.05173894762992859,
        0.051119882613420486,
        -0.05179743468761444,
        -0.034889545291662216,
        -0.05071127787232399,
        0.0517544150352478,
        0.0517846941947937,
        0.05097063630819321,
        -0.040512870997190475,
        0.004265024326741695,
        -0.05178707838058472,
        0.05180680751800537,
        0.05107906833291054,
        0.05165322497487068,
        -0.05051659792661667,
        0.051570530980825424,
        0.04839205741882324,
        0.051685139536857605,
        -0.051559995859861374,
        0.0518060065805912,
        -0.051800213754177094,
        0.051768891513347626,
        -0.04040694609284401,
        -0.05010862275958061,
        0.04858166351914406,
        0.05175762251019478,
        -0.050084326416254044,
        -0.05103905871510506,
        -0.05134083703160286,
        0.030183179304003716,
        -0.016848912462592125,
        0.04314538091421127,
        0.04694707691669464,
        0.051655590534210205,
        0.028484556823968887,
        -0.046458955854177475,
        -0.005099342204630375,
        0.051801104098558426,
        0.0518035888671875,
        -0.05180680379271507,
        0.04607639089226723,
        0.04710516333580017,
        0.003635021857917309,
        -0.05178388953208923,
        0.04355097934603691,
        0.05024586617946625,
        -0.05155447870492935,
        0.04166477546095848,
        -0.051806602627038956,
        0.05178823694586754,
        -0.051722001284360886,
        0.04681699350476265,
        -0.05088596045970917,
        -0.05180651322007179,
        0.051360275596380234,
        0.050944894552230835,
        -0.04909756779670715,
        -0.05080118775367737,
        -0.051771484315395355,
        -0.03735900670289993,
        -0.05171795189380646,
        0.0012943434994667768,
        0.04765624925494194,
        0.05180434510111809,
        0.05166907608509064,
        0.036943528801202774,
        -0.03908728063106537,
        0.05180640518665314,
        0.04819296672940254,
        0.011261586099863052,
        -0.02653624303638935,
        -0.014423695392906666,
        -0.05180520564317703,
        0.051426880061626434,
        0.0177720095962286,
        0.04001311585307121,
        0.04208067059516907,
        0.05180434510111809,
        -0.04837403818964958,
        -0.033213868737220764,
        -0.05180630460381508,
        -0.013898860663175583,
        -0.05180676281452179,
        -0.05098128318786621,
        0.0012761839898303151,
        0.05136677995324135,
        0.023945914581418037,
        -0.0168291088193655,
        0.05155044049024582,
        0.05134964734315872,
        -0.05154203251004219,
        0.051770828664302826,
        -0.022119464352726936,
        -0.009031775407493114,
        0.040528297424316406,
        -0.05110641196370125,
        0.051802366971969604,
        0.0508376881480217,
        -0.051757827401161194,
        -0.047013625502586365,
        -0.050029825419187546,
        0.051782239228487015,
        -0.04977846145629883,
        0.0482240729033947,
        0.03604234382510185,
        0.05089152976870537,
        0.03802800551056862,
        -0.05153292044997215,
        -0.051603831350803375,
        -0.050481166690588,
        0.04868602380156517,
        0.04103942960500717,
        0.051803845912218094,
        -0.05168653279542923,
        0.042423561215400696,
        -0.04841060936450958,
        0.04666952043771744,
        0.05162576958537102,
        -0.0509394109249115,
        0.03776200860738754,
        0.051468055695295334,
        -0.04896654561161995,
        -0.05015825852751732,
        0.05081412196159363,
        -0.03685871511697769,
        -0.05170155316591263,
        0.051806747913360596,
        -0.034254513680934906,
        0.05136863887310028,
        0.01568758673965931,
        -0.051762811839580536,
        -0.036705777049064636,
        0.05095967277884483,
        -0.05180681124329567,
        -0.04013548046350479,
        0.006934009492397308,
        0.0517536886036396,
        -0.00007554805051768199,
        0.051610033959150314,
        0.048377275466918945,
        -0.05164848268032074,
        -0.05174931883811951,
        0.05180676281452179,
        0.004484616219997406,
        0.04903140291571617,
        -0.05179904028773308,
        -0.05155138671398163,
        0.04455175995826721,
        0.05162641778588295,
        0.050975676625967026,
        0.01689736545085907,
        0.04575493559241295,
        0.05034958943724632,
        0.031886614859104156,
        -0.051460348069667816,
        0.013400636613368988,
        0.051806654781103134,
        0.05025147274136543,
        -0.04282671958208084,
        0.05003888159990311,
        0.05010252073407173,
        0.050940513610839844,
        -0.051786333322525024,
        0.05177327245473862,
        0.02233024314045906,
        -0.030154189094901085,
        0.034509897232055664,
        -0.051066115498542786,
        0.05174556002020836,
        -0.05180644243955612,
        -0.04464205726981163,
        0.05180301144719124,
        0.05171568691730499,
        0.05179477110505104,
        0.040220193564891815,
        -0.050798624753952026,
        -0.0514017790555954,
        0.02408408746123314,
        0.05179278180003166,
        0.02907487004995346,
        -0.05127619579434395,
        -0.04292043298482895,
        0.051775697618722916,
        0.051805801689624786,
        0.03176626190543175,
        -0.040622614324092865,
        0.04008166864514351,
        0.05175940692424774,
        -0.03684639930725098,
        0.04777008667588234,
        0.05070076510310173,
        0.05103331059217453,
        -0.02951505035161972,
        0.051471732556819916,
        0.05003506317734718,
        -0.0166902095079422,
        0.02959541790187359,
        0.006708289496600628,
        -0.04277118667960167,
        0.05155786871910095,
        -0.05151217803359032,
        0.045184556394815445,
        0.05180605873465538,
        0.05180184915661812,
        -0.05179324746131897,
        -0.047793783247470856,
        0.05180681124329567,
        0.02793770469725132,
        0.0517495758831501,
        -0.019887426868081093,
        0.04906065762042999,
        0.024115920066833496,
        -0.03345729783177376,
        0.0492984876036644,
        0.050673291087150574,
        0.05078016221523285,
        0.04109745845198631,
        -0.05158478021621704,
        -0.05119068920612335,
        -0.02300821617245674,
        -0.021340612322092056,
        -0.05162617564201355,
        0.04971573129296303,
        0.05124439299106598,
        -0.04532072693109512,
        0.05179695785045624,
        0.05056818574666977,
        0.03751896694302559,
        0.051562175154685974,
        0.05180058628320694,
        0.019390219822525978,
        0.05146336928009987,
        -0.048615165054798126,
        -0.024210376664996147,
        0.05173394829034805,
        0.05069558322429657,
        -0.0031386148184537888,
        -0.004079681355506182,
        -0.05175513029098511,
        0.05180305987596512,
        -0.05180677771568298,
        -0.033887188881635666,
        -0.04927327111363411,
        -0.05150490254163742,
        0.047401946038007736,
        -0.040007468312978745,
        -0.0263043362647295,
        -0.05163641273975372,
        0.03228268027305603,
        -0.05128641054034233,
        -0.044104915112257004,
        0.009799579158425331,
        -0.02116616629064083,
        0.051675137132406235,
        -0.04632037878036499,
        0.04448658972978592,
        0.0454227551817894,
        0.05144752934575081,
        0.038564447313547134,
        -0.05142680183053017,
        -0.026903048157691956,
        -0.05180647596716881,
        -0.04870617389678955,
        -0.05162402614951134,
        -0.0435444712638855,
        -0.04589987173676491,
        -0.04486021026968956,
        -0.042365897446870804,
        -0.05152513086795807,
        -0.031415242701768875,
        -0.050589315593242645,
        0.0518065020442009,
        -0.014612746424973011,
        -0.04988202452659607,
        0.05165307968854904,
        -0.0460694245994091,
        0.03423444554209709,
        0.05180661007761955,
        -0.0009992290288209915,
        0.048651326447725296,
        -0.025797564536333084,
        0.05028834939002991,
        -0.04277660697698593,
        0.019164081662893295,
        -0.041142843663692474,
        -0.05168376490473747,
        0.051676008850336075,
        -0.013003155589103699,
        -0.05180634185671806,
        0.0509551577270031,
        0.04985576495528221,
        -0.03402731567621231,
        -0.013042084872722626,
        -0.05089055374264717,
        0.04605193808674812,
        0.01407414861023426,
        -0.051804959774017334,
        0.0484817810356617,
        0.005743635352700949,
        0.05015711486339569,
        0.027084682136774063,
        -0.05131503939628601,
        0.051223162561655045,
        -0.010768953710794449,
        -0.03906209394335747,
        -0.05154268071055412,
        -0.04675937443971634,
        -0.05046229436993599,
        0.05129691958427429,
        -0.04318307340145111,
        0.04800601303577423,
        0.012149941176176071,
        0.04823194071650505,
        -0.05176742002367973,
        0.051801685243844986,
        0.04855941981077194,
        0.05148075893521309,
        0.0024283321108669043,
        -0.049915801733732224,
        -0.0517914704978466,
        0.05180656164884567,
        -0.04079372435808182,
        -0.05167243629693985,
        -0.05162566900253296,
        0.04760926216840744,
        0.051293276250362396,
        -0.049733005464076996,
        -0.0011265347711741924,
        -0.04673043265938759,
        0.04373412951827049,
        -0.05138886719942093,
        -0.044657152146101,
        0.04989427700638771,
        0.04881260171532631,
        -0.05172412097454071,
        0.019501447677612305,
        0.04148898646235466,
        0.0517338365316391,
        -0.05020839720964432,
        0.04998059198260307,
        0.048033975064754486,
        -0.004164344631135464,
        0.05007430166006088,
        -0.05151817947626114,
        -0.04461101070046425,
        0.03825273737311363,
        -0.051806069910526276,
        -0.05145421251654625,
        -0.05057908594608307,
        0.05095505714416504,
        0.04945405200123787,
        0.0378880500793457,
        0.04935649037361145,
        -0.04951946809887886,
        -0.051631759852170944,
        -0.05119809880852699,
        0.05043340474367142,
        0.0274217426776886,
        -0.05175567790865898,
        -0.044972505420446396,
        -0.0514012910425663,
        0.0013793576508760452,
        0.04971858859062195,
        0.011783776804804802,
        0.051132746040821075,
        -0.047564797103405,
        -0.05141526460647583,
        -0.04544494301080704,
        0.05176951736211777,
        -0.05123763903975487,
        0.0001749268121784553,
        0.05160924047231674,
        -0.051513928920030594,
        0.05171797797083855,
        -0.003278701798990369,
        -0.042160604149103165,
        0.051800329238176346,
        0.01410458143800497,
        -0.03057100996375084,
        0.04281863942742348,
        -0.05171583965420723,
        -0.051804859191179276,
        -0.051791731268167496,
        -0.05084382742643356,
        -0.024947645142674446,
        0.04760662093758583,
        0.05172891914844513,
        -0.038060907274484634,
        -0.051806528121232986,
        -0.048650797456502914,
        -0.03900522738695145,
        0.051720522344112396,
        -0.046276796609163284,
        -0.008227408863604069,
        -0.007536666002124548,
        0.00956628005951643,
        0.019717073068022728,
        -0.051714736968278885,
        0.0509350411593914,
        -0.03734735772013664,
        -0.05163261666893959,
        0.029373351484537125,
        -0.014986789785325527,
        -0.026455212384462357,
        -0.025317953899502754,
        -0.03605012223124504,
        0.000776923552621156,
        -0.04535380005836487,
        -0.023513995110988617,
        -0.05180496722459793,
        0.02603413537144661,
        -0.048693668097257614,
        0.051600560545921326,
        -0.05180274695158005,
        -0.041216716170310974,
        -0.020716242492198944,
        -0.051806602627038956,
        -0.028362013399600983,
        0.05153624340891838,
        -0.028294887393712997,
        0.04068859666585922,
        -0.0005896327784284949,
        0.01655147224664688,
        0.04413924738764763,
        -0.030087238177657127,
        -0.04694017767906189,
        -0.05075680837035179,
        0.051726922392845154,
        -0.03648333251476288,
        -0.0515163280069828,
        -0.007380015682429075,
        0.048855558037757874,
        -0.04932541400194168,
        0.03838884457945824,
        0.04931041970849037,
        0.05149117857217789,
        0.04930499568581581,
        -0.025800960138440132,
        0.024546103551983833,
        0.047716133296489716,
        -0.034031469374895096,
        0.04104720801115036,
        0.045114096254110336,
        0.027905957773327827,
        -0.04299353435635567,
        -0.040086161345243454,
        -0.03160649538040161,
        -0.047011345624923706,
        0.0517447330057621,
        0.0033769444562494755,
        0.041007671505212784,
        -0.05149230733513832,
        0.0504184328019619,
        0.037596847862005234,
        0.04925825074315071,
        0.051732949912548065,
        -0.05178782716393471,
        0.05180056020617485,
        -0.0515948124229908,
        -0.051621027290821075,
        0.05177798867225647,
        0.005332398694008589,
        0.01000527385622263,
        0.047228455543518066,
        -0.047228362411260605,
        0.01052320096641779,
        -0.03135569393634796,
        0.05177002772688866,
        -0.05176422744989395,
        -0.049662597477436066,
        -0.031176021322607994,
        0.023116879165172577,
        0.04659385606646538,
        0.04143063351511955,
        0.03969394043087959,
        0.05171747878193855,
        0.05180675908923149,
        -0.047136615961790085,
        -0.05095364898443222,
        -0.04710903763771057,
        0.04260159656405449,
        -0.04982190951704979,
        0.04627935588359833,
        0.043858177959918976,
        -0.0515318363904953,
        -0.0197707898914814,
        -0.0502435602247715,
        -0.0015147554222494364,
        0.05070117115974426,
        -0.051691628992557526,
        -0.051297206431627274,
        -0.0476141981780529,
        0.010019710287451744,
        0.00942380353808403,
        0.04765345901250839,
        0.05023603513836861,
        0.01444303896278143,
        -0.03961776942014694,
        0.04863577336072922,
        -0.05112466216087341,
        0.05173977464437485,
        -0.051502395421266556
    ],
    [
        0.05381646752357483,
        -0.05839962139725685,
        -0.02812810055911541,
        -0.019164681434631348,
        -0.02218867465853691,
        -0.057907238602638245,
        0.04226027801632881,
        -0.053725842386484146,
        0.022280093282461166,
        -0.04216713085770607,
        0.035643573850393295,
        -0.055616460740566254,
        -0.03127053752541542,
        -0.056929100304841995,
        -0.057065900415182114,
        -0.05482703447341919,
        0.04244723543524742,
        0.05479519069194794,
        -0.019547374919056892,
        0.021020445972681046,
        0.057415544986724854,
        -0.025334855541586876,
        0.055343691259622574,
        0.03590231016278267,
        -0.04709158465266228,
        0.053593795746564865,
        -0.04637470468878746,
        -0.056573476642370224,
        0.05767140910029411,
        -0.053160689771175385,
        -0.010462180711328983,
        0.05442821606993675,
        0.058065369725227356,
        -0.05596015602350235,
        0.03129264712333679,
        -0.05791763216257095,
        -0.05648500844836235,
        0.014940685592591763,
        -0.04769112542271614,
        -0.03386536240577698,
        -0.0022726214956492186,
        -0.028986923396587372,
        0.044350989162921906,
        -0.008769277483224869,
        0.05165107548236847,
        0.0364011749625206,
        0.05105140060186386,
        0.05459781736135483,
        0.056750379502773285,
        0.052543699741363525,
        0.057450469583272934,
        0.05551333725452423,
        -0.05651543661952019,
        -0.05830461159348488,
        -0.0004900009953416884,
        -0.05019913241267204,
        -0.005417053587734699,
        0.018073109909892082,
        -0.052415668964385986,
        -0.0576082281768322,
        -0.058135226368904114,
        0.054665833711624146,
        -0.05708473548293114,
        0.012094145640730858,
        0.0543966218829155,
        -0.05099286884069443,
        -0.027143606916069984,
        -0.054393913596868515,
        -0.05393344908952713,
        -0.03561149537563324,
        -0.05035965144634247,
        -0.022009432315826416,
        0.05797889083623886,
        0.05771515518426895,
        0.05685355141758919,
        -0.050751712173223495,
        -0.05716937035322189,
        0.05810711160302162,
        -0.02913728356361389,
        -0.03809107095003128,
        -0.0339147225022316,
        -0.005998185835778713,
        -0.04872262105345726,
        0.05625877156853676,
        -0.0008082238491624594,
        -0.007105999626219273,
        -0.05430644750595093,
        -0.05578013136982918,
        -0.0282975435256958,
        0.05710972100496292,
        -0.01841125078499317,
        0.03618461266160011,
        -0.05840856209397316,
        -0.056367870420217514,
        -0.010420005768537521,
        0.03512745350599289,
        -0.01814957521855831,
        -0.05781756341457367,
        -0.05187764763832092,
        0.040450453758239746,
        -0.05709310248494148,
        0.020193880423903465,
        -0.029087796807289124,
        -0.028688019141554832,
        -0.05531249940395355,
        0.034893106669187546,
        0.05801932513713837,
        0.05628490447998047,
        -0.03915860503911972,
        0.04141565039753914,
        -0.004247245844453573,
        0.04053635522723198,
        -0.053475476801395416,
        0.037691228091716766,
        -0.02138449251651764,
        0.04552488774061203,
        -0.022647928446531296,
        -0.029421545565128326,
        0.0571669340133667,
        -0.014372686855494976,
        0.050034575164318085,
        0.00047747333883307874,
        0.013908094726502895,
        -0.05817033722996712,
        -0.035658180713653564,
        -0.05283450335264206,
        0.013129614293575287,
        -0.03645682334899902,
        0.05825429782271385,
        -0.008172420784831047,
        0.057217102497816086,
        -0.049697283655405045,
        -0.014341196045279503,
        0.054528944194316864,
        -0.0537276417016983,
        -0.03429163619875908,
        0.05683175101876259,
        0.042694516479969025,
        -0.028327403590083122,
        0.0577760674059391,
        0.05840494856238365,
        -0.04692179337143898,
        0.054173536598682404,
        -0.05774857476353645,
        0.016178825870156288,
        0.05553271248936653,
        -0.00789425615221262,
        0.05838743597269058,
        -0.029589667916297913,
        0.039759233593940735,
        0.056841444224119186,
        -0.05705239251255989,
        -0.05439712107181549,
        0.025800224393606186,
        0.058335669338703156,
        -0.0570533461868763,
        -0.0583723708987236,
        0.04314487427473068,
        0.05736779421567917,
        0.041191209107637405,
        -0.013673734851181507,
        -0.00203501945361495,
        0.0524279922246933,
        0.028883671388030052,
        0.05834711715579033,
        0.05664185434579849,
        -0.017469966784119606,
        -0.014764760620892048,
        -0.029326435178518295,
        0.0488005056977272,
        0.040929291397333145,
        0.0574573278427124,
        -0.04955596104264259,
        -0.05836090072989464,
        -0.014389078132808208,
        -0.00428571505472064,
        0.004088427871465683,
        0.005693846382200718,
        0.056699324399232864,
        -0.05357080698013306,
        -0.04646404832601547,
        0.05840972065925598,
        0.036910779774188995,
        0.04480666294693947,
        0.035678986459970474,
        0.006106778513640165,
        0.04836302250623703,
        0.055873457342386246,
        0.05837031826376915,
        -0.05629260092973709,
        0.012641793116927147,
        -0.03826040029525757,
        0.05561421439051628,
        0.0580475851893425,
        -0.04361964389681816,
        -0.0541941300034523,
        -0.010144826956093311,
        0.05290268361568451,
        -0.05650995671749115,
        -0.002258565276861191,
        0.004042311105877161,
        0.05661501735448837,
        -0.05086398869752884,
        -0.045743267983198166,
        -0.03208855539560318,
        0.017647720873355865,
        0.05237961187958717,
        0.05606726557016373,
        0.03541625663638115,
        0.05800790339708328,
        -0.054154761135578156,
        -0.020457729697227478,
        0.05250271037220955,
        0.047669894993305206,
        0.04945316165685654,
        -0.028846729546785355,
        0.05056118592619896,
        -0.03688155114650726,
        0.05818205699324608,
        0.05781783163547516,
        0.05010468512773514,
        0.002658164594322443,
        -0.010762767866253853,
        -0.002691519446671009,
        0.05839654058218002,
        -0.0021460321731865406,
        0.055894915014505386,
        -0.0532931312918663,
        0.04373735561966896,
        -0.040877360850572586,
        -0.03404136747121811,
        -0.05581424757838249,
        0.02812795713543892,
        0.05749906599521637,
        -0.00018232583533972502,
        0.05709700286388397,
        0.05266660451889038,
        0.055974435061216354,
        0.03156670555472374,
        0.05686270818114281,
        -0.025211477652192116,
        0.04686160758137703,
        -0.05834437906742096,
        0.002787909470498562,
        0.03100685216486454,
        -0.01662302389740944,
        -0.057417597621679306,
        -0.054325688630342484,
        0.03220691159367561,
        0.041368164122104645,
        -0.005571905989199877,
        -0.05449487268924713,
        -0.0565301738679409,
        -0.05459805577993393,
        0.047533586621284485,
        -0.04320409148931503,
        0.020354732871055603,
        -0.024817220866680145,
        0.05111553519964218,
        0.058382388204336166,
        -0.03724779188632965,
        -0.030552444979548454,
        0.046056367456912994,
        0.04877784475684166,
        -0.05833989009261131,
        -0.009350758977234364,
        0.021872036159038544,
        0.055349189788103104,
        -0.046767961233854294,
        -0.0396764799952507,
        0.04081382974982262,
        -0.055201832205057144,
        0.058251723647117615,
        -0.011643948033452034,
        -0.041738204658031464,
        0.03407851979136467,
        -0.04936667159199715,
        0.014637976884841919,
        0.044164810329675674,
        0.00842331163585186,
        0.05201535299420357,
        -0.014708184637129307,
        0.018169261515140533,
        -0.04274772107601166,
        -0.05498331040143967,
        -0.0401441752910614,
        0.04945290461182594,
        0.05749674513936043,
        0.04815128818154335,
        -0.046359818428754807,
        -0.012040656059980392,
        -0.049890920519828796,
        -0.05051818862557411,
        0.030086394399404526,
        0.057188455015420914,
        -0.04280005767941475,
        0.055782292038202286,
        -0.05640837550163269,
        0.04762188345193863,
        0.053303081542253494,
        -0.050345733761787415,
        -0.02515910007059574,
        0.012206822633743286,
        0.05736814811825752,
        -0.014400425367057323,
        -0.02479041926562786,
        -0.0006695973570458591,
        0.010133589617908001,
        -0.007502025458961725,
        0.04771510511636734,
        0.028482655063271523,
        -0.020775040611624718,
        -0.00615630391985178,
        0.04359558969736099,
        -0.037482913583517075,
        -0.053565818816423416,
        -0.04810122027993202,
        -0.05833808332681656,
        0.05670008435845375,
        -0.014393928460776806,
        0.05690029263496399,
        -0.016441866755485535,
        0.056291304528713226,
        -0.002521729562431574,
        0.05802379921078682,
        -0.05063289403915405,
        0.02867787517607212,
        -0.057133834809064865,
        -0.019259242340922356,
        -0.03826930746436119,
        -0.03812336549162865,
        -0.025308741256594658,
        -0.05054955184459686,
        -0.042441271245479584,
        0.05368533357977867,
        -0.045976728200912476,
        0.05463310703635216,
        -0.03792088106274605,
        -0.02591712214052677,
        0.02961338683962822,
        -0.016826065257191658,
        0.037497397512197495,
        0.05721556395292282,
        0.056340478360652924,
        0.006130137015134096,
        0.025484638288617134,
        0.045313652604818344,
        0.056154150515794754,
        0.00174599455203861,
        0.007014749106019735,
        -0.04494687542319298,
        -0.03190607205033302,
        0.021288307383656502,
        -0.028667939826846123,
        0.009877592325210571,
        -0.008914544247090816,
        -0.056113842874765396,
        -0.05073254927992821,
        -0.05102235823869705,
        -0.057772520929574966,
        0.05524196848273277,
        0.05702514573931694,
        0.028603842481970787,
        -0.05065341666340828,
        -0.05385642871260643,
        -0.05754633992910385,
        0.05579519271850586,
        0.04207157343626022,
        -0.05707297846674919,
        -0.057938218116760254,
        -0.04616815224289894,
        0.04740104079246521,
        -0.051482170820236206,
        -0.05010766535997391,
        0.050854455679655075,
        -0.035580091178417206,
        0.054674841463565826,
        -0.03808144852519035,
        0.02910836972296238,
        -0.051554158329963684,
        -0.0549849271774292,
        -0.009840400889515877,
        -0.05652352422475815,
        0.049142614006996155,
        0.05016034096479416,
        -0.0513635016977787,
        -0.027585694566369057,
        -0.03360370174050331,
        -0.04166439548134804,
        0.017825983464717865,
        -0.05831202119588852,
        0.04038796201348305,
        -0.029059523716568947,
        -0.055489182472229004,
        0.0007211901829577982,
        0.05721819028258324,
        0.057016775012016296,
        -0.03880365565419197,
        -0.05761829391121864,
        -0.05601505935192108,
        -0.02517283521592617,
        0.05816647782921791,
        -0.05068463459610939,
        0.041716475039720535,
        -0.05394205451011658,
        0.05684877559542656,
        0.04290619492530823,
        -0.05598604306578636,
        0.05523640289902687,
        -0.012255240231752396,
        -0.05546405911445618,
        -0.0280521921813488,
        0.04635084792971611,
        0.027288170531392097,
        0.05803968384861946,
        -0.056379348039627075,
        0.04842543974518776,
        -0.04690786823630333,
        0.058335285633802414,
        -0.0425860695540905,
        0.05820220708847046,
        -0.021610161289572716,
        -0.0016313857631757855,
        -0.057882316410541534,
        -0.01483262237161398,
        -0.02324899472296238,
        -0.0443744957447052,
        0.010472599416971207,
        -0.028967399150133133,
        -0.05694817751646042,
        -0.032996904104948044,
        0.053376417607069016,
        -0.05726809427142143,
        -0.05489843338727951,
        -0.05735849589109421,
        -0.043890126049518585,
        -0.05671593174338341,
        0.058364640921354294,
        -0.04697435349225998,
        -0.05460687726736069,
        -0.03577320650219917,
        0.05224797502160072,
        0.0583854503929615,
        0.05634438619017601,
        0.058066852390766144,
        0.05795368552207947,
        0.0559677928686142,
        -0.054027996957302094,
        0.052702438086271286,
        0.043656229972839355,
        0.055864229798316956,
        0.05373968929052353,
        0.056115105748176575,
        0.04928330332040787,
        -0.05839337781071663,
        -0.0571536086499691,
        0.04797430709004402,
        -0.04552372917532921,
        -0.002849674317985773,
        0.050740551203489304,
        0.0038088064175099134,
        -0.05546253174543381,
        0.03278088569641113,
        -0.04494939371943474,
        0.057669468224048615,
        0.05831218883395195,
        -0.03862142190337181,
        0.05815567448735237,
        -0.011339353397488594,
        -0.05268793925642967,
        0.0534558966755867,
        0.05705125257372856,
        0.028539178892970085,
        0.014585468918085098,
        -0.04209183156490326,
        -0.008864630945026875,
        0.05290383845567703,
        0.05451706051826477,
        -0.04854556545615196,
        -0.030540533363819122,
        -0.053731899708509445,
        0.0478154681622982,
        0.05280999466776848,
        0.03369826823472977,
        -0.046462323516607285,
        0.048687178641557693,
        -0.0204305462539196,
        0.047909677028656006,
        -0.039165232330560684,
        0.04424065724015236,
        -0.04616929590702057,
        0.006318113766610622,
        0.051795557141304016,
        -0.03011358715593815,
        -0.004232640843838453,
        -0.006846425589174032,
        0.03154965490102768,
        0.05705546587705612,
        0.04923216253519058,
        -0.04074475169181824,
        0.038552019745111465,
        0.02817186526954174,
        -0.010731149464845657,
        0.019509846344590187,
        0.02022859826683998,
        0.03912614285945892,
        0.05664471536874771,
        -0.036833688616752625,
        -0.006854907143861055,
        -0.04967103153467178,
        -0.034364424645900726,
        -0.0362696535885334
    ],
    [
        -0.0531342551112175,
        -0.053834233433008194,
        0.016148099675774574,
        0.04724809527397156,
        -0.05119606480002403,
        -0.03227473050355911,
        0.036249611526727676,
        -0.054036788642406464,
        0.05391794070601463,
        0.05013222247362137,
        0.02333494834601879,
        0.028692273423075676,
        -0.05285053700208664,
        -0.030686307698488235,
        -0.053237542510032654,
        -0.05176383629441261,
        0.010939481668174267,
        0.05269836634397507,
        0.04621134325861931,
        0.04738214612007141,
        0.039845313876867294,
        -0.03740415722131729,
        0.02919667959213257,
        -0.028295323252677917,
        -0.052024465054273605,
        0.05394750088453293,
        0.03537379577755928,
        0.022204458713531494,
        -0.053883668035268784,
        -0.0409722700715065,
        -0.053521376103162766,
        0.04961776360869408,
        -0.047536760568618774,
        -0.051045648753643036,
        0.05362750589847565,
        0.025980578735470772,
        -0.053545981645584106,
        0.024648457765579224,
        0.0537295863032341,
        -0.04750142619013786,
        0.04881071299314499,
        0.051449283957481384,
        0.04874750226736069,
        -0.05211513862013817,
        0.022038770839571953,
        0.04875006899237633,
        -0.02422543801367283,
        0.05397503823041916,
        0.05030680075287819,
        -0.04596312716603279,
        0.05399852246046066,
        -0.05104418098926544,
        -0.05235570669174194,
        0.04288765788078308,
        -0.032612331211566925,
        -0.02957218885421753,
        0.0242835134267807,
        0.02196834795176983,
        0.010663829743862152,
        -0.042557504028081894,
        -0.03448023647069931,
        0.043922942131757736,
        -0.05343637242913246,
        0.051257163286209106,
        -0.013086809776723385,
        -0.00741012766957283,
        -0.02066585049033165,
        0.004212006460875273,
        0.05403074994683266,
        -0.0447494201362133,
        -0.03000207617878914,
        -0.027234170585870743,
        0.0170295350253582,
        0.05294680967926979,
        0.04803658276796341,
        0.01302082184702158,
        0.043008968234062195,
        0.054040390998125076,
        0.05270036309957504,
        -0.03238673880696297,
        -0.04951176419854164,
        0.052381932735443115,
        -0.05397826433181763,
        0.051375649869441986,
        0.03467150777578354,
        0.029398920014500618,
        0.012085461057722569,
        -0.049356549978256226,
        -0.0394892692565918,
        0.013131282292306423,
        -0.05379552021622658,
        -0.05334346741437912,
        -0.05402568727731705,
        -0.04468207433819771,
        0.00175767345353961,
        0.041672658175230026,
        0.02970363199710846,
        -0.05161365121603012,
        -0.043034978210926056,
        0.04764433577656746,
        -0.05403246730566025,
        0.054039087146520615,
        0.054035935550928116,
        0.05352725833654404,
        0.017856968566775322,
        -0.05232188105583191,
        -0.0484122596681118,
        -0.009842689149081707,
        -0.053930241614580154,
        -0.03693954646587372,
        -0.028498094528913498,
        -0.05370567366480827,
        0.044296372681856155,
        0.03309796005487442,
        -0.0444955937564373,
        0.037520650774240494,
        -0.0524972528219223,
        -0.04155232384800911,
        -0.04884506016969681,
        -0.05022980645298958,
        0.035398680716753006,
        -0.05210450291633606,
        0.04522380977869034,
        -0.048335492610931396,
        -0.04848730191588402,
        -0.04412049800157547,
        0.0533033087849617,
        -0.04100952297449112,
        0.050141703337430954,
        0.040437452495098114,
        0.02028915099799633,
        -0.053019050508737564,
        -0.05360547825694084,
        0.02922946773469448,
        -0.03938937187194824,
        -0.050908684730529785,
        0.054010652005672455,
        -0.04397676885128021,
        -0.05380893498659134,
        0.022369418293237686,
        -0.052550312131643295,
        -0.054032959043979645,
        0.05065227672457695,
        0.04601028561592102,
        -0.047625582665205,
        0.03269321843981743,
        -0.03542172163724899,
        -0.053692739456892014,
        -0.01738669164478779,
        -0.039323125034570694,
        -0.04788322374224663,
        -0.053507063537836075,
        0.03671770542860031,
        0.014015404507517815,
        -0.0500960648059845,
        -0.052800048142671585,
        -0.05254153162240982,
        0.0004083859093952924,
        0.05397845432162285,
        0.03190437704324722,
        0.04164380952715874,
        0.008428630419075489,
        0.05266033485531807,
        0.0540122427046299,
        -0.05372726172208786,
        0.040139008313417435,
        0.05069222301244736,
        0.026505697518587112,
        -0.011334765702486038,
        0.04347304627299309,
        -0.0322754941880703,
        0.0510198250412941,
        -0.05129878968000412,
        0.025809573009610176,
        -0.015003520995378494,
        0.04695669189095497,
        -0.01366561558097601,
        -0.04672009125351906,
        0.05235932767391205,
        -0.052613623440265656,
        -0.049669187515974045,
        0.047770917415618896,
        0.04047495499253273,
        -0.05102638900279999,
        0.04926104471087456,
        -0.022863203659653664,
        -0.053577836602926254,
        0.05335051938891411,
        0.04445780813694,
        -0.04099544137716293,
        -0.0469578355550766,
        -0.004872133024036884,
        0.03613760694861412,
        0.02176782861351967,
        -0.05227498710155487,
        0.048748258501291275,
        -0.026508692651987076,
        0.05309119448065758,
        -0.02538559027016163,
        0.0530456006526947,
        0.05401436984539032,
        0.025377435609698296,
        -0.04709494486451149,
        -0.052209168672561646,
        0.02676754631102085,
        0.04017588123679161,
        0.05390462279319763,
        0.04785531014204025,
        -0.0516374334692955,
        0.050410740077495575,
        -0.05119891092181206,
        0.030508708208799362,
        0.05271085351705551,
        0.05377783253788948,
        -0.047625523060560226,
        -0.05333613231778145,
        0.05394884943962097,
        -0.05368202552199364,
        0.05403854697942734,
        -0.04870358482003212,
        -0.05368949845433235,
        0.0023544589057564735,
        -0.03157750889658928,
        0.03169998154044151,
        0.053512416779994965,
        -0.05173436924815178,
        0.05144939199090004,
        -0.053689565509557724,
        0.05085467919707298,
        -0.05347704887390137,
        -0.03175297752022743,
        -0.05344405770301819,
        0.014259079471230507,
        0.0482676662504673,
        -0.05389517545700073,
        0.053896091878414154,
        0.051176853477954865,
        -0.05381649732589722,
        0.035678934305906296,
        0.05403456836938858,
        0.04347308725118637,
        -0.04158307611942291,
        -0.054039500653743744,
        -0.05399147421121597,
        0.05140301585197449,
        0.017788268625736237,
        0.05369701609015465,
        -0.03381750360131264,
        -0.04321977123618126,
        -0.05373994633555412,
        -0.0458175428211689,
        -0.051980845630168915,
        -0.04997538775205612,
        -0.053705811500549316,
        -0.008055813610553741,
        -0.03259383887052536,
        0.022092271596193314,
        -0.052351925522089005,
        0.04664017632603645,
        -0.053611014038324356,
        -0.0018625414231792092,
        -0.04426584765315056,
        0.030568448826670647,
        0.05006992816925049,
        0.05318194627761841,
        0.05378424748778343,
        0.05348053202033043,
        0.05390090122818947,
        -0.041614655405282974,
        -0.05119910463690758,
        0.01910724677145481,
        -0.05401436239480972,
        0.05332271382212639,
        -0.05379736050963402,
        -0.03554476052522659,
        0.0075081707909703255,
        -0.04671796038746834,
        0.0037073371931910515,
        0.049848515540361404,
        0.03504994139075279,
        -0.05378252640366554,
        0.054026033729314804,
        -0.04945249855518341,
        -0.01769149862229824,
        0.05390757694840431,
        0.04890846833586693,
        -0.03149386867880821,
        0.05393233522772789,
        0.048805054277181625,
        0.04712624475359917,
        -0.027761800214648247,
        -0.05200711637735367,
        -0.021264467388391495,
        0.04303731769323349,
        0.0365733802318573,
        -0.05373760312795639,
        0.054023925215005875,
        -0.045973531901836395,
        -0.005195478443056345,
        0.0510067343711853,
        0.054014164954423904,
        0.02363802306354046,
        -0.041737914085388184,
        0.053841620683670044,
        0.03528481721878052,
        0.03548622876405716,
        -0.05242423340678215,
        0.05357632040977478,
        0.051197249442338943,
        -0.018668871372938156,
        -0.05116414278745651,
        -0.046575937420129776,
        0.003489075228571892,
        0.05332927778363228,
        0.022063281387090683,
        -0.05175333470106125,
        0.023708701133728027,
        -0.037605833262205124,
        0.05322430282831192,
        0.022289346903562546,
        0.05315781012177467,
        -0.05378854647278786,
        0.049715470522642136,
        -0.019996244460344315,
        0.05225656181573868,
        0.049341898411512375,
        0.017626238986849785,
        0.013337036594748497,
        -0.010489936918020248,
        -0.051777295768260956,
        0.0379437692463398,
        -0.04242057725787163,
        -0.05234513059258461,
        -0.05168832093477249,
        0.049826040863990784,
        0.0476221926510334,
        0.012612421065568924,
        0.04125869274139404,
        -0.038322724401950836,
        0.050419725477695465,
        -0.04784698784351349,
        -0.02567346952855587,
        0.052147798240184784,
        -0.04394097998738289,
        0.04413609579205513,
        0.04757549986243248,
        0.05367868021130562,
        0.0535934679210186,
        0.05396871268749237,
        0.04992925748229027,
        0.053938765078783035,
        -0.04185642674565315,
        0.047218676656484604,
        -0.05397192761301994,
        -0.04738399386405945,
        -0.021342262625694275,
        -0.053917091339826584,
        -0.042890843003988266,
        -0.04496793821454048,
        0.026190340518951416,
        0.04978024959564209,
        0.05399172753095627,
        0.04892367497086525,
        -0.050351016223430634,
        -0.04169842228293419,
        -0.04893886670470238,
        0.05378573015332222,
        -0.051569268107414246,
        0.0042457436211407185,
        -0.04470062255859375,
        -0.04755167290568352,
        -0.04818215221166611,
        0.051356241106987,
        -0.0000946532964007929,
        0.05389053747057915,
        -0.013067735359072685,
        0.052920661866664886,
        -0.030378326773643494,
        0.05403410270810127,
        0.05020749568939209,
        0.05316281318664551,
        0.05367446318268776,
        -0.0335821807384491,
        0.021870918571949005,
        -0.013044273480772972,
        -0.04550240561366081,
        0.0540158674120903,
        -0.04383246973156929,
        -0.05080069974064827,
        0.0013109841383993626,
        -0.05392914637923241,
        0.049332067370414734,
        -0.053474847227334976,
        0.04486098513007164,
        0.05385113134980202,
        0.05356205254793167,
        0.0539957620203495,
        -0.0535275936126709,
        -0.05372696369886398,
        -0.05376944690942764,
        0.014329592697322369,
        0.05388501286506653,
        0.041326992213726044,
        0.05293518677353859,
        -0.052790164947509766,
        0.03338651359081268,
        -0.022996101528406143,
        -0.05293908715248108,
        0.0532386340200901,
        -0.006456164643168449,
        0.05327259376645088,
        -0.04153743013739586,
        -0.03831522539258003,
        -0.0310383178293705,
        0.05173945426940918,
        -0.05352780222892761,
        -0.04702122136950493,
        -0.04063843563199043,
        0.05266996845602989,
        0.05020448565483093,
        0.003987719304859638,
        -0.05389883741736412,
        -0.04013734310865402,
        -0.053939834237098694,
        -0.038591451942920685,
        -0.017712203785777092,
        0.006136388052254915,
        -0.05300853028893471,
        0.043782588094472885,
        -0.053728628903627396,
        0.04930797219276428,
        0.012660899199545383,
        -0.05005683749914169,
        -0.051814716309309006,
        0.028011219576001167,
        -0.05400629714131355,
        -0.0030987882055342197,
        0.03938824683427811,
        0.04882488027215004,
        -0.02950475737452507,
        -0.04242933169007301,
        0.005280809942632914,
        -0.029127728193998337,
        0.05080351606011391,
        0.05200834572315216,
        0.053772423416376114,
        0.053325653076171875,
        -0.0533328503370285,
        0.011289140209555626,
        0.05375299230217934,
        -0.04434933140873909,
        -0.015241382643580437,
        0.009912478737533092,
        0.053016673773527145,
        0.05137833580374718,
        -0.05279551446437836,
        0.029015084728598595,
        -0.023718178272247314,
        0.048022784292697906,
        0.05291437730193138,
        0.046821288764476776,
        -0.0539705753326416,
        0.05174211040139198,
        -0.04961002990603447,
        0.05403062701225281,
        0.05375930666923523,
        -0.053530868142843246,
        0.05354762449860573,
        -0.04239390790462494,
        -0.05344033241271973,
        0.026634767651557922,
        0.015736930072307587,
        0.05388127267360687,
        0.053893156349658966,
        -0.054021161049604416,
        0.03215452656149864,
        -0.030329516157507896,
        0.0504877008497715,
        -0.0488421693444252,
        -0.053919825702905655,
        -0.05314820259809494,
        -0.028502091765403748,
        -0.042265549302101135,
        -0.002035272540524602,
        -0.04418604448437691,
        0.05082535743713379,
        0.030313119292259216,
        0.02602110244333744,
        -0.05342596396803856,
        -0.04259057343006134,
        0.049158405512571335,
        -0.04170628637075424,
        0.053638145327568054,
        -0.012167620472609997,
        -0.0143220080062747,
        0.05357882380485535,
        -0.051901523023843765,
        -0.050841864198446274,
        0.04714335501194,
        0.05369499698281288,
        -0.04885537177324295,
        -0.03732914477586746,
        0.05389011278748512,
        -0.04980139061808586,
        0.053343672305345535,
        0.005404313560575247,
        0.05056633800268173,
        -0.04670301079750061,
        0.053986262530088425,
        -0.053942129015922546,
        0.05376899987459183,
        0.001247223699465394
    ],
    [
        0.021700691431760788,
        0.04299807548522949,
        0.05147859454154968,
        0.0323796309530735,
        -0.03164559230208397,
        -0.051859453320503235,
        0.04305995628237724,
        -0.054696545004844666,
        0.04160759598016739,
        0.020299073308706284,
        0.05242109298706055,
        0.051679905503988266,
        -0.052725814282894135,
        -0.05438770726323128,
        -0.05422784760594368,
        0.053657762706279755,
        -0.04218282178044319,
        0.05471838265657425,
        0.03764485940337181,
        -0.03322867676615715,
        0.05387048050761223,
        0.054693739861249924,
        0.036128655076026917,
        -0.004311175085604191,
        0.05446968972682953,
        0.049791499972343445,
        0.053709935396909714,
        0.020832553505897522,
        -0.054363176226615906,
        -0.010736709460616112,
        0.03573235496878624,
        0.047086648643016815,
        0.05473277345299721,
        -0.05465434491634369,
        0.05316140502691269,
        -0.05463716760277748,
        -0.0544268935918808,
        -0.006535349413752556,
        0.05466395616531372,
        -0.01887289620935917,
        -0.04588582366704941,
        0.033823635429143906,
        0.050033267587423325,
        -0.05102911591529846,
        0.04984894022345543,
        0.025740154087543488,
        0.05238586664199829,
        0.039761513471603394,
        0.054327599704265594,
        0.05236620455980301,
        0.054709095507860184,
        0.054616112262010574,
        0.011674551293253899,
        0.03215556964278221,
        -0.015352579765021801,
        0.052375636994838715,
        -0.05455915629863739,
        -0.01250852458178997,
        -0.05042256787419319,
        -0.054219648241996765,
        -0.05387392267584801,
        0.031109876930713654,
        -0.05394156277179718,
        0.05393195524811745,
        0.015686331316828728,
        -0.04834194853901863,
        -0.05428554490208626,
        -0.05453851819038391,
        -0.05235525965690613,
        0.054465070366859436,
        -0.015701930969953537,
        -0.04926373437047005,
        0.05471018701791763,
        0.05045821890234947,
        -0.022646788507699966,
        -0.049338147044181824,
        0.053282368928194046,
        0.05474339425563812,
        0.0062600416131317616,
        0.04804549738764763,
        -0.03727012127637863,
        0.0523788258433342,
        -0.054111089557409286,
        0.05340033397078514,
        -0.033251263201236725,
        0.04117157310247421,
        0.05333816260099411,
        -0.03833027929067612,
        -0.05395659804344177,
        0.05471618101000786,
        -0.016309384256601334,
        -0.020301034674048424,
        -0.054742198437452316,
        -0.024055084213614464,
        -0.05205294489860535,
        0.05230671167373657,
        -0.04665548354387283,
        -0.054451022297143936,
        0.054535236209630966,
        -0.02545856684446335,
        -0.0547376349568367,
        0.03895076364278793,
        0.052766457200050354,
        0.05386372283101082,
        0.0544874481856823,
        -0.025373339653015137,
        -0.051355913281440735,
        0.0515882670879364,
        0.046118784695863724,
        -0.007586781866848469,
        0.04602927342057228,
        -0.02827327325940132,
        -0.052446555346250534,
        -0.02902153506875038,
        0.01087920367717743,
        0.013910333625972271,
        -0.04612940549850464,
        -0.054666273295879364,
        0.05272066220641136,
        0.0021484761964529753,
        0.05348269268870354,
        0.03585151210427284,
        0.048650093376636505,
        -0.05457586795091629,
        -0.054492801427841187,
        -0.018109474331140518,
        -0.05473260208964348,
        0.007111012935638428,
        0.04899835214018822,
        0.015396376140415668,
        -0.005595536436885595,
        -0.05423833057284355,
        -0.05471881106495857,
        0.05471179261803627,
        -0.053509242832660675,
        -0.05472203344106674,
        0.053885769098997116,
        -0.05303261801600456,
        -0.054656434804201126,
        0.047048021107912064,
        0.03542301431298256,
        -0.04636459797620773,
        0.0419732965528965,
        0.05415359139442444,
        -0.029803359881043434,
        0.019455213099718094,
        0.03456655144691467,
        -0.047609735280275345,
        -0.05221240594983101,
        0.005258798133581877,
        -0.03978684917092323,
        -0.05435685068368912,
        -0.05420251563191414,
        -0.048936374485492706,
        -0.026755264028906822,
        0.03420247510075569,
        -0.051603782922029495,
        0.05168449133634567,
        0.05459320917725563,
        -0.0305552426725626,
        -0.012375147081911564,
        -0.041406575590372086,
        0.054550010710954666,
        0.02918451465666294,
        0.038566384464502335,
        0.00279429298825562,
        -0.049831077456474304,
        0.053122397512197495,
        -0.04673513397574425,
        0.049760736525058746,
        -0.054669756442308426,
        0.05388176068663597,
        -0.05130868777632713,
        0.051322415471076965,
        0.04067898169159889,
        -0.021072659641504288,
        0.0039056758396327496,
        0.0013342044549062848,
        0.05182787775993347,
        -0.05231461673974991,
        -0.03468134254217148,
        0.05474286898970604,
        0.0515214167535305,
        0.01957031525671482,
        0.012572410516440868,
        0.051772698760032654,
        -0.013961071148514748,
        0.05465811863541603,
        -0.048827145248651505,
        -0.05463191494345665,
        -0.05363704636693001,
        0.04391545057296753,
        -0.011623651720583439,
        -0.04553760960698128,
        -0.04531567543745041,
        -0.02603665366768837,
        -0.04598650708794594,
        -0.05458345636725426,
        -0.05286116153001785,
        0.04611307755112648,
        -0.013569156639277935,
        0.053454939275979996,
        -0.0041530500166118145,
        0.011883887462317944,
        -0.0010378295555710793,
        0.006323880050331354,
        0.04352886974811554,
        0.054694537073373795,
        -0.054596561938524246,
        0.05193303897976875,
        -0.053162239491939545,
        0.053834885358810425,
        0.05474154278635979,
        0.05453164875507355,
        0.05331387370824814,
        -0.05160798132419586,
        0.05346928909420967,
        -0.011360781267285347,
        0.05470098555088043,
        -0.04401148483157158,
        0.05029339715838432,
        0.054737795144319534,
        -0.03752495348453522,
        0.017842549830675125,
        0.054480861872434616,
        -0.05361160263419151,
        0.03772805631160736,
        0.012303429655730724,
        0.05393810197710991,
        -0.054103776812553406,
        0.038935206830501556,
        -0.010268832556903362,
        0.01061659213155508,
        0.05057593807578087,
        0.029568379744887352,
        0.054708898067474365,
        0.05454738065600395,
        -0.02839132398366928,
        0.04358012229204178,
        0.05474301427602768,
        0.051518991589546204,
        0.03352348506450653,
        -0.05473855137825012,
        -0.044528789818286896,
        0.05277249217033386,
        -0.03489908576011658,
        -0.05445925518870354,
        -0.05312887579202652,
        0.0378587506711483,
        -0.04553552344441414,
        -0.03600437939167023,
        -0.051148492842912674,
        0.030137015506625175,
        -0.05458793416619301,
        -0.05472412332892418,
        0.05459319427609444,
        0.017590289935469627,
        0.041719358414411545,
        0.048954449594020844,
        0.040159355849027634,
        0.054666392505168915,
        -0.0490700900554657,
        0.02126712165772915,
        0.05464846268296242,
        -0.05472470819950104,
        0.05443942919373512,
        0.054721493273973465,
        0.05071191117167473,
        -0.00857083685696125,
        -0.0032697662245482206,
        0.03919938579201698,
        -0.054664429277181625,
        0.05389678478240967,
        -0.05252789705991745,
        -0.01628793217241764,
        0.046273473650217056,
        -0.05445592477917671,
        -0.053990621119737625,
        0.052579283714294434,
        -0.054048579186201096,
        -0.05301789194345474,
        0.05302295833826065,
        -0.038281094282865524,
        -0.04725546017289162,
        -0.02689678780734539,
        0.04326309636235237,
        -0.037453632801771164,
        0.03214447200298309,
        0.04229704290628433,
        0.052310895174741745,
        -0.04680398851633072,
        0.05176208168268204,
        -0.040666744112968445,
        -0.04902568459510803,
        0.05328761413693428,
        -0.053683023899793625,
        0.05113563314080238,
        0.05065302178263664,
        -0.0033150038216263056,
        0.05470861494541168,
        0.03158397972583771,
        -0.000949512526858598,
        -0.023496560752391815,
        0.054499510675668716,
        -0.004909184295684099,
        -0.0383133701980114,
        0.02979501336812973,
        0.027449078857898712,
        0.027898501604795456,
        -0.04075000062584877,
        0.05373053997755051,
        0.04695659875869751,
        0.03391208127140999,
        0.0543256513774395,
        0.031525492668151855,
        -0.049889493733644485,
        -0.00006841693539172411,
        -0.0291095282882452,
        0.05235421285033226,
        -0.004759869538247585,
        0.032938942313194275,
        0.048657890409231186,
        0.0430123470723629,
        -0.02257405035197735,
        0.054632462561130524,
        -0.03287700563669205,
        0.05470431596040726,
        -0.05132738873362541,
        0.04482978582382202,
        -0.04430710896849632,
        0.04856477677822113,
        -0.0499599426984787,
        -0.04554372280836105,
        0.026800161227583885,
        0.054251834750175476,
        -0.0163115281611681,
        0.051612019538879395,
        0.03478268161416054,
        0.04671550542116165,
        0.03959500044584274,
        -0.0009816763922572136,
        -0.030046356841921806,
        -0.00907305907458067,
        0.04901769012212753,
        0.050010304898023605,
        0.03739329054951668,
        0.05286430940032005,
        0.05467265844345093,
        -0.031602468341588974,
        0.04820498824119568,
        0.054315708577632904,
        0.02460928075015545,
        0.009131801314651966,
        -0.04925495386123657,
        0.0014356375904753804,
        -0.02922716736793518,
        -0.05137158930301666,
        -0.05439196899533272,
        -0.03391164168715477,
        -0.032657869160175323,
        0.05134747549891472,
        0.04501049593091011,
        -0.05290364474058151,
        -0.052025068551301956,
        -0.0522850938141346,
        -0.05418917164206505,
        0.0546894297003746,
        -0.048809781670570374,
        0.015092885121703148,
        -0.05467643588781357,
        0.001245443825609982,
        0.004438422620296478,
        0.04487263038754463,
        -0.011684195138514042,
        0.05388417840003967,
        0.04349730536341667,
        0.04109086096286774,
        0.03846057504415512,
        0.05469822883605957,
        -0.024600286036729813,
        -0.029109371826052666,
        -0.04676622524857521,
        -0.054143186658620834,
        0.013674926944077015,
        0.05338117852807045,
        -0.05165529623627663,
        -0.03316843509674072,
        -0.05450959503650665,
        -0.04735521599650383,
        -0.04064246639609337,
        -0.05473719537258148,
        -0.015018763020634651,
        -0.007146455813199282,
        -0.047889165580272675,
        0.023861881345510483,
        0.053194500505924225,
        0.03398902714252472,
        -0.054535020142793655,
        -0.05155927687883377,
        -0.054340213537216187,
        -0.004293534904718399,
        0.054690103977918625,
        0.00634901225566864,
        -0.05295392498373985,
        0.021491920575499535,
        0.05405145511031151,
        0.05428554117679596,
        -0.05383325740695,
        0.05391005054116249,
        0.04585990309715271,
        -0.036887817084789276,
        -0.049002595245838165,
        0.04692116007208824,
        0.05292323976755142,
        0.051533088088035583,
        -0.04510437697172165,
        0.02082233875989914,
        -0.034365758299827576,
        0.05345747992396355,
        0.05472559109330177,
        0.04350123181939125,
        -0.04887474328279495,
        -0.02216777205467224,
        -0.054741088300943375,
        0.042344361543655396,
        -0.044859521090984344,
        -0.04051355645060539,
        0.027830135077238083,
        -0.05297683924436569,
        -0.05343296751379967,
        -0.012560115195810795,
        -0.03516222909092903,
        -0.03065197356045246,
        -0.017941338941454887,
        -0.05296839773654938,
        -0.05468916893005371,
        -0.05223294720053673,
        0.05473312735557556,
        -0.050735924392938614,
        -0.0534321628510952,
        0.026386886835098267,
        0.05471377447247505,
        0.05474342033267021,
        0.054331135004758835,
        -0.018623586744070053,
        -0.0018458799459040165,
        0.054466135799884796,
        -0.054722294211387634,
        0.05374065414071083,
        0.048057958483695984,
        0.04661615565419197,
        0.008608904667198658,
        0.043302904814481735,
        0.039150070399045944,
        -0.020412571728229523,
        -0.05458425357937813,
        -0.04656606540083885,
        -0.054152265191078186,
        0.054641205817461014,
        0.05242185667157173,
        0.044795192778110504,
        -0.054725464433431625,
        -0.052603963762521744,
        -0.02108503133058548,
        0.05360519140958786,
        0.05438539758324623,
        -0.05253969132900238,
        0.05445042997598648,
        -0.047605570405721664,
        -0.051614027470350266,
        0.021142011508345604,
        0.0426272414624691,
        0.05388018488883972,
        0.049954045563936234,
        -0.043094880878925323,
        0.048012856394052505,
        0.04927513003349304,
        0.054344505071640015,
        -0.05410458892583847,
        0.038442496210336685,
        -0.004464986268430948,
        -0.04375622794032097,
        0.012653551064431667,
        0.044570907950401306,
        0.05185883864760399,
        0.05365933105349541,
        0.054049842059612274,
        0.05056200921535492,
        -0.05254477635025978,
        -0.05365188419818878,
        -0.03579811006784439,
        -0.0473296083509922,
        0.0544586181640625,
        0.04279003292322159,
        -0.04785952717065811,
        -0.0221957229077816,
        0.05466487258672714,
        0.022462306544184685,
        0.05447123944759369,
        0.0546787865459919,
        -0.05471942573785782,
        -0.01073629129678011,
        0.014682886190712452,
        0.03075985796749592,
        0.05465950816869736,
        -0.005545778200030327,
        0.05459314212203026,
        0.0030714080203324556,
        0.02564420737326145,
        -0.05325457826256752,
        0.04250442981719971,
        0.03781557455658913
    ],
    [
        -0.020059362053871155,
        0.0591965988278389,
        -0.04827321693301201,
        0.005600790027529001,
        0.013525708578526974,
        -0.00979597494006157,
        0.0633818656206131,
        -0.006572579964995384,
        -0.021988634020090103,
        0.054519783705472946,
        -0.01222651544958353,
        0.054557301104068756,
        -0.06568804383277893,
        -0.057930249720811844,
        -0.06429778039455414,
        0.04184016212821007,
        -0.06455646455287933,
        0.05146791785955429,
        -0.05772915482521057,
        0.04197736829519272,
        -0.027377398684620857,
        0.04905249550938606,
        0.052809301763772964,
        0.04986901953816414,
        -0.0012475894764065742,
        0.04783986136317253,
        0.00026212248485535383,
        0.06068851426243782,
        0.017586877569556236,
        0.029876334592700005,
        0.03860284760594368,
        -0.06191188842058182,
        0.01620611734688282,
        -0.0653277114033699,
        0.017532771453261375,
        -0.020089522004127502,
        -0.06432507187128067,
        -0.05451786145567894,
        0.05113822966814041,
        0.007096979301422834,
        -0.018136143684387207,
        0.04284489154815674,
        0.06549269706010818,
        -0.05500592663884163,
        0.051583822816610336,
        -0.02977968193590641,
        0.021517084911465645,
        0.06708010286092758,
        0.06501036882400513,
        0.03055020608007908,
        0.023766184225678444,
        0.030738139525055885,
        0.06327210366725922,
        -0.02684461511671543,
        0.04539220780134201,
        -0.01531662605702877,
        -0.023552628234028816,
        0.03018069639801979,
        -0.033631328493356705,
        -0.011027464643120766,
        -0.056364476680755615,
        0.03602686524391174,
        -0.05451679229736328,
        -0.015330851078033447,
        0.044603381305933,
        0.0575135312974453,
        0.02851150371134281,
        -0.06153254210948944,
        0.04312985762953758,
        -0.06736021488904953,
        -0.012864797376096249,
        -0.040039848536252975,
        0.00634868536144495,
        0.05671815574169159,
        -0.05235796421766281,
        -0.01714210957288742,
        0.0561724528670311,
        0.06713778525590897,
        0.060937292873859406,
        -0.0055232481099665165,
        -0.03818767890334129,
        0.015823891386389732,
        -0.05903654918074608,
        -0.009148664772510529,
        0.05221373960375786,
        0.012828360311686993,
        -0.011641832999885082,
        -0.021283837035298347,
        -0.057963743805885315,
        0.06597539037466049,
        -0.06347168982028961,
        0.021765494719147682,
        -0.033791765570640564,
        -0.03729534521698952,
        0.039333656430244446,
        0.005053725093603134,
        -0.046428196132183075,
        -0.06387528777122498,
        -0.050529785454273224,
        0.03753510117530823,
        -0.06574854999780655,
        0.01658174768090248,
        0.06653369218111038,
        0.055585093796253204,
        -0.01147403009235859,
        -0.014892849139869213,
        0.0033666174858808517,
        0.015891078859567642,
        -0.029470141977071762,
        0.007551880553364754,
        -0.039658185094594955,
        -0.06638989597558975,
        -0.020410779863595963,
        -0.029007941484451294,
        0.024616878479719162,
        0.00002425172169751022,
        -0.030807243660092354,
        -0.06296732276678085,
        -0.06413716077804565,
        -0.002233593724668026,
        -0.04311368241906166,
        -0.05047217383980751,
        0.020418919622898102,
        -0.005574374459683895,
        -0.06657028943300247,
        -0.028774937614798546,
        -0.023005839437246323,
        0.03492157161235809,
        0.05731368437409401,
        0.03307968005537987,
        -0.006996168754994869,
        -0.02086830697953701,
        0.01479323673993349,
        0.05803632363677025,
        -0.04804695025086403,
        -0.06588958203792572,
        0.029954370111227036,
        -0.055675797164440155,
        -0.06528695672750473,
        0.06097028777003288,
        -0.03973550349473953,
        -0.030575189739465714,
        0.02046007290482521,
        0.028915157541632652,
        0.018717873841524124,
        0.05189567431807518,
        0.051380518823862076,
        -0.05976016819477081,
        0.026009079068899155,
        0.04389171674847603,
        -0.012710374779999256,
        -0.06292276829481125,
        0.005141294561326504,
        0.035081539303064346,
        -0.03148841857910156,
        -0.06393003463745117,
        0.05141909793019295,
        -0.017020108178257942,
        -0.012200919911265373,
        0.05767021328210831,
        0.05270741879940033,
        0.017131976783275604,
        0.06127709150314331,
        0.04916573688387871,
        -0.05695547163486481,
        0.024064457044005394,
        0.007256006356328726,
        -0.047050777822732925,
        0.027305208146572113,
        0.03444897383451462,
        -0.02583497390151024,
        -0.006532621569931507,
        -0.06524357944726944,
        0.00033118855208158493,
        0.022611554712057114,
        0.03995291888713837,
        -0.05999009683728218,
        -0.04889119416475296,
        0.06173454597592354,
        -0.026348669081926346,
        0.03809383884072304,
        0.05520390719175339,
        0.055137671530246735,
        0.012391665950417519,
        0.011209730058908463,
        0.02528459206223488,
        -0.06368955224752426,
        0.05932477116584778,
        -0.052633628249168396,
        -0.009081050753593445,
        -0.04447527974843979,
        -0.03203466534614563,
        0.003356717061251402,
        0.0544014610350132,
        0.03128072991967201,
        -0.046081360429525375,
        0.004434213042259216,
        0.056284479796886444,
        -0.03707602620124817,
        0.040089793503284454,
        0.0018592500127851963,
        0.055216848850250244,
        0.04918200895190239,
        -0.006634979508817196,
        -0.029329681769013405,
        -0.03410555049777031,
        0.0319552943110466,
        -0.022705350071191788,
        -0.06625046581029892,
        0.052095383405685425,
        -0.06333430856466293,
        0.04935731738805771,
        -0.01528437901288271,
        0.06046581640839577,
        0.06467390805482864,
        0.03012935258448124,
        0.0009162183268927038,
        0.02304002270102501,
        0.06194797530770302,
        0.0007917917682789266,
        0.0478704497218132,
        -0.012113492004573345,
        -0.06506238877773285,
        -0.06630256026983261,
        0.04745398834347725,
        -0.06124592572450638,
        0.016900548711419106,
        -0.04325054585933685,
        -0.02746320329606533,
        -0.0598040409386158,
        -0.04367503896355629,
        -0.053072139620780945,
        -0.029318084940314293,
        0.05082784593105316,
        0.025747066363692284,
        -0.015930872410535812,
        0.06385128945112228,
        -0.034390367567539215,
        -0.034441251307725906,
        0.06713473051786423,
        0.058717429637908936,
        0.06380395591259003,
        -0.048513077199459076,
        0.04379510506987572,
        -0.026285599917173386,
        -0.02542019449174404,
        0.05611543357372284,
        -0.005288219545036554,
        -0.04097016528248787,
        0.022165386006236076,
        -0.05660080164670944,
        -0.06367788463830948,
        -0.010168928653001785,
        -0.050399478524923325,
        0.059216003865003586,
        0.05592971667647362,
        -0.04818671941757202,
        -0.01480600330978632,
        0.06215241178870201,
        0.06546057015657425,
        0.05627359077334404,
        0.06588339060544968,
        -0.02668772079050541,
        0.047660112380981445,
        0.03679174929857254,
        0.05153956264257431,
        0.06002630665898323,
        0.06125007942318916,
        -0.024044843390583992,
        -0.04682598263025284,
        -0.00347485882230103,
        -0.06611881405115128,
        -0.016014715656638145,
        -0.06548868119716644,
        -0.017071466892957687,
        0.047003332525491714,
        -0.06211138144135475,
        0.016500871628522873,
        -0.008037067949771881,
        -0.054261043667793274,
        -0.060430195182561874,
        0.04664720967411995,
        0.007390622049570084,
        -0.025716841220855713,
        -0.006393602583557367,
        -0.046647924929857254,
        0.05809600278735161,
        -0.032221775501966476,
        -0.05334952473640442,
        -0.02257123589515686,
        -0.06588345021009445,
        -0.004817056469619274,
        0.03271167725324631,
        -0.03910665959119797,
        0.026477551087737083,
        -0.050783127546310425,
        0.06698637455701828,
        0.05714375153183937,
        -0.03852878510951996,
        0.058721065521240234,
        -0.02010040357708931,
        0.016178078949451447,
        -0.017847755923867226,
        -0.004043549299240112,
        0.05174608901143074,
        -0.01960880123078823,
        -0.050954751670360565,
        0.06170342490077019,
        0.060329146683216095,
        -0.05390721559524536,
        -0.00827610120177269,
        -0.027874574065208435,
        -0.00134764623362571,
        0.019556431099772453,
        -0.065764419734478,
        -0.051658641546964645,
        0.04541982337832451,
        0.045309655368328094,
        0.01520661823451519,
        -0.05671129748225212,
        -0.05722079798579216,
        -0.00921658519655466,
        0.048409536480903625,
        -0.06364511698484421,
        0.062428321689367294,
        0.05481717735528946,
        0.05669507011771202,
        -0.015600834041833878,
        -0.037922050803899765,
        -0.0292357187718153,
        -0.010956252925097942,
        0.03531258925795555,
        0.020929111167788506,
        -0.05819881334900856,
        0.06066971644759178,
        0.05684422329068184,
        -0.05218546837568283,
        0.05734449252486229,
        0.022696945816278458,
        0.0662764459848404,
        0.029018880799412727,
        -0.018744487315416336,
        0.0436285175383091,
        -0.01812232844531536,
        0.06721775978803635,
        0.04790033400058746,
        0.014577540569007397,
        0.061056096106767654,
        0.034083977341651917,
        0.06202324479818344,
        -0.06383075565099716,
        0.041613515466451645,
        -0.024934181943535805,
        -0.05929996445775032,
        -0.0242576003074646,
        0.06222529336810112,
        -0.05587632581591606,
        -0.027502816170454025,
        -0.04338628798723221,
        -0.06082602217793465,
        0.053155381232500076,
        0.056713320314884186,
        -0.06538929045200348,
        -0.06364011019468307,
        -0.02489139698445797,
        -0.03162085637450218,
        0.06719847768545151,
        -0.006517897825688124,
        -0.004924795124679804,
        -0.06669534742832184,
        0.016079530119895935,
        -0.06503225862979889,
        0.045499105006456375,
        0.021836373955011368,
        0.06429316848516464,
        0.0624266043305397,
        0.06106738746166229,
        0.0013793157413601875,
        -0.05238329991698265,
        -0.054357320070266724,
        0.03336183354258537,
        0.0660548061132431,
        -0.06416789442300797,
        -0.04048484191298485,
        0.06650570780038834,
        -0.021561674773693085,
        0.06661231815814972,
        -0.06323858350515366,
        -0.05659663677215576,
        -0.06486526876688004,
        -0.023846067488193512,
        0.0016951403813436627,
        -0.012177669443190098,
        0.04408780857920647,
        -0.00907780323177576,
        0.06315676122903824,
        0.05159854516386986,
        -0.0655607283115387,
        -0.05483400821685791,
        -0.06062488257884979,
        -0.037704698741436005,
        0.04358111694455147,
        0.03292648121714592,
        0.06243358179926872,
        -0.05677749589085579,
        0.025843776762485504,
        0.027980687096714973,
        -0.01592886820435524,
        0.05503188073635101,
        -0.05432591959834099,
        0.031345516443252563,
        -0.022423719987273216,
        0.04402425140142441,
        -0.02601373940706253,
        0.048394981771707535,
        -0.0024268587585538626,
        0.009662282653152943,
        -0.046433720737695694,
        0.05556540936231613,
        0.028693798929452896,
        -0.05344901233911514,
        -0.05313007906079292,
        0.012016823515295982,
        -0.05870581790804863,
        0.01914084330201149,
        -0.03017798811197281,
        0.03582463413476944,
        -0.05590551346540451,
        -0.0658530443906784,
        -0.023910531774163246,
        0.018238302320241928,
        -0.04186801612377167,
        0.05119992420077324,
        0.020600706338882446,
        0.060784485191106796,
        -0.04463615640997887,
        -0.002792307874187827,
        0.01708409935235977,
        -0.014895952306687832,
        -0.022192349657416344,
        0.05155566334724426,
        -0.043613385409116745,
        0.05042800307273865,
        0.04595823213458061,
        -0.045486029237508774,
        0.06416473537683487,
        0.061031684279441833,
        -0.02069195918738842,
        0.0625491663813591,
        0.024132877588272095,
        -0.05946224555373192,
        0.01932438276708126,
        0.05381067097187042,
        0.018965011462569237,
        -0.013025004416704178,
        -0.031091922894120216,
        -0.01827777363359928,
        0.0300707146525383,
        0.009475107304751873,
        0.06474100798368454,
        0.059536807239055634,
        0.001633425708860159,
        -0.005594130605459213,
        -0.029361600056290627,
        0.06687480956315994,
        0.06606272608041763,
        -0.06702670454978943,
        0.057879332453012466,
        -0.04793870076537132,
        -0.06443249434232712,
        0.05140222981572151,
        -0.024119829759001732,
        0.034867577254772186,
        0.05524003878235817,
        0.04331391677260399,
        0.047914810478687286,
        -0.036973416805267334,
        -0.04521986469626427,
        -0.050977349281311035,
        -0.022429894655942917,
        -0.011813745833933353,
        0.033980317413806915,
        0.008549537509679794,
        -0.012195724062621593,
        -0.004681515973061323,
        0.05367930978536606,
        0.04610526189208031,
        0.007078561466187239,
        -0.06140262633562088,
        -0.02335371822118759,
        -0.03926150128245354,
        -0.06522060930728912,
        0.06043326482176781,
        -0.037188995629549026,
        0.05654871091246605,
        0.03194431588053703,
        -0.06232932209968567,
        -0.04927545040845871,
        0.062469787895679474,
        0.037674449384212494,
        -0.04239563271403313,
        0.010501754470169544,
        -0.05088729038834572,
        0.004474438726902008,
        -0.02560318261384964,
        -0.008833077736198902,
        0.06538622081279755,
        -0.01056748442351818,
        -0.04581338167190552,
        -0.059474196285009384,
        0.06614986062049866,
        -0.043704647570848465
    ],
    [
        0.015232088044285774,
        -0.019681071862578392,
        0.0403318889439106,
        0.05023631453514099,
        0.031303852796554565,
        -0.030131781473755836,
        0.052605390548706055,
        0.039357516914606094,
        -0.05282774195075035,
        0.05112890154123306,
        0.0528067909181118,
        -0.022929374128580093,
        -0.05258873477578163,
        -0.05211768299341202,
        -0.0489230677485466,
        0.04626452177762985,
        0.017045719549059868,
        0.05057182535529137,
        0.05186184123158455,
        0.05279713124036789,
        -0.01375211589038372,
        0.03778862953186035,
        0.04532333090901375,
        0.03737452253699303,
        -0.05216089263558388,
        0.0215920303016901,
        0.02170702815055847,
        0.05163383111357689,
        -0.05281233415007591,
        -0.007015794515609741,
        -0.047736864537000656,
        0.05283051356673241,
        0.03317640349268913,
        -0.05282105505466461,
        0.052079275250434875,
        -0.028208792209625244,
        -0.03609757125377655,
        0.0257570818066597,
        -0.05280226841568947,
        0.02175985835492611,
        0.0022563734091818333,
        -0.03195006772875786,
        0.0467558316886425,
        0.0528249554336071,
        -0.03196989372372627,
        -0.05224614962935448,
        0.049883510917425156,
        0.05276528373360634,
        0.052830301225185394,
        0.05086589604616165,
        0.04956851527094841,
        0.05283110588788986,
        0.05115008354187012,
        0.027986273169517517,
        -0.037524912506341934,
        -0.049502745270729065,
        -0.05283229053020477,
        0.02174755558371544,
        -0.05283000320196152,
        0.05256348475813866,
        -0.017646510154008865,
        0.05055761709809303,
        -0.050461526960134506,
        -0.05278735235333443,
        0.05247754976153374,
        0.01392428670078516,
        -0.05274398252367973,
        0.04631620645523071,
        -0.04437321797013283,
        0.05207611992955208,
        -0.05259835720062256,
        0.05262158438563347,
        0.05277309566736221,
        0.046958889812231064,
        0.04657980427145958,
        0.05068374425172806,
        0.052815649658441544,
        0.05283309519290924,
        0.052804816514253616,
        -0.03301180154085159,
        -0.0035423850640654564,
        -0.05280378833413124,
        -0.05279086157679558,
        0.05263775587081909,
        0.042063746601343155,
        0.027082379907369614,
        0.05272035300731659,
        -0.018441658467054367,
        0.033251043409109116,
        0.04696198180317879,
        -0.05282048135995865,
        -0.05018226429820061,
        0.04606799781322479,
        -0.014622604474425316,
        -0.04527322202920914,
        0.04829047620296478,
        -0.047174178063869476,
        -0.03302142396569252,
        -0.05166980251669884,
        -0.03328077495098114,
        -0.007405608426779509,
        0.052795860916376114,
        -0.04461643099784851,
        0.052512913942337036,
        0.047769367694854736,
        -0.052403971552848816,
        0.04026695340871811,
        -0.011644707061350346,
        -0.04896359518170357,
        0.03185175731778145,
        0.03467065840959549,
        0.052475448697805405,
        -0.048168815672397614,
        -0.05277451127767563,
        -0.04860474541783333,
        0.05126219987869263,
        -0.042242784053087234,
        -0.05134914815425873,
        -0.05259133130311966,
        -0.05282853543758392,
        -0.046081993728876114,
        0.032244972884655,
        -0.051334138959646225,
        0.03829163685441017,
        -0.04514988511800766,
        -0.03694901615381241,
        -0.05238489434123039,
        0.04822007566690445,
        0.05194249004125595,
        -0.05278448015451431,
        -0.052792735397815704,
        -0.045075204223394394,
        -0.052830565720796585,
        0.052827250212430954,
        -0.02818802371621132,
        -0.05266621708869934,
        0.04874088987708092,
        0.03298544883728027,
        0.00333886849693954,
        0.05282711237668991,
        0.05235149338841438,
        -0.008044948801398277,
        0.04957659915089607,
        -0.05145860090851784,
        0.051836296916007996,
        0.03510347008705139,
        0.05283301696181297,
        0.04470222443342209,
        -0.052807629108428955,
        0.002971870359033346,
        -0.026663849130272865,
        -0.05276433750987053,
        0.052833132445812225,
        -0.04230339452624321,
        0.05280959606170654,
        -0.051074787974357605,
        -0.052833158522844315,
        -0.033336590975522995,
        0.05155397206544876,
        0.051566705107688904,
        0.014809071086347103,
        0.04573555290699005,
        0.041968606412410736,
        -0.04775650054216385,
        -0.05280348286032677,
        -0.05137326940894127,
        -0.05277518182992935,
        -0.05249050259590149,
        -0.045574698597192764,
        -0.003202541498467326,
        0.0034118499606847763,
        0.052551496773958206,
        -0.052599046379327774,
        0.05283217132091522,
        0.05269593000411987,
        0.05222970247268677,
        0.040867939591407776,
        -0.052574850618839264,
        0.05039443075656891,
        -0.05057401955127716,
        0.004962767940014601,
        0.02242181822657585,
        0.051127441227436066,
        -0.016972346231341362,
        -0.05172165855765343,
        0.04539119452238083,
        -0.047151096165180206,
        0.01646857149899006,
        -0.04836966469883919,
        0.010373593308031559,
        -0.05279811471700668,
        0.03369727358222008,
        0.05283263698220253,
        0.05269191414117813,
        -0.04069084674119949,
        -0.051364555954933167,
        0.05283262953162193,
        0.05180229991674423,
        0.05248650908470154,
        -0.03975239023566246,
        0.05032644793391228,
        0.049419984221458435,
        -0.05276640132069588,
        -0.04927053302526474,
        0.05283283814787865,
        -0.05278809741139412,
        0.03671196103096008,
        -0.05069520324468613,
        -0.052727315574884415,
        0.05170430615544319,
        -0.05065864324569702,
        -0.041786182671785355,
        0.043030381202697754,
        0.04908803105354309,
        0.04770249128341675,
        -0.0004960247315466404,
        0.05279773846268654,
        0.0518338643014431,
        0.052255649119615555,
        0.022776689380407333,
        0.05190351977944374,
        0.052435677498579025,
        -0.03872556984424591,
        -0.0527459979057312,
        -0.052203334867954254,
        0.04442086070775986,
        0.002693985356017947,
        -0.04762786254286766,
        -0.028285343199968338,
        -0.05124774947762489,
        -0.05168154835700989,
        0.05256457254290581,
        0.049732811748981476,
        0.023003598675131798,
        -0.014976837672293186,
        0.046230852603912354,
        0.03852437809109688,
        0.04842620715498924,
        0.038589924573898315,
        0.052824582904577255,
        0.051694635301828384,
        -0.05162198469042778,
        -0.0034071665722876787,
        -0.05086873844265938,
        0.04508918151259422,
        -0.04992879927158356,
        -0.05283002927899361,
        0.04815557599067688,
        -0.05281825363636017,
        -0.031117558479309082,
        -0.052833057940006256,
        0.006670769304037094,
        -0.012920021079480648,
        -0.04841739311814308,
        -0.03642050176858902,
        0.016385862603783607,
        -0.016351956874132156,
        0.03343728184700012,
        0.03585232049226761,
        -0.02167901024222374,
        0.04652008041739464,
        -0.05280076712369919,
        0.015549245290458202,
        -0.05001617595553398,
        -0.032790407538414,
        0.05281102657318115,
        0.04152006283402443,
        0.05200382322072983,
        -0.05273789167404175,
        -0.05172593146562576,
        -0.010072092525660992,
        -0.05262408033013344,
        -0.004363812040537596,
        -0.03465505316853523,
        -0.05283301696181297,
        0.025704726576805115,
        0.0321422815322876,
        0.01079550851136446,
        0.0398712083697319,
        -0.05129621922969818,
        -0.05215057358145714,
        0.05223238095641136,
        0.03679530322551727,
        0.05283301696181297,
        -0.04932434484362602,
        0.05163828656077385,
        0.04969120770692825,
        0.05218622088432312,
        0.03675492852926254,
        0.028429409489035606,
        0.035574544221162796,
        -0.03312556445598602,
        -0.0420072041451931,
        0.05282766371965408,
        -0.05282829329371452,
        -0.05207551643252373,
        0.05282926559448242,
        0.05279864743351936,
        -0.003389843739569187,
        0.05242110416293144,
        -0.05177988111972809,
        0.052020132541656494,
        0.034117620438337326,
        0.04029713198542595,
        -0.046714503318071365,
        -0.05279259756207466,
        -0.03837208449840546,
        0.05203615874052048,
        -0.04540788754820824,
        -0.052310146391391754,
        0.050274819135665894,
        -0.040583375841379166,
        -0.018798356875777245,
        -0.05061527341604233,
        -0.052828170359134674,
        -0.045714836567640305,
        -0.032713912427425385,
        -0.052699290215969086,
        0.04815305396914482,
        0.03966658189892769,
        0.026759497821331024,
        0.04909497871994972,
        -0.052822764962911606,
        -0.052832867950201035,
        -0.00489185331389308,
        0.03305930271744728,
        -0.024024885147809982,
        -0.05237731337547302,
        -0.045350082218647,
        -0.044961731880903244,
        0.044384200125932693,
        -0.04707980155944824,
        -0.05270475149154663,
        -0.04165906086564064,
        0.05116388574242592,
        0.052355702966451645,
        0.05055837333202362,
        -0.03148726373910904,
        0.005614001769572496,
        0.009446580894291401,
        -0.044907115399837494,
        0.038489703088998795,
        0.042757026851177216,
        -0.05282994359731674,
        0.046442050486803055,
        0.012205300852656364,
        -0.028032300993800163,
        0.008762690238654613,
        -0.008964785374701023,
        0.05233558639883995,
        0.05251200497150421,
        0.025285499170422554,
        0.0509878471493721,
        0.036112841218709946,
        -0.02153060771524906,
        0.02967667020857334,
        -0.05260302498936653,
        -0.05005539208650589,
        -0.0472569540143013,
        0.051275789737701416,
        0.051330097019672394,
        0.03743609040975571,
        0.05168876051902771,
        -0.05153125897049904,
        0.04239923506975174,
        -0.05080456659197807,
        0.052365776151418686,
        0.05083834379911423,
        0.028523050248622894,
        -0.047622162848711014,
        -0.052225325256586075,
        -0.052807342261075974,
        -0.04365876689553261,
        -0.048613499850034714,
        -0.04958321899175644,
        -0.04257504269480705,
        -0.03997360169887543,
        -0.05276450514793396,
        -0.036146607249975204,
        0.05270051583647728,
        -0.0524313785135746,
        0.052833158522844315,
        -0.01606525294482708,
        0.039301760494709015,
        0.052777260541915894,
        -0.05237164720892906,
        -0.010287708602845669,
        0.0013586675049737096,
        -0.037285350263118744,
        0.04592679813504219,
        -0.05233355239033699,
        -0.05282387509942055,
        -0.0361500158905983,
        -0.05278228223323822,
        -0.050958096981048584,
        0.05199684947729111,
        0.05037236213684082,
        0.05260492116212845,
        -0.052710141986608505,
        -0.03733377158641815,
        -0.04620399698615074,
        0.05283227562904358,
        -0.024786582216620445,
        0.036644913256168365,
        -0.0020113480277359486,
        -0.05244699493050575,
        0.05277017503976822,
        -0.052788712084293365,
        0.05161893367767334,
        -0.015092496760189533,
        -0.040390629321336746,
        -0.018846765160560608,
        0.03564554452896118,
        0.013314253650605679,
        0.05247243866324425,
        -0.02718336321413517,
        -0.05282289534807205,
        0.010332781821489334,
        0.05193363130092621,
        -0.05280941724777222,
        0.052823517471551895,
        0.014475811272859573,
        0.040738172829151154,
        -0.052618421614170074,
        0.05171838030219078,
        -0.05086737871170044,
        0.030281752347946167,
        0.03488616645336151,
        -0.05279841646552086,
        0.009091021493077278,
        -0.052674565464258194,
        0.05169777572154999,
        0.04660128057003021,
        -0.0211788397282362,
        0.010482344776391983,
        -0.04730680584907532,
        -0.05280359461903572,
        0.04785973206162453,
        -0.052686210721731186,
        -0.05226871743798256,
        -0.052440691739320755,
        0.052832264453172684,
        0.0509728342294693,
        0.04826662316918373,
        0.01959671825170517,
        -0.018807265907526016,
        -0.025693930685520172,
        -0.038765523582696915,
        -0.024417411535978317,
        0.05233890563249588,
        -0.051594581454992294,
        -0.051215559244155884,
        0.05283210799098015,
        0.05217929929494858,
        -0.03730115294456482,
        -0.04227778688073158,
        0.04130132496356964,
        -0.009711985476315022,
        0.0345362052321434,
        0.04020852595567703,
        0.0528116449713707,
        0.037130292505025864,
        0.04918840900063515,
        0.03783667832612991,
        0.0517684742808342,
        0.05280304700136185,
        -0.028077736496925354,
        0.05283269286155701,
        -0.04101603478193283,
        -0.012879066169261932,
        0.03764772042632103,
        -0.05283316969871521,
        0.045094866305589676,
        0.051408179104328156,
        0.019395673647522926,
        -0.04041861742734909,
        -0.05283115431666374,
        -0.05280642211437225,
        0.023054443299770355,
        -0.052313584834337234,
        0.05280708894133568,
        0.0401080884039402,
        0.05277147516608238,
        -0.04934870824217796,
        -0.052806027233600616,
        0.04065545275807381,
        0.04677819088101387,
        -0.01718236319720745,
        -0.05119704455137253,
        0.021806243807077408,
        -0.051577381789684296,
        -0.052823469042778015,
        0.025953084230422974,
        0.047666337341070175,
        -0.027963493019342422,
        0.05272064730525017,
        -0.04418852552771568,
        -0.051345087587833405,
        0.05091175064444542,
        -0.05259495601058006,
        -0.03944610059261322,
        0.05281660705804825,
        -0.052833132445812225,
        0.05282291769981384,
        0.03251129016280174,
        0.04661007225513458,
        0.042643316090106964,
        -0.047275517135858536,
        0.04718736186623573,
        -0.052760399878025055,
        0.03965596482157707,
        -0.05124619975686073
    ],
    [
        0.048907991498708725,
        -0.024189144372940063,
        0.05306680500507355,
        0.037930503487586975,
        -0.021480726078152657,
        -0.05417368933558464,
        0.05103963986039162,
        -0.05606197938323021,
        0.021207524463534355,
        0.027076570317149162,
        0.051873479038476944,
        0.05328657478094101,
        -0.03999894857406616,
        -0.04801386594772339,
        -0.055103156715631485,
        -0.03984883055090904,
        -0.011163746938109398,
        0.055602677166461945,
        0.0218860674649477,
        -0.04523887112736702,
        0.04651033878326416,
        0.056161507964134216,
        0.05616820976138115,
        0.0051396130584180355,
        -0.05389712005853653,
        0.05287037417292595,
        -0.019329946488142014,
        -0.05180065706372261,
        -0.027620501816272736,
        0.04494548216462135,
        0.04888050630688667,
        -0.04646378755569458,
        0.04412383958697319,
        -0.05615336820483208,
        0.05121532082557678,
        -0.04411891847848892,
        -0.05543429031968117,
        -0.04962198808789253,
        -0.00009811724157771096,
        -0.05571264773607254,
        -0.049082376062870026,
        0.004634533077478409,
        0.05367865413427353,
        -0.023073840886354446,
        -0.035939112305641174,
        0.025791363790631294,
        0.015617914497852325,
        0.05604343116283417,
        0.05501994118094444,
        -0.04084809869527817,
        0.05552181228995323,
        0.05502591282129288,
        0.01005406305193901,
        -0.029621677473187447,
        -0.04606357961893082,
        0.01508435606956482,
        -0.0552193783223629,
        0.022559812292456627,
        -0.056004974991083145,
        -0.050714343786239624,
        -0.055969640612602234,
        -0.028800547122955322,
        -0.05602458491921425,
        -0.02166743017733097,
        0.05574249476194382,
        -0.05174915865063667,
        -0.05309843271970749,
        -0.055903591215610504,
        0.043842658400535583,
        -0.006440162658691406,
        0.05307292193174362,
        -0.05568630248308182,
        0.0545542910695076,
        0.0077145518735051155,
        0.05389028787612915,
        -0.05230090394616127,
        0.03234660625457764,
        0.0561668798327446,
        0.05534037947654724,
        0.05573562905192375,
        -0.046125710010528564,
        -0.05211090296506882,
        -0.05427722632884979,
        0.053219329565763474,
        -0.054893024265766144,
        0.05377381294965744,
        -0.018175510689616203,
        0.006698195822536945,
        -0.0413956455886364,
        0.053718023002147675,
        -0.05579831451177597,
        -0.05165207386016846,
        -0.05570651963353157,
        -0.04481017589569092,
        -0.03002380207180977,
        0.05339900404214859,
        -0.04688652232289314,
        -0.05611715465784073,
        -0.05228540301322937,
        0.02340608276426792,
        -0.05477041378617287,
        0.05606203153729439,
        0.02456468902528286,
        0.018183445557951927,
        0.011124074459075928,
        0.035979293286800385,
        0.05524028092622757,
        0.02793249674141407,
        -0.0310635007917881,
        0.05605117231607437,
        0.05098286643624306,
        -0.05474065616726875,
        -0.038612596690654755,
        -0.054298222064971924,
        0.053473882377147675,
        0.001862218719907105,
        -0.055478475987911224,
        -0.04986114054918289,
        0.05289037525653839,
        -0.05476943030953407,
        -0.036375585943460464,
        0.03071737475693226,
        -0.0008033866179175675,
        0.03727098926901817,
        -0.05215393379330635,
        -0.055717118084430695,
        0.004089193884283304,
        0.05348557233810425,
        0.056020013988018036,
        0.043794307857751846,
        0.03828638792037964,
        -0.055827341973781586,
        0.05337189510464668,
        0.05605846643447876,
        -0.04634108766913414,
        -0.055909574031829834,
        0.05500750616192818,
        0.05245302617549896,
        0.050306469202041626,
        0.05560153350234032,
        -0.02087434194982052,
        -0.055821701884269714,
        0.05119158327579498,
        0.01191733218729496,
        0.048679955303668976,
        0.04743398725986481,
        0.05500137060880661,
        0.05606328696012497,
        -0.05258503556251526,
        -0.04443594440817833,
        0.03002157062292099,
        -0.055629242211580276,
        -0.04701691120862961,
        0.04635336995124817,
        0.05542251095175743,
        -0.046002522110939026,
        -0.0010009931866079569,
        -0.055311065167188644,
        0.048665303736925125,
        -0.05546547472476959,
        0.05539904907345772,
        0.02754850499331951,
        0.053615324199199677,
        0.04487401992082596,
        -0.04761597514152527,
        0.026899240911006927,
        -0.053308166563510895,
        -0.026421915739774704,
        -0.052147142589092255,
        0.018713070079684258,
        -0.013364886865019798,
        0.05062117427587509,
        -0.04590928554534912,
        0.04877183586359024,
        -0.03884071111679077,
        -0.027411850169301033,
        0.05471718683838844,
        0.005468594841659069,
        0.054576437920331955,
        -0.053452812135219574,
        -0.05371413379907608,
        0.05611235648393631,
        0.053763922303915024,
        -0.010988021269440651,
        0.029499711468815804,
        0.05563779175281525,
        0.019456462934613228,
        0.055031996220350266,
        -0.03201936557888985,
        -0.05339229106903076,
        -0.054197970777750015,
        0.05149334669113159,
        0.00959750171750784,
        -0.04772023484110832,
        -0.053728628903627396,
        0.02406478486955166,
        0.006574953440576792,
        -0.038018882274627686,
        -0.051973577588796616,
        0.03664228320121765,
        0.05527154356241226,
        0.03251006826758385,
        0.006638017483055592,
        -0.05555479973554611,
        -0.02170473150908947,
        -0.013176116161048412,
        0.04807562008500099,
        0.0561482310295105,
        -0.054312899708747864,
        0.05606137961149216,
        -0.054774850606918335,
        0.04879719763994217,
        0.026117047294974327,
        0.05582091212272644,
        0.036524202674627304,
        -0.04832381382584572,
        0.05087265744805336,
        0.026555940508842468,
        0.05555646866559982,
        0.056066110730171204,
        0.053202398121356964,
        0.012492845766246319,
        0.02496446669101715,
        -0.05598725378513336,
        0.0384865403175354,
        -0.055955540388822556,
        0.028954973444342613,
        -0.0026422524824738503,
        0.010877910070121288,
        -0.055293090641498566,
        -0.04412003606557846,
        -0.03307721018791199,
        0.01943209022283554,
        0.044992148876190186,
        0.0034321502316743135,
        0.05025061219930649,
        0.05099846050143242,
        0.04120759293437004,
        0.04190659523010254,
        0.05610649287700653,
        0.03629195690155029,
        0.04165066406130791,
        -0.04387276619672775,
        -0.031306665390729904,
        -0.015173311345279217,
        0.0174721572548151,
        0.04404941201210022,
        -0.03874163702130318,
        -0.05473132058978081,
        -0.021738221868872643,
        -0.054005201905965805,
        -0.05423494800925255,
        -0.03711165115237236,
        -0.053850505501031876,
        0.02076152339577675,
        0.007717528380453587,
        0.050103042274713516,
        0.015205119736492634,
        0.05544077232480049,
        0.04702660068869591,
        -0.0551217719912529,
        0.021369965746998787,
        0.026363570243120193,
        0.05595812946557999,
        -0.05245009437203407,
        0.05597637966275215,
        0.05519680678844452,
        0.049962013959884644,
        -0.05596068501472473,
        -0.056003253906965256,
        0.04513515159487724,
        -0.038428302854299545,
        0.0285467728972435,
        -0.054950129240751266,
        -0.024382466450333595,
        0.052658453583717346,
        -0.0470174103975296,
        -0.054002050310373306,
        0.04829996079206467,
        -0.011225898750126362,
        -0.05390589311718941,
        0.037032630294561386,
        -0.03721649572253227,
        -0.050365861505270004,
        -0.042990997433662415,
        0.052662719041109085,
        0.039926692843437195,
        0.04551619663834572,
        0.029693882912397385,
        0.0219058096408844,
        -0.05141879990696907,
        0.002830204088240862,
        -0.027654537931084633,
        0.0024406062439084053,
        0.05385899171233177,
        -0.05471867322921753,
        0.05226556584239006,
        -0.04389014095067978,
        -0.05471377074718475,
        0.05307087302207947,
        -0.05615641176700592,
        0.023603947833180428,
        -0.0022807344794273376,
        -0.011174589395523071,
        -0.03992282971739769,
        -0.04605090245604515,
        -0.03127911314368248,
        0.018018819391727448,
        0.05259521305561066,
        -0.023125022649765015,
        0.016452251002192497,
        -0.04683239758014679,
        -0.01696825586259365,
        0.046850260347127914,
        -0.03239220008254051,
        -0.05566485971212387,
        0.0110074607655406,
        -0.04590504989027977,
        0.05540505051612854,
        -0.031751856207847595,
        0.044393885880708694,
        0.047955822199583054,
        -0.03567759692668915,
        -0.008686790242791176,
        0.05591060221195221,
        0.03082401305437088,
        0.03877141326665878,
        -0.05433906242251396,
        -0.05352730676531792,
        -0.04751671105623245,
        0.05464869365096092,
        0.02171238139271736,
        0.02149626985192299,
        0.05072527751326561,
        0.05598970502614975,
        0.05098024383187294,
        -0.05594762787222862,
        0.05398411676287651,
        -0.04210053011775017,
        0.03444794937968254,
        -0.05510753020644188,
        0.042229875922203064,
        0.05611659213900566,
        0.03614525496959686,
        0.04736031964421272,
        0.03362526744604111,
        0.040837135165929794,
        0.05398443341255188,
        0.04633970931172371,
        0.04711344838142395,
        0.05213460326194763,
        -0.03995123505592346,
        0.04002044349908829,
        -0.046156466007232666,
        -0.055710867047309875,
        -0.032853979617357254,
        0.004063170403242111,
        -0.033313196152448654,
        -0.05530700832605362,
        -0.056156981736421585,
        0.04823438450694084,
        0.046389393508434296,
        -0.05130958929657936,
        -0.04193590581417084,
        -0.05539580062031746,
        -0.05525648966431618,
        0.05604928359389305,
        -0.009994436986744404,
        -0.053323764353990555,
        -0.056105099618434906,
        -0.04667743295431137,
        -0.04941156134009361,
        0.03867055103182793,
        -0.05047006905078888,
        0.0497899129986763,
        0.05371757969260216,
        0.005968810524791479,
        -0.049502041190862656,
        0.04905203729867935,
        -0.018039796501398087,
        -0.035513985902071,
        0.05269084870815277,
        -0.04967116564512253,
        0.036004673689603806,
        0.055969104170799255,
        -0.021298879757523537,
        0.04946163296699524,
        -0.05449458584189415,
        -0.05259408801794052,
        0.05402117222547531,
        -0.05546024069190025,
        0.03549407422542572,
        -0.025471458211541176,
        -0.05570128932595253,
        0.0016535449540242553,
        0.0543317086994648,
        0.05513941869139671,
        -0.02760969288647175,
        -0.026414930820465088,
        -0.05583276227116585,
        0.01134881004691124,
        0.055464453995227814,
        -0.05329211428761482,
        0.01906868815422058,
        -0.05239872634410858,
        -0.014774208888411522,
        0.0556904599070549,
        0.02749592810869217,
        0.05528729408979416,
        0.0227075032889843,
        -0.04502549767494202,
        -0.054731570184230804,
        0.02032962255179882,
        0.012192845344543457,
        0.054488036781549454,
        -0.055455949157476425,
        -0.004161294549703598,
        0.0549117736518383,
        0.055515870451927185,
        0.055284660309553146,
        0.047266680747270584,
        -0.0545354038476944,
        0.04328826069831848,
        -0.05610208958387375,
        -0.048069581389427185,
        0.027890585362911224,
        -0.04980851337313652,
        -0.007525400258600712,
        -0.04640170931816101,
        -0.0477505587041378,
        -0.01369450706988573,
        0.02791745774447918,
        0.032909926027059555,
        -0.017291953787207603,
        0.01556050032377243,
        -0.05565565079450607,
        -0.05613419786095619,
        0.04493442550301552,
        -0.054666753858327866,
        -0.05560735985636711,
        -0.04704810678958893,
        0.015606452710926533,
        0.05465997755527496,
        0.053927596658468246,
        -0.03959681838750839,
        -0.019203849136829376,
        0.05459141358733177,
        -0.02213737927377224,
        0.0043847267515957355,
        0.03903237357735634,
        0.038459412753582,
        -0.004296502098441124,
        0.05199965462088585,
        0.04886491596698761,
        -0.055970240384340286,
        -0.05589653179049492,
        -0.0297026876360178,
        -0.056051965802907944,
        0.03997344896197319,
        0.012329966761171818,
        0.05564410239458084,
        -0.051162075251340866,
        0.03352978080511093,
        0.014493266120553017,
        0.05609481781721115,
        0.05525050684809685,
        -0.054610077291727066,
        0.05613355711102486,
        0.04377780482172966,
        -0.040283817797899246,
        0.05508755147457123,
        0.036260996013879776,
        0.05551519617438316,
        0.05567460134625435,
        0.043709415942430496,
        0.014347728341817856,
        -0.019335024058818817,
        0.055889323353767395,
        -0.0386994406580925,
        -0.05416654050350189,
        -0.05555795133113861,
        0.039471860975027084,
        -0.012534168548882008,
        0.038215793669223785,
        -0.056077513843774796,
        0.0526224784553051,
        0.04986044391989708,
        0.04950739070773125,
        -0.05432415008544922,
        -0.010049985721707344,
        0.039889074862003326,
        0.023037372156977654,
        0.056067630648612976,
        -0.04238676652312279,
        0.05166354775428772,
        0.04301071539521217,
        -0.05540940910577774,
        -0.005450185388326645,
        0.04856952279806137,
        -0.044545210897922516,
        0.030795203521847725,
        -0.0005426752613857388,
        -0.04949943348765373,
        0.05557916685938835,
        0.045149706304073334,
        0.027142900973558426,
        0.0560823418200016,
        -0.013795598410069942,
        0.045818161219358444,
        -0.04728083312511444,
        0.022333793342113495,
        0.02990860678255558
    ],
    [
        -0.05892355367541313,
        -0.05566192418336868,
        -0.053391918540000916,
        0.0211371798068285,
        0.008013227954506874,
        0.023756228387355804,
        0.0410638302564621,
        -0.02089603990316391,
        -0.006942644715309143,
        0.053672123700380325,
        -0.014938060194253922,
        0.04924318566918373,
        -0.031904056668281555,
        -0.0508306659758091,
        -0.05772734060883522,
        -0.021868400275707245,
        -0.05819833278656006,
        -0.051436860114336014,
        -0.056275468319654465,
        0.05244952812790871,
        -0.05878646299242973,
        0.02445763349533081,
        0.05620982497930527,
        0.045625440776348114,
        0.04630717635154724,
        0.04467994347214699,
        0.058480747044086456,
        0.05597849562764168,
        -0.06017811596393585,
        0.032370973378419876,
        0.036549992859363556,
        -0.04824893921613693,
        0.03650296851992607,
        -0.05977100506424904,
        0.014827445149421692,
        0.05129614099860191,
        -0.058770958334207535,
        0.013699837028980255,
        0.041464440524578094,
        0.04489579796791077,
        -0.004540760535746813,
        0.04556961730122566,
        0.059506695717573166,
        -0.06082863360643387,
        0.038319505751132965,
        0.05001349002122879,
        0.04113870859146118,
        0.06021460145711899,
        0.05942104756832123,
        0.008931981399655342,
        0.05337211862206459,
        -0.021498339250683784,
        0.05349321290850639,
        0.016295064240694046,
        0.001205553999170661,
        -0.04460104927420616,
        -0.0549040324985981,
        -0.013670966029167175,
        0.015706313773989677,
        0.03892168775200844,
        -0.05756409466266632,
        -0.0038077509962022305,
        -0.057416610419750214,
        0.02699693664908409,
        -0.043716683983802795,
        0.06077244132757187,
        0.060914263129234314,
        -0.04871774837374687,
        0.05889137461781502,
        -0.06138123199343681,
        -0.040534909814596176,
        -0.060078658163547516,
        -0.044626183807849884,
        0.006195235066115856,
        -0.05789480730891228,
        0.033646754920482635,
        0.05238687992095947,
        0.0604594461619854,
        0.05581015720963478,
        -0.05105484649538994,
        -0.05772852525115013,
        0.0602680929005146,
        -0.05784328654408455,
        0.020766807720065117,
        0.05414330214262009,
        0.046812690794467926,
        0.020925333723425865,
        0.015058476477861404,
        -0.06022089719772339,
        0.06066090241074562,
        -0.061120666563510895,
        0.017720310017466545,
        -0.05758100748062134,
        -0.017543604597449303,
        0.04281625151634216,
        0.05153188109397888,
        -0.006366610061377287,
        -0.0026326817460358143,
        -0.04972590133547783,
        0.05435163527727127,
        -0.06135290116071701,
        0.05281003937125206,
        0.061373814940452576,
        0.058207523077726364,
        -0.004436844028532505,
        -0.036857739090919495,
        0.04368528723716736,
        0.0034493347629904747,
        -0.034575510770082474,
        -0.023445382714271545,
        -0.05808195099234581,
        -0.06035024672746658,
        -0.021526046097278595,
        -0.0028447695076465607,
        0.015842188149690628,
        -0.038532063364982605,
        -0.01824895106256008,
        -0.030118266120553017,
        -0.06105994060635567,
        -0.02536318264901638,
        0.013653365895152092,
        -0.0515068881213665,
        -0.06107634678483009,
        0.05927865207195282,
        -0.06085651367902756,
        -0.027041761204600334,
        0.03845811262726784,
        -0.011475528590381145,
        0.04906156286597252,
        0.039489347487688065,
        0.0570262186229229,
        -0.014100933447480202,
        -0.058999404311180115,
        0.03929910436272621,
        -0.03426019102334976,
        -0.04306960105895996,
        0.06060865893959999,
        -0.06066067889332771,
        -0.05821630731225014,
        0.03019540011882782,
        -0.001225581974722445,
        -0.0017357649048790336,
        0.04737111181020737,
        0.039869002997875214,
        -0.01664040982723236,
        0.03177936002612114,
        0.05076105147600174,
        -0.055688176304101944,
        -0.001546280924230814,
        0.03951744735240936,
        -0.04449424520134926,
        -0.06091148033738136,
        -0.038402508944272995,
        0.02759421430528164,
        -0.028840461745858192,
        -0.056484077125787735,
        0.05903884023427963,
        -0.05672486871480942,
        -0.01428991463035345,
        -0.00564363319426775,
        0.05344894528388977,
        -0.04276621341705322,
        0.04916422814130783,
        0.028939224779605865,
        -0.03644575551152229,
        0.032809413969516754,
        0.049067966639995575,
        -0.05477551743388176,
        0.032244689762592316,
        0.0413593165576458,
        -0.010586604475975037,
        -0.006878822576254606,
        -0.03656158223748207,
        0.010790950618684292,
        -0.028242601081728935,
        0.060265202075242996,
        -0.06055903807282448,
        0.0399770550429821,
        0.059654589742422104,
        -0.029359670355916023,
        0.010930069722235203,
        0.05423251539468765,
        0.04459834471344948,
        0.05098041892051697,
        -0.01752590574324131,
        -0.029847612604498863,
        -0.06046999990940094,
        0.05900006741285324,
        0.0022998845670372248,
        -0.03565230220556259,
        0.050919607281684875,
        -0.028060046955943108,
        0.023013222962617874,
        0.04727102816104889,
        -0.020401807501912117,
        -0.0313551239669323,
        0.02104826830327511,
        0.00901709496974945,
        -0.054837603121995926,
        0.057374000549316406,
        0.05328372120857239,
        0.02156209945678711,
        0.028441179543733597,
        0.004042975138872862,
        0.014187287539243698,
        0.026189323514699936,
        0.024705875664949417,
        0.003397673834115267,
        -0.0576607808470726,
        -0.01683555170893669,
        -0.051882486790418625,
        0.05827341601252556,
        0.032484833151102066,
        0.03379490226507187,
        0.05816686153411865,
        -0.000509869132656604,
        0.058225538581609726,
        0.00027578516164794564,
        0.05566416680812836,
        -0.03239007294178009,
        -0.032404981553554535,
        -0.05733046680688858,
        -0.02679506503045559,
        -0.059011176228523254,
        0.04615214839577675,
        -0.061140649020671844,
        -0.03984549641609192,
        0.025614343583583832,
        -0.049600452184677124,
        -0.05567384138703346,
        0.050698719918727875,
        -0.05865546688437462,
        -0.013320722617208958,
        0.05110054463148117,
        -0.04054507985711098,
        0.04193674400448799,
        0.05638955906033516,
        -0.03340696170926094,
        0.0147669343277812,
        0.059344202280044556,
        0.04582195356488228,
        0.029136527329683304,
        -0.05932190641760826,
        0.04362991824746132,
        -0.01060135755687952,
        0.03176794573664665,
        0.059264231473207474,
        -0.030570324510335922,
        -0.021310999989509583,
        0.00785024557262659,
        -0.017672335729002953,
        -0.05984429642558098,
        -0.0486324280500412,
        -0.054321713745594025,
        0.04950439929962158,
        0.057971421629190445,
        -0.04302478954195976,
        -0.04454684257507324,
        0.06071827560663223,
        0.037592675536870956,
        0.029900407418608665,
        0.047643259167671204,
        0.05642519146203995,
        0.05952376872301102,
        0.023957913741469383,
        0.048363495618104935,
        0.03970430791378021,
        0.0498446486890316,
        -0.03491991013288498,
        -0.05855093523859978,
        -0.04748010262846947,
        -0.06132274493575096,
        0.02503753826022148,
        -0.06027238070964813,
        0.0007712201331742108,
        0.06040218472480774,
        -0.054655056446790695,
        0.02127373218536377,
        -0.030199149623513222,
        -0.05078897625207901,
        0.04322563484311104,
        0.060055602341890335,
        0.027041878551244736,
        0.04555748030543327,
        0.011357066221535206,
        0.04738202691078186,
        0.03354087471961975,
        -0.04103279858827591,
        -0.05478797107934952,
        0.01054100040346384,
        -0.057932157069444656,
        -0.06076035276055336,
        0.025225825607776642,
        0.04565032199025154,
        -0.019710978493094444,
        -0.021701229736208916,
        0.061370499432086945,
        -0.051799580454826355,
        -0.010472559370100498,
        0.05507998913526535,
        0.05793396010994911,
        0.051697857677936554,
        0.008759144693613052,
        0.05600994452834129,
        -0.01789822056889534,
        0.008342130109667778,
        -0.054328855127096176,
        0.05376378446817398,
        0.028251146897673607,
        -0.04959382489323616,
        -0.052477966994047165,
        -0.038489632308483124,
        0.00805802270770073,
        0.048197221010923386,
        -0.05885728448629379,
        -0.04724286124110222,
        0.04990434646606445,
        0.020255528390407562,
        0.05104108154773712,
        -0.05729672685265541,
        -0.04660811275243759,
        -0.06039929389953613,
        0.057446304708719254,
        -0.0553416945040226,
        -0.04092731326818466,
        0.06075643375515938,
        0.02125736139714718,
        0.04197320342063904,
        -0.0052826679311692715,
        -0.03282441943883896,
        -0.031629402190446854,
        0.009113828651607037,
        -0.01767469197511673,
        -0.05985407903790474,
        0.05727064982056618,
        -0.006806990131735802,
        0.04467413201928139,
        0.061135631054639816,
        -0.04317592456936836,
        0.004739692434668541,
        -0.013132096268236637,
        -0.016863364726305008,
        -0.041520856320858,
        -0.059719834476709366,
        0.061311274766922,
        0.01801576092839241,
        0.028018074110150337,
        0.05260307341814041,
        0.060768935829401016,
        0.047285374253988266,
        -0.06010515242815018,
        0.009545865468680859,
        -0.04853643476963043,
        -0.0606326162815094,
        -0.060239631682634354,
        0.05788213759660721,
        -0.05899263545870781,
        0.029603207483887672,
        -0.05777788534760475,
        -0.04650486633181572,
        0.047184545546770096,
        0.057745158672332764,
        -0.05846400558948517,
        -0.05454939603805542,
        0.0467400997877121,
        -0.034003205597400665,
        0.06127726659178734,
        -0.05876535177230835,
        -0.007025022059679031,
        -0.06123927980661392,
        -0.043503157794475555,
        -0.061189647763967514,
        0.058783888816833496,
        0.05126182734966278,
        0.05091416835784912,
        0.051841843873262405,
        0.05907822027802467,
        0.01671784743666649,
        0.02170569822192192,
        -0.04913153871893883,
        0.023008158430457115,
        0.056956835091114044,
        -0.059382300823926926,
        0.011265618726611137,
        -0.008592181839048862,
        -0.032329436391592026,
        0.06063549220561981,
        -0.04821566492319107,
        -0.047487687319517136,
        -0.05212746560573578,
        -0.05917435884475708,
        0.05866274982690811,
        -0.002843654016032815,
        0.05766361951828003,
        -0.0486433170735836,
        0.0592367984354496,
        0.05740844085812569,
        -0.057053204625844955,
        -0.057766396552324295,
        -0.06111571565270424,
        -0.053622279316186905,
        0.05545462295413017,
        0.059623751789331436,
        0.0563335157930851,
        0.037949711084365845,
        0.05356348305940628,
        0.040212877094745636,
        -0.03725307062268257,
        0.06043414771556854,
        -0.05949963256716728,
        0.059286992996931076,
        0.01793191023170948,
        -0.04814762622117996,
        0.04545794054865837,
        0.046708956360816956,
        -0.031764790415763855,
        -0.04601721838116646,
        -0.019892876967787743,
        0.059485483914613724,
        0.023961124941706657,
        -0.0591401681303978,
        -0.02768523059785366,
        -0.03197561949491501,
        -0.05957966670393944,
        0.020856814458966255,
        0.05534908175468445,
        0.03926285356283188,
        -0.05960167199373245,
        -0.060822855681180954,
        -0.05335293337702751,
        0.05893687531352043,
        -0.023179946467280388,
        -0.009476572275161743,
        -0.05432574823498726,
        0.05984095111489296,
        0.036082059144973755,
        0.005693397484719753,
        0.0526302270591259,
        0.02247285656630993,
        -0.05964912846684456,
        -0.017016440629959106,
        0.009237752296030521,
        -0.03396407142281532,
        0.0399654395878315,
        0.024339359253644943,
        0.06098317354917526,
        0.060592301189899445,
        -0.057241782546043396,
        0.015477262437343597,
        -0.055214207619428635,
        -0.058313921093940735,
        0.002202614676207304,
        0.03969980403780937,
        0.004156327340751886,
        0.020969796925783157,
        -0.04359154403209686,
        -0.029164154082536697,
        -0.016796039417386055,
        -0.008280069567263126,
        0.05909549072384834,
        0.023163948208093643,
        0.010330154560506344,
        0.011322546750307083,
        0.00876845233142376,
        0.05955762043595314,
        0.06101392209529877,
        -0.056687597185373306,
        0.05955791473388672,
        -0.04846216365695,
        -0.058602526783943176,
        0.03250930830836296,
        0.045041561126708984,
        0.05245136469602585,
        0.055622316896915436,
        -0.0517142154276371,
        0.01916387863457203,
        -0.04685927554965019,
        0.031554944813251495,
        -0.030561145395040512,
        -0.058039192110300064,
        -0.012325766496360302,
        0.014950904995203018,
        0.002820518333464861,
        -0.011882498860359192,
        -0.005582168698310852,
        0.05892876535654068,
        -0.03505038842558861,
        0.014797603711485863,
        -0.058449096977710724,
        -0.04913755878806114,
        0.02678939513862133,
        -0.044307731091976166,
        0.027426283806562424,
        -0.02871883288025856,
        0.059178125113248825,
        0.052261315286159515,
        -0.061080724000930786,
        0.026344845071434975,
        0.012153459712862968,
        0.05976971238851547,
        -0.04671918973326683,
        0.0508597232401371,
        0.02580178529024124,
        -0.05837395787239075,
        -0.02156906947493553,
        -0.029207928106188774,
        0.027598660439252853,
        0.02560151368379593,
        -0.04122721776366234,
        -0.015147965401411057,
        0.06045283377170563,
        0.05081945285201073
    ],
    [
        -0.058088500052690506,
        -0.05887748301029205,
        -0.03386630862951279,
        -0.0008035947103053331,
        0.04691998288035393,
        -0.04117360711097717,
        0.05882789567112923,
        -0.001225007581524551,
        0.029686346650123596,
        0.04848101735115051,
        0.005800469312816858,
        0.03521464765071869,
        -0.04671808332204819,
        -0.05529281124472618,
        -0.056693121790885925,
        -0.04116865247488022,
        0.013708011247217655,
        0.03706176206469536,
        -0.0588822178542614,
        0.05108390748500824,
        0.05502258241176605,
        -0.05357317253947258,
        0.05582471564412117,
        -0.04408122971653938,
        -0.04240329563617706,
        0.04868113249540329,
        -0.03410152718424797,
        0.041616130620241165,
        -0.05190671607851982,
        0.03564425930380821,
        0.012756324373185635,
        0.057424839586019516,
        0.0520610511302948,
        -0.05834411829710007,
        0.0013341245939955115,
        -0.02688506431877613,
        -0.053292788565158844,
        -0.05732584744691849,
        -0.058889247477054596,
        -0.03098398819565773,
        0.01324041374027729,
        -0.002364380517974496,
        0.05678870156407356,
        -0.05239412561058998,
        0.05265354737639427,
        -0.03673897311091423,
        0.05888958275318146,
        0.05832730978727341,
        0.05781494826078415,
        -0.05888823792338371,
        0.05545628443360329,
        -0.04874436929821968,
        0.05383443459868431,
        0.009620505385100842,
        0.04538755491375923,
        -0.054648034274578094,
        -0.01294885203242302,
        0.02404550090432167,
        -0.058125872164964676,
        0.05212218314409256,
        -0.05534885823726654,
        0.007965711876749992,
        -0.035852134227752686,
        0.024365214630961418,
        -0.0577533058822155,
        0.05888362601399422,
        0.03617085516452789,
        0.05869073048233986,
        0.027023302391171455,
        -0.058889664709568024,
        0.02459922432899475,
        -0.05553653463721275,
        -0.004575556144118309,
        0.055508315563201904,
        0.05398719757795334,
        0.045058026909828186,
        0.05227487534284592,
        0.05567280948162079,
        0.048063840717077255,
        -0.0354284830391407,
        -0.048611871898174286,
        -0.003254668088629842,
        -0.05844626575708389,
        0.023964203894138336,
        0.05607961118221283,
        0.026639340445399284,
        -0.0041218893602490425,
        0.04462296515703201,
        -0.02178584784269333,
        0.04363686963915825,
        -0.05887807160615921,
        -0.03718818724155426,
        0.0373578816652298,
        -0.04913093149662018,
        -0.00464607635512948,
        -0.03339570015668869,
        -0.037546880543231964,
        -0.03693375736474991,
        -0.050552066415548325,
        0.04794561490416527,
        -0.057710323482751846,
        0.057369574904441833,
        0.05881674215197563,
        0.04450245574116707,
        0.04431520029902458,
        0.02900892309844494,
        0.05780641734600067,
        -0.03940233588218689,
        -0.04632819443941116,
        0.05291095748543739,
        0.04697854071855545,
        0.012255554087460041,
        0.029935097321867943,
        0.043089188635349274,
        0.04580383002758026,
        -0.0584782212972641,
        -0.01918051391839981,
        -0.03902394324541092,
        -0.05874978005886078,
        0.05869351699948311,
        0.05856867879629135,
        -0.04557668790221214,
        -0.05888956040143967,
        -0.029042067006230354,
        -0.05824337154626846,
        0.03939265385270119,
        -0.02791154757142067,
        0.01595953293144703,
        -0.046738505363464355,
        0.03430573642253876,
        0.036761172115802765,
        -0.03551212698221207,
        -0.011877726763486862,
        0.03802938386797905,
        -0.0548216886818409,
        -0.048177570104599,
        0.05800408124923706,
        -0.014841955155134201,
        -0.05253475531935692,
        -0.004726741928607225,
        0.058883730322122574,
        -0.047044072300195694,
        0.009166806936264038,
        -0.05888966843485832,
        -0.05451647937297821,
        0.05483236536383629,
        -0.03656134754419327,
        0.012793918140232563,
        -0.01176758948713541,
        0.04193606972694397,
        -0.04630986228585243,
        -0.05885607749223709,
        -0.05181591957807541,
        -0.04432423785328865,
        -0.03888816758990288,
        -0.04977035894989967,
        0.05556143820285797,
        0.019673001021146774,
        -0.020381618291139603,
        0.05655401945114136,
        0.03276435658335686,
        -0.0540003702044487,
        0.05533730611205101,
        -0.02146085910499096,
        -0.05017177015542984,
        0.05455346032977104,
        0.027104295790195465,
        -0.057173293083906174,
        0.058127742260694504,
        0.0015892647206783295,
        -0.036729708313941956,
        -0.03274202346801758,
        -0.056897424161434174,
        -0.037868648767471313,
        -0.05733570083975792,
        0.045327700674533844,
        -0.058889441192150116,
        0.005348028615117073,
        0.05655015632510185,
        -0.03954450413584709,
        -0.0333392359316349,
        -0.05171705037355423,
        0.05270218476653099,
        0.058889612555503845,
        0.004954790696501732,
        0.009912213310599327,
        -0.04939677193760872,
        0.05500822886824608,
        0.058865759521722794,
        0.055534083396196365,
        0.029225442558526993,
        -0.05570286884903908,
        0.04540730267763138,
        0.04948825389146805,
        0.013127796351909637,
        -0.05068433657288551,
        0.05856086686253548,
        0.006924010347574949,
        -0.026646694168448448,
        0.058020275086164474,
        0.05674731358885765,
        0.013006052933633327,
        -0.04993075504899025,
        -0.02396014705300331,
        0.03683007135987282,
        0.05884571000933647,
        -0.026670152321457863,
        0.012068308889865875,
        -0.05229268968105316,
        -0.05888966843485832,
        -0.03887239098548889,
        0.04816184937953949,
        -0.030551400035619736,
        0.056592635810375214,
        -0.05654542148113251,
        -0.05663835629820824,
        0.05888965725898743,
        -0.005392740480601788,
        0.05888527259230614,
        -0.03282066434621811,
        -0.031106535345315933,
        -0.04693017899990082,
        0.020570460706949234,
        -0.046816036105155945,
        0.05823088064789772,
        -0.0545126274228096,
        0.015722420066595078,
        0.008803257718682289,
        -0.05870246887207031,
        -0.03958022594451904,
        0.027171673253178596,
        -0.05848213657736778,
        -0.007109013386070728,
        0.05787894129753113,
        -0.057975322008132935,
        0.01972353085875511,
        0.049188680946826935,
        -0.038200367242097855,
        0.049303144216537476,
        0.058290354907512665,
        0.00435554189607501,
        0.0545891635119915,
        -0.057110495865345,
        0.001398121821694076,
        0.05732657015323639,
        0.005443170201033354,
        0.04333672672510147,
        -0.02130110375583172,
        -0.032401129603385925,
        -0.056937120854854584,
        -0.05668475106358528,
        -0.03750024363398552,
        -0.05483412370085716,
        -0.03716222569346428,
        0.021790461614727974,
        0.045885100960731506,
        0.058593202382326126,
        -0.055788859724998474,
        0.05598846822977066,
        0.048858460038900375,
        -0.016402894631028175,
        0.04390086978673935,
        -0.004697953350841999,
        0.058734629303216934,
        -0.04011036828160286,
        0.018200770020484924,
        -0.03113117627799511,
        0.05263350531458855,
        0.05796509608626366,
        -0.005349540617316961,
        -0.00256938929669559,
        -0.05885864421725273,
        -0.03258221596479416,
        -0.05871450901031494,
        0.02335432916879654,
        0.039458420127630234,
        -0.04817875474691391,
        -0.027239752933382988,
        -0.013766334392130375,
        0.05728542432188988,
        -0.05876200273633003,
        0.03886905312538147,
        -0.05196483060717583,
        -0.046739332377910614,
        0.05649019405245781,
        0.028754951432347298,
        0.033130943775177,
        -0.05888958275318146,
        -0.017458487302064896,
        0.05842883139848709,
        -0.05721157044172287,
        0.05808065086603165,
        0.002562422538176179,
        0.019455306231975555,
        0.017282893881201744,
        -0.04964516684412956,
        0.05881769582629204,
        0.04727921634912491,
        -0.055743612349033356,
        0.053135860711336136,
        0.057280123233795166,
        0.02141961269080639,
        -0.039078209549188614,
        -0.05841026455163956,
        0.039768896996974945,
        -0.009822439402341843,
        -0.04448677971959114,
        0.03924306854605675,
        0.04199058935046196,
        0.05814090743660927,
        0.05463496223092079,
        -0.05849305912852287,
        -0.02131754159927368,
        0.05543822795152664,
        -0.029517384245991707,
        -0.05188506469130516,
        0.050460334867239,
        -0.006352766416966915,
        0.02431066334247589,
        -0.03554234281182289,
        0.04273148253560066,
        0.043331097811460495,
        0.048992544412612915,
        -0.028130941092967987,
        0.04660843312740326,
        0.055376581847667694,
        0.04385359585285187,
        0.05881367623806,
        -0.04912779480218887,
        -0.053156036883592606,
        0.0028340178541839123,
        0.00021532336540985852,
        -0.053299833089113235,
        -0.0583849661052227,
        0.05489760637283325,
        0.015523405745625496,
        -0.031628113240003586,
        0.023860659450292587,
        0.00279480149038136,
        0.05205262452363968,
        0.05152324587106705,
        -0.04148576036095619,
        -0.05257882922887802,
        -0.049944017082452774,
        0.05888966843485832,
        -0.01438168901950121,
        0.039764150977134705,
        0.046136580407619476,
        -0.03386888653039932,
        0.01961415633559227,
        -0.05888020992279053,
        -0.00193842314183712,
        -0.044035911560058594,
        -0.05841822922229767,
        -0.017719024792313576,
        0.05679013952612877,
        -0.02999548427760601,
        -0.015849705785512924,
        -0.05669062212109566,
        0.05503275990486145,
        0.05487948656082153,
        0.05845564603805542,
        -0.047631073743104935,
        -0.050771668553352356,
        -0.05182848125696182,
        -0.05107315629720688,
        0.05886712670326233,
        -0.05349317565560341,
        -0.004116129130125046,
        -0.05860647186636925,
        -0.04631904140114784,
        -0.058704350143671036,
        -0.015570814721286297,
        0.04548874497413635,
        0.05827286094427109,
        0.05732600763440132,
        -0.0453374870121479,
        0.03535998612642288,
        0.02060435712337494,
        -0.05722009763121605,
        0.05012688413262367,
        0.003191628260537982,
        -0.0568300224840641,
        -0.050777122378349304,
        0.034625742584466934,
        -0.04086769372224808,
        0.05856698751449585,
        -0.054191358387470245,
        -0.05803881585597992,
        -0.05644819885492325,
        -0.00105983333196491,
        0.05887880176305771,
        -0.04289746284484863,
        0.027023348957300186,
        -0.027003837749361992,
        0.05195106565952301,
        0.058426737785339355,
        -0.0530611090362072,
        -0.05628666281700134,
        -0.05876147001981735,
        -0.0570218488574028,
        0.05629761889576912,
        0.055131640285253525,
        0.05814538896083832,
        -0.050917770713567734,
        0.05869036540389061,
        0.0490339919924736,
        -0.013986649923026562,
        0.05758357420563698,
        -0.04944096505641937,
        0.017302533611655235,
        -0.047608811408281326,
        -0.058834221214056015,
        0.002064872533082962,
        0.04506509751081467,
        -0.03968379646539688,
        0.019596179947257042,
        0.02613014355301857,
        -0.04756453260779381,
        -0.05865524709224701,
        0.005810569040477276,
        -0.02620592527091503,
        -0.05888964235782623,
        -0.05753038823604584,
        0.013081070967018604,
        0.03668329864740372,
        0.00959954783320427,
        -0.024291828274726868,
        0.027455046772956848,
        -0.03240039199590683,
        0.05357511714100838,
        -0.03293738141655922,
        -0.05294923111796379,
        -0.0446854829788208,
        -0.022935152053833008,
        -0.0544445738196373,
        0.0555146299302578,
        -0.04969557747244835,
        -0.035243865102529526,
        -0.05405929684638977,
        0.032133933156728745,
        0.012643450871109962,
        0.01730671338737011,
        0.042765986174345016,
        -0.017510423436760902,
        0.04261578246951103,
        0.056274864822626114,
        -0.0433303564786911,
        0.0534101240336895,
        0.011715576983988285,
        0.0486958809196949,
        0.050926536321640015,
        0.05357923358678818,
        -0.05831870436668396,
        -0.006962775252759457,
        -0.05033941566944122,
        -0.002669324167072773,
        0.012595214881002903,
        -0.05887787789106369,
        0.05824560672044754,
        0.0010564104886725545,
        0.05092934891581535,
        -0.023211056366562843,
        -0.04778512939810753,
        0.058834802359342575,
        0.058322686702013016,
        -0.05869326367974281,
        0.05887224152684212,
        -0.04242999106645584,
        -0.056487228721380234,
        0.03023451566696167,
        0.044150177389383316,
        0.03269914537668228,
        0.05846671760082245,
        -0.02818528562784195,
        0.053146012127399445,
        0.019000180065631866,
        0.05030463635921478,
        -0.04955047741532326,
        0.02900991402566433,
        -0.057736586779356,
        -0.014322479255497456,
        -0.010673380456864834,
        0.031205352395772934,
        0.043249282985925674,
        0.04000537097454071,
        -0.043948184698820114,
        -0.010797218419611454,
        -0.05627370625734329,
        -0.02960359863936901,
        0.043343931436538696,
        -0.037398770451545715,
        0.032510463148355484,
        0.01028535608202219,
        0.015176108106970787,
        -0.03298577293753624,
        -0.058812569826841354,
        0.0468020886182785,
        0.056023407727479935,
        -0.057132843881845474,
        -0.04890679195523262,
        0.00903700664639473,
        0.028437446802854538,
        0.025470269843935966,
        0.03166759014129639,
        0.006440746132284403,
        0.05168755352497101,
        -0.002922574058175087,
        -0.003155946033075452,
        0.02624151110649109,
        0.05879437178373337,
        0.05809403583407402
    ],
    [
        -0.051720064133405685,
        0.047044772654771805,
        0.02646814100444317,
        -0.043500062078237534,
        -0.05364126339554787,
        0.03833238035440445,
        0.05303724482655525,
        -0.05180390551686287,
        0.027506165206432343,
        0.05434517562389374,
        0.054206520318984985,
        -0.04489988461136818,
        -0.05383481830358505,
        -0.00030458945548161864,
        -0.05156642943620682,
        -0.04579262435436249,
        0.026675108820199966,
        0.05288390442728996,
        0.040207572281360626,
        -0.03234220668673515,
        0.05166429653763771,
        -0.0037485475186258554,
        0.053719937801361084,
        -0.03748587146401405,
        0.026067044585943222,
        -0.05306682363152504,
        -0.05408483371138573,
        0.05019480362534523,
        -0.03752594813704491,
        -0.05424003675580025,
        0.045649342238903046,
        0.04906777665019035,
        -0.05401815474033356,
        -0.05435193330049515,
        0.011602572165429592,
        0.047291599214076996,
        -0.05352413281798363,
        -0.036332182586193085,
        0.035703565925359726,
        -0.045734234154224396,
        0.021505219861865044,
        0.037472791969776154,
        0.052475493401288986,
        0.04757801070809364,
        0.0459265410900116,
        0.04885930195450783,
        0.05139979347586632,
        0.05413958802819252,
        0.054368238896131516,
        -0.05314229056239128,
        0.04664275795221329,
        0.05227836221456528,
        0.04613358527421951,
        -0.051229313015937805,
        0.002514604711905122,
        -0.05369247868657112,
        -0.05093557760119438,
        0.05047634243965149,
        -0.05410131812095642,
        -0.01849544420838356,
        -0.05389004200696945,
        0.04676242545247078,
        -0.053930070251226425,
        -0.027528133243322372,
        0.01488567516207695,
        -0.025391031056642532,
        -0.052643317729234695,
        0.04920286685228348,
        0.01573134958744049,
        -0.052737027406692505,
        0.03720147907733917,
        -0.05433470755815506,
        0.05421925708651543,
        0.0514829196035862,
        0.0517086386680603,
        -0.022044681012630463,
        0.054347507655620575,
        0.054381657391786575,
        0.05165788531303406,
        0.04542851075530052,
        -0.037345968186855316,
        -0.037057291716337204,
        -0.05435975268483162,
        0.041252609342336655,
        -0.03507847711443901,
        0.046253643929958344,
        0.01416357234120369,
        -0.05246051028370857,
        0.03740339353680611,
        0.045875951647758484,
        -0.05435469374060631,
        -0.021617654711008072,
        0.034469302743673325,
        0.04950025677680969,
        -0.01992785558104515,
        0.005049283150583506,
        -0.026630988344550133,
        -0.05117744952440262,
        -0.05343162640929222,
        0.04562554135918617,
        -0.0543007031083107,
        0.054374124854803085,
        0.0535634309053421,
        0.036219995468854904,
        0.04340549558401108,
        -0.053046006709337234,
        -0.05395769327878952,
        0.05264120548963547,
        -0.05389942601323128,
        -0.013101997785270214,
        0.029253017157316208,
        0.05417327210307121,
        0.02120131067931652,
        -0.014623323455452919,
        0.05376476049423218,
        -0.05004701390862465,
        -0.037194110453128815,
        -0.04603492468595505,
        0.048584453761577606,
        -0.042290493845939636,
        -0.053445614874362946,
        0.029273832216858864,
        -0.05432528257369995,
        0.02114441804587841,
        -0.05263535678386688,
        0.043465010821819305,
        -0.04389352351427078,
        -0.02776610665023327,
        -0.03129469230771065,
        -0.03547083958983421,
        0.03702584281563759,
        -0.05311539024114609,
        0.052370209246873856,
        0.05436117574572563,
        -0.04570392146706581,
        -0.04252820461988449,
        0.054370030760765076,
        -0.05427749454975128,
        0.005177643150091171,
        0.04985322058200836,
        0.033870987594127655,
        -0.05437081679701805,
        0.046715542674064636,
        -0.052055273205041885,
        0.023525061085820198,
        0.05383898317813873,
        0.053957220166921616,
        0.05399863049387932,
        -0.053836990147829056,
        0.04238738492131233,
        -0.03073888272047043,
        -0.05361748859286308,
        -0.051467977464199066,
        -0.032340530306100845,
        -0.05276401713490486,
        -0.05434415116906166,
        -0.053694095462560654,
        -0.04772183299064636,
        0.05434798449277878,
        0.0065023573115468025,
        0.02909814938902855,
        -0.048635125160217285,
        0.05353841558098793,
        0.05407602712512016,
        -0.05256396904587746,
        -0.02857774868607521,
        0.053450219333171844,
        0.034628286957740784,
        0.04665417969226837,
        0.024822011590003967,
        -0.05402534082531929,
        0.05137764289975166,
        -0.0538555309176445,
        -0.03380100429058075,
        0.054078567773103714,
        -0.004080827347934246,
        0.04660622403025627,
        -0.04312466084957123,
        0.054284773766994476,
        -0.05420262739062309,
        -0.0541151687502861,
        -0.051011037081480026,
        -0.033351656049489975,
        0.04864441603422165,
        -0.05421304330229759,
        0.03575071692466736,
        0.030927183106541634,
        0.053865108639001846,
        -0.050586532801389694,
        0.028242632746696472,
        -0.053571660071611404,
        -0.03873330354690552,
        0.044004231691360474,
        0.02657328173518181,
        -0.04084309935569763,
        -0.033857040107250214,
        0.054377589374780655,
        -0.05269850790500641,
        0.04678863286972046,
        0.05351279303431511,
        0.054375410079956055,
        -0.025209642946720123,
        0.01925806514918804,
        -0.052067678421735764,
        0.034684475511312485,
        0.03239503130316734,
        -0.04572587087750435,
        0.027348805218935013,
        -0.05237981304526329,
        -0.054278746247291565,
        -0.05224677920341492,
        0.023379351943731308,
        -0.0458599328994751,
        0.05349297448992729,
        -0.04062281921505928,
        -0.0462617501616478,
        0.05433967337012291,
        -0.04058712720870972,
        0.054373618215322495,
        -0.05171068757772446,
        0.05342922732234001,
        0.053576137870550156,
        0.04632928967475891,
        -0.05402537062764168,
        0.054121725261211395,
        -0.04303683340549469,
        0.05043717846274376,
        -0.022919856011867523,
        -0.051538143306970596,
        -0.052588194608688354,
        -0.050974033772945404,
        -0.0538470521569252,
        -0.0496148020029068,
        0.047965873032808304,
        -0.049150098115205765,
        0.05434996262192726,
        0.04275015369057655,
        0.05025549978017807,
        -0.04851961508393288,
        0.05428693816065788,
        0.002924904925748706,
        -0.05432038754224777,
        -0.05274295434355736,
        -0.04764651134610176,
        0.05210644379258156,
        0.036937929689884186,
        -0.046492163091897964,
        0.010877630673348904,
        0.020259767770767212,
        -0.046438172459602356,
        -0.02778668887913227,
        -0.05367391183972359,
        -0.05097808316349983,
        -0.054012659937143326,
        0.0539986714720726,
        0.01713430881500244,
        0.054013535380363464,
        -0.052459076046943665,
        0.051500048488378525,
        -0.045124590396881104,
        -0.0533907525241375,
        0.029387667775154114,
        -0.0462665930390358,
        -0.009441823698580265,
        -0.049932483583688736,
        0.05438004806637764,
        0.05420111119747162,
        0.002328003291040659,
        -0.008821734227240086,
        0.036980919539928436,
        -0.05142028629779816,
        -0.030170230194926262,
        0.037589654326438904,
        -0.05422623082995415,
        -0.04440181329846382,
        0.007862786762416363,
        -0.048200082033872604,
        0.04545021057128906,
        0.05423809960484505,
        -0.019143883138895035,
        -0.05349140986800194,
        0.05157104879617691,
        -0.049478091299533844,
        -0.03949005529284477,
        -0.03512260317802429,
        0.037845779210329056,
        -0.003878928953781724,
        0.04111657291650772,
        -0.05372459813952446,
        0.05344236269593239,
        -0.05166609585285187,
        0.029449688270688057,
        -0.046291619539260864,
        -0.052912525832653046,
        0.05229070037603378,
        -0.05369383469223976,
        0.05284848436713219,
        -0.04111828655004501,
        -0.054120343178510666,
        0.05259047448635101,
        -0.017194725573062897,
        0.047625236213207245,
        0.018948456272482872,
        0.051020361483097076,
        0.05253535509109497,
        -0.026356270536780357,
        0.04063776880502701,
        0.050367098301649094,
        0.012484533712267876,
        -0.0005633460823446512,
        0.011208595708012581,
        -0.03908525034785271,
        -0.007239135447889566,
        0.05040980502963066,
        0.022649934515357018,
        -0.05415603145956993,
        -0.033095669001340866,
        0.05422670394182205,
        0.032391998916864395,
        0.01628020778298378,
        0.015840237960219383,
        0.03438251093029976,
        -0.009009616449475288,
        0.01479431614279747,
        0.04421546310186386,
        0.05376458913087845,
        0.04758993908762932,
        -0.023415571078658104,
        -0.036300867795944214,
        -0.05393717810511589,
        0.048799771815538406,
        -0.04682445526123047,
        -0.05130806937813759,
        -0.054048098623752594,
        0.03600969538092613,
        -0.004468150436878204,
        0.026008622720837593,
        0.052613407373428345,
        0.037712931632995605,
        0.0018485626205801964,
        -0.04328307881951332,
        -0.05265757068991661,
        -0.01704142987728119,
        -0.05296328663825989,
        0.049586404114961624,
        0.02950570359826088,
        0.05415864288806915,
        0.052605755627155304,
        -0.046265799552202225,
        0.04444921389222145,
        0.05433657020330429,
        -0.04580158740282059,
        0.053136494010686874,
        -0.033267781138420105,
        -0.0543244332075119,
        0.025988616049289703,
        -0.05221771448850632,
        -0.053755540400743484,
        -0.04728321731090546,
        0.02818533405661583,
        0.039709076285362244,
        0.029669202864170074,
        0.0496920645236969,
        -0.053941357880830765,
        0.011631317436695099,
        -0.05428912490606308,
        0.05301529914140701,
        -0.029270555824041367,
        -0.03357207775115967,
        -0.05210704356431961,
        -0.011491973884403706,
        -0.054206036031246185,
        0.038230545818805695,
        -0.01138375699520111,
        0.02701558731496334,
        0.05437510833144188,
        -0.00007711361104156822,
        0.012203453108668327,
        -0.031135721132159233,
        -0.0011024449486285448,
        -0.046120647341012955,
        0.05002674460411072,
        -0.0317445807158947,
        0.021914269775152206,
        0.05437431111931801,
        -0.05368691310286522,
        -0.016470322385430336,
        -0.03603363037109375,
        -0.04139008745551109,
        -0.05338403955101967,
        -0.05279140919446945,
        0.05433974415063858,
        -0.024081392213702202,
        -0.040045659989118576,
        0.04969002678990364,
        0.05195553973317146,
        0.05355958640575409,
        0.05069919675588608,
        -0.05043235048651695,
        -0.05260381102561951,
        -0.042189422994852066,
        0.053844280540943146,
        -0.01135054137557745,
        0.002074460033327341,
        -0.05034610629081726,
        -0.005615456495434046,
        0.0539114810526371,
        -0.050197381526231766,
        0.053947288542985916,
        -0.04310433194041252,
        -0.026728888973593712,
        -0.03562916815280914,
        -0.0541989728808403,
        0.05433756485581398,
        0.05335270240902901,
        -0.043196432292461395,
        0.05118440464138985,
        0.05435122549533844,
        -0.008581544272601604,
        0.051479753106832504,
        0.0467664897441864,
        -0.05432218685746193,
        -0.05435071140527725,
        -0.05434553697705269,
        0.00024799926904961467,
        0.05280359089374542,
        -0.047937534749507904,
        -0.051438022404909134,
        -0.05210078880190849,
        -0.05399526283144951,
        0.05405651777982712,
        0.01851988583803177,
        0.04953831806778908,
        0.005547688342630863,
        -0.035558391362428665,
        -0.05111585184931755,
        -0.05422268807888031,
        -0.01415701862424612,
        -0.053090695291757584,
        -0.050759635865688324,
        -0.04687898978590965,
        0.008117024786770344,
        0.04167312756180763,
        0.0541849285364151,
        -0.054160166531801224,
        -0.011117312125861645,
        0.05288877710700035,
        -0.0339033380150795,
        0.036703843623399734,
        0.05382133647799492,
        0.04556572437286377,
        0.032044194638729095,
        0.00010112013842444867,
        -0.009295633994042873,
        0.015007039532065392,
        -0.05221378803253174,
        -0.048453427851200104,
        -0.05367857590317726,
        -0.0069505879655480385,
        0.02527489699423313,
        -0.041846223175525665,
        -0.05329648032784462,
        0.05405225232243538,
        -0.040862757712602615,
        0.054096631705760956,
        0.054204318672418594,
        -0.025265280157327652,
        0.05426391586661339,
        -0.031987495720386505,
        -0.05358724296092987,
        0.047594089061021805,
        0.04693657532334328,
        0.05057065933942795,
        0.04985983297228813,
        -0.015574630349874496,
        -0.01358985248953104,
        -0.04132544621825218,
        -0.03093608096241951,
        0.037256281822919846,
        0.04209189862012863,
        0.052366118878126144,
        -0.0436808206140995,
        -0.046162087470293045,
        0.028823472559452057,
        -0.0508791022002697,
        0.05288191884756088,
        0.05075128376483917,
        0.05341657996177673,
        -0.05240095406770706,
        -0.05403169244527817,
        -0.049023985862731934,
        0.02615906111896038,
        0.05034127086400986,
        0.044680431485176086,
        -0.05371297150850296,
        0.05415784940123558,
        -0.05407485365867615,
        -0.012085369788110256,
        0.04455699399113655,
        0.038794077932834625,
        0.05428179353475571,
        0.04792199656367302,
        0.02533271349966526,
        -0.012075108475983143,
        0.054330065846443176,
        0.054291337728500366,
        0.053310833871364594,
        -0.048612598329782486,
        0.04661901295185089,
        0.05253905430436134,
        0.05420469492673874,
        -0.03306388482451439
    ],
    [
        -0.05802541971206665,
        -0.0457608737051487,
        -0.014783998019993305,
        -0.0015762640396133065,
        0.029393769800662994,
        -0.03683018311858177,
        0.05841727927327156,
        -0.03768724948167801,
        0.04679044708609581,
        0.048492398113012314,
        0.044470321387052536,
        0.05702640861272812,
        -0.05461864545941353,
        0.048383794724941254,
        -0.05787449702620506,
        0.0486467070877552,
        -0.05516747385263443,
        0.05350238457322121,
        -0.058298010379076004,
        0.05528927221894264,
        -0.04891546815633774,
        -0.043846845626831055,
        0.05593223124742508,
        -0.017697008326649666,
        0.05177982896566391,
        0.03792719170451164,
        0.05229749158024788,
        0.052927806973457336,
        -0.0457342192530632,
        -0.056405626237392426,
        0.053750794380903244,
        -0.019103938713669777,
        0.042544182389974594,
        -0.05824429914355278,
        0.006497158668935299,
        -0.0373954102396965,
        -0.05120689794421196,
        -0.036864519119262695,
        -0.058510247617959976,
        0.007010811474174261,
        -0.021353593096137047,
        0.0001975140330614522,
        0.051639072597026825,
        -0.05802706256508827,
        0.05647988244891167,
        0.016754113137722015,
        0.05440434813499451,
        0.05588185042142868,
        0.05815079063177109,
        0.0029562250711023808,
        0.05524306371808052,
        0.018429778516292572,
        0.04139316827058792,
        0.02010783925652504,
        0.014480074867606163,
        -0.04782123118638992,
        -0.05801910534501076,
        -0.01965538039803505,
        -0.03857437148690224,
        0.004569895099848509,
        -0.044827900826931,
        -0.05044589191675186,
        -0.05329756438732147,
        0.019479507580399513,
        -0.003313518362119794,
        0.016857044771313667,
        0.05232732743024826,
        -0.04881513863801956,
        0.0005469468887895346,
        -0.05850733071565628,
        -0.056242335587739944,
        -0.05823691561818123,
        0.033809490501880646,
        -0.04364735260605812,
        -0.02248336561024189,
        -0.007720216177403927,
        0.056509360671043396,
        0.05743956193327904,
        0.05377008020877838,
        -0.0402485691010952,
        -0.031044907867908478,
        0.01640690118074417,
        -0.04125447943806648,
        0.04610174521803856,
        -0.02617882564663887,
        0.05410713329911232,
        -0.05604899302124977,
        0.029248353093862534,
        0.05743546783924103,
        0.05781140178442001,
        -0.006437177304178476,
        0.0450407937169075,
        0.038658928126096725,
        -0.05464755743741989,
        -0.05199718102812767,
        0.043994735926389694,
        -0.022527318447828293,
        -0.046632733196020126,
        0.019796840846538544,
        -0.006841245573014021,
        -0.05799791216850281,
        -0.036584846675395966,
        0.05220339074730873,
        0.05519339069724083,
        0.043850839138031006,
        -0.02326471358537674,
        -0.036369677633047104,
        0.04780213162302971,
        -0.05632829666137695,
        0.02791690267622471,
        -0.05462361127138138,
        -0.03992266207933426,
        -0.01023659948259592,
        0.03289557620882988,
        0.056334249675273895,
        -0.03506699949502945,
        -0.05612194910645485,
        -0.05157070234417915,
        -0.022559238597750664,
        0.024805519729852676,
        0.005308629013597965,
        0.050869159400463104,
        -0.05838417261838913,
        0.04804295301437378,
        -0.05727887526154518,
        -0.0005516320234164596,
        -0.03134370967745781,
        0.017779020592570305,
        0.05691602826118469,
        0.03482764586806297,
        0.05590355396270752,
        -0.034647196531295776,
        -0.049831684678792953,
        0.035537250339984894,
        -0.05500192567706108,
        -0.05312357470393181,
        0.056511230766773224,
        -0.055722761899232864,
        -0.04271986335515976,
        0.04791191220283508,
        0.05828726664185524,
        0.04124576598405838,
        0.05399715155363083,
        0.007533347699791193,
        -0.028349414467811584,
        0.05367252230644226,
        0.007755724247545004,
        0.01028151623904705,
        -0.03963794559240341,
        0.015963193029165268,
        -0.05134332925081253,
        -0.058272428810596466,
        -0.03840477019548416,
        -0.031286321580410004,
        0.04475166276097298,
        -0.05750312656164169,
        0.05846253037452698,
        0.0009299038792960346,
        0.010755039751529694,
        0.04407778009772301,
        0.03589000552892685,
        0.005215379409492016,
        0.05091289430856705,
        -0.03898412734270096,
        -0.05358729511499405,
        -0.046598631888628006,
        -0.041401877999305725,
        -0.04286440834403038,
        0.016223512589931488,
        0.04711201414465904,
        -0.04918495565652847,
        0.052366700023412704,
        -0.04514253884553909,
        0.05618668347597122,
        -0.04276818409562111,
        0.04691244289278984,
        -0.058510806411504745,
        0.043889231979846954,
        0.05471363291144371,
        -0.03247867897152901,
        -0.020808475092053413,
        -0.04607514292001724,
        0.05187381058931351,
        0.05132720619440079,
        -0.04173502326011658,
        0.03433806076645851,
        -0.03625722602009773,
        0.0545787438750267,
        0.002998653333634138,
        -0.05779847130179405,
        0.028795145452022552,
        -0.027950230985879898,
        -0.05779704451560974,
        0.02671079710125923,
        -0.02389351651072502,
        0.017112713307142258,
        0.05146113038063049,
        -0.04472643882036209,
        -0.021177643910050392,
        0.03260931000113487,
        0.039088815450668335,
        -0.025889450684189796,
        0.052641887217760086,
        0.05570397898554802,
        0.039380092173814774,
        0.050356727093458176,
        0.029827089980244637,
        0.03267969191074371,
        -0.056172892451286316,
        -0.05830046907067299,
        -0.054808564484119415,
        0.05791575089097023,
        -0.03110857680439949,
        0.0015804958529770374,
        0.055381350219249725,
        0.03478117659687996,
        0.05848665162920952,
        0.03657432273030281,
        0.05850132554769516,
        -0.041500601917505264,
        0.03416476398706436,
        0.03394303470849991,
        -0.05665409192442894,
        -0.05848005414009094,
        0.053180158138275146,
        -0.058472782373428345,
        -0.01557187456637621,
        0.05842724069952965,
        -0.043473441153764725,
        -0.047605305910110474,
        0.029403632506728172,
        -0.05252813920378685,
        -0.04289516061544418,
        0.04585591331124306,
        -0.007326229941099882,
        0.0027781897224485874,
        0.056195296347141266,
        0.019374709576368332,
        -0.014065469615161419,
        0.053716883063316345,
        0.031699039041996,
        0.024765487760305405,
        0.003898185444995761,
        0.04883695766329765,
        -0.010128876194357872,
        -0.010773292742669582,
        -0.015124917030334473,
        -0.00482132425531745,
        -0.0153751689940691,
        -0.027499116957187653,
        -0.03315608203411102,
        -0.052309293299913406,
        -0.02910485304892063,
        -0.05394900590181351,
        0.022328412160277367,
        0.05826988443732262,
        -0.04870208725333214,
        -0.040534891188144684,
        0.05782826989889145,
        0.05699213966727257,
        0.027656208723783493,
        0.043017443269491196,
        0.040764473378658295,
        0.056535687297582626,
        -0.056065190583467484,
        0.05520825833082199,
        -0.05101858824491501,
        -0.030280962586402893,
        -0.053673580288887024,
        -0.05230681598186493,
        -0.008938057348132133,
        -0.05675651133060455,
        0.03549015894532204,
        -0.05658489093184471,
        -0.05707091465592384,
        0.05637318640947342,
        -0.05798450857400894,
        0.025737125426530838,
        -0.002770962193608284,
        -0.0517180971801281,
        -0.053807005286216736,
        -0.057467203587293625,
        0.0029876958578824997,
        0.048422928899526596,
        -0.05463944375514984,
        -0.05708778277039528,
        0.021054113283753395,
        -0.05849326029419899,
        -0.04552575573325157,
        0.03373396396636963,
        -0.05229485034942627,
        0.032130420207977295,
        -0.04228636622428894,
        -0.01332111842930317,
        0.03723784536123276,
        -0.05193861946463585,
        0.056901343166828156,
        0.02386363036930561,
        -0.05485774949193001,
        0.04819319024682045,
        -0.042623672634363174,
        0.0497693195939064,
        0.006553263869136572,
        0.025233706459403038,
        -0.006024734582751989,
        -0.04558427631855011,
        -0.05371306091547012,
        0.05308223515748978,
        0.05792997404932976,
        -0.05824226140975952,
        0.03185388445854187,
        0.029563097283244133,
        0.006077385041862726,
        0.05701303109526634,
        -0.0577613040804863,
        -0.046374496072530746,
        0.053098827600479126,
        -0.0549846775829792,
        0.03913501650094986,
        -0.05439043045043945,
        -0.05793122947216034,
        0.02070518769323826,
        0.05714472383260727,
        -0.0021801439579576254,
        0.0482097826898098,
        0.051136717200279236,
        0.053302276879549026,
        0.04342556744813919,
        0.0392429381608963,
        -0.029092419892549515,
        -0.04397692158818245,
        0.03006664291024208,
        0.03317471221089363,
        -0.054453056305646896,
        0.0571465864777565,
        -0.042572587728500366,
        -0.04957690089941025,
        0.049697767943143845,
        -0.05606916919350624,
        0.05454714968800545,
        0.03371361643075943,
        0.026650886982679367,
        -0.049804430454969406,
        -0.052823204547166824,
        0.05851138010621071,
        0.04738861322402954,
        0.027961190789937973,
        0.051541414111852646,
        0.03418295830488205,
        0.05493234470486641,
        -0.05829614773392677,
        0.04207592457532883,
        -0.04825431853532791,
        -0.057651013135910034,
        -0.05705195292830467,
        0.047233011573553085,
        -0.05761319771409035,
        -0.05023128166794777,
        -0.051294997334480286,
        -0.018682174384593964,
        0.05410483852028847,
        0.01061275228857994,
        -0.05800269544124603,
        -0.052783429622650146,
        0.05674879625439644,
        -0.05078217387199402,
        0.05818600207567215,
        -0.0032641328871250153,
        0.055989887565374374,
        -0.05846169590950012,
        -0.033291496336460114,
        -0.05579151585698128,
        0.05746025964617729,
        0.023858916014432907,
        0.056658726185560226,
        0.058450035750865936,
        -0.002936809789389372,
        0.030789963901042938,
        -0.011542432941496372,
        -0.054064977914094925,
        -0.05063176900148392,
        0.05554370582103729,
        -0.0562446154654026,
        0.016205230727791786,
        0.04698052257299423,
        -0.052034150809049606,
        0.04975122585892677,
        -0.05510512366890907,
        -0.05120551213622093,
        -0.047776393592357635,
        -0.05392486974596977,
        0.030752915889024734,
        0.029924727976322174,
        0.043677814304828644,
        -0.04023270308971405,
        0.05563453584909439,
        0.05770375579595566,
        0.0338168665766716,
        -0.029954547062516212,
        -0.05771857872605324,
        -0.041038013994693756,
        0.04678977653384209,
        0.058271873742341995,
        0.05768100544810295,
        -0.0532328262925148,
        0.044482480734586716,
        0.057628098875284195,
        0.015314065851271152,
        0.05730968713760376,
        -0.050593066960573196,
        0.055233150720596313,
        -0.015008027665317059,
        -0.016722211614251137,
        0.02583196945488453,
        0.04474496468901634,
        0.04704878851771355,
        0.027939284220337868,
        0.01813798025250435,
        0.02079726941883564,
        -0.005775247234851122,
        -0.04356703162193298,
        -0.0562620647251606,
        -0.05746673792600632,
        -0.05799517780542374,
        0.05256156995892525,
        -0.0009762915433384478,
        -0.05449584126472473,
        -0.020976977422833443,
        -0.05698822811245918,
        -0.03852979093790054,
        0.048447154462337494,
        0.04250364378094673,
        -0.03547779843211174,
        -0.034513313323259354,
        0.013649920001626015,
        -0.03385230526328087,
        -0.05757218599319458,
        0.05581893026828766,
        0.008742019534111023,
        -0.05080123245716095,
        0.04808808118104935,
        0.00960985291749239,
        0.0027600876055657864,
        0.05159231275320053,
        0.05420028045773506,
        -0.02734495885670185,
        0.05777798593044281,
        -0.057735610753297806,
        0.056394606828689575,
        -0.05485646426677704,
        -0.041506536304950714,
        0.05498530715703964,
        0.05310911685228348,
        -0.004464959725737572,
        -0.05030170455574989,
        -0.05240417644381523,
        0.03575887531042099,
        -0.0008634902769699693,
        -0.058091502636671066,
        0.05526088550686836,
        0.02375391125679016,
        -0.04297029972076416,
        -0.057872675359249115,
        -0.00872831977903843,
        0.044883180409669876,
        0.057899489998817444,
        -0.05760445073246956,
        0.057541683316230774,
        -0.04722575098276138,
        -0.05643394961953163,
        0.05656641349196434,
        0.04720214381814003,
        0.037691984325647354,
        -0.02144121192395687,
        0.05401672050356865,
        0.052123911678791046,
        0.018076470121741295,
        0.03275953605771065,
        -0.04388086125254631,
        -0.049047380685806274,
        0.040404632687568665,
        -0.03716237470507622,
        0.056653715670108795,
        0.03733348846435547,
        0.03371339663863182,
        0.04711827635765076,
        0.04440200701355934,
        -0.012922262772917747,
        -0.05456695705652237,
        0.016980839893221855,
        -0.05779415741562843,
        -0.05438145622611046,
        0.05772845074534416,
        -0.004191894084215164,
        0.021741528064012527,
        0.03076023980975151,
        -0.054591745138168335,
        -0.055069658905267715,
        0.0543772429227829,
        0.04161730781197548,
        0.048938412219285965,
        -0.05012728273868561,
        0.030591780319809914,
        -0.007471149787306786,
        -0.03959310054779053,
        -0.026591850444674492,
        -0.057246848940849304,
        -0.0071396417915821075,
        -0.05559424310922623,
        0.01849391497671604,
        0.05718205124139786,
        0.057934120297431946
    ],
    [
        0.0467543788254261,
        0.017650894820690155,
        -0.03800287842750549,
        -0.045095209032297134,
        0.029458077624440193,
        0.008757242001593113,
        0.05330899357795715,
        -0.05520610138773918,
        0.04312978312373161,
        0.05046820640563965,
        0.05114243924617767,
        0.050042420625686646,
        -0.05098345875740051,
        -0.0300757996737957,
        -0.05571919307112694,
        -0.055690947920084,
        0.05377734452486038,
        0.055110204964876175,
        0.05486893281340599,
        -0.0437498614192009,
        -0.016386546194553375,
        0.05516922473907471,
        0.05571529269218445,
        -0.03831222280859947,
        -0.042704422026872635,
        0.02465527318418026,
        -0.01158774271607399,
        0.055653370916843414,
        -0.05070527270436287,
        -0.0353303886950016,
        0.04190167412161827,
        -0.050978999584913254,
        0.041833844035863876,
        -0.05326635763049126,
        0.052152521908283234,
        -0.020766068249940872,
        -0.05452976003289223,
        -0.04891739413142204,
        0.05184996873140335,
        -0.0009436103282496333,
        0.05308365821838379,
        0.05511287972331047,
        0.05354255437850952,
        0.041401639580726624,
        -0.044862471520900726,
        -0.03113003820180893,
        0.04910484701395035,
        0.05559523031115532,
        0.05499861016869545,
        -0.055330146104097366,
        0.05424843356013298,
        -0.013734152540564537,
        -0.020279765129089355,
        0.03218196704983711,
        0.016827547922730446,
        0.05402688309550285,
        -0.055602509528398514,
        0.054324887692928314,
        -0.020221732556819916,
        -0.04600382223725319,
        -0.05497589334845543,
        0.05204922705888748,
        -0.05371997132897377,
        -0.049891434609889984,
        -0.038919899612665176,
        -0.05130135640501976,
        -0.054645270109176636,
        -0.05476079881191254,
        -0.04166245833039284,
        0.027054941281676292,
        0.05133888125419617,
        -0.05440492928028107,
        0.05390549823641777,
        0.05480676144361496,
        0.05322379618883133,
        0.008729439228773117,
        0.0557270348072052,
        0.05573810264468193,
        0.035552978515625,
        0.054978400468826294,
        -0.05290606990456581,
        -0.04725708067417145,
        -0.05573039874434471,
        0.03501119464635849,
        -0.02503347583115101,
        -0.04674789309501648,
        -0.05502384901046753,
        -0.02191009931266308,
        -0.04579286277294159,
        0.022234749048948288,
        -0.05569826066493988,
        -0.048914313316345215,
        0.0395747534930706,
        -0.03942204266786575,
        -0.019060293212532997,
        0.047262419015169144,
        -0.013736363500356674,
        -0.03047937899827957,
        0.045741431415081024,
        0.052854835987091064,
        -0.05558130145072937,
        0.05531099811196327,
        0.03554624691605568,
        0.05366235598921776,
        -0.025803644210100174,
        0.05256468802690506,
        0.0022555612958967686,
        0.054740242660045624,
        -0.03227846324443817,
        -0.04219327121973038,
        -0.05572109669446945,
        -0.05364818871021271,
        -0.04577542841434479,
        0.042476680129766464,
        0.03235926106572151,
        -0.053504664450883865,
        -0.05463380366563797,
        -0.05324229598045349,
        0.020153429359197617,
        -0.03117842599749565,
        -0.04985532909631729,
        0.05249249190092087,
        -0.0486053042113781,
        0.029164131730794907,
        -0.055442918092012405,
        -0.05254599452018738,
        -0.054724108427762985,
        -0.04377054423093796,
        0.05565892904996872,
        -0.04868554323911667,
        -0.04455666244029999,
        0.012074094265699387,
        0.05302157625555992,
        0.05551943555474281,
        -0.03688250109553337,
        -0.051132604479789734,
        0.017784029245376587,
        0.0460897833108902,
        -0.05392051860690117,
        0.05243285372853279,
        -0.05532703921198845,
        -0.0470837727189064,
        0.052963536232709885,
        -0.05477358028292656,
        0.010129004716873169,
        0.041803061962127686,
        -0.035122375935316086,
        0.00466668838635087,
        -0.04967109486460686,
        -0.016198275610804558,
        0.03775652498006821,
        -0.05310087278485298,
        0.03860647603869438,
        -0.05415996164083481,
        -0.010488534346222878,
        -0.055731672793626785,
        -0.047318775206804276,
        -0.05534135550260544,
        0.03400980681180954,
        0.0291107390075922,
        -0.05462593957781792,
        0.053478844463825226,
        0.05216724053025246,
        0.05476371943950653,
        -0.0222649984061718,
        -0.05040043964982033,
        0.04703958332538605,
        -0.054764412343502045,
        0.05215239152312279,
        -0.05500628799200058,
        -0.05545477196574211,
        -0.03583233058452606,
        -0.05569986626505852,
        0.03311777114868164,
        0.05159357935190201,
        0.02733389288187027,
        0.04489470645785332,
        0.009885288774967194,
        0.05564209446310997,
        -0.05406820774078369,
        -0.04304009675979614,
        0.03928360715508461,
        -0.044827964156866074,
        0.022716568782925606,
        -0.05298254266381264,
        0.053947921842336655,
        0.05264369398355484,
        0.05507101118564606,
        0.001004814519546926,
        -0.05294016748666763,
        -0.055410973727703094,
        -0.054263196885585785,
        0.05484028905630112,
        -0.04563306272029877,
        -0.011991987004876137,
        -0.05494014173746109,
        0.05542454868555069,
        -0.05411753058433533,
        0.008801333606243134,
        0.05571327358484268,
        0.04795853793621063,
        -0.05566806346178055,
        0.05520639568567276,
        -0.055493880063295364,
        0.051706574857234955,
        -0.05209061875939369,
        -0.04704710841178894,
        0.0354672372341156,
        -0.0556023083627224,
        -0.04612353444099426,
        -0.055625639855861664,
        0.02042965590953827,
        0.04792037978768349,
        0.05564385652542114,
        -0.054420989006757736,
        0.03971768543124199,
        0.052121564745903015,
        0.006609010510146618,
        0.05471307784318924,
        0.052675511687994,
        0.04802129790186882,
        0.05295582860708237,
        -0.053968317806720734,
        -0.05382890626788139,
        0.05544073507189751,
        0.01503721997141838,
        0.05124380812048912,
        -0.05458984524011612,
        -0.0387590266764164,
        -0.04662160947918892,
        -0.05523495376110077,
        -0.05382828786969185,
        0.05533423274755478,
        -0.0192769356071949,
        -0.05400647968053818,
        0.05572326108813286,
        0.052918218076229095,
        0.04264280945062637,
        0.053043805062770844,
        0.055739350616931915,
        -0.03858192265033722,
        0.03341211378574371,
        -0.054161712527275085,
        0.012597979977726936,
        -0.01964437961578369,
        -0.0402941070497036,
        -0.05532624199986458,
        0.001591720967553556,
        0.05502820014953613,
        -0.05403001606464386,
        -0.02740875445306301,
        -0.05535436421632767,
        0.04362912103533745,
        -0.04274272546172142,
        0.049836598336696625,
        0.045845843851566315,
        0.012262447737157345,
        0.027305660769343376,
        0.047272179275751114,
        -0.012793028727173805,
        -0.014659363776445389,
        -0.05390295013785362,
        -0.05512910336256027,
        0.055507417768239975,
        -0.011982576921582222,
        0.05555465817451477,
        0.05426039919257164,
        0.05072909966111183,
        0.05530747026205063,
        0.0011384274112060666,
        0.014991230331361294,
        0.004945416934788227,
        0.008933264762163162,
        -0.055189311504364014,
        0.03617989271879196,
        0.033093445003032684,
        -0.039996612817049026,
        -0.029386376962065697,
        0.052878670394420624,
        0.006949111819267273,
        -0.0446331687271595,
        0.0556408055126667,
        -0.01225144974887371,
        0.05274464562535286,
        0.04977370426058769,
        0.041112516075372696,
        -0.04289532080292702,
        0.030559606850147247,
        -0.05551788955926895,
        0.033628229051828384,
        -0.05569872632622719,
        0.039210423827171326,
        0.05525877699255943,
        -0.019157400354743004,
        0.05573451891541481,
        -0.05265749990940094,
        -0.00652462849393487,
        0.04296967387199402,
        -0.016860246658325195,
        0.03603607043623924,
        -0.01833236590027809,
        -0.011767140589654446,
        0.022142773494124413,
        0.02616942673921585,
        -0.049609921872615814,
        -0.04786999523639679,
        0.04972393438220024,
        0.05352509766817093,
        0.009276133961975574,
        0.05375678092241287,
        0.05565623566508293,
        0.035067345947027206,
        -0.008244656957685947,
        0.0020251600071787834,
        0.025353292003273964,
        -0.055604685097932816,
        -0.015105338767170906,
        0.05573805421590805,
        0.012628958560526371,
        0.005476117599755526,
        -0.0551646463572979,
        0.023464402183890343,
        0.008825374767184258,
        0.03619801253080368,
        0.054462987929582596,
        -0.02649107575416565,
        -0.052444156259298325,
        -0.05378512293100357,
        0.027414683252573013,
        -0.05464598536491394,
        0.03417828306555748,
        -0.034171849489212036,
        -0.024027423933148384,
        -0.04531187564134598,
        0.0068407184444367886,
        0.052030786871910095,
        -0.052128009498119354,
        0.0073693725280463696,
        0.03401878848671913,
        0.04703378677368164,
        -0.051057230681180954,
        -0.04818245768547058,
        -0.013150912709534168,
        -0.051921527832746506,
        0.003705504583194852,
        0.05333128198981285,
        0.04989217221736908,
        0.05477634817361832,
        -0.03685206547379494,
        -0.013781885616481304,
        0.055699098855257034,
        -0.04843540117144585,
        0.049584705382585526,
        -0.05504296347498894,
        -0.049959078431129456,
        -0.005723071284592152,
        0.028559602797031403,
        -0.05468691512942314,
        -0.052938126027584076,
        -0.04010868817567825,
        0.04561888054013252,
        0.04619660973548889,
        -0.035933967679739,
        -0.0548836849629879,
        0.041648704558610916,
        -0.04843173548579216,
        0.055611588060855865,
        -0.03812841325998306,
        0.04470071941614151,
        -0.054438021034002304,
        -0.040561266243457794,
        -0.05572357773780823,
        -0.04851905629038811,
        0.05496034026145935,
        0.05292138457298279,
        0.05507735535502434,
        0.049508508294820786,
        -0.0520712286233902,
        0.03946477919816971,
        -0.004034419544041157,
        -0.04926437512040138,
        0.052275631576776505,
        -0.024705765768885612,
        0.02391117811203003,
        0.03500005975365639,
        -0.022078484296798706,
        0.009276442229747772,
        -0.0014537040842697024,
        -0.051833413541316986,
        -0.04909691959619522,
        -0.05568544566631317,
        0.0549788735806942,
        -0.05573533847928047,
        -0.04249103367328644,
        0.0011794065358117223,
        0.04981628432869911,
        -0.020763715729117393,
        -0.03699015453457832,
        -0.020995810627937317,
        -0.055182430893182755,
        -0.02839469164609909,
        0.05569244548678398,
        0.042707305401563644,
        0.0158050749450922,
        -0.054517101496458054,
        0.04938103258609772,
        0.05527220293879509,
        -0.000675637274980545,
        0.05447383597493172,
        -0.041058529168367386,
        -0.0063102589920163155,
        -0.005571762565523386,
        -0.03976701572537422,
        -0.0299745611846447,
        0.045515142381191254,
        -0.05499924719333649,
        -0.03516709432005882,
        0.050063587725162506,
        0.04349057376384735,
        0.04513918608427048,
        -0.028890961781144142,
        -0.052065908908843994,
        -0.05539167299866676,
        -0.055426351726055145,
        -0.03389349207282066,
        -0.04728040471673012,
        0.0026964377611875534,
        0.04163544625043869,
        -0.041489649564027786,
        -0.05556737259030342,
        0.055519215762615204,
        -0.0021044728346168995,
        -0.005383588839322329,
        -0.04711147025227547,
        0.05260038003325462,
        -0.053186021745204926,
        -0.05338311195373535,
        -0.0022787528578191996,
        -0.01096791960299015,
        -0.05057714134454727,
        0.05469786375761032,
        -0.036559876054525375,
        0.05420518293976784,
        -0.0038314168341457844,
        -0.006916222162544727,
        0.019972292706370354,
        0.052227482199668884,
        -0.0548122301697731,
        -0.014737649820744991,
        0.053750179708004,
        -0.04292261600494385,
        0.05566850304603577,
        0.0548337958753109,
        -0.04437253996729851,
        0.01992863044142723,
        0.02759614586830139,
        -0.0543256551027298,
        0.02792346104979515,
        0.050420720130205154,
        -0.03717123344540596,
        0.04134586825966835,
        -0.05365915969014168,
        0.055301398038864136,
        -0.04291648790240288,
        0.053899794816970825,
        0.055693961679935455,
        -0.0546567365527153,
        0.055622562766075134,
        0.04809150844812393,
        -0.05570470169186592,
        0.04906151071190834,
        0.054262951016426086,
        0.0007793499971739948,
        0.054513245820999146,
        -0.05088677629828453,
        0.010188572108745575,
        0.05408592149615288,
        0.014055391773581505,
        -0.006768021732568741,
        -0.0008812763262540102,
        0.0387062169611454,
        -0.05060714855790138,
        0.043874043971300125,
        -0.016312018036842346,
        -0.0010710929054766893,
        -0.04508298635482788,
        0.053669679909944534,
        0.04805669188499451,
        -0.0534324087202549,
        -0.05495993420481682,
        -0.00024808142916299403,
        0.010738960467278957,
        0.05272092670202255,
        -0.00840088166296482,
        -0.05093929171562195,
        0.0537211075425148,
        -0.05496136471629143,
        0.046788379549980164,
        0.05257881432771683,
        0.04693304002285004,
        0.03770159184932709,
        0.04924424737691879,
        0.04673030599951744,
        -0.05562763661146164,
        0.045570243149995804,
        0.05564209073781967,
        0.05510376766324043,
        0.036834750324487686,
        0.054860495030879974,
        -0.05417199432849884,
        0.03034079261124134,
        -0.02181701734662056
    ],
    [
        -0.0467974953353405,
        -0.0524228997528553,
        0.028988538309931755,
        0.009231306612491608,
        0.04270518943667412,
        -0.05284915864467621,
        0.059012822806835175,
        -0.04880455881357193,
        0.04770684242248535,
        0.03992798179388046,
        0.057043351233005524,
        0.03964131698012352,
        -0.050446540117263794,
        -0.04523679241538048,
        -0.05343380942940712,
        0.03451646491885185,
        -0.04188496619462967,
        0.057181958109140396,
        -0.05887370556592941,
        0.056150905787944794,
        -0.0317692905664444,
        -0.04776705428957939,
        0.057794392108917236,
        -0.05177144706249237,
        0.0007130226003937423,
        0.006601566914469004,
        -0.007490760181099176,
        0.05312531813979149,
        -0.045159146189689636,
        -0.05482546612620354,
        0.00249449978582561,
        -0.019200706854462624,
        0.05013406649231911,
        -0.05839481204748154,
        -0.00009341290569864213,
        -0.05417301878333092,
        -0.05696869641542435,
        -0.04312428459525108,
        -0.05903335288167,
        0.04249821975827217,
        -0.02043278142809868,
        0.04859460890293121,
        0.04199398308992386,
        -0.0583687350153923,
        0.05810662731528282,
        0.02607058919966221,
        0.059040095657110214,
        0.05701209604740143,
        0.05183788761496544,
        -0.05903230234980583,
        0.05039509758353233,
        -0.057314787060022354,
        0.02305896021425724,
        -0.04430767148733139,
        -0.044682394713163376,
        -0.018222838640213013,
        -0.05221820995211601,
        0.02657078392803669,
        -0.05746438726782799,
        0.02746085450053215,
        -0.055726952850818634,
        0.04696658253669739,
        -0.055039919912815094,
        0.019061710685491562,
        -0.036760080605745316,
        0.05896236002445221,
        -0.0048751491121947765,
        0.051505159586668015,
        -0.018315237015485764,
        -0.05904173105955124,
        0.006507650017738342,
        -0.05778321251273155,
        0.05181743949651718,
        -0.021855423226952553,
        0.021288132295012474,
        -0.04415389895439148,
        0.04338671267032623,
        0.059008918702602386,
        0.05568338930606842,
        -0.05261996388435364,
        -0.0359068438410759,
        -0.030938565731048584,
        -0.05691341683268547,
        0.049896806478500366,
        0.03791993483901024,
        0.02613573521375656,
        -0.05316804721951485,
        -0.04668061435222626,
        0.03184838593006134,
        0.058632951229810715,
        -0.057898759841918945,
        -0.009489133954048157,
        0.04924897104501724,
        -0.04262664169073105,
        -0.0026186842005699873,
        0.008042832836508751,
        -0.02094409614801407,
        -0.04457677900791168,
        -0.04950568079948425,
        -0.03198271989822388,
        -0.05735616013407707,
        -0.011492151767015457,
        0.05212170630693436,
        0.01774420775473118,
        0.04538186639547348,
        -0.01346930768340826,
        -0.050583045929670334,
        -0.001839006901718676,
        -0.05267176032066345,
        0.045427076518535614,
        -0.05687339976429939,
        -0.001984565518796444,
        -0.055134907364845276,
        -0.046027760952711105,
        0.058696117252111435,
        -0.021152274683117867,
        -0.054928187280893326,
        -0.0481119304895401,
        -0.05374019593000412,
        0.05707692727446556,
        -0.028122058138251305,
        0.016842352226376534,
        -0.05904163047671318,
        -0.01677202433347702,
        -0.058300238102674484,
        0.019228214398026466,
        -0.007823999039828777,
        -0.007640006951987743,
        0.014740661717951298,
        0.002760641509667039,
        -0.024567969143390656,
        -0.04303479939699173,
        -0.04200385883450508,
        0.05442611128091812,
        -0.042812280356884,
        -0.05721103399991989,
        0.03730608522891998,
        -0.02785181812942028,
        -0.04225486144423485,
        0.053694650530815125,
        0.05903645232319832,
        -0.04307565838098526,
        0.046008266508579254,
        -0.059042274951934814,
        0.019958851858973503,
        0.053231239318847656,
        0.0583750419318676,
        -0.05177804455161095,
        -0.0562758669257164,
        0.019260002300143242,
        -0.05655704811215401,
        -0.058646783232688904,
        -0.046991247683763504,
        -0.04905443266034126,
        -0.029513470828533173,
        -0.051975224167108536,
        0.05799618363380432,
        0.04567744955420494,
        -0.04246264323592186,
        0.05844024568796158,
        -0.002770273247733712,
        -0.026577793061733246,
        0.05658424645662308,
        -0.016321701928973198,
        -0.05770792067050934,
        0.010626403614878654,
        0.04579269886016846,
        -0.05325854942202568,
        0.030095800757408142,
        0.039123352617025375,
        -0.05384515970945358,
        0.048905059695243835,
        -0.058282800018787384,
        -0.022696882486343384,
        -0.0355168916285038,
        0.04403568431735039,
        -0.0590347945690155,
        0.0356764979660511,
        0.058439429849386215,
        0.006619971711188555,
        0.009298770688474178,
        -0.0556703545153141,
        0.055614907294511795,
        0.05903659388422966,
        -0.030620215460658073,
        0.029210062697529793,
        -0.05078263208270073,
        0.0575471967458725,
        0.0583086758852005,
        0.008785171434283257,
        -0.014760378748178482,
        -0.02747451327741146,
        0.002386034931987524,
        0.009374613873660564,
        0.04418381676077843,
        0.008069510571658611,
        0.058888062834739685,
        0.015095633454620838,
        -0.04189968481659889,
        0.04339592158794403,
        -0.0019318556878715754,
        0.05017741024494171,
        0.038877300918102264,
        0.058486875146627426,
        -0.031015081331133842,
        0.055697858333587646,
        -0.004700677935034037,
        0.04111712425947189,
        -0.03138638287782669,
        -0.05904318019747734,
        -0.049839284271001816,
        0.05537105351686478,
        0.006993306800723076,
        0.057411085814237595,
        0.037819307297468185,
        -0.030098887160420418,
        0.059041742235422134,
        0.03460152447223663,
        0.05898837372660637,
        -0.015683839097619057,
        0.0468468964099884,
        -0.0464259572327137,
        -0.025164203718304634,
        -0.05744129791855812,
        0.053950823843479156,
        -0.058427780866622925,
        0.007693165447562933,
        0.05350680276751518,
        -0.05840510129928589,
        -0.057520005851984024,
        -0.018832070752978325,
        -0.053522881120443344,
        -0.04913904890418053,
        0.050380904227495193,
        -0.0323614738881588,
        -0.01829487644135952,
        0.056866180151700974,
        0.03459535911679268,
        0.03664623573422432,
        0.05881582573056221,
        0.021759334951639175,
        0.03806270286440849,
        -0.019357336685061455,
        0.030426086857914925,
        0.02260826900601387,
        -0.0054203118197619915,
        -0.02277493290603161,
        -0.00032761984039098024,
        0.03868558257818222,
        -0.03383816406130791,
        -0.0359407477080822,
        -0.05365009978413582,
        -0.050690218806266785,
        -0.055298399180173874,
        -0.004323397763073444,
        0.057047441601753235,
        0.05781131237745285,
        -0.053574465215206146,
        0.057843081653118134,
        0.05274922028183937,
        0.026393549516797066,
        0.027829717844724655,
        0.009061637334525585,
        0.05668075010180473,
        -0.057217106223106384,
        0.052873898297548294,
        -0.04030020162463188,
        0.044961728155612946,
        0.05413617938756943,
        0.009455629624426365,
        -0.04288456216454506,
        -0.058992426842451096,
        -0.00740015646442771,
        -0.05819740146398544,
        0.03248956426978111,
        0.05681045353412628,
        -0.057192519307136536,
        0.020230568945407867,
        -0.02985532023012638,
        0.04118238016963005,
        -0.05827019736170769,
        -0.056476153433322906,
        -0.01792638935148716,
        0.053698208183050156,
        -0.05068003013730049,
        -0.021166430786252022,
        0.014099214226007462,
        -0.059042926877737045,
        -0.05623826012015343,
        0.05214973911643028,
        -0.05359094589948654,
        0.05839027464389801,
        -0.019041264429688454,
        -0.04785965755581856,
        -0.02097591571509838,
        -0.05410070717334747,
        0.056865692138671875,
        0.03988203406333923,
        -0.058942630887031555,
        0.0432945191860199,
        0.0443895198404789,
        0.05665579065680504,
        -0.037367288023233414,
        0.014761845581233501,
        0.04099215939640999,
        0.003292562672868371,
        -0.0419282428920269,
        0.055585891008377075,
        0.04287946596741676,
        -0.005048979539424181,
        0.05870645120739937,
        -0.05319933965802193,
        -0.02483103796839714,
        0.05659569799900055,
        -0.05291229486465454,
        -0.05463693290948868,
        0.05633360892534256,
        0.029694097116589546,
        0.00289046298712492,
        -0.004221543204039335,
        -0.048981793224811554,
        -0.016479721292853355,
        0.05665333941578865,
        -0.020891549065709114,
        0.05570891872048378,
        0.04464070498943329,
        0.031496934592723846,
        0.02396947331726551,
        0.03884151577949524,
        -0.05441834405064583,
        0.009371617808938026,
        -0.008321540430188179,
        -0.023435961455106735,
        -0.0580374076962471,
        0.05878617241978645,
        -0.017497943714261055,
        -0.036206409335136414,
        0.057111889123916626,
        -0.044024523347616196,
        0.04498056694865227,
        0.05396202951669693,
        0.004616613034158945,
        -0.05891638249158859,
        -0.05382213741540909,
        0.05904318019747734,
        0.057826995849609375,
        0.041591841727495193,
        0.056323569267988205,
        -0.01976698264479637,
        0.0552549734711647,
        -0.05049179494380951,
        0.03214371204376221,
        -0.05170082673430443,
        -0.055172331631183624,
        -0.048850417137145996,
        0.04505554959177971,
        -0.0514538437128067,
        -0.04209547117352486,
        -0.055123791098594666,
        0.05358998477458954,
        0.05256440490484238,
        0.04013783484697342,
        -0.046640731394290924,
        -0.03344014659523964,
        0.03769214078783989,
        -0.05228423327207565,
        0.05886218696832657,
        -0.04145516827702522,
        0.05856209993362427,
        -0.05860767513513565,
        -0.04658700153231621,
        -0.056909285485744476,
        0.04731792211532593,
        0.05702931433916092,
        0.03599728271365166,
        0.05889982730150223,
        -0.04127085953950882,
        0.013028908520936966,
        0.022178668528795242,
        -0.031126901507377625,
        -0.0412650890648365,
        0.04777469485998154,
        -0.057394031435251236,
        -0.05032409355044365,
        0.058030929416418076,
        -0.0545402355492115,
        0.036185141652822495,
        -0.052769195288419724,
        -0.050136975944042206,
        -0.058939576148986816,
        -0.03614788502454758,
        0.05877377465367317,
        0.023879779502749443,
        0.04326184093952179,
        0.027831096202135086,
        0.056780800223350525,
        0.057809241116046906,
        -0.054162632673978806,
        -0.056729238480329514,
        -0.058904021978378296,
        -0.04754866287112236,
        0.05535223335027695,
        0.05848719924688339,
        0.058431316167116165,
        -0.02430153824388981,
        0.04894540458917618,
        0.05842430517077446,
        -0.037741292268037796,
        0.05507681891322136,
        -0.04359401389956474,
        -0.05143556743860245,
        -0.04336395487189293,
        -0.057865072041749954,
        0.028481755405664444,
        0.05450902879238129,
        -0.04222586378455162,
        0.03799615427851677,
        0.002243099268525839,
        0.055630315095186234,
        -0.04833456128835678,
        -0.05466296151280403,
        -0.05677548795938492,
        -0.05904227867722511,
        -0.058018412441015244,
        0.048941511660814285,
        0.046947576105594635,
        -0.05311306193470955,
        -0.045989397913217545,
        -0.05015182867646217,
        0.012362957000732422,
        0.05653878301382065,
        0.036151885986328125,
        -0.032075148075819016,
        0.006434877868741751,
        -0.014235242269933224,
        -0.05531500279903412,
        0.005686170421540737,
        0.05139760673046112,
        -0.010210751555860043,
        -0.04175114259123802,
        0.003404536982998252,
        0.010074404999613762,
        0.03376465663313866,
        0.050190337002277374,
        -0.02877381443977356,
        0.043351706117391586,
        0.05883750319480896,
        -0.053292881697416306,
        0.0544586218893528,
        0.02451561763882637,
        -0.03488441929221153,
        -0.006933463271707296,
        0.03083430416882038,
        -0.058326397091150284,
        -0.01680530607700348,
        -0.053360842168331146,
        0.029375972226262093,
        -0.03495893254876137,
        -0.05846158042550087,
        0.058992788195610046,
        -0.026305964216589928,
        0.022707277908921242,
        -0.0528981015086174,
        -0.0533125177025795,
        0.0563073568046093,
        0.05880102515220642,
        -0.05884741246700287,
        0.058988358825445175,
        -0.05806131288409233,
        -0.05878464877605438,
        0.01414363645017147,
        -0.032227084040641785,
        0.03979145362973213,
        0.04576070234179497,
        0.04249298945069313,
        0.05296254903078079,
        -0.016339357942342758,
        0.033502642065286636,
        -0.03512382134795189,
        0.038582734763622284,
        -0.020790817216038704,
        0.010185329243540764,
        0.05702465772628784,
        0.044802408665418625,
        0.02535921335220337,
        0.03823096305131912,
        0.02032139152288437,
        -0.022327851504087448,
        -0.05618755519390106,
        -0.05567626282572746,
        -0.04578975960612297,
        -0.03047649934887886,
        0.053248342126607895,
        0.0015893731033429503,
        0.038428641855716705,
        -0.04215634986758232,
        -0.058927733451128006,
        -0.054592110216617584,
        0.05485733598470688,
        -0.01896188035607338,
        -0.05069784075021744,
        -0.019744470715522766,
        0.008880487643182278,
        0.02819294109940529,
        0.05036269128322601,
        0.03558319807052612,
        0.03777915611863136,
        0.007701241411268711,
        -0.046321894973516464,
        0.030627410858869553,
        0.0587831512093544,
        0.058876074850559235
    ],
    [
        0.010077273473143578,
        0.04414782300591469,
        -0.05592276528477669,
        -0.051885753870010376,
        -0.007209339644759893,
        -0.040386952459812164,
        0.056312333792448044,
        -0.052952840924263,
        -0.0012711992021650076,
        -0.046276167035102844,
        0.05564086139202118,
        0.04444176331162453,
        -0.053861103951931,
        -0.030454067513346672,
        -0.055672481656074524,
        -0.05362311378121376,
        0.05435369163751602,
        0.05342710018157959,
        0.05344589799642563,
        0.00009012158261612058,
        -0.037230413407087326,
        0.05033530294895172,
        0.05634918436408043,
        -0.04051121324300766,
        -0.05457744747400284,
        0.046507328748703,
        0.0013404322089627385,
        0.05459698662161827,
        -0.055786605924367905,
        -0.014701486565172672,
        0.049860335886478424,
        -0.04924114793539047,
        -0.04266974329948425,
        -0.05630265921354294,
        0.05220555141568184,
        -0.054949402809143066,
        -0.054264456033706665,
        -0.05219179764389992,
        0.022183740511536598,
        -0.015975140035152435,
        0.04675360396504402,
        0.029338447377085686,
        0.05461815372109413,
        -0.013460077345371246,
        0.03352155536413193,
        -0.05532744899392128,
        -0.014825581572949886,
        0.05615750700235367,
        0.05633603781461716,
        -0.05138340964913368,
        0.05600891634821892,
        -0.053256649523973465,
        -0.002198955276980996,
        -0.051657535135746,
        -0.03564300760626793,
        0.015418550930917263,
        -0.05631490424275398,
        -0.003578901058062911,
        -0.056314677000045776,
        -0.05150178447365761,
        -0.05592992901802063,
        0.027079423889517784,
        -0.054355792701244354,
        -0.047559309750795364,
        0.03811131790280342,
        0.05257374793291092,
        -0.054602768272161484,
        -0.005428251810371876,
        0.007104318588972092,
        -0.008250383660197258,
        0.04412772133946419,
        -0.055008869618177414,
        0.0561937652528286,
        0.017141276970505714,
        0.055898357182741165,
        -0.007326578721404076,
        0.05631191283464432,
        0.05638474225997925,
        0.032394248992204666,
        0.034865181893110275,
        -0.050713714212179184,
        -0.05615701153874397,
        -0.05603982135653496,
        0.04087691381573677,
        -0.028109705075621605,
        0.05282740294933319,
        -0.053810570389032364,
        -0.03848809376358986,
        -0.05576194450259209,
        0.019559796899557114,
        -0.055894557386636734,
        -0.04558156430721283,
        -0.03261414170265198,
        0.005950394552201033,
        -0.049666304141283035,
        0.017439566552639008,
        0.03529742360115051,
        -0.040130168199539185,
        -0.03534630313515663,
        0.052322931587696075,
        -0.056359998881816864,
        0.04952060058712959,
        0.0562300831079483,
        0.05137525871396065,
        0.0193723626434803,
        -0.053764574229717255,
        0.051573142409324646,
        0.0448199063539505,
        -0.04158028960227966,
        0.05628398060798645,
        -0.052271876484155655,
        -0.006924384273588657,
        -0.05150504410266876,
        0.037355970591306686,
        0.054256949573755264,
        -0.03992214426398277,
        -0.055341366678476334,
        -0.05160484462976456,
        -0.04529435187578201,
        -0.008721962571144104,
        0.03116416744887829,
        0.040484264492988586,
        -0.05630358308553696,
        -0.054427046328783035,
        -0.025990840047597885,
        0.05628979578614235,
        -0.0451575331389904,
        -0.013645150698721409,
        0.020339997485280037,
        0.04158293083310127,
        -0.02610977180302143,
        -0.04706848785281181,
        0.0072953347116708755,
        0.05620399862527847,
        -0.03676711767911911,
        -0.05417415127158165,
        0.0561351403594017,
        0.05517999455332756,
        -0.03061126358807087,
        0.047160856425762177,
        -0.051637742668390274,
        0.001226066262461245,
        0.055560700595378876,
        -0.05463762208819389,
        -0.04015771672129631,
        -0.052965693175792694,
        -0.050191059708595276,
        0.05413403734564781,
        -0.0550236813724041,
        0.026290412992239,
        0.040657997131347656,
        -0.05632316321134567,
        -0.02161397598683834,
        -0.005348756443709135,
        -0.049724772572517395,
        -0.053701478987932205,
        -0.03371254354715347,
        -0.05523211508989334,
        0.04430529475212097,
        0.01048944890499115,
        -0.04210269823670387,
        0.05608491227030754,
        0.05482477322220802,
        0.050794560462236404,
        -0.05326429381966591,
        0.005695164669305086,
        0.04179950803518295,
        -0.04681576415896416,
        0.033101316541433334,
        0.04376751929521561,
        -0.055437419563531876,
        -0.046529971063137054,
        -0.04867474362254143,
        0.05546312406659126,
        0.016247062012553215,
        0.036091506481170654,
        0.047421667724847794,
        0.05134503170847893,
        0.0563538521528244,
        -0.03281363472342491,
        -0.047914836555719376,
        -0.03305283188819885,
        0.05161731690168381,
        0.04479706659913063,
        0.0028981375508010387,
        0.011056158691644669,
        0.014141916297376156,
        0.05613464489579201,
        -0.028840234503149986,
        -0.010437068529427052,
        -0.04752880334854126,
        0.02791667729616165,
        0.014282816089689732,
        0.044949453324079514,
        -0.047261763364076614,
        -0.05015980824828148,
        0.0561944879591465,
        -0.01830061338841915,
        -0.02336614392697811,
        0.04923529922962189,
        0.054619867354631424,
        -0.054175350815057755,
        -0.04295806586742401,
        -0.05610160902142525,
        0.002363608917221427,
        0.008162539452314377,
        -0.0012859235284850001,
        0.05546218529343605,
        -0.050751861184835434,
        -0.055578384548425674,
        -0.04128995165228844,
        0.05422552675008774,
        0.03583613038063049,
        0.05614659935235977,
        -0.055762916803359985,
        0.04854715242981911,
        0.05568314716219902,
        0.00019862820045091212,
        0.05492101609706879,
        -0.027298498898744583,
        -0.00019095645984634757,
        0.05054185912013054,
        -0.05245979502797127,
        -0.05065092071890831,
        0.04330217093229294,
        0.00004570227247313596,
        0.054785750806331635,
        -0.053858332335948944,
        -0.033572468906641006,
        -0.0522015281021595,
        0.001140124280937016,
        -0.049684569239616394,
        0.006489276420325041,
        0.05232911556959152,
        -0.05226773023605347,
        0.056263621896505356,
        0.04126012325286865,
        -0.023236528038978577,
        0.05415758863091469,
        0.05635698512196541,
        -0.05318523570895195,
        0.050654251128435135,
        -0.0384097620844841,
        0.03475309535861015,
        0.04516621306538582,
        -0.048262279480695724,
        -0.03994699940085411,
        -0.01760760135948658,
        0.0008105263113975525,
        -0.038910362869501114,
        0.03065028414130211,
        -0.05597134679555893,
        -0.051052238792181015,
        -0.05218874663114548,
        0.05629511550068855,
        0.0548919215798378,
        -0.054811909794807434,
        -0.017324894666671753,
        0.04842611774802208,
        -0.05089891329407692,
        -0.04958539456129074,
        0.021935241296887398,
        -0.025599278509616852,
        0.056129470467567444,
        -0.05296674743294716,
        0.05592736974358559,
        0.03985285013914108,
        0.04567049443721771,
        0.039227548986673355,
        0.04101862385869026,
        -0.04848119243979454,
        -0.0038048906717449427,
        -0.05292075127363205,
        -0.050216518342494965,
        0.02217918075621128,
        0.02805691957473755,
        -0.05059047415852547,
        0.03255210071802139,
        0.03174496069550514,
        0.027744514867663383,
        -0.055888351052999496,
        0.054368626326322556,
        0.028195729479193687,
        0.05263153463602066,
        0.04566982761025429,
        0.018693815916776657,
        0.0537349134683609,
        0.0011194017715752125,
        -0.056286316365003586,
        0.01868637651205063,
        -0.05388231202960014,
        0.05615568161010742,
        0.04803217202425003,
        -0.006498393602669239,
        0.05628221854567528,
        -0.021358933299779892,
        0.05597677826881409,
        0.016309095546603203,
        -0.045902319252491,
        0.0534549206495285,
        -0.05285458639264107,
        0.03430812805891037,
        0.011074661277234554,
        0.05314784124493599,
        0.056165196001529694,
        -0.033504292368888855,
        -0.013094021938741207,
        0.04880233481526375,
        0.00441752839833498,
        0.05609811469912529,
        0.054694030433893204,
        -0.054739776998758316,
        0.03753390535712242,
        0.011881498619914055,
        -0.00387004716321826,
        -0.05462127923965454,
        -0.03695453703403473,
        0.053435832262039185,
        0.049529340118169785,
        -0.048751551657915115,
        -0.0013954183086752892,
        0.05175042524933815,
        0.05247543752193451,
        0.01924942247569561,
        0.03620575740933418,
        0.055147964507341385,
        -0.03469536826014519,
        -0.04898085072636604,
        -0.036499522626399994,
        -0.053650788962841034,
        0.053153082728385925,
        -0.05166399106383324,
        -0.05516105517745018,
        -0.05239364132285118,
        0.0507146380841732,
        0.05559416115283966,
        -0.05457694083452225,
        -0.002545231021940708,
        -0.027219465002417564,
        -0.05163079872727394,
        -0.05350124463438988,
        -0.05489562451839447,
        -0.0025588960852473974,
        -0.04035763442516327,
        0.05577681213617325,
        0.030386531725525856,
        0.05096105858683586,
        0.05616617202758789,
        0.024280795827507973,
        0.03750064969062805,
        0.05620579048991203,
        0.05277802795171738,
        0.04298073425889015,
        -0.05559495836496353,
        -0.05392080917954445,
        0.02850940264761448,
        -0.05189061537384987,
        -0.04683797061443329,
        -0.0556340217590332,
        -0.05099867284297943,
        0.04412268102169037,
        -0.0015009458875283599,
        -0.0549599751830101,
        -0.04632650688290596,
        0.05164729431271553,
        -0.05565280467271805,
        0.056277479976415634,
        -0.04211890324950218,
        -0.049294404685497284,
        -0.05564967170357704,
        -0.05392924323678017,
        -0.05602658540010452,
        -0.014034188352525234,
        0.05414315313100815,
        0.053347960114479065,
        0.05624551326036453,
        -0.0426933690905571,
        -0.02945014461874962,
        0.05445830896496773,
        -0.03156550973653793,
        -0.05547811836004257,
        0.05408500134944916,
        -0.05590962618589401,
        0.05534693971276283,
        -0.034672316163778305,
        -0.056226883083581924,
        0.04760121926665306,
        -0.05508965253829956,
        -0.05263066664338112,
        -0.051272645592689514,
        -0.0529119111597538,
        0.028880203142762184,
        -0.0019465951481834054,
        0.03864022344350815,
        0.05404055491089821,
        0.05579707399010658,
        -0.006472262553870678,
        -0.05040734261274338,
        -0.05556417256593704,
        -0.05228035897016525,
        -0.05601499229669571,
        0.055298808962106705,
        0.05636214092373848,
        0.008270678110420704,
        0.02835281752049923,
        0.05364711210131645,
        0.05363781377673149,
        -0.04674968123435974,
        0.05215872824192047,
        0.029413674026727676,
        0.029030142351984978,
        -0.020561641082167625,
        -0.0152913061901927,
        -0.0026782036293298006,
        0.0553201399743557,
        -0.05462777614593506,
        0.018499193713068962,
        -0.04942382499575615,
        -0.009958612732589245,
        0.053195927292108536,
        -0.022271228954195976,
        -0.01761496253311634,
        -0.05604657530784607,
        -0.056347813457250595,
        0.04044026508927345,
        0.028210420161485672,
        -0.02357489988207817,
        0.044335443526506424,
        -0.009580173529684544,
        -0.05320701003074646,
        0.035941872745752335,
        -0.014719167724251747,
        0.035208918154239655,
        -0.043013449758291245,
        -0.05033237859606743,
        -0.052896976470947266,
        -0.05467405542731285,
        0.03990219533443451,
        -0.007973894476890564,
        -0.05536225065588951,
        -0.055927325040102005,
        0.031201304867863655,
        0.05326124280691147,
        0.055970698595047,
        -0.05498187988996506,
        -0.0038638836704194546,
        0.05627988651394844,
        0.0008220875752158463,
        0.044185105711221695,
        0.04336557909846306,
        -0.05567743629217148,
        0.02972375601530075,
        0.04654155299067497,
        -0.02827255055308342,
        0.041666384786367416,
        -0.05505778267979622,
        0.04301801696419716,
        -0.049421314150094986,
        0.051259543746709824,
        0.056215863674879074,
        0.04027602821588516,
        0.03876915201544762,
        -0.02252265065908432,
        -0.05150795727968216,
        0.05417990684509277,
        0.05596413090825081,
        -0.054394837468862534,
        0.05616578459739685,
        -0.030108295381069183,
        -0.05607793852686882,
        0.05620778352022171,
        -0.03728256747126579,
        0.046825114637613297,
        0.055286042392253876,
        -0.0552423857152462,
        0.039392437785863876,
        0.048028118908405304,
        0.053508974611759186,
        -0.055361561477184296,
        0.042062755674123764,
        -0.020234284922480583,
        -0.04826931655406952,
        0.024748170748353004,
        0.005478162784129381,
        0.020868539810180664,
        0.0542759895324707,
        0.00022600892407353967,
        0.0520554780960083,
        -0.0553528368473053,
        -0.054165128618478775,
        -0.03978077322244644,
        0.028003089129924774,
        0.0368075966835022,
        -0.021820899099111557,
        0.04290160536766052,
        0.0550907701253891,
        -0.05584277957677841,
        0.019848177209496498,
        0.043965503573417664,
        -0.04346818849444389,
        0.017129097133874893,
        0.01210172288119793,
        -0.05256081745028496,
        0.05264878645539284,
        0.023953352123498917,
        0.008488311432301998,
        0.05596519634127617,
        -0.01846241019666195,
        0.02643636055290699,
        -0.053438663482666016,
        0.04875145107507706,
        -0.04215289652347565
    ],
    [
        -0.025783101096749306,
        0.04397372156381607,
        0.024017684161663055,
        0.010887029580771923,
        0.03422536700963974,
        -0.015182730741798878,
        0.05247894674539566,
        0.008079301565885544,
        0.004223935306072235,
        0.048267532140016556,
        0.007464083377271891,
        0.05172494053840637,
        -0.051692791283130646,
        -0.03886538743972778,
        -0.05476440116763115,
        -0.05141342058777809,
        -0.023522131145000458,
        -0.04089277982711792,
        -0.04625679925084114,
        -0.05589190870523453,
        -0.002647383138537407,
        -0.0329231359064579,
        0.04261544719338417,
        0.032244861125946045,
        0.047549329698085785,
        0.06256099790334702,
        0.02444174513220787,
        0.019917836412787437,
        -0.06145801767706871,
        0.004149682354182005,
        0.06321950256824493,
        -0.038448087871074677,
        -0.002212245250120759,
        -0.06382346898317337,
        -0.04087166488170624,
        -0.04023627191781998,
        -0.05433537811040878,
        -0.044456228613853455,
        -0.06698032468557358,
        0.0339643619954586,
        0.023770997300744057,
        0.015585672110319138,
        0.048777300864458084,
        -0.053098857402801514,
        0.04386630654335022,
        0.00505262752994895,
        -0.04436701908707619,
        0.05552707239985466,
        0.053906913846731186,
        0.0023714855778962374,
        0.019828535616397858,
        0.0012549110688269138,
        0.05911005660891533,
        0.02935057319700718,
        -0.06632589548826218,
        -0.051777586340904236,
        -0.03484981507062912,
        -0.0494624562561512,
        -0.00009098579903366044,
        -0.058737460523843765,
        -0.0527857281267643,
        -0.019871002063155174,
        -0.057688601315021515,
        0.055966611951589584,
        -0.02331063523888588,
        0.02205738052725792,
        0.055769771337509155,
        0.00815192423760891,
        0.06499116122722626,
        -0.06753064692020416,
        -0.056323543190956116,
        -0.06523136794567108,
        -0.05895056575536728,
        -0.06490979343652725,
        -0.01850198581814766,
        0.030031666159629822,
        -0.0003076995490118861,
        0.0649261623620987,
        0.042993348091840744,
        -0.05806667357683182,
        -0.05039764940738678,
        -0.009873438626527786,
        -0.049630992114543915,
        0.05778864771127701,
        -0.05918784439563751,
        0.029020486399531364,
        -0.010894370265305042,
        0.041478946805000305,
        0.019367974251508713,
        0.05798160657286644,
        -0.06206590682268143,
        0.012736267410218716,
        -0.03412047028541565,
        -0.030444128438830376,
        0.013110888190567493,
        0.03817398473620415,
        -0.04128342866897583,
        -0.05468769371509552,
        -0.062032781541347504,
        -0.02493882179260254,
        -0.06645170599222183,
        0.0489833727478981,
        0.0655384287238121,
        0.029120158404111862,
        -0.055435460060834885,
        -0.011202966794371605,
        -0.01848403736948967,
        0.011556347832083702,
        -0.0034882526379078627,
        0.02317068725824356,
        -0.02825235202908516,
        -0.04411177709698677,
        -0.020068397745490074,
        -0.03333304077386856,
        0.04388948902487755,
        -0.047826290130615234,
        0.015088007785379887,
        -0.013945121318101883,
        -0.049183595925569534,
        -0.005018596537411213,
        0.01432116236537695,
        0.021877238526940346,
        -0.0649494156241417,
        0.060835983604192734,
        -0.06648124754428864,
        -0.011739347130060196,
        0.03039383701980114,
        0.054975707083940506,
        0.04982756823301315,
        0.05559513717889786,
        -0.004947042558342218,
        -0.057047441601753235,
        -0.0389348566532135,
        0.03178183361887932,
        -0.04831598326563835,
        -0.04326207563281059,
        0.033722300082445145,
        -0.02362949214875698,
        -0.004394385498017073,
        0.06418594717979431,
        0.06259457767009735,
        0.022318601608276367,
        0.06503365933895111,
        -0.019024305045604706,
        0.04379262402653694,
        -0.014545056968927383,
        0.03149445727467537,
        0.03851437196135521,
        -0.04014335200190544,
        0.056328196078538895,
        -0.045240119099617004,
        -0.06653556227684021,
        -0.036978840827941895,
        0.050863537937402725,
        -0.020516516640782356,
        -0.05758347734808922,
        0.048711828887462616,
        -0.06568535417318344,
        -0.012743514962494373,
        -0.010727531276643276,
        0.06711915135383606,
        0.021692676469683647,
        0.04869178310036659,
        0.03928216174244881,
        -0.06184948608279228,
        -0.0008998902048915625,
        0.0248823631554842,
        -0.06337559968233109,
        0.055515073239803314,
        0.05094150826334953,
        -0.05078072473406792,
        0.013853779062628746,
        -0.04191488400101662,
        0.024335138499736786,
        0.0453796349465847,
        0.05980420485138893,
        -0.056944649666547775,
        0.03028247319161892,
        0.05384442210197449,
        0.008554349653422832,
        0.021413780748844147,
        0.04887210205197334,
        -0.025310209020972252,
        0.05833662301301956,
        0.05708730220794678,
        0.05092863738536835,
        -0.06699851900339127,
        0.0627007782459259,
        -0.020264452323317528,
        0.0030365234706550837,
        0.028195885941386223,
        -0.04056055471301079,
        -0.03789220005273819,
        -0.005826944950968027,
        -0.009716622531414032,
        -0.04040598124265671,
        -0.03767416626214981,
        -0.04179536923766136,
        0.058905597776174545,
        0.009021750651299953,
        0.006431601010262966,
        -0.053828511387109756,
        -0.008178099989891052,
        -0.008719587698578835,
        -0.01570795103907585,
        -0.04447770491242409,
        0.026614787057042122,
        -0.01989060826599598,
        -0.05868573114275932,
        -0.024744044989347458,
        -0.06449349224567413,
        0.0631231740117073,
        -0.048542506992816925,
        -0.030452454462647438,
        0.04928866773843765,
        0.008303975686430931,
        0.06253299117088318,
        0.022728832438588142,
        0.06574512273073196,
        -0.031637195497751236,
        -0.02657007798552513,
        0.003693744307383895,
        -0.027375856414437294,
        -0.06630778312683105,
        0.05460657924413681,
        -0.007562819868326187,
        -0.023316001519560814,
        -0.034575268626213074,
        -0.057330772280693054,
        -0.020492451265454292,
        -0.04353136196732521,
        -0.050729330629110336,
        -0.046882644295692444,
        0.054011911153793335,
        0.004137666430324316,
        0.02942143753170967,
        0.059560663998126984,
        0.0257018581032753,
        0.06340710073709488,
        0.05716072767972946,
        0.01812267117202282,
        0.03307609632611275,
        0.05419597029685974,
        -0.04051324725151062,
        -0.01704709604382515,
        -0.0163927860558033,
        0.04733223095536232,
        -0.04414035379886627,
        -0.05786082148551941,
        -0.02573149837553501,
        -0.058829355984926224,
        -0.060712382197380066,
        -0.05236397311091423,
        -0.05689242482185364,
        0.010461834259331226,
        0.03453921154141426,
        0.01633445918560028,
        0.010631373152136803,
        0.0654701218008995,
        0.055492084473371506,
        0.047279421240091324,
        0.03510817512869835,
        -0.0027093880344182253,
        0.0580088309943676,
        -0.05254929140210152,
        -0.0226509477943182,
        0.05939560756087303,
        0.05765441060066223,
        -0.00889455433934927,
        -0.06176105514168739,
        -0.04773173853754997,
        -0.06483206152915955,
        0.05058048665523529,
        -0.059664249420166016,
        -0.05807020887732506,
        0.040023308247327805,
        -0.059776727110147476,
        0.042518001049757004,
        -0.052046094089746475,
        -0.027617543935775757,
        0.016191398724913597,
        -0.04627108946442604,
        0.06250125914812088,
        0.042356137186288834,
        -0.03800332918763161,
        0.015353696420788765,
        0.04888497665524483,
        -0.05490783229470253,
        -0.06591039896011353,
        0.06182590872049332,
        -0.048954881727695465,
        -0.0038799408357590437,
        -0.03821567818522453,
        0.05278320983052254,
        0.056790757924318314,
        -0.01722993515431881,
        0.06652761250734329,
        -0.0010540306102484465,
        0.059455517679452896,
        0.05764111876487732,
        -0.04387854412198067,
        0.06039254367351532,
        -0.01399153470993042,
        0.04423203691840172,
        -0.027709169313311577,
        -0.060575034469366074,
        -0.04609190300107002,
        -0.01441124640405178,
        0.014356732368469238,
        -0.047962021082639694,
        -0.050682879984378815,
        -0.040428828448057175,
        -0.029499376192688942,
        -0.0064153848215937614,
        -0.0605405829846859,
        -0.04785192012786865,
        0.06336446851491928,
        -0.05423319339752197,
        0.05254200100898743,
        -0.059586476534605026,
        -0.03434094041585922,
        -0.01775510422885418,
        0.04514472559094429,
        -0.042285237461328506,
        -0.014023992232978344,
        0.03326915577054024,
        0.05333760380744934,
        -0.0517270490527153,
        0.0319179929792881,
        0.0015958043513819575,
        -0.009834615513682365,
        0.01917227730154991,
        0.027154454961419106,
        0.0019051434937864542,
        0.05609799176454544,
        0.04447560012340546,
        0.003487891983240843,
        0.05985737591981888,
        -0.04534091800451279,
        0.054881639778614044,
        -0.038489341735839844,
        -0.031019268557429314,
        0.017652906477451324,
        -0.05793760344386101,
        0.06757785379886627,
        -0.012526260688900948,
        -0.05054374039173126,
        0.04277561232447624,
        0.06627179682254791,
        0.033748310059309006,
        -0.06418638676404953,
        -0.022549735382199287,
        -0.05917830392718315,
        -0.05304553359746933,
        -0.06645471602678299,
        0.03694155067205429,
        -0.048843007534742355,
        -0.04832093417644501,
        -0.0656995102763176,
        0.00662586884573102,
        0.04808933660387993,
        0.05928715318441391,
        -0.061317116022109985,
        -0.04914669692516327,
        -0.041869185864925385,
        -0.05210234224796295,
        0.06607784330844879,
        -0.03577625751495361,
        0.05227253958582878,
        -0.064971923828125,
        -0.02577468939125538,
        -0.06558237969875336,
        0.05659903958439827,
        -0.04439510032534599,
        0.04210807383060455,
        0.05068441852927208,
        0.023248795419931412,
        0.048865512013435364,
        -0.007683438248932362,
        -0.02988044172525406,
        -0.02976359613239765,
        0.049934372305870056,
        -0.05819176882505417,
        0.05332530289888382,
        0.056729406118392944,
        -0.04851352795958519,
        0.06313061714172363,
        -0.04909422621130943,
        -0.05898580700159073,
        -0.04568103328347206,
        -0.059751804918050766,
        0.06007319316267967,
        0.03066249005496502,
        0.04800068214535713,
        -0.001556261908262968,
        0.05858451500535011,
        0.06346029788255692,
        -0.056698501110076904,
        -0.06417576968669891,
        -0.06292275339365005,
        -0.06340453773736954,
        0.029252829030156136,
        0.061934471130371094,
        0.01673385314643383,
        -0.020182454958558083,
        0.0597841739654541,
        0.054321710020303726,
        -0.03753609210252762,
        0.06000399589538574,
        -0.0550997294485569,
        0.06644435226917267,
        -0.04705425351858139,
        -0.029909009113907814,
        0.03475058078765869,
        0.058959849178791046,
        -0.030612492933869362,
        -0.03929424658417702,
        0.05173869431018829,
        -0.006002764217555523,
        0.0053608291782438755,
        -0.06684517115354538,
        -0.009074698202311993,
        -0.01608576998114586,
        -0.06479981541633606,
        -0.026799596846103668,
        -0.011340894736349583,
        -0.03126130253076553,
        -0.0027033216319978237,
        -0.06127282977104187,
        -0.05984867364168167,
        0.04278260096907616,
        -0.031810276210308075,
        -0.045574530959129333,
        -0.04895645007491112,
        0.06366786360740662,
        -0.021006133407354355,
        0.03792661428451538,
        -0.00966641679406166,
        0.03416166082024574,
        -0.04473916068673134,
        0.00588420033454895,
        0.048739753663539886,
        -0.004216763190925121,
        0.03919583559036255,
        0.051787249743938446,
        0.03899471089243889,
        0.06322557479143143,
        -0.06486561894416809,
        0.05275231599807739,
        -0.06031543016433716,
        -0.05695786699652672,
        0.04738756641745567,
        0.056270234286785126,
        0.02721939980983734,
        0.015310101211071014,
        -0.028587933629751205,
        0.04566165432333946,
        0.038077373057603836,
        -0.06071366369724274,
        0.05901261046528816,
        0.056328222155570984,
        0.04369793459773064,
        -0.049468860030174255,
        0.02036229893565178,
        0.06250115483999252,
        0.06566152721643448,
        -0.0617629773914814,
        0.06624870747327805,
        -0.01370264496654272,
        -0.06422051042318344,
        0.048390284180641174,
        0.02592984214425087,
        0.015088971704244614,
        0.027086995542049408,
        -0.023151971399784088,
        0.028896288946270943,
        -0.054438333958387375,
        0.04769260808825493,
        -0.03488742560148239,
        -0.05426300689578056,
        0.030036531388759613,
        -0.015411295928061008,
        0.00988332461565733,
        -0.005704071372747421,
        -0.05628509819507599,
        0.06181119754910469,
        -0.016668757423758507,
        -0.018251007422804832,
        -0.05377519503235817,
        -0.04569708928465843,
        -0.0179321076720953,
        -0.05599580705165863,
        -0.0029497731011360884,
        -0.017158513888716698,
        0.06367266178131104,
        -0.0009832349605858326,
        -0.062264032661914825,
        -0.04097870737314224,
        0.053990621119737625,
        0.0447840690612793,
        -0.02415110543370247,
        0.03080751746892929,
        0.02607032284140587,
        -0.019649747759103775,
        -0.014917373657226562,
        -0.024134129285812378,
        0.03869064897298813,
        -0.03873076289892197,
        -0.059592921286821365,
        -0.0355154387652874,
        0.055596958845853806,
        0.059932198375463486
    ],
    [
        -0.059778373688459396,
        -0.061191048473119736,
        -0.0013148036086931825,
        -0.01605064794421196,
        0.022925755009055138,
        -0.03457160294055939,
        0.06512201577425003,
        -0.020415546372532845,
        0.046368807554244995,
        0.024585194885730743,
        0.03083212673664093,
        -0.0013533707242459059,
        -0.04676692932844162,
        -0.024203453212976456,
        -0.06425442546606064,
        -0.06321660429239273,
        -0.05482635647058487,
        0.012393688783049583,
        -0.031949732452631,
        0.015205807983875275,
        -0.05072100833058357,
        0.01180784497410059,
        0.060659244656562805,
        -0.017769830301404,
        -0.012922178953886032,
        0.05778032913804054,
        -0.006515347398817539,
        0.03621329367160797,
        -0.03690238669514656,
        -0.009203758090734482,
        0.0050372895784676075,
        0.03630365803837776,
        0.02849307470023632,
        -0.06175379455089569,
        0.027801431715488434,
        0.027692072093486786,
        -0.054936062544584274,
        -0.04221038520336151,
        0.04551979899406433,
        -0.010501479730010033,
        -0.021728627383708954,
        -0.01625172421336174,
        0.062104806303977966,
        -0.05830726400017738,
        0.022259153425693512,
        -0.006089434027671814,
        0.008234490640461445,
        0.06294702738523483,
        0.06279795616865158,
        0.024439221248030663,
        0.054690830409526825,
        -0.03516305238008499,
        0.058679092675447464,
        -0.060457468032836914,
        0.00020388985285535455,
        -0.06314438581466675,
        -0.05562984570860863,
        0.016013866290450096,
        0.010755361057817936,
        -0.05176571011543274,
        -0.04607904702425003,
        0.01944650523364544,
        -0.048330891877412796,
        -0.0042940969578921795,
        -0.04442938417196274,
        0.05057501792907715,
        0.058851707726716995,
        -0.05613185837864876,
        0.04919518902897835,
        -0.06458673626184464,
        -0.05522270128130913,
        -0.0641150251030922,
        0.006962738931179047,
        0.012277520261704922,
        0.01087904628366232,
        -0.0020616992842406034,
        0.03250567242503166,
        0.03748130798339844,
        0.03351457417011261,
        -0.04748116061091423,
        -0.033036261796951294,
        0.02560136653482914,
        -0.06396931409835815,
        0.02611575648188591,
        -0.03175278380513191,
        0.030908770859241486,
        0.001652994891628623,
        0.046931397169828415,
        -0.015517578460276127,
        0.05477982759475708,
        -0.0634031668305397,
        0.051819510757923126,
        -0.06537718325853348,
        -0.059872087091207504,
        0.05237690731883049,
        0.04214274883270264,
        -0.038899585604667664,
        -0.04956088215112686,
        -0.05479957163333893,
        -0.005604369565844536,
        -0.06526952236890793,
        0.03407728672027588,
        0.06409751623868942,
        0.042400140315294266,
        -0.032086294144392014,
        0.024895289912819862,
        0.06221405789256096,
        0.05573900789022446,
        -0.06204047054052353,
        0.04357278719544411,
        0.004741590470075607,
        -0.06385955959558487,
        -0.009225727990269661,
        0.022168369963765144,
        -0.017725378274917603,
        -0.05078376829624176,
        -0.033513542264699936,
        -0.028594831004738808,
        -0.06451163440942764,
        -0.028534838929772377,
        0.020242074504494667,
        -0.04897236451506615,
        -0.01962500810623169,
        0.048593536019325256,
        -0.06420619785785675,
        -0.0057706986553967,
        -0.04423639923334122,
        0.03611341863870621,
        0.020439593121409416,
        0.021293139085173607,
        0.045866459608078,
        -0.0426681786775589,
        0.012942524626851082,
        0.03053656592965126,
        -0.05718906968832016,
        -0.022076351568102837,
        0.041553717106580734,
        -0.05226631090044975,
        -0.04812929779291153,
        0.0609675794839859,
        0.05241422727704048,
        -0.04926890507340431,
        0.05348222330212593,
        -0.06521463394165039,
        -0.053365398198366165,
        -0.011713119223713875,
        0.027147352695465088,
        0.057794950902462006,
        -0.015663394704461098,
        0.0279991514980793,
        0.021416431292891502,
        -0.06434060633182526,
        -0.04335077852010727,
        0.048201512545347214,
        0.064518503844738,
        -0.03489146754145622,
        0.058662429451942444,
        -0.015408540144562721,
        -0.0015000470448285341,
        0.002912836382165551,
        0.06453939527273178,
        0.023750394582748413,
        0.049218982458114624,
        -0.04821186885237694,
        -0.02643396705389023,
        0.009083428420126438,
        -0.01447503361850977,
        -0.04987795650959015,
        0.027917202562093735,
        0.05590570718050003,
        0.0032894189935177565,
        -0.025500619783997536,
        -0.06352613866329193,
        0.03391524404287338,
        -0.062288831919431686,
        0.05367746949195862,
        -0.05595387518405914,
        -0.05064992979168892,
        0.06144262105226517,
        0.03158421814441681,
        -0.03916630148887634,
        0.0652540996670723,
        0.05551375448703766,
        0.06408759206533432,
        0.033546123653650284,
        0.04473908618092537,
        -0.05928812548518181,
        0.061064351350069046,
        0.05947137996554375,
        -0.06370014697313309,
        0.04829468950629234,
        -0.04535505920648575,
        -0.054956577718257904,
        -0.022526230663061142,
        -0.02048804797232151,
        -0.045659370720386505,
        -0.015003697015345097,
        0.008366159163415432,
        -0.062365807592868805,
        0.035403721034526825,
        0.035304393619298935,
        -0.00492780189961195,
        -0.003422447480261326,
        -0.042485855519771576,
        0.026583250612020493,
        -0.0003874461690429598,
        0.037650104612112045,
        0.04648469761013985,
        -0.057515595108270645,
        0.0017465953715145588,
        -0.06351695209741592,
        0.03699789568781853,
        0.02925766073167324,
        0.022596532478928566,
        -0.014392178505659103,
        -0.013768627308309078,
        0.05980142578482628,
        0.0318664126098156,
        0.06505733728408813,
        -0.03184467926621437,
        -0.028638241812586784,
        -0.061747957020998,
        -0.04993271455168724,
        -0.06409231573343277,
        0.0648597702383995,
        -0.06511211395263672,
        0.014147087000310421,
        -0.008972915820777416,
        -0.05396869406104088,
        -0.057940781116485596,
        0.023802081122994423,
        -0.053191374987363815,
        0.02191038988530636,
        0.05540802702307701,
        -0.031854476779699326,
        0.008967115543782711,
        0.06144542619585991,
        -0.04054919630289078,
        0.037518784403800964,
        0.04760264977812767,
        0.0488961897790432,
        -0.011440609581768513,
        0.03575901314616203,
        -0.035482969135046005,
        0.014118149876594543,
        -0.009104195050895214,
        0.06464385986328125,
        -0.04897940903902054,
        -0.06338228285312653,
        -0.045071080327034,
        -0.0017323391512036324,
        -0.04393606632947922,
        -0.056047938764095306,
        -0.061210937798023224,
        -0.041662320494651794,
        0.010941172018647194,
        -0.05709724873304367,
        -0.06224227696657181,
        0.06481043249368668,
        0.06536490470170975,
        0.006087116431444883,
        0.04000187665224075,
        0.020214764401316643,
        0.061057209968566895,
        -0.05857236683368683,
        0.04801054671406746,
        0.03588585555553436,
        0.05558840185403824,
        -0.05107031390070915,
        -0.058340929448604584,
        0.010864362120628357,
        -0.06523631513118744,
        0.05455987900495529,
        -0.05985633283853531,
        -0.023805173113942146,
        0.06298962980508804,
        -0.057466037571430206,
        0.02845713682472706,
        -0.041394684463739395,
        0.02048361487686634,
        -0.06375625729560852,
        0.06283290684223175,
        -0.005109685938805342,
        0.03421378508210182,
        -0.023706449195742607,
        -0.04467739164829254,
        0.04417412355542183,
        0.008153434842824936,
        0.000615621218457818,
        -0.003182683140039444,
        -0.054228246212005615,
        -0.003593476489186287,
        -0.02142111212015152,
        0.03911951556801796,
        0.04466516152024269,
        -0.054041385650634766,
        0.0650780200958252,
        0.04451947286725044,
        -0.012028410099446774,
        0.06163593754172325,
        0.020216764882206917,
        0.060754332691431046,
        -0.03863474354147911,
        0.01751311868429184,
        -0.05630913004279137,
        -0.023387132212519646,
        -0.054717302322387695,
        0.030422108247876167,
        0.035029005259275436,
        -0.0084935138002038,
        -0.053301431238651276,
        0.006647240836173296,
        -0.017743894830346107,
        0.057950329035520554,
        -0.06456128507852554,
        -0.06025538966059685,
        0.05759399011731148,
        -0.042404655367136,
        0.01439578179270029,
        -0.0478181391954422,
        0.027580877766013145,
        -0.024796515703201294,
        0.05869090184569359,
        -0.015316723845899105,
        0.03222145885229111,
        0.06358838826417923,
        0.05998842045664787,
        0.026698168367147446,
        -0.008714965544641018,
        -0.05478660762310028,
        0.027737580239772797,
        0.03461393713951111,
        0.009618183597922325,
        -0.047182437032461166,
        0.0436939038336277,
        0.04937223345041275,
        -0.05907360464334488,
        0.054998334497213364,
        -0.017862185835838318,
        0.05075154826045036,
        -0.007922142744064331,
        -0.01287580281496048,
        0.05949176102876663,
        -0.01896282657980919,
        0.06442175805568695,
        0.05830724909901619,
        0.005454876460134983,
        0.03089185059070587,
        0.0626644492149353,
        0.03149573504924774,
        -0.06390289217233658,
        0.02620496228337288,
        -0.06246769800782204,
        -0.06521321088075638,
        -0.06294518709182739,
        0.05378333479166031,
        -0.0643189549446106,
        -0.009880640543997288,
        -0.05709754675626755,
        -0.06441450119018555,
        0.06204329803586006,
        0.054876603186130524,
        -0.03396425396203995,
        -0.03005516529083252,
        -0.0017423555254936218,
        -0.01649603806436062,
        0.0651712417602539,
        -0.013858513906598091,
        0.03187642991542816,
        -0.06531285494565964,
        -0.05568017065525055,
        -0.0649539977312088,
        0.040020935237407684,
        0.009996146894991398,
        0.06367960572242737,
        0.05266670510172844,
        0.022922491654753685,
        0.025225060060620308,
        -0.00005502888961927965,
        -0.03064507059752941,
        -0.0442904531955719,
        0.060351595282554626,
        -0.0572953075170517,
        -0.009691269136965275,
        -0.005777718033641577,
        -0.04640822485089302,
        0.06499560177326202,
        -0.06139634549617767,
        -0.04460550844669342,
        -0.04927336052060127,
        -0.0601622499525547,
        -0.014574352651834488,
        -0.010672838427126408,
        0.02006150409579277,
        0.005919716786593199,
        0.059762898832559586,
        0.0590677335858345,
        -0.035711176693439484,
        -0.05418166145682335,
        -0.06535696238279343,
        -0.06085421144962311,
        0.02669498696923256,
        0.05856920778751373,
        0.061883553862571716,
        -0.05445650592446327,
        0.016340255737304688,
        -0.03204414248466492,
        -0.04387100040912628,
        0.06479400396347046,
        -0.053819440305233,
        0.06030488759279251,
        -0.04886668547987938,
        0.03157464414834976,
        0.001855023205280304,
        0.05863732472062111,
        0.006220150738954544,
        -0.016559110954403877,
        0.0022558546625077724,
        0.06170322746038437,
        0.04770302027463913,
        -0.05199047923088074,
        -0.054774247109889984,
        -0.04976651445031166,
        -0.056791312992572784,
        0.033441923558712006,
        -0.04812197759747505,
        -0.058585140854120255,
        -0.048535022884607315,
        -0.04883091151714325,
        -0.002799045294523239,
        -0.002265810500830412,
        -0.011913251131772995,
        0.006137569900602102,
        -0.04279378801584244,
        0.036691468209028244,
        -0.03686624392867088,
        -0.033923640847206116,
        0.03466518223285675,
        -0.04328933730721474,
        -0.06019676476716995,
        0.0005737582105211914,
        -0.001386891002766788,
        0.06473226845264435,
        0.059197623282670975,
        0.02193053998053074,
        0.059997886419296265,
        0.0631152093410492,
        -0.029488733038306236,
        0.06236235797405243,
        -0.016924701631069183,
        -0.04303932562470436,
        0.04858811944723129,
        0.060156483203172684,
        -0.06292209774255753,
        -0.06464169919490814,
        -0.044717323035001755,
        0.037296392023563385,
        0.05288516730070114,
        -0.030142171308398247,
        0.06004001200199127,
        0.012948929332196712,
        -0.01098201610147953,
        -0.04262591153383255,
        0.03895072266459465,
        0.0612386018037796,
        0.06515874713659286,
        -0.06394853442907333,
        0.06344606727361679,
        -0.05317900702357292,
        -0.06443602591753006,
        0.05029752105474472,
        0.057377900928258896,
        0.025659814476966858,
        0.05558835715055466,
        -0.0033570725936442614,
        0.0545671284198761,
        -0.03884957358241081,
        0.06366896629333496,
        -0.050136398524045944,
        -0.06305267661809921,
        -0.06193561479449272,
        0.047012656927108765,
        0.0208014864474535,
        0.004188810475170612,
        0.02791893109679222,
        0.04619796946644783,
        -0.047575898468494415,
        -0.015379715710878372,
        -0.06289100646972656,
        0.005009759217500687,
        -0.018350081518292427,
        -0.06334666907787323,
        0.02668408304452896,
        -0.009064788930118084,
        0.04505425691604614,
        0.0400039367377758,
        -0.0606030710041523,
        0.040069110691547394,
        0.05388465151190758,
        -0.00034764522570185363,
        -0.03995869308710098,
        -0.04394908621907234,
        0.04484306275844574,
        -0.030299566686153412,
        0.022903090342879295,
        0.025279410183429718,
        0.05708041042089462,
        0.0017038799123838544,
        -0.0576191172003746,
        -0.06090649962425232,
        0.06464114785194397,
        0.03332797437906265
    ],
    [
        0.030981330201029778,
        -0.05331791192293167,
        -0.02319270558655262,
        0.03048500046133995,
        -0.02411741577088833,
        -0.052111778408288956,
        0.008956835605204105,
        -0.053094714879989624,
        -0.020872613415122032,
        0.0039100949652493,
        0.03519826754927635,
        0.0010530540021136403,
        -0.05324338376522064,
        -0.027348415926098824,
        -0.0525030680000782,
        0.011039694771170616,
        -0.04368653893470764,
        0.050612058490514755,
        -0.03941965103149414,
        -0.02634059078991413,
        0.036928940564394,
        -0.032906871289014816,
        0.05329215154051781,
        -0.02720324881374836,
        0.019423840567469597,
        -0.0076042176224291325,
        -0.047026898711919785,
        0.047162510454654694,
        -0.05332018807530403,
        0.043554771691560745,
        0.04745389521121979,
        0.053084347397089005,
        0.03486696630716324,
        -0.05329597741365433,
        0.052611492574214935,
        -0.0479939803481102,
        -0.05324311554431915,
        -0.04750861972570419,
        0.02541833557188511,
        -0.05316358432173729,
        0.05154311656951904,
        0.04438282176852226,
        0.05231383815407753,
        -0.05270479619503021,
        0.039843037724494934,
        0.04479437321424484,
        0.04882391542196274,
        0.05330207571387291,
        0.053324539214372635,
        0.042441610246896744,
        0.05308319628238678,
        0.05314448103308678,
        0.05175657197833061,
        -0.053195737302303314,
        -0.05073077604174614,
        0.038795433938503265,
        -0.047603871673345566,
        -0.004899756517261267,
        -0.05310515686869621,
        -0.052773039788007736,
        -0.05332166329026222,
        0.03835538774728775,
        -0.05321716517210007,
        0.04967823997139931,
        0.05170512944459915,
        -0.05017520487308502,
        -0.05216378718614578,
        0.007004487793892622,
        0.05332046002149582,
        -0.0014677613507956266,
        -0.03207119554281235,
        -0.05273876339197159,
        0.052434202283620834,
        0.01210696715861559,
        0.05321608856320381,
        -0.02942769229412079,
        0.053311582654714584,
        0.05332581698894501,
        0.051448754966259,
        0.014406640082597733,
        -0.04994824901223183,
        -0.052846312522888184,
        -0.05307165905833244,
        0.05319047346711159,
        -0.05147618055343628,
        0.039325546473264694,
        -0.030580846592783928,
        0.05203263461589813,
        -0.03781229257583618,
        0.046446118503808975,
        -0.05318605899810791,
        -0.045540597289800644,
        -0.052434202283620834,
        -0.04748466610908508,
        -0.03000919334590435,
        0.05264542996883392,
        -0.044726692140102386,
        -0.05330033600330353,
        -0.05302605777978897,
        0.04703725129365921,
        -0.053325146436691284,
        0.05331816524267197,
        0.0524403378367424,
        0.04443533346056938,
        0.052752070128917694,
        -0.05001843720674515,
        -0.04207313433289528,
        0.0529259517788887,
        -0.0519770048558712,
        0.014635918661952019,
        -0.007599012926220894,
        -0.05289295315742493,
        -0.04474485293030739,
        -0.026563525199890137,
        0.03698122501373291,
        -0.05135446786880493,
        -0.04696860536932945,
        -0.035118669271469116,
        -0.04730159044265747,
        -0.0516546294093132,
        -0.052401069551706314,
        -0.05269263684749603,
        -0.045821838080883026,
        0.016742486506700516,
        -0.052727364003658295,
        0.052035536617040634,
        -0.04818896949291229,
        -0.048220038414001465,
        0.052277643233537674,
        -0.05122974142432213,
        0.0040588537231087685,
        -0.046221841126680374,
        -0.03507257625460625,
        0.049750953912734985,
        -0.05230383947491646,
        -0.051981303840875626,
        0.05332355946302414,
        0.027366921305656433,
        -0.0515035055577755,
        0.053180597722530365,
        0.03728929162025452,
        -0.01372898556292057,
        0.04923608899116516,
        -0.00860156212002039,
        0.01678985171020031,
        0.040559928864240646,
        0.04357663914561272,
        0.023528793826699257,
        -0.006107388064265251,
        0.04747664928436279,
        -0.03731239587068558,
        -0.05329108238220215,
        -0.02546006627380848,
        0.02936727926135063,
        0.010894213803112507,
        -0.05005653202533722,
        -0.051636915653944016,
        -0.052441589534282684,
        0.05253637582063675,
        -0.039122939109802246,
        0.052484042942523956,
        -0.02408210188150406,
        0.05326109379529953,
        0.05316978693008423,
        -0.032046444714069366,
        -0.0254493597894907,
        -0.024454014375805855,
        -0.03847993165254593,
        -0.03258615732192993,
        0.051402583718299866,
        -0.024764912202954292,
        0.052376557141542435,
        -0.04484539106488228,
        0.05291154980659485,
        -0.04166770353913307,
        -0.04568883404135704,
        0.033113475888967514,
        -0.0054556443355977535,
        0.05259524658322334,
        -0.04746641963720322,
        -0.046133194118738174,
        0.041439708322286606,
        0.050936393439769745,
        -0.04516621306538582,
        0.002773626707494259,
        0.04639890417456627,
        -0.03345697000622749,
        0.052663467824459076,
        -0.008349787443876266,
        -0.022051312029361725,
        -0.052150577306747437,
        0.034839749336242676,
        0.02212882973253727,
        -0.01584601402282715,
        -0.05326315760612488,
        0.03753155842423439,
        0.05331403762102127,
        -0.006799745839089155,
        -0.000906423490960151,
        0.05317702516913414,
        0.05332520976662636,
        -0.05043436214327812,
        -0.042423393577337265,
        -0.05271641165018082,
        -0.04122186452150345,
        -0.05097854137420654,
        0.0533059760928154,
        0.05330018326640129,
        -0.052096858620643616,
        0.05305776000022888,
        -0.05160843953490257,
        0.05147843435406685,
        0.0485602542757988,
        0.05329244211316109,
        -0.05322489142417908,
        -0.05240551382303238,
        0.0530126579105854,
        -0.05146406218409538,
        0.0533139668405056,
        -0.04407642409205437,
        0.03088410384953022,
        0.05295053496956825,
        -0.0032955531496554613,
        -0.05260049179196358,
        0.05331093445420265,
        -0.05316510796546936,
        0.05146390572190285,
        -0.05134974792599678,
        0.04239850863814354,
        -0.05312275141477585,
        -0.053234752267599106,
        0.019573187455534935,
        -0.028689783066511154,
        0.049693863838911057,
        -0.040888622403144836,
        0.05212267115712166,
        0.052566975355148315,
        0.04658113420009613,
        -0.03481660038232803,
        0.05329473689198494,
        -0.008126541040837765,
        0.03584592044353485,
        -0.0312412790954113,
        -0.053191304206848145,
        0.04924655333161354,
        0.02634190209209919,
        0.049477800726890564,
        0.04673423990607262,
        0.04351138323545456,
        -0.04801759123802185,
        -0.05176709592342377,
        -0.05331509932875633,
        -0.04811843857169151,
        -0.0530700720846653,
        -0.05020955577492714,
        -0.028116950765252113,
        0.018025267869234085,
        -0.05280926451086998,
        0.031489625573158264,
        0.053092338144779205,
        -0.051630474627017975,
        -0.050975117832422256,
        -0.01968356966972351,
        0.051848433911800385,
        -0.04436378926038742,
        0.053293827921152115,
        0.053261615335941315,
        0.05258313566446304,
        -0.05332345888018608,
        -0.047671444714069366,
        0.05296386778354645,
        -0.0515257865190506,
        0.047738607972860336,
        -0.05141628533601761,
        -0.05315875634551048,
        0.05138951539993286,
        -0.03154684603214264,
        -0.05300552025437355,
        0.04979362338781357,
        0.05283303186297417,
        -0.012420509941875935,
        0.052537355571985245,
        -0.045581381767988205,
        0.04793553054332733,
        -0.016815824434161186,
        0.0497237928211689,
        0.05087747797369957,
        0.013286097906529903,
        0.013727630488574505,
        0.05217817425727844,
        -0.04554010182619095,
        -0.042239587754011154,
        -0.01467784121632576,
        0.0387827567756176,
        0.053215667605400085,
        -0.05192244425415993,
        0.053322143852710724,
        -0.03484649956226349,
        -0.03223787620663643,
        0.053300485014915466,
        -0.042030394077301025,
        -0.04713260382413864,
        -0.015384695492684841,
        0.052658312022686005,
        -0.04982132092118263,
        -0.031998973339796066,
        0.049912624061107635,
        0.04302196949720383,
        0.019612161442637444,
        -0.016344057396054268,
        -0.05222894996404648,
        0.038002174347639084,
        0.0514114610850811,
        0.05331959202885628,
        0.03218606114387512,
        -0.05294366553425789,
        0.03947065770626068,
        0.002109665423631668,
        0.051918189972639084,
        0.045238740742206573,
        0.046452779322862625,
        -0.02925374172627926,
        -0.029466042295098305,
        0.022355489432811737,
        0.053269706666469574,
        0.04973962530493736,
        0.029224436730146408,
        -0.053028032183647156,
        -0.04185754805803299,
        -0.02819231152534485,
        0.01641683652997017,
        -0.04843004792928696,
        -0.05322149023413658,
        -0.05251668021082878,
        0.05072164908051491,
        0.0500471256673336,
        -0.03283043950796127,
        0.043691981583833694,
        -0.05270398408174515,
        0.0319318026304245,
        -0.036696068942546844,
        -0.050818946212530136,
        0.05326587334275246,
        -0.04479840397834778,
        0.05296358838677406,
        0.05005338042974472,
        0.007940947078168392,
        0.05300683528184891,
        0.03212794288992882,
        0.04844099283218384,
        0.05026481673121452,
        -0.014954297803342342,
        0.027767358347773552,
        -0.04940466210246086,
        -0.05246978625655174,
        0.042470842599868774,
        -0.053161848336458206,
        -0.03557301685214043,
        -0.05223000794649124,
        -0.04895489662885666,
        0.04904527589678764,
        0.04848671704530716,
        -0.0372946560382843,
        -0.029124313965439796,
        0.01690818928182125,
        -0.05285241827368736,
        0.051956817507743835,
        -0.053143203258514404,
        -0.05328042805194855,
        -0.053318001329898834,
        -0.038720790296792984,
        -0.04350379854440689,
        0.050080347806215286,
        -0.04664497449994087,
        0.053224798291921616,
        0.05099789425730705,
        -0.04931553825736046,
        -0.04370393604040146,
        0.047778867185115814,
        0.05207466334104538,
        -0.045036640018224716,
        0.046996649354696274,
        -0.05309702083468437,
        0.05204487219452858,
        0.043317582458257675,
        -0.05225545912981033,
        -0.03932013362646103,
        -0.05308731645345688,
        -0.05325010046362877,
        -0.023101838305592537,
        -0.035416822880506516,
        0.03147927299141884,
        -0.05136546120047569,
        0.05285964533686638,
        0.05310798063874245,
        0.0527237132191658,
        0.05248434469103813,
        -0.015840109437704086,
        -0.045718900859355927,
        -0.05332239717245102,
        -0.03179498389363289,
        0.053322285413742065,
        0.05327305570244789,
        0.04983505606651306,
        -0.04447507485747337,
        -0.04332956299185753,
        0.026348769664764404,
        -0.052967704832553864,
        0.05330163240432739,
        -0.05248808488249779,
        0.05320797115564346,
        0.007195831276476383,
        0.05080317333340645,
        -0.04740471765398979,
        0.05275706574320793,
        -0.04936784505844116,
        0.0395486019551754,
        0.03547096997499466,
        0.052157457917928696,
        0.053141381591558456,
        0.050064168870449066,
        -0.02947130799293518,
        -0.046273358166217804,
        -0.05331655219197273,
        0.014089242555201054,
        -0.0395599827170372,
        0.03193102031946182,
        -0.0009904210455715656,
        -0.0496964305639267,
        -0.05253485217690468,
        -0.024745242670178413,
        0.027647851034998894,
        -0.034779250621795654,
        -0.03662106767296791,
        0.015042838640511036,
        -0.052401840686798096,
        -0.053321804851293564,
        0.04474736005067825,
        -0.049162402749061584,
        -0.052374351769685745,
        -0.05324579402804375,
        0.04468821734189987,
        0.04667278006672859,
        0.05206748843193054,
        0.04032391682267189,
        -0.03324827551841736,
        0.052160199731588364,
        -0.051798950880765915,
        0.029354287311434746,
        0.05321374163031578,
        -0.05058417469263077,
        0.017316410318017006,
        0.053249940276145935,
        -0.020357202738523483,
        0.04528701305389404,
        -0.05231187865138054,
        -0.0514555461704731,
        -0.014169607311487198,
        -0.0003187344700563699,
        0.04758238419890404,
        0.04899640381336212,
        -0.052918966859579086,
        0.01982356794178486,
        0.05156693235039711,
        0.05332108959555626,
        0.05330504849553108,
        -0.05331249535083771,
        0.05330841615796089,
        0.030892007052898407,
        -0.05283280089497566,
        0.048893630504608154,
        0.04038901627063751,
        0.05315447226166725,
        0.0432899035513401,
        0.008364575915038586,
        0.01622474566102028,
        -0.0025807495694607496,
        0.0474604070186615,
        -0.04921361804008484,
        -0.052805427461862564,
        -0.052745919674634933,
        0.038409095257520676,
        -0.05270202085375786,
        0.04246200993657112,
        -0.04816211014986038,
        0.05065004900097847,
        -0.03144089877605438,
        0.05270162224769592,
        -0.05316723510622978,
        0.02025514841079712,
        -0.001065180404111743,
        -0.05278271436691284,
        0.04447424039244652,
        -0.02175549976527691,
        -0.012785635888576508,
        -0.026234369724988937,
        -0.05328821390867233,
        0.03626256436109543,
        0.05326991155743599,
        -0.051474545150995255,
        -0.022005926817655563,
        -0.035774923861026764,
        -0.04554189741611481,
        -0.03418910875916481,
        0.0292294230312109,
        0.05295313894748688,
        0.047369830310344696,
        -0.050130728632211685,
        0.030029116198420525,
        -0.052691251039505005,
        0.05321048945188522,
        -0.025988416746258736
    ],
    [
        -0.05423084646463394,
        -0.05407142639160156,
        -0.052016548812389374,
        0.012906091287732124,
        -0.03693234547972679,
        -0.011385462246835232,
        0.054638829082250595,
        -0.05477002263069153,
        0.05309339240193367,
        -0.02135503478348255,
        0.054299987852573395,
        0.010000580921769142,
        -0.053646620362997055,
        -0.05424070730805397,
        -0.05416183918714523,
        -0.04424593597650528,
        0.025831466540694237,
        0.05501079559326172,
        0.02529233507812023,
        -0.015394662506878376,
        0.04374011978507042,
        0.04648809880018234,
        0.05497929826378822,
        -0.0534982904791832,
        -0.03492184728384018,
        0.052423980087041855,
        -0.052335429936647415,
        -0.05328899994492531,
        0.04280618578195572,
        -0.05234074592590332,
        0.0013035056181252003,
        -0.000425511971116066,
        0.047355253249406815,
        -0.05498932674527168,
        0.04006529599428177,
        -0.050308436155319214,
        -0.053513895720243454,
        0.0384545624256134,
        -0.01328959595412016,
        -0.05489281937479973,
        0.016931870952248573,
        0.011025749146938324,
        0.054246388375759125,
        -0.018587546423077583,
        0.05358174070715904,
        -0.019315510988235474,
        -0.04902192950248718,
        0.05429466441273689,
        0.05499882623553276,
        0.05502287298440933,
        0.05440123751759529,
        0.043123114854097366,
        -0.0026407029945403337,
        -0.05490521341562271,
        0.046455949544906616,
        -0.05504979193210602,
        -0.05380025878548622,
        0.04026253893971443,
        0.01092049852013588,
        -0.055049870163202286,
        -0.05459192022681236,
        -0.04367363080382347,
        -0.05402740463614464,
        0.05057167634367943,
        0.0388638973236084,
        -0.03994225338101387,
        0.026176633313298225,
        -0.04938535392284393,
        -0.05496235564351082,
        -0.046904150396585464,
        0.0060391793958842754,
        -0.05476480349898338,
        0.0546015240252018,
        -0.007528587710112333,
        0.046149417757987976,
        -0.03887857869267464,
        0.03733571618795395,
        0.05500606447458267,
        -0.04298054054379463,
        0.025911526754498482,
        -0.04207240790128708,
        0.02685050293803215,
        -0.054593220353126526,
        0.05127362161874771,
        -0.0490388460457325,
        0.04380630701780319,
        -0.05451954901218414,
        0.045267123728990555,
        0.011707424186170101,
        0.054972823709249496,
        -0.047362957149744034,
        0.05118226259946823,
        -0.055050432682037354,
        0.005396069493144751,
        0.02889684960246086,
        0.006749544758349657,
        -0.01342054270207882,
        -0.009553180076181889,
        -0.043638262897729874,
        -0.003014765214174986,
        -0.05420072749257088,
        0.05419772490859032,
        -0.03748566284775734,
        -0.05152418836951256,
        -0.05504583939909935,
        0.005881245713680983,
        0.05503787100315094,
        0.0300203375518322,
        -0.050694435834884644,
        0.05273079872131348,
        0.03429236635565758,
        -0.04944610968232155,
        -0.046547889709472656,
        0.05461552366614342,
        0.023923281580209732,
        0.03471871092915535,
        0.0018723673420026898,
        -0.0467938631772995,
        0.014190570451319218,
        0.020028097555041313,
        -0.043982166796922684,
        0.050477053970098495,
        0.0525025837123394,
        0.01496744155883789,
        -0.04356716200709343,
        -0.05497545003890991,
        0.054937344044446945,
        0.04712073877453804,
        0.05460892990231514,
        0.047049809247255325,
        0.0453607514500618,
        -0.052924808114767075,
        0.053821828216314316,
        0.030776629224419594,
        -0.046658314764499664,
        -0.05443572252988815,
        0.05363629385828972,
        0.04916280508041382,
        0.04858441278338432,
        0.04458123818039894,
        0.0550270639359951,
        -0.051576610654592514,
        0.049329131841659546,
        -0.055050842463970184,
        -0.04091440886259079,
        0.0541122630238533,
        -0.017184069380164146,
        0.05505095794796944,
        0.054158907383680344,
        -0.04519696533679962,
        0.05504506453871727,
        -0.0548563189804554,
        -0.040347304195165634,
        -0.026909613981842995,
        0.05505082756280899,
        -0.05486496165394783,
        -0.05395853519439697,
        -0.005175114143639803,
        0.05487241968512535,
        0.05316795036196709,
        0.05320877954363823,
        -0.023961255326867104,
        0.04957343265414238,
        -0.010133746080100536,
        0.05429234728217125,
        0.048510268330574036,
        -0.029786381870508194,
        -0.051203783601522446,
        -0.052848704159259796,
        0.026209143921732903,
        -0.03390643373131752,
        -0.023578889667987823,
        -0.05448935180902481,
        -0.053644128143787384,
        -0.05415913835167885,
        0.046676747500896454,
        -0.01035331655293703,
        -0.01811356283724308,
        0.05101296305656433,
        -0.04684096947312355,
        -0.022649532184004784,
        0.05505095794796944,
        -0.03913892060518265,
        0.054830193519592285,
        0.034404367208480835,
        0.04917461425065994,
        -0.014428559690713882,
        0.04761184751987457,
        0.054878801107406616,
        -0.04792365804314613,
        0.0007340065203607082,
        0.0486760213971138,
        -0.054484881460666656,
        -0.038276929408311844,
        -0.019913509488105774,
        -0.055020302534103394,
        -0.05156517028808594,
        -0.04383661970496178,
        -0.051447343081235886,
        0.0034382990561425686,
        0.052890002727508545,
        -0.04714231938123703,
        0.039687905460596085,
        0.03251520171761513,
        0.03922751545906067,
        -0.049795377999544144,
        0.02123532071709633,
        0.00882731657475233,
        0.05305386707186699,
        0.054942283779382706,
        -0.02876989357173443,
        0.05228342488408089,
        -0.053041648119688034,
        -0.03532175347208977,
        0.05297107622027397,
        0.04153364151716232,
        -0.012026174925267696,
        -0.04493014141917229,
        0.05472949147224426,
        0.050367310643196106,
        0.055027250200510025,
        -0.05169500410556793,
        0.026156706735491753,
        -0.021716443821787834,
        0.05499402433633804,
        -0.05496831610798836,
        -0.04844382405281067,
        0.051789429038763046,
        -0.041300877928733826,
        0.0123447822406888,
        0.009414794854819775,
        -0.018735934048891068,
        0.053445663303136826,
        0.052558496594429016,
        -0.03259856626391411,
        0.04326432943344116,
        0.053628355264663696,
        -0.0329214483499527,
        -0.042353712022304535,
        0.05496968328952789,
        -0.04040049761533737,
        0.036355696618556976,
        -0.004684065002948046,
        -0.05266149714589119,
        0.03915522247552872,
        -0.03525755554437637,
        -0.040950484573841095,
        0.054806485772132874,
        -0.011082449927926064,
        -0.03516913950443268,
        -0.05475986748933792,
        -0.052529167383909225,
        -0.04693714901804924,
        -0.05126529186964035,
        0.0525737926363945,
        -0.05349329859018326,
        -0.0550101138651371,
        -0.055045418441295624,
        0.047858625650405884,
        0.05505095422267914,
        -0.05298341438174248,
        0.0537906251847744,
        -0.054994624108076096,
        0.05447699502110481,
        -0.05125241354107857,
        0.05089002847671509,
        -0.004966398235410452,
        0.0318649485707283,
        -0.0530976876616478,
        -0.05193094164133072,
        0.045923974364995956,
        -0.0534232035279274,
        0.05400766059756279,
        -0.05443434789776802,
        0.026648906990885735,
        0.03519855812191963,
        -0.040740966796875,
        0.054711710661649704,
        0.05089552327990532,
        -0.043730489909648895,
        -0.05153963342308998,
        -0.034230753779411316,
        0.01616370491683483,
        0.0018154687713831663,
        0.044630780816078186,
        -0.054672446101903915,
        0.048899050801992416,
        0.054433614015579224,
        0.025673193857073784,
        0.02783338911831379,
        -0.051123734563589096,
        -0.012534269131720066,
        -0.053471919149160385,
        0.01825464516878128,
        0.054815590381622314,
        -0.04426227882504463,
        0.04361256584525108,
        0.05218122527003288,
        0.033287931233644485,
        0.047293394804000854,
        -0.055008213967084885,
        -0.0019471269333735108,
        -0.004891288001090288,
        0.01682458631694317,
        -0.047504376620054245,
        -0.04539157450199127,
        -0.0535319559276104,
        -0.051000531762838364,
        0.042797982692718506,
        0.03425566107034683,
        -0.034422367811203,
        -0.052707597613334656,
        -0.04953985661268234,
        -0.03741928189992905,
        -0.054912764579057693,
        -0.049052540212869644,
        -0.04402123764157295,
        -0.05372023209929466,
        0.052660949528217316,
        -0.05474080145359039,
        -0.04461941868066788,
        0.05199604853987694,
        0.050312191247940063,
        0.050357140600681305,
        0.054233256727457047,
        0.028806937858462334,
        0.05423310771584511,
        -0.05456516891717911,
        0.008912757970392704,
        0.03689693287014961,
        0.015510871075093746,
        0.0277355145663023,
        -0.05028889328241348,
        -0.04718202352523804,
        0.009239145554602146,
        0.02019389532506466,
        -0.014238639734685421,
        -0.0104695288464427,
        -0.053190890699625015,
        -0.013646122068166733,
        -0.04903610050678253,
        0.050919052213430405,
        0.05477087199687958,
        0.03871138393878937,
        -0.03807594254612923,
        0.05452818050980568,
        0.0528409518301487,
        0.05150110647082329,
        0.055026277899742126,
        0.018555058166384697,
        -0.054476723074913025,
        -0.033379022032022476,
        -0.023144617676734924,
        -0.05056347697973251,
        -0.04614084213972092,
        -0.02714497596025467,
        -0.054250091314315796,
        -0.05483772233128548,
        -0.02444509230554104,
        -0.0523994080722332,
        0.04454610124230385,
        0.04810589551925659,
        0.052488651126623154,
        -0.04957236349582672,
        -0.018537933006882668,
        -0.051419101655483246,
        0.054224833846092224,
        -0.03296038135886192,
        0.04819783568382263,
        -0.05438505858182907,
        -0.038980938494205475,
        -0.014206686988472939,
        0.0022680251859128475,
        -0.05503128841519356,
        0.05476117506623268,
        0.04937343671917915,
        -0.03419777750968933,
        0.054165322333574295,
        0.038733553141355515,
        0.022347796708345413,
        0.0546422153711319,
        -0.0021971676032990217,
        -0.04191397503018379,
        0.0007491509895771742,
        0.04355982318520546,
        -0.05461376905441284,
        0.054988328367471695,
        0.010047715157270432,
        -0.0008099612314254045,
        0.047500669956207275,
        -0.05504804477095604,
        0.03945723921060562,
        0.0035595567896962166,
        -0.05042435973882675,
        0.0286214891821146,
        0.05400077626109123,
        -0.004395381081849337,
        -0.04757877439260483,
        0.05392202362418175,
        -0.033149782568216324,
        -0.04938312992453575,
        0.05455583333969116,
        -0.05430997163057327,
        0.04967110604047775,
        -0.05473686754703522,
        0.0444318987429142,
        0.03405079245567322,
        -0.04343443363904953,
        0.05282365158200264,
        -0.04889018461108208,
        0.026221884414553642,
        -0.02564254216849804,
        0.055047228932380676,
        0.053698599338531494,
        0.05082684010267258,
        -0.01972857676446438,
        0.05314987897872925,
        0.05079874396324158,
        0.055048730224370956,
        -0.011857761070132256,
        0.04811229184269905,
        -0.04402036964893341,
        0.054766781628131866,
        -0.05498580262064934,
        0.04118796065449715,
        -0.05501028150320053,
        -0.05497252941131592,
        0.05273810774087906,
        -0.006460830103605986,
        -0.04833703115582466,
        -0.05386566370725632,
        -0.026705564931035042,
        -0.05065162852406502,
        -0.05492902174592018,
        0.0124183539301157,
        -0.05027968809008598,
        -0.05488213151693344,
        0.013934513553977013,
        -0.05458749085664749,
        -0.0498025044798851,
        -0.05452803894877434,
        -0.02835681475698948,
        0.0550505667924881,
        0.046130307018756866,
        -0.0387103371322155,
        0.00773696368560195,
        0.05370389297604561,
        -0.03321487456560135,
        0.04981507360935211,
        0.0524519681930542,
        -0.021101931110024452,
        0.05339895188808441,
        0.048712197691202164,
        -0.0057437969371676445,
        -0.05505090951919556,
        -0.04224221780896187,
        0.040667496621608734,
        -0.020774489268660545,
        -0.04160555079579353,
        0.03134506195783615,
        0.04613076522946358,
        -0.05385442078113556,
        0.05451580137014389,
        0.033346809446811676,
        0.05204043909907341,
        0.05491764098405838,
        -0.02321411482989788,
        0.054897621273994446,
        -0.053309425711631775,
        -0.035340480506420135,
        0.043827638030052185,
        0.050947632640600204,
        0.04983018338680267,
        0.04842368885874748,
        -0.04723617807030678,
        0.0517343170940876,
        -0.029886657372117043,
        0.05496650189161301,
        -0.002676617819815874,
        -0.053164947777986526,
        -0.04364345967769623,
        0.041919246315956116,
        0.0516580194234848,
        0.03896547481417656,
        -0.05159071832895279,
        0.026049014180898666,
        0.050231121480464935,
        0.05194735527038574,
        -0.05069117248058319,
        -0.04450162872672081,
        -0.052357569336891174,
        -0.05496034398674965,
        0.042513154447078705,
        -0.04871562123298645,
        0.010075647383928299,
        0.04925858601927757,
        -0.026883207261562347,
        0.02391389198601246,
        0.03438100591301918,
        -0.0550491064786911,
        0.05504699423909187,
        -0.035050224512815475,
        0.046706002205610275,
        0.03883576765656471,
        -0.043224040418863297,
        -0.021626485511660576,
        0.05109096318483353,
        -0.03984101861715317,
        0.044352930039167404,
        -0.05451122298836708,
        0.05391737073659897,
        -0.053844086825847626
    ],
    [
        0.028024623170495033,
        -0.044828373938798904,
        0.05333796516060829,
        0.013208931311964989,
        -0.011023744009435177,
        -0.052153926342725754,
        0.053652890026569366,
        -0.05249703302979469,
        0.05127368122339249,
        0.04249746724963188,
        0.051314663141965866,
        -0.04542378708720207,
        -0.05365144833922386,
        -0.053156014531850815,
        -0.05318394675850868,
        -0.05367046967148781,
        -0.035221412777900696,
        0.053665585815906525,
        0.05211877077817917,
        0.03076743893325329,
        0.053671419620513916,
        -0.04727378487586975,
        0.04718935862183571,
        -0.03774268925189972,
        -0.02405232936143875,
        0.018720049411058426,
        -0.053408052772283554,
        -0.05365601181983948,
        0.0527082160115242,
        -0.05300118774175644,
        0.0044322689063847065,
        0.05355284363031387,
        0.017962994053959846,
        -0.05330914258956909,
        0.04707428440451622,
        -0.05260344222187996,
        -0.05342162027955055,
        -0.04464978352189064,
        -0.0011574068339541554,
        -0.051706988364458084,
        0.02863764576613903,
        0.03877079114317894,
        0.05130697414278984,
        0.020637916401028633,
        -0.04592924937605858,
        -0.0403667688369751,
        0.024051383137702942,
        0.05353732034564018,
        0.05361633375287056,
        0.053013626486063004,
        0.05254171043634415,
        -0.0494079664349556,
        -0.01549005787819624,
        -0.05276703089475632,
        -0.020013101398944855,
        -0.05366979539394379,
        -0.014676261693239212,
        0.0326053649187088,
        -0.041685838252305984,
        -0.05367099866271019,
        -0.05329595506191254,
        0.022288000211119652,
        -0.05291855335235596,
        0.05206332728266716,
        0.04454329237341881,
        -0.030483007431030273,
        -0.05097854882478714,
        0.052919305860996246,
        0.048192866146564484,
        -0.05351915583014488,
        -0.040368322283029556,
        -0.04054800420999527,
        0.053405992686748505,
        0.053556427359580994,
        0.051955919712781906,
        0.006934354081749916,
        0.004620459862053394,
        0.05366750434041023,
        -0.016202621161937714,
        0.04123911261558533,
        -0.043313078582286835,
        -0.04326235130429268,
        -0.05365331098437309,
        0.04818316176533699,
        -0.05359663814306259,
        0.03412335738539696,
        -0.05292521044611931,
        -0.0533827506005764,
        -0.033124394714832306,
        0.053354766219854355,
        -0.04964504390954971,
        -0.04880860075354576,
        -0.05367233231663704,
        -0.038966238498687744,
        -0.028157774358987808,
        -0.03893924131989479,
        -0.017155710607767105,
        -0.018239492550492287,
        -0.05233878269791603,
        0.051843345165252686,
        -0.05366068333387375,
        0.050483666360378265,
        0.039944570511579514,
        -0.050190094858407974,
        -0.053224965929985046,
        0.01554510835558176,
        0.053637973964214325,
        0.03018433414399624,
        -0.05297674611210823,
        0.031841982156038284,
        0.0484074167907238,
        0.041625332087278366,
        0.01014795247465372,
        0.0030165978241711855,
        0.053444817662239075,
        -0.04732780531048775,
        -0.05329358950257301,
        -0.05237329751253128,
        0.052479349076747894,
        -0.0423101931810379,
        -0.047927647829055786,
        0.027990417554974556,
        -0.038901351392269135,
        0.01602006144821644,
        -0.05259132385253906,
        0.011145301163196564,
        -0.006846111733466387,
        0.027788754552602768,
        0.05231383815407753,
        0.024765174835920334,
        0.023877108469605446,
        -0.05268779769539833,
        0.03848535940051079,
        0.028288429602980614,
        -0.047883566468954086,
        -0.052504442632198334,
        0.05365898087620735,
        0.0456300750374794,
        0.024144703522324562,
        0.05364445969462395,
        0.053672198206186295,
        -0.05366531386971474,
        0.05198954790830612,
        -0.053670767694711685,
        -0.05361538007855415,
        0.050571661442518234,
        -0.05293193459510803,
        0.05367230623960495,
        -0.03026401437819004,
        -0.0341285765171051,
        0.053672339767217636,
        -0.05367172509431839,
        -0.05357421934604645,
        0.04866015911102295,
        0.053672417998313904,
        -0.053234830498695374,
        -0.05366842448711395,
        0.005844574887305498,
        0.0524839349091053,
        0.04704073444008827,
        -0.020053885877132416,
        0.05130298435688019,
        0.05262121558189392,
        0.0522763654589653,
        0.04828660562634468,
        0.04471328854560852,
        -0.02457864210009575,
        -0.05180245265364647,
        -0.0519380196928978,
        0.02799917384982109,
        -0.052451398223638535,
        0.04767949879169464,
        -0.05261315032839775,
        -0.05136021226644516,
        -0.04022073373198509,
        0.006869548466056585,
        0.02427019737660885,
        0.0064061288721859455,
        0.05280120670795441,
        -0.046521492302417755,
        -0.05168591067194939,
        0.05367053300142288,
        0.007401262875646353,
        0.053652919828891754,
        -0.030212217941880226,
        0.0527133047580719,
        -0.04062150418758392,
        0.05345550924539566,
        0.05365048348903656,
        -0.05315127223730087,
        -0.01779950223863125,
        -0.031063713133335114,
        -0.0535896010696888,
        0.036059629172086716,
        -0.05349632352590561,
        -0.05282090976834297,
        0.0052657038904726505,
        0.04046740010380745,
        -0.006595233920961618,
        0.020063204690814018,
        0.053654745221138,
        0.04542311280965805,
        -0.0131755992770195,
        -0.006106093060225248,
        -0.01966971345245838,
        0.049212925136089325,
        0.05331234261393547,
        0.05350155755877495,
        -0.03914105147123337,
        0.052502527832984924,
        -0.05067342519760132,
        -0.040983427315950394,
        -0.05297014117240906,
        0.053587909787893295,
        -0.05366162210702896,
        -0.04281982034444809,
        0.05364026874303818,
        -0.04589405655860901,
        0.053669948130846024,
        -0.049294549971818924,
        -0.05322730541229248,
        -0.028353633359074593,
        0.05029686912894249,
        -0.05203428491950035,
        0.05363696068525314,
        -0.05359821021556854,
        0.053443074226379395,
        -0.051503896713256836,
        0.03784623369574547,
        -0.03125644847750664,
        -0.050511498004198074,
        -0.048075709491968155,
        0.05292971432209015,
        0.053120680153369904,
        -0.049488987773656845,
        0.05131025239825249,
        0.0532819889485836,
        -0.040347035974264145,
        -0.026040157303214073,
        0.05366772040724754,
        -0.008888006210327148,
        -0.015199631452560425,
        -0.053388502448797226,
        -0.05152318626642227,
        -0.035816289484500885,
        -0.03420599177479744,
        -0.04449903964996338,
        0.015818236395716667,
        -0.0526263602077961,
        0.03263706713914871,
        0.03391960263252258,
        -0.053166721016168594,
        -0.053498610854148865,
        -0.05276753008365631,
        0.05062047019600868,
        -0.052396710962057114,
        -0.04619959741830826,
        -0.017633099108934402,
        0.050600942224264145,
        0.05367203801870346,
        -0.023787176236510277,
        -0.041784826666116714,
        -0.02429983764886856,
        0.05188555270433426,
        -0.01962926797568798,
        0.050589337944984436,
        -0.028440838679671288,
        0.0397912971675396,
        -0.05328841507434845,
        0.05290045216679573,
        0.05321195721626282,
        -0.005043153651058674,
        0.05366373062133789,
        -0.053132496774196625,
        -0.05363340303301811,
        0.043773382902145386,
        -0.05326336994767189,
        0.05366075783967972,
        0.047082461416721344,
        0.0071863881312310696,
        -0.05273706465959549,
        0.0120485108345747,
        -0.046670835465192795,
        -0.03691066429018974,
        -0.03664596751332283,
        -0.0033270122949033976,
        0.04876769334077835,
        0.053661830723285675,
        -0.04166471213102341,
        -0.010039666667580605,
        -0.05316616967320442,
        0.008538522757589817,
        -0.01723620295524597,
        0.05132807046175003,
        0.053650014102458954,
        -0.047392766922712326,
        0.050922561436891556,
        0.03888363391160965,
        0.053137779235839844,
        0.053106680512428284,
        -0.053356800228357315,
        0.030026277527213097,
        0.016325607895851135,
        0.053630366921424866,
        0.03972173109650612,
        -0.052542753517627716,
        -0.0217672698199749,
        0.022263113409280777,
        0.053383778780698776,
        0.016192244365811348,
        -0.04024939611554146,
        -0.05336756631731987,
        -0.020156703889369965,
        0.05188390612602234,
        -0.015101137571036816,
        -0.04950754716992378,
        -0.0379628948867321,
        -0.053589049726724625,
        0.05365418642759323,
        0.01912710629403591,
        0.05367077887058258,
        -0.046968013048172,
        0.05351097881793976,
        0.04458305984735489,
        0.05255114659667015,
        0.05351095646619797,
        0.05360404774546623,
        -0.053069498389959335,
        -0.027111582458019257,
        -0.049956321716308594,
        0.05330671742558479,
        -0.01609463430941105,
        -0.053625356405973434,
        0.04849136993288994,
        0.04866061359643936,
        0.026043297722935677,
        -0.05323566868901253,
        -0.039230573922395706,
        0.009497053921222687,
        0.006437715142965317,
        -0.012461313977837563,
        -0.04688318073749542,
        0.053670190274715424,
        0.04777996242046356,
        -0.0455133356153965,
        -0.005096490029245615,
        0.05298532173037529,
        0.05341484770178795,
        0.05353901535272598,
        0.04407310113310814,
        0.053549062460660934,
        0.04390114173293114,
        0.0004444350488483906,
        -0.05284733325242996,
        -0.05344964563846588,
        0.019386624917387962,
        -0.029608529061079025,
        -0.05255499109625816,
        -0.053640175610780716,
        -0.031550332903862,
        0.046957746148109436,
        0.05315735563635826,
        0.04843972995877266,
        -0.04166612774133682,
        -0.053669191896915436,
        -0.05363680049777031,
        0.05361941084265709,
        0.02763696201145649,
        -0.05227437615394592,
        -0.05258805304765701,
        -0.020955553278326988,
        -0.04925673454999924,
        -0.04766536131501198,
        -0.05367008596658707,
        0.053613461554050446,
        0.05359381437301636,
        0.03163649141788483,
        -0.014104774221777916,
        0.05333684757351875,
        -0.04404031112790108,
        0.002504528034478426,
        0.040638457983732224,
        -0.0496833436191082,
        0.036994051188230515,
        0.021787062287330627,
        -0.05365430936217308,
        0.05366133153438568,
        -0.018800420686602592,
        -0.038189880549907684,
        -0.044766973704099655,
        -0.053665753453969955,
        0.050118133425712585,
        -0.05335549637675285,
        0.01856541447341442,
        0.04467097297310829,
        0.0528329499065876,
        0.047034673392772675,
        -0.04801690950989723,
        0.01613543927669525,
        -0.0528501495718956,
        -0.03448271006345749,
        0.05358784645795822,
        0.053651999682188034,
        0.053465574979782104,
        -0.05366980656981468,
        0.048568785190582275,
        0.009635364636778831,
        -0.044131502509117126,
        0.05340917408466339,
        -0.04065351188182831,
        -0.01698565110564232,
        -0.05365888401865959,
        0.005094641353935003,
        -0.03808913752436638,
        0.053307823836803436,
        -0.04194848611950874,
        0.01235297229140997,
        0.05303752049803734,
        0.05366573482751846,
        0.04265762120485306,
        0.05364256724715233,
        -0.051994577050209045,
        -0.049916598945856094,
        -0.05365602672100067,
        -0.018780479207634926,
        -0.05026848614215851,
        0.010393562726676464,
        0.03092443197965622,
        -0.044772494584321976,
        -0.05364203453063965,
        0.010549083352088928,
        0.04946579039096832,
        -0.044903844594955444,
        -0.05350975692272186,
        -0.05003944784402847,
        -0.05075223371386528,
        -0.05340143293142319,
        -0.014737054705619812,
        0.0035088378936052322,
        -0.05203547328710556,
        -0.05364268273115158,
        -0.022815927863121033,
        0.053672417998313904,
        0.04836834967136383,
        -0.04136863350868225,
        0.0473519042134285,
        0.052471596747636795,
        -0.0506160594522953,
        -0.00029963802080601454,
        0.05257612094283104,
        -0.026058780029416084,
        0.05182533711194992,
        0.04877215623855591,
        0.042223602533340454,
        -0.053638845682144165,
        -0.04944301024079323,
        0.02651165798306465,
        -0.040926139801740646,
        -0.034180838614702225,
        0.03906247764825821,
        0.015282658860087395,
        -0.04414092376828194,
        0.052815526723861694,
        0.029554512351751328,
        0.05359587073326111,
        0.05361063405871391,
        -0.05341557413339615,
        0.05367203429341316,
        0.023959051817655563,
        -0.051607806235551834,
        0.05254751443862915,
        0.05348717421293259,
        0.05344133824110031,
        0.053149498999118805,
        -0.053237225860357285,
        0.031845420598983765,
        0.04232270270586014,
        0.05339987948536873,
        -0.052518993616104126,
        -0.04754099249839783,
        -0.048961199820041656,
        0.0349605493247509,
        0.016142606735229492,
        -0.012621847912669182,
        -0.04984462633728981,
        0.033683858811855316,
        -0.049115147441625595,
        0.03204403072595596,
        -0.053558431565761566,
        -0.05017906054854393,
        -0.05100336670875549,
        -0.02677723951637745,
        -0.047484662383794785,
        0.045171089470386505,
        -0.035605933517217636,
        -0.010524755343794823,
        -0.05349833145737648,
        0.021036788821220398,
        0.05050714686512947,
        -0.05337519571185112,
        0.053662847727537155,
        -0.04971446841955185,
        0.005622578784823418,
        0.04889414831995964,
        0.030857106670737267,
        0.052726637572050095,
        0.029154540970921516,
        -0.05014708265662193,
        0.034135136753320694,
        0.05318635702133179,
        0.05347427353262901,
        -0.045264340937137604
    ],
    [
        -0.022996634244918823,
        -0.05886273458600044,
        0.030192896723747253,
        0.006117687560617924,
        0.04533328488469124,
        -0.05711936950683594,
        0.059416513890028,
        -0.05285065248608589,
        0.019439026713371277,
        0.049753814935684204,
        0.04733401909470558,
        -0.03080986812710762,
        -0.049767494201660156,
        -0.008001694455742836,
        -0.05863460153341293,
        -0.058875273913145065,
        -0.05751189589500427,
        0.0587565042078495,
        -0.04852490872144699,
        0.04892370477318764,
        -0.030289866030216217,
        -0.05663770064711571,
        0.05608208104968071,
        -0.05740100517868996,
        -0.03476216644048691,
        0.03327536582946777,
        0.019624533131718636,
        -0.01393091306090355,
        0.029809122905135155,
        -0.05663575604557991,
        0.04816439375281334,
        0.015060762874782085,
        0.05210503190755844,
        -0.05832803249359131,
        0.03185531497001648,
        -0.016333092004060745,
        -0.05184303596615791,
        -0.046336621046066284,
        -0.059384334832429886,
        0.009579223580658436,
        -0.01897740736603737,
        0.012572821229696274,
        0.05102446675300598,
        -0.05675340071320534,
        0.04937756061553955,
        0.03675379976630211,
        0.04345615208148956,
        0.04999940097332001,
        0.05929254740476608,
        0.02873222716152668,
        0.05679691955447197,
        -0.007144325878471136,
        0.03028082475066185,
        0.0018931400263682008,
        -0.05257487669587135,
        -0.05664905160665512,
        -0.059189457446336746,
        0.027251217514276505,
        -0.027682775631546974,
        -0.057689741253852844,
        -0.050223372876644135,
        0.0105122160166502,
        -0.04821343719959259,
        0.036687467247247696,
        -0.017433779314160347,
        0.0387035496532917,
        -0.007404242642223835,
        0.018470026552677155,
        0.03733241930603981,
        -0.05944477394223213,
        -0.04537007585167885,
        -0.05906089395284653,
        0.056963421404361725,
        0.03558748960494995,
        -0.020647043362259865,
        -0.01739691197872162,
        -0.04576185718178749,
        0.058898866176605225,
        -0.02900516986846924,
        -0.041842300444841385,
        -0.03400252014398575,
        0.019518502056598663,
        -0.05874666944146156,
        0.04854384809732437,
        0.03483409062027931,
        0.04679900035262108,
        -0.009169144555926323,
        -0.008158721029758453,
        0.041204795241355896,
        0.057825591415166855,
        -0.039576828479766846,
        0.05710127204656601,
        -0.05908317118883133,
        -0.05675280839204788,
        -0.0034346659667789936,
        -0.043948255479335785,
        -0.04292482137680054,
        -0.05891990661621094,
        -0.05918535217642784,
        -0.03093668632209301,
        -0.05831022560596466,
        0.021573808044195175,
        0.05105796083807945,
        -0.013109743595123291,
        -0.0361531600356102,
        -0.016033751890063286,
        0.04922466725111008,
        0.05361271649599075,
        -0.0537528432905674,
        0.04450640827417374,
        0.0034397179260849953,
        -0.05454554036259651,
        -0.03225550055503845,
        0.017253831028938293,
        0.04905514791607857,
        -0.030140290036797523,
        -0.05455813184380531,
        -0.04898034408688545,
        -0.05343892052769661,
        0.033897917717695236,
        -0.02114308811724186,
        0.0175735242664814,
        -0.028290193527936935,
        0.015516231767833233,
        -0.058923158794641495,
        0.02466486766934395,
        0.01941642351448536,
        0.032025162130594254,
        0.05507860705256462,
        -0.006528682075440884,
        0.051858894526958466,
        -0.05428889021277428,
        0.004945474676787853,
        0.02092978172004223,
        -0.0474633127450943,
        -0.04749573394656181,
        0.055853597819805145,
        -0.0548752062022686,
        -0.03938697278499603,
        0.05935090407729149,
        0.059396084398031235,
        -0.0565720833837986,
        0.041963398456573486,
        -0.05889395996928215,
        -0.02314901165664196,
        0.05206448212265968,
        -0.020823849365115166,
        0.05536719039082527,
        -0.05794405937194824,
        0.0385003462433815,
        0.05121128633618355,
        -0.05817977339029312,
        -0.0559595562517643,
        0.011374145746231079,
        0.05933156609535217,
        -0.05294626206159592,
        0.03357246518135071,
        -0.025746362283825874,
        -0.032128605991601944,
        0.04404063895344734,
        0.05513382703065872,
        0.047061946243047714,
        0.03761942684650421,
        -0.03128119185566902,
        0.03883516788482666,
        0.019996166229248047,
        -0.016533220186829567,
        -0.058985549956560135,
        -0.044420044869184494,
        0.04307594522833824,
        -0.03633788973093033,
        0.01127168070524931,
        -0.053047917783260345,
        -0.015582357533276081,
        -0.05402739346027374,
        0.051290422677993774,
        -0.059152424335479736,
        -0.039476100355386734,
        0.0562058761715889,
        0.02861310914158821,
        -0.03296063095331192,
        0.05925406143069267,
        0.04836243391036987,
        0.05862594023346901,
        0.015881886705756187,
        0.05000868812203407,
        -0.05305138975381851,
        0.05567046254873276,
        0.058552198112010956,
        -0.05832308530807495,
        0.04577302932739258,
        -0.03189617022871971,
        -0.04070087522268295,
        -0.056526679545640945,
        -0.03068636730313301,
        -0.04702240973711014,
        0.017682235687971115,
        0.04645577818155289,
        -0.05523507297039032,
        0.05005541443824768,
        0.033119384199380875,
        0.0480865053832531,
        0.03690306469798088,
        0.053881458938121796,
        0.0009717276552692056,
        -0.014669615775346756,
        0.05135245993733406,
        0.05389995500445366,
        -0.051045358180999756,
        0.008633514866232872,
        -0.05396430194377899,
        0.04768974334001541,
        -0.032799895852804184,
        0.020536743104457855,
        0.04019615426659584,
        -0.02790716476738453,
        0.05836709216237068,
        0.005068490281701088,
        0.059424176812171936,
        0.04933511093258858,
        0.040786657482385635,
        -0.05933611840009689,
        -0.05171844735741615,
        -0.059251222759485245,
        0.05819578841328621,
        -0.0590880885720253,
        0.0398155078291893,
        0.04565446823835373,
        -0.0013895269948989153,
        -0.05756372958421707,
        0.035024493932724,
        -0.054902464151382446,
        0.019138000905513763,
        0.05471871793270111,
        -0.02574027143418789,
        -0.01832369528710842,
        0.05784705653786659,
        0.040790073573589325,
        -0.03751378133893013,
        0.05927395820617676,
        0.021958155557513237,
        0.05517640337347984,
        -0.057350512593984604,
        -0.028697991743683815,
        -0.017703674733638763,
        -0.030925467610359192,
        0.01658056117594242,
        0.021969519555568695,
        -0.0338699072599411,
        -0.05239304527640343,
        0.022162122651934624,
        -0.05372181162238121,
        -0.05877568945288658,
        -0.05388200283050537,
        0.00040761876152828336,
        0.03565433248877525,
        -0.02931414358317852,
        -0.05463514104485512,
        0.056351397186517715,
        0.05945335328578949,
        -0.013823353685438633,
        0.05274844169616699,
        0.0500204972922802,
        0.054675839841365814,
        -0.04770071804523468,
        0.028606370091438293,
        -0.0015495334519073367,
        0.04207448661327362,
        -0.04200147092342377,
        -0.05038364231586456,
        0.052329305559396744,
        -0.05936138704419136,
        0.058118000626564026,
        -0.05756562203168869,
        -0.04498392343521118,
        0.04736373573541641,
        -0.054968129843473434,
        0.05868222191929817,
        0.0371856652200222,
        -0.004626253619790077,
        -0.05705166980624199,
        -0.02708047442138195,
        -0.001092257327400148,
        -0.005608655977994204,
        0.012296181172132492,
        -0.057245124131441116,
        0.025354042649269104,
        -0.04688648506999016,
        -0.013319224119186401,
        -0.019050663337111473,
        -0.0391007699072361,
        0.014136984013020992,
        -0.04792501777410507,
        0.042004071176052094,
        0.0505705289542675,
        -0.04823357239365578,
        0.058949124068021774,
        0.02425231970846653,
        0.03242005407810211,
        0.05426334589719772,
        -0.04777906462550163,
        0.049439337104558945,
        0.020440440624952316,
        0.04635697230696678,
        -0.05619834363460541,
        -0.023410320281982422,
        -0.04569483548402786,
        0.020025623962283134,
        0.05759308487176895,
        -0.054081544280052185,
        0.03403429687023163,
        -0.05451265722513199,
        -0.04392324388027191,
        0.05590060353279114,
        -0.05911564081907272,
        -0.05276042968034744,
        0.04690059274435043,
        -0.026992490515112877,
        0.04506995528936386,
        -0.0574118047952652,
        -0.03568143770098686,
        0.006917686201632023,
        0.05921377241611481,
        -0.038119927048683167,
        0.05606301501393318,
        0.05776043236255646,
        0.051518987864255905,
        -0.05539112538099289,
        0.03531956300139427,
        -0.03999938815832138,
        -0.04371146857738495,
        0.05585973337292671,
        0.009944571182131767,
        -0.04558809846639633,
        0.054247479885816574,
        0.04983553662896156,
        -0.04576621949672699,
        0.04961317405104637,
        -0.03411302715539932,
        -0.013719352893531322,
        -0.011346545070409775,
        -0.030515901744365692,
        0.046090900897979736,
        0.016079172492027283,
        0.05945342406630516,
        0.027355918660759926,
        0.004968531895428896,
        0.04740767925977707,
        0.05509968101978302,
        0.03918401524424553,
        -0.05938717722892761,
        0.05344180762767792,
        -0.0583120621740818,
        -0.05854437127709389,
        -0.04877703636884689,
        0.04708878695964813,
        -0.056165728718042374,
        -0.0326913520693779,
        -0.05464516207575798,
        -0.05885301157832146,
        0.05688396096229553,
        0.027927881106734276,
        -0.014714701101183891,
        -0.004610435105860233,
        -0.021547770127654076,
        -0.03543524444103241,
        0.059372205287218094,
        -0.023343529552221298,
        0.05724741518497467,
        -0.058821797370910645,
        -0.05219248682260513,
        -0.05364952236413956,
        -0.004746004473417997,
        -0.05681370571255684,
        0.056856848299503326,
        0.041896309703588486,
        -0.048859819769859314,
        -0.02436559647321701,
        -0.04420814290642738,
        -0.051754966378211975,
        -0.02415286935865879,
        0.0131203792989254,
        -0.05774237960577011,
        0.045257993042469025,
        0.03510356694459915,
        -0.04869002848863602,
        0.057225629687309265,
        -0.051992740482091904,
        -0.0555572435259819,
        -0.056336164474487305,
        -0.05925209820270538,
        0.006187517661601305,
        -0.019761592149734497,
        0.056213632225990295,
        0.028067870065569878,
        0.05327139422297478,
        0.05706412345170975,
        -0.03907393291592598,
        0.023698262870311737,
        -0.05927580967545509,
        -0.05735286325216293,
        0.05599204823374748,
        0.05577101558446884,
        0.05933422967791557,
        -0.05794451758265495,
        0.02467806451022625,
        -0.0012595143634825945,
        0.028712261468172073,
        0.05918838083744049,
        -0.05021113529801369,
        0.052519265562295914,
        -0.018363142386078835,
        0.035972949117422104,
        0.025889648124575615,
        0.0520126111805439,
        0.005829984322190285,
        0.028342880308628082,
        0.03403502702713013,
        0.059202924370765686,
        -0.05012555792927742,
        -0.05666713789105415,
        -0.05740068852901459,
        -0.04156482219696045,
        -0.05724545568227768,
        0.01637130416929722,
        -0.02960265427827835,
        -0.051079027354717255,
        -0.04189819097518921,
        -0.049759216606616974,
        -0.015390947461128235,
        -0.007557413075119257,
        0.004796246998012066,
        -0.058592021465301514,
        -0.05709265172481537,
        -0.047385189682245255,
        -0.05142102390527725,
        -0.05853858217597008,
        0.02985764853656292,
        -0.03428560867905617,
        -0.05272619426250458,
        -0.054967984557151794,
        -0.025915049016475677,
        0.058656759560108185,
        0.05604391545057297,
        0.058228183537721634,
        0.04596533253788948,
        0.05316925793886185,
        -0.05692115053534508,
        0.05407240614295006,
        0.010705617256462574,
        -0.011994576081633568,
        0.05296769738197327,
        0.05537179484963417,
        -0.004990183748304844,
        -0.05733166262507439,
        -0.05523000285029411,
        0.04683257266879082,
        0.04524633660912514,
        -0.04280262812972069,
        0.05803044140338898,
        0.025341829285025597,
        -0.056503042578697205,
        0.0007214469369500875,
        -0.04315624758601189,
        0.04927290976047516,
        0.059279512614011765,
        -0.056512072682380676,
        0.059130143374204636,
        -0.018423661589622498,
        -0.05892220884561539,
        0.012583189643919468,
        0.057899296283721924,
        0.025221072137355804,
        0.05125785246491432,
        0.00817884411662817,
        0.04653241112828255,
        0.02412325143814087,
        0.05898617208003998,
        -0.03687954321503639,
        -0.0511615164577961,
        -0.05768034607172012,
        0.037094246596097946,
        0.05324875935912132,
        0.04864215478301048,
        -0.015546884387731552,
        0.052320800721645355,
        0.024862095713615417,
        0.023937031626701355,
        -0.05456282198429108,
        0.049204081296920776,
        -0.04718218743801117,
        -0.058906495571136475,
        -0.03296703100204468,
        0.014595063403248787,
        0.05781122297048569,
        -0.030261564999818802,
        -0.059058260172605515,
        0.05818510055541992,
        0.05636059492826462,
        0.005451078061014414,
        0.05858103930950165,
        -0.053903382271528244,
        0.043693121522665024,
        -0.025807511061429977,
        0.04025787115097046,
        0.034092601388692856,
        -0.010418141260743141,
        0.0023139785043895245,
        -0.042982518672943115,
        -0.04411128908395767,
        0.058428552001714706,
        0.04317735508084297
    ],
    [
        -0.006775484420359135,
        -0.022987425327301025,
        -0.039403773844242096,
        -0.030640123412013054,
        -0.04477871209383011,
        -0.054271094501018524,
        0.04393010586500168,
        -0.05763193219900131,
        0.03578895330429077,
        0.010046863928437233,
        -0.00978041347116232,
        -0.05256108194589615,
        -0.05663129314780235,
        -0.05506155639886856,
        -0.056921083480119705,
        0.0021487006451934576,
        0.04381965100765228,
        0.05730819329619408,
        0.05555437132716179,
        0.05036924406886101,
        0.05700911581516266,
        0.0008639400475658476,
        -0.025755377486348152,
        -0.025903161615133286,
        0.02206091210246086,
        0.05644512549042702,
        -0.025317003950476646,
        -0.005044462159276009,
        0.044896241277456284,
        -0.04645529016852379,
        0.05255739763379097,
        -0.05328507348895073,
        0.05564034730195999,
        -0.05683830752968788,
        0.0513574592769146,
        -0.05518478900194168,
        -0.05712541937828064,
        0.004279769491404295,
        0.056771185249090195,
        -0.012610076926648617,
        -0.0569019541144371,
        -0.009335502050817013,
        0.05270205810666084,
        -0.054662905633449554,
        0.04090605676174164,
        0.049537643790245056,
        0.0525643527507782,
        0.056609801948070526,
        0.057099416851997375,
        0.052206747233867645,
        0.05369343236088753,
        0.031034698709845543,
        -0.005727772600948811,
        -0.05604187771677971,
        0.05005095899105072,
        -0.05749223381280899,
        -0.05369873344898224,
        0.03781101480126381,
        -0.042263954877853394,
        -0.057563163340091705,
        -0.05717211216688156,
        -0.02550431154668331,
        -0.05640969052910805,
        0.05612782761454582,
        0.036212094128131866,
        0.026799771934747696,
        -0.04230557009577751,
        -0.05596524477005005,
        -0.011364208534359932,
        0.016652463003993034,
        0.04661976173520088,
        -0.055591534823179245,
        -0.006322227884083986,
        -0.03721960633993149,
        0.030992677435278893,
        -0.05063251778483391,
        0.05550714582204819,
        0.05764296278357506,
        0.05395820364356041,
        0.04529620707035065,
        -0.029881758615374565,
        -0.048156991600990295,
        -0.04947473108768463,
        0.02484610117971897,
        0.01240861602127552,
        0.05090352147817612,
        -0.05620436742901802,
        -0.05664212256669998,
        -0.05609685555100441,
        0.04972369968891144,
        -0.022480422630906105,
        -0.031096141785383224,
        -0.05759304761886597,
        0.013682661578059196,
        -0.04015268757939339,
        0.054562341421842575,
        -0.001062389463186264,
        -0.0548148937523365,
        0.042757462710142136,
        0.054242972284555435,
        -0.057589102536439896,
        0.053112100809812546,
        0.006708690430969,
        0.035043757408857346,
        -0.05742070451378822,
        0.006279114168137312,
        0.054400503635406494,
        0.05465639382600784,
        -0.04357215389609337,
        0.02744918130338192,
        0.04845905303955078,
        -0.04898752644658089,
        -0.05036313831806183,
        0.05397580936551094,
        0.023855295032262802,
        0.05575764179229736,
        -0.01160871796309948,
        -0.05663583427667618,
        0.0055710566230118275,
        -0.01494245883077383,
        -0.04791949316859245,
        0.02183934673666954,
        -0.000007876151357777417,
        0.04061569273471832,
        -0.05104325711727142,
        -0.04151766002178192,
        0.04510900378227234,
        0.02269517071545124,
        0.0541413314640522,
        0.05631024390459061,
        0.012477193027734756,
        -0.04239437356591225,
        -0.019593235105276108,
        0.05003727599978447,
        -0.043268974870443344,
        -0.05596303194761276,
        0.05762935057282448,
        0.025257524102926254,
        -0.03751959651708603,
        -0.00022542249644175172,
        0.05300205945968628,
        -0.05750414729118347,
        0.05232873558998108,
        -0.05751757696270943,
        0.004750435706228018,
        -0.013715886510908604,
        -0.05561338737607002,
        0.05738454312086105,
        0.03561850264668465,
        -0.05644415318965912,
        0.057337842881679535,
        -0.05691080912947655,
        -0.03952153027057648,
        -0.05646974593400955,
        0.05411335080862045,
        -0.05735259875655174,
        0.009031538851559162,
        -0.026832789182662964,
        0.05724942311644554,
        0.0299687422811985,
        -0.020024746656417847,
        0.02055835723876953,
        0.05278712511062622,
        0.05687982961535454,
        0.05750710517168045,
        0.04951157420873642,
        0.04370841383934021,
        0.013995873741805553,
        -0.01910238154232502,
        0.029817404225468636,
        -0.045426271855831146,
        0.018600821495056152,
        -0.05612283945083618,
        -0.024771559983491898,
        -0.03978259488940239,
        0.05273829400539398,
        -0.006174142938107252,
        -0.020734397694468498,
        0.05267069488763809,
        -0.05582010746002197,
        -0.020894821733236313,
        0.0576142854988575,
        0.03220517933368683,
        0.019898168742656708,
        0.04273771122097969,
        -0.021433025598526,
        0.05652650073170662,
        0.05462869256734848,
        0.05677828565239906,
        -0.05661393329501152,
        -0.041376881301403046,
        0.03926490247249603,
        -0.049968086183071136,
        0.050054244697093964,
        -0.05666268244385719,
        -0.0525631457567215,
        -0.010041093453764915,
        -0.03662732616066933,
        -0.05455244705080986,
        0.045098572969436646,
        0.04987996071577072,
        0.024107644334435463,
        0.05319121107459068,
        -0.010500951670110226,
        0.05105799809098244,
        -0.05316255986690521,
        0.05165053531527519,
        0.014336060732603073,
        -0.052138496190309525,
        0.054128214716911316,
        -0.05392174422740936,
        0.030238408595323563,
        -0.051375728100538254,
        0.04036369547247887,
        0.056132517755031586,
        0.008869582787156105,
        0.051337823271751404,
        -0.05284356698393822,
        0.056892961263656616,
        0.057253189384937286,
        0.031627487391233444,
        -0.033659689128398895,
        -0.04674213007092476,
        0.002803616924211383,
        0.05755070224404335,
        -0.05711992457509041,
        0.007511439733207226,
        0.0037884870544075966,
        0.052659787237644196,
        -0.02424292080104351,
        -0.03232557699084282,
        -0.047439832240343094,
        0.05450849235057831,
        0.05645923689007759,
        -0.0521860271692276,
        0.054835058748722076,
        0.05492641031742096,
        0.0010874029248952866,
        -0.05449932441115379,
        0.0575186051428318,
        -0.054454296827316284,
        0.052726034075021744,
        -0.05644701048731804,
        0.03719930350780487,
        0.009365175850689411,
        -0.05313723534345627,
        -0.017765654250979424,
        0.05118068680167198,
        -0.04590008035302162,
        -0.04547080025076866,
        -0.05069965869188309,
        -0.05719482898712158,
        -0.028074169531464577,
        -0.05621067062020302,
        0.02473467029631138,
        -0.033347517251968384,
        -0.05100852996110916,
        -0.056453898549079895,
        0.04808593913912773,
        0.0575023889541626,
        -0.0516396090388298,
        0.04664791375398636,
        -0.04425227269530296,
        0.04292317479848862,
        0.05526106804609299,
        0.056108176708221436,
        0.026096589863300323,
        0.049218688160181046,
        -0.05493084713816643,
        -0.05669137462973595,
        0.05575363710522652,
        -0.03992631658911705,
        0.0565122626721859,
        -0.055470578372478485,
        0.019105302169919014,
        0.05215197056531906,
        -0.05062653869390488,
        0.050372906029224396,
        0.046678896993398666,
        -0.0221521258354187,
        -0.053702451288700104,
        0.0514972023665905,
        -0.04317657649517059,
        -0.03270792216062546,
        -0.030338812619447708,
        0.033453624695539474,
        0.035524673759937286,
        0.05617484077811241,
        -0.012730240821838379,
        0.016482088714838028,
        -0.056979209184646606,
        -0.0574471652507782,
        -0.05446446314454079,
        0.0275886170566082,
        0.04242255911231041,
        -0.05464624986052513,
        0.05462174862623215,
        0.029796624556183815,
        0.049856867641210556,
        0.05226413160562515,
        -0.05598175525665283,
        -0.0033296102192252874,
        0.03683135285973549,
        0.05644116550683975,
        -0.03958487883210182,
        0.021402966231107712,
        0.006025916431099176,
        0.05546795576810837,
        0.03146352618932724,
        -0.05413847789168358,
        -0.0429164282977581,
        -0.015315503813326359,
        0.05215268209576607,
        0.029593342915177345,
        0.016771430149674416,
        -0.05653216317296028,
        -0.05014742165803909,
        0.00037953254650346935,
        0.05720305070281029,
        -0.043876390904188156,
        -0.04556018486618996,
        -0.025975802913308144,
        0.03555061295628548,
        0.04208633676171303,
        0.05757206305861473,
        -0.04231832176446915,
        0.011495462618768215,
        -0.05141891911625862,
        -0.025814609602093697,
        0.013350758701562881,
        0.037281688302755356,
        0.006886464077979326,
        -0.01820666529238224,
        -0.03785732388496399,
        0.05383383482694626,
        0.03885599598288536,
        0.04509705677628517,
        -0.045403946191072464,
        0.0006805870216339827,
        0.021282723173499107,
        -0.04173824191093445,
        -0.02345612458884716,
        0.05752652511000633,
        -0.031901631504297256,
        0.023771578446030617,
        0.04556810483336449,
        0.05227429419755936,
        0.055141061544418335,
        0.056801896542310715,
        0.01910950429737568,
        0.05696680024266243,
        0.055578358471393585,
        -0.042268723249435425,
        -0.057191673666238785,
        -0.0524396114051342,
        0.0065009198151528835,
        -0.0008932543569244444,
        -0.05714360252022743,
        -0.04782068729400635,
        -0.05707922577857971,
        0.0502043142914772,
        0.044114816933870316,
        0.05220535770058632,
        -0.024325737729668617,
        0.03917483985424042,
        -0.05399230495095253,
        0.05730220675468445,
        -0.029537785798311234,
        0.04577858746051788,
        -0.05752811208367348,
        -0.05271401256322861,
        -0.05297723785042763,
        0.051606401801109314,
        -0.052698004990816116,
        0.04690610244870186,
        0.02223653718829155,
        0.04183654114603996,
        -0.054601024836301804,
        0.05307544395327568,
        0.015827549621462822,
        0.025687674060463905,
        0.01130669005215168,
        -0.056142088025808334,
        0.01026489119976759,
        0.00978014711290598,
        -0.05491310730576515,
        0.05351059138774872,
        -0.032944995909929276,
        -0.04413030296564102,
        -0.00444480637088418,
        -0.056979767978191376,
        0.04787757247686386,
        -0.04443028196692467,
        -0.045558083802461624,
        0.04951387643814087,
        0.05690606310963631,
        -0.038812413811683655,
        -0.04039429873228073,
        -0.02439255639910698,
        -0.05687451362609863,
        -0.031787432730197906,
        0.057362962514162064,
        0.02423463575541973,
        0.05311224237084389,
        -0.03254939988255501,
        -0.052884213626384735,
        0.03339578956365585,
        -0.041293300688266754,
        0.05760187655687332,
        -0.04272862523794174,
        0.043029747903347015,
        -0.02613632194697857,
        0.028119754046201706,
        0.027955299243330956,
        0.05722109600901604,
        -0.005680576898157597,
        -0.0018333066254854202,
        0.05124147608876228,
        0.057556454092264175,
        0.05680748075246811,
        0.05543851852416992,
        -0.05602826923131943,
        0.055173370987176895,
        -0.05762472003698349,
        0.01968800090253353,
        -0.05230814591050148,
        -0.030845291912555695,
        -0.038031019270420074,
        -0.045403145253658295,
        -0.056568000465631485,
        0.047383055090904236,
        -0.026559168472886086,
        0.03769225999712944,
        -0.05607885494828224,
        0.018430225551128387,
        -0.038009073585271835,
        -0.041283901780843735,
        -0.017140744253993034,
        0.01947391778230667,
        -0.05450303480029106,
        -0.04506654664874077,
        0.030112456530332565,
        0.057529058307409286,
        0.057239457964897156,
        -0.04464634135365486,
        0.0398554690182209,
        0.05632699280977249,
        -0.05717296898365021,
        0.030462000519037247,
        0.05720489099621773,
        -0.029112279415130615,
        0.047182388603687286,
        0.04949912801384926,
        0.041636865586042404,
        -0.039502210915088654,
        -0.05706226825714111,
        -0.04948433116078377,
        -0.055452339351177216,
        0.028483862057328224,
        0.056352343410253525,
        0.05658803507685661,
        -0.015461326576769352,
        0.052902702242136,
        -0.024148469790816307,
        0.05559840425848961,
        0.057331424206495285,
        -0.049546848982572556,
        0.055579181760549545,
        -0.025810597464442253,
        -0.05133781209588051,
        0.04299524053931236,
        -0.002064990811049938,
        0.056308090686798096,
        0.04973950609564781,
        -0.046569548547267914,
        0.01804843544960022,
        -0.04959031194448471,
        0.04239821434020996,
        -0.035051681101322174,
        -0.05717978999018669,
        -0.042072538286447525,
        0.01382222305983305,
        -0.017747247591614723,
        0.011124282144010067,
        -0.04653283953666687,
        0.025834064930677414,
        0.05424739420413971,
        0.026080086827278137,
        -0.05725277215242386,
        -0.0337163545191288,
        -0.03634845092892647,
        -0.05641951039433479,
        0.056780170649290085,
        -0.057234987616539,
        -0.04289277270436287,
        0.03591734543442726,
        -0.051677681505680084,
        -0.03156544640660286,
        0.05296487733721733,
        -0.05228065699338913,
        0.03672648221254349,
        0.03361394256353378,
        -0.03304717689752579,
        -0.05617552995681763,
        -0.05255220830440521,
        0.04189738631248474,
        0.05026211217045784,
        -0.054640933871269226,
        0.05434587597846985,
        -0.0439327098429203,
        0.03427549824118614,
        -0.009677722118794918
    ],
    [
        -0.058209050446748734,
        -0.011148023419082165,
        -0.05210678279399872,
        0.005246996879577637,
        -0.050102606415748596,
        -0.045329682528972626,
        0.05713198333978653,
        -0.05758913606405258,
        0.028100401163101196,
        0.029433147981762886,
        0.05806488171219826,
        0.019876273348927498,
        -0.05855486914515495,
        -0.030218612402677536,
        -0.05779131501913071,
        -0.05074087157845497,
        0.05811190605163574,
        0.05864477902650833,
        0.055203720927238464,
        -0.03699985519051552,
        -0.020262137055397034,
        0.036496978253126144,
        0.018499480560421944,
        -0.04620217904448509,
        -0.03664965555071831,
        0.01938469149172306,
        -0.008388323709368706,
        -0.03888640180230141,
        -0.026385920122265816,
        0.0027232379652559757,
        -0.045144688338041306,
        0.05841938406229019,
        -0.03981352224946022,
        -0.05779331922531128,
        0.05586425960063934,
        0.006580805405974388,
        -0.05216613784432411,
        -0.053617630153894424,
        -0.02994919940829277,
        -0.05535672605037689,
        -0.041888099163770676,
        -0.024443620815873146,
        0.04370860755443573,
        -0.006410784553736448,
        0.05743448808789253,
        -0.049052465707063675,
        -0.05343334376811981,
        0.053042925894260406,
        0.05800585448741913,
        0.05335412546992302,
        0.05589798837900162,
        -0.034452758729457855,
        0.012546899728477001,
        -0.054390184581279755,
        0.03252481296658516,
        -0.012985598295927048,
        -0.05078426003456116,
        0.05038553476333618,
        -0.057510051876306534,
        -0.047469694167375565,
        -0.05865083634853363,
        0.010336179286241531,
        -0.055118635296821594,
        0.031716808676719666,
        0.04399780184030533,
        -0.038631927222013474,
        -0.03631889447569847,
        -0.002807090524584055,
        -0.03569161891937256,
        -0.01576361246407032,
        -0.05034754052758217,
        -0.048747509717941284,
        -0.014046778902411461,
        -0.028755374252796173,
        0.057281237095594406,
        -0.046395160257816315,
        -0.013520183973014355,
        0.058720361441373825,
        0.04189436510205269,
        0.03435010090470314,
        -0.04026464745402336,
        -0.016874434426426888,
        -0.05796201154589653,
        0.055600326508283615,
        -0.04163651913404465,
        0.05704156309366226,
        -0.020867353305220604,
        0.04751322418451309,
        0.018373513594269753,
        0.011751167476177216,
        -0.038739439100027084,
        -0.039872169494628906,
        -0.058480411767959595,
        -0.02430359646677971,
        -0.05506104975938797,
        -0.034025195986032486,
        0.024331582710146904,
        -0.05388078838586807,
        0.006199412047863007,
        0.05498913303017616,
        -0.05868765339255333,
        0.05857469141483307,
        0.047650501132011414,
        -0.004185596946626902,
        -0.055160123854875565,
        -0.04796813055872917,
        0.055999744683504105,
        0.052032310515642166,
        -0.04887670278549194,
        -0.05564318224787712,
        0.051098115742206573,
        -0.056187961250543594,
        0.05125032365322113,
        0.034465231001377106,
        0.0335216298699379,
        0.011850553564727306,
        0.007650128100067377,
        -0.055842991918325424,
        0.03847818449139595,
        -0.0420096218585968,
        -0.05641765892505646,
        0.02853246219456196,
        -0.0069919368252158165,
        -0.020208213478326797,
        -0.05359611287713051,
        -0.05727386102080345,
        0.058222848922014236,
        0.01594899781048298,
        0.03331376612186432,
        0.05458270013332367,
        0.055726367980241776,
        0.014449096284806728,
        0.031723909080028534,
        0.02634979598224163,
        -0.05148475244641304,
        -0.057446569204330444,
        0.05869160592556,
        -0.032037701457738876,
        0.031051160767674446,
        0.04436778277158737,
        0.030151477083563805,
        -0.05335310474038124,
        0.05202915146946907,
        -0.05859615281224251,
        -0.05373472720384598,
        0.014548251405358315,
        -0.03294958174228668,
        0.05749458819627762,
        0.058192599564790726,
        -0.05152932181954384,
        0.05300245061516762,
        -0.05748828500509262,
        -0.014891703613102436,
        -0.004056266508996487,
        0.05566747486591339,
        -0.05768541991710663,
        0.054557546973228455,
        -0.03762201964855194,
        0.02357570268213749,
        0.051891326904296875,
        0.04493660852313042,
        0.05651932209730148,
        0.05174998566508293,
        0.048696525394916534,
        0.008743921294808388,
        -0.020425859838724136,
        0.03550807386636734,
        -0.04787006974220276,
        -0.05366593599319458,
        0.041693296283483505,
        -0.040143705904483795,
        -0.01160886324942112,
        -0.058489322662353516,
        0.050076305866241455,
        -0.01647341251373291,
        0.016744084656238556,
        0.028588131070137024,
        -0.03897659480571747,
        0.05639427900314331,
        -0.058605290949344635,
        -0.05364613234996796,
        0.05739850550889969,
        0.05692249536514282,
        0.04958883300423622,
        0.0111777912825346,
        0.03482327610254288,
        0.01821226440370083,
        0.05679576098918915,
        0.05656283721327782,
        -0.01298360526561737,
        -0.034493692219257355,
        0.05325322225689888,
        -0.05793759971857071,
        0.04307786747813225,
        -0.029725290834903717,
        -0.04264415428042412,
        -0.019792679697275162,
        0.01034991629421711,
        -0.049859628081321716,
        0.028803395107388496,
        0.057902246713638306,
        -0.030319109559059143,
        -0.04715937003493309,
        -0.00863266084343195,
        0.022381609305739403,
        0.036764245480298996,
        -0.02223358117043972,
        0.031189696863293648,
        -0.011526848189532757,
        -0.005422238726168871,
        -0.04508607089519501,
        0.05372898280620575,
        -0.02328525483608246,
        0.05830852687358856,
        -0.05075771361589432,
        -0.057207800447940826,
        0.04667743667960167,
        -0.05730264261364937,
        0.05843213200569153,
        -0.05796749144792557,
        0.016571566462516785,
        0.021613402292132378,
        0.057307589799165726,
        -0.05554767698049545,
        0.05803991109132767,
        -0.056833069771528244,
        0.02537994645535946,
        0.041002050042152405,
        0.028843827545642853,
        -0.04957927018404007,
        -0.030525850132107735,
        -0.037974968552589417,
        -0.02255411073565483,
        0.05799466744065285,
        -0.04110613092780113,
        -0.05363593250513077,
        0.056718241423368454,
        -0.010791707783937454,
        -0.014466139487922192,
        0.058693014085292816,
        -0.027302859351038933,
        0.015170709230005741,
        0.029128454625606537,
        -0.01068817637860775,
        0.057430464774370193,
        0.026828480884432793,
        -0.027562705799937248,
        0.012270910665392876,
        -0.0437229759991169,
        -0.056441668421030045,
        -0.056206993758678436,
        -0.05818094313144684,
        -0.056855544447898865,
        -0.05777675658464432,
        -0.03733034431934357,
        -0.047618985176086426,
        -0.05324353650212288,
        -0.058421097695827484,
        0.04069347679615021,
        0.046311769634485245,
        -0.057604461908340454,
        0.055675383657217026,
        0.0014671505196020007,
        0.036413971334695816,
        0.006833722349256277,
        0.05660415068268776,
        -0.015047860331833363,
        0.05173715204000473,
        -0.04361189901828766,
        -0.058462414890527725,
        0.03450993448495865,
        -0.04940302297472954,
        0.05088438838720322,
        -0.05340244621038437,
        -0.002778506837785244,
        0.03978743404150009,
        -0.05639898404479027,
        0.04944906011223793,
        -0.04302321374416351,
        0.0000769266189308837,
        -0.057971879839897156,
        0.05015045031905174,
        -0.046644918620586395,
        -0.044594522565603256,
        0.05847915634512901,
        -0.03942902386188507,
        0.05491237714886665,
        0.05845550075173378,
        0.033472940325737,
        -0.004912243224680424,
        -0.04992975667119026,
        0.04524675011634827,
        -0.05532269552350044,
        0.013418149203062057,
        0.057249993085861206,
        -0.046661000698804855,
        0.051647819578647614,
        0.03595089539885521,
        0.04470708593726158,
        0.05841458588838577,
        -0.03715725243091583,
        -0.008841468021273613,
        0.011184858158230782,
        0.0411020927131176,
        0.0002238666929770261,
        -0.03917263448238373,
        -0.041792724281549454,
        0.02836659364402294,
        0.05257537215948105,
        -0.01490631140768528,
        -0.0552494041621685,
        -0.042961254715919495,
        0.053922683000564575,
        0.04842584952712059,
        -0.009576333686709404,
        -0.052968766540288925,
        -0.050631433725357056,
        -0.05640820041298866,
        0.056487880647182465,
        -0.03514253720641136,
        0.05329541489481926,
        -0.04809229448437691,
        0.0321730375289917,
        0.030916977673768997,
        0.05797800421714783,
        0.046487994492053986,
        0.05810043588280678,
        -0.05803316831588745,
        0.016753247007727623,
        -0.03907427936792374,
        0.05582953989505768,
        0.046069636940956116,
        -0.05497240275144577,
        -0.05823345482349396,
        0.052768051624298096,
        -0.02022903971374035,
        -0.0506729930639267,
        -0.05272306874394417,
        -0.05390755087137222,
        -0.014174393378198147,
        -0.046057891100645065,
        -0.0024658082984387875,
        0.05869920551776886,
        -0.030457044020295143,
        0.03734707459807396,
        0.05274118483066559,
        0.05442280322313309,
        0.05776245892047882,
        0.058488231152296066,
        0.03324024751782417,
        0.058470845222473145,
        0.05155356600880623,
        -0.01778770610690117,
        -0.05794467404484749,
        -0.043901629745960236,
        -0.01077150646597147,
        -0.05866582319140434,
        -0.05844086781144142,
        -0.04186707362532616,
        -0.05725298076868057,
        0.05133239924907684,
        0.05349282920360565,
        -0.017537551000714302,
        -0.0066857417114079,
        -0.03852580860257149,
        -0.0583336167037487,
        0.05845196545124054,
        -0.034632205963134766,
        0.010949273593723774,
        -0.05843688175082207,
        -0.028492020443081856,
        -0.05138701945543289,
        0.04672122374176979,
        -0.05108975991606712,
        0.05808998644351959,
        0.049394406378269196,
        -0.010780001059174538,
        0.02614349126815796,
        0.0582561194896698,
        0.005303708370774984,
        0.05373357608914375,
        0.05835925042629242,
        -0.05770225450396538,
        -0.01323788147419691,
        0.009774078615009785,
        -0.00019350028014741838,
        0.005753881763666868,
        0.02893192321062088,
        -0.022069700062274933,
        0.05026691406965256,
        -0.05329619720578194,
        0.026990603655576706,
        -0.03898155689239502,
        -0.051861267536878586,
        0.049647606909275055,
        0.05660108104348183,
        0.05824142321944237,
        0.04226238653063774,
        -0.04840216040611267,
        -0.05845808982849121,
        -0.04940135404467583,
        0.05397634953260422,
        0.048073966056108475,
        0.05821447819471359,
        -0.05733242258429527,
        -0.0078084953129291534,
        0.004224753938615322,
        -0.04496883600950241,
        0.046261630952358246,
        -0.052605193108320236,
        0.05101875215768814,
        -0.04605104401707649,
        0.019780747592449188,
        -0.007243261206895113,
        0.02216736413538456,
        -0.052489034831523895,
        0.047186631709337234,
        0.02253095433115959,
        0.02727903053164482,
        0.056357938796281815,
        0.056183602660894394,
        -0.057427071034908295,
        -0.05558431148529053,
        -0.05859597399830818,
        0.03311355412006378,
        -0.0053538978099823,
        -0.055788081139326096,
        -0.055786628276109695,
        -0.032902270555496216,
        -0.048230793327093124,
        -0.014886027202010155,
        0.009876837953925133,
        0.045388560742139816,
        -0.009128076955676079,
        -0.028138598427176476,
        -0.05729684978723526,
        -0.05781250074505806,
        0.054337937384843826,
        -0.03268682211637497,
        -0.049172256141901016,
        -0.05810586363077164,
        0.03470611944794655,
        0.058566611260175705,
        0.05144672095775604,
        -0.043086111545562744,
        -0.022825902327895164,
        0.05134167894721031,
        0.013163321651518345,
        0.027058906853199005,
        0.05608869343996048,
        -0.02298230305314064,
        0.05661363899707794,
        0.017528433352708817,
        -0.0008632764220237732,
        -0.056450005620718,
        -0.056525107473134995,
        -0.046147242188453674,
        -0.05721383914351463,
        0.014135131612420082,
        -0.010824678465723991,
        0.007369610480964184,
        -0.058162737637758255,
        0.05380178242921829,
        0.05251717567443848,
        0.05855599790811539,
        0.05859624594449997,
        -0.05270738899707794,
        0.05828277766704559,
        -0.029612410813570023,
        -0.056280866265296936,
        0.058523330837488174,
        -0.013709344901144505,
        0.05844946578145027,
        0.05817737802863121,
        0.03592668101191521,
        0.053377170115709305,
        -0.05808914452791214,
        0.05690666660666466,
        -0.053756918758153915,
        -0.058260269463062286,
        -0.051003169268369675,
        0.004363709129393101,
        0.04529215022921562,
        0.04875779524445534,
        0.03526696562767029,
        0.05463148653507233,
        0.05782703682780266,
        0.05076683685183525,
        -0.054930679500103,
        -0.0509612075984478,
        -0.025534283369779587,
        -0.056899890303611755,
        0.005093749146908522,
        -0.024037541821599007,
        -0.0579720176756382,
        0.04510883614420891,
        -0.022517336532473564,
        -0.05023356154561043,
        0.04585857316851616,
        -0.05835283175110817,
        0.056360818445682526,
        -0.005830873269587755,
        0.049651049077510834,
        0.04752954840660095,
        0.0584559328854084,
        0.04524896293878555,
        0.00026619774871505797,
        -0.04939155653119087,
        0.008193298242986202,
        -0.006863986607640982,
        0.052449777722358704,
        -0.03673157840967178
    ],
    [
        -0.05049176886677742,
        -0.035909976810216904,
        -0.026451487094163895,
        0.013717342168092728,
        0.05568426102399826,
        -0.02682628110051155,
        0.05913197621703148,
        -0.04541447013616562,
        -0.016504984349012375,
        0.039078548550605774,
        0.04443032294511795,
        -0.04055625945329666,
        -0.052518486976623535,
        0.00893120002001524,
        -0.057953111827373505,
        -0.058447662740945816,
        -0.058055296540260315,
        -0.007528831250965595,
        -0.05069870129227638,
        0.022586096078157425,
        -0.05693458020687103,
        0.05278852581977844,
        0.05790643021464348,
        0.013483697548508644,
        0.043386802077293396,
        0.04267316311597824,
        0.0021628537215292454,
        0.042962830513715744,
        -0.03509734198451042,
        -0.046788111329078674,
        0.026266666129231453,
        0.01481633447110653,
        0.01335894875228405,
        -0.03460710495710373,
        0.008026346564292908,
        0.035997893661260605,
        -0.05114033445715904,
        -0.042566388845443726,
        0.04781455546617508,
        0.04355044290423393,
        -0.0019196276552975178,
        0.024492358788847923,
        0.057204827666282654,
        -0.044099196791648865,
        0.04806607589125633,
        -0.015017110854387283,
        -0.0034659814555197954,
        0.05861116573214531,
        0.056772537529468536,
        0.04160681739449501,
        0.05192189663648605,
        -0.04517792537808418,
        0.05650729686021805,
        -0.057705141603946686,
        -0.0019160389201715589,
        -0.0561349056661129,
        -0.05838274583220482,
        0.044269755482673645,
        -0.04514250531792641,
        -0.057522766292095184,
        -0.05253070592880249,
        0.01469437126070261,
        -0.042980171740055084,
        -0.01740092970430851,
        -0.04350819066166878,
        0.05390837416052818,
        0.05819552764296532,
        -0.04744568094611168,
        0.048795197159051895,
        -0.05901861935853958,
        -0.04539066553115845,
        -0.05841010808944702,
        0.0470929779112339,
        0.04171602427959442,
        -0.05081889033317566,
        -0.016066791489720345,
        0.04539597034454346,
        0.05025779828429222,
        0.025815915316343307,
        -0.05290643870830536,
        -0.0233442522585392,
        0.044842686504125595,
        -0.05760585144162178,
        0.026037391275167465,
        -0.043833766132593155,
        0.022260945290327072,
        -0.04745837301015854,
        0.027468184009194374,
        -0.04559691250324249,
        0.05730017274618149,
        -0.0568983219563961,
        0.055616188794374466,
        -0.05911992862820625,
        -0.04793610796332359,
        0.03971143066883087,
        0.014676589518785477,
        -0.050657037645578384,
        -0.04766570404171944,
        -0.05785844475030899,
        -0.0007795728160999715,
        -0.0590858981013298,
        -0.03369792550802231,
        0.056218571960926056,
        0.013184720650315285,
        -0.038356978446245193,
        0.006144735962152481,
        0.05438515171408653,
        0.02812940813601017,
        -0.05553095415234566,
        0.02898079715669155,
        -0.005588731728494167,
        -0.055030059069395065,
        -0.05402123183012009,
        -0.050954654812812805,
        0.010741528123617172,
        -0.052935272455215454,
        -0.008451550267636776,
        -0.007207685615867376,
        -0.05905575305223465,
        0.004511662293225527,
        0.023053660988807678,
        -0.05024294555187225,
        -0.05081057548522949,
        0.056392472237348557,
        -0.05908782407641411,
        -0.036049529910087585,
        -0.049083542078733444,
        0.051419686526060104,
        0.041510023176670074,
        -0.04472220689058304,
        0.019457915797829628,
        -0.04430653527379036,
        -0.004867402371019125,
        0.04599522799253464,
        -0.05505463108420372,
        -0.006633398588746786,
        0.05357348918914795,
        -0.05746971815824509,
        -0.04444482550024986,
        0.05732341110706329,
        0.059035301208496094,
        0.003059352980926633,
        0.05289031192660332,
        -0.059145040810108185,
        -0.04710449278354645,
        0.04244156554341316,
        0.03220765292644501,
        0.058074407279491425,
        0.010155996307730675,
        0.03509669750928879,
        0.04527756944298744,
        -0.058789558708667755,
        -0.05710121616721153,
        -0.006388760171830654,
        0.05512789636850357,
        -0.056111983954906464,
        0.05816551297903061,
        -0.04765481501817703,
        -0.01305315364152193,
        0.03554864972829819,
        0.05593716353178024,
        0.017050189897418022,
        0.04751864820718765,
        0.023572931066155434,
        0.0199192613363266,
        -0.016861017793416977,
        0.028481081128120422,
        -0.057866666465997696,
        0.052802540361881256,
        0.052427489310503006,
        -0.03343985602259636,
        0.04449736326932907,
        -0.05839105695486069,
        -0.013395313173532486,
        -0.04982316866517067,
        0.041968412697315216,
        -0.04810316860675812,
        -0.018367266282439232,
        0.05687883123755455,
        0.05402925983071327,
        0.025039102882146835,
        0.05909702554345131,
        0.058503467589616776,
        0.05868071690201759,
        0.03794579952955246,
        0.010148230940103531,
        -0.057596705853939056,
        0.05801067128777504,
        0.057050690054893494,
        -0.05444400757551193,
        0.032887052744627,
        -0.055027931928634644,
        -0.05255882814526558,
        0.0006824032170698047,
        0.02815588377416134,
        -0.05229581892490387,
        0.010431579314172268,
        -0.023834725841879845,
        -0.05249519646167755,
        0.03250780701637268,
        0.026319187134504318,
        0.03793339431285858,
        0.037997037172317505,
        0.0026361211203038692,
        0.033057600259780884,
        -0.050648100674152374,
        0.05583564564585686,
        0.05022194981575012,
        -0.03483513742685318,
        0.02975252829492092,
        -0.05554567649960518,
        0.050433456897735596,
        -0.053058065474033356,
        -0.024747807532548904,
        -0.032187506556510925,
        -0.04396015778183937,
        0.05204876512289047,
        0.030785618349909782,
        0.058530762791633606,
        -0.051725152879953384,
        0.03004227764904499,
        -0.05546803027391434,
        -0.026580296456813812,
        -0.057550493627786636,
        0.058210957795381546,
        -0.059034641832113266,
        0.04656880721449852,
        0.02258143573999405,
        -0.058353241533041,
        -0.052420876920223236,
        -0.04108535870909691,
        -0.017760490998625755,
        0.04082883149385452,
        0.05234523490071297,
        -0.01782388798892498,
        0.002532788785174489,
        0.057347118854522705,
        -0.044026996940374374,
        0.05326644703745842,
        0.04681890457868576,
        0.05305618792772293,
        0.056392326951026917,
        -0.002833822276443243,
        -0.024447545409202576,
        0.0223012063652277,
        0.002630972769111395,
        0.055870093405246735,
        -0.04387722536921501,
        -0.04783128201961517,
        -0.011376265436410904,
        -0.04221123084425926,
        -0.05717677250504494,
        -0.05841584876179695,
        -0.05569976195693016,
        -0.030152976512908936,
        -0.014252547174692154,
        -0.057494934648275375,
        -0.05800728499889374,
        0.0588194876909256,
        0.05914275348186493,
        -0.007211253512650728,
        0.037101928144693375,
        0.019114434719085693,
        0.05392008647322655,
        -0.056629929691553116,
        0.049473874270915985,
        0.03109029307961464,
        0.027514200657606125,
        -0.004088771529495716,
        -0.0579938143491745,
        0.022341817617416382,
        -0.059124771505594254,
        0.03863370791077614,
        -0.05674475058913231,
        -0.010359927080571651,
        0.057612501084804535,
        -0.05595871061086655,
        0.039424967020750046,
        -0.0069996025413274765,
        0.018688911572098732,
        -0.05248216912150383,
        0.0378263033926487,
        0.03774538263678551,
        0.008098922669887543,
        -0.052814602851867676,
        -0.05374787002801895,
        0.052634503692388535,
        0.03768674284219742,
        -0.051674820482730865,
        -0.01479964330792427,
        -0.05270024389028549,
        0.044524263590574265,
        0.045042313635349274,
        0.052447039633989334,
        0.038860056549310684,
        -0.03417716175317764,
        0.05909222364425659,
        -0.03777553141117096,
        -0.007169945631176233,
        0.05444170907139778,
        0.03820478543639183,
        0.05633093789219856,
        -0.03478269651532173,
        0.0188954696059227,
        -0.026268362998962402,
        -0.0182055477052927,
        -0.05885674059391022,
        0.016457241028547287,
        0.022394917905330658,
        -0.015919292345643044,
        -0.029345175251364708,
        -0.04936908185482025,
        -0.029997356235980988,
        0.05523768067359924,
        -0.059140242636203766,
        -0.053095024079084396,
        0.055156901478767395,
        -0.052399102598428726,
        0.018195852637290955,
        -0.052173689007759094,
        0.012921794317662716,
        -0.04998013377189636,
        0.058441609144210815,
        -0.033469296991825104,
        0.05543767660856247,
        0.05898285657167435,
        0.05453311279416084,
        -0.03411896154284477,
        -0.028496405109763145,
        -0.04991023987531662,
        -0.03763415291905403,
        0.029561521485447884,
        0.03889372572302818,
        -0.05652061849832535,
        0.052740663290023804,
        0.04931649938225746,
        -0.04944876581430435,
        0.05804697796702385,
        -0.007104846648871899,
        0.053054120391607285,
        -0.011465827003121376,
        -0.026915203779935837,
        0.05718793347477913,
        -0.04003898799419403,
        0.059064727276563644,
        0.04538721963763237,
        0.019123930484056473,
        0.04274287447333336,
        0.05886095389723778,
        0.051824577152729034,
        -0.05842635780572891,
        0.035165488719940186,
        -0.05628462880849838,
        -0.058940544724464417,
        -0.05890621244907379,
        0.04269944503903389,
        -0.05698998272418976,
        -0.029335888102650642,
        -0.055917561054229736,
        -0.057284727692604065,
        0.05809292197227478,
        0.05634027346968651,
        -0.049394652247428894,
        -0.048065416514873505,
        0.025078060105443,
        -0.030326923355460167,
        0.05864546075463295,
        -0.030551528558135033,
        0.037416812032461166,
        -0.0588902086019516,
        -0.039305347949266434,
        -0.058605749160051346,
        0.029885634779930115,
        0.052447184920310974,
        0.05880995839834213,
        0.04863659664988518,
        0.017477447167038918,
        -0.010542718693614006,
        -0.02772444672882557,
        -0.05167480930685997,
        -0.036364007741212845,
        0.05746510624885559,
        -0.05473268777132034,
        0.010562487877905369,
        0.04622576758265495,
        -0.052494823932647705,
        0.058611467480659485,
        -0.05164644122123718,
        -0.05364051088690758,
        -0.05633518472313881,
        -0.05233738571405411,
        0.04681655764579773,
        -0.033462848514318466,
        0.030535506084561348,
        -0.05123244971036911,
        0.05693190172314644,
        0.056108877062797546,
        -0.04497630149126053,
        0.005357828922569752,
        -0.05913882702589035,
        -0.05363842844963074,
        0.05172497034072876,
        0.05674418807029724,
        0.058714546263217926,
        0.018446583300828934,
        0.036966945976018906,
        -0.008466267958283424,
        -0.051208920776844025,
        0.05710059031844139,
        -0.05383189022541046,
        0.05539712309837341,
        -0.009439660236239433,
        0.04869043827056885,
        0.03612073138356209,
        0.055395204573869705,
        0.012908454984426498,
        0.0296445544809103,
        0.03166504576802254,
        0.057414207607507706,
        0.05421056970953941,
        -0.05385886877775192,
        -0.057191602885723114,
        -0.05389302596449852,
        -0.05470845848321915,
        -0.012781043536961079,
        -0.0564456507563591,
        -0.0504438579082489,
        -0.049355342984199524,
        -0.03991919383406639,
        -0.006883061956614256,
        0.00936528854072094,
        -0.0348639041185379,
        -0.009025011211633682,
        -0.042110104113817215,
        0.04490647837519646,
        -0.03553728386759758,
        -0.05754438787698746,
        0.05513576418161392,
        -0.005249800160527229,
        -0.052948590368032455,
        0.023492412641644478,
        -0.00877441931515932,
        0.059141360223293304,
        0.05587649345397949,
        -0.0393381342291832,
        0.055836666375398636,
        0.05636828765273094,
        -0.05157766118645668,
        0.056855544447898865,
        -0.05632292851805687,
        -0.05562715604901314,
        -0.006500439718365669,
        0.04787730425596237,
        -0.05183486267924309,
        -0.05613628774881363,
        -0.04563061147928238,
        0.039479874074459076,
        0.05088083818554878,
        0.008323595859110355,
        0.052528176456689835,
        -0.031011002138257027,
        0.01824783720076084,
        -0.04696522653102875,
        0.016246836632490158,
        0.05399112030863762,
        0.05903783068060875,
        -0.05839378386735916,
        0.05882057547569275,
        -0.056798405945301056,
        -0.05846593156456947,
        0.04356912150979042,
        0.004004822578281164,
        0.01775006391108036,
        0.054988641291856766,
        0.03945929929614067,
        0.04019995778799057,
        -0.048896536231040955,
        0.05879506096243858,
        0.035119302570819855,
        -0.059100717306137085,
        -0.05514586344361305,
        0.03780214115977287,
        0.053673356771469116,
        0.020939942449331284,
        0.028256148099899292,
        0.02880323864519596,
        0.04652127996087074,
        -0.04273297265172005,
        -0.056635886430740356,
        -0.022731447592377663,
        -0.04020575061440468,
        -0.0581824854016304,
        0.024470871314406395,
        0.007319354917854071,
        0.05191478505730629,
        0.0417906828224659,
        -0.05708007141947746,
        0.02673908695578575,
        0.05562233924865723,
        -0.03454124182462692,
        -0.03099231608211994,
        -0.029520008713006973,
        0.05671640485525131,
        -0.024496672675013542,
        0.023828627541661263,
        0.0520348846912384,
        0.015740593895316124,
        -0.04584554582834244,
        -0.05011047050356865,
        -0.021818015724420547,
        0.059113018214702606,
        -0.01649313233792782
    ],
    [
        -0.05335476994514465,
        0.05641356110572815,
        0.05006534233689308,
        0.02781970426440239,
        0.056760188192129135,
        0.033226706087589264,
        0.06013094261288643,
        0.02305777370929718,
        -0.050910886377096176,
        0.05521155893802643,
        0.05111294239759445,
        0.03257429599761963,
        -0.049505237489938736,
        -0.037372082471847534,
        -0.05780812352895737,
        0.0012208550469949841,
        -0.05812999606132507,
        0.053533170372247696,
        -0.018452322110533714,
        0.05188460648059845,
        -0.026527732610702515,
        -0.012621480971574783,
        0.04137176647782326,
        -0.0014718614984303713,
        0.06002611294388771,
        0.041501790285110474,
        0.057040050625801086,
        0.056935641914606094,
        0.012076362036168575,
        -0.05990114063024521,
        0.057238057255744934,
        0.004939260892570019,
        0.05316391587257385,
        -0.05038236081600189,
        -0.014347587712109089,
        -0.056481923907995224,
        -0.04869649186730385,
        -0.009511083364486694,
        -0.010116714984178543,
        0.05507875978946686,
        0.022879503667354584,
        -0.012294812127947807,
        0.05697380006313324,
        -0.059900492429733276,
        0.0049642277881503105,
        0.014574686996638775,
        0.04939735680818558,
        0.05920611694455147,
        0.05210128426551819,
        0.053294673562049866,
        0.013387598097324371,
        0.01766766421496868,
        0.05844471976161003,
        0.027910323813557625,
        0.006268383469432592,
        -0.03650219365954399,
        -0.058975543826818466,
        -0.0000844753158162348,
        -0.032983846962451935,
        -0.007996375672519207,
        -0.047206491231918335,
        0.012223849073052406,
        -0.05204832926392555,
        0.0279593076556921,
        -0.007797107100486755,
        0.03741014748811722,
        0.05919434875249863,
        -0.04713315889239311,
        -0.029514437541365623,
        -0.06016239523887634,
        -0.05376945808529854,
        -0.06000019609928131,
        0.058182451874017715,
        -0.034931838512420654,
        -0.056050676852464676,
        0.020032623782753944,
        0.055131129920482635,
        0.025734076276421547,
        0.035780005156993866,
        -0.04687517508864403,
        -0.03772486746311188,
        0.05986455827951431,
        -0.0467526912689209,
        -0.013021854683756828,
        0.04238119348883629,
        -0.01355600543320179,
        -0.005952543579041958,
        -0.035859521478414536,
        -0.04718394950032234,
        0.060141708701848984,
        -0.05288050323724747,
        0.02231677994132042,
        -0.04717424884438515,
        -0.04673128202557564,
        0.04637061804533005,
        -0.03553982079029083,
        -0.014253901317715645,
        -0.054006848484277725,
        -0.056918006390333176,
        -0.05638333782553673,
        -0.058092519640922546,
        -0.056437898427248,
        0.056196924299001694,
        0.056351397186517715,
        0.024118291214108467,
        0.0073603675700724125,
        -0.04622229188680649,
        0.037410784512758255,
        0.007551165297627449,
        -0.04541018605232239,
        -0.05817604809999466,
        -0.03895742446184158,
        -0.05400669202208519,
        -0.04740157350897789,
        0.04643736034631729,
        -0.04446842148900032,
        -0.05881892144680023,
        -0.039324551820755005,
        -0.03646059334278107,
        -0.01907487027347088,
        0.03714165464043617,
        0.03542110696434975,
        -0.043628398329019547,
        0.024207089096307755,
        -0.05961209163069725,
        0.025466889142990112,
        -0.059450410306453705,
        -0.014600374735891819,
        0.0546463280916214,
        0.022555137053132057,
        -0.04894597455859184,
        -0.04683541879057884,
        -0.027082126587629318,
        0.04742112755775452,
        -0.046296313405036926,
        -0.04303845763206482,
        0.05567730590701103,
        -0.05661506950855255,
        -0.05525369197130203,
        0.059815265238285065,
        0.04040636494755745,
        -0.0013535578036680818,
        0.025155525654554367,
        -0.0014370288699865341,
        -0.027357371523976326,
        0.04715048521757126,
        0.059218358248472214,
        -0.05646718665957451,
        -0.05860738828778267,
        0.05703281611204147,
        -0.01066339761018753,
        -0.05884226784110069,
        -0.05848122388124466,
        -0.03843047842383385,
        -0.018964018672704697,
        -0.05633636936545372,
        0.05365750938653946,
        -0.03147047758102417,
        0.0023342359345406294,
        -0.0077690239995718,
        0.03354888781905174,
        0.05297957733273506,
        0.02765624411404133,
        -0.05492934212088585,
        -0.052464596927165985,
        0.010290063917636871,
        -0.05826982855796814,
        -0.05983861908316612,
        0.021985329687595367,
        0.03797125071287155,
        -0.02215568907558918,
        0.0524827316403389,
        -0.0461888313293457,
        -0.004480705596506596,
        0.05443752929568291,
        0.05719578266143799,
        -0.058342643082141876,
        -0.04814751073718071,
        0.050555117428302765,
        0.04702899232506752,
        0.057867880910634995,
        0.057618752121925354,
        0.05579299107193947,
        0.05877986550331116,
        -0.04330911114811897,
        -0.005133242346346378,
        -0.054498545825481415,
        0.05771479383111,
        -0.04400929436087608,
        -0.05985134840011597,
        -0.01761794649064541,
        -0.04524308070540428,
        -0.00014735567674506456,
        -0.01568644680082798,
        -0.04016447067260742,
        -0.027539879083633423,
        0.057665541768074036,
        -0.04328173026442528,
        -0.05808137357234955,
        0.01503361202776432,
        -0.02929532714188099,
        0.05649614706635475,
        0.054125264286994934,
        -0.011075331829488277,
        0.011468886397778988,
        0.03600773587822914,
        -0.002429685555398464,
        0.03548748046159744,
        -0.05028163269162178,
        0.03710642829537392,
        -0.04432721063494682,
        0.03447888419032097,
        -0.005649229045957327,
        0.016644928604364395,
        0.05634445324540138,
        0.03778356686234474,
        0.02782001718878746,
        0.04154340922832489,
        0.05132643133401871,
        -0.051700543612241745,
        -0.0034819168504327536,
        0.05318378284573555,
        -0.05912545695900917,
        -0.06013254076242447,
        0.05885550007224083,
        -0.05904688313603401,
        -0.02523108758032322,
        0.057265087962150574,
        -0.0077855526469647884,
        -0.054038017988204956,
        -0.001605701632797718,
        -0.05934189632534981,
        -0.0410483293235302,
        0.02080114558339119,
        -0.016607219353318214,
        0.01781443879008293,
        0.05303414165973663,
        -0.002531996928155422,
        -0.018001001328229904,
        0.05347297713160515,
        0.04210076481103897,
        0.05508943647146225,
        -0.05967775732278824,
        0.05675334855914116,
        -0.027446625754237175,
        -0.043008800595998764,
        -0.05617595091462135,
        0.0011776548344641924,
        -0.039437923580408096,
        -0.025607287883758545,
        -0.004422570578753948,
        -0.05841159448027611,
        -0.03890116512775421,
        -0.055861569941043854,
        -0.029744025319814682,
        0.0598037950694561,
        -0.056513283401727676,
        0.04851948097348213,
        0.05978415906429291,
        0.059874456375837326,
        0.059546612203121185,
        0.05219491571187973,
        0.038442377001047134,
        0.05152992904186249,
        -0.042649656534194946,
        0.0476209819316864,
        0.00618406105786562,
        -0.04061892628669739,
        0.05771907418966293,
        -0.01495839562267065,
        -0.02645842730998993,
        -0.0584576390683651,
        0.018206553533673286,
        -0.05679231137037277,
        -0.05357370525598526,
        0.04972856119275093,
        -0.05786255747079849,
        0.03651253134012222,
        -0.004950135480612516,
        -0.059206657111644745,
        -0.059185951948165894,
        -0.0375986322760582,
        0.052703991532325745,
        0.03890581056475639,
        -0.019099662080407143,
        -0.037940863519907,
        -0.028784634545445442,
        -0.056969862431287766,
        -0.05277831852436066,
        0.05280833691358566,
        -0.04729905724525452,
        0.034848589450120926,
        -0.012400781735777855,
        -0.001630335464142263,
        0.04244688153266907,
        -0.03842249885201454,
        0.05996594578027725,
        0.055332161486148834,
        -0.019745398312807083,
        0.04798603057861328,
        0.03569483757019043,
        0.055870603770017624,
        -0.02042202651500702,
        0.03632684424519539,
        -0.036119360476732254,
        -0.05128644406795502,
        -0.021629367023706436,
        -0.03954985737800598,
        0.04240984469652176,
        -0.059727784246206284,
        0.05919225513935089,
        -0.03246118500828743,
        -0.05616169795393944,
        0.051001425832509995,
        -0.05992862954735756,
        -0.05037548393011093,
        0.0585557259619236,
        0.027735859155654907,
        0.008139829151332378,
        -0.03848986327648163,
        -0.051955725997686386,
        -0.02035936340689659,
        0.056996189057826996,
        -0.058627352118492126,
        0.03949812054634094,
        0.05524809658527374,
        0.0168166384100914,
        -0.03381632640957832,
        0.04614181071519852,
        -0.0465872585773468,
        -0.041990384459495544,
        0.039059218019247055,
        -0.03223272040486336,
        -0.055566366761922836,
        0.057833194732666016,
        0.01148272305727005,
        -0.0238962359726429,
        0.05972253903746605,
        -0.0345025509595871,
        0.037701137363910675,
        0.0428297258913517,
        0.00947477575391531,
        -0.057100385427474976,
        -0.02275979518890381,
        0.06018102914094925,
        0.02023620344698429,
        0.045975521206855774,
        0.034645892679691315,
        -0.044016703963279724,
        0.05735808610916138,
        -0.06018223240971565,
        0.04650194197893143,
        -0.05050705373287201,
        -0.057221587747335434,
        -0.03419535979628563,
        0.05248408019542694,
        0.022521546110510826,
        -0.005365463905036449,
        -0.05065998435020447,
        -0.0569257028400898,
        0.05704605579376221,
        -0.007835419848561287,
        -0.05856733396649361,
        -0.04342324286699295,
        0.03536667302250862,
        -0.05245073884725571,
        0.06012507155537605,
        -0.02066067047417164,
        0.05873044952750206,
        -0.060125671327114105,
        -0.05092867091298103,
        -0.05965915694832802,
        0.05896115303039551,
        0.055012114346027374,
        0.03381306305527687,
        0.05906984955072403,
        0.05846802145242691,
        0.024870187044143677,
        -0.050755105912685394,
        -0.0442468635737896,
        -0.041359011083841324,
        0.05495858192443848,
        -0.058944083750247955,
        0.014464379288256168,
        0.04969589784741402,
        -0.046833496540784836,
        0.011448642238974571,
        -0.055506058037281036,
        -0.050743140280246735,
        -0.059163812547922134,
        -0.05621722340583801,
        0.057021599262952805,
        0.02422032505273819,
        0.053645480424165726,
        -0.056438345462083817,
        0.05338786169886589,
        0.056004226207733154,
        -0.03745559975504875,
        0.013130697421729565,
        -0.05516457185149193,
        -0.03718625009059906,
        0.04552742466330528,
        0.0580933652818203,
        0.04467006027698517,
        -0.04490267112851143,
        0.05605989694595337,
        0.051986511796712875,
        0.04121909290552139,
        0.048745229840278625,
        -0.05401189625263214,
        0.059436533600091934,
        -0.006087844725698233,
        0.04348280280828476,
        0.02145715430378914,
        0.05277300998568535,
        0.002095949836075306,
        0.03812553733587265,
        -0.03837773948907852,
        0.05575237795710564,
        -0.01866006664931774,
        -0.05262376368045807,
        -0.054211609065532684,
        -0.05168338865041733,
        -0.05674652382731438,
        0.0444454699754715,
        -0.028464961796998978,
        -0.024357346817851067,
        -0.05759705975651741,
        -0.059796661138534546,
        0.0077600665390491486,
        0.0533083938062191,
        0.013564513064920902,
        -0.02735167182981968,
        0.011716066859662533,
        0.03667285665869713,
        0.004010841250419617,
        -0.008208472281694412,
        0.0581425279378891,
        0.04397989809513092,
        -0.05347701907157898,
        0.053965162485837936,
        0.05750562250614166,
        0.06014971435070038,
        0.04867958649992943,
        0.01618710160255432,
        0.04880313575267792,
        0.05524154752492905,
        -0.05645222216844559,
        0.05477741360664368,
        -0.054625049233436584,
        -0.022764654830098152,
        0.05263565853238106,
        0.05560910701751709,
        0.04062016308307648,
        0.045745108276605606,
        -0.03396747261285782,
        0.0479704774916172,
        0.04947372153401375,
        0.02746315859258175,
        0.05154838413000107,
        -0.008197614923119545,
        -0.010042527690529823,
        -0.05582490563392639,
        -0.05303492769598961,
        0.04345821961760521,
        0.059591904282569885,
        -0.05969111621379852,
        0.05922745540738106,
        -0.059291739016771317,
        -0.05955630913376808,
        0.008489999920129776,
        0.036345742642879486,
        0.031874701380729675,
        -0.01807629130780697,
        0.022884098812937737,
        -0.030906440690159798,
        -0.016455989331007004,
        0.04607005417346954,
        -0.044685691595077515,
        0.017258502542972565,
        0.05895990878343582,
        -0.05380100756883621,
        0.05525224655866623,
        0.03975286707282066,
        0.04069618880748749,
        0.031014826148748398,
        0.03238561749458313,
        -0.040275655686855316,
        -0.046417951583862305,
        -0.04738819599151611,
        -0.055985309183597565,
        -0.05696487799286842,
        -0.00775148207321763,
        0.0222545824944973,
        0.059579361230134964,
        -0.00597987649962306,
        -0.04558275640010834,
        0.043905455619096756,
        0.055610500276088715,
        0.059653230011463165,
        -0.036005064845085144,
        -0.039128631353378296,
        -0.0022561131045222282,
        -0.025543294847011566,
        -0.03481196239590645,
        0.04127056896686554,
        0.04464004561305046,
        -0.01671680063009262,
        -0.05893390253186226,
        -0.0013705871533602476,
        0.058123134076595306,
        0.04425336420536041
    ],
    [
        -0.05314584821462631,
        -0.05042034760117531,
        -0.010272091254591942,
        0.037402063608169556,
        -0.04033171385526657,
        0.0007685587042942643,
        0.04977990686893463,
        -0.053120844066143036,
        0.04861186817288399,
        0.0529598742723465,
        0.05314847081899643,
        0.05118787661194801,
        -0.05286305397748947,
        -0.05269946530461311,
        -0.052948132157325745,
        0.005604971200227737,
        0.0526762530207634,
        0.05146899074316025,
        0.019562827423214912,
        -0.050964947789907455,
        0.0529819056391716,
        0.036272767931222916,
        0.05297581106424332,
        0.04243198037147522,
        -0.04766733944416046,
        0.029059285297989845,
        -0.05287919193506241,
        0.04872056096792221,
        -0.0528678260743618,
        -0.05201305076479912,
        0.020366545766592026,
        0.03574521839618683,
        0.05303189530968666,
        -0.053033970296382904,
        0.050271812826395035,
        -0.05296812951564789,
        -0.049554504454135895,
        -0.05300474166870117,
        -0.015315991826355457,
        -0.053095493465662,
        0.011517539620399475,
        0.042334072291851044,
        0.04309774190187454,
        0.052720073610544205,
        -0.028510134667158127,
        -0.034361615777015686,
        0.053017813712358475,
        0.053128719329833984,
        0.05308625474572182,
        -0.04897637665271759,
        0.04594311863183975,
        -0.009149142540991306,
        0.006951743736863136,
        -0.049009982496500015,
        0.05170292779803276,
        -0.007561156526207924,
        -0.042010363191366196,
        0.04650791734457016,
        -0.05291040241718292,
        -0.051919661462306976,
        -0.05298677086830139,
        0.0421915128827095,
        -0.05292365700006485,
        -0.049616873264312744,
        0.03126240894198418,
        0.016759799793362617,
        -0.05271471291780472,
        0.0515860840678215,
        -0.042531777173280716,
        -0.039067547768354416,
        0.052628666162490845,
        -0.052535150200128555,
        0.053148142993450165,
        0.0455361045897007,
        0.053124211728572845,
        -0.025866765528917313,
        0.05314059183001518,
        0.0531485453248024,
        -0.03543059900403023,
        0.04643009603023529,
        -0.0503876730799675,
        -0.04608475789427757,
        -0.0531236007809639,
        0.03877650573849678,
        -0.043467652052640915,
        0.05022302642464638,
        0.039051275700330734,
        0.04229329898953438,
        -0.05308442562818527,
        0.004134200047701597,
        -0.05314254388213158,
        -0.04668566584587097,
        0.050109654664993286,
        0.052373550832271576,
        -0.024783283472061157,
        -0.050470735877752304,
        0.0005729401018470526,
        -0.05255133658647537,
        -0.04736626520752907,
        0.05100609362125397,
        -0.05314646288752556,
        0.05314844101667404,
        0.05296473205089569,
        -0.02785380557179451,
        -0.05059283226728439,
        0.016835343092679977,
        0.03698088973760605,
        0.05230214446783066,
        -0.050370097160339355,
        -0.004393084440380335,
        0.05045979097485542,
        -0.052990611642599106,
        -0.051127832382917404,
        0.052804362028837204,
        0.05163891986012459,
        0.004664442036300898,
        -0.044965632259845734,
        -0.011436832137405872,
        0.0058485521003603935,
        -0.02699015475809574,
        0.017079835757613182,
        -0.037687722593545914,
        -0.0531415119767189,
        -0.0527479313313961,
        -0.036818668246269226,
        -0.04558331146836281,
        -0.03151855990290642,
        0.025097515434026718,
        0.050513315945863724,
        0.03752020373940468,
        -0.053004808723926544,
        -0.053080759942531586,
        0.0421396903693676,
        0.052546363323926926,
        -0.044685229659080505,
        -0.05152210593223572,
        0.05313990265130997,
        0.05302095785737038,
        -0.01852315478026867,
        0.05299510061740875,
        -0.005132524762302637,
        -0.053067076951265335,
        0.05209425091743469,
        -0.05314883589744568,
        -0.053056880831718445,
        0.041665736585855484,
        -0.03953530639410019,
        0.018878234550356865,
        0.00431855721399188,
        -0.018671786412596703,
        0.040313977748155594,
        -0.053143460303545,
        -0.05279303714632988,
        -0.05257024988532066,
        0.045731574296951294,
        -0.0531240850687027,
        -0.04972474277019501,
        -0.02875072881579399,
        0.05300598219037056,
        0.018235107883810997,
        0.05209948867559433,
        -0.011814077384769917,
        0.0503651462495327,
        0.053123779594898224,
        -0.05211222916841507,
        -0.02546212635934353,
        0.052768029272556305,
        -0.045320332050323486,
        0.03450856730341911,
        0.04144380986690521,
        -0.0523737408220768,
        0.03268197923898697,
        -0.05278310924768448,
        -0.03338104486465454,
        -0.04536673426628113,
        0.005644934717565775,
        0.02014850825071335,
        -0.04478692263364792,
        0.05105378478765488,
        -0.052365973591804504,
        -0.001050685066729784,
        0.043031878769397736,
        0.004036004655063152,
        0.05313311517238617,
        0.04188117757439613,
        0.04581507667899132,
        -0.040564458817243576,
        0.05272528529167175,
        -0.04196567460894585,
        0.05222441256046295,
        -0.05299092084169388,
        -0.028806423768401146,
        0.0436236672103405,
        0.04985027760267258,
        -0.04959496483206749,
        -0.05185307189822197,
        0.05314048379659653,
        -0.05304316058754921,
        0.03667290881276131,
        0.05160801485180855,
        0.052914123982191086,
        -0.0531344935297966,
        -0.02220519632101059,
        -0.05190853774547577,
        0.05083576589822769,
        0.05261685699224472,
        -0.016638491302728653,
        0.03418250381946564,
        0.034916192293167114,
        -0.05313359200954437,
        -0.05126873776316643,
        0.043417688459157944,
        0.04731694608926773,
        0.052931785583496094,
        -0.05308596417307854,
        -0.03225398808717728,
        0.05303265154361725,
        -0.05277806892991066,
        0.04741833359003067,
        -0.05229601263999939,
        0.021239086985588074,
        0.05314413458108902,
        0.052834685891866684,
        -0.04208783432841301,
        0.05304797738790512,
        -0.0515078604221344,
        0.05005400627851486,
        -0.05314146354794502,
        0.047537244856357574,
        -0.025881478562951088,
        -0.052557460963726044,
        -0.049691345542669296,
        -0.05213635414838791,
        0.052601322531700134,
        -0.05219658091664314,
        0.05302644148468971,
        0.04129187762737274,
        0.02423664554953575,
        0.0014876099303364754,
        0.05314737558364868,
        -0.016068043187260628,
        0.04065977409482002,
        -0.039861537516117096,
        -0.04966834560036659,
        0.050269149243831635,
        -0.009889706037938595,
        -0.04912199079990387,
        0.04906337708234787,
        0.05232599750161171,
        -0.053101275116205215,
        -0.022409869357943535,
        -0.053127795457839966,
        0.05249449983239174,
        -0.04840071126818657,
        0.0526026226580143,
        0.05081455409526825,
        0.02983693964779377,
        -0.051304686814546585,
        0.0514775775372982,
        -0.05100584775209427,
        0.03323785588145256,
        0.028324855491518974,
        -0.05306996405124664,
        0.05309184640645981,
        0.05188193544745445,
        0.052727051079273224,
        0.05314774811267853,
        0.05307500436902046,
        0.05313456058502197,
        0.05232834443449974,
        -0.049775026738643646,
        -0.03737105429172516,
        -0.028571758419275284,
        -0.05301819369196892,
        0.047344207763671875,
        -0.0024235232267528772,
        -0.0519355945289135,
        0.04757652431726456,
        0.05143462121486664,
        0.011622855439782143,
        -0.053124379366636276,
        -0.005132791120558977,
        -0.05211174115538597,
        -0.044930119067430496,
        0.05253181979060173,
        0.05314590036869049,
        0.052278682589530945,
        -0.05311144143342972,
        -0.038937490433454514,
        0.049891047179698944,
        -0.03960861638188362,
        0.044630266726017,
        0.026783958077430725,
        -0.015408421866595745,
        0.0530516691505909,
        -0.04493095725774765,
        0.05293075367808342,
        0.022561749443411827,
        0.05091452971100807,
        0.05233689397573471,
        -0.037543345242738724,
        0.026864448562264442,
        0.019682973623275757,
        0.05074179917573929,
        0.040632463991642,
        -0.037408359348773956,
        0.03616103157401085,
        -0.05305494740605354,
        0.038124360144138336,
        0.043664559721946716,
        -0.05249368026852608,
        -0.052915722131729126,
        0.02827438898384571,
        0.039061784744262695,
        0.052641499787569046,
        -0.050395675003528595,
        0.012346172705292702,
        0.05314887687563896,
        0.052800893783569336,
        0.0027110883966088295,
        -0.046460989862680435,
        0.03888271003961563,
        0.0504026859998703,
        0.030518732964992523,
        0.05311540141701698,
        0.051994092762470245,
        0.04951293393969536,
        -0.04567157104611397,
        -0.05286729335784912,
        -0.05114166438579559,
        0.04993467777967453,
        0.02501779794692993,
        -0.053121525794267654,
        -0.018951954320073128,
        0.0407646968960762,
        0.03649834170937538,
        0.0011189207434654236,
        0.016055146232247353,
        -0.02457626722753048,
        -0.029085425660014153,
        -0.043621182441711426,
        -0.04456137865781784,
        0.013632593676447868,
        0.015849726274609566,
        0.05304320901632309,
        0.022319165989756584,
        0.048761192709207535,
        0.05276034027338028,
        -0.04580271244049072,
        0.03851539269089699,
        0.0529877133667469,
        -0.036516692489385605,
        0.03547441586852074,
        -0.052853792905807495,
        -0.019383518025279045,
        -0.020202279090881348,
        -0.05299052223563194,
        -0.05211943760514259,
        -0.04815918952226639,
        -0.010663759894669056,
        0.026060262694954872,
        0.05222567543387413,
        -0.05303356051445007,
        -0.024987269192934036,
        0.04988279193639755,
        -0.052729442715644836,
        0.05307041481137276,
        -0.0006898649735376239,
        -0.05174553021788597,
        -0.05310189351439476,
        -0.0485762320458889,
        -0.05264373868703842,
        0.032117679715156555,
        0.023389684036374092,
        0.05294313281774521,
        0.019490119069814682,
        0.05078592896461487,
        0.05277707055211067,
        0.05201021209359169,
        0.048087265342473984,
        0.049086928367614746,
        0.025781292468309402,
        -0.04676135256886482,
        0.04619598761200905,
        -0.04345731809735298,
        -0.04728294536471367,
        -0.042296167463064194,
        -0.04982003942131996,
        -0.046201497316360474,
        -0.05309537425637245,
        -0.05070042610168457,
        0.01967688836157322,
        -0.05187666043639183,
        -0.04273496940732002,
        0.026097001507878304,
        0.0523698553442955,
        0.042866408824920654,
        -0.052676163613796234,
        -0.05313092842698097,
        -0.05298870801925659,
        -0.05127106234431267,
        0.05300689861178398,
        -0.037099942564964294,
        -0.013799088075757027,
        -0.052075933665037155,
        0.05289251357316971,
        0.017010604962706566,
        -0.0434204526245594,
        0.05293895676732063,
        -0.043501339852809906,
        0.010957159101963043,
        -0.029352983459830284,
        -0.0357925146818161,
        -0.0494966097176075,
        0.05279814079403877,
        -0.04890066385269165,
        0.0339282862842083,
        0.05176658555865288,
        -0.047621797770261765,
        0.05254894122481346,
        0.05191422253847122,
        -0.052910346537828445,
        -0.05212102830410004,
        -0.05300727114081383,
        0.019334018230438232,
        0.04911906644701958,
        -0.03853115439414978,
        0.05313287302851677,
        0.05133281275629997,
        -0.05136759206652641,
        0.04101813957095146,
        0.015622633509337902,
        0.004511099308729172,
        0.014213262125849724,
        0.022424956783652306,
        -0.05258869379758835,
        -0.04074086621403694,
        -0.04964883252978325,
        -0.039361659437417984,
        -0.041839707642793655,
        -0.04810943081974983,
        -0.002710192697122693,
        0.05314888805150986,
        0.0507599413394928,
        -0.05281245335936546,
        0.05259258672595024,
        0.05307351425290108,
        -0.02631261944770813,
        0.0026026254054158926,
        0.05081415921449661,
        0.015094079077243805,
        0.04624877870082855,
        -0.00258464808575809,
        -0.045973680913448334,
        0.02973266690969467,
        -0.05308959260582924,
        0.04882374778389931,
        -0.05130442604422569,
        -0.0039010178297758102,
        0.05232586711645126,
        -0.015441126190125942,
        -0.04612097889184952,
        0.053120650351047516,
        -0.04976946860551834,
        0.053141526877880096,
        0.05310484766960144,
        -0.04344690963625908,
        0.05314667895436287,
        -0.05226126313209534,
        -0.05295393988490105,
        0.052862003445625305,
        0.02255854941904545,
        0.052006255835294724,
        0.05307946726679802,
        0.020312855020165443,
        0.04325070604681969,
        0.04695800691843033,
        0.05313759669661522,
        -0.05161453038454056,
        0.029496293514966965,
        -0.02295617014169693,
        -0.04984661564230919,
        -0.05306610092520714,
        0.03164052218198776,
        -0.005037791095674038,
        0.04977322742342949,
        0.04888499900698662,
        0.044738732278347015,
        -0.052344515919685364,
        -0.05021870508790016,
        0.03782915696501732,
        0.051881611347198486,
        0.053011856973171234,
        -0.052625786513090134,
        -0.048333555459976196,
        -0.03137923404574394,
        -0.051503799855709076,
        -0.05133537948131561,
        0.04481216147542,
        0.022572873160243034,
        0.044395942240953445,
        -0.04323934018611908,
        0.01649794727563858,
        0.007397618144750595,
        0.035401612520217896,
        0.05161524936556816,
        0.05299311503767967,
        0.019464854151010513,
        0.05233289673924446,
        -0.05314008146524429,
        0.05153254419565201,
        0.004019159823656082
    ],
    [
        -0.0530865453183651,
        0.051375579088926315,
        0.052060991525650024,
        0.012049074284732342,
        -0.020596612244844437,
        0.05283516272902489,
        0.05288608372211456,
        -0.052778761833906174,
        -0.01446403469890356,
        0.05307294428348541,
        0.05312817171216011,
        0.04806991666555405,
        -0.05310444161295891,
        -0.05196630209684372,
        -0.05306185409426689,
        0.052989501506090164,
        -0.0378577783703804,
        0.05312623456120491,
        -0.0410139299929142,
        0.05313531309366226,
        0.052745021879673004,
        -0.04912281781435013,
        -0.03974379599094391,
        0.04866929352283478,
        0.05171329900622368,
        0.018093464896082878,
        -0.04377192258834839,
        0.052230801433324814,
        -0.05299224704504013,
        -0.05148255079984665,
        0.05219730734825134,
        0.02647521160542965,
        0.053068406879901886,
        -0.05298376455903053,
        0.052737366408109665,
        -0.052574947476387024,
        -0.051559124141931534,
        -0.05160891264677048,
        0.05308140069246292,
        0.0448455847799778,
        -0.00016597086505498737,
        -0.008051278069615364,
        0.04871605336666107,
        -0.05011633038520813,
        -0.005473458673804998,
        -0.05292437970638275,
        0.05293753743171692,
        0.05308256298303604,
        0.05287276953458786,
        -0.010828439146280289,
        0.05308247357606888,
        0.053122758865356445,
        -0.02136419340968132,
        -0.046968650072813034,
        0.04748158156871796,
        0.03102230839431286,
        -0.05262688919901848,
        0.05002647638320923,
        -0.05312488600611687,
        -0.05303148925304413,
        -0.05284826457500458,
        0.0519501268863678,
        -0.05304591730237007,
        -0.053024664521217346,
        0.03299805894494057,
        0.041863974183797836,
        -0.05222536250948906,
        0.030156582593917847,
        -0.052791547030210495,
        0.001656089909374714,
        -0.052834801375865936,
        -0.05308717116713524,
        0.05314062163233757,
        0.0018792083719745278,
        0.047083742916584015,
        -0.005290110595524311,
        0.05288474261760712,
        0.05314202979207039,
        -0.03914012759923935,
        0.05255750194191933,
        -0.05127792805433273,
        -0.05284383147954941,
        -0.05265839397907257,
        0.03505777567625046,
        0.05093638226389885,
        0.038227882236242294,
        0.0078427130356431,
        -0.02857900783419609,
        -0.04661187529563904,
        0.05312587693333626,
        -0.053101591765880585,
        -0.0025354388635605574,
        0.049989938735961914,
        -0.04625998064875603,
        -0.02862747758626938,
        -0.018244750797748566,
        -0.048940639942884445,
        -0.028325730934739113,
        0.05310575291514397,
        0.0459127277135849,
        -0.05308370292186737,
        0.049079179763793945,
        0.0324668288230896,
        0.014583622105419636,
        0.04811157286167145,
        -0.001404623850248754,
        -0.035401519387960434,
        -0.011510420590639114,
        -0.05307917296886444,
        -0.010476047173142433,
        0.04076877236366272,
        0.009622202254831791,
        -0.026535870507359505,
        -0.03096802905201912,
        -0.015289999544620514,
        -0.053083084523677826,
        -0.0320136696100235,
        -0.05248001590371132,
        0.03161168098449707,
        -0.05242963507771492,
        0.052455004304647446,
        -0.0403611846268177,
        -0.03974394127726555,
        -0.05312656983733177,
        -0.022523805499076843,
        -0.04477456584572792,
        -0.05312227085232735,
        -0.05090557038784027,
        -0.02131156250834465,
        0.050623610615730286,
        -0.04844222962856293,
        -0.048747118562459946,
        -0.05311943590641022,
        0.04976977780461311,
        -0.04438428208231926,
        -0.05308635160326958,
        0.0531080886721611,
        -0.053127601742744446,
        -0.05312502756714821,
        0.05248025432229042,
        -0.02484719827771187,
        -0.053049661219120026,
        0.04912398010492325,
        -0.04030235856771469,
        -0.04846459999680519,
        0.05294519662857056,
        0.013297208584845066,
        -0.050294358283281326,
        0.023098215460777283,
        0.052267927676439285,
        0.028615431860089302,
        -0.05312448367476463,
        -0.04308023676276207,
        0.0013249533949419856,
        0.05313695967197418,
        -0.05074867233633995,
        -0.03206726163625717,
        0.0516340397298336,
        0.0529581755399704,
        -0.0197241622954607,
        -0.013386466540396214,
        -0.021085359156131744,
        0.052550267428159714,
        0.05256424471735954,
        -0.019594402983784676,
        0.011776718311011791,
        0.00695005152374506,
        -0.04098764434456825,
        0.000209751509828493,
        0.009506373666226864,
        -0.051556047052145004,
        0.007696794345974922,
        -0.052954401820898056,
        -0.0005221635801717639,
        -0.0432758666574955,
        -0.032169587910175323,
        0.0428340919315815,
        -0.04886264353990555,
        0.05301576107740402,
        -0.053092919290065765,
        -0.05243314057588577,
        -0.014006172306835651,
        0.04990992322564125,
        -0.009846656583249569,
        -0.05311750993132591,
        0.053138457238674164,
        0.050626467913389206,
        0.05233757942914963,
        0.005601788870990276,
        -0.05215251445770264,
        -0.05314166471362114,
        -0.051581889390945435,
        0.03003748320043087,
        -0.04497845098376274,
        -0.04549220949411392,
        -0.03524946793913841,
        0.05285327136516571,
        -0.05297856032848358,
        -0.053005754947662354,
        -0.03437183052301407,
        -0.011352398432791233,
        0.051636453717947006,
        -0.052376098930835724,
        -0.05306948348879814,
        0.04678718373179436,
        0.052881769835948944,
        -0.003676262916997075,
        -0.05181632190942764,
        -0.0528324656188488,
        0.05312483757734299,
        -0.051944807171821594,
        0.03382771462202072,
        0.05149541795253754,
        0.0531412698328495,
        -0.04809137061238289,
        -0.053142134100198746,
        -0.030861275270581245,
        -0.04982249066233635,
        0.050256114453077316,
        -0.051833271980285645,
        -0.01107031013816595,
        0.05312275141477585,
        0.050063714385032654,
        -0.011226479895412922,
        0.05290267989039421,
        -0.039839185774326324,
        0.05106499418616295,
        0.0531022883951664,
        0.04775993898510933,
        -0.050214264541864395,
        0.009652892127633095,
        -0.05042216181755066,
        0.04932977259159088,
        0.04419275000691414,
        0.046271681785583496,
        0.0529179573059082,
        0.05034460872411728,
        -0.015157252550125122,
        -0.053110044449567795,
        0.05314163863658905,
        -0.01668439619243145,
        -0.03050946071743965,
        -0.052852828055620193,
        0.03903919830918312,
        -0.01586097665131092,
        -0.040910616517066956,
        -0.04307815060019493,
        0.0465131551027298,
        0.04748449847102165,
        -0.05302530527114868,
        -0.04944830760359764,
        -0.034799449145793915,
        0.05065355449914932,
        -0.050218045711517334,
        -0.008311851881444454,
        0.05306156352162361,
        -0.052828386425971985,
        0.0029490261804312468,
        0.04770047590136528,
        -0.05050468072295189,
        -0.04376456141471863,
        0.017476540058851242,
        -0.020063137635588646,
        0.05225858464837074,
        0.049901265650987625,
        0.05312538146972656,
        0.052307210862636566,
        0.05266788601875305,
        0.05305450037121773,
        0.05194612219929695,
        -0.03281868249177933,
        0.0351133793592453,
        -0.014148741029202938,
        -0.05304842069745064,
        -0.04762084782123566,
        0.04583704471588135,
        -0.04679155349731445,
        0.05309509113430977,
        -0.029344748705625534,
        -0.05285610258579254,
        -0.0531417652964592,
        -0.01541256345808506,
        -0.009824719280004501,
        -0.030413947999477386,
        0.052862152457237244,
        0.040420517325401306,
        -0.003353316802531481,
        0.04672433063387871,
        0.03657753765583038,
        0.04796402528882027,
        -0.04481873661279678,
        0.05073373764753342,
        -0.04569030925631523,
        -0.016778351739048958,
        0.04970039427280426,
        -0.05033295229077339,
        0.05277311056852341,
        0.05301268771290779,
        -0.02632216550409794,
        0.05232005938887596,
        0.011167608201503754,
        -0.008069383911788464,
        0.0060828858986496925,
        0.0450248196721077,
        0.047223471105098724,
        -0.027435576543211937,
        0.04789718613028526,
        0.04315613582730293,
        -0.03553095832467079,
        0.0030933592934161425,
        0.026791563257575035,
        0.052853990346193314,
        -0.05083367973566055,
        0.04321882873773575,
        0.018598005175590515,
        -0.05074683204293251,
        -0.03794582560658455,
        0.05309382826089859,
        0.05256687104701996,
        0.009614778682589531,
        -0.04589647799730301,
        0.049833059310913086,
        -0.0458391010761261,
        -0.019275695085525513,
        0.05013076588511467,
        -0.03344287350773811,
        0.053140196949243546,
        -0.03075822815299034,
        -0.007326443213969469,
        -0.05297393724322319,
        0.053098831325769424,
        -0.05159860476851463,
        -0.05288296565413475,
        0.0530816912651062,
        0.04305851086974144,
        0.045029547065496445,
        0.052820414304733276,
        0.0432155467569828,
        0.05314105376601219,
        -0.019026856869459152,
        0.037357691675424576,
        0.03991122171282768,
        -0.05250151455402374,
        0.043091122061014175,
        -0.029490189626812935,
        0.03834417834877968,
        0.04459025710821152,
        0.053082577884197235,
        -0.05309292674064636,
        0.03693319112062454,
        0.052323080599308014,
        0.05032464489340782,
        0.036362066864967346,
        -0.05146028846502304,
        0.03142146021127701,
        -0.04861216992139816,
        -0.052727166563272476,
        -0.052552953362464905,
        -0.04363216459751129,
        -0.05224844440817833,
        0.05033652111887932,
        -0.048996247351169586,
        -0.052579984068870544,
        -0.037041075527668,
        0.03098461590707302,
        -0.05237269401550293,
        0.05269431695342064,
        -0.0392957367002964,
        0.052973322570323944,
        -0.053096234798431396,
        -0.04366264492273331,
        -0.020132452249526978,
        -0.00711725652217865,
        -0.042354945093393326,
        0.053035203367471695,
        0.053142040967941284,
        0.04878762736916542,
        0.05309177190065384,
        0.04993894323706627,
        0.03946743160486221,
        0.03529047966003418,
        0.05014603212475777,
        -0.04535617679357529,
        0.0509323850274086,
        0.0531267374753952,
        -0.041716087609529495,
        -0.047661226242780685,
        -0.049375541508197784,
        -0.049430061131715775,
        -0.053096238523721695,
        -0.05212999880313873,
        -0.0018883335869759321,
        -0.05223868042230606,
        -0.015946803614497185,
        0.011643418110907078,
        0.05231941118836403,
        0.05311408266425133,
        -0.022633779793977737,
        -0.052772797644138336,
        -0.05309822037816048,
        -0.05155618488788605,
        0.053134456276893616,
        -0.05172426253557205,
        0.017283814027905464,
        -0.04530354589223862,
        0.05289165675640106,
        0.048504650592803955,
        -0.053075917065143585,
        0.0501919724047184,
        -0.04755587875843048,
        0.052120551466941833,
        -0.048906028270721436,
        0.04917679354548454,
        -0.03151726722717285,
        0.05229407921433449,
        -0.04830643907189369,
        -0.01117711327970028,
        0.03870175778865814,
        -0.047935232520103455,
        0.04787559062242508,
        0.03673706203699112,
        -0.052787743508815765,
        -0.015743762254714966,
        -0.05314099043607712,
        -0.025447633117437363,
        -0.003322859760373831,
        0.048226483166217804,
        -0.05287683755159378,
        0.0001620124967303127,
        -0.05301403999328613,
        0.03877462446689606,
        0.03351122885942459,
        0.052019715309143066,
        -0.05217164009809494,
        -0.04831188917160034,
        -0.05273810774087906,
        -0.048119012266397476,
        0.01462120283395052,
        -0.0372622087597847,
        -0.05106435716152191,
        -0.052614565938711166,
        0.052488021552562714,
        0.0531422421336174,
        0.05169017240405083,
        -0.05250027775764465,
        -0.012136074714362621,
        0.052628010511398315,
        -0.05249398201704025,
        0.030026301741600037,
        0.050574127584695816,
        0.03855853155255318,
        0.033283524215221405,
        0.01988949254155159,
        -0.040899667888879776,
        -0.0056604621931910515,
        -0.052403099834918976,
        0.025084033608436584,
        -0.04935023561120033,
        0.050679806619882584,
        0.05211591720581055,
        0.05263063684105873,
        -0.05284322425723076,
        0.052447639405727386,
        -0.04918426647782326,
        0.05308050662279129,
        0.052973031997680664,
        -0.04601546376943588,
        0.05311542749404907,
        0.001389133627526462,
        -0.05265343561768532,
        0.052338309586048126,
        -0.034675609320402145,
        0.05263408273458481,
        0.05081755295395851,
        0.0011861062375828624,
        0.020442238077521324,
        0.053094662725925446,
        0.05179097130894661,
        -0.049560658633708954,
        0.052540168166160583,
        0.05314062908291817,
        -0.04787232726812363,
        -0.05313839390873909,
        0.041659750044345856,
        0.03572980314493179,
        0.05306088179349899,
        0.046887993812561035,
        0.025235624983906746,
        -0.05145326629281044,
        -0.052531350404024124,
        0.03689911589026451,
        -0.05233483016490936,
        0.041272539645433426,
        0.02075960673391819,
        -0.05307282134890556,
        0.03767964243888855,
        -0.04480348527431488,
        -0.050919461995363235,
        0.04813114553689957,
        -0.021748661994934082,
        0.050229381769895554,
        0.0527733750641346,
        -0.014573397114872932,
        0.05053332820534706,
        0.052363913506269455,
        0.052769001573324203,
        0.04766061156988144,
        0.00655800336971879,
        0.051299527287483215,
        -0.052300311625003815,
        0.05312586575746536,
        -0.022481607273221016
    ],
    [
        -0.025271696969866753,
        -0.04703623801469803,
        -0.053023308515548706,
        -0.04418746754527092,
        0.0010182111291214824,
        -0.030059505254030228,
        0.05233050137758255,
        -0.05300889536738396,
        0.044667553156614304,
        -0.05290405452251434,
        0.052022840827703476,
        -0.025271501392126083,
        -0.052565962076187134,
        -0.012806209735572338,
        -0.05250217393040657,
        -0.05301062390208244,
        0.042185068130493164,
        0.052473485469818115,
        0.044342152774333954,
        0.05294223874807358,
        -0.052804578095674515,
        0.05302609130740166,
        0.05302724242210388,
        0.03685443475842476,
        -0.0013188739540055394,
        -0.05302701145410538,
        -0.016257911920547485,
        0.05302731692790985,
        -0.0517217181622982,
        -0.046912845224142075,
        0.047105126082897186,
        0.05279693007469177,
        0.05298081412911415,
        -0.04980457201600075,
        0.04851728305220604,
        -0.03239399939775467,
        -0.05129856616258621,
        -0.03634806349873543,
        0.05278637632727623,
        0.012182701379060745,
        0.04664451628923416,
        0.05192853882908821,
        0.052364155650138855,
        0.026220347732305527,
        0.0314318910241127,
        -0.025431470945477486,
        0.04093485698103905,
        0.05264347419142723,
        0.053022611886262894,
        -0.05217430740594864,
        0.007072250824421644,
        -0.053026407957077026,
        0.039026301354169846,
        -0.04209814593195915,
        0.05302716791629791,
        0.053001925349235535,
        -0.05284261330962181,
        -0.04065141826868057,
        -0.05249648168683052,
        0.040786661207675934,
        -0.032776206731796265,
        0.024933287873864174,
        -0.05218186601996422,
        -0.05027898773550987,
        0.03387851640582085,
        0.013162380084395409,
        -0.05271755903959274,
        0.015532649122178555,
        -0.039594538509845734,
        -0.034223735332489014,
        0.03918268904089928,
        -0.05274789035320282,
        0.05302760750055313,
        -0.02811587229371071,
        0.05299609526991844,
        0.04428260400891304,
        0.0530274324119091,
        0.05302545800805092,
        -0.03361502289772034,
        0.04354745149612427,
        -0.039856862276792526,
        -0.05004393681883812,
        -0.053015924990177155,
        -0.03983592987060547,
        0.05298566073179245,
        -0.026286421343684196,
        -0.027045832946896553,
        -0.027031995356082916,
        -0.05302507430315018,
        0.03449118137359619,
        -0.05213494598865509,
        -0.05054502934217453,
        0.04696180671453476,
        0.05039931461215019,
        -0.0321444608271122,
        0.0526818111538887,
        0.0243212953209877,
        -0.04663524776697159,
        -0.051345836371183395,
        0.051460620015859604,
        -0.05239078029990196,
        0.05223534256219864,
        0.05292593315243721,
        0.03889097645878792,
        -0.036445651203393936,
        0.05248202010989189,
        0.052894752472639084,
        0.0508246049284935,
        -0.052414096891880035,
        0.02822616882622242,
        -0.03731459751725197,
        -0.05281499773263931,
        -0.007723244838416576,
        0.052689168602228165,
        -0.006610700394958258,
        -0.004197945352643728,
        0.05257636681199074,
        -0.044956039637327194,
        -0.043554823845624924,
        -0.042515311390161514,
        0.0025676079094409943,
        -0.012620999477803707,
        -0.053015559911727905,
        -0.0519796721637249,
        -0.036032017320394516,
        0.03446022793650627,
        -0.048678040504455566,
        0.05284819379448891,
        0.05280464142560959,
        0.02859632298350334,
        -0.0528545081615448,
        -0.051463354378938675,
        0.04567813128232956,
        0.05288150906562805,
        -0.035096459090709686,
        -0.05276331678032875,
        0.05225146561861038,
        0.05292916297912598,
        -0.05239587649703026,
        -0.017440784722566605,
        -0.0529317781329155,
        0.04450766742229462,
        0.052684955298900604,
        -0.053027596324682236,
        -0.04017455503344536,
        0.05179089307785034,
        0.006239714100956917,
        -0.026411360129714012,
        -0.05055348575115204,
        0.0520399734377861,
        -0.03235848620533943,
        -0.05302729085087776,
        -0.04323473945260048,
        -0.04193074628710747,
        0.008458079770207405,
        -0.052936941385269165,
        0.04662549868226051,
        -0.04736165329813957,
        0.04393360763788223,
        -0.05207138881087303,
        -0.016820907592773438,
        0.03434337303042412,
        0.04860908165574074,
        0.051961686462163925,
        0.02238490805029869,
        0.04997040331363678,
        0.05296330526471138,
        0.04729211702942848,
        -0.025286493822932243,
        -0.008654887787997723,
        -0.05298176035284996,
        0.024108299985527992,
        -0.05302099511027336,
        0.019632022827863693,
        -0.050955142825841904,
        0.039738286286592484,
        0.051855530589818954,
        0.02697453834116459,
        0.050604287534952164,
        0.038183875381946564,
        -0.05070549249649048,
        -0.04923711717128754,
        0.034432437270879745,
        0.05302372947335243,
        -0.042604200541973114,
        -0.0015502857277169824,
        0.05128960311412811,
        0.05288570374250412,
        -0.026814809069037437,
        0.052177250385284424,
        -0.05298726633191109,
        0.04533104971051216,
        0.05285685509443283,
        -0.04698921740055084,
        -0.05208365246653557,
        -0.05296136811375618,
        0.052932679653167725,
        -0.03888716921210289,
        -0.02815718948841095,
        0.0523446723818779,
        0.05192883685231209,
        -0.04814359173178673,
        0.05195622146129608,
        -0.02976621314883232,
        0.046283919364213943,
        -0.031569890677928925,
        -0.047675494104623795,
        0.021550355479121208,
        -0.05285343900322914,
        -0.05302760377526283,
        -0.053004197776317596,
        -0.0034009176306426525,
        0.053027596324682236,
        0.05128781124949455,
        -0.0527544841170311,
        -0.01215294562280178,
        -0.037908975034952164,
        -0.02850888855755329,
        0.04685858264565468,
        -0.011907297186553478,
        -0.05290071293711662,
        -0.05108409747481346,
        0.01190976332873106,
        -0.0428311787545681,
        0.05293864384293556,
        -0.05290054902434349,
        0.049387458711862564,
        -0.05255024507641792,
        -0.047963742166757584,
        0.008504040539264679,
        -0.051482923328876495,
        -0.04998129606246948,
        0.04521616920828819,
        0.04513288289308548,
        -0.05270792916417122,
        0.05293658375740051,
        0.03736620396375656,
        -0.04515470191836357,
        0.023548664525151253,
        0.05301147699356079,
        -0.050024330615997314,
        0.04812287911772728,
        -0.05250567942857742,
        -0.05299772322177887,
        -0.030942583456635475,
        -0.03922753781080246,
        -0.045810047537088394,
        0.013476786203682423,
        0.045443061739206314,
        -0.03931741043925285,
        -0.05173325538635254,
        -0.0490114763379097,
        -0.05294601246714592,
        -0.05019201710820198,
        0.05284507945179939,
        0.05034158378839493,
        -0.0529252253472805,
        -0.05198964476585388,
        0.0503552220761776,
        -0.05023690313100815,
        -0.028793951496481895,
        0.047967635095119476,
        -0.05273449048399925,
        0.0530100092291832,
        -0.049964942038059235,
        0.052858199924230576,
        0.05302371084690094,
        0.05293780937790871,
        0.03170829266309738,
        0.01705246791243553,
        0.045009881258010864,
        -0.050846166908741,
        -0.010281131602823734,
        -0.05293932557106018,
        0.018586913123726845,
        0.019818244501948357,
        -0.050615232437849045,
        -0.0007233669748529792,
        0.053014978766441345,
        0.04534309729933739,
        -0.052091389894485474,
        -0.01515803299844265,
        -0.04819587618112564,
        0.006040315143764019,
        -0.008648057468235493,
        0.05301085487008095,
        0.02775254286825657,
        -0.05301576107740402,
        0.05293870344758034,
        0.040984924882650375,
        -0.05292011424899101,
        0.04627249389886856,
        0.0523739792406559,
        0.03930417820811272,
        0.05274422466754913,
        -0.04546744376420975,
        0.05273887887597084,
        0.01347952987998724,
        0.05066985636949539,
        0.03646359220147133,
        -0.052516672760248184,
        -0.0045222449116408825,
        -0.02332824282348156,
        0.007323372643440962,
        0.03766464442014694,
        -0.010255017317831516,
        -0.019863903522491455,
        -0.04404690861701965,
        0.0023589874617755413,
        0.03786772862076759,
        0.05302433669567108,
        -0.03540308028459549,
        0.04993216320872307,
        0.02495640330016613,
        0.03459542989730835,
        -0.05275488272309303,
        -0.028081566095352173,
        0.05302760750055313,
        0.04847870022058487,
        -0.03265596181154251,
        -0.052964452654123306,
        0.0469788983464241,
        0.052812159061431885,
        0.0493549183011055,
        0.052921514958143234,
        0.044979967176914215,
        0.02514166757464409,
        -0.046977512538433075,
        -0.0530075877904892,
        -0.05114297196269035,
        0.04148417338728905,
        -0.04838506504893303,
        -0.05105450749397278,
        -0.052903614938259125,
        0.025812361389398575,
        0.052455540746450424,
        0.041131749749183655,
        -0.043027736246585846,
        0.02553122118115425,
        -0.05255185440182686,
        -0.05270520597696304,
        -0.0034306973684579134,
        -0.052977755665779114,
        0.0410347543656826,
        0.05302760750055313,
        0.04007707163691521,
        0.051276907324790955,
        0.05208597332239151,
        -0.02371709793806076,
        0.04327518120408058,
        0.05297038331627846,
        -0.05292237922549248,
        0.03237077221274376,
        -0.05241122096776962,
        0.05278358235955238,
        -0.030574588105082512,
        0.05293264240026474,
        -0.05179465934634209,
        -0.05246333032846451,
        0.010291804559528828,
        0.003185910638421774,
        0.05296600982546806,
        -0.05180203542113304,
        -0.026339048519730568,
        0.05302757769823074,
        -0.05207744613289833,
        0.05300989747047424,
        -0.01971801556646824,
        -0.012283610180020332,
        -0.05294384807348251,
        -0.019765641540288925,
        -0.05302758514881134,
        -0.052983663976192474,
        0.05196220427751541,
        0.05259179696440697,
        0.05302760750055313,
        0.02814641408622265,
        -0.04854409024119377,
        0.05279882252216339,
        -0.013261389918625355,
        0.00738072395324707,
        -0.018684793263673782,
        -0.03718775510787964,
        0.049549683928489685,
        0.029910694807767868,
        -0.048428427428007126,
        -0.0521702840924263,
        -0.03762413188815117,
        0.014328192919492722,
        -0.04938394948840141,
        -0.029645122587680817,
        0.051707521080970764,
        -0.0529826395213604,
        0.020055057480931282,
        0.05177214741706848,
        0.052742838859558105,
        -0.053016383200883865,
        -0.049084361642599106,
        0.05299133434891701,
        -0.04685681685805321,
        -0.04987870529294014,
        0.052960265427827835,
        -0.04119311645627022,
        0.05034804716706276,
        -0.05225593224167824,
        -0.01377794984728098,
        0.03845186531543732,
        -0.05186872556805611,
        0.04700038582086563,
        -0.015612870454788208,
        0.03760354965925217,
        -0.0510685071349144,
        0.05299041420221329,
        -0.04977235943078995,
        0.05293339118361473,
        -0.02641111984848976,
        0.004207340534776449,
        -0.05141420662403107,
        -0.020522762089967728,
        0.05237207189202309,
        0.031471677124500275,
        -0.011710632592439651,
        -0.03990934044122696,
        -0.053007230162620544,
        0.04778493195772171,
        -0.05295925587415695,
        0.000491762941237539,
        0.05183342844247818,
        -0.03780675306916237,
        -0.05271419882774353,
        0.03745856136083603,
        -0.01992039941251278,
        -0.034820251166820526,
        -0.049936629831790924,
        -0.04809659346938133,
        -0.042606618255376816,
        -0.03721976652741432,
        -0.0384940467774868,
        -0.04206857830286026,
        -0.052230339497327805,
        -0.04662030190229416,
        0.026074668392539024,
        0.05302755534648895,
        0.050823796540498734,
        -0.04851359874010086,
        0.05143842473626137,
        0.051892559975385666,
        0.04991884529590607,
        -0.046655647456645966,
        0.05230582132935524,
        -0.046912163496017456,
        0.04764970391988754,
        0.047406844794750214,
        -0.03557446226477623,
        0.05148535594344139,
        -0.05023212358355522,
        -0.044340427964925766,
        -0.052088845521211624,
        0.052995238453149796,
        0.051801033318042755,
        0.03828464820981026,
        0.009575186297297478,
        -0.04775535687804222,
        -0.05302712693810463,
        0.05298934131860733,
        0.05298968404531479,
        -0.050212908536195755,
        0.053025368601083755,
        0.020658817142248154,
        -0.05279107391834259,
        -0.043510254472494125,
        -0.05300641059875488,
        0.04928871989250183,
        0.053027160465717316,
        0.04425932466983795,
        0.052203211933374405,
        0.05059888958930969,
        0.05222097411751747,
        -0.052859824150800705,
        -0.03503139689564705,
        -0.03743844851851463,
        -0.05102253332734108,
        -0.014297044835984707,
        -0.03519582003355026,
        0.0364321731030941,
        0.00950339064002037,
        0.05275366082787514,
        0.052255190908908844,
        -0.05118032172322273,
        -0.05267029255628586,
        0.03980480879545212,
        -0.01163763739168644,
        0.05285187065601349,
        -0.05022435262799263,
        0.007533862721174955,
        0.05020369589328766,
        0.052996691316366196,
        0.0038280801381915808,
        0.051983099430799484,
        -0.05164407566189766,
        0.0521346852183342,
        0.04274448752403259,
        0.029480766505002975,
        0.04339585080742836,
        0.05299462378025055,
        0.0522819422185421,
        0.050635434687137604,
        0.02143157087266445,
        0.051243189722299576,
        -0.05302760377526283,
        0.05281323194503784,
        -0.05287035182118416
    ],
    [
        -0.04727356135845184,
        0.009738879278302193,
        0.0514511838555336,
        0.015772107988595963,
        -0.016803056001663208,
        0.044367581605911255,
        0.05000380799174309,
        -0.05961504578590393,
        0.056008078157901764,
        0.06117546185851097,
        0.05885033681988716,
        -0.04207133501768112,
        -0.05814003571867943,
        -0.03704703226685524,
        -0.05678164213895798,
        -0.06084718927741051,
        -0.012626754119992256,
        0.05703267827630043,
        0.03883862867951393,
        0.01808442547917366,
        0.030952516943216324,
        -0.05292685329914093,
        -0.057843081653118134,
        0.01763920858502388,
        -0.04464282840490341,
        0.0051750484853982925,
        -0.055254947394132614,
        -0.057273413985967636,
        0.05429024621844292,
        -0.05906037986278534,
        0.021571459248661995,
        0.048564616590738297,
        -0.021569248288869858,
        -0.05877310037612915,
        0.050620611757040024,
        -0.03601173684000969,
        -0.053278062492609024,
        -0.05339590460062027,
        0.006819820962846279,
        0.05866045877337456,
        0.0051544783636927605,
        0.05812521278858185,
        0.026384420692920685,
        0.012897371314466,
        -0.02750416472554207,
        -0.039117325097322464,
        -0.051671069115400314,
        0.060151778161525726,
        0.06048106402158737,
        0.034328486770391464,
        0.060515593737363815,
        0.0069699776358902454,
        -0.05455553159117699,
        -0.03872157633304596,
        -0.04149923101067543,
        -0.05731087550520897,
        -0.031645502895116806,
        0.04989862069487572,
        -0.024399029091000557,
        -0.04268669709563255,
        -0.053825635462999344,
        -0.03161974996328354,
        -0.05990428477525711,
        -0.05232921987771988,
        -0.0015115758869796991,
        -0.00375367677770555,
        -0.03748568519949913,
        0.05315938964486122,
        0.05257163941860199,
        -0.06046430021524429,
        -0.010709230788052082,
        -0.026121407747268677,
        0.060372237116098404,
        0.05077441409230232,
        -0.04950951039791107,
        -0.002940933685749769,
        -0.058596476912498474,
        0.0612088181078434,
        0.020926840603351593,
        0.02499093860387802,
        -0.051365822553634644,
        -0.0015340857207775116,
        -0.06037217378616333,
        0.05766751244664192,
        -0.05653633922338486,
        0.03938527777791023,
        -0.0313502736389637,
        -0.05828586965799332,
        -0.050631213933229446,
        0.05271640419960022,
        -0.06090419366955757,
        -0.032395895570516586,
        -0.01668996550142765,
        0.017740126699209213,
        -0.02817985974252224,
        -0.05224490910768509,
        -0.008892035111784935,
        -0.047442901879549026,
        -0.05710260197520256,
        0.04991309717297554,
        -0.05935140326619148,
        0.05496147274971008,
        0.05878673866391182,
        0.050050340592861176,
        0.016757387667894363,
        0.020952219143509865,
        -0.00975737813860178,
        0.05895193666219711,
        -0.0590723417699337,
        -0.018277348950505257,
        0.042707156389951706,
        -0.016582747921347618,
        -0.020012160763144493,
        0.017229361459612846,
        0.05307426676154137,
        0.03656033053994179,
        -0.048824988305568695,
        -0.05753955990076065,
        0.05741195008158684,
        -0.057133711874485016,
        -0.02537439577281475,
        -0.034634120762348175,
        -0.040406808257102966,
        0.004731846507638693,
        -0.050716582685709,
        0.03593925014138222,
        0.030036870390176773,
        0.045495226979255676,
        0.039820317178964615,
        0.03189476579427719,
        -0.008848032914102077,
        -0.022182397544384003,
        -0.05678809434175491,
        0.025369053706526756,
        -0.03586247190833092,
        -0.058003880083560944,
        0.06058196350932121,
        -0.056625328958034515,
        0.028353875502943993,
        0.05634591728448868,
        0.002482321811839938,
        -0.06074948608875275,
        0.04202781990170479,
        -0.04804730787873268,
        -0.021313676610589027,
        0.05828292667865753,
        0.012371876277029514,
        -0.047609392553567886,
        -0.05267568305134773,
        0.04260672628879547,
        0.047103870660066605,
        -0.059037696570158005,
        -0.003660996677353978,
        0.016295593231916428,
        0.058796923607587814,
        -0.0536295585334301,
        -0.06112203747034073,
        0.05491868406534195,
        0.008277571760118008,
        0.05072770640254021,
        -0.05717066302895546,
        0.02076357789337635,
        0.05845329537987709,
        0.044228196144104004,
        -0.0424426905810833,
        0.031180405989289284,
        0.022581331431865692,
        0.013566797599196434,
        -0.0425431951880455,
        0.012415755540132523,
        -0.03369615226984024,
        0.0268863458186388,
        -0.023188022896647453,
        -0.010409497655928135,
        0.055550266057252884,
        0.0026653956156224012,
        0.060771118849515915,
        0.05315442383289337,
        0.059702590107917786,
        -0.05965298041701317,
        -0.0518268421292305,
        -0.048230405896902084,
        -0.015601497143507004,
        0.010347219184041023,
        -0.04722796380519867,
        0.006591023877263069,
        -0.05246937647461891,
        0.05943926423788071,
        -0.03813387081027031,
        -0.05297107622027397,
        -0.047406066209077835,
        0.04909766837954521,
        -0.05349241569638252,
        -0.04812334105372429,
        -0.03387823328375816,
        -0.028761383146047592,
        -0.030331214889883995,
        0.03441430255770683,
        0.053912702947854996,
        0.021430132910609245,
        0.060114242136478424,
        -0.013037292286753654,
        -0.03590282052755356,
        -0.05650225654244423,
        0.0032568806782364845,
        -0.025876356288790703,
        0.01174949761480093,
        -0.031009497120976448,
        -0.057110708206892014,
        0.05809881165623665,
        -0.053557779639959335,
        0.053972452878952026,
        -0.05310775339603424,
        0.06074073165655136,
        -0.03084287792444229,
        -0.06092182919383049,
        -0.009051299653947353,
        -0.05688309296965599,
        0.057931527495384216,
        -0.05309395492076874,
        -0.03563497215509415,
        -0.051511771976947784,
        0.01389017142355442,
        0.0029805072117596865,
        0.060408178716897964,
        -0.029326410964131355,
        0.0601457878947258,
        -0.059228431433439255,
        0.03952956944704056,
        -0.050127387046813965,
        -0.05005308613181114,
        -0.0606653168797493,
        -0.040538933128118515,
        0.054142408072948456,
        -0.042891353368759155,
        0.03704746812582016,
        0.05383150279521942,
        0.006463182624429464,
        -0.0048590837977826595,
        0.060082703828811646,
        -0.03067808970808983,
        -0.05949322506785393,
        -0.0413261242210865,
        -0.053128600120544434,
        0.04536549001932144,
        -0.028567487373948097,
        0.046783413738012314,
        -0.018560675904154778,
        0.04595506191253662,
        -0.04831154644489288,
        -0.01212676614522934,
        -0.05549827218055725,
        -0.0009576689917594194,
        -0.047343578189611435,
        -0.0013514343881979585,
        0.05380215495824814,
        0.06074859946966171,
        -0.05426188185811043,
        0.0016106024850159883,
        -0.0540788359940052,
        -0.04455466940999031,
        -0.009752563200891018,
        0.04943135008215904,
        0.04484383016824722,
        -0.03637072071433067,
        0.05604996159672737,
        0.05998888611793518,
        0.06091657280921936,
        -0.02962106466293335,
        0.057484857738018036,
        0.059261903166770935,
        -0.05810118466615677,
        -0.02440955862402916,
        -0.05330263823270798,
        -0.02314845100045204,
        0.03609934076666832,
        -0.0558449812233448,
        0.06114106997847557,
        0.05503420904278755,
        -0.060928355902433395,
        -0.05696635693311691,
        0.056430984288454056,
        0.051433224231004715,
        -0.060152798891067505,
        -0.02530135028064251,
        -0.05740679055452347,
        0.04159526899456978,
        0.05879899114370346,
        -0.05678849667310715,
        -0.05097741633653641,
        -0.051323529332876205,
        0.03907361999154091,
        -0.041732415556907654,
        0.05360742658376694,
        0.04714164882898331,
        -0.05213245749473572,
        0.05683164671063423,
        0.05099581554532051,
        0.02549215778708458,
        0.03560948744416237,
        -0.05130033940076828,
        -0.04680418595671654,
        0.00017591782670933753,
        0.05826079100370407,
        0.0540497861802578,
        -0.04763864353299141,
        0.002948543056845665,
        -0.04385358840227127,
        0.058979153633117676,
        0.03237811475992203,
        -0.03261304646730423,
        -0.05510015785694122,
        0.030508995056152344,
        -0.01786065474152565,
        0.03786008432507515,
        -0.056376826018095016,
        -0.055741533637046814,
        0.02516331896185875,
        0.044149480760097504,
        -0.05453718453645706,
        0.05751067399978638,
        -0.022751525044441223,
        0.06049796938896179,
        0.009388686157763004,
        0.039974186569452286,
        0.0038514677435159683,
        0.04579031094908714,
        -0.05929993465542793,
        0.027418851852416992,
        -0.04221034422516823,
        0.058108385652303696,
        -0.04208029806613922,
        -0.05104310065507889,
        -0.04740125685930252,
        0.052810873836278915,
        0.04670388624072075,
        0.004641854204237461,
        0.028466565534472466,
        0.06050531193614006,
        0.05261678248643875,
        0.050696492195129395,
        -0.054891202598810196,
        0.041647374629974365,
        0.03938589245080948,
        -0.01151541993021965,
        0.01739530637860298,
        -0.018002957105636597,
        0.05795934796333313,
        -0.02316645346581936,
        -0.001150860800407827,
        0.05937671661376953,
        0.05929464474320412,
        0.02379826083779335,
        -0.05417466536164284,
        -0.04425536468625069,
        0.017227713018655777,
        -0.03277932479977608,
        -0.057239118963479996,
        -0.025183413177728653,
        0.024749182164669037,
        0.03515706583857536,
        0.0317978598177433,
        0.037860553711652756,
        -0.04037369787693024,
        -0.026466401293873787,
        -0.0557267926633358,
        0.058853134512901306,
        0.05921751260757446,
        0.04043563827872276,
        -0.058777857571840286,
        0.04898836836218834,
        -0.05703248828649521,
        -0.020838258787989616,
        -0.060250215232372284,
        0.05260999873280525,
        0.060366593301296234,
        0.03491714596748352,
        0.04435223713517189,
        0.058337997645139694,
        0.02079261653125286,
        -0.058104898780584335,
        0.053610868752002716,
        -0.0328325554728508,
        0.03986559435725212,
        0.0417325533926487,
        -0.042400382459163666,
        0.025394149124622345,
        -0.03571831434965134,
        -0.05420157313346863,
        -0.05171647295355797,
        -0.04892118647694588,
        -0.04699815437197685,
        -0.033063214272260666,
        0.051794394850730896,
        0.03815124183893204,
        0.04431774467229843,
        0.012309174984693527,
        -0.0445002056658268,
        0.0022929846309125423,
        -0.05835004523396492,
        -0.057049743831157684,
        0.0602426677942276,
        -0.0028009035158902407,
        0.06021803617477417,
        -0.06042744964361191,
        0.015586082823574543,
        -0.02205117978155613,
        -0.04238918051123619,
        0.020565446466207504,
        -0.023032257333397865,
        -0.027562245726585388,
        -0.044245269149541855,
        0.015556531958281994,
        -0.021653417497873306,
        0.0315091609954834,
        -0.017188021913170815,
        0.02550712786614895,
        0.04753142595291138,
        0.049028147011995316,
        -0.016725577414035797,
        -0.05076710507273674,
        -0.055264558643102646,
        -0.04978204891085625,
        -0.060973476618528366,
        0.02836453914642334,
        -0.036480970680713654,
        -0.022634407505393028,
        -0.05605936050415039,
        0.026503756642341614,
        -0.05430658161640167,
        0.03925910219550133,
        0.05259358510375023,
        -0.013016140088438988,
        -0.05833917856216431,
        -0.05415916442871094,
        -0.05652979016304016,
        0.019021879881620407,
        -0.058151379227638245,
        -0.0019006850197911263,
        -0.03918828070163727,
        -0.055351343005895615,
        -0.01003055926412344,
        0.05161692574620247,
        -0.003100997768342495,
        0.004295573569834232,
        0.0029229517094790936,
        0.05482049658894539,
        0.002613563323393464,
        0.05814419686794281,
        0.05043276026844978,
        0.055150747299194336,
        0.017128217965364456,
        -0.03164447844028473,
        0.02072855457663536,
        0.05971039831638336,
        -0.05932164564728737,
        0.03384149819612503,
        -0.04485400393605232,
        0.06015534698963165,
        0.04008307680487633,
        -0.04041964188218117,
        -0.053937286138534546,
        0.06106295436620712,
        -0.05015852674841881,
        0.05528523400425911,
        0.06102050095796585,
        0.001921937451697886,
        0.05599414184689522,
        0.03305519372224808,
        -0.037022873759269714,
        -0.04082076996564865,
        -0.05316244438290596,
        0.05997270345687866,
        -0.037183977663517,
        -0.05651165544986725,
        0.053286805748939514,
        0.000725540507119149,
        0.05986633524298668,
        -0.04748682305216789,
        0.04257313907146454,
        0.05856579542160034,
        -0.01931924745440483,
        -0.05668487772345543,
        0.046875521540641785,
        0.05252576246857643,
        0.03404176980257034,
        0.027188224717974663,
        0.05696083977818489,
        -0.05606435611844063,
        -0.04290880262851715,
        0.02593263052403927,
        0.020064957439899445,
        0.02237080968916416,
        0.049180276691913605,
        -0.03138146921992302,
        0.032299552112817764,
        -0.057262685149908066,
        0.023512186482548714,
        0.02460164576768875,
        0.05789051949977875,
        0.059770841151475906,
        0.04244194179773331,
        0.005242898128926754,
        -0.030380012467503548,
        0.0610717348754406,
        0.00856299139559269,
        0.011302503757178783,
        0.01078763697296381,
        -0.03907983750104904,
        0.05864391103386879,
        0.05767373368144035,
        -0.0548667274415493
    ],
    [
        -0.05312814190983772,
        0.010164592415094376,
        0.051027581095695496,
        0.001631488441489637,
        0.0022219184320420027,
        0.053093887865543365,
        0.05310090631246567,
        -0.053126927465200424,
        0.050023458898067474,
        0.05307408794760704,
        0.053137339651584625,
        0.049206409603357315,
        -0.05303460732102394,
        -0.050649359822273254,
        -0.053012747317552567,
        0.051030389964580536,
        -0.011958110146224499,
        0.05291273072361946,
        0.05261089652776718,
        0.05310748517513275,
        0.050932057201862335,
        0.05010591447353363,
        -0.023937534540891647,
        0.01882597804069519,
        0.03331606835126877,
        0.053024038672447205,
        -0.04002506658434868,
        0.04884307086467743,
        -0.053102679550647736,
        -0.05273035168647766,
        -0.022117165848612785,
        0.021307164803147316,
        0.05302957072854042,
        -0.05132297798991203,
        0.053016502410173416,
        -0.04644224792718887,
        -0.052771057933568954,
        -0.0010358492145314813,
        0.05313599854707718,
        -0.04905173182487488,
        0.04800158739089966,
        -0.0372772254049778,
        0.046636275947093964,
        -0.039846740663051605,
        0.04585801437497139,
        -0.0434027761220932,
        0.00976747740060091,
        0.05082762986421585,
        0.05280128866434097,
        -0.05062917247414589,
        0.05281496047973633,
        0.05257977172732353,
        -0.04764869809150696,
        -0.02102457545697689,
        0.05217192694544792,
        -0.051698047667741776,
        -0.020406607538461685,
        0.04810824617743492,
        -0.053137607872486115,
        -0.041364334523677826,
        -0.05260511860251427,
        0.0007150240708142519,
        -0.05289780721068382,
        -0.043303653597831726,
        0.014178079552948475,
        0.04570288583636284,
        -0.05216355249285698,
        0.020494746044278145,
        -0.052818551659584045,
        0.051409173756837845,
        0.02358529344201088,
        -0.052844155579805374,
        0.05313738062977791,
        0.0011777104809880257,
        -0.044164661318063736,
        0.01615658402442932,
        0.0531301312148571,
        0.053137633949518204,
        0.04576243460178375,
        -0.005286935716867447,
        -0.05102420970797539,
        -0.053119949996471405,
        -0.051385149359703064,
        0.03322729095816612,
        -0.046990059316158295,
        0.052089691162109375,
        -0.044640108942985535,
        0.04536106437444687,
        -0.05313761159777641,
        0.05078674852848053,
        -0.05301768705248833,
        -0.05312825366854668,
        0.052623968571424484,
        -0.015378779731690884,
        -0.04722851887345314,
        0.005148902535438538,
        0.04625788703560829,
        -0.04517914354801178,
        0.052673619240522385,
        -0.04932740330696106,
        -0.053070101886987686,
        0.05306524038314819,
        0.00239345314912498,
        0.05203535780310631,
        -0.043788883835077286,
        -0.039401911199092865,
        -0.05122983083128929,
        0.0528520792722702,
        -0.0515962690114975,
        0.053029563277959824,
        -0.04786059260368347,
        0.026970721781253815,
        -0.05233310908079147,
        -0.009471484459936619,
        -0.011449668556451797,
        0.018909938633441925,
        -0.05309402942657471,
        -0.05302973836660385,
        -0.014181979931890965,
        0.03992670401930809,
        0.04793967679142952,
        -0.015245169401168823,
        -0.020812174305319786,
        -0.05307059362530708,
        -0.011723562143743038,
        -0.03647126257419586,
        0.012709664180874825,
        0.047918934375047684,
        -0.03997799754142761,
        -0.049841299653053284,
        -0.05313645675778389,
        -0.05136921629309654,
        0.01633387990295887,
        0.017060302197933197,
        -0.03148718550801277,
        -0.05066138878464699,
        0.052633434534072876,
        0.049024585634469986,
        -0.0530032254755497,
        0.03906148299574852,
        -0.048259492963552475,
        -0.05311356857419014,
        0.02385075017809868,
        -0.053137633949518204,
        -0.051449015736579895,
        -0.009152286686003208,
        -0.049324337393045425,
        -0.05275220051407814,
        -0.052552904933691025,
        -0.05130704864859581,
        -0.02529858611524105,
        -0.05307971313595772,
        0.04617225006222725,
        -0.051170166581869125,
        0.05312729254364967,
        -0.053039297461509705,
        -0.011133724823594093,
        0.009791655465960503,
        0.05184950307011604,
        -0.028809240087866783,
        0.023445043712854385,
        0.003465477144345641,
        0.05149265378713608,
        0.04970765858888626,
        -0.052082762122154236,
        0.004641897976398468,
        0.04889287054538727,
        0.03147541731595993,
        0.009070148691534996,
        0.03338368237018585,
        -0.05312362313270569,
        0.05289161205291748,
        -0.052776455879211426,
        0.0530582033097744,
        -0.04183538258075714,
        -0.05008704960346222,
        0.04983355104923248,
        -0.006456536240875721,
        0.05313340201973915,
        -0.04948289319872856,
        -0.03852355107665062,
        -0.043842386454343796,
        0.05273525044322014,
        0.05294451862573624,
        -0.03831610083580017,
        0.044841401278972626,
        -0.03884432837367058,
        0.05129985138773918,
        0.03281449154019356,
        -0.051184237003326416,
        -0.053094834089279175,
        -0.026371881365776062,
        -0.002768124919384718,
        0.030403105542063713,
        -0.04883231222629547,
        -0.03328403830528259,
        0.05313759297132492,
        0.028849123045802116,
        -0.025446817278862,
        -0.03254175931215286,
        -0.048175934702157974,
        0.04945093393325806,
        -0.049718622118234634,
        -0.019016895443201065,
        0.05182865262031555,
        0.04788937792181969,
        -0.00047574922791682184,
        -0.05205512419342995,
        -0.0508573092520237,
        0.04717306047677994,
        -0.04217314347624779,
        0.04994938150048256,
        0.05175958573818207,
        0.052241820842027664,
        0.04945585876703262,
        -0.021356046199798584,
        0.05313381925225258,
        -0.04173921048641205,
        0.011312192305922508,
        0.05309939756989479,
        0.05312950909137726,
        0.05313022434711456,
        -0.03388106822967529,
        -0.034762877970933914,
        0.05152283236384392,
        -0.052917953580617905,
        0.05097689479589462,
        0.035754259675741196,
        -0.042096834629774094,
        -0.049844890832901,
        -0.043249208480119705,
        0.032450076192617416,
        0.04783080890774727,
        0.05007733404636383,
        0.01632656902074814,
        0.042936407029628754,
        0.05059545487165451,
        0.004735092632472515,
        -0.005589746870100498,
        0.05313212051987648,
        0.03891191631555557,
        0.044045258313417435,
        -0.04630197584629059,
        -0.045121729373931885,
        0.01871412806212902,
        -0.050047483295202255,
        0.02806236408650875,
        0.05259612202644348,
        0.0522289052605629,
        -0.05309446156024933,
        -0.032396625727415085,
        -0.04789465293288231,
        0.052888549864292145,
        -0.047507524490356445,
        -0.007340054493397474,
        0.053108446300029755,
        -0.042715128511190414,
        0.03812789171934128,
        0.03575766086578369,
        -0.02694353647530079,
        -0.04238475486636162,
        0.04500778019428253,
        -0.031135737895965576,
        0.05293901264667511,
        0.05229181796312332,
        0.05306736007332802,
        0.05309680476784706,
        0.05266457051038742,
        0.05304243415594101,
        0.052916351705789566,
        -0.011374261230230331,
        -0.043567825108766556,
        -0.033298689872026443,
        -0.05305124819278717,
        -0.02074449509382248,
        0.02566489763557911,
        -0.050324175506830215,
        0.048065606504678726,
        0.05270129442214966,
        -0.05061236768960953,
        -0.05248523876070976,
        0.010499740950763226,
        -0.05242824926972389,
        0.016226790845394135,
        0.048476964235305786,
        0.05220568925142288,
        0.028562169522047043,
        -0.050023216754198074,
        0.04167080670595169,
        0.0511106513440609,
        0.05005989223718643,
        0.053024813532829285,
        -0.023353518918156624,
        -0.04783322662115097,
        0.053108446300029755,
        -0.05099443718791008,
        0.04966145381331444,
        0.053108636289834976,
        -0.052912063896656036,
        0.05219367891550064,
        0.03849012032151222,
        0.0409226156771183,
        0.023611020296812057,
        0.016039850190281868,
        0.042469266802072525,
        0.030669959262013435,
        -0.03356761485338211,
        0.023764068260788918,
        -0.05291719734668732,
        -0.0475354939699173,
        0.020214861258864403,
        0.05231912061572075,
        0.02987179532647133,
        0.05295949801802635,
        -0.04847126081585884,
        -0.05290718749165535,
        -0.026546798646450043,
        0.0531376451253891,
        0.050892848521471024,
        0.023472512140870094,
        -0.051313143223524094,
        0.05303971469402313,
        0.05020750313997269,
        0.04689043015241623,
        0.05262864753603935,
        0.04974154010415077,
        0.04219359904527664,
        -0.04023868963122368,
        -0.05116470158100128,
        -0.05312904343008995,
        0.051950450986623764,
        -0.045871444046497345,
        -0.0020454726181924343,
        0.025327401235699654,
        0.049898479133844376,
        -0.00938466377556324,
        0.0026803454384207726,
        0.052739545702934265,
        0.0412273183465004,
        0.0220755934715271,
        -0.021561311557888985,
        -0.034577373415231705,
        -0.047797802835702896,
        0.052765123546123505,
        0.001714249374344945,
        0.039624642580747604,
        0.03217925876379013,
        0.052747052162885666,
        -0.053048767149448395,
        -0.016004811972379684,
        0.04990240931510925,
        0.04259645193815231,
        0.0529867485165596,
        -0.05304121971130371,
        0.05273938551545143,
        -0.04155876487493515,
        -0.03803583234548569,
        -0.04875430464744568,
        -0.053004924207925797,
        0.05306747928261757,
        0.05247076600790024,
        -0.018236862495541573,
        0.01395029854029417,
        -0.041014354676008224,
        0.05100715160369873,
        -0.052459605038166046,
        0.05261180177330971,
        -0.02089221589267254,
        0.05290892347693443,
        -0.05095824599266052,
        -0.04380126670002937,
        -0.04447851702570915,
        0.051818594336509705,
        -0.005735483020544052,
        0.04790864884853363,
        0.05207396671175957,
        -0.050558026880025864,
        0.05270381271839142,
        0.052277155220508575,
        0.051836587488651276,
        0.003790567396208644,
        -0.004150002729147673,
        -0.04689378663897514,
        0.052956584841012955,
        0.05059146136045456,
        -0.05308564752340317,
        0.017174378037452698,
        -0.05175114423036575,
        -0.04581079259514809,
        -0.05313378944993019,
        -0.03905092552304268,
        -0.052982576191425323,
        -0.050003062933683395,
        0.05289359763264656,
        0.0508696548640728,
        0.053065646439790726,
        -0.02961616776883602,
        -0.04970358684659004,
        -0.04218536615371704,
        -0.05309910699725151,
        -0.050911180675029755,
        0.05263277143239975,
        -0.04965979605913162,
        0.03602752834558487,
        0.041858263313770294,
        0.04941533878445625,
        0.045047271996736526,
        -0.01805170625448227,
        0.04298010841012001,
        -0.029972920194268227,
        0.04409051313996315,
        -0.05310281738638878,
        0.05292943865060806,
        -0.051043108105659485,
        0.05242113769054413,
        -0.049044057726860046,
        -0.04039250686764717,
        -0.04507613927125931,
        -0.0065789008513092995,
        0.052757810801267624,
        -0.05234566330909729,
        -0.05158170685172081,
        -0.0005664060590788722,
        -0.05313684418797493,
        -0.05297410115599632,
        -0.04354162514209747,
        -0.000012375745427561924,
        -0.05119634047150612,
        0.03151466324925423,
        -0.04017667472362518,
        -0.050089288502931595,
        0.03670264780521393,
        -0.04301339387893677,
        -0.05313717573881149,
        -0.034925419837236404,
        -0.05266398563981056,
        -0.045340344309806824,
        -0.036042552441358566,
        -0.052066925913095474,
        -0.04640026390552521,
        -0.02196611277759075,
        0.028266970068216324,
        0.0531376414000988,
        0.05243540555238724,
        -0.05313641577959061,
        0.04415288195014,
        0.05269046872854233,
        0.0519605316221714,
        0.05291301757097244,
        0.053119003772735596,
        0.03512047976255417,
        -0.030010325834155083,
        0.049655377864837646,
        -0.052842650562524796,
        -0.03267200291156769,
        -0.05311281234025955,
        0.019290488213300705,
        -0.021024160087108612,
        0.053135741502046585,
        0.05269063264131546,
        0.04912157729268074,
        -0.04809638112783432,
        0.04262777790427208,
        0.042775724083185196,
        0.05068942531943321,
        0.05312944948673248,
        -0.04966525360941887,
        0.05312742292881012,
        -0.05299163982272148,
        -0.05305337905883789,
        0.04852161929011345,
        -0.05270294472575188,
        0.050391074270009995,
        -0.05196524038910866,
        0.017284974455833435,
        0.017723912373185158,
        0.053120583295822144,
        0.05152622237801552,
        0.048262134194374084,
        0.05295146629214287,
        -0.009255637414753437,
        -0.05155463144183159,
        -0.05195049196481705,
        0.03672141954302788,
        0.003004923928529024,
        0.008018051274120808,
        0.052990105003118515,
        0.0329291857779026,
        -0.05259499326348305,
        -0.0499376505613327,
        -0.049815669655799866,
        0.038430582731962204,
        0.03733230382204056,
        -0.045035019516944885,
        -0.0525391548871994,
        0.01150879729539156,
        -0.053099676966667175,
        -0.053057245910167694,
        0.05010395869612694,
        -0.04903615266084671,
        0.0517725944519043,
        0.03969939425587654,
        0.03628306835889816,
        0.05312832444906235,
        -0.02749960869550705,
        0.05283075571060181,
        0.051555365324020386,
        -0.05030777305364609,
        0.052673112601041794,
        -0.053050123155117035,
        0.053135111927986145,
        -0.05300404876470566
    ],
    [
        -0.05836188793182373,
        0.03888966888189316,
        0.04498516768217087,
        -0.0322379432618618,
        -0.046846333891153336,
        -0.05075879022479057,
        0.053800348192453384,
        -0.0578511580824852,
        0.030132019892334938,
        -0.05420134216547012,
        0.05778605490922928,
        0.05037872865796089,
        -0.05840064957737923,
        -0.05548209697008133,
        -0.05726074054837227,
        0.037560783326625824,
        -0.051992256194353104,
        0.05000605434179306,
        -0.04164890572428703,
        0.02476542629301548,
        0.04652344435453415,
        0.029659215360879898,
        0.05841641128063202,
        -0.02785460650920868,
        -0.05838605761528015,
        0.007195375859737396,
        -0.0315573550760746,
        0.018547503277659416,
        -0.040278103202581406,
        -0.017689861357212067,
        -0.0545353889465332,
        0.04810529574751854,
        0.05660230666399002,
        -0.057072706520557404,
        0.05766824260354042,
        -0.03532835468649864,
        -0.056212786585092545,
        -0.058295805007219315,
        0.049569010734558105,
        -0.0583464540541172,
        -0.005712196696549654,
        0.03968850523233414,
        -0.031919967383146286,
        0.05134420841932297,
        -0.05597491189837456,
        -0.04797780141234398,
        -0.05815650895237923,
        0.057897768914699554,
        0.05645604059100151,
        -0.05550811067223549,
        0.053352657705545425,
        0.05819037929177284,
        -0.048675503581762314,
        -0.034144673496484756,
        0.0352034792304039,
        -0.02484341524541378,
        -0.031166449189186096,
        -0.010249380022287369,
        -0.05655907094478607,
        -0.04137178137898445,
        -0.05772322788834572,
        0.04475019872188568,
        -0.058016613125801086,
        -0.04527261480689049,
        0.038521815091371536,
        0.0014526841696351767,
        -0.05670541152358055,
        0.01485613826662302,
        -0.009569615125656128,
        0.020092127844691277,
        -0.0538904070854187,
        -0.05704833194613457,
        0.021861916407942772,
        0.05830265209078789,
        0.048703666776418686,
        -0.053026340901851654,
        0.057079799473285675,
        0.05838432535529137,
        -0.014044889248907566,
        0.04255404323339462,
        -0.05761871859431267,
        -0.05640251934528351,
        -0.05476810038089752,
        0.05587422475218773,
        0.03425820544362068,
        0.042575448751449585,
        -0.0453033447265625,
        -0.03548027575016022,
        -0.05783439055085182,
        0.05022628977894783,
        -0.0583055205643177,
        -0.05101804807782173,
        0.007401023525744677,
        -0.02036607265472412,
        -0.04886307567358017,
        0.050947219133377075,
        -0.050897106528282166,
        -0.05806386470794678,
        0.052723478525877,
        0.05781223252415657,
        -0.0583917461335659,
        0.05802421271800995,
        0.05341867357492447,
        0.043490201234817505,
        0.014484412036836147,
        0.0034549583215266466,
        0.05548790842294693,
        0.05364014208316803,
        -0.055781587958335876,
        0.00920086819678545,
        0.05363628268241882,
        0.05474850535392761,
        -0.031063787639141083,
        0.035211700946092606,
        -0.018024278804659843,
        -0.043172113597393036,
        -0.0330418236553669,
        -0.057337116450071335,
        -0.05543936416506767,
        -0.04526228830218315,
        0.0470883846282959,
        -0.03786410763859749,
        0.05770781263709068,
        -0.047413527965545654,
        -0.052730660885572433,
        -0.04496105760335922,
        -0.04775136709213257,
        -0.023612406104803085,
        0.05185243487358093,
        0.01970561221241951,
        -0.0025931342970579863,
        -0.018371978774666786,
        -0.0143333300948143,
        0.030006306245923042,
        0.0012471890076994896,
        -0.04779845476150513,
        0.05839214101433754,
        0.009141250513494015,
        0.032422829419374466,
        0.048488158732652664,
        0.02255597896873951,
        -0.025508619844913483,
        0.044883474707603455,
        0.024675579741597176,
        -0.05526680871844292,
        -0.009362873621284962,
        -0.020409397780895233,
        -0.0215923972427845,
        0.01578943431377411,
        0.05439036712050438,
        0.031207531690597534,
        -0.056903064250946045,
        -0.0436493456363678,
        0.03475778549909592,
        0.058453094214200974,
        -0.056484002619981766,
        -0.0566750131547451,
        0.04862991347908974,
        0.05803794786334038,
        0.04893042892217636,
        0.01550404354929924,
        0.04455031827092171,
        0.057707395404577255,
        0.03780664876103401,
        -0.057880666106939316,
        -0.05389373376965523,
        0.009463014081120491,
        0.054889947175979614,
        0.019032789394259453,
        0.0545499362051487,
        -0.05672476813197136,
        0.05446901544928551,
        -0.05245212838053703,
        0.05805792286992073,
        -0.048080842941999435,
        -0.032239481806755066,
        -0.04427659511566162,
        0.05655505880713463,
        0.05757724866271019,
        -0.05817895755171776,
        -0.05514570325613022,
        0.03364096209406853,
        -0.03024125099182129,
        -0.03400108590722084,
        -0.05519528687000275,
        0.058349959552288055,
        0.011764145456254482,
        0.05763759836554527,
        -0.046974413096904755,
        -0.020433582365512848,
        -0.05787838622927666,
        -0.032344166189432144,
        -0.02582722343504429,
        0.010194245725870132,
        0.00922928936779499,
        -0.01364382915198803,
        0.05452177673578262,
        0.055039387196302414,
        -0.00443834625184536,
        -0.029734328389167786,
        0.056398916989564896,
        -0.033133603632450104,
        -0.02776053175330162,
        -0.022708602249622345,
        0.054227374494075775,
        0.057887207716703415,
        0.015715252608060837,
        0.03800724819302559,
        -0.053756579756736755,
        0.05179429426789284,
        -0.05297940596938133,
        0.04076888784766197,
        0.045280952006578445,
        0.05799848958849907,
        -0.038710400462150574,
        0.02239443175494671,
        0.05844031646847725,
        -0.054787397384643555,
        0.05825057998299599,
        0.01039891317486763,
        -0.05177448317408562,
        0.05810113996267319,
        -0.016881465911865234,
        -0.03065742924809456,
        0.05833405256271362,
        -0.05715904012322426,
        0.05733004957437515,
        -0.030189990997314453,
        0.05067243054509163,
        -0.034601662307977676,
        0.03237412869930267,
        -0.0579662024974823,
        0.053494978696107864,
        0.05049843713641167,
        -0.05484304204583168,
        0.044672317802906036,
        0.05395176261663437,
        -0.010522376745939255,
        0.015548298135399818,
        0.05846098065376282,
        0.053996115922927856,
        0.014210994355380535,
        -0.044746480882167816,
        0.0021020842250436544,
        0.023568522185087204,
        -0.024845348671078682,
        0.015713388100266457,
        -0.027573302388191223,
        -0.042971234768629074,
        -0.05441892519593239,
        -0.05368565395474434,
        -0.058245547115802765,
        -0.00027069338830187917,
        -0.05465519800782204,
        0.04177958518266678,
        0.0402241013944149,
        -0.0366966538131237,
        -0.04933268949389458,
        0.03879999741911888,
        -0.043538544327020645,
        0.038290757685899734,
        0.004668101668357849,
        -0.003977298270910978,
        0.009907100349664688,
        0.010383712127804756,
        0.05728587135672569,
        0.050777368247509,
        0.0413689911365509,
        0.028903253376483917,
        -0.007169220130890608,
        -0.048839032649993896,
        -0.030827607959508896,
        0.022484347224235535,
        -0.05699123814702034,
        -0.05461673066020012,
        -0.04337279498577118,
        -0.050413019955158234,
        0.017322367057204247,
        -0.05817880854010582,
        -0.039767924696207047,
        -0.05774864926934242,
        0.02847030758857727,
        0.00719199376180768,
        0.0568341463804245,
        0.04773397371172905,
        0.05063316971063614,
        0.04879196360707283,
        0.058369509875774384,
        -0.05580472946166992,
        0.019093066453933716,
        0.009515216574072838,
        0.05590149760246277,
        -0.032732535153627396,
        -0.05205259472131729,
        0.05355459079146385,
        -0.05441965535283089,
        0.04247106611728668,
        0.05756014958024025,
        -0.02191195823252201,
        0.04873188957571983,
        0.044534750282764435,
        0.05585041642189026,
        0.04757082462310791,
        0.05460302531719208,
        -0.005688098259270191,
        -0.0504627600312233,
        -0.01128047239035368,
        0.04884187877178192,
        0.03584560006856918,
        -0.0049398476257920265,
        -0.050947390496730804,
        -0.03752925992012024,
        -0.05056137219071388,
        0.05017055571079254,
        -0.024044344201683998,
        -0.057687848806381226,
        0.019853847101330757,
        0.03238503634929657,
        0.05799752101302147,
        -0.0008413884788751602,
        0.036608170717954636,
        -0.02567419596016407,
        0.05315237119793892,
        0.03208450600504875,
        0.051463548094034195,
        0.04803811013698578,
        0.0029750773683190346,
        -0.046553321182727814,
        -0.025700382888317108,
        -0.048662636429071426,
        0.05693095922470093,
        -0.05193148925900459,
        -0.0563686303794384,
        0.05729525536298752,
        0.03187936916947365,
        0.02911231853067875,
        -0.05743114650249481,
        0.00025582712260074914,
        -0.04310363158583641,
        0.031450845301151276,
        0.05263034626841545,
        -0.04253147169947624,
        -0.02639622986316681,
        0.013277285732328892,
        -0.002119106939062476,
        -0.02226617932319641,
        0.0025310979690402746,
        0.05822557583451271,
        0.050848063081502914,
        0.00490601034834981,
        0.05838919058442116,
        -0.0504688136279583,
        0.037397600710392,
        -0.05812546983361244,
        -0.020181478932499886,
        0.01540615875273943,
        -0.047104645520448685,
        -0.05137801170349121,
        -0.03859476000070572,
        0.018794825300574303,
        0.04851271212100983,
        0.013205602765083313,
        0.0043518985621631145,
        -0.03190773352980614,
        0.027327461168169975,
        -0.05811145901679993,
        0.05227038264274597,
        0.05339787155389786,
        0.057166557759046555,
        -0.03998592495918274,
        -0.04392222687602043,
        -0.05823646858334541,
        0.051364559680223465,
        0.03509986773133278,
        0.05662676319479942,
        0.056582916527986526,
        0.04900914058089256,
        0.04628905653953552,
        0.05550665780901909,
        -0.03778877854347229,
        -0.02172420546412468,
        0.05525832623243332,
        -0.02590463124215603,
        0.05691172182559967,
        0.03787316009402275,
        -0.03441745787858963,
        0.05638052895665169,
        -0.05763636529445648,
        -0.05507194623351097,
        -0.04932382330298424,
        -0.055020108819007874,
        0.015135745517909527,
        -0.04781055822968483,
        -0.03716424107551575,
        0.025770753622055054,
        0.055083587765693665,
        0.05812014639377594,
        -0.013909651897847652,
        -0.05841680243611336,
        -0.05828016251325607,
        -0.051806915551424026,
        0.05738576874136925,
        -0.05430081486701965,
        0.016962990164756775,
        -0.05464843288064003,
        0.04170235991477966,
        0.02637149766087532,
        -0.05555183067917824,
        0.05478117614984512,
        -0.038827084004879,
        0.0030044736340641975,
        -0.05806227773427963,
        -0.019554950296878815,
        0.0478188656270504,
        0.05776805058121681,
        -0.055714476853609085,
        0.016600487753748894,
        0.0548701249063015,
        -0.04830622300505638,
        0.04944652318954468,
        0.029601017013192177,
        -0.05517413467168808,
        0.03899316489696503,
        -0.058423373848199844,
        0.024250095710158348,
        -0.01166946068406105,
        0.04707389697432518,
        -0.020787866786122322,
        -0.0010552711319178343,
        -0.05792943015694618,
        0.055899981409311295,
        0.050360023975372314,
        0.04200075566768646,
        -0.01721882075071335,
        -0.027957038953900337,
        -0.05789941921830177,
        -0.023311948403716087,
        0.026143385097384453,
        -0.04938744381070137,
        -0.03610515967011452,
        -0.055362049490213394,
        0.022418305277824402,
        0.058350563049316406,
        0.053620822727680206,
        -0.05820808187127113,
        0.04549486190080643,
        0.05791739746928215,
        -0.05800595134496689,
        0.03258740156888962,
        -0.007486728951334953,
        -0.053685884922742844,
        0.052907638251781464,
        0.023193204775452614,
        -0.05660581216216087,
        -0.05773290991783142,
        -0.058180637657642365,
        -0.04074068367481232,
        -0.056259747594594955,
        0.03583706542849541,
        -0.05132931470870972,
        0.03978513553738594,
        -0.04169179126620293,
        0.008929613046348095,
        0.008664783090353012,
        0.05832986161112785,
        0.058323755860328674,
        -0.04646284878253937,
        0.058222588151693344,
        -0.02481439895927906,
        -0.05491645634174347,
        0.057773467153310776,
        -0.031588491052389145,
        0.05781068280339241,
        0.058346040546894073,
        -0.04870835319161415,
        0.05271395295858383,
        0.03845151513814926,
        0.012202424928545952,
        -0.04913158714771271,
        0.034349873661994934,
        0.0010212472407147288,
        0.03250446915626526,
        -0.05736081302165985,
        0.02813456952571869,
        0.011707428842782974,
        0.0513177216053009,
        0.05106606334447861,
        0.05477331951260567,
        -0.052294205874204636,
        -0.017114661633968353,
        0.0035878275521099567,
        0.01725003309547901,
        0.051825281232595444,
        0.0519389733672142,
        -0.055453650653362274,
        0.04351417347788811,
        -0.026812318712472916,
        -0.0577644407749176,
        0.04254645109176636,
        0.01035759225487709,
        0.03995010256767273,
        0.04154035821557045,
        -0.018766524270176888,
        0.047172628343105316,
        0.011381253600120544,
        0.057179491966962814,
        0.02778884582221508,
        -0.027140581980347633,
        0.05833210051059723,
        -0.05824248120188713,
        0.058246538043022156,
        0.0253815408796072
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.05058012157678604,
        0.039897412061691284,
        0.03220268338918686,
        0.0034393544774502516,
        -0.009248713031411171,
        -0.053867850452661514,
        0.052301451563835144,
        -0.05368296802043915,
        0.05389544740319252,
        0.05217931419610977,
        0.05359162017703056,
        -0.007749033160507679,
        -0.053186044096946716,
        -0.034354399889707565,
        -0.05365725979208946,
        -0.032932911068201065,
        -0.0162532776594162,
        0.041205789893865585,
        0.028273794800043106,
        0.05174553394317627,
        -0.03277317434549332,
        0.05424996092915535,
        0.052213165909051895,
        -0.028164170682430267,
        -0.04935546591877937,
        -0.007330517750233412,
        -0.02145000547170639,
        0.04809742048382759,
        -0.05354221910238266,
        -0.027279168367385864,
        -0.05375918373465538,
        0.007353811524808407,
        0.04121958464384079,
        -0.053185611963272095,
        0.05389944091439247,
        0.05232541263103485,
        -0.05261685326695442,
        -0.05364615470170975,
        0.053078606724739075,
        -0.04294516518712044,
        0.05393941327929497,
        0.05180191248655319,
        0.042778994888067245,
        -0.00989610143005848,
        -0.01666896790266037,
        0.007834336720407009,
        0.052959565073251724,
        0.05241597816348076,
        0.054190341383218765,
        -0.05214540287852287,
        0.044239774346351624,
        -0.05421696603298187,
        -0.045367658138275146,
        -0.05371183902025223,
        -0.025007320567965508,
        0.05422131344676018,
        -0.05245381221175194,
        -0.037487879395484924,
        0.04758502542972565,
        -0.053776584565639496,
        -0.04971931129693985,
        0.03217630460858345,
        -0.05402083694934845,
        -0.047762319445610046,
        0.03185668960213661,
        0.05161837115883827,
        -0.05361020192503929,
        0.045225027948617935,
        0.04195021092891693,
        -0.04647638276219368,
        0.05330682173371315,
        -0.036222584545612335,
        0.05424907058477402,
        0.017683599144220352,
        0.05305366963148117,
        -0.030816808342933655,
        0.054208338260650635,
        0.05426142364740372,
        -0.009029342792928219,
        0.011731946840882301,
        -0.002503539202734828,
        -0.03038911148905754,
        -0.054049570113420486,
        0.0477522574365139,
        0.0398426428437233,
        0.049799349159002304,
        0.04714306443929672,
        -0.04801636561751366,
        -0.05347293242812157,
        -0.034729890525341034,
        0.017645228654146194,
        0.05124831199645996,
        0.02885359898209572,
        0.054133590310811996,
        0.0037622356321662664,
        0.05311819911003113,
        0.0372120626270771,
        -0.02141163870692253,
        -0.019665641710162163,
        -0.04506316035985947,
        -0.05423133820295334,
        0.05424446240067482,
        0.052260175347328186,
        0.04964897409081459,
        -0.04193121939897537,
        -0.05418689176440239,
        -0.040833648294210434,
        0.0530756376683712,
        -0.05004476383328438,
        -0.03490420803427696,
        0.046929869800806046,
        -0.05415638908743858,
        -0.000921468308661133,
        -0.04785345494747162,
        0.04023067653179169,
        -0.03207575902342796,
        0.05420737341046333,
        -0.04721233248710632,
        -0.029551273211836815,
        -0.03828836977481842,
        -0.05425429716706276,
        -0.04594304785132408,
        -0.05421151593327522,
        -0.05275517329573631,
        -0.037574756890535355,
        -0.053603801876306534,
        -0.053668659180402756,
        -0.01956275850534439,
        0.032022491097450256,
        0.02654586359858513,
        -0.05404077097773552,
        -0.04483390599489212,
        0.013608145527541637,
        0.053114522248506546,
        -0.0446627102792263,
        -0.054055459797382355,
        0.05419943109154701,
        -0.024075379595160484,
        -0.05152839049696922,
        0.0485067255795002,
        0.01431331504136324,
        -0.05394730716943741,
        0.049552395939826965,
        -0.05426090210676193,
        -0.049524638801813126,
        -0.014826503582298756,
        0.032456088811159134,
        -0.054226454347372055,
        0.02894020639359951,
        -0.052452269941568375,
        -0.05315065756440163,
        -0.05418165773153305,
        -0.04619001969695091,
        -0.053500451147556305,
        0.053800467401742935,
        -0.0542321540415287,
        0.05259275436401367,
        -0.05406936630606651,
        0.029626091942191124,
        -0.008870859630405903,
        0.015045800246298313,
        0.053769975900650024,
        0.051724717020988464,
        0.05415831878781319,
        -0.05235041677951813,
        -0.04930273815989494,
        0.053999822586774826,
        0.03583624213933945,
        0.04639654606580734,
        0.045053575187921524,
        -0.05369860306382179,
        0.04131060093641281,
        -0.054059017449617386,
        0.006641990505158901,
        0.02998233772814274,
        0.036676570773124695,
        0.02820332907140255,
        0.014426825568079948,
        0.054155584424734116,
        0.04626627638936043,
        -0.050405751913785934,
        -0.05081098526716232,
        0.0034741966519504786,
        0.054211411625146866,
        -0.04790613055229187,
        0.02514151856303215,
        -0.0541815422475338,
        0.043320171535015106,
        0.016826480627059937,
        0.05416884645819664,
        -0.05313105136156082,
        -0.01207423210144043,
        0.04821242764592171,
        -0.04339852184057236,
        0.018359286710619926,
        0.01586960442364216,
        -0.003381116781383753,
        0.04667842388153076,
        -0.05352157726883888,
        0.05265684425830841,
        0.0540497712790966,
        0.02834850549697876,
        0.048657748848199844,
        0.05365539342164993,
        0.0541984885931015,
        -0.04626237228512764,
        0.04799187555909157,
        0.05226091295480728,
        -0.05123864486813545,
        -0.054251618683338165,
        -0.043283239006996155,
        0.053176213055849075,
        0.03825356066226959,
        0.04635041952133179,
        -0.054091598838567734,
        -0.05226113647222519,
        0.04734057933092117,
        -0.023733951151371002,
        0.041934095323085785,
        -0.04808247089385986,
        0.048251546919345856,
        0.05154871568083763,
        0.03655615076422691,
        -0.03383435308933258,
        -0.04901820793747902,
        -0.05417513847351074,
        0.05289098620414734,
        -0.05298910290002823,
        0.04881099611520767,
        -0.05330660566687584,
        -0.05391904339194298,
        0.03831253573298454,
        -0.00976943876594305,
        0.05356680974364281,
        -0.04902120307087898,
        0.05403610318899155,
        0.05374996364116669,
        -0.05144786089658737,
        -0.050819724798202515,
        0.054034605622291565,
        0.020220307633280754,
        0.05240663141012192,
        -0.0019551757723093033,
        -0.009332709945738316,
        0.012192386202514172,
        0.04863870516419411,
        -0.05219372361898422,
        0.05398924648761749,
        -0.01659572869539261,
        -0.051119714975357056,
        -0.05392148345708847,
        -0.053724683821201324,
        0.04937615990638733,
        -0.053763460367918015,
        -0.03481948375701904,
        0.05266960337758064,
        0.043700505048036575,
        -0.05319208651781082,
        0.03555053845047951,
        -0.05380255728960037,
        -0.02472451888024807,
        -0.029635325074195862,
        -0.045330531895160675,
        0.05288148671388626,
        -0.040210019797086716,
        0.05413901433348656,
        0.05422089993953705,
        0.043578751385211945,
        -0.03394155576825142,
        0.012774216011166573,
        0.007469157688319683,
        -0.04211411625146866,
        0.05395929515361786,
        -0.05421958863735199,
        -0.03993144631385803,
        0.02838362380862236,
        -0.050151847302913666,
        -0.053482115268707275,
        -0.053623177111148834,
        0.04070090875029564,
        -0.04924354702234268,
        -0.05280212685465813,
        -0.027716856449842453,
        0.02593960240483284,
        0.034142062067985535,
        0.05424150079488754,
        0.02785317413508892,
        -0.05406991392374039,
        0.053793735802173615,
        -0.042043380439281464,
        -0.03574225679039955,
        -0.01982489787042141,
        -0.03572762385010719,
        -0.02963370829820633,
        0.006774094887077808,
        -0.054084084928035736,
        0.05420364812016487,
        -0.007017308380454779,
        -0.05168425664305687,
        0.05352037772536278,
        0.04546574875712395,
        0.034634895622730255,
        -0.0157463476061821,
        0.05411231145262718,
        -0.04304831102490425,
        0.05128924176096916,
        0.0028291530907154083,
        0.013056700117886066,
        0.04664503410458565,
        -0.026685237884521484,
        0.0534987673163414,
        -0.049832988530397415,
        0.0053226831369102,
        0.047775331884622574,
        0.048112113028764725,
        -0.0541895292699337,
        -0.047639887779951096,
        0.0542619414627552,
        0.053645167499780655,
        0.04880625754594803,
        0.01609368994832039,
        -0.05425863713026047,
        0.05401176959276199,
        -0.0397152341902256,
        0.05334809049963951,
        0.021696966141462326,
        0.04979567229747772,
        -0.053021710366010666,
        -0.04338622838258743,
        -0.05423721298575401,
        0.02351304143667221,
        -0.04918254166841507,
        -0.03549732640385628,
        -0.016273539513349533,
        0.04850638657808304,
        -0.008907021023333073,
        -0.05306309461593628,
        0.053764086216688156,
        0.02773655578494072,
        -0.038993433117866516,
        -0.04938023164868355,
        0.04123251885175705,
        0.04321334511041641,
        -0.04076389595866203,
        0.054262083023786545,
        0.05367029458284378,
        0.05403267592191696,
        0.054083745926618576,
        0.05423193424940109,
        0.053657565265893936,
        0.05423763766884804,
        -0.0014554793015122414,
        0.02968607470393181,
        -0.045810963958501816,
        0.0523688942193985,
        -0.002848532749339938,
        -0.05394382402300835,
        -0.017451893538236618,
        -0.051994454115629196,
        0.04732797294855118,
        0.05321471765637398,
        0.041333410888910294,
        -0.05109414830803871,
        -0.03983783721923828,
        0.054253388196229935,
        -0.05350106582045555,
        0.05298123136162758,
        -0.0481763519346714,
        0.03838476911187172,
        -0.052805591374635696,
        -0.04854564741253853,
        -0.05401664227247238,
        0.04576729238033295,
        0.024345217272639275,
        0.0487762875854969,
        0.05402204021811485,
        0.03512989729642868,
        -0.030554886907339096,
        0.05337385833263397,
        0.053483620285987854,
        0.04863862693309784,
        0.04666943475604057,
        -0.054026637226343155,
        0.0057383193634450436,
        0.04134165123105049,
        -0.05396198853850365,
        -0.023374119773507118,
        -0.037827521562576294,
        -0.03406579792499542,
        -0.05425097048282623,
        -0.007094170432537794,
        -0.040901895612478256,
        -0.03513295575976372,
        0.022216197103261948,
        0.005774510558694601,
        0.0537484772503376,
        0.046801239252090454,
        -0.05367179214954376,
        -0.0538228414952755,
        -0.05414999648928642,
        -0.052550263702869415,
        0.054195065051317215,
        -0.02023191936314106,
        0.05337533354759216,
        -0.02619539201259613,
        -0.031656574457883835,
        0.04093661159276962,
        -0.04028661549091339,
        0.05363047868013382,
        -0.022977560758590698,
        -0.04861057549715042,
        0.015171121805906296,
        0.04673874005675316,
        0.01410525944083929,
        -0.005267190746963024,
        -0.0506037212908268,
        -0.04214063659310341,
        0.0003592108841985464,
        0.054152317345142365,
        0.054225899279117584,
        0.05309300869703293,
        -0.054133713245391846,
        -0.013300333172082901,
        -0.05381494760513306,
        -0.005148165859282017,
        -0.02156575210392475,
        0.021802950650453568,
        0.052284471690654755,
        -0.04950268194079399,
        -0.024036502465605736,
        0.047895871102809906,
        -0.04263145104050636,
        0.03252166137099266,
        0.04560789465904236,
        0.05072931572794914,
        -0.053916946053504944,
        -0.05397103354334831,
        -0.003775001736357808,
        0.052010126411914825,
        -0.050457943230867386,
        -0.05342337116599083,
        0.04733613133430481,
        0.05426153913140297,
        0.053268056362867355,
        -0.047315847128629684,
        0.05380592495203018,
        0.015778839588165283,
        -0.05083782225847244,
        0.0230325385928154,
        0.05353744328022003,
        -0.04607547074556351,
        -0.044310200959444046,
        -0.05297886207699776,
        0.022077340632677078,
        0.04597962275147438,
        -0.04890197515487671,
        -0.05353906750679016,
        -0.03179112821817398,
        0.039611686021089554,
        0.04406362771987915,
        0.04872659593820572,
        -0.04995177686214447,
        0.05294690281152725,
        0.04818256199359894,
        0.05287068709731102,
        0.05412118509411812,
        -0.0542140007019043,
        0.05425502732396126,
        -0.05361083149909973,
        -0.05413026362657547,
        0.054085202515125275,
        -0.05343079939484596,
        0.04931856319308281,
        0.054253581911325455,
        -0.007866731844842434,
        0.045368511229753494,
        -0.05413505807518959,
        0.05422661080956459,
        0.017623497173190117,
        -0.05412891507148743,
        -0.054110050201416016,
        -0.01157442294061184,
        -0.05297137424349785,
        -0.030721772462129593,
        -0.05270965024828911,
        0.05382090061903,
        0.05355991795659065,
        -0.01994228921830654,
        -0.049076590687036514,
        -0.05417865142226219,
        0.01647878810763359,
        0.05217808485031128,
        -0.005300180055201054,
        0.016086960211396217,
        -0.004657939076423645,
        0.03482845053076744,
        -0.046881381422281265,
        -0.02036924473941326,
        0.05160781741142273,
        -0.0027903723530471325,
        -0.05361365154385567,
        -0.04091498628258705,
        -0.030938902869820595,
        0.04720819368958473,
        0.03275595232844353,
        0.045253198593854904,
        -0.021572433412075043,
        -0.05309290066361427,
        0.03886276111006737,
        -0.05405259504914284,
        0.05418850854039192,
        -0.04937932640314102
    ],
    [
        -0.05805286392569542,
        0.030428564175963402,
        0.05203627422451973,
        -0.015351112000644207,
        0.03762160986661911,
        0.058120518922805786,
        0.05792582407593727,
        -0.0478566437959671,
        -0.03859256953001022,
        0.057535745203495026,
        0.030887421220541,
        0.05514633655548096,
        -0.048746317625045776,
        0.013473531231284142,
        -0.05431653559207916,
        0.05391542613506317,
        -0.04659314081072807,
        0.05519736930727959,
        -0.053064439445734024,
        0.05635868012905121,
        -0.05538717284798622,
        -0.03591315448284149,
        -0.05646241083741188,
        0.05368290841579437,
        0.054345980286598206,
        0.042625702917575836,
        -0.028273768723011017,
        0.05493475869297981,
        -0.052387285977602005,
        -0.05754224956035614,
        0.054673247039318085,
        -0.03695310652256012,
        0.058054834604263306,
        -0.05535142868757248,
        0.028549907729029655,
        -0.03259273245930672,
        -0.04976082965731621,
        -0.01855030283331871,
        -0.023577339947223663,
        0.008294526487588882,
        -0.05037066340446472,
        0.02179761230945587,
        0.0524241141974926,
        -0.05790393799543381,
        0.0541665181517601,
        -0.03058241680264473,
        0.05087609216570854,
        0.057962533086538315,
        0.05695846676826477,
        0.027573850005865097,
        0.05521644279360771,
        0.05633067712187767,
        0.057461321353912354,
        -0.0049199871718883514,
        0.05794266238808632,
        -0.05808374285697937,
        -0.05057915672659874,
        -0.05290473252534866,
        -0.052707601338624954,
        -0.04175996035337448,
        -0.05705377832055092,
        -0.0033767949789762497,
        -0.04841174930334091,
        -0.05787166580557823,
        0.013870200142264366,
        0.055986449122428894,
        0.022455448284745216,
        0.03616378828883171,
        0.05414387956261635,
        -0.05813906341791153,
        -0.04294205829501152,
        -0.05805707350373268,
        0.05813060328364372,
        -0.04057716578245163,
        -0.05806891247630119,
        0.019281500950455666,
        0.02061697095632553,
        0.055966030806303024,
        0.0010278642876073718,
        -0.024294758215546608,
        -0.04828082397580147,
        -0.004063182044774294,
        -0.05479738116264343,
        0.038902729749679565,
        0.027009688317775726,
        0.022456472739577293,
        -0.0536535307765007,
        0.023894328624010086,
        -0.03840828686952591,
        0.05531367287039757,
        -0.0475025437772274,
        0.04157309979200363,
        0.05585325509309769,
        -0.053959764540195465,
        -0.005945059936493635,
        0.05429483577609062,
        0.02773342654109001,
        -0.04719669371843338,
        -0.05403102561831474,
        0.050162721425294876,
        -0.057302460074424744,
        0.047854576259851456,
        0.0578235387802124,
        0.020390234887599945,
        0.035051725804805756,
        0.0007579133962281048,
        -0.024006420746445656,
        0.034028034657239914,
        -0.045925769954919815,
        0.056708648800849915,
        0.02085571363568306,
        -0.031598642468452454,
        0.04601548984646797,
        -0.02077377773821354,
        0.05754292756319046,
        0.00989359337836504,
        -0.05595773085951805,
        0.020216017961502075,
        -0.0541519932448864,
        -0.016166770830750465,
        0.05331166461110115,
        -0.003948680125176907,
        -0.056678663939237595,
        0.049956273287534714,
        -0.057729512453079224,
        -0.05229422077536583,
        -0.05757647380232811,
        0.05769697576761246,
        0.01321595348417759,
        0.03319312632083893,
        -0.054515719413757324,
        -0.03726477175951004,
        0.045798055827617645,
        0.036744777113199234,
        -0.03502693399786949,
        -0.03196568414568901,
        0.05392872542142868,
        -0.057995833456516266,
        -0.055569037795066833,
        0.032546527683734894,
        0.05534917116165161,
        -0.04511868581175804,
        0.04687415063381195,
        -0.05480635166168213,
        -0.02153538167476654,
        0.037890881299972534,
        0.03870978206396103,
        0.029865574091672897,
        -0.057831570506095886,
        0.050054654479026794,
        0.05707018822431564,
        -0.05786509811878204,
        -0.05535471811890602,
        0.012345247901976109,
        0.05768418684601784,
        -0.05205630511045456,
        0.05688166618347168,
        0.019687717780470848,
        0.034886106848716736,
        0.023557135835289955,
        0.010436298325657845,
        0.011240538209676743,
        0.03758678957819939,
        -0.050543252378702164,
        -0.0371847003698349,
        0.007282310165464878,
        0.03553054481744766,
        -0.057937897741794586,
        0.03898945823311806,
        0.044952161610126495,
        -0.05276673659682274,
        -0.018654679879546165,
        -0.05586583539843559,
        0.05685640126466751,
        0.05084731802344322,
        0.05697941407561302,
        -0.056577861309051514,
        -0.00963702704757452,
        0.05649634450674057,
        -0.028190145269036293,
        -0.04284428805112839,
        -0.04988773912191391,
        0.025829333811998367,
        0.05539019778370857,
        0.05362057313323021,
        0.035619013011455536,
        -0.05278952792286873,
        0.054090384393930435,
        -0.04997291788458824,
        -0.0014446639688685536,
        -0.03561294823884964,
        0.007285805884748697,
        0.047219421714544296,
        0.015741053968667984,
        0.024022186174988747,
        -0.05440106987953186,
        0.05693643167614937,
        0.010593383572995663,
        -0.047143030911684036,
        0.021465344354510307,
        -0.02913406491279602,
        0.057520076632499695,
        0.052357424050569534,
        -0.05092896148562431,
        0.054186973720788956,
        0.030639225617051125,
        -0.034748226404190063,
        -0.05528714507818222,
        -0.05780681595206261,
        0.03879181668162346,
        -0.04722246900200844,
        0.05330134928226471,
        -0.05721443146467209,
        -0.009792448952794075,
        0.05501839518547058,
        -0.0210878886282444,
        0.03297945857048035,
        0.046783365309238434,
        0.05715790390968323,
        -0.04934360086917877,
        0.050581734627485275,
        0.026204068213701248,
        -0.05748993158340454,
        -0.05774363875389099,
        0.057583995163440704,
        -0.058012064546346664,
        -0.031938523054122925,
        0.04553249850869179,
        -0.0007400322356261313,
        -0.012208574451506138,
        0.00986148789525032,
        -0.05359466001391411,
        -0.04898417741060257,
        0.033799778670072556,
        0.01619955152273178,
        0.0451238788664341,
        0.05485422536730766,
        -0.043335020542144775,
        -0.05336674302816391,
        0.0574498325586319,
        0.03863636776804924,
        0.05485919862985611,
        -0.05526530370116234,
        0.02209109626710415,
        -0.033184755593538284,
        -0.03322750702500343,
        0.052986737340688705,
        -0.04932035505771637,
        -0.04967896640300751,
        -0.05802806094288826,
        -0.013047490268945694,
        -0.044930558651685715,
        0.057828083634376526,
        -0.0531834177672863,
        0.04983997717499733,
        0.058093104511499405,
        -0.005545838735997677,
        -0.05631463974714279,
        0.055287446826696396,
        0.048106368631124496,
        0.044842444360256195,
        0.058073852211236954,
        -0.028728369623422623,
        0.056429095566272736,
        0.05684402212500572,
        0.0562918484210968,
        -0.001926044118590653,
        0.0577814094722271,
        0.014434176497161388,
        0.05187584087252617,
        -0.024382758885622025,
        -0.05689485743641853,
        0.016570990905165672,
        -0.05391119047999382,
        -0.02434602379798889,
        0.041711363941431046,
        -0.0522443950176239,
        0.045689720660448074,
        -0.005349195562303066,
        -0.04576583951711655,
        -0.0539642870426178,
        -0.049049317836761475,
        -0.023388611152768135,
        -0.05460675433278084,
        -0.05603084713220596,
        -0.052938349545001984,
        0.0505048967897892,
        -0.05800686404109001,
        0.019976478070020676,
        0.03988876938819885,
        -0.054919637739658356,
        0.05464218184351921,
        0.016899239271879196,
        0.037698324769735336,
        0.05028899386525154,
        -0.032387472689151764,
        0.058052752166986465,
        0.033708084374666214,
        0.04393817111849785,
        0.04822341352701187,
        -0.02675279788672924,
        0.0542643778026104,
        -0.030956348404288292,
        -0.04453718662261963,
        0.039217449724674225,
        0.01892273686826229,
        0.014022545889019966,
        0.044318657368421555,
        0.04454716295003891,
        -0.054528459906578064,
        -0.04365992918610573,
        0.029205115512013435,
        -0.018930543214082718,
        0.05643432214856148,
        -0.054427001625299454,
        -0.052000485360622406,
        0.043417297303676605,
        0.05811481177806854,
        0.023854296654462814,
        -0.044293444603681564,
        -0.057756394147872925,
        0.05210314318537712,
        0.05671264976263046,
        -0.04158538952469826,
        0.037231456488370895,
        0.05773622915148735,
        -0.0025143802631646395,
        0.03930972144007683,
        -0.05236642435193062,
        -0.04874937981367111,
        -0.05207665264606476,
        -0.03659754619002342,
        0.05012159422039986,
        0.01668909192085266,
        0.05445999652147293,
        0.03249610215425491,
        -0.05556362867355347,
        0.052215948700904846,
        -0.041677504777908325,
        0.055236537009477615,
        0.035411905497312546,
        -0.0021305480040609837,
        0.053145404905080795,
        -0.0013091260334476829,
        0.05812486633658409,
        0.04732523113489151,
        0.038168881088495255,
        0.0521431565284729,
        -0.046631235629320145,
        0.0402815118432045,
        -0.058046307414770126,
        0.05809689685702324,
        -0.01980282925069332,
        -0.056694936007261276,
        -0.010733947157859802,
        0.033579371869564056,
        -0.05779694765806198,
        -0.03803656995296478,
        -0.052362795919179916,
        -0.0578930601477623,
        0.0540890134871006,
        -0.002323602559044957,
        -0.05704039707779884,
        -0.04896821826696396,
        0.051312386989593506,
        0.0028938266914337873,
        0.05811788886785507,
        -0.046116903424263,
        -0.007184508256614208,
        -0.058040227741003036,
        -0.026203729212284088,
        -0.05640341341495514,
        0.016214853152632713,
        0.01937982626259327,
        0.05586401745676994,
        0.05784892290830612,
        0.04852992296218872,
        0.03374190628528595,
        0.01096301432698965,
        -0.02443516254425049,
        -0.01809879019856453,
        0.032396506518125534,
        -0.05085337534546852,
        0.014421333558857441,
        0.055070001631975174,
        -0.01891663298010826,
        0.017044490203261375,
        -0.0559077151119709,
        -0.05373075604438782,
        -0.05811644718050957,
        -0.048169106245040894,
        -0.048176903277635574,
        0.018841220065951347,
        0.05112417787313461,
        -0.04307828098535538,
        0.057488247752189636,
        0.053422097116708755,
        -0.01869937591254711,
        -0.05768003314733505,
        -0.057990748435258865,
        -0.04925084486603737,
        0.02156040072441101,
        0.054525528103113174,
        0.058056361973285675,
        -0.018759336322546005,
        0.05721962824463844,
        0.042660247534513474,
        -0.007897498086094856,
        0.05128072202205658,
        0.008255835622549057,
        0.046441178768873215,
        -0.04101613163948059,
        -0.017838800325989723,
        0.04360473155975342,
        0.0568401999771595,
        0.028573188930749893,
        -0.02276415191590786,
        0.05327291414141655,
        0.036085717380046844,
        -0.04279814660549164,
        -0.05585160478949547,
        -0.05210473760962486,
        -0.04732741415500641,
        -0.05723792687058449,
        0.04891929030418396,
        -0.007972425781190395,
        0.01824745163321495,
        -0.05589550733566284,
        -0.02350020408630371,
        -0.043673381209373474,
        0.011776772327721119,
        -0.03375846520066261,
        -0.028814615681767464,
        -0.05699577182531357,
        0.05341777577996254,
        -0.042990975081920624,
        -0.027391694486141205,
        0.024362750351428986,
        -0.040988389402627945,
        -0.05186576768755913,
        0.04910094663500786,
        -0.027737796306610107,
        0.05764511227607727,
        0.05259208381175995,
        0.011257887817919254,
        0.04331548139452934,
        0.05503738299012184,
        0.052583735436201096,
        0.04705030471086502,
        -0.008490491658449173,
        0.03315899521112442,
        0.054884783923625946,
        0.0563029944896698,
        -0.04525526985526085,
        0.014495798386633396,
        -0.038441307842731476,
        0.05349208787083626,
        -0.032932013273239136,
        -0.02154773287475109,
        0.054583702236413956,
        0.052961841225624084,
        0.0555398128926754,
        -0.0033183509949594736,
        0.0573262944817543,
        0.05780806764960289,
        0.05759178474545479,
        -0.05499395728111267,
        0.05691693723201752,
        -0.05032392591238022,
        -0.05715029686689377,
        0.05733729153871536,
        0.014047251082956791,
        0.044708333909511566,
        -0.025709642097353935,
        -0.0284165870398283,
        0.006853615865111351,
        0.04801125079393387,
        0.04763202369213104,
        -0.05710576847195625,
        0.005430586636066437,
        0.05808129906654358,
        -0.017641188576817513,
        0.022752007469534874,
        0.03206222131848335,
        -0.005775540601462126,
        0.04111557453870773,
        0.0329565592110157,
        0.0015517740976065397,
        -0.0525449775159359,
        0.03552338853478432,
        -0.0579339973628521,
        -0.05123763158917427,
        0.038206636905670166,
        -0.011082304641604424,
        0.0500955730676651,
        0.05602654069662094,
        -0.058115243911743164,
        -0.0008304276270791888,
        0.04911963269114494,
        0.043291132897138596,
        0.058049604296684265,
        0.006733663845807314,
        -0.013451237231492996,
        -0.0074577778577804565,
        -0.0570833720266819,
        0.024550721049308777,
        0.016216706484556198,
        -0.014299913309514523,
        0.020254354923963547,
        0.04189695417881012,
        0.057999953627586365,
        0.03964252397418022
    ],
    [
        -0.0414605513215065,
        -0.04335248842835426,
        -0.05392397195100784,
        -0.03570510819554329,
        0.04609211906790733,
        0.04504534974694252,
        0.052541639655828476,
        -0.053900446742773056,
        0.050790898501873016,
        0.015460660681128502,
        0.05319288745522499,
        -0.005903529468923807,
        -0.052654825150966644,
        0.02632872201502323,
        -0.05376499146223068,
        -0.0539279468357563,
        0.051935404539108276,
        0.053552839905023575,
        0.04987595975399017,
        0.05381929501891136,
        -0.05223661661148071,
        0.05371903255581856,
        -0.030798062682151794,
        0.051727116107940674,
        -0.04485037177801132,
        0.051912158727645874,
        -0.04634815827012062,
        0.05333513393998146,
        -0.05225633829832077,
        -0.0537617988884449,
        -0.013782704249024391,
        0.05371372774243355,
        0.04221218824386597,
        -0.05287586525082588,
        0.04804673418402672,
        -0.052381984889507294,
        -0.0502476841211319,
        -0.039958544075489044,
        0.05384647473692894,
        -0.01691327430307865,
        0.021475890651345253,
        0.05203293636441231,
        0.05174233019351959,
        -0.05391449108719826,
        -0.035287853330373764,
        -0.02710650861263275,
        0.029137620702385902,
        0.05387022718787193,
        0.05385564640164375,
        -0.0389186255633831,
        0.050772909075021744,
        -0.053925033658742905,
        -0.04790572077035904,
        -0.05246186628937721,
        -0.04714363440871239,
        0.03619886562228203,
        -0.04743247479200363,
        -0.013501440174877644,
        -0.05239051580429077,
        -0.05230499804019928,
        -0.05161529406905174,
        0.03747210279107094,
        -0.0536690391600132,
        -0.053586117923259735,
        -0.023803086951375008,
        0.024967707693576813,
        -0.05322485789656639,
        0.03875269368290901,
        -0.020781345665454865,
        0.026219002902507782,
        0.04620128870010376,
        -0.05235208570957184,
        0.05392814800143242,
        -0.01939038559794426,
        0.053904417902231216,
        0.03906681761145592,
        0.05392429232597351,
        0.053902529180049896,
        -0.05170820653438568,
        0.04896916076540947,
        -0.034965455532073975,
        -0.048704273998737335,
        -0.053882136940956116,
        0.028325216844677925,
        0.05331147462129593,
        0.02291441336274147,
        -0.047116998583078384,
        -0.05213426426053047,
        -0.05392802879214287,
        0.047970108687877655,
        -0.053328175097703934,
        -0.05242585018277168,
        0.03259064257144928,
        0.053897783160209656,
        -0.03365195915102959,
        0.05366015061736107,
        0.014779460616409779,
        -0.04636896774172783,
        -0.04191722720861435,
        0.05293184518814087,
        -0.05377880856394768,
        0.0537259578704834,
        0.053649645298719406,
        -0.0010369137162342668,
        0.026804551482200623,
        0.040669649839401245,
        -0.04815881326794624,
        0.053136419504880905,
        -0.053799908608198166,
        0.04130430892109871,
        -0.05305611714720726,
        -0.04195139929652214,
        -0.0035013980232179165,
        0.05269661545753479,
        0.04467795789241791,
        0.002340680453926325,
        -0.04994979128241539,
        -0.040915049612522125,
        0.03872189670801163,
        -0.05357081815600395,
        0.005190616473555565,
        -0.047015294432640076,
        -0.053928080946207047,
        -0.053736362606287,
        -0.004923747852444649,
        0.03167295455932617,
        -0.0032685566693544388,
        -0.02838609181344509,
        0.05319656431674957,
        0.029093386605381966,
        -0.05329335480928421,
        -0.05264497920870781,
        -0.0037238108925521374,
        0.05264660716056824,
        -0.0015555694699287415,
        -0.05331912636756897,
        0.05363534390926361,
        -0.02871677652001381,
        -0.05285683646798134,
        0.03654290363192558,
        -0.03457865118980408,
        -0.05353507772088051,
        0.05093951150774956,
        -0.05392814800143242,
        0.009635655209422112,
        -0.03181465342640877,
        -0.0013781043235212564,
        -0.048015713691711426,
        -0.049309663474559784,
        0.042702749371528625,
        -0.025997519493103027,
        -0.05392574518918991,
        0.04758373275399208,
        -0.024551808834075928,
        -0.05086558684706688,
        -0.053843799978494644,
        -0.0016878715250641108,
        -0.028854796662926674,
        0.03273879364132881,
        -0.040960147976875305,
        -0.05361520126461983,
        0.053820837289094925,
        0.051327355206012726,
        0.04776593670248985,
        -0.03998600319027901,
        0.05056460574269295,
        0.053612079471349716,
        -0.006775882560759783,
        0.050576336681842804,
        -0.009300817735493183,
        -0.050624433904886246,
        -0.024855831637978554,
        -0.05377811938524246,
        0.03995393216609955,
        0.020175378769636154,
        0.042963456362485886,
        0.04955175518989563,
        0.008519540540874004,
        0.0536646768450737,
        0.02832946740090847,
        -0.05341723933815956,
        -0.05115027353167534,
        0.007947942242026329,
        0.053928110748529434,
        -0.04793568700551987,
        -0.015729248523712158,
        0.05316302552819252,
        0.05369922146201134,
        0.05196770653128624,
        -0.03714138641953468,
        -0.053923845291137695,
        0.015012204647064209,
        0.053682733327150345,
        0.04778480529785156,
        -0.05296837165951729,
        -0.05375150218605995,
        0.053086258471012115,
        0.04515143111348152,
        -0.05290555953979492,
        0.053445108234882355,
        -0.019811926409602165,
        -0.03784019500017166,
        0.05168214440345764,
        -0.02292308583855629,
        0.05288318917155266,
        -0.05384773015975952,
        -0.04652447998523712,
        -0.051959581673145294,
        -0.05376970395445824,
        -0.05392814427614212,
        -0.053728148341178894,
        0.02326463721692562,
        0.040627025067806244,
        0.05390459671616554,
        -0.0539056621491909,
        0.016386469826102257,
        0.03030530735850334,
        0.010131767950952053,
        0.01294746994972229,
        0.020484033972024918,
        0.05222893878817558,
        0.05364140495657921,
        -0.02628164365887642,
        0.04879105091094971,
        0.0527447834610939,
        -0.04926692694425583,
        0.05314028263092041,
        -0.051881905645132065,
        -0.0034727214369922876,
        0.02190161682665348,
        -0.05055544525384903,
        -0.05382766202092171,
        0.04917695373296738,
        0.05162358283996582,
        -0.05391255021095276,
        0.0537603534758091,
        0.04229448363184929,
        -0.030115922912955284,
        -0.011831999756395817,
        0.05386589094996452,
        -0.03562605381011963,
        0.044810786843299866,
        -0.052265118807554245,
        -0.05051537975668907,
        0.02353494241833687,
        -0.04041886702179909,
        -0.05247482284903526,
        -0.0034177014604210854,
        -0.008209939114749432,
        -0.04602453485131264,
        -0.047767266631126404,
        -0.051321931183338165,
        0.053818557411432266,
        -0.051260024309158325,
        0.05361417308449745,
        0.053687792271375656,
        0.030820151790976524,
        -0.04998289793729782,
        0.049563705921173096,
        -0.042935810983181,
        -0.052793193608522415,
        0.017708107829093933,
        -0.05345972254872322,
        0.05392079055309296,
        -0.05385575816035271,
        0.05391758307814598,
        -0.04792904108762741,
        0.05364656075835228,
        0.053605955094099045,
        0.052063822746276855,
        0.03702625259757042,
        -0.05260568857192993,
        0.018796293064951897,
        -0.05367604270577431,
        -0.0461588054895401,
        0.04365314543247223,
        -0.05240561068058014,
        -0.015162356197834015,
        0.04676193371415138,
        0.005081112962216139,
        -0.053550027310848236,
        -0.049647193402051926,
        -0.050169438123703,
        -0.023351594805717468,
        0.0492834746837616,
        0.053927816450595856,
        0.024037880823016167,
        -0.053925152868032455,
        -0.008237446658313274,
        0.03587957099080086,
        -0.05343363434076309,
        0.02037811279296875,
        0.047996241599321365,
        0.026709796860814095,
        0.04906181991100311,
        -0.047676581889390945,
        0.05381932854652405,
        -0.03303647041320801,
        -0.048617418855428696,
        0.03934197127819061,
        -0.02586653269827366,
        0.030510801821947098,
        -0.014018725603818893,
        0.008764000609517097,
        0.05312429741024971,
        0.041737865656614304,
        0.03972109779715538,
        -0.053272273391485214,
        0.017353978008031845,
        0.03724336251616478,
        0.05392796918749809,
        0.016169670969247818,
        0.0498243011534214,
        0.03899533674120903,
        0.05110296607017517,
        -0.05329342931509018,
        -0.009175216779112816,
        0.05392814427614212,
        0.05007274076342583,
        -0.0370403528213501,
        0.03126116469502449,
        0.028224993497133255,
        0.05313655361533165,
        0.031010586768388748,
        0.04635132849216461,
        0.023265644907951355,
        -0.05119872838258743,
        -0.05048765614628792,
        -0.05385827645659447,
        -0.053439002484083176,
        0.05370477959513664,
        -0.05256902799010277,
        -0.053706541657447815,
        -0.05381794646382332,
        0.005145681090652943,
        0.05101288482546806,
        -0.03504902124404907,
        0.009642098098993301,
        0.049659620970487595,
        0.00027738255448639393,
        -0.05273837968707085,
        -0.04740510880947113,
        0.042707208544015884,
        0.031377945095300674,
        0.05392814800143242,
        0.04785793647170067,
        0.05313614755868912,
        0.053613122552633286,
        -0.051207978278398514,
        0.03780258074402809,
        0.02914215251803398,
        0.05391674488782883,
        0.05096324160695076,
        -0.05380084365606308,
        0.04023399204015732,
        -0.03898753225803375,
        -0.04859377443790436,
        -0.046562615782022476,
        -0.0432075560092926,
        0.052572958171367645,
        0.01709742099046707,
        0.0018133650301024318,
        -0.05121350660920143,
        -0.04921408370137215,
        0.05392814800143242,
        -0.051829636096954346,
        0.05390706658363342,
        -0.030095690861344337,
        -0.03554975986480713,
        -0.05384426563978195,
        -0.03902887552976608,
        -0.053925927728414536,
        -0.0007203760324046016,
        0.041800014674663544,
        0.044721025973558426,
        0.05392787978053093,
        0.05205335095524788,
        -0.05376642197370529,
        0.05384662374854088,
        0.04896440729498863,
        0.04584231972694397,
        -0.028133103623986244,
        -0.04874153807759285,
        0.04540662840008736,
        0.019905224442481995,
        -0.049970127642154694,
        -0.053925368934869766,
        -0.0344824343919754,
        -0.022952374070882797,
        -0.05210766941308975,
        -0.047815196216106415,
        0.034046437591314316,
        -0.053899455815553665,
        -0.042243946343660355,
        0.051025405526161194,
        0.0536806620657444,
        -0.04960049316287041,
        -0.04829458147287369,
        -0.049776460975408554,
        -0.051446229219436646,
        -0.05060316622257233,
        0.053905777633190155,
        0.027672000229358673,
        0.05389959737658501,
        -0.05388921871781349,
        0.0033387369476258755,
        -0.04236101731657982,
        -0.038526661694049835,
        0.04403972998261452,
        -0.05007114261388779,
        0.05209901183843613,
        -0.03171299770474434,
        0.05151914805173874,
        -0.04005936160683632,
        0.05229151248931885,
        -0.028773808851838112,
        -0.04029624164104462,
        -0.052429720759391785,
        -0.004343817010521889,
        0.0525844506919384,
        0.0373358279466629,
        -0.05347174406051636,
        -0.051244888454675674,
        -0.05392507463693619,
        0.024655722081661224,
        -0.05362814664840698,
        0.045745205134153366,
        -0.044885288923978806,
        -0.03419167548418045,
        -0.05386274680495262,
        0.051189932972192764,
        0.028680603951215744,
        0.003266338724642992,
        -0.05301697924733162,
        -0.05135469138622284,
        -0.05067084729671478,
        -0.03381621092557907,
        0.026713669300079346,
        -0.023702207952737808,
        -0.053468335419893265,
        0.03425191715359688,
        -0.00950669962912798,
        0.05392814800143242,
        0.0493362620472908,
        -0.05323383957147598,
        0.05206367373466492,
        0.05126972869038582,
        0.0374084897339344,
        -0.04702777788043022,
        0.05390369892120361,
        0.02979322522878647,
        0.05371330678462982,
        -0.0236914474517107,
        -0.0017462478717789054,
        0.04837441444396973,
        -0.045861080288887024,
        -0.03712000697851181,
        -0.05333823710680008,
        0.04923936724662781,
        0.05182768404483795,
        0.04143223911523819,
        0.040281400084495544,
        0.03658635914325714,
        -0.053077101707458496,
        0.05374982953071594,
        0.053862523287534714,
        -0.044994037598371506,
        0.05392325296998024,
        -0.013346805237233639,
        -0.05388551577925682,
        0.04467647522687912,
        -0.053922154009342194,
        0.04605930671095848,
        -0.021197818219661713,
        0.03820051625370979,
        0.04922526702284813,
        0.05278097465634346,
        0.05383956804871559,
        -0.05328869819641113,
        -0.053627852350473404,
        -0.04771739989519119,
        -0.05031469836831093,
        0.02591763250529766,
        0.007741853129118681,
        0.028393112123012543,
        0.029315756633877754,
        0.01541966013610363,
        0.0462169386446476,
        -0.053221914917230606,
        -0.05385303497314453,
        -0.018784599378705025,
        0.027656959369778633,
        0.03372138366103172,
        -0.020191941410303116,
        0.010095912963151932,
        0.04251566156744957,
        -0.05386043339967728,
        0.05285410210490227,
        0.05283082649111748,
        -0.04159772768616676,
        0.04325980320572853,
        0.045854538679122925,
        0.03233794495463371,
        -0.029740838333964348,
        -0.05142802745103836,
        0.053827106952667236,
        0.053650159388780594,
        -0.004679880104959011,
        0.05360494181513786,
        -0.013106442987918854,
        0.05264679715037346,
        -0.05392400547862053
    ],
    [
        -0.05390508100390434,
        -0.03575677052140236,
        -0.05807208642363548,
        0.03620091453194618,
        0.05982991307973862,
        -0.05106990411877632,
        0.05518093332648277,
        -0.012148291803896427,
        0.004757661372423172,
        0.05316580832004547,
        0.058891817927360535,
        0.051102977246046066,
        -0.051820237189531326,
        -0.021905986592173576,
        -0.056438930332660675,
        -0.05932801961898804,
        -0.0577494315803051,
        -0.03460053354501724,
        -0.029790693894028664,
        0.05741414427757263,
        -0.050660550594329834,
        0.05829642713069916,
        0.05492229014635086,
        0.04146341234445572,
        0.0031033814884722233,
        0.005083069205284119,
        -0.0094058932736516,
        0.03590305894613266,
        0.0012535391142591834,
        -0.05044809728860855,
        0.0449836365878582,
        0.049797724932432175,
        0.058795154094696045,
        -0.05957448109984398,
        0.020364638417959213,
        -0.060293883085250854,
        -0.041773535311222076,
        -0.053154356777668,
        0.038566432893276215,
        0.037455447018146515,
        -0.021189378574490547,
        -0.01900854893028736,
        0.04961821809411049,
        -0.056450553238391876,
        -0.006258743815124035,
        -0.037457190454006195,
        0.06097152456641197,
        0.06071449816226959,
        0.05976103991270065,
        0.006103988736867905,
        -0.015397596172988415,
        -0.06100258231163025,
        0.05321352556347847,
        -0.054749567061662674,
        -0.03369694948196411,
        -0.006063057109713554,
        -0.010476196184754372,
        -0.03308381512761116,
        -0.02982436865568161,
        -0.010496825911104679,
        -0.055829208344221115,
        -0.024315480142831802,
        -0.04653526097536087,
        -0.008622966706752777,
        -0.016369950026273727,
        0.059487234801054,
        -0.038809634745121,
        0.01901691034436226,
        -0.05979261174798012,
        -0.0595548152923584,
        -0.05599429830908775,
        -0.060647811740636826,
        0.06142408028244972,
        0.004714879207313061,
        0.00623299228027463,
        0.03976266831159592,
        0.061090137809515,
        0.058751147240400314,
        -0.0468880794942379,
        0.0061432127840816975,
        -0.0325179360806942,
        0.008692201226949692,
        -0.06076277419924736,
        0.0440991185605526,
        0.04545087739825249,
        0.0210568904876709,
        -0.02160271629691124,
        -0.03655634820461273,
        -0.06058329716324806,
        0.0557740144431591,
        -0.06037363037467003,
        0.0524040088057518,
        0.0455126017332077,
        0.0455697700381279,
        -0.017861537635326385,
        0.05169573053717613,
        -0.05014917999505997,
        -0.027491239830851555,
        -0.05778004601597786,
        0.01510230265557766,
        -0.06037558615207672,
        0.053479794412851334,
        0.05216015502810478,
        -0.061041224747896194,
        0.056628014892339706,
        -0.04206690192222595,
        0.033418379724025726,
        0.05911164730787277,
        -0.03933413326740265,
        0.048208367079496384,
        0.002078911056742072,
        -0.04470641538500786,
        -0.04714053496718407,
        0.04456952586770058,
        -0.05316147208213806,
        0.030439823865890503,
        0.035740528255701065,
        -0.05194022133946419,
        -0.06012830138206482,
        -0.004625567700713873,
        0.051656726747751236,
        0.031831201165914536,
        -0.06140998750925064,
        -0.034027207642793655,
        -0.05065959692001343,
        0.007045204285532236,
        -0.021673966199159622,
        -0.04590856283903122,
        0.037268273532390594,
        0.022802336141467094,
        -0.058664243668317795,
        -0.03447992354631424,
        0.007430208381265402,
        0.04606315493583679,
        -0.005612112581729889,
        -0.045822624117136,
        0.044867344200611115,
        -0.040259793400764465,
        -0.05410617217421532,
        0.051108863204717636,
        0.03321469575166702,
        -0.04245000332593918,
        0.0356924794614315,
        -0.061427801847457886,
        -0.046386342495679855,
        -0.05357988551259041,
        0.043410252779722214,
        -0.03379695490002632,
        -0.05836794897913933,
        0.05992833524942398,
        -0.02635551244020462,
        -0.06036882847547531,
        0.006884609814733267,
        -0.004701397381722927,
        -0.029128119349479675,
        -0.05729399621486664,
        0.05845498666167259,
        0.020943762734532356,
        0.047088027000427246,
        0.053245361894369125,
        0.025264931842684746,
        0.06083479896187782,
        0.021971892565488815,
        -0.00785794947296381,
        -0.04714779928326607,
        0.029159370809793472,
        0.0419699028134346,
        -0.054795123636722565,
        0.06063121557235718,
        0.047832224518060684,
        -0.038477446883916855,
        -0.036654021590948105,
        -0.058755241334438324,
        0.010420874692499638,
        -0.03108622133731842,
        0.04477259889245033,
        -0.019107047468423843,
        0.031632792204618454,
        0.05705501511693001,
        0.029670478776097298,
        0.03443988785147667,
        -0.035083383321762085,
        0.05662889406085014,
        0.06141982972621918,
        0.0059220632538199425,
        0.024457428604364395,
        -0.028923165053129196,
        0.05250319465994835,
        0.013124726712703705,
        0.006936108693480492,
        -0.040324095636606216,
        -0.028897857293486595,
        0.05303953215479851,
        0.0610358901321888,
        -0.02945001795887947,
        -0.05929787456989288,
        0.05859088525176048,
        -0.024219179525971413,
        -0.05568641796708107,
        0.019736722111701965,
        -0.05643762648105621,
        0.052035968750715256,
        -0.009029039181768894,
        0.05382542684674263,
        0.05250384286046028,
        0.047491464763879776,
        -0.03996371105313301,
        -0.05985371768474579,
        -0.05545152723789215,
        -0.06142790615558624,
        -0.052369579672813416,
        0.05518458038568497,
        0.05554443225264549,
        0.05793418362736702,
        -0.025544755160808563,
        0.03889523819088936,
        -0.011278086341917515,
        0.02924584038555622,
        -0.023095708340406418,
        -0.05321364477276802,
        0.04476182907819748,
        0.061387207359075546,
        -0.0596468560397625,
        -0.05442588031291962,
        0.05968642607331276,
        -0.060188986361026764,
        0.04836900904774666,
        -0.00429273908957839,
        0.004519263748079538,
        0.040058594197034836,
        0.03671674802899361,
        -0.05999606475234032,
        0.04927849397063255,
        0.05522647127509117,
        -0.042574793100357056,
        0.03189168497920036,
        0.05140373110771179,
        -0.01213919185101986,
        0.005736693739891052,
        0.061308931559324265,
        -0.03197784721851349,
        0.045394282788038254,
        -0.059885427355766296,
        0.06014018878340721,
        0.0066753290593624115,
        -0.04849713295698166,
        -0.054811738431453705,
        -0.038000185042619705,
        -0.020420201122760773,
        -0.05685306712985039,
        -0.03718200698494911,
        -0.060491856187582016,
        0.0289259385317564,
        -0.05374178662896156,
        0.060986775904893875,
        0.059983253479003906,
        0.057240355759859085,
        -0.024827053770422935,
        0.059361618012189865,
        0.032026153057813644,
        0.04960725083947182,
        0.04708658903837204,
        -0.0413660928606987,
        0.06099383905529976,
        -0.06034103035926819,
        0.05898167937994003,
        0.040640681982040405,
        0.045042525976896286,
        0.03721814975142479,
        0.05212458595633507,
        -0.05928723141551018,
        -0.059065431356430054,
        -0.0511593334376812,
        -0.05946613848209381,
        -0.01926482282578945,
        -0.008881782181560993,
        -0.0598769374191761,
        -0.003056078450754285,
        -0.06120931729674339,
        0.011901565827429295,
        -0.037548817694187164,
        -0.06088986247777939,
        0.03252861648797989,
        0.05964792147278786,
        0.053986985236406326,
        0.05978422984480858,
        0.0559961162507534,
        -0.06142181158065796,
        -0.049992676824331284,
        0.05274641141295433,
        0.030303170904517174,
        0.042551543563604355,
        0.05382915213704109,
        -0.006641441956162453,
        0.029287047684192657,
        -0.0007118749199435115,
        0.05938531830906868,
        0.041275084018707275,
        -0.008765347301959991,
        0.05126682668924332,
        -0.042954832315444946,
        0.0540730282664299,
        0.010667884722352028,
        -0.05604538694024086,
        0.0025235237553715706,
        -0.04214386269450188,
        -0.04103875532746315,
        -0.05853467062115669,
        0.04212387651205063,
        0.029964013025164604,
        0.06095659360289574,
        -0.04252113029360771,
        -0.03857537731528282,
        -0.0073405844159424305,
        -0.009500008076429367,
        -0.05582388862967491,
        0.058875709772109985,
        0.06140245497226715,
        0.04337352141737938,
        -0.048873357474803925,
        0.019273770973086357,
        0.06111004576086998,
        0.055114008486270905,
        -0.06035831570625305,
        0.05419149994850159,
        0.05688345432281494,
        -0.006589887663722038,
        -0.03676380217075348,
        -0.03141824156045914,
        -0.03480762988328934,
        0.05909004062414169,
        0.041949059814214706,
        -0.04628356173634529,
        -0.0218645166605711,
        0.047550003975629807,
        0.003246312029659748,
        -0.049733154475688934,
        0.02938852831721306,
        -0.039791058748960495,
        0.02629249542951584,
        -0.0341256782412529,
        -0.02802165225148201,
        0.056278400123119354,
        0.006722626276314259,
        0.061429109424352646,
        0.03694789111614227,
        0.05395827069878578,
        0.04837065562605858,
        -0.06117769703269005,
        0.048864562064409256,
        -0.058541398495435715,
        0.04749919846653938,
        -0.03252803534269333,
        -0.059507548809051514,
        -0.0021381813567131758,
        0.04583899676799774,
        -0.061351533979177475,
        -0.016014376655220985,
        -0.04092761501669884,
        -0.05647101625800133,
        0.0286057461053133,
        0.04713166877627373,
        -0.0551268644630909,
        -0.017131971195340157,
        0.061394914984703064,
        -0.035934899002313614,
        0.061250507831573486,
        -0.019196411594748497,
        0.024077661335468292,
        -0.060925617814064026,
        -0.04102547839283943,
        -0.059820376336574554,
        0.021412450820207596,
        0.05944385379552841,
        0.050317827612161636,
        0.04842269793152809,
        0.054334476590156555,
        -0.05830562487244606,
        -0.0006026469054631889,
        0.00732657453045249,
        0.042444828897714615,
        0.008337547071278095,
        -0.05894271656870842,
        0.005884157493710518,
        0.03471019119024277,
        -0.04799653962254524,
        -0.0599215030670166,
        -0.045025866478681564,
        -0.012856116518378258,
        -0.05606885626912117,
        -0.04772161319851875,
        0.051486387848854065,
        -0.046222783625125885,
        0.039935722947120667,
        -0.04969236999750137,
        0.05445907264947891,
        -0.05789917707443237,
        -0.047258198261260986,
        -0.05947534367442131,
        -0.0612248070538044,
        -0.0544368214905262,
        0.05515710636973381,
        0.060204748064279556,
        0.044900476932525635,
        -0.06055603548884392,
        0.058215271681547165,
        -0.05130285769701004,
        -0.027993174269795418,
        0.05271947756409645,
        -0.024562183767557144,
        0.060557421296834946,
        0.008437792770564556,
        -0.055008966475725174,
        0.017173003405332565,
        0.06025005504488945,
        -0.02133779227733612,
        -0.03601836413145065,
        -0.040397074073553085,
        -0.015304667875170708,
        0.01938348449766636,
        -0.008256369270384312,
        -0.018969109281897545,
        -0.00584491528570652,
        -0.048083409667015076,
        0.05003826692700386,
        0.020170174539089203,
        0.03406921401619911,
        -0.025226453319191933,
        -0.030381081625819206,
        -0.033745281398296356,
        0.04951265826821327,
        0.05417335405945778,
        -0.044593024998903275,
        0.01914535090327263,
        -0.03601357713341713,
        -0.024379339069128036,
        0.03925861790776253,
        0.059825748205184937,
        -0.02849469520151615,
        -0.04741974547505379,
        0.01705869287252426,
        0.021900245919823647,
        0.06142769753932953,
        0.05005594342947006,
        -0.05574570223689079,
        0.008575288578867912,
        0.05804276838898659,
        0.03202175721526146,
        0.05084235593676567,
        -0.01691386103630066,
        0.029869742691516876,
        0.05704342946410179,
        0.05899694189429283,
        -0.02233770303428173,
        -0.02428176812827587,
        -0.04282882809638977,
        0.03514562174677849,
        0.001919168047606945,
        0.004986859392374754,
        0.055092763155698776,
        0.007535878568887711,
        0.004097962751984596,
        0.03249039500951767,
        -0.055814698338508606,
        0.060874082148075104,
        0.060654666274785995,
        -0.0575033463537693,
        0.061234358698129654,
        -0.06058835610747337,
        -0.06109153851866722,
        0.055004771798849106,
        -0.06035980209708214,
        0.023321453481912613,
        0.018087759613990784,
        0.00911470502614975,
        0.003968218341469765,
        0.05334039404988289,
        0.04636969789862633,
        -0.06064736843109131,
        0.016208752989768982,
        0.029470259323716164,
        0.043295007199048996,
        0.05600835755467415,
        0.0466398224234581,
        0.03920833393931389,
        -0.0025077115278691053,
        0.011942439712584019,
        0.00271139619871974,
        -0.04296490550041199,
        -0.015251263044774532,
        -0.05467968061566353,
        -0.029571669176220894,
        0.03004739060997963,
        -0.02346549741923809,
        0.05384356528520584,
        -0.03998946025967598,
        -0.06069160997867584,
        0.02590213157236576,
        0.05772837623953819,
        -0.02680327743291855,
        -0.017382321879267693,
        0.002902429550886154,
        0.020351188257336617,
        0.05473127216100693,
        -0.0603889524936676,
        0.0578860379755497,
        0.059508614242076874,
        0.029460880905389786,
        -0.015521333552896976,
        0.010371768847107887,
        0.06096184626221657,
        -0.03870317339897156
    ],
    [
        -0.04312572628259659,
        0.04916248098015785,
        0.04400394856929779,
        0.04050876945257187,
        -0.05142030492424965,
        0.05560726299881935,
        0.0192125104367733,
        -0.0438745841383934,
        0.055795516818761826,
        0.04328213259577751,
        0.05837324261665344,
        0.03386743366718292,
        -0.057067953050136566,
        -0.04494316130876541,
        -0.05724974721670151,
        0.01639111340045929,
        -0.048276618123054504,
        0.01370901521295309,
        0.03566410392522812,
        -0.051066190004348755,
        0.04760308936238289,
        0.05847026780247688,
        0.05210087448358536,
        0.053344666957855225,
        -0.047205861657857895,
        0.015872925519943237,
        -0.0358923003077507,
        0.014721890911459923,
        -0.04114796593785286,
        0.0067562381736934185,
        0.04694822430610657,
        -0.038631029427051544,
        0.057990483939647675,
        -0.05807121470570564,
        0.05685573071241379,
        -0.036733802407979965,
        -0.05682770162820816,
        -0.04009496420621872,
        0.05666612461209297,
        0.007230417802929878,
        -0.030812472105026245,
        -0.002358323195949197,
        0.04938673600554466,
        -0.05615144222974777,
        0.0014806529507040977,
        -0.007184168323874474,
        0.054578498005867004,
        0.057510003447532654,
        0.05788983777165413,
        0.016590118408203125,
        0.05787975341081619,
        0.05685402825474739,
        -0.03750179335474968,
        -0.05585425719618797,
        0.057818032801151276,
        0.028044456616044044,
        -0.027252666652202606,
        -0.004299920052289963,
        -0.04553478583693504,
        -0.05539266765117645,
        -0.04508458450436592,
        -0.005497262347489595,
        -0.056900493800640106,
        0.006689542904496193,
        -0.04123043641448021,
        -0.022339707240462303,
        -0.04472773149609566,
        -0.057500362396240234,
        0.009716873988509178,
        0.05309511721134186,
        -0.05440741032361984,
        -0.056599412113428116,
        0.05703364312648773,
        0.02756592631340027,
        -0.002393490169197321,
        -0.04673342406749725,
        0.05224735289812088,
        0.058075014501810074,
        -0.004439919721335173,
        0.02289927564561367,
        -0.053186915814876556,
        0.015776408836245537,
        -0.058083534240722656,
        0.0350865013897419,
        -0.020538631826639175,
        0.054804399609565735,
        -0.0349806547164917,
        -0.05559706315398216,
        -0.05132952332496643,
        0.05788124352693558,
        -0.05763029307126999,
        -0.05138209089636803,
        -0.04195651039481163,
        -0.01070714183151722,
        -0.03600386530160904,
        0.015273313969373703,
        0.011596900410950184,
        -0.035405706614255905,
        0.043821267783641815,
        -0.02672981098294258,
        -0.056728295981884,
        0.03167754411697388,
        0.016775814816355705,
        0.02397078089416027,
        0.0508853942155838,
        0.0006166817620396614,
        0.05810137093067169,
        0.023123206570744514,
        -0.006494618486613035,
        0.044358860701322556,
        -0.0545613095164299,
        -0.05734371766448021,
        0.0012895636027678847,
        0.012809782288968563,
        -0.053749166429042816,
        -0.021877693012356758,
        -0.04712749645113945,
        -0.031545136123895645,
        0.036796458065509796,
        0.004738924093544483,
        0.006069345865398645,
        -0.04773798957467079,
        -0.0582231841981411,
        -0.029713565483689308,
        -0.054183319211006165,
        -0.03973366692662239,
        -0.058388497680425644,
        -0.05836248770356178,
        0.056164950132369995,
        0.05782319977879524,
        0.009779182262718678,
        -0.01215943694114685,
        -0.017476212233304977,
        0.054803065955638885,
        -0.04030517116189003,
        -0.04017597436904907,
        0.04260426014661789,
        -0.05104506015777588,
        -0.057633984833955765,
        0.05208321288228035,
        -0.04390406608581543,
        -0.057580456137657166,
        0.05200313404202461,
        -0.044942378997802734,
        -0.009270916692912579,
        0.039015285670757294,
        -0.04023747891187668,
        -0.047625716775655746,
        0.025350410491228104,
        0.057297710329294205,
        0.05828379467129707,
        -0.05697295069694519,
        0.012893716804683208,
        -0.037572961300611496,
        0.05845652520656586,
        -0.0577147901058197,
        0.04447788745164871,
        0.056875381618738174,
        0.050946034491062164,
        0.03169506788253784,
        0.02858136221766472,
        0.03197626769542694,
        0.05406726896762848,
        -0.04937531426548958,
        -0.05553008243441582,
        0.05682778358459473,
        -0.057608384639024734,
        -0.0449954979121685,
        0.04328063875436783,
        0.017648112028837204,
        -0.0562816821038723,
        0.0455709807574749,
        -0.05784390866756439,
        -0.0034693987108767033,
        -0.04951826110482216,
        0.022076789289712906,
        0.04184930771589279,
        0.05347822606563568,
        0.05585175380110741,
        -0.050006646662950516,
        -0.056043967604637146,
        0.058046240359544754,
        -0.027212457731366158,
        0.03217434138059616,
        0.05047047510743141,
        0.042117662727832794,
        0.05174381658434868,
        0.05708782374858856,
        0.020581820979714394,
        -0.05511697754263878,
        -0.046000003814697266,
        0.009238950908184052,
        0.0014026175485923886,
        -0.051108039915561676,
        -0.05791565403342247,
        -0.04068140685558319,
        -0.008573267608880997,
        -0.05741654336452484,
        -0.05321045592427254,
        0.01720716431736946,
        0.04676585644483566,
        -0.023028168827295303,
        0.04297477751970291,
        -0.05795234814286232,
        0.05058077350258827,
        0.04811561107635498,
        -0.02028609998524189,
        -0.006458859425038099,
        -0.05578848719596863,
        0.04488251730799675,
        -0.045570772141218185,
        0.015255504287779331,
        0.01884959451854229,
        0.05811454728245735,
        -0.05657786503434181,
        -0.03803550824522972,
        0.04924923926591873,
        -0.05754563584923744,
        0.01233517937362194,
        -0.02609698288142681,
        -0.029232222586870193,
        0.05813775956630707,
        0.019988344982266426,
        -0.05197977274656296,
        0.047110665589571,
        -0.05215970799326897,
        0.056519608944654465,
        -0.044716719537973404,
        0.056878697127103806,
        -0.05152871087193489,
        0.009341239929199219,
        0.010718997567892075,
        0.04959708824753761,
        0.056680288165807724,
        -0.055650945752859116,
        0.056876540184020996,
        0.053355395793914795,
        -0.0057981908321380615,
        0.04381025210022926,
        0.05847100168466568,
        -0.007640400435775518,
        0.010791373439133167,
        -0.05631353706121445,
        0.05674588307738304,
        0.030703343451023102,
        -0.04562215134501457,
        0.019226111471652985,
        -0.04837358370423317,
        0.03352551534771919,
        -0.05465076491236687,
        -0.00904956553131342,
        -0.05529162287712097,
        -0.02204275131225586,
        -0.03750700131058693,
        0.03806394711136818,
        0.057314831763505936,
        0.026768239215016365,
        -0.054086387157440186,
        0.038548655807971954,
        0.034273963421583176,
        -0.0034860398154705763,
        0.007312689907848835,
        0.055706944316625595,
        0.054027024656534195,
        -0.03841818869113922,
        0.057026319205760956,
        0.058446865528821945,
        0.058430660516023636,
        0.04699891805648804,
        0.05246756225824356,
        0.010869174264371395,
        -0.03702213242650032,
        0.020304551348090172,
        -0.005164992995560169,
        0.02557733654975891,
        0.015732066705822945,
        -0.05602734535932541,
        -0.045949120074510574,
        -0.04229985177516937,
        -0.05783390998840332,
        -0.057978566735982895,
        0.051227178424596786,
        -0.01207977719604969,
        0.03461518883705139,
        -0.03219336271286011,
        0.04931887239217758,
        0.022354666143655777,
        0.031039908528327942,
        0.04826197773218155,
        0.02155883237719536,
        -0.05619823560118675,
        0.00433308957144618,
        0.035337064415216446,
        0.0478801392018795,
        0.05808314308524132,
        -0.05158231779932976,
        0.05729103088378906,
        0.056737761944532394,
        0.045213617384433746,
        0.03909089043736458,
        0.042057253420352936,
        0.026650719344615936,
        -0.0005536639946512878,
        0.049455493688583374,
        -0.052112363278865814,
        -0.04334435611963272,
        0.012704255059361458,
        0.01867525838315487,
        0.05632886663079262,
        0.05505182221531868,
        0.02839689329266548,
        0.05652007833123207,
        -0.05319125950336456,
        0.05559340491890907,
        0.027267513796687126,
        -0.05296811833977699,
        0.01893044263124466,
        0.05543072521686554,
        0.05630289763212204,
        0.03908972442150116,
        0.038178812712430954,
        0.025204598903656006,
        0.05454343184828758,
        -0.008021398447453976,
        0.006800774484872818,
        -0.03394944593310356,
        -0.033179111778736115,
        -0.047334667295217514,
        -0.03518088907003403,
        -0.054602816700935364,
        0.050388939678668976,
        -0.05169351398944855,
        -0.05534036457538605,
        0.001750790630467236,
        0.05226964130997658,
        0.04097672551870346,
        -0.05745247006416321,
        0.00328054022975266,
        -0.004774737171828747,
        0.024859540164470673,
        -0.05471326410770416,
        0.038662709295749664,
        -0.0521702840924263,
        -0.03148002177476883,
        -0.0191666129976511,
        0.03184085711836815,
        0.057350676506757736,
        0.057935021817684174,
        -0.05788205936551094,
        0.04764896631240845,
        0.05772740766406059,
        -0.043297186493873596,
        0.049020905047655106,
        -0.058400318026542664,
        0.056849539279937744,
        -0.04123076796531677,
        -0.05387961491942406,
        -0.053876977413892746,
        0.03255222737789154,
        -0.029615478590130806,
        0.03744293376803398,
        0.04481279477477074,
        -0.05574432760477066,
        -0.044561535120010376,
        0.004840966314077377,
        -0.05691073462367058,
        0.05844362452626228,
        0.041561923921108246,
        -0.0009701034869067371,
        -0.05758212134242058,
        -0.04361017420887947,
        -0.04757755994796753,
        -0.03426109999418259,
        -0.034443218261003494,
        0.05707927793264389,
        0.056744273751974106,
        0.05515289679169655,
        0.0572383850812912,
        0.05551385506987572,
        0.05486936867237091,
        -0.05038248002529144,
        0.04064418748021126,
        -0.056992754340171814,
        0.046265266835689545,
        0.045969657599925995,
        -0.05162338539958,
        -0.02846231311559677,
        -0.03539068251848221,
        0.000883719592820853,
        -0.044072072952985764,
        -0.054313283413648605,
        0.012935477308928967,
        -0.05619623512029648,
        0.04376745969057083,
        0.032953500747680664,
        0.05312522500753403,
        0.058014366775751114,
        -0.05345023423433304,
        -0.0540849044919014,
        -0.0575101412832737,
        -0.04065656289458275,
        0.0584062822163105,
        -0.02021334134042263,
        -0.045722536742687225,
        -0.051802776753902435,
        0.057308897376060486,
        0.03206007182598114,
        -0.05497845262289047,
        0.050542205572128296,
        -0.05630289390683174,
        0.003355286782607436,
        -0.04662741348147392,
        -0.05254100635647774,
        -0.03404302895069122,
        0.04976106807589531,
        -0.05542580783367157,
        -0.04865032806992531,
        -0.046348635107278824,
        0.01496504433453083,
        0.058222487568855286,
        0.0050126067362725735,
        -0.05580722913146019,
        0.04414381459355354,
        -0.0581761971116066,
        -0.030369099229574203,
        0.05230114981532097,
        0.05212001875042915,
        0.02641965076327324,
        -0.03396416828036308,
        -0.05451399087905884,
        0.0584675595164299,
        0.04620019719004631,
        0.008742028847336769,
        -0.05742719769477844,
        -0.02070390246808529,
        -0.056297238916158676,
        -0.04056241735816002,
        0.05513225868344307,
        -0.054873622953891754,
        -0.0006878289859741926,
        -0.05830380693078041,
        0.03622995316982269,
        0.05846069008111954,
        0.055585235357284546,
        -0.058180082589387894,
        0.02069687657058239,
        0.0567544586956501,
        -0.053082939237356186,
        0.05366070196032524,
        -0.023399032652378082,
        0.03697749227285385,
        0.026039699092507362,
        0.039122700691223145,
        -0.04980883747339249,
        0.05184822902083397,
        -0.05659294128417969,
        0.049972351640462875,
        -0.010074823163449764,
        0.05237841233611107,
        0.05653562769293785,
        0.05260700732469559,
        -0.020044583827257156,
        0.017523733898997307,
        0.05322040617465973,
        0.05845565348863602,
        0.05813182517886162,
        -0.051498930901288986,
        0.058429840952157974,
        -0.03884640336036682,
        -0.05648745968937874,
        0.049811773002147675,
        0.000647589797154069,
        0.056337591260671616,
        0.057404208928346634,
        -0.05022251233458519,
        0.04849826171994209,
        0.05546970292925835,
        0.057895224541425705,
        -0.040293220430612564,
        -0.050521012395620346,
        0.04479527100920677,
        -0.0035448132548481226,
        -0.05838219076395035,
        0.030026355758309364,
        0.058333758264780045,
        0.03813163563609123,
        -0.006118697579950094,
        0.02832304686307907,
        -0.05682393163442612,
        0.010693864896893501,
        0.05404309928417206,
        0.05665582790970802,
        0.05823099985718727,
        0.014314436353743076,
        0.03828214854001999,
        0.024464543908834457,
        0.0019486561650410295,
        -0.015303106047213078,
        0.042615968734025955,
        0.04740412160754204,
        -0.02050163969397545,
        0.0552121177315712,
        -0.04015388339757919,
        0.0541975162923336,
        -0.04719529673457146,
        0.04199228808283806,
        0.05846814811229706,
        -0.027606647461652756,
        0.05249927565455437,
        -0.05517340078949928,
        0.016269514337182045,
        0.006191533524543047
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        0.04023256152868271,
        0.0504782497882843,
        -0.05476290360093117,
        0.03850802034139633,
        0.0479927733540535,
        -0.05521010980010033,
        0.05542919412255287,
        -0.0526791550219059,
        0.05157209932804108,
        -0.05448008328676224,
        0.05469302088022232,
        0.048801109194755554,
        -0.05544276535511017,
        -0.02268906496465206,
        -0.05505737289786339,
        0.05540076643228531,
        0.041247110813856125,
        0.05503573268651962,
        -0.04056120663881302,
        0.026586895808577538,
        -0.05232115834951401,
        0.004898814018815756,
        0.05356333777308464,
        0.01944301836192608,
        -0.055078912526369095,
        0.05354062467813492,
        0.054483067244291306,
        0.05496826022863388,
        -0.05510127916932106,
        -0.02358049899339676,
        -0.05457860976457596,
        0.04305825009942055,
        0.022277751937508583,
        -0.05552564561367035,
        0.05480656400322914,
        0.008184501901268959,
        -0.05475051701068878,
        -0.05509660392999649,
        0.044153694063425064,
        0.013548523187637329,
        -0.009970574639737606,
        0.04444992542266846,
        0.04924946278333664,
        0.0035625630989670753,
        0.05396230146288872,
        -0.05526009574532509,
        -0.045079607516527176,
        0.05548017472028732,
        0.05548735707998276,
        -0.05555419623851776,
        0.05525490269064903,
        0.016758719459176064,
        -0.0086392592638731,
        -0.0037869513034820557,
        -0.026488598436117172,
        0.02529672160744667,
        -0.02117409184575081,
        -0.010082582011818886,
        -0.05549011379480362,
        -0.05361931771039963,
        -0.05522254481911659,
        -0.016586823388934135,
        -0.051547110080718994,
        -0.054967161267995834,
        0.05060631409287453,
        0.055184006690979004,
        -0.0545567087829113,
        -0.0007169800228439271,
        -0.020428044721484184,
        -0.05437340587377548,
        0.03597612679004669,
        0.02584119327366352,
        0.05344604328274727,
        0.0547444224357605,
        0.05554652959108353,
        0.017454445362091064,
        0.03638029843568802,
        0.0555541105568409,
        0.05519950017333031,
        -0.03342261165380478,
        -0.04684046283364296,
        -0.0555516816675663,
        -0.055437419563531876,
        0.05460188537836075,
        -0.05229506269097328,
        0.03952990099787712,
        -0.0007320562726818025,
        -0.03151354193687439,
        -0.03721822798252106,
        -0.01097881980240345,
        -0.05259085074067116,
        -0.05515700578689575,
        -0.015384544618427753,
        -0.028347626328468323,
        -0.046617478132247925,
        0.0021687219850718975,
        0.029127970337867737,
        -0.054162248969078064,
        -0.038147926330566406,
        0.05397959426045418,
        -0.05553070455789566,
        0.011251199059188366,
        0.04759861156344414,
        0.054015640169382095,
        -0.055008549243211746,
        -0.055431585758924484,
        -0.03364131599664688,
        0.031974636018276215,
        -0.010520419105887413,
        0.01663285866379738,
        -0.05528006702661514,
        -0.054288920015096664,
        -0.05523379147052765,
        0.04625535383820534,
        0.05555056408047676,
        0.03425309807062149,
        -0.024973150342702866,
        -0.05517813190817833,
        -0.04012901708483696,
        -0.05549967661499977,
        -0.0013229530304670334,
        0.010962357744574547,
        0.054264724254608154,
        -0.055536311119794846,
        -0.0232967771589756,
        0.03574153780937195,
        0.03879152238368988,
        0.05458628013730049,
        0.055374857038259506,
        0.05493830516934395,
        0.05375731363892555,
        -0.05405426397919655,
        -0.04241548851132393,
        0.05353319272398949,
        -0.04920969903469086,
        -0.055037546902894974,
        0.05417456850409508,
        0.03992259502410889,
        0.05162438005208969,
        0.054537221789360046,
        -0.05497971922159195,
        -0.04392486438155174,
        0.05222957208752632,
        0.010374121367931366,
        -0.0535302460193634,
        0.01209434773772955,
        -0.025665737688541412,
        -0.05534761771559715,
        -0.043329861015081406,
        -0.04960106685757637,
        -0.05411225184798241,
        -0.05553629994392395,
        0.053119536489248276,
        0.036470092833042145,
        -0.047876521944999695,
        -0.055497728288173676,
        -0.05555223673582077,
        -0.05240001901984215,
        0.05533177778124809,
        0.009609075263142586,
        0.01700345054268837,
        0.05517331510782242,
        0.055208370089530945,
        -0.025985881686210632,
        -0.054804835468530655,
        0.008969957008957863,
        0.054727982729673386,
        0.04822865128517151,
        -0.04755028709769249,
        0.032982274889945984,
        -0.03448444604873657,
        0.054572317749261856,
        -0.05527883768081665,
        0.05201062560081482,
        -0.0550679974257946,
        0.03344538435339928,
        0.055173635482788086,
        0.05157840996980667,
        0.054889507591724396,
        -0.053159717470407486,
        -0.04404522478580475,
        0.05484394729137421,
        0.0017071942565962672,
        -0.016051577404141426,
        0.0017857429338619113,
        -0.01783415488898754,
        -0.012650672346353531,
        0.0554034598171711,
        -0.03276959806680679,
        0.01745428517460823,
        -0.05551885813474655,
        -0.048456523567438126,
        0.005265031009912491,
        0.032132476568222046,
        -0.05350876227021217,
        0.05414359271526337,
        0.05394842475652695,
        -0.025022132322192192,
        0.045520298182964325,
        0.012099294923245907,
        0.003180075203999877,
        0.05510134622454643,
        -0.034064289182424545,
        -0.04033529758453369,
        0.02253839373588562,
        0.009300493635237217,
        0.04826654493808746,
        0.029692936688661575,
        -0.054444894194602966,
        0.05456707254052162,
        -0.055012013763189316,
        0.04814217612147331,
        -0.041199009865522385,
        0.050551991909742355,
        -0.03326956182718277,
        -0.05491071939468384,
        0.05554968863725662,
        0.03973808512091637,
        0.05547399818897247,
        -0.0008216194692067802,
        -0.04202752932906151,
        0.04159005731344223,
        0.04746459797024727,
        -0.04046540707349777,
        0.03214983642101288,
        0.015824276953935623,
        0.05146302282810211,
        -0.0544111505150795,
        0.04854903370141983,
        -0.05516338348388672,
        -0.030151663348078728,
        0.019937345758080482,
        0.016800645738840103,
        0.05494634062051773,
        0.028285440057516098,
        0.05520680174231529,
        0.05379429832100868,
        -0.03195112571120262,
        -0.04413992166519165,
        0.055546097457408905,
        -0.004740065895020962,
        0.016852589324116707,
        -0.025031685829162598,
        -0.04042762145400047,
        -0.005751888733357191,
        0.040262941271066666,
        -0.04776156321167946,
        -0.011012856848537922,
        -0.03841317072510719,
        0.05002564564347267,
        -0.05003117769956589,
        -0.052910953760147095,
        -0.05497274920344353,
        -0.055348221212625504,
        0.055289026349782944,
        0.05489781126379967,
        -0.04217562824487686,
        0.028138166293501854,
        0.05341128632426262,
        -0.053362756967544556,
        0.03927810117602348,
        0.01259984914213419,
        -0.04860052093863487,
        0.05529654026031494,
        -0.05526785925030708,
        0.0475105345249176,
        0.04330287501215935,
        0.01093257125467062,
        -0.04710853844881058,
        -0.019182991236448288,
        0.052816007286310196,
        -0.04142661392688751,
        -0.050892267376184464,
        -0.054381150752305984,
        0.045601364225149155,
        0.023155955597758293,
        -0.039927054196596146,
        0.05501450598239899,
        0.0502505823969841,
        -0.05009147897362709,
        -0.05503546819090843,
        0.053647954016923904,
        0.05530814453959465,
        0.00076214101864025,
        -0.014758257195353508,
        0.045326195657253265,
        0.0387246236205101,
        0.055163633078336716,
        -0.055407945066690445,
        0.05204509571194649,
        -0.04770408198237419,
        0.016689611598849297,
        -0.036523956805467606,
        0.031419456005096436,
        -0.01678953506052494,
        -0.05367840453982353,
        0.055533986538648605,
        -0.004417408257722855,
        -0.05520810931921005,
        0.05413632094860077,
        0.05257663130760193,
        0.03744809702038765,
        0.013910012319684029,
        0.04624230042099953,
        0.051245540380477905,
        -0.04838358983397484,
        0.029080621898174286,
        0.055128056555986404,
        -0.015422886237502098,
        -0.0061212871223688126,
        -0.013239206746220589,
        -0.053596533834934235,
        0.053357698023319244,
        -0.05549854785203934,
        -0.05505485087633133,
        -0.05174509063363075,
        -0.03335069864988327,
        -0.05500384420156479,
        0.05449993908405304,
        -0.03328899294137955,
        0.05318150669336319,
        -0.039466362446546555,
        0.041256994009017944,
        -0.05334823206067085,
        0.049750711768865585,
        -0.05148700252175331,
        0.055330414324998856,
        -0.052638519555330276,
        -0.05521916225552559,
        -0.05521406978368759,
        0.04786429926753044,
        -0.053684789687395096,
        0.0004894935409538448,
        0.0004774723493028432,
        0.054583821445703506,
        0.04505539685487747,
        -0.03726467862725258,
        0.02302352525293827,
        -0.027677511796355247,
        -0.006915065925568342,
        -0.040209099650382996,
        -0.001137936138547957,
        -0.042852483689785004,
        -0.05100754648447037,
        -0.0349171981215477,
        0.050112057477235794,
        -0.006409345660358667,
        0.055367883294820786,
        0.022965772077441216,
        0.050487928092479706,
        0.05530776455998421,
        0.04538048803806305,
        -0.031698793172836304,
        -0.04611862823367119,
        -0.00801211129873991,
        -0.011041761375963688,
        -0.053040098398923874,
        0.006927578244358301,
        -0.05424204841256142,
        0.046556323766708374,
        0.025854937732219696,
        0.05277848243713379,
        -0.05285503715276718,
        -0.05335252359509468,
        -0.05549296364188194,
        -0.047270167618989944,
        0.05546101555228233,
        -0.012811148539185524,
        -0.03103814460337162,
        -0.05007234960794449,
        -0.03779662400484085,
        -0.05511153116822243,
        -0.04484958201646805,
        -0.04931667074561119,
        0.04790421575307846,
        0.05214570462703705,
        0.050339411944150925,
        0.050999995321035385,
        0.04612031579017639,
        -0.05547858029603958,
        -0.05484933778643608,
        0.055550865828990936,
        -0.0546659417450428,
        0.04315152391791344,
        0.028096014633774757,
        -0.05450555309653282,
        0.051144570112228394,
        -0.030004555359482765,
        -0.012989150360226631,
        -0.055143650621175766,
        -0.05470297858119011,
        -0.05320462957024574,
        -0.055062271654605865,
        -0.04490898549556732,
        -0.004472143482416868,
        0.05539410561323166,
        -0.004752487875521183,
        -0.05542318895459175,
        -0.05185991898179054,
        -0.0516553595662117,
        -0.009982286021113396,
        0.055465150624513626,
        0.055477574467659,
        0.019078494980931282,
        -0.04006464034318924,
        0.04338749498128891,
        0.033509355038404465,
        -0.048471368849277496,
        0.05466742813587189,
        0.04479106515645981,
        -0.024962618947029114,
        -0.046174731105566025,
        -0.04396355152130127,
        -0.05363192409276962,
        0.05519457906484604,
        -0.052555277943611145,
        -0.047902438789606094,
        -0.055552612990140915,
        0.037057165056467056,
        -0.05364234000444412,
        0.009995688684284687,
        -0.05085413530468941,
        -0.045981407165527344,
        -0.05551858991384506,
        0.05362556874752045,
        0.01829930581152439,
        0.008487232960760593,
        0.054385144263505936,
        -0.0480252169072628,
        -0.05147996544837952,
        0.043731749057769775,
        0.026832712814211845,
        0.0448739230632782,
        0.0551183819770813,
        -0.05212315917015076,
        -0.015279647894203663,
        -0.019820356741547585,
        0.033540621399879456,
        -0.047202400863170624,
        -0.05530977621674538,
        -0.02861929126083851,
        0.012704008258879185,
        -0.03486790880560875,
        0.05537205561995506,
        -0.02743544429540634,
        0.05544929951429367,
        0.05524687469005585,
        0.03894234448671341,
        -0.01981191709637642,
        0.04439689218997955,
        0.03365593031048775,
        0.05371595174074173,
        0.05500281974673271,
        0.047027651220560074,
        0.024443913251161575,
        -0.05424981936812401,
        -0.005549031775444746,
        -0.05047532543540001,
        0.051144201308488846,
        0.054991669952869415,
        0.05502178519964218,
        -0.051864851266145706,
        0.011576218530535698,
        -0.03663025423884392,
        0.04233281686902046,
        0.05541090667247772,
        -0.055395741015672684,
        0.05348587408661842,
        0.048065099865198135,
        -0.05229729413986206,
        0.03654631972312927,
        -0.0534147210419178,
        0.05316346883773804,
        0.05017576739192009,
        -0.04932107776403427,
        0.034543950110673904,
        -0.023225611075758934,
        0.0551200807094574,
        0.026240264996886253,
        0.04794381558895111,
        0.05182482302188873,
        0.017418639734387398,
        0.019827552139759064,
        0.05446154251694679,
        0.010677173733711243,
        0.039428725838661194,
        0.05238614231348038,
        0.03902934119105339,
        -0.05497600883245468,
        -0.05382521450519562,
        0.023014556616544724,
        -0.05548911169171333,
        0.055162254720926285,
        -0.014715287834405899,
        -0.0038053845055401325,
        -0.04569673165678978,
        -0.0554359070956707,
        -0.05060052499175072,
        0.04552020505070686,
        0.01776145026087761,
        -0.0540083609521389,
        -0.050322532653808594,
        -0.0553848035633564,
        0.008619135245680809,
        -0.024362897500395775,
        0.02984844520688057,
        0.0555262416601181,
        -0.033726487308740616,
        0.05263263359665871,
        -0.037241771817207336,
        -0.00878048688173294,
        -0.039650749415159225
    ],
    [
        0.009993018582463264,
        0.05315214768052101,
        -0.04172945395112038,
        0.020262928679585457,
        0.05689496546983719,
        -0.054620422422885895,
        0.05832793191075325,
        0.02462470345199108,
        -0.031358502805233,
        -0.0074115898460149765,
        0.02635030820965767,
        0.04318416491150856,
        -0.057463668286800385,
        0.028666645288467407,
        -0.05655845254659653,
        0.05728008970618248,
        -0.05498487129807472,
        0.04517495632171631,
        -0.057263169437646866,
        0.037413619458675385,
        -0.052456554025411606,
        -0.04655148833990097,
        0.05360884964466095,
        0.046988993883132935,
        0.003375285305082798,
        0.053334031254053116,
        0.052053216844797134,
        0.05630236864089966,
        -0.047685205936431885,
        -0.05208924785256386,
        0.041914358735084534,
        -0.030666470527648926,
        0.01077069342136383,
        -0.05736001208424568,
        -0.021081484854221344,
        0.014873689971864223,
        -0.05179049074649811,
        -0.05209503695368767,
        -0.05698004364967346,
        0.05434415489435196,
        -0.023046113550662994,
        0.020586272701621056,
        0.05518700182437897,
        -0.03892020508646965,
        0.020006412640213966,
        -0.054457828402519226,
        0.042834728956222534,
        0.05756363645195961,
        0.05589153245091438,
        -0.04493631422519684,
        0.0067465356551110744,
        -0.05347208306193352,
        0.05638635531067848,
        -0.034639108926057816,
        -0.01417425274848938,
        -0.056955330073833466,
        -0.040652304887771606,
        -0.01463309582322836,
        -0.055469926446676254,
        -0.011832167394459248,
        -0.05497634783387184,
        -0.036032918840646744,
        -0.05051947012543678,
        -0.031639087945222855,
        -0.01998116262257099,
        0.058306578546762466,
        0.047863271087408066,
        -0.05555183067917824,
        -0.03926553949713707,
        -0.05838471278548241,
        -0.04976842552423477,
        -0.057885557413101196,
        0.036593351513147354,
        0.05082949250936508,
        -0.008176456205546856,
        0.03935142606496811,
        0.04731946438550949,
        0.055548425763845444,
        0.04869522899389267,
        -0.050342198461294174,
        -0.028811298310756683,
        0.015647592023015022,
        -0.050296250730752945,
        0.00788885448127985,
        -0.049870751798152924,
        -0.023051578551530838,
        -0.055376071482896805,
        -0.04652395471930504,
        0.04546235129237175,
        0.05678210407495499,
        -0.05109456181526184,
        0.026104828342795372,
        -0.03389773145318031,
        -0.023386497050523758,
        0.028023721650242805,
        -0.048307888209819794,
        -0.020759116858243942,
        -0.0490742065012455,
        -0.0514715313911438,
        -0.0077001117169857025,
        -0.057499706745147705,
        -0.052110832184553146,
        0.05723810940980911,
        0.05582495778799057,
        -0.05759682133793831,
        -0.03624621406197548,
        -0.02564512938261032,
        -0.028927933424711227,
        -0.0550355426967144,
        -0.015119647607207298,
        -0.05476579815149307,
        -0.05802755057811737,
        -0.05488516762852669,
        0.020020505413413048,
        0.05714630335569382,
        -0.048258405178785324,
        -0.019920123741030693,
        -0.05724325403571129,
        -0.058145880699157715,
        -0.048776354640722275,
        -0.0399034321308136,
        0.02846819907426834,
        -0.049136947840452194,
        -0.006060017738491297,
        -0.058223657310009,
        0.04970122128725052,
        -0.03299500048160553,
        0.054051171988248825,
        0.05688696354627609,
        0.02748299203813076,
        0.05341833457350731,
        -0.045361462980508804,
        -0.041180770844221115,
        0.034482527524232864,
        -0.05106533691287041,
        -0.053626514971256256,
        0.050093378871679306,
        -0.031876739114522934,
        0.043641481548547745,
        0.05673382431268692,
        0.054825905710458755,
        0.05212809145450592,
        0.03804453834891319,
        0.0338590145111084,
        -0.025521310046315193,
        0.010228361934423447,
        0.04739996790885925,
        -0.04289255291223526,
        -0.050985295325517654,
        0.04964100569486618,
        -0.05638747289776802,
        -0.05754721164703369,
        -0.043514151126146317,
        0.04478044435381889,
        -0.02471720054745674,
        -0.053770218044519424,
        0.010747050866484642,
        -0.03859446197748184,
        0.01176932267844677,
        0.008307600393891335,
        0.03764031454920769,
        0.04222927242517471,
        0.040976691991090775,
        -0.055552754551172256,
        -0.05716114491224289,
        -0.041321951895952225,
        0.050196100026369095,
        -0.037697624415159225,
        0.0230847354978323,
        0.03989681228995323,
        -0.0063431174494326115,
        0.03842699155211449,
        -0.056006427854299545,
        -0.017548562958836555,
        -0.05530589073896408,
        0.056035224348306656,
        -0.05763300135731697,
        0.012491131201386452,
        0.05248022824525833,
        0.008205270394682884,
        0.05544702708721161,
        0.051298290491104126,
        0.05507273226976395,
        0.04603934660553932,
        0.0234394334256649,
        -0.015016697347164154,
        -0.05044751614332199,
        0.05310232192277908,
        -0.056044213473796844,
        -0.010241231881082058,
        -0.05040775611996651,
        -0.05367188900709152,
        -0.04892430081963539,
        -0.022083532065153122,
        -0.037205323576927185,
        0.022641276940703392,
        0.053108006715774536,
        -0.0527467355132103,
        0.021841375157237053,
        0.02081097476184368,
        -0.04259718954563141,
        0.05525653436779976,
        0.0019659704994410276,
        -0.006749307736754417,
        0.011165367439389229,
        0.04661482945084572,
        0.011380556039512157,
        -0.001324729761108756,
        -0.04316786676645279,
        -0.032747041434049606,
        -0.05158383399248123,
        0.025973888114094734,
        -0.05780501663684845,
        -0.05100290849804878,
        0.05570349097251892,
        -0.0008489212486892939,
        0.05782095715403557,
        0.05168537423014641,
        0.05824841558933258,
        -0.05296662822365761,
        -0.03523203358054161,
        -0.03389064595103264,
        -0.044531818479299545,
        -0.05815965682268143,
        0.05625179409980774,
        -0.046671293675899506,
        -0.004872952122241259,
        0.015919696539640427,
        -0.02298809215426445,
        -0.05519188195466995,
        -0.04707075282931328,
        -0.05730914697051048,
        -0.03862423822283745,
        0.02129068598151207,
        0.04427577182650566,
        -0.02612646482884884,
        0.056614622473716736,
        0.0004243674629833549,
        -0.02851776033639908,
        0.04726284742355347,
        0.05251872166991234,
        0.03212505578994751,
        0.018058184534311295,
        0.03062264807522297,
        -0.048493750393390656,
        0.03174227103590965,
        -0.022892393171787262,
        -0.04063107818365097,
        -0.04073076322674751,
        0.02723141945898533,
        0.01938493177294731,
        -0.057051125913858414,
        -0.03606094792485237,
        -0.05488547310233116,
        0.055186677724123,
        0.058248914778232574,
        -0.05366084724664688,
        0.03190750628709793,
        0.05812978744506836,
        0.05773426592350006,
        0.056942153722047806,
        0.05755677819252014,
        0.013813330791890621,
        0.053701311349868774,
        -0.05815478041768074,
        0.011750084348022938,
        0.016915157437324524,
        0.018626781180500984,
        0.0038014850579202175,
        -0.03684763237833977,
        -0.013434764929115772,
        -0.05697993189096451,
        -0.053346842527389526,
        -0.05755790323019028,
        0.03491739183664322,
        0.04711233451962471,
        -0.05648531764745712,
        0.056718651205301285,
        -0.016892792657017708,
        -0.05757951736450195,
        -0.056482844054698944,
        -0.052258364856243134,
        0.05808715894818306,
        0.026294870302081108,
        -0.03053065948188305,
        -0.05325343832373619,
        0.024469492956995964,
        -0.03783301264047623,
        -0.058350734412670135,
        0.04223925620317459,
        -0.04208620637655258,
        0.007784910500049591,
        -0.038988109678030014,
        -0.022067125886678696,
        -0.03772595524787903,
        -0.04218275472521782,
        0.05816401541233063,
        0.02981753647327423,
        -0.05056481435894966,
        0.053696006536483765,
        0.038910120725631714,
        0.05366906523704529,
        -0.02634444274008274,
        0.040279634296894073,
        0.046571195125579834,
        -0.0568632148206234,
        -0.045605119317770004,
        0.014874545857310295,
        0.05226190760731697,
        -0.05802987515926361,
        0.0380973145365715,
        -0.04739885777235031,
        -0.05045207589864731,
        -0.05303080379962921,
        -0.05818817764520645,
        -0.04171732813119888,
        0.053328514099121094,
        -0.05509178712964058,
        0.012486319057643414,
        -0.055420082062482834,
        -0.045833103358745575,
        0.033100325614213943,
        0.05735666677355766,
        -0.054180677980184555,
        0.04820183664560318,
        0.038732998073101044,
        0.055469121783971786,
        -0.02892031893134117,
        -0.04694892466068268,
        -0.03739942982792854,
        -0.05204759165644646,
        0.011886836029589176,
        0.0503237210214138,
        -0.029859496280550957,
        0.05693414807319641,
        0.02914736047387123,
        -0.04008720442652702,
        0.05709619075059891,
        -0.02218606136739254,
        0.05133998021483421,
        0.050441231578588486,
        -0.024741731584072113,
        -0.05524309724569321,
        -0.05641310662031174,
        0.05838482454419136,
        0.00930524617433548,
        0.03021593950688839,
        0.04378070309758186,
        0.034792132675647736,
        0.057804130017757416,
        -0.058283284306526184,
        0.04847288876771927,
        -0.05223163589835167,
        -0.05790799483656883,
        -0.05312850698828697,
        0.045720212161540985,
        -0.0016938603948801756,
        0.021618757396936417,
        -0.05658305063843727,
        -0.03803449869155884,
        0.05438024923205376,
        0.0490511879324913,
        -0.05567556619644165,
        -0.05394871532917023,
        -0.020222119987010956,
        -0.05269360542297363,
        0.058316268026828766,
        0.05006226524710655,
        0.02419262006878853,
        -0.05831993743777275,
        -0.03143953159451485,
        -0.05764732137322426,
        0.051848337054252625,
        0.003838831093162298,
        0.057473644614219666,
        0.05808285251259804,
        0.04965022951364517,
        0.05642959475517273,
        -0.057813748717308044,
        -0.05800681188702583,
        -0.05225436016917229,
        0.057998377829790115,
        -0.05607045814394951,
        -0.007245935034006834,
        0.05485370010137558,
        -0.05313236266374588,
        0.0518573559820652,
        -0.057125501334667206,
        -0.04557855799794197,
        -0.05703668296337128,
        -0.05263082683086395,
        0.03538868576288223,
        0.04178635776042938,
        0.0313662514090538,
        -0.057060644030570984,
        0.056395165622234344,
        0.04518570378422737,
        -0.049542248249053955,
        0.013417270965874195,
        -0.054996151477098465,
        0.01232919655740261,
        0.027892665937542915,
        0.057995282113552094,
        0.05615389719605446,
        -0.04864507168531418,
        0.05443640798330307,
        0.05309027060866356,
        0.029512623324990273,
        0.05099465325474739,
        -0.04035380855202675,
        0.04968409985303879,
        -0.004166958853602409,
        -0.039578042924404144,
        -0.010610867291688919,
        0.05691909044981003,
        0.042066268622875214,
        0.0230532418936491,
        -0.05766444653272629,
        0.03646799176931381,
        -0.05220755562186241,
        -0.05332671105861664,
        -0.043742887675762177,
        -0.05743098631501198,
        -0.05524055287241936,
        0.0570756234228611,
        -0.01172342337667942,
        -0.027869755402207375,
        0.053054798394441605,
        -0.05774012207984924,
        -0.04105498269200325,
        0.03891673684120178,
        -0.00149006606079638,
        0.03716609627008438,
        0.056928735226392746,
        0.049777526408433914,
        0.011909921653568745,
        0.020843341946601868,
        0.04424332454800606,
        -0.003833827329799533,
        -0.05224422365427017,
        0.03760742396116257,
        -0.026352152228355408,
        0.047970354557037354,
        0.05272307246923447,
        0.03453906252980232,
        0.04917773976922035,
        0.0569831021130085,
        -0.03991275653243065,
        0.05524776503443718,
        -0.05558734014630318,
        -0.00498656602576375,
        0.05689416453242302,
        0.05747505649924278,
        -0.048058245331048965,
        0.003573155030608177,
        -0.024678653106093407,
        0.006295687519013882,
        0.040674466639757156,
        -0.051923587918281555,
        0.05683999881148338,
        0.03804755583405495,
        -0.0018205753294751048,
        -0.053526636213064194,
        -0.024005398154258728,
        0.05562521144747734,
        0.05805148184299469,
        -0.05832462012767792,
        0.057707786560058594,
        -0.05143188685178757,
        -0.057682134211063385,
        0.01918811909854412,
        -0.02836577221751213,
        0.046803366392850876,
        0.04812159389257431,
        0.039523299783468246,
        0.03919016197323799,
        -0.05582767352461815,
        0.03229130432009697,
        0.008296004496514797,
        0.005719803739339113,
        0.05697919800877571,
        -0.025641923770308495,
        0.04612405225634575,
        0.04818171635270119,
        0.033830009400844574,
        0.04057261347770691,
        0.049750469624996185,
        -0.02556745707988739,
        -0.0522523894906044,
        -0.05699789896607399,
        -0.023524513468146324,
        -0.05831730738282204,
        0.017905155196785927,
        0.05161070451140404,
        0.05294512212276459,
        -0.005725916475057602,
        -0.057718727737665176,
        -0.027584604918956757,
        0.055429499596357346,
        0.05019386112689972,
        -0.05267021432518959,
        -0.05692634359002113,
        -0.00002915584082074929,
        -0.025308189913630486,
        -0.03497068211436272,
        0.023731771856546402,
        0.014665665104985237,
        0.029343660920858383,
        -0.05491749569773674,
        -0.0230307225137949,
        0.05493742600083351,
        0.05592518299818039
    ],
    [
        -0.053906239569187164,
        -0.053435392677783966,
        -0.0262781772762537,
        0.046961184591054916,
        -0.02990672178566456,
        -0.054097045212984085,
        0.05039876326918602,
        -0.054227884858846664,
        0.0535314716398716,
        0.015460362657904625,
        0.051745977252721786,
        0.053950924426317215,
        -0.05418411269783974,
        -0.017851009964942932,
        -0.05403615161776543,
        0.05133097991347313,
        -0.05273811146616936,
        0.05312904715538025,
        0.014674507081508636,
        0.05100814998149872,
        -0.05256865173578262,
        -0.047858402132987976,
        0.05420950427651405,
        -0.04842757433652878,
        -0.020456567406654358,
        0.01465328224003315,
        0.05419858917593956,
        0.03548891097307205,
        -0.05348701402544975,
        0.015467071905732155,
        -0.044406402856111526,
        0.04275800660252571,
        0.05358988419175148,
        -0.054253432899713516,
        0.05351867526769638,
        -0.048789869993925095,
        -0.052734386175870895,
        -0.005165570881217718,
        -0.05419309437274933,
        -0.046045560389757156,
        0.04421725869178772,
        0.04180385544896126,
        0.0411163829267025,
        -0.054172955453395844,
        0.05352401360869408,
        0.008645301684737206,
        -0.03101726993918419,
        0.05420941114425659,
        0.05425369739532471,
        0.03468642383813858,
        0.05417723208665848,
        0.050008222460746765,
        -0.04926149919629097,
        -0.05319062992930412,
        -0.04145827516913414,
        0.048226140439510345,
        -0.052510686218738556,
        -0.04217854514718056,
        -0.050120119005441666,
        -0.04746967926621437,
        -0.05416940525174141,
        0.024941211566329002,
        -0.052141278982162476,
        0.05421924218535423,
        0.05389838665723801,
        0.05350117012858391,
        -0.0507592111825943,
        -0.05322801321744919,
        0.050175122916698456,
        -0.04048175737261772,
        -0.023721706122159958,
        -0.046169668436050415,
        0.050256118178367615,
        0.0533832311630249,
        0.05211401730775833,
        0.02905953675508499,
        0.035686418414115906,
        0.054253824055194855,
        0.05261759087443352,
        -0.04302418604493141,
        -0.053882524371147156,
        -0.05422026664018631,
        -0.05389031767845154,
        0.053988415747880936,
        0.048020362854003906,
        0.03003925271332264,
        -0.02467726729810238,
        0.049809329211711884,
        0.052611563354730606,
        0.052215736359357834,
        -0.05285917967557907,
        -0.026717180386185646,
        -0.04872254654765129,
        0.007890084758400917,
        -0.04114808514714241,
        0.04238509014248848,
        -0.026670927181839943,
        -0.054175324738025665,
        -0.011678628623485565,
        0.05164159834384918,
        -0.0542215034365654,
        0.05420937389135361,
        0.05388810113072395,
        0.05001267418265343,
        0.052989792078733444,
        -0.05422436445951462,
        -0.04472949355840683,
        0.054233528673648834,
        -0.053445715457201004,
        0.05082394927740097,
        -0.053505789488554,
        -0.05154770612716675,
        -0.02347675897181034,
        0.045132189989089966,
        0.033965229988098145,
        0.04638276994228363,
        -0.02771262638270855,
        -0.052751246839761734,
        -0.05301360785961151,
        -0.054018039256334305,
        -0.03586620092391968,
        -0.04208999127149582,
        0.04682517424225807,
        -0.053964704275131226,
        -0.04264034330844879,
        0.03243017569184303,
        0.04870333522558212,
        -0.010694076307117939,
        0.05386144667863846,
        0.04879910126328468,
        0.0535711869597435,
        0.03439221903681755,
        -0.05125393718481064,
        0.03617888689041138,
        -0.0538298524916172,
        -0.05341336503624916,
        0.05419006198644638,
        0.048605553805828094,
        -0.0318065769970417,
        0.05388130620121956,
        0.023808840662240982,
        0.03273158520460129,
        0.038877762854099274,
        0.05424007400870323,
        -0.05323036015033722,
        0.04702552780508995,
        0.02268134616315365,
        -0.0494065061211586,
        0.013737526722252369,
        -0.036944884806871414,
        -0.05368558689951897,
        -0.054207943379879,
        0.054253678768873215,
        0.04723314940929413,
        -0.014083989895880222,
        -0.054237160831689835,
        -0.05328140780329704,
        0.018252816051244736,
        0.04778146371245384,
        0.015974638983607292,
        0.05258354917168617,
        -0.03289368376135826,
        0.05270938202738762,
        -0.038984525948762894,
        -0.0490376241505146,
        0.024175060912966728,
        0.04228067770600319,
        -0.04179037734866142,
        -0.05312857776880264,
        0.033030375838279724,
        0.04938473179936409,
        0.046904969960451126,
        -0.04980829358100891,
        0.05423244461417198,
        -0.028675656765699387,
        0.0530620813369751,
        -0.017353655770421028,
        -0.028956491500139236,
        0.05224958434700966,
        -0.05407704412937164,
        -0.04633111506700516,
        0.0480734147131443,
        0.0527232326567173,
        -0.054001372307538986,
        0.04907051473855972,
        0.03173321112990379,
        -0.03280891850590706,
        0.05316396430134773,
        -0.053108200430870056,
        -0.018991129472851753,
        -0.05112764611840248,
        0.034355953335762024,
        0.044394608587026596,
        0.016707390546798706,
        -0.014335006475448608,
        -0.01675104908645153,
        0.04762713238596916,
        0.04470543935894966,
        -0.027135364711284637,
        0.0515417642891407,
        0.053430065512657166,
        0.04388774558901787,
        -0.044230278581380844,
        -0.05232682079076767,
        0.009657829999923706,
        -0.05415600910782814,
        0.04730730503797531,
        -0.04826958104968071,
        -0.053812719881534576,
        0.05409638583660126,
        -0.04687182232737541,
        0.05402465909719467,
        0.053962867707014084,
        0.05424923077225685,
        0.05408854782581329,
        -0.025457963347434998,
        0.028315316885709763,
        -0.0504426471889019,
        0.05156668648123741,
        0.036593958735466,
        0.04243618994951248,
        0.03354313224554062,
        -0.029735609889030457,
        -0.05419732630252838,
        0.04564335197210312,
        -0.05382895469665527,
        0.05382510647177696,
        0.0533580556511879,
        0.04517415165901184,
        -0.05157202482223511,
        0.043072909116744995,
        0.023996450006961823,
        -0.03464261814951897,
        0.053335871547460556,
        -0.022756505757570267,
        0.050230830907821655,
        0.05232660472393036,
        0.03429006040096283,
        0.04402945563197136,
        0.05423739552497864,
        0.012921478599309921,
        0.046638503670692444,
        0.036861877888441086,
        -0.015396323055028915,
        0.042499344795942307,
        0.04793449863791466,
        0.029922589659690857,
        0.01326742023229599,
        0.033197071403265,
        -0.0012726468266919255,
        -0.04393305629491806,
        -0.054216038435697556,
        -0.05199127644300461,
        -0.053960759192705154,
        -0.05211319401860237,
        0.053462088108062744,
        -0.044728104025125504,
        -0.05124061182141304,
        -0.01182211097329855,
        0.03837132826447487,
        -0.030129071325063705,
        -0.052425216883420944,
        -0.007797829806804657,
        0.0541987419128418,
        -0.0531206876039505,
        0.05397636815905571,
        0.046730056405067444,
        0.054095979779958725,
        -0.054231494665145874,
        -0.04739835485816002,
        0.05122954770922661,
        -0.03536919876933098,
        -0.030769415199756622,
        -0.05069940909743309,
        -0.05104870721697807,
        -0.02884359285235405,
        -0.04863329231739044,
        -0.033483996987342834,
        -0.02405635267496109,
        -0.0524468868970871,
        -0.052990514785051346,
        0.03699420392513275,
        0.025140348821878433,
        0.05420248210430145,
        0.02203775756061077,
        0.017964979633688927,
        0.048159487545490265,
        0.03931265324354172,
        0.02839566580951214,
        -0.014122494496405125,
        -0.05392251908779144,
        -0.054098837077617645,
        -0.04145268350839615,
        -0.002852505771443248,
        -0.042004797607660294,
        -0.049225836992263794,
        0.05423398315906525,
        0.05311067774891853,
        -0.04677249491214752,
        0.05418899655342102,
        -0.014694666489958763,
        -0.030437495559453964,
        0.04931449890136719,
        0.05362599343061447,
        -0.022941626608371735,
        -0.018963444977998734,
        -0.02424001134932041,
        -0.04263276234269142,
        0.048290010541677475,
        0.0397186204791069,
        -0.053884606808423996,
        0.02897917665541172,
        -0.0026006833650171757,
        -0.054036084562540054,
        -0.046884845942258835,
        -0.04082292690873146,
        -0.025663381442427635,
        -0.054094232618808746,
        0.05322697386145592,
        -0.045789677649736404,
        0.053394779562950134,
        -0.05357493832707405,
        -0.03449133038520813,
        -0.05416739732027054,
        0.054151490330696106,
        0.025045765563845634,
        0.04390650987625122,
        -0.036798443645238876,
        0.02146872878074646,
        0.0024184868671000004,
        0.053301386535167694,
        0.015067012049257755,
        -0.053857043385505676,
        -0.05052940547466278,
        0.05000850185751915,
        0.05382469668984413,
        -0.034503716975450516,
        0.01159641332924366,
        -0.050673048943281174,
        0.05022824555635452,
        -0.028853246942162514,
        -0.046798720955848694,
        0.04671725258231163,
        -0.04170659929513931,
        0.046181634068489075,
        0.052804555743932724,
        -0.004783120006322861,
        0.054060183465480804,
        -0.024491550400853157,
        0.014395791105926037,
        0.05309513211250305,
        -0.006191191729158163,
        0.05361299216747284,
        -0.05371380224823952,
        0.05160203576087952,
        0.044849686324596405,
        -0.048464566469192505,
        -0.051374416798353195,
        -0.025976549834012985,
        -0.05343643203377724,
        0.03899196907877922,
        0.03164766728878021,
        -0.039234552532434464,
        -0.025537332519888878,
        0.049484431743621826,
        -0.04982728511095047,
        0.05424750968813896,
        -0.022180791944265366,
        0.02088155411183834,
        -0.05419540032744408,
        -0.051570791751146317,
        -0.05380367115139961,
        0.04135393723845482,
        -0.0005482970736920834,
        0.045579202473163605,
        0.007843993604183197,
        -0.006888069212436676,
        -0.00829537957906723,
        0.052560094743967056,
        0.04363841935992241,
        -0.048613887280225754,
        0.05425325781106949,
        -0.05408995971083641,
        0.052140288054943085,
        -0.017098575830459595,
        -0.04749586433172226,
        -0.03250448405742645,
        -0.05399470776319504,
        -0.05363158509135246,
        0.04621083661913872,
        -0.05415606126189232,
        -0.04672420769929886,
        -0.053568821400403976,
        0.054182037711143494,
        0.004268338438123465,
        0.05338026210665703,
        0.05415816232562065,
        0.030653776600956917,
        0.05271345376968384,
        -0.05423014238476753,
        -0.028720935806632042,
        0.054245151579380035,
        0.051277220249176025,
        0.004598491359502077,
        -0.05416150018572807,
        -0.03914862498641014,
        0.04122999683022499,
        -0.053056176751852036,
        0.054234474897384644,
        -0.051999472081661224,
        -0.04929463192820549,
        0.0513949878513813,
        0.052903544157743454,
        -0.0067846341989934444,
        0.04433346167206764,
        -0.03836556896567345,
        -0.046644315123558044,
        -0.05283285304903984,
        0.05284229293465614,
        0.006985997781157494,
        0.007660420145839453,
        0.03150384873151779,
        0.05403640866279602,
        -0.05421788990497589,
        0.0455746166408062,
        -0.016207950189709663,
        0.04140434041619301,
        0.05037301778793335,
        -0.03765837475657463,
        -0.05320332571864128,
        0.03517227619886398,
        0.05109519883990288,
        0.0446002334356308,
        -0.05209619179368019,
        -0.0032230725046247244,
        -0.05333900824189186,
        -0.052711378782987595,
        0.05313936993479729,
        0.006372974254190922,
        -0.045751333236694336,
        -0.05409042537212372,
        0.030973944813013077,
        0.023520294576883316,
        0.0524129644036293,
        0.04727865383028984,
        0.023706812411546707,
        0.05419740453362465,
        -0.046230021864175797,
        0.03210066258907318,
        0.05417514219880104,
        -0.048367589712142944,
        0.04660385102033615,
        0.052223287522792816,
        0.010109130293130875,
        -0.019502000883221626,
        -0.03610767796635628,
        -0.033756744116544724,
        -0.020469147711992264,
        0.03393418341875076,
        0.05386452376842499,
        0.050798967480659485,
        -0.054235879331827164,
        0.04693889617919922,
        0.022394690662622452,
        0.054100051522254944,
        0.05421612784266472,
        -0.043685298413038254,
        0.053745344281196594,
        -0.03224557265639305,
        -0.05423540249466896,
        0.02653215080499649,
        -0.05423363298177719,
        0.054000504314899445,
        0.040996212512254715,
        -0.04407133534550667,
        0.05275849997997284,
        0.04987436160445213,
        0.04638534039258957,
        -0.0325327031314373,
        -0.0507429875433445,
        -0.0351872518658638,
        0.02821534499526024,
        -0.04581531509757042,
        0.05402649566531181,
        -0.007835045456886292,
        0.05381844565272331,
        0.012760348618030548,
        0.0539880134165287,
        -0.05354813486337662,
        0.04661012813448906,
        -0.010791531763970852,
        -0.054053258150815964,
        0.054123654961586,
        -0.03449392691254616,
        0.011525003239512444,
        0.007499413099139929,
        -0.05393960699439049,
        0.04013647511601448,
        0.05251936614513397,
        0.03625937178730965,
        0.015475761145353317,
        0.03425265848636627,
        -0.0540686771273613,
        0.04942987486720085,
        -0.03906477615237236,
        0.05276176333427429,
        0.02573845535516739,
        -0.04333755001425743,
        0.0473601408302784,
        -0.04778466746211052,
        0.050720054656267166,
        0.013835625723004341
    ],
    [
        -0.061195529997348785,
        -0.05880100652575493,
        0.027650179341435432,
        0.024736909195780754,
        -0.0003168222028762102,
        -0.050498731434345245,
        0.06062137335538864,
        -0.061542656272649765,
        0.010237175971269608,
        0.02335165999829769,
        0.04840858280658722,
        0.03243972361087799,
        -0.044642940163612366,
        -0.04302326589822769,
        -0.062024399638175964,
        0.0456535667181015,
        -0.060963038355112076,
        0.06067049130797386,
        -0.060955218970775604,
        0.0526500903069973,
        -0.057742029428482056,
        -0.050112802535295486,
        0.03540360927581787,
        -0.03010760061442852,
        0.04464886337518692,
        0.0064833383075892925,
        0.04396112263202667,
        0.059772275388240814,
        -0.04984939843416214,
        -0.03293989971280098,
        0.05350450426340103,
        0.010889241471886635,
        -0.005813117139041424,
        -0.06216658651828766,
        0.024257631972432137,
        0.049522049725055695,
        -0.05944640189409256,
        0.0547335259616375,
        -0.062453027814626694,
        0.027854034677147865,
        -0.013406445272266865,
        -0.011151055805385113,
        0.006595985032618046,
        -0.056595589965581894,
        0.05086889490485191,
        0.026531044393777847,
        -0.037263672798871994,
        0.04970281943678856,
        0.06145482137799263,
        -0.051228176802396774,
        0.04346393421292305,
        0.016947036609053612,
        0.027010895311832428,
        -0.04150860011577606,
        0.010911304503679276,
        -0.022878115996718407,
        -0.03611893579363823,
        0.028277112171053886,
        -0.04192979633808136,
        0.05949205905199051,
        -0.03816152736544609,
        0.01762535609304905,
        -0.06180747225880623,
        0.057993292808532715,
        0.025947708636522293,
        0.028597230091691017,
        0.057860344648361206,
        0.0028371624648571014,
        0.04216056317090988,
        -0.062022436410188675,
        -0.06051994487643242,
        -0.05606544390320778,
        -0.04161977022886276,
        0.03949638828635216,
        -0.05523025244474411,
        -0.043331079185009,
        0.04693758115172386,
        0.053821805864572525,
        0.053651053458452225,
        -0.055579572916030884,
        -0.029060304164886475,
        -0.042809437960386276,
        -0.06107208877801895,
        0.056098710745573044,
        0.04137640446424484,
        0.049813661724328995,
        -0.020665587857365608,
        0.061887141317129135,
        0.04739154502749443,
        0.0608653761446476,
        -0.05717436596751213,
        -0.029452968388795853,
        -0.024776438251137733,
        -0.006368934642523527,
        0.04863746464252472,
        -0.02731739915907383,
        -0.025436382740736008,
        -0.03609367460012436,
        0.04800841957330704,
        0.015476142056286335,
        -0.06230061501264572,
        -0.04580128565430641,
        0.059029970318078995,
        0.057718247175216675,
        0.039209961891174316,
        -0.06136573478579521,
        -0.053771357983350754,
        0.05227295309305191,
        -0.061890311539173126,
        0.007456535007804632,
        -0.0598280131816864,
        -0.015191585756838322,
        -0.03039378486573696,
        -0.05620783194899559,
        0.04778540879487991,
        -0.0032956236973404884,
        0.03923961892724037,
        -0.04555605351924896,
        -0.06258812546730042,
        -0.033882636576890945,
        -0.03502130135893822,
        -0.061006348580121994,
        -0.021829240024089813,
        0.04645511880517006,
        -0.057140614837408066,
        0.02159246616065502,
        0.033931903541088104,
        0.019046254456043243,
        0.001733057084493339,
        0.04693346470594406,
        0.05613798648118973,
        -0.03354983031749725,
        -0.0016220815014094114,
        -0.02311219647526741,
        -0.05114275962114334,
        -0.05940287932753563,
        0.056573983281850815,
        -0.018247008323669434,
        -0.03586600348353386,
        0.0622774176299572,
        0.035117294639348984,
        0.04114413261413574,
        0.05760383978486061,
        0.06191239506006241,
        -0.053889691829681396,
        -0.029615463688969612,
        0.0381750762462616,
        -0.05858907848596573,
        0.02748998999595642,
        -0.028153834864497185,
        -0.061029307544231415,
        -0.061875008046627045,
        0.05866275355219841,
        0.05810381472110748,
        -0.01564573124051094,
        -0.060084085911512375,
        0.060735300183296204,
        0.038020599633455276,
        0.017872633412480354,
        -0.05037594214081764,
        0.05350181460380554,
        -0.054913394153118134,
        0.05294715613126755,
        -0.061959948390722275,
        -0.0615290030837059,
        -0.018988659605383873,
        -0.05200790613889694,
        0.010503550991415977,
        0.01274656318128109,
        0.04561171680688858,
        0.03950721397995949,
        0.008828745223581791,
        -0.060264911502599716,
        0.060899946838617325,
        -0.033521879464387894,
        0.052290625870227814,
        -0.06212824583053589,
        0.03746245801448822,
        0.05286511406302452,
        0.014458325691521168,
        0.027455536648631096,
        -0.023755334317684174,
        0.054638393223285675,
        -0.03797350823879242,
        0.03457320109009743,
        0.02824549563229084,
        0.041936829686164856,
        0.055160991847515106,
        -0.004499621223658323,
        -0.05207808315753937,
        -0.003575541777536273,
        0.01791416108608246,
        0.015694227069616318,
        0.025915496051311493,
        -0.018077155575156212,
        0.04272547364234924,
        0.05717727541923523,
        0.058775994926691055,
        0.001215537777170539,
        0.04852381721138954,
        -0.008976601995527744,
        0.05367802828550339,
        -0.03520021215081215,
        0.05065537989139557,
        0.026156941428780556,
        0.01600893773138523,
        0.003345990087836981,
        -0.05030376836657524,
        -0.05176292732357979,
        0.04717640578746796,
        -0.04194176197052002,
        0.021285537630319595,
        0.04505939409136772,
        -0.05293077975511551,
        0.04979865625500679,
        -0.030020955950021744,
        0.05835142359137535,
        -0.0003487732610665262,
        0.060849856585264206,
        -0.031128577888011932,
        -0.04419182986021042,
        -0.03657793626189232,
        -0.028329523280262947,
        -0.06182000786066055,
        0.012655929662287235,
        -0.05562805011868477,
        0.05789675563573837,
        0.034125495702028275,
        -0.055801741778850555,
        -0.057983025908470154,
        -0.004933226853609085,
        0.02733388915657997,
        -0.05646791681647301,
        0.05242733657360077,
        0.05001599341630936,
        -0.05140408128499985,
        0.05230579525232315,
        -0.04400160536170006,
        0.016667507588863373,
        0.0583546943962574,
        0.06106114760041237,
        -0.046780362725257874,
        0.06145760044455528,
        0.045525819063186646,
        0.04852036386728287,
        0.04715515673160553,
        0.05308205261826515,
        -0.03474197909235954,
        -0.0036719758063554764,
        -0.010038070380687714,
        -0.009180222637951374,
        -0.06002434715628624,
        -0.061792947351932526,
        -0.06120581552386284,
        0.03520188108086586,
        0.062385935336351395,
        0.0374375618994236,
        -0.04973297193646431,
        0.06145135685801506,
        0.0601673386991024,
        -0.03905828297138214,
        0.058807600289583206,
        0.05776122212409973,
        0.059673987329006195,
        -0.029957149177789688,
        0.055382076650857925,
        -0.02842143550515175,
        -0.011242259293794632,
        -0.052845291793346405,
        -0.060216907411813736,
        -0.028026847168803215,
        -0.05860761180520058,
        0.0418885238468647,
        -0.05496355518698692,
        -0.04156893491744995,
        -0.00949903018772602,
        -0.0584724023938179,
        -0.04799545556306839,
        -0.059800732880830765,
        0.0013768762582913041,
        -0.06131680682301521,
        0.06181059032678604,
        -0.03828161209821701,
        0.055319223552942276,
        0.02441094070672989,
        -0.0027997016441076994,
        0.015035999938845634,
        -0.053702935576438904,
        -0.03719527646899223,
        -0.03278914839029312,
        0.00963140930980444,
        -0.026226095855236053,
        -0.016773251816630363,
        0.02449360489845276,
        0.0128649165853858,
        -0.059034209698438644,
        0.06186502426862717,
        0.05334606394171715,
        -0.01360251009464264,
        0.05465293303132057,
        0.01786205917596817,
        0.024359118193387985,
        0.009556007571518421,
        0.040201928466558456,
        0.0006133868591859937,
        -0.005362452007830143,
        -0.012996528297662735,
        0.04035819694399834,
        0.05371759459376335,
        -0.042588043957948685,
        -0.05633053183555603,
        0.023854579776525497,
        -0.04922456294298172,
        0.018129820004105568,
        -0.051474761217832565,
        -0.043574392795562744,
        0.06166471540927887,
        -0.057952191680669785,
        0.01803715154528618,
        -0.060741886496543884,
        0.049330275505781174,
        -0.03847276791930199,
        0.014727010391652584,
        -0.037617675960063934,
        0.03728670999407768,
        0.06146295368671417,
        0.042585451155900955,
        -0.019543081521987915,
        0.02553383633494377,
        -0.03894132003188133,
        -0.04991580918431282,
        0.055010836571455,
        -0.0035481129307299852,
        -0.043704431504011154,
        0.06127279996871948,
        0.0517277717590332,
        -0.060700591653585434,
        0.04924054443836212,
        -0.0368160754442215,
        0.05815405398607254,
        -0.01695430465042591,
        -0.014940285123884678,
        0.012718652375042439,
        -0.006820615381002426,
        0.06256824731826782,
        0.044205162674188614,
        0.018849942833185196,
        0.04301035776734352,
        -0.04321424290537834,
        0.056365467607975006,
        -0.0598839595913887,
        -0.042911283671855927,
        -0.044442951679229736,
        -0.05115518346428871,
        0.023822864517569542,
        0.06045861169695854,
        -0.033724594861269,
        -0.028504416346549988,
        0.014543899334967136,
        -0.0369366779923439,
        0.05610102787613869,
        0.058407388627529144,
        -0.05301310867071152,
        0.03717035427689552,
        0.029916957020759583,
        -0.05192188918590546,
        0.061483606696128845,
        -0.039523351937532425,
        0.052848659455776215,
        -0.06220043823122978,
        -0.05819331109523773,
        -0.06131969019770622,
        0.04793979972600937,
        0.04860867187380791,
        -0.008487059734761715,
        0.03550923243165016,
        -0.04551555588841438,
        0.048107560724020004,
        0.029273822903633118,
        -0.039575766772031784,
        -0.06081148982048035,
        0.05982787534594536,
        -0.0606328621506691,
        0.019760968163609505,
        0.04800144582986832,
        -0.009155863896012306,
        0.009790103882551193,
        -0.03717103600502014,
        -0.061659205704927444,
        0.02831047587096691,
        0.007684912998229265,
        -0.011207838542759418,
        -0.04126487299799919,
        0.05422722548246384,
        -0.03837071731686592,
        0.05684249475598335,
        0.05345798283815384,
        0.04228440672159195,
        -0.01281233225017786,
        -0.06252619624137878,
        -0.027253108099102974,
        0.05708707123994827,
        0.06228801980614662,
        0.06230765953660011,
        0.0065331533551216125,
        -0.0308347437530756,
        0.03327159956097603,
        -0.024932870641350746,
        0.06030750274658203,
        -0.059408433735370636,
        0.037216998636722565,
        0.04359203204512596,
        0.03478507697582245,
        -0.0031959370244294405,
        0.043729018419981,
        0.035831280052661896,
        -0.05227521434426308,
        -0.055120863020420074,
        0.026065591722726822,
        -0.0029417278710752726,
        -0.016408832743763924,
        0.03505726158618927,
        0.02919861115515232,
        -0.060872748494148254,
        0.0348915234208107,
        0.05215706676244736,
        -0.06051184609532356,
        -0.0583680123090744,
        -0.03687221556901932,
        -0.046841807663440704,
        0.04097255319356918,
        0.0464056022465229,
        -0.007830886170268059,
        -0.021263210102915764,
        -0.0375068262219429,
        -0.05678180605173111,
        -0.03642057999968529,
        0.03244840353727341,
        0.044031430035829544,
        -0.0371277742087841,
        -0.05783872678875923,
        0.060495082288980484,
        -0.05631908029317856,
        0.05485916510224342,
        0.03778591379523277,
        0.040395382791757584,
        0.05683940649032593,
        -0.04903890937566757,
        0.056517601013183594,
        0.029761677607893944,
        -0.011201287619769573,
        0.015538403764367104,
        0.02255118265748024,
        -0.059836164116859436,
        -0.012344109825789928,
        -0.04769061505794525,
        0.009811894968152046,
        0.03186063468456268,
        0.01363710779696703,
        0.060956764966249466,
        -0.000577686820179224,
        -0.05543752759695053,
        -0.05766429752111435,
        0.041830677539110184,
        0.06160402297973633,
        0.062257394194602966,
        -0.062205929309129715,
        0.057489607483148575,
        -0.007791207637637854,
        -0.06210022047162056,
        0.04726016893982887,
        -0.0037399528082460165,
        0.055815186351537704,
        0.0011731208069249988,
        0.06226968765258789,
        0.014568760991096497,
        0.04812853783369064,
        -0.00601999694481492,
        -0.03929010406136513,
        -0.06015906482934952,
        -0.056585174053907394,
        0.007948596030473709,
        -0.01927405409514904,
        0.028435150161385536,
        0.056266870349645615,
        0.057209618389606476,
        0.0549205057322979,
        -0.05337024852633476,
        -0.054047469049692154,
        0.03598605468869209,
        -0.05870235338807106,
        -0.055299099534749985,
        0.040555570274591446,
        0.004975787829607725,
        0.02606889419257641,
        0.03863432630896568,
        -0.06013325974345207,
        0.019740397110581398,
        0.05938366800546646,
        0.015349280089139938,
        -0.03725672513246536,
        0.04641849547624588,
        -0.06057000160217285,
        0.05721857026219368,
        0.019872192293405533,
        0.04013623297214508,
        -0.058229297399520874,
        -0.01163891889154911,
        -0.03839121386408806,
        -0.0497761145234108,
        0.06021001562476158,
        0.0013580058002844453
    ],
    [
        -0.0623016357421875,
        -0.010328786447644234,
        -0.0033109344076365232,
        0.003698869375512004,
        0.052952151745557785,
        -0.04654831066727638,
        0.061794571578502655,
        -0.040701646357774734,
        0.02135872282087803,
        0.06051510199904442,
        0.044381268322467804,
        -0.024134254083037376,
        -0.0479494072496891,
        -0.03374290466308594,
        -0.05871492251753807,
        0.031981270760297775,
        -0.06274235993623734,
        0.05895217880606651,
        -0.06202387437224388,
        0.00026137204258702695,
        -0.05275072529911995,
        -0.00913207232952118,
        0.03288458287715912,
        -0.02589409425854683,
        -0.030512601137161255,
        0.04268382489681244,
        0.013853449374437332,
        0.05431550741195679,
        -0.04278134927153587,
        -0.05707768723368645,
        0.03353765234351158,
        -0.011115338653326035,
        0.02963469922542572,
        -0.06239674985408783,
        0.037360433489084244,
        0.0047650462947785854,
        -0.05469918251037598,
        -0.03991047292947769,
        -0.06135804206132889,
        0.03325542435050011,
        -0.06094902381300926,
        0.022262142971158028,
        0.01707088202238083,
        -0.029260262846946716,
        0.025156088173389435,
        -0.044246405363082886,
        0.041935212910175323,
        0.058058951050043106,
        0.061926163733005524,
        -0.0439656637609005,
        0.018093984574079514,
        -0.026266813278198242,
        0.05056082457304001,
        0.005800037644803524,
        -0.037445683032274246,
        -0.045243408530950546,
        -0.054359402507543564,
        -0.018823012709617615,
        0.014089910313487053,
        0.027701498940587044,
        -0.05231986939907074,
        -0.045177996158599854,
        -0.059505026787519455,
        0.023371970281004906,
        0.011010801419615746,
        0.05141895264387131,
        0.04579421505331993,
        -0.04321496561169624,
        -0.02820448949933052,
        -0.06282071769237518,
        -0.06262243539094925,
        -0.05154593288898468,
        0.041835397481918335,
        0.0012959599262103438,
        -0.029522232711315155,
        0.006103984545916319,
        0.037657421082258224,
        0.06303026527166367,
        0.05302562564611435,
        -0.04722033068537712,
        0.0024017987307161093,
        0.00447149109095335,
        -0.06002771854400635,
        0.0465954914689064,
        0.025561990216374397,
        0.040025174617767334,
        0.00590484170243144,
        0.040994707494974136,
        -0.002955877222120762,
        0.061657294631004333,
        -0.050627220422029495,
        0.034341000020504,
        0.01745101436972618,
        0.028381118550896645,
        0.01597205549478531,
        0.00742539344355464,
        -0.024812042713165283,
        -0.012717537581920624,
        -0.010611479170620441,
        0.016444452106952667,
        -0.0627850741147995,
        -0.04811163246631622,
        0.0304617490619421,
        -0.06177246570587158,
        0.04909830912947655,
        -0.0616559162735939,
        -0.05671611800789833,
        0.0600910447537899,
        -0.061804912984371185,
        -0.05881757661700249,
        -0.05648811161518097,
        -0.05886979028582573,
        -0.05934381112456322,
        -0.04447837173938751,
        0.020790504291653633,
        -0.03364625945687294,
        0.06213398650288582,
        -0.05494097247719765,
        -0.06256606429815292,
        -0.04271376505494118,
        0.04356478154659271,
        -0.038832008838653564,
        -0.029907871037721634,
        0.032184671610593796,
        -0.05863980948925018,
        -0.06122036650776863,
        0.03862287104129791,
        0.034397222101688385,
        0.045501548796892166,
        0.0220599677413702,
        -0.020254509523510933,
        -0.03186607360839844,
        -0.052847545593976974,
        0.0354076512157917,
        -0.052247729152441025,
        -0.05913813039660454,
        0.021213430911302567,
        -0.062052834779024124,
        -0.05684942007064819,
        0.05893860384821892,
        0.03815413638949394,
        -0.04785127937793732,
        0.03516114875674248,
        -0.005045970901846886,
        0.0028539076447486877,
        -0.035586778074502945,
        0.054858896881341934,
        -0.04877638444304466,
        0.05920775979757309,
        -0.023417025804519653,
        -0.03745812550187111,
        -0.06304429471492767,
        0.00881640613079071,
        0.004761693067848682,
        -0.010178265161812305,
        -0.061999980360269547,
        0.062435876578092575,
        0.01196920033544302,
        0.01715780980885029,
        0.04146827384829521,
        0.05252768099308014,
        0.059367816895246506,
        0.040290024131536484,
        -0.05723125487565994,
        -0.05472152307629585,
        -0.033176690340042114,
        0.03468085080385208,
        -0.06273748725652695,
        0.05790887773036957,
        0.0439385324716568,
        0.002275354927405715,
        -0.06269588321447372,
        -0.060358885675668716,
        0.024014998227357864,
        -0.04647308588027954,
        0.06052900105714798,
        -0.06111080199480057,
        0.007801073137670755,
        0.009675291366875172,
        0.03493354842066765,
        0.05833936855196953,
        0.04313427582383156,
        0.05801135301589966,
        0.03319733589887619,
        0.024166787043213844,
        0.03538326546549797,
        -0.018275942653417587,
        0.056574657559394836,
        -0.02431989647448063,
        -0.03652726113796234,
        -0.04034178704023361,
        -0.05693335086107254,
        -0.0031458146404474974,
        0.04924413934350014,
        0.010822679847478867,
        0.03325178474187851,
        0.05560491234064102,
        0.060886044055223465,
        0.008091223426163197,
        0.037198975682258606,
        -0.05625329539179802,
        0.059683457016944885,
        0.00479493010789156,
        0.056919168680906296,
        0.055654481053352356,
        0.038440048694610596,
        0.043615974485874176,
        -0.061971161514520645,
        -0.04274667426943779,
        -0.01979183964431286,
        -0.04007431119680405,
        0.040064726024866104,
        -0.024822648614645004,
        -0.04723241180181503,
        0.03492477536201477,
        -0.010225370526313782,
        0.0496978722512722,
        0.04394279420375824,
        0.06080227345228195,
        -0.020102761685848236,
        0.02927902340888977,
        0.024244636297225952,
        -0.0508921854197979,
        -0.060960803180933,
        0.050130072981119156,
        -0.041541483253240585,
        -0.00740520004183054,
        0.053272590041160583,
        -0.0325915552675724,
        -0.06007764860987663,
        -0.0525725893676281,
        -0.046594876796007156,
        -0.027191810309886932,
        0.048201609402894974,
        0.0075349751859903336,
        -0.020905030891299248,
        0.05970507860183716,
        -0.01813027635216713,
        -0.040897469967603683,
        0.061207618564367294,
        0.058912087231874466,
        -0.040313396602869034,
        0.06171662360429764,
        0.06239376962184906,
        0.009262858889997005,
        0.05903986096382141,
        -0.032252077013254166,
        0.0623469352722168,
        0.0011474475031718612,
        -0.011071551591157913,
        -0.05758964270353317,
        -0.05966416746377945,
        -0.05456658452749252,
        -0.06145109608769417,
        0.04885849729180336,
        0.06185588985681534,
        -0.01753677986562252,
        -0.05077514797449112,
        0.06217148154973984,
        0.05818554013967514,
        0.051066748797893524,
        0.060444433242082596,
        -0.05417654290795326,
        0.055253319442272186,
        -0.008214748464524746,
        0.053180765360593796,
        -0.0332752987742424,
        0.04251100495457649,
        0.048683859407901764,
        -0.05527110770344734,
        -0.047624535858631134,
        -0.061456065624952316,
        0.024016093462705612,
        -0.05878896266222,
        -0.04038606211543083,
        -0.008693380281329155,
        -0.0530581958591938,
        -0.03663431107997894,
        -0.06316737830638885,
        -0.0034335306845605373,
        -0.05823183059692383,
        -0.008289912715554237,
        0.026496507227420807,
        0.055430348962545395,
        0.06278827041387558,
        -0.05571034178137779,
        0.03343756124377251,
        -0.051563654094934464,
        -0.061304714530706406,
        0.039999596774578094,
        -0.01366748008877039,
        0.04944620653986931,
        0.02754209376871586,
        0.04798775538802147,
        -0.0463099330663681,
        -0.05069298297166824,
        0.06282026320695877,
        0.021072469651699066,
        -0.01522895973175764,
        0.05871035158634186,
        -0.03482416272163391,
        0.042558372020721436,
        0.009581913240253925,
        0.029240984469652176,
        -0.05288517475128174,
        -0.030978651717305183,
        -0.04912374168634415,
        -0.0031400243751704693,
        0.04883340001106262,
        -0.05370127037167549,
        0.043792519718408585,
        -0.06017567589879036,
        -0.059515297412872314,
        -0.06078203767538071,
        -0.06300000846385956,
        -0.05761865898966789,
        0.06115420535206795,
        -0.05990348011255264,
        0.013964361511170864,
        -0.06287690252065659,
        -0.03777533769607544,
        -0.02144360914826393,
        0.014610808342695236,
        -0.061642613261938095,
        0.04716670140624046,
        0.046937793493270874,
        0.0558355413377285,
        0.043015412986278534,
        0.050743550062179565,
        -0.03993396461009979,
        0.050952523946762085,
        0.05564786493778229,
        -0.03351413086056709,
        -0.015765132382512093,
        0.05977022647857666,
        0.046387750655412674,
        -0.050747234374284744,
        0.06204143539071083,
        -0.045222070068120956,
        0.021075287833809853,
        0.01335927750915289,
        -0.03660409152507782,
        0.017117939889431,
        -0.05644470825791359,
        0.0631665587425232,
        0.017109593376517296,
        0.008865580894052982,
        0.04048221558332443,
        -0.026539305225014687,
        0.06258026510477066,
        -0.0631105974316597,
        0.032857440412044525,
        -0.04186863824725151,
        -0.03578637167811394,
        0.011287997476756573,
        0.06145043671131134,
        -0.0631222277879715,
        -0.028531821444630623,
        -0.05557524412870407,
        0.0015141151379793882,
        0.05532532557845116,
        0.02675807476043701,
        -0.026011565700173378,
        -0.014835672453045845,
        0.05016309767961502,
        -0.05896742269396782,
        0.06260143965482712,
        -0.0139961838722229,
        0.04132305458188057,
        -0.0628877580165863,
        -0.041257455945014954,
        -0.061586443334817886,
        0.05542796850204468,
        0.04245736822485924,
        -0.045642539858818054,
        0.022687997668981552,
        -0.007074889726936817,
        -0.0072579761035740376,
        -0.026165535673499107,
        -0.044463690370321274,
        0.04984242096543312,
        0.030860528349876404,
        -0.06289464980363846,
        0.04998704418540001,
        0.051262106746435165,
        -0.045577920973300934,
        0.014839491806924343,
        0.006822506431490183,
        -0.05701334401965141,
        0.02227272465825081,
        -0.011268062517046928,
        0.0011034643976017833,
        0.004797682631760836,
        0.011729704216122627,
        -0.05874976888298988,
        0.05749083310365677,
        0.039464276283979416,
        -0.02821679785847664,
        -0.05150889232754707,
        -0.06260407716035843,
        -0.04808305203914642,
        0.05962345749139786,
        0.06121211498975754,
        0.059546902775764465,
        -0.05387461557984352,
        -0.04795102775096893,
        0.04673720523715019,
        -0.039187222719192505,
        0.057380322366952896,
        -0.02636753022670746,
        0.0486614853143692,
        0.017891334369778633,
        -0.061963461339473724,
        0.004855070263147354,
        0.019904309883713722,
        0.034831345081329346,
        -0.05630459636449814,
        -0.029539795592427254,
        0.014216638170182705,
        -0.021302811801433563,
        -0.05030098557472229,
        -0.05097787454724312,
        0.006279793567955494,
        -0.06117263808846474,
        0.04969985783100128,
        0.055033907294273376,
        -0.05916382372379303,
        -0.006274071522057056,
        -0.048267912119627,
        -0.0429329052567482,
        0.05350857228040695,
        0.025103013962507248,
        0.03864431753754616,
        0.050812751054763794,
        0.034498702734708786,
        -0.01869777776300907,
        -0.008461285382509232,
        0.02467663772404194,
        0.006536807399243116,
        -0.062001969665288925,
        -0.025037257000803947,
        0.05410418659448624,
        -0.022378820925951004,
        0.03892070800065994,
        -0.02985580451786518,
        0.0418672189116478,
        0.06022399663925171,
        -0.055844828486442566,
        0.04695696011185646,
        -0.05935036763548851,
        0.020392384380102158,
        0.041933055967092514,
        0.03093572147190571,
        -0.026516515761613846,
        -0.03344453498721123,
        -0.03618667647242546,
        0.0038691433146595955,
        0.03294852748513222,
        -0.06176723167300224,
        0.052454590797424316,
        0.038440342992544174,
        -0.038456983864307404,
        0.01940246857702732,
        -0.05424060672521591,
        0.04967482388019562,
        0.0628715306520462,
        -0.06302619725465775,
        0.058375608175992966,
        -0.031345486640930176,
        -0.06260999292135239,
        0.04712725058197975,
        -0.05808157101273537,
        0.03477779030799866,
        0.06235318258404732,
        0.061957329511642456,
        0.05162076652050018,
        -0.01968109980225563,
        0.03945758938789368,
        -0.023134347051382065,
        -0.056714024394750595,
        -0.0438111238181591,
        0.005744155962020159,
        0.05236510932445526,
        0.059175729751586914,
        0.029700718820095062,
        0.030795184895396233,
        0.059417299926280975,
        -0.05602554976940155,
        -0.05697925388813019,
        -0.04106660187244415,
        -0.05071306973695755,
        -0.06099768355488777,
        -0.05041166767477989,
        0.015056418254971504,
        0.06158256530761719,
        0.03205552697181702,
        -0.06222322955727577,
        0.041145991533994675,
        0.06011704355478287,
        -0.017308298498392105,
        -0.05846979096531868,
        -0.027102302759885788,
        -0.02082855999469757,
        0.039149146527051926,
        -0.0046550203114748,
        0.0228569395840168,
        -0.050287969410419464,
        0.019045080989599228,
        -0.05100531131029129,
        -0.04069197550415993,
        0.06151299551129341,
        0.050224386155605316
    ],
    [
        -0.05112115666270256,
        0.05115915834903717,
        -0.03006943315267563,
        0.048152677714824677,
        -0.017109541222453117,
        -0.05198373645544052,
        -0.018708284944295883,
        -0.051203180104494095,
        0.05181556195020676,
        -0.05188210308551788,
        0.05154411867260933,
        -0.04146008938550949,
        -0.04341474175453186,
        0.05221033841371536,
        -0.04693350940942764,
        0.05220676586031914,
        -0.004551384598016739,
        0.03556830808520317,
        -0.04772787541151047,
        -0.05098016932606697,
        -0.04800250008702278,
        -0.04792814701795578,
        0.046341318637132645,
        0.04954991862177849,
        -0.04042510315775871,
        0.05129794776439667,
        0.046522390097379684,
        -0.0018941459711641073,
        -0.05082814395427704,
        -0.029746560379862785,
        -0.05019120126962662,
        -0.04786108434200287,
        -0.05130339041352272,
        -0.05221113935112953,
        0.052178606390953064,
        -0.04640462249517441,
        -0.05179499462246895,
        -0.0402984619140625,
        -0.041566476225852966,
        0.041958898305892944,
        -0.03288258984684944,
        0.05048258230090141,
        0.005050498992204666,
        -0.052213650196790695,
        0.05220154672861099,
        -0.051966287195682526,
        -0.03515408933162689,
        0.05164770036935806,
        0.05221043899655342,
        -0.0521378293633461,
        0.05217916890978813,
        0.026253845542669296,
        -0.009491432458162308,
        -0.04476635158061981,
        0.027021465823054314,
        -0.011896159499883652,
        0.05221366137266159,
        -0.04545679688453674,
        -0.04490386322140694,
        -0.052147310227155685,
        -0.052080824971199036,
        -0.019640011712908745,
        -0.03979792818427086,
        0.051432572305202484,
        0.05130642279982567,
        0.05217235907912254,
        -0.0474107600748539,
        -0.0500558465719223,
        -0.039521146565675735,
        -0.007137977052479982,
        -0.01079718116670847,
        -0.05102686583995819,
        0.05208718776702881,
        0.0513031966984272,
        0.05220595747232437,
        0.045353129506111145,
        -0.0323224775493145,
        0.05221366137266159,
        0.05218853801488876,
        -0.050976745784282684,
        -0.05193197727203369,
        -0.05221165716648102,
        -0.05217651277780533,
        0.04780217260122299,
        0.04336214065551758,
        0.05005478113889694,
        -0.03061489202082157,
        -0.0510593019425869,
        0.05028696730732918,
        0.026187188923358917,
        -0.05202992260456085,
        -0.03924912214279175,
        0.04606202617287636,
        0.052071716636419296,
        -0.03347654268145561,
        0.04846285283565521,
        0.04824257642030716,
        -0.04447397217154503,
        0.03189277648925781,
        0.05198708176612854,
        -0.05219084769487381,
        0.04802750051021576,
        0.05161875858902931,
        0.009131787344813347,
        0.012533432804048061,
        -0.052213676273822784,
        -0.05009809136390686,
        0.04488227143883705,
        -0.051245301961898804,
        0.025692252442240715,
        -0.052209679037332535,
        -0.05144306272268295,
        -0.051989953964948654,
        0.05077526345849037,
        -0.022235237061977386,
        0.052213676273822784,
        0.049703486263751984,
        -0.05137151852250099,
        0.05009380355477333,
        -0.05221296846866608,
        0.02165479212999344,
        -0.03495359048247337,
        -0.028536103665828705,
        -0.052108924835920334,
        -0.014286031015217304,
        -0.05211040377616882,
        0.05203253775835037,
        -0.05221344158053398,
        0.05220140144228935,
        0.04832169786095619,
        0.04767485335469246,
        -0.04630958288908005,
        -0.04644378647208214,
        -0.01004601176828146,
        -0.04170959070324898,
        -0.05202566459774971,
        0.052032362669706345,
        -0.035432323813438416,
        0.0467904657125473,
        0.04902534931898117,
        -0.04014038294553757,
        -0.04887540265917778,
        0.043872520327568054,
        0.052139002829790115,
        0.0504356324672699,
        0.047760188579559326,
        -0.026847099885344505,
        -0.0500640831887722,
        -0.0068735782988369465,
        -0.04863463342189789,
        -0.04452432692050934,
        -0.05192992836236954,
        0.05221102759242058,
        0.03723056614398956,
        -0.040243156254291534,
        -0.05109414830803871,
        -0.052152588963508606,
        0.04023977369070053,
        0.05219520628452301,
        0.04403958097100258,
        -0.00019422410696279258,
        0.046987779438495636,
        0.05151660740375519,
        -0.032329533249139786,
        0.05055273696780205,
        0.029675815254449844,
        0.051756031811237335,
        -0.04728763923048973,
        0.044012244790792465,
        0.04213855043053627,
        0.05199642851948738,
        0.05198529362678528,
        -0.05218097195029259,
        -0.05026310682296753,
        0.009644594974815845,
        0.05218293517827988,
        0.05138130858540535,
        -0.006845791824162006,
        0.049368493258953094,
        -0.052175235003232956,
        -0.05215419828891754,
        -0.01296908687800169,
        0.029850924387574196,
        -0.039640143513679504,
        -0.047161445021629333,
        -0.052035942673683167,
        0.05198299139738083,
        0.05210212618112564,
        -0.009495492093265057,
        -0.03599802777171135,
        -0.05160987377166748,
        0.034882042557001114,
        -0.009985066018998623,
        0.0521748885512352,
        -0.05202542245388031,
        -0.04802441969513893,
        0.0521918348968029,
        0.04390648752450943,
        -0.008478375151753426,
        0.05214980989694595,
        0.052018798887729645,
        0.046655260026454926,
        -0.04086403176188469,
        -0.05219949409365654,
        -0.005663655232638121,
        0.04161424934864044,
        0.051738936454057693,
        -0.05217895656824112,
        0.041422516107559204,
        0.011819836683571339,
        -0.030946053564548492,
        0.041906654834747314,
        -0.011649535968899727,
        0.05215628072619438,
        -0.04364820942282677,
        -0.05045943707227707,
        0.0479414276778698,
        0.03568518906831741,
        -0.04910671338438988,
        0.05219774320721626,
        0.05213765799999237,
        0.0522114597260952,
        -0.002900687512010336,
        0.014249696396291256,
        -0.016059907153248787,
        0.05060309171676636,
        0.014315009117126465,
        0.033387038856744766,
        0.0522117055952549,
        0.0024742265231907368,
        0.051236849278211594,
        0.05147486925125122,
        -0.006001184228807688,
        0.047930870205163956,
        0.051272422075271606,
        0.05220489203929901,
        0.05092537775635719,
        -0.018896134570240974,
        -0.039781179279088974,
        0.052212245762348175,
        0.041018739342689514,
        -0.04384900629520416,
        -0.05088227614760399,
        0.05187137797474861,
        0.035007841885089874,
        -0.031204821541905403,
        0.04800776019692421,
        0.023479275405406952,
        0.05153212696313858,
        0.05195578187704086,
        0.0502876415848732,
        -0.05207355320453644,
        -0.051337722688913345,
        -0.050310421735048294,
        0.05000365898013115,
        0.05212772637605667,
        0.05220867320895195,
        -0.01771274022758007,
        0.04581957682967186,
        -0.05198195204138756,
        -0.05213746801018715,
        -0.050733793526887894,
        0.045859143137931824,
        0.002827260410413146,
        -0.051392313092947006,
        0.05221039801836014,
        -0.038932666182518005,
        0.05211707577109337,
        -0.051412519067525864,
        -0.03497637063264847,
        -0.03953425958752632,
        0.05017083138227463,
        -0.05081196501851082,
        -0.05209602788090706,
        0.03159378096461296,
        -0.03150827810168266,
        -0.04648476839065552,
        0.03857538476586342,
        -0.04258585721254349,
        0.01706661656498909,
        -0.05073818564414978,
        0.04357293248176575,
        -0.03665255755186081,
        0.04936767742037773,
        0.049316391348838806,
        0.052007317543029785,
        0.039069704711437225,
        0.052034761756658554,
        0.049285825341939926,
        0.05054517462849617,
        -0.03048310987651348,
        -0.05185515806078911,
        -0.036577723920345306,
        0.017002874985337257,
        -0.052205272018909454,
        -0.048445332795381546,
        0.052116356790065765,
        -0.04190312698483467,
        -0.05218178406357765,
        0.0514509454369545,
        0.05219775065779686,
        0.038475651293992996,
        0.04896896332502365,
        0.04577717185020447,
        0.05012235417962074,
        0.0008051524637266994,
        -0.05206938460469246,
        0.04446038603782654,
        -0.03403520584106445,
        0.0504688136279583,
        -0.05212578922510147,
        0.03846780210733414,
        -0.03626129403710365,
        -0.05221346393227577,
        -0.04151613265275955,
        -0.037214696407318115,
        0.006837427616119385,
        -0.05204478278756142,
        0.05179313197731972,
        -0.05040028691291809,
        0.05219201371073723,
        -0.04510936141014099,
        -0.0518709234893322,
        -0.05109911039471626,
        0.052179835736751556,
        -0.05142204836010933,
        -0.03280169516801834,
        0.03471413254737854,
        -0.05200367420911789,
        0.04634474962949753,
        0.013866324909031391,
        -0.04888792708516121,
        -0.046694062650203705,
        -0.05218986049294472,
        0.0512521006166935,
        -0.05220047011971474,
        -0.0488528236746788,
        0.04128580912947655,
        -0.04703623056411743,
        0.0522041916847229,
        -0.04513036087155342,
        0.05098101124167442,
        -0.0324135459959507,
        0.009835558943450451,
        -0.04849005490541458,
        0.02182777039706707,
        -0.028082311153411865,
        0.03886709734797478,
        -0.05220724269747734,
        0.04594404622912407,
        0.05202091485261917,
        -0.014053929597139359,
        0.05204552039504051,
        -0.05173651874065399,
        0.01539393700659275,
        0.013355144299566746,
        -0.05092046037316322,
        -0.051976051181554794,
        0.05175720900297165,
        -0.04138960316777229,
        0.013586672022938728,
        0.05188007652759552,
        -0.04258323833346367,
        0.030780350789427757,
        0.046020325273275375,
        -0.04899618774652481,
        0.052160345017910004,
        -0.011309181340038776,
        0.04368869215250015,
        -0.04899216815829277,
        -0.04661000147461891,
        -0.047110848128795624,
        0.04416045919060707,
        0.029698394238948822,
        0.04584811255335808,
        -0.01302609033882618,
        0.020814955234527588,
        0.011757832951843739,
        0.04850621148943901,
        0.034604042768478394,
        -0.05012485384941101,
        0.05221359059214592,
        -0.05179726704955101,
        0.020041480660438538,
        0.05220777913928032,
        -0.04968470707535744,
        -0.050352003425359726,
        -0.05194613337516785,
        -0.02962786890566349,
        0.03386925160884857,
        -0.05148537456989288,
        -0.03604312241077423,
        -0.052086081355810165,
        -0.04838504642248154,
        -0.052177175879478455,
        0.04950259253382683,
        0.05212227255105972,
        0.0458606481552124,
        -0.04844852536916733,
        -0.051963549107313156,
        -0.05000508204102516,
        0.052210528403520584,
        0.047106608748435974,
        -0.05218305438756943,
        -0.051829248666763306,
        0.01713208109140396,
        0.011893656104803085,
        -0.05215625464916229,
        0.05179598182439804,
        -0.045612238347530365,
        -0.050228673964738846,
        0.03152245283126831,
        -0.034410249441862106,
        -0.05005925893783569,
        0.05170196294784546,
        -0.04426518827676773,
        -0.05113336071372032,
        -0.052062954753637314,
        -0.0369388721883297,
        -0.05155526101589203,
        0.0389423593878746,
        0.02968081645667553,
        0.04678795486688614,
        -0.05208561196923256,
        0.041068702936172485,
        0.014272588305175304,
        0.05188246816396713,
        -0.04801682382822037,
        -0.017523029819130898,
        -0.05206597223877907,
        0.052213676273822784,
        0.05217213183641434,
        0.05209333449602127,
        0.051560789346694946,
        -0.051985256373882294,
        -0.05178927630186081,
        0.046883657574653625,
        0.05194380506873131,
        0.01079794205725193,
        -0.05213799700140953,
        -0.05187416449189186,
        0.04946477338671684,
        -0.051627762615680695,
        0.03472639247775078,
        0.014377787709236145,
        0.052121300250291824,
        0.05208779498934746,
        0.0486207976937294,
        -0.009145672433078289,
        0.05220828577876091,
        -0.004992317408323288,
        0.052020683884620667,
        0.041039880365133286,
        0.008160436525940895,
        -0.01525700744241476,
        -0.04300420731306076,
        -0.03203648701310158,
        -0.033355340361595154,
        0.05195419117808342,
        0.05197949707508087,
        0.048960499465465546,
        -0.0418957844376564,
        0.04786500707268715,
        0.051982581615448,
        0.052206989377737045,
        0.05220174044370651,
        0.018180284649133682,
        0.052071504294872284,
        0.01742849498987198,
        -0.05213841795921326,
        0.05170639231801033,
        -0.05221317708492279,
        0.0507742278277874,
        0.05187491327524185,
        -0.026263616979122162,
        0.04831560701131821,
        0.0522126704454422,
        -0.05204962193965912,
        0.04830513894557953,
        0.04179012030363083,
        0.04255150631070137,
        0.04800434038043022,
        0.046598169952631,
        0.04483552277088165,
        -0.02701057493686676,
        0.0029363573994487524,
        0.0521731972694397,
        0.05183916538953781,
        -0.0398675911128521,
        0.046817582100629807,
        0.05080592632293701,
        -0.051533132791519165,
        0.05218128114938736,
        0.00745790172368288,
        0.012352951802313328,
        -0.03263333439826965,
        -0.052213139832019806,
        -0.050485726445913315,
        0.03448567911982536,
        0.0003337629314046353,
        -0.051651015877723694,
        0.027303645387291908,
        -0.05220036953687668,
        0.05173588544130325,
        -0.03819886967539787,
        0.04438790678977966,
        0.05220797285437584,
        -0.015401327051222324,
        0.05213398113846779,
        0.02999146096408367,
        0.00910241063684225,
        0.007303561083972454
    ],
    [
        0.0454401858150959,
        0.047767285257577896,
        0.049242258071899414,
        0.0353991724550724,
        -0.008755546063184738,
        -0.048641301691532135,
        0.04540523886680603,
        -0.0476265475153923,
        0.04524598270654678,
        0.024538680911064148,
        0.04966677725315094,
        0.047655172646045685,
        -0.0492413304746151,
        -0.012502916157245636,
        -0.0462021566927433,
        0.04882150888442993,
        -0.014030554331839085,
        0.0022868472151458263,
        -0.04130649194121361,
        0.04963824152946472,
        0.04979068413376808,
        -0.049318231642246246,
        0.03232903406023979,
        0.037823475897312164,
        0.03940422460436821,
        0.04026590660214424,
        0.006545837037265301,
        0.04975160211324692,
        -0.04979116469621658,
        -0.04818187654018402,
        -0.03127826750278473,
        0.04978553578257561,
        0.04792186617851257,
        -0.049788374453783035,
        0.049447983503341675,
        -0.04967690259218216,
        -0.04619423672556877,
        -0.04914768040180206,
        -0.049791187047958374,
        -0.0497293658554554,
        0.04736039787530899,
        0.04824816435575485,
        0.017835529521107674,
        -0.04792068153619766,
        0.04943617060780525,
        -0.04958237335085869,
        0.03149879723787308,
        0.04936094954609871,
        0.04979116469621658,
        0.023123372346162796,
        0.04969359561800957,
        0.04978391155600548,
        -0.033446796238422394,
        -0.018372148275375366,
        -0.02930469624698162,
        0.04972366243600845,
        -0.04924320802092552,
        -0.04953945800662041,
        -0.0494576133787632,
        -0.049246884882450104,
        -0.04960593581199646,
        0.048822905868291855,
        -0.04752237722277641,
        0.0491919107735157,
        0.028567492961883545,
        0.0395723432302475,
        -0.049788787961006165,
        0.0009199430933222175,
        0.04979106783866882,
        0.04955431446433067,
        -0.04953070357441902,
        0.049699895083904266,
        -0.04866998642683029,
        0.04310131072998047,
        0.04954766854643822,
        0.04213746637105942,
        0.04976923391222954,
        0.04979117587208748,
        0.049272555857896805,
        -0.0489017590880394,
        -0.04455966129899025,
        -0.049765653908252716,
        -0.04933082312345505,
        0.0496240071952343,
        -0.04978514090180397,
        0.02262059971690178,
        0.010419518686830997,
        -0.04717573896050453,
        0.049109019339084625,
        0.03866135701537132,
        0.015106245875358582,
        0.043100763112306595,
        0.04960078373551369,
        -0.046229589730501175,
        -0.049012068659067154,
        0.04887024685740471,
        0.033526547253131866,
        -0.04167810082435608,
        -0.04967941716313362,
        0.02638980932533741,
        -0.049645040184259415,
        0.04977383464574814,
        -0.04553297907114029,
        0.049304112792015076,
        0.016571272164583206,
        -0.049791187047958374,
        -0.0497467927634716,
        0.03922629356384277,
        -0.04978254809975624,
        0.04925021901726723,
        0.0230712890625,
        -0.04976463317871094,
        0.028754303231835365,
        0.0216116514056921,
        0.049791138619184494,
        0.04583142325282097,
        0.03878578171133995,
        -0.018845845013856888,
        -0.02942691557109356,
        -0.049791090190410614,
        -0.04977524280548096,
        -0.04961703345179558,
        -0.049654584378004074,
        -0.04979031905531883,
        -0.04643147066235542,
        0.049654051661491394,
        -0.049790676683187485,
        -0.04920664429664612,
        0.04945699870586395,
        0.04885535687208176,
        0.03947701305150986,
        -0.04973546043038368,
        0.047071199864149094,
        0.04889560118317604,
        -0.04499233514070511,
        -0.04939781874418259,
        0.04965130612254143,
        -0.008113807067275047,
        -0.04978955164551735,
        0.04978194832801819,
        0.04965610057115555,
        -0.04610647261142731,
        0.04194030538201332,
        0.049759816378355026,
        -0.033027276396751404,
        0.04706597700715065,
        0.033338192850351334,
        -0.049641650170087814,
        -0.04779430478811264,
        0.04632072150707245,
        -0.04934770613908768,
        -0.04965391755104065,
        0.049791187047958374,
        0.0472470223903656,
        -0.04978916794061661,
        -0.049787115305662155,
        -0.04906891658902168,
        -0.04673079401254654,
        0.04978569224476814,
        0.037207573652267456,
        -0.029829837381839752,
        -0.03333716094493866,
        0.047767363488674164,
        -0.008424696512520313,
        -0.04964837059378624,
        -0.04953198879957199,
        -0.021487874910235405,
        -0.043044477701187134,
        -0.04966212809085846,
        0.011647667735815048,
        0.04639047011733055,
        0.040999636054039,
        -0.04977600276470184,
        0.049778785556554794,
        0.049791187047958374,
        0.04975973814725876,
        0.04880824685096741,
        0.041451629251241684,
        0.04971970617771149,
        -0.04404165968298912,
        -0.049754269421100616,
        -0.04592758044600487,
        0.04968665912747383,
        -0.04968666657805443,
        -0.015163350850343704,
        0.027622424066066742,
        0.04888478294014931,
        0.04950522631406784,
        -0.033755164593458176,
        0.04979116469621658,
        -0.049703449010849,
        0.049534473568201065,
        -0.04964907839894295,
        0.042098138481378555,
        -0.0497625507414341,
        -0.047896165400743484,
        0.04852338135242462,
        0.01867666468024254,
        0.04978841170668602,
        0.012528989464044571,
        0.04976624995470047,
        0.04827798530459404,
        -0.046605195850133896,
        -0.049767497926950455,
        0.03368355333805084,
        0.0244677122682333,
        0.04829822853207588,
        -0.0006355757941491902,
        -0.01821460947394371,
        0.048899050801992416,
        -0.0483522042632103,
        0.048525597900152206,
        0.015844009816646576,
        0.04973611235618591,
        -0.030293622985482216,
        -0.049791138619184494,
        0.049460090696811676,
        0.032074909657239914,
        0.04889978840947151,
        -0.04979000240564346,
        0.03486153855919838,
        0.049791160970926285,
        -0.042798686772584915,
        -0.04974275454878807,
        -0.049198735505342484,
        0.049791187047958374,
        0.03723488003015518,
        0.045121029019355774,
        0.04978379234671593,
        -0.0480622723698616,
        -0.049791187047958374,
        0.049791187047958374,
        0.007412342354655266,
        0.03068644180893898,
        -0.04947367683053017,
        0.04979071393609047,
        0.04863012209534645,
        -0.04866957291960716,
        -0.04742589592933655,
        0.04978315159678459,
        0.04978712648153305,
        -0.022629689425230026,
        0.04912984371185303,
        -0.04978492110967636,
        0.04596230015158653,
        -0.04837566241621971,
        -0.04970772564411163,
        0.037157442420721054,
        -0.04978962242603302,
        -0.0208795964717865,
        -0.04913710057735443,
        -0.029875336214900017,
        -0.04975790157914162,
        -0.04943384975194931,
        -0.04382997751235962,
        0.0488516204059124,
        -0.04614846780896187,
        0.04974333569407463,
        0.004969585686922073,
        0.037916235625743866,
        -0.03986004367470741,
        -0.04977067932486534,
        -0.039372749626636505,
        0.04941822960972786,
        -0.049733966588974,
        0.04979104921221733,
        0.049791187047958374,
        0.04977962002158165,
        -0.049784205853939056,
        -0.04653743654489517,
        -0.0412902757525444,
        -0.025824539363384247,
        0.024112986400723457,
        -0.04977693036198616,
        -0.049784716218709946,
        0.010433618910610676,
        -0.027519697323441505,
        -0.04751254618167877,
        -0.048267241567373276,
        0.04892239719629288,
        -0.04977613687515259,
        0.04663587734103203,
        0.04937877878546715,
        0.04951700195670128,
        0.04965156316757202,
        0.049783289432525635,
        0.049313735216856,
        -0.044692546129226685,
        0.04917561262845993,
        0.04909516125917435,
        -0.047212935984134674,
        -0.044586557894945145,
        -0.04955336079001427,
        -0.039551347494125366,
        -0.042227841913700104,
        -0.048513077199459076,
        0.04978884756565094,
        -0.049575090408325195,
        -0.004346303176134825,
        0.049609072506427765,
        -0.0497874841094017,
        -0.022206922993063927,
        -0.00878214556723833,
        0.049596160650253296,
        0.04375764727592468,
        -0.01257408782839775,
        0.04926835000514984,
        0.0015285969711840153,
        -0.004600058309733868,
        0.04450509324669838,
        0.02087349444627762,
        0.049781832844018936,
        -0.035098396241664886,
        -0.025381330400705338,
        -0.04887767508625984,
        -0.0492607057094574,
        -0.014436960220336914,
        -0.04912455752491951,
        0.04930536448955536,
        0.016470221802592278,
        0.04977232217788696,
        0.007618446834385395,
        -0.04776717349886894,
        -0.049791183322668076,
        0.04960169643163681,
        -0.047212742269039154,
        0.04977191239595413,
        -0.048931822180747986,
        -0.012425262480974197,
        -0.04906198009848595,
        0.005152517929673195,
        -0.024995720013976097,
        -0.04701203480362892,
        -0.04569920524954796,
        0.0495263934135437,
        0.04913537576794624,
        -0.04916512966156006,
        -0.0179746076464653,
        -0.0321497805416584,
        -0.03682020679116249,
        -0.03641367331147194,
        -0.04967202618718147,
        0.04978080838918686,
        -0.046095967292785645,
        0.0497911162674427,
        0.04272877052426338,
        -0.021606989204883575,
        0.04835452511906624,
        -0.04693734645843506,
        0.049195148050785065,
        0.04738669469952583,
        -0.042812056839466095,
        0.04773549735546112,
        -0.049717534333467484,
        0.02900133840739727,
        -0.02291470766067505,
        -0.049682386219501495,
        -0.049691248685121536,
        -0.04976080730557442,
        -0.046205420047044754,
        0.04079880565404892,
        0.03146902471780777,
        -0.030368739739060402,
        -0.022907480597496033,
        -0.014164451509714127,
        -0.049267008900642395,
        0.04976993054151535,
        0.04975555092096329,
        -0.0494011826813221,
        -0.04948829486966133,
        -0.03960056230425835,
        0.02013408951461315,
        0.04684066027402878,
        -0.040317535400390625,
        0.04968602955341339,
        0.037621963769197464,
        -0.011795520782470703,
        -0.0484737753868103,
        0.03938732296228409,
        -0.04970132187008858,
        -0.048995912075042725,
        0.049791160970926285,
        -0.049162786453962326,
        -0.04041072726249695,
        0.04979115352034569,
        -0.04973774775862694,
        -0.04925723373889923,
        -0.049719423055648804,
        -0.04847172647714615,
        0.03321395441889763,
        0.04121679067611694,
        -0.043039895594120026,
        -0.04970943182706833,
        0.045500125735998154,
        -0.0435856431722641,
        0.048511356115341187,
        0.04953773319721222,
        0.04426630586385727,
        -0.04977957531809807,
        -0.04978007450699806,
        -0.02478243224322796,
        0.04978248476982117,
        0.04978971183300018,
        0.04817018657922745,
        -0.038981132209300995,
        -0.04966602474451065,
        0.04977797344326973,
        -0.049789879471063614,
        0.04902997240424156,
        -0.0491974875330925,
        0.04978989064693451,
        0.0492948442697525,
        -0.033902764320373535,
        -0.04979030042886734,
        0.04865231364965439,
        -0.03261437639594078,
        0.04320487380027771,
        -0.049551527947187424,
        -0.03238248825073242,
        -0.0497894324362278,
        0.04978565871715546,
        -0.04975006356835365,
        -0.04977218061685562,
        -0.04971978813409805,
        0.048518817871809006,
        -0.025418167933821678,
        -0.0494237020611763,
        -0.04800247773528099,
        -0.0355294793844223,
        -0.049784041941165924,
        -0.04416699334979057,
        -0.02474667690694332,
        0.049279969185590744,
        0.049701888114213943,
        -0.04894714057445526,
        -0.04973209276795387,
        -0.048963189125061035,
        0.02860674448311329,
        -0.03927620127797127,
        -0.049782175570726395,
        -0.046353552490472794,
        0.049775585532188416,
        -0.04548264294862747,
        0.04923715069890022,
        0.049791187047958374,
        -0.04976397380232811,
        0.04966707527637482,
        -0.030429737642407417,
        -0.028278730809688568,
        0.04979107156395912,
        -0.04970545321702957,
        0.0497862845659256,
        0.04035172238945961,
        0.04969657585024834,
        -0.04148507118225098,
        -0.03907013684511185,
        -0.01569306291639805,
        -0.04641856998205185,
        0.03817625343799591,
        0.04978572577238083,
        0.049791187047958374,
        -0.04899376258254051,
        -0.041827499866485596,
        0.04755114018917084,
        0.049787767231464386,
        0.049775347113609314,
        -0.048555295914411545,
        0.0497903898358345,
        0.04840270057320595,
        -0.049782995134592056,
        0.04967238008975983,
        -0.049791187047958374,
        0.04853900521993637,
        0.04978609085083008,
        0.049787361174821854,
        0.04584907367825508,
        -0.04977431893348694,
        0.0392879955470562,
        -0.046993155032396317,
        -0.0480199009180069,
        -0.04945870116353035,
        0.03294850140810013,
        0.03377829119563103,
        0.023472631350159645,
        0.04979075863957405,
        0.04429066926240921,
        0.049791187047958374,
        0.04229705408215523,
        -0.048199184238910675,
        0.04886771738529205,
        0.04929983615875244,
        -0.04977734386920929,
        0.049642473459243774,
        0.04704933986067772,
        -0.04976242780685425,
        0.0018862804863601923,
        -0.04976985603570938,
        -0.04978826642036438,
        0.049088988453149796,
        -0.04960385710000992,
        -0.04979003965854645,
        -0.04978223145008087,
        -0.04978189617395401,
        0.04976329579949379,
        0.04978982359170914,
        0.04711831733584404,
        -0.047480612993240356,
        -0.038575537502765656,
        0.04979117587208748,
        -0.049750689417123795,
        -0.04978920519351959,
        -0.04957663640379906
    ],
    [
        -0.004359109792858362,
        -0.0045545161701738834,
        0.04670324549078941,
        -0.048910707235336304,
        0.006370218005031347,
        -0.05417851731181145,
        0.044016242027282715,
        -0.04772648587822914,
        0.041736703366041183,
        -0.05482465401291847,
        0.05403502285480499,
        -0.0023086394648998976,
        -0.05478350445628166,
        -0.014324784278869629,
        -0.05344562605023384,
        0.04415807127952576,
        0.001174268196336925,
        0.05313008278608322,
        -0.046702273190021515,
        0.021845024079084396,
        0.02927934192121029,
        -0.04171941801905632,
        0.050187673419713974,
        0.05190163850784302,
        -0.047694604843854904,
        0.014361276291310787,
        0.04768981412053108,
        0.047681115567684174,
        -0.054649967700242996,
        0.03205036371946335,
        0.05427353456616402,
        0.05021647363901138,
        0.05462788790464401,
        -0.05494226515293121,
        0.05447237193584442,
        -0.053717512637376785,
        -0.053596753627061844,
        -0.01914971135556698,
        -0.05477152764797211,
        -0.0342986173927784,
        -0.027385281398892403,
        0.031506653875112534,
        0.0359199121594429,
        -0.05182608217000961,
        -0.008790523745119572,
        0.04027044028043747,
        0.015468697994947433,
        0.0449286587536335,
        0.05498350039124489,
        -0.042446788400411606,
        0.04965945705771446,
        0.053191203624010086,
        0.036904845386743546,
        -0.020411700010299683,
        0.037958260625600815,
        0.04546167701482773,
        -0.05382639542222023,
        -0.042356010526418686,
        -0.024247588589787483,
        -0.05242780223488808,
        -0.0549783855676651,
        0.054432567209005356,
        -0.05456099659204483,
        -0.02536173164844513,
        0.04572594538331032,
        0.052554450929164886,
        -0.05482979863882065,
        -0.04316779226064682,
        0.053625427186489105,
        -0.029628369957208633,
        -0.04972146824002266,
        -0.04400197044014931,
        0.05340798199176788,
        0.04409228637814522,
        0.05464758351445198,
        0.007597873918712139,
        -0.01982414536178112,
        0.054999787360429764,
        0.00022782052110414952,
        -0.031439829617738724,
        -0.011437980458140373,
        -0.05373137444257736,
        -0.04350527003407478,
        0.0537354052066803,
        0.050583116710186005,
        0.033280324190855026,
        0.028859898447990417,
        -0.03895392641425133,
        -0.0102754021063447,
        0.053445346653461456,
        0.009938214905560017,
        0.04644316807389259,
        -0.03928421437740326,
        -0.022859906777739525,
        -0.049608372151851654,
        -0.004554521758109331,
        0.020982304587960243,
        -0.05482475832104683,
        0.012104136869311333,
        0.05476880073547363,
        -0.05499910190701485,
        0.05346974357962608,
        0.05156007409095764,
        0.05290087312459946,
        0.05445357412099838,
        -0.05485314130783081,
        -0.0518190935254097,
        0.05466631427407265,
        -0.0542728528380394,
        0.05433354899287224,
        -0.047544002532958984,
        -0.05412695184350014,
        -0.054742295295000076,
        -0.03510993719100952,
        0.05435262247920036,
        -0.04254717752337456,
        -0.04578838497400284,
        -0.047401946038007736,
        0.004476357717067003,
        -0.054902005940675735,
        -0.0539255291223526,
        -0.029180919751524925,
        0.03543151170015335,
        -0.05463055148720741,
        -0.05155930295586586,
        0.0543627068400383,
        -0.038010627031326294,
        0.010499676689505577,
        0.053140074014663696,
        -0.008693409152328968,
        -0.005969109944999218,
        -0.05072862654924393,
        -0.05394238978624344,
        0.04624797776341438,
        -0.01703065074980259,
        -0.05497285723686218,
        0.0540904775261879,
        -0.05485595762729645,
        -0.05057168006896973,
        0.0548526830971241,
        0.03489479050040245,
        -0.022551454603672028,
        0.006272966507822275,
        0.054935503751039505,
        0.05336250364780426,
        0.03945733234286308,
        0.05359617620706558,
        0.006049989722669125,
        -0.01916501484811306,
        0.053622469305992126,
        -0.04830058664083481,
        -0.05420868843793869,
        -0.04778415337204933,
        -0.02146529033780098,
        0.054569311439991,
        -0.050080783665180206,
        -0.04871923476457596,
        -0.003873384790495038,
        0.053234029561281204,
        0.04783285781741142,
        0.05272457003593445,
        0.0517846941947937,
        0.05467401444911957,
        0.04587080329656601,
        0.003818216035142541,
        -0.0510161928832531,
        0.053910207003355026,
        0.04525942727923393,
        0.022817615419626236,
        0.05331677943468094,
        0.0062489197589457035,
        0.054470375180244446,
        -0.05496715009212494,
        0.05447850003838539,
        -0.05136772617697716,
        0.046262890100479126,
        -0.03027249313890934,
        -0.03022584319114685,
        0.05496875196695328,
        -0.04770664498209953,
        -0.03842904791235924,
        0.05375564470887184,
        0.025953220203518867,
        -0.05358020216226578,
        0.045263662934303284,
        -0.04983993619680405,
        0.05427243188023567,
        0.04742411524057388,
        0.04865657538175583,
        -0.033914536237716675,
        -0.050557009875774384,
        0.04679135978221893,
        0.028898363932967186,
        0.04299307242035866,
        0.025514326989650726,
        0.05465921014547348,
        -0.0022799258586019278,
        0.04951094835996628,
        0.016001790761947632,
        0.042393192648887634,
        0.0540841668844223,
        0.0390862375497818,
        -0.035826656967401505,
        0.009496692568063736,
        -0.02004396729171276,
        0.04357451573014259,
        0.03929039090871811,
        0.035075489431619644,
        -0.05014760419726372,
        0.01725049875676632,
        -0.0548965260386467,
        0.054248642176389694,
        0.05351144075393677,
        0.05493157356977463,
        -0.05348311364650726,
        -0.02116536907851696,
        0.054995179176330566,
        0.05295545980334282,
        0.05499110743403435,
        -0.025868255645036697,
        0.05461274832487106,
        0.05112642049789429,
        -0.041023485362529755,
        -0.054965633898973465,
        -0.012464345432817936,
        -0.04751007258892059,
        0.054315295070409775,
        -0.047292813658714294,
        0.046141330152750015,
        -0.05451205372810364,
        -0.04052428901195526,
        -0.0037842639721930027,
        -0.04469345882534981,
        0.024611448869109154,
        -0.024885019287467003,
        0.05499862879514694,
        0.05478023737668991,
        0.05054733529686928,
        0.050902750343084335,
        0.05486892908811569,
        0.02347620762884617,
        0.04632873088121414,
        -0.01557209063321352,
        -0.049314480274915695,
        0.03440873697400093,
        -0.03845255449414253,
        0.0022403534967452288,
        -0.05201828107237816,
        0.021418264135718346,
        -0.0543782077729702,
        -0.05351316183805466,
        -0.054790668189525604,
        0.051176220178604126,
        -0.053041886538267136,
        0.05322527140378952,
        0.05234585702419281,
        0.04951152950525284,
        -0.010001187212765217,
        0.05345556512475014,
        0.05247757211327553,
        0.04649007320404053,
        -0.04877525195479393,
        0.03146140277385712,
        0.05492134764790535,
        -0.054758746176958084,
        0.05495890602469444,
        0.05360257625579834,
        0.05039159581065178,
        0.03997073322534561,
        0.01575603522360325,
        0.03902427852153778,
        -0.05438607558608055,
        0.054498687386512756,
        -0.05409801006317139,
        -0.05194953829050064,
        0.006332253571599722,
        -0.05388191342353821,
        -0.026814473792910576,
        -0.049884993582963943,
        0.024747880175709724,
        -0.05467696487903595,
        0.025988243520259857,
        -0.002834650222212076,
        0.05435159429907799,
        0.051744285970926285,
        -0.0019546691328287125,
        0.048380859196186066,
        0.054588668048381805,
        0.04920754209160805,
        0.053900573402643204,
        0.030294863507151604,
        -0.013693427667021751,
        -0.046298541128635406,
        -0.01845477521419525,
        -0.05400998145341873,
        -0.0542953684926033,
        0.0549781359732151,
        0.017001859843730927,
        -0.046516165137290955,
        0.054600346833467484,
        0.04298309236764908,
        0.038132913410663605,
        -0.014322253875434399,
        0.054848041385412216,
        0.03817148134112358,
        0.0533657930791378,
        -0.04278859496116638,
        0.053904369473457336,
        0.0381583608686924,
        -0.054948315024375916,
        0.019573770463466644,
        -0.05085597187280655,
        0.05045009031891823,
        0.05261590704321861,
        -0.025862975046038628,
        -0.04780047759413719,
        -0.00932815857231617,
        -0.0500013493001461,
        0.05437156930565834,
        0.002012364100664854,
        0.04803797975182533,
        0.030362550169229507,
        0.005796839017421007,
        -0.054974235594272614,
        0.05489880219101906,
        -0.039679139852523804,
        0.0298162791877985,
        -0.05489716678857803,
        -0.033168062567710876,
        -0.05451655015349388,
        0.054886750876903534,
        -0.00318898749537766,
        -0.05154229328036308,
        -0.05145728960633278,
        0.051153961569070816,
        0.022081725299358368,
        -0.05284540355205536,
        0.05471571534872055,
        0.0019041623454540968,
        -0.05089070275425911,
        -0.015552330762147903,
        0.007916545495390892,
        0.02307247743010521,
        -0.05362028628587723,
        0.05490254610776901,
        0.0499132014811039,
        0.042375598102808,
        0.053509507328271866,
        0.023771563544869423,
        0.05489102378487587,
        0.054547328501939774,
        -0.03083733841776848,
        -0.03957489877939224,
        0.03355242684483528,
        -0.05286784470081329,
        0.05497388914227486,
        -0.05499197170138359,
        0.02029845304787159,
        -0.032095931470394135,
        -0.053868234157562256,
        0.053406961262226105,
        -0.016694970428943634,
        -0.04904501512646675,
        -0.04741866514086723,
        -0.043000876903533936,
        -0.04700915142893791,
        0.053732484579086304,
        0.026562955230474472,
        -0.03998540714383125,
        -0.05489284545183182,
        -0.04872531443834305,
        -0.05217013508081436,
        0.054157327860593796,
        -0.05039479210972786,
        0.045094821602106094,
        0.0397917777299881,
        0.017018716782331467,
        -0.00019033892021980137,
        -0.026835063472390175,
        -0.038271643221378326,
        -0.03300722315907478,
        0.045235488563776016,
        -0.05370527505874634,
        0.052295420318841934,
        0.05332981050014496,
        -0.028151405975222588,
        -0.0070230718702077866,
        -0.03073066659271717,
        -0.05072753131389618,
        -0.02620062045753002,
        -0.05262046307325363,
        -0.053221385926008224,
        -0.05195936560630798,
        -0.04707585275173187,
        0.049744900315999985,
        0.05352720990777016,
        0.05492030829191208,
        -0.05241833254694939,
        -0.05499773845076561,
        -0.05481802299618721,
        0.03857145458459854,
        0.054859891533851624,
        -0.04683472961187363,
        0.05269620567560196,
        -0.0546719953417778,
        0.022285770624876022,
        0.05363323166966438,
        -0.050351332873106,
        0.05480058118700981,
        -0.017781028524041176,
        -0.042623165994882584,
        -0.04904964938759804,
        0.006621410138905048,
        0.018968995660543442,
        0.04974546656012535,
        -0.054552916437387466,
        -0.05377470701932907,
        0.041766904294490814,
        0.05498979240655899,
        0.040044769644737244,
        0.04706697165966034,
        -0.054370779544115067,
        0.038644880056381226,
        -0.05401453748345375,
        0.004917847458273172,
        0.04982099309563637,
        -0.04070861637592316,
        0.019473470747470856,
        -0.018850058317184448,
        -0.05495256185531616,
        -0.0007048338302411139,
        -0.0181273240596056,
        -0.00168522703461349,
        0.05469915643334389,
        -0.053673744201660156,
        -0.040715575218200684,
        -0.04537900537252426,
        0.05482061579823494,
        -0.042407505214214325,
        -0.05497131869196892,
        -0.03648950904607773,
        0.054466962814331055,
        0.044780902564525604,
        0.05495351180434227,
        0.028867650777101517,
        0.022866256535053253,
        -0.028846921399235725,
        -0.05499322712421417,
        0.034118153154850006,
        0.053928300738334656,
        -0.045093853026628494,
        0.027920136228203773,
        0.036144111305475235,
        0.0017291438998654485,
        -0.004797312431037426,
        -0.04689614847302437,
        -0.05316036567091942,
        -0.054713174700737,
        -0.03965381532907486,
        0.054876577109098434,
        0.046073704957962036,
        -0.05059415474534035,
        -0.042205847799777985,
        -0.05354112386703491,
        0.053803667426109314,
        0.053998228162527084,
        -0.05487189069390297,
        0.05365275964140892,
        -0.009667200036346912,
        -0.05483706295490265,
        0.05445067957043648,
        -0.03126571699976921,
        -0.044402725994586945,
        0.05494989454746246,
        0.05035779997706413,
        -0.02513391338288784,
        0.016547877341508865,
        0.04673904925584793,
        -0.054853666573762894,
        -0.04901675134897232,
        -0.05416743457317352,
        0.05330594256520271,
        -0.0027488283812999725,
        0.007972924038767815,
        0.0022688768804073334,
        0.05340367555618286,
        0.05461786314845085,
        0.04717623442411423,
        -0.05485369265079498,
        0.03370207920670509,
        0.0409192219376564,
        -0.043440498411655426,
        0.05390984192490578,
        -0.022021131590008736,
        0.024427974596619606,
        -0.031604185700416565,
        -0.050433021038770676,
        -0.05481932684779167,
        0.0548536479473114,
        0.048306480050086975,
        -0.05493839085102081,
        -0.053798332810401917,
        -0.04209858179092407,
        -0.034028060734272,
        0.05373802036046982,
        0.05417244881391525,
        -0.03026398830115795,
        0.033481668680906296,
        0.052773866802453995,
        -0.054876554757356644,
        0.0484374463558197,
        0.054524242877960205
    ],
    [
        0.047504208981990814,
        0.020065652206540108,
        -0.03923976793885231,
        0.04983213543891907,
        0.04662911966443062,
        -0.05235232412815094,
        0.04308643192052841,
        -0.052299823611974716,
        0.03268522396683693,
        0.0458398200571537,
        0.05237281695008278,
        0.04284559562802315,
        -0.051625385880470276,
        -0.05237317457795143,
        -0.05121171474456787,
        0.05204804614186287,
        0.03794241324067116,
        0.051696643233299255,
        0.045684605836868286,
        -0.04924899712204933,
        0.051502712070941925,
        0.052375417202711105,
        0.05218004435300827,
        0.046915359795093536,
        -0.05236785486340523,
        0.0514073371887207,
        0.0522640161216259,
        -0.020299017429351807,
        -0.05164565145969391,
        0.049605999141931534,
        -0.05232761800289154,
        -0.013265742920339108,
        0.04752432182431221,
        -0.052377328276634216,
        0.05206479877233505,
        -0.05205979943275452,
        -0.051545463502407074,
        -0.04072493314743042,
        -0.051967866718769073,
        -0.03556649014353752,
        0.033416569232940674,
        0.040309030562639236,
        0.039072807878255844,
        0.05217612162232399,
        0.052124906331300735,
        -0.05221126228570938,
        0.0013929588021710515,
        0.0523764044046402,
        0.05231359601020813,
        -0.05235050991177559,
        0.0518273264169693,
        -0.007284391205757856,
        0.031125295907258987,
        -0.05222034454345703,
        -0.04966333508491516,
        0.03499426692724228,
        -0.05164448916912079,
        -0.02376139722764492,
        -0.0523768812417984,
        -0.04997801035642624,
        -0.052211981266736984,
        0.036439888179302216,
        -0.051028359681367874,
        -0.03840772435069084,
        0.052339937537908554,
        0.05237603560090065,
        -0.05098619684576988,
        -0.05216851457953453,
        -0.03510166332125664,
        0.00567855779081583,
        -0.0328945629298687,
        0.0030050394125282764,
        0.029425274580717087,
        0.05213920772075653,
        0.05222519859671593,
        0.0002306127134943381,
        -0.0033801295794546604,
        0.05237363278865814,
        0.050754740834236145,
        -0.021074945107102394,
        -0.046467263251543045,
        -0.05236231908202171,
        -0.05236436799168587,
        0.05190066248178482,
        -0.03861790895462036,
        0.047140635550022125,
        -0.03388521820306778,
        0.015571048483252525,
        -0.016941027715802193,
        0.03442629426717758,
        -0.05237410590052605,
        -0.04311862215399742,
        -0.045625071972608566,
        -0.041829053312540054,
        -0.042863670736551285,
        0.05101822316646576,
        0.008476958610117435,
        -0.05145713686943054,
        -0.04176182299852371,
        0.0045687416568398476,
        -0.05221542716026306,
        0.04963625222444534,
        -0.032156798988580704,
        -0.031816016882658005,
        -0.0522993840277195,
        -0.051650047302246094,
        0.0519576333463192,
        -0.016290681436657906,
        -0.051618922501802444,
        0.05234449356794357,
        -0.051548101007938385,
        -0.0394950769841671,
        -0.0520281121134758,
        0.04679635539650917,
        0.05005690082907677,
        0.041133634746074677,
        -0.04809924587607384,
        -0.05230657756328583,
        -0.046803344041109085,
        -0.05226996913552284,
        0.05219379812479019,
        0.03937492519617081,
        0.040110405534505844,
        -0.0523308701813221,
        0.03534125164151192,
        0.05138102546334267,
        -0.05097721517086029,
        0.006516721099615097,
        0.051340363919734955,
        0.04636864364147186,
        0.04945230484008789,
        -0.05054943636059761,
        -0.03876669332385063,
        0.05203018710017204,
        -0.008455997332930565,
        -0.052312325686216354,
        0.052324727177619934,
        0.050464145839214325,
        0.0505649633705616,
        0.05226534977555275,
        0.0125034274533391,
        -0.046655237674713135,
        0.04672613367438316,
        -0.04717094451189041,
        -0.048512302339076996,
        0.020156675949692726,
        0.05209067091345787,
        0.03965567424893379,
        0.05035949498414993,
        0.04784690961241722,
        -0.051907073706388474,
        -0.05234957113862038,
        0.05232454091310501,
        0.008595665916800499,
        0.034672681242227554,
        -0.05198287591338158,
        -0.052377477288246155,
        0.051746759563684464,
        0.040749143809080124,
        0.039006493985652924,
        0.04998006299138069,
        0.0514405257999897,
        0.051840949803590775,
        -0.05104722082614899,
        -0.052377209067344666,
        -0.04585355147719383,
        0.05200805142521858,
        0.005863123573362827,
        -0.05236070975661278,
        0.04482639580965042,
        0.048925336450338364,
        0.04946979880332947,
        -0.0379183329641819,
        0.04165630042552948,
        0.035531289875507355,
        0.0515325553715229,
        0.052224863320589066,
        -0.0048991963267326355,
        0.05115097016096115,
        -0.05235474556684494,
        -0.045122165232896805,
        0.05237653851509094,
        0.04589688405394554,
        0.0205235593020916,
        0.05102669075131416,
        0.04906398057937622,
        -0.03407149016857147,
        0.049951158463954926,
        -0.050493303686380386,
        0.0523662306368351,
        -0.0519992858171463,
        0.01935909129679203,
        -0.02937951870262623,
        0.01956833340227604,
        -0.042503781616687775,
        -0.04761197045445442,
        0.04820496216416359,
        0.042291309684515,
        0.03136545047163963,
        0.03175950050354004,
        0.0029219379648566246,
        0.052368517965078354,
        -0.02801629528403282,
        -0.05096331983804703,
        -0.005089995916932821,
        -0.002997324801981449,
        0.005945111624896526,
        -0.009376873262226582,
        -0.02889656089246273,
        0.052219562232494354,
        -0.03337138518691063,
        -0.03965793177485466,
        -0.0522090420126915,
        0.05226990208029747,
        0.05041784420609474,
        -0.05219908431172371,
        0.05232900008559227,
        -0.04929639399051666,
        0.051174212247133255,
        -0.040485724806785583,
        0.05191299319267273,
        0.05203850194811821,
        0.05159946531057358,
        -0.03517942875623703,
        0.020894251763820648,
        0.0523100271821022,
        0.02872059866786003,
        0.020066531375050545,
        -0.043758172541856766,
        -0.0015618045581504703,
        0.015183246694505215,
        0.00540541484951973,
        0.04649978131055832,
        0.05051438882946968,
        -0.030111834406852722,
        0.049633532762527466,
        0.047732170671224594,
        0.04201874881982803,
        -0.03349754214286804,
        0.052341219037771225,
        0.040043946355581284,
        0.02465103007853031,
        0.05152638256549835,
        -0.03130073472857475,
        0.03294919803738594,
        0.04853072762489319,
        -0.019033338874578476,
        0.0499984472990036,
        -0.051947128027677536,
        0.05236348137259483,
        -0.052075956016778946,
        -0.05041208490729332,
        -0.05225016176700592,
        -0.049964968115091324,
        0.006254940293729305,
        0.051245443522930145,
        -0.05082354322075844,
        0.027192838490009308,
        0.017364494502544403,
        -0.049571745097637177,
        0.052256401628255844,
        0.045454829931259155,
        0.035967305302619934,
        0.04260065034031868,
        -0.05211298167705536,
        0.047947339713573456,
        0.052354246377944946,
        0.05187767744064331,
        -0.023072008043527603,
        -0.041185058653354645,
        -0.03161182627081871,
        -0.0473434254527092,
        -0.052035607397556305,
        -0.05196814984083176,
        -0.040235668420791626,
        0.04397793486714363,
        -0.04046135023236275,
        -0.02347087301313877,
        -0.047350358217954636,
        -0.05235257372260094,
        -0.05182928219437599,
        -0.01315388549119234,
        0.05237576737999916,
        0.04657958075404167,
        0.04932189732789993,
        0.026412632316350937,
        0.0496746264398098,
        0.04558750241994858,
        -0.05237746238708496,
        0.02460385486483574,
        -0.04852338507771492,
        -0.0518425814807415,
        -0.04371809586882591,
        -0.02452109009027481,
        -0.0374847948551178,
        -0.04881054535508156,
        0.05206670984625816,
        0.02840743586421013,
        -0.05188734456896782,
        0.04706985503435135,
        -0.051405154168605804,
        -0.012813876383006573,
        0.023071248084306717,
        0.05122705176472664,
        0.01157055702060461,
        -0.05190597102046013,
        -0.023074518889188766,
        -0.0405828058719635,
        0.023552831262350082,
        0.052195750176906586,
        -0.031973205506801605,
        0.033120047301054,
        -0.05209200456738472,
        -0.052288029342889786,
        -0.05230974033474922,
        -0.038736741989851,
        -0.03227631747722626,
        -0.05197543650865555,
        0.05086934193968773,
        -0.017982792109251022,
        0.05205471068620682,
        -0.02758619748055935,
        -0.05143975093960762,
        -0.052370887249708176,
        0.05138875171542168,
        -0.052069153636693954,
        0.052236396819353104,
        -0.0346982479095459,
        -0.051170773804187775,
        0.010183470323681831,
        0.05237473547458649,
        -0.04558869078755379,
        -0.05237548053264618,
        -0.00867723859846592,
        0.0488651879131794,
        -0.016034523025155067,
        -0.0339832529425621,
        0.024498486891388893,
        -0.05161270871758461,
        0.04085228964686394,
        -0.022665375843644142,
        -0.04889381676912308,
        0.04461201652884483,
        -0.051468878984451294,
        0.020061634480953217,
        0.05139411240816116,
        0.04894619435071945,
        0.05084981769323349,
        0.019564852118492126,
        0.03909469395875931,
        0.04912109673023224,
        -0.010073648765683174,
        0.050836335867643356,
        -0.04677199199795723,
        0.05081913620233536,
        -0.03981699422001839,
        -0.0424528606235981,
        -0.05142112448811531,
        -0.05112658441066742,
        0.040719352662563324,
        0.021988514810800552,
        0.05058370903134346,
        0.05232767388224602,
        -0.052287716418504715,
        -0.05233115702867508,
        -0.04875808209180832,
        0.05025920271873474,
        0.049711860716342926,
        -0.05131969228386879,
        -0.05040466785430908,
        -0.03810722380876541,
        0.04444577172398567,
        0.022427182644605637,
        -0.051323387771844864,
        0.051197439432144165,
        -0.04009910300374031,
        -0.04584920033812523,
        0.05212577059864998,
        -0.049038585275411606,
        -0.05171971023082733,
        -0.039954472333192825,
        0.052377503365278244,
        -0.03975176811218262,
        0.04068297892808914,
        0.0507776252925396,
        -0.040698323398828506,
        0.05233129486441612,
        -0.05154333636164665,
        -0.046564046293497086,
        0.05219588428735733,
        -0.05236348509788513,
        0.004304858390241861,
        -0.05222070962190628,
        -0.05159015208482742,
        -0.04059787094593048,
        0.05200868472456932,
        0.05185006931424141,
        -0.05203966796398163,
        -0.05188854783773422,
        -0.0475027859210968,
        -0.04504493996500969,
        0.052361320704221725,
        0.043822284787893295,
        -0.04887273907661438,
        -0.052316468209028244,
        -0.01679007150232792,
        0.03022068738937378,
        -0.04842647165060043,
        0.052030012011528015,
        -0.04422188177704811,
        -0.048213571310043335,
        0.04810400679707527,
        0.023118406534194946,
        -0.051442451775074005,
        0.05166273191571236,
        0.02525443583726883,
        -0.027392836287617683,
        -0.05237749591469765,
        0.04716324806213379,
        -0.05237744003534317,
        -0.006683092564344406,
        -0.052340056747198105,
        0.049587834626436234,
        -0.052375517785549164,
        0.05158352479338646,
        -0.04499916732311249,
        -0.043417297303676605,
        0.05232249200344086,
        0.040464043617248535,
        -0.0522826686501503,
        -0.027634523808956146,
        0.05170820280909538,
        0.05228682607412338,
        0.052321601659059525,
        -0.029525436460971832,
        -0.050857942551374435,
        -0.03422590345144272,
        0.052210260182619095,
        -0.05237594246864319,
        -0.040430959314107895,
        -0.052297722548246384,
        0.049963705241680145,
        0.05236643925309181,
        0.033079594373703,
        0.05150972306728363,
        0.052360791712999344,
        0.0521467849612236,
        0.008418857119977474,
        -0.027287080883979797,
        0.05187270790338516,
        -0.03665798529982567,
        -0.0006689262809231877,
        0.052313946187496185,
        -0.033190060406923294,
        -0.052279483526945114,
        -0.04880959168076515,
        0.020885173231363297,
        -0.03449418768286705,
        0.0432039238512516,
        0.051561616361141205,
        0.05237364396452904,
        -0.048271287232637405,
        0.04719174653291702,
        0.04044167697429657,
        0.052260808646678925,
        0.05219177156686783,
        -0.05186003819108009,
        0.05237288773059845,
        0.041082367300987244,
        -0.05026273801922798,
        -0.005177931394428015,
        -0.05237104371190071,
        0.052112992852926254,
        0.04810047894716263,
        -0.03564900904893875,
        0.02158234268426895,
        -0.05196959897875786,
        0.04986932501196861,
        -0.04130987077951431,
        0.047312814742326736,
        0.05216421186923981,
        -0.021970968693494797,
        -0.024347972124814987,
        0.04321672022342682,
        -0.04688356816768646,
        0.03907141461968422,
        0.05234615504741669,
        0.050036199390888214,
        -0.0474742092192173,
        -0.049076635390520096,
        0.05232393369078636,
        -0.0521073155105114,
        0.04900983348488808,
        -0.03568416088819504,
        -0.010816334746778011,
        -0.035756442695856094,
        -0.05211205407977104,
        -0.05030624195933342,
        0.022892531007528305,
        -0.047965072095394135,
        -0.052365027368068695,
        -0.051796797662973404,
        -0.05237288400530815,
        0.05230920389294624,
        0.01448487862944603,
        0.04753761738538742,
        0.05151275545358658,
        -0.00858312752097845,
        0.02897767163813114,
        -0.03229646757245064,
        -0.05232792720198631,
        -0.04604895040392876
    ],
    [
        -0.0578882209956646,
        0.010579978115856647,
        0.042621396481990814,
        0.01623634248971939,
        0.052057623863220215,
        -0.023252511397004128,
        0.06247345730662346,
        0.017271120101213455,
        0.03590160608291626,
        0.009643416851758957,
        0.0534001849591732,
        0.03531739488244057,
        -0.05150168761610985,
        -0.04948471114039421,
        -0.061598025262355804,
        0.049196045845746994,
        -0.05530878156423569,
        0.055358439683914185,
        -0.06120917201042175,
        -0.06200256198644638,
        -0.05980353057384491,
        0.05807739868760109,
        0.05940696969628334,
        0.04201984405517578,
        -0.030786477029323578,
        0.058884147554636,
        0.0064454092644155025,
        0.054550692439079285,
        -0.052532393485307693,
        -0.0005937109817750752,
        -0.030975574627518654,
        0.051504913717508316,
        0.039771318435668945,
        -0.06141262128949165,
        0.03793155401945114,
        -0.03475026413798332,
        -0.05257211998105049,
        -0.04791676253080368,
        -0.054026756435632706,
        0.0025081601925194263,
        0.02899198606610298,
        0.03859874606132507,
        0.04469725489616394,
        0.029146621003746986,
        0.05314239487051964,
        -0.05738731846213341,
        -0.05612948536872864,
        0.052205368876457214,
        0.053288184106349945,
        -0.014255215413868427,
        0.05532549321651459,
        -0.05240325629711151,
        0.05863261595368385,
        -0.016026228666305542,
        -0.05321052297949791,
        0.056342873722314835,
        -0.05741678178310394,
        -0.047555629163980484,
        -0.022664014250040054,
        -0.03884860500693321,
        -0.05236810818314552,
        0.012067487463355064,
        -0.0496792197227478,
        -0.05610065907239914,
        0.019877946004271507,
        0.020759446546435356,
        -0.03781307116150856,
        -0.059745389968156815,
        -0.01663546822965145,
        -0.05971723422408104,
        -0.06239156052470207,
        -0.05877355486154556,
        0.02365768887102604,
        0.034353625029325485,
        -0.039518799632787704,
        -0.033401843160390854,
        -0.00696778716519475,
        0.06207162141799927,
        0.03287782147526741,
        -0.03213229030370712,
        -0.04276256635785103,
        -0.007477389648556709,
        -0.03190182149410248,
        0.04722055792808533,
        -0.046035245060920715,
        0.04334573075175285,
        0.012404626235365868,
        0.05560475215315819,
        0.03433309495449066,
        0.04881134256720543,
        -0.017984533682465553,
        -0.0273622814565897,
        -0.0371459536254406,
        -0.011839287355542183,
        0.03948141634464264,
        -0.02616722881793976,
        -0.04116431623697281,
        -0.05148967355489731,
        0.012804497964680195,
        0.0005198832950554788,
        -0.06046948581933975,
        -0.05930845066905022,
        0.05276230722665787,
        0.02432469092309475,
        -0.047854792326688766,
        -0.05119431018829346,
        0.005491937510669231,
        -0.023050988093018532,
        -0.041571639478206635,
        -0.055984821170568466,
        0.005172009579837322,
        -0.06144007667899132,
        -0.047342296689748764,
        -0.01186857558786869,
        0.021326564252376556,
        -0.05919964984059334,
        -0.0351867601275444,
        -0.059708356857299805,
        -0.061752885580062866,
        -0.051462914794683456,
        0.015428471378982067,
        -0.033192671835422516,
        0.04090278595685959,
        0.03204967826604843,
        -0.06053346395492554,
        -0.007928571663796902,
        -0.04739971086382866,
        0.04294499009847641,
        0.06092333421111107,
        0.006205196958035231,
        0.061313215643167496,
        -0.05379949137568474,
        -0.038971371948719025,
        0.030179623514413834,
        -0.0383644737303257,
        -0.05683683976531029,
        0.025655189529061317,
        -0.057666193693876266,
        0.059011317789554596,
        0.06200022995471954,
        0.057518698275089264,
        -0.015138192102313042,
        0.05341467633843422,
        0.04689333587884903,
        0.004191010724753141,
        -0.05486045405268669,
        0.058371201157569885,
        -0.007603599689900875,
        -0.00021799837122671306,
        -0.02523951232433319,
        -0.05409884452819824,
        -0.0609840452671051,
        -0.04721447452902794,
        -0.014621791429817677,
        0.0259171724319458,
        0.006184359081089497,
        -0.012515992857515812,
        0.009971385821700096,
        -0.0022775058168917894,
        -0.009738633409142494,
        0.06268449127674103,
        0.007223117630928755,
        0.04769514128565788,
        -0.054342593997716904,
        -0.059852488338947296,
        -0.05445445328950882,
        0.04401972517371178,
        0.043130867183208466,
        -0.05762084573507309,
        0.060270242393016815,
        0.016943925991654396,
        0.03142142295837402,
        -0.06205536052584648,
        0.05645141005516052,
        -0.05946880578994751,
        -0.02378375083208084,
        -0.03776225075125694,
        0.030378635972738266,
        0.03897484764456749,
        -0.010973973199725151,
        0.05494328960776329,
        0.06266938894987106,
        0.04664577916264534,
        -0.018954075872898102,
        0.0393819697201252,
        0.027230290696024895,
        -0.05226059630513191,
        0.05679307505488396,
        -0.04968302324414253,
        -0.025841113179922104,
        -0.04639890044927597,
        -0.020866606384515762,
        -0.05939879268407822,
        -0.02364202030003071,
        -0.050681617110967636,
        -0.038004398345947266,
        -0.01957070082426071,
        -0.059967122972011566,
        0.040335316210985184,
        0.0006299877422861755,
        -0.038300707936286926,
        0.05040932446718216,
        0.023804493248462677,
        -0.02143024653196335,
        0.024589402601122856,
        0.018341146409511566,
        0.050152961164712906,
        -0.021062102168798447,
        -0.009753065183758736,
        0.04120795801281929,
        -0.05920036509633064,
        0.05935189872980118,
        -0.03202901780605316,
        0.03758881241083145,
        0.02907668426632881,
        -0.058153167366981506,
        -0.010945764370262623,
        0.0022981581278145313,
        0.052485909312963486,
        -0.06198501214385033,
        -0.03678714856505394,
        -0.009408270008862019,
        -0.02230873517692089,
        -0.060046713799238205,
        0.05885486677289009,
        -0.04142697900533676,
        -0.04175504669547081,
        -0.03137928619980812,
        -0.05073133111000061,
        -0.05255261808633804,
        -0.023011457175016403,
        0.011548509821295738,
        -0.05855507403612137,
        0.058007728308439255,
        -0.00326358200982213,
        -0.03405262529850006,
        0.061016764491796494,
        -0.025189800187945366,
        0.02778872475028038,
        0.061677299439907074,
        0.05579175427556038,
        -0.019460324198007584,
        0.05441014841198921,
        -0.05852406844496727,
        0.05618741363286972,
        0.0446632020175457,
        0.0447804220020771,
        -0.016768891364336014,
        -0.05633367970585823,
        -0.029799573123455048,
        -0.03897639736533165,
        -0.05297628790140152,
        -0.05036993697285652,
        -0.06018797308206558,
        0.017764246091246605,
        0.042523160576820374,
        -0.05272805690765381,
        0.012113597244024277,
        0.062425993382930756,
        0.059863168746232986,
        0.06216810271143913,
        0.0579981803894043,
        -0.02593541331589222,
        0.04007737338542938,
        -0.059669721871614456,
        -0.022723259404301643,
        0.04222944378852844,
        -0.018214140087366104,
        0.045412153005599976,
        -0.055638208985328674,
        -0.057164452970027924,
        -0.05997641384601593,
        -0.01789070852100849,
        -0.05679105222225189,
        -0.024476706981658936,
        -0.008739979937672615,
        -0.06166159734129906,
        0.019899217411875725,
        -0.039855267852544785,
        -0.06250286847352982,
        -0.06113816425204277,
        -0.010139343328773975,
        0.0469772145152092,
        -0.0068070292472839355,
        0.013051257468760014,
        -0.04242928698658943,
        0.043614357709884644,
        -0.03702715411782265,
        -0.06149963289499283,
        -0.0065131718292832375,
        -0.033172864466905594,
        0.05310504883527756,
        0.02284706011414528,
        -0.052781544625759125,
        0.010718230158090591,
        -0.05073096230626106,
        0.060199983417987823,
        0.04721338301897049,
        -0.060297608375549316,
        0.03732241690158844,
        -0.013382536359131336,
        0.04253903776407242,
        -0.03612846881151199,
        0.027611680328845978,
        -0.037586506456136703,
        -0.0414452850818634,
        -0.04069502651691437,
        -0.03287213668227196,
        0.048530954867601395,
        -0.017590774223208427,
        0.008293608203530312,
        -0.012454046867787838,
        -0.019192812964320183,
        0.03325547277927399,
        -0.06231384351849556,
        -0.03677087649703026,
        0.040327779948711395,
        -0.06065104529261589,
        0.02447638474404812,
        -0.0019605890847742558,
        0.019071316346526146,
        0.023637665435671806,
        0.04882796108722687,
        -0.05321115627884865,
        0.05503176152706146,
        0.05640588700771332,
        0.06240244209766388,
        -0.04283452033996582,
        -0.04822036623954773,
        -0.053419314324855804,
        0.034416962414979935,
        0.023199770599603653,
        -0.05638401582837105,
        0.052635859698057175,
        0.057015664875507355,
        0.007973955012857914,
        -0.016032608225941658,
        0.06105921044945717,
        -0.019286707043647766,
        0.0582277774810791,
        0.0371885821223259,
        0.03234116733074188,
        -0.023443108424544334,
        -0.02858455665409565,
        0.06269385665655136,
        0.024025019258260727,
        0.03592589870095253,
        0.05694955959916115,
        0.04165143519639969,
        0.05393623933196068,
        -0.06242593377828598,
        -0.005550468806177378,
        -0.05563962087035179,
        -0.05900590866804123,
        -0.03527352586388588,
        0.05377203971147537,
        -0.0601973794400692,
        -0.03625301644206047,
        -0.061035700142383575,
        -0.03365793824195862,
        0.051501400768756866,
        0.04247235879302025,
        -0.04386498034000397,
        -0.05273749679327011,
        -0.0531790666282177,
        -0.05815650522708893,
        0.06175684928894043,
        0.037680238485336304,
        0.04543031007051468,
        -0.06239938735961914,
        -0.046710677444934845,
        -0.05668817460536957,
        0.06019299849867821,
        -0.03611408546566963,
        0.061153098940849304,
        0.024699602276086807,
        -0.0011641794117167592,
        0.06243886798620224,
        -0.028129028156399727,
        -0.053485192358493805,
        0.02496800385415554,
        0.058197829872369766,
        -0.05260354280471802,
        -0.007987312972545624,
        0.045316655188798904,
        -0.02131379395723343,
        0.05691443011164665,
        -0.05535158887505531,
        -0.03971298784017563,
        -0.023722922429442406,
        -0.016884131357073784,
        0.039754293859004974,
        0.0338747464120388,
        -0.036710187792778015,
        -0.03760191798210144,
        0.060280993580818176,
        0.061970870941877365,
        -0.06061988323926926,
        -0.03178887441754341,
        -0.0584130585193634,
        -0.03467167168855667,
        0.056910689920186996,
        -0.0237130094319582,
        0.05296439677476883,
        -0.04414182901382446,
        0.021536244079470634,
        0.047680724412202835,
        -0.02920650877058506,
        0.028671618551015854,
        -0.05330180376768112,
        0.010028342716395855,
        0.004246470518410206,
        0.04761114716529846,
        -0.01438042614609003,
        0.060624800622463226,
        -0.00786573439836502,
        -0.010661734268069267,
        -0.056938398629426956,
        0.0245712511241436,
        0.043927427381277084,
        0.005324205383658409,
        -0.06235356628894806,
        -0.03911659121513367,
        -0.059665609151124954,
        -0.015534507110714912,
        -0.015202130191028118,
        -0.0574641078710556,
        0.057898037135601044,
        -0.05926384776830673,
        -0.05291161313652992,
        -0.020219890400767326,
        -0.004831632133573294,
        0.0545375719666481,
        0.06200289726257324,
        0.043898794800043106,
        -0.05186380073428154,
        0.020835237577557564,
        0.047286853194236755,
        0.04670169949531555,
        -0.05930589884519577,
        -0.017064616084098816,
        0.04671882465481758,
        0.06218860298395157,
        0.05789607763290405,
        -0.03021499514579773,
        0.05838694050908089,
        0.0599873811006546,
        -0.04079801216721535,
        0.057424891740083694,
        -0.0624396912753582,
        -0.06067189574241638,
        0.04568176716566086,
        0.05491255596280098,
        -0.06153528392314911,
        -0.05824290215969086,
        -0.04602997377514839,
        -0.02674211375415325,
        0.015862999483942986,
        -0.03230448067188263,
        0.049849484115839005,
        -0.016887536272406578,
        -0.04171011224389076,
        -0.045285094529390335,
        -0.05272655189037323,
        0.05725298449397087,
        0.062035996466875076,
        -0.057097457349300385,
        0.060928892344236374,
        -0.06108114868402481,
        -0.06151803210377693,
        0.045416176319122314,
        -0.029498079791665077,
        0.031993038952350616,
        0.059785518795251846,
        0.05853422358632088,
        0.05588946118950844,
        -0.04946159943938255,
        0.05843859165906906,
        -0.052323393523693085,
        0.05142809823155403,
        -0.03848133608698845,
        0.026471270248293877,
        0.019856227561831474,
        0.041251301765441895,
        0.05577286705374718,
        0.0341850146651268,
        0.01944846659898758,
        -0.00585642084479332,
        -0.058983270078897476,
        -0.042241420596838,
        -0.0057508619502186775,
        -0.06118861585855484,
        0.03834639862179756,
        0.051100246608257294,
        0.03158767148852348,
        -0.02074599452316761,
        -0.031038321554660797,
        -0.059107400476932526,
        0.049812138080596924,
        0.016639431938529015,
        -0.04971358925104141,
        -0.06047055125236511,
        -0.03311073035001755,
        0.061285603791475296,
        0.058932580053806305,
        -0.017128324136137962,
        0.05108398571610451,
        0.042976658791303635,
        0.002103261649608612,
        -0.05562476068735123,
        0.055655740201473236,
        0.05784158781170845
    ],
    [
        -0.047949809581041336,
        -0.03971555829048157,
        0.057160861790180206,
        0.026685303077101707,
        0.0471055842936039,
        -0.031086178496479988,
        0.05507449805736542,
        -0.052122339606285095,
        0.007005966734141111,
        0.05755891278386116,
        0.035829558968544006,
        0.034508589655160904,
        -0.055994946509599686,
        -0.03578326478600502,
        -0.050049036741256714,
        0.05827147513628006,
        -0.050513461232185364,
        0.056437671184539795,
        -0.05970606580376625,
        -0.012159008532762527,
        -0.030246902257204056,
        -0.047921549528837204,
        0.05329815298318863,
        -0.03839035704731941,
        -0.05646088719367981,
        0.024882569909095764,
        0.05710757523775101,
        0.05848275497555733,
        0.006645753048360348,
        -0.05724823474884033,
        0.03060467354953289,
        -0.04794373735785484,
        -0.00593390129506588,
        -0.05741877481341362,
        0.03368068113923073,
        -0.05840592458844185,
        -0.05178217589855194,
        -0.05169535055756569,
        -0.0597580224275589,
        0.0094492482021451,
        0.038472019135951996,
        0.0044707199558615685,
        0.04347316175699234,
        -0.010216278955340385,
        0.05109350383281708,
        -0.056487999856472015,
        0.0382242389023304,
        0.055028606206178665,
        0.054240111261606216,
        -0.04525459557771683,
        -0.016880780458450317,
        -0.01045240554958582,
        0.055300772190093994,
        -0.002303263870999217,
        -0.03879814222455025,
        -0.016468070447444916,
        -0.05877506360411644,
        0.021660272032022476,
        -0.026842353865504265,
        -0.059014853090047836,
        -0.03310583159327507,
        0.009721205569803715,
        -0.053097162395715714,
        0.05595637485384941,
        0.0457732118666172,
        0.05230918526649475,
        0.04966624826192856,
        0.02438126504421234,
        -0.04335054010152817,
        -0.0593315027654171,
        -0.05798053368926048,
        -0.055383820086717606,
        0.00023496120411437005,
        0.04132853448390961,
        0.008231004700064659,
        -0.025997262448072433,
        -0.02897290512919426,
        0.05967596918344498,
        0.054632317274808884,
        -0.05186920613050461,
        -0.031147072091698647,
        -0.03616631031036377,
        -0.03312923014163971,
        -0.01971421018242836,
        0.03879796713590622,
        0.000026307014195481315,
        -0.01998331770300865,
        -0.017439544200897217,
        0.058489251881837845,
        0.056419290602207184,
        -0.04203929007053375,
        0.055593062192201614,
        -0.05091390758752823,
        -0.04198400676250458,
        -0.01632896438241005,
        0.009513589553534985,
        -0.0272884052246809,
        -0.01722227968275547,
        -0.039411578327417374,
        -0.03863551840186119,
        -0.05923091992735863,
        -0.010815856046974659,
        0.056029271334409714,
        0.05427541956305504,
        0.01862012781202793,
        -0.05637747049331665,
        -0.05939433351159096,
        0.05721905827522278,
        -0.05789326876401901,
        0.03614545240998268,
        -0.05554904416203499,
        -0.022886313498020172,
        -0.05234145373106003,
        0.05719992518424988,
        -0.018533816561102867,
        0.009227032773196697,
        -0.030336029827594757,
        -0.011658946983516216,
        -0.05614800378680229,
        0.04120447486639023,
        0.05499010905623436,
        0.02757396548986435,
        0.057066887617111206,
        -0.0365491658449173,
        -0.05945048853754997,
        0.03337113931775093,
        -0.02633141726255417,
        0.041712842881679535,
        0.05543176457285881,
        0.023265503346920013,
        0.041970185935497284,
        -0.03924649953842163,
        -0.026516281068325043,
        0.05238320678472519,
        -0.03041600063443184,
        -0.008866476826369762,
        0.026935871690511703,
        -0.057458821684122086,
        -0.05759327858686447,
        0.05800797790288925,
        0.059026625007390976,
        0.011468878947198391,
        0.038312364369630814,
        0.03975381329655647,
        -0.04401835799217224,
        0.045900411903858185,
        0.029904386028647423,
        0.04315199330449104,
        -0.05030201002955437,
        0.039809223264455795,
        -0.039795536547899246,
        -0.05952715128660202,
        -0.00324262585490942,
        0.02712344564497471,
        0.04080546647310257,
        -0.05810800567269325,
        -0.050420310348272324,
        0.04670454561710358,
        0.018027644604444504,
        0.046860940754413605,
        0.05884450674057007,
        -0.024558929726481438,
        0.04842110723257065,
        -0.0587373748421669,
        -0.050557248294353485,
        -0.037599317729473114,
        0.02069106139242649,
        -0.039245638996362686,
        0.050311677157878876,
        0.039140697568655014,
        -0.01698494143784046,
        0.0358973927795887,
        -0.05948717147111893,
        -0.04711589589715004,
        0.05773669481277466,
        0.059597913175821304,
        -0.059584442526102066,
        -0.04139499366283417,
        0.033667463809251785,
        0.025177529081702232,
        0.04756123945116997,
        0.054887402802705765,
        0.05157356336712837,
        -0.04934627190232277,
        -0.0017650864319875836,
        -0.00034502882044762373,
        -0.011301103979349136,
        0.05636364594101906,
        -0.007430703844875097,
        -0.057700563222169876,
        -0.04506884142756462,
        -0.055836617946624756,
        -0.009384091943502426,
        0.006972858682274818,
        0.014551191590726376,
        -0.017431801185011864,
        -0.018244285136461258,
        0.03596045821905136,
        -0.046691443771123886,
        -0.020682064816355705,
        -0.04111389443278313,
        0.049756914377212524,
        -0.037259720265865326,
        0.05931495502591133,
        0.05842218175530434,
        -0.045622568577528,
        0.004625687841325998,
        0.01452809851616621,
        -0.056327786296606064,
        -0.028668398037552834,
        -0.004885279107838869,
        0.028819037601351738,
        0.027630938217043877,
        -0.0354633592069149,
        0.009797018021345139,
        0.039756592363119125,
        0.059617407619953156,
        0.04584266245365143,
        0.05973374843597412,
        -0.04576527327299118,
        0.04151354357600212,
        0.0444684699177742,
        -0.056219495832920074,
        -0.05891919136047363,
        0.022427527233958244,
        0.05533643811941147,
        0.052699554711580276,
        0.04408036544919014,
        -0.03968611732125282,
        -0.056641481816768646,
        -0.012886193580925465,
        -0.05360637232661247,
        -0.05165018513798714,
        0.04900777339935303,
        0.016309520229697227,
        0.05547163262963295,
        0.057373952120542526,
        -0.04793032631278038,
        0.03747507184743881,
        0.05940592661499977,
        0.048759207129478455,
        0.01798224076628685,
        0.03460771590471268,
        0.04327280446887016,
        0.04369622841477394,
        0.016465043649077415,
        -0.04041958972811699,
        0.03991531580686569,
        0.04810035228729248,
        0.03127171844244003,
        0.013795108534395695,
        -0.05928192287683487,
        -0.05861856788396835,
        -0.058251384645700455,
        -0.025981616228818893,
        0.058805275708436966,
        -0.052984319627285004,
        0.010584931820631027,
        0.05905328318476677,
        0.05941905826330185,
        0.03214442729949951,
        -0.001330738072283566,
        -0.019077222794294357,
        0.05863969027996063,
        -0.05925551801919937,
        0.050903499126434326,
        0.05266478657722473,
        -0.014755877666175365,
        0.038101132959127426,
        -0.0344240628182888,
        -0.0587826743721962,
        -0.05911931023001671,
        0.04980173707008362,
        -0.05321109667420387,
        -0.045372240245342255,
        -0.02231878787279129,
        -0.05231186002492905,
        0.044802404940128326,
        -0.012608367949724197,
        -0.021159488707780838,
        -0.05808960646390915,
        -0.05886409431695938,
        0.04816657677292824,
        0.03403964266180992,
        0.047516655176877975,
        -0.05905851721763611,
        0.044598087668418884,
        -0.050106436014175415,
        -0.03768559545278549,
        0.03814282640814781,
        0.03054829128086567,
        0.05004382133483887,
        -0.022497445344924927,
        -0.022500785067677498,
        -0.049178384244441986,
        -0.05719471350312233,
        0.05918997526168823,
        -0.049586787819862366,
        -0.041270866990089417,
        0.055411361157894135,
        0.01879865489900112,
        0.024241575971245766,
        0.0291057750582695,
        0.058922283351421356,
        -0.05595478415489197,
        -0.012829984538257122,
        -0.0238479170948267,
        0.010345048271119595,
        -0.02100428380072117,
        -0.057327646762132645,
        0.021477127447724342,
        -0.044333986937999725,
        0.015791431069374084,
        -0.0004376975412014872,
        -0.05975602939724922,
        -0.054363347589969635,
        0.05629114434123039,
        -0.05845361575484276,
        0.046730197966098785,
        -0.05608367547392845,
        -0.04134819284081459,
        -0.01665116287767887,
        0.0344928577542305,
        -0.05974745377898216,
        0.05584580451250076,
        -0.0076284995302557945,
        0.05834217742085457,
        -0.01704796403646469,
        0.05929132178425789,
        -0.05093712732195854,
        -0.031374748796224594,
        0.023135565221309662,
        -0.04040112718939781,
        -0.027281226590275764,
        0.04550909996032715,
        0.05418538674712181,
        -0.03711722418665886,
        0.05600562319159508,
        0.03498665988445282,
        0.02135808765888214,
        0.032010167837142944,
        -0.03905542939901352,
        -0.022113269194960594,
        0.032935649156570435,
        0.059758078306913376,
        0.032895226031541824,
        0.00551925553008914,
        0.04013233631849289,
        -0.005307209212332964,
        0.059135813266038895,
        -0.0596788115799427,
        -0.020142478868365288,
        0.006081376224756241,
        -0.04975041747093201,
        0.002032887190580368,
        0.054643332958221436,
        -0.059483110904693604,
        -0.0502716563642025,
        0.04310772567987442,
        -0.04561977833509445,
        0.056068819016218185,
        0.047331973910331726,
        -0.05263212323188782,
        -0.04830978065729141,
        -0.05356505513191223,
        -0.0511619970202446,
        0.05910063162446022,
        0.031314667314291,
        0.050056252628564835,
        -0.05751877650618553,
        -0.052618708461523056,
        -0.05038690194487572,
        0.03605717420578003,
        0.04940490797162056,
        0.043347302824258804,
        0.05297448858618736,
        -0.0591200590133667,
        0.036984603852033615,
        -0.04727227985858917,
        -0.05835045501589775,
        -0.05350453779101372,
        0.05103182792663574,
        -0.055017050355672836,
        0.05790187418460846,
        0.05836142599582672,
        -0.057318370789289474,
        0.05803921818733215,
        -0.0524054616689682,
        0.0016783110331743956,
        -0.050095729529857635,
        -0.05889041721820831,
        0.04655086621642113,
        0.016851533204317093,
        -0.042840976268053055,
        -0.05408337339758873,
        0.048143524676561356,
        0.0534595251083374,
        0.0462614931166172,
        -0.044954027980566025,
        -0.059601638466119766,
        -0.04338034614920616,
        0.05379720404744148,
        0.028589673340320587,
        0.046260684728622437,
        -0.04006204754114151,
        -0.026450134813785553,
        0.012259270995855331,
        0.0077122896909713745,
        0.051460687071084976,
        -0.03524873033165932,
        -0.019690509885549545,
        0.05849432945251465,
        -0.0425303615629673,
        -0.052238259464502335,
        0.05696772038936615,
        -0.051920726895332336,
        0.035518109798431396,
        -0.05708937719464302,
        0.04797209054231644,
        -0.05935359746217728,
        -0.03849838301539421,
        -0.04193485528230667,
        -0.056439563632011414,
        -0.054402925074100494,
        0.03055937960743904,
        -0.04210703447461128,
        -0.059722982347011566,
        -0.027647951617836952,
        -0.04411199688911438,
        -0.05524435266852379,
        0.04951111972332001,
        -0.04056030884385109,
        -0.029873473569750786,
        0.054091766476631165,
        -0.029283976182341576,
        -0.05341239273548126,
        -0.057997751981019974,
        0.048991166055202484,
        -0.03423098102211952,
        -0.047865867614746094,
        0.051315583288669586,
        0.05887221172451973,
        -0.017358452081680298,
        0.04875090345740318,
        0.05972593277692795,
        0.056180913001298904,
        0.03658256307244301,
        -0.0595574826002121,
        0.055048272013664246,
        -0.02041384018957615,
        -0.006026861723512411,
        0.056738026440143585,
        0.04884757101535797,
        0.04751504212617874,
        0.023085296154022217,
        -0.04729154333472252,
        0.03582637012004852,
        0.05256706103682518,
        -0.05485749617218971,
        0.05619337409734726,
        0.05028140917420387,
        -0.05657116696238518,
        -0.05758550763130188,
        -0.041611649096012115,
        0.05463491752743721,
        0.0587787926197052,
        -0.059156276285648346,
        0.05904258042573929,
        -0.052647460252046585,
        -0.05875145643949509,
        0.033159054815769196,
        0.02916611172258854,
        0.05104557052254677,
        0.019480545073747635,
        0.057951919734478,
        0.01906421408057213,
        -0.0438806489109993,
        0.05861067399382591,
        -0.051358092576265335,
        -0.01443590596318245,
        0.05520714074373245,
        -0.012547335587441921,
        0.0542055144906044,
        -0.0009028870263136923,
        -0.014414498582482338,
        0.05347319692373276,
        0.03917411342263222,
        -0.04300056770443916,
        -0.052631329745054245,
        0.05613227188587189,
        -0.05655813589692116,
        -0.05974793806672096,
        0.02825882099568844,
        -0.01315051969140768,
        0.029835131019353867,
        0.03144747018814087,
        -0.05399175360798836,
        0.02416077069938183,
        0.046475209295749664,
        0.042851679027080536,
        -0.035731762647628784,
        -0.04746898263692856,
        -0.03055020049214363,
        -0.05031270161271095,
        0.05558245629072189,
        0.026635531336069107,
        -0.057711828500032425,
        -0.04377166926860809,
        -0.03020615316927433,
        -0.05924394726753235,
        0.019368385896086693,
        0.05487075820565224
    ],
    [
        0.0351453498005867,
        -0.052960943430662155,
        -0.052557073533535004,
        0.05405091494321823,
        0.018727866932749748,
        -0.04106313735246658,
        0.04901173710823059,
        0.04757271707057953,
        0.05423266440629959,
        -0.05272810906171799,
        0.054728489369153976,
        0.05502522364258766,
        -0.054993342608213425,
        0.05351056158542633,
        -0.04920291155576706,
        -0.04696648567914963,
        -0.037456315010786057,
        0.008852445520460606,
        0.016711179167032242,
        0.05465877428650856,
        -0.03633657470345497,
        -0.03345559164881706,
        0.044160403311252594,
        0.01669585518538952,
        0.05190560966730118,
        0.04481158033013344,
        0.03788217529654503,
        0.05282197520136833,
        -0.05354224890470505,
        -0.05383101850748062,
        0.02179773338139057,
        0.05132772773504257,
        0.055003613233566284,
        -0.05482545122504234,
        0.0543794222176075,
        -0.05121246725320816,
        -0.05400638282299042,
        0.04661600664258003,
        0.050875794142484665,
        -0.029991289600729942,
        -0.033895306289196014,
        0.054134007543325424,
        0.044665027409791946,
        -0.055047664791345596,
        -0.03551739826798439,
        -0.03786907717585564,
        0.05067605525255203,
        0.043919891119003296,
        0.05481855943799019,
        -0.04897315800189972,
        0.05446169525384903,
        0.05396849662065506,
        -0.04575775936245918,
        -0.027917655184864998,
        0.012631302699446678,
        0.046634819358587265,
        -0.034745436161756516,
        -0.00033951419754885137,
        0.050670724362134933,
        -0.055022746324539185,
        -0.053190961480140686,
        0.05108208954334259,
        -0.05256960913538933,
        -0.03364748880267143,
        0.0065472302958369255,
        0.05461254343390465,
        -0.0549805723130703,
        -0.010441926307976246,
        0.049401093274354935,
        -0.004876405466347933,
        -0.04183623939752579,
        0.039840612560510635,
        0.05332810804247856,
        0.05504048615694046,
        0.048876337707042694,
        0.04428950697183609,
        0.044843319803476334,
        0.05504724383354187,
        0.05383245646953583,
        -0.03866031393408775,
        -0.04329034686088562,
        -0.054837584495544434,
        -0.05471685156226158,
        0.05409058928489685,
        0.05035633593797684,
        0.03181363269686699,
        0.03128199279308319,
        0.04673653095960617,
        0.05183599516749382,
        0.01944894902408123,
        -0.03365660086274147,
        0.010553356260061264,
        0.018525870516896248,
        -0.03727269172668457,
        -0.04830922931432724,
        -0.044052500277757645,
        -0.043690890073776245,
        -0.041549742221832275,
        0.016437571495771408,
        -0.009916551411151886,
        -0.05060499161481857,
        0.05482534319162369,
        0.027085354551672935,
        0.05367565527558327,
        0.05386815592646599,
        -0.055009450763463974,
        -0.05494387447834015,
        0.04133060947060585,
        -0.046350616961717606,
        0.04318632185459137,
        -0.04317443445324898,
        -0.054715968668460846,
        -0.054316408932209015,
        0.027902254834771156,
        0.03918080776929855,
        0.03242889791727066,
        0.020291801542043686,
        -0.013659151270985603,
        -0.014628265053033829,
        -0.05399144068360329,
        -0.009862461127340794,
        -0.05413651466369629,
        -0.05501709133386612,
        -0.05502965673804283,
        -0.053516559302806854,
        0.04509541019797325,
        0.03773615136742592,
        0.0026061488315463066,
        0.04571325704455376,
        0.050316233187913895,
        0.048406701534986496,
        -0.05139625072479248,
        -0.054738644510507584,
        0.05118391290307045,
        -0.03741089254617691,
        -0.054109085351228714,
        0.022309059277176857,
        0.018354199826717377,
        -0.053936172276735306,
        0.05504561960697174,
        0.01177491806447506,
        0.051217805594205856,
        0.054505087435245514,
        0.05503585562109947,
        -0.05027064308524132,
        0.03454809635877609,
        0.04994357004761696,
        -0.053297337144613266,
        0.03532542288303375,
        0.0031438295263797045,
        -0.05504317209124565,
        -0.05503629893064499,
        0.05489412695169449,
        0.05404973030090332,
        -0.030871234834194183,
        -0.05472106486558914,
        -0.05014685168862343,
        -0.021953051909804344,
        0.055041346698999405,
        0.05098007246851921,
        -0.017397567629814148,
        0.05381225794553757,
        0.05432562530040741,
        -0.0030227021779865026,
        -0.05323784053325653,
        0.04250996187329292,
        0.04543573409318924,
        -0.01732454262673855,
        0.054942309856414795,
        0.027702508494257927,
        0.032796911895275116,
        0.05448424816131592,
        -0.054926853626966476,
        0.05493739992380142,
        -0.0548853762447834,
        0.023580478504300117,
        0.04841567203402519,
        0.008410516194999218,
        0.05317522585391998,
        -0.04335402324795723,
        -0.05412061884999275,
        0.052629657089710236,
        -0.009964445605874062,
        -0.046744655817747116,
        -0.052359677851200104,
        -0.00004277114931028336,
        0.05342187359929085,
        0.05496041849255562,
        0.05503283441066742,
        -0.05423043295741081,
        -0.05481606349349022,
        -0.012737961485981941,
        0.05437903478741646,
        -0.048765212297439575,
        -0.03757695108652115,
        -0.02114057168364525,
        0.05396927520632744,
        -0.05504750832915306,
        -0.0503554604947567,
        0.04707546904683113,
        0.054521724581718445,
        -0.054950591176748276,
        -0.009574183262884617,
        -0.05504664406180382,
        0.04506251588463783,
        0.018400561064481735,
        0.04770388826727867,
        0.019189639016985893,
        -0.049936432391405106,
        0.05429280176758766,
        -0.05490744858980179,
        0.030132265761494637,
        0.05504751205444336,
        0.05468714237213135,
        -0.05370260775089264,
        -0.05437367036938667,
        0.05009223893284798,
        0.0032628511544317007,
        0.043932002037763596,
        -0.022648168727755547,
        0.005929520353674889,
        0.03753121569752693,
        -0.043075546622276306,
        -0.055014654994010925,
        0.0398176871240139,
        -0.054674483835697174,
        0.038708653301000595,
        -0.04931462183594704,
        0.04797907546162605,
        -0.05381786450743675,
        0.011980383656919003,
        0.054936666041612625,
        0.05172301456332207,
        0.050954196602106094,
        0.0028657298535108566,
        0.05502733588218689,
        0.05173161253333092,
        -0.02182457409799099,
        0.01862279325723648,
        0.05504453927278519,
        0.037399549037218094,
        0.04829828813672066,
        0.049270302057266235,
        0.04396229237318039,
        -0.026923896744847298,
        -0.05383693054318428,
        -0.01663140021264553,
        -0.047954004257917404,
        -0.040854696184396744,
        -0.0506974421441555,
        -0.04861468821763992,
        -0.018061133101582527,
        -0.007733185775578022,
        -0.0536181777715683,
        0.043176423758268356,
        0.055009014904499054,
        -0.051634375005960464,
        -0.04609311744570732,
        0.038496233522892,
        -0.0030883175786584616,
        0.011829063296318054,
        -0.05316593497991562,
        -0.022689776495099068,
        0.055032771080732346,
        -0.05486667528748512,
        0.055026985704898834,
        0.05415613204240799,
        0.05476586893200874,
        -0.05359327420592308,
        0.04469926282763481,
        0.040979690849781036,
        -0.0036214631982147694,
        -0.03168804943561554,
        -0.043742600828409195,
        -0.05026504397392273,
        -0.028880005702376366,
        -0.010002238675951958,
        0.00310021941550076,
        0.03576134890317917,
        0.012564565986394882,
        -0.055019229650497437,
        0.05496768653392792,
        0.02535378560423851,
        0.05109359696507454,
        0.04646460711956024,
        0.052121102809906006,
        -0.038447119295597076,
        0.05300494655966759,
        0.04867173358798027,
        -0.0063079227693378925,
        -0.049186717718839645,
        -0.04504081979393959,
        0.050862304866313934,
        0.01456950418651104,
        -0.0020884370896965265,
        -0.05162540450692177,
        0.03894512355327606,
        0.031702108681201935,
        0.04741915315389633,
        0.05344773828983307,
        0.03938674181699753,
        -0.027003593742847443,
        0.018959645181894302,
        0.05417869985103607,
        -0.03715422749519348,
        -0.021990250796079636,
        -0.049440860748291016,
        -0.04020971432328224,
        -0.0522366426885128,
        0.0472029447555542,
        0.052901990711688995,
        0.05364783853292465,
        0.030290966853499413,
        -0.05083489790558815,
        0.05303431302309036,
        -0.033235274255275726,
        0.014922809787094593,
        -0.055024370551109314,
        0.04950626939535141,
        -0.012632115744054317,
        0.05501560494303703,
        -0.048311278223991394,
        -0.05186692625284195,
        -0.05457783117890358,
        0.05399460345506668,
        0.05192990228533745,
        0.041049566119909286,
        -0.05439542233943939,
        -0.05155542492866516,
        -0.05296666920185089,
        0.054675813764333725,
        -0.049150533974170685,
        0.010466380976140499,
        -0.054802361875772476,
        0.03614312410354614,
        0.05371604114770889,
        0.05149872228503227,
        0.04960467666387558,
        -0.013815934769809246,
        -0.050598565489053726,
        -0.04171307757496834,
        0.05277559161186218,
        -0.04084388166666031,
        0.05179595947265625,
        -0.05433472618460655,
        0.04238634184002876,
        -0.007841612212359905,
        0.05435705929994583,
        -0.05325556546449661,
        0.051671113818883896,
        0.05369957163929939,
        0.051420435309410095,
        -0.03300957754254341,
        -0.049304183572530746,
        0.01138040516525507,
        -0.015299216844141483,
        -0.05021662637591362,
        -0.040411122143268585,
        -0.008922086097300053,
        -0.044122178107500076,
        0.044281747192144394,
        0.05203970894217491,
        -0.05364156886935234,
        0.018231714144349098,
        0.05463487282395363,
        -0.03178548067808151,
        0.05489686504006386,
        -0.013673504814505577,
        0.050794001668691635,
        -0.05425328388810158,
        -0.051392268389463425,
        -0.048373665660619736,
        -0.034812916070222855,
        -0.02601858787238598,
        0.054194577038288116,
        0.049621209502220154,
        0.05115366727113724,
        -0.011349355801939964,
        0.04828847199678421,
        0.036036234349012375,
        -0.03821514919400215,
        0.054759737104177475,
        -0.02721252106130123,
        0.05446651577949524,
        0.038465868681669235,
        -0.05334094166755676,
        -0.01013951189815998,
        -0.05327190086245537,
        -0.02008333057165146,
        0.03452768549323082,
        0.04362782463431358,
        -0.053796570748090744,
        -0.051843222230672836,
        0.054954349994659424,
        -0.03840836510062218,
        0.0515570305287838,
        0.015888409689068794,
        -0.04911983758211136,
        -0.045104846358299255,
        -0.054065071046352386,
        0.00828890036791563,
        0.05499764159321785,
        0.03946163132786751,
        0.04669778048992157,
        -0.05502292886376381,
        0.050600744783878326,
        0.04863673821091652,
        -0.05060771480202675,
        0.054739437997341156,
        0.03339388594031334,
        0.05488879606127739,
        0.052721377462148666,
        0.05062980204820633,
        -0.037524860352277756,
        0.05482864752411842,
        -0.05247331038117409,
        -0.052967384457588196,
        -0.05500393360853195,
        0.03509409353137016,
        0.05376647785305977,
        -0.04270116239786148,
        -0.02239036373794079,
        0.03752315416932106,
        -0.05422590672969818,
        0.04694736748933792,
        0.009211709722876549,
        0.010291806422173977,
        -0.02819058485329151,
        0.043309200555086136,
        -0.05117481201887131,
        0.052653830498456955,
        0.03066125698387623,
        0.04320300370454788,
        -0.049337416887283325,
        -0.05495257303118706,
        -0.05394669249653816,
        0.0016408775700256228,
        0.05456298589706421,
        -0.028052108362317085,
        -0.05346331745386124,
        -0.05419357866048813,
        0.036141879856586456,
        0.05206167697906494,
        0.048011958599090576,
        -0.01758604496717453,
        0.01861703209578991,
        0.036919742822647095,
        -0.05491113290190697,
        0.03734687715768814,
        0.04628215357661247,
        0.041720349341630936,
        0.015518942847847939,
        0.021663255989551544,
        -0.049414265900850296,
        0.05434612184762955,
        -0.024440953508019447,
        0.03703419864177704,
        0.04996799677610397,
        0.05184342339634895,
        0.05388070270419121,
        0.04626986384391785,
        -0.05445108935236931,
        0.03693721070885658,
        0.043992288410663605,
        0.05494781583547592,
        0.054923009127378464,
        -0.022716592997312546,
        0.055019769817590714,
        0.03462531045079231,
        -0.054272305220365524,
        0.03187467157840729,
        -0.05407943204045296,
        0.05224405974149704,
        0.04664085805416107,
        0.05159926787018776,
        0.04636836796998978,
        0.05500633269548416,
        0.054278239607810974,
        -0.022919584065675735,
        -0.04989190772175789,
        -0.04718942195177078,
        -0.027434751391410828,
        -0.0550302192568779,
        -0.018110452219843864,
        0.05322924256324768,
        0.027651896700263023,
        -0.0035704700276255608,
        0.038588520139455795,
        -0.0495837926864624,
        0.027616500854492188,
        0.042478736490011215,
        -0.028247924521565437,
        0.043260764330625534,
        -0.004534356761723757,
        -0.05305050313472748,
        -0.05122200399637222,
        -0.05459638684988022,
        -0.02602131851017475,
        0.05045170709490776,
        0.04518672451376915,
        -0.029467206448316574,
        0.0410977341234684,
        -0.02789817936718464,
        -0.052650194615125656,
        -0.01224258542060852,
        -0.002396098105236888,
        0.054851558059453964,
        0.019004710018634796,
        0.014952125959098339,
        -0.05504683777689934,
        0.05424020439386368,
        0.03272469341754913
    ],
    [
        -0.04613446816802025,
        -0.05631789192557335,
        -0.006668213754892349,
        0.01700662449002266,
        0.03369830176234245,
        -0.04142642393708229,
        0.04859015345573425,
        -0.045803915709257126,
        0.048563919961452484,
        0.05650527775287628,
        0.05426543578505516,
        -0.005470403004437685,
        -0.05494236201047897,
        -0.04366791620850563,
        -0.05505089461803436,
        0.054785940796136856,
        -0.05090619623661041,
        0.04313139244914055,
        -0.05412301793694496,
        0.05094589293003082,
        -0.054473020136356354,
        -0.05630109831690788,
        0.04251639172434807,
        -0.04693951830267906,
        0.027677087113261223,
        0.034890200942754745,
        0.05681091919541359,
        0.05625943839550018,
        -0.024106530472636223,
        -0.056204117834568024,
        -0.053388334810733795,
        -0.012446990236639977,
        -0.03777452930808067,
        -0.05685347691178322,
        0.04546487331390381,
        -0.038973525166511536,
        -0.05053741857409477,
        0.048680294305086136,
        -0.056897301226854324,
        0.004424233455210924,
        -0.008019610308110714,
        0.0538015253841877,
        0.0375252403318882,
        -0.054104141891002655,
        0.05537945032119751,
        -0.02067328430712223,
        0.05175643414258957,
        0.044377367943525314,
        0.05686086788773537,
        0.020759377628564835,
        0.05231853947043419,
        -0.047027185559272766,
        -0.026276500895619392,
        0.012153204530477524,
        -0.015632977709174156,
        -0.03151358664035797,
        -0.05682266131043434,
        0.017312373965978622,
        0.051413554698228836,
        -0.006429919507354498,
        -0.05619067698717117,
        -0.01655207760632038,
        -0.04888168349862099,
        0.05674546957015991,
        0.04642866179347038,
        0.040872860699892044,
        0.020030081272125244,
        0.014158759266138077,
        -0.04998723790049553,
        -0.05619155988097191,
        -0.05683406814932823,
        -0.054108619689941406,
        0.035870667546987534,
        0.0259882640093565,
        0.03576183691620827,
        0.028505420312285423,
        0.02214818261563778,
        0.05687947943806648,
        0.05468262359499931,
        -0.056685686111450195,
        -0.028570998460054398,
        -0.04028555750846863,
        -0.05656744912266731,
        0.05574209615588188,
        0.05153769627213478,
        0.005282538942992687,
        0.053749118000268936,
        -0.035866279155015945,
        0.056890103965997696,
        0.0550273060798645,
        -0.007388112600892782,
        0.040572814643383026,
        0.010819447226822376,
        0.026554962620139122,
        -0.0015396103262901306,
        -0.02846597507596016,
        -0.037725597620010376,
        -0.05634694546461105,
        -0.04348072037100792,
        -0.04220466688275337,
        -0.05596783757209778,
        0.026303047314286232,
        0.05634145066142082,
        0.0562848225235939,
        0.05551021918654442,
        -0.056884102523326874,
        -0.05677478387951851,
        0.05675842985510826,
        -0.056755080819129944,
        0.04594443738460541,
        -0.056684862822294235,
        0.053863078355789185,
        -0.05217033997178078,
        0.03817107900977135,
        -0.04985934495925903,
        0.046603865921497345,
        -0.04143377020955086,
        -0.05306309089064598,
        -0.030773717910051346,
        -0.04326849803328514,
        -0.008592203259468079,
        0.008605804294347763,
        -0.02128404937684536,
        -0.05564867705106735,
        -0.05516134575009346,
        -0.028493160381913185,
        0.014064417220652103,
        0.05194925144314766,
        0.05664334446191788,
        0.025800960138440132,
        -0.006693115457892418,
        -0.042107321321964264,
        -0.020622100681066513,
        -0.011073008179664612,
        -0.051204338669776917,
        -0.05109895393252373,
        0.011574255302548409,
        -0.00840822048485279,
        -0.05644267052412033,
        0.05689597129821777,
        0.05686362832784653,
        0.054479677230119705,
        0.04829078167676926,
        0.056720852851867676,
        -0.05188630521297455,
        0.05297601968050003,
        0.04790882393717766,
        -0.05547589063644409,
        -0.04935570806264877,
        -0.03220202028751373,
        -0.05650274455547333,
        -0.05574765428900719,
        0.0568869523704052,
        0.05330238491296768,
        0.031541381031274796,
        -0.053742051124572754,
        -0.056701015681028366,
        0.04476335644721985,
        0.017210308462381363,
        0.05481801927089691,
        0.054332561790943146,
        -0.05231041833758354,
        0.03526807203888893,
        -0.05661774054169655,
        -0.036810245364904404,
        -0.0029184450395405293,
        -0.051515985280275345,
        -0.04092772305011749,
        -0.002720613032579422,
        0.0055965096689760685,
        0.04149209335446358,
        0.05648268386721611,
        -0.05466080456972122,
        0.008611591532826424,
        0.054500386118888855,
        0.05679633468389511,
        -0.05597519129514694,
        0.013129736296832561,
        0.018434589728713036,
        -0.014790097251534462,
        0.04411541298031807,
        0.04548332840204239,
        0.022054268047213554,
        -0.05410756170749664,
        -0.05496785044670105,
        -0.051203880459070206,
        0.042449794709682465,
        0.05491954833269119,
        -0.03262050822377205,
        -0.05676023289561272,
        -0.03989412635564804,
        0.03410506621003151,
        0.04288959875702858,
        -0.032630953937768936,
        0.03658949211239815,
        0.03700455650687218,
        0.05619901418685913,
        0.0418800413608551,
        -0.03330538794398308,
        -0.008570121601223946,
        -0.011263913474977016,
        -0.019790617749094963,
        -0.05345304310321808,
        0.001621185801923275,
        -0.04505199193954468,
        -0.03661118075251579,
        0.04955320805311203,
        -0.04405733197927475,
        -0.05353039875626564,
        -0.015105117112398148,
        -0.03241002559661865,
        0.050066836178302765,
        0.056663740426301956,
        0.05276915058493614,
        0.054178863763809204,
        0.0298960842192173,
        0.04204830154776573,
        0.04422144591808319,
        0.05686918646097183,
        -0.044617269188165665,
        0.0044609853066504,
        -0.010124223306775093,
        -0.02590218000113964,
        -0.05668682977557182,
        0.0261611919850111,
        0.001191456918604672,
        0.04841110110282898,
        0.020721860229969025,
        -0.0075945425778627396,
        -0.0538594014942646,
        -0.01471218653023243,
        -0.03191523998975754,
        -0.056259993463754654,
        0.018330389633774757,
        -0.020095424726605415,
        0.04381130635738373,
        0.05641843378543854,
        0.035991255193948746,
        0.04906998947262764,
        0.05470496788620949,
        0.05345417559146881,
        -0.012882869690656662,
        0.02454359643161297,
        -0.04860575497150421,
        0.03960995376110077,
        0.02624668926000595,
        -0.045088328421115875,
        0.04412045329809189,
        0.010579344816505909,
        0.012225435115396976,
        0.041201137006282806,
        -0.05645865201950073,
        -0.05679430440068245,
        -0.05387548357248306,
        -0.043824248015880585,
        0.055947382003068924,
        -0.054650966078042984,
        -0.03795526549220085,
        0.05334451422095299,
        0.05159781873226166,
        0.053460974246263504,
        -0.05508529022336006,
        0.05487507954239845,
        0.056707609444856644,
        -0.05632324889302254,
        0.054763052612543106,
        0.006834379862993956,
        0.049453239887952805,
        -0.04423853009939194,
        -0.0006396441021934152,
        -0.021680722013115883,
        -0.055632881820201874,
        0.010518052615225315,
        -0.056540701538324356,
        -0.04712928459048271,
        0.01100265420973301,
        -0.05607084929943085,
        -0.013337388634681702,
        0.005239477381110191,
        -0.05480988323688507,
        -0.05619990453124046,
        -0.05549200251698494,
        0.0029300269670784473,
        0.05688827112317085,
        -0.03253877907991409,
        -0.047656700015068054,
        0.020610451698303223,
        -0.04638819396495819,
        0.0014229174703359604,
        0.023443076759576797,
        0.017155321314930916,
        -0.05576397478580475,
        -0.050085823982954025,
        0.0019094565650448203,
        -0.056534409523010254,
        -0.05598415806889534,
        0.05616891384124756,
        -0.011082367971539497,
        -0.037915561348199844,
        0.05220665782690048,
        -0.03674488142132759,
        0.056021060794591904,
        0.05021055415272713,
        0.05684944987297058,
        -0.05325761437416077,
        -0.026201192289590836,
        -0.02337905392050743,
        -0.03459120914340019,
        -0.027162615209817886,
        -0.05367523804306984,
        -0.018537359312176704,
        -0.0016808469081297517,
        0.013627427630126476,
        -0.051558252424001694,
        -0.056847523897886276,
        -0.05114290863275528,
        0.05391128733754158,
        -0.056475769728422165,
        0.036799922585487366,
        -0.05027614161372185,
        0.03888511657714844,
        -0.026277022436261177,
        -0.02849559858441353,
        -0.05689343810081482,
        0.05601022392511368,
        0.04987449571490288,
        0.0333336777985096,
        -0.04157673567533493,
        0.05669783800840378,
        0.011391553096473217,
        0.0358034111559391,
        0.05662466213107109,
        -0.02925204113125801,
        -0.05532645434141159,
        0.054831475019454956,
        0.056691430509090424,
        0.03419196233153343,
        0.056290365755558014,
        0.009362989105284214,
        0.03878830373287201,
        0.04000101238489151,
        -0.04661755636334419,
        -0.056629639118909836,
        -0.004003174137324095,
        0.05689708888530731,
        0.0546690933406353,
        -0.04173949733376503,
        0.03845442458987236,
        -0.04651207849383354,
        0.05640537291765213,
        -0.05654232203960419,
        -0.04656626284122467,
        0.002469250001013279,
        -0.05008918419480324,
        0.0561293363571167,
        0.05651099979877472,
        0.0261401180177927,
        -0.031062571331858635,
        -0.04979872703552246,
        -0.05652020871639252,
        0.050073761492967606,
        0.038074810057878494,
        -0.04445560276508331,
        0.0236626248806715,
        0.0005823522224090993,
        -0.046865224838256836,
        0.056849706918001175,
        0.02851351536810398,
        0.053088124841451645,
        -0.056864768266677856,
        -0.05272357165813446,
        -0.05586184561252594,
        0.054793838411569595,
        0.05656567960977554,
        -0.03818079084157944,
        0.055104803293943405,
        -0.04637174308300018,
        0.025971990078687668,
        -0.05516236275434494,
        -0.05466054752469063,
        -0.05682483687996864,
        0.05682648345828056,
        -0.05599670484662056,
        0.014547704719007015,
        0.04440028592944145,
        -0.054384440183639526,
        0.010828380472958088,
        -0.056602995842695236,
        -0.05407596379518509,
        0.04565049335360527,
        -0.05629238486289978,
        0.054400358349084854,
        -0.03345807269215584,
        0.0444343239068985,
        -0.05361595377326012,
        0.0503058023750782,
        0.05548141524195671,
        0.0323977991938591,
        0.05222402140498161,
        -0.05686559900641441,
        -0.050106894224882126,
        0.05650440603494644,
        0.05620059370994568,
        0.0040766289457678795,
        -0.048229265958070755,
        -0.051739852875471115,
        0.0540844090282917,
        -0.05504447966814041,
        0.056537698954343796,
        -0.055539052933454514,
        0.010230655781924725,
        0.05560290813446045,
        0.05033853277564049,
        -0.04915226250886917,
        0.055992722511291504,
        -0.010289723053574562,
        -0.03186934441328049,
        -0.05678930506110191,
        0.04869382828474045,
        -0.05677265301346779,
        -0.0164978988468647,
        0.000044627759052673355,
        0.03491901233792305,
        -0.056298185139894485,
        0.046360570937395096,
        -0.009386974386870861,
        -0.056852106004953384,
        -0.03946242108941078,
        -0.05265947803854942,
        -0.04614662751555443,
        0.03861849009990692,
        0.02876194566488266,
        -0.04679613187909126,
        -0.021647103130817413,
        -0.0436207614839077,
        -0.04660377651453018,
        0.03876139596104622,
        0.05505085736513138,
        -0.021700602024793625,
        -0.05656001344323158,
        -0.015838151797652245,
        0.054211363196372986,
        -0.02253742702305317,
        0.053848158568143845,
        0.056876108050346375,
        0.02795039676129818,
        0.055985234677791595,
        -0.05672371760010719,
        0.044247597455978394,
        0.04852408170700073,
        -0.03471948206424713,
        0.05433725565671921,
        0.035925522446632385,
        0.01715514250099659,
        -0.05488531291484833,
        -0.04271172732114792,
        -0.049665383994579315,
        0.050812747329473495,
        0.02114679664373398,
        0.05687020346522331,
        -0.029856331646442413,
        -0.05687519535422325,
        -0.05162358283996582,
        -0.04321052134037018,
        0.04427583888173103,
        0.05672929435968399,
        -0.05509999021887779,
        0.05646783486008644,
        -0.051694050431251526,
        -0.05687480419874191,
        -0.035585179924964905,
        -0.05679408460855484,
        0.050511427223682404,
        -0.004853702615946531,
        0.00418168306350708,
        -0.018843133002519608,
        -0.04272465035319328,
        0.05487239733338356,
        0.003473460441455245,
        -0.044112734496593475,
        0.05614573508501053,
        0.0563199482858181,
        0.039334118366241455,
        0.051172886043787,
        0.05176866799592972,
        0.03594743832945824,
        0.010461892932653427,
        -0.00019935076124966145,
        -0.05350566655397415,
        -0.023079266771674156,
        -0.056367188692092896,
        -0.056883033365011215,
        -0.003980850800871849,
        -0.009681790135800838,
        0.05486376956105232,
        0.03535231947898865,
        -0.003367554396390915,
        0.04525381326675415,
        0.05442224442958832,
        0.056696582585573196,
        -0.054416004568338394,
        -0.018912820145487785,
        -0.03992082551121712,
        -0.04044121876358986,
        0.05347897484898567,
        0.05129261314868927,
        -0.054029665887355804,
        -0.022917071357369423,
        -0.030559655278921127,
        -0.05630408599972725,
        0.013288604095578194,
        0.05567869544029236
    ],
    [
        0.036607153713703156,
        -0.023069528862833977,
        0.05360963195562363,
        -0.03187273070216179,
        -0.036686740815639496,
        -0.031225550919771194,
        0.004122626036405563,
        -0.05701806768774986,
        0.05827005207538605,
        -0.023491960018873215,
        0.046693701297044754,
        -0.01739141345024109,
        -0.056815218180418015,
        -0.0405220165848732,
        -0.057460859417915344,
        0.05809060111641884,
        0.05171201750636101,
        0.0581309013068676,
        -0.032857995480298996,
        0.034261271357536316,
        0.004629532806575298,
        0.025607198476791382,
        -0.03763197734951973,
        0.00478156004101038,
        -0.05629032850265503,
        -0.042787402868270874,
        0.04708388075232506,
        0.05833437666296959,
        -0.03761006519198418,
        -0.054857369512319565,
        -0.01865997165441513,
        0.048249587416648865,
        0.0572943389415741,
        -0.057978007942438126,
        0.05691910907626152,
        -0.05794025957584381,
        -0.05751940235495567,
        -0.016761403530836105,
        -0.058330025523900986,
        0.03577502444386482,
        0.03645281121134758,
        0.054026588797569275,
        0.050546590238809586,
        -0.047383230179548264,
        0.058198679238557816,
        -0.03573158383369446,
        0.020363621413707733,
        0.05686204507946968,
        0.058295004069805145,
        -0.05739773064851761,
        0.05499205365777016,
        0.05709385499358177,
        -0.051823921501636505,
        -0.00376542704179883,
        0.05714067444205284,
        -0.028693361207842827,
        -0.05829271301627159,
        -0.04612576588988304,
        -0.04182855412364006,
        -0.011211263947188854,
        -0.05827498435974121,
        0.05658917874097824,
        -0.05473416671156883,
        0.041952066123485565,
        0.041187550872564316,
        0.04360558092594147,
        -0.05700084939599037,
        0.0230813380330801,
        -0.023892560973763466,
        -0.030232330784201622,
        0.02106316387653351,
        -0.05663813650608063,
        0.05831529200077057,
        -0.02510392852127552,
        0.058106448501348495,
        -0.011847352609038353,
        0.017546571791172028,
        0.0583496131002903,
        0.016998356208205223,
        -0.05004526674747467,
        -0.0352785624563694,
        -0.05329321324825287,
        -0.05698192119598389,
        0.055411290377378464,
        0.055933427065610886,
        -0.010611320845782757,
        0.038768719881772995,
        -0.05729798227548599,
        0.018811287358403206,
        -0.049629729241132736,
        -0.016794515773653984,
        0.01743258349597454,
        -0.02660422958433628,
        -0.05665595084428787,
        0.012702238745987415,
        0.012518123723566532,
        0.02483978495001793,
        -0.054796263575553894,
        0.00546015752479434,
        0.051247335970401764,
        -0.05831630527973175,
        0.03870294615626335,
        0.04574936628341675,
        0.05683160200715065,
        0.04443126916885376,
        -0.05833034962415695,
        -0.05456443876028061,
        0.0557265542447567,
        -0.056224022060632706,
        0.007125623989850283,
        -0.045289937406778336,
        -0.0369722843170166,
        -0.05309683829545975,
        0.0571928545832634,
        0.0577724426984787,
        0.0450483039021492,
        -0.03051588125526905,
        -0.056290559470653534,
        0.05693262442946434,
        -0.056082431226968765,
        -0.02071303129196167,
        -0.05220341682434082,
        0.03891080990433693,
        -0.058081869035959244,
        -0.05632562190294266,
        0.015613033436238766,
        0.04699333384633064,
        0.0578015074133873,
        0.057701610028743744,
        0.05829814821481705,
        0.05439119040966034,
        -0.053966667503118515,
        0.04885854944586754,
        0.00730623584240675,
        -0.03901435807347298,
        -0.05761561170220375,
        0.05710624158382416,
        -0.02307676151394844,
        -0.03638807311654091,
        0.053063470870256424,
        0.04102138429880142,
        0.021385271102190018,
        0.05504581704735756,
        0.047928959131240845,
        -0.04790440946817398,
        0.054027050733566284,
        0.025001732632517815,
        -0.034256987273693085,
        0.005848651751875877,
        0.028276052325963974,
        0.0134780528023839,
        -0.05813446640968323,
        0.05566410720348358,
        0.015617110766470432,
        0.0439237616956234,
        -0.05149776116013527,
        -0.05835090950131416,
        0.034059248864650726,
        0.05445012077689171,
        0.019011354073882103,
        0.05502263829112053,
        -0.03129130229353905,
        0.056863728910684586,
        0.03980745002627373,
        0.010516219772398472,
        -0.012688488699495792,
        0.05812058970332146,
        0.053204137831926346,
        0.019218992441892624,
        -0.010929192416369915,
        0.029490714892745018,
        0.03465273603796959,
        -0.05797140672802925,
        0.037626516073942184,
        0.05794747918844223,
        0.05804998800158501,
        -0.024018507450819016,
        -0.01562776416540146,
        0.04967198893427849,
        -0.056763071566820145,
        -0.05049740523099899,
        0.05377819761633873,
        0.021915676072239876,
        -0.030852822586894035,
        -0.016633201390504837,
        -0.04880904033780098,
        0.040682919323444366,
        0.058142516762018204,
        -0.04496336728334427,
        0.05387880280613899,
        -0.05750978738069534,
        0.0539386086165905,
        -0.018415581434965134,
        0.021831603720784187,
        -0.021691856905817986,
        -0.006482657510787249,
        -0.002886133035644889,
        -0.0518912598490715,
        -0.015496974810957909,
        0.05743123218417168,
        0.055891718715429306,
        0.006404966581612825,
        0.017922841012477875,
        -0.05796021223068237,
        -0.03491124510765076,
        0.04403555765748024,
        0.01658075861632824,
        0.03126968443393707,
        -0.057326458394527435,
        0.0559760220348835,
        -0.04801976680755615,
        0.05755116418004036,
        0.055513739585876465,
        0.058193888515233994,
        -0.010863321833312511,
        -0.020598290488123894,
        0.04003262519836426,
        -0.0065946695394814014,
        0.057779863476753235,
        -0.021806014701724052,
        -0.026028292253613472,
        0.024982063099741936,
        0.04870143532752991,
        0.02803528681397438,
        0.051934465765953064,
        0.04904324561357498,
        0.048397406935691833,
        -0.055159397423267365,
        0.029859332367777824,
        -0.05364695563912392,
        -0.057269588112831116,
        -0.05656169727444649,
        -0.05791833996772766,
        0.053450413048267365,
        -0.02286159060895443,
        0.05818619951605797,
        0.050346262753009796,
        -0.02026297338306904,
        -0.05329282581806183,
        0.058347512036561966,
        0.041144128888845444,
        0.0358622744679451,
        -0.045462071895599365,
        0.018605051562190056,
        0.03775540366768837,
        0.02903635799884796,
        -0.013084315694868565,
        -0.000018387940144748427,
        -0.0186601672321558,
        0.002651411574333906,
        0.02009517513215542,
        -0.05697305500507355,
        0.01913783885538578,
        -0.056791432201862335,
        -0.05289723351597786,
        0.05720636248588562,
        -0.047346506267786026,
        -0.04281226918101311,
        0.05151297524571419,
        -0.046928759664297104,
        0.0358213409781456,
        -0.05786534398794174,
        0.024647723883390427,
        0.05784185230731964,
        0.029185999184846878,
        0.05820879340171814,
        0.05725480243563652,
        0.05831412598490715,
        -0.05020613223314285,
        0.05774667114019394,
        0.045236341655254364,
        -0.053389523178339005,
        0.04355652257800102,
        -0.056591905653476715,
        -0.045590102672576904,
        0.04750027880072594,
        -0.05144183337688446,
        0.01914731040596962,
        0.03145073354244232,
        -0.04401020705699921,
        -0.05658378079533577,
        0.0011869037989526987,
        -0.04744410887360573,
        -0.019966453313827515,
        0.03956320136785507,
        0.007760472595691681,
        0.030541950836777687,
        0.05718807503581047,
        0.050242915749549866,
        0.04659203439950943,
        -0.033087559044361115,
        -0.05450541898608208,
        -0.04987206682562828,
        -0.0481669083237648,
        -0.006222988944500685,
        -0.05739052966237068,
        0.05750839039683342,
        -0.053125180304050446,
        -0.053247299045324326,
        0.05794042348861694,
        0.033765796571969986,
        0.007844031788408756,
        0.017208995297551155,
        0.05748525634407997,
        0.02326904982328415,
        0.03603225201368332,
        -0.04682360216975212,
        -0.011303693987429142,
        0.02948414906859398,
        -0.04826812818646431,
        -0.01696421019732952,
        0.026607273146510124,
        0.055537063628435135,
        -0.037466179579496384,
        -0.027294935658574104,
        -0.057585135102272034,
        -0.025798186659812927,
        -0.021593140438199043,
        0.04913845658302307,
        -0.008035743609070778,
        0.05082251876592636,
        -0.02734886296093464,
        0.05813749507069588,
        -0.055926576256752014,
        0.05786875635385513,
        -0.014003225602209568,
        0.05796501412987709,
        -0.01789448782801628,
        0.012804779224097729,
        -0.05214317888021469,
        -0.052236177027225494,
        -0.04133627563714981,
        0.053724247962236404,
        -0.057888492941856384,
        0.0477953776717186,
        0.054137203842401505,
        -0.04787980765104294,
        0.05069859325885773,
        0.0035125629510730505,
        0.005447855684906244,
        -0.025639405474066734,
        0.012634837068617344,
        -0.057690978050231934,
        -0.012517241761088371,
        0.03874858468770981,
        -0.017898686230182648,
        0.04568076506257057,
        0.057186342775821686,
        0.017729006707668304,
        0.04055371880531311,
        0.05793638899922371,
        -0.019258806481957436,
        0.0485093891620636,
        -0.03733810782432556,
        0.018378447741270065,
        -0.0035335756838321686,
        -0.04528655856847763,
        -0.04276997596025467,
        -0.003172367811203003,
        -0.054169852286577225,
        0.042922403663396835,
        -0.042557619512081146,
        0.0026911278255283833,
        0.00044098522630520165,
        -0.05785543471574783,
        -0.05754653364419937,
        0.0575965940952301,
        0.018202854320406914,
        0.025537170469760895,
        -0.056926220655441284,
        -0.02180955745279789,
        -0.04407322406768799,
        0.029844442382454872,
        -0.00296465284191072,
        0.05022429674863815,
        0.05055338889360428,
        -0.03667737916111946,
        0.036415524780750275,
        0.05772731080651283,
        -0.05129746347665787,
        -0.055446140468120575,
        0.05742128938436508,
        -0.0487079992890358,
        -0.0096285967156291,
        0.05664801597595215,
        -0.037531398236751556,
        -0.03770299628376961,
        -0.05431152135133743,
        -0.0515516921877861,
        -0.03824325278401375,
        -0.057185735553503036,
        0.04015422612428665,
        -0.05793647840619087,
        -0.047644518315792084,
        -0.043309640139341354,
        0.05359688028693199,
        0.05830737203359604,
        -0.054035965353250504,
        -0.011191829107701778,
        -0.05593074485659599,
        -0.04490089416503906,
        0.058268606662750244,
        -0.009000852704048157,
        0.05214838311076164,
        -0.05252330005168915,
        -0.03803340345621109,
        0.035796891897916794,
        -0.05651050806045532,
        0.05168823525309563,
        0.018876664340496063,
        -0.05704944208264351,
        -0.028839142993092537,
        0.05422065407037735,
        -0.057510022073984146,
        0.05809842422604561,
        -0.04500506445765495,
        -0.051578473299741745,
        -0.052683863788843155,
        0.027264710515737534,
        -0.05808035284280777,
        -0.04809754714369774,
        -0.05493997409939766,
        -0.037420257925987244,
        -0.055139873176813126,
        0.04628906771540642,
        0.047568708658218384,
        -0.0582144670188427,
        0.00018597222515381873,
        -0.006675024051219225,
        -0.05771723389625549,
        0.05386253073811531,
        -0.019799286499619484,
        0.03026207722723484,
        0.03286231309175491,
        -0.0559316948056221,
        -0.057567983865737915,
        0.024357633665204048,
        0.0102117033675313,
        -0.051493048667907715,
        -0.0576130710542202,
        -0.008227109909057617,
        0.008020903915166855,
        0.010763972997665405,
        0.05688893422484398,
        0.05833066627383232,
        0.051661502569913864,
        0.05538206174969673,
        0.023080170154571533,
        0.014638860709965229,
        0.05723833665251732,
        0.01934404857456684,
        0.05257914215326309,
        -0.016977466642856598,
        0.010974470525979996,
        0.031348053365945816,
        -0.05562428757548332,
        0.0034080487675964832,
        -0.050733551383018494,
        0.047543883323669434,
        0.05830751731991768,
        0.055210892111063004,
        -0.045333366841077805,
        0.0029417213518172503,
        -0.02457425743341446,
        0.05059123411774635,
        0.05807872861623764,
        -0.05703461542725563,
        0.058231569826602936,
        0.025645064190030098,
        -0.056710124015808105,
        0.014792338944971561,
        -0.05491586774587631,
        0.05256775766611099,
        0.0558200404047966,
        -0.02927878312766552,
        0.022178364917635918,
        0.05058169364929199,
        0.057962093502283096,
        -0.057185422629117966,
        -0.020530078560113907,
        0.056490276008844376,
        0.044894080609083176,
        -0.034942712634801865,
        0.04736403375864029,
        0.057496000081300735,
        -0.0080392612144351,
        0.05756555497646332,
        0.05744091421365738,
        -0.05653299018740654,
        -0.013905096799135208,
        -0.055056776851415634,
        -0.05684228241443634,
        0.05652520805597305,
        -0.05531693249940872,
        -0.05481868237257004,
        -0.04801744595170021,
        -0.0014903537230566144,
        0.055890828371047974,
        0.05336751043796539,
        0.057967185974121094,
        -0.0467739999294281,
        -0.04118451848626137,
        -0.05153389647603035,
        -0.050905127078294754,
        0.05775944143533707,
        0.05463241785764694,
        0.04544743895530701,
        0.014070054516196251,
        0.032205019146203995,
        -0.057455651462078094,
        -0.05445630103349686,
        0.02689903788268566
    ],
    [
        -0.0385277085006237,
        0.0536918006837368,
        -0.011892619542777538,
        0.04547404497861862,
        0.0332949124276638,
        0.02859259396791458,
        0.05988628789782524,
        -0.044929686933755875,
        0.058416698127985,
        -0.05071796104311943,
        0.04229533672332764,
        0.017587626352906227,
        -0.05817659571766853,
        0.04703860357403755,
        -0.05690806359052658,
        0.043441642075777054,
        -0.05421973764896393,
        0.05833929404616356,
        -0.05471610650420189,
        -0.04387292638421059,
        0.026544664055109024,
        -0.0028127869591116905,
        0.054431408643722534,
        0.05958325043320656,
        0.05565117672085762,
        -0.010125387459993362,
        0.04498450085520744,
        0.05340317636728287,
        0.0013833714183419943,
        0.03610512241721153,
        0.06032133102416992,
        0.022550834342837334,
        -0.0538816899061203,
        -0.05814199522137642,
        0.04422421753406525,
        -0.012263026088476181,
        -0.05302004888653755,
        -0.0491735078394413,
        -0.03360777720808983,
        0.05977126583456993,
        0.012154247611761093,
        0.0505896620452404,
        0.05124261602759361,
        -0.04395666345953941,
        0.05977662280201912,
        0.0290712621062994,
        0.043392349034547806,
        0.042983222752809525,
        0.058622147887945175,
        0.024852123111486435,
        0.036833759397268295,
        -0.04412192106246948,
        0.05365012586116791,
        -0.04167607054114342,
        0.03894621878862381,
        -0.04568146914243698,
        -0.02620447240769863,
        0.030306681990623474,
        -0.04873261600732803,
        0.03904016315937042,
        -0.05628729239106178,
        -0.007818784564733505,
        -0.05734306201338768,
        0.05354219302535057,
        -0.010938574559986591,
        0.04528046026825905,
        0.029658125713467598,
        -0.03421144559979439,
        0.051578667014837265,
        -0.06037798896431923,
        -0.05670259892940521,
        -0.06041852757334709,
        0.043191906064748764,
        -0.006399167701601982,
        0.03491045907139778,
        0.00679273484274745,
        0.006703562568873167,
        0.06042352318763733,
        0.06024142727255821,
        -0.04787585139274597,
        -0.015881022438406944,
        -0.01905183494091034,
        -0.05251297354698181,
        0.028897134587168694,
        0.05163315311074257,
        0.042009077966213226,
        -0.0130416015163064,
        0.02722710557281971,
        0.03133144974708557,
        0.047957874834537506,
        -0.050360698252916336,
        0.0022598449140787125,
        -0.056121110916137695,
        -0.057836636900901794,
        0.005324539728462696,
        -0.04870370402932167,
        -0.04007294401526451,
        -0.05966982617974281,
        -0.05077006295323372,
        0.0271113570779562,
        -0.06039173901081085,
        -0.0026654149405658245,
        0.053462497889995575,
        0.03474033996462822,
        0.008320722728967667,
        0.009011633694171906,
        -0.0385134294629097,
        0.04890603572130203,
        -0.030400721356272697,
        -0.027533743530511856,
        -0.06004989147186279,
        -0.05943804606795311,
        -0.05850735306739807,
        -0.01178984809666872,
        0.014905729331076145,
        0.0011407533893361688,
        0.04776060953736305,
        -0.059924300760030746,
        -0.05716415122151375,
        -0.011193137615919113,
        -0.05361364409327507,
        -0.008676317520439625,
        0.015075086615979671,
        -0.02920212596654892,
        -0.059658605605363846,
        0.05793045833706856,
        0.033294182270765305,
        0.05070756748318672,
        0.052860699594020844,
        0.05541915073990822,
        -0.021221056580543518,
        -0.03732243552803993,
        -0.05429570749402046,
        -0.03747878968715668,
        -0.05413806065917015,
        -0.05896716192364693,
        0.038378071039915085,
        -0.035250574350357056,
        -0.050057217478752136,
        0.04898212105035782,
        0.05108979716897011,
        0.0557124949991703,
        0.014359565451741219,
        0.05424952507019043,
        0.041481345891952515,
        -0.05104845389723778,
        0.05074266344308853,
        -0.047777339816093445,
        0.031576186418533325,
        0.060459718108177185,
        -0.053372710943222046,
        -0.05949520692229271,
        -0.05643376708030701,
        -0.04146711900830269,
        -0.00282847392372787,
        -0.04585441201925278,
        0.05988692864775658,
        -0.04417168349027634,
        0.009646113961935043,
        0.011530715972185135,
        0.06021912768483162,
        0.03529699146747589,
        0.023206710815429688,
        0.012397789396345615,
        -0.047189246863126755,
        0.03950800001621246,
        0.04270437732338905,
        -0.0309387668967247,
        0.046096183359622955,
        0.05349652096629143,
        -0.010337741114199162,
        0.02280537784099579,
        -0.05917610600590706,
        -0.052630990743637085,
        -0.056134194135665894,
        0.0596960186958313,
        -0.06042732670903206,
        0.005697465501725674,
        0.05282806605100632,
        0.019190438091754913,
        0.00872814655303955,
        0.05747184902429581,
        0.056944191455841064,
        0.01993231102824211,
        0.044324323534965515,
        -0.049280714243650436,
        -0.011323085054755211,
        0.049962367862463,
        -0.021483883261680603,
        -0.04836402088403702,
        -0.04162771627306938,
        -0.048629652708768845,
        -0.04470429569482803,
        -0.043835800141096115,
        -0.021027296781539917,
        0.056833162903785706,
        0.044663626700639725,
        -0.05885471776127815,
        -0.05070451274514198,
        0.029906388372182846,
        0.03266880288720131,
        -0.017301183193922043,
        -0.03806089982390404,
        0.02824312075972557,
        -0.00286484370008111,
        0.05523114651441574,
        0.03617845103144646,
        0.037419140338897705,
        -0.041787274181842804,
        0.005756915546953678,
        -0.054954592138528824,
        0.053970303386449814,
        -0.05720760300755501,
        0.001992379315197468,
        -0.029935231432318687,
        0.04618994519114494,
        0.0601104274392128,
        0.045225340873003006,
        0.06021576374769211,
        -0.046669766306877136,
        0.020249035209417343,
        -0.056574635207653046,
        -0.02419128082692623,
        -0.05993291363120079,
        0.056448232382535934,
        -0.058233171701431274,
        -0.03408877179026604,
        0.05146828293800354,
        0.010732505470514297,
        -0.0596296451985836,
        -0.042745184153318405,
        -0.05950698256492615,
        -0.02903335727751255,
        0.03915597125887871,
        0.01846708171069622,
        -0.023373160511255264,
        0.059891875833272934,
        0.03823811560869217,
        -0.0443694144487381,
        0.05982908234000206,
        0.026989588513970375,
        0.06040063500404358,
        -0.004329041577875614,
        0.028501499444246292,
        0.004605303518474102,
        -0.0006103053456172347,
        0.051638782024383545,
        0.05574340373277664,
        -0.0590808279812336,
        -0.043843671679496765,
        -0.055754706263542175,
        -0.05790030211210251,
        -0.016573665663599968,
        -0.05979304388165474,
        0.05059180036187172,
        0.058025386184453964,
        -0.04831131175160408,
        -0.03533417358994484,
        0.06002143770456314,
        0.05888928472995758,
        0.027505716308951378,
        0.056789662688970566,
        0.04236743226647377,
        0.05872560292482376,
        -0.02517499029636383,
        0.04733002558350563,
        0.05909241363406181,
        0.029839081689715385,
        0.03404141217470169,
        -0.048256222158670425,
        -0.0009238977218046784,
        -0.06033673882484436,
        0.03505055606365204,
        -0.060198988765478134,
        0.02713758684694767,
        0.035406142473220825,
        -0.05917072296142578,
        0.059937916696071625,
        -0.0601629801094532,
        -0.056439418345689774,
        -0.06002166494727135,
        0.009495241567492485,
        0.039677318185567856,
        0.03284700587391853,
        0.06044004112482071,
        -0.059723980724811554,
        -0.0006800249102525413,
        -0.017776617780327797,
        -0.05475516617298126,
        0.03401286527514458,
        -0.009557487443089485,
        -0.0022520001512020826,
        -0.0049963053315877914,
        0.036530934274196625,
        -0.03542002663016319,
        -0.05872393026947975,
        0.06003465875983238,
        0.04734739661216736,
        0.008380785584449768,
        0.05999959260225296,
        -0.04965996742248535,
        0.05280036851763725,
        0.00035039029899053276,
        0.05309177562594414,
        0.027144283056259155,
        -0.020738182589411736,
        -0.058720912784338,
        0.03952905163168907,
        0.007873931899666786,
        -0.06044100970029831,
        -0.02186942845582962,
        -0.05919643118977547,
        -0.0058713010512292385,
        0.02864391729235649,
        -0.06030718982219696,
        -0.05862590670585632,
        0.014077025465667248,
        -0.054747048765420914,
        0.05345775559544563,
        -0.059315335005521774,
        -0.04537041857838631,
        0.028622962534427643,
        0.03304465860128403,
        -0.04806661233305931,
        0.05828395485877991,
        -0.0028367540799081326,
        0.055192429572343826,
        -0.04482071474194527,
        -0.0012833817163482308,
        0.017825165763497353,
        0.0042630587704479694,
        0.05900736525654793,
        0.03043835237622261,
        -0.05993281677365303,
        0.05640355870127678,
        0.02770853228867054,
        0.025687281042337418,
        0.059851109981536865,
        -0.028026213869452477,
        0.058626867830753326,
        -0.03799901530146599,
        0.004596846643835306,
        0.017166921868920326,
        -0.05068974569439888,
        0.06044796109199524,
        0.011249758303165436,
        0.05029749125242233,
        0.05754042789340019,
        -0.030181456357240677,
        0.059646133333444595,
        -0.057883139699697495,
        0.04003921523690224,
        -0.024497203528881073,
        -0.055270034819841385,
        -0.05542435869574547,
        0.058997370302677155,
        -0.05984949693083763,
        -0.029652519151568413,
        -0.031006284058094025,
        -0.046852849423885345,
        0.05768175050616264,
        0.007781909313052893,
        -0.012537036091089249,
        -0.021719248965382576,
        -0.027568653225898743,
        -0.0574311725795269,
        0.06036403775215149,
        0.04459817707538605,
        0.047665514051914215,
        -0.060242462903261185,
        -0.023819226771593094,
        -0.05432232469320297,
        0.05884280800819397,
        -0.048203542828559875,
        0.046075038611888885,
        0.05911726877093315,
        0.0074235424399375916,
        0.06028847396373749,
        0.02352173626422882,
        -0.055219560861587524,
        -0.005176915321499109,
        0.009250983595848083,
        -0.060303207486867905,
        -0.016509417444467545,
        0.06039435788989067,
        -0.008848090656101704,
        0.05231177806854248,
        -0.05400688946247101,
        -0.0467243418097496,
        -0.05434989184141159,
        0.03829702362418175,
        0.0438048392534256,
        0.020553862676024437,
        0.020915471017360687,
        -0.05477670207619667,
        0.05683940276503563,
        0.059588175266981125,
        -0.0471990630030632,
        0.004842428490519524,
        -0.06019095703959465,
        -0.0272059366106987,
        0.04602335765957832,
        0.0067741465754806995,
        0.0572923943400383,
        -0.05873088166117668,
        -0.011061778292059898,
        0.055539507418870926,
        0.003947702702134848,
        0.05525761842727661,
        -0.059154216200113297,
        0.05727285146713257,
        -0.047307029366493225,
        -0.041534554213285446,
        -0.004942096304148436,
        0.037544120103120804,
        -0.04637448862195015,
        -0.056856971234083176,
        -0.04998726397752762,
        0.04456625133752823,
        0.04789242893457413,
        -0.06023244187235832,
        0.056758999824523926,
        -0.010281138122081757,
        -0.05883694440126419,
        0.04865018278360367,
        -0.02255242131650448,
        -0.05932983011007309,
        -0.05009879171848297,
        -0.05983966216444969,
        -0.040936268866062164,
        0.045498985797166824,
        0.005744279827922583,
        0.016372036188840866,
        0.05721478536725044,
        0.011850940063595772,
        0.02635563351213932,
        -0.04542572423815727,
        -0.006043826229870319,
        0.013040618970990181,
        -0.05424550920724869,
        0.011261839419603348,
        -0.03272072598338127,
        0.02746611461043358,
        0.053508348762989044,
        -0.04262205585837364,
        -0.02104414626955986,
        0.060291241854429245,
        -0.051963478326797485,
        0.055810581892728806,
        -0.05330270528793335,
        -0.05211195349693298,
        0.054259538650512695,
        0.029988814145326614,
        -0.05311119928956032,
        0.0011149793863296509,
        -0.05914648622274399,
        -0.05765652284026146,
        -0.014971516095101833,
        -0.04995876923203468,
        0.058409739285707474,
        0.023126186802983284,
        -0.003288318170234561,
        -0.05968637764453888,
        -0.0526919849216938,
        0.05975161865353584,
        0.06022562086582184,
        -0.059940584003925323,
        0.024879762902855873,
        0.00874402467161417,
        -0.06028137728571892,
        0.05232672393321991,
        0.05228491872549057,
        -0.0021781381219625473,
        0.05847695842385292,
        0.04888128489255905,
        -0.025283781811594963,
        0.008628003299236298,
        0.03905388340353966,
        -0.011489368975162506,
        -0.052073899656534195,
        -0.05012340471148491,
        0.004786212928593159,
        0.027566242963075638,
        0.05980472266674042,
        0.03428683057427406,
        0.047549501061439514,
        0.05728999897837639,
        -0.01677052490413189,
        -0.05754914507269859,
        -0.038358282297849655,
        -0.04254202917218208,
        -0.05995642766356468,
        0.031388767063617706,
        0.04615482687950134,
        0.05466073006391525,
        0.010759079828858376,
        -0.04376943036913872,
        -0.0494258776307106,
        0.05973877012729645,
        0.0532044880092144,
        -0.059805307537317276,
        -0.04126362502574921,
        0.018904713913798332,
        -0.031732913106679916,
        0.048796553164720535,
        0.031237594783306122,
        0.045711107552051544,
        0.033532992005348206,
        -0.038595572113990784,
        -0.0591275617480278,
        0.06031179055571556,
        0.05949894338846207
    ],
    [
        0.049715615808963776,
        -0.0040743062272667885,
        -0.041920602321624756,
        0.024069374427199364,
        0.049403317272663116,
        -0.0421704538166523,
        0.03424428403377533,
        -0.02454407513141632,
        0.057467907667160034,
        -0.05497550219297409,
        0.041898779571056366,
        0.05318101495504379,
        -0.05877554789185524,
        0.028498511761426926,
        -0.05842430517077446,
        0.05723428353667259,
        -0.058544836938381195,
        -0.006199654191732407,
        -0.055828459560871124,
        -0.040352609008550644,
        0.05659989267587662,
        -0.009382129646837711,
        0.054872628301382065,
        -0.05693012475967407,
        -0.007185904774814844,
        -0.01976732350885868,
        0.01686832495033741,
        0.057227954268455505,
        -0.018168050795793533,
        0.05378950387239456,
        0.05856744199991226,
        0.0023584384471178055,
        0.04993082210421562,
        -0.058781955391168594,
        0.007517872378230095,
        -0.012777892872691154,
        -0.057342249900102615,
        -0.0357389934360981,
        -0.020352553576231003,
        0.05558805540204048,
        0.022678682580590248,
        0.055579774081707,
        0.053867876529693604,
        -0.035072360187768936,
        0.034237977117300034,
        -0.0400826670229435,
        0.05486951023340225,
        0.057665977627038956,
        0.05907529965043068,
        -0.035614628344774246,
        0.0021331338211894035,
        0.005677931942045689,
        0.05395166948437691,
        -0.02176543138921261,
        0.043900419026613235,
        0.015177207067608833,
        -0.05545809864997864,
        0.0056884195655584335,
        0.04948095977306366,
        -0.022694287821650505,
        -0.0428563691675663,
        -0.036152686923742294,
        -0.044785503298044205,
        0.04301345348358154,
        0.002151261316612363,
        0.037528377026319504,
        0.013174900785088539,
        -0.028619471937417984,
        -0.02312101051211357,
        -0.059155043214559555,
        -0.058582719415426254,
        -0.05874182656407356,
        -0.024541987106204033,
        0.02722724713385105,
        -0.05592476576566696,
        -0.022453246638178825,
        -0.0032563204877078533,
        0.059144023805856705,
        0.05333348736166954,
        -0.005846897140145302,
        0.004019593354314566,
        0.057748302817344666,
        -0.058499641716480255,
        0.00981076154857874,
        0.057009585201740265,
        0.044798120856285095,
        -0.03702200576663017,
        0.004276384133845568,
        0.030538111925125122,
        0.057613126933574677,
        -0.05586140230298042,
        0.04969468340277672,
        0.011600546538829803,
        -0.05714957043528557,
        -0.02298525534570217,
        0.008648620918393135,
        -0.008175441063940525,
        -0.05155141279101372,
        -0.05462023615837097,
        0.045721087604761124,
        -0.05847863852977753,
        -0.04527959227561951,
        0.057534560561180115,
        -0.017518196254968643,
        -0.0460379533469677,
        -0.02651527151465416,
        -0.05139259248971939,
        0.029279544949531555,
        -0.05114779248833656,
        0.008925288915634155,
        -0.05517532676458359,
        0.009198328480124474,
        -0.020740415900945663,
        0.03772502765059471,
        -0.012869630008935928,
        -0.058439891785383224,
        0.058349426835775375,
        -0.047290924936532974,
        -0.057376790791749954,
        0.020001959055662155,
        0.0012197871692478657,
        -0.04101085662841797,
        -0.01244115550071001,
        0.024161402136087418,
        -0.058784279972314835,
        0.04836278408765793,
        -0.03153575584292412,
        0.03540617972612381,
        0.04948937147855759,
        0.034101031720638275,
        -0.0014128483599051833,
        -0.031164631247520447,
        -0.047199223190546036,
        0.023460153490304947,
        -0.05630066618323326,
        -0.05695272609591484,
        -0.02863016352057457,
        0.0014249413507059216,
        -0.05459738150238991,
        0.05801934003829956,
        0.04500659555196762,
        0.05877777561545372,
        0.04548558220267296,
        0.053276654332876205,
        0.01358454953879118,
        -0.023074114695191383,
        0.05636731907725334,
        -0.044386763125658035,
        0.059138279408216476,
        0.05913494899868965,
        -0.03692173585295677,
        -0.05909649655222893,
        -0.05834100395441055,
        0.014599589630961418,
        -0.03051440790295601,
        -0.038518570363521576,
        0.05781398341059685,
        -0.011279482394456863,
        0.028933962807059288,
        0.04810105264186859,
        0.05652789771556854,
        0.049395252019166946,
        0.03609634190797806,
        -0.03120257705450058,
        -0.006313159130513668,
        -0.024295562878251076,
        0.05201194807887077,
        -0.058413032442331314,
        0.008256292901933193,
        0.04998168349266052,
        -0.01116965338587761,
        -0.05815150588750839,
        -0.05745786428451538,
        -0.0025105152744799852,
        -0.0440514050424099,
        0.05906705930829048,
        -0.05915570631623268,
        0.033527638763189316,
        0.02476360835134983,
        -0.025434160605072975,
        -0.007629178464412689,
        0.04359263554215431,
        0.04866427183151245,
        -0.04516409710049629,
        0.03269549086689949,
        0.0011971788480877876,
        -0.045415524393320084,
        0.05737009644508362,
        -0.026619713753461838,
        -0.011814005672931671,
        -0.017718276008963585,
        -0.02653760276734829,
        -0.018353601917624474,
        -0.05152115225791931,
        -0.05601518973708153,
        0.029583685100078583,
        0.04978662356734276,
        0.037834931164979935,
        0.01080411672592163,
        0.05656686797738075,
        0.05012914165854454,
        -0.05872079357504845,
        0.004141383338719606,
        -0.03430556505918503,
        0.02771844156086445,
        0.023249424993991852,
        0.0565677136182785,
        -0.05655202269554138,
        -0.050121042877435684,
        -0.008749271742999554,
        -0.05303702875971794,
        0.057632554322481155,
        -0.025398530066013336,
        -0.024935031309723854,
        -0.053010813891887665,
        0.037944547832012177,
        0.057863716036081314,
        0.026511890813708305,
        0.05885427072644234,
        -0.058652207255363464,
        0.031024273484945297,
        -0.048367295414209366,
        -0.057107266038656235,
        -0.05832834914326668,
        0.056041307747364044,
        -0.058292780071496964,
        -0.04984140768647194,
        0.040453147143125534,
        -0.04019889608025551,
        -0.05537717789411545,
        -0.03742162883281708,
        -0.05894351378083229,
        -0.020362820476293564,
        0.058435212820768356,
        -0.04593270272016525,
        0.057937249541282654,
        0.05747595801949501,
        -0.007800131104886532,
        -0.04548967629671097,
        0.05870775133371353,
        0.04804910346865654,
        0.05727267637848854,
        0.04820740222930908,
        0.0533415861427784,
        -0.038617122918367386,
        0.021701231598854065,
        0.00533594423905015,
        0.058582473546266556,
        -0.05113641545176506,
        -0.015979791060090065,
        -0.05619952455163002,
        -0.05857895314693451,
        -0.04450288787484169,
        -0.05703943967819214,
        0.01787262037396431,
        0.057526882737874985,
        -0.02375909499824047,
        -0.05357649177312851,
        0.059050895273685455,
        0.04960149899125099,
        0.05555418133735657,
        0.05469944328069687,
        -0.05453024059534073,
        0.058672066777944565,
        0.05894137918949127,
        0.04986884444952011,
        0.057671982795000076,
        0.046258363872766495,
        0.049528881907463074,
        -0.0569896325469017,
        -0.041951391845941544,
        -0.059077806770801544,
        -0.013470909558236599,
        -0.059050288051366806,
        0.017364608123898506,
        0.04850093275308609,
        -0.056677550077438354,
        0.052595000714063644,
        -0.05916322395205498,
        -0.05590299516916275,
        -0.05677109956741333,
        0.034737616777420044,
        -0.02093256264925003,
        0.05890197306871414,
        0.059148918837308884,
        -0.05267737805843353,
        0.02833019755780697,
        -0.03379784896969795,
        -0.04471917077898979,
        0.012804033234715462,
        -0.053570233285427094,
        0.051524266600608826,
        0.05136643722653389,
        0.028715617954730988,
        0.0051990472711622715,
        -0.04867542162537575,
        0.05881312116980553,
        0.030136410146951675,
        0.03558172285556793,
        0.05587553232908249,
        -0.057735517621040344,
        0.05586123839020729,
        0.02865382842719555,
        0.04349217191338539,
        -0.04056669399142265,
        -0.025974638760089874,
        -0.057346444576978683,
        0.04956679791212082,
        0.05214240774512291,
        -0.01914525404572487,
        0.006590284872800112,
        -0.05793201923370361,
        -0.055503614246845245,
        -0.029524540528655052,
        -0.05789792910218239,
        -0.05720166489481926,
        0.051050059497356415,
        -0.057393722236156464,
        0.016524652019143105,
        -0.0589820072054863,
        0.012486348859965801,
        -0.054859671741724014,
        0.048753004521131516,
        -0.05763191357254982,
        -0.02266111969947815,
        0.02606721967458725,
        -0.04898223653435707,
        -0.0010460492921993136,
        -0.016138793900609016,
        0.0253039188683033,
        0.05804254487156868,
        0.02806949056684971,
        -0.03686125949025154,
        -0.05266952887177467,
        0.053726986050605774,
        0.013548243790864944,
        0.033317118883132935,
        0.058310288935899734,
        -0.052784547209739685,
        0.03438978269696236,
        -0.03973069414496422,
        -0.028045788407325745,
        -0.048156578093767166,
        -0.018426258116960526,
        0.059130337089300156,
        -0.016427326947450638,
        0.04480070620775223,
        0.055936988443136215,
        -0.04449908435344696,
        0.05780730023980141,
        -0.05887975916266441,
        0.05654633417725563,
        -0.04979042336344719,
        -0.05678080767393112,
        0.007993190549314022,
        0.05735708400607109,
        -0.057791419327259064,
        -0.04614826291799545,
        -0.051840782165527344,
        -0.05196502432227135,
        0.05035782232880592,
        0.04580802842974663,
        -0.056739699095487595,
        0.02039737068116665,
        -0.037410419434309006,
        -0.05795927345752716,
        0.059096839278936386,
        -0.031065771356225014,
        0.05871550366282463,
        -0.05916218459606171,
        -0.022082064300775528,
        -0.0565105676651001,
        0.059138573706150055,
        0.043043334037065506,
        0.054276369512081146,
        0.04665497690439224,
        0.04599199444055557,
        0.046752020716667175,
        -0.02995435521006584,
        -0.05447206646203995,
        0.050927527248859406,
        0.024020472541451454,
        -0.058906883001327515,
        -0.0012797311646863818,
        0.02394104190170765,
        0.003758610924705863,
        0.057322993874549866,
        -0.0557577908039093,
        -0.02781960740685463,
        -0.025940705090761185,
        -0.026893015950918198,
        0.053632766008377075,
        -0.020075850188732147,
        0.0018141103209927678,
        -0.02269020490348339,
        0.05632033199071884,
        0.05398016795516014,
        -0.054970115423202515,
        -0.056185003370046616,
        -0.05899888649582863,
        -0.04927899315953255,
        0.05753286927938461,
        0.0296489167958498,
        0.04522499069571495,
        0.016544874757528305,
        -0.009926372207701206,
        0.046172622591257095,
        -0.04777832701802254,
        0.056714318692684174,
        -0.053358882665634155,
        0.05422452092170715,
        0.04204084351658821,
        -0.05660957470536232,
        -0.014494523406028748,
        0.0548582598567009,
        0.04333578422665596,
        0.0014159706188365817,
        0.054103460162878036,
        0.01806746982038021,
        0.0019321390427649021,
        -0.02991233393549919,
        0.05103885382413864,
        -0.01799413189291954,
        -0.05620957911014557,
        0.05422626808285713,
        0.019220586866140366,
        -0.05796050280332565,
        -0.012277430854737759,
        -0.05539000406861305,
        -0.05834782123565674,
        0.05523807927966118,
        -0.005744126159697771,
        0.010005850344896317,
        0.03397487476468086,
        0.031258296221494675,
        -0.020532025024294853,
        -0.055842965841293335,
        -0.009922453202307224,
        -0.037893395870923996,
        -0.05529581010341644,
        -0.025810522958636284,
        0.015741929411888123,
        0.004148558713495731,
        0.056497663259506226,
        -0.004656670615077019,
        -0.012740370817482471,
        0.058875828981399536,
        -0.058697257190942764,
        0.05445045605301857,
        -0.054351795464754105,
        -0.05826667323708534,
        0.05725923925638199,
        0.01865972764790058,
        -0.01389381568878889,
        0.05524730682373047,
        -0.04370801895856857,
        -0.022068722173571587,
        0.023550910875201225,
        -0.05869271978735924,
        0.05764486640691757,
        0.025273924693465233,
        0.03455469384789467,
        -0.00993601605296135,
        0.031130196526646614,
        0.058705661445856094,
        0.05874595418572426,
        -0.05072571709752083,
        0.05123206600546837,
        0.05491210147738457,
        -0.058504074811935425,
        0.04867355152964592,
        0.04857655614614487,
        0.036375101655721664,
        0.05911095812916756,
        0.055181700736284256,
        0.05522774159908295,
        -0.05540941283106804,
        0.04835447669029236,
        -0.05265352502465248,
        -0.05342849716544151,
        -0.027732325717806816,
        0.024888720363378525,
        0.047908879816532135,
        0.05390661209821701,
        0.03870268911123276,
        -0.046700164675712585,
        0.04652192443609238,
        0.019086867570877075,
        -0.05354125425219536,
        -0.05475578457117081,
        -0.04037315770983696,
        -0.05610351637005806,
        -0.05760861933231354,
        0.057458460330963135,
        0.05781058967113495,
        0.03275453299283981,
        -0.04853258654475212,
        0.05740823224186897,
        0.05649593845009804,
        0.018650099635124207,
        -0.029018230736255646,
        -0.046202871948480606,
        0.019456982612609863,
        -0.019470645114779472,
        -0.046536821871995926,
        0.040762368589639664,
        0.05014430731534958,
        -0.016091711819171906,
        -0.05452044680714607,
        -0.05912526696920395,
        0.05857973173260689,
        0.05912685766816139
    ],
    [
        -0.05844786763191223,
        0.024986397475004196,
        -0.002760673873126507,
        0.01624062843620777,
        0.05278860405087471,
        -0.050944484770298004,
        0.05836302414536476,
        -0.04095159471035004,
        0.05501013621687889,
        0.05783206224441528,
        0.04840546473860741,
        0.030800659209489822,
        -0.056746482849121094,
        0.009365158155560493,
        -0.057802118360996246,
        0.05673721060156822,
        -0.05785299465060234,
        0.04703657329082489,
        -0.05646378919482231,
        -0.008982930332422256,
        -0.015414017252624035,
        0.019245872274041176,
        0.03759395703673363,
        -0.01856323890388012,
        -0.030734920874238014,
        0.05168694257736206,
        0.001283192541450262,
        0.04948953539133072,
        -0.05202563479542732,
        -0.054891038686037064,
        0.04612291231751442,
        -0.028941860422492027,
        0.0406070314347744,
        -0.05805183947086334,
        0.048611197620630264,
        -0.024765590205788612,
        -0.05610289052128792,
        -0.0540650375187397,
        -0.04830663278698921,
        0.024321241304278374,
        -0.057314127683639526,
        0.012856527231633663,
        0.04783947765827179,
        -0.039284300059080124,
        0.0370892696082592,
        -0.04878264293074608,
        0.05696794018149376,
        0.05541851371526718,
        0.058576565235853195,
        0.01071443222463131,
        0.019458383321762085,
        -0.03828132897615433,
        0.015145920217037201,
        -0.040368225425481796,
        0.008279507979750633,
        -0.05418220907449722,
        -0.04829976335167885,
        -0.006237363442778587,
        -0.006124440114945173,
        -0.023851381614804268,
        -0.054665956646203995,
        -0.041361093521118164,
        -0.053747158497571945,
        0.015572657808661461,
        0.01639963872730732,
        0.05341330170631409,
        0.02981969341635704,
        -0.030453786253929138,
        -0.029090873897075653,
        -0.05851699039340019,
        -0.057686783373355865,
        -0.05731034651398659,
        0.05094984546303749,
        0.020331304520368576,
        -0.023907911032438278,
        0.027790091931819916,
        0.01403886079788208,
        0.05861728638410568,
        0.052632592618465424,
        -0.0041788313537836075,
        -0.01575532741844654,
        0.03687930479645729,
        -0.05089263617992401,
        0.042980920523405075,
        0.037985995411872864,
        0.03919852152466774,
        -0.032639291137456894,
        -0.03615507856011391,
        0.04979139566421509,
        0.05851556360721588,
        -0.04437459260225296,
        0.057018622756004333,
        0.011050100438296795,
        0.003463146509602666,
        -0.0349946990609169,
        -0.01978946477174759,
        0.006309175863862038,
        -0.05348281189799309,
        -0.03488694876432419,
        0.037526000291109085,
        -0.058356139808893204,
        -0.027497051283717155,
        0.04570874199271202,
        -0.05791293829679489,
        0.05336857587099075,
        -0.05228262394666672,
        -0.05552322790026665,
        0.05743473768234253,
        -0.05680553987622261,
        -0.05689425766468048,
        -0.05302499607205391,
        -0.05808253586292267,
        -0.05346197262406349,
        0.016744792461395264,
        0.013661581091582775,
        -0.04564402997493744,
        0.05768271163105965,
        -0.03501518443226814,
        -0.05809697136282921,
        -0.054268356412649155,
        -0.01517314650118351,
        -0.04255504906177521,
        -0.05212109535932541,
        -0.015471894294023514,
        -0.057809583842754364,
        -0.05677475780248642,
        0.03058743104338646,
        0.04543546587228775,
        0.05392245948314667,
        -0.004084530286490917,
        0.0001132126126321964,
        -0.010003064759075642,
        -0.05746358260512352,
        0.038311246782541275,
        -0.05611170083284378,
        -0.053341496735811234,
        0.03883226960897446,
        -0.05799185857176781,
        -0.05615090951323509,
        0.05433852598071098,
        0.05565818026661873,
        -0.050575822591781616,
        0.04161558672785759,
        0.02330794185400009,
        -0.002039222978055477,
        -0.014394396916031837,
        0.054491832852363586,
        -0.03931422904133797,
        0.05566306412220001,
        0.037600237876176834,
        -0.03759317845106125,
        -0.05863913148641586,
        -0.05457637459039688,
        -0.0032490056473761797,
        -0.009983093477785587,
        -0.05778341367840767,
        0.05780841410160065,
        0.0022788522765040398,
        0.03687075898051262,
        0.03999963775277138,
        0.04887944087386131,
        0.05762658268213272,
        0.04162992909550667,
        -0.0354042574763298,
        -0.04453931748867035,
        -0.03529208153486252,
        0.05670333653688431,
        -0.05856677517294884,
        0.049360714852809906,
        0.02962394244968891,
        -0.0071855694986879826,
        -0.05856379494071007,
        -0.058197855949401855,
        0.024408305063843727,
        -0.04539482295513153,
        0.05693596228957176,
        -0.05842077359557152,
        0.008376572281122208,
        0.029659604653716087,
        -0.006986144930124283,
        0.051455751061439514,
        0.026449602097272873,
        0.05802860110998154,
        0.0280900951474905,
        0.02545284666121006,
        0.02186167985200882,
        -0.030055716633796692,
        0.05555605888366699,
        -0.03187812119722366,
        -0.04962419718503952,
        -0.05264415964484215,
        -0.053268369287252426,
        0.00935581885278225,
        0.05334547534584999,
        -0.013205171562731266,
        0.030574439093470573,
        0.05223160609602928,
        0.04127740114927292,
        0.004320358391851187,
        0.042270004749298096,
        -0.04359830915927887,
        0.05334052816033363,
        -0.001038240734487772,
        0.044469185173511505,
        0.05095917731523514,
        0.05626879259943962,
        0.04647304117679596,
        -0.058218613266944885,
        -0.054180875420570374,
        -0.030032942071557045,
        -0.0436546765267849,
        0.05765021964907646,
        -0.04392918199300766,
        -0.0038740374147892,
        0.051770567893981934,
        -0.02131493017077446,
        0.05309227481484413,
        -0.006273228209465742,
        0.05813857913017273,
        -0.03639734536409378,
        0.047410961240530014,
        0.050694789737463,
        -0.051160991191864014,
        -0.057838112115859985,
        0.05803834646940231,
        -0.057741470634937286,
        -0.04323543235659599,
        0.05577896535396576,
        -0.024504028260707855,
        -0.05660306289792061,
        -0.03915313258767128,
        -0.058174584060907364,
        -0.005274109076708555,
        0.04900142177939415,
        -0.033833444118499756,
        0.054793763905763626,
        0.05754146724939346,
        0.03168616443872452,
        -0.04889875277876854,
        0.05635533109307289,
        0.0465107262134552,
        0.03611352667212486,
        0.051029179245233536,
        0.058416593819856644,
        0.007631497457623482,
        0.05212780460715294,
        -0.032315630465745926,
        0.058411337435245514,
        0.00014028647274244577,
        -0.02516385354101658,
        -0.05010581016540527,
        -0.05818577855825424,
        0.020931100472807884,
        -0.057341236621141434,
        0.04457370191812515,
        0.05832614749670029,
        0.0031163289677351713,
        -0.043016429990530014,
        0.058348238468170166,
        0.05046412721276283,
        0.05620206147432327,
        0.05777126923203468,
        -0.05143013596534729,
        0.05812058597803116,
        0.015142871998250484,
        0.05261322483420372,
        0.0030680757481604815,
        0.041396792978048325,
        0.051583144813776016,
        -0.04769004136323929,
        -0.014519347809255123,
        -0.058247826993465424,
        -0.009330886416137218,
        -0.05818956717848778,
        -0.03305454179644585,
        0.007967269979417324,
        -0.05545458942651749,
        0.03251808136701584,
        -0.058654963970184326,
        -0.05686213821172714,
        -0.05643259361386299,
        -0.04545509070158005,
        0.023273229598999023,
        0.03206532821059227,
        0.05851634591817856,
        -0.05786712467670441,
        0.027091488242149353,
        -0.051440317183732986,
        -0.0569726787507534,
        0.05252793803811073,
        -0.011992319487035275,
        0.006289691664278507,
        0.03800923004746437,
        0.039733268320560455,
        -0.05229621380567551,
        -0.050742559134960175,
        0.05822748690843582,
        0.013910483568906784,
        -0.01740136370062828,
        0.058140892535448074,
        -0.03451542928814888,
        0.05243873596191406,
        0.030386656522750854,
        0.05210915207862854,
        -0.022881386801600456,
        -0.04834654927253723,
        -0.05202397331595421,
        0.0005885395221412182,
        0.04498450830578804,
        -0.058008432388305664,
        0.03017091564834118,
        -0.0556928776204586,
        -0.056039102375507355,
        -0.05588671565055847,
        -0.058632899075746536,
        -0.05640295147895813,
        0.051773540675640106,
        -0.056081511080265045,
        0.04079650714993477,
        -0.0581425242125988,
        -0.055334992706775665,
        0.0029194671660661697,
        0.052551597356796265,
        -0.05757720395922661,
        0.045566730201244354,
        0.03077084757387638,
        0.05767625570297241,
        0.03277096152305603,
        0.03660055994987488,
        -0.014479929581284523,
        0.054021261632442474,
        0.053349751979112625,
        -0.032523032277822495,
        -0.03313685208559036,
        0.056518424302339554,
        0.02906845323741436,
        -0.000451770523795858,
        0.058280155062675476,
        -0.03950090333819389,
        -0.026746325194835663,
        0.044624410569667816,
        -0.01748025044798851,
        0.033170394599437714,
        -0.05648007243871689,
        0.05865566059947014,
        0.007164385635405779,
        0.008184123784303665,
        0.0557842031121254,
        -0.039194077253341675,
        0.05846186727285385,
        -0.058138761669397354,
        0.05505255237221718,
        -0.016796376556158066,
        -0.04916965961456299,
        -0.0292899701744318,
        0.05723150819540024,
        -0.05864698812365532,
        -0.013240507803857327,
        -0.05824199691414833,
        -0.0500779002904892,
        0.05391082912683487,
        0.007502746768295765,
        -0.0528111606836319,
        0.0031018920708447695,
        0.04388803616166115,
        -0.0556926503777504,
        0.05857114493846893,
        0.0077301994897425175,
        0.035514816641807556,
        -0.05861174687743187,
        -0.03956161439418793,
        -0.05732211098074913,
        0.05710772052407265,
        0.012540341354906559,
        0.027228977531194687,
        0.05079766735434532,
        0.024753376841545105,
        0.027745673432946205,
        -0.037100665271282196,
        -0.05375974252820015,
        0.058177534490823746,
        0.03478548675775528,
        -0.058476388454437256,
        0.04450014606118202,
        0.04862791299819946,
        -0.05165700986981392,
        0.024680621922016144,
        -0.049244724214076996,
        -0.049172718077898026,
        -0.03692039102315903,
        -0.01788095012307167,
        -0.014102672226727009,
        0.02022181637585163,
        0.009246766567230225,
        -0.0563257671892643,
        0.057247914373874664,
        0.05663374811410904,
        -0.04161034896969795,
        -0.04916447401046753,
        -0.058625224977731705,
        -0.03812626749277115,
        0.05236741527915001,
        0.0482342392206192,
        0.05708158016204834,
        -0.057460956275463104,
        -0.007777551654726267,
        0.051570452749729156,
        -0.025821974501013756,
        0.05767775699496269,
        -0.037879686802625656,
        0.05109529569745064,
        0.018544791266322136,
        -0.05832676216959953,
        0.00042249562102369964,
        0.03722693398594856,
        0.00708599342033267,
        -0.0421622060239315,
        0.024121033027768135,
        0.03638553246855736,
        0.041920606046915054,
        -0.05107816308736801,
        -0.05028662458062172,
        -0.042459312826395035,
        -0.05787632241845131,
        0.05174746736884117,
        0.034072961658239365,
        -0.053300581872463226,
        -0.027793480083346367,
        -0.05580044165253639,
        -0.054862141609191895,
        0.055146474391222,
        0.012375924736261368,
        0.0569799467921257,
        0.053138066083192825,
        0.038561366498470306,
        0.025362759828567505,
        -0.05132364481687546,
        0.032642245292663574,
        -0.005854321643710136,
        -0.05684973672032356,
        -0.04643277823925018,
        0.03045615740120411,
        0.04572921618819237,
        0.05242199823260307,
        -0.03224804997444153,
        0.03564229980111122,
        0.05837886407971382,
        -0.05608433112502098,
        0.051494114100933075,
        -0.058057911694049835,
        0.008748734369874,
        0.05727154389023781,
        0.013593659736216068,
        -0.010016156360507011,
        -0.0027946089394390583,
        -0.043229106813669205,
        -0.013871381059288979,
        0.014061720110476017,
        -0.05827854946255684,
        0.05427299439907074,
        0.03938603773713112,
        -0.04862402752041817,
        0.04329715669155121,
        -0.05675157159566879,
        0.055940672755241394,
        0.058558396995067596,
        -0.05796092376112938,
        0.05144859477877617,
        -0.033976346254348755,
        -0.058546993881464005,
        0.056302543729543686,
        -0.05499984696507454,
        0.022979194298386574,
        0.05778972804546356,
        0.05819198116660118,
        0.054622832685709,
        -0.02381778694689274,
        0.044977348297834396,
        -0.04715874046087265,
        -0.045354295521974564,
        -0.0005391643499024212,
        0.036494702100753784,
        0.056484371423721313,
        0.05665597319602966,
        0.04401198774576187,
        0.03478012979030609,
        0.054165489971637726,
        0.0125499926507473,
        -0.05663728713989258,
        -0.03853623941540718,
        -0.05071239173412323,
        -0.05843614786863327,
        -0.056283801794052124,
        0.05259738489985466,
        0.05490001291036606,
        0.034731827676296234,
        -0.057771433144807816,
        -0.02708517201244831,
        0.057749323546886444,
        0.005254549439996481,
        -0.05505262687802315,
        -0.016536284238100052,
        0.04156416654586792,
        0.004041194450110197,
        0.012207746505737305,
        0.044052038341760635,
        -0.04511832073330879,
        0.03566728159785271,
        -0.04935023561120033,
        -0.04867977648973465,
        0.05841594189405441,
        0.057669300585985184
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.014797677285969257,
        0.028563598170876503,
        0.05349664017558098,
        0.04617543891072273,
        -0.015692219138145447,
        -0.012873033061623573,
        -0.03481367602944374,
        -0.06052067130804062,
        0.06055467203259468,
        0.027819227427244186,
        0.0243797916918993,
        0.038061752915382385,
        -0.05047917366027832,
        -0.031331855803728104,
        -0.058712102472782135,
        0.06113850325345993,
        -0.00977380946278572,
        0.05770101398229599,
        -0.05559840053319931,
        -0.05428138002753258,
        0.05490827560424805,
        0.05853227525949478,
        0.03255276009440422,
        -0.04457306116819382,
        0.026628365740180016,
        0.047973815351724625,
        0.05591736361384392,
        -0.017468847334384918,
        0.018099091947078705,
        -0.0037828292697668076,
        0.05369710922241211,
        -0.004107336048036814,
        -0.04287862032651901,
        -0.06111890450119972,
        0.05156184732913971,
        0.02674415148794651,
        -0.05992303416132927,
        -0.049095068126916885,
        -0.048643067479133606,
        -0.04619915038347244,
        0.04790332913398743,
        0.03761037811636925,
        -0.004389029461890459,
        -0.05795038491487503,
        0.01981581375002861,
        0.05826616659760475,
        0.017435457557439804,
        0.04195789992809296,
        0.05968167260289192,
        0.0010295663960278034,
        0.056059516966342926,
        0.061102647334337234,
        -0.05136526748538017,
        0.03410593420267105,
        0.043630201369524,
        -0.0458989255130291,
        -0.01599889062345028,
        0.018037334084510803,
        -0.0006475343834608793,
        -0.045885540544986725,
        -0.057544492185115814,
        0.04615522176027298,
        -0.04838227853178978,
        0.06079154834151268,
        -0.05880976840853691,
        -0.031448278576135635,
        -0.03937411308288574,
        0.021282324567437172,
        -0.05950348451733589,
        0.04081552475690842,
        -0.050674255937337875,
        -0.06124401465058327,
        0.060865338891744614,
        0.019945582374930382,
        0.05264562368392944,
        -0.04444843530654907,
        0.020959168672561646,
        0.0614757314324379,
        -0.015759609639644623,
        -0.013393554836511612,
        0.02668990008533001,
        0.05448530614376068,
        -0.04189393296837807,
        0.04788099229335785,
        0.05800601467490196,
        0.05184723436832428,
        0.05500553920865059,
        -0.04231684282422066,
        0.041404083371162415,
        0.015862727537751198,
        0.027422381564974785,
        0.055155638605356216,
        -0.06067756563425064,
        -0.023821067065000534,
        -0.04237847775220871,
        0.0578446201980114,
        0.027071628719568253,
        -0.058946724981069565,
        0.05256575345993042,
        -0.01535267848521471,
        -0.05989702045917511,
        0.03071841038763523,
        0.04495133459568024,
        0.03103368729352951,
        0.05352459475398064,
        -0.06030752882361412,
        -0.05020888149738312,
        0.0583154633641243,
        -0.04411311075091362,
        0.051744308322668076,
        -0.06047426536679268,
        -0.019780559465289116,
        -0.0604640394449234,
        0.01387039478868246,
        -0.022341586649417877,
        -0.018877452239394188,
        0.05114632844924927,
        -0.022985465824604034,
        0.06111040711402893,
        0.043151311576366425,
        -0.03533937409520149,
        0.06031306833028793,
        0.03268425539135933,
        -0.04734697565436363,
        -0.051516856998205185,
        -0.04674604535102844,
        -0.05834048241376877,
        -0.05248207598924637,
        0.05835477635264397,
        0.024498309940099716,
        0.023613227531313896,
        -0.051672738045454025,
        -0.03287389129400253,
        0.03983041271567345,
        -0.057637277990579605,
        -0.05894133448600769,
        0.045154932886362076,
        0.058016080409288406,
        -0.060612794011831284,
        0.04454340413212776,
        0.058451563119888306,
        -0.005138134118169546,
        0.05234905704855919,
        -0.006294251885265112,
        0.05400626361370087,
        0.027131399139761925,
        0.05742120370268822,
        -0.047131408005952835,
        -0.011671190150082111,
        0.05898239463567734,
        0.05981333553791046,
        -0.060715578496456146,
        0.009051412343978882,
        -0.06014833226799965,
        0.05110221356153488,
        -0.05898810923099518,
        -0.013377954252064228,
        0.023944653570652008,
        0.034083060920238495,
        0.04471525922417641,
        0.055323634296655655,
        0.03332480043172836,
        0.051887571811676025,
        0.04299482703208923,
        0.03997577726840973,
        0.003300735028460622,
        0.010902190580964088,
        0.05394262075424194,
        0.03838464990258217,
        0.029226306825876236,
        -0.016684358939528465,
        0.043310776352882385,
        -0.052924878895282745,
        -0.0572606585919857,
        0.03104676678776741,
        0.060480982065200806,
        -0.060580115765333176,
        -0.013201919384300709,
        0.05517451837658882,
        -0.05343077704310417,
        -0.02383562922477722,
        0.061195023357868195,
        -0.01897498220205307,
        0.03510196879506111,
        0.038415346294641495,
        0.008220911026000977,
        -0.05368692800402641,
        0.057535454630851746,
        -0.048012129962444305,
        -0.040283482521772385,
        -0.04602836072444916,
        0.0029068661388009787,
        -0.014479625970125198,
        -0.04063991829752922,
        -0.04689926654100418,
        0.04439310356974602,
        -0.01596805825829506,
        -0.004155602306127548,
        -0.03812292590737343,
        0.013216829858720303,
        0.04972510412335396,
        -0.03899698704481125,
        0.029266271740198135,
        0.0084103187546134,
        -0.04304427653551102,
        0.04334200173616409,
        0.02423238195478916,
        0.039745431393384933,
        -0.05350581929087639,
        0.04547838494181633,
        -0.054975274950265884,
        0.05542067810893059,
        0.005141858477145433,
        0.013868092559278011,
        -0.05309990793466568,
        0.0487985797226429,
        0.058862023055553436,
        -0.004388747271150351,
        0.06038985401391983,
        -0.0006084802444092929,
        0.0013900226913392544,
        0.06026766821742058,
        -0.05975543335080147,
        -0.03384940326213837,
        0.05886439234018326,
        -0.022775953635573387,
        0.018863901495933533,
        0.02955648861825466,
        0.025545759126544,
        -0.05916868895292282,
        0.037066664546728134,
        -0.05666501820087433,
        -0.012556762434542179,
        0.04777985066175461,
        -0.040268491953611374,
        0.06149180233478546,
        0.05827442184090614,
        -0.034014757722616196,
        -0.022718332707881927,
        0.061384014785289764,
        -0.03425339236855507,
        0.060889843851327896,
        -0.061346810311079025,
        0.057410310953855515,
        0.03989230468869209,
        -0.007869115099310875,
        -0.06007586047053337,
        0.04223424196243286,
        -0.027734357863664627,
        -0.041497327387332916,
        -0.050285857170820236,
        -0.05984555929899216,
        0.05016445741057396,
        -0.05955016240477562,
        -0.05946970358490944,
        0.06027382239699364,
        -0.05774400755763054,
        -0.05586358159780502,
        0.02450958453118801,
        0.02055436000227928,
        0.06102833151817322,
        -0.04712550714612007,
        -0.04960921034216881,
        0.02023933082818985,
        0.03136570006608963,
        0.04827440530061722,
        0.0173273254185915,
        0.01977764628827572,
        -0.03954407200217247,
        0.046520620584487915,
        0.05119901895523071,
        -0.05642881244421005,
        0.06111317127943039,
        -0.038595542311668396,
        -0.05440675839781761,
        0.008147475309669971,
        -0.048575207591056824,
        -0.009904942475259304,
        -0.02409408614039421,
        -0.029033292084932327,
        -0.060224469751119614,
        -0.03933592885732651,
        -0.061273157596588135,
        0.05880418419837952,
        0.02459569275379181,
        -0.04381827265024185,
        0.010038327425718307,
        0.05396778881549835,
        0.05737023800611496,
        0.04901643097400665,
        -0.013259385712444782,
        0.05631415173411369,
        -0.016284659504890442,
        0.03801873326301575,
        0.05922722816467285,
        -0.06009262427687645,
        0.05797189474105835,
        0.059163860976696014,
        -0.05894514173269272,
        0.05770217254757881,
        0.05960848927497864,
        0.04867437481880188,
        -0.023590773344039917,
        0.05582030117511749,
        -0.01195248682051897,
        0.04873506352305412,
        -0.04308677464723587,
        0.04727692902088165,
        -0.014389554969966412,
        -0.05942912399768829,
        0.04072403162717819,
        -0.003974673338234425,
        -0.016777725890278816,
        0.03520318493247032,
        0.0039905221201479435,
        -0.05718778818845749,
        -0.0061303782276809216,
        -0.038640253245830536,
        0.039923522621393204,
        -0.028805801644921303,
        -0.0318879559636116,
        -0.03140407055616379,
        0.03703715652227402,
        0.04661085084080696,
        0.05793070048093796,
        -0.05407770723104477,
        -0.028289105743169785,
        0.01505750510841608,
        0.001103286980651319,
        -0.0574696846306324,
        0.00409693131223321,
        -0.05339048057794571,
        -0.027980640530586243,
        -0.05115823075175285,
        0.058003827929496765,
        0.040395498275756836,
        -0.05039825662970543,
        0.0566861554980278,
        -0.014367997646331787,
        0.030328501015901566,
        -0.04019620269536972,
        0.05651830509305,
        -0.061255257576704025,
        -0.047931354492902756,
        0.05564320459961891,
        0.02642175555229187,
        0.033920224756002426,
        0.059720635414123535,
        -0.03600824996829033,
        0.060108453035354614,
        0.057143282145261765,
        0.019278937950730324,
        -0.029665952548384666,
        -0.023524297401309013,
        -0.014942522160708904,
        0.04764409735798836,
        -0.05426640808582306,
        -0.03398172929883003,
        0.055534616112709045,
        -0.05948502942919731,
        0.046872612088918686,
        -0.04850585386157036,
        -0.020814506337046623,
        0.013885371387004852,
        -0.000861837703268975,
        -0.04792480543255806,
        0.06006041169166565,
        -0.03826621547341347,
        0.04771843180060387,
        -0.0598178431391716,
        -0.033239562064409256,
        -0.002103803912177682,
        0.05755242332816124,
        0.05501985177397728,
        0.03907005861401558,
        0.04197164997458458,
        -0.06075639650225639,
        0.05651912838220596,
        0.0603218711912632,
        0.05104997754096985,
        0.006492392159998417,
        -0.05798297002911568,
        -0.06120044365525246,
        0.057076748460531235,
        0.05669603496789932,
        -0.03433007746934891,
        -0.026724573224782944,
        -0.05488695576786995,
        -0.04115890711545944,
        -0.03477683290839195,
        -0.061284467577934265,
        -0.045644160360097885,
        -0.028792710974812508,
        -0.03986680880188942,
        -0.003452940145507455,
        0.05762508884072304,
        0.014023032039403915,
        0.049094248563051224,
        0.04987835884094238,
        -0.05887438729405403,
        0.014701197855174541,
        0.06010157987475395,
        0.02212369255721569,
        -0.04816941171884537,
        -0.028679993003606796,
        -0.000506664568092674,
        0.026054099202156067,
        -0.05044904351234436,
        0.05499628931283951,
        0.021847693249583244,
        -0.05736258625984192,
        -0.0585639551281929,
        0.05961887910962105,
        -0.0044184597209095955,
        0.025510113686323166,
        -0.05788625404238701,
        -0.019318656995892525,
        0.04849838465452194,
        0.024667656049132347,
        0.052003562450408936,
        0.046252742409706116,
        -0.04161407798528671,
        -0.026599621400237083,
        -0.05336523428559303,
        0.05114971473813057,
        -0.010026640258729458,
        -0.048448048532009125,
        -0.02065858617424965,
        0.03062976896762848,
        -0.05849282443523407,
        0.060230802744627,
        -0.011182891204953194,
        -0.06070601940155029,
        -0.017102913931012154,
        -0.017533861100673676,
        -0.059258487075567245,
        -0.05806297808885574,
        -0.04053090512752533,
        -0.012348280288279057,
        0.018243080005049706,
        0.057522963732481,
        0.057054728269577026,
        0.04769482836127281,
        0.05790043994784355,
        0.05995866656303406,
        0.007189203053712845,
        0.057282764464616776,
        -0.049642231315374374,
        0.05843265727162361,
        0.05484655871987343,
        -0.05814122036099434,
        0.03223961591720581,
        0.027128612622618675,
        0.050778843462467194,
        0.009828797541558743,
        -0.05713013559579849,
        0.013946454040706158,
        -0.008901093155145645,
        -0.03786732628941536,
        0.05160876736044884,
        -0.015048236586153507,
        -0.03745478391647339,
        -0.05932695418596268,
        0.04196416586637497,
        0.019915003329515457,
        0.060790807008743286,
        -0.037292834371328354,
        0.05214531719684601,
        -0.05881188064813614,
        -0.05896887183189392,
        0.03156376630067825,
        0.06006994470953941,
        0.03053409978747368,
        0.03956998884677887,
        -0.05876203998923302,
        0.014433450065553188,
        0.05234745889902115,
        0.0463777557015419,
        -0.03860140219330788,
        -0.055118296295404434,
        -0.034658003598451614,
        -0.060657307505607605,
        -0.043692003935575485,
        0.025517433881759644,
        0.044972993433475494,
        0.052150871604681015,
        0.05911841243505478,
        0.05005368962883949,
        -0.04655170068144798,
        -0.010640370659530163,
        -0.0595451258122921,
        0.014175044372677803,
        0.020619042217731476,
        0.013481149449944496,
        -0.03369294852018356,
        -0.004085746128112078,
        0.014181424863636494,
        0.0588754266500473,
        0.055259447544813156,
        0.06038932502269745,
        -0.05545893311500549,
        -0.041489336639642715,
        -0.03808039054274559,
        -0.0011248125229030848,
        -0.01729826256632805,
        -0.053278081119060516,
        0.06072089821100235,
        0.016580983996391296,
        0.04081444442272186,
        -0.06142602488398552,
        0.03123004361987114,
        0.057958293706178665
    ],
    [
        -0.02033396065235138,
        0.01889844797551632,
        0.050641272217035294,
        0.0286005400121212,
        -0.023999158293008804,
        -0.007136157248169184,
        -0.04363719001412392,
        -0.06128036603331566,
        0.060661207884550095,
        0.03287192061543465,
        0.03044869937002659,
        0.03147491067647934,
        -0.05176292732357979,
        -0.0482885017991066,
        -0.05832134559750557,
        0.0620640330016613,
        0.0021492496598511934,
        0.059910859912633896,
        -0.05586586892604828,
        -0.056518085300922394,
        0.05216009169816971,
        0.059243422001600266,
        0.037623267620801926,
        -0.042126692831516266,
        0.008575303480029106,
        0.041719406843185425,
        0.05699850618839264,
        -0.042970795184373856,
        0.008602902293205261,
        -0.002718067029491067,
        0.03214232996106148,
        -0.028667090460658073,
        -0.038372401148080826,
        -0.06117522343993187,
        0.05045032501220703,
        0.01910487562417984,
        -0.059785425662994385,
        -0.044548649340867996,
        -0.052726492285728455,
        -0.04301740974187851,
        0.046841591596603394,
        0.021646765992045403,
        -0.001206525950692594,
        -0.059125132858753204,
        0.03237781301140785,
        0.05939185246825218,
        0.024614427238702774,
        0.047101784497499466,
        0.05754070729017258,
        0.03575989231467247,
        0.05970839411020279,
        0.061967913061380386,
        -0.053465042263269424,
        0.03607609495520592,
        0.034560676664114,
        -0.049621544778347015,
        0.0023549164179712534,
        0.028543956577777863,
        0.0035038660280406475,
        -0.0542118139564991,
        -0.058560390025377274,
        0.04491979256272316,
        -0.048861145973205566,
        0.06180223450064659,
        -0.05882257968187332,
        -0.029534069821238518,
        -0.03689662367105484,
        -0.006619000807404518,
        -0.06132465600967407,
        0.047590404748916626,
        -0.05687745660543442,
        -0.061443109065294266,
        0.06170521676540375,
        0.026459909975528717,
        0.05527375265955925,
        -0.04347732663154602,
        0.006880013272166252,
        0.062231261283159256,
        -0.04349546134471893,
        -0.03279245272278786,
        0.021458102390170097,
        0.055861372500658035,
        -0.03561444953083992,
        0.04481218755245209,
        0.05934850126504898,
        0.0542660653591156,
        0.05212300270795822,
        -0.03355827182531357,
        0.043541837483644485,
        0.01431713905185461,
        0.03939797729253769,
        0.05386589094996452,
        -0.061806149780750275,
        -0.027803532779216766,
        -0.04580439254641533,
        0.059477560222148895,
        0.017492374405264854,
        -0.059216465801000595,
        0.057853762060403824,
        -0.026286497712135315,
        -0.06169184669852257,
        0.041493210941553116,
        0.04734092578291893,
        0.04079730808734894,
        0.055651795119047165,
        -0.06156904250383377,
        -0.04109269008040428,
        0.06047525256872177,
        -0.0372723825275898,
        0.05497150495648384,
        -0.06167202442884445,
        -0.007523696403950453,
        -0.06139887496829033,
        -0.0009405926684848964,
        -0.012546597979962826,
        -0.018708212301135063,
        0.05295982584357262,
        -0.036995869129896164,
        0.0620226189494133,
        0.0461709126830101,
        -0.028049563989043236,
        0.06035818159580231,
        0.047777168452739716,
        -0.052689723670482635,
        -0.05456797778606415,
        -0.05066966637969017,
        -0.05641500651836395,
        -0.058469317853450775,
        0.05887587368488312,
        0.0355556346476078,
        0.04675126075744629,
        -0.051630374044179916,
        -0.03961046040058136,
        0.0335298553109169,
        -0.05804102122783661,
        -0.05959897115826607,
        0.049775831401348114,
        0.0589325949549675,
        -0.062008876353502274,
        0.050069764256477356,
        0.05895150825381279,
        0.01579446718096733,
        0.05382460355758667,
        -0.0001979133376153186,
        0.05222204700112343,
        0.022963739931583405,
        0.059534184634685516,
        -0.05138574168086052,
        -0.01846560463309288,
        0.05996093526482582,
        0.05836227163672447,
        -0.06119515746831894,
        0.040040720254182816,
        -0.06100393086671829,
        0.04941471293568611,
        -0.05890373885631561,
        -0.039327800273895264,
        0.02720848098397255,
        0.04065019264817238,
        0.030519291758537292,
        0.056865543127059937,
        0.022170813754200935,
        0.05389885604381561,
        0.027010459452867508,
        0.04449934884905815,
        0.01423129253089428,
        0.013403471559286118,
        0.050734780728816986,
        0.02070176601409912,
        0.02703721635043621,
        0.015470698475837708,
        0.038992252200841904,
        -0.04411262273788452,
        -0.05942763015627861,
        0.048462219536304474,
        0.06041143089532852,
        -0.058604542165994644,
        0.004612686578184366,
        0.053681064397096634,
        -0.058008965104818344,
        -0.022287480533123016,
        0.06220356747508049,
        0.0025848194491118193,
        0.012465282343327999,
        0.038007702678442,
        -0.001764027401804924,
        -0.04840390384197235,
        0.05926203727722168,
        -0.052108101546764374,
        -0.028574269264936447,
        -0.03802194446325302,
        0.007465309929102659,
        0.007086457684636116,
        -0.02339870296418667,
        -0.041388992220163345,
        0.03478362038731575,
        -0.021105630323290825,
        -0.005015925504267216,
        -0.04986347630620003,
        0.01955888234078884,
        0.03317033872008324,
        -0.02488110587000847,
        -0.019173989072442055,
        -0.013114018365740776,
        -0.05597720667719841,
        0.037082523107528687,
        0.017095066606998444,
        0.033716343343257904,
        -0.04509197548031807,
        0.055893052369356155,
        -0.05253169313073158,
        0.05825601518154144,
        0.03162220120429993,
        0.03574629873037338,
        -0.04185859486460686,
        0.03397073969244957,
        0.04867376387119293,
        -0.022627616301178932,
        0.06086215376853943,
        -0.01669378951191902,
        0.0005540040438063443,
        0.06158231571316719,
        -0.05905875191092491,
        -0.02328353188931942,
        0.0583948977291584,
        0.017735539004206657,
        0.02961115539073944,
        0.03667130693793297,
        0.024510253220796585,
        -0.05814461410045624,
        0.021316850557923317,
        -0.04813568666577339,
        -0.02158786728978157,
        0.053552716970443726,
        -0.03096284531056881,
        0.06221778690814972,
        0.05985933542251587,
        -0.021199850365519524,
        -0.015188878402113914,
        0.062157753854990005,
        -0.01805804669857025,
        0.061378221958875656,
        -0.06208892539143562,
        0.0570758655667305,
        0.039328157901763916,
        -0.005789336748421192,
        -0.06130801886320114,
        0.027633655816316605,
        -0.010190024971961975,
        -0.04064236581325531,
        -0.05122716724872589,
        -0.060347843915224075,
        0.03462115302681923,
        -0.05898985639214516,
        -0.06002657115459442,
        0.061135709285736084,
        -0.05986152961850166,
        -0.05208856984972954,
        0.03309134021401405,
        0.004955488722771406,
        0.06142815202474594,
        -0.05054815858602524,
        -0.0490131676197052,
        0.009790015406906605,
        0.010931585915386677,
        0.04628739878535271,
        0.012823374941945076,
        0.03845749422907829,
        -0.02726961113512516,
        0.04215943440794945,
        0.04843335226178169,
        -0.055983543395996094,
        0.06157306954264641,
        -0.018691523000597954,
        -0.057625506073236465,
        0.009310822933912277,
        -0.0505041666328907,
        0.0038178875111043453,
        -0.036546073853969574,
        -0.020552214235067368,
        -0.06076065078377724,
        -0.03844298794865608,
        -0.06178063154220581,
        0.05869840458035469,
        0.031021689996123314,
        -0.05150860175490379,
        0.016849137842655182,
        0.05875168368220329,
        0.05381331592798233,
        0.045415084809064865,
        0.016744861379265785,
        0.055584169924259186,
        -0.01970072276890278,
        0.04094589874148369,
        0.06042076274752617,
        -0.06096825376152992,
        0.059620343148708344,
        0.05935846269130707,
        -0.06019945815205574,
        0.0574503093957901,
        0.0587228387594223,
        0.05303753539919853,
        -0.02603062242269516,
        0.05370352789759636,
        -0.008924681693315506,
        0.041443824768066406,
        -0.03990662842988968,
        0.03925153985619545,
        -0.013006046414375305,
        -0.05515999346971512,
        0.037422630935907364,
        0.021866923198103905,
        -0.030359825119376183,
        0.018359597772359848,
        0.01077722106128931,
        -0.05758272483944893,
        -0.0057698884047567844,
        -0.0488271526992321,
        0.04020557552576065,
        -0.0382935106754303,
        -0.02884037420153618,
        -0.033216796815395355,
        0.024922359734773636,
        0.045972492545843124,
        0.057950958609580994,
        -0.05303281173110008,
        -0.020291345193982124,
        0.017101390287280083,
        0.02544129453599453,
        -0.053763799369335175,
        0.007641560398042202,
        -0.05210413411259651,
        -0.043971553444862366,
        -0.05237707868218422,
        0.058885980397462845,
        0.04482926055788994,
        -0.03268616273999214,
        0.05706078186631203,
        -0.013849782757461071,
        0.03710472956299782,
        -0.04742850363254547,
        0.0565384216606617,
        -0.06209322065114975,
        -0.0399303175508976,
        0.032980743795633316,
        0.03488077223300934,
        0.032655589282512665,
        0.05963973328471184,
        -0.04205726459622383,
        0.06012534722685814,
        0.053903669118881226,
        0.014855025336146355,
        -0.035686276853084564,
        -0.026368174701929092,
        -0.0037893676199018955,
        0.04110013321042061,
        -0.057154580950737,
        -0.03198340907692909,
        0.0584164634346962,
        -0.06046038493514061,
        0.04976727440953255,
        -0.04369615018367767,
        -0.021861154586076736,
        0.008829975500702858,
        0.025710009038448334,
        -0.05386871472001076,
        0.05950980260968208,
        -0.04692661389708519,
        0.035107728093862534,
        -0.060746148228645325,
        -0.03581543266773224,
        0.0034405284095555544,
        0.054078251123428345,
        0.05560097470879555,
        0.02478673681616783,
        0.0308140330016613,
        -0.060116641223430634,
        0.05912311002612114,
        0.06125352904200554,
        0.051758185029029846,
        -0.030276909470558167,
        -0.059436697512865067,
        -0.0620996318757534,
        0.05580773949623108,
        0.05855121836066246,
        -0.021930444985628128,
        -0.041896335780620575,
        -0.05862240493297577,
        -0.0489889532327652,
        -0.01971040479838848,
        -0.06185954809188843,
        -0.049008455127477646,
        -0.016818903386592865,
        -0.039085753262043,
        -0.02421879954636097,
        0.056826937943696976,
        0.019494477659463882,
        0.04691599681973457,
        0.049165911972522736,
        -0.06054484844207764,
        0.013284491375088692,
        0.060188177973032,
        0.020897191017866135,
        -0.053752999752759933,
        -0.020146461203694344,
        0.006207961123436689,
        0.028991205617785454,
        -0.05585132911801338,
        0.05483603850007057,
        0.01731639914214611,
        -0.05828475579619408,
        -0.05837767943739891,
        0.0610802061855793,
        -0.016934089362621307,
        0.028918232768774033,
        -0.056403446942567825,
        -0.03293454647064209,
        0.02013852633535862,
        0.0256701298058033,
        0.04558876156806946,
        0.037134796380996704,
        -0.04984588176012039,
        -0.0080561563372612,
        -0.052678707987070084,
        0.03243524208664894,
        0.015485743060708046,
        -0.05749388411641121,
        -0.010470476932823658,
        0.03205937519669533,
        -0.05672552436590195,
        0.060440246015787125,
        0.001695541781373322,
        -0.06070060282945633,
        -0.024537885561585426,
        0.008126141503453255,
        -0.059581972658634186,
        -0.053953323513269424,
        -0.006217183079570532,
        -0.00796403270214796,
        -0.002422662451863289,
        0.054917145520448685,
        0.05888810753822327,
        0.05258774384856224,
        0.05902068689465523,
        0.061568472534418106,
        -0.007662831339985132,
        0.057850293815135956,
        -0.05000905320048332,
        0.060195378959178925,
        0.058589790016412735,
        -0.05782841145992279,
        0.01106230728328228,
        0.026388416066765785,
        0.046750858426094055,
        -0.0044280062429606915,
        -0.05910449102520943,
        0.013744919560849667,
        -0.026032332330942154,
        -0.008387964218854904,
        0.05471324920654297,
        0.0036401606630533934,
        -0.03781520575284958,
        -0.05755767226219177,
        0.039352595806121826,
        0.01484344806522131,
        0.06143738701939583,
        -0.03877299278974533,
        0.054197922348976135,
        -0.05982029810547829,
        -0.058978185057640076,
        0.028847327455878258,
        0.05787677690386772,
        0.025284135714173317,
        0.020609768107533455,
        -0.0614316500723362,
        -0.015114100649952888,
        0.057432256639003754,
        0.04546100273728371,
        -0.037978410720825195,
        -0.054801832884550095,
        -0.032107383012771606,
        -0.06076574698090553,
        -0.054288350045681,
        0.035887982696294785,
        0.04512982815504074,
        0.05454513058066368,
        0.06115825101733208,
        0.0494660809636116,
        -0.044459179043769836,
        -0.006678676698356867,
        -0.05727360397577286,
        0.026901300996541977,
        0.006894088815897703,
        0.007636963855475187,
        -0.023821720853447914,
        0.0029910067096352577,
        0.029231157153844833,
        0.06058865785598755,
        0.055746495723724365,
        0.06050143390893936,
        -0.05979837849736214,
        -0.04707122594118118,
        -0.05035391449928284,
        0.005660102237015963,
        0.010611013509333134,
        -0.05414501577615738,
        0.060768011957407,
        0.016112135723233223,
        0.028593750670552254,
        -0.06223389133810997,
        0.004250566009432077,
        0.05518034100532532
    ],
    [
        -0.013333712704479694,
        0.01686777174472809,
        0.05548254773020744,
        0.0350276343524456,
        -0.02106243558228016,
        0.02541043981909752,
        -0.030070407316088676,
        -0.06099070981144905,
        0.06018621101975441,
        0.02557491511106491,
        0.037753283977508545,
        0.004308929201215506,
        -0.047579869627952576,
        -0.01999843493103981,
        -0.058661866933107376,
        0.06055633723735809,
        -0.018618984147906303,
        0.0600748248398304,
        -0.05328565835952759,
        -0.05562521889805794,
        0.052165355533361435,
        0.06020979955792427,
        0.02779712714254856,
        -0.04547140747308731,
        0.03893032297492027,
        0.04189315065741539,
        0.054201167076826096,
        -0.028146784752607346,
        0.021799787878990173,
        0.01036395039409399,
        0.05311265587806702,
        -0.0009697807254269719,
        -0.04459675773978233,
        -0.06116991117596626,
        0.05384514480829239,
        0.027227895334362984,
        -0.060158416628837585,
        -0.04673396423459053,
        0.01595247909426689,
        -0.045580003410577774,
        0.041299205273389816,
        0.03191656619310379,
        -0.0027189250104129314,
        -0.05038169026374817,
        -0.02630056068301201,
        0.059262026101350784,
        -0.01624698005616665,
        0.040646955370903015,
        0.05888080224394798,
        0.02786470390856266,
        0.058586400002241135,
        0.06106570363044739,
        -0.05435742065310478,
        0.028685912489891052,
        0.042174290865659714,
        -0.04455821216106415,
        -0.021158520132303238,
        0.03836994990706444,
        0.03321659192442894,
        -0.0492231622338295,
        -0.05649208277463913,
        0.048249151557683945,
        -0.049043092876672745,
        0.060446176677942276,
        -0.056314773857593536,
        -0.05128314346075058,
        -0.0474201925098896,
        -0.011901547200977802,
        -0.0606156662106514,
        0.043182123452425,
        -0.05306815728545189,
        -0.061581749469041824,
        0.06135111302137375,
        0.036111023277044296,
        0.049545157700777054,
        -0.03476306051015854,
        0.00898254755884409,
        0.06161830201745033,
        -0.026242241263389587,
        -0.00396376196295023,
        0.0143522247672081,
        0.05258061736822128,
        -0.04411787539720535,
        0.0432039350271225,
        0.059475284069776535,
        0.052062906324863434,
        0.05157052353024483,
        -0.046952683478593826,
        0.0391903892159462,
        0.011325962841510773,
        0.03332161158323288,
        0.05113524571061134,
        -0.06119634956121445,
        -0.0014141990104690194,
        -0.033943451941013336,
        0.05833156406879425,
        0.00970138143748045,
        -0.05891774594783783,
        0.055424224585294724,
        -0.016939105466008186,
        -0.060946375131607056,
        0.03598104789853096,
        0.04048442468047142,
        0.028708165511488914,
        0.04622732847929001,
        -0.05900956690311432,
        -0.04110928997397423,
        0.0568554624915123,
        -0.04188359156250954,
        0.057698771357536316,
        -0.06030965968966484,
        0.013480201363563538,
        -0.06109980121254921,
        -0.012145066633820534,
        -0.04020337387919426,
        -0.04621760547161102,
        0.053860872983932495,
        -0.040254220366477966,
        0.061460111290216446,
        0.04711480438709259,
        -0.03827677294611931,
        0.060671426355838776,
        0.041999366134405136,
        -0.037288058549165726,
        -0.051342688500881195,
        -0.04087959975004196,
        -0.059629663825035095,
        -0.04826975613832474,
        0.056966014206409454,
        0.009504588320851326,
        0.015624916180968285,
        -0.054567884653806686,
        -0.021888600662350655,
        0.02581123448908329,
        -0.057178981602191925,
        -0.05936233326792717,
        0.04660814255475998,
        0.055727679282426834,
        -0.060438528656959534,
        0.050010621547698975,
        0.06012896075844765,
        -0.0003766193112824112,
        0.05203266814351082,
        0.0020732406992465258,
        0.05730380117893219,
        -0.009155684150755405,
        0.06001313775777817,
        -0.045992568135261536,
        -0.03032679297029972,
        0.05826180428266525,
        0.060875579714775085,
        -0.060437120497226715,
        -0.03234446793794632,
        -0.059558745473623276,
        0.059314243495464325,
        -0.0592079758644104,
        -0.024485906586050987,
        0.007337859831750393,
        0.02318619377911091,
        0.038320861756801605,
        0.05574643239378929,
        0.04979991540312767,
        0.050667595118284225,
        0.03918121010065079,
        0.04111098498106003,
        -0.03144150227308273,
        -0.0011229736264795065,
        0.05434354394674301,
        0.03682691603899002,
        0.032255448400974274,
        -0.031405258923769,
        0.04372752457857132,
        -0.04888707771897316,
        -0.05631330609321594,
        0.0021496135741472244,
        0.05999694764614105,
        -0.0587901771068573,
        -0.005022131372243166,
        0.05272834002971649,
        -0.052576858550310135,
        -0.018220191821455956,
        0.06143549829721451,
        -0.029325736686587334,
        0.042214784771203995,
        0.048918094485998154,
        0.01645745523273945,
        -0.04837237671017647,
        0.05893440917134285,
        -0.0537220761179924,
        -0.05552907660603523,
        -0.05014127120375633,
        -0.0002485213044565171,
        -0.010920913890004158,
        -0.022754047065973282,
        -0.03961852565407753,
        0.04742283374071121,
        -0.001611475832760334,
        -0.017726348713040352,
        -0.04976629838347435,
        0.001827081199735403,
        0.04880552738904953,
        -0.023992199450731277,
        0.02311744913458824,
        0.007332221604883671,
        -0.04796043410897255,
        0.02281203679740429,
        0.04099338501691818,
        0.030667440965771675,
        -0.05503259599208832,
        0.05355415120720863,
        -0.05208522453904152,
        0.05434088408946991,
        0.011951089836657047,
        0.007618700619786978,
        -0.05185258388519287,
        0.04721289873123169,
        0.05745675414800644,
        -0.005841647740453482,
        0.06080463156104088,
        0.010257723741233349,
        -0.014558684080839157,
        0.06008968502283096,
        -0.05863763019442558,
        -0.004726975690573454,
        0.06102096661925316,
        -0.05098041892051697,
        0.02775624953210354,
        0.02445914037525654,
        0.02159457467496395,
        -0.05930749326944351,
        0.03664188086986542,
        -0.05868519842624664,
        0.006593896076083183,
        0.046686794608831406,
        -0.031078416854143143,
        0.06161714345216751,
        0.05913490802049637,
        -0.02793721854686737,
        0.00913186278194189,
        0.06155604496598244,
        -0.03277898579835892,
        0.06060175970196724,
        -0.06147941201925278,
        0.05856083706021309,
        0.029061205685138702,
        -0.0319393128156662,
        -0.05965616926550865,
        0.03284227102994919,
        -0.011273369193077087,
        -0.04250370338559151,
        -0.046368807554244995,
        -0.06027272716164589,
        0.04579682648181915,
        -0.0589202418923378,
        -0.05767830088734627,
        0.059536732733249664,
        -0.055976539850234985,
        -0.058421067893505096,
        0.009096129797399044,
        0.016813339665532112,
        0.06094932556152344,
        -0.05157595872879028,
        -0.04093922674655914,
        0.019592594355344772,
        0.029950127005577087,
        0.05297476053237915,
        -0.009993364103138447,
        -0.010030052624642849,
        -0.03609820082783699,
        0.04506969079375267,
        0.04629546403884888,
        -0.05606568977236748,
        0.06142080947756767,
        -0.04368407651782036,
        -0.05684634670615196,
        0.0042640105821192265,
        -0.04533933848142624,
        0.002650680486112833,
        -0.050056539475917816,
        -0.03862269967794418,
        -0.06008369103074074,
        -0.05261573567986488,
        -0.06141475960612297,
        0.05910292640328407,
        0.020873447880148888,
        -0.042987413704395294,
        0.002393444301560521,
        0.05904923006892204,
        0.05506070703268051,
        0.04820995032787323,
        -0.0014337333850562572,
        0.05260321497917175,
        -0.024058207869529724,
        0.04409508407115936,
        0.05895671993494034,
        -0.06030327081680298,
        0.05668598785996437,
        0.05846938118338585,
        -0.058978885412216187,
        0.05834004655480385,
        0.05735323578119278,
        0.053925976157188416,
        -0.01969161443412304,
        0.05746772140264511,
        -0.04400808736681938,
        0.03577391058206558,
        -0.039418768137693405,
        0.053348612040281296,
        -0.005863670725375414,
        -0.0608317069709301,
        0.04187040403485298,
        0.026830431073904037,
        -0.032051365822553635,
        0.02814728394150734,
        0.020470643416047096,
        -0.056747257709503174,
        0.004634768236428499,
        -0.04138714075088501,
        0.04368356987833977,
        -0.026158630847930908,
        -0.03112897463142872,
        -0.03163841739296913,
        0.01826823689043522,
        0.05546860024333,
        0.05867199972271919,
        -0.056511856615543365,
        -0.04349031671881676,
        -0.0169864222407341,
        0.003419718472287059,
        -0.054067377001047134,
        0.022700689733028412,
        -0.046202752739191055,
        -0.021559201180934906,
        -0.039698414504528046,
        0.056892041116952896,
        0.03403147682547569,
        -0.0482608899474144,
        0.0596449188888073,
        -0.01951058767735958,
        0.024838363751769066,
        -0.04193353280425072,
        0.05843457579612732,
        -0.0612003393471241,
        -0.0449490062892437,
        0.03453226760029793,
        0.03668098524212837,
        0.04407200217247009,
        0.058540187776088715,
        -0.03400096297264099,
        0.059558358043432236,
        0.057200733572244644,
        0.003989932127296925,
        -0.03220211714506149,
        -0.017436780035495758,
        -0.016701964661478996,
        0.05002311244606972,
        -0.04862385615706444,
        -0.02813301980495453,
        0.05671573057770729,
        -0.06059170886874199,
        0.05164536461234093,
        -0.04685245454311371,
        0.004544154275208712,
        0.02164311893284321,
        0.007325539831072092,
        -0.04597736522555351,
        0.059725321829319,
        -0.0520535409450531,
        0.048535190522670746,
        -0.05940648540854454,
        -0.033590178936719894,
        0.004064786713570356,
        0.05666530877351761,
        0.05093900486826897,
        0.027252063155174255,
        0.03932793065905571,
        -0.059872958809137344,
        0.05591177940368652,
        0.0604943111538887,
        0.05695856735110283,
        -0.01298599038273096,
        -0.06009320542216301,
        -0.06118315830826759,
        0.05791270732879639,
        0.05327073857188225,
        -0.02278805896639824,
        -0.025919949635863304,
        -0.05856898054480553,
        -0.04182666912674904,
        -0.01376652717590332,
        -0.06142229586839676,
        -0.046508561819791794,
        -0.022811103612184525,
        -0.0468861348927021,
        -0.0113889304921031,
        0.057861484587192535,
        0.012374411337077618,
        0.05352381616830826,
        0.05302352085709572,
        -0.06003011390566826,
        0.011301684193313122,
        0.059647105634212494,
        0.005941311363130808,
        -0.04831961542367935,
        -0.035100407898426056,
        -0.009806880727410316,
        0.026286311447620392,
        -0.05153833329677582,
        0.051904644817113876,
        0.022626198828220367,
        -0.055476926267147064,
        -0.05948489531874657,
        0.06040456146001816,
        0.025065097957849503,
        0.04190794378519058,
        -0.056791700422763824,
        -0.0408356674015522,
        0.05579639598727226,
        0.03740857169032097,
        0.05741840600967407,
        0.04302290454506874,
        -0.044459328055381775,
        -0.014225275255739689,
        -0.051224738359451294,
        0.05308213084936142,
        -0.021538719534873962,
        -0.0457778163254261,
        -0.015436993911862373,
        0.011993205174803734,
        -0.056254394352436066,
        0.06039438024163246,
        0.015025237575173378,
        -0.06099989265203476,
        -0.019918376579880714,
        0.025208957493305206,
        -0.059790100902318954,
        -0.05756286159157753,
        -0.028974371030926704,
        -0.02792898565530777,
        0.010934855788946152,
        0.05369538813829422,
        0.054756056517362595,
        0.0564286932349205,
        0.058921366930007935,
        0.053222209215164185,
        -0.008556208573281765,
        0.05339697003364563,
        -0.04609803855419159,
        0.05921005830168724,
        0.047719232738018036,
        -0.053624723106622696,
        0.012493927031755447,
        0.03537285327911377,
        0.04003072530031204,
        -0.0023441454395651817,
        -0.05841778591275215,
        -0.0043916963040828705,
        -0.021349240094423294,
        -0.023477068170905113,
        0.05251588299870491,
        -0.0501573421061039,
        -0.03703374043107033,
        -0.05954853445291519,
        0.01685401238501072,
        0.016034092754125595,
        0.06099618598818779,
        -0.026117507368326187,
        0.052120231091976166,
        -0.05989430099725723,
        -0.055373307317495346,
        0.04188338667154312,
        0.0613718256354332,
        0.03445123881101608,
        0.030066100880503654,
        -0.0605260506272316,
        -0.0032778640743345022,
        0.05222071334719658,
        0.0425950288772583,
        -0.020096084102988243,
        -0.05459002032876015,
        -0.017989344894886017,
        -0.06044724956154823,
        -0.054766055196523666,
        0.02885439433157444,
        0.004275133367627859,
        0.049301877617836,
        0.05681904777884483,
        0.05331350490450859,
        -0.049029797315597534,
        -0.030099118128418922,
        -0.05995427817106247,
        0.03879203274846077,
        0.014080613851547241,
        -0.0027656808961182833,
        -0.019238661974668503,
        0.008672228083014488,
        0.02699015475809574,
        0.06035351753234863,
        0.0555768720805645,
        0.06085820496082306,
        -0.05214305222034454,
        -0.047026220709085464,
        -0.031127043068408966,
        -0.005042378790676594,
        -0.023919567465782166,
        -0.05028386041522026,
        0.06058875843882561,
        0.004854623228311539,
        0.01801818422973156,
        -0.06151489540934563,
        0.04259483516216278,
        0.05910329520702362
    ],
    [
        0.05511210486292839,
        0.030930010601878166,
        0.0017035664059221745,
        -0.01706305705010891,
        -0.0488242581486702,
        -0.05559508129954338,
        0.04280712455511093,
        -0.055820662528276443,
        0.0541716031730175,
        -0.055499982088804245,
        0.055205799639225006,
        0.015945224091410637,
        -0.055202826857566833,
        -0.02977490983903408,
        -0.05552951991558075,
        0.05515729635953903,
        0.046039897948503494,
        0.055419545620679855,
        0.019860897213220596,
        -0.005607943516224623,
        0.05568232014775276,
        -0.02721446380019188,
        0.05134042352437973,
        -0.03993828967213631,
        0.0006994392606429756,
        0.05557417497038841,
        -0.028977086767554283,
        0.043986726552248,
        -0.04739902541041374,
        -0.010232191532850266,
        0.047833483666181564,
        0.03452784940600395,
        0.040536798536777496,
        -0.055804748088121414,
        0.052621904760599136,
        -0.05193157121539116,
        -0.05572391673922539,
        -0.032867953181266785,
        0.05213484540581703,
        -0.03952629864215851,
        0.021571744233369827,
        0.04500426724553108,
        0.05294029787182808,
        -0.031405918300151825,
        0.05171516537666321,
        0.03613133355975151,
        0.055102188140153885,
        0.05274117738008499,
        0.055496688932180405,
        -0.05373633652925491,
        0.05489466339349747,
        0.05519687384366989,
        -0.029439367353916168,
        0.006128817331045866,
        -0.04426879435777664,
        0.00821396242827177,
        0.01852337270975113,
        0.0407630018889904,
        -0.05582959204912186,
        -0.05389939248561859,
        -0.0557747483253479,
        0.05425390601158142,
        -0.055183976888656616,
        0.012854860164225101,
        -0.04332952946424484,
        -0.05343485251069069,
        -0.055451005697250366,
        -0.054520465433597565,
        0.003913207910954952,
        -0.055525775998830795,
        -0.0024324823170900345,
        -0.05548667162656784,
        0.0557730607688427,
        0.04894346743822098,
        -0.008865365758538246,
        -0.04756022244691849,
        0.05559956654906273,
        0.05587974190711975,
        0.04970576614141464,
        0.0320194773375988,
        -0.05200352147221565,
        -0.05577061325311661,
        -0.055329542607069016,
        0.045354925096035004,
        -0.015301358886063099,
        0.05063896253705025,
        -0.05347614735364914,
        -0.05037066340446472,
        -0.04915843531489372,
        0.037863295525312424,
        -0.02277511917054653,
        -0.04844515770673752,
        -0.015643060207366943,
        -0.050064317882061005,
        -0.024255717173218727,
        0.029960962012410164,
        -0.05006664618849754,
        -0.05505068227648735,
        -0.01626349613070488,
        0.037095341831445694,
        -0.05587414652109146,
        0.05469662323594093,
        0.037424519658088684,
        0.05566403269767761,
        -0.02318951115012169,
        -0.055793795734643936,
        -0.05566931888461113,
        0.006980603095144033,
        -0.04687132686376572,
        0.05527316406369209,
        -0.025044163689017296,
        -0.04728218540549278,
        -0.036932460963726044,
        -0.018579857423901558,
        0.05579386651515961,
        0.011596732772886753,
        -0.054742392152547836,
        -0.05494973063468933,
        0.04095117375254631,
        -0.052885718643665314,
        -0.05525726452469826,
        -0.014722256921231747,
        0.04694041982293129,
        -0.05565521493554115,
        -0.046844981610774994,
        -0.013115471228957176,
        0.01876319758594036,
        0.04383420944213867,
        0.05555290728807449,
        0.013653818517923355,
        0.04275768995285034,
        -0.053732503205537796,
        -0.042503416538238525,
        0.055094726383686066,
        -0.038684070110321045,
        -0.05545516684651375,
        0.05580252781510353,
        0.035044945776462555,
        -0.00399561133235693,
        0.02750769816339016,
        -0.007394625339657068,
        -0.01357077993452549,
        0.033621206879615784,
        0.05283442139625549,
        0.040184441953897476,
        0.05452906712889671,
        0.05197225883603096,
        -0.028515649959445,
        -0.020710662007331848,
        0.031432975083589554,
        -0.0490163154900074,
        -0.055239398032426834,
        -0.05494893714785576,
        -0.05528565123677254,
        -0.05065643787384033,
        -0.026472222059965134,
        -0.04215601459145546,
        -0.052357710897922516,
        0.05582374334335327,
        0.0532132089138031,
        -0.05061164125800133,
        0.013514935038983822,
        0.055262573063373566,
        0.04233020544052124,
        -0.019970115274190903,
        -0.053615838289260864,
        0.0516858845949173,
        0.04499056190252304,
        -0.05294373631477356,
        0.015587236732244492,
        -0.0548466257750988,
        0.04862399771809578,
        -0.05458744242787361,
        0.045649752020835876,
        -0.03602058067917824,
        -0.018946833908557892,
        -0.021885542199015617,
        -0.02990374155342579,
        0.05587003380060196,
        -0.05536728724837303,
        -0.05330776423215866,
        0.05474672466516495,
        0.03861339017748833,
        -0.03853755444288254,
        0.03806053102016449,
        -0.049483854323625565,
        0.048899032175540924,
        0.05396242439746857,
        0.025087222456932068,
        0.04725806787610054,
        -0.05565439164638519,
        -0.02403181605041027,
        0.014397204853594303,
        0.003959312569350004,
        -0.05506327375769615,
        0.05051998421549797,
        0.0538608655333519,
        0.054012153297662735,
        0.00891913939267397,
        0.05348671227693558,
        0.02419630065560341,
        0.05320560932159424,
        -0.005404139868915081,
        -0.030393214896321297,
        -0.022569619119167328,
        -0.05086690932512283,
        0.055349938571453094,
        0.05525853484869003,
        -0.02451441064476967,
        0.029083022847771645,
        -0.04596688970923424,
        0.00479990104213357,
        -0.0036194603890180588,
        0.05560979247093201,
        0.054663173854351044,
        -0.043597884476184845,
        0.05586167424917221,
        -0.003008032450452447,
        0.05587577819824219,
        0.0011921467958018184,
        0.05440879985690117,
        0.055218856781721115,
        -0.018131406977772713,
        -0.010351168923079967,
        0.05534936115145683,
        -0.04657495766878128,
        0.049410805106163025,
        -0.0534161739051342,
        0.05447348952293396,
        -0.05579475313425064,
        -0.05540694296360016,
        -0.038566019386053085,
        0.012105414643883705,
        0.04776102304458618,
        0.04351372271776199,
        0.054042622447013855,
        0.054004039615392685,
        -0.01792026497423649,
        -0.05572962388396263,
        0.055761948227882385,
        0.04192676767706871,
        0.055832576006650925,
        -0.008211574517190456,
        -0.05580747500061989,
        0.049677010625600815,
        -0.03384118899703026,
        -0.015233969315886497,
        -0.01140014547854662,
        -0.011033018119633198,
        0.007093977648764849,
        -0.04811481758952141,
        -0.055507875978946686,
        0.053766053169965744,
        -0.05401545390486717,
        0.0021949016954749823,
        0.05228855088353157,
        0.051020096987485886,
        -0.012285816483199596,
        0.05037473142147064,
        0.0492846854031086,
        -0.013781373389065266,
        0.0302614513784647,
        -0.03364843875169754,
        -0.03149775043129921,
        -0.03735576197504997,
        0.055726002901792526,
        0.054440565407276154,
        0.015217534266412258,
        -0.018965305760502815,
        0.04527993127703667,
        0.05195225775241852,
        -0.05533923953771591,
        0.051877863705158234,
        -0.055252425372600555,
        0.04064345732331276,
        0.049615491181612015,
        -0.049302373081445694,
        -0.04321368783712387,
        0.0267284344881773,
        -0.045028600841760635,
        -0.05157768353819847,
        0.04787314310669899,
        0.008197502233088017,
        0.04878086596727371,
        0.04460574686527252,
        0.05570141226053238,
        0.05045826733112335,
        0.05448296293616295,
        -0.05117858946323395,
        0.019531607627868652,
        -0.052278369665145874,
        -0.017620990052819252,
        -0.0556744821369648,
        -0.04402007535099983,
        0.05485799163579941,
        -0.05356943607330322,
        0.054778091609478,
        0.03289489448070526,
        -0.05582220107316971,
        0.05410652607679367,
        0.03468616306781769,
        0.040424369275569916,
        0.007052072323858738,
        0.02132992446422577,
        0.03912609815597534,
        -0.014659455977380276,
        0.025140585377812386,
        0.055856671184301376,
        0.0282822847366333,
        -0.05492107570171356,
        0.034813158214092255,
        -0.05056706815958023,
        0.05217312276363373,
        0.055631764233112335,
        0.0488751046359539,
        -0.05502726882696152,
        -0.00692665996029973,
        0.0555625855922699,
        0.05343436077237129,
        0.045542728155851364,
        -0.013401422649621964,
        -0.024891579523682594,
        0.054897207766771317,
        0.016635702922940254,
        0.05587290972471237,
        0.02119668945670128,
        0.05263270437717438,
        -0.0547972172498703,
        -0.03418169543147087,
        -0.055397674441337585,
        0.015286239795386791,
        -0.04265867918729782,
        0.05435265973210335,
        -0.02769804373383522,
        0.053095556795597076,
        0.014298588037490845,
        -0.045988935977220535,
        0.029585005715489388,
        -0.015313402749598026,
        0.05158804729580879,
        -0.021245956420898438,
        -0.05182718485593796,
        -0.00021759951778221875,
        -0.05491790175437927,
        0.04836488142609596,
        0.05479700490832329,
        0.05229157581925392,
        0.05392972379922867,
        -0.036209262907505035,
        0.04902968555688858,
        0.05542415753006935,
        0.04506182298064232,
        -0.010181549936532974,
        -0.05570491775870323,
        -0.05130663514137268,
        -0.005447819363325834,
        -0.05572878569364548,
        -0.04175044223666191,
        -0.04674511030316353,
        0.013967993669211864,
        0.038920726627111435,
        0.04071599617600441,
        0.04691682755947113,
        -0.0350775420665741,
        -0.055425677448511124,
        -0.05573825165629387,
        0.05532950535416603,
        -0.04478014260530472,
        0.03012986294925213,
        -0.05572451278567314,
        -0.04824310913681984,
        -0.0024871721398085356,
        0.052775777876377106,
        -0.038005854934453964,
        0.054562319070100784,
        0.05554325506091118,
        -0.04539487138390541,
        -0.024369679391384125,
        0.05539437010884285,
        -0.03588801994919777,
        -0.052577242255210876,
        0.01248858030885458,
        -0.05077306926250458,
        -0.05263940989971161,
        0.05248091369867325,
        -0.055291369557380676,
        -0.013945897109806538,
        -0.04860013350844383,
        -0.04775506258010864,
        -0.05468447878956795,
        -0.0519392229616642,
        0.049961674958467484,
        -0.05434773489832878,
        0.033812765032052994,
        0.052507515996694565,
        0.05444236844778061,
        0.03827284276485443,
        -0.0524873286485672,
        -0.052488673478364944,
        -0.054172638803720474,
        0.0007153113256208599,
        0.055032532662153244,
        0.012790471315383911,
        0.007237575948238373,
        0.002818042878061533,
        0.04060410335659981,
        0.04849562421441078,
        -0.054584112018346786,
        0.05507081374526024,
        0.04031848907470703,
        0.009891672059893608,
        -0.04994544759392738,
        -0.022565094754099846,
        -0.0483008548617363,
        0.049477722495794296,
        -0.05198350176215172,
        -0.020802883431315422,
        -0.043979156762361526,
        0.055588629096746445,
        0.054652731865644455,
        0.05522351711988449,
        -0.05586521700024605,
        -0.05499235913157463,
        -0.05584172159433365,
        -0.04925411194562912,
        0.04577947407960892,
        0.010007894597947598,
        -0.05325615406036377,
        -0.0557180792093277,
        -0.0550924651324749,
        0.037567898631095886,
        -0.031151149421930313,
        -0.01543629914522171,
        0.04469951614737511,
        -0.05475001409649849,
        -0.054686665534973145,
        -0.055231932550668716,
        0.05214336887001991,
        -0.0555746890604496,
        -0.05397321656346321,
        0.013341439887881279,
        0.03890251740813255,
        0.054505620151758194,
        0.05537119135260582,
        -0.04285339638590813,
        0.04252443090081215,
        0.05458841472864151,
        -0.043183717876672745,
        0.019498983398079872,
        0.05587169528007507,
        -0.041901856660842896,
        0.035942841321229935,
        0.047840721905231476,
        0.0052978829480707645,
        0.0021393592469394207,
        -0.05561959743499756,
        -0.04273993521928787,
        -0.055028870701789856,
        0.012104934081435204,
        0.03287704661488533,
        0.054192714393138885,
        -0.05434354767203331,
        -0.03719000518321991,
        -0.05076269060373306,
        0.055637847632169724,
        0.05467483773827553,
        -0.054795797914266586,
        0.054015178233385086,
        0.02400275506079197,
        -0.05534450709819794,
        0.052192144095897675,
        -0.013251676224172115,
        0.05407099798321724,
        0.02810739353299141,
        -0.011451218277215958,
        -0.007627931889146566,
        0.01609327457845211,
        0.03867221996188164,
        -0.05181097984313965,
        -0.04170630872249603,
        -0.05393541604280472,
        -0.0311551783233881,
        -0.012467182241380215,
        0.04854707047343254,
        0.006746269296854734,
        0.04972342774271965,
        0.05586263909935951,
        0.054576627910137177,
        -0.054308775812387466,
        -0.05545630306005478,
        -0.051259033381938934,
        -0.055743150413036346,
        0.028425348922610283,
        0.04584810882806778,
        -0.05300556868314743,
        -0.0485268197953701,
        -0.046058572828769684,
        -0.052326805889606476,
        0.04526986554265022,
        0.04102190583944321,
        -0.05559128150343895,
        -0.05409577488899231,
        -0.023332633078098297,
        -0.05260369926691055,
        0.016753828153014183,
        0.053201824426651,
        0.05467567592859268,
        0.015169295482337475,
        0.04970068857073784,
        -0.04727181792259216,
        0.0368318185210228,
        0.04973393306136131
    ],
    [
        -0.014259742572903633,
        -0.0532064326107502,
        -0.05108081176877022,
        -0.03328227624297142,
        -0.040753547102212906,
        -0.053211748600006104,
        0.05301721766591072,
        -0.05179747939109802,
        -0.027905911207199097,
        0.042429640889167786,
        0.05290083587169647,
        0.029115993529558182,
        -0.0505816824734211,
        0.05297524482011795,
        -0.0530056431889534,
        0.04581844061613083,
        -0.049338921904563904,
        0.053189851343631744,
        -0.05320226028561592,
        0.04317215830087662,
        -0.040692295879125595,
        -0.05208519101142883,
        0.053109701722860336,
        -0.0491839237511158,
        -0.0532049760222435,
        0.04394850134849548,
        -0.052734531462192535,
        0.05282922089099884,
        0.03691255301237106,
        0.03845514357089996,
        0.05213699862360954,
        0.05303993076086044,
        0.04767504334449768,
        -0.05318162590265274,
        0.05117164924740791,
        -0.049845948815345764,
        -0.052179861813783646,
        -0.05254779011011124,
        -0.053212422877550125,
        -0.022832447662949562,
        0.051116980612277985,
        -0.043654199689626694,
        0.04723965376615524,
        -0.04266984015703201,
        0.04534154385328293,
        0.05080980435013771,
        0.05293135717511177,
        0.048329226672649384,
        0.05320468544960022,
        -0.05191407725214958,
        0.052978381514549255,
        0.04856115207076073,
        -0.011914560571312904,
        -0.051863621920347214,
        -0.0491936095058918,
        0.0530930832028389,
        -0.038706500083208084,
        -0.01010559406131506,
        -0.05255429446697235,
        -0.05129862576723099,
        -0.053194258362054825,
        0.020362019538879395,
        -0.05095165967941284,
        -0.039035603404045105,
        0.008509344421327114,
        -0.0502793975174427,
        -0.052456922829151154,
        -0.04520321264863014,
        0.019074639305472374,
        -0.05321115255355835,
        -0.043393995612859726,
        -0.05319618806242943,
        0.05321022868156433,
        -0.0035848612897098064,
        0.048337921500205994,
        -0.03869083896279335,
        0.05189399793744087,
        0.05321227386593819,
        -0.011630760505795479,
        0.04979467764496803,
        -0.051956843584775925,
        -0.051454268395900726,
        -0.0012282142415642738,
        0.04748478904366493,
        0.05138899013400078,
        0.04619225859642029,
        -0.05246945470571518,
        -0.0036740454379469156,
        0.012481071054935455,
        -0.014317977242171764,
        0.04219319298863411,
        0.03716731071472168,
        0.052833616733551025,
        -0.05319414287805557,
        -0.045765794813632965,
        -0.0320449136197567,
        -0.05306011065840721,
        -0.05309058725833893,
        -0.03539826348423958,
        -0.011069025844335556,
        -0.0530896820127964,
        0.05270875245332718,
        0.04196477308869362,
        -0.019791927188634872,
        0.03239961341023445,
        0.0033846807200461626,
        -0.03175373375415802,
        0.04523659870028496,
        -0.052799247205257416,
        0.04023125395178795,
        0.04209477826952934,
        0.042933106422424316,
        -0.03803117945790291,
        0.04414961487054825,
        0.04811495915055275,
        -0.05307335779070854,
        -0.03098420239984989,
        -0.052890218794345856,
        -0.005700987298041582,
        0.04375704750418663,
        -0.018444279208779335,
        0.05221628025174141,
        -0.007841276936233044,
        -0.03333813324570656,
        -0.05030546709895134,
        0.011358890682458878,
        0.041855402290821075,
        -0.052565742284059525,
        0.05302610248327255,
        -0.04619130864739418,
        0.05263734608888626,
        -0.050567876547575,
        -0.05278496444225311,
        0.050094690173864365,
        -0.051311928778886795,
        -0.0531308613717556,
        0.053182199597358704,
        0.016783254221081734,
        -0.04616469889879227,
        0.05308607593178749,
        0.052849095314741135,
        -0.016759006306529045,
        0.04718684405088425,
        -0.04934044927358627,
        0.045572053641080856,
        0.053182125091552734,
        -0.008987403474748135,
        0.030074715614318848,
        -0.046501290053129196,
        0.0461767353117466,
        -0.05258631706237793,
        -0.05284319072961807,
        -0.05303090810775757,
        -0.013552147895097733,
        0.03838337957859039,
        -0.02679520472884178,
        0.028262296691536903,
        -0.01242290623486042,
        -0.04877622425556183,
        -0.04230281338095665,
        0.05149244889616966,
        0.0319085456430912,
        0.04616665095090866,
        0.05310013145208359,
        -0.009571605361998081,
        -0.052874330431222916,
        0.017448559403419495,
        -0.044029563665390015,
        0.04558858647942543,
        0.05052317678928375,
        -0.05236389487981796,
        0.053080786019563675,
        -0.04545800760388374,
        -0.05197502672672272,
        -0.05316470190882683,
        0.039952635765075684,
        -0.053212400525808334,
        -0.02269241027534008,
        0.05271245166659355,
        -0.05308326706290245,
        0.01086021214723587,
        0.004287039395421743,
        0.04995380714535713,
        -0.047975584864616394,
        -0.04499361291527748,
        0.05316077917814255,
        -0.05289649963378906,
        0.052467115223407745,
        0.05294300243258476,
        0.05267489328980446,
        -0.05296569690108299,
        -0.024283112958073616,
        0.008802066557109356,
        0.04480528086423874,
        -0.05292673036456108,
        0.01955283060669899,
        0.053199827671051025,
        0.04983903840184212,
        -0.04640869423747063,
        0.04400763660669327,
        0.042825739830732346,
        0.015262736938893795,
        0.04992629960179329,
        0.05270988866686821,
        0.049759652465581894,
        0.05223486199975014,
        0.052352238446474075,
        0.05270273610949516,
        -0.050460219383239746,
        -0.050204623490571976,
        -0.04591994360089302,
        0.05241801217198372,
        0.04585092514753342,
        0.05088198184967041,
        0.04422519728541374,
        -0.05311966314911842,
        0.05318765342235565,
        -0.050197847187519073,
        0.05321228504180908,
        0.052589986473321915,
        0.05308764427900314,
        0.04198181629180908,
        -0.04799969121813774,
        -0.05231761559844017,
        0.05270588770508766,
        -0.05241341143846512,
        0.051385413855314255,
        0.0449753999710083,
        0.03189506381750107,
        -0.05169862508773804,
        -0.04035055264830589,
        -0.05315936356782913,
        -0.04307080805301666,
        0.046930961310863495,
        0.034208692610263824,
        0.053078748285770416,
        0.05179904028773308,
        0.03957092761993408,
        -0.05237671732902527,
        0.052992790937423706,
        0.006713114213198423,
        0.03002111427485943,
        -0.05314928665757179,
        -0.04882807284593582,
        -0.000006003147063893266,
        0.04654844105243683,
        -0.023436177521944046,
        0.053208813071250916,
        -0.006356092169880867,
        0.013281181454658508,
        -0.02042093686759472,
        -0.049997854977846146,
        -0.03813262656331062,
        -0.05281999707221985,
        0.04272962734103203,
        0.05305790528655052,
        0.03745873272418976,
        0.028533589094877243,
        0.045763708651065826,
        0.0526481494307518,
        -0.0487188883125782,
        0.020707212388515472,
        -0.050676435232162476,
        0.04817623645067215,
        -0.0024753976613283157,
        0.028595920652151108,
        -0.037653274834156036,
        0.043018803000450134,
        0.01582208089530468,
        0.04517579823732376,
        0.00479495245963335,
        -0.02970234490931034,
        0.046388693153858185,
        -0.05314817652106285,
        -0.053089771419763565,
        -0.027828274294734,
        -0.050089288502931595,
        -0.030255092307925224,
        0.04855958744883537,
        0.035227786749601364,
        -0.053142957389354706,
        -0.0528334379196167,
        -0.042662616819143295,
        0.007734529208391905,
        0.04908546432852745,
        -0.04286738485097885,
        0.04653412103652954,
        -0.0531100295484066,
        -0.04987698793411255,
        0.033264920115470886,
        0.026458153501152992,
        0.04480532184243202,
        -0.03365457430481911,
        -0.05118085816502571,
        0.052285488694906235,
        -0.05227665975689888,
        0.05307750403881073,
        0.007323906756937504,
        -0.05311186611652374,
        0.05315766856074333,
        0.029719145968556404,
        -0.025302918627858162,
        0.03453140705823898,
        0.04612365737557411,
        0.04787817969918251,
        0.011778278276324272,
        -0.05244768038392067,
        0.04020887240767479,
        0.05176624283194542,
        -0.05008977651596069,
        0.03964444622397423,
        -0.04873479902744293,
        -0.03677275404334068,
        0.053031958639621735,
        0.038123294711112976,
        -0.05291002243757248,
        -0.006711697671562433,
        0.05321174114942551,
        0.04915522411465645,
        -0.042940206825733185,
        -0.05273362994194031,
        -0.0354476198554039,
        0.049631621688604355,
        0.052310336381196976,
        0.0531977042555809,
        0.04641231521964073,
        0.052816249430179596,
        -0.03397910296916962,
        -0.05101826786994934,
        0.00893402099609375,
        -0.02800804004073143,
        0.015591264702379704,
        0.04449337348341942,
        0.03961867466568947,
        0.052205733954906464,
        -0.04823288694024086,
        -0.049323149025440216,
        0.02966328151524067,
        -0.03984128311276436,
        0.008287913165986538,
        -0.020866749808192253,
        -0.04959983006119728,
        -0.050537481904029846,
        0.014358731918036938,
        0.05321253836154938,
        0.052753008902072906,
        0.0270790234208107,
        0.053025733679533005,
        -0.05313463881611824,
        0.049786102026700974,
        -0.053140126168727875,
        0.05217127874493599,
        -0.012823265977203846,
        -0.0013248494360595942,
        -0.05310777202248573,
        0.0471823513507843,
        -0.05320778489112854,
        -0.049861982464790344,
        -0.05064839869737625,
        -0.046893294900655746,
        0.049611423164606094,
        -0.02153947204351425,
        -0.013487222604453564,
        -0.032302383333444595,
        0.04897952452301979,
        -0.0529920868575573,
        0.05303029716014862,
        0.03692251071333885,
        0.046816229820251465,
        -0.05318765342235565,
        -0.047079898416996,
        0.03007899783551693,
        0.05257357656955719,
        -0.04948941990733147,
        0.052701931446790695,
        0.0502937026321888,
        -0.052514560520648956,
        -0.05308874323964119,
        -0.024818485602736473,
        -0.019461212679743767,
        0.05148928984999657,
        0.05017823353409767,
        -0.0529860258102417,
        -0.003206387860700488,
        0.05254631116986275,
        -0.04252852499485016,
        -0.05302577465772629,
        -0.051739972084760666,
        -0.05264999344944954,
        -0.05279958248138428,
        -0.03903988003730774,
        -0.03198063001036644,
        -0.05312120541930199,
        0.05250915139913559,
        -0.0003778718819376081,
        0.04944223165512085,
        0.053194232285022736,
        -0.029089385643601418,
        -0.04402400553226471,
        -0.0529145784676075,
        -0.050868310034275055,
        0.05263787508010864,
        -0.04768126457929611,
        0.05316619947552681,
        -0.046297524124383926,
        0.015009528025984764,
        0.05288487672805786,
        0.04951939359307289,
        0.05301426723599434,
        -0.04950584098696709,
        0.0521475225687027,
        -0.046366557478904724,
        -0.03761296719312668,
        -0.03574664518237114,
        0.037542831152677536,
        -0.020534539595246315,
        0.053165338933467865,
        0.04886233061552048,
        0.02084525302052498,
        -0.021293221041560173,
        0.05302397906780243,
        -0.05320572480559349,
        -0.04602598771452904,
        -0.05262859910726547,
        0.01689402014017105,
        0.014363019727170467,
        0.044976770877838135,
        0.052790235728025436,
        -0.039299871772527695,
        -0.03828473761677742,
        -0.04915442317724228,
        -0.03932035341858864,
        -0.05284658074378967,
        -0.02446639910340309,
        -0.051603518426418304,
        -0.04565447196364403,
        -0.053208526223897934,
        0.052621595561504364,
        -0.027704110369086266,
        -0.05096520856022835,
        -0.05259409174323082,
        0.04686945304274559,
        -0.032714832574129105,
        0.05086499825119972,
        0.04750821739435196,
        0.01692654937505722,
        0.05307399854063988,
        -0.05320903658866882,
        0.047129541635513306,
        0.0419846773147583,
        -0.010460318066179752,
        0.027545029297471046,
        0.040141280740499496,
        0.04150799661874771,
        -0.052872657775878906,
        -0.04980451986193657,
        -0.025887614116072655,
        -0.03274960815906525,
        -0.053208570927381516,
        0.01321148406714201,
        0.052881039679050446,
        -0.053208451718091965,
        -0.03442353010177612,
        -0.05086314305663109,
        0.052651163190603256,
        0.053119778633117676,
        -0.04931147024035454,
        0.05315503478050232,
        0.05301639437675476,
        -0.052862294018268585,
        0.05130809545516968,
        0.05295157432556152,
        0.04738814756274223,
        0.050332892686128616,
        0.0517449788749218,
        0.01080483477562666,
        0.03916497156023979,
        -0.005406725220382214,
        -0.04300627484917641,
        0.003476769896224141,
        -0.05320128798484802,
        -0.051136478781700134,
        0.02790931798517704,
        0.05204704776406288,
        -0.05251389369368553,
        0.053084950894117355,
        0.04165637865662575,
        -0.015316170640289783,
        -0.04868395999073982,
        0.03236793726682663,
        -0.04266023635864258,
        -0.04394671320915222,
        0.020842907950282097,
        0.03898592293262482,
        0.027475319802761078,
        -0.044095560908317566,
        -0.04379153996706009,
        0.042403750121593475,
        0.052768733352422714,
        -0.04424998164176941,
        0.05320492759346962,
        -0.05279110372066498,
        0.03255802392959595,
        0.02972472459077835,
        0.05247537046670914,
        0.011400089599192142,
        -0.03500291332602501,
        -0.015049147419631481,
        0.03973589092493057,
        -0.04174488037824631,
        0.053106989711523056,
        0.051967985928058624
    ],
    [
        0.02594614215195179,
        -0.018391458317637444,
        0.04385390132665634,
        -0.03316543251276016,
        -0.04220357537269592,
        -0.05396270751953125,
        0.05224218592047691,
        -0.05472315475344658,
        0.052646294236183167,
        0.017692385241389275,
        0.045179568231105804,
        0.037823475897312164,
        -0.05417725443840027,
        0.025412490591406822,
        -0.0546913780272007,
        0.012241166085004807,
        0.045968107879161835,
        0.05457557737827301,
        -0.02868017740547657,
        0.04287757724523544,
        0.052671778947114944,
        0.038612399250268936,
        -0.0009490171796642244,
        0.04933132603764534,
        -0.01862119697034359,
        0.04540347680449486,
        0.05269872024655342,
        0.01679408736526966,
        -0.05414748936891556,
        -0.04866710677742958,
        -0.00779167702421546,
        0.04808822274208069,
        0.02935391664505005,
        -0.053898006677627563,
        0.05468878522515297,
        -0.054519567638635635,
        -0.05425747483968735,
        -0.0489937886595726,
        0.054917797446250916,
        -0.03177254647016525,
        -0.007382548879832029,
        -0.03201204165816307,
        0.054139211773872375,
        -0.0536809116601944,
        0.00975118763744831,
        0.0341050885617733,
        0.01210818812251091,
        0.05476660281419754,
        0.05493465065956116,
        -0.018769146874547005,
        0.05491606518626213,
        0.05156416445970535,
        0.05345645174384117,
        -0.03769257664680481,
        -0.04598842188715935,
        -0.021084202453494072,
        -0.00022262937272898853,
        0.0009515537531115115,
        -0.05477326735854149,
        -0.051037825644016266,
        -0.05491328984498978,
        0.052304212003946304,
        -0.05473233759403229,
        -0.02660040184855461,
        0.01979117840528488,
        -0.03408137336373329,
        -0.05491107702255249,
        -0.03681391477584839,
        0.05397844687104225,
        -0.03531447798013687,
        0.03733910620212555,
        -0.05349072068929672,
        0.05466257408261299,
        0.05113711208105087,
        0.05450333282351494,
        -0.05166232958436012,
        0.04053407907485962,
        0.054938945919275284,
        0.04811166226863861,
        0.04452242702245712,
        -0.03411119431257248,
        -0.0018518741708248854,
        -0.05234665423631668,
        0.038267526775598526,
        0.050560906529426575,
        0.03352483734488487,
        0.03836895152926445,
        -0.03588489443063736,
        -0.05463152006268501,
        0.04938675835728645,
        -0.053861651569604874,
        -0.031051235273480415,
        -0.05369000509381294,
        -0.037079814821481705,
        -0.04172606021165848,
        -0.02496631070971489,
        -0.03397023305296898,
        -0.0548638291656971,
        -0.0026819310151040554,
        0.05254348739981651,
        -0.05493507161736488,
        0.054852407425642014,
        0.054487332701683044,
        0.05152366682887077,
        -0.01682031713426113,
        -0.05473700165748596,
        -0.05430836230516434,
        0.05329873785376549,
        -0.00792622473090887,
        0.03306999430060387,
        -0.019894065335392952,
        -0.05422689765691757,
        -0.018964363262057304,
        -0.05318819731473923,
        0.05025774613022804,
        0.020957430824637413,
        -0.02783331833779812,
        -0.05418780446052551,
        -0.014942768961191177,
        -0.05177684128284454,
        -0.042361676692962646,
        -0.04704272747039795,
        0.04002387821674347,
        -0.05467678979039192,
        -0.05426263064146042,
        0.0472237691283226,
        -0.03703636676073074,
        0.03774305060505867,
        0.05475446209311485,
        0.035834453999996185,
        0.037796709686517715,
        -0.0542132705450058,
        -0.04911864176392555,
        0.05475413799285889,
        -0.048454925417900085,
        -0.054812557995319366,
        0.054762959480285645,
        -0.013590521179139614,
        -0.042146265506744385,
        0.03481137752532959,
        -0.044557686895132065,
        -0.05342040956020355,
        0.0004259713750798255,
        0.05486704036593437,
        0.03284945711493492,
        0.01124607678502798,
        0.054716553539037704,
        -0.01846732199192047,
        -0.04229577258229256,
        0.02934206835925579,
        -0.04106055945158005,
        -0.05490290746092796,
        -0.05227905511856079,
        -0.0472056120634079,
        0.04277573898434639,
        -0.024881884455680847,
        -0.044686682522296906,
        -0.046194497495889664,
        0.051088523119688034,
        0.0322536937892437,
        0.0138572808355093,
        0.04440564289689064,
        0.05272471159696579,
        0.054205503314733505,
        0.03469116613268852,
        0.04431769996881485,
        0.020662998780608177,
        0.04899692162871361,
        -0.0170082189142704,
        0.04191863164305687,
        -0.02842850051820278,
        0.024632345885038376,
        -0.05464411899447441,
        0.05195056274533272,
        0.00427647028118372,
        -0.005642934702336788,
        -0.025324122980237007,
        -0.052662406116724014,
        0.05488349869847298,
        -0.040888454765081406,
        -0.046009548008441925,
        0.05451945215463638,
        0.05435967445373535,
        -0.05380508303642273,
        0.05383475124835968,
        -0.02067740634083748,
        0.022653045132756233,
        0.05428288131952286,
        -0.030743932351469994,
        -0.034970179200172424,
        -0.054392021149396896,
        0.044971924275159836,
        0.019449321553111076,
        0.04626116156578064,
        -0.05330464988946915,
        0.047873448580503464,
        0.032372746616601944,
        0.03638326749205589,
        -0.016459336504340172,
        0.05281475558876991,
        0.054004233330488205,
        0.0532086156308651,
        -0.05175560340285301,
        -0.046168968081474304,
        -0.007908483035862446,
        0.047584690153598785,
        0.0528765507042408,
        0.04818302392959595,
        -0.05223003774881363,
        0.054180290549993515,
        -0.05324012413620949,
        -0.025852862745523453,
        0.04872177541255951,
        0.05347178503870964,
        -0.032259371131658554,
        -0.03186206892132759,
        0.054863039404153824,
        0.050914566963911057,
        0.05490991473197937,
        -0.003151915967464447,
        -0.021279405802488327,
        0.053482748568058014,
        0.010221263393759727,
        -0.05242960527539253,
        0.05484379082918167,
        -0.04713921993970871,
        0.04630809649825096,
        -0.04519996792078018,
        0.02341395802795887,
        -0.054934319108724594,
        -0.050547778606414795,
        -0.04767994582653046,
        -0.05187404900789261,
        0.052446309477090836,
        0.04488355666399002,
        0.05493009090423584,
        0.05408676713705063,
        -0.004448512103408575,
        -0.032462798058986664,
        0.05493708327412605,
        -0.03296803683042526,
        0.04818766936659813,
        -0.04809961095452309,
        -0.04667222499847412,
        0.04943746328353882,
        -0.004210406914353371,
        0.04775328189134598,
        0.044071540236473083,
        -0.05014749988913536,
        -0.03940621390938759,
        -0.050740018486976624,
        -0.05437064543366432,
        0.05347568541765213,
        -0.05389369651675224,
        -0.051291324198246,
        0.05155802518129349,
        0.020974917337298393,
        -0.02946528233587742,
        0.05444176122546196,
        0.05263063311576843,
        -0.043154243379831314,
        0.011034809052944183,
        0.02457086369395256,
        0.039643410593271255,
        0.005242000333964825,
        0.05452005937695503,
        0.0526486337184906,
        0.05450525879859924,
        0.036699142307043076,
        -0.03091059997677803,
        0.0509367398917675,
        -0.054708387702703476,
        0.04804541915655136,
        -0.05469147488474846,
        -0.04634249210357666,
        0.02331545017659664,
        -0.05105822533369064,
        0.03965838998556137,
        0.025026338174939156,
        0.041702546179294586,
        -0.05486971512436867,
        0.05083014816045761,
        -0.05440575256943703,
        -0.039601881057024,
        0.05238548666238785,
        0.036422550678253174,
        -0.04954966902732849,
        0.049641020596027374,
        0.04880179092288017,
        0.05349241942167282,
        -0.052557285875082016,
        0.04818832874298096,
        -0.028875304386019707,
        -0.029552480205893517,
        0.04748324304819107,
        -0.05451870337128639,
        0.05487311631441116,
        0.025421714410185814,
        -0.044669780880212784,
        0.054907433688640594,
        0.027332434430718422,
        0.04743538424372673,
        -0.049488604068756104,
        0.05449249967932701,
        0.03479873389005661,
        0.033508822321891785,
        -0.0454641655087471,
        0.049966201186180115,
        0.04733116552233696,
        -0.0548381507396698,
        0.03148059546947479,
        -0.023141225799918175,
        0.054605256766080856,
        0.054788704961538315,
        0.04146149381995201,
        -0.054449766874313354,
        0.009334046393632889,
        -0.03651902824640274,
        0.054531749337911606,
        0.016140872612595558,
        0.017397036775946617,
        -0.048174988478422165,
        0.034048039466142654,
        -0.00151561398524791,
        0.0549234114587307,
        -0.015285790897905827,
        0.054799504578113556,
        -0.0518842414021492,
        -0.048102136701345444,
        -0.054923031479120255,
        0.003873225999996066,
        -0.009339513257145882,
        -0.0411444790661335,
        -0.05206898972392082,
        0.054479118436574936,
        0.05483625829219818,
        -0.05001178756356239,
        0.048021458089351654,
        0.00015520871966145933,
        0.051962364464998245,
        -0.04903870448470116,
        -0.0011767300311475992,
        0.014645199291408062,
        -0.05149973928928375,
        0.054132040590047836,
        0.031618110835552216,
        0.053633466362953186,
        0.054587412625551224,
        -0.0067245387472212315,
        0.052505042403936386,
        0.05486650764942169,
        0.044533636420965195,
        0.05374010652303696,
        -0.05254871025681496,
        -0.04320553317666054,
        0.05365132912993431,
        -0.05466047674417496,
        -0.04121905937790871,
        0.052263032644987106,
        -0.02681940793991089,
        0.054438088089227676,
        0.04726297780871391,
        -0.0519544817507267,
        0.009471692144870758,
        -0.04762519896030426,
        -0.05421314015984535,
        0.05487822741270065,
        -0.05370674654841423,
        0.04064421355724335,
        -0.054577264934778214,
        -0.04426432028412819,
        -0.052340250462293625,
        0.0536128506064415,
        -0.05378792807459831,
        0.032205332070589066,
        0.050341926515102386,
        -0.007017768919467926,
        -0.03150486946105957,
        0.054849933832883835,
        -0.0431387834250927,
        -0.002042692620307207,
        0.04983391985297203,
        -0.046741925179958344,
        -0.025892840698361397,
        0.05339563637971878,
        -0.02133476547896862,
        -0.05152791365981102,
        -0.03790058568120003,
        -0.05308492109179497,
        -0.05269770324230194,
        -0.05255113169550896,
        0.04104083031415939,
        -0.05451493337750435,
        -0.052840542048215866,
        0.04148010537028313,
        0.05416218936443329,
        0.051263418048620224,
        -0.054104115813970566,
        -0.037402648478746414,
        -0.04453333467245102,
        0.010202681645751,
        0.054874058812856674,
        -0.03728995472192764,
        0.053694840520620346,
        -0.03952307999134064,
        -0.009363967925310135,
        0.04489515721797943,
        -0.05439255014061928,
        0.05276508256793022,
        -0.0017780186608433723,
        0.006328389514237642,
        -0.03292812034487724,
        -0.018764372915029526,
        -0.038218554109334946,
        0.05029606074094772,
        -0.05250241607427597,
        -0.048100586980581284,
        -0.02367839217185974,
        0.01955079846084118,
        0.05448385700583458,
        0.026844628155231476,
        -0.05458203703165054,
        -0.051267359405756,
        -0.05491732060909271,
        -0.04217544198036194,
        -0.018868308514356613,
        -0.010000131092965603,
        -0.04688958823680878,
        -0.051086049526929855,
        -0.05460318922996521,
        0.05474010854959488,
        -0.02404288575053215,
        0.033774856477975845,
        -0.020090140402317047,
        -0.04845355451107025,
        -0.05394725874066353,
        -0.054014597088098526,
        0.009526741690933704,
        -0.046165019273757935,
        -0.0548471175134182,
        -0.05301538109779358,
        0.05449299141764641,
        0.05473952740430832,
        0.05420645698904991,
        -0.05327942594885826,
        -0.0410214439034462,
        0.04277811944484711,
        -0.04732358828186989,
        0.04661715403199196,
        0.05490925908088684,
        0.051522690802812576,
        -0.0070456708781421185,
        0.052923448383808136,
        0.047484222799539566,
        0.0461268350481987,
        -0.05343376100063324,
        -0.051390621811151505,
        -0.054776087403297424,
        0.04204288125038147,
        0.05370892211794853,
        0.05351528525352478,
        -0.051343321800231934,
        -0.03719685971736908,
        -0.0535290390253067,
        0.05430438369512558,
        0.05484982952475548,
        -0.05454597249627113,
        0.05420989543199539,
        -0.04987935721874237,
        -0.05345022305846214,
        0.02292688749730587,
        0.035102471709251404,
        0.05464913696050644,
        0.05379734933376312,
        0.05254661291837692,
        0.011625781655311584,
        0.05191753804683685,
        0.0440186969935894,
        -0.053039684891700745,
        -0.04559016600251198,
        -0.053815264254808426,
        -0.017929792404174805,
        -0.02887032739818096,
        0.0044260174036026,
        -0.0033942994195967913,
        0.053873468190431595,
        0.054580189287662506,
        0.05288203805685043,
        -0.054364241659641266,
        -0.007628168910741806,
        0.03952334076166153,
        -0.04006900265812874,
        0.051992449909448624,
        -0.00975991040468216,
        -0.010399705730378628,
        -0.048128459602594376,
        -0.03550000861287117,
        -0.015430739149451256,
        0.05490889027714729,
        0.051225923001766205,
        -0.051016245037317276,
        -0.03096730075776577,
        -0.05469686910510063,
        -0.046287018805742264,
        0.05284362658858299,
        0.05300568416714668,
        0.05482608079910278,
        -0.0012925794580951333,
        0.05350835621356964,
        -0.054885827004909515,
        0.053647421300411224,
        0.005751384887844324
    ],
    [
        -0.0578528456389904,
        0.050770677626132965,
        0.020283276215195656,
        0.04131195694208145,
        0.05357687175273895,
        0.039037298411130905,
        0.06242358312010765,
        -0.03277977183461189,
        0.03224518895149231,
        0.05499931424856186,
        0.045383941382169724,
        0.060298431664705276,
        -0.056317754089832306,
        -0.042977530509233475,
        -0.06213080510497093,
        0.04273180291056633,
        -0.06263522803783417,
        0.048123136162757874,
        -0.024551620706915855,
        -0.05653630569577217,
        0.04304727166891098,
        0.0578807108104229,
        0.059263408184051514,
        0.05650319531559944,
        0.03547774627804756,
        0.017440082505345345,
        0.02049645595252514,
        -0.014147929847240448,
        -0.05491945892572403,
        0.014575827866792679,
        0.05362413078546524,
        -0.06264436990022659,
        0.0020921467803418636,
        -0.06209505349397659,
        -0.004088493064045906,
        0.017087046056985855,
        -0.06009476259350777,
        -0.02643720619380474,
        -0.051388710737228394,
        0.0069855875335633755,
        -0.023428378626704216,
        0.011229421012103558,
        0.05136885121464729,
        -0.05304885283112526,
        0.04111187160015106,
        0.03320055454969406,
        0.06178119406104088,
        0.060804564505815506,
        0.06263195723295212,
        0.05536065623164177,
        0.0577131062746048,
        -0.051423490047454834,
        0.047116752713918686,
        -0.04763796553015709,
        0.023176204413175583,
        -0.019209159538149834,
        0.0020824444945901632,
        -0.047369636595249176,
        -0.052064843475818634,
        -0.004554623272269964,
        -0.058366358280181885,
        -0.045286957174539566,
        -0.04957948997616768,
        -0.03665143623948097,
        -0.03948751837015152,
        0.056590355932712555,
        0.023661935701966286,
        -0.05053047463297844,
        -0.01859867200255394,
        -0.06278511136770248,
        -0.05955759435892105,
        -0.06273522228002548,
        0.0415165089070797,
        0.02746317721903324,
        -0.05960405617952347,
        0.018710002303123474,
        0.0022881715558469296,
        0.05401331186294556,
        0.019334616139531136,
        -0.01077471673488617,
        0.021105559542775154,
        0.016104422509670258,
        -0.058206189423799515,
        0.014061554335057735,
        -0.004099882207810879,
        0.014537890441715717,
        -0.06139140948653221,
        -0.055713996291160583,
        0.05238967388868332,
        0.059625234454870224,
        -0.04943322762846947,
        0.05258885398507118,
        -0.02131679281592369,
        -0.02429824136197567,
        -0.04995713382959366,
        0.04950445517897606,
        -0.05468891188502312,
        -0.04509981349110603,
        -0.01705983839929104,
        0.02288373000919819,
        -0.06244800239801407,
        0.00994101446121931,
        0.05526310205459595,
        0.04764855280518532,
        0.054084643721580505,
        0.018491104245185852,
        0.017746856436133385,
        0.04760674014687538,
        -0.06210761517286301,
        -0.05621882155537605,
        -0.05431795120239258,
        -0.06135481595993042,
        -0.003468502312898636,
        -0.04465391859412193,
        -0.017520876601338387,
        -0.0413321927189827,
        0.016716843470931053,
        -0.05417541414499283,
        -0.02651693858206272,
        -0.0123221380636096,
        -0.005619865842163563,
        0.019589172676205635,
        -0.05821455270051956,
        0.05569720268249512,
        -0.06158580631017685,
        -0.0558459646999836,
        -0.005218231584876776,
        0.04921656847000122,
        0.05399711802601814,
        -0.01278251875191927,
        0.05342651158571243,
        -0.01649271510541439,
        -0.045113738626241684,
        0.04932338744401932,
        -0.05623306334018707,
        -0.046514566987752914,
        0.02890031598508358,
        -0.043830160051584244,
        -0.015474500134587288,
        0.045024387538433075,
        0.033464252948760986,
        0.037255045026540756,
        0.04688446596264839,
        0.014027551747858524,
        0.0007592910551466048,
        0.060167860239744186,
        0.010824177414178848,
        -0.02477128803730011,
        -0.03685552254319191,
        0.05928598344326019,
        -0.029933296144008636,
        -0.06127997860312462,
        -0.05693010240793228,
        0.0035687307827174664,
        0.060018833726644516,
        -0.05951515957713127,
        0.061058033257722855,
        0.022438906133174896,
        0.024378016591072083,
        0.022586727514863014,
        -0.026807107031345367,
        0.008090202696621418,
        0.05325759947299957,
        -0.049277063459157944,
        -0.05864322558045387,
        0.009019074030220509,
        0.035657864063978195,
        -0.05026855319738388,
        -0.016629453748464584,
        0.026006149128079414,
        -0.04738098382949829,
        -0.03994496539235115,
        -0.06150059029459953,
        0.016717631369829178,
        -0.009015667252242565,
        0.060156892985105515,
        -0.05999109894037247,
        0.054740890860557556,
        0.049816593527793884,
        0.00991483498364687,
        0.00967314001172781,
        -0.04607660323381424,
        0.057264313101768494,
        0.03731110319495201,
        -0.02622036263346672,
        0.01481909491121769,
        -0.019664831459522247,
        0.0571352057158947,
        -0.05801989510655403,
        -0.029550427570939064,
        -0.03226066380739212,
        -0.049845218658447266,
        0.030743956565856934,
        -0.06015097722411156,
        -0.05107218027114868,
        0.009336315095424652,
        0.040713731199502945,
        0.050804246217012405,
        -0.025714915245771408,
        -0.005863979924470186,
        0.04223334416747093,
        0.05041458085179329,
        -0.04593347758054733,
        -0.022528231143951416,
        0.04853597655892372,
        -0.008012528531253338,
        0.029050949960947037,
        -0.006793511100113392,
        -0.045879390090703964,
        0.02972501702606678,
        -0.0614420585334301,
        0.04588451236486435,
        -0.041117385029792786,
        0.026945611461997032,
        0.0524701289832592,
        0.050080981105566025,
        0.04109131172299385,
        -0.04596228525042534,
        0.061761386692523956,
        -0.05158519372344017,
        -0.0022268854081630707,
        -0.04428887739777565,
        -0.052276406437158585,
        -0.06114340201020241,
        0.060900792479515076,
        -0.012093509547412395,
        -0.019018037244677544,
        -0.05620905011892319,
        -0.03190253674983978,
        -0.05349736288189888,
        0.035988952964544296,
        -0.06109175831079483,
        -0.01627827249467373,
        0.04690779000520706,
        0.03242221474647522,
        0.03895745798945427,
        0.060673344880342484,
        -0.02426939457654953,
        0.018210504204034805,
        0.05100409686565399,
        0.05716361477971077,
        0.06110940873622894,
        0.031078753992915154,
        -0.046704236418008804,
        0.004725006874650717,
        0.02440015599131584,
        0.04325639083981514,
        -0.033031705766916275,
        -0.05065415799617767,
        -0.02124433033168316,
        -0.05317551642656326,
        -0.0576428547501564,
        0.057058386504650116,
        -0.05722498893737793,
        -0.04627734795212746,
        0.06150595471262932,
        0.05922245234251022,
        -0.053444456309080124,
        0.05985688418149948,
        0.039739612489938736,
        -0.006796443369239569,
        0.05977616831660271,
        0.02967512235045433,
        0.05506548658013344,
        -0.049834221601486206,
        0.051203105598688126,
        0.02804562635719776,
        0.044237490743398666,
        0.0026594039518386126,
        -0.002619532635435462,
        0.018869196996092796,
        -0.061822887510061264,
        0.04714639112353325,
        -0.06005309522151947,
        0.005390536971390247,
        0.022299647331237793,
        -0.05831602215766907,
        -0.023690106347203255,
        -0.05121040344238281,
        -0.059505145996809006,
        -0.052438847720623016,
        -0.06114490330219269,
        0.02931388095021248,
        0.0410972461104393,
        -0.057677920907735825,
        -0.048685211688280106,
        0.019591931253671646,
        -0.028516899794340134,
        0.0027801867108792067,
        0.0279067512601614,
        -0.03622683510184288,
        0.04248077794909477,
        -0.04579214006662369,
        0.007524556014686823,
        -0.039346516132354736,
        -0.05679049342870712,
        0.061888188123703,
        0.051269080489873886,
        -0.01548664178699255,
        0.059485964477062225,
        -0.012438136152923107,
        0.053706131875514984,
        -0.02411467954516411,
        0.043156698346138,
        -0.03539559990167618,
        -0.05666859447956085,
        -0.0556972399353981,
        0.049126770347356796,
        0.035711973905563354,
        -0.061509281396865845,
        0.057070668786764145,
        -0.02312958426773548,
        -0.055710189044475555,
        0.046959131956100464,
        -0.06254933029413223,
        -0.05319288745522499,
        0.037249792367219925,
        0.060058049857616425,
        -0.007630269508808851,
        -0.05633162707090378,
        -0.026463834568858147,
        -0.042830873280763626,
        0.06052454188466072,
        -0.008903632871806622,
        0.042741671204566956,
        0.06079426780343056,
        0.028964925557374954,
        0.05162221938371658,
        -0.04320722445845604,
        -0.053889159113168716,
        0.0012392735807225108,
        -0.01609615981578827,
        0.06109349802136421,
        0.05031640827655792,
        0.05956089124083519,
        0.059594620019197464,
        -0.05428715795278549,
        0.05857209488749504,
        -0.0534447506070137,
        0.0449921153485775,
        0.02276349812746048,
        -0.060585711151361465,
        -0.03285149857401848,
        -0.04440987482666969,
        0.06284631788730621,
        0.038420431315898895,
        0.04617777839303017,
        0.03374769166111946,
        0.01480263751000166,
        0.060681648552417755,
        -0.05791640281677246,
        0.05880563706159592,
        -0.056279685348272324,
        -0.0622110441327095,
        -0.05377728119492531,
        0.05348077788949013,
        -0.0498618520796299,
        -0.03069458343088627,
        -0.03251345455646515,
        -0.049431148916482925,
        0.05068352818489075,
        0.05734420567750931,
        -0.06177131086587906,
        -0.028196407482028008,
        0.03506012633442879,
        -0.054604899138212204,
        0.0623745359480381,
        0.024255402386188507,
        0.02449110709130764,
        -0.062388475984334946,
        -0.055364035069942474,
        -0.06256956607103348,
        0.05095289275050163,
        0.031514063477516174,
        0.05136937275528908,
        0.06269892305135727,
        0.01577136106789112,
        0.060762349516153336,
        -0.0274298507720232,
        -0.058910440653562546,
        -0.04607974365353584,
        -0.032499589025974274,
        -0.06200753152370453,
        -0.041461870074272156,
        0.061917439103126526,
        -0.04419609159231186,
        -0.008845279924571514,
        -0.054847463965415955,
        -0.05443156138062477,
        -0.057694341987371445,
        -0.04089987277984619,
        0.011056995950639248,
        0.024099359288811684,
        -0.03154473751783371,
        -0.053302232176065445,
        0.06162227690219879,
        0.05457684025168419,
        0.04504935443401337,
        0.05497157573699951,
        -0.06275478005409241,
        -0.03920358046889305,
        0.05767946317791939,
        0.019059350714087486,
        0.053852327167987823,
        -0.03747924417257309,
        0.051558561623096466,
        0.0300626028329134,
        -0.02915724366903305,
        0.05906588211655617,
        -0.02844664268195629,
        0.03231301158666611,
        0.002961348742246628,
        -0.0001861737109720707,
        0.030145229771733284,
        0.05657656863331795,
        -0.024771034717559814,
        0.024304570630192757,
        0.03654051199555397,
        0.04521828889846802,
        0.05005687475204468,
        -0.04851885512471199,
        -0.03595290705561638,
        -0.019202489405870438,
        -0.06029767543077469,
        0.05847451090812683,
        -0.04720844328403473,
        0.027089226990938187,
        -0.05817902088165283,
        -0.060090404003858566,
        -0.04758632928133011,
        0.06272425502538681,
        -0.0306402537971735,
        -0.0066088223829865456,
        0.02540759928524494,
        0.04890289157629013,
        -0.010628211311995983,
        -0.05602332577109337,
        0.03985481709241867,
        -0.0172561202198267,
        -0.027289139106869698,
        0.04593599587678909,
        0.010115346871316433,
        0.058023013174533844,
        0.05705442279577255,
        0.013091920875012875,
        0.005118036642670631,
        0.06239481270313263,
        -0.05058019235730171,
        0.053476814180612564,
        -0.02808558940887451,
        -0.06201045587658882,
        0.059966713190078735,
        0.05912827327847481,
        -0.04924706369638443,
        0.021215640008449554,
        -0.04930943250656128,
        0.01969851739704609,
        0.030829094350337982,
        -0.0016677570529282093,
        -0.02394244261085987,
        0.017903106287121773,
        0.005322453565895557,
        -0.01578269526362419,
        0.052892930805683136,
        0.06041209027171135,
        0.06254006177186966,
        -0.06116233021020889,
        0.051846202462911606,
        -0.048193711787462234,
        -0.060509439557790756,
        0.012395107187330723,
        -0.039285704493522644,
        0.014348175376653671,
        0.05153559148311615,
        0.05768684670329094,
        0.04332634434103966,
        -0.013112334534525871,
        0.014413738623261452,
        -0.04091525822877884,
        -0.054079320281744,
        0.05450292304158211,
        -0.0045180898159742355,
        0.011385103687644005,
        0.05086735263466835,
        -0.029427189379930496,
        -0.01169662270694971,
        0.053780071437358856,
        0.01633381098508835,
        -0.05116909369826317,
        -0.01701844483613968,
        -0.020665917545557022,
        -0.061707381159067154,
        -0.05550924316048622,
        0.06045043468475342,
        0.0379825234413147,
        0.039507124572992325,
        -0.027185356244444847,
        -0.05857089161872864,
        0.05780209228396416,
        0.057968027889728546,
        -0.060375843197107315,
        0.057908084243535995,
        -0.012087597511708736,
        -0.057862263172864914,
        0.04591590538620949,
        0.0010705981403589249,
        -0.012888342142105103,
        0.030251355841755867,
        -0.0459442213177681,
        -0.02271178737282753,
        0.06217452883720398,
        0.0524711050093174
    ],
    [
        0.05166897550225258,
        0.025380130857229233,
        0.038684286177158356,
        -0.009903035126626492,
        -0.04451604560017586,
        0.0534837432205677,
        0.051452331244945526,
        -0.053150177001953125,
        0.05204522982239723,
        -0.04793499782681465,
        0.053716711699962616,
        0.009805968031287193,
        -0.053413353860378265,
        0.05411838740110397,
        -0.0538216196000576,
        0.05292509123682976,
        0.05085545405745506,
        0.05393078550696373,
        -0.0337759405374527,
        -0.04328629747033119,
        -0.025519277900457382,
        0.013634840957820415,
        0.0476352833211422,
        0.029755132272839546,
        0.001059188973158598,
        0.04564277455210686,
        -0.04664190486073494,
        0.05431971326470375,
        -0.05367295816540718,
        -0.04542679712176323,
        0.05332476273179054,
        -0.04233010858297348,
        -0.0033414638601243496,
        -0.05413151904940605,
        0.0480450876057148,
        -0.039998117834329605,
        -0.053002286702394485,
        0.053900495171546936,
        -0.0523667186498642,
        0.054137229919433594,
        0.05390292406082153,
        0.05089224874973297,
        0.050562240183353424,
        -0.014652114361524582,
        0.05002206191420555,
        0.016114016994833946,
        0.0516267828643322,
        0.05428045615553856,
        0.05409907549619675,
        -0.03534902259707451,
        0.05428539216518402,
        0.0537266805768013,
        -0.03793087601661682,
        0.029184531420469284,
        0.053738489747047424,
        -0.05423815920948982,
        0.03850408270955086,
        0.04415774345397949,
        -0.05415694788098335,
        -0.05219859257340431,
        -0.05431549996137619,
        0.02136366255581379,
        -0.05221350118517876,
        -0.0489027313888073,
        -0.03179570287466049,
        -0.031009245663881302,
        -0.05265940725803375,
        0.04782954230904579,
        -0.017949936911463737,
        -0.053433239459991455,
        -0.0019572158344089985,
        -0.05408131331205368,
        0.054392945021390915,
        0.037640489637851715,
        -0.047222331166267395,
        -0.032261498272418976,
        0.04932048171758652,
        0.05439896509051323,
        0.053400348871946335,
        0.0019474318251013756,
        -0.050380952656269073,
        -0.05418241769075394,
        -0.054132282733917236,
        0.04770537093281746,
        0.05423867702484131,
        0.04401412233710289,
        -0.05415790528059006,
        -0.04736524820327759,
        -0.030308546498417854,
        0.04086696729063988,
        0.01711730845272541,
        0.028241794556379318,
        0.042407725006341934,
        -0.05434608832001686,
        -0.04620405286550522,
        0.05195830017328262,
        -0.04398484528064728,
        -0.05402238294482231,
        -0.043774038553237915,
        0.052268411964178085,
        -0.05437290295958519,
        0.014855660498142242,
        0.03987736627459526,
        0.05403028056025505,
        0.034779831767082214,
        -0.053291745483875275,
        -0.042880214750766754,
        0.05310150235891342,
        -0.05031866952776909,
        0.05080929026007652,
        -0.05438702180981636,
        -0.03043355979025364,
        -0.0423693023622036,
        0.006280548870563507,
        0.05431036278605461,
        0.044889919459819794,
        -0.046773944050073624,
        -0.054164912551641464,
        0.052656784653663635,
        -0.05202016234397888,
        -0.03127415105700493,
        0.009540298022329807,
        0.02008700557053089,
        -0.049165766686201096,
        -0.046728745102882385,
        0.033027283847332,
        -0.05254116281867027,
        0.053528230637311935,
        0.05026724562048912,
        -0.02865125983953476,
        0.042659658938646317,
        -0.05123931169509888,
        0.00872065406292677,
        0.05146918073296547,
        -0.05156807601451874,
        -0.05389481410384178,
        0.05406635254621506,
        -0.04245680570602417,
        -0.038765501230955124,
        0.045089926570653915,
        0.054038021713495255,
        0.054014772176742554,
        0.050745997577905655,
        -0.05272848531603813,
        -0.04245469346642494,
        0.020924272015690804,
        0.05351927503943443,
        0.04352548345923424,
        -0.045298054814338684,
        0.054366860538721085,
        0.036995869129896164,
        -0.054273150861263275,
        -0.03741450235247612,
        -0.05284366384148598,
        -0.05084555223584175,
        -0.0359928160905838,
        0.0300946943461895,
        0.02337615191936493,
        0.05166267603635788,
        -0.038895878940820694,
        -0.05172208324074745,
        -0.05286334082484245,
        0.050601229071617126,
        0.010672593489289284,
        0.05435540899634361,
        -0.048559535294771194,
        0.0543513186275959,
        -0.013326002284884453,
        0.04579458013176918,
        0.047832638025283813,
        -0.05367874726653099,
        -0.04574892297387123,
        -0.054384537041187286,
        -0.053071994334459305,
        0.05436762422323227,
        0.026623714715242386,
        -0.05219639092683792,
        -0.02305018901824951,
        0.05429791286587715,
        -0.0543157123029232,
        -0.05269100144505501,
        -0.0442999005317688,
        0.014185663312673569,
        -0.01858539506793022,
        0.03783075511455536,
        -0.053809214383363724,
        0.04582706838846207,
        0.053781840950250626,
        -0.005628095008432865,
        0.03874228149652481,
        -0.05435296520590782,
        0.023030903190374374,
        0.03436334431171417,
        0.03989599272608757,
        -0.04923449084162712,
        -0.02742013707756996,
        0.04409743472933769,
        0.015021813102066517,
        0.01376702543348074,
        0.054392192512750626,
        0.054302528500556946,
        -0.02544444613158703,
        -0.03632091358304024,
        -0.04616896063089371,
        0.05064968392252922,
        -0.05038974806666374,
        0.030427362769842148,
        -0.034840695559978485,
        -0.053476933389902115,
        0.047699566930532455,
        -0.05303734540939331,
        0.008033758960664272,
        -0.04540940001606941,
        -0.02303607575595379,
        0.028657296672463417,
        -0.05359087139368057,
        0.05385461077094078,
        -0.013471598736941814,
        0.05367599055171013,
        -0.053505584597587585,
        0.05284259095788002,
        0.04519561305642128,
        -0.01945052482187748,
        -0.01873345673084259,
        0.05410980060696602,
        0.015431210398674011,
        0.053580719977617264,
        -0.029451753944158554,
        0.002874282654374838,
        -0.050545692443847656,
        -0.054373469203710556,
        -0.046031542122364044,
        -0.05298321694135666,
        0.04256002977490425,
        0.051502857357263565,
        0.0538434274494648,
        0.05367312580347061,
        -0.001604706747457385,
        -0.028585340827703476,
        0.054381828755140305,
        0.029076600447297096,
        0.053888171911239624,
        0.045749492943286896,
        -0.052678607404232025,
        0.048394232988357544,
        -0.010956230573356152,
        -0.031180251389741898,
        0.007849064655601978,
        -0.021295465528964996,
        -0.05106234923005104,
        -0.039077043533325195,
        -0.049526896327733994,
        0.054084327071905136,
        -0.05369601026177406,
        0.023246588185429573,
        0.054320141673088074,
        0.012291661463677883,
        -0.052132997661828995,
        0.04019400104880333,
        -0.03321017324924469,
        -0.05298219248652458,
        -0.03140436112880707,
        -0.0532337985932827,
        0.05292483791708946,
        -0.004677914548665285,
        0.054346777498722076,
        -0.0058995927684009075,
        0.05433020368218422,
        0.047006163746118546,
        0.0452776774764061,
        0.054033856838941574,
        -0.05200107768177986,
        0.054282296448946,
        -0.05211816355586052,
        0.021737374365329742,
        0.051903706043958664,
        -0.048776645213365555,
        0.015534586273133755,
        -0.02340312860906124,
        0.03702304884791374,
        -0.05426936224102974,
        -0.043567556887865067,
        -0.05377406254410744,
        0.02705906704068184,
        0.05353464558720589,
        -0.011123999953269958,
        -0.005774189252406359,
        0.04632909223437309,
        0.05107290670275688,
        0.03342434763908386,
        -0.05385999381542206,
        -0.021642066538333893,
        -0.04737788811326027,
        0.04752682149410248,
        0.05113515630364418,
        -0.05320826545357704,
        0.05428692698478699,
        0.048975322395563126,
        -0.0534745529294014,
        0.05401980131864548,
        0.05404543876647949,
        0.010226006619632244,
        -0.02285713329911232,
        0.02699529007077217,
        0.05436243861913681,
        -0.017057020217180252,
        -0.0453760102391243,
        0.053582675755023956,
        -0.016153931617736816,
        -0.05409533530473709,
        -0.004219856113195419,
        0.00367541890591383,
        0.0524265393614769,
        0.0315767340362072,
        -0.010535296984016895,
        -0.05425255373120308,
        0.026024749502539635,
        0.054398998618125916,
        0.0515412874519825,
        -0.00863451138138771,
        -0.05430643633008003,
        -0.025325089693069458,
        0.054343726485967636,
        -0.020684054121375084,
        0.05437052994966507,
        0.028945310041308403,
        0.05085655674338341,
        0.0481998510658741,
        -0.00936081912368536,
        -0.052152954041957855,
        -0.03086303360760212,
        -0.05243665352463722,
        0.05375027656555176,
        -0.024297719821333885,
        0.052563782781362534,
        0.05334354564547539,
        -0.052933380007743835,
        0.03577609732747078,
        0.03262145444750786,
        0.054161567240953445,
        -0.03476184234023094,
        -0.05341683328151703,
        0.0252096988260746,
        -0.0524853952229023,
        0.05425722524523735,
        0.0539051815867424,
        0.044428758323192596,
        0.05409407988190651,
        -0.052968233823776245,
        0.036819711327552795,
        0.05431165546178818,
        0.054370902478694916,
        0.017359916120767593,
        -0.05172751471400261,
        -0.05394052714109421,
        0.02477088011801243,
        -0.05432076007127762,
        -0.0540115050971508,
        -0.04444200173020363,
        0.0015218984335660934,
        0.05145880579948425,
        0.008015431463718414,
        -0.03614123538136482,
        -0.0530458502471447,
        0.04785747826099396,
        -0.0539788156747818,
        0.05432179570198059,
        -0.036080148071050644,
        0.0018082077149301767,
        -0.05413234978914261,
        -0.04909428209066391,
        -0.0040722377598285675,
        0.053435225039720535,
        -0.021096251904964447,
        0.032319195568561554,
        0.054397307336330414,
        0.04658786952495575,
        0.008280015550553799,
        0.05163072422146797,
        -0.011153159663081169,
        -0.049644287675619125,
        0.0281173437833786,
        -0.05250214412808418,
        -0.037772975862026215,
        0.05064695328474045,
        -0.039808329194784164,
        -0.05416039004921913,
        -0.0503881499171257,
        -0.05416901409626007,
        -0.054335128515958786,
        -0.051949869841337204,
        -0.024850163608789444,
        -0.05356753244996071,
        -0.05372164770960808,
        -0.018584677949547768,
        0.05384860187768936,
        0.05253803730010986,
        0.027096271514892578,
        -0.04389801621437073,
        -0.05326828733086586,
        -0.0315447673201561,
        0.05317627638578415,
        -0.03508778661489487,
        0.05345950275659561,
        0.0063414121977984905,
        -0.008970898576080799,
        0.0483086034655571,
        -0.04974943399429321,
        0.05409631505608559,
        0.03220924735069275,
        -0.05061589181423187,
        0.030142109841108322,
        0.03768709674477577,
        -0.04505530744791031,
        0.048727817833423615,
        -0.014446549117565155,
        -0.047758083790540695,
        0.022576196119189262,
        0.05295925959944725,
        0.02997586317360401,
        0.054078608751297,
        -0.04777209088206291,
        -0.0543958954513073,
        -0.05437231808900833,
        -0.02606203220784664,
        -0.04301019012928009,
        -0.05390510708093643,
        -0.03254813328385353,
        -0.04522262513637543,
        -0.05286112800240517,
        0.05326453596353531,
        -0.049824319779872894,
        0.03099299781024456,
        -0.04384426772594452,
        0.005112274084240198,
        -0.04279646649956703,
        -0.053390983492136,
        0.05240142345428467,
        -0.05327652767300606,
        -0.05181911215186119,
        0.0499393567442894,
        0.04391930624842644,
        0.04075635224580765,
        0.05123145878314972,
        0.03445863723754883,
        -0.032670751214027405,
        0.05308946222066879,
        -0.0008211550302803516,
        0.05077219009399414,
        0.05438936501741409,
        -0.04940369352698326,
        0.05297178775072098,
        -0.016674436628818512,
        -0.02545531466603279,
        0.05436435714364052,
        -0.052995335310697556,
        -0.03762960061430931,
        0.0036839416716247797,
        0.017382534220814705,
        0.03791380673646927,
        0.05420660227537155,
        -0.02079465426504612,
        -0.0463748499751091,
        -0.0220615416765213,
        0.05352703854441643,
        0.053862281143665314,
        -0.05422849953174591,
        0.05347519740462303,
        -0.047404296696186066,
        -0.054287709295749664,
        0.04341563582420349,
        -0.022279422730207443,
        0.02172696590423584,
        -0.029149703681468964,
        0.04983657971024513,
        -0.03719276562333107,
        -0.02825898863375187,
        0.05111744627356529,
        -0.05103325843811035,
        -0.05385977029800415,
        0.054316695779561996,
        -0.013242519460618496,
        0.047981105744838715,
        0.0516042597591877,
        -0.04912979528307915,
        0.04498325288295746,
        0.05435187742114067,
        0.047620683908462524,
        -0.04720229282975197,
        -0.05052363499999046,
        -0.03479341045022011,
        -0.05269014835357666,
        0.034570079296827316,
        0.05328074470162392,
        -0.05051048845052719,
        0.04536869376897812,
        -0.053087592124938965,
        0.020872244611382484,
        0.054010748863220215,
        0.05260203033685684,
        -0.04260957986116409,
        0.021687135100364685,
        0.041018515825271606,
        -0.05429336056113243,
        0.05092388018965721,
        0.05093776062130928,
        0.04755743220448494,
        -0.04794032499194145,
        0.05043620243668556,
        -0.05223681777715683,
        0.04123241826891899,
        0.03578445687890053
    ],
    [
        0.05357077717781067,
        0.04852679744362831,
        0.049857448786497116,
        -0.04039414972066879,
        -0.034894559532403946,
        -0.0007307423511520028,
        0.05136537924408913,
        -0.05400363728404045,
        0.054253581911325455,
        -0.055239830166101456,
        0.05527034029364586,
        0.04831557348370552,
        -0.05507761240005493,
        0.021140724420547485,
        -0.05565355345606804,
        0.025592321529984474,
        0.02279302477836609,
        0.05242865905165672,
        -0.05241428688168526,
        -0.037771787494421005,
        0.0544709749519825,
        0.002026680624112487,
        0.04722345620393753,
        0.03288348764181137,
        -0.04188857600092888,
        0.015238262712955475,
        -0.05298680067062378,
        0.05390326306223869,
        0.013232609257102013,
        0.05499307066202164,
        0.05558254197239876,
        -0.00495879678055644,
        -0.04809504374861717,
        -0.05515670031309128,
        0.03543662279844284,
        0.008919107727706432,
        -0.054603513330221176,
        -0.046724144369363785,
        -0.05384550988674164,
        0.052632465958595276,
        0.05554652214050293,
        -0.011683139950037003,
        0.05218448489904404,
        0.003226349363103509,
        0.04332725703716278,
        -0.008093778043985367,
        0.05019749701023102,
        0.05049411579966545,
        0.05565520003437996,
        0.0528024397790432,
        0.020258022472262383,
        0.04351646080613136,
        -0.011640716344118118,
        -0.045552920550107956,
        0.04557693749666214,
        -0.011299379169940948,
        -0.04327620193362236,
        0.021497201174497604,
        -0.051275886595249176,
        0.01442432589828968,
        -0.05453311651945114,
        -0.021342908963561058,
        -0.05234059318900108,
        0.04416415095329285,
        -0.034935180097818375,
        -0.04233646020293236,
        -0.05528876930475235,
        0.0023321020416915417,
        -0.010831743478775024,
        -0.055651936680078506,
        -0.054073184728622437,
        -0.05553174763917923,
        0.05522720515727997,
        0.055540286004543304,
        -0.05551070347428322,
        -0.048921868205070496,
        0.05564168095588684,
        0.05571645870804787,
        -0.04452245682477951,
        -0.021909309551119804,
        -0.05429448187351227,
        -0.05535739287734032,
        -0.04353456199169159,
        0.01376627292484045,
        0.05452413484454155,
        0.04977097734808922,
        -0.05182614177465439,
        -0.050691649317741394,
        0.05370013043284416,
        0.03573649376630783,
        -0.003670831210911274,
        -0.027596954256296158,
        0.053547248244285583,
        -0.05536150559782982,
        -0.048235561698675156,
        -0.041923124343156815,
        -0.04583144932985306,
        -0.05163972079753876,
        0.017977647483348846,
        0.047975704073905945,
        -0.05559924617409706,
        -0.021544136106967926,
        -0.022641105577349663,
        0.055442437529563904,
        -0.02656283788383007,
        -0.054614465683698654,
        -0.05117333307862282,
        0.054647788405418396,
        -0.05439921095967293,
        0.024623626843094826,
        -0.044239744544029236,
        0.04124198853969574,
        -0.051228117197752,
        0.02155197598040104,
        0.029776358976960182,
        -0.050512950867414474,
        0.03840536251664162,
        -0.055492646992206573,
        0.052806150168180466,
        0.04974362254142761,
        -0.05106133967638016,
        0.050368987023830414,
        -0.048507608473300934,
        -0.05501065030694008,
        -0.05383941903710365,
        0.054581306874752045,
        -0.05113682150840759,
        0.05206049978733063,
        0.055111199617385864,
        -0.048108600080013275,
        0.026752224192023277,
        -0.046081263571977615,
        -0.028743121773004532,
        0.018042346462607384,
        -0.055522892624139786,
        -0.05565677583217621,
        0.05539550259709358,
        0.023274295032024384,
        -0.012711720541119576,
        0.03467357158660889,
        0.05557618662714958,
        0.05568227544426918,
        0.0327194482088089,
        0.008245229721069336,
        0.002737739821895957,
        0.022090664133429527,
        0.04747382551431656,
        -0.03621558099985123,
        0.042790647596120834,
        0.05570438504219055,
        -0.00536471139639616,
        -0.05491187423467636,
        -0.03436127305030823,
        -0.054867759346961975,
        0.03281264379620552,
        0.03377894312143326,
        0.03020240180194378,
        -0.043229784816503525,
        0.05451958626508713,
        -0.004940175451338291,
        -0.0023390224669128656,
        0.019894037395715714,
        0.05368129536509514,
        0.055515043437480927,
        0.03020317666232586,
        -0.05121973901987076,
        0.05512436851859093,
        0.05418363958597183,
        0.05400191992521286,
        0.05286206305027008,
        -0.053690195083618164,
        0.050352055579423904,
        -0.055633097887039185,
        -0.0110469376668334,
        0.004026667680591345,
        0.054414793848991394,
        -0.05571401119232178,
        0.04754224792122841,
        0.05556013807654381,
        -0.04446400701999664,
        -0.019303401932120323,
        -0.050297632813453674,
        0.037514980882406235,
        -0.04700126126408577,
        -0.04571758955717087,
        -0.052055586129426956,
        0.0434553399682045,
        0.05438987910747528,
        -0.05555325746536255,
        0.052305273711681366,
        -0.055379271507263184,
        -0.05092758312821388,
        -0.045595720410346985,
        0.012603338807821274,
        -0.05546840280294418,
        0.041665155440568924,
        0.04939887672662735,
        0.022083649411797523,
        0.04178214445710182,
        0.05213753879070282,
        0.05551408603787422,
        -0.05211375281214714,
        -0.028092605993151665,
        -0.05318673700094223,
        0.039386630058288574,
        -0.03382382541894913,
        0.033863432705402374,
        0.05239082872867584,
        -0.04482704773545265,
        -0.05101485177874565,
        -0.04586814343929291,
        0.05508403480052948,
        -0.051655322313308716,
        0.038281846791505814,
        -0.04856806993484497,
        0.04387428238987923,
        0.03895730897784233,
        -0.025975631549954414,
        0.05570453032851219,
        -0.05551016703248024,
        0.04204851761460304,
        -0.004248607438057661,
        -0.05522746965289116,
        -0.032070767134428024,
        0.02787015028297901,
        -0.024635687470436096,
        0.04913761094212532,
        -0.05501875281333923,
        -0.03075259178876877,
        -0.04281662777066231,
        -0.055623456835746765,
        -0.05061572045087814,
        0.0017246698262169957,
        0.04941509664058685,
        0.04224843531847,
        0.05311708152294159,
        0.05010730028152466,
        -0.03243204951286316,
        -0.0535292886197567,
        0.0557134672999382,
        -0.022351762279868126,
        0.05569499358534813,
        0.014460602775216103,
        -0.05460803583264351,
        0.045945435762405396,
        -0.0012176736490800977,
        -0.04749665409326553,
        0.050314873456954956,
        -0.0409819521009922,
        -0.05019771307706833,
        -0.05551803484559059,
        -0.05562330409884453,
        0.055360082536935806,
        -0.05463222786784172,
        0.05348927527666092,
        0.054865818470716476,
        0.04845334589481354,
        -0.02080252394080162,
        0.05550291761755943,
        -0.008579340763390064,
        -0.028208669275045395,
        -0.04737181216478348,
        -0.009815934114158154,
        0.05438188463449478,
        0.04799113795161247,
        0.05550577864050865,
        -0.0168610867112875,
        -0.013531926088035107,
        0.0011248666560277343,
        0.055223073810338974,
        0.01112588495016098,
        -0.0546242855489254,
        0.054912030696868896,
        -0.0555899478495121,
        -0.052885159850120544,
        0.040016040205955505,
        -0.054266754537820816,
        0.034183576703071594,
        -0.05518711358308792,
        -0.03633052855730057,
        -0.05564425140619278,
        -0.054476551711559296,
        -0.055563729256391525,
        0.05567838251590729,
        0.052080921828746796,
        0.035295289009809494,
        0.03319389000535011,
        0.03654540702700615,
        -0.041604626923799515,
        -0.017177557572722435,
        -0.04198286309838295,
        0.05203348025679588,
        -0.04614550247788429,
        -0.05284390598535538,
        -0.01897321827709675,
        -0.050075363367795944,
        0.05557388812303543,
        0.05549795553088188,
        -0.0556945763528347,
        0.05562103912234306,
        -0.05265902727842331,
        0.054363831877708435,
        0.03826465830206871,
        0.051665712147951126,
        0.05557936057448387,
        -0.042127907276153564,
        -0.05491674318909645,
        0.054186105728149414,
        0.04795454442501068,
        -0.048699866980314255,
        0.04972459748387337,
        -0.05498190596699715,
        0.0318908616900444,
        0.05344395339488983,
        -0.023586707189679146,
        -0.05529024451971054,
        -0.01911119557917118,
        0.05541599169373512,
        0.03304791823029518,
        -0.030344219878315926,
        0.02742239646613598,
        0.026673007756471634,
        0.05394405871629715,
        0.015424956567585468,
        0.05496836081147194,
        0.04684853553771973,
        0.05552058294415474,
        -0.037475116550922394,
        0.0440581776201725,
        0.031009139493107796,
        0.022462615743279457,
        -0.012206807732582092,
        0.055602170526981354,
        0.0006185317179188132,
        0.054946400225162506,
        0.05364636704325676,
        -0.04961036145687103,
        0.05362258106470108,
        0.010280582122504711,
        0.045375265181064606,
        0.0009403087315149605,
        -0.051385559141635895,
        -0.05389533191919327,
        0.004731438588351011,
        0.05571376159787178,
        0.02159878984093666,
        0.04443958401679993,
        0.05410061776638031,
        -0.05537666007876396,
        0.04240838810801506,
        0.04009927064180374,
        0.04329530894756317,
        0.009659276343882084,
        -0.05105902627110481,
        -0.052585478872060776,
        0.052544210106134415,
        -0.05392039939761162,
        -0.05355377495288849,
        -0.0389694944024086,
        -0.051255397498607635,
        0.0425381176173687,
        -0.027124077081680298,
        -0.04719386249780655,
        -0.0498303659260273,
        -0.02685031294822693,
        -0.055597562342882156,
        0.055611737072467804,
        0.04337877780199051,
        0.05214916542172432,
        -0.05566948652267456,
        -0.04345395788550377,
        -0.03232954442501068,
        0.055611878633499146,
        -0.019973384216427803,
        0.0552663616836071,
        0.05350719392299652,
        -0.013778082095086575,
        -0.014464790001511574,
        0.0357498861849308,
        -0.05259700119495392,
        -0.014279250055551529,
        -0.009489564225077629,
        -0.055630654096603394,
        0.015315496362745762,
        0.05220029503107071,
        -0.05472170561552048,
        -0.026365211233496666,
        -0.05512252077460289,
        -0.055446505546569824,
        -0.05533728748559952,
        0.0201224647462368,
        0.03188684582710266,
        -0.05001331865787506,
        -0.012492471374571323,
        -0.04929115250706673,
        0.05516594648361206,
        0.05104236677289009,
        0.005962044466286898,
        -0.012154214084148407,
        -0.05492255836725235,
        -0.04962802678346634,
        0.05481409281492233,
        0.021627847105264664,
        0.05379525572061539,
        -0.012812917120754719,
        -0.04708606377243996,
        0.039707642048597336,
        -0.04552551358938217,
        0.05226542800664902,
        -0.05202549695968628,
        0.026199396699666977,
        -0.053854282945394516,
        -0.024748671799898148,
        -0.02914806269109249,
        0.05425472557544708,
        0.011571276001632214,
        0.02802158333361149,
        0.04822201281785965,
        -0.00009807414608076215,
        0.053266264498233795,
        0.05529329553246498,
        0.04436831548810005,
        -0.052986450493335724,
        -0.055401600897312164,
        0.04195057228207588,
        0.0388525165617466,
        -0.014388460665941238,
        -0.03600339964032173,
        -0.018685264512896538,
        -0.05524105951189995,
        0.03804769739508629,
        -0.04641176760196686,
        -0.019712865352630615,
        0.055211737751960754,
        -0.03513236716389656,
        -0.054203905165195465,
        -0.047130435705184937,
        0.03710569813847542,
        0.03405778855085373,
        -0.04843436926603317,
        -0.043011702597141266,
        -0.0016073008300736547,
        0.05484973266720772,
        0.052797552198171616,
        0.02073935978114605,
        -0.05497147887945175,
        0.05559655278921127,
        -0.030296871438622475,
        0.05554515868425369,
        0.055207036435604095,
        -0.05536922439932823,
        0.0552438348531723,
        -0.04638925939798355,
        -0.05320734158158302,
        0.053075093775987625,
        -0.05478523299098015,
        -0.049829669296741486,
        -0.00689606973901391,
        -0.03333465754985809,
        -0.016296876594424248,
        -0.008546332828700542,
        -0.02090235985815525,
        -0.01065435353666544,
        -0.032950565218925476,
        0.041875001043081284,
        0.05556396767497063,
        -0.05475091189146042,
        0.05026334151625633,
        0.022243648767471313,
        -0.054991234093904495,
        0.051665954291820526,
        0.023646779358386993,
        0.019300801679491997,
        0.05467943102121353,
        0.033287715166807175,
        -0.031787529587745667,
        -0.05347427353262901,
        0.0016417813021689653,
        -0.05334550142288208,
        -0.05391167476773262,
        -0.049612920731306076,
        0.014984291978180408,
        -0.018438871949911118,
        0.055497556924819946,
        0.04548140987753868,
        0.044158685952425,
        0.05553737282752991,
        0.054653510451316833,
        -0.05206015333533287,
        0.01366015337407589,
        -0.055508632212877274,
        -0.030524184927344322,
        0.03179243206977844,
        0.054121535271406174,
        -0.05558597296476364,
        -0.016452569514513016,
        0.0049231830053031445,
        -0.05400359258055687,
        0.05548764765262604,
        0.052616722881793976,
        -0.050175637006759644,
        0.021615298464894295,
        0.04988490417599678,
        -0.04979163035750389,
        -0.011605566367506981,
        0.04406620189547539,
        0.033025261014699936,
        -0.019300337880849838,
        0.0516476184129715,
        -0.054163601249456406,
        0.05519751086831093,
        0.04887330159544945
    ],
    [
        -0.02432917430996895,
        0.04310782626271248,
        0.008658789098262787,
        0.005690886173397303,
        -0.05227154120802879,
        -0.04159420356154442,
        0.05285848304629326,
        -0.045874230563640594,
        0.04129569232463837,
        -0.018708664923906326,
        0.05209444835782051,
        0.0501251146197319,
        -0.052435386925935745,
        -0.05336644500494003,
        -0.05448782444000244,
        0.04535578191280365,
        -0.05461739003658295,
        0.05513184517621994,
        0.03198780491948128,
        -0.03920289874076843,
        -0.031315602362155914,
        0.02990838885307312,
        0.04578140005469322,
        0.04393378272652626,
        -0.054749440401792526,
        0.0457802340388298,
        -0.0037013180553913116,
        0.05506439134478569,
        0.053901202976703644,
        0.05518209934234619,
        -0.016553135588765144,
        -0.05253627151250839,
        0.02638142742216587,
        -0.05512343347072601,
        0.03962179273366928,
        -0.032255444675683975,
        -0.0501098558306694,
        -0.002317332662642002,
        0.028377456590533257,
        0.032369740307331085,
        0.051041148602962494,
        0.04303518682718277,
        0.01913006603717804,
        0.05309727042913437,
        0.024205943569540977,
        -0.01207403838634491,
        0.05043288320302963,
        0.05098985880613327,
        0.05428077653050423,
        0.05509484186768532,
        0.05087292194366455,
        0.053400930017232895,
        -0.025586167350411415,
        -0.053988534957170486,
        0.03360220417380333,
        0.04873309284448624,
        0.04496182128787041,
        0.01573023945093155,
        -0.039818692952394485,
        -0.0051907021552324295,
        -0.05210908129811287,
        -0.0158533938229084,
        -0.048675719648599625,
        0.03529719263315201,
        -0.023221492767333984,
        -0.05508846417069435,
        -0.05489117279648781,
        -0.054096072912216187,
        -0.04488576203584671,
        -0.055164869874715805,
        -0.05502743646502495,
        -0.05453565716743469,
        0.054973870515823364,
        0.045960716903209686,
        -0.05518815666437149,
        -0.01425895281136036,
        0.03502943739295006,
        0.0551927424967289,
        -0.05166814848780632,
        -0.045250218361616135,
        -0.046557918190956116,
        -0.04631217196583748,
        -0.054625436663627625,
        0.017926106229424477,
        0.05388425663113594,
        0.02001441828906536,
        0.009816395118832588,
        -0.053342342376708984,
        0.05259208381175995,
        0.03398087993264198,
        -0.0431378073990345,
        -0.05147532373666763,
        0.043809812515974045,
        -0.05459323897957802,
        -0.05161594972014427,
        0.035894401371479034,
        -0.05507670342922211,
        -0.043879784643650055,
        0.017154144123196602,
        -0.024391133338212967,
        -0.053963422775268555,
        0.05476711317896843,
        -0.028595348820090294,
        0.04348643869161606,
        0.006250866688787937,
        -0.05468979477882385,
        0.03740057349205017,
        0.04909384623169899,
        -0.04979489743709564,
        0.03278576210141182,
        0.029433006420731544,
        0.046917904168367386,
        0.0514712817966938,
        0.045584581792354584,
        -0.04015954211354256,
        0.03206055983901024,
        0.03273938596248627,
        -0.051771875470876694,
        0.05510803312063217,
        -0.045763082802295685,
        0.046557240188121796,
        0.035204097628593445,
        0.050635773688554764,
        -0.05402161180973053,
        -0.04327310249209404,
        0.022869357839226723,
        -0.05324043333530426,
        0.05295585095882416,
        0.054511163383722305,
        -0.023382125422358513,
        0.054931625723838806,
        -0.05135717988014221,
        -0.055147331207990646,
        0.03835323080420494,
        -0.05006933584809303,
        -0.05339141562581062,
        0.05329441279172897,
        -0.0363350510597229,
        -0.05333670973777771,
        0.04613836854696274,
        0.04903464764356613,
        0.05246730148792267,
        0.05257401987910271,
        0.052915118634700775,
        -0.05013096332550049,
        0.02410554513335228,
        -0.05513656884431839,
        -0.05250781401991844,
        -0.050403401255607605,
        0.051287759095430374,
        -0.013040473684668541,
        -0.054005347192287445,
        -0.022152844816446304,
        -0.033470675349235535,
        0.055125776678323746,
        -0.00048698901082389057,
        0.03171997889876366,
        0.009223828092217445,
        0.0529380738735199,
        -0.049386851489543915,
        -0.035512808710336685,
        -0.053212184458971024,
        0.05128898099064827,
        -0.004199513234198093,
        0.04227719455957413,
        0.02109084464609623,
        0.03586271032691002,
        -0.0524124912917614,
        -0.0301362257450819,
        0.04469337314367294,
        -0.055079542100429535,
        0.0437532477080822,
        -0.05214761197566986,
        -0.014049380086362362,
        -0.054546602070331573,
        -0.0009151285048574209,
        0.021311316639184952,
        -0.028177138417959213,
        0.052735697478055954,
        -0.055161163210868835,
        0.04309091717004776,
        0.050036754459142685,
        0.039858270436525345,
        -0.026856446638703346,
        -0.029081037268042564,
        0.040614835917949677,
        -0.04241323471069336,
        0.054781388491392136,
        -0.05346192046999931,
        0.05494651943445206,
        -0.0544324554502964,
        -0.03121185675263405,
        0.05110348388552666,
        0.05463079363107681,
        -0.053620729595422745,
        -0.041490983217954636,
        0.0010563621763139963,
        0.0540144219994545,
        -0.05163998901844025,
        0.03983346000313759,
        0.028103642165660858,
        0.048576824367046356,
        -0.0010547238634899259,
        -0.05237853154540062,
        0.05161505937576294,
        -0.055191364139318466,
        0.03743553161621094,
        0.047946516424417496,
        -0.05098666995763779,
        0.05395447090268135,
        -0.031586792320013046,
        0.039723917841911316,
        0.05354776233434677,
        0.050162818282842636,
        0.0551300048828125,
        -0.05422349274158478,
        -0.0025644099805504084,
        -0.045749448239803314,
        0.05490271374583244,
        -0.05180450528860092,
        -0.031425606459379196,
        0.03084569238126278,
        -0.040951184928417206,
        0.023136494681239128,
        0.055153924971818924,
        -0.0526973195374012,
        0.050243448466062546,
        -0.03816482424736023,
        0.054767999798059464,
        -0.042737726122140884,
        0.04019588232040405,
        -0.05509648099541664,
        0.052894651889801025,
        0.04632622376084328,
        0.055014029145240784,
        0.05081837624311447,
        0.048246268182992935,
        -0.008867054246366024,
        -0.05133974552154541,
        0.0551479198038578,
        0.054967250674963,
        0.04693307355046272,
        -0.05469128489494324,
        -0.05519510433077812,
        0.039555054157972336,
        0.04647883400321007,
        0.00007851443660911173,
        0.05024050176143646,
        -0.05243431776762009,
        -0.05451198294758797,
        -0.05073782801628113,
        -0.0505928173661232,
        0.05518893525004387,
        -0.045345794409513474,
        0.053672611713409424,
        0.04955030232667923,
        0.03752048313617706,
        -0.026073474436998367,
        0.04638604074716568,
        0.007881579920649529,
        0.006857646629214287,
        0.045188404619693756,
        0.017185045406222343,
        0.05341406539082527,
        0.007916286587715149,
        0.05468331649899483,
        -0.003926765639334917,
        -0.050750114023685455,
        0.015200458467006683,
        0.05516490712761879,
        -0.040247976779937744,
        -0.024081626906991005,
        0.05519306659698486,
        -0.0549078993499279,
        0.01878085359930992,
        0.0502859503030777,
        -0.05374467000365257,
        -0.04421505331993103,
        -0.0530058816075325,
        -0.05238514021039009,
        -0.05350681021809578,
        -0.021867623552680016,
        -0.044461410492658615,
        0.05408612638711929,
        0.053652286529541016,
        0.05509384721517563,
        0.04785141348838806,
        0.05174105614423752,
        0.008219102397561073,
        -0.051743727177381516,
        -0.04948308318853378,
        -0.03673148527741432,
        -0.05087589845061302,
        -0.04515130817890167,
        0.05396737903356552,
        -0.05281549692153931,
        0.05488330498337746,
        0.04941416531801224,
        -0.05510643497109413,
        0.05299457907676697,
        0.007829266600310802,
        0.03895164653658867,
        0.031148014590144157,
        0.04864288493990898,
        0.03763493523001671,
        0.0138603076338768,
        -0.032579388469457626,
        0.05518084019422531,
        0.04893253371119499,
        -0.022539230063557625,
        0.01986437290906906,
        0.030579788610339165,
        -0.049431730061769485,
        0.04251302033662796,
        0.04122769832611084,
        -0.05403347313404083,
        -0.04391445219516754,
        0.05519496276974678,
        0.043521203100681305,
        -0.015446760691702366,
        0.05344725400209427,
        -0.01674470864236355,
        0.054242245852947235,
        -0.005047744605690241,
        0.04844820126891136,
        0.055189602077007294,
        0.05502934753894806,
        -0.0021365289576351643,
        0.01587221585214138,
        -0.03369545936584473,
        0.05493893474340439,
        -0.05265628173947334,
        0.055163927376270294,
        0.05519509315490723,
        0.05415123328566551,
        0.054711971431970596,
        -0.05146005377173424,
        -0.0481153205037117,
        0.04816967993974686,
        -0.051450811326503754,
        -0.04794195294380188,
        -0.045646242797374725,
        0.029900621622800827,
        0.05321768298745155,
        -0.02816692739725113,
        -0.00572042865678668,
        0.05459098517894745,
        0.05224980041384697,
        -0.05470839887857437,
        0.05340106040239334,
        -0.04621182382106781,
        0.05418664589524269,
        0.04160454124212265,
        -0.054884519428014755,
        -0.04117778688669205,
        0.049674998968839645,
        -0.05519489571452141,
        -0.05486203357577324,
        -0.040586233139038086,
        -0.05397620424628258,
        0.05267045274376869,
        0.03434538096189499,
        0.05456772819161415,
        -0.045443739742040634,
        -0.05459672957658768,
        -0.05490216985344887,
        0.05487776920199394,
        -0.0142515292391181,
        0.03539729863405228,
        -0.05240799859166145,
        -0.04016032814979553,
        -0.027177488431334496,
        0.02329179085791111,
        0.0068885027430951595,
        0.028159229084849358,
        0.055190544575452805,
        0.03639039769768715,
        0.0058404505252838135,
        0.05358364060521126,
        -0.049204759299755096,
        -0.028770476579666138,
        0.04100637882947922,
        -0.05261057987809181,
        -0.050694212317466736,
        -0.03407524898648262,
        -0.05463841184973717,
        -0.018274186179041862,
        -0.0015633985167369246,
        -0.054673753678798676,
        -0.05442243441939354,
        -0.05498991906642914,
        0.03121311403810978,
        -0.04834282025694847,
        0.032308179885149,
        -0.004545637406408787,
        0.04646711051464081,
        0.02417030930519104,
        0.001946744043380022,
        -0.053643230348825455,
        -0.04691823571920395,
        -0.049070894718170166,
        0.055143192410469055,
        -0.05502792075276375,
        -0.04406058043241501,
        -0.005542451981455088,
        -0.0020924389827996492,
        -0.02075614407658577,
        -0.05069112777709961,
        0.05469880253076553,
        -0.05130445957183838,
        -0.04884803295135498,
        -0.05436084792017937,
        0.050639405846595764,
        0.04566960036754608,
        0.051709648221731186,
        -0.022637328132987022,
        -0.01819276623427868,
        -0.035023510456085205,
        0.048789482563734055,
        0.04187989607453346,
        0.05519077181816101,
        -0.050109781324863434,
        0.05518127605319023,
        -0.055128443986177444,
        0.004566552583128214,
        -0.021296020597219467,
        0.054582905024290085,
        0.0322222001850605,
        -0.044179774820804596,
        -0.052550308406353,
        -0.04396160691976547,
        -0.02635866217315197,
        -0.05513879656791687,
        -0.036278508603572845,
        -0.027853291481733322,
        -0.03546025976538658,
        -0.034799184650182724,
        0.05519144982099533,
        0.02810114249587059,
        -0.03346805274486542,
        -0.052986521273851395,
        0.03611433506011963,
        0.05487248674035072,
        0.052527524530887604,
        -0.03159111365675926,
        -0.038754697889089584,
        0.052659548819065094,
        -0.047601789236068726,
        0.0402420237660408,
        0.055148713290691376,
        -0.030311616137623787,
        -0.0020384183153510094,
        -0.05112577602267265,
        0.021668793633580208,
        -0.05410285294055939,
        -0.05242501571774483,
        -0.018831787630915642,
        -0.05125480145215988,
        -0.024760965257883072,
        -0.05358447507023811,
        0.02994517609477043,
        -0.053326644003391266,
        0.04644092544913292,
        -0.03340821713209152,
        0.022535821422934532,
        0.053556788712739944,
        -0.027986831963062286,
        0.04894605651497841,
        0.048060692846775055,
        -0.053274333477020264,
        0.051541104912757874,
        0.04963679239153862,
        0.014547674916684628,
        0.05357421189546585,
        -0.054383840411901474,
        0.022996610030531883,
        -0.053783513605594635,
        -0.00235894531942904,
        -0.05318056046962738,
        -0.0294710174202919,
        0.04292860999703407,
        0.02850656770169735,
        -0.03647491708397865,
        0.0511690154671669,
        -0.05487416312098503,
        0.051918718963861465,
        0.0371822714805603,
        0.017488818615674973,
        -0.04787313938140869,
        -0.05015506595373154,
        -0.053992919623851776,
        -0.05435437709093094,
        -0.02761060744524002,
        0.05091575160622597,
        -0.04732455685734749,
        0.04512805864214897,
        0.055112626403570175,
        -0.05227828398346901,
        0.05449143052101135,
        0.033997103571891785,
        0.017926519736647606,
        0.05491584539413452,
        -0.0028905952349305153,
        -0.05201038345694542,
        0.0400959774851799,
        0.04698948189616203,
        0.05439776927232742,
        -0.04337986558675766,
        0.05499962344765663,
        -0.029563069343566895,
        0.05514709651470184,
        0.026225347071886063
    ],
    [
        -0.002296556020155549,
        0.03832155466079712,
        0.048864129930734634,
        0.011287323199212551,
        -0.022374236956238747,
        -0.044076383113861084,
        0.05595608055591583,
        -0.05605531856417656,
        0.042232073843479156,
        -0.04467708617448807,
        0.05511770024895668,
        0.05494556576013565,
        -0.05224274843931198,
        0.052610594779253006,
        -0.05623854324221611,
        0.003981408197432756,
        0.05397643521428108,
        0.056036025285720825,
        -0.03858634829521179,
        -0.021531496196985245,
        0.05316700413823128,
        -0.0030915539246052504,
        0.05581158027052879,
        -0.05616983398795128,
        0.02930843085050583,
        0.04625255614519119,
        -0.03469247370958328,
        0.05638061836361885,
        -0.055176325142383575,
        0.009480792097747326,
        0.055246565490961075,
        -0.053366776555776596,
        0.055760014802217484,
        -0.05611008033156395,
        0.047614436596632004,
        -0.04673419147729874,
        -0.05595679208636284,
        0.00227950606495142,
        -0.05362038314342499,
        0.03557892516255379,
        0.039472546428442,
        0.026342423632740974,
        0.052779559046030045,
        0.05392010509967804,
        0.054858360439538956,
        0.03809453547000885,
        0.05378562584519386,
        0.05558277294039726,
        0.05619988217949867,
        -0.05276700109243393,
        0.05605117231607437,
        0.05502549558877945,
        -0.03692939877510071,
        -0.000009310126188211143,
        -0.020245028659701347,
        -0.003035195404663682,
        -0.0321604423224926,
        0.02601000852882862,
        -0.05621590092778206,
        -0.04140450432896614,
        -0.05631185695528984,
        0.024160847067832947,
        -0.05382188409566879,
        0.008890065364539623,
        0.03561035171151161,
        -0.05549878254532814,
        -0.05531536042690277,
        -0.053601011633872986,
        -0.0392758846282959,
        -0.05506501719355583,
        -0.004837331362068653,
        -0.05617909133434296,
        0.02098737098276615,
        -0.004500273615121841,
        -0.056050319224596024,
        -0.04559584707021713,
        0.04815299063920975,
        0.05642038211226463,
        0.04964517056941986,
        -0.016451796516776085,
        -0.052626367658376694,
        -0.05616810545325279,
        -0.05353068560361862,
        0.05547348037362099,
        0.05170584097504616,
        0.0475953035056591,
        -0.05452059581875801,
        -0.02311265841126442,
        -0.0008882137481123209,
        0.048730380833148956,
        -0.007502940949052572,
        -0.01150787528604269,
        0.030336806550621986,
        -0.05593276768922806,
        -0.03124595433473587,
        0.039057713001966476,
        -0.055189915001392365,
        -0.05497400462627411,
        0.044464487582445145,
        0.017665104940533638,
        -0.0559769868850708,
        -0.01982603780925274,
        -0.011851421557366848,
        0.04766432195901871,
        -0.045516014099121094,
        -0.04527275636792183,
        -0.05493888258934021,
        0.05572037398815155,
        -0.04844354838132858,
        0.056203220039606094,
        -0.05549182370305061,
        0.04331672191619873,
        -0.05329315736889839,
        -0.04015084356069565,
        0.04739432409405708,
        -0.05557546392083168,
        0.05319583788514137,
        -0.0549982413649559,
        0.050790440291166306,
        -0.018331091850996017,
        -0.030464036390185356,
        0.04671155661344528,
        0.0537135973572731,
        -0.05433228984475136,
        -0.053507160395383835,
        -0.010938931256532669,
        -0.03963194042444229,
        0.05506913363933563,
        0.053519800305366516,
        -0.030690597370266914,
        -0.05455619841814041,
        -0.01177546102553606,
        -0.03825254738330841,
        0.043277811259031296,
        -0.05101112276315689,
        -0.056042931973934174,
        0.055970773100852966,
        0.00018670350254978985,
        -0.05456731095910072,
        0.05279162526130676,
        0.05463502183556557,
        0.0538831390440464,
        0.05062603950500488,
        -0.021966325119137764,
        0.05356506258249283,
        -0.011576015502214432,
        0.03439357131719589,
        0.00633974140509963,
        -0.052242860198020935,
        -0.040807709097862244,
        -0.05594417825341225,
        -0.05622280016541481,
        -0.04685366526246071,
        0.016381539404392242,
        -0.01394178532063961,
        0.02870872989296913,
        0.015478722751140594,
        -0.05192721262574196,
        -0.018162263557314873,
        -0.04069480672478676,
        -0.03479934111237526,
        -0.012086303904652596,
        0.05057560279965401,
        0.04860949143767357,
        -0.01139870099723339,
        -0.05630119517445564,
        0.05604461207985878,
        0.006732952781021595,
        0.050973374396562576,
        0.042251475155353546,
        -0.05633372813463211,
        0.05170441418886185,
        -0.05557869002223015,
        0.031049946323037148,
        0.05474128574132919,
        0.04691455140709877,
        -0.050328973680734634,
        0.04943395033478737,
        0.056369323283433914,
        -0.056093279272317886,
        -0.053742751479148865,
        -0.010928447358310223,
        0.055472832173109055,
        -0.05309036374092102,
        -0.03674193099141121,
        0.0442776158452034,
        -0.008809483610093594,
        0.05571197718381882,
        0.03611432760953903,
        -0.050869327038526535,
        -0.056230638176202774,
        0.042821548879146576,
        -0.04506786912679672,
        0.00373781262896955,
        -0.05547037348151207,
        0.0501384362578392,
        0.05603537708520889,
        -0.018781069666147232,
        0.04203854501247406,
        0.05239156261086464,
        0.05078678950667381,
        -0.04688986763358116,
        -0.041287947446107864,
        0.039515458047389984,
        0.006468718405812979,
        -0.04255639761686325,
        0.05112650990486145,
        0.05256075784564018,
        -0.05613638833165169,
        0.03629234433174133,
        -0.05372488871216774,
        0.03403617814183235,
        0.00497778132557869,
        0.04837634414434433,
        0.056359563022851944,
        -0.05203299969434738,
        0.05422118678689003,
        -0.01983627676963806,
        0.05641356483101845,
        0.016507228836417198,
        0.049602359533309937,
        0.016469815745949745,
        -0.05366434529423714,
        -0.029543614014983177,
        0.04661502316594124,
        -0.05431957170367241,
        0.05368485674262047,
        0.05282290652394295,
        -0.054807379841804504,
        -0.05432692915201187,
        -0.054980337619781494,
        -0.0032643815502524376,
        0.03984122350811958,
        0.03255705535411835,
        0.056110929697752,
        0.05468074604868889,
        0.056076306849718094,
        0.012608928605914116,
        -0.041775330901145935,
        0.05636487156152725,
        0.04198235645890236,
        0.0523369237780571,
        0.04775410518050194,
        -0.046656906604766846,
        0.025794776156544685,
        0.014880535192787647,
        -0.034982435405254364,
        0.0537339486181736,
        -0.022506874054670334,
        -0.029239369556307793,
        -0.05598124489188194,
        -0.050654154270887375,
        -0.001479597296565771,
        -0.05607176199555397,
        0.046890586614608765,
        0.054506972432136536,
        0.053178027272224426,
        -0.007366834674030542,
        0.04932260513305664,
        0.02553311362862587,
        0.02015308290719986,
        0.02708120085299015,
        -0.0361870676279068,
        0.026247398927807808,
        -0.01668023318052292,
        0.0562647320330143,
        0.053197361528873444,
        0.036581795662641525,
        0.041167180985212326,
        -0.041829317808151245,
        -0.021922433748841286,
        -0.052860576659440994,
        0.05613654851913452,
        -0.05597679316997528,
        -0.015619175508618355,
        0.05124770104885101,
        -0.04771452397108078,
        0.012287484481930733,
        -0.03775826469063759,
        0.04384574294090271,
        -0.05599914491176605,
        -0.036806680262088776,
        -0.03149716928601265,
        0.05355926975607872,
        0.04922962561249733,
        0.041180022060871124,
        0.04389376565814018,
        -0.018561141565442085,
        -0.029836999252438545,
        0.009676121175289154,
        -0.05550823360681534,
        0.030747709795832634,
        -0.05182541906833649,
        -0.014454741030931473,
        0.05038052052259445,
        -0.05410582572221756,
        0.0559646338224411,
        0.001359415939077735,
        -0.05623950809240341,
        0.05404970049858093,
        -0.04496227577328682,
        -0.013872489333152771,
        -0.005294824484735727,
        0.030866721644997597,
        0.023567436262965202,
        -0.0336206890642643,
        -0.04984255135059357,
        0.0561404787003994,
        -0.02236916683614254,
        -0.056009966880083084,
        0.05210350453853607,
        0.04168662056326866,
        0.03933966904878616,
        0.054483551532030106,
        -0.04977332055568695,
        -0.056265536695718765,
        -0.011535752564668655,
        0.05607745796442032,
        0.05299782752990723,
        -0.05201837047934532,
        -0.049474019557237625,
        -0.00283370865508914,
        0.025054369121789932,
        0.03854367136955261,
        0.05636567622423172,
        0.04081723466515541,
        0.05610165745019913,
        -0.054131586104631424,
        0.05173281207680702,
        0.031694065779447556,
        0.023065300658345222,
        0.016790341585874557,
        0.05629395321011543,
        0.04115826636552811,
        0.05550377443432808,
        -0.04578269645571709,
        -0.050354618579149246,
        0.05439658835530281,
        -0.03534485027194023,
        0.0353604294359684,
        -0.004869304597377777,
        -0.05111471936106682,
        -0.005466317757964134,
        -0.014711122959852219,
        0.056419506669044495,
        0.053220659494400024,
        -0.02090938575565815,
        0.055845484137535095,
        -0.04998838156461716,
        0.04217955097556114,
        0.055851053446531296,
        0.035666607320308685,
        0.0015659821219742298,
        -0.042993489652872086,
        -0.023671217262744904,
        0.05154304578900337,
        -0.0556638278067112,
        -0.052611660212278366,
        -0.047556281089782715,
        -0.026690952479839325,
        0.05238102748990059,
        0.04814622551202774,
        -0.03418290615081787,
        -0.04367772489786148,
        -0.005776120349764824,
        -0.05594566836953163,
        0.05606720224022865,
        0.009105464443564415,
        0.05637536570429802,
        -0.05628460273146629,
        -0.04894311726093292,
        0.01908576674759388,
        0.0563836507499218,
        -0.011656739749014378,
        0.05508870631456375,
        0.046376753598451614,
        -0.004251187201589346,
        -0.05225859209895134,
        0.054652322083711624,
        -0.042774952948093414,
        -0.018598714843392372,
        0.03425006940960884,
        -0.05506790429353714,
        -0.029157763347029686,
        0.05504067987203598,
        -0.04303040727972984,
        0.04537942260503769,
        -0.053884152323007584,
        -0.05037696659564972,
        -0.0533938966691494,
        -0.05157814919948578,
        0.009617435745894909,
        -0.055702224373817444,
        0.014901421964168549,
        0.0052027152851223946,
        0.055889587849378586,
        -0.0033177481964230537,
        -0.02345133014023304,
        -0.03965466469526291,
        -0.05628051236271858,
        -0.0528852678835392,
        0.05434099957346916,
        -0.00459057604894042,
        0.05579403042793274,
        0.0429873988032341,
        0.006482366006821394,
        0.05623737350106239,
        0.042991697788238525,
        0.05522417649626732,
        -0.007320056203752756,
        0.0443868450820446,
        -0.05366016924381256,
        -0.0319022536277771,
        0.038755446672439575,
        0.01773851551115513,
        -0.038693368434906006,
        0.04660361260175705,
        0.054566070437431335,
        0.05353923141956329,
        0.051094625145196915,
        -0.01896951161324978,
        -0.054583579301834106,
        -0.05606525018811226,
        -0.05638234317302704,
        -0.02870413474738598,
        -0.04063822701573372,
        -0.04904864728450775,
        -0.028861433267593384,
        -0.03771139308810234,
        -0.052041005343198776,
        -0.05238746851682663,
        -0.052224818617105484,
        -0.0272719357162714,
        -0.03499471768736839,
        -0.042946137487888336,
        -0.0544048510491848,
        -0.05573011189699173,
        0.05459415167570114,
        -0.04194788634777069,
        -0.048063069581985474,
        0.0072094169445335865,
        0.0487775020301342,
        0.029745113104581833,
        0.04790361598134041,
        0.03689567744731903,
        -0.0453375019133091,
        0.05508735775947571,
        -0.05593603476881981,
        0.053087737411260605,
        0.04796668142080307,
        0.04376395419239998,
        -0.052837736904621124,
        0.009651665575802326,
        -0.03642968088388443,
        -0.046155065298080444,
        -0.05350838974118233,
        -0.049868419766426086,
        0.018773647025227547,
        -0.053617049008607864,
        -0.03115677274763584,
        0.054695263504981995,
        -0.055126529186964035,
        -0.0340731181204319,
        -0.05434362590312958,
        0.012063124217092991,
        0.05632483959197998,
        -0.03954693302512169,
        0.055975332856178284,
        0.017127595841884613,
        -0.05629762262105942,
        0.055067576467990875,
        0.045537251979112625,
        0.046734102070331573,
        0.055735982954502106,
        0.05442409962415695,
        -0.014991315081715584,
        -0.0425560362637043,
        0.02077564410865307,
        0.04837058484554291,
        -0.05114232003688812,
        -0.05449967086315155,
        -0.04237895831465721,
        0.055413324385881424,
        0.053300630301237106,
        -0.0534084253013134,
        0.03840179368853569,
        0.055740080773830414,
        0.05118086189031601,
        -0.054712116718292236,
        -0.05347973108291626,
        -0.04916771128773689,
        -0.027951067313551903,
        0.030431699007749557,
        0.045208629220724106,
        -0.04971920698881149,
        0.05074773356318474,
        0.04800064489245415,
        -0.050056569278240204,
        0.05589134991168976,
        0.008264540694653988,
        -0.047443512827157974,
        0.03433092311024666,
        0.03570854291319847,
        -0.03677665814757347,
        0.04973892495036125,
        0.006776781752705574,
        -0.03396584838628769,
        -0.03844882547855377,
        0.03859391808509827,
        -0.05520458519458771,
        0.05382479727268219,
        0.03744867071509361
    ],
    [
        -0.02709798887372017,
        -0.045284975320100784,
        -0.05357428267598152,
        -0.0026273622643202543,
        -0.007698965724557638,
        0.024241020902991295,
        0.053429968655109406,
        -0.052394889295101166,
        0.04179368540644646,
        -0.053475767374038696,
        0.05327905714511871,
        0.04672892764210701,
        -0.0532740093767643,
        -0.040559057146310806,
        -0.053443171083927155,
        0.013400972820818424,
        -0.04783744737505913,
        0.05354694649577141,
        -0.04904570430517197,
        -0.05356063321232796,
        0.027469739317893982,
        -0.04873817786574364,
        0.016690636053681374,
        0.004501765128225088,
        -0.05347619578242302,
        0.05353192239999771,
        -0.04856392741203308,
        0.05347520485520363,
        -0.04208111763000488,
        0.04841344803571701,
        0.01744351163506508,
        -0.042283717542886734,
        0.04056122899055481,
        -0.05316951125860214,
        0.046780720353126526,
        0.050368860363960266,
        -0.05233039706945419,
        0.017827996984124184,
        -0.05266707018017769,
        -0.04059479013085365,
        0.04770331084728241,
        0.04817407950758934,
        0.04420039430260658,
        -0.01734783686697483,
        0.05348115786910057,
        -0.04186748340725899,
        -0.05250587686896324,
        0.05244515836238861,
        0.053403183817863464,
        -0.042705293744802475,
        0.053286172449588776,
        0.05264938995242119,
        -0.044583629816770554,
        -0.05217454582452774,
        -0.05053667724132538,
        0.05152000114321709,
        -0.05096984654664993,
        0.05069354549050331,
        -0.052889153361320496,
        -0.05077328532934189,
        -0.05271109938621521,
        0.011856394819915295,
        -0.04318035766482353,
        -0.02340584062039852,
        -0.04886322095990181,
        -0.050677746534347534,
        -0.05185646936297417,
        -0.050170134752988815,
        0.04007892310619354,
        -0.053586650639772415,
        -0.05327305197715759,
        -0.053401511162519455,
        0.04782566800713539,
        0.053554389625787735,
        -0.0440889336168766,
        -0.007220097817480564,
        0.004693897906690836,
        0.053580425679683685,
        -0.05222349986433983,
        -0.04591973125934601,
        -0.04436415806412697,
        -0.05334384739398956,
        -0.053573593497276306,
        0.040560852736234665,
        0.04866507276892662,
        -0.00696560088545084,
        -0.04830283671617508,
        -0.0192495696246624,
        0.01755174994468689,
        -0.005748072639107704,
        0.020764509215950966,
        -0.052194684743881226,
        0.0525844469666481,
        -0.05193452537059784,
        -0.04609016701579094,
        -0.05064062774181366,
        -0.05308089032769203,
        -0.03512081876397133,
        0.05152018740773201,
        0.037381965667009354,
        -0.05351174250245094,
        -0.05099504441022873,
        0.04160986468195915,
        0.051703762263059616,
        -0.05355042591691017,
        -0.0466337651014328,
        -0.028344660997390747,
        0.0515010841190815,
        -0.05137086659669876,
        0.013494720682501793,
        -0.05357547476887703,
        0.037115518003702164,
        -0.050838638097047806,
        -0.05244229733943939,
        0.053560029715299606,
        -0.05326133966445923,
        0.050469834357500076,
        -0.05339427292346954,
        0.042377080768346786,
        -0.04974644258618355,
        -0.049286894500255585,
        -0.004355038050562143,
        0.05336080491542816,
        -0.05343545973300934,
        -0.04959888011217117,
        0.04169146716594696,
        -0.03152874857187271,
        0.051480986177921295,
        0.053528960794210434,
        -0.04742884635925293,
        0.0535752959549427,
        -0.04842493310570717,
        -0.053328562527894974,
        -0.002289477502927184,
        -0.05025196820497513,
        -0.052447106689214706,
        0.05258362367749214,
        0.02065456472337246,
        -0.05320664867758751,
        0.05350632593035698,
        0.05307619646191597,
        0.053336042910814285,
        0.04650084301829338,
        0.05060606449842453,
        -0.05157731845974922,
        -0.05340563878417015,
        -0.05104168504476547,
        -0.04732970893383026,
        0.05043455958366394,
        0.01104092039167881,
        -0.04702860116958618,
        -0.05321285128593445,
        -0.05035511031746864,
        -0.0500129796564579,
        0.016614926978945732,
        -0.04839838668704033,
        -0.05291282385587692,
        -0.031550053507089615,
        0.018116544932127,
        0.004703589715063572,
        0.007856245152652264,
        0.05146879330277443,
        0.02047908306121826,
        0.011705975048244,
        -0.049378640949726105,
        -0.04827084019780159,
        0.053399160504341125,
        -0.033807579427957535,
        0.02897428534924984,
        0.03634962812066078,
        -0.04520173370838165,
        0.05178327113389969,
        -0.05357937887310982,
        0.03645729273557663,
        -0.05304769426584244,
        0.028279701247811317,
        -0.05196162313222885,
        0.053160663694143295,
        0.053511425852775574,
        -0.052869442850351334,
        -0.047751978039741516,
        0.05356401577591896,
        0.009138875640928745,
        -0.05069410055875778,
        0.03153321519494057,
        -0.03952605649828911,
        0.04314178228378296,
        0.05299907177686691,
        0.03279922530055046,
        0.0533539243042469,
        -0.05346709489822388,
        -0.049485042691230774,
        -0.00278090825304389,
        0.05003543570637703,
        -0.04599977284669876,
        -0.022204779088497162,
        0.026607906445860863,
        0.05069431662559509,
        0.0527840219438076,
        0.05128016322851181,
        0.03293728828430176,
        -0.024668090045452118,
        -0.002989769447594881,
        -0.052965812385082245,
        -0.04066438972949982,
        -0.053586818277835846,
        0.027708901092410088,
        0.05351170524954796,
        0.02861611917614937,
        0.05145677179098129,
        -0.05271206796169281,
        0.03847835212945938,
        -0.05321649834513664,
        0.0492110513150692,
        0.04571961238980293,
        -0.028428012505173683,
        0.040744006633758545,
        -0.03221907094120979,
        0.05350016430020332,
        -0.047942083328962326,
        -0.045738838613033295,
        -0.05151716247200966,
        -0.013692416250705719,
        -0.04520874097943306,
        0.049936968833208084,
        0.012831946834921837,
        0.052545201033353806,
        -0.02592579834163189,
        -0.04809680953621864,
        -0.04971373826265335,
        -0.05155258998274803,
        -0.044931262731552124,
        -0.04038592427968979,
        0.01635138690471649,
        0.04232613742351532,
        0.04192585498094559,
        0.05150672420859337,
        -0.045723553746938705,
        -0.00000954068309511058,
        0.05357619747519493,
        0.05212995409965515,
        0.05182226747274399,
        0.0528293177485466,
        -0.05358274281024933,
        0.04748395085334778,
        0.05146804079413414,
        -0.0531935878098011,
        0.010498433373868465,
        -0.05251331999897957,
        0.011206978000700474,
        -0.053056880831718445,
        -0.04783572256565094,
        0.0039437501691281796,
        -0.052807122468948364,
        0.0516386404633522,
        0.05267706140875816,
        -0.0103145781904459,
        0.017197398468852043,
        0.04670208320021629,
        0.053458429872989655,
        -0.04635702073574066,
        -0.03401312232017517,
        -0.048074763268232346,
        0.049862321466207504,
        0.01495334692299366,
        0.05319356545805931,
        -0.05307801067829132,
        -0.05356646701693535,
        0.05356394872069359,
        0.01676085591316223,
        0.0355810821056366,
        -0.04999664053320885,
        0.053575437515974045,
        -0.05331921577453613,
        -0.05337587743997574,
        0.03929423540830612,
        -0.04354143515229225,
        0.04034896567463875,
        -0.046593401581048965,
        -0.0033684324007481337,
        -0.05348484218120575,
        -0.05117635056376457,
        -0.02189083956182003,
        0.05319390445947647,
        0.052467044442892075,
        0.05356983840465546,
        0.0023872442543506622,
        0.03471425920724869,
        -0.053584326058626175,
        -0.05354883149266243,
        -0.0451919324696064,
        0.02710469253361225,
        -0.030143123120069504,
        -0.01276162825524807,
        0.052824005484580994,
        -0.05311557650566101,
        0.05332574620842934,
        0.05292002856731415,
        -0.052568916231393814,
        0.05336463078856468,
        0.034043651074171066,
        0.020317962393164635,
        -0.023113960400223732,
        0.050846509635448456,
        0.053098488599061966,
        -0.009629503823816776,
        -0.03363834694027901,
        0.05354074016213417,
        -0.006059630773961544,
        -0.030122879892587662,
        0.028632093220949173,
        0.028852146118879318,
        0.005384322255849838,
        0.051713235676288605,
        -0.05289724841713905,
        -0.05349710211157799,
        -0.02968435175716877,
        0.05344182997941971,
        0.04785395413637161,
        -0.04137261584401131,
        0.05356892943382263,
        -0.053583357483148575,
        0.05111973360180855,
        0.026008006185293198,
        0.053221430629491806,
        0.05354045704007149,
        0.05354870483279228,
        -0.051801957190036774,
        -0.01209970936179161,
        -0.05271867662668228,
        -0.02558467723429203,
        -0.000695604074280709,
        0.053508348762989044,
        0.03089650347828865,
        0.04988477751612663,
        0.041333410888910294,
        -0.033097438514232635,
        0.05211368948221207,
        -0.048477254807949066,
        0.0528469979763031,
        -0.051540590822696686,
        0.04551531374454498,
        0.03005819395184517,
        0.02362707071006298,
        0.053566161543130875,
        0.05159005522727966,
        -0.012901186011731625,
        0.0517941489815712,
        0.04919981583952904,
        0.05136657878756523,
        -0.05351221561431885,
        0.05344334989786148,
        0.00238602957688272,
        -0.04628797248005867,
        0.024882618337869644,
        0.049950819462537766,
        -0.0535782165825367,
        -0.05335342139005661,
        -0.04740548133850098,
        -0.05153433233499527,
        0.05018332228064537,
        0.0506126806139946,
        -0.017987100407481194,
        -0.05215976759791374,
        -0.05328309163451195,
        -0.05354040861129761,
        0.052182503044605255,
        -0.004382046405225992,
        -0.042718783020973206,
        -0.05355875566601753,
        -0.042651474475860596,
        -0.003937078174203634,
        0.05322299152612686,
        -0.020951637998223305,
        0.0513676218688488,
        -0.04483551159501076,
        0.041125454008579254,
        0.05056702718138695,
        0.05298079177737236,
        -0.053559813648462296,
        -0.04038185253739357,
        0.05036555603146553,
        -0.05303425341844559,
        -0.02915205992758274,
        0.044746510684490204,
        -0.04140578955411911,
        -0.04173197224736214,
        -0.0011453054612502456,
        -0.05266338586807251,
        -0.05226801708340645,
        -0.04645651951432228,
        0.036467064172029495,
        -0.05331762507557869,
        -0.04929313063621521,
        -0.04959982633590698,
        0.05213780328631401,
        0.0529961995780468,
        0.018056537955999374,
        -0.0529659241437912,
        -0.05343884229660034,
        -0.0469377264380455,
        0.05335400626063347,
        0.045732010155916214,
        0.05143527314066887,
        -0.010184488259255886,
        0.02477753907442093,
        0.050272587686777115,
        -0.04255380481481552,
        0.0397576242685318,
        -0.047324299812316895,
        0.04399644210934639,
        -0.05272242799401283,
        -0.009056741371750832,
        -0.03860216215252876,
        0.04122284799814224,
        -0.005713578779250383,
        0.0335586741566658,
        -0.05358225479722023,
        0.03325861319899559,
        -0.02038947492837906,
        0.053230393677949905,
        -0.0531802698969841,
        0.04493369162082672,
        -0.05326206237077713,
        -0.05002347752451897,
        0.03604397922754288,
        0.04782455787062645,
        0.049586329609155655,
        -0.01902894861996174,
        -0.05300311744213104,
        0.018096404150128365,
        -0.03937731310725212,
        -0.050730496644973755,
        0.00822032243013382,
        -0.04123617336153984,
        -0.04033438488841057,
        -0.05343939736485481,
        0.052698396146297455,
        -0.01682504639029503,
        -0.05348425731062889,
        -0.04373762756586075,
        0.008108291774988174,
        0.039976976811885834,
        0.030871426686644554,
        0.050002653151750565,
        0.03769999369978905,
        0.04475117847323418,
        -0.05320107564330101,
        0.04956777021288872,
        0.05107226222753525,
        -0.05310945585370064,
        -0.027539631351828575,
        -0.03709007427096367,
        -0.025564005598425865,
        -0.05356632545590401,
        -0.03164135664701462,
        0.028734663501381874,
        0.002201018389314413,
        -0.05197247490286827,
        0.03919070586562157,
        0.027264771983027458,
        -0.027819741517305374,
        -0.026099273934960365,
        0.042845264077186584,
        0.04575853794813156,
        0.0534963496029377,
        -0.049668457359075546,
        0.05352088063955307,
        0.05355816334486008,
        -0.05349184200167656,
        0.0534050427377224,
        0.052608881145715714,
        0.041849035769701004,
        0.053265683352947235,
        0.024553488940000534,
        -0.037804216146469116,
        -0.04576408118009567,
        0.05324186757206917,
        -0.01191503182053566,
        -0.05296526849269867,
        -0.05350801721215248,
        0.0158787053078413,
        0.04664873331785202,
        0.04850529506802559,
        0.04731915891170502,
        0.04593475162982941,
        0.04549287259578705,
        0.036858733743429184,
        -0.051887914538383484,
        0.018327107653021812,
        -0.05330885574221611,
        -0.053416769951581955,
        -0.036294370889663696,
        0.042805980890989304,
        -0.04994151368737221,
        -0.051205843687057495,
        -0.04820537194609642,
        0.04706168919801712,
        0.053180795162916183,
        -0.0007348837680183351,
        0.030926233157515526,
        0.05050956830382347,
        0.032058991491794586,
        0.043988246470689774,
        -0.05329287052154541,
        0.04770656302571297,
        0.03214605525135994,
        -0.04462042078375816,
        0.017904214560985565,
        -0.050859324634075165,
        0.044597890228033066,
        -0.024728043004870415
    ],
    [
        0.029135802760720253,
        0.016595864668488503,
        -0.04800887778401375,
        -0.02727360650897026,
        -0.02351870760321617,
        -0.014827588573098183,
        0.052371881902217865,
        -0.051130689680576324,
        0.053982920944690704,
        -0.05436139553785324,
        0.02381017617881298,
        -0.017587101086974144,
        -0.05420302227139473,
        -0.02553861029446125,
        -0.05443299934267998,
        0.033425185829401016,
        0.0436348095536232,
        0.05333052948117256,
        -0.012220900505781174,
        0.052246782928705215,
        0.05478953570127487,
        -0.03223058953881264,
        0.011887134052813053,
        0.054472800344228745,
        -0.05393734946846962,
        -0.008177620358765125,
        0.03160512074828148,
        0.05272144079208374,
        -0.05391722545027733,
        0.05480417609214783,
        0.017759643495082855,
        0.01585322991013527,
        -0.045558009296655655,
        -0.05483101308345795,
        0.0459836907684803,
        0.043941255658864975,
        -0.05271153524518013,
        -0.052501656115055084,
        0.01609147898852825,
        0.053943805396556854,
        0.045082077383995056,
        0.022613346576690674,
        0.05325017869472504,
        0.05397092550992966,
        0.017672160640358925,
        -0.03987949714064598,
        0.0073870643973350525,
        0.052929967641830444,
        0.05482397973537445,
        -0.05272725969552994,
        0.046427201479673386,
        -0.03489011526107788,
        -0.0461883619427681,
        -0.052823763340711594,
        0.05460045486688614,
        0.03756030648946762,
        0.05439877137541771,
        -0.04443596675992012,
        -0.04712521657347679,
        -0.04050358384847641,
        -0.04720873013138771,
        0.017717977985739708,
        -0.054590579122304916,
        -0.0455157496035099,
        -0.05096253380179405,
        -0.009861987084150314,
        -0.05414640158414841,
        -0.050786860287189484,
        0.054666824638843536,
        -0.054837871342897415,
        -0.05196638032793999,
        -0.05458781495690346,
        0.052790772169828415,
        0.05485067144036293,
        0.01877964287996292,
        -0.05170616880059242,
        0.039618588984012604,
        0.05485972389578819,
        0.054538752883672714,
        -0.012628735974431038,
        -0.05277223885059357,
        -0.05469368398189545,
        -0.05448110029101372,
        0.04192306101322174,
        -0.04922355338931084,
        0.051652081310749054,
        0.014060351997613907,
        -0.047720108181238174,
        0.04312179237604141,
        0.031045304611325264,
        -0.053612273186445236,
        -0.05418430641293526,
        -0.042733218520879745,
        -0.03895680606365204,
        -0.043198224157094955,
        0.050016455352306366,
        -0.023945579305291176,
        -0.03915906697511673,
        0.05004128813743591,
        -0.011755997315049171,
        -0.054727524518966675,
        0.04109072685241699,
        0.054355427622795105,
        0.05419803038239479,
        -0.042357489466667175,
        0.04904114454984665,
        -0.047708045691251755,
        0.019119413569569588,
        -0.046998970210552216,
        -0.008571136742830276,
        0.02980157546699047,
        0.0548219159245491,
        0.02499646134674549,
        -0.037220217287540436,
        0.006220248527824879,
        -0.05253378674387932,
        0.054158538579940796,
        -0.0534077063202858,
        0.05181990563869476,
        0.001798198209144175,
        -0.02756369300186634,
        0.006771232932806015,
        -0.04841502755880356,
        -0.05463007092475891,
        -0.04193871095776558,
        0.00800350308418274,
        -0.04116102308034897,
        0.05148636922240257,
        0.04785478115081787,
        0.04793412983417511,
        -0.031764157116413116,
        -0.03939593955874443,
        -0.05287570506334305,
        0.03511573001742363,
        -0.05093752220273018,
        -0.05451612174510956,
        0.05477312207221985,
        -0.054289113730192184,
        -0.03967839851975441,
        0.026845594868063927,
        -0.03734765946865082,
        0.05257796496152878,
        0.05264740064740181,
        0.03396039828658104,
        -0.016000190749764442,
        0.007866760715842247,
        -0.05358152464032173,
        -0.04802162945270538,
        0.0530620738863945,
        0.05472235009074211,
        0.03139215335249901,
        -0.054792847484350204,
        -0.05352989211678505,
        -0.009016986936330795,
        0.05430193617939949,
        -0.05400245264172554,
        0.046269867569208145,
        -0.04625057056546211,
        0.006038920488208532,
        0.04356423765420914,
        -0.0369587205350399,
        0.053543318063020706,
        0.054312754422426224,
        -0.02529461681842804,
        -0.009374067187309265,
        0.0331568606197834,
        0.05355566740036011,
        0.05312809348106384,
        -0.0423690527677536,
        0.04152565822005272,
        -0.05451885238289833,
        0.03617824986577034,
        -0.05261063203215599,
        -0.03163066506385803,
        -0.05370554327964783,
        0.008545158430933952,
        -0.05427556857466698,
        -0.028020132333040237,
        0.053559768944978714,
        0.007903278805315495,
        -0.04203173890709877,
        -0.0501091368496418,
        -0.019331756979227066,
        0.054269418120384216,
        -0.014044520445168018,
        0.032379377633333206,
        0.046301621943712234,
        0.05268172174692154,
        0.01526924129575491,
        -0.0062099783681333065,
        -0.05459046736359596,
        -0.04411802813410759,
        0.0241642314940691,
        -0.037328314036130905,
        -0.04033336043357849,
        0.012040812522172928,
        -0.020819200202822685,
        0.054832473397254944,
        0.018157027661800385,
        0.026283953338861465,
        0.054521188139915466,
        -0.027086619287729263,
        -0.015671132132411003,
        -0.03249025344848633,
        0.05451679974794388,
        -0.05159711465239525,
        0.027811765670776367,
        0.04954874515533447,
        -0.054796889424324036,
        -0.05481451377272606,
        -0.05368071794509888,
        0.04708919674158096,
        0.040355756878852844,
        0.05303996056318283,
        -0.05244771018624306,
        -0.05434844270348549,
        0.05486520752310753,
        0.006788617465645075,
        0.054863639175891876,
        -0.05352170765399933,
        -0.04735885187983513,
        0.0012976115103811026,
        0.04038191959261894,
        -0.024515394121408463,
        0.05343286693096161,
        -0.038441188633441925,
        0.0265668798238039,
        -0.054759152233600616,
        0.05421379581093788,
        -0.050941865891218185,
        -0.052830953150987625,
        -0.05234828591346741,
        0.03614214062690735,
        0.050226666033267975,
        -0.051780372858047485,
        0.054032716900110245,
        0.04726254940032959,
        0.0512867271900177,
        0.05117915943264961,
        0.054859742522239685,
        0.052046142518520355,
        0.04080204665660858,
        0.04860896244645119,
        -0.0543237142264843,
        -0.002852348145097494,
        0.010434126481413841,
        0.03629293292760849,
        -0.04079743102192879,
        -0.046037863940000534,
        -0.041525959968566895,
        -0.052896998822689056,
        -0.049479953944683075,
        0.0008776741451583803,
        -0.05364769324660301,
        0.05456876382231712,
        0.04451201856136322,
        0.05365461856126785,
        -0.0145406574010849,
        0.05305436998605728,
        -0.023532774299383163,
        -0.04900800436735153,
        0.03721446916460991,
        0.050915200263261795,
        0.054744966328144073,
        0.029598401859402657,
        0.05469775199890137,
        0.05485180392861366,
        0.027327774092555046,
        0.03163496032357216,
        0.04768438637256622,
        0.032224301248788834,
        -0.053956273943185806,
        0.054439395666122437,
        -0.05445491150021553,
        0.048701588064432144,
        0.047387756407260895,
        -0.04779309406876564,
        0.043035030364990234,
        -0.05444229021668434,
        -0.03708140552043915,
        -0.053662270307540894,
        -0.03939305990934372,
        0.0006378702237270772,
        0.021062558516860008,
        0.03665460646152496,
        0.054421257227659225,
        0.026742642745375633,
        -0.03487648442387581,
        0.025278229266405106,
        -0.04328247159719467,
        -0.05224780738353729,
        0.03260118141770363,
        -0.049259889870882034,
        -0.0405103825032711,
        0.04857534542679787,
        -0.05175769701600075,
        0.054580796509981155,
        0.053869180381298065,
        0.0547887347638607,
        0.0529295913875103,
        0.05146079137921333,
        0.05203584209084511,
        -0.0009418674744665623,
        0.0469660609960556,
        -0.034939855337142944,
        -0.0421653687953949,
        0.03346143662929535,
        0.05484563857316971,
        0.0393754281103611,
        -0.053712375462055206,
        -0.05447015538811684,
        -0.0016136651393026114,
        0.05015656352043152,
        0.05378534272313118,
        0.003668681252747774,
        -0.05315953865647316,
        -0.04811163619160652,
        0.054702967405319214,
        0.05342482402920723,
        0.005867107305675745,
        0.05438806116580963,
        -0.03689046576619148,
        0.05429490655660629,
        0.04609151557087898,
        0.01691824570298195,
        -0.037266805768013,
        0.04873717576265335,
        -0.042318522930145264,
        0.005537537392228842,
        -0.0501248873770237,
        0.05000058189034462,
        -0.05406534671783447,
        0.0468539297580719,
        -0.004814448766410351,
        0.051621802151203156,
        0.0463973805308342,
        -0.05153034254908562,
        0.04676799103617668,
        0.05131431296467781,
        0.04934609681367874,
        -0.05091312900185585,
        -0.05088362842798233,
        0.05466509610414505,
        -0.011809163726866245,
        0.054849013686180115,
        -0.011799375526607037,
        0.05448724702000618,
        0.05457143485546112,
        0.01932833530008793,
        0.03187670186161995,
        0.051812849938869476,
        -0.044140223413705826,
        0.04039028659462929,
        -0.054300662130117416,
        0.05150948464870453,
        0.020678211003541946,
        -0.054784372448921204,
        -0.052485547959804535,
        -0.05408100038766861,
        0.04526766389608383,
        0.04730774089694023,
        0.053226444870233536,
        -0.03915172442793846,
        -0.04971778392791748,
        -0.05482860282063484,
        -0.0547768659889698,
        0.05470607057213783,
        0.05407924950122833,
        -0.045995187014341354,
        -0.05413262918591499,
        -0.03577197343111038,
        -0.05467236787080765,
        -0.033513881266117096,
        -0.05212721601128578,
        0.04839583486318588,
        0.05486153066158295,
        0.05438212305307388,
        0.053471360355615616,
        0.051575250923633575,
        -0.0486794039607048,
        -0.030755527317523956,
        -0.0361904613673687,
        -0.05202565714716911,
        0.030539195984601974,
        0.05186210200190544,
        -0.05446408689022064,
        0.05468420684337616,
        -0.029493091627955437,
        -0.053171075880527496,
        -0.04102599620819092,
        -0.0508524626493454,
        0.01854216493666172,
        -0.02006661519408226,
        -0.044948600232601166,
        0.05158233270049095,
        0.05442853644490242,
        0.05478949472308159,
        0.0010614460334181786,
        -0.054865021258592606,
        -0.04392845556139946,
        -0.02984333224594593,
        0.053708516061306,
        -0.01959717459976673,
        -0.04277265816926956,
        -0.05306677147746086,
        0.001876276801340282,
        0.052861519157886505,
        -0.04574718326330185,
        0.05359992757439613,
        -0.009849035181105137,
        0.027755118906497955,
        -0.05441661179065704,
        -0.054536253213882446,
        0.053592827171087265,
        0.04523684084415436,
        -0.04900691285729408,
        -0.05400580167770386,
        -0.04516902193427086,
        0.0022373006213456392,
        0.008586221374571323,
        0.04890768229961395,
        0.04883091151714325,
        -0.0486769825220108,
        -0.054860834032297134,
        0.020771348848938942,
        -0.0516953319311142,
        0.02515077032148838,
        0.009077736176550388,
        -0.03341325372457504,
        -0.05458841100335121,
        0.03404153138399124,
        -0.04167720302939415,
        -0.04947804659605026,
        0.004661280661821365,
        -0.03565496578812599,
        -0.05259102210402489,
        -0.00033400903339497745,
        0.04976692795753479,
        0.013685809448361397,
        -0.04722803458571434,
        0.015576864592730999,
        0.020406125113368034,
        0.010475415736436844,
        0.052247337996959686,
        -0.041034746915102005,
        0.038811810314655304,
        0.054304368793964386,
        -0.05461619421839714,
        0.03219006955623627,
        0.046233754605054855,
        0.05347069725394249,
        0.052848950028419495,
        -0.04042546823620796,
        -0.04915342852473259,
        0.052064199000597,
        -0.051402509212493896,
        -0.04970083758234978,
        -0.05297742784023285,
        -0.054498862475156784,
        0.034407611936330795,
        0.036372363567352295,
        -0.017438078299164772,
        -0.054854024201631546,
        0.0546402707695961,
        0.050812285393476486,
        0.054477620869874954,
        -0.052704546600580215,
        0.05442126840353012,
        0.054847050458192825,
        -0.049604631960392,
        0.054828789085149765,
        0.054133445024490356,
        0.02931605838239193,
        0.05250174552202225,
        -0.0489882193505764,
        0.04034580662846565,
        -0.015184922143816948,
        0.051287759095430374,
        -0.05418715998530388,
        -0.05377107486128807,
        0.0385170541703701,
        -0.03829808533191681,
        -0.045667920261621475,
        0.05201766639947891,
        -0.007433035410940647,
        0.045918092131614685,
        0.036806922405958176,
        0.053816866129636765,
        -0.05394989252090454,
        -0.05388921499252319,
        -0.01798851601779461,
        0.002221637172624469,
        -0.019367890432476997,
        0.045132558792829514,
        -0.04893253371119499,
        0.015168922953307629,
        0.0547412633895874,
        -0.048932019621133804,
        0.05280603468418121,
        0.04625409096479416,
        -0.05425894260406494,
        0.052548110485076904,
        0.04711148887872696,
        -0.04667290300130844,
        -0.029797935858368874,
        0.02975897118449211,
        0.047622743993997574,
        0.008700431324541569,
        0.04731805622577667,
        -0.05314405634999275,
        0.0498044453561306,
        0.05481060594320297
    ],
    [
        -0.04080146923661232,
        0.05247526243329048,
        -0.010098954662680626,
        -0.0003662439121399075,
        -0.046503446996212006,
        -0.026376361027359962,
        0.030650995671749115,
        -0.05427558720111847,
        0.0564674511551857,
        -0.051053717732429504,
        0.04087933525443077,
        0.057045161724090576,
        -0.056691862642765045,
        -0.025384200736880302,
        -0.053047046065330505,
        0.05289413407444954,
        -0.02834681048989296,
        0.0536741204559803,
        0.05004856735467911,
        -0.0487012043595314,
        0.05711155757308006,
        0.05716164410114288,
        0.057098306715488434,
        0.015587272122502327,
        0.008133968338370323,
        0.04659848287701607,
        -0.004431453999131918,
        0.0466882549226284,
        -0.040911413729190826,
        0.05626332014799118,
        0.03683752566576004,
        -0.05726228654384613,
        0.01973803900182247,
        -0.05486556515097618,
        0.056021589785814285,
        -0.05195331200957298,
        -0.05668460577726364,
        -0.0420108288526535,
        0.0549619160592556,
        -0.05638920143246651,
        0.0383470319211483,
        0.05330640450119972,
        0.03963875770568848,
        0.04594098776578903,
        -0.022725991904735565,
        -0.0019138248171657324,
        0.05637180805206299,
        0.056640151888132095,
        0.053224753588438034,
        0.05460352078080177,
        0.05423936992883682,
        0.04589853808283806,
        -0.039392948150634766,
        -0.05493027716875076,
        0.05678451806306839,
        -0.030077816918492317,
        0.056896984577178955,
        -0.007164059206843376,
        -0.013818632811307907,
        0.0540616475045681,
        -0.05274965614080429,
        -0.01754438318312168,
        -0.056635309010744095,
        0.05545157939195633,
        -0.038659289479255676,
        -0.05456656962633133,
        -0.04208958148956299,
        -0.0539768785238266,
        -0.030378401279449463,
        -0.01271137222647667,
        0.01753011904656887,
        -0.055216945707798004,
        -0.0015632411232218146,
        0.02745925821363926,
        -0.05675040930509567,
        -0.05571014806628227,
        0.04829372465610504,
        0.05730254203081131,
        -0.05548243969678879,
        -0.006076511461287737,
        -0.04100997745990753,
        0.026988230645656586,
        -0.05461922660470009,
        0.03045571781694889,
        -0.009943701326847076,
        0.05569595471024513,
        -0.0565774068236351,
        -0.056724969297647476,
        0.044744931161403656,
        0.05519699305295944,
        -0.02513662539422512,
        -0.0506850890815258,
        -0.04727158322930336,
        -0.010079758241772652,
        -0.04824484884738922,
        0.02760423719882965,
        -0.046334341168403625,
        -0.04145149141550064,
        0.04451251029968262,
        -0.014775688759982586,
        -0.056971028447151184,
        0.011240039020776749,
        0.02995339408516884,
        0.04942096024751663,
        -0.031959403306245804,
        -0.05146089196205139,
        -0.003763619577512145,
        0.053440649062395096,
        -0.024614017456769943,
        0.03396007791161537,
        -0.05013369768857956,
        0.04187408834695816,
        0.02243632636964321,
        0.020594734698534012,
        -0.05661746487021446,
        0.05606098845601082,
        0.042428985238075256,
        -0.0449906550347805,
        0.057139329612255096,
        0.04671522602438927,
        -0.010320860892534256,
        -0.01884649321436882,
        -0.02999238483607769,
        -0.05492885038256645,
        -0.05119996517896652,
        -0.048904385417699814,
        -0.009541468694806099,
        0.04386912286281586,
        0.05477946624159813,
        0.05105393007397652,
        0.020950859412550926,
        -0.03378567844629288,
        0.04492468759417534,
        0.031434621661901474,
        -0.05093429237604141,
        -0.05582081153988838,
        0.05722580850124359,
        0.056636687368154526,
        -0.05497555434703827,
        -0.030109647661447525,
        -0.04957796633243561,
        0.05424245819449425,
        0.04732928052544594,
        0.05598421394824982,
        -0.005969046149402857,
        -0.01546767633408308,
        -0.05521225184202194,
        -0.056757718324661255,
        -0.019298279657959938,
        0.05136143043637276,
        0.01719503290951252,
        -0.0545581579208374,
        -0.019461149349808693,
        0.04079446941614151,
        -0.012760602869093418,
        0.017150256782770157,
        0.027235310524702072,
        -0.02033570408821106,
        0.05652037262916565,
        0.01418749988079071,
        -0.049454450607299805,
        -0.055611751973629,
        0.049626126885414124,
        -0.046404920518398285,
        -0.018793746829032898,
        0.04820532351732254,
        -0.005573587492108345,
        -0.03309670835733414,
        0.04212908074259758,
        0.04291383922100067,
        -0.05648843199014664,
        -0.05311739444732666,
        -0.035640083253383636,
        -0.047493960708379745,
        0.04729628190398216,
        0.03335850313305855,
        -0.030372379347682,
        0.04931158572435379,
        0.055735740810632706,
        -0.05371781811118126,
        -0.04902465268969536,
        0.04310661181807518,
        0.026606550440192223,
        -0.035102345049381256,
        0.02026260457932949,
        -0.04168416187167168,
        0.025817422196269035,
        0.05174536257982254,
        -0.04447811841964722,
        0.0019683006685227156,
        0.02959592454135418,
        -0.0002732188440859318,
        0.04787662997841835,
        0.05648418888449669,
        -0.05710069462656975,
        -0.04277611896395683,
        0.019559891894459724,
        0.032163750380277634,
        -0.05664282292127609,
        0.056526076048612595,
        0.04035434126853943,
        0.03344878554344177,
        -0.05545518919825554,
        -0.04793861135840416,
        0.028495600447058678,
        -0.029088372364640236,
        0.052531540393829346,
        -0.007837217301130295,
        -0.047107841819524765,
        0.05177998170256615,
        -0.026102522388100624,
        0.015315084718167782,
        0.04859846085309982,
        0.056903548538684845,
        0.05706028267741203,
        0.05718616768717766,
        -0.04672175273299217,
        0.028165418654680252,
        0.05550675094127655,
        0.014774734154343605,
        -0.015884002670645714,
        0.05427328497171402,
        -0.05521039664745331,
        0.0010613652411848307,
        0.05433507636189461,
        -0.03800234943628311,
        -0.0007102442905306816,
        -0.027287855744361877,
        0.05134906619787216,
        -0.03929063677787781,
        0.01964697428047657,
        -0.055565156042575836,
        0.052757199853658676,
        0.055001888424158096,
        0.051110874861478806,
        0.047502562403678894,
        0.05239176005125046,
        -0.02757035382091999,
        -0.05503704771399498,
        0.05728347226977348,
        0.05321560427546501,
        0.05633356422185898,
        -0.05596652254462242,
        -0.0013000165345147252,
        -0.0036967559717595577,
        0.03303428739309311,
        -0.038280144333839417,
        -0.04721071198582649,
        -0.041435450315475464,
        -0.04803001135587692,
        0.011937587521970272,
        -0.05588332936167717,
        0.0566943883895874,
        -0.054206401109695435,
        0.018825000151991844,
        0.054262224584817886,
        0.056941576302051544,
        -0.025631071999669075,
        0.05678541213274002,
        -0.02117549255490303,
        0.017469359561800957,
        0.05292040482163429,
        -0.051949527114629745,
        -0.03008957952260971,
        -0.03948109224438667,
        0.05717755854129791,
        0.05714325234293938,
        0.033035170286893845,
        0.025042591616511345,
        0.039556100964546204,
        -0.03846384212374687,
        -0.03213915228843689,
        0.05353860184550285,
        -0.05212865397334099,
        0.056996025145053864,
        0.05215169116854668,
        -0.05447015166282654,
        -0.05644797533750534,
        -0.05683992803096771,
        -0.04757722467184067,
        -0.054176054894924164,
        0.040466342121362686,
        -0.014305728487670422,
        0.05497662350535393,
        0.008860478177666664,
        0.05471283569931984,
        0.027873314917087555,
        0.053313106298446655,
        0.05451178923249245,
        0.018338555470108986,
        -0.020204532891511917,
        0.026862923055887222,
        -0.049565330147743225,
        -0.0227364432066679,
        0.05467408522963524,
        -0.05195353925228119,
        0.055299319326877594,
        0.05643610656261444,
        -0.03796061873435974,
        0.05452808737754822,
        -0.034630388021469116,
        0.04269953817129135,
        -0.025152994319796562,
        0.007116973865777254,
        0.008580057881772518,
        -0.012355918996036053,
        0.0017620270373299718,
        0.05720629543066025,
        0.03269879147410393,
        0.027138274163007736,
        0.042459774762392044,
        0.050925590097904205,
        -0.039057087153196335,
        -0.034769680351018906,
        -0.027088019996881485,
        -0.05661624297499657,
        0.01909654401242733,
        0.057253435254096985,
        0.05318918824195862,
        0.006111975759267807,
        0.04933592304587364,
        -0.012713739648461342,
        0.04010200500488281,
        0.0312334094196558,
        0.05615134537220001,
        0.02887893281877041,
        0.020032009109854698,
        0.03346679359674454,
        0.05649733915925026,
        -0.02284790202975273,
        0.05006089061498642,
        -0.05622924119234085,
        0.05705432593822479,
        0.05721333622932434,
        0.05401458591222763,
        0.05298609286546707,
        -0.05710412189364433,
        0.01570168137550354,
        -0.05191745609045029,
        0.041759997606277466,
        -0.018776318058371544,
        -0.025049760937690735,
        0.04601576551795006,
        0.053875479847192764,
        -0.01907123066484928,
        -0.02814585156738758,
        0.05688517540693283,
        0.05602489411830902,
        -0.0569026805460453,
        0.037301450967788696,
        0.05567905306816101,
        -0.04733559861779213,
        -0.03493025153875351,
        -0.057012464851140976,
        0.040895726531744,
        0.044989220798015594,
        -0.057061079889535904,
        -0.05644356086850166,
        0.028427964076399803,
        -0.050640810281038284,
        0.04793607071042061,
        0.054608557373285294,
        0.03430970013141632,
        -0.05627959221601486,
        -0.012098563835024834,
        -0.05559379607439041,
        0.05614030361175537,
        -0.015748271718621254,
        -0.003145534312352538,
        -0.057109732180833817,
        -0.05672303959727287,
        -0.05192426219582558,
        0.055094122886657715,
        0.0535665862262249,
        0.05296036973595619,
        0.05569105222821236,
        0.0559539720416069,
        0.0375693216919899,
        0.057100992649793625,
        -0.04383336380124092,
        -0.03339824080467224,
        -0.027489567175507545,
        -0.054798971861600876,
        -0.05547265708446503,
        0.01849883794784546,
        -0.05679396167397499,
        0.0034087379463016987,
        -0.0335940383374691,
        -0.05460886284708977,
        -0.054598934948444366,
        -0.05679158866405487,
        0.05369177088141441,
        -0.05621321499347687,
        -0.008718160912394524,
        -0.011693514883518219,
        0.0557611808180809,
        -0.025352805852890015,
        0.030184322968125343,
        -0.05125854164361954,
        -0.056921567767858505,
        -0.05499351769685745,
        0.05682118237018585,
        -0.05262110009789467,
        -0.05173590034246445,
        -0.007725742179900408,
        0.05358560010790825,
        -0.0056908573023974895,
        -0.05468427389860153,
        0.05696242302656174,
        -0.027170313522219658,
        -0.030162613838911057,
        -0.053129564970731735,
        -0.041769783943891525,
        0.038235172629356384,
        0.035656217485666275,
        -0.025524435564875603,
        0.047743670642375946,
        0.04520830884575844,
        0.015961995348334312,
        0.0532192625105381,
        0.05088220164179802,
        0.05612412840127945,
        0.049324799329042435,
        -0.05692971497774124,
        -0.011101409792900085,
        0.0453375019133091,
        0.01463865116238594,
        -0.056432683020830154,
        -0.055927444249391556,
        -0.04951737821102142,
        0.056072384119033813,
        -0.03848981484770775,
        -0.04380689188838005,
        -0.055513426661491394,
        0.016880158334970474,
        -0.05647101253271103,
        -0.05415574833750725,
        0.05298909544944763,
        0.01311861164867878,
        -0.04410349950194359,
        -0.03760756924748421,
        -0.02428027242422104,
        0.027128523215651512,
        0.048976749181747437,
        -0.055803082883358,
        -0.04557991400361061,
        0.05499110743403435,
        0.011618025600910187,
        0.05544392019510269,
        0.0560346320271492,
        0.02150162123143673,
        0.03585466742515564,
        0.0022437244188040495,
        -0.028186669573187828,
        -0.05626753345131874,
        -0.05643168464303017,
        0.004798886366188526,
        -0.03832297399640083,
        0.05243522301316261,
        -0.05443795025348663,
        0.05485241487622261,
        -0.037018194794654846,
        0.05242287367582321,
        0.05373397842049599,
        0.038355957716703415,
        0.055542558431625366,
        -0.05555273965001106,
        0.052968207746744156,
        -0.05259781703352928,
        -0.05618533864617348,
        0.05420415848493576,
        -0.004002923145890236,
        0.013628963381052017,
        0.0550079382956028,
        -0.05207381024956703,
        0.053001876920461655,
        -0.01366223394870758,
        -0.05705711618065834,
        -0.03536053001880646,
        -0.051235951483249664,
        -0.006708682049065828,
        -0.0009633032022975385,
        -0.04133640602231026,
        0.05167475342750549,
        -0.024579524993896484,
        0.05234833061695099,
        0.05515708401799202,
        0.05302168428897858,
        -0.05391841381788254,
        -0.05428262799978256,
        0.008123395964503288,
        -0.04465638846158981,
        0.04701896756887436,
        0.04516318067908287,
        -0.04986042529344559,
        0.043472450226545334,
        0.05705895274877548,
        -0.052637577056884766,
        0.05549650639295578,
        0.05452994629740715,
        -0.04837648198008537,
        0.05697040632367134,
        -0.043309327214956284,
        -0.056677062064409256,
        -0.05535339191555977,
        -0.02832362987101078,
        0.056742869317531586,
        0.021640043705701828,
        0.05692955106496811,
        -0.0408344529569149,
        0.053251657634973526,
        -0.04340256750583649
    ],
    [
        -0.03252842649817467,
        0.002983245300129056,
        0.05583259090781212,
        -0.00016684837464708835,
        0.009822987020015717,
        0.04590736702084541,
        0.053315091878175735,
        -0.05532575398683548,
        0.048140428960323334,
        -0.017732305452227592,
        0.0540611669421196,
        -0.009269057773053646,
        -0.05337051674723625,
        -0.05216190591454506,
        -0.0552644319832325,
        -0.01926153525710106,
        -0.05513996258378029,
        0.05562417954206467,
        -0.05555542930960655,
        -0.04834141954779625,
        0.055815596133470535,
        0.05574716255068779,
        0.05447797477245331,
        0.054891437292099,
        0.004078579135239124,
        -0.044093355536460876,
        -0.05444452539086342,
        0.053457025438547134,
        -0.04806152731180191,
        0.05538744851946831,
        0.05552977696061134,
        -0.05567042902112007,
        0.051265064626932144,
        -0.028217261657118797,
        0.05449565872550011,
        0.009049396961927414,
        -0.052807364612817764,
        0.02592908777296543,
        -0.049131326377391815,
        0.04631032794713974,
        0.04176676645874977,
        0.003837915137410164,
        0.04657134786248207,
        0.052968986332416534,
        -0.03396962955594063,
        -0.015712736174464226,
        0.0538775809109211,
        0.052308764308691025,
        0.05563696473836899,
        0.008747030049562454,
        0.05431771278381348,
        0.03988150134682655,
        -0.002702490659430623,
        -0.04104000702500343,
        0.05512986704707146,
        0.04708955064415932,
        0.04001479968428612,
        -0.008394300937652588,
        -0.04677714407444,
        -0.05048702657222748,
        -0.055453576147556305,
        0.022928986698389053,
        -0.038897547870874405,
        -0.020480835810303688,
        -0.051319669932127,
        0.036783210933208466,
        -0.05507379397749901,
        -0.05162346735596657,
        0.05334119126200676,
        -0.05399404466152191,
        -0.05560826510190964,
        -0.05367686599493027,
        0.03136482462286949,
        0.05067753419280052,
        -0.0556199885904789,
        -0.04763287678360939,
        -0.03494729846715927,
        0.05582830309867859,
        0.0016858667368069291,
        -0.050004731863737106,
        -0.051111023873090744,
        -0.050574421882629395,
        -0.04851755127310753,
        -0.05184229463338852,
        0.03724601864814758,
        0.046682536602020264,
        -0.05558033660054207,
        -0.05339539796113968,
        0.011846964247524738,
        0.016552045941352844,
        0.009175868704915047,
        0.01117422804236412,
        0.04317037761211395,
        -0.05553444102406502,
        -0.05195565149188042,
        0.05348849296569824,
        -0.044107794761657715,
        -0.048760101199150085,
        0.054633814841508865,
        0.0408351868391037,
        -0.055477116256952286,
        -0.029236329719424248,
        -0.042281098663806915,
        0.047808967530727386,
        0.007945925928652287,
        -0.03372016176581383,
        0.053560368716716766,
        0.03381945565342903,
        -0.05350276082754135,
        0.04971207305788994,
        -0.013636020943522453,
        0.03700723126530647,
        -0.045477207750082016,
        -0.05429239571094513,
        -0.03271648660302162,
        -0.041565049439668655,
        -0.003898347495123744,
        -0.05567029491066933,
        -0.03139775991439819,
        0.036957670003175735,
        0.032136864960193634,
        0.008724999614059925,
        -0.04877067357301712,
        -0.04399130120873451,
        -0.05225389450788498,
        0.03664402291178703,
        -0.055591169744729996,
        0.05169779807329178,
        0.013918507844209671,
        -0.025031935423612595,
        0.01633520796895027,
        -0.05244925990700722,
        0.04297352954745293,
        0.036592792719602585,
        -0.0458383709192276,
        -0.05539010837674141,
        0.05466744303703308,
        -0.04621100053191185,
        -0.02938241697847843,
        -0.00031012206454761326,
        0.05020902678370476,
        0.032608017325401306,
        0.04874390363693237,
        -0.03371041268110275,
        -0.047526538372039795,
        -0.05265965312719345,
        0.007007230538874865,
        0.03072584979236126,
        -0.0557439923286438,
        0.05556291714310646,
        0.046473100781440735,
        -0.05339072644710541,
        -0.04549051448702812,
        -0.021679764613509178,
        0.05583474785089493,
        -0.03591953590512276,
        0.055301666259765625,
        -0.030062714591622353,
        0.04865369200706482,
        0.030702320858836174,
        0.015452870167791843,
        0.04544772952795029,
        0.05228526145219803,
        -0.0021348167210817337,
        -0.02088981308043003,
        -0.03987327218055725,
        0.05539770424365997,
        0.04665651544928551,
        0.05314183980226517,
        0.047357626259326935,
        -0.04103194922208786,
        -0.012629725970327854,
        -0.0558001808822155,
        -0.0292508527636528,
        0.051150452345609665,
        0.05521010607481003,
        -0.05572481080889702,
        0.047963473945856094,
        0.05570646747946739,
        -0.03594762086868286,
        -0.02392447739839554,
        -0.04805152490735054,
        0.023375554010272026,
        -0.03588597849011421,
        0.036562591791152954,
        -0.025673633441329002,
        0.05470674857497215,
        0.05207259580492973,
        -0.03337249532341957,
        0.030291849747300148,
        -0.05405617132782936,
        -0.04667074605822563,
        -0.053007643669843674,
        0.026783954352140427,
        -0.05548860505223274,
        -0.04783439263701439,
        -0.005053766071796417,
        0.041253019124269485,
        -0.03248034417629242,
        0.03621407225728035,
        0.055567871779203415,
        0.04772021621465683,
        -0.035010386258363724,
        -0.029535062611103058,
        0.054379310458898544,
        0.02852104976773262,
        0.0204666405916214,
        0.02821851335465908,
        -0.04780203849077225,
        0.034797947853803635,
        -0.05358977988362312,
        0.0482574924826622,
        -0.04535635560750961,
        0.02174513228237629,
        -0.050718165934085846,
        0.017934640869498253,
        0.05431864783167839,
        0.010148751549422741,
        0.05580028146505356,
        -0.04695373773574829,
        -0.051140960305929184,
        -0.05073031038045883,
        -0.03800052031874657,
        -0.035936180502176285,
        0.05329253524541855,
        0.030993234366178513,
        0.0061561367474496365,
        -0.02081930637359619,
        -0.006798515561968088,
        -0.05114870145916939,
        -0.05560486763715744,
        -0.026299459859728813,
        0.055460914969444275,
        0.043659333139657974,
        -0.036201730370521545,
        0.053538087755441666,
        0.04657438024878502,
        -0.05381862446665764,
        0.0556630976498127,
        0.0557992160320282,
        0.04634571447968483,
        0.05437755212187767,
        0.023064998909831047,
        -0.01477692648768425,
        -0.04900519549846649,
        -0.05533602833747864,
        0.01214862521737814,
        0.019952969625592232,
        -0.055154211819171906,
        -0.030088959261775017,
        -0.05029792711138725,
        -0.05409359931945801,
        0.03394223749637604,
        -0.05539657920598984,
        0.050040893256664276,
        0.04916360229253769,
        0.053485773503780365,
        -0.002409826498478651,
        0.05525033548474312,
        -0.024627529084682465,
        -0.047982778400182724,
        -0.0025465930812060833,
        0.016052234917879105,
        0.05433250963687897,
        0.052353937178850174,
        0.05567817762494087,
        0.04170035943388939,
        0.052425503730773926,
        0.05453937128186226,
        0.008067507296800613,
        0.02676301822066307,
        -0.05551116541028023,
        0.05257666856050491,
        -0.055653609335422516,
        -0.05293352156877518,
        0.0025649936869740486,
        -0.05058617144823074,
        -0.028624996542930603,
        -0.055835794657468796,
        -0.02337544783949852,
        -0.055703625082969666,
        -0.04539269953966141,
        -0.033797189593315125,
        0.051864564418792725,
        0.0016103085363283753,
        -0.0005064771394245327,
        -0.0345674529671669,
        0.02593887224793434,
        0.04627112299203873,
        0.037856243550777435,
        -0.05283042788505554,
        0.018194951117038727,
        -0.04793113097548485,
        -0.040242716670036316,
        -0.017500663176178932,
        -0.05170664191246033,
        0.05577630177140236,
        0.052459023892879486,
        -0.0465996190905571,
        0.05386982858181,
        -0.051615744829177856,
        0.03012620098888874,
        -0.015357442200183868,
        0.01213198620826006,
        -0.05148540809750557,
        -0.05101099610328674,
        -0.046745941042900085,
        0.05559356510639191,
        0.03199746832251549,
        -0.049693986773490906,
        0.03401379659771919,
        0.05054836720228195,
        -0.052721910178661346,
        0.05577889829874039,
        -0.05030453950166702,
        -0.05553305149078369,
        -0.0073196133598685265,
        0.05552142485976219,
        0.052415262907743454,
        0.04044201597571373,
        0.030946524813771248,
        -0.033025939017534256,
        0.03544185683131218,
        -0.05111712962388992,
        0.05193888396024704,
        0.014945600181818008,
        0.046926870942115784,
        0.05475969985127449,
        0.05279519408941269,
        -0.05076663941144943,
        0.04739638417959213,
        -0.05181511119008064,
        0.05247906967997551,
        0.05581764504313469,
        0.05498093366622925,
        0.054912764579057693,
        -0.05503139644861221,
        0.0523538738489151,
        -0.02339271269738674,
        0.05094173923134804,
        -0.0005687784869223833,
        -0.04903944581747055,
        -0.049784205853939056,
        0.018878402188420296,
        0.05579085275530815,
        0.04606393724679947,
        0.055284809321165085,
        0.05393500626087189,
        -0.0552019402384758,
        0.04680287092924118,
        0.023924149572849274,
        -0.04952616989612579,
        -0.04505936801433563,
        -0.051134899258613586,
        -0.0537375770509243,
        0.052540212869644165,
        -0.045791301876306534,
        -0.05520137771964073,
        -0.05319195240736008,
        -0.053016357123851776,
        0.04847516492009163,
        0.03033560700714588,
        -0.009834780357778072,
        -0.04718378558754921,
        -0.05482333153486252,
        -0.05502541735768318,
        0.05569664388895035,
        0.04957956075668335,
        0.012994525022804737,
        -0.05316658318042755,
        -0.04699457809329033,
        -0.03694210201501846,
        0.05526845157146454,
        0.009413507767021656,
        0.049905214458703995,
        0.055826276540756226,
        -0.01675974391400814,
        0.053533777594566345,
        0.05482674390077591,
        -0.055073078721761703,
        -0.032211996614933014,
        -0.010335483588278294,
        -0.0548253059387207,
        -0.03499425947666168,
        0.05566999688744545,
        -0.042205922305583954,
        0.04736185818910599,
        -0.04657325521111488,
        -0.05422724410891533,
        -0.04928801581263542,
        0.011710590682923794,
        0.052188318222761154,
        -0.05083378031849861,
        -0.05412422865629196,
        -0.029502592980861664,
        0.055336251854896545,
        0.04712706804275513,
        0.026228206232190132,
        -0.052988871932029724,
        -0.053098030388355255,
        -0.052913207560777664,
        0.05543181300163269,
        -0.05386841297149658,
        0.052545174956321716,
        0.03563793748617172,
        -0.05170226842164993,
        0.05165575444698334,
        -0.05202594771981239,
        0.03983158618211746,
        -0.02170662395656109,
        -0.04288778081536293,
        -0.025435790419578552,
        -0.041728321462869644,
        -0.03471403568983078,
        0.05543665587902069,
        -0.03920843079686165,
        -0.04355290159583092,
        0.05572086572647095,
        0.04226553067564964,
        0.0021063911262899637,
        0.04988662898540497,
        -0.05530224367976189,
        -0.053514961153268814,
        -0.05053015798330307,
        -0.04800564423203468,
        0.04542494937777519,
        -0.03591635823249817,
        -0.05344737321138382,
        -0.002461144234985113,
        -0.054552074521780014,
        0.054374609142541885,
        -0.04610827937722206,
        0.00042493341607041657,
        0.05477002635598183,
        0.043768007308244705,
        -0.05464037507772446,
        -0.010729950852692127,
        0.047486014664173126,
        -0.01796533539891243,
        -0.05286041647195816,
        -0.042427897453308105,
        0.030902262777090073,
        0.04993617534637451,
        0.05501611530780792,
        0.035625211894512177,
        -0.05525515228509903,
        0.05468975380063057,
        -0.05561228469014168,
        0.050191730260849,
        0.018839389085769653,
        -0.05314695090055466,
        0.04617791995406151,
        0.028551531955599785,
        -0.05345514416694641,
        0.04050346091389656,
        -0.052497271448373795,
        -0.04334578290581703,
        -0.04778619483113289,
        -0.015162576921284199,
        -0.04647291079163551,
        0.04490038752555847,
        0.043574560433626175,
        -0.036402445286512375,
        -0.04560048505663872,
        0.04447782412171364,
        0.05489901825785637,
        -0.05085654556751251,
        0.055670130997896194,
        -0.03306502476334572,
        -0.05503811314702034,
        0.05192149430513382,
        0.035324577242136,
        0.017338518053293228,
        0.05583152547478676,
        0.04957194998860359,
        -0.0290289968252182,
        -0.04520665854215622,
        0.05077558383345604,
        -0.04462967813014984,
        -0.05500045418739319,
        0.052326589822769165,
        -0.02029256522655487,
        -0.018984142690896988,
        0.035730987787246704,
        -0.032976020127534866,
        0.048970047384500504,
        0.051095180213451385,
        0.04428601637482643,
        -0.05348057299852371,
        -0.011703900061547756,
        0.047493238002061844,
        0.0476035512983799,
        0.017383286729454994,
        0.052064500749111176,
        -0.05240239202976227,
        -0.028095826506614685,
        0.035737618803977966,
        -0.05485459044575691,
        0.05210590362548828,
        0.01468708086758852,
        -0.04885702207684517,
        0.05232059583067894,
        0.049574438482522964,
        -0.04503244534134865,
        -0.0014510444598272443,
        -0.030679242685437202,
        -0.02983202412724495,
        -0.05090249702334404,
        0.05453711748123169,
        -0.051977552473545074,
        0.04682992026209831,
        0.04791567474603653
    ],
    [
        -0.02292260341346264,
        0.045894328504800797,
        0.05357066169381142,
        -0.003787066787481308,
        -0.04854375869035721,
        0.012644503265619278,
        0.05170900374650955,
        -0.046125397086143494,
        0.05092784762382507,
        -0.04601706936955452,
        0.013478166423738003,
        -0.050066571682691574,
        -0.05501092970371246,
        -0.05017518997192383,
        -0.054589368402957916,
        0.055004093796014786,
        -0.053598713129758835,
        0.05476772040128708,
        -0.04924758896231651,
        -0.05502040684223175,
        0.04310981184244156,
        0.04977119714021683,
        0.05387865751981735,
        0.04207498952746391,
        0.049690477550029755,
        0.04842795059084892,
        0.049454737454652786,
        0.043796610087156296,
        -0.054916251450777054,
        0.047310613095760345,
        0.007339096162468195,
        -0.05516699701547623,
        0.047159578651189804,
        -0.05515789985656738,
        0.048045285046100616,
        -0.0011866382556036115,
        -0.05461326614022255,
        -0.05440833419561386,
        0.05443016067147255,
        -0.05427763983607292,
        -0.03943486139178276,
        0.04136776924133301,
        0.05154245346784592,
        -0.044970445334911346,
        -0.05038383975625038,
        0.024974359199404716,
        0.039199378341436386,
        0.055128343403339386,
        0.05509602278470993,
        -0.027997899800539017,
        0.053150225430727005,
        0.0503678135573864,
        -0.049190934747457504,
        -0.05152294039726257,
        -0.022466782480478287,
        -0.026708336547017097,
        0.05031681805849075,
        -0.01272616721689701,
        -0.013020195998251438,
        -0.05038362741470337,
        -0.05472034588456154,
        0.017109528183937073,
        -0.05473742261528969,
        -0.010382969863712788,
        -0.05482704937458038,
        -0.05415397509932518,
        -0.054932720959186554,
        -0.055143147706985474,
        0.025661662220954895,
        -0.0546850822865963,
        -0.049652379006147385,
        -0.05433116480708122,
        -0.0018855332164093852,
        0.04144623875617981,
        -0.011857559904456139,
        -0.011796500533819199,
        -0.0435374490916729,
        0.05516459792852402,
        -0.020918479189276695,
        -0.049725569784641266,
        -0.0486876517534256,
        -0.04536440968513489,
        -0.050706882029771805,
        0.03445441275835037,
        -0.009186351671814919,
        0.041008271276950836,
        -0.054433830082416534,
        -0.03539096191525459,
        -0.01042064931243658,
        -0.014430887065827847,
        -0.049217961728572845,
        0.007347484584897757,
        -0.03906000778079033,
        -0.05471173673868179,
        -0.04375658929347992,
        0.05148453637957573,
        -0.009195051155984402,
        -0.04920574277639389,
        0.05061701312661171,
        0.05153868719935417,
        -0.055121682584285736,
        0.05297504737973213,
        0.04327566549181938,
        0.05188404768705368,
        0.04701582342386246,
        -0.05491207540035248,
        -0.05422142148017883,
        0.04555138573050499,
        -0.053789641708135605,
        0.05331030115485191,
        0.033055659383535385,
        -0.029029205441474915,
        -0.014876432716846466,
        -0.042962588369846344,
        0.030123388394713402,
        -0.05280720815062523,
        0.05060765519738197,
        -0.052946098148822784,
        0.05422741174697876,
        -0.04844357818365097,
        -0.005343145690858364,
        -0.020537937059998512,
        0.034258883446455,
        0.03443541005253792,
        -0.05354214459657669,
        -0.04910082742571831,
        0.053093768656253815,
        0.03229822590947151,
        0.04968452453613281,
        0.03531041741371155,
        0.05403025075793266,
        0.019146883860230446,
        0.038388095796108246,
        0.05254846438765526,
        -0.05173486843705177,
        -0.05106530711054802,
        0.05515293776988983,
        -0.0497567355632782,
        0.024519111961126328,
        0.052012745290994644,
        -0.03544280678033829,
        0.03534466028213501,
        0.04370838403701782,
        0.05416695773601532,
        0.05479568615555763,
        -0.050787582993507385,
        0.051871638745069504,
        -0.04470515996217728,
        -0.04918738082051277,
        -0.03017665632069111,
        0.05368012934923172,
        -0.05487671121954918,
        -0.04838110879063606,
        0.05469788238406181,
        0.054819777607917786,
        -0.05352647975087166,
        0.05458317697048187,
        -0.01190616749227047,
        0.0441560298204422,
        0.03596899285912514,
        -0.027025258168578148,
        -0.05094120651483536,
        0.05442647635936737,
        -0.04688584804534912,
        -0.054648786783218384,
        -0.01923915185034275,
        0.049459561705589294,
        -0.03927241638302803,
        0.027167949825525284,
        -0.007402896881103516,
        -0.04164089635014534,
        -0.05382249131798744,
        -0.004311600234359503,
        0.034285929054021835,
        -0.029803963378071785,
        0.048429928719997406,
        0.043740082532167435,
        0.020834628492593765,
        0.052754491567611694,
        -0.04831520840525627,
        -0.04338912293314934,
        0.03946395590901375,
        0.04413687065243721,
        -0.0008572480292059481,
        0.05121578648686409,
        -0.04100845381617546,
        0.054939113557338715,
        0.054517924785614014,
        -0.046225205063819885,
        -0.051926467567682266,
        -0.05346374958753586,
        -0.012269998900592327,
        0.03601902350783348,
        -0.0005723233916796744,
        -0.053465716540813446,
        -0.05376359075307846,
        0.03592899814248085,
        0.05120352655649185,
        -0.05495636537671089,
        0.028669405728578568,
        0.033332571387290955,
        0.04160528630018234,
        -0.04749638959765434,
        -0.05455591529607773,
        -0.0037717705126851797,
        0.03589159995317459,
        0.052882853895425797,
        0.05234232172369957,
        -0.009529830887913704,
        0.05390722304582596,
        -0.050126682966947556,
        -0.03014029748737812,
        0.051606882363557816,
        0.05420985817909241,
        -0.04023292288184166,
        -0.010723583400249481,
        0.0544787161052227,
        0.024426529183983803,
        0.05516374111175537,
        -0.04448859393596649,
        -0.04435918107628822,
        -0.04618775472044945,
        0.007914787158370018,
        0.004791629035025835,
        0.05296895280480385,
        0.05475596338510513,
        0.05491892993450165,
        -0.04964577406644821,
        0.025551501661539078,
        -0.04640550538897514,
        -0.044142946600914,
        -0.05446550250053406,
        0.01389575470238924,
        0.05218258872628212,
        0.05081937834620476,
        0.055042415857315063,
        0.049992576241493225,
        0.03459196910262108,
        0.031234487891197205,
        0.055023107677698135,
        0.053426966071128845,
        0.054601363837718964,
        0.03311668708920479,
        -0.05504131317138672,
        -0.0004964101826772094,
        -0.05458948761224747,
        0.0490376316010952,
        0.01604275405406952,
        -0.04952725023031235,
        -0.04663977399468422,
        -0.022169269621372223,
        -0.05484321340918541,
        0.05485302954912186,
        -0.05271170288324356,
        -0.017579808831214905,
        0.052592452615499496,
        0.04257790744304657,
        -0.021217333152890205,
        0.03588714823126793,
        0.03221370279788971,
        -0.038651514798402786,
        0.017166024073958397,
        0.053277160972356796,
        0.03567062318325043,
        -0.004936025477945805,
        0.053312305361032486,
        0.05465144291520119,
        0.05514710023999214,
        0.05024952068924904,
        0.052175477147102356,
        0.0535542368888855,
        -0.053953055292367935,
        0.05487263575196266,
        -0.05092359334230423,
        0.03967937082052231,
        0.008256657980382442,
        -0.04646996781229973,
        -0.055020347237586975,
        -0.05499995872378349,
        0.02330469898879528,
        -0.02699873596429825,
        0.011578664183616638,
        0.009376097470521927,
        0.05395703762769699,
        -0.015289069153368473,
        -0.007968741469085217,
        0.040644265711307526,
        0.055036500096321106,
        0.05266376957297325,
        0.03558298200368881,
        -0.04933128505945206,
        -0.011998661793768406,
        -0.04726994037628174,
        -0.0520184226334095,
        0.039106812328100204,
        -0.05236712843179703,
        0.05512149631977081,
        0.001234870171174407,
        -0.03125763311982155,
        0.054092347621917725,
        -0.003224441548809409,
        -0.04513175040483475,
        0.016431547701358795,
        -0.011585825122892857,
        -0.05510279908776283,
        -0.051360126584768295,
        -0.05445495992898941,
        0.05513971298933029,
        0.03715677931904793,
        -0.05488640069961548,
        0.04897202551364899,
        -0.00022389160585589707,
        -0.009996365755796432,
        0.05315180867910385,
        -0.05221131443977356,
        -0.053054116666316986,
        -0.049671582877635956,
        0.05503762513399124,
        0.0408516563475132,
        -0.0254112146794796,
        0.002538798376917839,
        -0.05511672794818878,
        0.05289433151483536,
        0.01788119040429592,
        0.05500434339046478,
        0.05487007275223732,
        -0.03720692917704582,
        -0.016963696107268333,
        -0.01133683416992426,
        -0.05454584211111069,
        0.03394193574786186,
        0.0036033669020980597,
        0.05514989420771599,
        0.055054713040590286,
        0.04907546564936638,
        0.054834138602018356,
        -0.05450079217553139,
        -0.0034868945367634296,
        -0.04823806509375572,
        0.0338582918047905,
        -0.05472588911652565,
        -0.05511404573917389,
        -0.024640191346406937,
        0.007946133613586426,
        0.05140310525894165,
        -0.00830802321434021,
        0.04424794018268585,
        0.05420226603746414,
        -0.03987366706132889,
        0.04961034655570984,
        0.01629306748509407,
        0.05482356250286102,
        0.027902739122509956,
        -0.054138753563165665,
        -0.05217170715332031,
        0.027311451733112335,
        -0.05515791103243828,
        -0.04969143867492676,
        0.013810479082167149,
        -0.017014486715197563,
        0.05324988812208176,
        0.00726084690541029,
        0.02049989439547062,
        -0.03636625036597252,
        -0.04479179531335831,
        -0.054321255534887314,
        0.05502454563975334,
        -0.018579991534352303,
        -0.007754301652312279,
        -0.04979514703154564,
        -0.004079010337591171,
        -0.05091558396816254,
        0.00950978510081768,
        0.02353673428297043,
        0.055001724511384964,
        0.05501704290509224,
        -0.05497584119439125,
        0.05506810545921326,
        0.05422430858016014,
        -0.03839147090911865,
        -0.05210603028535843,
        -0.04812208563089371,
        -0.04485399276018143,
        -0.050474297255277634,
        0.05508462339639664,
        -0.0465443953871727,
        -0.05465448647737503,
        -0.03179292380809784,
        -0.05375807359814644,
        -0.026585692539811134,
        -0.054776955395936966,
        -0.040244389325380325,
        -0.0489150807261467,
        -0.050525084137916565,
        -0.01051730290055275,
        0.053154099732637405,
        0.054779358208179474,
        -0.043399445712566376,
        -0.03405247628688812,
        -0.054885972291231155,
        -0.04410248249769211,
        0.05250363051891327,
        -0.004640977829694748,
        -0.014668784104287624,
        -0.045320674777030945,
        0.04756048694252968,
        0.05172239989042282,
        -0.05498119071125984,
        0.05431438237428665,
        -0.020769426599144936,
        0.015805093571543694,
        -0.025578226894140244,
        -0.009458270855247974,
        0.041345030069351196,
        0.05124734714627266,
        -0.013910193927586079,
        0.0380123071372509,
        0.05139141529798508,
        0.053454674780368805,
        0.05073760449886322,
        -0.03979478031396866,
        -0.05419876426458359,
        0.05113869905471802,
        -0.055042702704668045,
        -0.04832262918353081,
        0.04960446059703827,
        -0.041599489748477936,
        -0.053637705743312836,
        -0.018757915124297142,
        -0.05472365766763687,
        0.055168554186820984,
        -0.03558911383152008,
        -0.039789244532585144,
        0.049516353756189346,
        -0.03421374037861824,
        -0.04044359549880028,
        -0.055054739117622375,
        0.05202362686395645,
        -0.04665132239460945,
        -0.05502365902066231,
        -0.05159885808825493,
        0.0357951745390892,
        -0.05093642324209213,
        0.05325947701931,
        0.025396842509508133,
        -0.05502958223223686,
        0.03315575420856476,
        -0.05279306694865227,
        -0.013079133816063404,
        0.050220489501953125,
        -0.05322902649641037,
        0.05478999391198158,
        0.054206814616918564,
        0.05148198828101158,
        0.008742363192141056,
        -0.017897237092256546,
        -0.04101153090596199,
        -0.05257038772106171,
        -0.04219358414411545,
        -0.04428437724709511,
        0.054182589054107666,
        0.03397844359278679,
        -0.04566149041056633,
        0.05361238867044449,
        0.05425986647605896,
        0.054972968995571136,
        -0.05398828536272049,
        0.05478796735405922,
        0.050291839987039566,
        -0.05143294483423233,
        0.04632328823208809,
        0.02897574007511139,
        0.05037396028637886,
        0.055078379809856415,
        -0.054189376533031464,
        0.03601709380745888,
        0.053480710834264755,
        0.050431374460458755,
        -0.04779885709285736,
        -0.05512678995728493,
        -0.045027390122413635,
        0.04783211648464203,
        -0.05461205914616585,
        0.033703986555337906,
        0.038005173206329346,
        0.052729811519384384,
        0.013008861802518368,
        0.05087431147694588,
        -0.05232663452625275,
        -0.04348823428153992,
        0.055033985525369644,
        -0.05460521578788757,
        -0.05390830338001251,
        0.002037974074482918,
        -0.005165570881217718,
        0.03904375061392784,
        -0.04654502123594284,
        0.023232990875840187,
        0.04991666600108147,
        0.05475436896085739,
        -0.05515720322728157,
        0.044491373002529144,
        -0.05289215222001076,
        -0.055165067315101624,
        0.02907625399529934,
        0.05352140590548515,
        0.05434242635965347,
        -0.046108998358249664,
        0.039401084184646606,
        -0.03825802356004715,
        0.03870060667395592,
        -0.016188109293580055
    ],
    [
        0.0519375279545784,
        -0.04653273895382881,
        -0.05115494504570961,
        0.04375350475311279,
        0.013794044964015484,
        -0.05222293362021446,
        0.051906123757362366,
        -0.05106842517852783,
        0.05155589058995247,
        -0.05211103335022926,
        0.03431149944663048,
        0.050781529396772385,
        -0.05205467343330383,
        -0.036956269294023514,
        -0.052020154893398285,
        0.05216066539287567,
        -0.013057906180620193,
        0.052058134227991104,
        0.02192002162337303,
        -0.04518243670463562,
        0.05222013220191002,
        0.05222810059785843,
        0.05222884938120842,
        -0.05207853391766548,
        -0.05159751698374748,
        0.049604643136262894,
        0.010474314913153648,
        0.05109620839357376,
        -0.04874938353896141,
        0.05222810059785843,
        0.0024718609638512135,
        -0.05215248465538025,
        0.0036952102091163397,
        -0.052229322493076324,
        0.051817793399095535,
        0.004585118032991886,
        -0.052127644419670105,
        -0.003362752264365554,
        0.05198298394680023,
        -0.027650224044919014,
        0.05155259370803833,
        0.05033770948648453,
        0.04837192967534065,
        0.04518866166472435,
        -0.007555422838777304,
        -0.015852084383368492,
        0.05217396467924118,
        0.05220076069235802,
        0.051438845694065094,
        -0.05220775678753853,
        0.05218528211116791,
        -0.052155446261167526,
        -0.036473989486694336,
        -0.040808651596307755,
        0.05046149715781212,
        -0.007403647527098656,
        0.051815442740917206,
        -0.042982641607522964,
        -0.012935802340507507,
        0.05035049840807915,
        -0.03400300443172455,
        0.0362427644431591,
        -0.05191345512866974,
        0.04343809187412262,
        -0.005657429341226816,
        -0.047721538692712784,
        -0.05217757821083069,
        -0.05162933096289635,
        -0.047232486307621,
        -0.05222884565591812,
        0.050317276269197464,
        -0.05222499370574951,
        -0.03097514808177948,
        0.052222833037376404,
        -0.0350717268884182,
        -0.03757588565349579,
        0.05150782689452171,
        0.05223018303513527,
        -0.01582692749798298,
        0.03784261271357536,
        -0.03192949295043945,
        -0.05110898241400719,
        -0.05192628875374794,
        0.04229635000228882,
        0.05034225806593895,
        0.051417235285043716,
        -0.05217955261468887,
        -0.05204504355788231,
        0.05006018280982971,
        0.051104385405778885,
        -0.04950100556015968,
        -0.05157918110489845,
        -0.035833168774843216,
        -0.05161288380622864,
        -0.036407265812158585,
        0.012787814252078533,
        -0.05120683088898659,
        -0.04492861405014992,
        0.043602779507637024,
        0.05018625408411026,
        -0.05215379223227501,
        -0.04717724025249481,
        0.04668702557682991,
        0.05192405357956886,
        -0.04941398650407791,
        -0.051494844257831573,
        -0.05208323895931244,
        -0.003227733075618744,
        -0.052006203681230545,
        0.052061647176742554,
        -0.04546414688229561,
        0.052229348570108414,
        -0.04937664419412613,
        -0.008362594991922379,
        -0.0005186106427572668,
        -0.052175942808389664,
        0.05166960880160332,
        -0.051108598709106445,
        0.05222761631011963,
        0.020317712798714638,
        -0.018893910571932793,
        -0.01697557605803013,
        -0.004980702418833971,
        -0.05210665613412857,
        -0.047757476568222046,
        0.015298094600439072,
        0.05212019383907318,
        0.0521632581949234,
        0.05108761042356491,
        0.00986710749566555,
        0.021177593618631363,
        -0.04926356300711632,
        0.050309523940086365,
        0.049283258616924286,
        -0.04696246236562729,
        -0.05014195665717125,
        0.05219115689396858,
        0.05217830464243889,
        0.044969599694013596,
        0.048576898872852325,
        -0.008412396535277367,
        0.05222427845001221,
        0.0368448831140995,
        0.04786881059408188,
        0.05141590163111687,
        0.020176490768790245,
        -0.025237582623958588,
        -0.04968643561005592,
        0.05155349522829056,
        -0.039693403989076614,
        -0.0006813000654801726,
        -0.052028413861989975,
        -0.024390356615185738,
        0.0522269532084465,
        -0.04243334382772446,
        0.04540818929672241,
        0.0007923369412310421,
        0.044571176171302795,
        0.05046134442090988,
        -0.002259603701531887,
        -0.05061575397849083,
        -0.050743307918310165,
        0.05151502788066864,
        0.037417083978652954,
        -0.047858309000730515,
        0.038512133061885834,
        0.05152488872408867,
        0.003164468565955758,
        -0.04852229356765747,
        0.03215144947171211,
        -0.05218902975320816,
        -0.03511353209614754,
        0.029135992750525475,
        -0.05207757651805878,
        -0.05220890790224075,
        0.04462413117289543,
        0.019168786704540253,
        0.05165493115782738,
        0.050520576536655426,
        -0.047637853771448135,
        -0.036832019686698914,
        0.0177195742726326,
        0.017356842756271362,
        -0.03804631531238556,
        0.011874427087605,
        -0.052186738699674606,
        0.0521557554602623,
        0.05119055509567261,
        0.05165870115160942,
        -0.03967880830168724,
        -0.05073713883757591,
        0.040193408727645874,
        0.04979177564382553,
        0.0026207438204437494,
        -0.05215901881456375,
        -0.03953824192285538,
        0.052133046090602875,
        0.05160036310553551,
        -0.05124787986278534,
        0.04778308421373367,
        0.0521656759083271,
        -0.032688628882169724,
        -0.051551468670368195,
        -0.05189233645796776,
        0.00973474606871605,
        -0.002359536476433277,
        0.04830830544233322,
        0.047780491411685944,
        0.015255028381943703,
        -0.05051945522427559,
        -0.04298132658004761,
        -0.04452567175030708,
        0.05217834562063217,
        0.0520976185798645,
        0.04847962409257889,
        0.05157369747757912,
        0.05218164250254631,
        0.04676838591694832,
        0.05223020538687706,
        -0.02746550925076008,
        -0.04714362695813179,
        -0.05153362452983856,
        0.008128343150019646,
        -0.037459392100572586,
        0.0516526885330677,
        -0.05203929543495178,
        0.030069781467318535,
        -0.0521739162504673,
        -0.051067039370536804,
        -0.04379257187247276,
        -0.021224312484264374,
        -0.052228160202503204,
        0.03174491226673126,
        0.05102335661649704,
        0.03299335390329361,
        0.04988604784011841,
        0.05161648243665695,
        0.04376626014709473,
        -0.0381707139313221,
        0.05222920700907707,
        0.05212827026844025,
        -0.017143310979008675,
        -0.05014176666736603,
        -0.05202830210328102,
        -0.029589980840682983,
        -0.00552095053717494,
        0.03652394190430641,
        -0.001530188019387424,
        -0.052155833691358566,
        0.050678472965955734,
        -0.02090795896947384,
        -0.03961406275629997,
        0.05167946591973305,
        -0.04475050047039986,
        0.0436641164124012,
        0.052113957703113556,
        0.05222898721694946,
        0.019679876044392586,
        0.04825618863105774,
        -0.01823948323726654,
        0.011502507142722607,
        0.045583128929138184,
        -0.015450718812644482,
        0.02073216252028942,
        -0.001671613659709692,
        0.05222456529736519,
        0.04011990502476692,
        0.04313090443611145,
        -0.03072202391922474,
        0.05211220309138298,
        0.04108801111578941,
        -0.047523681074380875,
        0.045785050839185715,
        -0.051970187574625015,
        0.052207786589860916,
        0.041951846331357956,
        -0.05019540339708328,
        -0.0467141792178154,
        -0.04091649875044823,
        -0.03795596957206726,
        -0.04889366030693054,
        0.04980362579226494,
        -0.050742946565151215,
        0.05222848802804947,
        0.015533522702753544,
        0.052146829664707184,
        -0.015302293933928013,
        0.04844094440340996,
        0.038604483008384705,
        -0.04335267096757889,
        -0.04954676330089569,
        -0.00282166781835258,
        -0.01792527735233307,
        -0.049123115837574005,
        0.05142701044678688,
        -0.0511716827750206,
        0.050313618034124374,
        0.04650554805994034,
        -0.05217081308364868,
        0.05170444771647453,
        -0.051865264773368835,
        0.046657495200634,
        -0.04492954909801483,
        0.03917436674237251,
        0.045061320066452026,
        -0.04807308688759804,
        -0.05222063139081001,
        0.0522301085293293,
        0.050992451608181,
        0.01936315931379795,
        0.04524647444486618,
        -0.05016448721289635,
        0.0114995576441288,
        0.0496450737118721,
        0.000552010431420058,
        -0.05010165646672249,
        -0.0389317162334919,
        0.05222105607390404,
        0.05201781541109085,
        0.0008331928984262049,
        0.05222408100962639,
        -0.047239042818546295,
        0.05219517648220062,
        0.052157409489154816,
        0.05184423550963402,
        0.051456693559885025,
        -0.03153449669480324,
        -0.04704470932483673,
        0.007516841404139996,
        -0.03633136674761772,
        0.0519510954618454,
        -0.046234410256147385,
        0.05220108479261398,
        0.05219053104519844,
        0.05095815658569336,
        0.047996919602155685,
        -0.05214712396264076,
        0.006755908951163292,
        -0.05199291929602623,
        0.034490302205085754,
        -0.04818189889192581,
        -0.052222512662410736,
        0.050435591489076614,
        0.050106603652238846,
        0.04187062755227089,
        -0.007181201130151749,
        0.0510406494140625,
        0.05147065594792366,
        -0.050313279032707214,
        0.0474233403801918,
        0.0519099235534668,
        0.002837713807821274,
        -0.02930201217532158,
        -0.05214796960353851,
        -0.04152033105492592,
        -0.03315200284123421,
        -0.008708016015589237,
        -0.05176813527941704,
        -0.041430067270994186,
        -0.04440709576010704,
        0.01466927770525217,
        0.05213585123419762,
        0.051700618118047714,
        -0.05054324120283127,
        -0.05221499875187874,
        -0.05211678892374039,
        0.052068110555410385,
        -0.011383242905139923,
        -0.026819556951522827,
        -0.05221596360206604,
        -0.042104918509721756,
        -0.04759589955210686,
        0.05201977863907814,
        0.02098207361996174,
        0.052065957337617874,
        0.05222375690937042,
        -0.0433182492852211,
        -0.04198509082198143,
        0.049905866384506226,
        -0.04983728006482124,
        -0.05211437866091728,
        -0.01708597131073475,
        -0.03443961963057518,
        -0.0520767904818058,
        0.05142427235841751,
        -0.051232628524303436,
        0.04625115171074867,
        -0.045205116271972656,
        -0.04670033976435661,
        0.04784488305449486,
        -0.05222104489803314,
        0.04650828242301941,
        -0.04629787802696228,
        -0.04980601370334625,
        0.04640592262148857,
        0.05213112756609917,
        -0.04926655814051628,
        -0.048197243362665176,
        0.03316894546151161,
        -0.05082637444138527,
        -0.048532795161008835,
        0.05092747509479523,
        -0.022892924025654793,
        0.005233687814325094,
        -0.044077057391405106,
        0.04267875850200653,
        0.0506497398018837,
        0.017864186316728592,
        0.04741790518164635,
        0.04983467608690262,
        -0.04814046621322632,
        -0.052184898406267166,
        -0.03641524910926819,
        0.041293032467365265,
        0.05053122714161873,
        -0.01224077120423317,
        0.05207023769617081,
        0.03678921237587929,
        0.05212777853012085,
        0.04979539290070534,
        0.052036575973033905,
        0.04057152569293976,
        -0.03241626173257828,
        -0.05221668258309364,
        0.039801426231861115,
        -0.039401620626449585,
        -0.05166875943541527,
        0.03569618985056877,
        -0.052021175622940063,
        -0.05219459533691406,
        0.051343608647584915,
        -0.014752724207937717,
        -0.052036307752132416,
        -0.022763920947909355,
        -0.0521671324968338,
        -0.04003901034593582,
        -0.0519748292863369,
        -0.016079656779766083,
        -0.048098839819431305,
        -0.051094457507133484,
        -0.05125979706645012,
        0.0435618981719017,
        -0.052101582288742065,
        0.05070050433278084,
        -0.05088574066758156,
        -0.04979313537478447,
        0.050566788762807846,
        -0.04885146766901016,
        0.023966217413544655,
        0.04972877353429794,
        -0.01983065903186798,
        0.04395991191267967,
        0.051876381039619446,
        0.00789585430175066,
        -0.05220942571759224,
        -0.042081840336322784,
        -0.04898155480623245,
        -0.045366376638412476,
        -0.05187630653381348,
        -0.052097201347351074,
        0.03274209797382355,
        -0.04991348460316658,
        -0.04456707835197449,
        0.04356604442000389,
        0.04804278910160065,
        0.05215659737586975,
        -0.049733635038137436,
        0.05218052491545677,
        0.05206843838095665,
        -0.04679858312010765,
        0.016852032393217087,
        0.05191359296441078,
        0.05197097733616829,
        0.052199508994817734,
        -0.05196085944771767,
        0.04593459889292717,
        0.050377313047647476,
        -0.05221569165587425,
        -0.037843164056539536,
        -0.05216006189584732,
        -0.05148785933852196,
        0.05026719346642494,
        0.0430053249001503,
        0.046506617218256,
        -0.0521734282374382,
        0.03261449560523033,
        0.009620060212910175,
        0.04658721387386322,
        -0.05198964104056358,
        -0.05222010612487793,
        0.03602830693125725,
        -0.05210867524147034,
        -0.04480162635445595,
        0.035456012934446335,
        -0.04499828815460205,
        -0.020295508205890656,
        0.05192583426833153,
        0.018492165952920914,
        0.04685936123132706,
        0.05125761777162552,
        -0.05068525671958923,
        0.05158061906695366,
        -0.05097637698054314,
        -0.052124638110399246,
        -0.05208053067326546,
        0.04835761711001396,
        0.05219206586480141,
        -0.019953209906816483,
        0.04245302081108093,
        0.049722105264663696,
        0.04957657679915428,
        0.007075343281030655
    ],
    [
        -0.055008407682180405,
        0.03254403918981552,
        0.05497242137789726,
        -0.0008464195998385549,
        -0.05472041293978691,
        0.05502384901046753,
        -0.021020056679844856,
        -0.05448008328676224,
        0.053774215281009674,
        0.05336780846118927,
        0.05498349294066429,
        -0.02522699348628521,
        -0.04865187034010887,
        -0.05280248075723648,
        -0.048761844635009766,
        0.05501800775527954,
        0.033439751714468,
        0.04953785985708237,
        -0.012543674558401108,
        0.04090769961476326,
        0.044856224209070206,
        0.01423586718738079,
        -0.054989445954561234,
        0.05410529300570488,
        0.05107920616865158,
        0.04328589886426926,
        -0.02557680383324623,
        0.053701769560575485,
        -0.0499735064804554,
        -0.029309889301657677,
        0.05497778579592705,
        -0.053233932703733444,
        0.05445344001054764,
        -0.053588952869176865,
        0.05234261974692345,
        -0.04160354286432266,
        -0.05270439758896828,
        0.04350815713405609,
        0.0545600987970829,
        0.007572450675070286,
        -0.007010469678789377,
        0.03352510184049606,
        0.037447571754455566,
        -0.005418708547949791,
        0.04956786707043648,
        -0.0053739468567073345,
        0.05493561550974846,
        0.054446667432785034,
        0.053951654583215714,
        0.05372295901179314,
        0.05444677919149399,
        0.0550113208591938,
        0.03838331624865532,
        -0.005536727607250214,
        0.05502424016594887,
        -0.05501938611268997,
        0.054899442940950394,
        0.024636220186948776,
        -0.045602742582559586,
        -0.04363648220896721,
        -0.05485054850578308,
        -0.012775622308254242,
        -0.05344875901937485,
        -0.05464886873960495,
        -0.023867657408118248,
        -0.04101179912686348,
        -0.04815840348601341,
        0.05311865359544754,
        0.05284085124731064,
        -0.05492936074733734,
        -0.034445397555828094,
        -0.054339151829481125,
        0.054945651441812515,
        -0.02824123203754425,
        -0.05502413585782051,
        -0.036136824637651443,
        0.024074368178844452,
        0.055015675723552704,
        0.017087185755372047,
        -0.02891138754785061,
        -0.04804399982094765,
        0.0300279650837183,
        -0.05424819886684418,
        0.053444765508174896,
        0.04193399101495743,
        0.04926491528749466,
        0.025121429935097694,
        0.051940206438302994,
        -0.05314945802092552,
        0.04708298668265343,
        -0.054312609136104584,
        -0.03858298808336258,
        0.049285925924777985,
        -0.026482975110411644,
        -0.045180391520261765,
        0.04166950285434723,
        -0.001974576385691762,
        -0.05287758260965347,
        -0.006914626806974411,
        0.04202934727072716,
        -0.054963525384664536,
        0.05499453470110893,
        0.02966766245663166,
        0.023806598037481308,
        -0.028441157191991806,
        -0.051321472972631454,
        -0.052645232528448105,
        0.05411476641893387,
        0.041561610996723175,
        0.05493941530585289,
        0.053405582904815674,
        0.032292675226926804,
        0.020822376012802124,
        0.03732055053114891,
        -0.00845360103994608,
        0.054782405495643616,
        -0.04661304131150246,
        -0.03293199464678764,
        0.05228034406900406,
        0.015701714903116226,
        0.0513504296541214,
        -0.03695809468626976,
        -0.053291916847229004,
        0.026643728837370872,
        -0.0533406101167202,
        -0.05385369434952736,
        -0.054533734917640686,
        0.054136984050273895,
        0.020263180136680603,
        0.002604173496365547,
        -0.055022500455379486,
        -0.036917492747306824,
        -0.006694758776575327,
        0.020668134093284607,
        -0.03426937013864517,
        -0.04247002303600311,
        0.05497582256793976,
        -0.05234139785170555,
        -0.05460408329963684,
        0.04320196434855461,
        0.03665745258331299,
        0.031009508296847343,
        0.053479619324207306,
        -0.05502159520983696,
        -0.023265378549695015,
        0.000048249054088955745,
        -0.0019603269174695015,
        0.04337477684020996,
        -0.05485835671424866,
        0.049428001046180725,
        0.054845359176397324,
        -0.05496889352798462,
        -0.029179586097598076,
        -0.03470330685377121,
        0.05455001816153526,
        0.002605987014248967,
        0.05059447139501572,
        0.04654126614332199,
        0.054922573268413544,
        0.020261062309145927,
        -0.026455875486135483,
        -0.0542508028447628,
        0.053122807294130325,
        0.010840806178748608,
        0.05488448962569237,
        0.017221178859472275,
        0.052876438945531845,
        -0.04650886729359627,
        0.05449925363063812,
        0.039739105850458145,
        -0.054918695241212845,
        0.003167062997817993,
        -0.0011432216269895434,
        -0.02591704949736595,
        0.05375155806541443,
        0.05371878668665886,
        0.05345288664102554,
        -0.05118910223245621,
        0.054225921630859375,
        -0.05094067007303238,
        -0.045584630221128464,
        -0.00946141593158245,
        -0.038943927735090256,
        0.05379817262291908,
        0.027971334755420685,
        0.016284514218568802,
        -0.05189273878931999,
        0.05305647477507591,
        -0.03939782455563545,
        0.054882779717445374,
        -0.012755506671965122,
        0.05357221141457558,
        0.054001301527023315,
        0.05294724553823471,
        -0.0496533140540123,
        -0.05362190306186676,
        0.04264442250132561,
        -0.021837618201971054,
        -0.045047223567962646,
        0.050839900970458984,
        0.007809294853359461,
        0.05134110525250435,
        0.008794953115284443,
        -0.054683659225702286,
        0.055002257227897644,
        -0.04384489357471466,
        0.012221713550388813,
        -0.05497560277581215,
        -0.05463070422410965,
        0.054942160844802856,
        -0.02116170898079872,
        0.04937376827001572,
        0.007622044999152422,
        -0.026541898027062416,
        0.05342816188931465,
        -0.054128725081682205,
        0.048632122576236725,
        -0.042405854910612106,
        0.035473890602588654,
        -0.05501599237322807,
        0.05297239124774933,
        0.05483706668019295,
        0.003500973340123892,
        -0.003368868725374341,
        0.04730226844549179,
        -0.053097132593393326,
        0.04148239642381668,
        -0.04514910280704498,
        0.05177302658557892,
        -0.0239728856831789,
        -0.054458294063806534,
        0.020587747916579247,
        0.01807197742164135,
        0.05354321748018265,
        0.05127997696399689,
        0.054858725517988205,
        0.039491549134254456,
        0.028838545083999634,
        -0.05501727759838104,
        0.054974187165498734,
        -0.04252109304070473,
        0.04971560835838318,
        -0.0489129014313221,
        -0.052918631583452225,
        0.04908781498670578,
        -0.003964584320783615,
        0.045181721448898315,
        -0.021088963374495506,
        -0.00020435050828382373,
        -0.05502258986234665,
        0.009870532900094986,
        -0.053849026560783386,
        0.05502435937523842,
        -0.05153327435255051,
        0.051678646355867386,
        0.054382480680942535,
        -0.006579856388270855,
        -0.049153540283441544,
        0.01745389960706234,
        -0.04708142578601837,
        -0.009270278736948967,
        0.05438986048102379,
        -0.039911288768053055,
        0.05472408980131149,
        0.05502405762672424,
        0.05444057658314705,
        0.0545852966606617,
        0.05502210184931755,
        0.04934902861714363,
        0.055019278079271317,
        -0.02019314281642437,
        -0.05454828590154648,
        0.054798707365989685,
        -0.05427134409546852,
        0.05404026433825493,
        0.05359569191932678,
        -0.04578277841210365,
        -0.04010705649852753,
        0.04524128884077072,
        -0.037268973886966705,
        -0.05251568183302879,
        -0.026819530874490738,
        -0.054962221533060074,
        -0.0457187294960022,
        -0.05147925391793251,
        -0.005886440631002188,
        0.04026859253644943,
        -0.04552168399095535,
        0.054735731333494186,
        0.053230464458465576,
        -0.04753352701663971,
        0.0037096303422003984,
        -0.0036803404800593853,
        0.026080111041665077,
        0.05494371056556702,
        -0.037604961544275284,
        0.0548524335026741,
        0.03010270930826664,
        0.0028682490810751915,
        0.04709825664758682,
        0.05034428462386131,
        0.011126842349767685,
        0.018023643642663956,
        -0.007368756458163261,
        0.029425548389554024,
        0.03619701787829399,
        0.044840436428785324,
        0.05441078171133995,
        0.001109104254283011,
        -0.029999421909451485,
        -0.05500819534063339,
        0.047110944986343384,
        0.004667822737246752,
        0.05172349885106087,
        0.05407025292515755,
        -0.05283782258629799,
        -0.02380041405558586,
        0.05502435937523842,
        0.05340658128261566,
        -0.032150641083717346,
        -0.055004529654979706,
        0.038143374025821686,
        0.054503463208675385,
        -0.003656048094853759,
        0.03246305510401726,
        0.05146627128124237,
        0.0448782816529274,
        0.053433291614055634,
        0.0005245457869023085,
        -0.04589218273758888,
        -0.011300778947770596,
        -0.05371616780757904,
        0.045137353241443634,
        0.05492397025227547,
        0.04869720712304115,
        0.04993564262986183,
        -0.05372075363993645,
        0.05319693684577942,
        -0.003740740707144141,
        0.015778446570038795,
        -0.05096087232232094,
        -0.05284124240279198,
        0.0540817528963089,
        0.0516616515815258,
        -0.05478769913315773,
        0.02187419682741165,
        0.05169575661420822,
        0.052720218896865845,
        -0.05498184636235237,
        -0.027742300182580948,
        0.03914153575897217,
        0.0550045371055603,
        0.04321910813450813,
        -0.054304566234350204,
        -0.010881892405450344,
        0.05123576521873474,
        -0.05502137914299965,
        -0.05320140719413757,
        -0.052170176059007645,
        -0.04936055466532707,
        0.051954250782728195,
        0.010282961651682854,
        -0.04446198791265488,
        -0.04168326035141945,
        -0.052924882620573044,
        -0.05191571265459061,
        0.05490698665380478,
        0.04117710143327713,
        -0.007645966485142708,
        -0.05260374769568443,
        -0.02568933181464672,
        0.022712653502821922,
        -0.02653384953737259,
        -0.05060841888189316,
        0.04020801559090614,
        0.05423670634627342,
        0.026819299906492233,
        0.054124534130096436,
        0.054461412131786346,
        0.05490988492965698,
        0.052347078919410706,
        -0.050464510917663574,
        -0.04876870661973953,
        -0.03716924041509628,
        0.04392927139997482,
        -0.054462552070617676,
        -0.024839669466018677,
        -0.03243745118379593,
        -0.05408955365419388,
        -0.055019743740558624,
        -0.05398207902908325,
        -0.05422645062208176,
        -0.05425480753183365,
        0.013667946681380272,
        0.03530922159552574,
        0.05430709198117256,
        0.041841745376586914,
        -0.04017232358455658,
        -0.05496510863304138,
        -0.05348068103194237,
        -0.05363183841109276,
        0.054726921021938324,
        -0.05149577185511589,
        -0.012611445970833302,
        0.05337218567728996,
        0.05305563285946846,
        0.02651246078312397,
        -0.04863784462213516,
        0.054907895624637604,
        -0.01864924095571041,
        -0.019016550853848457,
        -0.024380868300795555,
        0.016277533024549484,
        0.047155555337667465,
        0.04749799519777298,
        0.023208731785416603,
        -0.04669548571109772,
        0.05429401993751526,
        0.04408416897058487,
        0.04339016228914261,
        0.0184804517775774,
        -0.04013800621032715,
        -0.007701244205236435,
        -0.05495566874742508,
        -0.03292230889201164,
        -0.03884187713265419,
        -0.03489632532000542,
        -0.05291956663131714,
        0.05132050812244415,
        -0.05034736171364784,
        -0.0529196672141552,
        -0.040954411029815674,
        -0.05410594120621681,
        -0.054166629910469055,
        0.04363664612174034,
        -0.052732907235622406,
        0.012184183113276958,
        0.047265443950891495,
        -0.0499538779258728,
        -0.04311596229672432,
        -0.012315914034843445,
        -0.04324917122721672,
        0.05502261966466904,
        0.04743632301688194,
        -0.03287036716938019,
        0.03811024874448776,
        0.05476926639676094,
        0.05423961207270622,
        0.05251104757189751,
        0.05494517832994461,
        0.054494988173246384,
        -0.003899721661582589,
        -0.04435138776898384,
        -0.05377829074859619,
        0.05467826500535011,
        -0.05477938801050186,
        -0.011355763301253319,
        -0.01421026885509491,
        0.04767950251698494,
        -0.0443255677819252,
        0.0357186384499073,
        0.05262575298547745,
        0.052965641021728516,
        0.055010415613651276,
        0.05246579647064209,
        0.05457045137882233,
        0.029675684869289398,
        0.05224360525608063,
        -0.054939430207014084,
        -0.05137324333190918,
        0.05478062853217125,
        0.04460493475198746,
        0.04648102819919586,
        -0.016622966155409813,
        -0.05497302487492561,
        0.0478857047855854,
        0.02385159768164158,
        0.054189592599868774,
        -0.038954660296440125,
        -0.04474247246980667,
        0.05501580610871315,
        -0.05146096274256706,
        -0.05184473469853401,
        0.038401905447244644,
        -0.026921046897768974,
        0.051905371248722076,
        0.0526316836476326,
        0.05208097770810127,
        -0.0515512190759182,
        -0.009182918816804886,
        -0.05483229458332062,
        -0.016011225059628487,
        0.008359767496585846,
        0.0003907163627445698,
        -0.04647620767354965,
        0.053878456354141235,
        0.039445120841264725,
        -0.05491400137543678,
        0.05329604819417,
        0.02915124036371708,
        0.043893564492464066,
        0.04936136305332184,
        -0.0007057674811221659,
        -0.05463671684265137,
        -0.03423545882105827,
        0.030679114162921906,
        0.05489373579621315,
        -0.037136487662792206,
        0.05475081875920296,
        -0.023253528401255608,
        0.05494137108325958,
        -0.028599495068192482
    ],
    [
        -0.03925900161266327,
        -0.05444958060979843,
        -0.051182933151721954,
        0.0153177035972476,
        -0.05404290556907654,
        0.03886570408940315,
        0.050668224692344666,
        -0.05091016739606857,
        0.017761262133717537,
        -0.04181505739688873,
        0.054720547050237656,
        0.021746454760432243,
        -0.055120132863521576,
        0.05316510424017906,
        -0.055052272975444794,
        -0.05297291278839111,
        0.047094494104385376,
        0.054867565631866455,
        -0.05279283970594406,
        0.0547175407409668,
        -0.04961131513118744,
        0.013577806763350964,
        0.013895226642489433,
        0.041156843304634094,
        -0.04563639312982559,
        0.04723837971687317,
        0.007076408248394728,
        0.05460707098245621,
        -0.052539803087711334,
        -0.05485551804304123,
        0.018048997968435287,
        0.05362461134791374,
        0.013658148236572742,
        -0.054670918732881546,
        0.04209374636411667,
        -0.0545366145670414,
        -0.0546891875565052,
        -0.045853715389966965,
        -0.05469414219260216,
        -0.05259440466761589,
        0.019707053899765015,
        0.04684513434767723,
        0.05216037854552269,
        -0.053574129939079285,
        -0.03822070732712746,
        0.0067694964818656445,
        -0.04046270623803139,
        0.05323491618037224,
        0.05510948598384857,
        -0.05383185297250748,
        0.05509038269519806,
        0.045046281069517136,
        0.006972446572035551,
        -0.04574605077505112,
        -0.004987345077097416,
        0.012072281911969185,
        -0.05370928347110748,
        0.04397447407245636,
        -0.054491546005010605,
        -0.03411582484841347,
        -0.055023111402988434,
        0.013998063281178474,
        -0.05448935925960541,
        -0.054812557995319366,
        0.008802182972431183,
        -0.04443599656224251,
        -0.05110613629221916,
        -0.052143000066280365,
        0.044012803584337234,
        -0.05512550100684166,
        -0.0549817755818367,
        -0.05473044142127037,
        0.055130958557128906,
        0.03936990723013878,
        0.05404314398765564,
        -0.023478299379348755,
        0.018128858879208565,
        0.055126529186964035,
        0.025707637891173363,
        -0.01137413177639246,
        -0.043592847883701324,
        -0.05448465794324875,
        -0.05298883840441704,
        0.04905557632446289,
        0.0028835691045969725,
        0.05109184980392456,
        0.017792079597711563,
        0.0018101843306794763,
        0.05115548148751259,
        0.05447592958807945,
        -0.05436813831329346,
        0.05245542153716087,
        -0.024392899125814438,
        -0.05095387622714043,
        -0.009754037484526634,
        0.05411875247955322,
        0.010825304314494133,
        -0.05184626206755638,
        0.04225749894976616,
        0.04900270700454712,
        -0.05510321259498596,
        0.00022696312225889415,
        0.032247040420770645,
        0.055049918591976166,
        0.025923848152160645,
        -0.05346664413809776,
        -0.053377628326416016,
        0.0510956272482872,
        -0.05509686470031738,
        0.03761983662843704,
        -0.03352244198322296,
        -0.03493943810462952,
        -0.01724240556359291,
        -0.025004124268889427,
        0.05147100239992142,
        0.0008492989582009614,
        -0.030015459284186363,
        -0.04377872496843338,
        0.019058767706155777,
        -0.05179060623049736,
        -0.0536675900220871,
        -0.02491539716720581,
        0.00620160112157464,
        -0.05457933619618416,
        -0.054651279002428055,
        0.05176200345158577,
        -0.052476994693279266,
        0.051943279802799225,
        0.03993857279419899,
        0.04560781270265579,
        0.0500435009598732,
        -0.05076889321208,
        -0.05431549623608589,
        0.0536046028137207,
        -0.04964112117886543,
        -0.05023369938135147,
        0.05505598708987236,
        -0.05496649816632271,
        -0.008220206946134567,
        0.054248567670583725,
        0.05165041238069534,
        0.04988773539662361,
        0.039456967264413834,
        0.03909819945693016,
        -0.01970137469470501,
        0.0371764674782753,
        -0.028491131961345673,
        -0.05503898486495018,
        0.03394389525055885,
        0.05464642122387886,
        -0.04308503493666649,
        -0.054879702627658844,
        -0.05495571345090866,
        0.02975151129066944,
        0.05511864274740219,
        -0.05235226824879646,
        0.004963254556059837,
        -0.04726346954703331,
        -0.03993435204029083,
        0.012328433804214,
        -0.039315689355134964,
        0.05193253234028816,
        0.053784724324941635,
        0.015752866864204407,
        0.03901255130767822,
        -0.03286299109458923,
        0.0550563670694828,
        0.035072922706604004,
        -0.019423604011535645,
        0.02912808023393154,
        0.05054359883069992,
        0.03680701553821564,
        -0.05510822311043739,
        0.05512888729572296,
        -0.054657310247421265,
        -0.004014668986201286,
        -0.05492958799004555,
        -0.02845819666981697,
        0.05494152009487152,
        -0.05286340415477753,
        -0.05365805700421333,
        0.03211379423737526,
        0.0509108230471611,
        -0.041393741965293884,
        -0.05272701755166054,
        -0.051614388823509216,
        0.05492011085152626,
        0.05233875289559364,
        0.055133454501628876,
        -0.03977226838469505,
        -0.054474905133247375,
        -0.02053099498152733,
        0.03598194196820259,
        0.05498315766453743,
        0.049007613211870193,
        0.018891392275691032,
        0.05365324392914772,
        0.05378655716776848,
        -0.051612578332424164,
        0.039902765303850174,
        0.017183424904942513,
        0.04984019324183464,
        0.04307994619011879,
        0.016747308894991875,
        0.0544334352016449,
        -0.054116521030664444,
        0.049926988780498505,
        0.02539222687482834,
        -0.04444637894630432,
        0.0549185536801815,
        -0.05387360602617264,
        0.034378182142972946,
        -0.02386147528886795,
        0.05414164066314697,
        -0.05309927836060524,
        -0.05301558971405029,
        0.0543459989130497,
        0.046179842203855515,
        0.05513741075992584,
        -0.02795143984258175,
        0.04027845710515976,
        0.05165008828043938,
        0.041316594928503036,
        0.025676293298602104,
        0.05510779097676277,
        -0.05476251244544983,
        0.05129959434270859,
        0.005846537183970213,
        -0.0030735170003026724,
        -0.054574284702539444,
        -0.04562314972281456,
        -0.05349303036928177,
        0.023581070825457573,
        0.05298548936843872,
        -0.042895443737506866,
        0.05510930344462395,
        0.05465124920010567,
        0.04640344902873039,
        0.032527171075344086,
        0.054926421493291855,
        -0.038160309195518494,
        0.020271440967917442,
        0.03539332374930382,
        -0.04647190123796463,
        0.050748296082019806,
        -0.04266807436943054,
        0.02003960683941841,
        0.005032712128013372,
        0.00930336955934763,
        -0.03728070855140686,
        -0.052516184747219086,
        -0.05410652235150337,
        0.05356388911604881,
        -0.05383617803454399,
        0.05505850911140442,
        0.05411829054355621,
        -0.012804809026420116,
        -0.05345933511853218,
        0.05331463739275932,
        0.042995940893888474,
        -0.05313880369067192,
        -0.01563914865255356,
        0.01208973117172718,
        0.04938461631536484,
        -0.05510155111551285,
        0.05513753741979599,
        -0.04921392351388931,
        -0.016475919634103775,
        0.03552760183811188,
        -0.009910067543387413,
        0.03179937228560448,
        -0.05501353740692139,
        0.05510818585753441,
        -0.04574061930179596,
        -0.052554212510585785,
        0.05396641418337822,
        -0.05323649197816849,
        0.0021401913836598396,
        -0.054855089634656906,
        0.012476355768740177,
        -0.05016127973794937,
        0.041659772396087646,
        -0.05059149116277695,
        0.0009125967626459897,
        0.054920513182878494,
        0.048622146248817444,
        -0.044982507824897766,
        -0.02987627685070038,
        -0.049439217895269394,
        0.04438606649637222,
        -0.053253233432769775,
        0.015699030831456184,
        -0.05467034876346588,
        -0.04765505716204643,
        0.03250187262892723,
        -0.052983131259679794,
        0.05478404462337494,
        0.03486122190952301,
        -0.05201563984155655,
        0.0549921914935112,
        0.03163068741559982,
        -0.0030870314221829176,
        0.01236262172460556,
        0.05171678215265274,
        -0.015308871865272522,
        0.037170976400375366,
        0.005230525974184275,
        0.053351256996393204,
        0.04991062730550766,
        -0.03566013276576996,
        0.05348378047347069,
        0.047092366963624954,
        0.05454061180353165,
        0.05476733297109604,
        0.0390281155705452,
        -0.05301602557301521,
        -0.04171636700630188,
        -0.007966600358486176,
        0.05134734511375427,
        -0.04436257854104042,
        0.038195911794900894,
        0.053367991000413895,
        0.054716695100069046,
        0.011749068275094032,
        0.05357443541288376,
        -0.033170491456985474,
        0.04693622887134552,
        -0.0544537715613842,
        0.04981985315680504,
        -0.055098991841077805,
        0.053567320108413696,
        -0.05175897851586342,
        -0.007623027078807354,
        -0.03629086911678314,
        0.0535910464823246,
        -0.04959944263100624,
        -0.03278856351971626,
        0.05131401866674423,
        -0.004885807633399963,
        0.038750000298023224,
        -0.012357350438833237,
        -0.01193656213581562,
        0.04274412989616394,
        -0.047361310571432114,
        0.05513951554894447,
        0.03812403604388237,
        0.03717222064733505,
        0.05435069277882576,
        -0.025307321920990944,
        0.05469145625829697,
        -0.03525164723396301,
        0.05402020364999771,
        -0.007117701694369316,
        -0.05510404706001282,
        -0.05048874393105507,
        0.0035004201345145702,
        -0.055128250271081924,
        -0.05337073653936386,
        -0.04611743241548538,
        -0.054716285318136215,
        0.05077488347887993,
        -0.03598840907216072,
        0.02089783363044262,
        -0.05027636140584946,
        -0.02243831939995289,
        -0.0550154484808445,
        0.053391098976135254,
        -0.05172605440020561,
        -0.022380726411938667,
        -0.054963599890470505,
        -0.013209250755608082,
        -0.03631770238280296,
        0.053606800734996796,
        0.023262351751327515,
        0.050797924399375916,
        0.054559677839279175,
        -0.047157809138298035,
        0.03007146529853344,
        0.05499209836125374,
        -0.045271847397089005,
        -0.05246872827410698,
        -0.03241858258843422,
        -0.05164540559053421,
        0.016490131616592407,
        0.034972645342350006,
        -0.05488701909780502,
        -0.049252502620220184,
        -0.03555167093873024,
        -0.05412698909640312,
        -0.02316604182124138,
        -0.04950802028179169,
        0.049185577780008316,
        -0.047915857285261154,
        -0.05479784682393074,
        0.04512307792901993,
        0.05275328829884529,
        0.05461137369275093,
        -0.0016725686145946383,
        -0.05513448268175125,
        -0.055071040987968445,
        -0.026330316439270973,
        0.0544460266828537,
        -0.029148556292057037,
        0.05513240769505501,
        -0.05034397542476654,
        -0.02260035090148449,
        0.010776468552649021,
        -0.05331549048423767,
        0.055083610117435455,
        0.0056047323159873486,
        0.051538530737161636,
        -0.04234742373228073,
        0.010144863277673721,
        0.047415923327207565,
        0.045676205307245255,
        -0.04365706071257591,
        -0.052110664546489716,
        0.012411019764840603,
        0.05113080516457558,
        -0.0061548673547804356,
        0.054972294718027115,
        -0.05117451399564743,
        -0.0549539215862751,
        -0.05511196702718735,
        -0.029381971806287766,
        -0.05442233756184578,
        -0.053488221019506454,
        -0.04808015003800392,
        -0.053012363612651825,
        -0.05303295701742172,
        0.051939066499471664,
        -0.04478326439857483,
        -0.03739124536514282,
        -0.01193638239055872,
        -0.05449659749865532,
        -0.013303191401064396,
        -0.05512886866927147,
        0.026178250089287758,
        -0.007196240592747927,
        -0.031420253217220306,
        0.039627909660339355,
        0.05318062752485275,
        0.050614167004823685,
        0.054226066917181015,
        0.0534328855574131,
        -0.018562668934464455,
        0.05147159844636917,
        -0.055129699409008026,
        0.00998676847666502,
        0.05420593544840813,
        0.03717612475156784,
        0.05454111844301224,
        0.03468652814626694,
        -0.0034940626937896013,
        -0.04700396955013275,
        -0.05181632936000824,
        -0.044623587280511856,
        -0.05367555096745491,
        -0.010936305858194828,
        -0.012791500426828861,
        -0.036180876195430756,
        -0.010782334953546524,
        -0.05490632355213165,
        -0.050409283488988876,
        0.04862147569656372,
        0.055085185915231705,
        -0.05494959279894829,
        0.054440937936306,
        -0.05283091962337494,
        -0.054693084210157394,
        0.05426899716258049,
        0.053818922489881516,
        0.04957626014947891,
        -0.05410133674740791,
        0.05229780822992325,
        0.04089803993701935,
        -0.015524625778198242,
        0.055015187710523605,
        -0.05507690832018852,
        -0.04546421766281128,
        -0.054708074778318405,
        0.012863758020102978,
        -0.04430067911744118,
        0.041292954236269,
        -0.010434571653604507,
        0.05288868770003319,
        0.054850272834300995,
        -0.03856385871767998,
        -0.053999751806259155,
        0.03333413973450661,
        -0.029015514999628067,
        -0.054975565522909164,
        0.03574082627892494,
        -0.03730360418558121,
        -0.053937945514917374,
        -0.05325404182076454,
        0.02900065667927265,
        0.04387497901916504,
        0.05187761038541794,
        -0.03003893792629242,
        0.04973277449607849,
        0.040630605071783066,
        -0.032595712691545486,
        -0.04668668657541275,
        0.0026807028334587812,
        0.05375814810395241,
        0.0319022573530674,
        -0.013553113676607609,
        0.05309724807739258,
        -0.03092038817703724,
        0.05499007925391197,
        -0.03649498149752617
    ],
    [
        -0.008026489056646824,
        0.05356573686003685,
        -0.018782276660203934,
        -0.018910612910985947,
        -0.039513055235147476,
        -0.042982492595911026,
        0.040798455476760864,
        -0.05391159653663635,
        0.053962308913469315,
        -0.05270834267139435,
        0.0520947203040123,
        0.010552232153713703,
        -0.053480058908462524,
        -0.04651100933551788,
        -0.05276181548833847,
        0.04558568075299263,
        0.03485618531703949,
        0.052630629390478134,
        0.004905573558062315,
        -0.015645846724510193,
        0.0504368394613266,
        0.05352175608277321,
        0.0022869494277983904,
        0.051642607897520065,
        -0.05313098803162575,
        0.05386867746710777,
        0.03140513598918915,
        0.0540214367210865,
        -0.04126899689435959,
        -0.025387389585375786,
        0.05388128384947777,
        -0.0533752404153347,
        -0.013448956422507763,
        -0.05406082794070244,
        0.0538746677339077,
        -0.05115504935383797,
        -0.05267665535211563,
        0.012018668465316296,
        0.03698507696390152,
        0.04658832773566246,
        0.04953047260642052,
        0.046314485371112823,
        0.05161791667342186,
        0.0452442429959774,
        0.05172596126794815,
        -0.024840708822011948,
        0.05072992295026779,
        0.0540616400539875,
        0.05281632021069527,
        -0.051351241767406464,
        0.053360939025878906,
        -0.04109751433134079,
        0.04518834501504898,
        -0.03835977613925934,
        0.05405961349606514,
        -0.04213768616318703,
        0.05204936861991882,
        -0.025487486273050308,
        -0.05294155329465866,
        -0.05045780539512634,
        -0.04990505799651146,
        -0.0390857458114624,
        -0.05376428738236427,
        -0.04197418689727783,
        -0.03918437287211418,
        -0.04282012954354286,
        -0.05391684174537659,
        -0.05361276492476463,
        0.03394276648759842,
        -0.05311966314911842,
        0.032315317541360855,
        -0.04440220817923546,
        0.05365776643157005,
        0.05126326158642769,
        -0.05346032232046127,
        -0.051832910627126694,
        -0.038303013890981674,
        0.054063089191913605,
        0.02673797309398651,
        0.04476766288280487,
        -0.0506020188331604,
        -0.04936910420656204,
        -0.053993239998817444,
        0.050591371953487396,
        -0.020506851375102997,
        0.05139809101819992,
        -0.05339732766151428,
        -0.05231161415576935,
        -0.01323830708861351,
        0.05364393815398216,
        -0.05026724934577942,
        -0.04732581973075867,
        -0.017092542722821236,
        -0.05247565731406212,
        -0.029760198667645454,
        0.04940516874194145,
        -0.048450570553541183,
        -0.053483933210372925,
        -0.051361244171857834,
        0.04904715344309807,
        -0.053973741829395294,
        0.03774501010775566,
        0.049027539789676666,
        0.05392248183488846,
        -0.053135886788368225,
        -0.030509265139698982,
        -0.05358260124921799,
        -0.05213530361652374,
        0.04499885439872742,
        0.050380926579236984,
        0.04888395592570305,
        -0.053844206035137177,
        0.042875826358795166,
        0.04729972407221794,
        0.010848508216440678,
        0.03386189416050911,
        0.004707625601440668,
        -0.05315965414047241,
        -0.05223498120903969,
        0.03969526290893555,
        0.04601358622312546,
        -0.02028084360063076,
        -0.02169107459485531,
        -0.053685255348682404,
        -0.045274801552295685,
        0.04715661704540253,
        -0.053859420120716095,
        0.04308263212442398,
        0.05396389216184616,
        0.05262371152639389,
        -0.05053884908556938,
        -0.05192027986049652,
        -0.0472964271903038,
        0.053816188126802444,
        -0.04549456760287285,
        -0.053863201290369034,
        0.053443968296051025,
        -0.05373118445277214,
        -0.04406961798667908,
        0.0025720042176544666,
        -0.010589662939310074,
        -0.037191856652498245,
        0.04990410432219505,
        0.002993282163515687,
        0.04546233266592026,
        0.053571946918964386,
        -0.045926954597234726,
        -0.02346060238778591,
        0.03723328188061714,
        0.05007556453347206,
        0.04701128974556923,
        -0.05315713956952095,
        -0.049708668142557144,
        -0.05381178483366966,
        0.037057992070913315,
        0.010387047193944454,
        0.050652049481868744,
        0.05286858230829239,
        -0.03812871128320694,
        -0.013750911690294743,
        -0.02795647270977497,
        -0.00382019579410553,
        0.05372646078467369,
        0.0028635808266699314,
        0.05378800630569458,
        -0.0169298704713583,
        0.042822182178497314,
        0.013880027458071709,
        -0.025024747475981712,
        0.04359488934278488,
        -0.05339936539530754,
        0.008687327615916729,
        -0.0540153793990612,
        -0.03326066583395004,
        0.004515867680311203,
        0.05178724229335785,
        0.042571429163217545,
        -0.05066728964447975,
        0.05244924873113632,
        -0.05241779983043671,
        -0.05375289171934128,
        0.05384029075503349,
        0.048294275999069214,
        -0.04731118679046631,
        0.05315050482749939,
        0.02702145092189312,
        0.041568633168935776,
        0.052413009107112885,
        0.033861011266708374,
        0.031195927411317825,
        -0.05320005491375923,
        0.023352010175585747,
        -0.023873431608080864,
        0.041360244154930115,
        -0.04757063463330269,
        -0.04346359521150589,
        -0.04236200824379921,
        0.04507026821374893,
        -0.053744617849588394,
        0.05379103496670723,
        0.019507376477122307,
        0.05400678142905235,
        -0.00472758524119854,
        -0.05288713425397873,
        0.053969476372003555,
        -0.044881682842969894,
        0.04827180504798889,
        -0.03246462345123291,
        -0.053918588906526566,
        0.05387707054615021,
        -0.0512869767844677,
        0.03006558306515217,
        0.01750820130109787,
        0.030051620677113533,
        0.04725056514143944,
        -0.05401947721838951,
        0.04889388009905815,
        -0.051916155964136124,
        0.04853818565607071,
        -0.05301773175597191,
        0.05216177552938461,
        -0.02936548739671707,
        -0.03178633749485016,
        0.02906954474747181,
        0.05334503948688507,
        -0.04510403424501419,
        0.04738222062587738,
        -0.011017614044249058,
        -0.03211365267634392,
        -0.04970654472708702,
        -0.03892482817173004,
        -0.052636414766311646,
        0.01423692237585783,
        0.053785569965839386,
        0.04630310833454132,
        0.050451625138521194,
        0.05291608348488808,
        -0.03754713758826256,
        -0.05325840413570404,
        0.05405448004603386,
        0.052450958639383316,
        0.05394411087036133,
        -0.033788636326789856,
        -0.05403439328074455,
        0.05373087897896767,
        0.026138674467802048,
        0.04963596165180206,
        -0.017934568226337433,
        -0.04672985523939133,
        -0.05395907536149025,
        0.0018727892311289907,
        -0.05316668376326561,
        0.05403832346200943,
        -0.04799778386950493,
        0.02434033341705799,
        0.04970071092247963,
        0.026422780007123947,
        -0.05264340713620186,
        0.02462632954120636,
        0.05032370612025261,
        -0.013225643895566463,
        0.012029621750116348,
        -0.01638198085129261,
        0.04921663552522659,
        -0.05374288558959961,
        0.0538066141307354,
        0.054050859063863754,
        0.05319451168179512,
        0.036113180220127106,
        -0.010147636756300926,
        0.05135032534599304,
        -0.04955882951617241,
        0.053848303854465485,
        -0.05342472344636917,
        0.05405008792877197,
        0.04677814990282059,
        -0.051341649144887924,
        -0.05162700265645981,
        0.053481537848711014,
        0.011200889945030212,
        -0.053522076457738876,
        0.00013670747284777462,
        0.005245856940746307,
        -0.04836752638220787,
        0.015803851187229156,
        0.02975398488342762,
        -0.018530873581767082,
        0.03529638424515724,
        0.03213970363140106,
        0.04711056873202324,
        -0.05405602976679802,
        -0.03676629066467285,
        -0.05305188521742821,
        -0.030175525695085526,
        0.035209331661462784,
        -0.05315561592578888,
        0.054042454808950424,
        0.05390194430947304,
        0.014431060291826725,
        0.052905529737472534,
        0.05402670055627823,
        0.04103998467326164,
        -0.04258769378066063,
        0.05215786397457123,
        0.0497577041387558,
        -0.0011944372672587633,
        -0.04689226672053337,
        0.05335702374577522,
        0.05051848292350769,
        -0.00868048332631588,
        0.03359844535589218,
        -0.05157601088285446,
        0.050302326679229736,
        0.04664077237248421,
        -0.017399007454514503,
        -0.053209338337183,
        0.01005401462316513,
        0.054063521325588226,
        0.053521476686000824,
        0.01439567469060421,
        -0.03498665615916252,
        0.052372854202985764,
        0.05303547903895378,
        -0.0173325352370739,
        0.054023224860429764,
        -0.019498169422149658,
        0.05169045552611351,
        0.03239893168210983,
        0.03172017261385918,
        -0.047795068472623825,
        -0.04475021734833717,
        -0.053322214633226395,
        0.04937949404120445,
        0.05323696509003639,
        0.053812261670827866,
        0.052699536085128784,
        -0.049741778522729874,
        0.03946487605571747,
        0.052250780165195465,
        0.04362574219703674,
        -0.010375063866376877,
        -0.053855523467063904,
        0.05289975926280022,
        -0.05390969291329384,
        0.05364690721035004,
        -0.012817809358239174,
        0.05157724767923355,
        0.05401507019996643,
        -0.02919003739953041,
        0.051824625581502914,
        0.052976202219724655,
        0.0539521723985672,
        0.012773403897881508,
        -0.05395949259400368,
        -0.0036619561724364758,
        -0.047161780297756195,
        -0.05405418574810028,
        -0.05305683985352516,
        -0.05386574938893318,
        -0.03970957174897194,
        0.051902491599321365,
        0.05357582867145538,
        -0.020475825294852257,
        -0.05374166741967201,
        -0.032627031207084656,
        -0.052584435790777206,
        0.0539742112159729,
        0.04949728772044182,
        0.044698216021060944,
        -0.05393033102154732,
        -0.042578380554914474,
        -0.03399030864238739,
        0.016030093654990196,
        -0.051856137812137604,
        0.05288445204496384,
        0.054047536104917526,
        -0.026705767959356308,
        0.0364336296916008,
        0.05343016982078552,
        0.04335644841194153,
        0.04497514292597771,
        0.04722282290458679,
        -0.04795277491211891,
        -0.026288822293281555,
        -0.007059226278215647,
        -0.05400823429226875,
        -0.03927232697606087,
        -0.053174152970314026,
        -0.053386155515909195,
        -0.053917113691568375,
        -0.05403854325413704,
        0.007675250060856342,
        -0.008786450140178204,
        -0.04375838860869408,
        0.03477087616920471,
        0.05352957919239998,
        0.05393920838832855,
        -0.0509665347635746,
        -0.04005091264843941,
        -0.048121221363544464,
        -0.019910557195544243,
        0.05363738164305687,
        -0.053784310817718506,
        0.04433285817503929,
        0.048994146287441254,
        0.053976431488990784,
        0.04988858476281166,
        -0.0018794081406667829,
        0.0540359802544117,
        -0.028714273124933243,
        -0.05143340304493904,
        0.007839558646082878,
        0.04514404013752937,
        0.044414933770895004,
        0.030509984120726585,
        -0.04587583616375923,
        -0.05056360363960266,
        -0.013430084101855755,
        0.053399454802274704,
        0.010016377083957195,
        0.054039355367422104,
        -0.03164931386709213,
        -0.0536477155983448,
        -0.0540524460375309,
        -0.03808945417404175,
        -0.05392347276210785,
        0.03308805078268051,
        0.04233422875404358,
        -0.03701010346412659,
        -0.05354601889848709,
        0.00539932819083333,
        -0.05143548175692558,
        -0.042444560676813126,
        -0.043325040489435196,
        -0.04615064337849617,
        -0.052753716707229614,
        -0.008314590901136398,
        -0.011580130085349083,
        -0.02934280037879944,
        -0.04579275846481323,
        0.029691418632864952,
        0.015396593138575554,
        0.053451716899871826,
        0.04847518727183342,
        -0.04086996987462044,
        0.05342226102948189,
        0.053608037531375885,
        -0.010443491861224174,
        0.04911281168460846,
        0.052866533398628235,
        0.047254957258701324,
        0.0536809116601944,
        0.044800885021686554,
        -0.051159750670194626,
        0.053730811923742294,
        -0.05100388452410698,
        -0.04609816148877144,
        -0.04474271461367607,
        0.045629389584064484,
        0.05387793481349945,
        0.05342580005526543,
        -0.032243046909570694,
        -0.051984753459692,
        -0.03575592488050461,
        0.05387840420007706,
        0.053240757435560226,
        -0.05333986505866051,
        0.053406722843647,
        -0.05348522216081619,
        -0.048146020621061325,
        0.030165450647473335,
        -0.04056156426668167,
        0.05303569138050079,
        0.042356885969638824,
        -0.02861514687538147,
        0.0538700707256794,
        -0.051169417798519135,
        0.0515558198094368,
        -0.0490226075053215,
        -0.05373590439558029,
        0.012417991645634174,
        -0.03842335194349289,
        -0.0067468988709151745,
        -0.006558259949088097,
        -0.035731542855501175,
        0.04989525303244591,
        0.05386168509721756,
        0.0453106053173542,
        -0.04776814579963684,
        0.014841913245618343,
        0.031548939645290375,
        -0.0540597140789032,
        0.053044937551021576,
        0.04036290571093559,
        -0.03137954697012901,
        -0.0023703030310571194,
        0.04806225001811981,
        -0.03618054836988449,
        0.04676540568470955,
        0.019472284242510796,
        -0.0517057441174984,
        0.05345989018678665,
        -0.05184628441929817,
        -0.05385623872280121,
        0.011294632218778133,
        0.015019262209534645,
        0.05406181141734123,
        -0.03703007847070694,
        0.05383338779211044,
        -0.04880043864250183,
        0.015609968453645706,
        -0.04765411093831062
    ],
    [
        0.043920621275901794,
        -0.04982922971248627,
        -0.04671699181199074,
        0.03410482406616211,
        -0.0017220568843185902,
        -0.044892217963933945,
        0.053550176322460175,
        -0.043885767459869385,
        0.052758101373910904,
        -0.05242304131388664,
        0.04353358969092369,
        0.014735243283212185,
        -0.053134333342313766,
        0.02950938604772091,
        -0.03461877629160881,
        -0.03895998373627663,
        0.04665353521704674,
        0.04368679225444794,
        -0.048778362572193146,
        0.02804531529545784,
        -0.05343383550643921,
        -0.04893721640110016,
        0.01606833003461361,
        0.012417931109666824,
        -0.054598502814769745,
        0.052809033542871475,
        0.04225417971611023,
        0.054301828145980835,
        -0.04868925362825394,
        -0.009281455539166927,
        0.032037824392318726,
        -0.04640546813607216,
        0.05279924347996712,
        -0.054274022579193115,
        0.04310131445527077,
        -0.054666996002197266,
        -0.049746397882699966,
        0.05222535505890846,
        -0.031996335834264755,
        0.04294641688466072,
        0.006625214591622353,
        0.03375368192791939,
        0.04204899072647095,
        -0.04615722596645355,
        -0.014347044751048088,
        -0.04579590633511543,
        0.0543811172246933,
        0.054606396704912186,
        0.05425773561000824,
        -0.03319063410162926,
        0.054292742162942886,
        0.05279318988323212,
        0.034744855016469955,
        -0.03991097956895828,
        0.048629265278577805,
        -0.0397353395819664,
        0.04644809290766716,
        -0.05276406556367874,
        0.030162625014781952,
        -0.053547732532024384,
        -0.05019771680235863,
        0.043068453669548035,
        -0.05243610963225365,
        -0.012167898938059807,
        0.010820823721587658,
        -0.00922766700387001,
        -0.05467115342617035,
        -0.051564738154411316,
        0.05439581722021103,
        -0.05128473415970802,
        -0.04495950788259506,
        -0.04911930859088898,
        0.054575350135564804,
        0.04104647785425186,
        -0.054539017379283905,
        -0.029964661225676537,
        -0.05189162492752075,
        0.05466878414154053,
        0.05209808424115181,
        -0.008382351137697697,
        0.009336660616099834,
        -0.0517427995800972,
        -0.049705177545547485,
        0.042088963091373444,
        0.019488442689180374,
        0.0059379576705396175,
        -0.05378955230116844,
        -0.043049078434705734,
        0.002804707968607545,
        0.0447850227355957,
        -0.04538900777697563,
        0.03037453256547451,
        -0.02969895303249359,
        -0.05461788922548294,
        -0.05298333987593651,
        0.054588161408901215,
        -0.034467168152332306,
        -0.04836214333772659,
        -0.052846912294626236,
        0.02885913848876953,
        -0.05404491722583771,
        0.05452950671315193,
        0.003928529564291239,
        -0.004867704585194588,
        0.02180689200758934,
        -0.05452375113964081,
        -0.05456342548131943,
        0.05387239530682564,
        0.023009615018963814,
        0.05444789305329323,
        0.04941738396883011,
        -0.051951274275779724,
        0.028599318116903305,
        0.037411656230688095,
        0.038454681634902954,
        -0.023235637694597244,
        0.044295404106378555,
        -0.001283125253394246,
        -0.04997226968407631,
        -0.03833424299955368,
        0.05305055156350136,
        -0.04990893974900246,
        0.027504056692123413,
        -0.05421316623687744,
        -0.05168662965297699,
        0.05384191870689392,
        -0.02689860388636589,
        0.05376766622066498,
        0.02163323201239109,
        0.03786461055278778,
        -0.03739091381430626,
        -0.051928743720054626,
        -0.05415993928909302,
        0.054628897458314896,
        -0.00261665228754282,
        -0.04991833493113518,
        0.044913433492183685,
        -0.05349653586745262,
        -0.05241537466645241,
        0.05178682878613472,
        0.054404012858867645,
        0.022135229781270027,
        0.042510051280260086,
        0.05458255484700203,
        0.032263122498989105,
        -0.05375118553638458,
        -0.054123394191265106,
        0.05279214307665825,
        -0.054671142250299454,
        0.04770238324999809,
        -0.03769640251994133,
        -0.054652947932481766,
        -0.05417139455676079,
        0.05439743399620056,
        -0.032810430973768234,
        -0.006901951506733894,
        -0.01705850288271904,
        0.02181520126760006,
        0.052933644503355026,
        -0.014949370175600052,
        -0.016985537484288216,
        0.05232834815979004,
        0.049782197922468185,
        -0.005272333975881338,
        0.05439121276140213,
        0.051702577620744705,
        0.05264999717473984,
        -0.049307167530059814,
        0.03856551647186279,
        0.044920407235622406,
        -0.03704699128866196,
        -0.047113485634326935,
        -0.054583631455898285,
        0.022652029991149902,
        0.03392853960394859,
        0.0376746691763401,
        0.007981877774000168,
        -0.02491983398795128,
        0.054325781762599945,
        0.011421575210988522,
        -0.0022958924528211355,
        0.05231945961713791,
        0.03725940361618996,
        -0.04658341407775879,
        0.05460101738572121,
        -0.019558502361178398,
        0.04676473140716553,
        0.05186222493648529,
        0.03380224108695984,
        -0.036910392343997955,
        -0.05466071516275406,
        0.05205798149108887,
        0.05037220939993858,
        0.05463302880525589,
        -0.0013627043226733804,
        -0.04544197395443916,
        -0.0015574211720377207,
        0.040865663439035416,
        -0.03793110325932503,
        0.04712878167629242,
        -0.018193982541561127,
        0.053216855973005295,
        -0.044407740235328674,
        -0.0543164387345314,
        0.052896156907081604,
        -0.050179749727249146,
        0.026785729452967644,
        -0.05380723997950554,
        -0.05361900478601456,
        0.05274020507931709,
        -0.05432877317070961,
        -0.02672625705599785,
        0.043581873178482056,
        0.05143212899565697,
        0.05462874472141266,
        -0.043012987822294235,
        0.053254395723342896,
        0.04975496605038643,
        0.05451658368110657,
        -0.03540937602519989,
        0.03834065422415733,
        0.05463455244898796,
        -0.05436535179615021,
        -0.05435426905751228,
        0.054651763290166855,
        -0.05429716035723686,
        0.01143796555697918,
        -0.031144823879003525,
        0.05259253829717636,
        -0.04640934616327286,
        -0.04587183892726898,
        -0.053489960730075836,
        0.03228612616658211,
        0.04572231322526932,
        0.05030486360192299,
        0.054643500596284866,
        0.04831373319029808,
        0.054570626467466354,
        0.00064432731596753,
        0.05456326901912689,
        0.036650337278842926,
        -0.024935999885201454,
        0.009398522786796093,
        -0.05414065718650818,
        -0.0007467595860362053,
        -0.05385461077094078,
        0.05300384759902954,
        -0.025269890204072,
        -0.0352570004761219,
        -0.051734525710344315,
        0.01900510862469673,
        -0.05093260109424591,
        0.0546511746942997,
        -0.05213145166635513,
        0.054520633071660995,
        0.0545404776930809,
        0.053885988891124725,
        -0.047328103333711624,
        0.052481312304735184,
        0.009977076202630997,
        0.046019863337278366,
        0.03407793864607811,
        -0.05312138795852661,
        0.05355614051222801,
        -0.05449805408716202,
        0.05463271588087082,
        0.05451163649559021,
        0.054668959230184555,
        -0.013944467529654503,
        0.05451476201415062,
        0.003920879680663347,
        -0.05039661377668381,
        0.05405239388346672,
        -0.05244606360793114,
        0.05390465632081032,
        0.053516317158937454,
        -0.04800840839743614,
        -0.054510194808244705,
        -0.053241439163684845,
        0.012630910612642765,
        -0.04017118364572525,
        0.05177545174956322,
        -0.00325808790512383,
        -0.03504660725593567,
        0.05397976189851761,
        -0.051960695534944534,
        -0.02001184970140457,
        -0.018900727853178978,
        -0.020843898877501488,
        0.05114402249455452,
        -0.05459638684988022,
        0.03753161057829857,
        0.0030378408264368773,
        -0.05224892497062683,
        0.05150856450200081,
        -0.04521713778376579,
        0.05458645522594452,
        -0.016631679609417915,
        0.04428098723292351,
        0.052630044519901276,
        0.04562239721417427,
        0.05114360898733139,
        -0.05172886326909065,
        0.018980447202920914,
        -0.045787736773490906,
        0.046985987573862076,
        0.011218615807592869,
        0.05441969260573387,
        -0.005115378648042679,
        0.029549939557909966,
        0.037547219544649124,
        -0.028272097930312157,
        0.04494704678654671,
        -0.031781092286109924,
        -0.009580935351550579,
        -0.054017212241888046,
        -0.020950401201844215,
        0.046161260455846786,
        0.052663471549749374,
        -0.044439785182476044,
        0.005666544660925865,
        0.05349946767091751,
        0.05401008203625679,
        -0.04178062826395035,
        0.044818777590990067,
        0.05455701798200607,
        -0.052424684166908264,
        0.019280070438981056,
        -0.021698985248804092,
        -0.05309179797768593,
        0.054104868322610855,
        -0.02952440455555916,
        0.054324764758348465,
        0.05466955527663231,
        0.05313216149806976,
        0.054046835750341415,
        -0.053513187915086746,
        -0.00714496336877346,
        0.03798386827111244,
        -0.050757721066474915,
        -0.04791842773556709,
        -0.05430047586560249,
        0.05466790869832039,
        0.033473722636699677,
        0.0545060969889164,
        0.050236884504556656,
        0.05208482965826988,
        0.05304037779569626,
        -0.05438312143087387,
        0.05453721433877945,
        0.03028208762407303,
        0.054668571799993515,
        0.05277795344591141,
        -0.05148947238922119,
        -0.05419700965285301,
        -0.03007921203970909,
        -0.05467135086655617,
        -0.054116830229759216,
        -0.05270586907863617,
        -0.026644771918654442,
        0.049852676689624786,
        0.05444774404168129,
        0.028347846120595932,
        -0.05311038717627525,
        -0.04747163504362106,
        -0.05455762520432472,
        0.05457907170057297,
        -0.030551837757229805,
        -0.009160363115370274,
        -0.05428679659962654,
        -0.025367308408021927,
        0.0388946607708931,
        -0.04660351946949959,
        -0.02297857590019703,
        0.029144134372472763,
        0.05446052923798561,
        -0.00940349418669939,
        -0.010963082313537598,
        0.0515955425798893,
        -0.015417307615280151,
        -0.036847297102212906,
        0.011400232091546059,
        -0.043618343770504,
        -0.0309907179325819,
        0.046504393219947815,
        -0.05437063053250313,
        -0.039935946464538574,
        -0.035579048097133636,
        -0.03580760210752487,
        -0.051239874213933945,
        -0.053483523428440094,
        -0.04922425001859665,
        -0.00457195658236742,
        -0.05037601292133331,
        0.051430702209472656,
        0.052487537264823914,
        -0.051604900509119034,
        -0.05079437792301178,
        -0.05467122793197632,
        -0.03448866680264473,
        -0.021969780325889587,
        0.05162171274423599,
        0.018955277279019356,
        0.02904799021780491,
        -0.05110981687903404,
        0.011968514882028103,
        0.045671481639146805,
        -0.04648091644048691,
        0.05032701417803764,
        0.0533459447324276,
        -0.015208235941827297,
        -0.049046389758586884,
        -0.05280129984021187,
        0.052644792944192886,
        0.0545056015253067,
        -0.05321325361728668,
        -0.05362318083643913,
        -0.04349305108189583,
        0.054604895412921906,
        0.05160970240831375,
        0.054372478276491165,
        -0.04349743202328682,
        -0.05449821054935455,
        -0.054593030363321304,
        -0.0037815652322024107,
        0.0042003365233540535,
        -0.008929836563766003,
        -0.019407445564866066,
        -0.02605578489601612,
        -0.05366956442594528,
        -0.049887608736753464,
        -0.050753772258758545,
        0.03837680444121361,
        -0.04879986494779587,
        -0.05183149501681328,
        -0.051270630210638046,
        0.04297668859362602,
        0.05466816946864128,
        -0.047734055668115616,
        -0.054587606340646744,
        -0.0078066387213766575,
        0.05428704991936684,
        0.0332418754696846,
        0.05377793684601784,
        -0.017081649973988533,
        -0.013631857000291348,
        0.053235750645399094,
        -0.054509975016117096,
        0.05327850207686424,
        0.05465378984808922,
        0.054656218737363815,
        0.05432414263486862,
        0.04218664765357971,
        -0.04076911881566048,
        -0.0277204979211092,
        -0.036378517746925354,
        -0.007183046080172062,
        -0.046262986958026886,
        -0.04807113856077194,
        0.0230343509465456,
        0.05462382733821869,
        -0.037233948707580566,
        -0.05294140428304672,
        -0.03135058283805847,
        0.05393387749791145,
        0.0544053390622139,
        -0.052910685539245605,
        0.054630097001791,
        0.049450963735580444,
        -0.0529765710234642,
        0.0498402863740921,
        -0.050446804612874985,
        0.05408044531941414,
        -0.03756919130682945,
        -0.005037578754127026,
        0.04870860278606415,
        -0.0368504673242569,
        0.030293326824903488,
        -0.05462203174829483,
        0.018498023971915245,
        -0.04731197655200958,
        0.05109992250800133,
        0.053321294486522675,
        0.024053871631622314,
        -0.04318971186876297,
        0.045846305787563324,
        0.05197346210479736,
        0.05433265492320061,
        -0.054323989897966385,
        0.03260058909654617,
        -0.027807533740997314,
        -0.054602980613708496,
        0.05187182500958443,
        -0.027536900714039803,
        -0.04040411487221718,
        0.001136667444370687,
        0.0527103915810585,
        -0.00490094069391489,
        0.05128167197108269,
        0.05101439356803894,
        -0.045142319053411484,
        0.009225910529494286,
        -0.04429206997156143,
        -0.05399337038397789,
        0.046134140342473984,
        0.05348735302686691,
        0.05456625297665596,
        -0.04373127222061157,
        0.054633159190416336,
        -0.050803933292627335,
        0.054070282727479935,
        0.04449757561087608
    ],
    [
        0.03829387202858925,
        -0.046201251447200775,
        -0.0503133088350296,
        -0.035535819828510284,
        -0.051326848566532135,
        -0.05627841502428055,
        0.05187557265162468,
        -0.055101048201322556,
        0.02685835026204586,
        0.013428926467895508,
        0.05435904487967491,
        0.05499100685119629,
        -0.05024893581867218,
        0.016039539128541946,
        -0.05408128350973129,
        0.05515580251812935,
        0.04204534739255905,
        0.05434300750494003,
        0.04443681985139847,
        0.008474718779325485,
        0.013133347034454346,
        0.053808338940143585,
        0.054872483015060425,
        -0.05629545822739601,
        -0.05609099194407463,
        0.014531305991113186,
        -0.003501070197671652,
        0.055425338447093964,
        0.029310312122106552,
        0.05573568865656853,
        0.030669020488858223,
        -0.05620698258280754,
        0.04653105139732361,
        -0.05622325837612152,
        0.04934077337384224,
        -0.0559474378824234,
        -0.050972431898117065,
        -0.01429735030978918,
        0.0432363785803318,
        -0.04017337039113045,
        0.055560749024152756,
        0.04101049527525902,
        0.05094725638628006,
        -0.016603665426373482,
        0.01558842696249485,
        0.0024250319693237543,
        0.05508517101407051,
        0.05496763437986374,
        0.05507486313581467,
        0.0025260986294597387,
        0.055615976452827454,
        0.03963620588183403,
        -0.016502849757671356,
        0.03018893674015999,
        0.013055377639830112,
        0.04220867156982422,
        -0.02096513845026493,
        -0.024418897926807404,
        -0.03308961167931557,
        -0.03984680399298668,
        -0.054846636950969696,
        0.018670059740543365,
        -0.054735228419303894,
        0.05441509932279587,
        0.052387166768312454,
        -0.05343622341752052,
        -0.056248921900987625,
        -0.035825908184051514,
        0.010267061181366444,
        -0.04298447072505951,
        -0.04126529023051262,
        -0.03515199199318886,
        0.022914031520485878,
        -0.023323113098740578,
        -0.055854279547929764,
        -0.0511661097407341,
        0.04348014295101166,
        0.05629924312233925,
        0.04086132347583771,
        -0.004393936600536108,
        -0.040332432836294174,
        -0.047172319144010544,
        -0.050517283380031586,
        0.055373433977365494,
        0.029986340552568436,
        0.053885117173194885,
        -0.023398488759994507,
        -0.053043194115161896,
        0.0011529382318258286,
        0.05617029219865799,
        -0.04753706231713295,
        -0.052706919610500336,
        0.010763615369796753,
        -0.055864546447992325,
        -0.03293586149811745,
        0.05381709709763527,
        -0.04274643212556839,
        -0.05074078589677811,
        -0.0557531975209713,
        -0.00827778223901987,
        -0.054345548152923584,
        0.05620647966861725,
        -0.016817666590213776,
        0.051209572702646255,
        -0.007272443268448114,
        -0.05625981092453003,
        -0.055665358901023865,
        0.0005797428311780095,
        -0.009089510887861252,
        0.05431555211544037,
        0.05133695527911186,
        -0.05075795575976372,
        0.02336749993264675,
        0.03299262002110481,
        -0.03899277374148369,
        -0.04341959208250046,
        -0.056247610598802567,
        -0.049887076020240784,
        0.03232141211628914,
        0.052839893847703934,
        -0.028591366484761238,
        -0.032118797302246094,
        0.04915822669863701,
        -0.05424219369888306,
        -0.04322239011526108,
        0.05475732684135437,
        0.029928263276815414,
        0.005664669908583164,
        0.04171149805188179,
        0.05169479921460152,
        -0.05461059883236885,
        -0.05161944404244423,
        0.006013017613440752,
        0.055855099111795425,
        -0.04282139241695404,
        -0.05484726279973984,
        0.055028755217790604,
        0.04468996822834015,
        -0.03267505392432213,
        0.02622903324663639,
        -0.04314705356955528,
        0.05532528832554817,
        0.05181761831045151,
        0.055031515657901764,
        0.02190747857093811,
        0.0560564287006855,
        -0.054377660155296326,
        0.03352440148591995,
        -0.017810840159654617,
        0.04058132693171501,
        -0.035216134041547775,
        -0.04629574343562126,
        -0.03317533805966377,
        0.03885992616415024,
        0.04795891046524048,
        0.0526275672018528,
        -0.05097084864974022,
        -0.0024784167762845755,
        0.05587194859981537,
        0.03110417351126671,
        -0.01908407174050808,
        0.050721943378448486,
        0.054735276848077774,
        0.05544358119368553,
        0.053543493151664734,
        -0.03811701387166977,
        0.05164690688252449,
        -0.018435055390000343,
        0.0032095513306558132,
        0.04931476712226868,
        -0.052526794373989105,
        -0.05021950602531433,
        -0.03352705389261246,
        0.016562245786190033,
        0.023216137662529945,
        0.0025688824243843555,
        0.008984549902379513,
        0.05189349874854088,
        0.05441173166036606,
        -0.049689050763845444,
        -0.0428922139108181,
        0.03267664089798927,
        0.05492306500673294,
        -0.05622704327106476,
        -0.01854408159852028,
        0.030725985765457153,
        -0.0017162763979285955,
        0.05291923135519028,
        -0.014641529880464077,
        -0.013483989052474499,
        -0.025581378489732742,
        0.05404263362288475,
        -0.019800301641225815,
        0.04408954083919525,
        -0.05354934558272362,
        -0.005290372297167778,
        0.04759576916694641,
        0.05381496250629425,
        0.0423775240778923,
        0.04054079204797745,
        0.05588630959391594,
        -0.05447971448302269,
        -0.054604291915893555,
        -0.04804409667849541,
        0.053031742572784424,
        0.044471628963947296,
        0.030753737315535545,
        -0.019467275589704514,
        -0.051263678818941116,
        0.05061718076467514,
        -0.04465090110898018,
        -0.00928362924605608,
        0.05268231779336929,
        0.05366826057434082,
        0.05399823933839798,
        0.04648052901029587,
        0.03147353231906891,
        -0.042626719921827316,
        0.056237950921058655,
        -0.04933421313762665,
        -0.01041670236736536,
        0.05467606708407402,
        -0.056056831032037735,
        -0.04870571196079254,
        0.0528496615588665,
        -0.049901675432920456,
        0.054489001631736755,
        -0.053486861288547516,
        -0.006487430538982153,
        -0.05208926275372505,
        -0.053063299506902695,
        -0.04863428696990013,
        0.019859805703163147,
        0.05518079549074173,
        0.0019135132897645235,
        0.05592229589819908,
        0.0501677505671978,
        0.026208728551864624,
        -0.056285958737134933,
        0.05628184229135513,
        0.007946752943098545,
        0.04461373761296272,
        -0.015430139377713203,
        -0.054752446711063385,
        0.047326069325208664,
        0.009776830673217773,
        0.048513263463974,
        0.056147657334804535,
        0.02535724639892578,
        -0.05084388703107834,
        -0.030439700931310654,
        -0.04310339316725731,
        0.055642932653427124,
        -0.05504146218299866,
        0.05029095709323883,
        0.05142206326127052,
        0.055475883185863495,
        -0.055771056562662125,
        0.048204824328422546,
        0.03941742703318596,
        0.02673918381333351,
        0.027611110359430313,
        -0.011594175361096859,
        0.044296372681856155,
        0.056076355278491974,
        0.05626330524682999,
        0.05555795133113861,
        0.05628489702939987,
        -0.05199809744954109,
        0.04886377602815628,
        -0.05100230872631073,
        -0.047517579048871994,
        0.0025523800868541002,
        -0.049509819597005844,
        0.022377653047442436,
        0.04870133474469185,
        -0.05233519524335861,
        -0.04707523062825203,
        0.04319043084979057,
        0.04656977578997612,
        -0.0494510754942894,
        0.04737808182835579,
        -0.0279117189347744,
        0.023246878758072853,
        0.05617956444621086,
        -0.0423673614859581,
        0.0410003624856472,
        0.04015303775668144,
        -0.04200408607721329,
        0.05038844048976898,
        -0.05496726185083389,
        -0.008327193558216095,
        0.01721200719475746,
        -0.05170534551143646,
        0.056080371141433716,
        -0.05307004973292351,
        0.05376587063074112,
        0.030596910044550896,
        -0.0516546294093132,
        0.047516606748104095,
        -0.05420161411166191,
        0.028423331677913666,
        -0.023227708414196968,
        -0.018117601051926613,
        0.04339790344238281,
        0.048014383763074875,
        -0.054803136736154556,
        0.05299488827586174,
        0.053711455315351486,
        0.05542377755045891,
        0.026161525398492813,
        0.024884911254048347,
        0.03858935460448265,
        0.0546346977353096,
        0.045559968799352646,
        -0.05617096647620201,
        0.04016764461994171,
        0.05612602457404137,
        0.05470249056816101,
        -0.04469957947731018,
        -0.04819081723690033,
        0.05589766800403595,
        0.05444922298192978,
        -0.017199598252773285,
        0.05512868985533714,
        0.04762152209877968,
        0.00622781366109848,
        -0.05218074098229408,
        0.047671232372522354,
        -0.03272436559200287,
        0.05167818441987038,
        -0.04049791023135185,
        0.03128575161099434,
        0.04560743272304535,
        0.053303636610507965,
        0.05607258901000023,
        -0.05625700578093529,
        -0.04956706613302231,
        -0.052448570728302,
        0.02602616883814335,
        -0.001749010756611824,
        -0.05625377967953682,
        0.0002499526017345488,
        -0.05362040922045708,
        0.05563580244779587,
        0.03921685367822647,
        0.05617144703865051,
        0.05521377921104431,
        -0.056107159703969955,
        0.04600200429558754,
        0.04739760607481003,
        -0.01982741244137287,
        0.005983542185276747,
        -0.055864639580249786,
        -0.015176557935774326,
        0.001994358142837882,
        -0.05625110864639282,
        -0.054889749735593796,
        -0.011763039976358414,
        -0.022424232214689255,
        0.032245613634586334,
        0.055459171533584595,
        0.009247700683772564,
        -0.023305406793951988,
        -0.05346653610467911,
        -0.05598805844783783,
        0.05620519444346428,
        0.041035525500774384,
        0.04687582328915596,
        -0.0553787536919117,
        -0.03997989371418953,
        0.03991580381989479,
        0.053238142281770706,
        -0.0029084691777825356,
        0.028979560360312462,
        0.05280362814664841,
        -0.055388763546943665,
        -0.05599457398056984,
        0.052159033715724945,
        0.05555327609181404,
        -0.026308612897992134,
        0.039245907217264175,
        -0.05476502329111099,
        -0.045326557010412216,
        -0.029340848326683044,
        -0.05576402321457863,
        0.020743634551763535,
        -0.05508190020918846,
        -0.05225939676165581,
        0.030783088877797127,
        -0.05582266300916672,
        0.038361795246601105,
        -0.0550733357667923,
        0.055282678455114365,
        0.05619434267282486,
        0.049106091260910034,
        0.0473778173327446,
        -0.05447277054190636,
        -0.05061612278223038,
        -0.012613187544047832,
        -0.052297793328762054,
        0.053975287824869156,
        -0.009971646592020988,
        -0.05243518576025963,
        0.028643226251006126,
        -0.03842427581548691,
        0.04177083820104599,
        -0.008024003356695175,
        0.05445495620369911,
        -0.04736369103193283,
        -0.02230234071612358,
        0.03920992836356163,
        0.03921942412853241,
        -0.03112753853201866,
        0.04607882350683212,
        -0.050987325608730316,
        0.037563759833574295,
        0.052617788314819336,
        0.040971312671899796,
        0.051879581063985825,
        0.05563797429203987,
        -0.006036390084773302,
        -0.004429944790899754,
        -0.055589932948350906,
        0.021649831905961037,
        0.03893771767616272,
        -0.03972044959664345,
        0.052687641233205795,
        -0.010770780965685844,
        -0.04756646230816841,
        0.025741547346115112,
        -0.04930068179965019,
        -0.044205669313669205,
        -0.03667993098497391,
        -0.05394742265343666,
        -0.050683505833148956,
        -0.05073586478829384,
        0.02825666218996048,
        -0.04773605242371559,
        -0.053450629115104675,
        -0.05624474212527275,
        0.05095283314585686,
        -0.0373089425265789,
        0.05351843684911728,
        -0.052887752652168274,
        -0.056252285838127136,
        0.05574099346995354,
        -0.055203814059495926,
        0.05495605617761612,
        0.05135318636894226,
        0.0012539343442767859,
        0.035738468170166016,
        0.04942974075675011,
        -0.044204436242580414,
        0.05607408285140991,
        -0.05540478974580765,
        -0.03956838697195053,
        -0.04321737214922905,
        0.014427230693399906,
        0.011216717772185802,
        0.05595961585640907,
        -0.05056130513548851,
        0.04317751154303551,
        -0.04311785474419594,
        0.0362558551132679,
        0.0551251657307148,
        -0.055352237075567245,
        0.05606402829289436,
        0.01969054900109768,
        -0.055677030235528946,
        0.02021680772304535,
        -0.03178603947162628,
        0.05606919899582863,
        0.04847133532166481,
        -0.05259639024734497,
        0.029727676883339882,
        -0.051636096090078354,
        0.023001616820693016,
        -0.056040678173303604,
        -0.0008610984077677131,
        -0.05602103844285011,
        0.04719366133213043,
        0.04629339277744293,
        0.03584848344326019,
        -0.05115804448723793,
        0.05233777314424515,
        0.05578476935625076,
        0.05148684233427048,
        -0.055574823170900345,
        0.047504451125860214,
        -0.05526542291045189,
        -0.055781979113817215,
        -0.053970035165548325,
        -0.04189762845635414,
        -0.046148624271154404,
        0.04749169200658798,
        0.05624816566705704,
        -0.016429506242275238,
        0.046220771968364716,
        0.04040940850973129,
        -0.009644370526075363,
        -0.01955968141555786,
        -0.05601882189512253,
        0.0048683686181902885,
        -0.02109350822865963,
        0.022288011386990547,
        0.05554647743701935,
        -0.04289097711443901,
        0.055114880204200745,
        -0.05622488632798195,
        0.015334890224039555,
        -0.04855151101946831
    ],
    [
        -0.052550144493579865,
        0.02209075167775154,
        0.014235103502869606,
        -0.03176853433251381,
        -0.0535452626645565,
        -0.05074562504887581,
        0.038123276084661484,
        -0.05565107241272926,
        0.05072960630059242,
        -0.052072279155254364,
        0.05539929121732712,
        -0.017227770760655403,
        -0.05387386307120323,
        -0.04105771705508232,
        -0.05466816946864128,
        0.055655624717473984,
        0.04103672876954079,
        0.055509645491838455,
        -0.040068674832582474,
        -0.03635500743985176,
        -0.03323500230908394,
        0.049578264355659485,
        0.04355097934603691,
        0.04793211445212364,
        -0.043260253965854645,
        0.05453064665198326,
        0.04769499972462654,
        0.05611545965075493,
        -0.03603336960077286,
        -0.004614358767867088,
        0.044410619884729385,
        -0.05609963834285736,
        0.04634396359324455,
        -0.05688602104783058,
        0.05398193374276161,
        -0.053746502846479416,
        -0.054175131022930145,
        -0.007321374025195837,
        0.04838282987475395,
        0.03248366340994835,
        -0.052832625806331635,
        0.0483371838927269,
        0.05321766808629036,
        0.029152926057577133,
        0.04790828377008438,
        0.053700342774391174,
        0.048592351377010345,
        0.05661418288946152,
        0.05670006945729256,
        -0.05137094110250473,
        0.053926024585962296,
        0.052558377385139465,
        0.025301003828644753,
        -0.05419611185789108,
        0.055563002824783325,
        -0.024309678003191948,
        0.001009440515190363,
        0.012223312631249428,
        -0.051700759679079056,
        -0.04130861908197403,
        -0.05548587813973427,
        -0.033976562321186066,
        -0.0564284473657608,
        -0.05609118565917015,
        0.05415675416588783,
        -0.02778984233736992,
        -0.0521077923476696,
        -0.05044061690568924,
        -0.023728124797344208,
        -0.05573197081685066,
        0.018233738839626312,
        -0.05021169036626816,
        0.05557454749941826,
        0.040066640824079514,
        -0.053165800869464874,
        -0.052944064140319824,
        -0.006972145289182663,
        0.05689086765050888,
        0.04923918470740318,
        -0.032201074063777924,
        -0.023855438455939293,
        -0.048677727580070496,
        -0.05610504373908043,
        0.05330725386738777,
        0.05228777602314949,
        0.05567736178636551,
        -0.01547704916447401,
        -0.04337775334715843,
        0.017878828570246696,
        0.05551937222480774,
        -0.052709534764289856,
        0.04013190045952797,
        -0.027560383081436157,
        -0.027641069144010544,
        -0.05576401576399803,
        0.05232647433876991,
        -0.03743963688611984,
        -0.05618148669600487,
        -0.02560102567076683,
        0.04583121836185455,
        -0.05652223527431488,
        0.054514724761247635,
        0.019543088972568512,
        0.04397718235850334,
        0.01979627087712288,
        -0.0568479523062706,
        -0.029988251626491547,
        0.056359969079494476,
        0.018696032464504242,
        0.052078038454055786,
        0.05613416060805321,
        -0.05095524340867996,
        -0.0077557493932545185,
        0.031807348132133484,
        0.03549884259700775,
        -0.014559432864189148,
        -0.009440955705940723,
        -0.045372508466243744,
        0.045996181666851044,
        -0.05338706076145172,
        0.01898990571498871,
        0.0297891516238451,
        0.0055946530774235725,
        -0.03389239311218262,
        -0.05177970230579376,
        0.01286556851118803,
        -0.03470763564109802,
        0.05442005395889282,
        0.03488355875015259,
        -0.02581631951034069,
        -0.01379432063549757,
        0.017841581255197525,
        -0.038328979164361954,
        0.056790322065353394,
        -0.0523357018828392,
        -0.056028373539447784,
        0.056819576770067215,
        -0.05567678064107895,
        0.054638005793094635,
        0.021479377523064613,
        -0.053161706775426865,
        -0.027686607092618942,
        0.05511408671736717,
        0.04216943681240082,
        -0.030000796541571617,
        0.05378282815217972,
        0.03361447900533676,
        0.05414069816470146,
        -0.049536485224962234,
        -0.05266218259930611,
        -0.01222476176917553,
        -0.056781649589538574,
        -0.05360991880297661,
        0.04885705187916756,
        -0.0020989945624023676,
        -0.015440726652741432,
        0.0158695075660944,
        -0.03737952187657356,
        0.041426967829465866,
        0.023401038721203804,
        0.0013572403695434332,
        -0.00363468867726624,
        0.055119939148426056,
        -0.017211806029081345,
        0.05430950969457626,
        -0.021365920081734657,
        0.05674384906888008,
        -0.0085709597915411,
        -0.004105384927242994,
        0.0485142357647419,
        -0.04913753271102905,
        -0.0057709235697984695,
        -0.05512789636850357,
        0.013331410475075245,
        0.015018850564956665,
        0.009683899581432343,
        0.0566079244017601,
        -0.032076310366392136,
        0.0550311878323555,
        -0.050154585391283035,
        -0.02963555045425892,
        0.040482647716999054,
        0.0387270487844944,
        -0.05283643305301666,
        0.0472753643989563,
        -0.022274641320109367,
        0.05198550596833229,
        0.05274641141295433,
        0.002727803308516741,
        0.04101155325770378,
        -0.039986077696084976,
        0.05068036541342735,
        0.02571963705122471,
        0.05652979761362076,
        -0.049083542078733444,
        0.02988249622285366,
        0.0321907103061676,
        0.042255375534296036,
        -0.0455765500664711,
        0.04956299066543579,
        0.05366719141602516,
        0.0560772642493248,
        -0.008305385708808899,
        -0.03406189754605293,
        0.055072978138923645,
        -0.04718448221683502,
        0.036815471947193146,
        -0.0054713967256248,
        -0.05283674597740173,
        0.05625009536743164,
        -0.05487712845206261,
        0.03523826226592064,
        0.04666891694068909,
        0.042855001986026764,
        0.056776490062475204,
        -0.04513031616806984,
        0.007614031434059143,
        -0.015837764367461205,
        0.05585978552699089,
        -0.05566011369228363,
        0.019138064235448837,
        0.04561394825577736,
        -0.016895290464162827,
        -0.05062538757920265,
        0.05532967299222946,
        -0.05575570836663246,
        0.049148350954055786,
        -0.04023208096623421,
        0.05611203983426094,
        -0.055925335735082626,
        0.0216072928160429,
        -0.05501684918999672,
        0.0014984151348471642,
        0.056463584303855896,
        0.03231975808739662,
        0.056629519909620285,
        0.05512953922152519,
        0.053769271820783615,
        -0.042131561785936356,
        0.05661081150174141,
        0.010450867004692554,
        0.0027948664501309395,
        0.0009734643972478807,
        0.010168728418648243,
        0.05056852102279663,
        0.02502571977674961,
        0.0392935648560524,
        0.010986278764903545,
        -0.05653723329305649,
        -0.041261933743953705,
        0.049399521201848984,
        -0.05635960400104523,
        0.056707534939050674,
        -0.0563768669962883,
        0.0539233423769474,
        0.05081086978316307,
        0.018980372697114944,
        -0.04912887513637543,
        0.05274653807282448,
        -0.00313015328720212,
        0.009252328425645828,
        0.05630503594875336,
        -0.0018005939200520515,
        0.04683217778801918,
        -0.0307293888181448,
        0.05672150477766991,
        0.05128121003508568,
        0.05401919409632683,
        -0.02816815860569477,
        0.04952745512127876,
        -0.037840716540813446,
        -0.0536629818379879,
        0.053240954875946045,
        -0.05359257012605667,
        0.052790649235248566,
        0.0477302111685276,
        -0.05522174760699272,
        -0.05497302487492561,
        -0.006825478281825781,
        -0.041199907660484314,
        -0.03870023787021637,
        0.048912163823843,
        -0.03136012703180313,
        -0.04468034952878952,
        0.011648938991129398,
        0.029883764684200287,
        -0.015060639940202236,
        0.01867898739874363,
        -0.03406045213341713,
        0.041610363870859146,
        -0.05587555095553398,
        -0.03819059953093529,
        -0.02752293460071087,
        -0.03559080883860588,
        -0.04590394347906113,
        -0.05536369979381561,
        0.05655490607023239,
        -0.03543815761804581,
        -0.05547899752855301,
        0.05575581640005112,
        0.026735538616776466,
        0.009860629215836525,
        -0.0004309445503167808,
        0.0036062144208699465,
        0.00946769304573536,
        0.002270663157105446,
        0.002331275725737214,
        0.04920864850282669,
        0.05473584309220314,
        -0.049511343240737915,
        -0.055605798959732056,
        -0.027907151728868484,
        0.052610427141189575,
        0.05440538376569748,
        0.03705490380525589,
        -0.05653674900531769,
        -0.03940650075674057,
        0.056904904544353485,
        0.056384067982435226,
        -0.028314504772424698,
        -0.05669732764363289,
        -0.026813199743628502,
        0.055037595331668854,
        -0.05583597719669342,
        0.056719180196523666,
        0.05381791293621063,
        0.05661899223923683,
        0.0057335165329277515,
        0.05245964229106903,
        -0.055752456188201904,
        0.05102964863181114,
        -0.042864006012678146,
        0.049230001866817474,
        0.05450719967484474,
        0.05593956261873245,
        0.04099508002400398,
        -0.05679197609424591,
        0.04069092497229576,
        -0.04522775486111641,
        -0.04445610195398331,
        -0.049228884279727936,
        -0.055691882967948914,
        0.05357810854911804,
        -0.05371613800525665,
        0.0491572842001915,
        0.03898616507649422,
        0.05648475140333176,
        0.056348767131567,
        -0.05500020086765289,
        0.05234285816550255,
        0.011240676045417786,
        0.055813755840063095,
        0.03349754214286804,
        -0.05595802888274193,
        -0.05134623870253563,
        0.05502280220389366,
        -0.05677630752325058,
        -0.055849701166152954,
        -0.04423365741968155,
        -0.03839564323425293,
        0.054954253137111664,
        0.053994856774806976,
        -0.053529784083366394,
        -0.04085534065961838,
        -0.056705497205257416,
        -0.05679122358560562,
        0.0559120774269104,
        -0.03324934467673302,
        0.009950410574674606,
        -0.0553186871111393,
        -0.034109894186258316,
        0.015228083357214928,
        0.05254333093762398,
        -0.012433127500116825,
        0.05007840320467949,
        0.04908255115151405,
        -0.03514301776885986,
        -0.03895316272974014,
        0.04929865896701813,
        -0.03955749422311783,
        0.014091424643993378,
        0.037183284759521484,
        -0.05607735738158226,
        -0.04553310200572014,
        0.011998763307929039,
        -0.05546637251973152,
        -0.03298714756965637,
        0.024530429393053055,
        -0.05460022762417793,
        -0.0408918559551239,
        -0.055838145315647125,
        0.003470064140856266,
        -0.054333508014678955,
        -0.05327533185482025,
        0.033974554389715195,
        0.05423653870820999,
        0.04479766637086868,
        -0.05361100658774376,
        -0.05671847239136696,
        -0.02774575725197792,
        -0.040161117911338806,
        0.05455562472343445,
        -0.018765758723020554,
        0.05070072039961815,
        0.05276907607913017,
        -0.04882271960377693,
        0.019195497035980225,
        -0.038239266723394394,
        0.056780025362968445,
        0.04469228908419609,
        -0.05604258552193642,
        0.04184303805232048,
        0.05239230394363403,
        0.05075184628367424,
        -0.025473225861787796,
        -0.03511034697294235,
        -0.029065150767564774,
        0.011760266497731209,
        0.05435773730278015,
        0.031323350965976715,
        0.05592063069343567,
        -0.05687844380736351,
        -0.041011080145835876,
        -0.05679091438651085,
        -0.01916791871190071,
        0.041430652141571045,
        -0.055824000388383865,
        -0.05184781551361084,
        -0.04205404222011566,
        -0.05354359745979309,
        0.046223919838666916,
        -0.043626297265291214,
        0.040333040058612823,
        0.04306088760495186,
        -0.022131439298391342,
        -0.05108775198459625,
        -0.05530526861548424,
        0.05662580579519272,
        -0.051992159336805344,
        -0.0493922233581543,
        -0.04474756494164467,
        0.04182173311710358,
        0.030163485556840897,
        0.05558662489056587,
        -0.04423993453383446,
        0.0069838217459619045,
        0.055521607398986816,
        -0.047570861876010895,
        0.01704997755587101,
        0.05344432219862938,
        0.05683644488453865,
        0.046110764145851135,
        0.012093564495444298,
        -0.022933203727006912,
        0.04097500070929527,
        -0.05483939126133919,
        -0.02877454273402691,
        -0.05661031976342201,
        0.04052792489528656,
        -0.013607672415673733,
        -0.04565214738249779,
        -0.055729132145643234,
        0.04782682657241821,
        -0.007520954590290785,
        0.04970131441950798,
        0.05660557374358177,
        -0.05261729657649994,
        0.046174369752407074,
        -0.055691029876470566,
        -0.05348621681332588,
        0.05513685569167137,
        0.025602063164114952,
        0.05053063854575157,
        0.0473274365067482,
        0.030533183366060257,
        0.026685044169425964,
        -0.05565722659230232,
        0.048171550035476685,
        -0.0541522242128849,
        -0.040573086589574814,
        0.051702436059713364,
        0.04248576983809471,
        0.053915854543447495,
        0.04512842372059822,
        -0.048051197081804276,
        0.05317830666899681,
        0.0561525821685791,
        0.04952579736709595,
        -0.05558750033378601,
        -0.041141290217638016,
        -0.0350852906703949,
        -0.05104949325323105,
        0.05245564505457878,
        -0.0015207750257104635,
        0.02325643040239811,
        -0.015885304659605026,
        0.05354192107915878,
        -0.012319354340434074,
        0.05441858619451523,
        -0.0022829154040664434,
        -0.055194105952978134,
        -0.043815940618515015,
        -0.05422637239098549,
        -0.05569950118660927,
        0.05177026614546776,
        0.04459366574883461,
        0.053570494055747986,
        -0.02641657367348671,
        0.05359065532684326,
        0.01786753721535206,
        0.04466389864683151,
        -0.032919008284807205
    ],
    [
        0.05024923011660576,
        0.04450758919119835,
        0.0155276358127594,
        0.0319390632212162,
        -0.00878739170730114,
        -0.050699327141046524,
        0.05005478858947754,
        -0.0510193295776844,
        0.05090677738189697,
        -0.0510982982814312,
        0.05100492760539055,
        0.047097790986299515,
        -0.0510907806456089,
        0.023285532370209694,
        -0.049566179513931274,
        0.05106448009610176,
        0.049821384251117706,
        0.05104584991931915,
        -0.012720709666609764,
        -0.050503846257925034,
        -0.04917610436677933,
        0.017700210213661194,
        0.05109791085124016,
        -0.042159195989370346,
        -0.05059727653861046,
        -0.04946829751133919,
        0.04894990846514702,
        0.0510982871055603,
        -0.05084923654794693,
        0.0381784550845623,
        0.04974544420838356,
        -0.05109727010130882,
        0.051095519214868546,
        -0.051097288727760315,
        0.049800872802734375,
        -0.05086584389209747,
        -0.05042362958192825,
        -0.0006607172545045614,
        0.050949085503816605,
        0.048248883336782455,
        0.04108813777565956,
        0.04454273357987404,
        0.04657053202390671,
        0.03303897753357887,
        0.05107240378856659,
        0.0331105962395668,
        0.050670016556978226,
        0.05109519138932228,
        0.051077984273433685,
        -0.0510982945561409,
        0.05089101195335388,
        0.04958591237664223,
        -0.050017088651657104,
        0.026859791949391365,
        0.051069341599941254,
        0.03908844664692879,
        0.04852646216750145,
        -0.043860822916030884,
        -0.04669240117073059,
        -0.050991490483284,
        -0.05106614902615547,
        0.029245978221297264,
        -0.05104093998670578,
        -0.05100083351135254,
        0.04425942525267601,
        -0.049626950174570084,
        -0.05108420178294182,
        -0.046360038220882416,
        -0.04525282233953476,
        0.011385979130864143,
        0.012464910745620728,
        -0.05106431990861893,
        0.05109265819191933,
        0.044382210820913315,
        -0.050961703062057495,
        -0.04280753433704376,
        0.0360395722091198,
        0.0510982982814312,
        0.049136932939291,
        0.004960996098816395,
        -0.047082189470529556,
        -0.049611397087574005,
        -0.0507856160402298,
        0.050872255116701126,
        0.05107056722044945,
        0.05098987743258476,
        -0.04818890616297722,
        -0.05007290840148926,
        0.012631269171833992,
        -0.0047726756893098354,
        -0.050195954740047455,
        -0.0009302341495640576,
        -0.05103505775332451,
        -0.050914645195007324,
        -0.04903493449091911,
        0.05109049752354622,
        -0.017726322636008263,
        -0.05094797909259796,
        -0.049444735050201416,
        0.012874524109065533,
        -0.05102582648396492,
        0.04542507603764534,
        -0.030739445239305496,
        0.04754043370485306,
        0.010265632532536983,
        -0.05109499394893646,
        -0.05081838369369507,
        0.05080298334360123,
        -0.016941329464316368,
        0.05103691667318344,
        -0.04613027349114418,
        -0.026752524077892303,
        0.030702484771609306,
        -0.005013341549783945,
        0.037499018013477325,
        0.006516973953694105,
        0.049429360777139664,
        -0.0502174086868763,
        0.045345205813646317,
        -0.04521876573562622,
        0.024317976087331772,
        0.011410169303417206,
        0.05071853846311569,
        -0.05061747506260872,
        -0.041700247675180435,
        0.051032163202762604,
        -0.04448487609624863,
        0.04817222058773041,
        0.050893548876047134,
        0.03047109767794609,
        -0.05043719708919525,
        -0.05045558512210846,
        0.042635224759578705,
        0.051095400005578995,
        -0.045023757964372635,
        -0.05048854649066925,
        0.050517480820417404,
        0.04503690451383591,
        -0.043015167117118835,
        0.050318218767642975,
        -0.04906322434544563,
        0.051089927554130554,
        0.047678690403699875,
        0.01085361186414957,
        0.04962786287069321,
        0.04953205958008766,
        0.04652707278728485,
        0.051097068935632706,
        -0.05108310654759407,
        0.049486998468637466,
        0.03066660650074482,
        -0.050869300961494446,
        -0.05029395595192909,
        0.038516707718372345,
        -0.05089385434985161,
        -0.011932381428778172,
        0.04351671785116196,
        -0.03821752220392227,
        -0.050684619694948196,
        -0.049961529672145844,
        -0.039592791348695755,
        0.03501935303211212,
        0.048608701676130295,
        -0.05054938420653343,
        0.05103600025177002,
        -0.04856354743242264,
        0.05108215659856796,
        -0.03150743991136551,
        -0.050267294049263,
        0.04639523848891258,
        -0.05070529505610466,
        -0.051088280975818634,
        -0.0510745495557785,
        -0.05030724033713341,
        0.05107654631137848,
        0.04701656103134155,
        0.029334058985114098,
        0.02797740325331688,
        0.05096597224473953,
        -0.04458281025290489,
        -0.03463677316904068,
        -0.04940592125058174,
        0.04571208357810974,
        -0.05090855434536934,
        0.005147755146026611,
        -0.006599572487175465,
        0.048889752477407455,
        0.05024346709251404,
        -0.0142876747995615,
        0.03733492270112038,
        -0.05109488591551781,
        0.04773959890007973,
        0.04569707438349724,
        -0.019209876656532288,
        -0.04851899668574333,
        -0.05009855329990387,
        0.050168540328741074,
        0.04811715707182884,
        -0.04954860731959343,
        0.050990987569093704,
        0.043713998049497604,
        0.030582673847675323,
        0.024669380858540535,
        -0.05109250172972679,
        0.051066141575574875,
        0.008492201566696167,
        -0.03981311619281769,
        -0.014171363785862923,
        -0.05103061720728874,
        0.049392830580472946,
        -0.048805151134729385,
        0.00929597020149231,
        0.05109825357794762,
        0.017670009285211563,
        0.049679554998874664,
        -0.051074255257844925,
        0.042941778898239136,
        -0.03272165730595589,
        0.03671614080667496,
        -0.02034398540854454,
        0.04550603777170181,
        0.03071555308997631,
        -0.044846877455711365,
        -0.04953695461153984,
        0.048922162503004074,
        -0.04118549823760986,
        0.04265303164720535,
        -0.04857485741376877,
        0.0504133366048336,
        -0.04764731973409653,
        -0.049531854689121246,
        -0.04129767045378685,
        -0.05083006992936134,
        0.04950282722711563,
        0.04961487650871277,
        0.051089975982904434,
        0.0499170757830143,
        0.051004182547330856,
        -0.05094566568732262,
        0.05109820514917374,
        0.04720102623105049,
        0.04240122064948082,
        0.048420537263154984,
        -0.028017569333314896,
        -0.05075141414999962,
        -0.049034036695957184,
        0.04594031721353531,
        0.022678708657622337,
        -0.05104021355509758,
        -0.04253001883625984,
        0.05100483074784279,
        -0.038305286318063736,
        0.050120823085308075,
        -0.047423165291547775,
        0.05061958730220795,
        0.05109517648816109,
        -0.04562783241271973,
        -0.04891218990087509,
        0.03745535388588905,
        -0.04567539319396019,
        -0.04455123096704483,
        0.04975823312997818,
        -0.04938240349292755,
        0.04791007936000824,
        0.02787352353334427,
        0.050771553069353104,
        0.051097236573696136,
        0.051061324775218964,
        -0.049239058047533035,
        0.029332494363188744,
        -0.037974052131175995,
        -0.013822054490447044,
        0.05108080059289932,
        -0.05094809830188751,
        0.05109744891524315,
        0.0488843210041523,
        -0.04776069521903992,
        -0.05109645053744316,
        0.049906738102436066,
        0.050840627402067184,
        -0.05081767588853836,
        -0.04409441351890564,
        -0.05109281465411186,
        0.03677643835544586,
        0.033405642956495285,
        0.050765588879585266,
        0.04930843785405159,
        -0.05103790760040283,
        0.05107356980443001,
        0.023664947599172592,
        -0.05083003640174866,
        0.05066932365298271,
        -0.04691535234451294,
        -0.04935130104422569,
        0.050710711628198624,
        -0.04834599420428276,
        0.05107766017317772,
        -0.03727435693144798,
        -0.050839509814977646,
        0.050746239721775055,
        0.02353782020509243,
        0.04392487555742264,
        -0.020370643585920334,
        0.03456967696547508,
        0.03889762610197067,
        -0.03137180581688881,
        -0.020797519013285637,
        0.04114290699362755,
        0.04045606032013893,
        0.04141658917069435,
        -0.013306858949363232,
        -0.031714409589767456,
        0.0148786511272192,
        0.025823434814810753,
        0.03035111539065838,
        -0.05059649795293808,
        0.02376602776348591,
        0.0510982982814312,
        0.05063850060105324,
        0.025122616440057755,
        -0.0510982945561409,
        0.041382964700460434,
        0.05108599364757538,
        0.025884857401251793,
        0.05099566653370857,
        0.051043905317783356,
        -0.030970655381679535,
        0.05067010968923569,
        0.050819866359233856,
        -0.04598002880811691,
        0.023379012942314148,
        -0.047932256013154984,
        0.03584817796945572,
        0.050401587039232254,
        0.0465313121676445,
        -0.03406548872590065,
        -0.05109771341085434,
        -0.0023016955237835646,
        -0.015847843140363693,
        -0.05099591240286827,
        -0.05017758160829544,
        -0.051095716655254364,
        0.044799163937568665,
        0.050605736672878265,
        0.02176547423005104,
        0.04904286935925484,
        0.04999130591750145,
        0.050116006284952164,
        -0.05090413615107536,
        0.01652257889509201,
        0.050683557987213135,
        0.04993220791220665,
        -0.004073017742484808,
        -0.05060669407248497,
        -0.04680655151605606,
        -0.0012225080281496048,
        -0.051002178341150284,
        -0.051011823117733,
        -0.05095428228378296,
        -0.05067840591073036,
        0.03792501613497734,
        0.050169624388217926,
        -0.04934582859277725,
        -0.04992886260151863,
        -0.05029955133795738,
        -0.05102512612938881,
        0.051024843007326126,
        -0.026090536266565323,
        0.05047573521733284,
        -0.051039449870586395,
        -0.04817450791597366,
        -0.0036783653777092695,
        0.0009722167160362005,
        0.047182727605104446,
        0.03868652880191803,
        0.04916941002011299,
        -0.05109061300754547,
        -0.04896919056773186,
        0.04847155511379242,
        -0.026874471455812454,
        -0.04531239718198776,
        -0.03814905136823654,
        -0.049496203660964966,
        0.03309603035449982,
        -0.014474707655608654,
        -0.05108557641506195,
        -0.05086936429142952,
        -0.04630720987915993,
        -0.04919884353876114,
        -0.05050937458872795,
        -0.05109545215964317,
        0.018488407135009766,
        -0.04879540950059891,
        -0.04230879619717598,
        0.03921446204185486,
        0.050861191004514694,
        -0.05109204724431038,
        -0.050839610397815704,
        -0.05040767416357994,
        -0.05065394937992096,
        -0.034461747854948044,
        0.050868842750787735,
        -0.015203497372567654,
        -0.04985407739877701,
        0.051098089665174484,
        -0.0011680955067276955,
        0.050279419869184494,
        -0.0006928384536877275,
        0.0510711632668972,
        0.04842977970838547,
        -0.03438369929790497,
        0.05105919390916824,
        0.050779227167367935,
        0.04499658942222595,
        0.04632366821169853,
        -0.049039993435144424,
        0.0038651989307254553,
        0.050098348408937454,
        0.046378809958696365,
        0.050942327827215195,
        0.05006930232048035,
        -0.046769190579652786,
        -0.05099588260054588,
        -0.051081519573926926,
        0.04422083869576454,
        -0.045078638941049576,
        -0.0510982871055603,
        -0.004195166751742363,
        -0.04105705767869949,
        -0.05072883144021034,
        0.05098564922809601,
        0.007110167294740677,
        0.05080663785338402,
        0.051026809960603714,
        -0.040769416838884354,
        -0.050968244671821594,
        -0.050864219665527344,
        0.051062725484371185,
        -0.03386666253209114,
        -0.030353892594575882,
        -0.050010621547698975,
        0.027992334216833115,
        0.04231696203351021,
        0.046716347336769104,
        0.04297289997339249,
        0.05101965740323067,
        0.051085494458675385,
        -0.035647809505462646,
        0.04794963076710701,
        0.05109474062919617,
        0.03871699795126915,
        0.03091730922460556,
        0.008686666376888752,
        0.005809003487229347,
        0.04541623592376709,
        -0.05065305531024933,
        -0.04730384424328804,
        -0.03432716056704521,
        -0.0005205259658396244,
        -0.003335655201226473,
        0.05085347965359688,
        -0.02707534469664097,
        -0.050443828105926514,
        0.04431788623332977,
        0.05102142319083214,
        0.0510442852973938,
        -0.04894443228840828,
        0.051094215363264084,
        -0.047126658260822296,
        -0.05096916854381561,
        0.050768110901117325,
        0.0070417397655546665,
        0.0507366843521595,
        0.045070938766002655,
        0.0481962226331234,
        0.04041624441742897,
        -0.00024116724671330303,
        0.034926269203424454,
        -0.04906556382775307,
        -0.04987360164523125,
        0.05104494467377663,
        -0.041809871792793274,
        0.05089413747191429,
        0.05036609619855881,
        -0.051061876118183136,
        0.05071978643536568,
        0.051044709980487823,
        0.03877720236778259,
        -0.05056528374552727,
        -0.0476248562335968,
        -0.04900326207280159,
        0.04476122558116913,
        0.036839261651039124,
        0.047733012586832047,
        -0.013834507204592228,
        0.04361702874302864,
        0.04671686142683029,
        0.04176772013306618,
        0.05003771558403969,
        0.05108765512704849,
        -0.05047815293073654,
        -0.04613088443875313,
        -0.04392169415950775,
        -0.045203641057014465,
        0.0510982908308506,
        0.038575369864702225,
        0.05108533427119255,
        -0.05058233439922333,
        0.051085297018289566,
        -0.05109827220439911,
        -0.048861052840948105,
        -0.03676939383149147
    ],
    [
        0.01869707554578781,
        0.053878750652074814,
        0.05259892717003822,
        0.036200881004333496,
        -0.03260373696684837,
        0.059099867939949036,
        0.05688479542732239,
        -0.05765024572610855,
        0.011387593112885952,
        -0.017602596431970596,
        0.05997035279870033,
        0.05363650619983673,
        -0.042891837656497955,
        0.005335284862667322,
        -0.05979902669787407,
        0.05670354515314102,
        0.01629595085978508,
        0.05907480791211128,
        -0.0496203787624836,
        -0.0025520664639770985,
        0.034508850425481796,
        -0.044155094772577286,
        -0.05759035050868988,
        -0.05956967547535896,
        -0.0020842261146754026,
        0.054571740329265594,
        -0.050767384469509125,
        0.059708621352910995,
        -0.024768607690930367,
        -0.0017442665994167328,
        0.040829457342624664,
        -0.04936700314283371,
        0.037196699529886246,
        -0.059620391577482224,
        0.05719013884663582,
        -0.019178306683897972,
        -0.05951273441314697,
        -0.010398605838418007,
        -0.052365098148584366,
        0.05205351486802101,
        0.03382964804768562,
        0.00600044010207057,
        0.044786639511585236,
        -0.01777534745633602,
        0.0397484228014946,
        0.026976779103279114,
        0.04152774438261986,
        0.052364643663167953,
        0.05815349146723747,
        -0.054273247718811035,
        0.05884920805692673,
        0.0541672445833683,
        0.0051764328964054585,
        0.0196691881865263,
        -0.05002967268228531,
        -0.018119392916560173,
        -0.05772845074534416,
        -0.03758002817630768,
        -0.057401977479457855,
        -0.016283443197607994,
        -0.05774674192070961,
        -0.03116384707391262,
        -0.05788843706250191,
        -0.03788726404309273,
        0.026248302310705185,
        -0.05077887326478958,
        -0.03594788908958435,
        0.05902513116598129,
        -0.04560630023479462,
        -0.060425013303756714,
        -0.05542314052581787,
        -0.055787842720746994,
        0.06024392321705818,
        0.010562939569354057,
        -0.06036873906850815,
        -0.04233032092452049,
        0.05089189484715462,
        0.060383737087249756,
        0.04295450076460838,
        0.020695265382528305,
        -0.031744468957185745,
        -0.059261124581098557,
        -0.05988657474517822,
        0.05527888610959053,
        0.01416696049273014,
        0.03063339740037918,
        -0.033082589507102966,
        -0.03249195218086243,
        0.03117557056248188,
        0.05937876179814339,
        0.03749476745724678,
        -0.05062428116798401,
        0.05582258105278015,
        -0.044312313199043274,
        -0.0438692569732666,
        0.027708839625120163,
        -0.00635096151381731,
        -0.055640555918216705,
        -0.04126488417387009,
        0.040785644203424454,
        -0.05986931174993515,
        -0.049353133887052536,
        0.04132293909788132,
        0.046657055616378784,
        -0.058202825486660004,
        -0.05104812979698181,
        -0.05834997072815895,
        -0.030750015750527382,
        -0.025206109508872032,
        0.0009007708868011832,
        -0.02847132831811905,
        0.053074389696121216,
        -0.045545242726802826,
        -0.009967058897018433,
        0.06040143966674805,
        -0.054245639592409134,
        0.031198356300592422,
        -0.0019101938232779503,
        0.047548554837703705,
        -0.0365159846842289,
        -0.02940603531897068,
        0.032439060509204865,
        -0.013480651192367077,
        -0.04272649437189102,
        -0.05551556497812271,
        -0.03944580256938934,
        -0.04151038080453873,
        0.059911761432886124,
        0.05972559005022049,
        -0.05209392309188843,
        0.04705905169248581,
        -0.05242200940847397,
        -0.06014436483383179,
        0.05512620508670807,
        -0.047911807894706726,
        -0.05860906094312668,
        0.044815562665462494,
        -0.028797615319490433,
        -0.04407310113310814,
        0.05092119053006172,
        0.05945458263158798,
        -0.03274187073111534,
        0.05863001197576523,
        0.013019642792642117,
        0.03203225135803223,
        0.011049125343561172,
        0.0541248619556427,
        -0.05843723192811012,
        -0.005575395654886961,
        0.014723282307386398,
        -0.053022805601358414,
        -0.059612032026052475,
        -0.053989995270967484,
        -0.05714871361851692,
        0.058966830372810364,
        0.0071025718934834,
        -0.009855479001998901,
        0.002795722335577011,
        0.03165547177195549,
        -0.024285724386572838,
        -0.05058161914348602,
        0.058580182492733,
        0.052972737699747086,
        0.04829351603984833,
        0.027919894084334373,
        -0.05539131164550781,
        0.044532351195812225,
        -0.02599846012890339,
        -0.013483417220413685,
        0.03904590383172035,
        -0.05160718783736229,
        0.05783159285783768,
        -0.058328669518232346,
        0.05658593773841858,
        -0.0480232760310173,
        0.040926773101091385,
        -0.039935678243637085,
        0.05397198721766472,
        0.05983677878975868,
        -0.03754277899861336,
        -0.04290531203150749,
        -0.013872652314603329,
        -0.01816534623503685,
        -0.02577575296163559,
        -0.02242146246135235,
        -0.02255643717944622,
        -0.011649741791188717,
        0.05883391574025154,
        0.026993874460458755,
        0.05938282236456871,
        -0.055715516209602356,
        0.021791044622659683,
        -0.026728449389338493,
        -0.05815524607896805,
        -0.05277391895651817,
        0.052828606218099594,
        0.05972970277070999,
        0.05876697227358818,
        0.04769425839185715,
        0.007215187884867191,
        0.05804474651813507,
        0.05709933489561081,
        -0.04134918004274368,
        -0.03451262414455414,
        -0.012821641750633717,
        -0.059725746512413025,
        0.0348048061132431,
        0.030898239463567734,
        -0.028755735605955124,
        0.02519633062183857,
        -0.05220864713191986,
        0.056858889758586884,
        -0.022989999502897263,
        0.0252287145704031,
        0.05096006020903587,
        -0.05627340078353882,
        0.05949343368411064,
        0.03518301993608475,
        0.05919056013226509,
        -0.05314335972070694,
        0.03831413760781288,
        -0.025485999882221222,
        -0.00017681994359008968,
        -0.05759871378540993,
        0.014545145444571972,
        -0.041906677186489105,
        -0.023587265983223915,
        0.026796437799930573,
        -0.05465538054704666,
        -0.05528678372502327,
        -0.041263699531555176,
        -0.040025290101766586,
        -0.056267011910676956,
        0.040679264813661575,
        0.058615125715732574,
        0.03701034560799599,
        0.058747466653585434,
        -0.048419106751680374,
        -0.008014848455786705,
        0.05983741208910942,
        0.01958930306136608,
        -0.003681156085804105,
        0.03235442563891411,
        -0.06031916290521622,
        0.05383965000510216,
        0.03654612600803375,
        -0.059068597853183746,
        -0.021714119240641594,
        0.01326785422861576,
        -0.04762651026248932,
        -0.05652027204632759,
        -0.041177887469530106,
        0.054574452340602875,
        -0.05737650394439697,
        0.008843115530908108,
        0.05652771517634392,
        0.06018748879432678,
        -0.04255353659391403,
        0.04797937721014023,
        -0.031586501747369766,
        0.033748697489500046,
        -0.04018059000372887,
        -0.03940977901220322,
        0.040349338203668594,
        -0.04133126139640808,
        0.05961101874709129,
        -0.03891517594456673,
        0.009288382716476917,
        -0.022078540176153183,
        -0.008536146022379398,
        0.05226921662688255,
        -0.035560689866542816,
        0.06010762229561806,
        -0.0587739422917366,
        -0.03151669353246689,
        0.03683215752243996,
        -0.04729372635483742,
        0.05745835602283478,
        -0.056715693324804306,
        -0.038569431751966476,
        -0.03153832629323006,
        -0.013004199601709843,
        0.00431054038926959,
        0.06026321277022362,
        -0.01204244140535593,
        0.059684474021196365,
        0.014798172749578953,
        0.05571134760975838,
        -0.024067960679531097,
        0.0241226926445961,
        -0.0498240701854229,
        0.039655596017837524,
        -0.04820225387811661,
        0.05661885067820549,
        0.015140026807785034,
        -0.057996466755867004,
        0.05984709411859512,
        0.041066356003284454,
        -0.06041061878204346,
        0.05785919725894928,
        0.015180282294750214,
        0.05675221607089043,
        -0.04951796680688858,
        0.051427680999040604,
        0.027620872482657433,
        -0.0424199253320694,
        -0.05806950852274895,
        0.06007818132638931,
        0.03177953138947487,
        -0.05846886336803436,
        0.0509740486741066,
        -0.025635505095124245,
        0.04509096220135689,
        0.05623941496014595,
        -0.05607005953788757,
        -0.05656229332089424,
        -0.001595494570210576,
        0.058515410870313644,
        0.05399911105632782,
        -0.03648675978183746,
        0.001150584197603166,
        0.013018297031521797,
        0.044627103954553604,
        -0.014440234750509262,
        0.03397754952311516,
        0.05916235223412514,
        0.058715254068374634,
        -0.060362808406353,
        -0.004771752282977104,
        -0.04967555031180382,
        -0.03789634257555008,
        -0.046327222138643265,
        0.056289419531822205,
        0.05775248259305954,
        0.0579071082174778,
        0.04823613911867142,
        0.05313771218061447,
        0.056445252150297165,
        0.028004739433526993,
        0.0328446589410305,
        -0.051053378731012344,
        -0.03412330150604248,
        -0.057030659168958664,
        -0.05322183296084404,
        0.052714910358190536,
        0.05857647582888603,
        0.03741154447197914,
        0.05855340510606766,
        -0.032145608216524124,
        0.05963088199496269,
        -0.03176555782556534,
        0.05987472087144852,
        0.015487698838114738,
        0.034776996821165085,
        -0.03797809034585953,
        0.05853886157274246,
        -0.06005427986383438,
        -0.03676173835992813,
        -0.04684619605541229,
        -0.0049700262024998665,
        0.046612925827503204,
        -0.0070623294450342655,
        -0.047776296734809875,
        -0.053787216544151306,
        -0.04905496537685394,
        -0.0479663647711277,
        0.05590503662824631,
        -0.018513772636651993,
        0.053463324904441833,
        -0.05929463729262352,
        0.03911092132329941,
        0.010662378743290901,
        0.0597306564450264,
        -0.05583444982767105,
        0.022611262276768684,
        0.040462516248226166,
        0.02162248082458973,
        -0.053093500435352325,
        0.045506905764341354,
        -0.04942484572529793,
        0.043440137058496475,
        0.05565587058663368,
        -0.059502437710762024,
        0.006658538244664669,
        0.056789644062519073,
        -0.04613955318927765,
        -0.007207110524177551,
        -0.03540107235312462,
        -0.05153584107756615,
        -0.06027616932988167,
        -0.04961855337023735,
        0.008817066438496113,
        -0.05134408175945282,
        0.026492437347769737,
        -0.03898250311613083,
        0.056835420429706573,
        0.05795547738671303,
        0.005117901600897312,
        -0.05545048415660858,
        -0.05560041218996048,
        -0.030329477041959763,
        0.05404987931251526,
        -0.03413371741771698,
        0.050981491804122925,
        0.057078272104263306,
        0.042531318962574005,
        0.0518198125064373,
        -0.00328337331302464,
        0.052529361099004745,
        -0.042133625596761703,
        -0.038590189069509506,
        -0.05988646298646927,
        -0.033448297530412674,
        -0.03907884657382965,
        0.031026557087898254,
        -0.01210129912942648,
        0.03526068106293678,
        -0.009723073802888393,
        0.053030986338853836,
        -0.03772400692105293,
        0.023461714386940002,
        -0.059788722544908524,
        -0.060086119920015335,
        -0.05861922726035118,
        -0.054255519062280655,
        0.04639635235071182,
        0.022494209930300713,
        -0.058879557996988297,
        -0.03204432874917984,
        -0.0483769029378891,
        -0.010578902438282967,
        0.02401343360543251,
        -0.059509824961423874,
        0.011383654549717903,
        -0.03330477699637413,
        -0.012672697193920612,
        -0.059177786111831665,
        -0.02956940233707428,
        -0.05250279977917671,
        -0.05451599881052971,
        -0.04788866639137268,
        0.03957287222146988,
        -0.0032829968258738518,
        0.04786812141537666,
        -0.03964795544743538,
        -0.005392450373619795,
        0.059413842856884,
        0.010720815509557724,
        0.0484214648604393,
        0.05942534655332565,
        -0.05706033483147621,
        0.02391902543604374,
        0.005077187437564135,
        -0.026964297518134117,
        0.02328474260866642,
        -0.05277044326066971,
        0.053105227649211884,
        -0.03089446946978569,
        -0.05424671620130539,
        -0.02914019115269184,
        -0.03843534365296364,
        -0.050252124667167664,
        0.03809867054224014,
        -0.056863997131586075,
        -0.011367839761078358,
        0.05975376069545746,
        -0.05209171399474144,
        0.052305564284324646,
        -0.026124009862542152,
        -0.050182804465293884,
        0.05183447524905205,
        0.04921657592058182,
        0.04250224307179451,
        0.05870421230792999,
        -0.02239018678665161,
        -0.05096738785505295,
        -0.04738461226224899,
        0.05298768728971481,
        -0.03528783842921257,
        -0.025112930685281754,
        0.05319911986589432,
        0.006231691222637892,
        0.05499931797385216,
        0.05188298970460892,
        0.008946463465690613,
        0.01421203650534153,
        0.04188905656337738,
        0.05215434730052948,
        -0.05780993402004242,
        0.002423712983727455,
        -0.006063176319003105,
        -0.05824552848935127,
        0.05403214320540428,
        0.049072265625,
        -0.026515550911426544,
        0.030136162415146828,
        -0.054837096482515335,
        -0.05458727851510048,
        0.053890783339738846,
        0.0054442365653812885,
        0.03539218381047249,
        -0.0036974733229726553,
        -0.0535588264465332,
        -0.026168258860707283,
        0.018970055505633354,
        -0.030435554683208466,
        0.055286042392253876,
        -0.029446324333548546,
        -0.0022183035034686327,
        -0.027727633714675903,
        0.049490585923194885,
        0.05704451724886894
    ],
    [
        -0.03407934308052063,
        0.03632015734910965,
        0.053957220166921616,
        0.023854294791817665,
        -0.023949727416038513,
        0.05355571582913399,
        0.05584904924035072,
        -0.055475667119026184,
        0.040754638612270355,
        0.008544343523681164,
        0.05603610351681709,
        0.05572981759905815,
        -0.05354614183306694,
        0.023603063076734543,
        -0.055938899517059326,
        0.0534999780356884,
        -0.01600617542862892,
        0.05491643026471138,
        -0.03139806538820267,
        -0.05042995139956474,
        0.053154122084379196,
        -0.03530048951506615,
        -0.05321575328707695,
        -0.05597318708896637,
        -0.03934545814990997,
        0.05326271057128906,
        -0.05022701248526573,
        0.05590178072452545,
        -0.049314726144075394,
        -0.0005542494473047554,
        0.018345514312386513,
        -0.014396470040082932,
        0.03661106154322624,
        -0.055971261113882065,
        0.05401184409856796,
        0.024878453463315964,
        -0.05560274049639702,
        -0.033354636281728745,
        -0.04138373211026192,
        0.02739027887582779,
        0.05180872976779938,
        0.03297138959169388,
        0.03226601704955101,
        0.0365234836935997,
        0.03813614696264267,
        0.03863924369215965,
        0.053583260625600815,
        0.05490276217460632,
        0.05597003921866417,
        -0.05442103371024132,
        0.055680159479379654,
        0.03896960988640785,
        -0.044815465807914734,
        -0.03605455160140991,
        -0.05031101405620575,
        -0.03172242268919945,
        -0.055326204746961594,
        -0.01243991032242775,
        -0.05133887380361557,
        -0.04643508791923523,
        -0.055527981370687485,
        0.01953480765223503,
        -0.05474643409252167,
        -0.03872878849506378,
        0.04593111574649811,
        -0.0500052347779274,
        -0.05456063523888588,
        0.05594107136130333,
        -0.03268710523843765,
        -0.05607077479362488,
        -0.040095068514347076,
        -0.05505109950900078,
        0.05584636330604553,
        -0.018311679363250732,
        -0.05563182011246681,
        -0.02309977263212204,
        0.05549199506640434,
        0.056066811084747314,
        0.04941180720925331,
        -0.02726140432059765,
        -0.043627142906188965,
        -0.055974967777729034,
        -0.05581725388765335,
        0.05501709133386612,
        0.05582653731107712,
        0.03047456033527851,
        -0.054811544716358185,
        -0.0438108928501606,
        0.004385136999189854,
        0.05354202911257744,
        -0.043794866651296616,
        -0.05413680896162987,
        0.027723314240574837,
        -0.051330581307411194,
        -0.03438422828912735,
        0.0068912808783352375,
        -0.03358606994152069,
        -0.05490622669458389,
        -0.022845927625894547,
        0.043376192450523376,
        -0.055974602699279785,
        0.015919923782348633,
        0.004621149506419897,
        0.0503888837993145,
        -0.05574410781264305,
        -0.040988385677337646,
        -0.050856854766607285,
        0.041266318410634995,
        -0.039986949414014816,
        0.05123211070895195,
        -0.054703228175640106,
        0.027398735284805298,
        -0.031838733702898026,
        -0.02143973857164383,
        0.05606440454721451,
        -0.05511060357093811,
        0.05425022542476654,
        -0.05453715845942497,
        0.0046698590740561485,
        -0.026018133386969566,
        -0.04802296310663223,
        0.027852635830640793,
        0.03259553387761116,
        -0.05251006782054901,
        -0.05036035180091858,
        -0.034783199429512024,
        -0.04630332067608833,
        0.05546307936310768,
        0.05535626411437988,
        -0.05147391930222511,
        0.05448777601122856,
        -0.05032556131482124,
        -0.055271077901124954,
        0.044625964015722275,
        -0.05177643895149231,
        -0.05511860549449921,
        0.0491500198841095,
        0.03318603336811066,
        -0.054942212998867035,
        0.05513347312808037,
        0.054554227739572525,
        -0.020695772022008896,
        0.0501750186085701,
        -0.014926401898264885,
        0.015998290851712227,
        -0.036852262914180756,
        0.05551338940858841,
        -0.05574595183134079,
        0.014802336692810059,
        0.005163345020264387,
        -0.04983969405293465,
        -0.05542197450995445,
        -0.05216288939118385,
        -0.046710822731256485,
        0.04170333966612816,
        -0.02719750441610813,
        -0.0059528532437980175,
        0.0152061078697443,
        0.03815874829888344,
        -0.029581809416413307,
        -0.04356984794139862,
        0.05203787609934807,
        0.04229717701673508,
        0.05044889450073242,
        0.014956213533878326,
        -0.05149705335497856,
        0.05533996969461441,
        -0.011139570735394955,
        -0.003993238788098097,
        0.04125649482011795,
        -0.05360152944922447,
        0.05229710415005684,
        -0.05594911053776741,
        0.028152739629149437,
        -0.05142135173082352,
        0.04040353000164032,
        -0.026088455691933632,
        0.05377301201224327,
        0.055766746401786804,
        -0.0514158196747303,
        -0.0488254688680172,
        0.03764843940734863,
        -0.008825388737022877,
        -0.047559261322021484,
        -0.021936887875199318,
        -0.04772058501839638,
        0.0037923683412373066,
        0.05577603727579117,
        0.04477735608816147,
        0.055959828197956085,
        -0.055782269686460495,
        0.03312457352876663,
        -0.026869401335716248,
        -0.0518488734960556,
        -0.053108785301446915,
        0.02441694773733616,
        0.055844441056251526,
        0.055046793073415756,
        0.03464873135089874,
        0.047423556447029114,
        0.0551215261220932,
        0.050937023013830185,
        -0.055339518934488297,
        -0.05324937030673027,
        -0.01847090944647789,
        -0.055651187896728516,
        0.04211151972413063,
        0.048832815140485764,
        0.004365621134638786,
        0.042469318956136703,
        -0.047420043498277664,
        0.05134759843349457,
        -0.024851292371749878,
        -0.02064443752169609,
        -0.00822108332067728,
        -0.05550430715084076,
        0.05482795462012291,
        -0.0061918445862829685,
        0.055210888385772705,
        -0.055360764265060425,
        -0.0036247617099434137,
        -0.04191073030233383,
        0.018667355179786682,
        -0.05158548057079315,
        0.04685624688863754,
        -0.036207057535648346,
        0.05187927931547165,
        -0.022330623120069504,
        -0.0545637346804142,
        -0.05513272061944008,
        -0.05060025304555893,
        -0.05217795446515083,
        -0.05119956284761429,
        0.03347363695502281,
        0.05445122718811035,
        0.04722853749990463,
        0.055483702570199966,
        -0.030538197606801987,
        -0.02689354307949543,
        0.05591011047363281,
        0.01395606528967619,
        0.04442494735121727,
        0.04411573335528374,
        -0.05606440082192421,
        0.0558277890086174,
        0.04110109806060791,
        -0.05284719169139862,
        0.05233556777238846,
        -0.018227800726890564,
        -0.05454649776220322,
        -0.05552136152982712,
        -0.04370131716132164,
        0.05269654095172882,
        -0.05549388751387596,
        0.01632705144584179,
        0.05350824445486069,
        0.055847130715847015,
        -0.04988809674978256,
        0.047782957553863525,
        0.021905863657593727,
        -0.03991798684000969,
        -0.0480230338871479,
        -0.052782125771045685,
        0.05002450570464134,
        -0.05078253149986267,
        0.0554194301366806,
        -0.020582158118486404,
        0.012813393957912922,
        0.03917216882109642,
        -0.027833810076117516,
        0.04938338324427605,
        -0.053852617740631104,
        0.056047484278678894,
        -0.055703356862068176,
        -0.03607577830553055,
        0.00288719916716218,
        -0.04723368212580681,
        0.05556628108024597,
        -0.05359674617648125,
        0.0020517869852483273,
        -0.05600105598568916,
        0.02393316477537155,
        -0.04702106863260269,
        0.05573859065771103,
        0.049235373735427856,
        0.05605607107281685,
        0.00678689731284976,
        0.04915184900164604,
        0.044759929180145264,
        -0.020534511655569077,
        -0.036065723747015,
        0.021249381825327873,
        -0.04165973514318466,
        0.04752436280250549,
        0.04778977110981941,
        -0.05378404259681702,
        0.05579887330532074,
        0.04950680956244469,
        -0.05606893450021744,
        0.05571584403514862,
        0.037728115916252136,
        0.05373189225792885,
        -0.028971180319786072,
        0.054807696491479874,
        0.05214238539338112,
        -0.032824333757162094,
        -0.05364491418004036,
        0.05604700371623039,
        0.006054732482880354,
        -0.0536343976855278,
        0.05570679157972336,
        -0.03359392285346985,
        0.054416656494140625,
        0.055440403521060944,
        -0.030382459983229637,
        -0.055692363530397415,
        -0.0034571888390928507,
        0.0559144951403141,
        0.05384591221809387,
        -0.021907875314354897,
        0.0519835501909256,
        -0.05234488472342491,
        0.05255361646413803,
        0.022746020928025246,
        0.055122196674346924,
        0.055754706263542175,
        0.05592816323041916,
        -0.05596723034977913,
        -0.03158818930387497,
        -0.049639470875263214,
        -0.04208073019981384,
        0.03093085065484047,
        0.054388634860515594,
        0.054900161921978,
        0.05324177443981171,
        0.055458229035139084,
        -0.0070250509306788445,
        0.050304267555475235,
        0.03479067608714104,
        -0.00798522587865591,
        -0.04832714423537254,
        -0.023794930428266525,
        -0.042320918291807175,
        -0.05446775630116463,
        0.05521577596664429,
        0.055557288229465485,
        0.04033605754375458,
        0.0551450252532959,
        -0.0019445528741925955,
        0.054386284202337265,
        0.009438109584152699,
        0.05595223605632782,
        0.018332786858081818,
        -0.03482937440276146,
        0.008257809095084667,
        0.053951431065797806,
        -0.055992815643548965,
        -0.052105862647295,
        -0.018427247181534767,
        -0.043431345373392105,
        0.0518510565161705,
        -0.010376064106822014,
        -0.03443845734000206,
        -0.04863397404551506,
        -0.04970666766166687,
        -0.054017044603824615,
        0.05545879155397415,
        -0.03730526193976402,
        0.05238870903849602,
        -0.055782921612262726,
        -0.01605844870209694,
        0.021491849794983864,
        0.05594173073768616,
        -0.05485386773943901,
        0.05225132033228874,
        0.03894948959350586,
        0.0039251623675227165,
        -0.05296816676855087,
        0.05278787761926651,
        -0.028678929433226585,
        0.0375143326818943,
        0.054254814982414246,
        -0.0556207001209259,
        -0.02198326587677002,
        0.054735925048589706,
        -0.041929941624403,
        -0.039657775312662125,
        -0.018438762053847313,
        -0.05149417370557785,
        -0.05604371428489685,
        -0.04939333721995354,
        0.03874051198363304,
        -0.05420700088143349,
        -0.010945061221718788,
        0.007365511264652014,
        0.05406705662608147,
        0.05468590930104256,
        -0.03978867083787918,
        -0.0062825786881148815,
        -0.05556747689843178,
        -0.032686300575733185,
        0.055177539587020874,
        -0.04597404971718788,
        0.05425199121236801,
        0.048470765352249146,
        -0.00548997987061739,
        0.05154663324356079,
        -0.005248995963484049,
        0.04994098097085953,
        -0.05286046490073204,
        -0.0106651671230793,
        -0.05556294694542885,
        -0.017175894230604172,
        -0.038608450442552567,
        0.03337305039167404,
        -0.03135797008872032,
        0.043765876442193985,
        -0.04030323028564453,
        0.05232802405953407,
        0.00902483705431223,
        0.055894073098897934,
        -0.05508428066968918,
        -0.05588874593377113,
        -0.05526675656437874,
        -0.009402803145349026,
        -0.008753281086683273,
        0.02309923619031906,
        -0.053674597293138504,
        -0.036670833826065063,
        -0.04490448161959648,
        -0.023662865161895752,
        0.04260538890957832,
        -0.05603035166859627,
        -0.019003307446837425,
        -0.043835822492837906,
        -0.04991266876459122,
        -0.05558367818593979,
        -0.04858667775988579,
        -0.04040877893567085,
        -0.053173456341028214,
        -0.054910145699977875,
        0.04981004446744919,
        0.036452192813158035,
        0.052546221762895584,
        -0.042231589555740356,
        -0.04421060159802437,
        0.054835062474012375,
        0.017736874520778656,
        0.05434853211045265,
        0.05605962872505188,
        -0.05591137334704399,
        0.03782712295651436,
        0.04305124655365944,
        -0.032347671687603,
        0.00572309922426939,
        -0.053620338439941406,
        -0.013034769333899021,
        -0.01581760309636593,
        -0.05086521804332733,
        -0.0021237810142338276,
        -0.01906365156173706,
        -0.05549454316496849,
        0.04844335466623306,
        -0.054241955280303955,
        0.051292311400175095,
        0.05600336566567421,
        -0.05543873459100723,
        0.05311105400323868,
        -0.009490140713751316,
        -0.055802471935749054,
        0.05590549856424332,
        0.05229566991329193,
        0.04633030667901039,
        0.056007396429777145,
        -0.011846578679978848,
        -0.03147821128368378,
        -0.05451167747378349,
        0.05347089841961861,
        -0.03163173422217369,
        -0.052008505910634995,
        -0.023678388446569443,
        0.0040880790911614895,
        0.052282385528087616,
        0.0555284284055233,
        -0.009241796098649502,
        0.04106835648417473,
        0.052193328738212585,
        0.04111754149198532,
        -0.05410504341125488,
        -0.025657666847109795,
        0.007355122361332178,
        -0.0556941032409668,
        0.024879297241568565,
        0.04816267639398575,
        -0.019576407968997955,
        0.030335035175085068,
        -0.05569374933838844,
        -0.04917559027671814,
        0.055162280797958374,
        -0.0035695333499461412,
        -0.035683490335941315,
        -0.005544282961636782,
        -0.0448899082839489,
        -0.02887054719030857,
        0.02469385601580143,
        0.022529058158397675,
        0.055577121675014496,
        -0.047273289412260056,
        0.03818219155073166,
        -0.055084895342588425,
        0.05378102883696556,
        -0.0010039848275482655
    ],
    [
        -0.053972724825143814,
        0.05123038962483406,
        -0.04400670528411865,
        0.03293834254145622,
        -0.03324054181575775,
        -0.04411279410123825,
        0.05561383068561554,
        -0.05483032763004303,
        0.05081729590892792,
        -0.05542905256152153,
        0.05443751811981201,
        0.05560043826699257,
        -0.05502457171678543,
        -0.03885727375745773,
        -0.05574950575828552,
        0.0390222929418087,
        0.047719806432724,
        0.05569968745112419,
        -0.008282013237476349,
        -0.053927961736917496,
        -0.003423352725803852,
        -0.005875293165445328,
        0.055852800607681274,
        -0.004782756324857473,
        0.022579170763492584,
        0.0492866151034832,
        0.05212025344371796,
        0.055873699486255646,
        -0.05579125136137009,
        -0.05337647721171379,
        0.041750792413949966,
        -0.05448991432785988,
        0.0292708370834589,
        -0.05522754788398743,
        0.049498919397592545,
        0.007254275493323803,
        -0.054737769067287445,
        0.020798593759536743,
        -0.014185880310833454,
        -0.014107057824730873,
        0.017708782106637955,
        0.04097171500325203,
        0.0531490184366703,
        0.05382930487394333,
        0.05499223992228508,
        0.03174985945224762,
        0.05345555394887924,
        0.05548357963562012,
        0.05533953383564949,
        -0.05503064766526222,
        0.05568255856633186,
        0.01899348944425583,
        -0.050135836005210876,
        -0.020713474601507187,
        0.05493936315178871,
        -0.055586326867341995,
        -0.04862227290868759,
        0.04269334673881531,
        -0.04978572949767113,
        0.013106944039463997,
        -0.055776193737983704,
        0.03541605547070503,
        -0.052008938044309616,
        -0.04847466200590134,
        -0.0410599485039711,
        -0.05523691698908806,
        -0.05543552711606026,
        -0.05293593555688858,
        -0.044523924589157104,
        -0.04947270080447197,
        -0.04738069325685501,
        -0.04354460909962654,
        0.05466165393590927,
        0.053680986166000366,
        -0.05294167622923851,
        -0.008625006303191185,
        0.05434681847691536,
        0.05587571859359741,
        0.05292482301592827,
        0.011771486140787601,
        -0.04084919020533562,
        -0.053752463310956955,
        -0.05490787699818611,
        0.02841798961162567,
        0.04720691218972206,
        0.025005722418427467,
        -0.0459272526204586,
        -0.0533025786280632,
        -0.0218831617385149,
        0.05203605443239212,
        -0.030579127371311188,
        -0.04236266016960144,
        0.044328223913908005,
        -0.05540725588798523,
        -0.03150593861937523,
        0.030570490285754204,
        -0.05531088635325432,
        -0.05106881633400917,
        -0.03218654543161392,
        0.036818038672208786,
        -0.055860135704278946,
        0.02220918983221054,
        0.04384680464863777,
        0.05286377668380737,
        -0.003325503785163164,
        -0.038076531141996384,
        -0.03946659341454506,
        0.05219103768467903,
        -0.016039960086345673,
        0.05529307946562767,
        -0.055825211107730865,
        -0.04884716868400574,
        -0.03662075847387314,
        -0.05444173887372017,
        0.05505920574069023,
        -0.04649001359939575,
        0.03932476043701172,
        -0.052067872136831284,
        0.03622549772262573,
        -0.04330836981534958,
        -0.04968314990401268,
        0.018336312845349312,
        0.05024581775069237,
        -0.03364306315779686,
        -0.0542617104947567,
        0.017933472990989685,
        -0.05302274227142334,
        0.055457454174757004,
        0.05559990927577019,
        -0.025075774639844894,
        0.007985272444784641,
        -0.05409786477684975,
        -0.02190624549984932,
        0.05311141163110733,
        -0.04985257238149643,
        -0.05530038848519325,
        0.052175384014844894,
        -0.03900366276502609,
        -0.05579414218664169,
        0.038658853620290756,
        0.04039425030350685,
        0.0501553975045681,
        0.053376346826553345,
        0.04050995036959648,
        0.029335027560591698,
        0.05132856220006943,
        0.0558268241584301,
        0.05195385217666626,
        -0.05414111539721489,
        -0.025187233462929726,
        0.046512432396411896,
        -0.05547458678483963,
        -0.051158227026462555,
        -0.053891371935606,
        -0.0509953498840332,
        -0.051167454570531845,
        0.015435666777193546,
        -0.032195355743169785,
        -0.053431615233421326,
        0.03429294750094414,
        -0.029764577746391296,
        0.021949412301182747,
        0.05201710760593414,
        -0.047402650117874146,
        0.004637052305042744,
        -0.054932013154029846,
        0.05362137779593468,
        -0.04223311319947243,
        0.03876500576734543,
        0.03683802857995033,
        -0.055364251136779785,
        -0.040589168667793274,
        -0.05586468055844307,
        0.041936244815588,
        0.05220310017466545,
        0.04574796184897423,
        -0.03762776404619217,
        -0.02291969023644924,
        0.05582500994205475,
        -0.0544736385345459,
        -0.05285045504570007,
        -0.031500693410634995,
        0.03774512931704521,
        -0.05267365649342537,
        0.050923384726047516,
        -0.007665453013032675,
        0.03711150214076042,
        0.05510426685214043,
        -0.0012741910759359598,
        -0.04564252123236656,
        -0.05570296570658684,
        -0.03792699798941612,
        -0.0006609680713154376,
        0.04187138006091118,
        -0.04738772287964821,
        -0.013924817554652691,
        0.054364703595638275,
        -0.023051999509334564,
        0.037337854504585266,
        0.05150479078292847,
        0.0424453429877758,
        0.054789114743471146,
        -0.021754760295152664,
        -0.03990571200847626,
        0.006595615763217211,
        -0.0017229055520147085,
        0.044099561870098114,
        0.03882662579417229,
        -0.05413006246089935,
        -0.004199497401714325,
        -0.05545896664261818,
        0.02747858129441738,
        0.04626230522990227,
        0.0020959770772606134,
        0.05268193036317825,
        -0.04625973105430603,
        0.05119124427437782,
        -0.0009702704264782369,
        0.05404243245720863,
        -0.05581147223711014,
        -0.04965637996792793,
        -0.015147289261221886,
        -0.05493893474340439,
        -0.04531184583902359,
        0.04089517518877983,
        -0.04381021112203598,
        0.05070798099040985,
        0.046360574662685394,
        -0.04805290699005127,
        -0.05558416619896889,
        -0.048868611454963684,
        0.013089777901768684,
        -0.052275337278842926,
        0.036453358829021454,
        0.053733840584754944,
        0.05541550740599632,
        0.054279979318380356,
        -0.029337918385863304,
        -0.016419874504208565,
        0.05586332082748413,
        0.05043268948793411,
        0.05509442463517189,
        0.052188772708177567,
        -0.052183352410793304,
        0.0521070770919323,
        -0.008711719885468483,
        -0.05369316413998604,
        -0.04906809329986572,
        -0.03920910507440567,
        -0.055646978318691254,
        -0.050181008875370026,
        -0.04552995413541794,
        0.04919078201055527,
        -0.05532253906130791,
        0.054800767451524734,
        0.05168290063738823,
        -0.05451512336730957,
        -0.0502212792634964,
        0.047527868300676346,
        0.0029586483724415302,
        0.025361981242895126,
        -0.049406327307224274,
        -0.055656637996435165,
        0.047576043754816055,
        -0.05369759723544121,
        0.05282217636704445,
        0.04430036619305611,
        -0.04646947234869003,
        0.042695097625255585,
        -0.015854351222515106,
        -0.018108490854501724,
        -0.05310027301311493,
        0.055692728608846664,
        -0.053126413375139236,
        0.046782657504081726,
        0.03841781988739967,
        -0.04629550129175186,
        0.01547706127166748,
        0.01688351295888424,
        0.05153718218207359,
        -0.055733852088451385,
        -0.00016061369387898594,
        -0.049799844622612,
        0.05486803501844406,
        -0.004169204737991095,
        0.04307985678315163,
        -0.028846703469753265,
        0.009818038903176785,
        -0.015089865773916245,
        -0.028320230543613434,
        -0.055410418659448624,
        0.03707394003868103,
        0.0018930918304249644,
        -0.013247172348201275,
        0.05190785601735115,
        -0.05489328131079674,
        0.055806905031204224,
        0.05530714988708496,
        -0.05454006791114807,
        0.05525759980082512,
        0.05005877465009689,
        0.04801030829548836,
        -0.03070353902876377,
        0.036053724586963654,
        0.04915701970458031,
        0.00799925159662962,
        -0.04724008962512016,
        0.05542241781949997,
        0.030576329678297043,
        -0.0554017499089241,
        0.05500119924545288,
        -0.026792874559760094,
        0.029391992837190628,
        0.05440354347229004,
        -0.039902761578559875,
        -0.05559871718287468,
        -0.03045041114091873,
        0.05584796518087387,
        0.04720276594161987,
        0.0029225079342722893,
        -0.05574750900268555,
        0.02059582993388176,
        -0.013854880817234516,
        -0.0029341180343180895,
        0.055840156972408295,
        0.05370473116636276,
        0.05480183660984039,
        0.032933201640844345,
        -0.004595883656293154,
        -0.04798215255141258,
        -0.022612087428569794,
        -0.04333293437957764,
        0.053768765181303024,
        -0.003135305130854249,
        0.05187039449810982,
        0.000999600044451654,
        -0.0495125912129879,
        0.052601516246795654,
        -0.04623578116297722,
        -0.03197479993104935,
        -0.03477653115987778,
        -0.03191843256354332,
        0.04074712470173836,
        -0.05470592901110649,
        0.055793192237615585,
        0.055070534348487854,
        0.049357566982507706,
        0.055417053401470184,
        -0.0061949556693434715,
        0.049537453800439835,
        0.05574396625161171,
        0.0550047867000103,
        0.011430121026933193,
        -0.02784273587167263,
        -0.030998470261693,
        0.0300938431173563,
        -0.05584586039185524,
        -0.054979078471660614,
        -0.05129610002040863,
        -0.013224233873188496,
        0.05221277475357056,
        0.05032646283507347,
        -0.052929289638996124,
        -0.052682485431432724,
        0.0020177022088319063,
        -0.055268604308366776,
        0.054813459515571594,
        -0.01504341047257185,
        0.05401870980858803,
        -0.055452462285757065,
        -0.018143832683563232,
        -0.04935489594936371,
        0.05049850791692734,
        -0.05141926184296608,
        0.05430593341588974,
        0.0555478110909462,
        -0.006147424224764109,
        -0.007943798787891865,
        0.0545661523938179,
        -0.033995117992162704,
        -0.007152111269533634,
        0.05026153102517128,
        -0.05424986034631729,
        0.01512366533279419,
        0.046331506222486496,
        -0.048345547169446945,
        -0.022894371300935745,
        -0.04607357457280159,
        -0.053503088653087616,
        -0.055309366434812546,
        -0.042134806513786316,
        -0.027986891567707062,
        -0.05459964647889137,
        -0.05244596675038338,
        -0.038207799196243286,
        0.055581845343112946,
        0.04872020706534386,
        -0.044916070997714996,
        -0.028377331793308258,
        -0.05445912107825279,
        -0.04450732842087746,
        0.052459776401519775,
        0.05264167860150337,
        0.055298637598752975,
        0.043396513909101486,
        0.01503648329526186,
        0.05216135457158089,
        -0.03617456927895546,
        0.05138089880347252,
        -0.03730567544698715,
        -0.03843473270535469,
        0.0025536855682730675,
        0.03843448683619499,
        0.0184396430850029,
        0.02303662709891796,
        -0.051991745829582214,
        -0.05129420757293701,
        -0.015173612162470818,
        0.05532084032893181,
        0.0539579764008522,
        0.05526910349726677,
        -0.054107341915369034,
        -0.05553050711750984,
        -0.054181620478630066,
        -0.03130645677447319,
        0.0008723034407012165,
        -0.052609942853450775,
        0.008483157493174076,
        -0.04094979912042618,
        -0.05402342602610588,
        0.0555378794670105,
        -0.04496638849377632,
        0.03278207406401634,
        -0.0027930382639169693,
        0.012567597441375256,
        -0.0539579913020134,
        -0.053804587572813034,
        0.055691178888082504,
        -0.021584711968898773,
        -0.05482834577560425,
        0.03148020803928375,
        0.012071933597326279,
        0.04536507651209831,
        0.05371405929327011,
        -0.01815391331911087,
        -0.0017036598874256015,
        0.048592232167720795,
        -0.02364630065858364,
        0.04552670568227768,
        0.054676249623298645,
        -0.03164184093475342,
        -0.027583150193095207,
        0.035900264978408813,
        -0.03567185252904892,
        -0.008167044259607792,
        -0.04753396660089493,
        -0.03644847869873047,
        -0.01161289308220148,
        -0.05228164792060852,
        0.026846325024962425,
        0.03722579777240753,
        -0.05327463150024414,
        -0.046788543462753296,
        -0.054709091782569885,
        0.053295496851205826,
        0.05562281236052513,
        -0.05218818411231041,
        0.05476672574877739,
        -0.052823297679424286,
        -0.055682308971881866,
        0.05477543920278549,
        -0.00510859489440918,
        0.036313436925411224,
        0.05514177307486534,
        0.05517921596765518,
        -0.03157147020101547,
        -0.013108741492033005,
        -0.026886675506830215,
        -0.04115317016839981,
        -0.05570472031831741,
        0.05487842857837677,
        -0.036488618701696396,
        0.0556478276848793,
        0.05253572389483452,
        -0.05463248863816261,
        0.048326458781957626,
        0.054830387234687805,
        0.04545217007398605,
        -0.05403847247362137,
        -0.03701108694076538,
        -0.05352029204368591,
        -0.01971706934273243,
        0.04603315144777298,
        0.0425802581012249,
        -0.036778610199689865,
        0.04585358500480652,
        -0.036608483642339706,
        -0.052322156727313995,
        0.055523134768009186,
        0.05281023308634758,
        -0.05330313742160797,
        0.0428762286901474,
        -0.016357578337192535,
        -0.05485200881958008,
        0.0550973117351532,
        0.020338352769613266,
        0.050768088549375534,
        -0.03657984361052513,
        0.047788869589567184,
        -0.05584363639354706,
        0.018720567226409912,
        0.04194078594446182
    ],
    [
        -0.04435494914650917,
        0.050977740436792374,
        -0.021737273782491684,
        -0.04269489645957947,
        -0.05048056319355965,
        -0.03948252648115158,
        0.014467919245362282,
        -0.0529974065721035,
        0.052974339574575424,
        -0.022849997505545616,
        0.05079430714249611,
        0.04459262639284134,
        -0.05294902250170708,
        0.0350244976580143,
        -0.05282144621014595,
        0.050873104482889175,
        0.0525810681283474,
        0.045248061418533325,
        0.026785606518387794,
        0.02210129424929619,
        0.038677215576171875,
        0.021215328946709633,
        0.04940930753946304,
        0.050604674965143204,
        0.028934555128216743,
        0.052667032927274704,
        0.02605021558701992,
        0.05091630294919014,
        -0.045697275549173355,
        -0.03302178904414177,
        -0.008835883811116219,
        -0.0479002371430397,
        -0.0529630109667778,
        -0.05098889023065567,
        0.05238409340381622,
        -0.05057040601968765,
        -0.05237043648958206,
        0.020494211465120316,
        0.02810097299516201,
        0.05095444247126579,
        0.052772168070077896,
        0.036917783319950104,
        0.05257207527756691,
        0.04892764240503311,
        0.04223628342151642,
        -0.047556374222040176,
        0.052859269082546234,
        0.052928149700164795,
        0.05283370241522789,
        0.02123338170349598,
        0.05278192460536957,
        0.04738055169582367,
        -0.003430589335039258,
        -0.03724651411175728,
        0.05284526199102402,
        -0.05293165519833565,
        0.047071896493434906,
        0.02841215394437313,
        -0.04998258128762245,
        -0.04809406027197838,
        -0.05292714014649391,
        -0.027323784306645393,
        -0.052798885852098465,
        -0.018666947260499,
        -0.019049430266022682,
        -0.05267258360981941,
        -0.049061670899391174,
        -0.03106745518743992,
        0.05172890052199364,
        -0.05299816653132439,
        0.013990504667162895,
        -0.05223371088504791,
        0.05293574556708336,
        0.04149935021996498,
        -0.04600967839360237,
        -0.04908143728971481,
        0.0528109110891819,
        0.05302669107913971,
        0.052172619849443436,
        0.04856916517019272,
        -0.02698894403874874,
        -0.040774866938591,
        -0.05288076773285866,
        0.05268079414963722,
        -0.04995086044073105,
        0.050977908074855804,
        -0.05199553072452545,
        -0.0483175553381443,
        -0.006252502091228962,
        0.05257090926170349,
        -0.051026780158281326,
        -0.05038151890039444,
        -0.049374666064977646,
        -0.05274641141295433,
        -0.04901447147130966,
        0.05015014111995697,
        0.0005227124784141779,
        -0.04910474270582199,
        -0.038103703409433365,
        0.052737146615982056,
        -0.05300772190093994,
        0.05157703533768654,
        0.05290958657860756,
        0.052915673702955246,
        0.04538635537028313,
        -0.05287986993789673,
        -0.05287089943885803,
        0.05222479626536369,
        -0.048889972269535065,
        0.003213375573977828,
        -0.040357377380132675,
        0.004195578396320343,
        0.04761745408177376,
        0.0203630980104208,
        0.04979637265205383,
        0.05129757151007652,
        -0.00857933796942234,
        -0.0476888082921505,
        0.05182909965515137,
        -0.05074387788772583,
        -0.011640891432762146,
        -0.04124334454536438,
        0.03475755825638771,
        -0.052840568125247955,
        -0.05242833495140076,
        -0.03624037653207779,
        -0.04431293532252312,
        0.051788654178380966,
        0.050784409046173096,
        0.007064212579280138,
        -0.006084253080189228,
        -0.04671986773610115,
        -0.04927750676870346,
        0.05222019925713539,
        -0.052149828523397446,
        -0.052689019590616226,
        0.05302248150110245,
        -0.05263204500079155,
        -0.014230958186089993,
        -0.008281294256448746,
        -0.018382616341114044,
        -0.0324280671775341,
        0.052155978977680206,
        0.046161990612745285,
        0.035469602793455124,
        0.043260857462882996,
        0.046950194984674454,
        0.03861420974135399,
        0.0048885527066886425,
        0.05225833132863045,
        0.01145238894969225,
        -0.05301671847701073,
        -0.050590988248586655,
        -0.012227549217641354,
        -0.007625810336321592,
        -0.02447599358856678,
        0.02683236636221409,
        0.029762446880340576,
        0.052959490567445755,
        -0.026086699217557907,
        -0.052457988262176514,
        -0.04900886118412018,
        0.05252872407436371,
        0.05028789862990379,
        0.052716899663209915,
        0.009597004391252995,
        0.052936941385269165,
        0.01724950782954693,
        -0.050982266664505005,
        0.02516699582338333,
        -0.05254280939698219,
        0.013265739195048809,
        -0.0530005544424057,
        0.05013542249798775,
        0.05109606683254242,
        0.049946751445531845,
        -0.034357428550720215,
        -0.0515112541615963,
        0.05249365419149399,
        -0.05132273957133293,
        -0.05278392881155014,
        0.035467479377985,
        0.05125793069601059,
        0.02268296107649803,
        0.02897273190319538,
        -0.05298752710223198,
        0.05202454701066017,
        0.05242721736431122,
        -0.045805055648088455,
        -0.037949081510305405,
        -0.05280519276857376,
        0.04617270082235336,
        0.02769201248884201,
        0.05211558938026428,
        -0.02964593842625618,
        -0.008881393820047379,
        0.01657838374376297,
        0.05279524624347687,
        -0.04861099645495415,
        0.05263833701610565,
        0.04309779405593872,
        0.05144369602203369,
        -0.04292692244052887,
        -0.04182227700948715,
        0.036593735218048096,
        -0.05289587005972862,
        0.051477063447237015,
        -0.031291376799345016,
        -0.05242903158068657,
        -0.03497188165783882,
        -0.052493274211883545,
        0.05279843136668205,
        -0.03851044178009033,
        0.051332373172044754,
        0.04502632096409798,
        -0.05062638595700264,
        0.05299987271428108,
        0.049310337752103806,
        0.05298773944377899,
        -0.052909765392541885,
        0.050625186413526535,
        0.05171939730644226,
        0.01666603796184063,
        -0.041806332767009735,
        0.052673619240522385,
        -0.04749112203717232,
        0.05210491642355919,
        -0.013971528969705105,
        0.036421507596969604,
        -0.05275849252939224,
        -0.04486769065260887,
        -0.05287120118737221,
        -0.00714174285531044,
        0.050299763679504395,
        0.046102236956357956,
        0.05299006775021553,
        0.052693650126457214,
        -0.011532210744917393,
        -0.044531647115945816,
        0.05301876738667488,
        -0.019454283639788628,
        0.04632396996021271,
        0.03663320094347,
        -0.05302257463335991,
        0.052731141448020935,
        0.04498763754963875,
        -0.02212604507803917,
        0.02254418283700943,
        -0.023180894553661346,
        -0.04990731552243233,
        -0.02885803021490574,
        -0.050526250153779984,
        0.05302618443965912,
        -0.052315860986709595,
        0.03961486369371414,
        0.03694695606827736,
        0.05203888937830925,
        -0.047752849757671356,
        0.05246910825371742,
        -0.01368712354451418,
        -0.052911922335624695,
        0.01095983199775219,
        -0.0528096966445446,
        0.04620841518044472,
        -0.05280105769634247,
        0.05300260707736015,
        0.05295993387699127,
        0.03127932548522949,
        -0.006955580785870552,
        -0.02753681130707264,
        0.041488636285066605,
        -0.05156122148036957,
        0.05301956087350845,
        -0.052470725029706955,
        0.05169098824262619,
        0.0468236468732357,
        -0.05252392590045929,
        -0.04109541326761246,
        -0.04067745432257652,
        0.04252675920724869,
        -0.05168469250202179,
        0.05109892785549164,
        -0.046534083783626556,
        0.03899902105331421,
        0.04862978681921959,
        -0.009035550057888031,
        -0.03814658522605896,
        0.04786552116274834,
        0.046329937875270844,
        -0.0038111545145511627,
        -0.0529441237449646,
        0.027653537690639496,
        -0.051629506051540375,
        -0.02088823728263378,
        0.04638669267296791,
        -0.052718039602041245,
        0.052871085703372955,
        0.05235302820801735,
        -0.04376419261097908,
        0.05241573974490166,
        0.05114496126770973,
        0.0431620329618454,
        -0.03689349815249443,
        0.045606616884469986,
        0.05267670378088951,
        -0.014832623302936554,
        -0.04301181063055992,
        0.05301477015018463,
        0.03571688383817673,
        -0.052313562482595444,
        -0.04940580204129219,
        -0.05275765433907509,
        0.05287062004208565,
        0.0502212718129158,
        -0.04999547451734543,
        -0.05278703570365906,
        -0.049276210367679596,
        0.053018856793642044,
        0.05216999351978302,
        -0.022070879116654396,
        -0.04121173918247223,
        0.039000608026981354,
        0.05163220688700676,
        -0.04769734665751457,
        0.0526391826570034,
        0.052105434238910675,
        0.052519943565130234,
        0.027567008510231972,
        0.008734547533094883,
        -0.05225801467895508,
        0.05235228314995766,
        -0.05285905301570892,
        0.051237933337688446,
        0.04928632825613022,
        0.05230529606342316,
        0.052488088607788086,
        -0.024614958092570305,
        0.04281144589185715,
        0.05014009773731232,
        0.04771256446838379,
        -0.016277693212032318,
        -0.052662692964076996,
        0.05302610248327255,
        -0.0312802754342556,
        0.05241432040929794,
        0.02508782595396042,
        0.05246169492602348,
        0.05246362462639809,
        -0.05244491621851921,
        0.04150049388408661,
        0.052525755017995834,
        0.05269807204604149,
        0.04103924334049225,
        -0.052966050803661346,
        -0.05290966480970383,
        -0.018559835851192474,
        -0.053025681525468826,
        -0.05160752311348915,
        -0.052857112139463425,
        -0.012635715305805206,
        0.05232592672109604,
        0.04412682726979256,
        0.006064159329980612,
        -0.050379641354084015,
        -0.038201704621315,
        -0.05300295725464821,
        0.05252930149435997,
        -0.03688841685652733,
        -0.008035355247557163,
        -0.05295732617378235,
        -0.03169441223144531,
        -0.044507961720228195,
        0.0506051667034626,
        0.001414261874742806,
        0.05014277622103691,
        0.05302570387721062,
        0.041266363114118576,
        0.05203736200928688,
        0.052883416414260864,
        -0.03830504044890404,
        -0.04759930446743965,
        -0.02224135957658291,
        -0.051335837692022324,
        -0.04356514662504196,
        0.012870606034994125,
        -0.053025443106889725,
        -0.029901638627052307,
        -0.022554539144039154,
        -0.046666067093610764,
        -0.052811890840530396,
        -0.05284825712442398,
        -0.02751944214105606,
        -0.050593551248311996,
        -0.05300016701221466,
        0.016745153814554214,
        0.052982691675424576,
        0.0521460697054863,
        -0.049529626965522766,
        -0.052975043654441833,
        -0.049793362617492676,
        -0.04832879453897476,
        0.047435298562049866,
        -0.046055980026721954,
        -0.009896790608763695,
        -0.007855086587369442,
        0.05097184330224991,
        0.028545916080474854,
        -0.05009828507900238,
        0.052680835127830505,
        0.029520714655518532,
        -0.03208322077989578,
        -0.004137539770454168,
        -0.027086354792118073,
        0.05048904940485954,
        0.021165626123547554,
        -0.018070770427584648,
        -0.022304991260170937,
        0.04014356806874275,
        0.05291714519262314,
        0.05092475190758705,
        0.05295398831367493,
        0.004668544977903366,
        -0.052963897585868835,
        -0.05302610620856285,
        -0.04662027955055237,
        -0.04965110123157501,
        -0.05032564699649811,
        -0.05206095054745674,
        -0.04278961569070816,
        -0.05299536883831024,
        -0.026745198294520378,
        -0.052663423120975494,
        -0.0498083271086216,
        -0.02272065356373787,
        -0.05172672122716904,
        -0.0482693649828434,
        -0.05271176993846893,
        0.052916694432497025,
        -0.034800417721271515,
        -0.0333070382475853,
        0.02525349333882332,
        0.05064677819609642,
        0.04801823943853378,
        0.052653759717941284,
        -0.04700860008597374,
        -0.03829558566212654,
        0.05229119211435318,
        -0.0027110069058835506,
        0.05124236270785332,
        0.05299587547779083,
        -0.0028418400324881077,
        0.05281056463718414,
        -0.0040214587934315205,
        0.03421662002801895,
        0.05248064547777176,
        -0.05295944958925247,
        -0.0320458747446537,
        -0.008216453716158867,
        0.04575696587562561,
        0.0233305711299181,
        0.049776386469602585,
        -0.042432766407728195,
        -0.033268388360738754,
        0.044672880321741104,
        0.05286623165011406,
        0.05291023105382919,
        -0.05294568091630936,
        0.053000859916210175,
        -0.02346249483525753,
        -0.052770957350730896,
        0.0528704933822155,
        0.016894763335585594,
        0.050501156598329544,
        -0.04167286679148674,
        -0.026502124965190887,
        0.031510721892118454,
        -0.0463731475174427,
        0.009499036706984043,
        -0.053003814071416855,
        -0.05262083560228348,
        0.014586685225367546,
        -0.01500671822577715,
        0.047062840312719345,
        0.048136863857507706,
        -0.04743076115846634,
        0.0523519329726696,
        0.05229185149073601,
        0.047356925904750824,
        -0.05210896581411362,
        -0.04819624125957489,
        -0.05106593668460846,
        -0.05302439630031586,
        0.0447496697306633,
        0.05114908516407013,
        -0.052661146968603134,
        0.052637387067079544,
        -0.016171887516975403,
        -0.05289903283119202,
        0.052681539207696915,
        0.039705704897642136,
        -0.03907638043165207,
        0.05152704939246178,
        -0.03390884771943092,
        -0.05277184024453163,
        0.05249440670013428,
        0.05062342807650566,
        0.05278143659234047,
        -0.03775661438703537,
        0.052544452250003815,
        0.029598599299788475,
        0.052977096289396286,
        -0.00015785098366905004
    ],
    [
        0.05788958817720413,
        0.05571708083152771,
        -0.05672243610024452,
        -0.023203140124678612,
        -0.05181443691253662,
        -0.04157223179936409,
        0.04273951053619385,
        -0.05541408434510231,
        0.05264635384082794,
        0.047959957271814346,
        0.018989725038409233,
        0.04792128875851631,
        -0.05684930086135864,
        -0.055770888924598694,
        -0.054595328867435455,
        0.04279467836022377,
        0.0308940839022398,
        0.049174074083566666,
        0.0575118362903595,
        -0.04508106783032417,
        0.0467790886759758,
        0.056021325290203094,
        0.05506296083331108,
        0.0334634967148304,
        -0.034088362008333206,
        0.04904419928789139,
        -0.004592977464199066,
        0.05635106936097145,
        -0.0468701496720314,
        0.05798327922821045,
        0.006255587097257376,
        -0.056217774748802185,
        -0.01916643977165222,
        -0.05722793936729431,
        0.031188122928142548,
        -0.025388747453689575,
        -0.05748300999403,
        -0.01769675873219967,
        0.05695177987217903,
        -0.052234940230846405,
        0.05363476276397705,
        0.057904213666915894,
        0.04528833553195,
        0.04596960172057152,
        -0.0252898707985878,
        -0.0376388318836689,
        -0.040277332067489624,
        0.058059707283973694,
        0.057802487164735794,
        0.03657647967338562,
        0.05334216356277466,
        0.054279979318380356,
        0.027193384245038033,
        0.0009749141172505915,
        -0.028332704678177834,
        -0.01427331380546093,
        0.04020417109131813,
        0.0004696954565588385,
        0.04308447986841202,
        -0.03914549946784973,
        -0.05718380957841873,
        -0.009790978394448757,
        -0.04860139638185501,
        0.02869056724011898,
        0.025804243981838226,
        -0.04347369447350502,
        -0.05762028321623802,
        -0.01979108713567257,
        0.05518527328968048,
        -0.05482715740799904,
        0.0019637930672615767,
        -0.055507685989141464,
        0.04174479469656944,
        0.0031506733503192663,
        -0.05384383723139763,
        -0.041489291936159134,
        0.05312890186905861,
        0.05819166824221611,
        -0.04857463017106056,
        0.0535581074655056,
        -0.043628741055727005,
        -0.017913419753313065,
        -0.05726458504796028,
        0.031018583104014397,
        0.011799017898738384,
        0.0556572750210762,
        -0.04381632059812546,
        -0.05778215080499649,
        0.02375599928200245,
        0.040980253368616104,
        -0.05301804468035698,
        0.01408083550632,
        -0.05097869038581848,
        -0.05471421033143997,
        -0.025582335889339447,
        0.045568011701107025,
        -0.053584665060043335,
        -0.05322619155049324,
        -0.05560261756181717,
        0.045834630727767944,
        -0.057797376066446304,
        0.05753715708851814,
        0.03656085953116417,
        0.05697786062955856,
        -0.05731735751032829,
        -0.05028852820396423,
        -0.045449480414390564,
        -0.026069369167089462,
        -0.015830710530281067,
        0.012385355308651924,
        -0.04047778993844986,
        -0.039530035108327866,
        -0.045733384788036346,
        0.05412934720516205,
        0.047904469072818756,
        0.05721237510442734,
        0.00745585048571229,
        -0.038102664053440094,
        -0.001753996592015028,
        0.04281236231327057,
        -0.054967254400253296,
        -0.011373437941074371,
        0.04638153687119484,
        -0.023349501192569733,
        -0.018739549443125725,
        0.037004876881837845,
        -0.05763036757707596,
        0.05276919901371002,
        0.05816331505775452,
        0.022868383675813675,
        0.038807887583971024,
        -0.04660681262612343,
        -0.04387828707695007,
        0.055278174579143524,
        -0.04011944308876991,
        -0.05763987451791763,
        0.05764138698577881,
        0.05541842430830002,
        -0.04977641627192497,
        -0.022418249398469925,
        -0.0035996749065816402,
        0.026705335825681686,
        0.05476536601781845,
        0.056829143315553665,
        0.05478791519999504,
        -0.03336045891046524,
        0.005251840688288212,
        0.006283687427639961,
        0.01400869432836771,
        0.05480843037366867,
        0.05152519792318344,
        -0.05411447212100029,
        -0.007107096258550882,
        -0.04705436900258064,
        -0.04936373978853226,
        0.02782314084470272,
        -0.044637732207775116,
        -0.03191577270627022,
        0.056675467640161514,
        0.05701645836234093,
        -0.05252780765295029,
        0.008628882467746735,
        0.052635788917541504,
        0.05712264031171799,
        -0.02097015455365181,
        -0.02366340346634388,
        0.057367388159036636,
        -0.05480842664837837,
        0.0004866983799729496,
        0.01689189486205578,
        -0.05623488500714302,
        -0.057360243052244186,
        -0.05229142680764198,
        0.05694739148020744,
        0.04785609617829323,
        0.04897739365696907,
        -0.020133374258875847,
        0.015421264804899693,
        0.05364261195063591,
        -0.05025273188948631,
        -0.0401509664952755,
        -0.008410776033997536,
        0.05360583961009979,
        -0.04771663621068001,
        0.05689387395977974,
        -0.048854511231184006,
        -0.021551216021180153,
        0.05506184324622154,
        -0.057846151292324066,
        0.029026301577687263,
        -0.020511388778686523,
        0.046352554112672806,
        0.047123633325099945,
        0.05812767148017883,
        -0.025753457099199295,
        -0.05346589908003807,
        -0.024644359946250916,
        0.015431542880833149,
        0.019008595496416092,
        0.05717676505446434,
        -0.012653768062591553,
        0.030257759615778923,
        0.03710303083062172,
        -0.039914071559906006,
        0.0553206168115139,
        -0.057120151817798615,
        0.04013010859489441,
        0.011728567071259022,
        -0.049444347620010376,
        0.05579771101474762,
        -0.013134284876286983,
        0.046400848776102066,
        -0.01828121952712536,
        0.048406437039375305,
        0.05437548831105232,
        0.05087755247950554,
        0.017873790115118027,
        -0.02932104282081127,
        0.05710901319980621,
        0.01619570143520832,
        0.012601340189576149,
        0.05702027678489685,
        -0.058072712272405624,
        0.043925993144512177,
        0.05660073831677437,
        -0.03586621955037117,
        0.016048969700932503,
        -0.05636972188949585,
        0.008581184782087803,
        0.0052795750088989735,
        -0.05817513167858124,
        -0.04858577623963356,
        0.057220473885536194,
        0.05583033338189125,
        0.047200653702020645,
        0.05390749126672745,
        0.04649307578802109,
        -0.05065910145640373,
        -0.02602601982653141,
        0.0578908696770668,
        0.03353070467710495,
        0.05794331803917885,
        -0.03965254873037338,
        -0.05787080526351929,
        0.02490626648068428,
        0.009856975637376308,
        0.0352843701839447,
        0.026610802859067917,
        0.012025303207337856,
        -0.052140213549137115,
        -0.04537467285990715,
        -0.055116042494773865,
        0.05781610682606697,
        -0.055208828300237656,
        0.023664534091949463,
        0.037256162613630295,
        -0.025677965953946114,
        -0.045582160353660583,
        0.04609367251396179,
        0.049662359058856964,
        -0.01258774008601904,
        -0.040882669389247894,
        -0.05219165235757828,
        0.04014533758163452,
        0.05606137216091156,
        0.05760376900434494,
        0.057073887437582016,
        0.012685451656579971,
        -0.05453617870807648,
        0.046773526817560196,
        0.003655938897281885,
        -0.0532524436712265,
        0.05727609992027283,
        -0.05796930566430092,
        0.014719286933541298,
        0.015139305032789707,
        -0.04327169433236122,
        -0.013153128325939178,
        -0.05525446683168411,
        -0.04492365941405296,
        -0.016612710431218147,
        0.05176283419132233,
        -0.03361150994896889,
        0.004123852588236332,
        0.05023913085460663,
        0.03653229773044586,
        0.04949435591697693,
        0.035321976989507675,
        -0.028645236045122147,
        0.0539468489587307,
        -0.055103156715631485,
        0.036491770297288895,
        -0.03661768510937691,
        0.03613331541419029,
        0.05709322914481163,
        -0.05534270033240318,
        0.04829368367791176,
        0.05173897743225098,
        -0.029817230999469757,
        0.05531147122383118,
        -0.05774013325572014,
        0.02658168226480484,
        0.021367762237787247,
        0.04865971580147743,
        0.034688498824834824,
        0.026111679151654243,
        -0.011286035180091858,
        0.0577646903693676,
        0.0437016487121582,
        0.039468180388212204,
        0.002200271235778928,
        0.016226796433329582,
        0.014936767518520355,
        -0.031095286831259727,
        -0.03363199159502983,
        -0.05573910102248192,
        0.018117360770702362,
        0.058170586824417114,
        0.054475247859954834,
        -0.021492714062333107,
        -0.0569990873336792,
        0.02790161222219467,
        0.05552366375923157,
        0.030987801030278206,
        0.057926736772060394,
        0.027336832135915756,
        0.057879120111465454,
        -0.038159262388944626,
        0.031856782734394073,
        0.03791593387722969,
        -0.01133423950523138,
        -0.053633179515600204,
        0.05672873184084892,
        0.052673809230327606,
        0.055070534348487854,
        0.0460127517580986,
        0.004936243873089552,
        0.036530502140522,
        0.00936469528824091,
        0.044575124979019165,
        0.02183741144835949,
        -0.027529995888471603,
        0.05812538042664528,
        0.031008796766400337,
        -0.0473727211356163,
        0.00780258234590292,
        0.05159539729356766,
        0.052019864320755005,
        -0.026985103264451027,
        0.049082688987255096,
        0.057217709720134735,
        0.05253593623638153,
        0.02952360175549984,
        -0.0551193431019783,
        -0.05748138204216957,
        0.04023195430636406,
        -0.05793491005897522,
        -0.057707589119672775,
        -0.056637365370988846,
        -0.04057369381189346,
        0.0436319075524807,
        0.041259463876485825,
        0.05205494910478592,
        -0.05340123176574707,
        -0.05314551666378975,
        -0.05058206617832184,
        0.05791284143924713,
        -0.034355826675891876,
        -0.03008531592786312,
        -0.05621437355875969,
        -0.04710694029927254,
        0.0022192755714058876,
        -0.014154286123812199,
        0.013180414214730263,
        0.05467104911804199,
        0.052134785801172256,
        0.049721118062734604,
        -0.01492475438863039,
        0.056780651211738586,
        0.05680091306567192,
        0.025588268414139748,
        0.00934706348925829,
        -0.046475525945425034,
        -0.015560963191092014,
        0.045728735625743866,
        -0.05787533149123192,
        0.057410210371017456,
        -0.04536304995417595,
        -0.05385007709264755,
        -0.055829647928476334,
        -0.05807812884449959,
        0.0012944184709340334,
        -0.018832514062523842,
        -0.04623085632920265,
        0.02806340716779232,
        0.04567236453294754,
        0.05700649321079254,
        -0.045131437480449677,
        -0.057975884526968,
        -0.057107292115688324,
        -0.053699832409620285,
        0.058124471455812454,
        -0.03409639745950699,
        -0.05101544037461281,
        -0.049834027886390686,
        -0.01914902962744236,
        -0.0458545945584774,
        -0.055018432438373566,
        0.057694870978593826,
        -0.017808526754379272,
        -0.01240456011146307,
        0.01690448448061943,
        -0.026484377682209015,
        0.050656456500291824,
        0.05550391227006912,
        0.014130602590739727,
        -0.05421605333685875,
        0.030655765905976295,
        0.057239875197410583,
        0.047137387096881866,
        0.057591259479522705,
        0.056873880326747894,
        0.02405511401593685,
        -0.058017127215862274,
        0.044311147183179855,
        -0.021788839250802994,
        0.007518063765019178,
        0.05642350763082504,
        -0.05570504814386368,
        -0.05614254251122475,
        -0.01258186437189579,
        -0.03689393773674965,
        -0.03612837567925453,
        -0.05545870214700699,
        0.054319821298122406,
        -0.0500720851123333,
        -0.048259664326906204,
        -0.049128275364637375,
        0.006200874224305153,
        -0.0056586237624287605,
        -0.04816582798957825,
        -0.035902656614780426,
        0.014573631808161736,
        0.05600697174668312,
        0.00677282502874732,
        -0.010969300754368305,
        0.05740836635231972,
        -0.002939698752015829,
        0.03826066106557846,
        0.05772942304611206,
        -0.05818159505724907,
        0.05641201138496399,
        -0.008174892514944077,
        0.038948509842157364,
        0.04996955022215843,
        -0.05454951152205467,
        -0.03608262911438942,
        -0.043806467205286026,
        -0.011411307379603386,
        0.05608448386192322,
        0.05444161593914032,
        0.056799229234457016,
        0.04866442829370499,
        -0.01747593842446804,
        0.058035340160131454,
        0.05663997679948807,
        -0.05656227096915245,
        0.05455225706100464,
        0.03122308850288391,
        -0.05507354065775871,
        0.056723643094301224,
        -0.029920540750026703,
        0.04914049804210663,
        0.0038091572932899,
        -0.05808461084961891,
        0.05644223466515541,
        -0.05036246031522751,
        -0.0053117536008358,
        -0.05761374905705452,
        -0.0029722326435148716,
        0.053518105298280716,
        0.05478343367576599,
        0.009192332625389099,
        0.051747824996709824,
        -0.05463249236345291,
        0.049919623881578445,
        0.05737622082233429,
        0.05736440792679787,
        -0.0503116212785244,
        -0.037440069019794464,
        -0.056032318621873856,
        -0.057971321046352386,
        0.023777488619089127,
        -0.027420595288276672,
        -0.054160814732313156,
        0.05234324187040329,
        -0.05403231456875801,
        -0.0540761761367321,
        0.055995140224695206,
        0.04933023452758789,
        0.047286663204431534,
        0.03819188475608826,
        -0.036324888467788696,
        -0.03729556128382683,
        -0.05636047571897507,
        0.05066002532839775,
        0.05767928808927536,
        -0.02924102172255516,
        0.05730317905545235,
        -0.05814818665385246,
        0.057027824223041534,
        -0.035826172679662704
    ],
    [
        -0.041256923228502274,
        0.021713698282837868,
        0.04172059893608093,
        -0.030560610815882683,
        -0.05603882297873497,
        0.003599971067160368,
        -0.006637327838689089,
        -0.0559711679816246,
        0.040632396936416626,
        0.03999219089746475,
        0.052782148122787476,
        0.017955821007490158,
        -0.05697081238031387,
        -0.02964944764971733,
        -0.05604339763522148,
        -0.04742162674665451,
        -0.05673191696405411,
        0.05229302868247032,
        0.040143933147192,
        -0.05735575780272484,
        0.05428862199187279,
        0.0573788620531559,
        0.05453150346875191,
        0.04384337365627289,
        -0.021251004189252853,
        0.03563792258501053,
        -0.05256613343954086,
        0.05505237355828285,
        -0.05396432802081108,
        0.05707898736000061,
        0.0541546605527401,
        -0.05735112354159355,
        0.03977091610431671,
        -0.05726849287748337,
        0.055500976741313934,
        0.05087229609489441,
        -0.05583566054701805,
        -0.03241300582885742,
        0.05733800679445267,
        -0.056574996560811996,
        -0.0008937803795561194,
        0.050134338438510895,
        0.044181305915117264,
        -0.010008791461586952,
        -0.00995924137532711,
        0.051699161529541016,
        -0.014312091283500195,
        0.056904762983322144,
        0.05653956159949303,
        0.04798467084765434,
        0.04786735400557518,
        -0.019620634615421295,
        -0.053824394941329956,
        -0.03875606134533882,
        -0.042714089155197144,
        0.05620758235454559,
        0.0525609590113163,
        0.003800342557951808,
        0.002838535001501441,
        -0.00934573169797659,
        -0.05544152855873108,
        -0.03927302733063698,
        -0.05600002035498619,
        0.048509735614061356,
        -0.04658743739128113,
        -0.05713478848338127,
        -0.05686140060424805,
        -0.057351771742105484,
        0.057014644145965576,
        -0.05263454467058182,
        -0.04924415796995163,
        -0.056569524109363556,
        0.005623262841254473,
        0.00886211171746254,
        -0.05113855004310608,
        -0.053247496485710144,
        0.0050122495740652084,
        0.057392530143260956,
        -0.0558471642434597,
        0.012958778068423271,
        -0.055303189903497696,
        -0.05316311493515968,
        -0.056230783462524414,
        -0.027052849531173706,
        0.009121604263782501,
        0.053589653223752975,
        -0.0546853207051754,
        -0.05039823427796364,
        0.047339506447315216,
        0.02641054056584835,
        -0.05209646001458168,
        -0.03624685853719711,
        -0.05443074181675911,
        -0.04180078208446503,
        -0.03883230686187744,
        0.05241020396351814,
        -0.05191768705844879,
        -0.052203286439180374,
        0.05233065038919449,
        0.04215914383530617,
        -0.05595353990793228,
        0.01585322991013527,
        0.022875117138028145,
        0.0011338291224092245,
        0.055074773728847504,
        0.05436387658119202,
        0.025422507897019386,
        -0.00607514102011919,
        -0.04791930690407753,
        -0.01943010836839676,
        -0.0012504496844485402,
        -0.049138814210891724,
        0.02259211055934429,
        0.029020193964242935,
        -0.05614877864718437,
        0.02590782940387726,
        -0.0558614619076252,
        -0.05007948353886604,
        0.054517798125743866,
        0.012454953975975513,
        0.015384349972009659,
        0.022751426324248314,
        -0.038999561220407486,
        -0.02845573239028454,
        -0.05527447164058685,
        -0.056121379137039185,
        -0.026722867041826248,
        0.014723436906933784,
        0.05003367364406586,
        0.006737811490893364,
        0.056825555860996246,
        -0.016407325863838196,
        -0.03437456116080284,
        0.05573340505361557,
        -0.04738083481788635,
        -0.056342873722314835,
        0.05691007152199745,
        -0.006964843720197678,
        -0.04327050596475601,
        -0.024638880044221878,
        -0.050063349306583405,
        -0.017193535342812538,
        0.0317675843834877,
        0.05389197915792465,
        -0.01588839665055275,
        -0.026228167116642,
        -0.032999131828546524,
        -0.041123971343040466,
        -0.05398348346352577,
        -0.015647727996110916,
        0.055101316422224045,
        -0.05325930938124657,
        -0.01985536515712738,
        0.009068476967513561,
        0.05662839859724045,
        -0.03432389348745346,
        0.05645746365189552,
        -0.0548730194568634,
        0.041907843202352524,
        0.02236977033317089,
        0.03062950260937214,
        -0.037618618458509445,
        0.05475505068898201,
        -0.004222419112920761,
        -0.055561382323503494,
        -0.04445570707321167,
        0.04894068464636803,
        -0.053250592201948166,
        0.006185981910675764,
        0.01735646277666092,
        -0.057052455842494965,
        -0.05064210295677185,
        -0.05531032010912895,
        0.03626237064599991,
        -0.00837776530534029,
        -0.005568120628595352,
        0.014659494161605835,
        -0.03265310451388359,
        0.055647265166044235,
        -0.05124986544251442,
        -0.052756939083337784,
        0.0006860526045784354,
        0.05250720679759979,
        -0.04020132124423981,
        0.04252582788467407,
        0.030781831592321396,
        -0.025273537263274193,
        0.05581379309296608,
        -0.05181310325860977,
        -0.01753595471382141,
        -0.004383167251944542,
        -0.016286715865135193,
        0.03224414959549904,
        -0.013092129491269588,
        -0.05504545941948891,
        -0.046798110008239746,
        -0.042052000761032104,
        0.05524340644478798,
        -0.047954048961400986,
        0.055860355496406555,
        0.05575374886393547,
        -0.02627282775938511,
        0.05020326375961304,
        -0.05507269501686096,
        0.05607852712273598,
        -0.05732579901814461,
        0.0032170249614864588,
        0.05343032255768776,
        -0.05623247101902962,
        0.05716387927532196,
        -0.02917369268834591,
        0.05110587179660797,
        0.05672520771622658,
        0.05437790974974632,
        0.02714463137090206,
        0.04855838045477867,
        -0.029508166015148163,
        -0.02391098439693451,
        0.05728049576282501,
        -0.05368441343307495,
        -0.03142530843615532,
        -0.04923621565103531,
        -0.0006772120832465589,
        -0.0003668281133286655,
        0.05613869056105614,
        -0.04740652069449425,
        0.0366545170545578,
        -0.05491701140999794,
        0.03595466911792755,
        -0.052624691277742386,
        -0.044104162603616714,
        -0.055041030049324036,
        0.04340220242738724,
        0.044419534504413605,
        -0.005800427868962288,
        0.055394262075424194,
        0.04602382704615593,
        -0.05222754925489426,
        0.055575914680957794,
        0.057381343096494675,
        0.056533586233854294,
        0.05702953040599823,
        0.03749074786901474,
        -0.05732503533363342,
        -0.019960416480898857,
        -0.030133722350001335,
        0.05249476805329323,
        -0.042003318667411804,
        -0.056443940848112106,
        -0.030966542661190033,
        -0.03122200071811676,
        -0.05598684400320053,
        0.052383605390787125,
        -0.05254428833723068,
        -0.014876565895974636,
        0.05244345963001251,
        0.055819813162088394,
        -0.041411515325307846,
        0.052015792578458786,
        -0.03219492733478546,
        -0.0475589781999588,
        0.03182166814804077,
        0.04313080757856369,
        0.056689273566007614,
        0.05412013828754425,
        0.055165812373161316,
        -0.032192349433898926,
        0.053279146552085876,
        -0.015006735920906067,
        -0.026825841516256332,
        0.05507921054959297,
        -0.04550325870513916,
        0.05735313892364502,
        -0.05595044791698456,
        -0.015093911439180374,
        -0.017512043938040733,
        -0.053609125316143036,
        -0.056065548211336136,
        -0.05607598274946213,
        -0.0004250043712090701,
        -0.05136043578386307,
        0.02898734249174595,
        -0.04357699304819107,
        0.05475090816617012,
        -0.04547835886478424,
        0.05663326382637024,
        0.04879923164844513,
        0.05670154467225075,
        -0.011374394409358501,
        0.0446850024163723,
        -0.056719064712524414,
        -0.020800502970814705,
        -0.01986277662217617,
        -0.018969736993312836,
        0.05706791207194328,
        -0.048857223242521286,
        0.05675134062767029,
        0.051700666546821594,
        -0.01760769635438919,
        0.055899590253829956,
        0.001375176478177309,
        -0.00014650110097136348,
        -0.0025448333472013474,
        0.048623230308294296,
        -0.036392923444509506,
        -0.05636075884103775,
        -0.001956811174750328,
        0.05729643255472183,
        0.05607759207487106,
        0.024691227823495865,
        -0.0420629046857357,
        0.05692635476589203,
        -0.055440958589315414,
        0.054951686412096024,
        0.0199136883020401,
        -0.0566040463745594,
        -0.013971641659736633,
        0.057385239750146866,
        0.054916635155677795,
        -0.026304833590984344,
        0.03880884498357773,
        -0.05660128965973854,
        0.04057501256465912,
        0.05228723585605621,
        0.05183965340256691,
        0.05674169957637787,
        0.054249830543994904,
        0.04590146243572235,
        0.05493289604783058,
        0.02565251849591732,
        0.016834957525134087,
        -0.05283331125974655,
        0.05626391991972923,
        0.0573788657784462,
        0.05307421088218689,
        0.0573023185133934,
        -0.05423308536410332,
        -0.0449037179350853,
        -0.05502614378929138,
        0.053441304713487625,
        -0.05475698038935661,
        -0.05709081515669823,
        0.010737976059317589,
        -0.0507374033331871,
        -0.032157208770513535,
        0.0013257742393761873,
        0.056097306311130524,
        0.05702818185091019,
        -0.011446380987763405,
        0.029910238459706306,
        0.05679671838879585,
        -0.004161744378507137,
        0.017181454226374626,
        -0.0563381090760231,
        -0.051729168742895126,
        0.028028180822730064,
        -0.057332344353199005,
        -0.0566813126206398,
        -0.05666598305106163,
        -0.05628756433725357,
        0.046983662992715836,
        0.011157609522342682,
        0.027153238654136658,
        -0.05469287186861038,
        -0.04308929294347763,
        -0.05260021984577179,
        0.05691315606236458,
        0.03315337002277374,
        -0.0501873679459095,
        -0.05656101554632187,
        -0.03117983415722847,
        -0.056697215884923935,
        0.05474601313471794,
        0.033742886036634445,
        0.05596715211868286,
        0.05734425038099289,
        -0.0035076523199677467,
        -0.009111626073718071,
        0.05734030157327652,
        0.04938385263085365,
        0.022854125127196312,
        -0.03629864379763603,
        -0.047768786549568176,
        -0.041288308799266815,
        0.020121265202760696,
        -0.05544563755393028,
        0.0014685557689517736,
        -0.04539802297949791,
        -0.056631263345479965,
        -0.01494290679693222,
        -0.05731412395834923,
        0.04700254648923874,
        -0.05537917837500572,
        -0.01697283610701561,
        0.04426556080579758,
        0.05478460341691971,
        0.054409902542829514,
        -0.012298482470214367,
        0.026996178552508354,
        -0.05624474585056305,
        -0.051835063844919205,
        0.05647026747465134,
        -0.05096621811389923,
        -0.04994439333677292,
        -0.014669734053313732,
        0.044881585985422134,
        0.04724334180355072,
        -0.039502907544374466,
        0.05601189285516739,
        -0.04776452109217644,
        -0.04024076461791992,
        0.02857956290245056,
        -0.04914691671729088,
        0.037801921367645264,
        0.010605724528431892,
        0.01824403554201126,
        -0.009457942098379135,
        0.05594700202345848,
        0.05196060985326767,
        0.055529989302158356,
        0.047330353409051895,
        -0.04976585507392883,
        0.04961218684911728,
        -0.05695755407214165,
        0.014635699801146984,
        0.05049975961446762,
        0.023629043251276016,
        -0.05538677051663399,
        -0.05527215451002121,
        -0.05512271821498871,
        0.05484713986515999,
        -0.010424290783703327,
        -0.04655715450644493,
        -0.050671447068452835,
        0.04493606090545654,
        -0.056347500532865524,
        -0.05587093159556389,
        0.0536322183907032,
        -0.03535185754299164,
        -0.05019138753414154,
        -0.0568237379193306,
        -0.04105151817202568,
        0.019886408001184464,
        0.051707591861486435,
        -0.017422856763005257,
        -0.03861766681075096,
        0.05663519725203514,
        -0.038434822112321854,
        0.035277627408504486,
        -0.00730047607794404,
        -0.05729943886399269,
        0.05447866767644882,
        0.048468444496393204,
        0.036554496735334396,
        0.015553787350654602,
        -0.052162785083055496,
        -0.011915246024727821,
        -0.053705718368291855,
        -0.011528192088007927,
        -0.045235149562358856,
        0.04888291656970978,
        0.006465891841799021,
        0.017331207171082497,
        -0.0034169789869338274,
        0.041858971118927,
        0.057104937732219696,
        -0.03925961256027222,
        0.040400728583335876,
        0.04394562169909477,
        -0.054236218333244324,
        0.052457068115472794,
        0.05590295419096947,
        0.05296904966235161,
        0.05695709213614464,
        -0.05403944104909897,
        0.049911364912986755,
        0.01726897992193699,
        0.01956304721534252,
        -0.055845145136117935,
        -0.05719461664557457,
        0.01737297512590885,
        0.022727886214852333,
        -0.05293719843029976,
        0.04834221303462982,
        -0.03686529025435448,
        0.04654181748628616,
        -0.025716915726661682,
        0.05659973993897438,
        -0.055948272347450256,
        -0.042023178189992905,
        0.02874615043401718,
        -0.05625634267926216,
        0.03135298565030098,
        0.0018420821288600564,
        -0.048619672656059265,
        0.051732972264289856,
        0.05325600877404213,
        -0.029513947665691376,
        0.04701719805598259,
        0.056501101702451706,
        -0.02814742736518383,
        0.05684530362486839,
        0.03587958216667175,
        -0.05555226281285286,
        -0.043741557747125626,
        0.03544752672314644,
        0.05714407563209534,
        -0.03757490590214729,
        0.04374344274401665,
        0.00037016658461652696,
        0.03813990578055382,
        0.010072930715978146
    ],
    [
        0.024460112676024437,
        -0.02238539047539234,
        0.05097430944442749,
        0.02669508568942547,
        -0.0064391461201012135,
        -0.054515738040208817,
        0.024958724156022072,
        -0.05687747150659561,
        0.05699629336595535,
        -0.04643741995096207,
        -0.0009033862734213471,
        0.05589332431554794,
        -0.05603302642703056,
        0.0042252251878380775,
        -0.05016830191016197,
        0.0529506541788578,
        -0.03289082273840904,
        0.05694078281521797,
        -0.04198482260107994,
        0.028789591044187546,
        0.03929958865046501,
        -0.031093779951334,
        0.05338621512055397,
        -0.010593140497803688,
        -0.04846610128879547,
        0.050447460263967514,
        0.055807411670684814,
        0.05659966915845871,
        -0.03202775865793228,
        -0.042982522398233414,
        -0.015249715186655521,
        0.0474228709936142,
        0.057072963565588,
        -0.05765272676944733,
        0.055694010108709335,
        -0.05604804307222366,
        -0.05393148213624954,
        -0.03197682276368141,
        -0.056948017328977585,
        -0.05757025256752968,
        -0.00028837393620051444,
        0.05473452806472778,
        0.027958635240793228,
        -0.022769056260585785,
        0.012038814835250378,
        0.04980579763650894,
        0.05465393513441086,
        0.05764942243695259,
        0.05713887885212898,
        -0.0576009601354599,
        0.05232783406972885,
        -0.008704137057065964,
        -0.05162659287452698,
        -0.05682797729969025,
        0.051550693809986115,
        0.0536167211830616,
        -0.05723532289266586,
        -0.047475315630435944,
        -0.03225383907556534,
        -0.04952924698591232,
        -0.05685273930430412,
        0.050646938383579254,
        -0.05353216826915741,
        -0.022232791408896446,
        0.027449749410152435,
        -0.055258311331272125,
        -0.057003047317266464,
        -0.019963230937719345,
        -0.04561889171600342,
        -0.05676988512277603,
        -0.027244437485933304,
        -0.04562098905444145,
        0.04720747470855713,
        0.05478610843420029,
        0.05508190020918846,
        0.011061313562095165,
        0.023207202553749084,
        0.057672180235385895,
        0.05743909999728203,
        0.01637776754796505,
        -0.027712197974324226,
        -0.055568307638168335,
        -0.048835668712854385,
        0.044616423547267914,
        0.05662889778614044,
        0.012343796901404858,
        -0.037645138800144196,
        -0.05086848884820938,
        -0.021154876798391342,
        0.03449675440788269,
        -0.05751841515302658,
        0.01016436330974102,
        -0.04061412066221237,
        -0.05493287369608879,
        0.012407469563186169,
        -0.006321428809314966,
        -0.05603141710162163,
        -0.047312840819358826,
        -0.04185241833329201,
        0.05521510913968086,
        -0.05263897776603699,
        0.056560128927230835,
        0.04489220306277275,
        0.04792812466621399,
        0.057159315794706345,
        -0.05639277771115303,
        -0.05627157539129257,
        -0.01620125211775303,
        -0.05734610930085182,
        0.03879517316818237,
        0.008464504033327103,
        -0.053252335637807846,
        0.04111918434500694,
        0.01127211470156908,
        0.055549897253513336,
        -0.033777542412281036,
        -0.04791481792926788,
        -0.05455024540424347,
        0.04861157387495041,
        -0.05752669647336006,
        -0.029757820069789886,
        -0.050608452409505844,
        -0.046504419296979904,
        -0.05198367312550545,
        -0.055575985461473465,
        0.013817105442285538,
        0.019761517643928528,
        0.04773598909378052,
        0.056185878813266754,
        -0.0015462435549125075,
        0.045910321176052094,
        -0.05599275976419449,
        0.017543254420161247,
        0.057342588901519775,
        -0.03055885061621666,
        -0.0562242716550827,
        0.05206367373466492,
        -0.010227020829916,
        0.014939614571630955,
        0.05636300519108772,
        0.0490955114364624,
        -0.015242302790284157,
        0.050537146627902985,
        0.05551985278725624,
        -0.017420092597603798,
        0.056845635175704956,
        0.05636431276798248,
        -0.008150071837008,
        0.03971448168158531,
        0.0018664018716663122,
        -0.016327815130352974,
        -0.05761219933629036,
        -0.05586931109428406,
        0.05743321403861046,
        0.054351113736629486,
        -0.04219537228345871,
        -0.04722259193658829,
        0.05559312179684639,
        0.030936427414417267,
        -0.04410663619637489,
        -0.0035157869569957256,
        -0.05639762803912163,
        0.05731009319424629,
        0.04962009936571121,
        -0.057289231568574905,
        -0.044550325721502304,
        0.057611800730228424,
        0.047023870050907135,
        -0.029844526201486588,
        0.04890615865588188,
        0.00903042871505022,
        0.05718092992901802,
        -0.05760178714990616,
        0.047412846237421036,
        -0.015182575210928917,
        -0.04040134698152542,
        -0.023744193837046623,
        -0.038754455745220184,
        0.05701528117060661,
        -0.05689224228262901,
        -0.04859992116689682,
        0.0428415983915329,
        -0.015816394239664078,
        -0.040438540279865265,
        -0.036346424371004105,
        0.00016680416592862457,
        0.0510050430893898,
        0.05635291337966919,
        0.05480436608195305,
        0.03846242278814316,
        -0.05761915072798729,
        0.011923237703740597,
        -0.03529822453856468,
        -0.01612783409655094,
        -0.01608377695083618,
        0.015434357337653637,
        0.05441790446639061,
        0.0060594272799789906,
        -0.008604953996837139,
        0.04420428350567818,
        0.056485213339328766,
        -0.05625588446855545,
        0.056603047996759415,
        -0.04951735958456993,
        -0.0031774600502103567,
        0.023716295138001442,
        0.05484503135085106,
        0.03841507434844971,
        -0.035703402012586594,
        -0.0031346760224550962,
        -0.032593514770269394,
        0.025385674089193344,
        0.05768269672989845,
        0.05628952756524086,
        -0.05509689450263977,
        -0.02669605053961277,
        0.05765794217586517,
        -0.04718693718314171,
        0.05768140032887459,
        -0.057635702192783356,
        -0.02389463223516941,
        0.04934169352054596,
        -0.018628733232617378,
        -0.019823921844363213,
        0.05762036144733429,
        -0.02748931758105755,
        0.023841610178351402,
        -0.05597889423370361,
        -0.03944927453994751,
        -0.05695018172264099,
        -0.001437608734704554,
        -0.056982025504112244,
        -0.05284595862030983,
        0.046175990253686905,
        -0.05174283683300018,
        0.05768069997429848,
        0.05650212988257408,
        -0.013125994242727757,
        -0.002230379031971097,
        0.05755539983510971,
        0.05664939060807228,
        0.002335162367671728,
        -0.020128199830651283,
        -0.05713580176234245,
        0.056783877313137054,
        -0.0007879179320298135,
        0.05599192529916763,
        0.010292698629200459,
        -0.051980480551719666,
        -0.018615368753671646,
        0.021206596866250038,
        -0.027020249515771866,
        0.011564086191356182,
        -0.055196043103933334,
        -0.029786333441734314,
        0.05246429145336151,
        -0.03521914780139923,
        -0.04052501916885376,
        0.052517082542181015,
        0.039232511073350906,
        0.02868105098605156,
        -0.022601189091801643,
        -0.03924016281962395,
        0.04989286884665489,
        -0.04225180298089981,
        0.057486750185489655,
        0.014081339351832867,
        0.05665707588195801,
        -0.03588208928704262,
        0.017116708680987358,
        0.05088504031300545,
        -0.054238975048065186,
        0.054956551641225815,
        -0.05672254040837288,
        -0.037233866751194,
        0.040772855281829834,
        -0.05287032201886177,
        -0.016258113086223602,
        0.05027426406741142,
        -0.01539734099060297,
        -0.0567048117518425,
        0.048883985728025436,
        -0.04485156759619713,
        0.029560990631580353,
        -0.009015056304633617,
        0.0511331669986248,
        0.013629150576889515,
        0.017653118818998337,
        0.010159634985029697,
        0.0003147399111185223,
        -0.05625356733798981,
        -0.0540015809237957,
        -0.0536513477563858,
        -0.05759745091199875,
        0.01927211880683899,
        -0.05516708642244339,
        0.053489651530981064,
        -0.04956052824854851,
        -0.007484163623303175,
        0.05620267987251282,
        0.053962189704179764,
        0.04912177845835686,
        -0.043332163244485855,
        0.05718209221959114,
        0.005888820625841618,
        0.037435755133628845,
        -0.05471320450305939,
        0.047893133014440536,
        0.047311123460531235,
        -0.0567987896502018,
        -0.04295742139220238,
        -0.024168115109205246,
        0.05657756328582764,
        0.0548434779047966,
        0.04687952995300293,
        -0.05695302039384842,
        0.037901267409324646,
        0.05456620454788208,
        0.02918401174247265,
        0.05166783556342125,
        0.04689036309719086,
        -0.05460280925035477,
        0.057169876992702484,
        -0.05223773047327995,
        0.057461634278297424,
        0.0576166994869709,
        0.035853732377290726,
        -0.02801552601158619,
        -0.015060107223689556,
        -0.05617258697748184,
        0.042206358164548874,
        -0.029069114476442337,
        -0.034994594752788544,
        0.0051145791076123714,
        0.053539372980594635,
        0.057159002870321274,
        -0.054134804755449295,
        0.022705432027578354,
        -0.007599340286105871,
        -0.0473136380314827,
        0.029513945803046227,
        -0.023014867678284645,
        -0.0039445082657039165,
        -0.03140520676970482,
        0.05763363838195801,
        -0.0008441676618531346,
        0.04256324842572212,
        0.057463180273771286,
        0.05613775923848152,
        0.057488370686769485,
        0.05766633152961731,
        -0.0338420532643795,
        0.04165562987327576,
        -0.05721769854426384,
        -0.0470489040017128,
        -0.03415079042315483,
        -0.057070132344961166,
        -0.05607109144330025,
        -0.03777839243412018,
        0.025688450783491135,
        0.052119772881269455,
        0.05164404213428497,
        -0.02978302538394928,
        -0.038007158786058426,
        -0.05551633983850479,
        -0.05722732096910477,
        0.05757158249616623,
        0.031860388815402985,
        0.011420784518122673,
        -0.05634800344705582,
        0.051855411380529404,
        -0.020512422546744347,
        0.05566432327032089,
        0.05284690111875534,
        0.05612531304359436,
        0.056588757783174515,
        -0.056144829839468,
        -0.017863741144537926,
        0.04772594943642616,
        -0.037318844348192215,
        -0.04919073358178139,
        0.042127348482608795,
        -0.026421912014484406,
        -0.010754085145890713,
        0.04907011613249779,
        -0.03715940937399864,
        -0.02494150958955288,
        -0.044744741171598434,
        -0.013007713481783867,
        0.03229314461350441,
        -0.05724900960922241,
        0.04623023048043251,
        -0.0575421079993248,
        -0.0560232438147068,
        0.04834548011422157,
        0.05580957233905792,
        0.0536591000854969,
        -0.021573731675744057,
        -0.05194123834371567,
        -0.033793315291404724,
        0.017474938184022903,
        0.057138726115226746,
        -0.05274980142712593,
        0.043585166335105896,
        -0.05478077754378319,
        -0.047381442040205,
        0.05508493259549141,
        -0.04894178360700607,
        0.05348760634660721,
        0.020272811874747276,
        -0.0211420226842165,
        -0.000036819736124016345,
        -0.021394381299614906,
        -0.05043734982609749,
        0.05456848442554474,
        -0.05093583092093468,
        0.02286345884203911,
        -0.019493719562888145,
        0.053521525114774704,
        0.014787832275032997,
        0.05737433582544327,
        -0.05616425722837448,
        -0.037609297782182693,
        -0.05715364217758179,
        0.006121423095464706,
        0.04554807022213936,
        -0.04595673829317093,
        -0.05160025134682655,
        0.001315695932134986,
        -0.05747533589601517,
        0.057338181883096695,
        -0.035953085869550705,
        -0.000007936965630506165,
        -0.015975354239344597,
        -0.057153403759002686,
        -0.04861919581890106,
        -0.05368935689330101,
        -0.012498229742050171,
        -0.053064338862895966,
        -0.05694470927119255,
        -0.03790551424026489,
        0.05568653717637062,
        0.048047035932540894,
        0.05018749460577965,
        0.050522830337285995,
        -0.017511770129203796,
        0.05615202337503433,
        -0.057651810348033905,
        0.043512068688869476,
        0.054563336074352264,
        -0.05619579553604126,
        0.054992783814668655,
        0.056517452001571655,
        0.025569355115294456,
        -0.03636875003576279,
        0.0006481152377091348,
        0.011161564849317074,
        -0.03620963916182518,
        0.024559061974287033,
        0.012035555206239223,
        0.054998062551021576,
        -0.057228460907936096,
        -0.03964144363999367,
        -0.01976095139980316,
        0.05762667953968048,
        0.05713771656155586,
        -0.05213816091418266,
        0.05737479403614998,
        -0.01182955875992775,
        -0.05610848218202591,
        -0.0024948203936219215,
        0.03723845258355141,
        0.05636849254369736,
        0.057130116969347,
        0.05468825250864029,
        -0.009039045311510563,
        -0.031197592616081238,
        0.05661648139357567,
        -0.05639839917421341,
        -0.04532403126358986,
        0.03930187225341797,
        -0.04727693647146225,
        -0.05623610317707062,
        0.04671198129653931,
        -0.053704094141721725,
        0.056667059659957886,
        -0.021917041391134262,
        0.03943726792931557,
        -0.05669403821229935,
        -0.024783363565802574,
        0.04642465338110924,
        -0.05728045850992203,
        0.0392829068005085,
        -0.0570717453956604,
        -0.027003703638911247,
        -0.05180646479129791,
        0.021147044375538826,
        -0.04991920664906502,
        0.0462682880461216,
        0.05618283152580261,
        0.0322655625641346,
        -0.00997737143188715,
        0.018313830718398094,
        -0.05547037720680237,
        0.04742946848273277,
        0.057390861213207245,
        0.007740833796560764,
        -0.02657807059586048,
        0.05636288598179817,
        -0.05740339681506157,
        -0.022525187581777573,
        0.055686164647340775
    ],
    [
        -0.04196038842201233,
        -0.05074554681777954,
        -0.03842207044363022,
        0.025125635787844658,
        0.04434699937701225,
        -0.05289081111550331,
        0.05066775158047676,
        -0.047693926841020584,
        -0.01087931077927351,
        0.00892223697155714,
        -0.035997286438941956,
        -0.04823227971792221,
        -0.052941374480724335,
        0.03923332318663597,
        -0.04648637771606445,
        0.05314233526587486,
        -0.05141233652830124,
        0.05312506482005119,
        -0.05251111835241318,
        0.05299566686153412,
        -0.046410515904426575,
        0.05307998135685921,
        0.05301141366362572,
        0.04708284139633179,
        -0.01769142411649227,
        -0.05295196548104286,
        -0.04973660036921501,
        0.047711554914712906,
        -0.050352152436971664,
        0.05223030224442482,
        -0.05290622636675835,
        -0.05221995338797569,
        -0.022549843415617943,
        -0.053131137043237686,
        0.030786752700805664,
        -0.05290631577372551,
        -0.05289977788925171,
        -0.048682793974876404,
        -0.04948465898633003,
        -0.04869528114795685,
        0.051861412823200226,
        0.027173226699233055,
        0.0396801121532917,
        -0.029506636783480644,
        -0.035534922033548355,
        -0.007357018534094095,
        0.05287967994809151,
        0.0524679571390152,
        0.05313931778073311,
        0.03409735485911369,
        0.05065958574414253,
        0.046374257653951645,
        0.048121172934770584,
        -0.05230969935655594,
        0.029782986268401146,
        0.049565382301807404,
        0.028541233390569687,
        -0.052308544516563416,
        -0.04085943475365639,
        0.012984736822545528,
        0.020156677812337875,
        0.001443883404135704,
        -0.052103374153375626,
        0.052455008029937744,
        0.03312388062477112,
        -0.050391219556331635,
        -0.052316851913928986,
        -0.00030742681701667607,
        0.03671817481517792,
        -0.053116608411073685,
        -0.03527301177382469,
        -0.053141459822654724,
        0.05309426039457321,
        0.047086864709854126,
        0.050795264542102814,
        0.014150348491966724,
        0.052548959851264954,
        0.053142864257097244,
        0.05292423814535141,
        -0.03200719878077507,
        0.017194174230098724,
        -0.05310757830739021,
        -0.053108952939510345,
        0.045798368752002716,
        0.03804604336619377,
        0.04816475883126259,
        0.0023893387988209724,
        -0.05118630453944206,
        0.04356583207845688,
        0.03446119278669357,
        0.03246181458234787,
        -0.03929262235760689,
        -0.03869463875889778,
        -0.04581701382994652,
        -0.05127871781587601,
        0.05313001945614815,
        -0.04493321478366852,
        -0.0475674644112587,
        -0.04227302595973015,
        -0.013857088051736355,
        -0.04832778871059418,
        0.023444263264536858,
        0.05062323436141014,
        0.04371996596455574,
        0.05271662399172783,
        -0.05307115614414215,
        -0.0527493990957737,
        -0.04194040969014168,
        -0.05302129685878754,
        -0.03053271770477295,
        -0.053030550479888916,
        -0.00997508317232132,
        -0.05282511189579964,
        0.04708782956004143,
        0.019004059955477715,
        0.05313519760966301,
        -0.014621628448367119,
        -0.052123747766017914,
        0.0531175471842289,
        0.05126999691128731,
        -0.05246696621179581,
        -0.02821195498108864,
        -0.028015708550810814,
        -0.03747931122779846,
        -0.05177953839302063,
        -0.04136919602751732,
        0.05230481177568436,
        0.05256534367799759,
        0.05291804298758507,
        0.04664161801338196,
        -0.02053801529109478,
        -0.04247251898050308,
        -0.046136558055877686,
        0.05295276269316673,
        -0.03544161468744278,
        -0.04987744614481926,
        0.023441452533006668,
        0.046715959906578064,
        -0.04096537083387375,
        0.051943760365247726,
        0.05309457331895828,
        0.048218272626399994,
        0.04017487168312073,
        0.05310853198170662,
        0.04967237636446953,
        0.04905041679739952,
        -0.014237949624657631,
        -0.05273548886179924,
        -0.04064544290304184,
        -0.0045897383242845535,
        -0.053013045340776443,
        -0.053116459399461746,
        -0.05111994594335556,
        0.001877762726508081,
        0.052927471697330475,
        0.02923252247273922,
        0.04154881089925766,
        0.04106403514742851,
        0.05314001813530922,
        -0.04076092317700386,
        0.01949630305171013,
        0.047683022916316986,
        0.05230087414383888,
        0.04173404350876808,
        -0.05308524891734123,
        -0.0027565238997340202,
        -0.005335968919098377,
        -0.02808336913585663,
        -0.05262642726302147,
        0.037389736622571945,
        -0.052928805351257324,
        0.0448528416454792,
        -0.044872112572193146,
        0.05124284699559212,
        -0.052093010395765305,
        0.044222645461559296,
        -0.053130798041820526,
        -0.05148075148463249,
        0.036730825901031494,
        -0.0022196583449840546,
        0.025060847401618958,
        0.020836587995290756,
        0.04784880951046944,
        0.026938561350107193,
        -0.052633557468652725,
        -0.05293164774775505,
        0.05147891864180565,
        0.05093790963292122,
        -0.05274742469191551,
        -0.0529107004404068,
        -0.0511167049407959,
        0.027780521661043167,
        0.052090246230363846,
        0.03552388399839401,
        -0.039069049060344696,
        -0.0005642633768729866,
        0.05312317982316017,
        0.052940621972084045,
        -0.050864849239587784,
        0.03627556189894676,
        -0.008662688545882702,
        0.025605974718928337,
        -0.05270671844482422,
        0.05251822620630264,
        0.022497354075312614,
        -0.025927715003490448,
        0.053063225001096725,
        0.04615192115306854,
        -0.03968577831983566,
        0.01547772716730833,
        -0.04502515122294426,
        -0.053120143711566925,
        0.052817538380622864,
        0.049033667892217636,
        0.04524571821093559,
        0.04663188382983208,
        0.053142864257097244,
        0.05015111714601517,
        0.05314285308122635,
        0.05306467041373253,
        0.04563961550593376,
        0.052789583802223206,
        0.00713212788105011,
        -0.05314178019762039,
        0.0526350773870945,
        -0.05280042067170143,
        0.05076799541711807,
        -0.04950123652815819,
        0.04642960801720619,
        -0.05244596302509308,
        0.033895790576934814,
        -0.020973624661564827,
        -0.05218204855918884,
        0.042902104556560516,
        0.05314198136329651,
        0.05244768038392067,
        0.04004587605595589,
        0.04636991769075394,
        0.021537454798817635,
        0.05307832732796669,
        0.05281943455338478,
        0.04898744821548462,
        0.026623891666531563,
        -0.05313275754451752,
        -0.031832341104745865,
        -0.026899587363004684,
        0.04899656027555466,
        -0.0110394898802042,
        0.00943833589553833,
        0.05114289000630379,
        0.033818867057561874,
        -0.045477818697690964,
        0.049215465784072876,
        -0.0512128621339798,
        -0.03809882327914238,
        0.04919165000319481,
        0.04014664888381958,
        -0.052554238587617874,
        0.05174708738923073,
        0.0501876100897789,
        -0.051173292100429535,
        0.040126360952854156,
        -0.03194478526711464,
        -0.05237296596169472,
        -0.05013278126716614,
        0.05311861261725426,
        0.011409375816583633,
        0.05313470959663391,
        -0.052834801375865936,
        0.051289886236190796,
        0.052651066333055496,
        -0.041029155254364014,
        0.05311271920800209,
        -0.01583992876112461,
        -0.038950175046920776,
        -0.04300012066960335,
        0.009395004250109196,
        -0.031548574566841125,
        0.052561141550540924,
        -0.006078418344259262,
        -0.05107086896896362,
        -0.05280815064907074,
        -0.04465828463435173,
        0.03305507451295853,
        -0.05265399441123009,
        -0.040500521659851074,
        0.03754694014787674,
        0.026198742911219597,
        0.052320968359708786,
        0.00014633298269473016,
        0.00040213289321400225,
        0.051652055233716965,
        -0.009306266903877258,
        0.053111009299755096,
        0.03956811875104904,
        -0.05303213372826576,
        0.05221239849925041,
        0.029205990955233574,
        -0.05268970876932144,
        0.05132278800010681,
        0.009732994250953197,
        0.025665156543254852,
        -0.027657225728034973,
        0.05298709496855736,
        -0.05263746902346611,
        -0.05053027719259262,
        -0.05275147035717964,
        0.053141918033361435,
        0.005184287670999765,
        -0.05312792584300041,
        -0.0498347207903862,
        -0.023757657036185265,
        0.05282033607363701,
        0.04666462540626526,
        -0.05313313752412796,
        -0.04883343353867531,
        -0.03452140837907791,
        0.044228360056877136,
        0.05002963915467262,
        0.027127787470817566,
        -0.052993301302194595,
        -0.0501180998980999,
        0.052845392376184464,
        0.04923282191157341,
        0.05296603590250015,
        0.052058327943086624,
        0.05243697762489319,
        -0.052800510078668594,
        -0.05260344222187996,
        0.014376292936503887,
        0.03415674716234207,
        -0.049462102353572845,
        0.04335187003016472,
        0.025810841470956802,
        0.043385520577430725,
        0.04779493808746338,
        -0.002882467582821846,
        -0.006317534483969212,
        -0.052927255630493164,
        0.053122323006391525,
        -0.03265587240457535,
        -0.033610377460718155,
        -0.0031901157926768064,
        0.04965478181838989,
        0.05314285308122635,
        0.051359567791223526,
        0.029928700998425484,
        0.05002924054861069,
        -0.050367455929517746,
        0.05308103561401367,
        0.052990760654211044,
        -0.04161253198981285,
        0.021156735718250275,
        -0.05214596167206764,
        -0.05283982306718826,
        -0.01872800849378109,
        -0.05305465683341026,
        -0.03774276748299599,
        0.05314076319336891,
        0.05189067870378494,
        0.04708246886730194,
        0.05290130153298378,
        0.042284198105335236,
        0.010113544762134552,
        0.03041461855173111,
        -0.01559572946280241,
        0.05299541726708412,
        -0.053106214851140976,
        -0.04390861466526985,
        -0.052882399410009384,
        -0.05079635977745056,
        -0.04572228342294693,
        -0.05006770044565201,
        0.05078260973095894,
        -0.001054982072673738,
        0.05314286798238754,
        -0.05246198922395706,
        0.046261899173259735,
        -0.011198347434401512,
        -0.029515033587813377,
        -0.053081680089235306,
        -0.050487153232097626,
        -0.021660180762410164,
        -0.05209178477525711,
        0.05305543541908264,
        -0.037680044770240784,
        -0.00999649241566658,
        -0.0528356172144413,
        -0.05274522304534912,
        -0.05290588364005089,
        -0.05283000320196152,
        -0.052899815142154694,
        -0.04800283536314964,
        -0.05283941328525543,
        0.05033089965581894,
        0.05310839042067528,
        0.05288683995604515,
        0.04357793554663658,
        0.03702361509203911,
        -0.05116896703839302,
        -0.03006628155708313,
        0.04907020181417465,
        -0.049344662576913834,
        0.015687141567468643,
        -0.05312095209956169,
        -0.027762478217482567,
        0.051585715264081955,
        -0.05210499465465546,
        0.04939853399991989,
        0.018407747149467468,
        -0.05309699475765228,
        -0.052818428725004196,
        0.028749195858836174,
        0.041563522070646286,
        0.05293818190693855,
        -0.05298704653978348,
        -0.050371650606393814,
        -0.03596598282456398,
        0.0528595857322216,
        0.05035236477851868,
        -0.049797892570495605,
        -0.009190387092530727,
        -0.051521118730306625,
        -0.052351102232933044,
        0.05279388651251793,
        -0.05152339115738869,
        0.011094029992818832,
        -0.007169515360146761,
        -0.05304854363203049,
        0.010026434436440468,
        0.05160810053348541,
        0.017982149496674538,
        -0.05312458425760269,
        -0.048964545130729675,
        -0.025751732289791107,
        -0.04263272508978844,
        -0.05314268171787262,
        0.05314091593027115,
        -0.053128719329833984,
        -0.02659456431865692,
        0.038182202726602554,
        0.053018152713775635,
        -0.03263402357697487,
        0.052207693457603455,
        -0.03990476205945015,
        -0.007301527541130781,
        0.051638904958963394,
        -0.052672211080789566,
        0.027063271030783653,
        0.050221558660268784,
        -0.05277784541249275,
        -0.025258176028728485,
        0.053139686584472656,
        -0.019514143466949463,
        0.05063348636031151,
        -0.03631548956036568,
        -0.050443366169929504,
        0.01961793191730976,
        -0.052533671259880066,
        0.042660146951675415,
        0.050161443650722504,
        -0.05226496234536171,
        -0.05293380469083786,
        0.041969090700149536,
        0.037742435932159424,
        0.05306622013449669,
        -0.051647499203681946,
        0.05314139649271965,
        0.05277120694518089,
        -0.04018871486186981,
        0.037579432129859924,
        0.05080433189868927,
        0.052657920867204666,
        -0.00220146169885993,
        0.028486713767051697,
        -0.04004227742552757,
        0.05307946726679802,
        -0.04730137065052986,
        -0.052980899810791016,
        -0.04366495460271835,
        0.03765162080526352,
        -0.007214562967419624,
        -0.05012878030538559,
        -0.04560067877173424,
        -0.05311505123972893,
        -0.04232002794742584,
        -0.046181660145521164,
        0.03578295558691025,
        -0.052929505705833435,
        0.0454118512570858,
        -0.05127471685409546,
        -0.05314256250858307,
        -0.039910849183797836,
        -0.048860371112823486,
        0.022081440314650536,
        0.052610114216804504,
        0.05313989147543907,
        -0.0531209371984005,
        -0.03689144551753998,
        0.0514267161488533,
        -0.026625478640198708,
        0.027067910879850388,
        -0.05266924947500229,
        -0.040896426886320114,
        0.0047554317861795425,
        0.04991024360060692,
        0.043541647493839264,
        -0.047610506415367126,
        -0.03717214986681938,
        -0.05299735069274902,
        0.05298250541090965,
        0.05313274264335632
    ],
    [
        0.0528375618159771,
        -0.042244911193847656,
        0.028192799538373947,
        0.040720511227846146,
        -0.0006500582094304264,
        -0.052569370716810226,
        0.05314447730779648,
        -0.05321327969431877,
        0.054619915783405304,
        -0.03743928670883179,
        0.03705072030425072,
        0.051458925008773804,
        -0.05476796627044678,
        -0.008903583511710167,
        -0.054591331630945206,
        0.03572578728199005,
        0.033249594271183014,
        0.052155543118715286,
        0.0026055374182760715,
        0.053522828966379166,
        -0.018298355862498283,
        0.026915378868579865,
        0.05454770475625992,
        -0.012078391388058662,
        -0.03964130952954292,
        0.03726772964000702,
        0.02234632521867752,
        0.048182692378759384,
        -0.05478229373693466,
        0.0011640201555565,
        0.006123393774032593,
        -0.05087381601333618,
        0.05206255242228508,
        -0.05437031015753746,
        0.054275065660476685,
        -0.051324062049388885,
        -0.05427929013967514,
        -0.05270831659436226,
        0.05464516207575798,
        0.005815926939249039,
        0.01736469380557537,
        0.04881259799003601,
        0.05363919213414192,
        0.0023234260734170675,
        0.02471543289721012,
        0.014715450815856457,
        0.04832940548658371,
        0.05464234948158264,
        0.05471810698509216,
        -0.04717489331960678,
        0.05426769331097603,
        0.053450267761945724,
        -0.01710476726293564,
        -0.04881635308265686,
        -0.01899331621825695,
        0.04933037981390953,
        -0.030607879161834717,
        0.02253470942378044,
        -0.05415268987417221,
        -0.007930269464850426,
        -0.053339213132858276,
        0.04355132207274437,
        -0.0542563833296299,
        -0.03744950890541077,
        0.025406626984477043,
        -0.04010557383298874,
        -0.0534307062625885,
        -0.04983912408351898,
        -0.026880431920289993,
        -0.0387028343975544,
        0.03941286355257034,
        -0.04930242896080017,
        0.052272915840148926,
        0.054735615849494934,
        0.05471077188849449,
        0.04461095109581947,
        0.05465938150882721,
        0.054823681712150574,
        0.04887092858552933,
        0.04940084367990494,
        -0.04705896973609924,
        -0.05479542538523674,
        -0.054498638957738876,
        0.04922028258442879,
        0.03804907947778702,
        0.04937518760561943,
        -0.03733145818114281,
        -0.05246768519282341,
        -0.007919379509985447,
        0.05418575927615166,
        -0.05472024530172348,
        -0.030248159542679787,
        -0.05420554429292679,
        -0.045252326875925064,
        -0.020905788987874985,
        0.023951729759573936,
        -0.031146319583058357,
        -0.05313275381922722,
        0.03968410566449165,
        0.0532485656440258,
        -0.054809536784887314,
        0.05296684429049492,
        0.03834852576255798,
        0.05430185794830322,
        0.014225617051124573,
        -0.05329868942499161,
        -0.026635682210326195,
        0.04911240562796593,
        -0.04510589689016342,
        0.008990702219307423,
        -0.024341989308595657,
        -0.05462522804737091,
        -0.052088603377342224,
        -0.0378967821598053,
        0.04820290952920914,
        0.005040389485657215,
        -0.03431272879242897,
        -0.04141902178525925,
        0.05086705461144447,
        -0.054378535598516464,
        -0.05327434465289116,
        -0.053066592663526535,
        0.05051112920045853,
        -0.05412227287888527,
        -0.05159636586904526,
        -0.016671663150191307,
        -0.029015401378273964,
        0.054101746529340744,
        0.049346186220645905,
        0.01731310971081257,
        0.044154033064842224,
        -0.05380363389849663,
        -0.053676873445510864,
        0.05469132214784622,
        -0.050465647131204605,
        -0.05463697016239166,
        0.05470171943306923,
        -0.05353136360645294,
        -0.003539221826940775,
        0.015425032936036587,
        -0.03929204121232033,
        -0.02989807166159153,
        0.014203733764588833,
        0.05470038950443268,
        0.028434226289391518,
        0.044902950525283813,
        0.05188184604048729,
        -0.02916957437992096,
        -0.04463721811771393,
        -0.0033297445625066757,
        -0.044399719685316086,
        -0.05473927780985832,
        -0.0433596596121788,
        0.012270431034266949,
        0.04591428488492966,
        -0.04789794236421585,
        -0.022479159757494926,
        -0.05274733155965805,
        0.05427490919828415,
        -0.04661472141742706,
        -0.028041446581482887,
        -0.04291628301143646,
        0.05436337739229202,
        0.0532713383436203,
        -0.04038075730204582,
        -0.0011169324861839414,
        0.05428461357951164,
        0.030776945874094963,
        -0.0499332994222641,
        0.023693524301052094,
        -0.05276048928499222,
        -0.021879224106669426,
        -0.0546119250357151,
        0.04278106987476349,
        -0.04202042147517204,
        0.009336770512163639,
        -0.03051426075398922,
        -0.03865687549114227,
        0.05437363311648369,
        -0.024271508678793907,
        -0.0504266582429409,
        0.054297808557748795,
        0.04858051985502243,
        -0.02690267562866211,
        0.011756246909499168,
        0.030137304216623306,
        0.054358869791030884,
        0.05153105407953262,
        -0.013173451647162437,
        -0.05133015289902687,
        -0.05481797084212303,
        -0.034008488059043884,
        0.047755442559719086,
        0.04119575768709183,
        -0.014268480241298676,
        -0.01444515585899353,
        0.0540582500398159,
        0.0032955603674054146,
        -0.043987616896629333,
        0.04992828890681267,
        0.04572443291544914,
        0.016141850501298904,
        -0.024142082780599594,
        -0.05472893640398979,
        0.030168801546096802,
        0.02176607958972454,
        0.05458778515458107,
        0.05134856328368187,
        -0.054116178303956985,
        0.05181121453642845,
        -0.054555997252464294,
        0.04640655592083931,
        0.05444299429655075,
        0.0543886199593544,
        0.0532875694334507,
        -0.051518380641937256,
        0.054657794535160065,
        0.05122792348265648,
        0.054820556193590164,
        0.05439821630716324,
        0.04958539828658104,
        0.04725874587893486,
        0.028691884130239487,
        0.015242536552250385,
        0.054326605051755905,
        -0.04821716621518135,
        0.05095827579498291,
        -0.010099179111421108,
        0.05196789279580116,
        -0.05458715930581093,
        -0.052482444792985916,
        -0.0504339374601841,
        0.03827979788184166,
        0.050651341676712036,
        0.01254984550178051,
        0.05470840260386467,
        0.05471004545688629,
        0.052774447947740555,
        -0.0022039629984647036,
        0.054822832345962524,
        0.0517769493162632,
        0.004608379676938057,
        0.004713332746177912,
        -0.05164970085024834,
        0.05003368854522705,
        -0.04400677606463432,
        0.04696428030729294,
        0.019414355978369713,
        0.03974789381027222,
        -0.04482276737689972,
        -0.04888337105512619,
        -0.05349794775247574,
        0.049951594322919846,
        -0.05117419362068176,
        0.019588710740208626,
        0.05347739905118942,
        0.048879045993089676,
        0.03833543509244919,
        0.03704769164323807,
        0.017796557396650314,
        -0.05381406471133232,
        -0.04752669855952263,
        0.024423928931355476,
        0.05112454667687416,
        -0.05378315597772598,
        0.05471820756793022,
        0.04887660965323448,
        0.053483784198760986,
        -0.012540670111775398,
        -0.03347330912947655,
        0.03820839151740074,
        -0.054805219173431396,
        0.05398592725396156,
        -0.05464288592338562,
        0.04755702242255211,
        0.05289463698863983,
        -0.05248746648430824,
        -0.028525371104478836,
        0.04164198413491249,
        -0.05302103981375694,
        -0.05326392874121666,
        0.05451361462473869,
        -0.05088875815272331,
        -0.049375470727682114,
        0.052882835268974304,
        0.05137423425912857,
        0.00543375127017498,
        0.05366351827979088,
        0.00250697392039001,
        0.05290021747350693,
        -0.053044602274894714,
        0.01401454210281372,
        -0.010395076125860214,
        -0.03664565086364746,
        0.03751307353377342,
        -0.05240393429994583,
        0.05473679304122925,
        0.005166035611182451,
        -0.04573303088545799,
        0.054376743733882904,
        0.048223983496427536,
        0.024170273914933205,
        0.009838158264756203,
        0.05255231633782387,
        -0.03126564621925354,
        -0.014704767614603043,
        -0.017846733331680298,
        0.05475471541285515,
        -0.0005725699011236429,
        -0.05155428126454353,
        0.05003193020820618,
        -0.002240275265648961,
        0.05455780774354935,
        0.043487776070833206,
        -0.04617008939385414,
        -0.05421428754925728,
        0.019326966255903244,
        -0.02841590717434883,
        0.048916660249233246,
        0.03998027369379997,
        -0.045478206127882004,
        0.015299766324460506,
        0.053482167422771454,
        0.02712291292846203,
        0.05409633368253708,
        -0.007599710486829281,
        0.054776329547166824,
        -0.053990159183740616,
        -0.028716666623950005,
        -0.05421574041247368,
        -0.031217370182275772,
        -0.054269056767225266,
        0.05028544366359711,
        0.009033933281898499,
        0.0531017892062664,
        0.025615334510803223,
        0.03468404710292816,
        0.05151816084980965,
        0.04917861521244049,
        0.01927603967487812,
        -0.052641794085502625,
        0.022265279665589333,
        -0.024918340146541595,
        -0.04751162976026535,
        0.04261122643947601,
        0.03631557896733284,
        0.05354173108935356,
        0.05461227148771286,
        0.03675368055701256,
        0.04710346460342407,
        0.05480940639972687,
        -0.014236445538699627,
        -0.009049777872860432,
        -0.05446430295705795,
        -0.0521664172410965,
        -0.039670657366514206,
        -0.05449867248535156,
        -0.053802862763404846,
        -0.043924637138843536,
        -0.0537739023566246,
        0.04850767180323601,
        0.04712437093257904,
        -0.04991242289543152,
        -0.045063018798828125,
        -0.052812639623880386,
        -0.05347692221403122,
        0.05408087372779846,
        -0.048291198909282684,
        0.047749102115631104,
        -0.05408477410674095,
        -0.01651962473988533,
        -0.052333686500787735,
        -0.039307430386543274,
        -0.04359680041670799,
        0.05451938509941101,
        0.05441608279943466,
        -0.020016631111502647,
        0.030346514657139778,
        0.054720424115657806,
        -0.046285517513751984,
        -0.046264685690402985,
        0.031104275956749916,
        -0.05421793460845947,
        0.003926046192646027,
        0.053620871156454086,
        -0.05435224995017052,
        -0.008040832355618477,
        -0.0356992743909359,
        -0.035680562257766724,
        -0.043657951056957245,
        -0.05262492597103119,
        -0.04519321396946907,
        -0.04576302692294121,
        -0.05426225811243057,
        0.04723826050758362,
        0.053783901035785675,
        0.05373871326446533,
        -0.05161131173372269,
        -0.05133730545639992,
        -0.05481139197945595,
        -0.013204221613705158,
        0.05475275218486786,
        -0.04378345608711243,
        0.03487350046634674,
        -0.051876068115234375,
        -0.023411022499203682,
        0.04666150361299515,
        -0.05252224951982498,
        0.05478456988930702,
        0.047080524265766144,
        -0.04892517253756523,
        -0.04556857421994209,
        -0.0437636524438858,
        -0.009725775569677353,
        -0.0034156260080635548,
        -0.05441838875412941,
        -0.054555200040340424,
        0.01403340045362711,
        0.05423096939921379,
        0.05324361473321915,
        0.021143490448594093,
        -0.03138909488916397,
        -0.05368726700544357,
        -0.05471903830766678,
        0.030875498428940773,
        -0.00976882316172123,
        -0.04847557470202446,
        -0.038823314011096954,
        -0.05455442890524864,
        -0.054700084030628204,
        0.0392342284321785,
        0.022461505606770515,
        0.03999895229935646,
        -0.040639594197273254,
        -0.04514312744140625,
        -0.04112991690635681,
        -0.05451652407646179,
        0.049379099160432816,
        -0.04874397814273834,
        -0.053855203092098236,
        0.02411395125091076,
        0.05423980951309204,
        0.0252501480281353,
        0.05386849120259285,
        -0.034011635929346085,
        0.04361711069941521,
        0.054219987243413925,
        -0.05179421231150627,
        0.028274385258555412,
        0.05351605266332626,
        0.042788971215486526,
        0.053311508148908615,
        0.05143151059746742,
        -0.010077789425849915,
        -0.04551171138882637,
        -0.05002514272928238,
        -0.04566420614719391,
        -0.05420086532831192,
        0.03178761899471283,
        0.05264623463153839,
        0.0542215034365654,
        -0.0545906201004982,
        -0.04226678982377052,
        -0.048579685389995575,
        0.05392445623874664,
        0.05475229024887085,
        -0.054806631058454514,
        0.05286025255918503,
        -0.05164723843336105,
        -0.05439405515789986,
        0.0528411902487278,
        0.03348032385110855,
        0.05432426184415817,
        0.05091065913438797,
        0.04054643586277962,
        0.02068670094013214,
        0.03936561942100525,
        0.042455900460481644,
        -0.053918905556201935,
        -0.033745359629392624,
        -0.05246469005942345,
        0.04823023080825806,
        -0.05119703337550163,
        0.03800752013921738,
        -0.026424584910273552,
        0.05241013690829277,
        0.05428606644272804,
        0.03887844830751419,
        -0.05086294934153557,
        -0.03795972838997841,
        0.035045869648456573,
        -0.05373891443014145,
        0.054327961057424545,
        -0.04932812228798866,
        0.016687197610735893,
        -0.04456081613898277,
        0.04113065078854561,
        -0.05008895695209503,
        0.04994799941778183,
        0.05003437027335167,
        -0.05477374047040939,
        0.03902165964245796,
        -0.038531601428985596,
        -0.047537367790937424,
        0.05451032891869545,
        0.05424518510699272,
        0.054660145193338394,
        -0.03202202543616295,
        0.05088742449879646,
        -0.054093047976493835,
        0.05381719395518303,
        -0.01013272162526846
    ],
    [
        0.030011141672730446,
        -0.012923703528940678,
        -0.02330300398170948,
        -0.01839572936296463,
        -0.0545206218957901,
        -0.05122953653335571,
        -0.03458620235323906,
        -0.018951410427689552,
        0.054214417934417725,
        -0.05373336747288704,
        0.05240671709179878,
        0.05092315003275871,
        -0.052737925201654434,
        0.036663636565208435,
        -0.05446339026093483,
        0.05219988524913788,
        0.008118872530758381,
        0.053288791328668594,
        -0.01520266942679882,
        -0.008348848670721054,
        -0.045893535017967224,
        -0.050321485847234726,
        0.05465119332075119,
        0.03740115091204643,
        -0.04573354497551918,
        0.051267918199300766,
        0.009730073623359203,
        -0.05083557963371277,
        -0.054371584206819534,
        -0.05005546659231186,
        -0.03109929710626602,
        -0.025355368852615356,
        0.03205333277583122,
        -0.053866684436798096,
        0.05349624156951904,
        -0.054677996784448624,
        -0.05424373224377632,
        -0.05417080596089363,
        -0.029440827667713165,
        -0.051904287189245224,
        -0.0228445902466774,
        0.036163799464702606,
        0.054089270532131195,
        -0.054202936589717865,
        0.05439750850200653,
        0.0003083693445660174,
        0.05302533879876137,
        0.05468359217047691,
        0.05467533692717552,
        -0.03307720273733139,
        0.05346699059009552,
        0.05468524992465973,
        0.04267891123890877,
        -0.015282539650797844,
        -0.04361511394381523,
        -0.025710001587867737,
        -0.0530686192214489,
        0.05126819759607315,
        -0.046359892934560776,
        -0.05447099730372429,
        -0.01944567635655403,
        0.05192526802420616,
        -0.05180588737130165,
        -0.040373969823122025,
        -0.01751902513206005,
        -0.054368484765291214,
        -0.05437259376049042,
        -0.029763253405690193,
        0.033878207206726074,
        -0.04885848984122276,
        -0.013498072512447834,
        -0.027821725234389305,
        0.05439167469739914,
        0.05422894284129143,
        0.0544578991830349,
        -0.0509677529335022,
        0.04082632437348366,
        0.054692838340997696,
        0.05175120383501053,
        0.025560878217220306,
        -0.010117383673787117,
        -0.03861972317099571,
        -0.05466931685805321,
        0.0545850433409214,
        -0.049192916601896286,
        0.04801180958747864,
        0.04776027426123619,
        -0.0397564060986042,
        -0.018897853791713715,
        0.053425442427396774,
        -0.05290042981505394,
        -0.015090766362845898,
        -0.053907960653305054,
        -0.04874730110168457,
        0.003739170031622052,
        0.037442296743392944,
        -0.053636353462934494,
        -0.031190352514386177,
        -0.04213458299636841,
        0.05355272814631462,
        -0.054686084389686584,
        0.054474156349897385,
        0.0067313481122255325,
        0.05383380502462387,
        0.04715939238667488,
        -0.05429225414991379,
        0.04256628826260567,
        -0.005058153532445431,
        0.020949574187397957,
        -0.05189988762140274,
        0.04156554117798805,
        -0.054590243846178055,
        -0.01870344579219818,
        -0.052135538309812546,
        0.053243327885866165,
        0.03237135335803032,
        -0.054068874567747116,
        -0.04695628583431244,
        -0.00028816238045692444,
        -0.03881865367293358,
        -0.05080270394682884,
        -0.05423060804605484,
        0.05428512394428253,
        -0.05402499809861183,
        -0.04725702479481697,
        -0.05181823670864105,
        -0.030785763636231422,
        0.02640584483742714,
        0.05447573959827423,
        0.036936696618795395,
        0.021413279697299004,
        -0.054170433431863785,
        -0.05191513150930405,
        0.054622065275907516,
        -0.04188686981797218,
        -0.05438831076025963,
        0.019430434331297874,
        -0.054325222969055176,
        0.037685517221689224,
        0.05389227718114853,
        -0.010885707102715969,
        0.025951487943530083,
        0.04455990344285965,
        0.05458657816052437,
        -0.024108022451400757,
        0.04477490484714508,
        0.054549071937799454,
        0.04493589326739311,
        -0.051992591470479965,
        0.0442858561873436,
        -0.025479204952716827,
        -0.04979707673192024,
        -0.03758274018764496,
        -0.037941671907901764,
        0.05466744303703308,
        -0.016105474904179573,
        -0.026033155620098114,
        -0.045583028346300125,
        0.05467713624238968,
        0.04598533734679222,
        0.023872699588537216,
        -0.05336524173617363,
        0.054667629301548004,
        0.019609201699495316,
        0.053806986659765244,
        0.0366334468126297,
        0.05067720636725426,
        0.04099888354539871,
        -0.04379016533493996,
        0.03981797397136688,
        -0.0524563193321228,
        -0.02683442085981369,
        -0.05468342825770378,
        0.05467241629958153,
        -0.054557040333747864,
        -0.04625549912452698,
        -0.04486328363418579,
        0.008619335480034351,
        0.054312918335199356,
        0.013008498586714268,
        -0.0544254295527935,
        0.05468491464853287,
        0.001603583456017077,
        -0.03297153860330582,
        -0.043478429317474365,
        0.052267469465732574,
        0.054082226008176804,
        0.054186899214982986,
        0.013933162204921246,
        -0.05099153146147728,
        -0.054345566779375076,
        -0.044957663863897324,
        -0.004581841640174389,
        -0.05419861525297165,
        -0.05368536338210106,
        0.01613972894847393,
        -0.04283026605844498,
        -0.03378460556268692,
        -0.017334207892417908,
        0.04819386452436447,
        0.024907702580094337,
        -0.007056886795908213,
        0.016345422714948654,
        -0.013485142029821873,
        -0.011049987748265266,
        -0.040305446833372116,
        0.05441053584218025,
        0.03635096549987793,
        0.015098336152732372,
        0.054622583091259,
        -0.0546199269592762,
        0.043747469782829285,
        0.0545792318880558,
        0.054375067353248596,
        -0.03210020437836647,
        -0.01866164244711399,
        0.05458546802401543,
        0.052211564034223557,
        0.054688550531864166,
        0.046601831912994385,
        0.054119642823934555,
        0.018009062856435776,
        0.053529661148786545,
        -0.04047061875462532,
        0.054442085325717926,
        0.04154094308614731,
        -0.000990009866654873,
        -0.033882517367601395,
        0.04584408178925514,
        -0.05452819541096687,
        -0.05366076901555061,
        0.02351677045226097,
        -0.052366070449352264,
        0.051353923976421356,
        -0.020562155172228813,
        0.054688069969415665,
        0.05425938591361046,
        0.009703079238533974,
        -0.013798232190310955,
        0.05469222366809845,
        -0.04320822283625603,
        0.006496629677712917,
        0.048621028661727905,
        -0.05418211594223976,
        0.05239071324467659,
        -0.0516202449798584,
        -0.04217645153403282,
        -0.04393826425075531,
        0.048645809292793274,
        -0.053780052810907364,
        -0.054631203413009644,
        -0.01961701735854149,
        0.05448568984866142,
        -0.05354621633887291,
        0.05107380822300911,
        -0.02719874493777752,
        -0.005556539166718721,
        -0.053464680910110474,
        0.05141123756766319,
        0.05459462106227875,
        -0.05389310047030449,
        -0.027267221361398697,
        -0.049308303743600845,
        0.04086992144584656,
        -0.05290846526622772,
        0.05467906966805458,
        0.053493622690439224,
        0.0445258766412735,
        -0.03769444674253464,
        -0.007523255422711372,
        -0.018447520211338997,
        -0.05468539521098137,
        0.054629843682050705,
        -0.05011531710624695,
        0.027340063825249672,
        0.053444754332304,
        -0.04277816042304039,
        -0.013749262318015099,
        0.052163563668727875,
        -0.021535377949476242,
        -0.045943908393383026,
        0.052409056574106216,
        -0.050183773040771484,
        -0.05262002721428871,
        0.0312642827630043,
        -0.013121643103659153,
        -0.010993478819727898,
        0.05432837828993797,
        -0.022340120747685432,
        0.050382256507873535,
        -0.054669175297021866,
        0.038910359144210815,
        -0.0231946874409914,
        -0.05368238687515259,
        0.05348125472664833,
        -0.048114873468875885,
        0.05410836264491081,
        0.054656654596328735,
        -0.02072739601135254,
        0.05228570103645325,
        0.02837381698191166,
        0.041110534220933914,
        -0.035085469484329224,
        0.046613454818725586,
        -0.04323586821556091,
        0.0465717650949955,
        0.032848406583070755,
        0.054563432931900024,
        -0.011466964147984982,
        -0.04675816372036934,
        0.04490654543042183,
        -0.039790570735931396,
        0.05394446477293968,
        0.05240575224161148,
        -0.03196309879422188,
        -0.05284393951296806,
        -0.029207177460193634,
        -0.04692773520946503,
        0.05395533889532089,
        0.03956712409853935,
        -0.05433439090847969,
        0.053833384066820145,
        0.009896328672766685,
        -0.031138025224208832,
        0.05468084663152695,
        -0.04555630683898926,
        0.051747072488069534,
        -0.0546872578561306,
        -0.05001115798950195,
        -0.0522458590567112,
        -0.0030428015161305666,
        -0.05393291264772415,
        0.022779515013098717,
        -0.03607557713985443,
        0.05022132396697998,
        0.047681838274002075,
        0.041653480380773544,
        0.04056839644908905,
        -0.004856616724282503,
        0.04691017046570778,
        -0.05455750972032547,
        0.05215991660952568,
        -0.0363348163664341,
        0.0030510963406413794,
        0.05368051305413246,
        -0.02306421287357807,
        0.042239394038915634,
        0.053875599056482315,
        0.01573573239147663,
        0.051407139748334885,
        0.05363054201006889,
        -0.00005848888395121321,
        0.019526490941643715,
        -0.05425266921520233,
        -0.04809080809354782,
        -0.024970106780529022,
        -0.05468592420220375,
        -0.05305572226643562,
        -0.027451517060399055,
        -0.029537798836827278,
        0.05396667495369911,
        0.051969029009342194,
        -0.05428525432944298,
        -0.05212392285466194,
        -0.042580947279930115,
        -0.05325022712349892,
        0.054568517953157425,
        -0.0530136339366436,
        -0.0271527711302042,
        -0.05459792912006378,
        -0.041951898485422134,
        -0.05142475664615631,
        -0.02888728491961956,
        -0.054522596299648285,
        0.0425502210855484,
        0.05463806167244911,
        0.05337940528988838,
        0.053866077214479446,
        0.052508458495140076,
        0.05025019124150276,
        -0.04516390338540077,
        0.03497612103819847,
        -0.04594120755791664,
        0.051156334578990936,
        0.048109423369169235,
        -0.05459363013505936,
        -0.01014338806271553,
        -0.0480911061167717,
        -0.05247720703482628,
        -0.05435577407479286,
        -0.050978027284145355,
        -0.046551961451768875,
        -0.054325833916664124,
        -0.053288206458091736,
        0.050481926649808884,
        0.05392095446586609,
        0.02160661481320858,
        -0.054113589227199554,
        -0.053990438580513,
        -0.005359872709959745,
        0.046699076890945435,
        0.05467335879802704,
        -0.0473664365708828,
        0.04880503937602043,
        -0.054432377219200134,
        -0.04613586887717247,
        0.05152985826134682,
        -0.054503776133060455,
        0.05361896753311157,
        0.044987112283706665,
        -0.028219474479556084,
        -0.045569080859422684,
        -0.010973605327308178,
        0.04018675535917282,
        0.042516082525253296,
        -0.05393850430846214,
        -0.05416443198919296,
        0.009169182740151882,
        0.05416006222367287,
        0.05281592160463333,
        0.027974434196949005,
        -0.05269765481352806,
        -0.04601617902517319,
        -0.05468692258000374,
        -0.028055405244231224,
        0.03284073993563652,
        -0.03422826528549194,
        0.03864803537726402,
        -0.053761303424835205,
        -0.054439984261989594,
        0.053851280361413956,
        -0.052721187472343445,
        -0.04220336303114891,
        -0.05047859996557236,
        -0.05413783714175224,
        -0.049522820860147476,
        -0.054587461054325104,
        0.054645147174596786,
        -0.054133255034685135,
        -0.05291815474629402,
        0.01146682444959879,
        0.05439468100667,
        0.04897614195942879,
        0.05415913090109825,
        -0.03222346305847168,
        -0.035982098430395126,
        0.053362250328063965,
        -0.05466649308800697,
        0.05342675372958183,
        0.0339696928858757,
        -0.017430955544114113,
        -0.0034556707832962275,
        0.05466091260313988,
        0.0346645824611187,
        -0.04632975533604622,
        -0.052145011723041534,
        0.020339947193861008,
        -0.04983885958790779,
        -0.043678563088178635,
        0.04784664884209633,
        0.053338292986154556,
        -0.05466926842927933,
        -0.016271816566586494,
        -0.02388070523738861,
        0.048606764525175095,
        0.05410809442400932,
        -0.048781123012304306,
        0.054510559886693954,
        -0.043097805231809616,
        -0.05390508100390434,
        -0.007243421860039234,
        0.028787625953555107,
        0.04706462472677231,
        0.01534612849354744,
        -0.018911652266979218,
        0.052681706845760345,
        0.051202256232500076,
        0.05438646674156189,
        -0.05413888022303581,
        -0.013232233002781868,
        -0.054067421704530716,
        -0.03690938279032707,
        -0.05468183010816574,
        0.036543089896440506,
        -0.007102809846401215,
        0.05430101975798607,
        0.05270099639892578,
        0.05405507609248161,
        -0.05462250858545303,
        0.045800041407346725,
        -0.03762323036789894,
        -0.05368150770664215,
        0.031062094494700432,
        0.008289841935038567,
        -0.003763742744922638,
        -0.048425380140542984,
        0.04657108709216118,
        -0.051735565066337585,
        0.045047130435705185,
        0.005810655187815428,
        -0.050383687019348145,
        0.0030699963681399822,
        -0.049411170184612274,
        -0.02297075465321541,
        0.05390982702374458,
        0.05427585914731026,
        0.05468003824353218,
        0.019913028925657272,
        0.03797243908047676,
        -0.05468239262700081,
        0.021602021530270576,
        0.03361574932932854
    ],
    [
        0.022937005385756493,
        0.036454230546951294,
        -0.006162924226373434,
        -0.015697572380304337,
        -0.023311104625463486,
        0.01394307054579258,
        0.011654103174805641,
        -0.03696900233626366,
        0.05788056179881096,
        0.04460028186440468,
        0.05389833822846413,
        -0.007090787403285503,
        -0.05474897101521492,
        0.056490376591682434,
        -0.057468824088573456,
        0.0538492426276207,
        -0.022395700216293335,
        0.05712864175438881,
        0.05307561904191971,
        0.050909049808979034,
        -0.05420348048210144,
        -0.01753305457532406,
        0.022996166720986366,
        -0.01122315414249897,
        -0.05091755837202072,
        0.037145256996154785,
        0.0385473370552063,
        0.04806813225150108,
        -0.04131465032696724,
        0.05040207505226135,
        -0.05441586300730705,
        -0.044819850474596024,
        -0.013834794983267784,
        -0.05778723955154419,
        0.04050790145993233,
        -0.021639540791511536,
        -0.05714256316423416,
        -0.057702623307704926,
        0.051041968166828156,
        -0.05425921827554703,
        0.04286527633666992,
        -0.009679083712399006,
        0.04465934634208679,
        0.05070172995328903,
        0.05465990677475929,
        -0.03593797609210014,
        0.031253356486558914,
        0.05785251781344414,
        0.05779678001999855,
        -0.05511675402522087,
        0.05726969614624977,
        0.030981186777353287,
        -0.02882128581404686,
        -0.048168014734983444,
        0.04146818071603775,
        0.0039602904580533504,
        -0.040500178933143616,
        0.03528176620602608,
        -0.0061378818936645985,
        0.054334577172994614,
        -0.04790695384144783,
        0.04977494105696678,
        -0.05510500445961952,
        -0.050993967801332474,
        -0.010794724337756634,
        -0.057915084064006805,
        -0.0578102245926857,
        0.03146247938275337,
        -0.048350825905799866,
        -0.05790150165557861,
        -0.03655954450368881,
        -0.04525759443640709,
        0.057551488280296326,
        0.055140793323516846,
        -0.04306260123848915,
        -0.047769296914339066,
        0.008618897758424282,
        0.05796017125248909,
        -0.04555603489279747,
        0.0470060333609581,
        -0.022633256390690804,
        -0.053638994693756104,
        -0.057578183710575104,
        0.05744388699531555,
        -0.006434301380068064,
        0.04961772263050079,
        -0.053183663636446,
        -0.04282746836543083,
        -0.0028783108573406935,
        -0.004090616945177317,
        -0.022191321477293968,
        0.01616397313773632,
        -0.03628641739487648,
        -0.049223218113183975,
        0.00856500118970871,
        -0.0396716482937336,
        -0.056996241211891174,
        -0.05058250203728676,
        0.03937564045190811,
        0.05205899104475975,
        -0.05789828300476074,
        0.05220694839954376,
        0.04047447070479393,
        0.05739021301269531,
        -0.015903206542134285,
        -0.05593162402510643,
        0.005254455376416445,
        0.04003069922327995,
        -0.043371789157390594,
        -0.054201602935791016,
        -0.03112737648189068,
        0.018357668071985245,
        -0.02492227591574192,
        -0.02844514511525631,
        0.045630574226379395,
        -0.03423771262168884,
        0.001514822244644165,
        -0.05116942152380943,
        0.05788865312933922,
        -0.011677158996462822,
        -0.04725363105535507,
        -0.05640018358826637,
        0.05310158059000969,
        -0.057084955275058746,
        -0.04093709960579872,
        -0.05710193142294884,
        0.05327030271291733,
        0.05759359151124954,
        0.05057094246149063,
        0.05168065428733826,
        0.007898596115410328,
        -0.04978882148861885,
        -0.046793971210718155,
        0.012185417115688324,
        -0.046974293887615204,
        -0.05785112455487251,
        0.05776209384202957,
        -0.047873955219984055,
        -0.0089243333786726,
        0.04656929895281792,
        0.009903540834784508,
        0.0550960898399353,
        0.05213221535086632,
        0.05401342734694481,
        0.05083291977643967,
        0.046319346874952316,
        0.012179599143564701,
        -0.05707851052284241,
        -0.03712380677461624,
        -0.04808671399950981,
        -0.02465120144188404,
        -0.05694045126438141,
        0.010854619555175304,
        0.03643454238772392,
        0.057819999754428864,
        -0.009080932475626469,
        -0.04409734532237053,
        0.0008294959552586079,
        0.05781900882720947,
        0.04827312380075455,
        -0.015493125654757023,
        -0.047404106706380844,
        0.05762187018990517,
        0.012050298042595387,
        -0.0564505010843277,
        -0.02434455417096615,
        0.057382576167583466,
        0.04677887260913849,
        0.03952190279960632,
        0.03850361704826355,
        -0.057857196778059006,
        -0.0231962651014328,
        -0.028675831854343414,
        0.05749655142426491,
        -0.05533290281891823,
        -0.011492854915559292,
        0.04638476297259331,
        -0.020778270438313484,
        0.05402010679244995,
        -0.048419199883937836,
        -0.04744577407836914,
        0.035568248480558395,
        -0.04810713231563568,
        0.03088678978383541,
        0.03618527948856354,
        0.02766953594982624,
        -0.03536627069115639,
        0.057254891842603683,
        -0.05176813155412674,
        -0.04505343735218048,
        -0.05411098524928093,
        -0.036819785833358765,
        0.05218243971467018,
        0.04261815920472145,
        -0.05659300088882446,
        -0.02907598577439785,
        0.008742696605622768,
        0.04823659360408783,
        -0.021784590557217598,
        0.05076880007982254,
        -0.04673634469509125,
        -0.015769613906741142,
        -0.025684598833322525,
        -0.04968066141009331,
        0.03637854382395744,
        -0.019081871956586838,
        0.05786338448524475,
        0.028241166844964027,
        -0.05671152099967003,
        0.056734349578619,
        -0.057130780071020126,
        0.045346081256866455,
        0.05750339478254318,
        0.05777932330965996,
        0.0035327451769262552,
        -0.01037287525832653,
        0.05680008605122566,
        0.04567033797502518,
        0.05794326961040497,
        0.017083995044231415,
        0.029362432658672333,
        -0.050096627324819565,
        0.04057526960968971,
        0.0492316372692585,
        0.053261321038007736,
        -0.026482820510864258,
        0.024443071335554123,
        -0.05746622011065483,
        -0.007090241182595491,
        -0.055810555815696716,
        -0.05534018203616142,
        -0.056459683924913406,
        0.03526802361011505,
        0.05266926437616348,
        0.05434577167034149,
        0.057822976261377335,
        0.052096374332904816,
        -0.0011439122026786208,
        -0.014714698307216167,
        0.057959843426942825,
        -0.004826810210943222,
        0.03145980462431908,
        0.04805848374962807,
        -0.05742611736059189,
        0.042681269347667694,
        0.03560492396354675,
        -0.034477073699235916,
        0.05536888167262077,
        0.04936254769563675,
        -0.015183983370661736,
        -0.05431417003273964,
        -0.05708199366927147,
        0.05787558853626251,
        -0.05706885829567909,
        0.01745191216468811,
        -0.021052371710538864,
        0.05570044741034508,
        -0.02252325229346752,
        0.05483514070510864,
        0.011771740391850471,
        -0.05694489926099777,
        0.01832442916929722,
        0.034154098480939865,
        -0.0075164963491261005,
        0.04176371172070503,
        0.05474933981895447,
        0.045785728842020035,
        0.042303916066884995,
        -0.04285166785120964,
        0.057528406381607056,
        -0.04417134448885918,
        -0.057198598980903625,
        0.05794810876250267,
        -0.04292375221848488,
        0.03595460206270218,
        0.028710685670375824,
        -0.04971402883529663,
        0.028603674843907356,
        0.048343367874622345,
        -0.057737331837415695,
        -0.03744667395949364,
        0.05557642877101898,
        -0.012352034449577332,
        -0.0063910698518157005,
        -0.04653187096118927,
        0.03367483243346214,
        0.05141035467386246,
        0.057951606810092926,
        0.0003283121914137155,
        0.014149460941553116,
        -0.0560527965426445,
        -0.01572749763727188,
        -0.05539556220173836,
        -0.03726643696427345,
        0.05072471499443054,
        -0.0563427209854126,
        0.05599468573927879,
        0.052736394107341766,
        -0.057300932705402374,
        0.055496908724308014,
        0.03920064866542816,
        0.05570405349135399,
        0.008211073465645313,
        0.05647185072302818,
        -0.056821197271347046,
        0.036633946001529694,
        -0.05550254508852959,
        0.05792666971683502,
        0.030662570148706436,
        0.03232317045331001,
        0.04898650199174881,
        -0.04388698562979698,
        0.05094024911522865,
        -0.016039809212088585,
        -0.028649674728512764,
        -0.051387663930654526,
        -0.053779736161231995,
        0.057116929441690445,
        0.045410070568323135,
        0.020368410274386406,
        -0.024140479043126106,
        -0.057597652077674866,
        0.03966251388192177,
        0.021495752036571503,
        0.057655613869428635,
        -0.037435080856084824,
        0.05548984929919243,
        -0.05620700493454933,
        -0.03629384934902191,
        -0.04259958118200302,
        -0.040657710283994675,
        -0.05506330356001854,
        0.05793726444244385,
        0.042093344032764435,
        0.055574506521224976,
        0.04560154676437378,
        -0.027635086327791214,
        0.04418421536684036,
        -0.02827395871281624,
        0.0548793189227581,
        -0.04456228017807007,
        -0.025494642555713654,
        -0.03847251832485199,
        0.042658403515815735,
        -0.042144548147916794,
        0.023854253813624382,
        0.030258456245064735,
        0.05589444935321808,
        0.02406797558069229,
        0.04378865659236908,
        0.05790327861905098,
        0.04651521146297455,
        -0.004278951324522495,
        -0.048714663833379745,
        -0.0008358803461305797,
        0.006523072253912687,
        -0.05795503407716751,
        -0.05174115300178528,
        -0.04921986535191536,
        0.031361326575279236,
        0.05708540603518486,
        0.008934973739087582,
        0.025872502475976944,
        -0.05497228726744652,
        -0.010366939008235931,
        -0.056321047246456146,
        0.057866208255290985,
        0.005004227627068758,
        0.03966934606432915,
        -0.057886041700839996,
        -0.014085927978157997,
        -0.05567241832613945,
        0.048732053488492966,
        -0.05765397474169731,
        0.045490749180316925,
        0.05768260359764099,
        0.007689520716667175,
        0.04518663510680199,
        0.05613073334097862,
        -0.019905835390090942,
        -0.05318095535039902,
        0.04814242571592331,
        -0.04640834406018257,
        -0.01397558394819498,
        0.05102556198835373,
        -0.0571674183011055,
        0.03149496391415596,
        -0.004990837071090937,
        -0.057893384248018265,
        -0.057744208723306656,
        -0.057299576699733734,
        -0.05775166675448418,
        -0.043717481195926666,
        -0.05062790960073471,
        0.02605360932648182,
        0.057009197771549225,
        0.025051839649677277,
        0.002697442891076207,
        -0.022212421521544456,
        -0.05630233511328697,
        -0.04992426559329033,
        0.0578250028192997,
        -0.038079794496297836,
        0.05383887514472008,
        -0.05445801094174385,
        0.03083902783691883,
        0.031678225845098495,
        -0.05089147761464119,
        0.05655093491077423,
        0.0012643607333302498,
        -0.050167232751846313,
        -0.056376975029706955,
        0.02674487605690956,
        0.03880370035767555,
        0.05305355787277222,
        -0.04037797078490257,
        -0.054418276995420456,
        -0.02365153096616268,
        0.05375904589891434,
        0.03577059879899025,
        -0.036041632294654846,
        -0.055005814880132675,
        0.023983405902981758,
        -0.05793747678399086,
        0.035200901329517365,
        0.005617970135062933,
        0.04200534522533417,
        0.04917281121015549,
        -0.05662759393453598,
        -0.05588400736451149,
        0.043559253215789795,
        -0.03935514762997627,
        -0.05791117623448372,
        -0.05727466568350792,
        -0.04350000247359276,
        -0.03916184604167938,
        -0.05711093917489052,
        0.056223541498184204,
        -0.05763604864478111,
        -0.04414832219481468,
        0.029804695397615433,
        0.05334797501564026,
        0.02257465198636055,
        0.052899252623319626,
        0.0378248430788517,
        0.01493380032479763,
        0.0556412972509861,
        0.035825397819280624,
        0.05567816272377968,
        -0.03570082411170006,
        0.0018300149822607636,
        0.004164664074778557,
        0.054030224680900574,
        0.04523579031229019,
        -0.028908470645546913,
        -0.05065269023180008,
        -0.046943601220846176,
        -0.04504856467247009,
        -0.013288524933159351,
        0.003345250152051449,
        0.05200332775712013,
        -0.05775336176156998,
        -0.036933064460754395,
        0.05627688765525818,
        0.05418780446052551,
        0.05545083060860634,
        -0.05287151411175728,
        0.05789108946919441,
        0.002269822871312499,
        -0.04965965449810028,
        0.05499941483139992,
        0.05374738946557045,
        0.043863922357559204,
        0.03535177558660507,
        -0.05650946870446205,
        0.014868704602122307,
        0.03002779558300972,
        0.028350884094834328,
        -0.04683230444788933,
        -0.05623989552259445,
        0.05216975882649422,
        -0.04753313586115837,
        -0.05748174339532852,
        0.042403340339660645,
        -0.05567748844623566,
        0.052985623478889465,
        0.04322120174765587,
        0.05444689840078354,
        -0.05731844902038574,
        -0.04873122274875641,
        -0.05479472503066063,
        -0.05641261488199234,
        -0.055793143808841705,
        -0.021357860416173935,
        -0.015347287990152836,
        0.036038972437381744,
        0.05790315195918083,
        -0.056792568415403366,
        0.04522155970335007,
        0.05694353207945824,
        -0.037093162536621094,
        0.04459340125322342,
        0.03450552374124527,
        -0.05685910955071449,
        0.02478983998298645,
        0.04748392105102539,
        0.04558832198381424,
        -0.03953671455383301,
        -0.03706252574920654,
        -0.04682692512869835,
        0.05656140297651291,
        -0.01629021391272545
    ],
    [
        -0.00698462687432766,
        0.0399736724793911,
        0.0522218719124794,
        -0.002656487748026848,
        0.015836166217923164,
        0.006186520680785179,
        0.049166254699230194,
        -0.05249865725636482,
        0.052373256534338,
        -0.05259399488568306,
        0.052473053336143494,
        0.014589748345315456,
        -0.05246027186512947,
        -0.015507569536566734,
        -0.05056102201342583,
        0.035859011113643646,
        -0.052379511296749115,
        0.05150517076253891,
        -0.000002345000439163414,
        -0.05257619172334671,
        0.05250035226345062,
        0.05259280651807785,
        0.05258728563785553,
        0.03673689439892769,
        -0.04958319291472435,
        -0.052102528512477875,
        -0.02461990900337696,
        0.0524902381002903,
        -0.05253008380532265,
        0.052124083042144775,
        0.05225845053792,
        -0.0525943748652935,
        0.05182458087801933,
        -0.05259300395846367,
        0.04816573113203049,
        -0.04815925285220146,
        -0.051896411925554276,
        -0.039897751063108444,
        0.04913601279258728,
        -0.026619303971529007,
        0.035106360912323,
        0.037072304636240005,
        0.04126213118433952,
        0.05067821964621544,
        -0.04981551691889763,
        -0.03601643815636635,
        0.05084254965186119,
        0.052518006414175034,
        0.052518654614686966,
        -0.05252499878406525,
        0.0521121472120285,
        -0.04838951677083969,
        -0.010803263634443283,
        -0.05161094292998314,
        0.050047408789396286,
        0.052168093621730804,
        0.04380633682012558,
        0.00530135678127408,
        0.03876138851046562,
        -0.05250600725412369,
        -0.0404086597263813,
        0.031941968947649,
        -0.052221521735191345,
        -0.05098451301455498,
        -0.051056526601314545,
        -0.05247944965958595,
        -0.0525907464325428,
        -0.05220992863178253,
        0.0456281378865242,
        -0.05228046700358391,
        -0.05146397277712822,
        -0.05122356116771698,
        0.04861969128251076,
        0.0323384664952755,
        -0.0483817532658577,
        0.0011641718447208405,
        -0.04646052047610283,
        0.05259467661380768,
        -0.01799839921295643,
        -0.045190002769231796,
        -0.0457351990044117,
        -0.05094686150550842,
        -0.05259266123175621,
        0.04882652312517166,
        -0.022213706746697426,
        0.04980388283729553,
        -0.05207245051860809,
        -0.03748178854584694,
        0.0367211252450943,
        0.0522422231733799,
        -0.04405210167169571,
        -0.05121959373354912,
        -0.05072144791483879,
        -0.052463334053754807,
        -0.005034612026065588,
        0.05220036208629608,
        0.00828875508159399,
        -0.021617773920297623,
        -0.023785706609487534,
        0.05137190222740173,
        -0.05254202336072922,
        0.024393616244196892,
        0.006979142315685749,
        0.05120836943387985,
        0.03146102651953697,
        -0.04888085275888443,
        0.05179005116224289,
        0.023205196484923363,
        -0.0396757610142231,
        0.04992562532424927,
        -0.052234213799238205,
        -0.048144467175006866,
        0.0427783764898777,
        -0.037716008722782135,
        0.051808830350637436,
        -0.0500878170132637,
        0.04395107552409172,
        -0.032738134264945984,
        0.03846922144293785,
        -0.04707886278629303,
        0.005128924269229174,
        -0.04685774818062782,
        0.028517277911305428,
        -0.052377380430698395,
        -0.041172076016664505,
        0.042624976485967636,
        -0.05127200111746788,
        0.05177935212850571,
        0.04754355922341347,
        0.0524093322455883,
        0.03235866129398346,
        -0.0523412860929966,
        -0.013077432289719582,
        0.05249868333339691,
        -0.03960811346769333,
        -0.05233953520655632,
        0.045244500041007996,
        0.036696963012218475,
        -0.020741814747452736,
        0.05181797593832016,
        -0.04882565140724182,
        0.04604309797286987,
        0.04968472570180893,
        0.052231770008802414,
        0.01707192324101925,
        0.04729243367910385,
        -0.017980225384235382,
        0.04758991301059723,
        -0.051472410559654236,
        0.052585069090127945,
        0.05243083834648132,
        -0.05237851291894913,
        -0.05131366476416588,
        0.05103577300906181,
        0.05259467661380768,
        0.008158454671502113,
        0.05224590748548508,
        0.040154147893190384,
        -0.043825868517160416,
        -0.033598724752664566,
        0.011006684973835945,
        0.04991888627409935,
        0.05008247494697571,
        -0.04974186420440674,
        0.007610208820551634,
        0.03840382397174835,
        0.05191236361861229,
        -0.04507526755332947,
        -0.04057209938764572,
        0.015628688037395477,
        -0.04046153277158737,
        -0.05258677899837494,
        -0.05258529260754585,
        -0.01165066473186016,
        0.04920272156596184,
        0.04731122776865959,
        0.041876085102558136,
        0.03636030852794647,
        0.04836481064558029,
        -0.044984906911849976,
        -0.05221282318234444,
        -0.049101028591394424,
        -0.011752257123589516,
        -0.05024479702115059,
        0.0493900328874588,
        0.03900232911109924,
        0.052438512444496155,
        0.05173397436738014,
        0.013017061166465282,
        0.009417927823960781,
        -0.05236576125025749,
        -0.024129951372742653,
        -0.04875533655285835,
        -0.05191659554839134,
        -0.05254668369889259,
        -0.0524541474878788,
        -0.04875056445598602,
        0.051243845373392105,
        -0.0525713674724102,
        0.051139913499355316,
        0.052589673548936844,
        0.051179349422454834,
        -0.05220342427492142,
        -0.052545759826898575,
        0.04087473824620247,
        0.04749162122607231,
        0.028173141181468964,
        0.010288283228874207,
        -0.029499342665076256,
        0.05246715247631073,
        -0.0524187907576561,
        -0.02745426818728447,
        0.05156514793634415,
        0.05181695148348808,
        -0.05258960649371147,
        -0.05193538963794708,
        0.05104506388306618,
        -0.038492582738399506,
        0.05097755789756775,
        -0.052543170750141144,
        -0.052408069372177124,
        -0.05218757688999176,
        0.043291203677654266,
        -0.05204113572835922,
        0.04573012515902519,
        0.050004228949546814,
        0.051834553480148315,
        -0.04212474450469017,
        0.05076441541314125,
        -0.034240901470184326,
        -0.05052297189831734,
        -0.05092396214604378,
        -0.047634024173021317,
        0.04475472494959831,
        0.00689999433234334,
        0.05057147145271301,
        0.04543134570121765,
        0.027117932215332985,
        0.052484482526779175,
        0.052594657987356186,
        0.042844608426094055,
        0.015982817858457565,
        0.05225571617484093,
        -0.05223395302891731,
        -0.007076109293848276,
        -0.05093972757458687,
        0.05208044871687889,
        -0.04680611193180084,
        -0.051842786371707916,
        -0.026382500305771828,
        0.05235377699136734,
        0.013480588793754578,
        0.0521390363574028,
        -0.05167308449745178,
        -0.02030266635119915,
        0.05227745324373245,
        0.03188273683190346,
        0.0027189466636627913,
        0.04581799358129501,
        -0.04163070395588875,
        -0.05198647826910019,
        0.04575362429022789,
        0.04468066990375519,
        0.052235595881938934,
        -0.043804820626974106,
        0.05253985524177551,
        0.05259452387690544,
        0.052594512701034546,
        0.05046379938721657,
        0.02711675316095352,
        0.039432600140571594,
        -0.05165138468146324,
        0.0525798499584198,
        -0.05157429352402687,
        0.05235893651843071,
        0.027275610715150833,
        -0.04277890548110008,
        -0.051896728575229645,
        -0.05259273573756218,
        -0.008731812238693237,
        -0.047599710524082184,
        -0.002868117531761527,
        0.03648737445473671,
        0.05048099905252457,
        0.028291944414377213,
        0.05198860913515091,
        -0.035655319690704346,
        0.046680748462677,
        0.0524645671248436,
        -0.0005902398843318224,
        -0.05226911976933479,
        0.04345698654651642,
        -0.03262394666671753,
        -0.02585676498711109,
        0.04937603697180748,
        -0.0517115443944931,
        0.052582502365112305,
        0.03678654506802559,
        -0.020765095949172974,
        0.050961174070835114,
        -0.03279799595475197,
        0.024102607741951942,
        0.0065485150553286076,
        0.04994635656476021,
        -0.051959145814180374,
        -0.048845451325178146,
        -0.0355963297188282,
        0.052558958530426025,
        0.05133633315563202,
        -0.013635825365781784,
        0.030203241854906082,
        -0.03142383694648743,
        -0.04801762476563454,
        0.05223705247044563,
        0.006805786397308111,
        -0.05136234685778618,
        0.0023189904168248177,
        0.052594322711229324,
        0.05190715193748474,
        0.0515444353222847,
        -0.04920823872089386,
        -0.03422956168651581,
        0.052525606006383896,
        -0.04784854128956795,
        0.050247274339199066,
        -0.01116552110761404,
        0.004684542305767536,
        -0.003615239867940545,
        0.04894280061125755,
        -0.051300086081027985,
        0.05219772458076477,
        -0.03531993180513382,
        0.032439857721328735,
        0.052594635635614395,
        0.04296669363975525,
        0.052556633949279785,
        -0.05257800966501236,
        -0.03308321163058281,
        0.03870397061109543,
        0.040025077760219574,
        -0.05227077752351761,
        -0.0525880791246891,
        0.013479571789503098,
        0.05217583477497101,
        -0.010987377725541592,
        0.05058781057596207,
        0.05247366428375244,
        0.05147566273808479,
        -0.05079915001988411,
        0.048151832073926926,
        0.052593596279621124,
        0.040884070098400116,
        0.04956420138478279,
        -0.05180612951517105,
        -0.05079149454832077,
        -0.026587286964058876,
        -0.05244226008653641,
        -0.052473582327365875,
        -0.03326469287276268,
        -0.03944743424654007,
        0.03852207213640213,
        0.05050256848335266,
        -0.04936770349740982,
        -0.046234145760536194,
        -0.0515299066901207,
        -0.04771454632282257,
        0.052585210651159286,
        0.005963522009551525,
        0.0314229279756546,
        -0.05143342539668083,
        -0.03028555028140545,
        -0.0031719619873911142,
        -0.03307405486702919,
        -0.05240730568766594,
        0.050551705062389374,
        0.05259467661380768,
        -0.05122449994087219,
        0.05251002684235573,
        0.05256349965929985,
        -0.05230982229113579,
        -0.051434796303510666,
        0.028188051655888557,
        -0.044569771736860275,
        -0.02544761821627617,
        0.03243691846728325,
        -0.05093228071928024,
        -0.042397890239953995,
        -0.04809387028217316,
        -0.05017828941345215,
        -0.05228205397725105,
        -0.05230117216706276,
        -0.0034692101180553436,
        -0.05027139186859131,
        -0.04459282383322716,
        0.014282905496656895,
        0.052179012447595596,
        0.050985801964998245,
        -0.05031635984778404,
        -0.05145137012004852,
        -0.049134813249111176,
        -0.02574159763753414,
        0.05235370621085167,
        -0.051696762442588806,
        0.012722996063530445,
        0.03540955111384392,
        0.04433741793036461,
        0.05242208391427994,
        -0.05233602225780487,
        0.05084576457738876,
        -0.023596253246068954,
        -0.02867046929895878,
        0.045057717710733414,
        -0.016177447512745857,
        0.001925889984704554,
        0.04185258597135544,
        -0.05184029042720795,
        -0.039673857390880585,
        0.05241677537560463,
        0.0518626794219017,
        0.028722047805786133,
        0.015704963356256485,
        -0.046095896512269974,
        -0.046584371477365494,
        -0.05253668501973152,
        -0.026969721540808678,
        0.02272075042128563,
        -0.052185896784067154,
        -0.020865166559815407,
        -0.024610931053757668,
        -0.052553851157426834,
        0.05259466543793678,
        0.004325545858591795,
        0.04973774030804634,
        0.05249156057834625,
        -0.04718173295259476,
        -0.05195147916674614,
        -0.051951777189970016,
        0.05214283987879753,
        -0.015309859067201614,
        -0.05200421065092087,
        -0.05256938561797142,
        0.05145350098609924,
        0.029201732948422432,
        0.04829833284020424,
        0.029277967289090157,
        -0.018172288313508034,
        0.052406005561351776,
        -0.04334167018532753,
        0.04386484622955322,
        0.051968906074762344,
        -0.05165620148181915,
        0.051867056638002396,
        0.039704933762550354,
        -0.035339709371328354,
        0.052475836127996445,
        -0.04974459856748581,
        -0.012498820200562477,
        -0.04229878634214401,
        -0.02072552591562271,
        -0.043468229472637177,
        0.049654170870780945,
        -0.04669909179210663,
        0.013150998391211033,
        -0.03407781198620796,
        0.05250419303774834,
        0.05239086225628853,
        -0.051850710064172745,
        0.052583202719688416,
        0.02726469561457634,
        -0.05241263285279274,
        0.05208618566393852,
        0.042764563113451004,
        0.05078226327896118,
        0.05259464681148529,
        -0.018274065107107162,
        -0.030157124623656273,
        0.04423346370458603,
        0.052541885524988174,
        -0.05231526121497154,
        -0.05254965275526047,
        0.052418436855077744,
        0.04477563127875328,
        -0.05258023738861084,
        0.045050300657749176,
        -0.04754733666777611,
        0.04826967790722847,
        0.045975543558597565,
        0.04179883375763893,
        -0.05215316265821457,
        -0.037556249648332596,
        0.05258653312921524,
        0.04697369784116745,
        0.01196138747036457,
        0.05042058229446411,
        -0.023592505604028702,
        -0.04525281861424446,
        0.05216094106435776,
        -0.04969283565878868,
        0.04505547136068344,
        0.05238748714327812,
        -0.05215659737586975,
        0.04760641232132912,
        -0.04307039827108383,
        -0.05252394452691078,
        0.05258297547698021,
        0.04637284204363823,
        0.05136591196060181,
        -0.030590074136853218,
        0.049302101135253906,
        -0.052546143531799316,
        -0.04804594814777374,
        0.008946138434112072
    ],
    [
        -0.05511261522769928,
        0.0038175692316144705,
        0.05659843236207962,
        0.019534975290298462,
        0.056028205901384354,
        -0.05364002287387848,
        0.05510694906115532,
        -0.04245902597904205,
        0.05432616546750069,
        0.04286552593111992,
        0.02515239454805851,
        0.058034639805555344,
        -0.057941362261772156,
        -0.04144644737243652,
        -0.056345488876104355,
        0.03925098478794098,
        -0.05941348895430565,
        0.05259689316153526,
        -0.05874156951904297,
        -0.059347569942474365,
        0.046962302178144455,
        0.05927509441971779,
        0.0588870532810688,
        0.045304786413908005,
        0.017011068761348724,
        -0.04833424091339111,
        0.004935674834996462,
        0.056086331605911255,
        -0.04174527898430824,
        -0.002735511399805546,
        0.05254267156124115,
        -0.05947113782167435,
        0.04617566615343094,
        -0.0582331046462059,
        -0.021643029525876045,
        -0.04789270833134651,
        -0.05372083559632301,
        -0.0354594811797142,
        -0.05069644749164581,
        -0.01967901550233364,
        -0.0021728400606662035,
        0.0059056514874100685,
        0.0577569417655468,
        -0.00681708101183176,
        -0.034726858139038086,
        0.005694251973181963,
        0.05881715193390846,
        0.05635131150484085,
        0.056141484528779984,
        0.008486218750476837,
        -0.005927094258368015,
        -0.05775583162903786,
        0.05622517690062523,
        -0.03986745700240135,
        0.008266717195510864,
        0.05036473646759987,
        0.04041491821408272,
        -0.007782505825161934,
        0.04578408971428871,
        -0.04341348260641098,
        -0.04927922785282135,
        -0.04346638545393944,
        -0.05664718523621559,
        0.036823660135269165,
        -0.04215726628899574,
        0.022218959406018257,
        -0.00022548135893885046,
        -0.05423806607723236,
        -0.005548292770981789,
        -0.05959733948111534,
        -0.059262197464704514,
        -0.05923125147819519,
        0.020018313080072403,
        -0.046939101070165634,
        -0.05846037715673447,
        -0.006063375622034073,
        -0.02360784076154232,
        0.058839406818151474,
        -0.03414096683263779,
        -0.05878284573554993,
        -0.03424200415611267,
        0.045259807258844376,
        -0.058586131781339645,
        0.03348330780863762,
        0.022775327786803246,
        0.04688139632344246,
        -0.05295427516102791,
        -0.00547565845772624,
        0.032926738262176514,
        0.05778343603014946,
        -0.04701393470168114,
        0.04222794994711876,
        0.002180964220315218,
        -0.050777312368154526,
        0.020129622891545296,
        0.013243562541902065,
        -0.04468041658401489,
        -0.053422555327415466,
        -0.0019306100439280272,
        0.0027053223457187414,
        -0.05935761705040932,
        -0.006318490486592054,
        0.0558980330824852,
        0.03303002566099167,
        0.01675393059849739,
        -0.02134254202246666,
        0.04762062057852745,
        0.04981987550854683,
        -0.05124017596244812,
        -0.03004409745335579,
        -0.05927924066781998,
        -0.057922933250665665,
        -0.04244455322623253,
        0.0075355046428740025,
        0.036024220287799835,
        -0.046225886791944504,
        0.05092090740799904,
        -0.05083911120891571,
        -0.051840461790561676,
        -0.007120154798030853,
        0.013117865659296513,
        -0.013204306364059448,
        -0.04668615013360977,
        0.013970525935292244,
        -0.05551813915371895,
        -0.0576481930911541,
        -0.0418875478208065,
        0.058285705745220184,
        0.05475654825568199,
        0.05255561321973801,
        0.040084678679704666,
        -0.031039932742714882,
        0.015999561175704002,
        0.024986226111650467,
        -0.05066255107522011,
        -0.05757565051317215,
        0.05416238307952881,
        0.020785724744200706,
        -0.05353045463562012,
        0.058786433190107346,
        0.05828823521733284,
        0.05085735023021698,
        0.017674721777439117,
        0.04525693506002426,
        0.034758560359478,
        0.04761277511715889,
        0.0182633213698864,
        -0.028893793001770973,
        0.010093498043715954,
        0.055240947753190994,
        -0.004435666836798191,
        -0.05956362932920456,
        -0.05862703546881676,
        0.05545859411358833,
        0.05954812467098236,
        -0.034919679164886475,
        0.0592791810631752,
        0.006286990363150835,
        -0.051517877727746964,
        0.018535664305090904,
        0.05443434417247772,
        0.046471063047647476,
        0.03633509948849678,
        -0.058571018278598785,
        -0.05807988718152046,
        0.019762981683015823,
        0.04970957711338997,
        -0.05853715166449547,
        0.011589552275836468,
        0.03950459137558937,
        -0.03294583782553673,
        -0.058336906135082245,
        -0.056920330971479416,
        -0.050965867936611176,
        0.009716690517961979,
        0.05868355184793472,
        -0.05823526903986931,
        0.05690740793943405,
        0.0510532446205616,
        -0.04375165328383446,
        0.052499283105134964,
        0.03794019669294357,
        0.05266221612691879,
        -0.02058878168463707,
        0.0242475476115942,
        0.029977628961205482,
        -0.05065622925758362,
        0.056230902671813965,
        -0.01193472370505333,
        -0.031401459127664566,
        0.024173786863684654,
        -0.04682965204119682,
        -0.04711100086569786,
        -0.0008837784989736974,
        -0.05471266061067581,
        -0.01833576150238514,
        -0.03452588617801666,
        0.014649683609604836,
        -0.05588169023394585,
        0.036644626408815384,
        0.031532082706689835,
        0.039695948362350464,
        -0.0444650799036026,
        0.020105445757508278,
        0.009960911236703396,
        0.05568680539727211,
        0.024828802794218063,
        -0.03538389131426811,
        -0.03560188040137291,
        0.04306795075535774,
        -0.0534868948161602,
        0.01607191376388073,
        -0.043316662311553955,
        -0.027348674833774567,
        -0.013359544798731804,
        0.043600525707006454,
        -0.007682152558118105,
        -0.00047352779074572027,
        0.05772654339671135,
        -0.05876303091645241,
        -0.05819547548890114,
        -0.05801638588309288,
        -0.01690760999917984,
        -0.05952779948711395,
        0.05733083561062813,
        -0.03561607375741005,
        -0.006521994713693857,
        0.0026142322458326817,
        0.022056296467781067,
        -0.05664534866809845,
        -0.053378596901893616,
        -0.056620433926582336,
        0.0019007432274520397,
        0.05801429599523544,
        0.03760926425457001,
        -0.006639070808887482,
        0.05554284527897835,
        -0.02094545215368271,
        0.056094035506248474,
        0.05950256809592247,
        0.05614140257239342,
        0.05715424194931984,
        0.056297268718481064,
        -0.005146713927388191,
        0.007356235291808844,
        0.024045484140515327,
        0.03702164441347122,
        0.036794207990169525,
        -0.05708727613091469,
        0.03827948868274689,
        0.0005778183112852275,
        -0.05889235436916351,
        0.03190584480762482,
        -0.05870315060019493,
        0.008756531402468681,
        0.059173207730054855,
        -0.0022226227447390556,
        -0.014803649857640266,
        0.05949454382061958,
        0.0583009272813797,
        0.05466032773256302,
        0.05672842264175415,
        0.023985883221030235,
        0.056164249777793884,
        -0.05674527958035469,
        0.03671209514141083,
        0.05733231082558632,
        0.05734715610742569,
        0.013976841233670712,
        -0.0022391709499061108,
        -0.033000919967889786,
        -0.05787390470504761,
        0.05069142207503319,
        -0.05875607207417488,
        0.03272963687777519,
        0.03758646920323372,
        -0.058240100741386414,
        -0.017772935330867767,
        -0.0595991276204586,
        -0.05611863359808922,
        -0.05471055954694748,
        -0.05494613200426102,
        0.05164986848831177,
        0.0518641360104084,
        -0.029437553137540817,
        -0.034479111433029175,
        0.03426093980669975,
        -0.04573913663625717,
        -0.020011618733406067,
        0.041499990969896317,
        -0.006940385792404413,
        0.044208165258169174,
        -0.01982666552066803,
        -0.02233339287340641,
        0.03375409543514252,
        -0.03680729493498802,
        0.05933036655187607,
        0.041286807507276535,
        -0.0384724996984005,
        0.056856803596019745,
        -0.02951362170279026,
        0.05248115584254265,
        0.010236426256597042,
        0.0571601428091526,
        -0.0583207905292511,
        -0.05927497148513794,
        -0.02916489914059639,
        -0.01495447289198637,
        0.05196743458509445,
        -0.05179876834154129,
        0.037909217178821564,
        -0.04358428344130516,
        -0.05713188275694847,
        0.040348224341869354,
        -0.05920356512069702,
        -0.05548238009214401,
        0.022731907665729523,
        0.02642139606177807,
        0.0507814958691597,
        -0.04166365787386894,
        -0.04773673415184021,
        -0.03291528671979904,
        0.05749116465449333,
        -0.05771568417549133,
        0.034570369869470596,
        0.05267935246229172,
        0.05648289620876312,
        0.04511374607682228,
        0.05546019598841667,
        -0.03768443688750267,
        0.039636701345443726,
        0.05687449872493744,
        0.05393883213400841,
        0.058892734348773956,
        0.052747536450624466,
        0.05565547198057175,
        -0.05843732878565788,
        0.05649394541978836,
        -0.053951822221279144,
        0.02088417299091816,
        -0.02250972017645836,
        -0.058689046651124954,
        -0.04107826575636864,
        -0.004716867581009865,
        0.0595962293446064,
        0.021074719727039337,
        0.046724576503038406,
        0.050429802387952805,
        -0.040977414697408676,
        0.05840478837490082,
        -0.059429705142974854,
        -0.00500698946416378,
        -0.05158262327313423,
        -0.05886973813176155,
        -0.05885729193687439,
        0.05728962644934654,
        -0.05838966369628906,
        -0.04914597049355507,
        -0.053542494773864746,
        -0.028278695419430733,
        0.055971790105104446,
        0.056394968181848526,
        -0.05713324993848801,
        -0.03411509096622467,
        -0.03480398282408714,
        -0.05678815022110939,
        0.05950171872973442,
        -0.0019154149340465665,
        0.031004996970295906,
        -0.05947711318731308,
        -0.05677070468664169,
        -0.05936036631464958,
        0.04711165651679039,
        -0.014677301049232483,
        0.05560101568698883,
        0.059548504650592804,
        -0.044636256992816925,
        0.058306217193603516,
        -0.03633745014667511,
        -0.054612644016742706,
        -0.024818914011120796,
        0.007193456403911114,
        -0.05894172191619873,
        -0.036656782031059265,
        0.04802938178181648,
        -0.04670557379722595,
        0.019644901156425476,
        -0.05547994002699852,
        -0.04098448529839516,
        -0.04330163449048996,
        -0.04519614577293396,
        0.05019502714276314,
        0.007915561087429523,
        0.03905410319566727,
        -0.056904830038547516,
        0.05764130502939224,
        0.05349574610590935,
        -0.045803919434547424,
        0.00451703229919076,
        -0.05934843420982361,
        -0.04197848215699196,
        0.05230933055281639,
        0.02906719595193863,
        0.04315891116857529,
        -0.03741727024316788,
        0.027794035151600838,
        0.05475213751196861,
        -0.0175916887819767,
        0.05764448270201683,
        -0.039034854620695114,
        0.03285637125372887,
        0.02063123509287834,
        -0.038883026689291,
        -0.018099650740623474,
        0.05388011410832405,
        0.017817072570323944,
        0.0031531276181340218,
        0.05775260552763939,
        0.05369862914085388,
        0.03567352145910263,
        -0.058682557195425034,
        -0.04227243363857269,
        -0.01742883212864399,
        -0.05943990871310234,
        0.0445077046751976,
        0.03796076774597168,
        -0.05322948843240738,
        -0.031000588089227676,
        -0.05282609537243843,
        -0.054775409400463104,
        0.05959687381982803,
        0.022214455530047417,
        0.049652911722660065,
        0.05831913277506828,
        0.05654401332139969,
        -0.02404046058654785,
        -0.05814702436327934,
        0.051701441407203674,
        0.03965405747294426,
        -0.05240737646818161,
        -0.04991532489657402,
        -0.004220715723931789,
        0.05475755035877228,
        0.048501402139663696,
        -0.00474387314170599,
        0.01571119949221611,
        0.05915583670139313,
        -0.056684594601392746,
        0.041963979601860046,
        -0.051635950803756714,
        -0.05199318751692772,
        0.05867980793118477,
        0.03082970716059208,
        -0.04051303490996361,
        0.012057555839419365,
        -0.03762933611869812,
        0.021510181948542595,
        0.022004293277859688,
        -0.05455587059259415,
        -0.04988863691687584,
        0.02501409314572811,
        0.012560753151774406,
        -0.04657251387834549,
        -0.004667505621910095,
        0.05639425292611122,
        0.05910617858171463,
        -0.05919788405299187,
        0.05861689895391464,
        -0.054607633501291275,
        -0.059377849102020264,
        0.05104499310255051,
        0.028475435450673103,
        0.04647316411137581,
        0.05953904241323471,
        0.057907585054636,
        0.014023380354046822,
        -0.01604153774678707,
        0.04855190962553024,
        -0.04110119119286537,
        -0.05902983248233795,
        0.043698884546756744,
        0.044812820851802826,
        -0.029221614822745323,
        0.055693771690130234,
        -0.006568634882569313,
        0.028330320492386818,
        0.03772435709834099,
        -0.0075220889411866665,
        -0.05033547803759575,
        -0.05168236419558525,
        0.0361938513815403,
        -0.024623392149806023,
        -0.05710948258638382,
        0.053304001688957214,
        0.05038917809724808,
        -0.003962076269090176,
        0.033120352774858475,
        -0.05553616210818291,
        0.05694963037967682,
        0.045645229518413544,
        -0.05918484181165695,
        0.02678157389163971,
        0.00533227389678359,
        -0.04710257425904274,
        0.038867056369781494,
        0.01189351361244917,
        -0.0023503208067268133,
        -0.022992566227912903,
        -0.05260654166340828,
        -0.05350365862250328,
        0.05687715485692024,
        0.05918152630329132
    ],
    [
        -0.04811663553118706,
        0.03833378851413727,
        0.05353271961212158,
        0.00835345033556223,
        0.02593008056282997,
        -0.017932206392288208,
        0.030667630955576897,
        -0.050167009234428406,
        0.007885965518653393,
        -0.041789550334215164,
        0.054294075816869736,
        -0.007723651826381683,
        -0.05426523834466934,
        0.010999501682817936,
        -0.047386106103658676,
        -0.008830705657601357,
        -0.013090490363538265,
        0.04720757529139519,
        -0.04026227816939354,
        -0.05606307089328766,
        0.05588028207421303,
        0.056262798607349396,
        0.05606590211391449,
        0.05552985146641731,
        -0.015542153269052505,
        -0.031589630991220474,
        -0.051755860447883606,
        0.045382361859083176,
        -0.0397154726088047,
        0.055634383112192154,
        0.05493713542819023,
        -0.055647559463977814,
        -0.015607486478984356,
        -0.056032028049230576,
        0.054061394184827805,
        -0.05567660182714462,
        -0.05506503954529762,
        -0.05487098917365074,
        0.05488177761435509,
        -0.003605513134971261,
        0.043858852237463,
        0.03628689795732498,
        0.055198878049850464,
        0.05599799379706383,
        -0.05354812741279602,
        -0.05541309714317322,
        0.055383358150720596,
        0.055339034646749496,
        0.055432066321372986,
        -0.031891509890556335,
        0.053059518337249756,
        -0.055662356317043304,
        0.051940083503723145,
        -0.050977349281311035,
        0.04952036961913109,
        0.05503964424133301,
        0.05586692690849304,
        0.047458045184612274,
        0.020031709223985672,
        -0.05497648939490318,
        -0.05387542396783829,
        0.01233032625168562,
        -0.05548663064837456,
        -0.05601121485233307,
        -0.04955458268523216,
        -0.052188608795404434,
        -0.05562548339366913,
        -0.053657546639442444,
        0.03648786246776581,
        -0.05419250950217247,
        -0.04807315021753311,
        -0.008433750830590725,
        0.00291057419963181,
        -0.016658509150147438,
        0.01967654377222061,
        -0.03343882039189339,
        0.004037224687635899,
        0.05628207325935364,
        0.023312075063586235,
        -0.033447593450546265,
        -0.04997812956571579,
        -0.05561362951993942,
        -0.0552678145468235,
        0.020380012691020966,
        0.017221244052052498,
        0.05485480651259422,
        -0.05518307536840439,
        -0.025243010371923447,
        0.029139667749404907,
        0.02826198749244213,
        -0.042472489178180695,
        -0.050616949796676636,
        0.03727085888385773,
        -0.055599089711904526,
        -0.024597560986876488,
        0.050180237740278244,
        -0.055569496005773544,
        -0.04382198676466942,
        0.020623594522476196,
        0.05485972389578819,
        -0.055758312344551086,
        0.01030921284109354,
        0.0437764972448349,
        0.05510114133358002,
        0.05211414769291878,
        -0.033807262778282166,
        0.053985048085451126,
        -0.021739035844802856,
        -0.024314764887094498,
        0.03837667405605316,
        -0.04155460000038147,
        0.012632559053599834,
        -0.036495741456747055,
        0.007239614147692919,
        0.04164803773164749,
        -0.050243079662323,
        -0.04235592111945152,
        -0.047617312520742416,
        0.0493084155023098,
        0.032850079238414764,
        -0.04396595433354378,
        -0.042213425040245056,
        -0.05309455841779709,
        -0.052813105285167694,
        -0.048287104815244675,
        0.04796159639954567,
        -0.04680906608700752,
        0.05500505864620209,
        0.035938140004873276,
        0.05380833521485329,
        0.045119114220142365,
        -0.04910345748066902,
        0.03955306485295296,
        0.055831026285886765,
        -0.03710731863975525,
        -0.05566798523068428,
        0.05389723554253578,
        0.05003615841269493,
        0.054868847131729126,
        0.0551116019487381,
        -0.05014001578092575,
        0.04417075961828232,
        0.05217379704117775,
        0.05169772729277611,
        -0.039346616715192795,
        -0.02659202180802822,
        0.050970129668712616,
        0.02603982761502266,
        -0.05504444241523743,
        0.052634067833423615,
        0.034591011703014374,
        -0.05619098246097565,
        -0.05595818907022476,
        0.042418938130140305,
        0.056282542645931244,
        0.03614012151956558,
        0.05371678248047829,
        0.03296084329485893,
        -0.007412565406411886,
        -0.01033240556716919,
        0.0522884726524353,
        0.035165343433618546,
        0.05544954538345337,
        -0.00954294390976429,
        0.011920321732759476,
        0.0023620326537638903,
        0.05570108816027641,
        -0.038615576922893524,
        -0.05097058787941933,
        0.05143588036298752,
        -0.05572681501507759,
        -0.04974984750151634,
        -0.056127119809389114,
        -0.02943221852183342,
        -0.030466532334685326,
        0.05035042390227318,
        0.05562456324696541,
        0.04176285117864609,
        0.05598234012722969,
        -0.05147523060441017,
        -0.03248320147395134,
        0.02653605118393898,
        0.03040114976465702,
        -0.050576578825712204,
        -0.05036851763725281,
        -0.016459671780467033,
        0.04476329684257507,
        0.05487915873527527,
        -0.003549316432327032,
        -0.051892105489969254,
        -0.04999659210443497,
        0.03901437297463417,
        -0.05334586277604103,
        0.03860437124967575,
        -0.055935338139534,
        -0.05529642850160599,
        0.003047190373763442,
        -0.04566849768161774,
        -0.055036481469869614,
        0.011871847324073315,
        0.05586102604866028,
        0.053081657737493515,
        -0.05043422803282738,
        -0.056255780160427094,
        0.0081501305103302,
        0.0383928008377552,
        0.045990463346242905,
        -0.041741058230400085,
        -0.0419657863676548,
        0.05448872223496437,
        -0.05018843710422516,
        -0.05050739273428917,
        0.026506567373871803,
        0.05525421351194382,
        -0.05616091936826706,
        -0.05251947045326233,
        0.030475562438368797,
        -0.028942232951521873,
        0.05581037700176239,
        -0.05585368350148201,
        -0.05476341024041176,
        -0.052778974175453186,
        0.044646069407463074,
        -0.05595133453607559,
        -0.027141675353050232,
        0.041225139051675797,
        0.050075680017471313,
        -0.05300818011164665,
        0.025328200310468674,
        -0.05197007209062576,
        -0.05401323363184929,
        -0.018288733437657356,
        0.046428848057985306,
        0.047541309148073196,
        0.049404844641685486,
        0.054140590131282806,
        0.05515582486987114,
        0.05068415775895119,
        0.05583544075489044,
        0.056281618773937225,
        0.04485209286212921,
        0.013923987746238708,
        0.05448618158698082,
        -0.055834341794252396,
        -0.013995522633194923,
        -0.04357263073325157,
        0.041542232036590576,
        -0.03226352855563164,
        -0.05623354762792587,
        0.022457638755440712,
        -0.020912084728479385,
        -0.04880990460515022,
        0.053019508719444275,
        -0.05294889956712723,
        0.0017446654383093119,
        0.03170469403266907,
        0.05364946275949478,
        -0.0059908730909228325,
        0.039925333112478256,
        -0.0032346812076866627,
        -0.05337860807776451,
        -0.00610653730109334,
        0.02308531291782856,
        -0.009626664221286774,
        -0.055623527616262436,
        0.05496091768145561,
        0.043649934232234955,
        0.05245162919163704,
        0.05563405528664589,
        0.0534181110560894,
        0.031228989362716675,
        -0.050166159868240356,
        0.029906636103987694,
        -0.040168169885873795,
        0.006045247428119183,
        0.04692331328988075,
        -0.05297218635678291,
        0.0533706434071064,
        -0.05565473064780235,
        -0.02561650238931179,
        -0.04885295405983925,
        0.021562958136200905,
        0.04329521209001541,
        0.04565208777785301,
        -0.055510278791189194,
        0.03295204043388367,
        0.05446325242519379,
        0.0546463206410408,
        -0.03246433287858963,
        -0.016339967027306557,
        -0.05438216030597687,
        0.055684417486190796,
        -0.015950627624988556,
        -0.02915782667696476,
        0.05325334519147873,
        -0.04707682505249977,
        0.04994756728410721,
        0.048179928213357925,
        -0.053388819098472595,
        0.0537235252559185,
        -0.05619819462299347,
        0.038351815193891525,
        -0.015263663604855537,
        0.044505733996629715,
        0.04857752099633217,
        -0.05618980526924133,
        0.012284220196306705,
        0.05593397840857506,
        0.021892521530389786,
        0.04688028618693352,
        0.04788362607359886,
        -0.044868625700473785,
        -0.03119439072906971,
        0.05357358604669571,
        -0.03620287775993347,
        -0.05352786183357239,
        -0.04311443492770195,
        0.05495329946279526,
        0.05443209409713745,
        0.03496580570936203,
        -0.004190107341855764,
        0.015331906266510487,
        0.04507431015372276,
        -0.044952213764190674,
        0.05532779544591904,
        0.041833218187093735,
        0.05262542515993118,
        -0.034445613622665405,
        -0.038634348660707474,
        -0.02529962919652462,
        0.056259095668792725,
        -0.048050858080387115,
        0.03838851675391197,
        0.05627910792827606,
        0.047408800572156906,
        0.030400775372982025,
        -0.053313203155994415,
        -0.038646623492240906,
        0.0018077269196510315,
        0.05064781382679939,
        -0.05369051545858383,
        -0.05601656064391136,
        0.03949905186891556,
        0.04143526405096054,
        -0.03819304332137108,
        0.03579726815223694,
        0.05549647659063339,
        0.05471513792872429,
        -0.05551823973655701,
        -0.02025667577981949,
        0.05619724467396736,
        0.015046262182295322,
        -0.017203304916620255,
        -0.04372799023985863,
        -0.05563730373978615,
        -0.0023112399503588676,
        0.023274052888154984,
        -0.05614430457353592,
        -0.03830680623650551,
        -0.009156009182333946,
        0.03375713899731636,
        0.010902546346187592,
        -0.02485487051308155,
        -0.05078542232513428,
        -0.04749948903918266,
        -0.053509339690208435,
        0.056014981120824814,
        -0.01918942853808403,
        -0.035117559134960175,
        -0.055871520191431046,
        -0.034759264439344406,
        -0.05051129311323166,
        0.026576895266771317,
        -0.055743344128131866,
        0.056210119277238846,
        0.05628359690308571,
        -0.037512410432100296,
        0.05264318734407425,
        -0.004035619553178549,
        -0.05409873276948929,
        -0.046286895871162415,
        -0.02177002653479576,
        -0.047679729759693146,
        -0.05548395216464996,
        0.027285363525152206,
        -0.054739389568567276,
        -0.0427645780146122,
        -0.04150885343551636,
        -0.05275933817028999,
        -0.04905349388718605,
        -0.012061920017004013,
        0.027674850076436996,
        -0.048853982239961624,
        -0.05404036492109299,
        0.04009382799267769,
        0.05601482093334198,
        0.022493677213788033,
        -0.055999502539634705,
        0.024278854951262474,
        -0.03399661183357239,
        -0.047098614275455475,
        0.05614704266190529,
        0.011585475876927376,
        0.026288233697414398,
        -0.039908260107040405,
        0.03391440212726593,
        0.03895103931427002,
        -0.05322347208857536,
        0.04809136688709259,
        0.008217710070312023,
        0.003484081942588091,
        0.0012857414549216628,
        -0.02208543010056019,
        -0.03589995577931404,
        0.05565071851015091,
        -0.05427468568086624,
        -0.030624981969594955,
        0.05576559901237488,
        0.05197750777006149,
        0.047983087599277496,
        0.0430142842233181,
        -0.05024215206503868,
        -0.027696844190359116,
        -0.056193262338638306,
        -0.02290038950741291,
        -0.02259787544608116,
        -0.04927566275000572,
        0.01644427515566349,
        -0.04583446681499481,
        -0.054939817637205124,
        0.05626656487584114,
        -0.010223238728940487,
        -0.02131936140358448,
        0.05626314505934715,
        -0.017220372334122658,
        -0.05551183223724365,
        -0.03930821269750595,
        0.05570753663778305,
        0.04279765114188194,
        -0.014775815419852734,
        -0.05132703110575676,
        0.04600844904780388,
        0.05564899370074272,
        0.0504508800804615,
        -0.05607474967837334,
        -0.053408995270729065,
        0.056164201349020004,
        -0.054490555077791214,
        0.0539286769926548,
        -0.05254080146551132,
        -0.053433626890182495,
        0.04073072969913483,
        0.05413128808140755,
        -0.027833333238959312,
        0.05492520332336426,
        -0.04796060547232628,
        -0.013068332336843014,
        -0.050474680960178375,
        -0.017513712868094444,
        -0.0536055862903595,
        -0.02415025606751442,
        -0.0366838201880455,
        0.015450331382453442,
        -0.04456528648734093,
        0.05479566380381584,
        0.056012194603681564,
        -0.04578645899891853,
        0.05621643364429474,
        0.018242154270410538,
        -0.05579061061143875,
        0.005835888907313347,
        0.012491323985159397,
        0.05508269742131233,
        0.055586639791727066,
        0.016387300565838814,
        -0.003641218412667513,
        0.005933856125921011,
        0.050075847655534744,
        -0.03906711935997009,
        -0.004630910232663155,
        0.05331941694021225,
        0.048853226006031036,
        -0.055989690124988556,
        0.009745771996676922,
        -0.05324926599860191,
        0.0502810999751091,
        0.032480232417583466,
        0.05427425354719162,
        -0.042496487498283386,
        -0.05565491318702698,
        0.05462764948606491,
        0.05094316229224205,
        -0.05486198514699936,
        0.055368416011333466,
        -0.047788169234991074,
        -0.046572376042604446,
        0.053632743656635284,
        -0.056169770658016205,
        0.042339079082012177,
        0.04702479764819145,
        -0.055451650172472,
        0.052992913872003555,
        -0.04751662537455559,
        -0.05009152367711067,
        0.05170083045959473,
        0.032817989587783813,
        0.05620569363236427,
        -0.05122916400432587,
        0.054560549557209015,
        -0.030420484021306038,
        0.018961720168590546,
        -0.005298764444887638
    ],
    [
        -0.0537065714597702,
        -0.03708323836326599,
        -0.006577762775123119,
        -0.028710927814245224,
        -0.03923821449279785,
        -0.05422637611627579,
        0.039536502212285995,
        -0.05556793883442879,
        0.054195571690797806,
        0.05582854524254799,
        0.051739320158958435,
        0.05657923221588135,
        -0.05602287873625755,
        0.05189108848571777,
        -0.056030694395303726,
        0.05144747719168663,
        -0.04914918541908264,
        0.05533237382769585,
        -0.004218275658786297,
        -0.05097048729658127,
        0.055464837700128555,
        0.05635484680533409,
        0.05662901699542999,
        -0.05324539914727211,
        -0.0562911294400692,
        -0.03223855420947075,
        -0.019461924210190773,
        0.0559765063226223,
        -0.03313935548067093,
        0.024407697841525078,
        -0.04306422919034958,
        -0.05648943409323692,
        0.05566048622131348,
        -0.05640290305018425,
        0.052019499242305756,
        -0.02060527168214321,
        -0.055423032492399216,
        -0.04410653933882713,
        0.05411610007286072,
        -0.05619340017437935,
        0.015079840086400509,
        0.04223152995109558,
        0.05397266149520874,
        0.032264456152915955,
        -0.042238373309373856,
        0.016572190448641777,
        0.05661039799451828,
        0.05639224126935005,
        0.05649356171488762,
        0.0027017509564757347,
        0.05324994772672653,
        0.037099383771419525,
        -0.01285578217357397,
        -0.04919866472482681,
        0.05559137463569641,
        0.0565418116748333,
        0.017758600413799286,
        -0.00860736332833767,
        -0.0441308468580246,
        -0.011312216520309448,
        -0.05521780252456665,
        0.022295407950878143,
        -0.05611228570342064,
        -0.041925497353076935,
        -0.04031596705317497,
        -0.053388044238090515,
        -0.055652350187301636,
        -0.041325218975543976,
        -0.0481223538517952,
        -0.05634205415844917,
        0.021861745044589043,
        -0.05601881816983223,
        0.0535414032638073,
        0.009063079953193665,
        0.00616662809625268,
        -0.045177120715379715,
        0.05552422255277634,
        0.05665115267038345,
        -0.00048496382078155875,
        0.035154689103364944,
        -0.03820568323135376,
        -0.054993368685245514,
        -0.05507204681634903,
        0.0396580696105957,
        0.0518161803483963,
        0.041901711374521255,
        -0.05581329017877579,
        -0.04941936582326889,
        -0.009909072890877724,
        0.05586903542280197,
        -0.04249284043908119,
        -0.04984176903963089,
        0.009333308786153793,
        -0.05541115626692772,
        -0.026476258412003517,
        0.0466076098382473,
        -0.05087542533874512,
        -0.026340560987591743,
        0.05621543154120445,
        0.05304364860057831,
        -0.05586785078048706,
        0.05559626594185829,
        0.020190222188830376,
        0.05660843104124069,
        -0.04751187190413475,
        -0.007850014604628086,
        0.043511100113391876,
        0.05319943279027939,
        -0.052301567047834396,
        -0.05618510767817497,
        -0.05080519989132881,
        -0.055073633790016174,
        0.05151652917265892,
        -0.024680322036147118,
        0.029631618410348892,
        -0.04685458168387413,
        -0.04368888586759567,
        -0.054490141570568085,
        0.054326772689819336,
        0.003572700545191765,
        0.012549170292913914,
        -0.011481654830276966,
        0.04932241141796112,
        -0.05617133527994156,
        -0.043991826474666595,
        -0.056612543761730194,
        0.01435453537851572,
        0.022983182221651077,
        0.05401897057890892,
        0.05104748532176018,
        0.054874155670404434,
        -0.0032648828346282244,
        -0.02546427585184574,
        0.05168589949607849,
        -0.0353451743721962,
        -0.05556119978427887,
        0.05657728761434555,
        0.05578314885497093,
        -0.0062764352187514305,
        -0.03186119720339775,
        -0.054602429270744324,
        0.0554267056286335,
        0.050187140703201294,
        0.050675276666879654,
        -0.04025070741772652,
        0.05657566711306572,
        -0.05566520243883133,
        -0.056368015706539154,
        0.04958774521946907,
        0.0019941392820328474,
        0.034039076417684555,
        -0.05257485434412956,
        -0.05069544166326523,
        0.0008340167114511132,
        0.05637465417385101,
        -0.012896237894892693,
        0.03511066362261772,
        -0.050498560070991516,
        0.056206103414297104,
        -0.011011182330548763,
        0.012575865723192692,
        -0.053047046065330505,
        0.05424870178103447,
        0.017042746767401695,
        -0.055295344442129135,
        -0.036261800676584244,
        0.054724495857954025,
        -0.013142337091267109,
        -0.011671538464725018,
        0.003984133247286081,
        -0.05651228129863739,
        -0.027887901291251183,
        -0.05352319777011871,
        0.023726116865873337,
        -0.05620616301894188,
        0.021812833845615387,
        -0.019667882472276688,
        0.04022938013076782,
        0.042274653911590576,
        -0.04346504807472229,
        -0.02514488995075226,
        -0.020032597705721855,
        -0.021830063313245773,
        -0.04561557248234749,
        -0.01210954412817955,
        0.02984483912587166,
        0.014002393931150436,
        0.056191641837358475,
        -0.038771335035562515,
        0.01675257831811905,
        -0.05493580177426338,
        -0.0401042178273201,
        0.016626156866550446,
        -0.056288380175828934,
        -0.056243475526571274,
        -0.03980754688382149,
        0.041135963052511215,
        0.055580537766218185,
        -0.04632321372628212,
        0.05410987138748169,
        0.05159894749522209,
        -0.054247915744781494,
        -0.03958199918270111,
        -0.053303055465221405,
        0.04794459789991379,
        -0.0031154362950474024,
        0.038464490324258804,
        0.030930982902646065,
        -0.04939740151166916,
        -0.037206538021564484,
        -0.05361798033118248,
        0.03993600234389305,
        0.052833154797554016,
        0.05611180141568184,
        -0.011506879702210426,
        0.04176321625709534,
        0.04545038938522339,
        -0.02047884650528431,
        0.05633885785937309,
        0.028445884585380554,
        -0.05262744799256325,
        -0.035289231687784195,
        -0.026852181181311607,
        -0.031450677663087845,
        0.055732980370521545,
        -0.055273935198783875,
        0.04258660599589348,
        -0.05132784694433212,
        0.05046830698847771,
        -0.045590631663799286,
        -0.04154334217309952,
        -0.05659254640340805,
        0.053392767906188965,
        0.05130932852625847,
        0.016996808350086212,
        0.0221797376871109,
        0.052156489342451096,
        0.02419949881732464,
        -0.035922277718782425,
        0.056650806218385696,
        0.009696589782834053,
        0.04954128339886665,
        0.02238229475915432,
        -0.03685501590371132,
        0.05596785619854927,
        0.0563635379076004,
        0.04603295028209686,
        0.056479211896657944,
        0.05640158802270889,
        -0.019391365349292755,
        0.040775708854198456,
        -0.056079111993312836,
        0.053925152868032455,
        -0.05594565346837044,
        -0.04621957987546921,
        0.044812656939029694,
        0.048659246414899826,
        -0.03733409196138382,
        0.045996494591236115,
        0.006019963882863522,
        -0.052270278334617615,
        0.05574450269341469,
        0.013837339356541634,
        0.04898853972554207,
        0.05436979606747627,
        0.05612301453948021,
        0.0543455109000206,
        0.05486765503883362,
        -0.027693133801221848,
        0.05580631643533707,
        0.007235145196318626,
        -0.03175988793373108,
        0.05637328699231148,
        -0.05615956336259842,
        0.053243186324834824,
        0.027049750089645386,
        -0.05115398019552231,
        -0.05446821078658104,
        0.022836072370409966,
        -0.04815978556871414,
        -0.035529978573322296,
        0.027779992669820786,
        0.034641072154045105,
        -0.029273616150021553,
        0.0508100651204586,
        0.05602208897471428,
        0.04433053359389305,
        0.04943934828042984,
        0.05461796745657921,
        0.010572214610874653,
        -0.05294978991150856,
        -0.02241624891757965,
        -0.05410617217421532,
        -0.044313911348581314,
        0.05350364372134209,
        -0.05185244604945183,
        0.05552452802658081,
        -0.04822493717074394,
        -0.012895746156573296,
        0.055580347776412964,
        0.012891372665762901,
        0.038576312363147736,
        -0.004214058164507151,
        0.05545016750693321,
        -0.051464568823575974,
        -0.03709006309509277,
        -0.011814973317086697,
        0.056421756744384766,
        0.05628885701298714,
        0.022308604791760445,
        0.05136562138795853,
        -0.030019139871001244,
        0.05239493399858475,
        0.049630921334028244,
        -0.027520455420017242,
        -0.05594850704073906,
        -0.024020962417125702,
        0.05664368346333504,
        0.05500365421175957,
        0.038488149642944336,
        -0.05311700701713562,
        -0.05648750811815262,
        0.053999096155166626,
        0.05352284014225006,
        0.05247112736105919,
        -0.029820110648870468,
        0.049715716391801834,
        -0.04297531396150589,
        0.053704045712947845,
        -0.04728623479604721,
        -0.052961159497499466,
        -0.053762227296829224,
        0.056486934423446655,
        0.05568794906139374,
        0.05318064242601395,
        0.054481856524944305,
        -0.05544562265276909,
        0.033866167068481445,
        -0.049702804535627365,
        -0.02871692180633545,
        -0.00616854801774025,
        0.02173471264541149,
        -0.04758923873305321,
        0.048877034336328506,
        0.04777120426297188,
        0.03533179312944412,
        0.055602338165044785,
        0.05505204200744629,
        -0.03706737235188484,
        0.04845193400979042,
        0.047226469963788986,
        -0.05227302014827728,
        0.02597929909825325,
        -0.05651368573307991,
        0.05607013776898384,
        -0.04126377031207085,
        -0.05044857785105705,
        -0.055134162306785583,
        -0.013605859130620956,
        -0.05337489768862724,
        0.039224568754434586,
        0.007015901617705822,
        -0.03504318743944168,
        -0.046145398169755936,
        -0.022936612367630005,
        -0.05628316104412079,
        0.05653616413474083,
        -0.050729431211948395,
        -0.032935671508312225,
        -0.056553661823272705,
        0.027019096538424492,
        -0.05460492894053459,
        0.02519879676401615,
        -0.03843403235077858,
        0.05632863938808441,
        0.05665307864546776,
        0.05637907609343529,
        0.0308308657258749,
        0.05578913539648056,
        -0.03212219104170799,
        -0.035398952662944794,
        -0.0004986106068827212,
        -0.05622328817844391,
        -0.04324658215045929,
        0.012502072378993034,
        -0.05615146830677986,
        -0.05093388259410858,
        -0.026746634393930435,
        -0.03948644921183586,
        -0.05237603932619095,
        -0.05013781040906906,
        0.009221185930073261,
        -0.03968653082847595,
        0.029342208057641983,
        0.036466099321842194,
        0.05141438543796539,
        0.05453070253133774,
        0.0030844470020383596,
        0.056216783821582794,
        -0.05508984252810478,
        -0.03572661802172661,
        0.05270244553685188,
        -0.0448322631418705,
        -0.0002829431614372879,
        -0.047503892332315445,
        0.01852443814277649,
        0.055367372930049896,
        -0.006294531747698784,
        0.054584186524152756,
        -0.041027672588825226,
        -0.047556910663843155,
        -0.04300940781831741,
        -0.014861645177006721,
        0.025991851463913918,
        0.026606839150190353,
        -0.018712155520915985,
        -0.014646087773144245,
        -0.005089126992970705,
        0.05102110281586647,
        0.05601172149181366,
        0.05314861983060837,
        -0.0423949733376503,
        0.053892526775598526,
        -0.05658916011452675,
        0.02814195491373539,
        0.012226443737745285,
        0.03449031710624695,
        0.05623182654380798,
        -0.05507606267929077,
        -0.04071184992790222,
        0.05642443150281906,
        -0.015811650082468987,
        -0.05075232312083244,
        -0.05559135973453522,
        -0.017744753509759903,
        -0.0546269416809082,
        -0.05653749778866768,
        0.04718644917011261,
        -0.04831695556640625,
        -0.05519101023674011,
        -0.052925512194633484,
        0.048487599939107895,
        0.04542446509003639,
        0.05129591003060341,
        -0.018760384991765022,
        -0.01138303056359291,
        0.05534505099058151,
        -0.055341798812150955,
        0.030718496069312096,
        0.03455161303281784,
        0.045421209186315536,
        0.04575206711888313,
        0.05256874859333038,
        -0.006803773809224367,
        0.017818128690123558,
        -0.04988338425755501,
        -0.04527059569954872,
        -0.05101224407553673,
        -0.03403313457965851,
        -0.052653711289167404,
        0.04837387427687645,
        -0.05664954334497452,
        0.03967897966504097,
        0.05431747063994408,
        0.05451824143528938,
        0.05556761100888252,
        -0.05659518763422966,
        0.05233639106154442,
        0.017318565398454666,
        -0.05403883755207062,
        -0.00020553421927616,
        0.049355398863554,
        0.046280305832624435,
        0.05663855001330376,
        -0.05338915437459946,
        0.017393847927451134,
        -0.03545915707945824,
        0.04315872862935066,
        -0.040774423629045486,
        -0.046275097876787186,
        0.02974020503461361,
        0.019869979470968246,
        -0.056475549936294556,
        0.051990535110235214,
        -0.05101168900728226,
        0.05267469957470894,
        0.04441976174712181,
        0.0501709021627903,
        -0.0551019012928009,
        -0.04187299683690071,
        0.01209980994462967,
        -0.05506955087184906,
        -0.04214344546198845,
        -0.004907393362373114,
        0.020876841619610786,
        0.025600364431738853,
        0.05646253004670143,
        -0.020522428676486015,
        0.05376000329852104,
        0.04468517005443573,
        0.025812139734625816,
        0.05446939542889595,
        -0.011385585181415081,
        -0.05569952726364136,
        0.028742369264364243,
        0.03047262132167816,
        0.03723444417119026,
        -0.009105832315981388,
        0.045694317668676376,
        -0.054504480212926865,
        0.04780971631407738,
        0.0019677309319376945
    ],
    [
        -0.054941024631261826,
        0.05026154965162277,
        0.052339836955070496,
        0.016360227018594742,
        0.039320047944784164,
        -0.022237105295062065,
        0.06067469343543053,
        -0.013261743821203709,
        0.037370629608631134,
        0.04459230601787567,
        0.05918041616678238,
        0.04960450157523155,
        -0.06053901091217995,
        0.011145468801259995,
        -0.05934316664934158,
        0.0552934929728508,
        -0.053586963564157486,
        0.019848156720399857,
        -0.042409636080265045,
        0.03958798199892044,
        -0.04630718007683754,
        0.0029376044403761625,
        0.05413994565606117,
        -0.005183568224310875,
        0.04483145847916603,
        0.00809746328741312,
        0.046924442052841187,
        0.04384733736515045,
        -0.05916468799114227,
        -0.022320713847875595,
        0.044449154287576675,
        -0.056237101554870605,
        0.05820846930146217,
        -0.0579979345202446,
        0.006876508239656687,
        -0.054915785789489746,
        -0.05768140032887459,
        -0.05870371311903,
        -0.058038029819726944,
        0.030266594141721725,
        -0.05227791517972946,
        0.006593090947717428,
        0.05541755259037018,
        -0.03344333916902542,
        0.03231988474726677,
        0.03587513044476509,
        0.052570462226867676,
        0.05228264257311821,
        0.06098126620054245,
        -0.0007820430910214782,
        0.04209136590361595,
        -0.06106068938970566,
        0.04223187267780304,
        -0.05150396749377251,
        -0.020623840391635895,
        0.04758094251155853,
        -0.04734766483306885,
        -0.015004163607954979,
        -0.029979662969708443,
        0.008757824078202248,
        -0.04763113707304001,
        -0.02064238302409649,
        -0.05500641465187073,
        -0.012116760946810246,
        0.008422919549047947,
        -0.003749112132936716,
        0.007120085880160332,
        -0.05505339428782463,
        -0.057919684797525406,
        -0.061865534633398056,
        -0.053780097514390945,
        -0.05990787595510483,
        0.058482322841882706,
        -0.009887909516692162,
        -0.046638041734695435,
        -0.03258329629898071,
        0.04528617858886719,
        0.06146159768104553,
        -0.02697593718767166,
        0.002527066506445408,
        -0.01676293835043907,
        0.007048448082059622,
        -0.04098125919699669,
        0.042113084346055984,
        0.024973664432764053,
        0.05087893828749657,
        -0.049543414264917374,
        -0.043882112950086594,
        -0.002611067844554782,
        0.06169400364160538,
        -0.04360209405422211,
        0.00683049438521266,
        0.006329351104795933,
        -0.041990380734205246,
        -0.03770286962389946,
        -0.044241294264793396,
        -0.024204935878515244,
        -0.04946568235754967,
        -0.02939494512975216,
        0.0018298984505236149,
        -0.06158403679728508,
        -0.03280951455235481,
        0.054861050099134445,
        0.059761498123407364,
        0.01590246520936489,
        -0.03920022398233414,
        -0.004599486477673054,
        0.052511196583509445,
        -0.03468916565179825,
        -0.025805700570344925,
        -0.037314578890800476,
        -0.05891212448477745,
        -0.048468589782714844,
        -0.03358165919780731,
        0.019044287502765656,
        -0.050433699041604996,
        -0.021225420758128166,
        -0.05399870499968529,
        -0.03573230281472206,
        -0.007616536226123571,
        -0.051290083676576614,
        0.018912049010396004,
        -0.016725560650229454,
        -0.0223535168915987,
        -0.059935010969638824,
        0.00521192979067564,
        0.006044228561222553,
        0.0399148166179657,
        0.0378616601228714,
        0.02334950864315033,
        -0.008142407983541489,
        -0.043292336165905,
        -0.05751502886414528,
        0.055969301611185074,
        -0.058612268418073654,
        -0.05476032942533493,
        0.05702187865972519,
        -0.05946515500545502,
        -0.005991061218082905,
        0.055665675550699234,
        0.04602278396487236,
        0.04472796991467476,
        0.027915146201848984,
        0.05667998641729355,
        0.02774026244878769,
        0.004451124928891659,
        0.060741279274225235,
        -0.051997099071741104,
        -0.05426562577486038,
        -0.017772989347577095,
        -0.02417052537202835,
        -0.06091675907373428,
        -0.05249859020113945,
        -0.0363946408033371,
        0.05800841003656387,
        -0.038789987564086914,
        0.061283331364393234,
        -0.024799596518278122,
        -0.03126838803291321,
        0.04982267692685127,
        0.055877748876810074,
        0.049576710909605026,
        0.028265608474612236,
        -0.05051220953464508,
        -0.05838528648018837,
        -0.046836622059345245,
        -0.022247547283768654,
        -0.060048267245292664,
        -0.05206691473722458,
        0.0581868439912796,
        -0.045569613575935364,
        -0.0004217602254357189,
        -0.05312651768326759,
        0.057024646550416946,
        -0.05830072611570358,
        0.04632674157619476,
        -0.05985337495803833,
        -0.011549705639481544,
        0.058885689824819565,
        -0.004166340921074152,
        0.05832524597644806,
        0.00969757605344057,
        0.05527305603027344,
        0.039802294224500656,
        -0.002837285865098238,
        0.03248178958892822,
        -0.045073337852954865,
        0.05754036083817482,
        -0.05510370805859566,
        -0.05543322488665581,
        -0.01924283243715763,
        -0.035913094878196716,
        -0.030687345191836357,
        -0.038396935909986496,
        -0.04063449427485466,
        0.04723361134529114,
        0.03526856005191803,
        -0.04343259334564209,
        -0.020016290247440338,
        0.013593887910246849,
        -0.029893605038523674,
        0.05620144307613373,
        0.030173907056450844,
        0.03625320643186569,
        0.005248796660453081,
        0.05296660587191582,
        0.06069919466972351,
        0.0011336134048178792,
        -0.04162232205271721,
        -0.035690099000930786,
        -0.04509873688220978,
        0.04878028854727745,
        0.05086676776409149,
        0.028396548703312874,
        0.0445466972887516,
        0.02756858617067337,
        0.0614018589258194,
        0.04072337970137596,
        0.0614696629345417,
        -0.034650545567274094,
        0.004547495860606432,
        -0.04007154703140259,
        -0.03925502300262451,
        -0.05787332355976105,
        0.05134366452693939,
        -0.04236787557601929,
        0.011021880432963371,
        0.009335058741271496,
        0.05159597098827362,
        -0.05902170017361641,
        0.023180153220891953,
        -0.059552956372499466,
        -0.0543537512421608,
        0.048900097608566284,
        0.026318075135350227,
        0.02241951785981655,
        0.05387848988175392,
        0.009343828074634075,
        0.020046964287757874,
        0.05884181708097458,
        0.044737864285707474,
        0.05173829197883606,
        0.046083126217126846,
        -0.004569425247609615,
        0.041392043232917786,
        -0.023312032222747803,
        -0.056890860199928284,
        0.009716564789414406,
        -0.04085007682442665,
        -0.044445402920246124,
        0.01630765199661255,
        -0.06137964129447937,
        0.015120647847652435,
        -0.06096213683485985,
        0.050163645297288895,
        0.060816578567028046,
        -0.0340140163898468,
        0.007456117309629917,
        0.061617616564035416,
        0.04391301050782204,
        0.060133226215839386,
        0.048177387565374374,
        0.046938441693782806,
        0.042454443871974945,
        -0.05525543540716171,
        -0.0015835755039006472,
        -0.019775567576289177,
        -0.02800717018544674,
        0.058345552533864975,
        -0.006267879158258438,
        -0.009165776893496513,
        -0.05590159446001053,
        0.044377394020557404,
        -0.059160266071558,
        0.03443142771720886,
        0.03242981433868408,
        -0.060207098722457886,
        0.05594998598098755,
        -0.0076082851737737656,
        -0.05235810950398445,
        -0.05929868295788765,
        -0.059476543217897415,
        0.05275648832321167,
        0.05697740614414215,
        -0.05715252459049225,
        -0.009441321715712547,
        0.018748486414551735,
        -0.01378698367625475,
        -0.047304537147283554,
        0.048602309077978134,
        -0.026042180135846138,
        0.05815451964735985,
        -0.032752737402915955,
        0.0037686051800847054,
        -0.010923678055405617,
        -0.041446503251791,
        0.06056379899382591,
        0.03764094039797783,
        -0.0502268448472023,
        0.05945509672164917,
        -0.05825912207365036,
        0.05911576747894287,
        -0.025765791535377502,
        0.03653974086046219,
        -0.04974377527832985,
        -0.05135564133524895,
        -0.03430509939789772,
        0.033164821565151215,
        0.060930684208869934,
        -0.06171882897615433,
        0.056467946618795395,
        -0.05640493705868721,
        -0.03717994689941406,
        0.054559700191020966,
        -0.056427229195833206,
        -0.05767141282558441,
        0.04877832531929016,
        0.027448272332549095,
        0.054259490221738815,
        -0.05162473022937775,
        -0.06172791123390198,
        -0.008525770157575607,
        0.057908110320568085,
        -0.058734647929668427,
        0.05552155524492264,
        0.026304181665182114,
        0.05430283769965172,
        -0.060223646461963654,
        0.031153548508882523,
        -0.0063619776628911495,
        -0.050400882959365845,
        0.03158876299858093,
        0.030147766694426537,
        -0.0294378362596035,
        0.059526681900024414,
        0.021472442895174026,
        -0.04237933084368706,
        0.057846296578645706,
        -0.05174650996923447,
        0.017692700028419495,
        0.028558488935232162,
        -0.015203951857984066,
        -0.05798327177762985,
        -0.05870442092418671,
        0.06192212179303169,
        0.05154469236731529,
        0.0558369942009449,
        0.052421823143959045,
        -0.02078261785209179,
        0.061016570776700974,
        -0.0602724589407444,
        0.0238067414611578,
        -0.03733915463089943,
        -0.06050875782966614,
        -0.018620604649186134,
        0.057292450219392776,
        -0.056426871567964554,
        -0.03520619496703148,
        -0.0506596565246582,
        -0.011181628331542015,
        0.0566125363111496,
        -0.037560559809207916,
        -0.06138555705547333,
        -0.024235974997282028,
        0.048312775790691376,
        -0.05809822678565979,
        0.06141827628016472,
        -0.011208777315914631,
        0.061108753085136414,
        -0.06172589212656021,
        -0.04252661019563675,
        -0.05986914783716202,
        0.05876113474369049,
        -0.04375468194484711,
        0.05725864693522453,
        0.05866365507245064,
        0.04219435155391693,
        0.0302659310400486,
        0.0002685962535906583,
        -0.05642541125416756,
        -0.004874765872955322,
        -0.039017364382743835,
        -0.06163164600729942,
        0.0313737690448761,
        0.04799095168709755,
        -0.06061135604977608,
        0.01978883519768715,
        -0.05326145142316818,
        -0.03311596065759659,
        -0.06029690429568291,
        -0.02753942646086216,
        -0.03170148655772209,
        0.0494476780295372,
        0.0014286863151937723,
        -0.048930007964372635,
        0.05940479412674904,
        0.014884932897984982,
        -0.03968429192900658,
        0.008339381776750088,
        -0.06021856889128685,
        -0.0498330257833004,
        0.05190618708729744,
        0.0592234842479229,
        0.03665217012166977,
        -0.019678309559822083,
        0.04646230861544609,
        0.020356273278594017,
        -0.009652331471443176,
        0.057425227016210556,
        -0.038013607263565063,
        0.029199717566370964,
        0.015041937120258808,
        -0.02430001087486744,
        0.027643492445349693,
        0.05656612291932106,
        -0.028942009434103966,
        -0.027864662930369377,
        0.03063373826444149,
        0.05341388285160065,
        0.03462960943579674,
        -0.05347834900021553,
        -0.06077733635902405,
        0.0174519382417202,
        -0.060556720942258835,
        0.037399839609861374,
        0.0458870604634285,
        -0.022377891466021538,
        0.018757710233330727,
        -0.05870591476559639,
        -0.0226188525557518,
        0.059510376304388046,
        0.025038931518793106,
        -0.021048331633210182,
        0.05308080092072487,
        0.03636959567666054,
        0.007208356633782387,
        -0.05455334857106209,
        0.06014658510684967,
        0.0367608517408371,
        -0.05443042144179344,
        -0.051290057599544525,
        0.0414731539785862,
        0.05382652208209038,
        0.057830650359392166,
        -0.0544644370675087,
        0.006534529849886894,
        0.06119028851389885,
        -0.05875708535313606,
        0.05620035529136658,
        -0.05830216780304909,
        -0.00916800182312727,
        0.058484066277742386,
        0.04688383638858795,
        0.021071046590805054,
        0.046731725335121155,
        -0.053656067699193954,
        -0.012141121551394463,
        -0.02033945731818676,
        -0.05652438849210739,
        0.05553886666893959,
        -0.013884970918297768,
        -0.0585174486041069,
        -0.05625114589929581,
        -0.06080746278166771,
        0.0598735436797142,
        0.06099739298224449,
        -0.06114304065704346,
        0.052368875592947006,
        -0.060119353234767914,
        -0.06062084063887596,
        0.030065054073929787,
        0.022223807871341705,
        0.04967810586094856,
        0.05802243575453758,
        0.05418584868311882,
        -0.035128746181726456,
        -0.04959109053015709,
        0.058694519102573395,
        -0.0584389790892601,
        -0.026095228269696236,
        0.005875159054994583,
        -0.002154039451852441,
        0.04448851943016052,
        0.05393653362989426,
        0.023239759728312492,
        0.028238017112016678,
        0.05835621431469917,
        -0.006160454824566841,
        -0.04974537715315819,
        -0.04784449562430382,
        -0.05882945656776428,
        -0.05047755315899849,
        0.04864870756864548,
        0.01733754761517048,
        0.06153663620352745,
        -0.02889540232717991,
        0.025752637535333633,
        -0.03655407577753067,
        0.058160990476608276,
        0.04154324159026146,
        -0.060959797352552414,
        -0.05477476865053177,
        -0.043903227895498276,
        -0.03682301193475723,
        0.04029523953795433,
        0.008940384723246098,
        0.00019346603949088603,
        0.04679455608129501,
        -0.05541558191180229,
        -0.047515515238046646,
        0.060106001794338226,
        0.06070011109113693
    ],
    [
        0.00737496605142951,
        0.04340985044836998,
        0.050310760736465454,
        -0.031122466549277306,
        0.051293350756168365,
        -0.03343549370765686,
        0.061577025800943375,
        0.02827068790793419,
        0.04724016413092613,
        -0.013728396967053413,
        -0.037237200886011124,
        0.0600748285651207,
        -0.05329490080475807,
        0.057690709829330444,
        -0.059721916913986206,
        0.049292705953121185,
        -0.021604856476187706,
        0.04710199683904648,
        -0.04575982689857483,
        -0.045846447348594666,
        -0.06031901761889458,
        0.025230059400200844,
        0.006116790696978569,
        -0.012655719183385372,
        0.035003211349248886,
        0.04007291793823242,
        0.0531507283449173,
        0.05649755522608757,
        -0.04614780843257904,
        -0.02227756753563881,
        0.033410049974918365,
        -0.049415189772844315,
        0.04877625033259392,
        -0.0585942342877388,
        0.029006877914071083,
        -0.060029350221157074,
        -0.05885123461484909,
        -0.059893861413002014,
        0.013955265283584595,
        0.04798396676778793,
        -0.04264364764094353,
        0.04527154192328453,
        0.05590314790606499,
        -0.056541793048381805,
        0.04515144228935242,
        0.034176554530858994,
        0.056610554456710815,
        0.05013452097773552,
        0.05607282370328903,
        -0.05620932579040527,
        0.0476238876581192,
        -0.0074358549900352955,
        0.04588243365287781,
        0.005123036447912455,
        -0.005381257273256779,
        0.0394422672688961,
        -0.05959344282746315,
        -0.024036355316638947,
        -0.03774284943938255,
        0.022867126390337944,
        -0.05006317049264908,
        -0.030310923233628273,
        -0.052656181156635284,
        0.042388007044792175,
        -0.012644694186747074,
        -0.021034792065620422,
        0.005061813164502382,
        -0.05337321385741234,
        -0.04940447583794594,
        -0.06152195855975151,
        0.02031652070581913,
        -0.051808301359415054,
        0.061175085604190826,
        -0.006665768101811409,
        -0.04653812199831009,
        0.0324859619140625,
        0.04832889139652252,
        0.061594173312187195,
        0.01638774946331978,
        -0.054069727659225464,
        -0.027447761967778206,
        -0.015039910562336445,
        -0.05538812279701233,
        0.006475368980318308,
        -0.026165392249822617,
        0.03283090516924858,
        -0.03955637291073799,
        -0.05392573028802872,
        -0.026414547115564346,
        0.060950569808483124,
        -0.055771782994270325,
        -0.0608556866645813,
        -0.015523204579949379,
        -0.058993685990571976,
        0.007888075895607471,
        -0.032374631613492966,
        0.006412658374756575,
        -0.05457141622900963,
        -0.05007626488804817,
        0.009487476199865341,
        -0.05950368568301201,
        -0.022045601159334183,
        0.05647267401218414,
        0.06057056412100792,
        -0.0028878713492304087,
        -0.0426461435854435,
        -0.04707977548241615,
        0.011923491954803467,
        0.047768522053956985,
        0.0214583370834589,
        -0.017854556441307068,
        -0.06115031614899635,
        -0.045771434903144836,
        -0.03671100363135338,
        0.04375266283750534,
        -0.05608387291431427,
        -0.0492900013923645,
        -0.05471296235918999,
        -0.016555428504943848,
        0.036699194461107254,
        0.009335855953395367,
        -0.016947221010923386,
        -0.045859530568122864,
        -0.0004362012550700456,
        -0.0612412728369236,
        0.009177572093904018,
        -0.054899126291275024,
        0.03489404171705246,
        0.05966799706220627,
        0.01729111559689045,
        -0.004405819345265627,
        -0.0419207327067852,
        -0.05351569876074791,
        0.05487949401140213,
        -0.05271526053547859,
        -0.05302698165178299,
        0.0008873233455233276,
        -0.047606486827135086,
        0.05420743301510811,
        0.04280482605099678,
        0.05381481349468231,
        0.01509338989853859,
        0.04983434081077576,
        0.05496779456734657,
        0.05741823837161064,
        -0.022419018670916557,
        0.05891166254878044,
        -0.0038883539382368326,
        -0.059395141899585724,
        -0.002797926776111126,
        -0.0031523273792117834,
        -0.05938700959086418,
        -0.04063362628221512,
        -0.03659265115857124,
        -0.009721624664962292,
        0.028638752177357674,
        0.039109982550144196,
        -0.00419568782672286,
        -0.0472569540143013,
        0.03019452840089798,
        0.05385025963187218,
        0.02894463948905468,
        0.04809985309839249,
        -0.038930803537368774,
        -0.033227525651454926,
        -0.014375342056155205,
        -0.0402396097779274,
        -0.03247150778770447,
        0.05150241777300835,
        0.04903257265686989,
        -0.023359140381217003,
        0.02516281232237816,
        -0.047326166182756424,
        0.027601342648267746,
        -0.05732358247041702,
        0.033619292080402374,
        -0.02002442441880703,
        0.053401779383420944,
        0.05723851919174194,
        -0.014254464767873287,
        0.04189004376530647,
        0.05931117385625839,
        0.006111656315624714,
        -0.03369474783539772,
        0.048216745257377625,
        -0.03292941302061081,
        -0.0455864816904068,
        0.0603557787835598,
        -0.05569111183285713,
        -0.06039153411984444,
        -0.004608598072081804,
        -0.05255476012825966,
        -0.049276385456323624,
        -0.05455181375145912,
        -0.049685508012771606,
        0.04773077368736267,
        0.036393146961927414,
        -0.061610400676727295,
        -0.03642928972840309,
        0.04772660881280899,
        -0.05230814963579178,
        0.040290165692567825,
        0.03798676282167435,
        -0.057912737131118774,
        -0.019143162295222282,
        0.059737678617239,
        0.05766373872756958,
        0.03337181359529495,
        -0.05835578218102455,
        -0.0023297921288758516,
        -0.03618338704109192,
        0.02639845572412014,
        -0.04579288512468338,
        0.030870534479618073,
        0.054880011826753616,
        0.023325493559241295,
        0.03942485526204109,
        0.056024979799985886,
        0.05820853263139725,
        0.05384792014956474,
        -0.029573198407888412,
        -0.05332094058394432,
        -0.049642812460660934,
        -0.05959029868245125,
        0.05636410042643547,
        -0.059905052185058594,
        -0.024371929466724396,
        -0.011471575126051903,
        -0.045512694865465164,
        -0.05833308398723602,
        -0.03817909210920334,
        -0.06029985845088959,
        -0.0496799536049366,
        0.05064021795988083,
        0.0253150537610054,
        -0.05583035200834274,
        0.05893673747777939,
        0.001444868859834969,
        0.04323234036564827,
        0.05819910764694214,
        0.044090576469898224,
        0.026624729856848717,
        -0.02745071053504944,
        0.044998787343502045,
        0.05655735731124878,
        -0.002101356629282236,
        -0.00865245796740055,
        -0.019163385033607483,
        -0.029833901673555374,
        -0.03729521110653877,
        0.008418493904173374,
        -0.05555514246225357,
        0.0251728855073452,
        -0.05452399328351021,
        0.014382428489625454,
        0.059745948761701584,
        0.04313076287508011,
        -0.03214980661869049,
        0.06147795543074608,
        0.05950738489627838,
        0.060132741928100586,
        -0.05068787932395935,
        0.03571431338787079,
        0.059288255870342255,
        -0.06047606095671654,
        0.027886737138032913,
        0.01833285018801689,
        0.03337228670716286,
        0.028060803189873695,
        -0.02531481720507145,
        0.001087590935640037,
        -0.06073351204395294,
        -0.01726977713406086,
        -0.05841171741485596,
        0.04289160296320915,
        0.049111999571323395,
        -0.052799537777900696,
        0.03263550251722336,
        0.017966775223612785,
        -0.023587241768836975,
        -0.0563228577375412,
        -0.03048655018210411,
        0.03032522089779377,
        0.016372067853808403,
        -0.053964197635650635,
        -0.05205022543668747,
        0.019019246101379395,
        -0.01377523597329855,
        -0.05285750329494476,
        0.05482982471585274,
        -0.05939660966396332,
        0.05997088551521301,
        -0.024000423029065132,
        -0.033113639801740646,
        0.0002663130289874971,
        -0.05541644245386124,
        0.061517827212810516,
        0.02734953537583351,
        -0.061161164194345474,
        0.05436515063047409,
        -0.008978040888905525,
        0.05815399810671806,
        -0.05283232778310776,
        0.004040410742163658,
        -0.03446751460433006,
        -0.05574774742126465,
        -0.04341045022010803,
        -0.035776287317276,
        0.04919775575399399,
        -0.056881777942180634,
        0.05952148512005806,
        -0.05711320787668228,
        0.030029911547899246,
        -0.007034548558294773,
        -0.06031084433197975,
        -0.058777015656232834,
        0.05443723499774933,
        -0.026746252551674843,
        0.052053093910217285,
        -0.055562037974596024,
        -0.05832032486796379,
        0.04177817702293396,
        0.0583331473171711,
        -0.049635447561740875,
        0.040559105575084686,
        0.03569362312555313,
        0.05557979643344879,
        -0.05200537294149399,
        0.033153340220451355,
        0.03702012822031975,
        -0.05704446882009506,
        -0.024471910670399666,
        0.06146354600787163,
        -0.04983871430158615,
        0.05775453522801399,
        0.042956043034791946,
        -0.04536238685250282,
        0.05900432541966438,
        -0.011465754359960556,
        0.05540931224822998,
        0.046573564410209656,
        0.025241443887352943,
        -0.060248203575611115,
        -0.061493970453739166,
        0.061651792377233505,
        0.023696796968579292,
        0.05157075822353363,
        0.05163085460662842,
        -0.057838354259729385,
        0.060387544333934784,
        -0.06009877473115921,
        0.057312220335006714,
        -0.02394380420446396,
        -0.0586179755628109,
        -0.05278076231479645,
        0.04923256114125252,
        0.014026650227606297,
        -0.01575947366654873,
        -0.05247927084565163,
        -0.020188018679618835,
        0.0501725971698761,
        0.054321642965078354,
        -0.05573515221476555,
        -0.05672649294137955,
        -0.006082180887460709,
        -0.05936466529965401,
        0.06169361621141434,
        0.009301693178713322,
        0.06133027374744415,
        -0.061382997781038284,
        0.00938198808580637,
        -0.06101910397410393,
        0.05663922801613808,
        -0.00007695474778302014,
        0.050277434289455414,
        0.05559734255075455,
        0.05184564366936684,
        -0.01566448248922825,
        0.007377039175480604,
        -0.056506168097257614,
        -0.04444502666592598,
        0.03170458972454071,
        -0.060878362506628036,
        0.003642505267634988,
        0.05546414852142334,
        -0.056805893778800964,
        0.01596342772245407,
        -0.04932720214128494,
        -0.05380616709589958,
        -0.059118714183568954,
        -0.033197611570358276,
        0.023498328402638435,
        0.04451540857553482,
        -0.01111689954996109,
        -0.039761982858181,
        0.0597255639731884,
        0.03560148924589157,
        -0.05746499076485634,
        0.014783053658902645,
        -0.05768681317567825,
        -0.04035509377717972,
        0.049034714698791504,
        0.03994934260845184,
        0.022312909364700317,
        0.04114069417119026,
        0.052127376198768616,
        0.031055662781000137,
        0.040933758020401,
        0.03618256375193596,
        0.02215159498155117,
        -0.015501084737479687,
        -0.040223415940999985,
        0.014284105971455574,
        0.056533508002758026,
        0.059689778834581375,
        -0.019747985526919365,
        -0.03288273513317108,
        0.0067519983276724815,
        0.01738157868385315,
        0.03772974759340286,
        -0.06111450120806694,
        -0.05807599052786827,
        -0.045786093920469284,
        -0.05972615256905556,
        0.004034168552607298,
        0.03991888463497162,
        -0.03395284712314606,
        0.05760845169425011,
        -0.05297931656241417,
        -0.01533313374966383,
        0.06093064323067665,
        -0.0240909643471241,
        0.029983436688780785,
        0.05362305790185928,
        -0.02618463523685932,
        -0.043526411056518555,
        -0.0003841980069410056,
        -0.03133765608072281,
        0.04053695499897003,
        -0.05064450204372406,
        0.004029925912618637,
        0.01383295003324747,
        0.03937556594610214,
        0.0514223612844944,
        -0.05502145364880562,
        -0.0058225239627063274,
        0.06133871525526047,
        -0.0538986399769783,
        0.05253564193844795,
        -0.060995910316705704,
        0.055092453956604004,
        0.05241038650274277,
        0.054367586970329285,
        -0.04893767833709717,
        0.05645644664764404,
        -0.04850127547979355,
        0.008503862656652927,
        -0.023868374526500702,
        -0.022870998829603195,
        0.0489889457821846,
        -0.03704705089330673,
        -0.02084307000041008,
        -0.05278799682855606,
        -0.05487583950161934,
        0.056391239166259766,
        0.060697223991155624,
        -0.061192698776721954,
        0.051297567784786224,
        -0.057407233864068985,
        -0.060375843197107315,
        -0.05185812711715698,
        -0.03052402473986149,
        0.05696595832705498,
        0.047427698969841,
        0.041567690670490265,
        0.014368802309036255,
        -0.03676223009824753,
        0.05608421936631203,
        0.02739795669913292,
        -0.007695844862610102,
        -0.04725721478462219,
        -0.016634725034236908,
        0.04759034141898155,
        0.04544157534837723,
        0.04990982264280319,
        0.027485867962241173,
        0.02625231072306633,
        0.016624724492430687,
        -0.05693681910634041,
        -0.032480333000421524,
        -0.05712106451392174,
        -0.04629085585474968,
        0.058710936456918716,
        0.05632616952061653,
        0.05666559934616089,
        -0.0005995826213620603,
        -0.05470328778028488,
        0.03173098340630531,
        0.05712345615029335,
        0.049936167895793915,
        -0.059760261327028275,
        -0.048498090356588364,
        -0.057688359171152115,
        -0.052873436361551285,
        0.056220002472400665,
        -0.04104936495423317,
        0.06147824227809906,
        0.030611393973231316,
        -0.04930670186877251,
        -0.016701648011803627,
        0.046721357852220535,
        0.05768179893493652
    ],
    [
        -0.05703187361359596,
        0.05224354937672615,
        0.05338447913527489,
        0.00810087751597166,
        0.05386478081345558,
        -0.04999842122197151,
        0.055625103414058685,
        -0.02961081825196743,
        0.04806234687566757,
        0.025982242077589035,
        0.04944538325071335,
        0.057898037135601044,
        -0.05620330199599266,
        -0.0532454252243042,
        -0.05751659348607063,
        0.057458944618701935,
        -0.05514265596866608,
        0.04393094778060913,
        -0.02743964083492756,
        0.006670730654150248,
        -0.013012934476137161,
        -0.024446820840239525,
        0.057793717831373215,
        -0.006735228933393955,
        0.05624426156282425,
        0.024648714810609818,
        0.0444754920899868,
        0.04415467754006386,
        -0.05755128338932991,
        -0.015068632550537586,
        0.010883255861699581,
        -0.05787987634539604,
        0.05770277604460716,
        -0.05396455153822899,
        0.018854895606637,
        -0.04196557402610779,
        -0.05366663262248039,
        -0.056047841906547546,
        -0.05712887644767761,
        -0.020965270698070526,
        -0.04247116297483444,
        -0.00279313325881958,
        0.054724209010601044,
        -0.046834610402584076,
        0.040474798530340195,
        0.03967847302556038,
        0.053769633173942566,
        0.05151153355836868,
        0.05742006003856659,
        0.04535437747836113,
        0.05316879600286484,
        -0.05785146728157997,
        0.05153178051114082,
        -0.03447073698043823,
        -0.022695044055581093,
        0.04418189078569412,
        -0.04218149930238724,
        -0.03676151856780052,
        0.0134358499199152,
        0.007202832493931055,
        -0.054102443158626556,
        -0.006791407708078623,
        -0.052324362099170685,
        0.024323346093297005,
        0.04619451239705086,
        -0.002794829197227955,
        0.016808565706014633,
        -0.05690886825323105,
        -0.05646074190735817,
        -0.05803914740681648,
        -0.049945324659347534,
        -0.05423147231340408,
        0.049326445907354355,
        0.028025560081005096,
        -0.044990651309490204,
        -0.030827589333057404,
        0.02630416490137577,
        0.05776188150048256,
        -0.03944934904575348,
        0.01239191647619009,
        -0.03551804646849632,
        -0.014062676578760147,
        -0.035411395132541656,
        0.0039769187569618225,
        -0.03367270529270172,
        0.043825045228004456,
        -0.03407598286867142,
        -0.04257035627961159,
        0.011816846206784248,
        0.05805716663599014,
        -0.03677520155906677,
        -0.006119532976299524,
        -0.009712038561701775,
        -0.0017524283612146974,
        -0.02986125461757183,
        -0.04730101302266121,
        -0.037451520562171936,
        -0.054688774049282074,
        -0.044738151133060455,
        -0.04395994916558266,
        -0.05811358988285065,
        -0.025689318776130676,
        0.0554959736764431,
        0.05806868523359299,
        0.027388982474803925,
        -0.04341118782758713,
        -0.017236661165952682,
        0.04888276383280754,
        0.0020880342926830053,
        0.0037889692466706038,
        -0.04353686049580574,
        -0.05419459193944931,
        -0.052770890295505524,
        -0.04566933214664459,
        -0.029684361070394516,
        -0.04523458331823349,
        -0.049331385642290115,
        -0.057205330580472946,
        -0.011415049433708191,
        -0.043094635009765625,
        -0.03408635035157204,
        -0.029139429330825806,
        0.030927982181310654,
        -0.029151391237974167,
        -0.05768229439854622,
        -0.012090155854821205,
        0.01713966764509678,
        0.05279252305626869,
        0.040310125797986984,
        -0.00285705435089767,
        -0.052342843264341354,
        -0.04137486591935158,
        -0.04803495481610298,
        0.05184543877840042,
        -0.055403679609298706,
        -0.051897428929805756,
        0.04821578413248062,
        -0.050654828548431396,
        -0.037226349115371704,
        0.05767730623483658,
        0.0433945432305336,
        0.055545903742313385,
        -0.007848226465284824,
        0.05715268477797508,
        0.029359128326177597,
        0.04907630383968353,
        0.05564172565937042,
        -0.04209071770310402,
        -0.050891485065221786,
        -0.051092229783535004,
        -0.04204842448234558,
        -0.05741039663553238,
        -0.045203544199466705,
        -0.03614213317632675,
        0.05791020020842552,
        -0.030451633036136627,
        0.05520906671881676,
        -0.027638860046863556,
        0.03686469793319702,
        0.020130617544054985,
        0.057077813893556595,
        0.03739694133400917,
        0.02355935610830784,
        -0.05343901738524437,
        -0.055206067860126495,
        -0.04464524984359741,
        -0.03665269911289215,
        -0.05556608363986015,
        -0.0524294488132,
        0.05583583191037178,
        -0.03756493330001831,
        0.04950547218322754,
        -0.04709756001830101,
        0.05597113445401192,
        -0.05758846923708916,
        0.04763299971818924,
        -0.05451852083206177,
        -0.010959070175886154,
        0.05406951531767845,
        0.04780887812376022,
        0.05461883172392845,
        0.010690408758819103,
        0.04951614886522293,
        -0.008528661914169788,
        -0.031675785779953,
        0.029145240783691406,
        -0.04319148138165474,
        0.05517522990703583,
        -0.057751212269067764,
        -0.057753246277570724,
        -0.004353699740022421,
        -0.05025890842080116,
        -0.004294275306165218,
        -0.05655176565051079,
        -0.023621050640940666,
        0.056558188050985336,
        0.05021614953875542,
        -0.0337354801595211,
        0.015947336331009865,
        0.01998518966138363,
        -0.03590388968586922,
        0.02099543623626232,
        0.011575620621442795,
        0.03291608765721321,
        0.012302367947995663,
        0.041996680200099945,
        0.05687929317355156,
        0.03343144431710243,
        -0.050095122307538986,
        -0.04333975538611412,
        -0.05273149535059929,
        0.037800151854753494,
        0.05776533856987953,
        0.034112803637981415,
        0.04947224259376526,
        0.05620560050010681,
        0.05801459401845932,
        0.04952884092926979,
        0.05790745094418526,
        -0.05071929469704628,
        0.017068946734070778,
        -0.05554681643843651,
        -0.03197425603866577,
        -0.056252602487802505,
        0.048969194293022156,
        -0.044246599078178406,
        0.039101459085941315,
        -0.04699952155351639,
        0.04481680318713188,
        -0.056786615401506424,
        0.03312591090798378,
        -0.05656887963414192,
        -0.05512717738747597,
        0.0535135418176651,
        0.03515499085187912,
        0.03401268273591995,
        0.05406219884753227,
        0.0026990154292434454,
        0.034070804715156555,
        0.05385918170213699,
        0.042029689997434616,
        0.048120155930519104,
        0.052136726677417755,
        -0.051724765449762344,
        0.04084465280175209,
        -0.011245310306549072,
        -0.05411941558122635,
        0.0012334081111475825,
        -0.008326387032866478,
        -0.03841463103890419,
        -0.002358397701755166,
        -0.05789138004183769,
        0.05246948078274727,
        -0.056800033897161484,
        0.048907794058322906,
        0.056571152061223984,
        -0.019782613962888718,
        0.010883900336921215,
        0.05801452323794365,
        0.03452926501631737,
        0.05809556692838669,
        0.051954254508018494,
        0.04276120662689209,
        0.046053919941186905,
        -0.05366848036646843,
        -0.004477108363062143,
        0.04435432329773903,
        -0.03831474483013153,
        0.05615316331386566,
        -0.01739606074988842,
        0.0075711822137236595,
        -0.04705780744552612,
        0.019558748230338097,
        -0.05687151104211807,
        0.04639251157641411,
        -0.010863259434700012,
        -0.05750580132007599,
        0.02771860919892788,
        0.05579353868961334,
        -0.04870035499334335,
        -0.05664258450269699,
        -0.054971158504486084,
        0.05476079508662224,
        0.05765417590737343,
        -0.05788913369178772,
        0.03228795528411865,
        -0.0217913705855608,
        -0.035954345017671585,
        -0.03682154044508934,
        0.05237092077732086,
        -0.021470965817570686,
        0.05509931966662407,
        -0.022529637441039085,
        -0.03822362795472145,
        -0.009146736934781075,
        -0.04778497293591499,
        0.05791180580854416,
        0.043951615691185,
        -0.020957015454769135,
        0.05691999942064285,
        -0.05757176876068115,
        0.05682207643985748,
        -0.023655777797102928,
        0.007477729581296444,
        -0.05574953556060791,
        -0.05236433446407318,
        -0.019479569047689438,
        0.008227099664509296,
        0.05702558532357216,
        -0.05776555836200714,
        0.049552515149116516,
        -0.05221261829137802,
        -0.04966742917895317,
        0.05493006855249405,
        -0.05471038073301315,
        -0.05641033127903938,
        0.04663565754890442,
        0.04757656529545784,
        0.050489988178014755,
        -0.053098246455192566,
        -0.055645983666181564,
        0.03939743712544441,
        0.01013005431741476,
        -0.05663592368364334,
        0.05366833508014679,
        0.044841405004262924,
        0.05141306668519974,
        -0.05790383741259575,
        0.001799707650206983,
        0.0036646814551204443,
        -0.03538607805967331,
        0.0004621214757207781,
        0.011841976083815098,
        -0.03956417366862297,
        0.055609896779060364,
        0.032320473343133926,
        -0.04555187374353409,
        0.05648120120167732,
        -0.04972176253795624,
        0.017775503918528557,
        0.03827313706278801,
        -0.03372716158628464,
        -0.05761706084012985,
        -0.05614103376865387,
        0.05814134702086449,
        0.05366099253296852,
        0.05413174629211426,
        0.0503091998398304,
        -0.026278430595993996,
        0.05781151354312897,
        -0.05790150165557861,
        -0.04948634281754494,
        -0.03711966797709465,
        -0.055052559822797775,
        0.032815948128700256,
        0.05517595633864403,
        0.010690962895751,
        -0.01731814444065094,
        -0.053965769708156586,
        -0.04305339604616165,
        0.05367782711982727,
        0.012366079725325108,
        -0.058117885142564774,
        -0.03917470946907997,
        0.022290311753749847,
        -0.05675968900322914,
        0.05728389322757721,
        -0.012313210405409336,
        0.05670396238565445,
        -0.05807238072156906,
        -0.056861575692892075,
        -0.058049608021974564,
        0.05732989311218262,
        -0.04895534738898277,
        0.05085870251059532,
        0.05521274358034134,
        0.05354399234056473,
        0.0518089234828949,
        0.011099203489720821,
        -0.05154453217983246,
        -0.027641339227557182,
        -0.045702554285526276,
        -0.05800040438771248,
        0.04681621491909027,
        0.020975664258003235,
        -0.05740482732653618,
        0.053713567554950714,
        -0.05715649202466011,
        -0.024527624249458313,
        -0.045058924704790115,
        -0.024979118257761,
        -0.04391882196068764,
        0.04519740864634514,
        0.020033951848745346,
        -0.05263477936387062,
        0.05614447221159935,
        0.006334291305392981,
        -0.052981339395046234,
        -0.006974691990762949,
        -0.05781347677111626,
        -0.04450112581253052,
        0.05452648550271988,
        0.054351866245269775,
        -0.04779250547289848,
        -0.02903982810676098,
        0.055759966373443604,
        -0.017060840502381325,
        0.02201622910797596,
        0.05415457859635353,
        -0.04207046702504158,
        0.008429140783846378,
        0.016988473013043404,
        0.029935238882899284,
        0.030937623232603073,
        0.05687987804412842,
        -0.04062703251838684,
        -0.03966477885842323,
        0.050753261893987656,
        0.05455203726887703,
        0.04986770078539848,
        -0.055719099938869476,
        -0.0575101263821125,
        0.057029563933610916,
        -0.057609930634498596,
        0.0454244539141655,
        0.04624081403017044,
        -0.013707268983125687,
        0.05187401920557022,
        -0.05692382529377937,
        0.018226683139801025,
        0.05645968019962311,
        -0.0011358862975612283,
        0.00939128827303648,
        0.056178342550992966,
        0.016407040879130363,
        0.02092818170785904,
        -0.045783281326293945,
        0.05724305659532547,
        0.00724048400297761,
        -0.054348185658454895,
        -0.045493192970752716,
        0.03743531554937363,
        0.05556230619549751,
        0.05747305601835251,
        -0.055458493530750275,
        -0.015502309426665306,
        0.057292137295007706,
        -0.0578622929751873,
        0.05541634559631348,
        -0.0549340657889843,
        -0.005675316322594881,
        0.04955873638391495,
        0.054997965693473816,
        -0.011641096323728561,
        0.046569518744945526,
        -0.05500990152359009,
        -0.03154860809445381,
        0.004735415335744619,
        -0.0521782711148262,
        0.04536331444978714,
        -0.00141643057577312,
        -0.056528281420469284,
        -0.055885616689920425,
        -0.05708395317196846,
        0.05445749685168266,
        0.05767642706632614,
        -0.05804785341024399,
        0.044646378606557846,
        -0.05730012059211731,
        -0.057482413947582245,
        0.01739371195435524,
        0.04277382418513298,
        0.04884065315127373,
        0.05597091093659401,
        0.05030256509780884,
        -0.00660241162404418,
        -0.02424650639295578,
        0.05409722402691841,
        -0.05684351548552513,
        -0.05116470530629158,
        -0.038810908794403076,
        -0.0044740731827914715,
        0.03516145795583725,
        0.04320591688156128,
        -0.03126797825098038,
        0.021300427615642548,
        0.044890888035297394,
        -0.009972627274692059,
        -0.054544396698474884,
        -0.04309957101941109,
        -0.05150812491774559,
        -0.050763633102178574,
        0.03490840271115303,
        -0.010756861418485641,
        0.05797303095459938,
        -0.009935478679835796,
        0.05661480128765106,
        -0.04612300917506218,
        0.05636297166347504,
        0.0500657893717289,
        -0.05790794640779495,
        -0.04931088909506798,
        -0.05124498903751373,
        -0.01596369966864586,
        0.0019360296428203583,
        0.0024046413600444794,
        0.03997036814689636,
        0.02767522819340229,
        -0.0561431348323822,
        -0.05567862465977669,
        0.05548768863081932,
        0.0579703226685524
    ],
    [
        -0.015119671821594238,
        -0.041770994663238525,
        0.04048650711774826,
        0.016043830662965775,
        -0.04927198216319084,
        -0.04623112082481384,
        0.05349135771393776,
        -0.05053843930363655,
        0.04289105162024498,
        -0.052454881370067596,
        0.05265648663043976,
        0.02686617709696293,
        -0.05331810563802719,
        -0.05030876398086548,
        -0.05278870835900307,
        0.04085693508386612,
        -0.04139203205704689,
        0.0535653717815876,
        -0.007543719839304686,
        0.04848717525601387,
        -0.05303916335105896,
        0.0510786697268486,
        0.053478773683309555,
        0.017389746382832527,
        -0.05354907736182213,
        0.045465368777513504,
        0.03698645159602165,
        0.053570885211229324,
        -0.04975331574678421,
        0.05331731215119362,
        0.032164067029953,
        -0.05311751738190651,
        0.05301516503095627,
        -0.053600870072841644,
        0.052750661969184875,
        -0.047284070402383804,
        -0.05300350859761238,
        -0.020058535039424896,
        0.05354393646121025,
        -0.017991062253713608,
        0.04863978177309036,
        0.029733775183558464,
        0.04763905704021454,
        0.03093690611422062,
        -0.038395337760448456,
        0.037889450788497925,
        0.05052150413393974,
        0.05260079354047775,
        0.05356864631175995,
        0.004616494290530682,
        0.04800114408135414,
        0.028351522982120514,
        0.015803320333361626,
        -0.05216183513402939,
        0.018626300618052483,
        0.010001886636018753,
        -0.021015720441937447,
        -0.05192892998456955,
        -0.04912465438246727,
        0.03469269722700119,
        -0.02111862041056156,
        0.022389966994524002,
        -0.051336489617824554,
        -0.024762511253356934,
        0.0342426635324955,
        -0.05330762267112732,
        -0.053573161363601685,
        -0.04739779978990555,
        0.02824104204773903,
        -0.05329783260822296,
        -0.03970392420887947,
        -0.05208450183272362,
        0.053502991795539856,
        0.05223637446761131,
        -0.05303679034113884,
        -0.037195101380348206,
        0.05276857689023018,
        0.053603630512952805,
        0.04946494475007057,
        0.006800507195293903,
        -0.037520039826631546,
        -0.05342767760157585,
        -0.053574174642562866,
        0.04818563535809517,
        -0.02092692069709301,
        0.02582997828722,
        0.027321839705109596,
        -0.05110115930438042,
        0.03919830918312073,
        0.049004606902599335,
        -0.052664149552583694,
        -0.053205087780952454,
        -0.04253815487027168,
        0.02148846909403801,
        -0.015244991518557072,
        0.05295214429497719,
        -0.04022454097867012,
        -0.04661940783262253,
        -0.041165582835674286,
        0.024603085592389107,
        -0.0487067811191082,
        0.05358004942536354,
        0.048134297132492065,
        0.053330257534980774,
        -0.03564212843775749,
        -0.05358010530471802,
        0.010869541205465794,
        0.03960835188627243,
        -0.04545042663812637,
        0.023563560098409653,
        0.053320493549108505,
        -0.05263562500476837,
        0.04622218385338783,
        0.045976124703884125,
        -0.03541193902492523,
        0.05070776492357254,
        -0.032641708850860596,
        -0.04765275865793228,
        0.03122192807495594,
        -0.041790030896663666,
        0.03703159838914871,
        -0.03370930626988411,
        0.03377185016870499,
        -0.053455900400877,
        -0.03651043400168419,
        0.050149835646152496,
        0.052222058176994324,
        0.053592074662446976,
        0.048817310482263565,
        -0.013473374769091606,
        -0.03802120313048363,
        -0.05120033398270607,
        -0.04952839016914368,
        0.053587574511766434,
        -0.039296139031648636,
        -0.0495745949447155,
        0.049918703734874725,
        0.03109888732433319,
        -0.040134135633707047,
        0.0409313403069973,
        -0.026017606258392334,
        0.047819674015045166,
        0.04770631343126297,
        0.05280619487166405,
        -0.044580891728401184,
        -0.006378789898008108,
        -0.04584798589348793,
        -0.04193143546581268,
        -0.05360356345772743,
        -0.05276757478713989,
        -0.04778279364109039,
        -0.05281519517302513,
        -0.0386333093047142,
        0.042485177516937256,
        0.05359021946787834,
        -0.008633059449493885,
        0.028392093256115913,
        -0.0394279845058918,
        0.05345884710550308,
        -0.04029753431677818,
        0.021678423509001732,
        -0.05162481218576431,
        0.051214974373579025,
        -0.020148295909166336,
        0.029474882408976555,
        0.04562033340334892,
        0.03152133524417877,
        0.04700065776705742,
        -0.03004569187760353,
        0.024693327024579048,
        -0.05359085649251938,
        0.0451011098921299,
        -0.05354122072458267,
        0.05272194370627403,
        -0.053290143609046936,
        -0.03467525169253349,
        0.031128842383623123,
        -0.05175156518816948,
        0.048881057649850845,
        0.00023135730589274317,
        -0.03991293907165527,
        0.04617197439074516,
        0.047524187713861465,
        -0.028482966125011444,
        0.017687277868390083,
        0.042183004319667816,
        -0.032073017209768295,
        0.05308886244893074,
        0.03276749700307846,
        -0.03530662879347801,
        -0.053593914955854416,
        0.04617561027407646,
        0.053526416420936584,
        -0.0348636694252491,
        -0.05314629524946213,
        0.010588976554572582,
        0.025722172111272812,
        0.05191816762089729,
        -0.052036020904779434,
        0.021734679117798805,
        0.04769172519445419,
        0.04983218014240265,
        -0.03966738283634186,
        -0.050511185079813004,
        0.05359084904193878,
        -0.05350774526596069,
        0.04736937955021858,
        0.04586092755198479,
        -0.05329049006104469,
        0.01696646958589554,
        -0.05316410958766937,
        0.04580206796526909,
        0.05358593165874481,
        0.036649998277425766,
        0.05307093635201454,
        -0.04405352473258972,
        0.053571902215480804,
        -0.00846486072987318,
        0.05355430766940117,
        0.05129421874880791,
        0.044130776077508926,
        -0.03906666859984398,
        -0.046240124851465225,
        -0.050980035215616226,
        0.049657877534627914,
        -0.053343772888183594,
        0.04210735112428665,
        -0.0528033971786499,
        0.04403939098119736,
        -0.05347875505685806,
        0.00585200684145093,
        -0.04841209575533867,
        0.014716139994561672,
        0.052399542182683945,
        0.051748260855674744,
        0.05355244129896164,
        0.040852341800928116,
        -0.02701214887201786,
        0.016756923869252205,
        0.05355876311659813,
        0.05285849794745445,
        0.02502559684216976,
        -0.05233127996325493,
        -0.0536036491394043,
        -0.014086596667766571,
        -0.04269465431571007,
        0.03344203159213066,
        -0.02545567788183689,
        -0.050658196210861206,
        -0.05345054715871811,
        -0.01749369688332081,
        -0.03384694829583168,
        0.05358543246984482,
        -0.051540814340114594,
        0.053550027310848236,
        -0.005882532801479101,
        0.03283017873764038,
        -0.04794405773282051,
        0.046943049877882004,
        0.03913330286741257,
        -0.04938199743628502,
        0.04704654589295387,
        -0.005172237753868103,
        0.053507883101701736,
        -0.05210026726126671,
        0.05357511341571808,
        0.053424958139657974,
        0.053588446229696274,
        -0.05344914272427559,
        0.05097890645265579,
        -0.018932219594717026,
        -0.052052922546863556,
        0.05359278991818428,
        -0.0520845390856266,
        0.03345383703708649,
        0.03579311817884445,
        -0.03612649440765381,
        -0.05203972011804581,
        0.05219852924346924,
        -0.009205175563693047,
        -0.0531056672334671,
        0.05313463136553764,
        -0.04100490361452103,
        0.042127951979637146,
        -0.013028832152485847,
        0.05353093147277832,
        -0.007250673603266478,
        0.05039474368095398,
        0.05345221981406212,
        0.02311672829091549,
        -0.053044602274894714,
        -0.02867244742810726,
        -0.048729535192251205,
        -0.05186181142926216,
        0.03168174624443054,
        -0.05315579101443291,
        0.053594306111335754,
        0.05210663750767708,
        -0.05285032093524933,
        0.051996584981679916,
        0.00910629890859127,
        -0.019241997972130775,
        -0.03537917509675026,
        0.04972467198967934,
        -0.03987893462181091,
        -0.021203961223363876,
        -0.04700297862291336,
        0.05359611660242081,
        0.0497707724571228,
        -0.052247945219278336,
        0.048564352095127106,
        -0.0032720391172915697,
        0.04248649999499321,
        0.05330750346183777,
        0.0037789298221468925,
        -0.05261201411485672,
        -0.03712711110711098,
        0.05360306426882744,
        0.05292341113090515,
        0.00509260781109333,
        -0.046411365270614624,
        0.04785716161131859,
        0.052467189729213715,
        -0.0020509089808911085,
        0.05356990173459053,
        0.053535208106040955,
        0.016936354339122772,
        -0.04085906594991684,
        -0.05167185142636299,
        -0.0520998053252697,
        0.039772942662239075,
        -0.05115356668829918,
        0.053578075021505356,
        0.05360353738069534,
        0.05267675220966339,
        0.05360003560781479,
        -0.05221177637577057,
        -0.04515029117465019,
        -0.03647023066878319,
        -0.046408578753471375,
        -0.052193790674209595,
        -0.05332579463720322,
        0.042174022644758224,
        0.053188521414995193,
        0.03867987170815468,
        -0.030231265351176262,
        0.051853206008672714,
        0.05275030806660652,
        -0.04641870781779289,
        0.05327463895082474,
        0.05025805905461311,
        0.05233537033200264,
        0.024028955027461052,
        -0.05327773839235306,
        -0.050902076065540314,
        -0.050918035209178925,
        -0.05360215902328491,
        -0.03353217616677284,
        -0.04925365000963211,
        -0.00688944011926651,
        0.04564812406897545,
        0.053278401494026184,
        -0.007564427796751261,
        -0.0457829013466835,
        0.02254064567387104,
        -0.052121277898550034,
        0.053595345467329025,
        0.04459335654973984,
        0.01151231862604618,
        -0.052488360553979874,
        0.028392400592565536,
        -0.05354582890868187,
        -0.024956341832876205,
        -0.04392974451184273,
        0.047364842146635056,
        0.053603511303663254,
        -0.026316188275814056,
        0.01895030215382576,
        0.05355836823582649,
        0.03085104376077652,
        -0.04790434241294861,
        0.052102189511060715,
        -0.04898526892066002,
        0.025566430762410164,
        0.030272692441940308,
        -0.05357237532734871,
        0.03675364330410957,
        -0.0522652268409729,
        -0.03251111879944801,
        -0.05320176109671593,
        -0.053581103682518005,
        -0.05193076282739639,
        -0.04981072247028351,
        -0.042594969272613525,
        0.052635855972766876,
        0.053362295031547546,
        -0.04252773895859718,
        -0.05288783460855484,
        -0.05166168510913849,
        -0.052080634981393814,
        -0.038036853075027466,
        0.05344449728727341,
        -0.05331718921661377,
        0.03726208955049515,
        -0.04127650707960129,
        -0.047863252460956573,
        0.04208789020776749,
        -0.05189892277121544,
        0.053466763347387314,
        0.004402423743158579,
        -0.049709752202034,
        -0.04897034168243408,
        0.0026241999585181475,
        0.052979156374931335,
        0.04681219160556793,
        -0.05307808890938759,
        -0.026056010276079178,
        -0.037313688546419144,
        0.05353790149092674,
        0.05261870101094246,
        0.053428471088409424,
        -0.02776111662387848,
        -0.02500883862376213,
        -0.0535053089261055,
        0.0006019272259436548,
        -0.033063262701034546,
        0.03251676633954048,
        0.04611598700284958,
        -0.052896130830049515,
        -0.049375612288713455,
        -0.05323720723390579,
        -0.05213303118944168,
        -0.053172457963228226,
        -0.05199119821190834,
        -0.050196196883916855,
        -0.05175802484154701,
        -0.04678042232990265,
        0.053588178008794785,
        -0.05171124264597893,
        -0.05271444842219353,
        -0.05337352305650711,
        0.05225718766450882,
        0.050475846976041794,
        0.051845986396074295,
        -0.0513729602098465,
        0.031086750328540802,
        0.05303211882710457,
        -0.029159996658563614,
        0.04038769006729126,
        0.05359131842851639,
        0.03095366805791855,
        -0.023995354771614075,
        0.05064086243510246,
        0.03763943538069725,
        -0.041065145283937454,
        -0.051884330809116364,
        -0.009821798652410507,
        -0.01339800376445055,
        0.02361270971596241,
        -0.01394959818571806,
        0.0531005859375,
        -0.053505003452301025,
        0.02763141132891178,
        -0.041599810123443604,
        0.0521378219127655,
        0.05351812392473221,
        -0.05278888717293739,
        0.053534891456365585,
        -0.03606197237968445,
        -0.053188811987638474,
        0.04423430562019348,
        -0.016904206946492195,
        0.05231641232967377,
        0.04976818710565567,
        -0.051628753542900085,
        0.04987168312072754,
        -0.026029447093605995,
        0.050575342029333115,
        -0.05350279435515404,
        -0.03612874075770378,
        -0.02493993192911148,
        -0.03213901072740555,
        -0.03504223749041557,
        0.02634938433766365,
        -0.053336553275585175,
        0.0525430366396904,
        -0.02130107767879963,
        0.05096365883946419,
        -0.05334680527448654,
        -0.041895363479852676,
        -0.013094273395836353,
        -0.05343092978000641,
        0.053511202335357666,
        0.04554535448551178,
        0.028583722189068794,
        0.05052952468395233,
        0.05232786014676094,
        -0.05253010615706444,
        0.048292018473148346,
        0.04611769691109657,
        0.004720352590084076,
        0.05087610334157944,
        -0.051439426839351654,
        -0.049114376306533813,
        0.05341997370123863,
        0.043644316494464874,
        0.05358312651515007,
        -0.03517677262425423,
        0.05355953797698021,
        -0.05357710272073746,
        0.053367387503385544,
        -0.04336368292570114
    ],
    [
        0.013054992072284222,
        -0.054893720895051956,
        0.05501345172524452,
        -0.04510744288563728,
        0.016513412818312645,
        -0.05304306373000145,
        0.05542277544736862,
        -0.05559458211064339,
        -0.030111147090792656,
        0.055919099599123,
        0.05592132359743118,
        0.054777733981609344,
        -0.05588313192129135,
        -0.05437122657895088,
        -0.05576073005795479,
        -0.022132541984319687,
        0.026055382564663887,
        0.05509599670767784,
        -0.044892292469739914,
        0.02274695411324501,
        -0.001967196585610509,
        0.027548203244805336,
        0.05505147948861122,
        0.002005733083933592,
        -0.010391934774816036,
        -0.051203902810811996,
        0.04197230562567711,
        0.04411805793642998,
        -0.0389057882130146,
        0.05589495226740837,
        -0.03041924722492695,
        -0.055743101984262466,
        0.0478934720158577,
        -0.055822089314460754,
        0.0013509676791727543,
        -0.016092190518975258,
        -0.04966335371136665,
        -0.03146640583872795,
        0.0450306199491024,
        0.03528242185711861,
        0.02043883502483368,
        -0.018928097561001778,
        0.04511626809835434,
        0.055795278400182724,
        0.004804637283086777,
        0.01949230395257473,
        0.05591176822781563,
        0.05484510213136673,
        0.0557076595723629,
        -0.012661954388022423,
        0.05575324594974518,
        -0.01906859315931797,
        -0.028503477573394775,
        -0.05319458991289139,
        -0.04630674421787262,
        0.05401152744889259,
        -0.04106000065803528,
        0.003938397392630577,
        -0.03732983022928238,
        0.042787306010723114,
        -0.055132634937763214,
        0.02108004502952099,
        -0.055068954825401306,
        -0.04444773867726326,
        0.04770528897643089,
        -0.028591763228178024,
        -0.05585518106818199,
        -0.04784684255719185,
        0.054308585822582245,
        -0.055931560695171356,
        -0.05568590760231018,
        -0.0547511950135231,
        -0.028903821483254433,
        0.05268523469567299,
        -0.0558171384036541,
        -0.02874213643372059,
        0.024815505370497704,
        0.05595061555504799,
        0.03809710219502449,
        0.028322136029601097,
        -0.05548959597945213,
        -0.013092493638396263,
        -0.05351336672902107,
        0.04554750770330429,
        -0.02089082822203636,
        0.04987560957670212,
        -0.03871811181306839,
        0.031331442296504974,
        0.0038217068649828434,
        0.053088534623384476,
        -0.05583415925502777,
        -0.043094001710414886,
        -0.03138414025306702,
        -0.0424434132874012,
        -0.051061179488897324,
        0.05267408490180969,
        -0.05404457822442055,
        -0.05529928207397461,
        0.014010494574904442,
        -0.02306443639099598,
        -0.05440986156463623,
        0.05592682212591171,
        0.007477067410945892,
        0.053710829466581345,
        0.03488905727863312,
        0.05372011289000511,
        -0.04879935830831528,
        0.0498570017516613,
        0.014182857237756252,
        -0.04859786480665207,
        0.045476995408535004,
        -0.023825688287615776,
        0.01919044367969036,
        0.014148913323879242,
        -0.05526699870824814,
        0.03595435991883278,
        0.029150420799851418,
        -0.043446000665426254,
        -0.053522851318120956,
        0.036736615002155304,
        0.03515467420220375,
        0.03477473184466362,
        0.030144743621349335,
        -0.055813953280448914,
        -0.0508040226995945,
        0.011996177025139332,
        -0.05579007789492607,
        0.054158296436071396,
        0.019597699865698814,
        0.00738563621416688,
        0.033832062035799026,
        0.019658710807561874,
        -0.05393831431865692,
        0.05585266277194023,
        -0.047278326004743576,
        -0.053598303347826004,
        0.05584324151277542,
        -0.05585615709424019,
        -0.05044877901673317,
        -0.007572585251182318,
        -0.01955837570130825,
        -0.050550200045108795,
        0.051224786788225174,
        0.055661775171756744,
        -0.035876717418432236,
        0.05132124945521355,
        0.020947055891156197,
        -0.051996923983097076,
        -0.05562419816851616,
        0.031165622174739838,
        -0.05552766099572182,
        -0.05093898996710777,
        0.02028176188468933,
        0.024440791457891464,
        0.05553603917360306,
        -0.031086066737771034,
        0.04074414074420929,
        0.04571940377354622,
        0.05515043064951897,
        0.01669011078774929,
        0.021152907982468605,
        -0.053859006613492966,
        0.05318884178996086,
        -0.026809316128492355,
        -0.04744460806250572,
        0.04736937955021858,
        0.031076580286026,
        -0.05278445780277252,
        0.03787693381309509,
        0.05017663538455963,
        -0.05190606042742729,
        -0.02156238816678524,
        -0.03221892938017845,
        0.048915307968854904,
        -0.027399515733122826,
        0.050886642187833786,
        -0.053434573113918304,
        0.0412759892642498,
        0.05367858707904816,
        -0.04988187551498413,
        -0.039724770933389664,
        0.027031883597373962,
        0.05355427414178848,
        -0.04165824502706528,
        -0.04831334576010704,
        0.029361827298998833,
        0.05165977403521538,
        0.046692200005054474,
        -0.04340237379074097,
        -0.05350525304675102,
        -0.0549195259809494,
        -0.04963329806923866,
        0.053985510021448135,
        -0.05071335658431053,
        -0.012306208722293377,
        -0.0536123551428318,
        -0.003061524825170636,
        0.0259709432721138,
        -0.050309207290410995,
        -0.005179282743483782,
        0.055310752242803574,
        0.048759639263153076,
        -0.054973118007183075,
        -0.026103241369128227,
        0.05579202249646187,
        0.01213885098695755,
        0.048109330236911774,
        0.044899314641952515,
        -0.055018164217472076,
        0.03784097358584404,
        -0.03680243715643883,
        0.019516408443450928,
        0.05421827733516693,
        0.05567183345556259,
        0.05569811537861824,
        -0.05175568535923958,
        0.054141487926244736,
        0.039814747869968414,
        0.055937863886356354,
        -0.051049135625362396,
        -0.018300147727131844,
        -0.055127400904893875,
        -0.05123540759086609,
        -0.03390065208077431,
        0.045308712869882584,
        -0.03834260255098343,
        0.055688586086034775,
        -0.055816300213336945,
        -0.01408707071095705,
        -0.026415212079882622,
        -0.02978440932929516,
        -0.05475858598947525,
        0.05570429190993309,
        0.05093137174844742,
        -0.008852872997522354,
        -0.005541025195270777,
        0.054343461990356445,
        0.0312863364815712,
        -0.05247606337070465,
        0.05591816082596779,
        0.04856139421463013,
        0.010643077082931995,
        0.0043191323056817055,
        -0.05593392625451088,
        0.04883291944861412,
        -0.04428593069314957,
        0.04017198458313942,
        0.04958970099687576,
        0.04344392567873001,
        -0.04400530830025673,
        -0.04737642779946327,
        -0.04826055094599724,
        0.04369721934199333,
        -0.05432656407356262,
        0.05348093435168266,
        0.043589506298303604,
        0.05594072863459587,
        -0.006577368825674057,
        0.054023973643779755,
        -0.0027207520324736834,
        -0.0035729166120290756,
        0.046998415142297745,
        0.05240219831466675,
        0.0490230992436409,
        -0.03796754032373428,
        0.05512046068906784,
        0.0559375025331974,
        0.051323145627975464,
        0.037312619388103485,
        -0.0547604113817215,
        -0.048561155796051025,
        -0.05580844730138779,
        0.05234875530004501,
        -0.05258660018444061,
        0.04275203496217728,
        0.04642699658870697,
        -0.05421926826238632,
        -0.04847898334264755,
        -0.049460671842098236,
        -0.054383814334869385,
        -0.05397088825702667,
        0.052756328135728836,
        0.05562443658709526,
        0.018804162740707397,
        0.015480590052902699,
        0.036100685596466064,
        0.027194833382964134,
        -0.005860866513103247,
        -0.012710068374872208,
        -0.026685088872909546,
        -0.053993549197912216,
        -0.03774461895227432,
        -0.05083902180194855,
        -0.04992755874991417,
        -0.011544011533260345,
        -0.04287385195493698,
        0.05583594739437103,
        -0.005414910148829222,
        -0.02436049096286297,
        0.054609622806310654,
        -0.05200899764895439,
        -0.02188047766685486,
        0.020929409191012383,
        0.04107382148504257,
        -0.055939581245183945,
        -0.004020810127258301,
        0.0551111064851284,
        0.055918607860803604,
        -0.03654418885707855,
        0.04253893345594406,
        0.05586855858564377,
        0.04552796110510826,
        0.0499485544860363,
        0.053363267332315445,
        -0.04786057025194168,
        -0.053957708179950714,
        0.011978362686932087,
        0.05590984970331192,
        0.04813659191131592,
        0.013372350484132767,
        -0.014917348511517048,
        -0.02856074459850788,
        0.009857059456408024,
        -0.05107640475034714,
        0.05555497482419014,
        -0.0368342399597168,
        0.05564273148775101,
        -0.04236893355846405,
        0.05563298240303993,
        0.024174019694328308,
        -0.0065826247446238995,
        -0.034492719918489456,
        0.05588977783918381,
        0.05594319850206375,
        0.05326910316944122,
        0.054488327354192734,
        0.03221710026264191,
        -0.0142121072858572,
        0.040130455046892166,
        -0.04399292171001434,
        -0.023418810218572617,
        -0.05386706069111824,
        -0.012954805046319962,
        -0.0093610268086195,
        0.05465330928564072,
        0.0376754030585289,
        0.05520801991224289,
        0.0551619790494442,
        -0.055273327976465225,
        0.02885231375694275,
        -0.04989350959658623,
        -0.05014072358608246,
        0.050129275768995285,
        -0.055898282676935196,
        0.0033173656556755304,
        0.008871149271726608,
        -0.05590931326150894,
        -0.055445872247219086,
        -0.05043426901102066,
        -0.029255960136651993,
        0.051922764629125595,
        0.05465392768383026,
        0.036696191877126694,
        -0.05460570007562637,
        0.002150891348719597,
        -0.05514370650053024,
        0.0559450127184391,
        -0.03747718781232834,
        0.052644744515419006,
        -0.05585498362779617,
        -0.05306269973516464,
        -0.054867494851350784,
        -0.018926309421658516,
        -0.03949489817023277,
        -0.01271478459239006,
        0.055815115571022034,
        0.02589239552617073,
        -0.02488313429057598,
        0.05261879041790962,
        0.0022646647412329912,
        -0.027239371091127396,
        0.013100395910441875,
        -0.05451301485300064,
        0.04214180260896683,
        0.0527360662817955,
        -0.05586020275950432,
        0.0551283061504364,
        0.009535380639135838,
        -0.04974869266152382,
        -0.05585138872265816,
        -0.05584687739610672,
        0.016335435211658478,
        -0.042419616132974625,
        0.013653230853378773,
        0.008731752634048462,
        0.04849915951490402,
        0.04638857766985893,
        -0.027617668733000755,
        -0.055893030017614365,
        -0.05587971955537796,
        -0.053562212735414505,
        0.05490947142243385,
        -0.05016317963600159,
        -0.04268154501914978,
        -0.041615311056375504,
        -0.02236364409327507,
        0.04425939917564392,
        -0.04822922125458717,
        0.05562620237469673,
        -0.0556202232837677,
        -0.007420975714921951,
        0.055572155863046646,
        -0.05594286322593689,
        0.055243801325559616,
        0.054517555981874466,
        -0.035915907472372055,
        0.02202513813972473,
        0.04831515997648239,
        0.051144640892744064,
        0.03850048780441284,
        0.05565984547138214,
        -0.034830573946237564,
        0.0517975389957428,
        -0.055944666266441345,
        0.00733942212536931,
        0.02190757915377617,
        -0.03109825775027275,
        0.03581547364592552,
        -0.045935068279504776,
        -0.05540534853935242,
        -0.04268261417746544,
        0.013946847058832645,
        0.029643815010786057,
        -0.023921722546219826,
        0.019373023882508278,
        -0.05355410277843475,
        -0.05278466269373894,
        0.055887870490550995,
        -0.0045214686542749405,
        -0.037956465035676956,
        -0.0537845678627491,
        0.05335194990038872,
        0.04829180985689163,
        0.052186645567417145,
        -0.03845030441880226,
        -0.04523397237062454,
        0.05573610961437225,
        -0.05592691898345947,
        0.05584665387868881,
        0.05030466243624687,
        0.04180803522467613,
        0.05177712067961693,
        0.026619162410497665,
        -0.05531272664666176,
        0.055274900048971176,
        -0.053151682019233704,
        -0.046348538249731064,
        -0.05542973056435585,
        -0.045862603932619095,
        0.048645567148923874,
        0.04956090822815895,
        -0.05548817664384842,
        0.05577690899372101,
        -0.04738640412688255,
        0.05496758222579956,
        0.05574210733175278,
        -0.05566851794719696,
        0.0556974858045578,
        -0.007059188559651375,
        -0.05583376809954643,
        0.04273435100913048,
        0.046867676079273224,
        0.04978017881512642,
        0.05447044596076012,
        -0.053211722522974014,
        0.002082497114315629,
        -0.05576963350176811,
        0.05515553057193756,
        -0.04176560789346695,
        -0.044653575867414474,
        0.0173933207988739,
        0.053174328058958054,
        -0.03737763315439224,
        0.0554695799946785,
        -0.05119019001722336,
        0.054265424609184265,
        0.053598497062921524,
        0.049239661544561386,
        -0.05456102266907692,
        -0.029776258394122124,
        -0.015685923397541046,
        -0.055282220244407654,
        -0.03616685792803764,
        -0.03486450016498566,
        -0.01619863510131836,
        0.051437124609947205,
        0.039278000593185425,
        -0.0556844063103199,
        0.051791414618492126,
        0.05418568477034569,
        -0.05487402528524399,
        0.054291557520627975,
        -0.009731066413223743,
        -0.05594542622566223,
        0.05374322086572647,
        0.043321192264556885,
        0.0466291606426239,
        -0.0055696084164083,
        0.05458395183086395,
        -0.047949276864528656,
        0.05208469182252884,
        0.03218882530927658
    ],
    [
        0.05276019126176834,
        -0.032934438437223434,
        -0.05275113135576248,
        -0.03353848308324814,
        -0.029551470652222633,
        -0.05200275778770447,
        0.013136171735823154,
        -0.04859495535492897,
        0.04785998538136482,
        -0.05276245251297951,
        0.0419120155274868,
        0.0526878759264946,
        -0.05266038700938225,
        -0.052330829203128815,
        -0.051767315715551376,
        0.0028252992779016495,
        -0.021940093487501144,
        0.052099622786045074,
        0.05269318446516991,
        -0.04851289093494415,
        -0.015497618354856968,
        0.04537243768572807,
        0.05253143236041069,
        -0.01544965524226427,
        0.03167657554149628,
        0.05165274068713188,
        0.051446184515953064,
        0.04962855949997902,
        -0.04365864768624306,
        0.05275566875934601,
        0.05264279618859291,
        -0.05276300758123398,
        -0.01892421953380108,
        -0.05276207625865936,
        0.0499219186604023,
        -0.05271473154425621,
        -0.052304044365882874,
        0.04863668978214264,
        0.05276251584291458,
        0.05150022730231285,
        0.050908975303173065,
        0.01709963008761406,
        0.039373937994241714,
        0.047785259783267975,
        -0.003890479449182749,
        -0.051344044506549835,
        0.049861811101436615,
        0.052760351449251175,
        0.052680447697639465,
        0.02994624897837639,
        0.052558865398168564,
        0.03497670963406563,
        -0.010358552448451519,
        -0.052215076982975006,
        -0.05256588011980057,
        -0.05215133726596832,
        0.05203438922762871,
        0.03740876168012619,
        -0.05121038109064102,
        -0.011774384416639805,
        -0.05234961956739426,
        0.04682657867670059,
        -0.049882061779499054,
        0.04003816470503807,
        -0.04779675230383873,
        -0.05170532315969467,
        -0.05132035166025162,
        -0.05146411433815956,
        -0.05241471156477928,
        -0.052727118134498596,
        -0.05263383314013481,
        -0.052722640335559845,
        0.05248463526368141,
        0.04795840010046959,
        -0.05189819261431694,
        -0.014237383380532265,
        0.0068683624267578125,
        0.05276310816407204,
        -0.03531195968389511,
        0.05112611874938011,
        -0.004060042556375265,
        -0.052694205194711685,
        -0.05255947262048721,
        0.01847337931394577,
        -0.01478196494281292,
        0.04347327724099159,
        -0.026593679562211037,
        -0.05186773091554642,
        -0.027688831090927124,
        0.05246128514409065,
        -0.05255281925201416,
        -0.004286227747797966,
        -0.05109751224517822,
        -0.05271880701184273,
        -0.0519573874771595,
        0.05210700258612633,
        -0.05175330862402916,
        -0.049822788685560226,
        -0.030000798404216766,
        0.05253182351589203,
        -0.05270641669631004,
        0.04404402896761894,
        0.04181351885199547,
        0.04824057221412659,
        -0.035260047763586044,
        -0.05170018598437309,
        -0.05271652340888977,
        0.03869951143860817,
        -0.04873362556099892,
        0.05158648267388344,
        -0.0021789055317640305,
        -0.052659451961517334,
        -0.009656905196607113,
        0.03587876632809639,
        -0.05168616771697998,
        0.05146893486380577,
        0.04316912963986397,
        -0.05122145265340805,
        0.038391441106796265,
        -0.04228535667061806,
        0.027239587157964706,
        -0.0036924800369888544,
        0.04581431299448013,
        -0.05265865847468376,
        -0.05206451565027237,
        0.05276081711053848,
        -0.018519623205065727,
        0.05247236415743828,
        0.051087405532598495,
        0.045823823660612106,
        -0.043261680752038956,
        -0.041024349629879,
        -0.0527569055557251,
        0.05275709927082062,
        -0.040655024349689484,
        -0.050717491656541824,
        0.050386831164360046,
        -0.01866583712399006,
        0.05205518752336502,
        0.04902860149741173,
        -0.03705171123147011,
        0.05275987461209297,
        0.030863093212246895,
        0.04990696534514427,
        0.04455270245671272,
        -0.05266553536057472,
        -0.049149684607982635,
        0.050652775913476944,
        0.023772170767188072,
        0.05276298522949219,
        -0.05169251561164856,
        -0.052752137184143066,
        -0.04504844918847084,
        -0.01364012062549591,
        0.011963827535510063,
        0.05275016278028488,
        -0.04059631749987602,
        0.02636244334280491,
        0.05257168039679527,
        0.05226139351725578,
        -0.052245888859033585,
        -0.015459297224879265,
        0.05161381512880325,
        0.049807168543338776,
        0.052035555243492126,
        0.03125011548399925,
        0.052647244185209274,
        -0.051446765661239624,
        -0.02979559265077114,
        0.04678831994533539,
        -0.05263003334403038,
        -0.05275886505842209,
        -0.049937695264816284,
        0.012602227739989758,
        -0.02671743929386139,
        0.04547739401459694,
        -0.03894592449069023,
        -0.027954161167144775,
        0.05272956192493439,
        -0.052758149802684784,
        -0.05255569890141487,
        0.04830088093876839,
        0.040534794330596924,
        -0.04473261535167694,
        0.039217934012413025,
        -0.04980754852294922,
        0.049552012234926224,
        0.05204583704471588,
        -0.05206617712974548,
        -0.04434117302298546,
        -0.05271854251623154,
        0.01842440292239189,
        0.052676498889923096,
        0.05194868892431259,
        -0.03539246320724487,
        -0.038297973573207855,
        -0.03137875348329544,
        0.052560146898031235,
        0.0014913843479007483,
        0.05204661190509796,
        0.03718997910618782,
        -0.045325491577386856,
        -0.052573442459106445,
        -0.05273035541176796,
        0.027939192950725555,
        -0.05274830013513565,
        -0.0355706624686718,
        -0.052432455122470856,
        -0.051150765269994736,
        0.05275862663984299,
        -0.03124394081532955,
        0.02442937158048153,
        0.05269946902990341,
        0.052729297429323196,
        0.052753593772649765,
        0.052398666739463806,
        -0.009666528552770615,
        0.04914307966828346,
        0.05270259827375412,
        0.037587981671094894,
        0.05181512609124184,
        0.019063733518123627,
        -0.05240960046648979,
        0.00945731159299612,
        0.052407100796699524,
        -0.01565350778400898,
        0.026122253388166428,
        -0.04888421297073364,
        0.013037994503974915,
        -0.04922456666827202,
        -0.010923279449343681,
        -0.05245755612850189,
        0.03733844682574272,
        0.04327152669429779,
        0.05270437151193619,
        0.05274030566215515,
        0.047386836260557175,
        0.05128799006342888,
        0.010353358462452888,
        0.05258242040872574,
        -0.04145440831780434,
        0.05267985537648201,
        0.04882656782865524,
        -0.05276310816407204,
        -0.02721150405704975,
        -0.04324575141072273,
        -0.00027445328305475414,
        -0.02898642048239708,
        -0.04447578638792038,
        -0.03973821550607681,
        -0.05263510346412659,
        -0.049228206276893616,
        0.05276309698820114,
        -0.04371042177081108,
        0.05275272950530052,
        0.05098792910575867,
        0.05272681638598442,
        -0.04777907580137253,
        0.049136932939291,
        0.043858326971530914,
        -0.05265102535486221,
        0.022872909903526306,
        -0.046602293848991394,
        0.05190063267946243,
        -0.04764126241207123,
        0.052639782428741455,
        0.042752716690301895,
        0.052518848329782486,
        0.0503154918551445,
        -0.051819778978824615,
        -0.02772505395114422,
        -0.05160331726074219,
        0.0527627170085907,
        -0.05267670005559921,
        0.05272512882947922,
        0.04566718265414238,
        -0.05206551030278206,
        -0.006401636637747288,
        -0.05276227369904518,
        -0.024842845275998116,
        -0.02055973745882511,
        0.04882516339421272,
        0.025702865794301033,
        0.05275474488735199,
        0.05276299640536308,
        0.04479924216866493,
        -0.028192749246954918,
        0.05262014642357826,
        -0.052110455930233,
        0.03234264999628067,
        -0.051259636878967285,
        0.04971996694803238,
        -0.05269823223352432,
        -0.02855721488595009,
        -0.0307780634611845,
        -0.044650375843048096,
        0.05271686986088753,
        0.052762050181627274,
        -0.048576634377241135,
        0.05247841775417328,
        0.004820878151804209,
        -0.014397398568689823,
        -0.009271523915231228,
        0.052016325294971466,
        0.0017820955254137516,
        -0.007568833418190479,
        -0.05272116884589195,
        0.05275614187121391,
        -0.027405904605984688,
        0.024617604911327362,
        0.05173959955573082,
        -0.04471344128251076,
        0.05142712593078613,
        -0.05251471698284149,
        -0.05156458541750908,
        -0.04638658091425896,
        -0.012861697934567928,
        0.05276304483413696,
        0.04634720832109451,
        -0.05082406848669052,
        -0.0036744901444762945,
        0.05270012095570564,
        0.03479304164648056,
        -0.04842352867126465,
        0.051988404244184494,
        0.05266218259930611,
        0.021229157224297523,
        -0.04589492082595825,
        -0.030724339187145233,
        -0.02667091228067875,
        0.052758362144231796,
        0.03623967617750168,
        0.05273498222231865,
        0.05276305601000786,
        0.038936860859394073,
        0.05276026949286461,
        0.045783303678035736,
        0.018895190209150314,
        0.0018302162643522024,
        0.050882842391729355,
        -0.0518966019153595,
        -0.05275966599583626,
        0.03333373740315437,
        0.05193255469202995,
        -0.03954356908798218,
        -0.007491943892091513,
        0.03301674500107765,
        0.05162116512656212,
        -0.04936816170811653,
        0.047647468745708466,
        0.024631943553686142,
        0.052753958851099014,
        -0.007306235376745462,
        -0.052715662866830826,
        -0.052761100232601166,
        0.047922782599925995,
        -0.05276310816407204,
        -0.052070919424295425,
        -0.035465531051158905,
        0.02389877662062645,
        0.04394369199872017,
        0.052738383412361145,
        0.029729116708040237,
        -0.05121651664376259,
        -0.04682072997093201,
        -0.05261674150824547,
        0.052707698196172714,
        -0.05151667818427086,
        0.05274142324924469,
        -0.05225639045238495,
        -0.04782833531498909,
        -0.04851037636399269,
        0.04926950857043266,
        0.014176744036376476,
        0.03614994138479233,
        0.052522268146276474,
        -0.0009897240670397878,
        0.0371568463742733,
        0.05175165459513664,
        -0.024908365681767464,
        -0.014526821672916412,
        -0.036032114177942276,
        -0.05089955031871796,
        -0.037512924522161484,
        -0.04876202717423439,
        -0.05271309241652489,
        -0.04183747619390488,
        -0.031588710844516754,
        -0.05200142413377762,
        -0.05259547755122185,
        -0.052706655114889145,
        0.0010375796118751168,
        -0.047999847680330276,
        -0.05274628475308418,
        0.04322793334722519,
        0.05192772299051285,
        0.01097183395177126,
        -0.04190981760621071,
        -0.052761632949113846,
        -0.050054069608449936,
        -0.05085969343781471,
        0.05255617946386337,
        -0.05274414271116257,
        -0.04502810165286064,
        0.05220773071050644,
        0.05208631232380867,
        0.00841027032583952,
        -0.052625853568315506,
        0.051816463470458984,
        0.010966874659061432,
        0.005830890499055386,
        -0.052581798285245895,
        -0.051422107964754105,
        0.04589146003127098,
        0.05217339098453522,
        -0.018447309732437134,
        0.04946129396557808,
        0.03904787078499794,
        0.047946106642484665,
        0.04910902678966522,
        0.0527307502925396,
        0.052762966603040695,
        0.03501017391681671,
        -0.0527607761323452,
        0.04716096445918083,
        -0.032071489840745926,
        0.029211683198809624,
        -0.050611332058906555,
        -0.0402873232960701,
        -0.052642203867435455,
        0.00662677176296711,
        -0.0498848520219326,
        -0.05271843075752258,
        -0.0075670755468308926,
        -0.04587434604763985,
        -0.0015288055874407291,
        -0.027164701372385025,
        0.052654970437288284,
        -0.044689733535051346,
        -0.052549246698617935,
        -0.044718462973833084,
        0.012938719242811203,
        0.008120899088680744,
        0.05244443565607071,
        -0.05252381041646004,
        -0.05267960950732231,
        0.010415743105113506,
        -0.047330696135759354,
        0.03070531226694584,
        0.05275837704539299,
        -0.0525466725230217,
        0.05272265151143074,
        0.043362703174352646,
        -0.04559096693992615,
        0.05004112794995308,
        -0.05112069845199585,
        -0.0507676936686039,
        -0.04171324148774147,
        0.04982612282037735,
        0.034137167036533356,
        0.05261353775858879,
        0.044870469719171524,
        -0.047184839844703674,
        -0.044607922434806824,
        0.05256935954093933,
        0.05243031308054924,
        -0.039596181362867355,
        0.052753996104002,
        -0.01510710921138525,
        -0.04283684492111206,
        0.04376547783613205,
        -0.0357046015560627,
        0.046896710991859436,
        -0.0456794872879982,
        -0.04380933195352554,
        0.03607157617807388,
        -0.05087253823876381,
        0.046229466795921326,
        -0.05272022262215614,
        0.0026396114844828844,
        0.0347999669611454,
        0.05158613622188568,
        0.04352474957704544,
        0.042973197996616364,
        -0.05258138105273247,
        0.046818945556879044,
        0.03128056228160858,
        0.04901934415102005,
        -0.05066167190670967,
        -0.05199481546878815,
        -0.026847245171666145,
        -0.05276309698820114,
        -0.028080908581614494,
        0.017733410000801086,
        -0.052685387432575226,
        0.026540935039520264,
        0.012920086272060871,
        -0.04978511109948158,
        0.044636186212301254,
        -0.00796449650079012,
        -0.05268891155719757,
        0.051276616752147675,
        -0.039961639791727066,
        -0.05275177210569382,
        -0.012611075304448605,
        0.052615679800510406,
        0.05276275426149368,
        -0.0273425430059433,
        0.05217640846967697,
        -0.052759308367967606,
        0.05266200006008148,
        -0.020864160731434822
    ],
    [
        0.043500181287527084,
        -0.014344356022775173,
        0.04393988475203514,
        -0.007905078120529652,
        -0.022469354793429375,
        0.009000045247375965,
        0.050904881209135056,
        -0.05325007066130638,
        0.04392298310995102,
        0.013338607735931873,
        0.053513333201408386,
        0.05156061053276062,
        -0.049128469079732895,
        -0.047592613846063614,
        -0.05327821895480156,
        0.04597420617938042,
        -0.0388224758207798,
        0.05342817306518555,
        0.040699031203985214,
        -0.05356793478131294,
        0.013296812772750854,
        -0.05269620195031166,
        -0.026674136519432068,
        -0.05358031764626503,
        0.05122510716319084,
        0.014821959659457207,
        0.051364000886678696,
        0.015379434451460838,
        0.019370654597878456,
        0.05308913812041283,
        0.049264367669820786,
        -0.05354171246290207,
        0.046833835542201996,
        -0.05349324271082878,
        0.05175269395112991,
        -0.03352159261703491,
        -0.0518965944647789,
        -0.05183786898851395,
        0.053462278097867966,
        0.03577522188425064,
        -0.045829351991415024,
        0.04885442554950714,
        0.051048893481492996,
        -0.020339420065283775,
        0.026693938300013542,
        0.03239487484097481,
        0.05295596644282341,
        0.05355524644255638,
        0.0534975528717041,
        -0.04717279225587845,
        0.05349864810705185,
        0.05338158831000328,
        -0.04454497992992401,
        0.042236730456352234,
        -0.05356498062610626,
        0.05296287313103676,
        -0.053036052733659744,
        -0.031476717442274094,
        -0.0140712084248662,
        -0.05035612732172012,
        -0.05348489060997963,
        -0.012748095206916332,
        -0.05154043436050415,
        0.017600063234567642,
        -0.048280999064445496,
        -0.05328242853283882,
        -0.03963769972324371,
        0.0363946333527565,
        -0.0034351511858403683,
        -0.05358419939875603,
        -0.051309552043676376,
        -0.05356428772211075,
        0.053230028599500656,
        0.022789638489484787,
        -0.05348217487335205,
        -0.03527972847223282,
        0.05034760758280754,
        0.053584713488817215,
        0.018198462203145027,
        0.04906920716166496,
        -0.007074244786053896,
        -0.016199439764022827,
        -0.05353149026632309,
        0.05315043404698372,
        -0.05144722759723663,
        0.04328411445021629,
        0.051768433302640915,
        -0.044078197330236435,
        0.02938871644437313,
        0.04402275010943413,
        -0.04968436434864998,
        -0.05358405038714409,
        -0.008633305318653584,
        -0.008109383285045624,
        -0.012058627791702747,
        -0.03776746615767479,
        -0.021164018660783768,
        -0.05312709882855415,
        -0.011009649373590946,
        0.051371507346630096,
        -0.052658624947071075,
        0.05322660133242607,
        0.04811175540089607,
        -0.04280678182840347,
        -0.053167298436164856,
        -0.05159981921315193,
        -0.053469449281692505,
        -0.04858144372701645,
        0.028381425887346268,
        -0.04321020469069481,
        0.05356719717383385,
        -0.05356242135167122,
        -0.03660449758172035,
        0.02785416692495346,
        0.053582821041345596,
        -0.03440513834357262,
        0.05154631286859512,
        -0.05232162028551102,
        0.02951446920633316,
        -0.03114391304552555,
        -0.023673176765441895,
        0.05315703898668289,
        0.03481553867459297,
        -0.05055469274520874,
        -0.038329821079969406,
        0.03821653872728348,
        -0.038974128663539886,
        -0.022103624418377876,
        0.05338241159915924,
        0.04659246280789375,
        0.05358407273888588,
        -0.05242408812046051,
        -0.05358237028121948,
        0.052471406757831573,
        -0.05072173848748207,
        -0.05245841667056084,
        0.053506407886743546,
        -0.05111653730273247,
        0.05077362433075905,
        0.053548507392406464,
        0.01380709744989872,
        -0.053321123123168945,
        0.04581489413976669,
        0.05139594525098801,
        -0.037045639008283615,
        -0.017709869891405106,
        -0.051118865609169006,
        -0.053251318633556366,
        -0.04149995371699333,
        -0.05256704241037369,
        -0.0530390739440918,
        -0.05345071479678154,
        -0.051323581486940384,
        -0.05355287343263626,
        0.03751108795404434,
        0.050862498581409454,
        0.030270962044596672,
        -0.0451321117579937,
        0.050825800746679306,
        0.05353090167045593,
        -0.0477704294025898,
        0.014782496728003025,
        0.050601761788129807,
        0.05195049196481705,
        0.03515603765845299,
        -0.030543485656380653,
        0.04515916481614113,
        -0.009009354747831821,
        -0.052434634417295456,
        0.0035106386058032513,
        -0.052925217896699905,
        0.014836699701845646,
        0.035923246294260025,
        0.0022561801597476006,
        -0.04785299673676491,
        -0.026922892779111862,
        0.053330034017562866,
        0.04854584112763405,
        0.0532752089202404,
        -0.053305722773075104,
        -0.0015241833170875907,
        0.0495661199092865,
        -0.05168789625167847,
        -0.04188142344355583,
        -0.039226777851581573,
        0.03737785294651985,
        0.011190300807356834,
        0.05339458957314491,
        -0.050895292311906815,
        0.053264401853084564,
        0.02033829689025879,
        0.030892154201865196,
        -0.050568461418151855,
        -0.05358339473605156,
        -0.05356664955615997,
        -0.0248397309333086,
        0.05220288783311844,
        -0.044741034507751465,
        0.0501471683382988,
        0.051826342940330505,
        0.053509488701820374,
        -0.013104462064802647,
        0.04162604734301567,
        -0.05358196422457695,
        -0.03915110602974892,
        -0.053581878542900085,
        0.042715467512607574,
        0.05231226980686188,
        0.04590079188346863,
        0.05355973169207573,
        0.004919770639389753,
        0.0526559092104435,
        -0.04427823796868324,
        0.05353578180074692,
        0.05351816862821579,
        -0.05358079448342323,
        -0.035661403089761734,
        -0.0422050766646862,
        -0.001449610572308302,
        -0.05299137160181999,
        0.023921461775898933,
        -0.01868426986038685,
        -0.03689990192651749,
        -0.03361841291189194,
        0.052877992391586304,
        -0.05288084223866463,
        -0.047156307846307755,
        -0.05040958523750305,
        0.05203046277165413,
        -0.048239756375551224,
        -0.024261469021439552,
        -0.02738109789788723,
        -0.052912428975105286,
        0.050393108278512955,
        0.006212672684341669,
        -0.05343470722436905,
        0.051764149218797684,
        -0.007732312195003033,
        -0.04468170925974846,
        0.053414855152368546,
        -0.016523007303476334,
        0.023655779659748077,
        -0.05198991671204567,
        -0.05358535423874855,
        0.04139377549290657,
        -0.010124174878001213,
        0.0393047071993351,
        -0.04355822131037712,
        0.05029284209012985,
        -0.05354208126664162,
        -0.0532863587141037,
        -0.0534665584564209,
        0.048908449709415436,
        -0.05214288458228111,
        -0.020838718861341476,
        0.048882655799388885,
        0.05358537659049034,
        -0.030425606295466423,
        0.04682241007685661,
        0.030753623694181442,
        -0.04773300886154175,
        -0.028052689507603645,
        -0.046275846660137177,
        0.021971464157104492,
        -0.04396747052669525,
        0.053362887352705,
        0.0534767210483551,
        0.05092349648475647,
        -0.019486071541905403,
        -0.05264684557914734,
        0.0027291446458548307,
        -0.052681781351566315,
        0.05357658490538597,
        -0.05296037718653679,
        0.0534929484128952,
        0.04671848937869072,
        -0.04670320823788643,
        0.003658437170088291,
        -0.0020861865486949682,
        -0.03461106866598129,
        -0.0534709133207798,
        -0.04357375577092171,
        -0.03906891122460365,
        0.05294928699731827,
        0.03610488772392273,
        0.05356433987617493,
        0.047377027571201324,
        0.033156618475914,
        -0.0003495663986541331,
        -0.00864073634147644,
        -0.053438760340213776,
        0.04730987548828125,
        -0.053562719374895096,
        -0.049286745488643646,
        0.05351974815130234,
        -0.05248214304447174,
        0.05312451720237732,
        0.04876813292503357,
        -0.05356723070144653,
        0.05295581370592117,
        0.0028300583362579346,
        -0.02721622958779335,
        -0.0336332805454731,
        0.05120536684989929,
        -0.05106249451637268,
        0.035625528544187546,
        -0.001747504691593349,
        0.05356885865330696,
        0.025993462651968002,
        0.049543917179107666,
        0.052967146039009094,
        -0.016202379018068314,
        0.05241178348660469,
        0.023333625867962837,
        0.051625806838274,
        -0.051218438893556595,
        -0.019264889881014824,
        0.05358521267771721,
        0.05189594626426697,
        -0.038346853107213974,
        0.05350349843502045,
        -0.05334353446960449,
        0.0526493676006794,
        0.05250426381826401,
        0.0533771812915802,
        0.05358327180147171,
        0.031675830483436584,
        -0.05346231907606125,
        -0.03501851484179497,
        0.02919704280793667,
        -0.04303940758109093,
        0.02019129879772663,
        0.05356971174478531,
        0.05358395725488663,
        0.052747130393981934,
        0.0532878153026104,
        0.005372199229896069,
        0.018329624086618423,
        0.04543381929397583,
        0.049815401434898376,
        -0.044594958424568176,
        -0.05001967027783394,
        -0.048218511044979095,
        -0.023067837581038475,
        0.00013521713844966143,
        0.04544534906744957,
        0.03898858278989792,
        0.05327139422297478,
        -0.050131794065237045,
        0.05158136039972305,
        -0.05323893576860428,
        0.05154171586036682,
        -0.047194987535476685,
        -0.05094170942902565,
        -0.052198804914951324,
        0.04819153994321823,
        -0.05358536168932915,
        -0.05284755676984787,
        -0.01875925622880459,
        -0.05311557278037071,
        0.02298172377049923,
        0.03695649653673172,
        -0.053011488169431686,
        -0.03753266483545303,
        -0.05256469175219536,
        -0.050262462347745895,
        0.05219230055809021,
        -0.05345477908849716,
        0.05347930267453194,
        -0.05357406660914421,
        -0.01683659479022026,
        -0.044029973447322845,
        0.05155997350811958,
        -0.025015486404299736,
        0.0531521812081337,
        0.026501938700675964,
        0.04914947971701622,
        -0.046598825603723526,
        0.050323016941547394,
        -0.029415061697363853,
        0.0389985591173172,
        -0.016716688871383667,
        -0.053522173315286636,
        -0.05077044293284416,
        -0.040416911244392395,
        -0.050902631133794785,
        -0.05191248655319214,
        0.04861053451895714,
        -0.05310347303748131,
        -0.053568705916404724,
        -0.05256124213337898,
        0.0530555285513401,
        -0.02552730403840542,
        0.04981709271669388,
        0.04696764796972275,
        0.05206720158457756,
        -0.05343874171376228,
        -0.03684575855731964,
        -0.05122615024447441,
        -0.051633935421705246,
        -0.04806569218635559,
        0.05334314703941345,
        -0.05162221938371658,
        -0.05045364424586296,
        0.05352555587887764,
        0.05219869688153267,
        0.017258888110518456,
        0.021507583558559418,
        0.053439345210790634,
        -0.05281057208776474,
        0.01439910288900137,
        -0.032993070781230927,
        -0.0027506640180945396,
        -0.0280854944139719,
        0.053390227258205414,
        -0.03304296359419823,
        0.053584735840559006,
        0.010102988220751286,
        -0.052756667137145996,
        0.05350260064005852,
        0.05350600928068161,
        -0.05322296917438507,
        -0.053577106446027756,
        -0.05353779345750809,
        -0.05113231763243675,
        0.049902644008398056,
        0.05263197794556618,
        -0.04957940801978111,
        -0.009930073283612728,
        -0.05160897597670555,
        0.05059591308236122,
        -0.038428910076618195,
        -0.053559690713882446,
        -0.04691404476761818,
        -0.010831516236066818,
        -0.05328250676393509,
        -0.05334705114364624,
        -0.00737047428265214,
        0.04800018295645714,
        -0.04918920248746872,
        -0.05102584883570671,
        0.008057912811636925,
        -0.0033662139903753996,
        0.04977474361658096,
        -0.05127110332250595,
        -0.049458716064691544,
        0.01824580691754818,
        -0.015691272914409637,
        0.01087149791419506,
        0.05358279496431351,
        -0.03583377227187157,
        0.006546114105731249,
        0.020705867558717728,
        0.006428406573832035,
        0.05354303866624832,
        -0.05263466760516167,
        0.0027604391798377037,
        -0.052700724452733994,
        -0.020136579871177673,
        0.051719825714826584,
        -0.044672418385744095,
        -0.0535009428858757,
        0.053550880402326584,
        -0.0195296797901392,
        0.04986146464943886,
        0.053322453051805496,
        0.0017705587670207024,
        0.053430475294589996,
        0.048772141337394714,
        -0.03904981538653374,
        0.043528299778699875,
        0.05273399502038956,
        0.045546337962150574,
        0.05188729986548424,
        -0.052951909601688385,
        0.05149048566818237,
        -0.053422581404447556,
        0.05351846292614937,
        -0.042349450290203094,
        -0.05241506174206734,
        -0.028745723888278008,
        -0.03253616392612457,
        -0.026417776942253113,
        0.04623279720544815,
        0.05330853909254074,
        0.051161978393793106,
        -0.02059430442750454,
        0.03743724524974823,
        -0.05334797129034996,
        -0.01696089282631874,
        0.05338094383478165,
        -0.05354589968919754,
        0.05228443816304207,
        -0.020533116534352303,
        0.014823154546320438,
        -0.047778598964214325,
        -0.05354156345129013,
        0.022262368351221085,
        0.053267952054739,
        0.05122220143675804,
        -0.039267394691705704,
        -0.04900304600596428,
        0.037893157452344894,
        -0.05353841930627823,
        0.05348283052444458,
        -0.03881004452705383,
        0.053584687411785126,
        -0.031124241650104523,
        0.053472112864255905,
        -0.04965471476316452,
        0.05056377872824669,
        0.04648055136203766
    ],
    [
        -0.05782372131943703,
        0.043422337621450424,
        -0.024633053690195084,
        -0.025787033140659332,
        0.025863103568553925,
        -0.032323967665433884,
        0.062466006726026535,
        -0.049813397228717804,
        0.02461540699005127,
        0.060042399913072586,
        0.04475932568311691,
        0.03726945444941521,
        -0.05926058068871498,
        0.05772482603788376,
        -0.06122467666864395,
        -0.05147995427250862,
        -0.05087018013000488,
        0.025031384080648422,
        -0.06221605837345123,
        -0.03442251682281494,
        -0.04533812403678894,
        0.026871919631958008,
        0.06002633273601532,
        0.04555187746882439,
        0.013404148630797863,
        0.022125815972685814,
        0.0222247913479805,
        0.04449263960123062,
        -0.05397927761077881,
        -0.04185936972498894,
        0.05664493516087532,
        -0.034928567707538605,
        -0.06049705669283867,
        -0.05973834544420242,
        0.03276990354061127,
        -0.04987981170415878,
        -0.060800377279520035,
        -0.03141260892152786,
        -0.047745972871780396,
        0.049139440059661865,
        -0.05404234677553177,
        0.024857355281710625,
        0.060272324830293655,
        -0.05284952372312546,
        0.06109656020998955,
        -0.036820124834775925,
        0.018618827685713768,
        0.061040833592414856,
        0.0499490350484848,
        0.01685589924454689,
        0.0305187851190567,
        0.026292815804481506,
        0.059531070291996,
        0.006182575132697821,
        -0.009781289845705032,
        -0.052299559116363525,
        0.021807478740811348,
        -0.014485221356153488,
        -0.04524337872862816,
        0.05399047210812569,
        -0.04732636734843254,
        -0.03234536945819855,
        -0.0573606863617897,
        0.029407229274511337,
        -0.026846371591091156,
        0.009600542485713959,
        0.02102905511856079,
        -0.058241356164216995,
        0.024100137874484062,
        -0.06434375792741776,
        -0.06045176088809967,
        -0.06065119057893753,
        0.04585133492946625,
        0.024658795446157455,
        -0.061422526836395264,
        -0.04529457911849022,
        0.041821010410785675,
        0.0640496090054512,
        0.02224455028772354,
        -0.006877886597067118,
        -0.026982763782143593,
        0.050750598311424255,
        -0.05889325588941574,
        0.06006644666194916,
        -0.004529996775090694,
        0.0373508520424366,
        -0.028586236760020256,
        0.0062333946116268635,
        0.003813718445599079,
        0.06063612177968025,
        -0.060939639806747437,
        -0.00965173915028572,
        0.02556595578789711,
        -0.002662649843841791,
        -0.04285092651844025,
        -0.02273666299879551,
        -0.022228889167308807,
        -0.053572408854961395,
        -0.020176168531179428,
        0.015703536570072174,
        -0.06427481770515442,
        0.01140284352004528,
        0.06327185034751892,
        0.05219006538391113,
        0.007617071270942688,
        -0.011959601193666458,
        -0.0144997863098979,
        0.03109412081539631,
        -0.008753111585974693,
        -0.024737201631069183,
        -0.06242617964744568,
        -0.031976375728845596,
        -0.025448927655816078,
        -0.04539475217461586,
        0.023852946236729622,
        -0.007683757692575455,
        -0.024581756442785263,
        -0.0614260695874691,
        -0.06173982471227646,
        -0.014242133125662804,
        -0.0252132136374712,
        0.010875055566430092,
        -0.04880956932902336,
        0.016299454495310783,
        -0.06204294413328171,
        -0.012099228799343109,
        0.012428069487214088,
        0.05719558149576187,
        0.06215515732765198,
        0.017145400866866112,
        0.04602660983800888,
        -0.023337071761488914,
        -0.04962265491485596,
        -0.0022457982413470745,
        -0.052118513733148575,
        -0.06092564016580582,
        0.0367656946182251,
        -0.06365025788545609,
        -0.05973520129919052,
        0.05512263998389244,
        0.0579831525683403,
        -0.04491450637578964,
        0.04078559949994087,
        0.03123675473034382,
        0.05451696366071701,
        0.040079325437545776,
        0.05043701454997063,
        -0.057188887149095535,
        -0.001233149436302483,
        0.05196504294872284,
        -0.03794298693537712,
        -0.06160763278603554,
        -0.03120592050254345,
        0.021545788273215294,
        -0.04874969273805618,
        -0.05111230909824371,
        0.04858643561601639,
        -0.051329199224710464,
        -0.03865223377943039,
        0.05606486648321152,
        0.04825908690690994,
        0.050435569137334824,
        0.03995990753173828,
        -0.021128669381141663,
        -0.05147736519575119,
        0.035856615751981735,
        -0.02080419473350048,
        -0.062499258667230606,
        0.04272250831127167,
        0.029827740043401718,
        -0.04415839537978172,
        -0.032784756273031235,
        -0.05641131475567818,
        0.04218647629022598,
        -0.00462361890822649,
        0.05109521001577377,
        -0.058380376547575,
        0.050442736595869064,
        0.059491053223609924,
        -0.04628906026482582,
        -0.04174438491463661,
        0.0226425863802433,
        0.04865126684308052,
        0.03907715156674385,
        0.05286681652069092,
        0.020935123786330223,
        -0.05629153922200203,
        0.056310296058654785,
        -0.05336086079478264,
        -0.032481126487255096,
        -0.025975950062274933,
        -0.041789278388023376,
        -0.046383798122406006,
        0.03995027020573616,
        -0.05071553215384483,
        -0.003429359756410122,
        0.04064431041479111,
        -0.031351637095212936,
        -0.02301676571369171,
        0.017383746802806854,
        -0.010590635240077972,
        0.06321466714143753,
        -0.023183919489383698,
        0.005099125672131777,
        -0.044978171586990356,
        0.03307899460196495,
        0.05489926040172577,
        -0.020297247916460037,
        -0.03889293596148491,
        -0.008486698381602764,
        -0.05842316150665283,
        0.03652585670351982,
        -0.05403028056025505,
        0.045057304203510284,
        0.048394955694675446,
        -0.04148436710238457,
        0.061722688376903534,
        0.05848444625735283,
        0.0633842796087265,
        -0.059508807957172394,
        0.047213293612003326,
        0.0007860417827032506,
        -0.04678918793797493,
        -0.06420436501502991,
        0.04323058947920799,
        -0.045825522392988205,
        0.009744369424879551,
        0.04315657168626785,
        0.010684317909181118,
        -0.03215412423014641,
        0.014193220995366573,
        -0.058337416499853134,
        0.019174158573150635,
        0.050343092530965805,
        0.0470115952193737,
        -0.008143034763634205,
        0.06204532831907272,
        0.027660982683300972,
        -0.004050203133374453,
        0.06396695971488953,
        0.050858329981565475,
        0.0070779151283204556,
        0.027462972328066826,
        0.04182644933462143,
        0.005580672062933445,
        0.0035893379244953394,
        0.03401614725589752,
        -0.04840023070573807,
        -0.04784311726689339,
        0.019191820174455643,
        -0.04764701426029205,
        -0.06349064409732819,
        -0.024598093703389168,
        -0.06031167507171631,
        -0.045901428908109665,
        0.06329464912414551,
        0.04898002743721008,
        -0.018292831256985664,
        0.06071070581674576,
        0.0567341148853302,
        0.053759291768074036,
        0.05711685121059418,
        -0.005526514258235693,
        -0.02715683914721012,
        -0.06347303837537766,
        0.039268609136343,
        0.047069501131772995,
        0.025158710777759552,
        0.04435420781373978,
        -0.0473783016204834,
        -0.013368217274546623,
        -0.061492666602134705,
        -0.013723606243729591,
        -0.0624600350856781,
        0.007177046965807676,
        0.05899069085717201,
        -0.062349144369363785,
        0.050286322832107544,
        -0.037238460034132004,
        -0.06161440163850784,
        -0.05386246368288994,
        -0.010498766787350178,
        0.022369787096977234,
        0.044463422149419785,
        0.027801360934972763,
        -0.06263259798288345,
        0.04025381803512573,
        -0.041165053844451904,
        -0.0628434419631958,
        0.03671807423233986,
        -0.058782387524843216,
        0.036390215158462524,
        0.009043658152222633,
        -0.014818877913057804,
        0.028374772518873215,
        -0.046714067459106445,
        0.06374045461416245,
        0.051981959491968155,
        -0.05560479685664177,
        0.04933857172727585,
        -0.02804991789162159,
        0.0529707670211792,
        -0.019696060568094254,
        0.03592979907989502,
        0.024503743276000023,
        -0.06088897958397865,
        -0.058545492589473724,
        0.032603226602077484,
        0.05237886682152748,
        -0.05353141948580742,
        0.056869469583034515,
        -0.05953709036111832,
        -0.025105077773332596,
        0.01461036130785942,
        -0.06348496675491333,
        -0.04896441102027893,
        0.0251714289188385,
        -0.05204998701810837,
        0.04755827412009239,
        -0.05851620435714722,
        -0.05654072389006615,
        0.03173204883933067,
        -0.013217296451330185,
        -0.05256403610110283,
        0.04688126593828201,
        0.046393752098083496,
        0.058243703097105026,
        0.014293842017650604,
        0.0399872250854969,
        -0.015667591243982315,
        0.037530649453401566,
        0.032868605107069016,
        -0.01216055266559124,
        -0.06161528825759888,
        0.059935763478279114,
        -0.052846163511276245,
        0.015801161527633667,
        0.05850081518292427,
        -0.02535666897892952,
        0.06151217222213745,
        0.007735168095678091,
        -0.049320515245199203,
        0.04904019460082054,
        -0.0612216480076313,
        0.06434255093336105,
        0.04922349005937576,
        0.018223794177174568,
        0.05089595168828964,
        -0.014779766090214252,
        0.06189441680908203,
        -0.06392607092857361,
        0.05048292875289917,
        -0.014835314825177193,
        -0.05979703366756439,
        -0.05208093672990799,
        0.06141152232885361,
        -0.04978541284799576,
        -0.03449191525578499,
        -0.0524928905069828,
        -0.02134184166789055,
        0.053422801196575165,
        0.047015659511089325,
        -0.042686108499765396,
        -0.046956539154052734,
        -0.016347816213965416,
        -0.05148882418870926,
        0.06365740299224854,
        0.017622634768486023,
        0.012532003223896027,
        -0.06382041424512863,
        -0.04831625148653984,
        -0.06214772164821625,
        0.053603366017341614,
        0.005100797861814499,
        0.04638034477829933,
        0.06149249151349068,
        -0.029825860634446144,
        0.03624953329563141,
        -0.05313803628087044,
        -0.06315258145332336,
        0.0274630319327116,
        0.04952112212777138,
        -0.060659077018499374,
        -0.005125895608216524,
        0.05704952031373978,
        -0.04185967147350311,
        0.0404881052672863,
        -0.06071700155735016,
        -0.04154188558459282,
        -0.05361892282962799,
        -0.05907033011317253,
        0.031984925270080566,
        0.009435332380235195,
        0.052881188690662384,
        -0.060733821243047714,
        0.05702076479792595,
        0.061330731958150864,
        -0.06093175709247589,
        -0.02312910370528698,
        -0.05744337663054466,
        -0.02552703209221363,
        0.05605581775307655,
        0.062065038830041885,
        0.03749945014715195,
        -0.05789870768785477,
        0.03252803161740303,
        0.06180930137634277,
        -0.0333055704832077,
        0.05272650346159935,
        -0.05994574353098869,
        0.06007148325443268,
        0.04083556681871414,
        -0.04519883543252945,
        0.028616972267627716,
        0.018320711329579353,
        0.0026481114327907562,
        -0.036635082215070724,
        0.019395411014556885,
        0.03934446722269058,
        0.03296508267521858,
        -0.0634438544511795,
        -0.05237419903278351,
        -0.05871462821960449,
        -0.06345884501934052,
        -0.020281028002500534,
        -0.009845093823969364,
        0.011654009111225605,
        -0.04595459997653961,
        -0.05983187258243561,
        -0.06279598921537399,
        0.058546729385852814,
        -0.029149822890758514,
        0.05323465168476105,
        0.004405548796057701,
        0.04506683349609375,
        0.011074651964008808,
        -0.03124113194644451,
        0.05894102528691292,
        0.0047610425390303135,
        -0.0594208724796772,
        0.0016652223421260715,
        0.01151431817561388,
        0.03368128091096878,
        0.05506651848554611,
        -0.02800174057483673,
        0.03441988304257393,
        0.05708077549934387,
        -0.05562780424952507,
        0.023633338510990143,
        -0.059014804661273956,
        0.028782811015844345,
        0.016917182132601738,
        0.03750608116388321,
        -0.03925732523202896,
        -0.03425278514623642,
        -0.04971041902899742,
        -0.03138134256005287,
        -0.029904555529356003,
        -0.03331982344388962,
        -0.019791629165410995,
        0.021183371543884277,
        0.04402952641248703,
        -0.03153780475258827,
        -0.03772667050361633,
        0.062494609504938126,
        0.06230437383055687,
        -0.06345924735069275,
        0.059918444603681564,
        -0.04658828675746918,
        -0.060488250106573105,
        0.011944952420890331,
        0.04822300001978874,
        0.004229054786264896,
        0.05416901409626007,
        0.05445214360952377,
        0.031499411910772324,
        0.04961954057216644,
        -0.06057384982705116,
        0.008690645918250084,
        -0.04090503603219986,
        0.04505437612533569,
        0.014778598211705685,
        0.044784702360630035,
        0.03576061502099037,
        0.030129864811897278,
        0.05747216194868088,
        0.057740841060876846,
        0.015740517526865005,
        -0.059408362954854965,
        -0.06264348328113556,
        -0.036567751318216324,
        -0.034365348517894745,
        -0.04098216071724892,
        0.061719659715890884,
        0.06092286482453346,
        0.04921800643205643,
        -0.04986797645688057,
        -0.018093986436724663,
        0.056576523929834366,
        0.055013541132211685,
        -0.0468083918094635,
        0.0074662864208221436,
        -0.027241185307502747,
        -0.0077476538717746735,
        0.051978372037410736,
        0.028880810365080833,
        0.032770708203315735,
        0.053837087005376816,
        -0.0484607107937336,
        0.056465163826942444,
        0.06307778507471085,
        0.06093091517686844
    ],
    [
        0.03662664443254471,
        0.017193855717778206,
        -0.03920380026102066,
        0.0009386455058120191,
        -0.028369572013616562,
        0.02583138830959797,
        0.030716802924871445,
        -0.057101987302303314,
        0.05603795498609543,
        -0.057333268225193024,
        0.05725473538041115,
        0.051271483302116394,
        -0.054175425320863724,
        -0.05490584298968315,
        -0.03786097839474678,
        0.008172553963959217,
        0.02997196651995182,
        0.05593514069914818,
        0.007543968036770821,
        -0.05188347026705742,
        0.04853121563792229,
        0.05720826983451843,
        0.04907653108239174,
        -0.005954211112111807,
        -0.05459294468164444,
        0.05726506933569908,
        0.05354444310069084,
        0.05706270784139633,
        -0.04396883025765419,
        -0.022237805649638176,
        0.05196093022823334,
        -0.004512113053351641,
        -0.057336628437042236,
        -0.057346004992723465,
        0.04648824408650398,
        -0.05692566558718681,
        -0.05573182553052902,
        0.03207843750715256,
        0.05604066699743271,
        0.033028148114681244,
        0.055932771414518356,
        0.05004746466875076,
        0.053874626755714417,
        0.017444148659706116,
        -0.002221073489636183,
        -0.04464162513613701,
        -0.0534478984773159,
        0.05735227093100548,
        0.0571250319480896,
        -0.0035170784685760736,
        0.05680256336927414,
        -0.04873153567314148,
        -0.03750862926244736,
        -0.04925910010933876,
        0.0007860022014938295,
        -0.05467640981078148,
        -0.026387769728899002,
        0.049463897943496704,
        -0.04415128380060196,
        -0.04512679576873779,
        -0.05612563714385033,
        0.01584777422249317,
        -0.05363054946064949,
        0.05546342954039574,
        -0.05102317035198212,
        -0.05264616012573242,
        -0.05138624832034111,
        -0.05716097727417946,
        -0.04116853326559067,
        0.02680271491408348,
        -0.026584196835756302,
        -0.03234396129846573,
        0.05729653313755989,
        0.04983481019735336,
        0.018132247030735016,
        0.00416633952409029,
        0.05561241880059242,
        0.057351917028427124,
        0.04848948493599892,
        0.028759699314832687,
        -0.02080005593597889,
        -0.04419217258691788,
        -0.057068731635808945,
        0.02402012050151825,
        -0.03836562857031822,
        0.05623694509267807,
        -0.0072642844170331955,
        -0.05672503262758255,
        0.002121554920449853,
        0.04720268025994301,
        -0.055125970393419266,
        -0.01552222017198801,
        -0.05696418508887291,
        -0.055544983595609665,
        -0.05569075047969818,
        0.05621107295155525,
        -0.05124714970588684,
        -0.05099594593048096,
        -0.047166015952825546,
        0.05000900477170944,
        -0.04130574315786362,
        0.05145005136728287,
        0.04708568751811981,
        0.04986998438835144,
        0.048199884593486786,
        -0.05108233168721199,
        -0.043218035250902176,
        -0.03967737779021263,
        -0.040156539529561996,
        0.028788678348064423,
        -0.05397967994213104,
        -0.0005394134786911309,
        0.00743794534355402,
        -0.010420570150017738,
        0.02412351779639721,
        0.014000467956066132,
        -0.010654016397893429,
        -0.03587368503212929,
        0.05036519467830658,
        -0.021271968260407448,
        -0.03564279526472092,
        -0.04419870674610138,
        0.050892412662506104,
        -0.056128744035959244,
        -0.05664034187793732,
        0.05078612640500069,
        -0.057074178010225296,
        0.01209434773772955,
        0.04818226024508476,
        0.05718810856342316,
        0.053543608635663986,
        -0.04937967285513878,
        -0.05393203720450401,
        0.05634121969342232,
        -0.01873372681438923,
        -0.0558028370141983,
        0.05149863660335541,
        -0.04787497594952583,
        -0.01060267724096775,
        0.039744939655065536,
        -0.04277082532644272,
        0.01428141724318266,
        0.04643798619508743,
        0.055757224559783936,
        0.023349246010184288,
        -0.056936200708150864,
        0.05543873831629753,
        -0.0005107417819090188,
        0.05654401704668999,
        0.05734744668006897,
        0.04995134100317955,
        -0.057069502770900726,
        -0.04228707030415535,
        0.014037556946277618,
        0.020156949758529663,
        -0.050591178238391876,
        -0.05363684147596359,
        -0.015932731330394745,
        -0.048500336706638336,
        -0.033486708998680115,
        0.054856255650520325,
        0.05261257290840149,
        0.05632370710372925,
        -0.01444585807621479,
        -0.01741163246333599,
        0.01663196086883545,
        0.020585952326655388,
        0.0008207359351217747,
        -0.05205210670828819,
        0.04729261249303818,
        -0.051758479326963425,
        -0.05594353750348091,
        -0.05582865700125694,
        -0.04643283039331436,
        -0.005724398884922266,
        0.03811133652925491,
        -0.046345170587301254,
        -0.00921210739761591,
        0.05712325870990753,
        -0.05629485100507736,
        -0.057136084884405136,
        0.05724429339170456,
        0.030789107084274292,
        -0.0422322116792202,
        0.04893037676811218,
        0.033715974539518356,
        0.03352181240916252,
        0.05431419610977173,
        -0.028157765045762062,
        -0.05107284337282181,
        -0.0460008941590786,
        0.04500154033303261,
        0.03911186009645462,
        0.05514144524931908,
        -0.05661487579345703,
        -0.05669902637600899,
        -0.04808502271771431,
        0.04439888149499893,
        -0.057127323001623154,
        0.04250544682145119,
        0.043899066746234894,
        0.02847774140536785,
        -0.05292829871177673,
        -0.056211668998003006,
        -0.014274447225034237,
        0.0029870199505239725,
        -0.007173170335590839,
        0.005505433306097984,
        -0.05509689077734947,
        0.05229341983795166,
        -0.045676205307245255,
        0.046822525560855865,
        0.03129080682992935,
        0.04996700957417488,
        0.021521443501114845,
        -0.02886911854147911,
        -0.004143374040722847,
        0.018466873094439507,
        0.05453655496239662,
        -0.046465903520584106,
        0.03698589280247688,
        0.04942047968506813,
        -0.04692543298006058,
        -0.04269610345363617,
        0.05697749927639961,
        -0.029296254739165306,
        -0.018174391239881516,
        0.05730869621038437,
        0.05136171728372574,
        -0.0559871606528759,
        0.0023637793492525816,
        -0.0565914623439312,
        0.030467122793197632,
        0.04339049011468887,
        -0.007438489235937595,
        0.05610259622335434,
        0.04833076894283295,
        0.004279683344066143,
        0.05299295112490654,
        0.057336725294589996,
        0.0558653250336647,
        0.05039781704545021,
        -0.004651340655982494,
        0.04928438737988472,
        0.05716884136199951,
        -0.005753477569669485,
        -0.04579681158065796,
        0.007393136154860258,
        -0.043712109327316284,
        -0.05652351304888725,
        -0.04839547723531723,
        -0.05005103722214699,
        0.05359592288732529,
        -0.056758660823106766,
        0.055721383541822433,
        0.05472277104854584,
        -0.05341123789548874,
        -0.055171508342027664,
        0.05519210174679756,
        -0.037043146789073944,
        0.024290215224027634,
        -0.056524112820625305,
        -0.027626100927591324,
        0.04481223225593567,
        -0.05730509012937546,
        0.0573192834854126,
        0.056513622403144836,
        -0.025425340980291367,
        -0.008833255618810654,
        0.055713389068841934,
        0.03234977275133133,
        -0.05458975210785866,
        0.05674031004309654,
        -0.056417107582092285,
        0.05704597756266594,
        0.056779589504003525,
        -0.05164220184087753,
        -0.007468663156032562,
        -0.057352446019649506,
        -0.023368969559669495,
        -0.05653219297528267,
        0.03391939029097557,
        -0.015627197921276093,
        0.057195793837308884,
        0.05735361948609352,
        0.0029630905482918024,
        -0.036507897078990936,
        0.05494162067770958,
        -0.044152844697237015,
        0.03200310841202736,
        -0.056712452322244644,
        -0.0012511868262663484,
        -0.05184881389141083,
        -0.05634336546063423,
        0.04580642655491829,
        -0.05644126981496811,
        0.0571167953312397,
        0.057129696011543274,
        -0.05038328468799591,
        0.054700374603271484,
        0.05695287138223648,
        0.030659938231110573,
        -0.012472610920667648,
        0.05509014055132866,
        0.05354021117091179,
        -0.034929465502500534,
        -0.05406494438648224,
        0.043983299285173416,
        -0.028249656781554222,
        -0.019367463886737823,
        0.051328666508197784,
        -0.05228717252612114,
        0.05481264367699623,
        -0.00006707936699967831,
        -0.05647871643304825,
        -0.05487823113799095,
        0.03399132192134857,
        0.020924190059304237,
        0.05382971838116646,
        -0.04638512432575226,
        0.04861915484070778,
        0.05733649060130119,
        0.006478365510702133,
        -0.055836182087659836,
        0.04058309644460678,
        0.03162022680044174,
        -0.0008287015953101218,
        -0.0517314150929451,
        0.023132989183068275,
        -0.043493546545505524,
        0.05664122477173805,
        -0.03664443641901016,
        0.00681139063090086,
        0.0420503243803978,
        0.055753424763679504,
        0.05230403319001198,
        -0.055256862193346024,
        0.051694899797439575,
        0.03798210993409157,
        -0.003915880341082811,
        -0.05490434169769287,
        -0.04993339255452156,
        0.05726928636431694,
        -0.04467594623565674,
        0.00028718242538161576,
        0.055762339383363724,
        0.05687253549695015,
        0.056529246270656586,
        0.0361517108976841,
        0.05451588332653046,
        0.05716198682785034,
        0.05617178976535797,
        0.02604619599878788,
        -0.05375483259558678,
        -0.0553400032222271,
        -0.018280241638422012,
        -0.05732955038547516,
        -0.05721819028258324,
        -0.0027682471554726362,
        -0.04955451563000679,
        0.02654869668185711,
        0.011134207248687744,
        0.051365047693252563,
        -0.03497918322682381,
        -0.003414443228393793,
        -0.05623161047697067,
        0.05722414329648018,
        0.0026234358083456755,
        0.05003245174884796,
        -0.056713178753852844,
        -0.010522576980292797,
        0.0007312222151085734,
        0.04557741805911064,
        -0.05092444270849228,
        0.05607744678854942,
        0.05622302368283272,
        -0.05683232471346855,
        0.053964391350746155,
        0.05661998316645622,
        0.01757410541176796,
        0.02800300531089306,
        0.015312899835407734,
        -0.04975148290395737,
        0.05323924124240875,
        0.03761052340269089,
        -0.05580395460128784,
        -0.048902515321969986,
        -0.046408019959926605,
        -0.03963319584727287,
        0.001851621433161199,
        -0.05332150310277939,
        0.012056487612426281,
        -0.02712646685540676,
        -0.05350914224982262,
        -0.0053236777894198895,
        0.04737178981304169,
        0.05704812705516815,
        -0.05563918873667717,
        -0.054842907935380936,
        -0.019857684150338173,
        -0.04902135208249092,
        0.05687158554792404,
        -0.05279431492090225,
        0.0509968101978302,
        -0.033282533288002014,
        -0.010083770379424095,
        -0.009607928805053234,
        -0.021881211549043655,
        0.053234294056892395,
        0.012767897918820381,
        0.005758020095527172,
        -0.05570100247859955,
        -0.029917417094111443,
        0.0002855906786862761,
        0.05732625722885132,
        -0.04912169650197029,
        -0.049775514751672745,
        -0.05214148014783859,
        0.03952890262007713,
        -0.003478599013760686,
        0.056352052837610245,
        0.05514918267726898,
        -0.05561589449644089,
        -0.056828662753105164,
        0.025820277631282806,
        -0.04375642165541649,
        0.03221801295876503,
        -0.03788476809859276,
        -0.0400586761534214,
        -0.05340513586997986,
        0.056361451745033264,
        -0.033263757824897766,
        -0.02584272436797619,
        0.05045560374855995,
        -0.04208118095993996,
        -0.042412035167217255,
        -0.05066218599677086,
        0.056745078414678574,
        0.043610889464616776,
        -0.05657261982560158,
        -0.05064307898283005,
        0.05357389897108078,
        0.04780520498752594,
        0.055181220173835754,
        -0.013350888155400753,
        0.0032530357129871845,
        0.05333029478788376,
        -0.011032696813344955,
        0.04540858045220375,
        0.05604958161711693,
        -0.051726795732975006,
        0.05182827264070511,
        0.007013332098722458,
        0.022739779204130173,
        0.028548020869493484,
        -0.0496053472161293,
        -0.05095487833023071,
        -0.05159735307097435,
        0.055249325931072235,
        -0.016051778569817543,
        0.0504838302731514,
        -0.054908327758312225,
        -0.05283879116177559,
        -0.05679960921406746,
        0.056155070662498474,
        0.05384070426225662,
        -0.019119616597890854,
        0.05734569579362869,
        -0.055560775101184845,
        -0.03948957473039627,
        0.014989144168794155,
        -0.04551969841122627,
        0.05252809822559357,
        -0.006265132687985897,
        -0.00399166950955987,
        0.023579247295856476,
        -0.001309312880039215,
        -0.04690336436033249,
        -0.05715811997652054,
        -0.04824652895331383,
        0.028449291363358498,
        0.04664410650730133,
        -0.052853696048259735,
        0.013374818488955498,
        -0.053381819278001785,
        0.04960447549819946,
        0.05606550723314285,
        0.038816679269075394,
        -0.039667416363954544,
        -0.0350399874150753,
        0.02291109785437584,
        -0.051658641546964645,
        0.05419490858912468,
        -0.005160330329090357,
        -0.04664912819862366,
        -0.021973086521029472,
        -0.044019319117069244,
        -0.0011892488691955805,
        0.00893285684287548,
        0.009539980441331863,
        -0.049343474209308624,
        0.055715084075927734,
        -0.004789635073393583,
        -0.0283748060464859,
        0.0433870293200016,
        0.045403026044368744,
        0.05651547759771347,
        -0.04511866346001625,
        0.055000822991132736,
        -0.05576882138848305,
        -0.006539309397339821,
        -0.04544302076101303
    ],
    [
        -0.05184819549322128,
        -0.05041615292429924,
        0.054391875863075256,
        -0.016811521723866463,
        -0.04046833515167236,
        0.052327219396829605,
        0.031922344118356705,
        -0.053910788148641586,
        0.05018874630331993,
        0.021847626194357872,
        0.055091846734285355,
        0.043501563370227814,
        -0.05460796132683754,
        -0.05474386736750603,
        -0.042958807200193405,
        -0.04447828605771065,
        -0.0546923391520977,
        0.05487372726202011,
        -0.014031176455318928,
        -0.05484963580965996,
        0.055025432258844376,
        0.05512974038720131,
        0.043252453207969666,
        0.05314691737294197,
        -0.053696103394031525,
        -0.02829313464462757,
        -0.05351843312382698,
        0.04870709776878357,
        -0.04896026477217674,
        0.05511137843132019,
        0.05334003269672394,
        -0.05514908581972122,
        0.054948482662439346,
        -0.05476132780313492,
        0.052614420652389526,
        -0.04711126536130905,
        -0.04324860870838165,
        -0.0463113933801651,
        -0.04692750424146652,
        -0.049065615981817245,
        0.03872983902692795,
        -0.03392462432384491,
        0.0409836620092392,
        -0.05252855643630028,
        -0.03519963473081589,
        -0.04556988924741745,
        0.05515596643090248,
        0.05120258405804634,
        0.054182637482881546,
        -0.007014548406004906,
        0.053531207144260406,
        -0.05324488505721092,
        0.036044731736183167,
        -0.05382398143410683,
        0.0542522668838501,
        0.05505916476249695,
        0.011258495040237904,
        -0.04322979971766472,
        0.025650132447481155,
        -0.039607565850019455,
        -0.05458851531147957,
        -0.028254102915525436,
        -0.051191411912441254,
        0.019581301137804985,
        -0.03667256236076355,
        0.03748433291912079,
        -0.05464240908622742,
        0.020543936640024185,
        0.05480659753084183,
        -0.05514580383896828,
        -0.03321048989892006,
        -0.05207809433341026,
        0.026600593701004982,
        -0.009544509463012218,
        -0.05515165254473686,
        -0.010358963161706924,
        -0.03618990257382393,
        0.05510307475924492,
        -0.05385032668709755,
        -0.026617467403411865,
        -0.05280591547489166,
        -0.026371315121650696,
        -0.05456702783703804,
        -0.03217041492462158,
        0.04816921800374985,
        0.05338982865214348,
        -0.05425583943724632,
        -0.052094921469688416,
        0.04849148914217949,
        0.01475426647812128,
        -0.03623088821768761,
        0.011599288322031498,
        0.054995324462652206,
        -0.05239500850439072,
        -0.0549185685813427,
        0.03429607301950455,
        -0.036278970539569855,
        -0.03790182247757912,
        0.01877470500767231,
        0.030762383714318275,
        -0.054837416857481,
        0.053412627428770065,
        0.02150309644639492,
        0.05155383050441742,
        0.014024426229298115,
        -0.03970963880419731,
        0.05411268025636673,
        -0.04325943812727928,
        0.028957270085811615,
        0.05331050232052803,
        0.0509389191865921,
        -0.03737302124500275,
        0.048815298825502396,
        0.038698941469192505,
        -0.05079856887459755,
        0.005083146039396524,
        -0.0363103449344635,
        -0.02949957735836506,
        -0.043690025806427,
        0.05388454347848892,
        0.02582411840558052,
        0.0024839872494339943,
        -0.05510265380144119,
        -0.048510484397411346,
        -0.05468442663550377,
        0.05200033262372017,
        -0.05411198362708092,
        0.04158930853009224,
        -0.04318731278181076,
        -0.01374306995421648,
        0.019443264231085777,
        -0.03672796115279198,
        -0.027435366064310074,
        0.05015840381383896,
        -0.05227726325392723,
        -0.0544210784137249,
        0.05409136414527893,
        0.04725910350680351,
        -0.04205431416630745,
        -0.047176580876111984,
        0.03760898485779762,
        0.05089567229151726,
        0.05223482847213745,
        -0.03359763324260712,
        0.049120236188173294,
        -0.040735889226198196,
        -0.05279440060257912,
        -0.0016128814313560724,
        -0.05494431033730507,
        0.02667882665991783,
        0.05342258885502815,
        -0.05448618903756142,
        -0.045176953077316284,
        0.04570125788450241,
        0.055157653987407684,
        0.052217997610569,
        0.05513625964522362,
        0.0495755560696125,
        0.04900471866130829,
        0.030680164694786072,
        0.05281064286828041,
        0.020115353167057037,
        0.05283666029572487,
        0.018904179334640503,
        -0.0019253380596637726,
        0.04945056512951851,
        0.04556402936577797,
        -0.050260476768016815,
        0.04345117136836052,
        0.049713343381881714,
        -0.011001030914485455,
        -0.03969654068350792,
        -0.05433141440153122,
        0.0414707325398922,
        -0.05455424264073372,
        0.052647095173597336,
        -0.05201152712106705,
        0.03642651438713074,
        0.05344260856509209,
        0.000274302699835971,
        -0.023170998319983482,
        -0.03666196018457413,
        0.049369025975465775,
        -0.03983983397483826,
        0.021029174327850342,
        -0.04524504393339157,
        0.019405614584684372,
        0.05334296077489853,
        0.04283726215362549,
        0.05504891276359558,
        -0.0038664229214191437,
        0.03234995901584625,
        0.049305494874715805,
        -0.03701997175812721,
        -0.05442309007048607,
        -0.054677557200193405,
        -0.03500742092728615,
        0.04186319187283516,
        -0.05508638173341751,
        0.03581352159380913,
        0.05503970757126808,
        -0.019812969490885735,
        0.037881333380937576,
        -0.051361776888370514,
        0.05357888340950012,
        -0.04471889138221741,
        0.02424430474638939,
        0.024493420496582985,
        -0.036836642771959305,
        -0.007585871033370495,
        -0.025113165378570557,
        0.043947361409664154,
        0.052563391625881195,
        0.05409970507025719,
        -0.05478666350245476,
        0.05265907198190689,
        0.041741810739040375,
        -0.04597335681319237,
        0.05081614851951599,
        -0.054438892751932144,
        -0.04647999629378319,
        -0.05492359399795532,
        0.008141186088323593,
        0.04634188488125801,
        0.0375511609017849,
        -0.00578992860391736,
        0.014285118319094181,
        -0.05354877933859825,
        -0.04651520773768425,
        -0.04396580532193184,
        0.049833670258522034,
        -0.0540059469640255,
        0.04952765256166458,
        0.04287456348538399,
        0.015857107937335968,
        0.052454862743616104,
        0.048217106610536575,
        -0.05111665651202202,
        0.05397382378578186,
        0.055041972547769547,
        0.04763973504304886,
        0.05115630105137825,
        -0.019846107810735703,
        -0.055052127689123154,
        0.028540650382637978,
        -0.04854591190814972,
        0.04966847226023674,
        -0.045319583266973495,
        -0.054633740335702896,
        -0.055048659443855286,
        0.0336061529815197,
        -0.050231508910655975,
        0.05513854697346687,
        -0.05454292520880699,
        -0.011726689524948597,
        0.053535912185907364,
        0.054990723729133606,
        -0.040260251611471176,
        0.053510162979364395,
        -0.04563825577497482,
        -0.027263810858130455,
        0.043742585927248,
        0.05344963073730469,
        0.05331617593765259,
        0.007662664633244276,
        0.05501297488808632,
        0.04915820062160492,
        0.054679468274116516,
        0.050146330147981644,
        0.055107250809669495,
        -0.006974103394895792,
        -0.050947122275829315,
        0.04841845482587814,
        -0.05352041870355606,
        0.03368378058075905,
        0.052364084869623184,
        -0.048387132585048676,
        -0.054892994463443756,
        -0.05349930375814438,
        0.03300977870821953,
        -0.05495203658938408,
        0.04708758741617203,
        -0.04784422367811203,
        0.04888678714632988,
        -0.05228441581130028,
        0.05489281937479973,
        -0.005641882307827473,
        -0.03974051773548126,
        0.04475484415888786,
        0.04962318763136864,
        -0.049031633883714676,
        0.030564919114112854,
        -0.0016312815714627504,
        -0.04434701055288315,
        0.01525508426129818,
        -0.05085534229874611,
        0.05465604364871979,
        0.03152481094002724,
        -0.05107491463422775,
        0.0525611937046051,
        -0.054682016372680664,
        0.011908323504030704,
        -0.007954003289341927,
        -0.0021113604307174683,
        -0.05138014256954193,
        -0.01249947864562273,
        -0.02958892285823822,
        0.05489680916070938,
        0.048395127058029175,
        0.05513964593410492,
        0.050966255366802216,
        0.047885432839393616,
        -0.05472355708479881,
        0.05508282780647278,
        0.05099685862660408,
        -0.05495937168598175,
        -0.0300560612231493,
        0.05515781044960022,
        0.048947375267744064,
        0.016002068296074867,
        0.054279133677482605,
        0.027201225981116295,
        0.051608409732580185,
        -0.01870790682733059,
        0.0537138432264328,
        0.054924435913562775,
        0.03956662490963936,
        -0.007104092743247747,
        0.014082868583500385,
        0.03422822430729866,
        0.020612001419067383,
        -0.03916101157665253,
        0.054955337196588516,
        0.05515613779425621,
        0.05244417488574982,
        0.054401759058237076,
        -0.055107489228248596,
        -0.01857767254114151,
        -0.04128069803118706,
        -0.03723408654332161,
        -0.05100501328706741,
        -0.05475398525595665,
        -0.034844931215047836,
        0.05364732816815376,
        0.05514465644955635,
        0.044034194201231,
        0.05511040985584259,
        0.04944656789302826,
        -0.050598111003637314,
        0.009289728477597237,
        -0.05357983335852623,
        0.04549362510442734,
        -0.01012000348418951,
        -0.05399390682578087,
        -0.02281360514461994,
        0.05085063725709915,
        -0.051913321018218994,
        -0.054892655462026596,
        -0.05238150432705879,
        -0.05197320505976677,
        0.04753576219081879,
        0.01042626891285181,
        -0.021692465990781784,
        -0.04386771842837334,
        -0.054803263396024704,
        -0.05271728336811066,
        0.055093914270401,
        0.010295044630765915,
        -0.03005523607134819,
        -0.05394616350531578,
        -0.04145852103829384,
        -0.015102384611964226,
        0.03459010645747185,
        -0.034367434680461884,
        0.053445614874362946,
        0.054923489689826965,
        -0.0537334680557251,
        0.04889148846268654,
        0.05503738671541214,
        0.013322215527296066,
        0.01686875894665718,
        -0.050661686807870865,
        -0.05081159994006157,
        -0.0470917634665966,
        0.04906243085861206,
        -0.05499982088804245,
        0.03192129731178284,
        -0.054432377219200134,
        -0.05149732530117035,
        -0.052735984325408936,
        -0.02671424299478531,
        0.05479735881090164,
        -0.04633779078722,
        -0.04495641589164734,
        -0.006201965268701315,
        0.05281515046954155,
        0.040875762701034546,
        -0.030816001817584038,
        -0.051194943487644196,
        -0.05371013283729553,
        -0.04195195436477661,
        0.05154077708721161,
        -0.0511510856449604,
        0.015435024164617062,
        0.010174541734158993,
        0.04204995185136795,
        -0.028476735576987267,
        -0.0342353992164135,
        0.054237499833106995,
        -0.019283130764961243,
        -0.04126998037099838,
        0.015393885783851147,
        -0.05223914608359337,
        0.01772121898829937,
        0.05497996136546135,
        -0.005812080577015877,
        0.007010230794548988,
        0.054719291627407074,
        0.05120493471622467,
        -0.0003156849998049438,
        0.0542721226811409,
        -0.0482294037938118,
        0.042428966611623764,
        -0.051077693700790405,
        -0.042946167290210724,
        0.014527340419590473,
        0.05482737720012665,
        0.029499419033527374,
        -0.02312920242547989,
        -0.02739711105823517,
        0.03453480079770088,
        -0.05022426322102547,
        -0.04302015155553818,
        0.05359581112861633,
        0.018824608996510506,
        -0.054308902472257614,
        -0.053841277956962585,
        0.048514124006032944,
        0.03992369771003723,
        -0.048429179936647415,
        -0.05514336749911308,
        0.04444291815161705,
        0.05348178371787071,
        0.05288877710700035,
        -0.04107159748673439,
        -0.05009395629167557,
        0.05492936819791794,
        -0.04358379915356636,
        0.05428876355290413,
        0.025875002145767212,
        -0.05509393662214279,
        0.051116541028022766,
        0.02947896718978882,
        -0.05468355864286423,
        -0.016994280740618706,
        -0.0516437292098999,
        -0.009452354162931442,
        -0.03500707447528839,
        -0.048199016600847244,
        -0.03333301097154617,
        -0.025265250355005264,
        0.05198914185166359,
        0.02322275936603546,
        0.0374748669564724,
        0.054238609969615936,
        0.05336708575487137,
        0.028782019391655922,
        0.05275813862681389,
        -0.011839727871119976,
        -0.05280783399939537,
        0.033620256930589676,
        0.04823220148682594,
        0.053443118929862976,
        0.05514083430171013,
        -0.04734126105904579,
        0.04850650951266289,
        -0.040311992168426514,
        0.054706886410713196,
        -0.0551113523542881,
        -0.05433312803506851,
        -0.05287635326385498,
        0.0521213561296463,
        -0.04923315718770027,
        0.04423459246754646,
        0.014015883207321167,
        0.03873470798134804,
        0.012363878078758717,
        0.053035248070955276,
        -0.053543440997600555,
        0.05010226368904114,
        0.05066492781043053,
        -0.020655786618590355,
        0.008173477835953236,
        0.026146546006202698,
        -0.052635692059993744,
        0.03547455742955208,
        0.050712231546640396,
        -0.052917394787073135,
        0.045843735337257385,
        -0.05252616107463837,
        -0.017889728769659996,
        0.049255698919296265,
        -0.012623609974980354,
        -0.0015479099238291383,
        -0.0436011366546154,
        0.03953966498374939,
        0.05465403199195862,
        -0.04415804520249367,
        0.05481698736548424,
        0.022955453023314476,
        0.053649865090847015,
        0.0010737614938989282
    ],
    [
        0.027209008112549782,
        -0.05181911960244179,
        -0.05134309083223343,
        -0.01891743205487728,
        -0.029637683182954788,
        -0.0518193244934082,
        0.05180945619940758,
        -0.0517556257545948,
        -0.009022454731166363,
        0.05034037306904793,
        0.05167130008339882,
        -0.04610428214073181,
        -0.051698628813028336,
        0.050433166325092316,
        -0.05128706991672516,
        0.05150258168578148,
        -0.04923481494188309,
        0.05180024355649948,
        -0.051802538335323334,
        0.022308964282274246,
        -0.041856180876493454,
        -0.05148956924676895,
        0.0518176443874836,
        -0.050621483474969864,
        -0.05181938409805298,
        0.04798155277967453,
        -0.04796914756298065,
        0.05064567178487778,
        0.044614627957344055,
        0.05072404071688652,
        0.0339663028717041,
        0.0331285335123539,
        0.05077667534351349,
        -0.05181773379445076,
        0.050893403589725494,
        -0.05139860138297081,
        -0.051362939178943634,
        -0.05168916657567024,
        -0.05174018442630768,
        -0.005778453312814236,
        0.049653779715299606,
        -0.031351495534181595,
        0.050642576068639755,
        -0.05100667104125023,
        0.05052431300282478,
        0.040351416915655136,
        0.051813025027513504,
        0.05181019753217697,
        0.05181927606463432,
        -0.05165529251098633,
        0.04635348170995712,
        0.049902305006980896,
        0.000678534503094852,
        -0.051209740340709686,
        -0.04842319339513779,
        0.0518144816160202,
        -0.02540358528494835,
        -0.01693958230316639,
        -0.05076027289032936,
        -0.05160436034202576,
        -0.05176352709531784,
        0.035583000630140305,
        -0.049395907670259476,
        -0.043060582131147385,
        -0.01849951036274433,
        -0.05092358961701393,
        -0.05180051550269127,
        -0.04316188767552376,
        0.03441179171204567,
        -0.05181930586695671,
        0.021045727655291557,
        -0.05180900916457176,
        0.05181894451379776,
        0.05128537863492966,
        0.051268626004457474,
        -0.03846774995326996,
        0.0496973842382431,
        0.05181942507624626,
        0.02971120923757553,
        0.05107291042804718,
        -0.04964200407266617,
        -0.051096875220537186,
        -0.03843250498175621,
        0.03979098051786423,
        0.05091043934226036,
        0.044997960329055786,
        -0.0514405257999897,
        0.0008249245001934469,
        0.008767821826040745,
        0.03813619166612625,
        -0.042069584131240845,
        0.007499409839510918,
        0.04962256923317909,
        -0.05181867256760597,
        -0.04682543873786926,
        -0.003182812128216028,
        -0.050389036536216736,
        -0.05009527876973152,
        -0.05112883448600769,
        0.045240361243486404,
        -0.05123065039515495,
        0.051795199513435364,
        0.042768269777297974,
        -0.031913287937641144,
        0.049957651644945145,
        0.015946323052048683,
        -0.05068090558052063,
        0.03130113333463669,
        -0.04982703924179077,
        0.01669062301516533,
        0.05169578269124031,
        0.01789981871843338,
        0.0004936595796607435,
        0.046101223677396774,
        0.049719613045454025,
        -0.05173742026090622,
        -0.05171380192041397,
        -0.05089506506919861,
        0.03289571404457092,
        0.04798354580998421,
        -0.03523954749107361,
        0.03276367112994194,
        0.04608067125082016,
        -0.049587104469537735,
        -0.048455774784088135,
        0.04884312301874161,
        0.05141324922442436,
        -0.05157453939318657,
        0.05156345292925835,
        -0.03261565789580345,
        0.05177544057369232,
        -0.05157529190182686,
        -0.05095093697309494,
        0.05180364474654198,
        -0.029154261574149132,
        -0.05135968327522278,
        0.05181457847356796,
        -0.045700058341026306,
        -0.03421078622341156,
        0.05153137817978859,
        0.03413323685526848,
        -0.051382046192884445,
        0.03787126764655113,
        0.040263865143060684,
        0.04787207022309303,
        0.05180758982896805,
        -0.04159761592745781,
        0.0324939489364624,
        -0.04773600026965141,
        0.0281927902251482,
        -0.0515839122235775,
        -0.0514223612844944,
        -0.05179089680314064,
        -0.0387364886701107,
        0.042897798120975494,
        -0.04721130058169365,
        0.03527045249938965,
        0.03623714670538902,
        0.041180890053510666,
        0.012821068987250328,
        0.05054793506860733,
        -0.02495715022087097,
        0.05134536698460579,
        0.05179286375641823,
        -0.03957977145910263,
        0.04321398213505745,
        0.006809635553508997,
        -0.05111950263381004,
        -0.040094852447509766,
        0.0491572730243206,
        -0.0516296848654747,
        0.05113527923822403,
        -0.05029560998082161,
        -0.04804173484444618,
        -0.051813676953315735,
        0.02396700531244278,
        -0.0518193393945694,
        -0.05124718323349953,
        0.050623442977666855,
        -0.05151819437742233,
        -0.01981092058122158,
        0.04844793304800987,
        0.05152619257569313,
        -0.04384253919124603,
        -0.04986092820763588,
        0.05168329179286957,
        -0.003653262509033084,
        0.050006356090307236,
        0.051755379885435104,
        0.03803909569978714,
        -0.05178678035736084,
        -0.04365229606628418,
        0.05142899602651596,
        0.000828601885586977,
        -0.0508386567234993,
        -0.03476814553141594,
        0.05181856080889702,
        0.04939408600330353,
        -0.05148499086499214,
        0.04855605587363243,
        0.04922366887331009,
        0.027243267744779587,
        0.050972554832696915,
        0.047719329595565796,
        0.04874419420957565,
        0.044351596385240555,
        0.051626939326524734,
        0.050737496465444565,
        -0.03801122307777405,
        -0.033404748886823654,
        -0.04854629188776016,
        0.040842797607183456,
        0.051772672683000565,
        0.051797203719615936,
        0.0444018580019474,
        -0.051805127412080765,
        0.0518188551068306,
        -0.04766879230737686,
        0.05181942507624626,
        0.05181685462594032,
        0.0518142469227314,
        0.0402490459382534,
        -0.02485755644738674,
        -0.04887320473790169,
        0.051805585622787476,
        -0.048147693276405334,
        0.051349665969610214,
        -0.025587033480405807,
        0.049105435609817505,
        -0.05126987397670746,
        -0.023578613996505737,
        -0.05181880295276642,
        -0.04483485221862793,
        0.049866486340761185,
        0.016475142911076546,
        0.05181576684117317,
        0.04939663037657738,
        0.04801909998059273,
        -0.051621805876493454,
        0.05181479454040527,
        0.019186506047844887,
        0.014604568481445312,
        -0.051808394491672516,
        -0.05179380998015404,
        -0.0030328589491546154,
        -0.034900862723588943,
        -0.02119738608598709,
        0.051813650876283646,
        0.026226749643683434,
        0.0317634679377079,
        0.03739793226122856,
        -0.04305378720164299,
        -0.03953392058610916,
        -0.05136288329958916,
        0.02051018364727497,
        0.05178561806678772,
        0.05137641355395317,
        -0.017362745478749275,
        0.0411810576915741,
        0.05169538035988808,
        -0.05174538865685463,
        0.038444746285676956,
        -0.048651546239852905,
        0.027798909693956375,
        0.005720390006899834,
        0.05175202712416649,
        0.01285469625145197,
        0.05109083652496338,
        0.012602393515408039,
        0.05169444531202316,
        0.03248470276594162,
        -0.04929967597126961,
        0.049439165741205215,
        -0.051802102476358414,
        -0.04952212795615196,
        0.007961160503327847,
        -0.04670434817671776,
        -0.049287669360637665,
        0.051814138889312744,
        0.03443971648812294,
        -0.051524776965379715,
        0.013746875338256359,
        -0.04691139608621597,
        -0.01258400734513998,
        0.05117380991578102,
        -0.050235092639923096,
        0.04897456616163254,
        -0.051194850355386734,
        -0.04448290541768074,
        0.023635102435946465,
        -0.05082881078124046,
        0.04621223732829094,
        0.0014535163063555956,
        -0.051565103232860565,
        0.05087248980998993,
        -0.05108797177672386,
        0.05172819271683693,
        0.006632158532738686,
        -0.051580291241407394,
        0.051726747304201126,
        -0.008723150007426739,
        -0.050077371299266815,
        0.026658272370696068,
        0.04945326969027519,
        0.04826123267412186,
        0.01564931869506836,
        -0.05170522257685661,
        0.05170512571930885,
        0.05098764970898628,
        -0.03384169563651085,
        0.044483527541160583,
        -0.051474083214998245,
        0.000767579535022378,
        0.05163316801190376,
        -0.01185937412083149,
        -0.05159609764814377,
        -0.03254450857639313,
        0.05181938782334328,
        0.049434006214141846,
        -0.03420623764395714,
        -0.051713358610868454,
        -0.05009507015347481,
        0.04961731657385826,
        0.050996940582990646,
        0.05181548371911049,
        0.050154030323028564,
        0.0515679232776165,
        -0.03272801265120506,
        -0.05154532566666603,
        -0.04617232456803322,
        0.02281750738620758,
        -0.05002257972955704,
        0.012218192219734192,
        0.04244622588157654,
        0.05096311494708061,
        -0.019786786288022995,
        -0.03386213257908821,
        -0.03174370899796486,
        -0.025931240990757942,
        0.008310539647936821,
        -0.050075188279151917,
        -0.05161092430353165,
        -0.04349507763981819,
        0.05029527470469475,
        0.05181942507624626,
        0.05084109678864479,
        0.05106980353593826,
        0.05150219053030014,
        -0.05181526020169258,
        0.04827697575092316,
        -0.051768939942121506,
        0.05151938647031784,
        0.033322229981422424,
        -0.049672555178403854,
        -0.051766857504844666,
        -0.024846181273460388,
        -0.05181940272450447,
        -0.04161311313509941,
        -0.045592598617076874,
        -0.04299112409353256,
        0.037421755492687225,
        0.03764130175113678,
        0.0154642378911376,
        -0.04974856972694397,
        0.042064692825078964,
        -0.05153830721974373,
        0.051775362342596054,
        -0.011539732106029987,
        -0.00012858801346737891,
        -0.0517764613032341,
        -0.022019943222403526,
        0.03434645012021065,
        -0.03222554922103882,
        -0.0498310849070549,
        0.05168526619672775,
        0.05140719190239906,
        -0.05179451406002045,
        -0.051237933337688446,
        0.008623928762972355,
        -0.02216050773859024,
        0.04613121598958969,
        0.04367120563983917,
        -0.05079777538776398,
        -0.03474053367972374,
        0.05158286914229393,
        -0.051756493747234344,
        -0.05177601799368858,
        -0.04870448634028435,
        -0.04593011364340782,
        -0.051761846989393234,
        -0.050982654094696045,
        -0.04505325108766556,
        -0.05179693177342415,
        0.05006079748272896,
        0.05048355460166931,
        0.05105115473270416,
        0.05180398002266884,
        -0.045083411037921906,
        -0.04631480574607849,
        -0.05128562077879906,
        -0.04736393317580223,
        0.051405634731054306,
        -0.05127028748393059,
        0.050853464752435684,
        -0.051643989980220795,
        -0.001091954531148076,
        0.04576610028743744,
        0.034284137189388275,
        0.05176146328449249,
        -0.04921610653400421,
        0.04848719760775566,
        0.020827367901802063,
        -0.05064249038696289,
        -0.0077805654145777225,
        0.04520104452967644,
        -0.04618991166353226,
        0.05180058255791664,
        0.027542725205421448,
        0.050387024879455566,
        0.007833229377865791,
        0.05180596932768822,
        -0.051812246441841125,
        -0.04500126466155052,
        -0.0516916923224926,
        0.04013234004378319,
        0.011111337691545486,
        0.018531261011958122,
        0.05178006365895271,
        -0.051489826291799545,
        -0.05174775421619415,
        -0.04552273452281952,
        -0.03755916282534599,
        -0.05146503448486328,
        -0.038770440965890884,
        -0.05165174975991249,
        -0.05080610513687134,
        -0.051816295832395554,
        0.05171307176351547,
        -0.049408406019210815,
        -0.05051011964678764,
        -0.05157138407230377,
        0.05135686695575714,
        -0.0438869409263134,
        0.04968109726905823,
        0.024581821635365486,
        -0.01024415623396635,
        0.051758330315351486,
        -0.05181865766644478,
        -0.015684811398386955,
        0.05136441811919212,
        0.0018882432486861944,
        0.04554462805390358,
        0.050557635724544525,
        0.051052212715148926,
        -0.05076729506254196,
        -0.04945024847984314,
        -0.013625768013298512,
        -0.04504236578941345,
        -0.051810506731271744,
        0.03678012639284134,
        0.05179895460605621,
        -0.05181921645998955,
        0.01994454860687256,
        -0.05047639459371567,
        0.05177109315991402,
        0.05176566168665886,
        -0.04433602839708328,
        0.05181216076016426,
        0.0518183596432209,
        -0.050003938376903534,
        0.02088608779013157,
        0.049801576882600784,
        0.050820909440517426,
        0.04925299063324928,
        0.00161069817841053,
        0.015872938558459282,
        0.05163706839084625,
        -0.02994730696082115,
        -0.051200442016124725,
        0.04471103847026825,
        -0.05181450769305229,
        -0.035708311945199966,
        -0.019885510206222534,
        0.03677147999405861,
        -0.05172552168369293,
        0.05161181464791298,
        -0.02826092019677162,
        0.04962174966931343,
        -0.05147034674882889,
        0.04465804621577263,
        -0.041068773716688156,
        -0.051758937537670135,
        -0.006345564033836126,
        -0.033398017287254333,
        0.03377277031540871,
        -0.0239226333796978,
        -0.04030011221766472,
        0.0465661995112896,
        0.03684728592634201,
        -0.016050007194280624,
        0.05177689343690872,
        -0.051170483231544495,
        -0.04790210723876953,
        -0.03571483492851257,
        0.051770035177469254,
        0.051590368151664734,
        0.04609861597418785,
        -0.022188063710927963,
        0.049335651099681854,
        -0.045500971376895905,
        0.0515814907848835,
        -0.0017741440096870065
    ],
    [
        0.052355289459228516,
        -0.007826614193618298,
        0.014609712176024914,
        0.02462642267346382,
        -0.04675698280334473,
        0.05235554277896881,
        0.04954138025641441,
        -0.05194013938307762,
        0.05212821066379547,
        -0.049949321895837784,
        0.051730744540691376,
        -0.03268732875585556,
        -0.052031658589839935,
        0.05101247504353523,
        -0.04384938254952431,
        0.05240171030163765,
        0.05222836136817932,
        0.0523001104593277,
        -0.030421413481235504,
        -0.0511314757168293,
        -0.04482102766633034,
        0.012271619401872158,
        0.042973585426807404,
        0.04982401430606842,
        -0.006718113087117672,
        0.04845549166202545,
        -0.008430598303675652,
        0.0523499920964241,
        -0.051981933414936066,
        -0.032827991992235184,
        0.051350563764572144,
        -0.052399758249521255,
        -0.0074747162871062756,
        -0.052409060299396515,
        0.04895578697323799,
        -0.05236618593335152,
        -0.051479265093803406,
        0.05226372182369232,
        0.052074722945690155,
        0.05240243300795555,
        0.047068193554878235,
        0.05180215835571289,
        0.05039062350988388,
        -0.051908619701862335,
        0.05053035542368889,
        -0.008287436328828335,
        0.0523851253092289,
        0.05243659019470215,
        0.05202598497271538,
        -0.051931027323007584,
        0.051904916763305664,
        0.051677409559488297,
        -0.02937908098101616,
        0.0421421118080616,
        0.05236441642045975,
        -0.052392613142728806,
        0.05240817740559578,
        -0.021840985864400864,
        -0.04951373487710953,
        -0.05193215236067772,
        -0.05046804994344711,
        0.020047178491950035,
        -0.05159115791320801,
        -0.052041295915842056,
        -0.03406534716486931,
        -0.021107489243149757,
        -0.05196031183004379,
        0.05077030509710312,
        0.026579752564430237,
        -0.05109912529587746,
        0.04805387184023857,
        -0.05228658765554428,
        0.052432745695114136,
        0.02879403531551361,
        -0.009177317842841148,
        -0.022883817553520203,
        -0.007893027737736702,
        0.05243907868862152,
        0.05226308852434158,
        0.014058433473110199,
        -0.04382115975022316,
        -0.052141014486551285,
        -0.0524144172668457,
        0.050551824271678925,
        0.051698338240385056,
        0.051765039563179016,
        -0.052229877561330795,
        -0.0505339652299881,
        -0.014749066904187202,
        0.047613658010959625,
        -0.041394688189029694,
        0.02122688479721546,
        -0.0463983416557312,
        -0.05240020900964737,
        -0.051943883299827576,
        0.05233793333172798,
        -0.013443311676383018,
        -0.05069132521748543,
        -0.052155815064907074,
        0.051065653562545776,
        -0.05237837880849838,
        0.050389111042022705,
        0.04934704676270485,
        0.05232420191168785,
        0.04602725803852081,
        -0.052365273237228394,
        -0.05222627893090248,
        0.03466759994626045,
        -0.041110310703516006,
        0.03340865299105644,
        -0.052325889468193054,
        -0.05200265347957611,
        0.00954472180455923,
        0.04468328133225441,
        0.052422694861888885,
        0.05243026465177536,
        -0.0524003729224205,
        -0.05164989456534386,
        0.05209669843316078,
        -0.049759551882743835,
        -0.019948501139879227,
        -0.042430512607097626,
        0.04618217423558235,
        -0.05007973685860634,
        -0.01824759878218174,
        0.048364702612161636,
        -0.028980815783143044,
        0.04909207299351692,
        0.04683663696050644,
        0.048671986907720566,
        0.045630842447280884,
        -0.05131089687347412,
        -0.006166588980704546,
        0.05242086201906204,
        -0.02934741973876953,
        -0.051839519292116165,
        0.052282627671957016,
        -0.0508347749710083,
        0.040014080703258514,
        0.03602263331413269,
        0.018905363976955414,
        0.050812263041734695,
        0.045921552926301956,
        0.025403453037142754,
        -0.03546580299735069,
        0.02278945967555046,
        0.03712410852313042,
        0.05171355977654457,
        -0.0523916520178318,
        0.05239539593458176,
        0.05003951117396355,
        -0.0524316132068634,
        -0.04939575865864754,
        -0.05065324530005455,
        -0.052185170352458954,
        -0.0026229231152683496,
        0.010262837633490562,
        0.040940385311841965,
        0.052415117621421814,
        0.02098352462053299,
        -0.052068427205085754,
        -0.052029579877853394,
        0.051584452390670776,
        -0.02317877486348152,
        0.052433378994464874,
        0.04463256150484085,
        0.05242541432380676,
        -0.027855612337589264,
        -0.00827929750084877,
        0.03283903747797012,
        -0.05014806613326073,
        -0.05217856168746948,
        -0.05242447182536125,
        -0.05233968794345856,
        0.05241341516375542,
        0.03858737275004387,
        0.045950789004564285,
        -0.03951326385140419,
        0.05193565413355827,
        -0.052253954112529755,
        -0.05229464918375015,
        0.015980713069438934,
        0.027722669765353203,
        -0.01598573476076126,
        0.05146499350667,
        -0.05240491032600403,
        0.05233930051326752,
        0.05121632292866707,
        0.03886808082461357,
        -0.04935023561120033,
        -0.052424803376197815,
        0.04915774241089821,
        0.05203118920326233,
        0.02796754613518715,
        -0.02892952412366867,
        -0.05025235936045647,
        0.026374375447630882,
        -0.03492576628923416,
        -0.04429034888744354,
        0.05243729054927826,
        0.05235302820801735,
        -0.03262685611844063,
        -0.0005248634261079133,
        -0.05236497521400452,
        0.05239807069301605,
        -0.0509556382894516,
        -0.003261289792135358,
        -0.051952946931123734,
        -0.05078071355819702,
        0.05237412825226784,
        -0.050285935401916504,
        -0.03806352987885475,
        0.017096875235438347,
        0.04413764923810959,
        0.02942616306245327,
        -0.04993235319852829,
        0.05200167000293732,
        0.02912784181535244,
        0.0522937998175621,
        -0.038938380777835846,
        0.05202704668045044,
        0.05021455138921738,
        0.008476192131638527,
        -0.032778602093458176,
        0.0524030402302742,
        -0.015589523129165173,
        0.050927650183439255,
        -0.05147545784711838,
        0.05220329016447067,
        -0.048562612384557724,
        -0.052368078380823135,
        -0.049449555575847626,
        -0.05230580270290375,
        0.04740620777010918,
        0.04573176056146622,
        0.05217832699418068,
        0.05118798092007637,
        0.03671785816550255,
        -0.05141139030456543,
        0.052436500787734985,
        0.028180263936519623,
        0.039766017347574234,
        0.003974003717303276,
        -0.05237625166773796,
        0.04562050849199295,
        -0.05040711909532547,
        0.04541352018713951,
        -0.02869304269552231,
        -0.04575679078698158,
        -0.052052419632673264,
        0.052065275609493256,
        -0.025504423305392265,
        0.05242469906806946,
        -0.05150220915675163,
        0.012779352255165577,
        0.05242956429719925,
        0.03968358039855957,
        -0.05072993412613869,
        0.03336934372782707,
        -0.022274263203144073,
        -0.0518600232899189,
        0.0023658087011426687,
        -0.05197621136903763,
        0.049790386110544205,
        -0.02599654346704483,
        0.05243172496557236,
        0.04990550875663757,
        0.052439022809267044,
        0.03898372873663902,
        0.0429052971303463,
        0.052315063774585724,
        -0.05180743709206581,
        0.05243590474128723,
        -0.04053916782140732,
        0.05238927900791168,
        0.04913422837853432,
        -0.05083548650145531,
        -0.04699886590242386,
        0.04961381480097771,
        0.04520248621702194,
        -0.05215919390320778,
        0.02993405982851982,
        -0.05188501253724098,
        -0.04929997771978378,
        0.05165540799498558,
        0.002538383472710848,
        -0.0003306381404399872,
        0.05056752264499664,
        0.05234247073531151,
        0.04809463769197464,
        -0.051769062876701355,
        -0.004161749500781298,
        -0.04626987501978874,
        0.0505375862121582,
        0.05117258056998253,
        -0.051995109766721725,
        0.05242890119552612,
        -0.035758111625909805,
        -0.04175788536667824,
        0.051670655608177185,
        0.0523645281791687,
        0.01891639456152916,
        -0.017047321423888206,
        0.019358478486537933,
        0.05223412066698074,
        0.02997337467968464,
        -0.011371114291250706,
        0.05243195220828056,
        -0.030406266450881958,
        -0.04497698321938515,
        -0.03199357911944389,
        -0.022646194323897362,
        0.052426911890506744,
        -0.04859508201479912,
        0.01049467921257019,
        -0.052146051079034805,
        0.0102941133081913,
        0.05243915319442749,
        0.05181514844298363,
        0.013983143493533134,
        -0.052396249026060104,
        -0.0403335839509964,
        0.0524386391043663,
        -0.034783441573381424,
        0.05241036415100098,
        0.04456322640180588,
        0.021690769121050835,
        0.05179975926876068,
        -0.02531282976269722,
        -0.05209511145949364,
        -0.04845286160707474,
        -0.05165085196495056,
        0.052079733461141586,
        -0.009424224495887756,
        0.04770800098776817,
        0.05224011093378067,
        -0.05193392187356949,
        -0.04478521645069122,
        0.0030144660267978907,
        0.052368905395269394,
        -0.051087792962789536,
        -0.0524136982858181,
        0.05224411189556122,
        -0.0511932447552681,
        -0.03799637779593468,
        0.049640312790870667,
        0.051835279911756516,
        0.05139867216348648,
        -0.05214839056134224,
        0.011595274321734905,
        0.05206414684653282,
        0.05243789032101631,
        0.010817227885127068,
        -0.052101947367191315,
        -0.05241069197654724,
        -0.04973234236240387,
        -0.05243498459458351,
        -0.05240822583436966,
        -0.025079969316720963,
        -0.0031170500442385674,
        0.0438738651573658,
        0.04207050800323486,
        -0.043464917689561844,
        -0.05195048823952675,
        -0.0203233752399683,
        -0.051551952958106995,
        0.052421603351831436,
        -0.051452845335006714,
        -0.04027564451098442,
        -0.04917796328663826,
        -0.05122658237814903,
        -0.013662506826221943,
        -0.030893618240952492,
        0.024668147787451744,
        0.017191283404827118,
        0.05243908613920212,
        0.04734645411372185,
        0.03782171756029129,
        0.05231986939907074,
        0.03971632942557335,
        -0.05209687724709511,
        -0.016944540664553642,
        -0.0020316641312092543,
        -0.050764575600624084,
        0.043894924223423004,
        -0.0523403100669384,
        -0.0524153895676136,
        0.02470582164824009,
        -0.05164668709039688,
        -0.05243123322725296,
        -0.05236898362636566,
        -0.03537358343601227,
        -0.05079980567097664,
        -0.052435047924518585,
        0.013473249040544033,
        0.05207058787345886,
        0.024967823177576065,
        -0.00916996132582426,
        -0.05240044370293617,
        -0.032794881612062454,
        0.00004291231380193494,
        0.052267272025346756,
        -0.05072494596242905,
        0.014846133068203926,
        0.03168075531721115,
        0.04465477913618088,
        -0.021479815244674683,
        -0.04773470014333725,
        0.052283428609371185,
        0.05088929086923599,
        -0.051045920699834824,
        0.05157185718417168,
        -0.004112684167921543,
        -0.03541557863354683,
        0.047689832746982574,
        -0.02279287576675415,
        -0.048869967460632324,
        -0.006717465352267027,
        0.05026927590370178,
        0.04428352043032646,
        0.052414670586586,
        -0.042461883276700974,
        -0.052438534796237946,
        -0.052436456084251404,
        -0.010898240841925144,
        -0.047342449426651,
        -0.05242703855037689,
        -0.044075604528188705,
        -0.05137776955962181,
        -0.05237564817070961,
        0.052395787090063095,
        -0.04216007888317108,
        0.042519181966781616,
        -0.03626178577542305,
        -0.04629756510257721,
        -0.0327381007373333,
        -0.0513596273958683,
        0.051885295659303665,
        -0.05146379396319389,
        -0.04693044722080231,
        0.05024149268865585,
        0.05171263962984085,
        -0.00835594441741705,
        0.04992841184139252,
        -0.011580906808376312,
        0.020550791174173355,
        0.051724933087825775,
        0.032125651836395264,
        0.015345931053161621,
        0.052438050508499146,
        -0.007265315856784582,
        0.0524335615336895,
        0.00265687913633883,
        -0.01279408298432827,
        0.052439134567976,
        -0.0519888699054718,
        -0.03905574977397919,
        -0.04900547116994858,
        0.05051753297448158,
        0.05221642926335335,
        0.05241811275482178,
        0.03343359753489494,
        -0.03758515045046806,
        0.040325142443180084,
        0.05242919921875,
        0.051737263798713684,
        -0.052420251071453094,
        0.05239756032824516,
        -0.04497463256120682,
        -0.052193038165569305,
        0.04650753363966942,
        -0.038428351283073425,
        0.036878205835819244,
        -0.05230042338371277,
        -0.031395960599184036,
        -0.00562944496050477,
        0.006622915156185627,
        0.05072186887264252,
        -0.05229933187365532,
        -0.05159095674753189,
        0.052424877882003784,
        0.036675192415714264,
        0.05038497596979141,
        0.04626309126615524,
        -0.04428824782371521,
        0.04997728765010834,
        0.052387502044439316,
        0.04735012352466583,
        -0.04954265430569649,
        -0.027739636600017548,
        -0.02591850236058235,
        -0.05243464931845665,
        0.051926594227552414,
        0.0336756631731987,
        -0.05025843158364296,
        0.05033324658870697,
        -0.05041932314634323,
        0.021226024255156517,
        0.049076829105615616,
        0.05234849080443382,
        -0.05199510231614113,
        -0.039547886699438095,
        -0.042716220021247864,
        -0.05243830010294914,
        0.05201486498117447,
        0.05222441628575325,
        0.05243619158864021,
        -0.04205630347132683,
        0.0523383654654026,
        -0.04895423352718353,
        0.02225690707564354,
        -0.03833083063364029
    ],
    [
        0.054211437702178955,
        0.04304450377821922,
        -0.046264104545116425,
        -0.02556627430021763,
        -0.05318113788962364,
        0.02366221509873867,
        0.046600084751844406,
        -0.0522945299744606,
        0.053531210869550705,
        -0.05428939685225487,
        0.05430428311228752,
        0.04729507863521576,
        -0.054395392537117004,
        -0.014493616297841072,
        -0.0544203482568264,
        0.03428923338651657,
        0.03237561881542206,
        0.0396450012922287,
        0.048552144318819046,
        -0.02325095422565937,
        0.04825996980071068,
        0.022519003599882126,
        0.05340002104640007,
        0.018628399819135666,
        -0.05019247159361839,
        0.010987389832735062,
        -0.04972393810749054,
        0.05155754089355469,
        -0.009801835753023624,
        0.054410312324762344,
        0.05165460333228111,
        -0.04336316138505936,
        -0.046588294208049774,
        -0.05439826473593712,
        0.052179064601659775,
        -0.05105770751833916,
        -0.05407821014523506,
        0.025039060041308403,
        0.05384501814842224,
        0.052964404225349426,
        0.05432790145277977,
        0.04793909937143326,
        0.05243958532810211,
        0.051871158182621,
        0.014040227048099041,
        -0.04957621544599533,
        0.052131325006484985,
        0.05414682626724243,
        0.05443282797932625,
        0.05423304811120033,
        0.05184382572770119,
        0.033868707716464996,
        -0.030008863657712936,
        -0.02187518961727619,
        0.05144518241286278,
        -0.004310023505240679,
        -0.01710684224963188,
        0.03231467679142952,
        -0.04167189821600914,
        0.010559801012277603,
        -0.049553222954273224,
        -0.022105954587459564,
        -0.054203782230615616,
        -0.003046775469556451,
        -0.05318670719861984,
        -0.05438868701457977,
        -0.05438058450818062,
        -0.029523184522986412,
        -0.0361875519156456,
        -0.05420504882931709,
        -0.04404395446181297,
        -0.05205319821834564,
        0.05441761389374733,
        0.0543484203517437,
        -0.05437799543142319,
        -0.052243903279304504,
        0.054405227303504944,
        0.05443622171878815,
        -0.05171724781394005,
        0.0309895072132349,
        -0.053622789680957794,
        -0.05402227118611336,
        -0.05414166674017906,
        0.04997410625219345,
        0.011220182292163372,
        0.04834195226430893,
        -0.04835658520460129,
        -0.05109035596251488,
        0.051826417446136475,
        0.05398433655500412,
        -0.04701371118426323,
        -0.05209017172455788,
        -0.014798959717154503,
        -0.05393446981906891,
        -0.03976818174123764,
        0.012085220776498318,
        -0.04859696328639984,
        -0.023049211129546165,
        0.03461086377501488,
        0.05204986780881882,
        -0.05441104248166084,
        0.04815683513879776,
        0.01803247071802616,
        0.054422494024038315,
        0.0005078486865386367,
        -0.05424192547798157,
        -0.051566652953624725,
        0.05193231999874115,
        -0.05139967054128647,
        0.03017149493098259,
        -0.02050650492310524,
        -0.01878328248858452,
        0.001661372953094542,
        0.045834485441446304,
        -0.02275567315518856,
        -0.0038509683217853308,
        0.026713401079177856,
        -0.052606143057346344,
        0.054207004606723785,
        0.036674562841653824,
        -0.05014780908823013,
        0.03282032534480095,
        -0.05320172756910324,
        -0.054043952375650406,
        -0.047665342688560486,
        0.054408274590969086,
        -0.007540812715888023,
        0.050895173102617264,
        0.05320444330573082,
        0.011877061799168587,
        0.04227831959724426,
        -0.031619198620319366,
        -0.05341025069355965,
        0.04930375888943672,
        -0.05357624590396881,
        -0.05435587093234062,
        0.054397184401750565,
        0.00797599833458662,
        0.044452160596847534,
        0.030919624492526054,
        -0.010504234582185745,
        0.054433513432741165,
        0.04178754612803459,
        0.03207716718316078,
        -0.005868260748684406,
        0.007075958885252476,
        0.012066850438714027,
        -0.01757526397705078,
        0.04003021866083145,
        0.05442820489406586,
        0.0347210168838501,
        -0.05435730516910553,
        0.0035230580251663923,
        -0.053831297904253006,
        0.03234272077679634,
        0.051698360592126846,
        -0.028669556602835655,
        -0.009404754266142845,
        0.05442776530981064,
        0.03812641277909279,
        -0.0444023422896862,
        -0.04555245116353035,
        0.054204750806093216,
        0.05436829477548599,
        0.05245116725564003,
        0.036376290023326874,
        0.05283576250076294,
        0.019223101437091827,
        0.004479515831917524,
        0.05118498206138611,
        -0.0544089674949646,
        -0.021858321502804756,
        -0.05424518138170242,
        0.024934975430369377,
        -0.01753241755068302,
        0.037980228662490845,
        -0.047564808279275894,
        -0.010886021889746189,
        0.05414649844169617,
        -0.04768131300806999,
        -0.04923144727945328,
        -0.007120258640497923,
        -0.003226121421903372,
        -0.04358444735407829,
        -0.046033505350351334,
        -0.05426817759871483,
        0.037427715957164764,
        0.05386780947446823,
        -0.054410845041275024,
        0.04721146449446678,
        -0.05433741956949234,
        0.02512914314866066,
        0.03880761191248894,
        0.023228580132126808,
        -0.054037682712078094,
        -0.0054246168583631516,
        -0.021557031199336052,
        0.04384276643395424,
        0.024993879720568657,
        0.05335245281457901,
        0.05423698201775551,
        -0.050204306840896606,
        -0.05203148350119591,
        -0.05434836819767952,
        0.04833514615893364,
        -0.05442456156015396,
        0.04481515660881996,
        0.03036014921963215,
        -0.05096982792019844,
        -0.010339160449802876,
        -0.04956420883536339,
        0.054096199572086334,
        0.03616893291473389,
        0.05330189689993858,
        -0.024196304380893707,
        0.04194546118378639,
        0.03479718416929245,
        0.021051503717899323,
        0.05438666790723801,
        -0.05394252389669418,
        0.023763243108987808,
        0.024521568790078163,
        -0.05311927571892738,
        0.00988759659230709,
        0.01798699051141739,
        -0.046333011239767075,
        0.05427328124642372,
        -0.05442602559924126,
        0.031758520752191544,
        -0.04750688374042511,
        -0.05425168573856354,
        -0.05289831757545471,
        0.042480163276195526,
        0.04892612248659134,
        0.04472942277789116,
        0.04944230616092682,
        0.04877176508307457,
        -0.011236852966248989,
        -0.05114150792360306,
        0.054436083883047104,
        0.012380961328744888,
        0.05436069890856743,
        -0.032989419996738434,
        -0.05443372204899788,
        0.04664364084601402,
        -0.01780918426811695,
        -0.010738239623606205,
        0.016372766345739365,
        -0.03702371194958687,
        -0.05404839664697647,
        -0.05429036170244217,
        -0.05434844642877579,
        0.054434411227703094,
        -0.052420541644096375,
        0.05159585177898407,
        0.04804070666432381,
        0.053988225758075714,
        -0.039133280515670776,
        0.05396045744419098,
        -0.016132088378071785,
        -0.05365056172013283,
        -0.05367134138941765,
        -0.039627283811569214,
        0.053907278925180435,
        0.050130780786275864,
        0.05443043261766434,
        0.04856926575303078,
        0.03541896864771843,
        0.005780715961009264,
        0.05432916805148125,
        0.046626243740320206,
        -0.05370688438415527,
        0.054407767951488495,
        -0.05432943254709244,
        0.036350853741168976,
        0.04438108205795288,
        -0.05401337891817093,
        -0.008190625347197056,
        -0.04932931065559387,
        -0.0454709567129612,
        -0.053984154015779495,
        0.006587427109479904,
        -0.054051946848630905,
        0.05442534759640694,
        0.0542985163629055,
        0.04941621422767639,
        0.04729067161679268,
        0.05133628845214844,
        -0.0028077864553779364,
        -0.035342078655958176,
        -0.05327652022242546,
        0.053546614944934845,
        -0.05236203595995903,
        -0.040803536772727966,
        0.011338739655911922,
        -0.05018184334039688,
        0.05417066812515259,
        0.05437486991286278,
        -0.05437839776277542,
        0.05428716167807579,
        -0.049777545034885406,
        0.0525604709982872,
        0.03870982676744461,
        0.05133146047592163,
        0.05409519746899605,
        -0.01846684329211712,
        -0.05270175635814667,
        0.054393257945775986,
        0.05142588168382645,
        -0.013473961502313614,
        0.05008460953831673,
        -0.054348018020391464,
        0.05144777521491051,
        0.014751343987882137,
        0.03085695393383503,
        -0.05335034430027008,
        -0.03238144889473915,
        0.054435886442661285,
        0.0480320006608963,
        -0.030996717512607574,
        0.048513948917388916,
        0.018019797280430794,
        0.05436907708644867,
        0.031146230176091194,
        0.053906019777059555,
        0.053363677114248276,
        0.053733717650175095,
        -0.052305497229099274,
        0.020787915214896202,
        -0.005603243131190538,
        0.054091956466436386,
        -0.05097831413149834,
        0.054394565522670746,
        0.05205117538571358,
        0.053295642137527466,
        0.05437629297375679,
        -0.053149737417697906,
        -0.011268021538853645,
        0.042265258729457855,
        0.04497574269771576,
        -0.04831068590283394,
        -0.0537557415664196,
        0.030337093397974968,
        0.04678751155734062,
        0.048559509217739105,
        0.018161337822675705,
        0.0529353953897953,
        0.05389560014009476,
        -0.05430733785033226,
        0.005256550386548042,
        0.05377150699496269,
        0.05367894470691681,
        0.04998289793729782,
        -0.05432412773370743,
        -0.0506720244884491,
        0.0016230499604716897,
        -0.054348938167095184,
        -0.053426630795001984,
        -0.047203194350004196,
        0.0035696288105100393,
        0.03417918086051941,
        0.012711012735962868,
        -0.04118651524186134,
        -0.05249030888080597,
        -0.050242044031620026,
        -0.05441652610898018,
        0.054113563150167465,
        -0.013057773001492023,
        0.04901481047272682,
        -0.05439893901348114,
        -0.033972565084695816,
        -0.050034381449222565,
        0.051945481449365616,
        -0.05019519105553627,
        0.05406242981553078,
        0.054357606917619705,
        0.052781954407691956,
        -0.03157386928796768,
        0.05412934720516205,
        -0.018946759402751923,
        -0.03165240213274956,
        -0.0378531776368618,
        -0.053210336714982986,
        0.006133068352937698,
        -0.03743099048733711,
        -0.054424308240413666,
        -0.046466000378131866,
        -0.04325447976589203,
        -0.05338916927576065,
        -0.05432939901947975,
        -0.04958273097872734,
        -0.010247483849525452,
        -0.050409600138664246,
        -0.04616984352469444,
        -0.010205009020864964,
        0.054222989827394485,
        -0.01583920046687126,
        -0.04981309548020363,
        -0.036488376557826996,
        -0.0514499768614769,
        -0.04556037113070488,
        0.05401526391506195,
        -0.002723522949963808,
        0.03441954776644707,
        0.04395098611712456,
        0.048234473913908005,
        0.01853102445602417,
        -0.053691282868385315,
        0.05351955443620682,
        -0.03870950639247894,
        0.008113229647278786,
        -0.05074084922671318,
        0.004532503895461559,
        0.023136641830205917,
        0.05314800143241882,
        -0.01603306457400322,
        -0.023162903264164925,
        0.04421645402908325,
        0.04607680067420006,
        0.05425814911723137,
        0.05443470552563667,
        0.054049301892519,
        0.02218363620340824,
        -0.054435111582279205,
        0.03460054099559784,
        -0.013897662982344627,
        0.01101576630026102,
        -0.0033339487854391336,
        -0.053497448563575745,
        -0.05439748242497444,
        -0.023119591176509857,
        -0.052826326340436935,
        -0.05219889432191849,
        0.03713768348097801,
        -0.033550847321748734,
        -0.045854583382606506,
        -0.05218150466680527,
        0.054317303001880646,
        0.014870786108076572,
        -0.04409904405474663,
        -0.0472617968916893,
        -0.001790868816897273,
        0.05412603169679642,
        0.05282703787088394,
        -0.04324113577604294,
        -0.05411519482731819,
        0.05412130802869797,
        0.004591702949255705,
        0.05417390167713165,
        0.05443118140101433,
        -0.05391395464539528,
        0.05415286496281624,
        -0.03994479402899742,
        -0.0361960306763649,
        0.05438988655805588,
        -0.05426507815718651,
        -0.04872852936387062,
        -0.04731769114732742,
        0.05280207097530365,
        0.04807315021753311,
        0.030391918495297432,
        -0.004022455774247646,
        0.01840989664196968,
        0.04380445554852486,
        0.052377235144376755,
        0.05424114316701889,
        -0.05375843867659569,
        0.05395164713263512,
        -0.02873549610376358,
        -0.05323895066976547,
        0.0316796638071537,
        -0.013430939987301826,
        0.05004146695137024,
        0.04606816917657852,
        -0.04046579450368881,
        -0.0005938825779594481,
        -0.053880978375673294,
        0.04552888497710228,
        -0.05410180240869522,
        -0.05036768317222595,
        -0.0403607115149498,
        0.0530487559735775,
        -0.01866956800222397,
        0.05429423600435257,
        0.038743361830711365,
        0.035465363413095474,
        0.054314520210027695,
        0.05412694439291954,
        -0.05393866449594498,
        -0.048981837928295135,
        -0.053770359605550766,
        -0.05397629365324974,
        0.04697304219007492,
        0.05266287550330162,
        -0.05397370830178261,
        0.043810997158288956,
        0.01987631805241108,
        -0.054259009659290314,
        0.053945392370224,
        0.05217290297150612,
        -0.05206475034356117,
        0.02995854616165161,
        0.04047691449522972,
        -0.054204560816287994,
        0.039546556770801544,
        0.03807596117258072,
        0.05435249209403992,
        -0.00676410086452961,
        0.054357241839170456,
        -0.05371766537427902,
        0.054396018385887146,
        -0.02305113524198532
    ],
    [
        0.03765390068292618,
        0.048572953790426254,
        0.03395119309425354,
        0.04411068186163902,
        -0.026273591443896294,
        -0.05067795142531395,
        0.05266618728637695,
        -0.052845414727926254,
        0.04499530419707298,
        -0.05263471603393555,
        0.05093876272439957,
        0.016533764079213142,
        -0.052363935858011246,
        -0.022343570366501808,
        -0.05122827738523483,
        0.05194760859012604,
        0.052803631871938705,
        0.05284351110458374,
        0.0074858167208731174,
        -0.02419557422399521,
        -0.04770313948392868,
        -0.04330679774284363,
        0.052627772092819214,
        -0.052233193069696426,
        0.012831151485443115,
        0.038607701659202576,
        0.04327351227402687,
        0.052811022847890854,
        -0.04689802974462509,
        0.0519995354115963,
        0.05250220373272896,
        -0.052868399769067764,
        0.052817411720752716,
        -0.052862536162137985,
        0.04364430159330368,
        -0.05283547192811966,
        -0.05269615724682808,
        0.024998698383569717,
        0.048860255628824234,
        0.052630625665187836,
        -0.04655076190829277,
        0.049770254641771317,
        0.05062185600399971,
        0.009023962542414665,
        0.05271695926785469,
        0.043832987546920776,
        0.051986418664455414,
        0.05286436900496483,
        0.05222519859671593,
        -0.05269646272063255,
        0.05207313597202301,
        0.05280802771449089,
        -0.026740441098809242,
        0.03974009305238724,
        -0.026412511244416237,
        0.024559203535318375,
        0.04185709357261658,
        -0.012373218312859535,
        -0.052596624940633774,
        -0.04846026375889778,
        -0.05197661742568016,
        0.03730872645974159,
        -0.050077538937330246,
        -0.01988506130874157,
        0.04348595812916756,
        -0.05256003886461258,
        -0.05279757082462311,
        -0.05284300819039345,
        -0.04023871198296547,
        -0.05276169255375862,
        0.03475739806890488,
        -0.052828188985586166,
        -0.010119504295289516,
        -0.01699776202440262,
        -0.052739303559064865,
        -0.04793226346373558,
        -0.022535696625709534,
        0.05286865308880806,
        0.051061782985925674,
        0.04525487497448921,
        -0.04787524789571762,
        -0.050399698317050934,
        -0.05222059413790703,
        0.04459197819232941,
        -0.03588194027543068,
        0.046550873667001724,
        -0.03333190828561783,
        -0.038572780787944794,
        0.04052213951945305,
        0.052108924835920334,
        -0.0512511320412159,
        -0.05153762549161911,
        -0.05046498402953148,
        -0.05282003432512283,
        -0.03519604355096817,
        0.05251175910234451,
        -0.050161316990852356,
        -0.042534954845905304,
        0.007749801501631737,
        0.01707230880856514,
        -0.050816405564546585,
        0.03800442814826965,
        -0.005992908496409655,
        0.05172918364405632,
        -0.04199840873479843,
        -0.04908698797225952,
        -0.05282870680093765,
        0.035248804837465286,
        0.02913053333759308,
        0.05227229371666908,
        0.02589135803282261,
        -0.03836515545845032,
        -0.020623037591576576,
        -0.006021097768098116,
        0.051950857043266296,
        0.031602222472429276,
        -0.0406174510717392,
        -0.051098767668008804,
        0.05267810821533203,
        -0.01121112797409296,
        -0.02602606639266014,
        0.03739529475569725,
        0.052814941853284836,
        -0.051682159304618835,
        -0.048576295375823975,
        0.05176973715424538,
        -0.008884187787771225,
        0.05105798691511154,
        0.049232516437768936,
        0.05184967815876007,
        -0.05252291262149811,
        -0.0503104068338871,
        -0.05263213440775871,
        0.05285830423235893,
        -0.0071478295139968395,
        -0.052742790430784225,
        0.0528004989027977,
        -0.05262637138366699,
        -0.0160360150039196,
        0.050246331840753555,
        -0.04297180101275444,
        0.037003304809331894,
        0.026100724935531616,
        0.052055805921554565,
        0.05092892795801163,
        0.015399791300296783,
        -0.04558501020073891,
        0.04858551174402237,
        -0.05286687612533569,
        -0.05243901163339615,
        -0.0527743324637413,
        -0.052851345390081406,
        -0.05096624046564102,
        -0.05065792053937912,
        -0.04274826869368553,
        0.05234738811850548,
        0.015374291688203812,
        -0.015652643516659737,
        0.041653528809547424,
        0.04330083355307579,
        -0.04374197497963905,
        -0.013394412584602833,
        0.052072688937187195,
        0.007348422892391682,
        0.04574903845787048,
        -0.04916621372103691,
        0.05043882131576538,
        0.005181916058063507,
        -0.01989578828215599,
        0.028229031711816788,
        -0.052853018045425415,
        0.04180837795138359,
        -0.0515543669462204,
        0.0464942529797554,
        0.051450714468955994,
        0.04404555261135101,
        0.04732219874858856,
        -0.014560367912054062,
        0.05281226336956024,
        -0.052830591797828674,
        -0.05236704647541046,
        0.048261187970638275,
        0.052778635174036026,
        -0.04944967105984688,
        -0.03169562667608261,
        0.046582698822021484,
        0.04425261169672012,
        0.050359807908535004,
        -0.003076388733461499,
        -0.052852265536785126,
        -0.05284455418586731,
        0.051988665014505386,
        -0.029250890016555786,
        0.003877995302900672,
        -0.04634002596139908,
        -0.023593628779053688,
        0.051309213042259216,
        -0.05084001645445824,
        -0.01824316754937172,
        0.044790953397750854,
        0.03123856522142887,
        -0.005243572872132063,
        0.010443968698382378,
        -0.049924395978450775,
        0.05181090161204338,
        -0.05261430889368057,
        0.05039738118648529,
        0.04267764836549759,
        -0.05273459106683731,
        0.052711259573698044,
        -0.051454395055770874,
        0.03427305072546005,
        0.05233387276530266,
        0.05280105397105217,
        0.052868664264678955,
        -0.05275040864944458,
        0.0508422777056694,
        0.0010597733780741692,
        0.05286819487810135,
        0.05249376595020294,
        0.05231306701898575,
        0.049655742943286896,
        -0.05258691683411598,
        -0.04033956304192543,
        0.04873613640666008,
        -0.051892101764678955,
        0.05045587569475174,
        0.05084322765469551,
        0.0302916020154953,
        -0.0488959476351738,
        -0.04920317977666855,
        -0.025708574801683426,
        0.028866374865174294,
        0.0469999834895134,
        0.05282801389694214,
        0.052341047674417496,
        0.05216946080327034,
        0.048344794660806656,
        -0.05281120166182518,
        0.05285988003015518,
        0.05159957706928253,
        -0.02797912247478962,
        -0.013765526935458183,
        -0.05286570265889168,
        -0.04362617805600166,
        -0.05148148164153099,
        0.03714149072766304,
        0.025009943172335625,
        -0.04946476221084595,
        -0.04486324265599251,
        -0.039455048739910126,
        -0.030443204566836357,
        0.04966111481189728,
        -0.05159156769514084,
        0.050109561532735825,
        0.052735425531864166,
        0.052591416984796524,
        -0.030435848981142044,
        0.041562940925359726,
        0.04012706130743027,
        0.014271960593760014,
        0.03935955837368965,
        -0.04506857693195343,
        -0.013420290313661098,
        -0.03228994458913803,
        0.05286519601941109,
        0.05279557779431343,
        0.052464600652456284,
        0.02902969717979431,
        -0.042998284101486206,
        -0.04876221716403961,
        -0.051611192524433136,
        0.05286692827939987,
        -0.052480537444353104,
        0.05274989828467369,
        0.04327762871980667,
        -0.04708975926041603,
        -0.048543915152549744,
        0.052671801298856735,
        0.0513940267264843,
        -0.05262397602200508,
        0.019548390060663223,
        -0.004896037746220827,
        0.007147275842726231,
        -0.02174793928861618,
        0.01648356206715107,
        0.04787300527095795,
        -0.008666299283504486,
        -0.05085662007331848,
        0.041068390011787415,
        -0.052852507680654526,
        0.050349168479442596,
        -0.05238950252532959,
        -0.03572370111942291,
        0.05212404206395149,
        -0.05083345249295235,
        0.05261523276567459,
        -0.015409947372972965,
        -0.052712567150592804,
        0.04131999984383583,
        -0.04386676102876663,
        -0.038803767412900925,
        -0.018477756530046463,
        -0.009476698003709316,
        -0.047999102622270584,
        -0.0236857570707798,
        -0.03951236605644226,
        0.052867591381073,
        0.029428984969854355,
        -0.03987615555524826,
        0.04972243309020996,
        0.03686266392469406,
        0.049480125308036804,
        0.024226315319538116,
        -0.017345301806926727,
        -0.05216284841299057,
        -0.03992626816034317,
        0.05286809802055359,
        0.05186520516872406,
        -0.05190972983837128,
        -0.0526338554918766,
        0.0372670516371727,
        0.04436289891600609,
        0.042444199323654175,
        0.05286440998315811,
        0.05114085227251053,
        0.05063766613602638,
        -0.042192138731479645,
        0.047644104808568954,
        0.03499142825603485,
        0.02805284410715103,
        -0.02776435948908329,
        0.052864816039800644,
        0.05193827673792839,
        0.05147021263837814,
        -0.05167875438928604,
        -0.050989922136068344,
        0.03138766065239906,
        -0.03965339437127113,
        0.0504097118973732,
        -0.007669742219150066,
        -0.052810899913311005,
        0.012516484595835209,
        0.04255614057183266,
        0.04940929263830185,
        0.030443260446190834,
        0.04415135085582733,
        0.05186706781387329,
        -0.05264667794108391,
        0.04198808968067169,
        0.05151604115962982,
        0.051832739263772964,
        -0.00656147999688983,
        -0.05230093002319336,
        -0.052776023745536804,
        -0.04638252407312393,
        -0.05286240205168724,
        -0.05165696144104004,
        0.001741221989504993,
        -0.014487162232398987,
        0.045418862253427505,
        0.052086345851421356,
        -0.03278302028775215,
        -0.04813721403479576,
        -0.039103761315345764,
        -0.0522899404168129,
        0.052774012088775635,
        -0.003137352177873254,
        0.052866317331790924,
        -0.05280272662639618,
        -0.04612458124756813,
        0.008709478192031384,
        0.04205426573753357,
        0.01713871769607067,
        0.052435923367738724,
        0.052637793123722076,
        -0.019441068172454834,
        -0.05076538026332855,
        0.05255182087421417,
        0.014070412144064903,
        -0.050815124064683914,
        -0.0410250760614872,
        -0.04037390276789665,
        -0.04728727415204048,
        0.04950157180428505,
        -0.052843574434518814,
        0.04246317595243454,
        -0.042313121259212494,
        -0.012660076841711998,
        -0.052660975605249405,
        -0.05285561829805374,
        -0.03749581426382065,
        -0.051814086735248566,
        -0.02409798465669155,
        0.037968944758176804,
        0.05251091346144676,
        -0.052385926246643066,
        -0.04948876425623894,
        -0.05279827117919922,
        -0.052087441086769104,
        -0.050858478993177414,
        0.05065033212304115,
        -0.03822463005781174,
        -0.02761749178171158,
        0.05196583643555641,
        0.05190391093492508,
        0.051662132143974304,
        0.0493089035153389,
        0.05227579176425934,
        0.025237372145056725,
        -0.01038872916251421,
        -0.04086723551154137,
        -0.04958995804190636,
        0.050755955278873444,
        0.04642327502369881,
        -0.05106596276164055,
        0.0515749417245388,
        0.05092570558190346,
        0.047370508313179016,
        0.05072576552629471,
        0.03390264883637428,
        -0.052816662937402725,
        -0.052618809044361115,
        -0.052863750606775284,
        -0.034466806799173355,
        -0.05078539997339249,
        -0.05273885652422905,
        0.01855592057108879,
        -0.047062527388334274,
        -0.052754711359739304,
        -0.04491646960377693,
        -0.052427712827920914,
        -0.019329987466335297,
        -0.02662714757025242,
        -0.04446474835276604,
        -0.05257413163781166,
        -0.04206381365656853,
        0.05285138636827469,
        -0.028106506913900375,
        -0.01846862956881523,
        0.04339459538459778,
        0.05110644921660423,
        -0.031304918229579926,
        0.04506838321685791,
        -0.011446986347436905,
        -0.016070708632469177,
        0.05167051777243614,
        -0.05248728021979332,
        0.011029107496142387,
        0.05264132097363472,
        0.05281493812799454,
        -0.04367591068148613,
        0.015357652679085732,
        -0.04243253543972969,
        0.05075406655669212,
        -0.0510699525475502,
        -0.0504031740128994,
        -0.04950727894902229,
        -0.04387751221656799,
        0.04785580933094025,
        0.052830446511507034,
        -0.019218597561120987,
        -0.04770490527153015,
        -0.052380822598934174,
        0.052002228796482086,
        0.0528344064950943,
        -0.04011889547109604,
        0.05285246670246124,
        0.05135491117835045,
        -0.05231478437781334,
        0.04854621738195419,
        0.02424975484609604,
        0.052630312740802765,
        -0.05158388987183571,
        -0.042054880410432816,
        0.04243242368102074,
        0.010342059656977654,
        0.011625266633927822,
        -0.03365388885140419,
        -0.037498246878385544,
        -0.04873167723417282,
        -0.04645941033959389,
        0.05255553126335144,
        0.029868286103010178,
        -0.05068011209368706,
        0.042530328035354614,
        0.052721768617630005,
        0.0487859770655632,
        -0.052527982741594315,
        -0.014888630248606205,
        -0.05064134672284126,
        -0.05278347432613373,
        0.05279969051480293,
        -0.00639905221760273,
        -0.05204306170344353,
        0.051683276891708374,
        0.052704133093357086,
        -0.05172588676214218,
        0.04826938733458519,
        0.05221596732735634,
        -0.0526636466383934,
        -0.03124886378645897,
        -0.04801751673221588,
        -0.05281688645482063,
        0.052820682525634766,
        0.03896056488156319,
        0.052816130220890045,
        -0.0357578843832016,
        0.05261651426553726,
        -0.0469106025993824,
        0.044106729328632355,
        0.01763693429529667
    ],
    [
        0.03919622674584389,
        -0.05143441632390022,
        -0.05260889232158661,
        -0.00812005065381527,
        0.010226319544017315,
        0.030451398342847824,
        0.05259377509355545,
        -0.05230552703142166,
        0.0493633858859539,
        -0.052607085555791855,
        0.05252164974808693,
        0.0018838726682588458,
        -0.05260126665234566,
        -0.051262833178043365,
        -0.05252915620803833,
        0.049535688012838364,
        -0.04750087857246399,
        0.05256811901926994,
        -0.015343020670115948,
        -0.0526028610765934,
        -0.039083968847990036,
        -0.05121506378054619,
        0.048383019864559174,
        0.026626866310834885,
        -0.05260472744703293,
        0.05255177617073059,
        -0.04144790768623352,
        0.051777713000774384,
        0.022602111101150513,
        0.052600327879190445,
        -0.04437881335616112,
        -0.05260640010237694,
        -0.03217433765530586,
        -0.052608076483011246,
        0.05160268023610115,
        0.004077593330293894,
        -0.052435263991355896,
        -0.03632663935422897,
        0.05252227187156677,
        -0.02620847523212433,
        0.05122353136539459,
        0.0432889424264431,
        0.05222732201218605,
        -0.04726710915565491,
        0.052606046199798584,
        -0.045750942081213,
        -0.05246866121888161,
        0.05260728672146797,
        0.05254168063402176,
        -0.04487733170390129,
        0.05087985470890999,
        0.04820512607693672,
        -0.04744446277618408,
        -0.05209694430232048,
        -0.052539583295583725,
        0.052594758570194244,
        -0.011387627571821213,
        0.03880573436617851,
        -0.03515538573265076,
        -0.051549848169088364,
        -0.04686153307557106,
        -0.017059069126844406,
        -0.03852761909365654,
        0.000596661411691457,
        -0.05168326571583748,
        -0.05156082659959793,
        -0.05215451493859291,
        -0.05227302759885788,
        0.049258772283792496,
        -0.05261005833745003,
        -0.03723237290978432,
        -0.052551575005054474,
        0.047898296266794205,
        0.052606772631406784,
        0.002310659969225526,
        -0.033789291977882385,
        0.006675856187939644,
        0.05260908976197243,
        -0.05187547951936722,
        -0.0413152314722538,
        -0.01548510417342186,
        -0.05235901474952698,
        -0.0526096411049366,
        0.02875182405114174,
        -0.005258441437035799,
        0.042675089091062546,
        -0.04873037338256836,
        -0.035908643156290054,
        -0.020620934665203094,
        0.0008779746713116765,
        -0.05259586125612259,
        -0.052577465772628784,
        -0.020010044798254967,
        -0.046339813619852066,
        -0.04654128476977348,
        0.017503781244158745,
        -0.05131646245718002,
        0.02855507656931877,
        -0.04855775460600853,
        0.03626406565308571,
        -0.05259754881262779,
        0.006409343797713518,
        0.051352567970752716,
        0.05229515582323074,
        -0.05260894447565079,
        -0.052122168242931366,
        -0.0520017147064209,
        0.021128004416823387,
        -0.009965907782316208,
        -0.03964998200535774,
        -0.05248023942112923,
        -0.020343389362096786,
        0.012597015127539635,
        -0.04706569015979767,
        0.05252021551132202,
        0.011023682542145252,
        -0.0320637971162796,
        -0.052456971257925034,
        0.0295299980789423,
        -0.04598939046263695,
        -0.043307870626449585,
        -0.049486253410577774,
        0.05260901898145676,
        -0.05259939283132553,
        -0.036862049251794815,
        0.052359554916620255,
        0.04810897260904312,
        -0.03736690431833267,
        0.05259796604514122,
        0.04133085533976555,
        0.05260983482003212,
        -0.05225900933146477,
        -0.05257077515125275,
        0.051911503076553345,
        -0.03955589607357979,
        -0.046821631491184235,
        0.0503949373960495,
        -0.03668855130672455,
        -0.048104751855134964,
        0.05250789597630501,
        -0.04150213301181793,
        0.05139176547527313,
        -0.032274454832077026,
        0.05212588608264923,
        -0.0492316298186779,
        -0.05222727358341217,
        -0.0525866262614727,
        -0.03198489174246788,
        -0.03620949015021324,
        0.04749881848692894,
        -0.03553580492734909,
        -0.05253460258245468,
        -0.05096841976046562,
        -0.050735123455524445,
        -0.05036232993006706,
        -0.04738740250468254,
        -0.05080939084291458,
        -0.010511287488043308,
        0.05118017643690109,
        0.049341700971126556,
        -0.030512351542711258,
        0.05171811208128929,
        0.0033308109268546104,
        0.0480927899479866,
        -0.05219345912337303,
        0.03761909902095795,
        0.05095173418521881,
        -0.03234070539474487,
        -0.04848342016339302,
        0.03124171867966652,
        -0.05001659691333771,
        0.04224488139152527,
        -0.05260678380727768,
        -0.04138311371207237,
        -0.05256803706288338,
        0.03177329897880554,
        0.035748738795518875,
        0.05252145603299141,
        0.052520401775836945,
        -0.04138213396072388,
        -0.05202893167734146,
        0.052609995007514954,
        0.05176820233464241,
        -0.04910629987716675,
        0.04275907576084137,
        -0.017140448093414307,
        0.051393769681453705,
        0.051810458302497864,
        -0.03730716556310654,
        0.035934314131736755,
        -0.05259017273783684,
        -0.04681556671857834,
        0.04868408665060997,
        0.052287712693214417,
        -0.03572702035307884,
        -0.05166958272457123,
        0.030008604750037193,
        0.05245690420269966,
        -0.024774981662631035,
        0.04807113856077194,
        -0.03814083710312843,
        0.02678828313946724,
        0.027817852795124054,
        -0.05260711535811424,
        0.02941937930881977,
        -0.05261008441448212,
        0.024502690881490707,
        0.05250655114650726,
        0.05000973865389824,
        0.05257720500230789,
        -0.05136632174253464,
        0.03341265767812729,
        -0.044003453105688095,
        0.052404388785362244,
        0.05162791162729263,
        -0.03143961727619171,
        0.049287665635347366,
        0.00844191201031208,
        0.052582092583179474,
        0.0034275169018656015,
        -0.0411917082965374,
        -0.03920624405145645,
        0.03333050012588501,
        -0.04829847067594528,
        0.050516124814748764,
        -0.012355873361229897,
        0.05011255294084549,
        -0.03338060528039932,
        0.019481103867292404,
        -0.041113000363111496,
        -0.02711024507880211,
        -0.05217907577753067,
        -0.03936215862631798,
        0.0462450347840786,
        0.05078306421637535,
        0.005695133935660124,
        0.04905551299452782,
        0.03988604247570038,
        -0.04871843755245209,
        0.052609771490097046,
        0.05192173272371292,
        0.04003343731164932,
        0.026528166607022285,
        -0.05261008441448212,
        0.036753688007593155,
        0.012466046959161758,
        0.023927273228764534,
        -0.0073579950258135796,
        -0.052485913038253784,
        0.027477959170937538,
        0.016790583729743958,
        -0.0407567098736763,
        0.04243607074022293,
        -0.05126587674021721,
        0.046364475041627884,
        0.05171729996800423,
        -0.008549831807613373,
        -0.015514370985329151,
        0.04884672909975052,
        0.05256037786602974,
        -0.05237337574362755,
        -0.003475467674434185,
        -0.026797864586114883,
        0.03563772886991501,
        0.040223315358161926,
        0.05257675051689148,
        -0.0450860895216465,
        -0.0526040680706501,
        0.052571818232536316,
        0.022453635931015015,
        0.027804646641016006,
        -0.052416324615478516,
        0.052609194070100784,
        -0.05251782387495041,
        -0.0317361019551754,
        0.0491228885948658,
        -0.04736914485692978,
        0.02969100885093212,
        0.051938727498054504,
        -0.0019505862146615982,
        -0.05256491154432297,
        0.02943732589483261,
        0.012848039157688618,
        0.052437812089920044,
        0.0525655671954155,
        0.052608612924814224,
        0.020758509635925293,
        0.04558528587222099,
        -0.05260811373591423,
        -0.05260903015732765,
        -0.05006422474980354,
        0.04704194888472557,
        -0.03247726708650589,
        -0.05064045265316963,
        0.05215480178594589,
        -0.05218631774187088,
        0.052600715309381485,
        0.05107006058096886,
        -0.052279893308877945,
        0.04891057312488556,
        0.0245534498244524,
        0.03450644388794899,
        0.04342815279960632,
        0.048870500177145004,
        0.05257243290543556,
        0.012075502425432205,
        0.03767789900302887,
        0.052609898149967194,
        0.008523943834006786,
        0.051199771463871,
        0.010528729297220707,
        0.007882182486355305,
        0.04359159246087074,
        0.044827889651060104,
        -0.0526055283844471,
        -0.04961482435464859,
        -0.04006234183907509,
        0.05260990560054779,
        0.04979551583528519,
        -0.04914535954594612,
        0.05257580801844597,
        -0.05260936915874481,
        0.05257856473326683,
        0.049763742834329605,
        0.04988407716155052,
        0.05261000618338585,
        0.052596110850572586,
        -0.05237686261534691,
        -0.026675555855035782,
        -0.05246810242533684,
        -0.03954858332872391,
        0.007966257631778717,
        0.05260824039578438,
        0.052053604274988174,
        0.038719162344932556,
        0.05223074555397034,
        -0.009791900403797626,
        0.029710568487644196,
        -0.03005807474255562,
        0.05259308964014053,
        -0.051792435348033905,
        -0.05037127435207367,
        0.05215652287006378,
        0.041686851531267166,
        -0.03664344176650047,
        0.0500103123486042,
        0.05074534937739372,
        0.0396706685423851,
        0.048826057463884354,
        0.05046100541949272,
        -0.05260844528675079,
        0.05256280303001404,
        0.028337668627500534,
        -0.0524771586060524,
        -0.04822596162557602,
        0.013101810589432716,
        -0.05261007323861122,
        -0.052589040249586105,
        -0.03201796114444733,
        -0.05229100212454796,
        0.0372811034321785,
        0.052233096212148666,
        0.0390036515891552,
        -0.05172760784626007,
        -0.05259455740451813,
        -0.052026376128196716,
        0.05257047340273857,
        0.026546640321612358,
        -0.05085506662726402,
        -0.05254703015089035,
        -0.047745879739522934,
        -0.04074278101325035,
        -0.04236564040184021,
        0.044979508966207504,
        0.047383133322000504,
        -0.020675145089626312,
        0.04817477613687515,
        0.05191939324140549,
        0.052598245441913605,
        -0.052392080426216125,
        -0.051192499697208405,
        0.048405859619379044,
        -0.05196534842252731,
        -0.05196641385555267,
        0.046677153557538986,
        -0.052574750036001205,
        -0.0373789481818676,
        0.048652321100234985,
        -0.037477340549230576,
        -0.05260516703128815,
        -0.05258716642856598,
        0.04181423410773277,
        -0.03984808921813965,
        -0.05161311849951744,
        0.015993919223546982,
        0.05220823734998703,
        0.05186210572719574,
        -0.04734314978122711,
        -0.052494537085294724,
        -0.05259314924478531,
        -0.0518822968006134,
        0.05256006121635437,
        0.021729204803705215,
        -0.05148954316973686,
        0.022279631346464157,
        0.050740621984004974,
        0.04579756036400795,
        -0.05084928125143051,
        0.051854077726602554,
        -0.049956709146499634,
        0.04700970649719238,
        -0.0491953007876873,
        -0.05063968524336815,
        0.02743099443614483,
        0.0391673669219017,
        -0.050063349306583405,
        0.04611700028181076,
        -0.0526096411049366,
        0.030869631096720695,
        0.03273126110434532,
        0.05258326232433319,
        -0.05188683420419693,
        0.04904942587018013,
        -0.052603159099817276,
        -0.03180355951189995,
        0.030078155919909477,
        0.05073939636349678,
        0.049391765147447586,
        -0.052218176424503326,
        -0.05253482982516289,
        0.03155457228422165,
        -0.01240089163184166,
        -0.052393246442079544,
        0.039681676775217056,
        -0.051602620631456375,
        -0.012952471151947975,
        -0.051915548741817474,
        0.05259280279278755,
        -0.02018447406589985,
        -0.05141337588429451,
        -0.052120670676231384,
        0.03743570297956467,
        0.03602811321616173,
        0.04452047124505043,
        -0.03793594613671303,
        0.04799992963671684,
        0.05129583552479744,
        -0.05251558870077133,
        0.04065162315964699,
        0.05232591554522514,
        -0.05260337516665459,
        0.05072773993015289,
        -0.03426571562886238,
        0.03605039045214653,
        -0.050169479101896286,
        -0.04889272153377533,
        0.011373654007911682,
        -0.046068158000707626,
        -0.018580740317702293,
        0.052564144134521484,
        0.03878210857510567,
        0.04316471144556999,
        -0.02987348474562168,
        0.009623329155147076,
        0.05211951583623886,
        0.05256317928433418,
        -0.043926600366830826,
        0.052594635635614395,
        0.05259985104203224,
        -0.052286166697740555,
        0.05208946019411087,
        0.0525028258562088,
        0.052432600408792496,
        -0.006036567036062479,
        -0.052484117448329926,
        -0.0200375746935606,
        -0.03562299534678459,
        0.05179355666041374,
        -0.05095149204134941,
        -0.05203961953520775,
        -0.05255299061536789,
        0.038347985595464706,
        0.04862912744283676,
        0.038215722888708115,
        0.04643584042787552,
        0.05152170732617378,
        -0.016975197941064835,
        0.03304588422179222,
        -0.05240124464035034,
        0.040760111063718796,
        -0.05248844251036644,
        -0.05260825157165527,
        0.030261926352977753,
        0.009299760684370995,
        0.01287118997424841,
        0.02168729528784752,
        -0.05198925733566284,
        0.03299158811569214,
        0.04106997698545456,
        0.04460955038666725,
        -0.031035229563713074,
        0.052024420350790024,
        -0.051802970468997955,
        0.04177629575133324,
        -0.05207274481654167,
        0.05186864733695984,
        0.05260841175913811,
        -0.03530928120017052,
        0.05230257660150528,
        -0.026226205751299858,
        0.0460503064095974,
        -0.05249091237783432
    ],
    [
        -0.05267827957868576,
        -0.005968112964183092,
        -0.011943562887609005,
        -0.0019217492081224918,
        0.05427493900060654,
        -0.012410447001457214,
        0.05597817152738571,
        -0.03379368036985397,
        0.041187189519405365,
        -0.006918813567608595,
        -0.022383714094758034,
        0.05459761247038841,
        -0.05544695630669594,
        -0.04250968247652054,
        -0.055393025279045105,
        0.05498689413070679,
        -0.05394070968031883,
        0.05135124921798706,
        -0.05593971908092499,
        -0.011006365530192852,
        -0.005544454324990511,
        -0.023633887991309166,
        0.03502088412642479,
        0.040430206805467606,
        -0.036308806389570236,
        -0.009638517163693905,
        0.055419571697711945,
        0.052242569625377655,
        -0.04789835959672928,
        0.055960606783628464,
        0.04696241021156311,
        -0.016452407464385033,
        -0.027328316122293472,
        -0.05581245943903923,
        -0.028383180499076843,
        -0.012415475212037563,
        -0.05198054760694504,
        -0.05439532548189163,
        -0.053988657891750336,
        0.055878836661577225,
        0.031223559752106667,
        -0.027734417468309402,
        0.05452555790543556,
        -0.03299427404999733,
        0.0505036786198616,
        -0.0345349982380867,
        0.050361089408397675,
        0.05586646497249603,
        0.055828820914030075,
        -0.005965609103441238,
        0.04076968878507614,
        0.03432203829288483,
        0.055134475231170654,
        0.04253062978386879,
        0.03352171555161476,
        -0.03494158014655113,
        0.013914881274104118,
        -0.04809987172484398,
        0.037055131047964096,
        0.041513219475746155,
        -0.0006247024866752326,
        -0.035626284778118134,
        -0.05418996885418892,
        0.04593546316027641,
        -0.03698322921991348,
        0.04721137136220932,
        0.04750484228134155,
        -0.04877445474267006,
        0.04858928918838501,
        -0.05598584935069084,
        -0.05446033924818039,
        -0.0558432899415493,
        0.02757268399000168,
        0.05534718185663223,
        -0.05510977655649185,
        -0.02937008999288082,
        0.04275822639465332,
        0.05237424373626709,
        0.05416461080312729,
        -0.04780008643865585,
        -0.044774118810892105,
        0.05473354086279869,
        -0.04992130771279335,
        0.045033376663923264,
        -0.05045131966471672,
        0.042475081980228424,
        0.03664232790470123,
        0.03306140750646591,
        0.03641797602176666,
        0.055822860449552536,
        -0.055114805698394775,
        -0.028346266597509384,
        -0.005573485977947712,
        -0.04685204476118088,
        -0.03319774568080902,
        0.025000013411045074,
        -0.02609407715499401,
        -0.04676289111375809,
        -0.029823297634720802,
        -0.018885912373661995,
        -0.05596733093261719,
        -0.0018140844767913222,
        0.055924128741025925,
        0.0555269755423069,
        0.034132473170757294,
        0.012270285747945309,
        -0.054642755538225174,
        0.01598212867975235,
        0.04409929737448692,
        -0.05211874470114708,
        -0.04281356558203697,
        -0.0002605028566904366,
        0.03112896904349327,
        -0.050495341420173645,
        0.027881748974323273,
        -0.05315755307674408,
        0.050371430814266205,
        -0.05492270737886429,
        -0.055797699838876724,
        0.0023196812253445387,
        -0.022052373737096786,
        -0.009022627957165241,
        -0.04897039756178856,
        0.0266936756670475,
        -0.0556974895298481,
        -0.023701785132288933,
        -0.02815263159573078,
        0.052217938005924225,
        0.053089018911123276,
        0.01870879903435707,
        0.04650456830859184,
        -0.043694447726011276,
        -0.055909786373376846,
        0.0409456342458725,
        -0.05491650104522705,
        -0.052423346787691116,
        0.05455370247364044,
        -0.055916570127010345,
        -0.049284305423498154,
        0.05335014685988426,
        0.05528949946165085,
        0.05541544035077095,
        0.05249253287911415,
        0.05400265008211136,
        -0.0039210538379848,
        0.03807482868432999,
        -0.017376769334077835,
        -0.0540245845913887,
        0.0151700833812356,
        0.05549974739551544,
        -0.027552751824259758,
        -0.05595032498240471,
        -0.05582220107316971,
        -0.03546658903360367,
        -0.006710993591696024,
        -0.05521427467465401,
        0.05597137659788132,
        -0.02718987502157688,
        -0.03230561688542366,
        0.036283012479543686,
        0.055038828402757645,
        0.04902724176645279,
        0.04580317810177803,
        -0.05006086826324463,
        -0.03242867439985275,
        0.018740393221378326,
        -0.00810304470360279,
        -0.05104319006204605,
        -0.03668641299009323,
        0.03736018016934395,
        -0.05422479659318924,
        0.03677228093147278,
        -0.049579422920942307,
        -0.031088553369045258,
        -0.05469566583633423,
        0.0551423653960228,
        -0.05598575249314308,
        0.013534904457628727,
        0.023676060140132904,
        0.010135340504348278,
        0.044097740203142166,
        0.027381785213947296,
        0.05422735586762428,
        0.05541834980249405,
        0.00570750143378973,
        0.01958373561501503,
        -0.05520239099860191,
        0.051946669816970825,
        -0.054313864558935165,
        -0.05340594798326492,
        -0.031535204499959946,
        -0.054598208516836166,
        0.015053780749440193,
        0.023639308288693428,
        0.015043545514345169,
        -0.04994320124387741,
        0.04521467164158821,
        0.04600592702627182,
        -0.024814268574118614,
        0.022305015474557877,
        0.04763203114271164,
        0.05140974000096321,
        -0.009091074578464031,
        0.05517841503024101,
        0.05551236495375633,
        0.043947648257017136,
        0.015871629118919373,
        0.0429755263030529,
        -0.05535763502120972,
        -0.05590325593948364,
        -0.05548465624451637,
        0.030559148639440536,
        -0.05227350443601608,
        -0.003599301679059863,
        0.0533929169178009,
        -0.05131172016263008,
        0.05598573386669159,
        0.049761369824409485,
        0.055982545018196106,
        -0.052537642419338226,
        0.0033949522767215967,
        0.03270750120282173,
        -0.05437988415360451,
        -0.05593042075634003,
        0.05385756492614746,
        -0.05594034865498543,
        0.006190349347889423,
        0.027543561533093452,
        0.030906079337000847,
        -0.04729203134775162,
        0.021863793954253197,
        -0.0559251569211483,
        -0.04007735848426819,
        0.04903510585427284,
        0.022675679996609688,
        -0.040225476026535034,
        0.052367087453603745,
        0.03928041458129883,
        0.04374198988080025,
        0.05579288676381111,
        0.05584575608372688,
        0.036440409719944,
        -0.050020355731248856,
        -0.04209528863430023,
        -0.011798463761806488,
        0.003664585994556546,
        0.04955511912703514,
        -0.03423171490430832,
        -0.054573480039834976,
        -0.020393546670675278,
        -0.05100342258810997,
        -0.05533647537231445,
        0.016982870176434517,
        -0.054263886064291,
        0.0026333099231123924,
        0.055958449840545654,
        0.05010315403342247,
        0.03793511539697647,
        0.05589251592755318,
        0.05457036942243576,
        0.05577777698636055,
        0.054342132061719894,
        0.04956253245472908,
        0.050805702805519104,
        -0.043118201196193695,
        0.0448322631418705,
        0.055893220007419586,
        0.023190941661596298,
        0.04556475207209587,
        0.005782419815659523,
        -0.04380068555474281,
        -0.05597759410738945,
        0.04903881996870041,
        -0.05584798380732536,
        -0.013177168555557728,
        0.04030917212367058,
        -0.05375584587454796,
        0.02418140135705471,
        -0.05338827148079872,
        -0.05565826967358589,
        -0.055682502686977386,
        -0.05331704020500183,
        0.03591287508606911,
        0.05305265262722969,
        0.02651051990687847,
        -0.038766056299209595,
        0.03735528513789177,
        -0.055979304015636444,
        -0.05069434270262718,
        0.015016794204711914,
        -0.05167379975318909,
        0.05359087884426117,
        0.019067173823714256,
        0.006132153794169426,
        0.006379039958119392,
        -0.05365804210305214,
        0.05597657710313797,
        0.055606938898563385,
        0.046587537974119186,
        0.05483010411262512,
        0.018788473680615425,
        0.05514128506183624,
        -0.015013735741376877,
        0.027219412848353386,
        -0.04846694692969322,
        -0.05415575951337814,
        -0.051034215837717056,
        0.055421389639377594,
        0.053419653326272964,
        -0.05586228519678116,
        -0.049589142203330994,
        -0.05441446229815483,
        -0.02968597784638405,
        0.05571511387825012,
        -0.055711984634399414,
        -0.03991272300481796,
        0.045786768198013306,
        0.048106592148542404,
        0.03436294198036194,
        -0.052932124584913254,
        0.012415122240781784,
        -0.034610316157341,
        0.05550194904208183,
        -0.011124537326395512,
        0.030254235491156578,
        0.05137716978788376,
        0.055034883320331573,
        0.05077993497252464,
        0.014658249914646149,
        -0.04172550514340401,
        -0.020864827558398247,
        -0.001521160826086998,
        0.054129503667354584,
        -0.047341104596853256,
        0.05456826090812683,
        -0.03777216747403145,
        -0.054125841706991196,
        0.05571969226002693,
        0.028058931231498718,
        0.055834874510765076,
        0.046886514872312546,
        -0.04304439201951027,
        0.01999589428305626,
        -0.04475047066807747,
        0.05598587170243263,
        0.030706755816936493,
        0.042001962661743164,
        0.049661967903375626,
        0.014596747234463692,
        0.054783739149570465,
        -0.055984579026699066,
        0.041809290647506714,
        -0.03256279602646828,
        -0.05562391132116318,
        -0.05325539410114288,
        0.05220034345984459,
        -0.05589118227362633,
        -0.05018537864089012,
        -0.05521637201309204,
        0.022231174632906914,
        0.05399408936500549,
        0.054805465042591095,
        -0.05575093626976013,
        -0.04553096741437912,
        -0.0520484559237957,
        -0.05482632294297218,
        0.055978771299123764,
        0.034470222890377045,
        0.050650935620069504,
        -0.05596837028861046,
        -0.054074518382549286,
        -0.05596470460295677,
        0.041421182453632355,
        -0.01836531236767769,
        0.054671283811330795,
        0.05592562258243561,
        0.043317731469869614,
        0.05426531657576561,
        -0.023283744230866432,
        -0.05557608604431152,
        -0.026818977668881416,
        0.005033177323639393,
        -0.05442140996456146,
        0.025383519008755684,
        0.05540185794234276,
        -0.05514383688569069,
        0.055568646639585495,
        -0.055066581815481186,
        -0.0526774525642395,
        -0.05487191677093506,
        -0.0556398406624794,
        0.0158219076693058,
        0.05346214398741722,
        -0.00945308431982994,
        -0.055686380714178085,
        0.05514424666762352,
        0.05596239119768143,
        -0.0393853560090065,
        -0.05596042796969414,
        -0.055710554122924805,
        -0.048806704580783844,
        0.03355889394879341,
        0.054301969707012177,
        -0.008360505104064941,
        -0.04379698634147644,
        0.05091923475265503,
        0.05568452924489975,
        -0.005403509363532066,
        0.05538291856646538,
        -0.039312999695539474,
        0.052624840289354324,
        -0.04454541951417923,
        -0.05437013879418373,
        0.055589135736227036,
        0.04783810302615166,
        -0.0106034055352211,
        -0.031043317168951035,
        0.05053400248289108,
        0.021806912496685982,
        0.026003047823905945,
        -0.04692081734538078,
        0.03791600465774536,
        -0.0553121343255043,
        -0.05588691681623459,
        0.052998851984739304,
        -0.050208985805511475,
        -0.03380352631211281,
        -0.016760649159550667,
        -0.055941879749298096,
        -0.055480338633060455,
        0.05181163176894188,
        -0.05408041551709175,
        0.006024912931025028,
        0.0442185141146183,
        0.04204532131552696,
        0.00008353990415344015,
        -0.04778657481074333,
        0.05392548441886902,
        0.04207473620772362,
        -0.046372558921575546,
        0.043624185025691986,
        0.045660123229026794,
        0.025138869881629944,
        0.048370327800512314,
        -0.017179841175675392,
        0.0509522445499897,
        0.052360981702804565,
        -0.0559549480676651,
        0.05335669219493866,
        -0.05427879840135574,
        -0.033625341951847076,
        0.05543270707130432,
        0.03151223808526993,
        -0.04227358102798462,
        0.04036877304315567,
        -0.031643208116292953,
        -0.02397116646170616,
        0.0038186514284461737,
        -0.05595141649246216,
        0.04121321812272072,
        0.03860929608345032,
        0.04466153308749199,
        -0.05597994476556778,
        0.054402612149715424,
        0.055083390325307846,
        0.05592311546206474,
        -0.05598033219575882,
        0.05592743307352066,
        0.051707055419683456,
        -0.05540986359119415,
        0.055423103272914886,
        0.05406436696648598,
        0.012932137586176395,
        0.025545954704284668,
        -0.010975096374750137,
        0.04678434133529663,
        -0.019817236810922623,
        -0.02687690034508705,
        -0.05349711328744888,
        -0.055352382361888885,
        -0.04730185866355896,
        0.007379756774753332,
        0.05425183102488518,
        0.04013754427433014,
        0.017040245234966278,
        0.053169332444667816,
        0.05336976423859596,
        -0.037951402366161346,
        -0.05145612359046936,
        -0.03701753169298172,
        -0.049652986228466034,
        -0.05555735528469086,
        0.02209615893661976,
        0.05536744371056557,
        0.05513419955968857,
        0.05358457192778587,
        -0.046637676656246185,
        -0.03827142342925072,
        0.05523485317826271,
        0.05596259981393814,
        -0.05515531450510025,
        0.02061527781188488,
        -0.007770464289933443,
        -0.04056861624121666,
        0.04584940895438194,
        0.012499446049332619,
        0.02295989915728569,
        0.01538246963173151,
        -0.05170067399740219,
        -0.03065107762813568,
        0.05597882717847824,
        0.05597783997654915
    ],
    [
        -0.0003649352875072509,
        0.03913181275129318,
        0.053903546184301376,
        -0.02165265567600727,
        -0.048730768263339996,
        0.044896893203258514,
        0.049189239740371704,
        -0.05378780514001846,
        0.05260095372796059,
        -0.014138882048428059,
        0.051978856325149536,
        -0.049077942967414856,
        -0.05214006081223488,
        -0.05380461364984512,
        -0.052707575261592865,
        -0.020575784146785736,
        -0.050749991089105606,
        0.05325140431523323,
        -0.010031410492956638,
        -0.053650498390197754,
        0.053820643573999405,
        0.05390506982803345,
        0.053379904478788376,
        0.05385249853134155,
        -0.03497852012515068,
        -0.04584958776831627,
        -0.04969660937786102,
        0.05045192316174507,
        -0.022511344403028488,
        0.053851231932640076,
        0.053482431918382645,
        -0.05390874296426773,
        0.044812217354774475,
        -0.05029110983014107,
        0.05355057865381241,
        -0.04253587871789932,
        -0.05206482112407684,
        0.0493241585791111,
        0.05380614846944809,
        0.044189900159835815,
        0.03500894084572792,
        0.04556860774755478,
        0.0486622154712677,
        0.05278632417321205,
        -0.029982060194015503,
        -0.028400614857673645,
        0.05307672917842865,
        0.053814832121133804,
        0.05387264862656593,
        0.031823500990867615,
        0.05352835729718208,
        0.041697315871715546,
        -0.037957947701215744,
        -0.04590120166540146,
        0.05358690395951271,
        0.04587087407708168,
        0.05067669600248337,
        -0.0035331682302057743,
        -0.03411784768104553,
        -0.05185354873538017,
        -0.053476136177778244,
        0.024943441152572632,
        -0.046821609139442444,
        -0.01925785094499588,
        -0.052927907556295395,
        -0.023057110607624054,
        -0.05382608622312546,
        -0.053422871977090836,
        0.0522625632584095,
        -0.00849394965916872,
        -0.05171533674001694,
        -0.05217717960476875,
        0.0466938279569149,
        0.04574159160256386,
        -0.053371649235486984,
        -0.050236694514751434,
        -0.0440840907394886,
        0.05391278862953186,
        -0.025550926104187965,
        -0.012887590564787388,
        -0.04808490723371506,
        -0.038780294358730316,
        -0.05236036330461502,
        -0.04833174869418144,
        -0.015023951418697834,
        0.051557838916778564,
        -0.05352222919464111,
        -0.05342322215437889,
        -0.017544159665703773,
        -0.0019041369669139385,
        -0.0533347949385643,
        -0.03170337900519371,
        -0.042298439890146255,
        -0.05302349478006363,
        -0.05324956402182579,
        0.053470127284526825,
        -0.027959564700722694,
        -0.05191603675484657,
        0.051845699548721313,
        0.051298972219228745,
        -0.05386513099074364,
        0.028897855430841446,
        -0.02074236236512661,
        0.03693356364965439,
        0.037685226649045944,
        -0.04654160887002945,
        0.05304493010044098,
        0.01895645447075367,
        -0.052462149411439896,
        0.04726191610097885,
        0.04276445135474205,
        -0.05068514496088028,
        0.012940025888383389,
        -0.04040584713220596,
        -0.04307405278086662,
        0.03426416218280792,
        -0.01831645518541336,
        -0.05045178532600403,
        0.027529891580343246,
        -0.007621265482157469,
        0.044203296303749084,
        -0.027914293110370636,
        -0.048302192240953445,
        -0.05222119763493538,
        -0.048656050115823746,
        0.0518992617726326,
        -0.052208274602890015,
        0.05155828222632408,
        -0.0053504835814237595,
        0.0004007400420960039,
        0.033166881650686264,
        -0.04868509992957115,
        0.03673712909221649,
        0.053598660975694656,
        -0.030975688248872757,
        -0.053369905799627304,
        0.05373765155673027,
        -0.052466362714767456,
        0.031185559928417206,
        -0.0312686562538147,
        -0.05316740274429321,
        -0.04425908997654915,
        0.05215217545628548,
        0.03707720339298248,
        -0.050374068319797516,
        -0.053690262138843536,
        -0.014126415364444256,
        0.05284259468317032,
        -0.0538913831114769,
        0.050439927726984024,
        0.05351455509662628,
        -0.05265677720308304,
        -0.049708981066942215,
        -0.023153208196163177,
        0.05391136184334755,
        -0.02047917991876602,
        0.05312982201576233,
        -0.02105078659951687,
        0.05219539627432823,
        0.050595879554748535,
        -0.009071494452655315,
        0.0017369332490488887,
        0.052957676351070404,
        -0.029813088476657867,
        0.02116534486413002,
        0.04575400426983833,
        0.05358831584453583,
        -0.006704281084239483,
        0.02406393177807331,
        0.04416278004646301,
        -0.053360242396593094,
        -0.0527835413813591,
        -0.05387687683105469,
        -0.0127848656848073,
        0.052778925746679306,
        0.053028032183647156,
        0.01482713595032692,
        0.044080402702093124,
        0.053803324699401855,
        -0.032260697335004807,
        -0.03691183403134346,
        -0.034049708396196365,
        0.043886490166187286,
        -0.03859413415193558,
        0.05135269835591316,
        -0.05116569623351097,
        0.053846441209316254,
        0.05194292590022087,
        -0.05110648274421692,
        -0.03171209618449211,
        -0.05352004989981651,
        -0.004782737232744694,
        -0.03757889196276665,
        0.034809451550245285,
        -0.05245616286993027,
        -0.05321691930294037,
        -0.04734073206782341,
        0.039584774523973465,
        -0.050915975123643875,
        0.04880543798208237,
        0.053014617413282394,
        0.05109081789851189,
        -0.00576788280159235,
        -0.05374353006482124,
        0.05354450270533562,
        -0.0016053540166467428,
        0.01131243072450161,
        -0.03806585073471069,
        -0.05063912644982338,
        0.049389686435461044,
        -0.05085880681872368,
        0.04806823655962944,
        -0.00079262227518484,
        0.04855334758758545,
        -0.04010005667805672,
        0.004201535135507584,
        0.02292163297533989,
        0.005239271093159914,
        0.053774572908878326,
        0.028541671112179756,
        -0.04511672630906105,
        -0.04005442559719086,
        0.04252251610159874,
        0.027784103527665138,
        0.0537407211959362,
        0.03966161236166954,
        0.02475038357079029,
        -0.04540994390845299,
        0.049125246703624725,
        -0.051277004182338715,
        -0.053583886474370956,
        -0.05247034132480621,
        0.0534500814974308,
        0.040712159126996994,
        -0.05189603567123413,
        0.053727373480796814,
        0.047556858509778976,
        -0.04694339260458946,
        0.053838226944208145,
        0.053912416100502014,
        0.04633510485291481,
        0.046781521290540695,
        -0.03362225741147995,
        -0.05239478126168251,
        -0.048510678112506866,
        -0.05387265980243683,
        0.05032700300216675,
        -0.01716466434299946,
        -0.05367620661854744,
        -0.0352485328912735,
        -0.022861327975988388,
        -0.05307215452194214,
        0.052438266575336456,
        -0.053184740245342255,
        0.04965517669916153,
        0.038296833634376526,
        0.05364605411887169,
        -0.01694049872457981,
        0.05295960605144501,
        -0.030022289603948593,
        -0.05353802442550659,
        0.011549345217645168,
        -0.009535248391330242,
        0.05330323055386543,
        0.049789417535066605,
        0.05388902127742767,
        0.0504940003156662,
        0.05343934893608093,
        0.053157929331064224,
        0.016267098486423492,
        0.021114563569426537,
        -0.05237719416618347,
        0.05378391221165657,
        -0.053840190172195435,
        0.03808734938502312,
        0.026138123124837875,
        -0.05245097726583481,
        -0.05177629366517067,
        -0.05391287803649902,
        -0.024712327867746353,
        -0.05351686477661133,
        0.05167622119188309,
        -0.03396819159388542,
        0.03737139701843262,
        0.019593384116888046,
        0.026021413505077362,
        -0.018860507756471634,
        0.045825060456991196,
        0.050151750445365906,
        0.04165136441588402,
        -0.05379028990864754,
        0.041627176105976105,
        -0.04171079769730568,
        -0.03769255802035332,
        0.029104124754667282,
        -0.05115601047873497,
        0.05383642390370369,
        0.03558002784848213,
        -0.030824555084109306,
        0.050702765583992004,
        -0.034800078719854355,
        -0.013057788833975792,
        -0.014862691052258015,
        0.03773511201143265,
        -0.049076665192842484,
        -0.047982506453990936,
        -0.032189760357141495,
        0.05385740473866463,
        0.035237353295087814,
        -0.02033173479139805,
        0.035281550139188766,
        0.034113556146621704,
        -0.04845834895968437,
        0.053707607090473175,
        -0.027779553085565567,
        -0.05350076034665108,
        -0.04842773824930191,
        0.05391240492463112,
        0.05323372781276703,
        0.04227740317583084,
        0.01814650371670723,
        -0.038836050778627396,
        0.04832184314727783,
        -0.04404613375663757,
        0.04922221601009369,
        0.05010040104389191,
        0.026775823906064034,
        0.05356641486287117,
        0.04344681650400162,
        -0.052358925342559814,
        0.05382329970598221,
        -0.053600411862134933,
        0.04989355057477951,
        0.05391282960772514,
        0.05300736054778099,
        0.05378954857587814,
        -0.05366988107562065,
        0.021564023569226265,
        -0.024966580793261528,
        0.044379327446222305,
        -0.049992579966783524,
        -0.053651515394449234,
        0.038759030401706696,
        0.01601613499224186,
        -0.03557110205292702,
        0.042378924787044525,
        0.053880538791418076,
        0.053640518337488174,
        -0.053730547428131104,
        0.04180369898676872,
        0.05315865948796272,
        0.010657986626029015,
        0.003661510068923235,
        -0.05319857597351074,
        -0.053231626749038696,
        0.042967647314071655,
        -0.052864037454128265,
        -0.053594596683979034,
        -0.0530979298055172,
        -0.04975471273064613,
        0.041506651788949966,
        0.024983355775475502,
        0.03824855014681816,
        -0.05154068395495415,
        -0.05237497389316559,
        -0.05319063737988472,
        0.053755782544612885,
        0.03124753199517727,
        -0.04177854582667351,
        -0.05034857243299484,
        -0.035018108785152435,
        -0.052595820277929306,
        0.04946519061923027,
        0.0201267097145319,
        0.04932760074734688,
        0.05391291528940201,
        -0.0008259243913926184,
        0.05353696644306183,
        0.05384385958313942,
        -0.052001893520355225,
        -0.03457220271229744,
        -0.026216179132461548,
        -0.05137099698185921,
        -0.041991133242845535,
        0.04930602386593819,
        -0.05304969102144241,
        0.00008781123324297369,
        -0.008215370588004589,
        -0.05195310711860657,
        -0.04379313066601753,
        -0.05299820005893707,
        0.052996646612882614,
        -0.05290564149618149,
        -0.053842924535274506,
        0.03737609088420868,
        0.053683340549468994,
        -0.01746814325451851,
        -0.044040024280548096,
        -0.0536516010761261,
        -0.04450523108243942,
        -0.05084989219903946,
        0.05368681624531746,
        -0.053494904190301895,
        0.03285522013902664,
        0.04491840675473213,
        -0.03577924519777298,
        0.04033978283405304,
        -0.052946437150239944,
        0.048084404319524765,
        -0.018190722912549973,
        -0.05301325023174286,
        0.02404756471514702,
        -0.04857984557747841,
        0.03208158537745476,
        0.05322614312171936,
        -0.04990747943520546,
        -0.035787444561719894,
        0.05373283103108406,
        0.053062137216329575,
        0.05091065913438797,
        0.05384919419884682,
        -0.05381511524319649,
        -0.043571025133132935,
        -0.05330641567707062,
        -0.05028726905584335,
        0.0011734513100236654,
        -0.03933337330818176,
        -0.05073162168264389,
        -0.0492735393345356,
        -0.05388149619102478,
        0.05294308438897133,
        -0.051075421273708344,
        0.032370343804359436,
        0.051980603486299515,
        0.03780364245176315,
        -0.05277132987976074,
        0.006511298939585686,
        0.05294090136885643,
        -0.011476282961666584,
        -0.043423235416412354,
        -0.03153303265571594,
        0.04015527293086052,
        0.05314062908291817,
        0.05376012623310089,
        -0.03932952880859375,
        -0.050613630563020706,
        0.05371773988008499,
        -0.05351068452000618,
        0.0369597002863884,
        0.05110043287277222,
        -0.04337676241993904,
        0.05117490515112877,
        0.045137908309698105,
        -0.0424136146903038,
        0.04588591307401657,
        -0.05338611081242561,
        -0.0358242392539978,
        -0.053278014063835144,
        0.047654662281274796,
        0.009564804844558239,
        0.04077668488025665,
        0.051272787153720856,
        -0.004394591320306063,
        -0.0040203118696808815,
        0.05288693308830261,
        0.052928756922483444,
        -0.04396948218345642,
        0.053811367601156235,
        -0.03406883031129837,
        -0.052855875343084335,
        0.051116589456796646,
        -0.027676017954945564,
        0.04690255597233772,
        0.0538971871137619,
        -0.003319693962112069,
        0.028239205479621887,
        -0.02771722339093685,
        0.05069289728999138,
        -0.05139701068401337,
        -0.05141592398285866,
        0.05382360517978668,
        0.029862180352211,
        -0.026585301384329796,
        0.008990333415567875,
        -0.030821355059742928,
        0.04927229508757591,
        0.04621998965740204,
        0.04858773574233055,
        -0.052979111671447754,
        -0.04447284713387489,
        0.05321705713868141,
        0.03413955122232437,
        0.04728519171476364,
        0.04974422976374626,
        -0.047469113022089005,
        0.025869518518447876,
        0.03073398768901825,
        -0.052233800292015076,
        0.04392087087035179,
        0.052172016352415085,
        -0.048512052744627,
        0.0512973815202713,
        0.030610566958785057,
        -0.05304402485489845,
        0.05105483531951904,
        0.011147995479404926,
        0.05331389605998993,
        -0.05148519575595856,
        0.05384751036763191,
        -0.04390382394194603,
        0.039640046656131744,
        -0.007265408989042044
    ],
    [
        -0.05017935857176781,
        0.04807991534471512,
        0.05197968706488609,
        0.005238115321844816,
        -0.050317682325839996,
        0.05230940133333206,
        0.05206063389778137,
        -0.05232573673129082,
        0.05078953877091408,
        0.05089931935071945,
        0.05232002213597298,
        0.04741336405277252,
        -0.05222431570291519,
        -0.03963151574134827,
        -0.048219580203294754,
        0.052260372787714005,
        0.033429864794015884,
        0.05230366066098213,
        -0.04245559871196747,
        0.04033508524298668,
        -0.03713636472821236,
        0.051996201276779175,
        -0.012074151076376438,
        0.05148698762059212,
        0.036558669060468674,
        0.050880081951618195,
        -0.01223036739975214,
        0.05209651216864586,
        -0.05217086151242256,
        -0.04089559242129326,
        0.03863523527979851,
        -0.04118853807449341,
        0.052075423300266266,
        -0.05217689648270607,
        0.05062459781765938,
        0.0431557334959507,
        -0.051568128168582916,
        -0.020255722105503082,
        0.05183214321732521,
        -0.011511089280247688,
        -0.007514263037592173,
        0.04914918914437294,
        0.0507938526570797,
        -0.0520247146487236,
        0.04674365371465683,
        0.020794961601495743,
        0.04182737320661545,
        0.05233212187886238,
        0.0522790402173996,
        -0.05167997628450394,
        0.0519845113158226,
        0.051749009639024734,
        0.02679278701543808,
        -0.04756297171115875,
        0.05233481526374817,
        -0.05217500403523445,
        0.05016898736357689,
        -0.02749006450176239,
        -0.03159266337752342,
        -0.051504988223314285,
        -0.05227009952068329,
        0.030795902013778687,
        -0.04978473111987114,
        -0.04879388213157654,
        0.010959753766655922,
        0.002366483211517334,
        -0.052323903888463974,
        0.03414915129542351,
        0.052332937717437744,
        -0.052332352846860886,
        0.05045575648546219,
        -0.050483353435993195,
        0.05232866108417511,
        0.0489744208753109,
        -0.04970676451921463,
        -0.028922371566295624,
        0.05095113441348076,
        0.05233524367213249,
        0.05230501666665077,
        0.04132169112563133,
        -0.046208761632442474,
        -0.047171179205179214,
        -0.05231619253754616,
        0.04341568797826767,
        0.03471475467085838,
        0.041139110922813416,
        -0.022845281288027763,
        0.034585632383823395,
        -0.04875945299863815,
        0.05106991529464722,
        -0.05102517083287239,
        -0.04841441288590431,
        -0.04909466952085495,
        -0.050675585865974426,
        -0.042170800268650055,
        0.05223303660750389,
        0.006122840102761984,
        -0.048304591327905655,
        -0.049987684935331345,
        0.051858752965927124,
        -0.052320655435323715,
        0.052334628999233246,
        0.04972785338759422,
        0.035476405173540115,
        -0.020779019221663475,
        -0.04752834141254425,
        -0.05178326368331909,
        0.0488528311252594,
        0.022717265412211418,
        0.0521412156522274,
        0.0030611511319875717,
        -0.021338149905204773,
        0.036728352308273315,
        -0.039498548954725266,
        0.052293453365564346,
        0.05227706581354141,
        -0.048221420496702194,
        -0.05186137929558754,
        0.0031817362178117037,
        -0.030420826748013496,
        -0.02038082666695118,
        -0.04967932775616646,
        0.042558327317237854,
        -0.04181094095110893,
        -0.04934007674455643,
        -0.05198793485760689,
        -0.05212249606847763,
        0.0523054413497448,
        0.009866699576377869,
        0.05068760737776756,
        -0.05181059613823891,
        -0.052151136100292206,
        0.05010029301047325,
        0.052259016782045364,
        -0.014538314193487167,
        -0.050616294145584106,
        0.05228416249155998,
        -0.052160583436489105,
        -0.05218679830431938,
        0.014547602273523808,
        -0.03971181809902191,
        -0.0522429421544075,
        0.03882019594311714,
        -0.04772474244236946,
        -0.036912087351083755,
        0.04996305704116821,
        0.029920320957899094,
        -0.010867178440093994,
        -0.05227367579936981,
        0.04812120273709297,
        0.05226632580161095,
        -0.052241742610931396,
        -0.04220082610845566,
        0.028295740485191345,
        0.042512569576501846,
        -0.05225811526179314,
        0.05227958783507347,
        0.020846005529165268,
        0.05232276767492294,
        -0.025907279923558235,
        -0.01992379128932953,
        -0.0503980778157711,
        0.044825561344623566,
        -0.03491216152906418,
        0.035086724907159805,
        0.04965464770793915,
        0.04743465036153793,
        -0.04674902930855751,
        0.04222555831074715,
        0.018392907455563545,
        -0.04975579306483269,
        0.02702612616121769,
        -0.05231887474656105,
        -0.04215114191174507,
        0.045478418469429016,
        0.0282638818025589,
        0.016148298978805542,
        -0.051057666540145874,
        0.05122968554496765,
        -0.05021998658776283,
        -0.052033744752407074,
        0.03846290335059166,
        -0.0406971350312233,
        0.051167383790016174,
        0.05210118740797043,
        0.031985338777303696,
        0.020253293216228485,
        0.051094815135002136,
        0.0037193680182099342,
        0.04130566120147705,
        -0.05229232832789421,
        0.0022072733845561743,
        0.05226784944534302,
        0.0032528534065932035,
        0.03273003175854683,
        -0.052249565720558167,
        0.04743577912449837,
        0.029767440631985664,
        -0.051291562616825104,
        0.05226670578122139,
        0.04293476790189743,
        0.05231296271085739,
        0.05135096609592438,
        -0.04944506660103798,
        0.05232849344611168,
        -0.0522310771048069,
        -0.03161516785621643,
        -0.027396075427532196,
        -0.05198187381029129,
        0.05066288262605667,
        -0.051477428525686264,
        0.04833969473838806,
        0.00010257408575853333,
        0.03364984691143036,
        0.0499679297208786,
        -0.051788683980703354,
        0.052313026040792465,
        0.04277713969349861,
        0.05194026231765747,
        -0.036493103951215744,
        0.05209065601229668,
        0.04381808638572693,
        -0.027731414884328842,
        -0.046217601746320724,
        0.05208869278430939,
        -0.051330238580703735,
        0.03474297747015953,
        0.015232664532959461,
        0.02990107797086239,
        -0.03984006494283676,
        -0.051390137523412704,
        -0.05061855539679527,
        -0.01562783122062683,
        0.049541275948286057,
        0.04738783463835716,
        0.0519929938018322,
        0.0486447811126709,
        0.027451956644654274,
        -0.04848294332623482,
        0.05233466625213623,
        0.04050491005182266,
        0.0424056351184845,
        -0.051620204001665115,
        -0.052327413111925125,
        -0.013607020489871502,
        -0.04342474788427353,
        0.04941187798976898,
        0.038284093141555786,
        -0.05028780922293663,
        -0.052311357110738754,
        -0.02013794519007206,
        -0.02741927094757557,
        0.05232726037502289,
        -0.0470191165804863,
        0.049353890120983124,
        0.052299510687589645,
        -0.0025536157190799713,
        -0.05224762484431267,
        0.03902747854590416,
        0.025435717776417732,
        -0.04853178188204765,
        0.04691491276025772,
        -0.0522647462785244,
        0.0518413782119751,
        0.051906101405620575,
        0.05233388394117355,
        0.05186474323272705,
        0.052334029227495193,
        -0.043172258883714676,
        0.04613390192389488,
        0.05075836181640625,
        -0.05207270011305809,
        0.0523211844265461,
        -0.052243564277887344,
        0.05184664949774742,
        0.03109051287174225,
        -0.03795136511325836,
        -0.04761223495006561,
        0.051223013550043106,
        0.034488216042518616,
        -0.05187048390507698,
        0.04683214798569679,
        -0.052258990705013275,
        -0.05212616175413132,
        -0.011549803428351879,
        0.04406684264540672,
        0.011731642298400402,
        -0.037646692246198654,
        0.05232229083776474,
        0.04649129509925842,
        -0.05222086235880852,
        -0.03624577075242996,
        -0.02622266486287117,
        -0.035618484020233154,
        0.051968466490507126,
        -0.04994010552763939,
        0.052333127707242966,
        0.027367139235138893,
        0.044691313058137894,
        0.050712671130895615,
        0.046035077422857285,
        0.04801474139094353,
        0.014100720174610615,
        -0.012712168507277966,
        0.046192895621061325,
        0.049188610166311264,
        0.01380867138504982,
        0.05232195556163788,
        0.04715524613857269,
        -0.03390384092926979,
        -0.051841963082551956,
        0.04341476410627365,
        0.048607565462589264,
        0.05105367675423622,
        -0.045542456209659576,
        -0.05202382802963257,
        -0.02474723756313324,
        0.05233534425497055,
        0.051429618149995804,
        0.0012623307993635535,
        -0.05228603258728981,
        -0.04636078700423241,
        0.051787082105875015,
        -0.04313622787594795,
        0.05226239934563637,
        0.049950920045375824,
        0.04648607596755028,
        0.05137501657009125,
        -0.04471587762236595,
        -0.05232555791735649,
        -0.04045414552092552,
        -0.05178888142108917,
        0.048324357718229294,
        0.0522833876311779,
        0.04574766755104065,
        0.05156556889414787,
        -0.05148426070809364,
        0.03792378306388855,
        0.03234938904643059,
        0.04863141477108002,
        -0.04904932901263237,
        -0.02721579372882843,
        0.05233516916632652,
        0.02454282157123089,
        0.03400300815701485,
        0.049154918640851974,
        0.05218556523323059,
        0.05172676965594292,
        -0.05083814263343811,
        0.01677432656288147,
        0.04863462969660759,
        0.05220785364508629,
        0.03908148780465126,
        -0.05136473849415779,
        -0.05168253183364868,
        -0.04214335232973099,
        -0.05233459547162056,
        -0.052245963364839554,
        -0.05211736261844635,
        -0.05037173628807068,
        0.04799700155854225,
        0.04028850048780441,
        0.03337886184453964,
        -0.0496218279004097,
        -0.046188779175281525,
        -0.02447812259197235,
        0.0522950142621994,
        -0.043538179248571396,
        -0.044612571597099304,
        -0.05211785063147545,
        -0.024054791778326035,
        -0.04684910178184509,
        -0.05061464384198189,
        -0.0018781502731144428,
        -0.020143896341323853,
        0.052334874868392944,
        0.04979993775486946,
        0.05085887014865875,
        0.05230403319001198,
        0.049136385321617126,
        -0.005184415727853775,
        0.009748377837240696,
        -0.05103600397706032,
        -0.004519780166447163,
        0.05231793597340584,
        -0.051408130675554276,
        -0.027303552255034447,
        -0.02425547130405903,
        -0.049976177513599396,
        -0.052333954721689224,
        -0.05227470397949219,
        -0.05138390138745308,
        -0.051219772547483444,
        -0.05215618759393692,
        0.010009176097810268,
        0.05203725025057793,
        0.045873306691646576,
        -0.03551961109042168,
        -0.052320536226034164,
        -0.05229974538087845,
        -0.047909051179885864,
        0.05204298347234726,
        -0.0103463064879179,
        -0.026818659156560898,
        -0.050760213285684586,
        0.0470418781042099,
        0.034669291228055954,
        -0.04948940500617027,
        0.05182640254497528,
        -0.0017136402893811464,
        -0.049770183861255646,
        -0.050972502678632736,
        -0.005474484525620937,
        0.05034125968813896,
        0.04940267279744148,
        -0.0430997870862484,
        -0.05221093073487282,
        -0.019662324339151382,
        0.05223441123962402,
        0.040425993502140045,
        0.05188508331775665,
        -0.05119047313928604,
        -0.01972648873925209,
        -0.05232854187488556,
        0.004628252703696489,
        -0.04436869919300079,
        -0.050090789794921875,
        -0.05100807547569275,
        -0.0055956463329494,
        -0.04986254870891571,
        0.011949283070862293,
        -0.037176068872213364,
        -0.04729807376861572,
        -0.051914896816015244,
        -0.009558551013469696,
        -0.05041918903589249,
        -0.04171805828809738,
        0.02424508146941662,
        -0.051687683910131454,
        -0.052226074039936066,
        0.016894999891519547,
        0.011709760874509811,
        0.05165393650531769,
        0.0491524413228035,
        -0.030439401045441628,
        0.04866539686918259,
        0.052087463438510895,
        0.035643212497234344,
        0.03732986003160477,
        0.0523340106010437,
        0.04984521120786667,
        0.052270080894231796,
        -0.008261563256382942,
        0.03793252632021904,
        0.049304600805044174,
        -0.052036166191101074,
        -0.03595984727144241,
        -0.043330416083335876,
        0.042552050203084946,
        -0.006207073573023081,
        0.05233351141214371,
        -0.04101429507136345,
        0.013446206226944923,
        0.05214216932654381,
        0.05231190472841263,
        0.05198946222662926,
        -0.05224044993519783,
        0.052260223776102066,
        0.0022237917874008417,
        -0.05154331028461456,
        0.0522049143910408,
        0.020324300974607468,
        0.04284477233886719,
        0.0035804903600364923,
        -0.05181613937020302,
        -0.021497288718819618,
        0.031424082815647125,
        0.051718126982450485,
        -0.052072592079639435,
        -0.05177522823214531,
        0.05215570703148842,
        -0.013635112904012203,
        0.03689791262149811,
        0.0506785623729229,
        -0.0514080785214901,
        0.049413904547691345,
        0.05195162817835808,
        0.02810349315404892,
        -0.052166685461997986,
        0.04611879959702492,
        -0.05222932621836662,
        -0.05177782103419304,
        0.052232228219509125,
        -0.0365900918841362,
        -0.043155524879693985,
        0.05063561722636223,
        -0.052307628095149994,
        -0.05141814425587654,
        0.0439065657556057,
        0.05093163996934891,
        0.04762287065386772,
        0.04353748634457588,
        -0.025299374014139175,
        -0.046065669506788254,
        -0.024159837514162064,
        0.05224715545773506,
        0.052332036197185516,
        -0.050205063074827194,
        0.052323292940855026,
        0.011871897615492344,
        0.05221415311098099,
        -0.048630278557538986
    ],
    [
        -0.0038956657517701387,
        -0.05423544719815254,
        -0.03613146394491196,
        -0.0024370073806494474,
        0.04632541537284851,
        -0.05532068759202957,
        0.055379144847393036,
        -0.041172850877046585,
        0.038939591497182846,
        0.04934583604335785,
        0.0403439886868,
        0.0554233156144619,
        -0.05438009649515152,
        -0.02405046671628952,
        -0.05286998674273491,
        0.05504151061177254,
        -0.05341696739196777,
        0.04911242425441742,
        -0.05527222529053688,
        -0.04402517154812813,
        -0.04826918989419937,
        0.04325467348098755,
        0.05481988191604614,
        -0.05536286532878876,
        -0.051414862275123596,
        -0.0008165822364389896,
        0.054387886077165604,
        0.04341160133481026,
        0.02414081059396267,
        0.05546080693602562,
        0.03650795295834541,
        -0.053889013826847076,
        0.045744214206933975,
        -0.054516881704330444,
        -0.03558404743671417,
        -0.05484234169125557,
        -0.0486937053501606,
        -0.04890179634094238,
        -0.05411956086754799,
        0.037623997777700424,
        0.043637122958898544,
        -0.0274016335606575,
        0.0499456487596035,
        -0.05346957966685295,
        0.041145578026771545,
        -0.01131985243409872,
        0.05534842610359192,
        0.05542891472578049,
        0.05314977094531059,
        0.03287942707538605,
        0.03987228497862816,
        0.04817672446370125,
        0.05482381209731102,
        0.054028015583753586,
        -0.049874696880578995,
        0.0048462930135428905,
        -0.05368602275848389,
        -0.03784710168838501,
        0.04998750984668732,
        0.04188026860356331,
        -0.021722562611103058,
        -0.03443698585033417,
        -0.05034394934773445,
        0.055269546806812286,
        0.01761779934167862,
        0.0403711162507534,
        0.003444421337917447,
        -0.03378750756382942,
        -0.04249272495508194,
        -0.055481258779764175,
        -0.04932701215147972,
        -0.04894881695508957,
        -0.024027014151215553,
        -0.02608468383550644,
        -0.0552646704018116,
        -0.017456820234656334,
        0.04177473857998848,
        0.05508582293987274,
        0.04400474950671196,
        -0.05248421058058739,
        -0.020379027351737022,
        0.05546191334724426,
        -0.033036008477211,
        0.05014420673251152,
        0.0018063564784824848,
        0.04399925470352173,
        0.019960088655352592,
        0.007378953043371439,
        0.02386941760778427,
        0.055471088737249374,
        -0.053286369889974594,
        -0.008588484488427639,
        -0.025241916999220848,
        -0.05460144579410553,
        -0.008896693587303162,
        0.0465080551803112,
        -0.03179286792874336,
        -0.04790978506207466,
        -0.05533687025308609,
        0.007424246519804001,
        -0.05543401464819908,
        0.054346878081560135,
        0.052640460431575775,
        0.05427885800600052,
        0.04468793794512749,
        -0.05397060513496399,
        -0.05365848168730736,
        0.0070802196860313416,
        0.05324944853782654,
        0.0167170949280262,
        -0.047080107033252716,
        -0.05501580238342285,
        0.03552292659878731,
        0.03584480658173561,
        0.008759078569710255,
        -0.04294571653008461,
        -0.05522114783525467,
        -0.05436212569475174,
        -0.0554499626159668,
        0.05443164333701134,
        -0.028674885630607605,
        -0.005133138969540596,
        -0.02360360324382782,
        0.04106730967760086,
        -0.054885149002075195,
        0.044895581901073456,
        0.004179803188890219,
        -0.013874439522624016,
        0.047431785613298416,
        0.03902614116668701,
        0.03984852507710457,
        -0.05112472176551819,
        -0.05506259948015213,
        0.05408324673771858,
        -0.05242384597659111,
        -0.04608339071273804,
        0.04857617989182472,
        -0.03855901584029198,
        -0.05034826695919037,
        0.05116591602563858,
        0.05491218343377113,
        0.055100977420806885,
        0.04865111783146858,
        0.050596680492162704,
        0.006841360125690699,
        0.05479395389556885,
        0.001517673721536994,
        -0.04689330607652664,
        -0.0457516573369503,
        0.05041925609111786,
        -0.047942426055669785,
        -0.052451781928539276,
        -0.05271507427096367,
        -0.032010726630687714,
        -0.0284194964915514,
        -0.002954536583274603,
        0.05454650893807411,
        -0.003298043040558696,
        0.02509951964020729,
        0.04560646042227745,
        0.052670322358608246,
        0.05058010295033455,
        0.04350094869732857,
        0.016719011589884758,
        0.023933980613946915,
        -0.018916992470622063,
        -0.02511337585747242,
        -0.052054665982723236,
        -0.007039119955152273,
        0.04001673310995102,
        -0.04827496036887169,
        -0.030287692323327065,
        -0.023403123021125793,
        0.014269571751356125,
        -0.04236855357885361,
        0.05305340513586998,
        -0.05548189580440521,
        0.04971911385655403,
        0.04124712571501732,
        -0.037057917565107346,
        0.043304845690727234,
        0.05313241854310036,
        0.05430940166115761,
        -0.03928189352154732,
        0.004995119757950306,
        0.0179813914000988,
        -0.05542200058698654,
        0.0523686446249485,
        -0.052193138748407364,
        -0.046379249542951584,
        0.04342726990580559,
        -0.0333317331969738,
        -0.028916047886013985,
        0.04017692431807518,
        -0.024576928466558456,
        -0.0536213144659996,
        0.048290181905031204,
        0.03421266749501228,
        0.000008715309377294034,
        0.01957361213862896,
        0.048765357583761215,
        0.02402554824948311,
        -0.037747059017419815,
        0.05439303442835808,
        0.052051693201065063,
        0.055124521255493164,
        0.021613944321870804,
        -0.015589503571391106,
        -0.04645206779241562,
        -0.04936070740222931,
        -0.05344664305448532,
        -0.029878266155719757,
        -0.03853929415345192,
        -0.016017859801650047,
        0.05505659803748131,
        0.052146099507808685,
        0.0554116852581501,
        0.029197003692388535,
        0.0554712750017643,
        -0.026759319007396698,
        0.020096592605113983,
        0.05263155326247215,
        -0.05547631159424782,
        -0.05539870634675026,
        0.0539700947701931,
        -0.055361002683639526,
        0.047327179461717606,
        0.053421854972839355,
        -0.03159041702747345,
        -0.044787436723709106,
        0.02095787040889263,
        -0.05541549250483513,
        -0.008333168923854828,
        0.05280284956097603,
        0.02168916165828705,
        -0.006919153034687042,
        0.04920594394207001,
        0.02876458503305912,
        -0.05409860983490944,
        0.05523854121565819,
        0.0527585931122303,
        0.04785243794322014,
        -0.05501994863152504,
        -0.03259216248989105,
        0.019702475517988205,
        -0.017363114282488823,
        0.043364185839891434,
        0.05475875362753868,
        -0.0407646968960762,
        -0.022360756993293762,
        -0.04261145740747452,
        -0.05409004166722298,
        0.04705686494708061,
        -0.05150977522134781,
        -0.04597969725728035,
        0.055432967841625214,
        0.04868750274181366,
        -0.04508775472640991,
        0.054998625069856644,
        0.05507491156458855,
        0.05544457584619522,
        0.03871069476008415,
        -0.017926879227161407,
        0.025272058323025703,
        0.016494086012244225,
        0.048771344125270844,
        0.054053761065006256,
        0.05509435385465622,
        -0.031686555594205856,
        0.015920570120215416,
        -0.054674506187438965,
        -0.055475883185863495,
        -0.019790176302194595,
        -0.05528799071907997,
        -0.021441668272018433,
        0.043968748301267624,
        -0.05453808605670929,
        -0.04839358478784561,
        0.02550630271434784,
        -0.05355434864759445,
        -0.05337674543261528,
        -0.03875696286559105,
        0.01786869950592518,
        0.05284290015697479,
        0.051744185388088226,
        -0.05525074154138565,
        0.04064228758215904,
        -0.05546547845005989,
        -0.0545632466673851,
        0.04907402768731117,
        -0.05497109889984131,
        0.05121444910764694,
        0.047193512320518494,
        -0.03112979047000408,
        0.05118698999285698,
        -0.05257978290319443,
        0.05534685030579567,
        0.05180791765451431,
        -0.054242976009845734,
        0.0512104369699955,
        -0.05365839973092079,
        0.05338919907808304,
        -0.033175647258758545,
        0.001871354877948761,
        -0.022454174235463142,
        0.018872980028390884,
        -0.0553128719329834,
        0.04960991069674492,
        0.05469956994056702,
        0.011388305574655533,
        0.053016431629657745,
        -0.05456165596842766,
        -0.03671252354979515,
        0.05523078888654709,
        -0.05206145718693733,
        -0.050309859216213226,
        0.04528191313147545,
        0.0342213474214077,
        0.0247876588255167,
        -0.05408765748143196,
        -0.05489177629351616,
        0.04402773454785347,
        0.055041830986738205,
        -0.015163689851760864,
        0.052786681801080704,
        0.04580788314342499,
        0.035012874752283096,
        0.05281582847237587,
        0.03624409809708595,
        -0.010469915345311165,
        0.013555302284657955,
        0.010765776969492435,
        0.038967669010162354,
        -0.047192465513944626,
        0.053147509694099426,
        0.009332404471933842,
        -0.055372659116983414,
        0.04351348429918289,
        -0.05125213414430618,
        0.05478369817137718,
        0.051670562475919724,
        -0.05386892706155777,
        -0.0547901913523674,
        -0.05251828208565712,
        0.05548222362995148,
        0.04900171235203743,
        0.051821693778038025,
        0.04988127946853638,
        -0.05025944858789444,
        0.054005008190870285,
        -0.05547412857413292,
        0.03242325037717819,
        -0.023588165640830994,
        -0.05511019751429558,
        -0.05472598597407341,
        0.047595538198947906,
        -0.055211953818798065,
        -0.051591407507658005,
        -0.04221723601222038,
        0.03723696246743202,
        0.05242161825299263,
        0.05234416201710701,
        -0.05339835584163666,
        -0.041379109025001526,
        -0.02335243672132492,
        -0.05467298999428749,
        0.055479295551776886,
        0.002156993607059121,
        0.05470762774348259,
        -0.05542801693081856,
        -0.04178726300597191,
        -0.04387044534087181,
        0.054911281913518906,
        0.018243908882141113,
        0.05378536134958267,
        0.05273279920220375,
        -0.05460464954376221,
        -0.052760232239961624,
        -0.0200349073857069,
        -0.017519598826766014,
        -0.027590595185756683,
        0.027078038081526756,
        -0.054898619651794434,
        0.008991647511720657,
        0.04661709815263748,
        -0.054854076355695724,
        0.05282691866159439,
        -0.055432602763175964,
        -0.05261417105793953,
        -0.049620188772678375,
        -0.055428773164749146,
        0.04804214835166931,
        0.02099846862256527,
        0.05491068959236145,
        -0.032788295298814774,
        0.04438425227999687,
        0.0539349801838398,
        -0.05516277626156807,
        -0.03323092311620712,
        -0.049422040581703186,
        -0.05271516367793083,
        0.022744160145521164,
        0.05525992438197136,
        -0.025408945977687836,
        0.009616056457161903,
        0.03594466298818588,
        0.0529511421918869,
        0.01822763867676258,
        0.04887790605425835,
        -0.0535697303712368,
        0.037805598229169846,
        0.048618290573358536,
        0.017213117331266403,
        0.02393820323050022,
        0.049610722810029984,
        -0.003961043898016214,
        0.047770336270332336,
        0.05539267882704735,
        0.04231378436088562,
        0.018234223127365112,
        0.003980535082519054,
        0.00830753706395626,
        -0.05424821376800537,
        -0.054652437567710876,
        0.05214482918381691,
        -0.003263092366978526,
        -0.05229852721095085,
        0.04806015267968178,
        -0.05530516430735588,
        -0.05312402918934822,
        0.051649294793605804,
        -0.054178301244974136,
        -0.04008539021015167,
        0.004081462509930134,
        0.03178544342517853,
        -0.010504205711185932,
        -0.045662734657526016,
        0.05027035251259804,
        -0.0031596599146723747,
        -0.05109760910272598,
        -0.03867040574550629,
        0.04322783648967743,
        -0.018853414803743362,
        0.045306287705898285,
        -0.029898976907134056,
        -0.049803782254457474,
        0.05426710844039917,
        -0.05542342737317085,
        0.05497702211141586,
        -0.0470331571996212,
        -0.055122993886470795,
        0.05382099375128746,
        0.049836382269859314,
        -0.04112343490123749,
        0.051025036722421646,
        -0.04699332267045975,
        -0.03761623799800873,
        0.017762720584869385,
        -0.054652806371450424,
        0.024374408647418022,
        0.05369959771633148,
        0.02049241028726101,
        -0.05395044386386871,
        -0.0317051000893116,
        0.05180005356669426,
        0.055077940225601196,
        -0.05546019226312637,
        0.05541927367448807,
        -0.03271923586726189,
        -0.0552666150033474,
        -0.023519866168498993,
        0.010325751267373562,
        0.04930824413895607,
        0.023002687841653824,
        -0.0511823408305645,
        0.05128290876746178,
        -0.04693683609366417,
        -0.03927202150225639,
        -0.054611630737781525,
        -0.048105642199516296,
        -0.05543484911322594,
        0.04110085964202881,
        0.05523539334535599,
        0.0024114022962749004,
        0.01744060032069683,
        0.052747368812561035,
        0.05495181307196617,
        -0.04294773191213608,
        -0.05342216044664383,
        0.04573899880051613,
        -0.05385309457778931,
        -0.05528564751148224,
        -0.04030872508883476,
        0.045504938811063766,
        0.053485654294490814,
        0.05382344871759415,
        -0.04483283311128616,
        0.029473010450601578,
        0.054210491478443146,
        0.05545854941010475,
        -0.05071399733424187,
        -0.05322637781500816,
        -0.05141410976648331,
        -0.005411088932305574,
        0.0476023331284523,
        0.026258502155542374,
        0.05093034356832504,
        -0.021261226385831833,
        -0.04171868786215782,
        -0.05487823486328125,
        0.05546735227108002,
        0.04767485335469246
    ],
    [
        0.02168986015021801,
        -0.001904600183479488,
        0.05050647258758545,
        0.046082500368356705,
        -0.041282299906015396,
        0.05371301621198654,
        0.054037149995565414,
        -0.05192149057984352,
        0.043786581605672836,
        0.025433290749788284,
        0.05406536906957626,
        0.053162768483161926,
        -0.05389433726668358,
        -0.04206943139433861,
        -0.05357946828007698,
        0.053966980427503586,
        0.0207772646099329,
        0.05179112032055855,
        -0.044719792902469635,
        -0.053694948554039,
        0.029194654896855354,
        -0.05073470622301102,
        -0.052062880247831345,
        -0.054007355123758316,
        -0.05292215943336487,
        0.05337359756231308,
        -0.008642159402370453,
        0.053023792803287506,
        -0.04962236434221268,
        0.04494626447558403,
        -0.04199858754873276,
        -0.05366062372922897,
        0.05150219425559044,
        -0.054055266082286835,
        0.05371534079313278,
        -0.04123418778181076,
        -0.053099971264600754,
        -0.04747525602579117,
        0.052287451922893524,
        0.04907751455903053,
        -0.0024073971435427666,
        0.04852025583386421,
        0.04339089244604111,
        -0.025394463911652565,
        0.029275262728333473,
        0.0020809941925108433,
        0.052642107009887695,
        0.054067034274339676,
        0.05405716970562935,
        -0.05402591452002525,
        0.05360179767012596,
        0.029817041009664536,
        -0.03895004466176033,
        -0.03065231256186962,
        -0.05298691615462303,
        0.027091672644019127,
        -0.05128467082977295,
        -0.02242843434214592,
        -0.025913670659065247,
        -0.05074537917971611,
        -0.043414320796728134,
        0.015753472223877907,
        -0.05356777831912041,
        -0.05312282219529152,
        0.0495833083987236,
        -0.04876333847641945,
        -0.05397474020719528,
        0.05407215654850006,
        -0.019487688317894936,
        -0.05407405272126198,
        0.021819397807121277,
        -0.044097114354372025,
        0.05405561625957489,
        0.02963082306087017,
        -0.053992509841918945,
        0.01910615898668766,
        0.05325768142938614,
        0.054037414491176605,
        0.040364786982536316,
        0.01854606159031391,
        -0.04939860478043556,
        -0.05362886190414429,
        -0.05406448245048523,
        0.05326886847615242,
        0.05360075458884239,
        0.047836001962423325,
        -0.048772212117910385,
        -0.05007995665073395,
        0.039759863168001175,
        0.05372695252299309,
        -0.054006293416023254,
        -0.05389827862381935,
        -0.03877849131822586,
        -0.043556928634643555,
        -0.048722755163908005,
        0.029696917161345482,
        -0.006025003269314766,
        -0.04383831098675728,
        -0.05386842414736748,
        0.04854758828878403,
        -0.05390968918800354,
        0.05303806811571121,
        0.013159133493900299,
        0.05355341359972954,
        -0.05402915179729462,
        -0.05179305002093315,
        -0.053746651858091354,
        -0.034064456820487976,
        0.037925835698843,
        0.020351126790046692,
        -0.028486192226409912,
        -0.04121417924761772,
        0.030119771137833595,
        0.001987756695598364,
        0.05407397076487541,
        -0.04074857756495476,
        0.017865365371108055,
        -0.04580606147646904,
        -0.02731197141110897,
        0.022555625066161156,
        -0.04682198911905289,
        -0.04429764673113823,
        0.05262752249836922,
        -0.05168861895799637,
        -0.04525354132056236,
        0.011182770133018494,
        -0.034630876034498215,
        0.04324040189385414,
        0.053912095725536346,
        -0.01878870464861393,
        0.05393056944012642,
        -0.051813673228025436,
        -0.05404749885201454,
        0.053576432168483734,
        -0.04110700637102127,
        -0.05155501514673233,
        0.03526671603322029,
        -0.020799899473786354,
        -0.04853556305170059,
        0.05399487540125847,
        0.028327463194727898,
        -0.04031249135732651,
        0.044999316334724426,
        0.052465882152318954,
        0.04952448233962059,
        -0.027409572154283524,
        0.050908736884593964,
        -0.0540325753390789,
        -0.0505046471953392,
        0.0004083940584678203,
        -0.0503808856010437,
        -0.05389657989144325,
        -0.05365429073572159,
        -0.053276337683200836,
        -0.0028455171268433332,
        -0.02554558403789997,
        -0.008884831331670284,
        0.03604688122868538,
        0.052435558289289474,
        0.03882703557610512,
        -0.052083808928728104,
        0.029096229001879692,
        0.04547681286931038,
        0.04222513735294342,
        -0.021590905264019966,
        0.02064923755824566,
        0.04844485968351364,
        -0.002916658530011773,
        -0.03635810315608978,
        0.016578493639826775,
        -0.03471975401043892,
        0.04321330785751343,
        -0.053930990397930145,
        0.0014225979102775455,
        -0.05345015227794647,
        0.036024194210767746,
        0.05044092237949371,
        0.0447724387049675,
        0.05001647397875786,
        -0.01692483015358448,
        -0.05316891148686409,
        0.052713073790073395,
        -0.0015592926647514105,
        -0.013765302486717701,
        0.00755193131044507,
        -0.046078212559223175,
        0.04681232199072838,
        0.052845850586891174,
        0.037245023995637894,
        0.053994614630937576,
        -0.054033756256103516,
        0.031604133546352386,
        0.050730615854263306,
        -0.05398298799991608,
        -0.047833144664764404,
        -0.05007806420326233,
        0.053963903337717056,
        0.05327070504426956,
        0.01928858645260334,
        0.04278237000107765,
        0.053506750613451004,
        0.05356122553348541,
        -0.05363232642412186,
        -0.05405047908425331,
        0.04474969208240509,
        -0.05407272279262543,
        0.02785388194024563,
        0.03939909487962723,
        0.04967772215604782,
        0.053387247025966644,
        -0.04967154562473297,
        0.042152710258960724,
        0.0184765812009573,
        0.04327404499053955,
        0.026116738095879555,
        -0.05406176671385765,
        0.05352187156677246,
        0.002278317464515567,
        0.048686522990465164,
        -0.049675535410642624,
        -0.0011110043851658702,
        -0.037022124975919724,
        0.03933307155966759,
        -0.050978053361177444,
        0.01086738146841526,
        -0.0028798466082662344,
        0.04999508708715439,
        -0.051448166370391846,
        -0.015991294756531715,
        -0.05314667150378227,
        -0.02763061597943306,
        -0.05315458029508591,
        -0.05367065221071243,
        0.046004366129636765,
        0.05395220220088959,
        0.017004774883389473,
        0.050887931138277054,
        0.03664882481098175,
        -0.04797237366437912,
        0.054057829082012177,
        0.03074335679411888,
        -0.005083761643618345,
        0.030598338693380356,
        -0.054074063897132874,
        0.05403684452176094,
        0.01774522289633751,
        -0.0381026454269886,
        0.043340496718883514,
        0.016003862023353577,
        -0.05402783676981926,
        -0.030492667108774185,
        0.01415686309337616,
        0.05361846461892128,
        -0.05209410935640335,
        0.0022959790658205748,
        0.052312690764665604,
        0.05406638979911804,
        -0.05339386314153671,
        0.043182436376810074,
        0.01149494107812643,
        -0.005778714548796415,
        -0.044370416551828384,
        -0.048984747380018234,
        0.025622298941016197,
        -0.05253606289625168,
        0.05333738401532173,
        0.028938379138708115,
        0.05293143913149834,
        0.04823454096913338,
        -0.04433758929371834,
        0.04397078976035118,
        -0.05385722219944,
        0.054072681814432144,
        -0.0528717115521431,
        0.05044987425208092,
        0.0367385596036911,
        -0.04658371955156326,
        0.05259263515472412,
        0.029806824401021004,
        -0.021338393911719322,
        -0.054004646837711334,
        0.05203080549836159,
        -0.04049793258309364,
        0.053206875920295715,
        0.05260980874300003,
        0.05407382920384407,
        -0.006476767361164093,
        0.04743451625108719,
        0.05194877088069916,
        0.0005467859446071088,
        -0.05242026969790459,
        0.03198954835534096,
        -0.028464801609516144,
        0.04741555452346802,
        0.007140759378671646,
        -0.048751622438430786,
        0.05400136113166809,
        -0.023707469925284386,
        -0.05407314747571945,
        0.052862748503685,
        0.04577430337667465,
        0.05363639444112778,
        0.020636336877942085,
        0.05093137174844742,
        0.04262233152985573,
        -0.0013295781100168824,
        -0.03756995126605034,
        0.05407372862100601,
        0.024447506293654442,
        0.030827775597572327,
        0.05388249456882477,
        -0.0354052297770977,
        0.054031867533922195,
        0.03924356400966644,
        -0.03840819001197815,
        -0.051618658006191254,
        0.018116692081093788,
        0.054070521146059036,
        0.05333523079752922,
        -0.0018840815173462033,
        0.032539475709199905,
        -0.052582476288080215,
        0.05009337142109871,
        0.01101598609238863,
        0.049881454557180405,
        0.0540512315928936,
        0.05384223163127899,
        -0.054062213748693466,
        -0.050752945244312286,
        -0.05312814190983772,
        -0.05365096032619476,
        0.03769431263208389,
        0.05329082906246185,
        0.054004229605197906,
        0.04327402636408806,
        0.0540166050195694,
        0.018793892115354538,
        0.02975529432296753,
        0.045085955411195755,
        0.03240883722901344,
        -0.05322914198040962,
        -0.05264308676123619,
        -0.03338637948036194,
        -0.051517028361558914,
        -0.017407266423106194,
        0.05070735141634941,
        0.05262380093336105,
        0.05231623724102974,
        -0.033523187041282654,
        0.047074515372514725,
        -0.053037602454423904,
        0.05405762419104576,
        0.007706493604928255,
        -0.0359683632850647,
        -0.028435230255126953,
        0.012379979714751244,
        -0.05407267063856125,
        -0.053627610206604004,
        0.01265987753868103,
        -0.04255929961800575,
        0.03894313424825668,
        -0.015828728675842285,
        -0.05221279710531235,
        -0.05057375133037567,
        -0.053073130548000336,
        -0.049691006541252136,
        0.053854528814554214,
        -0.03622131794691086,
        0.04989941418170929,
        -0.05339907854795456,
        -0.0177024994045496,
        0.04302820935845375,
        0.011966348625719547,
        -0.05377476289868355,
        0.052147723734378815,
        0.014914353378117085,
        0.05055253952741623,
        -0.052358899265527725,
        0.052918124943971634,
        0.011229462921619415,
        0.03602549061179161,
        0.05320507660508156,
        -0.048485808074474335,
        -0.03784046694636345,
        0.053496889770030975,
        -0.05380234122276306,
        -0.05276358872652054,
        0.043870069086551666,
        -0.046940919011831284,
        -0.05407397076487541,
        -0.053647030144929886,
        0.05082137882709503,
        -0.03922635689377785,
        -0.0450701080262661,
        -0.014485633932054043,
        0.05066237971186638,
        0.04803743213415146,
        -0.05191734433174133,
        -0.04937363788485527,
        -0.05376910790801048,
        -0.04331345483660698,
        0.053869642317295074,
        -0.05266905948519707,
        -0.027428679168224335,
        0.05381842330098152,
        0.05317211151123047,
        0.031743451952934265,
        0.01742052473127842,
        0.05248593911528587,
        -0.05286846682429314,
        -0.0351695716381073,
        -0.05223286151885986,
        -0.024050608277320862,
        -0.0010667946189641953,
        0.046737197786569595,
        -0.048479191958904266,
        0.03979795426130295,
        -0.05214916542172432,
        0.03552069514989853,
        0.011843632906675339,
        0.05406909063458443,
        -0.05401381850242615,
        -0.05404539778828621,
        -0.05308978259563446,
        -0.0275285542011261,
        0.010894883424043655,
        0.013294506818056107,
        -0.0468980073928833,
        -0.044199127703905106,
        -0.052851539105176926,
        -0.017862912267446518,
        0.049116186797618866,
        -0.054053954780101776,
        0.015969568863511086,
        -0.05089738219976425,
        -0.036971528083086014,
        -0.04003184661269188,
        -0.03370645269751549,
        -0.05129186809062958,
        -0.05162113159894943,
        -0.054031092673540115,
        0.05353960394859314,
        0.046343475580215454,
        0.029733628034591675,
        -0.05370613560080528,
        -0.009257250465452671,
        0.05161866173148155,
        0.04227721318602562,
        0.04149610176682472,
        0.054073516279459,
        -0.053808800876140594,
        0.05328149348497391,
        0.02492254041135311,
        -0.043946824967861176,
        0.05379262566566467,
        -0.053598858416080475,
        -0.005891892593353987,
        -0.04895476996898651,
        -0.03721766918897629,
        0.05187492072582245,
        0.017917433753609657,
        -0.05323021858930588,
        0.052534475922584534,
        -0.053804416209459305,
        0.053957030177116394,
        0.05405399575829506,
        -0.05395141616463661,
        0.05400752276182175,
        -0.021908540278673172,
        -0.05259470269083977,
        0.053819842636585236,
        0.050599075853824615,
        0.05341619998216629,
        0.052863843739032745,
        -0.05332585796713829,
        -0.025447877123951912,
        -0.05311291664838791,
        0.05359955132007599,
        -0.05309668183326721,
        -0.04706050083041191,
        0.038500409573316574,
        0.05041619762778282,
        0.046295784413814545,
        0.05150057375431061,
        0.027999985963106155,
        0.047165773808956146,
        0.0495314821600914,
        0.018597031012177467,
        -0.05386991426348686,
        0.04136374220252037,
        0.04758015275001526,
        -0.054068971425294876,
        0.05384916812181473,
        0.04389948770403862,
        0.005189249757677317,
        0.03095991536974907,
        -0.053899213671684265,
        -0.053191475570201874,
        0.03565894812345505,
        0.016330143436789513,
        -0.05317031219601631,
        -0.04889087378978729,
        -0.054002340883016586,
        -0.04848673567175865,
        0.05258139595389366,
        0.03736653923988342,
        0.054073866456747055,
        -0.051486171782016754,
        0.052961599081754684,
        -0.05257827416062355,
        0.050818126648664474,
        -0.032595716416835785
    ],
    [
        -0.05164516717195511,
        0.0364830419421196,
        -0.042261071503162384,
        0.03678430989384651,
        -0.01358745712786913,
        -0.04904897138476372,
        0.05334442853927612,
        -0.05319388955831528,
        0.052923429757356644,
        -0.05354280769824982,
        0.047182150185108185,
        0.048318829387426376,
        -0.053298573940992355,
        -0.05311856046319008,
        -0.05078158527612686,
        0.05346035212278366,
        0.05324295908212662,
        0.053552739322185516,
        -0.01076944824308157,
        -0.05343760922551155,
        -0.05231192335486412,
        -0.002503584837540984,
        0.053563449531793594,
        0.04635944962501526,
        -0.03632470965385437,
        0.049170516431331635,
        0.053085312247276306,
        0.053567491471767426,
        -0.05323176085948944,
        -0.028498558327555656,
        0.032126639038324356,
        -0.05356180667877197,
        0.03686755150556564,
        -0.053544480353593826,
        0.051607660949230194,
        -0.05273956060409546,
        -0.05297623574733734,
        0.017502758651971817,
        0.05091259256005287,
        0.04683168977499008,
        -0.035622768104076385,
        0.04086410999298096,
        0.052188482135534286,
        0.04058586061000824,
        0.053415972739458084,
        -0.0012350274482741952,
        0.053146202117204666,
        0.053566113114356995,
        0.05111958086490631,
        -0.05356599763035774,
        0.05196761712431908,
        0.04600797966122627,
        -0.046141523867845535,
        0.013531943783164024,
        0.053030647337436676,
        -0.05319149047136307,
        -0.03510443866252899,
        -0.007699424400925636,
        -0.037121787667274475,
        0.03061019815504551,
        -0.04902810975909233,
        0.03159552440047264,
        -0.05206659063696861,
        -0.05241958424448967,
        -0.04576679691672325,
        -0.05287504941225052,
        -0.053417377173900604,
        -0.053149621933698654,
        -0.03963317349553108,
        -0.053102489560842514,
        0.007742223795503378,
        -0.04981942102313042,
        0.052516452968120575,
        0.04934176802635193,
        -0.04243184253573418,
        -0.019207747653126717,
        0.03872537240386009,
        0.05356793850660324,
        0.05313858762383461,
        0.01962960697710514,
        -0.03317834064364433,
        -0.04810241982340813,
        -0.052907854318618774,
        0.02793400175869465,
        -0.01216453593224287,
        0.05089139565825462,
        -0.03119363635778427,
        -0.05156470835208893,
        0.01797444373369217,
        0.048931051045656204,
        -0.05224090442061424,
        -0.04324569180607796,
        -0.022748667746782303,
        -0.053395483642816544,
        -0.03572774678468704,
        0.052144065499305725,
        -0.049840331077575684,
        -0.04731431603431702,
        -0.05298450216650963,
        0.04165947809815407,
        -0.05350979045033455,
        0.04055214673280716,
        0.05091902241110802,
        0.05265464261174202,
        0.006631965283304453,
        -0.029814718291163445,
        -0.050279613584280014,
        -0.007208040449768305,
        0.04799361154437065,
        0.0494447685778141,
        -0.0532473623752594,
        -0.05268611013889313,
        0.01463458500802517,
        -0.043498218059539795,
        0.053373102098703384,
        0.048478201031684875,
        -0.04607503116130829,
        -0.047792788594961166,
        0.039969827979803085,
        -0.02273573726415634,
        -0.01854586787521839,
        -0.012631512247025967,
        0.053207866847515106,
        -0.023091528564691544,
        -0.051700517535209656,
        0.04981864616274834,
        -0.025959832593798637,
        0.05069636553525925,
        0.05288279056549072,
        0.052888378500938416,
        0.025459686294198036,
        -0.05148062855005264,
        -0.03288314864039421,
        0.05355943366885185,
        0.0007708351477049291,
        -0.052317064255476,
        0.036308661103248596,
        -0.052469879388809204,
        -0.05329941585659981,
        0.018329648301005363,
        -0.04228563979268074,
        0.018342481926083565,
        0.0507221557199955,
        0.05307769402861595,
        0.02019047737121582,
        0.049393367022275925,
        0.05341339111328125,
        0.05339367315173149,
        -0.053544383496046066,
        -0.04338187351822853,
        0.05301697179675102,
        -0.05344713479280472,
        -0.049542736262083054,
        -0.05341216176748276,
        -0.04929489269852638,
        -0.012088942341506481,
        0.006862454582005739,
        0.0023327090311795473,
        -0.05193423107266426,
        0.048314861953258514,
        -0.004029669798910618,
        0.04141215607523918,
        0.052840787917375565,
        -0.05328349769115448,
        -0.010777711868286133,
        -0.04082981124520302,
        0.05165945738554001,
        -0.047961506992578506,
        -0.04867172613739967,
        0.028687702491879463,
        -0.05081411078572273,
        -0.05321211740374565,
        -0.05356639251112938,
        0.04811853915452957,
        0.049630988389253616,
        0.046850986778736115,
        0.024106495082378387,
        -0.04994187504053116,
        0.05256988853216171,
        -0.04873400926589966,
        -0.05255040153861046,
        0.022622769698500633,
        0.05114211142063141,
        -0.052644070237874985,
        0.05270611122250557,
        -0.033274970948696136,
        0.0458742119371891,
        0.05201312154531479,
        0.030563563108444214,
        -0.053528934717178345,
        -0.053501006215810776,
        -0.00469016982242465,
        0.04868420213460922,
        0.047985490411520004,
        -0.04911518096923828,
        -0.04743656888604164,
        0.050838034600019455,
        -0.035900503396987915,
        -0.042801037430763245,
        0.003401390975341201,
        0.03511694818735123,
        0.05354304239153862,
        0.03559156507253647,
        -0.05300639197230339,
        0.04851396009325981,
        -0.0050973230972886086,
        0.03604723885655403,
        -0.019898563623428345,
        -0.0530456118285656,
        0.05004413425922394,
        -0.052287064492702484,
        -0.029517967253923416,
        0.05325399339199066,
        0.051342036575078964,
        0.05262596160173416,
        -0.033719900995492935,
        0.04982989281415939,
        0.03667738288640976,
        0.05322052910923958,
        -0.052109088748693466,
        -0.0488411970436573,
        0.0118325250223279,
        -0.0532027967274189,
        -0.048778727650642395,
        0.04928264021873474,
        -0.04860435053706169,
        0.04032646864652634,
        0.04884162172675133,
        0.045884110033512115,
        -0.05350898951292038,
        -0.013856286182999611,
        -0.03332611173391342,
        -0.05306975170969963,
        0.04592207074165344,
        0.05002989619970322,
        0.053499720990657806,
        0.05073206126689911,
        0.047987207770347595,
        -0.05183856189250946,
        0.05356787145137787,
        0.05150154232978821,
        0.04623180627822876,
        0.018184499815106392,
        -0.04945487529039383,
        0.03656869754195213,
        -0.05000715330243111,
        0.006132213864475489,
        -0.047266628593206406,
        -0.049297455698251724,
        -0.05336005985736847,
        0.04931013286113739,
        -0.02254282683134079,
        0.05058730021119118,
        -0.05189479887485504,
        0.05295784771442413,
        0.05205412581562996,
        -0.04805991053581238,
        -0.052908387035131454,
        0.03952290117740631,
        0.03330407664179802,
        0.037284284830093384,
        -0.014487547799944878,
        -0.05355340242385864,
        0.034261662513017654,
        -0.05230550840497017,
        0.05189357325434685,
        0.052752383053302765,
        -0.027672989293932915,
        0.011925073340535164,
        -0.024758923798799515,
        -0.03542415425181389,
        -0.053236570209264755,
        0.05354838818311691,
        -0.05152500048279762,
        0.05353965237736702,
        0.028131691738963127,
        -0.03397780656814575,
        -0.04308432340621948,
        0.04557580128312111,
        0.05291794613003731,
        -0.05353890359401703,
        0.04620548337697983,
        -0.017958996817469597,
        0.04076940566301346,
        -0.04269442707300186,
        0.010210641659796238,
        0.018454059958457947,
        0.0016599004156887531,
        0.006158633157610893,
        -0.011539929546415806,
        -0.05350390076637268,
        0.05072939023375511,
        -0.007584001868963242,
        0.006939007434993982,
        0.04687502607703209,
        -0.05267000198364258,
        0.053558215498924255,
        0.05283506587147713,
        -0.05315709859132767,
        0.05245545879006386,
        0.050485581159591675,
        0.044409897178411484,
        -0.04695146530866623,
        0.02935503050684929,
        0.02206285297870636,
        -0.0046556671150028706,
        -0.03610708937048912,
        0.05355852469801903,
        0.04490383341908455,
        -0.0032433944288641214,
        0.0521099828183651,
        -0.042273953557014465,
        0.05191943421959877,
        0.040189847350120544,
        -0.04244228079915047,
        -0.05163881182670593,
        -0.03599197790026665,
        0.05356655269861221,
        0.053103528916835785,
        0.01661873050034046,
        -0.05356713384389877,
        0.045325033366680145,
        0.046624768525362015,
        0.017057674005627632,
        0.05348242446780205,
        0.052793022245168686,
        0.04117078706622124,
        0.048385825008153915,
        -0.021825090050697327,
        -0.04128383472561836,
        -0.04300783947110176,
        -0.05198727920651436,
        0.052696485072374344,
        0.037637561559677124,
        0.04709840938448906,
        -0.016180312260985374,
        -0.0498611256480217,
        0.0353604331612587,
        -0.052761174738407135,
        -0.04710622876882553,
        -0.0512690506875515,
        -0.04973878711462021,
        0.05303521454334259,
        -0.05190335214138031,
        0.04743720591068268,
        0.04201696813106537,
        0.05318424105644226,
        0.05187411233782768,
        -0.04538297280669212,
        0.045686833560466766,
        0.053360648453235626,
        0.05323264002799988,
        0.03186124190688133,
        -0.044735945761203766,
        -0.05173913761973381,
        -0.0505664199590683,
        -0.053567759692668915,
        -0.0533452033996582,
        -0.03928150236606598,
        -0.023655708879232407,
        0.03753325715661049,
        0.044803887605667114,
        -0.05286454036831856,
        -0.04996674135327339,
        -0.03297509253025055,
        -0.05292343720793724,
        0.05350465327501297,
        -0.05070674046874046,
        0.052557323127985,
        -0.053059808909893036,
        -0.014272371307015419,
        -0.04552553594112396,
        -0.0354139469563961,
        -0.0387849323451519,
        0.052751220762729645,
        0.053421199321746826,
        -0.050351474434137344,
        0.0012153326533734798,
        0.0534958653151989,
        -0.0016685031587257981,
        -0.037406325340270996,
        0.007034235168248415,
        -0.042050134390592575,
        -0.024383332580327988,
        -0.03758435323834419,
        -0.053550124168395996,
        -0.030627528205513954,
        -0.014605578035116196,
        -0.04837370291352272,
        -0.05348723754286766,
        -0.053113047033548355,
        -0.04778756573796272,
        -0.04641354829072952,
        -0.05329582840204239,
        -0.01661679521203041,
        0.05344891548156738,
        0.030208779498934746,
        -0.05339321494102478,
        -0.05316656082868576,
        -0.017494378611445427,
        -0.03896595910191536,
        0.04965007305145264,
        0.039575137197971344,
        0.04768402874469757,
        0.05266185849905014,
        0.046259425580501556,
        0.028393186628818512,
        -0.027725981548428535,
        0.0466948077082634,
        -0.031637851148843765,
        -0.049260467290878296,
        0.053036946803331375,
        -0.03606685623526573,
        0.04735342040657997,
        0.046995241194963455,
        -0.05282226577401161,
        -0.05287398025393486,
        -0.020559407770633698,
        0.05284310504794121,
        0.04572709649801254,
        0.053111732006073,
        -0.05202091112732887,
        -0.05346442386507988,
        -0.053053997457027435,
        0.0008222238975577056,
        0.03155389800667763,
        -0.05353746935725212,
        0.012917254120111465,
        -0.050674885511398315,
        -0.05340609699487686,
        0.053562622517347336,
        -0.05019224062561989,
        0.045350659638643265,
        0.020836394280195236,
        -0.041037801653146744,
        -0.052505847066640854,
        -0.04610488936305046,
        0.05355624854564667,
        -0.01803850382566452,
        -0.04817511513829231,
        -0.011196251027286053,
        0.03530161455273628,
        -0.04274812713265419,
        0.05313187465071678,
        -0.045224111527204514,
        0.02994452975690365,
        0.045108623802661896,
        0.01593739725649357,
        0.0032153946813195944,
        0.05193787068128586,
        0.009747843258082867,
        0.04218781366944313,
        0.04829321801662445,
        -0.038389645516872406,
        0.053216706961393356,
        -0.051033396273851395,
        -0.021902060136198997,
        -0.0481814481317997,
        -0.04626492038369179,
        0.05322154983878136,
        0.04675144702196121,
        -0.03998911753296852,
        -0.03891238570213318,
        -0.053265076130628586,
        0.05346785485744476,
        0.05349176377058029,
        -0.04990603029727936,
        0.053466662764549255,
        -0.04974345117807388,
        -0.05290648341178894,
        0.05084477737545967,
        -0.02521701529622078,
        0.05102519318461418,
        0.020454352721571922,
        0.010583203285932541,
        0.014790625311434269,
        0.0230123121291399,
        -0.019782401621341705,
        -0.05321569740772247,
        -0.053357213735580444,
        0.053490813821554184,
        -0.018348021432757378,
        0.05346936732530594,
        0.04836949333548546,
        -0.0516207218170166,
        0.046841081231832504,
        0.052649639546871185,
        0.04903797060251236,
        -0.05253978818655014,
        0.03506792336702347,
        -0.052615582942962646,
        -0.05205430090427399,
        0.05353175103664398,
        -0.003635067492723465,
        -0.0062272450886666775,
        0.051786504685878754,
        -0.04578397423028946,
        -0.049332283437252045,
        0.050388965755701065,
        0.05313780531287193,
        -0.053226228803396225,
        -0.0066131590865552425,
        -0.05307672545313835,
        -0.05353860557079315,
        0.053456734865903854,
        0.04240648075938225,
        0.05355774983763695,
        -0.034664932638406754,
        0.05339285731315613,
        -0.05330399051308632,
        -0.03045617789030075,
        0.015121592208743095
    ],
    [
        -0.012349889613687992,
        0.033744461834430695,
        0.03772192448377609,
        -0.026912203058600426,
        -0.049642156809568405,
        -0.05282321572303772,
        0.05162212997674942,
        -0.05169378221035004,
        0.046170108020305634,
        0.039585959166288376,
        0.046347156167030334,
        0.05232992023229599,
        -0.052841827273368835,
        0.04684664309024811,
        -0.05269940569996834,
        0.0459105409681797,
        0.042810339480638504,
        0.052418120205402374,
        0.04421558231115341,
        -0.040973130613565445,
        -0.01567203551530838,
        0.04835658147931099,
        0.05286170169711113,
        -0.05233908072113991,
        -0.05281265825033188,
        0.005578151438385248,
        0.05014185979962349,
        0.0493091382086277,
        -0.05258341133594513,
        0.03186741843819618,
        -0.05281470715999603,
        -0.05286557972431183,
        0.052823327481746674,
        -0.052865155041217804,
        0.05191019922494888,
        -0.05269945040345192,
        -0.05196910351514816,
        -0.02093527838587761,
        0.052608516067266464,
        -0.03434624895453453,
        0.02773345448076725,
        0.048393841832876205,
        0.05233391746878624,
        0.052845001220703125,
        -0.04131567105650902,
        0.03143598884344101,
        0.05262476205825806,
        0.05281466245651245,
        0.052864447236061096,
        0.010308532044291496,
        0.052773039788007736,
        0.022049250081181526,
        -0.02440567873418331,
        -0.05160278081893921,
        -0.038486406207084656,
        0.011700611561536789,
        0.04524913802742958,
        -0.045718371868133545,
        -0.03563958778977394,
        -0.05182811990380287,
        -0.05276130884885788,
        -0.03194902464747429,
        -0.052705761045217514,
        -0.043963585048913956,
        0.04089755937457085,
        -0.05193072557449341,
        -0.05277007818222046,
        -0.05279010534286499,
        -0.012549035251140594,
        -0.05102160573005676,
        0.027919765561819077,
        -0.05272268131375313,
        0.0015699248760938644,
        -0.003985247109085321,
        -0.03427143394947052,
        -0.052278827875852585,
        0.040770579129457474,
        0.05286558344960213,
        0.01534255500882864,
        0.04861001297831535,
        -0.008551695384085178,
        -0.025454608723521233,
        -0.05256989225745201,
        0.05268823355436325,
        0.034809909760951996,
        0.047924626618623734,
        -0.03690074756741524,
        -0.04887186363339424,
        -0.018613457679748535,
        0.05228963494300842,
        -0.052533239126205444,
        -0.0007993684848770499,
        -0.05194966495037079,
        -0.051965683698654175,
        -0.04654739052057266,
        0.04580690339207649,
        -0.04634622856974602,
        -0.05236426740884781,
        0.05149153620004654,
        0.046929456293582916,
        -0.05258774757385254,
        0.05281205475330353,
        -0.008779176510870457,
        0.05144146829843521,
        -0.04629911854863167,
        -0.05259108543395996,
        -0.03595268353819847,
        0.05265913903713226,
        -0.050893496721982956,
        -0.04480382800102234,
        0.04450336843729019,
        -0.04974755644798279,
        0.041751593351364136,
        0.03360806033015251,
        -0.015048320405185223,
        -0.017512332648038864,
        -0.0010952188167721033,
        -0.035942740738391876,
        0.052741121500730515,
        -0.03102126158773899,
        0.04621759429574013,
        -0.03448595479130745,
        0.02153589576482773,
        -0.047822605818510056,
        -0.05202103406190872,
        -0.052707694470882416,
        0.043982088565826416,
        0.0527212955057621,
        0.05094802752137184,
        0.04273248836398125,
        0.001680258079431951,
        -0.04977784678339958,
        -0.05260097607970238,
        0.05285632982850075,
        -0.05224199965596199,
        -0.051127638667821884,
        0.05266952887177467,
        -0.04810618236660957,
        -0.03623414412140846,
        0.04662549868226051,
        -0.023172693327069283,
        -0.002575501101091504,
        0.05047034099698067,
        0.052647098898887634,
        0.03821912407875061,
        0.050979144871234894,
        -0.03570479899644852,
        -0.03776029869914055,
        -0.005950340069830418,
        -0.04771747440099716,
        -0.049209825694561005,
        -0.052800506353378296,
        -0.05285213142633438,
        0.05114470422267914,
        0.052860409021377563,
        0.03538212552666664,
        0.049249663949012756,
        0.05062490329146385,
        0.024912303313612938,
        0.0021054211538285017,
        -0.05064133182168007,
        -0.051908817142248154,
        0.05252861604094505,
        -0.046878259629011154,
        -0.004088028334081173,
        -0.032939840108156204,
        0.05075399950146675,
        -0.04125116020441055,
        0.012597579509019852,
        0.03283469378948212,
        -0.0527883842587471,
        -0.05178500711917877,
        -0.03762983903288841,
        0.0470731183886528,
        -0.04121387377381325,
        0.021244248375296593,
        -0.024958960711956024,
        -0.05085679888725281,
        0.05054321512579918,
        -0.04967174306511879,
        -0.013097505085170269,
        -0.0024152579717338085,
        0.032342635095119476,
        -0.05234229937195778,
        -0.03769012168049812,
        0.04799683392047882,
        -0.0004623746208380908,
        0.0521775521337986,
        0.012000503949820995,
        -0.05129960551857948,
        -0.052835676819086075,
        0.05085698142647743,
        0.0459265261888504,
        0.026351425796747208,
        0.0005910347099415958,
        -0.05213528871536255,
        0.04251484572887421,
        0.05248819291591644,
        0.019881607964634895,
        0.04870171099901199,
        0.02287738397717476,
        -0.05219174921512604,
        -0.05064845830202103,
        -0.05144450068473816,
        0.051909372210502625,
        -0.03734292462468147,
        0.05162648484110832,
        0.026304900646209717,
        -0.05278081074357033,
        0.05049414932727814,
        -0.04914126172661781,
        -0.01613343320786953,
        0.05284344404935837,
        0.04422479867935181,
        0.05284397304058075,
        -0.05245853215456009,
        0.052398812025785446,
        0.043674878776073456,
        0.05286143347620964,
        0.039630964398384094,
        -0.020290052518248558,
        0.023179857060313225,
        0.01794004626572132,
        -0.030297178775072098,
        0.052390869706869125,
        -0.046818897128105164,
        0.009153868071734905,
        -0.05274307727813721,
        0.05089535564184189,
        -0.05222811549901962,
        -0.031066501513123512,
        -0.052800290286540985,
        0.00774870440363884,
        0.05239308252930641,
        0.051174938678741455,
        0.05284307524561882,
        0.05236870422959328,
        0.04834837093949318,
        0.049552179872989655,
        0.05285993963479996,
        0.05223012715578079,
        -0.03379395976662636,
        0.052676357328891754,
        -0.05275886878371239,
        0.03979111090302467,
        0.003335321322083473,
        0.04904468357563019,
        0.033759284764528275,
        -0.005842442158609629,
        -0.03262828662991524,
        -0.03350452333688736,
        -0.045707810670137405,
        0.05281476303935051,
        -0.05259213224053383,
        -0.040723372250795364,
        0.0505942776799202,
        0.0528646744787693,
        -0.05175038054585457,
        0.049611110240221024,
        0.04345138370990753,
        -0.045283690094947815,
        0.04311608523130417,
        0.03802703693509102,
        0.0254515390843153,
        -0.051144346594810486,
        0.05285476893186569,
        0.05286097899079323,
        0.05286505073308945,
        0.04794689640402794,
        -0.018675262108445168,
        -0.03997679799795151,
        -0.05131348595023155,
        0.052463967353105545,
        -0.04660353809595108,
        0.05284475162625313,
        0.050831664353609085,
        -0.05248766764998436,
        -0.04677822068333626,
        -0.04638524726033211,
        -0.02726214937865734,
        -0.052639055997133255,
        0.0519375242292881,
        -0.04184642806649208,
        -0.019999926909804344,
        0.007620477117598057,
        0.04384716600179672,
        0.04124996438622475,
        0.04837307333946228,
        0.0511145181953907,
        0.02796199917793274,
        -0.05246536806225777,
        0.015013877302408218,
        -0.04875683784484863,
        -0.052629757672548294,
        0.051177799701690674,
        -0.05177094414830208,
        0.0528227835893631,
        0.05056556314229965,
        0.012319887056946754,
        0.052588846534490585,
        0.043590154498815536,
        0.01934858225286007,
        -0.048941612243652344,
        0.05123022571206093,
        -0.05169866234064102,
        -0.0005788185517303646,
        -0.050299182534217834,
        0.052846360951662064,
        0.021883951500058174,
        -0.0466964952647686,
        0.02736378088593483,
        0.01836172491312027,
        0.05240827426314354,
        0.002416164381429553,
        0.030701229348778725,
        -0.05237537994980812,
        -0.0017123882425948977,
        0.05286504328250885,
        0.04750766232609749,
        -0.009826145134866238,
        -0.05202099680900574,
        -0.04966472461819649,
        0.052843086421489716,
        -0.017683209851384163,
        0.052664920687675476,
        0.025207584723830223,
        0.05168424919247627,
        -0.019407326355576515,
        0.043487705290317535,
        -0.046802591532468796,
        0.05170179530978203,
        -0.05193924903869629,
        0.052847232669591904,
        0.05286543816328049,
        0.05183020606637001,
        0.0528506338596344,
        -0.05276960879564285,
        0.01606692560017109,
        0.040018487721681595,
        0.05256346985697746,
        -0.03920178860425949,
        -0.05258367583155632,
        0.04804974049329758,
        0.03282950818538666,
        0.05049450322985649,
        0.049057044088840485,
        0.05231930688023567,
        0.0526791587471962,
        -0.051550786942243576,
        0.05167523771524429,
        0.052821774035692215,
        -0.0485050268471241,
        0.029699549078941345,
        -0.052679646760225296,
        -0.04436058923602104,
        0.047509465366601944,
        -0.05286545678973198,
        -0.05109714716672897,
        0.03623667731881142,
        0.046638887375593185,
        0.04590393975377083,
        0.052784550935029984,
        -0.0477917306125164,
        -0.052290089428424835,
        -0.02959650754928589,
        -0.052800945937633514,
        0.05286255478858948,
        -0.03862316533923149,
        0.05228688195347786,
        -0.052852652966976166,
        -0.04908725619316101,
        -0.015177350491285324,
        0.05283571407198906,
        0.008576069958508015,
        0.04941694065928459,
        0.05285628139972687,
        0.049247995018959045,
        0.006407875567674637,
        0.048798806965351105,
        0.03264714777469635,
        -0.019022073596715927,
        0.0355101078748703,
        -0.0525108240544796,
        -0.050038181245326996,
        0.03821740671992302,
        -0.05272328481078148,
        -0.04633940011262894,
        -0.009491287171840668,
        -0.03586871922016144,
        -0.04444637894630432,
        -0.052860915660858154,
        -0.011103803291916847,
        -0.046311233192682266,
        -0.0522904247045517,
        0.05282445624470711,
        0.05273985117673874,
        0.052824147045612335,
        -0.04648551717400551,
        -0.052862897515296936,
        -0.05084399878978729,
        -0.03987657278776169,
        0.05237939953804016,
        -0.05284939333796501,
        -0.04879328981041908,
        -0.03593132272362709,
        0.020350748673081398,
        0.04978379234671593,
        0.003979630768299103,
        0.052858855575323105,
        0.026663970202207565,
        -0.05249296873807907,
        0.0038981863763183355,
        -0.05280589312314987,
        0.049368105828762054,
        0.048850204795598984,
        -0.04787290468811989,
        0.01862988993525505,
        0.050323836505413055,
        0.0527939535677433,
        0.05120529606938362,
        0.05275397375226021,
        -0.05285421386361122,
        -0.00221102312207222,
        -0.0528644435107708,
        0.048140451312065125,
        0.03245040774345398,
        -0.022916704416275024,
        0.05285260081291199,
        -0.0497836172580719,
        -0.05136615037918091,
        0.05249440297484398,
        -0.017909180372953415,
        -0.05249811336398125,
        -0.052810605615377426,
        0.036837320774793625,
        -0.050894588232040405,
        -0.05282118171453476,
        0.05285114049911499,
        -0.05242377147078514,
        -0.04676081985235214,
        -0.046869199723005295,
        0.05277469381690025,
        0.036765825003385544,
        0.05270334333181381,
        -0.051118604838848114,
        0.0012821435229852796,
        0.052545610815286636,
        -0.05282856523990631,
        0.02825109101831913,
        0.05194610729813576,
        0.05172320455312729,
        0.05116841197013855,
        0.049743201583623886,
        0.025035565719008446,
        0.003116404637694359,
        -0.04033401980996132,
        -0.04211248457431793,
        -0.05163135379552841,
        -0.05238809064030647,
        -0.03668677806854248,
        0.05150057002902031,
        -0.052815016359090805,
        0.038111280649900436,
        0.02929757907986641,
        0.03116540051996708,
        0.05258684232831001,
        -0.052767012268304825,
        0.052810292690992355,
        -0.02624666690826416,
        -0.0527208149433136,
        0.04638233035802841,
        0.02153349108994007,
        0.05170551687479019,
        0.052843473851680756,
        0.021266348659992218,
        0.04748522862792015,
        0.004746862221509218,
        0.0343598797917366,
        -0.05246707797050476,
        -0.051148250699043274,
        -0.029127104207873344,
        -0.0506122000515461,
        -0.051011282950639725,
        0.040740106254816055,
        -0.052821435034275055,
        0.04715961962938309,
        0.02243173122406006,
        0.03414967283606529,
        -0.052720289677381516,
        -0.051368631422519684,
        0.05249939113855362,
        -0.05249473452568054,
        0.009840662591159344,
        -0.0513371042907238,
        0.0315001979470253,
        0.048236116766929626,
        0.05286514386534691,
        -0.050815194845199585,
        0.052640561014413834,
        0.05065252631902695,
        -0.05207926779985428,
        0.035816702991724014,
        -0.049307890236377716,
        -0.05286387726664543,
        0.04920094832777977,
        0.04699302464723587,
        0.052855897694826126,
        -0.019671067595481873,
        0.05243643373250961,
        -0.052716173231601715,
        0.047355443239212036,
        0.019019078463315964
    ],
    [
        -0.023395413532853127,
        0.04716242477297783,
        -0.05168667808175087,
        -0.031574852764606476,
        0.015345877967774868,
        -0.04518325999379158,
        0.05371455103158951,
        -0.054875414818525314,
        0.05491993576288223,
        -0.05281420052051544,
        0.053549617528915405,
        -0.036288127303123474,
        -0.05405116453766823,
        -0.049314048141241074,
        -0.054881077259778976,
        0.05494994670152664,
        0.05445672199130058,
        0.05488148704171181,
        0.007201271131634712,
        -0.04770733043551445,
        -0.0033094845712184906,
        0.045116256922483444,
        0.03320181369781494,
        -0.04613451659679413,
        0.03698476776480675,
        0.05329325795173645,
        0.0036347752902656794,
        0.05128041282296181,
        -0.044101741164922714,
        0.023912714794278145,
        0.01763106882572174,
        -0.055146291851997375,
        0.055130161345005035,
        -0.05459320545196533,
        0.030549408867955208,
        -0.0545511357486248,
        -0.052323076874017715,
        -0.0362904854118824,
        -0.0406060665845871,
        0.04579443857073784,
        -0.05352404713630676,
        0.051713425666093826,
        0.0454803891479969,
        0.04253946617245674,
        0.05492274463176727,
        -0.019073359668254852,
        0.05372823402285576,
        0.05511549860239029,
        0.05119162052869797,
        -0.05514580383896828,
        0.05490992218255997,
        0.03877067193388939,
        0.029206717386841774,
        -0.04941819980740547,
        0.05293978005647659,
        0.05102324113249779,
        0.04569992050528526,
        0.021119264885783195,
        -0.054943714290857315,
        -0.05077376589179039,
        -0.051543429493904114,
        -0.03320340812206268,
        -0.040673691779375076,
        -0.052342768758535385,
        0.034971706569194794,
        -0.035927217453718185,
        -0.05513174459338188,
        -0.05497720465064049,
        0.013758585788309574,
        -0.055148858577013016,
        -0.025951510295271873,
        -0.05349341779947281,
        0.05286610126495361,
        0.05266402289271355,
        -0.052938204258680344,
        -0.05217782407999039,
        -0.05275946855545044,
        0.055149100720882416,
        -0.04302442446351051,
        0.02091720700263977,
        -0.024177728220820427,
        -0.05449087172746658,
        -0.05512545630335808,
        -0.023184150457382202,
        -0.05364790931344032,
        0.05327903851866722,
        -0.054708078503608704,
        -0.0423714742064476,
        -0.03661664202809334,
        0.054922960698604584,
        -0.05043443664908409,
        -0.049963176250457764,
        -0.005343455355614424,
        -0.05506293475627899,
        -0.04067017883062363,
        0.00837749894708395,
        -0.05021284148097038,
        -0.04471811652183533,
        -0.048578426241874695,
        0.051918212324380875,
        -0.0541217140853405,
        -0.05463691055774689,
        -0.006487788166850805,
        0.051750145852565765,
        -0.054876673966646194,
        0.04581193998456001,
        -0.009275011718273163,
        -0.053384121507406235,
        0.04136058688163757,
        0.004733386915177107,
        -0.03497444465756416,
        -0.05199621245265007,
        -0.04599427804350853,
        -0.04768207669258118,
        0.0550500713288784,
        0.04413823038339615,
        -0.05143032968044281,
        -0.04185068607330322,
        0.05488402396440506,
        0.037176985293626785,
        -0.032201189547777176,
        0.004967567045241594,
        0.04670427739620209,
        -0.05501252040266991,
        -0.030830077826976776,
        -0.05151787027716637,
        -0.054421037435531616,
        0.03912876173853874,
        0.0413723960518837,
        0.05203922837972641,
        -0.012452159076929092,
        -0.05264197289943695,
        -0.05172857642173767,
        0.05512302741408348,
        -0.012204001657664776,
        -0.05501732602715492,
        0.05302441120147705,
        -0.051585305482149124,
        -0.003637671237811446,
        0.054458215832710266,
        0.03891071677207947,
        -0.05293389782309532,
        0.05275421962141991,
        -0.00979254674166441,
        -0.00315829343162477,
        0.05384547635912895,
        -0.04143550992012024,
        0.044143106788396835,
        0.03321520611643791,
        -0.04850999638438225,
        0.020865552127361298,
        -0.05472766235470772,
        -0.05495833605527878,
        -0.05497041717171669,
        0.05350415036082268,
        0.023863723501563072,
        -0.0032145665027201176,
        0.030122704803943634,
        0.025657586753368378,
        0.05467751622200012,
        -0.05372606962919235,
        0.054040808230638504,
        0.05244254320859909,
        -0.03107617422938347,
        -0.03531457111239433,
        0.015469850972294807,
        0.04788261279463768,
        -0.050838712602853775,
        -0.03524158149957657,
        0.03480323404073715,
        -0.05440476909279823,
        0.05221247673034668,
        -0.055117636919021606,
        0.04435433819890022,
        -0.03237751126289368,
        -0.01797136291861534,
        0.011589176021516323,
        0.017420172691345215,
        0.054919879883527756,
        -0.052792057394981384,
        -0.041509173810482025,
        0.055025093257427216,
        0.037229835987091064,
        0.009069746360182762,
        0.05000808462500572,
        0.05139021575450897,
        0.053981516510248184,
        0.05448086932301521,
        -0.02238505706191063,
        -0.053535234183073044,
        -0.04909004271030426,
        -0.03597787767648697,
        -0.013657965697348118,
        0.008311202749609947,
        0.00934530422091484,
        -0.05371243879199028,
        0.05428584665060043,
        -0.04677801579236984,
        -0.04353729635477066,
        0.050216250121593475,
        0.04947496950626373,
        0.05495563894510269,
        0.03825030103325844,
        -0.055058255791664124,
        0.02824040688574314,
        -0.04212815314531326,
        0.041237860918045044,
        0.05465466156601906,
        0.007146336138248444,
        0.05471006780862808,
        -0.02620944194495678,
        0.004171092063188553,
        0.039860229939222336,
        0.05501844361424446,
        0.055100999772548676,
        -0.05199498310685158,
        0.05263008549809456,
        -0.007021777797490358,
        0.055118169635534286,
        -0.03589635342359543,
        0.01951550878584385,
        -0.0005695077707059681,
        -0.05386823043227196,
        -0.04568236693739891,
        0.054717835038900375,
        -0.04769255965948105,
        0.014845131896436214,
        -0.04990345612168312,
        0.05277083441615105,
        -0.0510311983525753,
        -0.03224889189004898,
        -0.02630636654794216,
        -0.00031885041971690953,
        0.047611482441425323,
        0.04290490597486496,
        0.010008620098233223,
        0.0535469576716423,
        0.04887688159942627,
        -0.04454457759857178,
        0.05514859780669212,
        0.03743536025285721,
        0.052915628999471664,
        0.04045940190553665,
        -0.039418965578079224,
        0.051859378814697266,
        0.0031353021040558815,
        -0.0343918539583683,
        -0.048316966742277145,
        0.025324469432234764,
        -0.02151966281235218,
        0.027935724705457687,
        -0.04508262500166893,
        0.03776891902089119,
        -0.04923612251877785,
        0.0549178346991539,
        0.049920182675123215,
        0.054955191910266876,
        -0.03114074096083641,
        0.054129183292388916,
        0.021520407870411873,
        -0.02408229373395443,
        0.047424789518117905,
        0.04823632538318634,
        -0.044549696147441864,
        -0.005367876496165991,
        0.055085234344005585,
        0.05480106174945831,
        0.04997706785798073,
        0.055101633071899414,
        -0.054232027381658554,
        0.018476169556379318,
        -0.0529317669570446,
        0.052345819771289825,
        -0.05261465534567833,
        0.055009640753269196,
        0.042062267661094666,
        -0.052051205188035965,
        0.028073228895664215,
        0.03905084729194641,
        0.046047110110521317,
        -0.053118590265512466,
        0.02850445732474327,
        0.05380738154053688,
        -0.041418395936489105,
        -0.05185120180249214,
        -0.007198370061814785,
        0.02090330794453621,
        0.03443560749292374,
        -0.05511319637298584,
        -0.03410116210579872,
        -0.05511779710650444,
        0.05500826612114906,
        -0.051336172968149185,
        0.018108664080500603,
        0.05479993671178818,
        -0.053132642060518265,
        0.05372297018766403,
        0.021558662876486778,
        -0.020778493955731392,
        0.05215888097882271,
        -0.05297499895095825,
        0.05408604443073273,
        -0.008925747126340866,
        -0.03766418993473053,
        -0.040566571056842804,
        -0.053432539105415344,
        0.05272825062274933,
        0.05492726340889931,
        0.04802054539322853,
        0.029549477621912956,
        -0.010709640569984913,
        -0.04718371480703354,
        0.05105707421898842,
        0.05285341665148735,
        -0.05484023690223694,
        -0.05306652933359146,
        -0.04497457668185234,
        0.05497053265571594,
        0.04074867442250252,
        -0.016459640115499496,
        -0.051109395921230316,
        -0.006313736084848642,
        0.04397351294755936,
        0.0065545085817575455,
        0.052906583994627,
        0.027728727087378502,
        0.053227510303258896,
        -0.05497153475880623,
        0.04460122063755989,
        -0.011129713617265224,
        -0.05421769246459007,
        -0.049811020493507385,
        0.05500474199652672,
        -0.027786048129200935,
        0.053669560700654984,
        -0.05277736112475395,
        0.02774510346353054,
        0.016776837408542633,
        -0.0440046526491642,
        0.055125247687101364,
        -0.05031523108482361,
        -0.008575916290283203,
        0.03960490599274635,
        -0.04724947363138199,
        0.04013138264417648,
        0.04878566786646843,
        0.05511317029595375,
        0.05505574867129326,
        -0.043613698333501816,
        0.04358632117509842,
        -0.054160647094249725,
        0.05477287620306015,
        -0.013014825992286205,
        -0.04066145792603493,
        -0.012978742830455303,
        0.04318520426750183,
        -0.05474381148815155,
        -0.054974306374788284,
        -0.04244542121887207,
        -0.04417937248945236,
        0.04061188921332359,
        0.055099423974752426,
        -0.042521826922893524,
        -0.05262349173426628,
        -0.05512508004903793,
        -0.05465710535645485,
        0.05508500337600708,
        -0.01703937165439129,
        0.05514504760503769,
        -0.05481854826211929,
        -0.045180793851614,
        0.04226837679743767,
        -0.03470102325081825,
        -0.05216680094599724,
        0.05475158989429474,
        0.014614054933190346,
        0.054533980786800385,
        0.042116839438676834,
        0.05041497200727463,
        -0.05505058169364929,
        -0.016575906425714493,
        -0.05332665517926216,
        -0.054236091673374176,
        -0.04870441555976868,
        0.05356736108660698,
        -0.05502968654036522,
        0.040947325527668,
        0.04126441478729248,
        -0.05243867263197899,
        -0.05389278754591942,
        -0.04648859426379204,
        0.037767671048641205,
        -0.01820702850818634,
        -0.054336730390787125,
        -0.020397622138261795,
        0.048396166414022446,
        0.054998286068439484,
        -0.03427399694919586,
        -0.05514359846711159,
        -0.05422690883278847,
        -0.052167631685733795,
        0.05458441376686096,
        0.03064793534576893,
        -0.025359313935041428,
        0.04537852481007576,
        0.05513269454240799,
        0.05483777076005936,
        -0.02954910323023796,
        0.05385959893465042,
        -0.008779585361480713,
        -0.027079641819000244,
        0.04295363277196884,
        -0.05321396887302399,
        0.04711099714040756,
        0.04421457648277283,
        -0.0376862995326519,
        -0.00940453726798296,
        -0.05148886889219284,
        0.02966536022722721,
        -0.031016355380415916,
        -0.025507772341370583,
        -0.05510639399290085,
        -0.04201551899313927,
        -0.0549517460167408,
        -0.04758842661976814,
        0.04144219309091568,
        -0.054914407432079315,
        0.04221995547413826,
        0.008159756660461426,
        -0.05493469163775444,
        0.05458662658929825,
        -0.0116425771266222,
        -0.022150227800011635,
        -0.011178279295563698,
        -0.05006900057196617,
        -0.05200253799557686,
        -0.05454866215586662,
        0.05314240977168083,
        -0.05068349093198776,
        -0.015502594411373138,
        0.019479883834719658,
        -0.02009628154337406,
        0.03487645462155342,
        0.055089667439460754,
        -0.031475529074668884,
        0.05481947213411331,
        0.054885976016521454,
        -0.054974112659692764,
        0.036991000175476074,
        -0.03521663695573807,
        0.055105891078710556,
        0.014323998242616653,
        0.046468403190374374,
        -0.053672824054956436,
        0.05081433430314064,
        -0.05480613932013512,
        0.02029724046587944,
        -0.0531928688287735,
        -0.04825490713119507,
        0.05258413404226303,
        -0.019115498289465904,
        -0.021495407447218895,
        -0.045625679194927216,
        -0.03281601890921593,
        0.05504420027136803,
        0.054899249225854874,
        -0.03403821587562561,
        0.054292354732751846,
        0.052449047565460205,
        -0.05441759526729584,
        0.04051646962761879,
        0.05372589826583862,
        0.03038717433810234,
        0.039779920130968094,
        -0.0010158674558624625,
        0.03106410801410675,
        -0.0277645755559206,
        0.05510159581899643,
        -0.041510164737701416,
        -0.0443449541926384,
        -0.015940004959702492,
        -0.015471147373318672,
        0.05461545288562775,
        0.05065743252635002,
        0.03176500275731087,
        0.04694546386599541,
        0.05510171130299568,
        0.052275702357292175,
        -0.05443219840526581,
        0.04668627306818962,
        -0.040713146328926086,
        -0.05479724705219269,
        0.05512052774429321,
        -0.053833674639463425,
        -0.01975403167307377,
        -0.04282541200518608,
        0.036986932158470154,
        -0.031235408037900925,
        0.030189653858542442,
        0.027057629078626633,
        -0.047541093081235886,
        0.017541037872433662,
        -0.04852403700351715,
        -0.054778214544057846,
        -0.02480422891676426,
        0.04294997453689575,
        0.05508437752723694,
        -0.03265716880559921,
        0.050801362842321396,
        -0.012952925637364388,
        -0.04679993912577629,
        0.04920883849263191
    ],
    [
        0.04678012430667877,
        0.02550683729350567,
        0.05414271727204323,
        0.004878472536802292,
        -0.04417581856250763,
        -0.035350218415260315,
        0.05507021024823189,
        -0.054967932403087616,
        0.05263689532876015,
        -0.0035078106448054314,
        0.05490371957421303,
        -0.047630734741687775,
        -0.05525831878185272,
        -0.05506255105137825,
        -0.05571100115776062,
        0.040308378636837006,
        -0.023722738027572632,
        0.05496392026543617,
        -0.047499943524599075,
        0.05559451878070831,
        0.050055261701345444,
        -0.04541240260004997,
        -0.04356052353978157,
        0.04688491299748421,
        0.049569498747587204,
        0.05309358611702919,
        0.023068858310580254,
        0.05333053320646286,
        0.023801205679774284,
        0.055556390434503555,
        0.016482051461935043,
        0.024924924597144127,
        -0.017413916066288948,
        -0.055411722511053085,
        0.049636147916316986,
        0.026243798434734344,
        -0.05066708102822304,
        0.03502391651272774,
        0.05456114560365677,
        0.055122777819633484,
        0.017620475962758064,
        0.05222969129681587,
        0.04925664886832237,
        0.05406393110752106,
        0.04830758273601532,
        0.006885408889502287,
        0.05382333695888519,
        0.03100706823170185,
        0.05435540899634361,
        -0.0108800595626235,
        0.055621810257434845,
        0.05503351613879204,
        -0.02462718077003956,
        0.018975092098116875,
        0.021492181345820427,
        -0.027882572263479233,
        0.048994772136211395,
        -0.021047044545412064,
        -0.0069219269789755344,
        0.051290467381477356,
        -0.050826821476221085,
        0.04934071749448776,
        -0.054644983261823654,
        -0.01835605502128601,
        -0.0214212778955698,
        -0.05422592908143997,
        -0.05564822256565094,
        -0.019673744216561317,
        0.00269763614051044,
        -0.055671244859695435,
        -0.055749088525772095,
        -0.053984180092811584,
        0.04393073171377182,
        0.055839117616415024,
        -0.055764175951480865,
        -0.04363948479294777,
        0.0557386577129364,
        0.05586690083146095,
        0.05116448178887367,
        -0.029386024922132492,
        -0.05108381435275078,
        -0.054927319288253784,
        -0.0549640990793705,
        0.043455883860588074,
        0.0503239631652832,
        0.05256769061088562,
        0.030962813645601273,
        -0.05106601119041443,
        -0.015118462033569813,
        0.030948031693696976,
        -0.05390707775950432,
        -0.05503852665424347,
        -0.02666943147778511,
        -0.05542298033833504,
        -0.04875946789979935,
        0.006889678072184324,
        -0.054930634796619415,
        -0.050740957260131836,
        0.04400459676980972,
        -0.012631668709218502,
        -0.054952222853899,
        0.046182673424482346,
        -0.032472994178533554,
        0.05475855618715286,
        0.039169132709503174,
        -0.04587065801024437,
        -0.054950084537267685,
        0.021596241742372513,
        -0.047127243131399155,
        -0.00800313800573349,
        0.04931694269180298,
        0.055861927568912506,
        -0.012310446240007877,
        -0.018658418208360672,
        0.04686800017952919,
        -0.03449590504169464,
        -0.013485321775078773,
        -0.05581734701991081,
        -0.027578018605709076,
        -0.007752499543130398,
        0.04974020645022392,
        -0.002894623437896371,
        0.05178653076291084,
        -0.05526808649301529,
        -0.050676729530096054,
        0.029209554195404053,
        0.05000653117895126,
        0.039286691695451736,
        0.04140971973538399,
        -0.025954654440283775,
        0.0523994043469429,
        -0.040113020688295364,
        -0.05584638565778732,
        -0.017802564427256584,
        -0.03587833046913147,
        -0.05540690943598747,
        0.05535490810871124,
        -0.05579669401049614,
        -0.03452169522643089,
        0.025308573618531227,
        -0.030579136684536934,
        -0.006389457732439041,
        0.05122902989387512,
        0.055314674973487854,
        0.03951169177889824,
        0.026789603754878044,
        0.018353689461946487,
        -0.054072149097919464,
        -0.022372201085090637,
        0.036145661026239395,
        -0.055645719170570374,
        -0.05473515763878822,
        -0.006083782762289047,
        -0.03735775500535965,
        -0.04580412060022354,
        0.035455506294965744,
        0.03766295686364174,
        0.051742613315582275,
        0.05563482269644737,
        0.04524875059723854,
        -0.05258041247725487,
        -0.05418851599097252,
        0.055462781339883804,
        0.05390921235084534,
        0.05488701909780502,
        0.029663335531949997,
        0.054766152054071426,
        0.055409662425518036,
        -0.007029352243989706,
        0.03612790256738663,
        -0.05577191337943077,
        0.055538248270750046,
        -0.01690383069217205,
        -0.05330411344766617,
        -0.05399845540523529,
        -0.018338536843657494,
        -0.007279330398887396,
        -0.050173576921224594,
        0.05535879358649254,
        -0.046662457287311554,
        0.006877291016280651,
        -0.00021678030316252261,
        0.053228992968797684,
        -0.028508339077234268,
        -0.05334880203008652,
        -0.02618809975683689,
        0.043001942336559296,
        0.05471199378371239,
        0.03289215639233589,
        -0.01691359095275402,
        -0.055860865861177444,
        -0.02331848256289959,
        0.016065560281276703,
        -0.015587624162435532,
        -0.03833987936377525,
        0.044935308396816254,
        0.054584238678216934,
        0.05353507399559021,
        -0.037551458925008774,
        -0.024507272988557816,
        -0.018856197595596313,
        0.055783629417419434,
        -0.05480067804455757,
        -0.018516967073082924,
        0.05482190474867821,
        -0.05475681275129318,
        0.026673519983887672,
        0.05056165158748627,
        -0.03554997220635414,
        -0.055346086621284485,
        -0.05464046820998192,
        0.05426198989152908,
        0.05512671917676926,
        0.05500290170311928,
        0.055643230676651,
        -0.05585595220327377,
        0.05583992227911949,
        -0.046393364667892456,
        0.055844180285930634,
        -0.05561930313706398,
        -0.006774177309125662,
        -0.03894929960370064,
        0.04216107726097107,
        0.00044825984514318407,
        0.04953893646597862,
        -0.04748889058828354,
        0.046514153480529785,
        0.02262074500322342,
        -0.01670810766518116,
        -0.0438338927924633,
        -0.052339449524879456,
        -0.048104383051395416,
        -0.02338026650249958,
        0.0511423759162426,
        0.05512315407395363,
        0.05560719966888428,
        0.05361220985651016,
        0.011644994840025902,
        -0.05519988760352135,
        0.0558287650346756,
        0.055374860763549805,
        -0.02412678673863411,
        -0.047210950404405594,
        -0.055864304304122925,
        0.027715444564819336,
        0.0324835442006588,
        0.005619481671601534,
        -0.007946943864226341,
        -0.008728220127522945,
        -0.048776499927043915,
        -0.047283582389354706,
        -0.048324622213840485,
        0.05462026596069336,
        -0.05488529056310654,
        0.053106844425201416,
        0.03668798878788948,
        0.04685887321829796,
        0.05431798845529556,
        0.05385446920990944,
        -0.04641316831111908,
        0.05211077257990837,
        0.0480254590511322,
        0.0509437657892704,
        0.05250280350446701,
        0.008040832355618477,
        0.04859485104680061,
        0.007986331358551979,
        0.02453770488500595,
        0.045240916311740875,
        0.05548718571662903,
        0.038926854729652405,
        -0.05488473176956177,
        0.05584777146577835,
        -0.05534655973315239,
        -0.008168302476406097,
        -0.006624340079724789,
        -0.039532873779535294,
        0.0486791767179966,
        -0.04765552654862404,
        -0.05402784422039986,
        -0.055328503251075745,
        0.05570200830698013,
        -0.03789747878909111,
        0.05195314437150955,
        0.054749395698308945,
        0.05276462435722351,
        0.01640680991113186,
        0.05554735288023949,
        -0.05123606696724892,
        0.0023283232003450394,
        0.011982325464487076,
        -0.03720701485872269,
        -0.050138749182224274,
        -0.055545173585414886,
        0.004109448287636042,
        -0.05448722839355469,
        0.05330332741141319,
        0.042384400963783264,
        -0.05584185570478439,
        0.05424461141228676,
        0.05546212196350098,
        0.04690885543823242,
        0.012645716778934002,
        0.037144266068935394,
        0.05403130501508713,
        -0.018029121682047844,
        -0.017893213778734207,
        0.05586716905236244,
        0.030892232432961464,
        -0.02899318002164364,
        0.024381574243307114,
        -0.03656564652919769,
        0.004051473923027515,
        0.053720369935035706,
        0.04166324809193611,
        -0.05295258387923241,
        -0.04649282619357109,
        0.05581171438097954,
        0.054186515510082245,
        -0.030700702220201492,
        0.0537673681974411,
        0.02356685884296894,
        0.04811008274555206,
        -0.04103099927306175,
        0.055578578263521194,
        0.05583278462290764,
        0.05585863068699837,
        0.034308865666389465,
        -0.0031990804709494114,
        -0.04096151143312454,
        0.05555853992700577,
        0.01194077730178833,
        0.05565713718533516,
        0.055866241455078125,
        0.05495952442288399,
        0.05564944073557854,
        0.0494859404861927,
        0.03366176038980484,
        0.055502600967884064,
        -0.03015078231692314,
        -0.05032525211572647,
        -0.05583452433347702,
        -0.03317852318286896,
        0.03535626828670502,
        0.012514295056462288,
        0.026507703587412834,
        0.052806805819272995,
        0.05231773853302002,
        -0.054812952876091,
        0.04289316385984421,
        0.048667073249816895,
        0.03135079890489578,
        -0.0016621736576780677,
        -0.05193592607975006,
        -0.05540304630994797,
        0.014701338484883308,
        -0.055719539523124695,
        -0.05298249423503876,
        -0.054925549775362015,
        0.006112865172326565,
        0.051998984068632126,
        -0.05000675097107887,
        0.04726041480898857,
        0.003480142680928111,
        -0.05064445361495018,
        -0.054682716727256775,
        0.0547061525285244,
        0.055706460028886795,
        0.00006582570495083928,
        -0.052656929939985275,
        0.0234614759683609,
        -0.014851964078843594,
        0.028676068410277367,
        -0.055632054805755615,
        0.042653221637010574,
        0.05586451664566994,
        0.05494458228349686,
        0.019779188558459282,
        0.05476663261651993,
        0.019083533436059952,
        -0.028255082666873932,
        -0.005946644581854343,
        -0.04652882367372513,
        0.020330632105469704,
        0.0453021340072155,
        -0.0532412976026535,
        0.012745673768222332,
        -0.045792948454618454,
        -0.05343569070100784,
        -0.05081263929605484,
        -0.038295671343803406,
        0.05457863584160805,
        -0.05370377004146576,
        0.005362858530133963,
        0.04836396872997284,
        0.05351835489273071,
        0.050029367208480835,
        -0.05183359980583191,
        -0.04213804751634598,
        -0.05573969706892967,
        -0.051024001091718674,
        0.05558082461357117,
        -0.049570273607969284,
        -0.010142311453819275,
        0.03142613172531128,
        -0.031740594655275345,
        0.05033181235194206,
        0.007967531681060791,
        0.05329730361700058,
        -0.03638497740030289,
        -0.019457684829831123,
        -0.055484894663095474,
        0.0197150819003582,
        0.05550909414887428,
        0.04310978204011917,
        -0.04793425649404526,
        0.05144396796822548,
        -0.01827322319149971,
        0.05401163548231125,
        0.05523360148072243,
        0.05584564805030823,
        -0.050748005509376526,
        -0.055085014551877975,
        -0.055800661444664,
        0.01851164922118187,
        0.017537862062454224,
        -0.030819326639175415,
        -0.04608739912509918,
        -0.018092138692736626,
        -0.053521133959293365,
        -0.05581565946340561,
        -0.021990308538079262,
        -0.055659402161836624,
        0.05233075097203255,
        -0.05555831640958786,
        -0.05551142618060112,
        0.055822908878326416,
        0.055865366011857986,
        -0.03388691693544388,
        -0.03614965081214905,
        -0.05224859341979027,
        0.05461578816175461,
        0.05038895830512047,
        0.050725363194942474,
        -0.04988054186105728,
        -0.015540028922259808,
        0.04690666124224663,
        -0.049527935683727264,
        0.04539887607097626,
        0.05561758950352669,
        0.023081231862306595,
        0.028487876057624817,
        -0.05153043195605278,
        -0.04554254934191704,
        0.016493013128638268,
        -0.05460706353187561,
        -0.05485514923930168,
        -0.049130070954561234,
        0.002645535860210657,
        0.05543185770511627,
        0.04148233309388161,
        -0.05574411153793335,
        0.020640160888433456,
        -0.05576534569263458,
        0.0026463724207133055,
        0.055444974452257156,
        -0.05522419884800911,
        0.049913451075553894,
        0.03631173074245453,
        -0.05172150582075119,
        0.05158062279224396,
        0.04628700390458107,
        0.05544602870941162,
        -0.018867038190364838,
        -0.05369430035352707,
        0.011809693649411201,
        -0.05437532067298889,
        -0.04866063967347145,
        -0.05545996502041817,
        -0.00010349480726290494,
        0.0018414314836263657,
        0.034324586391448975,
        -0.04589774087071419,
        0.049056392163038254,
        -0.02922242134809494,
        0.05525653064250946,
        0.02457832545042038,
        0.05532941222190857,
        -0.054880764335393906,
        -0.055230822414159775,
        -0.03037559613585472,
        -0.05473070219159126,
        0.03674275428056717,
        0.05540292337536812,
        -0.054045166820287704,
        -0.006828725803643465,
        0.0553336925804615,
        -0.055832214653491974,
        0.051568832248449326,
        0.05267222598195076,
        -0.05585585534572601,
        0.017914554104208946,
        0.04205792397260666,
        -0.046857040375471115,
        0.05512284114956856,
        -0.019718147814273834,
        0.054931577295064926,
        0.02928120084106922,
        0.05391605943441391,
        0.03490091487765312,
        0.055638719350099564,
        0.05086708813905716
    ],
    [
        0.04261024668812752,
        -0.03022397682070732,
        0.04653850942850113,
        0.03154529631137848,
        0.010250440798699856,
        -0.012166772969067097,
        0.053335849195718765,
        -0.05350450426340103,
        0.0406198725104332,
        0.053142648190259933,
        0.053405534476041794,
        -0.014766834676265717,
        -0.053387414664030075,
        -0.05337231978774071,
        -0.052937477827072144,
        -0.034639324992895126,
        0.05313662812113762,
        0.0534990094602108,
        -0.03969622403383255,
        0.0532100573182106,
        -0.022384921088814735,
        0.022807952016592026,
        -0.040438029915094376,
        0.0512574277818203,
        0.051160022616386414,
        0.0431973896920681,
        -0.012045584619045258,
        0.04189470037817955,
        -0.03283446654677391,
        0.05301154777407646,
        0.048144273459911346,
        -0.05330809950828552,
        -0.0497717559337616,
        -0.053497564047575,
        0.051752593368291855,
        -0.04909074306488037,
        -0.052998919039964676,
        0.05078067630529404,
        0.05305354297161102,
        0.05350622534751892,
        0.0416460782289505,
        0.0442490316927433,
        0.048342734575271606,
        0.005949933081865311,
        0.03163033723831177,
        -0.010671286843717098,
        0.05318087339401245,
        0.05349746346473694,
        0.052871908992528915,
        0.005267884116619825,
        0.05267634242773056,
        0.05259634926915169,
        -0.03949935361742973,
        -0.030771195888519287,
        -0.04691815748810768,
        -0.030633052811026573,
        0.052449751645326614,
        0.00902145728468895,
        -0.05103455111384392,
        -0.04091048613190651,
        -0.044761642813682556,
        0.046054236590862274,
        -0.05112006515264511,
        -0.05288809537887573,
        -0.0072329542599618435,
        -0.05344516783952713,
        -0.0524548776447773,
        -0.05209767073392868,
        -0.0027272196020931005,
        -0.05161111056804657,
        -0.05017026886343956,
        -0.05328759178519249,
        0.05150793492794037,
        0.04445284977555275,
        0.011886932887136936,
        0.026425708085298538,
        0.04982058331370354,
        0.05350107699632645,
        0.0010328266071155667,
        0.050069354474544525,
        -0.04737834259867668,
        -0.05325517803430557,
        -0.05348062142729759,
        0.05035397410392761,
        -0.053288783878088,
        0.018174635246396065,
        0.008128223940730095,
        0.0005162026500329375,
        -0.018705245107412338,
        0.053499799221754074,
        -0.053339436650276184,
        -0.05237564072012901,
        -0.048258211463689804,
        -0.05136144906282425,
        -0.013341544196009636,
        0.05322927236557007,
        -0.04791639745235443,
        -0.0003751782060135156,
        -0.045138485729694366,
        0.015389482490718365,
        -0.053463954478502274,
        -0.0022163651883602142,
        0.011844947934150696,
        0.051840465515851974,
        0.05323734134435654,
        0.05315355584025383,
        -0.0534687265753746,
        0.011353406123816967,
        -0.04778696969151497,
        -0.0531298890709877,
        -0.051789771765470505,
        0.04240867868065834,
        0.03876110538840294,
        0.03173235431313515,
        0.05189984664320946,
        0.05026351287961006,
        -0.05127113685011864,
        -0.05321751907467842,
        0.05250244215130806,
        -0.053360629826784134,
        0.02457994408905506,
        -0.05087568610906601,
        0.05040154233574867,
        -0.05347662791609764,
        -0.032506778836250305,
        0.02108800783753395,
        -0.053231243044137955,
        0.023496214300394058,
        0.05043228343129158,
        0.052439235150814056,
        -0.03275503218173981,
        -0.04838790372014046,
        -0.05350635200738907,
        0.05272841453552246,
        -0.026462577283382416,
        -0.05316738039255142,
        0.053324129432439804,
        -0.053500473499298096,
        -0.04258405417203903,
        0.0487552173435688,
        -0.05290950462222099,
        -0.053369633853435516,
        0.03673791512846947,
        0.053340040147304535,
        -0.03444682061672211,
        0.05345715954899788,
        -0.05016227066516876,
        0.0226683858782053,
        -0.04912034794688225,
        0.053191374987363815,
        -0.05297981575131416,
        -0.053270962089300156,
        0.04084038734436035,
        -0.053491417318582535,
        -0.01815551519393921,
        -0.024736957624554634,
        -0.04967965558171272,
        0.050608497112989426,
        0.05348307639360428,
        -0.05165885016322136,
        -0.037474386394023895,
        -0.050135985016822815,
        0.051651567220687866,
        0.03354787081480026,
        0.05342461168766022,
        0.05127318203449249,
        0.005717345047742128,
        -0.02203141525387764,
        -0.053235143423080444,
        0.004405477549880743,
        -0.05326627939939499,
        0.03278562054038048,
        -0.05328218638896942,
        -0.053228508681058884,
        -0.04955468699336052,
        0.005064855329692364,
        0.053097862750291824,
        -0.04035167396068573,
        0.05299041420221329,
        -0.05338640511035919,
        -0.05305635184049606,
        0.05291498079895973,
        0.05342318117618561,
        -0.03117302805185318,
        -0.0534275658428669,
        0.05160783976316452,
        0.05309334397315979,
        0.04712972044944763,
        -0.04421549662947655,
        -0.05350194871425629,
        -0.05350568890571594,
        0.007735608611255884,
        0.05296890437602997,
        -0.052395254373550415,
        0.029379824176430702,
        -0.05231925845146179,
        0.046627406030893326,
        -0.0409286692738533,
        -0.053214699029922485,
        0.032630227506160736,
        0.002920167287811637,
        0.0521647147834301,
        -0.05319716036319733,
        -0.052703846246004105,
        0.05341221019625664,
        -0.053503379225730896,
        -0.0033695558086037636,
        0.03192213177680969,
        -0.0531136654317379,
        0.0502624474465847,
        -0.053476445376873016,
        0.0355265811085701,
        0.026164446026086807,
        0.05350487679243088,
        0.05349920690059662,
        -0.053506381809711456,
        0.029448000714182854,
        -0.01351330615580082,
        0.053487375378608704,
        -0.03735499829053879,
        0.03893136978149414,
        0.0003237773780710995,
        0.020179416984319687,
        -0.01311948150396347,
        0.05207398161292076,
        0.0028282147832214832,
        0.05308764800429344,
        0.029061468318104744,
        0.04468052461743355,
        -0.04619722440838814,
        -0.05107167735695839,
        -0.05036461353302002,
        0.043385304510593414,
        0.03767271712422371,
        0.04769862815737724,
        0.03891158103942871,
        0.044399335980415344,
        0.014495315961539745,
        -0.05330638214945793,
        0.05349764972925186,
        0.05042697489261627,
        -0.053385328501462936,
        -0.044171396642923355,
        -0.05350639298558235,
        0.027478668838739395,
        0.00767028983682394,
        -0.045380689203739166,
        0.00045722941285930574,
        0.0486861988902092,
        -0.05190745368599892,
        0.04076638072729111,
        -0.04041557386517525,
        0.053397852927446365,
        -0.05304223671555519,
        -0.036693308502435684,
        0.053092215210199356,
        0.01777728833258152,
        0.04494016617536545,
        -0.009839138947427273,
        -0.025896256789565086,
        -0.0072789485566318035,
        0.009553599171340466,
        -0.05014900863170624,
        0.048895373940467834,
        -0.05310797691345215,
        0.05348808318376541,
        0.04687957093119621,
        0.05254984647035599,
        0.0534503348171711,
        -0.006658728700131178,
        0.053468696773052216,
        -0.05236704275012016,
        0.05348668619990349,
        -0.053121697157621384,
        0.030390165746212006,
        0.03904329612851143,
        -0.047601234167814255,
        0.05138874799013138,
        0.052051179111003876,
        0.014185142703354359,
        -0.053491201251745224,
        0.053302157670259476,
        0.051999978721141815,
        0.052344247698783875,
        0.052316129207611084,
        0.05140472203493118,
        0.011961566284298897,
        0.04683317244052887,
        -0.05337784066796303,
        0.01733625866472721,
        -0.04748805984854698,
        0.03560345247387886,
        -0.05154882371425629,
        0.05078615993261337,
        0.050786834210157394,
        -0.05171974375844002,
        0.05348421633243561,
        0.05201262608170509,
        -0.05222274735569954,
        0.050133462995290756,
        -0.022247103974223137,
        -0.007421309128403664,
        0.016486164182424545,
        0.029757335782051086,
        0.01625474914908409,
        -0.04540364071726799,
        0.05338773503899574,
        0.05350233241915703,
        0.033946454524993896,
        0.0022030945401638746,
        0.03730994090437889,
        0.027698548510670662,
        -0.007273333612829447,
        0.017483297735452652,
        -0.005904020741581917,
        -0.0504683218896389,
        -0.03884560614824295,
        0.05350632965564728,
        0.051234252750873566,
        -0.02965593710541725,
        0.04585928097367287,
        0.05190562456846237,
        0.016904525458812714,
        -0.042860131710767746,
        0.05336097255349159,
        0.043154437094926834,
        0.05350498855113983,
        -0.008251999504864216,
        0.033294811844825745,
        -0.032014332711696625,
        0.053479261696338654,
        -0.024870052933692932,
        0.041987448930740356,
        0.053502436727285385,
        0.04009317606687546,
        0.04779685661196709,
        0.0534963384270668,
        -0.0008860517991706729,
        0.05350511521100998,
        0.04600617289543152,
        0.03665664792060852,
        -0.05339394882321358,
        0.04659244790673256,
        -0.046788912266492844,
        -0.032723892480134964,
        0.04892495647072792,
        0.048494596034288406,
        0.050945259630680084,
        -0.05343543365597725,
        0.03417239710688591,
        0.04437397047877312,
        0.0527765192091465,
        0.009587917476892471,
        -0.053183380514383316,
        -0.053463783115148544,
        -0.05036012828350067,
        -0.05348428711295128,
        -0.05227647349238396,
        -0.050950732082128525,
        -0.05134344846010208,
        0.04526934400200844,
        0.0432569719851017,
        -0.014487306587398052,
        -0.036295220255851746,
        0.03448982909321785,
        -0.04332270473241806,
        0.05346647650003433,
        -0.006962363142520189,
        0.04561289772391319,
        -0.053028207272291183,
        -0.05081655830144882,
        -0.053385525941848755,
        -0.05333436653017998,
        -0.05287054181098938,
        0.041542451828718185,
        0.05350639298558235,
        0.0534835048019886,
        0.0023361153434962034,
        0.0534694641828537,
        0.05274702608585358,
        0.021052712574601173,
        -0.03499327227473259,
        -0.018968576565384865,
        0.003320449497550726,
        0.024726087227463722,
        -0.053503524512052536,
        -0.049682751297950745,
        0.013265397399663925,
        -0.018641600385308266,
        -0.05347803980112076,
        -0.05350124090909958,
        -0.04126144200563431,
        -0.052086975425481796,
        -0.05322529375553131,
        0.007962348870933056,
        0.05317617207765579,
        -0.020756317302584648,
        -0.011874565854668617,
        -0.04533253237605095,
        -0.053463052958250046,
        -0.03930087760090828,
        0.0532267689704895,
        -0.05323512852191925,
        -0.048143960535526276,
        -0.046511199325323105,
        0.05310606583952904,
        0.005422899033874273,
        -0.03876737505197525,
        0.053211770951747894,
        -0.017603613436222076,
        -0.028175892308354378,
        0.015641195699572563,
        -0.04399186745285988,
        0.035089630633592606,
        0.04943852871656418,
        -0.052746694535017014,
        -0.02665156126022339,
        0.04499122500419617,
        0.053097039461135864,
        0.048830922693014145,
        0.05341954901814461,
        -0.02947608008980751,
        0.029343128204345703,
        -0.053504444658756256,
        -0.008671478368341923,
        -0.053246404975652695,
        -0.05016559734940529,
        0.026698987931013107,
        -0.04928802326321602,
        -0.05329558998346329,
        -0.04844285175204277,
        -0.04399416968226433,
        -0.04735163599252701,
        0.0315517820417881,
        -0.053468480706214905,
        -0.045264288783073425,
        -0.01720474660396576,
        0.053495462983846664,
        -0.018194228410720825,
        -0.04803403094410896,
        0.02555018477141857,
        0.041668545454740524,
        0.05325980857014656,
        0.05087330937385559,
        -0.03273293003439903,
        -0.031773798167705536,
        0.04914489760994911,
        -0.05310722067952156,
        0.024686530232429504,
        0.05349787324666977,
        0.05194464325904846,
        0.05295705050230026,
        -0.02382710389792919,
        -0.05134342238306999,
        0.053505972027778625,
        -0.027891745790839195,
        -0.049136269837617874,
        -0.04534117504954338,
        0.04388252645730972,
        0.05350377783179283,
        0.053499430418014526,
        -0.04449013993144035,
        0.05260968208312988,
        -0.053504567593336105,
        0.05309916287660599,
        0.053408291190862656,
        -0.05350525677204132,
        0.053482960909605026,
        0.05259598791599274,
        -0.05344191938638687,
        -0.03335016220808029,
        -0.0055015115067362785,
        0.05222248658537865,
        -0.05350135266780853,
        -0.053135789930820465,
        0.01027006097137928,
        -0.0012420512503013015,
        -0.00018322943651583046,
        -0.053426310420036316,
        0.009644529782235622,
        0.047387924045324326,
        0.0522836335003376,
        -0.05205048620700836,
        0.052186671644449234,
        -0.05248128995299339,
        0.05299368128180504,
        0.053041838109493256,
        0.029293615370988846,
        -0.05093889310956001,
        -0.050456199795007706,
        0.039925456047058105,
        -0.05349288508296013,
        0.05341179296374321,
        0.051523465663194656,
        -0.04856494069099426,
        0.05312636122107506,
        -0.04510580748319626,
        -0.05206914618611336,
        0.04999936744570732,
        0.05219871550798416,
        -0.05296662449836731,
        0.05075591057538986,
        -0.032998211681842804,
        -0.05349625274538994,
        0.05348357930779457,
        0.0534348264336586,
        0.05346954986453056,
        -0.010633043944835663,
        0.05302887782454491,
        0.05022186413407326,
        0.05313122645020485,
        -0.015989404171705246
    ],
    [
        0.05367541313171387,
        -0.011379539966583252,
        -0.0424962043762207,
        0.016572119668126106,
        0.051128942519426346,
        -0.04486957564949989,
        0.05332415923476219,
        -0.04828210547566414,
        0.04908301308751106,
        -0.05343781039118767,
        0.039631642401218414,
        0.05369339883327484,
        -0.05339841917157173,
        -0.04757848009467125,
        -0.053311631083488464,
        0.052689388394355774,
        -0.05274316295981407,
        0.04053172841668129,
        -0.05203012377023697,
        -0.04994778335094452,
        -0.0524473711848259,
        0.04166056588292122,
        0.05345207080245018,
        -0.03351704031229019,
        -0.010355189442634583,
        -0.007716793101280928,
        0.052851125597953796,
        0.049359340220689774,
        0.025927670300006866,
        0.05370055511593819,
        0.04074916988611221,
        -0.05359944701194763,
        -0.0204659104347229,
        -0.053389355540275574,
        -0.04647783935070038,
        -0.0520380400121212,
        -0.04694164916872978,
        0.03986905887722969,
        0.011558241210877895,
        0.05364139378070831,
        0.05339108780026436,
        -0.04042891785502434,
        0.04702538996934891,
        0.008327463641762733,
        0.05312306061387062,
        0.004802138078957796,
        0.04808424040675163,
        0.053693801164627075,
        0.05347130447626114,
        0.05323019623756409,
        0.05044537037611008,
        0.04961949586868286,
        0.053297389298677444,
        0.03167402371764183,
        -0.048639412969350815,
        -0.03327865153551102,
        -0.05027950927615166,
        -0.03351182863116264,
        0.002309053670614958,
        0.05098100006580353,
        -0.04362926632165909,
        -0.03899010643362999,
        -0.05106678232550621,
        0.053564559668302536,
        -0.04267764836549759,
        -0.01688471809029579,
        0.04618622362613678,
        -0.0501582995057106,
        -0.039949264377355576,
        -0.05370102822780609,
        -0.05360459163784981,
        -0.05338030681014061,
        0.014062872156500816,
        0.03451858460903168,
        -0.05369800701737404,
        -0.014631185680627823,
        0.043454498052597046,
        0.05203075706958771,
        0.042430952191352844,
        -0.040719173848629,
        -0.032149460166692734,
        0.05366687849164009,
        -0.04990515112876892,
        0.04933544993400574,
        -0.046849217265844345,
        0.048621755093336105,
        0.008321360684931278,
        0.04962660372257233,
        0.03685864061117172,
        0.05368851497769356,
        -0.05278285592794418,
        0.035563647747039795,
        -0.0351298525929451,
        -0.05367044359445572,
        0.03864277899265289,
        0.04865112900733948,
        -0.048362601548433304,
        -0.043036386370658875,
        -0.051538530737161636,
        0.018559487536549568,
        -0.05370044708251953,
        0.020069338381290436,
        0.05088087543845177,
        0.053516339510679245,
        0.017797550186514854,
        -0.044221922755241394,
        -0.05241695046424866,
        0.03530916944146156,
        0.05165607109665871,
        -0.04752947762608528,
        -0.05223914608359337,
        -0.05366415157914162,
        0.045121923089027405,
        -0.010877730324864388,
        -0.0013065737439319491,
        0.01787310093641281,
        -0.00960327684879303,
        -0.050952013581991196,
        -0.053675007075071335,
        -0.04109365865588188,
        0.028967898339033127,
        -0.028909342363476753,
        -0.01799386367201805,
        -0.03358598053455353,
        -0.053566403687000275,
        0.05332643538713455,
        -0.04674850031733513,
        0.047798141837120056,
        0.05150030925869942,
        0.031493447721004486,
        0.053204186260700226,
        -0.04638444632291794,
        -0.053690362721681595,
        0.05079471319913864,
        -0.05273599922657013,
        -0.05185754969716072,
        0.05324346199631691,
        -0.05064624175429344,
        -0.02020874246954918,
        0.05274469777941704,
        0.053262095898389816,
        0.053699322044849396,
        0.05027227848768234,
        0.05015602707862854,
        -0.044942695647478104,
        -0.025893909856677055,
        -0.019840925931930542,
        -0.05206714943051338,
        0.05309516564011574,
        0.053699541836977005,
        -0.05281970649957657,
        -0.05360599234700203,
        -0.0535489059984684,
        -0.05345718562602997,
        -0.04607871547341347,
        0.020872917026281357,
        0.05361025035381317,
        -0.0059321229346096516,
        0.02311672270298004,
        0.04532404616475105,
        0.05358313396573067,
        -0.043239038437604904,
        0.04604501277208328,
        -0.040410201996564865,
        0.03450582176446915,
        -0.026738621294498444,
        0.016916219145059586,
        -0.05348636209964752,
        -0.04827343672513962,
        0.04187723621726036,
        -0.050468601286411285,
        0.017093775793910027,
        -0.044402699917554855,
        0.013441726565361023,
        -0.04964488372206688,
        0.05358994007110596,
        -0.05370103940367699,
        0.024260977283120155,
        0.014163165353238583,
        -0.04668862745165825,
        0.041199591010808945,
        0.053539395332336426,
        0.053549543023109436,
        0.023983709514141083,
        -0.007279847748577595,
        -0.04200815409421921,
        -0.053613658994436264,
        0.04957633465528488,
        -0.05292069911956787,
        -0.038298919796943665,
        0.012161191552877426,
        -0.04474584758281708,
        0.01023972686380148,
        0.05129700526595116,
        -0.004013878758996725,
        -0.04984882101416588,
        0.03992612659931183,
        0.025616539642214775,
        -0.021996665745973587,
        0.05066455155611038,
        0.049282677471637726,
        -0.04279366880655289,
        -0.01627185381948948,
        0.03843765705823898,
        0.051429957151412964,
        -0.023833245038986206,
        0.002112465910613537,
        0.03609555959701538,
        -0.04884888976812363,
        0.02925127185881138,
        -0.0528651662170887,
        -0.0051422566175460815,
        -0.04758595675230026,
        -0.028513140976428986,
        0.05369444563984871,
        0.012945610098540783,
        0.04847344011068344,
        0.03927094116806984,
        0.053611863404512405,
        -0.04965624958276749,
        0.05154638737440109,
        0.04192638024687767,
        -0.05363395810127258,
        -0.05360279604792595,
        0.05347937345504761,
        -0.05365884304046631,
        0.033866602927446365,
        0.03191684931516647,
        -0.041194066405296326,
        -0.04466160759329796,
        0.05026090145111084,
        -0.05368649587035179,
        -0.033031221479177475,
        0.05253442004323006,
        0.05311501398682594,
        0.02584964781999588,
        0.05281475558876991,
        0.02755621261894703,
        -0.010292218998074532,
        0.05354589596390724,
        0.053242277354002,
        0.05369463935494423,
        -0.03346990793943405,
        -0.05357077345252037,
        0.0001649188343435526,
        0.006161438766866922,
        0.04585352540016174,
        0.03789842128753662,
        -0.03978818282485008,
        0.010197841562330723,
        -0.05289974808692932,
        -0.053493477404117584,
        0.05256616324186325,
        -0.04253203421831131,
        0.03982505947351456,
        0.05369766056537628,
        0.0498989038169384,
        -0.023249493911862373,
        0.05353555828332901,
        0.05346497520804405,
        0.05364007502794266,
        0.02133665792644024,
        0.04654250293970108,
        0.035719551146030426,
        -0.031813789159059525,
        0.0320393443107605,
        0.05355457589030266,
        0.040507614612579346,
        0.04625651612877846,
        -0.03606408089399338,
        -0.05220109596848488,
        -0.05369766056537628,
        0.05362134799361229,
        -0.05363204702734947,
        0.022260291501879692,
        0.04963214322924614,
        -0.05307967588305473,
        -0.05180692672729492,
        -0.05368153378367424,
        -0.05272843316197395,
        -0.052711814641952515,
        0.016496701166033745,
        0.04190119355916977,
        0.05370050668716431,
        0.05370048061013222,
        -0.05238199979066849,
        0.04342876002192497,
        -0.053363364189863205,
        -0.05180107057094574,
        0.01550086960196495,
        -0.053261030465364456,
        0.05074693262577057,
        -0.0067169261164963245,
        -0.013919671066105366,
        0.009090176783502102,
        -0.05126442387700081,
        0.05369935184717178,
        0.05362314730882645,
        -0.05201150104403496,
        0.052794914692640305,
        -0.01094054989516735,
        0.052942920476198196,
        -0.034738682210445404,
        0.048530593514442444,
        -0.012926614843308926,
        -0.02421124279499054,
        -0.05366850644350052,
        0.0514836311340332,
        0.04095158353447914,
        -0.03414149209856987,
        0.05076385289430618,
        -0.05367802456021309,
        -0.004970409441739321,
        0.05309441685676575,
        -0.05364396795630455,
        -0.04216044023633003,
        0.000011440609341661911,
        0.05364838242530823,
        0.003351980820298195,
        -0.05266496166586876,
        -0.04979601129889488,
        0.004929624497890472,
        0.05350979417562485,
        -0.050715573132038116,
        0.05317680910229683,
        0.05366780236363411,
        0.03922850266098976,
        0.05030134692788124,
        0.004267494659870863,
        -0.0422559417784214,
        0.027501754462718964,
        0.03736531361937523,
        0.05367696285247803,
        0.029188333079218864,
        0.05246337130665779,
        0.04134858027100563,
        -0.04996548220515251,
        0.05352797359228134,
        -0.0005360454088076949,
        0.05361422151327133,
        0.04096442833542824,
        -0.052860815078020096,
        -0.053178440779447556,
        -0.014824999496340752,
        0.05370103940367699,
        0.051615431904792786,
        0.050430722534656525,
        0.03670915216207504,
        -0.03892488777637482,
        0.0529269240796566,
        -0.053700949996709824,
        0.05343969538807869,
        -0.03842409700155258,
        -0.053590815514326096,
        -0.05368497222661972,
        0.05221259966492653,
        -0.05370023101568222,
        -0.05004162713885307,
        -0.05086130648851395,
        0.019299717620015144,
        0.05285666510462761,
        0.05233265087008476,
        -0.04992689937353134,
        -0.0495658740401268,
        0.003939186222851276,
        -0.05355895683169365,
        0.05370031297206879,
        -0.02154872752726078,
        0.05369105562567711,
        -0.053699810057878494,
        -0.052714474499225616,
        -0.05310527980327606,
        0.053636979311704636,
        0.02140946313738823,
        0.050181902945041656,
        0.05076105520129204,
        0.006362816784530878,
        0.05235452950000763,
        -0.0014014918124303222,
        -0.05338837578892708,
        -0.015955910086631775,
        -0.043683867901563644,
        -0.05213316157460213,
        0.006347470451146364,
        0.046770576387643814,
        -0.05330212786793709,
        0.04286976903676987,
        -0.05322884023189545,
        -0.05210794880986214,
        -0.053540803492069244,
        -0.05356741324067116,
        0.039894118905067444,
        0.04638252407312393,
        -0.015157496556639671,
        -0.05338536202907562,
        0.05253293737769127,
        0.0536973774433136,
        -0.05322481319308281,
        -0.053690798580646515,
        -0.053348299115896225,
        -0.044870298355817795,
        0.03287338465452194,
        -0.003260333789512515,
        0.016175175085663795,
        0.05248644948005676,
        0.05194179713726044,
        0.05322641506791115,
        -0.026172928512096405,
        0.05294777825474739,
        -0.051544710993766785,
        0.04633641988039017,
        0.0033770399168133736,
        -0.016364779323339462,
        0.0444236695766449,
        0.032240524888038635,
        0.04571682587265968,
        0.051614195108413696,
        0.05304750055074692,
        0.03373939171433449,
        0.050744809210300446,
        0.0456075593829155,
        0.05367518216371536,
        -0.04918857663869858,
        -0.05366171896457672,
        0.05262993276119232,
        -0.0523940809071064,
        -0.04752644523978233,
        0.03458300977945328,
        -0.053643520921468735,
        -0.05312268063426018,
        0.010505104437470436,
        -0.0533386655151844,
        -0.03854089602828026,
        0.006740985903888941,
        0.05137904733419418,
        0.03637402877211571,
        -0.05055972561240196,
        0.05363961681723595,
        0.033635083585977554,
        -0.04250890016555786,
        0.01859106309711933,
        0.045874856412410736,
        0.04964502528309822,
        0.04997268691658974,
        -0.042916521430015564,
        0.006070795003324747,
        0.052823882550001144,
        -0.053689103573560715,
        0.052846986800432205,
        0.02590792626142502,
        -0.053697697818279266,
        0.0532335564494133,
        0.010345294140279293,
        -0.04860397428274155,
        0.052327852696180344,
        -0.05206950381398201,
        -0.033116597682237625,
        0.013998769223690033,
        -0.03243996575474739,
        0.04804564639925957,
        0.05328075587749481,
        0.052839357405900955,
        -0.053651243448257446,
        0.044227905571460724,
        0.05339929834008217,
        0.05363515019416809,
        -0.05369795486330986,
        0.05367829278111458,
        -0.053075335919857025,
        -0.052911482751369476,
        0.049341607838869095,
        0.021025806665420532,
        0.004773732740432024,
        -0.031976912170648575,
        0.006955981720238924,
        0.04404951259493828,
        -0.05363179370760918,
        0.02511288970708847,
        -0.053239855915308,
        -0.05269189551472664,
        -0.053477153182029724,
        0.013021868653595448,
        0.052971251308918,
        0.046481117606163025,
        0.006617551203817129,
        0.051509302109479904,
        0.053656160831451416,
        -0.024478808045387268,
        -0.04766058176755905,
        0.005193214863538742,
        -0.03263246640563011,
        -0.05369555950164795,
        0.044019557535648346,
        0.04775647819042206,
        0.05323700234293938,
        0.05334131047129631,
        -0.04951063543558121,
        -0.016870176419615746,
        0.05333714932203293,
        0.05368079990148544,
        -0.053622059524059296,
        -0.01116865687072277,
        -0.04681597650051117,
        -0.0507378950715065,
        0.0533626414835453,
        -0.007171497214585543,
        0.05307648330926895,
        -0.012860148213803768,
        -0.04593253880739212,
        -0.05367826670408249,
        0.05369991064071655,
        0.0536360889673233
    ],
    [
        -0.05593618378043175,
        0.050681836903095245,
        -0.0011275598080828786,
        0.029176628217101097,
        0.05148009583353996,
        -0.0008453185437247157,
        0.05708228051662445,
        -0.03750534728169441,
        0.047089867293834686,
        -0.0427979901432991,
        0.04575828090310097,
        0.04696764051914215,
        -0.055061452090740204,
        -0.03706007078289986,
        -0.0551353394985199,
        0.05384784936904907,
        -0.0479755625128746,
        0.054645538330078125,
        -0.054362185299396515,
        -0.040677208453416824,
        -0.054121244698762894,
        -0.022657977417111397,
        0.056919388473033905,
        0.034402020275592804,
        0.049493659287691116,
        0.03160683065652847,
        0.05475207045674324,
        0.056959863752126694,
        -0.039402902126312256,
        -0.04861260578036308,
        0.05130859464406967,
        -0.05682945251464844,
        0.0023957588709890842,
        -0.05615966022014618,
        0.03354388475418091,
        -0.05394488200545311,
        -0.05095764994621277,
        -0.04830571264028549,
        -0.0520993135869503,
        0.03350267559289932,
        -0.04658147692680359,
        0.026449045166373253,
        0.052666131407022476,
        -0.05020555108785629,
        0.05553140863776207,
        0.0234337467700243,
        0.05555306747555733,
        0.056206852197647095,
        0.055045969784259796,
        -0.028590910136699677,
        0.04099154844880104,
        0.014138320460915565,
        0.04377571493387222,
        -0.045743897557258606,
        0.04769398272037506,
        -0.05234690383076668,
        -0.05447439104318619,
        -0.006319364998489618,
        -0.030171116814017296,
        0.03761425241827965,
        -0.038247231394052505,
        0.0004655955417547375,
        -0.0525532029569149,
        0.011740378104150295,
        -0.018624769523739815,
        -0.0024106318596750498,
        0.0024753687903285027,
        -0.056482307612895966,
        -0.03987586498260498,
        -0.05711919814348221,
        -0.055669404566287994,
        -0.05685945600271225,
        0.051942452788352966,
        0.04428015276789665,
        -0.05653946474194527,
        -0.011117661371827126,
        0.03378763422369957,
        0.056824829429388046,
        0.046452268958091736,
        -0.041092947125434875,
        0.000968571228440851,
        0.044708386063575745,
        -0.05192052572965622,
        0.029544377699494362,
        0.040682028979063034,
        -0.02494044415652752,
        -0.04918142035603523,
        -0.05397164076566696,
        -0.01173223927617073,
        0.05668840557336807,
        -0.05446017533540726,
        0.02396729402244091,
        -0.015282314270734787,
        -0.050100091844797134,
        0.0284554623067379,
        -0.028091011568903923,
        -0.05124738812446594,
        -0.045837875455617905,
        -0.05594983324408531,
        -0.025636736303567886,
        -0.056733887642621994,
        -0.039300814270973206,
        0.04616080969572067,
        0.054065123200416565,
        0.018885307013988495,
        -0.023439673706889153,
        -0.048222824931144714,
        0.02339586615562439,
        -0.007728293538093567,
        0.028491010889410973,
        -0.054527997970581055,
        -0.05635110288858414,
        -0.02822280488908291,
        -0.05558958649635315,
        0.043538857251405716,
        -0.037620097398757935,
        -0.050776347517967224,
        -0.05615205317735672,
        -0.04587261751294136,
        0.009867501445114613,
        -0.0463380441069603,
        -0.013162079267203808,
        0.004159124102443457,
        0.04545816406607628,
        -0.057054270058870316,
        0.0019980305805802345,
        -0.01958579011261463,
        0.05044763535261154,
        0.056962061673402786,
        0.03044387511909008,
        0.050637755542993546,
        -0.041090745478868484,
        0.007561510894447565,
        0.05459098890423775,
        -0.039826370775699615,
        -0.05281998589634895,
        -0.002148446161299944,
        -0.05464428663253784,
        -0.05290592089295387,
        0.053078942000865936,
        0.05217484012246132,
        0.03405905142426491,
        0.024438589811325073,
        0.04318605363368988,
        0.036964789032936096,
        0.04280903562903404,
        0.05689491704106331,
        0.04640665277838707,
        -0.056334298104047775,
        0.03355433791875839,
        -0.02491527982056141,
        -0.056788649410009384,
        -0.05673569440841675,
        -0.0017659316072240472,
        -0.05131521075963974,
        -0.051206402480602264,
        0.05686862766742706,
        -0.019436057657003403,
        -0.04505613073706627,
        0.053512800484895706,
        0.05189799889922142,
        0.04439583420753479,
        -0.00084965635323897,
        -0.056733954697847366,
        -0.05442829057574272,
        -0.03314948454499245,
        -0.02685905061662197,
        -0.0544392392039299,
        0.02785395085811615,
        0.041520390659570694,
        -0.01997339352965355,
        -0.026980673894286156,
        -0.05707598477602005,
        0.04506080225110054,
        0.011196445673704147,
        0.055872004479169846,
        -0.05543450266122818,
        -0.02113618515431881,
        0.055693041533231735,
        -0.030314568430185318,
        0.03614698350429535,
        0.045628566294908524,
        0.05633728951215744,
        -0.0005447427975013852,
        0.05001155659556389,
        -0.008644798770546913,
        -0.00817638449370861,
        0.054542332887649536,
        -0.0512213371694088,
        -0.05407779663801193,
        -0.049865495413541794,
        -0.050395406782627106,
        -0.03368828073143959,
        -0.005702925845980644,
        -0.006337873637676239,
        0.0019281123531982303,
        0.05549588426947594,
        -0.04845200106501579,
        -0.05225648358464241,
        -0.006436425726860762,
        -0.04546177759766579,
        0.056754108518362045,
        0.04830928146839142,
        -0.022710297256708145,
        -0.0010764910839498043,
        0.052147023379802704,
        0.020809819921851158,
        -0.010927095077931881,
        -0.03282465785741806,
        -0.02957042120397091,
        -0.052469585090875626,
        -0.04858902096748352,
        0.012826723046600819,
        0.0019500579219311476,
        0.05683933198451996,
        -0.00536153931170702,
        0.053538400679826736,
        0.048172324895858765,
        0.0566672645509243,
        -0.053521282970905304,
        0.01924554631114006,
        -0.03080803155899048,
        -0.05620063096284866,
        -0.057092200964689255,
        0.0558089017868042,
        -0.0562991201877594,
        -0.00369794899597764,
        0.04702313616871834,
        -0.02548462338745594,
        -0.05646023899316788,
        -0.047175321727991104,
        -0.05348756164312363,
        -0.05489257723093033,
        0.026805616915225983,
        0.03962152451276779,
        -0.010965689085423946,
        0.05573328211903572,
        0.004490668419748545,
        -0.015840450301766396,
        0.05701608583331108,
        0.05513417348265648,
        0.0550600029528141,
        0.00979987345635891,
        0.0009511281386949122,
        0.0420445017516613,
        -0.02880326472222805,
        -0.00021792834741063416,
        -0.04369284212589264,
        -0.05526791140437126,
        -0.04887201264500618,
        -0.013685640878975391,
        -0.054031647741794586,
        -0.002951364265754819,
        -0.05473189055919647,
        0.04283977299928665,
        0.056874170899391174,
        -0.05387740209698677,
        -0.038383711129426956,
        0.05660361051559448,
        0.0569213330745697,
        0.05578673258423805,
        0.05395450443029404,
        -0.030559660866856575,
        0.0250525064766407,
        -0.055305395275354385,
        0.053240079432725906,
        0.037900447845458984,
        0.015519593842327595,
        0.004501083865761757,
        -0.048490602523088455,
        -0.046850115060806274,
        -0.05669846013188362,
        0.03534836694598198,
        -0.05607021600008011,
        0.04823516681790352,
        0.005122148431837559,
        -0.053921088576316833,
        -0.0011702935444191098,
        -0.031627703458070755,
        -0.03211389109492302,
        -0.05682943016290665,
        -0.04969000443816185,
        0.048504725098609924,
        0.027158135548233986,
        -0.02976619265973568,
        -0.05620940774679184,
        -0.002148332307115197,
        -0.0564311183989048,
        -0.055281978100538254,
        0.023514926433563232,
        -0.04931095987558365,
        0.05552953481674194,
        -0.0012736249482259154,
        -0.04901006445288658,
        0.03160642087459564,
        -0.05547972396016121,
        0.0570765919983387,
        0.052107345312833786,
        -0.05572296306490898,
        0.05236617475748062,
        -0.033321790397167206,
        0.056242410093545914,
        -0.036293089389801025,
        0.010283601470291615,
        -0.03641064837574959,
        -0.0439063161611557,
        -0.05067607760429382,
        0.04677533358335495,
        0.05280256271362305,
        -0.05613906681537628,
        0.051141079515218735,
        -0.04446253553032875,
        -0.050625067204236984,
        0.04527134820818901,
        -0.05708882212638855,
        -0.053774528205394745,
        0.05534454807639122,
        0.04573101922869682,
        0.037568725645542145,
        -0.04958174377679825,
        -0.057095080614089966,
        0.047355517745018005,
        0.039013929665088654,
        -0.053984902799129486,
        0.05521974340081215,
        0.056594621390104294,
        0.05540229007601738,
        -0.0011097979731857777,
        0.04564010351896286,
        -0.018674464896321297,
        -0.04223404824733734,
        0.024953123182058334,
        0.05526368319988251,
        -0.0545109324157238,
        0.05341922119259834,
        0.017043786123394966,
        -0.05521462857723236,
        0.056867096573114395,
        -0.056027475744485855,
        0.047740690410137177,
        -0.018883513286709785,
        -0.02296821027994156,
        -0.019927168264985085,
        -0.05220189690589905,
        0.05712537467479706,
        0.04483539238572121,
        0.05594967305660248,
        0.036236315965652466,
        -0.03201171010732651,
        0.057031821459531784,
        -0.057029370218515396,
        0.04236322268843651,
        -0.0447111502289772,
        -0.05474737286567688,
        -0.05589766427874565,
        0.040336236357688904,
        -0.05657124146819115,
        -0.047188855707645416,
        -0.05345451086759567,
        -0.055113229900598526,
        0.054249901324510574,
        0.0526483990252018,
        -0.03926495835185051,
        -0.04887980595231056,
        0.02764579840004444,
        -0.05419767275452614,
        0.05709831416606903,
        0.0275692380964756,
        0.05586173012852669,
        -0.057060398161411285,
        -0.03195744752883911,
        -0.05522609129548073,
        0.04576171934604645,
        0.03606574982404709,
        0.05291679874062538,
        0.05686395987868309,
        -0.0032488126307725906,
        0.024335099384188652,
        -0.046220812946558,
        -0.05649596080183983,
        -0.024689452722668648,
        0.0323798768222332,
        -0.055930424481630325,
        -0.04069621488451958,
        0.056681882590055466,
        -0.05223969742655754,
        0.053375862538814545,
        -0.05089763551950455,
        -0.04906037449836731,
        -0.05632391944527626,
        -0.044150639325380325,
        0.04532782360911369,
        0.029709700495004654,
        -0.040303293615579605,
        -0.055346228182315826,
        0.05681682378053665,
        0.055108800530433655,
        -0.051536381244659424,
        0.0019400096498429775,
        -0.05689242109656334,
        -0.036112166941165924,
        0.025138605386018753,
        0.053065747022628784,
        0.054547812789678574,
        0.01016987580806017,
        0.0342213399708271,
        0.04449396952986717,
        -0.02842748910188675,
        0.03949897363781929,
        -0.05152379721403122,
        0.045499879866838455,
        0.034752778708934784,
        -0.02804296277463436,
        0.053360406309366226,
        0.05160175636410713,
        -0.049713365733623505,
        -0.009818303398787975,
        -0.022150782868266106,
        0.05509559437632561,
        0.049872636795043945,
        -0.0543002150952816,
        -0.05681965500116348,
        -0.05606606602668762,
        -0.054959844797849655,
        0.03162950649857521,
        0.019663570448756218,
        -0.05673747882246971,
        -0.025481563061475754,
        -0.05550098419189453,
        -0.05181527137756348,
        0.05580144375562668,
        -0.023695820942521095,
        0.05092490836977959,
        0.05483616888523102,
        0.029609203338623047,
        -0.040877535939216614,
        -0.03569817915558815,
        0.056501008570194244,
        0.030394261702895164,
        -0.05302489921450615,
        0.04539427161216736,
        -0.010760598815977573,
        0.05013853311538696,
        0.02975236251950264,
        -0.04399720951914787,
        0.023127593100070953,
        0.05630827322602272,
        -0.05232512950897217,
        0.05465644225478172,
        -0.049330245703458786,
        -0.030205560848116875,
        0.05395403131842613,
        0.053513165563344955,
        -0.05645190179347992,
        -0.0010028635151684284,
        -0.02426820434629917,
        0.03295811265707016,
        0.0030938538257032633,
        -0.05315611883997917,
        0.04120522364974022,
        0.0061558326706290245,
        -0.00867304764688015,
        -0.05654064193367958,
        -0.05380729213356972,
        0.0568121112883091,
        0.05676035210490227,
        -0.05683911591768265,
        0.0559794157743454,
        -0.05617717280983925,
        -0.0570664182305336,
        0.04850299283862114,
        0.016103798523545265,
        -0.0017235432751476765,
        0.0529378242790699,
        0.05603431537747383,
        -0.016190653666853905,
        -0.03166939318180084,
        -0.02420559525489807,
        -0.0417441762983799,
        -0.051755450665950775,
        0.0456928014755249,
        0.022634360939264297,
        0.05565863847732544,
        0.05247156694531441,
        -0.013342838734388351,
        0.01608203910291195,
        0.050491902977228165,
        -0.03700597584247589,
        -0.05470835044980049,
        -0.019423814490437508,
        -0.05627916008234024,
        -0.050439171493053436,
        0.05271759629249573,
        0.04218720644712448,
        0.05539434403181076,
        0.04851989820599556,
        -0.041168246418237686,
        -0.0374300517141819,
        0.05601577088236809,
        0.05556117370724678,
        -0.0555388405919075,
        -0.035643357783555984,
        -0.04721898213028908,
        -0.0481991246342659,
        0.052597057074308395,
        0.041108109056949615,
        0.0413876436650753,
        0.041454266756772995,
        -0.037122126668691635,
        -0.05327277630567551,
        0.054061707109212875,
        0.05623117461800575
    ],
    [
        -0.030467145144939423,
        -0.028302090242505074,
        0.01716352254152298,
        -0.03424692526459694,
        -0.0010113170137628913,
        -0.02198994904756546,
        0.05279234051704407,
        -0.052991729229688644,
        0.04903601482510567,
        0.028835376724600792,
        0.049472127109766006,
        0.018553905189037323,
        -0.052171554416418076,
        -0.04754050448536873,
        -0.051490411162376404,
        0.05272160843014717,
        -0.047336824238300323,
        0.0427803173661232,
        0.04311719536781311,
        0.05226041004061699,
        -0.013008303940296173,
        0.05305343121290207,
        0.05245019122958183,
        0.029514295980334282,
        -0.053090665489435196,
        0.05087190121412277,
        0.036625396460294724,
        0.049013204872608185,
        -0.05041958764195442,
        -0.04897807911038399,
        0.018312422558665276,
        0.051579926162958145,
        0.051701221615076065,
        -0.05309287831187248,
        0.051712315529584885,
        -0.04837735742330551,
        -0.053061943501234055,
        -0.0528976209461689,
        0.05282288417220116,
        -0.05104411393404007,
        0.049002211540937424,
        0.05161908641457558,
        0.04921245947480202,
        0.010416622273623943,
        -0.03134367987513542,
        -0.03580921143293381,
        -0.01894879899919033,
        0.05318387225270271,
        0.05303296446800232,
        -0.05306635797023773,
        0.05295679718255997,
        0.05173341557383537,
        -0.05187971889972687,
        -0.04541292041540146,
        0.051656290888786316,
        0.03526438772678375,
        -0.04912485182285309,
        0.03399505838751793,
        -0.05095162242650986,
        -0.05046607553958893,
        -0.05274622514843941,
        0.04542360082268715,
        -0.05180865153670311,
        -0.021858226507902145,
        0.05298715457320213,
        -0.039139654487371445,
        -0.05308237671852112,
        -0.014706275425851345,
        0.046930741518735886,
        -0.012445309199392796,
        0.014727065339684486,
        -0.052457600831985474,
        0.05317990481853485,
        0.02296449802815914,
        0.04109222814440727,
        -0.03893156722187996,
        0.0495167151093483,
        0.053186915814876556,
        0.04779206961393356,
        0.052125170826911926,
        -0.05195309966802597,
        -0.05036846175789833,
        -0.05001750960946083,
        -0.012934757396578789,
        -0.016727132722735405,
        0.03811746463179588,
        -0.05075502023100853,
        -0.053024109452962875,
        -0.028672510758042336,
        0.05165181681513786,
        -0.05286327749490738,
        -0.04439477249979973,
        -0.05167797580361366,
        -0.05262969061732292,
        -0.047716155648231506,
        0.04602726548910141,
        0.003047248348593712,
        -0.05030600354075432,
        0.027470078319311142,
        0.05208428204059601,
        -0.05315018072724342,
        0.009900755248963833,
        0.05237047001719475,
        0.05219213292002678,
        -0.04364851489663124,
        0.04776260256767273,
        -0.05095231160521507,
        0.03329075872898102,
        -0.05125603824853897,
        0.032884664833545685,
        -0.01592446118593216,
        -0.05273553356528282,
        -0.05240972340106964,
        0.01837419904768467,
        -0.0006277614738792181,
        -0.03744007274508476,
        0.05063489079475403,
        -0.04793248698115349,
        0.05291459709405899,
        0.004403372760862112,
        -0.008202964439988136,
        0.039284996688365936,
        0.0482911579310894,
        -0.05121033638715744,
        -0.050395071506500244,
        0.04849693924188614,
        -0.03428207337856293,
        0.05216817557811737,
        0.05293317139148712,
        -0.04271545633673668,
        0.029061006382107735,
        -0.05050942674279213,
        0.01248322892934084,
        0.05290323495864868,
        -0.050871267914772034,
        -0.028430089354515076,
        0.047514453530311584,
        0.046143535524606705,
        -0.05290409177541733,
        0.05287586897611618,
        -0.04773837700486183,
        -0.05283786356449127,
        0.05165774002671242,
        0.04761025682091713,
        -0.012104579247534275,
        0.028335796669125557,
        -0.03016207553446293,
        -0.05316713824868202,
        -0.04330055043101311,
        0.037362825125455856,
        0.04974940046668053,
        -0.053168315440416336,
        -0.05292278900742531,
        0.040983203798532486,
        0.04925631359219551,
        -0.05317683145403862,
        -0.037063438445329666,
        0.05142122879624367,
        0.05310441181063652,
        -0.05152824893593788,
        -0.025496771559119225,
        0.04905484989285469,
        0.05297037214040756,
        0.04437420144677162,
        -0.0000638791752862744,
        0.04504724219441414,
        -0.028508270159363747,
        0.023862143978476524,
        -0.045772675424814224,
        0.027394328266382217,
        -0.052960287779569626,
        0.04723470285534859,
        -0.052208635956048965,
        0.04453129321336746,
        -0.05257352441549301,
        -0.03616400808095932,
        0.028857305645942688,
        0.04844065010547638,
        0.04446062445640564,
        -0.05204087123274803,
        -0.0465715192258358,
        0.053150203078985214,
        0.026868950575590134,
        -0.0122602554038167,
        0.05306113511323929,
        -0.03343091905117035,
        0.05212564393877983,
        0.0528513565659523,
        -0.020445536822080612,
        -0.04948778450489044,
        -0.05301821231842041,
        -0.03812329098582268,
        0.05298207327723503,
        0.0378626249730587,
        -0.04578710347414017,
        0.04741711914539337,
        0.016238905489444733,
        -0.014018122106790543,
        -0.050617460161447525,
        0.037184830754995346,
        0.0021175022702664137,
        0.05195073410868645,
        -0.001956591382622719,
        -0.04647069051861763,
        0.046094320714473724,
        -0.03808954730629921,
        0.05221637710928917,
        0.04966537654399872,
        -0.04643940180540085,
        0.05309982970356941,
        -0.044273849576711655,
        0.052715275436639786,
        0.05307962745428085,
        0.04475274309515953,
        -0.047072634100914,
        -0.04792448505759239,
        0.05295102298259735,
        -0.02975386008620262,
        0.053175732493400574,
        0.03461943194270134,
        -0.04241565614938736,
        0.052965905517339706,
        -0.02729634940624237,
        -0.04888145998120308,
        0.05318688973784447,
        -0.051449697464704514,
        0.048966068774461746,
        -0.05318151414394379,
        0.04788884148001671,
        -0.0531633235514164,
        0.023239586502313614,
        -0.05311531946063042,
        -0.0071675824001431465,
        0.051811881363391876,
        -0.034544412046670914,
        0.05318340286612511,
        0.05143405497074127,
        -0.04937516525387764,
        0.032342441380023956,
        0.05318571254611015,
        0.037549711763858795,
        0.03458290919661522,
        -0.05318291112780571,
        0.006125557702034712,
        -0.03938383609056473,
        -0.050315260887145996,
        -0.05027433857321739,
        0.005023193545639515,
        0.03484778851270676,
        -0.051232486963272095,
        0.04088008776307106,
        -0.05302289500832558,
        -0.04051830992102623,
        -0.05176869034767151,
        0.011819890700280666,
        0.0531061626970768,
        -0.0014188219793140888,
        -0.03740241378545761,
        0.050949689000844955,
        0.014844588004052639,
        -0.02694833092391491,
        -0.03465583175420761,
        -0.05230764299631119,
        0.0531475804746151,
        -0.04981083795428276,
        0.05295313149690628,
        0.053178925067186356,
        0.052997153252363205,
        -0.051560163497924805,
        0.04677838087081909,
        0.052740294486284256,
        -0.02232375554740429,
        0.04414213448762894,
        -0.05055266618728638,
        0.04988318681716919,
        -0.022062061354517937,
        -0.05060984566807747,
        0.031100966036319733,
        0.019821738824248314,
        -0.05194387212395668,
        -0.04981075972318649,
        0.0530196875333786,
        -0.03338536247611046,
        -0.026843905448913574,
        -0.0059200553223490715,
        0.04549791291356087,
        -0.008912771008908749,
        0.04452935978770256,
        0.05230524390935898,
        0.04699157923460007,
        -0.040643155574798584,
        -0.007312084082514048,
        0.049165915697813034,
        0.04539157450199127,
        0.053184688091278076,
        -0.052805181592702866,
        0.052030298858881,
        0.047992464154958725,
        -0.036637045443058014,
        0.05277959629893303,
        -0.0329512320458889,
        -0.025286559015512466,
        0.04327773302793503,
        0.05230119824409485,
        -0.05071339011192322,
        0.034030407667160034,
        -0.049227386713027954,
        0.0528300516307354,
        0.05093365162611008,
        -0.04349090903997421,
        0.04249555617570877,
        0.050971146672964096,
        0.0038767107762396336,
        0.038847845047712326,
        -0.0031343186274170876,
        -0.05153166502714157,
        0.03489422798156738,
        0.04296579957008362,
        0.05031565576791763,
        -0.04775835946202278,
        0.04816523566842079,
        -0.0018850037595257163,
        0.051994986832141876,
        0.036219146102666855,
        0.05115373805165291,
        -0.051212336868047714,
        -0.03030148148536682,
        -0.051395077258348465,
        -0.052204716950654984,
        -0.05316798761487007,
        0.04429459944367409,
        -0.05288102477788925,
        -0.04393681138753891,
        0.053123876452445984,
        0.05024760589003563,
        0.024388635531067848,
        -0.02981000952422619,
        0.05110382288694382,
        -0.01309992279857397,
        -0.017465438693761826,
        -0.0520855113863945,
        -0.004283975809812546,
        0.05270243063569069,
        0.04122846946120262,
        0.05114084482192993,
        -0.03986218571662903,
        0.04974592104554176,
        0.0531158521771431,
        -0.0517318993806839,
        0.0398259274661541,
        0.05317281559109688,
        0.047516752034425735,
        -0.010593627579510212,
        -0.053080733865499496,
        0.05144668370485306,
        -0.04375399649143219,
        -0.05148041993379593,
        -0.04248529672622681,
        -0.04005065932869911,
        -0.037715740501880646,
        0.05137331783771515,
        0.052641209214925766,
        -0.011666325852274895,
        -0.051735877990722656,
        -0.04338819533586502,
        -0.03659437596797943,
        0.053141411393880844,
        -0.048089105635881424,
        -0.051432713866233826,
        -0.05287489667534828,
        -0.048086490482091904,
        -0.04575509950518608,
        -0.0434577502310276,
        0.05135057494044304,
        0.05038325488567352,
        0.05262770503759384,
        0.05023959279060364,
        0.050049688667058945,
        0.051660798490047455,
        0.04755859449505806,
        -0.0529104582965374,
        0.04707498848438263,
        -0.04624856635928154,
        0.0512734018266201,
        0.048416558653116226,
        -0.04262743145227432,
        -0.04122237488627434,
        -0.05108412727713585,
        -0.05110142007470131,
        -0.05255104601383209,
        -0.05318397283554077,
        -0.043778277933597565,
        -0.04939628392457962,
        -0.052728552371263504,
        0.0464012436568737,
        0.052412863820791245,
        -0.036680709570646286,
        -0.012502223253250122,
        -0.05276976525783539,
        -0.05313185974955559,
        0.03657631203532219,
        0.053089577704668045,
        -0.04939538612961769,
        -0.010850979946553707,
        -0.05242573469877243,
        0.052669718861579895,
        0.022976424545049667,
        -0.05286039412021637,
        0.051407892256975174,
        -0.04386703297495842,
        -0.05208184942603111,
        -0.05298153683543205,
        -0.04539817199110985,
        -0.017674751579761505,
        0.05312822014093399,
        -0.05307720974087715,
        -0.012162287719547749,
        -0.05293305963277817,
        0.05230683460831642,
        0.049438729882240295,
        0.05311397463083267,
        -0.003912702668458223,
        -0.04396969825029373,
        -0.05300774797797203,
        0.03039407730102539,
        -0.04940841719508171,
        0.029931824654340744,
        -0.024770746007561684,
        -0.05286099761724472,
        -0.053169168531894684,
        -0.03351595252752304,
        0.040122177451848984,
        -0.004761648364365101,
        -0.052210837602615356,
        0.007669083774089813,
        -0.05173530429601669,
        -0.05301803722977638,
        -0.05274714529514313,
        -0.025360161438584328,
        -0.04248008504509926,
        0.04194679856300354,
        0.02673114649951458,
        0.05283155292272568,
        0.05313730612397194,
        -0.030630523338913918,
        0.05173741653561592,
        0.05294220894575119,
        -0.033096034079790115,
        0.03368191793560982,
        0.05028874799609184,
        0.0038248663768172264,
        0.052870310842990875,
        0.05310342088341713,
        0.05302981659770012,
        -0.02292906865477562,
        -0.052418988198041916,
        0.024138592183589935,
        -0.040376096963882446,
        0.05309039726853371,
        0.04864594340324402,
        0.04444626346230507,
        -0.05264076218008995,
        -0.012966679409146309,
        -0.03674861043691635,
        0.053175073117017746,
        0.05318361893296242,
        -0.050701554864645004,
        0.05248348042368889,
        0.05022552236914635,
        -0.050324372947216034,
        0.04541183263063431,
        0.053091563284397125,
        0.053138911724090576,
        0.045315638184547424,
        -0.052902109920978546,
        0.04896131530404091,
        0.05313946679234505,
        0.04575418680906296,
        -0.05244328826665878,
        0.05172550305724144,
        0.011548439972102642,
        -0.017205126583576202,
        0.015504763461649418,
        0.019125571474432945,
        -0.025836657732725143,
        0.0004062122025061399,
        0.05270812287926674,
        0.04475538805127144,
        -0.05229904130101204,
        -0.029489707201719284,
        0.04270746558904648,
        -0.05312362313270569,
        0.053147561848163605,
        -0.04144415259361267,
        -0.042932167649269104,
        0.004658727440983057,
        -0.03772549703717232,
        -0.050436731427907944,
        0.05014554038643837,
        0.04898999258875847,
        0.03508521243929863,
        0.023959776386618614,
        -0.0420452356338501,
        0.03576104715466499,
        -0.03308001533150673,
        0.0529790036380291,
        0.053175415843725204,
        -0.04886733368039131,
        0.05261895805597305,
        -0.05316494405269623,
        0.0524241179227829,
        -0.004190452862530947
    ],
    [
        0.06022047996520996,
        -0.002169491723179817,
        0.04523854702711105,
        0.028527121990919113,
        -0.009532343596220016,
        -0.04020439833402634,
        -0.026856929063796997,
        -0.05377288907766342,
        0.05973226577043533,
        0.009777076542377472,
        -0.0359707809984684,
        -0.042473163455724716,
        -0.04470627009868622,
        -0.048782650381326675,
        -0.061211615800857544,
        0.03405779227614403,
        0.008844517171382904,
        0.05842653661966324,
        -0.014373148791491985,
        -0.0610625222325325,
        0.062379926443099976,
        0.06270995736122131,
        0.029594015330076218,
        -0.052900075912475586,
        0.046548210084438324,
        0.051736775785684586,
        -0.030157001689076424,
        -0.06262870132923126,
        0.05947020649909973,
        -0.002909439615905285,
        0.02332930453121662,
        -0.02630128711462021,
        -0.004491649102419615,
        -0.06243562698364258,
        0.05383302643895149,
        -0.020963944494724274,
        -0.06038282811641693,
        -0.04315922036767006,
        0.05945690721273422,
        0.00695626949891448,
        0.039754509925842285,
        -0.01227461826056242,
        0.024236777797341347,
        -0.06112029775977135,
        0.003180874977260828,
        0.0593075230717659,
        0.0057382709346711636,
        0.03774132579565048,
        0.04654763638973236,
        0.05922502279281616,
        0.05801256000995636,
        0.06162600219249725,
        -0.055133551359176636,
        -0.041098497807979584,
        -0.00878484733402729,
        -0.05325508862733841,
        0.05501565709710121,
        0.037924185395240784,
        0.023100396618247032,
        -0.0627341940999031,
        -0.06128458306193352,
        0.04353104531764984,
        -0.05431155115365982,
        0.061203982681035995,
        -0.037690170109272,
        -0.06005571037530899,
        -0.05220082029700279,
        -0.018689513206481934,
        -0.06093660369515419,
        -0.0199943445622921,
        -0.02658524364233017,
        -0.06306162476539612,
        0.061906952410936356,
        0.024417953565716743,
        0.0021599798928946257,
        -0.050564464181661606,
        -0.01759386993944645,
        0.06305328756570816,
        -0.0490989163517952,
        0.01797383278608322,
        -0.009265289641916752,
        0.041814595460891724,
        -0.03630521893501282,
        0.055703021585941315,
        0.05336926877498627,
        0.058010414242744446,
        -0.015815816819667816,
        -0.05983835458755493,
        0.04813707619905472,
        0.05472668260335922,
        0.052005186676979065,
        0.02513660117983818,
        -0.06309544295072556,
        -0.03536953032016754,
        -0.028692806139588356,
        0.06039293855428696,
        -0.023762375116348267,
        -0.060321394354104996,
        0.0019350536167621613,
        0.025986524298787117,
        -0.06209488585591316,
        0.045390717685222626,
        0.0013347782660275698,
        -0.004134179558604956,
        -0.034268155694007874,
        -0.021946407854557037,
        0.026437636464834213,
        0.04668018966913223,
        -0.02904387190937996,
        0.05815396085381508,
        0.023049985989928246,
        0.033751994371414185,
        -0.05839179828763008,
        0.009167810901999474,
        -0.015792254358530045,
        0.027364280074834824,
        0.06073576956987381,
        -0.005791321862488985,
        0.0631401389837265,
        0.028258245438337326,
        -0.015929844230413437,
        0.06192122399806976,
        -0.019971109926700592,
        -0.03415479511022568,
        -0.04303435608744621,
        -0.062085870653390884,
        -0.04883520305156708,
        -0.055656202137470245,
        0.06237708777189255,
        0.029443887993693352,
        0.035623155534267426,
        -0.056359440088272095,
        -0.011709494516253471,
        0.017095347866415977,
        -0.06018373370170593,
        -0.058976344764232635,
        0.03062315098941326,
        0.056838154792785645,
        -0.04907763749361038,
        0.056407857686281204,
        0.06301432847976685,
        -0.059505581855773926,
        0.05567941442131996,
        -0.05205284431576729,
        0.06268735229969025,
        0.03543215990066528,
        0.04955711588263512,
        0.05863133817911148,
        0.050477560609579086,
        0.027878329157829285,
        0.06306050717830658,
        -0.062110479921102524,
        -0.04859514907002449,
        -0.05300507694482803,
        0.058812711387872696,
        -0.04947960749268532,
        -0.023395078256726265,
        0.05810548737645149,
        0.05993238463997841,
        0.025968370959162712,
        -0.006086943205446005,
        0.0232697743922472,
        0.057464443147182465,
        0.0408005490899086,
        0.06272733956575394,
        0.0501360148191452,
        -0.04478861019015312,
        0.0059218923561275005,
        -0.014896209351718426,
        0.013762460090219975,
        -0.04475772753357887,
        0.05855588987469673,
        0.013468972407281399,
        -0.06299249827861786,
        -0.008656217716634274,
        0.052298929542303085,
        -0.05586353689432144,
        0.002826505806297064,
        0.019839901477098465,
        -0.0390385240316391,
        0.001264696940779686,
        0.0631391704082489,
        -0.017655985429883003,
        0.056240249425172806,
        0.05465535447001457,
        0.006814314052462578,
        -0.016850242391228676,
        0.05822629854083061,
        -0.011434422805905342,
        -0.040513429790735245,
        -0.03345499560236931,
        0.014976875856518745,
        -0.009961770847439766,
        0.03349475935101509,
        -0.03929955139756203,
        0.00702973548322916,
        -0.05699269846081734,
        -0.021218132227659225,
        -0.06225040555000305,
        0.010489503853023052,
        0.047457046806812286,
        0.025908883661031723,
        -0.010599171742796898,
        -0.0491948202252388,
        -0.05626056343317032,
        -0.010782827623188496,
        0.05475155636668205,
        0.05841312184929848,
        -0.0009483247995376587,
        0.05991111695766449,
        -0.0553390197455883,
        0.05739179998636246,
        0.02503674291074276,
        0.0342586487531662,
        0.020968379452824593,
        -0.017285184934735298,
        0.003667292185127735,
        0.01773488149046898,
        0.06183924898505211,
        0.04255551099777222,
        -0.0015146132791414857,
        0.056692346930503845,
        -0.038988836109638214,
        -0.019783975556492805,
        0.06295904517173767,
        -0.0318528413772583,
        -0.005687104072421789,
        -0.05601384490728378,
        0.04669469594955444,
        -0.05660303682088852,
        0.04144035279750824,
        -0.059600308537483215,
        0.0320565290749073,
        0.046283088624477386,
        0.02655480057001114,
        0.06271569430828094,
        0.059902604669332504,
        0.027868712320923805,
        -0.027176426723599434,
        0.06293956190347672,
        -0.04082588106393814,
        0.0591864213347435,
        -0.06314951181411743,
        0.006075268145650625,
        0.02590501867234707,
        -0.0011997786350548267,
        -0.06020451337099075,
        -0.05028556287288666,
        -0.050912585109472275,
        -0.015767768025398254,
        -0.05342959240078926,
        -0.06030763313174248,
        0.037496503442525864,
        -0.05921979993581772,
        -0.06111081317067146,
        0.041564177721738815,
        0.046969298273324966,
        -0.06100562587380409,
        0.012160778976976871,
        0.0589771531522274,
        0.05662215128540993,
        -0.05446087568998337,
        -0.030809782445430756,
        0.02241821214556694,
        -0.017040561884641647,
        0.052187807857990265,
        -0.05187385901808739,
        0.05531095340847969,
        -0.062050726264715195,
        0.03869340568780899,
        0.05996330827474594,
        -0.01223849318921566,
        0.06290833652019501,
        -0.012161766178905964,
        -0.02709576115012169,
        0.04295434057712555,
        -0.037419021129608154,
        -0.0042097619734704494,
        -0.017392918467521667,
        -0.011306063272058964,
        -0.0566219761967659,
        -0.02081950381398201,
        -0.0625811219215393,
        0.05043406784534454,
        -0.04928715154528618,
        -0.021285589784383774,
        0.005196413956582546,
        0.06261672079563141,
        0.05920173227787018,
        0.03329591825604439,
        -0.022159630432724953,
        -0.008209978230297565,
        -0.015199890360236168,
        0.05412370711565018,
        0.06208096817135811,
        -0.06062939018011093,
        0.05709822103381157,
        0.048584431409835815,
        -0.05901617929339409,
        0.058556605130434036,
        0.00361751695163548,
        0.047195371240377426,
        -0.02977205067873001,
        0.0541759617626667,
        -0.03663188964128494,
        -0.03408723324537277,
        -0.05540177971124649,
        0.055111613124608994,
        0.04442504048347473,
        -0.02178887650370598,
        0.018802130594849586,
        -0.026723157614469528,
        -0.02912158891558647,
        0.01737949065864086,
        0.05817881599068642,
        -0.05138075351715088,
        0.011192369274795055,
        -0.02759750746190548,
        0.0340442880988121,
        -0.05024416372179985,
        0.05100055783987045,
        -0.017799362540245056,
        0.048746392130851746,
        0.062497757375240326,
        0.0567166842520237,
        -0.057287972420454025,
        -0.026187021285295486,
        -0.058345191180706024,
        -0.04545392841100693,
        -0.000568824412766844,
        0.0015874095261096954,
        -0.03823262080550194,
        0.01841450110077858,
        0.048540789633989334,
        0.06129729002714157,
        0.043159693479537964,
        -0.022779902443289757,
        0.014520389959216118,
        0.027756085619330406,
        0.061118412762880325,
        -0.052569739520549774,
        0.04551500454545021,
        0.002075821626931429,
        -0.0017698596930131316,
        -0.05542103201150894,
        0.04022008180618286,
        0.04785354435443878,
        0.06156296655535698,
        -0.05187254026532173,
        0.05753067880868912,
        0.05469023808836937,
        0.02968212589621544,
        -0.038102056831121445,
        -0.018369117751717567,
        -0.060531001538038254,
        0.004338535014539957,
        -0.03890933096408844,
        -0.04534107446670532,
        0.05648991838097572,
        -0.06304716318845749,
        0.05119267851114273,
        -0.016891783103346825,
        0.026314811781048775,
        -0.018415896221995354,
        -0.023297274485230446,
        -0.05118752270936966,
        0.05499305948615074,
        -0.03659662976861,
        0.0010153133189305663,
        -0.05890188738703728,
        -0.02693396992981434,
        0.04559553042054176,
        0.036720700562000275,
        -0.02614699676632881,
        0.0359841026365757,
        0.015501841902732849,
        0.0159110426902771,
        -0.010245362296700478,
        0.05180966109037399,
        0.06201896443963051,
        -0.03750788792967796,
        -0.061925482004880905,
        -0.0623178668320179,
        0.02612040750682354,
        0.0626143142580986,
        -0.02695947140455246,
        -0.03557794168591499,
        -0.05944797396659851,
        -0.04704273119568825,
        -0.005708588287234306,
        -0.06312443315982819,
        0.04482736438512802,
        0.03417783975601196,
        -0.058448392897844315,
        -0.022591935470700264,
        0.05854305624961853,
        0.05498344451189041,
        0.04641581326723099,
        0.023977406322956085,
        -0.058124154806137085,
        -0.022393343970179558,
        0.0616302527487278,
        -0.04710656777024269,
        -0.061848234385252,
        -0.059595413506031036,
        0.052406102418899536,
        0.03318968415260315,
        -0.05006640404462814,
        0.0578603595495224,
        0.03205520659685135,
        -0.05566548556089401,
        -0.05984964966773987,
        0.06222507730126381,
        0.036413971334695816,
        0.04471234604716301,
        -0.056577932089567184,
        0.043607037514448166,
        0.0584600605070591,
        0.06245621666312218,
        0.06121860072016716,
        0.062136851251125336,
        -0.05486949160695076,
        0.02572057582437992,
        -0.04852398857474327,
        0.04346718266606331,
        -0.03763110935688019,
        -0.007467413786798716,
        0.01706762984395027,
        0.010807034559547901,
        -0.05291607230901718,
        0.05753962695598602,
        0.02694098651409149,
        -0.06275025010108948,
        -0.04860256239771843,
        -0.03284760192036629,
        -0.04859871417284012,
        -0.0619865320622921,
        -0.03018416091799736,
        -0.03479998931288719,
        0.04046347737312317,
        0.04279236122965813,
        0.05669223144650459,
        0.06254055351018906,
        0.061379026621580124,
        0.02696824260056019,
        -0.003946356941014528,
        0.058715879917144775,
        -0.01368558220565319,
        0.059875521808862686,
        0.056908827275037766,
        -0.05199505016207695,
        0.042444873601198196,
        0.0575166754424572,
        0.060294151306152344,
        -0.05285731703042984,
        -0.05806034058332443,
        0.0263509564101696,
        -0.02210031822323799,
        -0.029493415728211403,
        0.03016895242035389,
        0.0016495113959535956,
        -0.04772400110960007,
        -0.042918648570775986,
        0.0031628403812646866,
        0.043639592826366425,
        0.06251130998134613,
        -0.04864412918686867,
        0.06067991256713867,
        -0.05200618878006935,
        -0.05215943604707718,
        0.05590503290295601,
        0.06290189921855927,
        0.03233315795660019,
        0.030262984335422516,
        -0.063067227602005,
        0.007388785947114229,
        0.05357019975781441,
        0.024699291214346886,
        -0.05074653401970863,
        -0.06176033616065979,
        -0.0556722991168499,
        -0.023920128121972084,
        -0.03796503692865372,
        0.013403113931417465,
        -0.05156303569674492,
        0.051434148102998734,
        0.023623783141374588,
        0.047425057739019394,
        -0.05666288733482361,
        -0.031301580369472504,
        -0.003513433737680316,
        -0.024968422949314117,
        0.03583819791674614,
        0.018422070890665054,
        0.027422523126006126,
        -0.00893960427492857,
        0.041504867374897,
        0.06151324510574341,
        0.057845789939165115,
        0.0020593476947396994,
        -0.042077381163835526,
        -0.03398467227816582,
        -0.047400739043951035,
        -0.005775308236479759,
        -0.06010391190648079,
        -0.04375367984175682,
        0.0630837231874466,
        -0.03326600044965744,
        -0.011764327064156532,
        0.005298719275742769,
        0.032633405178785324,
        0.056169331073760986
    ],
    [
        0.057204268872737885,
        0.04161655530333519,
        0.014611241407692432,
        0.04583650827407837,
        -0.024363378062844276,
        -0.00816330872476101,
        -0.030126024037599564,
        -0.05577004328370094,
        0.060805026441812515,
        0.053240299224853516,
        -0.009397706016898155,
        0.024070944637060165,
        -0.051067858934402466,
        -0.0280421432107687,
        -0.058906275779008865,
        0.0593268983066082,
        0.002786615863442421,
        0.0483907088637352,
        0.027135640382766724,
        -0.06184971332550049,
        0.061868250370025635,
        0.06244099512696266,
        -0.018071848899126053,
        -0.036552950739860535,
        0.013235386461019516,
        0.03573036938905716,
        0.017817223444581032,
        -0.060401685535907745,
        0.027187904343008995,
        0.0590837262570858,
        0.01725081540644169,
        -0.04231147840619087,
        -0.02646689862012863,
        -0.06160080060362816,
        0.05345156788825989,
        0.04967769235372543,
        -0.0594882033765316,
        -0.05750586465001106,
        0.06069605052471161,
        0.020269187167286873,
        0.05047227442264557,
        0.0073640188202261925,
        0.026957042515277863,
        -0.061189498752355576,
        -0.002044352935627103,
        0.05987343192100525,
        -0.03925234451889992,
        0.05501686781644821,
        0.056549977511167526,
        0.00028740125708281994,
        0.04754151776432991,
        0.061393991112709045,
        -0.04878876358270645,
        -0.03166892006993294,
        -0.05203225463628769,
        0.007550966460257769,
        0.04290113225579262,
        0.009817206300795078,
        0.02453918568789959,
        -0.05494273453950882,
        -0.056017059832811356,
        0.04619380086660385,
        -0.053380630910396576,
        0.05813314765691757,
        -0.0552838109433651,
        -0.054335661232471466,
        -0.055621396750211716,
        -0.02289041504263878,
        -0.061791785061359406,
        -0.009111118502914906,
        -0.02795087918639183,
        -0.062262214720249176,
        0.06021178513765335,
        0.004330369643867016,
        -0.004400836769491434,
        -0.04530821368098259,
        -0.019554469734430313,
        0.06257234513759613,
        -0.03634491562843323,
        0.030958574265241623,
        0.014248112216591835,
        0.06014234200119972,
        -0.0326852947473526,
        0.05661584436893463,
        0.05815935879945755,
        0.056403085589408875,
        0.03792910650372505,
        -0.05421338230371475,
        0.05133300647139549,
        0.05632450804114342,
        0.03275339677929878,
        0.014795602299273014,
        -0.062373027205467224,
        -0.012512491084635258,
        -0.04698311910033226,
        0.05738329887390137,
        -0.03533634543418884,
        -0.05777432397007942,
        0.020810332149267197,
        -0.006577018182724714,
        -0.06112470477819443,
        0.05472662299871445,
        0.036772776395082474,
        0.015607834793627262,
        0.005772836972028017,
        -0.025129780173301697,
        -0.03243095427751541,
        0.046889904886484146,
        -0.02410629391670227,
        0.05747370049357414,
        -0.052268512547016144,
        0.0015750781167298555,
        -0.057129740715026855,
        -0.008583476766943932,
        -0.0185087863355875,
        -0.02231278456747532,
        0.06167531758546829,
        -0.035481229424476624,
        0.06262670457363129,
        0.04769337922334671,
        -0.05691136047244072,
        0.06176035478711128,
        0.0076300534419715405,
        -0.002916074125096202,
        -0.04418160393834114,
        -0.05347451940178871,
        -0.05725114420056343,
        -0.05942746624350548,
        0.05887420475482941,
        0.03824227675795555,
        0.048794329166412354,
        -0.04664618521928787,
        -0.04694698005914688,
        0.009437212720513344,
        -0.05961884185671806,
        -0.059591636061668396,
        0.04801288619637489,
        0.03819594904780388,
        -0.05937929451465607,
        0.042987920343875885,
        0.054674744606018066,
        -0.05928506329655647,
        0.05144527181982994,
        0.031872015446424484,
        0.06220061331987381,
        0.021857550367712975,
        0.060369834303855896,
        -0.05123505741357803,
        0.057642266154289246,
        0.05851990357041359,
        0.06216425821185112,
        -0.06152321398258209,
        -0.013893915340304375,
        -0.05936552211642265,
        0.053383566439151764,
        -0.059273283928632736,
        0.02090347185730934,
        0.050488412380218506,
        -0.040456485003232956,
        0.03258655220270157,
        0.026081960648298264,
        0.05852720886468887,
        0.05169464647769928,
        0.05503210052847862,
        0.03854422643780708,
        0.037208441644907,
        -0.040880803018808365,
        0.04706175625324249,
        -0.024399735033512115,
        0.008375084958970547,
        0.004218298010528088,
        0.038675617426633835,
        0.0038025008980184793,
        -0.052775703370571136,
        0.023923296481370926,
        0.056541088968515396,
        -0.059341415762901306,
        -0.015340745449066162,
        0.02178388088941574,
        -0.05454455688595772,
        -0.039582498371601105,
        0.06161591410636902,
        -0.040795840322971344,
        0.010434599593281746,
        0.053000450134277344,
        0.030848298221826553,
        -0.045599564909935,
        0.05125664174556732,
        -0.05861344188451767,
        -0.050487272441387177,
        -0.035227250307798386,
        -0.003740653395652771,
        0.016237100586295128,
        -0.014607337303459644,
        -0.03698626160621643,
        0.016909554600715637,
        -0.05403082072734833,
        0.016828464344143867,
        -0.05937550961971283,
        -0.01577264629304409,
        0.05905900150537491,
        -0.010426504537463188,
        -0.0005106297903694212,
        -0.03896303474903107,
        -0.0590096116065979,
        0.03755948320031166,
        0.04764653742313385,
        0.05337299779057503,
        -0.03504251688718796,
        0.061042994260787964,
        -0.055562254041433334,
        0.050986651331186295,
        -0.02688831090927124,
        0.04157558083534241,
        -0.041492365300655365,
        0.005304641555994749,
        -0.0018110460368916392,
        -0.02301931008696556,
        0.06015404313802719,
        0.046653568744659424,
        0.03516189381480217,
        0.059586066752672195,
        -0.05745024234056473,
        -0.03401000052690506,
        0.062216248363256454,
        -0.023304881528019905,
        0.01185656152665615,
        -0.011595102958381176,
        0.04057012125849724,
        -0.05319281294941902,
        0.05431096628308296,
        -0.06214337795972824,
        0.0040991283021867275,
        0.04377983883023262,
        -0.03159784525632858,
        0.062289606779813766,
        0.05925562232732773,
        -0.01111187320202589,
        -0.016201229766011238,
        0.0625377744436264,
        -0.011571456678211689,
        0.061158306896686554,
        -0.0626126378774643,
        0.040268830955028534,
        0.03884594142436981,
        -0.0077071236446499825,
        -0.05679183453321457,
        -0.000044919794163433835,
        -0.05998207628726959,
        -0.002932134782895446,
        -0.05737512931227684,
        -0.058966733515262604,
        0.0521731823682785,
        -0.06056715175509453,
        -0.062414489686489105,
        0.05996308848261833,
        0.037281591445207596,
        -0.06159912422299385,
        0.000653602706734091,
        0.0011405148543417454,
        0.05609404668211937,
        -0.044701531529426575,
        -0.04304925724864006,
        -0.028495976701378822,
        0.02303878217935562,
        0.04602935165166855,
        0.028570741415023804,
        0.04430561885237694,
        -0.06012207642197609,
        0.022635480388998985,
        0.0546310730278492,
        -0.02581857331097126,
        0.061877209693193436,
        -0.040750544518232346,
        -0.04970668628811836,
        0.03522760421037674,
        -0.040157172828912735,
        -0.009931190870702267,
        -0.05924477055668831,
        -0.056316643953323364,
        -0.05646383389830589,
        0.0016498874174430966,
        -0.06217397376894951,
        0.05885913595557213,
        -0.013010147027671337,
        -0.008443154394626617,
        -0.021389802917838097,
        0.05751730501651764,
        0.05734170973300934,
        0.034360308200120926,
        -0.041700273752212524,
        0.0462786965072155,
        -0.014913872815668583,
        0.055619195103645325,
        0.06166405975818634,
        -0.061597082763910294,
        0.0553566999733448,
        0.06148543581366539,
        -0.06171644479036331,
        0.05948407202959061,
        -0.012539863586425781,
        0.051859501749277115,
        -0.012562909163534641,
        0.03539999946951866,
        -0.05165219306945801,
        -0.005090623628348112,
        -0.0587327778339386,
        0.05468403175473213,
        0.041704993695020676,
        -0.03584888577461243,
        0.050845108926296234,
        -0.0074821580201387405,
        -0.04039183631539345,
        0.041038405150175095,
        0.053554896265268326,
        -0.05707239732146263,
        -0.010884419083595276,
        -0.012182052247226238,
        0.03000715747475624,
        -0.03787210211157799,
        0.023537348955869675,
        -0.05271347612142563,
        0.049986451864242554,
        0.06185629218816757,
        0.051511816680431366,
        -0.060595519840717316,
        -0.020297173410654068,
        -0.019008819013834,
        -0.05432663857936859,
        -0.006787678226828575,
        0.03185102343559265,
        -0.053626298904418945,
        -0.02212718315422535,
        -0.031990256160497665,
        0.057985663414001465,
        0.02758975699543953,
        -0.01634966768324375,
        0.03766210377216339,
        0.03130931779742241,
        0.06104688718914986,
        -0.0355570912361145,
        0.0346766822040081,
        -0.02732118032872677,
        -0.041006408631801605,
        -0.007814192213118076,
        0.05020320788025856,
        0.04307493939995766,
        0.05953967943787575,
        -0.05349583551287651,
        0.056661207228899,
        0.06125153601169586,
        0.05664677917957306,
        -0.03406131640076637,
        -0.021334333345294,
        -0.03684288263320923,
        0.007920904085040092,
        -0.03666107356548309,
        -0.03505381941795349,
        0.059194475412368774,
        -0.062252551317214966,
        0.04655088856816292,
        -0.03693785145878792,
        -0.03276004269719124,
        0.0017000297084450722,
        0.009186666458845139,
        -0.04952593147754669,
        0.05154791474342346,
        -0.051540616899728775,
        0.045830138027668,
        -0.058047544211149216,
        -0.04164385050535202,
        0.017083894461393356,
        0.05986109375953674,
        0.01865839958190918,
        0.04436938837170601,
        0.019483568146824837,
        -0.05224427208304405,
        0.019075745716691017,
        0.057606060057878494,
        0.051525264978408813,
        -0.014689110219478607,
        -0.06091833859682083,
        -0.06206167861819267,
        0.044887516647577286,
        0.062308937311172485,
        -0.02129332721233368,
        -0.015750769525766373,
        -0.06185232847929001,
        -0.049703776836395264,
        -0.05352243408560753,
        -0.06256548315286636,
        -0.03499681502580643,
        0.02659948728978634,
        -0.04556981101632118,
        -0.018298503011465073,
        0.05753638967871666,
        0.028360260650515556,
        0.030680963769555092,
        0.023428378626704216,
        -0.060756463557481766,
        -0.01002517156302929,
        0.06131916865706444,
        0.0007764964830130339,
        -0.060542091727256775,
        -0.05029073730111122,
        0.050889406353235245,
        -0.006258644163608551,
        -0.05299852043390274,
        0.0557430274784565,
        0.011651956476271152,
        -0.045335229486227036,
        -0.040190935134887695,
        0.06087711453437805,
        0.016584210097789764,
        0.030903154984116554,
        -0.048400864005088806,
        -0.022656669840216637,
        0.060769934207201004,
        0.05455106124281883,
        0.06085466966032982,
        0.05308559536933899,
        -0.04641081765294075,
        0.028243402019143105,
        -0.05496757850050926,
        0.04269915074110031,
        -0.0067554013803601265,
        0.03689150512218475,
        -0.04715585708618164,
        -0.018903855234384537,
        -0.051796767860651016,
        0.061638351529836655,
        -0.033666811883449554,
        -0.05999862775206566,
        -0.0566386952996254,
        0.030105844140052795,
        -0.04663558304309845,
        -0.06206721439957619,
        -0.054441794753074646,
        -0.023009540513157845,
        0.04439648613333702,
        0.04085184633731842,
        0.057679079473018646,
        0.03619544208049774,
        0.05987691506743431,
        0.016676517203450203,
        -0.040477871894836426,
        0.05494924262166023,
        0.011931905522942543,
        0.05288691446185112,
        0.057412270456552505,
        -0.06192563474178314,
        0.04603121802210808,
        0.0427473708987236,
        0.05721009522676468,
        0.023120243102312088,
        -0.060536254197359085,
        0.0224692951887846,
        -0.013157467357814312,
        -0.019780155271291733,
        0.0346318781375885,
        -0.005966725293546915,
        -0.018999112769961357,
        -0.04939321056008339,
        0.036844171583652496,
        0.05315332114696503,
        0.06163669377565384,
        -0.03264940530061722,
        0.04842337220907211,
        -0.023718610405921936,
        -0.05553298071026802,
        0.049203574657440186,
        0.0615326352417469,
        0.01288558915257454,
        0.02149570919573307,
        -0.06205108389258385,
        -0.010620289482176304,
        0.05129717290401459,
        0.017799753695726395,
        -0.055577751249074936,
        -0.061608340591192245,
        -0.052096594125032425,
        -0.04342048242688179,
        -0.05269511416554451,
        0.02667037770152092,
        -0.01654561050236225,
        0.05324166640639305,
        0.05617619678378105,
        0.04962904006242752,
        -0.05060597509145737,
        -0.034676216542720795,
        -0.04134519770741463,
        0.022521154955029488,
        0.00237099127843976,
        0.03895888477563858,
        0.010665037669241428,
        -0.000025932929929695092,
        -0.04005517065525055,
        0.06196802109479904,
        0.05872279405593872,
        0.05946730822324753,
        -0.044826168566942215,
        -0.025332236662507057,
        -0.05658829212188721,
        0.013390319421887398,
        -0.04760442301630974,
        -0.04934046417474747,
        0.06247040256857872,
        -0.011903109028935432,
        -0.026895318180322647,
        -0.046230245381593704,
        0.05655989423394203,
        0.03214413672685623
    ],
    [
        0.05437013506889343,
        0.04839495196938515,
        0.00902526080608368,
        0.05240212008357048,
        -0.043400175869464874,
        -0.018683454021811485,
        -0.030847767367959023,
        -0.05817277356982231,
        0.06186077743768692,
        0.05804426595568657,
        -0.020385077223181725,
        0.030495155602693558,
        -0.04658268764615059,
        -0.017357682809233665,
        -0.058733049780130386,
        0.06046074256300926,
        -0.010574132204055786,
        0.04973113164305687,
        0.011643799021840096,
        -0.061519596725702286,
        0.06193547323346138,
        0.06254974752664566,
        -0.017198191955685616,
        -0.023252500221133232,
        0.020868783816695213,
        0.053978364914655685,
        0.040593087673187256,
        -0.05747982859611511,
        0.022843848913908005,
        0.05434766784310341,
        0.04069320857524872,
        -0.02962728776037693,
        -0.025598542764782906,
        -0.061809320002794266,
        0.052857767790555954,
        0.0561707429587841,
        -0.0597442165017128,
        -0.05524138733744621,
        0.010326859541237354,
        0.028477583080530167,
        0.049561262130737305,
        0.02110961265861988,
        0.0307642612606287,
        -0.06200747564435005,
        0.01969861052930355,
        0.058092013001441956,
        -0.03945089504122734,
        0.0567689910531044,
        0.060861606150865555,
        -0.022796068340539932,
        0.05092381685972214,
        0.062141843140125275,
        -0.03628797084093094,
        -0.01215475331991911,
        -0.04470503702759743,
        0.004193001892417669,
        0.03273872286081314,
        0.014411408454179764,
        -0.006198435556143522,
        -0.05473759397864342,
        -0.05690859258174896,
        0.03390949219465256,
        -0.049928516149520874,
        0.059419386088848114,
        -0.05752284824848175,
        -0.048896417021751404,
        -0.05529545992612839,
        -0.0038686541374772787,
        -0.061748724430799484,
        -0.014386515133082867,
        -0.032423071563243866,
        -0.061508070677518845,
        0.05939672514796257,
        0.0022641303949058056,
        -0.00430463720113039,
        -0.039164360612630844,
        -0.008677477017045021,
        0.06278764456510544,
        -0.009999493137001991,
        0.030356235802173615,
        0.014719069004058838,
        0.05817996710538864,
        -0.04299180582165718,
        0.053558673709630966,
        0.04691125452518463,
        0.055716708302497864,
        0.0315307080745697,
        -0.053213123232126236,
        0.05714060738682747,
        0.05410207435488701,
        0.03556026518344879,
        0.03544357419013977,
        -0.062281928956508636,
        -0.014911534264683723,
        -0.045333947986364365,
        0.056744083762168884,
        -0.034545376896858215,
        -0.0572274886071682,
        0.02822169102728367,
        -0.002372301649302244,
        -0.059988148510456085,
        0.04631246626377106,
        0.0383479967713356,
        0.022786706686019897,
        0.006758729927241802,
        -0.039738621562719345,
        -0.03658114746212959,
        0.05192108079791069,
        -0.029209835454821587,
        0.055814169347286224,
        -0.05783378705382347,
        0.00540011003613472,
        -0.0583464689552784,
        0.020156612619757652,
        0.026415187865495682,
        0.005027939099818468,
        0.06101716309785843,
        -0.034048326313495636,
        0.06275897473096848,
        0.046327318996191025,
        -0.05813124030828476,
        0.06196432188153267,
        0.013238674961030483,
        0.003827692475169897,
        -0.04100808873772621,
        -0.05446632578969002,
        -0.058033592998981476,
        -0.058246418833732605,
        0.05992857366800308,
        0.045849185436964035,
        0.0566161684691906,
        -0.038221325725317,
        -0.039298318326473236,
        0.0013208790915086865,
        -0.05768762156367302,
        -0.05950779467821121,
        0.04989307001233101,
        0.035468220710754395,
        -0.05880534648895264,
        0.03358268737792969,
        0.05531341955065727,
        -0.060059111565351486,
        0.04735586792230606,
        0.03830335661768913,
        0.06217257305979729,
        0.028683429583907127,
        0.05871860682964325,
        -0.05124837905168533,
        0.05534942075610161,
        0.05525784194469452,
        0.06157798320055008,
        -0.06138508766889572,
        0.03962405025959015,
        -0.06140109524130821,
        0.04997975006699562,
        -0.06056255102157593,
        0.031600892543792725,
        0.05199764668941498,
        -0.015693185850977898,
        0.051259320229291916,
        0.014362193644046783,
        0.05399184301495552,
        0.050883132964372635,
        0.04922729358077049,
        0.037264928221702576,
        0.04142477735877037,
        -0.029350968077778816,
        0.04795563966035843,
        -0.014139085076749325,
        0.003276610281318426,
        0.01563960686326027,
        0.044185884296894073,
        -0.009122895076870918,
        -0.043540988117456436,
        0.04519768804311752,
        0.06003637984395027,
        -0.059310346841812134,
        -0.026145193725824356,
        0.03553719446063042,
        -0.057088885456323624,
        -0.045547567307949066,
        0.06090689077973366,
        -0.04838978126645088,
        0.007063350640237331,
        0.042475197464227676,
        0.01469977293163538,
        -0.04531574621796608,
        0.047843869775533676,
        -0.05617551505565643,
        -0.03388901427388191,
        -0.01979984901845455,
        -0.0127036664634943,
        -0.0048229144886136055,
        -0.03016600012779236,
        -0.05504589155316353,
        0.010725929401814938,
        -0.05622519925236702,
        0.027104923501610756,
        -0.05511554703116417,
        -0.0005832839524373412,
        0.058865901082754135,
        -0.001978077460080385,
        0.005737460218369961,
        -0.03414573147892952,
        -0.05453653633594513,
        0.01491539552807808,
        0.04674705117940903,
        0.04980115219950676,
        -0.03492171689867973,
        0.058965399861335754,
        -0.05774817243218422,
        0.05164429917931557,
        -0.03881080448627472,
        0.037706587463617325,
        -0.03817155212163925,
        0.015934836119413376,
        0.02305309846997261,
        -0.010536309331655502,
        0.059282176196575165,
        0.053907085210084915,
        0.0462145134806633,
        0.05931759998202324,
        -0.06066931411623955,
        -0.04131900891661644,
        0.061160072684288025,
        0.020805802196264267,
        0.006641825195401907,
        0.021840717643499374,
        0.04480205103754997,
        -0.05194278433918953,
        0.05120571330189705,
        -0.060607243329286575,
        0.019770000129938126,
        0.04468020424246788,
        -0.012457698583602905,
        0.06261037290096283,
        0.05916495993733406,
        -0.03842562809586525,
        -0.005089589860290289,
        0.06279295682907104,
        -0.013758108019828796,
        0.06149736046791077,
        -0.06271076202392578,
        0.03742457926273346,
        0.04236754775047302,
        0.006320906337350607,
        -0.06136413663625717,
        -0.004607451148331165,
        -0.06094535440206528,
        0.011760519817471504,
        -0.05829384922981262,
        -0.059080708771944046,
        0.048840004950761795,
        -0.060794007033109665,
        -0.06263984739780426,
        0.060651231557130814,
        0.029985269531607628,
        -0.06097031012177467,
        0.0069464570842683315,
        0.02590029314160347,
        0.05050906911492348,
        -0.04498252272605896,
        -0.052646663039922714,
        -0.017965245991945267,
        -0.017169438302516937,
        0.045514803379774094,
        0.034378375858068466,
        0.033664777874946594,
        -0.06096348911523819,
        0.024286910891532898,
        0.05774791166186333,
        -0.029149780049920082,
        0.06235766410827637,
        -0.03676525503396988,
        -0.04977360740303993,
        0.038100242614746094,
        -0.044341251254081726,
        -0.010415388271212578,
        -0.051621586084365845,
        -0.053827911615371704,
        -0.056830309331417084,
        0.0131797781214118,
        -0.062396880239248276,
        0.06007591634988785,
        -0.03467608615756035,
        -0.014774663373827934,
        -0.00047095923218876123,
        0.05634775385260582,
        0.05929793417453766,
        0.029338954016566277,
        -0.05371474102139473,
        0.04608045518398285,
        -0.035078369081020355,
        0.054980505257844925,
        0.06130160018801689,
        -0.061600133776664734,
        0.05648935213685036,
        0.06236601620912552,
        -0.06203998997807503,
        0.058785226196050644,
        0.008295931853353977,
        0.03198539465665817,
        -0.02379627898335457,
        0.03686591237783432,
        -0.0291264858096838,
        -0.009391352534294128,
        -0.06052844598889351,
        0.05423426255583763,
        0.04257765784859657,
        -0.034770287573337555,
        0.048911772668361664,
        -0.015293329954147339,
        -0.03696050867438316,
        0.02844373881816864,
        0.04294098541140556,
        -0.05825529620051384,
        -0.02404671348631382,
        -0.01841486617922783,
        0.026996908709406853,
        -0.03975614160299301,
        0.01820969022810459,
        -0.05165685713291168,
        0.04096416011452675,
        0.05901288986206055,
        0.05568499118089676,
        -0.06067162752151489,
        0.005925651174038649,
        0.006472517736256123,
        -0.054570309817790985,
        -0.02466067112982273,
        0.021099571138620377,
        -0.05796356126666069,
        -0.012917744927108288,
        -0.03497878089547157,
        0.05669364705681801,
        0.02090599201619625,
        -0.028311878442764282,
        0.028428373858332634,
        0.029054611921310425,
        0.06174102798104286,
        -0.047678396105766296,
        0.03143184259533882,
        -0.023891417309641838,
        -0.03763569891452789,
        0.013547944836318493,
        0.04661846533417702,
        0.034476108849048615,
        0.059748973697423935,
        -0.05429599806666374,
        0.05725954473018646,
        0.061134710907936096,
        0.05938101187348366,
        -0.04325835406780243,
        -0.038163866847753525,
        -0.037715110927820206,
        0.017545606940984726,
        -0.042660728096961975,
        -0.03225446492433548,
        0.057848431169986725,
        -0.06198381260037422,
        0.03894907981157303,
        -0.045010458678007126,
        -0.03590407967567444,
        0.0005608946667052805,
        0.02061126008629799,
        -0.04901210591197014,
        0.056693434715270996,
        -0.026799369603395462,
        0.037944674491882324,
        -0.0609290637075901,
        -0.045491062104701996,
        0.016046075150370598,
        0.060790643095970154,
        0.012454944662749767,
        0.04996008798480034,
        0.031983550637960434,
        -0.057935602962970734,
        0.023258613422513008,
        0.05296426638960838,
        0.03191739693284035,
        -0.01103911641985178,
        -0.05660441517829895,
        -0.062213584780693054,
        0.03652118518948555,
        0.0623338483273983,
        -0.018778610974550247,
        -0.01778532937169075,
        -0.06092940643429756,
        -0.0521911159157753,
        -0.05442751571536064,
        -0.06276758760213852,
        -0.048407215625047684,
        -0.008342375978827477,
        -0.04043867439031601,
        -0.03542976826429367,
        0.058439649641513824,
        0.0002294352016178891,
        0.03540891408920288,
        0.04760115593671799,
        -0.05893043801188469,
        -0.003381467657163739,
        0.06176864728331566,
        0.038096833974123,
        -0.05879684537649155,
        -0.05362594127655029,
        0.04809550568461418,
        -0.014961151406168938,
        -0.0537368468940258,
        0.05493704229593277,
        0.007833440788090229,
        -0.051706451922655106,
        -0.03842660039663315,
        0.06066584214568138,
        -0.0077000888995826244,
        0.027023451402783394,
        -0.037790533155202866,
        -0.013463025912642479,
        0.05795341357588768,
        0.04833678528666496,
        0.05477195978164673,
        0.05531042441725731,
        -0.051033422350883484,
        0.009440743364393711,
        -0.05506929010152817,
        0.040493596345186234,
        -0.011727256700396538,
        0.019493190571665764,
        -0.051431186497211456,
        0.006777901202440262,
        -0.05729469656944275,
        0.06164335086941719,
        -0.038807328790426254,
        -0.05965642258524895,
        -0.054390694946050644,
        0.03234206885099411,
        -0.053538862615823746,
        -0.06103970482945442,
        -0.05371926352381706,
        -0.0037749120965600014,
        0.03248392045497894,
        0.05781732499599457,
        0.05848083272576332,
        0.006765753962099552,
        0.058464836329221725,
        0.05600886791944504,
        -0.03664667159318924,
        0.05434757471084595,
        0.012787247076630592,
        0.04910866543650627,
        0.06027386710047722,
        -0.0619560144841671,
        0.0528700053691864,
        0.038276780396699905,
        0.060283973813056946,
        0.006217361893504858,
        -0.06038176268339157,
        0.025595957413315773,
        0.007354971952736378,
        -0.029611293226480484,
        0.015842920169234276,
        0.029253583401441574,
        -0.027635004371404648,
        -0.04740770161151886,
        0.048428911715745926,
        0.04836541786789894,
        0.06185116246342659,
        -0.02690100111067295,
        0.05078303813934326,
        -0.030634144321084023,
        -0.05706873908638954,
        0.04079243540763855,
        0.058095782995224,
        0.014399967156350613,
        0.03365842625498772,
        -0.06183985620737076,
        -0.017767662182450294,
        0.05207091197371483,
        0.02292928285896778,
        -0.05590113624930382,
        -0.06213244050741196,
        -0.04392131417989731,
        -0.05183452367782593,
        -0.04174359142780304,
        0.02041424624621868,
        0.02008742094039917,
        0.04995995759963989,
        0.06067406013607979,
        0.05315515026450157,
        -0.044692859053611755,
        -0.02840113639831543,
        -0.0488756038248539,
        -0.0011197443818673491,
        0.0356927216053009,
        0.04251893609762192,
        -0.026234440505504608,
        -0.029174692928791046,
        -0.05167942866683006,
        0.06194483861327171,
        0.0585392527282238,
        0.059748560190200806,
        -0.04394827410578728,
        -0.021729804575443268,
        -0.055462732911109924,
        0.01116314996033907,
        -0.0475606769323349,
        -0.04857109487056732,
        0.06254423409700394,
        -0.012798119336366653,
        0.015245584771037102,
        -0.038707926869392395,
        0.04466886818408966,
        0.023831669241189957
    ],
    [
        0.0520717054605484,
        0.04381102696061134,
        0.003776860423386097,
        0.04379434883594513,
        -0.04564770311117172,
        -0.010233123786747456,
        -0.0383368656039238,
        -0.058620844036340714,
        0.06213034689426422,
        0.0586254708468914,
        -0.02527902089059353,
        0.02133423276245594,
        -0.050341349095106125,
        -0.03638148307800293,
        -0.05783827230334282,
        0.06155845895409584,
        -0.003062121570110321,
        0.05321420356631279,
        0.017918022349476814,
        -0.062083274126052856,
        0.06192056089639664,
        0.0630573108792305,
        -0.016072953119874,
        -0.01629572920501232,
        0.002982068108394742,
        0.04616069048643112,
        0.040894679725170135,
        -0.06047917157411575,
        0.019830964505672455,
        0.056275829672813416,
        0.007976693101227283,
        -0.0401143804192543,
        -0.015410694293677807,
        -0.060344815254211426,
        0.05337895080447197,
        0.05689059570431709,
        -0.05915781110525131,
        -0.05173157900571823,
        0.0023063879925757647,
        0.033211082220077515,
        0.0493888221681118,
        0.006929198279976845,
        0.033551111817359924,
        -0.06278583407402039,
        0.02893666923046112,
        0.05865252763032913,
        -0.03759317472577095,
        0.058893803507089615,
        0.0580747127532959,
        -0.00019149892614223063,
        0.056238096207380295,
        0.06271982192993164,
        -0.041121575981378555,
        -0.005998138803988695,
        -0.04705951735377312,
        0.0069856285117566586,
        0.0424562506377697,
        0.026576891541481018,
        0.0016653689090162516,
        -0.05852889642119408,
        -0.05837533250451088,
        0.02965354360640049,
        -0.05174771696329117,
        0.06090083718299866,
        -0.05620107799768448,
        -0.04129385948181152,
        -0.05517046898603439,
        -0.026785975322127342,
        -0.06259633600711823,
        -0.002759619615972042,
        -0.04381752014160156,
        -0.05876176431775093,
        0.059963565319776535,
        0.005022259894758463,
        -0.010530701838433743,
        -0.03738969936966896,
        -0.015202261507511139,
        0.06332003325223923,
        -0.0347125269472599,
        0.004276562947779894,
        0.010513359680771828,
        0.058383166790008545,
        -0.03831157833337784,
        0.05253259837627411,
        0.053342923521995544,
        0.057667896151542664,
        0.021023869514465332,
        -0.04680432751774788,
        0.05573108792304993,
        0.05393524840474129,
        0.0407944917678833,
        0.0183845367282629,
        -0.06297876685857773,
        -0.015320704318583012,
        -0.050059691071510315,
        0.057375017553567886,
        -0.04219381883740425,
        -0.058219362050294876,
        0.04165101796388626,
        -0.0064316061325371265,
        -0.06214252859354019,
        0.05254019424319267,
        0.03652126342058182,
        0.03484819829463959,
        0.012301142327487469,
        -0.04969673231244087,
        -0.017246728762984276,
        0.05722543224692345,
        -0.015899326652288437,
        0.057023558765649796,
        -0.06056921184062958,
        0.0077139767818152905,
        -0.059591736644506454,
        0.002564863068982959,
        0.03515283018350601,
        0.008850647136569023,
        0.06168310344219208,
        -0.045841578394174576,
        0.06327866017818451,
        0.046926964074373245,
        -0.057947080582380295,
        0.06187326833605766,
        0.02807728387415409,
        -0.0037556453607976437,
        -0.04421807825565338,
        -0.05608423426747322,
        -0.05491708219051361,
        -0.06040411442518234,
        0.06002771109342575,
        0.051198530942201614,
        0.06041426956653595,
        -0.03736301511526108,
        -0.04246830567717552,
        -0.0137415062636137,
        -0.058183398097753525,
        -0.06033279746770859,
        0.05234779417514801,
        0.037216927856206894,
        -0.06161016970872879,
        0.03653356060385704,
        0.053091250360012054,
        -0.05753632262349129,
        0.04900294169783592,
        0.0398823507130146,
        0.06221920624375343,
        0.026951931416988373,
        0.05967871844768524,
        -0.05544058233499527,
        0.05359108746051788,
        0.05607357248663902,
        0.0611301064491272,
        -0.061632413417100906,
        0.05652899295091629,
        -0.06195063516497612,
        0.05080011859536171,
        -0.060485176742076874,
        0.016414187848567963,
        0.05270609259605408,
        -0.013785011135041714,
        0.041360657662153244,
        0.017500795423984528,
        0.05228409171104431,
        0.053105250000953674,
        0.04060006141662598,
        0.042516786605119705,
        0.04530613496899605,
        -0.025148387998342514,
        0.04221784323453903,
        -0.031836237758398056,
        -0.0019258592510595918,
        0.039864346385002136,
        0.03653300926089287,
        0.007864224724471569,
        -0.04664655029773712,
        0.05381660908460617,
        0.05827819183468819,
        -0.05311990901827812,
        -0.008810487575829029,
        0.03204973042011261,
        -0.05855373293161392,
        -0.04433763399720192,
        0.06212622672319412,
        -0.03784741833806038,
        -0.01773562654852867,
        0.043269719928503036,
        -0.002317806240171194,
        -0.03641127794981003,
        0.05195661261677742,
        -0.05822752043604851,
        -0.02059558406472206,
        -0.01033055316656828,
        -0.011280431412160397,
        0.014878392219543457,
        -0.010317250154912472,
        -0.05360930413007736,
        -0.005403936840593815,
        -0.05631579831242561,
        0.029834575951099396,
        -0.058305032551288605,
        0.010925659909844398,
        0.05553319305181503,
        0.01183224841952324,
        -0.028868364170193672,
        -0.04600079730153084,
        -0.05935577675700188,
        0.008481333963572979,
        0.04431742802262306,
        0.04412555694580078,
        -0.020410245284438133,
        0.06163293495774269,
        -0.05676119774580002,
        0.05441971868276596,
        -0.02437104843556881,
        0.04952378198504448,
        -0.02026689611375332,
        -0.0015363165875896811,
        -0.018157534301280975,
        -0.022159254178404808,
        0.05821026861667633,
        0.051138292998075485,
        0.044213589280843735,
        0.06013597920536995,
        -0.060106176882982254,
        -0.03869267925620079,
        0.059867776930332184,
        0.042477741837501526,
        0.017030823975801468,
        0.025280846282839775,
        0.04602528735995293,
        -0.048596713691949844,
        0.04112346097826958,
        -0.05723322182893753,
        0.011669388972222805,
        0.048521798104047775,
        -0.0016586200799793005,
        0.06275611370801926,
        0.06023208424448967,
        -0.02745049074292183,
        -0.004424207843840122,
        0.06332125514745712,
        0.0013239179970696568,
        0.0614331029355526,
        -0.06317581981420517,
        0.03894294425845146,
        0.04084495082497597,
        0.009575019590556622,
        -0.06185104697942734,
        -0.02281956560909748,
        -0.06048135459423065,
        0.020279059186577797,
        -0.059288278222084045,
        -0.059523019939661026,
        0.03866781294345856,
        -0.06031341105699539,
        -0.0631801187992096,
        0.061267148703336716,
        0.013618591241538525,
        -0.06078806892037392,
        0.016894569620490074,
        0.0111611969769001,
        0.046069882810115814,
        -0.045849744230508804,
        -0.053918592631816864,
        -0.02998715080320835,
        -0.02555663511157036,
        0.04241155833005905,
        0.034968070685863495,
        0.04647035524249077,
        -0.06095852702856064,
        0.02859470248222351,
        0.057530663907527924,
        -0.024354837834835052,
        0.06235099956393242,
        -0.018881574273109436,
        -0.054621607065200806,
        0.038570765405893326,
        -0.04544753208756447,
        -0.0051794834434986115,
        -0.05556619539856911,
        -0.05294868350028992,
        -0.056242767721414566,
        0.022903798148036003,
        -0.06256821751594543,
        0.06011789292097092,
        -0.032796114683151245,
        -0.0250672847032547,
        0.006676729768514633,
        0.059039365500211716,
        0.058205414563417435,
        0.0197893138974905,
        -0.04540291428565979,
        0.04211162403225899,
        -0.03325933218002319,
        0.055093128234148026,
        0.06214229390025139,
        -0.06216992810368538,
        0.05895429477095604,
        0.06291315704584122,
        -0.0628431886434555,
        0.05885178968310356,
        -0.006100954953581095,
        0.03461155295372009,
        -0.022539233788847923,
        0.028028545901179314,
        -0.025567516684532166,
        -0.019379716366529465,
        -0.06053014472126961,
        0.04920634999871254,
        0.043472930788993835,
        -0.00003508840745780617,
        0.0475238561630249,
        0.007851878181099892,
        -0.04670187085866928,
        0.0158105306327343,
        0.04683836176991463,
        -0.058400265872478485,
        -0.026558956131339073,
        -0.025423061102628708,
        0.02496613748371601,
        -0.04253341257572174,
        0.022955209016799927,
        -0.05475985258817673,
        0.03894031047821045,
        0.05899736285209656,
        0.053731925785541534,
        -0.060727186501026154,
        0.006223364733159542,
        0.014087837189435959,
        -0.048315852880477905,
        -0.009901946410536766,
        0.02242610789835453,
        -0.05815247818827629,
        -0.03214418143033981,
        -0.040297653526067734,
        0.05735631287097931,
        0.02955786883831024,
        -0.015003993175923824,
        0.02533886767923832,
        0.03103673830628395,
        0.06254637241363525,
        -0.049898792058229446,
        0.024735329672694206,
        -0.026314103975892067,
        -0.024958401918411255,
        -0.026071052998304367,
        0.05051007121801376,
        0.031258903443813324,
        0.05852130427956581,
        -0.055659133940935135,
        0.0556054450571537,
        0.061229050159454346,
        0.05909084528684616,
        -0.046029407531023026,
        -0.03666336461901665,
        -0.02933543175458908,
        0.005783458705991507,
        -0.04383566603064537,
        -0.03196794539690018,
        0.05921366065740585,
        -0.06230698153376579,
        0.039985574781894684,
        -0.04142105206847191,
        -0.03769663721323013,
        -0.004918936174362898,
        0.03996016085147858,
        -0.05347380042076111,
        0.05368088185787201,
        -0.0365547277033329,
        0.023661978542804718,
        -0.061785221099853516,
        -0.04659263789653778,
        0.010868764482438564,
        0.059494748711586,
        0.018091654404997826,
        0.04083999618887901,
        0.025263039395213127,
        -0.05230690538883209,
        0.034619394689798355,
        0.05587197467684746,
        0.03319021314382553,
        -0.04008834809064865,
        -0.05668792501091957,
        -0.06299908459186554,
        0.030896028503775597,
        0.06289642304182053,
        -0.008728054352104664,
        -0.026580296456813812,
        -0.06223694980144501,
        -0.05746552720665932,
        -0.04810316860675812,
        -0.06317359209060669,
        -0.05126647651195526,
        0.003806660184636712,
        -0.031114032492041588,
        -0.04278115928173065,
        0.05788880214095116,
        -0.001375423395074904,
        0.0264911986887455,
        0.04957820847630501,
        -0.06013280525803566,
        -0.0035019423812627792,
        0.06178613379597664,
        0.03917890414595604,
        -0.05998397246003151,
        -0.04912750795483589,
        0.04609155282378197,
        -0.01122533529996872,
        -0.056300725787878036,
        0.05536439269781113,
        0.008985810913145542,
        -0.05245885252952576,
        -0.033393871039152145,
        0.06179474666714668,
        -0.022417407482862473,
        0.029926609247922897,
        -0.03364907577633858,
        -0.03331087529659271,
        0.04763132333755493,
        0.050192542374134064,
        0.05137072876095772,
        0.04994886368513107,
        -0.0561099573969841,
        0.01894577220082283,
        -0.054644715040922165,
        0.01767333224415779,
        0.016078779473900795,
        0.006213397718966007,
        -0.05025920271873474,
        0.006090042181313038,
        -0.05362166464328766,
        0.061688508838415146,
        -0.03158541023731232,
        -0.056250568479299545,
        -0.05421993508934975,
        0.04778699204325676,
        -0.052978433668613434,
        -0.058659665286540985,
        -0.04260842129588127,
        0.006477294024080038,
        0.016899622976779938,
        0.05219761282205582,
        0.059059128165245056,
        0.016455426812171936,
        0.05941009894013405,
        0.059213459491729736,
        -0.042588163167238235,
        0.05437658727169037,
        0.021509338170289993,
        0.052589017897844315,
        0.06195734813809395,
        -0.06248381361365318,
        0.04537701606750488,
        0.031701188534498215,
        0.058974385261535645,
        0.007036054041236639,
        -0.06138543412089348,
        0.026401622220873833,
        -0.008248991332948208,
        0.009110122919082642,
        0.008033351972699165,
        0.04221796244382858,
        -0.029060309752821922,
        -0.033515457063913345,
        0.04808424413204193,
        0.045931123197078705,
        0.06207595765590668,
        -0.031780507415533066,
        0.05396682024002075,
        -0.03441006690263748,
        -0.056241922080516815,
        0.035158541053533554,
        0.04723446071147919,
        0.009086353704333305,
        0.017871160060167313,
        -0.0628550574183464,
        -0.0354287326335907,
        0.056756455451250076,
        0.015896106138825417,
        -0.05469053238630295,
        -0.06268802285194397,
        -0.045638177543878555,
        -0.044820386916399,
        -0.0522880032658577,
        0.028706993907690048,
        0.023962518200278282,
        0.053731631487607956,
        0.06218981742858887,
        0.05197804421186447,
        -0.04235657677054405,
        -0.027546709403395653,
        -0.04237835109233856,
        0.01404930092394352,
        0.02871655486524105,
        0.04362622648477554,
        -0.0187943447381258,
        -0.024543151259422302,
        -0.04847056046128273,
        0.06251352280378342,
        0.05925184488296509,
        0.058237046003341675,
        -0.05532294884324074,
        -0.025161465629935265,
        -0.058894988149404526,
        0.008305896073579788,
        -0.03683130443096161,
        -0.04933203384280205,
        0.06286193430423737,
        -0.012557164765894413,
        0.00606080237776041,
        -0.0407647006213665,
        0.03501689061522484,
        -0.004201231524348259
    ],
    [
        -0.041056226938962936,
        0.038864485919475555,
        0.04399711266160011,
        0.02624685689806938,
        0.05328330770134926,
        -0.020393451675772667,
        0.031580764800310135,
        -0.02038286067545414,
        0.023251762613654137,
        0.05348522961139679,
        0.04934104159474373,
        0.023938434198498726,
        0.007065579295158386,
        -0.04839157313108444,
        -0.0672786682844162,
        0.06420301645994186,
        -0.05668468028306961,
        0.060963694006204605,
        -0.06804029643535614,
        -0.0051553272642195225,
        -0.062306761741638184,
        0.05548744276165962,
        0.013394569978117943,
        -0.04874539002776146,
        0.02169835940003395,
        0.04464459791779518,
        0.061214230954647064,
        -0.027892228215932846,
        0.020914653316140175,
        0.009461583569645882,
        0.05620495229959488,
        -0.010163779370486736,
        -0.038991231471300125,
        -0.06615680456161499,
        0.03501815348863602,
        0.03750765323638916,
        -0.053731366991996765,
        -0.04988696053624153,
        -0.021985646337270737,
        -0.008434047922492027,
        0.04115515574812889,
        -0.029962727800011635,
        0.048793159425258636,
        -0.06540518254041672,
        -0.013174490071833134,
        0.05951884016394615,
        0.046300191432237625,
        0.037776581943035126,
        0.019421333447098732,
        -0.003937321249395609,
        0.052598536014556885,
        0.06789762526750565,
        0.01851091906428337,
        0.0666101798415184,
        -0.04609551280736923,
        -0.04575850069522858,
        -0.056497711688280106,
        -0.008398766629397869,
        0.022119468078017235,
        0.037781763821840286,
        -0.0688694417476654,
        0.018552685156464577,
        -0.05223827436566353,
        0.037177592515945435,
        -0.06367264688014984,
        -0.004317474085837603,
        0.057406261563301086,
        -0.005554642993956804,
        -0.056936223059892654,
        -0.06164637580513954,
        -0.06992779672145844,
        -0.07171478867530823,
        0.0640246793627739,
        -0.005732374265789986,
        0.00046222840319387615,
        -0.012741276994347572,
        0.052212171256542206,
        0.06132949888706207,
        0.0297367163002491,
        -0.03556957468390465,
        -0.002985982457175851,
        0.07091780751943588,
        -0.03405296429991722,
        0.05304655805230141,
        0.05466710403561592,
        0.026416203007102013,
        0.06757284700870514,
        -0.025333300232887268,
        -0.008916306309401989,
        0.061699029058218,
        0.02711651846766472,
        -0.009753737598657608,
        -0.06212133541703224,
        -0.02604222483932972,
        0.019883107393980026,
        0.02431611716747284,
        -0.02611735090613365,
        -0.06172586977481842,
        -0.01593361608684063,
        -0.0064785839058458805,
        -0.06876560300588608,
        0.021492568776011467,
        0.06983742117881775,
        0.04761349409818649,
        0.058084581047296524,
        -0.06668835133314133,
        -0.01129860244691372,
        0.008708766661584377,
        -0.03788340836763382,
        0.01867378130555153,
        -0.05976712331175804,
        -0.04477903991937637,
        -0.06556571274995804,
        -0.0005893399938941002,
        0.019052613526582718,
        -0.048848796635866165,
        0.029473820701241493,
        -0.06291760504245758,
        0.059467967599630356,
        0.051651060581207275,
        -0.008610153570771217,
        0.07052119821310043,
        -0.010480662807822227,
        0.05218807980418205,
        -0.07061614841222763,
        -0.05612555891275406,
        -0.0580618716776371,
        -0.05504686385393143,
        0.06376253068447113,
        0.0171507615596056,
        0.036702580749988556,
        -0.038705259561538696,
        -0.061158765107393265,
        0.017207983881235123,
        -0.05374704301357269,
        -0.062242232263088226,
        0.00037164948298595846,
        -0.012366543523967266,
        -0.06514497101306915,
        0.05926230549812317,
        0.05327571928501129,
        -0.03141321614384651,
        0.05570368841290474,
        -0.049297187477350235,
        0.030986079946160316,
        0.007482849061489105,
        0.06950251758098602,
        -0.06486755609512329,
        -0.030691346153616905,
        0.06476718932390213,
        0.027184735983610153,
        -0.06772200763225555,
        0.012548497878015041,
        -0.04387649893760681,
        0.018413566052913666,
        -0.025617072358727455,
        0.053258512169122696,
        0.024433134123682976,
        -0.029812829568982124,
        -0.014975624158978462,
        0.05997457727789879,
        0.008497228845953941,
        0.05921192839741707,
        -0.03378184139728546,
        0.01878790371119976,
        -0.03117353282868862,
        -0.058459728956222534,
        -0.0006057784776203334,
        0.030206505209207535,
        0.03483491390943527,
        0.002373811323195696,
        0.02200200967490673,
        0.026162004098296165,
        -0.05740751698613167,
        0.0366683155298233,
        0.06485988944768906,
        -0.06931585818529129,
        0.028669390827417374,
        0.04910688474774361,
        -0.05873682349920273,
        0.0530734583735466,
        0.06512314826250076,
        0.044972147792577744,
        0.05924004316329956,
        -0.019917355850338936,
        0.03165196627378464,
        -0.06728728115558624,
        0.06751783937215805,
        -0.06198699027299881,
        -0.04326256737112999,
        0.0045564016327261925,
        0.021673696115612984,
        -0.03188195079565048,
        0.0026403451338410378,
        -0.04419300705194473,
        0.014771318063139915,
        -0.029450999572873116,
        -0.010612518526613712,
        -0.04924936220049858,
        0.011914113536477089,
        -0.04092289134860039,
        0.017696281895041466,
        -0.006889487151056528,
        0.012885699048638344,
        -0.060559578239917755,
        0.06295032054185867,
        0.033135391771793365,
        0.011801278218626976,
        -0.06418954581022263,
        -0.009296800009906292,
        -0.05082377791404724,
        0.05787987634539604,
        -0.02364247851073742,
        -0.0008109902846626937,
        -0.0012022260343655944,
        0.003312458051368594,
        0.050304558128118515,
        -0.009184659458696842,
        0.062000107020139694,
        -0.016170058399438858,
        0.030837547034025192,
        0.05535084381699562,
        -0.047067027539014816,
        -0.05679301917552948,
        0.05950598418712616,
        -0.039692044258117676,
        -0.00914724636822939,
        0.04104679077863693,
        -0.05103432387113571,
        -0.047927118837833405,
        -0.0019592647440731525,
        -0.06401874125003815,
        -0.0379907451570034,
        0.03970929607748985,
        -0.052083294838666916,
        0.0398237481713295,
        0.06804066151380539,
        -0.039359159767627716,
        -0.04608621075749397,
        0.048694513738155365,
        -0.004763335455209017,
        0.06576471030712128,
        -0.0709744542837143,
        0.06148572638630867,
        0.019337773323059082,
        -0.016369158402085304,
        -0.04739457368850708,
        0.03799699246883392,
        -0.010334281250834465,
        -0.013151424005627632,
        -0.03976745158433914,
        -0.06738747656345367,
        -0.02750016190111637,
        -0.0636514350771904,
        -0.054836809635162354,
        0.0667683407664299,
        -0.05300896614789963,
        -0.004530458711087704,
        0.06239407882094383,
        0.013296566903591156,
        0.06838125735521317,
        0.01614254154264927,
        0.0025936781894415617,
        0.05360499769449234,
        -0.014504366554319859,
        0.020189721137285233,
        -0.03621260076761246,
        0.058229610323905945,
        -0.018447065725922585,
        -0.04951081797480583,
        -0.003776992904022336,
        -0.06834444403648376,
        0.06531669944524765,
        -0.05296638607978821,
        -0.0571383461356163,
        -0.00955104734748602,
        -0.05902722105383873,
        0.01973630115389824,
        -0.027353918179869652,
        -0.044396061450242996,
        -0.06154997646808624,
        -0.0601767972111702,
        -0.056916676461696625,
        0.04244695603847504,
        0.00847039744257927,
        -0.04432248696684837,
        0.05903276056051254,
        -0.00546225206926465,
        -0.023910023272037506,
        0.05189019814133644,
        -0.007472279015928507,
        0.04439006373286247,
        -0.028258439153432846,
        0.045414332300424576,
        0.06590703874826431,
        -0.059116218239068985,
        0.06721106171607971,
        0.05208589509129524,
        -0.0656384527683258,
        0.03235786035656929,
        0.06256270408630371,
        0.056023307144641876,
        -0.02836029604077339,
        0.016815055161714554,
        -0.034279439598321915,
        0.004132641013711691,
        -0.061932504177093506,
        0.020091142505407333,
        0.03304961696267128,
        -0.05809840187430382,
        0.05833596736192703,
        -0.03329949826002121,
        -0.030750026926398277,
        0.0631793960928917,
        -0.05714907869696617,
        -0.04455408826470375,
        0.0671134889125824,
        -0.0485280342400074,
        -0.0026202385779470205,
        -0.04570639878511429,
        -0.060672733932733536,
        -0.02484886161983013,
        0.056188564747571945,
        0.015217016451060772,
        0.044658709317445755,
        -0.011984794400632381,
        -0.024410901591181755,
        0.0667729452252388,
        -0.005024709273129702,
        -0.051991287618875504,
        0.00935422070324421,
        -0.00818489957600832,
        -0.050066813826560974,
        -0.04747292771935463,
        0.0620078518986702,
        0.006959433201700449,
        -0.00987510196864605,
        0.06355469673871994,
        -0.015280609019100666,
        0.018805958330631256,
        0.043972257524728775,
        0.04644550010561943,
        -0.07184562087059021,
        -0.05482571944594383,
        0.07043210417032242,
        0.00857606902718544,
        -0.006885326001793146,
        0.045388415455818176,
        0.0016636013751849532,
        0.06215478479862213,
        -0.0496029369533062,
        0.02759651467204094,
        -0.06616593897342682,
        -0.04962380602955818,
        -0.04674571380019188,
        0.06492271274328232,
        -0.036973536014556885,
        0.010497950948774815,
        0.02719191461801529,
        -0.05516413599252701,
        0.06509870290756226,
        0.008120417594909668,
        -0.04959455505013466,
        0.01567341573536396,
        0.06262677162885666,
        -0.05595187470316887,
        0.07136845588684082,
        -0.037717126309871674,
        0.04493189603090286,
        -0.06584303826093674,
        -0.03873205557465553,
        -0.05443265661597252,
        0.06366782635450363,
        0.06980326026678085,
        -0.02034151554107666,
        0.03563224896788597,
        -0.04873361811041832,
        0.010974708013236523,
        0.021299047395586967,
        0.01167333498597145,
        -0.03645803779363632,
        -0.05913930386304855,
        -0.06645724177360535,
        0.024928558617830276,
        0.05866518244147301,
        0.011859981343150139,
        -0.0010216080117970705,
        -0.0653630867600441,
        -0.053421083837747574,
        -0.0563596747815609,
        -0.06566794216632843,
        -0.04539768025279045,
        0.020113330334424973,
        0.012733410112559795,
        -0.05746360495686531,
        0.06156325340270996,
        0.052424635738134384,
        0.02127561718225479,
        0.024884073063731194,
        -0.0664149671792984,
        -0.03513138368725777,
        0.04943577200174332,
        0.06275947391986847,
        -0.003130887169390917,
        -0.004745157435536385,
        0.031708769500255585,
        0.010923382826149464,
        0.003953701350837946,
        0.03386492282152176,
        -0.044990573078393936,
        -0.01376971136778593,
        -0.06676274538040161,
        0.04676010459661484,
        -0.0009209939162246883,
        0.06115879490971565,
        -0.05150806903839111,
        -0.004538193345069885,
        0.01293661817908287,
        0.013209668919444084,
        0.015492808073759079,
        -0.016459019854664803,
        -0.034905847162008286,
        -0.016058174893260002,
        -0.057093922048807144,
        -0.0076044038869440556,
        0.009037906304001808,
        0.0008107415051199496,
        -0.03774873912334442,
        -0.044817645102739334,
        -0.031042130663990974,
        0.0558256097137928,
        -0.003576385322958231,
        -0.06591492146253586,
        -0.03426626697182655,
        0.028356801718473434,
        -0.04832524061203003,
        -0.00106313347350806,
        -0.032604288309812546,
        0.00681954063475132,
        -0.03191865608096123,
        0.06361740827560425,
        0.058268941938877106,
        0.032976869493722916,
        0.022259589284658432,
        0.0606231689453125,
        0.0060522365383803844,
        0.06878737360239029,
        -0.042516667395830154,
        0.06872569769620895,
        -0.03806265816092491,
        -0.06294777244329453,
        -0.009548680856823921,
        0.05843346565961838,
        -0.0032522797118872404,
        -0.006423078011721373,
        -0.032452866435050964,
        -0.022263161838054657,
        0.028828568756580353,
        -0.05493738129734993,
        0.05850331857800484,
        0.02144015021622181,
        0.024598855525255203,
        -0.06889434158802032,
        0.012734721414744854,
        0.04528549686074257,
        0.07007098197937012,
        -0.047650568187236786,
        0.0663747489452362,
        -0.052075475454330444,
        -0.0555599108338356,
        0.028836699202656746,
        0.03807549923658371,
        0.048590291291475296,
        0.01801932044327259,
        -0.06834406405687332,
        -0.03949340060353279,
        0.03503090888261795,
        0.06300240755081177,
        -0.02206263318657875,
        -0.04585854336619377,
        -0.02712823636829853,
        -0.048505350947380066,
        -0.03735319897532463,
        -0.000564710411708802,
        0.016857121139764786,
        0.021532157436013222,
        0.04005877301096916,
        -0.05131629481911659,
        -0.04709978774189949,
        -0.03529098629951477,
        -0.05539330467581749,
        0.0228730421513319,
        -0.04699717462062836,
        0.013453851453959942,
        0.06062181666493416,
        0.03358824923634529,
        -0.0524776317179203,
        0.05937750265002251,
        0.05272627994418144,
        0.06994881480932236,
        -0.04679372161626816,
        -0.04232420027256012,
        0.02055889554321766,
        -0.006045348942279816,
        0.02394261583685875,
        -0.009034433402121067,
        0.04832976311445236,
        -0.029243888333439827,
        -0.06079309061169624,
        -0.06763303279876709,
        0.03818899393081665,
        0.06467428058385849
    ],
    [
        -0.03843475878238678,
        0.04271804168820381,
        0.03516296669840813,
        0.04464767873287201,
        0.03631926327943802,
        -0.017944306135177612,
        0.030623603612184525,
        -0.060292284935712814,
        0.034277960658073425,
        0.031573791056871414,
        0.04822608828544617,
        0.036138955503702164,
        -0.009044519625604153,
        -0.04848673939704895,
        -0.0644608661532402,
        0.06277570873498917,
        -0.049248091876506805,
        0.05825572833418846,
        -0.06466811895370483,
        -0.014802179299294949,
        -0.023122718557715416,
        0.06068636476993561,
        0.03861772641539574,
        -0.05184387043118477,
        0.032620619982481,
        0.014710208401083946,
        0.056639935821294785,
        -0.001229684567078948,
        0.0237883310765028,
        -0.02385936677455902,
        0.06131640076637268,
        0.002385471248999238,
        -0.027267668396234512,
        -0.06399782001972198,
        0.0504990853369236,
        -0.015697717666625977,
        -0.061315614730119705,
        -0.045902423560619354,
        -0.025936655700206757,
        -0.017915965989232063,
        0.03257327899336815,
        -0.011106349527835846,
        0.031587690114974976,
        -0.06399151682853699,
        0.028020432218909264,
        0.05764048919081688,
        0.05018436536192894,
        0.004618092440068722,
        0.04948188364505768,
        0.004772379994392395,
        0.05059448629617691,
        0.06531141698360443,
        0.02891203947365284,
        0.061322811990976334,
        0.009260348044335842,
        -0.040363289415836334,
        -0.055921994149684906,
        -0.006973621901124716,
        0.004129963926970959,
        0.005503705237060785,
        -0.06150324270129204,
        0.05323584005236626,
        -0.05889485031366348,
        0.02641049213707447,
        -0.05912286415696144,
        -0.02363249659538269,
        0.04389185085892677,
        -0.004720068536698818,
        -0.061399802565574646,
        -0.054930392652750015,
        -0.0592709481716156,
        -0.06914617866277695,
        0.06760284304618835,
        -0.022165533155202866,
        -0.021680762991309166,
        -0.023506000638008118,
        0.036434490233659744,
        0.0682150200009346,
        0.0028889128006994724,
        -0.0136666065081954,
        0.012041694484651089,
        0.06811653077602386,
        -0.04478807747364044,
        0.057888638228178024,
        0.06497244536876678,
        0.04317600652575493,
        0.06377021223306656,
        -0.049491386860609055,
        0.01699712686240673,
        0.05092684179544449,
        0.04579487442970276,
        0.02182171121239662,
        -0.06147967651486397,
        -0.025239665061235428,
        -0.0000940467361942865,
        0.02610989287495613,
        0.0019400137243792415,
        -0.06502898037433624,
        -0.01105288416147232,
        -0.039131782948970795,
        -0.06821250170469284,
        0.013361907564103603,
        0.0650625079870224,
        0.028617925941944122,
        0.062679223716259,
        -0.0665084645152092,
        -0.03726997599005699,
        0.04433957859873772,
        -0.055456794798374176,
        0.02898687869310379,
        -0.05895090475678444,
        -0.053298771381378174,
        -0.06504327058792114,
        0.007724858820438385,
        -0.01496260054409504,
        -0.05750736594200134,
        0.058171022683382034,
        -0.038470689207315445,
        0.0597701258957386,
        0.04349866881966591,
        0.019162174314260483,
        0.06493523716926575,
        -0.018131088465452194,
        -0.0007284111343324184,
        -0.06481348723173141,
        -0.04373826086521149,
        -0.053991567343473434,
        -0.05331992357969284,
        0.057753920555114746,
        0.0217718705534935,
        0.0009684789110906422,
        -0.055643875151872635,
        -0.058356598019599915,
        0.048398133367300034,
        -0.05822468921542168,
        -0.0644150897860527,
        -0.02921190671622753,
        -0.015154053457081318,
        -0.06731176376342773,
        0.051794905215501785,
        0.06501159071922302,
        -0.013459493406116962,
        0.05621889978647232,
        -0.04808792099356651,
        0.049154214560985565,
        0.028255999088287354,
        0.06492491811513901,
        -0.06388037651777267,
        -0.03595226630568504,
        0.06029237434267998,
        0.03543563187122345,
        -0.0656585618853569,
        -0.04217802360653877,
        -0.048907581716775894,
        -0.0030373805202543736,
        -0.04721071943640709,
        0.049067821353673935,
        0.028932057321071625,
        -0.010424582287669182,
        -0.007599706295877695,
        0.06460844725370407,
        0.036582764238119125,
        0.056399937719106674,
        0.0013916643802076578,
        0.020193971693515778,
        -0.014744527637958527,
        -0.05103438347578049,
        -0.007021408528089523,
        0.04092409461736679,
        0.04543505236506462,
        -0.017978254705667496,
        0.044962748885154724,
        -0.008868055418133736,
        -0.06095783784985542,
        0.041887544095516205,
        0.06543204933404922,
        -0.06350627541542053,
        0.015788933262228966,
        0.04998406395316124,
        -0.05434604734182358,
        0.03946463018655777,
        0.06788961589336395,
        0.027505185455083847,
        0.056920431554317474,
        0.020139561966061592,
        0.028659939765930176,
        -0.06414690613746643,
        0.06586963683366776,
        -0.06135433539748192,
        -0.03540627658367157,
        -0.04087821766734123,
        0.018140759319067,
        -0.024724533781409264,
        0.00247169123031199,
        -0.04148838669061661,
        0.03920728340744972,
        -0.010215439833700657,
        -0.013206714764237404,
        -0.05410948395729065,
        -0.01985195279121399,
        0.016259092837572098,
        0.026906266808509827,
        0.0114968940615654,
        0.0041167582385241985,
        -0.05637338384985924,
        0.06346642225980759,
        0.000183953539817594,
        0.015936167910695076,
        -0.06577744334936142,
        0.03913109749555588,
        -0.05293334648013115,
        0.02966519445180893,
        -0.02508467435836792,
        -0.0019153837347403169,
        -0.010648938827216625,
        0.021628830581903458,
        0.05268584191799164,
        -0.04320450872182846,
        0.06050274148583412,
        -0.037071842700242996,
        0.014256441034376621,
        0.06721127033233643,
        -0.05848310887813568,
        -0.06386721134185791,
        0.06367940455675125,
        -0.05788954719901085,
        0.0029887021519243717,
        0.027260206639766693,
        -0.009226181544363499,
        -0.05704142898321152,
        0.03835064545273781,
        -0.06350909918546677,
        -0.03302359953522682,
        0.04903039336204529,
        -0.04791392758488655,
        0.06760057806968689,
        0.06300969421863556,
        -0.019108036532998085,
        -0.0399569533765316,
        0.06143668666481972,
        -0.0063947346061468124,
        0.0609041228890419,
        -0.06921383738517761,
        0.06316059827804565,
        0.028245562687516212,
        -0.007716531399637461,
        -0.057392433285713196,
        0.03161109238862991,
        -0.025446714833378792,
        -0.015917468816041946,
        -0.04603932797908783,
        -0.06540688872337341,
        -0.009246453642845154,
        -0.06079428642988205,
        -0.05957334488630295,
        0.06520236283540726,
        -0.04963112995028496,
        -0.03499956801533699,
        0.05510159954428673,
        -0.011109750717878342,
        0.06782403588294983,
        0.0093320207670331,
        -0.0003181402280461043,
        0.03193914517760277,
        -0.04277116060256958,
        0.044602129608392715,
        -0.008224215358495712,
        0.039352744817733765,
        -0.031775858253240585,
        -0.0033573966939002275,
        -0.0022339331917464733,
        -0.060939423739910126,
        0.06658883392810822,
        -0.05562732741236687,
        -0.05324263870716095,
        -0.022839611396193504,
        -0.05784112215042114,
        -0.0093317162245512,
        -0.012552553787827492,
        -0.03880315646529198,
        -0.06415645033121109,
        -0.06410040706396103,
        -0.06231221556663513,
        0.03809238597750664,
        -0.01490152906626463,
        -0.04884643107652664,
        0.036152563989162445,
        0.005542450584471226,
        0.044560883194208145,
        0.05472574383020401,
        -0.004901899490505457,
        0.053645770996809006,
        -0.03707052022218704,
        0.0512358658015728,
        0.06471078097820282,
        -0.06252361834049225,
        0.0632217675447464,
        0.05621512606739998,
        -0.06375595927238464,
        0.05028441920876503,
        0.06547258049249649,
        0.063604436814785,
        -0.020376667380332947,
        0.03552925959229469,
        -0.04801668971776962,
        0.040733110159635544,
        -0.05415298789739609,
        0.0166472177952528,
        0.013553783297538757,
        -0.06369886547327042,
        0.05372142046689987,
        -0.02872150018811226,
        -0.048866160213947296,
        0.05612160265445709,
        -0.02996673807501793,
        -0.05350501835346222,
        0.06586022675037384,
        -0.03720225393772125,
        0.03310740366578102,
        -0.032491303980350494,
        -0.04753406345844269,
        -0.021471692249178886,
        0.05600763112306595,
        0.03280473127961159,
        0.050978656858205795,
        -0.023488862439990044,
        -0.048587899655103683,
        0.06382103264331818,
        0.013194681145250797,
        -0.04544848948717117,
        -0.0007378425216302276,
        -0.03792717307806015,
        -0.04974988102912903,
        -0.05380832403898239,
        0.059752143919467926,
        0.024623552337288857,
        -0.050106439739465714,
        0.06369774043560028,
        0.0005667019286192954,
        0.002159303054213524,
        0.018323512747883797,
        0.05755250155925751,
        -0.06921100616455078,
        -0.03521595150232315,
        0.06909246742725372,
        0.005148234777152538,
        0.005395743530243635,
        0.05188940092921257,
        -0.03957342356443405,
        0.06581969559192657,
        -0.03112521395087242,
        0.039686109870672226,
        -0.05530080944299698,
        -0.04164813086390495,
        -0.043514590710401535,
        0.06247268617153168,
        -0.043156638741493225,
        -0.012573085725307465,
        0.03910008817911148,
        -0.06341161578893661,
        0.059659384191036224,
        -0.002382345264777541,
        -0.052650175988674164,
        0.03010241501033306,
        0.04801018908619881,
        -0.05310770869255066,
        0.0670141652226448,
        -0.053978484123945236,
        0.05519794300198555,
        -0.06713112443685532,
        -0.03807884827256203,
        -0.020536134019494057,
        0.05885416641831398,
        0.06710095703601837,
        -0.008939897641539574,
        0.043199602514505386,
        -0.05238770321011543,
        0.028698399662971497,
        0.04061168059706688,
        0.01303326990455389,
        -0.030850373208522797,
        -0.04698554053902626,
        -0.0655222162604332,
        0.041643448173999786,
        0.061222512274980545,
        0.008487123064696789,
        -0.011888994835317135,
        -0.06312427669763565,
        -0.028431562706828117,
        -0.05638878792524338,
        -0.06705901771783829,
        -0.012841420248150826,
        0.026709869503974915,
        -0.010262285359203815,
        -0.045818496495485306,
        0.061035964637994766,
        0.04827681928873062,
        0.028578802943229675,
        0.006245363038033247,
        -0.0655859038233757,
        -0.043117374181747437,
        0.056986745446920395,
        0.05980639532208443,
        -0.012384070083498955,
        0.004050598479807377,
        0.009356243535876274,
        0.030852677300572395,
        -0.03347666561603546,
        0.05358365550637245,
        0.02876695618033409,
        0.029843522235751152,
        -0.06246902421116829,
        0.052463121712207794,
        0.015973102301359177,
        0.057465311139822006,
        -0.06117260456085205,
        0.03492327034473419,
        0.02737891674041748,
        0.022715719416737556,
        0.04127376526594162,
        -0.000049000682338373736,
        -0.04010651260614395,
        -0.005604101344943047,
        -0.02118646912276745,
        0.028432801365852356,
        -0.014431198127567768,
        -0.0012947837822139263,
        -0.04947986453771591,
        -0.029194924980401993,
        -0.0326717309653759,
        0.054771095514297485,
        -0.004614580888301134,
        -0.06554444134235382,
        -0.027723446488380432,
        0.019100621342658997,
        -0.041873011738061905,
        -0.051027629524469376,
        0.002678103279322386,
        0.01166805624961853,
        0.004207530524581671,
        0.06036478281021118,
        0.06285107880830765,
        0.06147545203566551,
        0.052586331963539124,
        0.054793231189250946,
        0.05110592395067215,
        0.06602202355861664,
        -0.049833994358778,
        0.06775832176208496,
        -0.008638579398393631,
        -0.05100734904408455,
        -0.022544974461197853,
        0.038991328328847885,
        0.013608730398118496,
        -0.0056248377077281475,
        -0.0555407851934433,
        0.023862574249505997,
        0.024122163653373718,
        -0.04443404823541641,
        0.04320426657795906,
        -0.020642930641770363,
        0.013058727607131004,
        -0.06849727779626846,
        0.014448977075517178,
        0.04335039108991623,
        0.06768824905157089,
        -0.04740612208843231,
        0.06345263123512268,
        -0.057683806866407394,
        -0.06316360086202621,
        0.02423430234193802,
        0.054151494055986404,
        0.02090727910399437,
        0.015974191948771477,
        -0.05610815808176994,
        -0.022930357605218887,
        0.040630042552948,
        0.055443793535232544,
        -0.0006625927635468543,
        -0.043826691806316376,
        0.0024708686396479607,
        -0.06350889801979065,
        -0.021086547523736954,
        0.0009522163309156895,
        0.024088619276881218,
        0.010672850534319878,
        0.05291523039340973,
        -0.03019162267446518,
        -0.05411558225750923,
        -0.03883133456110954,
        -0.06468556076288223,
        -0.0023891476448625326,
        -0.02668984979391098,
        -0.015083851292729378,
        0.05013507977128029,
        0.01349052507430315,
        0.0018953218823298812,
        0.05663769692182541,
        0.05718116834759712,
        0.06833439320325851,
        -0.038487184792757034,
        -0.03901583328843117,
        -0.002017685677856207,
        0.008074500598013401,
        0.045019179582595825,
        -0.021794533357024193,
        0.04264558106660843,
        0.01153512205928564,
        -0.05530821532011032,
        -0.06762361526489258,
        0.048232823610305786,
        0.06732716411352158
    ],
    [
        -0.0410379059612751,
        0.008847792632877827,
        0.020566700026392937,
        0.041521891951560974,
        0.03721478953957558,
        -0.0370495580136776,
        0.012222250923514366,
        -0.05654721334576607,
        0.047500960528850555,
        0.04107198491692543,
        0.029580729082226753,
        0.05763247236609459,
        -0.011738077737390995,
        -0.0434783399105072,
        -0.06591123342514038,
        0.05783132463693619,
        -0.05710455775260925,
        0.062042396515607834,
        -0.060132045298814774,
        0.0020446362905204296,
        -0.031583454459905624,
        0.0595858097076416,
        0.006572959013283253,
        -0.047384295612573624,
        0.03242792561650276,
        0.03172129765152931,
        0.05405237153172493,
        -0.004784595686942339,
        0.014806350693106651,
        -0.04238395020365715,
        0.060004472732543945,
        0.01405342761427164,
        -0.014787540771067142,
        -0.06742753088474274,
        0.0382385291159153,
        0.0005087510799057782,
        -0.06156598776578903,
        -0.0606696642935276,
        -0.029747094959020615,
        -0.043073832988739014,
        0.014946981333196163,
        0.008636514656245708,
        0.034861572086811066,
        -0.06737161427736282,
        0.028177410364151,
        0.06043006479740143,
        0.04628188535571098,
        0.04070392996072769,
        0.063234344124794,
        0.004642191343009472,
        0.04979253187775612,
        0.06497196108102798,
        -0.010251956060528755,
        0.06066746637225151,
        0.0067858584225177765,
        -0.052640754729509354,
        -0.035228002816438675,
        -0.034439437091350555,
        0.03701094910502434,
        -0.006703454535454512,
        -0.06723061203956604,
        0.021693089976906776,
        -0.05924394726753235,
        0.0389343686401844,
        -0.06170361861586571,
        -0.041689857840538025,
        0.04224178194999695,
        0.0022137213964015245,
        -0.06142258271574974,
        -0.05518270283937454,
        -0.06413053721189499,
        -0.06983701884746552,
        0.06818247586488724,
        0.0029486389830708504,
        -0.005606510676443577,
        -0.03193432465195656,
        0.04380400851368904,
        0.0695745125412941,
        0.01369929313659668,
        -0.002373197115957737,
        -0.00638050539419055,
        0.06788879632949829,
        -0.04555901139974594,
        0.05024127662181854,
        0.060453739017248154,
        0.035136498510837555,
        0.06619828194379807,
        -0.03420381247997284,
        -0.01770690269768238,
        0.06335923820734024,
        0.028842883184552193,
        -0.003009348874911666,
        -0.05990169942378998,
        -0.04078763350844383,
        0.03162241354584694,
        0.05476052686572075,
        -0.016344331204891205,
        -0.06389862298965454,
        -0.0038651360664516687,
        0.01487406250089407,
        -0.0675448551774025,
        0.024709222838282585,
        0.06227714568376541,
        0.02225532755255699,
        0.0610000342130661,
        -0.0648718923330307,
        -0.029606692492961884,
        0.030153438448905945,
        -0.05332978069782257,
        0.04135027527809143,
        -0.056519825011491776,
        -0.05820133164525032,
        -0.061000071465969086,
        0.03136799857020378,
        0.016805263236165047,
        -0.0430556945502758,
        0.06177881732583046,
        -0.054629284888505936,
        0.051387421786785126,
        0.052718717604875565,
        0.013539358973503113,
        0.06718224287033081,
        -0.031611353158950806,
        0.029081422835588455,
        -0.06405989080667496,
        -0.03487440198659897,
        -0.051347117871046066,
        -0.04889005795121193,
        0.0667630136013031,
        0.021471772342920303,
        0.007633353117853403,
        -0.052843984216451645,
        -0.058960139751434326,
        0.02583260089159012,
        -0.053175173699855804,
        -0.0673503503203392,
        0.015525122173130512,
        0.03539586812257767,
        -0.06825526803731918,
        0.044883377850055695,
        0.06350375711917877,
        -0.02029627561569214,
        0.05664094537496567,
        -0.0506315715610981,
        0.042270708829164505,
        0.041748423129320145,
        0.06367600709199905,
        -0.06668146699666977,
        -0.018050968647003174,
        0.05920447036623955,
        0.05906618386507034,
        -0.06422365456819534,
        -0.027225909754633904,
        -0.0058495718985795975,
        0.020919036120176315,
        -0.045903049409389496,
        0.05322154611349106,
        0.03831900656223297,
        0.00211546802893281,
        -0.02612967975437641,
        0.06030600145459175,
        0.03128637373447418,
        0.0557556189596653,
        0.0012410071212798357,
        0.03009873442351818,
        0.00880794133991003,
        -0.057619281113147736,
        -0.015834810212254524,
        0.023801855742931366,
        0.04310547560453415,
        -0.016461744904518127,
        0.02889363281428814,
        -0.0012387847527861595,
        -0.055506132543087006,
        -0.0028494885191321373,
        0.0661536306142807,
        -0.06044537201523781,
        0.0028594464529305696,
        0.051558513194322586,
        -0.05371580645442009,
        0.04086869955062866,
        0.0691034123301506,
        0.01883147470653057,
        0.05740220099687576,
        0.00003348614336573519,
        0.04717978090047836,
        -0.06690725684165955,
        0.06643492728471756,
        -0.02584907039999962,
        -0.030224235728383064,
        -0.005133712198585272,
        0.00015340112440753728,
        -0.0324283093214035,
        0.009831052273511887,
        -0.058017704635858536,
        0.01809941604733467,
        -0.001256701536476612,
        -0.04174516722559929,
        -0.06501974910497665,
        0.020516693592071533,
        -0.018963104113936424,
        0.021117130294442177,
        0.025209913030266762,
        0.021090539172291756,
        -0.05004367604851723,
        0.06313984096050262,
        0.025093434378504753,
        0.02398829534649849,
        -0.06710629910230637,
        0.056949079036712646,
        -0.05123909190297127,
        0.05361045524477959,
        0.035123664885759354,
        -0.021316703408956528,
        -0.010564799420535564,
        0.0042105792090296745,
        0.03587949648499489,
        -0.024671878665685654,
        0.06172799691557884,
        -0.04409696161746979,
        -0.017674334347248077,
        0.06175656244158745,
        -0.05743439868092537,
        -0.062472883611917496,
        0.06257829070091248,
        -0.06428012251853943,
        -0.038427967578172684,
        0.04177585989236832,
        -0.020438896492123604,
        -0.05857353284955025,
        0.034226588904857635,
        -0.06693625450134277,
        -0.03019344061613083,
        0.05599990487098694,
        -0.037001390010118484,
        0.06276992708444595,
        0.06546337157487869,
        -0.05607151240110397,
        -0.04752001538872719,
        0.06534971296787262,
        -0.011621624231338501,
        0.05949157848954201,
        -0.07008948922157288,
        0.06177922338247299,
        0.01505780965089798,
        0.014672082848846912,
        -0.048011962324380875,
        0.03869566321372986,
        -0.022589927539229393,
        -0.02186456136405468,
        -0.02899016998708248,
        -0.06877900660037994,
        -0.010259497910737991,
        -0.05994027480483055,
        -0.051306530833244324,
        0.06374777108430862,
        -0.061932522803545,
        -0.03626608848571777,
        0.0552169494330883,
        0.03340785205364227,
        0.06838545948266983,
        0.008042698726058006,
        -0.004882547538727522,
        0.04911784827709198,
        -0.055304139852523804,
        0.03183259069919586,
        0.006534732412546873,
        0.0557488314807415,
        -0.06308789551258087,
        -0.004926764871925116,
        0.00743509829044342,
        -0.06455507129430771,
        0.0651913583278656,
        -0.05238650366663933,
        -0.04366026073694229,
        -0.019834553822875023,
        -0.04078246280550957,
        0.022759025916457176,
        -0.02287808246910572,
        -0.03234978765249252,
        -0.05799322575330734,
        -0.061125051230192184,
        -0.06101874262094498,
        0.02423963136970997,
        0.00863597635179758,
        -0.027347149327397346,
        0.05046319216489792,
        0.004514738451689482,
        0.02368558757007122,
        0.058013610541820526,
        -0.02562282606959343,
        0.048411350697278976,
        -0.024998517706990242,
        0.038994889706373215,
        0.06478588283061981,
        -0.06026936322450638,
        0.06614067405462265,
        0.056844066828489304,
        -0.042458511888980865,
        0.04985929653048515,
        0.051679760217666626,
        0.062081776559352875,
        -0.02153751440346241,
        0.05107700079679489,
        -0.020259544253349304,
        0.006386902183294296,
        -0.056570108979940414,
        -0.0013159094378352165,
        0.04918144270777702,
        -0.057310815900564194,
        0.05298558995127678,
        -0.023543499410152435,
        -0.0290884617716074,
        0.05866070091724396,
        -0.0016595534980297089,
        -0.0610833615064621,
        0.06059130281209946,
        -0.04924378544092178,
        0.03395474702119827,
        -0.01873592659831047,
        -0.051647625863552094,
        0.003957515582442284,
        0.047870486974716187,
        0.02309397980570793,
        0.04272545129060745,
        -0.03546202927827835,
        -0.035003792494535446,
        0.06120374798774719,
        0.03340074047446251,
        -0.024579260498285294,
        0.00936189852654934,
        -0.02826770953834057,
        -0.05574105307459831,
        -0.025048233568668365,
        0.05921037122607231,
        0.04214147850871086,
        -0.033100783824920654,
        0.060984086245298386,
        -0.023455720394849777,
        -0.02247101441025734,
        0.04059286788105965,
        0.048336513340473175,
        -0.06964170932769775,
        -0.04610351100564003,
        0.06995619088411331,
        -0.010041867382824421,
        0.022961946204304695,
        0.05898416414856911,
        -0.02131439559161663,
        0.06494391709566116,
        -0.03204195573925972,
        0.054045576602220535,
        -0.057535093277692795,
        -0.04201892018318176,
        -0.03469167277216911,
        0.06493503600358963,
        -0.0526687316596508,
        -0.02704475261271,
        0.039532918483018875,
        -0.058824095875024796,
        0.06232163682579994,
        0.0008669940871186554,
        -0.04032498970627785,
        0.0226899404078722,
        0.05287006124854088,
        -0.055058542639017105,
        0.0697474405169487,
        -0.03631898760795593,
        0.05119321122765541,
        -0.0676637813448906,
        -0.0014165446627885103,
        -0.05108548700809479,
        0.051729653030633926,
        0.0633106604218483,
        0.0074205719865858555,
        0.05139586329460144,
        -0.05878051370382309,
        0.014396765269339085,
        0.05671432986855507,
        0.029121268540620804,
        -0.01787867583334446,
        -0.057025644928216934,
        -0.06835401058197021,
        0.029926814138889313,
        0.04968592897057533,
        -0.016339613124728203,
        -0.006365310866385698,
        -0.06483239680528641,
        -0.042349763214588165,
        -0.033489082008600235,
        -0.0674857348203659,
        -0.04550277441740036,
        0.014818662777543068,
        0.03308045119047165,
        -0.033371564000844955,
        0.05926498770713806,
        0.04770682752132416,
        0.011515259742736816,
        0.016842322424054146,
        -0.062127333134412766,
        -0.04991712048649788,
        0.05881324037909508,
        0.06450730562210083,
        0.0030292265582829714,
        -0.026905380189418793,
        0.020563628524541855,
        0.03520148992538452,
        -0.02106744982302189,
        0.05715108662843704,
        -0.02973972074687481,
        0.03998073935508728,
        -0.06404156237840652,
        0.055737558752298355,
        -0.0035580089315772057,
        0.05058233067393303,
        -0.05246420577168465,
        -0.003328669583424926,
        -0.011720861308276653,
        0.027582800015807152,
        0.02179373800754547,
        0.005583120509982109,
        0.01998649165034294,
        -0.003063737880438566,
        -0.0415620282292366,
        0.04970937967300415,
        -0.03422283008694649,
        0.00946117378771305,
        -0.01991068571805954,
        -0.05421392247080803,
        -0.015774084255099297,
        0.0576690249145031,
        0.02337653934955597,
        -0.06821653246879578,
        -0.04426385834813118,
        0.0005523846484720707,
        -0.04889638349413872,
        -0.05465998873114586,
        -0.03245319426059723,
        0.008419232442975044,
        -0.02973027341067791,
        0.03569300100207329,
        0.06006600335240364,
        0.05144044756889343,
        0.04323868826031685,
        0.05860793963074684,
        0.02217571996152401,
        0.06861186772584915,
        -0.050149306654930115,
        0.06451880931854248,
        0.018840352073311806,
        -0.04023423418402672,
        0.012564511969685555,
        0.059907712042331696,
        0.038072194904088974,
        -0.02382327988743782,
        -0.059675101190805435,
        0.02180085889995098,
        -0.018699390813708305,
        -0.04272116720676422,
        0.043133445084095,
        -0.001395283150486648,
        -0.0043425182811915874,
        -0.06720336526632309,
        0.035896558314561844,
        0.023270267993211746,
        0.06818878650665283,
        -0.04907762631773949,
        0.060636911541223526,
        -0.0525459386408329,
        -0.062304168939590454,
        0.03321881592273712,
        0.06186908856034279,
        0.022305378690361977,
        0.005868186708539724,
        -0.06433772295713425,
        0.003213374176993966,
        0.043374087661504745,
        0.058522023260593414,
        -0.006345711182802916,
        -0.03795422241091728,
        -0.028158104047179222,
        -0.06219807267189026,
        -0.03366843983530998,
        0.021833177655935287,
        0.004046806134283543,
        0.007613122928887606,
        0.03438064455986023,
        0.027157627046108246,
        -0.055255744606256485,
        -0.03973438963294029,
        -0.06069884076714516,
        -0.025882858783006668,
        -0.027111781761050224,
        -0.005150412674993277,
        0.06070670485496521,
        0.028757305815815926,
        -0.019165942445397377,
        0.06101250648498535,
        0.05999772995710373,
        0.06304167956113815,
        0.02976454608142376,
        -0.037038128823041916,
        -0.017398159950971603,
        0.015575401484966278,
        0.010964308865368366,
        -0.0488915890455246,
        0.05600611865520477,
        0.03146681562066078,
        -0.0478195883333683,
        -0.06869706511497498,
        0.0391983799636364,
        0.050938043743371964
    ],
    [
        -0.03773002326488495,
        0.053495123982429504,
        0.046064987778663635,
        0.03900346904993057,
        0.03036424145102501,
        -0.021950196474790573,
        0.030536692589521408,
        -0.05353482440114021,
        0.061043884605169296,
        0.0245736725628376,
        0.03224148601293564,
        0.03946150839328766,
        -0.026995543390512466,
        -0.05600537732243538,
        -0.0666976198554039,
        0.0600716806948185,
        -0.05856192484498024,
        0.04682205244898796,
        -0.054512832313776016,
        -0.0007038907497189939,
        -0.005191157106310129,
        0.06027579307556152,
        0.01276838406920433,
        -0.048469286412000656,
        0.027763308957219124,
        0.027333704754710197,
        0.06146848201751709,
        -0.019973034039139748,
        0.03294382244348526,
        -0.03860510140657425,
        0.05761203169822693,
        -0.0022993902675807476,
        -0.00903285201638937,
        -0.058923035860061646,
        0.061327945441007614,
        0.028713835403323174,
        -0.06344381719827652,
        -0.04995551332831383,
        0.04017576947808266,
        -0.01953541487455368,
        0.032295309007167816,
        -0.013213918544352055,
        0.023156719282269478,
        -0.0630384087562561,
        0.023312155157327652,
        0.06110787019133568,
        0.033974822610616684,
        0.011679813265800476,
        0.009816493839025497,
        0.009887044318020344,
        0.03387024253606796,
        0.057900235056877136,
        -0.012055376544594765,
        0.055135853588581085,
        0.019983960315585136,
        -0.05191172659397125,
        -0.04033385589718819,
        -0.008390308357775211,
        0.012957025319337845,
        0.019100839272141457,
        -0.06758836656808853,
        0.05842311680316925,
        -0.0629875659942627,
        0.04111172631382942,
        -0.0587945431470871,
        -0.013513213023543358,
        0.02275782823562622,
        -0.0032752270344644785,
        -0.053442396223545074,
        -0.05486779659986496,
        -0.061585117131471634,
        -0.07068058103322983,
        0.06699740141630173,
        0.018702642992138863,
        -0.008888729847967625,
        -0.035733845084905624,
        0.032348331063985825,
        0.0704658254981041,
        0.035058584064245224,
        -0.03592957183718681,
        0.009950627572834492,
        0.06998001039028168,
        -0.03240343555808067,
        0.03716951608657837,
        0.06369664520025253,
        0.04464573413133621,
        0.06436427682638168,
        -0.05398816615343094,
        0.0013215071521699429,
        0.04578704759478569,
        0.0422147773206234,
        0.003006227547302842,
        -0.06801487505435944,
        -0.011928810738027096,
        0.04395567998290062,
        0.02636191062629223,
        0.002738972194492817,
        -0.06556648015975952,
        0.006958535872399807,
        -0.006702164653688669,
        -0.07019645720720291,
        0.029860850423574448,
        0.06950243562459946,
        0.02216356247663498,
        0.06139519810676575,
        -0.06671866774559021,
        -0.03393719717860222,
        0.036821067333221436,
        -0.06105229631066322,
        0.02774827741086483,
        -0.058060936629772186,
        -0.055276088416576385,
        -0.06349468976259232,
        0.003817926160991192,
        0.01647062599658966,
        -0.05490482226014137,
        0.06144629791378975,
        -0.033887799829244614,
        0.06697459518909454,
        0.03758174926042557,
        0.023303700610995293,
        0.06638918817043304,
        -0.012475693598389626,
        -0.00601198198273778,
        -0.06579219549894333,
        -0.03922608494758606,
        -0.0392654724419117,
        -0.02209719456732273,
        0.06472521275281906,
        0.03054051846265793,
        -0.028119660913944244,
        -0.05273154005408287,
        -0.052808042615652084,
        0.01162663009017706,
        -0.05510265752673149,
        -0.06584043055772781,
        -0.0442466102540493,
        -0.014773448929190636,
        -0.06929057091474533,
        0.04538383334875107,
        0.05227179080247879,
        -0.03613206371665001,
        0.062315139919519424,
        -0.03703675791621208,
        0.035024698823690414,
        0.001895179389975965,
        0.06474945694208145,
        -0.0673489198088646,
        -0.017942335456609726,
        0.04904303699731827,
        0.030051490291953087,
        -0.06306282430887222,
        -0.04414151981472969,
        -0.04606375843286514,
        0.03416367247700691,
        -0.05268656834959984,
        0.04547419771552086,
        0.04048477113246918,
        -0.002389871049672365,
        -0.003940117079764605,
        0.06821015477180481,
        0.030723096802830696,
        0.059905942529439926,
        -0.014294103719294071,
        0.029598956927657127,
        0.014797812327742577,
        -0.052080441266298294,
        0.002758121583610773,
        0.0484926737844944,
        0.04612026736140251,
        -0.02217969112098217,
        0.02587852068245411,
        0.0023320233449339867,
        -0.06113665923476219,
        0.037623971700668335,
        0.05962716415524483,
        -0.06163220480084419,
        -0.01711074821650982,
        0.05124954506754875,
        -0.0553845576941967,
        0.05620000511407852,
        0.06937731802463531,
        0.03207472339272499,
        0.055535752326250076,
        0.042595479637384415,
        0.013751242309808731,
        -0.06648240983486176,
        0.06617944687604904,
        -0.050932563841342926,
        -0.04211817681789398,
        -0.01971561089158058,
        -0.006476312875747681,
        -0.014657574705779552,
        0.0380367636680603,
        -0.05278146266937256,
        0.043234650045633316,
        -0.01582244783639908,
        -0.02378568798303604,
        -0.05489875748753548,
        -0.017870347946882248,
        -0.010378742590546608,
        0.020355308428406715,
        -0.0014581963187083602,
        -0.0012852196814492345,
        -0.058937642723321915,
        0.06368497759103775,
        0.023174580186605453,
        -0.01696322113275528,
        -0.06715106219053268,
        0.04285716265439987,
        -0.05073411762714386,
        0.04047655686736107,
        -0.014184845611453056,
        -0.005470717791467905,
        -0.011842912063002586,
        0.0069719282910227776,
        0.042995911091566086,
        -0.045259200036525726,
        0.05526038259267807,
        -0.014528147876262665,
        0.018899455666542053,
        0.06356428563594818,
        -0.051260508596897125,
        -0.06470627337694168,
        0.06710247695446014,
        -0.0640716627240181,
        0.004287436604499817,
        0.0038201117422431707,
        -0.01644391380250454,
        -0.06366239488124847,
        0.01267948467284441,
        -0.0656459853053093,
        -0.04374907538294792,
        0.057772789150476456,
        -0.04912329465150833,
        0.06955227255821228,
        0.06739430129528046,
        -0.04812265932559967,
        -0.041351884603500366,
        0.057049378752708435,
        0.0014953131321817636,
        0.06713023036718369,
        -0.07111480832099915,
        0.06651560962200165,
        0.015877921134233475,
        -0.0005260935868136585,
        -0.06215234473347664,
        0.011999228037893772,
        -0.018141964450478554,
        -0.03248944506049156,
        -0.03393758460879326,
        -0.06889382749795914,
        -0.0007063428638502955,
        -0.06598419696092606,
        -0.061709970235824585,
        0.06528513133525848,
        -0.028074000030755997,
        -0.008781153708696365,
        0.060865215957164764,
        0.00476178340613842,
        0.06642450392246246,
        0.0038849804550409317,
        -0.00009366870654048398,
        0.03923971578478813,
        -0.0170394629240036,
        0.02746167592704296,
        0.015944823622703552,
        0.04849245399236679,
        -0.04844709113240242,
        -0.018081773072481155,
        -0.012075476348400116,
        -0.06428126245737076,
        0.06775975227355957,
        -0.050762493163347244,
        -0.0435476116836071,
        -0.009711631573736668,
        -0.0455809086561203,
        0.017450453713536263,
        -0.03694945201277733,
        -0.052970558404922485,
        -0.06574452668428421,
        -0.05264562368392944,
        -0.06330517679452896,
        0.035788267850875854,
        -0.019324829801917076,
        -0.03222319483757019,
        0.05319375917315483,
        0.035996027290821075,
        0.05681132152676582,
        0.05501428619027138,
        -0.008235258981585503,
        0.050612401217222214,
        -0.014583036303520203,
        0.05043479800224304,
        0.0667157918214798,
        -0.05941185727715492,
        0.06428568810224533,
        0.05874882638454437,
        -0.06216054409742355,
        0.05065905675292015,
        0.0677579715847969,
        0.059169232845306396,
        -0.04284484311938286,
        0.055899374186992645,
        -0.036994196474552155,
        0.03948034718632698,
        -0.06196599826216698,
        0.015521161258220673,
        0.009404250420629978,
        -0.06623020023107529,
        0.045385949313640594,
        -0.044281549751758575,
        -0.041800010949373245,
        0.05067845806479454,
        -0.034578997641801834,
        -0.05089673772454262,
        0.0652698278427124,
        -0.036624472588300705,
        0.03206662833690643,
        -0.02367653138935566,
        -0.029174285009503365,
        -0.022005032747983932,
        0.06020330637693405,
        0.023730767890810966,
        0.050673797726631165,
        -0.04238588735461235,
        -0.051169443875551224,
        0.06356960535049438,
        -0.03136123716831207,
        -0.054585229605436325,
        0.037315741181373596,
        -0.04591056704521179,
        -0.04442640766501427,
        -0.03937118873000145,
        0.05671229213476181,
        0.05301184579730034,
        -0.036847494542598724,
        0.06167000159621239,
        0.018898405134677887,
        0.00389865436591208,
        0.018381262198090553,
        0.0409165658056736,
        -0.06804893165826797,
        -0.03215275704860687,
        0.06800424307584763,
        0.0021803593263030052,
        0.014841524884104729,
        0.06203202158212662,
        -0.04988503083586693,
        0.06852638721466064,
        0.003960117697715759,
        0.041761673986911774,
        -0.04539487510919571,
        -0.058145537972450256,
        -0.04315673187375069,
        0.05671151354908943,
        -0.05607638508081436,
        0.008502436801791191,
        0.02059127390384674,
        -0.06241854280233383,
        0.06531637907028198,
        0.0013357639545574784,
        -0.05884769931435585,
        0.029065998271107674,
        0.0433550700545311,
        -0.05674409866333008,
        0.06979555636644363,
        -0.03789537400007248,
        0.04930655658245087,
        -0.06548956781625748,
        -0.022306829690933228,
        -0.05544622987508774,
        0.06074229255318642,
        0.06816490739583969,
        -0.00896211713552475,
        0.04432331398129463,
        -0.04833704233169556,
        0.040947817265987396,
        0.04929657652974129,
        0.0344499796628952,
        -0.04336683824658394,
        -0.048329468816518784,
        -0.06694648414850235,
        0.026827946305274963,
        0.05740756914019585,
        0.0439622700214386,
        -0.01617996208369732,
        -0.06599964946508408,
        -0.029231250286102295,
        -0.05508941784501076,
        -0.0674491673707962,
        -0.04404030740261078,
        0.04068376496434212,
        0.005865653045475483,
        -0.03460614010691643,
        0.06805332005023956,
        0.05734474956989288,
        0.01986219547688961,
        -0.01886143907904625,
        -0.06628598272800446,
        -0.03470873832702637,
        0.047239791601896286,
        0.048688940703868866,
        0.02276737056672573,
        -0.025586718693375587,
        0.007221593521535397,
        0.0049936724826693535,
        -0.010949469171464443,
        0.02557087503373623,
        0.012795459479093552,
        -0.011312674731016159,
        -0.06738299876451492,
        0.059753984212875366,
        -0.022162124514579773,
        0.04473479837179184,
        -0.062227971851825714,
        -0.014306982979178429,
        0.005990717560052872,
        0.0369681678712368,
        0.05994865670800209,
        -0.02210148051381111,
        -0.03335529565811157,
        -0.0005950949853286147,
        -0.022286267951130867,
        0.011770523153245449,
        0.017823215574026108,
        0.04166055843234062,
        -0.05438966676592827,
        -0.04493290185928345,
        -0.040655117481946945,
        0.05546828359365463,
        0.014373409561812878,
        -0.06627732515335083,
        -0.04950111359357834,
        -0.0026369555853307247,
        -0.039673276245594025,
        -0.047921035438776016,
        -0.02597336657345295,
        0.01766807772219181,
        0.01040858868509531,
        0.060539111495018005,
        0.05579530820250511,
        0.05833697319030762,
        0.057828955352306366,
        0.04486725479364395,
        0.056516777724027634,
        0.06488696485757828,
        -0.015135632827877998,
        0.06994403898715973,
        0.02602328732609749,
        -0.03559704124927521,
        -0.010786432772874832,
        0.015156174078583717,
        0.010858631692826748,
        -0.007406395394355059,
        -0.051235076040029526,
        0.030947698280215263,
        0.02159114181995392,
        -0.028208047151565552,
        0.054134588688611984,
        -0.015316564589738846,
        -0.0015101955505087972,
        -0.06963077187538147,
        -0.003368476638570428,
        0.048127058893442154,
        0.06893418729305267,
        -0.03391642868518829,
        0.060951750725507736,
        -0.05935470014810562,
        -0.061129048466682434,
        0.0335565060377121,
        0.029684284701943398,
        0.02407401241362095,
        0.01180612575262785,
        -0.06189845874905586,
        -0.022649291902780533,
        0.05183093249797821,
        0.04856327176094055,
        -0.013608386740088463,
        -0.055458489805459976,
        -0.006808931939303875,
        -0.06052112951874733,
        -0.04310806095600128,
        0.00660376250743866,
        0.007714591454714537,
        0.012678167782723904,
        0.046011798083782196,
        0.016953371465206146,
        -0.058660779148340225,
        -0.04412267357110977,
        -0.058416225016117096,
        0.012557413429021835,
        -0.03457930311560631,
        -0.022141214460134506,
        0.03943254426121712,
        0.015323862433433533,
        0.006570226978510618,
        0.03979707509279251,
        0.05984045937657356,
        0.07062686234712601,
        -0.04241686686873436,
        -0.04693622142076492,
        0.016030484810471535,
        0.0061003295704722404,
        0.0330776572227478,
        -0.02556355856359005,
        0.05031101405620575,
        0.01622457429766655,
        -0.05266794562339783,
        -0.06760624796152115,
        0.037996236234903336,
        0.06428699195384979
    ],
    [
        -0.004996223840862513,
        -0.03188855201005936,
        -0.04244726151227951,
        0.030823897570371628,
        0.018131142482161522,
        -0.04611177369952202,
        -0.035700928419828415,
        -0.06370920687913895,
        0.04687896743416786,
        0.034977927803993225,
        -0.02036035992205143,
        0.062299661338329315,
        -0.02969461865723133,
        -0.052583370357751846,
        -0.05644331872463226,
        0.06313467770814896,
        0.05029702186584473,
        0.06055336818099022,
        -0.06230607256293297,
        -0.0023334315046668053,
        0.055902183055877686,
        -0.05526720732450485,
        0.048227302730083466,
        -0.03549787774682045,
        -0.03570272773504257,
        0.032931480556726456,
        0.026845678687095642,
        0.008214034140110016,
        0.027959290891885757,
        0.043249186128377914,
        0.022086093202233315,
        -0.046761855483055115,
        0.0010421372717246413,
        -0.057962916791439056,
        0.02478722296655178,
        -0.05560082942247391,
        -0.060917943716049194,
        -0.05993035435676575,
        -0.06384048610925674,
        -0.04057672247290611,
        0.01806805096566677,
        0.008606701157987118,
        0.006099636200815439,
        -0.045368824154138565,
        0.05056080222129822,
        0.060079753398895264,
        0.06399276852607727,
        0.01194071490317583,
        0.04321540892124176,
        -0.0377831757068634,
        0.050551168620586395,
        0.06410381197929382,
        -0.04629259929060936,
        0.0386049784719944,
        0.03200649097561836,
        -0.05018600448966026,
        -0.05419738218188286,
        0.0396214984357357,
        -0.02229590341448784,
        0.047233521938323975,
        -0.06160665675997734,
        0.014167962595820427,
        -0.052465710788965225,
        0.060837410390377045,
        -0.05724894627928734,
        -0.024307630956172943,
        0.02219443768262863,
        0.012133152224123478,
        -0.06400199979543686,
        -0.050619225949048996,
        -0.0463128499686718,
        -0.05276316776871681,
        0.05693391337990761,
        0.013607139699161053,
        0.04005341976881027,
        -0.051969170570373535,
        0.05251137912273407,
        0.06446167081594467,
        -0.020625142380595207,
        0.01580778881907463,
        0.005350287072360516,
        0.05945175513625145,
        -0.02010994590818882,
        0.04960930347442627,
        0.058660171926021576,
        0.05188601091504097,
        0.04961809515953064,
        -0.053416211158037186,
        -0.005272350739687681,
        0.04936816170811653,
        -0.053712788969278336,
        -0.004671873990446329,
        -0.030999887734651566,
        -0.034214794635772705,
        -0.045919019728899,
        0.05283782631158829,
        -0.0037978438194841146,
        -0.05704057216644287,
        0.013261783868074417,
        -0.027773886919021606,
        -0.06315547972917557,
        0.044334907084703445,
        0.05424245819449425,
        0.053102754056453705,
        0.05163557827472687,
        -0.01641033962368965,
        -0.011933885514736176,
        0.04001269489526749,
        -0.017420414835214615,
        0.013773556798696518,
        -0.048603884875774384,
        0.038289304822683334,
        -0.061119142919778824,
        0.02246968448162079,
        -0.05208972841501236,
        -0.008726201951503754,
        0.03737984970211983,
        -0.046182747930288315,
        0.05881666764616966,
        0.06137130409479141,
        0.04432069510221481,
        0.06280694156885147,
        -0.060922250151634216,
        -0.034913189709186554,
        -0.04167299345135689,
        0.008764967322349548,
        -0.0057552228681743145,
        -0.06045672670006752,
        0.045871563255786896,
        -0.025950463488698006,
        -0.016145095229148865,
        -0.046264149248600006,
        -0.06192663684487343,
        0.04538137838244438,
        -0.05794352665543556,
        -0.062455616891384125,
        0.05202612653374672,
        0.06145954504609108,
        -0.06282837688922882,
        0.01935223862528801,
        0.06362691521644592,
        0.020934006199240685,
        0.06117431819438934,
        -0.059688836336135864,
        0.05607974901795387,
        0.05425550416111946,
        0.05098247528076172,
        -0.021799689158797264,
        0.02741403505206108,
        0.059807587414979935,
        0.05474425107240677,
        -0.06178883835673332,
        -0.0012415189994499087,
        -0.06387240439653397,
        -0.0403546504676342,
        -0.045681022107601166,
        -0.011854602955281734,
        0.04077363386750221,
        0.001958685228601098,
        0.05519088730216026,
        0.048317037522792816,
        0.03603823855519295,
        0.056967414915561676,
        0.06305943429470062,
        0.0335596539080143,
        0.04425111413002014,
        -0.05155880004167557,
        0.03431639447808266,
        0.014968553557991982,
        0.03440001234412193,
        -0.03381062299013138,
        0.04530647397041321,
        -0.009370112791657448,
        -0.051470473408699036,
        -0.016125725582242012,
        0.05899656191468239,
        -0.06450829654932022,
        0.023782385513186455,
        0.043997857719659805,
        -0.05406948924064636,
        0.009272944182157516,
        0.05715251713991165,
        0.008066516369581223,
        0.0522734709084034,
        -0.01788048818707466,
        0.020561236888170242,
        -0.055755846202373505,
        0.061586011201143265,
        -0.03737908974289894,
        -0.03491123393177986,
        0.023630935698747635,
        -0.037397950887680054,
        0.04735143110156059,
        -0.01576819084584713,
        -0.03847126290202141,
        0.03501640632748604,
        0.04508731886744499,
        -0.04215846583247185,
        -0.03076954372227192,
        -0.0037453214172273874,
        0.027661845088005066,
        -0.06007920950651169,
        0.013678856194019318,
        0.05404118821024895,
        -0.04754815623164177,
        0.06362979859113693,
        0.0037064964417368174,
        0.028559694066643715,
        -0.03762813284993172,
        -0.06436924636363983,
        -0.038288213312625885,
        0.04567843675613403,
        0.05568733811378479,
        0.05522559955716133,
        -0.003050090977922082,
        0.05153040960431099,
        0.06445523351430893,
        -0.011389551684260368,
        0.06315237283706665,
        0.040037695318460464,
        0.004471050575375557,
        0.06169915944337845,
        -0.05865662544965744,
        0.016895830631256104,
        0.0591590590775013,
        -0.03246690332889557,
        -0.013552295044064522,
        0.03902813419699669,
        0.020267250016331673,
        -0.035019151866436005,
        0.06084592640399933,
        -0.06303693354129791,
        -0.04034656658768654,
        0.039334122091531754,
        -0.0583629310131073,
        0.06145741790533066,
        0.05153283849358559,
        -0.022060468792915344,
        -0.054813042283058167,
        0.06336807459592819,
        -0.043257810175418854,
        0.059556134045124054,
        -0.06430745869874954,
        0.06030353903770447,
        0.05517948046326637,
        -0.015406970866024494,
        -0.04927824065089226,
        0.017312707379460335,
        0.006688461173325777,
        -0.05711530148983002,
        -0.04786462336778641,
        -0.06318967044353485,
        0.034811440855264664,
        -0.05779653787612915,
        -0.03630782663822174,
        0.04079456627368927,
        -0.06096140667796135,
        -0.03781724348664284,
        0.041417136788368225,
        -0.051313236355781555,
        0.06394555419683456,
        -0.03176243603229523,
        -0.05689584091305733,
        -0.004352937918156385,
        0.05235287547111511,
        0.033420268446207047,
        0.024520250037312508,
        0.03828701376914978,
        0.03147647902369499,
        0.040082693099975586,
        -0.005769613664597273,
        -0.06102357804775238,
        0.03764888271689415,
        -0.05507352948188782,
        -0.03160758316516876,
        0.01680290512740612,
        -0.037641506642103195,
        0.019628413021564484,
        0.039292968809604645,
        0.0407576858997345,
        -0.061478517949581146,
        -0.05230037868022919,
        -0.059036605060100555,
        0.06328441202640533,
        0.06215759739279747,
        -0.037371888756752014,
        0.0031137848272919655,
        -0.057937026023864746,
        -0.002154317684471607,
        0.060806941241025925,
        -0.014920300804078579,
        0.06301707774400711,
        -0.053578831255435944,
        0.011824984103441238,
        0.0576002262532711,
        -0.05481330305337906,
        0.0501655712723732,
        0.05965684354305267,
        -0.04992375522851944,
        0.05843552574515343,
        -0.0011601172154769301,
        0.04382507875561714,
        -0.02582860179245472,
        0.0425226204097271,
        0.008897065185010433,
        0.03454001247882843,
        -0.025840047746896744,
        0.009071637876331806,
        -0.021615011617541313,
        -0.052403587847948074,
        0.05592506751418114,
        -0.05969773232936859,
        -0.045965537428855896,
        0.04654170572757721,
        0.023449834436178207,
        -0.05124303698539734,
        0.0047682193107903,
        -0.030170125886797905,
        0.03154747188091278,
        -0.05141839385032654,
        -0.0558050163090229,
        0.03967645764350891,
        0.03249466419219971,
        0.04704519733786583,
        0.046703506261110306,
        -0.04035415127873421,
        -0.01283043809235096,
        0.04934978112578392,
        0.0446649044752121,
        0.045100849121809006,
        0.01267237775027752,
        -0.042704060673713684,
        -0.056515391916036606,
        -0.06334076076745987,
        0.05929437279701233,
        -0.04111304506659508,
        0.05810440704226494,
        0.032030995935201645,
        -0.015416009351611137,
        -0.04432706907391548,
        0.02911238558590412,
        0.055833280086517334,
        -0.06449057906866074,
        -0.041827209293842316,
        0.06450270116329193,
        -0.002529588295146823,
        0.034251198172569275,
        0.05142738297581673,
        -0.029569542035460472,
        0.05871497839689255,
        0.028670944273471832,
        -0.007958458736538887,
        -0.02801506593823433,
        0.04163924604654312,
        0.010817439295351505,
        0.057904116809368134,
        -0.01697980798780918,
        -0.053151268512010574,
        0.028287187218666077,
        -0.05195898935198784,
        0.04566488415002823,
        -0.026060055941343307,
        0.043099671602249146,
        0.03540395572781563,
        0.00821516290307045,
        -0.053310293704271317,
        0.056243762373924255,
        -0.05036773905158043,
        0.06016417220234871,
        -0.05928221717476845,
        0.008005061186850071,
        -0.03876602649688721,
        0.045744333416223526,
        0.058746982365846634,
        0.00681071262806654,
        0.006301214452832937,
        -0.05652442201972008,
        0.03506910055875778,
        0.059052724391222,
        -0.004353133495897055,
        0.04683252424001694,
        -0.06348656862974167,
        -0.0641881451010704,
        0.05946315824985504,
        0.048571910709142685,
        -0.056177765130996704,
        0.03119673579931259,
        -0.05970301479101181,
        -0.04479064419865608,
        -0.0398440919816494,
        -0.06203804537653923,
        0.001703681075014174,
        -0.017659146338701248,
        -0.03456330671906471,
        0.01533113420009613,
        0.05695506930351257,
        0.05339283496141434,
        0.016554370522499084,
        -0.052539922297000885,
        -0.06199094280600548,
        -0.05051405727863312,
        0.06219436600804329,
        0.04471335560083389,
        -0.061709947884082794,
        0.03748838230967522,
        0.02888660505414009,
        0.04486968740820885,
        -0.045994143933057785,
        0.055729158222675323,
        0.028362706303596497,
        -0.01740984618663788,
        -0.038589611649513245,
        -0.04649057239294052,
        0.03867706283926964,
        0.04670994356274605,
        -0.047627124935388565,
        0.0029763905331492424,
        0.061094366014003754,
        -0.012010077945888042,
        0.015791526064276695,
        -0.006024020258337259,
        0.039848510175943375,
        -0.03858286514878273,
        -0.06255372613668442,
        0.03743009269237518,
        0.03532644733786583,
        -0.026254208758473396,
        0.050333887338638306,
        0.028617380186915398,
        -0.05787482485175133,
        0.05569813773036003,
        -0.023882271721959114,
        -0.06176583096385002,
        -0.03671105578541756,
        -0.031198615208268166,
        -0.06058628484606743,
        -0.05195910111069679,
        -0.028986450284719467,
        0.0416928306221962,
        -0.005218851380050182,
        0.028055885806679726,
        0.056630074977874756,
        0.025835786014795303,
        0.05876275151968002,
        0.057208821177482605,
        -0.019474029541015625,
        0.06401955336332321,
        -0.06403179466724396,
        0.054967354983091354,
        0.025383293628692627,
        -0.05185048282146454,
        -0.017787890508770943,
        0.017070118337869644,
        0.018186209723353386,
        0.0036180242896080017,
        -0.05839910730719566,
        0.04080210253596306,
        -0.054148975759744644,
        -0.0618886798620224,
        0.06006145849823952,
        0.003006569342687726,
        0.025484532117843628,
        -0.0615902878344059,
        0.01953432522714138,
        0.05077661573886871,
        0.061617664992809296,
        -0.029152972623705864,
        0.05194538086652756,
        -0.02179734967648983,
        -0.05755620077252388,
        -0.008981961756944656,
        0.05932344123721123,
        0.028291596099734306,
        0.014873982407152653,
        -0.05338682979345322,
        -0.021936308592557907,
        0.035182781517505646,
        -0.04794945567846298,
        -0.05133585259318352,
        0.022089455276727676,
        -0.05834968760609627,
        -0.06255602091550827,
        0.019233698025345802,
        0.03495684638619423,
        0.013656050898134708,
        0.05980277061462402,
        0.03816768154501915,
        0.039165448397397995,
        -0.054032981395721436,
        -0.036905836313962936,
        -0.059153277426958084,
        0.039329301565885544,
        0.030952012166380882,
        -0.0007873917347751558,
        0.020868510007858276,
        0.04572977498173714,
        -0.003946378827095032,
        0.04244695603847504,
        0.06000875309109688,
        0.055021680891513824,
        -0.028102464973926544,
        0.01982334814965725,
        -0.012662192806601524,
        -0.03726152703166008,
        0.06003052368760109,
        -0.054213374853134155,
        0.0519259013235569,
        0.04532923176884651,
        0.019726434722542763,
        -0.06441199034452438,
        0.05533527582883835,
        0.06445151567459106
    ],
    [
        0.0066451444290578365,
        -0.021480673924088478,
        0.053842656314373016,
        0.014028143137693405,
        0.03268436715006828,
        -0.012417650781571865,
        -0.036505959928035736,
        -0.061217986047267914,
        0.03246588259935379,
        -0.0008257380686700344,
        -0.015552842058241367,
        0.017958423122763634,
        -0.04663880541920662,
        -0.05903335288167,
        -0.05976510047912598,
        0.05996590480208397,
        -0.012273420579731464,
        0.057997073978185654,
        -0.06064990907907486,
        0.012673706747591496,
        0.0620761439204216,
        0.048191070556640625,
        0.05362485721707344,
        -0.03314421325922012,
        -0.05015663802623749,
        -0.022768370807170868,
        -0.006142177619040012,
        -0.0563567578792572,
        0.031706422567367554,
        0.05559300631284714,
        0.019651982933282852,
        -0.055489521473646164,
        0.038359977304935455,
        -0.057280950248241425,
        0.05807631090283394,
        -0.0581158809363842,
        -0.06233537569642067,
        -0.03151553124189377,
        -0.05434715747833252,
        -0.01745201274752617,
        0.019474424421787262,
        0.022456377744674683,
        0.013222195208072662,
        -0.00032277361606247723,
        -0.008548105135560036,
        0.051741115748882294,
        0.05886843800544739,
        -0.009866408072412014,
        0.05968647822737694,
        0.06244760751724243,
        0.045417752116918564,
        0.06524670124053955,
        -0.044790420681238174,
        -0.02588513307273388,
        0.028555970638990402,
        0.01687624491751194,
        -0.03554905578494072,
        -0.02437448315322399,
        0.004672825802117586,
        -0.06090135872364044,
        -0.061008520424366,
        0.05012496933341026,
        -0.0444188117980957,
        0.06165732070803642,
        -0.025990387424826622,
        -0.02483852580189705,
        -0.027420083060860634,
        -0.029731648042798042,
        -0.06370773166418076,
        0.022585589438676834,
        -0.05963728576898575,
        -0.06355242431163788,
        0.05806468054652214,
        0.025109613314270973,
        0.009397130459547043,
        -0.038766104727983475,
        0.0033553990069776773,
        0.06552824378013611,
        -0.03529445081949234,
        0.005470310337841511,
        -0.017535513266921043,
        0.039778027683496475,
        -0.031339555978775024,
        0.04262814298272133,
        0.053030576556921005,
        0.04924914613366127,
        0.01598540134727955,
        -0.06275193393230438,
        0.03596588969230652,
        0.03552551940083504,
        0.04266493022441864,
        0.03778935223817825,
        -0.05918951705098152,
        -0.04649890959262848,
        -0.04826927185058594,
        0.06281476467847824,
        -0.005954028572887182,
        -0.04907230660319328,
        0.06054685637354851,
        -0.0012169997207820415,
        -0.06368225812911987,
        0.007359714712947607,
        0.0031082460191100836,
        0.03199952840805054,
        0.04042121022939682,
        -0.047525372356176376,
        0.05562954768538475,
        0.06218043342232704,
        -0.05162302404642105,
        0.0446697399020195,
        -0.0012193776201456785,
        0.05114025995135307,
        -0.058001503348350525,
        -0.01747053675353527,
        -0.060363661497831345,
        -0.0444977730512619,
        0.06353804469108582,
        0.021565552800893784,
        0.06480942666530609,
        0.05667942762374878,
        0.01958739198744297,
        0.06218741089105606,
        -0.01091510895639658,
        -0.04535316303372383,
        -0.05453403666615486,
        -0.045743007212877274,
        0.00583563232794404,
        -0.01230638101696968,
        0.056427858769893646,
        0.001054630265571177,
        -0.03267083689570427,
        -0.052525948733091354,
        -0.055815327912569046,
        0.037278760224580765,
        -0.05523644760251045,
        -0.05803957208991051,
        0.000875628727953881,
        0.05743619427084923,
        -0.06413780152797699,
        0.024628378450870514,
        0.06535203754901886,
        0.043450359255075455,
        0.05964380502700806,
        -0.04112900421023369,
        0.057791560888290405,
        -0.024712879210710526,
        0.024930531159043312,
        0.031754519790410995,
        -0.014379245229065418,
        0.058478862047195435,
        0.06266006827354431,
        -0.06461478769779205,
        -0.036027804017066956,
        -0.000255274964729324,
        0.0655660480260849,
        -0.047748368233442307,
        0.014006041921675205,
        0.04735536500811577,
        0.0500006377696991,
        0.052875541150569916,
        0.05688291788101196,
        0.02998659946024418,
        0.05729648470878601,
        0.042338840663433075,
        0.047695718705654144,
        0.04574662446975708,
        -0.038201745599508286,
        0.038308944553136826,
        -0.009652636013925076,
        0.020844990387558937,
        -0.05236530303955078,
        0.043015290051698685,
        -0.05305527150630951,
        -0.0608246736228466,
        -0.02798943966627121,
        0.061227064579725266,
        -0.06540285050868988,
        0.02913173846900463,
        0.023958101868629456,
        -0.040737565606832504,
        0.023494767025113106,
        0.06506489217281342,
        0.019665604457259178,
        0.03016195073723793,
        0.010468946769833565,
        0.041738130152225494,
        -0.030699478462338448,
        0.061373740434646606,
        -0.023718981072306633,
        -0.057684801518917084,
        -0.055946893990039825,
        0.03781990706920624,
        0.017783086746931076,
        -0.04814383387565613,
        -0.01562955416738987,
        0.03255196660757065,
        -0.053061723709106445,
        -0.012452100403606892,
        -0.04647447168827057,
        -0.049852192401885986,
        0.028808819130063057,
        -0.05609912797808647,
        -0.030494676902890205,
        0.03749127686023712,
        -0.046977508813142776,
        0.04213298112154007,
        0.017706535756587982,
        0.01799589954316616,
        -0.04692904278635979,
        0.03924782574176788,
        -0.05742070451378822,
        0.0501093789935112,
        0.057629335671663284,
        0.04761597514152527,
        -0.016351114958524704,
        0.0262640118598938,
        0.06510963290929794,
        -0.02201947569847107,
        0.06529023498296738,
        0.04970879480242729,
        -0.03893432021141052,
        0.06048133596777916,
        -0.03828616067767143,
        -0.03629881516098976,
        0.06320688128471375,
        -0.033529773354530334,
        -0.010312451049685478,
        0.009461683221161366,
        0.05009590834379196,
        -0.04744143411517143,
        0.05149566009640694,
        -0.06024390831589699,
        -0.006217238958925009,
        0.037372324615716934,
        -0.05671484023332596,
        0.06536401808261871,
        0.0598197877407074,
        -0.03994229808449745,
        0.05085922032594681,
        0.06506910920143127,
        -0.030244994908571243,
        0.04881935194134712,
        -0.0654502734541893,
        0.06117338687181473,
        -0.025022517889738083,
        -0.04861874133348465,
        -0.06175173446536064,
        -0.026474347338080406,
        -0.043348535895347595,
        -0.04719916731119156,
        -0.06031331419944763,
        -0.058279044926166534,
        -0.0005061830161139369,
        -0.06438043713569641,
        -0.05064171180129051,
        0.055189698934555054,
        -0.0388820506632328,
        -0.02743697166442871,
        0.013162040151655674,
        0.013539968058466911,
        0.06351018697023392,
        -0.009094090200960636,
        -0.02211865596473217,
        0.04544360190629959,
        0.061449356377124786,
        0.05762813612818718,
        0.025888901203870773,
        0.06406919658184052,
        -0.038858044892549515,
        0.040208570659160614,
        0.02649039588868618,
        -0.054679691791534424,
        0.05947660282254219,
        -0.02395160123705864,
        -0.04840192571282387,
        0.012867078185081482,
        -0.03928465023636818,
        0.0328512042760849,
        -0.058890413492918015,
        -0.05833989381790161,
        -0.05814073607325554,
        -0.06200309097766876,
        -0.05437341332435608,
        0.059881679713726044,
        0.033792804926633835,
        -0.04076266661286354,
        0.011269930750131607,
        0.04378902539610863,
        0.06061293184757233,
        0.03315681591629982,
        -0.0015110421227291226,
        0.0564824603497982,
        0.026359865441918373,
        0.03449588641524315,
        0.05100381374359131,
        -0.06078706681728363,
        0.05325712263584137,
        0.04253223538398743,
        0.002621868858113885,
        0.055853623896837234,
        -0.03950876742601395,
        0.02982005663216114,
        -0.02217506803572178,
        0.052821673452854156,
        -0.0617172047495842,
        0.03970934450626373,
        -0.05053465813398361,
        0.054250434041023254,
        -0.05015008896589279,
        -0.06423767656087875,
        0.01113206334412098,
        0.0027612573467195034,
        -0.038802485913038254,
        0.04221852496266365,
        0.020234689116477966,
        -0.05904842168092728,
        -0.012443813495337963,
        -0.05726662650704384,
        0.054708778858184814,
        -0.06072048470377922,
        -0.006081306375563145,
        -0.0006401228019967675,
        0.03193281590938568,
        0.036135561764240265,
        0.05652497708797455,
        -0.05223614722490311,
        -0.027129434049129486,
        -0.05735771358013153,
        -0.015450678765773773,
        0.004569916520267725,
        0.059704337269067764,
        -0.033056169748306274,
        -0.028558749705553055,
        0.03850776329636574,
        0.06206981837749481,
        0.03922368213534355,
        0.021048542112112045,
        0.05446727201342583,
        -0.0027228984981775284,
        0.019926773384213448,
        -0.03514252230525017,
        0.06297511607408524,
        -0.06473758071660995,
        0.05779951065778732,
        0.06497322767972946,
        0.02878955379128456,
        0.019082412123680115,
        0.057460710406303406,
        -0.035228144377470016,
        0.0599195621907711,
        0.06246499344706535,
        -0.05473794415593147,
        -0.01611562818288803,
        -0.0025655017234385014,
        0.009242604486644268,
        0.045047394931316376,
        -0.047698959708213806,
        -0.051285356283187866,
        0.057942308485507965,
        -0.06401150673627853,
        0.051948241889476776,
        0.006836498621851206,
        -0.013437599875032902,
        0.02528422884643078,
        0.001152046024799347,
        -0.04195326566696167,
        0.05953148752450943,
        -0.018950942903757095,
        0.05137108638882637,
        -0.058268558233976364,
        -0.00741605693474412,
        0.003686437616124749,
        0.05799463763833046,
        0.020984726026654243,
        0.039123840630054474,
        0.04401782900094986,
        -0.049285244196653366,
        0.05641031637787819,
        0.06085939705371857,
        0.027723340317606926,
        0.02273927815258503,
        -0.06078850105404854,
        -0.06393212080001831,
        0.05849534273147583,
        0.06492231786251068,
        0.00791673082858324,
        0.03172465041279793,
        -0.0624074712395668,
        -0.027626115828752518,
        0.04043004289269447,
        -0.06414879858493805,
        -0.022512635216116905,
        -0.024017348885536194,
        -0.05206194892525673,
        0.012445570901036263,
        0.06152684614062309,
        0.05311218276619911,
        0.0463823601603508,
        -0.05361050367355347,
        -0.06233711168169975,
        -0.022225607186555862,
        0.06331859529018402,
        -0.044125720858573914,
        -0.06148102506995201,
        -0.04535464197397232,
        -0.0371914878487587,
        0.023696400225162506,
        -0.05210842937231064,
        0.017155896872282028,
        0.036985088139772415,
        -0.05670832097530365,
        -0.06369740515947342,
        0.0066260118037462234,
        0.034084711223840714,
        0.05783267319202423,
        -0.05835554376244545,
        0.013250072486698627,
        0.060508500784635544,
        0.06059001013636589,
        0.03971371799707413,
        0.0013416283763945103,
        -0.0322699137032032,
        0.03820336237549782,
        -0.05404849350452423,
        0.03619539365172386,
        -0.031394440680742264,
        -0.04142419621348381,
        0.04009425640106201,
        0.033144716173410416,
        -0.06280532479286194,
        0.027766387909650803,
        -0.03995945304632187,
        -0.06228964403271675,
        -0.04662325978279114,
        -0.04030514135956764,
        -0.059463758021593094,
        -0.05948968604207039,
        0.03794192522764206,
        -0.016604794189333916,
        0.00392794469371438,
        0.02819620445370674,
        0.06361602246761322,
        0.06022778898477554,
        0.061039410531520844,
        0.04361675679683685,
        0.00020786005188710988,
        0.061404190957546234,
        -0.06532151997089386,
        0.05747934803366661,
        -0.011333934962749481,
        -0.05165580287575722,
        -0.027903025969862938,
        0.05063968896865845,
        0.04467108100652695,
        -0.057969287037849426,
        -0.0565539114177227,
        0.009922079741954803,
        -0.02847970463335514,
        -0.06204354763031006,
        0.031473368406295776,
        0.008145281113684177,
        -0.03255068138241768,
        -0.05816918611526489,
        0.023954104632139206,
        0.0314987450838089,
        0.06398504227399826,
        0.007629502099007368,
        0.061290718615055084,
        -0.004568530712276697,
        -0.06049816682934761,
        0.033480726182460785,
        0.06084628403186798,
        0.04697367548942566,
        0.06291496753692627,
        -0.061227209866046906,
        -0.019167261198163033,
        0.05309642106294632,
        0.042177408933639526,
        -0.05287192761898041,
        -0.01506385300308466,
        -0.04665609449148178,
        -0.05177275091409683,
        -0.04918605089187622,
        -0.003228350542485714,
        0.0030681071802973747,
        0.04896317422389984,
        -0.005403762683272362,
        0.049083612859249115,
        -0.05641733482480049,
        -0.009006978012621403,
        -0.015410179272294044,
        0.029736917465925217,
        0.0037001532036811113,
        0.015688328072428703,
        0.006181560456752777,
        0.011809191666543484,
        0.06528729200363159,
        0.018943730741739273,
        0.05591742694377899,
        -0.0013981304364278913,
        -0.0483342707157135,
        -0.007332418113946915,
        -0.02052684873342514,
        0.04074423760175705,
        0.03328397125005722,
        -0.055754102766513824,
        0.042864833027124405,
        0.02941816672682762,
        -0.00786552857607603,
        -0.06555577367544174,
        0.05426735430955887,
        0.0654810220003128
    ],
    [
        -0.01301172561943531,
        0.004823162220418453,
        0.01932368613779545,
        0.041266147047281265,
        0.042397577315568924,
        -0.02069014310836792,
        0.04410919174551964,
        -0.06451454013586044,
        0.0623125284910202,
        0.03998131304979324,
        0.026788251474499702,
        0.05285700410604477,
        -0.04091951251029968,
        -0.048175595700740814,
        -0.050398681312799454,
        0.062324948608875275,
        -0.020918257534503937,
        0.06094812601804733,
        -0.047875165939331055,
        -0.04302264750003815,
        0.02791925147175789,
        0.06529036909341812,
        0.04100188985466957,
        -0.029529502615332603,
        0.03851018846035004,
        0.014075048267841339,
        0.043021008372306824,
        -0.031659699976444244,
        -0.007597773335874081,
        0.004321252461522818,
        0.05479520931839943,
        -0.0009024442988447845,
        -0.048182033002376556,
        -0.06720512360334396,
        0.05189456045627594,
        0.007985871285200119,
        -0.06614098697900772,
        -0.04517604038119316,
        0.017885584384202957,
        -0.04357239976525307,
        0.031569432467222214,
        0.022107213735580444,
        0.021074756979942322,
        -0.06676143407821655,
        0.002668241737410426,
        0.06590168923139572,
        0.009518001228570938,
        0.0396353080868721,
        0.033779315650463104,
        0.036704279482364655,
        0.052943021059036255,
        0.0632990375161171,
        -0.0115866893902421,
        -0.0027943598106503487,
        0.029000163078308105,
        -0.0553896389901638,
        -0.012897158041596413,
        -0.0007694751839153469,
        0.006181566044688225,
        0.0006873488309793174,
        -0.060573332011699677,
        0.05879049003124237,
        -0.049011632800102234,
        0.06754007190465927,
        -0.05492747947573662,
        -0.04120812565088272,
        0.007194445468485355,
        -0.008109194226562977,
        -0.04829013720154762,
        -0.0485813245177269,
        -0.0642368420958519,
        -0.0703897774219513,
        0.06329574435949326,
        -0.003023779019713402,
        0.02351205423474312,
        -0.04120121896266937,
        0.036702364683151245,
        0.0707111731171608,
        -0.009946583770215511,
        0.02913246490061283,
        0.02520184963941574,
        0.06822250038385391,
        -0.016324985772371292,
        0.058288104832172394,
        0.0537651926279068,
        0.033013273030519485,
        0.06626063585281372,
        -0.04306415095925331,
        0.03681888058781624,
        0.06228669732809067,
        0.008847903460264206,
        -0.007100990973412991,
        -0.07005194574594498,
        -0.01899630017578602,
        0.01901279389858246,
        0.04286754131317139,
        0.011416345834732056,
        -0.06769707798957825,
        -0.046450357884168625,
        -0.017669152468442917,
        -0.0647125244140625,
        0.05027839168906212,
        0.06844289600849152,
        0.025835061445832253,
        0.05932335928082466,
        -0.054482556879520416,
        -0.04919707775115967,
        0.04429878294467926,
        -0.058182042092084885,
        0.03778629004955292,
        -0.05481649935245514,
        -0.03879213333129883,
        -0.0634559914469719,
        -0.02820328250527382,
        -0.018535565584897995,
        -0.024246355518698692,
        0.04670111462473869,
        -0.013396457768976688,
        0.06820676475763321,
        0.03847862407565117,
        -0.023061728104948997,
        0.06386192888021469,
        -0.01633487269282341,
        -0.013758020475506783,
        -0.061122823506593704,
        -0.012987962923943996,
        -0.048769235610961914,
        -0.004507176112383604,
        0.05814037844538689,
        0.032587774097919464,
        -0.03071601875126362,
        -0.05273590609431267,
        -0.02079816535115242,
        0.04720604047179222,
        -0.06403848528862,
        -0.06282255798578262,
        0.03129877895116806,
        0.025142522528767586,
        -0.059643328189849854,
        0.06296610087156296,
        0.06238164007663727,
        -0.02677471935749054,
        0.059888679534196854,
        0.03888659551739693,
        0.05510488897562027,
        0.008965474553406239,
        0.06457947939634323,
        -0.04390544816851616,
        0.046519409865140915,
        0.067511647939682,
        0.06015593558549881,
        -0.06865709275007248,
        -0.04440358281135559,
        -0.04527171701192856,
        0.021921908482909203,
        -0.029611000791192055,
        0.00823200587183237,
        0.003201008541509509,
        -0.035275090485811234,
        -0.005890158005058765,
        0.06941878795623779,
        0.059400346130132675,
        0.048684850335121155,
        0.05564294010400772,
        -0.00651924591511488,
        0.023569483309984207,
        -0.03770117089152336,
        0.011648698709905148,
        0.04553918167948723,
        0.042780887335538864,
        -0.01707707718014717,
        0.03786875680088997,
        -0.02368209883570671,
        -0.058145567774772644,
        -0.005916946567595005,
        0.05082918703556061,
        -0.06733623892068863,
        0.0031576640903949738,
        0.03182391822338104,
        -0.04979002848267555,
        0.024315346032381058,
        0.07029823958873749,
        -0.006722590886056423,
        0.03680624067783356,
        0.049240726977586746,
        0.003510567592456937,
        -0.05502708628773689,
        0.06473490595817566,
        -0.05538001284003258,
        -0.049964189529418945,
        -0.056221853941679,
        -0.0010338100837543607,
        0.010800166055560112,
        -0.014566805213689804,
        -0.042651113122701645,
        0.03678131848573685,
        -0.03827951103448868,
        -0.03983699530363083,
        -0.04210296645760536,
        0.0008108094334602356,
        0.05001349374651909,
        -0.034167785197496414,
        0.013399739749729633,
        -0.014288253150880337,
        -0.06899262964725494,
        0.06744207441806793,
        0.008767691440880299,
        0.011721602641046047,
        -0.048921093344688416,
        0.05381546542048454,
        -0.06087179481983185,
        0.055235136300325394,
        0.05332007259130478,
        0.04921071231365204,
        -0.05714936926960945,
        0.055798232555389404,
        0.027708830311894417,
        -0.045410532504320145,
        0.06943759322166443,
        -0.02986963465809822,
        -0.00468974094837904,
        0.06093864142894745,
        -0.05431723594665527,
        -0.06013447791337967,
        0.06940940022468567,
        -0.06065746396780014,
        -0.0052961064502596855,
        0.001502061728388071,
        -0.01657174713909626,
        -0.06106304004788399,
        0.018306324258446693,
        -0.06846702843904495,
        -0.05093248933553696,
        0.0251727607101202,
        -0.06256019324064255,
        0.07027550041675568,
        0.06007395312190056,
        -0.008129235357046127,
        -0.041617244482040405,
        0.06754688173532486,
        -0.001716200727969408,
        0.06708991527557373,
        -0.07074299454689026,
        0.04245062544941902,
        0.047959666699171066,
        0.02857026644051075,
        -0.04566929116845131,
        -0.014976090751588345,
        -0.03530455008149147,
        -0.05645972490310669,
        -0.025686590000987053,
        -0.06602044403553009,
        -0.0008371956064365804,
        -0.06861632317304611,
        -0.03627486899495125,
        0.050526686012744904,
        -0.06385914236307144,
        -0.05199425294995308,
        0.053580913692712784,
        0.01938445307314396,
        0.0696709156036377,
        -0.023248154670000076,
        -0.02990335039794445,
        0.05147428810596466,
        0.018357109278440475,
        0.038418203592300415,
        0.009508881717920303,
        0.06076613813638687,
        -0.042522720992565155,
        -0.008167044259607792,
        0.04129413142800331,
        -0.054832495748996735,
        0.06762536615133286,
        -0.056809067726135254,
        -0.04537580534815788,
        -0.012626553885638714,
        -0.04502459987998009,
        0.003519392106682062,
        -0.026071852073073387,
        -0.0005978923290967941,
        -0.061546698212623596,
        -0.00428533460944891,
        -0.0649898573756218,
        0.06453128159046173,
        0.0553484745323658,
        -0.046145401895046234,
        0.0156689640134573,
        0.05144665762782097,
        0.03827245160937309,
        0.06075243651866913,
        -0.0432111918926239,
        0.05431320518255234,
        0.006902195047587156,
        0.055954717099666595,
        0.06674746423959732,
        -0.06519795209169388,
        0.06195736303925514,
        0.053106747567653656,
        -0.042884815484285355,
        0.051134947687387466,
        0.036477070301771164,
        0.057017579674720764,
        -0.06151971220970154,
        0.06127188354730606,
        -0.02153238281607628,
        0.02403779700398445,
        -0.060988251119852066,
        0.004993895534425974,
        0.021231038495898247,
        -0.06915022432804108,
        0.018338406458497047,
        -0.06187532842159271,
        -0.02391129359602928,
        0.056278154253959656,
        -0.012543789111077785,
        -0.05907266587018967,
        0.03936227411031723,
        -0.05242367088794708,
        0.03920513764023781,
        -0.018357092514634132,
        -0.040497615933418274,
        0.029968641698360443,
        0.0461951345205307,
        0.04663235321640968,
        0.04937377944588661,
        0.011146970093250275,
        -0.035701312124729156,
        -0.0005531184142455459,
        0.009032643400132656,
        -0.031173359602689743,
        0.03562062606215477,
        0.01954580843448639,
        -0.06325025856494904,
        -0.056886736303567886,
        0.06467994302511215,
        0.04767987132072449,
        -0.030878890305757523,
        0.06340371817350388,
        -0.029166970402002335,
        -0.010689684189856052,
        0.017320698127150536,
        0.056485775858163834,
        -0.06263909488916397,
        -0.0424942784011364,
        0.05829295888543129,
        0.02963217720389366,
        0.008550870232284069,
        0.05841483548283577,
        0.009532196447253227,
        0.06785308569669724,
        0.024346711114048958,
        -0.004707767628133297,
        -0.03713417053222656,
        -0.04351445659995079,
        -0.04906522110104561,
        0.05264284834265709,
        -0.051705554127693176,
        -0.003397010499611497,
        0.019728809595108032,
        -0.06959503144025803,
        0.05855420231819153,
        0.013994617387652397,
        0.020623555406928062,
        0.022331098094582558,
        -0.04575078561902046,
        -0.053344666957855225,
        0.06755463033914566,
        -0.03636322170495987,
        0.03639308735728264,
        -0.06525544822216034,
        0.01566985622048378,
        -0.001806588494218886,
        0.05525016784667969,
        0.06484823673963547,
        0.0073471968062222,
        0.023419832810759544,
        -0.04928695037961006,
        0.037460848689079285,
        0.056624315679073334,
        0.035622864961624146,
        0.0029945361893624067,
        -0.058660946786403656,
        -0.06467220187187195,
        0.0579729899764061,
        0.05426746979355812,
        -0.00048740595229901373,
        0.01566062495112419,
        -0.06851892173290253,
        -0.024238094687461853,
        -0.02294270321726799,
        -0.06802264600992203,
        0.008903107605874538,
        0.002630191156640649,
        -0.00037949925172142684,
        -0.008812231943011284,
        0.0625753104686737,
        0.0650755986571312,
        0.03154648840427399,
        0.0015273990575224161,
        -0.06388126313686371,
        -0.009464848786592484,
        0.041131552308797836,
        0.04349856078624725,
        -0.031740207225084305,
        -0.045248325914144516,
        0.006722448393702507,
        0.03014075569808483,
        -0.04088922590017319,
        0.04080898314714432,
        0.018848948180675507,
        0.017400817945599556,
        -0.06669392436742783,
        0.05199088901281357,
        -0.0010951904114335775,
        0.04341010004281998,
        -0.061974212527275085,
        -0.02515963278710842,
        0.05944887921214104,
        0.0432640016078949,
        0.06310926377773285,
        -0.007376893423497677,
        0.009282599203288555,
        0.04188675060868263,
        -0.0559559240937233,
        0.045439302921295166,
        0.025031233206391335,
        -0.019010603427886963,
        -0.022382790222764015,
        -0.01686599850654602,
        -0.06077412888407707,
        0.06250903010368347,
        -0.009360182099044323,
        -0.06676771491765976,
        -0.026119837537407875,
        0.004427455365657806,
        -0.062064118683338165,
        -0.06432279199361801,
        -0.0434550866484642,
        0.030478881672024727,
        0.013619056902825832,
        0.03163883835077286,
        0.05662091448903084,
        0.05059244856238365,
        0.054488081485033035,
        0.05014893785119057,
        0.012810362502932549,
        0.054389588534832,
        -0.05581947788596153,
        0.06673572957515717,
        0.02442898228764534,
        -0.044727522879838943,
        0.016370682045817375,
        0.05304549261927605,
        -0.007664816919714212,
        0.013952433131635189,
        -0.04465564340353012,
        0.013123729266226292,
        -0.011232969351112843,
        -0.02653157338500023,
        0.050241753458976746,
        -0.02762048877775669,
        -0.013818253763020039,
        -0.06732447445392609,
        0.005234232172369957,
        0.059499770402908325,
        0.06932755559682846,
        -0.05802867189049721,
        0.047447703778743744,
        -0.061799824237823486,
        -0.06403153389692307,
        0.05438951775431633,
        0.06681875139474869,
        -0.001469162292778492,
        0.03875172138214111,
        -0.06334227323532104,
        -0.04367755725979805,
        0.007100131828337908,
        0.020264629274606705,
        -0.04412197321653366,
        -0.03928237035870552,
        -0.05685744807124138,
        -0.05019402131438255,
        -0.04893309250473976,
        0.03378806263208389,
        -0.006440836004912853,
        0.047860052436590195,
        0.039607372134923935,
        0.03855526074767113,
        -0.05933168902993202,
        -0.03268631920218468,
        -0.059712737798690796,
        -0.0046029482036828995,
        0.00956421997398138,
        -0.002379810204729438,
        0.04915155470371246,
        0.0029294011183083057,
        0.02468251995742321,
        0.044642429798841476,
        0.061217669397592545,
        0.06844791769981384,
        -0.024980461224913597,
        -0.044425029307603836,
        -0.010689154267311096,
        -0.021740136668086052,
        0.002247548196464777,
        -0.02939649485051632,
        0.060510046780109406,
        0.03739321231842041,
        -0.028376154601573944,
        -0.06939315050840378,
        0.047303762286901474,
        0.06795894354581833
    ],
    [
        -0.0045038978569209576,
        -0.012860752642154694,
        0.025484217330813408,
        0.04276563599705696,
        -0.010980731807649136,
        -0.01983344927430153,
        0.005073568318039179,
        -0.05869168043136597,
        0.05282319709658623,
        0.008756792172789574,
        0.020337050780653954,
        0.04527721554040909,
        -0.015927964821457863,
        -0.03583045303821564,
        -0.0615072064101696,
        0.06130797788500786,
        0.023827549070119858,
        0.05741604417562485,
        -0.057214803993701935,
        -0.016336768865585327,
        0.02033405750989914,
        0.05973095819354057,
        0.017457857728004456,
        0.005728792864829302,
        0.02507113665342331,
        0.03195741027593613,
        0.019610434770584106,
        -0.030681731179356575,
        -0.026210438460111618,
        0.04287426546216011,
        0.048507094383239746,
        -0.02146044559776783,
        -0.046434588730335236,
        -0.06531540304422379,
        0.061681754887104034,
        0.011442434042692184,
        -0.0625775009393692,
        -0.02062014304101467,
        -0.043666597455739975,
        -0.04761090129613876,
        0.056854601949453354,
        0.012942880392074585,
        0.026332328096032143,
        -0.04265375807881355,
        0.02076258696615696,
        0.06530001014471054,
        0.007581756915897131,
        -0.03151516616344452,
        0.048007987439632416,
        0.04934673011302948,
        0.06368598341941833,
        0.06579382717609406,
        -0.05941860377788544,
        0.0331546813249588,
        -0.0008472950430586934,
        -0.041205860674381256,
        -0.027945054695010185,
        -0.017969250679016113,
        -0.011699004098773003,
        -0.0450102873146534,
        -0.0410970114171505,
        0.012821925804018974,
        -0.0604906901717186,
        0.06348691135644913,
        -0.06425165385007858,
        -0.02158595249056816,
        0.03920717164874077,
        -0.009705759584903717,
        -0.05043598264455795,
        0.03536529839038849,
        -0.061620913445949554,
        -0.06693218648433685,
        0.0659434050321579,
        0.02429729327559471,
        0.0564686544239521,
        -0.03843926638364792,
        0.011179652996361256,
        0.06637877225875854,
        -0.03345907852053642,
        -0.0009150149999186397,
        0.031050844117999077,
        0.04887199401855469,
        0.029426511377096176,
        0.053950559347867966,
        0.03875032067298889,
        0.052412938326597214,
        0.06371632218360901,
        -0.033171653747558594,
        0.05703354254364967,
        0.03761470317840576,
        0.04526472091674805,
        0.04390917718410492,
        -0.06525570899248123,
        -0.0269932821393013,
        -0.027420971542596817,
        0.06340928375720978,
        -0.011181659065186977,
        -0.06064409762620926,
        0.03793426975607872,
        -0.04724700376391411,
        -0.05036424100399017,
        0.0456673726439476,
        0.050486497581005096,
        0.026442719623446465,
        0.05417771264910698,
        -0.06024830788373947,
        -0.0350368469953537,
        0.05942857638001442,
        -0.050529055297374725,
        0.012385256588459015,
        -0.06132123991847038,
        -0.042435940355062485,
        -0.06176863983273506,
        -0.005608021281659603,
        -0.04861345514655113,
        -0.048208087682724,
        0.05681818351149559,
        0.016708943992853165,
        0.06601409614086151,
        0.03934352844953537,
        -0.024501120671629906,
        0.06507354974746704,
        -0.0037794257514178753,
        -0.05068540945649147,
        -0.05301956459879875,
        -0.02798982709646225,
        -0.05901765078306198,
        -0.05335525423288345,
        0.06026800721883774,
        0.010198689065873623,
        0.01952982135117054,
        -0.04968739300966263,
        -0.06159384921193123,
        0.05194913223385811,
        -0.05782640352845192,
        -0.06327026337385178,
        0.02910217083990574,
        0.04903049021959305,
        -0.06518899649381638,
        0.035982485860586166,
        0.06606391072273254,
        -0.04007692635059357,
        0.06247938424348831,
        0.008690313436090946,
        0.04874463379383087,
        -0.024826258420944214,
        0.053669728338718414,
        -0.0487867072224617,
        -0.013637285679578781,
        0.06471645087003708,
        0.06187070161104202,
        -0.06571147590875626,
        -0.008099064230918884,
        -0.03814387321472168,
        0.051794830709695816,
        -0.051715899258852005,
        -0.008436276577413082,
        -0.003643919015303254,
        0.012232632376253605,
        0.008151647634804249,
        0.042052507400512695,
        0.05680610612034798,
        0.03413692116737366,
        0.04580766707658768,
        0.02979537472128868,
        -0.016903575509786606,
        -0.013115624897181988,
        0.04751351475715637,
        0.007024388760328293,
        0.020168479532003403,
        -0.04137609899044037,
        0.04122292995452881,
        -0.037916943430900574,
        -0.04997972771525383,
        0.04831419140100479,
        0.04506829380989075,
        -0.06701682507991791,
        0.03427685797214508,
        0.05114137753844261,
        -0.050733666867017746,
        0.008845644071698189,
        0.06610710173845291,
        0.02282482199370861,
        0.0555957593023777,
        0.03700127452611923,
        0.029224006459116936,
        -0.058005694299936295,
        0.05642404034733772,
        -0.05612226203083992,
        -0.052233390510082245,
        -0.05899686738848686,
        0.029664326459169388,
        0.00043674567132256925,
        0.03344876691699028,
        -0.012878483161330223,
        0.012025687843561172,
        -0.046301066875457764,
        -0.017668912187218666,
        -0.021462703123688698,
        0.00464016804471612,
        0.014187474735081196,
        -0.03652237728238106,
        -0.019955679774284363,
        -0.001221710816025734,
        -0.05439076945185661,
        0.05433155596256256,
        0.03252842277288437,
        0.06136355921626091,
        -0.06347699463367462,
        0.01107125822454691,
        -0.05935799703001976,
        0.06399432569742203,
        0.0095489751547575,
        0.0306551530957222,
        -0.029648344963788986,
        0.03438219800591469,
        0.0652758777141571,
        0.000417381408624351,
        0.06665293127298355,
        0.017696894705295563,
        0.027480466291308403,
        0.0662187933921814,
        -0.057263925671577454,
        -0.05400976166129112,
        0.06122657284140587,
        -0.0528145469725132,
        -0.03888615593314171,
        0.029076779261231422,
        -0.03383184224367142,
        -0.05273418873548508,
        0.034672338515520096,
        -0.06475009769201279,
        -0.02087843418121338,
        0.019294945523142815,
        -0.05154553800821304,
        0.06699810922145844,
        0.06424844264984131,
        0.008617287501692772,
        -0.03882373496890068,
        0.06209363043308258,
        -0.03830232098698616,
        0.06537339836359024,
        -0.06703314930200577,
        0.04612761735916138,
        0.02748859114944935,
        -0.03760382905602455,
        -0.05705513060092926,
        0.015730028972029686,
        -0.014209565706551075,
        -0.0489659458398819,
        -0.06182404235005379,
        -0.05865392088890076,
        0.04864555969834328,
        -0.060768477618694305,
        -0.045063916593790054,
        0.06126731261610985,
        -0.051433999091386795,
        -0.04465041682124138,
        0.03202041983604431,
        -0.0012100584572181106,
        0.0643238052725792,
        -0.057544466108083725,
        -0.024490928277373314,
        0.03213174641132355,
        -0.0022589180152863264,
        0.03388878330588341,
        0.030291330069303513,
        0.04840673878788948,
        -0.028689390048384666,
        0.013879316858947277,
        0.03984752297401428,
        -0.057673390954732895,
        0.06680813431739807,
        -0.056178074330091476,
        -0.06177310645580292,
        0.04513780027627945,
        -0.049477484077215195,
        0.014238324947655201,
        -0.023627350106835365,
        -0.027216913178563118,
        -0.06005966663360596,
        -0.0565345324575901,
        -0.06634001433849335,
        0.05372098088264465,
        0.05170007422566414,
        -0.0530741810798645,
        0.01868334412574768,
        0.022101884707808495,
        0.0505780391395092,
        0.03321168199181557,
        0.018040364608168602,
        0.05438534915447235,
        -0.003920825198292732,
        0.05681759491562843,
        0.06431060284376144,
        -0.06461957097053528,
        0.059982623904943466,
        0.06258688867092133,
        -0.04621737077832222,
        0.037824224680662155,
        0.055721502751111984,
        0.04233826324343681,
        -0.05329056456685066,
        0.05263237655162811,
        -0.019940590485930443,
        0.05379117652773857,
        -0.03311462700366974,
        0.03960651904344559,
        -0.04609065502882004,
        -0.06669332087039948,
        0.045010849833488464,
        0.002260893816128373,
        0.013561583124101162,
        0.05309853330254555,
        -0.04133434221148491,
        -0.0577983595430851,
        0.013142679817974567,
        -0.048640500754117966,
        0.03130428493022919,
        -0.027301615104079247,
        -0.05295191705226898,
        0.04129742085933685,
        0.03066547028720379,
        0.061671141535043716,
        0.03510522469878197,
        -0.04748789593577385,
        0.0037800034042447805,
        0.0269341878592968,
        0.030399039387702942,
        -0.05155370756983757,
        0.008799507282674313,
        -0.04068164527416229,
        -0.05378059297800064,
        -0.05467367544770241,
        0.04565373808145523,
        0.038639940321445465,
        0.012849591672420502,
        0.04687872529029846,
        -0.021931109949946404,
        0.04283154010772705,
        -0.0018132338300347328,
        0.06512031704187393,
        -0.0661153793334961,
        -0.049178916960954666,
        0.06654302775859833,
        0.05236293002963066,
        -0.003775698132812977,
        0.06380613893270493,
        0.003007390769198537,
        0.06614188849925995,
        0.06097092106938362,
        0.0029356987215578556,
        -0.041612692177295685,
        -0.022169923409819603,
        -0.014288540929555893,
        0.033636998385190964,
        -0.06271293014287949,
        -0.051203127950429916,
        0.054984815418720245,
        -0.06237129494547844,
        0.05165882036089897,
        -0.00451353844255209,
        -0.023224856704473495,
        -0.010236944071948528,
        0.023982971906661987,
        -0.052005089819431305,
        0.058629803359508514,
        -0.04745180159807205,
        0.0057258615270257,
        -0.056797534227371216,
        -0.005048126447945833,
        0.01417116355150938,
        0.04373221844434738,
        0.0561392717063427,
        0.04069226607680321,
        0.04026547819375992,
        -0.061912164092063904,
        0.050251275300979614,
        0.06531153619289398,
        0.05113840848207474,
        0.011920626275241375,
        -0.06587011367082596,
        -0.06398777663707733,
        0.06214587762951851,
        0.05908408761024475,
        -0.04211905226111412,
        0.00825534202158451,
        -0.05861775949597359,
        -0.03917945548892021,
        -0.04720661789178848,
        -0.06536443531513214,
        -0.054515838623046875,
        -0.0072586205787956715,
        -0.03817306458950043,
        -0.024966834113001823,
        0.06230345368385315,
        0.06494181603193283,
        0.033336374908685684,
        -0.02875347062945366,
        -0.061247728765010834,
        -0.008341845124959946,
        0.06419606506824493,
        0.02854160964488983,
        -0.01494714617729187,
        -0.017313430085778236,
        0.006165378727018833,
        0.0442240908741951,
        -0.04472794011235237,
        -0.007940261624753475,
        -0.009134529158473015,
        -0.00861191563308239,
        -0.0646883174777031,
        0.05958115682005882,
        0.03903507813811302,
        0.030707964673638344,
        -0.05941031500697136,
        -0.0382939875125885,
        0.051888201385736465,
        0.008695616386830807,
        0.06026513874530792,
        0.034363213926553726,
        0.018582139164209366,
        -0.020120177417993546,
        -0.06139443814754486,
        0.0291802529245615,
        -0.012440638616681099,
        -0.01294354535639286,
        -0.01875525712966919,
        -0.013261081650853157,
        -0.06190669164061546,
        0.048439111560583115,
        -0.003028396051377058,
        -0.0650891587138176,
        -0.06042620912194252,
        0.012572851963341236,
        -0.057620082050561905,
        -0.0656888335943222,
        -0.018348798155784607,
        -0.01579667627811432,
        0.05006936192512512,
        0.046982280910015106,
        0.06205768138170242,
        0.041967928409576416,
        0.03136188164353371,
        0.06348037719726562,
        0.019316574558615685,
        0.057687561959028244,
        -0.06166277080774307,
        0.05673426017165184,
        0.026483070105314255,
        -0.057921361178159714,
        0.007217636331915855,
        0.01189063023775816,
        0.029502255842089653,
        -0.013059306889772415,
        -0.05492166429758072,
        -0.014213668182492256,
        -0.02800285443663597,
        -0.05961349979043007,
        0.05349038913846016,
        -0.03290049731731415,
        0.011442040093243122,
        -0.0658741444349289,
        0.053417470306158066,
        0.0435527041554451,
        0.0660560205578804,
        0.011721896938979626,
        0.05528078228235245,
        -0.05653489753603935,
        -0.047117069363594055,
        0.06148642674088478,
        0.06379295140504837,
        0.04255824536085129,
        0.018828267231583595,
        -0.04707590118050575,
        0.00617370568215847,
        0.06224029138684273,
        0.05689512938261032,
        -0.04064807668328285,
        -0.031594060361385345,
        -0.049301330000162125,
        -0.057333704084157944,
        -0.05010072514414787,
        -0.02117118053138256,
        0.024007366970181465,
        0.0549418143928051,
        0.045523978769779205,
        0.029282301664352417,
        -0.06054074317216873,
        -0.04042615368962288,
        -0.06014473736286163,
        -0.0035363829229027033,
        -0.008431368507444859,
        0.006656103301793337,
        0.005494444631040096,
        0.029230104759335518,
        0.009466944262385368,
        0.04546106606721878,
        0.06274322420358658,
        0.06096571683883667,
        -0.044283799827098846,
        0.0023920689709484577,
        0.019272103905677795,
        0.009989241138100624,
        0.036660268902778625,
        -0.061165034770965576,
        0.040070414543151855,
        0.04714713245630264,
        -0.02215707302093506,
        -0.0671139657497406,
        0.03294927254319191,
        0.06462132185697556
    ],
    [
        -0.0028789318166673183,
        0.0003614970191847533,
        0.05177450180053711,
        0.03490449860692024,
        0.020673152059316635,
        -0.03707042708992958,
        -0.005640326999127865,
        -0.06114574894309044,
        0.05309532582759857,
        0.017393285408616066,
        -0.024784602224826813,
        0.03597097471356392,
        -0.03232083097100258,
        -0.029856739565730095,
        -0.06269114464521408,
        0.04920174181461334,
        0.012542758136987686,
        0.06351640075445175,
        -0.0581236369907856,
        -0.04263830557465553,
        0.04091615602374077,
        0.060398176312446594,
        0.027511661872267723,
        -0.027261916548013687,
        -0.0030467379838228226,
        0.018846839666366577,
        0.018594086170196533,
        -0.03302667662501335,
        -0.029688198119401932,
        0.018668469041585922,
        0.044668395072221756,
        0.0036720780190080404,
        -0.0362900085747242,
        -0.054297320544719696,
        0.023006174713373184,
        0.05495643988251686,
        -0.06427845358848572,
        -0.052537575364112854,
        0.014736202545464039,
        -0.0513882078230381,
        0.05201156437397003,
        0.0000015743008816571091,
        0.03865855932235718,
        -0.05259393900632858,
        0.010013988241553307,
        0.05396575108170509,
        -0.0010924333473667502,
        -0.014253940433263779,
        0.05762290954589844,
        -0.0007025109953247011,
        0.056734323501586914,
        0.06470632553100586,
        -0.04023667052388191,
        0.0338611975312233,
        0.0007951614097692072,
        -0.03906331956386566,
        0.009047234430909157,
        0.026642825454473495,
        0.04458212852478027,
        -0.023536138236522675,
        -0.06141258403658867,
        0.05871789529919624,
        -0.011277323588728905,
        0.056056637316942215,
        -0.061820246279239655,
        -0.049122922122478485,
        -0.01927538774907589,
        0.013824556954205036,
        -0.01769169792532921,
        0.017387520521879196,
        -0.0587727427482605,
        -0.06541670858860016,
        0.061660200357437134,
        0.06401851773262024,
        0.03607741370797157,
        -0.04440126195549965,
        0.04405198246240616,
        0.06542546302080154,
        0.009841389954090118,
        0.0363817997276783,
        -0.00951521284878254,
        0.04100309684872627,
        -0.05010632053017616,
        0.0458211787045002,
        0.03288688883185387,
        -0.011698725633323193,
        0.049693889915943146,
        -0.055848732590675354,
        0.03299383446574211,
        0.026384707540273666,
        0.04841108247637749,
        0.04521263390779495,
        -0.04903437942266464,
        -0.027318337932229042,
        0.032349586486816406,
        0.0586651936173439,
        -0.053862541913986206,
        -0.05088147893548012,
        0.006564236246049404,
        -0.0277980025857687,
        -0.06240006536245346,
        0.005971865262836218,
        0.026044074445962906,
        0.02157765068113804,
        0.051055945456027985,
        -0.06160932779312134,
        -0.03102772869169712,
        0.03425262123346329,
        -0.05710884556174278,
        0.0004678585974033922,
        -0.056234486401081085,
        -0.04023073613643646,
        -0.06238912418484688,
        -0.03988030180335045,
        -0.05384240671992302,
        -0.041576843708753586,
        0.06323859840631485,
        -0.03500080481171608,
        0.06027250364422798,
        0.04252894967794418,
        -0.04182853922247887,
        0.06235530599951744,
        -0.011734274215996265,
        -0.024686584249138832,
        -0.06242770701646805,
        -0.0450923927128315,
        -0.06259141117334366,
        -0.039268478751182556,
        0.060089111328125,
        -0.04509354010224342,
        -0.006893265526741743,
        -0.045627839863300323,
        -0.045166876167058945,
        0.01566789112985134,
        -0.06078007072210312,
        -0.06067696213722229,
        0.030949791893363,
        0.04482797905802727,
        -0.0646033063530922,
        0.05418306216597557,
        0.06452994793653488,
        -0.00028638882213272154,
        0.06279150396585464,
        0.004094538278877735,
        0.06189005449414253,
        0.021498722955584526,
        0.0616227351129055,
        -0.03094838187098503,
        -0.01552604604512453,
        0.05287821590900421,
        0.060916636139154434,
        -0.06403949856758118,
        -0.01891375705599785,
        -0.05606384202837944,
        0.06265274435281754,
        -0.0575600229203701,
        -0.006340427789837122,
        -0.01675906963646412,
        -0.004461226519197226,
        0.0011089615290984511,
        0.04784812778234482,
        0.047989413142204285,
        0.04396490007638931,
        0.0351303331553936,
        0.050936538726091385,
        -0.05907747521996498,
        -0.04286575689911842,
        0.041041210293769836,
        0.04841770604252815,
        0.003615713445469737,
        -0.02708573266863823,
        0.0553157702088356,
        -0.058236900717020035,
        -0.0545467734336853,
        0.0087331123650074,
        0.06072592735290527,
        -0.0653746947646141,
        -0.016453906893730164,
        0.054691191762685776,
        -0.022941630333662033,
        0.02379719540476799,
        0.06392591446638107,
        0.042304977774620056,
        0.02935437671840191,
        0.03576914966106415,
        0.040308877825737,
        -0.05189472809433937,
        0.06101115792989731,
        -0.053384482860565186,
        -0.04650506377220154,
        -0.05569065734744072,
        -0.02836073935031891,
        -0.00018675463797990233,
        -0.014313937164843082,
        -0.026536880061030388,
        0.03022477962076664,
        -0.015395525842905045,
        0.015515674836933613,
        -0.011528384871780872,
        0.039268869906663895,
        0.04856223985552788,
        -0.04929380491375923,
        -0.006040820386260748,
        -0.03240976110100746,
        -0.05395950376987457,
        0.04943223297595978,
        0.053335078060626984,
        0.058156080543994904,
        -0.06164737418293953,
        0.051635731011629105,
        -0.06345680356025696,
        0.028298629447817802,
        -0.013224775902926922,
        0.02047729678452015,
        -0.03034079074859619,
        0.001146171591244638,
        0.06298413127660751,
        -0.011879856698215008,
        0.06306396424770355,
        0.03615889698266983,
        -0.02309032715857029,
        0.05033564195036888,
        -0.04416096955537796,
        -0.058393146842718124,
        0.05613936483860016,
        -0.012094882316887379,
        0.058009713888168335,
        -0.010082295164465904,
        -0.05534275621175766,
        -0.04678257182240486,
        -0.001242503640241921,
        -0.04018690809607506,
        -0.036270204931497574,
        -0.033539511263370514,
        0.022127103060483932,
        0.06523005664348602,
        0.06256360560655594,
        -0.05809321254491806,
        -0.03891971707344055,
        0.054831817746162415,
        -0.01233324408531189,
        0.06448144465684891,
        -0.06439061462879181,
        0.02080111764371395,
        0.04634683206677437,
        -0.037136510014534,
        -0.058063752949237823,
        0.010438410565257072,
        -0.032020993530750275,
        -0.04657188057899475,
        -0.06230444833636284,
        -0.06224190071225166,
        0.03728650137782097,
        -0.06225106120109558,
        -0.04667234048247337,
        0.05663890764117241,
        -0.04769761115312576,
        -0.055617086589336395,
        0.03379078209400177,
        0.031047409400343895,
        0.05997854843735695,
        -0.05405028164386749,
        -0.05038079991936684,
        -0.020749583840370178,
        -0.00008261218317784369,
        0.04655423015356064,
        -0.020136363804340363,
        0.01888136938214302,
        -0.029926571995019913,
        0.040588393807411194,
        0.011212286539375782,
        -0.034383825957775116,
        0.06465156376361847,
        -0.06201822683215141,
        -0.06081143766641617,
        -0.0086434381082654,
        0.014634991995990276,
        -0.01866483874619007,
        0.0023096732329577208,
        -0.03579236939549446,
        -0.06380049884319305,
        -0.0528615303337574,
        -0.06523650884628296,
        0.06088044494390488,
        0.02594657428562641,
        -0.03226574510335922,
        -0.012284895405173302,
        0.04615914076566696,
        0.03322691097855568,
        0.05515570938587189,
        -0.03096216544508934,
        0.056390274316072464,
        0.031107937917113304,
        0.061775222420692444,
        0.06305348873138428,
        -0.06172527000308037,
        0.05305202677845955,
        0.05965633690357208,
        -0.055781666189432144,
        0.048106320202350616,
        0.05230053514242172,
        0.06112320348620415,
        -0.011574876494705677,
        0.05527349188923836,
        -0.05550895631313324,
        0.036884695291519165,
        -0.049733810126781464,
        0.04956122115254402,
        -0.02389238029718399,
        -0.06259579211473465,
        0.03575684875249863,
        0.016833895817399025,
        -0.04912889003753662,
        0.05801903456449509,
        -0.029235780239105225,
        -0.05961078032851219,
        0.011726331897079945,
        -0.045076556503772736,
        -0.0021739869844168425,
        0.0189608596265316,
        -0.03970327973365784,
        -0.026159130036830902,
        -0.010533935390412807,
        0.061403777450323105,
        0.061426568776369095,
        -0.047362662851810455,
        -0.014419838786125183,
        0.03701402246952057,
        -0.014891923405230045,
        -0.04689376801252365,
        -0.029118359088897705,
        -0.04617997631430626,
        -0.0458439439535141,
        -0.05363072082400322,
        0.057644132524728775,
        0.03655458241701126,
        -0.043521031737327576,
        0.061395950615406036,
        0.019841965287923813,
        0.050762247294187546,
        0.02894972823560238,
        0.06355180591344833,
        -0.06403537094593048,
        -0.03213489428162575,
        0.0649373009800911,
        0.03135324642062187,
        -0.020238256081938744,
        0.05350439250469208,
        -0.015362412668764591,
        0.060496482998132706,
        0.026888897642493248,
        -0.009732464328408241,
        -0.03273605555295944,
        0.014364795759320259,
        -0.021236775442957878,
        0.05505087226629257,
        -0.051229871809482574,
        -0.03305666521191597,
        0.056194569915533066,
        -0.05696887522935867,
        0.061150114983320236,
        0.02796189673244953,
        -0.0362013541162014,
        0.009083730168640614,
        -0.0008455532952211797,
        -0.05331213027238846,
        0.037738945335149765,
        -0.047295648604631424,
        0.04482685774564743,
        -0.03728621080517769,
        -0.04389352723956108,
        -0.050867851823568344,
        0.026301588863134384,
        0.05260360613465309,
        0.04436410591006279,
        0.03291705250740051,
        -0.05496889352798462,
        0.05468498542904854,
        0.05363089218735695,
        0.05518254637718201,
        0.015459761954843998,
        -0.05449303612112999,
        -0.031232871115207672,
        0.06134327873587608,
        0.0634467676281929,
        -0.021830102428793907,
        -0.016032200306653976,
        -0.05889072269201279,
        -0.050702113658189774,
        -0.056298933923244476,
        -0.050447043031454086,
        -0.029538610950112343,
        -0.05488891154527664,
        -0.019548561424016953,
        -0.051337830722332,
        0.06194894015789032,
        0.04412306845188141,
        0.05425044894218445,
        0.05060122162103653,
        -0.06383536756038666,
        -0.05174409598112106,
        0.05845782533288002,
        0.030413441359996796,
        -0.026213251054286957,
        -0.029749011620879173,
        -0.028586495667696,
        0.053901683539152145,
        -0.05583310127258301,
        0.05402710288763046,
        -0.015203318558633327,
        -0.019500834867358208,
        -0.06229301914572716,
        0.058110322803258896,
        0.013755238614976406,
        0.04611101746559143,
        -0.057964131236076355,
        -0.05472499132156372,
        0.06103431060910225,
        0.02684076689183712,
        0.06021620333194733,
        0.022408749908208847,
        -0.04070824012160301,
        -0.05304684489965439,
        -0.045882828533649445,
        -0.016048576682806015,
        -0.05242138355970383,
        0.04777420684695244,
        -0.024157263338565826,
        0.009107141755521297,
        -0.059698667377233505,
        0.05735061317682266,
        -0.04654945060610771,
        -0.05727752670645714,
        -0.035963039845228195,
        0.04659945145249367,
        -0.058702874928712845,
        -0.0645899847149849,
        -0.024933679029345512,
        0.007617617025971413,
        0.02632884308695793,
        0.050528623163700104,
        0.053784579038619995,
        0.040679801255464554,
        0.03800085932016373,
        0.050434667617082596,
        0.03189368173480034,
        0.053267884999513626,
        -0.06152989715337753,
        0.06151410937309265,
        0.04642004892230034,
        -0.06183481588959694,
        -0.058820080012083054,
        0.05332767590880394,
        -0.04267045855522156,
        -0.03728195279836655,
        -0.05668056011199951,
        -0.04949522018432617,
        0.00888499990105629,
        -0.0564320906996727,
        0.04969304800033569,
        -0.0018682706868276,
        -0.04188059642910957,
        -0.0634549930691719,
        0.03514984995126724,
        -0.0038618287071585655,
        0.0648176372051239,
        -0.0339319221675396,
        0.061182647943496704,
        -0.03775262087583542,
        -0.05788499861955643,
        0.04742175340652466,
        0.0635712668299675,
        0.038066551089286804,
        0.055575113743543625,
        -0.004529389552772045,
        -0.022267727181315422,
        0.059702467173337936,
        0.04846511408686638,
        0.005962805822491646,
        -0.05912764370441437,
        -0.05993112176656723,
        -0.03990129381418228,
        -0.035519883036613464,
        -0.031821586191654205,
        -0.013835291378200054,
        0.03419731184840202,
        0.04472428560256958,
        0.025260891765356064,
        -0.05886335298418999,
        -0.0038919078651815653,
        -0.05916048586368561,
        -0.002970699453726411,
        -0.02890867181122303,
        0.03708333149552345,
        -0.03262845799326897,
        0.05755741894245148,
        -0.025085126981139183,
        0.04826769232749939,
        0.06234363466501236,
        0.05206567421555519,
        -0.05350566655397415,
        0.003465818939730525,
        0.04667702689766884,
        0.016223175451159477,
        0.037889204919338226,
        -0.017446285113692284,
        0.049620263278484344,
        -0.005487958434969187,
        -0.027926167473196983,
        -0.0653761774301529,
        0.05524270981550217,
        0.06393873691558838
    ],
    [
        0.03970170021057129,
        0.0482572577893734,
        0.04562936723232269,
        0.02933267131447792,
        -0.008405664004385471,
        -0.051923543214797974,
        -0.0331999696791172,
        -0.06099418178200722,
        0.04540830850601196,
        0.03912712261080742,
        0.021256281062960625,
        0.05401269719004631,
        -0.04993020370602608,
        -0.014818885363638401,
        -0.057435180991888046,
        0.05304752290248871,
        -0.007617149967700243,
        0.04694373905658722,
        -0.03135494887828827,
        -0.05617128685116768,
        0.056981541216373444,
        0.06287349760532379,
        0.052778612822294235,
        -0.016475103795528412,
        -0.03713816776871681,
        0.05674110725522041,
        0.007721281610429287,
        -0.05453142896294594,
        0.020654670894145966,
        -0.015946118161082268,
        0.05228326469659805,
        -0.05344950407743454,
        -0.037410374730825424,
        -0.061321549117565155,
        0.05105660855770111,
        -0.046433210372924805,
        -0.060811083763837814,
        -0.016882117837667465,
        0.049458038061857224,
        -0.0572788305580616,
        0.03945446014404297,
        -0.01671237125992775,
        0.042088232934474945,
        -0.059306416660547256,
        -0.0006382634164765477,
        0.059610698372125626,
        0.01704900525510311,
        0.0586232915520668,
        0.05748194083571434,
        0.05359102040529251,
        0.05605550482869148,
        0.062357306480407715,
        0.0240886639803648,
        -0.01355263777077198,
        0.03659937530755997,
        -0.034158285707235336,
        -0.020659852772951126,
        0.014965022914111614,
        -0.04383206367492676,
        -0.058911923319101334,
        -0.06049388274550438,
        0.024350984022021294,
        -0.05539283528923988,
        0.06052356958389282,
        -0.02682308666408062,
        -0.03937899321317673,
        -0.009182450361549854,
        -0.0466095469892025,
        -0.05225629359483719,
        0.017476581037044525,
        -0.02457711659371853,
        -0.06287817656993866,
        0.06044912710785866,
        -0.004194191657006741,
        0.041489068418741226,
        -0.052923209965229034,
        0.051954347640275955,
        0.06293809413909912,
        -0.033462800085544586,
        0.00019570995937101543,
        0.013791288249194622,
        0.060827840119600296,
        -0.02981099858880043,
        0.04684590548276901,
        0.05724824592471123,
        0.057906050235033035,
        0.012333041056990623,
        -0.05738939717411995,
        -0.027428435161709785,
        0.0566188208758831,
        -0.013109548948705196,
        0.03379083052277565,
        -0.06259491294622421,
        0.0044355071149766445,
        -0.046109870076179504,
        0.06163489446043968,
        0.030106503516435623,
        -0.05817090719938278,
        0.012685945257544518,
        -0.04132755100727081,
        -0.061681028455495834,
        0.058696448802948,
        0.019135141745209694,
        0.04869861528277397,
        -0.00466131791472435,
        -0.04460434243083,
        0.05190916731953621,
        0.052558548748493195,
        0.007945148274302483,
        0.05597240850329399,
        -0.04664386436343193,
        -0.05358400568366051,
        -0.0590050183236599,
        -0.02109883725643158,
        -0.044115711003541946,
        0.028927288949489594,
        0.027541587129235268,
        -0.036387935280799866,
        0.06227128580212593,
        0.034761667251586914,
        -0.03263786807656288,
        0.06006021052598953,
        -0.0028110456187278032,
        -0.00370908435434103,
        -0.053284451365470886,
        -0.057616494596004486,
        -0.061629001051187515,
        -0.0567324124276638,
        0.060349322855472565,
        0.03286133334040642,
        -0.03624984994530678,
        -0.052689239382743835,
        0.01452482771128416,
        0.05866732820868492,
        -0.0562196746468544,
        -0.06081697717308998,
        0.05596892908215523,
        0.06103944033384323,
        -0.060745853930711746,
        0.03384235128760338,
        0.055365532636642456,
        -0.014637653715908527,
        0.05876113474369049,
        -0.04513029381632805,
        0.05577478185296059,
        0.0420440249145031,
        0.056948672980070114,
        0.045826856046915054,
        -0.004869290627539158,
        0.05934186652302742,
        0.0627337321639061,
        -0.06196676939725876,
        -0.04536619782447815,
        -0.05767970532178879,
        0.04578850045800209,
        -0.060281429439783096,
        -0.0095460070297122,
        -0.027057765051722527,
        0.022403286769986153,
        -0.0033240984193980694,
        0.05258757621049881,
        0.044626493006944656,
        0.056550558656454086,
        0.05314173176884651,
        0.049164555966854095,
        0.008060079999268055,
        -0.04279688745737076,
        0.022872194647789,
        0.03152963146567345,
        0.04245956242084503,
        -0.012897987850010395,
        0.03391417860984802,
        -0.05030389130115509,
        -0.06130148842930794,
        0.02162778377532959,
        0.056447092443704605,
        -0.056584931910037994,
        0.024204717949032784,
        0.04983435571193695,
        -0.014826406724750996,
        0.0013602800900116563,
        0.06304913014173508,
        0.03834635019302368,
        0.009099653922021389,
        0.050307441502809525,
        0.04521198198199272,
        -0.05817754566669464,
        0.05950397998094559,
        -0.023084018379449844,
        -0.0041658030822873116,
        -0.034807588905096054,
        0.018033798784017563,
        0.015710610896348953,
        -0.016201000660657883,
        -0.04913126677274704,
        -0.0267961323261261,
        -0.05082160234451294,
        -0.03051183745265007,
        -0.04375505819916725,
        -0.015697794035077095,
        0.05260010436177254,
        -0.0127668846398592,
        0.03800557181239128,
        -0.012629437260329723,
        -0.03304067626595497,
        -0.008589540608227253,
        0.004799950867891312,
        0.05794665217399597,
        -0.05448786914348602,
        0.06056931987404823,
        -0.0578075647354126,
        0.05700920149683952,
        -0.021848969161510468,
        -0.0064069717191159725,
        -0.019925789907574654,
        0.042858049273490906,
        0.023892443627119064,
        0.0188224408775568,
        0.059480905532836914,
        0.03526162728667259,
        0.03567853569984436,
        0.060673411935567856,
        -0.04847836494445801,
        -0.05187898129224777,
        0.060373615473508835,
        -0.048830412328243256,
        -0.018983548507094383,
        0.002890839008614421,
        0.031032085418701172,
        -0.05691969767212868,
        0.010970651172101498,
        -0.05113686993718147,
        0.023720424622297287,
        0.03543487563729286,
        -0.04214724898338318,
        0.06262500584125519,
        0.05758027359843254,
        0.028643112629652023,
        -0.019903142005205154,
        0.0626426413655281,
        -0.040477070957422256,
        0.062109727412462234,
        -0.06294017285108566,
        0.062450431287288666,
        0.035426344722509384,
        0.0038465557154268026,
        -0.05794151872396469,
        0.03803357109427452,
        -0.03160281851887703,
        -0.043451644480228424,
        -0.057443201541900635,
        -0.06076449900865555,
        0.04469304159283638,
        -0.06014478579163551,
        -0.05607324466109276,
        0.03658134490251541,
        -0.056420497596263885,
        -0.06102912127971649,
        0.0466240756213665,
        0.04282236099243164,
        0.06173543259501457,
        -0.0031339789275079966,
        -0.05028959736227989,
        0.009650216437876225,
        0.05340220779180527,
        0.05696185678243637,
        -0.004725195001810789,
        0.04828086122870445,
        -0.05764910578727722,
        -0.012215107679367065,
        0.043196238577365875,
        -0.0554017536342144,
        0.06003711745142937,
        -0.0443849042057991,
        -0.04898655787110329,
        0.047007109969854355,
        -0.04560943692922592,
        -0.0358462892472744,
        -0.014159919694066048,
        -0.010630441829562187,
        -0.059454936534166336,
        -0.02220676839351654,
        -0.060488369315862656,
        0.02924199216067791,
        0.0015340697718784213,
        -0.04118160158395767,
        0.04109187424182892,
        0.05947733670473099,
        0.03985504060983658,
        0.05617770552635193,
        -0.052327610552310944,
        0.003643249860033393,
        -0.01306398119777441,
        0.04426201805472374,
        0.06197766959667206,
        -0.058865245431661606,
        0.05630771443247795,
        0.034189675003290176,
        -0.05016852915287018,
        0.05930357798933983,
        0.008586396463215351,
        0.056963641196489334,
        -0.04495210573077202,
        0.04514319822192192,
        -0.034884314984083176,
        -0.0030775340273976326,
        -0.02733851782977581,
        0.050191935151815414,
        0.02723020315170288,
        -0.05868634954094887,
        0.04130588844418526,
        -0.022257491946220398,
        -0.03261104226112366,
        0.05011477321386337,
        0.019330089911818504,
        -0.05944007635116577,
        -0.009743939153850079,
        -0.046944573521614075,
        0.0472114272415638,
        -0.031318336725234985,
        -0.055772364139556885,
        0.015141102485358715,
        0.017993634566664696,
        0.05642477422952652,
        0.058483075350522995,
        -0.06086927279829979,
        -0.03669076785445213,
        -0.03551457077264786,
        -0.006202097050845623,
        -0.02894238568842411,
        0.0034986825194209814,
        -0.05934835970401764,
        -0.04247693717479706,
        -0.04891550913453102,
        0.06167145445942879,
        0.049108151346445084,
        -0.05301117151975632,
        0.05393685773015022,
        -0.05031121149659157,
        0.0025496322195976973,
        -0.049822598695755005,
        0.061263952404260635,
        -0.040036916732788086,
        -0.042068928480148315,
        0.03229141607880592,
        0.034431975334882736,
        0.05231453478336334,
        0.05533568933606148,
        0.011734729632735252,
        0.058739691972732544,
        0.05934279039502144,
        -0.04597216099500656,
        -0.007395619060844183,
        -0.03211541101336479,
        -0.0418739952147007,
        0.04135240614414215,
        0.010520679876208305,
        -0.04941274970769882,
        0.05470540001988411,
        -0.06267748028039932,
        0.0507005900144577,
        -0.009466426447033882,
        0.006930169649422169,
        -0.033661115914583206,
        0.004347306210547686,
        -0.056740522384643555,
        0.06152338534593582,
        -0.04913739860057831,
        -0.021674398332834244,
        -0.06095036119222641,
        -0.0016917629400268197,
        0.014249395579099655,
        0.053537752479314804,
        0.022600015625357628,
        0.019244128838181496,
        -0.00986909307539463,
        -0.051591891795396805,
        -0.03341740369796753,
        0.06028367578983307,
        0.05799552798271179,
        0.018787670880556107,
        -0.055094968527555466,
        -0.06196349859237671,
        0.05812542140483856,
        0.0626329630613327,
        -0.05131486430764198,
        0.026198940351605415,
        -0.05801722779870033,
        -0.031399186700582504,
        0.029996635392308235,
        -0.06274883449077606,
        0.009092655032873154,
        -0.019008927047252655,
        -0.023209642618894577,
        0.032005827873945236,
        0.056094005703926086,
        0.039639707654714584,
        -0.0011878323275595903,
        0.03815672919154167,
        -0.05636593699455261,
        -0.012400442734360695,
        0.061189427971839905,
        -0.02724439464509487,
        -0.05910979583859444,
        0.0009561898186802864,
        -0.019358083605766296,
        0.04080764949321747,
        -0.014713861979544163,
        0.05737856775522232,
        0.04117734730243683,
        -0.041652098298072815,
        -0.044493187218904495,
        0.05994468927383423,
        0.04978931322693825,
        0.042576052248477936,
        -0.05157232657074928,
        -0.03020104579627514,
        0.05958041176199913,
        0.056600283831357956,
        0.06031429395079613,
        0.03803858906030655,
        -0.04846734181046486,
        0.050108619034290314,
        -0.05545048788189888,
        0.016108885407447815,
        -0.018831957131624222,
        -0.012714121490716934,
        0.0374099425971508,
        -0.03237050399184227,
        -0.03672928363084793,
        0.06034710258245468,
        -0.02222459577023983,
        -0.057331185787916183,
        -0.05185137689113617,
        0.025937778875231743,
        -0.05975198373198509,
        -0.06286956369876862,
        -0.03386961296200752,
        -0.04510071501135826,
        0.002518607536330819,
        -0.006090973038226366,
        0.0583091601729393,
        0.06216105446219444,
        0.06040135771036148,
        0.02347414381802082,
        0.023192649707198143,
        0.06129299849271774,
        -0.04936680197715759,
        0.059844765812158585,
        0.037232790142297745,
        -0.054035063832998276,
        -0.021494794636964798,
        0.057116493582725525,
        0.055482011288404465,
        -0.025026479735970497,
        -0.05866064503788948,
        0.04163788631558418,
        -0.04716647416353226,
        0.017623571678996086,
        0.037815384566783905,
        0.039358384907245636,
        -0.03617708384990692,
        -0.0559149831533432,
        0.03533831983804703,
        0.03692258149385452,
        0.05973232164978981,
        -0.04938901588320732,
        0.05857708677649498,
        -0.058004215359687805,
        -0.05279256030917168,
        0.027853380888700485,
        0.06244443356990814,
        0.024797510355710983,
        0.03447888791561127,
        -0.055879294872283936,
        -0.03011113405227661,
        0.001006653648801148,
        0.05372507497668266,
        -0.0448143407702446,
        -0.05350276082754135,
        -0.05123766511678696,
        -0.05837022513151169,
        -0.04725008085370064,
        0.011681728065013885,
        -0.05735434591770172,
        0.0580187663435936,
        0.05885501205921173,
        0.04249383136630058,
        -0.05628528445959091,
        -0.009987439960241318,
        -0.05557844415307045,
        0.04706137627363205,
        0.027160577476024628,
        -0.011097129434347153,
        0.055844198912382126,
        0.023638902232050896,
        0.045070819556713104,
        0.056769128888845444,
        0.05851084366440773,
        -0.008048247545957565,
        -0.029213417321443558,
        -0.01709889806807041,
        -0.05844461917877197,
        0.04159930720925331,
        -0.0363098569214344,
        -0.0491497665643692,
        0.06283252686262131,
        -0.022467171773314476,
        0.012136518955230713,
        -0.06263870000839233,
        0.038442570716142654,
        0.05352514237165451
    ],
    [
        -0.052345603704452515,
        -0.025713954120874405,
        0.006850781384855509,
        0.04100586101412773,
        0.0349002443253994,
        -0.022199219092726707,
        0.02726011537015438,
        -0.05851668491959572,
        0.02776787243783474,
        0.023897703737020493,
        0.00390217499807477,
        0.0482105016708374,
        -0.015071125701069832,
        -0.05422169715166092,
        -0.06852450966835022,
        0.04099790006875992,
        -0.035023484379053116,
        0.06075670197606087,
        -0.05877772718667984,
        -0.04566801339387894,
        -0.03663571923971176,
        0.04910736158490181,
        0.027635375037789345,
        -0.05156207084655762,
        0.04908837005496025,
        0.015204878523945808,
        0.052811238914728165,
        -0.03577394783496857,
        -0.007250965107232332,
        0.016931608319282532,
        0.06410711258649826,
        -0.03174677863717079,
        -0.034337397664785385,
        -0.06997955590486526,
        0.03649406507611275,
        -0.014513794332742691,
        -0.06314263492822647,
        -0.048666659742593765,
        -0.04447720944881439,
        -0.01909583806991577,
        0.047513172030448914,
        0.026687990874052048,
        0.030138256028294563,
        -0.06444187462329865,
        0.027379149571061134,
        0.06678254902362823,
        0.020528176799416542,
        0.04251756891608238,
        0.06389462202787399,
        0.049245886504650116,
        0.056617576628923416,
        0.056031737476587296,
        0.0018405173905193806,
        0.04893531650304794,
        -0.01904316619038582,
        -0.05964519456028938,
        -0.021574700251221657,
        -0.03375646471977234,
        0.03148139268159866,
        0.03058663383126259,
        -0.04541268199682236,
        0.0035614995285868645,
        -0.05360031500458717,
        0.06482306122779846,
        -0.02285526506602764,
        -0.03218040242791176,
        0.05114101991057396,
        -0.026960426941514015,
        -0.04650195688009262,
        -0.06798598915338516,
        -0.06769303232431412,
        -0.0719817653298378,
        0.05034336447715759,
        -0.04499383643269539,
        0.00545195396989584,
        -0.004432739689946175,
        0.012713643722236156,
        0.06868141889572144,
        -0.008425634354352951,
        -0.014102996326982975,
        -0.0007011916604824364,
        0.06786825507879257,
        -0.0513773038983345,
        0.056274719536304474,
        0.05992355942726135,
        0.050113506615161896,
        0.06475836038589478,
        -0.00780045660212636,
        -0.004898186773061752,
        0.06637485325336456,
        -0.038645174354314804,
        0.03312858194112778,
        -0.06373050063848495,
        -0.006002490874379873,
        -0.006136333104223013,
        0.0582539401948452,
        -0.017158200964331627,
        -0.06539399921894073,
        -0.05254579707980156,
        0.0038534163031727076,
        -0.06670515984296799,
        0.02431377023458481,
        0.07014825940132141,
        0.035015273839235306,
        0.05591457709670067,
        -0.05923132225871086,
        -0.015350274741649628,
        0.03202468156814575,
        -0.06151941046118736,
        0.05227509140968323,
        -0.05399036034941673,
        -0.046770378947257996,
        -0.030250171199440956,
        -0.031041469424962997,
        -0.0072166272439062595,
        -0.050823915749788284,
        0.054037902504205704,
        -0.003633615095168352,
        0.06300216168165207,
        0.03612298145890236,
        0.0006925566704012454,
        0.06646870821714401,
        -0.030379189178347588,
        0.040132276713848114,
        -0.0632358118891716,
        -0.03200206533074379,
        -0.022626925259828568,
        -0.03522748872637749,
        0.056070808321237564,
        0.020214993506669998,
        0.005740069784224033,
        -0.04895869269967079,
        -0.05396031215786934,
        0.03338546305894852,
        -0.06366261094808578,
        -0.054878976196050644,
        0.0069017861969769,
        -0.008762429468333721,
        -0.0626198798418045,
        0.0583505816757679,
        0.0582084022462368,
        -0.003927548881620169,
        0.06117821857333183,
        -0.02099967747926712,
        0.060675326734781265,
        0.019740136340260506,
        0.06997355073690414,
        -0.032419949769973755,
        0.022011933848261833,
        0.06774623692035675,
        0.05471612885594368,
        -0.07093970477581024,
        -0.03413110971450806,
        -0.0005705751827917993,
        0.02543986588716507,
        -0.03736938536167145,
        0.04220132529735565,
        0.03375327214598656,
        -0.038092467933893204,
        0.0009422437869943678,
        0.06914548575878143,
        0.05013278126716614,
        0.053180526942014694,
        0.02847055345773697,
        0.01828727126121521,
        -0.005057044792920351,
        -0.0365101657807827,
        -0.022647714242339134,
        0.00783214345574379,
        0.03363815322518349,
        -0.02308794856071472,
        0.019724979996681213,
        0.013382762670516968,
        -0.04822690412402153,
        -0.005384405143558979,
        0.06781641393899918,
        -0.07045978307723999,
        0.018046380952000618,
        0.022223174571990967,
        -0.05374622344970703,
        0.038396913558244705,
        0.0681721642613411,
        0.04367241635918617,
        0.051398880779743195,
        0.020627375692129135,
        0.03548986464738846,
        -0.06567059457302094,
        0.06691821664571762,
        -0.06326422095298767,
        -0.04246634244918823,
        -0.031668663024902344,
        0.04442333057522774,
        -0.021692385897040367,
        -0.0019281207351014018,
        -0.04122927039861679,
        0.015872826799750328,
        0.018954696133732796,
        -0.023483533412218094,
        -0.05117129907011986,
        0.009358367882668972,
        0.021877802908420563,
        -0.002232127822935581,
        0.016921687871217728,
        0.02440364472568035,
        -0.06596949696540833,
        0.06801259517669678,
        0.03568362072110176,
        -0.0055632637813687325,
        -0.06262004375457764,
        0.041397057473659515,
        -0.046098534017801285,
        0.06428014487028122,
        0.009385182522237301,
        0.017509285360574722,
        0.008269807323813438,
        0.032589416950941086,
        0.04034377261996269,
        -0.03208374232053757,
        0.06736822426319122,
        -0.045576512813568115,
        -0.012833588756620884,
        0.029273314401507378,
        -0.04851990193128586,
        -0.05875980854034424,
        0.07007642090320587,
        -0.06134723499417305,
        -0.030574200674891472,
        0.03715202584862709,
        -0.026055077090859413,
        -0.06261332333087921,
        0.06057363003492355,
        -0.06794089823961258,
        -0.06067712977528572,
        0.03584542125463486,
        -0.053628649562597275,
        0.06747334450483322,
        0.06609112024307251,
        -0.03020121343433857,
        -0.054817020893096924,
        0.06338250637054443,
        0.026613296940922737,
        0.06296014785766602,
        -0.07037021964788437,
        0.030022889375686646,
        0.04072784259915352,
        0.0034666757564991713,
        -0.028877057135105133,
        -0.007063427474349737,
        -0.047718532383441925,
        -0.04962629824876785,
        -0.04779381304979324,
        -0.06931588798761368,
        -0.002164282836019993,
        -0.06437482684850693,
        -0.025761017575860023,
        0.06106286868453026,
        -0.05449165776371956,
        -0.047124139964580536,
        0.06600421667098999,
        0.0035236708354204893,
        0.07134289294481277,
        -0.0032011037692427635,
        0.011287909001111984,
        0.028078459203243256,
        -0.004082418512552977,
        0.04561922699213028,
        -0.03304333612322807,
        0.05244751647114754,
        -0.03714041784405708,
        -0.03432193771004677,
        0.022840701043605804,
        -0.06406906247138977,
        0.06535959243774414,
        -0.05652405694127083,
        -0.05207633599638939,
        0.0119607113301754,
        -0.052221763879060745,
        0.030374666675925255,
        -0.032592758536338806,
        -0.02686695009469986,
        -0.058807939291000366,
        -0.02731538750231266,
        -0.05720197781920433,
        0.06523256748914719,
        0.033317796885967255,
        -0.048354361206293106,
        0.032174043357372284,
        0.011868366971611977,
        -0.01624479703605175,
        0.05096052214503288,
        -0.03411783277988434,
        0.04977748170495033,
        0.0031429079826921225,
        0.055781491100788116,
        0.059531234204769135,
        -0.062445394694805145,
        0.06710712611675262,
        0.0519242063164711,
        -0.05549906566739082,
        0.045376162976026535,
        0.0052923825569450855,
        0.06033332273364067,
        -0.009140662848949432,
        0.031481046229600906,
        -0.025361506268382072,
        -0.00907802116125822,
        -0.04309891536831856,
        0.011171841062605381,
        0.043139006942510605,
        -0.06840170174837112,
        0.018387626856565475,
        -0.05238984897732735,
        -0.0385141484439373,
        0.0613832026720047,
        -0.03245597705245018,
        -0.05936374142765999,
        0.055733516812324524,
        -0.05817525461316109,
        0.0196211077272892,
        -0.0544697605073452,
        -0.05845298618078232,
        0.04151180386543274,
        0.036911603063344955,
        0.0235055573284626,
        0.00814841315150261,
        0.01915057562291622,
        -0.018520040437579155,
        -0.009232522919774055,
        -0.003559867851436138,
        0.01496126689016819,
        0.03431861475110054,
        0.006713176611810923,
        -0.06025625392794609,
        -0.060259751975536346,
        0.0664866492152214,
        0.019624672830104828,
        -0.019921228289604187,
        0.0630900114774704,
        -0.04972688853740692,
        0.024701997637748718,
        0.03417287766933441,
        0.03855103254318237,
        -0.06369853764772415,
        -0.06164714694023132,
        0.07169551402330399,
        -0.006595589220523834,
        0.01941797509789467,
        0.04777461290359497,
        -0.004169007297605276,
        0.06436795741319656,
        -0.051695842295885086,
        0.016989396885037422,
        -0.04287410154938698,
        -0.06378859281539917,
        -0.06293889135122299,
        0.061502598226070404,
        -0.0599452443420887,
        -0.004766926169395447,
        0.03203945979475975,
        -0.06776068359613419,
        0.053078655153512955,
        0.04128063842654228,
        -0.03992459923028946,
        0.010457614436745644,
        0.033067017793655396,
        -0.041711658239364624,
        0.06990659236907959,
        -0.04376761242747307,
        0.060439422726631165,
        -0.06977513432502747,
        -0.021802391856908798,
        -0.03952644392848015,
        0.06372950226068497,
        0.05426490679383278,
        0.034883156418800354,
        0.04067796468734741,
        -0.038727693259716034,
        0.03290414810180664,
        0.04124443605542183,
        0.046145085245370865,
        0.02135193534195423,
        -0.04833811894059181,
        -0.06926821172237396,
        0.037436094135046005,
        0.050496406853199005,
        0.013320088386535645,
        0.019031215459108353,
        -0.0677734985947609,
        -0.019713344052433968,
        -0.0118520837277174,
        -0.06941565126180649,
        0.014234797097742558,
        0.023894332349300385,
        0.0035723592154681683,
        -0.02699096128344536,
        0.06458434462547302,
        0.06147637963294983,
        0.043590378016233444,
        0.0020761461928486824,
        -0.06647826731204987,
        -0.032966915518045425,
        0.058318279683589935,
        0.06308574229478836,
        -0.010395236313343048,
        -0.03965648636221886,
        0.02505415491759777,
        0.019057119265198708,
        -0.035975877195596695,
        0.05054817348718643,
        -0.009230944328010082,
        0.0310685895383358,
        -0.06402484327554703,
        0.05794937163591385,
        0.021737389266490936,
        0.04681411758065224,
        -0.05402809754014015,
        -0.023530971258878708,
        0.057191889733076096,
        0.018943848088383675,
        0.03554718196392059,
        -0.025671660900115967,
        -0.029804762452840805,
        0.020568864420056343,
        -0.05201641470193863,
        0.05119050666689873,
        0.00690136943012476,
        -0.004878817591816187,
        -0.03704870864748955,
        -0.04803421348333359,
        -0.053014181554317474,
        0.05809613689780235,
        0.009276354685425758,
        -0.06785331666469574,
        -0.016866719350218773,
        0.015976043418049812,
        -0.05148405209183693,
        -0.05866580456495285,
        -0.023790348321199417,
        0.024112634360790253,
        0.01377908606082201,
        0.04064764827489853,
        0.0645468607544899,
        0.04211048781871796,
        0.06121193245053291,
        0.051750097423791885,
        0.031784649938344955,
        0.06912233680486679,
        -0.04725205898284912,
        0.06442447751760483,
        -0.02382451295852661,
        -0.060989972203969955,
        -0.010280118323862553,
        0.06683233380317688,
        0.02062106877565384,
        -0.008484606631100178,
        -0.056096192449331284,
        0.02954065054655075,
        -0.020337864756584167,
        -0.05248980224132538,
        0.06137795001268387,
        -0.028490522876381874,
        0.0011080503463745117,
        -0.06655749678611755,
        -0.00494103180244565,
        0.06367506831884384,
        0.06993484497070312,
        -0.05188840255141258,
        0.054378896951675415,
        -0.06470980495214462,
        -0.06652846932411194,
        0.04164948686957359,
        0.06442885100841522,
        0.035273488610982895,
        0.009203650057315826,
        -0.06260968744754791,
        -0.002924602245911956,
        0.030146626755595207,
        -0.004939003381878138,
        -0.05185255408287048,
        -0.042555760592222214,
        -0.04802248999476433,
        -0.060537222772836685,
        -0.02907922863960266,
        0.01471787504851818,
        -0.030841706320643425,
        0.03056606650352478,
        0.020805981010198593,
        0.01802140846848488,
        -0.05911612883210182,
        -0.0248344074934721,
        -0.04970869421958923,
        -0.020100226625800133,
        0.005732379853725433,
        -0.0057490188628435135,
        0.06750126928091049,
        0.031633637845516205,
        -0.012929797172546387,
        0.059576380997896194,
        0.045271359384059906,
        0.0631960853934288,
        -0.04115494713187218,
        -0.048525188118219376,
        -0.04942641034722328,
        0.00904723722487688,
        -0.012002499774098396,
        -0.04417014494538307,
        0.04514472559094429,
        0.03276849910616875,
        -0.05503319948911667,
        -0.06196848675608635,
        0.05315884202718735,
        0.0635005533695221
    ],
    [
        -0.056617382913827896,
        -0.023260384798049927,
        0.00036693006404675543,
        0.04552130028605461,
        0.024786535650491714,
        -0.028124991804361343,
        0.027086395770311356,
        -0.05887066200375557,
        0.02979149855673313,
        0.030930902808904648,
        0.009105619974434376,
        0.050239987671375275,
        -0.009520238265395164,
        -0.051240403205156326,
        -0.06897369027137756,
        0.03835047408938408,
        -0.035128481686115265,
        0.05891492962837219,
        -0.06162403151392937,
        -0.04606540501117706,
        -0.03034011274576187,
        0.04050283879041672,
        0.0310724638402462,
        -0.04869283735752106,
        0.04937975853681564,
        0.028741637244820595,
        0.05664093792438507,
        -0.024403728544712067,
        -0.009202122688293457,
        0.0058068339712917805,
        0.06760381907224655,
        -0.02482752501964569,
        -0.03396744653582573,
        -0.0706295520067215,
        0.03988702595233917,
        -0.006041001528501511,
        -0.06376095116138458,
        -0.05082261934876442,
        -0.0620577298104763,
        -0.012708772905170918,
        0.050676558166742325,
        0.03439262509346008,
        0.03099050559103489,
        -0.06606560200452805,
        0.029618127271533012,
        0.06545207649469376,
        0.021123763173818588,
        0.04346199333667755,
        0.06709190458059311,
        0.042876437306404114,
        0.0531652607023716,
        0.05470423027873039,
        0.011888507753610611,
        0.050552986562252045,
        -0.008428646251559258,
        -0.058200594037771225,
        -0.0225713849067688,
        -0.03074014186859131,
        0.024752076715230942,
        0.0335940346121788,
        -0.04978612810373306,
        -0.0013321859296411276,
        -0.05111183971166611,
        0.06606277823448181,
        -0.036145053803920746,
        -0.025129511952400208,
        0.05126204341650009,
        -0.02166924811899662,
        -0.04251193255186081,
        -0.06840571761131287,
        -0.0675162523984909,
        -0.07255958020687103,
        0.04581107571721077,
        -0.047336019575595856,
        0.011771873570978642,
        0.00008312460704473779,
        0.017256811261177063,
        0.0706692785024643,
        0.005973345600068569,
        -0.014263453893363476,
        -0.007382082287222147,
        0.06813275814056396,
        -0.05300038680434227,
        0.05126959830522537,
        0.05162974074482918,
        0.04708325117826462,
        0.06533752381801605,
        -0.008626561611890793,
        0.007110003847628832,
        0.0662379041314125,
        -0.03929803520441055,
        0.03980768471956253,
        -0.062469482421875,
        -0.002448089886456728,
        -0.006689432542771101,
        0.05169464647769928,
        -0.021554451435804367,
        -0.06581832468509674,
        -0.05555617809295654,
        0.003627573372796178,
        -0.0653996542096138,
        0.02206667885184288,
        0.07064259052276611,
        0.0451120026409626,
        0.05544722080230713,
        -0.06083289906382561,
        -0.021039607003331184,
        0.03933916985988617,
        -0.05905195325613022,
        0.0551140271127224,
        -0.05488865450024605,
        -0.051924895495176315,
        -0.03048681654036045,
        -0.017586026340723038,
        -0.0029098663944751024,
        -0.048784226179122925,
        0.047219809144735336,
        0.0016676302766427398,
        0.05488800257444382,
        0.03818437457084656,
        -0.013548720628023148,
        0.06559763848781586,
        -0.029872315004467964,
        0.04408317059278488,
        -0.06408241391181946,
        -0.021636560559272766,
        -0.02885301597416401,
        -0.04320729896426201,
        0.056931886821985245,
        0.024861454963684082,
        0.008213619701564312,
        -0.04391762241721153,
        -0.05074778571724892,
        0.030334485694766045,
        -0.0620882585644722,
        -0.05478823184967041,
        0.0003060760791413486,
        -0.016613896936178207,
        -0.0617557130753994,
        0.0582384318113327,
        0.05775373801589012,
        -0.01385538000613451,
        0.0616067573428154,
        -0.023947924375534058,
        0.06129695847630501,
        0.02591811679303646,
        0.06950707733631134,
        -0.030714746564626694,
        0.01591290719807148,
        0.06811339408159256,
        0.051928360015153885,
        -0.071108378469944,
        -0.018859945237636566,
        -0.008074652403593063,
        0.019514407962560654,
        -0.04456562548875809,
        0.050945576280355453,
        0.03385831415653229,
        -0.037729185074567795,
        0.012549585662782192,
        0.06977815926074982,
        0.04607464745640755,
        0.05408027768135071,
        0.021840352565050125,
        0.019588656723499298,
        0.0014986988389864564,
        -0.04339943453669548,
        -0.024298159405589104,
        0.009732778184115887,
        0.03481042757630348,
        -0.018697360530495644,
        0.029332339763641357,
        0.008987262845039368,
        -0.0433509424328804,
        -0.0027583190239965916,
        0.06956588476896286,
        -0.07137365639209747,
        0.007267582230269909,
        0.031524643301963806,
        -0.056101955473423004,
        0.03727450966835022,
        0.06821872293949127,
        0.03825100511312485,
        0.053690534085035324,
        0.012977899052202702,
        0.035120345652103424,
        -0.06672234833240509,
        0.06645593047142029,
        -0.061379075050354004,
        -0.03824092447757721,
        -0.030113376677036285,
        0.042454663664102554,
        -0.032299790531396866,
        -0.023005686700344086,
        -0.043547965586185455,
        0.011580126360058784,
        0.014825505204498768,
        -0.023507550358772278,
        -0.04339183494448662,
        0.01934024505317211,
        0.03466148301959038,
        -0.013010096736252308,
        0.022997133433818817,
        0.030471716076135635,
        -0.06450322270393372,
        0.06726353615522385,
        0.033236730843782425,
        -0.006922372616827488,
        -0.06375991553068161,
        0.017475726082921028,
        -0.04765148088335991,
        0.06528156250715256,
        -0.006537389941513538,
        0.01943415217101574,
        0.008283227682113647,
        0.036485061049461365,
        0.05131147801876068,
        -0.025627046823501587,
        0.06487166881561279,
        -0.04134666919708252,
        -0.011436644941568375,
        0.026804307475686073,
        -0.05610642954707146,
        -0.06267479807138443,
        0.06998002529144287,
        -0.059000879526138306,
        -0.026008008047938347,
        0.04258818179368973,
        -0.010800658725202084,
        -0.06182936951518059,
        0.060119930654764175,
        -0.06731826812028885,
        -0.060366105288267136,
        0.03246186673641205,
        -0.05097547546029091,
        0.0687669962644577,
        0.06637997925281525,
        -0.03403277322649956,
        -0.049431636929512024,
        0.0666206106543541,
        0.023934027180075645,
        0.0622631274163723,
        -0.06976165622472763,
        0.01924181543290615,
        0.03880003094673157,
        0.006871566642075777,
        -0.03667768836021423,
        0.004983304534107447,
        -0.04925328120589256,
        -0.05394613370299339,
        -0.04417608305811882,
        -0.07016996294260025,
        -0.0038369987159967422,
        -0.06522980332374573,
        -0.03042941354215145,
        0.06314253807067871,
        -0.05605754628777504,
        -0.045475177466869354,
        0.06683827936649323,
        0.020509187132120132,
        0.07183212786912918,
        -0.011303328908979893,
        0.004800599068403244,
        0.03245789557695389,
        -0.019478466361761093,
        0.04115816578269005,
        -0.021061621606349945,
        0.04560079425573349,
        -0.039762943983078,
        -0.029198413714766502,
        0.027929503470659256,
        -0.06383976340293884,
        0.06393729895353317,
        -0.059099867939949036,
        -0.04818005487322807,
        0.019552748650312424,
        -0.054724693298339844,
        0.02728413976728916,
        -0.010923915542662144,
        -0.026450492441654205,
        -0.0592225007712841,
        -0.030444830656051636,
        -0.05512642115354538,
        0.06728978455066681,
        0.026296459138393402,
        -0.045243214815855026,
        0.0376865416765213,
        -0.010418491438031197,
        -0.013225294649600983,
        0.0491798110306263,
        -0.038116563111543655,
        0.047956302762031555,
        -0.004298062063753605,
        0.05452122539281845,
        0.05706189200282097,
        -0.06153542920947075,
        0.06760603189468384,
        0.05957028642296791,
        -0.05025762319564819,
        0.04414257034659386,
        0.016321776434779167,
        0.05544637143611908,
        -0.0005664259078912437,
        0.029515115544199944,
        -0.017993401736021042,
        -0.006322138011455536,
        -0.04728012904524803,
        0.003023935714736581,
        0.04553120955824852,
        -0.0692998617887497,
        0.008865661919116974,
        -0.052769098430871964,
        -0.040667448192834854,
        0.062365058809518814,
        -0.037661969661712646,
        -0.061471834778785706,
        0.0545291043817997,
        -0.051818519830703735,
        0.020534487441182137,
        -0.05529089644551277,
        -0.060711055994033813,
        0.03820944204926491,
        0.028377912938594818,
        0.010235521011054516,
        0.019999556243419647,
        0.019891273230314255,
        -0.016128256916999817,
        -0.005695533473044634,
        -0.010175103321671486,
        0.009881565347313881,
        0.030504168942570686,
        0.0031307514291256666,
        -0.05941106006503105,
        -0.062454771250486374,
        0.06755086779594421,
        0.012894904240965843,
        -0.029771536588668823,
        0.06123005598783493,
        -0.05058962479233742,
        0.028404153883457184,
        0.03254172205924988,
        0.041485704481601715,
        -0.061771996319293976,
        -0.06232084706425667,
        0.07269014418125153,
        -0.01086433045566082,
        0.014654370956122875,
        0.04888353496789932,
        -0.016610637307167053,
        0.06359528750181198,
        -0.05478136986494064,
        0.022941159084439278,
        -0.04901496693491936,
        -0.06436656415462494,
        -0.06354225426912308,
        0.0627937912940979,
        -0.0592474602162838,
        -0.004405757877975702,
        0.029425760731101036,
        -0.06715348362922668,
        0.0506952740252018,
        0.04186581075191498,
        -0.04434709995985031,
        0.01587223820388317,
        0.03206057846546173,
        -0.039723753929138184,
        0.07041969150304794,
        -0.02713995985686779,
        0.060712780803442,
        -0.07142628729343414,
        -0.02782927267253399,
        -0.041282352060079575,
        0.06556962430477142,
        0.05273616313934326,
        0.03723204508423805,
        0.04410819336771965,
        -0.04539996758103371,
        0.03626010939478874,
        0.022880755364894867,
        0.04192652553319931,
        0.02675071731209755,
        -0.03388090431690216,
        -0.07056447863578796,
        0.03929798677563667,
        0.04635898023843765,
        0.014781667850911617,
        0.018158717080950737,
        -0.06812020391225815,
        -0.022847788408398628,
        -0.01839209906756878,
        -0.06974291056394577,
        0.013855183497071266,
        0.005100208334624767,
        0.007474950514733791,
        -0.04007222503423691,
        0.06621027737855911,
        0.056943848729133606,
        0.04415220394730568,
        0.015027099289000034,
        -0.06648953258991241,
        -0.03319937363266945,
        0.06127222254872322,
        0.06741026788949966,
        -0.014096403494477272,
        -0.04435091093182564,
        0.021856924518942833,
        0.011129925958812237,
        -0.03466297686100006,
        0.05362709239125252,
        -0.027876922860741615,
        0.02758106030523777,
        -0.06197333335876465,
        0.057470373809337616,
        0.011862708255648613,
        0.04973346367478371,
        -0.0508536621928215,
        -0.018935799598693848,
        0.05635356530547142,
        0.019575174897909164,
        0.028486724942922592,
        -0.011375893838703632,
        -0.031109189614653587,
        0.015513706021010876,
        -0.049750275909900665,
        0.05571310222148895,
        -0.0064839813858270645,
        -0.011787296272814274,
        -0.03567361831665039,
        -0.04543476179242134,
        -0.056717123836278915,
        0.05839458107948303,
        -0.0018235499737784266,
        -0.06723830848932266,
        -0.007067013066262007,
        0.024904368445277214,
        -0.05040531978011131,
        -0.04875156283378601,
        -0.02201610617339611,
        0.035285692662000656,
        0.005043285433202982,
        0.05179678276181221,
        0.06287038326263428,
        0.042064059525728226,
        0.059930142015218735,
        0.05510256066918373,
        0.033135786652565,
        0.06942038983106613,
        -0.05035904422402382,
        0.06432539969682693,
        -0.016272379085421562,
        -0.06296151131391525,
        0.0033169749658554792,
        0.06618671864271164,
        0.021907059475779533,
        -0.01431908831000328,
        -0.05789044871926308,
        0.02850974164903164,
        -0.0011046950239688158,
        -0.05683675780892372,
        0.061862144619226456,
        -0.024383051320910454,
        -0.004010985605418682,
        -0.06746801733970642,
        0.0031691642943769693,
        0.06439706683158875,
        0.07079045474529266,
        -0.05321195349097252,
        0.054208774119615555,
        -0.06522734463214874,
        -0.0688524916768074,
        0.034689322113990784,
        0.061508167535066605,
        0.03258311375975609,
        0.01759473606944084,
        -0.06001440808176994,
        0.002131915418431163,
        0.023974305018782616,
        -0.004930499009788036,
        -0.05103323236107826,
        -0.042706068605184555,
        -0.04879987612366676,
        -0.06041772663593292,
        -0.019328640773892403,
        0.017718810588121414,
        -0.01534050703048706,
        0.028962668031454086,
        0.03151614964008331,
        0.02528585121035576,
        -0.056948427110910416,
        -0.016725342720746994,
        -0.05678107962012291,
        -0.027491213753819466,
        0.016828037798404694,
        0.0026798956096172333,
        0.06575213372707367,
        0.0226922444999218,
        -0.018501725047826767,
        0.057787757366895676,
        0.04321793466806412,
        0.06570224463939667,
        -0.04645757004618645,
        -0.049337662756443024,
        -0.05079718679189682,
        0.006853355560451746,
        -0.007004471030086279,
        -0.03998466581106186,
        0.04864511638879776,
        0.028612442314624786,
        -0.05086498707532883,
        -0.06266795098781586,
        0.05439306050539017,
        0.0626068040728569
    ],
    [
        -0.06052915379405022,
        -0.01697779819369316,
        0.0011139468988403678,
        0.041917040944099426,
        0.022912923246622086,
        -0.027996422722935677,
        0.022905834019184113,
        -0.06111589074134827,
        0.0277947299182415,
        0.024938322603702545,
        0.00266830762848258,
        0.04830031096935272,
        -0.009055222384631634,
        -0.056624796241521835,
        -0.06882047653198242,
        0.04461851343512535,
        -0.031606562435626984,
        0.058740537613630295,
        -0.060080770403146744,
        -0.04485340788960457,
        -0.029621001332998276,
        0.04356569051742554,
        0.0329178087413311,
        -0.04829088971018791,
        0.04454219713807106,
        0.02162129618227482,
        0.05896073579788208,
        -0.03697097674012184,
        -0.0110499681904912,
        0.005426065530627966,
        0.06491827964782715,
        -0.02474784106016159,
        -0.0307210274040699,
        -0.06975779682397842,
        0.03633533790707588,
        -0.001483812928199768,
        -0.06370198726654053,
        -0.04976380988955498,
        -0.05949622392654419,
        -0.016617652028799057,
        0.051815375685691833,
        0.03167346864938736,
        0.03385895863175392,
        -0.06563714891672134,
        0.033388685435056686,
        0.06669827550649643,
        0.03039180487394333,
        0.04711506515741348,
        0.06375288963317871,
        0.04878346994519234,
        0.05960478261113167,
        0.05710988491773605,
        0.011975601315498352,
        0.05418694019317627,
        -0.0037626426201313734,
        -0.06044881045818329,
        -0.011234325356781483,
        -0.028329500928521156,
        0.02591298520565033,
        0.028117764741182327,
        -0.05416106432676315,
        -0.0030436988454312086,
        -0.054902367293834686,
        0.06668413430452347,
        -0.032768893986940384,
        -0.019853981211781502,
        0.05397144705057144,
        -0.02769792638719082,
        -0.04052521288394928,
        -0.06786590069532394,
        -0.06870689988136292,
        -0.07270658761262894,
        0.045590661466121674,
        -0.03994150459766388,
        0.01382941659539938,
        0.002743710996583104,
        0.018370432779192924,
        0.0707644522190094,
        0.0016014527063816786,
        -0.020950276404619217,
        -0.008981231600046158,
        0.06882701069116592,
        -0.05253919959068298,
        0.049933139234781265,
        0.055699001997709274,
        0.04854020103812218,
        0.06621958315372467,
        -0.004732743836939335,
        0.0062185609713196754,
        0.06615500897169113,
        -0.037202201783657074,
        0.031002163887023926,
        -0.06423483043909073,
        -0.0018789649475365877,
        -0.01507261861115694,
        0.05407759174704552,
        -0.025761490687727928,
        -0.06593518704175949,
        -0.050581593066453934,
        0.003624113043770194,
        -0.06734941899776459,
        0.02606304921209812,
        0.07143720239400864,
        0.0501038059592247,
        0.05829261243343353,
        -0.06376700848340988,
        -0.01630524918437004,
        0.04190843924880028,
        -0.05800125375390053,
        0.05642687901854515,
        -0.0536804124712944,
        -0.050351593643426895,
        -0.024829553440213203,
        -0.027459660544991493,
        0.009101374074816704,
        -0.05272668972611427,
        0.050405777990818024,
        -0.009468326345086098,
        0.05806994438171387,
        0.037292707711458206,
        -0.005183520261198282,
        0.0658223107457161,
        -0.021674107760190964,
        0.039948370307683945,
        -0.06567710638046265,
        -0.025977587327361107,
        -0.02685151807963848,
        -0.04516834020614624,
        0.055476292967796326,
        0.028275802731513977,
        0.015711037442088127,
        -0.04939192906022072,
        -0.05263707414269447,
        0.02853357046842575,
        -0.06363551318645477,
        -0.05575781688094139,
        0.011072618886828423,
        -0.016246939077973366,
        -0.06518729031085968,
        0.056246910244226456,
        0.05662728101015091,
        0.001200419501401484,
        0.06281982362270355,
        -0.016776813194155693,
        0.05937721207737923,
        0.027435502037405968,
        0.06998760998249054,
        -0.038736872375011444,
        0.016105974093079567,
        0.06754140555858612,
        0.04516284167766571,
        -0.07150314748287201,
        -0.006088677793741226,
        -0.009306542575359344,
        0.01563490368425846,
        -0.03935521841049194,
        0.04674794524908066,
        0.035248901695013046,
        -0.035032760351896286,
        -0.001502525177784264,
        0.06988991051912308,
        0.04492831230163574,
        0.057028818875551224,
        0.015937088057398796,
        0.025562144815921783,
        -0.004572455771267414,
        -0.0402948334813118,
        -0.022667353972792625,
        0.0039793336763978004,
        0.0277179516851902,
        -0.010022755712270737,
        0.027104312554001808,
        0.018832985311746597,
        -0.04727279022336006,
        0.0035491066519171,
        0.06896563619375229,
        -0.07040315121412277,
        0.01276534702628851,
        0.028410885483026505,
        -0.05562924966216087,
        0.03268329054117203,
        0.06975056976079941,
        0.04440179839730263,
        0.04908831790089607,
        0.010855314321815968,
        0.030905531719326973,
        -0.06551720947027206,
        0.06796524673700333,
        -0.06228994205594063,
        -0.030662545934319496,
        -0.02836856245994568,
        0.0470089316368103,
        -0.0201833825558424,
        -0.010306707583367825,
        -0.04660286009311676,
        0.011257844977080822,
        0.016625840216875076,
        -0.029343897476792336,
        -0.044744282960891724,
        0.024655599147081375,
        0.023056842386722565,
        -0.002994593232870102,
        0.004491628147661686,
        0.016433319076895714,
        -0.06662268936634064,
        0.06856276094913483,
        0.0332803837954998,
        -0.008065184578299522,
        -0.06293109804391861,
        0.025622939690947533,
        -0.0480959415435791,
        0.0673794373869896,
        0.0005671032122336328,
        0.027435343712568283,
        0.011183354072272778,
        0.024674244225025177,
        0.04131820797920227,
        -0.03454078361392021,
        0.06611651182174683,
        -0.05122281610965729,
        -0.018574221059679985,
        0.0350748747587204,
        -0.04962395876646042,
        -0.06065215915441513,
        0.06931848078966141,
        -0.05515967682003975,
        -0.022711049765348434,
        0.0402803048491478,
        -0.017201220616698265,
        -0.062495045363903046,
        0.05547475442290306,
        -0.06392687559127808,
        -0.06280528753995895,
        0.03712262958288193,
        -0.04918660223484039,
        0.06862129271030426,
        0.06781083345413208,
        -0.039170220494270325,
        -0.054217271506786346,
        0.06574846804141998,
        0.03135066479444504,
        0.06407848000526428,
        -0.0707039162516594,
        0.021636078134179115,
        0.04016562178730965,
        0.0005940349656157196,
        -0.04319293797016144,
        -0.009745923802256584,
        -0.04673957824707031,
        -0.05448384955525398,
        -0.051898494362831116,
        -0.07032082974910736,
        -0.010278320871293545,
        -0.06383810192346573,
        -0.026052573695778847,
        0.06321098655462265,
        -0.05813717469573021,
        -0.03762533888220787,
        0.06844501197338104,
        0.004164131358265877,
        0.07227098941802979,
        -0.009184705093502998,
        0.004316746257245541,
        0.032157253473997116,
        -0.022927632555365562,
        0.04084791988134384,
        -0.022265058010816574,
        0.049773283302783966,
        -0.03677967190742493,
        -0.02472071908414364,
        0.02548278123140335,
        -0.06415611505508423,
        0.06416713446378708,
        -0.05478867515921593,
        -0.05189811438322067,
        0.015904124826192856,
        -0.055160872638225555,
        0.03068704344332218,
        -0.013426564633846283,
        -0.0228326003998518,
        -0.05928673967719078,
        -0.024370284751057625,
        -0.054812077432870865,
        0.06547411531209946,
        0.02438530884683132,
        -0.04749616980552673,
        0.03698192164301872,
        0.007125203497707844,
        -0.016266683116555214,
        0.044094014912843704,
        -0.029811112210154533,
        0.04794219508767128,
        -0.005125909112393856,
        0.05248459056019783,
        0.057288963347673416,
        -0.0631314143538475,
        0.06886805593967438,
        0.059562381356954575,
        -0.053969770669937134,
        0.04395100474357605,
        0.014069047756493092,
        0.05735081806778908,
        -0.0018307416466996074,
        0.02555103413760662,
        -0.00769821647554636,
        -0.013236121274530888,
        -0.045885708183050156,
        0.00032642894075252116,
        0.04377707839012146,
        -0.06801454722881317,
        0.009710285812616348,
        -0.05142650753259659,
        -0.047420162707567215,
        0.06033944711089134,
        -0.030155785381793976,
        -0.06204315647482872,
        0.05684357136487961,
        -0.0575646236538887,
        0.01927623525261879,
        -0.055870555341243744,
        -0.05667330324649811,
        0.034377045929431915,
        0.026441354304552078,
        0.007153684739023447,
        0.013819834217429161,
        0.01796971634030342,
        -0.017996855080127716,
        0.0037473838310688734,
        -0.005299771204590797,
        0.014496351592242718,
        0.03292417153716087,
        -0.0017689085798338056,
        -0.06306231766939163,
        -0.06173965334892273,
        0.06768904626369476,
        0.01767450012266636,
        -0.029005227610468864,
        0.061255767941474915,
        -0.05003510043025017,
        0.033299967646598816,
        0.02963111363351345,
        0.04194791242480278,
        -0.06178802251815796,
        -0.0614522360265255,
        0.07280751317739487,
        -0.012452179566025734,
        0.009238583035767078,
        0.047161731868982315,
        -0.01806856505572796,
        0.06371204555034637,
        -0.0547374002635479,
        0.02454940229654312,
        -0.05087288096547127,
        -0.06533826142549515,
        -0.06280463188886642,
        0.06123047322034836,
        -0.061288729310035706,
        0.0007669219630770385,
        0.030256574973464012,
        -0.06740513443946838,
        0.05043020471930504,
        0.047702256590127945,
        -0.05050541087985039,
        0.011243661865592003,
        0.038314443081617355,
        -0.04447845369577408,
        0.07001147419214249,
        -0.03122919425368309,
        0.05678578466176987,
        -0.07153437286615372,
        -0.023676084354519844,
        -0.044084981083869934,
        0.06277097016572952,
        0.05536884069442749,
        0.03173277899622917,
        0.04797389358282089,
        -0.02818237617611885,
        0.04076067730784416,
        0.03680771216750145,
        0.045566946268081665,
        0.008555074222385883,
        -0.034543320536613464,
        -0.0710117369890213,
        0.03481597825884819,
        0.04982931911945343,
        0.02172764390707016,
        0.01517049502581358,
        -0.06926172226667404,
        -0.02999808080494404,
        -0.005328684113919735,
        -0.06839153170585632,
        0.010335011407732964,
        0.009645494632422924,
        0.010170291177928448,
        -0.03842131048440933,
        0.0659969374537468,
        0.059914011508226395,
        0.04358087480068207,
        0.014335356652736664,
        -0.06718403100967407,
        -0.03198248893022537,
        0.05858507752418518,
        0.06749967485666275,
        -0.01676987111568451,
        -0.039608802646398544,
        0.0176447331905365,
        0.013995913788676262,
        -0.029200300574302673,
        0.0551583394408226,
        -0.016743909567594528,
        0.01946539804339409,
        -0.06429459154605865,
        0.05860811471939087,
        0.008637082763016224,
        0.049904413521289825,
        -0.05088476836681366,
        -0.02606428787112236,
        0.04693727567791939,
        0.0215363260358572,
        0.031989891082048416,
        -0.006350100040435791,
        -0.039869632571935654,
        0.012992827221751213,
        -0.04951984062790871,
        0.048054758459329605,
        0.007802814710885286,
        -0.011838557198643684,
        -0.031237926334142685,
        -0.04712822660803795,
        -0.05372009053826332,
        0.05566276237368584,
        0.006071010604500771,
        -0.06630034744739532,
        -0.0042061638087034225,
        0.02508022077381611,
        -0.05312195047736168,
        -0.04139101877808571,
        -0.013105056248605251,
        0.03744952380657196,
        0.00043517991434782743,
        0.04540249705314636,
        0.0654151439666748,
        0.04675784334540367,
        0.06034105643630028,
        0.053708095103502274,
        0.029161041602492332,
        0.06957975775003433,
        -0.042991943657398224,
        0.06491590291261673,
        -0.010898654349148273,
        -0.05767272785305977,
        -0.015032835304737091,
        0.06535998731851578,
        0.022072982043027878,
        -0.010019341483712196,
        -0.06003068760037422,
        0.02887568809092045,
        -0.017791887745261192,
        -0.049071792513132095,
        0.061683233827352524,
        -0.019205164164304733,
        -0.00891188159584999,
        -0.06745557487010956,
        0.006813723128288984,
        0.06407427042722702,
        0.07118246704339981,
        -0.05239918828010559,
        0.053585611283779144,
        -0.06411849707365036,
        -0.0675990879535675,
        0.03874105215072632,
        0.05553753674030304,
        0.040744926780462265,
        0.00705938832834363,
        -0.06432346254587173,
        -0.010788151063024998,
        0.02683992311358452,
        -0.011463907547295094,
        -0.04873533174395561,
        -0.045123737305402756,
        -0.049049705266952515,
        -0.059807177633047104,
        -0.03290073946118355,
        0.017706893384456635,
        -0.02109380066394806,
        0.03474149852991104,
        0.03380611166357994,
        0.024706339463591576,
        -0.05839236453175545,
        -0.02194005437195301,
        -0.05472065135836601,
        -0.018600551411509514,
        0.009589212015271187,
        0.007751740515232086,
        0.06674978137016296,
        0.023034507408738136,
        -0.006495957262814045,
        0.05732927471399307,
        0.04539242386817932,
        0.06559940427541733,
        -0.05867961421608925,
        -0.04725582152605057,
        -0.0549699142575264,
        0.007996771484613419,
        0.004342456813901663,
        -0.04748590663075447,
        0.0499008409678936,
        0.029205195605754852,
        -0.05143893137574196,
        -0.06337562203407288,
        0.04987557977437973,
        0.05994955822825432
    ],
    [
        -0.05720629170536995,
        -0.024086618795990944,
        0.0045860763639211655,
        0.043128568679094315,
        0.025198766961693764,
        -0.02049952745437622,
        0.02992350235581398,
        -0.06153128296136856,
        0.02896438166499138,
        0.030165839940309525,
        0.010244414210319519,
        0.034283313900232315,
        -0.0061515397392213345,
        -0.05004098638892174,
        -0.06866618245840073,
        0.02937156707048416,
        -0.021788908168673515,
        0.06141115725040436,
        -0.060318246483802795,
        -0.043973159044981,
        -0.03413807600736618,
        0.05034409090876579,
        0.03255357965826988,
        -0.051708947867155075,
        0.05324827507138252,
        0.022723592817783356,
        0.05613723397254944,
        -0.025011874735355377,
        -0.0015726524870842695,
        0.011476937681436539,
        0.06767558306455612,
        -0.020622801035642624,
        -0.036828599870204926,
        -0.07050225883722305,
        0.04307486116886139,
        -0.0063060009852051735,
        -0.0640152171254158,
        -0.04874526709318161,
        -0.056373611092567444,
        -0.01643284223973751,
        0.04865013435482979,
        0.03305639699101448,
        0.03133697807788849,
        -0.062193069607019424,
        0.015578698366880417,
        0.06690485030412674,
        0.016184471547603607,
        0.04340321570634842,
        0.0664961040019989,
        0.05008105933666229,
        0.058039404451847076,
        0.05751466751098633,
        0.0017914907075464725,
        0.0523640401661396,
        0.004403230268508196,
        -0.05879387632012367,
        -0.028147198259830475,
        -0.03176196664571762,
        0.03046778030693531,
        0.032305192202329636,
        -0.05053120478987694,
        -0.0004772719112224877,
        -0.05093793570995331,
        0.06588219851255417,
        -0.025925330817699432,
        -0.030719898641109467,
        0.050822000950574875,
        -0.029638390988111496,
        -0.04707585647702217,
        -0.06687827408313751,
        -0.06819995492696762,
        -0.07282937318086624,
        0.049090541899204254,
        -0.04363478720188141,
        0.00816496554762125,
        0.0068778605200350285,
        0.015642277896404266,
        0.070344477891922,
        0.0003081974573433399,
        -0.014111588709056377,
        -0.012299642898142338,
        0.06674852967262268,
        -0.05088869482278824,
        0.05121701583266258,
        0.05887564271688461,
        0.050217341631650925,
        0.06517322361469269,
        -0.00831088051199913,
        0.007150914054363966,
        0.06543527543544769,
        -0.032515913248062134,
        0.034522078931331635,
        -0.06415880471467972,
        0.004608070477843285,
        -0.0007720100693404675,
        0.053844474256038666,
        -0.0279314536601305,
        -0.06658614426851273,
        -0.052997738122940063,
        0.004005346447229385,
        -0.0652061253786087,
        0.022273579612374306,
        0.0698975920677185,
        0.045436929911375046,
        0.05363817512989044,
        -0.06098094955086708,
        -0.012652747333049774,
        0.0387408509850502,
        -0.06007162854075432,
        0.06021663546562195,
        -0.052331555634737015,
        -0.04572592303156853,
        -0.03320390731096268,
        -0.03036261536180973,
        -0.00597235094755888,
        -0.053372085094451904,
        0.04806114360690117,
        -0.011663520708680153,
        0.06075069680809975,
        0.044516630470752716,
        -0.012918398715555668,
        0.06708724051713943,
        -0.0262004267424345,
        0.04021406173706055,
        -0.0628255158662796,
        -0.020323751494288445,
        -0.031502898782491684,
        -0.036501407623291016,
        0.055358365178108215,
        0.021539444103837013,
        0.001113830367103219,
        -0.04656802490353584,
        -0.04499335587024689,
        0.02791176550090313,
        -0.06133154779672623,
        -0.05563238635659218,
        0.015888376161456108,
        -0.016196103766560555,
        -0.059702690690755844,
        0.05786651745438576,
        0.06285436451435089,
        -0.009017172269523144,
        0.06223761662840843,
        -0.029049836099147797,
        0.06237547844648361,
        0.01914847642183304,
        0.07007375359535217,
        -0.02668989822268486,
        0.007207595277577639,
        0.0673302486538887,
        0.05456060543656349,
        -0.07094358652830124,
        -0.023124385625123978,
        -0.004042462445795536,
        0.02833619900047779,
        -0.04024037346243858,
        0.04473035782575607,
        0.028873426839709282,
        -0.03438672795891762,
        0.008485933765769005,
        0.06874483078718185,
        0.05073470622301102,
        0.05514158681035042,
        0.02350796014070511,
        0.02675056643784046,
        -0.009686899371445179,
        -0.04220447316765785,
        -0.014573991298675537,
        0.0026141435373574495,
        0.03377947583794594,
        -0.03250186890363693,
        0.028400396928191185,
        0.012914429418742657,
        -0.045487284660339355,
        -0.0045081875286996365,
        0.06867171823978424,
        -0.07080138474702835,
        0.011015262454748154,
        0.02836570329964161,
        -0.053158681839704514,
        0.03890036791563034,
        0.06868354976177216,
        0.033607419580221176,
        0.056283142417669296,
        0.015429758466780186,
        0.033627212047576904,
        -0.06515791267156601,
        0.06765728443861008,
        -0.06250397115945816,
        -0.04665721207857132,
        -0.03029116801917553,
        0.04909740388393402,
        -0.029480699449777603,
        -0.01094833854585886,
        -0.041879769414663315,
        0.012681573629379272,
        0.02577740140259266,
        -0.032878290861845016,
        -0.050491753965616226,
        0.023475684225559235,
        0.030620185658335686,
        -0.001205921988002956,
        0.019888432696461678,
        0.02997598797082901,
        -0.06555024534463882,
        0.06739291548728943,
        0.03740949183702469,
        -0.006354502867907286,
        -0.06399287283420563,
        0.019209688529372215,
        -0.04454689100384712,
        0.06379693001508713,
        0.001855989103205502,
        0.017421772703528404,
        0.012750158086419106,
        0.04008149355649948,
        0.04919954389333725,
        -0.0233626589179039,
        0.06508983671665192,
        -0.038490284234285355,
        -0.014688518829643726,
        0.027085280045866966,
        -0.05044694244861603,
        -0.05714177340269089,
        0.07046867161989212,
        -0.06192697957158089,
        -0.02948099561035633,
        0.04200329631567001,
        -0.015215970575809479,
        -0.06278691440820694,
        0.05901056155562401,
        -0.06754882633686066,
        -0.05976033955812454,
        0.029651742428541183,
        -0.048107556998729706,
        0.06916431337594986,
        0.06541294604539871,
        -0.03142667934298515,
        -0.048388510942459106,
        0.06648936867713928,
        0.019090406596660614,
        0.06252168118953705,
        -0.07075639814138412,
        0.027274494990706444,
        0.03709392994642258,
        0.0012637663166970015,
        -0.03908757492899895,
        -0.008939401246607304,
        -0.047158993780612946,
        -0.053343672305345535,
        -0.04593205079436302,
        -0.06995026767253876,
        -0.009832151234149933,
        -0.0642712414264679,
        -0.02161962352693081,
        0.061024684458971024,
        -0.0563819520175457,
        -0.04358954355120659,
        0.06293844431638718,
        0.013454746454954147,
        0.07195558398962021,
        -0.009419767186045647,
        0.007840699516236782,
        0.027557559311389923,
        -0.020248278975486755,
        0.047010354697704315,
        -0.03333400562405586,
        0.04299875721335411,
        -0.03754064068198204,
        -0.031136414036154747,
        0.0320495143532753,
        -0.0629667341709137,
        0.06556864827871323,
        -0.05957188084721565,
        -0.051200978457927704,
        0.015256861224770546,
        -0.05144362896680832,
        0.03340959921479225,
        -0.01849016733467579,
        -0.0271291546523571,
        -0.05994720757007599,
        -0.03274127468466759,
        -0.057454850524663925,
        0.06662456691265106,
        0.028451398015022278,
        -0.04629913717508316,
        0.03778649494051933,
        0.006384660955518484,
        -0.014608507975935936,
        0.05098186805844307,
        -0.038620445877313614,
        0.043512433767318726,
        -0.009014714509248734,
        0.05491390824317932,
        0.054368115961551666,
        -0.06297891587018967,
        0.0672372505068779,
        0.05791281536221504,
        -0.05313713848590851,
        0.043807581067085266,
        0.006127779837697744,
        0.05630349740386009,
        -0.0012404978042468429,
        0.0337933711707592,
        -0.020957674831151962,
        -0.01182549074292183,
        -0.04567784070968628,
        0.001808980363421142,
        0.04599783569574356,
        -0.07051705569028854,
        0.013244365341961384,
        -0.04998500272631645,
        -0.03815019503235817,
        0.06063617020845413,
        -0.027751591056585312,
        -0.061380453407764435,
        0.055950459092855453,
        -0.054800596088171005,
        0.017093131318688393,
        -0.05430857837200165,
        -0.057991884648799896,
        0.042197100818157196,
        0.022522035986185074,
        0.02217639610171318,
        0.02654511108994484,
        0.0158979669213295,
        -0.0199720598757267,
        -0.012264351360499859,
        -0.001014985959045589,
        0.015445559285581112,
        0.02841363474726677,
        0.005666199140250683,
        -0.05894653499126434,
        -0.061105381697416306,
        0.06718248873949051,
        0.013144786469638348,
        -0.029591523110866547,
        0.06317203491926193,
        -0.05475984141230583,
        0.027586903423070908,
        0.028142224997282028,
        0.0442657545208931,
        -0.059831321239471436,
        -0.06127944216132164,
        0.07257658988237381,
        -0.00990439485758543,
        0.018722612410783768,
        0.04493283852934837,
        -0.010473759844899178,
        0.061248235404491425,
        -0.05026226118206978,
        0.017917461693286896,
        -0.051416900008916855,
        -0.06238217651844025,
        -0.06296820938587189,
        0.06301412731409073,
        -0.05553697794675827,
        -0.004349926952272654,
        0.031995028257369995,
        -0.06748608499765396,
        0.05324014276266098,
        0.04415078088641167,
        -0.03585034981369972,
        0.016512714326381683,
        0.037639815360307693,
        -0.040033530443906784,
        0.0702805295586586,
        -0.03881769999861717,
        0.06010380759835243,
        -0.07111284881830215,
        -0.031703636050224304,
        -0.037157852202653885,
        0.06549996882677078,
        0.0513075590133667,
        0.028374025598168373,
        0.04479560628533363,
        -0.03901689127087593,
        0.02999735437333584,
        0.030407605692744255,
        0.04411129653453827,
        0.01629546657204628,
        -0.04196937754750252,
        -0.07058454304933548,
        0.03925703465938568,
        0.04283939674496651,
        0.019838813692331314,
        0.02361672930419445,
        -0.0685523971915245,
        -0.023933259770274162,
        -0.0019421197939664125,
        -0.07011138647794724,
        0.02085018716752529,
        -0.0025318257976323366,
        0.0031154160387814045,
        -0.03962257131934166,
        0.06581918150186539,
        0.055667538195848465,
        0.04595411568880081,
        0.02015388384461403,
        -0.06537465006113052,
        -0.036343254148960114,
        0.06020691990852356,
        0.06655795127153397,
        -0.011755955405533314,
        -0.0459265410900116,
        0.015635350719094276,
        0.021866701543331146,
        -0.03076309897005558,
        0.05315940082073212,
        -0.023581184446811676,
        0.030770957469940186,
        -0.06397875398397446,
        0.05892680212855339,
        0.02172277867794037,
        0.05330691486597061,
        -0.049518413841724396,
        -0.029634825885295868,
        0.05840754136443138,
        0.025257153436541557,
        0.035191554576158524,
        -0.008547862991690636,
        -0.03133616968989372,
        0.005929857958108187,
        -0.049108680337667465,
        0.05626560375094414,
        -0.008745566010475159,
        -0.01887219399213791,
        -0.03075483627617359,
        -0.045905038714408875,
        -0.05415498465299606,
        0.05649026483297348,
        0.0024196840822696686,
        -0.06784255802631378,
        -0.009198484942317009,
        0.030498260632157326,
        -0.05475557595491409,
        -0.04751474782824516,
        -0.018890347331762314,
        0.03153030201792717,
        0.0025203735567629337,
        0.048580270260572433,
        0.06426932662725449,
        0.049366217106580734,
        0.0602862648665905,
        0.05198565125465393,
        0.030115755274891853,
        0.06815493851900101,
        -0.04721099138259888,
        0.06426791101694107,
        -0.022587496787309647,
        -0.05748775973916054,
        -0.0067605674266815186,
        0.06764204055070877,
        0.020419634878635406,
        -0.012395460158586502,
        -0.05832391232252121,
        0.017035378143191338,
        -0.01132805086672306,
        -0.05306386947631836,
        0.06067105010151863,
        -0.03556830808520317,
        -0.006760630290955305,
        -0.06723178923130035,
        0.0005007621366530657,
        0.06426432728767395,
        0.07100430130958557,
        -0.048811040818691254,
        0.0596189871430397,
        -0.06789358705282211,
        -0.06686656922101974,
        0.03768440708518028,
        0.06418836861848831,
        0.0344611331820488,
        0.011705665849149227,
        -0.062778040766716,
        -0.008450009860098362,
        0.025013385340571404,
        -0.009845724329352379,
        -0.044614046812057495,
        -0.03908737376332283,
        -0.04531063511967659,
        -0.059778399765491486,
        -0.02620689757168293,
        0.019918357953429222,
        -0.030515626072883606,
        0.03137096017599106,
        0.02387060411274433,
        0.022934064269065857,
        -0.05635608732700348,
        -0.02695939689874649,
        -0.056276410818099976,
        -0.021741589531302452,
        0.021113228052854538,
        -0.008138678967952728,
        0.06766734272241592,
        0.02685583382844925,
        -0.01549084298312664,
        0.0620015487074852,
        0.04577288031578064,
        0.06470990180969238,
        -0.044534869492053986,
        -0.04862706735730171,
        -0.04671260714530945,
        -0.0014037651708349586,
        -0.01281312108039856,
        -0.04371665045619011,
        0.04726853221654892,
        0.01993202045559883,
        -0.0513366237282753,
        -0.060560572892427444,
        0.05206181854009628,
        0.06244902312755585
    ],
    [
        -0.061673976480960846,
        -0.028009634464979172,
        -0.02786538004875183,
        0.023484107106924057,
        0.03742048889398575,
        -0.03997625410556793,
        0.06290094554424286,
        -0.016985207796096802,
        -0.055997833609580994,
        0.019427921622991562,
        0.02782640978693962,
        0.05508044362068176,
        -0.043217893689870834,
        -0.042576756328344345,
        -0.06306571513414383,
        -0.028293922543525696,
        -0.05728960782289505,
        0.0579797588288784,
        -0.04368205741047859,
        -0.0270927045494318,
        -0.05832448601722717,
        0.0020579597912728786,
        0.05512037128210068,
        -0.03902193158864975,
        -0.008825656957924366,
        0.009242300875484943,
        -0.003798906458541751,
        0.05012713372707367,
        -0.05706984922289848,
        -0.025654280558228493,
        0.06226729229092598,
        0.04029787331819534,
        0.024156270548701286,
        -0.0627562403678894,
        0.023946989327669144,
        -0.03909638524055481,
        -0.05814855918288231,
        -0.05026289075613022,
        -0.05090998858213425,
        -0.0020219343714416027,
        0.04636580869555473,
        0.017136109992861748,
        0.05939333885908127,
        -0.04980671405792236,
        0.03708161413669586,
        0.014899082481861115,
        0.000057991783251054585,
        0.06251207739114761,
        0.06317631900310516,
        0.032256998121738434,
        0.05875147506594658,
        -0.009343476966023445,
        0.05649952590465546,
        0.0074480255134403706,
        -0.015038018114864826,
        -0.012595778331160545,
        -0.050881873816251755,
        -0.030377140268683434,
        -0.04801829531788826,
        0.046106256544589996,
        -0.06064099445939064,
        -0.022863678634166718,
        -0.060308054089546204,
        0.043528128415346146,
        0.04384511709213257,
        -0.03360103815793991,
        0.053200919181108475,
        -0.0495915487408638,
        0.03816751763224602,
        -0.06355644017457962,
        -0.05845089256763458,
        -0.06375811249017715,
        0.04283597320318222,
        -0.010255689732730389,
        -0.03192702680826187,
        0.028228608891367912,
        0.048043448477983475,
        0.06204458326101303,
        0.027961276471614838,
        -0.010891363956034184,
        -0.053641222417354584,
        0.02622295916080475,
        -0.051749762147665024,
        0.05560053884983063,
        0.046862658113241196,
        0.0491107702255249,
        -0.014504178427159786,
        0.024516718462109566,
        -0.02766421250998974,
        0.06304148584604263,
        -0.061930447816848755,
        0.006076505407691002,
        0.017375830560922623,
        -0.022058768197894096,
        0.0317804329097271,
        -0.027611559256911278,
        -0.035370003432035446,
        -0.06111767143011093,
        -0.061071913689374924,
        0.04109017550945282,
        -0.06225259602069855,
        -0.014316829852759838,
        0.06332974880933762,
        0.05355861410498619,
        0.05251563340425491,
        -0.0424840971827507,
        -0.03511640802025795,
        0.016522957012057304,
        -0.06265819817781448,
        0.027428550645709038,
        0.021370872855186462,
        -0.052334319800138474,
        -0.00957519095391035,
        -0.04855864867568016,
        0.048781879246234894,
        -0.06241662800312042,
        0.022483203560113907,
        -0.014951140619814396,
        -0.052680887281894684,
        -0.04899559170007706,
        0.0073873973451554775,
        0.02287798933684826,
        -0.060827940702438354,
        0.0564812608063221,
        -0.06083636358380318,
        0.036951810121536255,
        -0.014616209082305431,
        0.00831530336290598,
        0.0440271832048893,
        -0.010410325601696968,
        0.015919454395771027,
        -0.03753754496574402,
        -0.04252759367227554,
        0.0487777478992939,
        -0.05909402295947075,
        -0.054100655019283295,
        0.04277607053518295,
        -0.057514991611242294,
        -0.03898896276950836,
        0.06317783147096634,
        0.05354341119527817,
        0.01647353731095791,
        0.05928719416260719,
        -0.026678703725337982,
        0.01625600829720497,
        0.02013303153216839,
        0.05955284833908081,
        0.0013345367042347789,
        0.020213019102811813,
        0.05536695569753647,
        -0.02111874893307686,
        -0.06371933221817017,
        -0.05477910861372948,
        0.06100742891430855,
        -0.036549776792526245,
        -0.045851994305849075,
        0.060933396220207214,
        -0.004848077893257141,
        -0.0419992171227932,
        -0.053750310093164444,
        0.06301388889551163,
        0.026057422161102295,
        0.05838999152183533,
        0.005079822149127722,
        -0.05236326530575752,
        -0.054110780358314514,
        -0.04284407198429108,
        -0.04698070511221886,
        -0.016169974580407143,
        0.04697977006435394,
        -0.06043960899114609,
        0.035190824419260025,
        -0.04186327010393143,
        0.046998362988233566,
        -0.05440174788236618,
        0.04855368286371231,
        -0.06171450763940811,
        0.033090390264987946,
        0.02374601922929287,
        -0.0005333113367669284,
        0.055343955755233765,
        0.005841522943228483,
        0.06280852109193802,
        0.02476983703672886,
        -0.004465190228074789,
        0.048980310559272766,
        -0.05264349654316902,
        0.06107266992330551,
        -0.05191131681203842,
        -0.04026241973042488,
        -0.05664645507931709,
        0.048702385276556015,
        -0.04764402657747269,
        0.026062967255711555,
        -0.05835007503628731,
        0.012859519571065903,
        0.06278334558010101,
        -0.038831137120723724,
        -0.013310005888342857,
        0.04527289792895317,
        0.015558620914816856,
        0.027510249987244606,
        0.05069756880402565,
        0.04099121689796448,
        -0.0442785769701004,
        0.060461994260549545,
        0.013907079584896564,
        0.02458287961781025,
        -0.06227770447731018,
        -0.013362604193389416,
        -0.04337233677506447,
        0.06138745695352554,
        0.03013889119029045,
        0.0012478349963203073,
        0.033797312527894974,
        -0.02511829137802124,
        0.049646858125925064,
        -0.02994995191693306,
        0.061750177294015884,
        -0.05932777002453804,
        -0.031220123171806335,
        -0.005992674734443426,
        -0.00821479968726635,
        -0.06288637965917587,
        0.06353975832462311,
        -0.06337255239486694,
        -0.0024380688555538654,
        0.01661982759833336,
        -0.0493294857442379,
        -0.06172969937324524,
        0.02856111153960228,
        -0.05983569845557213,
        -0.06172773987054825,
        0.039544131606817245,
        -0.03650076687335968,
        0.02146998792886734,
        0.05688013508915901,
        -0.029731540009379387,
        0.000011716608241840731,
        0.06022613123059273,
        0.058279234915971756,
        0.026761041954159737,
        -0.050701625645160675,
        -0.009877795353531837,
        0.027394233271479607,
        0.01384519413113594,
        0.058088403195142746,
        -0.021348731592297554,
        -0.029847847297787666,
        -0.046846698969602585,
        -0.035768184810876846,
        -0.061316996812820435,
        -0.057604026049375534,
        -0.0576905831694603,
        0.05319155007600784,
        0.05595448613166809,
        -0.0324617438018322,
        -0.013940602540969849,
        0.062874436378479,
        0.036345064640045166,
        0.0630093514919281,
        0.04886173456907272,
        0.046035077422857285,
        0.0521969236433506,
        -0.025302018970251083,
        0.056780945509672165,
        -0.00015190722479019314,
        0.00831332802772522,
        -0.0019899089820683002,
        -0.04682396724820137,
        0.011289541609585285,
        -0.049650948494672775,
        -0.023236218839883804,
        -0.061858296394348145,
        -0.04705006629228592,
        0.022238969802856445,
        -0.05672626942396164,
        -0.01273375190794468,
        -0.016794688999652863,
        -0.04034190997481346,
        -0.0588211715221405,
        -0.014377888292074203,
        0.008822374045848846,
        0.057250190526247025,
        0.03887612745165825,
        -0.03282146528363228,
        0.053696293383836746,
        -0.05534708499908447,
        -0.06146208569407463,
        0.048851240426301956,
        -0.046721093356609344,
        0.030997823923826218,
        0.0243768822401762,
        0.024964703246951103,
        0.054929088801145554,
        -0.051016125828027725,
        0.06308973580598831,
        0.00978144258260727,
        -0.049764230847358704,
        0.0467325896024704,
        -0.05618877708911896,
        0.050310954451560974,
        0.03853412717580795,
        0.032479893416166306,
        0.03733227029442787,
        -0.05842714011669159,
        -0.028677459806203842,
        -0.028302742168307304,
        0.057322416454553604,
        -0.06288163363933563,
        -0.03047511726617813,
        -0.0424506776034832,
        -0.03505248203873634,
        0.062291789799928665,
        -0.054131027311086655,
        -0.057421691715717316,
        0.060286734253168106,
        0.022100748494267464,
        0.021504554897546768,
        -0.054100602865219116,
        -0.04414741322398186,
        0.05415235459804535,
        0.05811000615358353,
        0.007822208106517792,
        -0.021465009078383446,
        0.06315300613641739,
        0.042981747537851334,
        -0.06135033816099167,
        -0.0424453541636467,
        0.018074441701173782,
        0.047500234097242355,
        0.05059060826897621,
        -0.024120183661580086,
        -0.04461512342095375,
        0.06246206536889076,
        0.046222444623708725,
        -0.04124661535024643,
        0.05181180313229561,
        -0.053827185183763504,
        0.017297694459557533,
        0.003774822223931551,
        -0.0467752143740654,
        0.043220385909080505,
        -0.05773429572582245,
        0.06379756331443787,
        0.019307365640997887,
        0.009609261527657509,
        0.04820207133889198,
        -0.0020319195464253426,
        0.043119095265865326,
        -0.06255143880844116,
        0.009400956332683563,
        -0.044390492141246796,
        -0.06185236945748329,
        -0.06245574355125427,
        0.050923045724630356,
        -0.06174829602241516,
        -0.017985716462135315,
        -0.060724012553691864,
        -0.060152873396873474,
        0.05666867271065712,
        0.059749480336904526,
        -0.06268515437841415,
        -0.027487782761454582,
        0.029977958649396896,
        -0.0425715334713459,
        0.0633092150092125,
        -0.032545458525419235,
        0.05779284983873367,
        -0.0637613907456398,
        -0.04140177741646767,
        -0.06228630989789963,
        0.0623183511197567,
        0.029988516122102737,
        0.06058649346232414,
        0.0604051910340786,
        0.022416863590478897,
        0.005192579235881567,
        0.009286520071327686,
        0.007062670774757862,
        -0.022130250930786133,
        0.0618550106883049,
        -0.054329924285411835,
        0.009632415138185024,
        0.043014172464609146,
        -0.025184763595461845,
        0.02077525667846203,
        -0.06282663345336914,
        -0.05173521861433983,
        0.015919605270028114,
        -0.054961785674095154,
        0.04928194731473923,
        0.014147736132144928,
        0.04756281524896622,
        -0.02002570964396,
        0.06066498905420303,
        0.06090892478823662,
        0.042245157063007355,
        -0.049699414521455765,
        -0.06254396587610245,
        -0.030845750123262405,
        0.051451362669467926,
        0.0613795705139637,
        0.061062637716531754,
        -0.027511581778526306,
        0.04691141098737717,
        0.04990895465016365,
        -0.013074090704321861,
        0.0624154657125473,
        -0.04703360050916672,
        0.058981671929359436,
        -0.055190861225128174,
        0.057293426245450974,
        0.00769919715821743,
        0.05818115547299385,
        -0.014711395837366581,
        -0.01790286973118782,
        0.04594005271792412,
        0.014264492318034172,
        0.057259004563093185,
        -0.04455477371811867,
        -0.05863726884126663,
        -0.013405043631792068,
        -0.06233073025941849,
        0.03241175040602684,
        0.027890322729945183,
        -0.005471421871334314,
        -0.05270008370280266,
        -0.0633062869310379,
        -0.03782843053340912,
        0.041871488094329834,
        0.03211352601647377,
        0.01604231260716915,
        0.04073578491806984,
        0.00780601566657424,
        -0.04934846609830856,
        -0.06035447120666504,
        0.013013944029808044,
        0.042761992663145065,
        -0.03366914764046669,
        -0.05162066966295242,
        0.060835931450128555,
        0.05018894746899605,
        0.059117890894412994,
        -0.029209841042757034,
        0.03605513274669647,
        0.06257976591587067,
        -0.054352425038814545,
        0.05396467074751854,
        -0.06044422462582588,
        -0.05072907730937004,
        0.0315750427544117,
        0.06345080584287643,
        -0.012552695348858833,
        -0.018976561725139618,
        -0.0365641713142395,
        0.04967621713876724,
        -0.03265653923153877,
        -0.03821621835231781,
        0.06081371754407883,
        -0.015066316351294518,
        -0.05816816911101341,
        -0.056285809725522995,
        -0.053119584918022156,
        0.061808567494153976,
        0.0635451078414917,
        -0.060437411069869995,
        0.0619821734726429,
        -0.056923218071460724,
        -0.05962821841239929,
        0.05636841431260109,
        0.04130465164780617,
        0.0610751137137413,
        0.04676978662610054,
        0.05341216176748276,
        0.04768827185034752,
        -0.020763907581567764,
        -0.04324666038155556,
        -0.04588339477777481,
        -0.012542487122118473,
        -0.0469035729765892,
        -0.01589544489979744,
        -0.012058278545737267,
        0.03054160065948963,
        -0.030346708372235298,
        0.04423355683684349,
        -0.010260522365570068,
        -0.010984084568917751,
        -0.06243545934557915,
        -0.038101598620414734,
        -0.015853945165872574,
        -0.05459810420870781,
        0.051230158656835556,
        0.015345964580774307,
        0.06299174576997757,
        0.036487385630607605,
        -0.05990215390920639,
        0.028511082753539085,
        0.03602006286382675,
        0.012032002210617065,
        0.017036616802215576,
        -0.030256185680627823,
        -0.05176089331507683,
        0.04516204819083214,
        0.00466717267408967,
        0.0009239803766831756,
        -0.0034507326781749725,
        0.016514895483851433,
        -0.04134196415543556,
        -0.014193009585142136,
        0.062339890748262405,
        0.053314581513404846
    ],
    [
        0.00434120511636138,
        0.05309326946735382,
        0.05333569645881653,
        0.022374741733074188,
        -0.032736312597990036,
        -0.05236690118908882,
        0.05332382023334503,
        -0.05351097509264946,
        0.053322166204452515,
        0.04756971821188927,
        0.052406612783670425,
        -0.0005225306958891451,
        -0.05330910161137581,
        0.02137000486254692,
        -0.05346682295203209,
        0.053432006388902664,
        0.04704977199435234,
        0.04702389985322952,
        0.04093719646334648,
        0.04932823032140732,
        0.02118002623319626,
        0.04383007809519768,
        0.05206826329231262,
        0.05178194120526314,
        -0.03801605850458145,
        0.053230781108140945,
        0.04251430928707123,
        -0.030460504814982414,
        -0.05338563397526741,
        -0.023622330278158188,
        -0.0010648014722391963,
        0.046398695558309555,
        -0.05282878875732422,
        -0.053503382951021194,
        0.05263427272439003,
        0.044240932911634445,
        -0.05330904945731163,
        -0.04408971965312958,
        0.05156929790973663,
        0.008250637911260128,
        0.04671579226851463,
        0.040475375950336456,
        0.05071239545941353,
        0.0020393591839820147,
        0.03829110413789749,
        0.038351599127054214,
        0.01893710345029831,
        0.05320950597524643,
        0.05350207909941673,
        -0.021429168060421944,
        0.05328822135925293,
        0.03355054557323456,
        -0.05285921320319176,
        -0.046381887048482895,
        0.04452606663107872,
        -0.05275649577379227,
        -0.052281368523836136,
        -0.026589274406433105,
        -0.05349837988615036,
        -0.05016858130693436,
        -0.053494758903980255,
        0.04160815849900246,
        -0.05274396762251854,
        0.052259597927331924,
        -0.03216239809989929,
        0.0031707289163023233,
        -0.05350566282868385,
        -0.05142762139439583,
        0.053099364042282104,
        0.04353237524628639,
        0.03475353121757507,
        -0.05352574959397316,
        0.052302997559309006,
        0.05267252027988434,
        0.05353955924510956,
        -0.05039766803383827,
        0.0520331934094429,
        0.05354795604944229,
        0.0533783957362175,
        -0.0355176143348217,
        -0.0506274551153183,
        -0.023521097376942635,
        -0.0488671138882637,
        0.04587256535887718,
        -0.04959731549024582,
        0.044290538877248764,
        0.05055052787065506,
        -0.05290698632597923,
        -0.017218301072716713,
        -0.0005802388768643141,
        -0.03914154693484306,
        0.007138767745345831,
        -0.0535401813685894,
        0.04746130108833313,
        -0.04253523051738739,
        0.024876650422811508,
        -0.0366361066699028,
        -0.05312754586338997,
        -0.011952745728194714,
        -0.05197307839989662,
        -0.05354331061244011,
        0.05354747176170349,
        0.05346548557281494,
        0.050452135503292084,
        0.050159700214862823,
        -0.053275518119335175,
        -0.05275239422917366,
        0.05324346572160721,
        -0.05152615159749985,
        0.04883928969502449,
        0.05166112631559372,
        0.00810885801911354,
        -0.03273472562432289,
        -0.019940294325351715,
        0.050757911056280136,
        0.049164243042469025,
        0.014604146592319012,
        -0.05315788462758064,
        0.05328357219696045,
        -0.04798414185643196,
        -0.029833242297172546,
        0.03233194723725319,
        0.01656675525009632,
        -0.05307174474000931,
        -0.051709722727537155,
        -0.05300293490290642,
        0.010793386958539486,
        0.05344182997941971,
        0.04456415772438049,
        0.05120279639959335,
        0.00714598735794425,
        -0.05261991545557976,
        0.021544259041547775,
        0.051561739295721054,
        -0.04936785250902176,
        -0.05314426124095917,
        0.05343965068459511,
        -0.053345296531915665,
        -0.043783653527498245,
        0.04610878974199295,
        -0.03180965408682823,
        -0.053509991616010666,
        0.05113282427191734,
        0.048310864716768265,
        -0.05324346572160721,
        0.0499650202691555,
        0.026607519015669823,
        0.041642919182777405,
        0.012120322324335575,
        0.005049398634582758,
        0.04113096371293068,
        -0.05345159024000168,
        -0.01937692053616047,
        -0.0485406368970871,
        -0.047691185027360916,
        -0.05333535000681877,
        0.00047970382729545236,
        -0.05294511467218399,
        0.05205656215548515,
        -0.041069626808166504,
        0.016152139753103256,
        -0.019574251025915146,
        0.052967749536037445,
        0.02986956015229225,
        -0.050004519522190094,
        -0.033258505165576935,
        0.0496109202504158,
        -0.00123961444478482,
        0.04518909379839897,
        0.04573437198996544,
        -0.05345603823661804,
        0.051794834434986115,
        -0.05085209757089615,
        -0.04821712523698807,
        0.05230998247861862,
        0.048924703150987625,
        0.015650706365704536,
        -0.00864266138523817,
        0.0533265545964241,
        -0.05287988856434822,
        -0.05258917063474655,
        -0.021507693454623222,
        0.04877667874097824,
        -0.04798385500907898,
        0.05262576788663864,
        0.043607231229543686,
        -0.05239023640751839,
        0.05163520574569702,
        -0.049403999000787735,
        -0.026070764288306236,
        -0.05344352498650551,
        -0.041220180690288544,
        0.021214881911873817,
        -0.0076238978654146194,
        -0.052802279591560364,
        0.05289491266012192,
        0.004101616330444813,
        -0.001790523179806769,
        -0.0029936430510133505,
        0.05025386065244675,
        0.05328810587525368,
        0.04537243768572807,
        0.02510671690106392,
        -0.05107738822698593,
        0.0531889833509922,
        0.04237280786037445,
        0.05348927155137062,
        0.03309500962495804,
        -0.0534999817609787,
        -0.040701281279325485,
        -0.05063730478286743,
        0.05136524513363838,
        -0.048616811633110046,
        0.05188111960887909,
        0.01907309703528881,
        -0.05324934795498848,
        0.05305866524577141,
        0.051210954785346985,
        0.05354329198598862,
        -0.0217887032777071,
        0.053105175495147705,
        0.05261591449379921,
        0.02900136262178421,
        0.03678663447499275,
        0.049837298691272736,
        -0.04553723707795143,
        0.0523400716483593,
        0.04189196228981018,
        0.053543779999017715,
        -0.05343300849199295,
        -0.05340900644659996,
        -0.051974888890981674,
        -0.043711841106414795,
        0.04965963587164879,
        -0.05345086380839348,
        0.05353736877441406,
        0.05340592563152313,
        0.045886337757110596,
        -0.05060010403394699,
        0.05354781076312065,
        0.04401416331529617,
        0.04546722397208214,
        -0.05244772508740425,
        -0.005070862825959921,
        0.033596206456422806,
        -0.037019386887550354,
        -0.03320559114217758,
        0.05353403836488724,
        -0.053222425282001495,
        -0.053370628505945206,
        -0.05354032292962074,
        -0.05350470915436745,
        0.012985455803573132,
        -0.04665430635213852,
        -0.010922173038125038,
        0.05243925750255585,
        -0.007863418199121952,
        -0.03943312540650368,
        0.04680058732628822,
        -0.04151228070259094,
        -0.04507273808121681,
        -0.04912543669342995,
        -0.0519728884100914,
        0.05114658549427986,
        0.05056191608309746,
        0.05018435791134834,
        0.05336902290582657,
        0.03358893096446991,
        -0.050453733652830124,
        0.010158451274037361,
        0.011828775517642498,
        -0.05304683744907379,
        0.05345820635557175,
        -0.053504157811403275,
        0.014980347827076912,
        0.01159911323338747,
        -0.04916271194815636,
        -0.04505535215139389,
        -0.017752496525645256,
        -0.0495406836271286,
        -0.05353602021932602,
        -0.04122253879904747,
        -0.05125448852777481,
        -0.011700068600475788,
        0.052259162068367004,
        -0.0012012493098154664,
        0.047611743211746216,
        0.041257891803979874,
        0.04952961951494217,
        0.00594146316871047,
        -0.022825727239251137,
        0.026843005791306496,
        -0.05298230051994324,
        -0.029487082734704018,
        0.038577888160943985,
        -0.05281495675444603,
        0.053542595356702805,
        -0.003666769713163376,
        0.029708627611398697,
        0.053094085305929184,
        0.05297256261110306,
        0.04027460142970085,
        -0.0031117862090468407,
        0.05295127257704735,
        -0.005694085266441107,
        0.052081651985645294,
        -0.014096441678702831,
        0.053236473351716995,
        -0.03795350715517998,
        -0.053542837500572205,
        -0.03300080820918083,
        -0.05179264023900032,
        -0.004319609608501196,
        -0.0050504510290920734,
        -0.007435125764459372,
        -0.05222952738404274,
        -0.020908061414957047,
        -0.003269938286393881,
        0.053362470120191574,
        -0.03589465469121933,
        0.03656427562236786,
        -0.05254139006137848,
        0.05323134362697601,
        -0.02213239297270775,
        0.05348910391330719,
        -0.0329941064119339,
        0.04899824038147926,
        -0.0064951274544000626,
        0.030828488990664482,
        -0.05288166552782059,
        0.043972790241241455,
        -0.052559927105903625,
        0.0359317921102047,
        -0.04144204407930374,
        0.05279097706079483,
        0.051020730286836624,
        -0.05354001745581627,
        0.049042053520679474,
        -0.03710233047604561,
        -0.015326564200222492,
        -0.05220165103673935,
        0.018640732392668724,
        0.05354129523038864,
        -0.047213051468133926,
        0.05243567004799843,
        -0.01124845165759325,
        0.05104441940784454,
        0.05318894609808922,
        0.003651950741186738,
        0.051581889390945435,
        0.05352403596043587,
        -0.020932892337441444,
        0.05049608275294304,
        -0.051410526037216187,
        -0.0518462173640728,
        -0.005268664099276066,
        -0.05349872261285782,
        -0.047374751418828964,
        -0.05023582652211189,
        -0.04700708016753197,
        0.04974207654595375,
        -0.01920318976044655,
        0.030674872919917107,
        -0.027659635990858078,
        0.018885375931859016,
        -0.05292638763785362,
        0.053307175636291504,
        0.033795055001974106,
        -0.049680255353450775,
        -0.053435374051332474,
        -0.03923879191279411,
        -0.05299108847975731,
        0.050184059888124466,
        -0.034524548798799515,
        0.05224764719605446,
        0.05351555347442627,
        0.023788349702954292,
        0.032918188720941544,
        0.05270902067422867,
        0.05251834914088249,
        -0.044278740882873535,
        0.04640275985002518,
        -0.05339380353689194,
        0.053472455590963364,
        0.05340377241373062,
        -0.05067695677280426,
        0.052765361964702606,
        -0.05282406881451607,
        -0.05195236578583717,
        -0.0029250963125377893,
        -0.05346826836466789,
        -0.048124853521585464,
        -0.04561276733875275,
        -0.052003443241119385,
        -0.024523939937353134,
        0.05321561172604561,
        0.05348033457994461,
        -0.04932736977934837,
        -0.053376708179712296,
        -0.053400274366140366,
        0.008398722857236862,
        0.05343550816178322,
        -0.009416352957487106,
        0.046393465250730515,
        -0.05295722559094429,
        -0.049177609384059906,
        0.04437773674726486,
        -0.01745941862463951,
        0.047744929790496826,
        0.03863954544067383,
        -0.0519496314227581,
        -0.052262838929891586,
        0.04475470259785652,
        0.037229493260383606,
        0.05036340653896332,
        -0.0522942952811718,
        -0.03612089902162552,
        0.046005070209503174,
        0.052859123796224594,
        0.05350498855113983,
        0.051373887807130814,
        -0.05352405458688736,
        -0.04804743826389313,
        -0.053537819534540176,
        -0.026395734399557114,
        -0.026064110919833183,
        -0.051093004643917084,
        0.009240050800144672,
        -0.04008569195866585,
        -0.053056150674819946,
        0.021196313202381134,
        -0.05303323268890381,
        0.015459788963198662,
        -0.03802211955189705,
        0.009076441638171673,
        -0.053525976836681366,
        -0.05149391293525696,
        0.03592166677117348,
        0.04346494749188423,
        -0.016908809542655945,
        -0.0361371710896492,
        0.052694905549287796,
        0.03797616809606552,
        0.052712347358465195,
        0.05170978978276253,
        0.04032023623585701,
        0.052369337528944016,
        -0.048526331782341,
        0.027149571105837822,
        0.05009619519114494,
        0.05284374579787254,
        0.008980541490018368,
        -0.04934316873550415,
        0.05215313658118248,
        0.049413297325372696,
        -0.05159357935190201,
        -0.0526006780564785,
        -0.0330267995595932,
        0.04924197494983673,
        0.05210898071527481,
        0.05353640019893646,
        -0.05258733034133911,
        -0.041546780616045,
        0.051430460065603256,
        0.05137684568762779,
        0.05346566066145897,
        -0.05183608457446098,
        0.05266053229570389,
        -0.006752364803105593,
        -0.0530247688293457,
        0.05341712757945061,
        -0.03934159874916077,
        0.05322529375553131,
        0.04484323784708977,
        0.00015884228923823684,
        0.05164484307169914,
        -0.014729471877217293,
        0.00014412701420951635,
        -0.034471556544303894,
        -0.053390923887491226,
        0.04463217407464981,
        -0.05330616980791092,
        -0.0476372204720974,
        0.04647215083241463,
        -0.052854955196380615,
        0.052243564277887344,
        0.05280740186572075,
        0.04794792830944061,
        -0.05306268855929375,
        -0.05289539694786072,
        -0.048488207161426544,
        -0.015804853290319443,
        0.04162096604704857,
        0.051191546022892,
        0.02771143428981304,
        0.05114171281456947,
        -0.03358611464500427,
        0.045564260333776474,
        0.045880697667598724,
        0.053532637655735016,
        -0.05304133519530296,
        -0.05221610143780708,
        -0.027988512068986893,
        -0.023422876372933388,
        0.05225493386387825,
        0.042570456862449646,
        -0.005328445695340633,
        -0.03044193796813488,
        0.05305536836385727,
        -0.0506141223013401,
        0.05194137245416641,
        0.048197656869888306
    ],
    [
        -0.06163860484957695,
        0.04739080369472504,
        0.03417891636490822,
        0.025093160569667816,
        0.048746783286333084,
        0.06335848569869995,
        0.06364977359771729,
        -0.03392956033349037,
        0.040102701634168625,
        0.046849168837070465,
        0.062346428632736206,
        0.008860587142407894,
        -0.050898462533950806,
        -0.0520920567214489,
        -0.054187413305044174,
        -0.00548324640840292,
        -0.04099534451961517,
        0.0635092705488205,
        -0.036015577614307404,
        -0.06468097120523453,
        -0.03485903516411781,
        0.06162691488862038,
        0.06228141859173775,
        0.0369502454996109,
        0.05888352915644646,
        0.05046997591853142,
        0.03390864282846451,
        0.056572794914245605,
        -0.05503467470407486,
        0.009424262680113316,
        0.024222278967499733,
        0.01097105909138918,
        0.040640171617269516,
        -0.06321588158607483,
        0.04004534333944321,
        -0.016720829531550407,
        -0.061767857521772385,
        -0.03415270149707794,
        0.05599555745720863,
        0.059632573276758194,
        0.05402347818017006,
        0.04368865489959717,
        0.042742688208818436,
        -0.03707563504576683,
        0.06458701193332672,
        -0.010574988089501858,
        -0.027424275875091553,
        0.06167348474264145,
        0.05906064435839653,
        0.04808979108929634,
        -0.027709385380148888,
        -0.010922051034867764,
        -0.013501318171620369,
        0.041028473526239395,
        0.027960726991295815,
        0.0004616111982613802,
        -0.05044401437044144,
        0.055447209626436234,
        -0.04086687043309212,
        0.023157019168138504,
        -0.056093357503414154,
        0.009981390088796616,
        -0.037838105112314224,
        0.04309476539492607,
        -0.049760282039642334,
        -0.045971084386110306,
        0.018681557849049568,
        -0.06200747191905975,
        -0.0010853169951587915,
        -0.060671113431453705,
        -0.048440445214509964,
        -0.06080920621752739,
        0.06094815209507942,
        0.06327114999294281,
        -0.05378125607967377,
        -0.009656596928834915,
        0.02291681431233883,
        0.06398189812898636,
        0.06340635567903519,
        0.012661383487284184,
        -0.027527941390872,
        0.05118151381611824,
        -0.0621359646320343,
        0.013699105940759182,
        -0.05116792768239975,
        -0.010440477170050144,
        0.06145765632390976,
        0.06454654037952423,
        -0.046709924936294556,
        0.06214733421802521,
        -0.06340937316417694,
        -0.06061980128288269,
        -0.058476679027080536,
        -0.0554230660200119,
        0.02849668264389038,
        -0.040485840290784836,
        -0.015812309458851814,
        -0.038681760430336,
        -0.05815723538398743,
        0.02839481644332409,
        -0.060547761619091034,
        -0.03223457932472229,
        -0.00557131739333272,
        0.016714053228497505,
        0.053933169692754745,
        -0.05993090569972992,
        -0.0512729175388813,
        0.028791218996047974,
        0.06242586672306061,
        -0.028603214770555496,
        -0.04905613511800766,
        -0.06257785856723785,
        -0.04970128834247589,
        -0.039769791066646576,
        0.026240622624754906,
        -0.05619043484330177,
        -0.052113354206085205,
        -0.05275486782193184,
        -0.04918995127081871,
        0.02942495420575142,
        -0.036062974482774734,
        -0.03774607926607132,
        0.017628170549869537,
        -0.002060576807707548,
        -0.057626571506261826,
        0.05548781529068947,
        -0.060591839253902435,
        0.05087275803089142,
        0.05723542720079422,
        0.030260756611824036,
        -0.028190748766064644,
        -0.025742199271917343,
        -0.015626104548573494,
        0.053429510444402695,
        -0.04342713952064514,
        -0.05080119147896767,
        -0.02050369791686535,
        -0.06378285586833954,
        -0.04150620847940445,
        0.01845410466194153,
        -0.0016536203911527991,
        0.0009892217349261045,
        0.04335080087184906,
        0.053155139088630676,
        -0.059427861124277115,
        -0.06427758932113647,
        0.057929400354623795,
        -0.05631541833281517,
        -0.026987455785274506,
        -0.0027586277574300766,
        -0.029623810201883316,
        -0.04231875389814377,
        -0.048304542899131775,
        -0.02902594953775406,
        -0.0509926937520504,
        -0.05540245398879051,
        0.05472042039036751,
        0.045664232224226,
        -0.013681948184967041,
        0.015589834190905094,
        0.0646798387169838,
        -0.032623663544654846,
        0.042291559278964996,
        -0.027234181761741638,
        -0.04120273143053055,
        0.00002318427686986979,
        -0.059038370847702026,
        -0.041608698666095734,
        -0.04116915911436081,
        0.007556048221886158,
        -0.02937597781419754,
        0.03370751440525055,
        -0.06428717076778412,
        0.00882653146982193,
        -0.02415861375629902,
        0.042398206889629364,
        -0.052229803055524826,
        0.004419352393597364,
        0.05060067027807236,
        -0.04545270651578903,
        0.03349531069397926,
        0.06453963369131088,
        0.05876738950610161,
        0.03153375908732414,
        0.045570116490125656,
        0.0028305319137871265,
        0.009000802412629128,
        0.05723901093006134,
        -0.018016234040260315,
        -0.04620159789919853,
        -0.06024741008877754,
        0.01702157035470009,
        0.017283828929066658,
        -0.005827631801366806,
        0.05855301767587662,
        0.048783592879772186,
        0.05833795294165611,
        -0.06471279263496399,
        -0.004956874065101147,
        0.03555356338620186,
        0.05483293905854225,
        -0.012310091406106949,
        0.047909416258335114,
        -0.050151027739048004,
        -0.04021559655666351,
        0.03267988562583923,
        0.012714973650872707,
        -0.014397422783076763,
        -0.006199561525136232,
        0.054710257798433304,
        -0.05686398595571518,
        0.03651787340641022,
        0.033063627779483795,
        0.04780569672584534,
        0.03983340039849281,
        -0.030165711417794228,
        -0.018771260976791382,
        -0.04989764094352722,
        0.05516504868865013,
        -0.06265174597501755,
        0.051231253892183304,
        0.040460553020238876,
        0.0603577084839344,
        -0.05260950326919556,
        0.05876867100596428,
        -0.05961275473237038,
        -0.020367832854390144,
        -0.007173965685069561,
        -0.058628231287002563,
        -0.05941508710384369,
        -0.05495321750640869,
        -0.04107385501265526,
        -0.062409136444330215,
        0.023617759346961975,
        -0.022856134921312332,
        -0.021456211805343628,
        0.05226666480302811,
        -0.04353642836213112,
        0.03747955337166786,
        0.05706464871764183,
        0.008512868545949459,
        0.054653991013765335,
        -0.009347287006676197,
        -0.016157712787389755,
        0.06077359989285469,
        0.007721681613475084,
        -0.056325942277908325,
        -0.02451249584555626,
        0.015754809603095055,
        -0.02359233796596527,
        -0.03933652490377426,
        -0.05081609636545181,
        -0.046799685806035995,
        -0.06061892956495285,
        0.06222015991806984,
        0.06417792290449142,
        -0.03689190745353699,
        -0.027541423216462135,
        0.024854041635990143,
        0.012052028439939022,
        0.06450822204351425,
        -0.024937910959124565,
        0.03453869745135307,
        0.06148838251829147,
        -0.0591154508292675,
        0.06217551603913307,
        0.010019228793680668,
        0.022001780569553375,
        0.05789828673005104,
        0.015218913555145264,
        0.02524658665060997,
        -0.06398284435272217,
        0.044162116944789886,
        -0.0624210387468338,
        0.022877221927046776,
        0.036842599511146545,
        -0.04628641903400421,
        -0.028299087658524513,
        -0.03808044642210007,
        -0.04431476071476936,
        -0.06393509358167648,
        0.0625963881611824,
        -0.030906058847904205,
        -0.012081721797585487,
        0.06280403584241867,
        -0.062374696135520935,
        0.002619620878249407,
        0.032280221581459045,
        -0.043837495148181915,
        0.043474458158016205,
        -0.06374519318342209,
        0.009917477145791054,
        0.06103241816163063,
        -0.044927649199962616,
        0.005676185246556997,
        -0.05876108631491661,
        0.0616232231259346,
        0.05758533626794815,
        -0.01982218027114868,
        0.05800175666809082,
        -0.0025242557749152184,
        0.04092257469892502,
        -0.04539623484015465,
        0.03467324748635292,
        -0.044710464775562286,
        0.0007076074252836406,
        -0.040399711579084396,
        -0.04506693780422211,
        0.05105732008814812,
        -0.04927047714591026,
        0.058668214827775955,
        -0.05317250266671181,
        -0.034816909581422806,
        0.032481517642736435,
        -0.060025203973054886,
        -0.05792880430817604,
        0.008267246186733246,
        -0.05574890598654747,
        0.003883508499711752,
        -0.05530984327197075,
        -0.030197756364941597,
        0.060803670436143875,
        0.05562422424554825,
        -0.05785829573869705,
        0.05280810222029686,
        0.06253928691148758,
        0.04605843126773834,
        -0.0012226371327415109,
        -0.013291099108755589,
        -0.05306430533528328,
        -0.014795491471886635,
        0.060889605432748795,
        -0.046158723533153534,
        -0.056589819490909576,
        0.05385815352201462,
        -0.00520296860486269,
        -0.005677574779838324,
        0.06383244693279266,
        -0.058875229209661484,
        0.04980521649122238,
        0.022735264152288437,
        -0.01573812961578369,
        -0.02079993486404419,
        -0.05966814607381821,
        0.06196003407239914,
        0.011869974434375763,
        0.027558419853448868,
        0.03788105025887489,
        -0.059735435992479324,
        0.061115652322769165,
        -0.050135888159275055,
        0.03881276771426201,
        -0.03501307964324951,
        -0.046267181634902954,
        -0.05758524686098099,
        0.044431209564208984,
        -0.060750920325517654,
        -0.04309029504656792,
        -0.05663516744971275,
        -0.05292174592614174,
        0.03916361555457115,
        0.02616887167096138,
        -0.047975871711969376,
        -0.03240577504038811,
        -0.03404734656214714,
        -0.054193343967199326,
        0.06413985788822174,
        0.03047100640833378,
        0.04262968897819519,
        -0.06444241106510162,
        -0.0007408569217659533,
        -0.03924106806516647,
        0.03490185737609863,
        -0.02902204729616642,
        0.0359075590968132,
        0.055403418838977814,
        0.03932920843362808,
        0.06471851468086243,
        0.018006660044193268,
        -0.03564871475100517,
        0.04077025502920151,
        -0.02593054249882698,
        -0.0608074776828289,
        -0.02020103856921196,
        0.06346169859170914,
        -0.055845703929662704,
        -0.015098804607987404,
        -0.05958801880478859,
        -0.008167705498635769,
        -0.04219445213675499,
        -0.03418557718396187,
        0.03745443746447563,
        0.016427015885710716,
        -0.037266794592142105,
        -0.042855314910411835,
        0.061110179871320724,
        0.05410010740160942,
        -0.058877356350421906,
        -0.05354313179850578,
        -0.06396599858999252,
        0.011265363544225693,
        -0.028253495693206787,
        0.05785093829035759,
        -0.03908492997288704,
        -0.051418401300907135,
        0.04514308646321297,
        0.04428455978631973,
        0.0076379599049687386,
        0.04619196802377701,
        -0.04965917766094208,
        0.024240922182798386,
        -0.03610154986381531,
        0.005646288860589266,
        -0.03846219554543495,
        0.05464594438672066,
        -0.01523050107061863,
        -0.04892707243561745,
        -0.06338588148355484,
        0.04337240755558014,
        0.040165696293115616,
        0.013480029068887234,
        -0.05275965854525566,
        -0.05646616965532303,
        -0.062365077435970306,
        0.0448598675429821,
        0.034712839871644974,
        -0.03590715304017067,
        -0.012091701850295067,
        -0.056614916771650314,
        -0.011078321374952793,
        0.03559470921754837,
        -0.022378819063305855,
        0.05723636597394943,
        0.04029123857617378,
        0.013811115175485611,
        -0.02196037955582142,
        0.031858477741479874,
        0.01874910295009613,
        0.031802948564291,
        -0.05188281089067459,
        -0.023498544469475746,
        -0.010872355662286282,
        0.06325234472751617,
        0.04430609568953514,
        -0.0587763674557209,
        0.029429307207465172,
        0.0643831193447113,
        -0.05266846343874931,
        0.05164581537246704,
        -0.06178011745214462,
        -0.04726722463965416,
        0.03701590746641159,
        0.014564958401024342,
        -0.06008137762546539,
        0.02130941115319729,
        -0.05573967471718788,
        -0.002039822516962886,
        -0.0031880245078355074,
        0.03123977966606617,
        0.04313099384307861,
        -0.029582664370536804,
        -0.052011314779520035,
        -0.031270548701286316,
        -0.052393704652786255,
        0.06209288164973259,
        0.06399695575237274,
        -0.060661282390356064,
        0.013187237083911896,
        -0.05956429988145828,
        -0.06401453167200089,
        0.0023768290411680937,
        0.02216215245425701,
        0.055781036615371704,
        -0.022726651281118393,
        -0.013469318859279156,
        0.0103570856153965,
        0.0440487414598465,
        0.04970360919833183,
        0.007029061671346426,
        0.013377638533711433,
        -0.045441798865795135,
        -0.03195977210998535,
        0.03350537642836571,
        0.05520075559616089,
        0.06395339220762253,
        -0.0289060790091753,
        0.05452139675617218,
        0.03198755905032158,
        -0.04492092877626419,
        -0.024910245090723038,
        -0.021001385524868965,
        -0.038826096802949905,
        0.056338295340538025,
        0.026659229770302773,
        0.03850940242409706,
        0.0201715100556612,
        -0.03833089768886566,
        0.004497210960835218,
        0.05446880683302879,
        0.055996134877204895,
        -0.0638282522559166,
        0.028862975537776947,
        -0.04684191942214966,
        0.034270916134119034,
        0.06087283417582512,
        0.040367770940065384,
        0.06414724141359329,
        0.03492990881204605,
        -0.06066124141216278,
        -0.026585064828395844,
        0.06333806365728378,
        0.011258530430495739
    ],
    [
        0.05373212695121765,
        -0.05964558199048042,
        0.01453718263655901,
        0.04123577103018761,
        -0.04805774614214897,
        -0.04595834016799927,
        -0.05646985024213791,
        -0.04565032571554184,
        0.04716605693101883,
        0.04297361895442009,
        -0.014493749476969242,
        0.031471386551856995,
        -0.055781036615371704,
        -0.050652723759412766,
        -0.048979271203279495,
        0.056399572640657425,
        0.04229268431663513,
        0.05112146958708763,
        0.03496556729078293,
        -0.05494222417473793,
        0.040617261081933975,
        0.043993961066007614,
        0.042290326207876205,
        0.052287474274635315,
        -0.010513363406062126,
        0.055573802441358566,
        0.04163571819663048,
        -0.057531796395778656,
        -0.03254419565200806,
        0.060048509389162064,
        0.010887444019317627,
        0.0532548762857914,
        -0.040593042969703674,
        -0.038351044058799744,
        0.019149107858538628,
        -0.021302584558725357,
        -0.054815150797367096,
        -0.05070501193404198,
        -0.01709035411477089,
        -0.059728704392910004,
        0.03628406673669815,
        0.007526801899075508,
        0.045298270881175995,
        0.018485354259610176,
        0.047623369842767715,
        -0.03242374584078789,
        -0.025297772139310837,
        0.060569167137145996,
        0.05638108029961586,
        -0.024517670273780823,
        0.060034241527318954,
        0.056656789034605026,
        -0.043454792350530624,
        -0.023377906531095505,
        -0.055895011872053146,
        0.04456552118062973,
        0.03799572214484215,
        -0.02176993153989315,
        -0.0256214402616024,
        0.015520903281867504,
        -0.0576796680688858,
        -0.04284236580133438,
        -0.0500510111451149,
        0.0007317085983231664,
        0.032820895314216614,
        -0.052783604711294174,
        -0.054663386195898056,
        0.010694507509469986,
        -0.012661424465477467,
        0.009304638020694256,
        -0.0444776825606823,
        0.009779877960681915,
        0.05900125578045845,
        0.0025686235167086124,
        0.059829261153936386,
        -0.04968024790287018,
        -0.05482709780335426,
        0.060615189373493195,
        -0.043981075286865234,
        0.05170799046754837,
        -0.012690931558609009,
        0.0596936009824276,
        -0.05015399307012558,
        0.05666273832321167,
        -0.05998200550675392,
        0.048899296671152115,
        0.05407878756523132,
        -0.03780654817819595,
        -0.04108856990933418,
        0.05059441179037094,
        -0.044193509966135025,
        -0.0095509123057127,
        -0.05301514267921448,
        0.012071245349943638,
        -0.05566221848130226,
        0.03563770651817322,
        0.030899472534656525,
        -0.05869565159082413,
        -0.052781928330659866,
        0.04041396453976631,
        -0.058928992599248886,
        0.05976782739162445,
        0.06047974154353142,
        0.025155937299132347,
        0.05408856272697449,
        0.009203347377479076,
        -0.02589792013168335,
        0.01848462224006653,
        0.05578678846359253,
        -0.057970475405454636,
        0.036272864788770676,
        0.03804196044802666,
        0.025849871337413788,
        0.054566655308008194,
        0.006304020993411541,
        -0.007260623853653669,
        0.005519210826605558,
        0.05844483524560928,
        0.05995588004589081,
        -0.029742462560534477,
        0.04090062156319618,
        -0.04026206210255623,
        0.025533465668559074,
        -0.0600026398897171,
        -0.05849076434969902,
        -0.02142602950334549,
        0.019202522933483124,
        -0.045522283762693405,
        0.054096583276987076,
        0.04715818166732788,
        0.05991889536380768,
        -0.04878808930516243,
        -0.046829864382743835,
        0.052457910031080246,
        -0.05153824761509895,
        -0.005639169365167618,
        0.04188329726457596,
        -0.010734962299466133,
        -0.05571923777461052,
        0.04648293927311897,
        -0.0016036544693633914,
        -0.059062518179416656,
        0.057432789355516434,
        0.055147092789411545,
        0.03649050369858742,
        -0.041361551731824875,
        -0.01280274149030447,
        -0.057208966463804245,
        -0.042535919696092606,
        -0.01937633939087391,
        -0.043946947902441025,
        -0.059988539665937424,
        -0.030588718131184578,
        0.05504132807254791,
        0.042824238538742065,
        -0.05938366800546646,
        -0.045746877789497375,
        0.004104631021618843,
        0.03142520412802696,
        0.011983645148575306,
        0.04887691140174866,
        -0.059793803840875626,
        0.058889854699373245,
        0.05126119777560234,
        -0.034569937735795975,
        0.059984318912029266,
        -0.05245086923241615,
        -0.03750097379088402,
        -0.04546959325671196,
        0.00684165395796299,
        -0.024771634489297867,
        0.05280332267284393,
        -0.016638515517115593,
        -0.056027669459581375,
        0.032796625047922134,
        -0.05592571943998337,
        0.0009349295287393034,
        -0.002999152522534132,
        0.03529046103358269,
        -0.05783229321241379,
        -0.03274175152182579,
        0.05790292099118233,
        0.02689293399453163,
        -0.034455589950084686,
        0.052183665335178375,
        0.04067673534154892,
        -0.055007725954055786,
        0.057203132659196854,
        -0.0552641935646534,
        -0.0434822216629982,
        -0.04145864397287369,
        0.03814929723739624,
        0.058894507586956024,
        0.03807733952999115,
        0.004319675266742706,
        -0.010156157426536083,
        -0.009698528796434402,
        -0.0025672041811048985,
        -0.04166386276483536,
        -0.0010482331272214651,
        0.056909363716840744,
        0.015025203116238117,
        -0.05214868113398552,
        -0.05788688361644745,
        -0.0578371062874794,
        0.01580183394253254,
        0.059842124581336975,
        0.031882964074611664,
        -0.05499672517180443,
        0.052187032997608185,
        -0.044856131076812744,
        0.046900153160095215,
        0.053031228482723236,
        0.05998817831277847,
        -0.04567459598183632,
        -0.023930110037326813,
        -0.03765777498483658,
        -0.03925948217511177,
        0.06035766005516052,
        0.01090969331562519,
        0.04789746552705765,
        0.05793182551860809,
        0.04394304007291794,
        0.05087922140955925,
        0.0605996735394001,
        0.01313516590744257,
        0.01753709837794304,
        -0.041959505528211594,
        0.05246153473854065,
        -0.056670188903808594,
        0.014704064466059208,
        -0.05711357295513153,
        -0.05295206233859062,
        0.04861265420913696,
        -0.009058814495801926,
        0.059141721576452255,
        0.03666134178638458,
        0.03705453500151634,
        0.053908687084913254,
        0.06058470904827118,
        -0.014076088555157185,
        -0.05419604480266571,
        -0.06060265004634857,
        -0.05552690848708153,
        0.05691125988960266,
        -0.01184840314090252,
        -0.05680230259895325,
        -0.059655435383319855,
        0.051056887954473495,
        0.011106263846158981,
        -0.02810685895383358,
        -0.059410955756902695,
        -0.044684845954179764,
        -0.05359966307878494,
        -0.05969705060124397,
        0.02717590518295765,
        0.05993487685918808,
        0.020140422508120537,
        0.05811573565006256,
        -0.005522020626813173,
        -0.014952926896512508,
        -0.049870360642671585,
        0.040039852261543274,
        0.019056562334299088,
        -0.05665963143110275,
        0.039746545255184174,
        0.05564697086811066,
        0.0493667833507061,
        -0.04887372627854347,
        -0.04255145415663719,
        0.01676303893327713,
        -0.05736793577671051,
        0.05789486691355705,
        -0.009432835504412651,
        -0.05932736024260521,
        0.06031719595193863,
        -0.0539342500269413,
        -0.02661711536347866,
        0.049861520528793335,
        -0.05473353713750839,
        0.05928057059645653,
        0.058259088546037674,
        -0.054337117820978165,
        -0.036329567432403564,
        -0.0591915138065815,
        0.029615797102451324,
        0.03477507457137108,
        0.046139247715473175,
        0.05973535776138306,
        0.052809737622737885,
        -0.05794330686330795,
        -0.03238474950194359,
        0.02752661518752575,
        0.019840944558382034,
        0.060338493436574936,
        -0.05737048760056496,
        0.04745396971702576,
        -0.046204447746276855,
        -0.028645750135183334,
        0.048567358404397964,
        -0.02721666730940342,
        -0.019452905282378197,
        -0.03769884258508682,
        0.031743843108415604,
        -0.04926816374063492,
        0.012425282970070839,
        -0.007344329729676247,
        0.034098390489816666,
        -0.013334759511053562,
        0.03805805742740631,
        -0.02896447852253914,
        -0.012388892471790314,
        0.054854121059179306,
        0.04228772222995758,
        0.05773568153381348,
        -0.03365145996212959,
        -0.04106772318482399,
        -0.05783059075474739,
        0.03353061527013779,
        0.022190598770976067,
        0.058822136372327805,
        0.004819653928279877,
        0.02206846885383129,
        0.03485400229692459,
        0.02471838891506195,
        -0.05945420637726784,
        -0.014087826944887638,
        0.014361094683408737,
        -0.04646864905953407,
        -0.06025640666484833,
        0.040072422474622726,
        -0.05618959665298462,
        -0.06027949973940849,
        0.03229836747050285,
        0.05904397368431091,
        -0.0477169044315815,
        0.054618578404188156,
        -0.012487527914345264,
        -0.002373859053477645,
        0.06029628962278366,
        0.020213734358549118,
        0.025191906839609146,
        -0.036063022911548615,
        0.050478652119636536,
        0.0064505659975111485,
        0.013540937565267086,
        0.0499342605471611,
        0.057836730033159256,
        -0.038825832307338715,
        0.05199781432747841,
        0.009074274450540543,
        -0.05279483273625374,
        0.053464699536561966,
        -0.06016590818762779,
        -0.0307029839605093,
        0.027044760063290596,
        -0.05708114057779312,
        -0.05724676325917244,
        -0.04937997832894325,
        -0.04858392849564552,
        0.047156695276498795,
        0.04747955501079559,
        -0.05793851986527443,
        -0.05942277982831001,
        -0.03504147753119469,
        -0.058989018201828,
        0.06024077907204628,
        -0.04039325192570686,
        -0.060496602207422256,
        -0.05941149592399597,
        -0.02540273219347,
        0.04325947165489197,
        -0.02019265666604042,
        -0.013979056850075722,
        0.06000465899705887,
        0.01338566280901432,
        -0.04624968394637108,
        0.05207943916320801,
        0.05904426798224449,
        0.03756078705191612,
        -0.01890231855213642,
        -0.04962102323770523,
        -0.05707499757409096,
        0.0008368526468984783,
        0.05016191676259041,
        -0.05086669325828552,
        -0.04911825805902481,
        -0.05918244272470474,
        -0.057755451649427414,
        -0.021151022985577583,
        -0.05956684798002243,
        0.0024839872494339943,
        0.014300670474767685,
        -0.057622890919446945,
        0.025035962462425232,
        0.05938241630792618,
        0.05497654527425766,
        -0.040158409625291824,
        -0.05902836471796036,
        -0.018305974081158638,
        0.001722498331218958,
        0.06030965968966484,
        -0.04365858435630798,
        -0.04278164729475975,
        -0.058715224266052246,
        0.05904707312583923,
        -0.02269984968006611,
        -0.05940236151218414,
        0.05360477790236473,
        -0.019762376323342323,
        -0.057507168501615524,
        -0.05341681092977524,
        0.0424184612929821,
        -0.04919211566448212,
        0.060320645570755005,
        -0.05910436436533928,
        0.05828586965799332,
        0.05173247680068016,
        -0.03363623842597008,
        0.010956344194710255,
        0.058072805404663086,
        0.04970475286245346,
        0.014625194482505322,
        -0.06031741946935654,
        -0.049241047352552414,
        0.04645625874400139,
        0.009970003738999367,
        -0.01367125939577818,
        -0.03629855439066887,
        -0.05824381858110428,
        -0.04649222642183304,
        0.04388716071844101,
        -0.057553041726350784,
        -0.031247934326529503,
        0.05094639211893082,
        -0.05703602731227875,
        -0.05908993259072304,
        -0.007093743421137333,
        -0.01192968338727951,
        -0.03424132615327835,
        0.04244290664792061,
        0.05656108632683754,
        0.02170061133801937,
        0.05949479341506958,
        0.055710308253765106,
        -0.05010499805212021,
        0.06001035496592522,
        0.009513501077890396,
        0.014488259330391884,
        0.03718361258506775,
        -0.002539249835535884,
        0.05884767696261406,
        0.029359085485339165,
        0.0583188571035862,
        -0.05909863859415054,
        -0.05628105252981186,
        0.05908203125,
        -0.03565729409456253,
        -0.0006797267124056816,
        0.04951206594705582,
        0.0039698719047009945,
        -0.05651623755693436,
        -0.00898672640323639,
        0.03948122635483742,
        0.06025596335530281,
        0.05994061008095741,
        -0.05651819705963135,
        0.060502778738737106,
        0.03356528282165527,
        -0.04198981449007988,
        0.029959557577967644,
        0.014599150978028774,
        0.05690113827586174,
        0.006979847326874733,
        -0.059507135301828384,
        0.010573088191449642,
        0.04972849413752556,
        0.04647960141301155,
        -0.052066870033741,
        0.01730928383767605,
        0.04450913146138191,
        0.03811842203140259,
        -0.03928250074386597,
        -0.008410572074353695,
        -0.029698610305786133,
        0.03185206279158592,
        -0.056682538241147995,
        0.05229854956269264,
        -0.050644829869270325,
        -0.0047372994013130665,
        -0.02992008812725544,
        0.028542781248688698,
        0.03365873917937279,
        -0.04522916302084923,
        -0.05309668928384781,
        0.027250371873378754,
        0.01479965541511774,
        0.058173634111881256,
        0.0477595292031765,
        0.060078226029872894,
        0.05398716777563095,
        0.022968558594584465,
        -0.0287497378885746,
        0.05547138303518295,
        0.019304649904370308,
        -0.016766458749771118,
        0.06000561639666557,
        -0.04694867134094238,
        -0.030985277146100998,
        -0.0574055053293705,
        0.04324830695986748,
        0.03700238838791847
    ],
    [
        0.03659569099545479,
        0.04950845241546631,
        0.04656589403748512,
        0.021144485101103783,
        -0.05100588873028755,
        -0.03806270658969879,
        0.05460765212774277,
        -0.05382300168275833,
        0.05394395440816879,
        0.0376761332154274,
        0.05317435413599014,
        0.048158951103687286,
        -0.05184502899646759,
        -0.05248691141605377,
        -0.05420134216547012,
        -0.05206464231014252,
        0.020111961290240288,
        0.05263959988951683,
        0.012544147670269012,
        -0.05027642846107483,
        0.04968750476837158,
        0.05435702204704285,
        0.0505567230284214,
        0.022521039471030235,
        0.029020410031080246,
        0.04154191538691521,
        0.04970908537507057,
        -0.05409139767289162,
        0.01808922551572323,
        0.047169025987386703,
        0.04633788391947746,
        0.0548076331615448,
        0.05302215367555618,
        -0.05033380538225174,
        0.05073367804288864,
        -0.048224564641714096,
        -0.052503518760204315,
        0.02003403939306736,
        0.050979483872652054,
        0.04069620370864868,
        -0.052866678684949875,
        0.042728815227746964,
        0.036124031990766525,
        -0.04244419187307358,
        0.051997262984514236,
        0.03612114116549492,
        0.03131704777479172,
        0.05468698590993881,
        0.05466422811150551,
        0.04809914156794548,
        0.05454114452004433,
        0.039607126265764236,
        -0.04447386413812637,
        0.04589573293924332,
        0.02075587958097458,
        0.04686591774225235,
        -0.0106849679723382,
        -0.020903943106532097,
        -0.033656347543001175,
        -0.0532301664352417,
        -0.05439325049519539,
        -0.028219738975167274,
        -0.05343525856733322,
        0.054294660687446594,
        0.015507842414081097,
        -0.0547187477350235,
        -0.05279748514294624,
        -0.0420023612678051,
        -0.0393717996776104,
        0.02051546424627304,
        -0.012745525687932968,
        -0.05316613242030144,
        0.05487743392586708,
        0.05488906800746918,
        -0.049419090151786804,
        -0.052973341196775436,
        -0.009531485848128796,
        0.05483179911971092,
        -0.05235901102423668,
        0.035804007202386856,
        -0.04177488386631012,
        0.0548386424779892,
        -0.05215015262365341,
        0.03968130424618721,
        -0.04679713025689125,
        0.051931049674749374,
        0.054708268493413925,
        0.02633875422179699,
        -0.02300390787422657,
        0.05488207936286926,
        -0.05030667781829834,
        -0.04151564836502075,
        -0.05491222068667412,
        -0.046001870185136795,
        -0.05006900057196617,
        0.030490588396787643,
        -0.05105772987008095,
        -0.05357140302658081,
        -0.03200465813279152,
        -0.04679425060749054,
        -0.05476883053779602,
        0.04937360808253288,
        0.05456818267703056,
        0.005617865826934576,
        0.05468496307730675,
        -0.048055458813905716,
        -0.01676356978714466,
        0.04497383162379265,
        0.05464906990528107,
        -0.0002551896614022553,
        -0.01153020653873682,
        0.05375503748655319,
        0.0023032149765640497,
        -0.05372297018766403,
        0.05193913355469704,
        -0.032392557710409164,
        0.05078061670064926,
        -0.022912321612238884,
        0.05484415963292122,
        -0.0380982905626297,
        0.042351316660642624,
        -0.008149359375238419,
        0.048298221081495285,
        -0.05444084480404854,
        -0.05341476947069168,
        -0.013082141987979412,
        -0.046178460121154785,
        0.04517033323645592,
        0.052707523107528687,
        0.050775542855262756,
        0.03505866974592209,
        -0.050430696457624435,
        -0.05382430925965309,
        0.054074790328741074,
        -0.052877672016620636,
        -0.05217571556568146,
        0.0545465461909771,
        -0.05280547961592674,
        -0.054477259516716,
        0.05304964631795883,
        0.04104148596525192,
        -0.03278183937072754,
        0.04365864023566246,
        0.05289652943611145,
        -0.01153553370386362,
        0.03319195285439491,
        -0.013221347704529762,
        -0.025286145508289337,
        -0.05251341685652733,
        -0.04483535885810852,
        0.007505789864808321,
        -0.0546865314245224,
        -0.053265657275915146,
        0.014435448683798313,
        -0.042859457433223724,
        -0.0537601038813591,
        -0.05376656353473663,
        -0.04755008593201637,
        0.05201120302081108,
        -0.01603369414806366,
        0.024816961959004402,
        -0.006886822637170553,
        0.05307785049080849,
        -0.03363635390996933,
        0.044165924191474915,
        0.05456101894378662,
        -0.05430791154503822,
        -0.005247613415122032,
        -0.04354718327522278,
        0.044873982667922974,
        -0.05206827446818352,
        0.05462675914168358,
        0.030037203803658485,
        -0.05154042690992355,
        0.05291321128606796,
        0.01870102249085903,
        0.05320150405168533,
        -0.002727946499362588,
        0.046425797045230865,
        -0.054297395050525665,
        -0.023475635796785355,
        0.054868049919605255,
        0.04811989888548851,
        0.04839354008436203,
        -0.04645663499832153,
        0.05376986786723137,
        -0.04447656869888306,
        0.05407075211405754,
        -0.021386831998825073,
        -0.04969651624560356,
        -0.0491066537797451,
        0.027838459238409996,
        0.05341962352395058,
        -0.0012978093000128865,
        -0.037992849946022034,
        -0.037470314651727676,
        -0.00216941861435771,
        -0.05477475747466087,
        -0.05124276131391525,
        0.0265290979295969,
        0.05360749736428261,
        0.054797857999801636,
        0.031146638095378876,
        -0.037908412516117096,
        -0.015684520825743675,
        0.033332373946905136,
        0.05220358446240425,
        0.05477044731378555,
        -0.05306224524974823,
        0.046157486736774445,
        -0.03964514285326004,
        0.05277128890156746,
        0.054810620844364166,
        0.054451294243335724,
        0.053735699504613876,
        -0.05451517924666405,
        -0.020965518429875374,
        -0.04027342423796654,
        0.05461884289979935,
        -0.0027168216183781624,
        0.010487277992069721,
        0.054863493889570236,
        -0.04390815645456314,
        0.04863251373171806,
        0.05409909784793854,
        -0.05390061438083649,
        -0.018855828791856766,
        0.03425511345267296,
        0.05390413478016853,
        -0.05375955253839493,
        0.03463330864906311,
        -0.053192757070064545,
        0.03964048996567726,
        0.053428974002599716,
        -0.004957618657499552,
        0.053782131522893906,
        0.04968877136707306,
        0.026237713173031807,
        -0.03716278821229935,
        0.05486421287059784,
        0.03292359411716461,
        0.028278516605496407,
        -0.05491260811686516,
        -0.01682606339454651,
        0.01883046142756939,
        -0.04538654536008835,
        -0.05488833412528038,
        -0.05268362909555435,
        -0.05436578765511513,
        -0.032392363995313644,
        -0.053002241998910904,
        -0.05340651050209999,
        -0.054637324064970016,
        -0.052808117121458054,
        -0.0066538224928081036,
        0.05470035970211029,
        0.05363088846206665,
        0.03596899285912514,
        0.051333654671907425,
        0.005186375230550766,
        0.05485393851995468,
        0.01928877644240856,
        0.04381748288869858,
        0.05132355913519859,
        -0.05426318943500519,
        0.05457175895571709,
        0.04117198288440704,
        -0.050155311822891235,
        0.03205820918083191,
        0.05086689069867134,
        0.04423251748085022,
        -0.0540737621486187,
        0.05397433042526245,
        -0.05432477220892906,
        -0.04701364040374756,
        0.029369257390499115,
        -0.051944300532341,
        -0.049936991184949875,
        0.05188893899321556,
        -0.05467405542731285,
        -0.05201653391122818,
        0.050610750913619995,
        -0.04528803378343582,
        -0.051214031875133514,
        -0.05148802325129509,
        -0.004843371454626322,
        0.044125307351350784,
        0.024653738364577293,
        0.014465267769992352,
        0.00010282064613420516,
        -0.05437428876757622,
        0.050106924027204514,
        0.03851061686873436,
        0.04130500927567482,
        0.05375528335571289,
        -0.050747379660606384,
        0.05447012931108475,
        0.03173305094242096,
        0.04191248491406441,
        0.053954966366291046,
        -0.04902787506580353,
        0.03306123614311218,
        -0.006901658605784178,
        -0.03479020297527313,
        -0.0018442088039591908,
        -0.03709829971194267,
        0.020916055887937546,
        -0.041882071644067764,
        0.05041151121258736,
        -0.04406731203198433,
        0.023337624967098236,
        -0.04082841798663139,
        -0.048500679433345795,
        0.015538378618657589,
        0.04372192919254303,
        -0.05417679622769356,
        -0.030497366562485695,
        -0.052770670503377914,
        0.052124254405498505,
        -0.040871888399124146,
        0.05472473427653313,
        -0.002236544154584408,
        0.04517311975359917,
        -0.010566052049398422,
        0.04376465082168579,
        0.041020799428224564,
        0.05361149087548256,
        -0.04915434867143631,
        0.05146783962845802,
        -0.05306597426533699,
        0.05356330797076225,
        -0.03837495669722557,
        -0.04839634150266647,
        -0.016543887555599213,
        0.053610727190971375,
        -0.04674113914370537,
        -0.046968936920166016,
        0.027078915387392044,
        0.02552098222076893,
        -0.009577058255672455,
        -0.03278641030192375,
        0.022568820044398308,
        0.049400463700294495,
        0.05441708862781525,
        -0.017998982220888138,
        0.04251769930124283,
        0.047741878777742386,
        0.054092083126306534,
        -0.05424029380083084,
        0.04850839450955391,
        -0.024929534643888474,
        -0.03207015618681908,
        0.03792592138051987,
        -0.05433925241231918,
        -0.04290898144245148,
        0.04662041738629341,
        -0.053116992115974426,
        -0.05434098094701767,
        -0.04932689666748047,
        -0.03342952951788902,
        0.045882854610681534,
        0.05374530330300331,
        -0.05324404686689377,
        -0.048099491745233536,
        -0.050548575818538666,
        -0.054561443626880646,
        0.054800018668174744,
        -0.027070099487900734,
        -0.01824275404214859,
        -0.04855934903025627,
        -0.05182116851210594,
        -0.008603817783296108,
        -0.04828447476029396,
        -0.05046109855175018,
        0.0481482595205307,
        0.014007365331053734,
        0.05465824529528618,
        -0.03416844829916954,
        0.05458388477563858,
        -0.03275010734796524,
        0.011975632980465889,
        -0.048250265419483185,
        -0.05198502913117409,
        0.051636748015880585,
        0.05422092229127884,
        -0.05000792816281319,
        -0.012964651919901371,
        -0.05432889983057976,
        -0.011715560220181942,
        0.01468479260802269,
        -0.054906390607357025,
        0.04273049905896187,
        0.03397037461400032,
        0.02009515091776848,
        -0.05391691252589226,
        0.05058058351278305,
        -0.004630872048437595,
        -0.04767942801117897,
        -0.05465156212449074,
        -0.05369139835238457,
        -0.04677741974592209,
        0.05387674644589424,
        0.05429207161068916,
        -0.05219127982854843,
        -0.05440094321966171,
        0.05461057275533676,
        0.01756911538541317,
        0.0025303533766418695,
        0.05429617688059807,
        0.041109900921583176,
        -0.035463228821754456,
        -0.0546957366168499,
        0.04439898580312729,
        0.04977302998304367,
        0.04990636929869652,
        -0.050189126282930374,
        0.010715124197304249,
        0.0018818695098161697,
        0.05261341854929924,
        0.05396949127316475,
        0.05478254333138466,
        -0.05399245023727417,
        -0.008418165147304535,
        -0.054900724440813065,
        -0.012753668241202831,
        0.010960453189909458,
        -0.04100587218999863,
        -0.05313250049948692,
        -0.047220975160598755,
        -0.05389455705881119,
        -0.04949435964226723,
        0.025620944797992706,
        0.03333456441760063,
        -0.010139772668480873,
        -0.04601336270570755,
        -0.0534280464053154,
        -0.04021431505680084,
        0.05489238724112511,
        -0.015386606566607952,
        -0.04749826714396477,
        -0.022402916103601456,
        0.05222724750638008,
        0.05489499866962433,
        0.054014336317777634,
        0.04918374866247177,
        0.048682522028684616,
        0.05447300150990486,
        -0.05448506399989128,
        0.03958036005496979,
        0.02564297243952751,
        0.054908331483602524,
        0.04144449532032013,
        -0.05373363569378853,
        0.05481775104999542,
        -0.05359528213739395,
        -0.051298584789037704,
        0.020757030695676804,
        -0.0536893866956234,
        0.05362747982144356,
        0.038885295391082764,
        0.022172823548316956,
        -0.05446701496839523,
        0.004076687153428793,
        -0.027859747409820557,
        0.0535733662545681,
        0.05420535057783127,
        -0.05265483632683754,
        0.054472826421260834,
        0.044284090399742126,
        -0.05276855453848839,
        0.028394801542162895,
        0.044658780097961426,
        0.04498926177620888,
        -0.030692290514707565,
        -0.05482058227062225,
        0.03825310617685318,
        0.03185965120792389,
        0.053644996136426926,
        -0.05280119925737381,
        0.018124183639883995,
        -0.015165493823587894,
        -0.008555248379707336,
        0.05117917060852051,
        0.04369646683335304,
        0.0052544474601745605,
        0.03975272551178932,
        0.05321904644370079,
        0.04027675837278366,
        -0.051962025463581085,
        -0.038894664496183395,
        -0.050459109246730804,
        0.041544582694768906,
        0.05354694277048111,
        0.05440782755613327,
        0.04655108600854874,
        0.04855593293905258,
        0.053715337067842484,
        0.05469098314642906,
        0.04691402241587639,
        0.054633453488349915,
        -0.049766797572374344,
        0.005483564455062151,
        -0.03752846270799637,
        0.05407749488949776,
        0.0543338879942894,
        0.009960412979125977,
        0.05438951775431633,
        -0.02452482469379902,
        0.026495587080717087,
        0.018953965976834297,
        0.05303960666060448,
        -0.041561271995306015
    ],
    [
        0.005916115362197161,
        -0.04505931958556175,
        0.05168900638818741,
        -0.006294783670455217,
        0.013960195705294609,
        -0.040450189262628555,
        0.05299973115324974,
        -0.05309540405869484,
        0.05301329493522644,
        -0.007364449556916952,
        0.05256562680006027,
        0.025508277118206024,
        -0.05279235914349556,
        -0.04965289682149887,
        -0.053044650703668594,
        -0.05044006183743477,
        0.04283196106553078,
        0.05306921526789665,
        0.0502266101539135,
        0.03932417184114456,
        0.0457550473511219,
        -0.028106916695833206,
        0.04678727686405182,
        -0.012437517754733562,
        0.052649375051259995,
        0.048345666378736496,
        0.053093988448381424,
        -0.018786277621984482,
        -0.050120897591114044,
        -0.047364991158246994,
        -0.00790198240429163,
        0.05243613198399544,
        0.05205194279551506,
        -0.05309190973639488,
        0.05295362323522568,
        -0.0516468770802021,
        -0.052315033972263336,
        -0.04090313985943794,
        0.011335855349898338,
        0.04661500081419945,
        0.05233493447303772,
        0.05122305452823639,
        0.04367860034108162,
        -0.050937678664922714,
        0.045132748782634735,
        0.052829496562480927,
        0.04997893050312996,
        0.053076598793268204,
        0.05309723690152168,
        -0.0067287711426615715,
        0.05309512838721275,
        0.037417758256196976,
        -0.05279827490448952,
        0.041590698063373566,
        -0.040701303631067276,
        0.05255353823304176,
        -0.037347953766584396,
        0.024094220250844955,
        -0.02923187054693699,
        -0.05091943219304085,
        -0.052862364798784256,
        0.0017711009131744504,
        -0.05273672565817833,
        0.052209965884685516,
        0.03614235669374466,
        -0.02864467352628708,
        -0.052147027105093,
        -0.003396518062800169,
        0.04315659776329994,
        0.03908146917819977,
        -0.009793566539883614,
        -0.04946637526154518,
        0.05307846516370773,
        0.05306171625852585,
        0.05309669300913811,
        -0.021380484104156494,
        0.0366336964070797,
        0.05308731645345688,
        -0.02158023975789547,
        -0.03865204006433487,
        -0.052018068730831146,
        0.05019354447722435,
        -0.05306664854288101,
        0.050884854048490524,
        0.012307370081543922,
        0.05144263058900833,
        0.053079936653375626,
        -0.0355546735227108,
        -0.05103830248117447,
        0.05136917158961296,
        -0.053081490099430084,
        -0.004393524955958128,
        -0.05309158191084862,
        0.002540792804211378,
        -0.05171414837241173,
        0.043351199477910995,
        0.05040167644619942,
        -0.05290033295750618,
        -0.04870373755693436,
        -0.05061078816652298,
        -0.05309277027845383,
        0.05197351053357124,
        0.05307132005691528,
        0.051584888249635696,
        0.04840252175927162,
        -0.04732329025864601,
        -0.05241299793124199,
        0.053028885275125504,
        0.05106322839856148,
        -0.0411096028983593,
        -0.052832577377557755,
        0.03197450190782547,
        -0.05216493830084801,
        -0.052436552941799164,
        0.04348860681056976,
        0.028589345514774323,
        -0.0463123582303524,
        -0.052856240421533585,
        0.053023647516965866,
        -0.052944306284189224,
        0.04205651581287384,
        0.0036463052965700626,
        0.0519060380756855,
        -0.05309738963842392,
        -0.04912089556455612,
        0.030956806614995003,
        -0.04898698627948761,
        -0.0473787859082222,
        0.052655626088380814,
        0.04425124078989029,
        -0.047680169343948364,
        -0.05226587504148483,
        -0.052814990282058716,
        0.05271657183766365,
        -0.05275140330195427,
        -0.04835756868124008,
        0.05308074876666069,
        -0.007553227245807648,
        -0.05299460515379906,
        0.052961885929107666,
        -0.019232675433158875,
        -0.05234350636601448,
        0.014237163588404655,
        0.0518227219581604,
        -0.015332193113863468,
        0.04668625816702843,
        0.005857318639755249,
        -0.05175777152180672,
        -0.052150171250104904,
        0.039522331207990646,
        -0.029968801885843277,
        -0.053074441850185394,
        0.0233559999614954,
        -0.038797080516815186,
        0.0039481064304709435,
        -0.053075484931468964,
        -0.05309394747018814,
        -0.05009087547659874,
        0.053046587854623795,
        0.010571719147264957,
        -0.039420150220394135,
        0.05280514806509018,
        0.05225720629096031,
        0.04692981764674187,
        -0.023168930783867836,
        0.04128094017505646,
        0.03322590887546539,
        -0.052620865404605865,
        0.001052854466252029,
        0.034606385976076126,
        -0.01561884954571724,
        0.052680518478155136,
        -0.0055595277808606625,
        -0.04938061162829399,
        0.05290775001049042,
        0.04235171154141426,
        0.04244724288582802,
        -0.04166629537940025,
        0.052192963659763336,
        -0.0495678186416626,
        -0.017091775313019753,
        0.053096313029527664,
        0.0527656264603138,
        0.05125996470451355,
        -0.03914635255932808,
        -0.0060166530311107635,
        -0.04820786789059639,
        0.05214499309659004,
        0.024032728746533394,
        -0.0529680959880352,
        -0.053003545850515366,
        -0.00572450365871191,
        0.053098924458026886,
        0.044344279915094376,
        -0.05144438520073891,
        0.0406516008079052,
        0.05014257878065109,
        -0.038273923099040985,
        -0.053081102669239044,
        0.05142712593078613,
        0.0530548095703125,
        0.05044249817728996,
        -0.04086444899439812,
        -0.052901625633239746,
        0.052785031497478485,
        0.049093663692474365,
        0.05018484219908714,
        0.052469201385974884,
        -0.05284860357642174,
        0.052868690341711044,
        -0.05261779949069023,
        0.052406538277864456,
        0.052903272211551666,
        0.05309765785932541,
        -0.030886515974998474,
        -0.04291773959994316,
        0.04289145767688751,
        0.018005838617682457,
        0.05296754091978073,
        0.015697801485657692,
        -0.016300160437822342,
        0.05158926546573639,
        -0.039252035319805145,
        0.038449060171842575,
        0.053037263453006744,
        -0.050801146775484085,
        0.05228026956319809,
        -0.04724904149770737,
        0.05304973945021629,
        -0.05271952599287033,
        -0.0037283040583133698,
        -0.05294904112815857,
        -0.03212856873869896,
        0.052159324288368225,
        -0.05127601698040962,
        0.05308288708329201,
        0.051614951342344284,
        0.013558187521994114,
        0.038155846297740936,
        0.053094442933797836,
        -0.00002621400562929921,
        0.052104610949754715,
        -0.05309909209609032,
        0.049611255526542664,
        0.05161166191101074,
        -0.05075889825820923,
        -0.0530979260802269,
        0.040127407759428024,
        -0.028381457552313805,
        -0.05148132145404816,
        0.03800877183675766,
        -0.05272233486175537,
        -0.019291426986455917,
        -0.051470741629600525,
        -0.03453496843576431,
        0.05307987332344055,
        -0.03620688617229462,
        0.052667129784822464,
        0.02700573392212391,
        0.0003947436925955117,
        0.052950453013181686,
        -0.047722700983285904,
        0.05102717876434326,
        0.05303667485713959,
        0.008382752537727356,
        0.0529879629611969,
        0.04881688579916954,
        0.04298969358205795,
        0.05287235975265503,
        0.020835375413298607,
        0.052607592195272446,
        -0.05304422602057457,
        0.05305834859609604,
        -0.05264350026845932,
        -0.05089113861322403,
        0.045800019055604935,
        -0.049998730421066284,
        -0.00041277334094047546,
        0.009938017465174198,
        -0.05252775549888611,
        -0.052962616086006165,
        0.053012602031230927,
        0.026156889274716377,
        0.0004604846762958914,
        0.04929562658071518,
        0.05003029480576515,
        -0.04744567349553108,
        0.03323148563504219,
        0.05148540064692497,
        0.048101965337991714,
        -0.008049058727920055,
        -0.046731602400541306,
        -0.03393441066145897,
        -0.011549870483577251,
        -0.03464784100651741,
        -0.052138566970825195,
        0.0530969612300396,
        0.050702858716249466,
        0.050766706466674805,
        0.05308403819799423,
        0.053060468286275864,
        0.013370559550821781,
        0.01387276966124773,
        0.05283351615071297,
        -0.03142796829342842,
        -0.027933383360505104,
        0.05302932858467102,
        -0.04482897371053696,
        0.03983542323112488,
        -0.04357476532459259,
        0.02152952179312706,
        -0.04380977153778076,
        0.03126337751746178,
        0.004583962727338076,
        0.016593394801020622,
        -0.05285906791687012,
        0.014037265442311764,
        -0.030820168554782867,
        0.05153653025627136,
        0.04274575784802437,
        0.05302116647362709,
        -0.05282556265592575,
        0.05117323622107506,
        -0.053076520562171936,
        0.053045399487018585,
        0.002922996412962675,
        0.03847210109233856,
        -0.05220139026641846,
        0.053060755133628845,
        -0.05302024632692337,
        0.0428597591817379,
        0.02915511094033718,
        -0.0523720346391201,
        -0.04765387624502182,
        0.05286552757024765,
        0.038952600210905075,
        -0.04151282459497452,
        0.04787708818912506,
        0.021955637261271477,
        -0.004114661365747452,
        0.012966763228178024,
        -0.04019254818558693,
        -0.007653010543435812,
        -0.03606007248163223,
        0.03225615993142128,
        0.032412268221378326,
        0.052037835121154785,
        0.052746497094631195,
        -0.042076464742422104,
        0.048745881766080856,
        0.03125511482357979,
        -0.044571518898010254,
        0.0514061376452446,
        -0.052791446447372437,
        -0.029874544590711594,
        0.03733136132359505,
        -0.04836302995681763,
        -0.04423714056611061,
        -0.052543509751558304,
        -0.05306777358055115,
        0.05052712559700012,
        0.050647176802158356,
        -0.049179524183273315,
        -0.00006134112481959164,
        -0.05115698650479317,
        -0.051106616854667664,
        0.05309812352061272,
        -0.03856894373893738,
        0.04991450905799866,
        -0.05175235494971275,
        -0.0529923178255558,
        -0.04826550558209419,
        -0.020661858841776848,
        0.04510142654180527,
        -0.02685057558119297,
        0.0385492667555809,
        0.05301687493920326,
        0.010359659790992737,
        0.05305526778101921,
        0.04961010813713074,
        0.030329223722219467,
        0.01693858578801155,
        -0.052786026149988174,
        0.052871473133563995,
        -0.03267187625169754,
        -0.05166631564497948,
        -0.048266954720020294,
        -0.04219523444771767,
        -0.04642430320382118,
        -0.048147618770599365,
        -0.053098518401384354,
        -0.025448236614465714,
        -0.05054207518696785,
        -0.02029767632484436,
        -0.04772970825433731,
        0.052087973803281784,
        0.053036630153656006,
        -0.047113098204135895,
        -0.052947577089071274,
        -0.05295568332076073,
        -0.047470320016145706,
        0.053093139082193375,
        0.03984951227903366,
        -0.03654016926884651,
        -0.05296686664223671,
        0.05063788965344429,
        0.032115254551172256,
        -0.04111175239086151,
        0.05309801176190376,
        -0.035775963217020035,
        -0.001202915096655488,
        -0.04298630729317665,
        0.019677497446537018,
        0.0230950228869915,
        0.04563513025641441,
        -0.0526958592236042,
        -0.04024292156100273,
        0.001446326496079564,
        0.053069982677698135,
        0.025287045165896416,
        0.05290062725543976,
        -0.05227046087384224,
        0.026980876922607422,
        -0.05309867486357689,
        0.05178248882293701,
        0.015894759446382523,
        -0.02739039435982704,
        -0.04266052693128586,
        -0.05070871114730835,
        -0.05301477760076523,
        0.03670857101678848,
        0.049102429300546646,
        -0.014275461435317993,
        0.024633463472127914,
        -0.05270630866289139,
        -0.05206901952624321,
        -0.027792522683739662,
        0.05109748616814613,
        0.04650808498263359,
        -0.05287088081240654,
        -0.04287351667881012,
        0.053090307861566544,
        0.0530969463288784,
        0.05278187617659569,
        0.053066790103912354,
        0.05285605788230896,
        0.049616195261478424,
        -0.05230548232793808,
        0.030984818935394287,
        0.05298340693116188,
        0.051015328615903854,
        0.05279040336608887,
        -0.013840642757713795,
        0.05309620127081871,
        0.05209456756711006,
        -0.05143940448760986,
        -0.019785678014159203,
        -0.0509253591299057,
        0.05305544286966324,
        0.05309709161520004,
        0.04998196288943291,
        -0.053045183420181274,
        0.028003644198179245,
        -0.052192527800798416,
        0.05181535705924034,
        0.05309038981795311,
        -0.053035903722047806,
        0.052973221987485886,
        -0.04215245693922043,
        -0.05299330875277519,
        0.052056148648262024,
        0.04205618053674698,
        0.052643775939941406,
        0.022658802568912506,
        -0.05309461057186127,
        -0.017833644524216652,
        0.04907414689660072,
        0.05300790071487427,
        -0.05163365602493286,
        -0.027566449716687202,
        -0.014079912565648556,
        -0.04450454190373421,
        0.05187634006142616,
        0.04840480536222458,
        -0.044304728507995605,
        0.050340887159109116,
        0.052531398832798004,
        0.016589775681495667,
        -0.05253451690077782,
        0.003484437009319663,
        -0.025992034003138542,
        0.03013760782778263,
        0.046825096011161804,
        0.014307072386145592,
        0.051848404109478,
        -0.03501534089446068,
        -0.015038182958960533,
        0.05179503187537193,
        0.05219636484980583,
        0.05308437719941139,
        -0.0530780628323555,
        0.033688291907310486,
        -0.05176481977105141,
        0.0016699250554665923,
        -0.026578038930892944,
        0.05209885537624359,
        0.05294409394264221,
        -0.03344663232564926,
        0.0437820665538311,
        -0.04522647708654404,
        0.01331070251762867,
        -0.04463203623890877
    ],
    [
        0.04414575174450874,
        0.040284547954797745,
        0.049301933497190475,
        0.03732190653681755,
        -0.021883293986320496,
        -0.0337974987924099,
        0.026475854218006134,
        -0.05116743966937065,
        0.047381263226270676,
        0.04937705397605896,
        0.05106938257813454,
        0.05102991685271263,
        -0.05116482451558113,
        -0.04948005825281143,
        -0.04965878278017044,
        0.04719364270567894,
        0.04937911406159401,
        0.05104801803827286,
        0.022921545431017876,
        0.0027709812857210636,
        0.0510636642575264,
        0.051138557493686676,
        0.051042694598436356,
        0.03763899952173233,
        0.05116388946771622,
        0.04707767069339752,
        0.05116977170109749,
        0.05070457234978676,
        -0.051107585430145264,
        -0.05008506402373314,
        -0.048332300037145615,
        0.050825174897909164,
        0.051161058247089386,
        -0.050976864993572235,
        0.036491647362709045,
        -0.05116066336631775,
        -0.050632212311029434,
        0.050590332597494125,
        0.05029105022549629,
        -0.04789076745510101,
        -0.010877063497900963,
        0.05096607282757759,
        0.03986746445298195,
        0.026931066066026688,
        0.03227249160408974,
        0.007001929916441441,
        0.0472184419631958,
        0.051171671599149704,
        0.04552830755710602,
        0.04209331423044205,
        0.051110852509737015,
        0.05117018520832062,
        -0.050935667008161545,
        -0.020713770762085915,
        0.043565716594457626,
        0.0382002629339695,
        -0.05105777457356453,
        -0.004668733105063438,
        -0.050597529858350754,
        -0.051091309636831284,
        -0.05107078701257706,
        0.012616331689059734,
        -0.05021302402019501,
        0.0511164627969265,
        0.011570080183446407,
        -0.05020357668399811,
        -0.05100924149155617,
        -0.050987668335437775,
        -0.051158152520656586,
        0.051163364201784134,
        -0.0290810689330101,
        -0.04993091896176338,
        0.05116887763142586,
        0.05080553889274597,
        0.027002600952982903,
        -0.0412469282746315,
        0.04245094209909439,
        0.05117188021540642,
        -0.03256932273507118,
        -0.042713843286037445,
        -0.04015757143497467,
        0.050657376646995544,
        -0.04852231219410896,
        0.04276210814714432,
        0.045471370220184326,
        0.04920375719666481,
        0.05104096978902817,
        -0.04963056743144989,
        -0.010505869053304195,
        0.05116480216383934,
        -0.0490901805460453,
        0.04744047671556473,
        -0.051171913743019104,
        -0.0485772043466568,
        -0.05001502111554146,
        0.05106493458151817,
        -0.050065621733665466,
        -0.05114741250872612,
        0.049773164093494415,
        -0.05087312310934067,
        -0.051152460277080536,
        0.04713309183716774,
        0.027912184596061707,
        0.04995288699865341,
        0.05108334869146347,
        0.004457679111510515,
        -0.05095285177230835,
        0.05054008215665817,
        0.047407764941453934,
        0.04829278588294983,
        -0.05115557834506035,
        0.05112459883093834,
        -0.04816058278083801,
        -0.020871398970484734,
        -0.026286717504262924,
        0.024676727131009102,
        -0.050365425646305084,
        -0.038473185151815414,
        0.05117177963256836,
        -0.046703897416591644,
        0.05116018280386925,
        0.030611606314778328,
        0.051144663244485855,
        -0.05117185413837433,
        -0.04606122896075249,
        0.040890470147132874,
        -0.05117192491889,
        0.048179373145103455,
        0.0467258095741272,
        0.051166605204343796,
        0.044300105422735214,
        -0.0511343739926815,
        -0.0505688339471817,
        0.05103548243641853,
        -0.045229941606521606,
        -0.049449753016233444,
        0.05010325089097023,
        0.009516801685094833,
        -0.0511719286441803,
        0.05109718069434166,
        0.05106675252318382,
        0.036310262978076935,
        0.04173125699162483,
        0.051135484129190445,
        -0.0509263314306736,
        0.049673210829496384,
        -0.000627353903837502,
        -0.05103123560547829,
        -0.05111568793654442,
        0.02591802179813385,
        0.004248988814651966,
        -0.05115847289562225,
        -0.04976048320531845,
        -0.0022130904253572226,
        0.022201890125870705,
        -0.05097143352031708,
        -0.0511719286441803,
        -0.023783084005117416,
        0.05117045342922211,
        -0.048410382121801376,
        0.023883063346147537,
        -0.05105641111731529,
        0.049599241465330124,
        -0.026903564110398293,
        -0.02264312468469143,
        0.04963885620236397,
        -0.04964836686849594,
        -0.039964064955711365,
        -0.042254310101270676,
        0.042125459760427475,
        -0.04889146611094475,
        0.05110527202486992,
        -0.050856415182352066,
        -0.01967092975974083,
        0.051171548664569855,
        -0.04857702553272247,
        0.04626123607158661,
        -0.027885105460882187,
        0.04971736669540405,
        -0.051068924367427826,
        -0.05091571435332298,
        0.0511719174683094,
        0.051148585975170135,
        0.04669135436415672,
        -0.023546770215034485,
        0.05045852065086365,
        0.040170688182115555,
        0.0497848242521286,
        -0.04846389219164848,
        -0.051166534423828125,
        -0.051154132932424545,
        -0.039751239120960236,
        0.05115581303834915,
        0.050600990653038025,
        0.013909334316849709,
        -0.040681611746549606,
        0.04708194360136986,
        -0.051171012222766876,
        -0.0498642735183239,
        -0.0023280831519514322,
        -0.04729510471224785,
        0.05081745237112045,
        -0.022191321477293968,
        -0.04967530816793442,
        -0.0467221736907959,
        0.022485394030809402,
        0.013935954309999943,
        0.05090087279677391,
        -0.05114440619945526,
        0.05117124691605568,
        -0.04949921742081642,
        -0.025223512202501297,
        0.05117184668779373,
        0.051131051033735275,
        0.05104155093431473,
        -0.043168000876903534,
        0.018644006922841072,
        0.009836398996412754,
        0.0511358417570591,
        -0.049483317881822586,
        -0.05050843581557274,
        0.0511719286441803,
        -0.05023561790585518,
        0.05020056664943695,
        0.051169656217098236,
        -0.049899622797966,
        0.05066247656941414,
        0.042875226587057114,
        0.04792248457670212,
        -0.05095463618636131,
        0.0476936437189579,
        -0.04906049743294716,
        0.05013979226350784,
        0.050449807196855545,
        -0.0008823896059766412,
        0.0511707179248333,
        0.05103749781847,
        0.02486691251397133,
        -0.027305981144309044,
        0.05117170512676239,
        0.050881631672382355,
        0.049920596182346344,
        -0.05117192491889,
        0.04984637722373009,
        0.014862708747386932,
        -0.0478559285402298,
        -0.05117176100611687,
        -0.050173304975032806,
        -0.0018889927305281162,
        -0.04728955402970314,
        0.029526611790060997,
        -0.050011374056339264,
        -0.051164768636226654,
        -0.048232391476631165,
        -0.05114443600177765,
        0.05117165669798851,
        -0.051151569932699203,
        0.018351690843701363,
        0.04802442342042923,
        0.04683033376932144,
        0.05117190256714821,
        -0.04753769934177399,
        0.030880194157361984,
        0.050876278430223465,
        -0.05116208270192146,
        0.051137372851371765,
        0.0511036291718483,
        -0.04401398077607155,
        0.04319765791296959,
        0.047018490731716156,
        -0.030035503208637238,
        -0.051089365035295486,
        0.0511433370411396,
        -0.05048651993274689,
        -0.01533910259604454,
        0.006282406859099865,
        -0.05093611776828766,
        -0.04871037229895592,
        0.04901256784796715,
        -0.05114945396780968,
        -0.0170894842594862,
        0.049227047711610794,
        0.019549405202269554,
        -0.04994414746761322,
        0.0323573537170887,
        0.05027175694704056,
        -0.026634223759174347,
        0.047090083360672,
        0.05045118182897568,
        0.0016175906639546156,
        -0.05060731619596481,
        0.023641200736165047,
        0.048046357929706573,
        0.002564848167821765,
        0.051142457872629166,
        -0.05093728378415108,
        0.04534909874200821,
        -0.05040859058499336,
        0.0507870577275753,
        0.050763875246047974,
        0.05023131147027016,
        -0.011245843023061752,
        -0.023584505543112755,
        0.0469411164522171,
        -0.025909749791026115,
        0.006673608906567097,
        0.050475962460041046,
        -0.014973956160247326,
        -0.04020346701145172,
        0.031074168160557747,
        0.05074804648756981,
        0.05012527108192444,
        0.04526715353131294,
        -0.05079236254096031,
        -0.022191613912582397,
        -0.046782441437244415,
        -0.01998443901538849,
        -0.05072115734219551,
        0.05101625993847847,
        0.007270762696862221,
        0.05114781856536865,
        -0.0394698902964592,
        0.05060737580060959,
        -0.04846438765525818,
        0.05114316567778587,
        -0.04738843813538551,
        0.05082030966877937,
        0.012296407483518124,
        0.05091742053627968,
        -0.05106848478317261,
        0.015435501001775265,
        -0.050532396882772446,
        -0.04356161504983902,
        -0.0003744058485608548,
        0.05037286877632141,
        -0.048781607300043106,
        0.04718181863427162,
        0.021666331216692924,
        -0.028153477236628532,
        -0.011087197810411453,
        0.04231557622551918,
        0.05091594532132149,
        -0.025253601372241974,
        0.04274459555745125,
        -0.05100254714488983,
        0.05035831779241562,
        0.045468661934137344,
        0.05105340853333473,
        -0.05032496154308319,
        0.04993633180856705,
        0.05107855796813965,
        -0.051086440682411194,
        0.04629699885845184,
        -0.05089114233851433,
        0.05116816982626915,
        -0.04008392244577408,
        -0.04137604683637619,
        -0.0510602742433548,
        -0.030057180672883987,
        -0.04979555681347847,
        0.04938089847564697,
        -0.020716266706585884,
        -0.046064652502536774,
        -0.0509037971496582,
        -0.0497162751853466,
        -0.05112595111131668,
        0.05113370716571808,
        -0.050380948930978775,
        -0.03533042222261429,
        -0.0511670745909214,
        -0.046956390142440796,
        0.04689652472734451,
        -0.0030408906750380993,
        0.049746859818696976,
        0.04845597967505455,
        0.015073537826538086,
        0.05109502747654915,
        0.04997892305254936,
        0.051134780049324036,
        -0.04247879609465599,
        -0.05103744938969612,
        -0.050781890749931335,
        -0.0480356439948082,
        0.051008451730012894,
        -0.03413330018520355,
        -0.05087806656956673,
        -0.002887965412810445,
        -0.05079168453812599,
        -0.036100834608078,
        -0.006465846206992865,
        -0.05117190256714821,
        0.04793904349207878,
        -0.03935735300183296,
        -0.05038197711110115,
        0.0028611035086214542,
        0.04947793856263161,
        0.04450598359107971,
        -0.04769124090671539,
        -0.04370388761162758,
        -0.05106489360332489,
        -0.036996759474277496,
        0.050405193120241165,
        0.000018438304323353805,
        -0.051122214645147324,
        -0.04834555834531784,
        0.051166340708732605,
        -0.00529964454472065,
        -0.05095859616994858,
        0.05022594705224037,
        0.03701477125287056,
        -0.04272659868001938,
        -0.04972139745950699,
        0.050383154302835464,
        0.045789383351802826,
        0.05090071260929108,
        -0.05042010918259621,
        -0.025294439867138863,
        -0.05116674304008484,
        0.022832877933979034,
        0.01308277528733015,
        0.050998710095882416,
        -0.019031569361686707,
        -0.028787286952137947,
        -0.051171232014894485,
        0.05097542703151703,
        -0.013355433009564877,
        -0.050182633101940155,
        0.043794360011816025,
        -0.04525940492749214,
        -0.05073859542608261,
        -0.0163583904504776,
        0.02573571726679802,
        -0.04196704924106598,
        -0.051145005971193314,
        -0.04538729786872864,
        -0.0504826083779335,
        -0.035964176058769226,
        0.0511719174683094,
        -0.050952207297086716,
        -0.04966433718800545,
        0.05111239105463028,
        0.05117015540599823,
        0.0511719286441803,
        0.05104454979300499,
        0.0511113740503788,
        0.04573033004999161,
        0.051024675369262695,
        -0.050857555121183395,
        0.03796305134892464,
        0.0487743578851223,
        0.05115718021988869,
        0.0454002283513546,
        -0.046447478234767914,
        0.05089413747191429,
        -0.05062282830476761,
        -0.051061391830444336,
        0.030833229422569275,
        -0.009257137775421143,
        0.05117183178663254,
        0.051166851073503494,
        -0.008309902623295784,
        -0.051171571016311646,
        -0.03559838607907295,
        -0.04986290633678436,
        0.05005979910492897,
        0.05111825093626976,
        -0.05107411369681358,
        0.05116426572203636,
        -0.04895259067416191,
        -0.0482199527323246,
        -0.043790947645902634,
        0.048263486474752426,
        0.04809191823005676,
        -0.008218117989599705,
        -0.05116434022784233,
        0.0016140869120135903,
        0.050312016159296036,
        0.05115149915218353,
        -0.04379876330494881,
        0.04798298701643944,
        0.05116526409983635,
        -0.04187353327870369,
        -0.0020473815966397524,
        0.02625369466841221,
        0.039411772042512894,
        0.04996555298566818,
        0.051133718341588974,
        0.005608207546174526,
        -0.04245437681674957,
        -0.050933096557855606,
        -0.04937233775854111,
        -0.050455451011657715,
        0.05105692520737648,
        -0.04923294857144356,
        -0.04644246771931648,
        -0.027398154139518738,
        0.05117182806134224,
        0.05115880072116852,
        0.047894734889268875,
        0.05117178335785866,
        -0.05114853009581566,
        -0.028616424649953842,
        -0.0019034246215596795,
        0.016229283064603806,
        0.025513334199786186,
        0.05028598755598068,
        0.05101612210273743,
        -0.03013112023472786,
        0.01193272229284048,
        -0.051156338304281235,
        0.00395629508420825,
        -0.05025402456521988
    ],
    [
        0.01207197830080986,
        0.04861198365688324,
        0.04547497257590294,
        0.028420457616448402,
        -0.01248554140329361,
        -0.04407564550638199,
        0.05314032360911369,
        -0.05368828773498535,
        0.053595058619976044,
        0.017634423449635506,
        0.05277673155069351,
        0.05323448032140732,
        -0.05357713624835014,
        0.03184850886464119,
        -0.052933964878320694,
        0.05111890658736229,
        0.053149785846471786,
        0.053328145295381546,
        0.04506169632077217,
        -0.04396648332476616,
        -0.02843981795012951,
        0.05335921421647072,
        0.0529489628970623,
        0.04704970493912697,
        -0.04210885614156723,
        0.0533776618540287,
        0.05359689146280289,
        0.05069795623421669,
        -0.053622789680957794,
        0.00572978937998414,
        -0.05159739404916763,
        0.05082973837852478,
        0.032378003001213074,
        -0.05357569456100464,
        0.0512077622115612,
        0.04148360714316368,
        -0.05311812460422516,
        0.019270041957497597,
        0.05366251990199089,
        0.020782195031642914,
        0.04939432069659233,
        0.041921742260456085,
        0.05355846509337425,
        0.016829553991556168,
        0.04212012514472008,
        0.043623149394989014,
        -0.025802316144108772,
        0.0536833293735981,
        0.053368959575891495,
        -0.0188057292252779,
        0.05334976315498352,
        0.05343978479504585,
        -0.040338627994060516,
        0.028503090143203735,
        0.012611011043190956,
        -0.05311806499958038,
        -0.018866075202822685,
        -0.017363645136356354,
        -0.05131183937191963,
        0.017818927764892578,
        -0.052267201244831085,
        0.032429732382297516,
        -0.053144730627536774,
        0.032550569623708725,
        0.03989444300532341,
        -0.046727292239665985,
        -0.05103772506117821,
        -0.03538208827376366,
        0.028819803148508072,
        0.05277998372912407,
        -0.008044502697885036,
        -0.046691346913576126,
        0.05325200408697128,
        0.053478121757507324,
        0.052957504987716675,
        -0.05134325847029686,
        -0.00916377641260624,
        0.05369015410542488,
        0.053354885429143906,
        -0.04041672125458717,
        -0.04526780918240547,
        0.05215786397457123,
        -0.05357576906681061,
        0.037691544741392136,
        -0.051362257450819016,
        0.008829108439385891,
        0.05367431417107582,
        0.04250812530517578,
        -0.05040517821907997,
        0.052685678005218506,
        -0.052340466529130936,
        -0.04150236025452614,
        -0.05369013547897339,
        -0.03794655576348305,
        -0.04413482919335365,
        0.05321935936808586,
        0.018998706713318825,
        -0.049278002232313156,
        -0.05212479829788208,
        -0.037609513849020004,
        -0.05364237353205681,
        0.05367395654320717,
        0.03284857049584389,
        0.005690217483788729,
        -0.025170734152197838,
        -0.05236344784498215,
        -0.052681487053632736,
        0.04803358390927315,
        0.0407458059489727,
        0.05246073752641678,
        0.00630476837977767,
        -0.048428867012262344,
        -0.052343226969242096,
        -0.04870113357901573,
        0.025628412142395973,
        0.032878801226615906,
        -0.04824609309434891,
        -0.053668659180402756,
        0.0248979814350605,
        -0.05339467525482178,
        0.045400530099868774,
        0.0197963435202837,
        0.05367254838347435,
        -0.053152769804000854,
        -0.05152398347854614,
        -0.014566626399755478,
        -0.051465004682540894,
        0.05359874293208122,
        0.0525028295814991,
        0.037722259759902954,
        -0.023077405989170074,
        -0.053438302129507065,
        -0.053089264780282974,
        0.053160808980464935,
        -0.04888997599482536,
        -0.05295426771044731,
        0.0536816380918026,
        0.02685977704823017,
        -0.05129637569189072,
        0.03845994174480438,
        0.007458453997969627,
        -0.0529150553047657,
        0.04692615941166878,
        0.05216284096240997,
        -0.04851410537958145,
        -0.027829565107822418,
        0.051678530871868134,
        -0.04174655303359032,
        -0.05313190445303917,
        -0.04288142919540405,
        -0.051538705825805664,
        -0.05364677682518959,
        -0.04316289350390434,
        0.02852162905037403,
        0.008068639785051346,
        -0.05330431088805199,
        -0.05319016054272652,
        -0.049561988562345505,
        0.0532245896756649,
        -0.05048597976565361,
        0.05345844477415085,
        0.021263232454657555,
        0.05284985527396202,
        0.018838360905647278,
        -0.024111943319439888,
        0.04234794154763222,
        0.003005294594913721,
        -0.03506087511777878,
        -0.02900584414601326,
        0.024402521550655365,
        -0.05329914018511772,
        0.05160358175635338,
        -0.050421342253685,
        0.030363300815224648,
        0.021105576306581497,
        0.03713187202811241,
        0.01830579899251461,
        -0.03183472901582718,
        0.05303400382399559,
        -0.0497758574783802,
        0.03081333264708519,
        0.05368512496352196,
        0.05327676236629486,
        -0.03553853929042816,
        0.05021673068404198,
        0.05341359227895737,
        -0.05311640724539757,
        0.049193814396858215,
        0.008198519237339497,
        -0.05349639430642128,
        -0.052678558975458145,
        0.012748214416205883,
        0.05170976370573044,
        0.05325258523225784,
        -0.051409244537353516,
        0.018336832523345947,
        0.032038312405347824,
        -0.053618840873241425,
        -0.006805517245084047,
        0.051139943301677704,
        0.05323325842618942,
        -0.009176230058073997,
        -0.0274992473423481,
        -0.024143418297171593,
        0.04693450778722763,
        0.053493063896894455,
        0.048333458602428436,
        0.0524650402367115,
        -0.053572315722703934,
        0.0533415749669075,
        -0.05151648074388504,
        0.02592037431895733,
        0.023603685200214386,
        0.04323859512805939,
        0.035701196640729904,
        -0.05364860221743584,
        0.05292593315243721,
        0.04935193806886673,
        0.05366336926817894,
        0.03055407479405403,
        -0.033159684389829636,
        0.05334794148802757,
        0.05191882699728012,
        0.021085238084197044,
        0.05365844815969467,
        -0.053669221699237823,
        0.038091715425252914,
        -0.04506360739469528,
        -0.04185611754655838,
        -0.053505681455135345,
        -0.01623426005244255,
        -0.052639834582805634,
        -0.026249241083860397,
        0.0476188026368618,
        -0.026671765372157097,
        0.0536268912255764,
        0.05148737505078316,
        -0.030266549438238144,
        0.013712522573769093,
        0.05368993431329727,
        0.04311429336667061,
        0.044980306178331375,
        -0.05368317291140556,
        -0.053049441426992416,
        -0.004516435321420431,
        -0.05160010978579521,
        -0.053276482969522476,
        -0.029040252789855003,
        -0.05310862138867378,
        -0.034324415028095245,
        -0.04850299283862114,
        -0.05092311277985573,
        -0.014168670400977135,
        -0.05258379504084587,
        -0.04006466269493103,
        0.053671110421419144,
        -0.04664093255996704,
        -0.007853582501411438,
        0.05302336439490318,
        -0.04280249774456024,
        0.05359592288732529,
        -0.02058533765375614,
        -0.034863948822021484,
        0.05257616937160492,
        -0.015850568190217018,
        0.05022227019071579,
        0.05367785319685936,
        0.05358802154660225,
        -0.05102497339248657,
        0.040372755378484726,
        -0.012127758003771305,
        -0.053672146052122116,
        0.033961694687604904,
        -0.053611475974321365,
        0.0416506789624691,
        0.03237936273217201,
        -0.05146462842822075,
        -0.03775355964899063,
        0.042480431497097015,
        -0.05286353453993797,
        -0.05349811539053917,
        0.053532663732767105,
        -0.04960980266332626,
        -0.022418910637497902,
        0.05294908583164215,
        -0.008462105877697468,
        -0.021452056244015694,
        0.051279038190841675,
        0.035740163177251816,
        0.0480656623840332,
        -0.04965024068951607,
        -0.021532181650400162,
        -0.015314262360334396,
        0.04258846864104271,
        0.053603626787662506,
        -0.05365114286541939,
        0.053690291941165924,
        0.049392253160476685,
        -0.035467855632305145,
        0.052596382796764374,
        0.053559958934783936,
        0.017400536686182022,
        -0.01992860622704029,
        0.05171392112970352,
        -0.023045454174280167,
        -0.04534588381648064,
        -0.02239288203418255,
        0.04706575348973274,
        0.03164824843406677,
        -0.050796568393707275,
        -0.009400191716849804,
        -0.038605231791734695,
        0.016576608642935753,
        0.027384303510189056,
        -0.05180739611387253,
        -0.05218058079481125,
        -0.005252726376056671,
        -0.053367238491773605,
        0.05122349411249161,
        -0.006768698804080486,
        0.046460818499326706,
        -0.04533545300364494,
        0.05300974100828171,
        -0.050416454672813416,
        0.053645059466362,
        -0.019563088193535805,
        0.05344826728105545,
        0.05250200256705284,
        -0.04453200474381447,
        -0.05367399379611015,
        0.05139764025807381,
        -0.04120572656393051,
        -0.04420975595712662,
        0.03334458917379379,
        0.04821278899908066,
        0.04763208329677582,
        -0.043277546763420105,
        0.051373161375522614,
        -0.01947029121220112,
        -0.034145504236221313,
        -0.0042258030734956264,
        0.03845207020640373,
        0.05222656577825546,
        -0.035282716155052185,
        0.03271225094795227,
        0.0354604534804821,
        0.04529763385653496,
        0.05321786180138588,
        -0.046187855303287506,
        0.05215691775083542,
        0.05367681756615639,
        0.042863085865974426,
        0.0442081019282341,
        -0.05248052626848221,
        -0.02420802041888237,
        0.04396698996424675,
        -0.0536213256418705,
        -0.05149787291884422,
        -0.05305386707186699,
        -0.02108554169535637,
        0.04825880005955696,
        0.04787681996822357,
        -0.00022114808962214738,
        -0.04995144531130791,
        -0.04920431226491928,
        -0.05260510370135307,
        0.0533180758357048,
        -0.022396255284547806,
        -0.05161058157682419,
        -0.053155068308115005,
        -0.04735362529754639,
        -0.05209751054644585,
        0.04935801029205322,
        0.011570931412279606,
        0.036085620522499084,
        0.05061913654208183,
        0.0446583554148674,
        0.05289224162697792,
        0.05332684516906738,
        0.0402042381465435,
        -0.020618461072444916,
        0.051448479294776917,
        -0.053094375878572464,
        0.05349849537014961,
        0.04507004842162132,
        -0.05144742503762245,
        0.052865397185087204,
        -0.05254942923784256,
        -0.047715503722429276,
        -0.0342596061527729,
        -0.05366200953722,
        -0.04687124490737915,
        -0.04122568666934967,
        -0.04895409941673279,
        0.038014188408851624,
        0.05234627425670624,
        0.0503554567694664,
        -0.052881043404340744,
        -0.05350348725914955,
        -0.053130656480789185,
        -0.04882275313138962,
        0.05347517132759094,
        0.037633221596479416,
        -0.020131919533014297,
        -0.052130553871393204,
        -0.014666284434497356,
        0.029364606365561485,
        -0.03421435505151749,
        0.05367927625775337,
        -0.001509665627963841,
        -0.036416180431842804,
        -0.053242988884449005,
        0.0015176516026258469,
        0.045923151075839996,
        0.04867202043533325,
        -0.05313723906874657,
        0.029794620350003242,
        -0.014609497971832752,
        0.0535583533346653,
        0.052689991891384125,
        0.05322209745645523,
        -0.05226932093501091,
        -0.027339071035385132,
        -0.05369029566645622,
        0.04468761011958122,
        -0.0478966124355793,
        -0.05156264081597328,
        0.05362865701317787,
        -0.05310380831360817,
        -0.05280136689543724,
        -0.05142052099108696,
        -0.006840005051344633,
        0.04776107892394066,
        -0.04307840019464493,
        0.03189216926693916,
        -0.05291871726512909,
        0.015760865062475204,
        0.050337053835392,
        -0.04640529304742813,
        -0.0530519112944603,
        -0.03912367671728134,
        0.053396858274936676,
        0.053637195378541946,
        0.05078711733222008,
        -0.046420469880104065,
        0.05278145521879196,
        0.053428810089826584,
        -0.009746713563799858,
        0.01934095472097397,
        0.05280044674873352,
        0.05220190808176994,
        -0.030374882742762566,
        0.017990173771977425,
        0.0527290403842926,
        -0.026472952216863632,
        -0.0526118241250515,
        0.011814139783382416,
        -0.0461234413087368,
        0.05098174512386322,
        0.053257204592227936,
        0.05343737080693245,
        -0.05331821367144585,
        -0.04946029558777809,
        0.009845308028161526,
        0.02184930071234703,
        0.0535380057990551,
        -0.053660135716199875,
        0.05328669771552086,
        -0.05224008485674858,
        -0.05287870764732361,
        0.03541148453950882,
        -0.015028380788862705,
        0.05279958248138428,
        0.05033934861421585,
        -0.05367576330900192,
        0.03756735101342201,
        0.011360508389770985,
        0.04032648354768753,
        -0.043508559465408325,
        -0.0378597155213356,
        0.043861497193574905,
        -0.05212761089205742,
        0.02970920316874981,
        -0.010809080675244331,
        -0.05322253704071045,
        0.05307483300566673,
        0.052631229162216187,
        0.02893504872918129,
        -0.05284224450588226,
        -0.05251127853989601,
        0.03014293871819973,
        0.027292339131236076,
        0.0490163154900074,
        0.05155770108103752,
        0.05098605155944824,
        0.05178464949131012,
        0.0007780530722811818,
        0.04351162165403366,
        0.052023034542798996,
        0.05352933704853058,
        -0.053649723529815674,
        -0.04795364290475845,
        -0.05143669620156288,
        0.04713032394647598,
        0.04976451396942139,
        0.022013254463672638,
        0.05368508771061897,
        -0.04972873628139496,
        0.050753600895404816,
        -0.05345230549573898,
        0.05364474281668663,
        -0.051265474408864975
    ],
    [
        0.05670889839529991,
        0.010752977803349495,
        0.019111650064587593,
        0.014788423664867878,
        -0.05001654848456383,
        -0.009737612679600716,
        0.00285750487819314,
        -0.05643435940146446,
        0.051261939108371735,
        -0.05669531598687172,
        0.05396997928619385,
        0.05027179792523384,
        -0.054268281906843185,
        -0.023073017597198486,
        -0.05630766227841377,
        0.056174322962760925,
        0.05263900011777878,
        0.05645560845732689,
        0.006614417303353548,
        0.03696494176983833,
        0.05673075094819069,
        -0.020440461114048958,
        0.04976280778646469,
        -0.004356379620730877,
        0.05049249157309532,
        -0.0075090378522872925,
        0.04952326789498329,
        0.05494125559926033,
        -0.04684439301490784,
        0.056560397148132324,
        0.053251225501298904,
        0.050194524228572845,
        0.05650473013520241,
        -0.056656356900930405,
        0.056126669049263,
        -0.05459980666637421,
        -0.05192733556032181,
        0.007384867407381535,
        0.025407986715435982,
        0.007037338335067034,
        0.033203475177288055,
        0.031179195269942284,
        0.04138593375682831,
        0.044474367052316666,
        0.05626152455806732,
        0.021453579887747765,
        0.03862518072128296,
        0.04511549696326256,
        0.056262366473674774,
        -0.029536381363868713,
        0.05410751700401306,
        0.05249674618244171,
        -0.025490159168839455,
        -0.05666913837194443,
        0.04734247550368309,
        0.0482189878821373,
        -0.05581069737672806,
        -0.004598540253937244,
        -0.05645051971077919,
        -0.0372801311314106,
        -0.05630252882838249,
        0.015795420855283737,
        -0.051362231373786926,
        0.04168877750635147,
        0.03818740323185921,
        -0.05406201258301735,
        -0.04686187207698822,
        -0.03683136776089668,
        -0.05554372817277908,
        0.041264552623033524,
        -0.024435264989733696,
        -0.056241363286972046,
        0.04520934075117111,
        0.03548910468816757,
        0.05611741542816162,
        -0.018070800229907036,
        -0.014415874145925045,
        0.05673513561487198,
        0.055486779659986496,
        -0.05221894383430481,
        -0.0016190878814086318,
        -0.031064005568623543,
        -0.05272821709513664,
        0.051602691411972046,
        0.05580166354775429,
        0.04530896246433258,
        0.0210332702845335,
        0.011225265450775623,
        -0.04228941723704338,
        0.05609078332781792,
        -0.05243650823831558,
        -0.04011200740933418,
        -0.05673205479979515,
        -0.055943563580513,
        0.01812242902815342,
        -0.02223864383995533,
        -0.05092990770936012,
        -0.05449216440320015,
        0.03179994970560074,
        -0.03648281842470169,
        -0.05658869445323944,
        -0.023808807134628296,
        0.017515864223241806,
        -0.018580714240670204,
        0.04421598091721535,
        0.025119904428720474,
        -0.03738803789019585,
        0.05196836218237877,
        0.03916028141975403,
        0.0526154525578022,
        -0.04128428176045418,
        0.05442574992775917,
        -0.037676531821489334,
        0.04108468070626259,
        -0.040029238909482956,
        0.008616619743406773,
        0.04001959413290024,
        -0.055527787655591965,
        0.009867398999631405,
        -0.0557137094438076,
        0.054015181958675385,
        -0.031058458611369133,
        0.05282680690288544,
        -0.05672110989689827,
        -0.04574485123157501,
        0.05643753334879875,
        -0.04232887551188469,
        0.00955156795680523,
        0.03557894751429558,
        0.05660417303442955,
        -0.03512420505285263,
        -0.05251654237508774,
        0.005286394618451595,
        0.05099771171808243,
        -0.045807257294654846,
        -0.0552012175321579,
        0.056588564068078995,
        0.054971665143966675,
        -0.05398796871304512,
        0.001980570377781987,
        -0.037236932665109634,
        0.05503026023507118,
        0.012921127490699291,
        0.01136706117540598,
        -0.05443175137042999,
        -0.02281412109732628,
        0.02029566466808319,
        -0.047540269792079926,
        0.05637180060148239,
        0.05665406212210655,
        -0.033085379749536514,
        -0.056661464273929596,
        -0.011192153207957745,
        -0.03100186586380005,
        0.024218333885073662,
        -0.014159314334392548,
        -0.05620598793029785,
        -0.04800092801451683,
        0.05353250727057457,
        -0.049577899277210236,
        0.05516679584980011,
        -0.01617421582341194,
        0.05457016080617905,
        0.019827285781502724,
        0.03703675791621208,
        0.05092521011829376,
        0.03619098290801048,
        0.05016300827264786,
        0.035977527499198914,
        0.046634968370199203,
        0.026310916990041733,
        0.03457127884030342,
        -0.05579502880573273,
        0.04031818360090256,
        0.05558832362294197,
        0.03778911009430885,
        -0.055348098278045654,
        -0.05555552616715431,
        0.05532938241958618,
        -0.05522846058011055,
        -0.03493058308959007,
        0.056669220328330994,
        0.054189328104257584,
        -0.0446651317179203,
        0.007009362801909447,
        0.051949869841337204,
        0.0018885040190070868,
        0.05244820564985275,
        -0.011973856948316097,
        -0.05075860768556595,
        -0.05358787998557091,
        0.013830306008458138,
        -0.002236283151432872,
        -0.049380481243133545,
        -0.0554487518966198,
        -0.02925817482173443,
        0.03885817900300026,
        -0.034280844032764435,
        -0.053406741470098495,
        0.04864053428173065,
        0.056343965232372284,
        -0.056726764887571335,
        0.00011908437591046095,
        -0.038530733436346054,
        0.020074933767318726,
        0.017623478546738625,
        0.049184590578079224,
        -0.002516090404242277,
        -0.055968381464481354,
        0.051100146025419235,
        -0.053526490926742554,
        -0.006207027938216925,
        0.0559103786945343,
        0.05602924898266792,
        -0.05065567046403885,
        0.03159008547663689,
        0.048522159457206726,
        -0.036050062626600266,
        0.05649176239967346,
        -0.05287197232246399,
        0.03219880163669586,
        0.05580134689807892,
        -0.01904437132179737,
        0.055939048528671265,
        0.05661269649863243,
        -0.020803656429052353,
        0.04875209927558899,
        -0.04980551451444626,
        0.05579647421836853,
        -0.051654357463121414,
        -0.01365905161947012,
        -0.05605005845427513,
        -0.050023019313812256,
        0.04779816418886185,
        -0.03870118409395218,
        0.05647881329059601,
        0.054952818900346756,
        0.0401490293443203,
        -0.033450838178396225,
        0.05673057958483696,
        0.04346287623047829,
        0.056707363575696945,
        -0.05637955665588379,
        0.02600974030792713,
        0.03549648076295853,
        -0.04500657692551613,
        -0.02329426072537899,
        0.056298039853572845,
        -0.054049260914325714,
        -0.0458613820374012,
        -0.05051439628005028,
        -0.050376832485198975,
        -0.05577586591243744,
        -0.05065343528985977,
        -0.05552971363067627,
        0.05197140574455261,
        -0.03182823210954666,
        -0.0385144017636776,
        0.052901268005371094,
        0.03668525442481041,
        -0.03694108873605728,
        -0.004019447136670351,
        0.030831852927803993,
        0.05460665747523308,
        0.05672592669725418,
        0.05576961860060692,
        0.05670497566461563,
        0.047220539301633835,
        0.03412242606282234,
        -0.05337102338671684,
        0.0192850511521101,
        -0.05661413446068764,
        0.018202591687440872,
        -0.05527256801724434,
        -0.032083697617053986,
        0.0035092660691589117,
        -0.049964629113674164,
        -0.0049952236004173756,
        -0.05669813230633736,
        -0.05586419627070427,
        -0.05624087154865265,
        0.04874136671423912,
        0.03248266130685806,
        0.04658809304237366,
        0.05672913417220116,
        0.05565183609724045,
        -0.044847194105386734,
        0.03434208780527115,
        0.047922637313604355,
        0.04783948138356209,
        -0.021240757778286934,
        0.018876472488045692,
        -0.03266865760087967,
        0.036075521260499954,
        -0.03313261643052101,
        -0.04859642684459686,
        0.05664709582924843,
        -0.02227245829999447,
        0.05483052134513855,
        0.05474522337317467,
        -0.039732154458761215,
        0.022896599024534225,
        -0.03380247578024864,
        0.023669792339205742,
        0.0027256645262241364,
        0.04916590452194214,
        0.022379064932465553,
        0.03175763785839081,
        -0.04207615181803703,
        -0.028210647404193878,
        -0.054466355592012405,
        -0.04918712005019188,
        -0.00536300428211689,
        -0.01859521120786667,
        0.03163779526948929,
        -0.05617370083928108,
        -0.037881363183259964,
        -0.032026421278715134,
        0.05557328462600708,
        -0.03173565864562988,
        0.054378442466259,
        -0.05661093443632126,
        0.05018329992890358,
        -0.024976667016744614,
        0.05658572539687157,
        -0.05198896303772926,
        0.030907772481441498,
        -0.03761742264032364,
        0.0554329976439476,
        -0.023226842284202576,
        0.05621527507901192,
        0.018769675865769386,
        -0.008033291436731815,
        -0.05514231696724892,
        0.04525846242904663,
        0.049963366240262985,
        -0.0014343827497214079,
        0.05332345888018608,
        -0.0071615250781178474,
        -0.0008787012775428593,
        0.026639077812433243,
        0.03637397289276123,
        -0.02846645563840866,
        0.05211106315255165,
        0.04663027822971344,
        0.04232185706496239,
        0.0504579059779644,
        0.053189270198345184,
        -0.054503604769706726,
        0.04523992910981178,
        0.05641365796327591,
        0.019984036684036255,
        0.05265560746192932,
        -0.052350327372550964,
        0.05488856881856918,
        0.04857728257775307,
        -0.056699853390455246,
        -0.05598355457186699,
        -0.016681667417287827,
        -0.05622093379497528,
        0.05148264393210411,
        0.02837100438773632,
        -0.054320018738508224,
        0.005095983389765024,
        -0.054848961532115936,
        -0.056411970406770706,
        0.056324876844882965,
        -0.040126360952854156,
        0.05610501021146774,
        -0.055930450558662415,
        -0.03334673494100571,
        0.00042667111847549677,
        0.05672889202833176,
        0.030976351350545883,
        0.05063919350504875,
        -0.020812297239899635,
        0.02271892875432968,
        0.049338724464178085,
        0.05667790025472641,
        -0.05073455348610878,
        -0.045566126704216,
        -0.015621989965438843,
        -0.05642026290297508,
        0.025954658165574074,
        0.03921077400445938,
        0.012671083211898804,
        0.053287092596292496,
        -0.056453775614500046,
        -0.0541650764644146,
        -0.026930226013064384,
        -0.05579032376408577,
        0.047153789550065994,
        -0.024697912856936455,
        -0.054190993309020996,
        0.04506313428282738,
        0.05355163291096687,
        0.05475661903619766,
        -0.05512841045856476,
        -0.05670005455613136,
        -0.056545231491327286,
        -0.023287929594516754,
        0.055592130869627,
        -0.05443910136818886,
        -0.007970715872943401,
        0.05474943667650223,
        -0.047371476888656616,
        0.03806500509381294,
        -0.056666988879442215,
        0.05288810655474663,
        -0.04741700366139412,
        0.045632731169462204,
        0.035961560904979706,
        -0.0305679552257061,
        -0.02969531901180744,
        0.05401528254151344,
        -0.037199996411800385,
        0.019565850496292114,
        0.04681481793522835,
        0.04793303832411766,
        -0.03266614302992821,
        0.05246448516845703,
        0.05666529759764671,
        0.056177906692028046,
        -0.05662284418940544,
        0.04903728887438774,
        -0.031876757740974426,
        -0.056294262409210205,
        -0.04194964841008186,
        -0.04614383727312088,
        -0.0508488267660141,
        -0.0067060538567602634,
        -0.02073497697710991,
        0.020346373319625854,
        -0.0458662323653698,
        -0.05582938343286514,
        -0.054392989724874496,
        -0.03314344212412834,
        0.05613280460238457,
        -0.030226504430174828,
        -0.054675597697496414,
        -0.04415425285696983,
        0.0553632453083992,
        0.055635519325733185,
        0.054877303540706635,
        0.03356187790632248,
        -0.04895096644759178,
        0.056141406297683716,
        -0.052430957555770874,
        0.05046093836426735,
        0.05515286698937416,
        -0.03985963389277458,
        -0.021533053368330002,
        -0.03725667670369148,
        -0.030133601278066635,
        0.051681309938430786,
        -0.0564182884991169,
        -0.025000352412462234,
        -0.034745365381240845,
        0.04486360773444176,
        0.056645363569259644,
        0.054172106087207794,
        -0.049088068306446075,
        0.024404628202319145,
        0.05428832024335861,
        0.049637552350759506,
        0.056438129395246506,
        -0.0516485758125782,
        0.04662451148033142,
        0.055852390825748444,
        -0.05616109445691109,
        0.0507737435400486,
        -0.016951393336057663,
        0.0380229651927948,
        0.0566573403775692,
        -0.02907566726207733,
        0.0031000112649053335,
        -0.0337771400809288,
        0.056479863822460175,
        -0.05430661886930466,
        0.011947707273066044,
        0.018620548769831657,
        0.02474408783018589,
        -0.022291412577033043,
        0.05206083878874779,
        0.05587427318096161,
        0.04414758086204529,
        0.04878886416554451,
        0.055821493268013,
        -0.05281897634267807,
        0.025100335478782654,
        -0.04908851906657219,
        -0.04850872978568077,
        -0.014669477008283138,
        -0.03426675871014595,
        -0.03397262468934059,
        0.029141517356038094,
        0.05502118170261383,
        0.05647439882159233,
        0.05592009797692299,
        0.05221440643072128,
        -0.05580338090658188,
        0.02324856072664261,
        -0.04342712461948395,
        0.03372982516884804,
        0.03324948623776436,
        0.02423921972513199,
        0.052919335663318634,
        0.007713626138865948,
        0.04398597404360771,
        -0.0567348413169384,
        0.051372040063142776,
        0.05392274633049965
    ],
    [
        -0.0461561381816864,
        0.050237350165843964,
        0.03979825600981712,
        0.009636752307415009,
        -0.04014867916703224,
        0.05428751930594444,
        -0.0102354995906353,
        -0.05485152453184128,
        0.05435687676072121,
        0.039463747292757034,
        0.0540163479745388,
        0.053691018372774124,
        -0.054556120187044144,
        -0.04509977996349335,
        -0.05434180796146393,
        0.04616059735417366,
        0.04814574122428894,
        0.01949901692569256,
        0.023713314905762672,
        -0.05501291900873184,
        0.05491992458701134,
        0.055011533200740814,
        0.054222654551267624,
        0.05425705015659332,
        0.0421907976269722,
        0.04625022038817406,
        0.054855842143297195,
        0.04376165196299553,
        -0.02664732187986374,
        0.05489429458975792,
        0.049236901104450226,
        0.030700545758008957,
        0.05309922993183136,
        -0.05203989893198013,
        0.03828012943267822,
        -0.05347980186343193,
        -0.05400722846388817,
        -0.022222377359867096,
        0.05388583242893219,
        -0.053180571645498276,
        0.050706516951322556,
        -0.03867238387465477,
        0.039974141865968704,
        0.04454445466399193,
        0.019895995035767555,
        -0.03690124303102493,
        0.05278541520237923,
        0.05501056835055351,
        0.05376231670379639,
        0.04156297817826271,
        0.05398554727435112,
        0.05184440314769745,
        -0.022059952840209007,
        0.05214916169643402,
        0.05085877701640129,
        0.054796893149614334,
        -0.04716522619128227,
        0.04203561693429947,
        -0.051247213035821915,
        -0.05087463930249214,
        -0.04875817894935608,
        -0.009799203835427761,
        -0.05324956029653549,
        0.04975443705916405,
        -0.05370540916919708,
        0.02502397634088993,
        -0.052288033068180084,
        -0.05472245812416077,
        -0.040879301726818085,
        0.05415457487106323,
        -0.03336484357714653,
        -0.050006404519081116,
        0.04911937192082405,
        0.03156762570142746,
        0.04448811337351799,
        -0.04745896905660629,
        -0.04094874486327171,
        0.05501091480255127,
        0.051929671317338943,
        -0.01831420697271824,
        -0.04476713761687279,
        0.05487918481230736,
        -0.04549769312143326,
        0.011977273039519787,
        0.04764290526509285,
        0.04580586776137352,
        0.05483778938651085,
        0.05495467782020569,
        -0.033475007861852646,
        -0.027606552466750145,
        -0.05391097068786621,
        -0.03352849557995796,
        -0.054880790412425995,
        -0.05463830754160881,
        -0.04801429435610771,
        0.05467946082353592,
        -0.035355355590581894,
        -0.05448786914348602,
        0.033252689987421036,
        0.03461027517914772,
        -0.05478527769446373,
        0.04309942200779915,
        0.05032355338335037,
        0.05201951786875725,
        0.05195155739784241,
        -0.013836881145834923,
        0.033869266510009766,
        0.04603223875164986,
        0.0523354597389698,
        0.018635721877217293,
        -0.016213908791542053,
        -0.051270969212055206,
        -0.01554759219288826,
        -0.03909831494092941,
        -0.047887083142995834,
        -0.044692523777484894,
        -0.050807345658540726,
        -0.04887452349066734,
        0.03928280621767044,
        -0.04005564749240875,
        0.05457225814461708,
        0.023371076211333275,
        0.03953133150935173,
        -0.054797179996967316,
        -0.04470270499587059,
        0.052705198526382446,
        -0.05501297488808632,
        -0.030151372775435448,
        0.020815258845686913,
        0.05440272018313408,
        0.012190560810267925,
        -0.05263926088809967,
        -0.05274804309010506,
        0.05407056212425232,
        -0.052893299609422684,
        -0.05113605409860611,
        0.05481388419866562,
        0.012269104830920696,
        -0.045863356441259384,
        0.03704856336116791,
        0.019337305799126625,
        0.03209187090396881,
        0.05252488702535629,
        0.05122778192162514,
        0.007965548895299435,
        -0.0547909140586853,
        -0.020601773634552956,
        -0.05470101162791252,
        -0.02941557765007019,
        0.051354870200157166,
        0.05302318558096886,
        -0.054495181888341904,
        -0.04773952066898346,
        -0.0495210736989975,
        0.0527205653488636,
        -0.03416035696864128,
        -0.019900748506188393,
        0.01469867117702961,
        0.007129963021725416,
        0.007924833334982395,
        0.055008433759212494,
        -0.03604501485824585,
        0.05292472988367081,
        -0.014441394247114658,
        -0.04155062884092331,
        0.00600364850834012,
        -0.040569450706243515,
        0.0013732761144638062,
        -0.006949837319552898,
        0.03070714883506298,
        -0.045296281576156616,
        0.02252483367919922,
        -0.05113803222775459,
        -0.05260077863931656,
        0.053682368248701096,
        0.030781907960772514,
        -0.01686784252524376,
        0.015305187553167343,
        0.04533357918262482,
        -0.05472378805279732,
        -0.048445720225572586,
        0.05501290410757065,
        0.04754282161593437,
        -0.02490028738975525,
        0.020280994474887848,
        0.043424129486083984,
        -0.0513991080224514,
        0.05397514998912811,
        -0.05453598499298096,
        -0.05486498400568962,
        0.008158894255757332,
        -0.03384202718734741,
        0.053891438990831375,
        0.05208795890212059,
        -0.044318683445453644,
        -0.04825028404593468,
        -0.039444450289011,
        -0.055012911558151245,
        -0.052030738443136215,
        0.05226514860987663,
        0.054356928914785385,
        -0.05405329540371895,
        -0.0013250181218609214,
        -0.051042038947343826,
        0.0345776341855526,
        0.05227341502904892,
        0.0259554386138916,
        0.031162919476628304,
        -0.05401094630360603,
        0.05497480556368828,
        -0.04766521230340004,
        0.03694261610507965,
        -0.05192501097917557,
        0.05453731119632721,
        -0.04398340359330177,
        0.012168442830443382,
        0.0066200727596879005,
        -0.03862292692065239,
        0.04786454513669014,
        -0.05292957276105881,
        -0.054009344428777695,
        0.055012356489896774,
        0.0504971519112587,
        0.028866829350590706,
        0.05484235659241676,
        -0.05013468116521835,
        0.049424562603235245,
        0.05033927783370018,
        -0.021243510767817497,
        -0.04844270274043083,
        0.03910162299871445,
        -0.05485334247350693,
        -0.0027111084200441837,
        0.053035590797662735,
        -0.044955190271139145,
        0.05471450090408325,
        0.05406103655695915,
        -0.003257215255871415,
        0.04843638837337494,
        0.055007461458444595,
        -0.049258753657341,
        0.05473218485713005,
        -0.05500161275267601,
        0.052497755736112595,
        0.040580328553915024,
        -0.035556577146053314,
        -0.05408303067088127,
        0.022098595276474953,
        -0.050802603363990784,
        -0.039342720061540604,
        -0.04476681351661682,
        -0.05442046746611595,
        0.04969935864210129,
        -0.05359627306461334,
        -0.054897237569093704,
        0.05490383505821228,
        -0.011020983569324017,
        0.04543992131948471,
        0.054405562579631805,
        -0.05165260285139084,
        0.05500864237546921,
        -0.04728592932224274,
        -0.005919364746659994,
        0.05001843348145485,
        0.0013627716107293963,
        0.049043625593185425,
        0.054194819182157516,
        0.05207114294171333,
        0.05498630553483963,
        0.04418978467583656,
        -0.017565008252859116,
        -0.054803866893053055,
        0.05344733968377113,
        -0.05406498163938522,
        -0.008732376620173454,
        0.052400123327970505,
        -0.05169679969549179,
        -0.054238978773355484,
        -0.05025744438171387,
        -0.05482916533946991,
        -0.05429788678884506,
        0.042719610035419464,
        -0.002393331378698349,
        -0.029084820300340652,
        0.053737591952085495,
        -0.021390732377767563,
        -0.03823844715952873,
        0.037534233182668686,
        0.04455107823014259,
        0.05358192324638367,
        -0.054896965622901917,
        0.03416072949767113,
        0.04702160879969597,
        0.04077769070863724,
        0.052310504019260406,
        -0.0540526919066906,
        0.05490924417972565,
        0.05320204794406891,
        -0.027658669278025627,
        0.045949697494506836,
        0.05124783515930176,
        -0.004193782340735197,
        -0.013485915958881378,
        0.04266073554754257,
        -0.051089826971292496,
        0.013898749835789204,
        0.011596997268497944,
        -0.05041483789682388,
        -0.05025123804807663,
        0.03438033163547516,
        0.047666534781455994,
        -0.05369725450873375,
        0.016296006739139557,
        -0.007104761898517609,
        -0.035847507417201996,
        -0.0545676164329052,
        -0.050065796822309494,
        -0.04192858934402466,
        0.05363111197948456,
        0.045962125062942505,
        0.05050263553857803,
        -0.041293609887361526,
        0.039840806275606155,
        -0.04076823964715004,
        0.05236682668328285,
        -0.0522574782371521,
        0.053841326385736465,
        0.05465864762663841,
        -0.050948310643434525,
        -0.05465150624513626,
        0.05452067032456398,
        -0.013307677581906319,
        -0.04938223585486412,
        0.05417441949248314,
        0.05331411212682724,
        0.02806318737566471,
        -0.046316102147102356,
        0.043410610407590866,
        -0.012899721972644329,
        0.05493062362074852,
        -0.0520976297557354,
        -0.01710830070078373,
        -0.03369992971420288,
        -0.05452396720647812,
        0.04121740162372589,
        0.02173864096403122,
        0.04533527418971062,
        0.053335435688495636,
        -0.05483963340520859,
        0.04537864401936531,
        0.054914504289627075,
        0.02212357521057129,
        0.007890625856816769,
        -0.054470229893922806,
        -0.04278424754738808,
        0.007050881627947092,
        -0.023942267522215843,
        -0.05484377220273018,
        0.00030603178311139345,
        0.0069913663901388645,
        0.05371977388858795,
        0.00911408755928278,
        -0.054902881383895874,
        -0.05025361478328705,
        -0.03961298614740372,
        -0.05469760671257973,
        0.05498018115758896,
        -0.051016416400671005,
        0.04505869373679161,
        -0.05459251627326012,
        -0.0379486083984375,
        -0.02485349215567112,
        0.05004341900348663,
        0.047304969280958176,
        0.03558576852083206,
        0.05484943464398384,
        0.05108949914574623,
        0.05501268431544304,
        0.054890867322683334,
        -0.03512576222419739,
        -0.048501890152692795,
        -0.04947199672460556,
        -0.05431295186281204,
        0.041213613003492355,
        0.04484233632683754,
        0.007686165627092123,
        -0.03353594243526459,
        -0.05357743799686432,
        -0.05350368842482567,
        0.007946504279971123,
        -0.05499003827571869,
        0.04967833310365677,
        -0.048779748380184174,
        -0.054386407136917114,
        -0.04357580840587616,
        0.04915495961904526,
        0.04692220315337181,
        -0.04430902749300003,
        -0.03489795699715614,
        -0.05387192219495773,
        -0.05129044130444527,
        0.054246943444013596,
        0.0038406886160373688,
        -0.054063938558101654,
        0.031562015414237976,
        0.054841842502355576,
        0.04205027595162392,
        0.01532303262501955,
        0.05345365032553673,
        -0.050450053066015244,
        -0.0487208366394043,
        -0.05368680879473686,
        0.0212499238550663,
        0.016042612493038177,
        0.05276188999414444,
        -0.03793574124574661,
        -0.040428996086120605,
        0.04914649575948715,
        -0.04631368815898895,
        0.05388089641928673,
        0.05415533110499382,
        -0.052515722811222076,
        -0.049014586955308914,
        -0.055004287511110306,
        0.02526841312646866,
        0.011766614392399788,
        -0.012900211848318577,
        0.048191606998443604,
        -0.007662626914680004,
        -0.054828088730573654,
        0.0548703558743,
        -0.048703212291002274,
        0.05374710261821747,
        -0.045394640415906906,
        0.037964850664138794,
        -0.04506263509392738,
        0.032358430325984955,
        0.03745540231466293,
        -0.008052457123994827,
        -0.05207284539937973,
        0.04477713629603386,
        0.054484207183122635,
        0.055012352764606476,
        0.053391072899103165,
        -0.030153632164001465,
        -0.0008376657497137785,
        0.05479349568486214,
        -0.05170564353466034,
        0.04217096418142319,
        -0.05085550993680954,
        -0.041993092745542526,
        0.0506775937974453,
        -0.02499431185424328,
        0.050368532538414,
        0.05448746681213379,
        -0.0545077808201313,
        -0.050153691321611404,
        -0.046592626720666885,
        0.05312351509928703,
        0.03498765826225281,
        0.016588596627116203,
        -0.033989183604717255,
        -0.03612184897065163,
        0.014270479790866375,
        0.053304485976696014,
        0.05464433506131172,
        0.026611315086483955,
        0.054735299199819565,
        -0.054044704884290695,
        -0.03327839449048042,
        -0.02611364796757698,
        0.041969556361436844,
        0.05296286940574646,
        0.024471823126077652,
        -0.054971326142549515,
        -0.022290214896202087,
        0.037669796496629715,
        0.04423341900110245,
        0.011116573587059975,
        -0.019118579104542732,
        0.03653222694993019,
        -0.04350954666733742,
        -0.045776307582855225,
        0.009190543554723263,
        0.03557151183485985,
        0.053720079362392426,
        0.05316297337412834,
        0.045928262174129486,
        -0.0522678904235363,
        -0.014186269603669643,
        0.04697638377547264,
        -0.007564634550362825,
        0.03300531953573227,
        0.024921072646975517,
        -0.04910555109381676,
        0.0384666845202446,
        0.05455775558948517,
        0.045451268553733826,
        0.05157935619354248,
        0.055005114525556564,
        -0.05501021817326546,
        0.03456893563270569,
        -0.028208009898662567,
        -0.012286192737519741,
        0.025502678006887436,
        0.023360731080174446,
        0.054985418915748596,
        -0.03781670331954956,
        0.014207416214048862,
        -0.027517130598425865,
        0.04553082585334778,
        -0.040468886494636536
    ],
    [
        -0.023182624951004982,
        0.048577457666397095,
        0.057379405945539474,
        0.013178892433643341,
        -0.017405075952410698,
        0.06219461187720299,
        0.05347190052270889,
        -0.043273888528347015,
        0.00014416359772440046,
        0.052256979048252106,
        0.04457192122936249,
        0.042448025196790695,
        -0.043514225631952286,
        -0.03722051531076431,
        -0.04756473749876022,
        0.00518081896007061,
        0.04392557218670845,
        0.06250537186861038,
        -0.0017494078492745757,
        -0.06094018369913101,
        0.06065145134925842,
        0.053907450288534164,
        0.03776063770055771,
        0.04252169653773308,
        0.06308786571025848,
        0.0527467280626297,
        0.05209122598171234,
        0.0540044829249382,
        0.0020082781556993723,
        -0.005304145626723766,
        0.012094699777662754,
        0.006837164051830769,
        0.05976713076233864,
        -0.051057618111371994,
        0.022779366001486778,
        -0.054192349314689636,
        -0.05903072655200958,
        0.020006699487566948,
        0.05668454244732857,
        0.04566540569067001,
        0.04503830149769783,
        0.03806138038635254,
        0.04917790740728378,
        -0.04107484593987465,
        0.06328286975622177,
        0.024495860561728477,
        0.04212949052453041,
        0.06026654317975044,
        0.044691119343042374,
        0.061372555792331696,
        0.04691341891884804,
        0.04257681593298912,
        -0.04497428238391876,
        0.014304306358098984,
        0.026420822367072105,
        0.033475201576948166,
        -0.04071388766169548,
        0.04843255877494812,
        -0.062970370054245,
        -0.0012598878238350153,
        -0.06092078983783722,
        -0.025232695043087006,
        -0.04760964959859848,
        0.06092463433742523,
        -0.009647654369473457,
        0.004820378962904215,
        0.04706612974405289,
        -0.05516811087727547,
        -0.03462636098265648,
        0.02861461043357849,
        -0.050154685974121094,
        -0.057569850236177444,
        0.05739739164710045,
        0.056645479053258896,
        -0.00023178322589956224,
        -0.018351465463638306,
        -0.05131831020116806,
        0.05808728188276291,
        0.055354658514261246,
        -0.02508513815701008,
        -0.0023514158092439175,
        0.05517895147204399,
        -0.06003953516483307,
        0.015214676968753338,
        0.022879645228385925,
        0.021834474056959152,
        0.06240865960717201,
        0.05350328981876373,
        -0.049228210002183914,
        0.05979721620678902,
        -0.06072482466697693,
        0.0062069157138466835,
        -0.06311708688735962,
        -0.056718189269304276,
        -0.02621428109705448,
        0.0035669540520757437,
        -0.04329037293791771,
        -0.057524099946022034,
        -0.05463443323969841,
        -0.03248181566596031,
        -0.056642480194568634,
        -0.013250662945210934,
        0.05631685256958008,
        0.00673632649704814,
        0.056318335235118866,
        -0.020363297313451767,
        -0.06075798347592354,
        0.04946347698569298,
        0.0520830973982811,
        -0.03361184149980545,
        -0.05617658793926239,
        0.0036178824957460165,
        -0.056296177208423615,
        0.008063361980021,
        -0.03813394531607628,
        -0.04674772918224335,
        -0.05761628597974777,
        0.025877682492136955,
        0.05251675099134445,
        0.014303662814199924,
        0.062298860400915146,
        -0.026048293337225914,
        0.042237572371959686,
        -0.06285422295331955,
        -0.05681691691279411,
        0.05106312781572342,
        -0.06330575793981552,
        0.04874252900481224,
        0.036239054054021835,
        0.06154936924576759,
        -0.052097808569669724,
        -0.041245631873607635,
        -0.035129666328430176,
        0.048285532742738724,
        -0.03662934899330139,
        -0.026253841817378998,
        0.057644907385110855,
        -0.055114660412073135,
        -0.06318974494934082,
        0.025804651901125908,
        0.026067301630973816,
        0.025945261120796204,
        0.04449333995580673,
        0.03963727876543999,
        -0.055946484208106995,
        -0.06113770604133606,
        -0.004341844003647566,
        -0.0629347637295723,
        -0.05755603685975075,
        0.05280453711748123,
        -0.04996449127793312,
        -0.060321446508169174,
        -0.04970546066761017,
        0.029698658734560013,
        -0.056732628494501114,
        -0.03716573491692543,
        -0.05884748324751854,
        -0.003599579446017742,
        0.05334259942173958,
        0.03841434046626091,
        0.06290179491043091,
        -0.060050372034311295,
        0.04724612459540367,
        -0.026946604251861572,
        -0.061227500438690186,
        0.039799921214580536,
        -0.03766998276114464,
        -0.059198930859565735,
        0.014306113123893738,
        0.01604965515434742,
        -0.011550645343959332,
        0.045101139694452286,
        -0.03674229234457016,
        -0.058490388095378876,
        0.06267775595188141,
        0.04077375307679176,
        0.011999581940472126,
        0.013807802461087704,
        0.04487393796443939,
        -0.0598587729036808,
        -0.01320391520857811,
        0.0633673444390297,
        0.062481340020895004,
        0.049073148518800735,
        0.030235784128308296,
        0.04438801109790802,
        -0.0302369836717844,
        0.050080109387636185,
        -0.018975375220179558,
        -0.048821792006492615,
        -0.028140729293227196,
        -0.04160518944263458,
        0.06229211390018463,
        0.04852932319045067,
        -0.02908209338784218,
        0.03659575432538986,
        0.05716397985816002,
        -0.06339023262262344,
        -0.04318319261074066,
        -0.0034244160633534193,
        -0.030612515285611153,
        0.01462061982601881,
        -0.03759169951081276,
        -0.05965977534651756,
        -0.04326529800891876,
        0.013254140503704548,
        0.012886632233858109,
        0.012707725167274475,
        -0.05909191444516182,
        0.06275191903114319,
        -0.04989934712648392,
        0.03877168148756027,
        0.0374860018491745,
        0.061035968363285065,
        0.06024577096104622,
        0.005252433475106955,
        -0.05607929825782776,
        -0.013856793753802776,
        0.05651778355240822,
        -0.05972468852996826,
        -0.004887860734015703,
        0.06309863924980164,
        0.049176327884197235,
        0.03440454602241516,
        0.06217034161090851,
        -0.04002919793128967,
        -0.019455434754490852,
        0.0214428398758173,
        0.002144488738849759,
        -0.03086869977414608,
        -0.03432155400514603,
        -0.060175541788339615,
        -0.04850374162197113,
        0.04589790105819702,
        0.013389629311859608,
        0.0407542958855629,
        0.05778127163648605,
        -0.03451701998710632,
        -0.01308463979512453,
        0.028094403445720673,
        0.001525092520751059,
        0.06015355512499809,
        -0.06319142878055573,
        0.05066325142979622,
        0.037314288318157196,
        0.014493049122393131,
        -0.0629960298538208,
        -0.04913140833377838,
        0.033936843276023865,
        -0.047186415642499924,
        -0.051641691476106644,
        -0.05197453126311302,
        -0.06102105602622032,
        -0.055545706301927567,
        -0.01091174315661192,
        0.06202181056141853,
        -0.016187015920877457,
        0.04026017338037491,
        0.05142885074019432,
        -0.03399789705872536,
        0.06325618922710419,
        -0.004725947976112366,
        0.05259118229150772,
        0.05524676665663719,
        -0.04855605214834213,
        0.046883586794137955,
        0.057038091123104095,
        0.016082147136330605,
        0.05827072635293007,
        0.013528815470635891,
        -0.041023701429367065,
        -0.06193505972623825,
        0.05249399691820145,
        -0.04706409201025963,
        0.028792815282940865,
        0.05176318436861038,
        -0.050147704780101776,
        -0.017682723701000214,
        -0.05875696986913681,
        -0.059035368263721466,
        -0.05742065608501434,
        0.05354326218366623,
        0.035771649330854416,
        -0.054822348058223724,
        0.057376470416784286,
        -0.04477774351835251,
        0.04040536284446716,
        0.04241522401571274,
        0.042895011603832245,
        0.04366428405046463,
        -0.044129855930805206,
        -0.002213794505223632,
        0.04493936151266098,
        0.023090219125151634,
        0.03200073912739754,
        -0.055999189615249634,
        0.06076556071639061,
        -0.037538569420576096,
        0.0516803003847599,
        0.05711136758327484,
        0.05687818303704262,
        0.010080111213028431,
        -0.04571574181318283,
        0.029327059164643288,
        -0.040097426623106,
        0.019533416256308556,
        0.04041614010930061,
        -0.044680505990982056,
        -0.04970560222864151,
        -0.011804583482444286,
        0.05947112292051315,
        -0.012694808654487133,
        0.01339546125382185,
        -0.04613468423485756,
        -0.04253352805972099,
        -0.06063053384423256,
        -0.013899530284106731,
        -0.06218181923031807,
        0.04208524152636528,
        -0.04225620627403259,
        0.060373879969120026,
        0.011676722206175327,
        0.026161475107073784,
        -0.06181962788105011,
        0.059131279587745667,
        0.004503111820667982,
        0.04028794541954994,
        0.02332310378551483,
        0.056398905813694,
        -0.031145885586738586,
        -0.029778707772493362,
        0.03958980739116669,
        -0.02104831300675869,
        -0.030965158715844154,
        0.05815979465842247,
        0.026276683434844017,
        0.04662824794650078,
        0.05808152258396149,
        -0.0592910535633564,
        0.056501373648643494,
        0.050057947635650635,
        0.03392587602138519,
        -0.05203091353178024,
        -0.043860334903001785,
        0.04011783376336098,
        0.010232902131974697,
        -0.010122335515916348,
        0.031623244285583496,
        -0.06225331500172615,
        0.057819291949272156,
        -0.0020541823469102383,
        -0.027798548340797424,
        -0.010714042000472546,
        -0.05276602506637573,
        0.05390368029475212,
        0.04908565804362297,
        -0.05573541671037674,
        -0.01992635987699032,
        -0.01572170853614807,
        -0.05868466570973396,
        0.04031507670879364,
        0.018225084990262985,
        -0.053197454661130905,
        -0.03089386411011219,
        -0.008357631042599678,
        -0.05987248197197914,
        0.06303255259990692,
        0.0031148989219218493,
        -0.0032131143379956484,
        -0.0632835254073143,
        -0.038430243730545044,
        0.004931391216814518,
        0.04527248442173004,
        0.06138695031404495,
        0.02565658837556839,
        0.04114760085940361,
        0.06112828105688095,
        0.06339539587497711,
        0.046408943831920624,
        -0.052507299929857254,
        -0.031843479722738266,
        -0.04593251645565033,
        -0.06100321561098099,
        -0.0010602992260828614,
        0.05981028825044632,
        -0.008535422384738922,
        -0.032744232565164566,
        -0.05808301642537117,
        -0.02335098199546337,
        -0.04328037053346634,
        -0.05986983701586723,
        0.05886713042855263,
        0.012967212125658989,
        -0.021151339635252953,
        0.00835166685283184,
        0.059488244354724884,
        0.060385752469301224,
        -0.051653459668159485,
        -0.060781534761190414,
        -0.06289790570735931,
        0.004704518709331751,
        -0.0072650909423828125,
        0.020570216700434685,
        -0.0581342987716198,
        -0.047770071774721146,
        0.06104660406708717,
        -0.004001970868557692,
        -0.00195728475227952,
        0.05977322533726692,
        -0.03349766135215759,
        0.011067874729633331,
        -0.059036318212747574,
        -0.045317042618989944,
        -0.042445167899131775,
        0.06236220896244049,
        0.012584419921040535,
        0.03234514221549034,
        -0.06308108568191528,
        -0.03223792836070061,
        -0.009516390971839428,
        -0.012530081905424595,
        -0.0036434014327824116,
        -0.05619486793875694,
        -0.06019037216901779,
        0.05205170065164566,
        0.04216989129781723,
        0.03484044596552849,
        -0.05371517688035965,
        -0.044538404792547226,
        -0.0403735414147377,
        0.009635640308260918,
        -0.011720340698957443,
        0.025561047717928886,
        -0.049424245953559875,
        0.01430810522288084,
        -0.014218438416719437,
        0.009791708551347256,
        0.059248704463243484,
        -0.000296960846753791,
        -0.040317095816135406,
        0.05743703618645668,
        0.06117013469338417,
        0.06337091326713562,
        0.04848548397421837,
        0.058297254145145416,
        0.04682381451129913,
        0.06209767982363701,
        -0.00816083699464798,
        0.028489092364907265,
        -0.058866649866104126,
        0.058268893510103226,
        -0.004294019658118486,
        0.02383076213300228,
        0.0469026155769825,
        0.02397157996892929,
        -0.06019065901637077,
        0.047051314264535904,
        -0.002424657577648759,
        0.057734448462724686,
        0.06029348075389862,
        -0.02089749090373516,
        -0.058590520173311234,
        -0.04283579811453819,
        -0.01571667194366455,
        0.04485222324728966,
        0.06163974851369858,
        -0.04816025123000145,
        0.03624354675412178,
        -0.05498047545552254,
        -0.061413444578647614,
        -0.0001396417646901682,
        -0.0022568474523723125,
        0.03023073822259903,
        -0.04954281821846962,
        -0.05170011147856712,
        -0.03546440228819847,
        0.015705883502960205,
        0.05504909157752991,
        -0.0229107066988945,
        0.05431848019361496,
        0.06255919486284256,
        -0.056103408336639404,
        0.00868949107825756,
        0.0547904446721077,
        0.06043178215622902,
        -0.0025885896757245064,
        0.0052488106302917,
        -0.007086277008056641,
        -0.04333451762795448,
        -0.03429531678557396,
        -0.04208069294691086,
        -0.05594218149781227,
        0.034910473972558975,
        -0.04210402071475983,
        -0.011719373054802418,
        -0.0016468787798658013,
        0.06015288829803467,
        0.05937282368540764,
        0.054893139749765396,
        0.06288795173168182,
        -0.06323443353176117,
        0.04083486273884773,
        -0.03245295584201813,
        0.027879886329174042,
        0.037729039788246155,
        -0.0002079709229292348,
        0.0610569566488266,
        0.02656298689544201,
        -0.05717672035098076,
        -0.003924774471670389,
        0.05004812404513359,
        -0.008397012017667294
    ],
    [
        0.02851339615881443,
        0.029788782820105553,
        0.05211256444454193,
        -0.03178948536515236,
        -0.04553532227873802,
        0.04741547629237175,
        0.05371636152267456,
        -0.05464915931224823,
        0.05095209926366806,
        0.047238100320100784,
        0.05311088636517525,
        0.04354602470993996,
        -0.054223932325839996,
        -0.052755583077669144,
        -0.05348978191614151,
        0.05354167893528938,
        0.054523766040802,
        0.05366363003849983,
        0.043156206607818604,
        -0.045390475541353226,
        0.053606919944286346,
        0.03436799347400665,
        0.050959765911102295,
        -0.05274280160665512,
        -0.001766670262441039,
        0.052196990698575974,
        0.05097775533795357,
        0.05195041000843048,
        -0.02113918960094452,
        -0.03595292940735817,
        0.002135315677151084,
        0.032796431332826614,
        0.0019930421840399504,
        -0.054424501955509186,
        0.05362536758184433,
        -0.054511260241270065,
        -0.05277695134282112,
        -0.04928610101342201,
        -0.05042474716901779,
        -0.05460411310195923,
        0.05291181430220604,
        0.035376545041799545,
        0.04668410122394562,
        0.05377776548266411,
        0.04920737072825432,
        -0.005421636626124382,
        -0.03509071096777916,
        0.05459989234805107,
        0.054680369794368744,
        0.01216147467494011,
        0.05468124523758888,
        0.048812367022037506,
        -0.05411898344755173,
        -0.048828139901161194,
        -0.04874328523874283,
        -0.02169025130569935,
        -0.05457569286227226,
        0.04883085936307907,
        -0.05446317419409752,
        -0.021336939185857773,
        -0.05468575283885002,
        0.012770680710673332,
        -0.05398688092827797,
        0.03684471920132637,
        0.05106638744473457,
        -0.05451923981308937,
        -0.05219126492738724,
        -0.017733588814735413,
        -0.049757812172174454,
        0.04463167116045952,
        -0.026231635361909866,
        -0.03765710070729256,
        0.05449310690164566,
        0.03170223534107208,
        0.054684389382600784,
        -0.024676695466041565,
        0.04498995095491409,
        0.05470618978142738,
        -0.01345291268080473,
        -0.0126184755936265,
        -0.04882463812828064,
        -0.007934043183922768,
        -0.04922671243548393,
        0.05425022169947624,
        -0.04132702201604843,
        0.04617512971162796,
        0.047182295471429825,
        -0.03690949082374573,
        0.03278641402721405,
        0.04677330330014229,
        -0.054327987134456635,
        -0.017184367403388023,
        -0.053128719329833984,
        -0.04810285195708275,
        -0.04869625344872475,
        0.02991323545575142,
        -0.02172635868191719,
        -0.05291546508669853,
        -0.05208596587181091,
        -0.04937223345041275,
        -0.054702673107385635,
        0.05263221636414528,
        0.05392385646700859,
        0.048405446112155914,
        0.049899253994226456,
        -0.048987485468387604,
        -0.05460818484425545,
        0.05376265570521355,
        -0.05109534040093422,
        0.049448009580373764,
        0.013973300345242023,
        0.05204295367002487,
        -0.04692099615931511,
        0.03565897420048714,
        -0.008790399879217148,
        -0.050855908542871475,
        -0.054564088582992554,
        -0.0528908371925354,
        0.054627321660518646,
        -0.05442814156413078,
        0.03157791867852211,
        0.016086943447589874,
        0.054299697279930115,
        -0.054688550531864166,
        -0.05375548452138901,
        0.05407242849469185,
        -0.051957763731479645,
        -0.014501135796308517,
        0.05403022840619087,
        0.02500324510037899,
        -0.008427693508565426,
        -0.05339912325143814,
        0.02783924713730812,
        0.051551904529333115,
        -0.05247845873236656,
        -0.051097095012664795,
        0.054702699184417725,
        0.0476958341896534,
        -0.052044834941625595,
        0.05436994507908821,
        0.021001048386096954,
        -0.051289040595293045,
        0.05054349824786186,
        0.05145954713225365,
        -0.030238617211580276,
        -0.00906248576939106,
        0.04272948578000069,
        -0.013461238704621792,
        -0.051430169492959976,
        0.006418346893042326,
        -0.04163075238466263,
        -0.0541546568274498,
        0.041831668466329575,
        0.007713648956269026,
        -0.03080769255757332,
        -0.05440141260623932,
        -0.05470605939626694,
        -0.042076725512742996,
        0.05450129508972168,
        -0.008944250643253326,
        0.054076503962278366,
        -0.048561736941337585,
        0.05339561402797699,
        0.05157189071178436,
        -0.05443482846021652,
        -0.010182482190430164,
        -0.0246818196028471,
        -0.04420888423919678,
        -0.04197103902697563,
        0.051121294498443604,
        -0.014584770426154137,
        0.053753141313791275,
        -0.01599324494600296,
        -0.005210839677602053,
        0.05465167015790939,
        0.029248908162117004,
        0.05309532582759857,
        -0.026244910433888435,
        0.05441868677735329,
        -0.05322856083512306,
        -0.051797978579998016,
        0.054680489003658295,
        0.020558752119541168,
        -0.0338909737765789,
        -0.04696856439113617,
        0.04378903657197952,
        0.05360639840364456,
        0.05205855518579483,
        -0.03370653837919235,
        -0.05113503336906433,
        -0.05040653795003891,
        -0.046315133571624756,
        0.0538790337741375,
        0.03697953373193741,
        -0.05011206120252609,
        -0.01730213686823845,
        0.05398206785321236,
        -0.05441707745194435,
        -0.04689115285873413,
        0.04460129886865616,
        0.04457811638712883,
        -0.05113667622208595,
        -0.03605234995484352,
        -0.05407719314098358,
        -0.0237108264118433,
        0.03588905930519104,
        0.05290737748146057,
        0.039762090891599655,
        -0.04974746331572533,
        0.04470760002732277,
        -0.051359567791223526,
        0.0005870520835742354,
        0.04401367902755737,
        0.05467889830470085,
        0.027695834636688232,
        -0.04056761413812637,
        0.05029385909438133,
        0.0011595466639846563,
        0.05469754338264465,
        -0.013620274141430855,
        0.042989131063222885,
        0.05455286055803299,
        0.04388586804270744,
        0.028372453525662422,
        0.054622896015644073,
        0.042164623737335205,
        0.05253005772829056,
        -0.04346579313278198,
        0.048028238117694855,
        -0.05294061824679375,
        -0.04414711520075798,
        -0.05416750907897949,
        -0.05359014868736267,
        0.05087127164006233,
        -0.05405990034341812,
        0.05458854138851166,
        0.053134985268116,
        0.014925272203981876,
        -0.009142572991549969,
        0.054684605449438095,
        -0.004444169346243143,
        0.0289189200848341,
        -0.05467839166522026,
        -0.04924369975924492,
        0.054164256900548935,
        -0.042225539684295654,
        -0.05469321087002754,
        0.04088951647281647,
        -0.03125124052166939,
        -0.045839425176382065,
        0.03198852390050888,
        -0.05383343994617462,
        -0.049790456891059875,
        -0.05025781691074371,
        -0.04507368057966232,
        0.04909370467066765,
        -0.038157958537340164,
        0.05117621272802353,
        0.05116098374128342,
        -0.02791404165327549,
        0.03172248974442482,
        -0.0516342893242836,
        -0.048595279455184937,
        0.018568800762295723,
        -0.0532175712287426,
        0.05461807921528816,
        0.05381479486823082,
        0.022148050367832184,
        0.043803870677948,
        -0.04435413330793381,
        0.018259700387716293,
        -0.04899835214018822,
        0.05112779140472412,
        -0.051054541021585464,
        -0.026980126276612282,
        0.01961945928633213,
        -0.037937749177217484,
        -0.020945221185684204,
        0.038380321115255356,
        -0.04444658383727074,
        -0.05443268269300461,
        0.028389086946845055,
        0.006754062604159117,
        0.05445922538638115,
        0.05366034805774689,
        0.013914224691689014,
        0.02623113803565502,
        0.05233529582619667,
        0.049243226647377014,
        0.050918132066726685,
        0.017643995583057404,
        0.03789811208844185,
        -0.05454928055405617,
        -0.05036061257123947,
        0.05081811174750328,
        -0.05388758331537247,
        0.054670803248882294,
        -0.022147927433252335,
        -0.04886994510889053,
        0.0542227178812027,
        -0.050942886620759964,
        -0.005527067929506302,
        -0.0036311058793216944,
        0.04746571555733681,
        -0.030152849853038788,
        0.016386527568101883,
        0.053585804998874664,
        -0.001702039153315127,
        -0.01979932002723217,
        -0.03252072632312775,
        0.049915023148059845,
        -0.05265761911869049,
        0.04653695970773697,
        -0.03447360917925835,
        -0.032500069588422775,
        -0.05408379063010216,
        -0.018509484827518463,
        -0.017494242638349533,
        0.04098832607269287,
        0.017993714660406113,
        0.05433093011379242,
        -0.04970213398337364,
        0.02559959515929222,
        -0.05338296294212341,
        0.05467767268419266,
        -0.00537361204624176,
        0.05213911086320877,
        -0.05160140618681908,
        0.05467455834150314,
        -0.05304207652807236,
        0.03294510766863823,
        -0.031033851206302643,
        -0.054338887333869934,
        -0.04150233790278435,
        0.05103256180882454,
        0.05388021469116211,
        -0.006426532287150621,
        0.03953368589282036,
        0.026517588645219803,
        0.004065910819917917,
        -0.046371638774871826,
        -0.05315843224525452,
        -0.04089224711060524,
        -0.0443965420126915,
        0.04254155978560448,
        0.04555639252066612,
        0.05105872079730034,
        0.05251553654670715,
        -0.04791183024644852,
        0.04466303065419197,
        0.04667336493730545,
        -0.015811394900083542,
        0.043960731476545334,
        -0.0537017285823822,
        0.050201136618852615,
        0.0439058281481266,
        -0.05112829431891441,
        -0.04267312213778496,
        -0.03817296773195267,
        -0.054103974252939224,
        0.039241064339876175,
        -0.024109521880745888,
        0.028569113463163376,
        -0.040476102381944656,
        -0.03426090627908707,
        -0.054688792675733566,
        0.054554931819438934,
        -0.01971915364265442,
        -0.002246646909043193,
        -0.05433470383286476,
        -0.0405796617269516,
        -0.05228891968727112,
        0.053705524653196335,
        0.05323559045791626,
        -0.024248866364359856,
        0.05334433540701866,
        -0.05056555196642876,
        0.05411233380436897,
        0.053571704775094986,
        -0.04973515123128891,
        -0.05425480380654335,
        0.037255533039569855,
        -0.050013624131679535,
        0.036690227687358856,
        0.03794713690876961,
        -0.0530572272837162,
        -0.050094909965991974,
        -0.04267876595258713,
        -0.0510905459523201,
        -0.04501068592071533,
        -0.05465748533606529,
        0.015440495684742928,
        -0.05227673426270485,
        -0.054607830941677094,
        0.052824027836322784,
        0.05341705307364464,
        0.05452620983123779,
        -0.014893871732056141,
        -0.05065138265490532,
        -0.05466509237885475,
        -0.04648121818900108,
        0.05308987945318222,
        -0.0510200597345829,
        -0.007794312201440334,
        -0.0035196682438254356,
        0.03819424659013748,
        0.04832083731889725,
        -0.05239948630332947,
        0.05469429865479469,
        0.025968117639422417,
        0.0020665654446929693,
        -0.02560395747423172,
        -0.042926233261823654,
        -0.02653515338897705,
        0.05368516221642494,
        -0.05255672335624695,
        0.054633431136608124,
        0.0018644483061507344,
        0.034019384533166885,
        -0.002538852859288454,
        0.053793828934431076,
        -0.04631120339035988,
        -0.04966279864311218,
        -0.05468348413705826,
        0.004750899039208889,
        0.0019278238760307431,
        -0.0538741797208786,
        -0.028660951182246208,
        -0.05111360922455788,
        -0.05181566998362541,
        -0.03825227543711662,
        0.03457457944750786,
        -0.012050682678818703,
        0.009613762609660625,
        -0.053643323481082916,
        -0.04861264303326607,
        -0.05463842675089836,
        -0.005814030300825834,
        -0.03210918605327606,
        -0.053669776767492294,
        -0.04097142443060875,
        0.05389004200696945,
        0.05418645218014717,
        0.0533071793615818,
        0.02966802753508091,
        -0.050825320184230804,
        0.054566122591495514,
        -0.04721669852733612,
        0.04033045470714569,
        0.0544806607067585,
        0.051677580922842026,
        0.04880571365356445,
        0.05215690657496452,
        0.05430653691291809,
        0.028719080612063408,
        -0.05441277101635933,
        0.033501919358968735,
        -0.053898949176073074,
        0.04711499065160751,
        0.05400247126817703,
        0.053345415741205215,
        -0.054522089660167694,
        0.020913856104016304,
        -0.048375971615314484,
        0.04723701998591423,
        0.05436059087514877,
        -0.05388535559177399,
        0.054624348878860474,
        -0.03822094202041626,
        -0.054557885974645615,
        0.048211369663476944,
        -0.05083822086453438,
        0.049671586602926254,
        0.013807637616991997,
        -0.05429430305957794,
        -0.051590610295534134,
        -0.04115781560540199,
        0.0493963398039341,
        -0.047049134969711304,
        0.010793538764119148,
        0.051244135946035385,
        -0.03510349616408348,
        0.007950391620397568,
        0.023072822019457817,
        -0.03200562298297882,
        0.053675178438425064,
        0.026097720488905907,
        0.034016065299510956,
        -0.05302947387099266,
        -0.04890827834606171,
        -0.05230681225657463,
        -0.05018208920955658,
        0.05109624192118645,
        -0.041037388145923615,
        -0.011402612552046776,
        0.02864607237279415,
        -0.03190768510103226,
        0.0542854405939579,
        0.05297653749585152,
        0.053224388509988785,
        -0.04800153151154518,
        -0.029325678944587708,
        -0.054321128875017166,
        -0.03357387334108353,
        0.05386049672961235,
        0.04006504267454147,
        0.05380717292428017,
        0.018250085413455963,
        0.05436338856816292,
        -0.05383129045367241,
        0.0060561830177903175,
        -0.026313431560993195
    ],
    [
        0.03316182643175125,
        0.04789160192012787,
        0.04799323529005051,
        0.013396285474300385,
        -0.014879757538437843,
        -0.006745524238795042,
        0.05913340672850609,
        -0.04557414352893829,
        0.0585179477930069,
        0.04628642275929451,
        0.040247593075037,
        0.018968872725963593,
        -0.053260140120983124,
        -0.053511325269937515,
        -0.05905044078826904,
        0.04383138567209244,
        0.03567693382501602,
        0.05142529681324959,
        0.05325041338801384,
        -0.02224363386631012,
        0.055701013654470444,
        0.034650836139917374,
        0.050199393182992935,
        -0.05506347864866257,
        0.025404809042811394,
        0.05706726759672165,
        0.04797752946615219,
        -0.0023353768046945333,
        0.051124490797519684,
        -0.049412161111831665,
        -0.0018496084958314896,
        0.043930619955062866,
        0.05572878196835518,
        -0.0560624785721302,
        0.048432134091854095,
        -0.056320372968912125,
        -0.05873537436127663,
        -0.05943549796938896,
        0.024026626721024513,
        -0.056008268147706985,
        0.028423596173524857,
        0.05490083619952202,
        0.05273846164345741,
        0.011825313791632652,
        0.035058870911598206,
        0.005256033968180418,
        -0.0557524599134922,
        0.06035839021205902,
        0.05886762961745262,
        0.019890889525413513,
        0.05436572805047035,
        0.054452475160360336,
        -0.05870567634701729,
        -0.035375408828258514,
        -0.014228449203073978,
        0.053206413984298706,
        -0.05663357302546501,
        -0.006940733641386032,
        -0.007856346666812897,
        -0.05649985745549202,
        -0.058733388781547546,
        0.020458100363612175,
        -0.04605058580636978,
        0.05856186896562576,
        0.05034663528203964,
        -0.06026114895939827,
        -0.05601521581411362,
        -0.03726710006594658,
        -0.03018980659544468,
        0.04968276992440224,
        -0.011512011289596558,
        0.017696857452392578,
        0.05825548991560936,
        0.05861721187829971,
        0.05857736989855766,
        -0.05578627809882164,
        -0.01829502545297146,
        0.060378547757864,
        -0.04932335764169693,
        0.016652483493089676,
        -0.053291600197553635,
        -0.038316186517477036,
        -0.03857898339629173,
        0.05172957852482796,
        -0.05576254427433014,
        0.05431175231933594,
        0.0574188269674778,
        -0.04306638985872269,
        -0.03861818090081215,
        0.05851108580827713,
        -0.05669662356376648,
        -0.022022603079676628,
        -0.04125045984983444,
        -0.023064762353897095,
        -0.04599212110042572,
        0.025272082537412643,
        -0.019712163135409355,
        -0.0601683035492897,
        -0.05301019921898842,
        -0.003062882926315069,
        -0.054223936051130295,
        0.004277227912098169,
        0.05452913045883179,
        0.018871119245886803,
        0.03457896038889885,
        -0.016008034348487854,
        -0.05756209045648575,
        0.013047415763139725,
        0.03189829736948013,
        0.05761290714144707,
        0.04712987318634987,
        0.051558125764131546,
        -0.04582739248871803,
        0.012152002193033695,
        -0.036576759070158005,
        -0.004529101774096489,
        -0.05946061760187149,
        -0.003675593063235283,
        0.06035197526216507,
        0.04039100930094719,
        0.05779549852013588,
        -0.050107091665267944,
        0.05509141832590103,
        -0.05967577546834946,
        -0.05674516409635544,
        0.04204193502664566,
        -0.05736249312758446,
        0.0015898187411949039,
        0.057296305894851685,
        0.032996319234371185,
        -0.04164290800690651,
        -0.02521681971848011,
        0.04015830159187317,
        0.056745246052742004,
        -0.03788653016090393,
        -0.05184910073876381,
        0.04795870557427406,
        0.026583513244986534,
        -0.05429359897971153,
        0.0581342913210392,
        0.026343731209635735,
        -0.05877194553613663,
        0.05683916434645653,
        0.06019781529903412,
        -0.04293016716837883,
        -0.015434641391038895,
        -0.032926492393016815,
        -0.0005308280233293772,
        0.028685007244348526,
        -0.016788117587566376,
        0.03788764402270317,
        -0.05286377668380737,
        -0.002760347444564104,
        -0.02512957714498043,
        -0.04192613810300827,
        -0.046626683324575424,
        -0.06000181660056114,
        -0.014945176430046558,
        0.05749436467885971,
        -0.024572152644395828,
        0.05572663992643356,
        0.011073228903114796,
        0.05933940038084984,
        0.014311091043055058,
        -0.05931839719414711,
        0.04651515558362007,
        -0.058035556226968765,
        0.010424205102026463,
        -0.009250879287719727,
        0.02458047680556774,
        -0.04480621591210365,
        0.05896086245775223,
        -0.01565026119351387,
        -0.030513469129800797,
        0.06022225320339203,
        -0.05788294970989227,
        0.058853622525930405,
        -0.002305838279426098,
        0.05689474940299988,
        -0.026474686339497566,
        -0.051363855600357056,
        0.060218263417482376,
        0.019723504781723022,
        -0.05083082243800163,
        -0.03481948375701904,
        0.05751094967126846,
        0.04076257348060608,
        0.058922331780195236,
        -0.025903064757585526,
        -0.056429266929626465,
        0.008204355835914612,
        -0.05723590776324272,
        0.04569767415523529,
        0.04579555243253708,
        -0.05916822329163551,
        -0.0005406290874816477,
        0.05396043136715889,
        -0.06031199172139168,
        -0.05824637785553932,
        0.0033270905259996653,
        0.02177710272371769,
        -0.040777698159217834,
        0.03400634601712227,
        -0.05845877155661583,
        -0.045947179198265076,
        0.019163453951478004,
        0.05087679997086525,
        0.05834294855594635,
        -0.036592572927474976,
        0.05949968472123146,
        -0.037526730448007584,
        -0.02125212922692299,
        0.059393201023340225,
        0.06000398099422455,
        0.011485348455607891,
        -0.02833428606390953,
        0.011194415390491486,
        -0.02693966031074524,
        0.05848923698067665,
        0.01435061451047659,
        0.03437889367341995,
        0.06034659594297409,
        -0.044803038239479065,
        -0.024017056450247765,
        0.05988957732915878,
        -0.02976991981267929,
        0.033794187009334564,
        0.02167421206831932,
        -0.0009350393665954471,
        -0.052949465811252594,
        -0.04189073294401169,
        -0.057716429233551025,
        -0.04283443093299866,
        0.05672437325119972,
        -0.05361146107316017,
        0.059305500239133835,
        0.04545104131102562,
        0.015287530608475208,
        -0.022624021396040916,
        0.06036203354597092,
        -0.01811755634844303,
        0.05753118917346001,
        -0.060347672551870346,
        0.029472026973962784,
        0.05353834107518196,
        -0.05386911705136299,
        -0.06024077534675598,
        -0.036967333406209946,
        0.04230475425720215,
        -0.05976711958646774,
        0.050839509814977646,
        -0.0592716746032238,
        -0.05963059142231941,
        -0.05001133680343628,
        -0.03984052315354347,
        0.053469058126211166,
        -0.04261663183569908,
        0.04000133275985718,
        0.04723998159170151,
        0.009191397577524185,
        0.059654466807842255,
        -0.05719348415732384,
        -0.02702401950955391,
        -0.018725724890828133,
        -0.03949431702494621,
        0.056917063891887665,
        0.05855005607008934,
        0.053497713059186935,
        0.018277233466506004,
        -0.05257442593574524,
        0.05760210379958153,
        -0.009741324000060558,
        0.0005343498778529465,
        -0.05573571100831032,
        -0.028654763475060463,
        -0.007293949835002422,
        -0.0558401457965374,
        0.03074674680829048,
        0.02649502083659172,
        -0.022179778665304184,
        -0.05966874212026596,
        0.053478214889764786,
        0.022019030526280403,
        0.056582313030958176,
        0.055015094578266144,
        -0.035897426307201385,
        0.043966930359601974,
        0.05672784522175789,
        0.04581702873110771,
        0.043828822672367096,
        -0.043798770755529404,
        0.05266060307621956,
        0.014761646278202534,
        -0.0025604641996324062,
        0.055176761001348495,
        -0.05849197134375572,
        0.05822283774614334,
        0.04475551098585129,
        0.02307620830833912,
        0.05529257655143738,
        -0.05974898859858513,
        -0.014151244424283504,
        0.00034441685420461,
        0.05944424495100975,
        -0.023381007835268974,
        0.013150013983249664,
        0.026258165016770363,
        -0.05422472581267357,
        0.05198558419942856,
        -0.0072148749604821205,
        -0.010128246620297432,
        -0.0371406190097332,
        0.031153080984950066,
        -0.0006213352899067104,
        -0.05855164676904678,
        -0.05725841969251633,
        -0.025768473744392395,
        -0.05645329877734184,
        0.04721437022089958,
        -0.029575368389487267,
        0.04699046164751053,
        0.017947910353541374,
        -0.03341500833630562,
        -0.05468114838004112,
        0.054262902587652206,
        -0.03209606185555458,
        0.0399450846016407,
        -0.052319351583719254,
        0.05981483310461044,
        -0.05923563614487648,
        0.05644487962126732,
        -0.048230502754449844,
        -0.059400588274002075,
        -0.005111044272780418,
        0.055469810962677,
        0.042038071900606155,
        0.04825875163078308,
        0.04229124262928963,
        -0.018013719469308853,
        -0.006777299102395773,
        -0.03324625641107559,
        0.022127049043774605,
        0.025368493050336838,
        0.02733505330979824,
        -0.044360172003507614,
        -0.010368245653808117,
        0.05874043330550194,
        0.06025557965040207,
        -0.05813812091946602,
        0.05016765370965004,
        0.058565083891153336,
        0.022232072427868843,
        -0.03800367936491966,
        -0.044106513261795044,
        0.05860031396150589,
        -0.015582851134240627,
        -0.05700833350419998,
        -0.03650059551000595,
        0.0009734472841955721,
        -0.05803285911679268,
        0.0487704835832119,
        0.032932765781879425,
        -0.004297430161386728,
        -0.04740668833255768,
        0.020733598619699478,
        -0.05685758590698242,
        0.05919117480516434,
        0.04309574142098427,
        0.05913395434617996,
        -0.056783948093652725,
        -0.030829843133687973,
        0.012956012971699238,
        0.031428780406713486,
        0.05861462652683258,
        0.052030954509973526,
        -0.006836866959929466,
        0.0476088710129261,
        0.0544249527156353,
        0.0549929141998291,
        -0.033267635852098465,
        -0.03657926619052887,
        0.02716732583940029,
        -0.05494844913482666,
        0.035708244889974594,
        0.013188824988901615,
        0.005967543460428715,
        -0.03397921100258827,
        -0.054639145731925964,
        -0.056135863065719604,
        0.012425240129232407,
        -0.05761922523379326,
        0.018506551161408424,
        -0.01570252887904644,
        -0.058610979467630386,
        -0.009498454630374908,
        0.05756876617670059,
        0.06016732007265091,
        -0.03375121206045151,
        -0.059349220246076584,
        -0.05976870283484459,
        -0.03571857139468193,
        0.05900942534208298,
        -0.040817610919475555,
        -0.002573181176558137,
        -0.05629781261086464,
        0.055594440549612045,
        0.03674374520778656,
        -0.027260171249508858,
        0.05689080059528351,
        -0.04335985705256462,
        0.02141614817082882,
        -0.03397637605667114,
        0.03748345002532005,
        0.01569385826587677,
        0.05985730141401291,
        -0.05392691120505333,
        0.040779080241918564,
        -0.0379570908844471,
        0.0023741659242659807,
        0.049101538956165314,
        0.0547296479344368,
        -0.007296557072550058,
        -0.034341949969530106,
        -0.05842166766524315,
        -0.03679847717285156,
        0.035075653344392776,
        -0.0536767914891243,
        0.02839437499642372,
        0.016996102407574654,
        -0.059504128992557526,
        -0.01773686893284321,
        0.04136873781681061,
        0.04147961363196373,
        -0.05158030986785889,
        -0.057485878467559814,
        -0.056471992284059525,
        -0.05160263553261757,
        -0.054303377866744995,
        0.05290256813168526,
        -0.050035733729600906,
        0.043212223798036575,
        0.04487573355436325,
        0.05996023118495941,
        0.05516189709305763,
        0.05511641874909401,
        -0.0160788856446743,
        0.05551199987530708,
        -0.03645152971148491,
        0.05189048871397972,
        -0.038375839591026306,
        0.0584680512547493,
        0.043845634907484055,
        0.030542051419615746,
        0.060153648257255554,
        -0.04339494928717613,
        -0.0592750646173954,
        0.03157399222254753,
        -0.03940385952591896,
        0.05997456982731819,
        0.0595359280705452,
        0.05764893814921379,
        -0.059371717274188995,
        -0.016975082457065582,
        -0.04937585070729256,
        0.053414471447467804,
        0.05944499373435974,
        0.023979797959327698,
        0.05588899552822113,
        -0.02693764679133892,
        -0.056077394634485245,
        0.004175384063273668,
        0.032933883368968964,
        0.04338027536869049,
        -0.025689421221613884,
        -0.03931105509400368,
        -0.011051231995224953,
        -0.006424602586776018,
        0.05907260999083519,
        -0.021261418238282204,
        0.05715564265847206,
        0.05224526673555374,
        -0.033633433282375336,
        -0.04507727175951004,
        0.04181653633713722,
        0.026972679421305656,
        0.03423274680972099,
        -0.02131577767431736,
        0.05662927404046059,
        -0.05730750784277916,
        0.016895901411771774,
        -0.02063552848994732,
        0.00271326070651412,
        0.06033499166369438,
        -0.05665971711277962,
        0.03658294305205345,
        0.016911514103412628,
        0.04196476936340332,
        0.04282224178314209,
        0.03972472622990608,
        0.059283845126628876,
        0.04608368128538132,
        -0.011058846488595009,
        -0.05593439191579819,
        0.03678707778453827,
        -0.03315286338329315,
        0.026217050850391388,
        0.05864039063453674,
        -0.051110994070768356,
        0.05725589022040367,
        -0.054324157536029816,
        -0.05800655111670494,
        -0.022234342992305756
    ],
    [
        -0.03295675292611122,
        0.03789621964097023,
        0.05483941733837128,
        0.008683604188263416,
        -0.0067644864320755005,
        -0.04790389537811279,
        0.05525441840291023,
        -0.028211062774062157,
        0.030687371268868446,
        0.03574522212147713,
        0.05509781092405319,
        0.05438610911369324,
        -0.05458737164735794,
        -0.02979150600731373,
        -0.05506722256541252,
        0.04422229155898094,
        0.04801115021109581,
        0.05517567694187164,
        0.05209043249487877,
        -0.05237573757767677,
        0.05476844310760498,
        0.05443834140896797,
        0.05520738288760185,
        -0.05518142506480217,
        0.05186377093195915,
        0.04892697185277939,
        0.048855654895305634,
        -0.030403614044189453,
        0.030160319060087204,
        -0.036094680428504944,
        -0.0228124912828207,
        -0.05439131334424019,
        0.023860007524490356,
        -0.054148539900779724,
        0.05422639101743698,
        -0.05403727665543556,
        -0.051903754472732544,
        -0.015327855944633484,
        -0.04871828854084015,
        -0.05507142096757889,
        -0.049237046390771866,
        0.019584497436881065,
        0.050821203738451004,
        0.052252065390348434,
        0.010492544621229172,
        0.0341767780482769,
        0.017830543220043182,
        0.037961699068546295,
        0.054549746215343475,
        0.029080601409077644,
        0.05389607697725296,
        0.020961198955774307,
        -0.045587439090013504,
        -0.051690489053726196,
        -0.03651617839932442,
        0.04929161071777344,
        -0.05520310625433922,
        -0.03578302636742592,
        -0.054534830152988434,
        -0.004637450911104679,
        -0.050533004105091095,
        -0.019008003175258636,
        -0.05289234593510628,
        0.05394795909523964,
        -0.05423624441027641,
        -0.05457732826471329,
        -0.044950198382139206,
        -0.053065329790115356,
        -0.05482430383563042,
        0.05427610129117966,
        0.04613862559199333,
        -0.050036560744047165,
        0.05512639880180359,
        0.05188407748937607,
        0.05501510202884674,
        0.019000820815563202,
        0.05196140706539154,
        0.05477841570973396,
        -0.02214428409934044,
        0.04701551795005798,
        -0.04187298193573952,
        0.049921777099370956,
        -0.04825689271092415,
        0.03581392019987106,
        -0.05433978512883186,
        0.05270100012421608,
        0.045400992035865784,
        -0.055151235312223434,
        0.04131393879652023,
        0.05527765676379204,
        0.001084827003069222,
        0.01972729153931141,
        -0.05493452772498131,
        0.018335584551095963,
        -0.0060590412467718124,
        -0.014185956679284573,
        -0.04336181655526161,
        -0.05429529771208763,
        -0.02431846782565117,
        0.050649337470531464,
        -0.04419615492224693,
        -0.0001547904103063047,
        0.05442580580711365,
        0.04215741157531738,
        0.055209845304489136,
        0.008863899856805801,
        -0.05462777242064476,
        0.03454609215259552,
        -0.048582881689071655,
        0.0018706029513850808,
        0.05449571833014488,
        0.013848068192601204,
        -0.03398475423455238,
        0.018314963206648827,
        0.05194970965385437,
        -0.046634476631879807,
        -0.05515617877244949,
        0.03448161855340004,
        0.05529962107539177,
        -0.04704964533448219,
        0.03814174234867096,
        0.0373883917927742,
        0.01227844413369894,
        -0.05428953096270561,
        -0.04634261876344681,
        0.042261600494384766,
        -0.05490628257393837,
        -0.05391980707645416,
        0.03664155676960945,
        0.04124342277646065,
        0.03617733344435692,
        -0.054181359708309174,
        0.0020551455672830343,
        0.055072881281375885,
        -0.053543925285339355,
        -0.0548427477478981,
        0.05486522242426872,
        -0.02957986108958721,
        -0.016321972012519836,
        0.04904155805706978,
        -0.005549130029976368,
        -0.05527787655591965,
        0.04622812941670418,
        0.05222809687256813,
        -0.05454171821475029,
        0.0549810454249382,
        -0.051244474947452545,
        -0.05005311220884323,
        -0.005707713309675455,
        -0.04170525446534157,
        0.04467477276921272,
        -0.05451506748795509,
        -0.055041853338479996,
        -0.050112783908843994,
        0.034068286418914795,
        0.05034098029136658,
        -0.0547134131193161,
        -0.048769254237413406,
        0.05512811243534088,
        0.0022456031292676926,
        0.028653856366872787,
        -0.05300689861178398,
        0.054924141615629196,
        -0.02651304565370083,
        -0.0549519918859005,
        -0.04441206157207489,
        -0.054847124963998795,
        -0.009277839213609695,
        0.04298875480890274,
        0.05256900191307068,
        -0.055209919810295105,
        0.03164530172944069,
        -0.053442440927028656,
        0.007874857634305954,
        0.055283501744270325,
        -0.05380873754620552,
        0.055238544940948486,
        -0.05442914739251137,
        0.05033770576119423,
        -0.05264968052506447,
        -0.00566908298060298,
        0.05412228778004646,
        0.010333060286939144,
        0.03547714278101921,
        -0.04194698482751846,
        0.045056283473968506,
        -0.026281660422682762,
        0.05409899726510048,
        -0.053985610604286194,
        -0.05523647740483284,
        -0.04339485615491867,
        -0.04500585049390793,
        0.045632991939783096,
        -0.017553236335515976,
        0.008935079909861088,
        -0.018521862104535103,
        0.05525285005569458,
        -0.05522403493523598,
        -0.05525917187333107,
        0.045909300446510315,
        0.05525561794638634,
        -0.05487395077943802,
        0.03721099719405174,
        -0.03612212464213371,
        -0.04235730692744255,
        0.03558187186717987,
        0.013266717083752155,
        0.05195637792348862,
        -0.050424784421920776,
        -0.055070359259843826,
        -0.050064168870449066,
        -0.005396790336817503,
        0.05305701494216919,
        0.05525708198547363,
        0.03440146893262863,
        0.04695864021778107,
        0.05052940919995308,
        -0.03757794573903084,
        0.0552917942404747,
        0.053562745451927185,
        0.036900993436574936,
        0.05529991537332535,
        -0.05504732206463814,
        0.015984026715159416,
        0.055295638740062714,
        -0.04903700202703476,
        -0.005441734567284584,
        0.050694361329078674,
        0.054876260459423065,
        -0.04713994264602661,
        0.05297485738992691,
        -0.05526261404156685,
        -0.045597076416015625,
        0.04477900266647339,
        -0.05449952930212021,
        0.05518593266606331,
        0.05364171043038368,
        -0.01548691000789404,
        0.01973961852490902,
        0.05492599308490753,
        0.05486355721950531,
        -0.019295765087008476,
        -0.05529880151152611,
        0.05198244750499725,
        0.0550931841135025,
        0.0519506111741066,
        -0.05508076772093773,
        -0.050041258335113525,
        -0.0013241921551525593,
        -0.055228233337402344,
        0.05526817962527275,
        -0.0536697618663311,
        -0.05486850440502167,
        -0.05154769867658615,
        -0.05529473349452019,
        0.054706454277038574,
        0.05252387747168541,
        0.015473161824047565,
        0.03881961107254028,
        -0.034950029104948044,
        0.043426264077425,
        -0.053752850741147995,
        -0.026734596118330956,
        -0.030262256041169167,
        -0.053936075419187546,
        0.05405939742922783,
        0.020670657977461815,
        0.0007996595231816173,
        0.050222642719745636,
        -0.05520210415124893,
        0.0020562661811709404,
        -0.029743194580078125,
        0.046483494341373444,
        -0.053233470767736435,
        0.04322649911046028,
        0.043050944805145264,
        -0.053361136466264725,
        -0.05355234071612358,
        0.004797585308551788,
        -0.04570312798023224,
        -0.05520031228661537,
        -0.05246364325284958,
        0.004839210771024227,
        0.0532974898815155,
        0.055251769721508026,
        -0.01584227755665779,
        0.05504549667239189,
        0.0545479916036129,
        0.05492110922932625,
        0.05515372380614281,
        -0.053846511989831924,
        0.055167194455862045,
        -0.04230323061347008,
        -0.02146603725850582,
        0.05508086830377579,
        -0.05374372377991676,
        0.043577589094638824,
        0.04387115314602852,
        -0.025685220956802368,
        0.05332237482070923,
        -0.028532670810818672,
        0.03724056854844093,
        -0.03801447153091431,
        0.05526019632816315,
        0.010018597356975079,
        0.04683927446603775,
        -0.012107394635677338,
        0.010363185778260231,
        0.0009290272137150168,
        -0.049864549189805984,
        -0.013911806978285313,
        -0.05517343431711197,
        0.03925897926092148,
        -0.04059332236647606,
        -0.03967942297458649,
        -0.055096499621868134,
        -0.005698021035641432,
        0.03279624879360199,
        0.05087795481085777,
        0.03596985340118408,
        0.05194781720638275,
        -0.05451153218746185,
        0.05432845279574394,
        0.02587396278977394,
        0.05526339262723923,
        0.02149411477148533,
        0.05493476241827011,
        0.011601603589951992,
        0.05518342927098274,
        -0.046876825392246246,
        -0.025532273575663567,
        -0.02484273351728916,
        -0.0037962475325912237,
        -0.028528517112135887,
        0.028827698901295662,
        0.04325148090720177,
        -0.05176549032330513,
        -0.01731402426958084,
        -0.000919585581868887,
        0.051718056201934814,
        0.05144631117582321,
        0.027403496205806732,
        0.028392449021339417,
        -0.02751763164997101,
        0.033728767186403275,
        0.028211941942572594,
        0.03274191915988922,
        0.055241841822862625,
        -0.039978329092264175,
        0.048160191625356674,
        0.05503195524215698,
        -0.01215625274926424,
        0.040274981409311295,
        -0.05241832137107849,
        -0.003902923781424761,
        -0.01938808523118496,
        -0.045313429087400436,
        -0.05486876890063286,
        -0.030196504667401314,
        -0.04258390888571739,
        0.051935240626335144,
        -0.026511820033192635,
        -0.0543145015835762,
        -0.04786854609847069,
        -0.024523314088582993,
        -0.054863449186086655,
        0.05523714795708656,
        -0.047987740486860275,
        0.05524645000696182,
        -0.05519136041402817,
        -0.033228158950805664,
        -0.051069725304841995,
        0.055294476449489594,
        0.05520806461572647,
        0.05513739958405495,
        0.05524194613099098,
        0.05498416721820831,
        -0.03022485412657261,
        0.04987836256623268,
        -0.04797153174877167,
        -0.008337928913533688,
        -0.03093499131500721,
        -0.041006822139024734,
        0.008448909036815166,
        -0.0441623218357563,
        -0.04608314484357834,
        -0.019667774438858032,
        -0.030387964099645615,
        -0.047130417078733444,
        -0.05433034151792526,
        -0.05512997508049011,
        0.05067408084869385,
        0.04675116762518883,
        -0.05522088333964348,
        -0.0043447306379675865,
        0.05015246942639351,
        0.055147286504507065,
        0.05227368324995041,
        -0.04105379432439804,
        -0.0546419620513916,
        -0.03577245771884918,
        0.04499834030866623,
        -0.04945208504796028,
        0.005953897722065449,
        -0.0009281871025450528,
        0.05501967668533325,
        0.05415550619363785,
        -0.03137390688061714,
        0.047295838594436646,
        -0.051917750388383865,
        -0.05008098483085632,
        -0.021057261154055595,
        -0.04321495071053505,
        0.026312755420804024,
        0.053190719336271286,
        -0.044151823967695236,
        0.05467440187931061,
        0.05524152144789696,
        -0.030868811532855034,
        0.05424155294895172,
        0.055284880101680756,
        -0.03561387583613396,
        -0.0530538409948349,
        -0.05529114231467247,
        0.04358528554439545,
        0.04046476632356644,
        -0.053651973605155945,
        -0.036809153854846954,
        -0.054841093719005585,
        -0.05420980975031853,
        0.05462398752570152,
        -0.05398187413811684,
        -0.026646362617611885,
        -0.05441202595829964,
        -0.0483020544052124,
        -0.04502373933792114,
        -0.055271320044994354,
        0.04178210347890854,
        0.009963038377463818,
        -0.018719283863902092,
        0.05514701455831528,
        0.054892636835575104,
        0.05391925200819969,
        0.05065828934311867,
        -0.02055211365222931,
        -0.015331460162997246,
        0.053961846977472305,
        -0.05268090218305588,
        0.05274994671344757,
        0.028932763263583183,
        0.05408889800310135,
        0.05250184237957001,
        0.008316622115671635,
        0.05298934876918793,
        0.05518897995352745,
        -0.05415550619363785,
        0.03404845669865608,
        -0.013112184591591358,
        0.032813362777233124,
        0.009953656233847141,
        -0.010788651183247566,
        -0.055178724229335785,
        0.05411872640252113,
        -0.050659604370594025,
        0.05529698356986046,
        0.05513276532292366,
        -0.04627548158168793,
        0.046076223254203796,
        -0.05526091158390045,
        -0.05448533594608307,
        0.03257869929075241,
        0.04401242733001709,
        0.0451396107673645,
        0.055178433656692505,
        -0.016956789419054985,
        0.03794769197702408,
        -0.028253167867660522,
        0.05331840738654137,
        0.011889394372701645,
        0.032366737723350525,
        0.05120022967457771,
        -0.04373239725828171,
        0.03167112544178963,
        0.04431474581360817,
        -0.035404376685619354,
        0.031182900071144104,
        0.04922777786850929,
        0.05487419292330742,
        -0.05300973355770111,
        -0.04487495869398117,
        0.03331070393323898,
        -0.03728059306740761,
        0.055286113172769547,
        -0.04484804347157478,
        0.014933263882994652,
        -0.012724832631647587,
        0.016701877117156982,
        0.05527373403310776,
        0.03784053400158882,
        0.05529090762138367,
        0.03088308311998844,
        -0.03548664227128029,
        0.04406672343611717,
        -0.04055821895599365,
        0.02323613502085209,
        0.03309590741991997,
        0.05383367836475372,
        0.006491291802376509,
        0.054753948003053665,
        0.004658056423068047,
        0.041500274091959,
        0.05437145754694939
    ],
    [
        -0.06153184920549393,
        0.05585585907101631,
        0.05994855612516403,
        0.035566993057727814,
        0.04468121379613876,
        0.03761311620473862,
        0.06115617975592613,
        -0.0309195164591074,
        0.039017677307128906,
        0.06172960251569748,
        0.05837302654981613,
        0.0564485564827919,
        -0.05455233156681061,
        -0.02183849923312664,
        -0.04835580661892891,
        -0.002896761056035757,
        -0.04185286536812782,
        0.052484799176454544,
        -0.05181991308927536,
        0.047238584607839584,
        -0.032033391296863556,
        0.05784620717167854,
        0.05771428719162941,
        -0.016378575935959816,
        0.06132412329316139,
        0.03256119787693024,
        0.03702044486999512,
        0.05872558429837227,
        -0.06174904853105545,
        -0.05057201534509659,
        0.03716101869940758,
        0.04064236581325531,
        -0.002398963551968336,
        -0.04730784147977829,
        0.0015183979412540793,
        -0.05993677303195,
        -0.058634039014577866,
        -0.05700995773077011,
        0.01365208625793457,
        0.03542806953191757,
        -0.035959530621767044,
        -0.003119214903563261,
        0.04396388679742813,
        -0.049400750547647476,
        0.055642373859882355,
        -0.026080526411533356,
        0.04819311946630478,
        0.06020234897732735,
        0.04953521490097046,
        0.05764323100447655,
        -0.044160179793834686,
        0.026053734123706818,
        0.017127089202404022,
        -0.010197310708463192,
        0.05593402683734894,
        -0.06126142665743828,
        -0.026940355077385902,
        -0.03894977271556854,
        -0.0492841899394989,
        0.057970061898231506,
        -0.05238308012485504,
        0.019223975017666817,
        -0.0444982685148716,
        0.044077880680561066,
        -0.029896510764956474,
        -0.012220576405525208,
        0.040024373680353165,
        -0.04448116198182106,
        -0.015541543252766132,
        -0.059933628886938095,
        -0.05566273629665375,
        -0.057515740394592285,
        0.05509326234459877,
        0.033597711473703384,
        -0.05959333851933479,
        0.01211145892739296,
        0.05641923472285271,
        0.04840618371963501,
        0.035335756838321686,
        -0.042529474943876266,
        -0.032385602593421936,
        0.06066261976957321,
        -0.05518108606338501,
        0.011310847476124763,
        -0.001017746515572071,
        0.019879309460520744,
        0.040168263018131256,
        0.0234562698751688,
        -0.005316376686096191,
        0.06264586746692657,
        -0.05732330307364464,
        0.03153765946626663,
        -0.06058860942721367,
        -0.04220227524638176,
        0.025293929502367973,
        -0.049025390297174454,
        -0.030336294323205948,
        -0.03751291334629059,
        -0.04154364764690399,
        0.047426629811525345,
        -0.06227397173643112,
        -0.00019850015814881772,
        0.06119484826922417,
        0.044336579740047455,
        0.06205596774816513,
        -0.053774721920490265,
        -0.0005785047542303801,
        0.016949549317359924,
        -0.0055352249182760715,
        -0.03185506537556648,
        -0.05082528665661812,
        -0.05085430294275284,
        0.003592375200241804,
        -0.010210499167442322,
        0.007722067181020975,
        -0.02201955020427704,
        -0.046035826206207275,
        -0.04078048840165138,
        -0.05646982043981552,
        -0.013812009245157242,
        0.016922470182180405,
        -0.033400874584913254,
        -0.04792513698339462,
        -0.056435856968164444,
        -0.052806247025728226,
        -0.03330887481570244,
        -0.0581897534430027,
        0.0614912249147892,
        0.0557820089161396,
        0.022798260673880577,
        -0.03909917548298836,
        -0.0374642089009285,
        0.013856466859579086,
        0.005810585804283619,
        -0.037415049970149994,
        -0.04673849046230316,
        0.027634738013148308,
        -0.05256200581789017,
        -0.060486435890197754,
        0.05912132188677788,
        0.04617895558476448,
        0.05773582309484482,
        0.024566330015659332,
        0.03630892187356949,
        -0.04814751446247101,
        0.04356108233332634,
        0.05819970741868019,
        -0.06248168647289276,
        -0.061368100345134735,
        0.05326727032661438,
        -0.027487296611070633,
        -0.06122957170009613,
        -0.047743432223796844,
        0.059886831790208817,
        -0.004435318056493998,
        -0.0496797077357769,
        0.045670948922634125,
        0.0022324121091514826,
        0.03491250425577164,
        0.036168839782476425,
        0.060417357832193375,
        -0.05965485796332359,
        0.045123424381017685,
        0.01535363495349884,
        0.034581851214170456,
        -0.01977127604186535,
        -0.048480767756700516,
        -0.05359542369842529,
        0.04421044513583183,
        0.03505736216902733,
        -0.041125427931547165,
        -0.005156465340405703,
        -0.0614544078707695,
        -0.03156815469264984,
        0.003576103365048766,
        0.026367373764514923,
        -0.04511398449540138,
        -0.0077276406809687614,
        0.053384266793727875,
        -0.05120937153697014,
        0.04990483075380325,
        0.05270999297499657,
        0.06094345450401306,
        0.05450715124607086,
        0.049242328852415085,
        0.045706987380981445,
        -0.04230949655175209,
        0.04966489225625992,
        -0.042999159544706345,
        -0.014011457562446594,
        -0.021729478612542152,
        -0.031688954681158066,
        -0.0009156343876384199,
        -0.03592187166213989,
        0.03188445791602135,
        0.012033424340188503,
        0.0442475751042366,
        -0.052736684679985046,
        -0.04896966740489006,
        0.012863272801041603,
        -0.04392712190747261,
        0.06207350268959999,
        -0.06059769541025162,
        0.028670256957411766,
        -0.03893979638814926,
        0.060121286660432816,
        -0.02140827849507332,
        -0.029085438698530197,
        -0.04242607206106186,
        0.057601094245910645,
        -0.04983917996287346,
        0.05352265015244484,
        0.04747732728719711,
        0.016912993043661118,
        0.04709962382912636,
        -0.04857135936617851,
        0.01245100423693657,
        0.011919278651475906,
        0.06013632193207741,
        -0.06242027506232262,
        -0.03461525961756706,
        0.05703834071755409,
        -0.03204938769340515,
        -0.057315971702337265,
        0.0536036416888237,
        -0.04434528574347496,
        0.026286665350198746,
        0.041938234120607376,
        -0.04855232685804367,
        -0.05945555120706558,
        -0.027109453454613686,
        -0.03650835528969765,
        -0.050205595791339874,
        0.03690609335899353,
        0.015819599851965904,
        0.018464429304003716,
        0.060140397399663925,
        -0.053983572870492935,
        0.022791583091020584,
        0.05882706865668297,
        0.04985732585191727,
        0.05129311606287956,
        -0.05819633975625038,
        0.016202326864004135,
        0.04701891914010048,
        0.02632976323366165,
        -0.05408541485667229,
        -0.05290675163269043,
        -0.028041480109095573,
        -0.057217422872781754,
        -0.03805476054549217,
        -0.05726927891373634,
        -0.058921750634908676,
        -0.05409952998161316,
        0.02406420186161995,
        0.060945939272642136,
        -0.05599690228700638,
        0.012898754328489304,
        0.053841304033994675,
        0.04620394855737686,
        0.06243328005075455,
        0.04415903985500336,
        0.04175875708460808,
        0.05772743374109268,
        -0.05915062874555588,
        0.051281001418828964,
        0.05211639404296875,
        0.05396810919046402,
        0.057433173060417175,
        0.004184553865343332,
        -0.055848270654678345,
        -0.0622914656996727,
        0.0024746025446802378,
        -0.05632292851805687,
        0.013171609491109848,
        0.04577949643135071,
        -0.05930265411734581,
        0.047916632145643234,
        -0.044002167880535126,
        -0.061167117208242416,
        -0.05845741555094719,
        0.03650406375527382,
        0.0013871871633455157,
        -0.005143995862454176,
        0.01748981513082981,
        -0.040493521839380264,
        0.04999241977930069,
        -0.0025258406531065702,
        -0.04464271664619446,
        0.05720978602766991,
        -0.05411491170525551,
        0.04853229597210884,
        0.027826480567455292,
        -0.04468468949198723,
        0.031237898394465446,
        -0.04586907848715782,
        0.06212664023041725,
        0.016349954530596733,
        0.004412091337144375,
        0.056486111134290695,
        0.018264146521687508,
        0.0567672997713089,
        0.0010884810471907258,
        0.02698832005262375,
        0.0001717372942948714,
        -0.03880082815885544,
        -0.0501134917140007,
        -0.012689595110714436,
        0.0552474707365036,
        -0.058257073163986206,
        0.04972881078720093,
        -0.04063229262828827,
        -0.04163939505815506,
        0.03970587998628616,
        -0.06160292029380798,
        -0.05456508323550224,
        -0.032032452523708344,
        -0.05474812537431717,
        0.027270056307315826,
        -0.04736615717411041,
        -0.04268679767847061,
        0.05790873244404793,
        0.03460616618394852,
        -0.0614255852997303,
        0.056355830281972885,
        0.053271327167749405,
        0.054246194660663605,
        0.013129198923707008,
        0.05068039894104004,
        -0.033144280314445496,
        -0.03428833931684494,
        0.04899187758564949,
        -0.0026085684075951576,
        -0.0033350354060530663,
        0.057174816727638245,
        0.020569980144500732,
        -0.038100454956293106,
        0.05832882970571518,
        -0.05839152634143829,
        0.03279557824134827,
        0.061452675610780716,
        -0.02416626736521721,
        -0.009982539340853691,
        -0.042131081223487854,
        0.06242145597934723,
        0.03315916284918785,
        0.033273376524448395,
        0.04506802558898926,
        -0.033231619745492935,
        0.05555776134133339,
        -0.05996482074260712,
        -0.011391888372600079,
        -0.04128395766019821,
        -0.05600080266594887,
        0.014882830902934074,
        0.02452615089714527,
        -0.050120964646339417,
        -0.03017337992787361,
        -0.05858228728175163,
        -0.0240767989307642,
        0.05536691099405289,
        0.043404534459114075,
        -0.057757072150707245,
        -0.0457376167178154,
        -0.027163401246070862,
        -0.04474930092692375,
        0.06244456768035889,
        0.0479247085750103,
        -0.02175096422433853,
        -0.0620952770113945,
        0.010166899301111698,
        -0.05746934190392494,
        0.0570906437933445,
        0.04529858008027077,
        0.06080305576324463,
        0.06060749292373657,
        0.05922609567642212,
        0.05878940597176552,
        -0.03709275647997856,
        -0.05796002969145775,
        0.014700381085276604,
        0.053858835250139236,
        -0.053058017045259476,
        0.04727734625339508,
        0.050845298916101456,
        -0.027343682944774628,
        0.04609508812427521,
        -0.061662349849939346,
        -0.031135568395256996,
        -0.05995263159275055,
        -0.052228037267923355,
        0.05476120859384537,
        0.05834353715181351,
        0.0582587756216526,
        -0.04258379712700844,
        0.060679901391267776,
        0.010741687379777431,
        -0.052610620856285095,
        0.011319434270262718,
        -0.061725977808237076,
        -0.01707981713116169,
        0.01719309203326702,
        0.060673296451568604,
        0.03863372281193733,
        -0.02166958525776863,
        0.056355856359004974,
        0.02906154654920101,
        -0.008218727074563503,
        0.0531640350818634,
        -0.06002727150917053,
        0.058968644589185715,
        -0.04958294704556465,
        0.0432702898979187,
        -0.04051436856389046,
        0.05591747909784317,
        0.003216630080714822,
        -0.0008471808396279812,
        -0.038467079401016235,
        0.021390045061707497,
        0.027764976024627686,
        -0.04910152778029442,
        0.017474794760346413,
        -0.0580473393201828,
        -0.05951520428061485,
        0.02963181398808956,
        -0.04264267161488533,
        -0.021474620327353477,
        -0.05584833770990372,
        -0.05902805179357529,
        -0.048532579094171524,
        0.0064063910394907,
        -0.05149585008621216,
        -0.0072387210093438625,
        0.03233529254794121,
        0.020914040505886078,
        -0.005381575785577297,
        -0.03320572152733803,
        0.05907026678323746,
        0.043627578765153885,
        -0.053985342383384705,
        0.023930083960294724,
        0.04618813470005989,
        0.06199956312775612,
        0.054058752954006195,
        0.002692542737349868,
        0.05630055442452431,
        0.06162833794951439,
        -0.04107820987701416,
        0.043985120952129364,
        -0.024725232273340225,
        -0.02090930938720703,
        0.03988960012793541,
        0.034497518092393875,
        -0.03245346248149872,
        -0.045634444802999496,
        -0.04965353012084961,
        0.03235770761966705,
        0.0482182577252388,
        0.04409069940447807,
        0.009507653303444386,
        -0.00365091091953218,
        -0.05662022903561592,
        -0.028685323894023895,
        -0.05479280278086662,
        0.061804093420505524,
        0.06149820238351822,
        -0.06224837526679039,
        0.038067884743213654,
        -0.06259582191705704,
        -0.06205291301012039,
        0.011098315007984638,
        0.005183406174182892,
        0.018759198486804962,
        0.04443471133708954,
        0.039913054555654526,
        0.01964387856423855,
        0.023427395150065422,
        0.006716523785144091,
        -0.044045936316251755,
        0.03647712990641594,
        0.040849719196558,
        0.03564104065299034,
        0.04222466051578522,
        0.057871971279382706,
        0.05615770444273949,
        0.05812500789761543,
        0.05203245207667351,
        0.0032121033873409033,
        -0.05116243660449982,
        -0.0538485124707222,
        -0.0036284676752984524,
        -0.05519050359725952,
        0.018074970692396164,
        0.05109589174389839,
        0.03715630993247032,
        0.03433072566986084,
        0.009242191910743713,
        -0.0492551326751709,
        0.05439377948641777,
        0.05615909397602081,
        -0.05983810871839523,
        0.021074974909424782,
        -0.012179749086499214,
        0.028280729427933693,
        0.05668410658836365,
        0.03475730121135712,
        0.050184838473796844,
        0.02769952081143856,
        -0.04898820072412491,
        -0.05665721744298935,
        0.06233331933617592,
        0.03152900189161301
    ],
    [
        -0.060639817267656326,
        0.0604419931769371,
        -0.006895833648741245,
        0.0015223537338897586,
        -0.012525464408099651,
        0.05980600416660309,
        0.06108151748776436,
        -0.013241142965853214,
        -0.012011983431875706,
        0.05980876460671425,
        0.05362546071410179,
        0.05285494774580002,
        -0.040412694215774536,
        0.014523554593324661,
        -0.05953989177942276,
        -0.05852504447102547,
        -0.04848799481987953,
        -0.01722969114780426,
        -0.0028410409577190876,
        0.05312458053231239,
        0.04267828166484833,
        0.05706288665533066,
        0.0568416528403759,
        0.051871757954359055,
        0.05958640202879906,
        0.03590748459100723,
        -0.01377996988594532,
        0.06033311039209366,
        -0.057465773075819016,
        -0.05965759977698326,
        0.05770900100469589,
        0.05684278532862663,
        0.05038487911224365,
        -0.027618873864412308,
        0.008169058710336685,
        -0.05977892130613327,
        -0.04851989448070526,
        0.010474340990185738,
        0.061143770813941956,
        0.01153097115457058,
        0.007959495298564434,
        0.04410097748041153,
        0.05362819507718086,
        -0.0617130771279335,
        0.027939047664403915,
        -0.001163734938018024,
        0.05603017285466194,
        0.05395158752799034,
        0.03734812140464783,
        0.06130121648311615,
        0.01911155879497528,
        -0.01313357800245285,
        0.04135490581393242,
        0.01904233545064926,
        0.06086692586541176,
        -0.04338778927922249,
        -0.0424245223402977,
        0.008654152974486351,
        -0.0532427541911602,
        0.04407855123281479,
        -0.054472506046295166,
        0.03407888859510422,
        -0.03852960467338562,
        0.013506770133972168,
        -0.025162670761346817,
        0.020722614601254463,
        0.03407128527760506,
        -0.05669114738702774,
        -0.04941165819764137,
        -0.044207192957401276,
        -0.05349530279636383,
        -0.05899209529161453,
        0.06187176704406738,
        0.0009215516620315611,
        -0.050380561500787735,
        0.010649069212377071,
        0.061592407524585724,
        0.05770910158753395,
        -0.026361679658293724,
        -0.039269063621759415,
        -0.006383096799254417,
        0.05696221813559532,
        -0.0606200285255909,
        -0.002498666988685727,
        0.05282055586576462,
        0.024111822247505188,
        0.03650256618857384,
        -0.008299640379846096,
        -0.05314624682068825,
        0.0613987073302269,
        -0.05470665544271469,
        0.042198777198791504,
        -0.0157427117228508,
        0.009095430374145508,
        0.005320659838616848,
        -0.03535465896129608,
        -0.017286796122789383,
        -0.035047050565481186,
        0.00793789979070425,
        0.045280665159225464,
        -0.06150493025779724,
        0.05189337208867073,
        0.061123378574848175,
        0.05692647770047188,
        0.05564262345433235,
        0.016857925802469254,
        0.04564397409558296,
        0.007177290506660938,
        -0.040930479764938354,
        0.026786932721734047,
        -0.04307911917567253,
        -0.055882032960653305,
        0.023281004279851913,
        0.023039713501930237,
        -0.0494503527879715,
        -0.03894942253828049,
        -0.057313594967126846,
        -0.047159068286418915,
        0.001439377898350358,
        -0.04304914548993111,
        0.047557283192873,
        -0.01825617253780365,
        -0.06166650354862213,
        -0.05605396628379822,
        -0.05907966196537018,
        -0.0341867059469223,
        -0.061595361679792404,
        0.03792349249124527,
        0.05646160989999771,
        -0.008364747278392315,
        -0.059882670640945435,
        -0.047974810004234314,
        0.04816477373242378,
        0.014975992031395435,
        -0.050490014255046844,
        -0.023986410349607468,
        0.029524022713303566,
        0.022746754810214043,
        -0.06144637241959572,
        0.05884256958961487,
        -0.003346940502524376,
        0.05049073323607445,
        0.04107286036014557,
        -0.06161896511912346,
        -0.032078128308057785,
        0.042427774518728256,
        0.018717626109719276,
        -0.06137219816446304,
        -0.06046447902917862,
        0.05436045303940773,
        0.020508935675024986,
        -0.061042800545692444,
        -0.027464156970381737,
        0.011989763006567955,
        -0.03899010643362999,
        -0.045404624193906784,
        0.04924405738711357,
        -0.019750189036130905,
        0.031023705378174782,
        0.03879214823246002,
        0.05798117071390152,
        -0.05250518023967743,
        0.05073520913720131,
        0.03851141780614853,
        0.01728828065097332,
        0.045367054641246796,
        -0.058326978236436844,
        -0.02070433646440506,
        0.05995076894760132,
        0.03762601315975189,
        -0.05749112740159035,
        0.010352043434977531,
        -0.047474298626184464,
        -0.025942549109458923,
        0.006899951491504908,
        -0.008451179601252079,
        0.002542896429076791,
        -0.004125711042433977,
        0.05522124841809273,
        -0.04916256666183472,
        -0.004942197818309069,
        0.0012619523331522942,
        0.06018060818314552,
        0.061808619648218155,
        0.02211805246770382,
        0.05220247805118561,
        -0.058751121163368225,
        0.05817512795329094,
        -0.032459717243909836,
        -0.006987380795180798,
        0.0031692672055214643,
        -0.03589940816164017,
        0.0232097115367651,
        -0.03487197309732437,
        -0.004975669085979462,
        -0.04362133517861366,
        0.05146610364317894,
        -0.06161285191774368,
        -0.056169185787439346,
        0.026170877739787102,
        -0.04747427627444267,
        0.03752715513110161,
        -0.05358223617076874,
        -0.014985264278948307,
        -0.019807443022727966,
        0.05949927121400833,
        -0.01424602884799242,
        -0.046813949942588806,
        -0.050203900784254074,
        -0.04846401512622833,
        -0.04837305471301079,
        0.05980909615755081,
        0.06118454411625862,
        0.049677394330501556,
        -0.01782778650522232,
        -0.029318947345018387,
        -0.06053674593567848,
        0.02379973977804184,
        -0.0003058762813452631,
        -0.061591990292072296,
        -0.040945518761873245,
        0.06060467287898064,
        -0.0484936349093914,
        -0.05089477077126503,
        0.06051495298743248,
        -0.058414965867996216,
        -0.026749441400170326,
        -0.027269713580608368,
        0.000592356373090297,
        -0.03865806385874748,
        -0.028681833297014236,
        -0.021048715338110924,
        -0.0034831890370696783,
        0.04877336695790291,
        -0.04084474593400955,
        0.04647480323910713,
        0.05204170197248459,
        -0.04805555194616318,
        -0.003777510253712535,
        0.05780179426074028,
        0.03890235349535942,
        0.05238572135567665,
        -0.061840783804655075,
        0.0202668234705925,
        0.03041575662791729,
        -0.021157611161470413,
        -0.057235199958086014,
        -0.05853426456451416,
        -0.028858672827482224,
        -0.06175078824162483,
        -0.038434527814388275,
        -0.05521101504564285,
        -0.03762763738632202,
        -0.056464556604623795,
        0.002915282966569066,
        0.06129073724150658,
        -0.06106453761458397,
        0.031476810574531555,
        0.05952087789773941,
        0.013082596473395824,
        0.06034072861075401,
        0.0432392917573452,
        0.028785116970539093,
        0.06134754791855812,
        -0.004693180788308382,
        0.05766955390572548,
        0.03719169273972511,
        0.05439972132444382,
        0.050808973610401154,
        0.060020942240953445,
        -0.04792724549770355,
        -0.057832397520542145,
        -0.023373406380414963,
        -0.05997016280889511,
        0.0008450125460512936,
        0.056516341865062714,
        -0.06043512746691704,
        0.04959915950894356,
        0.041426923125982285,
        -0.058876994997262955,
        -0.0603468120098114,
        -0.03851739317178726,
        -0.03996141254901886,
        0.017844177782535553,
        0.015241125598549843,
        0.0564759261906147,
        0.05457166209816933,
        -0.0610627681016922,
        0.04513399675488472,
        0.054243844002485275,
        -0.05403430387377739,
        0.045471854507923126,
        0.04079453647136688,
        -0.017664600163698196,
        0.05234485864639282,
        -0.04243788123130798,
        0.061114270240068436,
        0.035431716591119766,
        0.030565373599529266,
        0.04147655516862869,
        0.011070875450968742,
        0.0388290211558342,
        -0.04025472700595856,
        0.0161067396402359,
        0.024077584967017174,
        -0.04368191212415695,
        -0.041606102138757706,
        -0.059040479362010956,
        0.019989751279354095,
        -0.050863802433013916,
        0.059175044298172,
        0.040729545056819916,
        -0.044966187328100204,
        0.016217296943068504,
        -0.011621606536209583,
        -0.05545539781451225,
        0.013703321106731892,
        0.06157945469021797,
        0.04755571112036705,
        -0.05626465007662773,
        -0.04248744994401932,
        0.06104510277509689,
        0.03827936202287674,
        -0.0546724870800972,
        0.047079503536224365,
        0.058391470462083817,
        0.01598937064409256,
        -0.019254270941019058,
        -0.04389152303338051,
        -0.04876899719238281,
        -0.030853983014822006,
        0.04765670374035835,
        -0.021809685975313187,
        -0.050297804176807404,
        0.05872543528676033,
        0.03043699450790882,
        0.007323769852519035,
        0.05683978646993637,
        -0.036506928503513336,
        0.022303536534309387,
        0.0364396832883358,
        0.046721454709768295,
        -0.0425802618265152,
        0.04118899255990982,
        0.06159580126404762,
        -0.007905068807303905,
        0.05882027745246887,
        0.03162993863224983,
        -0.06105729565024376,
        0.039284221827983856,
        -0.053821589797735214,
        0.05226116627454758,
        -0.04037798196077347,
        -0.060084689408540726,
        0.05669218674302101,
        0.0298758577555418,
        0.05454248934984207,
        -0.03691927343606949,
        -0.05962289497256279,
        -0.05788702890276909,
        0.05390308052301407,
        0.03916042670607567,
        -0.0605962797999382,
        -0.041323471814394,
        0.05961790308356285,
        -0.04325392097234726,
        0.0615386925637722,
        0.01164500042796135,
        -0.01078042946755886,
        -0.06091047450900078,
        -0.021009797230362892,
        -0.061526767909526825,
        0.04697129502892494,
        0.05917840823531151,
        0.06128857284784317,
        0.06172441318631172,
        0.061699945479631424,
        0.041124600917100906,
        -0.050561510026454926,
        0.020083842799067497,
        0.012193732894957066,
        0.05364953354001045,
        -0.05175749957561493,
        0.04316358640789986,
        0.056861430406570435,
        0.03144831582903862,
        -0.04578879103064537,
        -0.06152045726776123,
        -0.047450482845306396,
        -0.058076467365026474,
        -0.04547799378633499,
        0.05918329581618309,
        0.04577544704079628,
        0.05979360267519951,
        -0.043483179062604904,
        0.05769483000040054,
        -0.026368912309408188,
        -0.05455402284860611,
        -0.001061424263752997,
        -0.05968357250094414,
        -0.046930018812417984,
        0.05970480665564537,
        0.04418203607201576,
        -0.007517451420426369,
        -0.039309293031692505,
        0.06064712256193161,
        0.044765062630176544,
        -0.020249024033546448,
        0.05323692038655281,
        -0.05835583806037903,
        0.059983447194099426,
        -0.054269563406705856,
        0.06153828650712967,
        -0.034144919365644455,
        0.059814453125,
        0.030014459043741226,
        0.023497501388192177,
        -0.057303398847579956,
        0.017950475215911865,
        0.057817790657281876,
        -0.01823803409934044,
        0.0006973599665798247,
        -0.039628785103559494,
        -0.05759922042489052,
        -0.0022560334764420986,
        -0.05476076528429985,
        0.05215783789753914,
        -0.05269317328929901,
        -0.04048650339245796,
        -0.05604267120361328,
        -0.009192529134452343,
        -0.027356727048754692,
        0.03755271062254906,
        -0.04843737930059433,
        0.003019265364855528,
        -0.03950006887316704,
        -0.019312601536512375,
        0.025003360584378242,
        0.02199294976890087,
        -0.05947892740368843,
        0.04614958167076111,
        0.03424811735749245,
        0.06190565973520279,
        0.0592881515622139,
        -0.03428623080253601,
        0.05844224989414215,
        0.06056741252541542,
        0.019332773983478546,
        0.05092104151844978,
        -0.024608971551060677,
        -0.030919983983039856,
        -0.017454657703638077,
        0.019158290699124336,
        -0.036913953721523285,
        -0.04320884495973587,
        -0.040212664753198624,
        0.04948198050260544,
        0.005327988415956497,
        0.06134485825896263,
        0.044362813234329224,
        -0.011202598921954632,
        -0.03325732424855232,
        -0.04521195963025093,
        -0.056077614426612854,
        0.06114823743700981,
        0.06130313128232956,
        -0.04627116397023201,
        0.05672937259078026,
        -0.06145932525396347,
        -0.061081528663635254,
        -0.012159004807472229,
        -0.05280381068587303,
        0.04135945439338684,
        0.04521043226122856,
        0.021162088960409164,
        0.016551833599805832,
        0.059929024428129196,
        0.015159254893660545,
        -0.0548526830971241,
        0.05995786562561989,
        0.058622345328330994,
        0.017976993694901466,
        0.0007260628626681864,
        0.03365396708250046,
        0.060004089027643204,
        0.044587209820747375,
        0.04752058908343315,
        0.020671123638749123,
        -0.054457709193229675,
        -0.05327647924423218,
        0.015041898936033249,
        -0.012235364876687527,
        0.010630405507981777,
        0.04790939390659332,
        0.012273651547729969,
        0.026688942685723305,
        0.03673410788178444,
        -0.04645666107535362,
        0.04217078909277916,
        0.051456019282341,
        -0.03263511136174202,
        0.029849128797650337,
        -0.005995729472488165,
        0.06053980812430382,
        0.009783570654690266,
        0.030912989750504494,
        0.06131072714924812,
        0.04000648111104965,
        -0.010232334956526756,
        -0.060716599225997925,
        0.06063659116625786,
        0.03696945309638977
    ],
    [
        -0.057369790971279144,
        0.0544457733631134,
        0.058601465076208115,
        0.0029982642736285925,
        0.0579279288649559,
        0.060497283935546875,
        0.060711570084095,
        -0.012206636369228363,
        0.005145067814737558,
        0.05903872102499008,
        0.03426612541079521,
        -0.027006424963474274,
        -0.0394597165286541,
        0.05586610734462738,
        -0.059595655649900436,
        0.05960870534181595,
        -0.05981836095452309,
        0.051225341856479645,
        -0.043806735426187515,
        0.05646060034632683,
        -0.051713068038225174,
        -0.04076423868536949,
        -0.059425558894872665,
        0.024391938000917435,
        0.04546127840876579,
        0.04012483358383179,
        -0.04412679374217987,
        0.05128174647688866,
        -0.05205346643924713,
        -0.019785895943641663,
        0.05876230075955391,
        0.055703070014715195,
        0.05118413642048836,
        -0.05700506642460823,
        0.024089036509394646,
        -0.05892578884959221,
        -0.055345844477415085,
        -0.05019547790288925,
        -0.012239206582307816,
        0.02138889953494072,
        -0.033419955521821976,
        0.04820903763175011,
        0.057488854974508286,
        -0.04308043420314789,
        0.03975406661629677,
        -0.04699277877807617,
        -0.04108712449669838,
        0.060439806431531906,
        0.05972647666931152,
        0.05017007887363434,
        0.04833474010229111,
        0.05899171158671379,
        0.05206792429089546,
        -0.00744895962998271,
        0.0581807978451252,
        -0.012615409679710865,
        -0.04123635217547417,
        -0.0055339159443974495,
        -0.05957759916782379,
        0.0053118630312383175,
        -0.05439558997750282,
        0.008913308382034302,
        -0.049917105585336685,
        -0.0519745871424675,
        0.046871598809957504,
        -0.03978970646858215,
        -0.04581828787922859,
        0.007638364098966122,
        0.024517664685845375,
        -0.06071670353412628,
        -0.05447276309132576,
        -0.06050826981663704,
        0.06076577305793762,
        0.04854443669319153,
        -0.06072660908102989,
        -0.00008415616321144626,
        0.054283786565065384,
        0.059546954929828644,
        -0.021619630977511406,
        0.01883009821176529,
        -0.05513470619916916,
        -0.044932637363672256,
        -0.052444323897361755,
        -0.014382055029273033,
        0.0504755899310112,
        0.026330916211009026,
        -0.051329247653484344,
        0.031335726380348206,
        -0.01730261743068695,
        0.058670029044151306,
        -0.055463019758462906,
        -0.019228124991059303,
        0.05133092775940895,
        -0.05946679785847664,
        -0.03470609709620476,
        -0.03167302533984184,
        -0.02184542827308178,
        -0.060407668352127075,
        -0.018506769090890884,
        0.05198415741324425,
        -0.06039006635546684,
        -0.04921036586165428,
        0.059690672904253006,
        0.05681784451007843,
        0.0378643199801445,
        0.03570463880896568,
        -0.05554265156388283,
        0.0022987474221736193,
        -0.05173368752002716,
        0.02469826675951481,
        0.015586757101118565,
        0.030514251440763474,
        -0.008467229083180428,
        -0.05061427131295204,
        0.06034928932785988,
        -0.04676520824432373,
        -0.056066498160362244,
        -0.03574180230498314,
        0.0106596564874053,
        -0.03711425140500069,
        0.010941834188997746,
        -0.023575859144330025,
        -0.03966912627220154,
        0.024411920458078384,
        -0.05972922593355179,
        -0.005510596092790365,
        -0.05840420350432396,
        0.05238627269864082,
        0.04906288534402847,
        -0.03550355136394501,
        0.02706187404692173,
        -0.05490456894040108,
        -0.022308455780148506,
        -0.009759167209267616,
        -0.04642194136977196,
        -0.036245860159397125,
        0.019725177437067032,
        -0.057717058807611465,
        -0.052291493862867355,
        0.05882607400417328,
        0.05695774778723717,
        0.01007608138024807,
        0.055262066423892975,
        0.03643817454576492,
        0.02798842079937458,
        -0.0004457765317056328,
        0.04937777295708656,
        -0.035214703530073166,
        -0.03448907285928726,
        0.05237242951989174,
        0.05984656140208244,
        -0.06030510738492012,
        -0.05979137867689133,
        0.03912106901407242,
        0.0458671972155571,
        -0.028626637533307076,
        0.009079929441213608,
        0.013999879360198975,
        0.03894136846065521,
        0.026545079424977303,
        0.04486680030822754,
        0.04573877900838852,
        0.052414726465940475,
        0.04926193878054619,
        -0.04342114180326462,
        0.030080052092671394,
        -0.04297058284282684,
        -0.006835562642663717,
        -0.05013379082083702,
        0.05330968275666237,
        -0.05881543457508087,
        0.033547766506671906,
        -0.06010247394442558,
        0.058065496385097504,
        0.0442233681678772,
        -0.03236043080687523,
        0.0027681987266987562,
        0.018718497827649117,
        0.05742008984088898,
        -0.012679729610681534,
        -0.005574770737439394,
        -0.01890094392001629,
        0.05561710521578789,
        0.037493232637643814,
        0.05497608333826065,
        0.030828379094600677,
        0.0027871509082615376,
        0.05861470103263855,
        -0.0595223642885685,
        -0.040116991847753525,
        -0.036202460527420044,
        -0.030172159895300865,
        -0.04246065765619278,
        0.009675649926066399,
        -0.008755319751799107,
        0.0356818288564682,
        0.05634823068976402,
        -0.01697324961423874,
        -0.0023071228060871363,
        -0.006005259696394205,
        -0.01420070230960846,
        0.06021011248230934,
        0.051617447286844254,
        -0.050663627684116364,
        0.012153242714703083,
        0.019565191119909286,
        0.05549902096390724,
        0.011913342401385307,
        -0.052919719368219376,
        0.055113907903432846,
        -0.049567271023988724,
        0.039318595081567764,
        -0.015110846608877182,
        0.02343389019370079,
        -0.004794836509972811,
        -0.048250604420900345,
        0.053913358598947525,
        0.03931982070207596,
        0.05966607853770256,
        -0.0596248023211956,
        -0.01628686860203743,
        0.057947415858507156,
        -0.05465041846036911,
        -0.0600755512714386,
        0.06050677224993706,
        -0.06018276885151863,
        0.015858735889196396,
        -0.050551507622003555,
        0.030275583267211914,
        -0.05961751937866211,
        -0.029925642535090446,
        -0.011957010254263878,
        -0.03886635974049568,
        0.05108454078435898,
        0.03229834511876106,
        0.03333725780248642,
        0.05885927006602287,
        -0.015922868624329567,
        -0.0205643679946661,
        0.059614747762680054,
        0.05764951929450035,
        0.0537247508764267,
        -0.05903354659676552,
        0.054698701947927475,
        0.009575685486197472,
        -0.035996921360492706,
        0.05547120049595833,
        -0.053509294986724854,
        -0.02161579206585884,
        -0.0470905564725399,
        0.04113253951072693,
        -0.059602219611406326,
        -0.03173828125,
        -0.05597136169672012,
        0.001969348406419158,
        0.06058644875884056,
        -0.015439817681908607,
        0.0180350374430418,
        0.06022454425692558,
        0.055255740880966187,
        0.04541773349046707,
        0.054986756294965744,
        0.01919259876012802,
        0.053642258048057556,
        0.060618381947278976,
        0.05442988499999046,
        0.048143986612558365,
        0.022392120212316513,
        0.02804277278482914,
        0.005578760523349047,
        -0.0023471610620617867,
        -0.058172933757305145,
        0.008488964289426804,
        -0.0581345297396183,
        -0.05471154302358627,
        0.0521228164434433,
        -0.05985523760318756,
        0.05857403203845024,
        0.03234542906284332,
        -0.059293922036886215,
        -0.05812621861696243,
        -0.049632586538791656,
        -0.05279362574219704,
        -0.057525359094142914,
        -0.004356016870588064,
        -0.03717568516731262,
        0.057056017220020294,
        -0.017546607181429863,
        -0.00921721663326025,
        -0.005155674647539854,
        -0.04629906639456749,
        0.06045156717300415,
        0.045589860528707504,
        -0.03730712831020355,
        0.05725625157356262,
        -0.04963895305991173,
        0.05349070206284523,
        -0.013271842151880264,
        -0.04386983439326286,
        0.047199513763189316,
        -0.0472940169274807,
        0.05613202601671219,
        0.026905126869678497,
        0.025586910545825958,
        0.024734972044825554,
        -0.01042216457426548,
        -0.05477241799235344,
        -0.010681875050067902,
        0.05687560886144638,
        -0.05910065770149231,
        -0.03131692111492157,
        0.011154052801430225,
        0.01754457876086235,
        0.06004931405186653,
        -0.05631624534726143,
        -0.04911191388964653,
        0.05268479138612747,
        0.053836431354284286,
        0.03256848454475403,
        -0.05730600655078888,
        -0.021240275353193283,
        -0.020860908553004265,
        0.05893225222826004,
        0.0010311660589650273,
        0.047320399433374405,
        0.05910357087850571,
        0.024513179436326027,
        -0.032032985240221024,
        -0.05420563369989395,
        -0.05778549239039421,
        -0.031974729150533676,
        -0.00962891522794962,
        0.026417389512062073,
        -0.05515144020318985,
        0.05574119836091995,
        -0.008866540156304836,
        -0.04842421039938927,
        0.05806903541088104,
        -0.027960605919361115,
        0.05831417813897133,
        0.053874269127845764,
        0.03740976005792618,
        0.045131608843803406,
        -0.004669116344302893,
        0.05444717779755592,
        0.04273586720228195,
        -0.032587017863988876,
        0.049978483468294144,
        -0.05160585045814514,
        0.05529743432998657,
        -0.05846512317657471,
        0.05961323156952858,
        -0.04774016514420509,
        -0.0605938658118248,
        0.05632376670837402,
        0.001619621878489852,
        -0.047344427555799484,
        -0.044982898980379105,
        -0.054599661380052567,
        -0.046388640999794006,
        0.06003966182470322,
        -0.035724569112062454,
        -0.05925269424915314,
        -0.03105800785124302,
        -0.01554868370294571,
        -0.054423898458480835,
        0.060579802840948105,
        0.030185740441083908,
        -0.0062202392145991325,
        -0.060436200350522995,
        -0.029322834685444832,
        -0.05330253019928932,
        0.053265903145074844,
        0.0010635947110131383,
        0.060652073472738266,
        0.015124023891985416,
        0.04744238406419754,
        0.03432496264576912,
        0.005958986468613148,
        -0.057636670768260956,
        -0.03629230707883835,
        0.01929909735918045,
        -0.055597323924303055,
        -0.009698577225208282,
        0.05942274257540703,
        0.013157897628843784,
        0.012263751588761806,
        -0.06065525859594345,
        -0.051864106208086014,
        -0.05951080843806267,
        -0.038215331733226776,
        -0.05866433307528496,
        0.004622171632945538,
        0.0411105751991272,
        0.01376855093985796,
        0.059497345238924026,
        0.0596807487308979,
        0.008486680686473846,
        -0.04197598248720169,
        -0.058704011142253876,
        -0.030925629660487175,
        0.05128277465701103,
        0.05751175805926323,
        0.059554774314165115,
        -0.04645242169499397,
        0.04442213103175163,
        0.043587956577539444,
        0.02916128560900688,
        0.04616481810808182,
        -0.050315044820308685,
        0.013678157702088356,
        -0.05374535918235779,
        0.037191733717918396,
        0.03731031343340874,
        0.057108074426651,
        -0.033338386565446854,
        -0.003975952509790659,
        -0.01700054109096527,
        0.004246038850396872,
        0.029666166752576828,
        -0.02689923346042633,
        -0.04837140813469887,
        -0.04645591601729393,
        -0.0593145377933979,
        0.0035531939938664436,
        -0.01053949911147356,
        -0.011787244118750095,
        -0.058006543666124344,
        -0.0545828677713871,
        -0.02995777316391468,
        0.05002037435770035,
        -0.030072886496782303,
        -0.009678133763372898,
        -0.028515364974737167,
        -0.037390194833278656,
        -0.048826731741428375,
        -0.051194075495004654,
        -0.014990145340561867,
        0.03438222035765648,
        -0.054915785789489746,
        0.05362856388092041,
        0.05003109946846962,
        0.060470882803201675,
        0.04878031089901924,
        0.010951211676001549,
        0.030462849885225296,
        0.05863339453935623,
        0.028920458629727364,
        0.03834810480475426,
        -0.05466989427804947,
        0.021071193739771843,
        0.05871037393808365,
        0.05905691161751747,
        -0.04977625235915184,
        0.04413912445306778,
        -0.040113065391778946,
        0.020972279831767082,
        0.027381936088204384,
        0.020374560728669167,
        0.05893344059586525,
        0.017955245450139046,
        0.005103291943669319,
        -0.046790990978479385,
        0.06003677845001221,
        0.06066727638244629,
        0.0605681911110878,
        -0.0577794685959816,
        0.05950750783085823,
        -0.03274783492088318,
        -0.06035856902599335,
        0.05267201364040375,
        0.05601392686367035,
        0.05160222202539444,
        0.038210198283195496,
        0.056497398763895035,
        0.049895450472831726,
        0.05527501180768013,
        -0.055725373327732086,
        -0.052933670580387115,
        0.04326765984296799,
        0.05877028778195381,
        -0.012979128398001194,
        0.0392666794359684,
        0.028948461636900902,
        0.05480321869254112,
        0.053619615733623505,
        0.05171946808695793,
        0.04479696974158287,
        -0.05899614468216896,
        -0.035121191293001175,
        -0.0597878023982048,
        -0.05725135654211044,
        -0.0005188249051570892,
        0.051835641264915466,
        0.049071356654167175,
        0.02036316692829132,
        -0.05612035468220711,
        -0.0459255687892437,
        0.054524533450603485,
        0.01720414310693741,
        0.049349598586559296,
        -0.04418442025780678,
        -0.017670175060629845,
        0.05747123435139656,
        -0.03709845989942551,
        0.03301134705543518,
        0.04420095309615135,
        0.0007348970975726843,
        -0.033485688269138336,
        -0.050016142427921295,
        0.06029418483376503,
        0.0470762737095356
    ],
    [
        -0.013237531296908855,
        0.02641422674059868,
        0.05018781125545502,
        0.04436694085597992,
        -0.05778221786022186,
        0.034949176013469696,
        0.05139920860528946,
        -0.05936285853385925,
        0.05832144245505333,
        -0.016675768420100212,
        0.05814099684357643,
        0.058652352541685104,
        -0.04343292489647865,
        -0.04893309995532036,
        -0.05938338488340378,
        0.057885535061359406,
        0.04531741887331009,
        0.057075612246990204,
        0.05289203301072121,
        0.018783850595355034,
        0.05769726634025574,
        0.05975610390305519,
        0.038739752024412155,
        0.054730743169784546,
        0.05192695930600166,
        0.04053393006324768,
        0.055138394236564636,
        0.05386434867978096,
        -0.015665465965867043,
        0.028147056698799133,
        0.045141033828258514,
        0.040918152779340744,
        0.04212052375078201,
        -0.05800357088446617,
        0.057417046278715134,
        -0.03788626939058304,
        -0.0579671785235405,
        -0.017980312928557396,
        0.057046353816986084,
        -0.04542415216565132,
        0.020257199183106422,
        0.05448227375745773,
        0.05595981702208519,
        0.031638193875551224,
        0.011169055476784706,
        0.010273363441228867,
        -0.015835558995604515,
        0.058989547193050385,
        0.052342768758535385,
        -0.050137054175138474,
        0.0372840017080307,
        0.03613796457648277,
        -0.04710499569773674,
        -0.05790255591273308,
        0.05960213020443916,
        0.0057480214163661,
        -0.021008707582950592,
        0.02571837417781353,
        -0.0466485358774662,
        -0.05183436721563339,
        -0.05842619761824608,
        -0.014727181755006313,
        -0.05842846259474754,
        -0.028291158378124237,
        -0.04849071055650711,
        -0.026883892714977264,
        -0.05120881646871567,
        -0.00859595276415348,
        -0.052193544805049896,
        0.058679141104221344,
        -0.0282137393951416,
        -0.043131664395332336,
        0.058900054544210434,
        0.04759097471833229,
        0.051613595336675644,
        0.016148686408996582,
        -0.023708617314696312,
        0.059828970581293106,
        0.0032478387001901865,
        -0.04584720358252525,
        -0.03479565680027008,
        -0.0021491332445293665,
        -0.05923774465918541,
        -0.0014319363981485367,
        0.031430963426828384,
        0.0022636300418525934,
        0.03285948187112808,
        -0.057838115841150284,
        -0.040031347423791885,
        0.05964631587266922,
        -0.044660720974206924,
        0.0302144642919302,
        -0.05976086109876633,
        -0.029094746336340904,
        -0.039224471896886826,
        0.0443718396127224,
        -0.030904149636626244,
        -0.011422812938690186,
        -0.04625682905316353,
        -0.029247716069221497,
        -0.05899600312113762,
        0.03838936612010002,
        0.04608908295631409,
        0.058578699827194214,
        0.01440911553800106,
        0.0058966223150491714,
        -0.0008328203693963587,
        0.010767502710223198,
        -0.03455799072980881,
        0.02836335450410843,
        -0.00460344273597002,
        0.05651025474071503,
        -0.028476791456341743,
        -0.05346928536891937,
        -0.006861812435090542,
        -0.03481777012348175,
        -0.03454179689288139,
        -0.055057574063539505,
        0.05929269641637802,
        0.026513541117310524,
        0.04446769505739212,
        -0.05341018736362457,
        0.05961867421865463,
        -0.05980263277888298,
        0.024263300001621246,
        0.04249146580696106,
        -0.059406578540802,
        0.0018222886137664318,
        0.013944183476269245,
        0.05733253061771393,
        0.040843743830919266,
        -0.02584199607372284,
        -0.004720326047390699,
        0.05884750932455063,
        -0.04416989907622337,
        -0.0520944707095623,
        0.057133808732032776,
        0.056021470576524734,
        -0.05958344042301178,
        0.028881125152111053,
        -0.04892357811331749,
        -0.028340956196188927,
        0.05292884632945061,
        0.058693256229162216,
        -0.05715850740671158,
        0.01543481182307005,
        0.03825028985738754,
        -0.059661585837602615,
        -0.04755271598696709,
        0.04219929873943329,
        0.014396964572370052,
        -0.0518907755613327,
        -0.02548455260694027,
        0.0005869192536920309,
        -0.0495324470102787,
        -0.05910123884677887,
        -0.05175230652093887,
        -0.041760481894016266,
        0.05959799513220787,
        -0.05542448163032532,
        0.013363304547965527,
        -0.05566047132015228,
        0.05947427451610565,
        0.03723892569541931,
        -0.05726209655404091,
        0.05939295515418053,
        -0.0491793267428875,
        0.04730994254350662,
        -0.003916448447853327,
        -0.009513552300632,
        -0.0495479479432106,
        0.0553731843829155,
        -0.05972358211874962,
        -0.01903490163385868,
        0.05907393619418144,
        0.012060722336173058,
        0.039679329842329025,
        0.03680254891514778,
        0.05387753248214722,
        -0.007240673992782831,
        -0.019313281401991844,
        0.0579221285879612,
        0.05587010830640793,
        0.045023344457149506,
        0.03969099745154381,
        0.022147350013256073,
        0.044455405324697495,
        0.05902404338121414,
        -0.04335983097553253,
        -0.05306193605065346,
        -0.05332155153155327,
        -0.04571603611111641,
        0.05596411973237991,
        -0.027412405237555504,
        -0.05425490066409111,
        0.001235671923495829,
        0.04508355259895325,
        -0.05978861451148987,
        -0.059800323098897934,
        -0.03813305124640465,
        0.051052119582891464,
        0.04162129759788513,
        -0.05153843015432358,
        -0.04025362804532051,
        0.02824275568127632,
        0.0356312096118927,
        -0.02008332684636116,
        0.03177211061120033,
        -0.02026948891580105,
        0.030569829046726227,
        -0.04065065458416939,
        0.034537773579359055,
        0.0576823465526104,
        0.05826116353273392,
        -0.04714502766728401,
        -0.009480353444814682,
        0.04465712234377861,
        -0.04484123736619949,
        0.053847428411245346,
        -0.04172418639063835,
        -0.032507073134183884,
        0.05978280305862427,
        0.04364527761936188,
        0.05779600143432617,
        0.05975878983736038,
        -0.05395277962088585,
        0.051713861525058746,
        0.033508751541376114,
        0.059322334825992584,
        -0.059521060436964035,
        0.0208297036588192,
        -0.058692529797554016,
        0.01477360911667347,
        0.05090907961130142,
        0.020973486825823784,
        0.05819447711110115,
        0.05207550525665283,
        -0.02465241029858589,
        -0.036462970077991486,
        0.05974594131112099,
        0.04021458700299263,
        0.05624796450138092,
        -0.059793200343847275,
        0.05769407004117966,
        0.05893436819314957,
        -0.046548597514629364,
        -0.05933646485209465,
        -0.013839798048138618,
        0.02938373200595379,
        -0.05947943031787872,
        0.05417807400226593,
        -0.0585879310965538,
        -0.0439518503844738,
        -0.03476567566394806,
        -0.05071538686752319,
        0.057727474719285965,
        -0.05925754830241203,
        -0.03434522822499275,
        0.0330350361764431,
        -0.02856605499982834,
        0.026377471163868904,
        0.041935116052627563,
        -0.019909361377358437,
        0.05285457894206047,
        -0.04567442089319229,
        0.05814383551478386,
        0.05958739295601845,
        0.05204561725258827,
        0.018488507717847824,
        0.013801831752061844,
        0.056901462376117706,
        -0.05887302756309509,
        0.055543202906847,
        -0.03907855600118637,
        0.05798206850886345,
        -0.025199901312589645,
        -0.056117862462997437,
        0.013792784884572029,
        -0.005113762337714434,
        -0.056553274393081665,
        -0.05294967070221901,
        -0.004160185810178518,
        -0.042834192514419556,
        -0.051382388919591904,
        0.025162138044834137,
        0.0528000183403492,
        0.020745614543557167,
        0.04965319111943245,
        0.059810519218444824,
        0.021201003342866898,
        -0.03559934347867966,
        -0.0138664860278368,
        -0.0387396439909935,
        0.029882650822401047,
        0.05859532579779625,
        -0.05917217209935188,
        0.057287782430648804,
        -0.01072095800191164,
        0.04131427779793739,
        0.053286176174879074,
        0.038075681775808334,
        0.04391707479953766,
        -0.01396834198385477,
        0.05703791603446007,
        0.009725106880068779,
        -0.019305860623717308,
        0.02742219902575016,
        0.05706461891531944,
        0.007067790720611811,
        -0.024693578481674194,
        0.011976120062172413,
        -0.05578472092747688,
        0.016031255945563316,
        -0.05781264975667,
        -0.040399085730314255,
        -0.03961111605167389,
        -0.05484350770711899,
        -0.036200229078531265,
        0.05622851476073265,
        0.03791491687297821,
        0.05761507526040077,
        -0.04889628663659096,
        0.049532487988471985,
        0.016673166304826736,
        0.05468383803963661,
        -0.05537261441349983,
        -0.0031841411255300045,
        0.01856432855129242,
        0.0382232703268528,
        -0.05934550240635872,
        -0.012334375642240047,
        -0.05880213901400566,
        -0.014253053814172745,
        -0.007521891966462135,
        0.05644224211573601,
        0.04207286238670349,
        0.042307037860155106,
        0.03979587182402611,
        0.02760022133588791,
        -0.05531015247106552,
        0.012462303042411804,
        0.02679445780813694,
        0.05832895264029503,
        0.047978661954402924,
        0.04978565126657486,
        0.03512003272771835,
        0.05512384697794914,
        0.05796646699309349,
        -0.0313362181186676,
        0.00042295752791687846,
        0.059777017682790756,
        0.04207855090498924,
        0.028303034603595734,
        -0.05730424076318741,
        0.05507979542016983,
        -0.048555638641119,
        -0.056931380182504654,
        -0.05413942039012909,
        -0.016746342182159424,
        -0.05768917500972748,
        0.03417278826236725,
        0.006359677761793137,
        -0.05160369724035263,
        -0.03988758102059364,
        0.04621414095163345,
        -0.05949787795543671,
        0.05114687979221344,
        0.02383461408317089,
        -0.008917218074202538,
        -0.0568433552980423,
        -0.043395865708589554,
        -0.004678005818277597,
        0.001439075218513608,
        0.05381578952074051,
        0.05858365818858147,
        0.05925007537007332,
        0.057424772530794144,
        0.05968334898352623,
        0.05873033404350281,
        -0.036674004048109055,
        -0.058945901691913605,
        0.027854153886437416,
        -0.051159586757421494,
        0.03243817761540413,
        0.025664377957582474,
        -0.05788954719901085,
        -0.013522070832550526,
        -0.031068984419107437,
        -0.0558898039162159,
        -0.05850483849644661,
        -0.04748592525720596,
        -0.018204575404524803,
        -0.027487019076943398,
        -0.05944211781024933,
        -0.010031010955572128,
        0.059335462749004364,
        0.0003701126843225211,
        -0.0344461053609848,
        -0.042899660766124725,
        -0.051748961210250854,
        -0.012107886373996735,
        0.05708467960357666,
        -0.02553856186568737,
        0.05264446139335632,
        -0.014718789607286453,
        0.04862847551703453,
        -0.012275036424398422,
        -0.055637020617723465,
        0.02297399193048477,
        0.021504607051610947,
        0.017186205834150314,
        -0.03883948177099228,
        0.03784944862127304,
        0.009413594380021095,
        0.05897939205169678,
        -0.05780605971813202,
        -0.013651992194354534,
        -0.05499620363116264,
        0.057745564728975296,
        0.05893062800168991,
        0.04824380576610565,
        0.03480551391839981,
        0.027356665581464767,
        -0.058864153921604156,
        0.05616671219468117,
        -0.031409576535224915,
        -0.05572450906038284,
        -0.027138739824295044,
        0.0416361466050148,
        -0.05838119983673096,
        0.04401218146085739,
        0.03309286758303642,
        -0.04448249936103821,
        -0.05574948713183403,
        -0.02670774795114994,
        -0.011288213543593884,
        0.0020237257704138756,
        0.054869819432497025,
        0.04619162902235985,
        -0.0456484816968441,
        0.03458355367183685,
        0.059542205184698105,
        0.05981913208961487,
        0.059308119118213654,
        0.03303350508213043,
        0.026946572586894035,
        0.05927383899688721,
        0.03289639577269554,
        0.043902356177568436,
        0.05332258343696594,
        0.056779954582452774,
        -0.013148379512131214,
        -0.02581934817135334,
        0.0541670061647892,
        0.058974552899599075,
        -0.059306371957063675,
        -0.0029881123919039965,
        -0.04947037249803543,
        0.05954558402299881,
        0.0595005601644516,
        0.024554895237088203,
        -0.059245068579912186,
        0.01002961490303278,
        0.034546613693237305,
        0.05970582738518715,
        0.05886053666472435,
        -0.05817217752337456,
        0.05883006379008293,
        -0.04957747831940651,
        -0.05763375759124756,
        0.011480463668704033,
        -0.03568234667181969,
        0.04986978694796562,
        0.025770200416445732,
        -0.05933528393507004,
        -0.00583035871386528,
        -0.01036449521780014,
        0.05957411602139473,
        -0.054173391312360764,
        0.021492529660463333,
        0.057173408567905426,
        -0.03945433720946312,
        -0.05830034986138344,
        0.02022900991141796,
        -0.04385818541049957,
        0.047448281198740005,
        0.05948809161782265,
        0.053795553743839264,
        -0.048104144632816315,
        -0.020777951925992966,
        0.0029528746381402016,
        -0.010981833562254906,
        0.059564050287008286,
        -0.014961411245167255,
        -0.016284795477986336,
        -0.046916354447603226,
        0.05397488921880722,
        0.05567769706249237,
        0.050108376890420914,
        0.05867339298129082,
        -0.05433572456240654,
        0.04601737856864929,
        -0.0538063570857048,
        -0.021067161113023758,
        -0.010076832957565784,
        0.04435895010828972,
        0.052742984145879745,
        -0.038457486778497696,
        0.03717918321490288,
        -0.05874769762158394,
        -0.0075699943117797375,
        -0.04943867772817612
    ],
    [
        0.06104118004441261,
        0.03127940744161606,
        0.051819588989019394,
        0.03364073857665062,
        -0.03216841444373131,
        -0.00858072005212307,
        -0.03121182508766651,
        -0.06367532163858414,
        0.06243990734219551,
        -0.014203467406332493,
        0.003832056885585189,
        0.04202556610107422,
        -0.03104395978152752,
        -0.011155668646097183,
        -0.06435280293226242,
        0.0630473718047142,
        0.030675895512104034,
        0.06540501117706299,
        -0.04631770774722099,
        -0.04932406544685364,
        0.030938345938920975,
        0.05943986400961876,
        -0.028253333643078804,
        -0.0592571496963501,
        0.04354097321629524,
        -0.018324188888072968,
        0.028325803577899933,
        -0.016743173822760582,
        0.02081855945289135,
        0.04439122602343559,
        0.00854602549225092,
        -0.04947205260396004,
        -0.021619686856865883,
        -0.06473293900489807,
        0.06228046491742134,
        -0.047978296875953674,
        -0.0656680315732956,
        -0.04722408577799797,
        0.05131557211279869,
        0.027954021468758583,
        0.030662836506962776,
        0.044425517320632935,
        0.017053861171007156,
        -0.0537622906267643,
        -0.01571604795753956,
        0.06495567411184311,
        0.048391927033662796,
        0.04735757037997246,
        0.041850630193948746,
        -0.018643509596586227,
        0.038784489035606384,
        0.06756856292486191,
        -0.05840761959552765,
        0.024611283093690872,
        0.02299368754029274,
        -0.02227947674691677,
        0.021196328103542328,
        -0.02446061559021473,
        0.0540507435798645,
        -0.05301152169704437,
        -0.05310671776533127,
        0.05120956525206566,
        -0.05518123507499695,
        0.062062520533800125,
        -0.030185259878635406,
        -0.0517396405339241,
        -0.03147314861416817,
        0.031579870730638504,
        -0.06301481276750565,
        0.040594566613435745,
        -0.012819487601518631,
        -0.06771810352802277,
        0.06403076648712158,
        0.007243358064442873,
        -0.005580962635576725,
        -0.05137438327074051,
        0.045052822679281235,
        0.06800613552331924,
        -0.04361550137400627,
        -0.009919993579387665,
        0.021424362435936928,
        0.044898904860019684,
        -0.04885774478316307,
        0.053560979664325714,
        0.060648057609796524,
        0.03885723277926445,
        0.06099681928753853,
        -0.05601396784186363,
        0.03577554225921631,
        0.058318860828876495,
        0.00007748350617475808,
        -0.05100948363542557,
        -0.06710067391395569,
        -0.04261552169919014,
        -0.031362250447273254,
        0.058820709586143494,
        0.04193615913391113,
        -0.06510500609874725,
        0.02687888778746128,
        -0.002402158919721842,
        -0.06478817015886307,
        0.00801477674394846,
        0.028474902734160423,
        -0.0023577711544930935,
        0.02634429931640625,
        -0.06497541069984436,
        -0.034943245351314545,
        0.05799497663974762,
        0.030905595049262047,
        0.05855216830968857,
        -0.06417008489370346,
        0.03575536608695984,
        -0.06673549115657806,
        0.022371187806129456,
        0.005663904827088118,
        -0.033214058727025986,
        0.062088869512081146,
        -0.007567038293927908,
        0.06750939041376114,
        0.05458549037575722,
        -0.015827704221010208,
        0.05665132775902748,
        -0.0461014062166214,
        -0.030693741515278816,
        -0.04436648637056351,
        -0.05125461518764496,
        -0.059496909379959106,
        0.01967918686568737,
        0.05392260476946831,
        -0.0017902343533933163,
        -0.02729630656540394,
        -0.05143646150827408,
        -0.034439366310834885,
        0.04188849776983261,
        -0.0554223358631134,
        -0.05517498776316643,
        0.019709652289748192,
        0.059063445776700974,
        -0.06320924311876297,
        0.0317525714635849,
        0.06273733079433441,
        0.029997963458299637,
        0.061381690204143524,
        0.002903186483308673,
        0.06456326693296432,
        -0.01498695369809866,
        0.05902212858200073,
        -0.047536399215459824,
        0.03672001510858536,
        0.06588955968618393,
        0.06027086451649666,
        -0.06027638539671898,
        -0.0305141843855381,
        -0.020090220496058464,
        0.06117652356624603,
        -0.05309257283806801,
        -0.02612304873764515,
        0.03159375861287117,
        0.022078435868024826,
        0.023183729499578476,
        -0.01284018810838461,
        0.047270387411117554,
        0.058194682002067566,
        0.052092839032411575,
        0.05181316286325455,
        0.029228996485471725,
        -0.017463307827711105,
        0.05241779237985611,
        0.047606758773326874,
        0.019531719386577606,
        -0.041560277342796326,
        0.04820350557565689,
        -0.032541655004024506,
        -0.061771459877491,
        -0.0016406852519139647,
        0.06037689000368118,
        -0.05816156044602394,
        0.03477688133716583,
        0.04310810938477516,
        -0.038085389882326126,
        0.034958530217409134,
        0.06633410602807999,
        -0.007846382446587086,
        0.02642393298447132,
        -0.00010098730854224414,
        0.01824520342051983,
        -0.04567161574959755,
        0.063652403652668,
        -0.029138145968317986,
        -0.05078965798020363,
        -0.059038978070020676,
        0.048961032181978226,
        -0.011620152741670609,
        -0.05157890170812607,
        0.02863813377916813,
        0.05471793934702873,
        -0.040398675948381424,
        -0.03157985955476761,
        -0.02120782434940338,
        -0.009358293376863003,
        0.005221648141741753,
        -0.032798029482364655,
        0.009806767106056213,
        -0.03206002339720726,
        -0.05986819416284561,
        0.051865898072719574,
        0.02060270868241787,
        0.03836655989289284,
        -0.05763351917266846,
        0.06337986141443253,
        -0.05375799909234047,
        0.04007450118660927,
        0.043672289699316025,
        0.029010573402047157,
        -0.042072828859090805,
        0.031254932284355164,
        0.05943838134407997,
        -0.020303450524806976,
        0.06628800928592682,
        0.04479565471410751,
        -0.023886727169156075,
        0.05733506754040718,
        -0.057696398347616196,
        -0.03773267939686775,
        0.06297849863767624,
        -0.029426613822579384,
        0.01877540349960327,
        -0.05064956471323967,
        -0.022942861542105675,
        -0.045778099447488785,
        0.03836330026388168,
        -0.06069403514266014,
        -0.011344542726874352,
        0.03130644932389259,
        -0.023588303476572037,
        0.06480567902326584,
        0.06493017077445984,
        -0.043955422937870026,
        0.031775884330272675,
        0.06496365368366241,
        -0.054182060062885284,
        0.06674828380346298,
        -0.06724944710731506,
        0.05366714298725128,
        0.03166630119085312,
        -0.025129111483693123,
        -0.06609778106212616,
        -0.028494762256741524,
        0.008741309866309166,
        0.0042689829133450985,
        -0.026131875813007355,
        -0.057498518377542496,
        0.032221052795648575,
        -0.055703941732645035,
        -0.03326309472322464,
        0.055205438286066055,
        0.013107211329042912,
        -0.06674198061227798,
        -0.02671070769429207,
        -0.007797362748533487,
        0.06593222916126251,
        -0.04948188364505768,
        -0.03413993492722511,
        0.04347916319966316,
        0.034764230251312256,
        0.05918398126959801,
        0.015225999057292938,
        0.05335170030593872,
        -0.04122869297862053,
        0.017533840611577034,
        0.05941593647003174,
        -0.05592961981892586,
        0.06456504017114639,
        -0.024468984454870224,
        -0.023649509996175766,
        0.05066915601491928,
        -0.03582191839814186,
        0.03388562798500061,
        -0.058557700365781784,
        0.0030616719741374254,
        -0.04833661764860153,
        -0.003957849927246571,
        -0.06338077038526535,
        0.05228385329246521,
        -0.04397553950548172,
        0.022194642573595047,
        0.03111942857503891,
        0.0633658617734909,
        0.054175570607185364,
        0.05077754706144333,
        -0.054195087403059006,
        0.06395518779754639,
        0.03436727821826935,
        0.03806638345122337,
        0.06179060786962509,
        -0.06430348753929138,
        0.04381406679749489,
        0.014750541187822819,
        -0.0663084164261818,
        0.05871160328388214,
        0.06192051246762276,
        0.050267212092876434,
        -0.04970304295420647,
        0.03242398798465729,
        -0.03821763023734093,
        0.019516168162226677,
        -0.02492867223918438,
        0.05765340477228165,
        0.04057016223669052,
        -0.05843236669898033,
        0.04047442972660065,
        0.014812224544584751,
        0.04528089612722397,
        0.04652014374732971,
        0.012131039053201675,
        -0.052115678787231445,
        -0.04632304608821869,
        -0.021857181563973427,
        0.04182650148868561,
        -0.022785816341638565,
        -0.010933222249150276,
        -0.03788512945175171,
        0.05489014834165573,
        0.0521804504096508,
        0.03810513764619827,
        -0.057364288717508316,
        -0.05480660870671272,
        -0.05544741079211235,
        0.04709398001432419,
        -0.037262264639139175,
        0.04019243270158768,
        -0.056466612964868546,
        -0.004736080765724182,
        -0.03723743557929993,
        0.06353024393320084,
        0.04787692055106163,
        -0.04603419080376625,
        0.05901985615491867,
        0.02794021926820278,
        -0.01350559201091528,
        0.06504221260547638,
        0.05993106961250305,
        -0.06749340146780014,
        -0.04715343192219734,
        -0.012537037953734398,
        0.05232871696352959,
        0.02524602971971035,
        0.05311074107885361,
        -0.030150936916470528,
        0.05956154316663742,
        0.06148327514529228,
        -0.028796041384339333,
        -0.02477138675749302,
        0.010352819226682186,
        0.008731904439628124,
        0.0439855232834816,
        -0.04060101509094238,
        -0.014044061303138733,
        0.06313049793243408,
        -0.06325230747461319,
        0.04868754744529724,
        -0.003920108545571566,
        0.016250353306531906,
        -0.011192897334694862,
        -0.03006901405751705,
        -0.023427622392773628,
        0.05646352469921112,
        -0.022254467010498047,
        0.053901173174381256,
        -0.059653736650943756,
        -0.01093360222876072,
        0.043827518820762634,
        0.04270847886800766,
        0.05369811877608299,
        -0.01149547565728426,
        0.02799106016755104,
        -0.04272592067718506,
        0.007133690640330315,
        0.061921004205942154,
        0.019062086939811707,
        0.02115868218243122,
        -0.06657636165618896,
        -0.06525756418704987,
        0.04770401120185852,
        0.05180682986974716,
        -0.03381269797682762,
        -0.03212479501962662,
        -0.06379933655261993,
        0.016460515558719635,
        -0.048706479370594025,
        -0.06727437674999237,
        -0.026311423629522324,
        0.04616162180900574,
        0.01030298788100481,
        0.03862253576517105,
        0.06474214792251587,
        0.04697617515921593,
        0.002445282181724906,
        -0.02205720730125904,
        -0.06246886029839516,
        0.005692149978131056,
        0.05878476798534393,
        -0.005112909711897373,
        -0.06458503007888794,
        -0.021665796637535095,
        -0.003555683884769678,
        0.016637563705444336,
        -0.047866031527519226,
        0.0450415164232254,
        0.04972664266824722,
        -0.04405195266008377,
        -0.06284478306770325,
        0.019279051572084427,
        0.03605508804321289,
        0.055408719927072525,
        -0.057896826416254044,
        -0.005060418974608183,
        0.050762780010700226,
        0.03320431336760521,
        0.05692589282989502,
        0.0037122198846191168,
        -0.01659550704061985,
        0.03211704269051552,
        -0.036322981119155884,
        0.056424789130687714,
        -0.015856407582759857,
        -0.02050652541220188,
        -0.03061838448047638,
        0.04350583627820015,
        -0.049138348549604416,
        0.06377846002578735,
        0.0044484324753284454,
        -0.06689879298210144,
        0.014203503727912903,
        -0.0424499548971653,
        -0.043596282601356506,
        -0.04025157913565636,
        -0.05393420532345772,
        -0.041398219764232635,
        0.019115202128887177,
        0.04805449768900871,
        0.03726974502205849,
        0.048087894916534424,
        0.0654340609908104,
        0.0026277408469468355,
        0.023154836148023605,
        0.06518150866031647,
        -0.05530431494116783,
        0.06304262578487396,
        0.03492710739374161,
        -0.046198874711990356,
        -0.021354883909225464,
        0.027638785541057587,
        0.032250385731458664,
        -0.010053484700620174,
        -0.06345152854919434,
        0.028723081573843956,
        0.018904615193605423,
        -0.0044925641268491745,
        0.045290667563676834,
        -0.049411144107580185,
        0.01806299388408661,
        -0.06352151185274124,
        0.02533756196498871,
        0.03658607602119446,
        0.06647533923387527,
        -0.067036472260952,
        0.018183689564466476,
        -0.06216315180063248,
        -0.05430928245186806,
        0.02168850228190422,
        0.06618408113718033,
        0.03695991635322571,
        0.0367819219827652,
        -0.06654432415962219,
        0.014794401824474335,
        0.053396791219711304,
        0.025547105818986893,
        -0.01643212139606476,
        -0.052101388573646545,
        -0.058265335857868195,
        -0.05450436472892761,
        -0.05213833972811699,
        0.02150069549679756,
        0.052873071283102036,
        0.02214600332081318,
        0.0017942405538633466,
        0.05094662681221962,
        -0.05757612735033035,
        -0.021669307723641396,
        -0.05998225882649422,
        0.040910448879003525,
        0.02117902599275112,
        -0.009021187201142311,
        -0.022220663726329803,
        0.019958464428782463,
        0.05595747008919716,
        0.055062178522348404,
        0.05650107190012932,
        0.054801493883132935,
        -0.058599747717380524,
        -0.04490185156464577,
        -0.04843217879533768,
        -0.034299593418836594,
        -0.0005768701084889472,
        -0.04577423632144928,
        0.06753922253847122,
        0.008052738383412361,
        -0.04626886174082756,
        -0.06747859716415405,
        0.025742655619978905,
        0.03515104576945305
    ],
    [
        -0.0019030241528525949,
        0.053511299192905426,
        0.00822630524635315,
        0.04046095907688141,
        -0.020779872313141823,
        0.06385546177625656,
        -0.04177163168787956,
        -0.05583016946911812,
        0.06050114333629608,
        -0.01518824603408575,
        0.022763608023524284,
        0.021769752725958824,
        -0.04554280266165733,
        -0.02138279378414154,
        -0.06010288745164871,
        0.05564235523343086,
        -0.025232819840312004,
        0.06329767405986786,
        -0.036651816219091415,
        -0.06420990824699402,
        0.059425193816423416,
        0.06296493113040924,
        0.04105649143457413,
        -0.016341745853424072,
        0.058479808270931244,
        0.04278020188212395,
        0.046620115637779236,
        -0.015035687945783138,
        0.015298412181437016,
        0.05918096750974655,
        0.04199815168976784,
        -0.039825934916734695,
        -0.023321030661463737,
        -0.0636044517159462,
        0.05895911902189255,
        0.002123839920386672,
        -0.061943117529153824,
        -0.02451702207326889,
        0.0626240000128746,
        0.022733330726623535,
        0.06232452765107155,
        0.043824221938848495,
        0.015446794219315052,
        -0.06056993454694748,
        0.06314124166965485,
        0.04955200105905533,
        0.007358446251600981,
        0.05891778692603111,
        0.05826381593942642,
        0.01355492603033781,
        0.04718286544084549,
        0.06292801350355148,
        -0.060634028166532516,
        0.06015042960643768,
        -0.009193495847284794,
        0.013813936151564121,
        0.02220023423433304,
        0.06016940623521805,
        0.009987848810851574,
        -0.051653020083904266,
        -0.04770442843437195,
        0.0299585722386837,
        -0.04032484441995621,
        0.06110157445073128,
        -0.061211489140987396,
        -0.05619421973824501,
        -0.03919646143913269,
        -0.04527750983834267,
        -0.06108367443084717,
        0.03148577734827995,
        -0.054435670375823975,
        -0.06383064389228821,
        0.056820377707481384,
        0.055429182946681976,
        0.010623490437865257,
        -0.045070867985486984,
        -0.023275509476661682,
        0.06352178752422333,
        0.0636228397488594,
        -0.013462038710713387,
        0.022234074771404266,
        0.060343414545059204,
        -0.05659875646233559,
        0.05695977434515953,
        0.06029389426112175,
        0.04954127222299576,
        0.06383364647626877,
        0.06401970982551575,
        0.032679978758096695,
        -0.029423339292407036,
        -0.05298817530274391,
        -0.04411664977669716,
        -0.06409134715795517,
        -0.03208552673459053,
        -0.04401566833257675,
        0.056818876415491104,
        -0.009755302220582962,
        -0.055997349321842194,
        -0.0008321109344251454,
        0.0397651232779026,
        -0.06034770980477333,
        0.04855712503194809,
        0.01334492489695549,
        -0.029174767434597015,
        0.04319293797016144,
        -0.06043799966573715,
        -0.0568007156252861,
        0.04652659595012665,
        0.05877912789583206,
        0.049017779529094696,
        -0.06112236902117729,
        0.03301702067255974,
        -0.06052693724632263,
        0.012782624922692776,
        -0.0583733394742012,
        -0.05740505829453468,
        0.0469072125852108,
        -0.006369834765791893,
        0.0628909170627594,
        0.008244959637522697,
        0.0029946602880954742,
        0.05731244012713432,
        -0.01364596001803875,
        -0.02868516370654106,
        -0.027131957933306694,
        -0.0018719808431342244,
        -0.06267917156219482,
        -0.05772492662072182,
        0.05926093831658363,
        0.03797788918018341,
        -0.04561452195048332,
        -0.04569873958826065,
        -0.039783209562301636,
        0.046277567744255066,
        -0.04894862696528435,
        -0.05597880482673645,
        0.033171962946653366,
        0.038034722208976746,
        -0.06048481911420822,
        0.013714657165110111,
        0.05648474395275116,
        0.018732823431491852,
        0.05926414206624031,
        -0.014604105614125729,
        0.05689472705125809,
        -0.06381288915872574,
        0.05735510587692261,
        -0.045069459825754166,
        0.026021957397460938,
        0.05592692643404007,
        0.06010761484503746,
        -0.05734620988368988,
        -0.028060978278517723,
        -0.04219641536474228,
        0.03254709765315056,
        -0.04372772201895714,
        -0.017729660496115685,
        0.04211115464568138,
        0.000009121609764406458,
        0.041249506175518036,
        0.06417537480592728,
        0.012826573103666306,
        0.049484726041555405,
        0.05637137219309807,
        0.004834163002669811,
        0.027493100613355637,
        -0.016026433557271957,
        0.006783203221857548,
        0.02624577470123768,
        0.022416893392801285,
        -0.034227609634399414,
        0.05288058519363403,
        -0.03709186613559723,
        -0.06119774281978607,
        0.008118444122374058,
        0.060029346495866776,
        -0.05084751546382904,
        0.019920852035284042,
        0.03639167919754982,
        -0.05328904837369919,
        -0.048660654574632645,
        0.06419472396373749,
        0.002302734646946192,
        0.04952959716320038,
        0.015871725976467133,
        0.0247561763972044,
        -0.028939932584762573,
        0.05271191895008087,
        0.008726022206246853,
        -0.054629433900117874,
        -0.04899449273943901,
        0.03276143595576286,
        0.04392591118812561,
        -0.042483389377593994,
        -0.0005799256032332778,
        0.02464539371430874,
        0.011330869048833847,
        -0.06419514119625092,
        -0.04622642695903778,
        -0.022324837744235992,
        0.05838662385940552,
        -0.06360755860805511,
        0.04058070108294487,
        -0.03789427876472473,
        -0.0551103912293911,
        0.040731556713581085,
        0.006122925318777561,
        0.030543498694896698,
        -0.050781287252902985,
        0.062078215181827545,
        -0.05424690991640091,
        0.042044010013341904,
        0.03491981327533722,
        0.05503415688872337,
        -0.05974668264389038,
        0.052224840968847275,
        0.052349917590618134,
        -0.03222567215561867,
        0.06176413968205452,
        -0.05446330085396767,
        0.015266042202711105,
        0.06123165786266327,
        0.02246125601232052,
        -0.008883574046194553,
        0.058398835361003876,
        -0.013280501589179039,
        -0.0017887064022943377,
        -0.014581483788788319,
        -0.048529934138059616,
        -0.039641279727220535,
        0.03132646530866623,
        -0.0610145665705204,
        -0.02819305844604969,
        0.038121502846479416,
        -0.001018085633404553,
        0.053007155656814575,
        0.059767138212919235,
        -0.037325914949178696,
        0.00032818023464642465,
        0.06107939034700394,
        -0.047694675624370575,
        0.061323825269937515,
        -0.06373919546604156,
        0.045209821313619614,
        0.044864095747470856,
        -0.0024465713649988174,
        -0.06196662038564682,
        -0.02190643921494484,
        0.03839416429400444,
        -0.014699253253638744,
        -0.05128519982099533,
        -0.055361345410346985,
        0.030593790113925934,
        -0.06342366337776184,
        -0.047291532158851624,
        0.06119375303387642,
        -0.045390158891677856,
        -0.05815916880965233,
        -0.008863060735166073,
        -0.043960995972156525,
        0.06406605243682861,
        -0.047523099929094315,
        -0.008992210030555725,
        0.021188288927078247,
        0.013155081309378147,
        0.0606156662106514,
        -0.0041722762398421764,
        0.050080783665180206,
        0.01341156754642725,
        0.060433529317379,
        0.05472120642662048,
        -0.05889993533492088,
        0.06395941227674484,
        -0.03558942675590515,
        -0.03363817557692528,
        0.0493057519197464,
        -0.03826897218823433,
        -0.04249925911426544,
        -0.053355686366558075,
        -0.011440823785960674,
        -0.044900745153427124,
        0.053880613297224045,
        -0.06072590872645378,
        0.05489964783191681,
        0.06224885955452919,
        -0.05183998495340347,
        0.01909204199910164,
        0.061434291303157806,
        0.05695108324289322,
        0.0553111806511879,
        -0.05578184872865677,
        0.017713453620672226,
        0.05434231460094452,
        0.04456155002117157,
        0.05645725503563881,
        -0.06156779080629349,
        0.04487437382340431,
        0.03848027437925339,
        -0.06011990085244179,
        0.051912639290094376,
        0.05892038345336914,
        -0.02988138049840927,
        -0.0440276563167572,
        0.04450923204421997,
        -0.059165410697460175,
        0.02123991958796978,
        -0.025295216590166092,
        0.0534554049372673,
        -0.052248090505599976,
        -0.007615183480083942,
        0.029130756855010986,
        -0.029900308698415756,
        -0.02242216095328331,
        0.036691661924123764,
        0.008942768909037113,
        -0.05507920682430267,
        -0.03110523894429207,
        -0.05501837283372879,
        0.038505956530570984,
        -0.03424330800771713,
        0.04569582641124725,
        -0.00426859874278307,
        0.04803787171840668,
        0.05502013862133026,
        0.047468919306993484,
        -0.010464251972734928,
        -0.05112877115607262,
        -0.03864018991589546,
        -0.04704122617840767,
        -0.039217766374349594,
        0.02227221056818962,
        0.03543931990861893,
        -0.043636709451675415,
        -0.036997877061367035,
        0.05603752285242081,
        0.008059332147240639,
        0.005904137622565031,
        0.05552562326192856,
        -0.019568204879760742,
        0.03457598015666008,
        -0.04386560618877411,
        0.05539873614907265,
        -0.060551419854164124,
        -0.04860372468829155,
        -0.0527275875210762,
        0.013877129182219505,
        0.021572832018136978,
        0.0512724369764328,
        -0.058503080159425735,
        0.06037241220474243,
        0.06197771430015564,
        0.04355284944176674,
        -0.013050457462668419,
        -0.00842094887048006,
        -0.04977397620677948,
        0.04288293421268463,
        -0.05443292483687401,
        -0.04270249605178833,
        0.05673014000058174,
        -0.057582251727581024,
        0.04525074362754822,
        -0.02454245835542679,
        -0.014030414633452892,
        -0.01348194945603609,
        -0.049942102283239365,
        -0.05261825770139694,
        0.0574004240334034,
        -0.02942323125898838,
        0.05510162189602852,
        -0.06163674220442772,
        -0.021388303488492966,
        0.04626796394586563,
        0.020654229447245598,
        0.03314852714538574,
        0.012955445796251297,
        0.009944125078618526,
        -0.05482059344649315,
        0.06420475989580154,
        0.062450017780065536,
        0.05749910697340965,
        0.037713631987571716,
        -0.06305892020463943,
        -0.06192578747868538,
        0.040042418986558914,
        0.06376572698354721,
        -0.019746504724025726,
        -0.028752749785780907,
        -0.05201871693134308,
        0.012058395892381668,
        -0.020580628886818886,
        -0.06383311003446579,
        -0.009089003317058086,
        -0.03857508674263954,
        -0.05274650827050209,
        0.026678184047341347,
        0.0603555366396904,
        0.058301493525505066,
        0.000606429937761277,
        -0.02631618082523346,
        -0.060001883655786514,
        -0.018002158030867577,
        0.06030813604593277,
        0.0030923776794224977,
        -0.06414707005023956,
        -0.028398476541042328,
        0.041680995374917984,
        0.031916696578264236,
        -0.03788591921329498,
        0.04550525173544884,
        0.04332884028553963,
        -0.024114634841680527,
        -0.05956428125500679,
        0.04522145539522171,
        0.011531751602888107,
        0.024727394804358482,
        -0.05180913582444191,
        -0.02857605181634426,
        -0.05072682723402977,
        0.024649349972605705,
        0.053732115775346756,
        0.019715413451194763,
        0.008636336773633957,
        -0.009215226396918297,
        -0.048996057361364365,
        0.059692371636629105,
        0.04817431420087814,
        0.013476424850523472,
        -0.038408685475587845,
        0.005641077179461718,
        -0.05696653947234154,
        0.06344309449195862,
        -0.0032910697627812624,
        -0.05761324614286423,
        -0.011253360658884048,
        -0.0476769283413887,
        -0.027363747358322144,
        -0.04229436814785004,
        -0.02732202038168907,
        -0.03610456734895706,
        -0.009195997379720211,
        0.00886353850364685,
        0.04396158456802368,
        0.05631718412041664,
        0.0540037527680397,
        0.01592608168721199,
        -0.002245335839688778,
        0.059832703322172165,
        -0.052309464663267136,
        0.053631361573934555,
        -0.018086571246385574,
        -0.061337925493717194,
        -0.019702820107340813,
        0.006070838309824467,
        0.026161350309848785,
        0.018970556557178497,
        -0.06082530692219734,
        0.03855680301785469,
        -0.01322327833622694,
        -0.027691612020134926,
        0.03014291077852249,
        -0.054880738258361816,
        -0.05446268245577812,
        -0.05601733922958374,
        0.029809165745973587,
        0.03286278247833252,
        0.06271672248840332,
        0.011387343518435955,
        0.04806187376379967,
        -0.052722565829753876,
        -0.055952928960323334,
        0.034265682101249695,
        0.06342145800590515,
        -0.00210567656904459,
        0.02896016463637352,
        -0.06376534700393677,
        0.020768124610185623,
        0.05507485941052437,
        0.045878469944000244,
        0.04899199306964874,
        -0.05831499025225639,
        -0.049623940140008926,
        -0.055993352085351944,
        -0.03726797550916672,
        0.03328689560294151,
        0.05897382274270058,
        0.03252508491277695,
        0.025190060958266258,
        0.048549018800258636,
        -0.04780606925487518,
        -0.04225175455212593,
        -0.04274722933769226,
        0.024283654987812042,
        -0.03403010591864586,
        -0.022187547758221626,
        -0.02028726227581501,
        0.014576311223208904,
        0.046491049230098724,
        0.061455439776182175,
        0.04710830748081207,
        0.06095393747091293,
        -0.06240636482834816,
        0.021247409284114838,
        -0.05582602322101593,
        -0.005888347513973713,
        0.0058677950873970985,
        -0.03935243561863899,
        0.06412741541862488,
        0.017815742641687393,
        -0.0456736795604229,
        -0.05996935814619064,
        0.04330190271139145,
        0.017434753477573395
    ],
    [
        0.015970027074217796,
        0.06020570918917656,
        0.05413169041275978,
        0.01441455539315939,
        -0.04029559716582298,
        0.01224539801478386,
        -0.028958061710000038,
        -0.06909527629613876,
        0.07293519377708435,
        0.01142024900764227,
        0.026243798434734344,
        -0.025921927765011787,
        -0.038755401968955994,
        -0.04092029109597206,
        -0.057272158563137054,
        0.07334358245134354,
        0.04740891978144646,
        0.07303837686777115,
        -0.03893742337822914,
        -0.035275742411613464,
        -0.01459643617272377,
        0.06222318857908249,
        -0.041870903223752975,
        -0.05859892815351486,
        -0.0033230578992515802,
        0.019996151328086853,
        0.045208655297756195,
        0.034522704780101776,
        0.023693261668086052,
        -0.017470832914114,
        -0.006302430294454098,
        -0.032254014164209366,
        0.011737508699297905,
        -0.06380125880241394,
        0.05759729444980621,
        -0.01338797528296709,
        -0.06618458777666092,
        -0.030514763668179512,
        -0.07460115849971771,
        0.01914559118449688,
        0.022266007959842682,
        0.031407177448272705,
        0.02991131693124771,
        -0.06905749440193176,
        0.06375312805175781,
        0.04510519653558731,
        -0.00828204769641161,
        0.04416695982217789,
        0.021939147263765335,
        0.024804597720503807,
        0.03723696619272232,
        0.07477955520153046,
        -0.06031978875398636,
        0.05353299528360367,
        0.05439237877726555,
        -0.058595094829797745,
        0.014298866502940655,
        -0.021285532042384148,
        -0.013149600476026535,
        -0.03337691351771355,
        -0.0677093043923378,
        0.05295070633292198,
        -0.046109601855278015,
        0.06940507143735886,
        -0.053286533802747726,
        -0.006915245670825243,
        0.02603033185005188,
        0.033911775797605515,
        -0.07201951742172241,
        0.0006971645634621382,
        -0.04573681950569153,
        -0.06770437210798264,
        0.06471943855285645,
        -0.01064225658774376,
        -0.00030386587604880333,
        -0.0399768203496933,
        -0.00736985681578517,
        0.07540391385555267,
        -0.05770060420036316,
        -0.032664455473423004,
        -0.0011237546568736434,
        0.03988031670451164,
        -0.041529424488544464,
        0.0129190469160676,
        0.05097140744328499,
        0.04770190268754959,
        0.06798369437456131,
        -0.04107680544257164,
        0.05622801184654236,
        0.053877655416727066,
        0.056723348796367645,
        -0.007374936249107122,
        -0.05193048715591431,
        -0.05136308819055557,
        -0.037994928658008575,
        0.03534175455570221,
        0.02059374377131462,
        -0.060274869203567505,
        0.045941565185785294,
        0.004911911673843861,
        -0.06722752004861832,
        -0.04703850671648979,
        0.022195471450686455,
        0.010352171026170254,
        0.04743700847029686,
        -0.07422278076410294,
        -0.0189789030700922,
        0.060006313025951385,
        0.024355802685022354,
        0.021732371300458908,
        -0.06933331489562988,
        0.02376618981361389,
        -0.06962195783853531,
        0.055718835443258286,
        0.06361501663923264,
        0.02917221002280712,
        0.02527514286339283,
        -0.03451914340257645,
        0.0734180137515068,
        0.0024280657526105642,
        -0.013979938812553883,
        0.023529626429080963,
        0.027453910559415817,
        -0.06723327189683914,
        -0.058121465146541595,
        -0.060505323112010956,
        -0.041140343993902206,
        0.055161770433187485,
        0.06741145998239517,
        0.0674932673573494,
        0.042809147387742996,
        -0.061065346002578735,
        0.011892634443938732,
        -0.01189792063087225,
        -0.04071542248129845,
        -0.06764692813158035,
        0.022775856778025627,
        0.043837208300828934,
        -0.07298801839351654,
        -0.003006851766258478,
        0.06713889539241791,
        0.007290937937796116,
        0.06589040160179138,
        0.03660339117050171,
        0.027507418766617775,
        0.04730482026934624,
        0.024135855957865715,
        -0.05075949802994728,
        0.05086437985301018,
        0.058120518922805786,
        0.05434873700141907,
        -0.06649342179298401,
        0.062034592032432556,
        -0.052799321711063385,
        0.0517665296792984,
        -0.041765231639146805,
        -0.07080099731683731,
        0.05273696035146713,
        0.03657851740717888,
        0.026227567344903946,
        0.026731090620160103,
        0.024063458666205406,
        0.06449168175458908,
        0.004548684228211641,
        0.060797031968832016,
        0.018515873700380325,
        0.03483060374855995,
        0.020805591717362404,
        0.027176422998309135,
        0.016534049063920975,
        0.03544015809893608,
        0.009474321268498898,
        -0.035973627120256424,
        -0.04975191131234169,
        0.07217920571565628,
        0.0670681968331337,
        -0.06641271710395813,
        -0.005746570415794849,
        0.060941293835639954,
        -0.0629725530743599,
        0.006681477651000023,
        0.06725296378135681,
        0.03099234402179718,
        -0.005204567685723305,
        -0.009020401164889336,
        0.002548013348132372,
        -0.022338882088661194,
        0.06649968028068542,
        -0.045404061675071716,
        0.06015615165233612,
        -0.031270112842321396,
        0.030702989548444748,
        -0.02864859811961651,
        0.013991798274219036,
        0.013938822783529758,
        -0.009014553390443325,
        -0.03918865695595741,
        -0.030779585242271423,
        -0.009863623417913914,
        0.045007869601249695,
        0.014294506050646305,
        -0.008676797151565552,
        0.0006180144846439362,
        -0.05723706632852554,
        -0.07001432031393051,
        0.03576631844043732,
        -0.007688477169722319,
        0.004472414962947369,
        -0.04031587392091751,
        0.061533451080322266,
        -0.03867732733488083,
        0.06899227946996689,
        0.04841289669275284,
        0.06232434883713722,
        0.02780311182141304,
        -0.0005255438736639917,
        0.001305668381974101,
        0.0013949940912425518,
        0.06229123845696449,
        -0.0033388538286089897,
        0.018619684502482414,
        0.06861094385385513,
        -0.029787534847855568,
        0.011549454182386398,
        0.007533636875450611,
        0.07096244394779205,
        -0.02850727178156376,
        0.015425750985741615,
        -0.04127931594848633,
        -0.028709087520837784,
        -0.04245297238230705,
        -0.013479907996952534,
        -0.058033496141433716,
        0.037685979157686234,
        0.030366839841008186,
        0.0698179230093956,
        0.06283194571733475,
        -0.03147391229867935,
        -0.03754037246108055,
        0.07437464594841003,
        -0.025743583217263222,
        0.06704717874526978,
        -0.059840623289346695,
        0.04568447917699814,
        0.019105084240436554,
        0.0338040366768837,
        -0.07412675023078918,
        -0.05815795063972473,
        -0.007117952685803175,
        0.022608427330851555,
        -0.006961886305361986,
        -0.056411631405353546,
        -0.029804470017552376,
        -0.061882685869932175,
        -0.05413554236292839,
        0.07162972539663315,
        -0.06052898243069649,
        -0.056585270911455154,
        -0.0020767133682966232,
        -0.017901184037327766,
        0.07248631119728088,
        -0.057292092591524124,
        -0.04657495766878128,
        0.03358517959713936,
        0.006930223200470209,
        0.06454060971736908,
        -0.004529994446784258,
        0.06396190077066422,
        -0.03809884190559387,
        0.04959791526198387,
        0.0466109998524189,
        -0.0073385476134717464,
        0.05869121477007866,
        -0.042666222900152206,
        -0.055258411914110184,
        0.05267692729830742,
        -0.03540777415037155,
        0.031786639243364334,
        -0.04795768857002258,
        -0.013749828562140465,
        -0.05165104940533638,
        0.031828876584768295,
        -0.05668234825134277,
        0.043247565627098083,
        0.005408906377851963,
        -0.03928522765636444,
        0.05630498006939888,
        0.07112473994493484,
        -0.007582433987408876,
        0.053391166031360626,
        -0.035017695277929306,
        0.028748761862516403,
        -0.026215337216854095,
        0.021555431187152863,
        0.05714739114046097,
        -0.07193198055028915,
        0.047090914100408554,
        0.04321741685271263,
        -0.06752247363328934,
        0.055734988301992416,
        0.06300971657037735,
        0.04824896901845932,
        -0.051269255578517914,
        -0.0014545496087521315,
        0.0054711150005459785,
        -0.0014778844779357314,
        -0.05310104414820671,
        0.016999172046780586,
        0.05767182633280754,
        -0.03445851430296898,
        -0.028285415843129158,
        0.04004368558526039,
        -0.002115103183314204,
        -0.03364848345518112,
        -0.01036831270903349,
        -0.05916135013103485,
        -0.011502550914883614,
        -0.04782126471400261,
        0.004318498075008392,
        0.012421321123838425,
        -0.03587322309613228,
        0.015490995720028877,
        0.027827071025967598,
        -0.020152892917394638,
        0.05039104446768761,
        -0.05476808547973633,
        0.031854089349508286,
        0.033964671194553375,
        0.06577901542186737,
        -0.035586681216955185,
        -0.012509075924754143,
        -0.0674186497926712,
        -0.025457410141825676,
        -0.056508008390665054,
        0.06278800964355469,
        0.0005696238949894905,
        -0.030678914859890938,
        0.0513724610209465,
        -0.007982123643159866,
        0.031655583530664444,
        0.06670287251472473,
        0.060157518833875656,
        -0.0743885412812233,
        0.004949337337166071,
        -0.055428147315979004,
        0.028075523674488068,
        0.034240566194057465,
        0.057540372014045715,
        -0.033781494945287704,
        0.06290516257286072,
        0.05379274860024452,
        -0.04246623069047928,
        -0.007078562863171101,
        0.05196906253695488,
        0.03989585489034653,
        0.033208608627319336,
        -0.062114592641592026,
        -0.017894010990858078,
        0.05261531472206116,
        -0.05244450643658638,
        0.04912452772259712,
        -0.03553028404712677,
        0.026436936110258102,
        -0.021274011582136154,
        0.008182410150766373,
        -0.03975023329257965,
        0.021224766969680786,
        0.005242655985057354,
        0.0025261822156608105,
        -0.06235908344388008,
        0.02861226163804531,
        0.052584435790777206,
        0.017535658553242683,
        0.05992516502737999,
        -0.011378049850463867,
        -0.012395510450005531,
        -0.052156269550323486,
        0.04832998663187027,
        0.06549419462680817,
        -0.00980248861014843,
        0.002442118711769581,
        -0.03935747221112251,
        -0.07221002876758575,
        0.05014142021536827,
        0.05067203566431999,
        -0.005566315725445747,
        -0.017699716612696648,
        -0.06663399189710617,
        -0.02382640913128853,
        -0.04469437524676323,
        -0.07008469849824905,
        -0.002342041116207838,
        0.009526251815259457,
        0.028590042144060135,
        -0.05206499993801117,
        0.06698751449584961,
        0.0650833323597908,
        0.02835734933614731,
        -0.004001175984740257,
        -0.05861359089612961,
        -0.04537573456764221,
        0.05211443081498146,
        0.034703053534030914,
        -0.057308558374643326,
        -0.040143173187971115,
        -0.004175609443336725,
        -0.008548170328140259,
        -0.05166348069906235,
        0.0387161485850811,
        0.003872848581522703,
        -0.05308748409152031,
        -0.05114586278796196,
        0.053989991545677185,
        -0.0652436763048172,
        0.06483911722898483,
        -0.03142757713794708,
        -0.011115355417132378,
        -0.03782065585255623,
        -0.0060784826055169106,
        -0.06157412379980087,
        -0.033202167600393295,
        -0.0374254509806633,
        -0.006085732486099005,
        -0.0005578431882895529,
        0.013825086876749992,
        0.03902599588036537,
        -0.06227157637476921,
        0.0007372163818217814,
        0.0398484505712986,
        -0.05503377318382263,
        0.05853268504142761,
        -0.025540031492710114,
        -0.03246137872338295,
        -0.0007330172811634839,
        0.01758505590260029,
        -0.05000742897391319,
        0.015770578756928444,
        -0.01891579106450081,
        -0.037931717932224274,
        -0.02772289700806141,
        0.05419662222266197,
        0.00527283176779747,
        0.01416801568120718,
        0.049574755132198334,
        0.07493612170219421,
        0.05183372646570206,
        0.06588850915431976,
        0.04902809485793114,
        0.06474470347166061,
        0.06799806654453278,
        -0.04943981021642685,
        0.002823755843564868,
        -0.037537261843681335,
        0.0043367063626646996,
        -0.025420887395739555,
        -0.06715411692857742,
        0.05266682058572769,
        0.00234679551795125,
        -0.0031620317604392767,
        0.04387137293815613,
        0.049259718507528305,
        0.01434050127863884,
        -0.044205229729413986,
        0.037514496594667435,
        -0.026094578206539154,
        0.0689840093255043,
        -0.06868840754032135,
        0.05917523801326752,
        -0.050747182220220566,
        -0.049772709608078,
        0.036247916519641876,
        -0.006115785799920559,
        -0.023470867425203323,
        -0.007228592410683632,
        -0.0709894523024559,
        -0.05046906694769859,
        0.06917161494493484,
        0.02642587013542652,
        -0.04236306622624397,
        -0.0017545608570799232,
        0.02363443374633789,
        -0.04624433070421219,
        -0.04657074064016342,
        0.04388386383652687,
        0.07083110511302948,
        0.01288537960499525,
        0.057365186512470245,
        0.06932339072227478,
        -0.05703158676624298,
        -0.00684371218085289,
        -0.06687234342098236,
        -0.015291077084839344,
        0.031860265880823135,
        -0.03633423522114754,
        -0.05763205140829086,
        0.007855468429625034,
        0.05383510887622833,
        0.0476800836622715,
        0.053630802780389786,
        0.06710877269506454,
        -0.03957521170377731,
        -0.03462991490960121,
        -0.06075357273221016,
        0.002704278565943241,
        0.002757113194093108,
        -0.02771163545548916,
        0.05919967591762543,
        0.014321090653538704,
        -0.036744385957717896,
        -0.07260995358228683,
        -0.06180129572749138,
        -0.01717517152428627
    ],
    [
        0.034864842891693115,
        0.005030106753110886,
        0.06301136314868927,
        0.03256048262119293,
        0.03610921651124954,
        0.0522812195122242,
        0.0352647565305233,
        -0.02257164567708969,
        0.058030448853969574,
        0.02478843182325363,
        0.024903493002057076,
        -0.04287954792380333,
        -0.03607441112399101,
        -0.061654232442379,
        -0.06850540637969971,
        0.016605887562036514,
        -0.04953085631132126,
        0.07092627882957458,
        -0.04283871874213219,
        -0.07286439836025238,
        0.054239422082901,
        0.06967960298061371,
        0.04765778034925461,
        -0.005098906345665455,
        0.05977201461791992,
        0.06073606014251709,
        -0.014319316484034061,
        -0.058358144015073776,
        0.05727385729551315,
        -0.04402165487408638,
        0.03241289034485817,
        -0.03163791820406914,
        0.015645170584321022,
        -0.06401855498552322,
        0.019588138908147812,
        -0.03059122897684574,
        -0.06350097060203552,
        -0.030591227114200592,
        0.05540246516466141,
        0.0493878498673439,
        0.04762289673089981,
        -0.01471412368118763,
        0.056386981159448624,
        -0.06926029920578003,
        0.06448625773191452,
        0.03101593628525734,
        -0.01948833279311657,
        0.06279878318309784,
        0.03704088181257248,
        0.07132311165332794,
        0.009542018175125122,
        0.020902369171380997,
        0.004349485505372286,
        0.031002385541796684,
        -0.014596815221011639,
        -0.05992204695940018,
        0.06493430584669113,
        0.05340415984392166,
        0.00340369064360857,
        -0.06158236786723137,
        -0.05391243100166321,
        0.00093316804850474,
        -0.047473907470703125,
        0.06507714837789536,
        -0.056706227362155914,
        -0.048272695392370224,
        -0.005520247854292393,
        -0.061120182275772095,
        -0.04740353673696518,
        -0.06541045010089874,
        -0.043536022305488586,
        -0.07075637578964233,
        0.06105252355337143,
        0.06647317111492157,
        -0.03680626302957535,
        -0.006352113094180822,
        -0.012220744043588638,
        0.07232505083084106,
        0.043986234813928604,
        0.00727386400103569,
        0.008413564413785934,
        0.06387972831726074,
        -0.06198526546359062,
        0.0394330732524395,
        -0.008184380829334259,
        0.02959739789366722,
        0.03458590433001518,
        0.031024809926748276,
        -0.010714278556406498,
        0.06814785301685333,
        -0.03977048024535179,
        0.005533256568014622,
        -0.07294303178787231,
        -0.04057351499795914,
        -0.013055762276053429,
        0.041288815438747406,
        -0.05413902923464775,
        -0.05302244797348976,
        -0.06301627308130264,
        0.031112493947148323,
        -0.07229007035493851,
        0.022684672847390175,
        0.04539387673139572,
        -0.019002581015229225,
        -0.018580026924610138,
        -0.04119555652141571,
        -0.0018939049914479256,
        -0.02381110191345215,
        -0.004068953450769186,
        0.01596529595553875,
        -0.023237911984324455,
        -0.01264893263578415,
        -0.06710673868656158,
        0.011087252758443356,
        0.01816265471279621,
        0.012671692296862602,
        0.01070822961628437,
        -0.05572531372308731,
        0.06943019479513168,
        -0.004606223199516535,
        -0.04396952688694,
        0.04923390597105026,
        0.03366144374012947,
        0.014938580803573132,
        -0.06684377044439316,
        -0.06326781213283539,
        -0.04824665188789368,
        -0.0228191539645195,
        0.07073482125997543,
        0.029460862278938293,
        0.008562386967241764,
        -0.04676336795091629,
        0.030800987035036087,
        -0.006532333791255951,
        -0.04090805724263191,
        -0.06138182058930397,
        0.0062758466228842735,
        -0.041984591633081436,
        -0.045048631727695465,
        0.06310329586267471,
        0.06321213394403458,
        -0.062357641756534576,
        0.057918768376111984,
        -0.027546774595975876,
        0.06620191037654877,
        -0.04288304224610329,
        0.06494291126728058,
        0.0505339652299881,
        -0.01245163381099701,
        -0.025512101128697395,
        0.06896157562732697,
        -0.05587408319115639,
        -0.029146268963813782,
        -0.03510304167866707,
        0.023075124248862267,
        -0.03715655207633972,
        -0.02994060330092907,
        0.06189112365245819,
        0.046542029827833176,
        0.030063603073358536,
        0.07170122861862183,
        -0.020109232515096664,
        0.03697405755519867,
        -0.035271625965833664,
        0.032942429184913635,
        0.03735967352986336,
        -0.054025113582611084,
        -0.048237670212984085,
        -0.018013080582022667,
        0.010355985723435879,
        0.04689143970608711,
        0.05593870207667351,
        -0.00041085982229560614,
        -0.0726509690284729,
        0.002857349580153823,
        0.06742122024297714,
        -0.01955767534673214,
        -0.0211864672601223,
        0.04319733753800392,
        -0.020153654739260674,
        0.0020400499925017357,
        0.07320456951856613,
        0.01626744121313095,
        0.06455226242542267,
        0.05131307616829872,
        -0.022578923031687737,
        -0.016039973124861717,
        0.055582232773303986,
        -0.006087660789489746,
        -0.04053535684943199,
        -0.0019168569706380367,
        -0.04379253461956978,
        0.04553098976612091,
        0.006520794238895178,
        -0.012511742301285267,
        -0.020484978333115578,
        -0.012050017714500427,
        -0.06916908174753189,
        -0.051999691873788834,
        -0.025771908462047577,
        0.0375165231525898,
        0.04557565599679947,
        0.018160942941904068,
        -0.04375859722495079,
        -0.059104952961206436,
        -0.012623127549886703,
        0.051645826548337936,
        0.01274479366838932,
        0.020220087841153145,
        0.06982783228158951,
        -0.06451711803674698,
        0.05556238815188408,
        -0.019631877541542053,
        0.0070715406909585,
        0.0614287443459034,
        0.027553284540772438,
        -0.06291088461875916,
        0.05040094256401062,
        0.06421266496181488,
        0.03703370317816734,
        0.05989089980721474,
        -0.005894229747354984,
        0.03226024657487869,
        -0.048640429973602295,
        0.06881747394800186,
        0.01572979986667633,
        -0.05760858952999115,
        -0.0461459718644619,
        0.009312360547482967,
        -0.042559172958135605,
        -0.03816606476902962,
        -0.05779360234737396,
        -0.008379950188100338,
        0.03398323804140091,
        0.05631045997142792,
        0.006701381877064705,
        0.06483013927936554,
        0.01793304644525051,
        -0.007863880135118961,
        0.07023264467716217,
        0.017240844666957855,
        0.03307872638106346,
        -0.07072516530752182,
        -0.004385774023830891,
        0.022033847868442535,
        -0.003892158856615424,
        -0.06856878846883774,
        -0.04417923092842102,
        -0.02098139002919197,
        0.01120021566748619,
        -0.03924758359789848,
        -0.06669095903635025,
        -0.04410089552402496,
        -0.06624902039766312,
        0.011106464080512524,
        0.05727870389819145,
        0.03118727169930935,
        -0.04012184590101242,
        0.059019360691308975,
        0.07121746987104416,
        0.06893305480480194,
        -0.05021873116493225,
        -0.010368692688643932,
        0.03860355168581009,
        -0.06195509433746338,
        0.037518952041864395,
        -0.05834143981337547,
        0.04261738806962967,
        -0.04230630397796631,
        0.018405158072710037,
        0.03608022257685661,
        -0.06348757445812225,
        0.07219818979501724,
        -0.060917459428310394,
        -0.005295370239764452,
        0.05284428969025612,
        -0.048584725707769394,
        -0.00012666246038861573,
        0.001746246125549078,
        0.000912737101316452,
        -0.056924156844615936,
        0.005453970283269882,
        -0.05026959255337715,
        -0.008020018227398396,
        -0.03662515804171562,
        -0.05683460831642151,
        0.03182344138622284,
        0.07068531215190887,
        0.045918211340904236,
        0.031041782349348068,
        -0.03938036412000656,
        -0.05001500993967056,
        0.023908011615276337,
        0.03302149474620819,
        0.05912376195192337,
        -0.05236725136637688,
        0.07028793543577194,
        0.026814771816134453,
        -0.032438941299915314,
        0.05641717463731766,
        0.005311692599207163,
        0.03798476234078407,
        -0.04297671839594841,
        0.048366960138082504,
        -0.05946892127394676,
        -0.05259479582309723,
        -0.06722855567932129,
        0.006406654138118029,
        0.0494917668402195,
        -0.03614266961812973,
        0.04798189550638199,
        -0.056223440915346146,
        -0.057643912732601166,
        -0.027222344651818275,
        -0.019051652401685715,
        -0.051817458122968674,
        0.044073790311813354,
        -0.058003250509500504,
        0.009198161773383617,
        -0.057017840445041656,
        0.033050645142793655,
        0.026695657521486282,
        0.03174864873290062,
        -0.024304741993546486,
        0.062788225710392,
        -0.010775591246783733,
        -0.012698058970272541,
        -0.04165225848555565,
        -0.040773313492536545,
        0.02053523249924183,
        -0.05950448662042618,
        0.06746191531419754,
        -0.0026691339444369078,
        -0.025874728336930275,
        0.06404687464237213,
        -0.027241457253694534,
        -0.008922064676880836,
        0.06755436211824417,
        -0.04966717213392258,
        0.07097335904836655,
        0.00265309470705688,
        0.008297372609376907,
        0.027927882969379425,
        -0.025804217904806137,
        -0.032061606645584106,
        0.042921628803014755,
        0.022310568019747734,
        0.037184759974479675,
        -0.0575079545378685,
        0.0694783553481102,
        -0.06836926192045212,
        0.0022591587621718645,
        -0.052652426064014435,
        0.005652640014886856,
        -0.07022792100906372,
        0.05933685228228569,
        -0.016176875680685043,
        0.009054542519152164,
        0.023013275116682053,
        -0.07220549136400223,
        0.03648265078663826,
        0.03287756070494652,
        0.0011551434872671962,
        0.0006282525137066841,
        -0.0015028464840725064,
        -0.05154993012547493,
        0.06804590672254562,
        0.008334438316524029,
        -0.036841798573732376,
        -0.07186400890350342,
        -0.047847770154476166,
        -0.0013047787360846996,
        -0.004853077232837677,
        -0.045993268489837646,
        0.016083160415291786,
        0.017568575218319893,
        0.0301387719810009,
        0.07117427885532379,
        -0.022842731326818466,
        0.061358723789453506,
        -0.0010303322924301028,
        -0.06495793908834457,
        -0.06882475316524506,
        -0.009379381313920021,
        0.072726771235466,
        -0.02419760264456272,
        -0.04327698051929474,
        -0.06180700287222862,
        -0.04195471107959747,
        -0.011099724099040031,
        -0.07155142724514008,
        -0.004578104708343744,
        0.05943181365728378,
        -0.05797411501407623,
        -0.060062479227781296,
        0.05891330912709236,
        0.06260064244270325,
        0.028132716193795204,
        0.03401770442724228,
        -0.06113377586007118,
        -0.014252573251724243,
        0.035167958587408066,
        -0.00691432086750865,
        -0.06627853959798813,
        -0.07156630605459213,
        0.06421364098787308,
        -0.00737559050321579,
        0.03830563649535179,
        0.06187094375491142,
        -0.027889445424079895,
        -0.03948560729622841,
        -0.0642867386341095,
        0.06728515028953552,
        -0.02677873708307743,
        0.056878987699747086,
        -0.001478430931456387,
        0.028509514406323433,
        -0.0456281416118145,
        0.07072455435991287,
        0.01215572189539671,
        0.017503419890999794,
        -0.06434591859579086,
        0.02179672010242939,
        -0.015141352079808712,
        0.05199158936738968,
        0.009564241394400597,
        0.02653639391064644,
        -0.03147265687584877,
        -0.0517580583691597,
        -0.028152193874120712,
        0.048746369779109955,
        0.03262440487742424,
        -0.055869415402412415,
        0.013041161000728607,
        0.03571518883109093,
        0.002608965616673231,
        -0.037107113748788834,
        0.0048091476783156395,
        -0.04269963875412941,
        -0.0461343452334404,
        0.04975326731801033,
        0.04512389004230499,
        0.06552237272262573,
        0.05197128653526306,
        0.05971751734614372,
        0.02095750719308853,
        0.06246805936098099,
        0.022632814943790436,
        0.05850597098469734,
        -0.018984023481607437,
        -0.01462506502866745,
        0.043286923319101334,
        0.04847127944231033,
        0.056389424949884415,
        -0.056089919060468674,
        -0.05803544074296951,
        0.005273044109344482,
        0.04325570538640022,
        -0.026658572256565094,
        0.004021217115223408,
        0.011821058578789234,
        -0.06344962865114212,
        -0.027846843004226685,
        -0.02905658259987831,
        0.034511499106884,
        0.07178322225809097,
        -0.06763564795255661,
        0.0557803176343441,
        -0.060653842985630035,
        -0.06322312355041504,
        0.03825926035642624,
        0.04820924997329712,
        0.008737405762076378,
        -0.012365397065877914,
        -0.0678514689207077,
        -0.020642656832933426,
        0.05991429463028908,
        0.010719086974859238,
        -0.017041029408574104,
        -0.06630635261535645,
        -0.036000486463308334,
        -0.022212332114577293,
        0.03682859241962433,
        0.04181889444589615,
        -0.009388342499732971,
        0.005749905481934547,
        0.03558532893657684,
        0.02736988104879856,
        -0.04715871065855026,
        -0.033169981092214584,
        -0.04551025852560997,
        -0.052018772810697556,
        -0.006692821159958839,
        0.015799488872289658,
        0.0587969645857811,
        -0.020430229604244232,
        0.028255179524421692,
        0.0585477240383625,
        0.03512938693165779,
        0.04405585676431656,
        -0.06854413449764252,
        -0.0350615568459034,
        -0.03350197151303291,
        0.022903017699718475,
        -0.02776789478957653,
        -0.007068020291626453,
        0.07238062471151352,
        0.01679384335875511,
        -0.07034148275852203,
        0.04714693874120712,
        0.05202627554535866,
        0.014434008859097958
    ],
    [
        -0.05301371589303017,
        0.05988353490829468,
        0.058134596794843674,
        0.0462595634162426,
        -0.042741820216178894,
        0.06901995092630386,
        -0.026800666004419327,
        -0.06510191410779953,
        0.06513094156980515,
        -0.06215927377343178,
        0.04757286608219147,
        -0.0344124510884285,
        -0.03447958827018738,
        -0.031133482232689857,
        -0.05568324401974678,
        0.06902717798948288,
        0.006037178914994001,
        0.06919506937265396,
        -0.023744821548461914,
        -0.07074487209320068,
        -0.022046886384487152,
        0.04953049123287201,
        0.04873061180114746,
        -0.02609073556959629,
        0.05046892166137695,
        0.01710079237818718,
        0.02705295942723751,
        0.05495865270495415,
        -0.04470660537481308,
        0.03811812773346901,
        0.022936619818210602,
        -0.07062502205371857,
        0.06062234193086624,
        -0.06179465726017952,
        0.04933701455593109,
        0.011758949607610703,
        -0.06464856117963791,
        -0.05041609704494476,
        0.03636586293578148,
        0.04620901122689247,
        0.061196696013212204,
        0.021409668028354645,
        0.02429974265396595,
        -0.05741612985730171,
        0.06997151672840118,
        0.038574568927288055,
        0.008185459300875664,
        0.03720076009631157,
        0.0562717542052269,
        -0.03348371013998985,
        0.033392880111932755,
        0.05973878875374794,
        -0.05362062156200409,
        0.06470632553100586,
        0.0248120054602623,
        0.015275885351002216,
        0.06694988906383514,
        0.04625285789370537,
        -0.01740780472755432,
        -0.056041277945041656,
        -0.05210678651928902,
        0.007651580963283777,
        -0.04684646055102348,
        0.058050502091646194,
        -0.06018807739019394,
        -0.0639461800456047,
        -0.01615392044186592,
        -0.052139803767204285,
        -0.06447167694568634,
        -0.003256185445934534,
        -0.040865764021873474,
        -0.0699288621544838,
        0.049723122268915176,
        0.053813569247722626,
        -0.04250060394406319,
        -0.02542295679450035,
        -0.06056199222803116,
        0.07048226147890091,
        0.060127343982458115,
        0.005171231925487518,
        0.0015684394165873528,
        0.04500865563750267,
        -0.05901481956243515,
        0.044452738016843796,
        0.025072194635868073,
        0.05099708214402199,
        0.06871109455823898,
        0.06722845882177353,
        0.03559447452425957,
        -0.038848426192998886,
        0.0069193160161376,
        0.0068812295794487,
        -0.06493827700614929,
        0.025755777955055237,
        -0.05268344283103943,
        0.05225376412272453,
        -0.02642136439681053,
        -0.046003539115190506,
        0.008885624818503857,
        -0.03527659922838211,
        -0.05067547410726547,
        0.015515441074967384,
        -0.010385533794760704,
        -0.029011575505137444,
        -0.02596336044371128,
        -0.07035060226917267,
        -0.04769839346408844,
        0.027275308966636658,
        0.0468921959400177,
        0.0396716445684433,
        -0.045397501438856125,
        -0.05862114205956459,
        -0.05991656705737114,
        0.010197293013334274,
        -0.05898643657565117,
        -0.03688407316803932,
        0.04457525536417961,
        0.007156594190746546,
        0.07051636278629303,
        0.016700394451618195,
        -0.01612953282892704,
        0.054716505110263824,
        0.045880600810050964,
        -0.03729068860411644,
        -0.02597779966890812,
        -0.017175743356347084,
        -0.022696031257510185,
        0.03074309043586254,
        0.06027837097644806,
        -0.005424338858574629,
        -0.06886472553014755,
        -0.04501127079129219,
        -0.015565712004899979,
        0.04103114455938339,
        -0.04144739359617233,
        -0.05597659572958946,
        0.046996962279081345,
        -0.0075703882612288,
        -0.06221674010157585,
        -0.02659529447555542,
        0.023122074082493782,
        0.051469586789608,
        0.06608825922012329,
        0.024250082671642303,
        0.0599692203104496,
        -0.06540320813655853,
        0.01564888283610344,
        0.021650027483701706,
        -0.06464643031358719,
        -0.04437870904803276,
        0.05188404396176338,
        -0.057797424495220184,
        -0.02643340267241001,
        -0.04294470697641373,
        0.06529735028743744,
        -0.0005035283975303173,
        0.05197177827358246,
        0.0028535902965813875,
        -0.014378205873072147,
        0.030748404562473297,
        0.07045258581638336,
        0.036826182156801224,
        0.053748343139886856,
        0.0318194217979908,
        0.011568517424166203,
        -0.02516803704202175,
        0.03854444622993469,
        -0.011508934199810028,
        0.03238575905561447,
        0.006169358734041452,
        -0.022547522559762,
        -0.031224993988871574,
        0.011624637991189957,
        -0.06283880025148392,
        -0.020160773769021034,
        0.06084580346941948,
        -0.02629442885518074,
        0.013381995260715485,
        0.04334377124905586,
        -0.023352911695837975,
        0.03313272073864937,
        0.05587814375758171,
        0.0373418964445591,
        -0.006791316904127598,
        0.0020519718527793884,
        0.012408917769789696,
        -0.0022692526690661907,
        0.054216060787439346,
        -0.03172007575631142,
        -0.019736314192414284,
        -0.052490025758743286,
        0.030268359929323196,
        0.05225355923175812,
        -0.0394769161939621,
        -0.03638194873929024,
        -0.0053780097514390945,
        0.045785482972860336,
        -0.0706169456243515,
        0.02765386551618576,
        -0.029295576736330986,
        0.02835751138627529,
        -0.06563850492238998,
        0.02510647661983967,
        -0.052010513842105865,
        -0.01949596032500267,
        0.04061105474829674,
        0.021324411034584045,
        -0.010562104173004627,
        -0.05594005435705185,
        0.06306849420070648,
        -0.054019998759031296,
        0.035556089133024216,
        0.06400323659181595,
        0.0208127461373806,
        0.04727797955274582,
        0.018365249037742615,
        0.0009167584939859807,
        -0.0039003605488687754,
        0.047791071236133575,
        0.0427711084485054,
        0.036485619843006134,
        0.05094457417726517,
        0.0503363236784935,
        0.02294164150953293,
        0.049981724470853806,
        0.02678920142352581,
        -0.038635700941085815,
        0.011277546174824238,
        -0.01368562038987875,
        -0.003917100839316845,
        -0.04624571651220322,
        -0.0370534211397171,
        -0.058711983263492584,
        0.0433013029396534,
        0.041164107620716095,
        0.06322471052408218,
        0.06120630353689194,
        -0.01807277463376522,
        -0.045201703906059265,
        0.06803712248802185,
        -0.03182315081357956,
        0.021106567233800888,
        -0.01493125781416893,
        0.013837110251188278,
        0.02598787657916546,
        -0.041836801916360855,
        -0.0649939477443695,
        -0.009266146458685398,
        0.040951501578092575,
        -0.04498151317238808,
        0.0016979186329990625,
        -0.04400062933564186,
        0.0680634081363678,
        -0.06690138578414917,
        0.06321437656879425,
        0.06776269525289536,
        -0.00853218138217926,
        -0.04953925684094429,
        -0.017902694642543793,
        -0.05873746797442436,
        0.0688248798251152,
        0.001405865652486682,
        -0.031232373788952827,
        0.03010156750679016,
        0.029614172875881195,
        0.055711131542921066,
        -0.0004832566773984581,
        0.061316605657339096,
        0.009332858957350254,
        0.0626545250415802,
        0.026388542726635933,
        -0.035268791019916534,
        0.07008244842290878,
        -0.055255528539419174,
        0.05692595615983009,
        0.04122759401798248,
        -0.02660682052373886,
        -0.032751817256212234,
        0.007514670956879854,
        0.04459105432033539,
        -0.057670958340168,
        0.010674172081053257,
        -0.06093079596757889,
        0.037720274180173874,
        0.05206931009888649,
        -0.037884000688791275,
        0.044083122164011,
        0.06075579300522804,
        0.06537129729986191,
        0.06373761594295502,
        -0.03944538161158562,
        0.023569295182824135,
        0.015466980636119843,
        0.037316761910915375,
        0.049126289784908295,
        -0.06743907183408737,
        0.05907626077532768,
        0.029416698962450027,
        -0.065073661506176,
        0.05504560098052025,
        0.034381184726953506,
        -0.03176598995923996,
        -0.05681364983320236,
        0.002589303068816662,
        -0.06995213776826859,
        -0.016261780634522438,
        -0.041010212153196335,
        0.05260096862912178,
        -0.03047952800989151,
        -0.04487581178545952,
        0.007410488557070494,
        0.0345594547688961,
        -0.029324302449822426,
        0.003129264572635293,
        -0.004769499413669109,
        -0.05780308321118355,
        -0.02534295991063118,
        0.06015978381037712,
        0.045111216604709625,
        -0.015500693581998348,
        -0.0673595666885376,
        0.06601642072200775,
        0.014312898740172386,
        0.038038354367017746,
        0.06030949577689171,
        0.00494220107793808,
        0.01606045849621296,
        -0.053735844790935516,
        -0.030602186918258667,
        0.03199214115738869,
        -0.05459238588809967,
        0.015439062379300594,
        0.02432848885655403,
        0.003967822063714266,
        0.06319639831781387,
        -0.044817518442869186,
        -0.018918057903647423,
        0.06019503250718117,
        -0.055610816925764084,
        -0.007085216231644154,
        -0.06197737902402878,
        0.028953850269317627,
        -0.06600646674633026,
        0.017245681956410408,
        -0.03370130434632301,
        -0.008849979378283024,
        0.04076135531067848,
        0.03940065577626228,
        -0.06613469123840332,
        0.06284491717815399,
        0.060918230563402176,
        -0.02837180532515049,
        -0.02361452206969261,
        0.041614830493927,
        -0.042839229106903076,
        0.05067189782857895,
        -0.06401868909597397,
        -0.01271379180252552,
        0.05157322809100151,
        -0.062369924038648605,
        0.032714929431676865,
        0.024009807035326958,
        -0.04406341165304184,
        0.017430514097213745,
        -0.04468444362282753,
        -0.04165574163198471,
        0.04591492563486099,
        -0.00835324078798294,
        0.06265842914581299,
        -0.05555448308587074,
        -0.03805404156446457,
        0.050382860004901886,
        -0.0359070710837841,
        -0.0006169524858705699,
        -0.00907953456044197,
        0.01238387729972601,
        -0.06762288510799408,
        0.07083127647638321,
        0.05607077106833458,
        0.04980156198143959,
        0.06492616981267929,
        -0.06993643939495087,
        -0.06826113909482956,
        -0.0037027557846158743,
        0.06742884963750839,
        -0.04622132331132889,
        -0.015216156840324402,
        -0.03746553137898445,
        -0.004110121168196201,
        -0.02806798927485943,
        -0.0648849606513977,
        -0.05010496452450752,
        -0.06026526540517807,
        -0.03951442614197731,
        -0.02754906564950943,
        0.06287159770727158,
        -0.03676014393568039,
        0.057069115340709686,
        -0.025426426902413368,
        -0.060797348618507385,
        -0.02875993214547634,
        0.05114080384373665,
        -0.031357843428850174,
        -0.07049141079187393,
        0.04749123379588127,
        0.05164048820734024,
        -0.017033616080880165,
        0.02616969682276249,
        0.06029674410820007,
        0.028086943551898003,
        -0.0587024912238121,
        -0.05447636917233467,
        0.033604178577661514,
        -0.037841249257326126,
        0.05334288254380226,
        -0.014879574067890644,
        -0.06276220083236694,
        -0.03976011648774147,
        -0.020497098565101624,
        0.056522514671087265,
        -0.06297987699508667,
        -0.06568822264671326,
        -0.026592319831252098,
        -0.007686925120651722,
        0.030107630416750908,
        0.062485042959451675,
        -0.002729384694248438,
        0.025272799655795097,
        0.020091168582439423,
        -0.051408227533102036,
        0.068605937063694,
        -0.047425899654626846,
        -0.030158886685967445,
        0.04814060404896736,
        0.03364294022321701,
        -0.044844698160886765,
        -0.060989875346422195,
        0.02015076018869877,
        -0.0604105181992054,
        0.003033301793038845,
        0.00524103082716465,
        0.02229144237935543,
        0.020295703783631325,
        0.04802238941192627,
        0.029839083552360535,
        0.03064877539873123,
        0.045916102826595306,
        0.03698023408651352,
        0.05047113075852394,
        -0.015380599536001682,
        0.04223307594656944,
        -0.05566020309925079,
        -0.000719395640771836,
        0.003703005611896515,
        0.04631313681602478,
        -0.06279881298542023,
        0.02474949322640896,
        -0.007071915548294783,
        -0.05404042452573776,
        -0.02409127540886402,
        -0.04388226568698883,
        -0.03833499178290367,
        -0.049115873873233795,
        0.007746520917862654,
        -0.0186736099421978,
        0.06906014680862427,
        0.05202736333012581,
        0.03528035804629326,
        -0.06830544024705887,
        -0.057819169014692307,
        0.03990868479013443,
        0.06057088077068329,
        0.008078869432210922,
        -0.012244132347404957,
        -0.060501597821712494,
        -0.04859160631895065,
        0.06426747888326645,
        0.05190720036625862,
        0.056490637362003326,
        -0.05414952337741852,
        -0.01567835360765457,
        -0.06719374656677246,
        -0.0510128028690815,
        0.0337245874106884,
        0.008467123843729496,
        -0.02225400134921074,
        0.05363794416189194,
        0.053485557436943054,
        -0.05614978075027466,
        -0.007737976964563131,
        -0.048694908618927,
        0.05104775354266167,
        0.0510120615363121,
        -0.061137694865465164,
        -0.020622307434678078,
        0.015495793893933296,
        0.07020995020866394,
        -0.03172538802027702,
        0.04399010166525841,
        0.06591754406690598,
        -0.06560490280389786,
        -0.006119127385318279,
        -0.05348919332027435,
        -0.014090056531131268,
        0.04534221813082695,
        -0.033242374658584595,
        0.06934013962745667,
        0.012746498920023441,
        0.012399833649396896,
        -0.0673118457198143,
        -0.009395039640367031,
        -0.0069016204215586185
    ],
    [
        -0.006580292247235775,
        0.0631863996386528,
        0.02943483553826809,
        0.022702964022755623,
        0.011142723262310028,
        0.06678933650255203,
        0.04045719653367996,
        -0.05773560702800751,
        0.051435455679893494,
        0.031210167333483696,
        0.030135711655020714,
        0.04572011157870293,
        -0.05154513195157051,
        -0.03920430690050125,
        -0.061185576021671295,
        0.05767664685845375,
        -0.06350746750831604,
        0.06667176634073257,
        -0.06461865454912186,
        -0.06962775439023972,
        -0.022378213703632355,
        0.0491180345416069,
        0.03697431832551956,
        0.015679558739066124,
        0.05712359771132469,
        0.040593016892671585,
        0.029503868892788887,
        0.0347609668970108,
        0.04771566390991211,
        -0.03769791126251221,
        0.050858233124017715,
        -0.03543751314282417,
        -0.05812980607151985,
        -0.04978619143366814,
        0.04104850813746452,
        -0.03818562999367714,
        -0.060489289462566376,
        -0.04996044933795929,
        -0.01970183104276657,
        0.06391826272010803,
        0.0602700375020504,
        0.013977359980344772,
        0.05188365280628204,
        -0.06937883794307709,
        0.06474770605564117,
        0.013625609688460827,
        -0.026996685191988945,
        0.0658307820558548,
        0.06267520785331726,
        0.0434223972260952,
        0.0016616908833384514,
        0.018912019208073616,
        0.020303739234805107,
        0.059121470898389816,
        0.010676825419068336,
        -0.028900666162371635,
        0.02766251377761364,
        0.054628822952508926,
        -0.05278914049267769,
        -0.009203501977026463,
        -0.047434572130441666,
        0.012682905420660973,
        -0.053855761885643005,
        0.06599840521812439,
        -0.05448497086763382,
        0.010346928611397743,
        0.022602247074246407,
        -0.05382430925965309,
        -0.04129483923316002,
        -0.07070115208625793,
        -0.05258818343281746,
        -0.07046890258789062,
        0.05955309420824051,
        0.062133193016052246,
        -0.06171777471899986,
        -0.017881451174616814,
        0.02706136368215084,
        0.06976689398288727,
        0.06711599975824356,
        -0.017021136358380318,
        0.030250944197177887,
        0.06722640991210938,
        -0.05224795639514923,
        0.0425029918551445,
        0.05031814053654671,
        0.039043400436639786,
        0.06030581519007683,
        0.048500899225473404,
        0.009877188131213188,
        0.060463663190603256,
        -0.0380190946161747,
        0.042337123304605484,
        -0.05198780074715614,
        -0.03499159589409828,
        -0.049089882522821426,
        0.019348375499248505,
        -0.01670212484896183,
        -0.04941414296627045,
        -0.04515909031033516,
        0.054451461881399155,
        -0.06996534019708633,
        0.00249465205706656,
        0.05805274471640587,
        -0.0215440820902586,
        -0.006627660244703293,
        -0.0667915865778923,
        -0.023997776210308075,
        0.021355697885155678,
        0.035101812332868576,
        0.005492487922310829,
        -0.06579963117837906,
        -0.0058956267312169075,
        -0.06610598415136337,
        -0.022118641063570976,
        0.0524933747947216,
        -0.04340152442455292,
        0.04446886479854584,
        -0.024242667481303215,
        0.03664802759885788,
        -0.0343380905687809,
        -0.048207931220531464,
        0.02876039408147335,
        -0.006647469010204077,
        -0.009134175255894661,
        -0.061516109853982925,
        -0.009876623749732971,
        -0.05553342029452324,
        0.05583253130316734,
        0.06674231588840485,
        -0.00475145410746336,
        0.013061000034213066,
        -0.045815613120794296,
        -0.01595987007021904,
        0.02381536364555359,
        -0.038938969373703,
        -0.05557361617684364,
        0.029570670798420906,
        -0.045469898730516434,
        -0.06521552801132202,
        0.005965756252408028,
        0.040619418025016785,
        -0.01812530681490898,
        0.06035216525197029,
        0.017381418496370316,
        0.04476802051067352,
        -0.05321723595261574,
        0.06734360754489899,
        -0.054922036826610565,
        -0.027488701045513153,
        0.0623769536614418,
        0.040573135018348694,
        -0.06691454350948334,
        -0.04956841468811035,
        -0.010051275603473186,
        -0.03311566635966301,
        -0.06604889780282974,
        0.05903084948658943,
        -0.0076680569909513,
        -0.042599089443683624,
        0.03784999996423721,
        0.07111553102731705,
        0.020547593012452126,
        0.032488979399204254,
        0.05548864230513573,
        -0.009219962172210217,
        0.023481005802750587,
        0.045913998037576675,
        -0.06377536803483963,
        -0.027949543669819832,
        0.011975887231528759,
        0.035528674721717834,
        -0.0025854872073978186,
        -0.04766690731048584,
        -0.05970211327075958,
        -0.040535956621170044,
        0.06871917098760605,
        -0.05090991407632828,
        0.008511554449796677,
        0.05573864281177521,
        -0.04367130994796753,
        0.030684519559144974,
        0.06481314450502396,
        0.059181563556194305,
        0.019779106602072716,
        0.029706761240959167,
        -0.0452914834022522,
        -0.043723344802856445,
        0.06008315086364746,
        0.01872636191546917,
        0.01941452920436859,
        -0.03774896636605263,
        -0.038563527166843414,
        0.020793946459889412,
        -0.008450367487967014,
        0.02373686619102955,
        -0.015191344544291496,
        0.06340963393449783,
        -0.06893689930438995,
        -0.02581268735229969,
        -0.02827230654656887,
        0.05017929896712303,
        0.05170701444149017,
        0.06233174353837967,
        -0.01938781887292862,
        -0.0663997232913971,
        0.012061093002557755,
        -0.0066834548488259315,
        -0.05758751556277275,
        -0.014938372187316418,
        0.052029699087142944,
        -0.06174863502383232,
        0.05254564806818962,
        -0.03092982806265354,
        0.013595222495496273,
        0.036626312881708145,
        -0.027163779363036156,
        -0.013572627678513527,
        0.03239675611257553,
        0.06138886511325836,
        -0.03412199020385742,
        0.020794762298464775,
        0.022411024197936058,
        0.014778237789869308,
        -0.06076428294181824,
        0.06933985650539398,
        -0.04320567101240158,
        -0.043501418083906174,
        0.0669575110077858,
        -0.04853852093219757,
        -0.030238991603255272,
        -0.02348208986222744,
        -0.06834767758846283,
        -0.040490973740816116,
        0.04663865268230438,
        0.005194317549467087,
        0.045487307012081146,
        0.06430938094854355,
        0.018753645941615105,
        0.04239174723625183,
        0.06868929415941238,
        -0.01940365880727768,
        0.045083798468112946,
        -0.06913384795188904,
        0.04365454986691475,
        0.022255955263972282,
        -0.006249038968235254,
        -0.06437171995639801,
        -0.011017682030797005,
        -0.005542689934372902,
        0.02868266962468624,
        -0.03200569748878479,
        -0.06724245101213455,
        -0.02827342599630356,
        -0.06860442459583282,
        0.051903415471315384,
        0.06888405978679657,
        -0.05870519578456879,
        -0.03880155459046364,
        0.05602189898490906,
        0.024794932454824448,
        0.06975854188203812,
        0.01692507602274418,
        -0.051736019551754,
        0.02057839371263981,
        -0.031738992780447006,
        0.05717282369732857,
        -0.04470915347337723,
        -0.02227325551211834,
        -0.016552865505218506,
        0.0478278249502182,
        0.04140394181013107,
        -0.06344114243984222,
        0.0575118213891983,
        -0.06793975085020065,
        -0.009344667196273804,
        0.04743749275803566,
        -0.05848248675465584,
        0.0497826412320137,
        -0.06749264150857925,
        -0.02941049635410309,
        -0.05162008851766586,
        0.0005181587766855955,
        -0.013846688903868198,
        0.05077187716960907,
        0.062196895480155945,
        -0.06887436658143997,
        0.015972813591361046,
        0.059629444032907486,
        -0.014505570754408836,
        0.049969613552093506,
        -0.035223767161369324,
        -0.015569403767585754,
        0.042217761278152466,
        0.030047256499528885,
        0.04944884404540062,
        -0.06284578889608383,
        0.06879613548517227,
        0.04450123384594917,
        -0.06780634820461273,
        0.059505313634872437,
        0.041965555399656296,
        0.03404546156525612,
        -0.054524630308151245,
        0.043970510363578796,
        -0.028878415003418922,
        -0.004362962208688259,
        -0.06838090717792511,
        0.05236907675862312,
        0.02727479301393032,
        -0.06353328377008438,
        0.03461475670337677,
        -0.053211186081171036,
        -0.025120452046394348,
        0.026914451271295547,
        -0.0427112802863121,
        -0.059603914618492126,
        -0.0007298231939785182,
        -0.05590607970952988,
        0.04681337624788284,
        -0.06147808954119682,
        -0.04883573204278946,
        0.05443029850721359,
        0.056676652282476425,
        -0.03713835030794144,
        0.06397993117570877,
        0.05024503171443939,
        0.00650972593575716,
        -0.0476602278649807,
        -0.03675305098295212,
        -0.03858211636543274,
        0.005377754103392363,
        0.05093129724264145,
        -0.021670592948794365,
        -0.03761705383658409,
        0.0603724829852581,
        0.003979996778070927,
        0.01202401053160429,
        0.06993945688009262,
        -0.0258315559476614,
        0.022936144843697548,
        -0.002239505061879754,
        0.03797662630677223,
        -0.0420646145939827,
        -0.014263289049267769,
        0.05467987060546875,
        0.03163348138332367,
        0.03330134600400925,
        0.04367702454328537,
        -0.05625230446457863,
        0.07030395418405533,
        -0.06129218637943268,
        0.04193909093737602,
        -0.02570064552128315,
        -0.009388522244989872,
        -0.06822119653224945,
        0.06765116751194,
        -0.035200104117393494,
        -0.005930689163506031,
        -0.02717498317360878,
        -0.06757157295942307,
        0.04409216344356537,
        -0.030825702473521233,
        -0.014090927317738533,
        -0.015299470163881779,
        0.025671960785984993,
        -0.042349912226200104,
        0.0643448680639267,
        -0.05062149092555046,
        0.018777301535010338,
        -0.06796552985906601,
        -0.017609963193535805,
        -0.0258598905056715,
        0.03829234093427658,
        0.009314032271504402,
        0.015923434868454933,
        0.05864531919360161,
        -0.023053627461194992,
        0.07102123647928238,
        0.004336124751716852,
        -0.04211920499801636,
        0.06144588068127632,
        -0.05021081119775772,
        -0.06896314024925232,
        0.044148270040750504,
        0.07037727534770966,
        0.007389590609818697,
        -0.025354094803333282,
        -0.05590866133570671,
        -0.009969755075871944,
        -0.03594036027789116,
        -0.06435689330101013,
        0.007229180075228214,
        0.032175611704587936,
        0.006977926939725876,
        -0.059097688645124435,
        0.06177792698144913,
        0.0610123947262764,
        0.020787939429283142,
        -0.018336361274123192,
        -0.06735312938690186,
        -0.009170753881335258,
        0.019990572705864906,
        0.03348808363080025,
        -0.053811103105545044,
        -0.04516330733895302,
        0.017338862642645836,
        -0.010971891693770885,
        0.004640216939151287,
        0.056657202541828156,
        0.010194114409387112,
        0.010520084761083126,
        -0.061953090131282806,
        0.009005488827824593,
        -0.044591791927814484,
        0.04033913463354111,
        0.011341147124767303,
        -0.01665477640926838,
        -0.0649324506521225,
        0.015650376677513123,
        0.00931266788393259,
        -0.0678526982665062,
        -0.05340003967285156,
        -0.03898383304476738,
        -0.040745388716459274,
        0.02034124545753002,
        0.021954772993922234,
        0.03537162020802498,
        -0.06196794658899307,
        -0.03812740370631218,
        -0.0586528517305851,
        0.06831996142864227,
        -0.006655740551650524,
        -0.02973118983209133,
        0.015517041087150574,
        0.04976790398359299,
        0.03167303651571274,
        -0.05907456949353218,
        -0.0048712571151554585,
        0.014442787505686283,
        -0.04823813587427139,
        0.022735420614480972,
        0.01340266689658165,
        -0.006766104605048895,
        0.05370846018195152,
        0.039984796196222305,
        0.022569211199879646,
        0.054821357131004333,
        -0.022746162489056587,
        0.06225089356303215,
        -0.03833189234137535,
        -0.0539984367787838,
        0.055489808320999146,
        0.012034515850245953,
        0.06011104956269264,
        0.002953402465209365,
        -0.05771830305457115,
        0.047882456332445145,
        0.026047974824905396,
        -0.04453938081860542,
        -0.028989296406507492,
        -0.012444376945495605,
        -0.061110254377126694,
        -0.05318538844585419,
        -0.06015295162796974,
        0.028945008292794228,
        0.06905002892017365,
        -0.06373246759176254,
        0.04551555588841438,
        -0.0667560026049614,
        -0.06675498187541962,
        0.024506179615855217,
        0.01455710455775261,
        -0.028878958895802498,
        -0.02289092354476452,
        -0.03605170547962189,
        -0.04375024139881134,
        0.058062244206666946,
        -0.03513891249895096,
        -0.0015337479999288917,
        -0.03439199924468994,
        0.049766890704631805,
        -0.05900787562131882,
        -0.018436690792441368,
        0.05049508437514305,
        0.05225028842687607,
        0.020251663401722908,
        0.06597086042165756,
        0.033978868275880814,
        -0.05313486605882645,
        -0.014521079137921333,
        -0.030094029381871223,
        -0.03187524899840355,
        0.018737655133008957,
        -0.025112107396125793,
        0.046217940747737885,
        -0.008374086581170559,
        -0.050349775701761246,
        -0.00232122209854424,
        0.05676467716693878,
        0.05120746046304703,
        -0.007110023871064186,
        -0.024287337437272072,
        -0.0512845516204834,
        0.03755665197968483,
        0.024698549881577492,
        0.022159915417432785,
        0.060892991721630096,
        0.03781752660870552,
        -0.04579264670610428,
        -0.0061712078750133514,
        0.06383438408374786,
        0.06327533721923828
    ],
    [
        -0.017213745042681694,
        0.0407990999519825,
        0.06413824111223221,
        0.024303743615746498,
        -0.042268868535757065,
        0.06835582852363586,
        -0.015972282737493515,
        -0.06787306815385818,
        0.06771727651357651,
        -0.0309857577085495,
        0.03682873770594597,
        -0.05061553791165352,
        -0.010170146822929382,
        -0.05136910825967789,
        -0.056511521339416504,
        0.06727283447980881,
        -0.01091873086988926,
        0.0675300657749176,
        0.011759385466575623,
        -0.06961487233638763,
        0.04807555302977562,
        0.03771618753671646,
        0.03500952944159508,
        -0.04454895853996277,
        0.06657148152589798,
        0.05535771697759628,
        0.06011495366692543,
        0.04585110396146774,
        0.005407758988440037,
        0.03339428827166557,
        0.008059412240982056,
        -0.0300514604896307,
        -0.015407291240990162,
        -0.06475478410720825,
        0.058767978101968765,
        0.00607153819873929,
        -0.059202566742897034,
        -0.016443995758891106,
        -0.015391548164188862,
        0.010807556100189686,
        0.06658641993999481,
        0.020289041101932526,
        0.05395041033625603,
        -0.05148732662200928,
        0.06915730237960815,
        0.05437733605504036,
        -0.04060499742627144,
        0.060945648699998856,
        0.006666856352239847,
        0.03509632498025894,
        0.04091101512312889,
        0.06364966928958893,
        -0.06659204512834549,
        0.06355767697095871,
        0.007578710094094276,
        0.04194919019937515,
        -0.0008237684960477054,
        0.05678912624716759,
        0.0032929163426160812,
        -0.02764604613184929,
        -0.06315822154283524,
        -0.02993294782936573,
        -0.03649114444851875,
        0.06980273872613907,
        -0.06542034447193146,
        -0.06328880786895752,
        0.04432327300310135,
        -0.05534478649497032,
        -0.05701133608818054,
        0.028537992388010025,
        -0.030635444447398186,
        -0.06635896861553192,
        0.05728961527347565,
        0.05929876118898392,
        0.0490991435945034,
        -0.048608921468257904,
        -0.004656792618334293,
        0.06962256878614426,
        0.049613531678915024,
        -0.006048689596354961,
        -0.002444186247885227,
        0.06329872459173203,
        -0.043896403163671494,
        0.024487195536494255,
        0.03776419162750244,
        0.05229238048195839,
        0.06983562558889389,
        0.0648052990436554,
        0.03133150190114975,
        0.015675636008381844,
        -0.02622321993112564,
        -0.009969680570065975,
        -0.0692860558629036,
        0.010620438493788242,
        -0.054424434900283813,
        0.05505776405334473,
        -0.047986727207899094,
        -0.05626774951815605,
        -0.03632849082350731,
        -0.04633304104208946,
        -0.06485181301832199,
        -0.014434611424803734,
        0.042944785207509995,
        -0.0501234233379364,
        0.010569174773991108,
        -0.0642906203866005,
        -0.051360879093408585,
        0.022318443283438683,
        0.053260959684848785,
        0.04058670252561569,
        -0.054760999977588654,
        0.06539592891931534,
        -0.05600433051586151,
        0.002175938570871949,
        -0.03505568951368332,
        -0.033972207456827164,
        -0.04413561150431633,
        -0.039073482155799866,
        0.07003702223300934,
        -0.01629110984504223,
        0.004416326060891151,
        0.05208882689476013,
        0.04806773364543915,
        -0.06272096931934357,
        -0.04311583936214447,
        0.028413191437721252,
        -0.06605728715658188,
        -0.03416069597005844,
        0.06676074117422104,
        -0.031799763441085815,
        -0.04132631793618202,
        -0.06056823581457138,
        -0.0034103423822671175,
        0.027414919808506966,
        -0.05304930731654167,
        -0.06288478523492813,
        0.060027651488780975,
        0.018516521900892258,
        -0.06881433725357056,
        0.017014389857649803,
        0.01163589395582676,
        -0.0032900322694331408,
        0.06470725685358047,
        0.029056094586849213,
        0.019908765330910683,
        -0.05897489935159683,
        0.06143386662006378,
        -0.05481189861893654,
        -0.035875819623470306,
        0.02642012946307659,
        0.05733828991651535,
        -0.05517540127038956,
        0.0425732396543026,
        -0.04969039931893349,
        -0.024410812184214592,
        -0.04243098944425583,
        -0.06480225175619125,
        -0.017021747305989265,
        0.021595437079668045,
        0.01556396670639515,
        0.06824048608541489,
        0.01815803535282612,
        0.06175500527024269,
        0.023336706683039665,
        -0.04205403849482536,
        -0.0221627876162529,
        -0.006467456929385662,
        0.003268752247095108,
        -0.016461744904518127,
        0.008400920778512955,
        0.03534293919801712,
        0.04650535807013512,
        0.05122007802128792,
        -0.06804322451353073,
        0.06560634821653366,
        0.053384799510240555,
        0.021070770919322968,
        0.00034307275200262666,
        0.055399470031261444,
        -0.036597490310668945,
        -0.0022984049282968044,
        0.06865104287862778,
        0.010183755308389664,
        0.00837587472051382,
        0.019866371527314186,
        -0.012685291469097137,
        -0.023436300456523895,
        0.05597427859902382,
        -0.05571193993091583,
        0.005157404113560915,
        -0.0007337044808082283,
        -0.020643772557377815,
        0.03956053778529167,
        -0.0006777946837246418,
        -0.007799715735018253,
        -0.01111533585935831,
        0.05648750439286232,
        -0.06892863661050797,
        -0.030609333887696266,
        -0.012044021859765053,
        0.04352280870079994,
        -0.03129780665040016,
        0.051859769970178604,
        -0.032117556780576706,
        -0.06417309492826462,
        -0.05599286034703255,
        0.049105074256658554,
        0.020844662562012672,
        -0.02853066846728325,
        0.03711903840303421,
        -0.05468905344605446,
        0.06485232710838318,
        0.029062313959002495,
        0.049754705280065536,
        0.05584978684782982,
        0.04848471283912659,
        -0.023728396743535995,
        0.03554694354534149,
        0.057951346039772034,
        -0.04981015622615814,
        0.01749393530189991,
        0.06788697093725204,
        0.053374405950307846,
        0.04669371619820595,
        0.06492505222558975,
        0.026228712871670723,
        -0.05827988311648369,
        0.059038132429122925,
        -0.026876740157604218,
        -0.012164879590272903,
        -0.051835108548402786,
        -0.051612284034490585,
        -0.02166474424302578,
        0.039041657000780106,
        -0.0065790265798568726,
        0.06143053248524666,
        0.05985476076602936,
        -0.013556908816099167,
        -0.03787772357463837,
        0.06459388136863708,
        -0.053875211626291275,
        0.01564595475792885,
        -0.06944946199655533,
        0.0496382899582386,
        0.01580064557492733,
        0.004392135422676802,
        -0.07004271447658539,
        0.002490261336788535,
        0.02067788876593113,
        0.004528568126261234,
        0.03018188290297985,
        -0.04820028319954872,
        -0.04176666587591171,
        -0.05910830199718475,
        -0.038901522755622864,
        0.06048376113176346,
        -0.05306033045053482,
        -0.013651101849973202,
        0.02181396260857582,
        -0.03883116692304611,
        0.06790798902511597,
        -0.06457800418138504,
        -0.054876912385225296,
        -0.004989795386791229,
        0.01742471382021904,
        0.04869898036122322,
        -0.03574935719370842,
        -0.04493692144751549,
        -0.03612219914793968,
        0.018261704593896866,
        0.057552777230739594,
        -0.0615299828350544,
        0.06807570159435272,
        -0.06319798529148102,
        -0.05252203345298767,
        0.0687413290143013,
        -0.03272748365998268,
        0.016924237832427025,
        -0.04066608101129532,
        -0.018414536491036415,
        -0.05118120089173317,
        0.02703508920967579,
        -0.04868944734334946,
        0.06514862924814224,
        0.03669498488306999,
        -0.020167633891105652,
        0.03897903487086296,
        0.05663146823644638,
        0.03591421991586685,
        0.06035150587558746,
        -0.05454004183411598,
        -0.05821595340967178,
        -0.006879530381411314,
        0.04105767235159874,
        0.05494163557887077,
        -0.06323213130235672,
        0.06658519059419632,
        0.04985920712351799,
        -0.06270323693752289,
        0.05656467750668526,
        0.03602570667862892,
        0.04257848113775253,
        -0.05463549494743347,
        0.04191097617149353,
        -0.0645146369934082,
        -0.009948167949914932,
        0.007210953626781702,
        0.03307926282286644,
        0.014365297742187977,
        -0.01697278767824173,
        -0.047308214008808136,
        0.011943030171096325,
        -0.04726073890924454,
        -0.008177363313734531,
        -0.016815735027194023,
        -0.060872115194797516,
        -0.005786682479083538,
        -0.03457065299153328,
        0.016947418451309204,
        0.03920428082346916,
        0.028317725285887718,
        -0.020647535100579262,
        -0.05063767731189728,
        -0.0433320552110672,
        0.055548932403326035,
        -0.006313285790383816,
        -0.025872886180877686,
        -0.010598599910736084,
        0.04576202854514122,
        0.020999779924750328,
        -0.015499561093747616,
        0.011561799794435501,
        -0.06621387600898743,
        -0.037199851125478745,
        0.06647296249866486,
        -0.04319196194410324,
        0.05353621020913124,
        0.059017349034547806,
        -0.0441109798848629,
        0.05709151178598404,
        -0.033115483820438385,
        0.03037944994866848,
        -0.05134660378098488,
        -0.05965184047818184,
        -0.06553985178470612,
        0.04104919731616974,
        0.02778678573668003,
        0.049202412366867065,
        -0.033194296061992645,
        0.049320969730615616,
        0.04944637045264244,
        -0.05444306135177612,
        -0.029625078663229942,
        0.056223396211862564,
        0.018494972959160805,
        0.06284942477941513,
        -0.02316243387758732,
        -0.026471201330423355,
        0.043394751846790314,
        -0.05899347737431526,
        0.04441212862730026,
        -0.03988093137741089,
        0.04290938004851341,
        -0.029154978692531586,
        -0.03518811613321304,
        -0.040525004267692566,
        0.035942189395427704,
        -0.04402347281575203,
        0.009062209166586399,
        -0.06650400161743164,
        -0.026339879259467125,
        0.05128013342618942,
        0.043641347438097,
        0.037006035447120667,
        -0.044293999671936035,
        -0.037514809519052505,
        -0.01726788654923439,
        0.07031363993883133,
        0.052974771708250046,
        0.04595049470663071,
        0.015393935143947601,
        -0.05937843397259712,
        -0.06854548305273056,
        0.024718550965189934,
        0.06109687685966492,
        -0.0049566421657800674,
        -0.010950574651360512,
        -0.0555817112326622,
        -0.04024720564484596,
        -0.00782287772744894,
        -0.06989526748657227,
        0.03650274500250816,
        -0.06259941309690475,
        -0.04468405246734619,
        -0.005724094342440367,
        0.05631954222917557,
        0.029345490038394928,
        0.02185458317399025,
        0.018955737352371216,
        -0.03951311856508255,
        -0.046054907143116,
        0.05602867528796196,
        0.023624634370207787,
        -0.06989898532629013,
        -0.036628369241952896,
        0.011662885546684265,
        -0.020433273166418076,
        -0.013019287958741188,
        0.06477639079093933,
        -0.021808866411447525,
        -0.04524339735507965,
        -0.05640074238181114,
        0.0590096153318882,
        -0.06185422092676163,
        0.05809181556105614,
        0.009205594658851624,
        -0.011448923498392105,
        -0.06537343561649323,
        0.024647798389196396,
        -0.04273448511958122,
        -0.011247438378632069,
        -0.06235708296298981,
        -0.0016833886038511992,
        -0.04917144775390625,
        0.03545727580785751,
        0.06717640161514282,
        0.0064619011245667934,
        -0.056660961359739304,
        -0.02813161537051201,
        -0.04671196639537811,
        0.058226969093084335,
        -0.013796718791127205,
        -0.06459270417690277,
        0.01841018907725811,
        0.032238513231277466,
        -0.020629897713661194,
        -0.04580705240368843,
        -0.047445472329854965,
        -0.0437905415892601,
        0.00001800065911083948,
        0.031037170439958572,
        0.0013976369518786669,
        0.03238999471068382,
        0.04875252768397331,
        0.06843037903308868,
        0.002940493170171976,
        0.062242958694696426,
        0.0244460366666317,
        0.05557321757078171,
        0.011215695179998875,
        -0.041725367307662964,
        -0.05185961350798607,
        0.010300141759216785,
        0.061895038932561874,
        0.018620524555444717,
        -0.06550469994544983,
        0.03127153590321541,
        0.025426769629120827,
        0.039289433509111404,
        -0.028462406247854233,
        -0.02913588471710682,
        -0.05877853184938431,
        0.0039041955024003983,
        -0.048641007393598557,
        -0.04772871360182762,
        0.06460385024547577,
        0.031690564006567,
        0.026486871764063835,
        -0.06448347866535187,
        -0.04576278477907181,
        -0.020398184657096863,
        0.05088312178850174,
        0.01321400050073862,
        -0.04485476762056351,
        -0.06894003599882126,
        -0.03644399717450142,
        0.04495256766676903,
        -0.04292755573987961,
        0.048324909061193466,
        -0.04486636817455292,
        0.024542011320590973,
        -0.06839136779308319,
        -0.04332415759563446,
        0.03818949684500694,
        0.008553648367524147,
        0.028464438393712044,
        0.018093178048729897,
        0.036319512873888016,
        -0.057470135390758514,
        -0.05206285044550896,
        -0.03194170072674751,
        0.0336567759513855,
        0.05062973126769066,
        -0.06581816077232361,
        0.010088952258229256,
        -0.004866622854024172,
        0.04765108972787857,
        0.05637571960687637,
        0.04846113920211792,
        0.06889968365430832,
        -0.027821088209748268,
        0.004993224050849676,
        -0.04622216895222664,
        -0.003666327567771077,
        -0.06020153686404228,
        -0.027586422860622406,
        0.06624948233366013,
        0.024103770032525063,
        -0.04587651789188385,
        0.0007902790675871074,
        -0.04493381083011627,
        -0.030916381627321243
    ],
    [
        -0.015833012759685516,
        0.05436248704791069,
        0.0585351437330246,
        0.05280774459242821,
        -0.06036205589771271,
        0.07190648466348648,
        -0.04781065136194229,
        -0.06423347443342209,
        0.07023148238658905,
        0.01100020669400692,
        -0.0005837574135512114,
        -0.04348748177289963,
        -0.004868977703154087,
        -0.03400133177638054,
        -0.056697484105825424,
        0.06825625896453857,
        -0.016495762392878532,
        0.07299532741308212,
        -0.023660266771912575,
        -0.07454671710729599,
        0.013111491687595844,
        0.06424263119697571,
        -0.02509477362036705,
        -0.04670640453696251,
        0.055385176092386246,
        0.038267847150564194,
        0.023741068318486214,
        -0.03960128128528595,
        0.035037558525800705,
        0.0430833138525486,
        0.0046044401824474335,
        -0.0486486554145813,
        -0.03616217523813248,
        -0.059903714805841446,
        0.05731863155961037,
        0.0264197438955307,
        -0.06313906610012054,
        -0.025202631950378418,
        0.03036225587129593,
        0.02184750698506832,
        0.058868277817964554,
        0.02364903874695301,
        0.03462791442871094,
        -0.06775237619876862,
        0.050437457859516144,
        0.05347159877419472,
        -0.04838955029845238,
        0.03911617770791054,
        0.024944443255662918,
        0.05132383108139038,
        0.009091023355722427,
        0.0713372603058815,
        -0.0541161485016346,
        0.0655551552772522,
        0.0023538083769381046,
        -0.03856605663895607,
        0.05922159180045128,
        0.05245613679289818,
        -0.021411769092082977,
        -0.01377528440207243,
        -0.06296799331903458,
        -0.005293028894811869,
        -0.04684947058558464,
        0.0712599903345108,
        -0.06405522674322128,
        -0.058570053428411484,
        0.040262337774038315,
        -0.006321498658508062,
        -0.06900959461927414,
        0.02695750817656517,
        -0.051794156432151794,
        -0.07281758636236191,
        0.0526667982339859,
        0.053550172597169876,
        -0.04744874686002731,
        -0.041456326842308044,
        -0.0216217041015625,
        0.0743226408958435,
        0.05172378569841385,
        -0.008100416511297226,
        0.027990762144327164,
        0.06967815011739731,
        -0.055488236248493195,
        0.05119752883911133,
        0.041897524148225784,
        0.049077145755290985,
        0.07361753284931183,
        0.0687222108244896,
        0.044766828417778015,
        0.058161430060863495,
        0.027343764901161194,
        -0.0626082494854927,
        -0.07186353206634521,
        -0.029463903978466988,
        -0.058050088584423065,
        0.026574214920401573,
        -0.010739507153630257,
        -0.05032496526837349,
        0.028497179970145226,
        0.029659390449523926,
        -0.06974242627620697,
        -0.005294590722769499,
        0.005045775324106216,
        -0.04603515937924385,
        0.038114458322525024,
        -0.0682770237326622,
        -0.011727539822459221,
        0.03984079137444496,
        0.06119785085320473,
        0.034572452306747437,
        -0.06439093500375748,
        0.022812265902757645,
        -0.07062471657991409,
        0.015999576076865196,
        -0.022856445983052254,
        -0.010157372802495956,
        0.020958315581083298,
        -0.02022828906774521,
        0.07196908444166183,
        0.017966659739613533,
        -0.04596515744924545,
        0.062259916216135025,
        0.03374093398451805,
        -0.023205747827887535,
        -0.039272189140319824,
        -0.04701654985547066,
        -0.04684172198176384,
        -0.009446931071579456,
        0.06162077188491821,
        -0.00283863116055727,
        -0.02986382320523262,
        -0.050008375197649,
        -0.03882591053843498,
        -0.011885102838277817,
        -0.04105503112077713,
        -0.05834342539310455,
        0.05566948652267456,
        0.03629237040877342,
        -0.06686469912528992,
        -0.040602996945381165,
        0.05593438073992729,
        -0.007330430671572685,
        0.06859543174505234,
        0.009769692085683346,
        0.057107917964458466,
        -0.06554491817951202,
        0.05775255709886551,
        -0.032835379242897034,
        0.030850231647491455,
        0.04987179487943649,
        0.06221297010779381,
        -0.0655248686671257,
        -0.0014433804899454117,
        -0.0654752105474472,
        0.054723095148801804,
        -0.040076687932014465,
        -0.019934270530939102,
        0.04975175857543945,
        -0.009154089726507664,
        0.0535714328289032,
        0.07271592319011688,
        0.03986545279622078,
        0.04956084117293358,
        0.039437469094991684,
        0.03929174318909645,
        0.026874564588069916,
        -0.026824353262782097,
        0.019486894831061363,
        -0.012436427175998688,
        -0.012012869119644165,
        0.055766019970178604,
        0.02692839503288269,
        0.028073415160179138,
        -0.06325224041938782,
        0.01746046543121338,
        0.05943037196993828,
        -0.05466057360172272,
        -0.02706257626414299,
        0.04449053853750229,
        -0.050871044397354126,
        0.039269208908081055,
        0.07287663966417313,
        0.032646726816892624,
        0.019430719316005707,
        0.003792576724663377,
        0.01724574901163578,
        -0.046594150364398956,
        0.05166630074381828,
        -0.012438240461051464,
        -0.003943450283259153,
        -0.01809110678732395,
        -0.0012762682745233178,
        -0.0029114652425050735,
        -0.030537649989128113,
        0.04966263845562935,
        0.04473421350121498,
        -0.010055704042315483,
        -0.06872136890888214,
        0.01985142007470131,
        0.0013610281748697162,
        0.04828291013836861,
        -0.048588886857032776,
        0.027063308283686638,
        -0.033672455698251724,
        -0.06838779896497726,
        0.027188941836357117,
        0.05210128799080849,
        0.009622654877603054,
        -0.01726721040904522,
        0.06814655661582947,
        -0.04944803565740585,
        0.05085396394133568,
        0.005816017277538776,
        0.042736757546663284,
        -0.009513447061181068,
        0.016304003074765205,
        -0.006238702218979597,
        -0.024219246581196785,
        0.0556345134973526,
        -0.001159472274594009,
        0.024541715160012245,
        0.06440345197916031,
        0.025561224669218063,
        0.012631544843316078,
        0.060290392488241196,
        0.03258960694074631,
        -0.025169238448143005,
        0.00538763590157032,
        -0.05643137916922569,
        -0.0030428871978074312,
        -0.020036868751049042,
        -0.059041280299425125,
        -0.04030953347682953,
        0.016202859580516815,
        0.00020300048345234245,
        0.03126300498843193,
        0.06417615711688995,
        -0.060350202023983,
        0.022476801648736,
        0.06672398000955582,
        -0.05662925913929939,
        0.060269735753536224,
        -0.06816509366035461,
        0.010408296249806881,
        0.02856970764696598,
        -0.008689681999385357,
        -0.07321159541606903,
        -0.03489864617586136,
        0.031235290691256523,
        0.0029044579714536667,
        -0.04017794504761696,
        -0.06204613298177719,
        0.01570575311779976,
        -0.06624141335487366,
        -0.013026812113821507,
        0.06434544175863266,
        -0.03779399394989014,
        -0.06187741458415985,
        -0.016531093046069145,
        -0.0062456801533699036,
        0.07289396971464157,
        -0.048230867832899094,
        -0.04539458081126213,
        0.008810454048216343,
        0.034243352711200714,
        0.044890958815813065,
        -0.042551301419734955,
        0.03807389363646507,
        -0.02163449116051197,
        0.04686320573091507,
        0.058487292379140854,
        -0.03898116946220398,
        0.07160893827676773,
        -0.05086547136306763,
        -0.03766056150197983,
        0.06141851097345352,
        -0.028003185987472534,
        0.011713648214936256,
        -0.04771263897418976,
        -0.01809816248714924,
        -0.042954105883836746,
        0.02111811377108097,
        -0.07141123712062836,
        0.05134267359972,
        0.03544062376022339,
        -0.05244581401348114,
        0.04120923951268196,
        0.0702744647860527,
        0.024177033454179764,
        0.05639233812689781,
        -0.024720430374145508,
        0.031340498477220535,
        0.032418638467788696,
        0.05267666280269623,
        0.060806963592767715,
        -0.06882325559854507,
        0.05877585709095001,
        0.059352561831474304,
        -0.06813932210206985,
        0.05164851248264313,
        0.06816209107637405,
        -0.011191383004188538,
        -0.05972478911280632,
        -0.010957746766507626,
        -0.05808849632740021,
        0.014340216293931007,
        -0.06948468089103699,
        0.03584401309490204,
        0.028877971693873405,
        -0.026316367089748383,
        0.002849987242370844,
        -0.008910022675991058,
        -0.02937256172299385,
        0.01391642913222313,
        0.03527676686644554,
        -0.05336830019950867,
        -0.02534933015704155,
        -0.05591166764497757,
        0.010211740620434284,
        -0.03884511813521385,
        -0.026794929057359695,
        0.0169148501008749,
        -0.012133934535086155,
        0.038322992622852325,
        0.05822223424911499,
        -0.016506744548678398,
        -0.03363639488816261,
        0.001567676430568099,
        0.022995952516794205,
        -0.015697535127401352,
        0.018616031855344772,
        0.0023908489383757114,
        -0.06000182032585144,
        -0.05454346537590027,
        0.060010477900505066,
        -0.013749727979302406,
        -0.009940759278833866,
        0.061761777848005295,
        -0.030934203416109085,
        0.05361536517739296,
        0.05784429609775543,
        0.06082147732377052,
        -0.07062222808599472,
        -0.023226546123623848,
        -0.048586152493953705,
        0.01807894930243492,
        0.017928363755345345,
        0.0460352823138237,
        -0.0651388093829155,
        0.06878900527954102,
        0.05537426099181175,
        -0.01836339756846428,
        -0.02693478763103485,
        0.05116036534309387,
        -0.025200821459293365,
        0.06596226990222931,
        -0.05733628571033478,
        0.009306124411523342,
        0.057598941028118134,
        -0.0655834823846817,
        0.04265264421701431,
        -0.03549155592918396,
        0.009185820817947388,
        -0.004026027396321297,
        0.007462385576218367,
        -0.026149773970246315,
        -0.0005264405044727027,
        -0.021085236221551895,
        -0.002281861612573266,
        -0.06490327417850494,
        -0.0449870228767395,
        0.05496012046933174,
        0.038093190640211105,
        0.03182288259267807,
        -0.044396061450242996,
        -0.0003937982255592942,
        -0.06678829342126846,
        0.07444535195827484,
        0.06223093718290329,
        0.04001308232545853,
        0.06572326272726059,
        -0.07368070632219315,
        -0.07212108373641968,
        0.05328560248017311,
        0.06559385359287262,
        -0.01632964424788952,
        -0.03296349570155144,
        -0.0614139586687088,
        -0.04781275615096092,
        -0.03713866323232651,
        -0.06865926086902618,
        -0.01510692946612835,
        0.008032781071960926,
        0.01552375964820385,
        -0.018451856449246407,
        0.06611321866512299,
        0.03740260377526283,
        0.05148213356733322,
        0.0038207832258194685,
        -0.06014056131243706,
        0.007121093105524778,
        0.03347582742571831,
        0.0336332805454731,
        -0.07394231110811234,
        -0.030808456242084503,
        0.03483209013938904,
        -0.05232296884059906,
        -0.009961046278476715,
        0.06766841560602188,
        -0.022136935964226723,
        -0.04772068187594414,
        -0.061023592948913574,
        0.05862899124622345,
        -0.047026246786117554,
        0.05147336423397064,
        -0.004917341750115156,
        -0.02584332413971424,
        -0.04347366839647293,
        0.027332013472914696,
        0.04037300869822502,
        -0.03046039491891861,
        -0.02934124879539013,
        0.0027864270377904177,
        0.008509192615747452,
        0.0156428050249815,
        0.04419506713747978,
        0.010641111992299557,
        -0.042993370443582535,
        0.03173532709479332,
        -0.041581977158784866,
        0.06590508669614792,
        -0.016002323478460312,
        -0.062136825174093246,
        -0.00983415450900793,
        0.03103426843881607,
        -0.03190109133720398,
        -0.043893977999687195,
        -0.040871139615774155,
        -0.038582753390073776,
        -0.004959906917065382,
        0.03979378193616867,
        0.007715978659689426,
        0.021019184961915016,
        0.030168773606419563,
        0.03889624401926994,
        0.011432747356593609,
        0.062904953956604,
        0.048530127853155136,
        0.06075088679790497,
        0.02819087542593479,
        -0.06380214542150497,
        -0.046928372234106064,
        -0.038703784346580505,
        -0.0097982631996274,
        0.013631051406264305,
        -0.0715484693646431,
        0.051031310111284256,
        0.021802891045808792,
        -0.028739159926772118,
        -0.029110243543982506,
        -0.0452580526471138,
        -0.015153717249631882,
        -0.05967458337545395,
        0.04683578759431839,
        -0.046589214354753494,
        0.07029947638511658,
        -0.06670141220092773,
        -0.00961362849920988,
        -0.06826850771903992,
        -0.04273449629545212,
        0.02317742444574833,
        0.057627178728580475,
        0.009156757965683937,
        -0.03066982515156269,
        -0.06997134536504745,
        -0.03902400657534599,
        0.06534562259912491,
        0.016515767201781273,
        -0.008127272129058838,
        -0.055700186640024185,
        -0.05478062108159065,
        -0.06226493790745735,
        -0.04940560460090637,
        0.024637727066874504,
        0.06841687113046646,
        -0.00324557488784194,
        0.017015395686030388,
        0.040938664227724075,
        -0.05664840340614319,
        0.004617774393409491,
        -0.06587731838226318,
        0.028602492064237595,
        0.004415459465235472,
        -0.02464226447045803,
        -0.03177027404308319,
        0.012201007455587387,
        0.045715268701314926,
        0.01691601239144802,
        0.04793212190270424,
        0.05635085329413414,
        -0.06036831811070442,
        -0.029546521604061127,
        -0.05792224779725075,
        0.020797977223992348,
        -0.008149604313075542,
        -0.04228907823562622,
        0.07243427634239197,
        0.025156483054161072,
        -0.061365604400634766,
        -0.0639989823102951,
        0.0526978075504303,
        0.0008442531689070165
    ],
    [
        -0.0419582836329937,
        0.061089396476745605,
        0.05024319142103195,
        0.05187200382351875,
        -0.06129908561706543,
        0.07158311456441879,
        -0.036282069981098175,
        -0.05894406884908676,
        0.06773734092712402,
        -0.0023338214959949255,
        0.03657737746834755,
        -0.03502241522073746,
        -0.01732795126736164,
        -0.042125154286623,
        -0.06276271492242813,
        0.06446932256221771,
        -0.040446534752845764,
        0.07029878348112106,
        -0.011237507686018944,
        -0.07174407690763474,
        0.0412132553756237,
        0.05786454305052757,
        0.00025603771791793406,
        0.015700623393058777,
        0.05189503729343414,
        0.05403092876076698,
        0.000620107282884419,
        0.011178855784237385,
        0.0027548130601644516,
        0.01526369247585535,
        0.043689534068107605,
        -0.03660803288221359,
        -0.011403256095945835,
        -0.056455325335264206,
        0.06183338910341263,
        0.03763765096664429,
        -0.0658486932516098,
        -0.0009961515897884965,
        0.0502576120197773,
        0.030388886108994484,
        0.0651594027876854,
        0.03365856781601906,
        0.039040908217430115,
        -0.06758085638284683,
        0.06987792253494263,
        0.03604407608509064,
        -0.04944209009408951,
        0.060550887137651443,
        0.03443490341305733,
        0.033244531601667404,
        0.03371765464544296,
        0.06733388453722,
        -0.047188472002744675,
        0.0611310675740242,
        0.023322410881519318,
        -0.002809436060488224,
        0.05664495751261711,
        0.06602504849433899,
        -0.05957069247961044,
        -0.03711404278874397,
        -0.059565793722867966,
        -0.008668403141200542,
        -0.05145552381873131,
        0.06604359298944473,
        -0.06820572167634964,
        -0.050613269209861755,
        0.008385416120290756,
        -0.030180716887116432,
        -0.0642140731215477,
        0.014246854931116104,
        -0.041257839649915695,
        -0.06974036246538162,
        0.062180571258068085,
        0.06425482779741287,
        -0.026531396433711052,
        -0.043396808207035065,
        -0.04387078806757927,
        0.07100841403007507,
        0.07038706541061401,
        -0.01641985960304737,
        0.016604764387011528,
        0.06147497147321701,
        -0.06208140403032303,
        0.03348002955317497,
        0.03471260145306587,
        0.05108252912759781,
        0.06886619329452515,
        0.06992003321647644,
        0.02673804946243763,
        -0.0035224882885813713,
        0.007901246659457684,
        -0.034137140959501266,
        -0.06634219735860825,
        -0.010087652131915092,
        -0.05481336638331413,
        0.0360184870660305,
        -0.013117778114974499,
        -0.06170797720551491,
        0.02338716946542263,
        0.04701792821288109,
        -0.056788284331560135,
        0.007611725013703108,
        0.025230661034584045,
        -0.04246370866894722,
        0.00524589978158474,
        -0.06548699736595154,
        -0.05345810949802399,
        0.03040938451886177,
        0.048089399933815,
        0.02388881891965866,
        -0.0644662082195282,
        0.0004019352491013706,
        -0.06705936044454575,
        0.02705010399222374,
        -0.01642623357474804,
        -0.045634444802999496,
        0.021786296740174294,
        0.006744534708559513,
        0.06520381569862366,
        -0.010215191170573235,
        -0.047479577362537384,
        0.0368679016828537,
        0.04245953634381294,
        -0.016107227653265,
        -0.04520842060446739,
        -0.0050684669986367226,
        -0.06056470796465874,
        -0.01596861332654953,
        0.06220107898116112,
        -0.0010517368791624904,
        -0.04656018689274788,
        -0.048390697687864304,
        -0.029982885345816612,
        0.007065997924655676,
        -0.04552844166755676,
        -0.05668175220489502,
        0.044753801077604294,
        0.007824542932212353,
        -0.06371062994003296,
        -0.04145432636141777,
        0.03725754842162132,
        -0.023096201941370964,
        0.06710637360811234,
        0.011015504598617554,
        0.045983873307704926,
        -0.06993212550878525,
        0.04502428323030472,
        -0.04558761045336723,
        -0.019265050068497658,
        0.022346310317516327,
        0.054783228784799576,
        -0.05997277796268463,
        -0.005263191647827625,
        -0.0631667897105217,
        0.047015901654958725,
        -0.05001786723732948,
        -0.008713538758456707,
        0.04321626201272011,
        0.001099897432141006,
        0.04182478040456772,
        0.0714864656329155,
        0.02067599445581436,
        0.05737444385886192,
        0.05692165344953537,
        0.014654695056378841,
        0.02037707529962063,
        -0.0003861428122036159,
        0.01055257860571146,
        -0.014945512637495995,
        -0.007507121190428734,
        0.028297118842601776,
        0.02990531362593174,
        -0.028130054473876953,
        -0.05680422484874725,
        0.0023108532186597586,
        0.05575969070196152,
        -0.023984892293810844,
        -0.02514304593205452,
        0.05663266032934189,
        -0.04876613989472389,
        0.01621292531490326,
        0.07030107825994492,
        0.019080158323049545,
        0.008957757614552975,
        0.02186317928135395,
        -0.029941463842988014,
        -0.012287522666156292,
        0.05583944916725159,
        0.006209091283380985,
        0.01231419574469328,
        -0.047147683799266815,
        -0.0012466260232031345,
        0.036570146679878235,
        -0.019493622705340385,
        -0.012206969782710075,
        0.0511518158018589,
        0.05199824273586273,
        -0.07068914920091629,
        0.032790277153253555,
        -0.027390623465180397,
        0.05892873927950859,
        -0.05654395744204521,
        0.05108240619301796,
        -0.04575216770172119,
        -0.06199103966355324,
        -0.008921443484723568,
        0.04036558046936989,
        -0.0148466806858778,
        -0.020946336910128593,
        0.06527861952781677,
        -0.061854664236307144,
        0.05038391053676605,
        -0.011682325974106789,
        0.041997842490673065,
        -0.007815403863787651,
        0.03174642100930214,
        0.001229657675139606,
        -0.02789708785712719,
        0.050599049776792526,
        0.009644552133977413,
        0.04842616245150566,
        0.062649205327034,
        0.04060309752821922,
        -0.004265467636287212,
        0.06347742676734924,
        0.004561536014080048,
        -0.031053265556693077,
        0.03330494463443756,
        -0.046285346150398254,
        -0.052235234528779984,
        -0.04406391829252243,
        -0.044660456478595734,
        -0.04658469557762146,
        0.0335865244269371,
        0.011358329094946384,
        0.05740620568394661,
        0.06409920752048492,
        -0.05043347552418709,
        0.011866689659655094,
        0.0674552470445633,
        -0.04442315921187401,
        0.0455186627805233,
        -0.0641716793179512,
        0.02497347630560398,
        0.034321922808885574,
        -0.00383386411704123,
        -0.07002077996730804,
        -0.0214476827532053,
        0.05090213939547539,
        -0.021524280309677124,
        -0.044106386601924896,
        -0.059816181659698486,
        0.04699904844164848,
        -0.06635031849145889,
        0.011652522720396519,
        0.06805984675884247,
        -0.031585246324539185,
        -0.05225563049316406,
        0.010549833066761494,
        -0.019557761028409004,
        0.06890646368265152,
        -0.043453413993120193,
        -0.05478547513484955,
        0.013147225603461266,
        0.03597225621342659,
        0.06221166253089905,
        -0.050054505467414856,
        0.012954658828675747,
        -0.011306052096188068,
        0.06646540760993958,
        0.06259200721979141,
        -0.037111759185791016,
        0.06953984498977661,
        -0.0490194670855999,
        -0.023372147232294083,
        0.0660766214132309,
        -0.03450952470302582,
        -0.01971699856221676,
        -0.029362397268414497,
        -0.016458546742796898,
        -0.045645855367183685,
        0.040065061300992966,
        -0.06853586435317993,
        0.04180227965116501,
        0.05510326102375984,
        -0.06046281382441521,
        0.04155108332633972,
        0.06896857172250748,
        0.058764535933732986,
        0.059155646711587906,
        -0.04912478104233742,
        -0.003889697603881359,
        0.03473154455423355,
        0.04543367400765419,
        0.05870736762881279,
        -0.06882646679878235,
        0.06413934379816055,
        0.05651361495256424,
        -0.06815505772829056,
        0.059262465685606,
        0.0649653971195221,
        -0.037823278456926346,
        -0.0609121136367321,
        0.041258394718170166,
        -0.06293424963951111,
        0.030936069786548615,
        -0.0632271096110344,
        0.052133504301309586,
        -0.031368087977170944,
        -0.031505852937698364,
        0.00317230517975986,
        0.0038661633152514696,
        -0.028482794761657715,
        0.01208499539643526,
        0.01872766762971878,
        -0.06385519355535507,
        -0.03555845841765404,
        -0.03573663532733917,
        0.037139177322387695,
        -0.022787749767303467,
        0.0040007163770496845,
        0.03333837538957596,
        0.03317767381668091,
        0.02692931331694126,
        0.056863050907850266,
        0.006448655389249325,
        -0.03378204628825188,
        -0.04066156968474388,
        -0.059017255902290344,
        -0.04922153055667877,
        0.001808221684768796,
        0.007574656046926975,
        -0.052547696977853775,
        -0.05247335135936737,
        0.06365161389112473,
        -0.031282778829336166,
        0.016169626265764236,
        0.06462804973125458,
        -0.0512530691921711,
        0.05193360522389412,
        -0.06041083112359047,
        0.060281913727521896,
        -0.06139679625630379,
        -0.03706389293074608,
        -0.04705587774515152,
        0.02363564819097519,
        0.012648680247366428,
        0.05795248597860336,
        -0.06670378893613815,
        0.06868588179349899,
        0.06464213132858276,
        0.009380396455526352,
        -0.03266347944736481,
        0.019904745742678642,
        -0.04233485832810402,
        0.04605957865715027,
        -0.05579216405749321,
        -0.005349161569029093,
        0.053908571600914,
        -0.06456292420625687,
        0.04336332157254219,
        -0.0372455008327961,
        -0.020656926557421684,
        -0.009974979795515537,
        0.002100392011925578,
        -0.042206767946481705,
        0.045164261013269424,
        -0.02925938181579113,
        0.011454666033387184,
        -0.06714706122875214,
        -0.010388212278485298,
        0.04207257181406021,
        0.023194998502731323,
        0.0019273476209491491,
        -0.009713659062981606,
        0.02906770259141922,
        -0.0652686133980751,
        0.07186486572027206,
        0.06099971756339073,
        0.04635531082749367,
        0.06106165051460266,
        -0.06868425756692886,
        -0.06993842869997025,
        0.0324350968003273,
        0.07058621942996979,
        -0.012806550599634647,
        -0.04585859179496765,
        -0.05967893823981285,
        -0.029353929683566093,
        -0.03232737258076668,
        -0.062645822763443,
        -0.033740147948265076,
        -0.059473998844623566,
        -0.03419167920947075,
        -0.009121334180235863,
        0.06581628322601318,
        0.03689184784889221,
        0.03469947725534439,
        -0.004076448734849691,
        -0.05438372120261192,
        0.01574859768152237,
        0.04825075343251228,
        0.004022581037133932,
        -0.06831871718168259,
        -0.03487583249807358,
        0.03083905763924122,
        -0.043244149535894394,
        -0.021350376307964325,
        0.057374969124794006,
        0.014636964537203312,
        -0.05702848359942436,
        -0.06650973856449127,
        0.06442377716302872,
        -0.04633714631199837,
        0.052266187965869904,
        -0.018152043223381042,
        -0.04450090602040291,
        -0.06331183016300201,
        0.020906323567032814,
        0.04642842337489128,
        -0.02246595360338688,
        -0.05316640064120293,
        -0.034210093319416046,
        -0.03037160076200962,
        -0.01616155542433262,
        0.05702375993132591,
        0.0412445068359375,
        -0.061007656157016754,
        0.034994132816791534,
        -0.056180622428655624,
        0.06823553144931793,
        -0.026377489790320396,
        -0.045866720378398895,
        -0.02436937391757965,
        0.04161219298839569,
        -0.03136506676673889,
        -0.05156628042459488,
        -0.04429689049720764,
        -0.03074452467262745,
        -0.021281037479639053,
        0.043898213654756546,
        -0.0019083081278949976,
        0.027403486892580986,
        0.04637414589524269,
        0.027804091572761536,
        0.01605244353413582,
        0.05642497166991234,
        0.04469684511423111,
        0.05567171424627304,
        0.028049109503626823,
        -0.058592043817043304,
        -0.013878739438951015,
        -0.03606205806136131,
        0.0015137431910261512,
        0.008070861920714378,
        -0.06609835475683212,
        0.0557095892727375,
        0.011396578513085842,
        -0.009697023779153824,
        -0.03508620336651802,
        -0.03161604329943657,
        -0.05291438847780228,
        -0.03374441713094711,
        0.022756189107894897,
        -0.008405929431319237,
        0.06888771057128906,
        0.022783426567912102,
        0.019043439999222755,
        -0.0640997439622879,
        -0.05208722501993179,
        0.02138511836528778,
        0.048919640481472015,
        0.014475923962891102,
        -0.01581321284174919,
        -0.06208239495754242,
        -0.021863456815481186,
        0.06573711335659027,
        0.025776470080018044,
        0.024635689333081245,
        -0.04798785224556923,
        -0.039066292345523834,
        -0.06358768790960312,
        -0.044564139097929,
        0.033445458859205246,
        0.06690890341997147,
        -0.000172295534866862,
        0.053698644042015076,
        0.04945595562458038,
        -0.05636685714125633,
        0.0017746430821716785,
        -0.05843978002667427,
        0.02696755900979042,
        0.014351045712828636,
        -0.031450849026441574,
        -0.053354255855083466,
        -0.015557993203401566,
        0.03210277482867241,
        0.03028685972094536,
        0.05112955719232559,
        0.053899820894002914,
        -0.05640123784542084,
        -0.00272710295394063,
        -0.05623840540647507,
        0.053178343921899796,
        -0.005131955724209547,
        -0.024749193340539932,
        0.07108218222856522,
        0.023502174764871597,
        -0.01255759783089161,
        -0.05222904309630394,
        0.06310967355966568,
        -0.01234772801399231
    ],
    [
        -0.0419582836329937,
        0.061089396476745605,
        0.05024319142103195,
        0.05187200382351875,
        -0.06129908561706543,
        0.07158311456441879,
        -0.036282069981098175,
        -0.05894406884908676,
        0.06773734092712402,
        -0.0023338214959949255,
        0.03657737746834755,
        -0.03502241522073746,
        -0.01732795126736164,
        -0.042125154286623,
        -0.06276271492242813,
        0.06446932256221771,
        -0.040446534752845764,
        0.07029878348112106,
        -0.011237507686018944,
        -0.07174407690763474,
        0.0412132553756237,
        0.05786454305052757,
        0.00025603771791793406,
        0.015700623393058777,
        0.05189503729343414,
        0.05403092876076698,
        0.000620107282884419,
        0.011178855784237385,
        0.0027548130601644516,
        0.01526369247585535,
        0.043689534068107605,
        -0.03660803288221359,
        -0.011403256095945835,
        -0.056455325335264206,
        0.06183338910341263,
        0.03763765096664429,
        -0.0658486932516098,
        -0.0009961515897884965,
        0.0502576120197773,
        0.030388886108994484,
        0.0651594027876854,
        0.03365856781601906,
        0.039040908217430115,
        -0.06758085638284683,
        0.06987792253494263,
        0.03604407608509064,
        -0.04944209009408951,
        0.060550887137651443,
        0.03443490341305733,
        0.033244531601667404,
        0.03371765464544296,
        0.06733388453722,
        -0.047188472002744675,
        0.0611310675740242,
        0.023322410881519318,
        -0.002809436060488224,
        0.05664495751261711,
        0.06602504849433899,
        -0.05957069247961044,
        -0.03711404278874397,
        -0.059565793722867966,
        -0.008668403141200542,
        -0.05145552381873131,
        0.06604359298944473,
        -0.06820572167634964,
        -0.050613269209861755,
        0.008385416120290756,
        -0.030180716887116432,
        -0.0642140731215477,
        0.014246854931116104,
        -0.041257839649915695,
        -0.06974036246538162,
        0.062180571258068085,
        0.06425482779741287,
        -0.026531396433711052,
        -0.043396808207035065,
        -0.04387078806757927,
        0.07100841403007507,
        0.07038706541061401,
        -0.01641985960304737,
        0.016604764387011528,
        0.06147497147321701,
        -0.06208140403032303,
        0.03348002955317497,
        0.03471260145306587,
        0.05108252912759781,
        0.06886619329452515,
        0.06992003321647644,
        0.02673804946243763,
        -0.0035224882885813713,
        0.007901246659457684,
        -0.034137140959501266,
        -0.06634219735860825,
        -0.010087652131915092,
        -0.05481336638331413,
        0.0360184870660305,
        -0.013117778114974499,
        -0.06170797720551491,
        0.02338716946542263,
        0.04701792821288109,
        -0.056788284331560135,
        0.007611725013703108,
        0.025230661034584045,
        -0.04246370866894722,
        0.00524589978158474,
        -0.06548699736595154,
        -0.05345810949802399,
        0.03040938451886177,
        0.048089399933815,
        0.02388881891965866,
        -0.0644662082195282,
        0.0004019352491013706,
        -0.06705936044454575,
        0.02705010399222374,
        -0.01642623357474804,
        -0.045634444802999496,
        0.021786296740174294,
        0.006744534708559513,
        0.06520381569862366,
        -0.010215191170573235,
        -0.047479577362537384,
        0.0368679016828537,
        0.04245953634381294,
        -0.016107227653265,
        -0.04520842060446739,
        -0.0050684669986367226,
        -0.06056470796465874,
        -0.01596861332654953,
        0.06220107898116112,
        -0.0010517368791624904,
        -0.04656018689274788,
        -0.048390697687864304,
        -0.029982885345816612,
        0.007065997924655676,
        -0.04552844166755676,
        -0.05668175220489502,
        0.044753801077604294,
        0.007824542932212353,
        -0.06371062994003296,
        -0.04145432636141777,
        0.03725754842162132,
        -0.023096201941370964,
        0.06710637360811234,
        0.011015504598617554,
        0.045983873307704926,
        -0.06993212550878525,
        0.04502428323030472,
        -0.04558761045336723,
        -0.019265050068497658,
        0.022346310317516327,
        0.054783228784799576,
        -0.05997277796268463,
        -0.005263191647827625,
        -0.0631667897105217,
        0.047015901654958725,
        -0.05001786723732948,
        -0.008713538758456707,
        0.04321626201272011,
        0.001099897432141006,
        0.04182478040456772,
        0.0714864656329155,
        0.02067599445581436,
        0.05737444385886192,
        0.05692165344953537,
        0.014654695056378841,
        0.02037707529962063,
        -0.0003861428122036159,
        0.01055257860571146,
        -0.014945512637495995,
        -0.007507121190428734,
        0.028297118842601776,
        0.02990531362593174,
        -0.028130054473876953,
        -0.05680422484874725,
        0.0023108532186597586,
        0.05575969070196152,
        -0.023984892293810844,
        -0.02514304593205452,
        0.05663266032934189,
        -0.04876613989472389,
        0.01621292531490326,
        0.07030107825994492,
        0.019080158323049545,
        0.008957757614552975,
        0.02186317928135395,
        -0.029941463842988014,
        -0.012287522666156292,
        0.05583944916725159,
        0.006209091283380985,
        0.01231419574469328,
        -0.047147683799266815,
        -0.0012466260232031345,
        0.036570146679878235,
        -0.019493622705340385,
        -0.012206969782710075,
        0.0511518158018589,
        0.05199824273586273,
        -0.07068914920091629,
        0.032790277153253555,
        -0.027390623465180397,
        0.05892873927950859,
        -0.05654395744204521,
        0.05108240619301796,
        -0.04575216770172119,
        -0.06199103966355324,
        -0.008921443484723568,
        0.04036558046936989,
        -0.0148466806858778,
        -0.020946336910128593,
        0.06527861952781677,
        -0.061854664236307144,
        0.05038391053676605,
        -0.011682325974106789,
        0.041997842490673065,
        -0.007815403863787651,
        0.03174642100930214,
        0.001229657675139606,
        -0.02789708785712719,
        0.050599049776792526,
        0.009644552133977413,
        0.04842616245150566,
        0.062649205327034,
        0.04060309752821922,
        -0.004265467636287212,
        0.06347742676734924,
        0.004561536014080048,
        -0.031053265556693077,
        0.03330494463443756,
        -0.046285346150398254,
        -0.052235234528779984,
        -0.04406391829252243,
        -0.044660456478595734,
        -0.04658469557762146,
        0.0335865244269371,
        0.011358329094946384,
        0.05740620568394661,
        0.06409920752048492,
        -0.05043347552418709,
        0.011866689659655094,
        0.0674552470445633,
        -0.04442315921187401,
        0.0455186627805233,
        -0.0641716793179512,
        0.02497347630560398,
        0.034321922808885574,
        -0.00383386411704123,
        -0.07002077996730804,
        -0.0214476827532053,
        0.05090213939547539,
        -0.021524280309677124,
        -0.044106386601924896,
        -0.059816181659698486,
        0.04699904844164848,
        -0.06635031849145889,
        0.011652522720396519,
        0.06805984675884247,
        -0.031585246324539185,
        -0.05225563049316406,
        0.010549833066761494,
        -0.019557761028409004,
        0.06890646368265152,
        -0.043453413993120193,
        -0.05478547513484955,
        0.013147225603461266,
        0.03597225621342659,
        0.06221166253089905,
        -0.050054505467414856,
        0.012954658828675747,
        -0.011306052096188068,
        0.06646540760993958,
        0.06259200721979141,
        -0.037111759185791016,
        0.06953984498977661,
        -0.0490194670855999,
        -0.023372147232294083,
        0.0660766214132309,
        -0.03450952470302582,
        -0.01971699856221676,
        -0.029362397268414497,
        -0.016458546742796898,
        -0.045645855367183685,
        0.040065061300992966,
        -0.06853586435317993,
        0.04180227965116501,
        0.05510326102375984,
        -0.06046281382441521,
        0.04155108332633972,
        0.06896857172250748,
        0.058764535933732986,
        0.059155646711587906,
        -0.04912478104233742,
        -0.003889697603881359,
        0.03473154455423355,
        0.04543367400765419,
        0.05870736762881279,
        -0.06882646679878235,
        0.06413934379816055,
        0.05651361495256424,
        -0.06815505772829056,
        0.059262465685606,
        0.0649653971195221,
        -0.037823278456926346,
        -0.0609121136367321,
        0.041258394718170166,
        -0.06293424963951111,
        0.030936069786548615,
        -0.0632271096110344,
        0.052133504301309586,
        -0.031368087977170944,
        -0.031505852937698364,
        0.00317230517975986,
        0.0038661633152514696,
        -0.028482794761657715,
        0.01208499539643526,
        0.01872766762971878,
        -0.06385519355535507,
        -0.03555845841765404,
        -0.03573663532733917,
        0.037139177322387695,
        -0.022787749767303467,
        0.0040007163770496845,
        0.03333837538957596,
        0.03317767381668091,
        0.02692931331694126,
        0.056863050907850266,
        0.006448655389249325,
        -0.03378204628825188,
        -0.04066156968474388,
        -0.059017255902290344,
        -0.04922153055667877,
        0.001808221684768796,
        0.007574656046926975,
        -0.052547696977853775,
        -0.05247335135936737,
        0.06365161389112473,
        -0.031282778829336166,
        0.016169626265764236,
        0.06462804973125458,
        -0.0512530691921711,
        0.05193360522389412,
        -0.06041083112359047,
        0.060281913727521896,
        -0.06139679625630379,
        -0.03706389293074608,
        -0.04705587774515152,
        0.02363564819097519,
        0.012648680247366428,
        0.05795248597860336,
        -0.06670378893613815,
        0.06868588179349899,
        0.06464213132858276,
        0.009380396455526352,
        -0.03266347944736481,
        0.019904745742678642,
        -0.04233485832810402,
        0.04605957865715027,
        -0.05579216405749321,
        -0.005349161569029093,
        0.053908571600914,
        -0.06456292420625687,
        0.04336332157254219,
        -0.0372455008327961,
        -0.020656926557421684,
        -0.009974979795515537,
        0.002100392011925578,
        -0.042206767946481705,
        0.045164261013269424,
        -0.02925938181579113,
        0.011454666033387184,
        -0.06714706122875214,
        -0.010388212278485298,
        0.04207257181406021,
        0.023194998502731323,
        0.0019273476209491491,
        -0.009713659062981606,
        0.02906770259141922,
        -0.0652686133980751,
        0.07186486572027206,
        0.06099971756339073,
        0.04635531082749367,
        0.06106165051460266,
        -0.06868425756692886,
        -0.06993842869997025,
        0.0324350968003273,
        0.07058621942996979,
        -0.012806550599634647,
        -0.04585859179496765,
        -0.05967893823981285,
        -0.029353929683566093,
        -0.03232737258076668,
        -0.062645822763443,
        -0.033740147948265076,
        -0.059473998844623566,
        -0.03419167920947075,
        -0.009121334180235863,
        0.06581628322601318,
        0.03689184784889221,
        0.03469947725534439,
        -0.004076448734849691,
        -0.05438372120261192,
        0.01574859768152237,
        0.04825075343251228,
        0.004022581037133932,
        -0.06831871718168259,
        -0.03487583249807358,
        0.03083905763924122,
        -0.043244149535894394,
        -0.021350376307964325,
        0.057374969124794006,
        0.014636964537203312,
        -0.05702848359942436,
        -0.06650973856449127,
        0.06442377716302872,
        -0.04633714631199837,
        0.052266187965869904,
        -0.018152043223381042,
        -0.04450090602040291,
        -0.06331183016300201,
        0.020906323567032814,
        0.04642842337489128,
        -0.02246595360338688,
        -0.05316640064120293,
        -0.034210093319416046,
        -0.03037160076200962,
        -0.01616155542433262,
        0.05702375993132591,
        0.0412445068359375,
        -0.061007656157016754,
        0.034994132816791534,
        -0.056180622428655624,
        0.06823553144931793,
        -0.026377489790320396,
        -0.045866720378398895,
        -0.02436937391757965,
        0.04161219298839569,
        -0.03136506676673889,
        -0.05156628042459488,
        -0.04429689049720764,
        -0.03074452467262745,
        -0.021281037479639053,
        0.043898213654756546,
        -0.0019083081278949976,
        0.027403486892580986,
        0.04637414589524269,
        0.027804091572761536,
        0.01605244353413582,
        0.05642497166991234,
        0.04469684511423111,
        0.05567171424627304,
        0.028049109503626823,
        -0.058592043817043304,
        -0.013878739438951015,
        -0.03606205806136131,
        0.0015137431910261512,
        0.008070861920714378,
        -0.06609835475683212,
        0.0557095892727375,
        0.011396578513085842,
        -0.009697023779153824,
        -0.03508620336651802,
        -0.03161604329943657,
        -0.05291438847780228,
        -0.03374441713094711,
        0.022756189107894897,
        -0.008405929431319237,
        0.06888771057128906,
        0.022783426567912102,
        0.019043439999222755,
        -0.0640997439622879,
        -0.05208722501993179,
        0.02138511836528778,
        0.048919640481472015,
        0.014475923962891102,
        -0.01581321284174919,
        -0.06208239495754242,
        -0.021863456815481186,
        0.06573711335659027,
        0.025776470080018044,
        0.024635689333081245,
        -0.04798785224556923,
        -0.039066292345523834,
        -0.06358768790960312,
        -0.044564139097929,
        0.033445458859205246,
        0.06690890341997147,
        -0.000172295534866862,
        0.053698644042015076,
        0.04945595562458038,
        -0.05636685714125633,
        0.0017746430821716785,
        -0.05843978002667427,
        0.02696755900979042,
        0.014351045712828636,
        -0.031450849026441574,
        -0.053354255855083466,
        -0.015557993203401566,
        0.03210277482867241,
        0.03028685972094536,
        0.05112955719232559,
        0.053899820894002914,
        -0.05640123784542084,
        -0.00272710295394063,
        -0.05623840540647507,
        0.053178343921899796,
        -0.005131955724209547,
        -0.024749193340539932,
        0.07108218222856522,
        0.023502174764871597,
        -0.01255759783089161,
        -0.05222904309630394,
        0.06310967355966568,
        -0.01234772801399231
    ],
    [
        -0.023258071392774582,
        0.053681887686252594,
        0.05806126445531845,
        0.048209670931100845,
        -0.059190209954977036,
        0.07186409831047058,
        -0.051366858184337616,
        -0.06493714451789856,
        0.07099707424640656,
        0.007594129536300898,
        -0.003110345918685198,
        -0.04880642145872116,
        -0.0031675798818469048,
        -0.03998706489801407,
        -0.055008117109537125,
        0.069964200258255,
        -0.01660933531820774,
        0.07314255833625793,
        -0.021458828821778297,
        -0.07486318796873093,
        0.012369262054562569,
        0.06563203036785126,
        -0.021524151787161827,
        -0.047677963972091675,
        0.04966547712683678,
        0.03630116209387779,
        0.02580156922340393,
        -0.04593263939023018,
        0.025473853573203087,
        0.04496419057250023,
        0.0013083884259685874,
        -0.052436038851737976,
        -0.03568974509835243,
        -0.059371091425418854,
        0.057061515748500824,
        0.027067942544817924,
        -0.06379830837249756,
        -0.018805652856826782,
        0.014755784533917904,
        0.017503786832094193,
        0.060433097183704376,
        0.023229287937283516,
        0.03486618772149086,
        -0.06860797107219696,
        0.05563964322209358,
        0.053259704262018204,
        -0.04503032937645912,
        0.04270580783486366,
        0.013499791733920574,
        0.05629391223192215,
        0.013828408904373646,
        0.07181927561759949,
        -0.05635737627744675,
        0.06755764782428741,
        0.0012022574665024877,
        -0.03814772143959999,
        0.060661591589450836,
        0.05338650569319725,
        -0.018308643251657486,
        -0.027434006333351135,
        -0.06353872269392014,
        -0.008514064364135265,
        -0.04739953204989433,
        0.07183735817670822,
        -0.06504669040441513,
        -0.05525676906108856,
        0.04244218394160271,
        -0.014999295584857464,
        -0.06903647631406784,
        0.032933514565229416,
        -0.05693211778998375,
        -0.07116374373435974,
        0.0477527379989624,
        0.05336062237620354,
        -0.04567110165953636,
        -0.037995994091033936,
        -0.025692086666822433,
        0.07422332465648651,
        0.04700234904885292,
        -0.017903223633766174,
        0.024348853155970573,
        0.0699257180094719,
        -0.05387803539633751,
        0.047603022307157516,
        0.041771795600652695,
        0.05093900114297867,
        0.07366100698709488,
        0.07089947164058685,
        0.04712427780032158,
        0.05677196756005287,
        0.02785780467092991,
        -0.06383006274700165,
        -0.0727141872048378,
        -0.0316278338432312,
        -0.05937347561120987,
        0.029192421585321426,
        -0.007578236982226372,
        -0.05015852302312851,
        0.03527946397662163,
        0.03175746276974678,
        -0.0704265907406807,
        -0.0020531020127236843,
        0.0026164886076003313,
        -0.040968749672174454,
        0.04313640668988228,
        -0.07007071375846863,
        -0.0066151926293969154,
        0.0443139374256134,
        0.06331735104322433,
        0.03794633969664574,
        -0.06509101390838623,
        0.015159137547016144,
        -0.07055764645338058,
        0.015212321653962135,
        -0.019235581159591675,
        -0.009456148371100426,
        0.01714504510164261,
        -0.027414388954639435,
        0.07184257358312607,
        0.020811233669519424,
        -0.040227826684713364,
        0.05916263908147812,
        0.0390956774353981,
        -0.026488909497857094,
        -0.03998509794473648,
        -0.050743088126182556,
        -0.047768812626600266,
        -0.0191783644258976,
        0.06212383881211281,
        0.009022261016070843,
        -0.019295351579785347,
        -0.05129869654774666,
        -0.039219193160533905,
        -0.01244011614471674,
        -0.041834037750959396,
        -0.05900238826870918,
        0.05327935889363289,
        0.038618892431259155,
        -0.0681704729795456,
        -0.03981553018093109,
        0.05514814704656601,
        -0.00040936845471151173,
        0.06901414692401886,
        0.01368651445955038,
        0.054250333458185196,
        -0.06564109027385712,
        0.055120810866355896,
        -0.03540460020303726,
        0.02973225526511669,
        0.04598250612616539,
        0.060631029307842255,
        -0.06485415250062943,
        0.022050322964787483,
        -0.06516405940055847,
        0.05447651818394661,
        -0.0315927192568779,
        -0.0262934397906065,
        0.053402774035930634,
        -0.008096989244222641,
        0.048926085233688354,
        0.07311102747917175,
        0.029997562989592552,
        0.0531378909945488,
        0.03405211865901947,
        0.042040251195430756,
        0.02840478904545307,
        -0.027917511761188507,
        0.017124591395258904,
        -0.015853676944971085,
        -0.016877664253115654,
        0.05831029266119003,
        0.017824284732341766,
        0.025588400661945343,
        -0.06410500407218933,
        0.02932913415133953,
        0.056795474141836166,
        -0.05176234990358353,
        -0.017828606069087982,
        0.045154035091400146,
        -0.052442871034145355,
        0.03296366706490517,
        0.07381684333086014,
        0.03472420200705528,
        0.007007664535194635,
        0.003208789275959134,
        0.019677139818668365,
        -0.04123608395457268,
        0.05411737412214279,
        -0.01832238957285881,
        0.005276194307953119,
        -0.007457103114575148,
        0.0009199370397254825,
        -0.0009622840443626046,
        -0.029588807374238968,
        0.04396601766347885,
        0.039200231432914734,
        -0.020935365930199623,
        -0.06990409642457962,
        0.018344342708587646,
        0.005491575226187706,
        0.04336978495121002,
        -0.045858342200517654,
        0.009241585619747639,
        -0.041405268013477325,
        -0.0684407502412796,
        0.028669659048318863,
        0.0472184494137764,
        0.009281672537326813,
        -0.013765965588390827,
        0.07066371291875839,
        -0.04934786632657051,
        0.05353380739688873,
        0.009465809911489487,
        0.04585371911525726,
        -0.008376737125217915,
        0.01163873728364706,
        -0.017791055142879486,
        -0.033360715955495834,
        0.05657188221812248,
        -0.011756591498851776,
        0.026225019246339798,
        0.06387462466955185,
        0.030838076025247574,
        0.01761971041560173,
        0.053187642246484756,
        0.050924476236104965,
        -0.02567259781062603,
        0.006201310083270073,
        -0.058943286538124084,
        -0.005539358593523502,
        -0.03484775125980377,
        -0.044939231127500534,
        -0.04338078945875168,
        0.02159280702471733,
        0.0023300752509385347,
        0.024552149698138237,
        0.06508192420005798,
        -0.05994463711977005,
        0.017615390941500664,
        0.06612005084753036,
        -0.05351020768284798,
        0.0596613883972168,
        -0.06712297350168228,
        0.008991078473627567,
        0.030777983367443085,
        -0.007908650673925877,
        -0.07343406230211258,
        -0.03959640488028526,
        0.03737471252679825,
        0.0076989103108644485,
        -0.0445118211209774,
        -0.06190843507647514,
        0.011403384618461132,
        -0.06512662768363953,
        -0.019405992701649666,
        0.06525498628616333,
        -0.041049279272556305,
        -0.05683338642120361,
        -0.012567651458084583,
        -0.008067130111157894,
        0.07269010692834854,
        -0.05252554267644882,
        -0.04667488858103752,
        0.011710090562701225,
        0.02875332720577717,
        0.04758646711707115,
        -0.03804952651262283,
        0.05005450174212456,
        -0.016457870602607727,
        0.0468289740383625,
        0.05901447683572769,
        -0.039960939437150955,
        0.07161370664834976,
        -0.04483075439929962,
        -0.04035838320851326,
        0.06207086145877838,
        -0.026406120508909225,
        0.00794786587357521,
        -0.04527667537331581,
        -0.009574010036885738,
        -0.03920203074812889,
        0.026386290788650513,
        -0.07132905721664429,
        0.04681951180100441,
        0.03422774001955986,
        -0.05695956200361252,
        0.04531628265976906,
        0.07115687429904938,
        0.022674059495329857,
        0.05481488257646561,
        -0.026205459609627724,
        0.028487669304013252,
        0.029786434024572372,
        0.048127543181180954,
        0.062407609075307846,
        -0.06962613016366959,
        0.05812324211001396,
        0.061432842165231705,
        -0.06862559169530869,
        0.04956651106476784,
        0.06707440316677094,
        -0.014102411456406116,
        -0.06085549667477608,
        -0.010804847814142704,
        -0.05629919841885567,
        0.011136454530060291,
        -0.06920495629310608,
        0.03166370466351509,
        0.02521897852420807,
        -0.010524628683924675,
        0.004791124723851681,
        -0.0074276006780564785,
        -0.03614261373877525,
        0.009406434372067451,
        0.03401055932044983,
        -0.05324406549334526,
        -0.026261335238814354,
        -0.06103586032986641,
        0.005523571744561195,
        -0.038418613374233246,
        -0.020987525582313538,
        0.019257938489317894,
        -0.015302182175219059,
        0.03286595642566681,
        0.054747581481933594,
        -0.02114097587764263,
        -0.03569500893354416,
        0.01681612804532051,
        0.03216922655701637,
        -0.012597531080245972,
        0.017787083983421326,
        0.0010058070765808225,
        -0.06324676424264908,
        -0.052742525935173035,
        0.060435470193624496,
        -0.00932961143553257,
        -0.008664445020258427,
        0.05983041971921921,
        -0.029850933700799942,
        0.058632753789424896,
        0.05570211634039879,
        0.058955226093530655,
        -0.07082803547382355,
        -0.02219902165234089,
        -0.054515279829502106,
        0.02196699008345604,
        0.010862992145121098,
        0.043396685272455215,
        -0.064143605530262,
        0.06753699481487274,
        0.05468804016709328,
        -0.01708543486893177,
        -0.02529575303196907,
        0.04933983087539673,
        -0.02351570501923561,
        0.0640600323677063,
        -0.057990577071905136,
        0.011471712030470371,
        0.058496616780757904,
        -0.0625535249710083,
        0.04162333160638809,
        -0.02713114209473133,
        0.008480379357933998,
        -0.0073331622406840324,
        0.012642906047403812,
        -0.02992425672709942,
        -0.0056419395841658115,
        -0.013069764710962772,
        -0.009967665188014507,
        -0.06619378924369812,
        -0.04075882211327553,
        0.05567820370197296,
        0.031325992196798325,
        0.029622264206409454,
        -0.044931475073099136,
        -0.0046861437149345875,
        -0.06675069034099579,
        0.07475882768630981,
        0.06513145565986633,
        0.04177945852279663,
        0.06220578774809837,
        -0.0737433210015297,
        -0.07222903519868851,
        0.05379330739378929,
        0.06617595255374908,
        -0.01728248782455921,
        -0.02936691604554653,
        -0.06196935102343559,
        -0.04761374741792679,
        -0.022255007177591324,
        -0.06823599338531494,
        -0.022005455568432808,
        0.011185538955032825,
        0.017650950700044632,
        -0.014656588435173035,
        0.06585905700922012,
        0.03783087059855461,
        0.04440751299262047,
        0.011118351481854916,
        -0.061293825507164,
        0.005218044854700565,
        0.0277932770550251,
        0.03808199614286423,
        -0.07427171617746353,
        -0.028118690475821495,
        0.03344500809907913,
        -0.04944472387433052,
        -0.01151319220662117,
        0.06623325496912003,
        -0.018570533022284508,
        -0.0482560358941555,
        -0.05888001248240471,
        0.06109083443880081,
        -0.05177575722336769,
        0.05229142680764198,
        -0.00881313905119896,
        -0.02684120088815689,
        -0.05335703864693642,
        0.026431366801261902,
        0.032162781804800034,
        -0.027545267716050148,
        -0.02760973386466503,
        -0.00145561748649925,
        0.0013791471719741821,
        0.005353214219212532,
        0.048678189516067505,
        0.002069852314889431,
        -0.032437171787023544,
        0.03699449822306633,
        -0.04355314001441002,
        0.06608124822378159,
        -0.011915590614080429,
        -0.05972811207175255,
        -0.006373571697622538,
        0.03483697399497032,
        -0.03226293995976448,
        -0.031472835689783096,
        -0.04153742268681526,
        -0.03134915977716446,
        -0.016250427812337875,
        0.03586728870868683,
        0.013768529519438744,
        0.018303096294403076,
        0.028123756870627403,
        0.05394331365823746,
        0.010300524532794952,
        0.0649295449256897,
        0.05064816027879715,
        0.06228257715702057,
        0.03733386844396591,
        -0.06395839899778366,
        -0.05010318011045456,
        -0.04116993397474289,
        -0.016251154243946075,
        0.017664656043052673,
        -0.07175242900848389,
        0.052703164517879486,
        0.017016252502799034,
        -0.016154903918504715,
        -0.03281363844871521,
        -0.031088529154658318,
        -0.011091307736933231,
        -0.05942476913332939,
        0.05219864100217819,
        -0.04302136227488518,
        0.06996086239814758,
        -0.06609668582677841,
        -0.0049760909751057625,
        -0.06785707920789719,
        -0.03958366438746452,
        0.013483751565217972,
        0.05132481828331947,
        0.009825763292610645,
        -0.0358557365834713,
        -0.07072755694389343,
        -0.037381596863269806,
        0.06730365753173828,
        0.01817230135202408,
        -0.004480788018554449,
        -0.056710369884967804,
        -0.05636972561478615,
        -0.06106062978506088,
        -0.054307013750076294,
        0.023180639371275902,
        0.06840527057647705,
        0.000479109090520069,
        0.02247018739581108,
        0.04090674966573715,
        -0.05488099902868271,
        0.008420399390161037,
        -0.063376784324646,
        0.031403373926877975,
        0.00028141180519014597,
        -0.022445544600486755,
        -0.03285912051796913,
        0.010664391331374645,
        0.04902798682451248,
        0.023221882060170174,
        0.04719032719731331,
        0.05584589019417763,
        -0.06547913700342178,
        -0.02350233867764473,
        -0.06111801043152809,
        0.019037779420614243,
        0.0014343481743708253,
        -0.04793428257107735,
        0.07248794287443161,
        0.0265487227588892,
        -0.05949420854449272,
        -0.06593411415815353,
        0.04055948182940483,
        -0.014586081728339195
    ],
    [
        -0.052846889942884445,
        0.05736994743347168,
        0.06115609407424927,
        0.04161041975021362,
        -0.03938652575016022,
        0.06910086423158646,
        -0.016738537698984146,
        -0.06593559682369232,
        0.0647079274058342,
        -0.06101492419838905,
        0.04808736965060234,
        -0.05074002221226692,
        -0.03289392590522766,
        -0.028093567118048668,
        -0.05471193417906761,
        0.06806342303752899,
        0.012945517897605896,
        0.0693974420428276,
        -0.02097088098526001,
        -0.07048842310905457,
        -0.027117693796753883,
        0.05234375596046448,
        0.048756085336208344,
        -0.026106784120202065,
        0.04958609491586685,
        0.005861991085112095,
        0.02555415779352188,
        0.05119264870882034,
        -0.04569179564714432,
        0.03757552430033684,
        0.023745791986584663,
        -0.07032652199268341,
        0.056134603917598724,
        -0.0599697045981884,
        0.04950239509344101,
        0.02227056212723255,
        -0.0651288777589798,
        -0.04725058004260063,
        0.03684411942958832,
        0.048617854714393616,
        0.05871696025133133,
        0.02136821672320366,
        0.02167590893805027,
        -0.05303555354475975,
        0.06920265406370163,
        0.04260650649666786,
        -0.0004321187443565577,
        0.04144638404250145,
        0.05026758462190628,
        -0.02165151946246624,
        0.03132886439561844,
        0.05950028449296951,
        -0.057085875421762466,
        0.06558772921562195,
        0.025286758318543434,
        0.009554685093462467,
        0.06653348356485367,
        0.04897330701351166,
        -0.01163424365222454,
        -0.0587879940867424,
        -0.0520605742931366,
        0.012409098446369171,
        -0.04558670148253441,
        0.05913565680384636,
        -0.05859927088022232,
        -0.06511522084474564,
        -0.022855835035443306,
        -0.05547496676445007,
        -0.06510081887245178,
        0.007060602307319641,
        -0.041587259620428085,
        -0.06971569359302521,
        0.05543142184615135,
        0.05683179572224617,
        -0.04008379951119423,
        -0.017881538718938828,
        -0.06124570593237877,
        0.07012767344713211,
        0.05713120475411415,
        0.010109486989676952,
        -0.0012406788300722837,
        0.042147696018218994,
        -0.057873401790857315,
        0.043422847986221313,
        0.02760758437216282,
        0.05330195650458336,
        0.06830879300832748,
        0.06705942004919052,
        0.04063368961215019,
        -0.03712470829486847,
        0.01679154671728611,
        0.0027247187681496143,
        -0.06588805466890335,
        0.028505662456154823,
        -0.052368391305208206,
        0.050634387880563736,
        -0.029902009293437004,
        -0.045026663690805435,
        0.018906645476818085,
        -0.033423226326704025,
        -0.05151423439383507,
        0.016256332397460938,
        -0.009807616472244263,
        -0.02898515947163105,
        -0.024371474981307983,
        -0.06996484845876694,
        -0.046887293457984924,
        0.023519447073340416,
        0.04196196421980858,
        0.04537612944841385,
        -0.04424559324979782,
        -0.0548868253827095,
        -0.06228599697351456,
        -0.004663750994950533,
        -0.058603208512067795,
        -0.0451507568359375,
        0.0452655553817749,
        -0.004603310488164425,
        0.07039792835712433,
        0.022654352709650993,
        -0.014081478118896484,
        0.05582539364695549,
        0.04303424060344696,
        -0.03632492944598198,
        -0.02721785195171833,
        -0.020189104601740837,
        -0.031282875686883926,
        0.03607384115457535,
        0.060760077089071274,
        -0.020915329456329346,
        -0.06852003186941147,
        -0.046539027243852615,
        -0.008437194861471653,
        0.037184953689575195,
        -0.04412532225251198,
        -0.05599503219127655,
        0.043551404029130936,
        -0.008020475506782532,
        -0.06292613595724106,
        -0.01315989252179861,
        0.04324307292699814,
        0.05447879433631897,
        0.06585513800382614,
        0.026354972273111343,
        0.05949459224939346,
        -0.06516919285058975,
        0.02032887004315853,
        0.020352797582745552,
        -0.06601657718420029,
        -0.045731525868177414,
        0.05269778147339821,
        -0.05809986591339111,
        -0.031766507774591446,
        -0.04106174036860466,
        0.06664662063121796,
        -0.005679487716406584,
        0.04398074001073837,
        -0.009287331253290176,
        -0.022159336134791374,
        0.014681752771139145,
        0.07012299448251724,
        0.03569323197007179,
        0.052891600877046585,
        0.03691254183650017,
        0.026342282071709633,
        -0.039914365857839584,
        0.03998531773686409,
        -0.014975116588175297,
        0.03132893145084381,
        0.003299477742984891,
        -0.023117266595363617,
        -0.03105667047202587,
        0.006730945780873299,
        -0.06459704041481018,
        -0.02656426467001438,
        0.056201111525297165,
        -0.026228131726384163,
        0.012432737275958061,
        0.040491458028554916,
        -0.019916558638215065,
        0.03420880809426308,
        0.055431563407182693,
        0.03668106347322464,
        -0.0013830940006300807,
        0.0043399217538535595,
        0.008619238622486591,
        0.005530199967324734,
        0.055795252323150635,
        -0.042967721819877625,
        -0.03131188824772835,
        -0.05569593980908394,
        0.025784965604543686,
        0.052350159734487534,
        -0.03467625007033348,
        -0.03266400098800659,
        0.0005199143197387457,
        0.052197396755218506,
        -0.07039523124694824,
        0.029628843069076538,
        -0.021424315869808197,
        0.021519409492611885,
        -0.06387492269277573,
        0.018193989992141724,
        -0.050704002380371094,
        -0.026388654485344887,
        0.03156428039073944,
        0.020347846671938896,
        -0.007707515265792608,
        -0.056288961321115494,
        0.06364566087722778,
        -0.0518510676920414,
        0.03507023677229881,
        0.06336431205272675,
        0.022446241229772568,
        0.044666752219200134,
        0.014783990569412708,
        0.001204845611937344,
        -0.0030864367727190256,
        0.05175187438726425,
        0.04870801046490669,
        0.03471327945590019,
        0.0479138046503067,
        0.05089988559484482,
        0.032654546201229095,
        0.050437916070222855,
        0.019553685560822487,
        -0.03164978697896004,
        0.013636334799230099,
        -0.017201019451022148,
        -0.0027112504467368126,
        -0.0516870953142643,
        -0.03148075193166733,
        -0.05613046884536743,
        0.04054960235953331,
        0.04685856029391289,
        0.06264752894639969,
        0.060076773166656494,
        -0.015422986820340157,
        -0.03580215200781822,
        0.06753931939601898,
        -0.029811181128025055,
        0.014537959359586239,
        -0.014853162690997124,
        0.011175505816936493,
        0.01892191171646118,
        -0.045607373118400574,
        -0.06537970155477524,
        -0.019396133720874786,
        0.05116938054561615,
        -0.04022184759378433,
        0.0015319206286221743,
        -0.04591016843914986,
        0.06631886214017868,
        -0.06541931629180908,
        0.06369280070066452,
        0.06780889630317688,
        -0.013791825622320175,
        -0.0445026233792305,
        -0.020340200513601303,
        -0.05763409659266472,
        0.06811085343360901,
        -0.011760985478758812,
        -0.026718754321336746,
        0.03113514930009842,
        0.023914529010653496,
        0.056781888008117676,
        -0.012326324358582497,
        0.058419618755578995,
        0.01738426275551319,
        0.06267902255058289,
        0.030377227813005447,
        -0.027839183807373047,
        0.06993710249662399,
        -0.056031484156847,
        0.0477013997733593,
        0.04241320490837097,
        -0.023745331913232803,
        -0.023038841784000397,
        0.005150448530912399,
        0.04846399277448654,
        -0.053231049329042435,
        0.0009360919357277453,
        -0.06288109719753265,
        0.03314867615699768,
        0.050470445305109024,
        -0.03845687210559845,
        0.047111641615629196,
        0.06111941859126091,
        0.06387338787317276,
        0.06430813670158386,
        -0.03722253441810608,
        0.01882551982998848,
        0.015684623271226883,
        0.04114820435643196,
        0.05362159013748169,
        -0.06729871034622192,
        0.05820392072200775,
        0.026425883173942566,
        -0.06511479616165161,
        0.055828195065259933,
        0.030099444091320038,
        -0.0313485711812973,
        -0.055515356361866,
        0.014137687161564827,
        -0.06977195292711258,
        -0.028216157108545303,
        -0.03866137936711311,
        0.048090171068906784,
        -0.030751623213291168,
        -0.049919985234737396,
        0.004869653843343258,
        0.04272174462676048,
        -0.03216283395886421,
        -0.004039723426103592,
        -0.0006161656347103417,
        -0.056852106004953384,
        -0.02614748664200306,
        0.0571184940636158,
        0.04500697925686836,
        -0.011614415794610977,
        -0.06608704477548599,
        0.0659017264842987,
        0.004152078181505203,
        0.04612049087882042,
        0.05956077575683594,
        0.0035910941660404205,
        0.01068172138184309,
        -0.05448995158076286,
        -0.01880701072514057,
        0.029159627854824066,
        -0.056723956018686295,
        0.0219236072152853,
        0.02056337147951126,
        0.0018926227930933237,
        0.06345654278993607,
        -0.04782058298587799,
        -0.017322590574622154,
        0.06299188733100891,
        -0.05133495852351189,
        -0.0008278943714685738,
        -0.06088227778673172,
        0.034955207258462906,
        -0.0644034892320633,
        0.01949050836265087,
        -0.03839125856757164,
        0.001267501269467175,
        0.0396362841129303,
        0.03335697203874588,
        -0.06381753832101822,
        0.06240564212203026,
        0.05823591724038124,
        -0.03359494358301163,
        -0.024535970762372017,
        0.04648108780384064,
        -0.043032314628362656,
        0.05035020783543587,
        -0.0609201043844223,
        -0.012883574701845646,
        0.04755736514925957,
        -0.06142055615782738,
        0.03374246135354042,
        0.029903752729296684,
        -0.041633736342191696,
        0.013799968175590038,
        -0.039853520691394806,
        -0.04137396812438965,
        0.042429178953170776,
        -0.022109098732471466,
        0.059256769716739655,
        -0.056968804448843,
        -0.039240289479494095,
        0.04781671240925789,
        -0.04767351597547531,
        0.003671577898785472,
        -0.015142357908189297,
        0.016367945820093155,
        -0.06618299335241318,
        0.07059018313884735,
        0.05394142493605614,
        0.05487721040844917,
        0.06323294341564178,
        -0.06977105140686035,
        -0.06785492599010468,
        0.003617424750700593,
        0.06659475713968277,
        -0.042246412485837936,
        -0.01484720315784216,
        -0.041651979088783264,
        -0.0019422636833041906,
        -0.01060701347887516,
        -0.06507658958435059,
        -0.05342375114560127,
        -0.05974192172288895,
        -0.043505389243364334,
        -0.03341897949576378,
        0.06322530657052994,
        -0.039619531482458115,
        0.05781421810388565,
        -0.010848446749150753,
        -0.06060318648815155,
        -0.026842912659049034,
        0.04990936070680618,
        -0.032898593693971634,
        -0.07018961757421494,
        0.042785078287124634,
        0.0478934682905674,
        -0.007489506620913744,
        0.025675876066088676,
        0.05958786979317665,
        0.03325546160340309,
        -0.05961533263325691,
        -0.05655891075730324,
        0.04143117368221283,
        -0.03455180302262306,
        0.0545179583132267,
        -0.020081819966435432,
        -0.0655452162027359,
        -0.032469652593135834,
        -0.016712898388504982,
        0.05698460340499878,
        -0.06124228984117508,
        -0.06386882066726685,
        -0.03741689771413803,
        -0.008575985208153725,
        0.029474228620529175,
        0.061581581830978394,
        0.0005500472616404295,
        0.034560203552246094,
        0.01965235359966755,
        -0.0519963763654232,
        0.06815481185913086,
        -0.038497939705848694,
        -0.03289690986275673,
        0.04675370454788208,
        0.04798263683915138,
        -0.04747270792722702,
        -0.06178542226552963,
        0.015343066304922104,
        -0.0589781291782856,
        -0.001141451415605843,
        -0.0022897874005138874,
        0.017106186598539352,
        0.026738489046692848,
        0.048165544867515564,
        0.036043256521224976,
        0.025248179212212563,
        0.040585435926914215,
        0.04360378161072731,
        0.04939281940460205,
        -0.008350789546966553,
        0.049450356513261795,
        -0.05672150477766991,
        -0.004591548349708319,
        -0.0015723657561466098,
        0.04353414475917816,
        -0.06236559525132179,
        0.018280860036611557,
        -0.010444417595863342,
        -0.05031392350792885,
        -0.031692180782556534,
        -0.0511053241789341,
        -0.033294182270765305,
        -0.04890197515487671,
        0.005165493581444025,
        -0.019696608185768127,
        0.06889186799526215,
        0.049903709441423416,
        0.039036352187395096,
        -0.06799116730690002,
        -0.05368221178650856,
        0.043912507593631744,
        0.062677763402462,
        0.0040169996209442616,
        -0.020271386951208115,
        -0.060309551656246185,
        -0.04974166303873062,
        0.06546865403652191,
        0.050615329295396805,
        0.06260169297456741,
        -0.05337437614798546,
        -0.007804378401488066,
        -0.06648942083120346,
        -0.05207270011305809,
        0.0328233540058136,
        -0.009694931097328663,
        -0.02112896926701069,
        0.05390160530805588,
        0.05423524230718613,
        -0.05543382093310356,
        -0.01557069830596447,
        -0.050138071179389954,
        0.05614425614476204,
        0.050387270748615265,
        -0.06288032978773117,
        -0.022120052948594093,
        0.01776188611984253,
        0.0698186457157135,
        -0.013966863974928856,
        0.04433531314134598,
        0.06599529087543488,
        -0.06446269899606705,
        -0.0061431932263076305,
        -0.04448634013533592,
        -0.016919707879424095,
        0.04436643794178963,
        -0.02911795675754547,
        0.06845538318157196,
        0.010621131397783756,
        0.006808042526245117,
        -0.06705155968666077,
        -0.013411038555204868,
        -0.0028794514946639538
    ],
    [
        -0.04916933551430702,
        -0.06382551044225693,
        -0.004867370240390301,
        0.03715129569172859,
        -0.0627291351556778,
        0.07063275575637817,
        -0.021796416491270065,
        -0.0536104291677475,
        0.06749200820922852,
        -0.012737061828374863,
        0.04911528155207634,
        -0.06593982875347137,
        -0.02486482448875904,
        0.0010494482703506947,
        -0.05210566148161888,
        0.004927855916321278,
        -0.049902625381946564,
        0.07138314843177795,
        -0.06784971803426743,
        -0.07095891237258911,
        -0.05230750888586044,
        -0.0007202793494798243,
        0.015625832602381706,
        -0.020083047449588776,
        0.006651365198194981,
        0.053225450217723846,
        0.021971534937620163,
        0.014881066977977753,
        0.02523571252822876,
        -0.0006391066126525402,
        0.05483932048082352,
        -0.007849743589758873,
        0.019212927669286728,
        -0.06125815212726593,
        0.05093220993876457,
        0.050604429095983505,
        -0.06489270180463791,
        0.004904615692794323,
        -0.06263505667448044,
        0.030358251184225082,
        0.06323947012424469,
        0.038803040981292725,
        0.033910252153873444,
        -0.0712670162320137,
        0.06856337934732437,
        0.04532896354794502,
        -0.03812846913933754,
        0.06613076478242874,
        0.04349468648433685,
        -0.00773191824555397,
        0.018857479095458984,
        0.0665505900979042,
        -0.04820805415511131,
        0.06634268164634705,
        -0.006953846197575331,
        0.03599743917584419,
        0.044923778623342514,
        0.06488540023565292,
        -0.01727469637989998,
        -0.01629197783768177,
        -0.062239810824394226,
        -0.01193068828433752,
        -0.04281584545969963,
        0.051013462245464325,
        -0.06660574674606323,
        -0.04396376386284828,
        -0.01603858359158039,
        -0.03582720085978508,
        -0.029878616333007812,
        -0.06823135167360306,
        -0.05506247282028198,
        -0.06995276361703873,
        0.06546486914157867,
        0.06171325594186783,
        -0.004857966210693121,
        -0.04150354489684105,
        -0.06801614165306091,
        0.07140269130468369,
        0.06286395341157913,
        -0.022859657183289528,
        0.016789410263299942,
        0.05082736164331436,
        -0.060483381152153015,
        0.04159030690789223,
        0.054789818823337555,
        0.05886786803603172,
        0.0669330433011055,
        0.07145076990127563,
        0.052764665335416794,
        -0.009376025758683681,
        0.012467141263186932,
        -0.009271828457713127,
        -0.05407019704580307,
        -0.0475647933781147,
        -0.051610514521598816,
        0.024697689339518547,
        -0.03345249220728874,
        -0.04437101259827614,
        0.01685411110520363,
        0.037736352533102036,
        -0.06290224939584732,
        0.03212007135152817,
        0.028499189764261246,
        -0.03251063823699951,
        -0.007124303840100765,
        -0.06607288122177124,
        -0.03446013480424881,
        0.01203569769859314,
        0.039739206433296204,
        0.05434446409344673,
        -0.0570983961224556,
        0.004003382753580809,
        -0.06084498018026352,
        0.04150722175836563,
        -0.005534397438168526,
        -0.055512480437755585,
        -0.040311604738235474,
        -0.02289280667901039,
        0.059711795300245285,
        0.03219355642795563,
        -0.03396095335483551,
        0.04367198795080185,
        0.04300113767385483,
        -0.039765313267707825,
        -0.034842632710933685,
        0.013062690384685993,
        -0.05131133645772934,
        0.012404563836753368,
        0.05968468636274338,
        0.011722620576620102,
        0.015371795743703842,
        -0.054085757583379745,
        -0.035404521971940994,
        -0.020338337868452072,
        -0.04263203218579292,
        -0.05834884196519852,
        0.05219370126724243,
        -0.04331813007593155,
        -0.06270518898963928,
        0.00857782643288374,
        0.05593567714095116,
        0.0035134225618094206,
        0.0651717483997345,
        0.031845930963754654,
        0.002863785019144416,
        -0.0640067458152771,
        -0.0015255577163770795,
        -0.0371042937040329,
        -0.046724725514650345,
        0.009224672801792622,
        0.013941674493253231,
        -0.05848120525479317,
        0.01683928444981575,
        -0.05937180668115616,
        0.06910097599029541,
        -0.043504465371370316,
        -0.018721889704465866,
        0.010830288752913475,
        -0.02450067549943924,
        0.05458711087703705,
        0.07131288200616837,
        0.023460937663912773,
        0.05670761317014694,
        0.004957887344062328,
        0.05294313281774521,
        0.017769254744052887,
        0.01327160932123661,
        -0.047759268432855606,
        0.0019192594336345792,
        0.004706767853349447,
        0.060944851487874985,
        0.020309871062636375,
        0.013238467276096344,
        -0.01997964456677437,
        -0.05445454269647598,
        0.05195318162441254,
        -0.070008285343647,
        -0.039049163460731506,
        0.048649318516254425,
        -0.05315456911921501,
        -0.02311365120112896,
        0.06814749538898468,
        0.005548516754060984,
        0.0021816780790686607,
        -0.0270786602050066,
        -0.0139950942248106,
        0.0033882095012813807,
        0.05550603196024895,
        0.06262396275997162,
        0.04633178561925888,
        -0.020051460713148117,
        -0.015723438933491707,
        0.04006829112768173,
        0.00018603964417707175,
        0.006289919838309288,
        -0.004488380625844002,
        0.0511014461517334,
        -0.06910645216703415,
        -0.009691404178738594,
        0.03284061700105667,
        0.058088019490242004,
        -0.04539595916867256,
        0.05190170556306839,
        -0.02506271004676819,
        -0.05532330647110939,
        -0.049065001308918,
        0.03805139660835266,
        -0.0393923819065094,
        -0.010794002562761307,
        0.06474293023347855,
        -0.056402333080768585,
        0.04774484410881996,
        -0.005456295795738697,
        0.05349866300821304,
        0.015589267015457153,
        0.027697952464222908,
        -0.034794267266988754,
        -0.000285399699350819,
        0.062458284199237823,
        0.05462319403886795,
        0.06455034762620926,
        -0.03171943500638008,
        0.03659897297620773,
        0.018139109015464783,
        0.06602297723293304,
        -0.02030983753502369,
        -0.034675925970077515,
        0.059988103806972504,
        -0.035213902592659,
        -0.0035672285594046116,
        -0.03708653524518013,
        -0.048087552189826965,
        -0.022108593955636024,
        0.03873788192868233,
        0.008760773576796055,
        0.060955386608839035,
        0.06152569130063057,
        -0.04119616374373436,
        -0.029913565143942833,
        0.06997901946306229,
        -0.05026852712035179,
        0.03620330989360809,
        -0.039670541882514954,
        -0.0259648859500885,
        0.022759534418582916,
        -0.022460157051682472,
        -0.06864488869905472,
        -0.004904986824840307,
        0.03774981200695038,
        0.004375082906335592,
        -0.04327266290783882,
        -0.05529724061489105,
        -0.0002978322154376656,
        -0.06549163907766342,
        0.05270684137940407,
        0.06953154504299164,
        -0.034906089305877686,
        -0.04823286086320877,
        -0.014700225554406643,
        0.022772086784243584,
        0.05350986123085022,
        -0.05085067078471184,
        -0.035433851182460785,
        0.02784222550690174,
        0.010644922032952309,
        0.06263651698827744,
        -0.07041516155004501,
        0.012574839405715466,
        -0.014869393780827522,
        0.060397107154130936,
        0.06269024312496185,
        -0.05838824808597565,
        0.0711011290550232,
        -0.06175198778510094,
        -0.06247555837035179,
        0.06534391641616821,
        -0.033295273780822754,
        0.0015554664423689246,
        -0.014711902476847172,
        0.02575751580297947,
        -0.03360411524772644,
        0.03507482260465622,
        -0.06646312773227692,
        0.014560333453118801,
        0.052597977221012115,
        -0.06157701089978218,
        0.057042770087718964,
        0.010301361791789532,
        0.03758779913187027,
        0.06246669217944145,
        -0.06324958056211472,
        -0.02477632462978363,
        0.024669494479894638,
        0.054471567273139954,
        0.06661803275346756,
        -0.06667127460241318,
        0.059321317821741104,
        0.03627953678369522,
        -0.06296806037425995,
        0.050599243491888046,
        0.047687895596027374,
        -0.04779563844203949,
        -0.06230397894978523,
        0.007170653436332941,
        -0.06861115992069244,
        -0.009256904944777489,
        -0.053535349667072296,
        0.04874037578701973,
        0.03355322778224945,
        0.03376958519220352,
        -0.01041985023766756,
        0.02484162151813507,
        -0.03453195095062256,
        0.016879405826330185,
        0.04055733233690262,
        -0.05607086420059204,
        -0.038926225155591965,
        -0.057188842445611954,
        0.030953077599406242,
        -0.02152763493359089,
        -0.005856210365891457,
        0.006471191067248583,
        -0.013871554285287857,
        0.0161295123398304,
        0.04910274222493172,
        0.02744342014193535,
        -0.03943830356001854,
        0.019858654588460922,
        -0.0354168601334095,
        -0.036875173449516296,
        -0.03597681224346161,
        -0.013554807752370834,
        -0.05347278341650963,
        -0.04699002578854561,
        0.06291614472866058,
        -0.06685728579759598,
        0.027044501155614853,
        0.06306364387273788,
        -0.06079857796430588,
        0.05331850424408913,
        -0.06080416589975357,
        0.06390565633773804,
        -0.06234318017959595,
        -0.014783385209739208,
        0.06269634515047073,
        0.04116836562752724,
        0.05704599246382713,
        0.048903774470090866,
        -0.06252500414848328,
        0.06561142206192017,
        -0.0680692121386528,
        0.044174376875162125,
        -0.03602706640958786,
        0.03557000681757927,
        -0.045191749930381775,
        0.053956739604473114,
        -0.06456489115953445,
        -0.014726119115948677,
        0.026896493509411812,
        -0.06113766133785248,
        0.037926726043224335,
        -0.024535026401281357,
        0.029944580048322678,
        -0.03732648864388466,
        0.0139504699036479,
        -0.04755198583006859,
        0.031742919236421585,
        -0.05149761959910393,
        0.006339182145893574,
        -0.06478457152843475,
        -0.040115103125572205,
        0.05050060525536537,
        -0.017403079196810722,
        0.0011671518441289663,
        -0.007250848691910505,
        0.008957474492490292,
        -0.06681699305772781,
        0.071724534034729,
        0.045597389340400696,
        0.048535093665122986,
        0.06041326746344566,
        -0.0675278902053833,
        -0.06996318697929382,
        -0.006323401350528002,
        0.05981362983584404,
        -0.019797975197434425,
        -0.03983984515070915,
        -0.052095796912908554,
        -0.01784728281199932,
        0.0011998584959656,
        -0.06016659364104271,
        -0.013520556502044201,
        -0.05873687192797661,
        -0.01178918220102787,
        -0.03570803999900818,
        0.06416581571102142,
        0.04841562360525131,
        0.03138647973537445,
        0.006826373282819986,
        -0.044673942029476166,
        -0.0297471322119236,
        0.04148731008172035,
        -0.0037666342686861753,
        -0.03698486089706421,
        -0.06676178425550461,
        0.03748154267668724,
        -0.04150032252073288,
        -0.011269313283264637,
        0.06152492016553879,
        -0.01870528981089592,
        -0.041494905948638916,
        -0.050492942333221436,
        0.06537344306707382,
        -0.047572169452905655,
        0.048698991537094116,
        0.008854785934090614,
        -0.06368285417556763,
        -0.06526066362857819,
        0.019536027684807777,
        -0.060676272958517075,
        0.021438172087073326,
        -0.05573596805334091,
        -0.020619848743081093,
        -0.020637935027480125,
        -0.013832091353833675,
        0.05820583552122116,
        0.046126946806907654,
        -0.04424581304192543,
        0.030155273154377937,
        -0.05191086605191231,
        0.0465579479932785,
        0.00026350418920628726,
        -0.06288692355155945,
        -0.04775296524167061,
        0.046164363622665405,
        -0.044573985040187836,
        -0.04726214334368706,
        -0.03288651257753372,
        -0.049494668841362,
        -0.03573904559016228,
        0.030068637803196907,
        -0.0315038301050663,
        -0.0286567322909832,
        0.037969157099723816,
        0.07043302059173584,
        0.0024675028398633003,
        0.05446824058890343,
        0.00026189317577518523,
        0.06183194741606712,
        0.02659749798476696,
        0.00986392144113779,
        -0.03502961993217468,
        -0.028330162167549133,
        -0.01589459739625454,
        -0.059452254325151443,
        -0.06226933002471924,
        0.04498901590704918,
        0.01576073281466961,
        -0.05737115815281868,
        -0.043181806802749634,
        -0.04603826627135277,
        -0.0213126502931118,
        -0.02495473064482212,
        0.010417431592941284,
        -0.010920926928520203,
        0.0696890577673912,
        0.03351486474275589,
        0.03912312164902687,
        -0.02782706916332245,
        -0.04553504288196564,
        0.016923248767852783,
        0.06479097157716751,
        -0.002283497015014291,
        -0.056269578635692596,
        -0.0647020936012268,
        -0.04371465742588043,
        0.06813894957304001,
        0.04611632227897644,
        0.03145173564553261,
        -0.050018422305583954,
        -0.06460103392601013,
        -0.042730461806058884,
        -0.05456075444817543,
        0.023516686633229256,
        0.04784790053963661,
        -0.03699677065014839,
        0.0360284261405468,
        0.054455652832984924,
        -0.05469324812293053,
        0.0562489777803421,
        -0.0558052584528923,
        -0.018717218190431595,
        0.025297079235315323,
        -0.06572096794843674,
        -0.03403530269861221,
        -0.028768295422196388,
        0.010420035570859909,
        0.06915837526321411,
        0.03929973393678665,
        0.047987207770347595,
        0.06015506759285927,
        -0.006613621022552252,
        -0.0406746082007885,
        0.029918694868683815,
        -0.03488627448678017,
        -0.0054903533309698105,
        0.06518622487783432,
        0.01300443522632122,
        -0.034513119608163834,
        -0.032817769795656204,
        0.03586330637335777,
        -0.027228834107518196
    ],
    [
        -0.046171292662620544,
        0.05876697599887848,
        0.056435246020555496,
        0.04578574374318123,
        -0.060619741678237915,
        0.07098913192749023,
        -0.03331995755434036,
        -0.06204456463456154,
        0.06626758724451065,
        -0.006313366815447807,
        0.036662694066762924,
        -0.05674149468541145,
        -0.014575553126633167,
        -0.03824412450194359,
        -0.06193002685904503,
        0.0609615221619606,
        -0.04250297695398331,
        0.07029062509536743,
        -0.0020780223421752453,
        -0.07104044407606125,
        0.02933645434677601,
        0.0618227943778038,
        0.00632269773632288,
        0.012409518472850323,
        0.052822012454271317,
        0.044061899185180664,
        0.0029255119152367115,
        0.002299287123605609,
        -0.0012767176376655698,
        0.019436262547969818,
        0.04765656962990761,
        -0.03331403434276581,
        -0.019120659679174423,
        -0.059333495795726776,
        0.06192772090435028,
        0.04457167908549309,
        -0.06648565083742142,
        -0.0035315065179020166,
        0.05811327323317528,
        0.02957819029688835,
        0.06236611679196358,
        0.03876158595085144,
        0.03915160521864891,
        -0.06384880095720291,
        0.06675435602664948,
        0.03487645089626312,
        -0.052611302584409714,
        0.06299096345901489,
        0.02389196678996086,
        0.04170835763216019,
        0.038249433040618896,
        0.06500976532697678,
        -0.05196177214384079,
        0.06312379240989685,
        0.023595862090587616,
        -0.0030951006338000298,
        0.050809673964977264,
        0.0662006139755249,
        -0.05169364809989929,
        -0.035567957907915115,
        -0.059789810329675674,
        -0.004264316987246275,
        -0.050097618252038956,
        0.06407887488603592,
        -0.06639112532138824,
        -0.05904065817594528,
        -0.00204165349714458,
        -0.04414106160402298,
        -0.06502826511859894,
        0.01866650953888893,
        -0.04384185001254082,
        -0.07009021937847137,
        0.06537599861621857,
        0.06480606645345688,
        -0.024808544665575027,
        -0.03429821878671646,
        -0.0472896546125412,
        0.07041159272193909,
        0.06949573755264282,
        -0.002522522583603859,
        0.01145043782889843,
        0.05860763415694237,
        -0.06222119554877281,
        0.03445763513445854,
        0.04216302931308746,
        0.05165047571063042,
        0.06741797178983688,
        0.06887637078762054,
        0.03924143686890602,
        -0.009419661946594715,
        0.010643013752996922,
        -0.04198308289051056,
        -0.0661359429359436,
        0.002185042016208172,
        -0.05241883546113968,
        0.03566605597734451,
        -0.02663256973028183,
        -0.06134163960814476,
        0.030870983377099037,
        0.04887695610523224,
        -0.05979426950216293,
        0.007891904562711716,
        0.02465818263590336,
        -0.04279351234436035,
        0.005019170697778463,
        -0.06296002864837646,
        -0.04772672802209854,
        0.01814241148531437,
        0.04148354381322861,
        0.03922775760293007,
        -0.06208423525094986,
        0.02439483441412449,
        -0.06760936975479126,
        0.010009551420807838,
        -0.023897483944892883,
        -0.05446605384349823,
        0.02264656126499176,
        -0.019860735163092613,
        0.06702745705842972,
        -0.002799323061481118,
        -0.04350416362285614,
        0.05070481821894646,
        0.03769519180059433,
        -0.0015463592717424035,
        -0.049980685114860535,
        -0.007833457551896572,
        -0.060002077370882034,
        -0.00006285525887506083,
        0.062138721346855164,
        -0.018906157463788986,
        -0.04526469111442566,
        -0.05044229328632355,
        -0.017382916063070297,
        0.0036663305945694447,
        -0.04648266360163689,
        -0.0580027811229229,
        0.04353007301688194,
        -0.0009341519908048213,
        -0.06214014068245888,
        -0.026980817317962646,
        0.05198794975876808,
        -0.02013883739709854,
        0.06672703474760056,
        0.016484661027789116,
        0.04959622770547867,
        -0.06979762017726898,
        0.05341678485274315,
        -0.04530025273561478,
        -0.03420205041766167,
        0.008112046867609024,
        0.05883724242448807,
        -0.05926976352930069,
        -0.024199899286031723,
        -0.060152944177389145,
        0.06199874356389046,
        -0.04736615717411041,
        -0.014401210471987724,
        0.03700105845928192,
        -0.012926858849823475,
        0.02985866740345955,
        0.07079324126243591,
        0.024341214448213577,
        0.056670140475034714,
        0.055141035467386246,
        0.030530886724591255,
        -0.008124548941850662,
        -0.005531809758394957,
        0.0042253341525793076,
        -0.019705917686223984,
        -0.01109789777547121,
        0.0177475493401289,
        0.024312060326337814,
        -0.0237924512475729,
        -0.056301843374967575,
        -0.019111307337880135,
        0.05038635805249214,
        -0.017589105293154716,
        -0.02092902921140194,
        0.05462297052145004,
        -0.048805978149175644,
        0.012041551060974598,
        0.0687355101108551,
        0.017580922693014145,
        0.01511213555932045,
        0.02564689703285694,
        -0.02538946270942688,
        -0.0041107358410954475,
        0.05912799760699272,
        -0.021667126566171646,
        -0.006697546225041151,
        -0.04990703985095024,
        -0.004049063194543123,
        0.03654240071773529,
        -0.02119072899222374,
        -0.014006981626152992,
        0.05244828760623932,
        0.05729115381836891,
        -0.07014432549476624,
        0.021346645429730415,
        -0.019935201853513718,
        0.05718834698200226,
        -0.05497175455093384,
        0.0482950285077095,
        -0.044878341257572174,
        -0.061707817018032074,
        -0.02739943377673626,
        0.05055893585085869,
        -0.01572408340871334,
        -0.02458343468606472,
        0.06178431212902069,
        -0.05928020179271698,
        0.04976985603570938,
        -0.011172765865921974,
        0.04212931916117668,
        -0.014347202144563198,
        0.031517621129751205,
        -0.002902975073084235,
        -0.0246331337839365,
        0.055018045008182526,
        0.020647039636969566,
        0.045905064791440964,
        0.056867457926273346,
        0.0453779436647892,
        0.019953539595007896,
        0.0647275447845459,
        -0.015892090275883675,
        -0.027721691876649857,
        0.038145776838064194,
        -0.045879025012254715,
        -0.050329819321632385,
        -0.04617537558078766,
        -0.04759591072797775,
        -0.041486985981464386,
        0.034839075058698654,
        0.021458027884364128,
        0.05092819035053253,
        0.06382891535758972,
        -0.04940935969352722,
        0.024298427626490593,
        0.06714195013046265,
        -0.04080698639154434,
        0.03596701845526695,
        -0.06360360980033875,
        0.02088831178843975,
        0.019083669409155846,
        -0.016612501814961433,
        -0.06906343251466751,
        -0.02781464159488678,
        0.05697180703282356,
        -0.01777838170528412,
        -0.03923436254262924,
        -0.059833187609910965,
        0.033151064068078995,
        -0.0642809271812439,
        0.01772422157227993,
        0.06786225736141205,
        -0.027774231508374214,
        -0.050489429384469986,
        0.004914316348731518,
        -0.020966630429029465,
        0.06653165072202682,
        -0.04973374679684639,
        -0.050882551819086075,
        0.009712077677249908,
        0.028610145673155785,
        0.062040314078330994,
        -0.05744955688714981,
        0.005543895997107029,
        -0.002705593826249242,
        0.066092848777771,
        0.062434516847133636,
        -0.03125663101673126,
        0.06899360567331314,
        -0.0542905256152153,
        -0.03734719753265381,
        0.06592785567045212,
        -0.028852075338363647,
        -0.006782235577702522,
        -0.03920215740799904,
        -0.01987697370350361,
        -0.03891530632972717,
        0.0104737039655447,
        -0.0686328187584877,
        0.04128870368003845,
        0.048455026000738144,
        -0.05792061984539032,
        0.039022430777549744,
        0.06815391033887863,
        0.05662146955728531,
        0.0588848702609539,
        -0.04881832003593445,
        -0.003882183227688074,
        0.028259173035621643,
        0.050244323909282684,
        0.06041811406612396,
        -0.06818635016679764,
        0.06236744672060013,
        0.053656600415706635,
        -0.06806118786334991,
        0.058446865528821945,
        0.05979520082473755,
        -0.03858008235692978,
        -0.05806960165500641,
        0.044242072850465775,
        -0.06598267704248428,
        0.008958975784480572,
        -0.06217440962791443,
        0.0539168119430542,
        -0.026685524731874466,
        -0.042462244629859924,
        0.009737204760313034,
        0.015203558839857578,
        -0.04228521138429642,
        0.0031541986390948296,
        0.02878207340836525,
        -0.0629633441567421,
        -0.03524387627840042,
        -0.03340529650449753,
        0.03485071286559105,
        -0.02108030766248703,
        0.01578885316848755,
        0.03435354679822922,
        0.019640879705548286,
        0.04323941841721535,
        0.05753874406218529,
        0.006512897089123726,
        -0.04662349820137024,
        -0.04184051975607872,
        -0.054351139813661575,
        -0.04502898082137108,
        0.005537121556699276,
        0.022052453830838203,
        -0.05413369834423065,
        -0.04563241824507713,
        0.06293665617704391,
        -0.04052354395389557,
        0.01565544120967388,
        0.06644685566425323,
        -0.05016685649752617,
        0.05235835537314415,
        -0.05649738386273384,
        0.06197457015514374,
        -0.05882808566093445,
        -0.029107237234711647,
        -0.04750888794660568,
        0.0327925868332386,
        0.01395114790648222,
        0.053425733000040054,
        -0.06492669135332108,
        0.06757577508687973,
        0.061747532337903976,
        -0.0010098426137119532,
        -0.03626060485839844,
        0.029096877202391624,
        -0.03920692950487137,
        0.04753342643380165,
        -0.04871528223156929,
        -0.0028457806911319494,
        0.05215832218527794,
        -0.06423179060220718,
        0.04481484740972519,
        -0.038225676864385605,
        -0.017875539138913155,
        -0.01191216241568327,
        0.021294882521033287,
        -0.04133503511548042,
        0.03915342688560486,
        -0.040113769471645355,
        0.01017747726291418,
        -0.06691829860210419,
        -0.013861006125807762,
        0.03897048160433769,
        0.008687544614076614,
        0.004141656681895256,
        -0.01659921556711197,
        0.04718747362494469,
        -0.062339454889297485,
        0.0711568221449852,
        0.05836282670497894,
        0.05850872024893761,
        0.05582890287041664,
        -0.06868704408407211,
        -0.06868575513362885,
        0.03513585403561592,
        0.06939318031072617,
        -0.006379945669323206,
        -0.04908955097198486,
        -0.06286096572875977,
        -0.03334973752498627,
        -0.009049526415765285,
        -0.06486809253692627,
        -0.03563163802027702,
        -0.05660972371697426,
        -0.04300271347165108,
        -0.01601949706673622,
        0.06568604707717896,
        0.02420506812632084,
        0.0400550402700901,
        0.023934854194521904,
        -0.052941326051950455,
        0.01778974011540413,
        0.04664377495646477,
        -0.003934468608349562,
        -0.06720961630344391,
        -0.03944719582796097,
        0.020051266998052597,
        -0.03878292441368103,
        -0.021371830254793167,
        0.05776699632406235,
        0.020286040380597115,
        -0.05977075546979904,
        -0.0669945478439331,
        0.06524395942687988,
        -0.041975170373916626,
        0.059139881283044815,
        -0.014852069318294525,
        -0.053840529173612595,
        -0.05760933458805084,
        0.03152445703744888,
        0.04579727724194527,
        -0.016126036643981934,
        -0.04781482368707657,
        -0.04038664326071739,
        -0.03226631507277489,
        -0.014317844063043594,
        0.05714651197195053,
        0.04773653298616409,
        -0.056142646819353104,
        0.02727186493575573,
        -0.05268804356455803,
        0.06759847700595856,
        -0.014399155974388123,
        -0.045077599585056305,
        -0.018744993954896927,
        0.05639108270406723,
        -0.03557031601667404,
        -0.050656065344810486,
        -0.04141605645418167,
        -0.03279631584882736,
        -0.035237736999988556,
        0.03102717734873295,
        -0.009691769257187843,
        0.03861285001039505,
        0.0445774681866169,
        0.020216569304466248,
        0.003982776775956154,
        0.05090903118252754,
        0.05625198781490326,
        0.05557909235358238,
        0.020537419244647026,
        -0.05060431361198425,
        -0.02791615203022957,
        -0.03199160099029541,
        -0.00905625056475401,
        0.006315687205642462,
        -0.06630819290876389,
        0.04502236098051071,
        0.009361499920487404,
        -0.004688371904194355,
        -0.038013752549886703,
        -0.05142241343855858,
        -0.049991585314273834,
        -0.032418057322502136,
        0.01123487763106823,
        -0.010686365887522697,
        0.06836320459842682,
        0.028849055990576744,
        0.026765137910842896,
        -0.061056096106767654,
        -0.04184379056096077,
        0.024160044267773628,
        0.05354427173733711,
        0.021086009219288826,
        -0.018910221755504608,
        -0.0647381842136383,
        -0.019191406667232513,
        0.06685161590576172,
        0.01474300678819418,
        0.044219084084033966,
        -0.04706742614507675,
        -0.01945069059729576,
        -0.0616411417722702,
        -0.050477586686611176,
        0.025934193283319473,
        0.055726852267980576,
        -0.0020125762093812227,
        0.04510745406150818,
        0.04957716539502144,
        -0.05617052689194679,
        -0.02089592069387436,
        -0.05854756012558937,
        0.03651623800396919,
        0.015650970861315727,
        -0.03683247044682503,
        -0.0485762394964695,
        -0.00967817846685648,
        0.025286436080932617,
        0.044046711176633835,
        0.04876022785902023,
        0.05746816471219063,
        -0.04731162637472153,
        -0.008539841510355473,
        -0.04819286987185478,
        0.05110067129135132,
        -0.00735144829377532,
        -0.023045316338539124,
        0.06994261592626572,
        0.02039736695587635,
        -0.020852243527770042,
        -0.05151364207267761,
        0.06311000883579254,
        -0.00019473873544484377
    ],
    [
        -0.052255868911743164,
        0.03716115653514862,
        0.05669427290558815,
        0.04849754646420479,
        -0.05676234886050224,
        0.06821545958518982,
        -0.024644672870635986,
        -0.06187841296195984,
        0.06583492457866669,
        0.029063386842608452,
        0.04413475841283798,
        -0.04881873354315758,
        -0.02846195176243782,
        -0.027928750962018967,
        -0.06270083785057068,
        0.05553477630019188,
        -0.05252162739634514,
        0.06808070838451385,
        -0.013959171250462532,
        -0.06841343641281128,
        0.05423479154706001,
        0.06574878096580505,
        0.03150544688105583,
        -0.009980195201933384,
        0.05820920318365097,
        0.047805801033973694,
        0.02343420684337616,
        0.006184925325214863,
        0.002843576017767191,
        0.04703465849161148,
        0.05083512142300606,
        -0.01834031194448471,
        -0.017452750355005264,
        -0.06654468178749084,
        0.06065444275736809,
        0.042062580585479736,
        -0.06577888876199722,
        -0.01269934605807066,
        0.05966442450881004,
        0.03396391496062279,
        0.0646870881319046,
        0.033073700964450836,
        0.02450854890048504,
        -0.05511081591248512,
        0.06319820880889893,
        0.04819275066256523,
        -0.04814464598894119,
        0.060169897973537445,
        0.05839032307267189,
        0.03706233203411102,
        0.04668039083480835,
        0.06212848052382469,
        -0.057298094034194946,
        0.06298321485519409,
        0.02879820391535759,
        -0.007168011274188757,
        0.019627301022410393,
        0.06581937521696091,
        -0.0272842925041914,
        -0.04331614077091217,
        -0.055006273090839386,
        0.006031227298080921,
        -0.047532543540000916,
        0.06530673056840897,
        -0.06058318912982941,
        -0.05654001608490944,
        -0.03392139449715614,
        -0.05198334529995918,
        -0.06179768219590187,
        0.013727831654250622,
        -0.049847446382045746,
        -0.06801754236221313,
        0.062405284494161606,
        0.058654576539993286,
        -0.005014740861952305,
        -0.01094940584152937,
        -0.03160722926259041,
        0.06778734177350998,
        0.06777582317590714,
        -0.02524569258093834,
        0.016503406688570976,
        0.05372987315058708,
        -0.06330577284097672,
        0.05677545815706253,
        0.05554313212633133,
        0.055091843008995056,
        0.06523018330335617,
        0.06709558516740799,
        0.04537232965230942,
        -0.017779981717467308,
        -0.046826206147670746,
        -0.0476212352514267,
        -0.06766786426305771,
        -0.00040071617695502937,
        -0.04278561472892761,
        0.04542220011353493,
        -0.017313700169324875,
        -0.06330695003271103,
        0.012097409926354885,
        0.047798171639442444,
        -0.054715272039175034,
        0.04247269779443741,
        0.004239203408360481,
        -0.03352741897106171,
        -0.005326885264366865,
        -0.06560484319925308,
        -0.03249359130859375,
        0.04916141927242279,
        0.041483134031295776,
        0.05976194888353348,
        -0.06133874133229256,
        0.011837609112262726,
        -0.065792977809906,
        0.020057974383234978,
        -0.04359214007854462,
        -0.06070270389318466,
        0.043818145990371704,
        -0.03844975307583809,
        0.06242065876722336,
        0.01419790182262659,
        -0.040781423449516296,
        0.05700099840760231,
        0.04110652953386307,
        0.014639993198215961,
        -0.02640658989548683,
        0.018355373293161392,
        -0.06376201659440994,
        -0.020437143743038177,
        0.052813731133937836,
        -0.007139522582292557,
        -0.036631990224123,
        -0.027996176853775978,
        0.002902667736634612,
        0.005872668698430061,
        -0.04348091036081314,
        -0.052315764129161835,
        0.03380203992128372,
        0.016022197902202606,
        -0.05973147228360176,
        -0.009196313098073006,
        0.04840435832738876,
        -0.02271590381860733,
        0.0628352090716362,
        -0.009341094642877579,
        0.054037854075431824,
        -0.06789897382259369,
        0.05893067643046379,
        -0.037868015468120575,
        -0.038804635405540466,
        0.020297259092330933,
        0.06322374194860458,
        -0.057648587971925735,
        -0.0155178252607584,
        -0.050396207720041275,
        0.06388695538043976,
        -0.053669799119234085,
        -0.006137718912214041,
        0.035575591027736664,
        -0.03156164288520813,
        0.04580239579081535,
        0.06833410263061523,
        -0.0012581590563058853,
        0.04970364272594452,
        0.04870670661330223,
        0.009087112732231617,
        -0.019876694306731224,
        0.012037349864840508,
        0.024779830127954483,
        -0.02382071316242218,
        -0.0135140810161829,
        -0.015089095570147038,
        0.03268243744969368,
        -0.04058182239532471,
        -0.05960202217102051,
        -0.02249564602971077,
        0.06164739280939102,
        -0.030303407460451126,
        -0.007798903621733189,
        0.03349872678518295,
        -0.059952158480882645,
        0.01144453976303339,
        0.06782644987106323,
        0.011890017427504063,
        0.03351648151874542,
        0.04094512760639191,
        -0.019640475511550903,
        -0.03200328350067139,
        0.05668441578745842,
        -0.01799704320728779,
        -0.03347684442996979,
        -0.05529894307255745,
        0.016843991354107857,
        0.028150973841547966,
        0.001394743099808693,
        -0.0289223063737154,
        0.03812970593571663,
        0.04897134751081467,
        -0.06770890206098557,
        -0.0016460310434922576,
        -0.018184281885623932,
        0.0627577006816864,
        -0.054352667182683945,
        0.04039850831031799,
        -0.031457677483558655,
        -0.05052854120731354,
        -0.0153356296941638,
        0.04421760141849518,
        -0.00534557132050395,
        -0.0448211245238781,
        0.06487827003002167,
        -0.05901309475302696,
        0.04786854609847069,
        -0.016908293589949608,
        0.02874409221112728,
        -0.02290734089910984,
        0.024323374032974243,
        0.032616086304187775,
        -0.045004405081272125,
        0.0640324056148529,
        0.011746395379304886,
        0.022485582157969475,
        0.04428703337907791,
        0.030113454908132553,
        0.018088027834892273,
        0.06661123037338257,
        -0.036428302526474,
        -0.016582805663347244,
        0.01361278910189867,
        -0.04791974648833275,
        -0.05500510707497597,
        -0.01682330295443535,
        -0.06156355142593384,
        -0.028171014040708542,
        0.039026420563459396,
        0.013846571557223797,
        0.04967928305268288,
        0.06395086646080017,
        -0.04320686310529709,
        0.040110960602760315,
        0.06517623364925385,
        -0.05091378465294838,
        0.04796995222568512,
        -0.0649551972746849,
        0.03964591026306152,
        0.033159416168928146,
        -0.0223191250115633,
        -0.06387095898389816,
        0.009216146543622017,
        0.05278974398970604,
        -0.015122298151254654,
        -0.04463540017604828,
        -0.058617740869522095,
        0.028417792171239853,
        -0.06404443830251694,
        0.003185710171237588,
        0.06459721177816391,
        -0.026532873511314392,
        -0.05672531574964523,
        -0.003085572039708495,
        -0.016590198501944542,
        0.06473373621702194,
        -0.05238909646868706,
        -0.03517814353108406,
        0.030503980815410614,
        0.013861577957868576,
        0.06005580723285675,
        -0.04517051577568054,
        0.015092003159224987,
        -0.007486697286367416,
        0.06385684758424759,
        0.05449219420552254,
        -0.057367872446775436,
        0.06798547506332397,
        -0.053730614483356476,
        -0.04066647216677666,
        0.05448324978351593,
        -0.0319889560341835,
        -0.013592729344964027,
        -0.05441225692629814,
        -0.015717511996626854,
        -0.05572180077433586,
        0.03037489950656891,
        -0.06692442297935486,
        0.04967919737100601,
        0.05662498623132706,
        -0.057040657848119736,
        0.04207253456115723,
        0.06583084911108017,
        0.06411398947238922,
        0.04968558996915817,
        -0.057561397552490234,
        -0.032684314996004105,
        0.03385496884584427,
        0.04463675990700722,
        0.06218710169196129,
        -0.06620463728904724,
        0.056260596960783005,
        0.05896551162004471,
        -0.06656334549188614,
        0.054671574383974075,
        0.06140362098813057,
        -0.03998535871505737,
        -0.0415787398815155,
        0.05502784624695778,
        -0.06380046904087067,
        0.00470094894990325,
        -0.057631511241197586,
        0.06041783466935158,
        -0.02052425965666771,
        -0.044117271900177,
        0.026814715936779976,
        -0.0008482743869535625,
        -0.03955993801355362,
        0.015736399218440056,
        0.03285684809088707,
        -0.06164390966296196,
        -0.042194124311208725,
        -0.04022130370140076,
        0.03291429579257965,
        -0.027265196666121483,
        0.02438095025718212,
        0.02459958754479885,
        0.02414017729461193,
        0.043790291994810104,
        0.05736752226948738,
        -0.013039542362093925,
        -0.05070716515183449,
        -0.028468521311879158,
        -0.055430494248867035,
        -0.049946099519729614,
        0.01915278472006321,
        0.045452795922756195,
        -0.042125314474105835,
        -0.026664186269044876,
        0.05806322023272514,
        -0.009789155796170235,
        -0.018237119540572166,
        0.06434570252895355,
        -0.043171241879463196,
        0.05271272733807564,
        -0.05325276777148247,
        0.055219653993844986,
        -0.0453750342130661,
        -0.05411253869533539,
        -0.03419702127575874,
        0.026982467621564865,
        0.02071896381676197,
        0.04849685728549957,
        -0.06310994923114777,
        0.06092630326747894,
        0.06340998411178589,
        0.016531767323613167,
        -0.051509127020835876,
        -0.024515165016055107,
        -0.04766574501991272,
        0.04646993428468704,
        -0.05003815516829491,
        -0.024206670001149178,
        0.05382710322737694,
        -0.06439336389303207,
        0.03912799060344696,
        -0.03840923681855202,
        0.03264307230710983,
        0.005326193291693926,
        -0.013335050083696842,
        -0.03610316291451454,
        0.06271497905254364,
        -0.02781841717660427,
        0.042616646736860275,
        -0.06688083708286285,
        -0.030989160761237144,
        0.03620174899697304,
        0.04153049737215042,
        -0.021163081750273705,
        0.014060105197131634,
        0.044696420431137085,
        -0.06350903958082199,
        0.06842628866434097,
        0.06107202172279358,
        0.06165042519569397,
        0.04128088429570198,
        -0.064869724214077,
        -0.06529933214187622,
        0.02291039004921913,
        0.06599098443984985,
        -0.005617245566099882,
        -0.034630145877599716,
        -0.0586530826985836,
        -0.015658767893910408,
        0.006406719330698252,
        -0.0673639178276062,
        -0.033155787736177444,
        -0.04800933226943016,
        -0.045362263917922974,
        0.014339267276227474,
        0.06416807323694229,
        0.03480231389403343,
        0.020678138360381126,
        0.03769880533218384,
        -0.06280229985713959,
        -0.004018167965114117,
        0.05360019579529762,
        0.01667270064353943,
        -0.06498213112354279,
        -0.04642105847597122,
        0.0019994466565549374,
        -0.017771324142813683,
        -0.028136461973190308,
        0.050458408892154694,
        0.015319672413170338,
        -0.05471634119749069,
        -0.0626712292432785,
        0.0651983693242073,
        -0.013410726562142372,
        0.0557875819504261,
        -0.0327330157160759,
        -0.058717962354421616,
        -0.05272766575217247,
        0.03301021084189415,
        0.03964439034461975,
        0.02213864214718342,
        -0.04538832604885101,
        -0.03901985287666321,
        -0.04196890443563461,
        0.04801919683814049,
        0.02779960446059704,
        0.03606782481074333,
        -0.047191716730594635,
        0.007686791941523552,
        -0.05260416865348816,
        0.06669554114341736,
        -0.004366936162114143,
        -0.05808396264910698,
        -0.023316916078329086,
        0.04762890562415123,
        -0.046027641743421555,
        -0.026625515893101692,
        -0.05134452134370804,
        -0.019051333889365196,
        -0.012565793469548225,
        0.02158847637474537,
        0.01348841842263937,
        0.04629110172390938,
        0.05634614825248718,
        0.03192918375134468,
        0.005928752478212118,
        0.04831156134605408,
        0.039596762508153915,
        0.057180099189281464,
        0.02728140912950039,
        -0.05970604345202446,
        -0.010861778631806374,
        0.017889946699142456,
        0.004342018626630306,
        0.0010104277171194553,
        -0.06544354557991028,
        0.02132069319486618,
        0.025435717776417732,
        -0.00006407770706573501,
        -0.003521913895383477,
        -0.05638137087225914,
        -0.0576029010117054,
        -0.042826682329177856,
        0.0035024681128561497,
        0.031811926513910294,
        0.06701068580150604,
        0.008441165089607239,
        0.034334298223257065,
        -0.06623665243387222,
        -0.04946083202958107,
        0.0341566726565361,
        0.06463433057069778,
        0.01712542027235031,
        -0.003915667533874512,
        -0.06667722016572952,
        0.008325890637934208,
        0.06076466664671898,
        0.036494072526693344,
        0.035400789231061935,
        -0.051952626556158066,
        -0.034082747995853424,
        -0.06133263185620308,
        -0.05176405608654022,
        0.0316879004240036,
        0.04393143206834793,
        -0.0028906133957207203,
        0.048064470291137695,
        0.04724434018135071,
        -0.044423215091228485,
        -0.03677065297961235,
        -0.056823115795850754,
        0.03465273603796959,
        0.032975923269987106,
        -0.03744727373123169,
        -0.03275236487388611,
        -0.008417889475822449,
        0.006415965035557747,
        0.06074035167694092,
        0.04195290803909302,
        0.061004139482975006,
        -0.05456550419330597,
        -0.00491423299536109,
        -0.05210429057478905,
        0.04115885868668556,
        0.010179280303418636,
        -0.0205899216234684,
        0.0678262934088707,
        0.007169113960117102,
        -0.0296257846057415,
        -0.04474342614412308,
        0.05877801030874252,
        -0.006582452915608883
    ],
    [
        -0.056938692927360535,
        -0.06097747012972832,
        -0.042526725679636,
        0.0323924720287323,
        -0.05655179172754288,
        0.05968548357486725,
        0.051504649221897125,
        -0.037339989095926285,
        0.05592924728989601,
        -0.027716074138879776,
        0.05945262312889099,
        -0.057223841547966,
        -0.036836594343185425,
        0.01964803785085678,
        -0.05162598937749863,
        -0.02608918398618698,
        -0.032055556774139404,
        0.061068009585142136,
        -0.06101297214627266,
        -0.06076506897807121,
        -0.059042755514383316,
        -0.030741717666387558,
        0.053490713238716125,
        0.02055351808667183,
        -0.04254624620079994,
        0.05534544587135315,
        -0.00038044387474656105,
        0.05638623237609863,
        -0.03741120174527168,
        0.013759713619947433,
        0.053493794053792953,
        0.03590497747063637,
        0.056478749960660934,
        -0.060587819665670395,
        0.05349874123930931,
        -0.00945246871560812,
        -0.05993696302175522,
        0.025500750169157982,
        -0.060492679476737976,
        0.034307677298784256,
        0.05962488055229187,
        0.05227130651473999,
        0.04259004816412926,
        -0.061010994017124176,
        0.06102143973112106,
        -0.009185375645756721,
        -0.030319657176733017,
        0.06086007133126259,
        0.058970872312784195,
        -0.046472370624542236,
        0.04070636257529259,
        0.05370255559682846,
        -0.0023464313708245754,
        0.058527711778879166,
        -0.005809274036437273,
        0.05500213801860809,
        0.0017384991515427828,
        0.05657012015581131,
        -0.052742552012205124,
        0.00714645441621542,
        -0.04757034033536911,
        -0.03483724966645241,
        -0.05115887150168419,
        0.03649277240037918,
        -0.05431273952126503,
        -0.024980664253234863,
        -0.05236882343888283,
        -0.05626406520605087,
        0.05912983790040016,
        -0.06109035015106201,
        -0.033380769193172455,
        -0.059782724827528,
        0.05820615962147713,
        0.05989500507712364,
        0.029407944530248642,
        -0.04691591486334801,
        -0.06019319221377373,
        0.0611085407435894,
        0.05918923392891884,
        -0.05214422568678856,
        -0.005609744694083929,
        -0.04170086607336998,
        -0.06090938299894333,
        0.05081506818532944,
        -0.009458093903958797,
        0.04721742868423462,
        0.05939539149403572,
        0.06112538278102875,
        0.030936433002352715,
        0.01847599633038044,
        -0.04471559450030327,
        -0.03171839192509651,
        0.030556706711649895,
        -0.056253667920827866,
        -0.03020760789513588,
        -0.012869380414485931,
        0.004594986792653799,
        -0.04681950435042381,
        -0.04616886377334595,
        0.033457353711128235,
        -0.05118473991751671,
        0.020529817789793015,
        0.04259064421057701,
        -0.011087124235928059,
        -0.0271732360124588,
        -0.05993301793932915,
        -0.055220335721969604,
        0.014543180353939533,
        0.04375515133142471,
        0.0312533900141716,
        -0.05040712654590607,
        -0.03868432715535164,
        -0.03449072316288948,
        0.05662909895181656,
        0.04556775465607643,
        -0.03604380413889885,
        -0.06024627387523651,
        0.008670013397932053,
        -0.01264760922640562,
        0.0067509193904697895,
        -0.00407943781465292,
        -0.03961493447422981,
        0.04831499233841896,
        -0.06042138859629631,
        -0.030070465058088303,
        0.053042102605104446,
        -0.017840858548879623,
        0.05452892556786537,
        0.050786834210157394,
        0.04616667702794075,
        0.030512796714901924,
        -0.05274215713143349,
        -0.02792913094162941,
        0.044968560338020325,
        -0.006553043611347675,
        -0.05621836706995964,
        0.0572192408144474,
        -0.059608690440654755,
        -0.027737174183130264,
        0.045443493872880936,
        0.02111758477985859,
        0.014685515314340591,
        0.05684679374098778,
        0.05514654517173767,
        -0.059586793184280396,
        -0.05984760820865631,
        -0.051407575607299805,
        -0.023913994431495667,
        -0.054526664316654205,
        -0.02783610299229622,
        -0.0576365701854229,
        -0.052273739129304886,
        0.013387152925133705,
        -0.030488738790154457,
        0.05712158605456352,
        -0.021849391981959343,
        -0.028423672541975975,
        -0.007701524533331394,
        0.04016103968024254,
        0.0323532409965992,
        0.06111900880932808,
        -0.021381046622991562,
        0.05807418003678322,
        -0.03541344404220581,
        0.006424048915505409,
        0.013919771648943424,
        0.00996796227991581,
        -0.059169307351112366,
        -0.037319306284189224,
        0.021729616448283195,
        0.05097851902246475,
        0.04634089767932892,
        -0.05265543982386589,
        0.056777223944664,
        -0.0603124275803566,
        0.018211061134934425,
        -0.06080343946814537,
        -0.04172815382480621,
        0.047893065959215164,
        -0.05180516093969345,
        -0.04208911955356598,
        0.0608765073120594,
        0.041231513023376465,
        -0.019206605851650238,
        -0.04720095172524452,
        -0.0314096063375473,
        0.04500879347324371,
        0.05022023618221283,
        0.06003814563155174,
        0.046012770384550095,
        -0.04858332872390747,
        -0.0029142776038497686,
        0.054341450333595276,
        0.054703958332538605,
        -0.017486082389950752,
        -0.03281964734196663,
        0.06033538281917572,
        -0.06107335165143013,
        0.007696688640862703,
        0.05946195498108864,
        0.05883108451962471,
        -0.016371343284845352,
        0.0520385205745697,
        -0.027474014088511467,
        -0.008404630236327648,
        -0.05547236278653145,
        0.03395849093794823,
        -0.029462799429893494,
        -0.019508911296725273,
        0.055153634399175644,
        -0.053807489573955536,
        0.014436298981308937,
        -0.02241683565080166,
        0.058239661157131195,
        0.048473093658685684,
        -0.021222930401563644,
        0.0008007530705071986,
        0.005030928645282984,
        0.059846021234989166,
        0.0517631396651268,
        0.060734253376722336,
        -0.042732123285532,
        0.05928018316626549,
        -0.050886332988739014,
        0.059003666043281555,
        -0.0536973774433136,
        -0.036998242139816284,
        0.056529246270656586,
        -0.05281390622258186,
        -0.03179483115673065,
        -0.04645724967122078,
        -0.049491964280605316,
        -0.04798011854290962,
        0.03164063021540642,
        0.02261076681315899,
        0.05461470037698746,
        0.05720263719558716,
        0.028821149840950966,
        -0.05033900961279869,
        0.06088543310761452,
        -0.03756966069340706,
        -0.005569496192038059,
        0.04960178956389427,
        -0.05809413641691208,
        0.0412617102265358,
        -0.04015364870429039,
        -0.050681207329034805,
        0.03531434014439583,
        0.055340711027383804,
        0.0006256817141547799,
        -0.05350366234779358,
        -0.044741421937942505,
        -0.033383361995220184,
        -0.05897220969200134,
        0.0606904961168766,
        0.06076505035161972,
        0.00012772540503647178,
        -0.02343503199517727,
        0.0185655876994133,
        0.05323569476604462,
        0.04573434218764305,
        -0.05279137194156647,
        -0.0073713501915335655,
        0.05201828107237816,
        -0.045213330537080765,
        0.06081227958202362,
        -0.060673654079437256,
        0.019498297944664955,
        0.04001323878765106,
        0.058864299207925797,
        0.05485335364937782,
        -0.059245381504297256,
        0.060509324073791504,
        -0.06062285974621773,
        -0.05554547905921936,
        0.05638273060321808,
        -0.03896799683570862,
        -0.020467618480324745,
        0.02364702709019184,
        0.04428146779537201,
        -0.044352930039167404,
        0.0471315011382103,
        -0.03829840198159218,
        0.001431599142961204,
        0.05891325697302818,
        -0.05195586010813713,
        0.05658905953168869,
        -0.052492257207632065,
        -0.02283187210559845,
        0.055032480508089066,
        -0.06081029772758484,
        -0.02429242432117462,
        0.045676860958337784,
        0.0492408312857151,
        0.05227971076965332,
        -0.05881981551647186,
        0.05936262756586075,
        0.0018919900758191943,
        -0.04856279492378235,
        0.040836527943611145,
        -0.005497942678630352,
        -0.056676480919122696,
        -0.05615610256791115,
        -0.01563861034810543,
        -0.0590292364358902,
        -0.04403187707066536,
        -0.011471650563180447,
        0.03808809816837311,
        0.04529803618788719,
        0.048634301871061325,
        -0.019060207530856133,
        -0.007876886054873466,
        -0.008482212200760841,
        0.00484641594812274,
        -0.03300776332616806,
        -0.057018402963876724,
        -0.04499650001525879,
        -0.058381009846925735,
        0.04835091531276703,
        -0.029371146112680435,
        0.029539600014686584,
        0.047160275280475616,
        0.03305463120341301,
        -0.049578551203012466,
        0.014028708450496197,
        0.058373015373945236,
        0.04511697217822075,
        -0.0033146380446851254,
        -0.02558000013232231,
        -0.04898691549897194,
        -0.020052500069141388,
        -0.019413046538829803,
        -0.04182685911655426,
        -0.04566550627350807,
        0.05284437909722328,
        -0.05910471826791763,
        0.041352514177560806,
        0.05865044146776199,
        -0.060604989528656006,
        0.05294378101825714,
        -0.05593396723270416,
        0.04634876921772957,
        -0.01730920374393463,
        -0.021247318014502525,
        0.06109904870390892,
        0.04043126478791237,
        0.05868885666131973,
        0.05073363706469536,
        -0.05953758955001831,
        0.05517028272151947,
        -0.06109239533543587,
        0.045898646116256714,
        -0.0005605311598628759,
        -0.017880553379654884,
        -0.04832123592495918,
        0.037760257720947266,
        -0.06042902544140816,
        -0.029467377811670303,
        -0.03657379373908043,
        0.027649367228150368,
        0.002040565013885498,
        0.029022132977843285,
        0.03474622219800949,
        -0.05917295068502426,
        -0.048522770404815674,
        -0.05783214047551155,
        0.059740375727415085,
        -0.05538703873753548,
        -0.0071044801734387875,
        -0.060430243611335754,
        -0.016857294365763664,
        0.04258111119270325,
        -0.049314625561237335,
        -0.043674856424331665,
        0.04088658094406128,
        0.028217311948537827,
        -0.05540219321846962,
        0.06112335994839668,
        0.029578855261206627,
        0.02483311854302883,
        0.05510617792606354,
        -0.042372990399599075,
        -0.056953784078359604,
        -0.04428081586956978,
        0.05361536145210266,
        -0.058664433658123016,
        -0.017021378502249718,
        -0.03499255329370499,
        -0.031461428850889206,
        0.0046849483624100685,
        -0.04463549330830574,
        0.03227608650922775,
        -0.05363170802593231,
        -0.02573762834072113,
        -0.03068864718079567,
        0.05927487090229988,
        0.06052438169717789,
        -0.029560809955000877,
        -0.0544058158993721,
        -0.05244241654872894,
        -0.047000352293252945,
        0.025116868317127228,
        -0.018706519156694412,
        0.0349980965256691,
        -0.06100040301680565,
        0.05103220418095589,
        0.0045903208665549755,
        -0.006174849346280098,
        0.05744184926152229,
        -0.03212923929095268,
        -0.0338507778942585,
        -0.03377819433808327,
        0.04605122283101082,
        -0.048555027693510056,
        0.055894479155540466,
        0.03353852406144142,
        -0.05994055047631264,
        -0.060838572680950165,
        0.028950165957212448,
        -0.06055241823196411,
        0.030319849029183388,
        -0.05695253238081932,
        -0.02179466374218464,
        -0.059345267713069916,
        -0.001626676763407886,
        0.05742837116122246,
        0.03754359111189842,
        0.03228963911533356,
        -0.04042508453130722,
        -0.05180244892835617,
        -0.020779356360435486,
        -0.003986866679042578,
        -0.04447638988494873,
        -0.023519029840826988,
        -0.0051517123356461525,
        -0.0507160946726799,
        -0.05096932128071785,
        0.013046463020145893,
        -0.05589320883154869,
        -0.04599766060709953,
        -0.04828127101063728,
        -0.019059790298342705,
        0.013926099054515362,
        0.004213595297187567,
        0.06040879711508751,
        0.008697574958205223,
        0.05908960476517677,
        -0.04751551151275635,
        0.046970952302217484,
        -0.036592837423086166,
        0.05391738936305046,
        0.011043857783079147,
        -0.020894406363368034,
        -0.041304633021354675,
        -0.05779331922531128,
        -0.052636630833148956,
        0.04370465874671936,
        -0.01917317323386669,
        -0.04860465228557587,
        0.036650240421295166,
        -0.03575233370065689,
        -0.04871750622987747,
        0.007945879362523556,
        -0.01583169773221016,
        0.019985521212220192,
        0.06075774505734444,
        0.031802449375391006,
        0.05230552330613136,
        0.05085161700844765,
        -0.054338447749614716,
        -0.012573003768920898,
        0.03781561926007271,
        0.0456753745675087,
        -0.0581272691488266,
        -0.014517787843942642,
        -0.003604730824008584,
        0.058037254959344864,
        0.055376261472702026,
        0.01746346615254879,
        0.03379098325967789,
        -0.060201168060302734,
        0.03698062151670456,
        -0.01803097501397133,
        0.034098703414201736,
        0.033935900777578354,
        -0.05609950050711632,
        0.01920573227107525,
        0.05882827565073967,
        -0.05423469468951225,
        0.06061917170882225,
        -0.043305590748786926,
        -0.05909357964992523,
        0.056225672364234924,
        -0.05877790227532387,
        -0.02696777880191803,
        -0.013133333995938301,
        -0.047052133828401566,
        0.05871143192052841,
        0.042643655091524124,
        0.030506456270813942,
        0.060124944895505905,
        0.031845271587371826,
        -0.058668043464422226,
        0.03810794651508331,
        -0.011784203350543976,
        0.03939630836248398,
        0.05370105803012848,
        0.00566093297675252,
        -0.01351700909435749,
        0.03489666432142258,
        0.047188010066747665,
        -0.05238671228289604
    ],
    [
        -0.05329984799027443,
        0.04122718796133995,
        0.04615368694067001,
        0.04644909128546715,
        -0.054787542670965195,
        0.060137998312711716,
        0.05121293663978577,
        -0.05704283341765404,
        0.057759009301662445,
        -0.014245937578380108,
        0.05853020399808884,
        -0.04480535909533501,
        -0.04697928577661514,
        -0.04809227213263512,
        -0.05814191326498985,
        0.05873851478099823,
        0.022792553529143333,
        0.05992468446493149,
        0.013443337753415108,
        -0.060209207236766815,
        -0.018046017736196518,
        0.058365099132061005,
        0.04415622353553772,
        0.05108094960451126,
        0.020749511197209358,
        0.054800428450107574,
        -0.04290984943509102,
        0.05132351815700531,
        -0.05674112215638161,
        -0.0034226304851472378,
        -0.013249886222183704,
        0.026545396074652672,
        0.044593192636966705,
        -0.05644616857171059,
        0.05981063470244408,
        -0.03464541956782341,
        -0.05969161167740822,
        0.0282380823045969,
        0.04955512657761574,
        0.009979285299777985,
        0.05881953984498978,
        0.028002291917800903,
        0.051727212965488434,
        -0.04440845176577568,
        0.06016794592142105,
        -0.035467490553855896,
        -0.05355850234627724,
        0.05996761843562126,
        0.04908129945397377,
        -0.02864815853536129,
        0.05248847231268883,
        0.037184543907642365,
        0.004334450699388981,
        0.04631320759654045,
        0.03281741216778755,
        0.02802876941859722,
        0.02327863499522209,
        0.0566042959690094,
        -0.06006600335240364,
        -0.04398340359330177,
        -0.05612172558903694,
        -0.04961453750729561,
        -0.0559207983314991,
        0.05527162924408913,
        -0.03654361888766289,
        -0.05160929635167122,
        -0.04321111738681793,
        -0.05528401583433151,
        0.03191778063774109,
        0.010139100253582,
        -0.007548895664513111,
        -0.05299030989408493,
        0.057793520390987396,
        0.05996757373213768,
        -0.010114029981195927,
        -0.052375152707099915,
        -0.031231265515089035,
        0.06019660830497742,
        0.06017061695456505,
        -0.047667961567640305,
        -0.009788284078240395,
        -0.029674801975488663,
        -0.060128118842840195,
        0.03644087165594101,
        -0.058714576065540314,
        0.038074251264333725,
        0.060030773282051086,
        0.06021745130419731,
        0.013488104566931725,
        0.041656848043203354,
        -0.0388520173728466,
        -0.055903345346450806,
        -0.039057884365320206,
        -0.04314529895782471,
        -0.047912612557411194,
        0.020262639969587326,
        0.015243219211697578,
        -0.05786697193980217,
        -0.043028563261032104,
        0.046889565885066986,
        -0.047947145998477936,
        -0.005117450840771198,
        0.043317362666130066,
        -0.04914230480790138,
        0.0041383616626262665,
        -0.05949024111032486,
        -0.05975329130887985,
        -0.0009097416768781841,
        0.05305898189544678,
        -0.01425652951002121,
        -0.05472441017627716,
        -0.030628155916929245,
        -0.058446019887924194,
        0.05121711269021034,
        0.05335116758942604,
        -0.013052793219685555,
        -0.05164559930562973,
        0.030438566580414772,
        0.0020270643290132284,
        -0.04081152752041817,
        -0.03411842882633209,
        -0.05294206738471985,
        0.04557345435023308,
        -0.0549064464867115,
        -0.05385783687233925,
        0.037969235330820084,
        -0.045073963701725006,
        0.056004367768764496,
        0.05622010678052902,
        0.01861281506717205,
        -0.05880700796842575,
        -0.038808632642030716,
        0.010880311951041222,
        0.05547010898590088,
        -0.014882718212902546,
        -0.05355975404381752,
        0.04618791118264198,
        -0.0464760921895504,
        0.00843789055943489,
        -0.007590961642563343,
        -0.027066390961408615,
        -0.05010383576154709,
        0.05793258547782898,
        0.03645602986216545,
        -0.05605510249733925,
        -0.060137491673231125,
        0.037620410323143005,
        -0.04057789966464043,
        -0.02481251209974289,
        -0.04569723829627037,
        -0.04682933911681175,
        -0.056982289999723434,
        -0.03921258822083473,
        -0.040770791471004486,
        0.03124348632991314,
        -0.028598802164196968,
        -0.030710747465491295,
        0.03394851088523865,
        0.04621788486838341,
        -0.04629945755004883,
        0.06021851673722267,
        -0.03477277234196663,
        0.0575953908264637,
        0.05304459109902382,
        -0.0474325567483902,
        0.015722045674920082,
        -0.008362000808119774,
        -0.022582929581403732,
        -0.05728275328874588,
        0.027656082063913345,
        -0.01958361454308033,
        0.055586960166692734,
        -0.059614039957523346,
        0.05084286630153656,
        -0.05522918701171875,
        -0.039415787905454636,
        0.051098354160785675,
        -0.023935649544000626,
        0.05832188203930855,
        -0.05266956612467766,
        0.020493727177381516,
        0.06018573045730591,
        0.05067606642842293,
        -0.02361329086124897,
        0.015969447791576385,
        -0.03349696844816208,
        0.04116128385066986,
        0.055534154176712036,
        0.032402701675891876,
        -0.008926190435886383,
        -0.05876468867063522,
        -0.011935307644307613,
        0.04241276904940605,
        0.041448161005973816,
        -0.03672734275460243,
        0.05477382615208626,
        0.05989832058548927,
        -0.06021537259221077,
        0.051511045545339584,
        0.03111386112868786,
        0.05690423771739006,
        -0.034376997500658035,
        0.05481991916894913,
        -0.04926947131752968,
        -0.03977975994348526,
        -0.027632595971226692,
        0.05633546784520149,
        0.013453152030706406,
        -0.013948396779596806,
        0.055769357830286026,
        -0.053965456783771515,
        0.031175749376416206,
        0.005620728712528944,
        0.05232296511530876,
        -0.005491689778864384,
        -0.03369225189089775,
        0.0431494414806366,
        -0.0352778322994709,
        0.05638156458735466,
        -0.023179208859801292,
        0.05873321741819382,
        0.059454478323459625,
        0.05990474298596382,
        -0.04791220650076866,
        0.05567022040486336,
        -0.05224001780152321,
        -0.03504284471273422,
        0.03576751798391342,
        -0.059768252074718475,
        -0.059305589646101,
        -0.05915034934878349,
        0.0011677920119836926,
        -0.05941086262464523,
        0.04508903622627258,
        0.026229877024888992,
        0.04479352757334709,
        0.05803394317626953,
        -0.028092747554183006,
        0.04401714354753494,
        0.0595996268093586,
        -0.031254641711711884,
        -0.01659640111029148,
        0.014454838819801807,
        -0.05802202224731445,
        0.053984057158231735,
        0.003093151142820716,
        -0.05574095994234085,
        0.0037229694426059723,
        0.059926338493824005,
        -0.03279915079474449,
        -0.04979052394628525,
        -0.05491039529442787,
        -0.010454506613314152,
        -0.05659055337309837,
        0.0584564246237278,
        0.057794373482465744,
        -0.00806155614554882,
        -0.0367874875664711,
        0.05469183251261711,
        0.0031982949003577232,
        0.05980197712779045,
        -0.04701768979430199,
        -0.050329867750406265,
        0.05151505768299103,
        -0.046577002853155136,
        0.05965837091207504,
        0.010711804032325745,
        0.03641669824719429,
        0.04540795087814331,
        0.05792504921555519,
        0.05729053169488907,
        -0.03500059247016907,
        0.053360309451818466,
        -0.058195389807224274,
        0.012228368781507015,
        0.05855947732925415,
        -0.0476522222161293,
        -0.050150446593761444,
        -0.022954171523451805,
        0.021388478577136993,
        -0.05406928434967995,
        0.05786706879734993,
        -0.05116336792707443,
        0.02371813915669918,
        0.057291023433208466,
        -0.05342964082956314,
        0.02772551216185093,
        0.058574747294187546,
        0.008789323270320892,
        0.0537978820502758,
        -0.05491097643971443,
        0.029312847182154655,
        0.04929819330573082,
        0.022030772641301155,
        0.03343328833580017,
        -0.05907770246267319,
        0.059874527156353,
        0.0390373095870018,
        -0.05768069997429848,
        0.055368825793266296,
        0.04084482043981552,
        -0.05109081044793129,
        -0.053830668330192566,
        0.05064566433429718,
        -0.05532513186335564,
        0.007829212583601475,
        -0.03232802450656891,
        -0.008356010541319847,
        -0.0066182371228933334,
        -0.04095263406634331,
        -0.015955856069922447,
        -0.03891001269221306,
        0.015441427007317543,
        0.014692528173327446,
        -0.05615796521306038,
        -0.0597294345498085,
        -0.04618021100759506,
        -0.05127360299229622,
        0.054394666105508804,
        -0.029106274247169495,
        0.054149966686964035,
        0.05907929688692093,
        0.05773767828941345,
        -0.03955438733100891,
        0.043333251029253006,
        0.05276922136545181,
        0.05304639786481857,
        -0.058081746101379395,
        -0.04234397038817406,
        -0.05888861045241356,
        0.05274954438209534,
        0.02344846911728382,
        -0.057554759085178375,
        -0.04415940120816231,
        0.05559079721570015,
        -0.003215546254068613,
        0.03054150938987732,
        0.05849871411919594,
        -0.057875752449035645,
        0.05112573131918907,
        -0.05385393276810646,
        -0.004064188804477453,
        0.039456553757190704,
        -0.04182305186986923,
        0.0360531359910965,
        0.046716365963220596,
        0.04328712075948715,
        0.05891897529363632,
        -0.05962950736284256,
        0.05931501090526581,
        0.05865379795432091,
        0.0023574919905513525,
        0.00047613176866434515,
        -0.02721702866256237,
        -0.047513432800769806,
        0.043406277894973755,
        -0.05910225957632065,
        -0.0228958111256361,
        -0.04364979267120361,
        0.02639530412852764,
        0.045816946774721146,
        0.0445094034075737,
        -0.03751523792743683,
        -0.052627187222242355,
        -0.05077117681503296,
        -0.056510575115680695,
        0.05943289399147034,
        -0.04370136186480522,
        -0.04150878265500069,
        -0.059318579733371735,
        0.04183994233608246,
        0.030284181237220764,
        -0.02467256970703602,
        -0.041378509253263474,
        0.01523925643414259,
        0.05795365571975708,
        -0.0384422168135643,
        0.060219310224056244,
        0.056041061878204346,
        0.04143927991390228,
        0.04922444000840187,
        -0.03149602562189102,
        -0.053167786449193954,
        0.01244468241930008,
        0.05955914407968521,
        -0.055055953562259674,
        -0.00989411398768425,
        -0.056572217494249344,
        -0.050260670483112335,
        -0.006573533173650503,
        -0.044098861515522,
        -0.015397910960018635,
        -0.055949751287698746,
        -0.04986288771033287,
        0.035930272191762924,
        0.06001980975270271,
        0.059815652668476105,
        -0.055684804916381836,
        -0.05931597203016281,
        -0.055607039481401443,
        0.024089958518743515,
        0.017355263233184814,
        -0.019740741699934006,
        -0.03194139152765274,
        -0.04424862191081047,
        0.040568992495536804,
        -0.014317173510789871,
        -0.03149018436670303,
        0.05816274508833885,
        0.00023963834973983467,
        -0.054049134254455566,
        -0.059568796306848526,
        0.02266608364880085,
        -0.05359256640076637,
        0.05791004002094269,
        0.004277098923921585,
        -0.05660904198884964,
        -0.059971392154693604,
        0.045292261987924576,
        0.02656874805688858,
        -0.02266121283173561,
        -0.057500727474689484,
        -0.050896283239126205,
        -0.059916723519563675,
        -0.05481795221567154,
        0.05570431798696518,
        0.008781424723565578,
        0.011009053327143192,
        -0.025190729647874832,
        -0.053602349013090134,
        0.04311384633183479,
        -0.012582005001604557,
        0.04753148928284645,
        0.03692636638879776,
        0.01809273473918438,
        -0.02118515968322754,
        -0.054347019642591476,
        -0.015213549137115479,
        -0.0447813905775547,
        -0.05761997401714325,
        -0.04946228861808777,
        0.007949772290885448,
        0.059363313019275665,
        0.04989643022418022,
        -0.03559661656618118,
        0.024729415774345398,
        0.058858878910541534,
        0.04728753864765167,
        -0.007536778692156076,
        -0.01857919804751873,
        -0.016357794404029846,
        0.026286540552973747,
        -0.04491420090198517,
        -0.03746774420142174,
        0.036437299102544785,
        -0.05568765103816986,
        0.05375293642282486,
        -0.0386231429874897,
        0.050055909901857376,
        0.046661488711833954,
        0.0038780388422310352,
        -0.057280879467725754,
        -0.012949042953550816,
        0.021980758756399155,
        0.0028386160265654325,
        0.059791047126054764,
        -0.023829711601138115,
        0.03362087905406952,
        -0.05233079567551613,
        -0.05224279686808586,
        0.013902056030929089,
        -0.028187425807118416,
        0.056097447872161865,
        -0.041905902326107025,
        0.007002749014645815,
        0.0177636556327343,
        0.05161510035395622,
        0.056434936821460724,
        0.03875536099076271,
        0.033732302486896515,
        -0.03200663626194,
        0.022702239453792572,
        -0.027156412601470947,
        0.04467156156897545,
        0.057285044342279434,
        -0.0358869805932045,
        0.04568784683942795,
        0.04969954490661621,
        -0.05742752552032471,
        0.020033111795783043,
        -0.03312887251377106,
        -0.023141246289014816,
        0.058016061782836914,
        -0.018056167289614677,
        -0.04253528267145157,
        0.02488931082189083,
        -0.04961869493126869,
        -0.04726611077785492,
        0.05330273136496544,
        0.015714749693870544,
        -0.04468604177236557,
        0.0006053573451936245,
        -0.05894780158996582,
        0.054360948503017426,
        0.04835369437932968,
        0.03959692269563675,
        0.060106631368398666,
        -0.014220614917576313,
        0.005576943978667259,
        -0.005372141022235155,
        0.059606216847896576,
        -0.02344977669417858
    ],
    [
        -0.05329984799027443,
        0.04122718796133995,
        0.04615368694067001,
        0.04644909128546715,
        -0.054787542670965195,
        0.060137998312711716,
        0.05121293663978577,
        -0.05704283341765404,
        0.057759009301662445,
        -0.014245937578380108,
        0.05853020399808884,
        -0.04480535909533501,
        -0.04697928577661514,
        -0.04809227213263512,
        -0.05814191326498985,
        0.05873851478099823,
        0.022792553529143333,
        0.05992468446493149,
        0.013443337753415108,
        -0.060209207236766815,
        -0.018046017736196518,
        0.058365099132061005,
        0.04415622353553772,
        0.05108094960451126,
        0.020749511197209358,
        0.054800428450107574,
        -0.04290984943509102,
        0.05132351815700531,
        -0.05674112215638161,
        -0.0034226304851472378,
        -0.013249886222183704,
        0.026545396074652672,
        0.044593192636966705,
        -0.05644616857171059,
        0.05981063470244408,
        -0.03464541956782341,
        -0.05969161167740822,
        0.0282380823045969,
        0.04955512657761574,
        0.009979285299777985,
        0.05881953984498978,
        0.028002291917800903,
        0.051727212965488434,
        -0.04440845176577568,
        0.06016794592142105,
        -0.035467490553855896,
        -0.05355850234627724,
        0.05996761843562126,
        0.04908129945397377,
        -0.02864815853536129,
        0.05248847231268883,
        0.037184543907642365,
        0.004334450699388981,
        0.04631320759654045,
        0.03281741216778755,
        0.02802876941859722,
        0.02327863499522209,
        0.0566042959690094,
        -0.06006600335240364,
        -0.04398340359330177,
        -0.05612172558903694,
        -0.04961453750729561,
        -0.0559207983314991,
        0.05527162924408913,
        -0.03654361888766289,
        -0.05160929635167122,
        -0.04321111738681793,
        -0.05528401583433151,
        0.03191778063774109,
        0.010139100253582,
        -0.007548895664513111,
        -0.05299030989408493,
        0.057793520390987396,
        0.05996757373213768,
        -0.010114029981195927,
        -0.052375152707099915,
        -0.031231265515089035,
        0.06019660830497742,
        0.06017061695456505,
        -0.047667961567640305,
        -0.009788284078240395,
        -0.029674801975488663,
        -0.060128118842840195,
        0.03644087165594101,
        -0.058714576065540314,
        0.038074251264333725,
        0.060030773282051086,
        0.06021745130419731,
        0.013488104566931725,
        0.041656848043203354,
        -0.0388520173728466,
        -0.055903345346450806,
        -0.039057884365320206,
        -0.04314529895782471,
        -0.047912612557411194,
        0.020262639969587326,
        0.015243219211697578,
        -0.05786697193980217,
        -0.043028563261032104,
        0.046889565885066986,
        -0.047947145998477936,
        -0.005117450840771198,
        0.043317362666130066,
        -0.04914230480790138,
        0.0041383616626262665,
        -0.05949024111032486,
        -0.05975329130887985,
        -0.0009097416768781841,
        0.05305898189544678,
        -0.01425652951002121,
        -0.05472441017627716,
        -0.030628155916929245,
        -0.058446019887924194,
        0.05121711269021034,
        0.05335116758942604,
        -0.013052793219685555,
        -0.05164559930562973,
        0.030438566580414772,
        0.0020270643290132284,
        -0.04081152752041817,
        -0.03411842882633209,
        -0.05294206738471985,
        0.04557345435023308,
        -0.0549064464867115,
        -0.05385783687233925,
        0.037969235330820084,
        -0.045073963701725006,
        0.056004367768764496,
        0.05622010678052902,
        0.01861281506717205,
        -0.05880700796842575,
        -0.038808632642030716,
        0.010880311951041222,
        0.05547010898590088,
        -0.014882718212902546,
        -0.05355975404381752,
        0.04618791118264198,
        -0.0464760921895504,
        0.00843789055943489,
        -0.007590961642563343,
        -0.027066390961408615,
        -0.05010383576154709,
        0.05793258547782898,
        0.03645602986216545,
        -0.05605510249733925,
        -0.060137491673231125,
        0.037620410323143005,
        -0.04057789966464043,
        -0.02481251209974289,
        -0.04569723829627037,
        -0.04682933911681175,
        -0.056982289999723434,
        -0.03921258822083473,
        -0.040770791471004486,
        0.03124348632991314,
        -0.028598802164196968,
        -0.030710747465491295,
        0.03394851088523865,
        0.04621788486838341,
        -0.04629945755004883,
        0.06021851673722267,
        -0.03477277234196663,
        0.0575953908264637,
        0.05304459109902382,
        -0.0474325567483902,
        0.015722045674920082,
        -0.008362000808119774,
        -0.022582929581403732,
        -0.05728275328874588,
        0.027656082063913345,
        -0.01958361454308033,
        0.055586960166692734,
        -0.059614039957523346,
        0.05084286630153656,
        -0.05522918701171875,
        -0.039415787905454636,
        0.051098354160785675,
        -0.023935649544000626,
        0.05832188203930855,
        -0.05266956612467766,
        0.020493727177381516,
        0.06018573045730591,
        0.05067606642842293,
        -0.02361329086124897,
        0.015969447791576385,
        -0.03349696844816208,
        0.04116128385066986,
        0.055534154176712036,
        0.032402701675891876,
        -0.008926190435886383,
        -0.05876468867063522,
        -0.011935307644307613,
        0.04241276904940605,
        0.041448161005973816,
        -0.03672734275460243,
        0.05477382615208626,
        0.05989832058548927,
        -0.06021537259221077,
        0.051511045545339584,
        0.03111386112868786,
        0.05690423771739006,
        -0.034376997500658035,
        0.05481991916894913,
        -0.04926947131752968,
        -0.03977975994348526,
        -0.027632595971226692,
        0.05633546784520149,
        0.013453152030706406,
        -0.013948396779596806,
        0.055769357830286026,
        -0.053965456783771515,
        0.031175749376416206,
        0.005620728712528944,
        0.05232296511530876,
        -0.005491689778864384,
        -0.03369225189089775,
        0.0431494414806366,
        -0.0352778322994709,
        0.05638156458735466,
        -0.023179208859801292,
        0.05873321741819382,
        0.059454478323459625,
        0.05990474298596382,
        -0.04791220650076866,
        0.05567022040486336,
        -0.05224001780152321,
        -0.03504284471273422,
        0.03576751798391342,
        -0.059768252074718475,
        -0.059305589646101,
        -0.05915034934878349,
        0.0011677920119836926,
        -0.05941086262464523,
        0.04508903622627258,
        0.026229877024888992,
        0.04479352757334709,
        0.05803394317626953,
        -0.028092747554183006,
        0.04401714354753494,
        0.0595996268093586,
        -0.031254641711711884,
        -0.01659640111029148,
        0.014454838819801807,
        -0.05802202224731445,
        0.053984057158231735,
        0.003093151142820716,
        -0.05574095994234085,
        0.0037229694426059723,
        0.059926338493824005,
        -0.03279915079474449,
        -0.04979052394628525,
        -0.05491039529442787,
        -0.010454506613314152,
        -0.05659055337309837,
        0.0584564246237278,
        0.057794373482465744,
        -0.00806155614554882,
        -0.0367874875664711,
        0.05469183251261711,
        0.0031982949003577232,
        0.05980197712779045,
        -0.04701768979430199,
        -0.050329867750406265,
        0.05151505768299103,
        -0.046577002853155136,
        0.05965837091207504,
        0.010711804032325745,
        0.03641669824719429,
        0.04540795087814331,
        0.05792504921555519,
        0.05729053169488907,
        -0.03500059247016907,
        0.053360309451818466,
        -0.058195389807224274,
        0.012228368781507015,
        0.05855947732925415,
        -0.0476522222161293,
        -0.050150446593761444,
        -0.022954171523451805,
        0.021388478577136993,
        -0.05406928434967995,
        0.05786706879734993,
        -0.05116336792707443,
        0.02371813915669918,
        0.057291023433208466,
        -0.05342964082956314,
        0.02772551216185093,
        0.058574747294187546,
        0.008789323270320892,
        0.0537978820502758,
        -0.05491097643971443,
        0.029312847182154655,
        0.04929819330573082,
        0.022030772641301155,
        0.03343328833580017,
        -0.05907770246267319,
        0.059874527156353,
        0.0390373095870018,
        -0.05768069997429848,
        0.055368825793266296,
        0.04084482043981552,
        -0.05109081044793129,
        -0.053830668330192566,
        0.05064566433429718,
        -0.05532513186335564,
        0.007829212583601475,
        -0.03232802450656891,
        -0.008356010541319847,
        -0.0066182371228933334,
        -0.04095263406634331,
        -0.015955856069922447,
        -0.03891001269221306,
        0.015441427007317543,
        0.014692528173327446,
        -0.05615796521306038,
        -0.0597294345498085,
        -0.04618021100759506,
        -0.05127360299229622,
        0.054394666105508804,
        -0.029106274247169495,
        0.054149966686964035,
        0.05907929688692093,
        0.05773767828941345,
        -0.03955438733100891,
        0.043333251029253006,
        0.05276922136545181,
        0.05304639786481857,
        -0.058081746101379395,
        -0.04234397038817406,
        -0.05888861045241356,
        0.05274954438209534,
        0.02344846911728382,
        -0.057554759085178375,
        -0.04415940120816231,
        0.05559079721570015,
        -0.003215546254068613,
        0.03054150938987732,
        0.05849871411919594,
        -0.057875752449035645,
        0.05112573131918907,
        -0.05385393276810646,
        -0.004064188804477453,
        0.039456553757190704,
        -0.04182305186986923,
        0.0360531359910965,
        0.046716365963220596,
        0.04328712075948715,
        0.05891897529363632,
        -0.05962950736284256,
        0.05931501090526581,
        0.05865379795432091,
        0.0023574919905513525,
        0.00047613176866434515,
        -0.02721702866256237,
        -0.047513432800769806,
        0.043406277894973755,
        -0.05910225957632065,
        -0.0228958111256361,
        -0.04364979267120361,
        0.02639530412852764,
        0.045816946774721146,
        0.0445094034075737,
        -0.03751523792743683,
        -0.052627187222242355,
        -0.05077117681503296,
        -0.056510575115680695,
        0.05943289399147034,
        -0.04370136186480522,
        -0.04150878265500069,
        -0.059318579733371735,
        0.04183994233608246,
        0.030284181237220764,
        -0.02467256970703602,
        -0.041378509253263474,
        0.01523925643414259,
        0.05795365571975708,
        -0.0384422168135643,
        0.060219310224056244,
        0.056041061878204346,
        0.04143927991390228,
        0.04922444000840187,
        -0.03149602562189102,
        -0.053167786449193954,
        0.01244468241930008,
        0.05955914407968521,
        -0.055055953562259674,
        -0.00989411398768425,
        -0.056572217494249344,
        -0.050260670483112335,
        -0.006573533173650503,
        -0.044098861515522,
        -0.015397910960018635,
        -0.055949751287698746,
        -0.04986288771033287,
        0.035930272191762924,
        0.06001980975270271,
        0.059815652668476105,
        -0.055684804916381836,
        -0.05931597203016281,
        -0.055607039481401443,
        0.024089958518743515,
        0.017355263233184814,
        -0.019740741699934006,
        -0.03194139152765274,
        -0.04424862191081047,
        0.040568992495536804,
        -0.014317173510789871,
        -0.03149018436670303,
        0.05816274508833885,
        0.00023963834973983467,
        -0.054049134254455566,
        -0.059568796306848526,
        0.02266608364880085,
        -0.05359256640076637,
        0.05791004002094269,
        0.004277098923921585,
        -0.05660904198884964,
        -0.059971392154693604,
        0.045292261987924576,
        0.02656874805688858,
        -0.02266121283173561,
        -0.057500727474689484,
        -0.050896283239126205,
        -0.059916723519563675,
        -0.05481795221567154,
        0.05570431798696518,
        0.008781424723565578,
        0.011009053327143192,
        -0.025190729647874832,
        -0.053602349013090134,
        0.04311384633183479,
        -0.012582005001604557,
        0.04753148928284645,
        0.03692636638879776,
        0.01809273473918438,
        -0.02118515968322754,
        -0.054347019642591476,
        -0.015213549137115479,
        -0.0447813905775547,
        -0.05761997401714325,
        -0.04946228861808777,
        0.007949772290885448,
        0.059363313019275665,
        0.04989643022418022,
        -0.03559661656618118,
        0.024729415774345398,
        0.058858878910541534,
        0.04728753864765167,
        -0.007536778692156076,
        -0.01857919804751873,
        -0.016357794404029846,
        0.026286540552973747,
        -0.04491420090198517,
        -0.03746774420142174,
        0.036437299102544785,
        -0.05568765103816986,
        0.05375293642282486,
        -0.0386231429874897,
        0.050055909901857376,
        0.046661488711833954,
        0.0038780388422310352,
        -0.057280879467725754,
        -0.012949042953550816,
        0.021980758756399155,
        0.0028386160265654325,
        0.059791047126054764,
        -0.023829711601138115,
        0.03362087905406952,
        -0.05233079567551613,
        -0.05224279686808586,
        0.013902056030929089,
        -0.028187425807118416,
        0.056097447872161865,
        -0.041905902326107025,
        0.007002749014645815,
        0.0177636556327343,
        0.05161510035395622,
        0.056434936821460724,
        0.03875536099076271,
        0.033732302486896515,
        -0.03200663626194,
        0.022702239453792572,
        -0.027156412601470947,
        0.04467156156897545,
        0.057285044342279434,
        -0.0358869805932045,
        0.04568784683942795,
        0.04969954490661621,
        -0.05742752552032471,
        0.020033111795783043,
        -0.03312887251377106,
        -0.023141246289014816,
        0.058016061782836914,
        -0.018056167289614677,
        -0.04253528267145157,
        0.02488931082189083,
        -0.04961869493126869,
        -0.04726611077785492,
        0.05330273136496544,
        0.015714749693870544,
        -0.04468604177236557,
        0.0006053573451936245,
        -0.05894780158996582,
        0.054360948503017426,
        0.04835369437932968,
        0.03959692269563675,
        0.060106631368398666,
        -0.014220614917576313,
        0.005576943978667259,
        -0.005372141022235155,
        0.059606216847896576,
        -0.02344977669417858
    ],
    [
        0.01820174977183342,
        0.060697443783283234,
        0.06365730613470078,
        0.003139561740681529,
        -0.04227579012513161,
        0.06813802570104599,
        0.044186148792505264,
        0.004473891109228134,
        0.05835296958684921,
        0.010578974150121212,
        -0.0016157833160832524,
        -0.01940738782286644,
        -0.023589173331856728,
        -0.011799806728959084,
        -0.047760259360075,
        0.03905538469552994,
        -0.0008215169655159116,
        0.06778322905302048,
        -0.009170131757855415,
        -0.06866701692342758,
        -0.03357547149062157,
        0.06667079776525497,
        0.006692525465041399,
        0.024341773241758347,
        0.0555928535759449,
        0.06498637050390244,
        -0.03248247504234314,
        -0.021704301238059998,
        -0.062189437448978424,
        0.053952038288116455,
        0.020037254318594933,
        0.004597847815603018,
        0.000012563353266159538,
        -0.06413516402244568,
        0.061163075268268585,
        -0.029691489413380623,
        -0.06515846401453018,
        -0.06272110342979431,
        0.06401810795068741,
        0.043659329414367676,
        0.06413478404283524,
        0.03552570194005966,
        0.0441829189658165,
        -0.05253118649125099,
        0.06518666446208954,
        -0.006402573548257351,
        -0.04350165277719498,
        0.06470049917697906,
        0.02370370551943779,
        0.04336167499423027,
        0.03144187107682228,
        0.0035510414745658636,
        -0.04521919786930084,
        0.055807095021009445,
        -0.05456015467643738,
        0.014113971963524818,
        0.06066098064184189,
        0.007354521658271551,
        0.020145289599895477,
        -0.06264300644397736,
        -0.050848525017499924,
        -0.04063106328248978,
        -0.0495382584631443,
        0.05718095228075981,
        -0.03755699843168259,
        -0.06452144682407379,
        -0.03239051252603531,
        -0.03210369125008583,
        -0.050457194447517395,
        0.0034474418498575687,
        -0.02014641836285591,
        -0.059888165444135666,
        0.023711616173386574,
        0.0478532500565052,
        -0.03003973513841629,
        -0.06198149919509888,
        -0.028053689748048782,
        0.06862716376781464,
        0.06362829357385635,
        -0.006843270268291235,
        -0.016247045248746872,
        -0.026632528752088547,
        -0.0469030886888504,
        0.025085855275392532,
        -0.059872835874557495,
        0.03796027600765228,
        0.06856949627399445,
        0.0686369389295578,
        0.021772656589746475,
        0.06004486605525017,
        -0.02395367994904518,
        -0.0683356449007988,
        -0.0144054489210248,
        0.002924899337813258,
        -0.034751202911138535,
        0.05531182140111923,
        -0.006228069309145212,
        0.01982860639691353,
        0.0260886512696743,
        0.021107546985149384,
        -0.06480124592781067,
        0.021935297176241875,
        -0.006117573473602533,
        -0.049623847007751465,
        0.04024636372923851,
        -0.061069585382938385,
        -0.061098601669073105,
        0.017162637785077095,
        0.06768742948770523,
        -0.018238071352243423,
        0.029825272038578987,
        0.007484316360205412,
        -0.028742153197526932,
        0.02156420238316059,
        -0.023900309577584267,
        -0.0490664467215538,
        0.046332407742738724,
        -0.013786026276648045,
        0.001682315138168633,
        -0.03853076696395874,
        -0.010729552246630192,
        0.005699457135051489,
        -0.02145303785800934,
        -0.03131866827607155,
        -0.05243948474526405,
        -0.05045128986239433,
        -0.06288694590330124,
        -0.015912342816591263,
        0.05827413871884346,
        0.0028233202174305916,
        -0.04220783710479736,
        -0.05952358618378639,
        -0.06292878836393356,
        0.05816145986318588,
        -0.011576609686017036,
        -0.06600753962993622,
        0.03009057231247425,
        -0.04909510165452957,
        0.06499522179365158,
        -0.000002296183765793103,
        0.036245834082365036,
        -0.04750451445579529,
        0.06495841592550278,
        0.01530410349369049,
        0.06082029640674591,
        -0.06809193640947342,
        0.06564032286405563,
        0.06151847913861275,
        -0.019882120192050934,
        -0.05534733459353447,
        0.04475514590740204,
        -0.03376927599310875,
        -0.048917245119810104,
        -0.004843969363719225,
        -0.009692567400634289,
        0.033982787281274796,
        0.015150945633649826,
        0.04745681211352348,
        0.03378286957740784,
        -0.04894908517599106,
        0.06865544617176056,
        -0.049445752054452896,
        0.06484191119670868,
        0.03684063255786896,
        0.062441661953926086,
        0.008452807553112507,
        -0.03777365759015083,
        -0.043778106570243835,
        -0.055478792637586594,
        0.04142696037888527,
        -0.03898487240076065,
        0.0632907897233963,
        -0.056957222521305084,
        -0.0015436279354617,
        -0.06311953812837601,
        -0.03511594235897064,
        0.03663225844502449,
        0.009527957066893578,
        0.05707922205328941,
        -0.02131539024412632,
        0.03903195634484291,
        0.06854710727930069,
        0.04480765014886856,
        -0.0021030965726822615,
        -0.019496923312544823,
        0.047010619193315506,
        -0.056691478937864304,
        0.04862280189990997,
        0.045821260660886765,
        0.022153552621603012,
        -0.04831605404615402,
        0.0010853576241061091,
        -0.027396999299526215,
        -0.05670923739671707,
        0.03327906131744385,
        -0.027670253068208694,
        -0.03100413829088211,
        -0.06865981221199036,
        0.04275696352124214,
        0.05808090791106224,
        0.060846034437417984,
        0.018308281898498535,
        0.06322203576564789,
        -0.05512193962931633,
        -0.01080434862524271,
        -0.04700280353426933,
        0.053272929042577744,
        0.024147897958755493,
        -0.04309073090553284,
        0.03735455498099327,
        -0.04457714036107063,
        0.06106194108724594,
        0.0059432643465697765,
        0.055279891937971115,
        -0.043444663286209106,
        -0.0577436201274395,
        -0.02441219985485077,
        0.03232860192656517,
        -0.013845808804035187,
        -0.057703275233507156,
        0.05795015022158623,
        0.01893329992890358,
        0.06313887983560562,
        0.02015511877834797,
        0.0433025062084198,
        0.058712948113679886,
        -0.06371744722127914,
        -0.01663137413561344,
        -0.06674095243215561,
        -0.015303130261600018,
        -0.015436659567058086,
        0.00011641852324828506,
        -0.06807886064052582,
        0.007111773360520601,
        0.053977351635694504,
        -0.03699345141649246,
        0.05674174427986145,
        0.0386451818048954,
        0.060738224536180496,
        0.06772805750370026,
        -0.05211469903588295,
        -0.060932256281375885,
        -0.02465706318616867,
        -0.06846929341554642,
        0.05676495283842087,
        0.030542442575097084,
        -0.038298528641462326,
        -0.03774840384721756,
        0.026810580864548683,
        -0.053928740322589874,
        -0.0612521767616272,
        -0.05081135407090187,
        -0.011200802400708199,
        -0.06458494067192078,
        0.015761611983180046,
        0.044767916202545166,
        0.03352956846356392,
        -0.02066732756793499,
        0.028265295550227165,
        0.016111379489302635,
        0.0678362101316452,
        -0.06774041801691055,
        -0.04952440410852432,
        0.012854859232902527,
        -0.05602838844060898,
        0.061706285923719406,
        -0.019390691071748734,
        0.00022245226136874408,
        0.02847883850336075,
        0.015243945643305779,
        0.02026241086423397,
        0.02516361139714718,
        0.05400005728006363,
        -0.048957958817481995,
        -0.01975185237824917,
        0.06057290360331535,
        -0.02362781949341297,
        -0.030305473133921623,
        -0.04287946969270706,
        0.03358282521367073,
        -0.011279812082648277,
        0.06491158157587051,
        -0.037921708077192307,
        0.044659268110990524,
        0.03258788213133812,
        -0.015574107877910137,
        0.03961735963821411,
        0.04189940541982651,
        -0.04483459144830704,
        0.055910542607307434,
        -0.060879699885845184,
        0.04870714247226715,
        0.0607297345995903,
        0.01369623001664877,
        0.042568814009428024,
        -0.060521211475133896,
        0.0623372457921505,
        0.026467083021998405,
        -0.05666203424334526,
        0.047912631183862686,
        -0.022597918286919594,
        -0.023310018703341484,
        -0.06281030178070068,
        -0.023851867765188217,
        -0.03553171828389168,
        -0.056889038532972336,
        -0.027545621618628502,
        -0.03227769210934639,
        0.06435070931911469,
        -0.00781253818422556,
        0.01809871569275856,
        -0.0180339552462101,
        -0.015424628742039204,
        0.0363980308175087,
        0.038236554712057114,
        -0.0609026663005352,
        -0.04932016506791115,
        -0.03619341552257538,
        0.01810300722718239,
        -0.030466333031654358,
        0.027049986645579338,
        0.0668315514922142,
        -0.0164924468845129,
        0.018021486699581146,
        0.060879774391651154,
        0.015370956622064114,
        0.01546071469783783,
        -0.06313204020261765,
        -0.01306169293820858,
        -0.00341433216817677,
        0.06061948835849762,
        0.004250448662787676,
        -0.06013370677828789,
        0.06733917444944382,
        0.0626826360821724,
        -0.026828041300177574,
        0.00881984457373619,
        0.05203429237008095,
        -0.028086284175515175,
        0.052879542112350464,
        0.05751367658376694,
        0.030651848763227463,
        0.019099881872534752,
        -0.027886638417840004,
        -0.025209855288267136,
        0.0015013664960861206,
        0.04477658495306969,
        0.05280648544430733,
        -0.04917733743786812,
        0.060760315507650375,
        -0.015416866168379784,
        0.04632744565606117,
        0.042674846947193146,
        0.03919477015733719,
        -0.05923166126012802,
        0.05207546427845955,
        -0.056093305349349976,
        -0.050743039697408676,
        -0.05839984118938446,
        -0.004482668358832598,
        0.022054027765989304,
        -0.013213004916906357,
        0.019191885367035866,
        -0.0574055016040802,
        -0.01811150088906288,
        -0.04816820099949837,
        0.003554498078301549,
        -0.009785081259906292,
        -0.02632102742791176,
        -0.06586747616529465,
        0.049490783363580704,
        0.009100914932787418,
        -0.009816118516027927,
        -0.057542599737644196,
        -0.04458921030163765,
        0.016576211899518967,
        -0.05890108272433281,
        0.06858868151903152,
        0.05362357571721077,
        0.06440040469169617,
        0.03997288644313812,
        -0.061730753630399704,
        -0.06530257314443588,
        0.057697415351867676,
        0.06774718314409256,
        -0.04282396286725998,
        0.0201704204082489,
        -0.06332176178693771,
        0.004733045119792223,
        -0.0066489833407104015,
        -0.06264512985944748,
        0.047759510576725006,
        0.017852097749710083,
        -0.06534221768379211,
        0.0000113268733912264,
        0.06075899675488472,
        0.06558869034051895,
        -0.06656861305236816,
        -0.06329292058944702,
        -0.048555243760347366,
        -0.05497854948043823,
        0.0501418299973011,
        -0.02657671459019184,
        -0.06837299466133118,
        -0.055366627871990204,
        0.05727799981832504,
        -0.007077248301357031,
        -0.0514959953725338,
        0.061058223247528076,
        -0.03203941881656647,
        -0.053827881813049316,
        -0.05765879154205322,
        0.05268658325076103,
        -0.023063762113451958,
        0.06047426909208298,
        -0.007243437226861715,
        0.03419238328933716,
        0.000942475104238838,
        0.04946187511086464,
        0.05861952155828476,
        0.032003577798604965,
        -0.04839026555418968,
        0.025114374235272408,
        -0.06305509805679321,
        -0.06111777946352959,
        -0.029579512774944305,
        -0.01704934425652027,
        0.0643475279211998,
        -0.05949513614177704,
        -0.055098094046115875,
        0.031313374638557434,
        -0.019912604242563248,
        0.008638231083750725,
        0.06721807271242142,
        0.03277406096458435,
        -0.024099567905068398,
        0.0035523746628314257,
        -0.05190012603998184,
        0.059851981699466705,
        -0.04936673119664192,
        -0.06347639858722687,
        0.04916798695921898,
        0.06570719927549362,
        0.027894528582692146,
        -0.030080877244472504,
        -0.019626986235380173,
        0.06777606904506683,
        -0.013132900930941105,
        0.033700235188007355,
        -0.0535258911550045,
        -0.0658140778541565,
        -0.020975537598133087,
        -0.003741142340004444,
        0.002652763621881604,
        -0.05097678303718567,
        -0.058436475694179535,
        0.050287675112485886,
        -0.009422872215509415,
        -0.008884304203093052,
        -0.02951870672404766,
        0.01874689571559429,
        -0.03752298653125763,
        -0.046538617461919785,
        0.04359959438443184,
        -0.017529888078570366,
        0.06580903381109238,
        -0.06545734405517578,
        0.04946208745241165,
        -0.05435794219374657,
        -0.06148839369416237,
        0.02748234197497368,
        0.03496945649385452,
        -0.0222098920494318,
        0.03158987686038017,
        -0.03263961151242256,
        0.054951123893260956,
        0.007266045548021793,
        -0.007313088048249483,
        -0.002811066573485732,
        0.05894305557012558,
        -0.043947070837020874,
        0.007948632352054119,
        0.004734337795525789,
        0.049685027450323105,
        0.028839915990829468,
        -0.002309974282979965,
        -0.03004617802798748,
        0.05963478609919548,
        -0.060043927282094955,
        -0.04091211408376694,
        0.004729411564767361,
        -0.010608965530991554,
        0.053641531616449356,
        0.06516540050506592,
        -0.015440276823937893,
        0.05160611867904663,
        -0.054166730493307114,
        0.006091564428061247,
        0.05673392489552498,
        -0.035806480795145035,
        -0.06525558978319168,
        -0.04538186267018318,
        -0.06671278923749924,
        0.059136006981134415,
        0.05752480775117874,
        -0.0185991358011961,
        0.06848855316638947,
        0.038782909512519836,
        -0.062135789543390274,
        0.023129934445023537,
        0.05622163787484169,
        -0.008572585880756378
    ],
    [
        -0.048087701201438904,
        0.02882508747279644,
        0.051282092928886414,
        0.019088972359895706,
        0.04024742916226387,
        0.06177344173192978,
        0.04268547520041466,
        0.013604245148599148,
        0.04393470287322998,
        0.06626979261636734,
        0.040118228644132614,
        0.012399005703628063,
        -0.053725823760032654,
        -0.05517591908574104,
        -0.05998019129037857,
        0.06085514649748802,
        -0.060142502188682556,
        0.05765766650438309,
        -0.037059005349874496,
        -0.07075571268796921,
        -0.055790286511182785,
        0.06921103596687317,
        0.026963144540786743,
        0.009196651168167591,
        0.06301730871200562,
        0.046982575207948685,
        0.05165928229689598,
        -0.018316099420189857,
        0.030172407627105713,
        -0.024690518155694008,
        0.056365255266427994,
        -0.033534735441207886,
        -0.013082373887300491,
        -0.018597055226564407,
        0.0014980660052970052,
        0.026210937649011612,
        -0.05165158212184906,
        -0.025470513850450516,
        0.015610028989613056,
        0.04511188715696335,
        0.06168142706155777,
        0.02524552308022976,
        0.052083712071180344,
        -0.06802776455879211,
        0.05816664546728134,
        0.050481051206588745,
        -0.0055457972921431065,
        0.062007784843444824,
        0.04671751707792282,
        0.05558956786990166,
        0.014283174648880959,
        0.05214911699295044,
        0.019658410921692848,
        0.0632157102227211,
        0.044707778841257095,
        -0.02995382435619831,
        0.010304754599928856,
        0.05688514932990074,
        -0.006643264554440975,
        0.04885575920343399,
        -0.05365721508860588,
        -0.00687793642282486,
        -0.045538753271102905,
        0.06848402321338654,
        -0.06458181142807007,
        -0.03331176936626434,
        0.061027348041534424,
        -0.04686656594276428,
        0.0021149166859686375,
        -0.06547457724809647,
        -0.06489910930395126,
        -0.06710843741893768,
        0.0059595475904643536,
        0.05468391627073288,
        -0.051551464945077896,
        0.03703843057155609,
        0.03926059976220131,
        0.0644623339176178,
        0.06441077589988708,
        -0.04476362094283104,
        -0.008275733329355717,
        0.07043962180614471,
        -0.052157506346702576,
        -0.013555342331528664,
        0.01838959939777851,
        0.007057879585772753,
        0.07029785960912704,
        0.0703607052564621,
        -0.0072416504845023155,
        0.047769442200660706,
        -0.04739955812692642,
        -0.04760049656033516,
        -0.059959474951028824,
        -0.05836263298988342,
        -0.012537808157503605,
        -0.009210878983139992,
        -0.029607446864247322,
        -0.04789666086435318,
        -0.055850863456726074,
        0.022873833775520325,
        -0.06847403198480606,
        0.0008372956071980298,
        0.06303392350673676,
        -0.030845215544104576,
        0.0529167465865612,
        -0.04002763703465462,
        -0.031015606597065926,
        0.021344618871808052,
        0.06606559455394745,
        0.02372337132692337,
        -0.04738766327500343,
        -0.06863103806972504,
        -0.05271294340491295,
        0.014212183654308319,
        -0.023656265810132027,
        -0.03288394585251808,
        0.00007554187322966754,
        -0.02116851508617401,
        0.012050040066242218,
        0.056542493402957916,
        -0.04228070005774498,
        0.04137059301137924,
        -0.005073202773928642,
        0.04573509469628334,
        -0.06755730509757996,
        -0.002887479029595852,
        -0.06813400238752365,
        -0.04251981899142265,
        0.056632526218891144,
        -0.00615675700828433,
        -0.04028192535042763,
        -0.03925882652401924,
        0.0022507912945002317,
        -0.00740262633189559,
        -0.03174399957060814,
        -0.040506985038518906,
        -0.021954219788312912,
        -0.04465698450803757,
        -0.062166955322027206,
        0.05349874496459961,
        0.0162553321570158,
        -0.0424070842564106,
        0.05819333344697952,
        0.02927124872803688,
        -0.008548088371753693,
        -0.06873822957277298,
        0.06319528073072433,
        -0.06491132080554962,
        0.004546487238258123,
        0.05113409459590912,
        0.028596483170986176,
        -0.06089295074343681,
        -0.04301348701119423,
        -0.038822073489427567,
        -0.045541681349277496,
        -0.0562608540058136,
        0.0680556446313858,
        0.02254687249660492,
        -0.030022356659173965,
        0.014124397188425064,
        0.07080940157175064,
        -0.0194667000323534,
        0.037609171122312546,
        -0.0024742598179727793,
        -0.04483535885810852,
        0.030453719198703766,
        -0.05541275441646576,
        -0.06530744582414627,
        0.024629591032862663,
        0.027233706787228584,
        0.0488245002925396,
        0.0036848371382802725,
        -0.05420054495334625,
        -0.056127749383449554,
        -0.017222130671143532,
        0.06476331502199173,
        -0.06628948450088501,
        -0.0171562060713768,
        0.054905910044908524,
        -0.009744961746037006,
        0.05474095791578293,
        0.07079547643661499,
        0.03743874654173851,
        0.049131039530038834,
        0.04314499348402023,
        -0.016285240650177002,
        -0.051450908184051514,
        0.061116792261600494,
        -0.014181690290570259,
        -0.04592909663915634,
        -0.010050574317574501,
        -0.05473768338561058,
        0.05367927625775337,
        0.0010314630344510078,
        -0.002159056719392538,
        -0.04059816524386406,
        0.03352229669690132,
        -0.07078945636749268,
        0.016922172158956528,
        0.018266120925545692,
        0.02673385664820671,
        -0.052551209926605225,
        0.06276697665452957,
        -0.04271932691335678,
        -0.0644480437040329,
        0.05659867078065872,
        0.004604530055075884,
        -0.03875363990664482,
        -0.04059654474258423,
        0.06271450966596603,
        -0.06098165735602379,
        0.0048779211938381195,
        -0.006800406612455845,
        -0.012385314330458641,
        0.022015750408172607,
        0.02526930905878544,
        -0.04994212090969086,
        0.00781604740768671,
        0.014743144623935223,
        -0.045478299260139465,
        0.01800723373889923,
        0.04535164684057236,
        0.027404872700572014,
        -0.06704644113779068,
        0.05544073507189751,
        -0.04777728021144867,
        -0.02130567841231823,
        0.016544541344046593,
        -0.06602489948272705,
        -0.03425983712077141,
        -0.04812731221318245,
        -0.053969547152519226,
        -0.06518234312534332,
        0.012731735594570637,
        -0.01172018051147461,
        -0.027199601754546165,
        0.06489436328411102,
        -0.05302519351243973,
        -0.004458573646843433,
        0.05402103811502457,
        -0.01523288618773222,
        0.055403921753168106,
        -0.0598994717001915,
        0.013747813180088997,
        0.00020397326443344355,
        0.0002927650639321655,
        -0.06161371245980263,
        -0.00362935452722013,
        0.02776399254798889,
        -0.03111177310347557,
        -0.040521588176488876,
        -0.06205325201153755,
        -0.05022641271352768,
        -0.06865811347961426,
        0.007840242236852646,
        0.06390245258808136,
        -0.05069635435938835,
        -0.02698453702032566,
        0.06655555963516235,
        0.04612546041607857,
        0.07074525952339172,
        -0.019000235944986343,
        -0.039754144847393036,
        0.05296777933835983,
        -0.003262156853452325,
        0.006432262249290943,
        0.011050849221646786,
        0.05539353936910629,
        0.03921671584248543,
        0.0034946005325764418,
        0.01083883922547102,
        -0.0651981309056282,
        0.061067741364240646,
        -0.05864623561501503,
        -0.024370377883315086,
        0.06211807206273079,
        -0.06261563301086426,
        -0.030625436455011368,
        -0.024797355756163597,
        -0.023301761597394943,
        -0.04051358625292778,
        0.05306762456893921,
        -0.06494138389825821,
        0.05137353762984276,
        0.047191884368658066,
        -0.06374173611402512,
        0.02824343368411064,
        0.006465957034379244,
        -0.04871382564306259,
        0.051698245108127594,
        -0.056932710111141205,
        0.015352053567767143,
        0.06793496012687683,
        0.029969943687319756,
        0.067725770175457,
        -0.035329218953847885,
        0.0671234056353569,
        0.06282727420330048,
        0.004611732438206673,
        0.04809504747390747,
        0.03266926109790802,
        0.01334933191537857,
        -0.039573784917593,
        0.03361589461565018,
        -0.06344316899776459,
        0.008898942731320858,
        -0.057134684175252914,
        -0.03235609084367752,
        0.030884966254234314,
        -0.006988605484366417,
        0.027229411527514458,
        -0.04667441174387932,
        -0.05955490469932556,
        0.038668058812618256,
        -0.05252465233206749,
        -0.06331485509872437,
        0.06204099953174591,
        -0.039714086800813675,
        0.003104262985289097,
        -0.058927640318870544,
        -0.04946719855070114,
        0.01767154596745968,
        0.037270549684762955,
        -0.024122802540659904,
        0.036089248955249786,
        0.05394978076219559,
        0.01268006395548582,
        0.030189648270606995,
        -0.03889741748571396,
        -0.0031352154910564423,
        -0.01948012411594391,
        0.06271792203187943,
        -0.05104440078139305,
        -0.050013456493616104,
        0.05952238291501999,
        0.008959553204476833,
        0.01764911785721779,
        0.06661344319581985,
        -0.057895150035619736,
        0.050838496536016464,
        -0.029837625101208687,
        0.03937225043773651,
        -0.061193596571683884,
        -0.06084411218762398,
        0.057027898728847504,
        0.03284343332052231,
        -0.002233807696029544,
        0.011357263661921024,
        -0.05609890818595886,
        0.061208657920360565,
        -0.06559187918901443,
        0.035280827432870865,
        -0.04961983114480972,
        -0.0647941455245018,
        -0.06410256028175354,
        0.06075514853000641,
        -0.05526068061590195,
        -0.0076856836676597595,
        0.003502292325720191,
        -0.06340423971414566,
        0.05143250524997711,
        0.0581684336066246,
        -0.024838700890541077,
        -0.010200764052569866,
        -0.006072563119232655,
        -0.0539356954395771,
        0.06932353973388672,
        -0.03958072513341904,
        0.01848517172038555,
        -0.0701506957411766,
        -0.03774013742804527,
        -0.036259979009628296,
        0.02924717590212822,
        0.05756056308746338,
        -0.021597323939204216,
        -0.017915604636073112,
        -0.010949227958917618,
        0.07076213508844376,
        0.0032900746446102858,
        0.03018796071410179,
        0.02911575511097908,
        -0.03533679619431496,
        -0.06828134506940842,
        0.0433361642062664,
        0.06807834655046463,
        -0.01890978217124939,
        -0.0239158533513546,
        -0.060009513050317764,
        -0.042924217879772186,
        -0.03939142823219299,
        -0.04490269720554352,
        0.008302765898406506,
        0.02671271376311779,
        -0.02235422469675541,
        -0.041585661470890045,
        0.049055133014917374,
        0.05865475907921791,
        -0.03666209056973457,
        0.006589752621948719,
        -0.06048838421702385,
        -0.008073847740888596,
        -0.000658782257232815,
        0.062214188277721405,
        -0.06802091747522354,
        -0.05900814011693001,
        0.061013657599687576,
        -0.017073307186365128,
        0.04370812699198723,
        0.050430454313755035,
        -0.04755944013595581,
        0.012640155851840973,
        -0.05499228835105896,
        0.042363837361335754,
        -0.03408071771264076,
        0.05422801151871681,
        0.022953199222683907,
        -0.046804189682006836,
        -0.06152564659714699,
        0.036526791751384735,
        0.05490588769316673,
        -0.028084028512239456,
        -0.02397933229804039,
        0.015182028524577618,
        -0.01499770488590002,
        0.03936309367418289,
        0.058593910187482834,
        -0.003624530741944909,
        0.045895401388406754,
        -0.05528730899095535,
        -0.034403637051582336,
        0.06329409778118134,
        -0.0018089052755385637,
        -0.043666500598192215,
        0.023313850164413452,
        0.055220827460289,
        0.004257245920598507,
        -0.007224982604384422,
        -0.03978418558835983,
        0.04931316152215004,
        -0.05216021090745926,
        0.04352649301290512,
        0.024276891723275185,
        0.057399120181798935,
        0.0316549576818943,
        0.013069435022771358,
        0.03843282535672188,
        0.06703673303127289,
        -0.015371525660157204,
        0.0650675967335701,
        -0.061890702694654465,
        -0.05447811260819435,
        0.04611029848456383,
        0.04667121171951294,
        -0.009792211465537548,
        0.025700898841023445,
        -0.03630800545215607,
        0.055632900446653366,
        0.05639990419149399,
        -0.012139301747083664,
        0.05705425143241882,
        -0.02501916140317917,
        -0.019733408465981483,
        -0.06799208372831345,
        0.009239683859050274,
        0.044080931693315506,
        0.06613089144229889,
        -0.05074609816074371,
        0.05436689406633377,
        -0.061897773295640945,
        -0.06834253668785095,
        0.038024138659238815,
        0.06575822085142136,
        0.004908798262476921,
        -0.023021550849080086,
        -0.03408633545041084,
        -0.03265293687582016,
        0.02533550187945366,
        0.034593615680933,
        0.019168002530932426,
        -0.0366993322968483,
        -0.06270183622837067,
        -0.017351960763335228,
        -0.017150791361927986,
        0.027416875585913658,
        0.0427166149020195,
        -0.010095793753862381,
        0.018593179062008858,
        -0.03021448664367199,
        -0.043697234243154526,
        0.03561297431588173,
        -0.06403707712888718,
        -0.013138050213456154,
        0.011223681271076202,
        -0.009033774957060814,
        0.05522478371858597,
        -0.018259603530168533,
        -0.030911769717931747,
        0.043595314025878906,
        0.05411387234926224,
        0.0690203309059143,
        -0.06048280745744705,
        -0.05434507131576538,
        -0.04055372625589371,
        0.011233092285692692,
        -0.005653824657201767,
        0.028886228799819946,
        0.07047101855278015,
        0.015470506623387337,
        -0.06678283959627151,
        -0.054265882819890976,
        0.05551822483539581,
        0.03823038190603256
    ],
    [
        0.0438193716108799,
        0.005383959040045738,
        0.05019069090485573,
        0.0379406102001667,
        -0.040241505950689316,
        -0.009501161053776741,
        -0.024351557716727257,
        -0.06176543980836868,
        0.058431267738342285,
        0.0460997149348259,
        0.03983236849308014,
        0.052451930940151215,
        -0.038951415568590164,
        -0.05795198678970337,
        -0.05784132704138756,
        0.06193309649825096,
        0.009615132585167885,
        0.05961756408214569,
        -0.06116435304284096,
        -0.01467872317880392,
        -0.0003441853041294962,
        0.03842126205563545,
        0.03384379670023918,
        -0.04977647587656975,
        0.051316145807504654,
        0.054765306413173676,
        0.056302085518836975,
        -0.03693414852023125,
        -0.009824244305491447,
        0.04301872476935387,
        -0.01630820333957672,
        0.0008310624398291111,
        -0.060976434499025345,
        -0.0626814067363739,
        0.05097024142742157,
        0.050973184406757355,
        -0.05825350061058998,
        0.05044431611895561,
        -0.03348506614565849,
        -0.003540855599567294,
        0.0077738575637340546,
        0.025073498487472534,
        -0.0023036361671984196,
        -0.031904350966215134,
        0.04366018995642662,
        0.06124274805188179,
        0.05789560824632645,
        0.05278097838163376,
        0.06018121540546417,
        0.036968257278203964,
        0.05470568314194679,
        0.06247900053858757,
        -0.06014818698167801,
        0.05590076372027397,
        -0.033985257148742676,
        -0.06322979927062988,
        -0.057112280279397964,
        -0.04073816165328026,
        -0.0022329301573336124,
        0.05899579077959061,
        -0.0521170012652874,
        0.020127177238464355,
        -0.05518423393368721,
        0.059379901736974716,
        -0.04014606028795242,
        -0.03853365033864975,
        -0.02113235369324684,
        0.05953599885106087,
        -0.06331630051136017,
        0.0237459484487772,
        -0.06148974969983101,
        -0.056996677070856094,
        0.06160929054021835,
        0.020574448630213737,
        -0.019227344542741776,
        -0.028813151642680168,
        0.06124397739768028,
        0.0630965456366539,
        -0.0038299544248729944,
        -0.05355082079768181,
        -0.00993385724723339,
        0.056738629937171936,
        -0.05113328993320465,
        0.058301892131567,
        0.06248577684164047,
        0.05447506904602051,
        0.06060844659805298,
        -0.05713769793510437,
        0.05719514936208725,
        0.04933052137494087,
        0.014605826698243618,
        0.004957712721079588,
        -0.06028968095779419,
        0.0057787708938121796,
        -0.047345712780952454,
        0.05488044396042824,
        -0.015203483402729034,
        -0.060196615755558014,
        0.014024212956428528,
        -0.05796638876199722,
        -0.05313201621174812,
        0.039307400584220886,
        0.05331489071249962,
        0.03670384734869003,
        0.05932978540658951,
        -0.0565897598862648,
        -0.020575013011693954,
        0.057551927864551544,
        -0.015133080072700977,
        0.050714682787656784,
        -0.06267723441123962,
        0.06342362612485886,
        -0.05887647718191147,
        0.0010049371048808098,
        0.008741594851016998,
        0.006049137096852064,
        0.05670121684670448,
        -0.02501455508172512,
        0.06066393107175827,
        0.041431523859500885,
        0.03676528483629227,
        0.06192202866077423,
        -0.016989268362522125,
        -0.052177805453538895,
        -0.02057858742773533,
        -0.058924514800310135,
        -0.038077764213085175,
        -0.02707424946129322,
        0.05974593758583069,
        0.002827599411830306,
        0.055458106100559235,
        -0.03741210699081421,
        -0.06094016134738922,
        -0.006260215770453215,
        -0.048983264714479446,
        -0.05713360384106636,
        0.04421493783593178,
        0.05704716220498085,
        -0.06294041872024536,
        0.0056169768795371056,
        0.0628848448395729,
        0.05187882483005524,
        0.05869197100400925,
        -0.009854487143456936,
        0.05632776767015457,
        -0.013353516347706318,
        0.06340109556913376,
        -0.05368857830762863,
        -0.031168274581432343,
        0.045519303530454636,
        0.051742494106292725,
        -0.06295955181121826,
        0.061914294958114624,
        -0.056099265813827515,
        0.03502887859940529,
        -0.05482729151844978,
        -0.05682557076215744,
        0.012386886402964592,
        0.002931010676547885,
        0.060807544738054276,
        -0.03058456815779209,
        -0.01733890175819397,
        0.047581933438777924,
        0.0038241944275796413,
        0.021034155040979385,
        0.028273841366171837,
        -0.02239878475666046,
        0.019472261890769005,
        -0.005575705785304308,
        0.00917348824441433,
        -0.027283577248454094,
        0.0446290522813797,
        0.03617989271879196,
        -0.044478271156549454,
        0.03243270888924599,
        0.06254542618989944,
        -0.06154375150799751,
        0.038977742195129395,
        0.05094906687736511,
        -0.049501899629831314,
        -0.00476659694686532,
        0.03231450915336609,
        -0.004497558809816837,
        0.029501669108867645,
        0.002416169736534357,
        -0.009468051604926586,
        -0.05630452558398247,
        0.05220143124461174,
        -0.029377950355410576,
        -0.034631941467523575,
        -0.025958286598324776,
        0.05025840923190117,
        0.04422742873430252,
        -0.03572366014122963,
        -0.03602233901619911,
        -0.03804054111242294,
        0.04591154307126999,
        0.05514136329293251,
        0.01969151943922043,
        -0.03528467193245888,
        0.0360344797372818,
        -0.01340735424309969,
        -0.05215676873922348,
        -0.016210537403821945,
        -0.06072724610567093,
        0.0025432142429053783,
        -0.00010231683700112626,
        -0.017661379650235176,
        -0.051032934337854385,
        -0.05453229695558548,
        -0.03874549642205238,
        0.05838757008314133,
        0.03413376957178116,
        0.03517916053533554,
        0.019363904371857643,
        0.03364783897995949,
        0.05424711853265762,
        -0.001245850813575089,
        0.06230523809790611,
        -0.04767652601003647,
        -0.031038135290145874,
        0.04508452117443085,
        -0.051681697368621826,
        -0.022330470383167267,
        0.05501078441739082,
        -0.04109537601470947,
        -0.004715311340987682,
        0.05966832488775253,
        -0.009779500775039196,
        -0.0346406064927578,
        0.061471208930015564,
        -0.052218057215213776,
        -0.0284427460283041,
        0.032715100795030594,
        -0.04635610431432724,
        0.062051404267549515,
        0.05600840225815773,
        -0.033299919217824936,
        -0.039604999125003815,
        0.061225712299346924,
        0.005639650393277407,
        0.05775202810764313,
        -0.06328134983778,
        0.049411896616220474,
        0.042220525443553925,
        -0.007386229932308197,
        -0.06330956518650055,
        -0.04044053703546524,
        -0.042289867997169495,
        -0.04858480021357536,
        -0.062480002641677856,
        -0.05272052064538002,
        -0.03152807801961899,
        -0.06050577759742737,
        -0.05647697299718857,
        0.019944120198488235,
        -0.031117642298340797,
        -0.05843348428606987,
        0.013780075125396252,
        -0.03882947191596031,
        0.06322439014911652,
        -0.0523054264485836,
        -0.053627949208021164,
        0.011315908282995224,
        -0.022684017196297646,
        0.03290136903524399,
        -0.06295320391654968,
        -0.042685166001319885,
        -0.05920669436454773,
        0.012297199107706547,
        0.021612131968140602,
        -0.05619782954454422,
        0.0625973641872406,
        -0.04619508236646652,
        -0.06036892905831337,
        0.009274408221244812,
        -0.043284837156534195,
        0.05223482474684715,
        -0.05816158279776573,
        -0.05043020471930504,
        -0.05987553298473358,
        0.038991279900074005,
        -0.06268887221813202,
        0.0634714663028717,
        -0.003864672966301441,
        -0.005012780427932739,
        0.027845211327075958,
        0.05963260307908058,
        0.05564539507031441,
        0.022039039060473442,
        -0.008644641377031803,
        0.014830769039690495,
        -0.02425227500498295,
        0.050156962126493454,
        0.0516425296664238,
        -0.06131996214389801,
        0.042681556195020676,
        0.05905874818563461,
        -0.06306643784046173,
        0.05083782598376274,
        0.061299171298742294,
        0.058936264365911484,
        -0.01989816129207611,
        0.018158888444304466,
        0.028211543336510658,
        0.011064899154007435,
        -0.04686916247010231,
        0.062459658831357956,
        0.00392815750092268,
        -0.04677199944853783,
        0.062202829867601395,
        -0.04356922581791878,
        0.004093578550964594,
        0.04006720706820488,
        0.048773352056741714,
        -0.05703328922390938,
        -0.030490024015307426,
        -0.06262769550085068,
        0.03796771541237831,
        -0.007024222053587437,
        0.015004701912403107,
        -0.017049405723810196,
        -0.03162910044193268,
        0.04759977385401726,
        0.05068981274962425,
        -0.021478332579135895,
        -0.03355146199464798,
        0.011898262426257133,
        0.03137553855776787,
        -0.01612500287592411,
        -0.04155630245804787,
        -0.040092770010232925,
        -0.0510546937584877,
        -0.05572264641523361,
        0.06055067107081413,
        0.053823936730623245,
        0.01125655509531498,
        0.037398215383291245,
        -0.042646024376153946,
        -0.029125194996595383,
        0.014699738472700119,
        0.06135881692171097,
        -0.06340733915567398,
        -0.06276799738407135,
        0.011404704302549362,
        0.02827870287001133,
        0.015641482546925545,
        0.0544700026512146,
        0.007508047856390476,
        0.05071297660470009,
        0.06148134544491768,
        0.02118998020887375,
        -0.047715406864881516,
        -0.018673865124583244,
        0.046100616455078125,
        0.04152606427669525,
        -0.035105179995298386,
        -0.05634743720293045,
        0.029095591977238655,
        -0.04866546019911766,
        0.037179142236709595,
        -0.061607666313648224,
        0.036925945430994034,
        0.02121127024292946,
        0.060283493250608444,
        -0.05194941163063049,
        0.06046751141548157,
        -0.008800363168120384,
        0.031794313341379166,
        -0.0586431622505188,
        -0.044119998812675476,
        -0.04974481835961342,
        0.054775167256593704,
        0.017190536484122276,
        -0.016135944053530693,
        0.04658627510070801,
        -0.04925362765789032,
        -0.04584527760744095,
        0.058743178844451904,
        0.05157008767127991,
        -0.03409089893102646,
        -0.04551331698894501,
        -0.06232618913054466,
        0.0426606722176075,
        0.05508457124233246,
        -0.02080833539366722,
        -0.04088568314909935,
        -0.05661124363541603,
        -0.0431373231112957,
        -0.05364590510725975,
        -0.06309790909290314,
        -0.023702846840023994,
        0.0129527747631073,
        -0.013712689280509949,
        -0.016844606027007103,
        0.05906244367361069,
        0.04138877987861633,
        0.04601435363292694,
        0.04371904954314232,
        -0.05201353877782822,
        -0.03911193087697029,
        0.06103818863630295,
        0.0423777773976326,
        -0.04280999302864075,
        -0.011550502851605415,
        -0.020301207900047302,
        0.04123243689537048,
        -0.05613014101982117,
        0.05293481796979904,
        -0.0011556075187399983,
        -0.05690857768058777,
        -0.061582695692777634,
        0.0398283489048481,
        0.039473552256822586,
        0.046108681708574295,
        -0.050454456359148026,
        0.030813956633210182,
        0.05033321678638458,
        0.041906245052814484,
        0.008893958292901516,
        0.06057804822921753,
        -0.023053400218486786,
        -0.01812714710831642,
        -0.051887594163417816,
        0.04473608732223511,
        0.041556961834430695,
        -0.03410688787698746,
        -0.03313514217734337,
        -0.026299655437469482,
        -0.04740912839770317,
        0.03772816061973572,
        0.011671382002532482,
        -0.06288044899702072,
        -0.06170684099197388,
        -0.003572250949218869,
        -0.05916299298405647,
        0.0042127822525799274,
        0.05321842432022095,
        -0.010879609733819962,
        -0.005251073278486729,
        -0.02203482948243618,
        0.05982600152492523,
        0.006837060209363699,
        0.04785687103867531,
        0.04575880616903305,
        -0.055879317224025726,
        0.05867091193795204,
        0.014903890900313854,
        0.054047442972660065,
        0.06143940985202789,
        -0.06247592717409134,
        -0.003803012426942587,
        0.05123583599925041,
        0.034104615449905396,
        -0.05486314371228218,
        -0.05878065526485443,
        0.02484811283648014,
        -0.022731555625796318,
        -0.05355451628565788,
        0.05839972198009491,
        -0.03784609213471413,
        -0.027102282270789146,
        -0.045773133635520935,
        0.03138194978237152,
        0.029080582782626152,
        0.060487061738967896,
        -0.04421504586935043,
        0.06177085265517235,
        -0.06226567551493645,
        -0.054578375071287155,
        0.043090738356113434,
        -0.02250272035598755,
        0.007991055026650429,
        0.0506042055785656,
        -0.06333642452955246,
        0.004813395440578461,
        0.02769913710653782,
        -0.06175483018159866,
        -0.046719323843717575,
        -0.061992522329092026,
        0.05824446305632591,
        -0.049899570643901825,
        0.047488871961832047,
        0.03550983965396881,
        0.02967149391770363,
        0.0480223149061203,
        -0.01966904103755951,
        0.04436982795596123,
        -0.048022814095020294,
        -0.045234985649585724,
        -0.061399511992931366,
        -0.006324279587715864,
        -0.023012103512883186,
        0.04348161816596985,
        0.01887262798845768,
        0.05553023889660835,
        -0.0033143251203000546,
        0.05445270985364914,
        0.04742511361837387,
        0.04429600015282631,
        -0.04596405848860741,
        0.015588521026074886,
        -0.05570875108242035,
        0.010218044742941856,
        -0.013087230734527111,
        -0.05438416078686714,
        0.05710884928703308,
        0.005960550159215927,
        -0.049488939344882965,
        -0.060551904141902924,
        0.04798481613397598,
        0.058757659047842026
    ],
    [
        0.04560880735516548,
        0.042433712631464005,
        0.05157255753874779,
        0.035902705043554306,
        -0.0404568575322628,
        0.028260599821805954,
        -0.02812916599214077,
        -0.06223645061254501,
        0.05633159726858139,
        0.032157085835933685,
        0.03349241614341736,
        0.053295742720365524,
        -0.03699436038732529,
        -0.057038966566324234,
        -0.05700325965881348,
        0.06395885348320007,
        0.0034653740003705025,
        0.05900179594755173,
        -0.0601603165268898,
        0.02544068545103073,
        -0.02826007828116417,
        0.032595742493867874,
        0.016085656359791756,
        -0.04366881400346756,
        0.049347203224897385,
        0.042493853718042374,
        0.048484500497579575,
        -0.02667991630733013,
        -0.006823772098869085,
        0.023229366168379784,
        -0.018150581046938896,
        -0.023254532366991043,
        -0.06309492886066437,
        -0.06104379519820213,
        0.052156392484903336,
        0.037001416087150574,
        -0.05967021733522415,
        0.046432409435510635,
        0.003329465864226222,
        0.002914393786340952,
        -0.009550013579428196,
        0.0173395574092865,
        0.026286348700523376,
        -0.04510926082730293,
        0.05012140050530434,
        0.061813853681087494,
        0.05991872772574425,
        0.048764668405056,
        0.038667917251586914,
        0.05231881141662598,
        0.03591402247548103,
        0.06478312611579895,
        -0.05383703112602234,
        0.05065098777413368,
        -0.010357961058616638,
        -0.0656435415148735,
        -0.048029519617557526,
        -0.034519653767347336,
        -0.016646651551127434,
        0.061780743300914764,
        -0.04518776759505272,
        0.016229037195444107,
        -0.05461952090263367,
        0.05414281785488129,
        -0.04243047535419464,
        -0.04792027175426483,
        0.010998275130987167,
        0.06074235960841179,
        -0.0658750832080841,
        0.025890646502375603,
        -0.05790707841515541,
        -0.0489681176841259,
        0.06510934233665466,
        0.03929061070084572,
        -0.04366394504904747,
        -0.04463675990700722,
        0.05981483682990074,
        0.06532879173755646,
        -0.0007986131822690368,
        -0.0479523241519928,
        -0.0015626633539795876,
        0.05990754812955856,
        -0.04418834298849106,
        0.049023378640413284,
        0.061725933104753494,
        0.05298888683319092,
        0.06224408745765686,
        -0.06029665470123291,
        0.049112942069768906,
        0.058128029108047485,
        0.04896041005849838,
        -0.003899461356922984,
        -0.05039701238274574,
        0.01810430735349655,
        -0.054529108107089996,
        0.051957231014966965,
        -0.026622485369443893,
        -0.06142585352063179,
        0.003981484565883875,
        -0.05659330263733864,
        -0.05919129028916359,
        0.022050466388463974,
        0.05497799441218376,
        0.020643820986151695,
        0.06282424926757812,
        -0.04833328723907471,
        -0.04398694261908531,
        0.04677485302090645,
        0.0038172665517777205,
        0.02851494401693344,
        -0.06516372412443161,
        0.06564740091562271,
        -0.06030145287513733,
        -0.018811117857694626,
        -0.00889668706804514,
        0.02167021855711937,
        0.04385336861014366,
        0.01678161323070526,
        0.05928245931863785,
        0.017262468114495277,
        0.019740525633096695,
        0.05946170911192894,
        -0.023650404065847397,
        -0.0525091290473938,
        -0.04592039808630943,
        -0.06300023943185806,
        -0.03108828142285347,
        -0.007811753544956446,
        0.06385747343301773,
        -0.0310985017567873,
        0.03343752771615982,
        -0.052008431404829025,
        -0.06409219652414322,
        0.008744681254029274,
        -0.049806252121925354,
        -0.06009448319673538,
        0.02240164950489998,
        0.05305952951312065,
        -0.06527620553970337,
        -0.016509292647242546,
        0.06503134220838547,
        0.052980951964855194,
        0.06252996623516083,
        0.028560033068060875,
        0.057540662586688995,
        0.03734782338142395,
        0.06590931862592697,
        -0.056216634809970856,
        -0.04369543865323067,
        0.049515970051288605,
        0.047193288803100586,
        -0.06505485624074936,
        0.05876651033759117,
        -0.059549201279878616,
        0.022467447444796562,
        -0.05138611048460007,
        -0.05843731388449669,
        0.015727216377854347,
        0.020106757059693336,
        0.05795484781265259,
        -0.04314534738659859,
        0.015722831711173058,
        0.054117683321237564,
        0.01920062117278576,
        0.034624047577381134,
        0.023328708484768867,
        -0.05195769667625427,
        -0.017296062782406807,
        0.014295779168605804,
        0.007719980552792549,
        -0.008176182396709919,
        0.05028882622718811,
        0.03881058096885681,
        -0.03905021771788597,
        0.01621600054204464,
        0.061343804001808167,
        -0.059943582862615585,
        0.03384162485599518,
        0.05480365827679634,
        -0.022720111533999443,
        0.008887508884072304,
        0.017388194799423218,
        0.006119549740105867,
        0.012514223344624043,
        0.0053930929861962795,
        -0.008695210330188274,
        -0.047723107039928436,
        0.05417419224977493,
        -0.03719991818070412,
        -0.03434338420629501,
        -0.020299749448895454,
        0.02634812518954277,
        0.047809526324272156,
        -0.04675763472914696,
        -0.006752954795956612,
        -0.016806166619062424,
        0.054249200969934464,
        0.056534864008426666,
        0.03872525691986084,
        -0.046950019896030426,
        0.020165592432022095,
        -0.0015662431251257658,
        -0.052600421011447906,
        -0.014639723114669323,
        -0.06306107342243195,
        -0.020460359752178192,
        0.008679007180035114,
        -0.02192590944468975,
        -0.05346255004405975,
        -0.054652947932481766,
        -0.04391951113939285,
        0.06010940670967102,
        0.035044845193624496,
        0.03686128929257393,
        0.02727643959224224,
        0.05348833650350571,
        0.051542434841394424,
        0.027463605627417564,
        0.05911428853869438,
        -0.036717489361763,
        -0.0038018052000552416,
        0.056980811059474945,
        -0.05159005895256996,
        -0.04943157359957695,
        0.05157637223601341,
        -0.05359742045402527,
        -0.02278379537165165,
        0.06158123165369034,
        -0.02022532932460308,
        -0.03541210666298866,
        0.06179254874587059,
        -0.04245436564087868,
        -0.030733557417988777,
        0.032799988985061646,
        -0.024969948455691338,
        0.06253992766141891,
        0.055433981120586395,
        -0.029039911925792694,
        -0.03203500807285309,
        0.06431280076503754,
        0.0021779933013021946,
        0.05675601214170456,
        -0.06580027937889099,
        0.026197465136647224,
        0.02223706617951393,
        -0.01250826008617878,
        -0.06576310098171234,
        -0.0568804107606411,
        -0.03017314150929451,
        -0.05110705643892288,
        -0.06055809557437897,
        -0.05637967213988304,
        -0.0006870778743177652,
        -0.06202831491827965,
        -0.05522529408335686,
        0.03336126729846001,
        -0.02981412597000599,
        -0.059149451553821564,
        0.019346585497260094,
        -0.03376242518424988,
        0.06572641432285309,
        -0.05284691974520683,
        -0.05424650385975838,
        -0.01802678592503071,
        -0.022058820351958275,
        0.03831557184457779,
        -0.06414062529802322,
        -0.042145632207393646,
        -0.06166737899184227,
        0.013404580764472485,
        0.03716633841395378,
        -0.04143844172358513,
        0.06125757098197937,
        -0.03858741745352745,
        -0.06097230315208435,
        0.042404767125844955,
        -0.04797934368252754,
        0.0484679751098156,
        -0.03741445019841194,
        -0.05591472610831261,
        -0.046446654945611954,
        0.014771001413464546,
        -0.06321864575147629,
        0.06605719774961472,
        -0.04127328097820282,
        0.013384897261857986,
        0.03422993794083595,
        0.058200910687446594,
        0.04937458410859108,
        0.03525454178452492,
        0.025266529992222786,
        0.030228210613131523,
        -0.004421460907906294,
        0.05829533562064171,
        0.04245636612176895,
        -0.06434808671474457,
        0.05692480131983757,
        0.053222090005874634,
        -0.06515084952116013,
        0.05234907194972038,
        0.05953201279044151,
        0.061791639775037766,
        -0.04146043211221695,
        0.01375665981322527,
        -0.015848884359002113,
        0.0064386725425720215,
        -0.05482066795229912,
        0.06297837197780609,
        -0.0019396439893171191,
        -0.05652087181806564,
        0.06340404599905014,
        -0.01834769733250141,
        0.004772875923663378,
        0.03878002613782883,
        0.025440549477934837,
        -0.05605309084057808,
        -0.032188426703214645,
        -0.06303081661462784,
        0.04065714031457901,
        0.003386193187907338,
        -0.011190406046807766,
        0.0021728896535933018,
        -0.037710584700107574,
        0.033487651497125626,
        0.04846958816051483,
        -0.018735134974122047,
        -0.040734898298978806,
        -0.017252691090106964,
        0.02844034694135189,
        -0.008467472158372402,
        -0.04793686419725418,
        -0.05246112868189812,
        -0.04617142304778099,
        -0.06075200065970421,
        0.0630391389131546,
        0.03351783752441406,
        0.044418178498744965,
        0.049597833305597305,
        -0.04844297096133232,
        -0.015358658507466316,
        0.01795903593301773,
        0.06416924297809601,
        -0.06601167470216751,
        -0.06398503482341766,
        0.016216041520237923,
        0.0390910767018795,
        0.017947813495993614,
        0.057329561561346054,
        0.0012716115452349186,
        0.062072038650512695,
        0.05982925742864609,
        -0.003666047239676118,
        -0.03435809910297394,
        0.033810295164585114,
        0.0532601997256279,
        0.0400063693523407,
        -0.03570060059428215,
        -0.04764920473098755,
        0.027258113026618958,
        -0.04564256593585014,
        0.04753565788269043,
        -0.062267985194921494,
        0.0026350279804319143,
        0.002604852430522442,
        0.06349296867847443,
        -0.04937280714511871,
        0.05081428587436676,
        -0.02413821965456009,
        -0.026714226230978966,
        -0.056710369884967804,
        -0.019800154492259026,
        -0.053982291370630264,
        0.04313940554857254,
        0.04220839589834213,
        -0.03374303877353668,
        0.045111283659935,
        -0.04577675089240074,
        -0.04495424032211304,
        0.055943578481674194,
        0.04610824957489967,
        -0.02442152239382267,
        -0.04291838780045509,
        -0.06515415012836456,
        0.05091635882854462,
        0.05983481928706169,
        -0.024295158684253693,
        -0.05279698967933655,
        -0.0622589997947216,
        -0.04739220812916756,
        -0.06008369103074074,
        -0.06333589553833008,
        -0.029912758618593216,
        0.013471168465912342,
        -0.011888633482158184,
        -0.03767462819814682,
        0.06072993576526642,
        0.03697744756937027,
        0.051687613129615784,
        0.03369893133640289,
        -0.05087423697113991,
        -0.02197391539812088,
        0.05879034847021103,
        0.034808505326509476,
        -0.04606661945581436,
        -0.008277700282633305,
        0.011502793990075588,
        0.032258402556180954,
        -0.05397183448076248,
        0.053279463201761246,
        0.005594511050730944,
        -0.057204291224479675,
        -0.06397329270839691,
        0.04354685917496681,
        0.02907324582338333,
        0.04864293709397316,
        -0.04930092394351959,
        0.02435164526104927,
        0.04810646176338196,
        0.05177086591720581,
        0.03642915189266205,
        0.044947851449251175,
        -0.018244897946715355,
        -0.01316780224442482,
        -0.03983201086521149,
        0.0002717692404985428,
        0.04249156266450882,
        -0.004738692194223404,
        -0.04860033094882965,
        -0.03738997131586075,
        -0.051778968423604965,
        0.02974875271320343,
        0.025113705545663834,
        -0.06337763369083405,
        -0.06197802349925041,
        0.016643892973661423,
        -0.05075685307383537,
        -0.054782841354608536,
        0.05955349653959274,
        -0.04486604034900665,
        0.004008669871836901,
        0.01202447060495615,
        0.051957257091999054,
        0.01962386630475521,
        0.04410744085907936,
        0.009742180816829205,
        -0.052390020340681076,
        0.06038208305835724,
        0.025279689580202103,
        0.05683537945151329,
        0.05922050029039383,
        -0.06336498260498047,
        -0.02130848355591297,
        0.037848472595214844,
        0.02589157596230507,
        -0.05847179889678955,
        -0.060885537415742874,
        0.04535149037837982,
        -0.020481564104557037,
        -0.049515899270772934,
        0.049657631665468216,
        -0.0455964058637619,
        -0.003890689229592681,
        -0.04591572657227516,
        0.024408549070358276,
        0.00002420205964881461,
        0.05995497107505798,
        -0.04057716950774193,
        0.061129238456487656,
        -0.06328633427619934,
        -0.04872273653745651,
        0.025046994909644127,
        -0.0438745953142643,
        0.0025943629443645477,
        0.0346427820622921,
        -0.06435821205377579,
        -0.024106038734316826,
        0.04362127557396889,
        -0.06486491113901138,
        -0.042486730962991714,
        -0.06340085715055466,
        0.059790655970573425,
        -0.04283919930458069,
        0.050867386162281036,
        0.024078436195850372,
        0.037576377391815186,
        0.04566969722509384,
        -0.01498337835073471,
        0.04457182437181473,
        -0.05314767733216286,
        -0.01735159568488598,
        -0.06446611136198044,
        -0.012660610489547253,
        -0.018627198413014412,
        0.037187423557043076,
        0.02234659157693386,
        0.060141101479530334,
        0.02403533272445202,
        0.03371388465166092,
        0.055245980620384216,
        0.04190335050225258,
        -0.04327944293618202,
        0.017357178032398224,
        -0.056874051690101624,
        0.02434116043150425,
        -0.025852231308817863,
        -0.05239902064204216,
        0.0590510219335556,
        0.025330139324069023,
        -0.0548076294362545,
        -0.06286215037107468,
        0.05603868141770363,
        0.06106953322887421
    ],
    [
        0.04560880735516548,
        0.042433712631464005,
        0.05157255753874779,
        0.035902705043554306,
        -0.0404568575322628,
        0.028260599821805954,
        -0.02812916599214077,
        -0.06223645061254501,
        0.05633159726858139,
        0.032157085835933685,
        0.03349241614341736,
        0.053295742720365524,
        -0.03699436038732529,
        -0.057038966566324234,
        -0.05700325965881348,
        0.06395885348320007,
        0.0034653740003705025,
        0.05900179594755173,
        -0.0601603165268898,
        0.02544068545103073,
        -0.02826007828116417,
        0.032595742493867874,
        0.016085656359791756,
        -0.04366881400346756,
        0.049347203224897385,
        0.042493853718042374,
        0.048484500497579575,
        -0.02667991630733013,
        -0.006823772098869085,
        0.023229366168379784,
        -0.018150581046938896,
        -0.023254532366991043,
        -0.06309492886066437,
        -0.06104379519820213,
        0.052156392484903336,
        0.037001416087150574,
        -0.05967021733522415,
        0.046432409435510635,
        0.003329465864226222,
        0.002914393786340952,
        -0.009550013579428196,
        0.0173395574092865,
        0.026286348700523376,
        -0.04510926082730293,
        0.05012140050530434,
        0.061813853681087494,
        0.05991872772574425,
        0.048764668405056,
        0.038667917251586914,
        0.05231881141662598,
        0.03591402247548103,
        0.06478312611579895,
        -0.05383703112602234,
        0.05065098777413368,
        -0.010357961058616638,
        -0.0656435415148735,
        -0.048029519617557526,
        -0.034519653767347336,
        -0.016646651551127434,
        0.061780743300914764,
        -0.04518776759505272,
        0.016229037195444107,
        -0.05461952090263367,
        0.05414281785488129,
        -0.04243047535419464,
        -0.04792027175426483,
        0.010998275130987167,
        0.06074235960841179,
        -0.0658750832080841,
        0.025890646502375603,
        -0.05790707841515541,
        -0.0489681176841259,
        0.06510934233665466,
        0.03929061070084572,
        -0.04366394504904747,
        -0.04463675990700722,
        0.05981483682990074,
        0.06532879173755646,
        -0.0007986131822690368,
        -0.0479523241519928,
        -0.0015626633539795876,
        0.05990754812955856,
        -0.04418834298849106,
        0.049023378640413284,
        0.061725933104753494,
        0.05298888683319092,
        0.06224408745765686,
        -0.06029665470123291,
        0.049112942069768906,
        0.058128029108047485,
        0.04896041005849838,
        -0.003899461356922984,
        -0.05039701238274574,
        0.01810430735349655,
        -0.054529108107089996,
        0.051957231014966965,
        -0.026622485369443893,
        -0.06142585352063179,
        0.003981484565883875,
        -0.05659330263733864,
        -0.05919129028916359,
        0.022050466388463974,
        0.05497799441218376,
        0.020643820986151695,
        0.06282424926757812,
        -0.04833328723907471,
        -0.04398694261908531,
        0.04677485302090645,
        0.0038172665517777205,
        0.02851494401693344,
        -0.06516372412443161,
        0.06564740091562271,
        -0.06030145287513733,
        -0.018811117857694626,
        -0.00889668706804514,
        0.02167021855711937,
        0.04385336861014366,
        0.01678161323070526,
        0.05928245931863785,
        0.017262468114495277,
        0.019740525633096695,
        0.05946170911192894,
        -0.023650404065847397,
        -0.0525091290473938,
        -0.04592039808630943,
        -0.06300023943185806,
        -0.03108828142285347,
        -0.007811753544956446,
        0.06385747343301773,
        -0.0310985017567873,
        0.03343752771615982,
        -0.052008431404829025,
        -0.06409219652414322,
        0.008744681254029274,
        -0.049806252121925354,
        -0.06009448319673538,
        0.02240164950489998,
        0.05305952951312065,
        -0.06527620553970337,
        -0.016509292647242546,
        0.06503134220838547,
        0.052980951964855194,
        0.06252996623516083,
        0.028560033068060875,
        0.057540662586688995,
        0.03734782338142395,
        0.06590931862592697,
        -0.056216634809970856,
        -0.04369543865323067,
        0.049515970051288605,
        0.047193288803100586,
        -0.06505485624074936,
        0.05876651033759117,
        -0.059549201279878616,
        0.022467447444796562,
        -0.05138611048460007,
        -0.05843731388449669,
        0.015727216377854347,
        0.020106757059693336,
        0.05795484781265259,
        -0.04314534738659859,
        0.015722831711173058,
        0.054117683321237564,
        0.01920062117278576,
        0.034624047577381134,
        0.023328708484768867,
        -0.05195769667625427,
        -0.017296062782406807,
        0.014295779168605804,
        0.007719980552792549,
        -0.008176182396709919,
        0.05028882622718811,
        0.03881058096885681,
        -0.03905021771788597,
        0.01621600054204464,
        0.061343804001808167,
        -0.059943582862615585,
        0.03384162485599518,
        0.05480365827679634,
        -0.022720111533999443,
        0.008887508884072304,
        0.017388194799423218,
        0.006119549740105867,
        0.012514223344624043,
        0.0053930929861962795,
        -0.008695210330188274,
        -0.047723107039928436,
        0.05417419224977493,
        -0.03719991818070412,
        -0.03434338420629501,
        -0.020299749448895454,
        0.02634812518954277,
        0.047809526324272156,
        -0.04675763472914696,
        -0.006752954795956612,
        -0.016806166619062424,
        0.054249200969934464,
        0.056534864008426666,
        0.03872525691986084,
        -0.046950019896030426,
        0.020165592432022095,
        -0.0015662431251257658,
        -0.052600421011447906,
        -0.014639723114669323,
        -0.06306107342243195,
        -0.020460359752178192,
        0.008679007180035114,
        -0.02192590944468975,
        -0.05346255004405975,
        -0.054652947932481766,
        -0.04391951113939285,
        0.06010940670967102,
        0.035044845193624496,
        0.03686128929257393,
        0.02727643959224224,
        0.05348833650350571,
        0.051542434841394424,
        0.027463605627417564,
        0.05911428853869438,
        -0.036717489361763,
        -0.0038018052000552416,
        0.056980811059474945,
        -0.05159005895256996,
        -0.04943157359957695,
        0.05157637223601341,
        -0.05359742045402527,
        -0.02278379537165165,
        0.06158123165369034,
        -0.02022532932460308,
        -0.03541210666298866,
        0.06179254874587059,
        -0.04245436564087868,
        -0.030733557417988777,
        0.032799988985061646,
        -0.024969948455691338,
        0.06253992766141891,
        0.055433981120586395,
        -0.029039911925792694,
        -0.03203500807285309,
        0.06431280076503754,
        0.0021779933013021946,
        0.05675601214170456,
        -0.06580027937889099,
        0.026197465136647224,
        0.02223706617951393,
        -0.01250826008617878,
        -0.06576310098171234,
        -0.0568804107606411,
        -0.03017314150929451,
        -0.05110705643892288,
        -0.06055809557437897,
        -0.05637967213988304,
        -0.0006870778743177652,
        -0.06202831491827965,
        -0.05522529408335686,
        0.03336126729846001,
        -0.02981412597000599,
        -0.059149451553821564,
        0.019346585497260094,
        -0.03376242518424988,
        0.06572641432285309,
        -0.05284691974520683,
        -0.05424650385975838,
        -0.01802678592503071,
        -0.022058820351958275,
        0.03831557184457779,
        -0.06414062529802322,
        -0.042145632207393646,
        -0.06166737899184227,
        0.013404580764472485,
        0.03716633841395378,
        -0.04143844172358513,
        0.06125757098197937,
        -0.03858741745352745,
        -0.06097230315208435,
        0.042404767125844955,
        -0.04797934368252754,
        0.0484679751098156,
        -0.03741445019841194,
        -0.05591472610831261,
        -0.046446654945611954,
        0.014771001413464546,
        -0.06321864575147629,
        0.06605719774961472,
        -0.04127328097820282,
        0.013384897261857986,
        0.03422993794083595,
        0.058200910687446594,
        0.04937458410859108,
        0.03525454178452492,
        0.025266529992222786,
        0.030228210613131523,
        -0.004421460907906294,
        0.05829533562064171,
        0.04245636612176895,
        -0.06434808671474457,
        0.05692480131983757,
        0.053222090005874634,
        -0.06515084952116013,
        0.05234907194972038,
        0.05953201279044151,
        0.061791639775037766,
        -0.04146043211221695,
        0.01375665981322527,
        -0.015848884359002113,
        0.0064386725425720215,
        -0.05482066795229912,
        0.06297837197780609,
        -0.0019396439893171191,
        -0.05652087181806564,
        0.06340404599905014,
        -0.01834769733250141,
        0.004772875923663378,
        0.03878002613782883,
        0.025440549477934837,
        -0.05605309084057808,
        -0.032188426703214645,
        -0.06303081661462784,
        0.04065714031457901,
        0.003386193187907338,
        -0.011190406046807766,
        0.0021728896535933018,
        -0.037710584700107574,
        0.033487651497125626,
        0.04846958816051483,
        -0.018735134974122047,
        -0.040734898298978806,
        -0.017252691090106964,
        0.02844034694135189,
        -0.008467472158372402,
        -0.04793686419725418,
        -0.05246112868189812,
        -0.04617142304778099,
        -0.06075200065970421,
        0.0630391389131546,
        0.03351783752441406,
        0.044418178498744965,
        0.049597833305597305,
        -0.04844297096133232,
        -0.015358658507466316,
        0.01795903593301773,
        0.06416924297809601,
        -0.06601167470216751,
        -0.06398503482341766,
        0.016216041520237923,
        0.0390910767018795,
        0.017947813495993614,
        0.057329561561346054,
        0.0012716115452349186,
        0.062072038650512695,
        0.05982925742864609,
        -0.003666047239676118,
        -0.03435809910297394,
        0.033810295164585114,
        0.0532601997256279,
        0.0400063693523407,
        -0.03570060059428215,
        -0.04764920473098755,
        0.027258113026618958,
        -0.04564256593585014,
        0.04753565788269043,
        -0.062267985194921494,
        0.0026350279804319143,
        0.002604852430522442,
        0.06349296867847443,
        -0.04937280714511871,
        0.05081428587436676,
        -0.02413821965456009,
        -0.026714226230978966,
        -0.056710369884967804,
        -0.019800154492259026,
        -0.053982291370630264,
        0.04313940554857254,
        0.04220839589834213,
        -0.03374303877353668,
        0.045111283659935,
        -0.04577675089240074,
        -0.04495424032211304,
        0.055943578481674194,
        0.04610824957489967,
        -0.02442152239382267,
        -0.04291838780045509,
        -0.06515415012836456,
        0.05091635882854462,
        0.05983481928706169,
        -0.024295158684253693,
        -0.05279698967933655,
        -0.0622589997947216,
        -0.04739220812916756,
        -0.06008369103074074,
        -0.06333589553833008,
        -0.029912758618593216,
        0.013471168465912342,
        -0.011888633482158184,
        -0.03767462819814682,
        0.06072993576526642,
        0.03697744756937027,
        0.051687613129615784,
        0.03369893133640289,
        -0.05087423697113991,
        -0.02197391539812088,
        0.05879034847021103,
        0.034808505326509476,
        -0.04606661945581436,
        -0.008277700282633305,
        0.011502793990075588,
        0.032258402556180954,
        -0.05397183448076248,
        0.053279463201761246,
        0.005594511050730944,
        -0.057204291224479675,
        -0.06397329270839691,
        0.04354685917496681,
        0.02907324582338333,
        0.04864293709397316,
        -0.04930092394351959,
        0.02435164526104927,
        0.04810646176338196,
        0.05177086591720581,
        0.03642915189266205,
        0.044947851449251175,
        -0.018244897946715355,
        -0.01316780224442482,
        -0.03983201086521149,
        0.0002717692404985428,
        0.04249156266450882,
        -0.004738692194223404,
        -0.04860033094882965,
        -0.03738997131586075,
        -0.051778968423604965,
        0.02974875271320343,
        0.025113705545663834,
        -0.06337763369083405,
        -0.06197802349925041,
        0.016643892973661423,
        -0.05075685307383537,
        -0.054782841354608536,
        0.05955349653959274,
        -0.04486604034900665,
        0.004008669871836901,
        0.01202447060495615,
        0.051957257091999054,
        0.01962386630475521,
        0.04410744085907936,
        0.009742180816829205,
        -0.052390020340681076,
        0.06038208305835724,
        0.025279689580202103,
        0.05683537945151329,
        0.05922050029039383,
        -0.06336498260498047,
        -0.02130848355591297,
        0.037848472595214844,
        0.02589157596230507,
        -0.05847179889678955,
        -0.060885537415742874,
        0.04535149037837982,
        -0.020481564104557037,
        -0.049515899270772934,
        0.049657631665468216,
        -0.0455964058637619,
        -0.003890689229592681,
        -0.04591572657227516,
        0.024408549070358276,
        0.00002420205964881461,
        0.05995497107505798,
        -0.04057716950774193,
        0.061129238456487656,
        -0.06328633427619934,
        -0.04872273653745651,
        0.025046994909644127,
        -0.0438745953142643,
        0.0025943629443645477,
        0.0346427820622921,
        -0.06435821205377579,
        -0.024106038734316826,
        0.04362127557396889,
        -0.06486491113901138,
        -0.042486730962991714,
        -0.06340085715055466,
        0.059790655970573425,
        -0.04283919930458069,
        0.050867386162281036,
        0.024078436195850372,
        0.037576377391815186,
        0.04566969722509384,
        -0.01498337835073471,
        0.04457182437181473,
        -0.05314767733216286,
        -0.01735159568488598,
        -0.06446611136198044,
        -0.012660610489547253,
        -0.018627198413014412,
        0.037187423557043076,
        0.02234659157693386,
        0.060141101479530334,
        0.02403533272445202,
        0.03371388465166092,
        0.055245980620384216,
        0.04190335050225258,
        -0.04327944293618202,
        0.017357178032398224,
        -0.056874051690101624,
        0.02434116043150425,
        -0.025852231308817863,
        -0.05239902064204216,
        0.0590510219335556,
        0.025330139324069023,
        -0.0548076294362545,
        -0.06286215037107468,
        0.05603868141770363,
        0.06106953322887421
    ],
    [
        0.04386160150170326,
        0.004878793377429247,
        0.051204126328229904,
        0.03781474381685257,
        -0.029887456446886063,
        0.00197131116874516,
        -0.02957642450928688,
        -0.0605132021009922,
        0.05735238641500473,
        0.04475080221891403,
        0.03435512259602547,
        0.05652876943349838,
        -0.04184345901012421,
        -0.05827218294143677,
        -0.05782362446188927,
        0.06139485910534859,
        0.0026225082110613585,
        0.05877554044127464,
        -0.05728708580136299,
        -0.014748398214578629,
        0.0057430025190114975,
        0.04288610443472862,
        0.033118780702352524,
        -0.054011911153793335,
        0.04842080920934677,
        0.045954521745443344,
        0.05638209730386734,
        -0.040919229388237,
        -0.014017746783792973,
        0.043077148497104645,
        -0.030841831117868423,
        -0.01867005228996277,
        -0.05953047424554825,
        -0.06212661415338516,
        0.05134541168808937,
        0.03705548495054245,
        -0.05824197456240654,
        0.04223683103919029,
        0.014942185021936893,
        -0.012210044078528881,
        0.010397843085229397,
        0.029141394421458244,
        -0.0003104203788097948,
        -0.023871060460805893,
        0.04700760543346405,
        0.06100229173898697,
        0.05859614536166191,
        0.04925145208835602,
        0.058879513293504715,
        0.046492528170347214,
        0.05399269610643387,
        0.06190007925033569,
        -0.05646249279379845,
        0.053920917212963104,
        -0.020420964807271957,
        -0.0626504197716713,
        -0.05611345171928406,
        -0.03675498813390732,
        -0.0041251592338085175,
        0.05848957225680351,
        -0.049104951322078705,
        0.03046787902712822,
        -0.055524859577417374,
        0.058911070227622986,
        -0.029876459389925003,
        -0.04423067718744278,
        -0.006295470520853996,
        0.05801299214363098,
        -0.06269029527902603,
        0.027543749660253525,
        -0.05918065831065178,
        -0.05482352897524834,
        0.06141314283013344,
        0.02493392862379551,
        -0.02413037233054638,
        -0.036505986005067825,
        0.060187894850969315,
        0.06226795166730881,
        0.00857179332524538,
        -0.05334804579615593,
        0.008526950143277645,
        0.0584663562476635,
        -0.04760580137372017,
        0.05941992625594139,
        0.06150667369365692,
        0.05538341775536537,
        0.060917556285858154,
        -0.05546755716204643,
        0.05054113268852234,
        0.05622958391904831,
        -0.007511388510465622,
        -0.02141655795276165,
        -0.060438960790634155,
        0.00234209350310266,
        -0.04821322113275528,
        0.05588807910680771,
        -0.01929645985364914,
        -0.06063414365053177,
        0.00037661680835299194,
        -0.056182220578193665,
        -0.05530738830566406,
        0.046529144048690796,
        0.054428745061159134,
        0.0373135507106781,
        0.0604555569589138,
        -0.056083302944898605,
        -0.033113155514001846,
        0.05633974075317383,
        -0.006062749773263931,
        0.05028124526143074,
        -0.062117815017700195,
        0.06257547438144684,
        -0.0576481819152832,
        -0.008920897729694843,
        -0.03532977029681206,
        0.012718389742076397,
        0.05457182601094246,
        -0.015661993995308876,
        0.058654338121414185,
        0.03369104117155075,
        0.032778818160295486,
        0.06101204454898834,
        -0.01485901977866888,
        -0.04663334786891937,
        -0.03130031004548073,
        -0.05947209522128105,
        -0.030766312032938004,
        -0.03246719017624855,
        0.05934596434235573,
        -0.006522315554320812,
        0.039489150047302246,
        -0.03920399770140648,
        -0.0598868653178215,
        0.016759412363171577,
        -0.05169912800192833,
        -0.05503511428833008,
        0.03546828404068947,
        0.05816568061709404,
        -0.06232944503426552,
        0.006025566253811121,
        0.06150113046169281,
        0.05469466745853424,
        0.057945407927036285,
        -0.006281814072281122,
        0.0522378645837307,
        0.021252885460853577,
        0.06273862719535828,
        -0.0540170893073082,
        -0.029250197112560272,
        0.050266195088624954,
        0.05644455924630165,
        -0.062370773404836655,
        0.059221550822257996,
        -0.055351823568344116,
        0.03492472693324089,
        -0.05309455469250679,
        -0.060167428106069565,
        0.02026582695543766,
        0.0021735315676778555,
        0.059709399938583374,
        -0.019271861761808395,
        -0.02003803476691246,
        0.04633457213640213,
        0.018190180882811546,
        0.0327174998819828,
        0.03691801056265831,
        -0.037744246423244476,
        0.006088246125727892,
        -0.001788206398487091,
        0.007528714369982481,
        -0.02143775299191475,
        0.046233877539634705,
        0.02851094678044319,
        -0.044287510216236115,
        0.02799791283905506,
        0.06161525845527649,
        -0.05978066846728325,
        0.03884164243936539,
        0.04609804227948189,
        -0.04719449579715729,
        -0.0042586373165249825,
        0.048332083970308304,
        0.0003827465116046369,
        0.02002321183681488,
        0.012091477401554585,
        -0.0032490717712789774,
        -0.05495169386267662,
        0.05237041786313057,
        -0.03415093943476677,
        -0.04649360850453377,
        -0.03656567260622978,
        0.0453944131731987,
        0.04960716515779495,
        -0.04117736592888832,
        -0.028252124786376953,
        -0.032054562121629715,
        0.046693410724401474,
        0.0559602826833725,
        0.02564609982073307,
        -0.04330109432339668,
        0.03902100771665573,
        -0.028411760926246643,
        -0.055032774806022644,
        -0.020378218963742256,
        -0.05890556797385216,
        0.001901245559565723,
        0.0039485152810812,
        -0.009730914607644081,
        -0.055390700697898865,
        -0.03482964634895325,
        -0.04349150508642197,
        0.05793646723031998,
        0.04510121792554855,
        0.039395496249198914,
        0.011493409983813763,
        0.052521560341119766,
        0.05231843516230583,
        -0.002297999570146203,
        0.061288733035326004,
        -0.05000719800591469,
        -0.028408212587237358,
        0.04707995802164078,
        -0.053644418716430664,
        -0.03392661362886429,
        0.05670107528567314,
        -0.05124657228589058,
        -0.011406796053051949,
        0.045138709247112274,
        -0.0015695445472374558,
        -0.04363732039928436,
        0.06157614290714264,
        -0.05416848883032799,
        -0.027221519500017166,
        0.03712407499551773,
        -0.04428143799304962,
        0.060610733926296234,
        0.05768691375851631,
        -0.016104141250252724,
        -0.03513412922620773,
        0.06043500080704689,
        0.0008176254341378808,
        0.05770216882228851,
        -0.06270087510347366,
        0.03725823760032654,
        0.04099062830209732,
        -0.007513313088566065,
        -0.062251195311546326,
        -0.03789553791284561,
        -0.03804983198642731,
        -0.049585793167352676,
        -0.06133843958377838,
        -0.05374084413051605,
        -0.02049369364976883,
        -0.06056303158402443,
        -0.05564252659678459,
        0.021787498146295547,
        -0.035538170486688614,
        -0.05925671011209488,
        0.01843726448714733,
        -0.032759059220552444,
        0.06263963878154755,
        -0.050345953553915024,
        -0.047297153621912,
        -0.00011049534077756107,
        -0.015364058315753937,
        0.03459169343113899,
        -0.05777747556567192,
        -0.020129021257162094,
        -0.05868604779243469,
        0.012230859138071537,
        0.0224901270121336,
        -0.05714291334152222,
        0.06168269366025925,
        -0.03756515681743622,
        -0.056850288063287735,
        0.014819839037954807,
        -0.04580718278884888,
        0.04607398435473442,
        -0.04635205864906311,
        -0.0525023378431797,
        -0.058083076030015945,
        0.04182683303952217,
        -0.061987899243831635,
        0.06281467527151108,
        -0.008657516911625862,
        0.013987671583890915,
        0.028544027358293533,
        0.056156206876039505,
        0.05629948899149895,
        0.021232683211565018,
        -0.0012813182547688484,
        0.026002489030361176,
        -0.012046806514263153,
        0.051521800458431244,
        0.050933241844177246,
        -0.06143582984805107,
        0.049483347684144974,
        0.05730250105261803,
        -0.06132534518837929,
        0.05174853652715683,
        0.05940155312418938,
        0.05929982289671898,
        -0.027738843113183975,
        0.021892428398132324,
        -0.0012998159509152174,
        0.0023888887371867895,
        -0.045173097401857376,
        0.06136632710695267,
        -0.009368634782731533,
        -0.045591454952955246,
        0.061596982181072235,
        -0.04463217034935951,
        0.008521045558154583,
        0.04624504968523979,
        0.04270246997475624,
        -0.056475553661584854,
        -0.02739095129072666,
        -0.0611279271543026,
        0.04240061342716217,
        -0.012065259739756584,
        0.015102434903383255,
        -0.020501716062426567,
        -0.03303544595837593,
        0.0456976555287838,
        0.04514465853571892,
        -0.023973023518919945,
        -0.04986317455768585,
        0.008391189388930798,
        0.03627254441380501,
        -0.01428507175296545,
        -0.04091868922114372,
        -0.045231107622385025,
        -0.04926416277885437,
        -0.05775285139679909,
        0.05977152660489082,
        0.05418612062931061,
        0.019780799746513367,
        0.04306264594197273,
        -0.04435350373387337,
        -0.0292709618806839,
        0.022631781175732613,
        0.06011446937918663,
        -0.06277303397655487,
        -0.06216784939169884,
        0.016910484060645103,
        0.03223348781466484,
        0.016703201457858086,
        0.05682239308953285,
        0.019084947183728218,
        0.05569127947092056,
        0.06043832004070282,
        0.0058585721999406815,
        -0.044034212827682495,
        -0.028372013941407204,
        0.05593788996338844,
        0.03673432767391205,
        -0.03562379628419876,
        -0.051814109086990356,
        0.03575244918465614,
        -0.052458506077528,
        0.03973247483372688,
        -0.058930784463882446,
        0.030531112104654312,
        0.014396482147276402,
        0.056655559688806534,
        -0.05200204998254776,
        0.060985028743743896,
        -0.024216491729021072,
        0.018086407333612442,
        -0.058479469269514084,
        -0.040505699813365936,
        -0.05436293035745621,
        0.051559824496507645,
        0.0347539521753788,
        -0.019126759842038155,
        0.03407350555062294,
        -0.05161218345165253,
        -0.03927185758948326,
        0.059530045837163925,
        0.05556629225611687,
        -0.04403902217745781,
        -0.04622489959001541,
        -0.06208479404449463,
        0.042720843106508255,
        0.054453957825899124,
        -0.03212595731019974,
        -0.042033832520246506,
        -0.05696439743041992,
        -0.03989819809794426,
        -0.05189497023820877,
        -0.062498681247234344,
        -0.027023369446396828,
        0.026072699576616287,
        -0.019624358043074608,
        0.006245547439903021,
        0.058377914130687714,
        0.04234137386083603,
        0.03475965932011604,
        0.03319040685892105,
        -0.05614545941352844,
        -0.031466417014598846,
        0.06005179136991501,
        0.041250064969062805,
        -0.05090829357504845,
        -0.002830443438142538,
        -0.01509097684174776,
        0.03921284154057503,
        -0.05642877146601677,
        0.05302213132381439,
        0.017025822773575783,
        -0.05508534610271454,
        -0.06027405709028244,
        0.04779575392603874,
        0.047542985528707504,
        0.041466306895017624,
        -0.05599669739603996,
        0.020795833319425583,
        0.04749074950814247,
        0.04976925253868103,
        0.027944114059209824,
        0.05765700340270996,
        0.013697081245481968,
        0.0000664908075123094,
        -0.051945604383945465,
        0.04670882597565651,
        0.031420718878507614,
        -0.025593005120754242,
        -0.03256387263536453,
        -0.04246765375137329,
        -0.04284967854619026,
        0.04350414127111435,
        0.02208096720278263,
        -0.06228522211313248,
        -0.0617448166012764,
        -0.021886304020881653,
        -0.057058099657297134,
        -0.03488358482718468,
        0.052895691245794296,
        -0.02746943198144436,
        0.014391643926501274,
        -0.01770446076989174,
        0.05860459804534912,
        0.02213585376739502,
        0.05168870836496353,
        0.03523162007331848,
        -0.05349026992917061,
        0.059004876762628555,
        0.002475868444889784,
        0.05540502071380615,
        0.06000636890530586,
        -0.06204036995768547,
        -0.012450383976101875,
        0.053075022995471954,
        0.03759876638650894,
        -0.05368157848715782,
        -0.05867292732000351,
        0.02707342803478241,
        -0.03244079649448395,
        -0.0441206619143486,
        0.05740977078676224,
        -0.04294799640774727,
        -0.02543937973678112,
        -0.049044229090213776,
        0.04471825435757637,
        0.02607639878988266,
        0.05987828969955444,
        -0.04727557301521301,
        0.06018492951989174,
        -0.06136806681752205,
        -0.0555766262114048,
        0.03724095970392227,
        -0.020544590428471565,
        0.009637070819735527,
        0.04880405217409134,
        -0.06259641796350479,
        0.011956173926591873,
        0.01892108842730522,
        -0.06045501306653023,
        -0.04554818943142891,
        -0.0611390694975853,
        0.05114463344216347,
        -0.048711251467466354,
        0.048065073788166046,
        0.03325994685292244,
        0.0318368524312973,
        0.05084225535392761,
        -0.00783947017043829,
        0.03756287693977356,
        -0.04867750406265259,
        -0.04093379154801369,
        -0.06135731190443039,
        -0.023892322555184364,
        -0.02959022857248783,
        0.039974573999643326,
        0.04110198840498924,
        0.05719983950257301,
        0.02114837057888508,
        0.05491751432418823,
        0.05193541944026947,
        0.04413052275776863,
        -0.05256867781281471,
        0.027944689616560936,
        -0.05764191225171089,
        0.0076732635498046875,
        -0.0008884106646291912,
        -0.055128298699855804,
        0.06017928943037987,
        0.016031719744205475,
        -0.0506417453289032,
        -0.061738163232803345,
        0.05328832194209099,
        0.05449323728680611
    ],
    [
        0.023737674579024315,
        0.006158338859677315,
        0.03776412829756737,
        0.03664735332131386,
        -0.04400712624192238,
        -0.01364145241677761,
        -0.04388037696480751,
        -0.06158369034528732,
        0.05934062972664833,
        0.053258854895830154,
        0.03629019856452942,
        0.0569438636302948,
        -0.03860965743660927,
        -0.05991968512535095,
        -0.05559009313583374,
        0.0621594600379467,
        0.0046898615546524525,
        0.059845391660928726,
        -0.058624450117349625,
        -0.019088270142674446,
        0.004344309214502573,
        0.03852095827460289,
        0.038355447351932526,
        -0.0452580489218235,
        0.040577232837677,
        0.053476765751838684,
        0.059224601835012436,
        -0.046486515551805496,
        -0.03499358519911766,
        0.03158062323927879,
        -0.0382048599421978,
        -0.011823462322354317,
        -0.05913786590099335,
        -0.0611242912709713,
        0.04950800538063049,
        0.05293253809213638,
        -0.05817750096321106,
        0.04639824852347374,
        -0.05072148144245148,
        -0.007670585066080093,
        0.012244286015629768,
        0.026243148371577263,
        0.0012687635608017445,
        -0.04050310701131821,
        0.05625499039888382,
        0.06016859784722328,
        0.06029545143246651,
        0.05369502678513527,
        0.059265486896038055,
        0.04684564098715782,
        0.05792434886097908,
        0.0623277947306633,
        -0.054435692727565765,
        0.05701560527086258,
        0.005321780685335398,
        -0.06283654272556305,
        -0.05264861881732941,
        -0.032229404896497726,
        -0.02160673961043358,
        0.057646896690130234,
        -0.053098030388355255,
        0.014338141307234764,
        -0.05343218892812729,
        0.06090284883975983,
        -0.040323350578546524,
        -0.026204882189631462,
        -0.00825529359281063,
        0.058572493493556976,
        -0.06280737370252609,
        0.042104654014110565,
        -0.06065398454666138,
        -0.029219890013337135,
        0.0610121451318264,
        0.0364525206387043,
        -0.018268072977662086,
        -0.026118649169802666,
        0.05995408818125725,
        0.06251561641693115,
        0.012624780647456646,
        -0.056876908987760544,
        -0.0035285730846226215,
        0.058446407318115234,
        -0.05040924623608589,
        0.05557752028107643,
        0.05991420894861221,
        0.05610977113246918,
        0.06072509288787842,
        -0.04881400242447853,
        0.0556088462471962,
        0.05370407924056053,
        0.007809402421116829,
        -0.012426270171999931,
        -0.06019414961338043,
        0.004689415451139212,
        -0.05002472177147865,
        0.05291653051972389,
        -0.03222665935754776,
        -0.060113269835710526,
        0.016334615647792816,
        -0.056414004415273666,
        -0.05486823618412018,
        0.04713046923279762,
        0.055138107389211655,
        0.04537470266222954,
        0.06138930097222328,
        -0.06048838794231415,
        -0.02888181433081627,
        0.059518322348594666,
        -0.004094031639397144,
        0.05017326399683952,
        -0.06260799616575241,
        0.06266477704048157,
        -0.0582192987203598,
        -0.0012443212326616049,
        -0.011865759268403053,
        0.029853546991944313,
        0.05247090756893158,
        -0.024939557537436485,
        0.054070789366960526,
        0.034060828387737274,
        0.030825091525912285,
        0.06023166701197624,
        0.002900006715208292,
        -0.0490129329264164,
        -0.02993069216609001,
        -0.059562746435403824,
        -0.03300977498292923,
        -0.04254394397139549,
        0.0586688257753849,
        0.01418290100991726,
        0.053268447518348694,
        -0.037542443722486496,
        -0.05988448113203049,
        -0.011113638058304787,
        -0.04804748296737671,
        -0.055988818407058716,
        0.04157938063144684,
        0.05708818882703781,
        -0.06275641173124313,
        0.005028418265283108,
        0.06147066131234169,
        0.05659462511539459,
        0.058224428445100784,
        -0.006820290349423885,
        0.04676085710525513,
        0.029639480635523796,
        0.06272495537996292,
        -0.056236665695905685,
        -0.041736289858818054,
        0.04516616463661194,
        0.04829665273427963,
        -0.062321823090314865,
        0.06269775331020355,
        -0.0578177236020565,
        0.01632736623287201,
        -0.055139873176813126,
        -0.060583800077438354,
        0.03162549436092377,
        0.0197282787412405,
        0.060176193714141846,
        -0.019739490002393723,
        -0.042240869253873825,
        0.04842051491141319,
        -0.008466889150440693,
        0.038429006934165955,
        0.041316088289022446,
        -0.03793119639158249,
        0.0077377185225486755,
        -0.009914667345583439,
        -0.00974054355174303,
        0.004291811492294073,
        0.04720199108123779,
        0.03719117492437363,
        -0.04500711336731911,
        0.048544351011514664,
        0.06168646737933159,
        -0.05705157667398453,
        0.04072858765721321,
        0.04866969585418701,
        -0.052315615117549896,
        -0.009871633723378181,
        0.0505124069750309,
        -0.000030193195925676264,
        0.010701827704906464,
        0.0009123852360062301,
        -0.016123758628964424,
        -0.05030826851725578,
        0.05217890068888664,
        -0.035138968378305435,
        -0.020915048196911812,
        -0.014861734583973885,
        0.046763572841882706,
        0.05266766622662544,
        -0.047776803374290466,
        -0.04392506554722786,
        -0.04712187871336937,
        0.041469115763902664,
        0.05659960210323334,
        0.041091255843639374,
        -0.023744110018014908,
        0.034407999366521835,
        -0.023735523223876953,
        -0.06047511845827103,
        -0.036498602479696274,
        -0.05892809107899666,
        -0.007051346357911825,
        -0.0009217802435159683,
        -0.020865170285105705,
        -0.05191720277070999,
        -0.041606977581977844,
        -0.042180247604846954,
        0.0598316565155983,
        0.04155723378062248,
        0.049792297184467316,
        0.02428894303739071,
        0.048334646970033646,
        0.04817042872309685,
        -0.012334506958723068,
        0.05898907780647278,
        -0.05297470837831497,
        -0.030722659081220627,
        0.05226026475429535,
        -0.05467388033866882,
        -0.033270929008722305,
        0.04517355561256409,
        -0.009101892821490765,
        -0.005278630647808313,
        0.051770374178886414,
        0.006011412478983402,
        -0.03328833356499672,
        0.06052772328257561,
        -0.027565743774175644,
        -0.031472623348236084,
        0.04061563313007355,
        -0.033948950469493866,
        0.060172244906425476,
        0.05748111382126808,
        -0.03480501100420952,
        -0.03780454024672508,
        0.061580587178468704,
        0.01339084841310978,
        0.05675463005900383,
        -0.06270759552717209,
        0.03499603271484375,
        0.043659742921590805,
        0.004292841535061598,
        -0.0627593845129013,
        -0.040193550288677216,
        -0.03128214180469513,
        -0.049590785056352615,
        -0.061887726187705994,
        -0.05413030833005905,
        -0.0345640629529953,
        -0.05972329154610634,
        -0.05623290687799454,
        0.03016749955713749,
        -0.04357792064547539,
        -0.05560067296028137,
        0.026930343359708786,
        -0.03566398471593857,
        0.0626932829618454,
        -0.05259373039007187,
        -0.052214913070201874,
        0.003545325016602874,
        -0.04009280726313591,
        0.026483459398150444,
        -0.05549050495028496,
        -0.020075635984539986,
        -0.057538654655218124,
        0.0254595298320055,
        0.023759739473462105,
        -0.05641591548919678,
        0.0614175908267498,
        -0.027009258046746254,
        -0.057854220271110535,
        0.00796802993863821,
        -0.049312375485897064,
        0.040391262620687485,
        -0.02296581119298935,
        -0.05004964768886566,
        -0.058051493018865585,
        0.04556475952267647,
        -0.062029872089624405,
        0.0629567876458168,
        -0.017889954149723053,
        0.00045080066774971783,
        0.03605332598090172,
        0.05632226541638374,
        0.055872175842523575,
        0.011954543180763721,
        0.0011882730759680271,
        0.015248997136950493,
        -0.021621540188789368,
        0.05010431259870529,
        0.04921606183052063,
        -0.061543289572000504,
        0.050746139138936996,
        0.060606420040130615,
        -0.06065553054213524,
        0.04725070297718048,
        0.060396093875169754,
        0.05568917095661163,
        -0.024327881634235382,
        0.008127046748995781,
        0.02947118505835533,
        -0.00247926265001297,
        -0.05074431747198105,
        0.05936723202466965,
        -0.014350304380059242,
        -0.020279841497540474,
        0.06068791449069977,
        -0.03621894121170044,
        -0.013163125142455101,
        0.032189518213272095,
        0.04020419716835022,
        -0.05722030997276306,
        -0.03335846588015556,
        -0.06157286837697029,
        0.036774132400751114,
        -0.01854134164750576,
        0.029852300882339478,
        -0.025836937129497528,
        -0.04639716073870659,
        0.02954474650323391,
        0.04973354935646057,
        -0.026760626584291458,
        -0.04197477549314499,
        0.03555365279316902,
        0.03833618015050888,
        -0.011295588687062263,
        -0.04859096184372902,
        -0.047930989414453506,
        -0.05569446459412575,
        -0.05908606946468353,
        0.05953622981905937,
        0.05374372750520706,
        0.014028080739080906,
        0.031624093651771545,
        -0.047574419528245926,
        -0.014606514014303684,
        0.006983015686273575,
        0.060126591473817825,
        -0.06289854645729065,
        -0.06205243244767189,
        0.007112815510481596,
        0.031560998409986496,
        -0.0021061436273157597,
        0.0543244369328022,
        0.010429122485220432,
        0.05263882130384445,
        0.05842800810933113,
        0.015812935307621956,
        -0.04987500607967377,
        -0.030163174495100975,
        0.058930810540914536,
        0.033997371792793274,
        -0.040552303194999695,
        -0.05334316939115524,
        0.03343834728002548,
        -0.044058073312044144,
        0.031573716551065445,
        -0.05681311711668968,
        0.024546831846237183,
        0.015359926968812943,
        0.05930808186531067,
        -0.053896259516477585,
        0.06014860421419144,
        0.005952371750026941,
        -0.015759125351905823,
        -0.06032666563987732,
        -0.04451875388622284,
        -0.05617794021964073,
        0.04592643678188324,
        0.0327799953520298,
        -0.024666456505656242,
        0.030956095084547997,
        -0.0517490990459919,
        -0.017176847904920578,
        0.05813104659318924,
        0.047914016991853714,
        -0.05225051939487457,
        -0.028389999642968178,
        -0.06225990876555443,
        0.036472026258707047,
        0.054885704070329666,
        -0.021770423278212547,
        -0.044866979122161865,
        -0.05532919242978096,
        -0.05211513862013817,
        -0.04485270380973816,
        -0.06225370615720749,
        -0.04222891479730606,
        -0.0011858762009069324,
        -0.004607597831636667,
        -0.033687788993120193,
        0.057813894003629684,
        0.022187523543834686,
        0.04171203821897507,
        0.049774374812841415,
        -0.05575844645500183,
        -0.03484001010656357,
        0.06051085889339447,
        0.055950917303562164,
        -0.05445830151438713,
        -0.0028374679386615753,
        -0.012644817121326923,
        0.03316052258014679,
        -0.057639557868242264,
        0.05425659939646721,
        0.004464611876755953,
        -0.057614076882600784,
        -0.05938741937279701,
        0.05322738364338875,
        0.02998066507279873,
        0.044877152889966965,
        -0.050040729343891144,
        0.008264262229204178,
        0.010564887896180153,
        0.03957726061344147,
        -0.005816727876663208,
        0.05937234312295914,
        0.004849160090088844,
        -0.007180578075349331,
        -0.0505470372736454,
        0.03399686887860298,
        0.036620356142520905,
        -0.045427534729242325,
        -0.023937303572893143,
        -0.029006600379943848,
        -0.04623511806130409,
        0.04287347570061684,
        0.020445020869374275,
        -0.061369236558675766,
        -0.06151514872908592,
        0.004460310563445091,
        -0.05842312425374985,
        0.008392445743083954,
        0.0577009879052639,
        -0.00589503301307559,
        -0.012378818355500698,
        -0.00290216994471848,
        0.05992574617266655,
        0.01658165268599987,
        0.050212908536195755,
        0.05940091609954834,
        -0.053547222167253494,
        0.05897599831223488,
        0.010931884869933128,
        0.054043613374233246,
        0.061961494386196136,
        -0.06170644238591194,
        -0.017514420673251152,
        0.04607933387160301,
        0.03587876260280609,
        -0.057203613221645355,
        -0.05940927192568779,
        0.024237431585788727,
        -0.030106553807854652,
        -0.03530322387814522,
        0.05592145025730133,
        -0.014811614528298378,
        -0.03449580445885658,
        -0.03992914780974388,
        0.0562962107360363,
        0.026285627856850624,
        0.060032110661268234,
        -0.043417252600193024,
        0.061139583587646484,
        -0.06138426437973976,
        -0.05487634986639023,
        0.015843376517295837,
        -0.053796328604221344,
        0.010186473838984966,
        0.0442965067923069,
        -0.06279773265123367,
        -0.0035938944201916456,
        0.03327412158250809,
        -0.06067982316017151,
        -0.040656913071870804,
        -0.06198219209909439,
        0.053679049015045166,
        -0.04961024969816208,
        0.04854695498943329,
        0.030257591977715492,
        0.048159997910261154,
        0.05177520588040352,
        0.030614683404564857,
        0.04211878031492233,
        -0.04196002706885338,
        -0.03188456594944,
        -0.06163710728287697,
        -0.028034184128046036,
        -0.023570381104946136,
        0.0457901731133461,
        0.01777440309524536,
        0.054890718311071396,
        0.017693251371383667,
        0.056556615978479385,
        0.05084933713078499,
        0.04409720003604889,
        -0.05910229682922363,
        0.03013557754456997,
        -0.05846642702817917,
        0.013551099225878716,
        0.02904108352959156,
        -0.05453362315893173,
        0.05794059485197067,
        0.011148611083626747,
        -0.03810979798436165,
        -0.06209355965256691,
        0.039492618292570114,
        0.040827006101608276
    ],
    [
        0.04048823192715645,
        0.0412895530462265,
        0.05246651917695999,
        0.033032678067684174,
        -0.04597514867782593,
        0.03969697281718254,
        -0.03284483030438423,
        -0.06416106969118118,
        0.058973778039216995,
        0.02722197212278843,
        0.03706107288599014,
        0.03752374276518822,
        -0.03147575631737709,
        -0.05725671350955963,
        -0.0579061433672905,
        0.06321081519126892,
        -0.006312093697488308,
        0.062404897063970566,
        -0.060138218104839325,
        0.021679172292351723,
        -0.02768787182867527,
        0.029110636562108994,
        0.015729140490293503,
        -0.039462506771087646,
        0.049853283911943436,
        0.047841381281614304,
        0.05215079337358475,
        -0.025175131857395172,
        -0.022131405770778656,
        0.006824007723480463,
        0.0030992028769105673,
        -0.009863722138106823,
        -0.06288363039493561,
        -0.06014581769704819,
        0.05181729793548584,
        0.051979660987854004,
        -0.06042568385601044,
        0.04740375652909279,
        -0.02772519364953041,
        0.006606189999729395,
        -0.026088209822773933,
        0.026117555797100067,
        0.021512843668460846,
        -0.04003612697124481,
        0.041451405733823776,
        0.06084262952208519,
        0.05263741686940193,
        0.048698730766773224,
        0.04385630786418915,
        0.047967731952667236,
        0.043809741735458374,
        0.06482396274805069,
        -0.05431051924824715,
        0.05712379142642021,
        0.011082095094025135,
        -0.06594865024089813,
        -0.04771237075328827,
        -0.018353881314396858,
        -0.01683119125664234,
        0.06070328876376152,
        -0.05067703127861023,
        0.01778010092675686,
        -0.05442555621266365,
        0.05693013221025467,
        -0.04733585938811302,
        -0.053198885172605515,
        -0.0018301071831956506,
        0.06059937924146652,
        -0.06619830429553986,
        0.03553778678178787,
        -0.057594697922468185,
        -0.05042804405093193,
        0.06551747769117355,
        0.050321437418460846,
        -0.04402599856257439,
        -0.033950239419937134,
        0.058337271213531494,
        0.06597431749105453,
        0.008287802338600159,
        -0.038363292813301086,
        -0.01119418628513813,
        0.05718783289194107,
        -0.050514284521341324,
        0.045007508248090744,
        0.05797293037176132,
        0.05137361213564873,
        0.06186617538332939,
        -0.06042121350765228,
        0.0574503093957901,
        0.05413719639182091,
        0.05438529700040817,
        0.0010642335982993245,
        -0.050720009952783585,
        0.030445104464888573,
        -0.05375633388757706,
        0.04823535308241844,
        -0.04200172796845436,
        -0.061947330832481384,
        0.02342759259045124,
        -0.05216875299811363,
        -0.058849625289440155,
        0.005517337471246719,
        0.05727390572428703,
        0.02925538830459118,
        0.06163472309708595,
        -0.046033840626478195,
        -0.042590342462062836,
        0.04259968549013138,
        -0.010965360328555107,
        0.04039290174841881,
        -0.06537573039531708,
        0.06614062935113907,
        -0.06252583116292953,
        -0.029961764812469482,
        0.002718675183132291,
        -0.0022535226307809353,
        0.038614917546510696,
        0.0025937308091670275,
        0.06007765606045723,
        0.017688840627670288,
        0.006972571834921837,
        0.06087452173233032,
        -0.009293058887124062,
        -0.03795268386602402,
        -0.04971398785710335,
        -0.06259004026651382,
        -0.034890566021203995,
        0.007467607967555523,
        0.06410574167966843,
        -0.035812318325042725,
        0.03295907750725746,
        -0.05428871884942055,
        -0.061787400394678116,
        -0.01684737391769886,
        -0.04603305831551552,
        -0.06022436544299126,
        0.024435745552182198,
        0.051060449331998825,
        -0.06538616865873337,
        0.005597209557890892,
        0.06574471294879913,
        0.05523180961608887,
        0.06233178824186325,
        0.025245608761906624,
        0.05954527109861374,
        0.02449188567698002,
        0.0660666972398758,
        -0.053217221051454544,
        -0.04935922101140022,
        0.039911843836307526,
        0.05091940611600876,
        -0.06514602899551392,
        0.05972788855433464,
        -0.06153455749154091,
        0.04289497807621956,
        -0.053145572543144226,
        -0.061314232647418976,
        0.005199508275836706,
        0.010820365510880947,
        0.05769960582256317,
        -0.039517369121313095,
        0.01762642152607441,
        0.0531628392636776,
        0.016733460128307343,
        0.04880046099424362,
        -0.004120344761759043,
        -0.049889810383319855,
        -0.009160276502370834,
        0.01551938708871603,
        0.0037335362285375595,
        0.0009170141420327127,
        0.052064232528209686,
        0.03539951145648956,
        -0.03355855122208595,
        0.011580124497413635,
        0.06107446551322937,
        -0.058306049555540085,
        0.030425401404500008,
        0.05662710592150688,
        -0.03141721710562706,
        0.001850330037996173,
        0.010338637046515942,
        -0.020971577614545822,
        0.017001839354634285,
        0.005646761041134596,
        -0.013395699672400951,
        -0.04061185196042061,
        0.05648258328437805,
        -0.0462489053606987,
        -0.0329948365688324,
        -0.02778770960867405,
        0.019714294001460075,
        0.049472544342279434,
        -0.050984326750040054,
        -0.02708577737212181,
        -0.00991330947726965,
        0.05580511316657066,
        0.057104676961898804,
        0.04795940965414047,
        -0.03845714032649994,
        0.028289442881941795,
        -0.001883281278423965,
        -0.05335351824760437,
        -0.027647214010357857,
        -0.06319395452737808,
        -0.04426944628357887,
        0.02372645027935505,
        -0.022952845320105553,
        -0.05313919857144356,
        -0.05600174888968468,
        -0.03999558091163635,
        0.06287413090467453,
        0.030131813138723373,
        0.04239639267325401,
        0.02307659201323986,
        0.05364570766687393,
        0.05327865853905678,
        0.024906188249588013,
        0.05892350524663925,
        -0.023982426151633263,
        -0.014481666497886181,
        0.052693579345941544,
        -0.053228702396154404,
        -0.03625708818435669,
        0.04837409779429436,
        -0.049611736088991165,
        -0.0122071523219347,
        0.06108435243368149,
        -0.016658125445246696,
        -0.02665630355477333,
        0.057953130453825,
        -0.02962091751396656,
        -0.026995116844773293,
        0.03183169662952423,
        -0.00608007563278079,
        0.06350598484277725,
        0.056463468819856644,
        -0.03493977338075638,
        -0.01598462462425232,
        0.06519247591495514,
        0.012505934573709965,
        0.049725133925676346,
        -0.06547868996858597,
        0.025179339572787285,
        0.013665087521076202,
        -0.018569108098745346,
        -0.06619961559772491,
        -0.05986451357603073,
        -0.01077579241245985,
        -0.051276590675115585,
        -0.05999064818024635,
        -0.05853166803717613,
        -0.01774202287197113,
        -0.06141943857073784,
        -0.04768945276737213,
        0.03727853670716286,
        -0.032969143241643906,
        -0.05903717875480652,
        0.017954008653759956,
        -0.028218455612659454,
        0.06580626219511032,
        -0.05862191319465637,
        -0.05586431175470352,
        0.0015421415446326137,
        -0.02651953138411045,
        0.037743791937828064,
        -0.06457632035017014,
        -0.053468719124794006,
        -0.06041637063026428,
        0.025814848020672798,
        0.03940366953611374,
        -0.03250814229249954,
        0.0634165108203888,
        -0.04633196070790291,
        -0.06143922358751297,
        0.040783025324344635,
        -0.04583241418004036,
        0.048829086124897,
        -0.029769055545330048,
        -0.05400247499346733,
        -0.042368337512016296,
        -0.006118455436080694,
        -0.06478310376405716,
        0.06631962954998016,
        -0.048190172761678696,
        0.019576892256736755,
        0.03593863919377327,
        0.06082049384713173,
        0.043327730149030685,
        0.03385499492287636,
        0.024089571088552475,
        0.022800283506512642,
        -0.01969173178076744,
        0.058545440435409546,
        0.041463252156972885,
        -0.06408775597810745,
        0.053416986018419266,
        0.057424161583185196,
        -0.06485611945390701,
        0.053036320954561234,
        0.060800205916166306,
        0.06020631268620491,
        -0.03338479623198509,
        0.020195763558149338,
        -0.014360286295413971,
        -0.015430126339197159,
        -0.05494837090373039,
        0.062382910400629044,
        0.0005204754415899515,
        -0.05805913358926773,
        0.06132259964942932,
        -0.010785641148686409,
        -0.014387095347046852,
        0.02652527578175068,
        0.03182070329785347,
        -0.05732490122318268,
        -0.02751833014190197,
        -0.06255476176738739,
        0.04221531003713608,
        0.005112281069159508,
        -0.0016344364266842604,
        0.0005765056703239679,
        -0.052619077265262604,
        0.036695532500743866,
        0.05442621186375618,
        -0.025129156187176704,
        -0.05019951984286308,
        -0.0190590787678957,
        0.023050127550959587,
        -0.009156459011137486,
        -0.049508824944496155,
        -0.04760309308767319,
        -0.049338508397340775,
        -0.060687772929668427,
        0.06288833916187286,
        0.018046550452709198,
        0.035508960485458374,
        0.05573038384318352,
        -0.047259170562028885,
        -0.01564522087574005,
        0.011125022545456886,
        0.06460782140493393,
        -0.06624085456132889,
        -0.06316599249839783,
        0.0019421654287725687,
        0.03713815286755562,
        0.004590875934809446,
        0.05475512892007828,
        0.009689461439847946,
        0.061478324234485626,
        0.05773196741938591,
        -0.012591258622705936,
        -0.045104995369911194,
        0.03332146257162094,
        0.05588602274656296,
        0.04225442558526993,
        -0.017986252903938293,
        -0.043683815747499466,
        0.011296067386865616,
        -0.05198993906378746,
        0.046276725828647614,
        -0.06081952154636383,
        -0.00045053678331896663,
        0.014064650982618332,
        0.06290514022111893,
        -0.04905437305569649,
        0.04797619953751564,
        -0.013669335283339024,
        -0.03461531922221184,
        -0.059112176299095154,
        -0.0262759979814291,
        -0.05532968044281006,
        0.029390063136816025,
        0.037118732929229736,
        -0.04034646973013878,
        0.046350590884685516,
        -0.04541122913360596,
        -0.032350558787584305,
        0.04782808944582939,
        0.050915952771902084,
        -0.02840850129723549,
        -0.04264708235859871,
        -0.06507866084575653,
        0.05354186147451401,
        0.05596468225121498,
        -0.009075380861759186,
        -0.05260707810521126,
        -0.062249086797237396,
        -0.05258100852370262,
        -0.05288294702768326,
        -0.06331363320350647,
        -0.03794983774423599,
        -0.009021632373332977,
        -0.016172459349036217,
        -0.05054732412099838,
        0.061636559665203094,
        0.021869421005249023,
        0.05945451930165291,
        0.051490142941474915,
        -0.051592689007520676,
        -0.022239074110984802,
        0.0599069781601429,
        0.04403107613325119,
        -0.04849311336874962,
        -0.009019054472446442,
        -0.00038774896529503167,
        0.026772281154990196,
        -0.053911563009023666,
        0.05560655519366264,
        0.010538031347095966,
        -0.060938239097595215,
        -0.06474299728870392,
        0.049606114625930786,
        0.02090492472052574,
        0.055151429027318954,
        -0.0456325002014637,
        0.005675445776432753,
        0.0507873110473156,
        0.05048222839832306,
        0.030928954482078552,
        0.04877842590212822,
        -0.00959665235131979,
        -0.022560155019164085,
        -0.03594409301877022,
        -0.002666244748979807,
        0.043710775673389435,
        -0.014777258038520813,
        -0.0435878150165081,
        -0.022360997274518013,
        -0.051411911845207214,
        0.03728185594081879,
        0.030139006674289703,
        -0.06311871111392975,
        -0.060943007469177246,
        0.04278117045760155,
        -0.05536237359046936,
        -0.04434175789356232,
        0.05936543270945549,
        -0.038996804505586624,
        -0.018191661685705185,
        0.015053464099764824,
        0.04317716881632805,
        0.024916615337133408,
        0.041560836136341095,
        0.02936914749443531,
        -0.05398203432559967,
        0.055532827973365784,
        0.03819391876459122,
        0.0532081164419651,
        0.06055380031466484,
        -0.06148642301559448,
        -0.030848508700728416,
        0.02073574624955654,
        0.02199782058596611,
        -0.060243673622608185,
        -0.06139150261878967,
        0.03516127169132233,
        -0.02223029173910618,
        -0.04932157322764397,
        0.04417188838124275,
        -0.0506180115044117,
        -0.014565855264663696,
        -0.045490775257349014,
        0.025736916810274124,
        -0.01004993636161089,
        0.06114126741886139,
        -0.02635616436600685,
        0.06207682192325592,
        -0.06242121011018753,
        -0.04701701179146767,
        0.030877364799380302,
        -0.04505481943488121,
        0.0018550645327195525,
        0.039629798382520676,
        -0.06482461094856262,
        -0.030827639624476433,
        0.04556352645158768,
        -0.06468397378921509,
        -0.022827552631497383,
        -0.06444017589092255,
        0.06144150346517563,
        -0.043721891939640045,
        0.05078544467687607,
        0.019638318568468094,
        0.03193063661456108,
        0.04428736865520477,
        -0.004056102130562067,
        0.04890022799372673,
        -0.05311940237879753,
        -0.03106427565217018,
        -0.06550141423940659,
        0.003275472205132246,
        -0.008803033269941807,
        0.037955138832330704,
        0.0006479569710791111,
        0.056834641844034195,
        0.02038755640387535,
        0.043417833745479584,
        0.05280984938144684,
        0.044846560806035995,
        -0.04306333139538765,
        0.012969761155545712,
        -0.04652934521436691,
        0.029232028871774673,
        -0.0211234949529171,
        -0.0470958910882473,
        0.055895835161209106,
        0.019259342923760414,
        -0.05195421725511551,
        -0.06300938874483109,
        0.0553690642118454,
        0.06167234107851982
    ],
    [
        0.04048823192715645,
        0.0412895530462265,
        0.05246651917695999,
        0.033032678067684174,
        -0.04597514867782593,
        0.03969697281718254,
        -0.03284483030438423,
        -0.06416106969118118,
        0.058973778039216995,
        0.02722197212278843,
        0.03706107288599014,
        0.03752374276518822,
        -0.03147575631737709,
        -0.05725671350955963,
        -0.0579061433672905,
        0.06321081519126892,
        -0.006312093697488308,
        0.062404897063970566,
        -0.060138218104839325,
        0.021679172292351723,
        -0.02768787182867527,
        0.029110636562108994,
        0.015729140490293503,
        -0.039462506771087646,
        0.049853283911943436,
        0.047841381281614304,
        0.05215079337358475,
        -0.025175131857395172,
        -0.022131405770778656,
        0.006824007723480463,
        0.0030992028769105673,
        -0.009863722138106823,
        -0.06288363039493561,
        -0.06014581769704819,
        0.05181729793548584,
        0.051979660987854004,
        -0.06042568385601044,
        0.04740375652909279,
        -0.02772519364953041,
        0.006606189999729395,
        -0.026088209822773933,
        0.026117555797100067,
        0.021512843668460846,
        -0.04003612697124481,
        0.041451405733823776,
        0.06084262952208519,
        0.05263741686940193,
        0.048698730766773224,
        0.04385630786418915,
        0.047967731952667236,
        0.043809741735458374,
        0.06482396274805069,
        -0.05431051924824715,
        0.05712379142642021,
        0.011082095094025135,
        -0.06594865024089813,
        -0.04771237075328827,
        -0.018353881314396858,
        -0.01683119125664234,
        0.06070328876376152,
        -0.05067703127861023,
        0.01778010092675686,
        -0.05442555621266365,
        0.05693013221025467,
        -0.04733585938811302,
        -0.053198885172605515,
        -0.0018301071831956506,
        0.06059937924146652,
        -0.06619830429553986,
        0.03553778678178787,
        -0.057594697922468185,
        -0.05042804405093193,
        0.06551747769117355,
        0.050321437418460846,
        -0.04402599856257439,
        -0.033950239419937134,
        0.058337271213531494,
        0.06597431749105453,
        0.008287802338600159,
        -0.038363292813301086,
        -0.01119418628513813,
        0.05718783289194107,
        -0.050514284521341324,
        0.045007508248090744,
        0.05797293037176132,
        0.05137361213564873,
        0.06186617538332939,
        -0.06042121350765228,
        0.0574503093957901,
        0.05413719639182091,
        0.05438529700040817,
        0.0010642335982993245,
        -0.050720009952783585,
        0.030445104464888573,
        -0.05375633388757706,
        0.04823535308241844,
        -0.04200172796845436,
        -0.061947330832481384,
        0.02342759259045124,
        -0.05216875299811363,
        -0.058849625289440155,
        0.005517337471246719,
        0.05727390572428703,
        0.02925538830459118,
        0.06163472309708595,
        -0.046033840626478195,
        -0.042590342462062836,
        0.04259968549013138,
        -0.010965360328555107,
        0.04039290174841881,
        -0.06537573039531708,
        0.06614062935113907,
        -0.06252583116292953,
        -0.029961764812469482,
        0.002718675183132291,
        -0.0022535226307809353,
        0.038614917546510696,
        0.0025937308091670275,
        0.06007765606045723,
        0.017688840627670288,
        0.006972571834921837,
        0.06087452173233032,
        -0.009293058887124062,
        -0.03795268386602402,
        -0.04971398785710335,
        -0.06259004026651382,
        -0.034890566021203995,
        0.007467607967555523,
        0.06410574167966843,
        -0.035812318325042725,
        0.03295907750725746,
        -0.05428871884942055,
        -0.061787400394678116,
        -0.01684737391769886,
        -0.04603305831551552,
        -0.06022436544299126,
        0.024435745552182198,
        0.051060449331998825,
        -0.06538616865873337,
        0.005597209557890892,
        0.06574471294879913,
        0.05523180961608887,
        0.06233178824186325,
        0.025245608761906624,
        0.05954527109861374,
        0.02449188567698002,
        0.0660666972398758,
        -0.053217221051454544,
        -0.04935922101140022,
        0.039911843836307526,
        0.05091940611600876,
        -0.06514602899551392,
        0.05972788855433464,
        -0.06153455749154091,
        0.04289497807621956,
        -0.053145572543144226,
        -0.061314232647418976,
        0.005199508275836706,
        0.010820365510880947,
        0.05769960582256317,
        -0.039517369121313095,
        0.01762642152607441,
        0.0531628392636776,
        0.016733460128307343,
        0.04880046099424362,
        -0.004120344761759043,
        -0.049889810383319855,
        -0.009160276502370834,
        0.01551938708871603,
        0.0037335362285375595,
        0.0009170141420327127,
        0.052064232528209686,
        0.03539951145648956,
        -0.03355855122208595,
        0.011580124497413635,
        0.06107446551322937,
        -0.058306049555540085,
        0.030425401404500008,
        0.05662710592150688,
        -0.03141721710562706,
        0.001850330037996173,
        0.010338637046515942,
        -0.020971577614545822,
        0.017001839354634285,
        0.005646761041134596,
        -0.013395699672400951,
        -0.04061185196042061,
        0.05648258328437805,
        -0.0462489053606987,
        -0.0329948365688324,
        -0.02778770960867405,
        0.019714294001460075,
        0.049472544342279434,
        -0.050984326750040054,
        -0.02708577737212181,
        -0.00991330947726965,
        0.05580511316657066,
        0.057104676961898804,
        0.04795940965414047,
        -0.03845714032649994,
        0.028289442881941795,
        -0.001883281278423965,
        -0.05335351824760437,
        -0.027647214010357857,
        -0.06319395452737808,
        -0.04426944628357887,
        0.02372645027935505,
        -0.022952845320105553,
        -0.05313919857144356,
        -0.05600174888968468,
        -0.03999558091163635,
        0.06287413090467453,
        0.030131813138723373,
        0.04239639267325401,
        0.02307659201323986,
        0.05364570766687393,
        0.05327865853905678,
        0.024906188249588013,
        0.05892350524663925,
        -0.023982426151633263,
        -0.014481666497886181,
        0.052693579345941544,
        -0.053228702396154404,
        -0.03625708818435669,
        0.04837409779429436,
        -0.049611736088991165,
        -0.0122071523219347,
        0.06108435243368149,
        -0.016658125445246696,
        -0.02665630355477333,
        0.057953130453825,
        -0.02962091751396656,
        -0.026995116844773293,
        0.03183169662952423,
        -0.00608007563278079,
        0.06350598484277725,
        0.056463468819856644,
        -0.03493977338075638,
        -0.01598462462425232,
        0.06519247591495514,
        0.012505934573709965,
        0.049725133925676346,
        -0.06547868996858597,
        0.025179339572787285,
        0.013665087521076202,
        -0.018569108098745346,
        -0.06619961559772491,
        -0.05986451357603073,
        -0.01077579241245985,
        -0.051276590675115585,
        -0.05999064818024635,
        -0.05853166803717613,
        -0.01774202287197113,
        -0.06141943857073784,
        -0.04768945276737213,
        0.03727853670716286,
        -0.032969143241643906,
        -0.05903717875480652,
        0.017954008653759956,
        -0.028218455612659454,
        0.06580626219511032,
        -0.05862191319465637,
        -0.05586431175470352,
        0.0015421415446326137,
        -0.02651953138411045,
        0.037743791937828064,
        -0.06457632035017014,
        -0.053468719124794006,
        -0.06041637063026428,
        0.025814848020672798,
        0.03940366953611374,
        -0.03250814229249954,
        0.0634165108203888,
        -0.04633196070790291,
        -0.06143922358751297,
        0.040783025324344635,
        -0.04583241418004036,
        0.048829086124897,
        -0.029769055545330048,
        -0.05400247499346733,
        -0.042368337512016296,
        -0.006118455436080694,
        -0.06478310376405716,
        0.06631962954998016,
        -0.048190172761678696,
        0.019576892256736755,
        0.03593863919377327,
        0.06082049384713173,
        0.043327730149030685,
        0.03385499492287636,
        0.024089571088552475,
        0.022800283506512642,
        -0.01969173178076744,
        0.058545440435409546,
        0.041463252156972885,
        -0.06408775597810745,
        0.053416986018419266,
        0.057424161583185196,
        -0.06485611945390701,
        0.053036320954561234,
        0.060800205916166306,
        0.06020631268620491,
        -0.03338479623198509,
        0.020195763558149338,
        -0.014360286295413971,
        -0.015430126339197159,
        -0.05494837090373039,
        0.062382910400629044,
        0.0005204754415899515,
        -0.05805913358926773,
        0.06132259964942932,
        -0.010785641148686409,
        -0.014387095347046852,
        0.02652527578175068,
        0.03182070329785347,
        -0.05732490122318268,
        -0.02751833014190197,
        -0.06255476176738739,
        0.04221531003713608,
        0.005112281069159508,
        -0.0016344364266842604,
        0.0005765056703239679,
        -0.052619077265262604,
        0.036695532500743866,
        0.05442621186375618,
        -0.025129156187176704,
        -0.05019951984286308,
        -0.0190590787678957,
        0.023050127550959587,
        -0.009156459011137486,
        -0.049508824944496155,
        -0.04760309308767319,
        -0.049338508397340775,
        -0.060687772929668427,
        0.06288833916187286,
        0.018046550452709198,
        0.035508960485458374,
        0.05573038384318352,
        -0.047259170562028885,
        -0.01564522087574005,
        0.011125022545456886,
        0.06460782140493393,
        -0.06624085456132889,
        -0.06316599249839783,
        0.0019421654287725687,
        0.03713815286755562,
        0.004590875934809446,
        0.05475512892007828,
        0.009689461439847946,
        0.061478324234485626,
        0.05773196741938591,
        -0.012591258622705936,
        -0.045104995369911194,
        0.03332146257162094,
        0.05588602274656296,
        0.04225442558526993,
        -0.017986252903938293,
        -0.043683815747499466,
        0.011296067386865616,
        -0.05198993906378746,
        0.046276725828647614,
        -0.06081952154636383,
        -0.00045053678331896663,
        0.014064650982618332,
        0.06290514022111893,
        -0.04905437305569649,
        0.04797619953751564,
        -0.013669335283339024,
        -0.03461531922221184,
        -0.059112176299095154,
        -0.0262759979814291,
        -0.05532968044281006,
        0.029390063136816025,
        0.037118732929229736,
        -0.04034646973013878,
        0.046350590884685516,
        -0.04541122913360596,
        -0.032350558787584305,
        0.04782808944582939,
        0.050915952771902084,
        -0.02840850129723549,
        -0.04264708235859871,
        -0.06507866084575653,
        0.05354186147451401,
        0.05596468225121498,
        -0.009075380861759186,
        -0.05260707810521126,
        -0.062249086797237396,
        -0.05258100852370262,
        -0.05288294702768326,
        -0.06331363320350647,
        -0.03794983774423599,
        -0.009021632373332977,
        -0.016172459349036217,
        -0.05054732412099838,
        0.061636559665203094,
        0.021869421005249023,
        0.05945451930165291,
        0.051490142941474915,
        -0.051592689007520676,
        -0.022239074110984802,
        0.0599069781601429,
        0.04403107613325119,
        -0.04849311336874962,
        -0.009019054472446442,
        -0.00038774896529503167,
        0.026772281154990196,
        -0.053911563009023666,
        0.05560655519366264,
        0.010538031347095966,
        -0.060938239097595215,
        -0.06474299728870392,
        0.049606114625930786,
        0.02090492472052574,
        0.055151429027318954,
        -0.0456325002014637,
        0.005675445776432753,
        0.0507873110473156,
        0.05048222839832306,
        0.030928954482078552,
        0.04877842590212822,
        -0.00959665235131979,
        -0.022560155019164085,
        -0.03594409301877022,
        -0.002666244748979807,
        0.043710775673389435,
        -0.014777258038520813,
        -0.0435878150165081,
        -0.022360997274518013,
        -0.051411911845207214,
        0.03728185594081879,
        0.030139006674289703,
        -0.06311871111392975,
        -0.060943007469177246,
        0.04278117045760155,
        -0.05536237359046936,
        -0.04434175789356232,
        0.05936543270945549,
        -0.038996804505586624,
        -0.018191661685705185,
        0.015053464099764824,
        0.04317716881632805,
        0.024916615337133408,
        0.041560836136341095,
        0.02936914749443531,
        -0.05398203432559967,
        0.055532827973365784,
        0.03819391876459122,
        0.0532081164419651,
        0.06055380031466484,
        -0.06148642301559448,
        -0.030848508700728416,
        0.02073574624955654,
        0.02199782058596611,
        -0.060243673622608185,
        -0.06139150261878967,
        0.03516127169132233,
        -0.02223029173910618,
        -0.04932157322764397,
        0.04417188838124275,
        -0.0506180115044117,
        -0.014565855264663696,
        -0.045490775257349014,
        0.025736916810274124,
        -0.01004993636161089,
        0.06114126741886139,
        -0.02635616436600685,
        0.06207682192325592,
        -0.06242121011018753,
        -0.04701701179146767,
        0.030877364799380302,
        -0.04505481943488121,
        0.0018550645327195525,
        0.039629798382520676,
        -0.06482461094856262,
        -0.030827639624476433,
        0.04556352645158768,
        -0.06468397378921509,
        -0.022827552631497383,
        -0.06444017589092255,
        0.06144150346517563,
        -0.043721891939640045,
        0.05078544467687607,
        0.019638318568468094,
        0.03193063661456108,
        0.04428736865520477,
        -0.004056102130562067,
        0.04890022799372673,
        -0.05311940237879753,
        -0.03106427565217018,
        -0.06550141423940659,
        0.003275472205132246,
        -0.008803033269941807,
        0.037955138832330704,
        0.0006479569710791111,
        0.056834641844034195,
        0.02038755640387535,
        0.043417833745479584,
        0.05280984938144684,
        0.044846560806035995,
        -0.04306333139538765,
        0.012969761155545712,
        -0.04652934521436691,
        0.029232028871774673,
        -0.0211234949529171,
        -0.0470958910882473,
        0.055895835161209106,
        0.019259342923760414,
        -0.05195421725511551,
        -0.06300938874483109,
        0.0553690642118454,
        0.06167234107851982
    ],
    [
        0.031103864312171936,
        -0.00018534772971179336,
        0.047165993601083755,
        0.039048124104738235,
        -0.04107954725623131,
        0.009714744053781033,
        -0.03612642362713814,
        -0.06182408705353737,
        0.059154655784368515,
        0.05272139236330986,
        0.03826886788010597,
        0.05107869952917099,
        -0.03774065896868706,
        -0.05550434812903404,
        -0.057596947997808456,
        0.060784440487623215,
        -0.005350354593247175,
        0.0613505057990551,
        -0.05914675071835518,
        -0.01571846194565296,
        0.0031383212190121412,
        0.04433837905526161,
        0.03339480236172676,
        -0.05048638582229614,
        0.05435273051261902,
        0.05274197831749916,
        0.05819433927536011,
        -0.040066808462142944,
        -0.01797022856771946,
        0.03064439631998539,
        -0.012599380686879158,
        0.0030014982912689447,
        -0.06071040779352188,
        -0.062057528644800186,
        0.05171709880232811,
        0.05093703046441078,
        -0.059222474694252014,
        0.04327794536948204,
        -0.020385274663567543,
        -0.0031885874923318624,
        0.005445660557597876,
        0.02831283025443554,
        -0.00043433159589767456,
        -0.01722361147403717,
        0.04124694690108299,
        0.061118561774492264,
        0.05571078136563301,
        0.04820779338479042,
        0.06086930260062218,
        0.04808363690972328,
        0.056693319231271744,
        0.06198588386178017,
        -0.054063450545072556,
        0.05667548626661301,
        0.011762505397200584,
        -0.06299017369747162,
        -0.05761270597577095,
        -0.024403223767876625,
        -0.002404026919975877,
        0.05825769901275635,
        -0.05076930671930313,
        0.021770259365439415,
        -0.05411716178059578,
        0.05988570675253868,
        -0.03214360773563385,
        -0.05022180825471878,
        -0.01872982271015644,
        0.05746576935052872,
        -0.0630466565489769,
        0.030850574374198914,
        -0.05985335260629654,
        -0.05570955574512482,
        0.06179066002368927,
        0.036936432123184204,
        -0.023435525596141815,
        -0.016883309930562973,
        0.060450971126556396,
        0.06281013786792755,
        0.025015192106366158,
        -0.05001501739025116,
        -0.004032924771308899,
        0.057006124407052994,
        -0.053347572684288025,
        0.057637374848127365,
        0.06067876145243645,
        0.05581933259963989,
        0.06097592040896416,
        -0.052835553884506226,
        0.05665086209774017,
        0.05316374823451042,
        0.00807139277458191,
        -0.011699289083480835,
        -0.06037822365760803,
        0.021915020421147346,
        -0.04453810304403305,
        0.05325208231806755,
        -0.03609815984964371,
        -0.06056668609380722,
        0.013988052494823933,
        -0.05386214330792427,
        -0.055152297019958496,
        0.04446811228990555,
        0.053614042699337006,
        0.04138848930597305,
        0.06018303334712982,
        -0.05727965757250786,
        -0.0329120047390461,
        0.05704975873231888,
        -0.012451647780835629,
        0.05467616394162178,
        -0.06235433369874954,
        0.06300994008779526,
        -0.059576451778411865,
        -0.013510847464203835,
        -0.03366193547844887,
        0.0012590938713401556,
        0.053829558193683624,
        -0.0316239632666111,
        0.057071708142757416,
        0.03677451238036156,
        0.022828951478004456,
        0.06137872114777565,
        -0.0010918714106082916,
        -0.03611478582024574,
        -0.026753591373562813,
        -0.05905938893556595,
        -0.04199026897549629,
        -0.02643519453704357,
        0.05929555371403694,
        -0.010471349582076073,
        0.03765566647052765,
        -0.03806053102016449,
        -0.056585464626550674,
        -0.01745721325278282,
        -0.04740577191114426,
        -0.05652898922562599,
        0.03730683773756027,
        0.053794074803590775,
        -0.06250850111246109,
        0.01744212955236435,
        0.062471307814121246,
        0.05437098443508148,
        0.0573689304292202,
        -0.009554113261401653,
        0.05610133707523346,
        0.014548070728778839,
        0.06302344799041748,
        -0.05183909833431244,
        -0.046073801815509796,
        0.03756341338157654,
        0.05764474719762802,
        -0.0625758171081543,
        0.06087040156126022,
        -0.05596330761909485,
        0.04240390658378601,
        -0.05552612245082855,
        -0.06054319068789482,
        0.016268284991383553,
        0.0009343454730696976,
        0.06074560061097145,
        -0.01324988342821598,
        -0.026158930733799934,
        0.04508434981107712,
        0.010763168334960938,
        0.04184786230325699,
        0.008091873489320278,
        -0.03485448285937309,
        0.02215159870684147,
        -0.00019101683574263006,
        0.0019135101465508342,
        -0.020635545253753662,
        0.05234750732779503,
        0.028017735108733177,
        -0.04220971092581749,
        0.019909219816327095,
        0.06204536557197571,
        -0.058825571089982986,
        0.034485820680856705,
        0.043296825140714645,
        -0.049103032797575,
        -0.006423356477171183,
        0.04733823612332344,
        -0.024016309529542923,
        0.031392090022563934,
        0.005845516920089722,
        -0.011353655718266964,
        -0.05158758908510208,
        0.052939727902412415,
        -0.035735372453927994,
        -0.05088486149907112,
        -0.03262912109494209,
        0.042119499295949936,
        0.04470906779170036,
        -0.04494485631585121,
        -0.0398489274084568,
        -0.034081216901540756,
        0.04946842044591904,
        0.053980812430381775,
        0.037492670118808746,
        -0.034607239067554474,
        0.0440039224922657,
        -0.020596399903297424,
        -0.05636667460203171,
        -0.01811845973134041,
        -0.057217881083488464,
        -0.025327522307634354,
        0.01987927220761776,
        -0.021955905482172966,
        -0.056765757501125336,
        -0.04531865194439888,
        -0.04152943193912506,
        0.05903167650103569,
        0.03478267788887024,
        0.03941721469163895,
        0.014668314717710018,
        0.051797397434711456,
        0.056083906441926956,
        0.004049679730087519,
        0.06077563390135765,
        -0.04589551314711571,
        -0.037031982094049454,
        0.045489151030778885,
        -0.05513139069080353,
        -0.022559083998203278,
        0.056975141167640686,
        -0.05214840546250343,
        -0.005130508914589882,
        0.050306811928749084,
        -0.002369742142036557,
        -0.04077267646789551,
        0.061522047966718674,
        -0.05059020221233368,
        -0.01847517304122448,
        0.0380265936255455,
        -0.03263206034898758,
        0.06150193512439728,
        0.057690322399139404,
        -0.028780622407794,
        -0.01606585644185543,
        0.06204720214009285,
        0.0016568793216720223,
        0.05410256236791611,
        -0.062911257147789,
        0.04034426063299179,
        0.03785737231373787,
        -0.012752776965498924,
        -0.06282848864793777,
        -0.036204561591148376,
        -0.022710934281349182,
        -0.05157829821109772,
        -0.061569083482027054,
        -0.056138839572668076,
        -0.028433600440621376,
        -0.06028124317526817,
        -0.05245432257652283,
        0.012288163416087627,
        -0.03301796317100525,
        -0.059614550322294235,
        0.020960278809070587,
        -0.023020483553409576,
        0.06292427331209183,
        -0.05466192960739136,
        -0.049333591014146805,
        0.00870549213141203,
        -0.034367743879556656,
        0.03511681407690048,
        -0.05926748365163803,
        -0.04622569680213928,
        -0.058226846158504486,
        0.025131715461611748,
        0.018411414697766304,
        -0.05634896829724312,
        0.06242641434073448,
        -0.044634174555540085,
        -0.05834377184510231,
        0.007209294009953737,
        -0.04777226224541664,
        0.043821439146995544,
        -0.035502348095178604,
        -0.053028881549835205,
        -0.057950329035520554,
        0.023600010201334953,
        -0.0626542866230011,
        0.0631532296538353,
        -0.024641843512654305,
        0.0119541697204113,
        0.03360188007354736,
        0.05772846192121506,
        0.05650144815444946,
        0.021260803565382957,
        0.0010778489522635937,
        0.005365184973925352,
        -0.025272980332374573,
        0.05042324587702751,
        0.043694302439689636,
        -0.06138318032026291,
        0.0443420335650444,
        0.06007518619298935,
        -0.060697656124830246,
        0.050315529108047485,
        0.060336917638778687,
        0.05768251046538353,
        -0.017284993082284927,
        0.02532750926911831,
        -0.0024783832486718893,
        -0.009630884975194931,
        -0.04986891523003578,
        0.06114603206515312,
        -0.007376638241112232,
        -0.05374685674905777,
        0.06075158342719078,
        -0.03490862995386124,
        -0.003625385696068406,
        0.03863416239619255,
        0.04488203302025795,
        -0.0568779893219471,
        -0.025710158050060272,
        -0.06121957302093506,
        0.04270130768418312,
        -0.015895718708634377,
        0.024004101753234863,
        -0.019746417179703712,
        -0.051670584827661514,
        0.04518437385559082,
        0.054029639810323715,
        -0.03349251672625542,
        -0.04934535548090935,
        0.007003286387771368,
        0.031073791906237602,
        -0.009255392476916313,
        -0.04566415399312973,
        -0.03872331604361534,
        -0.04634608328342438,
        -0.057107485830783844,
        0.05895231291651726,
        0.048243142664432526,
        0.0009989733807742596,
        0.04874954745173454,
        -0.04466107860207558,
        -0.02167796716094017,
        0.012391588650643826,
        0.060868728905916214,
        -0.06301619112491608,
        -0.0623568519949913,
        0.016648130491375923,
        0.030473114922642708,
        0.007689591497182846,
        0.05374126881361008,
        0.020124491304159164,
        0.05328400433063507,
        0.05886383727192879,
        0.0028573181480169296,
        -0.05115547403693199,
        -0.02372746914625168,
        0.05600912123918533,
        0.043692804872989655,
        -0.02144521474838257,
        -0.051875583827495575,
        0.030812328681349754,
        -0.051499783992767334,
        0.0383974090218544,
        -0.057850081473588943,
        0.038099247962236404,
        0.02713053673505783,
        0.05790454149246216,
        -0.05066780745983124,
        0.06102903559803963,
        -0.010311040095984936,
        0.00677763344720006,
        -0.060622140765190125,
        -0.046731021255254745,
        -0.0569210983812809,
        0.052511706948280334,
        0.02346830815076828,
        -0.024620965123176575,
        0.03335057944059372,
        -0.051577333360910416,
        -0.02991318330168724,
        0.05662107840180397,
        0.055099815130233765,
        -0.04576888680458069,
        -0.04202786833047867,
        -0.061963532119989395,
        0.045465823262929916,
        0.05053802579641342,
        -0.017957299947738647,
        -0.0385381281375885,
        -0.05606772005558014,
        -0.04599261283874512,
        -0.04413480684161186,
        -0.06280047446489334,
        -0.03229067847132683,
        -0.0010745965410023928,
        -0.020932361483573914,
        -0.02825220301747322,
        0.05947725847363472,
        0.028042824938893318,
        0.05015919357538223,
        0.05250292271375656,
        -0.057350557297468185,
        -0.03857438638806343,
        0.06088818609714508,
        0.051967818289995193,
        -0.05183364823460579,
        -0.014790904708206654,
        -0.021012287586927414,
        0.03466936573386192,
        -0.056790657341480255,
        0.0534944012761116,
        0.013505714014172554,
        -0.055033355951309204,
        -0.06064918637275696,
        0.054467424750328064,
        0.04774042218923569,
        0.04893779382109642,
        -0.05259936302900314,
        -0.002742635551840067,
        0.051271386444568634,
        0.04656406119465828,
        0.02423074096441269,
        0.05949876084923744,
        0.00759128388017416,
        -0.014812244102358818,
        -0.04779540374875069,
        0.049320198595523834,
        0.013758931308984756,
        -0.03756256029009819,
        -0.023837268352508545,
        -0.039944496005773544,
        -0.04527191072702408,
        0.0475299209356308,
        0.027040503919124603,
        -0.06243244558572769,
        -0.0618475042283535,
        0.0186893530189991,
        -0.05974164605140686,
        -0.01507828664034605,
        0.055667951703071594,
        -0.016295339912176132,
        -0.0021431571803987026,
        0.006863266695290804,
        0.05800776183605194,
        0.02593108080327511,
        0.051486484706401825,
        0.04358777031302452,
        -0.05428485572338104,
        0.055788855999708176,
        0.0037001881282776594,
        0.05367186665534973,
        0.060314442962408066,
        -0.06142484024167061,
        -0.011612591333687305,
        0.05092537775635719,
        0.03232617676258087,
        -0.05766966566443443,
        -0.059808798134326935,
        0.003599230432882905,
        -0.027399877086281776,
        -0.04507388919591904,
        0.055902231484651566,
        -0.050327565521001816,
        -0.03576691821217537,
        -0.04935382306575775,
        0.0479617677628994,
        0.022783370688557625,
        0.06086646765470505,
        -0.04009826108813286,
        0.06104457005858421,
        -0.06227714195847511,
        -0.05358239263296127,
        0.035516127943992615,
        -0.012102804146707058,
        0.009665829129517078,
        0.04671201482415199,
        -0.0629156082868576,
        -0.003227220382541418,
        0.017488282173871994,
        -0.060760095715522766,
        -0.03019675239920616,
        -0.062262073159217834,
        0.05518759414553642,
        -0.05149895325303078,
        0.048524409532547,
        0.03146681189537048,
        0.021081069484353065,
        0.048430655151605606,
        0.007826470769941807,
        0.046575985848903656,
        -0.04531745985150337,
        -0.04594966769218445,
        -0.06247532367706299,
        -0.020167460665106773,
        -0.012176970951259136,
        0.03549474850296974,
        0.02721652202308178,
        0.055203329771757126,
        0.01686272770166397,
        0.05809961259365082,
        0.049348581582307816,
        0.049152664840221405,
        -0.052463795989751816,
        0.026575351133942604,
        -0.05276423320174217,
        0.0029070256277918816,
        0.0050429729744791985,
        -0.051274966448545456,
        0.057991813868284225,
        0.00022554205497726798,
        -0.04639264568686485,
        -0.061301734298467636,
        0.053472280502319336,
        0.055249541997909546
    ],
    [
        0.04140099883079529,
        -0.02825106494128704,
        0.042525116354227066,
        0.0177681352943182,
        -0.05227236449718475,
        0.029050013050436974,
        0.049338363111019135,
        -0.05704519897699356,
        0.038013674318790436,
        0.000918943842407316,
        0.05374480411410332,
        0.05430055782198906,
        -0.04976354539394379,
        -0.057905733585357666,
        -0.05769916623830795,
        0.05446099489927292,
        0.021595682948827744,
        0.058198872953653336,
        -0.051485467702150345,
        0.057235538959503174,
        -0.026186581701040268,
        -0.034291088581085205,
        0.04716704785823822,
        -0.008832509629428387,
        0.021857649087905884,
        0.05200367793440819,
        0.024718286469578743,
        0.055547721683979034,
        -0.05892077088356018,
        -0.004285442177206278,
        -0.054482366889715195,
        0.035905756056308746,
        -0.05337966978549957,
        -0.05749572813510895,
        0.056332025676965714,
        -0.002442594850435853,
        -0.056114014238119125,
        0.05817781388759613,
        0.04785802587866783,
        -0.019156023859977722,
        -0.051244016736745834,
        0.023649156093597412,
        0.03394149988889694,
        0.04652342572808266,
        0.05586417391896248,
        0.027487484738230705,
        0.05506237596273422,
        0.058472152799367905,
        0.054809778928756714,
        0.025098303332924843,
        0.05239521712064743,
        0.04947247728705406,
        -0.0019381149904802442,
        0.023323066532611847,
        0.02329842932522297,
        -0.05907473713159561,
        -0.058660928159952164,
        -0.049202218651771545,
        -0.05714384838938713,
        0.05897527560591698,
        -0.03292182832956314,
        -0.008206162601709366,
        -0.05755552649497986,
        0.014511843211948872,
        0.03105318732559681,
        -0.027220316231250763,
        -0.05223637446761131,
        0.05455803498625755,
        -0.05584435164928436,
        0.0498175211250782,
        -0.03948327898979187,
        0.057744745165109634,
        0.058988168835639954,
        0.05719614773988724,
        -0.014976623468101025,
        -0.044559821486473083,
        0.05898376554250717,
        0.059005189687013626,
        0.056309595704078674,
        -0.0563369058072567,
        -0.03332754224538803,
        -0.05096421390771866,
        -0.05850601941347122,
        0.048765163868665695,
        0.007339254952967167,
        0.0436444953083992,
        0.04640263319015503,
        -0.05388419330120087,
        -0.01558688934892416,
        0.056576862931251526,
        -0.015206040814518929,
        -0.056058622896671295,
        0.01032221969217062,
        0.03409777209162712,
        -0.042458292096853256,
        -0.00936028640717268,
        -0.03166942670941353,
        -0.058565761893987656,
        -0.049202777445316315,
        -0.054148249328136444,
        -0.02533891424536705,
        0.0162173043936491,
        0.0588848702609539,
        0.05032544210553169,
        0.0568280853331089,
        -0.03934045508503914,
        -0.055245429277420044,
        0.008324366062879562,
        -0.03271704167127609,
        -0.007840217091143131,
        -0.05694722756743431,
        0.05904302000999451,
        -0.04050479084253311,
        -0.031564291566610336,
        0.0416400283575058,
        0.04448959231376648,
        -0.03993595391511917,
        0.025823194533586502,
        -0.04620228707790375,
        -0.05632909759879112,
        0.0505836047232151,
        -0.0384737066924572,
        -0.02027963101863861,
        -0.0495227687060833,
        -0.056208182126283646,
        -0.05526106804609299,
        0.03407753258943558,
        0.05847208574414253,
        0.05812777206301689,
        -0.022899679839611053,
        0.035691291093826294,
        -0.04371258243918419,
        -0.05074412003159523,
        0.038631729781627655,
        -0.03541809692978859,
        -0.054509758949279785,
        0.03534776344895363,
        0.04258439317345619,
        -0.04732928052544594,
        0.02776615135371685,
        0.05121896043419838,
        0.05688721314072609,
        0.05765485763549805,
        0.056533679366111755,
        -0.027927787974476814,
        0.013831275515258312,
        0.058926600962877274,
        -0.02843167819082737,
        -0.05294908210635185,
        -0.04553471878170967,
        -0.005478937644511461,
        -0.05902471765875816,
        0.058889783918857574,
        0.0018937133718281984,
        0.02792448364198208,
        -0.045374106615781784,
        -0.059082359075546265,
        -0.03983662277460098,
        0.040429189801216125,
        0.023394664749503136,
        -0.042287085205316544,
        -0.0548732690513134,
        0.0571264885365963,
        -0.0384170338511467,
        -0.028904085978865623,
        0.03411572799086571,
        -0.051327284425497055,
        0.007618992123752832,
        -0.043588798493146896,
        0.027311110869050026,
        -0.023056648671627045,
        0.04957645758986473,
        -0.0492328479886055,
        0.05419101566076279,
        -0.012394051067531109,
        0.006380480248481035,
        0.02582414261996746,
        0.04047531262040138,
        0.04954938217997551,
        -0.005791108589619398,
        -0.0076310960575938225,
        -0.04295317828655243,
        0.024762198328971863,
        -0.0463147833943367,
        0.02253427542746067,
        -0.042830273509025574,
        0.04885314404964447,
        0.053977787494659424,
        -0.037295471876859665,
        -0.048379044979810715,
        -0.055157147347927094,
        0.0349530391395092,
        0.05783582851290703,
        -0.02122586779296398,
        -0.05590078607201576,
        -0.00831220205873251,
        0.05900624394416809,
        0.05894051492214203,
        0.05869580805301666,
        -0.01951158605515957,
        0.04168122634291649,
        0.040161844342947006,
        -0.058527201414108276,
        -0.03474724665284157,
        -0.042084209620952606,
        -0.03875233232975006,
        0.038912639021873474,
        -0.0355173796415329,
        -0.05244500935077667,
        -0.04902929812669754,
        -0.051043905317783356,
        0.037355273962020874,
        0.05060570314526558,
        0.053825873881578445,
        0.03765024244785309,
        0.04849204421043396,
        0.04264592379331589,
        0.020349541679024696,
        0.0576111376285553,
        -0.038175832480192184,
        -0.009538804180920124,
        -0.011647133156657219,
        -0.015087129548192024,
        -0.04677771404385567,
        0.050218433141708374,
        -0.0557590052485466,
        0.0020834265742450953,
        -0.0023415919858962297,
        -0.009295438416302204,
        -0.056851375848054886,
        0.044520359486341476,
        0.04988710209727287,
        -0.05133035033941269,
        0.04399143159389496,
        -0.029241375625133514,
        0.01803273893892765,
        0.052995819598436356,
        -0.03315156325697899,
        0.021292181685566902,
        0.058661263436079025,
        0.05628731846809387,
        0.0017908429726958275,
        -0.05163250491023064,
        -0.04920104146003723,
        0.023857783526182175,
        -0.044312573969364166,
        -0.05541598051786423,
        -0.05810343474149704,
        -0.016123447567224503,
        -0.056309424340724945,
        -0.05720100551843643,
        -0.04662589728832245,
        -0.05450590327382088,
        -0.0554153211414814,
        0.014798933640122414,
        -0.03294720873236656,
        0.017891956493258476,
        -0.012722229585051537,
        0.056022971868515015,
        -0.02870379202067852,
        0.05768132582306862,
        -0.05545071139931679,
        -0.052382420748472214,
        0.019827941432595253,
        -0.049392521381378174,
        0.056859090924263,
        -0.04503592848777771,
        -0.04350636526942253,
        -0.0571478009223938,
        0.006853798869997263,
        -0.001490653259679675,
        -0.04703914746642113,
        0.031591299921274185,
        -0.04946788772940636,
        -0.05280337482690811,
        0.05011029168963432,
        -0.05464135482907295,
        0.02012341096997261,
        0.024848483502864838,
        -0.05529963970184326,
        -0.030895346775650978,
        0.05655023083090782,
        -0.05518681928515434,
        0.05907416343688965,
        -0.04449913650751114,
        0.05800318345427513,
        0.04644870012998581,
        0.05589517951011658,
        0.04729529842734337,
        0.0019417196745052934,
        -0.001385051291435957,
        -0.024742763489484787,
        -0.026210270822048187,
        0.03607857972383499,
        -0.053059518337249756,
        -0.05829912796616554,
        0.058523256331682205,
        0.04318608343601227,
        -0.05378802493214607,
        0.05066422373056412,
        0.039445023983716965,
        0.04949759319424629,
        -0.04107268899679184,
        -0.011575880460441113,
        0.004935829434543848,
        -0.034772153943777084,
        -0.04282999411225319,
        0.055635690689086914,
        -0.0046484037302434444,
        -0.05726142227649689,
        0.05623626708984375,
        -0.024493291974067688,
        0.05305684357881546,
        0.04370947182178497,
        -0.04551985487341881,
        -0.057503923773765564,
        -0.047214675694704056,
        -0.057749997824430466,
        0.05368969589471817,
        0.04488493874669075,
        0.05514058098196983,
        0.03629440814256668,
        -0.03781355172395706,
        -0.04919303208589554,
        0.05505392327904701,
        0.05166539549827576,
        0.0035853914450854063,
        -0.053346652537584305,
        -0.0029946218710392714,
        -0.04128660261631012,
        -0.056614797562360764,
        -0.04966573417186737,
        -0.04298088327050209,
        -0.057065244764089584,
        0.05757756158709526,
        0.05360153689980507,
        0.04795749485492706,
        0.022196225821971893,
        -0.05687427148222923,
        0.0028340949211269617,
        -0.009690240025520325,
        0.041105661541223526,
        -0.055866073817014694,
        -0.05896460637450218,
        0.02312101610004902,
        0.0399772971868515,
        -0.010447288863360882,
        0.058086566627025604,
        0.030736329033970833,
        0.0525691881775856,
        0.053695179522037506,
        -0.008720661513507366,
        -0.030612831935286522,
        -0.040727414190769196,
        0.058957792818546295,
        -0.0499693788588047,
        -0.010270152240991592,
        -0.0507768951356411,
        -0.057123761624097824,
        0.04790904372930527,
        0.04912465438246727,
        -0.0433109775185585,
        -0.00791165977716446,
        -0.03387713432312012,
        0.055101633071899414,
        -0.05607449263334274,
        0.05838711932301521,
        0.026860497891902924,
        -0.05810331553220749,
        -0.05635959655046463,
        0.03911963850259781,
        -0.05901380255818367,
        0.03035716712474823,
        -0.03754859045147896,
        0.027834570035338402,
        0.050825946033000946,
        -0.007764672394841909,
        -0.057039301842451096,
        0.05322626233100891,
        0.0009872798109427094,
        -0.05708964541554451,
        0.057072315365076065,
        -0.04637349024415016,
        0.01153575349599123,
        0.05342975631356239,
        -0.0375351719558239,
        -0.048872943967580795,
        -0.05543268844485283,
        -0.05374949052929878,
        -0.04983533173799515,
        -0.04375014826655388,
        -0.04762646183371544,
        -0.03034244291484356,
        -0.022287245839834213,
        -0.039228834211826324,
        0.05838358774781227,
        0.05200659856200218,
        0.02829648181796074,
        -0.000018714774341788143,
        -0.04245137423276901,
        -0.037164341658353806,
        0.05123886838555336,
        0.04033603146672249,
        0.057910095900297165,
        -0.03638887032866478,
        -0.017831576988101006,
        0.0326109305024147,
        -0.05780409649014473,
        0.04904795065522194,
        -0.004748204723000526,
        -0.05171903967857361,
        -0.05790163204073906,
        0.047969162464141846,
        0.012474450282752514,
        0.057349223643541336,
        -0.049440041184425354,
        -0.03577307611703873,
        -0.0077768838964402676,
        0.054962389171123505,
        -0.032204627990722656,
        0.055074166506528854,
        0.02359135076403618,
        -0.009929157793521881,
        -0.058621104806661606,
        -0.056358251720666885,
        0.051478203386068344,
        -0.00841727014631033,
        -0.0517217256128788,
        -0.05858350172638893,
        -0.046953633427619934,
        0.005792630836367607,
        0.035270806401968,
        0.026668740436434746,
        -0.05851181596517563,
        -0.03214928135275841,
        -0.05189598351716995,
        -0.043027620762586594,
        0.05854029953479767,
        -0.04419352859258652,
        -0.05502470210194588,
        -0.05179837346076965,
        0.053704407066106796,
        -0.00942646898329258,
        0.039572425186634064,
        -0.030883416533470154,
        -0.05452344939112663,
        0.05084046721458435,
        0.04492328315973282,
        0.008344345726072788,
        0.058293502777814865,
        -0.05572367459535599,
        0.022679924964904785,
        0.05768326297402382,
        0.005452288780361414,
        -0.058757174760103226,
        -0.0466243177652359,
        0.04552861303091049,
        -0.0431215763092041,
        0.04975997656583786,
        0.05459537357091904,
        0.019498657435178757,
        -0.036275751888751984,
        0.007696597371250391,
        0.029451733455061913,
        0.05175813287496567,
        0.05721229687333107,
        -0.052678536623716354,
        0.059024959802627563,
        -0.054234422743320465,
        -0.047208189964294434,
        0.0003944947966374457,
        -0.05908627808094025,
        0.05409935861825943,
        0.058500610291957855,
        -0.034729164093732834,
        0.035867124795913696,
        -0.0003741913242265582,
        -0.0590352788567543,
        -0.04412883147597313,
        -0.05801447108387947,
        0.05857298523187637,
        0.05316764488816261,
        0.05840347707271576,
        0.04117656499147415,
        0.023080836981534958,
        0.03639179840683937,
        -0.03776123374700546,
        0.049992866814136505,
        -0.05707786977291107,
        -0.0357557013630867,
        -0.05751504749059677,
        -0.05704851076006889,
        -0.001771097769960761,
        0.041988540440797806,
        0.017333054915070534,
        0.05814824253320694,
        -0.045157089829444885,
        0.015294691547751427,
        0.05096164718270302,
        -0.03460920602083206,
        -0.03984055295586586,
        0.05524724721908569,
        -0.057309340685606,
        0.05323689803481102,
        0.03926008939743042,
        -0.02912326529622078,
        0.03927147015929222,
        -0.003822354832664132,
        -0.04063794016838074,
        -0.05483829975128174,
        0.05701256915926933,
        -0.037325385957956314
    ],
    [
        -0.012575709261000156,
        0.060375895351171494,
        0.03305460885167122,
        0.036000680178403854,
        0.06707578152418137,
        -0.03428182378411293,
        0.061263326555490494,
        -0.00008586435433244333,
        0.02605314925312996,
        -0.008931335061788559,
        0.01468263566493988,
        -0.014302722178399563,
        -0.04215008392930031,
        -0.04081963747739792,
        -0.06345093995332718,
        0.06432586908340454,
        -0.06179896369576454,
        0.01051302719861269,
        -0.06620501726865768,
        -0.04268605634570122,
        -0.040584031492471695,
        0.058101050555706024,
        0.015858985483646393,
        0.006807939149439335,
        0.020492684096097946,
        0.04826134443283081,
        0.04731219634413719,
        0.004787542391568422,
        0.02664845436811447,
        0.03137615695595741,
        0.034497957676649094,
        -0.04196741804480553,
        -0.007001398131251335,
        -0.028426067903637886,
        0.004820739850401878,
        -0.003673867555335164,
        -0.057191189378499985,
        -0.06001526862382889,
        0.0012328943703323603,
        0.03407210484147072,
        0.030280640348792076,
        0.024204997345805168,
        0.04390181973576546,
        -0.07253117859363556,
        0.0185994990170002,
        0.027776317670941353,
        -0.012260951101779938,
        0.05105019733309746,
        0.03563350439071655,
        0.033687517046928406,
        0.007275644689798355,
        0.03658956289291382,
        0.015936270356178284,
        0.030150556936860085,
        -0.022921020165085793,
        -0.007871047593653202,
        -0.04271181672811508,
        -0.0035042939707636833,
        0.012483124621212482,
        0.001244601677171886,
        -0.06776048243045807,
        0.02022324688732624,
        -0.05235493555665016,
        0.04279303550720215,
        -0.06183256208896637,
        0.019704656675457954,
        0.06093786284327507,
        -0.039555467665195465,
        -0.062418509274721146,
        -0.06855439394712448,
        -0.05832614377140999,
        -0.06988360732793808,
        0.04942847788333893,
        0.06326305866241455,
        -0.05969015136361122,
        0.018665628507733345,
        -0.005941030103713274,
        0.06137413531541824,
        -0.01857728324830532,
        -0.03454006463289261,
        -0.012743416242301464,
        0.07426349073648453,
        -0.04391730949282646,
        0.015163795091211796,
        -0.0034291972406208515,
        -0.01761215552687645,
        0.0571957491338253,
        -0.02831888012588024,
        0.00034980277996510267,
        0.06610884517431259,
        0.04348544776439667,
        0.021227534860372543,
        -0.0668405294418335,
        -0.004694858565926552,
        0.021306702867150307,
        -0.045328497886657715,
        -0.03052314557135105,
        -0.05686233565211296,
        -0.03820690140128136,
        -0.044033944606781006,
        -0.07121767848730087,
        -0.04380480945110321,
        0.06333718448877335,
        0.0650298148393631,
        0.009968970902264118,
        -0.06062162667512894,
        -0.03650682792067528,
        0.028981968760490417,
        0.022504273802042007,
        -0.025734305381774902,
        -0.059382110834121704,
        -0.06256671994924545,
        -0.05906541645526886,
        -0.028113873675465584,
        0.05989755690097809,
        -0.04319288209080696,
        0.04815954342484474,
        -0.014490398578345776,
        0.03599392995238304,
        0.03257167711853981,
        -0.03586621582508087,
        0.04136155545711517,
        -0.0001553821930428967,
        0.0015705109108239412,
        -0.07169236242771149,
        -0.02302180789411068,
        -0.041313085705041885,
        0.0037125907838344574,
        0.06903054565191269,
        0.03283987194299698,
        0.004507930018007755,
        -0.06606624275445938,
        -0.061012472957372665,
        0.008415480144321918,
        -0.04913683608174324,
        -0.05312461405992508,
        -0.024141762405633926,
        -0.028140617534518242,
        -0.05912843346595764,
        0.07017040252685547,
        0.05813043564558029,
        0.013684424571692944,
        0.06178219988942146,
        0.042059075087308884,
        0.026742123067378998,
        -0.024539638310670853,
        0.05611728876829147,
        -0.06959059834480286,
        -0.014515917748212814,
        0.05742979794740677,
        -0.001331792795099318,
        -0.0625229924917221,
        -0.05839608609676361,
        -0.034853219985961914,
        -0.023209290578961372,
        -0.05827268585562706,
        0.06100693345069885,
        0.03529040887951851,
        -0.06021885946393013,
        0.02340562455356121,
        0.05456582456827164,
        0.06899753212928772,
        0.04436098411679268,
        -0.04863541200757027,
        -0.008691401220858097,
        0.008912031538784504,
        -0.0706467255949974,
        -0.0546894446015358,
        0.02690058760344982,
        0.04144540801644325,
        0.04534641280770302,
        0.053349435329437256,
        -0.0043965596705675125,
        -0.05266391485929489,
        -0.014275623485445976,
        0.06190330162644386,
        -0.06158994510769844,
        0.016981184482574463,
        0.033520687371492386,
        -0.006459936965256929,
        0.05696665123105049,
        0.0736563429236412,
        0.05413990095257759,
        0.04346197843551636,
        0.0347917415201664,
        0.023046297952532768,
        -0.06614744663238525,
        0.06143414229154587,
        -0.04916318878531456,
        -0.04207698255777359,
        -0.012211949564516544,
        -0.02625003457069397,
        -0.02572549134492874,
        0.01344181690365076,
        0.013573509640991688,
        0.009690696373581886,
        -0.02472045086324215,
        -0.04796499386429787,
        -0.038036033511161804,
        0.003477020189166069,
        -0.026811011135578156,
        0.04689624160528183,
        -0.024025024846196175,
        -0.04251200705766678,
        -0.05643274262547493,
        0.04744477942585945,
        0.04785502329468727,
        0.04306194186210632,
        -0.01948535442352295,
        0.05476672947406769,
        -0.0502268522977829,
        0.03543578088283539,
        -0.04067140072584152,
        -0.02143881656229496,
        -0.015661777928471565,
        0.01878855936229229,
        0.021842271089553833,
        0.034181270748376846,
        0.058218445628881454,
        -0.007907237857580185,
        0.02089514024555683,
        0.06228727102279663,
        -0.024942869320511818,
        -0.071523018181324,
        0.06052248552441597,
        -0.009680180810391903,
        0.01601615734398365,
        0.04107433184981346,
        -0.05225660279393196,
        -0.05707673355937004,
        -0.05450809746980667,
        -0.06317117810249329,
        -0.05183093249797821,
        0.029532890766859055,
        -0.01119210198521614,
        0.026358941569924355,
        0.06231466308236122,
        -0.040669411420822144,
        0.00857283640652895,
        0.03246144950389862,
        0.05362372472882271,
        0.07184283435344696,
        -0.06932172924280167,
        0.050837285816669464,
        0.013847612775862217,
        0.002474522450938821,
        -0.06928586214780807,
        -0.03862008824944496,
        -0.04969035089015961,
        0.012872054241597652,
        -0.021602682769298553,
        -0.07143551856279373,
        -0.004322657361626625,
        -0.06921295076608658,
        -0.043687909841537476,
        0.07065354287624359,
        -0.04685273766517639,
        -0.03714214265346527,
        0.06550758332014084,
        0.05545676872134209,
        0.07389426231384277,
        0.025391416624188423,
        -0.012455152347683907,
        0.059776272624731064,
        -0.06639967113733292,
        0.01475322712212801,
        0.02161942794919014,
        -0.021566256880760193,
        -0.0004346537170931697,
        -0.024619746953248978,
        0.03507988527417183,
        -0.06832991540431976,
        0.015577537007629871,
        -0.06724001467227936,
        -0.046640846878290176,
        0.016490725800395012,
        -0.058664027601480484,
        -0.008995703421533108,
        -0.04371609911322594,
        -0.06266995519399643,
        -0.053795162588357925,
        -0.04712137579917908,
        -0.004608388990163803,
        0.0361579991877079,
        -0.014331391081213951,
        -0.0453222282230854,
        0.0199674591422081,
        0.02593393623828888,
        -0.0493965744972229,
        0.020228594541549683,
        -0.022971084341406822,
        0.056363899260759354,
        0.023896105587482452,
        0.04166709631681442,
        0.059342507272958755,
        -0.06226632744073868,
        0.06768371164798737,
        0.054998915642499924,
        -0.0573846660554409,
        0.05329318344593048,
        0.031858496367931366,
        0.055761221796274185,
        -0.02723633497953415,
        -0.009975477121770382,
        -0.051684871315956116,
        -0.008917480707168579,
        -0.0637429729104042,
        -0.03882846608757973,
        0.05707336217164993,
        -0.06926655769348145,
        0.06260751187801361,
        -0.043498434126377106,
        -0.053734682500362396,
        0.048418667167425156,
        -0.059606943279504776,
        -0.06568998098373413,
        0.06613850593566895,
        -0.06487592309713364,
        -0.01783733442425728,
        -0.05799213424324989,
        -0.03069923259317875,
        0.01887916587293148,
        0.07019370794296265,
        -0.036782406270504,
        0.05600270628929138,
        0.018715837970376015,
        -0.031431954354047775,
        0.02776305377483368,
        -0.03872217983007431,
        -0.0463830940425396,
        -0.011802946217358112,
        -0.005934371612966061,
        -0.018719997256994247,
        -0.06287811696529388,
        0.0648023933172226,
        0.02685028687119484,
        -0.03749453276395798,
        0.06564163416624069,
        -0.031108269467949867,
        0.06222794950008392,
        0.04191621392965317,
        0.033417146652936935,
        -0.07109422236680984,
        -0.02837936393916607,
        0.06460075080394745,
        -0.005101849790662527,
        0.03354400768876076,
        0.039879366755485535,
        -0.06245899200439453,
        0.07157246023416519,
        -0.0716119110584259,
        0.031347960233688354,
        -0.05354072153568268,
        -0.03363647684454918,
        -0.058844637125730515,
        0.04862073436379433,
        0.020355476066470146,
        0.03370070457458496,
        -0.0021286983974277973,
        -0.06269767880439758,
        0.06559716165065765,
        0.0375048890709877,
        -0.06883362680673599,
        -0.01914169080555439,
        -0.011736399494111538,
        -0.06044008210301399,
        0.06565237045288086,
        0.04535720497369766,
        -0.014030985534191132,
        -0.06748587638139725,
        -0.030553091317415237,
        -0.030057918280363083,
        0.053538352251052856,
        0.05758742243051529,
        0.0078070810995996,
        0.02952680177986622,
        -0.0004459088668227196,
        0.028967808932065964,
        -0.03645534813404083,
        -0.05712100490927696,
        -0.006188990548253059,
        -0.04397229850292206,
        -0.06734377145767212,
        0.057944636791944504,
        0.0730486661195755,
        -0.03176440671086311,
        -0.013339711353182793,
        -0.07215564697980881,
        -0.02897857502102852,
        -0.06190892681479454,
        -0.043486587703228,
        0.037861697375774384,
        0.03901756554841995,
        0.03989991545677185,
        -0.06551432609558105,
        0.06285490095615387,
        0.063724584877491,
        -0.011329916305840015,
        -0.012750707566738129,
        -0.07192116230726242,
        -0.03455260396003723,
        0.03772152587771416,
        0.06416629999876022,
        -0.03843340650200844,
        -0.030077628791332245,
        0.06759541481733322,
        0.009172348305583,
        0.016505694016814232,
        0.04182949289679527,
        -0.01823068968951702,
        -0.001642637769691646,
        -0.051375385373830795,
        0.05553297698497772,
        -0.02480553649365902,
        0.05531235411763191,
        -0.03655955195426941,
        -0.0025301629211753607,
        -0.004663323983550072,
        0.02667783759534359,
        0.015011602081358433,
        -0.060355547815561295,
        -0.05281802639365196,
        -0.028794139623641968,
        -0.021240562200546265,
        -0.021455757319927216,
        0.0445358008146286,
        -0.026877667754888535,
        -0.01896602474153042,
        -0.0438893586397171,
        -0.03632296994328499,
        0.04902254045009613,
        -0.004169359803199768,
        -0.031249240040779114,
        0.042686302214860916,
        0.023198818787932396,
        -0.01838812232017517,
        -0.03765689954161644,
        -0.002177688991650939,
        0.001185059081763029,
        -0.04667484015226364,
        0.06085216999053955,
        0.0373895987868309,
        0.06355906277894974,
        0.03677799925208092,
        0.019799720495939255,
        0.03001389466226101,
        0.06594748049974442,
        -0.04315150901675224,
        0.0693463683128357,
        -0.05524767190217972,
        -0.05660328269004822,
        0.056550201028585434,
        -0.019306233152747154,
        -0.008685550652444363,
        0.014695706777274609,
        -0.031661368906497955,
        0.02610330656170845,
        0.03639562800526619,
        -0.030734363943338394,
        0.020754778757691383,
        -0.009386751800775528,
        0.02496197447180748,
        -0.07320616394281387,
        -0.008922937326133251,
        0.040587905794382095,
        0.07297500967979431,
        -0.06622543185949326,
        0.05864318460226059,
        -0.045128971338272095,
        -0.07057962566614151,
        0.039425622671842575,
        0.030535241588950157,
        0.04393647983670235,
        -0.030417531728744507,
        -0.029430178925395012,
        -0.03927735239267349,
        0.033088669180870056,
        0.06737043708562851,
        -0.028563614934682846,
        -0.06673947721719742,
        -0.060140375047922134,
        -0.020614836364984512,
        0.0020324925426393747,
        0.04163432866334915,
        0.06815904378890991,
        -0.011918958276510239,
        0.02498830109834671,
        -0.0477202832698822,
        -0.062465015798807144,
        -0.003719863947480917,
        -0.06076052784919739,
        0.0035063785035163164,
        -0.010936582460999489,
        0.05763814225792885,
        0.06274384260177612,
        -0.004864410497248173,
        -0.0327422209084034,
        0.05470441281795502,
        0.06474049389362335,
        0.07059340178966522,
        -0.06606834381818771,
        -0.05159333720803261,
        -0.023095643147826195,
        0.018382785841822624,
        0.021321145817637444,
        0.02689523622393608,
        0.05727708339691162,
        0.007735696155577898,
        -0.07334151864051819,
        -0.05006444454193115,
        0.05751825124025345,
        0.0619061142206192
    ],
    [
        0.02469027414917946,
        0.05056273192167282,
        -0.0009329066961072385,
        0.03158548101782799,
        0.06139561906456947,
        -0.0569019578397274,
        0.04998450726270676,
        -0.04482980817556381,
        0.039597298949956894,
        -0.00849429052323103,
        0.023840559646487236,
        0.05236165225505829,
        -0.05203188210725784,
        -0.0031232654582709074,
        -0.05299501493573189,
        0.05676429718732834,
        -0.04556635767221451,
        0.06237702816724777,
        -0.06750302761793137,
        0.010737254284322262,
        -0.04601527750492096,
        0.07256417721509933,
        0.026152223348617554,
        -0.03146205097436905,
        0.02858344465494156,
        0.053803108632564545,
        0.04460101202130318,
        0.03042801283299923,
        0.020928235724568367,
        -0.01792844943702221,
        0.039886943995952606,
        -0.04120148718357086,
        -0.04844524338841438,
        -0.05600178241729736,
        0.010092374868690968,
        -0.018371781334280968,
        -0.05634316802024841,
        -0.020713411271572113,
        0.01789555512368679,
        0.03161202371120453,
        0.05148882791399956,
        0.0027936664409935474,
        0.037141550332307816,
        -0.0679449811577797,
        -0.03393755108118057,
        0.03761356323957443,
        -0.02098165825009346,
        0.07028670608997345,
        0.014227960258722305,
        0.06143786013126373,
        -0.0007165176211856306,
        0.06718304008245468,
        -0.012608726508915424,
        0.027376407757401466,
        0.015480546280741692,
        -0.015479421243071556,
        0.01075980719178915,
        -0.048705942928791046,
        0.03944563865661621,
        0.0038960680831223726,
        -0.05762670189142227,
        0.029261860996484756,
        -0.03872067481279373,
        0.04035944491624832,
        -0.05701255053281784,
        -0.04048500210046768,
        0.06301423907279968,
        -0.0477478951215744,
        -0.05349122732877731,
        -0.06839333474636078,
        -0.06625194102525711,
        -0.06591160595417023,
        0.06229931116104126,
        0.007777858525514603,
        -0.05027169734239578,
        0.02416352927684784,
        0.03976747766137123,
        0.07277493923902512,
        -0.056886687874794006,
        -0.011502847075462341,
        -0.0017304476350545883,
        0.07370094209909439,
        -0.04056882858276367,
        0.01514882780611515,
        0.036802828311920166,
        0.001974396873265505,
        0.06580467522144318,
        -0.05637512728571892,
        -0.01890653744339943,
        0.07368582487106323,
        0.033931147307157516,
        -0.002654638374224305,
        -0.05585164576768875,
        -0.050540946424007416,
        -0.025488711893558502,
        0.022265760228037834,
        0.013094427064061165,
        -0.05577351152896881,
        -0.062163304537534714,
        -0.04691074788570404,
        -0.060032717883586884,
        -0.04764990508556366,
        0.0662233829498291,
        0.03826067969202995,
        0.030185285955667496,
        -0.05563335493206978,
        -0.04374965280294418,
        0.029123231768608093,
        0.0475042462348938,
        0.022732792422175407,
        -0.06825966387987137,
        -0.0525391548871994,
        -0.053930506110191345,
        -0.048515599220991135,
        0.010623125359416008,
        -0.011154955253005028,
        0.027972251176834106,
        -0.00044040687498636544,
        0.06363572180271149,
        0.049733564257621765,
        -0.037612590938806534,
        0.01737605594098568,
        -0.006726831663399935,
        -0.03472806140780449,
        -0.07104802131652832,
        -0.013339068740606308,
        -0.07255393266677856,
        -0.019421124830842018,
        0.06646012514829636,
        0.05250459164381027,
        0.01668155938386917,
        -0.04985979571938515,
        -0.0560276098549366,
        0.01808837056159973,
        -0.0442081019282341,
        -0.04916275292634964,
        -0.0025045054499059916,
        0.007045688573271036,
        -0.0697915107011795,
        0.040879469364881516,
        0.052033934742212296,
        0.028014857321977615,
        0.05938560888171196,
        0.05750514939427376,
        0.04017123952507973,
        0.004616203717887402,
        0.06689589470624924,
        -0.06908200681209564,
        0.04683513939380646,
        0.07356810569763184,
        0.01708373799920082,
        -0.07238223403692245,
        -0.045204006135463715,
        -0.005443739704787731,
        -0.0345204696059227,
        -0.042814068496227264,
        0.053141724318265915,
        0.035207599401474,
        -0.05984558165073395,
        0.009557405486702919,
        0.02788160741329193,
        0.07065726071596146,
        0.05749409645795822,
        -0.035030070692300797,
        -0.024132611230015755,
        0.020902572199702263,
        -0.06344722211360931,
        -0.06312280893325806,
        0.027142604812979698,
        0.015040677040815353,
        0.03748854622244835,
        -0.0450008399784565,
        -0.001474160817451775,
        -0.05190322920680046,
        0.027282506227493286,
        0.05884314328432083,
        -0.07286939024925232,
        0.022839047014713287,
        0.05985618755221367,
        -0.007837613113224506,
        0.03822079300880432,
        0.07318869978189468,
        0.052305903285741806,
        0.012715696357190609,
        0.00445715244859457,
        0.01927250251173973,
        -0.05993187427520752,
        0.0622243769466877,
        -0.027687976136803627,
        -0.05553162097930908,
        -0.037069786339998245,
        0.0017835346516221762,
        0.03577820211648941,
        0.05252619460225105,
        -0.022551096975803375,
        -0.029889268800616264,
        -0.025785084813833237,
        -0.0031190169975161552,
        -0.049685508012771606,
        -0.0007433051941916347,
        -0.04530362784862518,
        0.017829641699790955,
        0.013385784812271595,
        -0.041497357189655304,
        -0.0683957114815712,
        0.055047422647476196,
        -0.0033139651641249657,
        -0.024423550814390182,
        -0.05895581468939781,
        0.061331912875175476,
        -0.04561542347073555,
        0.014487734995782375,
        0.0204450860619545,
        0.02924286387860775,
        0.009372901171445847,
        0.0034140890929847956,
        -0.0011552241630852222,
        0.021479973569512367,
        0.061098258942365646,
        -0.03276083990931511,
        0.022542569786310196,
        0.06901110708713531,
        -0.06387916207313538,
        -0.06852570176124573,
        0.06848951429128647,
        -0.04878576844930649,
        -0.007890467531979084,
        0.044312674552202225,
        -0.019714584574103355,
        -0.0326572060585022,
        -0.002952882554382086,
        -0.06634148955345154,
        -0.006614048965275288,
        0.009214998222887516,
        -0.012355306185781956,
        0.06327757984399796,
        0.06308989226818085,
        -0.023246347904205322,
        -0.023357365280389786,
        0.05923091992735863,
        0.041961077600717545,
        0.07233041524887085,
        -0.06520698964595795,
        0.0680345818400383,
        0.029245490208268166,
        0.010107534006237984,
        -0.07142089307308197,
        -0.04668120667338371,
        -0.02179103158414364,
        -0.0080507667735219,
        -0.021474730223417282,
        -0.0688912644982338,
        -0.017924821004271507,
        -0.06884216517210007,
        -0.014034997671842575,
        0.06896767765283585,
        -0.05478598549962044,
        -0.045254599303007126,
        0.06792653352022171,
        0.05127423256635666,
        0.0735461413860321,
        -0.010532411746680737,
        -0.027254002168774605,
        0.05412112548947334,
        -0.06606550514698029,
        0.04250921308994293,
        0.026441756635904312,
        0.020676525309681892,
        -0.0032309959642589092,
        -0.004989437758922577,
        0.003323625074699521,
        -0.06545130908489227,
        0.043696753680706024,
        -0.06323166936635971,
        -0.016223477199673653,
        0.021883375942707062,
        -0.06306082010269165,
        0.01124956738203764,
        -0.07040907442569733,
        -0.03518999367952347,
        -0.06195550784468651,
        0.030727442353963852,
        -0.027607331052422523,
        0.0670340433716774,
        0.04984861984848976,
        -0.04943522438406944,
        0.01182466559112072,
        0.025569064542651176,
        -0.03933626413345337,
        0.021426305174827576,
        -0.060083042830228806,
        0.053948767483234406,
        0.052785471081733704,
        0.061832960695028305,
        0.06959918886423111,
        -0.06668318808078766,
        0.06665775179862976,
        0.06275367736816406,
        -0.050065044313669205,
        0.036391016095876694,
        0.06304401159286499,
        0.053587477654218674,
        -0.05869359150528908,
        0.02294297330081463,
        0.013241627253592014,
        0.012706439942121506,
        -0.0649220421910286,
        0.027877619490027428,
        0.06561478227376938,
        -0.04732740297913551,
        0.06984680145978928,
        -0.027495739981532097,
        -0.014482256956398487,
        0.02933674305677414,
        -0.0639643669128418,
        -0.05764111131429672,
        0.054779816418886185,
        -0.06142333522439003,
        0.006812619976699352,
        -0.051669295877218246,
        -0.0643189549446106,
        0.053959112614393234,
        0.042105209082365036,
        -0.04880775138735771,
        0.031073102727532387,
        -0.02784791961312294,
        -0.0043913996778428555,
        0.004666447173804045,
        0.021624106913805008,
        -0.025319712236523628,
        0.018621603026986122,
        -0.05512973293662071,
        -0.0437454916536808,
        -0.06255819648504257,
        0.0690552368760109,
        0.021556001156568527,
        -0.054458774626255035,
        0.07189691066741943,
        -0.021596578881144524,
        0.01722433790564537,
        0.07161354273557663,
        0.06351633369922638,
        -0.05985477194190025,
        -0.04886123910546303,
        0.07023801654577255,
        0.0350632444024086,
        0.023891631513834,
        0.02779613621532917,
        -0.044367946684360504,
        0.06858038902282715,
        -0.06416521221399307,
        0.012865481898188591,
        -0.03460788354277611,
        -0.05235869809985161,
        -0.020393162965774536,
        0.0644572526216507,
        -0.060989633202552795,
        0.011531579308211803,
        0.028019089251756668,
        -0.07063346356153488,
        0.058869704604148865,
        0.04151805862784386,
        -0.020321594551205635,
        -0.03133248910307884,
        0.05716506019234657,
        -0.050549667328596115,
        0.06801141798496246,
        0.016912275925278664,
        0.009571000002324581,
        -0.06854023784399033,
        -0.007251846604049206,
        0.008887101896107197,
        0.049935482442379,
        0.06516088545322418,
        0.01637408137321472,
        0.006574243772774935,
        0.02029968984425068,
        -0.029067663475871086,
        -0.0021617216989398003,
        -0.018033402040600777,
        0.042292896658182144,
        -0.03479376435279846,
        -0.07143670320510864,
        0.03929753974080086,
        0.05579230934381485,
        -0.02638654224574566,
        -0.016253657639026642,
        -0.07187315821647644,
        -0.04063547030091286,
        -0.06214844807982445,
        -0.05921449884772301,
        0.009295856580138206,
        0.06310732662677765,
        0.03957255184650421,
        -0.05623482167720795,
        0.0681099072098732,
        0.0711175724864006,
        -0.007340541109442711,
        -0.04654230922460556,
        -0.06940857321023941,
        -0.017415521666407585,
        0.008264373987913132,
        0.030368467792868614,
        -0.04471332207322121,
        -0.05800313502550125,
        0.06001777946949005,
        0.00856231153011322,
        -0.0005281012272462249,
        0.023040233179926872,
        -0.021512189880013466,
        0.03480549529194832,
        -0.053071003407239914,
        -0.01617673598229885,
        0.0013063147198408842,
        0.06822314858436584,
        -0.035499658435583115,
        0.0038968969602137804,
        0.0097449766471982,
        0.02342594973742962,
        0.014268866740167141,
        -0.0159736480563879,
        0.0393432080745697,
        0.0066012064926326275,
        -0.05056888610124588,
        0.06014931946992874,
        -0.007912908680737019,
        0.03082212433218956,
        0.027001677080988884,
        -0.03664807975292206,
        -0.0351698063313961,
        0.042338285595178604,
        -0.012338485568761826,
        -0.06249445676803589,
        -0.017053574323654175,
        0.05572101101279259,
        -0.03772556036710739,
        -0.022824948653578758,
        0.023071417585015297,
        0.031000178307294846,
        -0.012216869741678238,
        0.05344708263874054,
        0.041147083044052124,
        0.05555235221982002,
        0.027771852910518646,
        0.003974387422204018,
        0.020799539983272552,
        0.06318672001361847,
        -0.026950422674417496,
        0.06790647655725479,
        -0.04648769274353981,
        -0.04082471504807472,
        0.04273391142487526,
        0.027097303420305252,
        -0.002285526366904378,
        0.02408016473054886,
        -0.018681319430470467,
        0.040449365973472595,
        0.03075561672449112,
        0.002257207641378045,
        0.01781591959297657,
        0.006112686358392239,
        0.05069279670715332,
        -0.07196082919836044,
        0.03299828618764877,
        0.05487943813204765,
        0.0701257586479187,
        -0.07189783453941345,
        0.0659966766834259,
        -0.06800885498523712,
        -0.07005340605974197,
        0.02023492008447647,
        0.03538383170962334,
        -0.003832700429484248,
        -0.029895512387156487,
        -0.05930793657898903,
        -0.026740822941064835,
        0.009579595178365707,
        -0.008905956521630287,
        -0.052823737263679504,
        -0.048351436853408813,
        -0.022421764209866524,
        -0.04572033882141113,
        -0.01891770213842392,
        0.021843483671545982,
        0.021977294236421585,
        -0.001172391464933753,
        0.01962296850979328,
        0.01894981600344181,
        -0.03689916431903839,
        0.004208109807223082,
        -0.04952777549624443,
        0.013836135156452656,
        0.01840340346097946,
        0.023627139627933502,
        0.06184612959623337,
        0.006022768560796976,
        0.026537736877799034,
        0.029057228937745094,
        0.06682268530130386,
        0.06757257878780365,
        -0.05827583745121956,
        -0.04482359066605568,
        -0.052751898765563965,
        0.029921600595116615,
        -0.0306965671479702,
        0.0158626027405262,
        0.0630224347114563,
        0.03526388108730316,
        -0.06730355322360992,
        -0.07153788208961487,
        0.045505911111831665,
        0.035722099244594574
    ],
    [
        0.022510142996907234,
        0.05194292590022087,
        -0.006315986625850201,
        0.03412490710616112,
        0.06184505298733711,
        -0.05654507502913475,
        0.051224153488874435,
        -0.048308875411748886,
        0.040301356464624405,
        0.0009015561663545668,
        0.02576264552772045,
        0.05874061584472656,
        -0.05484301224350929,
        0.0007479594205506146,
        -0.05562148615717888,
        0.055104345083236694,
        -0.04804428294301033,
        0.06061699241399765,
        -0.06542249023914337,
        0.013574212789535522,
        -0.04352813959121704,
        0.07264073193073273,
        0.02974819391965866,
        -0.03266002982854843,
        0.03543757647275925,
        0.05316561087965965,
        0.048784639686346054,
        0.033018991351127625,
        0.018108658492565155,
        -0.02940681204199791,
        0.03696490079164505,
        -0.04397348687052727,
        -0.04560234770178795,
        -0.05062710866332054,
        0.0036813607439398766,
        -0.02736721932888031,
        -0.05632422864437103,
        -0.025660185143351555,
        0.023360546678304672,
        0.029504776000976562,
        0.044988036155700684,
        0.004354539327323437,
        0.039482731372117996,
        -0.06652449816465378,
        -0.03702254965901375,
        0.037292007356882095,
        -0.012866782955825329,
        0.06929249316453934,
        0.01411132700741291,
        0.06183936446905136,
        -0.0016281994758173823,
        0.06362973153591156,
        0.0038277723360806704,
        0.03201030194759369,
        0.027005990967154503,
        -0.023965200409293175,
        0.006314587779343128,
        -0.040407445281744,
        0.03289758041501045,
        0.008480068296194077,
        -0.0587020106613636,
        0.0355946384370327,
        -0.037905238568782806,
        0.042762454599142075,
        -0.05775359645485878,
        -0.04358261451125145,
        0.06222629174590111,
        -0.053055159747600555,
        -0.05688082426786423,
        -0.06783383339643478,
        -0.06337691098451614,
        -0.06412765383720398,
        0.06446661055088043,
        0.009302892722189426,
        -0.04695438966155052,
        0.01739126816391945,
        0.04542702063918114,
        0.07324323803186417,
        -0.053162164986133575,
        -0.011055009439587593,
        0.006078237202018499,
        0.0737333819270134,
        -0.03828832134604454,
        0.014132781885564327,
        0.029095880687236786,
        0.0069533586502075195,
        0.06644129753112793,
        -0.05672827363014221,
        -0.028342030942440033,
        0.0736144408583641,
        0.022300835698843002,
        -0.0061189234256744385,
        -0.05436117202043533,
        -0.04971528425812721,
        -0.0269586443901062,
        0.021155158057808876,
        0.004766450263559818,
        -0.05520503968000412,
        -0.05989296734333038,
        -0.04657056927680969,
        -0.06480690091848373,
        -0.044552456587553024,
        0.06778223812580109,
        0.04158758372068405,
        0.03855297341942787,
        -0.052922189235687256,
        -0.045413512736558914,
        0.02727067470550537,
        0.045254047960042953,
        0.022082151845097542,
        -0.06902692466974258,
        -0.056931283324956894,
        -0.058425016701221466,
        -0.05119972676038742,
        0.0012069469084963202,
        -0.00972750224173069,
        0.023992612957954407,
        -0.006474226247519255,
        0.06121426820755005,
        0.03825848922133446,
        -0.03281151503324509,
        0.018361466005444527,
        -0.014675655402243137,
        -0.02777528576552868,
        -0.07163448631763458,
        -0.013580410741269588,
        -0.07255924493074417,
        -0.02385895885527134,
        0.06681451946496964,
        0.046477995812892914,
        0.007428062614053488,
        -0.05084242299199104,
        -0.05488981306552887,
        0.021676180884242058,
        -0.04792888090014458,
        -0.04963420331478119,
        -0.006628456059843302,
        0.007817529141902924,
        -0.07074175029993057,
        0.04453735798597336,
        0.0498727485537529,
        0.03161592036485672,
        0.06051521748304367,
        0.051201749593019485,
        0.038608893752098083,
        0.019385356456041336,
        0.06692235916852951,
        -0.06941735744476318,
        0.04720316454768181,
        0.07318062335252762,
        0.01906716264784336,
        -0.07217179983854294,
        -0.047913551330566406,
        -0.014238930307328701,
        -0.03754444420337677,
        -0.04611191898584366,
        0.05108119919896126,
        0.0357794463634491,
        -0.05942286178469658,
        0.008011329919099808,
        0.029691491276025772,
        0.0707392692565918,
        0.05693468824028969,
        -0.02852555923163891,
        -0.019639352336525917,
        0.020224040374159813,
        -0.06440873444080353,
        -0.06476955860853195,
        0.03648039326071739,
        0.018033454194664955,
        0.042606376111507416,
        -0.03121558204293251,
        -0.005358700640499592,
        -0.05152828246355057,
        0.02406960166990757,
        0.05473848804831505,
        -0.07174672186374664,
        0.020561812445521355,
        0.05688610300421715,
        -0.017619384452700615,
        0.0371917225420475,
        0.07329526543617249,
        0.05226016044616699,
        0.01836756430566311,
        0.0026835440658032894,
        0.01722513511776924,
        -0.06065116450190544,
        0.06224966049194336,
        -0.03084138035774231,
        -0.0586022213101387,
        -0.03607264161109924,
        -0.015922008082270622,
        0.03437338396906853,
        0.051296167075634,
        -0.018870079889893532,
        -0.024601232260465622,
        -0.01710701733827591,
        -0.007324399426579475,
        -0.04547720029950142,
        -0.0006730795721523464,
        -0.04014727100729942,
        0.009147884324193,
        0.014794427901506424,
        -0.03859145566821098,
        -0.06814402341842651,
        0.046388495713472366,
        0.0031885476782917976,
        -0.01891341060400009,
        -0.05899873375892639,
        0.05824170261621475,
        -0.04996770992875099,
        0.01917254738509655,
        0.02192956954240799,
        0.03458862006664276,
        0.008494878187775612,
        0.0152721107006073,
        0.005317328963428736,
        0.026201777160167694,
        0.06012542545795441,
        -0.03797156736254692,
        0.014743856154382229,
        0.06988950073719025,
        -0.06750871986150742,
        -0.06894783675670624,
        0.06793001294136047,
        -0.0532224141061306,
        0.0023058943916112185,
        0.037073008716106415,
        -0.014486826956272125,
        -0.03491635620594025,
        0.0007472520228475332,
        -0.06712661683559418,
        0.003706693882122636,
        0.017914969474077225,
        -0.001722593093290925,
        0.06338515132665634,
        0.06447046995162964,
        -0.025572240352630615,
        -0.010115955956280231,
        0.0654306560754776,
        0.04724060371518135,
        0.07115325331687927,
        -0.06493030488491058,
        0.06830789148807526,
        0.021967733278870583,
        0.014817735180258751,
        -0.07085879892110825,
        -0.04513401910662651,
        -0.017744168639183044,
        -0.009157705120742321,
        -0.010349794290959835,
        -0.0696626603603363,
        -0.017418377101421356,
        -0.0689631924033165,
        -0.0116251977160573,
        0.06922953575849533,
        -0.05906791612505913,
        -0.049340832978487015,
        0.06961984932422638,
        0.05342315882444382,
        0.07341011613607407,
        -0.02007666788995266,
        -0.030622445046901703,
        0.052245691418647766,
        -0.06816668808460236,
        0.03683539852499962,
        0.04222854599356651,
        0.02124641090631485,
        0.006861279718577862,
        0.0019223286071792245,
        0.003475198056548834,
        -0.06550555676221848,
        0.03960447758436203,
        -0.06292259693145752,
        -0.010642356239259243,
        0.02110270783305168,
        -0.06491349637508392,
        0.0052315304055809975,
        -0.06706696003675461,
        -0.030833754688501358,
        -0.06377767026424408,
        0.02513035759329796,
        -0.027133306488394737,
        0.06724172085523605,
        0.046960487961769104,
        -0.04723724350333214,
        0.005187622737139463,
        0.01927894912660122,
        -0.0417318232357502,
        0.019627580419182777,
        -0.05569851025938988,
        0.057361166924238205,
        0.05285977944731712,
        0.061868827790021896,
        0.06958737969398499,
        -0.06592977792024612,
        0.06667205691337585,
        0.0642392560839653,
        -0.04180726781487465,
        0.04097552224993706,
        0.061361901462078094,
        0.05352777615189552,
        -0.058198846876621246,
        0.02504713088274002,
        0.011595613323152065,
        0.004965829662978649,
        -0.06692693382501602,
        0.016011804342269897,
        0.06543660908937454,
        -0.05172760784626007,
        0.0690024122595787,
        -0.03193512186408043,
        -0.021570630371570587,
        0.02643846906721592,
        -0.06383045762777328,
        -0.05884483456611633,
        0.056261591613292694,
        -0.05586228519678116,
        0.009527617134153843,
        -0.05195163935422897,
        -0.0642721951007843,
        0.050221625715494156,
        0.03478148207068443,
        -0.05104762315750122,
        0.03987295180559158,
        -0.021504975855350494,
        -0.012319979257881641,
        0.013477674685418606,
        0.019670777022838593,
        -0.03054836019873619,
        0.020476946607232094,
        -0.05619864538311958,
        -0.03974153473973274,
        -0.06393005698919296,
        0.06853699684143066,
        0.020389718934893608,
        -0.05499512702226639,
        0.07202602922916412,
        -0.012713680975139141,
        0.01673751138150692,
        0.07159159332513809,
        0.061965636909008026,
        -0.059564754366874695,
        -0.04842200502753258,
        0.07133129239082336,
        0.03412553668022156,
        0.021273571997880936,
        0.03100891411304474,
        -0.04449464753270149,
        0.07001981139183044,
        -0.065846286714077,
        0.013923638500273228,
        -0.03902913257479668,
        -0.05461500212550163,
        -0.01673515886068344,
        0.06413502246141434,
        -0.058674946427345276,
        0.015607452020049095,
        0.02678597718477249,
        -0.07051906734704971,
        0.060133904218673706,
        0.04370201379060745,
        -0.030569253489375114,
        -0.02801332250237465,
        0.05666041001677513,
        -0.04994891583919525,
        0.06679099053144455,
        0.013130507431924343,
        0.00020984542788937688,
        -0.07010943442583084,
        -0.0059964898973703384,
        -0.0022094990126788616,
        0.049173809587955475,
        0.06798487156629562,
        0.012994915246963501,
        0.01215825229883194,
        0.02761109359562397,
        -0.025096580386161804,
        -0.005918131675571203,
        -0.00844439398497343,
        0.032161034643650055,
        -0.0302017480134964,
        -0.07133117318153381,
        0.04065960273146629,
        0.0493311882019043,
        -0.026963358744978905,
        -0.026200411841273308,
        -0.07190379500389099,
        -0.03968421742320061,
        -0.05967240408062935,
        -0.060177091509103775,
        0.0031947342213243246,
        0.0620446503162384,
        0.03558489680290222,
        -0.05668596178293228,
        0.06773246079683304,
        0.0702451840043068,
        -0.0002693075512070209,
        -0.040490057319402695,
        -0.06986723095178604,
        -0.017893614247441292,
        0.006507501471787691,
        0.03688017651438713,
        -0.04745561629533768,
        -0.05526650696992874,
        0.05989888310432434,
        0.003097293898463249,
        0.008241481147706509,
        0.029337571933865547,
        -0.015863902866840363,
        0.02841951884329319,
        -0.05099904537200928,
        -0.008164887316524982,
        0.0005317667964845896,
        0.06624618917703629,
        -0.03937213122844696,
        -0.00169365166220814,
        0.009841213934123516,
        0.023387504741549492,
        0.02637462317943573,
        -0.02911313809454441,
        0.03605850040912628,
        0.006523758638650179,
        -0.047200653702020645,
        0.0582304447889328,
        -0.010383358225226402,
        0.027907386422157288,
        0.02836579643189907,
        -0.03896108642220497,
        -0.02845585346221924,
        0.04542745649814606,
        -0.0072697061114013195,
        -0.060930024832487106,
        -0.022280536592006683,
        0.05537812411785126,
        -0.0358506478369236,
        -0.026789994910359383,
        0.03613409399986267,
        0.03002297692000866,
        -0.010129355825483799,
        0.05684967339038849,
        0.028790635988116264,
        0.06107328459620476,
        0.03242379426956177,
        0.0005862136022187769,
        0.021430728957057,
        0.06140846014022827,
        -0.03463316336274147,
        0.06667309999465942,
        -0.05033835023641586,
        -0.03585723787546158,
        0.03494618833065033,
        0.023298976942896843,
        0.01017238013446331,
        0.02944450080394745,
        -0.024087795987725258,
        0.03511809930205345,
        0.0341389961540699,
        0.009936688467860222,
        0.011576569639146328,
        -0.0032505274284631014,
        0.04782092571258545,
        -0.07181080430746078,
        0.030884092673659325,
        0.05138474330306053,
        0.07009516656398773,
        -0.07217283546924591,
        0.06381382793188095,
        -0.06852072477340698,
        -0.06998094171285629,
        0.01678227074444294,
        0.034366898238658905,
        -0.001038460060954094,
        -0.02862388826906681,
        -0.055057987570762634,
        -0.024968529120087624,
        0.005072283558547497,
        -0.00273487763479352,
        -0.04781565070152283,
        -0.05337105691432953,
        -0.030855150893330574,
        -0.05017135664820671,
        -0.01585935428738594,
        0.0211173202842474,
        0.02369619719684124,
        0.0058453441597521305,
        0.037015583366155624,
        0.02080371044576168,
        -0.040439900010824203,
        -0.010652045719325542,
        -0.05194549262523651,
        0.011072088964283466,
        0.026274926960468292,
        0.021527530625462532,
        0.06345514953136444,
        0.006541954353451729,
        0.029721735045313835,
        0.028254810720682144,
        0.06735550612211227,
        0.0673058032989502,
        -0.06210770830512047,
        -0.04034726321697235,
        -0.04796120524406433,
        0.027521701529622078,
        -0.028262950479984283,
        0.019442765042185783,
        0.06286352872848511,
        0.039907947182655334,
        -0.06846383959054947,
        -0.07158861309289932,
        0.05082767829298973,
        0.0377558097243309
    ],
    [
        0.022510142996907234,
        0.05194292590022087,
        -0.006315986625850201,
        0.03412490710616112,
        0.06184505298733711,
        -0.05654507502913475,
        0.051224153488874435,
        -0.048308875411748886,
        0.040301356464624405,
        0.0009015561663545668,
        0.02576264552772045,
        0.05874061584472656,
        -0.05484301224350929,
        0.0007479594205506146,
        -0.05562148615717888,
        0.055104345083236694,
        -0.04804428294301033,
        0.06061699241399765,
        -0.06542249023914337,
        0.013574212789535522,
        -0.04352813959121704,
        0.07264073193073273,
        0.02974819391965866,
        -0.03266002982854843,
        0.03543757647275925,
        0.05316561087965965,
        0.048784639686346054,
        0.033018991351127625,
        0.018108658492565155,
        -0.02940681204199791,
        0.03696490079164505,
        -0.04397348687052727,
        -0.04560234770178795,
        -0.05062710866332054,
        0.0036813607439398766,
        -0.02736721932888031,
        -0.05632422864437103,
        -0.025660185143351555,
        0.023360546678304672,
        0.029504776000976562,
        0.044988036155700684,
        0.004354539327323437,
        0.039482731372117996,
        -0.06652449816465378,
        -0.03702254965901375,
        0.037292007356882095,
        -0.012866782955825329,
        0.06929249316453934,
        0.01411132700741291,
        0.06183936446905136,
        -0.0016281994758173823,
        0.06362973153591156,
        0.0038277723360806704,
        0.03201030194759369,
        0.027005990967154503,
        -0.023965200409293175,
        0.006314587779343128,
        -0.040407445281744,
        0.03289758041501045,
        0.008480068296194077,
        -0.0587020106613636,
        0.0355946384370327,
        -0.037905238568782806,
        0.042762454599142075,
        -0.05775359645485878,
        -0.04358261451125145,
        0.06222629174590111,
        -0.053055159747600555,
        -0.05688082426786423,
        -0.06783383339643478,
        -0.06337691098451614,
        -0.06412765383720398,
        0.06446661055088043,
        0.009302892722189426,
        -0.04695438966155052,
        0.01739126816391945,
        0.04542702063918114,
        0.07324323803186417,
        -0.053162164986133575,
        -0.011055009439587593,
        0.006078237202018499,
        0.0737333819270134,
        -0.03828832134604454,
        0.014132781885564327,
        0.029095880687236786,
        0.0069533586502075195,
        0.06644129753112793,
        -0.05672827363014221,
        -0.028342030942440033,
        0.0736144408583641,
        0.022300835698843002,
        -0.0061189234256744385,
        -0.05436117202043533,
        -0.04971528425812721,
        -0.0269586443901062,
        0.021155158057808876,
        0.004766450263559818,
        -0.05520503968000412,
        -0.05989296734333038,
        -0.04657056927680969,
        -0.06480690091848373,
        -0.044552456587553024,
        0.06778223812580109,
        0.04158758372068405,
        0.03855297341942787,
        -0.052922189235687256,
        -0.045413512736558914,
        0.02727067470550537,
        0.045254047960042953,
        0.022082151845097542,
        -0.06902692466974258,
        -0.056931283324956894,
        -0.058425016701221466,
        -0.05119972676038742,
        0.0012069469084963202,
        -0.00972750224173069,
        0.023992612957954407,
        -0.006474226247519255,
        0.06121426820755005,
        0.03825848922133446,
        -0.03281151503324509,
        0.018361466005444527,
        -0.014675655402243137,
        -0.02777528576552868,
        -0.07163448631763458,
        -0.013580410741269588,
        -0.07255924493074417,
        -0.02385895885527134,
        0.06681451946496964,
        0.046477995812892914,
        0.007428062614053488,
        -0.05084242299199104,
        -0.05488981306552887,
        0.021676180884242058,
        -0.04792888090014458,
        -0.04963420331478119,
        -0.006628456059843302,
        0.007817529141902924,
        -0.07074175029993057,
        0.04453735798597336,
        0.0498727485537529,
        0.03161592036485672,
        0.06051521748304367,
        0.051201749593019485,
        0.038608893752098083,
        0.019385356456041336,
        0.06692235916852951,
        -0.06941735744476318,
        0.04720316454768181,
        0.07318062335252762,
        0.01906716264784336,
        -0.07217179983854294,
        -0.047913551330566406,
        -0.014238930307328701,
        -0.03754444420337677,
        -0.04611191898584366,
        0.05108119919896126,
        0.0357794463634491,
        -0.05942286178469658,
        0.008011329919099808,
        0.029691491276025772,
        0.0707392692565918,
        0.05693468824028969,
        -0.02852555923163891,
        -0.019639352336525917,
        0.020224040374159813,
        -0.06440873444080353,
        -0.06476955860853195,
        0.03648039326071739,
        0.018033454194664955,
        0.042606376111507416,
        -0.03121558204293251,
        -0.005358700640499592,
        -0.05152828246355057,
        0.02406960166990757,
        0.05473848804831505,
        -0.07174672186374664,
        0.020561812445521355,
        0.05688610300421715,
        -0.017619384452700615,
        0.0371917225420475,
        0.07329526543617249,
        0.05226016044616699,
        0.01836756430566311,
        0.0026835440658032894,
        0.01722513511776924,
        -0.06065116450190544,
        0.06224966049194336,
        -0.03084138035774231,
        -0.0586022213101387,
        -0.03607264161109924,
        -0.015922008082270622,
        0.03437338396906853,
        0.051296167075634,
        -0.018870079889893532,
        -0.024601232260465622,
        -0.01710701733827591,
        -0.007324399426579475,
        -0.04547720029950142,
        -0.0006730795721523464,
        -0.04014727100729942,
        0.009147884324193,
        0.014794427901506424,
        -0.03859145566821098,
        -0.06814402341842651,
        0.046388495713472366,
        0.0031885476782917976,
        -0.01891341060400009,
        -0.05899873375892639,
        0.05824170261621475,
        -0.04996770992875099,
        0.01917254738509655,
        0.02192956954240799,
        0.03458862006664276,
        0.008494878187775612,
        0.0152721107006073,
        0.005317328963428736,
        0.026201777160167694,
        0.06012542545795441,
        -0.03797156736254692,
        0.014743856154382229,
        0.06988950073719025,
        -0.06750871986150742,
        -0.06894783675670624,
        0.06793001294136047,
        -0.0532224141061306,
        0.0023058943916112185,
        0.037073008716106415,
        -0.014486826956272125,
        -0.03491635620594025,
        0.0007472520228475332,
        -0.06712661683559418,
        0.003706693882122636,
        0.017914969474077225,
        -0.001722593093290925,
        0.06338515132665634,
        0.06447046995162964,
        -0.025572240352630615,
        -0.010115955956280231,
        0.0654306560754776,
        0.04724060371518135,
        0.07115325331687927,
        -0.06493030488491058,
        0.06830789148807526,
        0.021967733278870583,
        0.014817735180258751,
        -0.07085879892110825,
        -0.04513401910662651,
        -0.017744168639183044,
        -0.009157705120742321,
        -0.010349794290959835,
        -0.0696626603603363,
        -0.017418377101421356,
        -0.0689631924033165,
        -0.0116251977160573,
        0.06922953575849533,
        -0.05906791612505913,
        -0.049340832978487015,
        0.06961984932422638,
        0.05342315882444382,
        0.07341011613607407,
        -0.02007666788995266,
        -0.030622445046901703,
        0.052245691418647766,
        -0.06816668808460236,
        0.03683539852499962,
        0.04222854599356651,
        0.02124641090631485,
        0.006861279718577862,
        0.0019223286071792245,
        0.003475198056548834,
        -0.06550555676221848,
        0.03960447758436203,
        -0.06292259693145752,
        -0.010642356239259243,
        0.02110270783305168,
        -0.06491349637508392,
        0.0052315304055809975,
        -0.06706696003675461,
        -0.030833754688501358,
        -0.06377767026424408,
        0.02513035759329796,
        -0.027133306488394737,
        0.06724172085523605,
        0.046960487961769104,
        -0.04723724350333214,
        0.005187622737139463,
        0.01927894912660122,
        -0.0417318232357502,
        0.019627580419182777,
        -0.05569851025938988,
        0.057361166924238205,
        0.05285977944731712,
        0.061868827790021896,
        0.06958737969398499,
        -0.06592977792024612,
        0.06667205691337585,
        0.0642392560839653,
        -0.04180726781487465,
        0.04097552224993706,
        0.061361901462078094,
        0.05352777615189552,
        -0.058198846876621246,
        0.02504713088274002,
        0.011595613323152065,
        0.004965829662978649,
        -0.06692693382501602,
        0.016011804342269897,
        0.06543660908937454,
        -0.05172760784626007,
        0.0690024122595787,
        -0.03193512186408043,
        -0.021570630371570587,
        0.02643846906721592,
        -0.06383045762777328,
        -0.05884483456611633,
        0.056261591613292694,
        -0.05586228519678116,
        0.009527617134153843,
        -0.05195163935422897,
        -0.0642721951007843,
        0.050221625715494156,
        0.03478148207068443,
        -0.05104762315750122,
        0.03987295180559158,
        -0.021504975855350494,
        -0.012319979257881641,
        0.013477674685418606,
        0.019670777022838593,
        -0.03054836019873619,
        0.020476946607232094,
        -0.05619864538311958,
        -0.03974153473973274,
        -0.06393005698919296,
        0.06853699684143066,
        0.020389718934893608,
        -0.05499512702226639,
        0.07202602922916412,
        -0.012713680975139141,
        0.01673751138150692,
        0.07159159332513809,
        0.061965636909008026,
        -0.059564754366874695,
        -0.04842200502753258,
        0.07133129239082336,
        0.03412553668022156,
        0.021273571997880936,
        0.03100891411304474,
        -0.04449464753270149,
        0.07001981139183044,
        -0.065846286714077,
        0.013923638500273228,
        -0.03902913257479668,
        -0.05461500212550163,
        -0.01673515886068344,
        0.06413502246141434,
        -0.058674946427345276,
        0.015607452020049095,
        0.02678597718477249,
        -0.07051906734704971,
        0.060133904218673706,
        0.04370201379060745,
        -0.030569253489375114,
        -0.02801332250237465,
        0.05666041001677513,
        -0.04994891583919525,
        0.06679099053144455,
        0.013130507431924343,
        0.00020984542788937688,
        -0.07010943442583084,
        -0.0059964898973703384,
        -0.0022094990126788616,
        0.049173809587955475,
        0.06798487156629562,
        0.012994915246963501,
        0.01215825229883194,
        0.02761109359562397,
        -0.025096580386161804,
        -0.005918131675571203,
        -0.00844439398497343,
        0.032161034643650055,
        -0.0302017480134964,
        -0.07133117318153381,
        0.04065960273146629,
        0.0493311882019043,
        -0.026963358744978905,
        -0.026200411841273308,
        -0.07190379500389099,
        -0.03968421742320061,
        -0.05967240408062935,
        -0.060177091509103775,
        0.0031947342213243246,
        0.0620446503162384,
        0.03558489680290222,
        -0.05668596178293228,
        0.06773246079683304,
        0.0702451840043068,
        -0.0002693075512070209,
        -0.040490057319402695,
        -0.06986723095178604,
        -0.017893614247441292,
        0.006507501471787691,
        0.03688017651438713,
        -0.04745561629533768,
        -0.05526650696992874,
        0.05989888310432434,
        0.003097293898463249,
        0.008241481147706509,
        0.029337571933865547,
        -0.015863902866840363,
        0.02841951884329319,
        -0.05099904537200928,
        -0.008164887316524982,
        0.0005317667964845896,
        0.06624618917703629,
        -0.03937213122844696,
        -0.00169365166220814,
        0.009841213934123516,
        0.023387504741549492,
        0.02637462317943573,
        -0.02911313809454441,
        0.03605850040912628,
        0.006523758638650179,
        -0.047200653702020645,
        0.0582304447889328,
        -0.010383358225226402,
        0.027907386422157288,
        0.02836579643189907,
        -0.03896108642220497,
        -0.02845585346221924,
        0.04542745649814606,
        -0.0072697061114013195,
        -0.060930024832487106,
        -0.022280536592006683,
        0.05537812411785126,
        -0.0358506478369236,
        -0.026789994910359383,
        0.03613409399986267,
        0.03002297692000866,
        -0.010129355825483799,
        0.05684967339038849,
        0.028790635988116264,
        0.06107328459620476,
        0.03242379426956177,
        0.0005862136022187769,
        0.021430728957057,
        0.06140846014022827,
        -0.03463316336274147,
        0.06667309999465942,
        -0.05033835023641586,
        -0.03585723787546158,
        0.03494618833065033,
        0.023298976942896843,
        0.01017238013446331,
        0.02944450080394745,
        -0.024087795987725258,
        0.03511809930205345,
        0.0341389961540699,
        0.009936688467860222,
        0.011576569639146328,
        -0.0032505274284631014,
        0.04782092571258545,
        -0.07181080430746078,
        0.030884092673659325,
        0.05138474330306053,
        0.07009516656398773,
        -0.07217283546924591,
        0.06381382793188095,
        -0.06852072477340698,
        -0.06998094171285629,
        0.01678227074444294,
        0.034366898238658905,
        -0.001038460060954094,
        -0.02862388826906681,
        -0.055057987570762634,
        -0.024968529120087624,
        0.005072283558547497,
        -0.00273487763479352,
        -0.04781565070152283,
        -0.05337105691432953,
        -0.030855150893330574,
        -0.05017135664820671,
        -0.01585935428738594,
        0.0211173202842474,
        0.02369619719684124,
        0.0058453441597521305,
        0.037015583366155624,
        0.02080371044576168,
        -0.040439900010824203,
        -0.010652045719325542,
        -0.05194549262523651,
        0.011072088964283466,
        0.026274926960468292,
        0.021527530625462532,
        0.06345514953136444,
        0.006541954353451729,
        0.029721735045313835,
        0.028254810720682144,
        0.06735550612211227,
        0.0673058032989502,
        -0.06210770830512047,
        -0.04034726321697235,
        -0.04796120524406433,
        0.027521701529622078,
        -0.028262950479984283,
        0.019442765042185783,
        0.06286352872848511,
        0.039907947182655334,
        -0.06846383959054947,
        -0.07158861309289932,
        0.05082767829298973,
        0.0377558097243309
    ],
    [
        0.024359164759516716,
        0.050477731972932816,
        -0.0011647740611806512,
        0.033625658601522446,
        0.06291095167398453,
        -0.05615534260869026,
        0.051156409084796906,
        -0.048941269516944885,
        0.0395444817841053,
        -0.004951441660523415,
        0.024758191779255867,
        0.04916321113705635,
        -0.05425172299146652,
        -0.0018124607158824801,
        -0.055737633258104324,
        0.054279785603284836,
        -0.04679176211357117,
        0.0628548339009285,
        -0.06707405298948288,
        0.017586635425686836,
        -0.04699431732296944,
        0.0731792151927948,
        0.02986345812678337,
        -0.035316213965415955,
        0.03480586037039757,
        0.05128433555364609,
        0.04808562994003296,
        0.032486431300640106,
        0.015288902446627617,
        -0.025866197422146797,
        0.04165642708539963,
        -0.044537849724292755,
        -0.04450826346874237,
        -0.05136673524975777,
        0.0080573670566082,
        -0.020694425329566002,
        -0.05709897726774216,
        -0.020759155973792076,
        0.017087994143366814,
        0.028858037665486336,
        0.04362620413303375,
        0.009149446152150631,
        0.03951935097575188,
        -0.06618382036685944,
        -0.04280319809913635,
        0.03997458517551422,
        -0.017031243070960045,
        0.06937697529792786,
        0.0085340216755867,
        0.062378983944654465,
        0.003052379237487912,
        0.065559521317482,
        -0.0031290152110159397,
        0.038107097148895264,
        0.027023494243621826,
        -0.026723915711045265,
        0.0033054330851882696,
        -0.04282042384147644,
        0.03573097661137581,
        0.0027137359138578176,
        -0.05819021165370941,
        0.035290367901325226,
        -0.03853972256183624,
        0.042510099709033966,
        -0.05572136119008064,
        -0.043485358357429504,
        0.061405643820762634,
        -0.053241319954395294,
        -0.059188563376665115,
        -0.06694910675287247,
        -0.06448545306921005,
        -0.06521641463041306,
        0.06602580845355988,
        0.010579831898212433,
        -0.04908901825547218,
        0.021484073251485825,
        0.042532823979854584,
        0.07363514602184296,
        -0.05497630313038826,
        -0.006845942232757807,
        0.0031805159524083138,
        0.07398511469364166,
        -0.036889128386974335,
        0.015535777434706688,
        0.03380829095840454,
        0.005441403482109308,
        0.06646240502595901,
        -0.057989317923784256,
        -0.017602838575839996,
        0.07388535141944885,
        0.030018430203199387,
        -0.006708990316838026,
        -0.05802851542830467,
        -0.04821181669831276,
        -0.02574656717479229,
        0.022451864555478096,
        0.00048114205128513277,
        -0.05820475146174431,
        -0.05813833326101303,
        -0.04619976505637169,
        -0.063880555331707,
        -0.04684102535247803,
        0.06775234639644623,
        0.045803800225257874,
        0.03674640879034996,
        -0.05385485664010048,
        -0.044808391481637955,
        0.02616683952510357,
        0.04382223263382912,
        0.033097151666879654,
        -0.06876765191555023,
        -0.055323731154203415,
        -0.06068713963031769,
        -0.05540589243173599,
        -0.0006448950152844191,
        -0.016720684245228767,
        0.023493051528930664,
        -0.010726218111813068,
        0.06342656910419464,
        0.04473670944571495,
        -0.03292734920978546,
        0.022929435595870018,
        -0.012297414243221283,
        -0.024517206475138664,
        -0.07225679606199265,
        -0.011688128113746643,
        -0.07273761928081512,
        -0.01915072463452816,
        0.0671900287270546,
        0.04473967105150223,
        0.006103682331740856,
        -0.05434637516736984,
        -0.05300173908472061,
        0.019359908998012543,
        -0.04817429184913635,
        -0.05071055889129639,
        -0.004301989451050758,
        0.008660314604640007,
        -0.07092676311731339,
        0.050029538571834564,
        0.057508956640958786,
        0.03514914959669113,
        0.06180112063884735,
        0.05294020473957062,
        0.041803497821092606,
        0.013789844699203968,
        0.06762879341840744,
        -0.06950120627880096,
        0.043371863663196564,
        0.07359880954027176,
        0.018639838322997093,
        -0.07252153009176254,
        -0.047924064099788666,
        -0.013500550761818886,
        -0.029368465766310692,
        -0.04509081318974495,
        0.047979339957237244,
        0.029360510408878326,
        -0.05972488969564438,
        0.0016169720329344273,
        0.02490416169166565,
        0.07132246345281601,
        0.058580297976732254,
        -0.030855538323521614,
        -0.009182428009808064,
        0.014868446625769138,
        -0.06469489634037018,
        -0.06416158378124237,
        0.03331662714481354,
        0.016109220683574677,
        0.039503101259469986,
        -0.03193012997508049,
        -0.0000162437299877638,
        -0.052871908992528915,
        0.021550584584474564,
        0.05092611908912659,
        -0.07242591679096222,
        0.022050464525818825,
        0.05708715692162514,
        -0.014277719892561436,
        0.036451030522584915,
        0.07365778833627701,
        0.049762677401304245,
        0.02215678058564663,
        0.003449114505201578,
        0.01891377940773964,
        -0.05914788320660591,
        0.06414693593978882,
        -0.03678860887885094,
        -0.059285085648298264,
        -0.03711959347128868,
        -0.011880558915436268,
        0.03787998482584953,
        0.050175417214632034,
        -0.02570054680109024,
        -0.02413124404847622,
        -0.015021145343780518,
        -0.007497722748667002,
        -0.048134494572877884,
        0.006828301586210728,
        -0.042138829827308655,
        0.016445646062493324,
        0.00550438929349184,
        -0.04210599511861801,
        -0.06902555376291275,
        0.04536726698279381,
        0.009701731614768505,
        -0.014422466047108173,
        -0.059768740087747574,
        0.06000400707125664,
        -0.04854695871472359,
        0.017889229580760002,
        0.025229455903172493,
        0.033595211803913116,
        0.005949509795755148,
        0.015160945244133472,
        0.013006812892854214,
        0.02508690021932125,
        0.06173868104815483,
        -0.0311894491314888,
        0.01707172952592373,
        0.06954434514045715,
        -0.0664101392030716,
        -0.06870052963495255,
        0.06730153411626816,
        -0.051863979548215866,
        0.004098559729754925,
        0.03682974725961685,
        -0.017806652933359146,
        -0.03165658935904503,
        -0.005424248054623604,
        -0.06581777334213257,
        0.0013679413823410869,
        0.01593240536749363,
        0.006024918518960476,
        0.06489261984825134,
        0.06386645883321762,
        -0.02347579598426819,
        -0.00986259151250124,
        0.06436342000961304,
        0.04590507969260216,
        0.07209707051515579,
        -0.06537938863039017,
        0.06819213926792145,
        0.017088202759623528,
        0.007854794152081013,
        -0.07175198197364807,
        -0.051355671137571335,
        -0.00702104764059186,
        -0.005303716752678156,
        -0.013619760051369667,
        -0.07028130441904068,
        -0.024301650002598763,
        -0.06869752705097198,
        -0.006163956131786108,
        0.06967035681009293,
        -0.059931524097919464,
        -0.04637837037444115,
        0.06920444220304489,
        0.04986785724759102,
        0.07362827658653259,
        -0.026443591341376305,
        -0.024881424382328987,
        0.05381381884217262,
        -0.06915951520204544,
        0.03699878975749016,
        0.041412558406591415,
        0.023815350607037544,
        0.004472232423722744,
        -0.0007235605153255165,
        0.010316302999854088,
        -0.06472676992416382,
        0.043436624109745026,
        -0.06324845552444458,
        -0.015550213865935802,
        0.02053237333893776,
        -0.06441240757703781,
        0.013270322233438492,
        -0.06788647919893265,
        -0.028379356488585472,
        -0.05943277105689049,
        0.021660545840859413,
        -0.029267678037285805,
        0.06806943565607071,
        0.04547669366002083,
        -0.04380210116505623,
        0.006723193451762199,
        0.024652156978845596,
        -0.03985045105218887,
        0.0207134410738945,
        -0.05562544986605644,
        0.0542747899889946,
        0.05167398601770401,
        0.0640537217259407,
        0.07048675417900085,
        -0.06699316203594208,
        0.06710702180862427,
        0.06295722723007202,
        -0.044412579387426376,
        0.040406521409749985,
        0.060465142130851746,
        0.05044911801815033,
        -0.05781636759638786,
        0.029712075367569923,
        0.006607141811400652,
        -0.003266831859946251,
        -0.06734278053045273,
        0.013418340124189854,
        0.06505026668310165,
        -0.05463603883981705,
        0.06939869374036789,
        -0.030352355912327766,
        -0.02328374609351158,
        0.025643546134233475,
        -0.06331872195005417,
        -0.056573182344436646,
        0.056513961404561996,
        -0.05886204540729523,
        0.008531782776117325,
        -0.05027025192975998,
        -0.06388752162456512,
        0.05103674903512001,
        0.029592353850603104,
        -0.05007409304380417,
        0.04169110208749771,
        -0.02485170029103756,
        -0.018474910408258438,
        0.010448763146996498,
        0.024186545982956886,
        -0.03264939785003662,
        0.015407492406666279,
        -0.05558975413441658,
        -0.04208032041788101,
        -0.06424969434738159,
        0.06894571334123611,
        0.016545986756682396,
        -0.05323920026421547,
        0.07271146029233932,
        -0.012083486653864384,
        0.015911605209112167,
        0.07190757244825363,
        0.0639597624540329,
        -0.06227054074406624,
        -0.04549695551395416,
        0.070945605635643,
        0.03257213160395622,
        0.01780085079371929,
        0.028550531715154648,
        -0.039175208657979965,
        0.0700768455862999,
        -0.06617604941129684,
        0.012091094627976418,
        -0.04468090087175369,
        -0.05284571275115013,
        -0.012811793945729733,
        0.06343840062618256,
        -0.056781329214572906,
        0.015735721215605736,
        0.025174058973789215,
        -0.07078560441732407,
        0.060772184282541275,
        0.04546501860022545,
        -0.025043725967407227,
        -0.03024378977715969,
        0.05683193355798721,
        -0.050482552498579025,
        0.06650868058204651,
        0.009650249034166336,
        -0.005367194302380085,
        -0.0700041651725769,
        -0.00914323516190052,
        0.004765570163726807,
        0.0419611856341362,
        0.06776491552591324,
        0.008042044937610626,
        0.012526537291705608,
        0.02913137525320053,
        -0.026440231129527092,
        -0.009953221306204796,
        -0.007030630484223366,
        0.030254440382122993,
        -0.03101332299411297,
        -0.07165664434432983,
        0.043691519647836685,
        0.04859006777405739,
        -0.0230791624635458,
        -0.019961895421147346,
        -0.07278236746788025,
        -0.03997723385691643,
        -0.05690545588731766,
        -0.06106608361005783,
        0.000537005951628089,
        0.05997592583298683,
        0.038509856909513474,
        -0.058302074670791626,
        0.06817443668842316,
        0.07122940570116043,
        -0.0013256854144856334,
        -0.03616679087281227,
        -0.06927977502346039,
        -0.013800504617393017,
        0.008403100073337555,
        0.03632890805602074,
        -0.048901110887527466,
        -0.053516779094934464,
        0.058553218841552734,
        0.009675081819295883,
        0.006564273964613676,
        0.029121091589331627,
        -0.013063971884548664,
        0.02511410042643547,
        -0.0535445511341095,
        -0.007913343608379364,
        0.002479111310094595,
        0.0685672014951706,
        -0.03895461559295654,
        -0.010127371177077293,
        0.011462416499853134,
        0.026205740869045258,
        0.022217068821191788,
        -0.025671949610114098,
        0.04478174448013306,
        0.004106984008103609,
        -0.04618952423334122,
        0.05674407631158829,
        -0.007616276387125254,
        0.02664581499993801,
        0.03403147682547569,
        -0.03732583299279213,
        -0.0300129521638155,
        0.04160866141319275,
        -0.0036291645374149084,
        -0.06215519830584526,
        -0.01786508411169052,
        0.05907541513442993,
        -0.04103729501366615,
        -0.02051335759460926,
        0.03591783717274666,
        0.027561962604522705,
        -0.015898317098617554,
        0.05352768674492836,
        0.029582979157567024,
        0.06195228546857834,
        0.030230190604925156,
        0.008509707637131214,
        0.020745733752846718,
        0.05954543501138687,
        -0.0313582606613636,
        0.06644173711538315,
        -0.04908713698387146,
        -0.03293377906084061,
        0.02520899474620819,
        0.02496175281703472,
        0.006052990909665823,
        0.027408940717577934,
        -0.024527698755264282,
        0.03069428540766239,
        0.03518354520201683,
        0.010813390836119652,
        0.011663595214486122,
        -0.006225731689482927,
        0.049589402973651886,
        -0.07267314940690994,
        0.03249219432473183,
        0.05089534446597099,
        0.0706193596124649,
        -0.07214964926242828,
        0.06539209932088852,
        -0.06853236258029938,
        -0.06885851919651031,
        0.018321281298995018,
        0.03655341640114784,
        -0.000624645734205842,
        -0.030146420001983643,
        -0.05645917356014252,
        -0.02811085246503353,
        0.007905563339591026,
        -0.001161837368272245,
        -0.0402609147131443,
        -0.05246099457144737,
        -0.02216845005750656,
        -0.04683089256286621,
        -0.022329295054078102,
        0.017270270735025406,
        0.01364410575479269,
        0.002714036265388131,
        0.030034000054001808,
        0.022256793454289436,
        -0.04127002879977226,
        -0.016456210985779762,
        -0.054356273263692856,
        0.019142605364322662,
        0.02746603824198246,
        0.01747165061533451,
        0.06433108448982239,
        0.00251985271461308,
        0.02550719864666462,
        0.03283871337771416,
        0.06740572303533554,
        0.06845024973154068,
        -0.06168690323829651,
        -0.0407906137406826,
        -0.043196115642786026,
        0.023278266191482544,
        -0.026173358783125877,
        0.016082432121038437,
        0.06175728142261505,
        0.03880833834409714,
        -0.06920018047094345,
        -0.07252687215805054,
        0.04645223915576935,
        0.043921299278736115
    ],
    [
        0.05227087438106537,
        0.05668815225362778,
        0.05578652769327164,
        0.01097787730395794,
        -0.002288012532517314,
        0.03586031123995781,
        -0.03163284435868263,
        -0.05631633102893829,
        0.06026291847229004,
        -0.02304880879819393,
        -0.04198587313294411,
        0.05339217558503151,
        -0.04402186721563339,
        0.05814151093363762,
        -0.05731310695409775,
        0.060974009335041046,
        0.030815154314041138,
        0.057991430163383484,
        -0.028343565762043,
        -0.062271665781736374,
        0.0012317410437390208,
        0.062050409615039825,
        -0.05105087161064148,
        -0.05203857645392418,
        0.0020669950172305107,
        0.05151813477277756,
        0.053305916488170624,
        -0.008301010355353355,
        0.046340081840753555,
        0.049090676009655,
        0.017546474933624268,
        -0.061880405992269516,
        -0.006839042063802481,
        -0.05983002483844757,
        0.05518157407641411,
        -0.04920263960957527,
        -0.060116976499557495,
        -0.04832165315747261,
        0.05755201354622841,
        -0.009133215062320232,
        0.04704473167657852,
        0.04623318836092949,
        0.033865269273519516,
        -0.05997210368514061,
        0.048647042363882065,
        0.056697580963373184,
        0.00977556686848402,
        0.03301238268613815,
        0.028070621192455292,
        -0.01951204612851143,
        0.058630309998989105,
        0.06196608021855354,
        -0.04950627684593201,
        0.05559443682432175,
        -0.003697443986311555,
        0.01234704814851284,
        0.018128832802176476,
        0.013405894860625267,
        0.01208161748945713,
        -0.05422544106841087,
        -0.05197090283036232,
        0.014736403711140156,
        -0.04745025187730789,
        0.062115348875522614,
        -0.05555375665426254,
        -0.05203412473201752,
        -0.02916840836405754,
        -0.035993847995996475,
        -0.06101372838020325,
        0.014529203064739704,
        0.050057049840688705,
        -0.06176966428756714,
        0.05825096368789673,
        -0.029497653245925903,
        -0.0025502440985292196,
        -0.029487082734704018,
        -0.03470885381102562,
        0.062262337654829025,
        -0.03249901905655861,
        -0.050234414637088776,
        0.003784877946600318,
        0.05757799744606018,
        -0.050594642758369446,
        0.05232440307736397,
        0.03275565803050995,
        0.04837443679571152,
        0.05210832506418228,
        -0.05326823890209198,
        0.04825311154127121,
        0.00941893644630909,
        0.004499128088355064,
        -0.04164772853255272,
        -0.061830662190914154,
        -0.03901896998286247,
        -0.04347553104162216,
        0.057803284376859665,
        0.03172418102622032,
        -0.05882779136300087,
        0.0457577221095562,
        0.01996365748345852,
        -0.06062590703368187,
        0.024168623611330986,
        0.03628958761692047,
        0.028389638289809227,
        -0.015769844874739647,
        -0.060696471482515335,
        -0.05160386860370636,
        0.02943769469857216,
        0.04638879746198654,
        0.056652192026376724,
        -0.047170691192150116,
        -0.05123668536543846,
        -0.05964547023177147,
        0.002616780810058117,
        -0.035865895450115204,
        -0.0277253370732069,
        0.05641018599271774,
        0.0013525505783036351,
        0.06235385686159134,
        0.04125620052218437,
        0.038760293275117874,
        0.06029408425092697,
        0.009036150760948658,
        -0.05366143211722374,
        -0.047164492309093475,
        -0.05337929353117943,
        -0.05966757982969284,
        -0.007045389618724585,
        0.057565972208976746,
        0.03247705101966858,
        -0.03340489789843559,
        -0.057288091629743576,
        -0.052036456763744354,
        0.03889816254377365,
        -0.04977062717080116,
        -0.057752709835767746,
        0.013556651771068573,
        0.056666649878025055,
        -0.04939274489879608,
        0.013747995719313622,
        0.05788861960172653,
        0.013402426615357399,
        0.05974825471639633,
        -0.005960332229733467,
        0.06180708110332489,
        -0.06003214046359062,
        0.012094918638467789,
        -0.02535896934568882,
        -0.003238228615373373,
        0.03314392268657684,
        0.059312645345926285,
        -0.060756783932447433,
        0.0071310014463961124,
        -0.049980804324150085,
        0.04776851087808609,
        -0.020367588847875595,
        -0.02025916799902916,
        0.010076160542666912,
        -0.017347635701298714,
        0.029745860025286674,
        0.05002391338348389,
        0.04235142841935158,
        0.05303310602903366,
        0.054341502487659454,
        0.04779599606990814,
        0.029029106721282005,
        0.028611473739147186,
        0.043520066887140274,
        0.06080332025885582,
        0.02047862485051155,
        -0.023444637656211853,
        0.040886472910642624,
        -0.017154065892100334,
        -0.06081008166074753,
        0.05069180205464363,
        0.060430433601140976,
        -0.0227932371199131,
        0.05792974680662155,
        0.054186657071113586,
        -0.043087489902973175,
        -0.034397777169942856,
        0.062326010316610336,
        -0.01566540077328682,
        -0.01590738445520401,
        0.05704496428370476,
        -0.04101388528943062,
        -0.053320810198783875,
        0.058826882392168045,
        -0.05273660644888878,
        -0.05397050455212593,
        -0.03659377619624138,
        0.040362581610679626,
        -0.028300220146775246,
        -0.01585754007101059,
        -0.04138953983783722,
        0.007897911593317986,
        -0.056472714990377426,
        -0.062152352184057236,
        -0.021564245223999023,
        -0.007362096104770899,
        -0.0054616560228168964,
        -0.055583514273166656,
        0.021111108362674713,
        -0.05940987914800644,
        -0.056674860417842865,
        0.0011885708663612604,
        0.029241643846035004,
        0.047809898853302,
        -0.06075868010520935,
        0.061461493372917175,
        -0.05515678972005844,
        0.04926898330450058,
        -0.05039641261100769,
        -0.001061021350324154,
        0.0054549709893763065,
        0.008606906980276108,
        0.0011290244292467833,
        0.043730996549129486,
        0.060017239302396774,
        0.058577146381139755,
        0.0074532548896968365,
        0.05608145147562027,
        -0.019996222108602524,
        -0.0407150462269783,
        0.05470925569534302,
        -0.024868585169315338,
        -0.014735839329659939,
        -0.01604982651770115,
        -0.045459333807229996,
        -0.04965739697217941,
        0.006102382205426693,
        -0.06046247482299805,
        0.013190998695790768,
        0.02992314286530018,
        0.013179564848542213,
        0.05969446897506714,
        0.060324776917696,
        -0.04736458137631416,
        0.028560273349285126,
        0.061978284269571304,
        -0.02335446886718273,
        0.06066940724849701,
        -0.062188055366277695,
        0.06036606803536415,
        0.04480127990245819,
        -0.03624224290251732,
        -0.058787014335393906,
        -0.028202325105667114,
        -0.020793236792087555,
        0.0030413558706641197,
        -0.05670050531625748,
        -0.049566205590963364,
        0.05925285071134567,
        -0.06074000149965286,
        -0.06068505346775055,
        0.056922465562820435,
        0.05648183077573776,
        -0.058618053793907166,
        0.0187691617757082,
        -0.026564065366983414,
        0.0604536198079586,
        -0.05917350575327873,
        -0.026954250410199165,
        0.05245004966855049,
        -0.006247804965823889,
        0.05452289432287216,
        0.042655885219573975,
        0.05743798986077309,
        -0.03825339302420616,
        0.02452070638537407,
        0.03557240962982178,
        -0.056895818561315536,
        0.06107088178396225,
        -0.039397187530994415,
        0.0002616554847918451,
        0.05130460113286972,
        -0.03185300901532173,
        -0.005945452023297548,
        -0.04961158335208893,
        -0.01816936582326889,
        -0.049259670078754425,
        -0.03721536323428154,
        -0.058979105204343796,
        0.030351560562849045,
        -0.020147712901234627,
        -0.0445723831653595,
        0.020044470205903053,
        0.061229247599840164,
        0.05206546559929848,
        0.05563924461603165,
        -0.050413891673088074,
        0.04893423989415169,
        -0.022891508415341377,
        0.04123074188828468,
        0.06051543354988098,
        -0.05889292061328888,
        0.057779498398303986,
        0.029848987236618996,
        -0.06208392232656479,
        0.05318206548690796,
        0.054837409406900406,
        0.023979905992746353,
        -0.04736790806055069,
        0.03795722499489784,
        -0.050845611840486526,
        -0.021040068939328194,
        -0.05353659391403198,
        0.04223495349287987,
        -0.05306689441204071,
        -0.038794249296188354,
        0.04819149151444435,
        0.0060277217999100685,
        0.03287056088447571,
        -0.05671929940581322,
        -0.04240471497178078,
        -0.054650191217660904,
        0.003062971169129014,
        -0.027993690222501755,
        0.05336115509271622,
        -0.05170223489403725,
        0.02706216834485531,
        -0.02207840234041214,
        0.059465061873197556,
        0.05454475060105324,
        0.03811163455247879,
        -0.059659287333488464,
        -0.03489091619849205,
        -0.047905102372169495,
        -0.004122091457247734,
        -0.023814544081687927,
        -0.028255442157387733,
        -0.056200187653303146,
        0.06226343661546707,
        -0.022292355075478554,
        0.056942395865917206,
        0.05392587557435036,
        -0.05048912763595581,
        0.049713365733623505,
        0.03752012178301811,
        0.05648842453956604,
        -0.018184281885623932,
        0.056196101009845734,
        -0.06081446632742882,
        -0.05532056465744972,
        -0.037479136139154434,
        0.04368675500154495,
        0.03781512752175331,
        0.054115455597639084,
        -0.044192440807819366,
        0.05512222275137901,
        0.055950820446014404,
        0.03781755268573761,
        0.0002465718425810337,
        0.009896762669086456,
        -0.03410433232784271,
        0.030553195625543594,
        -0.043414074927568436,
        -0.047947291284799576,
        0.05645487830042839,
        -0.06084365397691727,
        0.04251113906502724,
        0.007256855722516775,
        -0.015690533444285393,
        -0.023153062909841537,
        -0.05296038091182709,
        -0.048257023096084595,
        0.060082342475652695,
        -0.03231870010495186,
        0.05906447768211365,
        -0.05318520590662956,
        -0.004404599778354168,
        0.01016501896083355,
        0.042996082454919815,
        0.053795743733644485,
        0.03900550305843353,
        0.003410287434235215,
        -0.056143127381801605,
        0.05704573169350624,
        0.06189719960093498,
        0.050741128623485565,
        -0.027089467272162437,
        -0.056710753589868546,
        -0.061272699385881424,
        0.01093815453350544,
        0.06079747527837753,
        -0.05273127555847168,
        -0.029020966961979866,
        -0.05279582738876343,
        0.0019482452189549804,
        -0.04362616688013077,
        -0.06224648654460907,
        -0.02043801359832287,
        0.006288814824074507,
        -0.04269056394696236,
        0.040704596787691116,
        0.05957186222076416,
        0.05050044134259224,
        0.035001955926418304,
        0.027512792497873306,
        -0.05701262503862381,
        -0.02582295425236225,
        0.05660281330347061,
        -0.05442717671394348,
        -0.061740268021821976,
        0.04796762391924858,
        0.04737706854939461,
        0.03826560452580452,
        0.025363212451338768,
        0.041493721306324005,
        0.061054568737745285,
        -0.05711771920323372,
        -0.05343310534954071,
        0.05083760619163513,
        0.05017322674393654,
        0.0493609718978405,
        -0.05119818449020386,
        -0.0017911315662786365,
        0.04933464527130127,
        0.012758944183588028,
        0.05691337212920189,
        -0.05793667584657669,
        -0.03565298765897751,
        0.006248156074434519,
        -0.045113202184438705,
        0.0359342023730278,
        0.0010041218483820558,
        0.0022529885172843933,
        0.04928794503211975,
        0.007588052656501532,
        -0.05243820324540138,
        0.06171188876032829,
        -0.03599425405263901,
        -0.06099523976445198,
        -0.015128943137824535,
        -0.046100035309791565,
        -0.049764107912778854,
        -0.05876605957746506,
        -0.05572773888707161,
        -0.03282037749886513,
        0.044004518538713455,
        0.04172128066420555,
        0.041493579745292664,
        0.027386127039790154,
        0.05495069921016693,
        0.04543549194931984,
        0.005324664060026407,
        0.056693173944950104,
        -0.020164554938673973,
        0.053895629942417145,
        -0.01454930566251278,
        0.008195703849196434,
        -0.027743414044380188,
        0.03385613486170769,
        0.05588611215353012,
        0.041315071284770966,
        -0.055950991809368134,
        0.0411062091588974,
        -0.025111930444836617,
        -0.008497352711856365,
        0.035908229649066925,
        -0.04926016181707382,
        0.024466508999466896,
        -0.05860292166471481,
        0.049619462341070175,
        -0.02225453220307827,
        0.06114903464913368,
        -0.019359365105628967,
        0.05269298702478409,
        -0.057367198169231415,
        -0.055667366832494736,
        0.009537640027701855,
        0.061011020094156265,
        0.027550680562853813,
        0.02872752770781517,
        -0.06196588650345802,
        0.017179742455482483,
        0.03970137611031532,
        0.05863098427653313,
        0.05215007811784744,
        -0.05859655886888504,
        -0.05701037496328354,
        -0.05699877440929413,
        -0.05281104892492294,
        0.009741299785673618,
        0.038550153374671936,
        0.04525405913591385,
        0.04565440118312836,
        0.055240754038095474,
        -0.05492223799228668,
        -0.024938177317380905,
        -0.05387788638472557,
        -0.008833452127873898,
        0.04893024265766144,
        0.01744583062827587,
        0.00002368448258494027,
        0.023874839767813683,
        0.04759698733687401,
        0.054795801639556885,
        0.051421504467725754,
        0.06067483127117157,
        -0.056969527155160904,
        0.005253918468952179,
        -0.05631479248404503,
        -0.0427769310772419,
        0.006481064949184656,
        -0.05997958034276962,
        0.06231388822197914,
        -0.01899493671953678,
        -0.003914134576916695,
        -0.055122010409832,
        -0.024626262485980988,
        0.03788711503148079
    ],
    [
        -0.05804461985826492,
        0.024912869557738304,
        -0.0011583208106458187,
        0.034171540290117264,
        0.03334146738052368,
        -0.0006933988188393414,
        0.02951716259121895,
        -0.018703682348132133,
        0.049265697598457336,
        0.02497114986181259,
        -0.048007529228925705,
        -0.016379909589886665,
        -0.04310378432273865,
        -0.011589315719902515,
        -0.06233194097876549,
        0.0687159076333046,
        -0.05993892624974251,
        0.035796087235212326,
        -0.054748713970184326,
        0.05534938722848892,
        -0.0688374936580658,
        0.023064853623509407,
        -0.011451374739408493,
        -0.04829724505543709,
        0.04523514583706856,
        -0.023541834205389023,
        0.06553012132644653,
        0.0171181820333004,
        0.019371548667550087,
        -0.05175020918250084,
        0.026676183566451073,
        -0.02226591855287552,
        0.01664729416370392,
        -0.05516326427459717,
        0.014703976921737194,
        0.037822920829057693,
        -0.05513766035437584,
        0.0003035700647160411,
        -0.07054376602172852,
        0.030353281646966934,
        0.018284665420651436,
        0.043232571333646774,
        0.034468602389097214,
        -0.07080117613077164,
        0.031402941793203354,
        0.042771223932504654,
        -0.0014675838174298406,
        0.00942306313663721,
        0.039493802934885025,
        0.04906763881444931,
        0.04479323700070381,
        0.039211686700582504,
        -0.02903723530471325,
        0.060678549110889435,
        0.04447292163968086,
        -0.044451307505369186,
        -0.004262634553015232,
        -0.018712259829044342,
        0.03755791112780571,
        0.008766109123826027,
        -0.05473381280899048,
        0.007424055598676205,
        -0.04366955906152725,
        0.06919720768928528,
        -0.031134244054555893,
        0.06552723050117493,
        0.05610077455639839,
        0.017635969445109367,
        -0.026483183726668358,
        -0.06925323605537415,
        -0.0670681819319725,
        -0.06864505261182785,
        0.045525141060352325,
        0.014055576175451279,
        -0.05336988717317581,
        0.005974451079964638,
        -0.010935375466942787,
        0.06956414878368378,
        -0.01484382338821888,
        -0.0503833070397377,
        -0.007817743346095085,
        0.06535472720861435,
        -0.053601473569869995,
        0.052723780274391174,
        0.06571691483259201,
        0.031282659620046616,
        0.05303845554590225,
        -0.05644679442048073,
        0.06214020401239395,
        0.0671534612774849,
        0.06279686093330383,
        0.04942607134580612,
        -0.05282004177570343,
        -0.011451479978859425,
        -0.050866808742284775,
        0.03043520636856556,
        -0.02611197717487812,
        -0.058650385588407516,
        -0.0001953570026671514,
        0.029058340936899185,
        -0.06900066137313843,
        -0.04455052316188812,
        0.06924128532409668,
        0.0383434072136879,
        0.03145540505647659,
        -0.06712441891431808,
        -0.0011908940505236387,
        0.009003912098705769,
        -0.03580746427178383,
        0.03282838687300682,
        -0.06673861294984818,
        -0.056892603635787964,
        -0.06075162813067436,
        -0.006877994630485773,
        0.053735580295324326,
        0.03242304548621178,
        0.02442970685660839,
        -0.03368120640516281,
        0.06226034089922905,
        0.0380769781768322,
        -0.04184513911604881,
        0.03195672854781151,
        -0.05408047139644623,
        0.021335061639547348,
        -0.06714852899312973,
        -0.05443466082215309,
        -0.01198647078126669,
        0.05006422847509384,
        0.06788445264101028,
        0.045015595853328705,
        0.05688827857375145,
        -0.059190038591623306,
        -0.04620799049735069,
        -0.046233583241701126,
        -0.05399315431714058,
        -0.05927849933505058,
        -0.0044372123666107655,
        -0.011781767942011356,
        -0.0686439573764801,
        0.06727393716573715,
        0.0700763463973999,
        0.04559231922030449,
        0.05929715186357498,
        0.04610209912061691,
        0.01864294521510601,
        0.04493604600429535,
        0.056751176714897156,
        -0.06741918623447418,
        0.005618121009320021,
        0.06352195143699646,
        0.03248513489961624,
        -0.06892004609107971,
        0.040273167192935944,
        -0.026553859934210777,
        0.03534441813826561,
        -0.023981142789125443,
        0.05955975875258446,
        0.009851280599832535,
        0.025511804968118668,
        -0.008492599241435528,
        0.05809018015861511,
        0.029903706163167953,
        0.04185172915458679,
        -0.010586714372038841,
        0.04192604497075081,
        0.00917620025575161,
        -0.03258860483765602,
        -0.058295492082834244,
        -0.002364306477829814,
        0.02335868962109089,
        0.043910715728998184,
        0.04277057945728302,
        -0.0175773948431015,
        0.004569935612380505,
        0.011367922648787498,
        0.06453799456357956,
        -0.06839366257190704,
        0.042411815375089645,
        0.055525198578834534,
        -0.0102671654894948,
        0.03749281167984009,
        0.0403248593211174,
        0.03828493133187294,
        0.0408647283911705,
        0.016937436535954475,
        -0.05009176582098007,
        -0.05930127203464508,
        0.061999816447496414,
        -0.061482518911361694,
        0.009633771143853664,
        0.0484023243188858,
        0.006174590904265642,
        -0.030578145757317543,
        0.013650390319526196,
        -0.04271065443754196,
        -0.009555311873555183,
        0.012765142135322094,
        -0.03265645354986191,
        -0.026355372741818428,
        0.03971582278609276,
        0.006622132379561663,
        0.04000462219119072,
        0.026303723454475403,
        -0.05135129764676094,
        -0.06763467937707901,
        0.030687076970934868,
        0.041496943682432175,
        -0.031413864344358444,
        -0.03341617062687874,
        0.04527315869927406,
        -0.054024115204811096,
        0.05283761024475098,
        0.0571279413998127,
        0.018033750355243683,
        0.06100980564951897,
        0.027116676792502403,
        -0.01289474219083786,
        0.049205534160137177,
        0.06358837336301804,
        -0.045885615050792694,
        -0.04933254420757294,
        0.03635202720761299,
        -0.06472308933734894,
        -0.06980060786008835,
        0.01031726598739624,
        -0.059227872639894485,
        -0.02916022762656212,
        0.06329527497291565,
        -0.02953260764479637,
        -0.01708790846168995,
        0.016792209818959236,
        -0.025702761486172676,
        -0.057618506252765656,
        0.03821184113621712,
        0.04248490184545517,
        0.057317450642585754,
        0.06279326230287552,
        -0.03893432766199112,
        -0.04401060938835144,
        0.06795735657215118,
        0.030281903222203255,
        0.06247697398066521,
        -0.06805019080638885,
        0.05742518603801727,
        0.005483183078467846,
        -0.010043763555586338,
        -0.051346875727176666,
        -0.06627737730741501,
        0.01379587221890688,
        -0.03130641207098961,
        0.00906166061758995,
        -0.06770395487546921,
        -0.0614483542740345,
        -0.060258056968450546,
        -0.020288802683353424,
        0.06860513240098953,
        -0.06804158538579941,
        -0.06389559060335159,
        0.05481508746743202,
        0.031815219670534134,
        0.06940596550703049,
        -0.007247155532240868,
        -0.0023106324952095747,
        0.03104041889309883,
        -0.025951167568564415,
        0.008796739391982555,
        -0.035864684730768204,
        0.06256429105997086,
        -0.0689440369606018,
        0.00159744406118989,
        0.059459250420331955,
        -0.04143800586462021,
        0.009168020449578762,
        -0.04666604846715927,
        -0.050212837755680084,
        0.059725116938352585,
        -0.05885278433561325,
        0.060828711837530136,
        -0.04488372057676315,
        -0.05095276981592178,
        -0.021301375702023506,
        -0.04500190541148186,
        -0.03845064714550972,
        0.05979863926768303,
        -0.035265352576971054,
        -0.05551868677139282,
        0.03961079940199852,
        0.018804587423801422,
        0.038581591099500656,
        0.02153395675122738,
        -0.02068071998655796,
        -0.019963227212429047,
        0.03655753657221794,
        0.0632624477148056,
        0.062359653413295746,
        -0.05138895660638809,
        0.06092846021056175,
        0.03120158612728119,
        -0.03992944955825806,
        0.033424753695726395,
        0.034947849810123444,
        0.05747545138001442,
        -0.028339961543679237,
        0.04413020238280296,
        -0.0012021242873743176,
        -0.023523392155766487,
        -0.06825697422027588,
        -0.020132528617978096,
        0.053430136293172836,
        -0.06021560728549957,
        -0.049409907311201096,
        0.013828307390213013,
        -0.021513182669878006,
        -0.03762931004166603,
        -0.05971882864832878,
        -0.038264378905296326,
        0.05296788364648819,
        -0.06798815727233887,
        0.018428850919008255,
        -0.05691574513912201,
        -0.06275364011526108,
        -0.009470529854297638,
        0.059851568192243576,
        -0.012906057760119438,
        0.012344923801720142,
        0.017358936369419098,
        -0.013853137381374836,
        -0.006994587369263172,
        0.00588162150233984,
        0.008529560640454292,
        -0.04865419864654541,
        -0.010166188701987267,
        -0.037098903208971024,
        -0.06737690418958664,
        0.06460744142532349,
        -0.013932849280536175,
        0.0021284501999616623,
        0.06739747524261475,
        -0.042520880699157715,
        0.06113842874765396,
        0.037922684103250504,
        0.06788962334394455,
        -0.06549535691738129,
        -0.017571909353137016,
        0.06679854542016983,
        0.03434576839208603,
        -0.007902093231678009,
        0.04569139704108238,
        0.010339335538446903,
        0.06463782489299774,
        -0.035630062222480774,
        -0.001990181626752019,
        -0.0532185323536396,
        -0.03199746832251549,
        0.005317730363458395,
        0.06433438509702682,
        -0.04923182725906372,
        -0.00784099567681551,
        0.04538162052631378,
        -0.06038271263241768,
        0.0563114732503891,
        0.02229996770620346,
        -0.032017383724451065,
        0.01602553203701973,
        0.055807095021009445,
        -0.027090279385447502,
        0.06490325182676315,
        -0.037981510162353516,
        0.03471142798662186,
        -0.06895975023508072,
        -0.039995890110731125,
        -0.03700094297528267,
        0.002818918088451028,
        0.06505952030420303,
        0.030074506998062134,
        0.05532212555408478,
        0.032387882471084595,
        0.053743068128824234,
        -0.030445149168372154,
        -0.019303426146507263,
        -0.022288544103503227,
        0.036750223487615585,
        -0.06929293274879456,
        0.05237360671162605,
        0.04283576086163521,
        0.04619503766298294,
        0.03266950324177742,
        -0.06775016337633133,
        -0.05604899674654007,
        -0.004871181212365627,
        -0.06429927796125412,
        -0.01056255865842104,
        0.028584932908415794,
        0.06376408785581589,
        -0.04638291895389557,
        0.06578760594129562,
        0.06871525943279266,
        0.029000699520111084,
        0.04903094097971916,
        -0.06448784470558167,
        -0.03340901806950569,
        0.05453990772366524,
        0.06573885679244995,
        0.003597424365580082,
        -0.041032206267118454,
        0.04970181733369827,
        0.030413838103413582,
        -0.041630882769823074,
        0.06574557721614838,
        -0.038237422704696655,
        -0.012132733128964901,
        -0.061994828283786774,
        0.060276903212070465,
        -0.04523193836212158,
        0.05757325515151024,
        -0.02998994290828705,
        -0.06301990896463394,
        -0.06785809248685837,
        0.047309961169958115,
        -0.048277709633111954,
        -0.06688935309648514,
        -0.013784409500658512,
        -0.0349205918610096,
        -0.0422695018351078,
        0.014288472011685371,
        0.04018359258770943,
        -0.02288559451699257,
        -0.04963064193725586,
        -0.03892497345805168,
        -0.044298309832811356,
        0.06599965691566467,
        0.025954723358154297,
        -0.05827026441693306,
        -0.04801809787750244,
        0.056135352700948715,
        -0.03537468612194061,
        -0.030242914333939552,
        0.009359653107821941,
        -0.009397437795996666,
        -0.05040007829666138,
        0.03781742602586746,
        0.018354732543230057,
        0.021728144958615303,
        0.030139345675706863,
        0.07036615908145905,
        0.04518958553671837,
        0.06560636311769485,
        0.0015484512550756335,
        0.0615689754486084,
        -0.0006570937111973763,
        -0.04908975213766098,
        -0.007438949774950743,
        0.01975397951900959,
        0.004035726189613342,
        0.04249689355492592,
        -0.05152362212538719,
        0.039357516914606094,
        0.029706889763474464,
        0.0073379273526370525,
        0.04310093820095062,
        -0.008165287785232067,
        -0.025986403226852417,
        -0.05990833789110184,
        0.005363063421100378,
        0.008771531283855438,
        0.0665389820933342,
        -0.060402169823646545,
        0.059193309396505356,
        -0.05567434802651405,
        -0.06613456457853317,
        0.03245559334754944,
        -0.001767985988408327,
        -0.008075324818491936,
        -0.019390296190977097,
        -0.06452112644910812,
        -0.03749886900186539,
        0.05529225245118141,
        0.046918485313653946,
        -0.014484412968158722,
        -0.041281383484601974,
        0.05938645452260971,
        0.028239743784070015,
        -0.02880057878792286,
        0.027013618499040604,
        0.04458896815776825,
        0.003573500784114003,
        0.026487549766898155,
        0.03498933091759682,
        -0.052427295595407486,
        -0.007938790135085583,
        -0.05637642741203308,
        -0.06141841411590576,
        0.04313097521662712,
        -0.02467028796672821,
        0.06029082089662552,
        0.026195289567112923,
        -0.03676167502999306,
        0.0260501429438591,
        0.049827247858047485,
        0.06821547448635101,
        0.0049963598139584064,
        -0.03782237321138382,
        0.011799798347055912,
        -0.005739959422498941,
        -0.056944556534290314,
        -0.01159483753144741,
        0.0015717478236183524,
        0.02021188661456108,
        -0.059962909668684006,
        -0.06620390713214874,
        0.008701363578438759,
        0.06780416518449783
    ],
    [
        -0.06291981041431427,
        0.009054040536284447,
        -0.009515654295682907,
        0.03611719608306885,
        0.03408399969339371,
        -0.007269382942467928,
        0.03372368589043617,
        -0.017603052780032158,
        0.053636640310287476,
        0.041289277374744415,
        -0.04483592510223389,
        -0.019004730507731438,
        -0.039686962962150574,
        -0.006315958220511675,
        -0.06093872711062431,
        0.06673812121152878,
        -0.06091431900858879,
        0.031862761825323105,
        -0.05750681459903717,
        0.04585063457489014,
        -0.06549926102161407,
        0.02098517119884491,
        -0.0016891970299184322,
        -0.05301966518163681,
        0.04891138896346092,
        -0.016105206683278084,
        0.06596973538398743,
        0.012498855590820312,
        0.02302258461713791,
        -0.03871023282408714,
        0.019314534962177277,
        -0.007475086022168398,
        0.00265309726819396,
        -0.06294456124305725,
        0.014226984232664108,
        0.042237624526023865,
        -0.05502451956272125,
        -0.006427441723644733,
        -0.0677424818277359,
        0.028110496699810028,
        0.028918815776705742,
        0.04541826993227005,
        0.018535057082772255,
        -0.06775061041116714,
        0.031631045043468475,
        0.0457608588039875,
        0.003255340503528714,
        0.01300930604338646,
        0.05826010927557945,
        0.045912474393844604,
        0.05062590539455414,
        0.0372515544295311,
        -0.027000250294804573,
        0.06056981533765793,
        0.048566561192274094,
        -0.048398129642009735,
        -0.023998264223337173,
        -0.018486574292182922,
        0.04895060509443283,
        0.011712493374943733,
        -0.05078240856528282,
        0.004330997355282307,
        -0.03942910581827164,
        0.06734491884708405,
        -0.022457599639892578,
        0.06434878706932068,
        0.055413465946912766,
        0.006489328108727932,
        -0.03136803209781647,
        -0.06707319617271423,
        -0.06624380499124527,
        -0.06711570173501968,
        0.03662220388650894,
        -0.004159633070230484,
        -0.04440603777766228,
        0.021284867078065872,
        0.006008574739098549,
        0.06518316268920898,
        -0.0017534649232402444,
        -0.05990740284323692,
        -0.004143028054386377,
        0.06410181522369385,
        -0.055428396910429,
        0.05758580192923546,
        0.06563402712345123,
        0.038378942757844925,
        0.0547170490026474,
        -0.05005604028701782,
        0.06313487142324448,
        0.06493379920721054,
        0.038858745247125626,
        0.04489947110414505,
        -0.06021031364798546,
        -0.013307417742908001,
        -0.03873993083834648,
        0.030743561685085297,
        -0.02464326284825802,
        -0.05773765593767166,
        -0.0011117174290120602,
        0.03109780140221119,
        -0.06523396074771881,
        -0.025303326547145844,
        0.06670244038105011,
        0.04430295526981354,
        0.027420029044151306,
        -0.06620481610298157,
        0.023122694343328476,
        0.015610759146511555,
        -0.043722640722990036,
        0.05029593035578728,
        -0.06507676839828491,
        -0.054939884692430496,
        -0.05555550381541252,
        0.017393002286553383,
        0.0441112145781517,
        0.03666513040661812,
        0.03780456632375717,
        -0.048690468072891235,
        0.058882419019937515,
        0.04836975038051605,
        -0.029809441417455673,
        0.04324617609381676,
        -0.052021730691194534,
        0.03584935888648033,
        -0.06261513382196426,
        -0.04886915907263756,
        -0.012421338818967342,
        0.035420529544353485,
        0.06487513333559036,
        0.05321633815765381,
        0.05934547260403633,
        -0.044131144881248474,
        -0.034360405057668686,
        -0.05097541585564613,
        -0.05405750125646591,
        -0.05259900540113449,
        -0.007979663088917732,
        0.010283994488418102,
        -0.06655308604240417,
        0.06555062532424927,
        0.06733247637748718,
        0.04744512960314751,
        0.051157146692276,
        0.03202876076102257,
        0.010834865272045135,
        0.03956841677427292,
        0.052111394703388214,
        -0.06527356803417206,
        0.005186747759580612,
        0.06272292882204056,
        0.04305914789438248,
        -0.06648741662502289,
        0.048104580491781235,
        -0.006386737339198589,
        0.03963097929954529,
        -0.027628052979707718,
        0.05844812095165253,
        0.01441606879234314,
        0.028752697631716728,
        0.010661747306585312,
        0.06192673370242119,
        -0.0013308948837220669,
        0.03292679414153099,
        -0.022330841049551964,
        0.028937287628650665,
        0.027188915759325027,
        -0.029096651822328568,
        -0.048799749463796616,
        -0.014435429126024246,
        0.020150484517216682,
        0.028926480561494827,
        0.036863043904304504,
        -0.026329655200242996,
        -0.0018391442717984319,
        0.015140553005039692,
        0.06536607444286346,
        -0.06652191281318665,
        0.037115100771188736,
        0.04514363780617714,
        -0.0385933481156826,
        0.04232514649629593,
        0.05150420218706131,
        0.030821021646261215,
        0.05099796876311302,
        0.01642371527850628,
        -0.04670959338545799,
        -0.06165175139904022,
        0.06023680046200752,
        -0.06050879508256912,
        -0.011026808060705662,
        0.04776187613606453,
        0.02072504721581936,
        -0.04424504190683365,
        0.02800598181784153,
        -0.04689948633313179,
        -0.030742717906832695,
        0.004931676667183638,
        -0.04191932827234268,
        -0.04078003391623497,
        0.043351102620363235,
        0.006691111251711845,
        0.03194021061062813,
        0.01632949523627758,
        -0.04873456805944443,
        -0.06431125849485397,
        0.04410649463534355,
        0.034494899213314056,
        -0.03519632667303085,
        -0.03728995844721794,
        0.03821207955479622,
        -0.04897107556462288,
        0.048037171363830566,
        0.05284372717142105,
        -0.00045691116247326136,
        0.06006607040762901,
        0.027437012642621994,
        -0.0035417932085692883,
        0.03684123605489731,
        0.06382935494184494,
        -0.05134906992316246,
        -0.05850159376859665,
        0.032473497092723846,
        -0.06474310159683228,
        -0.0666976198554039,
        0.029799846932291985,
        -0.06075264513492584,
        -0.03116234391927719,
        0.05933975800871849,
        -0.024281157180666924,
        -0.01898176781833172,
        0.03345131129026413,
        -0.04496905207633972,
        -0.05149426311254501,
        0.04294685274362564,
        0.028715291991829872,
        0.051420580595731735,
        0.06261997669935226,
        -0.04255964234471321,
        -0.0447351410984993,
        0.0636691153049469,
        0.02798149734735489,
        0.06283800303936005,
        -0.06695646047592163,
        0.06043774634599686,
        0.012896043248474598,
        -0.0022810332011431456,
        -0.0329197533428669,
        -0.06033386290073395,
        0.013169153593480587,
        -0.03421076387166977,
        0.008574819192290306,
        -0.06578726321458817,
        -0.06298867613077164,
        -0.05816403031349182,
        -0.016195883974432945,
        0.06603460013866425,
        -0.06625551730394363,
        -0.06260354816913605,
        0.05230608582496643,
        0.038741495460271835,
        0.0668470561504364,
        0.0061049978248775005,
        0.012110433541238308,
        0.041571926325559616,
        -0.025271400809288025,
        0.005107399541884661,
        -0.0256514772772789,
        0.062259405851364136,
        -0.0664493665099144,
        -0.0009509928058832884,
        0.05530669540166855,
        -0.057195648550987244,
        0.018862122669816017,
        -0.05116034671664238,
        -0.046199601143598557,
        0.05130593106150627,
        -0.057787615805864334,
        0.06102985888719559,
        -0.05161391943693161,
        -0.052783090621232986,
        -0.04718015342950821,
        -0.03600215166807175,
        -0.047672953456640244,
        0.05718632787466049,
        -0.006143602076917887,
        -0.05628877505660057,
        0.043547436594963074,
        0.006697957403957844,
        0.049257006496191025,
        0.0164569653570652,
        -0.03829774633049965,
        -0.027011627331376076,
        0.029415443539619446,
        0.05647435411810875,
        0.06190573424100876,
        -0.04761480167508125,
        0.05449604615569115,
        0.04883680120110512,
        -0.03336447477340698,
        0.033655453473329544,
        0.03377941623330116,
        0.05694003775715828,
        -0.015034528449177742,
        0.046225301921367645,
        0.00945969671010971,
        -0.02889334037899971,
        -0.06553878635168076,
        -0.014600291848182678,
        0.05285393446683884,
        -0.05529345944523811,
        -0.05235039442777634,
        -0.005606641992926598,
        -0.02711261436343193,
        -0.0386519581079483,
        -0.058050211519002914,
        -0.04473509639501572,
        0.055777657777071,
        -0.06621354818344116,
        0.019146593287587166,
        -0.06204710528254509,
        -0.05664237216114998,
        -0.011922416277229786,
        0.059150032699108124,
        -0.005651578307151794,
        0.004702274687588215,
        0.024092519655823708,
        -0.008437268435955048,
        0.01948150247335434,
        0.0118956184014678,
        0.016588883474469185,
        -0.04842400923371315,
        0.012406308203935623,
        -0.03465673327445984,
        -0.06411962956190109,
        0.06223975867033005,
        0.005324347410351038,
        -0.012884680181741714,
        0.06279031187295914,
        -0.04041111841797829,
        0.06062914803624153,
        0.036004431545734406,
        0.06480583548545837,
        -0.06168567016720772,
        -0.0289943628013134,
        0.065170057117939,
        0.027095086872577667,
        -0.0066399890929460526,
        0.042532697319984436,
        0.008825651369988918,
        0.05937901511788368,
        -0.031185869127511978,
        0.005893884692341089,
        -0.05759156122803688,
        -0.0551144964993,
        0.009884936735033989,
        0.06241809204220772,
        -0.04372936114668846,
        -0.025217415764927864,
        0.0524677075445652,
        -0.058465808629989624,
        0.05443943664431572,
        0.02842073328793049,
        -0.005936908535659313,
        0.03010287694633007,
        0.05171196907758713,
        -0.027732020244002342,
        0.06702319532632828,
        -0.042518239468336105,
        0.054718002676963806,
        -0.06692802160978317,
        -0.048489078879356384,
        -0.045308198779821396,
        0.02742953971028328,
        0.0616748109459877,
        0.04671241343021393,
        0.057102423161268234,
        0.029151462018489838,
        0.0586712621152401,
        -0.02870039828121662,
        -0.02074608951807022,
        -0.033861562609672546,
        0.03603809326887131,
        -0.06652531772851944,
        0.03800550475716591,
        0.03709078207612038,
        0.0458313450217247,
        0.04861694574356079,
        -0.06424017995595932,
        -0.050512783229351044,
        0.012672340497374535,
        -0.066368967294693,
        -0.0030041998252272606,
        0.03003312088549137,
        0.06150148808956146,
        -0.03960151970386505,
        0.0638728216290474,
        0.06596498191356659,
        0.012304136529564857,
        0.05494037643074989,
        -0.06469368934631348,
        -0.04641260579228401,
        0.05680588260293007,
        0.06553059071302414,
        0.00014911046309862286,
        -0.04597320780158043,
        0.043827567249536514,
        0.03642131760716438,
        -0.045662399381399155,
        0.0609370693564415,
        -0.049552932381629944,
        0.0004887421964667737,
        -0.05877048522233963,
        0.06022419407963753,
        -0.028221461921930313,
        0.056109488010406494,
        -0.03896225616335869,
        -0.06376475095748901,
        -0.06619366258382797,
        0.03528273105621338,
        -0.05574219673871994,
        -0.06093192100524902,
        0.011673768982291222,
        -0.03561675176024437,
        -0.053259216248989105,
        0.04815806820988655,
        0.02204584702849388,
        -0.036037199199199677,
        -0.03851809725165367,
        -0.04508277401328087,
        -0.045597270131111145,
        0.06573619693517685,
        0.026519808918237686,
        -0.0619976706802845,
        -0.05731376260519028,
        0.04937128722667694,
        -0.047102559357881546,
        0.0024291668087244034,
        -0.003336745547130704,
        0.00840806309133768,
        -0.0447600856423378,
        0.040858060121536255,
        0.02549566514790058,
        0.022593935951590538,
        0.043579086661338806,
        0.06764589250087738,
        0.04323256015777588,
        0.06294803321361542,
        -0.0073214140720665455,
        0.06062045693397522,
        0.011918839067220688,
        -0.05419906973838806,
        0.020844148471951485,
        0.043274763971567154,
        0.01460016705095768,
        0.043316397815942764,
        -0.047307856380939484,
        0.027995821088552475,
        0.03286496549844742,
        0.006374186370521784,
        0.056853849440813065,
        -0.016549788415431976,
        -0.031056325882673264,
        -0.05983974039554596,
        0.017078636214137077,
        0.04557276517152786,
        0.06541339308023453,
        -0.059217195957899094,
        0.06008588895201683,
        -0.058714549988508224,
        -0.06578677892684937,
        0.03462502360343933,
        0.020330583676695824,
        -0.0007097823545336723,
        -0.01143123209476471,
        -0.06618515402078629,
        -0.01887631043791771,
        0.04913876950740814,
        0.051455799490213394,
        -0.018861105665564537,
        -0.037930961698293686,
        0.05811101198196411,
        0.02447219006717205,
        -0.036919232457876205,
        0.032042376697063446,
        0.040221355855464935,
        0.011129625141620636,
        0.032877225428819656,
        0.024900143966078758,
        -0.04233923554420471,
        -0.030908707529306412,
        -0.05280227214097977,
        -0.06314275413751602,
        0.04805673286318779,
        -0.030772624537348747,
        0.06316766887903214,
        0.027840005233883858,
        -0.04608992859721184,
        0.04780237004160881,
        0.047545015811920166,
        0.06649959087371826,
        0.01568276807665825,
        -0.025907550007104874,
        0.01530552189797163,
        -0.013365859165787697,
        -0.054719798266887665,
        -0.010406937450170517,
        0.017198054119944572,
        -0.0016173406038433313,
        -0.050630442798137665,
        -0.06444841623306274,
        0.012308532372117043,
        0.06484340131282806
    ],
    [
        -0.058760929852724075,
        -0.0011259490856900811,
        -0.018750185146927834,
        0.026778768748044968,
        0.0320606492459774,
        -0.019876766949892044,
        0.05947132408618927,
        0.05073605105280876,
        -0.010480557568371296,
        0.013247273862361908,
        -0.04932749271392822,
        -0.0029154489748179913,
        -0.049889929592609406,
        0.019596749916672707,
        -0.05786211043596268,
        0.05854988098144531,
        -0.05944870412349701,
        0.04607093706727028,
        -0.04926227033138275,
        0.05917077139019966,
        -0.060200680047273636,
        -0.03726861625909805,
        0.0064065284095704556,
        -0.028613463044166565,
        0.03574554994702339,
        -0.017517756670713425,
        0.05383862555027008,
        0.05937556177377701,
        -0.03481508046388626,
        -0.05944236367940903,
        0.0037260339595377445,
        -0.022883379831910133,
        0.05570884048938751,
        -0.05139896273612976,
        0.021076053380966187,
        -0.017411721870303154,
        -0.05312558636069298,
        0.022199057042598724,
        -0.06023675203323364,
        0.05294140428304672,
        0.008433218114078045,
        0.04340428113937378,
        0.055981289595365524,
        -0.06027091294527054,
        0.041016556322574615,
        -0.035792600363492966,
        -0.02020382694900036,
        0.055747516453266144,
        0.05163971334695816,
        0.006702107843011618,
        0.05835232138633728,
        -0.04085082560777664,
        0.03789744898676872,
        0.02704053558409214,
        0.0467696487903595,
        -0.031600821763277054,
        -0.038606446236371994,
        -0.017100321128964424,
        -0.03174885734915733,
        -0.0029226262122392654,
        -0.052209749817848206,
        -0.020290223881602287,
        -0.05192871391773224,
        0.05586423724889755,
        0.046666309237480164,
        0.0601777583360672,
        0.056420985609292984,
        -0.018972188234329224,
        0.05724421888589859,
        -0.06026364490389824,
        -0.05706160515546799,
        -0.0551222525537014,
        0.024605942890048027,
        0.027248479425907135,
        -0.05918499827384949,
        -0.0007259920239448547,
        -0.0021803523413836956,
        0.05933661758899689,
        0.04225768521428108,
        -0.05761881172657013,
        -0.030690805986523628,
        0.02273542992770672,
        -0.05633620545268059,
        0.05613131821155548,
        0.05441443994641304,
        0.019567565992474556,
        -0.04052094742655754,
        -0.05087769031524658,
        0.0472540482878685,
        0.06005189195275307,
        0.041780199855566025,
        0.028686048462986946,
        0.03328249230980873,
        -0.03586023673415184,
        -0.03321899473667145,
        -0.03940257057547569,
        -0.0377640426158905,
        -0.05215119570493698,
        -0.04501890018582344,
        0.044202156364917755,
        -0.05965583398938179,
        -0.05886707082390785,
        0.060045577585697174,
        0.052524153143167496,
        -0.013779859058558941,
        -0.05875599384307861,
        -0.014003043062984943,
        -0.028297146782279015,
        -0.048576921224594116,
        -0.0361812561750412,
        -0.054657481610774994,
        -0.0592443123459816,
        -0.04912630096077919,
        -0.030300507321953773,
        0.05956195667386055,
        0.04214296117424965,
        -0.021602928638458252,
        -0.037514083087444305,
        -0.04080595448613167,
        -0.03599715605378151,
        -0.04687492549419403,
        -0.055202145129442215,
        -0.058568548411130905,
        0.03931402787566185,
        -0.05988981947302818,
        -0.021940642967820168,
        0.0010595908388495445,
        0.05986403673887253,
        0.058861784636974335,
        0.05150018632411957,
        0.058658353984355927,
        -0.05284468084573746,
        -0.03907932713627815,
        -0.01123057585209608,
        -0.04748785123229027,
        -0.051147595047950745,
        0.05004313588142395,
        -0.05359495431184769,
        -0.048744432628154755,
        0.05964751914143562,
        0.058918196707963943,
        0.04924828186631203,
        0.05324641987681389,
        0.05849665775895119,
        -0.054759904742240906,
        0.03902339190244675,
        0.04696375131607056,
        -0.05784782022237778,
        0.027040649205446243,
        0.046523693948984146,
        -0.04008810594677925,
        -0.05983853340148926,
        0.017324740067124367,
        0.042566634714603424,
        0.04048718884587288,
        -0.015785513445734978,
        0.05910352244973183,
        -0.0036587694194167852,
        0.041824325919151306,
        -0.05334055423736572,
        0.05102362856268883,
        -0.012040184810757637,
        0.042754605412483215,
        -0.053787846118211746,
        -0.021495139226317406,
        0.005681938491761684,
        -0.006173991598188877,
        -0.05994841456413269,
        -0.03889000788331032,
        0.038601938635110855,
        0.042739201337099075,
        0.02948213741183281,
        -0.055179547518491745,
        0.059141069650650024,
        -0.040236786007881165,
        0.03808249905705452,
        -0.057874344289302826,
        0.04496614634990692,
        0.05746171623468399,
        0.05142775923013687,
        0.04718393459916115,
        -0.03889624401926994,
        0.05810021236538887,
        -0.022322529926896095,
        0.003118665423244238,
        -0.058022111654281616,
        -0.011402311734855175,
        0.055678583681583405,
        -0.04503779113292694,
        0.012837331742048264,
        0.055334556847810745,
        -0.0034128392580896616,
        -0.04655957594513893,
        0.0449264720082283,
        -0.05422752723097801,
        -0.03505884110927582,
        0.05746680125594139,
        -0.019360940903425217,
        0.008921133354306221,
        0.053848862648010254,
        0.01733437553048134,
        0.05723646283149719,
        0.054075732827186584,
        -0.05383529141545296,
        -0.054497089236974716,
        -0.002510849619284272,
        0.05612581595778465,
        -0.040579330176115036,
        -0.008873164653778076,
        0.05532835051417351,
        -0.05296469107270241,
        0.013945464976131916,
        0.05791196972131729,
        -0.012442744337022305,
        0.0596509613096714,
        -0.014419050887227058,
        -0.051005683839321136,
        0.05542336776852608,
        0.05919618904590607,
        -0.05450698360800743,
        -0.05004524812102318,
        -0.0461268313229084,
        -0.05496632307767868,
        -0.06026191636919975,
        0.006587603595107794,
        -0.059042464941740036,
        -0.04654264822602272,
        0.056790195405483246,
        -0.04605013132095337,
        -0.04415786638855934,
        -0.022566471248865128,
        -0.002539364155381918,
        -0.05930258706212044,
        0.038937751203775406,
        0.054332051426172256,
        -0.03089923784136772,
        0.05726586654782295,
        -0.009036469273269176,
        -0.022168893367052078,
        0.05865250527858734,
        0.05213160067796707,
        0.036924488842487335,
        -0.027653930708765984,
        0.04853092133998871,
        0.004935222212225199,
        -0.0018276403425261378,
        0.04930538684129715,
        -0.059926968067884445,
        -0.008831077255308628,
        -0.02705102041363716,
        0.04123620688915253,
        -0.05817815661430359,
        -0.05915934965014458,
        -0.056795645505189896,
        0.045751579105854034,
        0.0593193955719471,
        -0.05746860429644585,
        -0.059346526861190796,
        0.05879563093185425,
        0.05575616657733917,
        0.05536118894815445,
        0.051278285682201385,
        0.027990683913230896,
        0.007135305553674698,
        -0.04982651397585869,
        0.04760816693305969,
        -0.03411947563290596,
        0.054842911660671234,
        -0.06001714617013931,
        -0.013505488634109497,
        0.055775225162506104,
        -0.03457753732800484,
        -0.05773363262414932,
        -0.04958462342619896,
        -0.017763011157512665,
        0.05936911329627037,
        -0.05814222991466522,
        0.054640814661979675,
        -0.05067591369152069,
        -0.04401154816150665,
        -0.03356499597430229,
        0.018976755440235138,
        0.03207448124885559,
        0.043245721608400345,
        -0.047645844519138336,
        -0.05626140534877777,
        0.03303137794137001,
        -0.02785586751997471,
        -0.008414257317781448,
        0.0025733986403793097,
        -0.05673820525407791,
        -0.04985610768198967,
        0.047325268387794495,
        0.057058874517679214,
        0.05367787554860115,
        -0.041312169283628464,
        0.059694066643714905,
        -0.012881826609373093,
        -0.03744930401444435,
        0.027115456759929657,
        -0.011274519376456738,
        0.04717400670051575,
        -0.044245991855859756,
        0.02948385290801525,
        0.04741286113858223,
        -0.055322255939245224,
        -0.05900021642446518,
        -0.005581117235124111,
        0.058620747178792953,
        -0.04858304560184479,
        -0.05663241446018219,
        0.04472452029585838,
        0.0047699883580207825,
        -0.021685391664505005,
        -0.059860922396183014,
        -0.040588464587926865,
        0.053022854030132294,
        -0.05957654118537903,
        0.019772792235016823,
        -0.05364074185490608,
        -0.056431256234645844,
        0.017520278692245483,
        0.05980324745178223,
        -0.04307902231812477,
        -0.024473601952195168,
        0.058158598840236664,
        0.03725098818540573,
        -0.05713871493935585,
        -0.0004832231788896024,
        -0.03285669535398483,
        -0.05551653727889061,
        -0.035200219601392746,
        0.005853333044797182,
        -0.05759912729263306,
        0.05825819820165634,
        0.0030421356204897165,
        -0.011669065803289413,
        0.05916305258870125,
        -0.04858638718724251,
        0.05823754146695137,
        0.03626980632543564,
        0.054143574088811874,
        -0.02119371108710766,
        -0.02996481955051422,
        0.060187697410583496,
        0.045418042689561844,
        0.008253002539277077,
        0.052041471004486084,
        0.027333490550518036,
        0.05541345104575157,
        -0.056131213903427124,
        -0.01787523366510868,
        -0.0512494295835495,
        -0.057821620255708694,
        -0.01849840022623539,
        0.05850198119878769,
        -0.05373668298125267,
        -0.023230545222759247,
        -0.010168555192649364,
        -0.04768940806388855,
        0.05405765399336815,
        0.04831573739647865,
        -0.05087120458483696,
        -0.04145139828324318,
        0.04673171788454056,
        -0.03051796182990074,
        0.05978109687566757,
        -0.0520259328186512,
        0.046513717621564865,
        -0.0601898729801178,
        -0.04006505012512207,
        -0.05437912791967392,
        0.012565171346068382,
        0.05368401110172272,
        0.05503024533390999,
        0.05910532921552658,
        0.05322249233722687,
        0.04593927040696144,
        -0.042347900569438934,
        -0.055791135877370834,
        -0.05285293236374855,
        0.06015386804938316,
        -0.05822669342160225,
        -0.010896096006035805,
        0.03585689887404442,
        0.019863609224557877,
        0.03773670271039009,
        -0.058130331337451935,
        -0.0595242902636528,
        -0.011316788382828236,
        -0.028604652732610703,
        0.028787391260266304,
        0.008189884945750237,
        0.059635940939188004,
        -0.019299780949950218,
        0.05874668061733246,
        0.060200177133083344,
        -0.02841755747795105,
        0.006873008795082569,
        -0.05681338906288147,
        -0.021632926538586617,
        0.04406321048736572,
        0.05830400437116623,
        0.05975309759378433,
        -0.03966432809829712,
        0.053926046937704086,
        0.04485250636935234,
        -0.052607715129852295,
        0.059816084802150726,
        -0.03800069913268089,
        0.02637067437171936,
        -0.02658216655254364,
        0.05168358236551285,
        -0.0471872054040432,
        0.05661889538168907,
        0.028108961880207062,
        -0.059053052216768265,
        -0.06025675684213638,
        0.05116930976510048,
        -0.052652757614851,
        -0.06020727381110191,
        -0.042922623455524445,
        -0.052966583520174026,
        -0.05412866920232773,
        -0.024169260635972023,
        0.05095745623111725,
        -0.029222356155514717,
        -0.05888819694519043,
        -0.059074923396110535,
        0.006854218430817127,
        0.059564054012298584,
        0.04092567414045334,
        0.03426876664161682,
        -0.04108097031712532,
        0.039978690445423126,
        -0.007759321480989456,
        -0.04490579292178154,
        0.025573085993528366,
        -0.02647586725652218,
        -0.058534447103738785,
        -0.0459100566804409,
        0.0006595385493710637,
        -0.02633676305413246,
        0.022488471120595932,
        0.05967847257852554,
        0.04623905196785927,
        0.059398408979177475,
        -0.024944446980953217,
        0.05247553810477257,
        -0.052945639938116074,
        -0.03636594116687775,
        -0.006041232030838728,
        0.054169777780771255,
        -0.01966705173254013,
        0.05451209098100662,
        -0.031050410121679306,
        0.05564077943563461,
        0.03168880194425583,
        0.04479309171438217,
        0.027966361492872238,
        0.027621235698461533,
        -0.05675926059484482,
        -0.02162078022956848,
        -0.0444776825606823,
        -0.023447588086128235,
        0.058192405849695206,
        -0.0597430020570755,
        0.058547668159008026,
        -0.048838987946510315,
        -0.05890114977955818,
        0.037538859993219376,
        -0.04966672509908676,
        0.021875549107789993,
        0.027130456641316414,
        0.0076064057648181915,
        -0.004960506223142147,
        0.040708210319280624,
        0.03109518624842167,
        -0.03059123083949089,
        -0.007257361896336079,
        0.05901029333472252,
        0.0549626424908638,
        -0.010211368091404438,
        0.022550422698259354,
        0.04976661130785942,
        0.011465034447610378,
        -0.0025546180550009012,
        0.012477342039346695,
        -0.0568985678255558,
        0.003402077592909336,
        -0.0282958522439003,
        -0.060113295912742615,
        0.05499410629272461,
        -0.016467323526740074,
        0.05842571705579758,
        0.0248833317309618,
        -0.058366917073726654,
        -0.028648855164647102,
        0.051597148180007935,
        0.04574130102992058,
        0.054577309638261795,
        -0.008729731664061546,
        -0.02793791890144348,
        -0.0037179519422352314,
        -0.05709577351808548,
        0.03029591590166092,
        -0.04114361107349396,
        -0.0022912765853106976,
        -0.04760971665382385,
        -0.04196253791451454,
        0.02146824635565281,
        0.05529617890715599
    ],
    [
        -0.058760929852724075,
        -0.0011259490856900811,
        -0.018750185146927834,
        0.026778768748044968,
        0.0320606492459774,
        -0.019876766949892044,
        0.05947132408618927,
        0.05073605105280876,
        -0.010480557568371296,
        0.013247273862361908,
        -0.04932749271392822,
        -0.0029154489748179913,
        -0.049889929592609406,
        0.019596749916672707,
        -0.05786211043596268,
        0.05854988098144531,
        -0.05944870412349701,
        0.04607093706727028,
        -0.04926227033138275,
        0.05917077139019966,
        -0.060200680047273636,
        -0.03726861625909805,
        0.0064065284095704556,
        -0.028613463044166565,
        0.03574554994702339,
        -0.017517756670713425,
        0.05383862555027008,
        0.05937556177377701,
        -0.03481508046388626,
        -0.05944236367940903,
        0.0037260339595377445,
        -0.022883379831910133,
        0.05570884048938751,
        -0.05139896273612976,
        0.021076053380966187,
        -0.017411721870303154,
        -0.05312558636069298,
        0.022199057042598724,
        -0.06023675203323364,
        0.05294140428304672,
        0.008433218114078045,
        0.04340428113937378,
        0.055981289595365524,
        -0.06027091294527054,
        0.041016556322574615,
        -0.035792600363492966,
        -0.02020382694900036,
        0.055747516453266144,
        0.05163971334695816,
        0.006702107843011618,
        0.05835232138633728,
        -0.04085082560777664,
        0.03789744898676872,
        0.02704053558409214,
        0.0467696487903595,
        -0.031600821763277054,
        -0.038606446236371994,
        -0.017100321128964424,
        -0.03174885734915733,
        -0.0029226262122392654,
        -0.052209749817848206,
        -0.020290223881602287,
        -0.05192871391773224,
        0.05586423724889755,
        0.046666309237480164,
        0.0601777583360672,
        0.056420985609292984,
        -0.018972188234329224,
        0.05724421888589859,
        -0.06026364490389824,
        -0.05706160515546799,
        -0.0551222525537014,
        0.024605942890048027,
        0.027248479425907135,
        -0.05918499827384949,
        -0.0007259920239448547,
        -0.0021803523413836956,
        0.05933661758899689,
        0.04225768521428108,
        -0.05761881172657013,
        -0.030690805986523628,
        0.02273542992770672,
        -0.05633620545268059,
        0.05613131821155548,
        0.05441443994641304,
        0.019567565992474556,
        -0.04052094742655754,
        -0.05087769031524658,
        0.0472540482878685,
        0.06005189195275307,
        0.041780199855566025,
        0.028686048462986946,
        0.03328249230980873,
        -0.03586023673415184,
        -0.03321899473667145,
        -0.03940257057547569,
        -0.0377640426158905,
        -0.05215119570493698,
        -0.04501890018582344,
        0.044202156364917755,
        -0.05965583398938179,
        -0.05886707082390785,
        0.060045577585697174,
        0.052524153143167496,
        -0.013779859058558941,
        -0.05875599384307861,
        -0.014003043062984943,
        -0.028297146782279015,
        -0.048576921224594116,
        -0.0361812561750412,
        -0.054657481610774994,
        -0.0592443123459816,
        -0.04912630096077919,
        -0.030300507321953773,
        0.05956195667386055,
        0.04214296117424965,
        -0.021602928638458252,
        -0.037514083087444305,
        -0.04080595448613167,
        -0.03599715605378151,
        -0.04687492549419403,
        -0.055202145129442215,
        -0.058568548411130905,
        0.03931402787566185,
        -0.05988981947302818,
        -0.021940642967820168,
        0.0010595908388495445,
        0.05986403673887253,
        0.058861784636974335,
        0.05150018632411957,
        0.058658353984355927,
        -0.05284468084573746,
        -0.03907932713627815,
        -0.01123057585209608,
        -0.04748785123229027,
        -0.051147595047950745,
        0.05004313588142395,
        -0.05359495431184769,
        -0.048744432628154755,
        0.05964751914143562,
        0.058918196707963943,
        0.04924828186631203,
        0.05324641987681389,
        0.05849665775895119,
        -0.054759904742240906,
        0.03902339190244675,
        0.04696375131607056,
        -0.05784782022237778,
        0.027040649205446243,
        0.046523693948984146,
        -0.04008810594677925,
        -0.05983853340148926,
        0.017324740067124367,
        0.042566634714603424,
        0.04048718884587288,
        -0.015785513445734978,
        0.05910352244973183,
        -0.0036587694194167852,
        0.041824325919151306,
        -0.05334055423736572,
        0.05102362856268883,
        -0.012040184810757637,
        0.042754605412483215,
        -0.053787846118211746,
        -0.021495139226317406,
        0.005681938491761684,
        -0.006173991598188877,
        -0.05994841456413269,
        -0.03889000788331032,
        0.038601938635110855,
        0.042739201337099075,
        0.02948213741183281,
        -0.055179547518491745,
        0.059141069650650024,
        -0.040236786007881165,
        0.03808249905705452,
        -0.057874344289302826,
        0.04496614634990692,
        0.05746171623468399,
        0.05142775923013687,
        0.04718393459916115,
        -0.03889624401926994,
        0.05810021236538887,
        -0.022322529926896095,
        0.003118665423244238,
        -0.058022111654281616,
        -0.011402311734855175,
        0.055678583681583405,
        -0.04503779113292694,
        0.012837331742048264,
        0.055334556847810745,
        -0.0034128392580896616,
        -0.04655957594513893,
        0.0449264720082283,
        -0.05422752723097801,
        -0.03505884110927582,
        0.05746680125594139,
        -0.019360940903425217,
        0.008921133354306221,
        0.053848862648010254,
        0.01733437553048134,
        0.05723646283149719,
        0.054075732827186584,
        -0.05383529141545296,
        -0.054497089236974716,
        -0.002510849619284272,
        0.05612581595778465,
        -0.040579330176115036,
        -0.008873164653778076,
        0.05532835051417351,
        -0.05296469107270241,
        0.013945464976131916,
        0.05791196972131729,
        -0.012442744337022305,
        0.0596509613096714,
        -0.014419050887227058,
        -0.051005683839321136,
        0.05542336776852608,
        0.05919618904590607,
        -0.05450698360800743,
        -0.05004524812102318,
        -0.0461268313229084,
        -0.05496632307767868,
        -0.06026191636919975,
        0.006587603595107794,
        -0.059042464941740036,
        -0.04654264822602272,
        0.056790195405483246,
        -0.04605013132095337,
        -0.04415786638855934,
        -0.022566471248865128,
        -0.002539364155381918,
        -0.05930258706212044,
        0.038937751203775406,
        0.054332051426172256,
        -0.03089923784136772,
        0.05726586654782295,
        -0.009036469273269176,
        -0.022168893367052078,
        0.05865250527858734,
        0.05213160067796707,
        0.036924488842487335,
        -0.027653930708765984,
        0.04853092133998871,
        0.004935222212225199,
        -0.0018276403425261378,
        0.04930538684129715,
        -0.059926968067884445,
        -0.008831077255308628,
        -0.02705102041363716,
        0.04123620688915253,
        -0.05817815661430359,
        -0.05915934965014458,
        -0.056795645505189896,
        0.045751579105854034,
        0.0593193955719471,
        -0.05746860429644585,
        -0.059346526861190796,
        0.05879563093185425,
        0.05575616657733917,
        0.05536118894815445,
        0.051278285682201385,
        0.027990683913230896,
        0.007135305553674698,
        -0.04982651397585869,
        0.04760816693305969,
        -0.03411947563290596,
        0.054842911660671234,
        -0.06001714617013931,
        -0.013505488634109497,
        0.055775225162506104,
        -0.03457753732800484,
        -0.05773363262414932,
        -0.04958462342619896,
        -0.017763011157512665,
        0.05936911329627037,
        -0.05814222991466522,
        0.054640814661979675,
        -0.05067591369152069,
        -0.04401154816150665,
        -0.03356499597430229,
        0.018976755440235138,
        0.03207448124885559,
        0.043245721608400345,
        -0.047645844519138336,
        -0.05626140534877777,
        0.03303137794137001,
        -0.02785586751997471,
        -0.008414257317781448,
        0.0025733986403793097,
        -0.05673820525407791,
        -0.04985610768198967,
        0.047325268387794495,
        0.057058874517679214,
        0.05367787554860115,
        -0.041312169283628464,
        0.059694066643714905,
        -0.012881826609373093,
        -0.03744930401444435,
        0.027115456759929657,
        -0.011274519376456738,
        0.04717400670051575,
        -0.044245991855859756,
        0.02948385290801525,
        0.04741286113858223,
        -0.055322255939245224,
        -0.05900021642446518,
        -0.005581117235124111,
        0.058620747178792953,
        -0.04858304560184479,
        -0.05663241446018219,
        0.04472452029585838,
        0.0047699883580207825,
        -0.021685391664505005,
        -0.059860922396183014,
        -0.040588464587926865,
        0.053022854030132294,
        -0.05957654118537903,
        0.019772792235016823,
        -0.05364074185490608,
        -0.056431256234645844,
        0.017520278692245483,
        0.05980324745178223,
        -0.04307902231812477,
        -0.024473601952195168,
        0.058158598840236664,
        0.03725098818540573,
        -0.05713871493935585,
        -0.0004832231788896024,
        -0.03285669535398483,
        -0.05551653727889061,
        -0.035200219601392746,
        0.005853333044797182,
        -0.05759912729263306,
        0.05825819820165634,
        0.0030421356204897165,
        -0.011669065803289413,
        0.05916305258870125,
        -0.04858638718724251,
        0.05823754146695137,
        0.03626980632543564,
        0.054143574088811874,
        -0.02119371108710766,
        -0.02996481955051422,
        0.060187697410583496,
        0.045418042689561844,
        0.008253002539277077,
        0.052041471004486084,
        0.027333490550518036,
        0.05541345104575157,
        -0.056131213903427124,
        -0.01787523366510868,
        -0.0512494295835495,
        -0.057821620255708694,
        -0.01849840022623539,
        0.05850198119878769,
        -0.05373668298125267,
        -0.023230545222759247,
        -0.010168555192649364,
        -0.04768940806388855,
        0.05405765399336815,
        0.04831573739647865,
        -0.05087120458483696,
        -0.04145139828324318,
        0.04673171788454056,
        -0.03051796182990074,
        0.05978109687566757,
        -0.0520259328186512,
        0.046513717621564865,
        -0.0601898729801178,
        -0.04006505012512207,
        -0.05437912791967392,
        0.012565171346068382,
        0.05368401110172272,
        0.05503024533390999,
        0.05910532921552658,
        0.05322249233722687,
        0.04593927040696144,
        -0.042347900569438934,
        -0.055791135877370834,
        -0.05285293236374855,
        0.06015386804938316,
        -0.05822669342160225,
        -0.010896096006035805,
        0.03585689887404442,
        0.019863609224557877,
        0.03773670271039009,
        -0.058130331337451935,
        -0.0595242902636528,
        -0.011316788382828236,
        -0.028604652732610703,
        0.028787391260266304,
        0.008189884945750237,
        0.059635940939188004,
        -0.019299780949950218,
        0.05874668061733246,
        0.060200177133083344,
        -0.02841755747795105,
        0.006873008795082569,
        -0.05681338906288147,
        -0.021632926538586617,
        0.04406321048736572,
        0.05830400437116623,
        0.05975309759378433,
        -0.03966432809829712,
        0.053926046937704086,
        0.04485250636935234,
        -0.052607715129852295,
        0.059816084802150726,
        -0.03800069913268089,
        0.02637067437171936,
        -0.02658216655254364,
        0.05168358236551285,
        -0.0471872054040432,
        0.05661889538168907,
        0.028108961880207062,
        -0.059053052216768265,
        -0.06025675684213638,
        0.05116930976510048,
        -0.052652757614851,
        -0.06020727381110191,
        -0.042922623455524445,
        -0.052966583520174026,
        -0.05412866920232773,
        -0.024169260635972023,
        0.05095745623111725,
        -0.029222356155514717,
        -0.05888819694519043,
        -0.059074923396110535,
        0.006854218430817127,
        0.059564054012298584,
        0.04092567414045334,
        0.03426876664161682,
        -0.04108097031712532,
        0.039978690445423126,
        -0.007759321480989456,
        -0.04490579292178154,
        0.025573085993528366,
        -0.02647586725652218,
        -0.058534447103738785,
        -0.0459100566804409,
        0.0006595385493710637,
        -0.02633676305413246,
        0.022488471120595932,
        0.05967847257852554,
        0.04623905196785927,
        0.059398408979177475,
        -0.024944446980953217,
        0.05247553810477257,
        -0.052945639938116074,
        -0.03636594116687775,
        -0.006041232030838728,
        0.054169777780771255,
        -0.01966705173254013,
        0.05451209098100662,
        -0.031050410121679306,
        0.05564077943563461,
        0.03168880194425583,
        0.04479309171438217,
        0.027966361492872238,
        0.027621235698461533,
        -0.05675926059484482,
        -0.02162078022956848,
        -0.0444776825606823,
        -0.023447588086128235,
        0.058192405849695206,
        -0.0597430020570755,
        0.058547668159008026,
        -0.048838987946510315,
        -0.05890114977955818,
        0.037538859993219376,
        -0.04966672509908676,
        0.021875549107789993,
        0.027130456641316414,
        0.0076064057648181915,
        -0.004960506223142147,
        0.040708210319280624,
        0.03109518624842167,
        -0.03059123083949089,
        -0.007257361896336079,
        0.05901029333472252,
        0.0549626424908638,
        -0.010211368091404438,
        0.022550422698259354,
        0.04976661130785942,
        0.011465034447610378,
        -0.0025546180550009012,
        0.012477342039346695,
        -0.0568985678255558,
        0.003402077592909336,
        -0.0282958522439003,
        -0.060113295912742615,
        0.05499410629272461,
        -0.016467323526740074,
        0.05842571705579758,
        0.0248833317309618,
        -0.058366917073726654,
        -0.028648855164647102,
        0.051597148180007935,
        0.04574130102992058,
        0.054577309638261795,
        -0.008729731664061546,
        -0.02793791890144348,
        -0.0037179519422352314,
        -0.05709577351808548,
        0.03029591590166092,
        -0.04114361107349396,
        -0.0022912765853106976,
        -0.04760971665382385,
        -0.04196253791451454,
        0.02146824635565281,
        0.05529617890715599
    ],
    [
        -0.05779024958610535,
        0.024998558685183525,
        -0.039274752140045166,
        0.01926310919225216,
        0.04798760265111923,
        -0.02520895190536976,
        0.05765707790851593,
        0.054966408759355545,
        0.017105666920542717,
        0.036767128854990005,
        -0.04458599537611008,
        -0.02376040257513523,
        -0.0553697794675827,
        0.024522066116333008,
        -0.055787209421396255,
        0.05741390585899353,
        -0.05780264362692833,
        0.016757020726799965,
        -0.023899931460618973,
        0.05682121217250824,
        -0.05794669687747955,
        -0.04059915617108345,
        0.025213493034243584,
        -0.043946363031864166,
        -0.0007103230454958975,
        -0.04862264543771744,
        0.054575320333242416,
        0.05701456591486931,
        -0.023985423147678375,
        -0.05725099518895149,
        -0.025805197656154633,
        0.003861380973830819,
        0.048832330852746964,
        -0.05575629696249962,
        0.026025589555501938,
        -0.0017197345150634646,
        -0.045070622116327286,
        0.021163981407880783,
        -0.05796489864587784,
        0.05115397647023201,
        -0.0179534163326025,
        0.04744168370962143,
        0.03760765492916107,
        -0.05796179175376892,
        0.04328069090843201,
        -0.050406888127326965,
        -0.013164001516997814,
        0.05194668099284172,
        0.05686337873339653,
        0.033286333084106445,
        0.0438530407845974,
        -0.04359966889023781,
        0.02566356584429741,
        0.014198689721524715,
        0.05490998178720474,
        -0.04785558953881264,
        -0.0479000024497509,
        -0.014103361405432224,
        -0.004648571368306875,
        0.037051815539598465,
        -0.05534057319164276,
        0.002959779929369688,
        -0.047214001417160034,
        0.056751929223537445,
        0.04958542808890343,
        0.057956263422966,
        0.046287450939416885,
        -0.005806954577565193,
        0.05121033638715744,
        -0.05795915052294731,
        -0.05702849105000496,
        -0.05622592568397522,
        -0.013332845643162727,
        0.04154280945658684,
        -0.05700888857245445,
        0.01820576936006546,
        0.01962568797171116,
        0.05698718503117561,
        0.047105658799409866,
        -0.05737936869263649,
        -0.04375075176358223,
        0.026269741356372833,
        -0.05454558506608009,
        0.0557563453912735,
        0.05726165696978569,
        0.0017967126332223415,
        -0.050974391400814056,
        -0.052615415304899216,
        0.05713241919875145,
        0.05789460241794586,
        -0.008198930881917477,
        0.03334836661815643,
        0.004214172251522541,
        -0.043742451816797256,
        -0.02702287957072258,
        -0.052237220108509064,
        -0.043448884040117264,
        -0.05237463861703873,
        -0.041567686945199966,
        0.052934680134058,
        -0.057109709829092026,
        -0.053132787346839905,
        0.05724506825208664,
        0.05461610108613968,
        -0.0007805938948877156,
        -0.05752438306808472,
        0.029412774369120598,
        -0.0290911253541708,
        -0.05129265412688255,
        0.003050726605579257,
        -0.05727140232920647,
        -0.05751485377550125,
        -0.03509297966957092,
        0.007947794161736965,
        0.057722728699445724,
        0.03539639711380005,
        -0.04102020338177681,
        -0.054046135395765305,
        -0.05067981407046318,
        -0.02682165987789631,
        -0.05543135106563568,
        -0.05547260865569115,
        -0.057071223855018616,
        0.05181051418185234,
        -0.05731300637125969,
        -0.02253146655857563,
        0.028789566829800606,
        0.05763456970453262,
        0.05704857409000397,
        0.053845103830099106,
        0.05743107199668884,
        -0.03986324742436409,
        -0.011674443259835243,
        -0.046002842485904694,
        -0.04689699783921242,
        -0.043392352759838104,
        -0.0007740880246274173,
        -0.049333371222019196,
        -0.051931340247392654,
        0.057955022901296616,
        0.057789213955402374,
        0.054299842566251755,
        0.04014519602060318,
        0.055355872958898544,
        -0.056111082434654236,
        0.052668213844299316,
        0.00531611405313015,
        -0.05760687217116356,
        0.030055275186896324,
        0.04903808981180191,
        -0.039309777319431305,
        -0.05687315762042999,
        0.04161819443106651,
        0.048651836812496185,
        0.01998806744813919,
        -0.013538199476897717,
        0.05727047473192215,
        0.02117852121591568,
        0.04198828712105751,
        -0.00861772708594799,
        0.05222930386662483,
        -0.03241923078894615,
        0.03240141645073891,
        -0.05328558385372162,
        -0.041302863508462906,
        0.022049622610211372,
        -0.03212950378656387,
        -0.05758877471089363,
        -0.03746182844042778,
        0.036741457879543304,
        0.0425618551671505,
        0.041756849735975266,
        -0.05708615854382515,
        0.05749930441379547,
        -0.024183833971619606,
        0.047531407326459885,
        -0.05147688463330269,
        0.03692726418375969,
        0.04536496475338936,
        0.022112412378191948,
        0.04885920137166977,
        -0.046044979244470596,
        0.05439350754022598,
        -0.000010880212357733399,
        -0.008709214627742767,
        -0.05630006268620491,
        -0.033389512449502945,
        0.05489971488714218,
        -0.04252908006310463,
        0.020145239308476448,
        0.02053557150065899,
        0.007812008261680603,
        -0.05470254644751549,
        0.05183713883161545,
        -0.04396090283989906,
        -0.037629518657922745,
        0.057146891951560974,
        -0.041304249316453934,
        -0.007761094719171524,
        0.05088859796524048,
        0.030506357550621033,
        0.055899541825056076,
        0.04255043342709541,
        -0.05688514560461044,
        -0.052893687039613724,
        0.013414052315056324,
        0.01952200196683407,
        -0.04110681638121605,
        0.012677074410021305,
        0.047192949801683426,
        -0.04936765134334564,
        0.006600779481232166,
        0.05466882511973381,
        -0.019311437383294106,
        0.05769325792789459,
        -0.03374112397432327,
        -0.038390010595321655,
        0.039901476353406906,
        0.05711072310805321,
        -0.05594085901975632,
        -0.05527487024664879,
        -0.029621291905641556,
        -0.05720672756433487,
        -0.05796429142355919,
        0.009534663520753384,
        -0.05770867317914963,
        -0.01762320287525654,
        0.05413421615958214,
        -0.04501104727387428,
        -0.03188818320631981,
        -0.022327888756990433,
        -0.02191847190260887,
        -0.056158632040023804,
        0.03926606476306915,
        0.054460182785987854,
        -0.05126011371612549,
        0.05554788559675217,
        0.01041102409362793,
        -0.020603327080607414,
        0.056446295231580734,
        0.05642637237906456,
        0.036813072860240936,
        -0.03657761216163635,
        0.05506604537367821,
        0.02697158046066761,
        0.025747625157237053,
        0.054054420441389084,
        -0.0576704666018486,
        0.01345615554600954,
        -0.03288954123854637,
        0.034370504319667816,
        -0.0558495819568634,
        -0.05777474865317345,
        -0.047068461775779724,
        0.04908653348684311,
        0.05769038572907448,
        -0.057053375989198685,
        -0.05763019621372223,
        0.05396350473165512,
        0.056002210825681686,
        0.05561218410730362,
        0.04913681000471115,
        0.03687506914138794,
        0.02094932459294796,
        -0.052243076264858246,
        0.03870483487844467,
        -0.028098974376916885,
        0.05659767612814903,
        -0.05786838382482529,
        -0.0012285758275538683,
        0.05398007109761238,
        -0.032134801149368286,
        -0.057550448924303055,
        -0.053591467440128326,
        -0.004781905096024275,
        0.048707928508520126,
        -0.05407188460230827,
        0.056599877774715424,
        -0.04346189275383949,
        -0.05507868528366089,
        -0.054483674466609955,
        0.00913151353597641,
        0.04289248585700989,
        0.03247418254613876,
        -0.03931165486574173,
        -0.05692547187209129,
        0.05005529522895813,
        -0.014557996764779091,
        -0.00010559467773418874,
        -0.017915233969688416,
        -0.0474122129380703,
        -0.051242709159851074,
        0.04516899958252907,
        0.04245024546980858,
        0.050101038068532944,
        -0.025242561474442482,
        0.05343311280012131,
        0.026162471622228622,
        -0.036698855459690094,
        0.034463245421648026,
        -0.023806238546967506,
        0.050487834960222244,
        0.010998019948601723,
        0.0485835000872612,
        0.05191456899046898,
        -0.050707533955574036,
        -0.057443711906671524,
        -0.008175075054168701,
        0.05726722627878189,
        -0.04287128522992134,
        -0.056419163942337036,
        0.03398163244128227,
        -0.036713577806949615,
        -0.04230417311191559,
        -0.05760323256254196,
        -0.03941883146762848,
        0.05075015872716904,
        -0.05774762108922005,
        0.012277607806026936,
        -0.05643751844763756,
        -0.04930524900555611,
        0.03267466276884079,
        0.05782337486743927,
        -0.04413396120071411,
        -0.022990843281149864,
        0.057337384670972824,
        0.04089994728565216,
        -0.048194319009780884,
        -0.011417752131819725,
        -0.004813165403902531,
        -0.04985078051686287,
        0.03240017592906952,
        0.01892632432281971,
        -0.05710770562291145,
        0.055803097784519196,
        0.04259476438164711,
        -0.04136398062109947,
        0.05642661452293396,
        -0.05238916724920273,
        0.05691820755600929,
        0.04280401021242142,
        0.05056185647845268,
        -0.009961442090570927,
        -0.019481884315609932,
        0.05782337486743927,
        0.050347451120615005,
        -0.0038257683627307415,
        0.04190688952803612,
        0.02238309569656849,
        0.049046602100133896,
        -0.04114409536123276,
        -0.004320055246353149,
        -0.051702890545129776,
        -0.054293595254421234,
        0.001303371973335743,
        0.048185158520936966,
        -0.044227518141269684,
        -0.017020132392644882,
        0.012004062533378601,
        -0.03513816371560097,
        0.05241861939430237,
        0.053305432200431824,
        -0.04795510694384575,
        0.012873662635684013,
        0.047255679965019226,
        -0.031117618083953857,
        0.05787127837538719,
        -0.022796988487243652,
        0.054521508514881134,
        -0.057906825095415115,
        -0.053652241826057434,
        -0.05365888774394989,
        0.011922644451260567,
        0.04248563200235367,
        0.057551201432943344,
        0.05759454146027565,
        0.05500277131795883,
        0.05211583524942398,
        -0.056787822395563126,
        -0.056244075298309326,
        -0.05493376404047012,
        0.05795303359627724,
        -0.05706307291984558,
        -0.015080169774591923,
        0.027890929952263832,
        0.03668889030814171,
        0.05359596014022827,
        -0.057119447737932205,
        -0.05568685382604599,
        0.011885767802596092,
        -0.039510488510131836,
        0.02203901670873165,
        0.02555501088500023,
        0.05780569091439247,
        -0.025267917662858963,
        0.05633959919214249,
        0.057948339730501175,
        -0.043483369052410126,
        0.03644595667719841,
        -0.05759774148464203,
        -0.03561561182141304,
        0.050162654370069504,
        0.0576552078127861,
        0.05764365196228027,
        -0.05100008845329285,
        0.053357407450675964,
        0.05404851585626602,
        -0.04854222759604454,
        0.05748420208692551,
        -0.0558575876057148,
        0.02785862237215042,
        -0.02030620537698269,
        0.05253861844539642,
        -0.050661999732255936,
        0.05478377267718315,
        -0.023877568542957306,
        -0.0574762225151062,
        -0.057962607592344284,
        0.04736659675836563,
        -0.055918168276548386,
        -0.057746585458517075,
        -0.032935164868831635,
        -0.05580258369445801,
        -0.05370773375034332,
        0.04609476402401924,
        0.04589395597577095,
        -0.047882210463285446,
        -0.05502668768167496,
        -0.05643846094608307,
        -0.03200127184391022,
        0.05762363597750664,
        0.0487678088247776,
        0.030771736055612564,
        -0.053399428725242615,
        0.04542636498808861,
        -0.04381749406456947,
        -0.001508076791651547,
        0.029850834980607033,
        -0.037185560911893845,
        -0.0552990585565567,
        -0.03863616660237312,
        -0.02111920155584812,
        -0.031560685485601425,
        0.04356948286294937,
        0.057770904153585434,
        0.04515526816248894,
        0.05493032559752464,
        -0.007358955685049295,
        0.04764951393008232,
        -0.03569968789815903,
        -0.0352390818297863,
        0.047628022730350494,
        0.05045947805047035,
        -0.05022728815674782,
        0.054628655314445496,
        -0.020886056125164032,
        0.05384218320250511,
        0.04851354658603668,
        0.05217602849006653,
        0.052209217101335526,
        0.030873285606503487,
        -0.05383039638400078,
        0.01084890216588974,
        -0.04551571607589722,
        0.053843867033720016,
        0.05735129863023758,
        -0.05722475051879883,
        0.056771401315927505,
        -0.048579636961221695,
        -0.05784851685166359,
        0.0445588044822216,
        -0.05308210849761963,
        0.00010197296796832234,
        0.019349057227373123,
        0.008038370870053768,
        0.04335610568523407,
        0.04774317517876625,
        0.047512251883745193,
        -0.04328940808773041,
        -0.01233938429504633,
        0.05760885402560234,
        0.05764875188469887,
        -0.023017223924398422,
        0.052099134773015976,
        0.056682296097278595,
        0.011316942051053047,
        0.028033021837472916,
        -0.019280074164271355,
        -0.04599801078438759,
        0.007352709770202637,
        -0.011804255656898022,
        -0.057938605546951294,
        0.05715164542198181,
        -0.01446222048252821,
        0.05690714716911316,
        0.03030374087393284,
        -0.057555653154850006,
        -0.012618804350495338,
        0.04988764971494675,
        0.0518922284245491,
        0.05286501720547676,
        -0.0008680791943334043,
        -0.019645217806100845,
        -0.011833107098937035,
        -0.05578894168138504,
        0.04706515744328499,
        -0.018679535016417503,
        0.024813825264573097,
        -0.038819391280412674,
        -0.043270405381917953,
        0.021055147051811218,
        0.05382635071873665
    ],
    [
        -0.008382637985050678,
        0.039699360728263855,
        0.04724263399839401,
        0.010861626826226711,
        -0.04438129439949989,
        0.06516516953706741,
        0.023125525563955307,
        -0.06272017955780029,
        0.06102868542075157,
        0.05221782624721527,
        0.039938438683748245,
        -0.003594115609303117,
        -0.03867312893271446,
        -0.041970446705818176,
        -0.054907940328121185,
        0.06461183726787567,
        0.036286599934101105,
        0.06255608797073364,
        -0.02359212562441826,
        -0.06558828055858612,
        0.05374402925372124,
        0.060448721051216125,
        0.04248428717255592,
        0.021675853058695793,
        -0.014957455918192863,
        0.0493307039141655,
        0.055990301072597504,
        -0.024951552972197533,
        -0.009351478889584541,
        0.0652952790260315,
        0.022726276889443398,
        -0.01029820553958416,
        0.04539330303668976,
        -0.06339935958385468,
        0.04938087239861488,
        0.04389295354485512,
        -0.055813632905483246,
        -0.01632562093436718,
        0.002841254463419318,
        0.013503404334187508,
        0.02132759429514408,
        0.028700409457087517,
        0.02440096065402031,
        0.006209203973412514,
        0.06042477861046791,
        0.041953299194574356,
        -0.046523354947566986,
        0.008622744120657444,
        0.05938756838440895,
        -0.03584854677319527,
        0.06035386770963669,
        0.0640498548746109,
        -0.048644840717315674,
        0.005085712764412165,
        0.03809813782572746,
        0.00886436179280281,
        -0.0369579903781414,
        0.05922350659966469,
        -0.015851568430662155,
        -0.027714023366570473,
        -0.05297207087278366,
        -0.010708916932344437,
        -0.04369344562292099,
        0.06500059366226196,
        -0.057841140776872635,
        -0.02325935661792755,
        -0.030410664156079292,
        -0.011896783486008644,
        -0.03775348886847496,
        -0.046506330370903015,
        -0.006019684951752424,
        -0.06373798847198486,
        -0.03783369064331055,
        0.050216443836688995,
        -0.027330052107572556,
        -0.03265736997127533,
        0.038466133177280426,
        0.06504537910223007,
        0.06386124342679977,
        0.020556004717946053,
        -0.006711619906127453,
        0.019731415435671806,
        -0.06012274697422981,
        0.04446641728281975,
        0.04351024702191353,
        0.0550910159945488,
        0.0623665489256382,
        0.06490285694599152,
        0.02536577358841896,
        -0.05003040283918381,
        -0.030054887756705284,
        -0.06193270534276962,
        -0.06520231813192368,
        -0.015703175216913223,
        -0.027220699936151505,
        0.05697499215602875,
        -0.009104223921895027,
        -0.05635334551334381,
        0.05979067087173462,
        0.0054233865812420845,
        -0.06412879377603531,
        0.06381938606500626,
        0.03609994426369667,
        0.029959436506032944,
        -0.06362667679786682,
        -0.04645540192723274,
        0.037509482353925705,
        0.056482892483472824,
        0.05410618335008621,
        0.0542566180229187,
        0.06081661209464073,
        0.05886630341410637,
        -0.05220259353518486,
        0.04082050919532776,
        -0.04957259073853493,
        -0.048297155648469925,
        0.06483366340398788,
        -0.0535561628639698,
        0.055914394557476044,
        0.062473878264427185,
        -0.006645774003118277,
        0.05815756320953369,
        0.017181986942887306,
        -0.05373244360089302,
        -0.022703474387526512,
        0.03366617485880852,
        -0.0017206406919285655,
        0.05306673049926758,
        0.018167827278375626,
        0.06369777768850327,
        0.029569193720817566,
        -0.05068318173289299,
        0.00414425740018487,
        -0.017922580242156982,
        -0.05845211446285248,
        -0.05665173381567001,
        0.0629318505525589,
        -0.0017120502889156342,
        -0.04524477943778038,
        -0.025006089359521866,
        0.05577173829078674,
        -0.016336169093847275,
        0.04568428546190262,
        -0.0028786780312657356,
        0.00956909079104662,
        -0.06573283672332764,
        -0.007996748201549053,
        -0.04273312911391258,
        0.04469538480043411,
        -0.0478861965239048,
        0.047182925045490265,
        -0.060930799692869186,
        0.04172145202755928,
        -0.0624036081135273,
        0.029972702264785767,
        -0.05563093349337578,
        0.061024390161037445,
        -0.005393823143094778,
        0.0267088171094656,
        0.053482137620449066,
        0.06576111167669296,
        -0.0378740094602108,
        0.04757144674658775,
        -0.0031244622077792883,
        -0.0356680192053318,
        0.055068012326955795,
        -0.02838023006916046,
        0.03823899105191231,
        0.016539018601179123,
        0.04051275923848152,
        -0.04722526669502258,
        0.03220682218670845,
        -0.01636076718568802,
        -0.019765958189964294,
        -0.025091413408517838,
        0.062227513641119,
        -0.06430376321077347,
        -0.011599241755902767,
        0.029938427731394768,
        -0.04392138496041298,
        -0.029854103922843933,
        0.06552503257989883,
        -0.04136677086353302,
        0.05791196972131729,
        0.06432382017374039,
        0.009963220916688442,
        -0.04821727052330971,
        0.052714694291353226,
        -0.0070096589624881744,
        0.04818851128220558,
        -0.025937870144844055,
        0.027115337550640106,
        -0.003245476633310318,
        -0.00818503089249134,
        0.016585132107138634,
        0.015474643558263779,
        -0.05407430976629257,
        -0.06575721502304077,
        -0.05963044986128807,
        0.040523216128349304,
        0.058395449072122574,
        -0.0643996000289917,
        0.04652149975299835,
        0.02798880636692047,
        -0.02552565559744835,
        0.029169801622629166,
        0.02811681479215622,
        0.006739696487784386,
        -0.02723614126443863,
        -0.0007037715404294431,
        -0.04896561801433563,
        0.014005590230226517,
        0.0474378764629364,
        0.0024641845375299454,
        0.029662085697054863,
        0.0004806310753338039,
        0.06567277759313583,
        -0.0002445389109198004,
        0.0634743869304657,
        0.054537151008844376,
        0.037197764962911606,
        0.030398664996027946,
        0.06283755600452423,
        0.001859460142441094,
        0.049412939697504044,
        -0.04557026922702789,
        0.030074777081608772,
        0.013740511611104012,
        -0.014375092461705208,
        -0.011193878948688507,
        -0.011638964526355267,
        -0.057283490896224976,
        0.011221268214285374,
        0.04895627871155739,
        -0.036109596490859985,
        -0.03508240729570389,
        0.05843536555767059,
        -0.0021416509989649057,
        0.0019837089348584414,
        0.06395480036735535,
        -0.042464498430490494,
        0.05763017758727074,
        -0.0519881546497345,
        0.051975786685943604,
        0.04111917316913605,
        -0.018223777413368225,
        -0.05942821130156517,
        0.026504360139369965,
        -0.056310296058654785,
        -0.05693631246685982,
        -0.0643041655421257,
        -0.06284199655056,
        -0.04507860168814659,
        -0.06444494426250458,
        -0.05273858457803726,
        0.0564279668033123,
        -0.032582566142082214,
        -0.049605369567871094,
        0.00752289779484272,
        -0.01648297905921936,
        0.06209472194314003,
        -0.03730764985084534,
        -0.04827553778886795,
        0.047512274235486984,
        0.0577879473567009,
        0.05996476486325264,
        0.05549802631139755,
        0.05361275374889374,
        -0.05214918404817581,
        0.005679610185325146,
        -0.007728781551122665,
        -0.05204331502318382,
        0.06543369591236115,
        -0.053737662732601166,
        0.05072774365544319,
        0.03759844973683357,
        -0.03831044211983681,
        -0.03752288222312927,
        -0.062266215682029724,
        -0.04131826013326645,
        -0.053711388260126114,
        0.023868195712566376,
        -0.061314377933740616,
        0.05083470046520233,
        0.06537920236587524,
        -0.058642543852329254,
        -0.0028334215749055147,
        0.05915944278240204,
        0.06407064944505692,
        0.056155309081077576,
        -0.05338181182742119,
        -0.011152718216180801,
        -0.023207301273941994,
        0.04060206189751625,
        0.059513889253139496,
        -0.05500570684671402,
        0.055592648684978485,
        0.052211154252290726,
        -0.013788657262921333,
        0.05773404240608215,
        0.0002616569690871984,
        -0.030590495094656944,
        -0.038454677909612656,
        0.05124439671635628,
        -0.06483254581689835,
        0.04297587648034096,
        -0.056523628532886505,
        0.06281492114067078,
        -0.04257255420088768,
        -0.03749648481607437,
        -0.04918229579925537,
        -0.0527099072933197,
        -0.015069401822984219,
        -0.033295948058366776,
        0.020139433443546295,
        -0.05838529020547867,
        -0.03219950944185257,
        -0.048868000507354736,
        0.04023826867341995,
        -0.033578574657440186,
        -0.04926443472504616,
        -0.0507187657058239,
        0.05141588672995567,
        0.03814743831753731,
        0.05324690788984299,
        -0.04020041972398758,
        0.04157731682062149,
        0.03474164009094238,
        -0.030649829655885696,
        -0.03497934713959694,
        0.028979144990444183,
        0.0420776829123497,
        0.026363380253314972,
        0.0454581193625927,
        0.051310282200574875,
        0.03786652535200119,
        -0.010743319056928158,
        0.04372948035597801,
        -0.0038933262694627047,
        0.04447546973824501,
        -0.06269504129886627,
        0.05691299960017204,
        0.03160570561885834,
        0.0011080290423706174,
        -0.011617038398981094,
        0.021804306656122208,
        0.0622214674949646,
        0.0486765131354332,
        -0.04683299735188484,
        0.047795094549655914,
        0.06368578970432281,
        0.04170176759362221,
        0.025619082152843475,
        -0.043501339852809906,
        -0.03502706065773964,
        0.02419103868305683,
        -0.04894903674721718,
        -0.06241827458143234,
        0.01243283785879612,
        -0.029135173186659813,
        0.04653294384479523,
        -0.011961751617491245,
        0.05826117843389511,
        -0.030925821512937546,
        -0.054655250161886215,
        -0.03949667885899544,
        0.06196684017777443,
        -0.017116548493504524,
        0.06361144036054611,
        -0.059380415827035904,
        -0.02189469151198864,
        0.0012545135105028749,
        0.021036574617028236,
        -0.023890815675258636,
        0.0004658627731259912,
        0.01171796303242445,
        -0.04399972781538963,
        0.06575930118560791,
        0.062299590557813644,
        0.035213179886341095,
        0.03552516549825668,
        -0.047310616821050644,
        -0.06399192661046982,
        0.049387913197278976,
        0.06502821296453476,
        -0.021746166050434113,
        0.06503139436244965,
        -0.01961408369243145,
        -0.03416576609015465,
        -0.03582403063774109,
        -0.06396842002868652,
        -0.007657584734261036,
        -0.03107130154967308,
        -0.054446130990982056,
        -0.010606512427330017,
        0.06165817752480507,
        0.030972447246313095,
        -0.015927668660879135,
        -0.05932582914829254,
        -0.05926191434264183,
        -0.04929925873875618,
        0.05594003200531006,
        -0.03521027788519859,
        -0.06540565937757492,
        -0.057087186723947525,
        -0.018662456423044205,
        0.04144580662250519,
        -0.04280449077486992,
        0.05955691635608673,
        -0.021795140579342842,
        -0.05388820171356201,
        -0.060587991029024124,
        -0.023741059005260468,
        0.029873346909880638,
        0.026692820712924004,
        -0.04920116066932678,
        -0.05128239840269089,
        -0.025259781628847122,
        0.04872963950037956,
        0.03738950192928314,
        -0.00014800287317484617,
        -0.05830642953515053,
        0.040211908519268036,
        -0.06056532263755798,
        0.017257580533623695,
        0.023845940828323364,
        -0.03954757750034332,
        0.05626772344112396,
        -0.0063836658373475075,
        -0.044290296733379364,
        -0.016982683911919594,
        -0.04783405363559723,
        -0.0550137422978878,
        -0.06228556111454964,
        0.05846339836716652,
        -0.05313260108232498,
        0.014176338911056519,
        -0.05539413541555405,
        0.03077862411737442,
        -0.042609959840774536,
        -0.01921674981713295,
        0.061944056302309036,
        0.010320577770471573,
        0.05720466747879982,
        0.008869258686900139,
        0.03240732476115227,
        0.05741100758314133,
        -0.05361335352063179,
        0.05759960785508156,
        0.05579838901758194,
        0.0062625170685350895,
        -0.003428690368309617,
        -0.002773214364424348,
        0.007677051238715649,
        0.0062855929136276245,
        -0.058655574917793274,
        -0.021523987874388695,
        -0.05276546627283096,
        -0.05817533656954765,
        0.04444113001227379,
        0.05917473882436752,
        -0.04041280224919319,
        -0.05717009678483009,
        0.060162194073200226,
        0.04647674039006233,
        0.06006958335638046,
        -0.007513793651014566,
        0.05430002138018608,
        -0.018839171156287193,
        -0.06114125996828079,
        0.06214325502514839,
        0.06526412069797516,
        -0.014297268353402615,
        0.06045926734805107,
        -0.06516814231872559,
        -0.024666810408234596,
        -0.035228654742240906,
        0.043848976492881775,
        0.046239618211984634,
        -0.05554758384823799,
        -0.05241422727704048,
        -0.037897564470767975,
        -0.05766831710934639,
        0.02852581813931465,
        0.0012350579490885139,
        0.05147768184542656,
        0.05048944056034088,
        0.038091033697128296,
        -0.04212987422943115,
        -0.0554666630923748,
        -0.02904934622347355,
        0.028099993243813515,
        0.057082779705524445,
        -0.0334206260740757,
        -0.024237653240561485,
        0.048114992678165436,
        0.039494190365076065,
        0.03162134811282158,
        0.051414892077445984,
        0.05668995901942253,
        -0.058702487498521805,
        -0.03053174540400505,
        -0.04586007073521614,
        -0.02204463817179203,
        0.0380694679915905,
        -0.056208137422800064,
        0.06224953383207321,
        -0.004299692343920469,
        0.020572353154420853,
        -0.05887075513601303,
        0.04579072445631027,
        0.06428135931491852
    ],
    [
        -0.00830773077905178,
        0.03563664108514786,
        0.042898666113615036,
        0.013282297179102898,
        -0.03757287561893463,
        0.06519847363233566,
        0.012015230022370815,
        -0.062006495893001556,
        0.061442721635103226,
        0.05285583809018135,
        0.040176376700401306,
        0.013724429532885551,
        -0.04250016063451767,
        -0.043973781168460846,
        -0.05610791593790054,
        0.06469493359327316,
        0.03515775874257088,
        0.06290046870708466,
        -0.0018920993898063898,
        -0.06562767177820206,
        0.05600171536207199,
        0.060994554311037064,
        0.042339131236076355,
        0.02213451825082302,
        -0.023028135299682617,
        0.0433460958302021,
        0.05581055209040642,
        -0.03126989305019379,
        -0.020607832819223404,
        0.06527364999055862,
        0.005054651293903589,
        -0.03227468952536583,
        0.04793718829751015,
        -0.06337469816207886,
        0.04953288659453392,
        0.03425607085227966,
        -0.05719878524541855,
        -0.03723325952887535,
        0.026720207184553146,
        0.0005873831687495112,
        0.021356185898184776,
        0.026296306401491165,
        0.025836659595370293,
        0.008104152977466583,
        0.06190183386206627,
        0.04465949535369873,
        -0.042023252695798874,
        0.005536723416298628,
        0.05864666774868965,
        -0.0333111397922039,
        0.06076045334339142,
        0.06405868381261826,
        -0.04043904319405556,
        -0.006073521915823221,
        0.04661235958337784,
        -0.00019115809118375182,
        -0.03473513200879097,
        0.060306139290332794,
        -0.019140711054205894,
        -0.028780143707990646,
        -0.05243709683418274,
        -0.0005798256024718285,
        -0.04529779404401779,
        0.06498855352401733,
        -0.0570707693696022,
        -0.027787763625383377,
        -0.026496537029743195,
        -0.020651189610362053,
        -0.03930499404668808,
        -0.04530251398682594,
        0.009303897619247437,
        -0.06321271508932114,
        -0.036701131612062454,
        0.052077703177928925,
        -0.02051972970366478,
        -0.03926634043455124,
        0.027159767225384712,
        0.0649023950099945,
        0.06422577798366547,
        0.023494824767112732,
        0.007622134871780872,
        0.030828339979052544,
        -0.05957742780447006,
        0.05068007856607437,
        0.030964046716690063,
        0.05659527704119682,
        0.06315212696790695,
        0.0651453509926796,
        0.014088528230786324,
        -0.04391031712293625,
        -0.04342145472764969,
        -0.06336891651153564,
        -0.06529795378446579,
        -0.016643386334180832,
        -0.031865011900663376,
        0.05809984356164932,
        -0.01503388024866581,
        -0.05764158442616463,
        0.058784160763025284,
        0.010313249193131924,
        -0.06456036865711212,
        0.0644550770521164,
        0.03583082556724548,
        0.028894716873764992,
        -0.0627373531460762,
        -0.047057297080755234,
        0.032189205288887024,
        0.055501341819763184,
        0.05441420152783394,
        0.05289566144347191,
        0.05966313183307648,
        0.04608164727687836,
        -0.052769847214221954,
        0.03552817553281784,
        -0.05992778390645981,
        -0.04179336875677109,
        0.06459229439496994,
        -0.049954693764448166,
        0.05310509726405144,
        0.061281200498342514,
        -0.01411471888422966,
        0.058203063905239105,
        0.01781589165329933,
        -0.05033420771360397,
        -0.028112882748246193,
        0.02592485025525093,
        0.0042048669420182705,
        0.050340715795755386,
        0.016404205933213234,
        0.06355226039886475,
        0.0036045117303729057,
        -0.05026041343808174,
        0.009925052523612976,
        -0.002787595381960273,
        -0.059632059186697006,
        -0.055754199624061584,
        0.062422141432762146,
        0.008163346908986568,
        -0.04598508030176163,
        -0.02129177190363407,
        0.044794924557209015,
        -0.006773302331566811,
        0.0465041846036911,
        -0.010549426078796387,
        0.002097342861816287,
        -0.06561635434627533,
        -0.01624002866446972,
        -0.03897424414753914,
        0.044858697801828384,
        -0.04519873112440109,
        0.05694567412137985,
        -0.0614004023373127,
        0.03170781210064888,
        -0.06234227865934372,
        0.0334654226899147,
        -0.05592626333236694,
        0.056285955011844635,
        0.002917070407420397,
        0.021488487720489502,
        0.05388160049915314,
        0.06572593748569489,
        -0.038550347089767456,
        0.046264439821243286,
        0.011608839966356754,
        -0.029043033719062805,
        0.055989935994148254,
        -0.035390228033065796,
        0.029097827151417732,
        0.025701189413666725,
        0.038942959159612656,
        -0.04146605357527733,
        0.030499344691634178,
        -0.028468606993556023,
        -0.02487110160291195,
        -0.026521217077970505,
        0.06085698679089546,
        -0.0638272613286972,
        -0.012311390601098537,
        0.023799799382686615,
        -0.042495403438806534,
        -0.03193889185786247,
        0.06559774279594421,
        -0.038306694477796555,
        0.05583401769399643,
        0.0646948590874672,
        0.011930057778954506,
        -0.047551821917295456,
        0.052084386348724365,
        -0.00875295139849186,
        0.04145574942231178,
        -0.03256094455718994,
        0.01543968915939331,
        0.00798075646162033,
        -0.0129517437890172,
        0.024681270122528076,
        0.019465381279587746,
        -0.05579672008752823,
        -0.0657082200050354,
        -0.057557739317417145,
        0.031984757632017136,
        0.05953390523791313,
        -0.06497147679328918,
        0.04353729262948036,
        0.02250632643699646,
        -0.011664976365864277,
        0.03125881031155586,
        0.0303687434643507,
        0.014090010896325111,
        -0.035014886409044266,
        0.03241933509707451,
        -0.051483139395713806,
        0.017503533512353897,
        0.05194373056292534,
        0.011756457388401031,
        0.014654409140348434,
        0.032192692160606384,
        0.06562303751707077,
        -0.0003181652573402971,
        0.06335035711526871,
        0.053294651210308075,
        0.037489380687475204,
        0.03081696107983589,
        0.0622594878077507,
        0.0001880940399132669,
        0.05053921043872833,
        -0.04616764560341835,
        0.026373062282800674,
        -0.02854391187429428,
        -0.010263930074870586,
        -0.022108852863311768,
        -0.007433800492435694,
        -0.05879509076476097,
        0.008536476641893387,
        0.05156099423766136,
        -0.03583719581365585,
        -0.04051714390516281,
        0.059779245406389236,
        0.011357622221112251,
        0.009594653733074665,
        0.06432458758354187,
        -0.043920647352933884,
        0.05688120052218437,
        -0.052291542291641235,
        0.04550636559724808,
        0.04002537950873375,
        -0.015723586082458496,
        -0.051303450018167496,
        0.033396489918231964,
        -0.05541152134537697,
        -0.05722443386912346,
        -0.06391560286283493,
        -0.06320236623287201,
        -0.03526831045746803,
        -0.06458486616611481,
        -0.0518561415374279,
        0.05720466747879982,
        -0.03680986166000366,
        -0.05335966497659683,
        0.01007430162280798,
        -0.006157036405056715,
        0.062183864414691925,
        -0.036730796098709106,
        -0.044580720365047455,
        0.045336417853832245,
        0.05946981534361839,
        0.06011312082409859,
        0.06351711601018906,
        0.059452690184116364,
        -0.049756553024053574,
        0.009141287766397,
        -0.007612108718603849,
        -0.055066633969545364,
        0.06533632427453995,
        -0.04982946813106537,
        0.05581285431981087,
        0.03441369906067848,
        -0.04032701626420021,
        -0.04790797457098961,
        -0.05625766143202782,
        -0.038690172135829926,
        -0.05199969932436943,
        0.03065142035484314,
        -0.061842937022447586,
        0.04082575440406799,
        0.06524856388568878,
        -0.05704121291637421,
        -0.005400161724537611,
        0.056254711002111435,
        0.06374097615480423,
        0.05827705189585686,
        -0.05039634928107262,
        0.0033263389486819506,
        -0.018414221704006195,
        0.042373549193143845,
        0.059627410024404526,
        -0.05761077255010605,
        0.058266591280698776,
        0.052066441625356674,
        0.02211025170981884,
        0.058245208114385605,
        -0.011291179805994034,
        -0.02761637046933174,
        -0.04318428412079811,
        0.05156507343053818,
        -0.06524977833032608,
        0.041434284299612045,
        -0.0559379942715168,
        0.061551593244075775,
        -0.04810459911823273,
        -0.03234384208917618,
        -0.0498461052775383,
        -0.05257285386323929,
        -0.015847301110625267,
        -0.02587779052555561,
        0.014710307121276855,
        -0.058536168187856674,
        -0.03165080398321152,
        -0.03702659532427788,
        0.04140877723693848,
        -0.03611843287944794,
        -0.04940352961421013,
        -0.04944438114762306,
        0.050311774015426636,
        0.03925342485308647,
        0.05229760706424713,
        -0.04665246605873108,
        0.027226896956562996,
        0.03578554838895798,
        -0.020303593948483467,
        -0.037732962518930435,
        0.02570602111518383,
        0.037171512842178345,
        0.027386149391531944,
        0.034306563436985016,
        0.04937441274523735,
        0.0364672988653183,
        -0.012769605033099651,
        0.04835730046033859,
        -0.01208790484815836,
        0.04701249301433563,
        -0.062253303825855255,
        0.05467022955417633,
        0.027681363746523857,
        -0.00298826745711267,
        -0.006860701367259026,
        0.030218392610549927,
        0.06199014559388161,
        0.051429424434900284,
        -0.0367918536067009,
        0.05405885726213455,
        0.0627061054110527,
        0.03223254159092903,
        0.02985585853457451,
        -0.04671812057495117,
        -0.019859902560710907,
        0.021682925522327423,
        -0.04962354153394699,
        -0.06159408390522003,
        0.01808568648993969,
        -0.03463233634829521,
        0.04946393147110939,
        0.008006437681615353,
        0.056800272315740585,
        -0.03569803386926651,
        -0.060780975967645645,
        -0.039424020797014236,
        0.0629049688577652,
        -0.022945523262023926,
        0.0614553838968277,
        -0.05988892912864685,
        -0.019811097532510757,
        -0.01222902536392212,
        0.01771797239780426,
        -0.014423946850001812,
        -0.00113248685374856,
        -0.009298047982156277,
        -0.05086980387568474,
        0.06572509557008743,
        0.06334780156612396,
        0.044110920280218124,
        0.026019640266895294,
        -0.0500771626830101,
        -0.06419641524553299,
        0.05073534697294235,
        0.06511169672012329,
        -0.033895816653966904,
        0.06507545709609985,
        -0.017007092013955116,
        -0.03472712263464928,
        -0.030343206599354744,
        -0.06419558823108673,
        -0.013153084553778172,
        -0.024981042370200157,
        -0.058026041835546494,
        0.003238001139834523,
        0.06086250767111778,
        0.03194618225097656,
        -0.02539358288049698,
        -0.06164313107728958,
        -0.0615813247859478,
        -0.04556698352098465,
        0.05348227173089981,
        -0.03394867479801178,
        -0.0655120313167572,
        -0.05584296956658363,
        -0.011139786802232265,
        0.03796551004052162,
        -0.047591984272003174,
        0.059990912675857544,
        -0.009490471333265305,
        -0.05273682251572609,
        -0.0578465461730957,
        -0.01038564182817936,
        0.038134656846523285,
        0.015522578731179237,
        -0.05494374781847,
        -0.05543604493141174,
        -0.024663273245096207,
        0.051634304225444794,
        0.03970516473054886,
        -0.016448797658085823,
        -0.049944669008255005,
        0.047130078077316284,
        -0.06051873415708542,
        0.022992810234427452,
        0.01380710955709219,
        -0.03942100331187248,
        0.0584537498652935,
        -0.020039230585098267,
        -0.03437086194753647,
        0.0044873058795928955,
        -0.04418997839093208,
        -0.05377720296382904,
        -0.06297279894351959,
        0.055808473378419876,
        -0.04933067038655281,
        -0.025071047246456146,
        -0.05670701339840889,
        0.02050413005053997,
        -0.034829072654247284,
        -0.012384723871946335,
        0.06094971299171448,
        0.008499943651258945,
        0.05973402410745621,
        0.012707187794148922,
        0.035112809389829636,
        0.05869234353303909,
        -0.05754201114177704,
        0.05989731848239899,
        0.053222429007291794,
        -0.0006890204967930913,
        -0.004815965425223112,
        0.0006481232703663409,
        0.012662322260439396,
        0.0040839542634785175,
        -0.05955483764410019,
        -0.018339853733778,
        -0.05633793771266937,
        -0.05571651831269264,
        0.0430481843650341,
        0.05895030125975609,
        -0.03480268642306328,
        -0.05786949396133423,
        0.0632251501083374,
        0.04561132565140724,
        0.06022283062338829,
        -0.01671055518090725,
        0.04978964850306511,
        -0.017679478973150253,
        -0.06158880516886711,
        0.06162172928452492,
        0.06537307053804398,
        -0.01397652830928564,
        0.059756528586149216,
        -0.06478570401668549,
        -0.017133258283138275,
        -0.03760781139135361,
        0.051398903131484985,
        0.04757295176386833,
        -0.0565047562122345,
        -0.058982186019420624,
        -0.039873115718364716,
        -0.05754747614264488,
        0.025859348475933075,
        0.008893806487321854,
        0.054625123739242554,
        0.05674342066049576,
        0.03223733231425285,
        -0.042857374995946884,
        -0.05484865605831146,
        -0.0339827798306942,
        0.01586966961622238,
        0.056079063564538956,
        -0.04087676480412483,
        -0.008245394565165043,
        0.051957789808511734,
        0.04761279746890068,
        0.04045558348298073,
        0.054736822843551636,
        0.05535281077027321,
        -0.06147973611950874,
        -0.025585001334547997,
        -0.04974563792347908,
        -0.01933293417096138,
        0.04449490085244179,
        -0.056351546198129654,
        0.06374675780534744,
        0.006567174568772316,
        0.019711412489414215,
        -0.06235526502132416,
        0.05042369291186333,
        0.0626988410949707
    ],
    [
        -0.0175758246332407,
        0.03721971437335014,
        0.04072607681155205,
        0.024835141375660896,
        -0.04686533287167549,
        0.06464231014251709,
        0.01203946303576231,
        -0.06260310113430023,
        0.062223926186561584,
        0.057823628187179565,
        0.03852124139666557,
        0.01481640711426735,
        -0.0404697023332119,
        -0.035466212779283524,
        -0.05482693389058113,
        0.06420061737298965,
        0.024257712066173553,
        0.0625581219792366,
        -0.013135626912117004,
        -0.06512422859668732,
        0.057591963559389114,
        0.05944892391562462,
        0.04429088532924652,
        0.03045782260596752,
        -0.023752329871058464,
        0.05428268015384674,
        0.059191834181547165,
        -0.02260460890829563,
        -0.023292358964681625,
        0.06443347036838531,
        0.023242998868227005,
        -0.02157687209546566,
        0.04825860634446144,
        -0.06293530017137527,
        0.04809866100549698,
        0.043601375073194504,
        -0.05742022395133972,
        -0.034757547080516815,
        -0.022966498509049416,
        0.007298841141164303,
        0.023524796590209007,
        0.033293217420578,
        0.028325369581580162,
        -0.00023521421826444566,
        0.06175592541694641,
        0.03838948905467987,
        -0.038992322981357574,
        0.0075207906775176525,
        0.06198876351118088,
        -0.038441967219114304,
        0.05983536317944527,
        0.06376651674509048,
        -0.032913193106651306,
        0.002402838319540024,
        0.052785418927669525,
        0.006823885720223188,
        -0.03801242262125015,
        0.06088133901357651,
        -0.03172463923692703,
        -0.02935847081243992,
        -0.054230984300374985,
        -0.010686119087040424,
        -0.042358897626399994,
        0.06465452909469604,
        -0.059737276285886765,
        -0.021794097498059273,
        -0.02926962450146675,
        -0.00810205191373825,
        -0.033689308911561966,
        -0.04317020997405052,
        0.01238024327903986,
        -0.06114014610648155,
        -0.0393158495426178,
        0.054240498691797256,
        -0.018164796754717827,
        -0.03328217938542366,
        0.03532232716679573,
        0.06467945873737335,
        0.06455563753843307,
        0.023479362949728966,
        0.005833155009895563,
        0.025486599653959274,
        -0.060565244406461716,
        0.04567224532365799,
        0.011912861838936806,
        0.05554243549704552,
        0.06261663138866425,
        0.06470422446727753,
        0.02700261026620865,
        -0.04676593840122223,
        -0.042706962674856186,
        -0.06208564713597298,
        -0.06467150151729584,
        -0.01545734703540802,
        -0.034389007836580276,
        0.055207181721925735,
        -0.016441378742456436,
        -0.0567837618291378,
        0.05874767154455185,
        0.015420009382069111,
        -0.06359460949897766,
        0.06361781805753708,
        0.03600628301501274,
        0.03372788429260254,
        -0.062147025018930435,
        -0.051924023777246475,
        0.025329485535621643,
        0.058476753532886505,
        0.05550843104720116,
        0.05366722121834755,
        0.0582033172249794,
        0.04215327277779579,
        -0.05403614416718483,
        0.04463495686650276,
        -0.056630801409482956,
        -0.03655553609132767,
        0.06372383236885071,
        -0.04926325008273125,
        0.043989162892103195,
        0.06070081144571304,
        -0.01982167549431324,
        0.05762790888547897,
        0.0181085467338562,
        -0.04736432433128357,
        -0.027117185294628143,
        0.028832413256168365,
        -0.006631725002080202,
        0.048523999750614166,
        0.022709259763360023,
        0.0631260797381401,
        0.013650970533490181,
        -0.046684522181749344,
        0.01611352153122425,
        -0.007503730710595846,
        -0.05816114693880081,
        -0.0542561300098896,
        0.0620526485145092,
        0.004614011384546757,
        -0.04441535845398903,
        -0.026336684823036194,
        0.045953474938869476,
        -0.013716929592192173,
        0.04317674785852432,
        -0.0022832942195236683,
        0.0020186221227049828,
        -0.06512536108493805,
        -0.03176054358482361,
        -0.041323959827423096,
        0.03967410698533058,
        -0.050402745604515076,
        0.052259236574172974,
        -0.060867298394441605,
        0.04781078174710274,
        -0.06295895576477051,
        0.026739045977592468,
        -0.05888479948043823,
        0.059402383863925934,
        0.003107517957687378,
        0.030871780589222908,
        0.05717994272708893,
        0.06524019688367844,
        -0.04635029658675194,
        0.046023979783058167,
        0.0020390592981129885,
        -0.02716565690934658,
        0.056730397045612335,
        -0.03614778071641922,
        0.0282012689858675,
        0.029501793906092644,
        0.03528672829270363,
        -0.03718454763293266,
        0.036638129502534866,
        -0.03259493038058281,
        -0.01448129303753376,
        -0.02122615836560726,
        0.06228037178516388,
        -0.0636940523982048,
        -0.023468026891350746,
        0.03202376514673233,
        -0.045484885573387146,
        -0.03568676486611366,
        0.0651182308793068,
        -0.04194575548171997,
        0.05576451122760773,
        0.06380456686019897,
        0.006799802184104919,
        -0.04718348756432533,
        0.0486854612827301,
        0.002263241447508335,
        0.04775961488485336,
        -0.03000221960246563,
        0.01230885460972786,
        -0.00014763014041818678,
        -0.02805931493639946,
        0.009046175517141819,
        0.015284224413335323,
        -0.056124940514564514,
        -0.06522221863269806,
        -0.05319349467754364,
        0.04099065437912941,
        0.05968339741230011,
        -0.0646185353398323,
        0.04605129361152649,
        0.028453120961785316,
        0.0044219112023711205,
        0.013202453963458538,
        0.031643979251384735,
        0.008004161529242992,
        -0.03689674288034439,
        0.012529023922979832,
        -0.053583573549985886,
        0.019373437389731407,
        0.04534662887454033,
        0.007689873222261667,
        0.015735121443867683,
        0.033276282250881195,
        0.06519177556037903,
        0.0027622547931969166,
        0.061685506254434586,
        0.05637544393539429,
        0.04249389097094536,
        0.03206787258386612,
        0.06000617891550064,
        -0.007750564720481634,
        0.043501608073711395,
        -0.03513232618570328,
        0.02838493324816227,
        -0.015371101908385754,
        0.0004055627214256674,
        -0.021495573222637177,
        -0.010573187842965126,
        -0.05321734398603439,
        0.018580973148345947,
        0.05050842463970184,
        -0.024081122130155563,
        -0.03720741719007492,
        0.05932757258415222,
        -0.005089254584163427,
        0.023121416568756104,
        0.06420949101448059,
        -0.044859062880277634,
        0.056642282754182816,
        -0.042938001453876495,
        0.0412842258810997,
        0.04126116260886192,
        -0.010095939971506596,
        -0.05759251490235329,
        0.038439780473709106,
        -0.054692719131708145,
        -0.05719098448753357,
        -0.0631554052233696,
        -0.06314516812562943,
        -0.036398641765117645,
        -0.0642395168542862,
        -0.05469899624586105,
        0.05868600681424141,
        -0.04132164642214775,
        -0.047819096595048904,
        0.01656888797879219,
        0.009141583926975727,
        0.06062276288866997,
        -0.040434788912534714,
        -0.049199238419532776,
        0.04905711114406586,
        0.05373302847146988,
        0.05893518775701523,
        0.0636284127831459,
        0.05679753050208092,
        -0.049496348947286606,
        0.019361844286322594,
        -0.0012186955427750945,
        -0.05517704784870148,
        0.06491231918334961,
        -0.04937151074409485,
        0.05621799826622009,
        0.035410523414611816,
        -0.044434722512960434,
        -0.050976190716028214,
        -0.04818179830908775,
        -0.039287250488996506,
        -0.05112869665026665,
        0.03487642481923103,
        -0.06193928420543671,
        0.04535765200853348,
        0.06461544334888458,
        -0.056524477899074554,
        -0.00006827278411947191,
        0.04969136044383049,
        0.06367549300193787,
        0.055050093680620193,
        -0.05478936806321144,
        -0.0015790160978212953,
        -0.022099561989307404,
        0.042232710868120193,
        0.05836125463247299,
        -0.05708363652229309,
        0.05814097821712494,
        0.05665295198559761,
        0.02540939673781395,
        0.057647593319416046,
        0.003555110888555646,
        -0.04269201681017876,
        -0.04017893970012665,
        0.05322693660855293,
        -0.0644972175359726,
        0.041355691850185394,
        -0.05789044499397278,
        0.06074783578515053,
        -0.047788601368665695,
        -0.03434402123093605,
        -0.05226656794548035,
        -0.05437541380524635,
        -0.016333747655153275,
        -0.032061152160167694,
        0.001003414043225348,
        -0.05945689231157303,
        -0.03723462298512459,
        -0.03551999479532242,
        0.0402197390794754,
        -0.034853797405958176,
        -0.051042161881923676,
        -0.05270928889513016,
        0.043857693672180176,
        0.022516850382089615,
        0.0564018189907074,
        -0.04577617347240448,
        0.034433286637067795,
        0.04485240578651428,
        -0.027627307921648026,
        -0.04482031613588333,
        0.021533004939556122,
        0.031168103218078613,
        0.029253894463181496,
        0.03747130557894707,
        0.048179320991039276,
        0.03413063660264015,
        -0.022097213193774223,
        0.04554499313235283,
        -0.00901446957141161,
        0.04930082708597183,
        -0.06280415505170822,
        0.05445580184459686,
        0.03723889961838722,
        -0.0009066229104064405,
        0.01633564569056034,
        0.018632955849170685,
        0.06041334196925163,
        0.05186532065272331,
        -0.044370297342538834,
        0.05347208306193352,
        0.061633363366127014,
        0.04283904284238815,
        0.02433730848133564,
        -0.051850128918886185,
        -0.018002986907958984,
        0.024936752393841743,
        -0.050727810710668564,
        -0.061122991144657135,
        0.012708461843430996,
        -0.025329243391752243,
        0.044584039598703384,
        0.0036705858074128628,
        0.05577656626701355,
        -0.03360208123922348,
        -0.0595209114253521,
        -0.03824000805616379,
        0.0629485547542572,
        0.00006294147169683129,
        0.060041241347789764,
        -0.06161345914006233,
        -0.02257678657770157,
        -0.012057407759130001,
        0.018722090870141983,
        -0.012456122785806656,
        0.0038638804107904434,
        0.0017344128573313355,
        -0.054810550063848495,
        0.06524283438920975,
        0.061361007392406464,
        0.034452591091394424,
        0.027861421927809715,
        -0.036044612526893616,
        -0.06370888650417328,
        0.049278635531663895,
        0.06455795466899872,
        -0.03193780407309532,
        0.06441549956798553,
        -0.004437426570802927,
        -0.03815968707203865,
        -0.03187593072652817,
        -0.06378092616796494,
        -0.02548704296350479,
        -0.04160129651427269,
        -0.05709346756339073,
        -0.010831610299646854,
        0.061531029641628265,
        0.0135975806042552,
        -0.019490398466587067,
        -0.05743783339858055,
        -0.06072039157152176,
        -0.04321268945932388,
        0.05531684681773186,
        -0.011111290194094181,
        -0.0650067925453186,
        -0.05679629370570183,
        -0.016158387064933777,
        0.03357110172510147,
        -0.0465988963842392,
        0.060198958963155746,
        -0.01557842642068863,
        -0.055368196219205856,
        -0.05686090886592865,
        -0.013896400108933449,
        0.025974998250603676,
        0.015159417875111103,
        -0.05099065229296684,
        -0.05445870757102966,
        -0.03647438809275627,
        0.05006357654929161,
        0.02905375137925148,
        0.0003067205543629825,
        -0.05205833166837692,
        0.03940702974796295,
        -0.06070967763662338,
        0.02476762793958187,
        0.0011061294935643673,
        -0.041123781353235245,
        0.05834539234638214,
        -0.011107979342341423,
        -0.04592327028512955,
        -0.0006887512863613665,
        -0.04830479621887207,
        -0.05211303383111954,
        -0.062068551778793335,
        0.05704355984926224,
        -0.05115935206413269,
        -0.004391374997794628,
        -0.054871149361133575,
        0.0323207825422287,
        -0.04223788529634476,
        0.01631057634949684,
        0.06052592769265175,
        0.003897362854331732,
        0.05779755488038063,
        0.03532104939222336,
        0.037169262766838074,
        0.058333247900009155,
        -0.05824720114469528,
        0.05765799805521965,
        0.05678540840744972,
        0.002398193348199129,
        0.007232761476188898,
        -0.007379543501883745,
        0.023868437856435776,
        -0.008430186659097672,
        -0.05942608788609505,
        -0.019583726301789284,
        -0.0500885471701622,
        -0.05704205855727196,
        0.03811180219054222,
        0.060577601194381714,
        -0.04334728792309761,
        -0.05843045562505722,
        0.06360340118408203,
        0.04317326471209526,
        0.060150835663080215,
        -0.011748119257390499,
        0.051527172327041626,
        -0.015536783263087273,
        -0.06174932047724724,
        0.05956084281206131,
        0.06456224620342255,
        -0.011893338523805141,
        0.06084038317203522,
        -0.06392785906791687,
        -0.01921054907143116,
        -0.0385805107653141,
        0.05200490355491638,
        0.0499088354408741,
        -0.05706603452563286,
        -0.05815989151597023,
        -0.046326637268066406,
        -0.052545081824064255,
        0.023323532193899155,
        0.02500813826918602,
        0.05104939639568329,
        0.059703465551137924,
        0.03955267369747162,
        -0.03801266476511955,
        -0.05118098482489586,
        -0.04162478819489479,
        0.003459916217252612,
        0.05903582647442818,
        -0.03449711576104164,
        -0.0281092319637537,
        0.04504339396953583,
        0.04222846031188965,
        0.03525892645120621,
        0.05430660396814346,
        0.05674603953957558,
        -0.06187491491436958,
        -0.020910628139972687,
        -0.04867741838097572,
        -0.019966769963502884,
        0.04589327797293663,
        -0.05268961563706398,
        0.063267782330513,
        0.005268442910164595,
        0.03614550456404686,
        -0.061537060886621475,
        0.04755603149533272,
        0.06170422211289406
    ],
    [
        -0.0247347392141819,
        0.03229137137532234,
        0.039938632398843765,
        0.011839297600090504,
        -0.04592299088835716,
        0.06510179489850998,
        0.0032932120375335217,
        -0.0635853111743927,
        0.06255238503217697,
        0.058310627937316895,
        0.03696468099951744,
        0.0119444839656353,
        -0.04012627527117729,
        -0.045803409069776535,
        -0.05378689989447594,
        0.06495215743780136,
        0.028151005506515503,
        0.06279756873846054,
        -0.014781604520976543,
        -0.06560575217008591,
        0.055247534066438675,
        0.06048811972141266,
        0.043300651013851166,
        0.033212810754776,
        -0.03457525372505188,
        0.04943494126200676,
        0.05944928154349327,
        -0.0348590724170208,
        -0.023809267207980156,
        0.06504824012517929,
        0.00442499341443181,
        -0.02472211979329586,
        0.050864867866039276,
        -0.06147442385554314,
        0.04723888263106346,
        0.048046570271253586,
        -0.05638092756271362,
        -0.025394225493073463,
        -0.03677719458937645,
        0.009072468616068363,
        0.021926363930106163,
        0.02453005500137806,
        0.02843238040804863,
        -0.00616205669939518,
        0.06289912015199661,
        0.04312599077820778,
        -0.03204566612839699,
        0.013041302561759949,
        0.059288255870342255,
        -0.029524371027946472,
        0.06273278594017029,
        0.06468451023101807,
        -0.03600313141942024,
        0.015347883105278015,
        0.05314181372523308,
        0.0020337633322924376,
        -0.03273984417319298,
        0.062057014554739,
        -0.02990822121500969,
        -0.03472223877906799,
        -0.056417547166347504,
        -0.015049408189952374,
        -0.04515168443322182,
        0.06527531892061234,
        -0.05911262705922127,
        -0.013428659178316593,
        -0.02588294818997383,
        -0.024965716525912285,
        -0.039243437349796295,
        -0.04007907956838608,
        -0.007289212197065353,
        -0.05892723426222801,
        -0.03972616791725159,
        0.055790599435567856,
        -0.02714925818145275,
        -0.03198462352156639,
        0.03297867253422737,
        0.06508832424879074,
        0.064407579600811,
        0.012197169475257397,
        -0.0014635204570367932,
        0.027526797726750374,
        -0.059702761471271515,
        0.042514897882938385,
        0.020952625200152397,
        0.0557812936604023,
        0.06226711720228195,
        0.0653468444943428,
        0.027772990986704826,
        -0.047526780515909195,
        -0.039888493716716766,
        -0.0633532777428627,
        -0.06525913625955582,
        -0.021275928243994713,
        -0.037917133420705795,
        0.05724983662366867,
        -0.02624993398785591,
        -0.0587652362883091,
        0.061014700680971146,
        0.016250193119049072,
        -0.06459348648786545,
        0.06443106383085251,
        0.036409661173820496,
        0.03858441114425659,
        -0.061776433140039444,
        -0.056080032140016556,
        0.038497425615787506,
        0.06033245474100113,
        0.05570496618747711,
        0.056206025183200836,
        0.05589393526315689,
        0.0467359684407711,
        -0.05519656091928482,
        0.041529200971126556,
        -0.053974803537130356,
        -0.03685426339507103,
        0.0642881765961647,
        -0.05426962673664093,
        0.04415884613990784,
        0.061486318707466125,
        -0.008576429449021816,
        0.057693421840667725,
        0.028179150074720383,
        -0.046475984156131744,
        -0.02983504720032215,
        0.028556963428854942,
        0.0032560352701693773,
        0.04371962323784828,
        0.01669735461473465,
        0.0638207197189331,
        0.03152666240930557,
        -0.049264248460531235,
        0.01087955292314291,
        -0.02075882814824581,
        -0.05839824676513672,
        -0.05582394823431969,
        0.06274645030498505,
        0.004093479830771685,
        -0.05426422134041786,
        -0.029125750064849854,
        0.04728073254227638,
        0.000018805472791427746,
        0.044104691594839096,
        -0.004042929504066706,
        -0.004854045808315277,
        -0.06558489054441452,
        -0.03064456768333912,
        -0.043259259313344955,
        0.03973349556326866,
        -0.04812220484018326,
        0.047784604132175446,
        -0.060147710144519806,
        0.05831029638648033,
        -0.06363067030906677,
        0.025230541825294495,
        -0.05721050500869751,
        0.05827026069164276,
        0.007134622894227505,
        0.034582722932100296,
        0.054538924247026443,
        0.0657249391078949,
        -0.04700290039181709,
        0.04749257490038872,
        -0.006369434297084808,
        -0.02071595937013626,
        0.057084523141384125,
        -0.03235066682100296,
        0.02832144685089588,
        0.022566581144928932,
        0.03353377431631088,
        -0.0271011795848608,
        0.03308048099279404,
        -0.021940279752016068,
        -0.01777007430791855,
        -0.008261912502348423,
        0.06153939291834831,
        -0.0632634088397026,
        -0.008573900908231735,
        0.030161529779434204,
        -0.05047660320997238,
        -0.035967595875263214,
        0.06561651825904846,
        -0.032733507454395294,
        0.05037461593747139,
        0.06436425447463989,
        -0.0002354298922000453,
        -0.042231954634189606,
        0.052267514169216156,
        -0.0035460488870739937,
        0.05282929912209511,
        -0.01928546652197838,
        0.013596703298389912,
        0.008170805871486664,
        -0.013019799254834652,
        0.0027384820859879255,
        0.00846754852682352,
        -0.055941347032785416,
        -0.06570623070001602,
        -0.05572041869163513,
        0.04800854250788689,
        0.057435959577560425,
        -0.06480991840362549,
        0.03131166473031044,
        0.023493601009249687,
        -0.012018343433737755,
        0.020076092332601547,
        0.030334359034895897,
        0.0021607333328574896,
        -0.0290655717253685,
        0.027028432115912437,
        -0.05265912786126137,
        0.025738297030329704,
        0.05135635286569595,
        0.02172996662557125,
        0.023902030661702156,
        0.02429516799747944,
        0.06563615798950195,
        -0.007764220703393221,
        0.06237098202109337,
        0.052183523774147034,
        0.03930475562810898,
        0.03649198263883591,
        0.060863420367240906,
        -0.0005048396997153759,
        0.03479611128568649,
        -0.019075796008110046,
        0.03375730663537979,
        -0.010829971171915531,
        -0.007565170060843229,
        -0.015781722962856293,
        -0.024119896814227104,
        -0.04683660343289375,
        0.010335143655538559,
        0.05213790014386177,
        -0.021385949105024338,
        -0.04105764627456665,
        0.060041073709726334,
        -0.0011965282028540969,
        0.014832836575806141,
        0.0645277202129364,
        -0.04241692274808884,
        0.056811828166246414,
        -0.04547036811709404,
        0.04341702163219452,
        0.0408596508204937,
        -0.014633171260356903,
        -0.05811348930001259,
        0.03053383156657219,
        -0.052281517535448074,
        -0.056077904999256134,
        -0.06416827440261841,
        -0.06353107839822769,
        -0.045944493263959885,
        -0.06436233222484589,
        -0.05515933781862259,
        0.059336207807064056,
        -0.0463792160153389,
        -0.04558948799967766,
        0.020763125270605087,
        -0.006544462870806456,
        0.06087809056043625,
        -0.03945048153400421,
        -0.05058170482516289,
        0.046193357557058334,
        0.05310662463307381,
        0.058345042169094086,
        0.06406479328870773,
        0.06041330471634865,
        -0.05100356787443161,
        0.022039787843823433,
        -0.0068110134452581406,
        -0.05539451166987419,
        0.06523983180522919,
        -0.04261719062924385,
        0.05385388806462288,
        0.034761685878038406,
        -0.0430714450776577,
        -0.05075281485915184,
        -0.05130775645375252,
        -0.03683490306138992,
        -0.04880903288722038,
        0.035740870982408524,
        -0.061456914991140366,
        0.04367128014564514,
        0.06510140001773834,
        -0.059029195457696915,
        0.00754443509504199,
        0.055979710072278976,
        0.06375524401664734,
        0.05333239212632179,
        -0.05111013352870941,
        -0.0017055002972483635,
        -0.02366851083934307,
        0.04101360961794853,
        0.059814512729644775,
        -0.05737883597612381,
        0.05885010212659836,
        0.05707912519574165,
        0.014523394405841827,
        0.056506913155317307,
        -0.006229483522474766,
        -0.04324724152684212,
        -0.03967532142996788,
        0.04801798611879349,
        -0.06473777443170547,
        0.036519382148981094,
        -0.059725672006607056,
        0.059735413640737534,
        -0.048295892775058746,
        -0.01864926889538765,
        -0.05373580381274223,
        -0.04914111644029617,
        -0.03024192340672016,
        -0.03553275763988495,
        0.00941629521548748,
        -0.05937698483467102,
        -0.03570495918393135,
        -0.04095487296581268,
        0.0378037765622139,
        -0.036650802940130234,
        -0.04891826584935188,
        -0.05231786519289017,
        0.042402639985084534,
        0.022350357845425606,
        0.05438264459371567,
        -0.04469679668545723,
        0.03592067211866379,
        0.050842445343732834,
        -0.018931588158011436,
        -0.03649858012795448,
        0.025069788098335266,
        0.030824920162558556,
        0.01774488389492035,
        0.035852327942848206,
        0.04766448587179184,
        0.03836030513048172,
        -0.014315820299088955,
        0.039978209882974625,
        -0.010201600380241871,
        0.052699558436870575,
        -0.06349053233861923,
        0.053326163440942764,
        0.03246196731925011,
        0.0054917545057833195,
        -0.0076034944504499435,
        0.027937835082411766,
        0.05981102213263512,
        0.04782683029770851,
        -0.04629605636000633,
        0.051750943064689636,
        0.061564527451992035,
        0.041769515722990036,
        0.02113840915262699,
        -0.05081707984209061,
        -0.014616868458688259,
        0.019778892397880554,
        -0.054788507521152496,
        -0.06111345812678337,
        0.018060879781842232,
        -0.020130107179284096,
        0.04450474679470062,
        0.0074096848256886005,
        0.056423112750053406,
        -0.03688427805900574,
        -0.057050902396440506,
        -0.04234088957309723,
        0.06257093697786331,
        -0.004459837451577187,
        0.05766879767179489,
        -0.062379151582717896,
        -0.0255561713129282,
        -0.012010268867015839,
        0.004198776558041573,
        -0.00950741209089756,
        -0.003931811079382896,
        -0.005262466613203287,
        -0.05023602023720741,
        0.06572898477315903,
        0.06271065771579742,
        0.03588412329554558,
        0.007849599234759808,
        -0.03702240064740181,
        -0.06443891674280167,
        0.04882149025797844,
        0.06498274952173233,
        -0.020626969635486603,
        0.06483417749404907,
        -0.016842300072312355,
        -0.04707939922809601,
        -0.012448521330952644,
        -0.06348106265068054,
        -0.03206000477075577,
        -0.04048585891723633,
        -0.0528387576341629,
        -0.01758146658539772,
        0.06135674938559532,
        0.016104212030768394,
        -0.018127184361219406,
        -0.055977653712034225,
        -0.061204973608255386,
        -0.04220539703965187,
        0.05262281373143196,
        -0.00826017465442419,
        -0.06552238762378693,
        -0.054382674396038055,
        -0.01942039281129837,
        0.03338298946619034,
        -0.047000136226415634,
        0.06058618798851967,
        -0.013205309398472309,
        -0.05624014139175415,
        -0.05650203302502632,
        -0.008343110792338848,
        0.01820504292845726,
        0.016802359372377396,
        -0.04849876090884209,
        -0.05815160647034645,
        -0.04830791801214218,
        0.04812370240688324,
        0.018743544816970825,
        -0.008003003895282745,
        -0.05569556728005409,
        0.041880927979946136,
        -0.0605553463101387,
        0.009132820181548595,
        0.02075367420911789,
        -0.04764223098754883,
        0.059538573026657104,
        -0.008834300562739372,
        -0.04180127754807472,
        -0.002436078619211912,
        -0.041764285415410995,
        -0.048965130001306534,
        -0.06250855326652527,
        0.059857919812202454,
        -0.052408602088689804,
        0.014714795164763927,
        -0.049686770886182785,
        0.035560186952352524,
        -0.04673655703663826,
        0.0010952451266348362,
        0.061594631522893906,
        0.003958575427532196,
        0.0576934777200222,
        0.045463960617780685,
        0.030286647379398346,
        0.05861670523881912,
        -0.05628790333867073,
        0.059544723480939865,
        0.060484107583761215,
        0.004967613611370325,
        -0.009842277504503727,
        -0.006556390784680843,
        0.017481397837400436,
        -0.0031117144972085953,
        -0.06063937023282051,
        -0.015689603984355927,
        -0.055037178099155426,
        -0.0506477952003479,
        0.034384794533252716,
        0.06238911673426628,
        -0.044138748198747635,
        -0.0548379123210907,
        0.06403084099292755,
        0.03990160673856735,
        0.06011182442307472,
        -0.012825867161154747,
        0.05123937875032425,
        -0.015178079716861248,
        -0.06141946464776993,
        0.05976186320185661,
        0.06444095075130463,
        -0.011325787752866745,
        0.058916255831718445,
        -0.06510293483734131,
        -0.03273829072713852,
        -0.031370531767606735,
        0.05022149905562401,
        0.051768362522125244,
        -0.058380987495183945,
        -0.05797421932220459,
        -0.04117657244205475,
        -0.057744912803173065,
        0.028024155646562576,
        0.025092879310250282,
        0.053566090762615204,
        0.06205141916871071,
        0.0414457768201828,
        -0.038497358560562134,
        -0.05258263275027275,
        -0.03547405079007149,
        0.016356943175196648,
        0.05837998539209366,
        -0.03234025835990906,
        -0.026377707719802856,
        0.04587914049625397,
        0.04393158107995987,
        0.037446826696395874,
        0.054716967046260834,
        0.05640945956110954,
        -0.06359310448169708,
        -0.022601526230573654,
        -0.05074337497353554,
        -0.022121107205748558,
        0.051801979541778564,
        -0.05585073307156563,
        0.06274612247943878,
        0.007031880784779787,
        0.03318341448903084,
        -0.06237693503499031,
        0.038187265396118164,
        0.06029724329710007
    ],
    [
        -0.020902466028928757,
        0.027288008481264114,
        0.04494895786046982,
        0.013611399568617344,
        -0.04235401004552841,
        0.0649895966053009,
        0.015567735768854618,
        -0.06343655288219452,
        0.062057267874479294,
        0.05720171332359314,
        0.04371926188468933,
        -0.00603528693318367,
        -0.036715373396873474,
        -0.02950011007487774,
        -0.054930273443460464,
        0.06410569697618484,
        0.029142729938030243,
        0.06324281543493271,
        -0.015400988981127739,
        -0.06531614810228348,
        0.052700188010931015,
        0.06037785857915878,
        0.041668813675642014,
        0.01973167061805725,
        -0.014244714751839638,
        0.04984597861766815,
        0.05722847580909729,
        -0.02452998422086239,
        -0.024110281839966774,
        0.06485654413700104,
        0.02117510326206684,
        -0.009891187772154808,
        0.043414242565631866,
        -0.06250688433647156,
        0.04974004253745079,
        0.05040619522333145,
        -0.05779410898685455,
        -0.0334082767367363,
        0.00548921525478363,
        0.007610083092004061,
        0.017160242423415184,
        0.030692584812641144,
        0.027400363236665726,
        0.02050168439745903,
        0.05921824648976326,
        0.042748913168907166,
        -0.04773920029401779,
        0.003524616127833724,
        0.06055859848856926,
        -0.033038537949323654,
        0.06083022803068161,
        0.06365815550088882,
        -0.03846526890993118,
        0.007084994576871395,
        0.05495963618159294,
        -0.00012948918447364122,
        -0.0380953885614872,
        0.06205575913190842,
        -0.011943797580897808,
        -0.029449254274368286,
        -0.05392385274171829,
        -0.008064468391239643,
        -0.04438016936182976,
        0.06470642238855362,
        -0.057195164263248444,
        -0.0341036319732666,
        -0.030926499515771866,
        -0.019813021644949913,
        -0.04101738706231117,
        -0.042693253606557846,
        0.007652278523892164,
        -0.06274368613958359,
        -0.03416610136628151,
        0.0562787763774395,
        -0.0247026514261961,
        -0.026185182854533195,
        0.03160605952143669,
        0.06463456153869629,
        0.06454771757125854,
        0.029161887243390083,
        -0.0046656206250190735,
        0.022289009764790535,
        -0.060689136385917664,
        0.042214978486299515,
        0.024545473977923393,
        0.05642666295170784,
        0.062489766627550125,
        0.06504614651203156,
        0.020533055067062378,
        -0.047448597848415375,
        -0.03985397517681122,
        -0.06302433460950851,
        -0.06507232785224915,
        -0.004893288481980562,
        -0.02507469803094864,
        0.057102933526039124,
        -0.025471016764640808,
        -0.05719253420829773,
        0.05966956540942192,
        0.019701197743415833,
        -0.06425951421260834,
        0.06406410038471222,
        0.035236284136772156,
        0.031005211174488068,
        -0.0626223087310791,
        -0.04923740029335022,
        0.03107055462896824,
        0.05651417747139931,
        0.054671142250299454,
        0.05777560919523239,
        0.06110326200723648,
        0.05373883619904518,
        -0.05581570789217949,
        0.03949355706572533,
        -0.05964182689785957,
        -0.049225881695747375,
        0.06419665366411209,
        -0.0550101175904274,
        0.04773138836026192,
        0.06185772642493248,
        -0.015616323798894882,
        0.057477135211229324,
        0.028235143050551414,
        -0.042001716792583466,
        -0.022139087319374084,
        0.02927354909479618,
        -0.0046828617341816425,
        0.05137384682893753,
        0.011082582175731659,
        0.06201734393835068,
        0.005645056255161762,
        -0.050006669014692307,
        0.026999615132808685,
        -0.025366734713315964,
        -0.057602111250162125,
        -0.057249899953603745,
        0.06200969219207764,
        -0.009114747866988182,
        -0.0441054068505764,
        -0.02098415046930313,
        0.05339062586426735,
        -0.010984609834849834,
        0.043445516377687454,
        -0.013557100668549538,
        0.009785924106836319,
        -0.06536860764026642,
        -0.019229797646403313,
        -0.03943544253706932,
        0.03441953286528587,
        -0.052963271737098694,
        0.0537981241941452,
        -0.059779416769742966,
        0.040549859404563904,
        -0.06173507869243622,
        0.03566764295101166,
        -0.05714229494333267,
        0.05799337849020958,
        -0.00592069374397397,
        0.030164092779159546,
        0.05247514694929123,
        0.06546897441148758,
        -0.042894717305898666,
        0.0458470918238163,
        0.005947427358478308,
        -0.02171194553375244,
        0.04754609987139702,
        -0.03309795632958412,
        0.03931776061654091,
        0.027993619441986084,
        0.03707701712846756,
        -0.04583374783396721,
        0.041546229273080826,
        -0.028903275728225708,
        -0.018136126920580864,
        -0.035406485199928284,
        0.06133764982223511,
        -0.0633184090256691,
        -0.017060482874512672,
        0.02691633440554142,
        -0.04494745284318924,
        -0.03148484602570534,
        0.06532368063926697,
        -0.04733020067214966,
        0.058777593076229095,
        0.06402210891246796,
        0.01120796799659729,
        -0.044803839176893234,
        0.051671724766492844,
        -0.009952440857887268,
        0.03614877909421921,
        -0.029736289754509926,
        0.01104497816413641,
        -0.0014842060627415776,
        -0.015620894730091095,
        0.01164931058883667,
        0.017063211649656296,
        -0.051001597195863724,
        -0.06545713543891907,
        -0.05665101855993271,
        0.043954379856586456,
        0.060460641980171204,
        -0.06442000716924667,
        0.04312274605035782,
        0.036151621490716934,
        -0.0029853698797523975,
        0.014663668349385262,
        0.03677811101078987,
        0.004826303571462631,
        -0.03988560289144516,
        0.016474662348628044,
        -0.05168335884809494,
        0.014331473037600517,
        0.049420926719903946,
        0.004783314652740955,
        0.020777283236384392,
        0.02300863154232502,
        0.06543932110071182,
        0.002531612990424037,
        0.062248796224594116,
        0.05464668944478035,
        0.03503851592540741,
        0.027380798012018204,
        0.06089908629655838,
        0.011327852495014668,
        0.04853171110153198,
        -0.05130717530846596,
        0.03069387935101986,
        -0.019914383068680763,
        -0.009723995812237263,
        -0.016732560470700264,
        -0.014091070741415024,
        -0.05545582249760628,
        0.021035512909293175,
        0.05092895030975342,
        -0.015349877066910267,
        -0.03442201763391495,
        0.05890367552638054,
        -0.0021178843453526497,
        0.021746164187788963,
        0.0643358826637268,
        -0.04441080987453461,
        0.053286001086235046,
        -0.04457149654626846,
        0.04584275186061859,
        0.037028949707746506,
        -0.01815306767821312,
        -0.05575263500213623,
        0.03436488285660744,
        -0.04881531372666359,
        -0.05717400833964348,
        -0.06321509182453156,
        -0.06356983631849289,
        -0.0438845157623291,
        -0.06415815651416779,
        -0.049098096787929535,
        0.055569835007190704,
        -0.036966364830732346,
        -0.05194615572690964,
        0.013939425349235535,
        -0.0042099361307919025,
        0.06128288060426712,
        -0.0419936440885067,
        -0.04456370323896408,
        0.048692379146814346,
        0.05613129958510399,
        0.0598149374127388,
        0.06288203597068787,
        0.053912535309791565,
        -0.05078393965959549,
        0.016853978857398033,
        -0.009994789958000183,
        -0.055121712386608124,
        0.06505338847637177,
        -0.05267513915896416,
        0.053945884108543396,
        0.035696037113666534,
        -0.04044583439826965,
        -0.04602515324950218,
        -0.053002554923295975,
        -0.04086709022521973,
        -0.04857189580798149,
        0.024815047159790993,
        -0.06237407773733139,
        0.04413215443491936,
        0.06483868509531021,
        -0.05521206557750702,
        0.004105613566935062,
        0.0569007582962513,
        0.0638994574546814,
        0.05679452791810036,
        -0.052081771194934845,
        -0.011710019782185555,
        -0.023268794640898705,
        0.03886524587869644,
        0.057240527123212814,
        -0.055343955755233765,
        0.055138058960437775,
        0.054782308638095856,
        0.024053554981946945,
        0.05770023167133331,
        -0.005833515897393227,
        -0.03481519594788551,
        -0.036384861916303635,
        0.052374180406332016,
        -0.06492889672517776,
        0.03312614560127258,
        -0.057510003447532654,
        0.06064901500940323,
        -0.04636560007929802,
        -0.043737269937992096,
        -0.05662300065159798,
        -0.04993193596601486,
        -0.023510335013270378,
        -0.03456597775220871,
        0.016324659809470177,
        -0.058437228202819824,
        -0.03018723800778389,
        -0.037778858095407486,
        0.0414838083088398,
        -0.036000628024339676,
        -0.0487387590110302,
        -0.0508919432759285,
        0.03576725721359253,
        0.03524012491106987,
        0.05664321780204773,
        -0.046903762966394424,
        0.03125675022602081,
        0.04087722674012184,
        -0.02885156124830246,
        -0.03487410768866539,
        0.02666146494448185,
        0.03950432315468788,
        0.03305910900235176,
        0.042851392179727554,
        0.0471816323697567,
        0.026600809767842293,
        -0.017528533935546875,
        0.05096850171685219,
        -0.005198618397116661,
        0.04740263521671295,
        -0.06252705305814743,
        0.05441588908433914,
        0.04288560897111893,
        0.001785558881238103,
        -0.009764336980879307,
        0.02702835388481617,
        0.06092764437198639,
        0.04766102880239487,
        -0.04087810590863228,
        0.049676619470119476,
        0.06154020503163338,
        0.03920985758304596,
        0.0218211580067873,
        -0.046130768954753876,
        -0.022571291774511337,
        0.02777642011642456,
        -0.04659796506166458,
        -0.0612613782286644,
        0.010399154387414455,
        -0.024813203141093254,
        0.048241790384054184,
        0.010137331672012806,
        0.0591464601457119,
        -0.02764919027686119,
        -0.05927314609289169,
        -0.03474187105894089,
        0.06259345263242722,
        -0.014188610017299652,
        0.060341160744428635,
        -0.061737701296806335,
        -0.025164080783724785,
        -0.017674515023827553,
        0.015844235196709633,
        -0.022670159116387367,
        -0.0033577687572687864,
        -0.005361132323741913,
        -0.048671871423721313,
        0.06546901166439056,
        0.061699558049440384,
        0.042656004428863525,
        0.022836826741695404,
        -0.04159334674477577,
        -0.06362738460302353,
        0.05176848918199539,
        0.06444243341684341,
        -0.020669201388955116,
        0.06499180942773819,
        -0.018781574442982674,
        -0.0360652394592762,
        -0.02013266459107399,
        -0.06378787755966187,
        -0.030787594616413116,
        -0.0412805899977684,
        -0.05725102871656418,
        -0.009167945012450218,
        0.06178881600499153,
        0.016868336126208305,
        -0.01455132756382227,
        -0.055801406502723694,
        -0.06182629242539406,
        -0.04980247840285301,
        0.05544470250606537,
        -0.01485632173717022,
        -0.06522610038518906,
        -0.05710721015930176,
        -0.023288700729608536,
        0.03226345404982567,
        -0.04525616019964218,
        0.05972976237535477,
        -0.011814940720796585,
        -0.05271940678358078,
        -0.05842163413763046,
        -0.0056059998460114,
        0.03585835173726082,
        0.028456415981054306,
        -0.05041118338704109,
        -0.05820855870842934,
        -0.023913316428661346,
        0.05159291625022888,
        0.03739343583583832,
        -0.005466383416205645,
        -0.052544690668582916,
        0.03942553699016571,
        -0.060390621423721313,
        0.029069039970636368,
        0.00018317053036298603,
        -0.044967442750930786,
        0.0597982183098793,
        -0.013936193659901619,
        -0.04055965319275856,
        -0.006386945489794016,
        -0.04039160907268524,
        -0.053935274481773376,
        -0.062117256224155426,
        0.06118975207209587,
        -0.05315675586462021,
        0.005159731488674879,
        -0.05275264009833336,
        0.026631303131580353,
        -0.04372735321521759,
        0.0008709671092219651,
        0.059377122670412064,
        0.016586873680353165,
        0.05784052237868309,
        0.017118411138653755,
        0.032709188759326935,
        0.055490642786026,
        -0.056787408888339996,
        0.058692578226327896,
        0.054944753646850586,
        0.019477881491184235,
        -0.0029101574327796698,
        -0.003304167417809367,
        0.011460698209702969,
        -0.006313568912446499,
        -0.05998365581035614,
        -0.03217671811580658,
        -0.05421646684408188,
        -0.05437619239091873,
        0.03401589021086693,
        0.05569155886769295,
        -0.04459284245967865,
        -0.0582449845969677,
        0.06319313496351242,
        0.041988883167505264,
        0.06079474464058876,
        -0.007863275706768036,
        0.0528317429125309,
        -0.021498095244169235,
        -0.06061697378754616,
        0.061113931238651276,
        0.06515070796012878,
        -0.008623502217233181,
        0.059281110763549805,
        -0.06461203098297119,
        -0.02657061070203781,
        -0.03975498676300049,
        0.04976572096347809,
        0.05504291504621506,
        -0.057533856481313705,
        -0.05699385702610016,
        -0.04366053640842438,
        -0.05650469288229942,
        0.02551894448697567,
        -0.005339124705642462,
        0.05066407844424248,
        0.05793243646621704,
        0.04464124143123627,
        -0.040477246046066284,
        -0.058484334498643875,
        -0.04394974559545517,
        0.02106849104166031,
        0.05765210837125778,
        -0.04064817354083061,
        -0.021124454215168953,
        0.04998324438929558,
        0.042951542884111404,
        0.0419711098074913,
        0.05325324833393097,
        0.05812988057732582,
        -0.0602564811706543,
        -0.02751460298895836,
        -0.0376250259578228,
        -0.02416779100894928,
        0.04625652730464935,
        -0.052331577986478806,
        0.06251692026853561,
        -0.00277695176191628,
        0.022524237632751465,
        -0.060451362282037735,
        0.05080334469676018,
        0.06265348196029663
    ],
    [
        -0.03341134265065193,
        0.046088580042123795,
        0.018696947023272514,
        -0.026038281619548798,
        -0.0511401891708374,
        0.05462746322154999,
        0.05208791792392731,
        -0.043898843228816986,
        0.05418022349476814,
        0.053866565227508545,
        0.04601692035794258,
        0.038385920226573944,
        -0.05180836096405983,
        -0.030784685164690018,
        -0.04866204038262367,
        0.054233305156230927,
        0.03431857377290726,
        0.053872086107730865,
        0.05150644853711128,
        -0.05464480444788933,
        0.03648781031370163,
        0.05040166899561882,
        0.054015517234802246,
        0.052755750715732574,
        -0.05225478485226631,
        0.04939411208033562,
        0.04820924252271652,
        0.03372615575790405,
        -0.05192539840936661,
        0.05464307218790054,
        -0.031043624505400658,
        0.030652152374386787,
        0.054581742733716965,
        -0.05416804552078247,
        0.043061066418886185,
        0.029728464782238007,
        -0.05242890492081642,
        -0.04902634024620056,
        0.04763084277510643,
        0.04530251398682594,
        0.009036888368427753,
        0.03713756799697876,
        0.04926784336566925,
        0.052563417702913284,
        0.05460461229085922,
        -0.04929289221763611,
        -0.03286591172218323,
        0.05418810620903969,
        0.05418160557746887,
        -0.05375005677342415,
        0.05433632433414459,
        0.05152023583650589,
        -0.041328251361846924,
        -0.050949495285749435,
        0.04778138920664787,
        0.05236995592713356,
        -0.04801271855831146,
        0.05447595566511154,
        -0.05185908079147339,
        -0.004446806851774454,
        -0.05194734409451485,
        -0.006848967634141445,
        -0.04368794709444046,
        0.05315924808382988,
        -0.02718028612434864,
        0.0021931494120508432,
        -0.052051372826099396,
        -0.039701152592897415,
        0.04278024286031723,
        -0.05135812982916832,
        0.04688749834895134,
        -0.04738610237836838,
        -0.052074700593948364,
        0.054217781871557236,
        0.007574132643640041,
        -0.0402962900698185,
        0.025645343586802483,
        0.054513119161129,
        0.05464472249150276,
        0.05002649128437042,
        -0.03905028477311134,
        -0.04801710322499275,
        -0.054075583815574646,
        0.046213049441576004,
        -0.027004271745681763,
        0.051427144557237625,
        0.0402529314160347,
        0.05464721471071243,
        -0.045221514999866486,
        -0.05433893948793411,
        -0.05425621196627617,
        -0.05463781952857971,
        -0.05448155850172043,
        -0.04463420435786247,
        0.015351960435509682,
        0.01781955547630787,
        -0.04092615842819214,
        -0.05167509242892265,
        0.053746696561574936,
        0.045282941311597824,
        -0.05454917997121811,
        0.05460979416966438,
        0.02305961586534977,
        0.041668206453323364,
        -0.05463775619864464,
        -0.023506229743361473,
        0.04363264515995979,
        0.053106121718883514,
        0.0538092777132988,
        -0.0008636273560114205,
        0.05463789775967598,
        0.025473926216363907,
        -0.026546552777290344,
        0.043876390904188156,
        -0.04335324466228485,
        -0.04853363707661629,
        0.05369412153959274,
        -0.049951132386922836,
        -0.04330334812402725,
        0.03525112196803093,
        -0.031142588704824448,
        -0.009572447277605534,
        -0.02172187529504299,
        -0.05291241407394409,
        -0.030810244381427765,
        0.05348675698041916,
        0.03788616880774498,
        0.05440681427717209,
        -0.03870612010359764,
        0.054633963853120804,
        0.04663389176130295,
        -0.045908816158771515,
        0.03095822222530842,
        0.017423564568161964,
        -0.05106598883867264,
        -0.05050414055585861,
        0.054639555513858795,
        -0.05243010073900223,
        0.0217756200581789,
        -0.02479366958141327,
        -0.030229870229959488,
        -0.019879264757037163,
        0.016315991058945656,
        -0.01752294972538948,
        -0.051829323172569275,
        -0.05464794486761093,
        -0.05355452001094818,
        -0.051324691623449326,
        0.030630700290203094,
        -0.05462322384119034,
        0.0031076797749847174,
        -0.05355336144566536,
        0.04406041279435158,
        -0.048980966210365295,
        -0.01003334578126669,
        -0.048211719840765,
        0.05426402762532234,
        -0.036130379885435104,
        0.052429888397455215,
        0.04880348592996597,
        0.0546480193734169,
        -0.05409907549619675,
        0.05123816058039665,
        -0.014836405403912067,
        -0.05433029308915138,
        0.051735758781433105,
        -0.010616227053105831,
        0.02018849179148674,
        0.010270335711538792,
        0.046316150575876236,
        -0.05325720086693764,
        0.04751056060194969,
        -0.048253051936626434,
        0.04952709749341011,
        -0.05320203676819801,
        0.04628276452422142,
        -0.04647631198167801,
        -0.022613493725657463,
        0.046734362840652466,
        -0.02967706508934498,
        -0.049780912697315216,
        0.05456509441137314,
        -0.03509562835097313,
        0.05164756998419762,
        0.0545269250869751,
        0.01315602008253336,
        -0.02811705693602562,
        0.032739534974098206,
        0.02506285533308983,
        0.0482330285012722,
        -0.04625263065099716,
        0.012791788205504417,
        0.03340453654527664,
        0.009402170777320862,
        0.023917846381664276,
        0.014700350351631641,
        -0.03625978156924248,
        -0.054648011922836304,
        -0.05188827961683273,
        0.044669415801763535,
        0.05376088619232178,
        -0.05463949218392372,
        0.05124541372060776,
        0.006459564436227083,
        0.05092787370085716,
        0.0006592230638489127,
        0.050449274480342865,
        0.021295517683029175,
        -0.047019388526678085,
        0.004213929641991854,
        -0.04279383271932602,
        -0.004796645138412714,
        0.05380959436297417,
        0.04652032628655434,
        0.04628858342766762,
        -0.003211228409782052,
        0.05464771017432213,
        0.011420212686061859,
        0.053493935614824295,
        0.05266445130109787,
        0.03918832540512085,
        0.011744354851543903,
        0.05463185906410217,
        0.004310747608542442,
        0.03947746381163597,
        -0.05016796290874481,
        0.0466635636985302,
        -0.04881185665726662,
        0.021146951243281364,
        -0.02346157841384411,
        -0.053076084703207016,
        -0.04701585695147514,
        0.026796163991093636,
        0.05400259420275688,
        -0.0013563398970291018,
        -0.054582905024290085,
        0.052276235073804855,
        0.03930079564452171,
        0.023827437311410904,
        0.05451970919966698,
        -0.02191305160522461,
        0.0198478065431118,
        0.04441331326961517,
        0.0245461817830801,
        0.018632754683494568,
        0.004652815405279398,
        0.024027448147535324,
        0.050256870687007904,
        -0.04718512296676636,
        -0.051623694598674774,
        -0.05437890440225601,
        -0.05434054136276245,
        -0.05100538954138756,
        -0.05443957448005676,
        0.00019297558174002916,
        0.05188469961285591,
        0.03626968711614609,
        -0.008171807043254375,
        0.036244384944438934,
        0.01719188131392002,
        0.035339999943971634,
        -0.008721823804080486,
        -0.041571248322725296,
        0.04602859914302826,
        0.0471721813082695,
        0.05430929735302925,
        0.05464441329240799,
        0.05307798832654953,
        -0.023402079939842224,
        0.02093678154051304,
        -0.04994473606348038,
        -0.04863801598548889,
        0.0541786290705204,
        -0.05285489931702614,
        0.05451410263776779,
        0.04960431903600693,
        -0.05222460255026817,
        -0.05367933586239815,
        -0.0441674068570137,
        -0.04767533019185066,
        -0.048307303339242935,
        0.05311319977045059,
        -0.037402328103780746,
        -0.04217347502708435,
        0.054644085466861725,
        -0.05096738412976265,
        -0.0038658117409795523,
        0.04661301150918007,
        0.05434860289096832,
        0.0518442839384079,
        -0.054147619754076004,
        -0.044539034366607666,
        -0.02829984575510025,
        -0.027412131428718567,
        0.0502488911151886,
        -0.048189375549554825,
        0.05359261482954025,
        0.022542987018823624,
        0.04518652707338333,
        0.05147967115044594,
        -0.05151932314038277,
        -0.05250772088766098,
        -0.02740517072379589,
        0.05333622545003891,
        -0.05463787540793419,
        0.04773498699069023,
        -0.052063483744859695,
        0.05285609886050224,
        -0.05274268239736557,
        0.03279208391904831,
        -0.053403519093990326,
        -0.044386040419340134,
        0.011602858081459999,
        -0.03751978278160095,
        0.008470766246318817,
        -0.051749732345342636,
        -0.05230700969696045,
        0.022773414850234985,
        0.050647832453250885,
        -0.005537083838135004,
        -0.03453001007437706,
        -0.054009661078453064,
        0.053470637649297714,
        -0.014367777854204178,
        0.0527380034327507,
        -0.0008974113152362406,
        0.05413895100355148,
        0.015804937109351158,
        -0.05409467592835426,
        -0.052091311663389206,
        0.04582015052437782,
        0.05361625924706459,
        0.05281604081392288,
        0.05423605814576149,
        0.0406077578663826,
        0.017078084871172905,
        -0.026880918070673943,
        0.05059708282351494,
        -0.021966025233268738,
        0.04694676771759987,
        -0.054636191576719284,
        0.023539621382951736,
        0.054595667868852615,
        0.03556885942816734,
        -0.017793554812669754,
        0.011284628883004189,
        0.05352111905813217,
        0.048927389085292816,
        -0.03619221970438957,
        0.03390013799071312,
        0.054488688707351685,
        0.03618398308753967,
        0.0517255999147892,
        -0.053735654801130295,
        -0.04033990949392319,
        -0.03234554082155228,
        -0.05370130389928818,
        -0.05442775785923004,
        -0.05208004266023636,
        0.05072751268744469,
        0.04718952253460884,
        0.04487653076648712,
        0.04995020851492882,
        -0.0536775179207325,
        -0.05410192906856537,
        -0.05021795630455017,
        0.054497163742780685,
        0.011271905153989792,
        0.05407639220356941,
        -0.05441373586654663,
        -0.03966089338064194,
        -0.04906186833977699,
        -0.007984256371855736,
        -0.05345583334565163,
        0.05168299004435539,
        0.030333735048770905,
        0.01472598034888506,
        0.0546480193734169,
        0.05383129417896271,
        -0.02064165286719799,
        -0.006859025917947292,
        0.02792460098862648,
        -0.05334485322237015,
        0.03267526626586914,
        0.054605938494205475,
        -0.05212029814720154,
        0.05464021489024162,
        0.031809598207473755,
        -0.05085591599345207,
        -0.04067043960094452,
        -0.05210298299789429,
        -0.041228171437978745,
        -0.053241148591041565,
        -0.05361301451921463,
        0.021760661154985428,
        0.0534341037273407,
        0.02938130684196949,
        -0.053911108523607254,
        -0.05464119464159012,
        -0.05366184934973717,
        -0.051415760070085526,
        0.05123215541243553,
        -0.049070581793785095,
        -0.054500825703144073,
        -0.053918562829494476,
        0.00751352496445179,
        0.04735129699110985,
        -0.043412819504737854,
        0.054549966007471085,
        -0.05203338712453842,
        -0.052173204720020294,
        -0.04914793372154236,
        -0.05093321204185486,
        0.027338096871972084,
        0.036763995885849,
        -0.050208527594804764,
        -0.052268676459789276,
        -0.05415435507893562,
        0.05110882595181465,
        0.043947018682956696,
        -0.004274378065019846,
        -0.05366998910903931,
        0.042951248586177826,
        -0.05463573709130287,
        -0.010289615020155907,
        0.04103296622633934,
        -0.04697416350245476,
        0.05446990951895714,
        -0.05189859867095947,
        -0.04433828964829445,
        -0.044479332864284515,
        -0.051774680614471436,
        0.045382656157016754,
        -0.05444207042455673,
        0.054294753819704056,
        -0.04746672511100769,
        0.013644203543663025,
        -0.03864522650837898,
        0.03332110494375229,
        -0.0539851188659668,
        -0.0499231293797493,
        0.05285809189081192,
        -0.007872110232710838,
        0.05386146157979965,
        -0.046515896916389465,
        0.04229672625660896,
        0.05058588460087776,
        -0.053049441426992416,
        0.0453503355383873,
        0.043386079370975494,
        0.053090233355760574,
        0.02124958485364914,
        -0.032000742852687836,
        -0.03995417803525925,
        -0.000527937023434788,
        -0.05398992449045181,
        -0.04921749606728554,
        -0.05374942719936371,
        -0.04806148260831833,
        0.04417920112609863,
        0.05457115173339844,
        -0.053933680057525635,
        0.007760226260870695,
        0.05417869612574577,
        0.053251512348651886,
        0.05283721536397934,
        -0.015064447186887264,
        0.05407925695180893,
        0.043031781911849976,
        -0.0525558777153492,
        0.054108452051877975,
        0.054168786853551865,
        0.03434338793158531,
        0.054230090230703354,
        -0.05286232754588127,
        0.04407576844096184,
        -0.051457978785037994,
        0.04831055924296379,
        0.05384043604135513,
        -0.0433635488152504,
        -0.05330347642302513,
        -0.000443555909441784,
        -0.049851126968860626,
        0.03536688908934593,
        0.0020598145201802254,
        0.053245659917593,
        0.05363874137401581,
        0.032936301082372665,
        -0.04612870514392853,
        -0.053541749715805054,
        0.03739164024591446,
        -0.008387952111661434,
        0.05418437346816063,
        -0.04961283504962921,
        -0.04866565763950348,
        0.0535295307636261,
        0.004292771685868502,
        -0.0056481799110770226,
        0.047078877687454224,
        0.04127771779894829,
        -0.05392901971936226,
        -0.026468396186828613,
        -0.049228936433792114,
        -0.031061794608831406,
        0.05434754118323326,
        -0.007250181864947081,
        0.05430823564529419,
        -0.027548158541321754,
        0.052705440670251846,
        -0.0483977347612381,
        0.05372209846973419,
        0.052302297204732895
    ],
    [
        0.032571032643318176,
        -0.04089159518480301,
        -0.03369375690817833,
        0.023613087832927704,
        0.05659269914031029,
        -0.020090602338314056,
        0.005978203844279051,
        -0.05139977112412453,
        0.054742198437452316,
        -0.05534043535590172,
        -0.01681792549788952,
        0.04641742631793022,
        -0.057742588222026825,
        0.0010929523268714547,
        -0.056780070066452026,
        0.05596884340047836,
        -0.0574757382273674,
        0.020654207095503807,
        -0.05751202255487442,
        -0.02561783790588379,
        0.05649189278483391,
        -0.03220079094171524,
        0.04824577644467354,
        -0.056930914521217346,
        -0.020127322524785995,
        -0.02826794423162937,
        0.025264093652367592,
        0.056587040424346924,
        -0.03786380961537361,
        0.05440766364336014,
        0.05643691122531891,
        0.013027754612267017,
        0.054408080875873566,
        -0.057593073695898056,
        0.004484060686081648,
        -0.004821078851819038,
        -0.05462373048067093,
        -0.03244337439537048,
        -0.047619935125112534,
        0.042109742760658264,
        0.040093086659908295,
        0.04914811998605728,
        0.054478470236063004,
        -0.03934754058718681,
        0.044695254415273666,
        -0.02322256937623024,
        0.057241301983594894,
        0.057482216507196426,
        0.05799834802746773,
        -0.04742123931646347,
        0.04357197508215904,
        0.03487514704465866,
        0.056710705161094666,
        0.025972122326493263,
        0.04752037674188614,
        -0.016210097819566727,
        -0.0536065511405468,
        -0.01519098412245512,
        0.04130873829126358,
        -0.03397737815976143,
        -0.0535862036049366,
        -0.03216370940208435,
        -0.04808736965060234,
        0.011733774095773697,
        0.030115703120827675,
        0.05060894787311554,
        -0.012408856302499771,
        0.014968998730182648,
        -0.010567168705165386,
        -0.05802018195390701,
        -0.05743077024817467,
        -0.05628078058362007,
        -0.022651245817542076,
        0.04192190244793892,
        -0.05147228762507439,
        -0.04535796493291855,
        -0.023281347006559372,
        0.05802897736430168,
        0.05537283048033714,
        -0.014548588544130325,
        -0.013456732966005802,
        0.055381160229444504,
        -0.05737869814038277,
        -0.006693487986922264,
        0.05758791044354439,
        0.0490277037024498,
        -0.040949463844299316,
        0.03890877589583397,
        -0.0490838959813118,
        0.04826702922582626,
        -0.05204036086797714,
        0.02924317494034767,
        0.002627905923873186,
        -0.05689325928688049,
        -0.018124552443623543,
        0.03388707712292671,
        -0.012272072024643421,
        -0.05379058048129082,
        -0.054847121238708496,
        0.04711753875017166,
        -0.05638037249445915,
        -0.05407680198550224,
        0.055087361484766006,
        -0.008967573754489422,
        -0.035755373537540436,
        -0.03004108928143978,
        -0.05565028265118599,
        0.0530066080391407,
        -0.05144583061337471,
        -0.003979841247200966,
        -0.0546111986041069,
        -0.006474523805081844,
        -0.010909497737884521,
        0.03691883012652397,
        0.016736943274736404,
        -0.05657663196325302,
        0.05680589750409126,
        -0.04786088317632675,
        -0.05781267583370209,
        0.043259959667921066,
        0.0455462783575058,
        -0.0515609011054039,
        -0.03228088840842247,
        -0.003121271962299943,
        -0.05686579644680023,
        0.03441339731216431,
        -0.024077747017145157,
        0.04710521176457405,
        0.04596899077296257,
        0.05059381201863289,
        -0.03608715161681175,
        -0.05205937847495079,
        -0.02301810123026371,
        0.037886980921030045,
        -0.05463256686925888,
        -0.055426739156246185,
        0.03677593171596527,
        0.01741730608046055,
        -0.04814476892352104,
        0.04476964846253395,
        0.04458414763212204,
        0.05786564201116562,
        0.03695392236113548,
        0.009276116266846657,
        -0.017965013161301613,
        -0.0023068131413310766,
        0.04412178322672844,
        -0.050948306918144226,
        0.057915471494197845,
        0.057805340737104416,
        -0.03542685881257057,
        -0.05793054401874542,
        -0.057613831013441086,
        -0.010955712758004665,
        -0.035035088658332825,
        -0.03710215166211128,
        0.05740663781762123,
        0.010716216638684273,
        0.041516177356243134,
        0.05483359098434448,
        0.05400814861059189,
        0.03719872608780861,
        0.04686368256807327,
        -0.007577844429761171,
        0.03322744369506836,
        -0.030361639335751534,
        0.05042739585042,
        -0.05505182594060898,
        0.03953842446208,
        0.05451146140694618,
        -0.03537499159574509,
        -0.05719038099050522,
        -0.05608260631561279,
        -0.02801290526986122,
        -0.0484435148537159,
        0.05798426270484924,
        -0.05803265422582626,
        0.014780349098145962,
        -0.020841732621192932,
        0.014150342904031277,
        0.007459970656782389,
        0.019381675869226456,
        0.04684216156601906,
        -0.023387059569358826,
        0.0044531384482979774,
        -0.021638622507452965,
        -0.04184279963374138,
        0.053346920758485794,
        0.018028810620307922,
        -0.03610290214419365,
        0.0063651567324995995,
        -0.0178076084703207,
        -0.017499540001153946,
        -0.055617813020944595,
        -0.05335789546370506,
        0.02033272571861744,
        0.054236236959695816,
        0.05420626327395439,
        -0.003495464799925685,
        0.05652157962322235,
        0.05455755442380905,
        -0.0578932985663414,
        -0.031418923288583755,
        0.0345713272690773,
        0.05501224473118782,
        0.018372690305113792,
        0.056154701858758926,
        -0.05277015268802643,
        -0.0515935942530632,
        -0.04710390791296959,
        -0.04968391731381416,
        0.041985370218753815,
        0.004697905853390694,
        -0.0398222990334034,
        -0.05709046125411987,
        0.03412739187479019,
        0.0579637736082077,
        0.02648979052901268,
        0.057833585888147354,
        -0.0579802542924881,
        0.03902171924710274,
        -0.05312677472829819,
        -0.05539020895957947,
        -0.05210002511739731,
        0.05047902092337608,
        -0.056146807968616486,
        -0.03497917950153351,
        -0.006656232289969921,
        -0.027236737310886383,
        -0.05516986921429634,
        -0.05306524410843849,
        -0.05711396038532257,
        -0.047178249806165695,
        0.05637044832110405,
        -0.027003712952136993,
        0.05271132290363312,
        0.05580327659845352,
        -0.04624496400356293,
        -0.04714655503630638,
        0.05784500017762184,
        0.035772401839494705,
        0.0570102222263813,
        0.04621961712837219,
        0.020747462287545204,
        -0.0389493964612484,
        0.035232800990343094,
        0.02661043405532837,
        0.05785563215613365,
        -0.03653079643845558,
        -0.01891942135989666,
        -0.05652158334851265,
        -0.05663870647549629,
        -0.03812835365533829,
        -0.055976688861846924,
        -0.020781224593520164,
        0.052962638437747955,
        -0.021544482558965683,
        -0.05409225821495056,
        0.05781230330467224,
        0.051771774888038635,
        0.05315602943301201,
        0.05233970656991005,
        -0.05442333221435547,
        0.05669205263257027,
        0.0579373836517334,
        0.056669462472200394,
        0.0564211905002594,
        0.056543149054050446,
        0.05523885414004326,
        -0.051642391830682755,
        -0.03662330284714699,
        -0.058018285781145096,
        0.029478132724761963,
        -0.057359956204891205,
        0.0066393278539180756,
        0.030138636007905006,
        -0.05452506244182587,
        0.028950851410627365,
        -0.058028556406497955,
        -0.004442817531526089,
        -0.05464080348610878,
        0.041129544377326965,
        -0.036104585975408554,
        0.057137567549943924,
        0.05802140757441521,
        -0.04542795941233635,
        0.03034035488963127,
        -0.053508076816797256,
        0.00039018766256049275,
        0.04596465080976486,
        -0.0542498454451561,
        0.05586940422654152,
        0.04058091714978218,
        0.04999491944909096,
        0.014479697681963444,
        -0.054737020283937454,
        0.057938363403081894,
        -0.023685498163104057,
        0.039293136447668076,
        0.052445944398641586,
        -0.0557929128408432,
        0.052600279450416565,
        0.0004458704497665167,
        0.039972446858882904,
        -0.04862673953175545,
        -0.017276642844080925,
        -0.05518841743469238,
        0.05518452078104019,
        0.044787999242544174,
        -0.008608810603618622,
        0.01638689637184143,
        -0.055310074239969254,
        -0.03556403890252113,
        0.02020331099629402,
        -0.05100042745471001,
        -0.05641264468431473,
        0.05359840393066406,
        -0.04589317366480827,
        -0.023878494277596474,
        -0.056688182055950165,
        0.037703610956668854,
        -0.05543285608291626,
        0.0372992604970932,
        -0.05613243579864502,
        0.032655466347932816,
        0.0049254922196269035,
        -0.041102584451436996,
        0.02536269836127758,
        0.016731105744838715,
        -0.007708541117608547,
        0.053665343672037125,
        0.011451935395598412,
        -0.032998181879520416,
        -0.0517108254134655,
        0.0528307743370533,
        0.030084244906902313,
        0.0176225733011961,
        0.05789076164364815,
        -0.05579102784395218,
        0.05146200582385063,
        -0.05054657906293869,
        -0.050524625927209854,
        -0.056551579385995865,
        0.014533464796841145,
        0.05803360417485237,
        -0.0017015057383105159,
        0.05454385280609131,
        0.05436747893691063,
        -0.0164676271378994,
        0.05340975895524025,
        -0.05771081894636154,
        0.054034922271966934,
        -0.048262741416692734,
        -0.05620449036359787,
        0.014487276785075665,
        0.056740082800388336,
        -0.05767777934670448,
        -0.04069546237587929,
        -0.03907681256532669,
        -0.009969527833163738,
        0.052842579782009125,
        0.05593523755669594,
        -0.053266849368810654,
        -0.03704119846224785,
        0.005958739202469587,
        -0.05693064257502556,
        0.057960089296102524,
        -0.032271310687065125,
        0.057362478226423264,
        -0.058024901896715164,
        -0.03432426229119301,
        -0.05025601014494896,
        0.05802201107144356,
        0.034541353583335876,
        0.05334794148802757,
        0.0466502420604229,
        0.030634794384241104,
        0.025399906560778618,
        0.024579709395766258,
        -0.05494658276438713,
        0.041860464960336685,
        0.008614802733063698,
        -0.05666408687829971,
        -0.025060120970010757,
        0.03021504543721676,
        -0.012146800756454468,
        0.056178417056798935,
        -0.05159611254930496,
        -0.05044569447636604,
        -0.016023168340325356,
        -0.03007025644183159,
        0.05623199790716171,
        -0.027878543362021446,
        -0.034537408500909805,
        0.0020869707223027945,
        0.05545865371823311,
        0.045165080577135086,
        -0.043706946074962616,
        -0.05599931254982948,
        -0.05793954059481621,
        -0.047802794724702835,
        0.05582937225699425,
        0.03856063634157181,
        0.048429928719997406,
        0.03583431616425514,
        0.0031908194068819284,
        0.04897772893309593,
        -0.05340241268277168,
        0.05673987418413162,
        -0.03330852836370468,
        0.05206919461488724,
        0.02455572970211506,
        -0.05575526878237724,
        0.005698752589523792,
        0.052638206630945206,
        0.05256045237183571,
        0.003014419926330447,
        0.05420259013772011,
        -0.0065680439583957195,
        -0.03621085733175278,
        -0.02095942758023739,
        0.05392272770404816,
        -0.04647219553589821,
        -0.05078321695327759,
        0.05474685877561569,
        -0.016037117689847946,
        -0.05785157531499863,
        -0.00344385695643723,
        -0.04829198122024536,
        -0.0561533086001873,
        0.05367753282189369,
        -0.0026150888297706842,
        -0.0441153347492218,
        0.03279795125126839,
        0.007472529076039791,
        -0.05516359210014343,
        -0.05105975270271301,
        -0.04099017381668091,
        -0.043485164642333984,
        -0.05605784431099892,
        0.02410409227013588,
        0.042796604335308075,
        0.007290305569767952,
        0.053255610167980194,
        -0.0023862526286393404,
        -0.03790374845266342,
        0.05724268779158592,
        -0.05721688270568848,
        0.053836092352867126,
        -0.04395744204521179,
        -0.05665143206715584,
        0.05362183228135109,
        0.04279803857207298,
        -0.041736163198947906,
        0.05715402960777283,
        -0.04807699844241142,
        -0.018448714166879654,
        -0.015749074518680573,
        -0.057799555361270905,
        0.05140983313322067,
        0.04753986746072769,
        0.04455646499991417,
        -0.046168647706508636,
        0.053432539105415344,
        0.05524381995201111,
        0.05748328939080238,
        -0.05627177283167839,
        0.057035502046346664,
        0.043585192412137985,
        -0.05258938670158386,
        0.021331878378987312,
        0.04550463333725929,
        0.024410730227828026,
        0.057961925864219666,
        0.05494524538516998,
        0.05186973512172699,
        -0.05557708069682121,
        0.0469440221786499,
        -0.04748627543449402,
        -0.055106256157159805,
        -0.04658897593617439,
        0.025673501193523407,
        0.051014453172683716,
        0.04537124186754227,
        0.011526161804795265,
        -0.05511254817247391,
        0.050363391637802124,
        -0.00122140406165272,
        -0.054380036890506744,
        -0.023360105231404305,
        -0.02255946956574917,
        -0.054545335471630096,
        -0.05537852272391319,
        0.04692034423351288,
        0.05513907968997955,
        0.05234123766422272,
        -0.04988538473844528,
        0.05679137632250786,
        0.05669396370649338,
        0.009755820967257023,
        -0.053094420582056046,
        -0.052203577011823654,
        -0.012352697551250458,
        -0.052565984427928925,
        -0.04038187488913536,
        0.0195583775639534,
        0.05267985910177231,
        -0.04731705039739609,
        -0.038371723145246506,
        -0.058015476912260056,
        0.05760391429066658,
        0.05797327682375908
    ],
    [
        0.05424100160598755,
        0.020700974389910698,
        -0.024108316749334335,
        0.025280162692070007,
        0.05167338624596596,
        -0.029808012768626213,
        0.04838912934064865,
        -0.03595162183046341,
        0.04900440201163292,
        -0.04481549561023712,
        0.02959398925304413,
        0.027197806164622307,
        -0.060063671320676804,
        0.03622051700949669,
        -0.05826361104846001,
        -0.017510423436760902,
        -0.05969078093767166,
        0.003806766588240862,
        -0.02938307821750641,
        -0.04430646076798439,
        0.055858563631772995,
        -0.004828193224966526,
        0.0538053922355175,
        -0.05033217743039131,
        0.04292020574212074,
        -0.010512414388358593,
        -0.0008516482193954289,
        0.03373591974377632,
        0.022940758615732193,
        0.05531466007232666,
        0.0597587414085865,
        0.027874967083334923,
        -0.03512928634881973,
        -0.059933606535196304,
        0.019297771155834198,
        -0.028822675347328186,
        -0.05745778605341911,
        -0.003343302756547928,
        -0.04631347209215164,
        0.05735677480697632,
        0.01750885136425495,
        0.0576486699283123,
        0.05169770121574402,
        0.029460914433002472,
        0.015916595235466957,
        -0.03279624134302139,
        0.04169827327132225,
        0.05612361431121826,
        0.06034431606531143,
        -0.008977767080068588,
        0.01758645661175251,
        0.016551773995161057,
        0.05926809459924698,
        -0.017625438049435616,
        -0.011133315972983837,
        0.024067552760243416,
        -0.055313389748334885,
        -0.00859158020466566,
        0.028301117941737175,
        -0.023736612871289253,
        -0.044206369668245316,
        -0.031246840953826904,
        -0.036810848861932755,
        0.052021220326423645,
        -0.0018521436722949147,
        0.03261857479810715,
        0.03353297710418701,
        -0.04133634641766548,
        -0.0021108081564307213,
        -0.060511935502290726,
        -0.059734344482421875,
        -0.06014780327677727,
        -0.037570513784885406,
        0.03324281424283981,
        -0.056606560945510864,
        -0.016562113538384438,
        0.012322133406996727,
        0.060464419424533844,
        0.05748073756694794,
        -0.055442895740270615,
        0.002373000606894493,
        0.057038549333810806,
        -0.06007412448525429,
        0.03484313562512398,
        0.047485437244176865,
        0.05116412788629532,
        -0.020213128998875618,
        -0.015079308301210403,
        -0.000532097474206239,
        0.059528037905693054,
        -0.053323328495025635,
        -0.0145399896427989,
        -0.009180480614304543,
        -0.055508263409137726,
        -0.045239098370075226,
        0.0014821038348600268,
        0.0035623586736619473,
        -0.047042008489370346,
        -0.05767636001110077,
        0.03726670891046524,
        -0.060116108506917953,
        -0.02919580787420273,
        0.057443078607320786,
        -0.03352857008576393,
        -0.03856863081455231,
        -0.039881739765405655,
        -0.05169966444373131,
        0.0443497933447361,
        -0.023471765220165253,
        0.014187375083565712,
        -0.05591181665658951,
        0.020516499876976013,
        -0.03953541815280914,
        -0.012012678198516369,
        0.020472662523388863,
        -0.058152735233306885,
        0.049124836921691895,
        -0.057837311178445816,
        -0.05756421387195587,
        0.030547861009836197,
        0.004902112763375044,
        -0.012939109466969967,
        -0.03493884578347206,
        0.04959573596715927,
        -0.05968109890818596,
        0.057346876710653305,
        -0.02356644906103611,
        0.0319458432495594,
        0.04387384653091431,
        0.03540676459670067,
        -0.03565777465701103,
        -0.04778734967112541,
        -0.03871627524495125,
        0.014943663962185383,
        -0.05693132430315018,
        -0.05481010675430298,
        -0.00015719611837994307,
        -0.01740497164428234,
        -0.009409099817276001,
        0.058656126260757446,
        0.05215376615524292,
        0.05641044303774834,
        0.034700457006692886,
        0.037284005433321,
        0.009253093041479588,
        -0.023942572996020317,
        0.03947395458817482,
        -0.008530808612704277,
        0.06028025969862938,
        0.060517240315675735,
        -0.04291113093495369,
        -0.06037610024213791,
        -0.0601399764418602,
        -0.010865636169910431,
        -0.0270429328083992,
        -0.04896491393446922,
        0.05728626251220703,
        -0.04299577698111534,
        -0.020694443956017494,
        0.05944586172699928,
        0.04420468583703041,
        0.05929683521389961,
        0.03804739937186241,
        -0.0034417943097651005,
        -0.026128072291612625,
        -0.04269307106733322,
        0.040941204875707626,
        -0.05913155898451805,
        0.03546789661049843,
        0.051296062767505646,
        -0.043490439653396606,
        -0.05897587910294533,
        -0.05858038365840912,
        -0.012344280257821083,
        -0.012675965204834938,
        0.06027260050177574,
        -0.06046311929821968,
        0.0001062617520801723,
        0.04541391879320145,
        -0.009505786001682281,
        -0.00383324665017426,
        0.034362997859716415,
        0.011738908477127552,
        -0.013234209269285202,
        0.046037331223487854,
        0.03227202221751213,
        -0.05878237262368202,
        0.05830812826752663,
        -0.017988242208957672,
        -0.0295216403901577,
        -0.003034977475181222,
        -0.033682409673929214,
        -0.05519289895892143,
        -0.04460093751549721,
        -0.053118996322155,
        0.04228414595127106,
        0.041512493044137955,
        0.055448442697525024,
        0.052224837243556976,
        0.05571387708187103,
        0.050440844148397446,
        -0.05959353968501091,
        0.003724421374499798,
        0.00043399035348556936,
        0.043355703353881836,
        -0.016877541318535805,
        0.05682853236794472,
        -0.04973544552922249,
        -0.05310715362429619,
        -0.04363527148962021,
        -0.053148042410612106,
        0.04906843230128288,
        -0.05414850637316704,
        -0.027939448133111,
        -0.05318126082420349,
        0.022155260667204857,
        0.06029881164431572,
        0.05306435376405716,
        0.06036417558789253,
        -0.059496600180864334,
        0.015419872477650642,
        -0.029999814927577972,
        -0.059860095381736755,
        -0.06025225669145584,
        0.04459265246987343,
        -0.058178916573524475,
        -0.03158928081393242,
        0.016781393438577652,
        -0.017576197162270546,
        -0.05736493691802025,
        -0.051858481019735336,
        -0.06006082519888878,
        -0.013835012912750244,
        0.05740507319569588,
        -0.04966724291443825,
        -0.0041755856946110725,
        0.05913088843226433,
        -0.026183729991316795,
        -0.02105235494673252,
        0.060360223054885864,
        0.046806368976831436,
        0.05347099527716637,
        0.05065527185797691,
        0.0461975559592247,
        -0.03504462540149689,
        0.029824387282133102,
        0.017466532066464424,
        0.05790894478559494,
        -0.05358438938856125,
        0.01798350363969803,
        -0.05918624624609947,
        -0.059593360871076584,
        -0.054220713675022125,
        -0.05753382295370102,
        0.0009692020248621702,
        0.05525586009025574,
        0.050596144050359726,
        -0.055337417870759964,
        0.060252830386161804,
        0.051115717738866806,
        0.05644809827208519,
        0.04932215064764023,
        -0.053345609456300735,
        0.057204656302928925,
        0.058462947607040405,
        0.056241076439619064,
        0.05907100811600685,
        0.026430310681462288,
        0.05255116894841194,
        -0.05467114970088005,
        -0.03838585317134857,
        -0.06043560802936554,
        -0.024510342627763748,
        -0.06035934388637543,
        -0.007688454352319241,
        0.011412594467401505,
        -0.05836913734674454,
        0.04566557705402374,
        -0.06052630767226219,
        -0.05766540765762329,
        -0.05380455404520035,
        0.023503636941313744,
        0.027398517355322838,
        0.0599052831530571,
        0.06050865724682808,
        -0.059133049100637436,
        0.030126424506306648,
        -0.012491898611187935,
        -0.05950842797756195,
        0.01718423143029213,
        -0.04458335414528847,
        0.05780790373682976,
        0.04199426248669624,
        0.038907647132873535,
        -0.010775123722851276,
        -0.047355443239212036,
        0.060010138899087906,
        0.014385201036930084,
        0.019934305921196938,
        0.05547464266419411,
        -0.05821302533149719,
        0.05734511837363243,
        0.02076554112136364,
        0.01595565490424633,
        0.01017472892999649,
        -0.025662275031208992,
        -0.05860314145684242,
        0.05370868369936943,
        0.054536301642656326,
        -0.038859087973833084,
        0.006594163365662098,
        -0.05996673181653023,
        -0.05574134737253189,
        -0.0332055501639843,
        -0.05701826885342598,
        -0.05865246057510376,
        0.053912580013275146,
        -0.05452683940529823,
        0.02248750440776348,
        -0.06036853790283203,
        0.03239946439862251,
        -0.054960284382104874,
        0.05846951901912689,
        -0.05634462833404541,
        -0.0298930611461401,
        0.04093671217560768,
        -0.031755950301885605,
        -0.03722216933965683,
        0.03194005414843559,
        -0.0013652198249474168,
        0.060429636389017105,
        0.03424862399697304,
        -0.01885351538658142,
        -0.05107783526182175,
        0.05496196821331978,
        0.0062796734273433685,
        -0.033587899059057236,
        0.059887368232011795,
        -0.04100547730922699,
        0.05731145292520523,
        -0.018977753818035126,
        -0.055449068546295166,
        0.02643808163702488,
        -0.04627183824777603,
        0.06050339341163635,
        -0.008328315801918507,
        0.056351833045482635,
        0.04704856127500534,
        -0.032564952969551086,
        0.057413168251514435,
        -0.059557922184467316,
        0.03733522444963455,
        -0.051385995000600815,
        -0.05906647443771362,
        -0.04748697578907013,
        0.05894653499126434,
        -0.059743933379650116,
        -0.04777970537543297,
        -0.04810158908367157,
        -0.03998151421546936,
        0.05528716742992401,
        0.05711296200752258,
        -0.04411907494068146,
        -0.02154604159295559,
        -0.040545083582401276,
        -0.05943285673856735,
        0.060408178716897964,
        0.010279374197125435,
        0.059383731335401535,
        -0.060506708920001984,
        -0.02897416241466999,
        -0.054217904806137085,
        0.06048129126429558,
        0.03596355393528938,
        0.025820119306445122,
        0.05519767105579376,
        0.04440908133983612,
        -0.008273656480014324,
        -0.024331284686923027,
        -0.05702131986618042,
        0.05004098638892174,
        0.018434423953294754,
        -0.05983778089284897,
        -0.0008326270617544651,
        0.03503906726837158,
        -0.0031328427139669657,
        0.059006139636039734,
        -0.05119840055704117,
        -0.043568145483732224,
        -0.009965764358639717,
        -0.013996634632349014,
        0.05938408151268959,
        -0.012712904252111912,
        0.045643582940101624,
        -0.014585377648472786,
        0.057230144739151,
        0.05559007450938225,
        -0.05364008992910385,
        -0.05822901800274849,
        -0.05943568795919418,
        -0.04049411788582802,
        0.05966993793845177,
        0.04637390002608299,
        0.03574768453836441,
        0.012088384479284286,
        -0.026572557166218758,
        0.050742294639348984,
        -0.04355088621377945,
        0.05180920660495758,
        -0.05177416279911995,
        0.05544257536530495,
        0.04340709000825882,
        -0.05941344425082207,
        0.030122442170977592,
        0.05372782424092293,
        0.04079485684633255,
        -0.02413085475564003,
        0.059025540947914124,
        0.016263511031866074,
        0.032013144344091415,
        -0.04186460003256798,
        0.045183002948760986,
        -0.03525451943278313,
        -0.05830291286110878,
        0.05551670119166374,
        0.00468792486935854,
        -0.05865073949098587,
        0.007758662570267916,
        -0.054822102189064026,
        -0.05496051535010338,
        0.05888861045241356,
        -0.008887398056685925,
        -0.0118001913651824,
        0.04545573145151138,
        0.023786699399352074,
        -0.046610262244939804,
        -0.05469856783747673,
        0.01127949170768261,
        -0.01833740994334221,
        -0.05760739743709564,
        -0.007048448082059622,
        -0.0042374334298074245,
        -0.001945486874319613,
        0.053992148488759995,
        -0.05432886630296707,
        -0.002494444837793708,
        0.059924136847257614,
        -0.05872268229722977,
        0.04967062175273895,
        -0.05345119163393974,
        -0.05864351987838745,
        0.05853971466422081,
        0.019564811140298843,
        -0.038845982402563095,
        0.05996529757976532,
        -0.047290969640016556,
        0.00402893265709281,
        -0.010614143684506416,
        -0.05924766883254051,
        0.05529357120394707,
        0.011684437282383442,
        0.03726740926504135,
        -0.019076477736234665,
        0.02166259102523327,
        0.0579884834587574,
        0.05946857109665871,
        -0.05823696404695511,
        0.05456828698515892,
        0.04711679741740227,
        -0.059809960424900055,
        0.04732762649655342,
        0.0496041364967823,
        0.02202681452035904,
        0.060269154608249664,
        0.05797208845615387,
        0.05542777478694916,
        -0.0599820613861084,
        0.03188924491405487,
        -0.04897615686058998,
        -0.049713134765625,
        -0.054986678063869476,
        0.01850847154855728,
        0.05211577191948891,
        0.05589614436030388,
        0.04008651524782181,
        -0.029201863333582878,
        0.043420951813459396,
        0.017985094338655472,
        -0.049824584275484085,
        -0.05563913658261299,
        -0.053150858730077744,
        -0.044645756483078,
        -0.05784241110086441,
        0.05814860388636589,
        0.05818524211645126,
        0.05389474704861641,
        -0.05336334556341171,
        0.0498485341668129,
        0.05768618360161781,
        0.01938929222524166,
        -0.045134447515010834,
        -0.056575655937194824,
        0.010572526603937149,
        -0.023943256586790085,
        -0.013923614285886288,
        0.020474502816796303,
        0.055769503116607666,
        -0.009835820645093918,
        -0.0545438788831234,
        -0.058588068932294846,
        0.06044657155871391,
        0.06039860472083092
    ],
    [
        0.03663233667612076,
        -0.04784989729523659,
        -0.041923023760318756,
        0.034384675323963165,
        -0.005747792311012745,
        -0.044729869812726974,
        0.052725065499544144,
        -0.05370742455124855,
        0.05361434817314148,
        -0.05416851490736008,
        0.053973738104104996,
        -0.020891334861516953,
        -0.051089752465486526,
        0.05338003486394882,
        -0.05395421013236046,
        0.046422988176345825,
        0.053030550479888916,
        0.054112598299980164,
        -0.03863076865673065,
        0.052955370396375656,
        0.05094186216592789,
        0.00600337702780962,
        0.054094862192869186,
        -0.03541145101189613,
        -0.04618818312883377,
        -0.014202168211340904,
        -0.02279995195567608,
        0.024431729689240456,
        -0.054190151393413544,
        0.0541817806661129,
        -0.053809136152267456,
        -0.045870911329984665,
        -0.05402441695332527,
        -0.05416512116789818,
        0.05345209687948227,
        -0.05323878303170204,
        -0.0533728189766407,
        -0.04756208136677742,
        0.05234099552035332,
        0.021647604182362556,
        0.040560033172369,
        0.05079451575875282,
        0.051250629127025604,
        0.05108119547367096,
        0.05412948876619339,
        0.012136183679103851,
        0.028798241168260574,
        0.04780685901641846,
        0.05414680019021034,
        -0.05402831360697746,
        0.05408182367682457,
        -0.05066627264022827,
        -0.022545164451003075,
        -0.05352995917201042,
        -0.00811968743801117,
        -0.013674339279532433,
        0.05383402109146118,
        -0.049652349203825,
        -0.054193466901779175,
        0.05100587010383606,
        -0.05306373909115791,
        -0.02294965460896492,
        -0.05324741452932358,
        -0.04707592353224754,
        0.04831438139081001,
        -0.018266724422574043,
        -0.05291096866130829,
        -0.05309059098362923,
        0.036371804773807526,
        0.0055713411420583725,
        0.050574012100696564,
        -0.0541069358587265,
        0.051133591681718826,
        0.053098324686288834,
        0.05335965007543564,
        -0.032958678901195526,
        0.026889394968748093,
        0.05421819910407066,
        0.05239205062389374,
        -0.04977384954690933,
        -0.03878955543041229,
        -0.052645158022642136,
        -0.0540507473051548,
        0.03571285307407379,
        0.014144090004265308,
        0.04845558851957321,
        -0.054057639092206955,
        -0.051282282918691635,
        -0.010136640630662441,
        0.02831793762743473,
        -0.05261384695768356,
        -0.051995035260915756,
        -0.05418640375137329,
        -0.04035932943224907,
        -0.051024120301008224,
        0.03824321925640106,
        0.005023521836847067,
        -0.05375097692012787,
        -0.028121059760451317,
        -0.04273728281259537,
        -0.05393446609377861,
        0.013346267864108086,
        0.0494328998029232,
        0.05298950523138046,
        -0.05346490815281868,
        -0.04653434082865715,
        -0.04370204359292984,
        -0.008493845351040363,
        -0.05230490863323212,
        0.0538606122136116,
        -0.05372503772377968,
        0.05370309203863144,
        -0.051458556205034256,
        0.027370641008019447,
        0.040800049901008606,
        -0.00917159765958786,
        -0.017402056604623795,
        -0.05378839373588562,
        0.053495995700359344,
        0.023357335478067398,
        -0.04109147563576698,
        0.029373465105891228,
        0.05359016731381416,
        -0.054065581411123276,
        -0.004303484223783016,
        0.04870195314288139,
        0.054091453552246094,
        0.0031951311975717545,
        0.03782911226153374,
        -0.0331624299287796,
        0.05062831565737724,
        -0.04406103119254112,
        -0.05335408076643944,
        0.04204921796917915,
        -0.039346158504486084,
        -0.05310892313718796,
        0.05413253977894783,
        0.0316542312502861,
        0.031933918595314026,
        0.04457877576351166,
        -0.0322536937892437,
        0.05356619507074356,
        0.044978685677051544,
        -0.029612667858600616,
        0.05397999286651611,
        0.04438520222902298,
        0.029708748683333397,
        -0.053571008145809174,
        -0.031092630699276924,
        0.02204112894833088,
        -0.054122477769851685,
        -0.054141655564308167,
        -0.04221060872077942,
        0.042265135794878006,
        -0.0027488223277032375,
        -0.034278616309165955,
        -0.053916335105895996,
        -0.048378631472587585,
        0.04891457408666611,
        0.05247442424297333,
        -0.050354618579149246,
        0.042782194912433624,
        0.05396533012390137,
        0.051269687712192535,
        -0.053784020245075226,
        -0.019514719024300575,
        0.04226723313331604,
        0.044584985822439194,
        -0.052987147122621536,
        0.035105813294649124,
        -0.05359132960438728,
        0.05403083190321922,
        -0.02413361333310604,
        -0.05254797637462616,
        -0.031453926116228104,
        0.018134357407689095,
        0.052731890231370926,
        0.025409281253814697,
        0.054047875106334686,
        -0.04955302178859711,
        -0.011455880478024483,
        0.04107565060257912,
        0.05341046303510666,
        -0.030791480094194412,
        0.05241280049085617,
        -0.04947953298687935,
        -0.048826348036527634,
        0.05196305736899376,
        0.04141886532306671,
        -0.027904098853468895,
        -0.05383969843387604,
        0.0529351606965065,
        0.005654277745634317,
        -0.03182607144117355,
        -0.04907207563519478,
        0.04834209755063057,
        0.05360673740506172,
        0.053972672671079636,
        0.00945091899484396,
        -0.010868425481021404,
        0.05406949296593666,
        0.026580480858683586,
        -0.05405823513865471,
        -0.009227833710610867,
        -0.003940904047340155,
        0.04397634044289589,
        0.04200955107808113,
        0.053991593420505524,
        -0.027010099962353706,
        0.026498831808567047,
        -0.04934129863977432,
        -0.02336048148572445,
        -0.04253103584051132,
        0.052355047315359116,
        0.052728231996297836,
        -0.0505225844681263,
        0.054196108132600784,
        0.029785027727484703,
        0.05421838164329529,
        0.051442231982946396,
        0.008737575262784958,
        0.0016158863436430693,
        0.04953962191939354,
        -0.027286212891340256,
        0.04959247261285782,
        -0.053500980138778687,
        -0.0031145229004323483,
        -0.0472739152610302,
        0.05399319902062416,
        -0.04981987923383713,
        0.03824751451611519,
        -0.03379246965050697,
        0.007118237670511007,
        0.05276946350932121,
        0.037559691816568375,
        0.030017120763659477,
        0.05326461046934128,
        0.011338702403008938,
        0.048528898507356644,
        0.05421306565403938,
        0.05394897237420082,
        0.03309956192970276,
        -0.04717640206217766,
        -0.045198649168014526,
        -0.04918971657752991,
        -0.03844553232192993,
        0.04180368036031723,
        -0.040683209896087646,
        -0.050533026456832886,
        0.05179709568619728,
        -0.05357963219285011,
        -0.05295778065919876,
        -0.054201819002628326,
        -0.05200979858636856,
        0.04324852675199509,
        0.03235786035656929,
        0.048861000686883926,
        -0.022764312103390694,
        0.050897788256406784,
        -0.05260896310210228,
        -0.04978485405445099,
        0.0513928048312664,
        0.05328138545155525,
        -0.0466981865465641,
        -0.04856362193822861,
        0.05154750868678093,
        0.05313310772180557,
        0.05164404213428497,
        -0.014418911188840866,
        -0.044757816940546036,
        -0.04538452997803688,
        -0.0542132630944252,
        -0.014750758185982704,
        -0.05259166657924652,
        0.03474375233054161,
        0.04445590823888779,
        -0.04876969754695892,
        0.0541658028960228,
        0.05162794142961502,
        -0.053958188742399216,
        -0.05410778895020485,
        -0.027853678911924362,
        0.0029773826245218515,
        -0.039751291275024414,
        -0.00781339779496193,
        0.04057425633072853,
        0.032303400337696075,
        0.05292933061718941,
        -0.04735063761472702,
        0.012852302752435207,
        0.0019546726252883673,
        0.05159664899110794,
        -0.04945361986756325,
        -0.05380440130829811,
        0.04629802703857422,
        -0.05323871597647667,
        0.04889792576432228,
        -0.0457579642534256,
        -0.054085977375507355,
        0.04793521761894226,
        0.04298565164208412,
        0.039928700774908066,
        -0.003544286359101534,
        0.006330377887934446,
        0.011966469697654247,
        -0.023398837074637413,
        -0.04947512969374657,
        0.054217539727687836,
        0.04591762274503708,
        -0.03838932514190674,
        -0.004304720554500818,
        0.029657069593667984,
        0.04298486188054085,
        0.05262153968214989,
        -0.033952005207538605,
        -0.05240265280008316,
        -0.016493508592247963,
        -0.052081331610679626,
        0.05330418422818184,
        -0.018561966717243195,
        0.053559016436338425,
        -0.05324428901076317,
        0.04940033704042435,
        0.05377001315355301,
        0.054194122552871704,
        -0.053883589804172516,
        0.029612550511956215,
        -0.03294802084565163,
        0.011751215904951096,
        0.027452033013105392,
        -0.01964232325553894,
        -0.03716573864221573,
        0.002654105192050338,
        -0.05421676114201546,
        0.044324327260255814,
        -0.05412775278091431,
        -0.048121143132448196,
        0.04867622256278992,
        -0.054092440754175186,
        0.0541924424469471,
        -0.01980714313685894,
        -0.038412947207689285,
        -0.01999843120574951,
        -0.011700720526278019,
        0.05188082158565521,
        0.05172477290034294,
        0.047308310866355896,
        0.05237482488155365,
        0.04707089066505432,
        0.04363103210926056,
        0.05357510596513748,
        -0.053575899451971054,
        -0.03587484359741211,
        -0.053535331040620804,
        0.047707900404930115,
        -0.04086959734559059,
        -0.034257736057043076,
        -0.05230112746357918,
        0.025486119091510773,
        0.05359238013625145,
        0.028433281928300858,
        0.04437876492738724,
        0.053459055721759796,
        -0.045472752302885056,
        -0.05289149656891823,
        -0.05384375527501106,
        0.05376836284995079,
        0.05322005972266197,
        -0.0008394604083150625,
        -0.05413590371608734,
        -0.030368728563189507,
        0.0023722073528915644,
        0.053599268198013306,
        -0.049901511520147324,
        0.05409448966383934,
        0.0517519935965538,
        -0.02413186989724636,
        -0.040652841329574585,
        0.039085615426301956,
        -0.052024345844984055,
        -0.03633928671479225,
        0.050386566668748856,
        -0.050137609243392944,
        -0.052310921251773834,
        0.05421745032072067,
        -0.04701153561472893,
        0.054182350635528564,
        -0.053768340498209,
        -0.052771955728530884,
        -0.045554906129837036,
        -0.051923349499702454,
        0.04270823299884796,
        -0.047151245176792145,
        -0.05301661044359207,
        0.0540621280670166,
        0.053588319569826126,
        0.052312709391117096,
        -0.0486966036260128,
        -0.05296427384018898,
        -0.05076149106025696,
        -0.03752245008945465,
        0.052516888827085495,
        0.031157396733760834,
        0.043875932693481445,
        -0.04249577224254608,
        0.02383090928196907,
        0.05311739817261696,
        -0.006566113792359829,
        0.050078876316547394,
        -0.017282314598560333,
        -0.05210813507437706,
        -0.05412355065345764,
        0.03405754640698433,
        0.03885660320520401,
        0.05198116600513458,
        -0.037902314215898514,
        0.05419457331299782,
        -0.05296969413757324,
        0.05164545029401779,
        -0.02090328186750412,
        0.02063254825770855,
        -0.05366315320134163,
        -0.054058752954006195,
        -0.054210130125284195,
        0.049707524478435516,
        -0.054187435656785965,
        -0.05397650599479675,
        -0.004636717028915882,
        -0.04728168621659279,
        -0.0467144213616848,
        -0.03851831331849098,
        -0.04877087473869324,
        -0.03611007332801819,
        0.00553327240049839,
        -0.041492823511362076,
        -0.052074700593948364,
        -0.05315530672669411,
        0.05420539155602455,
        -0.04860915616154671,
        -0.03594231605529785,
        0.04585437849164009,
        0.04609168693423271,
        -0.02980726957321167,
        0.053731534630060196,
        -0.04920753091573715,
        0.05325454846024513,
        0.05385013297200203,
        -0.05257689952850342,
        0.007863404229283333,
        0.053374141454696655,
        0.0444098562002182,
        -0.043223995715379715,
        0.041045088320970535,
        -0.03235547989606857,
        -0.053801584988832474,
        -0.054042018949985504,
        -0.053883619606494904,
        -0.01997804455459118,
        0.01231408305466175,
        0.05389632657170296,
        0.01141959335654974,
        0.011133342050015926,
        -0.054172784090042114,
        -0.0012419247068464756,
        0.05373165011405945,
        0.054154809564352036,
        -0.048820264637470245,
        0.05402689799666405,
        0.02916845493018627,
        -0.05284241959452629,
        0.045863743871450424,
        0.05321989208459854,
        0.05346641689538956,
        0.04597119241952896,
        -0.027241945266723633,
        0.05235617980360985,
        0.027152059599757195,
        -0.054059892892837524,
        -0.023916108533740044,
        -0.050471656024456024,
        -0.050579607486724854,
        -0.04296339675784111,
        0.054125573486089706,
        0.021273428574204445,
        -0.0503753125667572,
        0.024480102583765984,
        0.05407383665442467,
        0.050740133970975876,
        -0.05378162860870361,
        -0.053776051849126816,
        0.04974369704723358,
        -0.0420016348361969,
        0.052546486258506775,
        0.0286276675760746,
        -0.015612104907631874,
        0.007895952090620995,
        0.05417286977171898,
        -0.04769749939441681,
        0.04915783181786537,
        -0.02435799315571785,
        -0.054184675216674805,
        -0.054109618067741394,
        -0.003304649144411087,
        0.03516468405723572,
        0.047962870448827744,
        -0.03128543496131897,
        0.04797236621379852,
        -0.00652032857760787,
        -0.004721253179013729,
        -0.051553983241319656,
        0.05371441692113876,
        0.03369190916419029
    ],
    [
        -0.03503012657165527,
        0.05263857543468475,
        0.06042102724313736,
        0.05384228006005287,
        -0.04655061662197113,
        0.006596312392503023,
        -0.030435089021921158,
        -0.06253602355718613,
        0.06211961433291435,
        0.050282642245292664,
        0.016968706622719765,
        0.034742750227451324,
        -0.04212065786123276,
        -0.02073681354522705,
        -0.05925239622592926,
        0.060894738882780075,
        0.01704222708940506,
        0.061030928045511246,
        -0.029285425320267677,
        -0.06375981867313385,
        0.028697486966848373,
        0.05910765752196312,
        -0.0020336098968982697,
        -0.025292476639151573,
        -0.021978318691253662,
        0.053787313401699066,
        0.04698757082223892,
        -0.05726049840450287,
        -0.0034345542080700397,
        0.05444220453500748,
        -0.05352180078625679,
        -0.010965817607939243,
        -0.047049958258867264,
        -0.062650665640831,
        0.04671069234609604,
        -0.01315073762089014,
        -0.062140677124261856,
        -0.05590185523033142,
        0.01846923679113388,
        -0.05683598294854164,
        0.022202054038643837,
        0.026006855070590973,
        -0.014180806465446949,
        -0.026271065697073936,
        0.04038996621966362,
        0.0606696791946888,
        -0.04609181731939316,
        -0.002790062688291073,
        0.0556192472577095,
        0.05778243765234947,
        0.042479027062654495,
        0.05735820531845093,
        -0.0451139397919178,
        0.0019483648939058185,
        -0.06084854155778885,
        0.005272670648992062,
        -0.0035920117516070604,
        -0.03787793964147568,
        0.020796077325940132,
        -0.052158284932374954,
        -0.03844093158841133,
        0.025800377130508423,
        -0.05651291832327843,
        0.06138770282268524,
        -0.05271671712398529,
        -0.050290580838918686,
        -0.04596127197146416,
        -0.03062976524233818,
        -0.06286267936229706,
        0.02590898610651493,
        -0.05931627005338669,
        -0.06368253380060196,
        0.05926140770316124,
        -0.020794963464140892,
        0.007328846491873264,
        -0.027640976011753082,
        0.012521316297352314,
        0.0641251653432846,
        -0.04856647178530693,
        -0.01300851535052061,
        -0.007564628962427378,
        0.058218855410814285,
        -0.05104295164346695,
        0.040318768471479416,
        0.054148439317941666,
        0.056613098829984665,
        0.06277107447385788,
        -0.049537889659404755,
        0.017652565613389015,
        0.026790980249643326,
        0.0009454867686145008,
        0.020221788436174393,
        -0.06407524645328522,
        0.006166780833154917,
        -0.030264919623732567,
        0.056578755378723145,
        -0.021877504885196686,
        -0.05418386310338974,
        0.061268243938684464,
        -0.025477880612015724,
        -0.06256233155727386,
        0.055522751063108444,
        0.040928687900304794,
        0.010513681918382645,
        0.001030059065669775,
        -0.06090549752116203,
        0.021295929327607155,
        0.057313159108161926,
        -0.0548592172563076,
        -0.01672310009598732,
        -0.007037162780761719,
        0.038377612829208374,
        -0.03855220228433609,
        -0.0035519078373908997,
        -0.0437353253364563,
        -0.0435882993042469,
        0.056410953402519226,
        -0.037628173828125,
        0.06392347067594528,
        0.03934722766280174,
        0.0029656817205250263,
        0.06252916157245636,
        0.05151178687810898,
        -0.04834989458322525,
        -0.02724631503224373,
        -0.06372078508138657,
        0.000840183871332556,
        -0.04824376106262207,
        0.056009531021118164,
        0.04647938162088394,
        0.06120457872748375,
        -0.04344601184129715,
        -0.06198432296514511,
        -0.031242460012435913,
        -0.05616164579987526,
        -0.06340127438306808,
        0.05755089595913887,
        0.036742065101861954,
        -0.05735813081264496,
        -0.02321595884859562,
        0.06322317570447922,
        -0.03625506907701492,
        0.05803271383047104,
        -0.03775070980191231,
        0.06039244681596756,
        -0.013048370368778706,
        0.03143612667918205,
        -0.05281117931008339,
        0.05064643174409866,
        -0.0068415747955441475,
        0.048238884657621384,
        -0.06325448304414749,
        -0.011460370384156704,
        -0.013844534754753113,
        0.062131136655807495,
        -0.044458940625190735,
        -0.01448748167604208,
        -0.030462730675935745,
        -0.026475263759493828,
        0.04944070801138878,
        0.060100775212049484,
        0.028041619807481766,
        0.051561467349529266,
        0.04124971851706505,
        -0.010230639018118382,
        -0.008355897851288319,
        0.029400303959846497,
        0.05492110922932625,
        -0.03437141701579094,
        0.025482719764113426,
        -0.05073240399360657,
        0.04566780477762222,
        -0.012531513348221779,
        -0.044799912720918655,
        0.023580506443977356,
        0.06156422942876816,
        -0.04191016033291817,
        0.0029148522298783064,
        0.0342927984893322,
        -0.05680956691503525,
        -0.0022071676794439554,
        0.06394235789775848,
        0.021211139857769012,
        0.03872188180685043,
        0.03211911767721176,
        0.05740242823958397,
        -0.06291905045509338,
        0.04627886041998863,
        -0.036976974457502365,
        -0.014848107472062111,
        -0.009015077725052834,
        0.03116210736334324,
        -0.051455479115247726,
        -0.032122667878866196,
        -0.03791535645723343,
        0.026461981236934662,
        -0.055721890181303024,
        0.009491227567195892,
        -0.053855668753385544,
        -0.006044307257980108,
        0.03575066477060318,
        -0.03663584217429161,
        0.013300318270921707,
        0.03863842785358429,
        -0.05095401778817177,
        0.03712766245007515,
        0.02738386206328869,
        0.051866315305233,
        -0.04109218344092369,
        0.04304623603820801,
        -0.053020112216472626,
        0.05518219247460365,
        0.025457579642534256,
        0.04270020127296448,
        0.002537729684263468,
        -0.05105810984969139,
        0.05650291591882706,
        -0.036563992500305176,
        0.06286722421646118,
        -0.03686458244919777,
        -0.044905487447977066,
        0.06250175833702087,
        -0.03922876715660095,
        0.02825169824063778,
        0.061399877071380615,
        -0.00907817017287016,
        -0.020823996514081955,
        0.04750131443142891,
        0.05944725126028061,
        -0.040497392416000366,
        0.05834077298641205,
        -0.06254839897155762,
        -0.03413170576095581,
        0.042113471776247025,
        -0.027199694886803627,
        0.06277566403150558,
        0.05809483677148819,
        -0.04070861265063286,
        -0.0001629885082365945,
        0.06351939588785172,
        0.03806641697883606,
        0.05580892786383629,
        -0.06296520680189133,
        0.04001767560839653,
        0.06183266267180443,
        0.05468219518661499,
        -0.050297852605581284,
        0.003603217890486121,
        -0.04455304145812988,
        -0.039301078766584396,
        -0.06230654567480087,
        -0.06136638671159744,
        -0.03809640556573868,
        -0.06034139543771744,
        -0.06241641193628311,
        0.04781155288219452,
        -0.05054435133934021,
        -0.03465074673295021,
        0.01738932728767395,
        -0.04885251820087433,
        0.06229078024625778,
        -0.035117316991090775,
        0.029745450243353844,
        0.006701732520014048,
        0.011187544092535973,
        0.041079331189394,
        -0.000011168401215400081,
        0.04010915383696556,
        -0.058023933321237564,
        0.013892234303057194,
        -0.028078777715563774,
        -0.05096683278679848,
        0.06395424157381058,
        -0.055151354521512985,
        -0.05466008558869362,
        0.021238652989268303,
        -0.0468808077275753,
        0.02386454865336418,
        -0.061530038714408875,
        -0.0598660409450531,
        -0.0603332594037056,
        -0.05820951238274574,
        -0.06129157170653343,
        0.05400572344660759,
        0.06125621497631073,
        -0.002591082127764821,
        0.020755687728524208,
        0.061860911548137665,
        0.0589573010802269,
        0.05205276980996132,
        -0.009273099713027477,
        0.046256449073553085,
        -0.052806541323661804,
        -0.014633066020905972,
        0.0577535405755043,
        -0.05967020243406296,
        0.05254882201552391,
        0.031679149717092514,
        -0.05987934768199921,
        0.051736775785684586,
        0.060161493718624115,
        0.05176713317632675,
        -0.026735620573163033,
        0.04614763706922531,
        -0.038759976625442505,
        0.015732932835817337,
        -0.05655255541205406,
        0.053335487842559814,
        0.009096592664718628,
        -0.04288695752620697,
        0.009838824160397053,
        0.022259103134274483,
        -0.028677307069301605,
        0.038524772971868515,
        0.047928716987371445,
        -0.058836448937654495,
        -0.0342489629983902,
        -0.057969339191913605,
        0.035785891115665436,
        -0.0423971526324749,
        0.01282995380461216,
        -0.05892998352646828,
        0.0371258482336998,
        0.060609735548496246,
        0.052458733320236206,
        -0.025057317689061165,
        0.06282956898212433,
        -0.0020575430244207382,
        0.05413083732128143,
        -0.01800689473748207,
        0.010928844101727009,
        -0.0014670119853690267,
        -0.02734614908695221,
        -0.013378236442804337,
        0.05432141572237015,
        0.04103964939713478,
        -0.05401933193206787,
        0.047455620020627975,
        0.01817820407450199,
        0.04723832383751869,
        -0.012105659581720829,
        0.04695003479719162,
        -0.05180349946022034,
        0.00031887588556855917,
        0.04160374402999878,
        0.04256942495703697,
        0.04159008339047432,
        0.0577116385102272,
        0.015880916267633438,
        0.04709800332784653,
        0.05799759551882744,
        -0.001984868198633194,
        -0.039707716554403305,
        -0.005129482131451368,
        0.013896270655095577,
        0.05210084840655327,
        -0.061529289931058884,
        -0.05938860774040222,
        0.036476947367191315,
        -0.056869421154260635,
        0.05077691748738289,
        -0.04987279698252678,
        0.05277613177895546,
        0.012179509736597538,
        -0.026973366737365723,
        -0.04916851967573166,
        0.05339306965470314,
        0.04467107728123665,
        0.05404651165008545,
        -0.057585932314395905,
        -0.04066343978047371,
        0.00918820034712553,
        0.06290098279714584,
        0.015494957566261292,
        0.049742214381694794,
        -0.0036391487810760736,
        -0.051113780587911606,
        0.05226871371269226,
        0.0627429336309433,
        0.04696659371256828,
        0.056191012263298035,
        -0.05074261873960495,
        -0.0632689818739891,
        0.04401100054383278,
        0.06146787106990814,
        -0.013930035755038261,
        0.04418791085481644,
        -0.04955953732132912,
        -0.030350040644407272,
        0.006483403500169516,
        -0.06385403126478195,
        0.006068139337003231,
        0.026830608025193214,
        -0.02365000918507576,
        0.005162759218364954,
        0.059096723794937134,
        0.06327623128890991,
        0.0484449602663517,
        0.017017647624015808,
        -0.06035980209708214,
        -0.021111730486154556,
        0.06135037541389465,
        -0.022780822589993477,
        -0.061343301087617874,
        -0.04240203648805618,
        -0.04202893003821373,
        0.014881771057844162,
        -0.019751034677028656,
        0.05700488016009331,
        0.0007199061219580472,
        -0.033865224570035934,
        -0.06103386729955673,
        0.053704190999269485,
        0.009933547116816044,
        -0.008184125646948814,
        -0.030295604839920998,
        0.03583071380853653,
        0.05501789227128029,
        0.02682342566549778,
        0.05460900440812111,
        0.02563883736729622,
        -0.06111452728509903,
        0.027553729712963104,
        -0.05752784386277199,
        0.05279970541596413,
        -0.05534859001636505,
        -0.03513675555586815,
        0.004141627345234156,
        0.030591584742069244,
        -0.050864387303590775,
        0.05221918225288391,
        -0.024984456598758698,
        -0.06237213313579559,
        -0.03954840078949928,
        0.029425857588648796,
        -0.057102639228105545,
        -0.03580182045698166,
        0.03497832641005516,
        0.04868244379758835,
        -0.01490437239408493,
        0.042520325630903244,
        0.06320846825838089,
        0.060429856181144714,
        0.04849892109632492,
        0.05747991427779198,
        0.044824812561273575,
        0.05997414141893387,
        -0.05143355205655098,
        0.053032223135232925,
        0.05680115148425102,
        -0.05865367501974106,
        0.009551603347063065,
        -0.008157762698829174,
        0.023486120626330376,
        -0.0569615475833416,
        -0.06031224876642227,
        -0.023999271914362907,
        -0.02945941500365734,
        -0.05588143318891525,
        0.0076912459917366505,
        -0.005972725804895163,
        -0.04705457761883736,
        -0.05298558995127678,
        0.05780209228396416,
        0.03236425668001175,
        0.062473028898239136,
        -0.05154038593173027,
        0.04495588690042496,
        -0.06300370395183563,
        -0.05873263254761696,
        0.05390607565641403,
        0.05871590971946716,
        -0.014784262515604496,
        0.054969120770692825,
        -0.06264908611774445,
        0.013751355931162834,
        0.005788594949990511,
        0.0006751624750904739,
        -0.041812118142843246,
        -0.056893110275268555,
        -0.0386321060359478,
        -0.06039207801222801,
        -0.054863058030605316,
        0.04086767137050629,
        -0.012191632762551308,
        0.04288044944405556,
        0.04870672523975372,
        0.05354434624314308,
        -0.04640108346939087,
        -0.05041680857539177,
        -0.04773241654038429,
        0.001223961473442614,
        -0.01533749420195818,
        0.029285283759236336,
        0.0056779831647872925,
        0.03659642115235329,
        0.05883459374308586,
        0.03452827408909798,
        0.05152110755443573,
        0.05956762284040451,
        -0.06026465445756912,
        -0.04857369884848595,
        -0.03480757772922516,
        0.021358266472816467,
        0.060303427278995514,
        -0.05818505212664604,
        0.03247637301683426,
        0.012044222094118595,
        -0.002090108348056674,
        -0.06196734309196472,
        0.03805844858288765,
        0.05987146869301796
    ],
    [
        -0.02911006473004818,
        0.05173655226826668,
        0.059585489332675934,
        0.05437210202217102,
        -0.04072355851531029,
        0.01805892027914524,
        -0.03527574986219406,
        -0.061362624168395996,
        0.061753351241350174,
        0.0466449111700058,
        0.013143567368388176,
        0.04201392084360123,
        -0.04539400339126587,
        -0.02474254183471203,
        -0.06000626087188721,
        0.05987505242228508,
        0.0052953073754906654,
        0.06016514450311661,
        -0.0065848068334162235,
        -0.06344408541917801,
        0.027060924097895622,
        0.06001393496990204,
        0.0006508825463242829,
        -0.03268777206540108,
        -0.02764112688601017,
        0.04606464505195618,
        0.04740498587489128,
        -0.058262377977371216,
        -0.016300875693559647,
        0.05624577775597572,
        -0.05608770623803139,
        -0.0297540333122015,
        -0.04701123759150505,
        -0.062375012785196304,
        0.04576224833726883,
        -0.02944807894527912,
        -0.06207657605409622,
        -0.06024063006043434,
        0.04220119118690491,
        -0.057388219982385635,
        0.028072617948055267,
        0.023787595331668854,
        -0.005477916914969683,
        -0.025293421000242233,
        0.046644724905490875,
        0.06100647896528244,
        -0.044906456023454666,
        -0.004578444641083479,
        0.0544821061193943,
        0.05932070314884186,
        0.046074412763118744,
        0.056873105466365814,
        -0.030174072831869125,
        -0.002259946893900633,
        -0.05936070904135704,
        -0.003099766792729497,
        -0.004606911912560463,
        -0.034846026450395584,
        0.014845590107142925,
        -0.049825917929410934,
        -0.035139039158821106,
        0.03033335879445076,
        -0.05632511153817177,
        0.06104525551199913,
        -0.052041053771972656,
        -0.05228118970990181,
        -0.03943351283669472,
        -0.03653903305530548,
        -0.06228315457701683,
        0.03124498389661312,
        -0.05489492788910866,
        -0.0632849931716919,
        0.05942071974277496,
        -0.015415419824421406,
        0.010067835450172424,
        -0.03189387917518616,
        0.010722360573709011,
        0.0637858435511589,
        -0.042980045080184937,
        -0.006316719576716423,
        0.004510657861828804,
        0.05987698957324028,
        -0.046859338879585266,
        0.042773157358169556,
        0.049464352428913116,
        0.05729936435818672,
        0.06271437555551529,
        -0.04811395704746246,
        -0.0017900606617331505,
        0.03799736127257347,
        -0.021351367235183716,
        -0.007878843694925308,
        -0.06379784643650055,
        0.0014604273019358516,
        -0.033716049045324326,
        0.05792470648884773,
        -0.024158017709851265,
        -0.05570597946643829,
        0.05960080027580261,
        -0.018566185608506203,
        -0.06264913827180862,
        0.05757525563240051,
        0.04381680116057396,
        0.012786506675183773,
        0.013286618515849113,
        -0.060813598334789276,
        0.02541983872652054,
        0.05535896122455597,
        -0.05299700051546097,
        -0.0156268123537302,
        -0.009211105294525623,
        0.002662461018189788,
        -0.03495476767420769,
        -0.00802231952548027,
        -0.05636996030807495,
        -0.04049867391586304,
        0.051662627607584,
        -0.030844097957015038,
        0.0634331926703453,
        0.030601194128394127,
        -0.0005754787125624716,
        0.062288131564855576,
        0.050956934690475464,
        -0.042304735630750656,
        -0.03382394090294838,
        -0.0631842389702797,
        0.003026624908670783,
        -0.05153598636388779,
        0.054695162922143936,
        0.04336199164390564,
        0.057986415922641754,
        -0.04861069843173027,
        -0.062034036964178085,
        -0.015059790574014187,
        -0.05758006125688553,
        -0.06287349760532379,
        0.05459233373403549,
        0.03916499763727188,
        -0.05673802271485329,
        -0.025781845673918724,
        0.06120332330465317,
        -0.030460847541689873,
        0.05802261456847191,
        -0.044767312705516815,
        0.059132881462574005,
        0.011104355566203594,
        0.02761393040418625,
        -0.053359467536211014,
        0.04521738737821579,
        0.0012790053151547909,
        0.05299461632966995,
        -0.06304075568914413,
        -0.020539814606308937,
        -0.008897019550204277,
        0.061857204884290695,
        -0.04135045036673546,
        -0.025240974500775337,
        -0.02047274447977543,
        -0.029757866635918617,
        0.04659610614180565,
        0.061073340475559235,
        0.02287950925529003,
        0.052258964627981186,
        0.05004933848977089,
        0.004856153856962919,
        0.004290004726499319,
        0.00885411724448204,
        0.05333206057548523,
        -0.027527939528226852,
        0.023206593468785286,
        -0.04872340336441994,
        0.04906454309821129,
        -0.019012564793229103,
        -0.04436848312616348,
        0.023257553577423096,
        0.06037479639053345,
        -0.03693199157714844,
        0.0019491713028401136,
        0.032645903527736664,
        -0.0553358718752861,
        -0.003775773337110877,
        0.063736692070961,
        0.02323205955326557,
        0.034368857741355896,
        0.037042126059532166,
        0.05806911736726761,
        -0.06252048909664154,
        0.04798911511898041,
        -0.04347442463040352,
        -0.020177293568849564,
        -0.014778776094317436,
        0.021356306970119476,
        -0.05041887238621712,
        -0.03786676004528999,
        -0.03784680739045143,
        0.03251689672470093,
        -0.05665198713541031,
        0.00899088941514492,
        -0.051801647990942,
        -0.02417372539639473,
        0.04280126467347145,
        -0.0425226166844368,
        -0.0010798866860568523,
        0.03213159739971161,
        -0.04355103150010109,
        0.03742456063628197,
        0.027258893474936485,
        0.05520292744040489,
        -0.04659664258360863,
        0.055246900767087936,
        -0.055268291383981705,
        0.057126570492982864,
        0.02974671870470047,
        0.04570389911532402,
        -0.013365057297050953,
        -0.03760233521461487,
        0.058474183082580566,
        -0.03882939741015434,
        0.06218200922012329,
        -0.038149017840623856,
        -0.0409054197371006,
        0.062225308269262314,
        -0.03648949787020683,
        0.024335386231541634,
        0.06097656488418579,
        -0.011961063370108604,
        -0.030152495950460434,
        0.02245810441672802,
        0.05836104601621628,
        -0.046634238213300705,
        0.058859579265117645,
        -0.06239422410726547,
        -0.035012174397706985,
        0.04603128880262375,
        -0.02407083846628666,
        0.06182413920760155,
        0.05876193195581436,
        -0.03358342871069908,
        0.011097664013504982,
        0.06320182979106903,
        0.035875849425792694,
        0.05440535396337509,
        -0.06295564025640488,
        0.02071833424270153,
        0.060602184385061264,
        0.050895318388938904,
        -0.030919061973690987,
        -0.0001477070909459144,
        -0.04338596388697624,
        -0.03750954940915108,
        -0.06174078956246376,
        -0.060885343700647354,
        -0.027722112834453583,
        -0.0605049766600132,
        -0.06125405430793762,
        0.047024354338645935,
        -0.05330129712820053,
        -0.03487417474389076,
        0.02219552733004093,
        -0.04931621998548508,
        0.06131817027926445,
        -0.03732946515083313,
        0.040200378745794296,
        0.007187321316450834,
        0.014336373656988144,
        0.04431510716676712,
        0.03869977593421936,
        0.05293700844049454,
        -0.057363372296094894,
        0.008503079414367676,
        -0.032549887895584106,
        -0.054285190999507904,
        0.0635475218296051,
        -0.05271245166659355,
        -0.05159193277359009,
        0.025590697303414345,
        -0.04874779284000397,
        0.017676150426268578,
        -0.0544721819460392,
        -0.059588298201560974,
        -0.05887105315923691,
        -0.05664902180433273,
        -0.06156177818775177,
        0.04816511273384094,
        0.05911991745233536,
        0.0074339332059025764,
        0.01942872628569603,
        0.0604390874505043,
        0.05780790001153946,
        0.054762426763772964,
        -0.011637857183814049,
        0.052166469395160675,
        -0.05083808675408363,
        -0.0173130352050066,
        0.05786045268177986,
        -0.060748375952243805,
        0.0560310073196888,
        0.030891824513673782,
        -0.056882139295339584,
        0.0509205125272274,
        0.05746796354651451,
        0.05395584553480148,
        -0.03687620535492897,
        0.04539197310805321,
        -0.04719033092260361,
        0.008297243155539036,
        -0.055762991309165955,
        0.05120294168591499,
        0.0010452010901644826,
        -0.04314158484339714,
        0.01658465340733528,
        0.02696540392935276,
        -0.03307969868183136,
        0.0475585050880909,
        0.042767852544784546,
        -0.05791385844349861,
        -0.03319230303168297,
        -0.054512690752744675,
        0.033204156905412674,
        -0.04525023698806763,
        0.012384863570332527,
        -0.056821513921022415,
        0.03572356700897217,
        0.060334403067827225,
        0.04744859039783478,
        -0.030039500445127487,
        0.06012280285358429,
        0.005346220452338457,
        0.05436880141496658,
        -0.016130788251757622,
        0.00858861580491066,
        -0.016125626862049103,
        -0.025747627019882202,
        -0.019782746210694313,
        0.052673015743494034,
        0.03849675506353378,
        -0.049914754927158356,
        0.049891404807567596,
        0.017840750515460968,
        0.04943132400512695,
        -0.01358723919838667,
        0.03535854071378708,
        -0.05707819014787674,
        -0.0012832264183089137,
        0.04352116957306862,
        0.047447044402360916,
        0.041424188762903214,
        0.0591820664703846,
        0.03302714601159096,
        0.051374658942222595,
        0.0555870421230793,
        -0.008199767209589481,
        -0.03050578571856022,
        -0.014242771081626415,
        0.020242976024746895,
        0.05108882859349251,
        -0.06115414947271347,
        -0.056391578167676926,
        0.040541283786296844,
        -0.05707889795303345,
        0.05253582075238228,
        -0.04115675762295723,
        0.050304245203733444,
        -0.001049028243869543,
        -0.03860028088092804,
        -0.04964158684015274,
        0.05621115490794182,
        0.04692056030035019,
        0.047359392046928406,
        -0.05792582407593727,
        -0.03371014818549156,
        -0.0062629664316773415,
        0.062371574342250824,
        0.022351108491420746,
        0.04849839210510254,
        -0.008052489720284939,
        -0.053171128034591675,
        0.053881753236055374,
        0.06292425096035004,
        0.04959830269217491,
        0.05142287164926529,
        -0.05119796097278595,
        -0.06305389106273651,
        0.04354922100901604,
        0.06220201402902603,
        -0.027903199195861816,
        0.04583431035280228,
        -0.05117854103446007,
        -0.031836435198783875,
        0.006918545346707106,
        -0.06351972371339798,
        0.006091131828725338,
        0.038059063255786896,
        -0.03439117968082428,
        0.0253764558583498,
        0.0589437261223793,
        0.06285751610994339,
        0.042279839515686035,
        0.008211653679609299,
        -0.06123599037528038,
        -0.012818408198654652,
        0.06049337983131409,
        -0.013120419345796108,
        -0.062082260847091675,
        -0.04032647982239723,
        -0.038934603333473206,
        0.0054845139384269714,
        -0.027029428631067276,
        0.05631772428750992,
        0.01133768167346716,
        -0.026886170729994774,
        -0.06040261313319206,
        0.0573514960706234,
        0.017589712515473366,
        -0.007538029458373785,
        -0.03757728636264801,
        0.032530635595321655,
        0.05471205711364746,
        0.033294547349214554,
        0.05726050212979317,
        0.003803752362728119,
        -0.056198641657829285,
        0.03373236581683159,
        -0.057219017297029495,
        0.054451651871204376,
        -0.057887621223926544,
        -0.03666047751903534,
        0.007049475330859423,
        0.011213424615561962,
        -0.04660448431968689,
        0.0542258657515049,
        -0.017753301188349724,
        -0.06192752346396446,
        -0.04553758352994919,
        0.017115524038672447,
        -0.05445726960897446,
        -0.05188931152224541,
        0.03144901618361473,
        0.044819992035627365,
        -0.005108770914375782,
        0.05086570233106613,
        0.06270309537649155,
        0.06075336039066315,
        0.0521494522690773,
        0.05621650069952011,
        0.046004317700862885,
        0.06129232421517372,
        -0.055213384330272675,
        0.05594881623983383,
        0.05359853059053421,
        -0.06021741405129433,
        0.003755733836442232,
        -0.0052111330442130566,
        0.030609900131821632,
        -0.05713462829589844,
        -0.06067100167274475,
        -0.02347678318619728,
        -0.03535163030028343,
        -0.05115306377410889,
        0.0091942697763443,
        -0.0047730859369039536,
        -0.038595493882894516,
        -0.057831183075904846,
        0.05938137322664261,
        0.029189059510827065,
        0.0620868019759655,
        -0.05119094252586365,
        0.03745667263865471,
        -0.062339406460523605,
        -0.057691581547260284,
        0.04946127161383629,
        0.05857744440436363,
        -0.00670846551656723,
        0.050879184156656265,
        -0.06141174957156181,
        0.018637431785464287,
        -0.0019030210096389055,
        0.011165119707584381,
        -0.04176707938313484,
        -0.05654178932309151,
        -0.05172017589211464,
        -0.06073453277349472,
        -0.0539211705327034,
        0.03304815664887428,
        -0.0063442266546189785,
        0.04358543083071709,
        0.052596691995859146,
        0.05139589682221413,
        -0.04895877093076706,
        -0.049144402146339417,
        -0.04606025665998459,
        -0.006145003717392683,
        -0.014756723307073116,
        0.024068202823400497,
        0.023850835859775543,
        0.04168306291103363,
        0.05971836671233177,
        0.03433137387037277,
        0.05395098775625229,
        0.05886195972561836,
        -0.06161165609955788,
        -0.04262838885188103,
        -0.03941253945231438,
        0.025002924725413322,
        0.06175285950303078,
        -0.05912366881966591,
        0.04669991135597229,
        0.017367549240589142,
        -0.00355939706787467,
        -0.0628093034029007,
        0.04269138723611832,
        0.05513022467494011
    ],
    [
        -0.032678961753845215,
        0.05235183238983154,
        0.05949150770902634,
        0.0577186718583107,
        -0.04946091026067734,
        0.010384395718574524,
        -0.03734885901212692,
        -0.06196518987417221,
        0.06293072551488876,
        0.0503511019051075,
        0.01740908995270729,
        0.043885596096515656,
        -0.04266989231109619,
        -0.01336701400578022,
        -0.060161154717206955,
        0.06080976128578186,
        -0.005169802810996771,
        0.06078832596540451,
        -0.021839914843440056,
        -0.06341832876205444,
        0.0341876782476902,
        0.05870604142546654,
        0.008127357810735703,
        -0.022082123905420303,
        -0.02835467830300331,
        0.05679813772439957,
        0.05409754440188408,
        -0.05556761845946312,
        -0.014568865299224854,
        0.052341900765895844,
        -0.04971917346119881,
        -0.010539877228438854,
        -0.04481768608093262,
        -0.0626298189163208,
        0.04755198210477829,
        -0.014001443050801754,
        -0.06246164068579674,
        -0.05908571928739548,
        -0.018517926335334778,
        -0.05591224133968353,
        0.03177785128355026,
        0.03254637122154236,
        -0.007395901717245579,
        -0.029669195413589478,
        0.05030320584774017,
        0.06012339144945145,
        -0.04581340029835701,
        -0.0069619533605873585,
        0.05965839698910713,
        0.05640775337815285,
        0.04550090432167053,
        0.05860576033592224,
        -0.020289087668061256,
        0.003975186496973038,
        -0.05597992613911629,
        -0.00002101972495438531,
        -0.011545727029442787,
        -0.03085043467581272,
        -0.004107275512069464,
        -0.04793199896812439,
        -0.036232735961675644,
        0.024913815781474113,
        -0.05566604062914848,
        0.061921700835227966,
        -0.05639345198869705,
        -0.05081818997859955,
        -0.042353831231594086,
        -0.028775209560990334,
        -0.062499456107616425,
        0.03127557039260864,
        -0.0550801157951355,
        -0.06297114491462708,
        0.05897725746035576,
        -0.014934990555047989,
        0.01372826099395752,
        -0.028554845601320267,
        0.01995980739593506,
        0.06400082260370255,
        -0.026980498805642128,
        -0.0034480090253055096,
        0.0032131739426404238,
        0.0588095560669899,
        -0.0519452728331089,
        0.0357491672039032,
        0.032923586666584015,
        0.05649741366505623,
        0.06291398406028748,
        -0.047203730791807175,
        0.01310266274958849,
        0.03438662737607956,
        -0.020202673971652985,
        0.008522040210664272,
        -0.06391385942697525,
        -0.0021998148877173662,
        -0.036050792783498764,
        0.05584167689085007,
        -0.029293173924088478,
        -0.05642443522810936,
        0.059773508459329605,
        -0.012138848192989826,
        -0.06219790503382683,
        0.05328446254134178,
        0.0442628338932991,
        0.02096227928996086,
        0.011362061835825443,
        -0.061828434467315674,
        0.016495106741786003,
        0.05816730111837387,
        -0.05324852094054222,
        -0.011333364993333817,
        -0.01689566671848297,
        0.005110679194331169,
        -0.037666983902454376,
        0.010686667636036873,
        -0.052462223917245865,
        -0.033958375453948975,
        0.04755522683262825,
        -0.029867006465792656,
        0.06307907402515411,
        0.033513907343149185,
        -0.012432069517672062,
        0.06226962432265282,
        0.05341566354036331,
        -0.036734651774168015,
        -0.033143412321805954,
        -0.06317305564880371,
        -0.0025850918609648943,
        -0.05261347442865372,
        0.056473251432180405,
        0.04880140721797943,
        0.0595218688249588,
        -0.04464275762438774,
        -0.0611577033996582,
        -0.028297336772084236,
        -0.056389618664979935,
        -0.06302949786186218,
        0.053594302386045456,
        0.03480144217610359,
        -0.05636460334062576,
        -0.026363646611571312,
        0.06159157678484917,
        -0.03572523966431618,
        0.056645557284355164,
        -0.03676936775445938,
        0.05959959328174591,
        0.014776386320590973,
        0.009749466553330421,
        -0.05376666039228439,
        0.04711615666747093,
        -0.0016716677928343415,
        0.04775372892618179,
        -0.06306435912847519,
        0.008084853179752827,
        -0.022663289681077003,
        0.06226636469364166,
        -0.04703650623559952,
        -0.01452172826975584,
        -0.014207173138856888,
        -0.023424046114087105,
        0.05476422607898712,
        0.06111178174614906,
        0.005890551954507828,
        0.05278175696730614,
        0.042383208870887756,
        0.005926835350692272,
        0.01053646206855774,
        0.002399956341832876,
        0.054679643362760544,
        -0.02556624449789524,
        0.020184509456157684,
        -0.04516012221574783,
        0.05350043624639511,
        -0.021999716758728027,
        -0.03348971903324127,
        0.02734808810055256,
        0.062019020318984985,
        -0.04589981585741043,
        -0.00794188492000103,
        0.04145964980125427,
        -0.05758819729089737,
        -0.010804002173244953,
        0.06391094624996185,
        0.011826790869235992,
        0.03138935565948486,
        0.02535948157310486,
        0.058130476623773575,
        -0.06268426030874252,
        0.044912852346897125,
        -0.035953983664512634,
        -0.012981410138309002,
        -0.010330096818506718,
        0.018779877573251724,
        -0.054585009813308716,
        -0.048323992639780045,
        -0.049569666385650635,
        0.03493351861834526,
        -0.05849693715572357,
        0.021151235327124596,
        -0.04511338472366333,
        -0.0068765985779464245,
        0.04685574024915695,
        -0.04840420186519623,
        0.010246521793305874,
        0.039342351257801056,
        -0.03685300797224045,
        0.024417707696557045,
        0.03354983031749725,
        0.05257197469472885,
        -0.04878613352775574,
        0.04908125102519989,
        -0.05696437507867813,
        0.05827697739005089,
        0.020736563950777054,
        0.044634148478507996,
        -0.012011929415166378,
        -0.031180575489997864,
        0.06159232184290886,
        -0.03724302724003792,
        0.061924710869789124,
        -0.024684714153409004,
        -0.03566155582666397,
        0.06229935213923454,
        -0.04760461673140526,
        0.021898197010159492,
        0.05983582139015198,
        0.004331344738602638,
        -0.02817985787987709,
        0.03293018415570259,
        0.060726411640644073,
        -0.04711655154824257,
        0.05903510004281998,
        -0.061579275876283646,
        -0.028092579916119576,
        0.04325563460588455,
        -0.014514075592160225,
        0.06272850185632706,
        0.0588008351624012,
        -0.0398181788623333,
        0.019242357462644577,
        0.06365987658500671,
        0.035174861550331116,
        0.05662358179688454,
        -0.06227736920118332,
        0.01883554831147194,
        0.06102976202964783,
        0.054319173097610474,
        -0.04751413315534592,
        0.015430791303515434,
        -0.044313982129096985,
        -0.037137892097234726,
        -0.061436526477336884,
        -0.06192416325211525,
        -0.03456920012831688,
        -0.060725122690200806,
        -0.06179675832390785,
        0.05037127807736397,
        -0.053651291877031326,
        -0.03245757892727852,
        0.02990114875137806,
        -0.038421034812927246,
        0.060271479189395905,
        -0.04298087954521179,
        0.0350460447371006,
        0.022151799872517586,
        -0.003814465133473277,
        0.04256577044725418,
        0.047058168798685074,
        0.044825468212366104,
        -0.05935632809996605,
        0.01722428947687149,
        -0.028473826125264168,
        -0.05503791198134422,
        0.06381428241729736,
        -0.05360165983438492,
        -0.05107266455888748,
        0.027160821482539177,
        -0.05228413641452789,
        0.006862761918455362,
        -0.04842933267354965,
        -0.060210250318050385,
        -0.05865900218486786,
        -0.05611537769436836,
        -0.06190672516822815,
        0.053892530500888824,
        0.05741516873240471,
        0.006312575191259384,
        0.022488020360469818,
        0.05934717133641243,
        0.05997639149427414,
        0.05006680637598038,
        -0.02285582944750786,
        0.05153341963887215,
        -0.054880909621715546,
        -0.02063869684934616,
        0.05643738433718681,
        -0.060403693467378616,
        0.055597055703401566,
        0.0448540635406971,
        -0.055061642080545425,
        0.05123445764183998,
        0.06003710627555847,
        0.04708317294716835,
        -0.03096611052751541,
        0.04718708246946335,
        -0.04011467844247818,
        0.01081006322056055,
        -0.058542486280202866,
        0.05228004604578018,
        0.00652766227722168,
        -0.044234346598386765,
        0.008055629208683968,
        0.021414726972579956,
        -0.03179074078798294,
        0.04445895180106163,
        0.035183992236852646,
        -0.059154212474823,
        -0.03720686212182045,
        -0.05408390238881111,
        0.035110633820295334,
        -0.044153761118650436,
        0.005685099400579929,
        -0.058708079159259796,
        0.026816535741090775,
        0.056210968643426895,
        0.053825125098228455,
        -0.03372989967465401,
        0.06132970005273819,
        0.01779790222644806,
        0.051654551178216934,
        -0.026456676423549652,
        0.008222795091569424,
        -0.02492886409163475,
        -0.023211436346173286,
        -0.018932195380330086,
        0.05152494087815285,
        0.03375500813126564,
        -0.05631577968597412,
        0.04989444091916084,
        0.01925249770283699,
        0.05168675631284714,
        -0.021439017727971077,
        0.03981887176632881,
        -0.055929671972990036,
        0.00018521578749641776,
        0.05307905003428459,
        0.03743365406990051,
        0.03453356400132179,
        0.05972147732973099,
        0.01840713806450367,
        0.05163925513625145,
        0.0525946170091629,
        0.0019886374939233065,
        -0.037119410932064056,
        -0.02575388178229332,
        0.024571893736720085,
        0.053434260189533234,
        -0.061698395758867264,
        -0.056672416627407074,
        0.031894754618406296,
        -0.05526139959692955,
        0.0486152246594429,
        -0.046287354081869125,
        0.05037441477179527,
        0.0033134245313704014,
        -0.03875143080949783,
        -0.05010532587766647,
        0.057979170233011246,
        0.05707160383462906,
        0.04703634977340698,
        -0.06028088554739952,
        -0.039654817432165146,
        0.00029452689341269433,
        0.06294943392276764,
        0.021478278562426567,
        0.04992901161313057,
        -0.003331123385578394,
        -0.05746525153517723,
        0.05654697120189667,
        0.06246056407690048,
        0.04234343022108078,
        0.05319855362176895,
        -0.03974722698330879,
        -0.0631767064332962,
        0.04194701835513115,
        0.06182702258229256,
        -0.026066318154335022,
        0.04565117508172989,
        -0.04522896185517311,
        -0.03574623912572861,
        0.003933740314096212,
        -0.06369700282812119,
        -0.012366685084998608,
        0.019450396299362183,
        -0.03136327490210533,
        0.008537455461919308,
        0.060092996805906296,
        0.062476448714733124,
        0.04691791906952858,
        0.028518816456198692,
        -0.06104982644319534,
        -0.009472801350057125,
        0.061378929764032364,
        0.011567866429686546,
        -0.06219314783811569,
        -0.04251403361558914,
        -0.04041921719908714,
        -0.0038206151220947504,
        -0.02622693032026291,
        0.05650471895933151,
        -0.0005827884888276458,
        -0.03653544932603836,
        -0.06046440079808235,
        0.05608764663338661,
        -0.0003028814389836043,
        -0.007209399249404669,
        -0.03182068094611168,
        0.031153151765465736,
        0.051084619015455246,
        0.030838901177048683,
        0.050441961735486984,
        0.022443018853664398,
        -0.05584130063652992,
        0.031085362657904625,
        -0.05833854526281357,
        0.05594391003251076,
        -0.059933315962553024,
        -0.03860669210553169,
        0.0067729405127465725,
        0.022353574633598328,
        -0.05430111289024353,
        0.05375099554657936,
        -0.024848779663443565,
        -0.06242508813738823,
        -0.04393865540623665,
        0.022870315238833427,
        -0.05596595257520676,
        -0.03774974122643471,
        0.03530710190534592,
        0.049763359129428864,
        -0.01630168780684471,
        0.05895518511533737,
        0.06254786252975464,
        0.058807067573070526,
        0.049761928617954254,
        0.060802917927503586,
        0.047205206006765366,
        0.06146351993083954,
        -0.056633610278367996,
        0.05417735502123833,
        0.05725881829857826,
        -0.06015226989984512,
        0.016164936125278473,
        -0.013260699808597565,
        0.03849220648407936,
        -0.06045542657375336,
        -0.061614081263542175,
        -0.024408843368291855,
        -0.014482464641332626,
        -0.05471789091825485,
        0.005938365124166012,
        0.010540933348238468,
        -0.04573656991124153,
        -0.059635065495967865,
        0.06160728633403778,
        0.025060787796974182,
        0.062201086431741714,
        -0.049057573080062866,
        0.03454705700278282,
        -0.06236888840794563,
        -0.059809643775224686,
        0.044893745332956314,
        0.05506346374750137,
        -0.008264473639428616,
        0.05488574504852295,
        -0.06070982292294502,
        0.018576301634311676,
        -0.006576672662049532,
        0.014890875667333603,
        -0.03875220566987991,
        -0.05743174999952316,
        -0.05028090253472328,
        -0.061802756041288376,
        -0.04895208030939102,
        0.03301609680056572,
        0.015558204613626003,
        0.03358544409275055,
        0.057435065507888794,
        0.05650024861097336,
        -0.0456567257642746,
        -0.04571704566478729,
        -0.05500442162156105,
        -0.017831949517130852,
        0.005832514259964228,
        0.0326533168554306,
        0.0026741151232272387,
        0.02637123316526413,
        0.05949142202734947,
        0.03050241991877556,
        0.05319332331418991,
        0.06011482700705528,
        -0.061804238706827164,
        -0.04113653302192688,
        -0.03956995904445648,
        0.02802475541830063,
        0.06200065091252327,
        -0.057607147842645645,
        0.047795817255973816,
        0.019637955352663994,
        0.017539475113153458,
        -0.06297409534454346,
        0.037493325769901276,
        0.05562620982527733
    ],
    [
        -0.038403015583753586,
        0.052745670080184937,
        0.05918172001838684,
        0.053586695343256,
        -0.05027145519852638,
        0.008863265626132488,
        -0.04339665547013283,
        -0.06237723305821419,
        0.06289242208003998,
        0.052425555884838104,
        0.0145715968683362,
        0.040820565074682236,
        -0.04274168238043785,
        -0.027151009067893028,
        -0.058869972825050354,
        0.06185920163989067,
        0.005620767828077078,
        0.06096121296286583,
        -0.020406797528266907,
        -0.06371466815471649,
        0.025569552555680275,
        0.05944075435400009,
        0.008994091302156448,
        -0.020898183807730675,
        -0.03879179432988167,
        0.05340627208352089,
        0.053764671087265015,
        -0.05984824523329735,
        -0.01846316270530224,
        0.05180465057492256,
        -0.05835576355457306,
        -0.021932566538453102,
        -0.04354716092348099,
        -0.06056467071175575,
        0.04521498456597328,
        -0.00903793890029192,
        -0.062151845544576645,
        -0.057904455810785294,
        -0.02440679259598255,
        -0.05612124130129814,
        0.031085260212421417,
        0.023371156305074692,
        -0.002973370486870408,
        -0.035014428198337555,
        0.05367101728916168,
        0.060374338179826736,
        -0.04147754982113838,
        0.008455992676317692,
        0.05476025119423866,
        0.060643650591373444,
        0.05301956459879875,
        0.05917789787054062,
        -0.022368622943758965,
        0.015292893163859844,
        -0.05738518759608269,
        -0.0009318036609329283,
        0.004996544681489468,
        -0.0285579152405262,
        0.0008469108724966645,
        -0.053990162909030914,
        -0.042360253632068634,
        0.015331238508224487,
        -0.05594143643975258,
        0.0625884011387825,
        -0.054725900292396545,
        -0.04606516659259796,
        -0.04100682958960533,
        -0.04254904016852379,
        -0.06273390352725983,
        0.03928050026297569,
        -0.05887940153479576,
        -0.061627037823200226,
        0.059182874858379364,
        -0.007734088692814112,
        0.010325143113732338,
        -0.027159394696354866,
        0.014473066665232182,
        0.06402169913053513,
        -0.04203416407108307,
        -0.020952023565769196,
        -0.003948398865759373,
        0.05986681953072548,
        -0.046921905130147934,
        0.025601105764508247,
        0.037460338324308395,
        0.05778288096189499,
        0.06274014711380005,
        -0.03902779892086983,
        0.015857115387916565,
        0.03296776860952377,
        -0.01142018660902977,
        0.002336324891075492,
        -0.06398176401853561,
        -0.0033726240508258343,
        -0.040194571018218994,
        0.05629386380314827,
        -0.03221983090043068,
        -0.05604458227753639,
        0.06163393706083298,
        -0.01639646664261818,
        -0.06286371499300003,
        0.05638068914413452,
        0.043728068470954895,
        0.022275831550359726,
        0.021925266832113266,
        -0.0627988651394844,
        0.028301913291215897,
        0.05969167500734329,
        -0.051076170057058334,
        -0.009499197825789452,
        -0.021624352782964706,
        0.006492302753031254,
        -0.037599075585603714,
        0.0008975606760941446,
        -0.04864061251282692,
        -0.03557761013507843,
        0.04976288229227066,
        -0.04067298024892807,
        0.06332139670848846,
        0.033728111535310745,
        0.0005859125521965325,
        0.06181390956044197,
        0.05673838406801224,
        -0.042215146124362946,
        -0.036180898547172546,
        -0.06343717873096466,
        0.0008360476931557059,
        -0.056582577526569366,
        0.05593472719192505,
        0.05036068335175514,
        0.06219204515218735,
        -0.04542788863182068,
        -0.061722833663225174,
        -0.036102328449487686,
        -0.05617053061723709,
        -0.06316202133893967,
        0.056656647473573685,
        0.0318123884499073,
        -0.05990079790353775,
        -0.027257194742560387,
        0.06193297728896141,
        -0.027800843119621277,
        0.05776115506887436,
        -0.03527000918984413,
        0.05773068591952324,
        0.015171317383646965,
        0.011480806395411491,
        -0.057709433138370514,
        0.045224711298942566,
        -0.007913217879831791,
        0.03855405002832413,
        -0.06308362632989883,
        0.033908549696207047,
        -0.022324055433273315,
        0.06195428967475891,
        -0.04382926598191261,
        -0.02494608424603939,
        -0.01783260703086853,
        -0.02436133660376072,
        0.04830216243863106,
        0.06136150285601616,
        0.00016459690232295543,
        0.05402468144893646,
        0.03862975910305977,
        0.014355776831507683,
        0.00944504514336586,
        0.011775320395827293,
        0.053999051451683044,
        -0.03518529608845711,
        0.01591537892818451,
        -0.03413877263665199,
        0.05185149237513542,
        -0.007131658494472504,
        -0.04037158936262131,
        0.03972996771335602,
        0.060442786663770676,
        -0.02888399176299572,
        0.006259564310312271,
        0.038105085492134094,
        -0.05853889137506485,
        -0.011366822756826878,
        0.06400387734174728,
        0.025324411690235138,
        0.01708018034696579,
        0.02403430826961994,
        0.0557059571146965,
        -0.06207301467657089,
        0.04899512603878975,
        -0.04093589261174202,
        0.0008614288526587188,
        -0.001338680274784565,
        0.019773557782173157,
        -0.051358699798583984,
        -0.04230993986129761,
        -0.04950454458594322,
        0.02138998918235302,
        -0.05723348632454872,
        0.01293979026377201,
        -0.04965944588184357,
        0.003129302989691496,
        0.037797894328832626,
        -0.04138738662004471,
        -0.01852433569729328,
        0.028684653341770172,
        -0.048859789967536926,
        0.028811609372496605,
        0.02756352908909321,
        0.05129406228661537,
        -0.038955096155405045,
        0.052332472056150436,
        -0.05556713044643402,
        0.05943942442536354,
        0.02612236514687538,
        0.05300753936171532,
        -0.002099429490044713,
        -0.04498846083879471,
        0.056723687797784805,
        -0.04380043223500252,
        0.06126631423830986,
        -0.040107183158397675,
        -0.03987213969230652,
        0.06292468309402466,
        -0.043105851858854294,
        0.02529568038880825,
        0.05820434167981148,
        0.028187843039631844,
        -0.02255677431821823,
        0.04061923921108246,
        0.06073212996125221,
        -0.04158778116106987,
        0.05581878870725632,
        -0.058335572481155396,
        -0.03487695753574371,
        0.04832761362195015,
        -0.007448054384440184,
        0.06227579712867737,
        0.059616029262542725,
        -0.039468102157115936,
        0.015453058294951916,
        0.06364428997039795,
        0.04335378110408783,
        0.05482011288404465,
        -0.06250298768281937,
        0.02073374204337597,
        0.06137546896934509,
        0.05469304323196411,
        -0.050789061933755875,
        -0.00034343887818977237,
        -0.035099565982818604,
        -0.032703109085559845,
        -0.06196862831711769,
        -0.06168549880385399,
        -0.04519594460725784,
        -0.0597180537879467,
        -0.061702318489551544,
        0.05122942477464676,
        -0.05685039609670639,
        -0.017806120216846466,
        0.03504030033946037,
        -0.048199694603681564,
        0.0605183020234108,
        -0.04208383336663246,
        0.031083637848496437,
        0.007766254246234894,
        -0.017277318984270096,
        0.03809152916073799,
        0.04479862004518509,
        0.052201662212610245,
        -0.05699251592159271,
        0.023777790367603302,
        -0.03296017274260521,
        -0.05236406251788139,
        0.06374001502990723,
        -0.046689722687006,
        -0.054272301495075226,
        0.026503654196858406,
        -0.052757374942302704,
        0.008570808917284012,
        -0.05303964763879776,
        -0.05967968702316284,
        -0.057372741401195526,
        -0.055778536945581436,
        -0.06129433959722519,
        0.050511520355939865,
        0.05913776531815529,
        -0.007218707352876663,
        0.02812992036342621,
        0.06073474511504173,
        0.058718420565128326,
        0.04825851693749428,
        -0.014666109345853329,
        0.05101172626018524,
        -0.053757499903440475,
        -0.025483354926109314,
        0.057628169655799866,
        -0.0607072114944458,
        0.056657034903764725,
        0.04502531886100769,
        -0.057269856333732605,
        0.04867947846651077,
        0.05916976556181908,
        0.04507957771420479,
        -0.03420286998152733,
        0.04239535704255104,
        -0.0360838919878006,
        -0.0011264340719208121,
        -0.05881049484014511,
        0.04418989643454552,
        0.001219777506776154,
        -0.023188311606645584,
        0.005671226419508457,
        0.03157278895378113,
        -0.0407409705221653,
        0.03826344385743141,
        0.04168493300676346,
        -0.058532845228910446,
        -0.03973618149757385,
        -0.05740334838628769,
        0.03342340141534805,
        -0.04603234678506851,
        0.018708840012550354,
        -0.05958407372236252,
        0.020565275102853775,
        0.0564892441034317,
        0.052508264780044556,
        -0.030047986656427383,
        0.06197072193026543,
        0.026532819494605064,
        0.05498495325446129,
        -0.014582233503460884,
        0.007457504514604807,
        -0.02485055662691593,
        -0.0365833044052124,
        -0.018648291006684303,
        0.052937101572752,
        0.038398485630750656,
        -0.05255836620926857,
        0.047719959169626236,
        0.019379151985049248,
        0.054222676903009415,
        -0.026938173919916153,
        0.037393778562545776,
        -0.055374231189489365,
        0.013584937900304794,
        0.04145539551973343,
        0.04442840814590454,
        0.03112574853003025,
        0.05853163078427315,
        0.015619629062712193,
        0.04915129020810127,
        0.049581460654735565,
        0.003594702575355768,
        -0.03808330371975899,
        -0.02596508525311947,
        0.031022675335407257,
        0.05011827498674393,
        -0.062379226088523865,
        -0.05684767663478851,
        0.035959865897893906,
        -0.05249709635972977,
        0.04817685857415199,
        -0.041798509657382965,
        0.04799208790063858,
        -0.0008959047845564783,
        -0.022323429584503174,
        -0.054266199469566345,
        0.05480673909187317,
        0.05436902120709419,
        0.036705758422613144,
        -0.06080429628491402,
        -0.03918423503637314,
        -0.002414435613900423,
        0.06224137544631958,
        0.02411656081676483,
        0.04761107265949249,
        -0.007846013642847538,
        -0.05317678675055504,
        0.05890690162777901,
        0.06281374394893646,
        0.04198699817061424,
        0.04556618630886078,
        -0.04058003053069115,
        -0.06348894536495209,
        0.03934657946228981,
        0.06194338947534561,
        -0.017185715958476067,
        0.03922991082072258,
        -0.05003334581851959,
        -0.04456767067313194,
        0.018547162413597107,
        -0.06344863772392273,
        -0.013828469440340996,
        0.02053588442504406,
        -0.019920025020837784,
        -0.005199905950576067,
        0.05954543501138687,
        0.06243348494172096,
        0.042255427688360214,
        0.03442871570587158,
        -0.06070626154541969,
        -0.013093838468194008,
        0.06105685234069824,
        0.012937207706272602,
        -0.0626000314950943,
        -0.03739839419722557,
        -0.04092198610305786,
        0.0006475778645835817,
        -0.02968071959912777,
        0.05703716725111008,
        0.002800829242914915,
        -0.035694804042577744,
        -0.05965714529156685,
        0.057619474828243256,
        -0.007672756444662809,
        -0.009003973565995693,
        -0.025998126715421677,
        0.024884307757019997,
        0.03775881603360176,
        0.025508269667625427,
        0.049547918140888214,
        0.01885995827615261,
        -0.05899374559521675,
        0.02710247039794922,
        -0.05699310079216957,
        0.04639120772480965,
        -0.0554182305932045,
        -0.04736066237092018,
        0.01597476191818714,
        0.02442985028028488,
        -0.051243945956230164,
        0.053170345723629,
        -0.019737163558602333,
        -0.06074969843029976,
        -0.04183564707636833,
        0.038556743413209915,
        -0.056345146149396896,
        -0.025461453944444656,
        0.04877467080950737,
        0.05392405390739441,
        -0.028034191578626633,
        0.05458245426416397,
        0.06288682669401169,
        0.06028589978814125,
        0.0511050783097744,
        0.06219885125756264,
        0.044676441699266434,
        0.06125146895647049,
        -0.05450017377734184,
        0.05488749220967293,
        0.060149434953927994,
        -0.058942653238773346,
        -0.0009837814141064882,
        -0.019887035712599754,
        0.03281388431787491,
        -0.059847597032785416,
        -0.06183639168739319,
        -0.02166256308555603,
        -0.029202474281191826,
        -0.04572860896587372,
        -0.005107630509883165,
        0.017991986125707626,
        -0.04894760996103287,
        -0.05503665283322334,
        0.06188736855983734,
        0.022293105721473694,
        0.062159936875104904,
        -0.05050120875239372,
        0.04101592302322388,
        -0.06271779537200928,
        -0.058746758848428726,
        0.04038926213979721,
        0.04545264691114426,
        -0.010851343162357807,
        0.052375856786966324,
        -0.06237537041306496,
        0.005060925148427486,
        0.008633555844426155,
        0.012108782306313515,
        -0.03339916467666626,
        -0.05826006829738617,
        -0.050828490406274796,
        -0.061030562967061996,
        -0.05585244297981262,
        0.03626195341348648,
        0.01826353743672371,
        0.04253346472978592,
        0.060179702937603,
        0.0561353825032711,
        -0.04421067237854004,
        -0.04657801613211632,
        -0.047742608934640884,
        -0.0055663101375103,
        -0.005659554153680801,
        0.032241590321063995,
        0.006883962545543909,
        0.02739984355866909,
        0.0597609244287014,
        0.03910018503665924,
        0.054042428731918335,
        0.0596344955265522,
        -0.06297321617603302,
        -0.0450935922563076,
        -0.0434037409722805,
        0.02928604744374752,
        0.06301292777061462,
        -0.05954797565937042,
        0.04305170848965645,
        0.016140609979629517,
        0.01590552367269993,
        -0.06308227777481079,
        0.026726404204964638,
        0.04944899305701256
    ],
    [
        -0.035608261823654175,
        0.047571275383234024,
        0.059653669595718384,
        0.05469873920083046,
        -0.048706408590078354,
        0.01665686070919037,
        -0.0341651514172554,
        -0.06235557422041893,
        0.06232042238116264,
        0.05288074165582657,
        0.023775912821292877,
        0.03348059579730034,
        -0.04092833399772644,
        -0.001166536589153111,
        -0.05923239141702652,
        0.05831723287701607,
        0.010056711733341217,
        0.06154167279601097,
        -0.013011650182306767,
        -0.06339173018932343,
        0.023650724440813065,
        0.06002088636159897,
        0.006692265160381794,
        -0.02942313440144062,
        -0.022344473749399185,
        0.05346861854195595,
        0.0510452501475811,
        -0.05787726119160652,
        -0.01453713234513998,
        0.05177629739046097,
        -0.054804325103759766,
        -0.005547333974391222,
        -0.048980046063661575,
        -0.06192643940448761,
        0.048683471977710724,
        -0.009127014316618443,
        -0.062172841280698776,
        -0.0594276487827301,
        0.018736518919467926,
        -0.05671851336956024,
        0.024475742131471634,
        0.027615079656243324,
        -0.009739991277456284,
        -0.010767355561256409,
        0.04135680943727493,
        0.06058945506811142,
        -0.05095835030078888,
        -0.008758114650845528,
        0.058531589806079865,
        0.06000499054789543,
        0.04703439027070999,
        0.05662434175610542,
        -0.02550966665148735,
        0.000031474948627874255,
        -0.05507718399167061,
        0.005346992518752813,
        -0.01175740361213684,
        -0.020083535462617874,
        0.00994530227035284,
        -0.05103408917784691,
        -0.03334629163146019,
        0.032342419028282166,
        -0.05461106449365616,
        0.061573270708322525,
        -0.051286060363054276,
        -0.05513550341129303,
        -0.04609052836894989,
        -0.04122038185596466,
        -0.06266060471534729,
        0.03804580867290497,
        -0.05575796961784363,
        -0.06317615509033203,
        0.060220636427402496,
        -0.0033576213754713535,
        0.008059266023337841,
        -0.017518337815999985,
        0.016818970441818237,
        0.06372677534818649,
        -0.035014115273952484,
        -0.0009445096948184073,
        -0.004036435391753912,
        0.058042075484991074,
        -0.051767218858003616,
        0.03225620090961456,
        0.04344658926129341,
        0.05783480778336525,
        0.06253762543201447,
        -0.04426223039627075,
        0.01731712371110916,
        0.03466762602329254,
        -0.011296470649540424,
        -0.00012857196270488203,
        -0.06368041783571243,
        0.006351293530315161,
        -0.032301850616931915,
        0.056272946298122406,
        -0.03754616528749466,
        -0.05427255854010582,
        0.061179760843515396,
        -0.009136728942394257,
        -0.06241513416171074,
        0.055983513593673706,
        0.039837755262851715,
        0.010200700722634792,
        0.0024375193752348423,
        -0.06142253428697586,
        0.021008824929594994,
        0.05666632950305939,
        -0.05477612093091011,
        -0.003387985983863473,
        0.003190921386703849,
        0.01873328536748886,
        -0.041959505528211594,
        -0.006583758629858494,
        -0.05619911476969719,
        -0.048486754298210144,
        0.052682556211948395,
        -0.039602361619472504,
        0.06324001401662827,
        0.037451326847076416,
        -0.01385406218469143,
        0.06200885772705078,
        0.05429297313094139,
        -0.03586091473698616,
        -0.02828936092555523,
        -0.06325582414865494,
        -0.008410515263676643,
        -0.04900972545146942,
        0.05352430045604706,
        0.03997301682829857,
        0.0594346821308136,
        -0.04504425451159477,
        -0.05934767425060272,
        -0.03971171751618385,
        -0.0551326721906662,
        -0.06279691308736801,
        0.05730442330241203,
        0.024203980341553688,
        -0.054376013576984406,
        -0.022134801372885704,
        0.06261087208986282,
        -0.03354689106345177,
        0.05643897503614426,
        -0.04239700362086296,
        0.060030821710824966,
        0.010308968834578991,
        0.02148115634918213,
        -0.052815601229667664,
        0.04448516294360161,
        -0.01728506200015545,
        0.05227101966738701,
        -0.06278197467327118,
        -0.014033149927854538,
        -0.006832254584878683,
        0.06258852779865265,
        -0.04644273594021797,
        -0.021723002195358276,
        -0.028775598853826523,
        -0.028139283880591393,
        0.05090789869427681,
        0.06117819994688034,
        0.012807220220565796,
        0.051372919231653214,
        0.04593002796173096,
        0.009327140636742115,
        -0.020709434524178505,
        0.014149132184684277,
        0.05478036776185036,
        -0.028340233489871025,
        0.022031046450138092,
        -0.04934331029653549,
        0.05307871475815773,
        -0.022483203560113907,
        -0.040563102811574936,
        0.009469890035688877,
        0.06103277578949928,
        -0.03242667391896248,
        -0.0004991984460502863,
        0.03389575332403183,
        -0.055923573672771454,
        -0.012992356903851032,
        0.06365373730659485,
        0.007236032281070948,
        0.03725674748420715,
        0.02734548971056938,
        0.057076696306467056,
        -0.0619097538292408,
        0.0464034378528595,
        -0.03836271911859512,
        -0.03213287144899368,
        -0.016037506982684135,
        0.01853277161717415,
        -0.05361592024564743,
        -0.04073479026556015,
        -0.04466899111866951,
        0.03542202338576317,
        -0.05395222827792168,
        0.007727670483291149,
        -0.050206258893013,
        -0.0072136977687478065,
        0.04821411892771721,
        -0.04321669414639473,
        0.0074750808998942375,
        0.04212305694818497,
        -0.043027956038713455,
        0.023456329479813576,
        0.03460593521595001,
        0.05374712869524956,
        -0.04762457311153412,
        0.05091173201799393,
        -0.05471841245889664,
        0.05732894688844681,
        0.022351890802383423,
        0.04520643875002861,
        -0.0016484236111864448,
        -0.03851761668920517,
        0.0596105232834816,
        -0.032959382981061935,
        0.0616610050201416,
        -0.027824457734823227,
        -0.04436218738555908,
        0.06196029111742973,
        -0.04364088922739029,
        0.030995797365903854,
        0.06085408478975296,
        -0.02208799123764038,
        -0.024540649726986885,
        0.03069593943655491,
        0.060044195502996445,
        -0.044741615653038025,
        0.058814067393541336,
        -0.06140509247779846,
        -0.023228468373417854,
        0.04315846040844917,
        -0.003473069751635194,
        0.062175918370485306,
        0.05863535404205322,
        -0.03823240101337433,
        0.028208229690790176,
        0.0634380355477333,
        0.0394294373691082,
        0.05137479305267334,
        -0.061944399029016495,
        0.02597728744149208,
        0.06135508045554161,
        0.05312472954392433,
        -0.04531583935022354,
        0.003357187146320939,
        -0.036935556679964066,
        -0.036503590643405914,
        -0.061383720487356186,
        -0.06172766909003258,
        -0.0439005009829998,
        -0.059715237468481064,
        -0.06055967137217522,
        0.04572228342294693,
        -0.05078595504164696,
        -0.03806860372424126,
        0.023512573912739754,
        -0.03891834244132042,
        0.06061559543013573,
        -0.040691621601581573,
        0.03645334020256996,
        0.01086744386702776,
        -0.0075517273508012295,
        0.04602481797337532,
        0.037916816771030426,
        0.036892205476760864,
        -0.058497026562690735,
        0.014917664229869843,
        -0.03749673068523407,
        -0.051775071769952774,
        0.06352898478507996,
        -0.054452456533908844,
        -0.053102411329746246,
        0.018220527097582817,
        -0.04959344118833542,
        0.014981983229517937,
        -0.054301321506500244,
        -0.06018815562129021,
        -0.05819087103009224,
        -0.0591726079583168,
        -0.062190137803554535,
        0.04991789534687996,
        0.05985637754201889,
        0.006409714929759502,
        0.026243222877383232,
        0.06064535304903984,
        0.05883617326617241,
        0.0519355870783329,
        -0.012382538057863712,
        0.04551991447806358,
        -0.053630370646715164,
        -0.02479596622288227,
        0.055950261652469635,
        -0.06008877977728844,
        0.052425142377614975,
        0.040324050933122635,
        -0.054022081196308136,
        0.051999494433403015,
        0.05908416956663132,
        0.05215148255228996,
        -0.028877820819616318,
        0.049648717045784,
        -0.048606544733047485,
        -0.005802839528769255,
        -0.05772983655333519,
        0.048224497586488724,
        0.008373782970011234,
        -0.04965624213218689,
        -0.0027152893599122763,
        0.03476053848862648,
        -0.03245722874999046,
        0.04313626512885094,
        0.0464305505156517,
        -0.0586729533970356,
        -0.03558874875307083,
        -0.05436268076300621,
        0.03822450712323189,
        -0.04319727048277855,
        0.011565261520445347,
        -0.05880121886730194,
        0.013876620680093765,
        0.059582676738500595,
        0.0551142580807209,
        -0.035944532603025436,
        0.061886947602033615,
        0.0035635221283882856,
        0.053860943764448166,
        -0.01262888964265585,
        0.005978541914373636,
        -0.005811440292745829,
        -0.015469561330974102,
        -0.012938914820551872,
        0.05040054768323898,
        0.026246841996908188,
        -0.055896684527397156,
        0.054822888225317,
        0.018371544778347015,
        0.0519481860101223,
        -0.023666149005293846,
        0.04106203094124794,
        -0.046658556908369064,
        0.008456257171928883,
        0.04560229554772377,
        0.043839167803525925,
        0.041806258261203766,
        0.057434000074863434,
        0.02886875346302986,
        0.04710095748305321,
        0.05128489434719086,
        -0.010425141081213951,
        -0.037775635719299316,
        -0.014615480788052082,
        0.025742508471012115,
        0.05496510490775108,
        -0.06074245274066925,
        -0.05662420764565468,
        0.031536903232336044,
        -0.05542498081922531,
        0.05160446837544441,
        -0.0380590483546257,
        0.05508336052298546,
        0.010339055210351944,
        -0.035187166184186935,
        -0.04887852072715759,
        0.05547742173075676,
        0.04823201149702072,
        0.04531850665807724,
        -0.05968635529279709,
        -0.04488898813724518,
        -0.008440639823675156,
        0.06256425380706787,
        0.014899898320436478,
        0.047799576073884964,
        -0.011986609548330307,
        -0.052733879536390305,
        0.05564456805586815,
        0.06215684115886688,
        0.05023793876171112,
        0.052398987114429474,
        -0.04643087834119797,
        -0.06282703578472137,
        0.04400290548801422,
        0.06102599576115608,
        -0.014185779727995396,
        0.04642151668667793,
        -0.045034777373075485,
        -0.03243497759103775,
        0.023396126925945282,
        -0.06342267990112305,
        -0.005236020311713219,
        0.020347723737359047,
        -0.03397263213992119,
        0.006831383798271418,
        0.059746354818344116,
        0.06259267032146454,
        0.05086492747068405,
        0.03361503779888153,
        -0.06138802692294121,
        -0.024775562807917595,
        0.060966942459344864,
        -0.0020323616918176413,
        -0.06182577461004257,
        -0.044940050691366196,
        -0.043624650686979294,
        0.0040783449076116085,
        -0.025084806606173515,
        0.054741956293582916,
        0.006787053309381008,
        -0.03162384405732155,
        -0.06037425249814987,
        0.05750177800655365,
        0.018157409504055977,
        -0.009151523001492023,
        -0.03025119937956333,
        0.020523330196738243,
        0.05468856543302536,
        0.034216031432151794,
        0.05597754940390587,
        0.020685916766524315,
        -0.058632928878068924,
        0.02293977700173855,
        -0.05643824487924576,
        0.05721999332308769,
        -0.05994775891304016,
        -0.04189492389559746,
        0.01659524254500866,
        0.014218934811651707,
        -0.04908684268593788,
        0.05390063673257828,
        -0.008966638706624508,
        -0.061815887689590454,
        -0.04413750767707825,
        0.04475369676947594,
        -0.05822691321372986,
        -0.045489996671676636,
        0.041669927537441254,
        0.046639081090688705,
        -0.020676294341683388,
        0.05519408732652664,
        0.062272511422634125,
        0.06113481894135475,
        0.052370812743902206,
        0.05723916366696358,
        0.04432183876633644,
        0.058685969561338425,
        -0.05561438947916031,
        0.05555197596549988,
        0.05645420029759407,
        -0.05576077848672867,
        0.010149188339710236,
        -0.007803086191415787,
        0.027251793071627617,
        -0.06058763340115547,
        -0.061323605477809906,
        -0.032581113278865814,
        -0.03391702473163605,
        -0.052356742322444916,
        -0.009984293952584267,
        -0.020538942888379097,
        -0.05113982781767845,
        -0.056678060442209244,
        0.06086103618144989,
        0.024411605671048164,
        0.06234283372759819,
        -0.04938361793756485,
        0.040705837309360504,
        -0.06303532421588898,
        -0.058561380952596664,
        0.05134747177362442,
        0.059874050319194794,
        -0.016206540167331696,
        0.05502887815237045,
        -0.06124456971883774,
        0.006982437334954739,
        -0.006811740808188915,
        0.009889169596135616,
        -0.017391806468367577,
        -0.05849343165755272,
        -0.04908444359898567,
        -0.06102883443236351,
        -0.05391307547688484,
        0.03886912390589714,
        -0.013071681372821331,
        0.03612201660871506,
        0.057179875671863556,
        0.05726733058691025,
        -0.044516898691654205,
        -0.05270058289170265,
        -0.054148681461811066,
        -0.0055023059248924255,
        -0.0006294879131019115,
        0.021743152290582657,
        0.01009279116988182,
        0.03615254908800125,
        0.05973459780216217,
        0.04220018535852432,
        0.052871543914079666,
        0.05910947173833847,
        -0.06096411868929863,
        -0.048328597098588943,
        -0.02631501294672489,
        0.024975840002298355,
        0.06190844625234604,
        -0.0561838261783123,
        0.04349609091877937,
        0.004127181135118008,
        0.005110525526106358,
        -0.06222406402230263,
        0.04085130989551544,
        0.05678517743945122
    ],
    [
        -0.04629771038889885,
        0.050923459231853485,
        0.05255114287137985,
        0.04380394145846367,
        -0.051335498690605164,
        -0.04001498222351074,
        0.03430672362446785,
        -0.05200069397687912,
        0.05255143716931343,
        0.045892611145973206,
        0.04925699532032013,
        0.04951119422912598,
        -0.05244472622871399,
        0.041553277522325516,
        -0.052554644644260406,
        0.04804842546582222,
        0.00790717825293541,
        0.05245974287390709,
        0.0525474026799202,
        -0.05264927074313164,
        0.00958301778882742,
        0.045285988599061966,
        0.04471144080162048,
        -0.008537809364497662,
        -0.052540816366672516,
        0.05185336247086525,
        0.03393923118710518,
        -0.04597393050789833,
        -0.050856828689575195,
        0.048630889505147934,
        -0.052613768726587296,
        0.0492292121052742,
        0.00981423445045948,
        -0.05248717963695526,
        0.05141083896160126,
        -0.05122920870780945,
        -0.05256735160946846,
        -0.05262758582830429,
        0.05264735966920853,
        -0.052412424236536026,
        0.04042353481054306,
        0.029703998938202858,
        0.046715471893548965,
        0.04483823850750923,
        0.05029122158885002,
        -0.025782234966754913,
        -0.05217830836772919,
        0.05202770605683327,
        0.05264350399374962,
        0.047456614673137665,
        0.05134650319814682,
        0.01666671223938465,
        0.03192763403058052,
        -0.052404146641492844,
        -0.05263959616422653,
        0.05235661193728447,
        -0.03367282822728157,
        -0.03459055349230766,
        -0.05012764409184456,
        -0.052235398441553116,
        -0.051366474479436874,
        0.03881540894508362,
        -0.05257340520620346,
        0.025724833831191063,
        -0.017980579286813736,
        -0.05253905430436134,
        -0.052625976502895355,
        -0.051781803369522095,
        -0.039424728602170944,
        0.050166092813014984,
        0.006772236432880163,
        -0.05052966624498367,
        0.04863913729786873,
        -0.025497326627373695,
        0.0025250574108213186,
        -0.02145955339074135,
        0.046494558453559875,
        0.05265337601304054,
        0.03128291293978691,
        0.014968673698604107,
        -0.043131228536367416,
        0.017875248566269875,
        -0.0522991418838501,
        0.03565782308578491,
        -0.0506533645093441,
        0.05105337128043175,
        0.04974259063601494,
        -0.021471841260790825,
        -0.0526270791888237,
        0.05065677687525749,
        -0.0525994673371315,
        -0.052351705729961395,
        -0.05265231058001518,
        -0.030199462547898293,
        -0.012926832772791386,
        0.02107279747724533,
        -0.05033710226416588,
        -0.05051865056157112,
        0.052539851516485214,
        0.05235759913921356,
        -0.05265257507562637,
        0.05178697407245636,
        0.051923658698797226,
        0.040150657296180725,
        -0.035584956407547,
        -0.05250799283385277,
        0.008605041541159153,
        0.05204528942704201,
        -0.048163317143917084,
        -0.051161326467990875,
        0.05240993574261665,
        -0.044707655906677246,
        0.038172073662281036,
        -0.03901083022356033,
        0.009188773110508919,
        -0.05154993012547493,
        0.028894076123833656,
        -0.05034744367003441,
        0.0492185577750206,
        -0.04990571737289429,
        -0.028797488659620285,
        -0.034015607088804245,
        0.05248107388615608,
        -0.05089785158634186,
        -0.036507926881313324,
        -0.05262882262468338,
        0.050189826637506485,
        -0.03187134489417076,
        0.05182570219039917,
        0.04629770666360855,
        0.0526278093457222,
        -0.047828495502471924,
        -0.05229432135820389,
        -0.03563365340232849,
        -0.05087650194764137,
        -0.05264511704444885,
        0.05265245586633682,
        -0.05182674154639244,
        0.0024059126153588295,
        -0.041713036596775055,
        0.01194729469716549,
        -0.05259912833571434,
        0.05101935192942619,
        0.035273656249046326,
        0.05069274082779884,
        0.027784377336502075,
        0.01506035402417183,
        -0.05229935422539711,
        0.05246664956212044,
        -0.05256739258766174,
        -0.0476812981069088,
        -0.05264127627015114,
        -0.05127440020442009,
        0.05221470445394516,
        0.05261237919330597,
        -0.006293585058301687,
        -0.04703233391046524,
        -0.036675237119197845,
        -0.013052111491560936,
        0.01076057180762291,
        0.05244920030236244,
        -0.04940420389175415,
        0.05252993479371071,
        0.034088775515556335,
        -0.03780946135520935,
        -0.043058861047029495,
        0.04197228327393532,
        0.0493934266269207,
        -0.05247919633984566,
        0.026929302141070366,
        -0.0523994006216526,
        0.05045454204082489,
        -0.051538798958063126,
        0.052121493965387344,
        -0.04449260234832764,
        0.040798988193273544,
        0.05259833484888077,
        0.008418465033173561,
        0.05111192911863327,
        -0.04939205199480057,
        -0.04286469891667366,
        0.052652135491371155,
        0.05181979387998581,
        -0.052343763411045074,
        -0.02868722565472126,
        0.05251835286617279,
        -0.0519997663795948,
        0.050795778632164,
        0.017108049243688583,
        -0.05003944784402847,
        -0.04870156943798065,
        0.028086284175515175,
        -0.051111843436956406,
        0.016694456338882446,
        -0.05174102261662483,
        0.044918935745954514,
        -0.04370993748307228,
        0.0385693721473217,
        -0.0492415726184845,
        0.05044334754347801,
        0.05121936276555061,
        -0.018205154687166214,
        0.007710660807788372,
        0.03247789293527603,
        -0.023629434406757355,
        0.02084256522357464,
        0.052524320781230927,
        0.052433837205171585,
        -0.03703085705637932,
        0.05244021117687225,
        -0.05098142474889755,
        0.05195186659693718,
        0.0499955415725708,
        0.05257493630051613,
        -0.0045781307853758335,
        -0.05265282467007637,
        0.05228458344936371,
        -0.043384160846471786,
        0.05141262710094452,
        -0.030260996893048286,
        -0.032839056104421616,
        0.052589453756809235,
        0.045296914875507355,
        0.050568725913763046,
        0.05259324237704277,
        -0.0002366654807701707,
        -0.019423803314566612,
        0.02984415553510189,
        0.052628904581069946,
        -0.052459631115198135,
        0.04658288136124611,
        -0.05229583755135536,
        -0.04577401652932167,
        0.05146264284849167,
        0.0455070398747921,
        0.05070396140217781,
        0.05230264738202095,
        -0.027693241834640503,
        0.04737822338938713,
        0.052653227001428604,
        0.05256134271621704,
        -0.046003665775060654,
        -0.0010791118256747723,
        -0.05213329941034317,
        0.05262555181980133,
        0.05261440947651863,
        0.05126727372407913,
        0.03763629496097565,
        -0.00025817585992626846,
        -0.014114072546362877,
        -0.0520995669066906,
        -0.052619364112615585,
        -0.050602417439222336,
        -0.05217796564102173,
        -0.052224159240722656,
        0.012455468066036701,
        0.04852240905165672,
        0.021296393126249313,
        0.05187508836388588,
        -0.04648394510149956,
        -0.04904849827289581,
        -0.03915507346391678,
        0.05183498188853264,
        -0.01971954107284546,
        -0.051248058676719666,
        0.05191286280751228,
        0.05264275521039963,
        0.049320537596940994,
        -0.04875463619828224,
        0.004374064039438963,
        -0.05260394141077995,
        -0.051618169993162155,
        0.052406877279281616,
        -0.052214618772268295,
        -0.027377625927329063,
        0.04391057416796684,
        -0.05217774957418442,
        -0.03762349858880043,
        -0.04520322009921074,
        -0.05260332673788071,
        -0.050792109221220016,
        0.020231690257787704,
        -0.045842207968235016,
        -0.015319796279072762,
        0.05231916159391403,
        0.05011596903204918,
        0.01782291755080223,
        0.05263245105743408,
        0.050137557089328766,
        0.04079679399728775,
        -0.04663626477122307,
        0.05059576779603958,
        -0.04998299106955528,
        -0.05261301249265671,
        0.05170257017016411,
        -0.05030106008052826,
        0.0525427870452404,
        -0.02847318723797798,
        -0.05207791179418564,
        0.05212536081671715,
        0.050061095505952835,
        0.03816155344247818,
        -0.029972318559885025,
        0.04941670969128609,
        0.004702236503362656,
        -0.02399524487555027,
        -0.05150791257619858,
        0.04818226397037506,
        0.026541894301772118,
        0.0323043093085289,
        0.006811683531850576,
        0.020546138286590576,
        0.033879030495882034,
        0.05242065712809563,
        0.04675689712166786,
        -0.05254027619957924,
        -0.05206741765141487,
        -0.047914765775203705,
        0.04313342645764351,
        -0.01603790372610092,
        0.05141022056341171,
        -0.05263615399599075,
        0.048798564821481705,
        0.05193005129694939,
        0.05262301117181778,
        0.04123041033744812,
        0.05265334993600845,
        -0.05096353590488434,
        -0.007105569820851088,
        -0.043923404067754745,
        0.050441496074199677,
        0.006513600237667561,
        0.02210879884660244,
        0.04770704731345177,
        0.04337344691157341,
        0.03057422861456871,
        -0.0521450899541378,
        0.049424994736909866,
        0.05182734504342079,
        0.052427127957344055,
        -0.02801736816763878,
        -0.05190272256731987,
        0.05260482057929039,
        0.0502442866563797,
        0.02673572674393654,
        0.040396369993686676,
        0.0492728017270565,
        0.05255662649869919,
        0.009968296624720097,
        0.03102724254131317,
        0.05227941647171974,
        0.021441642194986343,
        0.0437721349298954,
        -0.0518140010535717,
        -0.019441571086645126,
        0.038192059844732285,
        -0.052650336176157,
        -0.0522126667201519,
        -0.049964696168899536,
        0.03887717425823212,
        0.05245288833975792,
        0.012406294234097004,
        0.02731153927743435,
        -0.04254449903964996,
        -0.05139193311333656,
        -0.05253921076655388,
        0.04930346459150314,
        0.05144067108631134,
        0.048523493111133575,
        -0.05260596424341202,
        -0.04870129004120827,
        -0.04843664914369583,
        0.0526522696018219,
        -0.04934534430503845,
        0.05237949639558792,
        0.04022499918937683,
        -0.002591557102277875,
        0.051521074026823044,
        0.05264294520020485,
        0.003743775188922882,
        0.03169263154268265,
        0.05247350409626961,
        -0.05241098999977112,
        -0.021081332117319107,
        0.05256228148937225,
        -0.05134754255414009,
        0.04306512326002121,
        -0.024233873933553696,
        -0.051932621747255325,
        -0.022937040776014328,
        -0.0524199977517128,
        0.030468590557575226,
        -0.004618094302713871,
        -0.03892473503947258,
        0.05193333327770233,
        0.05215263366699219,
        0.05265243351459503,
        -0.04387100785970688,
        -0.04868001490831375,
        -0.05253291130065918,
        -0.03587696701288223,
        0.0523126944899559,
        -0.04319631680846214,
        -0.022232752293348312,
        -0.04683498665690422,
        -0.00950329378247261,
        0.023778539150953293,
        -0.035482361912727356,
        0.052537430077791214,
        -0.05007488653063774,
        -0.016023360192775726,
        -0.051174893975257874,
        0.04967639222741127,
        -0.04177883267402649,
        -0.031148986890912056,
        0.012491535395383835,
        0.04941529780626297,
        0.05038587749004364,
        0.049536243081092834,
        0.05261165276169777,
        0.01671837456524372,
        -0.0526307187974453,
        -0.03073047660291195,
        -0.05265242978930473,
        -0.004832449369132519,
        -0.05259322747588158,
        -0.02549639530479908,
        -0.03615892305970192,
        -0.05104231834411621,
        -0.051669590175151825,
        0.04526115581393242,
        -0.030442338436841965,
        -0.020534345880150795,
        -0.03683798760175705,
        -0.004824489820748568,
        -0.05106890946626663,
        -0.05030818656086922,
        0.05208001658320427,
        0.05089364945888519,
        -0.05255488306283951,
        0.00520915724337101,
        0.05262826010584831,
        0.05260594189167023,
        0.051067620515823364,
        -0.05200445279479027,
        0.05164559930562973,
        0.052600711584091187,
        -0.051188480108976364,
        0.04030436649918556,
        0.05232499912381172,
        -0.042532507330179214,
        0.01832464523613453,
        -0.016929980367422104,
        0.014138821512460709,
        -0.05254591628909111,
        -0.05263926088809967,
        -0.05126520246267319,
        -0.04966205358505249,
        0.008568300865590572,
        -0.04671793803572655,
        0.05129124969244003,
        -0.05259597674012184,
        -0.016356034204363823,
        0.0525001585483551,
        0.04994271323084831,
        0.0525529682636261,
        -0.05221351236104965,
        0.0515313483774662,
        -0.0521751344203949,
        -0.0521816648542881,
        0.05252717435359955,
        -0.0017909979214891791,
        0.04544767737388611,
        0.05255739018321037,
        -0.04205316677689552,
        0.04963050037622452,
        -0.02426142804324627,
        -0.004288864322006702,
        -0.030819742009043694,
        -0.04171670973300934,
        -0.052611324936151505,
        -0.05182720720767975,
        -0.05089975520968437,
        0.052289169281721115,
        -0.043152760714292526,
        0.044869180768728256,
        0.05218486115336418,
        0.05259079486131668,
        -0.05228760838508606,
        -0.05156905949115753,
        -0.00455893948674202,
        -0.028644725680351257,
        0.027274342253804207,
        0.05216128006577492,
        -0.01871361769735813,
        0.04864149168133736,
        0.012619808316230774,
        -0.0483982190489769,
        0.05205825716257095,
        0.04572805389761925,
        -0.05259842425584793,
        -0.03406815230846405,
        -0.05140133202075958,
        0.04899473860859871,
        0.052652712911367416,
        0.010598765686154366,
        0.05243390053510666,
        -0.0492270290851593,
        0.045006945729255676,
        -0.03915653005242348,
        0.0524643175303936,
        -0.03056911751627922
    ],
    [
        0.0150431664660573,
        -0.05301964282989502,
        0.04599090293049812,
        -0.021380726248025894,
        -0.04926835373044014,
        -0.0530078262090683,
        -0.007114576641470194,
        -0.052914027124643326,
        0.052958838641643524,
        0.03485920652747154,
        -0.007426142692565918,
        -0.049069564789533615,
        -0.051757022738456726,
        0.013535785488784313,
        -0.04985048994421959,
        0.018848177045583725,
        -0.04218066483736038,
        0.05301779881119728,
        -0.04496607556939125,
        0.027516452595591545,
        0.0419430211186409,
        0.053019680082798004,
        0.048681288957595825,
        -0.0005513421492651105,
        -0.020579112693667412,
        -0.05022909492254257,
        0.027233952656388283,
        -0.011119605973362923,
        -0.05212244763970375,
        0.053019747138023376,
        -0.042470820248126984,
        -0.05299782007932663,
        0.0037189945578575134,
        -0.05301009118556976,
        0.05277132987976074,
        0.0387086495757103,
        -0.05285486578941345,
        -0.02790786698460579,
        0.05301777273416519,
        -0.050566233694553375,
        0.05274377018213272,
        0.02602257765829563,
        0.05040435865521431,
        -0.010637491941452026,
        -0.05202585831284523,
        0.032841868698596954,
        0.05267444998025894,
        0.05273182690143585,
        0.053017936646938324,
        -0.05301192030310631,
        0.0528157576918602,
        -0.03694722056388855,
        -0.006450495682656765,
        -0.01902477815747261,
        -0.05301075056195259,
        0.052943747490644455,
        0.052990589290857315,
        -0.03606358915567398,
        -0.019250864163041115,
        0.052797380834817886,
        -0.05277998372912407,
        -0.03942510858178139,
        -0.05299094319343567,
        0.0518590472638607,
        -0.011110437102615833,
        0.050101879984140396,
        -0.052971817553043365,
        -0.04708416759967804,
        0.052768874913454056,
        -0.031326547265052795,
        -0.03497165068984032,
        -0.05301930382847786,
        -0.005586508195847273,
        0.05054827407002449,
        0.04949213191866875,
        0.007331083528697491,
        -0.01748899556696415,
        0.05301773548126221,
        -0.033963773399591446,
        -0.03984280303120613,
        -0.04701509699225426,
        0.04412161558866501,
        -0.053010243922472,
        0.031383216381073,
        0.05280856043100357,
        0.052790913730859756,
        -0.02714860439300537,
        -0.022448759526014328,
        -0.0506594218313694,
        0.038520582020282745,
        -0.052701499313116074,
        -0.015751997008919716,
        -0.052994634956121445,
        -0.05112902820110321,
        -0.045809127390384674,
        0.047741860151290894,
        -0.042790308594703674,
        -0.05297807604074478,
        0.03548792749643326,
        0.022368580102920532,
        -0.05281873419880867,
        0.052849963307380676,
        0.05269410088658333,
        -0.017893927171826363,
        0.03548820689320564,
        -0.05282404273748398,
        0.04033661261200905,
        -0.030287565663456917,
        -0.020978597924113274,
        0.052633486688137054,
        -0.05301269143819809,
        0.05030102655291557,
        -0.052895437926054,
        0.042686473578214645,
        0.008891139179468155,
        -0.01565312035381794,
        0.0335129015147686,
        -0.05279914289712906,
        0.04981246963143349,
        0.05274515599012375,
        -0.05015948787331581,
        -0.024255812168121338,
        0.05014486238360405,
        -0.050617169588804245,
        -0.031672172248363495,
        -0.052584994584321976,
        0.05191996321082115,
        0.051418107002973557,
        0.04967281594872475,
        0.051721081137657166,
        0.0326802022755146,
        -0.05061565339565277,
        -0.03353472054004669,
        0.03507716953754425,
        -0.04201503098011017,
        -0.052335139364004135,
        0.05300997942686081,
        0.0520317368209362,
        -0.003271698020398617,
        0.017204197123646736,
        -0.05035387724637985,
        -0.024495314806699753,
        0.041541483253240585,
        0.015102270990610123,
        0.052725840359926224,
        -0.05202818661928177,
        0.030739732086658478,
        -0.05294833704829216,
        -0.05123103782534599,
        0.0512121357023716,
        -0.052970025688409805,
        -0.05301181972026825,
        0.04575252905488014,
        0.05277857929468155,
        0.0530196949839592,
        -0.025545069947838783,
        0.045174580067396164,
        0.00852818414568901,
        0.04721568152308464,
        0.03434827923774719,
        -0.05194246396422386,
        0.029209798201918602,
        0.052109356969594955,
        -0.004588687792420387,
        -0.05298931151628494,
        -0.0012037447886541486,
        0.05273020640015602,
        -0.026361482217907906,
        -0.05003900080919266,
        0.01670962944626808,
        -0.030178355053067207,
        -0.05240863561630249,
        0.05272849276661873,
        -0.027175115421414375,
        -0.05277618393301964,
        0.0501180961728096,
        -0.051591794937849045,
        -0.003199632978066802,
        0.05233657732605934,
        -0.05219137296080589,
        0.010570029728114605,
        -0.0012304220581427217,
        0.05299660935997963,
        0.02753603272140026,
        -0.006025885697454214,
        -0.04840952157974243,
        -0.0380588099360466,
        0.05066787078976631,
        0.044256534427404404,
        -0.05285141244530678,
        -0.05268644541501999,
        0.05246465653181076,
        0.05292140319943428,
        0.052708905190229416,
        -0.052589382976293564,
        -0.014028416015207767,
        0.03928745537996292,
        0.053006112575531006,
        -0.05114305391907692,
        0.04996967315673828,
        0.04616355895996094,
        0.05231718346476555,
        -0.05295689404010773,
        0.02326660044491291,
        0.04852396994829178,
        0.05295417830348015,
        0.029624152928590775,
        0.04426221176981926,
        -0.05134943872690201,
        0.05298769846558571,
        0.006843303795903921,
        -0.05292258784174919,
        0.023822197690606117,
        0.05281742289662361,
        0.020162850618362427,
        0.024556422606110573,
        0.053018637001514435,
        0.05167720094323158,
        0.0530196838080883,
        0.053019747138023376,
        0.04606080800294876,
        -0.05281659588217735,
        0.04412560164928436,
        -0.037161726504564285,
        0.05292082577943802,
        -0.05050453171133995,
        0.05211697891354561,
        0.05131803825497627,
        0.03563487529754639,
        -0.050660472363233566,
        0.013437049463391304,
        -0.052955709397792816,
        0.05292000249028206,
        0.0462656244635582,
        0.047889240086078644,
        0.04945454001426697,
        0.04824770614504814,
        0.05173635110259056,
        0.05223217234015465,
        0.05300045758485794,
        0.05240342766046524,
        0.05263713747262955,
        -0.03543350473046303,
        -0.01587412878870964,
        0.04832997918128967,
        -0.05284027010202408,
        0.020351586863398552,
        0.052222706377506256,
        -0.03932934254407883,
        0.05241062492132187,
        -0.0528259240090847,
        -0.050518639385700226,
        0.05291495472192764,
        -0.0515836738049984,
        -0.05138026922941208,
        0.05291268602013588,
        0.05296365171670914,
        -0.04819219186902046,
        0.04061032086610794,
        -0.029544269666075706,
        -0.011293279007077217,
        0.052884798496961594,
        0.05245457962155342,
        0.03891895338892937,
        0.05177940055727959,
        0.05266730487346649,
        -0.008571541868150234,
        0.05301558971405029,
        0.00586099224165082,
        0.03843194991350174,
        0.05116534233093262,
        -0.053019508719444275,
        -0.01224189717322588,
        -0.05300762876868248,
        -0.020657261833548546,
        0.036445315927267075,
        -0.023267215117812157,
        -0.018916115164756775,
        -0.05301967263221741,
        -0.05301936715841293,
        -0.052269209176301956,
        0.05299536511301994,
        -0.03706283122301102,
        -0.04099823534488678,
        -0.01073441095650196,
        0.01261949259787798,
        0.04693387448787689,
        0.052971526980400085,
        0.046840108931064606,
        0.04960968717932701,
        0.043572619557380676,
        0.02471640519797802,
        -0.016950147226452827,
        0.0037562504876405,
        0.04356343299150467,
        -0.052445024251937866,
        0.05261455103754997,
        0.03853531926870346,
        -0.0530196875333786,
        0.05189231038093567,
        -0.0054646143689751625,
        -0.026568546891212463,
        0.03674035146832466,
        -0.009247330017387867,
        -0.05290742591023445,
        -0.01668516919016838,
        -0.051234181970357895,
        0.05301970988512039,
        -0.007637453731149435,
        0.033620309084653854,
        0.051131244748830795,
        0.05109473690390587,
        -0.029209528118371964,
        0.051776424050331116,
        -0.05175216495990753,
        -0.051945481449365616,
        -0.04498324170708656,
        -0.03816748782992363,
        0.05292129144072533,
        0.019356288015842438,
        0.011359854601323605,
        -0.04511243477463722,
        0.05290979892015457,
        0.05233520641922951,
        0.05300780013203621,
        -0.05100799724459648,
        0.052965469658374786,
        0.048598337918519974,
        0.025626353919506073,
        -0.04825472831726074,
        0.052679646760225296,
        0.035738587379455566,
        0.05083338916301727,
        0.049293629825115204,
        0.04668135941028595,
        0.04578947275876999,
        -0.052835676819086075,
        0.048406172543764114,
        -0.052728138864040375,
        0.0530015230178833,
        -0.0014156776014715433,
        -0.05236580967903137,
        0.01849258877336979,
        0.05270916223526001,
        -0.011203459464013577,
        0.05232186242938042,
        0.05264648050069809,
        0.045578472316265106,
        -0.05301453918218613,
        0.03442435711622238,
        0.0527508370578289,
        -0.053017508238554,
        0.022578924894332886,
        -0.047824833542108536,
        -0.05296463891863823,
        0.0484924353659153,
        -0.03110485151410103,
        -0.05228084698319435,
        0.03720686584711075,
        0.052611760795116425,
        -0.022749628871679306,
        0.049618545919656754,
        0.05168628320097923,
        -0.017785508185625076,
        -0.05265937000513077,
        -0.0527009479701519,
        0.05299977585673332,
        -0.019322171807289124,
        0.0046120211482048035,
        -0.04944276064634323,
        -0.05020195618271828,
        0.04471983760595322,
        0.030512221157550812,
        -0.0240683201700449,
        0.04079596325755119,
        0.05301554873585701,
        -0.05300404503941536,
        -0.013207499869167805,
        0.051497168838977814,
        0.052283477038145065,
        0.04371306300163269,
        -0.052805036306381226,
        0.0030710124410688877,
        -0.03402162715792656,
        0.05301330238580704,
        -0.05217664688825607,
        0.05301039293408394,
        -0.05182289332151413,
        -0.052833713591098785,
        -0.020388787612318993,
        -0.052938442677259445,
        -0.009697397239506245,
        -0.04815683513879776,
        -0.041341718286275864,
        0.0521678663790226,
        0.05283933877944946,
        0.05286945030093193,
        -0.038641348481178284,
        0.052383601665496826,
        -0.052259597927331924,
        -0.023291174322366714,
        0.047675974667072296,
        -0.05294959992170334,
        0.024338409304618835,
        -0.05298194661736488,
        -0.0506197065114975,
        0.029005754739046097,
        -0.029246313497424126,
        0.05276654660701752,
        -0.04147403687238693,
        -0.052927691489458084,
        -0.052947934716939926,
        -0.005771102849394083,
        0.05172080919146538,
        0.05257059261202812,
        0.005826273933053017,
        0.0005361941293813288,
        0.05301405489444733,
        0.05297506973147392,
        -0.05113157629966736,
        -0.04919298365712166,
        -0.05115596204996109,
        -0.03780142217874527,
        -0.05301911011338234,
        0.05208832025527954,
        0.04396042227745056,
        -0.00022870801331009716,
        -0.0495651550590992,
        -0.04782255366444588,
        -0.05231102555990219,
        0.047881681472063065,
        0.04985365271568298,
        -0.0510680191218853,
        -0.03457585349678993,
        0.03569018468260765,
        -0.05202435702085495,
        -0.05074116587638855,
        0.05071565881371498,
        -0.053003422915935516,
        -0.05244729295372963,
        -0.052816472947597504,
        0.051812563091516495,
        -0.05128805339336395,
        0.051671843975782394,
        -0.04088311269879341,
        -0.049872253090143204,
        0.05248923599720001,
        0.04787157103419304,
        -0.013763057999312878,
        0.04259124770760536,
        -0.04972404986619949,
        0.052194658666849136,
        -0.04094414412975311,
        -0.047957755625247955,
        -0.04986061155796051,
        -0.04910263419151306,
        -0.05292316898703575,
        -0.05240882560610771,
        0.03682556375861168,
        0.050209470093250275,
        0.04848172143101692,
        -0.03487392142415047,
        -0.049641773104667664,
        -0.052768051624298096,
        0.052666377276182175,
        0.052925702184438705,
        -0.04995715245604515,
        0.05301867425441742,
        0.05120455101132393,
        -0.048047807067632675,
        0.03778035193681717,
        0.0527559332549572,
        0.04926109313964844,
        0.05265970900654793,
        -0.05281217023730278,
        0.04893389716744423,
        0.053019072860479355,
        0.0016802853206172585,
        -0.052952419966459274,
        -0.05187862738966942,
        -0.04946807399392128,
        0.05280384048819542,
        0.002814715262502432,
        0.04222765564918518,
        -0.048383526504039764,
        0.017200417816638947,
        0.0375404953956604,
        0.04927444830536842,
        -0.05289163812994957,
        -0.03811846673488617,
        0.043566323816776276,
        -0.026137877255678177,
        -0.05118969455361366,
        -0.02435513772070408,
        -0.0310516394674778,
        0.043057631701231,
        0.0528995580971241,
        -0.01935313642024994,
        0.04069485887885094,
        0.030775712803006172,
        -0.0530083104968071,
        -0.05078045651316643,
        -0.044696494936943054,
        0.04539566487073898,
        -0.05258554592728615,
        0.05203254520893097,
        0.05268078297376633,
        -0.038774557411670685,
        0.019715193659067154,
        -0.04361987113952637,
        0.053019359707832336,
        -0.03864194452762604
    ],
    [
        -0.052072398364543915,
        -0.045597322285175323,
        0.04392065480351448,
        -0.011225912719964981,
        0.016800569370388985,
        -0.056285981088876724,
        0.060365546494722366,
        -0.03379570692777634,
        -0.015856850892305374,
        0.04088553786277771,
        -0.011872575618326664,
        -0.029437372460961342,
        -0.04498612508177757,
        -0.029964298009872437,
        -0.05996732413768768,
        0.04228153079748154,
        -0.05708377808332443,
        0.047109149396419525,
        -0.05873197689652443,
        0.0586012527346611,
        0.007193181663751602,
        -0.03504915162920952,
        0.058250073343515396,
        0.015368343330919743,
        0.038050271570682526,
        -0.04995769262313843,
        0.024204343557357788,
        0.04702933505177498,
        0.04783366620540619,
        0.05663313716650009,
        0.050012536346912384,
        -0.002896436955779791,
        -0.032776497304439545,
        -0.05705743655562401,
        0.01958797127008438,
        -0.009504507295787334,
        -0.05570637434720993,
        -0.05576114356517792,
        -0.05543801188468933,
        0.04341039061546326,
        0.055996816605329514,
        -0.03368894383311272,
        0.05543685704469681,
        -0.05086609348654747,
        0.015296919271349907,
        -0.008182479068636894,
        0.05024508014321327,
        0.053723108023405075,
        0.05946527048945427,
        -0.026574598625302315,
        0.05416414141654968,
        0.019455239176750183,
        0.04795265570282936,
        -0.02729399874806404,
        0.005478015635162592,
        0.04866372421383858,
        -0.04832519218325615,
        -0.022168513387441635,
        -0.025172121822834015,
        0.039379313588142395,
        -0.058359213173389435,
        -0.02740722894668579,
        -0.051742009818553925,
        0.054990291595458984,
        0.0025680381804704666,
        0.060084063559770584,
        -0.031683314591646194,
        -0.04147488251328468,
        0.05988892912864685,
        -0.06045026332139969,
        -0.057644691318273544,
        -0.06010376662015915,
        -0.04361190274357796,
        0.02311251498758793,
        0.013179044239223003,
        -0.0261281318962574,
        0.045693546533584595,
        0.060076143592596054,
        0.047960467636585236,
        -0.04528544843196869,
        -0.053370263427495956,
        -0.0028455350548028946,
        -0.052974455058574677,
        -0.011038676835596561,
        0.060007113963365555,
        0.05074150860309601,
        -0.0584762841463089,
        -0.009848084300756454,
        -0.007109296042472124,
        0.048549797385931015,
        -0.05398823693394661,
        0.05733613669872284,
        0.035784341394901276,
        -0.05333513393998146,
        -0.02742677927017212,
        -0.02707330696284771,
        -0.042394764721393585,
        -0.05839379504323006,
        -0.016172170639038086,
        0.035596609115600586,
        -0.057529266923666,
        -0.009242906235158443,
        0.05893311649560928,
        0.052364982664585114,
        0.04583032429218292,
        -0.03767063841223717,
        0.05048336833715439,
        -0.0377844013273716,
        -0.05643414705991745,
        -0.03538411483168602,
        -0.036699917167425156,
        0.03982286527752876,
        -0.029372163116931915,
        0.0009958314476534724,
        0.049608368426561356,
        -0.05491972342133522,
        -0.02230355516076088,
        -0.0576893612742424,
        -0.05775228515267372,
        0.05675208568572998,
        0.03034084476530552,
        0.01893135905265808,
        -0.0272770244628191,
        -0.01732962392270565,
        -0.05930053070187569,
        0.04734877869486809,
        -0.032035332173109055,
        0.048624664545059204,
        0.04431583732366562,
        0.02726503647863865,
        0.034495074301958084,
        -0.0497237965464592,
        -0.0026707532815635204,
        0.047234129160642624,
        -0.05207457020878792,
        -0.03569990023970604,
        0.053122628480196,
        -0.05165733024477959,
        -0.04576563090085983,
        0.042741987854242325,
        0.033258359879255295,
        -0.0018018254777416587,
        0.046831920742988586,
        0.004819931462407112,
        0.03648752719163895,
        -0.009640011005103588,
        0.055016662925481796,
        -0.015635870397090912,
        -0.03884800523519516,
        0.05776860937476158,
        -0.0217769555747509,
        -0.05792916193604469,
        -0.05190623924136162,
        0.036242272704839706,
        0.05927189067006111,
        -0.05789991468191147,
        0.060208167880773544,
        0.03295192867517471,
        -0.023040710017085075,
        -0.008899143896996975,
        0.05684087425470352,
        0.02027704566717148,
        0.042820535600185394,
        0.01154940016567707,
        -0.05819080024957657,
        -0.05540335923433304,
        0.04449562355875969,
        -0.04461897909641266,
        -0.02297932095825672,
        0.05130879208445549,
        0.0005582697922363877,
        -0.029566876590251923,
        -0.055782243609428406,
        0.051479969173669815,
        -0.05923937261104584,
        0.057900603860616684,
        -0.06047333776950836,
        0.04658857360482216,
        0.059265922755002975,
        0.013336101546883583,
        0.04735242575407028,
        -0.011975792236626148,
        0.06034070625901222,
        0.010208668187260628,
        0.045855797827243805,
        -0.03160402178764343,
        -0.05669061467051506,
        0.05651000887155533,
        -0.05620745196938515,
        -0.05692901089787483,
        0.013107530772686005,
        -0.0389070138335228,
        -0.05359021574258804,
        0.05918429419398308,
        -0.044025711715221405,
        0.04361305385828018,
        0.04503660276532173,
        0.053298354148864746,
        -0.0028746849857270718,
        0.00914304330945015,
        -0.02569008804857731,
        0.05880521610379219,
        0.04855142906308174,
        0.056517936289310455,
        0.005791262723505497,
        0.058386120945215225,
        0.04222554713487625,
        -0.03870069608092308,
        -0.049434710294008255,
        -0.058804795145988464,
        -0.02979378215968609,
        0.04910147935152054,
        -0.05788077041506767,
        0.022766798734664917,
        0.037524979561567307,
        0.03551541641354561,
        0.06016986444592476,
        0.042360201478004456,
        0.06037548929452896,
        0.05709981173276901,
        -0.013360775075852871,
        -0.04052551090717316,
        -0.052707016468048096,
        -0.05831747129559517,
        0.058075807988643646,
        -0.0599355585873127,
        0.014818422496318817,
        0.06039569899439812,
        -0.053230877965688705,
        -0.05934523418545723,
        0.02929835021495819,
        -0.059463128447532654,
        0.04328472167253494,
        0.05475099757313728,
        -0.01693742349743843,
        0.04082776978611946,
        0.05617490038275719,
        -0.05120297148823738,
        -0.004915560595691204,
        0.05724656954407692,
        0.04862479493021965,
        0.04763419181108475,
        -0.021822987124323845,
        0.05292275920510292,
        -0.01741313375532627,
        -0.026025667786598206,
        0.05998091772198677,
        0.05801687389612198,
        -0.04583582654595375,
        0.04910801723599434,
        -0.051064010709524155,
        -0.054021891206502914,
        -0.04356468468904495,
        -0.04780568182468414,
        -0.0183059424161911,
        0.05763017013669014,
        -0.038728829473257065,
        0.015284203924238682,
        0.05989938601851463,
        0.05963251739740372,
        0.04121420159935951,
        0.05816979706287384,
        0.004434868227690458,
        -0.02322147972881794,
        0.058197543025016785,
        0.0425148606300354,
        -0.05545381084084511,
        -0.050199203193187714,
        0.03649744391441345,
        -0.03256755694746971,
        -0.029217034578323364,
        -0.05682428181171417,
        -0.024802543222904205,
        -0.058044273406267166,
        -0.058873970061540604,
        0.05612115189433098,
        -0.05734481289982796,
        0.05112806707620621,
        -0.060110315680503845,
        -0.05478470399975777,
        -0.057691771537065506,
        -0.00950935110449791,
        0.01995929330587387,
        0.01114403735846281,
        -0.025464387610554695,
        -0.059213943779468536,
        0.03893240913748741,
        -0.052427295595407486,
        -0.05650912970304489,
        0.006361514329910278,
        -0.019703175872564316,
        0.05381915718317032,
        -0.02010793797671795,
        -0.013715705834329128,
        -0.012271379120647907,
        -0.039011526852846146,
        0.059874389320611954,
        0.012089554220438004,
        -0.05960956960916519,
        0.05361158400774002,
        -0.005944295786321163,
        0.04338002949953079,
        0.005699845962226391,
        0.03376193344593048,
        0.02660033106803894,
        0.02613898552954197,
        -0.057039741426706314,
        0.058854907751083374,
        0.022049373015761375,
        -0.05877736955881119,
        0.015648536384105682,
        -0.05510064214468002,
        -0.03387795388698578,
        0.05897285044193268,
        -0.06037159636616707,
        -0.05784989893436432,
        0.053838372230529785,
        -0.0543196015059948,
        0.05204848200082779,
        -0.03955882415175438,
        -0.03903573378920555,
        0.013172086328268051,
        0.057807471603155136,
        0.016938216984272003,
        0.0035007570404559374,
        0.04975554347038269,
        0.05337958037853241,
        0.03753359615802765,
        -0.04963085055351257,
        -0.03979876637458801,
        0.01626848801970482,
        0.05024515837430954,
        0.021332941949367523,
        -0.017861925065517426,
        0.05720658227801323,
        0.03646150603890419,
        -0.037522248923778534,
        0.058594804257154465,
        -0.022644275799393654,
        0.05919080600142479,
        0.011831684969365597,
        0.05101672559976578,
        -0.03137924522161484,
        -0.0345735102891922,
        0.06047253683209419,
        -0.005255031865090132,
        0.034428104758262634,
        0.057483457028865814,
        -0.012332550249993801,
        0.05234956741333008,
        -0.059295494109392166,
        -0.057647209614515305,
        -0.011339136399328709,
        -0.05767998844385147,
        -0.05968650057911873,
        0.05766386166214943,
        -0.04540066421031952,
        -0.024899758398532867,
        -0.02307421714067459,
        0.048821236938238144,
        0.05790227651596069,
        -0.019898701459169388,
        -0.03795795887708664,
        -0.03469964861869812,
        0.014220526441931725,
        -0.05824146792292595,
        0.060406170785427094,
        -0.05271509662270546,
        0.04739272966980934,
        -0.060349393635988235,
        -0.05317565053701401,
        -0.05863972008228302,
        0.05441611632704735,
        0.02935415506362915,
        0.05430613458156586,
        0.060223985463380814,
        -0.012911643832921982,
        0.05233275890350342,
        -0.023810671642422676,
        -0.05206160247325897,
        -0.04686414450407028,
        0.04610496014356613,
        -0.05855625867843628,
        0.044015850871801376,
        0.05636923387646675,
        -0.05115881934762001,
        0.06023135781288147,
        -0.05404428392648697,
        -0.05444296821951866,
        -0.04477868974208832,
        -0.035682715475559235,
        0.045050669461488724,
        -0.015182703733444214,
        0.03731141611933708,
        -0.010413618758320808,
        0.05577815696597099,
        0.058488138020038605,
        -0.03363390639424324,
        0.040575653314590454,
        -0.054198697209358215,
        -0.05231316015124321,
        0.05391985923051834,
        0.04032830521464348,
        0.05848158150911331,
        -0.0564340278506279,
        -0.05668574571609497,
        0.027375997975468636,
        0.018906719982624054,
        0.05429519712924957,
        -0.05332346633076668,
        -0.007057660259306431,
        -0.04129418358206749,
        -0.007019542623311281,
        0.00554509274661541,
        0.05884595587849617,
        -0.016478095203638077,
        -0.05782804638147354,
        0.03422544151544571,
        0.034214675426483154,
        -0.017477674409747124,
        -0.05923133343458176,
        -0.0593443363904953,
        -0.05483508110046387,
        -0.0564902238547802,
        0.04246702417731285,
        0.03867742046713829,
        0.04387029632925987,
        -0.023502018302679062,
        -0.058965668082237244,
        -0.04810595139861107,
        0.05444963648915291,
        -0.042326670140028,
        -0.009203416295349598,
        0.028530796989798546,
        0.031007813289761543,
        -0.041323449462652206,
        -0.0214866753667593,
        -0.003876072121784091,
        -0.016857102513313293,
        -0.05045803636312485,
        -0.03641580045223236,
        0.05354183539748192,
        0.0011703853961080313,
        0.05573464184999466,
        -0.025224221870303154,
        0.04089770093560219,
        0.060218170285224915,
        -0.05659685283899307,
        0.04087486118078232,
        -0.05591678246855736,
        -0.049576178193092346,
        0.04847988858819008,
        0.05450005084276199,
        -0.04936957731842995,
        -0.020819958299398422,
        -0.04976605623960495,
        0.0260672215372324,
        0.02203918807208538,
        -0.05560917779803276,
        0.06003400310873985,
        0.00722120376303792,
        -0.0012628503609448671,
        -0.059979148209095,
        -0.059164609760046005,
        0.059610430151224136,
        0.05917980894446373,
        -0.04564346373081207,
        0.060116320848464966,
        0.039292994886636734,
        -0.05999436974525452,
        0.050114136189222336,
        0.019024256616830826,
        0.05750397592782974,
        0.04054249823093414,
        0.013471449725329876,
        0.05675093084573746,
        0.04997197538614273,
        -0.05836604908108711,
        -0.03767961263656616,
        0.04310307279229164,
        -0.0033144145272672176,
        -0.01674630120396614,
        0.02725491300225258,
        0.03290552645921707,
        -0.0010580556700006127,
        0.05324135348200798,
        0.022415831685066223,
        -0.04540639743208885,
        -0.0582493394613266,
        0.029797730967402458,
        -0.05757935345172882,
        -0.05819905549287796,
        0.023936476558446884,
        0.024526230990886688,
        0.038503292948007584,
        0.040603432804346085,
        -0.05593813955783844,
        -0.034928224980831146,
        0.057161781936883926,
        0.04245177283883095,
        -0.007885606959462166,
        -0.048928506672382355,
        0.032086439430713654,
        0.05852432921528816,
        -0.057731993496418,
        0.04723022133111954,
        -0.005287152249366045,
        -0.02046024613082409,
        -0.020978784188628197,
        0.014881545677781105,
        0.05939625948667526,
        0.05332457646727562
    ],
    [
        -0.004305161535739899,
        0.01602231152355671,
        -0.03973976522684097,
        0.04734976962208748,
        -0.048424676060676575,
        -0.020748641341924667,
        0.05186309665441513,
        -0.05198659747838974,
        -0.0032127024605870247,
        -0.0032731490209698677,
        0.05252121761441231,
        0.05184400826692581,
        -0.05247054249048233,
        0.04753199592232704,
        -0.0485062375664711,
        0.03646358475089073,
        0.04048645496368408,
        0.05151120573282242,
        -0.052151624113321304,
        0.036670707166194916,
        0.048630233854055405,
        0.02538212761282921,
        0.05225318670272827,
        0.04648302122950554,
        -0.045920535922050476,
        0.04077945649623871,
        -0.01651585102081299,
        0.024722157046198845,
        -0.052737653255462646,
        -0.0412827730178833,
        -0.051739200949668884,
        0.05261000245809555,
        -0.05241932347416878,
        -0.052733294665813446,
        0.051941100507974625,
        -0.052513811737298965,
        -0.05225306749343872,
        -0.04908665642142296,
        -0.0518396720290184,
        0.04663302004337311,
        0.04870402440428734,
        0.02957151085138321,
        0.04138150066137314,
        0.020039893686771393,
        0.041764069348573685,
        -0.02259877882897854,
        -0.04909801110625267,
        0.052589889615774155,
        0.052741117775440216,
        0.051310863345861435,
        0.052554428577423096,
        0.05274053290486336,
        0.008932853117585182,
        -0.0499916709959507,
        -0.03742244094610214,
        0.03542555123567581,
        0.015459389425814152,
        -0.021585550159215927,
        -0.04590105265378952,
        -0.04509398713707924,
        -0.05230993777513504,
        0.05021791160106659,
        -0.050769299268722534,
        0.0015391240594908595,
        0.04980798810720444,
        -0.052611175924539566,
        -0.05267166346311569,
        -0.05023931711912155,
        0.052741341292858124,
        -0.04069548845291138,
        -0.05131394788622856,
        -0.04960545152425766,
        0.05270211398601532,
        0.05261722207069397,
        0.037274789065122604,
        0.04339393600821495,
        0.0525534562766552,
        0.05274207517504692,
        0.05198556184768677,
        -0.01418514084070921,
        -0.014303773641586304,
        -0.05155809223651886,
        -0.05239248275756836,
        0.05192095786333084,
        -0.05016364902257919,
        0.040364716202020645,
        -0.010291514918208122,
        -0.02313469536602497,
        0.04939672723412514,
        0.0526730976998806,
        -0.017955774441361427,
        -0.050295595079660416,
        -0.005266279447823763,
        -0.04982651025056839,
        -0.052415575832128525,
        0.015499070286750793,
        -0.010725925676524639,
        -0.003859145101159811,
        -0.04932112619280815,
        0.016571100801229477,
        -0.05268729105591774,
        0.05270510911941528,
        0.03046116605401039,
        0.05036967992782593,
        0.02280377224087715,
        -0.05272814258933067,
        -0.05271148309111595,
        0.038566168397665024,
        -0.05261294171214104,
        -0.012016760185360909,
        -0.05200577154755592,
        0.04481742903590202,
        0.0232414398342371,
        -0.05234181508421898,
        0.05263201892375946,
        -0.0004368143854662776,
        0.031864315271377563,
        0.04577987268567085,
        0.05272367224097252,
        -0.05235636979341507,
        -0.05261349305510521,
        -0.052500542253255844,
        -0.02955220825970173,
        -0.05080946534872055,
        -0.052574630826711655,
        -0.05244535580277443,
        -0.03263825923204422,
        0.05158454552292824,
        0.050606802105903625,
        0.05154084786772728,
        0.052209995687007904,
        -0.05112578719854355,
        -0.051903314888477325,
        0.04703653231263161,
        -0.05088423565030098,
        -0.05196624994277954,
        0.04670960083603859,
        -0.05271875485777855,
        -0.03423672169446945,
        0.046424537897109985,
        0.052688632160425186,
        -0.03712720051407814,
        0.042814359068870544,
        0.052741438150405884,
        0.05086391419172287,
        0.05238725617527962,
        0.05258161947131157,
        0.02739536575973034,
        -0.022331280633807182,
        0.05271270498633385,
        -0.0503506101667881,
        -0.052740953862667084,
        -0.051595501601696014,
        0.05259227380156517,
        -0.011773199774324894,
        -0.052736878395080566,
        0.05153501778841019,
        0.046922214329242706,
        0.04415593668818474,
        0.02381248213350773,
        0.051980480551719666,
        -0.025833068415522575,
        0.05128097161650658,
        0.004989861510694027,
        -0.02100260742008686,
        -0.03931219130754471,
        -0.05223137140274048,
        -0.05227922275662422,
        -0.038823504000902176,
        0.03261891379952431,
        -0.052023131400346756,
        0.009539661929011345,
        -0.052713435143232346,
        0.048228077590465546,
        -0.028684446588158607,
        0.0461803562939167,
        -0.052739985287189484,
        -0.051143579185009,
        0.05115538462996483,
        -0.027142532169818878,
        -0.05272223800420761,
        0.04112047329545021,
        0.04868977516889572,
        -0.0055562518537044525,
        0.05136037617921829,
        -0.03281866014003754,
        -0.03484664484858513,
        0.05033373460173607,
        0.043074414134025574,
        -0.03740144148468971,
        -0.05274178460240364,
        0.04242636635899544,
        0.04132258519530296,
        0.03080843761563301,
        0.03848332166671753,
        0.039061013609170914,
        -0.025039248168468475,
        0.052565768361091614,
        0.05184837058186531,
        0.008294152095913887,
        0.05189644545316696,
        0.03809843957424164,
        -0.05237853154540062,
        0.05194840207695961,
        -0.015396863222122192,
        -0.05011536553502083,
        0.040927547961473465,
        0.05184038355946541,
        -0.052491262555122375,
        0.009389371611177921,
        -0.052515964955091476,
        0.002007514238357544,
        -0.03718763589859009,
        0.04986988753080368,
        0.018013428896665573,
        -0.052626337856054306,
        0.052742063999176025,
        0.052704036235809326,
        0.05273425206542015,
        0.00304972380399704,
        0.03656459227204323,
        0.052710410207509995,
        0.04244821518659592,
        -0.05274193733930588,
        -0.01874082162976265,
        -0.04413985460996628,
        0.04482777789235115,
        -0.04645982012152672,
        0.04912206530570984,
        -0.05269975587725639,
        -0.050524234771728516,
        0.01182611845433712,
        -0.05242098122835159,
        0.04611337184906006,
        0.03589857742190361,
        0.051793329417705536,
        0.050176993012428284,
        0.035692036151885986,
        0.04630301520228386,
        0.05274194851517677,
        0.05164061486721039,
        -0.050045326352119446,
        0.05271681398153305,
        -0.052641771733760834,
        0.05264586582779884,
        0.021005431190133095,
        0.024917537346482277,
        -0.04946974292397499,
        -0.03133311867713928,
        -0.03855938836932182,
        -0.05273987352848053,
        -0.025498012080788612,
        -0.02958671934902668,
        -0.05243240296840668,
        -0.015041918493807316,
        0.05216344818472862,
        0.05121859535574913,
        -0.05166081711649895,
        0.05133518576622009,
        0.040084950625896454,
        0.032899126410484314,
        -0.04083964228630066,
        0.01199389435350895,
        0.030863869935274124,
        -0.05265991389751434,
        0.05272967740893364,
        0.052218228578567505,
        0.04621884226799011,
        -0.004005654249340296,
        -0.04890609160065651,
        0.0025087199173867702,
        -0.04975175857543945,
        0.05258156731724739,
        -0.050144296139478683,
        -0.04198044165968895,
        -0.01479396503418684,
        -0.0431230254471302,
        -0.026108408346772194,
        -0.03613254427909851,
        -0.04084727168083191,
        -0.05271849036216736,
        -0.04847829043865204,
        -0.050692833960056305,
        -0.014865783974528313,
        0.05243551731109619,
        -0.0426969900727272,
        0.010507634840905666,
        -0.004977364558726549,
        0.05026049539446831,
        -0.007589912507683039,
        -0.04802761971950531,
        0.04472745954990387,
        0.010041747242212296,
        0.042837902903556824,
        0.04843466356396675,
        -0.05265187844634056,
        0.052740927785634995,
        0.046559251844882965,
        -0.02973317727446556,
        0.05253727734088898,
        0.02960549294948578,
        0.05255565419793129,
        -0.009558236226439476,
        0.04633430019021034,
        -0.0028932204004377127,
        0.05130965635180473,
        -0.04496051371097565,
        0.0527358241379261,
        0.04671860113739967,
        -0.05274171382188797,
        -0.051182035356760025,
        0.046465929597616196,
        0.045549504458904266,
        0.05236603319644928,
        -0.052632614970207214,
        -0.05258830264210701,
        0.007098880596458912,
        -0.05271180346608162,
        0.052648212760686874,
        -0.05186169221997261,
        -0.04449430853128433,
        -0.0008383794920518994,
        0.05110723152756691,
        -0.04388989880681038,
        -0.04560425132513046,
        -0.044033270329236984,
        0.05271496623754501,
        -0.052665825933218,
        0.02653350867331028,
        -0.052610959857702255,
        0.05241691693663597,
        -0.025583786889910698,
        -0.03187524899840355,
        -0.031941719353199005,
        0.05117883160710335,
        0.051380712538957596,
        -0.052208978682756424,
        0.052572257816791534,
        -0.026401618495583534,
        0.052691489458084106,
        -0.05196613445878029,
        0.04572450369596481,
        0.05273810029029846,
        -0.029163416475057602,
        0.05274196341633797,
        0.05268165096640587,
        0.05268747732043266,
        0.05063580721616745,
        0.02950621023774147,
        0.052625712007284164,
        0.052229564636945724,
        -0.0449688620865345,
        0.05258052051067352,
        0.05229176953434944,
        -0.05088105797767639,
        0.04392356425523758,
        -0.0527418851852417,
        -0.04965275526046753,
        0.04258440434932709,
        0.0503164567053318,
        0.040436528623104095,
        0.052596647292375565,
        -0.02016850933432579,
        -0.049636926501989365,
        -0.04619274288415909,
        -0.04827658087015152,
        0.05262703076004982,
        -0.01819109357893467,
        0.04579928517341614,
        -0.05253887176513672,
        -0.051409970968961716,
        -0.007120138965547085,
        0.044713471084833145,
        -0.05246080458164215,
        0.05054188519716263,
        0.052617356181144714,
        0.05043894797563553,
        0.05039243400096893,
        0.04492570087313652,
        0.03458717465400696,
        0.03422629460692406,
        0.052455801516771317,
        -0.05240875855088234,
        0.021720314398407936,
        0.052741870284080505,
        -0.044295765459537506,
        -0.028887776657938957,
        0.04770785942673683,
        -0.05102613940834999,
        0.041816744953393936,
        -0.05161738023161888,
        -0.048889853060245514,
        0.03559555858373642,
        -0.05266476050019264,
        0.021160703152418137,
        0.052680522203445435,
        0.05274193733930588,
        0.03695331886410713,
        -0.05274186283349991,
        -0.05268462002277374,
        -0.01507137157022953,
        0.05271971598267555,
        -0.0502217672765255,
        -0.025398295372724533,
        -0.05269978195428848,
        0.04591250419616699,
        0.05258262902498245,
        -0.05181480944156647,
        0.04866150766611099,
        -0.018696654587984085,
        -0.00522427586838603,
        -0.05058131739497185,
        -0.048232365399599075,
        0.03667805343866348,
        0.030910588800907135,
        -0.05079145357012749,
        -0.0510002039372921,
        -0.025905925780534744,
        0.05272801220417023,
        0.05246788263320923,
        0.04968896135687828,
        -0.05270801857113838,
        -0.04548311606049538,
        -0.052674874663352966,
        0.047047559171915054,
        -0.04867899790406227,
        -0.0435071736574173,
        -0.03914330154657364,
        -0.051810722798109055,
        -0.05245394632220268,
        -0.004198762122541666,
        0.01628538779914379,
        0.04461699724197388,
        -0.021105308085680008,
        -0.007847071625292301,
        -0.05036316066980362,
        -0.052741896361112595,
        0.05264146998524666,
        -0.05238258093595505,
        -0.05097207427024841,
        -0.002297451486811042,
        0.052738338708877563,
        -0.023664819076657295,
        0.052726034075021744,
        -0.05256960541009903,
        0.0307804886251688,
        0.019771786406636238,
        -0.052737168967723846,
        0.04516569525003433,
        0.041959647089242935,
        0.048003844916820526,
        0.05226881802082062,
        -0.03987663611769676,
        0.04981834813952446,
        -0.014353165403008461,
        -0.05084237456321716,
        0.03851360082626343,
        -0.04584444314241409,
        -0.05269838869571686,
        0.04768001288175583,
        0.052633706480264664,
        -0.05243953317403793,
        -0.052437007427215576,
        -0.03996118903160095,
        0.052081573754549026,
        0.052716679871082306,
        -0.05264557525515556,
        0.05271884426474571,
        -0.014093060977756977,
        -0.05266166105866432,
        0.052494388073682785,
        0.052005309611558914,
        0.04490002989768982,
        0.042567554861307144,
        0.05274110659956932,
        -0.049428071826696396,
        0.04441651329398155,
        -0.05150892585515976,
        -0.045537665486335754,
        -0.05199792608618736,
        -0.007813985459506512,
        0.04741337150335312,
        -0.04480884596705437,
        0.004843645729124546,
        -0.05188706889748573,
        0.044200412929058075,
        0.052704375237226486,
        0.0062867156229913235,
        -0.05259082838892937,
        0.05256308987736702,
        0.02938893251121044,
        -0.050569914281368256,
        0.05272660404443741,
        0.0489816814661026,
        -0.03138941898941994,
        0.014900235459208488,
        0.037415605038404465,
        -0.052672382444143295,
        0.051356490701436996,
        0.003135794773697853,
        -0.04127435013651848,
        -0.03942842409014702,
        -0.052655529230833054,
        -0.035583458840847015,
        0.05234723910689354,
        0.04444284364581108,
        0.0341566726565361,
        0.008203941397368908,
        0.05267731472849846,
        -0.05193227902054787,
        0.05130159109830856,
        0.052740514278411865
    ],
    [
        0.0409819632768631,
        0.008272182196378708,
        0.025657733902335167,
        0.0481337234377861,
        -0.026771971955895424,
        -0.05755030736327171,
        0.04236844554543495,
        -0.05707865208387375,
        0.0576992966234684,
        -0.04275161772966385,
        0.027625879272818565,
        -0.04888904467225075,
        -0.05191890522837639,
        0.0068558440543711185,
        -0.05679618939757347,
        0.04616861417889595,
        0.05605831742286682,
        0.052213240414857864,
        0.030173039063811302,
        0.053973834961652756,
        0.05517875403165817,
        0.0027724565006792545,
        0.05259791389107704,
        0.011020700447261333,
        0.05428171902894974,
        -0.03175840526819229,
        0.02832690253853798,
        0.006068551912903786,
        -0.021094907075166702,
        -0.021872490644454956,
        0.050536416471004486,
        0.04120498523116112,
        -0.05704560875892639,
        -0.05584772303700447,
        0.03676119074225426,
        -0.049352921545505524,
        -0.05717666074633598,
        -0.04032882675528526,
        0.052838120609521866,
        0.04594026878476143,
        0.04995056986808777,
        0.0023691775277256966,
        0.05544016882777214,
        -0.04951098561286926,
        0.05289994552731514,
        0.05051156133413315,
        0.057743411511182785,
        0.05777479708194733,
        0.056799113750457764,
        -0.03763822093605995,
        0.05775652825832367,
        0.05033827945590019,
        -0.04764290526509285,
        -0.04338680952787399,
        0.05759233608841896,
        -0.04699142649769783,
        0.057313814759254456,
        0.004028218798339367,
        -0.013082069344818592,
        -0.04399055615067482,
        -0.052486978471279144,
        0.03969813510775566,
        -0.05539445951581001,
        0.05298442393541336,
        -0.010657347738742828,
        -0.0429006852209568,
        -0.05717867985367775,
        -0.05608686804771423,
        0.05193425342440605,
        -0.05248098447918892,
        0.02415306679904461,
        -0.055937834084033966,
        0.05357879400253296,
        0.05619993805885315,
        0.038411449640989304,
        -0.04488968476653099,
        0.046732377260923386,
        0.05783884599804878,
        0.04835806414484978,
        0.035550907254219055,
        -0.02811555378139019,
        -0.04644249379634857,
        -0.05519319698214531,
        0.023288143798708916,
        0.033505894243717194,
        0.035854876041412354,
        -0.05629371851682663,
        -0.05775689333677292,
        -0.03249102085828781,
        0.056902527809143066,
        -0.048976410180330276,
        -0.03214340656995773,
        -0.046296194195747375,
        -0.05744548887014389,
        -0.03371923789381981,
        0.05302092060446739,
        -0.05212472751736641,
        -0.05577322840690613,
        -0.046346671879291534,
        -0.002993532456457615,
        -0.057258665561676025,
        0.048162251710891724,
        0.055594317615032196,
        0.05734151974320412,
        0.019300920888781548,
        -0.05227229371666908,
        -0.05707816779613495,
        -0.005595473572611809,
        -0.04882781580090523,
        0.01944250985980034,
        -0.014985139481723309,
        0.03463590890169144,
        -0.038562171161174774,
        -0.056270550936460495,
        0.01365243922919035,
        0.027330458164215088,
        0.0320553220808506,
        -0.03976529836654663,
        0.057462360709905624,
        0.024835241958498955,
        -0.05623240023851395,
        0.019142132252454758,
        0.010233494453132153,
        -0.05458148568868637,
        -0.0402846522629261,
        -0.04178229346871376,
        0.04777238145470619,
        0.02613748610019684,
        0.056867897510528564,
        0.01864279992878437,
        0.006757389288395643,
        -0.044185567647218704,
        -0.04664239659905434,
        0.05494102090597153,
        -0.053547587245702744,
        -0.05398627370595932,
        0.05767707899212837,
        -0.03620007261633873,
        -0.04325397312641144,
        0.038156475871801376,
        -0.02075941488146782,
        -0.030958354473114014,
        0.04481363669037819,
        0.04630737379193306,
        0.0572751946747303,
        0.05739062279462814,
        0.0551953949034214,
        -0.03653876483440399,
        -0.04829970374703407,
        0.009746464900672436,
        0.031803179532289505,
        -0.054960086941719055,
        -0.01920177973806858,
        0.042993415147066116,
        -0.03280380740761757,
        -0.05480242148041725,
        -0.008384657092392445,
        -0.040232304483652115,
        0.057671163231134415,
        0.013587656430900097,
        -0.03920799866318703,
        0.014092323370277882,
        0.055511586368083954,
        0.056936752051115036,
        0.05697374790906906,
        0.02161082625389099,
        0.045216504484415054,
        -0.01278249267488718,
        -0.026385238394141197,
        0.026465026661753654,
        -0.055390190333127975,
        0.04652924835681915,
        -0.05711764469742775,
        -0.05533330515027046,
        0.03247293829917908,
        0.0393533892929554,
        -0.007347067352384329,
        -0.02325395680963993,
        0.057718709111213684,
        0.00888277031481266,
        -0.04260067641735077,
        -0.02785956673324108,
        0.05547676235437393,
        0.035080015659332275,
        0.022366376593708992,
        -0.056507788598537445,
        0.05314664542675018,
        0.05699857324361801,
        -0.013876844197511673,
        -0.0568387545645237,
        -0.05364647135138512,
        0.0379413478076458,
        0.04503025487065315,
        0.04828803613781929,
        -0.057301752269268036,
        0.05395141616463661,
        0.05614616349339485,
        0.03118523769080639,
        -0.05730118975043297,
        0.04315871000289917,
        0.0551939383149147,
        0.05526365339756012,
        -0.03203028440475464,
        -0.03927956894040108,
        -0.002676747040823102,
        0.052397340536117554,
        0.05565537512302399,
        0.05131833255290985,
        -0.03172461688518524,
        -0.0298672616481781,
        -0.0569438673555851,
        0.023341454565525055,
        0.04089948907494545,
        0.05658986046910286,
        0.048378195613622665,
        -0.039016805589199066,
        0.056477054953575134,
        0.049263060092926025,
        0.057786308228969574,
        0.05094139277935028,
        0.045293498784303665,
        -0.01476365327835083,
        -0.031136516481637955,
        0.007604935672134161,
        0.05701156333088875,
        -0.057364340871572495,
        0.010053776204586029,
        0.037500444799661636,
        0.05406775698065758,
        -0.057411644607782364,
        -0.030477184802293777,
        -0.04805519059300423,
        0.030834827572107315,
        0.04582744464278221,
        0.038642216473817825,
        0.048463717103004456,
        0.05297590419650078,
        0.030551422387361526,
        -0.05645123869180679,
        0.057756341993808746,
        0.038766343146562576,
        0.039973706007003784,
        -0.057183124125003815,
        0.000492276216391474,
        0.03585809841752052,
        -0.05503232777118683,
        -0.039811622351408005,
        -0.025653885677456856,
        -0.050055403262376785,
        -0.016128191724419594,
        -0.011632285080850124,
        -0.0562618151307106,
        -0.01579393818974495,
        -0.05375174060463905,
        0.026832932606339455,
        0.05129879713058472,
        0.05449049547314644,
        0.026870016008615494,
        0.0478607639670372,
        -0.02180236019194126,
        -0.016490336507558823,
        -0.041136886924505234,
        -0.0028956274036318064,
        -0.05641980469226837,
        0.03321230411529541,
        0.057776231318712234,
        0.042367033660411835,
        0.04338208958506584,
        -0.05297242850065231,
        0.021309854462742805,
        0.04929637536406517,
        -0.056183621287345886,
        0.050933532416820526,
        -0.051662180572748184,
        -0.020940348505973816,
        0.05464537814259529,
        -0.047911785542964935,
        0.04791779816150665,
        0.05123680830001831,
        -0.0018725442932918668,
        -0.040682852268218994,
        0.0375962033867836,
        -0.05583230406045914,
        0.002447423990815878,
        -0.04531894996762276,
        0.028864379972219467,
        0.014947542920708656,
        0.05508515611290932,
        0.05281559005379677,
        0.039677850902080536,
        -0.0560278445482254,
        -0.0026288311928510666,
        -0.009388294070959091,
        -0.038568366318941116,
        0.05556504800915718,
        -0.056368403136730194,
        0.0575963594019413,
        0.006753349676728249,
        -0.05272198095917702,
        0.05618962645530701,
        -0.00479224743321538,
        0.05633772537112236,
        -0.041212048381567,
        0.053051143884658813,
        0.045100487768650055,
        -0.03911219909787178,
        -0.05013370141386986,
        0.05780217796564102,
        0.050931453704833984,
        -0.05688910186290741,
        0.04451408609747887,
        -0.05354747176170349,
        0.018982894718647003,
        0.056265801191329956,
        -0.025949211791157722,
        -0.05623165890574455,
        -0.04150339215993881,
        0.014018823392689228,
        0.05578390881419182,
        0.03928674757480621,
        0.05140044167637825,
        -0.05483274534344673,
        0.046682413667440414,
        -0.0007497200858779252,
        0.05757811293005943,
        -0.04676342383027077,
        0.05057533457875252,
        -0.04800809547305107,
        0.04834992066025734,
        -0.029265377670526505,
        0.030589140951633453,
        -0.051253411918878555,
        0.008066541515290737,
        -0.05411480367183685,
        0.05669557675719261,
        -0.05723544582724571,
        0.009160006418824196,
        0.05726869776844978,
        -0.024228878319263458,
        0.057035550475120544,
        -0.04630819708108902,
        0.0017353484872728586,
        0.045311339199543,
        -0.01760735549032688,
        0.0562322661280632,
        0.035033758729696274,
        0.05748353898525238,
        0.05603640154004097,
        -0.05044236406683922,
        0.05689733102917671,
        0.057570841163396835,
        0.011829349212348461,
        0.02372463047504425,
        -0.05150196701288223,
        -0.05298750102519989,
        -0.007217954378575087,
        -0.0502784438431263,
        -0.048492494970560074,
        -0.04062493517994881,
        -0.032651565968990326,
        0.03159451484680176,
        0.057062678039073944,
        0.044966522604227066,
        -0.03449656814336777,
        -0.01004968211054802,
        -0.05664437636733055,
        0.057416241616010666,
        0.006248824298381805,
        0.04358498379588127,
        -0.05644141137599945,
        0.04446106404066086,
        -0.055312857031822205,
        0.03793940320611,
        -0.016688749194145203,
        0.05612088367342949,
        0.05770902708172798,
        0.04608430713415146,
        -0.026335105299949646,
        0.056186988949775696,
        0.03269306570291519,
        -0.05369509756565094,
        0.03518883138895035,
        -0.057292819023132324,
        -0.014832324348390102,
        0.057640235871076584,
        -0.0576009601354599,
        0.0009757291991263628,
        -0.05147093906998634,
        -0.035878736525774,
        -0.04905031621456146,
        -0.051193710416555405,
        0.05422896519303322,
        -0.01947879046201706,
        -0.055527400225400925,
        0.016009677201509476,
        0.05644163489341736,
        0.04526163637638092,
        -0.02525979094207287,
        -0.026851598173379898,
        -0.040076401084661484,
        -0.03392324969172478,
        0.05714816600084305,
        0.0302350502461195,
        0.034749835729599,
        -0.048440318554639816,
        0.04762027785181999,
        0.05297870561480522,
        -0.05620361492037773,
        0.05768153443932533,
        0.0238238163292408,
        -0.02048308216035366,
        -0.05772627145051956,
        -0.03844888135790825,
        0.03222562000155449,
        0.004245293792337179,
        -0.05153948441147804,
        -0.02988577075302601,
        0.05178746208548546,
        0.05778872221708298,
        0.055331017822027206,
        0.057229336351156235,
        -0.05471355468034744,
        -0.04673925042152405,
        -0.057699963450431824,
        0.005464585497975349,
        -0.0074262167327106,
        -0.01812133565545082,
        -0.057608503848314285,
        -0.055050209164619446,
        -0.057663220912218094,
        0.05242559313774109,
        -0.04948935657739639,
        -0.015799758955836296,
        0.03959691524505615,
        -0.04521782696247101,
        -0.05060209706425667,
        -0.05757616460323334,
        0.04704706743359566,
        0.04448210448026657,
        -0.030908307060599327,
        0.05140068754553795,
        0.05726543068885803,
        0.04932728409767151,
        0.057675380259752274,
        0.027590110898017883,
        0.013133211992681026,
        0.04884187504649162,
        -0.0569605752825737,
        0.005647079087793827,
        0.05606648325920105,
        0.05424666032195091,
        0.05537562072277069,
        0.051938023418188095,
        0.05667645111680031,
        0.01303557213395834,
        -0.051679085940122604,
        -0.05161572992801666,
        -0.05173264071345329,
        0.009115749970078468,
        0.04037432000041008,
        0.05538206920027733,
        -0.04909433424472809,
        0.011978620663285255,
        -0.034915946424007416,
        0.057002898305654526,
        0.05729076638817787,
        0.004403742030262947,
        0.057722367346286774,
        0.023765629157423973,
        -0.04935229942202568,
        0.03199981898069382,
        0.008197111077606678,
        0.05440697446465492,
        0.03614688664674759,
        -0.04597187414765358,
        -0.004223762080073357,
        0.05666929855942726,
        -0.0568656399846077,
        -0.039438772946596146,
        -0.046665169298648834,
        -0.000822797475848347,
        -0.03810153901576996,
        0.05693351477384567,
        0.02067229151725769,
        -0.05748547613620758,
        0.04749930649995804,
        0.057216525077819824,
        0.054720085114240646,
        -0.0561046339571476,
        -0.04011789709329605,
        -0.039936862885951996,
        -0.049530670046806335,
        0.04018045589327812,
        0.0516991913318634,
        -0.03422684594988823,
        0.05412493646144867,
        0.00370459514670074,
        -0.017278017476201057,
        0.046793267130851746,
        0.049400780349969864,
        -0.054604679346084595,
        0.03194867819547653,
        -0.019957872107625008,
        -0.0305755827575922,
        -0.025483865290880203,
        0.016982857137918472,
        0.05762738734483719,
        -0.0310689564794302,
        0.05589398741722107,
        -0.020293867215514183,
        0.04663049802184105,
        0.046588797122240067
    ],
    [
        -0.014963755384087563,
        -0.06037631630897522,
        0.03027062863111496,
        0.05704256147146225,
        0.020866669714450836,
        0.017688196152448654,
        0.030163371935486794,
        -0.016715342178940773,
        0.044652774930000305,
        0.028724605217576027,
        0.040510497987270355,
        -0.00045165198389440775,
        -0.0036366567946970463,
        -0.01787247695028782,
        -0.05768575519323349,
        0.06074805557727814,
        -0.06667445600032806,
        0.05581515282392502,
        -0.07444663345813751,
        0.030916906893253326,
        -0.0678839161992073,
        0.003405267372727394,
        -0.023645158857107162,
        -0.020226258784532547,
        0.05298718065023422,
        0.054936062544584274,
        0.03218528628349304,
        -0.004748091101646423,
        0.030598048120737076,
        -0.01629657857120037,
        0.04918506741523743,
        -0.013255972415208817,
        -0.028501741588115692,
        -0.06411521136760712,
        0.0023832409642636776,
        0.04193597659468651,
        -0.061782777309417725,
        -0.027754273265600204,
        -0.074123814702034,
        0.021889371797442436,
        0.03908722475171089,
        -0.02029281295835972,
        0.05675194039940834,
        -0.07524286955595016,
        0.040402334183454514,
        0.07104883342981339,
        0.04046792909502983,
        0.05967975780367851,
        0.017535626888275146,
        0.05861363187432289,
        0.05326889455318451,
        0.06422076374292374,
        0.040154192596673965,
        0.013622405007481575,
        -0.03238816186785698,
        -0.027842151001095772,
        0.004875941667705774,
        0.0034837329294532537,
        0.037297572940588,
        0.00662681320682168,
        -0.07025809586048126,
        -0.00891899038106203,
        -0.04106343165040016,
        0.04988905042409897,
        -0.054278045892715454,
        -0.009828084148466587,
        0.06893640011548996,
        0.013011886738240719,
        -0.007183652371168137,
        -0.07364708930253983,
        -0.07130679488182068,
        -0.07388252764940262,
        0.050699297338724136,
        -0.01213020645081997,
        -0.01728030852973461,
        0.01308973878622055,
        -0.02451743744313717,
        0.07366086542606354,
        -0.02233748696744442,
        -0.033510465174913406,
        0.008400367572903633,
        0.07042303681373596,
        -0.0658988431096077,
        0.03389063477516174,
        0.030081717297434807,
        0.04100998491048813,
        0.0583178773522377,
        0.046966586261987686,
        0.060471028089523315,
        0.07285849750041962,
        0.04681655392050743,
        0.04540843144059181,
        -0.04568661004304886,
        -0.04064296558499336,
        -0.03823148459196091,
        0.023146556690335274,
        -0.03777210786938667,
        -0.05242310091853142,
        -0.05849423632025719,
        -0.020033283159136772,
        -0.07371155172586441,
        -0.018215445801615715,
        0.07352375239133835,
        -0.01743282750248909,
        0.058010369539260864,
        -0.04417987912893295,
        -0.025775834918022156,
        0.016237208619713783,
        -0.03779787942767143,
        0.00443693995475769,
        -0.06668508052825928,
        -0.036724310368299484,
        -0.043862003833055496,
        0.00510864332318306,
        0.01847420260310173,
        -0.041402529925107956,
        -0.03576896712183952,
        -0.01426213700324297,
        0.023912575095891953,
        0.029253749176859856,
        -0.005944525822997093,
        0.004575519356876612,
        0.009997533634305,
        0.006599655840545893,
        -0.07014833390712738,
        -0.043673187494277954,
        -0.03884708881378174,
        -0.029321214184165,
        0.07087794691324234,
        0.04360583424568176,
        0.05446229502558708,
        -0.06250248849391937,
        -0.0634966790676117,
        -0.015183525159955025,
        -0.051061682403087616,
        -0.05117608606815338,
        -0.001606677076779306,
        -0.030577370896935463,
        -0.0702235996723175,
        0.05625351890921593,
        0.05083617568016052,
        0.020646056160330772,
        0.05933207646012306,
        0.05187647417187691,
        0.04270010441541672,
        0.0464731901884079,
        0.06354429572820663,
        -0.06817203760147095,
        -0.006670319940894842,
        0.06554466485977173,
        -0.05172932893037796,
        -0.07224109768867493,
        0.011232355609536171,
        -0.032338716089725494,
        0.014647933654487133,
        -0.04114091768860817,
        0.06225096806883812,
        0.03667260706424713,
        -0.01255998108536005,
        0.0005417356733232737,
        0.06892953813076019,
        0.05075322464108467,
        0.05931079015135765,
        -0.06263594329357147,
        0.0067028990015387535,
        0.0003515307034831494,
        -0.059456098824739456,
        -0.04127507656812668,
        0.03647978976368904,
        0.03828725218772888,
        0.06997178494930267,
        0.024857431650161743,
        0.04740685969591141,
        -0.024563444778323174,
        -0.010723885148763657,
        0.0724705308675766,
        -0.07515969127416611,
        0.005597055424004793,
        0.05389029160141945,
        0.02930208295583725,
        0.044258907437324524,
        0.06664346158504486,
        0.05184650048613548,
        -0.0247829407453537,
        -0.008075272664427757,
        0.03271758183836937,
        -0.03569681942462921,
        0.06769806891679764,
        -0.025788094848394394,
        -0.012985805049538612,
        0.039894312620162964,
        0.022402724251151085,
        -0.03964716196060181,
        -0.031555820256471634,
        -0.009797601029276848,
        0.005814877338707447,
        0.002039115410298109,
        0.0016115681501105428,
        -0.040011316537857056,
        0.01226737443357706,
        -0.004655662924051285,
        0.018364612013101578,
        -0.035581737756729126,
        -0.021088814362883568,
        -0.042546648532152176,
        0.043817199766635895,
        0.06308407336473465,
        -0.013873421587049961,
        -0.04355904832482338,
        0.06940782815217972,
        -0.061965975910425186,
        0.056512050330638885,
        -0.0027459694538265467,
        -0.006773358676582575,
        0.03130801394581795,
        -0.006481884978711605,
        -0.03394310548901558,
        -0.01944476179778576,
        0.05945461615920067,
        -0.01435062289237976,
        0.03840738162398338,
        0.0364544503390789,
        -0.01921948604285717,
        -0.07033664733171463,
        0.06552302092313766,
        -0.04029631242156029,
        -0.02497974783182144,
        0.06268751621246338,
        -0.03932439535856247,
        -0.046047110110521317,
        -0.024545712396502495,
        -0.01991402357816696,
        -0.06125732883810997,
        0.042542532086372375,
        -0.039972078055143356,
        0.05064711719751358,
        0.06446244567632675,
        -0.03353022411465645,
        -0.05984422191977501,
        0.06227048113942146,
        0.031592957675457,
        0.06293648481369019,
        -0.04066663980484009,
        0.022155772894620895,
        0.0060875569470226765,
        0.024653606116771698,
        -0.06016549468040466,
        0.007414824794977903,
        -0.04472409933805466,
        -0.048138007521629333,
        -0.001994629856199026,
        -0.0730835348367691,
        -0.021446794271469116,
        -0.06925450265407562,
        -0.052167147397994995,
        0.0610211119055748,
        -0.042145024985075,
        -0.04167616739869118,
        0.061398059129714966,
        0.06572666019201279,
        0.055972713977098465,
        -0.023943541571497917,
        0.023825762793421745,
        0.06291966140270233,
        -0.02533816173672676,
        0.039929356426000595,
        -0.05430860444903374,
        0.0476895309984684,
        -0.05843210965394974,
        -0.04371044039726257,
        -0.011023086495697498,
        -0.06562629342079163,
        0.06015468016266823,
        -0.04551494121551514,
        -0.06965771317481995,
        0.060059916228055954,
        -0.04980514198541641,
        0.00624976959079504,
        -0.025707164779305458,
        -0.03041100688278675,
        -0.05116986110806465,
        -0.04650046303868294,
        -0.05021502077579498,
        0.05508629232645035,
        -0.03127589449286461,
        -0.0504278801381588,
        0.0054618860594928265,
        -0.032814089208841324,
        0.02126656472682953,
        0.05736662819981575,
        0.0007739028078503907,
        0.012716020457446575,
        0.01441213395446539,
        0.06504689157009125,
        0.03969903290271759,
        -0.06490246951580048,
        0.0745028629899025,
        0.025386083871126175,
        -0.05534762516617775,
        0.04628909006714821,
        0.04807806387543678,
        0.07080935686826706,
        -0.029797164723277092,
        0.011360390111804008,
        -0.06360094994306564,
        -0.007218723185360432,
        -0.028288038447499275,
        0.010608834214508533,
        0.05916254594922066,
        -0.044904131442308426,
        -0.006326035130769014,
        0.054624564945697784,
        -0.012029006145894527,
        0.05666264146566391,
        -0.05819779261946678,
        -0.04893266037106514,
        0.0722348690032959,
        -0.06270816922187805,
        0.01993595063686371,
        -0.03174298629164696,
        -0.06242281571030617,
        -0.0031524989753961563,
        0.00515741528943181,
        -0.050012797117233276,
        0.03211047872900963,
        -0.014327422715723515,
        -0.016825929284095764,
        0.025568582117557526,
        0.02409088797867298,
        -0.013254780322313309,
        -0.028629692271351814,
        -0.015741433948278427,
        -0.05060625076293945,
        -0.058410655707120895,
        0.06057966873049736,
        -0.007568443194031715,
        -0.017337007448077202,
        0.07109805941581726,
        -0.023878490552306175,
        0.062300581485033035,
        0.026363158598542213,
        0.021747736260294914,
        -0.07339896261692047,
        -0.055375222116708755,
        0.07550466805696487,
        0.004854114260524511,
        0.034483496099710464,
        0.033596478402614594,
        -0.06108798459172249,
        0.06626377999782562,
        -0.07499632239341736,
        0.022837305441498756,
        -0.038040172308683395,
        -0.03670702502131462,
        -0.04835163429379463,
        0.06953485310077667,
        -0.06601276993751526,
        -0.021830454468727112,
        0.003734557656571269,
        -0.07336407154798508,
        0.05940817669034004,
        -0.008214635774493217,
        -0.056947559118270874,
        0.009958668611943722,
        0.06642649322748184,
        -0.04887501895427704,
        0.06558962911367416,
        0.006297612097114325,
        0.01409071683883667,
        -0.06591621786355972,
        -0.04889430105686188,
        -0.04676016420125961,
        0.06417074054479599,
        0.05053921416401863,
        -0.021976612508296967,
        0.014055762439966202,
        -0.013843825086951256,
        0.0046858410350978374,
        0.013095615431666374,
        0.026263190433382988,
        0.004757746122777462,
        -0.02437864989042282,
        -0.07240983843803406,
        0.01833820343017578,
        0.07057172805070877,
        0.008536330424249172,
        -0.04996471852064133,
        -0.0672396644949913,
        -0.058446407318115234,
        -0.060884151607751846,
        -0.021993204951286316,
        0.03543854132294655,
        0.03154594078660011,
        0.029888074845075607,
        -0.05155126377940178,
        0.06406038999557495,
        0.069952592253685,
        0.06246677413582802,
        0.008134189993143082,
        -0.06777503341436386,
        -0.02839381992816925,
        0.054946959018707275,
        0.043364234268665314,
        0.033598367124795914,
        -0.034333258867263794,
        0.04674453288316727,
        -0.011181622743606567,
        -0.017427988350391388,
        0.06385281682014465,
        -0.04992593452334404,
        0.06318549811840057,
        -0.05859551206231117,
        0.03733053430914879,
        -0.06181655079126358,
        0.05633564293384552,
        -0.03289833664894104,
        0.023077407851815224,
        -0.011700108647346497,
        0.02597486414015293,
        -0.04614843428134918,
        -0.035737380385398865,
        -0.058248721063137054,
        0.030446259304881096,
        0.01900160312652588,
        0.027271267026662827,
        0.03905537351965904,
        -0.01231737993657589,
        -0.0566927045583725,
        -0.048999372869729996,
        -0.023061789572238922,
        0.021218078210949898,
        0.014009229838848114,
        -0.06647086888551712,
        0.0021327652502804995,
        0.050213124603033066,
        -0.011955837719142437,
        -0.06722994148731232,
        0.007892305962741375,
        -0.043480727821588516,
        -0.01984417624771595,
        0.06282496452331543,
        -0.007282139267772436,
        -0.01899593695998192,
        0.04456092789769173,
        0.07320983707904816,
        -0.022736595943570137,
        0.07148104161024094,
        -0.045836906880140305,
        0.0690070316195488,
        -0.018849674612283707,
        -0.033666495233774185,
        0.03167426213622093,
        0.026149868965148926,
        -0.047443684190511703,
        -0.02069300226867199,
        -0.05449339747428894,
        0.021425973623991013,
        0.047917816787958145,
        -0.06302189826965332,
        0.057231608778238297,
        0.0017178971320390701,
        0.02072734758257866,
        -0.07326077669858932,
        0.028733810409903526,
        0.012788422405719757,
        0.07178689539432526,
        -0.06073778495192528,
        0.06410589069128036,
        -0.025782892480492592,
        -0.05956165865063667,
        0.027913019061088562,
        0.00009602312638889998,
        0.04907277598977089,
        -0.04757969453930855,
        -0.022037992253899574,
        -0.05689838528633118,
        0.04806806892156601,
        0.021540606394410133,
        -0.03157682716846466,
        -0.0695897564291954,
        -0.03790215402841568,
        -0.03452960029244423,
        -0.023037545382976532,
        0.010534062050282955,
        0.03949951380491257,
        -0.05621650815010071,
        0.0044549391604959965,
        0.005219921935349703,
        -0.06227801367640495,
        0.06657280027866364,
        -0.05234184116125107,
        -0.056319352239370346,
        -0.014783415012061596,
        -0.012773306109011173,
        0.04852835834026337,
        0.009773075580596924,
        0.006562430877238512,
        0.02931569516658783,
        0.045991845428943634,
        0.060816649347543716,
        -0.0008292267448268831,
        -0.060331813991069794,
        -0.03255602717399597,
        0.0064096893183887005,
        -0.02914302609860897,
        -0.004829488229006529,
        0.011449728161096573,
        0.015223129652440548,
        -0.06826746463775635,
        -0.06571418792009354,
        0.03393615782260895,
        0.06251239031553268
    ],
    [
        -0.014963755384087563,
        -0.06037631630897522,
        0.03027062863111496,
        0.05704256147146225,
        0.020866669714450836,
        0.017688196152448654,
        0.030163371935486794,
        -0.016715342178940773,
        0.044652774930000305,
        0.028724605217576027,
        0.040510497987270355,
        -0.00045165198389440775,
        -0.0036366567946970463,
        -0.01787247695028782,
        -0.05768575519323349,
        0.06074805557727814,
        -0.06667445600032806,
        0.05581515282392502,
        -0.07444663345813751,
        0.030916906893253326,
        -0.0678839161992073,
        0.003405267372727394,
        -0.023645158857107162,
        -0.020226258784532547,
        0.05298718065023422,
        0.054936062544584274,
        0.03218528628349304,
        -0.004748091101646423,
        0.030598048120737076,
        -0.01629657857120037,
        0.04918506741523743,
        -0.013255972415208817,
        -0.028501741588115692,
        -0.06411521136760712,
        0.0023832409642636776,
        0.04193597659468651,
        -0.061782777309417725,
        -0.027754273265600204,
        -0.074123814702034,
        0.021889371797442436,
        0.03908722475171089,
        -0.02029281295835972,
        0.05675194039940834,
        -0.07524286955595016,
        0.040402334183454514,
        0.07104883342981339,
        0.04046792909502983,
        0.05967975780367851,
        0.017535626888275146,
        0.05861363187432289,
        0.05326889455318451,
        0.06422076374292374,
        0.040154192596673965,
        0.013622405007481575,
        -0.03238816186785698,
        -0.027842151001095772,
        0.004875941667705774,
        0.0034837329294532537,
        0.037297572940588,
        0.00662681320682168,
        -0.07025809586048126,
        -0.00891899038106203,
        -0.04106343165040016,
        0.04988905042409897,
        -0.054278045892715454,
        -0.009828084148466587,
        0.06893640011548996,
        0.013011886738240719,
        -0.007183652371168137,
        -0.07364708930253983,
        -0.07130679488182068,
        -0.07388252764940262,
        0.050699297338724136,
        -0.01213020645081997,
        -0.01728030852973461,
        0.01308973878622055,
        -0.02451743744313717,
        0.07366086542606354,
        -0.02233748696744442,
        -0.033510465174913406,
        0.008400367572903633,
        0.07042303681373596,
        -0.0658988431096077,
        0.03389063477516174,
        0.030081717297434807,
        0.04100998491048813,
        0.0583178773522377,
        0.046966586261987686,
        0.060471028089523315,
        0.07285849750041962,
        0.04681655392050743,
        0.04540843144059181,
        -0.04568661004304886,
        -0.04064296558499336,
        -0.03823148459196091,
        0.023146556690335274,
        -0.03777210786938667,
        -0.05242310091853142,
        -0.05849423632025719,
        -0.020033283159136772,
        -0.07371155172586441,
        -0.018215445801615715,
        0.07352375239133835,
        -0.01743282750248909,
        0.058010369539260864,
        -0.04417987912893295,
        -0.025775834918022156,
        0.016237208619713783,
        -0.03779787942767143,
        0.00443693995475769,
        -0.06668508052825928,
        -0.036724310368299484,
        -0.043862003833055496,
        0.00510864332318306,
        0.01847420260310173,
        -0.041402529925107956,
        -0.03576896712183952,
        -0.01426213700324297,
        0.023912575095891953,
        0.029253749176859856,
        -0.005944525822997093,
        0.004575519356876612,
        0.009997533634305,
        0.006599655840545893,
        -0.07014833390712738,
        -0.043673187494277954,
        -0.03884708881378174,
        -0.029321214184165,
        0.07087794691324234,
        0.04360583424568176,
        0.05446229502558708,
        -0.06250248849391937,
        -0.0634966790676117,
        -0.015183525159955025,
        -0.051061682403087616,
        -0.05117608606815338,
        -0.001606677076779306,
        -0.030577370896935463,
        -0.0702235996723175,
        0.05625351890921593,
        0.05083617568016052,
        0.020646056160330772,
        0.05933207646012306,
        0.05187647417187691,
        0.04270010441541672,
        0.0464731901884079,
        0.06354429572820663,
        -0.06817203760147095,
        -0.006670319940894842,
        0.06554466485977173,
        -0.05172932893037796,
        -0.07224109768867493,
        0.011232355609536171,
        -0.032338716089725494,
        0.014647933654487133,
        -0.04114091768860817,
        0.06225096806883812,
        0.03667260706424713,
        -0.01255998108536005,
        0.0005417356733232737,
        0.06892953813076019,
        0.05075322464108467,
        0.05931079015135765,
        -0.06263594329357147,
        0.0067028990015387535,
        0.0003515307034831494,
        -0.059456098824739456,
        -0.04127507656812668,
        0.03647978976368904,
        0.03828725218772888,
        0.06997178494930267,
        0.024857431650161743,
        0.04740685969591141,
        -0.024563444778323174,
        -0.010723885148763657,
        0.0724705308675766,
        -0.07515969127416611,
        0.005597055424004793,
        0.05389029160141945,
        0.02930208295583725,
        0.044258907437324524,
        0.06664346158504486,
        0.05184650048613548,
        -0.0247829407453537,
        -0.008075272664427757,
        0.03271758183836937,
        -0.03569681942462921,
        0.06769806891679764,
        -0.025788094848394394,
        -0.012985805049538612,
        0.039894312620162964,
        0.022402724251151085,
        -0.03964716196060181,
        -0.031555820256471634,
        -0.009797601029276848,
        0.005814877338707447,
        0.002039115410298109,
        0.0016115681501105428,
        -0.040011316537857056,
        0.01226737443357706,
        -0.004655662924051285,
        0.018364612013101578,
        -0.035581737756729126,
        -0.021088814362883568,
        -0.042546648532152176,
        0.043817199766635895,
        0.06308407336473465,
        -0.013873421587049961,
        -0.04355904832482338,
        0.06940782815217972,
        -0.061965975910425186,
        0.056512050330638885,
        -0.0027459694538265467,
        -0.006773358676582575,
        0.03130801394581795,
        -0.006481884978711605,
        -0.03394310548901558,
        -0.01944476179778576,
        0.05945461615920067,
        -0.01435062289237976,
        0.03840738162398338,
        0.0364544503390789,
        -0.01921948604285717,
        -0.07033664733171463,
        0.06552302092313766,
        -0.04029631242156029,
        -0.02497974783182144,
        0.06268751621246338,
        -0.03932439535856247,
        -0.046047110110521317,
        -0.024545712396502495,
        -0.01991402357816696,
        -0.06125732883810997,
        0.042542532086372375,
        -0.039972078055143356,
        0.05064711719751358,
        0.06446244567632675,
        -0.03353022411465645,
        -0.05984422191977501,
        0.06227048113942146,
        0.031592957675457,
        0.06293648481369019,
        -0.04066663980484009,
        0.022155772894620895,
        0.0060875569470226765,
        0.024653606116771698,
        -0.06016549468040466,
        0.007414824794977903,
        -0.04472409933805466,
        -0.048138007521629333,
        -0.001994629856199026,
        -0.0730835348367691,
        -0.021446794271469116,
        -0.06925450265407562,
        -0.052167147397994995,
        0.0610211119055748,
        -0.042145024985075,
        -0.04167616739869118,
        0.061398059129714966,
        0.06572666019201279,
        0.055972713977098465,
        -0.023943541571497917,
        0.023825762793421745,
        0.06291966140270233,
        -0.02533816173672676,
        0.039929356426000595,
        -0.05430860444903374,
        0.0476895309984684,
        -0.05843210965394974,
        -0.04371044039726257,
        -0.011023086495697498,
        -0.06562629342079163,
        0.06015468016266823,
        -0.04551494121551514,
        -0.06965771317481995,
        0.060059916228055954,
        -0.04980514198541641,
        0.00624976959079504,
        -0.025707164779305458,
        -0.03041100688278675,
        -0.05116986110806465,
        -0.04650046303868294,
        -0.05021502077579498,
        0.05508629232645035,
        -0.03127589449286461,
        -0.0504278801381588,
        0.0054618860594928265,
        -0.032814089208841324,
        0.02126656472682953,
        0.05736662819981575,
        0.0007739028078503907,
        0.012716020457446575,
        0.01441213395446539,
        0.06504689157009125,
        0.03969903290271759,
        -0.06490246951580048,
        0.0745028629899025,
        0.025386083871126175,
        -0.05534762516617775,
        0.04628909006714821,
        0.04807806387543678,
        0.07080935686826706,
        -0.029797164723277092,
        0.011360390111804008,
        -0.06360094994306564,
        -0.007218723185360432,
        -0.028288038447499275,
        0.010608834214508533,
        0.05916254594922066,
        -0.044904131442308426,
        -0.006326035130769014,
        0.054624564945697784,
        -0.012029006145894527,
        0.05666264146566391,
        -0.05819779261946678,
        -0.04893266037106514,
        0.0722348690032959,
        -0.06270816922187805,
        0.01993595063686371,
        -0.03174298629164696,
        -0.06242281571030617,
        -0.0031524989753961563,
        0.00515741528943181,
        -0.050012797117233276,
        0.03211047872900963,
        -0.014327422715723515,
        -0.016825929284095764,
        0.025568582117557526,
        0.02409088797867298,
        -0.013254780322313309,
        -0.028629692271351814,
        -0.015741433948278427,
        -0.05060625076293945,
        -0.058410655707120895,
        0.06057966873049736,
        -0.007568443194031715,
        -0.017337007448077202,
        0.07109805941581726,
        -0.023878490552306175,
        0.062300581485033035,
        0.026363158598542213,
        0.021747736260294914,
        -0.07339896261692047,
        -0.055375222116708755,
        0.07550466805696487,
        0.004854114260524511,
        0.034483496099710464,
        0.033596478402614594,
        -0.06108798459172249,
        0.06626377999782562,
        -0.07499632239341736,
        0.022837305441498756,
        -0.038040172308683395,
        -0.03670702502131462,
        -0.04835163429379463,
        0.06953485310077667,
        -0.06601276993751526,
        -0.021830454468727112,
        0.003734557656571269,
        -0.07336407154798508,
        0.05940817669034004,
        -0.008214635774493217,
        -0.056947559118270874,
        0.009958668611943722,
        0.06642649322748184,
        -0.04887501895427704,
        0.06558962911367416,
        0.006297612097114325,
        0.01409071683883667,
        -0.06591621786355972,
        -0.04889430105686188,
        -0.04676016420125961,
        0.06417074054479599,
        0.05053921416401863,
        -0.021976612508296967,
        0.014055762439966202,
        -0.013843825086951256,
        0.0046858410350978374,
        0.013095615431666374,
        0.026263190433382988,
        0.004757746122777462,
        -0.02437864989042282,
        -0.07240983843803406,
        0.01833820343017578,
        0.07057172805070877,
        0.008536330424249172,
        -0.04996471852064133,
        -0.0672396644949913,
        -0.058446407318115234,
        -0.060884151607751846,
        -0.021993204951286316,
        0.03543854132294655,
        0.03154594078660011,
        0.029888074845075607,
        -0.05155126377940178,
        0.06406038999557495,
        0.069952592253685,
        0.06246677413582802,
        0.008134189993143082,
        -0.06777503341436386,
        -0.02839381992816925,
        0.054946959018707275,
        0.043364234268665314,
        0.033598367124795914,
        -0.034333258867263794,
        0.04674453288316727,
        -0.011181622743606567,
        -0.017427988350391388,
        0.06385281682014465,
        -0.04992593452334404,
        0.06318549811840057,
        -0.05859551206231117,
        0.03733053430914879,
        -0.06181655079126358,
        0.05633564293384552,
        -0.03289833664894104,
        0.023077407851815224,
        -0.011700108647346497,
        0.02597486414015293,
        -0.04614843428134918,
        -0.035737380385398865,
        -0.058248721063137054,
        0.030446259304881096,
        0.01900160312652588,
        0.027271267026662827,
        0.03905537351965904,
        -0.01231737993657589,
        -0.0566927045583725,
        -0.048999372869729996,
        -0.023061789572238922,
        0.021218078210949898,
        0.014009229838848114,
        -0.06647086888551712,
        0.0021327652502804995,
        0.050213124603033066,
        -0.011955837719142437,
        -0.06722994148731232,
        0.007892305962741375,
        -0.043480727821588516,
        -0.01984417624771595,
        0.06282496452331543,
        -0.007282139267772436,
        -0.01899593695998192,
        0.04456092789769173,
        0.07320983707904816,
        -0.022736595943570137,
        0.07148104161024094,
        -0.045836906880140305,
        0.0690070316195488,
        -0.018849674612283707,
        -0.033666495233774185,
        0.03167426213622093,
        0.026149868965148926,
        -0.047443684190511703,
        -0.02069300226867199,
        -0.05449339747428894,
        0.021425973623991013,
        0.047917816787958145,
        -0.06302189826965332,
        0.057231608778238297,
        0.0017178971320390701,
        0.02072734758257866,
        -0.07326077669858932,
        0.028733810409903526,
        0.012788422405719757,
        0.07178689539432526,
        -0.06073778495192528,
        0.06410589069128036,
        -0.025782892480492592,
        -0.05956165865063667,
        0.027913019061088562,
        0.00009602312638889998,
        0.04907277598977089,
        -0.04757969453930855,
        -0.022037992253899574,
        -0.05689838528633118,
        0.04806806892156601,
        0.021540606394410133,
        -0.03157682716846466,
        -0.0695897564291954,
        -0.03790215402841568,
        -0.03452960029244423,
        -0.023037545382976532,
        0.010534062050282955,
        0.03949951380491257,
        -0.05621650815010071,
        0.0044549391604959965,
        0.005219921935349703,
        -0.06227801367640495,
        0.06657280027866364,
        -0.05234184116125107,
        -0.056319352239370346,
        -0.014783415012061596,
        -0.012773306109011173,
        0.04852835834026337,
        0.009773075580596924,
        0.006562430877238512,
        0.02931569516658783,
        0.045991845428943634,
        0.060816649347543716,
        -0.0008292267448268831,
        -0.060331813991069794,
        -0.03255602717399597,
        0.0064096893183887005,
        -0.02914302609860897,
        -0.004829488229006529,
        0.011449728161096573,
        0.015223129652440548,
        -0.06826746463775635,
        -0.06571418792009354,
        0.03393615782260895,
        0.06251239031553268
    ],
    [
        -0.03137312829494476,
        -0.06482716649770737,
        0.01832808554172516,
        0.059287965297698975,
        0.02432568371295929,
        0.004592822398990393,
        0.03477075695991516,
        -0.012228135019540787,
        0.04526544734835625,
        0.035146910697221756,
        0.045505791902542114,
        -0.00697912136092782,
        -0.00012628964032046497,
        -0.017834417521953583,
        -0.05719269812107086,
        0.05883407220244408,
        -0.0678396001458168,
        0.05373561754822731,
        -0.07239548861980438,
        0.020557209849357605,
        -0.06659696996212006,
        -0.003357317764312029,
        -0.011672577820718288,
        -0.02173757366836071,
        0.055067721754312515,
        0.055331263691186905,
        0.04153814539313316,
        -0.0048498064279556274,
        0.03170778229832649,
        -0.0005076500819995999,
        0.04611608013510704,
        -0.0009170948178507388,
        -0.034876275807619095,
        -0.06760574877262115,
        0.0039602783508598804,
        0.039885178208351135,
        -0.06260786950588226,
        -0.027784235775470734,
        -0.07218746095895767,
        0.02635849080979824,
        0.040230561047792435,
        -0.014739582315087318,
        0.05328293889760971,
        -0.07268930971622467,
        0.039729222655296326,
        0.06972737610340118,
        0.049420230090618134,
        0.061680566519498825,
        0.03439738601446152,
        0.05641743168234825,
        0.05848494917154312,
        0.06239531561732292,
        0.039529360830783844,
        0.024033954367041588,
        -0.03453966602683067,
        -0.03513285145163536,
        -0.01245045568794012,
        0.008379865437746048,
        0.04895487427711487,
        0.00703804474323988,
        -0.06760896742343903,
        -0.007793433498591185,
        -0.041936684399843216,
        0.05158558487892151,
        -0.052101533859968185,
        -0.009416237473487854,
        0.06778401881456375,
        -0.0006761524127796292,
        -0.008992349728941917,
        -0.0719769299030304,
        -0.07077819854021072,
        -0.07224372029304504,
        0.054242879152297974,
        -0.028968803584575653,
        -0.00222655083052814,
        0.02053108625113964,
        -0.012861200608313084,
        0.07037103921175003,
        -0.019366303458809853,
        -0.049118783324956894,
        0.012603914365172386,
        0.06869079917669296,
        -0.06490263342857361,
        0.04211050271987915,
        0.04202641174197197,
        0.04240948706865311,
        0.05633098632097244,
        0.05070459842681885,
        0.06265737861394882,
        0.07100822031497955,
        0.019703123718500137,
        0.04001764580607414,
        -0.05190527066588402,
        -0.049451034516096115,
        -0.02664942294359207,
        0.02404244989156723,
        -0.03799809515476227,
        -0.050974588841199875,
        -0.06004965305328369,
        -0.019768381491303444,
        -0.07098587602376938,
        -0.004580874461680651,
        0.07169228047132492,
        -0.006376533769071102,
        0.06094593182206154,
        -0.041541457176208496,
        -0.007347725331783295,
        0.01995180733501911,
        -0.040108248591423035,
        0.01561164390295744,
        -0.06651567667722702,
        -0.03609504550695419,
        -0.03775876760482788,
        0.011909483931958675,
        0.011960401199758053,
        -0.05211266130208969,
        -0.031552236527204514,
        -0.02503490075469017,
        0.01641646772623062,
        0.03355336934328079,
        0.01489281002432108,
        0.01934746652841568,
        0.0022767160553485155,
        0.0228487029671669,
        -0.06744691729545593,
        -0.03157662972807884,
        -0.03951500728726387,
        -0.04782595857977867,
        0.06825035065412521,
        0.04593110829591751,
        0.058345407247543335,
        -0.05755307897925377,
        -0.0634915828704834,
        -0.015244337730109692,
        -0.055002618581056595,
        -0.045635346323251724,
        0.0007517450139857829,
        -0.024508720263838768,
        -0.0684291198849678,
        0.05925287306308746,
        0.04961246997117996,
        0.02209516242146492,
        0.057383231818675995,
        0.038637589663267136,
        0.04239892587065697,
        0.041827086359262466,
        0.06229094788432121,
        -0.06682181358337402,
        -0.0049993982538580894,
        0.06622575223445892,
        -0.05234774574637413,
        -0.07027100026607513,
        0.015751812607049942,
        -0.0215141624212265,
        0.021633338183164597,
        -0.044984254986047745,
        0.06277244538068771,
        0.03607865050435066,
        -0.02097698114812374,
        0.014924775809049606,
        0.06846510618925095,
        0.04536587744951248,
        0.056872110813856125,
        -0.06457430869340897,
        -0.0005499037215486169,
        0.010211817920207977,
        -0.058252133429050446,
        -0.03917061537504196,
        0.029095027595758438,
        0.03966570273041725,
        0.0665203332901001,
        0.028342029079794884,
        0.04461880028247833,
        -0.02544260211288929,
        -0.020439472049474716,
        0.07106898725032806,
        -0.07282880693674088,
        0.006775944493710995,
        0.04351409524679184,
        0.011763602495193481,
        0.04556446149945259,
        0.06854492425918579,
        0.04970837011933327,
        -0.008344563655555248,
        -0.006377092562615871,
        0.03246071934700012,
        -0.04296603798866272,
        0.06788155436515808,
        -0.022395435720682144,
        -0.025464290753006935,
        0.03916799649596214,
        0.027392935007810593,
        -0.048295967280864716,
        -0.026236645877361298,
        -0.015086762607097626,
        -0.003494408680126071,
        -0.002288426971063018,
        -0.0033384186681360006,
        -0.04794512316584587,
        0.01161503978073597,
        -0.00518344109877944,
        0.01822265051305294,
        -0.042762961238622665,
        -0.01905171573162079,
        -0.03864167258143425,
        0.0466616116464138,
        0.06246791407465935,
        -0.00731623126193881,
        -0.045917119830846786,
        0.06736572086811066,
        -0.05997448414564133,
        0.05881824344396591,
        -0.008038478903472424,
        -0.006396283861249685,
        0.025693722069263458,
        -0.015421628020703793,
        -0.02776709385216236,
        -0.03246794268488884,
        0.06140940636396408,
        -0.027424821630120277,
        0.024664362892508507,
        0.030362443998456,
        -0.029392750933766365,
        -0.06844906508922577,
        0.067197285592556,
        -0.050077710300683975,
        -0.017674526199698448,
        0.05912309139966965,
        -0.038765911012887955,
        -0.046718161553144455,
        -0.013865985907614231,
        -0.038024064153432846,
        -0.06017598509788513,
        0.044941578060388565,
        -0.05356501415371895,
        0.03922926262021065,
        0.0645759329199791,
        -0.039155710488557816,
        -0.05881502106785774,
        0.0557861402630806,
        0.03380786255002022,
        0.06211293861269951,
        -0.049873173236846924,
        0.03129143640398979,
        0.010675685480237007,
        0.030054079368710518,
        -0.053451716899871826,
        0.019731227308511734,
        -0.03773704543709755,
        -0.04539404436945915,
        -0.0016010489780455828,
        -0.07137784361839294,
        -0.029972588643431664,
        -0.0674845352768898,
        -0.05212422087788582,
        0.05766931176185608,
        -0.043591827154159546,
        -0.04602907598018646,
        0.05972055718302727,
        0.06369631737470627,
        0.05617397651076317,
        -0.022694656625390053,
        0.031625766307115555,
        0.0659465417265892,
        -0.034081220626831055,
        0.03774630278348923,
        -0.05316152423620224,
        0.04995128884911537,
        -0.0536842867732048,
        -0.04075242206454277,
        -0.01739167608320713,
        -0.0676577240228653,
        0.060754869133234024,
        -0.04796363785862923,
        -0.06814675778150558,
        0.05387894809246063,
        -0.04875591769814491,
        0.004477987065911293,
        -0.027992406859993935,
        -0.02832839824259281,
        -0.0550202801823616,
        -0.04345244541764259,
        -0.0472431555390358,
        0.05770377069711685,
        -0.02414833940565586,
        -0.05284462496638298,
        0.0031972008291631937,
        -0.03799190744757652,
        0.02423587255179882,
        0.053972870111465454,
        -0.00960654765367508,
        0.006168042775243521,
        0.013139685615897179,
        0.06281930208206177,
        0.04372905194759369,
        -0.06285489350557327,
        0.07198838889598846,
        0.036063916981220245,
        -0.056505054235458374,
        0.04890689626336098,
        0.048615384846925735,
        0.06927573680877686,
        -0.02198421210050583,
        0.006052584387362003,
        -0.06067832559347153,
        -0.021637070924043655,
        -0.026642950251698494,
        0.006884951144456863,
        0.060977399349212646,
        -0.03937416523694992,
        -0.00690856110304594,
        0.04918045923113823,
        -0.02658778615295887,
        0.06008701026439667,
        -0.05760751664638519,
        -0.050241537392139435,
        0.07066578418016434,
        -0.061736926436424255,
        0.019437450915575027,
        -0.03849995881319046,
        -0.05998460575938225,
        -0.010888800024986267,
        0.008460014127194881,
        -0.04771919921040535,
        0.027536025270819664,
        -0.008489991538226604,
        -0.023374995216727257,
        0.037106845527887344,
        0.026663854718208313,
        -0.019552476704120636,
        -0.017843136563897133,
        -0.007338821422308683,
        -0.04930722340941429,
        -0.05526650696992874,
        0.05959637835621834,
        0.005050780717283487,
        -0.025486141443252563,
        0.06763281673192978,
        -0.015357290394604206,
        0.05955558270215988,
        0.027784569188952446,
        0.011414856649935246,
        -0.07137980312108994,
        -0.06223950907588005,
        0.07307370752096176,
        -0.004087350331246853,
        0.036034222692251205,
        0.03271216154098511,
        -0.0616091750562191,
        0.06291207671165466,
        -0.07268638163805008,
        0.027291400358080864,
        -0.04752059280872345,
        -0.05540292337536812,
        -0.05185391381382942,
        0.06565196812152863,
        -0.066388338804245,
        -0.03322989493608475,
        0.001428059651516378,
        -0.07134595513343811,
        0.05704376846551895,
        -0.005005635321140289,
        -0.05160340294241905,
        0.012308011762797832,
        0.06542206555604935,
        -0.04982788860797882,
        0.06864409893751144,
        0.0032412554137408733,
        0.03339628875255585,
        -0.06500816345214844,
        -0.050300560891628265,
        -0.05067235603928566,
        0.06378216296434402,
        0.04794114828109741,
        -0.005714809987694025,
        0.0222921185195446,
        -0.010441566817462444,
        0.003232364309951663,
        0.017355404794216156,
        0.03419593349099159,
        -0.007348889950662851,
        -0.01956132985651493,
        -0.07002327591180801,
        -0.003505136352032423,
        0.06601347029209137,
        0.0006934518460184336,
        -0.05244980379939079,
        -0.06425362825393677,
        -0.053847916424274445,
        -0.05725673958659172,
        -0.03564302995800972,
        0.037309691309928894,
        0.03322853520512581,
        0.03901178017258644,
        -0.04601613059639931,
        0.06410025805234909,
        0.06895162165164948,
        0.055760275572538376,
        0.02055610530078411,
        -0.06754794716835022,
        -0.030569858849048615,
        0.05689593032002449,
        0.04917199909687042,
        0.03361499682068825,
        -0.038328949362039566,
        0.04370339959859848,
        -0.008273628540337086,
        -0.01881842315196991,
        0.059769876301288605,
        -0.05439116060733795,
        0.06428835541009903,
        -0.05308008939027786,
        0.04062825068831444,
        -0.054348260164260864,
        0.05431384593248367,
        -0.03516404330730438,
        0.01991979219019413,
        -0.011018726974725723,
        0.020873991772532463,
        -0.04666507989168167,
        -0.03032979555428028,
        -0.05561589449644089,
        0.03978049382567406,
        0.014379126019775867,
        0.03894760087132454,
        0.03726283833384514,
        -0.021161137148737907,
        -0.048542167991399765,
        -0.05234459787607193,
        -0.021343529224395752,
        0.030312204733490944,
        0.01818707399070263,
        -0.06750133633613586,
        0.00037703971611335874,
        0.04199318587779999,
        -0.01946096308529377,
        -0.057451870292425156,
        0.011346036568284035,
        -0.032834719866514206,
        -0.014144285582005978,
        0.05748916417360306,
        -0.011098502203822136,
        -0.011404621414840221,
        0.04992973059415817,
        0.07092321664094925,
        -0.029027894139289856,
        0.06917882710695267,
        -0.05233132094144821,
        0.06687705218791962,
        -0.022938156500458717,
        -0.03813163936138153,
        0.0359787717461586,
        0.041007671505212784,
        -0.04575697332620621,
        -0.01662326790392399,
        -0.052027780562639236,
        0.016962818801403046,
        0.05063667520880699,
        -0.06419031322002411,
        0.06492302566766739,
        -0.016898447647690773,
        0.020190250128507614,
        -0.07167666405439377,
        0.024382837116718292,
        0.033396001905202866,
        0.07014842331409454,
        -0.06105133146047592,
        0.0644846186041832,
        -0.02702338807284832,
        -0.059529274702072144,
        0.027089282870292664,
        0.015061030164361,
        0.05353818088769913,
        -0.04297498241066933,
        -0.04098210483789444,
        -0.046294450759887695,
        0.03970005735754967,
        0.026746010407805443,
        -0.03347671404480934,
        -0.06808532029390335,
        -0.044694624841213226,
        -0.02927418425679207,
        -0.028881965205073357,
        0.012999627739191055,
        0.03653820604085922,
        -0.05133754014968872,
        0.00016508743283338845,
        -0.0040926458314061165,
        -0.059244588017463684,
        0.060184452682733536,
        -0.05064459517598152,
        -0.0573151521384716,
        -0.008829599246382713,
        -0.012436921708285809,
        0.05345512926578522,
        0.007991774939000607,
        -0.003971250727772713,
        0.04369297996163368,
        0.04438479617238045,
        0.06218087673187256,
        0.0042676739394664764,
        -0.0579385869204998,
        -0.026210347190499306,
        -0.0016456766752526164,
        -0.01896785944700241,
        -0.008688019588589668,
        0.019183704629540443,
        0.007280632387846708,
        -0.0668795183300972,
        -0.06339246034622192,
        0.037889789789915085,
        0.06275201588869095
    ],
    [
        -0.010383648797869682,
        -0.0603373758494854,
        0.028882034122943878,
        0.057632382959127426,
        0.019496280699968338,
        0.017104465514421463,
        0.027747439220547676,
        -0.01611180603504181,
        0.04373934492468834,
        0.03217574208974838,
        0.03917869180440903,
        0.008012628182768822,
        -0.009158813394606113,
        -0.016099734231829643,
        -0.058506134897470474,
        0.06172456592321396,
        -0.06744171679019928,
        0.05397963896393776,
        -0.07395417988300323,
        0.03779786825180054,
        -0.06788862496614456,
        0.002665736712515354,
        -0.017812076956033707,
        -0.02563737891614437,
        0.05550159886479378,
        0.051527272909879684,
        0.03488971292972565,
        -0.01102084293961525,
        0.028326286002993584,
        -0.015566226094961166,
        0.04364567995071411,
        -0.020673951134085655,
        -0.02748447097837925,
        -0.06327606737613678,
        0.00234258477576077,
        0.03139427676796913,
        -0.06193158030509949,
        -0.03401774913072586,
        -0.07339832186698914,
        0.02489272505044937,
        0.03154430165886879,
        -0.017400257289409637,
        0.05890386551618576,
        -0.07472803443670273,
        0.0389910526573658,
        0.07117384672164917,
        0.05046563595533371,
        0.05545283481478691,
        0.011322359554469585,
        0.06098739802837372,
        0.051689088344573975,
        0.06295516341924667,
        0.04943418130278587,
        0.020272271707654,
        -0.027924848720431328,
        -0.033141668885946274,
        -0.001908650971017778,
        0.01270398497581482,
        0.03646451607346535,
        0.01122109591960907,
        -0.06869392842054367,
        -0.006089423783123493,
        -0.041275352239608765,
        0.050106264650821686,
        -0.05201263725757599,
        -0.014244194142520428,
        0.0687793493270874,
        -0.00030980209703557193,
        -0.013200271874666214,
        -0.0733683854341507,
        -0.06971137225627899,
        -0.07323053479194641,
        0.05295123532414436,
        -0.011578801088035107,
        -0.020038560032844543,
        0.006651758216321468,
        -0.01818142458796501,
        0.07304795831441879,
        -0.018069621175527573,
        -0.03310989588499069,
        0.017005423083901405,
        0.0711815282702446,
        -0.06412702053785324,
        0.03435596451163292,
        0.03278612717986107,
        0.044502999633550644,
        0.058111291378736496,
        0.04523979127407074,
        0.05633259564638138,
        0.07318630814552307,
        0.036873795092105865,
        0.0399235375225544,
        -0.04899352788925171,
        -0.042526841163635254,
        -0.03780743107199669,
        0.02609316259622574,
        -0.04231482371687889,
        -0.051526933908462524,
        -0.05807174742221832,
        -0.015095153823494911,
        -0.07380147278308868,
        -0.01385115273296833,
        0.07289186120033264,
        -0.014817812480032444,
        0.06281561404466629,
        -0.03953290730714798,
        -0.02351214736700058,
        0.011766643263399601,
        -0.037740834057331085,
        0.00035608085454441607,
        -0.06631284207105637,
        -0.04018913954496384,
        -0.044809404760599136,
        -0.0032084458507597446,
        0.007746776100248098,
        -0.041748713701963425,
        -0.036448948085308075,
        -0.016117986291646957,
        0.02356242761015892,
        0.02407301776111126,
        0.004369031172245741,
        0.003933292347937822,
        0.00247750012204051,
        0.010035422630608082,
        -0.07052230089902878,
        -0.046556130051612854,
        -0.03650187328457832,
        -0.034642431885004044,
        0.07104261219501495,
        0.035472799092531204,
        0.051621630787849426,
        -0.063798688352108,
        -0.06514915078878403,
        -0.002325340174138546,
        -0.05582933500409126,
        -0.05341339856386185,
        -0.0071114301681518555,
        -0.03385172784328461,
        -0.07113280147314072,
        0.05644432082772255,
        0.049876317381858826,
        0.024104619398713112,
        0.06181688234210014,
        0.05124697461724281,
        0.0479620136320591,
        0.0547432005405426,
        0.06579432636499405,
        -0.06853912025690079,
        -0.005109927151352167,
        0.06485309451818466,
        -0.05153423547744751,
        -0.07172228395938873,
        0.00461149588227272,
        -0.03156166896224022,
        0.0039044294971972704,
        -0.039881158620119095,
        0.06218045949935913,
        0.037063661962747574,
        -0.004815865773707628,
        -0.0027494889218360186,
        0.06777413189411163,
        0.04741281643509865,
        0.0586831271648407,
        -0.05862352252006531,
        0.01457354798913002,
        0.0007986282580532134,
        -0.06145482510328293,
        -0.044167667627334595,
        0.03995499759912491,
        0.03820477053523064,
        0.07026785612106323,
        0.03480272367596626,
        0.04648132249712944,
        -0.023910097777843475,
        -0.015647685155272484,
        0.07159563899040222,
        -0.0746455192565918,
        0.00993223488330841,
        0.04788888245820999,
        0.02621867135167122,
        0.04507151246070862,
        0.06796551495790482,
        0.05583759397268295,
        -0.02038547210395336,
        -0.0038264659233391285,
        0.031040608882904053,
        -0.03315011411905289,
        0.06791488826274872,
        -0.029146861284971237,
        -0.02135307714343071,
        0.041701462119817734,
        0.011769765056669712,
        -0.04032484069466591,
        -0.03144272044301033,
        -0.0017637814162299037,
        0.006072846706956625,
        0.00782047025859356,
        -0.0017664741026237607,
        -0.03918389603495598,
        0.008224708028137684,
        -0.005769441369920969,
        0.020527230575680733,
        -0.038987889885902405,
        -0.015596290118992329,
        -0.041721757501363754,
        0.040562842041254044,
        0.06451153755187988,
        -0.008740637451410294,
        -0.0436662882566452,
        0.0682906061410904,
        -0.06269780546426773,
        0.05698738619685173,
        0.0047278376296162605,
        -0.0019237087108194828,
        0.031166208907961845,
        -0.0049312966875731945,
        -0.01825941912829876,
        -0.017020119354128838,
        0.061371009796857834,
        -0.023383235558867455,
        0.036851666867733,
        0.04235561192035675,
        -0.024671638384461403,
        -0.07014887034893036,
        0.06356673687696457,
        -0.04466886445879936,
        -0.021765999495983124,
        0.06023750826716423,
        -0.041180793195962906,
        -0.048079729080200195,
        -0.023236652836203575,
        -0.02623971551656723,
        -0.058045461773872375,
        0.04807788133621216,
        -0.03677164763212204,
        0.04617498070001602,
        0.06549496203660965,
        -0.035435717552900314,
        -0.05791594088077545,
        0.06448692828416824,
        0.034924209117889404,
        0.06165347248315811,
        -0.04980742558836937,
        0.02654176950454712,
        0.0014111371710896492,
        0.020889930427074432,
        -0.057586632668972015,
        0.004724045284092426,
        -0.03996306657791138,
        -0.04472234100103378,
        0.0009104429627768695,
        -0.07294715195894241,
        -0.01933559775352478,
        -0.06891059875488281,
        -0.05128690227866173,
        0.060584090650081635,
        -0.04494586959481239,
        -0.044591404497623444,
        0.06429167836904526,
        0.06514173746109009,
        0.05629587545990944,
        -0.02990618906915188,
        0.02417718805372715,
        0.060157958418130875,
        -0.027518978342413902,
        0.03983572497963905,
        -0.047494180500507355,
        0.05331145226955414,
        -0.05627506971359253,
        -0.041231151670217514,
        -0.011747377924621105,
        -0.06663738936185837,
        0.0558268167078495,
        -0.04371209815144539,
        -0.06913858652114868,
        0.06044447049498558,
        -0.0524778850376606,
        0.003685830859467387,
        -0.013077986426651478,
        -0.028566092252731323,
        -0.04585110768675804,
        -0.04808727279305458,
        -0.04918047785758972,
        0.0556584969162941,
        -0.03688230738043785,
        -0.05058395862579346,
        -0.003017223672941327,
        -0.03606477379798889,
        0.020236877724528313,
        0.058455076068639755,
        0.007663903757929802,
        0.017653625458478928,
        0.015386358834803104,
        0.06477629393339157,
        0.04099416360259056,
        -0.06523000448942184,
        0.07407551258802414,
        0.020048346370458603,
        -0.05286633223295212,
        0.048196546733379364,
        0.038549795746803284,
        0.0709051564335823,
        -0.03239341825246811,
        0.008492586202919483,
        -0.06510064750909805,
        -0.013454577885568142,
        -0.03478728607296944,
        0.009435269981622696,
        0.05803834646940231,
        -0.05035115033388138,
        -0.00581522798165679,
        0.054901134222745895,
        -0.019144849851727486,
        0.05789731442928314,
        -0.05941852927207947,
        -0.046956032514572144,
        0.07219831645488739,
        -0.06301993131637573,
        0.02401246689260006,
        -0.03633812069892883,
        -0.06369441002607346,
        -0.001722589717246592,
        0.00011921516852453351,
        -0.051449500024318695,
        0.03313245251774788,
        -0.009057032875716686,
        -0.023103151470422745,
        0.029464561492204666,
        0.02965409867465496,
        -0.016626890748739243,
        -0.027069734409451485,
        -0.019529173150658607,
        -0.04910261183977127,
        -0.05950343981385231,
        0.05927775427699089,
        -0.008926731534302235,
        -0.008602526038885117,
        0.07126893848180771,
        -0.01599840447306633,
        0.060967590659856796,
        0.03000028431415558,
        0.0185677632689476,
        -0.07329768687486649,
        -0.054937995970249176,
        0.075010284781456,
        0.005940021947026253,
        0.03356266766786575,
        0.03673844784498215,
        -0.05922773480415344,
        0.06782615184783936,
        -0.07464092969894409,
        0.01955799013376236,
        -0.03644794970750809,
        -0.03241051733493805,
        -0.05007633566856384,
        0.06950784474611282,
        -0.0663805827498436,
        -0.021341031417250633,
        0.003676677355542779,
        -0.07251422852277756,
        0.06053686514496803,
        -0.007545642089098692,
        -0.05908133462071419,
        0.006209782790392637,
        0.0656084343791008,
        -0.0502922423183918,
        0.06368573009967804,
        -0.0008185263141058385,
        0.0032848708797246218,
        -0.06651996076107025,
        -0.04766362905502319,
        -0.04934987798333168,
        0.06295958906412125,
        0.05403204262256622,
        -0.021304327994585037,
        0.013805491849780083,
        -0.006877850741147995,
        0.0014287583762779832,
        0.019782479852437973,
        0.0315556675195694,
        -0.002334524178877473,
        -0.02554342895746231,
        -0.0719432458281517,
        0.021398894488811493,
        0.07026973366737366,
        0.00014586045290343463,
        -0.05007973313331604,
        -0.06737290322780609,
        -0.057024095207452774,
        -0.05834190174937248,
        -0.024898141622543335,
        0.03309771791100502,
        0.036512602120637894,
        0.028823088854551315,
        -0.049595482647418976,
        0.06363949179649353,
        0.07039140909910202,
        0.0623028539121151,
        0.006555574014782906,
        -0.06847954541444778,
        -0.02882355824112892,
        0.05224224179983139,
        0.046278104186058044,
        0.02869599685072899,
        -0.027228403836488724,
        0.04727114364504814,
        -0.008539289236068726,
        -0.015879197046160698,
        0.06374621391296387,
        -0.04423489794135094,
        0.06324887275695801,
        -0.05607331544160843,
        0.03823831304907799,
        -0.06047530099749565,
        0.05227541923522949,
        -0.033646244555711746,
        0.011990777216851711,
        -0.008807562291622162,
        0.02969745546579361,
        -0.039910949766635895,
        -0.04776936396956444,
        -0.056518375873565674,
        0.030474836006760597,
        0.023078765720129013,
        0.018364453688263893,
        0.04001353308558464,
        -0.014321621507406235,
        -0.055235423147678375,
        -0.054293468594551086,
        -0.022279314696788788,
        0.022752802819013596,
        0.02503499761223793,
        -0.06578109413385391,
        -0.0034474795684218407,
        0.04980992153286934,
        -0.009357278235256672,
        -0.06933888047933578,
        0.02353203296661377,
        -0.04766114056110382,
        -0.014611823484301567,
        0.0631401538848877,
        -0.012955346144735813,
        -0.014307450503110886,
        0.04888486489653587,
        0.07240632176399231,
        -0.02418624237179756,
        0.07152631133794785,
        -0.0546288788318634,
        0.06847111880779266,
        -0.02848268859088421,
        -0.033164191991090775,
        0.02062026411294937,
        0.025458309799432755,
        -0.04194185137748718,
        -0.016872795298695564,
        -0.057598285377025604,
        0.017751965671777725,
        0.04520302265882492,
        -0.06248091161251068,
        0.055861927568912506,
        -0.004212451633065939,
        0.02317127026617527,
        -0.07326081395149231,
        0.022935934364795685,
        0.008356655016541481,
        0.07102347910404205,
        -0.061670854687690735,
        0.06029617413878441,
        -0.022769147530198097,
        -0.056604333221912384,
        0.0250104833394289,
        0.0058008343912661076,
        0.05208628997206688,
        -0.0489332377910614,
        -0.01410688180476427,
        -0.05586126446723938,
        0.050437942147254944,
        0.020276907831430435,
        -0.026804210618138313,
        -0.06967351585626602,
        -0.04272160306572914,
        -0.03440532833337784,
        -0.02453955076634884,
        0.005690011195838451,
        0.03709542006254196,
        -0.052436165511608124,
        0.016593631356954575,
        0.002785508520901203,
        -0.06406997889280319,
        0.06333541125059128,
        -0.05297088995575905,
        -0.056756407022476196,
        -0.013913827948272228,
        -0.016045356169342995,
        0.054877039045095444,
        0.014982474036514759,
        0.011679076589643955,
        0.026262756437063217,
        0.04901393502950668,
        0.06023487448692322,
        -0.010631673969328403,
        -0.0574340857565403,
        -0.03313053026795387,
        0.0011560521088540554,
        -0.024820292368531227,
        -0.007943343371152878,
        0.0076092868112027645,
        0.022420724853873253,
        -0.06929660588502884,
        -0.06586777418851852,
        0.036083903163671494,
        0.06481865048408508
    ],
    [
        -0.010383648797869682,
        -0.0603373758494854,
        0.028882034122943878,
        0.057632382959127426,
        0.019496280699968338,
        0.017104465514421463,
        0.027747439220547676,
        -0.01611180603504181,
        0.04373934492468834,
        0.03217574208974838,
        0.03917869180440903,
        0.008012628182768822,
        -0.009158813394606113,
        -0.016099734231829643,
        -0.058506134897470474,
        0.06172456592321396,
        -0.06744171679019928,
        0.05397963896393776,
        -0.07395417988300323,
        0.03779786825180054,
        -0.06788862496614456,
        0.002665736712515354,
        -0.017812076956033707,
        -0.02563737891614437,
        0.05550159886479378,
        0.051527272909879684,
        0.03488971292972565,
        -0.01102084293961525,
        0.028326286002993584,
        -0.015566226094961166,
        0.04364567995071411,
        -0.020673951134085655,
        -0.02748447097837925,
        -0.06327606737613678,
        0.00234258477576077,
        0.03139427676796913,
        -0.06193158030509949,
        -0.03401774913072586,
        -0.07339832186698914,
        0.02489272505044937,
        0.03154430165886879,
        -0.017400257289409637,
        0.05890386551618576,
        -0.07472803443670273,
        0.0389910526573658,
        0.07117384672164917,
        0.05046563595533371,
        0.05545283481478691,
        0.011322359554469585,
        0.06098739802837372,
        0.051689088344573975,
        0.06295516341924667,
        0.04943418130278587,
        0.020272271707654,
        -0.027924848720431328,
        -0.033141668885946274,
        -0.001908650971017778,
        0.01270398497581482,
        0.03646451607346535,
        0.01122109591960907,
        -0.06869392842054367,
        -0.006089423783123493,
        -0.041275352239608765,
        0.050106264650821686,
        -0.05201263725757599,
        -0.014244194142520428,
        0.0687793493270874,
        -0.00030980209703557193,
        -0.013200271874666214,
        -0.0733683854341507,
        -0.06971137225627899,
        -0.07323053479194641,
        0.05295123532414436,
        -0.011578801088035107,
        -0.020038560032844543,
        0.006651758216321468,
        -0.01818142458796501,
        0.07304795831441879,
        -0.018069621175527573,
        -0.03310989588499069,
        0.017005423083901405,
        0.0711815282702446,
        -0.06412702053785324,
        0.03435596451163292,
        0.03278612717986107,
        0.044502999633550644,
        0.058111291378736496,
        0.04523979127407074,
        0.05633259564638138,
        0.07318630814552307,
        0.036873795092105865,
        0.0399235375225544,
        -0.04899352788925171,
        -0.042526841163635254,
        -0.03780743107199669,
        0.02609316259622574,
        -0.04231482371687889,
        -0.051526933908462524,
        -0.05807174742221832,
        -0.015095153823494911,
        -0.07380147278308868,
        -0.01385115273296833,
        0.07289186120033264,
        -0.014817812480032444,
        0.06281561404466629,
        -0.03953290730714798,
        -0.02351214736700058,
        0.011766643263399601,
        -0.037740834057331085,
        0.00035608085454441607,
        -0.06631284207105637,
        -0.04018913954496384,
        -0.044809404760599136,
        -0.0032084458507597446,
        0.007746776100248098,
        -0.041748713701963425,
        -0.036448948085308075,
        -0.016117986291646957,
        0.02356242761015892,
        0.02407301776111126,
        0.004369031172245741,
        0.003933292347937822,
        0.00247750012204051,
        0.010035422630608082,
        -0.07052230089902878,
        -0.046556130051612854,
        -0.03650187328457832,
        -0.034642431885004044,
        0.07104261219501495,
        0.035472799092531204,
        0.051621630787849426,
        -0.063798688352108,
        -0.06514915078878403,
        -0.002325340174138546,
        -0.05582933500409126,
        -0.05341339856386185,
        -0.0071114301681518555,
        -0.03385172784328461,
        -0.07113280147314072,
        0.05644432082772255,
        0.049876317381858826,
        0.024104619398713112,
        0.06181688234210014,
        0.05124697461724281,
        0.0479620136320591,
        0.0547432005405426,
        0.06579432636499405,
        -0.06853912025690079,
        -0.005109927151352167,
        0.06485309451818466,
        -0.05153423547744751,
        -0.07172228395938873,
        0.00461149588227272,
        -0.03156166896224022,
        0.0039044294971972704,
        -0.039881158620119095,
        0.06218045949935913,
        0.037063661962747574,
        -0.004815865773707628,
        -0.0027494889218360186,
        0.06777413189411163,
        0.04741281643509865,
        0.0586831271648407,
        -0.05862352252006531,
        0.01457354798913002,
        0.0007986282580532134,
        -0.06145482510328293,
        -0.044167667627334595,
        0.03995499759912491,
        0.03820477053523064,
        0.07026785612106323,
        0.03480272367596626,
        0.04648132249712944,
        -0.023910097777843475,
        -0.015647685155272484,
        0.07159563899040222,
        -0.0746455192565918,
        0.00993223488330841,
        0.04788888245820999,
        0.02621867135167122,
        0.04507151246070862,
        0.06796551495790482,
        0.05583759397268295,
        -0.02038547210395336,
        -0.0038264659233391285,
        0.031040608882904053,
        -0.03315011411905289,
        0.06791488826274872,
        -0.029146861284971237,
        -0.02135307714343071,
        0.041701462119817734,
        0.011769765056669712,
        -0.04032484069466591,
        -0.03144272044301033,
        -0.0017637814162299037,
        0.006072846706956625,
        0.00782047025859356,
        -0.0017664741026237607,
        -0.03918389603495598,
        0.008224708028137684,
        -0.005769441369920969,
        0.020527230575680733,
        -0.038987889885902405,
        -0.015596290118992329,
        -0.041721757501363754,
        0.040562842041254044,
        0.06451153755187988,
        -0.008740637451410294,
        -0.0436662882566452,
        0.0682906061410904,
        -0.06269780546426773,
        0.05698738619685173,
        0.0047278376296162605,
        -0.0019237087108194828,
        0.031166208907961845,
        -0.0049312966875731945,
        -0.01825941912829876,
        -0.017020119354128838,
        0.061371009796857834,
        -0.023383235558867455,
        0.036851666867733,
        0.04235561192035675,
        -0.024671638384461403,
        -0.07014887034893036,
        0.06356673687696457,
        -0.04466886445879936,
        -0.021765999495983124,
        0.06023750826716423,
        -0.041180793195962906,
        -0.048079729080200195,
        -0.023236652836203575,
        -0.02623971551656723,
        -0.058045461773872375,
        0.04807788133621216,
        -0.03677164763212204,
        0.04617498070001602,
        0.06549496203660965,
        -0.035435717552900314,
        -0.05791594088077545,
        0.06448692828416824,
        0.034924209117889404,
        0.06165347248315811,
        -0.04980742558836937,
        0.02654176950454712,
        0.0014111371710896492,
        0.020889930427074432,
        -0.057586632668972015,
        0.004724045284092426,
        -0.03996306657791138,
        -0.04472234100103378,
        0.0009104429627768695,
        -0.07294715195894241,
        -0.01933559775352478,
        -0.06891059875488281,
        -0.05128690227866173,
        0.060584090650081635,
        -0.04494586959481239,
        -0.044591404497623444,
        0.06429167836904526,
        0.06514173746109009,
        0.05629587545990944,
        -0.02990618906915188,
        0.02417718805372715,
        0.060157958418130875,
        -0.027518978342413902,
        0.03983572497963905,
        -0.047494180500507355,
        0.05331145226955414,
        -0.05627506971359253,
        -0.041231151670217514,
        -0.011747377924621105,
        -0.06663738936185837,
        0.0558268167078495,
        -0.04371209815144539,
        -0.06913858652114868,
        0.06044447049498558,
        -0.0524778850376606,
        0.003685830859467387,
        -0.013077986426651478,
        -0.028566092252731323,
        -0.04585110768675804,
        -0.04808727279305458,
        -0.04918047785758972,
        0.0556584969162941,
        -0.03688230738043785,
        -0.05058395862579346,
        -0.003017223672941327,
        -0.03606477379798889,
        0.020236877724528313,
        0.058455076068639755,
        0.007663903757929802,
        0.017653625458478928,
        0.015386358834803104,
        0.06477629393339157,
        0.04099416360259056,
        -0.06523000448942184,
        0.07407551258802414,
        0.020048346370458603,
        -0.05286633223295212,
        0.048196546733379364,
        0.038549795746803284,
        0.0709051564335823,
        -0.03239341825246811,
        0.008492586202919483,
        -0.06510064750909805,
        -0.013454577885568142,
        -0.03478728607296944,
        0.009435269981622696,
        0.05803834646940231,
        -0.05035115033388138,
        -0.00581522798165679,
        0.054901134222745895,
        -0.019144849851727486,
        0.05789731442928314,
        -0.05941852927207947,
        -0.046956032514572144,
        0.07219831645488739,
        -0.06301993131637573,
        0.02401246689260006,
        -0.03633812069892883,
        -0.06369441002607346,
        -0.001722589717246592,
        0.00011921516852453351,
        -0.051449500024318695,
        0.03313245251774788,
        -0.009057032875716686,
        -0.023103151470422745,
        0.029464561492204666,
        0.02965409867465496,
        -0.016626890748739243,
        -0.027069734409451485,
        -0.019529173150658607,
        -0.04910261183977127,
        -0.05950343981385231,
        0.05927775427699089,
        -0.008926731534302235,
        -0.008602526038885117,
        0.07126893848180771,
        -0.01599840447306633,
        0.060967590659856796,
        0.03000028431415558,
        0.0185677632689476,
        -0.07329768687486649,
        -0.054937995970249176,
        0.075010284781456,
        0.005940021947026253,
        0.03356266766786575,
        0.03673844784498215,
        -0.05922773480415344,
        0.06782615184783936,
        -0.07464092969894409,
        0.01955799013376236,
        -0.03644794970750809,
        -0.03241051733493805,
        -0.05007633566856384,
        0.06950784474611282,
        -0.0663805827498436,
        -0.021341031417250633,
        0.003676677355542779,
        -0.07251422852277756,
        0.06053686514496803,
        -0.007545642089098692,
        -0.05908133462071419,
        0.006209782790392637,
        0.0656084343791008,
        -0.0502922423183918,
        0.06368573009967804,
        -0.0008185263141058385,
        0.0032848708797246218,
        -0.06651996076107025,
        -0.04766362905502319,
        -0.04934987798333168,
        0.06295958906412125,
        0.05403204262256622,
        -0.021304327994585037,
        0.013805491849780083,
        -0.006877850741147995,
        0.0014287583762779832,
        0.019782479852437973,
        0.0315556675195694,
        -0.002334524178877473,
        -0.02554342895746231,
        -0.0719432458281517,
        0.021398894488811493,
        0.07026973366737366,
        0.00014586045290343463,
        -0.05007973313331604,
        -0.06737290322780609,
        -0.057024095207452774,
        -0.05834190174937248,
        -0.024898141622543335,
        0.03309771791100502,
        0.036512602120637894,
        0.028823088854551315,
        -0.049595482647418976,
        0.06363949179649353,
        0.07039140909910202,
        0.0623028539121151,
        0.006555574014782906,
        -0.06847954541444778,
        -0.02882355824112892,
        0.05224224179983139,
        0.046278104186058044,
        0.02869599685072899,
        -0.027228403836488724,
        0.04727114364504814,
        -0.008539289236068726,
        -0.015879197046160698,
        0.06374621391296387,
        -0.04423489794135094,
        0.06324887275695801,
        -0.05607331544160843,
        0.03823831304907799,
        -0.06047530099749565,
        0.05227541923522949,
        -0.033646244555711746,
        0.011990777216851711,
        -0.008807562291622162,
        0.02969745546579361,
        -0.039910949766635895,
        -0.04776936396956444,
        -0.056518375873565674,
        0.030474836006760597,
        0.023078765720129013,
        0.018364453688263893,
        0.04001353308558464,
        -0.014321621507406235,
        -0.055235423147678375,
        -0.054293468594551086,
        -0.022279314696788788,
        0.022752802819013596,
        0.02503499761223793,
        -0.06578109413385391,
        -0.0034474795684218407,
        0.04980992153286934,
        -0.009357278235256672,
        -0.06933888047933578,
        0.02353203296661377,
        -0.04766114056110382,
        -0.014611823484301567,
        0.0631401538848877,
        -0.012955346144735813,
        -0.014307450503110886,
        0.04888486489653587,
        0.07240632176399231,
        -0.02418624237179756,
        0.07152631133794785,
        -0.0546288788318634,
        0.06847111880779266,
        -0.02848268859088421,
        -0.033164191991090775,
        0.02062026411294937,
        0.025458309799432755,
        -0.04194185137748718,
        -0.016872795298695564,
        -0.057598285377025604,
        0.017751965671777725,
        0.04520302265882492,
        -0.06248091161251068,
        0.055861927568912506,
        -0.004212451633065939,
        0.02317127026617527,
        -0.07326081395149231,
        0.022935934364795685,
        0.008356655016541481,
        0.07102347910404205,
        -0.061670854687690735,
        0.06029617413878441,
        -0.022769147530198097,
        -0.056604333221912384,
        0.0250104833394289,
        0.0058008343912661076,
        0.05208628997206688,
        -0.0489332377910614,
        -0.01410688180476427,
        -0.05586126446723938,
        0.050437942147254944,
        0.020276907831430435,
        -0.026804210618138313,
        -0.06967351585626602,
        -0.04272160306572914,
        -0.03440532833337784,
        -0.02453955076634884,
        0.005690011195838451,
        0.03709542006254196,
        -0.052436165511608124,
        0.016593631356954575,
        0.002785508520901203,
        -0.06406997889280319,
        0.06333541125059128,
        -0.05297088995575905,
        -0.056756407022476196,
        -0.013913827948272228,
        -0.016045356169342995,
        0.054877039045095444,
        0.014982474036514759,
        0.011679076589643955,
        0.026262756437063217,
        0.04901393502950668,
        0.06023487448692322,
        -0.010631673969328403,
        -0.0574340857565403,
        -0.03313053026795387,
        0.0011560521088540554,
        -0.024820292368531227,
        -0.007943343371152878,
        0.0076092868112027645,
        0.022420724853873253,
        -0.06929660588502884,
        -0.06586777418851852,
        0.036083903163671494,
        0.06481865048408508
    ],
    [
        -0.025719819590449333,
        -0.06571484357118607,
        0.019641133025288582,
        0.05968792736530304,
        0.03232043981552124,
        0.008027984760701656,
        0.03060777299106121,
        -0.011795167811214924,
        0.048655278980731964,
        0.03515946492552757,
        0.04377957060933113,
        0.002902982523664832,
        -0.007978972047567368,
        -0.023122988641262054,
        -0.05917661264538765,
        0.06055454909801483,
        -0.06837121397256851,
        0.056099217385053635,
        -0.07188692688941956,
        0.022897280752658844,
        -0.066090427339077,
        0.0022185123525559902,
        -0.009176219813525677,
        -0.030678996816277504,
        0.05518438294529915,
        0.05115784332156181,
        0.0438019335269928,
        -0.012994768097996712,
        0.02584095485508442,
        0.006487276405096054,
        0.0388217531144619,
        -0.011677233502268791,
        -0.03490293025970459,
        -0.06681843101978302,
        0.0029134198557585478,
        0.03343066945672035,
        -0.06310481578111649,
        -0.03688168153166771,
        -0.07158438116312027,
        0.025860298424959183,
        0.04064934700727463,
        -0.022527026012539864,
        0.05260496214032173,
        -0.07227162271738052,
        0.04468690976500511,
        0.06975109130144119,
        0.052463121712207794,
        0.05865444988012314,
        0.030647573992609978,
        0.06036479026079178,
        0.05645281448960304,
        0.06214052066206932,
        0.04873741790652275,
        0.02373320423066616,
        -0.026261303573846817,
        -0.04075845703482628,
        -0.012971758842468262,
        0.01536300964653492,
        0.047294918447732925,
        0.015469454228878021,
        -0.06616324186325073,
        -0.0013908531982451677,
        -0.04178186133503914,
        0.053944021463394165,
        -0.048287536948919296,
        -0.015810009092092514,
        0.06712336838245392,
        -0.004743292927742004,
        -0.009353995323181152,
        -0.0716637596487999,
        -0.06957554072141647,
        -0.07161609828472137,
        0.05154387652873993,
        -0.019184095785021782,
        -0.004678316414356232,
        0.013418009504675865,
        -0.015018433332443237,
        0.0701519250869751,
        -0.013467696495354176,
        -0.04755093529820442,
        0.023806964978575706,
        0.0697152242064476,
        -0.06379640102386475,
        0.04195771738886833,
        0.035621434450149536,
        0.045495323836803436,
        0.057955607771873474,
        0.05400821194052696,
        0.05964525416493416,
        0.07107383012771606,
        0.009918544441461563,
        0.031953245401382446,
        -0.05427916347980499,
        -0.05005423724651337,
        -0.025553319603204727,
        0.02269585244357586,
        -0.038545798510313034,
        -0.0519760362803936,
        -0.060657206922769547,
        -0.016416357830166817,
        -0.07095678150653839,
        0.0023440204095095396,
        0.07147291302680969,
        -0.008493220433592796,
        0.06327906996011734,
        -0.04559853672981262,
        -0.008700154721736908,
        0.0122988885268569,
        -0.043349966406822205,
        0.013602737337350845,
        -0.06590743362903595,
        -0.044877465814352036,
        -0.0383278988301754,
        0.005124763585627079,
        -0.00575336953625083,
        -0.04840956628322601,
        -0.03392447903752327,
        -0.019798263907432556,
        0.017216024920344353,
        0.029293080791831017,
        0.011730226688086987,
        0.015672743320465088,
        0.0031041568145155907,
        0.027698468416929245,
        -0.0680079236626625,
        -0.038759589195251465,
        -0.03430033475160599,
        -0.047187089920043945,
        0.06838864088058472,
        0.04001249745488167,
        0.05337386950850487,
        -0.05929577350616455,
        -0.06201496720314026,
        -0.007960231974720955,
        -0.05760383605957031,
        -0.045919064432382584,
        -0.009563603438436985,
        -0.02106546424329281,
        -0.06840541213750839,
        0.06091685965657234,
        0.04719112068414688,
        0.02835063636302948,
        0.05694339796900749,
        0.04352924972772598,
        0.041799336671829224,
        0.0522603876888752,
        0.06290566921234131,
        -0.06679753959178925,
        -0.006857112515717745,
        0.06489212810993195,
        -0.048172738403081894,
        -0.07010730355978012,
        0.009622927755117416,
        -0.021578267216682434,
        0.019504303112626076,
        -0.04545379430055618,
        0.059437450021505356,
        0.04103685915470123,
        -0.01656193844974041,
        0.014344018884003162,
        0.06937485933303833,
        0.03715754672884941,
        0.05519518256187439,
        -0.06083519011735916,
        0.009352656081318855,
        0.010829633101820946,
        -0.0601334311068058,
        -0.04185609146952629,
        0.03209856525063515,
        0.038652777671813965,
        0.06786824017763138,
        0.035161420702934265,
        0.04292323440313339,
        -0.028929492458701134,
        -0.02163190208375454,
        0.0703698918223381,
        -0.07238198071718216,
        0.004162706900388002,
        0.0381011888384819,
        0.00866128783673048,
        0.047716669738292694,
        0.0695691704750061,
        0.05337665230035782,
        -0.011501036584377289,
        0.00087869813432917,
        0.030819876119494438,
        -0.042351219803094864,
        0.0675414577126503,
        -0.03014143370091915,
        -0.0319935642182827,
        0.0333845280110836,
        0.018616799265146255,
        -0.04769701883196831,
        -0.025068355724215508,
        -0.013398912735283375,
        -0.0035787983797490597,
        -0.004856287036091089,
        0.0001989107404369861,
        -0.0470009483397007,
        0.005522433202713728,
        -0.0023627865593880415,
        0.01616203784942627,
        -0.04733860865235329,
        -0.022648075595498085,
        -0.035855911672115326,
        0.048784881830215454,
        0.06232394278049469,
        -0.003714559832587838,
        -0.047154463827610016,
        0.06853114068508148,
        -0.06060110777616501,
        0.05979473143815994,
        -0.001167172216810286,
        -0.0023583199363201857,
        0.024150721728801727,
        -0.003369275713339448,
        -0.027579914778470993,
        -0.03281386196613312,
        0.06235910579562187,
        -0.02627001516520977,
        0.026147151365876198,
        0.030099254101514816,
        -0.031406138092279434,
        -0.06851120293140411,
        0.06704791635274887,
        -0.05373615771532059,
        -0.01794971339404583,
        0.0525943897664547,
        -0.04141845181584358,
        -0.0493229478597641,
        -0.012511645443737507,
        -0.037608686834573746,
        -0.05922160670161247,
        0.0484347864985466,
        -0.051677409559488297,
        0.03691112995147705,
        0.06560426205396652,
        -0.03588510677218437,
        -0.056083351373672485,
        0.05709054321050644,
        0.038047660142183304,
        0.06049719080328941,
        -0.053410839289426804,
        0.02313266135752201,
        0.009396180510520935,
        0.030124304816126823,
        -0.045603975653648376,
        0.017538495361804962,
        -0.03454525023698807,
        -0.041027192026376724,
        0.0022611457388848066,
        -0.07113521546125412,
        -0.02580033615231514,
        -0.06736773997545242,
        -0.05098015442490578,
        0.05765589699149132,
        -0.04483852908015251,
        -0.04376190900802612,
        0.061574459075927734,
        0.06491890549659729,
        0.056144535541534424,
        -0.027130544185638428,
        0.036262866109609604,
        0.06510696560144424,
        -0.030123332515358925,
        0.03937610983848572,
        -0.038444481790065765,
        0.05606057122349739,
        -0.0526033416390419,
        -0.0423220731317997,
        -0.0216367244720459,
        -0.06810887902975082,
        0.06031649932265282,
        -0.04480686038732529,
        -0.06813199818134308,
        0.050986502319574356,
        -0.05117485299706459,
        -0.002488823374733329,
        -0.008231423795223236,
        -0.030073655769228935,
        -0.054336633533239365,
        -0.04157910868525505,
        -0.05307629704475403,
        0.05323716625571251,
        -0.0317939855158329,
        -0.05278078094124794,
        0.0019914661534130573,
        -0.04263989254832268,
        0.023882737383246422,
        0.054234329611063004,
        -0.0024096688721328974,
        0.01369935367256403,
        0.014686539769172668,
        0.0621086061000824,
        0.045577410608530045,
        -0.06405847519636154,
        0.07173692435026169,
        0.034788429737091064,
        -0.05137329548597336,
        0.04921995475888252,
        0.0426042415201664,
        0.06882917881011963,
        -0.02729737013578415,
        0.009221079759299755,
        -0.06339618563652039,
        -0.028121253475546837,
        -0.02978253923356533,
        0.0066169253550469875,
        0.059975765645504,
        -0.041381385177373886,
        -0.006601584609597921,
        0.048622407019138336,
        -0.029529878869652748,
        0.061960652470588684,
        -0.0584656223654747,
        -0.0492553636431694,
        0.07046642899513245,
        -0.062402114272117615,
        0.021474875509738922,
        -0.038642480969429016,
        -0.05867251753807068,
        -0.009946146048605442,
        0.0032420505303889513,
        -0.04770660772919655,
        0.024781381711363792,
        -0.008521289564669132,
        -0.03063580021262169,
        0.042849570512771606,
        0.029782461002469063,
        -0.021078607067465782,
        -0.02043919265270233,
        -0.014115013182163239,
        -0.048940710723400116,
        -0.05799742788076401,
        0.057505492120981216,
        0.0011731351260095835,
        -0.021634813398122787,
        0.06823400408029556,
        -0.015611870214343071,
        0.06015874445438385,
        0.0301976315677166,
        0.007419189903885126,
        -0.07119369506835938,
        -0.060055289417505264,
        0.07261494547128677,
        -0.0005527380271814764,
        0.03641588240861893,
        0.036567844450473785,
        -0.058264102786779404,
        0.06542826443910599,
        -0.07235574722290039,
        0.01739327795803547,
        -0.044092003256082535,
        -0.05542634427547455,
        -0.04937649890780449,
        0.06509678810834885,
        -0.0647953525185585,
        -0.02872551418840885,
        -0.00011230340169277042,
        -0.07114003598690033,
        0.0571027472615242,
        0.005511927418410778,
        -0.05387360230088234,
        0.012486769817769527,
        0.06315363943576813,
        -0.05069924518465996,
        0.06895474344491959,
        -0.0008957708487287164,
        0.020330151543021202,
        -0.06482978910207748,
        -0.047183841466903687,
        -0.05330363288521767,
        0.06270474195480347,
        0.05050031840801239,
        -0.0058374665677547455,
        0.015267915092408657,
        -0.014707830734550953,
        0.011617771349847317,
        0.024631178006529808,
        0.03708343580365181,
        -0.01686123013496399,
        -0.022738905623555183,
        -0.06977198272943497,
        -0.0003883209719788283,
        0.06694754958152771,
        -0.005577255971729755,
        -0.04775641858577728,
        -0.06387122720479965,
        -0.052317459136247635,
        -0.05451485887169838,
        -0.039730023592710495,
        0.03232390806078911,
        0.04016982018947601,
        0.033201225101947784,
        -0.042009804397821426,
        0.06367025524377823,
        0.06858424842357635,
        0.053951188921928406,
        0.017689315602183342,
        -0.06864390522241592,
        -0.029413212090730667,
        0.05521843954920769,
        0.05115940421819687,
        0.028779076412320137,
        -0.03445605933666229,
        0.04427821561694145,
        -0.007588615640997887,
        -0.02285025082528591,
        0.0589543916285038,
        -0.04926785081624985,
        0.06453703343868256,
        -0.05148998275399208,
        0.04784270003437996,
        -0.05317378789186478,
        0.05015246570110321,
        -0.04101645201444626,
        0.01357319951057434,
        -0.009627265855669975,
        0.02398979291319847,
        -0.044891733676195145,
        -0.038457728922367096,
        -0.049411945044994354,
        0.042730897665023804,
        0.01779373548924923,
        0.03887014091014862,
        0.03053833171725273,
        -0.01948574371635914,
        -0.04414047300815582,
        -0.0575440414249897,
        -0.022378958761692047,
        0.03241204097867012,
        0.02551654726266861,
        -0.06706362962722778,
        -0.008963785134255886,
        0.03812599182128906,
        -0.019880235195159912,
        -0.06452542543411255,
        0.01576787233352661,
        -0.04023369401693344,
        -0.004415157251060009,
        0.06019981577992439,
        -0.012113808654248714,
        -0.010857798159122467,
        0.05456208437681198,
        0.07087896019220352,
        -0.026623111218214035,
        0.06925357133150101,
        -0.05618175119161606,
        0.06705407798290253,
        -0.022517940029501915,
        -0.039970334619283676,
        0.03271610662341118,
        0.03941042721271515,
        -0.0426616445183754,
        -0.02360859140753746,
        -0.051451802253723145,
        0.019389048218727112,
        0.04713933542370796,
        -0.06288067996501923,
        0.06476867198944092,
        -0.015753397718071938,
        0.026045680046081543,
        -0.0714411586523056,
        0.029868153855204582,
        0.03239021077752113,
        0.06969095021486282,
        -0.060962360352277756,
        0.061961956322193146,
        -0.024624429643154144,
        -0.05954315885901451,
        0.024548713117837906,
        0.017562799155712128,
        0.05390222370624542,
        -0.0444498211145401,
        -0.03329663723707199,
        -0.04532604664564133,
        0.0436171256005764,
        0.02776283398270607,
        -0.033922187983989716,
        -0.06853294372558594,
        -0.05220292881131172,
        -0.02861005812883377,
        -0.02660902589559555,
        0.006510588340461254,
        0.03582773730158806,
        -0.05036010220646858,
        0.014651434496045113,
        -0.008741609752178192,
        -0.06052137166261673,
        0.05992741510272026,
        -0.049998391419649124,
        -0.058840569108724594,
        -0.009484573267400265,
        -0.019372500479221344,
        0.05842803418636322,
        0.013275695964694023,
        0.004077655728906393,
        0.04427903890609741,
        0.04789484292268753,
        0.061817146837711334,
        -0.0033233638387173414,
        -0.05619516596198082,
        -0.03158951550722122,
        0.002933730836957693,
        -0.01221546158194542,
        -0.008256827481091022,
        0.020493166521191597,
        0.011833167634904385,
        -0.06703365594148636,
        -0.0655389055609703,
        0.04022817686200142,
        0.06144356727600098
    ],
    [
        -0.016883457079529762,
        -0.05851965770125389,
        0.02742793783545494,
        0.059803858399391174,
        0.016506696119904518,
        0.01698700152337551,
        0.025057906284928322,
        -0.0180530808866024,
        0.04569698125123978,
        0.03413508087396622,
        0.03770719841122627,
        0.007699831388890743,
        -0.008582524955272675,
        -0.016001928597688675,
        -0.059076301753520966,
        0.061065368354320526,
        -0.06732507795095444,
        0.05102580785751343,
        -0.07407407462596893,
        0.03664862737059593,
        -0.06825552880764008,
        0.0024299374781548977,
        -0.019189883023500443,
        -0.02323347143828869,
        0.05634021386504173,
        0.05379350110888481,
        0.03808383643627167,
        -0.0031467280350625515,
        0.027048999443650246,
        -0.022751346230506897,
        0.04678798094391823,
        -0.014348933473229408,
        -0.027027785778045654,
        -0.061413250863552094,
        0.0010832177940756083,
        0.03363839536905289,
        -0.06144597381353378,
        -0.03217994421720505,
        -0.07420293241739273,
        0.024467911571264267,
        0.03254084661602974,
        -0.01643526926636696,
        0.058403678238391876,
        -0.07494708895683289,
        0.03642755374312401,
        0.07045561075210571,
        0.04790538549423218,
        0.055832602083683014,
        0.014539413154125214,
        0.05934534966945648,
        0.05168857052922249,
        0.060096874833106995,
        0.05175824090838432,
        0.02205665409564972,
        -0.02083291858434677,
        -0.03296119347214699,
        0.00037734321085736156,
        0.017705818638205528,
        0.03367643058300018,
        0.01244301162660122,
        -0.0697900578379631,
        -0.008624632842838764,
        -0.04023142158985138,
        0.05278944596648216,
        -0.05516611784696579,
        -0.012322846800088882,
        0.0690329298377037,
        0.000027641885026241653,
        -0.011980323120951653,
        -0.07327926903963089,
        -0.07002408802509308,
        -0.07324671000242233,
        0.054285772144794464,
        -0.01267432514578104,
        -0.014899013563990593,
        0.008355940692126751,
        -0.01365403737872839,
        0.0737840086221695,
        -0.015701774507761,
        -0.03380099684000015,
        0.01553113479167223,
        0.07152356952428818,
        -0.06437918543815613,
        0.02966061607003212,
        0.021862493827939034,
        0.04185488820075989,
        0.05885995924472809,
        0.046764619648456573,
        0.058121126145124435,
        0.07297476381063461,
        0.04037002474069595,
        0.039884936064481735,
        -0.044193025678396225,
        -0.041934527456760406,
        -0.037448011338710785,
        0.020804772153496742,
        -0.04492241144180298,
        -0.051289450377225876,
        -0.057474732398986816,
        -0.01568528451025486,
        -0.07402734458446503,
        -0.015063350088894367,
        0.0735403522849083,
        -0.006137899588793516,
        0.06183944270014763,
        -0.040842361748218536,
        -0.025811754167079926,
        0.011834547854959965,
        -0.039332423359155655,
        0.0023546312004327774,
        -0.06753396987915039,
        -0.042851608246564865,
        -0.04432394728064537,
        0.0028202298562973738,
        0.011109480634331703,
        -0.041396789252758026,
        -0.042059771716594696,
        -0.016275830566883087,
        0.01720862090587616,
        0.019368601962924004,
        -0.0031893528066575527,
        0.0018986567156389356,
        0.00228028348647058,
        0.017917031422257423,
        -0.07073650509119034,
        -0.042410027235746384,
        -0.039434127509593964,
        -0.03897819668054581,
        0.07077662646770477,
        0.03996412083506584,
        0.05105292797088623,
        -0.06318232417106628,
        -0.06377232074737549,
        -0.012498615309596062,
        -0.05305247753858566,
        -0.05273955315351486,
        -0.006754502188414335,
        -0.03286692127585411,
        -0.07105116546154022,
        0.05791889503598213,
        0.04858507588505745,
        0.022413745522499084,
        0.06148553639650345,
        0.0482918955385685,
        0.044586293399333954,
        0.05373108759522438,
        0.06243341788649559,
        -0.0689532682299614,
        -0.005135839339345694,
        0.06427839398384094,
        -0.051456138491630554,
        -0.07158956676721573,
        0.013412917032837868,
        -0.03597458824515343,
        0.008978543803095818,
        -0.040221258997917175,
        0.061704121530056,
        0.038716673851013184,
        -0.007197651080787182,
        0.0012343042762950063,
        0.06872367858886719,
        0.04855404421687126,
        0.05853430926799774,
        -0.060037437826395035,
        0.012191839516162872,
        0.004168336279690266,
        -0.06345420330762863,
        -0.04334672540426254,
        0.04215526580810547,
        0.03892232105135918,
        0.07095704227685928,
        0.04094872996211052,
        0.04671645909547806,
        -0.02243870496749878,
        -0.013025966472923756,
        0.07190921902656555,
        -0.07473164796829224,
        0.004675760865211487,
        0.051549188792705536,
        0.01790737546980381,
        0.04516810178756714,
        0.06788308173418045,
        0.051246609538793564,
        -0.02036859653890133,
        -0.009082869626581669,
        0.03145981207489967,
        -0.035801034420728683,
        0.06775495409965515,
        -0.031565021723508835,
        -0.019885364919900894,
        0.043108340352773666,
        0.00954239908605814,
        -0.04055779427289963,
        -0.037521183490753174,
        -0.008056361228227615,
        0.0075015714392066,
        0.006975607480853796,
        -0.004053550772368908,
        -0.03660298511385918,
        0.012866802513599396,
        0.0012744733830913901,
        0.013103298842906952,
        -0.03530540689826012,
        -0.019429026171565056,
        -0.040818069130182266,
        0.036268316209316254,
        0.06518235057592392,
        -0.0061373403295874596,
        -0.04334753379225731,
        0.06720684468746185,
        -0.06278710067272186,
        0.05852017179131508,
        -0.0007898729527369142,
        0.005429334472864866,
        0.03232767432928085,
        0.0020120497792959213,
        -0.026068566367030144,
        -0.014047105796635151,
        0.057728204876184464,
        -0.02236344665288925,
        0.03285680338740349,
        0.04250393062829971,
        -0.033376798033714294,
        -0.07047533243894577,
        0.06173428148031235,
        -0.04190012067556381,
        -0.02134225144982338,
        0.059829190373420715,
        -0.033177439123392105,
        -0.04631028324365616,
        -0.024977929890155792,
        -0.023071149364113808,
        -0.058293480426073074,
        0.04594238102436066,
        -0.032192252576351166,
        0.048051826655864716,
        0.0655774176120758,
        -0.03866608813405037,
        -0.05660257115960121,
        0.06667234748601913,
        0.039080001413822174,
        0.05742880329489708,
        -0.04477263614535332,
        0.021925775334239006,
        -0.000797575106844306,
        0.027072174474596977,
        -0.05958429351449013,
        0.008701447397470474,
        -0.040872979909181595,
        -0.04872506111860275,
        0.008625772781670094,
        -0.07331941276788712,
        -0.02339542843401432,
        -0.06923443078994751,
        -0.05017777904868126,
        0.06233561784029007,
        -0.04723037779331207,
        -0.045170657336711884,
        0.06451746076345444,
        0.06601037830114365,
        0.05663139373064041,
        -0.03184903785586357,
        0.02045244351029396,
        0.06107346713542938,
        -0.0335652157664299,
        0.03457403928041458,
        -0.043312251567840576,
        0.05054574832320213,
        -0.055883947759866714,
        -0.039864037185907364,
        -0.011206092312932014,
        -0.06527891755104065,
        0.05649533122777939,
        -0.04413735866546631,
        -0.06839950382709503,
        0.06157983839511871,
        -0.052900731563568115,
        0.0012138680322095752,
        -0.0010336689883843064,
        -0.027356434613466263,
        -0.05112699046730995,
        -0.049819719046354294,
        -0.04802248626947403,
        0.05600891262292862,
        -0.0370386578142643,
        -0.04926031455397606,
        -0.0017372997244819999,
        -0.03831017389893532,
        0.019119039177894592,
        0.057172082364559174,
        0.007349363528192043,
        0.019458197057247162,
        0.009274047799408436,
        0.06385565549135208,
        0.037258755415678024,
        -0.06435325741767883,
        0.07424519956111908,
        0.02891092374920845,
        -0.04879932105541229,
        0.04882090166211128,
        0.0442633293569088,
        0.07040157914161682,
        -0.02921685017645359,
        0.0078432597219944,
        -0.06399490684270859,
        -0.011346423998475075,
        -0.03611849993467331,
        -0.0012388455215841532,
        0.059353284537792206,
        -0.04784970358014107,
        -0.012403435073792934,
        0.05301951617002487,
        -0.02276228740811348,
        0.05541330575942993,
        -0.0592852458357811,
        -0.048478320240974426,
        0.07258139550685883,
        -0.058921024203300476,
        0.02318020537495613,
        -0.03597347438335419,
        -0.06271933019161224,
        -0.007750590797513723,
        -0.007347169332206249,
        -0.05241994187235832,
        0.03586404770612717,
        -0.004548931494355202,
        -0.027513861656188965,
        0.033138707280159,
        0.02300461195409298,
        -0.014616865664720535,
        -0.029045389965176582,
        -0.01894320920109749,
        -0.048557478934526443,
        -0.06104307249188423,
        0.05930214747786522,
        -0.015576943755149841,
        -0.014903857372701168,
        0.07098466157913208,
        -0.017955556511878967,
        0.06235141307115555,
        0.02796255610883236,
        0.017535710707306862,
        -0.07349191606044769,
        -0.05392849072813988,
        0.07527173310518265,
        0.0021101494785398245,
        0.030913662165403366,
        0.03757215291261673,
        -0.06100580841302872,
        0.06805213540792465,
        -0.07488755136728287,
        0.02199428528547287,
        -0.04301607236266136,
        -0.03592211380600929,
        -0.04776459559798241,
        0.06963558495044708,
        -0.06513432413339615,
        -0.019713575020432472,
        0.00469717662781477,
        -0.07299063354730606,
        0.059840280562639236,
        -0.004370090086013079,
        -0.06009507179260254,
        0.013458716683089733,
        0.06697114557027817,
        -0.049262821674346924,
        0.06395071744918823,
        0.006194406654685736,
        0.006409458350390196,
        -0.06827283650636673,
        -0.049657151103019714,
        -0.05119515210390091,
        0.06315918266773224,
        0.05552579462528229,
        -0.023063769564032555,
        0.021957650780677795,
        -0.006074355915188789,
        0.0056661381386220455,
        0.010747379623353481,
        0.031494125723838806,
        -0.0035737669095396996,
        -0.019453220069408417,
        -0.07236448675394058,
        0.017362289130687714,
        0.06909895688295364,
        0.005299369338899851,
        -0.05387076735496521,
        -0.06779412925243378,
        -0.05936979129910469,
        -0.057934630662202835,
        -0.021814007312059402,
        0.03255729004740715,
        0.027675610035657883,
        0.02929212711751461,
        -0.05468439310789108,
        0.06375054270029068,
        0.06928744167089462,
        0.06444396078586578,
        0.01799813285470009,
        -0.06813163310289383,
        -0.02849263697862625,
        0.05532517656683922,
        0.050378747284412384,
        0.02736377716064453,
        -0.028521694242954254,
        0.046669259667396545,
        -0.019490722566843033,
        -0.0081487987190485,
        0.06469850987195969,
        -0.0485399067401886,
        0.06170590966939926,
        -0.05758090317249298,
        0.040013041347265244,
        -0.06200608238577843,
        0.05446082726120949,
        -0.031138703227043152,
        0.016097253188490868,
        -0.01577257737517357,
        0.0251783337444067,
        -0.03968504071235657,
        -0.04558451473712921,
        -0.05789373442530632,
        0.034128546714782715,
        0.02644749917089939,
        0.019223535433411598,
        0.03955397754907608,
        -0.0194910429418087,
        -0.056607525795698166,
        -0.050368692725896835,
        -0.018572304397821426,
        0.02719375304877758,
        0.017653275281190872,
        -0.06437650322914124,
        0.00030407047597691417,
        0.050005994737148285,
        -0.008746094070374966,
        -0.06706813722848892,
        0.024475879967212677,
        -0.041024744510650635,
        -0.02008880488574505,
        0.06488314270973206,
        -0.02082437090575695,
        -0.009775607846677303,
        0.04716465622186661,
        0.07310419529676437,
        -0.0224898774176836,
        0.07080970704555511,
        -0.0532824881374836,
        0.06816475093364716,
        -0.026174195110797882,
        -0.02807161770761013,
        0.0213250070810318,
        0.020206281915307045,
        -0.04087649658322334,
        -0.013208693824708462,
        -0.05533488467335701,
        0.01333884708583355,
        0.05097654089331627,
        -0.0622103326022625,
        0.05430815741419792,
        -0.003242490580305457,
        0.01840154267847538,
        -0.07310285419225693,
        0.027807457372546196,
        0.008130107074975967,
        0.07148735225200653,
        -0.06165376678109169,
        0.06146375462412834,
        -0.023827549070119858,
        -0.059609562158584595,
        0.019942838698625565,
        -0.005077430512756109,
        0.053414613008499146,
        -0.04700468108057976,
        -0.016682283952832222,
        -0.05583630129694939,
        0.04504658654332161,
        0.02465786039829254,
        -0.022933322936296463,
        -0.07064942270517349,
        -0.04527146369218826,
        -0.0345643125474453,
        -0.022228792309761047,
        0.007321873214095831,
        0.043060190975666046,
        -0.05059297755360603,
        0.021027933806180954,
        0.002085499931126833,
        -0.06314538419246674,
        0.06289562582969666,
        -0.05475478619337082,
        -0.05816555395722389,
        -0.008688434027135372,
        -0.010418110527098179,
        0.050667062401771545,
        0.011314154602587223,
        0.01071928534656763,
        0.02463192492723465,
        0.049382053315639496,
        0.06027588993310928,
        -0.013525757007300854,
        -0.05758850648999214,
        -0.028886429965496063,
        -0.0008749377448111773,
        -0.021395539864897728,
        -0.0047615524381399155,
        0.009784744121134281,
        0.016525715589523315,
        -0.06886429339647293,
        -0.06639418005943298,
        0.03814196586608887,
        0.06364766508340836
    ],
    [
        -0.016883457079529762,
        -0.05851965770125389,
        0.02742793783545494,
        0.059803858399391174,
        0.016506696119904518,
        0.01698700152337551,
        0.025057906284928322,
        -0.0180530808866024,
        0.04569698125123978,
        0.03413508087396622,
        0.03770719841122627,
        0.007699831388890743,
        -0.008582524955272675,
        -0.016001928597688675,
        -0.059076301753520966,
        0.061065368354320526,
        -0.06732507795095444,
        0.05102580785751343,
        -0.07407407462596893,
        0.03664862737059593,
        -0.06825552880764008,
        0.0024299374781548977,
        -0.019189883023500443,
        -0.02323347143828869,
        0.05634021386504173,
        0.05379350110888481,
        0.03808383643627167,
        -0.0031467280350625515,
        0.027048999443650246,
        -0.022751346230506897,
        0.04678798094391823,
        -0.014348933473229408,
        -0.027027785778045654,
        -0.061413250863552094,
        0.0010832177940756083,
        0.03363839536905289,
        -0.06144597381353378,
        -0.03217994421720505,
        -0.07420293241739273,
        0.024467911571264267,
        0.03254084661602974,
        -0.01643526926636696,
        0.058403678238391876,
        -0.07494708895683289,
        0.03642755374312401,
        0.07045561075210571,
        0.04790538549423218,
        0.055832602083683014,
        0.014539413154125214,
        0.05934534966945648,
        0.05168857052922249,
        0.060096874833106995,
        0.05175824090838432,
        0.02205665409564972,
        -0.02083291858434677,
        -0.03296119347214699,
        0.00037734321085736156,
        0.017705818638205528,
        0.03367643058300018,
        0.01244301162660122,
        -0.0697900578379631,
        -0.008624632842838764,
        -0.04023142158985138,
        0.05278944596648216,
        -0.05516611784696579,
        -0.012322846800088882,
        0.0690329298377037,
        0.000027641885026241653,
        -0.011980323120951653,
        -0.07327926903963089,
        -0.07002408802509308,
        -0.07324671000242233,
        0.054285772144794464,
        -0.01267432514578104,
        -0.014899013563990593,
        0.008355940692126751,
        -0.01365403737872839,
        0.0737840086221695,
        -0.015701774507761,
        -0.03380099684000015,
        0.01553113479167223,
        0.07152356952428818,
        -0.06437918543815613,
        0.02966061607003212,
        0.021862493827939034,
        0.04185488820075989,
        0.05885995924472809,
        0.046764619648456573,
        0.058121126145124435,
        0.07297476381063461,
        0.04037002474069595,
        0.039884936064481735,
        -0.044193025678396225,
        -0.041934527456760406,
        -0.037448011338710785,
        0.020804772153496742,
        -0.04492241144180298,
        -0.051289450377225876,
        -0.057474732398986816,
        -0.01568528451025486,
        -0.07402734458446503,
        -0.015063350088894367,
        0.0735403522849083,
        -0.006137899588793516,
        0.06183944270014763,
        -0.040842361748218536,
        -0.025811754167079926,
        0.011834547854959965,
        -0.039332423359155655,
        0.0023546312004327774,
        -0.06753396987915039,
        -0.042851608246564865,
        -0.04432394728064537,
        0.0028202298562973738,
        0.011109480634331703,
        -0.041396789252758026,
        -0.042059771716594696,
        -0.016275830566883087,
        0.01720862090587616,
        0.019368601962924004,
        -0.0031893528066575527,
        0.0018986567156389356,
        0.00228028348647058,
        0.017917031422257423,
        -0.07073650509119034,
        -0.042410027235746384,
        -0.039434127509593964,
        -0.03897819668054581,
        0.07077662646770477,
        0.03996412083506584,
        0.05105292797088623,
        -0.06318232417106628,
        -0.06377232074737549,
        -0.012498615309596062,
        -0.05305247753858566,
        -0.05273955315351486,
        -0.006754502188414335,
        -0.03286692127585411,
        -0.07105116546154022,
        0.05791889503598213,
        0.04858507588505745,
        0.022413745522499084,
        0.06148553639650345,
        0.0482918955385685,
        0.044586293399333954,
        0.05373108759522438,
        0.06243341788649559,
        -0.0689532682299614,
        -0.005135839339345694,
        0.06427839398384094,
        -0.051456138491630554,
        -0.07158956676721573,
        0.013412917032837868,
        -0.03597458824515343,
        0.008978543803095818,
        -0.040221258997917175,
        0.061704121530056,
        0.038716673851013184,
        -0.007197651080787182,
        0.0012343042762950063,
        0.06872367858886719,
        0.04855404421687126,
        0.05853430926799774,
        -0.060037437826395035,
        0.012191839516162872,
        0.004168336279690266,
        -0.06345420330762863,
        -0.04334672540426254,
        0.04215526580810547,
        0.03892232105135918,
        0.07095704227685928,
        0.04094872996211052,
        0.04671645909547806,
        -0.02243870496749878,
        -0.013025966472923756,
        0.07190921902656555,
        -0.07473164796829224,
        0.004675760865211487,
        0.051549188792705536,
        0.01790737546980381,
        0.04516810178756714,
        0.06788308173418045,
        0.051246609538793564,
        -0.02036859653890133,
        -0.009082869626581669,
        0.03145981207489967,
        -0.035801034420728683,
        0.06775495409965515,
        -0.031565021723508835,
        -0.019885364919900894,
        0.043108340352773666,
        0.00954239908605814,
        -0.04055779427289963,
        -0.037521183490753174,
        -0.008056361228227615,
        0.0075015714392066,
        0.006975607480853796,
        -0.004053550772368908,
        -0.03660298511385918,
        0.012866802513599396,
        0.0012744733830913901,
        0.013103298842906952,
        -0.03530540689826012,
        -0.019429026171565056,
        -0.040818069130182266,
        0.036268316209316254,
        0.06518235057592392,
        -0.0061373403295874596,
        -0.04334753379225731,
        0.06720684468746185,
        -0.06278710067272186,
        0.05852017179131508,
        -0.0007898729527369142,
        0.005429334472864866,
        0.03232767432928085,
        0.0020120497792959213,
        -0.026068566367030144,
        -0.014047105796635151,
        0.057728204876184464,
        -0.02236344665288925,
        0.03285680338740349,
        0.04250393062829971,
        -0.033376798033714294,
        -0.07047533243894577,
        0.06173428148031235,
        -0.04190012067556381,
        -0.02134225144982338,
        0.059829190373420715,
        -0.033177439123392105,
        -0.04631028324365616,
        -0.024977929890155792,
        -0.023071149364113808,
        -0.058293480426073074,
        0.04594238102436066,
        -0.032192252576351166,
        0.048051826655864716,
        0.0655774176120758,
        -0.03866608813405037,
        -0.05660257115960121,
        0.06667234748601913,
        0.039080001413822174,
        0.05742880329489708,
        -0.04477263614535332,
        0.021925775334239006,
        -0.000797575106844306,
        0.027072174474596977,
        -0.05958429351449013,
        0.008701447397470474,
        -0.040872979909181595,
        -0.04872506111860275,
        0.008625772781670094,
        -0.07331941276788712,
        -0.02339542843401432,
        -0.06923443078994751,
        -0.05017777904868126,
        0.06233561784029007,
        -0.04723037779331207,
        -0.045170657336711884,
        0.06451746076345444,
        0.06601037830114365,
        0.05663139373064041,
        -0.03184903785586357,
        0.02045244351029396,
        0.06107346713542938,
        -0.0335652157664299,
        0.03457403928041458,
        -0.043312251567840576,
        0.05054574832320213,
        -0.055883947759866714,
        -0.039864037185907364,
        -0.011206092312932014,
        -0.06527891755104065,
        0.05649533122777939,
        -0.04413735866546631,
        -0.06839950382709503,
        0.06157983839511871,
        -0.052900731563568115,
        0.0012138680322095752,
        -0.0010336689883843064,
        -0.027356434613466263,
        -0.05112699046730995,
        -0.049819719046354294,
        -0.04802248626947403,
        0.05600891262292862,
        -0.0370386578142643,
        -0.04926031455397606,
        -0.0017372997244819999,
        -0.03831017389893532,
        0.019119039177894592,
        0.057172082364559174,
        0.007349363528192043,
        0.019458197057247162,
        0.009274047799408436,
        0.06385565549135208,
        0.037258755415678024,
        -0.06435325741767883,
        0.07424519956111908,
        0.02891092374920845,
        -0.04879932105541229,
        0.04882090166211128,
        0.0442633293569088,
        0.07040157914161682,
        -0.02921685017645359,
        0.0078432597219944,
        -0.06399490684270859,
        -0.011346423998475075,
        -0.03611849993467331,
        -0.0012388455215841532,
        0.059353284537792206,
        -0.04784970358014107,
        -0.012403435073792934,
        0.05301951617002487,
        -0.02276228740811348,
        0.05541330575942993,
        -0.0592852458357811,
        -0.048478320240974426,
        0.07258139550685883,
        -0.058921024203300476,
        0.02318020537495613,
        -0.03597347438335419,
        -0.06271933019161224,
        -0.007750590797513723,
        -0.007347169332206249,
        -0.05241994187235832,
        0.03586404770612717,
        -0.004548931494355202,
        -0.027513861656188965,
        0.033138707280159,
        0.02300461195409298,
        -0.014616865664720535,
        -0.029045389965176582,
        -0.01894320920109749,
        -0.048557478934526443,
        -0.06104307249188423,
        0.05930214747786522,
        -0.015576943755149841,
        -0.014903857372701168,
        0.07098466157913208,
        -0.017955556511878967,
        0.06235141307115555,
        0.02796255610883236,
        0.017535710707306862,
        -0.07349191606044769,
        -0.05392849072813988,
        0.07527173310518265,
        0.0021101494785398245,
        0.030913662165403366,
        0.03757215291261673,
        -0.06100580841302872,
        0.06805213540792465,
        -0.07488755136728287,
        0.02199428528547287,
        -0.04301607236266136,
        -0.03592211380600929,
        -0.04776459559798241,
        0.06963558495044708,
        -0.06513432413339615,
        -0.019713575020432472,
        0.00469717662781477,
        -0.07299063354730606,
        0.059840280562639236,
        -0.004370090086013079,
        -0.06009507179260254,
        0.013458716683089733,
        0.06697114557027817,
        -0.049262821674346924,
        0.06395071744918823,
        0.006194406654685736,
        0.006409458350390196,
        -0.06827283650636673,
        -0.049657151103019714,
        -0.05119515210390091,
        0.06315918266773224,
        0.05552579462528229,
        -0.023063769564032555,
        0.021957650780677795,
        -0.006074355915188789,
        0.0056661381386220455,
        0.010747379623353481,
        0.031494125723838806,
        -0.0035737669095396996,
        -0.019453220069408417,
        -0.07236448675394058,
        0.017362289130687714,
        0.06909895688295364,
        0.005299369338899851,
        -0.05387076735496521,
        -0.06779412925243378,
        -0.05936979129910469,
        -0.057934630662202835,
        -0.021814007312059402,
        0.03255729004740715,
        0.027675610035657883,
        0.02929212711751461,
        -0.05468439310789108,
        0.06375054270029068,
        0.06928744167089462,
        0.06444396078586578,
        0.01799813285470009,
        -0.06813163310289383,
        -0.02849263697862625,
        0.05532517656683922,
        0.050378747284412384,
        0.02736377716064453,
        -0.028521694242954254,
        0.046669259667396545,
        -0.019490722566843033,
        -0.0081487987190485,
        0.06469850987195969,
        -0.0485399067401886,
        0.06170590966939926,
        -0.05758090317249298,
        0.040013041347265244,
        -0.06200608238577843,
        0.05446082726120949,
        -0.031138703227043152,
        0.016097253188490868,
        -0.01577257737517357,
        0.0251783337444067,
        -0.03968504071235657,
        -0.04558451473712921,
        -0.05789373442530632,
        0.034128546714782715,
        0.02644749917089939,
        0.019223535433411598,
        0.03955397754907608,
        -0.0194910429418087,
        -0.056607525795698166,
        -0.050368692725896835,
        -0.018572304397821426,
        0.02719375304877758,
        0.017653275281190872,
        -0.06437650322914124,
        0.00030407047597691417,
        0.050005994737148285,
        -0.008746094070374966,
        -0.06706813722848892,
        0.024475879967212677,
        -0.041024744510650635,
        -0.02008880488574505,
        0.06488314270973206,
        -0.02082437090575695,
        -0.009775607846677303,
        0.04716465622186661,
        0.07310419529676437,
        -0.0224898774176836,
        0.07080970704555511,
        -0.0532824881374836,
        0.06816475093364716,
        -0.026174195110797882,
        -0.02807161770761013,
        0.0213250070810318,
        0.020206281915307045,
        -0.04087649658322334,
        -0.013208693824708462,
        -0.05533488467335701,
        0.01333884708583355,
        0.05097654089331627,
        -0.0622103326022625,
        0.05430815741419792,
        -0.003242490580305457,
        0.01840154267847538,
        -0.07310285419225693,
        0.027807457372546196,
        0.008130107074975967,
        0.07148735225200653,
        -0.06165376678109169,
        0.06146375462412834,
        -0.023827549070119858,
        -0.059609562158584595,
        0.019942838698625565,
        -0.005077430512756109,
        0.053414613008499146,
        -0.04700468108057976,
        -0.016682283952832222,
        -0.05583630129694939,
        0.04504658654332161,
        0.02465786039829254,
        -0.022933322936296463,
        -0.07064942270517349,
        -0.04527146369218826,
        -0.0345643125474453,
        -0.022228792309761047,
        0.007321873214095831,
        0.043060190975666046,
        -0.05059297755360603,
        0.021027933806180954,
        0.002085499931126833,
        -0.06314538419246674,
        0.06289562582969666,
        -0.05475478619337082,
        -0.05816555395722389,
        -0.008688434027135372,
        -0.010418110527098179,
        0.050667062401771545,
        0.011314154602587223,
        0.01071928534656763,
        0.02463192492723465,
        0.049382053315639496,
        0.06027588993310928,
        -0.013525757007300854,
        -0.05758850648999214,
        -0.028886429965496063,
        -0.0008749377448111773,
        -0.021395539864897728,
        -0.0047615524381399155,
        0.009784744121134281,
        0.016525715589523315,
        -0.06886429339647293,
        -0.06639418005943298,
        0.03814196586608887,
        0.06364766508340836
    ],
    [
        -0.035663072019815445,
        -0.06627266108989716,
        0.01757761463522911,
        0.061546050012111664,
        0.023268086835741997,
        0.0005033828201703727,
        0.03277802839875221,
        -0.009896153584122658,
        0.0491577610373497,
        0.03811110928654671,
        0.04444663226604462,
        -0.0017242362955585122,
        -0.0017265481874346733,
        -0.01582849584519863,
        -0.05779595300555229,
        0.0605047270655632,
        -0.0680764690041542,
        0.0509411096572876,
        -0.07194285094738007,
        0.025368180125951767,
        -0.06668305397033691,
        -0.008406531065702438,
        -0.00532860541716218,
        -0.03014163300395012,
        0.05470852926373482,
        0.05442319065332413,
        0.04542763903737068,
        -0.001283162389881909,
        0.024551667273044586,
        -0.0007918965420685709,
        0.044851552695035934,
        -0.003485033055767417,
        -0.0349653884768486,
        -0.06692288815975189,
        0.005347654689103365,
        0.039350152015686035,
        -0.06282766163349152,
        -0.04044945165514946,
        -0.0721202865242958,
        0.029355255886912346,
        0.04462338611483574,
        -0.014055828563869,
        0.05396675691008568,
        -0.07215053588151932,
        0.04253365471959114,
        0.06878086179494858,
        0.05483785644173622,
        0.05956069380044937,
        0.039165206253528595,
        0.055626749992370605,
        0.05451776832342148,
        0.05963873863220215,
        0.051462624222040176,
        0.024700423702597618,
        -0.0188762154430151,
        -0.03608781099319458,
        -0.01407848484814167,
        0.0157652348279953,
        0.046938322484493256,
        0.017011521384119987,
        -0.06613253057003021,
        -0.006992790382355452,
        -0.038168903440237045,
        0.053094908595085144,
        -0.05447288230061531,
        -0.007516956888139248,
        0.06753179430961609,
        -0.00003360327900736593,
        0.0025965801905840635,
        -0.07157568633556366,
        -0.06923443078994751,
        -0.07136207073926926,
        0.05019404739141464,
        -0.02120506949722767,
        -0.0005980544374324381,
        0.018310967832803726,
        -0.010070024989545345,
        0.07054567337036133,
        -0.007949416525661945,
        -0.04923733323812485,
        0.019641751423478127,
        0.06943046301603317,
        -0.06366320699453354,
        0.03650670498609543,
        0.027572814375162125,
        0.04362615942955017,
        0.05740451067686081,
        0.055096860975027084,
        0.06350428611040115,
        0.07066219300031662,
        0.008964075706899166,
        0.035625264048576355,
        -0.05130051076412201,
        -0.04797274246811867,
        -0.026783764362335205,
        0.015271714888513088,
        -0.04281795397400856,
        -0.05048864334821701,
        -0.0632496029138565,
        -0.018494421616196632,
        -0.07047246396541595,
        -0.0014551973436027765,
        0.07134786993265152,
        0.0007905263919383287,
        0.06243978813290596,
        -0.04792874678969383,
        -0.013569466769695282,
        0.017031140625476837,
        -0.04090569168329239,
        0.019857056438922882,
        -0.06621111929416656,
        -0.04797022044658661,
        -0.03793960064649582,
        0.014108196832239628,
        -0.0018154040444642305,
        -0.046852633357048035,
        -0.03979023918509483,
        -0.014292586594820023,
        -0.004671137779951096,
        0.0307632889598608,
        0.0011972980573773384,
        0.008741413243114948,
        0.000055696444178465754,
        0.03385729342699051,
        -0.06779912114143372,
        -0.028793781995773315,
        -0.03747234120965004,
        -0.05233243852853775,
        0.06781719624996185,
        0.040342673659324646,
        0.05498221144080162,
        -0.05707670375704765,
        -0.06205996870994568,
        -0.008682466112077236,
        -0.054419465363025665,
        -0.044014859944581985,
        -0.009376964531838894,
        -0.02832150273025036,
        -0.0680161789059639,
        0.06038358062505722,
        0.0475301630795002,
        0.021945033222436905,
        0.05692259222269058,
        0.04108614847064018,
        0.041086338460445404,
        0.05324923247098923,
        0.05985158681869507,
        -0.06691694259643555,
        -0.008748499676585197,
        0.0648413673043251,
        -0.05253678560256958,
        -0.0692242830991745,
        0.01884910836815834,
        -0.022229498252272606,
        0.014988256618380547,
        -0.047205641865730286,
        0.0634918063879013,
        0.042907726019620895,
        -0.016562746837735176,
        0.01908751390874386,
        0.06884434819221497,
        0.036780569702386856,
        0.05466175079345703,
        -0.06329703330993652,
        0.002760251285508275,
        0.01288272999227047,
        -0.06174365431070328,
        -0.04421594366431236,
        0.03316390514373779,
        0.039469651877880096,
        0.06790527701377869,
        0.038686178624629974,
        0.041972123086452484,
        -0.025228366255760193,
        -0.025980254635214806,
        0.07071346789598465,
        -0.07232119888067245,
        -0.003761610249057412,
        0.04447717219591141,
        0.008647415786981583,
        0.04699340835213661,
        0.0686580017209053,
        0.049810126423835754,
        -0.01131321582943201,
        -0.010299352928996086,
        0.0332065150141716,
        -0.041562583297491074,
        0.06668931245803833,
        -0.02558859996497631,
        -0.029484428465366364,
        0.03814474120736122,
        0.01825167052447796,
        -0.05005766823887825,
        -0.038299255073070526,
        -0.015737242996692657,
        -0.005319681018590927,
        -0.002574954414740205,
        -0.0007731388323009014,
        -0.04327074810862541,
        0.014607011340558529,
        0.00780350249260664,
        0.00802372582256794,
        -0.0429006926715374,
        -0.016919849440455437,
        -0.026061799377202988,
        0.04362252354621887,
        0.06294664740562439,
        0.000955600175075233,
        -0.047794025391340256,
        0.0650239884853363,
        -0.06138543412089348,
        0.05883045494556427,
        -0.013189860619604588,
        -0.0032681208103895187,
        0.022098982706665993,
        -0.0005169202340766788,
        -0.012255785055458546,
        -0.027441170066595078,
        0.05927726998925209,
        -0.027210868895053864,
        0.023307040333747864,
        0.025624362751841545,
        -0.03762882947921753,
        -0.06945794820785522,
        0.0644017904996872,
        -0.05248934403061867,
        -0.014248153194785118,
        0.055609919130802155,
        -0.03210296109318733,
        -0.04719691723585129,
        -0.011595489457249641,
        -0.035582371056079865,
        -0.05977066233754158,
        0.04495524987578392,
        -0.04767448082566261,
        0.03841042146086693,
        0.06499925255775452,
        -0.03730694204568863,
        -0.05560726299881935,
        0.0597798116505146,
        0.03658992797136307,
        0.05835761874914169,
        -0.045219536870718,
        0.014250700362026691,
        0.005897125694900751,
        0.03479815647006035,
        -0.04734917730093002,
        0.026743967086076736,
        -0.03868085518479347,
        -0.045920006930828094,
        0.014616871252655983,
        -0.07111993432044983,
        -0.02719404175877571,
        -0.06723246723413467,
        -0.05210733786225319,
        0.059612613171339035,
        -0.045605845749378204,
        -0.04792633652687073,
        0.0614798367023468,
        0.06630698591470718,
        0.05413892865180969,
        -0.027109378948807716,
        0.03192337974905968,
        0.06439600884914398,
        -0.039846330881118774,
        0.03420954942703247,
        -0.032943494617938995,
        0.05282287299633026,
        -0.054142966866493225,
        -0.04085448011755943,
        -0.014810352586209774,
        -0.06806379556655884,
        0.05800117924809456,
        -0.04642244800925255,
        -0.06679324060678482,
        0.055354438722133636,
        -0.052671194076538086,
        -0.0035700532607734203,
        0.00972839631140232,
        -0.0290447436273098,
        -0.05680238455533981,
        -0.04232029989361763,
        -0.050375234335660934,
        0.056820761412382126,
        -0.03548789396882057,
        -0.05197066441178322,
        0.0057791066356003284,
        -0.05236339196562767,
        0.025341656059026718,
        0.05128495395183563,
        -0.009873908013105392,
        0.013215344399213791,
        0.011996280401945114,
        0.06204516068100929,
        0.040237490087747574,
        -0.0633501186966896,
        0.07167168706655502,
        0.04256739467382431,
        -0.04924243316054344,
        0.04870202764868736,
        0.047992125153541565,
        0.06795675307512283,
        -0.02150290459394455,
        0.0021494661923497915,
        -0.061529651284217834,
        -0.022316746413707733,
        -0.029830139130353928,
        0.005785270594060421,
        0.06170588359236717,
        -0.0406959131360054,
        -0.016111567616462708,
        0.04736749827861786,
        -0.03223554044961929,
        0.061973828822374344,
        -0.06080439314246178,
        -0.051306452602148056,
        0.07041644304990768,
        -0.05882735922932625,
        0.020309150218963623,
        -0.03915374353528023,
        -0.05919951573014259,
        -0.01736990362405777,
        -0.0018372968770563602,
        -0.05118871107697487,
        0.028418119996786118,
        -0.00006317142106126994,
        -0.027512239292263985,
        0.04599543288350105,
        0.02571803145110607,
        -0.020144866779446602,
        -0.024546179920434952,
        -0.01470382884144783,
        -0.0483645424246788,
        -0.05813196673989296,
        0.05782370641827583,
        -0.005691847763955593,
        -0.028930336236953735,
        0.0671793520450592,
        -0.01624734327197075,
        0.06158329173922539,
        0.027130570262670517,
        0.005931226536631584,
        -0.07082980126142502,
        -0.06098761409521103,
        0.07253596931695938,
        -0.003797395620495081,
        0.031783945858478546,
        0.03767237439751625,
        -0.060437873005867004,
        0.06487676501274109,
        -0.07230601459741592,
        0.022779172286391258,
        -0.049483709037303925,
        -0.0560685470700264,
        -0.04912211745977402,
        0.06590501964092255,
        -0.06506086885929108,
        -0.028370706364512444,
        0.005478398874402046,
        -0.0707012265920639,
        0.05512037128210068,
        0.004550758749246597,
        -0.05362856015563011,
        0.014939198270440102,
        0.06390666961669922,
        -0.04892151057720184,
        0.06862655282020569,
        0.010578731074929237,
        0.021280648186802864,
        -0.06722064316272736,
        -0.05124280974268913,
        -0.0527515672147274,
        0.06316497176885605,
        0.05004920810461044,
        -0.005325211212038994,
        0.02137671411037445,
        -0.02285454049706459,
        0.01142486184835434,
        0.007012335583567619,
        0.03520207852125168,
        -0.009375663474202156,
        -0.008214430883526802,
        -0.06981262564659119,
        -0.0016493527218699455,
        0.06521308422088623,
        -0.0056598796509206295,
        -0.04793797805905342,
        -0.0636465847492218,
        -0.055423103272914886,
        -0.05553463473916054,
        -0.03591618686914444,
        0.031812913715839386,
        0.028546614572405815,
        0.03594603389501572,
        -0.04727897047996521,
        0.06438424438238144,
        0.06759008765220642,
        0.05543740838766098,
        0.031213898211717606,
        -0.06814274191856384,
        -0.02697509527206421,
        0.05864068120718002,
        0.05766099691390991,
        0.028504040092229843,
        -0.03816065564751625,
        0.04147021099925041,
        -0.013987857848405838,
        -0.01473800279200077,
        0.05951534956693649,
        -0.05629056319594383,
        0.06345169991254807,
        -0.04643411189317703,
        0.04402453452348709,
        -0.055382609367370605,
        0.0526697151362896,
        -0.03350042924284935,
        0.01566099375486374,
        -0.014302590861916542,
        0.023897895589470863,
        -0.04791930317878723,
        -0.028999648988246918,
        -0.05115610361099243,
        0.03869139403104782,
        0.021088415756821632,
        0.04554453864693642,
        0.02494501881301403,
        -0.025364989414811134,
        -0.04270108416676521,
        -0.05578461289405823,
        -0.02227960154414177,
        0.03209041804075241,
        0.019380655139684677,
        -0.06667020171880722,
        0.003675412619486451,
        0.041223932057619095,
        -0.01556467730551958,
        -0.06017736345529556,
        0.01611575484275818,
        -0.03143015876412392,
        -0.008786228485405445,
        0.06257131695747375,
        -0.019072093069553375,
        -0.015898719429969788,
        0.050920452922582626,
        0.0710020363330841,
        -0.023976262658834457,
        0.06885384768247604,
        -0.05750690773129463,
        0.06680449098348618,
        -0.02096560224890709,
        -0.04097922891378403,
        0.03676553815603256,
        0.03748948127031326,
        -0.04218641668558121,
        -0.019592246040701866,
        -0.05090444162487984,
        0.01650310307741165,
        0.055624961853027344,
        -0.06559263914823532,
        0.06415142118930817,
        -0.020852264016866684,
        0.021349847316741943,
        -0.07124731689691544,
        0.035923831164836884,
        0.0318339578807354,
        0.06971684843301773,
        -0.06169935688376427,
        0.0621475987136364,
        -0.02410317026078701,
        -0.061755601316690445,
        0.019199417904019356,
        0.013234056532382965,
        0.0548720546066761,
        -0.041478198021650314,
        -0.0310463085770607,
        -0.04208051785826683,
        0.034960146993398666,
        0.029175816103816032,
        -0.028642956167459488,
        -0.06916076689958572,
        -0.053299274295568466,
        -0.0302282702177763,
        -0.021803181618452072,
        0.005067683290690184,
        0.040596675127744675,
        -0.04976610094308853,
        0.014297770336270332,
        -0.005564752500504255,
        -0.05824241414666176,
        0.06135029345750809,
        -0.05325918272137642,
        -0.06116316840052605,
        -0.0020358851179480553,
        -0.011153378523886204,
        0.05548593029379845,
        0.008188867941498756,
        -0.001527511514723301,
        0.04257456213235855,
        0.04767465591430664,
        0.06312423944473267,
        -0.007896446622908115,
        -0.057058218866586685,
        -0.032695282250642776,
        -0.003900366835296154,
        -0.008930864743888378,
        -0.004822033457458019,
        0.019998867064714432,
        0.006954722572118044,
        -0.0655808299779892,
        -0.06490495055913925,
        0.04291510581970215,
        0.06041479483246803
    ],
    [
        -0.020000223070383072,
        -0.06022978201508522,
        0.02464728057384491,
        0.05864410847425461,
        0.01624131016433239,
        0.011930272914469242,
        0.02167011797428131,
        -0.019408419728279114,
        0.04350512847304344,
        0.030031517148017883,
        0.040954191237688065,
        0.004455524031072855,
        -0.005468361545354128,
        -0.022543082013726234,
        -0.05732082948088646,
        0.0636010691523552,
        -0.06699579954147339,
        0.05415498837828636,
        -0.07371573895215988,
        0.03160078823566437,
        -0.06732561439275742,
        0.004481197334825993,
        -0.015537209808826447,
        -0.021950088441371918,
        0.049538131803274155,
        0.05378811061382294,
        0.03841647505760193,
        -0.012010088190436363,
        0.02524033933877945,
        -0.019926343113183975,
        0.043911777436733246,
        -0.019693801179528236,
        -0.0268519576638937,
        -0.06022341549396515,
        0.00012003269512206316,
        0.03680117800831795,
        -0.06156390905380249,
        -0.0288536474108696,
        -0.07405487447977066,
        0.021397195756435394,
        0.035873960703611374,
        -0.020952507853507996,
        0.05795343965291977,
        -0.07437796145677567,
        0.04094051197171211,
        0.07041201740503311,
        0.05321970954537392,
        0.05804820358753204,
        0.005439504981040955,
        0.06016774848103523,
        0.05743469297885895,
        0.06117578595876694,
        0.053734250366687775,
        0.02441699244081974,
        -0.023173393681645393,
        -0.034327369183301926,
        0.005405634641647339,
        0.01795855164527893,
        0.03247793763875961,
        0.007630184758454561,
        -0.0697687417268753,
        -0.01226566918194294,
        -0.04143722727894783,
        0.052712902426719666,
        -0.054497670382261276,
        -0.007125542964786291,
        0.06924881041049957,
        -0.0009029937209561467,
        -0.008439736440777779,
        -0.0727892592549324,
        -0.07021024078130722,
        -0.07199050486087799,
        0.051927387714385986,
        -0.009345470927655697,
        -0.009255710989236832,
        0.010559993796050549,
        -0.016826240345835686,
        0.07305064797401428,
        -0.017587201669812202,
        -0.03328370302915573,
        0.01524606253951788,
        0.07100079208612442,
        -0.06545663625001907,
        0.02815215103328228,
        0.02097358927130699,
        0.04334326088428497,
        0.059090085327625275,
        0.047699544578790665,
        0.062067363411188126,
        0.07244552671909332,
        0.03896044194698334,
        0.038342565298080444,
        -0.049233123660087585,
        -0.043841760605573654,
        -0.04088900610804558,
        0.020613860338926315,
        -0.042791835963726044,
        -0.05316707119345665,
        -0.05751321092247963,
        -0.013650579378008842,
        -0.07361448556184769,
        -0.009820839390158653,
        0.07317690551280975,
        -0.0015806638402864337,
        0.06241706386208534,
        -0.0469316765666008,
        -0.02434244006872177,
        0.014090360142290592,
        -0.03596840798854828,
        0.003603138029575348,
        -0.06620215624570847,
        -0.04375367611646652,
        -0.0438820943236351,
        0.006100520025938749,
        0.013720422983169556,
        -0.03975878655910492,
        -0.04122764989733696,
        -0.01966410130262375,
        0.01916964165866375,
        0.023094462230801582,
        0.002289888449013233,
        -0.0005817620549350977,
        0.009819296188652515,
        0.01297658309340477,
        -0.0711352750658989,
        -0.044975098222494125,
        -0.036510881036520004,
        -0.04172331094741821,
        0.07046782225370407,
        0.04524214565753937,
        0.05387713015079498,
        -0.0644453838467598,
        -0.06357860565185547,
        -0.009456618689000607,
        -0.055132199078798294,
        -0.050614841282367706,
        -0.008738425560295582,
        -0.031200909987092018,
        -0.07102631777524948,
        0.05825510993599892,
        0.04827224090695381,
        0.028223732486367226,
        0.06163257360458374,
        0.05265667662024498,
        0.04345131665468216,
        0.05509008467197418,
        0.06136836111545563,
        -0.06835372000932693,
        -0.006950504146516323,
        0.06319288909435272,
        -0.05306046083569527,
        -0.0712229311466217,
        0.02365472912788391,
        -0.036476049572229385,
        0.009631737135350704,
        -0.03559494763612747,
        0.060144875198602676,
        0.04123277962207794,
        -0.007153631653636694,
        -0.006994113326072693,
        0.06947263330221176,
        0.04403021186590195,
        0.05934138968586922,
        -0.06112488731741905,
        0.01152676623314619,
        0.005817570723593235,
        -0.06423762440681458,
        -0.04468284174799919,
        0.0412629209458828,
        0.03728432208299637,
        0.07119303196668625,
        0.036345645785331726,
        0.048009276390075684,
        -0.02750113233923912,
        -0.008696110919117928,
        0.071695476770401,
        -0.0741867795586586,
        0.006550576072186232,
        0.05036270618438721,
        0.020731797441840172,
        0.04006277769804001,
        0.0692434012889862,
        0.05454089865088463,
        -0.02669905684888363,
        -0.008332776837050915,
        0.03133518993854523,
        -0.029098788276314735,
        0.06786870211362839,
        -0.033702585846185684,
        -0.009649272076785564,
        0.046063750982284546,
        0.012748521752655506,
        -0.03810270503163338,
        -0.037119876593351364,
        -0.014501545578241348,
        0.0009955567074939609,
        -0.001522712642326951,
        -0.002679018070921302,
        -0.03746628388762474,
        0.012684604153037071,
        -0.005120887421071529,
        0.013505831360816956,
        -0.04536368325352669,
        -0.025794757530093193,
        -0.03801458701491356,
        0.04376104101538658,
        0.06428323686122894,
        -0.007351954001933336,
        -0.04031376913189888,
        0.06826368719339371,
        -0.06306439638137817,
        0.05813613161444664,
        0.0017293059499934316,
        0.005456678103655577,
        0.02930503711104393,
        -0.000017535796359879896,
        -0.02818123996257782,
        -0.023124495521187782,
        0.061725471168756485,
        -0.025952262803912163,
        0.03615240007638931,
        0.040549252182245255,
        -0.025387775152921677,
        -0.06990012526512146,
        0.05979965999722481,
        -0.029578400775790215,
        -0.01900830864906311,
        0.0575585812330246,
        -0.03632662072777748,
        -0.047969549894332886,
        -0.031671006232500076,
        -0.012265104800462723,
        -0.06006801873445511,
        0.04893241450190544,
        -0.032688770443201065,
        0.04738108813762665,
        0.06612794101238251,
        -0.03919149190187454,
        -0.057146113365888596,
        0.06465481221675873,
        0.04034062847495079,
        0.060973215848207474,
        -0.0433986634016037,
        0.01844189316034317,
        0.004738170187920332,
        0.020110009238123894,
        -0.06051529198884964,
        0.006506065372377634,
        -0.03773406520485878,
        -0.049170322716236115,
        -0.0008536019595339894,
        -0.07265667617321014,
        -0.024107245728373528,
        -0.06827865540981293,
        -0.05036173015832901,
        0.0612877681851387,
        -0.04688890278339386,
        -0.037354376167058945,
        0.06651395559310913,
        0.06451182812452316,
        0.053783856332302094,
        -0.03371614217758179,
        0.02246973291039467,
        0.06193181499838829,
        -0.03648390993475914,
        0.03703085333108902,
        -0.0387328565120697,
        0.054750557988882065,
        -0.055259790271520615,
        -0.038674648851156235,
        -0.014751125127077103,
        -0.06558854877948761,
        0.05862170085310936,
        -0.04113268107175827,
        -0.06833551824092865,
        0.06052575260400772,
        -0.05435580760240555,
        -0.0015532724792137742,
        0.003840831806883216,
        -0.019400132820010185,
        -0.05253777280449867,
        -0.048759523779153824,
        -0.05004655197262764,
        0.05234474688768387,
        -0.037173185497522354,
        -0.05136841908097267,
        -0.0026660992298275232,
        -0.0348750576376915,
        0.022230522707104683,
        0.05628844350576401,
        0.00904848612844944,
        0.023407991975545883,
        0.004163779318332672,
        0.06324707716703415,
        0.0381016731262207,
        -0.0654684379696846,
        0.07376758754253387,
        0.026399841532111168,
        -0.04792000725865364,
        0.047266505658626556,
        0.043085310608148575,
        0.07001642882823944,
        -0.02870018407702446,
        0.010950950905680656,
        -0.06296396255493164,
        -0.01377184223383665,
        -0.03546487167477608,
        -0.000561004038900137,
        0.05806436017155647,
        -0.0435219407081604,
        -0.012780440971255302,
        0.05117648467421532,
        -0.028246959671378136,
        0.056130170822143555,
        -0.059332676231861115,
        -0.04682149738073349,
        0.07238022983074188,
        -0.06207522749900818,
        0.023016151040792465,
        -0.034227367490530014,
        -0.06201602518558502,
        -0.005159740801900625,
        -0.008175800554454327,
        -0.05520288273692131,
        0.029438374564051628,
        -0.007579565979540348,
        -0.026910385116934776,
        0.0397617444396019,
        0.03047034703195095,
        -0.010908877477049828,
        -0.03072558343410492,
        -0.0217454731464386,
        -0.05271613225340843,
        -0.05965345352888107,
        0.05832977965474129,
        -0.014317167922854424,
        -0.01750892959535122,
        0.0701431930065155,
        -0.0148139214143157,
        0.06267733126878738,
        0.02663838490843773,
        0.014954475685954094,
        -0.07304402440786362,
        -0.053184494376182556,
        0.07468551397323608,
        0.002607854316011071,
        0.030548997223377228,
        0.03779689222574234,
        -0.06053820997476578,
        0.06736794859170914,
        -0.07439998537302017,
        0.02487720362842083,
        -0.0394214428961277,
        -0.037389643490314484,
        -0.048622388392686844,
        0.06879256665706635,
        -0.06581830978393555,
        -0.01815049722790718,
        0.005494101904332638,
        -0.0724029541015625,
        0.05947913974523544,
        0.0017619484569877386,
        -0.06062781438231468,
        0.008686184883117676,
        0.06644202023744583,
        -0.04913778975605965,
        0.06294456869363785,
        0.013531379401683807,
        -0.000556131883058697,
        -0.0675688087940216,
        -0.049285463988780975,
        -0.05202720686793327,
        0.059633947908878326,
        0.0549149364233017,
        -0.024806348606944084,
        0.021373802796006203,
        -0.004458161070942879,
        0.009991522878408432,
        0.019470639526844025,
        0.0340295173227787,
        -0.01003353577107191,
        -0.021581856533885002,
        -0.07180482894182205,
        0.01847759447991848,
        0.06964363157749176,
        0.0036667927633970976,
        -0.052445996552705765,
        -0.06773191690444946,
        -0.05875198170542717,
        -0.053557559847831726,
        -0.019420111551880836,
        0.02906068041920662,
        0.03574581816792488,
        0.02722083404660225,
        -0.05424406751990318,
        0.06416328996419907,
        0.0698966234922409,
        0.06371781975030899,
        0.017560889944434166,
        -0.06817958503961563,
        -0.028727494180202484,
        0.05066389590501785,
        0.04891582205891609,
        0.02698301151394844,
        -0.0281981248408556,
        0.04328883811831474,
        -0.01542674284428358,
        -0.011267902329564095,
        0.065068319439888,
        -0.045815449208021164,
        0.05983441695570946,
        -0.05825693905353546,
        0.0376489982008934,
        -0.06311138719320297,
        0.056160565465688705,
        -0.03340829908847809,
        0.010869881138205528,
        -0.02408238872885704,
        0.025565683841705322,
        -0.045730579644441605,
        -0.037182264029979706,
        -0.05763789638876915,
        0.03493737801909447,
        0.02561134845018387,
        0.015896538272500038,
        0.04107897728681564,
        -0.019729815423488617,
        -0.051971934735774994,
        -0.05266561359167099,
        -0.019801272079348564,
        0.025962652638554573,
        0.022159524261951447,
        -0.06322640925645828,
        0.002159526338800788,
        0.05038338527083397,
        -0.009741348214447498,
        -0.06587911397218704,
        0.025693446397781372,
        -0.04106728360056877,
        -0.027932722121477127,
        0.06333732604980469,
        -0.00984151754528284,
        -0.015965279191732407,
        0.048118893057107925,
        0.07318796217441559,
        -0.024914110079407692,
        0.07072550058364868,
        -0.05248500034213066,
        0.06826130300760269,
        -0.01947946287691593,
        -0.025270331650972366,
        0.020620197057724,
        0.0205440241843462,
        -0.04040539637207985,
        -0.016604498028755188,
        -0.0574832409620285,
        0.019959332421422005,
        0.0454566515982151,
        -0.06103057414293289,
        0.055442098528146744,
        0.007847148925065994,
        0.022130105644464493,
        -0.07309454679489136,
        0.03181632608175278,
        0.012359979562461376,
        0.07119344174861908,
        -0.06037355959415436,
        0.06060164421796799,
        -0.01671859622001648,
        -0.05718090385198593,
        0.02024978958070278,
        -0.013500778004527092,
        0.05627092346549034,
        -0.049258772283792496,
        -0.021180562674999237,
        -0.05844172090291977,
        0.04840122535824776,
        0.01890837401151657,
        -0.025737065821886063,
        -0.07071419805288315,
        -0.04474128410220146,
        -0.03080981969833374,
        -0.02911643497645855,
        0.0035711671225726604,
        0.03976090997457504,
        -0.05119165778160095,
        0.02216249145567417,
        0.002023885026574135,
        -0.061945486813783646,
        0.06390462070703506,
        -0.053305938839912415,
        -0.05654192343354225,
        -0.01461375318467617,
        -0.010128629393875599,
        0.053430505096912384,
        0.012128427624702454,
        0.018147610127925873,
        0.02599116787314415,
        0.047276467084884644,
        0.06182730197906494,
        -0.023370755836367607,
        -0.05521503835916519,
        -0.03700625151395798,
        -0.00015049937064759433,
        -0.006632267963141203,
        -0.010268046520650387,
        0.008690093643963337,
        0.013008342124521732,
        -0.06736285239458084,
        -0.06818132102489471,
        0.026764869689941406,
        0.06223272904753685
    ],
    [
        -0.020000223070383072,
        -0.06022978201508522,
        0.02464728057384491,
        0.05864410847425461,
        0.01624131016433239,
        0.011930272914469242,
        0.02167011797428131,
        -0.019408419728279114,
        0.04350512847304344,
        0.030031517148017883,
        0.040954191237688065,
        0.004455524031072855,
        -0.005468361545354128,
        -0.022543082013726234,
        -0.05732082948088646,
        0.0636010691523552,
        -0.06699579954147339,
        0.05415498837828636,
        -0.07371573895215988,
        0.03160078823566437,
        -0.06732561439275742,
        0.004481197334825993,
        -0.015537209808826447,
        -0.021950088441371918,
        0.049538131803274155,
        0.05378811061382294,
        0.03841647505760193,
        -0.012010088190436363,
        0.02524033933877945,
        -0.019926343113183975,
        0.043911777436733246,
        -0.019693801179528236,
        -0.0268519576638937,
        -0.06022341549396515,
        0.00012003269512206316,
        0.03680117800831795,
        -0.06156390905380249,
        -0.0288536474108696,
        -0.07405487447977066,
        0.021397195756435394,
        0.035873960703611374,
        -0.020952507853507996,
        0.05795343965291977,
        -0.07437796145677567,
        0.04094051197171211,
        0.07041201740503311,
        0.05321970954537392,
        0.05804820358753204,
        0.005439504981040955,
        0.06016774848103523,
        0.05743469297885895,
        0.06117578595876694,
        0.053734250366687775,
        0.02441699244081974,
        -0.023173393681645393,
        -0.034327369183301926,
        0.005405634641647339,
        0.01795855164527893,
        0.03247793763875961,
        0.007630184758454561,
        -0.0697687417268753,
        -0.01226566918194294,
        -0.04143722727894783,
        0.052712902426719666,
        -0.054497670382261276,
        -0.007125542964786291,
        0.06924881041049957,
        -0.0009029937209561467,
        -0.008439736440777779,
        -0.0727892592549324,
        -0.07021024078130722,
        -0.07199050486087799,
        0.051927387714385986,
        -0.009345470927655697,
        -0.009255710989236832,
        0.010559993796050549,
        -0.016826240345835686,
        0.07305064797401428,
        -0.017587201669812202,
        -0.03328370302915573,
        0.01524606253951788,
        0.07100079208612442,
        -0.06545663625001907,
        0.02815215103328228,
        0.02097358927130699,
        0.04334326088428497,
        0.059090085327625275,
        0.047699544578790665,
        0.062067363411188126,
        0.07244552671909332,
        0.03896044194698334,
        0.038342565298080444,
        -0.049233123660087585,
        -0.043841760605573654,
        -0.04088900610804558,
        0.020613860338926315,
        -0.042791835963726044,
        -0.05316707119345665,
        -0.05751321092247963,
        -0.013650579378008842,
        -0.07361448556184769,
        -0.009820839390158653,
        0.07317690551280975,
        -0.0015806638402864337,
        0.06241706386208534,
        -0.0469316765666008,
        -0.02434244006872177,
        0.014090360142290592,
        -0.03596840798854828,
        0.003603138029575348,
        -0.06620215624570847,
        -0.04375367611646652,
        -0.0438820943236351,
        0.006100520025938749,
        0.013720422983169556,
        -0.03975878655910492,
        -0.04122764989733696,
        -0.01966410130262375,
        0.01916964165866375,
        0.023094462230801582,
        0.002289888449013233,
        -0.0005817620549350977,
        0.009819296188652515,
        0.01297658309340477,
        -0.0711352750658989,
        -0.044975098222494125,
        -0.036510881036520004,
        -0.04172331094741821,
        0.07046782225370407,
        0.04524214565753937,
        0.05387713015079498,
        -0.0644453838467598,
        -0.06357860565185547,
        -0.009456618689000607,
        -0.055132199078798294,
        -0.050614841282367706,
        -0.008738425560295582,
        -0.031200909987092018,
        -0.07102631777524948,
        0.05825510993599892,
        0.04827224090695381,
        0.028223732486367226,
        0.06163257360458374,
        0.05265667662024498,
        0.04345131665468216,
        0.05509008467197418,
        0.06136836111545563,
        -0.06835372000932693,
        -0.006950504146516323,
        0.06319288909435272,
        -0.05306046083569527,
        -0.0712229311466217,
        0.02365472912788391,
        -0.036476049572229385,
        0.009631737135350704,
        -0.03559494763612747,
        0.060144875198602676,
        0.04123277962207794,
        -0.007153631653636694,
        -0.006994113326072693,
        0.06947263330221176,
        0.04403021186590195,
        0.05934138968586922,
        -0.06112488731741905,
        0.01152676623314619,
        0.005817570723593235,
        -0.06423762440681458,
        -0.04468284174799919,
        0.0412629209458828,
        0.03728432208299637,
        0.07119303196668625,
        0.036345645785331726,
        0.048009276390075684,
        -0.02750113233923912,
        -0.008696110919117928,
        0.071695476770401,
        -0.0741867795586586,
        0.006550576072186232,
        0.05036270618438721,
        0.020731797441840172,
        0.04006277769804001,
        0.0692434012889862,
        0.05454089865088463,
        -0.02669905684888363,
        -0.008332776837050915,
        0.03133518993854523,
        -0.029098788276314735,
        0.06786870211362839,
        -0.033702585846185684,
        -0.009649272076785564,
        0.046063750982284546,
        0.012748521752655506,
        -0.03810270503163338,
        -0.037119876593351364,
        -0.014501545578241348,
        0.0009955567074939609,
        -0.001522712642326951,
        -0.002679018070921302,
        -0.03746628388762474,
        0.012684604153037071,
        -0.005120887421071529,
        0.013505831360816956,
        -0.04536368325352669,
        -0.025794757530093193,
        -0.03801458701491356,
        0.04376104101538658,
        0.06428323686122894,
        -0.007351954001933336,
        -0.04031376913189888,
        0.06826368719339371,
        -0.06306439638137817,
        0.05813613161444664,
        0.0017293059499934316,
        0.005456678103655577,
        0.02930503711104393,
        -0.000017535796359879896,
        -0.02818123996257782,
        -0.023124495521187782,
        0.061725471168756485,
        -0.025952262803912163,
        0.03615240007638931,
        0.040549252182245255,
        -0.025387775152921677,
        -0.06990012526512146,
        0.05979965999722481,
        -0.029578400775790215,
        -0.01900830864906311,
        0.0575585812330246,
        -0.03632662072777748,
        -0.047969549894332886,
        -0.031671006232500076,
        -0.012265104800462723,
        -0.06006801873445511,
        0.04893241450190544,
        -0.032688770443201065,
        0.04738108813762665,
        0.06612794101238251,
        -0.03919149190187454,
        -0.057146113365888596,
        0.06465481221675873,
        0.04034062847495079,
        0.060973215848207474,
        -0.0433986634016037,
        0.01844189316034317,
        0.004738170187920332,
        0.020110009238123894,
        -0.06051529198884964,
        0.006506065372377634,
        -0.03773406520485878,
        -0.049170322716236115,
        -0.0008536019595339894,
        -0.07265667617321014,
        -0.024107245728373528,
        -0.06827865540981293,
        -0.05036173015832901,
        0.0612877681851387,
        -0.04688890278339386,
        -0.037354376167058945,
        0.06651395559310913,
        0.06451182812452316,
        0.053783856332302094,
        -0.03371614217758179,
        0.02246973291039467,
        0.06193181499838829,
        -0.03648390993475914,
        0.03703085333108902,
        -0.0387328565120697,
        0.054750557988882065,
        -0.055259790271520615,
        -0.038674648851156235,
        -0.014751125127077103,
        -0.06558854877948761,
        0.05862170085310936,
        -0.04113268107175827,
        -0.06833551824092865,
        0.06052575260400772,
        -0.05435580760240555,
        -0.0015532724792137742,
        0.003840831806883216,
        -0.019400132820010185,
        -0.05253777280449867,
        -0.048759523779153824,
        -0.05004655197262764,
        0.05234474688768387,
        -0.037173185497522354,
        -0.05136841908097267,
        -0.0026660992298275232,
        -0.0348750576376915,
        0.022230522707104683,
        0.05628844350576401,
        0.00904848612844944,
        0.023407991975545883,
        0.004163779318332672,
        0.06324707716703415,
        0.0381016731262207,
        -0.0654684379696846,
        0.07376758754253387,
        0.026399841532111168,
        -0.04792000725865364,
        0.047266505658626556,
        0.043085310608148575,
        0.07001642882823944,
        -0.02870018407702446,
        0.010950950905680656,
        -0.06296396255493164,
        -0.01377184223383665,
        -0.03546487167477608,
        -0.000561004038900137,
        0.05806436017155647,
        -0.0435219407081604,
        -0.012780440971255302,
        0.05117648467421532,
        -0.028246959671378136,
        0.056130170822143555,
        -0.059332676231861115,
        -0.04682149738073349,
        0.07238022983074188,
        -0.06207522749900818,
        0.023016151040792465,
        -0.034227367490530014,
        -0.06201602518558502,
        -0.005159740801900625,
        -0.008175800554454327,
        -0.05520288273692131,
        0.029438374564051628,
        -0.007579565979540348,
        -0.026910385116934776,
        0.0397617444396019,
        0.03047034703195095,
        -0.010908877477049828,
        -0.03072558343410492,
        -0.0217454731464386,
        -0.05271613225340843,
        -0.05965345352888107,
        0.05832977965474129,
        -0.014317167922854424,
        -0.01750892959535122,
        0.0701431930065155,
        -0.0148139214143157,
        0.06267733126878738,
        0.02663838490843773,
        0.014954475685954094,
        -0.07304402440786362,
        -0.053184494376182556,
        0.07468551397323608,
        0.002607854316011071,
        0.030548997223377228,
        0.03779689222574234,
        -0.06053820997476578,
        0.06736794859170914,
        -0.07439998537302017,
        0.02487720362842083,
        -0.0394214428961277,
        -0.037389643490314484,
        -0.048622388392686844,
        0.06879256665706635,
        -0.06581830978393555,
        -0.01815049722790718,
        0.005494101904332638,
        -0.0724029541015625,
        0.05947913974523544,
        0.0017619484569877386,
        -0.06062781438231468,
        0.008686184883117676,
        0.06644202023744583,
        -0.04913778975605965,
        0.06294456869363785,
        0.013531379401683807,
        -0.000556131883058697,
        -0.0675688087940216,
        -0.049285463988780975,
        -0.05202720686793327,
        0.059633947908878326,
        0.0549149364233017,
        -0.024806348606944084,
        0.021373802796006203,
        -0.004458161070942879,
        0.009991522878408432,
        0.019470639526844025,
        0.0340295173227787,
        -0.01003353577107191,
        -0.021581856533885002,
        -0.07180482894182205,
        0.01847759447991848,
        0.06964363157749176,
        0.0036667927633970976,
        -0.052445996552705765,
        -0.06773191690444946,
        -0.05875198170542717,
        -0.053557559847831726,
        -0.019420111551880836,
        0.02906068041920662,
        0.03574581816792488,
        0.02722083404660225,
        -0.05424406751990318,
        0.06416328996419907,
        0.0698966234922409,
        0.06371781975030899,
        0.017560889944434166,
        -0.06817958503961563,
        -0.028727494180202484,
        0.05066389590501785,
        0.04891582205891609,
        0.02698301151394844,
        -0.0281981248408556,
        0.04328883811831474,
        -0.01542674284428358,
        -0.011267902329564095,
        0.065068319439888,
        -0.045815449208021164,
        0.05983441695570946,
        -0.05825693905353546,
        0.0376489982008934,
        -0.06311138719320297,
        0.056160565465688705,
        -0.03340829908847809,
        0.010869881138205528,
        -0.02408238872885704,
        0.025565683841705322,
        -0.045730579644441605,
        -0.037182264029979706,
        -0.05763789638876915,
        0.03493737801909447,
        0.02561134845018387,
        0.015896538272500038,
        0.04107897728681564,
        -0.019729815423488617,
        -0.051971934735774994,
        -0.05266561359167099,
        -0.019801272079348564,
        0.025962652638554573,
        0.022159524261951447,
        -0.06322640925645828,
        0.002159526338800788,
        0.05038338527083397,
        -0.009741348214447498,
        -0.06587911397218704,
        0.025693446397781372,
        -0.04106728360056877,
        -0.027932722121477127,
        0.06333732604980469,
        -0.00984151754528284,
        -0.015965279191732407,
        0.048118893057107925,
        0.07318796217441559,
        -0.024914110079407692,
        0.07072550058364868,
        -0.05248500034213066,
        0.06826130300760269,
        -0.01947946287691593,
        -0.025270331650972366,
        0.020620197057724,
        0.0205440241843462,
        -0.04040539637207985,
        -0.016604498028755188,
        -0.0574832409620285,
        0.019959332421422005,
        0.0454566515982151,
        -0.06103057414293289,
        0.055442098528146744,
        0.007847148925065994,
        0.022130105644464493,
        -0.07309454679489136,
        0.03181632608175278,
        0.012359979562461376,
        0.07119344174861908,
        -0.06037355959415436,
        0.06060164421796799,
        -0.01671859622001648,
        -0.05718090385198593,
        0.02024978958070278,
        -0.013500778004527092,
        0.05627092346549034,
        -0.049258772283792496,
        -0.021180562674999237,
        -0.05844172090291977,
        0.04840122535824776,
        0.01890837401151657,
        -0.025737065821886063,
        -0.07071419805288315,
        -0.04474128410220146,
        -0.03080981969833374,
        -0.02911643497645855,
        0.0035711671225726604,
        0.03976090997457504,
        -0.05119165778160095,
        0.02216249145567417,
        0.002023885026574135,
        -0.061945486813783646,
        0.06390462070703506,
        -0.053305938839912415,
        -0.05654192343354225,
        -0.01461375318467617,
        -0.010128629393875599,
        0.053430505096912384,
        0.012128427624702454,
        0.018147610127925873,
        0.02599116787314415,
        0.047276467084884644,
        0.06182730197906494,
        -0.023370755836367607,
        -0.05521503835916519,
        -0.03700625151395798,
        -0.00015049937064759433,
        -0.006632267963141203,
        -0.010268046520650387,
        0.008690093643963337,
        0.013008342124521732,
        -0.06736285239458084,
        -0.06818132102489471,
        0.026764869689941406,
        0.06223272904753685
    ],
    [
        -0.02790321782231331,
        -0.06608793139457703,
        0.023671794682741165,
        0.05380448326468468,
        0.025936683639883995,
        0.006757343653589487,
        0.06327212601900101,
        0.033383652567863464,
        0.008071543648838997,
        0.02515368163585663,
        0.04209733381867409,
        -0.01970352604985237,
        -0.013796331360936165,
        -0.010871118865907192,
        -0.05956026166677475,
        0.029360326007008553,
        -0.0663798451423645,
        0.05634228512644768,
        -0.06691861897706985,
        0.051306113600730896,
        -0.06540880352258682,
        -0.03549849987030029,
        0.02416505105793476,
        -0.004372450988739729,
        0.050049807876348495,
        0.057457271963357925,
        -0.01647896133363247,
        0.05486220493912697,
        0.008631374686956406,
        -0.0477130301296711,
        0.053848180919885635,
        0.03140239417552948,
        0.01729350909590721,
        -0.06175515055656433,
        -0.008950327523052692,
        0.02302451990544796,
        -0.06160034239292145,
        -0.030105965211987495,
        -0.06690757721662521,
        0.04081680253148079,
        0.04438900575041771,
        -0.03410908952355385,
        0.059834014624357224,
        -0.06718197464942932,
        0.054869525134563446,
        0.06247367709875107,
        0.03809197247028351,
        0.06584464758634567,
        0.028563514351844788,
        0.04419398307800293,
        0.0567547045648098,
        0.024011559784412384,
        0.0628967434167862,
        -0.0329088494181633,
        -0.033325985074043274,
        -0.015938326716423035,
        -0.03857330605387688,
        0.03278804570436478,
        0.00451170839369297,
        0.009287439286708832,
        -0.06270474940538406,
        0.0009241681545972824,
        -0.05314931273460388,
        0.01899813674390316,
        -0.012055881321430206,
        0.0052797687239944935,
        0.06484820693731308,
        -0.04060283675789833,
        0.06193046644330025,
        -0.06725159287452698,
        -0.06284967809915543,
        -0.06601141393184662,
        0.016332583501935005,
        -0.012482451274991035,
        -0.02993794158101082,
        0.003193350974470377,
        -0.007628211751580238,
        0.06670402735471725,
        0.034437425434589386,
        -0.03315487876534462,
        0.009914289228618145,
        0.023797236382961273,
        -0.06460703164339066,
        0.02005409076809883,
        -0.026793496683239937,
        0.0398937426507473,
        -0.024992752820253372,
        0.06501545757055283,
        0.051802363246679306,
        0.06670349091291428,
        -0.022913742810487747,
        0.030495287850499153,
        0.0241398923099041,
        -0.05571854114532471,
        -0.011063066311180592,
        -0.021810734644532204,
        -0.05032198131084442,
        -0.03522593900561333,
        -0.06481718271970749,
        0.0008791221189312637,
        -0.06627874076366425,
        -0.04363758862018585,
        0.0665401741862297,
        -0.007056737784296274,
        0.044417642056941986,
        -0.009761776775121689,
        -0.037461984902620316,
        -0.008996503427624702,
        -0.05522041767835617,
        -0.0331263542175293,
        -0.05450580269098282,
        -0.04868069663643837,
        -0.002209402620792389,
        -0.010723012499511242,
        0.04463706910610199,
        -0.05892113223671913,
        -0.06169160082936287,
        0.0021119818557053804,
        -0.06462626159191132,
        -0.02698993869125843,
        -0.0015389386098831892,
        -0.06055430322885513,
        0.0008281353511847556,
        0.03795543685555458,
        -0.0661235824227333,
        -0.007586512248963118,
        -0.034890107810497284,
        0.033067986369132996,
        0.06428764760494232,
        0.03522179275751114,
        0.05802936851978302,
        -0.05950649455189705,
        -0.029101364314556122,
        0.012410846538841724,
        -0.051296383142471313,
        -0.03678823262453079,
        -0.007688646204769611,
        -0.055957991629838943,
        -0.04586012661457062,
        0.06241322308778763,
        -0.02018437534570694,
        0.024718670174479485,
        0.05630059540271759,
        0.062126558274030685,
        -0.00432913051918149,
        0.05476069822907448,
        0.054817888885736465,
        -0.05659277364611626,
        -0.011541161686182022,
        0.039717838168144226,
        -0.06566758453845978,
        -0.06605776399374008,
        -0.02293241024017334,
        0.02619178034365177,
        0.03282644599676132,
        -0.04302746802568436,
        0.06172696501016617,
        0.029724858701229095,
        -0.0046636261977255344,
        -0.04500467702746391,
        0.06613941490650177,
        0.011873656883835793,
        0.06026235595345497,
        -0.06430825591087341,
        -0.03584614396095276,
        -0.01767476089298725,
        -0.05525099113583565,
        -0.05416979268193245,
        0.015406862832605839,
        0.050002120435237885,
        0.06394226104021072,
        0.03953223675489426,
        -0.016471683979034424,
        0.05182129144668579,
        -0.05446918308734894,
        0.06412850320339203,
        -0.06714722514152527,
        0.001707001356408,
        0.050195369869470596,
        0.06076117977499962,
        0.047916229814291,
        0.05055366829037666,
        0.06393494457006454,
        -0.06275799125432968,
        -0.013236074708402157,
        0.03206269070506096,
        0.025255173444747925,
        0.06338388472795486,
        -0.0023088508751243353,
        -0.04249568283557892,
        0.026052286848425865,
        0.015226209536194801,
        -0.05879254266619682,
        -0.010890546254813671,
        -0.0157295111566782,
        0.02236209250986576,
        0.0561477392911911,
        0.0177882369607687,
        -0.022248245775699615,
        0.03407171368598938,
        0.028996150940656662,
        0.036685772240161896,
        -0.009897183626890182,
        -0.02292526140809059,
        0.001512177288532257,
        0.0175588708370924,
        0.06443582475185394,
        0.00482668774202466,
        -0.04922052100300789,
        0.06614046543836594,
        -0.06032268702983856,
        0.05521996691823006,
        -0.0026387888938188553,
        -0.0257344301789999,
        0.04871109127998352,
        -0.03348051384091377,
        -0.05778020992875099,
        -0.024162665009498596,
        0.06467403471469879,
        -0.023925310000777245,
        0.041955217719078064,
        -0.05133027955889702,
        -0.016669681295752525,
        -0.06671791523694992,
        0.06348097324371338,
        -0.06338412314653397,
        -0.01398678869009018,
        0.04949093237519264,
        -0.05622486770153046,
        -0.058829810470342636,
        -0.05585014075040817,
        0.006226780358701944,
        -0.06066560372710228,
        0.04377837851643562,
        -0.03627515584230423,
        -0.02101469412446022,
        0.0615844801068306,
        -0.020591218024492264,
        -0.05127126723527908,
        0.05733879283070564,
        0.05538025125861168,
        0.026738405227661133,
        0.053372230380773544,
        -0.024094799533486366,
        -0.022864630445837975,
        0.04578477889299393,
        0.026734260842204094,
        0.01886942610144615,
        -0.05011381581425667,
        -0.0376579575240612,
        0.02122768573462963,
        -0.06603320688009262,
        -0.055743470788002014,
        -0.06424573063850403,
        -0.014231435023248196,
        0.0450541116297245,
        -0.014013982377946377,
        -0.01949898526072502,
        0.0645243301987648,
        0.06695273518562317,
        -0.017579376697540283,
        0.004505799617618322,
        0.04828847199678421,
        0.0598343163728714,
        -0.05183516815304756,
        0.05290450155735016,
        -0.03914644569158554,
        0.032704878598451614,
        -0.05991050973534584,
        -0.05489927902817726,
        -0.03964174911379814,
        -0.05978575348854065,
        0.023180929943919182,
        -0.04925401508808136,
        -0.0653284341096878,
        0.063575379550457,
        -0.05523711070418358,
        -0.022020766511559486,
        -0.0005017740186303854,
        -0.004428085871040821,
        -0.05148085206747055,
        0.005273959133774042,
        -0.008266215212643147,
        0.041537072509527206,
        -0.05657229200005531,
        -0.04723382741212845,
        -0.007622317411005497,
        -0.0592825710773468,
        -0.006982916966080666,
        0.05186411738395691,
        -0.030111778527498245,
        -0.004118712153285742,
        0.016242198646068573,
        0.0543733686208725,
        0.017445292323827744,
        -0.06047704070806503,
        0.06716854125261307,
        -0.012516804039478302,
        -0.049750782549381256,
        0.04309758171439171,
        -0.0090778274461627,
        0.06524346768856049,
        -0.017060961574316025,
        0.011097720824182034,
        -0.061854489147663116,
        -0.05143614858388901,
        0.009862329810857773,
        0.022139351814985275,
        0.06462741643190384,
        -0.03304940462112427,
        -0.03726158291101456,
        0.06356039643287659,
        -0.0015996242873370647,
        0.0645618885755539,
        -0.06528256833553314,
        -0.0520709827542305,
        0.06698282063007355,
        -0.0510140135884285,
        0.02991451509296894,
        -0.017825258895754814,
        -0.05186483636498451,
        0.010675325989723206,
        0.03207416832447052,
        -0.06168833374977112,
        0.017964286729693413,
        0.05440672114491463,
        0.0021943426690995693,
        -0.030546022579073906,
        0.00770959397777915,
        -0.0380108505487442,
        -0.04185054451227188,
        -0.00817971583455801,
        -0.008421691134572029,
        -0.03583991155028343,
        0.05757540464401245,
        0.003328870516270399,
        -0.036759115755558014,
        0.06510639935731888,
        -0.021241791546344757,
        0.06266870349645615,
        0.007222771644592285,
        -0.04535882547497749,
        -0.05172211676836014,
        -0.05730375647544861,
        0.06732414662837982,
        -0.010160775855183601,
        0.04973401501774788,
        0.03885817155241966,
        -0.05050625652074814,
        0.05935806781053543,
        -0.0673162117600441,
        0.026674576103687286,
        -0.03024626523256302,
        -0.059589989483356476,
        -0.05856258049607277,
        0.05922454223036766,
        -0.06211772933602333,
        -0.035238396376371384,
        -0.06048516556620598,
        -0.06634773313999176,
        0.058688752353191376,
        0.020298926159739494,
        -0.06376168131828308,
        -0.013733540661633015,
        0.06032383441925049,
        -0.04572689160704613,
        0.06293611228466034,
        0.011901435442268848,
        0.01361937541514635,
        -0.06552916020154953,
        -0.05010419338941574,
        -0.06465207785367966,
        0.06307724118232727,
        0.02128741145133972,
        0.035396456718444824,
        0.04699840769171715,
        0.0283916424959898,
        -0.0029900704976171255,
        -0.015931416302919388,
        0.010436475276947021,
        -0.04927844926714897,
        0.06207955628633499,
        -0.062172774225473404,
        -0.02934441529214382,
        0.06347635388374329,
        -0.03188204765319824,
        -0.048015840351581573,
        -0.059646692126989365,
        -0.05831568315625191,
        -0.057493746280670166,
        0.04341813921928406,
        0.05777818709611893,
        0.042200177907943726,
        0.03992089629173279,
        -0.0348471999168396,
        0.05829368531703949,
        0.06575882434844971,
        0.0512503944337368,
        0.003789016744121909,
        -0.06438002735376358,
        -0.027454616501927376,
        0.05617512762546539,
        0.040152352303266525,
        0.0668032094836235,
        -0.045607853680849075,
        0.029513848945498466,
        0.004236227832734585,
        -0.00831176433712244,
        0.06217677891254425,
        -0.056483395397663116,
        0.06624625623226166,
        -0.02437420003116131,
        0.04415912553668022,
        -0.06315938383340836,
        0.05522990971803665,
        -0.028943678364157677,
        0.03201180323958397,
        -0.04028461128473282,
        0.040020622313022614,
        -0.05180216580629349,
        -0.055462900549173355,
        -0.06240198761224747,
        0.024254607036709785,
        0.02447306551039219,
        0.018393201753497124,
        0.029996080324053764,
        -0.020451651886105537,
        -0.062103062868118286,
        -0.06589049845933914,
        -0.007037615869194269,
        -0.018496984615921974,
        0.034470755606889725,
        -0.02592647261917591,
        0.014319154433906078,
        0.04496705159544945,
        0.01136268861591816,
        -0.06506048887968063,
        0.022232919931411743,
        -0.05329255387187004,
        -0.0254768468439579,
        0.02617049030959606,
        -0.04353940859436989,
        -0.03783655911684036,
        0.05580269172787666,
        0.06581473350524902,
        -0.030975395813584328,
        0.06507254391908646,
        -0.058816179633140564,
        0.06307034939527512,
        -0.05471016839146614,
        -0.013389290310442448,
        0.03840281069278717,
        0.05751392990350723,
        -0.06084403768181801,
        -0.03383897989988327,
        -0.043072499334812164,
        0.03379128873348236,
        0.05907200649380684,
        -0.05269928649067879,
        0.061330631375312805,
        0.010099700652062893,
        -0.021166563034057617,
        -0.06538653373718262,
        0.00016624960699118674,
        0.03565611317753792,
        0.06593470275402069,
        -0.06417641788721085,
        0.06357935816049576,
        0.013927740044891834,
        -0.056560225784778595,
        0.04063764959573746,
        -0.04390457272529602,
        0.06269720941781998,
        -0.041745930910110474,
        0.06266998499631882,
        -0.021544199436903,
        0.016160616651177406,
        -0.015441176481544971,
        -0.01944725029170513,
        -0.06307699531316757,
        -0.04454037919640541,
        0.0282819252461195,
        -0.0066519007086753845,
        0.013696456328034401,
        0.023630520328879356,
        -0.05946569889783859,
        0.010742990300059319,
        -0.002692784182727337,
        -0.06473823636770248,
        0.064776711165905,
        -0.035685472190380096,
        -0.06643812358379364,
        0.02728012576699257,
        -0.013938226737082005,
        0.04965287446975708,
        0.006893525831401348,
        -0.049233194440603256,
        -0.009171338751912117,
        0.04692325368523598,
        0.018111668527126312,
        0.056488048285245895,
        -0.04773269221186638,
        -0.03988703340291977,
        0.009630478918552399,
        -0.038238007575273514,
        0.0354483388364315,
        -0.03651275858283043,
        -0.00732556963339448,
        -0.06266878545284271,
        -0.03928922489285469,
        0.041721638292074203,
        0.02318519726395607
    ],
    [
        0.0491468571126461,
        0.05241018533706665,
        0.053894732147455215,
        0.010103209875524044,
        -0.028446998447179794,
        -0.0538494810461998,
        0.021512441337108612,
        -0.05389488860964775,
        0.05317780002951622,
        0.007858453318476677,
        0.05369191616773605,
        0.05260418355464935,
        -0.05270671844482422,
        -0.010754610411822796,
        -0.04558034986257553,
        0.053261734545230865,
        -0.007843240164220333,
        0.052660804241895676,
        -0.05249680206179619,
        -0.05406319350004196,
        0.053848426789045334,
        0.035377293825149536,
        0.05201272666454315,
        -0.024489309638738632,
        -0.006789997685700655,
        0.052819620817899704,
        -0.052956901490688324,
        -0.051535870879888535,
        -0.04769331216812134,
        -0.042608510702848434,
        -0.0333181731402874,
        -0.05403714254498482,
        -0.05405240133404732,
        -0.05401352792978287,
        -0.009666898287832737,
        -0.053361233323812485,
        -0.05388360098004341,
        -0.024899614974856377,
        0.05355608090758324,
        -0.04323172569274902,
        0.049090426415205,
        -0.019841976463794708,
        0.02207251638174057,
        -0.04874147102236748,
        0.05392514914274216,
        -0.0057402257807552814,
        -0.024339262396097183,
        0.05406402796506882,
        0.05402439087629318,
        -0.026298299431800842,
        0.054019708186388016,
        0.05368233472108841,
        -0.052305322140455246,
        0.0011618452845141292,
        -0.05209517106413841,
        -0.0538121722638607,
        0.054062359035015106,
        -0.02753482386469841,
        -0.026589207351207733,
        -0.053596895188093185,
        -0.05249457433819771,
        0.052961673587560654,
        -0.04892399534583092,
        0.05389139801263809,
        0.010662196204066277,
        -0.0534280426800251,
        -0.05406404659152031,
        -0.03842984884977341,
        0.04631434753537178,
        0.0524277463555336,
        0.047187380492687225,
        -0.051334116607904434,
        0.053651392459869385,
        0.05350291728973389,
        0.05405735224485397,
        -0.026830997318029404,
        -0.04165637493133545,
        0.05406446009874344,
        0.04860462620854378,
        -0.038130033761262894,
        -0.052063096314668655,
        -0.05381033569574356,
        -0.05368702486157417,
        0.04057464748620987,
        0.041447680443525314,
        0.052642349153757095,
        -0.049998700618743896,
        0.025786617770791054,
        -0.008435164578258991,
        -0.038606226444244385,
        -0.052999358624219894,
        0.012497936375439167,
        -0.04617290571331978,
        -0.054021451622247696,
        -0.05083080381155014,
        0.018572088330984116,
        -0.017704760655760765,
        -0.01504164095968008,
        0.007979989051818848,
        0.007072427775710821,
        -0.054043252021074295,
        0.045563533902168274,
        0.015391279011964798,
        -0.025318464264273643,
        0.05398458614945412,
        -0.04437873512506485,
        -0.054038938134908676,
        -0.0142188286408782,
        -0.03942231461405754,
        0.041362542659044266,
        -0.05403811112046242,
        -0.016338663175702095,
        -0.008305755443871021,
        0.03061503916978836,
        -0.020941605791449547,
        0.049549661576747894,
        -0.05406158044934273,
        -0.0506327822804451,
        0.05406113341450691,
        -0.05319299176335335,
        0.04916555434465408,
        -0.030293142423033714,
        0.054030537605285645,
        -0.05405309051275253,
        -0.04251433163881302,
        -0.053956903517246246,
        -0.037765827029943466,
        -0.05403899401426315,
        0.053913794457912445,
        0.05374299734830856,
        0.04320777207612991,
        -0.054041776806116104,
        0.016420865431427956,
        0.053594209253787994,
        0.00008246729703387246,
        -0.05363183096051216,
        0.053485553711652756,
        0.053638529032468796,
        -0.040088851004838943,
        0.05355718731880188,
        0.023457296192646027,
        -0.013397393748164177,
        0.04477494955062866,
        0.05273400619626045,
        0.04713389649987221,
        0.018128272145986557,
        0.025639336556196213,
        0.05404278635978699,
        -0.05320201814174652,
        0.05115336552262306,
        0.02184559404850006,
        -0.053997963666915894,
        -0.031299129128456116,
        0.04934438318014145,
        -0.04787411168217659,
        -0.05309624224901199,
        -0.05405956879258156,
        -0.004699792247265577,
        0.05323745310306549,
        0.022980954498052597,
        0.053952861577272415,
        0.03866070508956909,
        0.04850899055600166,
        0.053088050335645676,
        -0.027152743190526962,
        0.004586885217577219,
        0.009789930656552315,
        -0.04449757561087608,
        0.010322057642042637,
        0.043684378266334534,
        0.05289580300450325,
        -0.032865364104509354,
        -0.053980063647031784,
        -0.05403362587094307,
        0.050877198576927185,
        0.046159837394952774,
        0.017411667853593826,
        0.0496395044028759,
        0.05398384854197502,
        -0.05287474766373634,
        -0.04968542233109474,
        0.054062340408563614,
        0.05323079228401184,
        0.004328029230237007,
        -0.01330881379544735,
        0.05189219489693642,
        0.0461474172770977,
        0.051432233303785324,
        -0.004976246505975723,
        -0.054004572331905365,
        -0.053457111120224,
        -0.021416831761598587,
        -0.037001192569732666,
        0.053844012320041656,
        -0.04450687766075134,
        -0.05385936424136162,
        0.054011810570955276,
        -0.05035083368420601,
        0.05362534523010254,
        0.021579531952738762,
        0.044223714619874954,
        0.04274415969848633,
        -0.0521375872194767,
        -0.0540611557662487,
        -0.05117905139923096,
        0.054040033370256424,
        -0.05134949833154678,
        0.05112782120704651,
        -0.03165280073881149,
        0.054030679166316986,
        -0.04831971228122711,
        -0.03418205305933952,
        -0.0450509637594223,
        0.05354408547282219,
        -0.01838587410748005,
        -0.04861905798316002,
        0.046062272042036057,
        -0.049720216542482376,
        0.05349407345056534,
        0.042475491762161255,
        0.035967014729976654,
        0.05406154319643974,
        0.0034786867909133434,
        -0.05396953970193863,
        0.05383668094873428,
        0.05353861302137375,
        0.048026688396930695,
        0.005784583278000355,
        -0.05112382397055626,
        -0.03814977407455444,
        -0.04676602780818939,
        0.0301529373973608,
        0.0161296334117651,
        0.052401430904865265,
        0.04143780842423439,
        0.05405152216553688,
        0.04829103499650955,
        0.039833035320043564,
        -0.03641454130411148,
        0.05406437814235687,
        0.03643791377544403,
        0.05318467319011688,
        -0.04493335261940956,
        -0.0345320887863636,
        -0.02310808002948761,
        -0.05352820083498955,
        0.049689337611198425,
        -0.02165278233587742,
        0.04116987809538841,
        0.05335015058517456,
        -0.05241173505783081,
        -0.02567392773926258,
        -0.04778487980365753,
        -0.0536932498216629,
        0.0493239164352417,
        0.053826700896024704,
        0.0522831529378891,
        0.0393453910946846,
        0.04329769313335419,
        -0.01282885205000639,
        0.05360007286071777,
        -0.053288932889699936,
        -0.0323638916015625,
        -0.0000700401360518299,
        0.03876323997974396,
        0.053814686834812164,
        -0.05301058292388916,
        -0.02693885564804077,
        0.054018378257751465,
        0.02054492197930813,
        -0.01581917516887188,
        0.05286579951643944,
        0.05307059362530708,
        -0.05327605456113815,
        -0.053627949208021164,
        0.014228935353457928,
        -0.053257234394550323,
        0.02204275131225586,
        0.010692598298192024,
        0.05390925705432892,
        -0.05377032235264778,
        0.012136928737163544,
        -0.053977739065885544,
        0.025425082072615623,
        0.05379290506243706,
        -0.033696822822093964,
        0.04947471246123314,
        0.054055944085121155,
        -0.05357270687818527,
        0.052036937326192856,
        0.004001075867563486,
        0.053421929478645325,
        -0.020736083388328552,
        -0.05266425758600235,
        0.05401601269841194,
        -0.05342048406600952,
        0.05370577797293663,
        -0.04900191351771355,
        -0.05120004713535309,
        0.05351687967777252,
        0.05215302109718323,
        0.04768763855099678,
        0.05159474536776543,
        0.03848901018500328,
        0.04744081571698189,
        -0.04889843985438347,
        -0.015948645770549774,
        0.04963378235697746,
        -0.05052214860916138,
        0.04545500501990318,
        0.04487544298171997,
        0.05073929950594902,
        -0.008335556834936142,
        0.0132589777931571,
        -0.04926247522234917,
        -0.04607108235359192,
        -0.04824706166982651,
        -0.04427293315529823,
        0.053305383771657944,
        -0.02867082506418228,
        0.05371549725532532,
        0.05272392928600311,
        -0.0528358556330204,
        0.047673020511865616,
        0.05064333975315094,
        0.015805207192897797,
        0.05366568639874458,
        0.031033901497721672,
        -0.05326738953590393,
        -0.05358893796801567,
        0.02693709172308445,
        -0.010560993105173111,
        0.0007567002903670073,
        -0.04963736608624458,
        0.04706881195306778,
        0.04348474368453026,
        -0.021175876259803772,
        0.016129259020090103,
        -0.05241379141807556,
        0.0534147173166275,
        -0.04900897666811943,
        0.04611578583717346,
        -0.05388359725475311,
        -0.05400662496685982,
        -0.05369202420115471,
        0.04464133456349373,
        0.05378410592675209,
        0.053986381739377975,
        -0.017056778073310852,
        0.014201766811311245,
        0.05176692456007004,
        -0.01080875750631094,
        0.049386702477931976,
        -0.020839199423789978,
        -0.05277341231703758,
        -0.02526751160621643,
        -0.050309255719184875,
        -0.054050300270318985,
        -0.04262121394276619,
        -0.05051359906792641,
        0.049074795097112656,
        0.02758903242647648,
        0.0413559228181839,
        -0.02518884837627411,
        -0.05384429544210434,
        -0.05362740159034729,
        0.05322585254907608,
        -0.04154264181852341,
        -0.03665231540799141,
        -0.041760724037885666,
        -0.05282789468765259,
        -0.03419892117381096,
        -0.015731848776340485,
        -0.04985751956701279,
        0.05363526940345764,
        0.00030753191094845533,
        -0.05376414209604263,
        0.053473588079214096,
        0.05352664738893509,
        0.016041036695241928,
        -0.0423123799264431,
        -0.04079296067357063,
        -0.0038208654150366783,
        0.04830070212483406,
        0.05406289920210838,
        -0.05391937866806984,
        -0.04956725984811783,
        -0.0240654107183218,
        0.01672172173857689,
        -0.016596579924225807,
        -0.05277581512928009,
        0.01025328878313303,
        -0.05258743837475777,
        -0.053173087537288666,
        0.03854601830244064,
        0.050375282764434814,
        0.054059647023677826,
        0.05123230442404747,
        0.014975391328334808,
        -0.05320396274328232,
        -0.053074516355991364,
        0.0540497824549675,
        -0.05322137475013733,
        -0.054052457213401794,
        -0.053514596074819565,
        0.053031668066978455,
        0.051428183913230896,
        -0.012230923399329185,
        0.05402214452624321,
        0.03216084837913513,
        -0.014313853345811367,
        -0.05266899988055229,
        0.01627299003303051,
        -0.03753519430756569,
        0.053877685219049454,
        -0.05317416414618492,
        -0.010579588823020458,
        0.049433499574661255,
        -0.04863143339753151,
        0.05403870344161987,
        0.02011684514582157,
        -0.05342079699039459,
        0.04888549819588661,
        -0.05337012559175491,
        -0.001380282104946673,
        0.04789906367659569,
        -0.04148467257618904,
        -0.010847212746739388,
        0.048895563930273056,
        -0.054040782153606415,
        0.05406414717435837,
        0.05369792506098747,
        0.044462911784648895,
        0.05304573476314545,
        -0.05402947589755058,
        -0.0531754195690155,
        -0.05382227525115013,
        0.05268408730626106,
        -0.05253537744283676,
        -0.03146017715334892,
        -0.05326899513602257,
        0.05015067383646965,
        0.03703124821186066,
        0.053257718682289124,
        -0.05190374702215195,
        -0.006882964633405209,
        0.05310274288058281,
        -0.05402008071541786,
        0.030739614740014076,
        0.025291506201028824,
        -0.03528837114572525,
        0.05267907679080963,
        0.05226385220885277,
        -0.01741110160946846,
        -0.05305037647485733,
        -0.05223549157381058,
        0.053502947092056274,
        -0.0519426092505455,
        -0.005714196711778641,
        0.054037220776081085,
        0.05387190356850624,
        -0.028503969311714172,
        -0.051844194531440735,
        -0.053184304386377335,
        0.05381857976317406,
        0.05397077649831772,
        -0.04056253656744957,
        0.05405508354306221,
        0.015876732766628265,
        -0.053322240710258484,
        0.023002589121460915,
        0.01949223317205906,
        0.05395527929067612,
        0.02138466015458107,
        0.05386089161038399,
        0.01996886357665062,
        0.05406443402171135,
        -0.0540330745279789,
        -0.027996037155389786,
        0.02592978999018669,
        -0.02755824103951454,
        -0.007123188115656376,
        -0.009548859670758247,
        0.036210186779499054,
        -0.00975128635764122,
        0.05230679363012314,
        0.04790535569190979,
        0.050648581236600876,
        -0.052284326404333115,
        0.05261074751615524,
        -0.04810021072626114,
        0.013555495999753475,
        -0.03098512627184391,
        -0.0446678064763546,
        -0.053667597472667694,
        -0.0012742704711854458,
        -0.053526319563388824,
        -0.04612063243985176,
        0.017322303727269173,
        0.0511004738509655,
        -0.04985281080007553,
        -0.0044928560964763165,
        -0.05405629426240921,
        0.05373241379857063,
        -0.006446832790970802,
        0.05340495705604553,
        0.05406482517719269,
        -0.012628811411559582,
        0.053910598158836365,
        -0.05399199202656746,
        -0.0517939031124115,
        0.047280699014663696
    ],
    [
        0.0228317528963089,
        0.009745419956743717,
        0.0515686459839344,
        -0.03885902836918831,
        -0.042590703815221786,
        -0.017827749252319336,
        0.05137430876493454,
        -0.03966357186436653,
        0.01562628149986267,
        0.049129586666822433,
        -0.035658497363328934,
        0.051187463104724884,
        -0.051335692405700684,
        -0.05049480125308037,
        -0.049742765724658966,
        0.027104303240776062,
        0.03879249095916748,
        0.0518026277422905,
        0.037398286163806915,
        -0.05180416628718376,
        0.011945383623242378,
        0.051802992820739746,
        0.00351879489608109,
        0.030524397268891335,
        -0.050659675151109695,
        0.051309552043676376,
        0.029475562274456024,
        0.001176350051537156,
        -0.050621505826711655,
        0.05151000991463661,
        0.04677413031458855,
        0.051310934126377106,
        0.05174161493778229,
        -0.051803965121507645,
        0.051108140498399734,
        -0.030959809198975563,
        -0.05149134248495102,
        -0.05179790034890175,
        0.05179557576775551,
        0.0340750515460968,
        0.026366110891103745,
        0.04608004540205002,
        0.051353976130485535,
        -0.047345343977212906,
        -0.014197106473147869,
        0.0037561000790446997,
        0.026701102033257484,
        0.05180220678448677,
        0.05180073902010918,
        -0.050924379378557205,
        0.05164318531751633,
        0.032865557819604874,
        0.04795342683792114,
        -0.0506865568459034,
        -0.051705311983823776,
        0.04902489110827446,
        -0.051604680716991425,
        0.00010275440581608564,
        -0.025374185293912888,
        -0.051797278225421906,
        -0.05000682920217514,
        0.05131503567099571,
        -0.046927712857723236,
        0.021182753145694733,
        0.04990759864449501,
        -0.02904226817190647,
        -0.05179378762841225,
        0.01888309419155121,
        -0.003827929962426424,
        -0.019793404266238213,
        0.02201976254582405,
        -0.05144728720188141,
        0.050950080156326294,
        -0.049571532756090164,
        0.050490736961364746,
        -0.030011184513568878,
        0.02535395137965679,
        0.05180417373776436,
        0.05112742260098457,
        0.04493735730648041,
        -0.02070479840040207,
        -0.05107351019978523,
        -0.04822118207812309,
        0.04152276739478111,
        0.02542850375175476,
        -0.004141581244766712,
        0.05172830447554588,
        0.051791440695524216,
        -0.03937946632504463,
        0.051418568938970566,
        -0.05176389217376709,
        -0.05180233716964722,
        -0.05166814848780632,
        -0.04917680472135544,
        0.04344966262578964,
        0.051793430000543594,
        -0.012506979517638683,
        -0.025136679410934448,
        -0.0476701445877552,
        0.05148060992360115,
        -0.05179769918322563,
        0.0517391599714756,
        0.045678962022066116,
        -0.004833877552300692,
        0.04610909894108772,
        0.0017989649204537272,
        -0.04762879014015198,
        0.0344594307243824,
        0.05087606608867645,
        0.04618825763463974,
        0.050413619726896286,
        -0.05176139622926712,
        -0.016703922301530838,
        -0.012930324301123619,
        0.047241099178791046,
        -0.05178563669323921,
        -0.023119840770959854,
        -0.05016102269291878,
        -0.04907355457544327,
        -0.05151170864701271,
        -0.022125601768493652,
        -0.039919376373291016,
        0.050216272473335266,
        0.03399648889899254,
        -0.0508585087954998,
        0.013028893619775772,
        -0.05167743191123009,
        0.0272093303501606,
        0.04266929253935814,
        0.050194017589092255,
        0.044764816761016846,
        -0.05094531178474426,
        -0.04994644969701767,
        0.05180377513170242,
        -0.04444027692079544,
        -0.051488421857357025,
        0.05168861523270607,
        -0.044740498065948486,
        0.04976629093289375,
        0.04287320375442505,
        -0.024490436539053917,
        -0.05179830268025398,
        0.008156441152095795,
        0.030333388596773148,
        0.05159566178917885,
        -0.05093766376376152,
        0.051748376339673996,
        0.05145767703652382,
        -0.027247050777077675,
        -0.04310198128223419,
        -0.010890400968492031,
        -0.05178070068359375,
        -0.05136028304696083,
        -0.01873468980193138,
        0.00789563823491335,
        -0.006402530241757631,
        -0.02452296018600464,
        -0.023517627269029617,
        0.049921665340662,
        -0.05175786465406418,
        0.05180416256189346,
        0.037418898195028305,
        0.05034466087818146,
        0.0490017831325531,
        0.046799760311841965,
        0.05055813118815422,
        0.04133803769946098,
        0.013174093328416348,
        -0.05177031457424164,
        0.0460486076772213,
        -0.04353344812989235,
        0.051725227385759354,
        -0.05179699882864952,
        0.051736459136009216,
        -0.051401715725660324,
        0.04740804433822632,
        0.041629914194345474,
        -0.0013843551278114319,
        0.05174126476049423,
        -0.03017200529575348,
        -0.04681875929236412,
        0.05180416256189346,
        0.03271050006151199,
        -0.028694696724414825,
        -0.006847694981843233,
        0.04216175898909569,
        0.04459969326853752,
        0.0516623817384243,
        0.042091112583875656,
        -0.03434915468096733,
        -0.05170135945081711,
        0.01026225183159113,
        -0.04050187021493912,
        -0.05175210162997246,
        -0.05041685327887535,
        -0.05082818493247032,
        -0.04328189790248871,
        -0.051803506910800934,
        -0.03870948776602745,
        0.047898828983306885,
        0.0518028549849987,
        0.044784363359212875,
        0.04253869131207466,
        -0.05120845511555672,
        0.05002519115805626,
        -0.04544105380773544,
        0.04163806140422821,
        0.05119898170232773,
        -0.05145702883601189,
        0.05034855753183365,
        -0.05077032372355461,
        0.04341534897685051,
        -0.016326677054166794,
        0.05142258107662201,
        -0.05177868530154228,
        -0.05171124264597893,
        0.05175964534282684,
        0.01677241548895836,
        0.05128827318549156,
        -0.025586627423763275,
        -0.009532982483506203,
        -0.01590253785252571,
        0.013909599743783474,
        -0.051589421927928925,
        0.051706306636333466,
        -0.014270436018705368,
        0.033379361033439636,
        -0.04917960613965988,
        -0.04933493584394455,
        -0.05129748955368996,
        0.05111735686659813,
        -0.051488231867551804,
        -0.05169687792658806,
        0.04922598600387573,
        0.022795535624027252,
        0.04781203716993332,
        0.04931270331144333,
        0.05120845511555672,
        0.033616140484809875,
        0.05180343613028526,
        -0.04206721484661102,
        -0.0472237654030323,
        -0.036183908581733704,
        -0.05180417001247406,
        0.0509752482175827,
        -0.024652928113937378,
        0.04294849932193756,
        -0.013251553289592266,
        -0.051605865359306335,
        -0.05155973508954048,
        -0.051737893372774124,
        -0.04120200499892235,
        0.04875944182276726,
        -0.05162905901670456,
        -0.05126786231994629,
        0.05028733238577843,
        -0.007783808745443821,
        -0.04789307713508606,
        0.04789471626281738,
        0.03718617185950279,
        0.05172284319996834,
        -0.00573181314393878,
        -0.050940994173288345,
        -0.0043436274863779545,
        -0.051594894379377365,
        0.05141367390751839,
        0.05179606005549431,
        0.051789700984954834,
        -0.014590669423341751,
        -0.03239180147647858,
        0.02461385913193226,
        -0.049344707280397415,
        0.049769069999456406,
        -0.05055799335241318,
        -0.006589288357645273,
        0.041789717972278595,
        -0.04600892961025238,
        -0.049823224544525146,
        0.05096518248319626,
        0.03171020746231079,
        -0.05080126225948334,
        0.05179866403341293,
        -0.04402470588684082,
        0.022337162867188454,
        0.03826049342751503,
        0.05121653899550438,
        0.007163589354604483,
        0.048766668885946274,
        -0.044463612139225006,
        0.04287060350179672,
        -0.05166877061128616,
        0.050759777426719666,
        0.05140160396695137,
        0.04818988963961601,
        0.05179315432906151,
        -0.050803061574697495,
        0.051803670823574066,
        0.04947781562805176,
        -0.04972929507493973,
        0.048824116587638855,
        -0.050610870122909546,
        0.03151748701930046,
        -0.03435657173395157,
        0.023319242522120476,
        -0.049659982323646545,
        -0.03985382243990898,
        0.03803893178701401,
        0.032895077019929886,
        0.05175568163394928,
        -0.03536776825785637,
        0.050078120082616806,
        -0.049875907599925995,
        0.04747038707137108,
        0.05168407782912254,
        -0.01134873554110527,
        -0.05168968439102173,
        -0.048973869532346725,
        -0.049490220844745636,
        0.050703395158052444,
        0.03658587858080864,
        0.04858055338263512,
        0.027273016050457954,
        0.018118606880307198,
        0.04451649636030197,
        0.0517503097653389,
        0.04259559512138367,
        0.04914385825395584,
        -0.04957865551114082,
        -0.051749687641859055,
        -0.051763035356998444,
        0.051793232560157776,
        -0.04177397862076759,
        -0.05162966623902321,
        0.051803283393383026,
        0.04592983052134514,
        0.05110710486769676,
        -0.04495914280414581,
        0.027159059420228004,
        0.05040176585316658,
        0.017428196966648102,
        -0.03178919851779938,
        -0.04146047681570053,
        0.04845687001943588,
        -0.050253476947546005,
        0.03391776978969574,
        0.03762024641036987,
        0.05157754570245743,
        0.0511833094060421,
        0.05105888471007347,
        0.049368441104888916,
        0.04999540001153946,
        0.051721006631851196,
        0.03152504935860634,
        -0.05173960700631142,
        -0.05021209642291069,
        -0.028964506462216377,
        -0.051635682582855225,
        -0.051447004079818726,
        -0.041490450501441956,
        -0.05074014514684677,
        0.027656476944684982,
        0.0035056823398917913,
        0.041604213416576385,
        -0.05005941912531853,
        -0.051195718348026276,
        -0.05161247029900551,
        0.051489025354385376,
        -0.0516003780066967,
        -0.027435870841145515,
        -0.05180338770151138,
        -0.047624409198760986,
        -0.05178793519735336,
        0.024162033572793007,
        -0.05058109760284424,
        0.051515642553567886,
        0.05116427689790726,
        -0.051426634192466736,
        0.04771314188838005,
        0.04889346659183502,
        0.04838471859693527,
        -0.04471617937088013,
        0.028385968878865242,
        -0.04924609512090683,
        0.05059705674648285,
        0.051741838455200195,
        -0.0421268567442894,
        0.05128741264343262,
        -0.03722940757870674,
        0.018537838011980057,
        -0.050617847591638565,
        -0.051556359976530075,
        0.048812415450811386,
        -0.051329005509614944,
        -0.051731571555137634,
        0.051781538873910904,
        0.051121413707733154,
        0.04747249558568001,
        -0.05180320888757706,
        -0.050884850323200226,
        -0.04994533210992813,
        -0.04956160858273506,
        0.05178137868642807,
        -0.04189969599246979,
        -0.03245655074715614,
        -0.05150867998600006,
        -0.044658783823251724,
        0.051224540919065475,
        -0.05004393681883812,
        0.050495900213718414,
        0.02967141382396221,
        -0.03818531334400177,
        -0.051010530441999435,
        0.03360457345843315,
        0.015145529992878437,
        0.0512775219976902,
        -0.05179056525230408,
        0.0511782206594944,
        0.05179077386856079,
        0.05176912993192673,
        0.051794636994600296,
        0.05158483237028122,
        -0.05147431045770645,
        0.014844181016087532,
        -0.0518009252846241,
        -0.03932777792215347,
        -0.04891721159219742,
        -0.05158001929521561,
        0.05178658664226532,
        -0.050719987601041794,
        -0.05177920311689377,
        -0.01934121735394001,
        0.021864691749215126,
        -0.03652779757976532,
        0.051198042929172516,
        -0.04432203620672226,
        -0.05165925249457359,
        -0.050779182463884354,
        -0.05115780606865883,
        0.04930473119020462,
        -0.05137871205806732,
        -0.05108683928847313,
        0.05178900808095932,
        0.051801394671201706,
        0.05078158527612686,
        -0.051448624581098557,
        -0.03711632639169693,
        0.05089753121137619,
        -0.051660750061273575,
        0.04460351914167404,
        0.032601334154605865,
        -0.05172376334667206,
        0.006998470984399319,
        0.05180064216256142,
        0.01743142679333687,
        -0.044676247984170914,
        -0.04853947460651398,
        -0.0438128225505352,
        -0.0502326525747776,
        -0.05067863315343857,
        0.03707820177078247,
        0.051394201815128326,
        -0.051456060260534286,
        -0.025521622970700264,
        -0.004098657052963972,
        0.05174190178513527,
        0.051768433302640915,
        -0.051799874752759933,
        0.051798611879348755,
        0.05142105743288994,
        -0.05177479237318039,
        0.04832785949110985,
        0.048089005053043365,
        0.03558005020022392,
        0.05179573968052864,
        -0.04662760719656944,
        0.05088428407907486,
        -0.044996585696935654,
        0.047892797738313675,
        -0.051708050072193146,
        -0.011084557510912418,
        -0.05179543048143387,
        0.022297775372862816,
        -0.019943997263908386,
        0.00876341201364994,
        -0.05046994984149933,
        0.05038759857416153,
        0.0032517423387616873,
        0.04311478137969971,
        -0.051241930574178696,
        -0.05050239711999893,
        0.03563746064901352,
        -0.05151752755045891,
        0.050080638378858566,
        0.04962578043341637,
        -0.015225696377456188,
        0.04118405655026436,
        -0.05177246779203415,
        0.05164865031838417,
        0.04994950070977211,
        -0.044260211288928986,
        -0.051679059863090515,
        -0.05087142810225487,
        -0.051802974194288254,
        0.025965364649891853,
        0.05124451965093613,
        0.05130245164036751,
        0.05180417001247406,
        -0.04766344279050827,
        0.045184843242168427,
        -0.03959970921278,
        0.04888024181127548,
        0.04891994968056679
    ],
    [
        -0.05618136748671532,
        -0.052025679498910904,
        0.05855325609445572,
        0.04238550737500191,
        0.044618990272283554,
        0.021875428035855293,
        0.05925596132874489,
        -0.017761360853910446,
        0.014164629392325878,
        0.04378284886479378,
        0.04301982372999191,
        0.052850391715765,
        -0.04984466731548309,
        -0.03434273228049278,
        -0.05694645643234253,
        0.04045488312840462,
        -0.054633233696222305,
        0.05434924364089966,
        -0.05154489353299141,
        -0.05668208748102188,
        -0.04195927828550339,
        0.05180467665195465,
        0.0515233613550663,
        0.02471955120563507,
        0.040822405368089676,
        0.04684529826045036,
        -0.03226485848426819,
        0.05732232704758644,
        -0.05925026535987854,
        -0.03993195295333862,
        0.049721065908670425,
        0.05106150358915329,
        0.032524168491363525,
        -0.059108294546604156,
        0.027478838339447975,
        -0.03169640898704529,
        -0.05489125847816467,
        -0.04728670045733452,
        0.04961578547954559,
        -0.02717036008834839,
        0.02509203925728798,
        0.03139413520693779,
        0.0577935129404068,
        -0.014713097363710403,
        -0.0067629036493599415,
        0.008176257833838463,
        -0.04775463044643402,
        0.05849474295973778,
        0.055501244962215424,
        -0.03309617564082146,
        0.0552515983581543,
        -0.010578240267932415,
        0.03778452053666115,
        -0.040744245052337646,
        -0.05258969962596893,
        -0.01992688700556755,
        -0.047072116285562515,
        -0.029316678643226624,
        0.04956718906760216,
        -0.001980165019631386,
        -0.056627221405506134,
        -0.011775033548474312,
        -0.035012342035770416,
        -0.040795888751745224,
        -0.04750456288456917,
        -0.0517163909971714,
        -0.01252150908112526,
        -0.019403664395213127,
        0.05933371186256409,
        -0.058504167944192886,
        -0.053477272391319275,
        -0.05697150528430939,
        0.049885209649801254,
        0.011197421699762344,
        -0.040579795837402344,
        -0.03370114415884018,
        0.035882607102394104,
        0.059237778186798096,
        0.05517548695206642,
        -0.018320826813578606,
        -0.03303372114896774,
        0.04619218409061432,
        -0.05328768491744995,
        0.03230425715446472,
        -0.014194194227457047,
        0.027399953454732895,
        0.032424718141555786,
        0.05338069051504135,
        -0.02089879661798477,
        0.058147840201854706,
        -0.05042390152812004,
        -0.05839292332530022,
        -0.058278635144233704,
        -0.043911248445510864,
        0.035612355917692184,
        0.02779565565288067,
        -0.018963532522320747,
        -0.052705880254507065,
        -0.05036767199635506,
        0.012409787625074387,
        -0.05786772444844246,
        -0.025957243517041206,
        0.058272313326597214,
        0.04953863471746445,
        -0.00905244518071413,
        -0.022644998505711555,
        -0.052646804600954056,
        -0.012684683315455914,
        -0.026043381541967392,
        -0.05431364104151726,
        -0.0005004146369174123,
        -0.05809606611728668,
        -0.049995966255664825,
        -0.0062034097500145435,
        0.046481359750032425,
        -0.04217804968357086,
        -0.0460299588739872,
        -0.050990860909223557,
        -0.059325043112039566,
        0.0170863289386034,
        -0.040499959141016006,
        -0.05548371002078056,
        -0.0020028199069201946,
        0.04449450969696045,
        -0.05888501927256584,
        0.05216130614280701,
        -0.045143451541662216,
        0.030350571498274803,
        0.056931085884571075,
        -0.052774302661418915,
        0.058034468442201614,
        -0.05409494787454605,
        -0.0389220155775547,
        0.055757828056812286,
        -0.05009331926703453,
        -0.047894854098558426,
        0.04545241594314575,
        -0.05558909475803375,
        0.02730719931423664,
        0.058947302401065826,
        0.05001646652817726,
        -0.031098779290914536,
        0.047923263162374496,
        0.057596322149038315,
        0.03811304643750191,
        -0.058140434324741364,
        0.05934974178671837,
        -0.02642245776951313,
        -0.05131816118955612,
        0.04089772328734398,
        -0.0546959713101387,
        -0.05892639979720116,
        -0.057772841304540634,
        0.029521312564611435,
        -0.02085055783390999,
        -0.02196735329926014,
        0.05010701343417168,
        0.01062234677374363,
        0.04742656648159027,
        -0.05327862501144409,
        0.05932336300611496,
        -0.039641622453927994,
        0.054731663316488266,
        -0.04741827771067619,
        -0.04680464789271355,
        -0.022152328863739967,
        0.031436819583177567,
        -0.04787372797727585,
        -0.039067286998033524,
        0.0538596510887146,
        -0.03953970968723297,
        0.026102138683199883,
        -0.059090062975883484,
        0.05818719044327736,
        -0.0534353032708168,
        0.03371012583374977,
        -0.058858953416347504,
        0.02931961789727211,
        0.054106201976537704,
        0.04627125337719917,
        0.05638941377401352,
        0.05936470627784729,
        0.025532664731144905,
        0.03106480836868286,
        0.048875223845243454,
        -0.041465289890766144,
        -0.04844460263848305,
        0.05481266602873802,
        -0.0168442502617836,
        -0.056522972881793976,
        -0.05708611384034157,
        -0.028064250946044922,
        -0.04438953101634979,
        -0.0373678095638752,
        -0.054109252989292145,
        -0.04900164157152176,
        0.017877312377095222,
        -0.05858093872666359,
        -0.03033946081995964,
        0.046984948217868805,
        0.04345077648758888,
        0.05286087840795517,
        0.041558410972356796,
        -0.00539041543379426,
        0.024630920961499214,
        0.010972948744893074,
        0.05192838981747627,
        0.03406425565481186,
        -0.0026417600456625223,
        0.045305147767066956,
        -0.05759822577238083,
        0.042522307485342026,
        0.020077574998140335,
        -0.042371273040771484,
        -0.02747970260679722,
        -0.03057016246020794,
        0.053911738097667694,
        0.02499024197459221,
        0.05868973582983017,
        -0.05447098985314369,
        -0.02211996726691723,
        -0.053355567157268524,
        -0.042100436985492706,
        -0.059129226952791214,
        0.05545327067375183,
        -0.05841870978474617,
        -0.02957451529800892,
        -0.049600329250097275,
        -0.05365237593650818,
        -0.05770024284720421,
        -0.011671636253595352,
        -0.037660300731658936,
        -0.05689287558197975,
        0.05199651047587395,
        0.04605930298566818,
        -0.034680962562561035,
        0.05766579508781433,
        -0.026176726445555687,
        0.05033644661307335,
        0.059000320732593536,
        0.05230439081788063,
        0.021264588460326195,
        0.045363835990428925,
        -0.05912270396947861,
        0.047215528786182404,
        -0.018501725047826767,
        0.05511217191815376,
        -0.04978222772479057,
        0.008819655515253544,
        -0.046346068382263184,
        -0.004072351846843958,
        -0.05555859953165054,
        -0.013860411942005157,
        -0.0551866814494133,
        0.03955402597784996,
        0.04570922628045082,
        -0.042267367243766785,
        -0.042150773108005524,
        0.059104327112436295,
        0.05883492901921272,
        0.05409042164683342,
        -0.007967714220285416,
        0.0018355963984504342,
        0.05179349705576897,
        -0.057797469198703766,
        -0.014243843965232372,
        0.048718713223934174,
        0.046714331954717636,
        0.04540380835533142,
        -0.054151520133018494,
        -0.02268831431865692,
        -0.05933885648846626,
        0.05676844343543053,
        -0.054823439568281174,
        -0.03949032351374626,
        -0.01504338439553976,
        -0.058268994092941284,
        0.027006888762116432,
        -0.00865845289081335,
        -0.038068827241659164,
        -0.05469967797398567,
        0.04720746725797653,
        -0.04640403017401695,
        -0.018379416316747665,
        0.011263074353337288,
        -0.02060743421316147,
        0.018207484856247902,
        0.013561667874455452,
        -0.05221141129732132,
        0.03852968290448189,
        -0.05146480351686478,
        0.029537279158830643,
        0.04640529677271843,
        -0.012221075594425201,
        0.04063965752720833,
        -0.05180996283888817,
        0.05936707928776741,
        0.04538261890411377,
        -0.04836921766400337,
        0.034941185265779495,
        0.027596721425652504,
        0.0457434318959713,
        -0.019582217559218407,
        0.05537469685077667,
        -0.013378324918448925,
        -0.043853651732206345,
        -0.0295309666544199,
        0.049183439463377,
        0.057587880641222,
        -0.05740426480770111,
        0.04702873155474663,
        -0.015343590639531612,
        0.006599146872758865,
        0.05903066694736481,
        -0.05919535458087921,
        -0.05520549416542053,
        0.04960693046450615,
        -0.05865611508488655,
        0.0402628630399704,
        0.02700120210647583,
        -0.03866361826658249,
        0.010506152175366879,
        0.04447801783680916,
        -0.040909357368946075,
        0.048384927213191986,
        0.05710423365235329,
        0.04064120724797249,
        -0.02882581576704979,
        -0.010010309517383575,
        -0.05279526486992836,
        -0.05198550969362259,
        0.002219363348558545,
        -0.05473028123378754,
        0.03698068857192993,
        0.052358243614435196,
        0.024452127516269684,
        0.017824236303567886,
        0.059329595416784286,
        -0.044831160455942154,
        0.04288037493824959,
        0.034651126712560654,
        0.040450938045978546,
        0.046037446707487106,
        -0.0424206368625164,
        0.05896171182394028,
        0.01384644117206335,
        0.048415735363960266,
        0.05690790340304375,
        0.055384181439876556,
        0.0532706156373024,
        -0.057707443833351135,
        0.041780173778533936,
        -0.0553930327296257,
        -0.05830802395939827,
        -0.05740562826395035,
        0.05186546966433525,
        -0.059156909584999084,
        -0.04887828975915909,
        -0.032178573310375214,
        -0.009537129662930965,
        0.05515119433403015,
        0.04235817492008209,
        0.011329061351716518,
        -0.05039764940738678,
        -0.02733166702091694,
        -0.0435652881860733,
        0.059284720569849014,
        0.007212282624095678,
        0.03339815512299538,
        -0.059368085116147995,
        -0.01974157989025116,
        -0.055935267359018326,
        0.05328742787241936,
        -0.043667182326316833,
        0.05880127474665642,
        0.0486144982278347,
        0.05133471265435219,
        0.05731774866580963,
        -0.01186408568173647,
        0.011502843350172043,
        -0.013336005620658398,
        0.055278122425079346,
        -0.05277020111680031,
        0.01340392604470253,
        0.04677729681134224,
        -0.045869842171669006,
        0.05613207072019577,
        -0.054422393441200256,
        -0.04067442566156387,
        -0.05888286605477333,
        -0.045649152249097824,
        -0.023960284888744354,
        -0.008455323986709118,
        -0.044503845274448395,
        0.04489954188466072,
        0.05782688781619072,
        0.05842428281903267,
        -0.05929055064916611,
        -0.04081473872065544,
        -0.05099929869174957,
        -0.000003907475729647558,
        0.05347639322280884,
        0.02706553041934967,
        0.05553155764937401,
        -0.05278542637825012,
        0.006999324541538954,
        0.05624200403690338,
        -0.027726374566555023,
        0.03358464688062668,
        -0.054471950978040695,
        0.05678071454167366,
        -0.03747635707259178,
        -0.03539465367794037,
        0.03060338832437992,
        0.05831049755215645,
        -0.036067087203264236,
        0.02239246852695942,
        0.0004010145494248718,
        0.05499621108174324,
        0.05770772323012352,
        -0.03955390676856041,
        -0.03091512620449066,
        -0.04309135302901268,
        -0.058426499366760254,
        0.003070792416110635,
        -0.033481284976005554,
        -0.056542109698057175,
        0.044729989022016525,
        -0.055563654750585556,
        0.003679724410176277,
        0.0015684214886277914,
        -0.04409299045801163,
        -0.05814271420240402,
        0.051163509488105774,
        0.03365091234445572,
        -0.03731384500861168,
        -0.03285419940948486,
        -0.030116980895400047,
        0.057972781360149384,
        -0.052293434739112854,
        -0.04633401706814766,
        0.05345287546515465,
        0.05733166262507439,
        0.05348214879631996,
        -0.055803313851356506,
        0.052180495113134384,
        0.05437704548239708,
        -0.05868605524301529,
        0.05497310310602188,
        -0.056931786239147186,
        -0.05562157183885574,
        0.041954293847084045,
        0.05868297442793846,
        -0.044523321092128754,
        0.02060556411743164,
        -0.03150921314954758,
        -0.0030473796650767326,
        0.04842378571629524,
        -0.05151458457112312,
        0.043059028685092926,
        0.008128464221954346,
        -0.0354844331741333,
        -0.04082780331373215,
        -0.036490194499492645,
        0.05825462192296982,
        0.05912648141384125,
        -0.05896103009581566,
        0.059274666011333466,
        -0.05739173665642738,
        -0.057932280004024506,
        0.05221381410956383,
        0.056472599506378174,
        0.04663555324077606,
        0.052435122430324554,
        0.04018434137105942,
        0.05005386844277382,
        -0.028398863971233368,
        0.051034051924943924,
        -0.016100894659757614,
        0.03920022025704384,
        -0.05444810539484024,
        0.02396487072110176,
        -0.0047425213269889355,
        0.03807622194290161,
        0.03876535966992378,
        0.04624394327402115,
        -0.03554726019501686,
        -0.011708158068358898,
        -0.05668702349066734,
        -0.030009549111127853,
        0.021393263712525368,
        -0.033449236303567886,
        0.025440284982323647,
        0.0352628268301487,
        0.04957641661167145,
        0.02688097208738327,
        -0.059201423078775406,
        -0.03892343491315842,
        0.04033435136079788,
        0.04895533248782158,
        -0.05156654492020607,
        -0.03832312300801277,
        -0.022984527051448822,
        -0.014696029946208,
        0.029032690450549126,
        -0.023129336535930634,
        0.05893411114811897,
        0.016454854980111122,
        0.029414869844913483,
        -0.05880342051386833,
        0.05686216056346893,
        0.05826631560921669
    ],
    [
        -0.012924183160066605,
        0.0034911816474050283,
        0.05105530843138695,
        0.0331905223429203,
        -0.038354359567165375,
        -0.05181557685136795,
        0.0522676445543766,
        -0.052398502826690674,
        0.05205082148313522,
        -0.048863768577575684,
        0.04941466823220253,
        -0.017082519829273224,
        -0.05235181376338005,
        -0.047004349529743195,
        -0.05224056914448738,
        0.051686450839042664,
        0.02024885080754757,
        0.05238213762640953,
        0.04168568179011345,
        0.002894324017688632,
        -0.02877303585410118,
        0.05240318179130554,
        0.05210205540060997,
        0.042969126254320145,
        -0.047437429428100586,
        0.015978330746293068,
        0.05053785815834999,
        -0.03250508010387421,
        -0.051824819296598434,
        0.044921308755874634,
        -0.04760993272066116,
        0.052018217742443085,
        -0.04608796909451485,
        -0.05236228555440903,
        0.05221691355109215,
        -0.039099764078855515,
        -0.05210971459746361,
        -0.05240480229258537,
        0.037420425564050674,
        -0.04280366376042366,
        0.052256930619478226,
        0.03906534984707832,
        0.042054567486047745,
        -0.004616104997694492,
        -0.013521797955036163,
        -0.023818496614694595,
        -0.04497664421796799,
        0.052209481596946716,
        0.05239085853099823,
        -0.04773873835802078,
        0.052337851375341415,
        -0.020295659080147743,
        -0.011885927058756351,
        -0.051591500639915466,
        0.05018000304698944,
        0.0043692090548574924,
        0.03138795867562294,
        -0.03717290610074997,
        -0.011128355748951435,
        -0.052289459854364395,
        -0.052239786833524704,
        0.0481417216360569,
        -0.05216938257217407,
        0.05121682211756706,
        0.04982186481356621,
        -0.04901135340332985,
        -0.0523914210498333,
        0.04823305830359459,
        0.0523943156003952,
        0.03960499167442322,
        0.051609981805086136,
        -0.0363902784883976,
        0.052401915192604065,
        0.051586925983428955,
        0.052401281893253326,
        -0.04797697812318802,
        0.05168944597244263,
        0.05240575224161148,
        0.05220793932676315,
        0.01512853242456913,
        -0.007483004592359066,
        -0.052174363285303116,
        -0.04732479527592659,
        -0.008287988603115082,
        -0.052265673875808716,
        0.044422704726457596,
        -0.008218416944146156,
        -0.05124817416071892,
        -0.027062078937888145,
        0.01679641380906105,
        0.010377814993262291,
        -0.051474541425704956,
        -0.05237363278865814,
        -0.05028470605611801,
        -0.051169347018003464,
        0.05220219865441322,
        -0.024864869192242622,
        -0.048994582146406174,
        -0.05163085460662842,
        -0.021470792591571808,
        -0.05240302532911301,
        0.05239308252930641,
        0.0406900979578495,
        0.048171088099479675,
        -0.004648123402148485,
        -0.049693744629621506,
        -0.03943036124110222,
        -0.03811510652303696,
        0.03951546549797058,
        0.05219651386141777,
        0.04336712136864662,
        -0.046654462814331055,
        0.045851413160562515,
        -0.039132192730903625,
        0.05225886031985283,
        0.04897256940603256,
        -0.04642179235816002,
        -0.04416835308074951,
        0.0519634410738945,
        -0.04643505811691284,
        -0.052364226430654526,
        -0.04273618757724762,
        0.010404715314507484,
        -0.04356236010789871,
        -0.051975101232528687,
        0.04778072610497475,
        -0.052222367376089096,
        0.052390534430742264,
        0.052326686680316925,
        0.05224816873669624,
        -0.03061327338218689,
        -0.05238519236445427,
        -0.031126998364925385,
        0.05233119800686836,
        -0.03454907238483429,
        -0.05129261687397957,
        0.051009681075811386,
        0.051959261298179626,
        0.03965011239051819,
        0.02684234455227852,
        -0.03148620203137398,
        -0.05100938677787781,
        0.051611244678497314,
        0.04754551500082016,
        -0.050206735730171204,
        0.029107380658388138,
        0.014469653367996216,
        0.051801301538944244,
        -0.045406442135572433,
        0.05170367285609245,
        -0.008230024017393589,
        -0.05238034576177597,
        -0.04388660565018654,
        -0.029717743396759033,
        0.051815155893564224,
        -0.051596567034721375,
        -0.050183508545160294,
        -0.05232589691877365,
        0.051643092185258865,
        -0.05236436426639557,
        0.051978204399347305,
        0.03394072875380516,
        0.05189283937215805,
        0.051976751536130905,
        -0.019717924296855927,
        0.051679134368896484,
        0.012827017344534397,
        0.05113760754466057,
        -0.05231486260890961,
        0.03980880230665207,
        -0.05112079530954361,
        0.0522315576672554,
        -0.05238928273320198,
        0.04586125537753105,
        -0.0435955785214901,
        -0.04001858830451965,
        0.010832563042640686,
        0.041356708854436874,
        0.052391186356544495,
        -0.014777950942516327,
        -0.05074889212846756,
        0.051675181835889816,
        0.008029911667108536,
        0.030331691727042198,
        0.040206413716077805,
        0.043572090566158295,
        -0.01430231798440218,
        0.05201248452067375,
        -0.04959026724100113,
        0.011062388308346272,
        -0.051814183592796326,
        0.0009822575375437737,
        -0.03160416707396507,
        0.005991997197270393,
        -0.04867926612496376,
        0.028162207454442978,
        -0.03561875969171524,
        0.0262819342315197,
        -0.02167203649878502,
        0.043594762682914734,
        0.05227390304207802,
        0.052321020513772964,
        -0.003883398836478591,
        -0.04999328777194023,
        0.05209851637482643,
        0.002827288815751672,
        0.05206341668963432,
        0.05226963758468628,
        -0.05099714547395706,
        0.040473587810993195,
        -0.05152316391468048,
        0.0025832131505012512,
        0.03245648741722107,
        0.051462143659591675,
        -0.05231526121497154,
        -0.05066628381609917,
        0.05186426267027855,
        0.026820722967386246,
        0.052404846996068954,
        0.045741792768239975,
        -0.02229776233434677,
        0.042144399136304855,
        0.04815645143389702,
        -0.044953566044569016,
        0.05225924775004387,
        -0.05215344578027725,
        0.04925670847296715,
        -0.047327347099781036,
        -0.01223751064389944,
        -0.05166785791516304,
        -0.05204477161169052,
        0.012542186304926872,
        -0.03006584569811821,
        0.05087561532855034,
        -0.04779545217752457,
        0.05239810794591904,
        0.05229203775525093,
        -0.044378072023391724,
        0.041557297110557556,
        0.05238554626703262,
        -0.00670858146622777,
        0.026432419195771217,
        -0.0496738962829113,
        -0.05239608883857727,
        0.0076162731274962425,
        -0.033840399235486984,
        -0.023941628634929657,
        0.023401036858558655,
        -0.05239987373352051,
        0.0018759131198748946,
        -0.05077803134918213,
        -0.049397315829992294,
        0.02561827190220356,
        -0.05139131844043732,
        0.05177496746182442,
        0.051994554698467255,
        -0.05085865780711174,
        -0.04790555685758591,
        0.048476189374923706,
        0.03915221989154816,
        -0.05191841349005699,
        -0.04935035854578018,
        -0.04775016009807587,
        0.05212794989347458,
        -0.050786446779966354,
        0.05238034203648567,
        0.05239700525999069,
        0.019033879041671753,
        -0.05239952728152275,
        -0.015876729041337967,
        0.0520658902823925,
        -0.04761001467704773,
        0.05192375183105469,
        -0.052098989486694336,
        -0.04148276895284653,
        0.0486307218670845,
        -0.05110868811607361,
        0.034193508327007294,
        0.04759250581264496,
        0.028783708810806274,
        -0.05170102044939995,
        0.04666377231478691,
        -0.04988041892647743,
        -0.052381668239831924,
        -0.004046786576509476,
        0.04943680763244629,
        0.028797416016459465,
        0.008881389163434505,
        0.03847785294055939,
        0.016359813511371613,
        -0.05175132676959038,
        0.038954246789216995,
        -0.04667309671640396,
        0.02277201972901821,
        0.05216315761208534,
        -0.05236148089170456,
        0.05240350216627121,
        0.05017860606312752,
        -0.015199699439108372,
        0.05109430477023125,
        -0.03666279464960098,
        0.03767665848135948,
        -0.026236170902848244,
        0.05170343071222305,
        0.051143646240234375,
        -0.03099379502236843,
        -0.028639884665608406,
        0.04500291496515274,
        0.051258016377687454,
        -0.04724060744047165,
        -0.04044182226061821,
        -0.02549717389047146,
        0.04716186597943306,
        0.040168698877096176,
        -0.05170780420303345,
        -0.05191769823431969,
        0.0002233186096418649,
        -0.035381704568862915,
        0.05224447324872017,
        0.011498288251459599,
        0.05192575603723526,
        0.009907945059239864,
        0.05236007273197174,
        -0.0025546844117343426,
        0.052353549748659134,
        -0.037500735372304916,
        0.05214132368564606,
        -0.051248691976070404,
        -0.04799041524529457,
        -0.0523407906293869,
        0.03545629605650902,
        -0.052297770977020264,
        -0.05010007694363594,
        0.041959602385759354,
        0.04229282587766647,
        -0.010203148238360882,
        -0.052345532923936844,
        0.0023859580978751183,
        0.035564348101615906,
        0.01600944623351097,
        -0.0507635734975338,
        0.029938360676169395,
        0.052378784865140915,
        0.029955338686704636,
        0.05217566713690758,
        0.03922994062304497,
        0.051679790019989014,
        0.05221252888441086,
        0.04373866692185402,
        0.051995184272527695,
        0.04959654062986374,
        0.006654884200543165,
        0.0504625141620636,
        -0.052109766751527786,
        -0.03704693540930748,
        -0.002470213221386075,
        -0.0473024807870388,
        -0.04887404665350914,
        -0.05221967771649361,
        -0.035613302141427994,
        0.050851672887802124,
        0.05188585817813873,
        0.039372172206640244,
        -0.051175154745578766,
        -0.05227261409163475,
        -0.05233858525753021,
        0.05149777606129646,
        0.008070801384747028,
        -0.05232721194624901,
        -0.0500338040292263,
        -0.0394163616001606,
        -0.051573246717453,
        -0.04828402400016785,
        -0.052308883517980576,
        0.04871252551674843,
        0.052405502647161484,
        -0.04025142267346382,
        0.04999178275465965,
        0.052365776151418686,
        0.04004713147878647,
        -0.042301468551158905,
        0.042867496609687805,
        -0.05222272500395775,
        0.04567180573940277,
        0.050578176975250244,
        -0.05238642916083336,
        0.0402180552482605,
        -0.047413814812898636,
        0.016959143802523613,
        -0.04763970896601677,
        -0.04956626519560814,
        -0.04824133962392807,
        -0.050014182925224304,
        -0.05212106183171272,
        0.038716256618499756,
        0.052344128489494324,
        0.04699883237481117,
        -0.0518326573073864,
        -0.02580263465642929,
        -0.04974965378642082,
        0.02937076799571514,
        0.052041150629520416,
        -0.026754174381494522,
        0.029379041865468025,
        -0.052401527762413025,
        -0.045927997678518295,
        0.03308313712477684,
        -0.04326639696955681,
        0.05102049931883812,
        0.02241474576294422,
        -0.05230699107050896,
        -0.05234552547335625,
        0.04505917429924011,
        0.01674054190516472,
        0.052225735038518906,
        -0.052350517362356186,
        -0.052259985357522964,
        -0.049434468150138855,
        0.05172837898135185,
        0.04916739463806152,
        0.052398212254047394,
        0.05188875272870064,
        -0.007869238965213299,
        -0.052400074899196625,
        0.05228568986058235,
        -0.052374210208654404,
        -0.04989692196249962,
        0.052241481840610504,
        -0.05221769958734512,
        -0.050970520824193954,
        -0.04703526943922043,
        0.030033592134714127,
        -0.04687889292836189,
        0.05177951604127884,
        -0.052025508135557175,
        -0.0517069436609745,
        -0.05183258280158043,
        -0.028977954760193825,
        0.04583066329360008,
        -0.050931114703416824,
        -0.050995852798223495,
        0.05088392272591591,
        0.05239182710647583,
        0.052341002970933914,
        0.018462279811501503,
        0.03223707526922226,
        0.05216272175312042,
        -0.011677287518978119,
        0.050279971212148666,
        0.05228537693619728,
        0.03844481334090233,
        0.04741267114877701,
        0.04914119094610214,
        0.050016019493341446,
        0.03947371244430542,
        -0.051093243062496185,
        -0.04993343725800514,
        -0.033292531967163086,
        0.043851736932992935,
        0.051392361521720886,
        0.05196646973490715,
        -0.03941996023058891,
        -0.052128106355667114,
        0.0492328442633152,
        0.051885947585105896,
        0.052317481487989426,
        -0.05240331590175629,
        0.05239943042397499,
        0.049498300999403,
        -0.05094834044575691,
        0.051821596920490265,
        -0.04956817626953125,
        0.05229051783680916,
        0.017330998554825783,
        -0.04891149327158928,
        0.0502484068274498,
        -0.05159614980220795,
        0.05183671787381172,
        -0.05179780721664429,
        -0.051056019961833954,
        -0.034150414168834686,
        0.0019919464830309153,
        -0.0452156662940979,
        -0.02707035094499588,
        -0.05237852409482002,
        0.04616156965494156,
        0.04621680825948715,
        0.0464022196829319,
        -0.05223726108670235,
        -0.011859187856316566,
        -0.05205626040697098,
        -0.026216423138976097,
        0.05236322432756424,
        0.03386913239955902,
        -0.0445253886282444,
        -0.01761479675769806,
        -0.033247221261262894,
        -0.02870391681790352,
        0.049530498683452606,
        0.03912772610783577,
        0.02827107533812523,
        -0.05081355944275856,
        -0.049703069031238556,
        0.05240153893828392,
        0.050926219671964645,
        0.05206405743956566,
        0.0523272305727005,
        -0.05209435522556305,
        0.05239206925034523,
        -0.05231248214840889,
        0.036626074463129044,
        -0.04860606789588928
    ],
    [
        -0.016008760780096054,
        0.00045660423347726464,
        0.04768887534737587,
        -0.03362192586064339,
        -0.05329223349690437,
        -0.008951527066528797,
        0.050938013941049576,
        -0.05347301810979843,
        0.05355899780988693,
        0.0030711593572050333,
        0.0537082776427269,
        0.037335168570280075,
        -0.053547341376543045,
        0.04887828603386879,
        -0.05377015471458435,
        0.049119409173727036,
        0.039276592433452606,
        0.052439555525779724,
        -0.04404715448617935,
        0.008775616995990276,
        0.04911397397518158,
        0.036550819873809814,
        0.0537831149995327,
        -0.023509012535214424,
        -0.05166066437959671,
        0.04871193319559097,
        -0.040902331471443176,
        -0.04030453413724899,
        -0.053776778280735016,
        -0.05333945155143738,
        -0.053580667823553085,
        -0.03142303228378296,
        -0.052697837352752686,
        -0.03744298592209816,
        0.05276445299386978,
        -0.05368568003177643,
        -0.05285666510462761,
        -0.049149174243211746,
        0.05144582316279411,
        -0.05325905606150627,
        0.05289210379123688,
        0.03828946128487587,
        0.05250949785113335,
        0.04743474721908569,
        0.05280506610870361,
        0.04290859028697014,
        -0.04166392236948013,
        0.05350336804986,
        0.053806185722351074,
        0.008400337770581245,
        0.05379549041390419,
        0.041689228266477585,
        0.03258495405316353,
        -0.01582930237054825,
        0.018218502402305603,
        0.051509369164705276,
        0.039791468530893326,
        -0.023800896480679512,
        -0.052996277809143066,
        -0.0532597154378891,
        -0.05331391096115112,
        0.007604616228491068,
        -0.05286880210042,
        -0.03358917683362961,
        -0.044626545161008835,
        -0.04740297049283981,
        -0.04880412295460701,
        -0.01581892929971218,
        0.0535714253783226,
        -0.0432366207242012,
        -0.02673525922000408,
        0.03709174320101738,
        0.04706946760416031,
        0.05345164239406586,
        0.053768839687108994,
        -0.05135449394583702,
        0.047960296273231506,
        0.053813397884368896,
        0.05349172651767731,
        0.030657850205898285,
        0.012873745523393154,
        0.03461813926696777,
        -0.05375487357378006,
        0.053638797253370285,
        -0.0525200217962265,
        0.053078871220350266,
        -0.011160139925777912,
        0.03334323689341545,
        -0.037232015281915665,
        0.05276627838611603,
        -0.05320538207888603,
        0.03647974878549576,
        -0.05222749337553978,
        -0.05233892425894737,
        -0.05032498762011528,
        0.051052991300821304,
        -0.007623582612723112,
        -0.05344964191317558,
        0.03700762614607811,
        0.038188569247722626,
        -0.05376692861318588,
        0.05380232259631157,
        0.02895663119852543,
        0.05351460725069046,
        -0.02147742547094822,
        -0.0537647008895874,
        -0.03786694258451462,
        -0.035253554582595825,
        -0.05094575509428978,
        -0.05316535755991936,
        0.04733989015221596,
        -0.052375856786966324,
        0.016128024086356163,
        -0.053527429699897766,
        0.04620283842086792,
        0.026214100420475006,
        0.014581196010112762,
        -0.05157404765486717,
        -0.027486488223075867,
        -0.021009434014558792,
        -0.039688315242528915,
        -0.047530397772789,
        0.05130612477660179,
        -0.053678467869758606,
        -0.048300739377737045,
        -0.05244254693388939,
        -0.05208366736769676,
        0.036041587591171265,
        0.04785787686705589,
        0.047467224299907684,
        0.01758560724556446,
        -0.05312762409448624,
        -0.051477137953042984,
        0.05274445563554764,
        -0.0516328290104866,
        -0.05254020169377327,
        0.05375416576862335,
        -0.05124051123857498,
        -0.039174165576696396,
        0.020540418103337288,
        -0.04894569516181946,
        0.010536828078329563,
        0.05334579944610596,
        0.044922683387994766,
        -0.04557398334145546,
        0.048141755163669586,
        0.022369075566530228,
        -0.0535186231136322,
        -0.03178964555263519,
        -0.009686444886028767,
        -0.05171188712120056,
        -0.053640399128198624,
        -0.050106022506952286,
        0.050530146807432175,
        0.0537942610681057,
        -0.03993883356451988,
        -0.00892739649862051,
        -0.03494090959429741,
        0.05227280408143997,
        0.036536023020744324,
        0.04128091782331467,
        -0.05047556012868881,
        0.05367489159107208,
        0.053560201078653336,
        -0.04992302134633064,
        0.046966303139925,
        0.044022079557180405,
        0.052148010581731796,
        0.050017885863780975,
        0.05246105045080185,
        -0.0013878189492970705,
        0.031080370768904686,
        -0.05371275171637535,
        0.05071735009551048,
        -0.05177063122391701,
        -0.05061905458569527,
        -0.034781306982040405,
        0.03590380400419235,
        0.05347486585378647,
        0.034664470702409744,
        -0.05078430473804474,
        0.053647808730602264,
        0.03915800526738167,
        -0.049767736345529556,
        0.010522057302296162,
        0.04491671547293663,
        0.016610652208328247,
        0.05078522115945816,
        0.05292578414082527,
        0.01662670448422432,
        -0.009981455281376839,
        -0.04864533990621567,
        -0.04592598229646683,
        0.00040122767677530646,
        -0.05278634652495384,
        0.03629171848297119,
        -0.0022391555830836296,
        -0.04401344060897827,
        -0.0013855155557394028,
        0.010517697781324387,
        0.05380595475435257,
        -0.03060329146683216,
        -0.044888246804475784,
        0.038260169327259064,
        0.0447712168097496,
        -0.01015897374600172,
        0.05296573415398598,
        0.027752649039030075,
        -0.03331882879137993,
        0.05275760963559151,
        -0.053726762533187866,
        0.04973701015114784,
        0.03499249368906021,
        0.02831212803721428,
        -0.05308683216571808,
        -0.053184960037469864,
        0.05372363328933716,
        0.02892177738249302,
        0.05380555987358093,
        -0.02194361761212349,
        0.007258049212396145,
        0.04874883219599724,
        0.05364438146352768,
        0.04775448888540268,
        0.053790029138326645,
        -0.05135703086853027,
        -0.02948792837560177,
        0.015386062674224377,
        0.048459336161613464,
        -0.05335652455687523,
        -0.05358169227838516,
        0.037934284657239914,
        -0.03730284050107002,
        0.051667358726263046,
        0.01053494680672884,
        0.05360651761293411,
        0.05369849503040314,
        -0.04214657470583916,
        0.05325450748205185,
        0.05374455451965332,
        -0.033089615404605865,
        -0.050321321934461594,
        0.045866552740335464,
        -0.023719780147075653,
        0.05379150062799454,
        -0.02802516520023346,
        0.024182207882404327,
        0.052554868161678314,
        0.0005720393382944167,
        -0.047884054481983185,
        -0.053244952112436295,
        -0.017049772664904594,
        -0.041594017297029495,
        -0.05367182195186615,
        0.022703977301716805,
        -0.004042929969727993,
        -0.01036240253597498,
        -0.03032180853188038,
        0.0537942536175251,
        -0.028650710359215736,
        -0.05217607319355011,
        -0.023688551038503647,
        0.05010773986577988,
        0.032239172607660294,
        0.013343669474124908,
        0.05373413860797882,
        0.0534493550658226,
        0.051860056817531586,
        0.04010158032178879,
        -0.045189790427684784,
        -0.04804283753037453,
        -0.05328577011823654,
        0.053702276200056076,
        -0.052450988441705704,
        0.033934108912944794,
        0.051705099642276764,
        -0.05064590647816658,
        -0.030418336391448975,
        0.02127964049577713,
        -0.01029281411319971,
        -0.053723420947790146,
        0.053343694657087326,
        -0.017888883128762245,
        -0.05363605543971062,
        0.05248376354575157,
        -0.03001842088997364,
        -0.04505450651049614,
        0.04954887926578522,
        0.052243247628211975,
        0.05135330930352211,
        -0.0403602197766304,
        0.04093482345342636,
        -0.04177739471197128,
        -0.039005979895591736,
        0.052820831537246704,
        -0.053327448666095734,
        0.05377943068742752,
        0.023895660415291786,
        -0.010213064029812813,
        0.04684917628765106,
        0.034139398485422134,
        0.05011323094367981,
        -0.04652376472949982,
        0.05367744341492653,
        -0.05338572710752487,
        0.05304858833551407,
        -0.009480549022555351,
        0.053698599338531494,
        -0.0398147813975811,
        -0.051475971937179565,
        -0.015206963755190372,
        -0.0430125929415226,
        0.05298714339733124,
        0.05359530448913574,
        -0.04992837831377983,
        -0.05338374152779579,
        0.015697838738560677,
        -0.0536622516810894,
        0.05356702208518982,
        -0.02982921712100506,
        -0.030864542350172997,
        -0.04491185024380684,
        0.03765001520514488,
        -0.03374715894460678,
        0.05375565588474274,
        -0.05319608375430107,
        0.05370701104402542,
        -0.05334402248263359,
        0.053450774401426315,
        -0.0535636730492115,
        -0.04278017580509186,
        -0.05282172933220863,
        -0.014639024622738361,
        -0.01878344640135765,
        0.05364183709025383,
        0.043826743960380554,
        -0.0338895358145237,
        0.05287276580929756,
        -0.0482540987432003,
        0.053567979484796524,
        -0.05340180918574333,
        0.04831109195947647,
        0.05359998345375061,
        0.044869713485240936,
        0.05321166291832924,
        0.04838818684220314,
        0.05327410623431206,
        0.05358714982867241,
        0.052191250026226044,
        0.05315634608268738,
        0.053774237632751465,
        -0.05236682668328285,
        0.0522327646613121,
        -0.053276196122169495,
        -0.03750703111290932,
        0.051234614104032516,
        -0.053798433393239975,
        -0.04908497631549835,
        -0.0030728387646377087,
        0.04022202268242836,
        0.05247282609343529,
        0.05376726761460304,
        -0.052066780626773834,
        -0.05309377610683441,
        -0.04621262475848198,
        -0.05358182638883591,
        0.053358688950538635,
        -0.019038617610931396,
        -0.016133688390254974,
        -0.05308816581964493,
        -0.04454503580927849,
        -0.01336027029901743,
        0.04853960871696472,
        -0.04998670518398285,
        0.0535983070731163,
        0.04699933901429176,
        0.04729843884706497,
        0.053640216588974,
        0.05379204824566841,
        0.023667478933930397,
        0.012679044157266617,
        0.05212480574846268,
        -0.05327865108847618,
        -0.03691738843917847,
        0.053810231387615204,
        -0.05361935868859291,
        -0.015333151444792747,
        0.03678557276725769,
        -0.05133500322699547,
        -0.0430116131901741,
        0.01811649650335312,
        -0.01951444521546364,
        -0.031371138989925385,
        -0.05063365027308464,
        0.053368985652923584,
        0.05318862944841385,
        0.05355004221200943,
        -0.04904266819357872,
        -0.05378099903464317,
        -0.05331743508577347,
        -0.004323313012719154,
        0.05372168496251106,
        -0.03878790885210037,
        0.04164079204201698,
        -0.053333036601543427,
        0.0015590670518577099,
        0.04456114396452904,
        -0.05229800194501877,
        0.053800977766513824,
        -0.05041336268186569,
        0.044531989842653275,
        -0.044632863253355026,
        -0.04872313514351845,
        0.003557321149855852,
        0.050417039543390274,
        -0.027462752535939217,
        -0.05295786261558533,
        -0.040523651987314224,
        0.030135072767734528,
        0.053184643387794495,
        0.04139955714344978,
        -0.053797412663698196,
        -0.05260857194662094,
        -0.05381189286708832,
        -0.03799517825245857,
        -0.04689556732773781,
        -0.006510052364319563,
        0.0523357018828392,
        -0.04940344765782356,
        -0.05342845618724823,
        0.04843911901116371,
        -0.04329204931855202,
        -0.01224818266928196,
        -0.050995178520679474,
        -0.04321619123220444,
        -0.051329001784324646,
        -0.053810328245162964,
        0.05283021554350853,
        0.021084802225232124,
        -0.05202833563089371,
        -0.03240946680307388,
        0.053802117705345154,
        0.02069132961332798,
        0.053786128759384155,
        -0.004553122911602259,
        0.05026634782552719,
        0.049347251653671265,
        -0.05374838411808014,
        0.026113610714673996,
        0.01056014932692051,
        0.05139055848121643,
        0.03200060874223709,
        0.047187432646751404,
        0.053487155586481094,
        0.01984519511461258,
        -0.05356795713305473,
        -0.05167311057448387,
        -0.04349183291196823,
        -0.001628367812372744,
        0.023761745542287827,
        0.01908891089260578,
        -0.0537555105984211,
        -0.049999333918094635,
        0.029615815728902817,
        0.05220610275864601,
        0.053802043199539185,
        -0.05063597485423088,
        0.053525764495134354,
        -0.05367954075336456,
        -0.04844248667359352,
        0.04367886111140251,
        0.05007388815283775,
        0.016380978748202324,
        0.05378095060586929,
        0.03720550239086151,
        0.04331493005156517,
        0.016356388106942177,
        0.05368012934923172,
        -0.014789645560085773,
        -0.009055414237082005,
        -0.024766026064753532,
        -0.04894334822893143,
        -0.05356995388865471,
        0.03148172050714493,
        -0.01734508015215397,
        0.053762756288051605,
        0.05378800258040428,
        0.03888898342847824,
        -0.05369875207543373,
        0.04784822463989258,
        0.049923744052648544,
        -0.04731621593236923,
        0.053434859961271286,
        -0.037965547293424606,
        -0.02040007896721363,
        -0.04566899314522743,
        0.05380769819021225,
        -0.053502216935157776,
        0.05314939096570015,
        -0.05052320286631584,
        -0.05319418013095856,
        0.011770830489695072,
        0.011622658930718899,
        0.032216254621744156,
        0.05191141366958618,
        -0.043543655425310135,
        0.049465760588645935,
        0.005418759770691395,
        0.053671374917030334,
        -0.05380743369460106,
        0.047136034816503525,
        0.04506174102425575
    ],
    [
        0.05573911964893341,
        0.006611089687794447,
        0.04576786234974861,
        -0.02873983234167099,
        -0.04936710000038147,
        -0.04536967724561691,
        -0.05501151829957962,
        -0.05646383762359619,
        0.053601108491420746,
        0.040753841400146484,
        0.051828064024448395,
        0.046669695526361465,
        -0.0556347481906414,
        -0.05476180836558342,
        -0.04780983924865723,
        -0.02537817507982254,
        0.021234484389424324,
        0.05261373519897461,
        0.03870410844683647,
        -0.056386448442935944,
        0.04812246933579445,
        0.05680924654006958,
        -0.0553431361913681,
        0.05482868477702141,
        0.05578160285949707,
        0.054106831550598145,
        0.01823822222650051,
        0.031246937811374664,
        -0.05533083528280258,
        -0.0020089191384613514,
        0.05384451150894165,
        0.0473521463572979,
        0.05080130323767662,
        -0.05645155906677246,
        0.05270504578948021,
        -0.05551830306649208,
        -0.05656271055340767,
        0.01571367308497429,
        0.04778134450316429,
        -0.0025422037579119205,
        -0.053482454270124435,
        0.052029818296432495,
        0.04524853825569153,
        -0.0037331939674913883,
        0.01556546613574028,
        0.05091606825590134,
        -0.034340206533670425,
        0.05663679540157318,
        0.05710989981889725,
        0.0370052196085453,
        0.05715092271566391,
        0.05534559115767479,
        -0.02251606248319149,
        -0.0016619821544736624,
        -0.05415688827633858,
        -0.0049470458179712296,
        -0.03830564767122269,
        -0.03485533595085144,
        -0.054153528064489365,
        -0.02517937310039997,
        -0.055965498089790344,
        0.01581968367099762,
        -0.05592961981892586,
        0.05501648783683777,
        0.015328237786889076,
        -0.0523485466837883,
        -0.05077337846159935,
        -0.048146843910217285,
        0.050339486449956894,
        0.029065953567624092,
        -0.04855718836188316,
        -0.05660538375377655,
        0.05653674155473709,
        0.049634773284196854,
        -0.004481075331568718,
        -0.030207505449652672,
        -0.015524719841778278,
        0.05717477202415466,
        0.051888275891542435,
        0.04825286939740181,
        -0.039142120629549026,
        -0.005226604174822569,
        -0.05413489788770676,
        0.055071502923965454,
        -0.00006641753134317696,
        0.049042824655771255,
        0.05529589205980301,
        0.014286952093243599,
        -0.029072226956486702,
        0.05252303183078766,
        -0.01306633185595274,
        -0.05076184123754501,
        -0.056879159063100815,
        -0.04748935624957085,
        -0.02405126951634884,
        0.054939012974500656,
        -0.024559879675507545,
        -0.05584234744310379,
        -0.021335771307349205,
        0.056061938405036926,
        -0.05712230131030083,
        0.015952661633491516,
        0.04968750849366188,
        0.03369217365980148,
        0.056450482457876205,
        0.05162438377737999,
        -0.05080108344554901,
        0.04812518507242203,
        0.03760628029704094,
        0.008902757428586483,
        -0.055439043790102005,
        -0.05682413652539253,
        -0.05199181288480759,
        0.04872942715883255,
        0.05545872449874878,
        -0.012594609521329403,
        -0.03816154971718788,
        -0.035815104842185974,
        0.04666707292199135,
        -0.056410130113363266,
        -0.011031410656869411,
        0.028163153678178787,
        -0.01797502487897873,
        -0.05305645614862442,
        -0.05327412858605385,
        0.02544441446661949,
        -0.05651737004518509,
        0.05332164093852043,
        0.056478019803762436,
        0.038421064615249634,
        0.04805866256356239,
        -0.05514383688569069,
        -0.05643105134367943,
        0.056365158408880234,
        -0.018950778990983963,
        -0.05663589760661125,
        0.056807927787303925,
        -0.05387086793780327,
        -0.03013795241713524,
        0.027179090306162834,
        -0.04507029801607132,
        -0.054330501705408096,
        0.048884209245443344,
        0.05578283220529556,
        -0.00013068708358332515,
        -0.03847036138176918,
        0.019954334944486618,
        -0.01750156842172146,
        0.05367621034383774,
        0.05552956461906433,
        -0.028095979243516922,
        -0.05690827593207359,
        -0.05104536935687065,
        -0.01391051709651947,
        0.018413139507174492,
        -0.012965939939022064,
        -0.047516968101263046,
        -0.05024024471640587,
        0.03845788165926933,
        -0.04280414804816246,
        0.05520372837781906,
        0.0343727208673954,
        0.054505009204149246,
        0.04156794399023056,
        0.055416543036699295,
        -0.009844238869845867,
        0.055716585367918015,
        -0.0015749966260045767,
        -0.05275069922208786,
        0.05097757652401924,
        -0.0491773895919323,
        -0.014901731163263321,
        -0.04705708846449852,
        0.054259076714515686,
        -0.0013927435502409935,
        0.025351231917738914,
        0.03362128511071205,
        0.023133035749197006,
        0.055427927523851395,
        -0.05688712000846863,
        -0.03286708518862724,
        0.05667533352971077,
        0.054491136223077774,
        -0.03982889652252197,
        0.04090890288352966,
        0.011370631866157055,
        0.045864786952733994,
        0.05357572063803673,
        -0.033062856644392014,
        -0.05277010425925255,
        -0.05554082989692688,
        0.04087723046541214,
        -0.043218858540058136,
        0.0021785052958875895,
        -0.05058600381016731,
        0.044648800045251846,
        -0.03207644820213318,
        -0.051853012293577194,
        0.053044527769088745,
        0.05402647331357002,
        0.04437881335616112,
        0.04414000362157822,
        0.045056119561195374,
        -0.05296343192458153,
        0.03114205040037632,
        -0.00969874206930399,
        0.0541587769985199,
        0.02154569700360298,
        -0.056773990392684937,
        0.05707453191280365,
        -0.0509750172495842,
        0.02551937848329544,
        0.00004452973007573746,
        0.05007469654083252,
        0.04095572233200073,
        -0.05363847687840462,
        0.03865423426032066,
        -0.04935988783836365,
        0.056498121470212936,
        -0.04395650699734688,
        -0.0007018899195827544,
        0.051556337624788284,
        0.01988493837416172,
        -0.03757890686392784,
        0.05708170682191849,
        -0.006160542834550142,
        0.03722646087408066,
        -0.051210809499025345,
        0.03771492838859558,
        -0.05401851609349251,
        -0.03700888529419899,
        -0.05443938076496124,
        -0.0570799745619297,
        0.05411936715245247,
        -0.03884965181350708,
        0.05703253298997879,
        0.05566268786787987,
        -0.027676893398165703,
        0.031060662120580673,
        0.05694609880447388,
        0.021649295464158058,
        0.055429235100746155,
        -0.022307323291897774,
        -0.05371040850877762,
        0.04836934059858322,
        -0.01640990749001503,
        -0.013970914296805859,
        -0.05503641068935394,
        0.04752486199140549,
        0.010808628983795643,
        -0.054149266332387924,
        -0.056251924484968185,
        0.04864426329731941,
        -0.056837260723114014,
        -0.03233262524008751,
        0.05598852038383484,
        0.04486292600631714,
        0.026439089328050613,
        0.0506155900657177,
        -0.019658634439110756,
        0.05540797486901283,
        -0.043211113661527634,
        0.014879965223371983,
        0.055531758815050125,
        -0.016844863072037697,
        0.055245235562324524,
        0.054609086364507675,
        0.037734340876340866,
        0.033740077167749405,
        -0.027775628492236137,
        0.047021083533763885,
        -0.04343220591545105,
        0.055038511753082275,
        -0.05679686740040779,
        -0.04358840733766556,
        0.012258763425052166,
        -0.048333100974559784,
        -0.03573734685778618,
        -0.05692442134022713,
        -0.05322451516985893,
        0.055371031165122986,
        0.05675046890974045,
        0.012270202860236168,
        -0.03581714257597923,
        0.04726213216781616,
        0.053458839654922485,
        -0.044121548533439636,
        0.047839079052209854,
        -0.056674033403396606,
        0.05544767156243324,
        -0.052795760333538055,
        -0.029410088434815407,
        -0.02830672264099121,
        0.04485839232802391,
        0.05682682245969772,
        -0.05345112457871437,
        0.05625154450535774,
        -0.05064708739519119,
        -0.029227251186966896,
        0.05635886266827583,
        -0.046632908284664154,
        -0.017855729907751083,
        -0.036924801766872406,
        0.05421104282140732,
        -0.023170821368694305,
        -0.009452695958316326,
        0.0534750334918499,
        -0.044395457953214645,
        0.02739577181637287,
        -0.056292466819286346,
        -0.001538159791380167,
        0.03815717622637749,
        0.04856915399432182,
        0.05700087919831276,
        0.011254748329520226,
        -0.05584026500582695,
        -0.039936043322086334,
        0.0015202732756733894,
        0.05425720661878586,
        0.003445531241595745,
        -0.03983937203884125,
        0.03540767729282379,
        0.05473378673195839,
        -0.046052493155002594,
        0.055675946176052094,
        -0.036277350038290024,
        0.05644911900162697,
        -0.05591640993952751,
        0.02550939843058586,
        -0.05484363064169884,
        0.05655613914132118,
        -0.018340719863772392,
        -0.055962130427360535,
        0.013189411722123623,
        0.04825771600008011,
        0.03582482412457466,
        0.05478975921869278,
        0.053331490606069565,
        -0.054247401654720306,
        -0.014011217281222343,
        -0.038238365203142166,
        -0.052702683955430984,
        0.04858863353729248,
        -0.05699373036623001,
        -0.03656463325023651,
        0.01747085526585579,
        0.04659793898463249,
        0.05663541704416275,
        0.001061675837263465,
        0.05540195852518082,
        0.05623549968004227,
        -0.008837185800075531,
        0.033272579312324524,
        -0.05697603151202202,
        -0.05636138841509819,
        0.04733749479055405,
        -0.05703691765666008,
        -0.05318610742688179,
        -0.04323825240135193,
        -0.032074522227048874,
        0.04284584894776344,
        0.02302490547299385,
        -0.011907839216291904,
        -0.05119343101978302,
        -0.049825333058834076,
        -0.055827848613262177,
        0.05706358328461647,
        -0.04271063581109047,
        -0.056251876056194305,
        -0.05616920813918114,
        -0.023752976208925247,
        -0.04986999183893204,
        0.053800079971551895,
        -0.05462418496608734,
        0.007074435241520405,
        0.04140156880021095,
        -0.014330578036606312,
        0.009317848831415176,
        0.056907203048467636,
        -0.04589739814400673,
        0.021509351208806038,
        -0.053541626781225204,
        -0.054454464465379715,
        0.05008752644062042,
        -0.002902284497395158,
        0.0079544298350811,
        -0.04116448387503624,
        -0.0521535724401474,
        -0.05299101397395134,
        -0.006708274129778147,
        -0.05688275396823883,
        0.048287615180015564,
        -0.05633389577269554,
        -0.04969567060470581,
        0.0429571233689785,
        0.056050483137369156,
        0.04380958899855614,
        -0.05417793616652489,
        -0.057024482637643814,
        -0.054360222071409225,
        0.03377891331911087,
        0.05317828431725502,
        0.018461786210536957,
        0.01842828094959259,
        -0.021599072962999344,
        -0.01783577911555767,
        0.025336407124996185,
        -0.047013428062200546,
        0.0537441112101078,
        -0.04660242423415184,
        -0.021244695410132408,
        -0.04996044188737869,
        0.004437796771526337,
        0.028981352224946022,
        0.026934532448649406,
        -0.053576257079839706,
        -0.040952667593955994,
        0.045613355934619904,
        0.05103035271167755,
        0.056111112236976624,
        0.043742649257183075,
        0.03258160874247551,
        -0.03648584336042404,
        -0.05714455619454384,
        0.015503399074077606,
        0.05184248462319374,
        -0.05112942308187485,
        -0.03136132284998894,
        -0.05508781224489212,
        -0.05660150945186615,
        0.05507658049464226,
        0.014913423918187618,
        0.04729723557829857,
        -0.013961776159703732,
        -0.0432465560734272,
        -0.05449017137289047,
        -0.05600018799304962,
        0.04694758355617523,
        -0.019271405413746834,
        -0.055999960750341415,
        -0.0034454409033060074,
        0.043730728328228,
        0.055850621312856674,
        0.055471889674663544,
        0.01586589217185974,
        0.003561869729310274,
        0.0565960593521595,
        -0.05441775545477867,
        0.04964718595147133,
        -0.030796995386481285,
        -0.0389406755566597,
        0.05018992722034454,
        0.03573349863290787,
        0.025566862896084785,
        0.05333771929144859,
        -0.05585935339331627,
        -0.022449327632784843,
        -0.056764207780361176,
        0.044079143553972244,
        0.05375166982412338,
        0.03724365681409836,
        -0.05174754932522774,
        0.0048187365755438805,
        -0.05556455999612808,
        0.05643858388066292,
        0.056177105754613876,
        -0.05032489821314812,
        0.05167828127741814,
        0.0367194302380085,
        -0.049594372510910034,
        0.043843697756528854,
        0.039037637412548065,
        0.04350355640053749,
        0.049517713487148285,
        0.04372771084308624,
        0.018857311457395554,
        0.028117619454860687,
        0.055331066250801086,
        -0.056365013122558594,
        0.0348801426589489,
        0.04288831353187561,
        0.044228214770555496,
        -0.02781003899872303,
        0.04370713606476784,
        0.04551755264401436,
        0.020435495302081108,
        0.029444733634591103,
        0.054108839482069016,
        -0.055274590849876404,
        -0.024171575903892517,
        -0.05098510533571243,
        -0.03997976705431938,
        0.018870197236537933,
        -0.018183914944529533,
        -0.05216937139630318,
        0.02982310578227043,
        -0.01812846213579178,
        0.05214650556445122,
        0.0530514270067215,
        0.056439220905303955,
        -0.055878397077322006,
        -0.02830308862030506,
        -0.04588289186358452,
        -0.042847998440265656,
        0.055324435234069824,
        0.05057857558131218,
        0.055974435061216354,
        0.02502306178212166,
        -0.006983865983784199,
        -0.05411660298705101,
        0.04951376095414162,
        0.039334703236818314
    ],
    [
        -0.05109873414039612,
        0.052123621106147766,
        0.05540229007601738,
        0.02290373481810093,
        -0.042856648564338684,
        -0.05390213057398796,
        0.03926180675625801,
        -0.05433652177453041,
        0.04941856116056442,
        0.055154211819171906,
        0.05282389372587204,
        0.03676356375217438,
        -0.05190376564860344,
        -0.04816529527306557,
        -0.0547480508685112,
        -0.05044125020503998,
        0.04868066683411598,
        0.05491020902991295,
        0.055342115461826324,
        -0.055423516780138016,
        -0.05347776412963867,
        0.050124701112508774,
        0.055273741483688354,
        0.006714256480336189,
        -0.007512855809181929,
        0.05452432855963707,
        0.05495617166161537,
        -0.053057581186294556,
        -0.046448249369859695,
        -0.05444495752453804,
        -0.05248904600739479,
        0.0025222497060894966,
        -0.05291430652141571,
        -0.055334702134132385,
        0.05254831537604332,
        -0.01570037193596363,
        -0.05461610108613968,
        -0.052059490233659744,
        0.05288618803024292,
        -0.05371575802564621,
        0.04893672838807106,
        0.012550530955195427,
        0.051419928669929504,
        0.053345344960689545,
        0.052814364433288574,
        0.020996198058128357,
        0.023510340601205826,
        0.05405842140316963,
        0.0552295446395874,
        0.04480050504207611,
        0.055121202021837234,
        -0.014136734418570995,
        0.03592737391591072,
        -0.03124755620956421,
        -0.05449016019701958,
        -0.04238492622971535,
        -0.05035484954714775,
        0.04657891020178795,
        -0.054828889667987823,
        -0.05311569944024086,
        -0.050974033772945404,
        -0.008713889867067337,
        -0.05490798130631447,
        0.027001243084669113,
        0.029968546703457832,
        -0.048947080969810486,
        -0.029903659597039223,
        -0.009555554948747158,
        0.04393603280186653,
        -0.04110722616314888,
        -0.043861038982868195,
        -0.054104283452034,
        0.04394753277301788,
        0.048850905150175095,
        0.01590871997177601,
        -0.05435038357973099,
        0.007952127605676651,
        0.05541504546999931,
        0.05437975376844406,
        0.033824995160102844,
        0.02617313154041767,
        0.054091326892375946,
        -0.05328110232949257,
        0.04280902445316315,
        -0.054758377373218536,
        0.03927344083786011,
        0.05526559799909592,
        0.00026044435799121857,
        0.010292435064911842,
        0.04339741915464401,
        -0.054784536361694336,
        -0.0015059445286169648,
        -0.055388886481523514,
        0.009094207547605038,
        -0.051313094794750214,
        0.03345348313450813,
        -0.04626394063234329,
        -0.0535019226372242,
        -0.026343615725636482,
        -0.02539275959134102,
        -0.0552515834569931,
        0.05542341247200966,
        0.05529528111219406,
        0.006578426342457533,
        0.010849101468920708,
        -0.05530945584177971,
        -0.021360771730542183,
        0.05354004725813866,
        -0.03568700700998306,
        -0.04058994725346565,
        0.05540068447589874,
        -0.05507142096757889,
        0.05165761709213257,
        -0.05320635437965393,
        0.050159621983766556,
        -0.03655694052577019,
        0.020729800686240196,
        -0.009108365513384342,
        0.04955868422985077,
        -0.054509446024894714,
        -0.017742164433002472,
        -0.006751717999577522,
        -0.04217854514718056,
        0.03693912923336029,
        -0.05402841046452522,
        -0.054811399430036545,
        0.04101812094449997,
        0.04833312705159187,
        0.05008836090564728,
        -0.018100792542099953,
        0.040041323751211166,
        -0.05116472765803337,
        0.030270101502537727,
        0.052666205912828445,
        -0.05230199918150902,
        -0.05536218732595444,
        0.0553736574947834,
        -0.054824117571115494,
        0.05064339563250542,
        0.054769113659858704,
        -0.04397908225655556,
        -0.05539408326148987,
        0.04863156005740166,
        0.04950765147805214,
        -0.052364055067300797,
        0.03458821028470993,
        0.05322854965925217,
        0.02155247889459133,
        0.011887481436133385,
        -0.05250139907002449,
        0.002085811458528042,
        -0.05473918840289116,
        -0.05489156395196915,
        0.054831936955451965,
        -0.049575548619031906,
        -0.054943375289440155,
        -0.03777753934264183,
        -0.05531640723347664,
        0.00017808994743973017,
        -0.010523625649511814,
        0.04094809666275978,
        -0.04653250426054001,
        0.052708469331264496,
        -0.015296008437871933,
        -0.05025814473628998,
        -0.025033386424183846,
        0.054366741329431534,
        -0.05072198435664177,
        -0.05033880099654198,
        0.04970989376306534,
        -0.05387476831674576,
        -0.010352260433137417,
        -0.04480239003896713,
        0.031067226082086563,
        0.05533720925450325,
        0.041869305074214935,
        0.05539374053478241,
        -0.04875494912266731,
        0.03937409073114395,
        -0.03748239204287529,
        -0.03904537856578827,
        0.010849667713046074,
        0.047925498336553574,
        -0.006607783026993275,
        0.03074749745428562,
        0.025900481268763542,
        -0.05539211630821228,
        0.053361669182777405,
        -0.03667638450860977,
        0.04318678006529808,
        -0.05494636669754982,
        0.02052842639386654,
        0.0486244298517704,
        -0.01564011164009571,
        -0.047138262540102005,
        0.0034979069605469704,
        -0.031243257224559784,
        -0.005908349994570017,
        0.008443190716207027,
        0.05417373403906822,
        0.05520285293459892,
        -0.01514553464949131,
        -0.04167201742529869,
        -0.055039182305336,
        0.03323667123913765,
        -0.025455646216869354,
        0.05026007443666458,
        0.05506204441189766,
        -0.050734955817461014,
        0.011792919598519802,
        -0.05153763294219971,
        0.05523188039660454,
        0.03908395394682884,
        0.015333828516304493,
        0.005886891856789589,
        -0.05539485067129135,
        -0.047104764729738235,
        0.021150197833776474,
        0.05082850903272629,
        -0.05464622750878334,
        -0.007617973256856203,
        0.05282288044691086,
        0.04727215692400932,
        -0.04158926382660866,
        0.05065803602337837,
        -0.022181466221809387,
        -0.0007628462626598775,
        0.029449427500367165,
        0.04997839406132698,
        -0.054773058742284775,
        -0.048888061195611954,
        -0.046261440962553024,
        -0.05510566011071205,
        0.04712683707475662,
        -0.03400697186589241,
        0.05227832496166229,
        0.053592193871736526,
        0.03960432484745979,
        -0.010720656253397465,
        0.055276960134506226,
        0.03199639171361923,
        -0.04729320853948593,
        -0.03200819343328476,
        -0.028817953541874886,
        0.055058229714632034,
        0.05141469091176987,
        -0.02373340353369713,
        0.03474624454975128,
        -0.029605384916067123,
        -0.05263077840209007,
        -0.0520816296339035,
        -0.055332984775304794,
        -0.04042714461684227,
        -0.054857321083545685,
        0.02153693698346615,
        -0.0034068599343299866,
        0.054991334676742554,
        -0.028475791215896606,
        0.053715579211711884,
        -0.03383699432015419,
        -0.04202600568532944,
        0.04424984008073807,
        0.03831925988197327,
        0.053185369819402695,
        -0.053320616483688354,
        0.04712653160095215,
        0.05504818260669708,
        0.03346337378025055,
        -0.05463623255491257,
        -0.05471682548522949,
        -0.05314004793763161,
        -0.05118735507130623,
        0.05487987771630287,
        -0.05435164272785187,
        0.00981550570577383,
        0.050735220313072205,
        -0.0517570786178112,
        -0.04474090784788132,
        0.0009877508273348212,
        -0.05504385381937027,
        -0.03181402385234833,
        0.054226819425821304,
        -0.051005106419324875,
        -0.05512022599577904,
        0.05537590757012367,
        0.002390020526945591,
        0.04250088334083557,
        0.044391099363565445,
        0.028611471876502037,
        0.03414288908243179,
        -0.053212083876132965,
        -0.054181717336177826,
        -0.038577642291784286,
        -0.0427468903362751,
        0.05501258373260498,
        -0.05098196119070053,
        0.05526508390903473,
        -0.015078005380928516,
        -0.007662015967071056,
        0.0552259162068367,
        0.05110849812626839,
        -0.0024951305240392685,
        -0.041481561958789825,
        0.054380886256694794,
        -0.04533344507217407,
        -0.05207431688904762,
        -0.04409921169281006,
        -0.0215456523001194,
        0.05005164071917534,
        -0.0020074821077287197,
        -0.04666903614997864,
        -0.05518877133727074,
        0.020459827035665512,
        0.051720842719078064,
        0.04976890608668327,
        -0.052586670964956284,
        -0.05059727653861046,
        0.04312034323811531,
        0.05513615161180496,
        0.013734284788370132,
        -0.03472631424665451,
        -0.05519762635231018,
        0.03983531892299652,
        -0.05368178337812424,
        0.05189058557152748,
        0.0476883128285408,
        0.05535048991441727,
        -0.04859147593379021,
        0.051797378808259964,
        -0.05414200946688652,
        0.05217406898736954,
        0.04599987342953682,
        -0.04880204424262047,
        0.05468432977795601,
        0.052648305892944336,
        0.042959507554769516,
        -0.05524362996220589,
        0.0006825783639214933,
        -0.04494877904653549,
        0.0016777499113231897,
        -0.03161583095788956,
        -0.04888012260198593,
        0.05542372167110443,
        0.01878170482814312,
        0.027815768495202065,
        0.03319147229194641,
        0.05055707320570946,
        0.0530315525829792,
        0.05077304691076279,
        0.05498287081718445,
        0.0550573468208313,
        -0.03333287686109543,
        0.007826968096196651,
        -0.05284927412867546,
        -0.05533769354224205,
        0.008397563360631466,
        -0.054778747260570526,
        -0.04822457209229469,
        -0.054330576211214066,
        -0.055121444165706635,
        0.05080878362059593,
        0.05467872694134712,
        0.013607879169285297,
        -0.030731316655874252,
        -0.030577272176742554,
        -0.05496455356478691,
        0.05502144247293472,
        -0.03170950710773468,
        -0.03275091573596001,
        -0.049959130585193634,
        -0.025809191167354584,
        -0.011289269663393497,
        0.05471779406070709,
        -0.004870211239904165,
        0.0496324822306633,
        0.022617070004343987,
        -0.04536295682191849,
        0.033611614257097244,
        0.05343209207057953,
        0.04445170238614082,
        0.04841555655002594,
        0.054263003170490265,
        -0.05518927425146103,
        0.046781186014413834,
        0.00495969457551837,
        -0.05187232419848442,
        -0.038715753704309464,
        0.01392377633601427,
        -0.051550574600696564,
        -0.01057659462094307,
        -0.05521909520030022,
        0.05463559553027153,
        -0.040881168097257614,
        -0.039612531661987305,
        -0.035311080515384674,
        0.05465751141309738,
        0.05541267618536949,
        -0.051343582570552826,
        -0.05485532060265541,
        -0.05070850998163223,
        -0.03767969459295273,
        0.05169378221035004,
        0.0015955778071656823,
        0.039966825395822525,
        -0.04676363989710808,
        -0.0204933974891901,
        0.03788706287741661,
        -0.004871005192399025,
        0.054012179374694824,
        -0.04467327147722244,
        0.023484090343117714,
        0.02704482525587082,
        -0.04757445678114891,
        0.049670301377773285,
        -0.03921860456466675,
        -0.05213676020503044,
        0.05180006101727486,
        0.04475492984056473,
        0.05441734939813614,
        0.05503733828663826,
        0.055336255580186844,
        -0.05526955798268318,
        -0.05478444695472717,
        -0.05507943034172058,
        0.03579027205705643,
        0.04098512977361679,
        -0.045625846832990646,
        -0.01753280498087406,
        -0.05356445163488388,
        -0.04811495915055275,
        0.04905756935477257,
        0.002725285245105624,
        -0.016170263290405273,
        0.03437403216958046,
        0.042282383888959885,
        -0.05346394702792168,
        -0.0553554967045784,
        0.051236964762210846,
        -0.03486132249236107,
        -0.0005354522145353258,
        -0.05511396750807762,
        0.055133797228336334,
        0.055326759815216064,
        0.054796695709228516,
        0.05370424687862396,
        0.030714644119143486,
        0.04572608321905136,
        0.029705993831157684,
        0.04209255427122116,
        0.054603490978479385,
        0.04436485096812248,
        0.03461296483874321,
        -0.010278331115841866,
        0.05381114035844803,
        0.02424323745071888,
        -0.05522620677947998,
        -0.0014378688065335155,
        -0.03925290331244469,
        0.043641313910484314,
        -0.04376573860645294,
        -0.021974267438054085,
        -0.0554155558347702,
        0.055296704173088074,
        0.05501997470855713,
        0.052284758538007736,
        0.055288515985012054,
        -0.054645199328660965,
        0.04936779662966728,
        -0.05540774390101433,
        -0.05432946979999542,
        0.055412210524082184,
        0.021422483026981354,
        0.03790927678346634,
        0.05524469166994095,
        0.021957533434033394,
        0.02723570726811886,
        -0.05527286231517792,
        0.055287186056375504,
        0.0010987725108861923,
        -0.05020671337842941,
        0.050106462091207504,
        -0.04673682153224945,
        -0.04206448420882225,
        0.04685784876346588,
        -0.05540457367897034,
        0.05502660945057869,
        0.051849596202373505,
        0.053041405975818634,
        -0.0550093911588192,
        -0.05505148693919182,
        0.02723865583539009,
        0.0043248883448541164,
        -0.01024869829416275,
        0.04788699001073837,
        0.03244921192526817,
        0.045733463019132614,
        -0.05126740783452988,
        0.02047058381140232,
        0.046812497079372406,
        0.046952951699495316,
        -0.052657678723335266,
        -0.0465083010494709,
        -0.011047987267374992,
        -0.008677645586431026,
        0.055424027144908905,
        -0.037830453366041183,
        0.052978046238422394,
        -0.0346364825963974,
        0.004194533918052912,
        -0.0020219299476593733,
        0.04848921671509743,
        0.041934143751859665
    ],
    [
        -0.01102908980101347,
        0.0502014234662056,
        0.05947880446910858,
        -0.00008865256677381694,
        0.05752545967698097,
        -0.03229076787829399,
        0.04303792491555214,
        0.03296628221869469,
        -0.04550209268927574,
        0.03819548711180687,
        -0.02990187145769596,
        0.04705575481057167,
        -0.05826423317193985,
        0.02285430207848549,
        -0.05828556790947914,
        0.006129288114607334,
        -0.05928707867860794,
        0.015794450417160988,
        -0.046781767159700394,
        -0.05881086736917496,
        -0.055129602551460266,
        0.02312425710260868,
        0.0330209955573082,
        0.055525511503219604,
        0.041590385138988495,
        0.03305618092417717,
        0.03800998628139496,
        0.052378248423337936,
        -0.057392533868551254,
        -0.006440192461013794,
        0.05764596164226532,
        0.012246732600033283,
        -0.003039698116481304,
        -0.0567224882543087,
        0.03003590553998947,
        -0.04053591936826706,
        -0.04807867109775543,
        -0.055914346128702164,
        0.05117505043745041,
        0.009006364271044731,
        -0.028229929506778717,
        0.04630690813064575,
        0.056929945945739746,
        -0.03464847058057785,
        0.04519692808389664,
        -0.033976342529058456,
        0.01485511101782322,
        0.05727555975317955,
        0.058218155056238174,
        0.028130684047937393,
        0.05172670632600784,
        0.02143697813153267,
        0.059606701135635376,
        0.023873822763562202,
        -0.046208787709474564,
        -0.04260442033410072,
        0.009178437292575836,
        -0.04202888160943985,
        -0.00814078375697136,
        0.023371316492557526,
        -0.04413587599992752,
        -0.00813725870102644,
        -0.05035940557718277,
        -0.008024735376238823,
        -0.024077966809272766,
        0.02375873364508152,
        0.0517050102353096,
        -0.04733458161354065,
        0.024895180016756058,
        -0.05994797125458717,
        -0.05817321687936783,
        -0.05830177664756775,
        0.03986910358071327,
        0.020870519801974297,
        -0.052448779344558716,
        0.050296250730752945,
        0.04933328926563263,
        0.03386196121573448,
        0.05871851369738579,
        -0.05184720829129219,
        -0.046054091304540634,
        0.0340433344244957,
        -0.05238751694560051,
        0.006408364046365023,
        -0.055319707840681076,
        -0.03355276584625244,
        0.033145345747470856,
        0.017653264105319977,
        -0.03599964827299118,
        0.03711618110537529,
        -0.05881774425506592,
        -0.05578337982296944,
        -0.02284754440188408,
        -0.03377603366971016,
        0.029299654066562653,
        0.04088031128048897,
        -0.04501892253756523,
        -0.035238925367593765,
        -0.02356692962348461,
        0.020607762038707733,
        -0.059560783207416534,
        -0.04227790609002113,
        0.057225968688726425,
        0.059906426817178726,
        -0.024601880460977554,
        -0.03597808629274368,
        -0.044355325400829315,
        0.021761616691946983,
        0.04534713178873062,
        -0.04699135199189186,
        -0.03986206650733948,
        -0.059239838272333145,
        -0.02227194420993328,
        -0.021260689944028854,
        0.04665924981236458,
        -0.036331359297037125,
        0.022129647433757782,
        -0.05521908402442932,
        -0.05993950739502907,
        -0.04060659557580948,
        -0.04117044433951378,
        0.032918985933065414,
        -0.041974011808633804,
        0.056293416768312454,
        -0.05995435267686844,
        0.03777538985013962,
        -0.05142126977443695,
        -0.0032591489143669605,
        0.05521881580352783,
        -0.00352319423109293,
        0.04989386722445488,
        -0.03930830955505371,
        -0.04840723052620888,
        0.0076249572448432446,
        -0.050756897777318954,
        -0.05673203244805336,
        0.0549946054816246,
        -0.05479840189218521,
        0.054983001202344894,
        0.054819874465465546,
        0.028865637257695198,
        -0.012780660763382912,
        0.05211053788661957,
        0.04300032928586006,
        0.00221642036922276,
        -0.056184716522693634,
        0.054726969450712204,
        -0.02631467767059803,
        -0.05744549259543419,
        0.01894698664546013,
        -0.05602438747882843,
        -0.05903942510485649,
        -0.05892960727214813,
        0.04064380005002022,
        0.0034087125677615404,
        -0.03591036796569824,
        0.04478336498141289,
        -0.056439660489559174,
        -0.019054194912314415,
        -0.04646037891507149,
        0.060021501034498215,
        -0.0011133113875985146,
        0.049499060958623886,
        -0.03401949256658554,
        0.04440654069185257,
        -0.04587170481681824,
        -0.005934529472142458,
        -0.04914696142077446,
        -0.03180404752492905,
        0.05244601517915726,
        -0.047413699328899384,
        0.037965208292007446,
        -0.05839666724205017,
        0.015159757807850838,
        -0.05649550259113312,
        0.03795267641544342,
        -0.047118015587329865,
        0.031598467379808426,
        0.05748755857348442,
        -0.004609943367540836,
        0.05278310924768448,
        0.0595499649643898,
        0.05825917795300484,
        0.01811833679676056,
        0.015136780217289925,
        -0.02181106247007847,
        -0.058056872338056564,
        0.05858752503991127,
        -0.046248555183410645,
        -0.018470175564289093,
        -0.023808667436242104,
        -0.04814029857516289,
        -0.037302497774362564,
        -0.03544703498482704,
        -0.05916482210159302,
        0.024114184081554413,
        0.04306088015437126,
        -0.05693504959344864,
        0.04082273691892624,
        0.05703738331794739,
        0.02872135117650032,
        0.05451732128858566,
        0.036161839962005615,
        -0.05310705304145813,
        0.04437340795993805,
        -0.023438766598701477,
        0.052482448518276215,
        0.050658948719501495,
        -0.04532913863658905,
        0.034747637808322906,
        -0.04990210384130478,
        0.030957113951444626,
        -0.056552011519670486,
        -0.014798067510128021,
        -0.005796265788376331,
        -0.03677842393517494,
        0.05135037750005722,
        0.05365427955985069,
        0.05594287067651749,
        -0.042589783668518066,
        0.000852837460115552,
        -0.05256231129169464,
        -0.017181264236569405,
        -0.05987078696489334,
        0.04536527767777443,
        -0.03169222176074982,
        -0.03613431379199028,
        -0.022151727229356766,
        -0.032147228717803955,
        -0.05857180058956146,
        -0.0442524217069149,
        -0.03189951181411743,
        -0.05899994820356369,
        -0.008198694325983524,
        0.05934082344174385,
        -0.055721819400787354,
        0.05670640990138054,
        -0.043738894164562225,
        0.0211496502161026,
        0.016815215349197388,
        0.058910172432661057,
        -0.04704701155424118,
        -0.0061559779569506645,
        -0.05142873153090477,
        -0.033644095063209534,
        0.046790409833192825,
        0.04664900526404381,
        -0.047675345093011856,
        0.0008753340225666761,
        0.005281900055706501,
        0.03359351307153702,
        -0.05789286270737648,
        -0.04346557706594467,
        -0.05830350145697594,
        0.03454037383198738,
        0.0565841905772686,
        -0.017052600160241127,
        0.04492148384451866,
        0.059739623218774796,
        0.05622529983520508,
        0.05935326963663101,
        0.031122485175728798,
        0.0443827360868454,
        0.026643358170986176,
        -0.05716020613908768,
        -0.028493771329522133,
        0.03605303913354874,
        0.03823954984545708,
        0.01960890181362629,
        -0.049319665879011154,
        -0.03376256674528122,
        -0.05919891595840454,
        0.007444526068866253,
        -0.05910405144095421,
        -0.03759727627038956,
        0.049801167100667953,
        -0.05750149115920067,
        0.00038131760084070265,
        -0.011837447062134743,
        -0.05261696130037308,
        0.05839470773935318,
        0.037401266396045685,
        0.033451151102781296,
        0.020699316635727882,
        -0.04323611408472061,
        0.053704001009464264,
        0.015830103307962418,
        -0.014359415508806705,
        -0.058434631675481796,
        0.03423678129911423,
        -0.05295775458216667,
        -0.016653429716825485,
        -0.0024195269215852022,
        -0.015345201827585697,
        0.05522434413433075,
        -0.04283291473984718,
        0.05993563309311867,
        -0.05568615347146988,
        -0.05824580416083336,
        0.056377168744802475,
        -0.028842851519584656,
        0.030387165024876595,
        -0.013867933303117752,
        0.02114938199520111,
        -0.02561255171895027,
        -0.05736836791038513,
        -0.036592137068510056,
        0.02671957202255726,
        0.04666927084326744,
        -0.056042831391096115,
        0.05004442110657692,
        -0.033660560846328735,
        -0.05043373629450798,
        0.05581595003604889,
        -0.051323000341653824,
        -0.056892503052949905,
        0.04523477703332901,
        -0.046397823840379715,
        -0.008839909918606281,
        -0.04762054979801178,
        0.0015373239293694496,
        0.03261161595582962,
        0.05579497665166855,
        -0.047088805586099625,
        0.05817393586039543,
        0.05816067010164261,
        0.04201281815767288,
        -0.008277958258986473,
        -0.055063650012016296,
        -0.041009191423654556,
        -0.03805023059248924,
        0.053823161870241165,
        0.04373211786150932,
        0.031623825430870056,
        0.05940341204404831,
        -0.058068688958883286,
        0.026379358023405075,
        0.05956050008535385,
        -0.053056370466947556,
        0.04981903359293938,
        0.04257107153534889,
        -0.03638695552945137,
        -0.05541638657450676,
        -0.05272082984447479,
        0.05993998795747757,
        -0.022941511124372482,
        -0.048397693783044815,
        0.026218833401799202,
        0.028706511482596397,
        0.05276688560843468,
        -0.06006672605872154,
        0.01854007877409458,
        -0.05323150008916855,
        -0.06003398448228836,
        -0.0593116320669651,
        0.04799347743391991,
        -0.00979156605899334,
        -0.01369599997997284,
        -0.05060581490397453,
        -0.05007844418287277,
        0.058184605091810226,
        0.037447065114974976,
        -0.056026753038167953,
        -0.05304734781384468,
        -0.042398128658533096,
        -0.05672015994787216,
        0.06002656742930412,
        0.03844844922423363,
        -0.04264708608388901,
        -0.060009680688381195,
        -0.03746199235320091,
        -0.05948150157928467,
        0.05577341839671135,
        -0.05143783614039421,
        0.058039888739585876,
        0.0527140349149704,
        0.05142020806670189,
        0.05681462585926056,
        -0.03971247747540474,
        -0.05483947694301605,
        -0.03332081809639931,
        0.05913148820400238,
        -0.045779064297676086,
        0.002219187328591943,
        0.05838591977953911,
        -0.05733281001448631,
        -0.0016627877485007048,
        -0.05949801206588745,
        -0.05592616647481918,
        -0.05882546305656433,
        0.0353870615363121,
        0.05200567841529846,
        -0.02724960818886757,
        -0.008720573037862778,
        -0.05692369118332863,
        0.056972332298755646,
        0.05815698206424713,
        -0.059415291994810104,
        0.00029302723123691976,
        -0.05168898403644562,
        0.007985521107912064,
        0.048649463802576065,
        0.05100368335843086,
        0.036468278616666794,
        -0.008265150710940361,
        0.043502967804670334,
        0.01799990050494671,
        0.02543814107775688,
        0.05527757108211517,
        -0.04489884525537491,
        -0.012359583750367165,
        -0.05086290463805199,
        -0.011385444551706314,
        0.036723677068948746,
        0.056835561990737915,
        0.04645427688956261,
        0.006631661206483841,
        0.04642603173851967,
        0.030634170398116112,
        0.05261828377842903,
        -0.0540379136800766,
        0.008147954940795898,
        -0.052269186824560165,
        -0.05700458958745003,
        -0.015843089669942856,
        0.048403576016426086,
        -0.019720545038580894,
        0.003954026382416487,
        -0.05978820100426674,
        0.0052490998059511185,
        0.053060222417116165,
        -0.018697068095207214,
        0.03803645074367523,
        0.05993470177054405,
        0.04827164113521576,
        -0.010196163319051266,
        0.02928546443581581,
        0.04208344966173172,
        0.059881098568439484,
        -0.05987340211868286,
        -0.04643019288778305,
        0.03848838061094284,
        0.057692211121320724,
        0.04793407395482063,
        -0.053450535982847214,
        0.05556068196892738,
        0.05911790579557419,
        -0.0458957739174366,
        0.055238571017980576,
        -0.05982190743088722,
        -0.05364925041794777,
        0.031023139134049416,
        0.0574849508702755,
        -0.05547346547245979,
        -0.011288079433143139,
        -0.007465915288776159,
        -0.035015903413295746,
        0.04927896335721016,
        -0.03000204637646675,
        0.05630137398838997,
        0.008226877078413963,
        -0.048784542828798294,
        -0.05364690348505974,
        -0.0034608629066497087,
        0.05095406249165535,
        0.05983046069741249,
        -0.06006980314850807,
        0.05977173149585724,
        -0.050598349422216415,
        -0.059413280338048935,
        0.052445605397224426,
        0.04549822583794594,
        0.05363420769572258,
        0.05629502609372139,
        0.051738206297159195,
        0.05413176864385605,
        -0.05659131705760956,
        0.0037692615296691656,
        0.032825250178575516,
        0.053519438952207565,
        -0.057858195155858994,
        0.0304168239235878,
        0.04545494541525841,
        0.04736050218343735,
        -0.017634350806474686,
        0.0513005368411541,
        0.031247060745954514,
        -0.04441748559474945,
        -0.048090774565935135,
        -0.056905485689640045,
        -0.05613523721694946,
        -0.023355066776275635,
        0.03214374929666519,
        0.059577543288469315,
        0.04934510216116905,
        0.022685352712869644,
        -0.05968437343835831,
        0.004487774334847927,
        0.05743866413831711,
        0.015071447007358074,
        -0.05774719640612602,
        -0.05947374179959297,
        -0.050344374030828476,
        0.0500984787940979,
        0.058405738323926926,
        0.023005211725831032,
        0.05983394384384155,
        0.017255309969186783,
        -0.05913731828331947,
        -0.04423174634575844,
        0.059976305812597275,
        0.058066226541996
    ],
    [
        -0.05406193435192108,
        0.028517398983240128,
        0.041315361857414246,
        0.04211616516113281,
        0.01738245040178299,
        -0.04485103115439415,
        0.05392904952168465,
        -0.05304507166147232,
        0.04589071869850159,
        -0.0538720041513443,
        0.053840577602386475,
        0.05431803688406944,
        -0.05434037744998932,
        0.046845775097608566,
        -0.05330272391438484,
        -0.00740077206864953,
        -0.046794116497039795,
        0.054435256868600845,
        -0.029873806983232498,
        -0.05155184492468834,
        -0.05351436883211136,
        0.054373450577259064,
        0.05445048585534096,
        0.05437866598367691,
        -0.0445164293050766,
        0.01590271107852459,
        0.04706784337759018,
        0.05389438569545746,
        -0.05449594184756279,
        -0.03646911680698395,
        0.047025568783283234,
        0.053544674068689346,
        0.010894225910305977,
        -0.054485078901052475,
        0.0504562184214592,
        0.002193445572629571,
        -0.052887000143527985,
        -0.049567073583602905,
        0.03038347326219082,
        -0.04897455498576164,
        0.049021173268556595,
        0.04604687541723251,
        0.05172392353415489,
        0.04926805943250656,
        0.04853534698486328,
        -0.05355917662382126,
        -0.05439772456884384,
        0.05341401696205139,
        0.054505184292793274,
        -0.05353137105703354,
        0.051386892795562744,
        0.05069568008184433,
        0.04782338812947273,
        -0.05372678115963936,
        0.02702164836227894,
        0.008863386698067188,
        -0.0408891998231411,
        -0.051577579230070114,
        -0.0484306775033474,
        -0.05239913612604141,
        -0.048515792936086655,
        0.039555471390485764,
        -0.04462761804461479,
        -0.042068831622600555,
        0.03409139811992645,
        -0.0256012175232172,
        -0.04951448738574982,
        -0.0017246906645596027,
        0.05321712791919708,
        0.01570841856300831,
        0.03510267287492752,
        -0.04792867600917816,
        0.05254262313246727,
        0.05394807830452919,
        0.05417002737522125,
        0.03675629943609238,
        -0.0034820816945284605,
        0.05455441027879715,
        0.054068759083747864,
        -0.04868697375059128,
        -0.051492609083652496,
        -0.05232508108019829,
        -0.05450810119509697,
        0.051708970218896866,
        -0.054439570754766464,
        -0.004827940370887518,
        0.0483761690557003,
        0.05050567165017128,
        0.042908795177936554,
        0.019965823739767075,
        -0.04211752489209175,
        -0.052883051335811615,
        -0.05442193150520325,
        0.040400370955467224,
        -0.023087795823812485,
        0.05311334878206253,
        0.02424822561442852,
        -0.005278897471725941,
        -0.053956661373376846,
        0.022231167182326317,
        -0.05438893288373947,
        0.054275497794151306,
        0.054027050733566284,
        0.017956692725419998,
        0.00874565914273262,
        -0.05446537584066391,
        0.03179023042321205,
        0.054291676729917526,
        0.05236791446805,
        0.04676821827888489,
        -0.05298255756497383,
        -0.047541145235300064,
        0.007605975493788719,
        -0.006494474597275257,
        0.05418933182954788,
        0.04842185974121094,
        0.0018108916701748967,
        -0.05225655063986778,
        -0.05386614054441452,
        -0.05446191132068634,
        0.053512055426836014,
        -0.05200027674436569,
        0.054361823946237564,
        -0.045588523149490356,
        -0.01777079701423645,
        0.051681820303201675,
        -0.02223419025540352,
        0.05434839427471161,
        0.047303225845098495,
        0.05422135442495346,
        -0.03515457361936569,
        -0.052005864679813385,
        -0.03838067129254341,
        0.05211261659860611,
        0.003324522404000163,
        -0.04729944095015526,
        0.05381591245532036,
        0.04419447109103203,
        0.010971459560096264,
        0.05261266231536865,
        0.009985433891415596,
        -0.02758563496172428,
        0.04449877515435219,
        0.05262027680873871,
        -0.0543438158929348,
        -0.0366675890982151,
        -0.04101993888616562,
        0.05132674798369408,
        -0.054323554039001465,
        0.019172320142388344,
        -0.042757049202919006,
        -0.05429554358124733,
        -0.04587317258119583,
        0.05440634489059448,
        0.037784457206726074,
        -0.05446593090891838,
        -0.053501103073358536,
        -0.0544276237487793,
        0.04626793414354324,
        -0.0545034259557724,
        0.054504528641700745,
        0.05437127500772476,
        0.05178782343864441,
        -0.032631367444992065,
        0.015098781324923038,
        -0.036817725747823715,
        -0.011162171140313148,
        -0.04012380540370941,
        0.034339625388383865,
        0.04222823306918144,
        -0.05292140319943428,
        0.0519062802195549,
        -0.0545363575220108,
        0.04209378734230995,
        0.031854454427957535,
        -0.014021606184542179,
        0.040472984313964844,
        -0.006317745894193649,
        0.05311072990298271,
        -0.04227824881672859,
        -0.04023024067282677,
        0.05384529381990433,
        -0.009343806654214859,
        -0.05140092968940735,
        0.0446028970181942,
        0.05447077751159668,
        -0.054499395191669464,
        0.05387749522924423,
        -0.05250173434615135,
        0.02447572909295559,
        -0.054417796432971954,
        -0.0014496758813038468,
        -0.01885026879608631,
        0.05063113942742348,
        -0.05332734063267708,
        -0.0036611096002161503,
        -0.04636789485812187,
        -0.051091961562633514,
        -0.029928890988230705,
        0.03703110292553902,
        0.054218798875808716,
        0.053484957665205,
        -0.049945708364248276,
        -0.05195969343185425,
        0.05443405359983444,
        -0.03734045475721359,
        0.048687417060136795,
        0.052484285086393356,
        -0.05440156161785126,
        0.05440657213330269,
        -0.054180946201086044,
        0.024600138887763023,
        -0.043060414493083954,
        0.0490386076271534,
        -0.0540187731385231,
        -0.054376162588596344,
        0.05293099954724312,
        -0.013917097821831703,
        0.05424417182803154,
        -0.02872636914253235,
        -0.04224324971437454,
        0.051365382969379425,
        0.02149234339594841,
        -0.05440289527177811,
        -0.05069781839847565,
        -0.05115945637226105,
        0.05388106405735016,
        0.052925657480955124,
        -0.03359382227063179,
        -0.04041513428092003,
        -0.016610797494649887,
        0.03599986433982849,
        -0.05209171026945114,
        0.04990799352526665,
        0.054194554686546326,
        0.05394893139600754,
        0.03620705008506775,
        -0.0400397926568985,
        0.026590242981910706,
        0.05454796552658081,
        0.04841265082359314,
        -0.022966701537370682,
        -0.028916548937559128,
        -0.05315791815519333,
        -0.01460597850382328,
        0.04754217341542244,
        -0.017177127301692963,
        -0.04354514926671982,
        -0.04521799832582474,
        -0.022375958040356636,
        0.02208670601248741,
        -0.03134770691394806,
        -0.022647785022854805,
        -0.05331912636756897,
        0.053426045924425125,
        0.0451405830681324,
        -0.054479025304317474,
        -0.03412022069096565,
        0.03360000625252724,
        -0.049558527767658234,
        0.02649392932653427,
        0.00840036105364561,
        -0.052270080894231796,
        0.05423421412706375,
        -0.05388486385345459,
        0.050329480320215225,
        0.05430063232779503,
        0.05275637283921242,
        -0.053410690277814865,
        0.0350833386182785,
        0.01682385243475437,
        -0.050124555826187134,
        -0.0185108445584774,
        -0.052340876311063766,
        -0.03414469212293625,
        -0.010321277193725109,
        -0.016072679311037064,
        0.005957907065749168,
        0.012193270027637482,
        -0.04716043546795845,
        -0.04603556543588638,
        0.051891278475522995,
        0.04862014204263687,
        -0.052097152918577194,
        0.004018591251224279,
        -0.002541395602747798,
        0.04558940604329109,
        0.04652228578925133,
        -0.048073235899209976,
        0.041480060666799545,
        -0.05326957628130913,
        0.02919117547571659,
        0.030605623498558998,
        0.04962628334760666,
        0.05267489328980446,
        -0.049051497131586075,
        0.054547008126974106,
        0.008527876809239388,
        -0.01870696246623993,
        0.032261621206998825,
        0.052011165767908096,
        0.04158177599310875,
        0.019722215831279755,
        0.04648372903466225,
        0.051218580454587936,
        -0.05397678539156914,
        0.041498277336359024,
        0.03454058989882469,
        0.028034981340169907,
        -0.0381024032831192,
        -0.054455094039440155,
        -0.05264683812856674,
        -0.03287409991025925,
        -0.05383949354290962,
        -0.04981175810098648,
        -0.05026901513338089,
        -0.04802652820944786,
        -0.05422878637909889,
        0.052829544991254807,
        0.027549786493182182,
        0.04988207668066025,
        0.01410745270550251,
        0.04894409328699112,
        -0.044076815247535706,
        0.052159104496240616,
        0.02985430881381035,
        0.05362599715590477,
        0.02865857072174549,
        -0.054050102829933167,
        -0.05174057558178902,
        0.05423036590218544,
        -0.04567881301045418,
        -0.04999971389770508,
        0.05448409169912338,
        0.03979940712451935,
        0.03777681663632393,
        -0.053934961557388306,
        0.01571798324584961,
        -0.039206501096487045,
        -0.015345006249845028,
        -0.049531176686286926,
        -0.034145381301641464,
        0.054539117962121964,
        0.05139137804508209,
        0.017705976963043213,
        0.0435987152159214,
        -0.00746924290433526,
        0.048487063497304916,
        0.049456868320703506,
        0.04198673740029335,
        0.05435709282755852,
        0.029047254472970963,
        0.05008697509765625,
        -0.04157581180334091,
        -0.054348789155483246,
        0.00767219252884388,
        -0.05422740802168846,
        -0.05051639303565025,
        -0.04956699162721634,
        -0.04933936893939972,
        0.040056776255369186,
        0.051341522485017776,
        -0.051072075963020325,
        -0.0498451292514801,
        -0.054266590625047684,
        -0.030353160575032234,
        0.053861383348703384,
        0.048540253192186356,
        -0.05335303395986557,
        -0.03850303217768669,
        -0.012044079601764679,
        -0.038766469806432724,
        -0.05290655791759491,
        -0.05449507012963295,
        0.05206318572163582,
        0.05402068793773651,
        0.037089064717292786,
        0.053269315510988235,
        0.05430736020207405,
        -0.029438374564051628,
        -0.029009297490119934,
        0.0545479878783226,
        -0.045527100563049316,
        0.0543423593044281,
        0.04399799928069115,
        -0.051927074790000916,
        0.05160452798008919,
        -0.054325807839632034,
        -0.025313330814242363,
        -0.053815606981515884,
        -0.04393883794546127,
        -0.05104217305779457,
        -0.05427630990743637,
        -0.014132664538919926,
        -0.040097352117300034,
        0.054263994097709656,
        0.054182663559913635,
        -0.05386101454496384,
        -0.050936248153448105,
        -0.054335545748472214,
        -0.03256874904036522,
        0.054393455386161804,
        0.05197426676750183,
        0.042310867458581924,
        -0.035083506256341934,
        0.01790362223982811,
        0.038761164993047714,
        -0.05385662615299225,
        0.050083428621292114,
        0.03410880267620087,
        -0.03521747887134552,
        -0.052965596318244934,
        -0.002771536121144891,
        0.043217334896326065,
        0.053388092666864395,
        -0.054389722645282745,
        -0.04526955261826515,
        0.04639515280723572,
        0.054073259234428406,
        0.03326454013586044,
        0.020800508558750153,
        0.04436466842889786,
        0.046019647270441055,
        -0.05451780930161476,
        0.0481237918138504,
        -0.02339273691177368,
        -0.04400397464632988,
        0.0511208213865757,
        -0.002780413255095482,
        -0.016201620921492577,
        -0.028428252786397934,
        -0.03363874554634094,
        0.04455641284584999,
        0.054456185549497604,
        -0.04588088393211365,
        -0.051463156938552856,
        -0.052554551512002945,
        0.05406559631228447,
        0.03633060306310654,
        -0.05342748388648033,
        -0.0544334277510643,
        0.038833752274513245,
        0.05446343868970871,
        0.05269978567957878,
        -0.05186646804213524,
        0.054525647312402725,
        0.054399553686380386,
        0.051789917051792145,
        0.004678233060985804,
        0.040687862783670425,
        0.0065619442611932755,
        -0.04690992459654808,
        -0.025945091620087624,
        0.016004031524062157,
        0.0028047494124621153,
        -0.04824439808726311,
        0.023625701665878296,
        -0.011447441764175892,
        0.03778262063860893,
        0.015849417075514793,
        0.05385187268257141,
        -0.05383651331067085,
        -0.007019423879683018,
        0.03598584234714508,
        0.0516141913831234,
        0.054329562932252884,
        -0.05339951440691948,
        0.05440184101462364,
        0.032934918999671936,
        -0.0543321892619133,
        0.054532941430807114,
        -0.03200531378388405,
        0.053683001548051834,
        0.05251244455575943,
        -0.04854440316557884,
        0.025557542219758034,
        -0.026111021637916565,
        0.05386447533965111,
        -0.029890669509768486,
        0.013391239568591118,
        0.004161187447607517,
        -0.04085458815097809,
        -0.04838826134800911,
        0.007990886457264423,
        -0.054496053606271744,
        0.05417061224579811,
        -0.02616952173411846,
        0.051673103123903275,
        -0.05371776968240738,
        -0.011807636357843876,
        -0.04204753786325455,
        0.04851903021335602,
        0.05446327105164528,
        0.054177019745111465,
        0.013781211338937283,
        0.04189031943678856,
        -0.0542072169482708,
        -0.024193942546844482,
        -0.0037410035729408264,
        0.03144094720482826,
        0.035052694380283356,
        0.024482188746333122,
        -0.04789941385388374,
        0.05454643815755844,
        0.05223409831523895,
        0.04656597226858139,
        0.05438835918903351,
        -0.05244231969118118,
        0.05362406745553017,
        -0.045804910361766815,
        0.05451691150665283,
        -0.014226426370441914
    ],
    [
        0.025929808616638184,
        0.0473858006298542,
        0.05306868627667427,
        -0.04812667891383171,
        0.002380350837484002,
        0.022530443966388702,
        0.05275280028581619,
        0.037356410175561905,
        0.037729475647211075,
        -0.05303459241986275,
        -0.05297653004527092,
        -0.04731435328722,
        -0.05293862894177437,
        0.053116749972105026,
        -0.05166080221533775,
        0.052971694618463516,
        -0.03450504690408707,
        0.05311853438615799,
        -0.005533704999834299,
        -0.05313316732645035,
        -0.04470551013946533,
        0.01613645814359188,
        0.050929076969623566,
        0.053095001727342606,
        -0.042346127331256866,
        0.03219287842512131,
        -0.05312706157565117,
        -0.0515584871172905,
        -0.05226781964302063,
        0.03804226592183113,
        0.05060527101159096,
        -0.043422747403383255,
        -0.04700147360563278,
        -0.04448160529136658,
        -0.031753674149513245,
        -0.017477650195360184,
        -0.04756608605384827,
        -0.05076256021857262,
        0.0457494780421257,
        -0.0052354056388139725,
        0.03911774605512619,
        -0.03966469317674637,
        -0.03865712508559227,
        -0.05289622023701668,
        0.014546124264597893,
        0.0484013557434082,
        -0.049687180668115616,
        0.05170537158846855,
        0.006446779239922762,
        -0.03520585224032402,
        0.0473693422973156,
        0.053006526082754135,
        -0.047925569117069244,
        -0.05300191417336464,
        -0.0028462265618145466,
        -0.03324112668633461,
        0.05313342064619064,
        0.019352957606315613,
        -0.04896785691380501,
        -0.05313154682517052,
        -0.052945129573345184,
        -0.04831397533416748,
        -0.052933499217033386,
        0.04466613009572029,
        -0.027372078970074654,
        -0.04521156847476959,
        -0.046982619911432266,
        -0.039665549993515015,
        -0.03063839301466942,
        0.05296334624290466,
        0.04929015040397644,
        -0.05313234031200409,
        0.053125087171792984,
        0.03894396871328354,
        0.052895184606313705,
        0.031724151223897934,
        -0.041228003799915314,
        0.05311524122953415,
        -0.04258435592055321,
        0.009286587126553059,
        -0.04626989737153053,
        -0.05307730659842491,
        0.032315272837877274,
        -0.013586940243840218,
        -0.05297686159610748,
        0.05229422077536583,
        -0.053093425929546356,
        -0.030719652771949768,
        0.05312470346689224,
        -0.05040896311402321,
        -0.042337480932474136,
        -0.050732772797346115,
        -0.037500299513339996,
        0.05195602402091026,
        -0.0011840485967695713,
        0.04765595868229866,
        -0.004822887480258942,
        -0.04226377606391907,
        0.053127579391002655,
        -0.023309826850891113,
        -0.0530611015856266,
        0.020066866651177406,
        0.05276089906692505,
        0.029808197170495987,
        -0.05311637744307518,
        -0.05117740482091904,
        0.014551155269145966,
        0.01655065454542637,
        -0.05146714672446251,
        0.05254160612821579,
        -0.03376968950033188,
        -0.047500673681497574,
        -0.04232548922300339,
        0.011759670451283455,
        -0.05227792635560036,
        0.05312807485461235,
        0.053001563996076584,
        -0.00699002668261528,
        0.05277213454246521,
        -0.04198399931192398,
        -0.05010300874710083,
        0.05003511160612106,
        -0.017271913588047028,
        0.053006891161203384,
        0.005867566913366318,
        -0.04089052975177765,
        0.028585216030478477,
        -0.053133394569158554,
        0.04759920760989189,
        -0.050643179565668106,
        0.05209754407405853,
        -0.05229310691356659,
        -0.024406546726822853,
        -0.05068650096654892,
        0.052795883268117905,
        -0.05308551713824272,
        0.03335423395037651,
        0.05305864289402962,
        0.0531192310154438,
        0.05310988798737526,
        0.0530887097120285,
        -0.0014590485952794552,
        0.043438080698251724,
        -0.05203091725707054,
        0.053016647696495056,
        -0.035859785974025726,
        0.03821013122797012,
        0.053130876272916794,
        -0.05312332510948181,
        -0.031297773122787476,
        0.052473198622465134,
        -0.053098127245903015,
        -0.021117407828569412,
        -0.052320774644613266,
        0.03767669200897217,
        -0.044527966529130936,
        -0.02374468557536602,
        -0.03394276276230812,
        0.05310925468802452,
        -0.052819691598415375,
        0.05296113342046738,
        -0.05086030438542366,
        0.05262363702058792,
        -0.03765759989619255,
        0.04752371832728386,
        -0.05254943296313286,
        -0.04561426490545273,
        0.03996701166033745,
        0.05267900973558426,
        0.048960961401462555,
        0.04356313869357109,
        0.05310995504260063,
        -0.05312366783618927,
        -0.053128622472286224,
        -0.0521264374256134,
        -0.04526489973068237,
        0.053046755492687225,
        -0.029781246557831764,
        0.05274983122944832,
        -0.03674736246466637,
        0.043986186385154724,
        0.0529930479824543,
        0.02713913284242153,
        -0.009322446770966053,
        0.04487578570842743,
        -0.04576229676604271,
        -0.052816420793533325,
        0.011355890892446041,
        -0.0494823157787323,
        0.025541186332702637,
        -0.030664458870887756,
        -0.006729891989380121,
        -0.035487063229084015,
        0.03545713052153587,
        -0.052887290716171265,
        0.03149338811635971,
        0.03843314200639725,
        -0.05225887522101402,
        -0.04806848242878914,
        0.05298157036304474,
        0.042613133788108826,
        0.005047420039772987,
        0.026593903079628944,
        -0.053128644824028015,
        0.05312574282288551,
        -0.0517214760184288,
        0.05228831246495247,
        0.05312914773821831,
        0.04363572970032692,
        0.04833697900176048,
        -0.04229586198925972,
        -0.04930393770337105,
        -0.051376063376665115,
        0.020879533141851425,
        -0.05022154375910759,
        -0.04914894700050354,
        -0.04213837534189224,
        0.04902220144867897,
        -0.0399356372654438,
        0.05313238129019737,
        0.05295242741703987,
        0.052903350442647934,
        0.03705991059541702,
        -0.013544753193855286,
        0.028038950636982918,
        0.041897453367710114,
        0.04272986575961113,
        -0.05283244326710701,
        0.008829817175865173,
        0.00808289460837841,
        0.02530571259558201,
        0.052977416664361954,
        -0.04196542501449585,
        -0.021079128608107567,
        0.05313323065638542,
        0.039525486528873444,
        0.052729979157447815,
        0.052261561155319214,
        0.017718691378831863,
        0.05310048907995224,
        -0.009238914586603642,
        -0.04958197474479675,
        -0.052533138543367386,
        -0.045003633946180344,
        -0.025004804134368896,
        -0.01908789947628975,
        0.052939120680093765,
        0.019540583714842796,
        -0.004970806650817394,
        -0.04181179031729698,
        -0.012961278669536114,
        -0.04661591351032257,
        -0.029507718980312347,
        -0.05080896243453026,
        0.024639161303639412,
        0.005609442479908466,
        0.05313142016530037,
        -0.041240282356739044,
        0.04539963975548744,
        0.052747517824172974,
        -0.05312478542327881,
        -0.05297865718603134,
        -0.03838371858000755,
        -0.052518293261528015,
        0.05027502775192261,
        0.03556998074054718,
        -0.04467243701219559,
        -0.04063817486166954,
        -0.007654156535863876,
        -0.05308010056614876,
        -0.03907283768057823,
        0.05160033702850342,
        0.052971452474594116,
        0.047210607677698135,
        -0.04859042540192604,
        0.014297127723693848,
        -0.003742115804925561,
        0.05255388468503952,
        0.051178112626075745,
        0.05307081714272499,
        -0.05303666368126869,
        -0.052601397037506104,
        -0.05313285067677498,
        -0.04729166254401207,
        -0.05288248881697655,
        0.02914315275847912,
        0.05312434211373329,
        0.048722345381975174,
        0.04238596931099892,
        0.03167136013507843,
        0.05226504057645798,
        0.052615754306316376,
        -0.012978329323232174,
        0.03038601018488407,
        0.05039910227060318,
        -0.05253903567790985,
        0.05143163353204727,
        -0.01847887597978115,
        -0.0531001016497612,
        0.04973501339554787,
        0.0429796539247036,
        0.002899294486269355,
        -0.014831213280558586,
        0.04407728090882301,
        0.05265586078166962,
        -0.05282261222600937,
        -0.04444648325443268,
        -0.00538389477878809,
        0.002593378536403179,
        0.043775033205747604,
        -0.0528973713517189,
        0.045614808797836304,
        -0.052511222660541534,
        -0.050580885261297226,
        0.052301615476608276,
        0.0002744307857938111,
        -0.051001954823732376,
        0.05239207670092583,
        0.05106225237250328,
        -0.05093809962272644,
        0.048491641879081726,
        -0.03465818613767624,
        -0.05192169174551964,
        0.053132735192775726,
        0.05311690643429756,
        0.006180612836033106,
        -0.049935609102249146,
        -0.05085250362753868,
        -0.0500594824552536,
        0.05235957354307175,
        -0.05042063444852829,
        -0.05130719020962715,
        0.03955049812793732,
        -0.015895817428827286,
        0.024772441014647484,
        -0.05313315615057945,
        -0.043610792607069016,
        0.04741460829973221,
        -0.04952464997768402,
        0.05313339829444885,
        -0.049753911793231964,
        0.05302382633090019,
        0.04137694463133812,
        0.04678214341402054,
        -0.05125781148672104,
        0.009968033991754055,
        0.02992130257189274,
        0.04143114387989044,
        -0.047860611230134964,
        0.04948469623923302,
        -0.04923713952302933,
        0.0525808185338974,
        -0.022146619856357574,
        -0.047022197395563126,
        -0.05232793837785721,
        -0.02639160118997097,
        0.05082785710692406,
        -0.05253800004720688,
        0.05306445062160492,
        -0.05281975120306015,
        0.03994699567556381,
        0.052214689552783966,
        0.04360664263367653,
        -0.040925007313489914,
        0.04938744753599167,
        -0.04890783503651619,
        0.048618998378515244,
        -0.03454875200986862,
        0.005677953362464905,
        -0.052651550620794296,
        -0.04997225105762482,
        -0.04738134518265724,
        0.04513926059007645,
        -0.023575181141495705,
        0.045722004026174545,
        -0.04803986847400665,
        0.005068687256425619,
        0.02952493727207184,
        -0.012070722877979279,
        0.052423957735300064,
        -0.05035184696316719,
        -0.03872779384255409,
        -0.011962796561419964,
        -0.02334599755704403,
        0.05313335359096527,
        -0.019965162500739098,
        -0.04732055217027664,
        -0.05236439034342766,
        -0.0528494268655777,
        -0.050060417503118515,
        -0.052319660782814026,
        -0.041785482317209244,
        -0.051007311791181564,
        -0.05313027650117874,
        -0.05273905768990517,
        0.05276735499501228,
        0.04013443738222122,
        0.01199727039784193,
        -0.048502855002880096,
        -0.03041287511587143,
        -0.022719809785485268,
        0.053131140768527985,
        -0.0530707985162735,
        -0.05293270945549011,
        0.02634742669761181,
        0.053132154047489166,
        0.041440173983573914,
        -0.052440058439970016,
        0.052658598870038986,
        -0.05213282257318497,
        -0.05308316648006439,
        -0.052836667746305466,
        0.05312134698033333,
        0.05301041901111603,
        0.053120940923690796,
        0.03237482160329819,
        0.033644288778305054,
        0.052833378314971924,
        -0.030541952699422836,
        0.05309911444783211,
        0.052805762737989426,
        -0.04032667353749275,
        0.053088560700416565,
        -0.05298870801925659,
        -0.050163932144641876,
        -0.052950579673051834,
        -0.052769068628549576,
        0.052686989307403564,
        -0.010670958086848259,
        -0.0522627979516983,
        0.053133171051740646,
        -0.022932615131139755,
        0.014085402712225914,
        0.05310771241784096,
        0.04937339574098587,
        -0.04333164542913437,
        -0.05304019898176193,
        -0.04273815453052521,
        0.053089722990989685,
        0.022365238517522812,
        -0.052832599729299545,
        0.0404624342918396,
        0.05259610339999199,
        0.04640873521566391,
        -0.05163151025772095,
        0.04886051267385483,
        0.05151797831058502,
        -0.007856243290007114,
        0.03243473917245865,
        0.053026143461465836,
        -0.02049178257584572,
        -0.0031312103383243084,
        0.05258401483297348,
        -0.05303115025162697,
        -0.05264503136277199,
        -0.05220123752951622,
        0.0036361515522003174,
        -0.0008013324113562703,
        0.043805353343486786,
        0.04685869440436363,
        0.051325153559446335,
        0.053121499717235565,
        0.0331016406416893,
        0.053133316338062286,
        0.05310741811990738,
        0.053111203014850616,
        -0.040768224745988846,
        0.05312687158584595,
        -0.01786118932068348,
        -0.051792312413454056,
        0.053109221160411835,
        0.04762374237179756,
        0.05114429444074631,
        0.032485753297805786,
        0.04291217401623726,
        0.0008096419041976333,
        0.05262235179543495,
        -0.020045442506670952,
        0.052355341613292694,
        0.04953233152627945,
        -0.05051451176404953,
        -0.04052872583270073,
        0.0526055209338665,
        0.0447583943605423,
        -0.053106889128685,
        0.01767842285335064,
        0.04921652004122734,
        0.032916609197854996,
        -0.05309120565652847,
        0.03176002576947212,
        0.03200739249587059,
        0.05307406932115555,
        0.053094591945409775,
        0.05310778319835663,
        -0.052065540105104446,
        0.05289696529507637,
        -0.05313340947031975,
        -0.04082973673939705,
        0.05126647651195526,
        -0.05305752158164978,
        -0.010535361245274544,
        -0.0528755709528923,
        -0.05199733003973961,
        0.053046368062496185,
        0.010339660570025444,
        0.006471247412264347,
        0.05313342064619064,
        -0.028676945716142654,
        0.0530378520488739,
        -0.05293077975511551,
        0.05073512718081474,
        0.053133029490709305
    ],
    [
        -0.04709625244140625,
        0.005910386331379414,
        0.06217106804251671,
        0.031965695321559906,
        0.062405411154031754,
        0.0005149115459062159,
        0.06338619440793991,
        0.025328602641820908,
        -0.04747899994254112,
        0.06036098673939705,
        0.044987235218286514,
        0.024036243557929993,
        -0.0535515658557415,
        0.027496811002492905,
        -0.06053781881928444,
        -0.007850179448723793,
        0.0039977445267140865,
        0.06272569298744202,
        -0.046345923095941544,
        -0.017687158659100533,
        -0.04691402241587639,
        0.007138367276638746,
        0.010332547128200531,
        -0.0030968794599175453,
        0.00892077200114727,
        -0.0054839844815433025,
        -0.035566482692956924,
        0.035055894404649734,
        -0.012995298020541668,
        -0.03484327718615532,
        0.03825420141220093,
        0.03993593901395798,
        -0.026762790977954865,
        -0.049825519323349,
        0.044113144278526306,
        -0.053445324301719666,
        -0.058950986713171005,
        -0.03675288334488869,
        0.014062264934182167,
        0.018183544278144836,
        -0.010372433811426163,
        0.003231900045648217,
        0.05846114084124565,
        -0.024710742756724358,
        0.0400751456618309,
        0.05078498646616936,
        -0.019668137654662132,
        0.061454709619283676,
        0.061628226190805435,
        0.03673473745584488,
        0.06283678859472275,
        0.019912639632821083,
        0.06338813155889511,
        -0.04647206515073776,
        0.013701818883419037,
        -0.04626699537038803,
        0.05428575351834297,
        -0.055088404566049576,
        -0.054354630410671234,
        -0.00021614985598716885,
        -0.05325315520167351,
        -0.00380126154050231,
        -0.05730883404612541,
        -0.03741377592086792,
        0.013290595263242722,
        0.010149162262678146,
        0.024624645709991455,
        0.004443565849214792,
        0.022327594459056854,
        -0.06377625465393066,
        -0.05799948051571846,
        -0.057122472673654556,
        0.04530877619981766,
        -0.01427230890840292,
        -0.04616807773709297,
        0.017772532999515533,
        0.0353483222424984,
        0.062391962856054306,
        0.05471865087747574,
        -0.0191415473818779,
        -0.02883797138929367,
        -0.024439001455903053,
        -0.059217292815446854,
        0.04422083497047424,
        -0.05517635866999626,
        0.039332661777734756,
        0.008030515164136887,
        -0.038759369403123856,
        -0.04091152548789978,
        0.056646812707185745,
        -0.05004343390464783,
        -0.04937098175287247,
        0.031536877155303955,
        -0.05648766830563545,
        0.02889520861208439,
        0.014765354804694653,
        -0.042294424027204514,
        -0.06108194217085838,
        -0.009559799917042255,
        0.0025162824895232916,
        -0.06318192929029465,
        -0.045094992965459824,
        0.060718316584825516,
        0.06093614175915718,
        0.015705203637480736,
        -0.06333498656749725,
        -0.062093980610370636,
        0.032877590507268906,
        -0.036877140402793884,
        -0.05556651949882507,
        -0.024147259071469307,
        -0.0607636384665966,
        -0.04372309893369675,
        -0.010382112115621567,
        0.058484915643930435,
        0.05007641389966011,
        0.046560294926166534,
        -0.04437083378434181,
        -0.052335839718580246,
        -0.002712160348892212,
        -0.05388635769486427,
        -0.009969636797904968,
        -0.03925405442714691,
        0.05995547026395798,
        -0.06292617321014404,
        0.040105175226926804,
        0.0026140897534787655,
        0.013874618336558342,
        0.04500166326761246,
        0.051308948546648026,
        0.059824395924806595,
        -0.03303080424666405,
        -0.023246563971042633,
        0.00966562982648611,
        -0.057596657425165176,
        -0.0573103204369545,
        0.048312265425920486,
        -0.06266877800226212,
        0.0536532886326313,
        0.06044880673289299,
        -0.011890865862369537,
        -0.04820253327488899,
        0.042638227343559265,
        0.05872272327542305,
        -0.05181831493973732,
        -0.045994196087121964,
        0.0502268448472023,
        -0.059773948043584824,
        -0.028224103152751923,
        -0.03570452705025673,
        -0.05680235102772713,
        -0.06295791268348694,
        -0.05090826377272606,
        -0.04207225516438484,
        0.03713585436344147,
        -0.05479876324534416,
        0.05489993467926979,
        -0.0014076881343498826,
        0.04108746722340584,
        -0.0600588396191597,
        0.061018820852041245,
        0.03014024719595909,
        0.06015317142009735,
        -0.025520965456962585,
        0.016687314957380295,
        -0.03151204064488411,
        -0.020917441695928574,
        -0.03418690711259842,
        -0.017562955617904663,
        0.05231412127614021,
        -0.056038226932287216,
        0.04665646702051163,
        -0.061968982219696045,
        0.006546426098793745,
        -0.04568898305296898,
        0.009107421152293682,
        -0.043490540236234665,
        0.0010551244486123323,
        0.06167450547218323,
        0.02176021598279476,
        0.03205528482794762,
        0.0571390725672245,
        0.0346686989068985,
        -0.004841031972318888,
        -0.05164174735546112,
        -0.06053067743778229,
        -0.031339652836322784,
        0.06157035380601883,
        -0.0002970295608974993,
        -0.033908773213624954,
        0.008016150444746017,
        0.03033554181456566,
        -0.04907442629337311,
        -0.027286019176244736,
        -0.05310804396867752,
        0.04845578968524933,
        0.0621936097741127,
        -0.029661165550351143,
        -0.02920583263039589,
        0.05727477744221687,
        0.0629635900259018,
        0.055239055305719376,
        0.04835791140794754,
        -0.009752333164215088,
        0.027061959728598595,
        0.012905916199088097,
        0.05307731032371521,
        0.020866574719548225,
        -0.02728898823261261,
        0.05917754024267197,
        -0.061181385070085526,
        -0.059742994606494904,
        -0.013674667105078697,
        0.034416429698467255,
        0.034849025309085846,
        -0.03444519639015198,
        0.028533700853586197,
        -0.010249938815832138,
        0.059908293187618256,
        -0.012479817494750023,
        0.03760329633951187,
        -0.03977743536233902,
        -0.03438415378332138,
        -0.06338801234960556,
        0.027414673939347267,
        -0.055072490125894547,
        0.015513498336076736,
        -0.027890006080269814,
        0.05050412565469742,
        -0.06224977970123291,
        0.007856477983295918,
        0.022981759160757065,
        -0.06002486124634743,
        0.042027492076158524,
        0.06331176310777664,
        0.017805887386202812,
        0.06006889417767525,
        0.04228359833359718,
        -0.02085888385772705,
        0.05972937494516373,
        0.057254429906606674,
        -0.061231568455696106,
        0.0018014400266110897,
        -0.029735354706645012,
        0.033347781747579575,
        0.04147511348128319,
        0.06109313294291496,
        0.033655229955911636,
        -0.054236020892858505,
        -0.008892720565199852,
        0.0414111502468586,
        -0.0606410875916481,
        -0.05841776728630066,
        -0.060500338673591614,
        0.04112480208277702,
        0.056174349039793015,
        0.03542457893490791,
        -0.015856405720114708,
        0.06281837075948715,
        0.03369913622736931,
        0.03302038088440895,
        0.018260467797517776,
        0.058278270065784454,
        -0.0629255399107933,
        -0.03504052758216858,
        0.0592554546892643,
        0.02722993679344654,
        0.012749274261295795,
        0.0009346015867777169,
        -0.050672728568315506,
        0.034738343209028244,
        -0.05847546458244324,
        0.05477828532457352,
        -0.027435513213276863,
        -0.034239090979099274,
        0.0501195527613163,
        -0.061975374817848206,
        0.0380534827709198,
        0.02846657857298851,
        -0.03439097851514816,
        -0.058686114847660065,
        0.03573518246412277,
        -0.03947821259498596,
        -0.02149214595556259,
        -0.024870753288269043,
        -0.03798890858888626,
        0.02469809539616108,
        0.01427207700908184,
        -0.0331229604780674,
        0.034333646297454834,
        -0.026992328464984894,
        0.027728518471121788,
        -0.022420519962906837,
        0.01968572661280632,
        0.04217454791069031,
        -0.057564713060855865,
        0.0637563094496727,
        -0.049908027052879333,
        -0.05619216337800026,
        0.057556409388780594,
        -0.05027857795357704,
        0.054881200194358826,
        -0.04307858645915985,
        0.05102934688329697,
        -0.017957573756575584,
        -0.04998108744621277,
        -0.049839459359645844,
        0.0391882061958313,
        0.05965347960591316,
        -0.059032924473285675,
        0.02733655273914337,
        -0.06009884178638458,
        0.03935491293668747,
        0.05857086181640625,
        -0.04554210230708122,
        -0.05985637009143829,
        0.020114127546548843,
        -0.002592010423541069,
        0.01570029929280281,
        -0.04664541780948639,
        -0.04448094964027405,
        -0.05833037942647934,
        0.06109090894460678,
        -0.03152940422296524,
        0.06208201125264168,
        0.062493696808815,
        0.05049789324402809,
        -0.015630271285772324,
        0.03665085881948471,
        -0.059979960322380066,
        -0.014291631989181042,
        -0.039685025811195374,
        0.006263094488531351,
        -0.01719367690384388,
        0.061751656234264374,
        -0.06123673915863037,
        -0.04710059240460396,
        0.06248132511973381,
        -0.03690917044878006,
        0.06145872175693512,
        0.004631792660802603,
        0.010402968153357506,
        -0.024330081418156624,
        0.009256957098841667,
        0.06386703252792358,
        0.05970931425690651,
        0.03238331526517868,
        0.014042804017663002,
        0.005132404156029224,
        0.05280936136841774,
        -0.06338652223348618,
        0.052972640842199326,
        -0.0412428081035614,
        -0.06287366151809692,
        -0.06153453141450882,
        0.054537735879421234,
        -0.047344647347927094,
        -0.03995677828788757,
        0.03909069299697876,
        -0.04768715426325798,
        0.05898340791463852,
        0.029126757755875587,
        0.010239074006676674,
        -0.0500740148127079,
        -0.005120737943798304,
        -0.05931388959288597,
        0.06340350210666656,
        -0.042989809066057205,
        0.0532708503305912,
        -0.06120334565639496,
        -0.03086409531533718,
        -0.05432317405939102,
        0.05895460769534111,
        0.014235174283385277,
        0.0518319234251976,
        0.06079701706767082,
        0.051540739834308624,
        0.04513722285628319,
        0.027097074314951897,
        -0.05718715116381645,
        -0.06004177778959274,
        0.058527860790491104,
        -0.05533638969063759,
        -0.06020045280456543,
        0.06341911107301712,
        -0.04225246235728264,
        -0.023873383179306984,
        -0.05941269174218178,
        -0.0100295664742589,
        -0.05430817976593971,
        0.023296967148780823,
        0.03384910896420479,
        0.033672768622636795,
        -0.0557258240878582,
        -0.0072057596407830715,
        0.060685839504003525,
        0.060818880796432495,
        -0.05396270006895065,
        -0.04186704754829407,
        -0.03306981921195984,
        0.00889570266008377,
        0.04839601740241051,
        0.043802693486213684,
        0.061953105032444,
        -0.058448486030101776,
        -0.010569049045443535,
        0.03833173215389252,
        -0.001106278970837593,
        0.06291978061199188,
        -0.0077185551635921,
        0.006606903858482838,
        -0.06179773062467575,
        0.018032962456345558,
        0.043004367500543594,
        0.05687461048364639,
        -0.0023650769144296646,
        -0.027026042342185974,
        0.050286561250686646,
        0.02598254755139351,
        0.032880254089832306,
        -0.0019624223932623863,
        -0.06019871309399605,
        -0.025762394070625305,
        -0.06273398548364639,
        -0.05387521907687187,
        -0.02393912337720394,
        -0.06048113852739334,
        -0.05240698903799057,
        -0.06146775931119919,
        0.059225138276815414,
        0.06056666001677513,
        -0.0044593168422579765,
        0.00028237453079782426,
        0.058299265801906586,
        -0.014780673198401928,
        0.023431919515132904,
        -0.056895188987255096,
        0.015516676008701324,
        0.06303273886442184,
        -0.05905821546912193,
        -0.023139800876379013,
        0.047129400074481964,
        0.031968455761671066,
        0.06173037365078926,
        -0.04329901933670044,
        0.04059452936053276,
        0.06081206724047661,
        -0.06108507141470909,
        0.05439259484410286,
        -0.04112698510289192,
        -0.011635605245828629,
        0.02797960676252842,
        0.06074768304824829,
        -0.03568840026855469,
        -0.006775815971195698,
        -0.022878671064972878,
        -0.04939977452158928,
        0.03697359189391136,
        0.0052170599810779095,
        0.013699267990887165,
        0.044409796595573425,
        -0.0337909571826458,
        -0.03833499178290367,
        0.02764527127146721,
        0.03936448320746422,
        0.06367664784193039,
        -0.063631072640419,
        0.06084175035357475,
        -0.049028102308511734,
        -0.0625251978635788,
        0.04761052504181862,
        0.056945569813251495,
        0.049540549516677856,
        0.03933020681142807,
        0.05725379288196564,
        0.007157100830227137,
        -0.06009270250797272,
        -0.04406684264540672,
        0.025355733931064606,
        0.05411456897854805,
        -0.054488543421030045,
        0.017417585477232933,
        0.03729125112295151,
        0.026463763788342476,
        -0.0330045111477375,
        0.05405718460679054,
        0.05381781607866287,
        0.035650864243507385,
        -0.059705451130867004,
        -0.05137151852250099,
        -0.03208261728286743,
        -0.04473109915852547,
        0.04564163461327553,
        0.050611864775419235,
        0.0525139719247818,
        0.045698169618844986,
        -0.0589391328394413,
        -0.029969988390803337,
        0.06077844649553299,
        0.03697425127029419,
        -0.038604144006967545,
        -0.05949297174811363,
        -0.057048093527555466,
        0.023727573454380035,
        0.06310970336198807,
        -0.03063381277024746,
        0.05944862961769104,
        -0.023003313690423965,
        -0.031044358387589455,
        -0.05489279702305794,
        0.0617670975625515,
        0.06258819252252579
    ],
    [
        0.006820168346166611,
        0.041832249611616135,
        0.062139496207237244,
        -0.003403874346986413,
        0.053254738450050354,
        -0.026863915845751762,
        0.05261271446943283,
        0.02000497840344906,
        0.003115297993645072,
        0.054313238710165024,
        0.028598006814718246,
        0.05119974538683891,
        -0.05854882299900055,
        0.03538850322365761,
        -0.060572925955057144,
        -0.030880896374583244,
        -0.04329247027635574,
        0.044902440160512924,
        -0.0010170791065320373,
        -0.04129417613148689,
        -0.04256174713373184,
        0.028260620310902596,
        0.017482273280620575,
        0.031105613335967064,
        0.05277847498655319,
        0.01695452257990837,
        0.02341308444738388,
        0.006677047349512577,
        -0.0536251924932003,
        -0.03383781388401985,
        0.056600622832775116,
        0.03244636207818985,
        0.007406360004097223,
        -0.061965782195329666,
        0.011658751405775547,
        -0.01417877059429884,
        -0.058911532163619995,
        -0.057999640703201294,
        0.04883106052875519,
        0.03962137922644615,
        -0.03224531561136246,
        0.03275018930435181,
        0.062365561723709106,
        -0.04309689253568649,
        0.04937867075204849,
        0.025248708203434944,
        -0.0034308813046664,
        0.06122507527470589,
        0.0630897805094719,
        0.018503306433558464,
        0.06286022067070007,
        0.033446863293647766,
        0.054535988718271255,
        -0.047550275921821594,
        -0.029864827170968056,
        -0.056660961359739304,
        0.03533707186579704,
        -0.02745603583753109,
        -0.04666319489479065,
        0.0014721481129527092,
        -0.06303636729717255,
        0.012814228422939777,
        -0.055780574679374695,
        -0.016577348113059998,
        -0.002758043585345149,
        0.012313407845795155,
        0.004028765484690666,
        -0.019720222800970078,
        0.023668494075536728,
        -0.061637040227651596,
        -0.06188548728823662,
        -0.06264874339103699,
        0.025896383449435234,
        0.041493259370326996,
        -0.03714577481150627,
        0.0293048657476902,
        0.01722114533185959,
        0.06237489730119705,
        0.058331236243247986,
        -0.02713773027062416,
        -0.05943402647972107,
        -0.006677108816802502,
        -0.052772168070077896,
        0.04700291156768799,
        -0.03115089237689972,
        0.024025416001677513,
        0.009876485913991928,
        -0.01783796399831772,
        -0.04440930858254433,
        0.05408022552728653,
        -0.062181033194065094,
        -0.043753452599048615,
        -0.038407351821660995,
        -0.04496487230062485,
        -0.00665632588788867,
        0.03524363785982132,
        -0.04808872193098068,
        -0.05937836691737175,
        -0.012038152664899826,
        0.03942204639315605,
        -0.062226422131061554,
        -0.02704121731221676,
        0.04077083244919777,
        0.061264947056770325,
        0.022048568353056908,
        -0.06040513888001442,
        -0.060351498425006866,
        0.059691622853279114,
        -0.001505020190961659,
        -0.025794584304094315,
        -0.0373070202767849,
        -0.06304319202899933,
        -0.05199263617396355,
        -0.0058418200351297855,
        0.052375417202711105,
        0.0017975290538743138,
        0.05795729532837868,
        -0.05623616278171539,
        -0.061762090772390366,
        -0.041047338396310806,
        -0.05501745641231537,
        0.034916624426841736,
        -0.03718651458621025,
        0.050778359174728394,
        -0.060610562562942505,
        0.017841096967458725,
        -0.0516577810049057,
        -0.029800182208418846,
        0.05357470363378525,
        -0.036164384335279465,
        0.05967390164732933,
        -0.0367005430161953,
        -0.057430069893598557,
        0.04943151772022247,
        -0.05595007911324501,
        -0.057572320103645325,
        0.05753301829099655,
        -0.06139218807220459,
        0.023295750841498375,
        0.05670671537518501,
        0.05571892485022545,
        -0.053653422743082047,
        0.057035598903894424,
        0.0282428115606308,
        0.015637407079339027,
        -0.04259009286761284,
        0.06200426444411278,
        -0.043363094329833984,
        -0.024735210463404655,
        0.01407464500516653,
        -0.054649971425533295,
        -0.0625707283616066,
        -0.05591992661356926,
        0.03780655562877655,
        0.015171791426837444,
        -0.05912572517991066,
        0.05923639237880707,
        -0.01259220577776432,
        0.03667974844574928,
        -0.04423912987112999,
        0.04250330850481987,
        -0.024745222181081772,
        0.05905226245522499,
        0.0011822048109024763,
        0.05479079857468605,
        -0.0593671016395092,
        0.056311074644327164,
        -0.013452461920678616,
        0.008032097481191158,
        0.03778611123561859,
        -0.06021317094564438,
        0.05015816166996956,
        -0.06198793649673462,
        0.017679613083600998,
        -0.0535825677216053,
        -0.012764673680067062,
        -0.024082981050014496,
        0.05374652519822121,
        0.0634036585688591,
        -0.03792155161499977,
        0.04978874325752258,
        0.05475897714495659,
        0.0627676248550415,
        0.039981547743082047,
        0.017488688230514526,
        -0.03634406626224518,
        -0.043233711272478104,
        0.06240014359354973,
        0.004650352522730827,
        -0.010217901319265366,
        -0.03971652314066887,
        -0.04287935793399811,
        -0.049174629151821136,
        -0.023478394374251366,
        -0.044967200607061386,
        0.05658390000462532,
        0.05084085091948509,
        -0.051504332572221756,
        0.03930199146270752,
        0.056615717709064484,
        0.04685245454311371,
        0.05873551219701767,
        0.04579683020710945,
        0.006447798106819391,
        0.0441599003970623,
        -0.005552898161113262,
        0.05532233044505119,
        0.05165307968854904,
        -0.05568891018629074,
        0.047296192497015,
        -0.06144818663597107,
        0.006513816304504871,
        -0.03882914036512375,
        0.019041473045945168,
        0.050849881023168564,
        -0.059351224452257156,
        0.057349320501089096,
        0.004857840482145548,
        0.051213983446359634,
        -0.028755182400345802,
        0.016624733805656433,
        -0.048691775649785995,
        -0.005379706155508757,
        -0.05947596952319145,
        0.05006367340683937,
        -0.047789592295885086,
        0.030490649864077568,
        -0.02029428817331791,
        0.014116110280156136,
        -0.061577942222356796,
        -0.01320737786591053,
        0.0010687274625524879,
        -0.062387559562921524,
        0.04906357824802399,
        0.0635761097073555,
        0.02901068888604641,
        0.06225381791591644,
        0.0002503330761101097,
        0.010768664069473743,
        0.054186537861824036,
        0.055873170495033264,
        -0.055330488830804825,
        0.021846843883395195,
        -0.049552761018276215,
        0.009258000180125237,
        0.04719005525112152,
        0.05916270241141319,
        -0.01631896011531353,
        0.012777482159435749,
        -0.01878456398844719,
        0.0008397940546274185,
        -0.058087147772312164,
        -0.020698515698313713,
        -0.05739477276802063,
        -0.0006922775646671653,
        0.059447742998600006,
        0.022302856668829918,
        0.015506640076637268,
        0.04965144768357277,
        0.03869255259633064,
        0.05502217262983322,
        -0.00007790676318109035,
        0.06008325517177582,
        -0.055234476923942566,
        -0.043969545513391495,
        0.0218735970556736,
        0.05278690904378891,
        0.04857878014445305,
        -0.022366942837834358,
        -0.03635115548968315,
        -0.0026226206682622433,
        -0.06130195036530495,
        0.04812366142868996,
        -0.04407981410622597,
        -0.020678600296378136,
        0.058103084564208984,
        -0.06114156171679497,
        0.014278876595199108,
        -0.0235005971044302,
        -0.050041526556015015,
        0.05100925639271736,
        0.01537566538900137,
        -0.052829559892416,
        0.035223010927438736,
        -0.03930612653493881,
        0.036800213158130646,
        0.043221451342105865,
        0.020137913525104523,
        -0.059596624225378036,
        -0.00275713624432683,
        -0.03015308454632759,
        -0.018566269427537918,
        0.0047271172516047955,
        -0.0383610837161541,
        0.04200250655412674,
        -0.048285871744155884,
        0.06334645301103592,
        -0.0545923076570034,
        -0.058876000344753265,
        0.060768723487854004,
        -0.033909473568201065,
        0.03944067656993866,
        -0.010963348671793938,
        0.05003384128212929,
        0.00984810572117567,
        -0.029698604717850685,
        -0.05265002325177193,
        0.04483481124043465,
        0.029796980321407318,
        -0.060166679322719574,
        0.05238453298807144,
        -0.013085066340863705,
        -0.016428332775831223,
        0.059803351759910583,
        -0.020286908373236656,
        -0.06271127611398697,
        0.03849269822239876,
        -0.014463407918810844,
        -0.022343028336763382,
        -0.04489997774362564,
        0.018038403242826462,
        -0.024804366752505302,
        0.04736688360571861,
        -0.040371619164943695,
        0.06298186630010605,
        0.049945734441280365,
        0.050882674753665924,
        0.001817390788346529,
        -0.01449347473680973,
        -0.060815323144197464,
        0.01838342659175396,
        0.03906717896461487,
        0.032762520015239716,
        0.004414832219481468,
        0.062104251235723495,
        -0.057366978377103806,
        0.014085137285292149,
        0.06324885785579681,
        -0.0300636887550354,
        0.05839882418513298,
        0.007525134366005659,
        -0.0009192503057420254,
        -0.04559159278869629,
        -0.024431154131889343,
        0.06396273523569107,
        0.04641397297382355,
        -0.03666715696454048,
        0.042730145156383514,
        0.020874228328466415,
        0.03323989361524582,
        -0.0485059916973114,
        0.04448695480823517,
        -0.05714665725827217,
        -0.0636143907904625,
        -0.06273907423019409,
        0.04663751646876335,
        -0.05430970340967178,
        -0.027675345540046692,
        -0.022168563678860664,
        -0.032464154064655304,
        0.05747852474451065,
        0.03726840391755104,
        -0.015671873465180397,
        -0.05224206671118736,
        -0.012660722248256207,
        -0.057695530354976654,
        0.06382988393306732,
        0.0029249396175146103,
        0.023359021171927452,
        -0.06379780173301697,
        -0.026117736473679543,
        -0.060721561312675476,
        0.058541953563690186,
        -0.01973705366253853,
        0.059038080275058746,
        0.006053699180483818,
        0.012904719449579716,
        0.047544214874506,
        0.007828052155673504,
        -0.046310726553201675,
        -0.05941716581583023,
        0.06238560006022453,
        -0.0610382966697216,
        0.009929253719747066,
        0.06338899582624435,
        -0.055280573666095734,
        0.01168060302734375,
        -0.06124395132064819,
        -0.05969834700226784,
        -0.05917162820696831,
        -0.0027130628004670143,
        0.046099696308374405,
        -0.04590043053030968,
        -0.037520963698625565,
        -0.024858728051185608,
        0.057705849409103394,
        0.06126486137509346,
        -0.05974174663424492,
        -0.04303613677620888,
        -0.060565054416656494,
        0.004459736868739128,
        0.05732898414134979,
        0.05696241185069084,
        0.05069999769330025,
        -0.017045915126800537,
        -0.028293823823332787,
        0.03949170559644699,
        -0.03237820789217949,
        0.06301894783973694,
        -0.05595727637410164,
        -0.05277330428361893,
        -0.05991513654589653,
        0.009961164556443691,
        0.050371646881103516,
        0.059147849678993225,
        0.02387257292866707,
        -0.023210683837532997,
        0.051922041922807693,
        0.04788772389292717,
        0.06218758970499039,
        -0.006179274991154671,
        -0.05363547056913376,
        -0.0370013527572155,
        -0.06305167078971863,
        -0.0377238392829895,
        0.021384261548519135,
        -0.048218145966529846,
        -0.013936511240899563,
        -0.06260016560554504,
        0.03668542951345444,
        0.06099856644868851,
        -0.006008105352520943,
        0.02381504699587822,
        0.06278328597545624,
        0.024776287376880646,
        -0.020962636917829514,
        -0.03447267413139343,
        0.021950097754597664,
        0.0632648691534996,
        -0.059679049998521805,
        -0.007121630944311619,
        0.04678088799118996,
        0.059160392731428146,
        0.058074045926332474,
        -0.05017507076263428,
        0.05715589225292206,
        0.06307478994131088,
        -0.049927596002817154,
        0.05626094713807106,
        -0.033539172261953354,
        -0.03156109154224396,
        0.047813642770051956,
        0.054544225335121155,
        -0.055292561650276184,
        -0.052965372800827026,
        -0.015143941156566143,
        -0.053205233067274094,
        0.03376726433634758,
        0.0018228244734928012,
        0.05375954508781433,
        0.013363449834287167,
        -0.04603434354066849,
        -0.04915191978216171,
        -0.0027945409528911114,
        0.047880858182907104,
        0.06386756896972656,
        -0.06363952904939651,
        0.06047375872731209,
        -0.053627535700798035,
        -0.06285559386014938,
        0.060739289969205856,
        0.05111202970147133,
        0.0483134500682354,
        0.05226840078830719,
        0.052447360008955,
        0.055418092757463455,
        -0.038215138018131256,
        0.008853010833263397,
        0.032011233270168304,
        0.044060491025447845,
        -0.06263219565153122,
        0.015823040157556534,
        0.043679412454366684,
        0.03334704786539078,
        -0.037711869925260544,
        0.0583811029791832,
        0.04401121288537979,
        -0.031237272545695305,
        -0.05392520874738693,
        -0.05640597641468048,
        -0.04737846553325653,
        -0.0059390137903392315,
        0.04815793037414551,
        0.0624203123152256,
        0.013553723692893982,
        0.031095512211322784,
        -0.06346960365772247,
        0.03133496642112732,
        0.06293033063411713,
        0.03614427521824837,
        -0.0635438933968544,
        -0.05853874981403351,
        -0.05913906544446945,
        0.042020007967948914,
        0.060105860233306885,
        0.04401269182562828,
        0.06148060783743858,
        0.02401586063206196,
        -0.037987589836120605,
        -0.05032401159405708,
        0.06339258700609207,
        0.0619531013071537
    ],
    [
        -0.05006520077586174,
        0.05437817424535751,
        0.0548541396856308,
        -0.0028697666712105274,
        0.047566886991262436,
        0.04334641993045807,
        0.059950102120637894,
        0.003773083444684744,
        0.019808635115623474,
        0.059942688792943954,
        0.012985565699636936,
        0.021534394472837448,
        -0.05489490181207657,
        0.013652789406478405,
        -0.0602717250585556,
        -0.035293541848659515,
        -0.03944721072912216,
        0.053333815187215805,
        -0.040439654141664505,
        -0.05802641436457634,
        -0.05533048138022423,
        0.012872804887592793,
        -0.007662842515856028,
        0.04946259409189224,
        0.057377174496650696,
        0.008448215201497078,
        -0.01756315305829048,
        0.002665499923750758,
        -0.04708430916070938,
        0.006945059634745121,
        0.051778603345155716,
        -0.05375486984848976,
        -0.05011308193206787,
        -0.0595361553132534,
        0.006207191850990057,
        -0.03570587933063507,
        -0.05894722416996956,
        -0.054140836000442505,
        -0.006395774893462658,
        0.051081642508506775,
        -0.029163401573896408,
        0.03286227583885193,
        0.05918749421834946,
        -0.05582069233059883,
        0.06079377233982086,
        0.028696633875370026,
        -0.04188034310936928,
        0.06019623950123787,
        0.061261244118213654,
        0.054234232753515244,
        0.05779806151986122,
        0.00876599084585905,
        0.061597224324941635,
        -0.041918735951185226,
        -0.043071199208498,
        -0.05672094598412514,
        0.003989808261394501,
        -0.03485555201768875,
        -0.04723990708589554,
        -0.03226466476917267,
        -0.054990410804748535,
        -0.04351544752717018,
        -0.046592820435762405,
        0.018783802166581154,
        0.024434160441160202,
        0.03992576152086258,
        0.034513507038354874,
        -0.05676240101456642,
        0.045310042798519135,
        -0.062038604170084,
        -0.0598670169711113,
        -0.06064004451036453,
        -0.012033706530928612,
        -0.004151335917413235,
        -0.04883451387286186,
        -0.01197749376296997,
        0.00849191751331091,
        0.05896017327904701,
        0.01640237495303154,
        -0.03788694366812706,
        -0.04778964817523956,
        0.022003598511219025,
        -0.06040425971150398,
        0.014563770964741707,
        -0.031285610049963,
        0.03531857579946518,
        0.022791322320699692,
        0.015180855058133602,
        -0.0079417172819376,
        0.05365142598748207,
        -0.05897686257958412,
        0.006325119640678167,
        -0.00995162595063448,
        -0.020846102386713028,
        -0.006899041589349508,
        -0.004282418638467789,
        -0.03794790059328079,
        -0.05833004042506218,
        -0.01255923043936491,
        0.010617951862514019,
        -0.06199156492948532,
        -0.02504211850464344,
        0.056291352957487106,
        0.058168526738882065,
        -0.011836735531687737,
        -0.05995958298444748,
        -0.03351984918117523,
        0.04235751926898956,
        -0.010952211916446686,
        -0.047355733811855316,
        -0.011288640089333057,
        -0.05763828381896019,
        -0.03874572739005089,
        -0.04534970223903656,
        0.05178864672780037,
        -0.015599122270941734,
        0.046520959585905075,
        -0.06013830378651619,
        -0.0560942068696022,
        -0.013386816717684269,
        -0.04796263575553894,
        -0.008029863238334656,
        -0.01906895637512207,
        0.050955064594745636,
        -0.0594184473156929,
        0.027039313688874245,
        -0.03881526738405228,
        0.048582348972558975,
        0.04455702006816864,
        0.05109879747033119,
        0.05222364142537117,
        -0.04209626093506813,
        -0.026083482429385185,
        0.009888934902846813,
        -0.05674663186073303,
        -0.05895962938666344,
        0.05970131978392601,
        -0.061992574483156204,
        0.014196355827152729,
        0.060627758502960205,
        0.005455445032566786,
        -0.01023680530488491,
        0.03992897644639015,
        0.05097804591059685,
        -0.012592294253408909,
        -0.058679062873125076,
        0.051577020436525345,
        -0.05022713914513588,
        -0.04517584666609764,
        0.00452992832288146,
        -0.04763486608862877,
        -0.061302024871110916,
        -0.03461627662181854,
        -0.0034880994353443384,
        0.04120819270610809,
        -0.05185752734541893,
        0.06113894283771515,
        -0.032981500029563904,
        -0.03700501099228859,
        0.044476188719272614,
        0.06138429045677185,
        0.00676747364923358,
        0.050635408610105515,
        -0.01712546870112419,
        -0.04492221027612686,
        -0.027935955673456192,
        0.02582470327615738,
        -0.04354018718004227,
        0.027202341705560684,
        0.054842572659254074,
        -0.049990613013505936,
        0.0256182923913002,
        -0.05819965526461601,
        0.03569992259144783,
        -0.01427472848445177,
        0.053346265107393265,
        -0.0350966677069664,
        0.03438887372612953,
        0.05992381274700165,
        -0.021351132541894913,
        0.01772445999085903,
        0.04874837026000023,
        0.06012960150837898,
        0.0029825239907950163,
        0.018164820969104767,
        -0.0191629808396101,
        -0.05546312406659126,
        0.05987134203314781,
        -0.054958101361989975,
        -0.043751154094934464,
        0.045397255569696426,
        -0.040584977716207504,
        -0.05805018171668053,
        -0.04345610365271568,
        -0.04973321035504341,
        0.029808497056365013,
        0.0364966094493866,
        -0.059154316782951355,
        -0.0127129340544343,
        0.025983436033129692,
        0.05568448826670647,
        0.05801289528608322,
        0.027447961270809174,
        -0.032587286084890366,
        0.03335931524634361,
        -0.012274212203919888,
        0.043253470212221146,
        0.012394064106047153,
        -0.0490746907889843,
        0.044286347925662994,
        -0.0589420311152935,
        0.05276577174663544,
        -0.060174308717250824,
        0.04651642218232155,
        0.03207065165042877,
        -0.043689221143722534,
        0.0009321570978499949,
        0.0296282097697258,
        0.060107406228780746,
        -0.052512265741825104,
        0.03101477585732937,
        -0.05802009999752045,
        0.031028132885694504,
        -0.06101085990667343,
        0.04144211858510971,
        -0.03967709466814995,
        -0.037203554064035416,
        0.021241191774606705,
        0.01554473489522934,
        -0.055738456547260284,
        -0.029884332790970802,
        -0.03622477874159813,
        -0.06111335754394531,
        0.05806337669491768,
        0.05843175947666168,
        -0.004854698199778795,
        0.06027442589402199,
        0.03831230103969574,
        -0.015531963668763638,
        0.06035877764225006,
        0.05349646881222725,
        -0.027821125462651253,
        0.04247552528977394,
        -0.017265552654862404,
        0.0063104876317083836,
        0.02392675168812275,
        0.06122748553752899,
        -0.010758405551314354,
        -0.05951961129903793,
        -0.005184503272175789,
        -0.05929844453930855,
        -0.061413057148456573,
        -0.050198931246995926,
        -0.060050152242183685,
        0.050903260707855225,
        0.05333150178194046,
        0.012567685917019844,
        -0.03203628212213516,
        0.06178206577897072,
        0.053638383746147156,
        0.0347311869263649,
        0.058799974620342255,
        0.05367054045200348,
        0.006762027274817228,
        -0.012937607243657112,
        0.049168910831213,
        -0.019381064921617508,
        -0.006360861472785473,
        0.04556398466229439,
        -0.0569840744137764,
        -0.052894141525030136,
        -0.059917300939559937,
        0.04864368587732315,
        -0.05927915498614311,
        -0.04223579913377762,
        0.05348861962556839,
        -0.06072041019797325,
        0.05728836730122566,
        -0.05178408697247505,
        -0.05318376049399376,
        -0.060236819088459015,
        -0.028246087953448296,
        -0.0074547394178807735,
        -0.026678938418626785,
        -0.04396255314350128,
        -0.042873404920101166,
        0.046872664242982864,
        0.001507725566625595,
        -0.061362896114587784,
        0.033224139362573624,
        -0.04619431868195534,
        0.04553594812750816,
        -0.03467582166194916,
        -0.044367365539073944,
        0.041383709758520126,
        -0.047823112457990646,
        0.061636362224817276,
        -0.021732904016971588,
        -0.04003506898880005,
        0.05800720676779747,
        -0.05187554657459259,
        0.05309971421957016,
        -0.01580631732940674,
        0.0472637340426445,
        -0.029356542974710464,
        -0.04205632582306862,
        -0.0012520725140348077,
        0.02127700299024582,
        0.05800342559814453,
        -0.059313639998435974,
        0.01636682078242302,
        -0.035334158688783646,
        -0.043238330632448196,
        0.05591056868433952,
        -0.04437576234340668,
        -0.054085299372673035,
        0.015927491709589958,
        -0.04428771510720253,
        0.026327604427933693,
        -0.061091966927051544,
        0.016789525747299194,
        -0.027662064880132675,
        0.03109106793999672,
        -0.04770071804523468,
        0.05009688809514046,
        0.05906073749065399,
        0.058108504861593246,
        -0.03147759288549423,
        0.013985621742904186,
        0.010810757987201214,
        0.005661430303007364,
        0.04466578736901283,
        0.041192054748535156,
        -0.038570355623960495,
        0.06069355458021164,
        -0.026203162968158722,
        -0.01860077492892742,
        0.06152728572487831,
        -0.038334447890520096,
        0.06138897314667702,
        -0.00037370575591921806,
        -0.044110096991062164,
        -0.027639610692858696,
        -0.041451651602983475,
        0.06197986751794815,
        0.01711180992424488,
        -0.005154768470674753,
        0.034110937267541885,
        0.04974204674363136,
        0.04828000068664551,
        -0.06196954846382141,
        0.05087002366781235,
        -0.05236680805683136,
        -0.05933166667819023,
        -0.060651328414678574,
        0.05923822894692421,
        -0.05643737316131592,
        -0.050971437245607376,
        -0.02571234479546547,
        -0.05959874764084816,
        0.05732964724302292,
        0.015710774809122086,
        -0.05479821190237999,
        -0.04715503379702568,
        0.01957392320036888,
        -0.0580766424536705,
        0.06178826466202736,
        0.006124140229076147,
        0.027433885261416435,
        -0.06201237067580223,
        -0.05253404751420021,
        -0.061891619116067886,
        0.06095593795180321,
        -0.039496537297964096,
        0.05646286904811859,
        0.05519548058509827,
        0.05442670360207558,
        0.05841066688299179,
        -0.031074896454811096,
        -0.058000653982162476,
        -0.04188212379813194,
        0.05699804425239563,
        -0.06002612039446831,
        -0.04407099634408951,
        0.061848342418670654,
        -0.046518728137016296,
        0.053716015070676804,
        -0.06035773828625679,
        -0.05445372685790062,
        -0.05019175633788109,
        0.002414475893601775,
        0.029246805235743523,
        0.025590162724256516,
        -0.021915214136242867,
        -0.037203170359134674,
        0.05904263257980347,
        0.05844329670071602,
        -0.05690537393093109,
        -0.05064617097377777,
        -0.05772702023386955,
        -0.05319194123148918,
        0.06057560443878174,
        0.05755186825990677,
        0.05512481555342674,
        -0.05580182373523712,
        -0.009699353016912937,
        0.03144872561097145,
        0.030672676861286163,
        0.06158699095249176,
        -0.046488307416439056,
        0.014655363745987415,
        -0.04334501549601555,
        0.008751600049436092,
        0.057521212846040726,
        0.05460376664996147,
        0.04047931358218193,
        -0.016369182616472244,
        0.029256993904709816,
        0.04455336555838585,
        0.05076708644628525,
        -0.060547877103090286,
        -0.06106257438659668,
        -0.021064721047878265,
        -0.061711594462394714,
        0.013606216758489609,
        -0.001819821074604988,
        -0.05749867483973503,
        -0.011704188771545887,
        -0.060518741607666016,
        -0.04025987908244133,
        0.06050010398030281,
        -0.03001091629266739,
        0.05377046391367912,
        0.05548916757106781,
        0.05021374300122261,
        -0.03571280464529991,
        -0.03253684937953949,
        0.04157266393303871,
        0.05883694812655449,
        -0.05782664567232132,
        -0.029061084613204002,
        0.048016004264354706,
        0.03799470141530037,
        0.059889111667871475,
        -0.01164642721414566,
        0.04568863660097122,
        0.06079455465078354,
        -0.05596880614757538,
        0.05683578550815582,
        -0.05531153827905655,
        -0.00533621571958065,
        0.005151765886694193,
        0.057013656944036484,
        -0.04938751086592674,
        -0.018498588353395462,
        -0.04263951629400253,
        -0.03790677338838577,
        -0.017722854390740395,
        -0.04557904973626137,
        0.06107466667890549,
        0.01995704136788845,
        0.014901586808264256,
        -0.05945500358939171,
        0.03679266571998596,
        0.05655873194336891,
        0.0618908517062664,
        -0.06178024038672447,
        0.05920857563614845,
        -0.04175678640604019,
        -0.06177286058664322,
        0.058797404170036316,
        0.05213382467627525,
        0.053521569818258286,
        0.030200425535440445,
        0.05422700196504593,
        0.04296402260661125,
        -0.048943936824798584,
        -0.0080420495942235,
        -0.02668752335011959,
        -0.03058369643986225,
        -0.022901467978954315,
        0.0013011510018259287,
        -0.0175002533942461,
        0.04379161074757576,
        -0.0036110130604356527,
        0.05777296796441078,
        0.05132294073700905,
        0.0005060848779976368,
        -0.05626900866627693,
        -0.0513177253305912,
        -0.04895484074950218,
        -0.03322576358914375,
        0.03796759992837906,
        0.05757828429341316,
        0.05957253277301788,
        -0.00613184692338109,
        -0.05797981098294258,
        0.02259012497961521,
        0.05847381055355072,
        0.040582165122032166,
        -0.058477263897657394,
        -0.048315275460481644,
        -0.05448286235332489,
        -0.028438758105039597,
        0.058914557099342346,
        -0.023504052311182022,
        0.05575190857052803,
        -0.0020578547846525908,
        -0.05823947489261627,
        0.013588259927928448,
        0.06034393236041069,
        0.06074950844049454
    ],
    [
        -0.04779284819960594,
        0.01385417953133583,
        0.04353917017579079,
        -0.029392946511507034,
        -0.046144332736730576,
        -0.044367801398038864,
        0.010946800000965595,
        -0.05242530256509781,
        0.05766662210226059,
        -0.04362078011035919,
        0.021373890340328217,
        0.004730407614260912,
        -0.058879874646663666,
        -0.035979919135570526,
        -0.060830701142549515,
        0.0531744509935379,
        -0.01784941554069519,
        0.05434029549360275,
        0.021281393244862556,
        -0.030755018815398216,
        0.03564116358757019,
        -0.05594547092914581,
        0.05422333627939224,
        0.05496354028582573,
        0.03887638822197914,
        0.041764192283153534,
        0.04093098267912865,
        -0.049052998423576355,
        -0.06069128215312958,
        0.021750394254922867,
        0.05723278969526291,
        0.012424462474882603,
        0.014715787954628468,
        -0.06146134063601494,
        0.05727597326040268,
        -0.03038276731967926,
        -0.06100819259881973,
        -0.055094990879297256,
        0.04030191898345947,
        -0.05703183636069298,
        -0.04428950697183609,
        0.030093863606452942,
        0.056631553918123245,
        -0.03905213624238968,
        0.04325704276561737,
        0.05109059065580368,
        0.05455351248383522,
        0.027020500972867012,
        0.06221413239836693,
        0.005421328824013472,
        0.06223571300506592,
        -0.002771219005808234,
        -0.05178957059979439,
        -0.05493141710758209,
        0.04401249811053276,
        -0.03519780933856964,
        0.053921546787023544,
        -0.008145018480718136,
        -0.04788291081786156,
        -0.05945106968283653,
        -0.06226334720849991,
        -0.05411083251237869,
        -0.06003905460238457,
        0.03749025985598564,
        0.05504091456532478,
        -0.028584668412804604,
        -0.05932330712676048,
        -0.019702397286891937,
        0.041498005390167236,
        0.04726787284016609,
        -0.031796105206012726,
        -0.06170080974698067,
        0.013255567289888859,
        0.038190778344869614,
        0.059195153415203094,
        -0.05883721262216568,
        -0.022678343579173088,
        0.06232946738600731,
        0.04085759073495865,
        0.008625950664281845,
        -0.032384615391492844,
        -0.02360459789633751,
        -0.04003516584634781,
        0.057527314871549606,
        0.0054897586815059185,
        0.05631745606660843,
        -0.03882208466529846,
        -0.03880950063467026,
        0.014850778505206108,
        0.007162278052419424,
        -0.021127481013536453,
        -0.0027241932693868876,
        -0.05136328935623169,
        0.04416362941265106,
        -0.052310507744550705,
        -0.03934284299612045,
        -0.011622060090303421,
        -0.06127209588885307,
        0.0598643459379673,
        0.04253344610333443,
        -0.0617850087583065,
        0.05736328288912773,
        0.06116566061973572,
        0.0607701800763607,
        0.037842582911252975,
        -0.05534255877137184,
        -0.056025583297014236,
        0.050525303930044174,
        -0.06139707937836647,
        0.00014448983711190522,
        0.013264895416796207,
        -0.039710141718387604,
        -0.04940478131175041,
        -0.05106991529464722,
        0.05916859582066536,
        0.037415821105241776,
        0.021135248243808746,
        -0.037866149097681046,
        0.06005437672138214,
        -0.054908089339733124,
        -0.015483502298593521,
        -0.00823755469173193,
        0.041816774755716324,
        0.00996203813701868,
        -0.05971275642514229,
        -0.013564134016633034,
        0.061425190418958664,
        -0.03277197480201721,
        0.04716124013066292,
        -0.012490751221776009,
        0.042353555560112,
        -0.061160046607255936,
        -0.04692625626921654,
        0.03955037146806717,
        -0.05961908772587776,
        -0.059570785611867905,
        0.06210651993751526,
        0.017185183241963387,
        0.04912753775715828,
        0.048555705696344376,
        -0.0021500636357814074,
        -0.025186162441968918,
        0.059947364032268524,
        0.0613277442753315,
        0.04544289410114288,
        -0.007384610362350941,
        0.05632816627621651,
        -0.05235437676310539,
        -0.03623271360993385,
        -0.05694039538502693,
        -0.023312224075198174,
        -0.05795593187212944,
        -0.05496440455317497,
        0.008472170680761337,
        -0.0032654465176165104,
        -0.041377536952495575,
        0.01746397092938423,
        0.003084784373641014,
        0.05952456220984459,
        0.0439034067094326,
        0.03707648068666458,
        0.004673322197049856,
        0.06002889946103096,
        0.05305188521742821,
        -0.006989504210650921,
        -0.023894473910331726,
        -0.02379048988223076,
        0.003145586233586073,
        -0.006939128506928682,
        0.05750219523906708,
        -0.008580571040511131,
        0.06002027913928032,
        -0.038354333490133286,
        0.0500774160027504,
        -0.062014561146497726,
        0.008838956244289875,
        -0.02213088609278202,
        -0.05191943421959877,
        0.05812780186533928,
        -0.027969472110271454,
        -0.03610978648066521,
        0.03866162151098251,
        -0.013636132702231407,
        -0.04937103018164635,
        0.034643977880477905,
        -0.03529530391097069,
        0.0014080090913921595,
        0.062002554535865784,
        0.007813548669219017,
        -0.018371058627963066,
        0.020414724946022034,
        0.046518053859472275,
        -0.038942601531744,
        0.029802314937114716,
        -0.0593021921813488,
        0.056888215243816376,
        0.049585480242967606,
        -0.05900995805859566,
        -0.0007396811270155013,
        0.0480235256254673,
        0.05593151971697807,
        0.00005972264989395626,
        0.035335723310709,
        -0.04962589219212532,
        -0.023427464067935944,
        -0.03469923883676529,
        0.06172274053096771,
        0.05217471346259117,
        -0.02813826873898506,
        -0.028887346386909485,
        0.026584045961499214,
        0.05917137488722801,
        0.06027040258049965,
        0.05031580477952957,
        -0.05344763025641441,
        0.014667301438748837,
        0.06192845478653908,
        0.04985293000936508,
        0.06102706119418144,
        0.0023428883869200945,
        0.05560302734375,
        0.04402465000748634,
        0.026332853361964226,
        0.016650138422846794,
        0.05394216254353523,
        -0.00139050860889256,
        -0.04095721244812012,
        -0.050693340599536896,
        0.04983628913760185,
        -0.06162165105342865,
        -0.01588384248316288,
        0.010883624665439129,
        -0.05389752984046936,
        0.05658290907740593,
        -0.025375643745064735,
        0.06190063804388046,
        0.05745318904519081,
        0.050699058920145035,
        -0.021698741242289543,
        0.062242358922958374,
        -0.023267537355422974,
        -0.020031120628118515,
        -0.04001791402697563,
        -0.05992578715085983,
        0.010384902358055115,
        -0.023634254932403564,
        0.0503779761493206,
        -0.0447833277285099,
        -0.02953578345477581,
        -0.044154729694128036,
        -0.035493917763233185,
        -0.06184720993041992,
        0.04774090647697449,
        -0.06104161590337753,
        -0.04568566381931305,
        0.028453409671783447,
        0.04629896581172943,
        -0.040715496987104416,
        0.04126778244972229,
        -0.03078969568014145,
        -0.03467123210430145,
        -0.011542811058461666,
        0.052606187760829926,
        0.034035664051771164,
        -0.03052767738699913,
        0.05353430658578873,
        -0.027702581137418747,
        -0.0015946505591273308,
        0.04781074449419975,
        -0.039587728679180145,
        0.035393282771110535,
        -0.04758184403181076,
        0.05168391764163971,
        0.01930016092956066,
        -0.04434094950556755,
        0.034548304975032806,
        -0.05108453333377838,
        0.0008872196194715798,
        0.05666422098875046,
        0.058201033622026443,
        -0.05185955762863159,
        0.02393815480172634,
        -0.05868411809206009,
        0.003099975408986211,
        -0.04811477288603783,
        0.004809567239135504,
        0.030102822929620743,
        0.061212532222270966,
        0.05943906679749489,
        0.05668346583843231,
        0.04250217601656914,
        0.023838095366954803,
        -0.055504728108644485,
        -0.001871950109489262,
        0.05606915056705475,
        -0.0607854500412941,
        0.05885938182473183,
        -0.048830803483724594,
        -0.0440254844725132,
        0.06043407693505287,
        -0.05398005619645119,
        0.03835823014378548,
        -0.00321219046600163,
        0.056698042899370193,
        0.0337444506585598,
        -0.013943389989435673,
        -0.021273700520396233,
        0.045870810747146606,
        0.027469376102089882,
        -0.05792236328125,
        -0.018658770248293877,
        0.006442164536565542,
        -0.04318677634000778,
        0.04536827281117439,
        0.05733727291226387,
        -0.05538031458854675,
        0.02108118310570717,
        -0.059614650905132294,
        0.05671394243836403,
        -0.023549910634756088,
        0.03543464094400406,
        -0.04121040925383568,
        0.037255216389894485,
        0.04485522210597992,
        0.06173259764909744,
        -0.011303137056529522,
        0.02888241969048977,
        -0.04430489242076874,
        -0.016431422904133797,
        -0.006130643654614687,
        0.05509967729449272,
        0.048223573714494705,
        -0.025099052116274834,
        -0.05094299092888832,
        0.058531276881694794,
        -0.05313293635845184,
        -0.006704075261950493,
        0.05753840133547783,
        -0.061888687312603,
        0.059245944023132324,
        -0.042307235300540924,
        0.03263081610202789,
        -0.0037376796826720238,
        0.043396398425102234,
        0.05643138661980629,
        -0.01057681068778038,
        0.05434620752930641,
        0.060296881943941116,
        0.004652758128941059,
        0.05528746545314789,
        0.04554370790719986,
        -0.04151171073317528,
        -0.016014227643609047,
        -0.061323169618844986,
        -0.061339713633060455,
        0.04999256879091263,
        -0.058731809258461,
        -0.0551505871117115,
        -0.024785567075014114,
        -0.056771017611026764,
        0.054887186735868454,
        0.05344793200492859,
        -0.05171428620815277,
        -0.024164708331227303,
        -0.01569768227636814,
        -0.061525389552116394,
        0.061943039298057556,
        -0.04023657739162445,
        -0.050855349749326706,
        -0.05833687633275986,
        0.0028370211366564035,
        -0.051305703818798065,
        0.04820157214999199,
        -0.011018242686986923,
        0.06049002334475517,
        0.009608006104826927,
        0.032879747450351715,
        0.05399198457598686,
        0.057016052305698395,
        0.008266446180641651,
        -0.007417863234877586,
        -0.026530325412750244,
        -0.06143384054303169,
        -0.03360036015510559,
        0.061912957578897476,
        -0.04514425992965698,
        0.010614349506795406,
        -0.057241544127464294,
        -0.06118438020348549,
        0.060687195509672165,
        -0.016581695526838303,
        -0.006420312449336052,
        -0.05776752904057503,
        -0.06040123477578163,
        0.03104444034397602,
        0.05695085972547531,
        0.05891133099794388,
        -0.011829440481960773,
        -0.05845705419778824,
        -0.05612815544009209,
        0.008168344385921955,
        0.062225937843322754,
        -0.03439448028802872,
        0.04028673842549324,
        -0.05749069154262543,
        0.020388130098581314,
        0.04679999127984047,
        -0.057916510850191116,
        0.06064805015921593,
        -0.022264430299401283,
        -0.05284431204199791,
        -0.04270721971988678,
        0.008688610978424549,
        0.052119314670562744,
        0.05681486427783966,
        -0.02380680851638317,
        0.0492280013859272,
        0.04426771402359009,
        0.03566528111696243,
        0.06060711666941643,
        0.03192099183797836,
        -0.06161045283079147,
        0.061020489782094955,
        -0.059673748910427094,
        -0.03808962553739548,
        -0.04189664125442505,
        -0.050638243556022644,
        -0.005596508271992207,
        -0.04488934949040413,
        -0.05659136548638344,
        0.0360732264816761,
        0.005771447904407978,
        -0.01866660639643669,
        0.05455653741955757,
        -0.03716333582997322,
        -0.06073061004281044,
        -0.06138186901807785,
        0.028286173939704895,
        0.0022387958597391844,
        -0.04358891770243645,
        -0.06000082194805145,
        0.04580015689134598,
        0.020823530852794647,
        0.05861995741724968,
        -0.043941088020801544,
        -0.03770192340016365,
        0.06162792444229126,
        -0.05899334326386452,
        0.030155129730701447,
        0.022939475253224373,
        0.047555018216371536,
        0.058090101927518845,
        -0.018391571938991547,
        0.019450142979621887,
        -0.05588523671030998,
        -0.0608661025762558,
        0.01835975982248783,
        -0.05566971376538277,
        -0.013244902715086937,
        0.05405300855636597,
        -0.02803650312125683,
        -0.04377974197268486,
        -0.040099211037158966,
        0.043105289340019226,
        0.059487685561180115,
        0.06189076974987984,
        0.028346966952085495,
        0.05766642093658447,
        0.01696910709142685,
        -0.05872783437371254,
        0.06032145023345947,
        0.03586329519748688,
        0.05452192947268486,
        -0.02786611020565033,
        0.05729350447654724,
        0.04711892083287239,
        0.05169803276658058,
        0.008553935214877129,
        -0.04410827159881592,
        0.0029605869203805923,
        -0.05803758278489113,
        -0.04134342819452286,
        -0.018074167892336845,
        0.045279234647750854,
        -0.03063294291496277,
        0.0497298426926136,
        0.04660303145647049,
        0.06157499551773071,
        -0.06141848489642143,
        0.024906430393457413,
        -0.05508763715624809,
        0.04276449978351593,
        0.0601351372897625,
        0.02138986997306347,
        -0.040542345494031906,
        -0.020984366536140442,
        -0.02267376147210598,
        -0.05425985902547836,
        0.05252144858241081,
        0.0070446087047457695,
        -0.05357171967625618,
        -0.03381987288594246,
        -0.022714626044034958,
        -0.04748131334781647,
        0.045667290687561035,
        -0.04505147039890289,
        0.05119584500789642,
        0.014259116724133492,
        0.06004083901643753,
        -0.05684845522046089,
        0.05167783051729202,
        0.06227363273501396
    ],
    [
        0.03856099396944046,
        -0.027858076617121696,
        -0.02543647587299347,
        -0.044530581682920456,
        -0.04277198761701584,
        0.03221042826771736,
        0.04873228445649147,
        -0.05654153227806091,
        0.05367905646562576,
        0.055496565997600555,
        0.05329672992229462,
        -0.023997683078050613,
        -0.04770956188440323,
        0.05661029368638992,
        -0.055790357291698456,
        0.05364600941538811,
        0.01999293453991413,
        0.05637156963348389,
        -0.01873633824288845,
        -0.0565432533621788,
        -0.05597621947526932,
        -0.012496275827288628,
        0.048765767365694046,
        0.04641253128647804,
        -0.056603483855724335,
        0.012675412930548191,
        0.049000438302755356,
        0.030805423855781555,
        -0.051377564668655396,
        0.048870619386434555,
        -0.025272049009799957,
        0.02619006484746933,
        -0.056475114077329636,
        -0.05647597089409828,
        0.05448959022760391,
        -0.05177638307213783,
        -0.054553430527448654,
        0.031132308766245842,
        -0.055756982415914536,
        0.011737387627363205,
        0.01847141794860363,
        -0.0045782155357301235,
        0.052776969969272614,
        0.041576530784368515,
        0.05607812479138374,
        -0.02410268411040306,
        0.056646302342414856,
        0.05637184903025627,
        0.05662016198039055,
        -0.006254883948713541,
        0.053333207964897156,
        0.04124928638339043,
        0.016577187925577164,
        -0.024004098027944565,
        -0.010924166068434715,
        0.05569750815629959,
        0.03390564024448395,
        0.02844010852277279,
        -0.05533117055892944,
        -0.05333075299859047,
        -0.05630096048116684,
        0.046030350029468536,
        -0.05597635358572006,
        -0.029020212590694427,
        0.03076326660811901,
        -0.055974509567022324,
        -0.042735226452350616,
        -0.007124884054064751,
        0.042054858058691025,
        0.0534600205719471,
        -0.015339144505560398,
        0.0016486587701365352,
        0.05664757639169693,
        0.0004132147296331823,
        0.056622039526700974,
        -0.0422864705324173,
        0.04852956905961037,
        0.0566089004278183,
        0.05654708668589592,
        -0.026778873056173325,
        -0.04475674033164978,
        -0.053142037242650986,
        -0.056412216275930405,
        0.04020247980952263,
        -0.04569756239652634,
        0.028386788442730904,
        0.008560501970350742,
        0.05342067778110504,
        -0.00432963203638792,
        -0.010535710491240025,
        -0.05462515354156494,
        -0.05663774535059929,
        -0.05001838505268097,
        -0.05661795288324356,
        -0.05604325607419014,
        0.030593691393733025,
        -0.03723769262433052,
        -0.049828287214040756,
        0.050567131489515305,
        0.026886913925409317,
        -0.056636743247509,
        0.049882955849170685,
        0.05565433204174042,
        0.03812135010957718,
        0.05615917593240738,
        -0.05308353155851364,
        -0.055017828941345215,
        0.052678924053907394,
        0.024518782272934914,
        -0.04492134228348732,
        -0.05593203380703926,
        -0.0566357858479023,
        -0.029824789613485336,
        -0.038854703307151794,
        -0.032298460602760315,
        -0.04829716309905052,
        -0.04019516333937645,
        -0.0541888065636158,
        0.012389127165079117,
        -0.03415132313966751,
        -0.0003378198598511517,
        -0.03586823120713234,
        -0.05110441893339157,
        -0.04599311947822571,
        -0.0557037852704525,
        -0.027541503310203552,
        -0.024376124143600464,
        -0.05625976249575615,
        0.04787641018629074,
        -0.019596699625253677,
        0.0562090128660202,
        -0.042444851249456406,
        -0.05624004825949669,
        0.04465955123305321,
        -0.04918183386325836,
        -0.05427384376525879,
        0.056277256458997726,
        0.021241111680865288,
        0.03167501464486122,
        -0.0011108217295259237,
        0.0328434482216835,
        0.020836781710386276,
        0.053932610899209976,
        0.05559580773115158,
        0.0007770099909976125,
        0.00657912390306592,
        -0.0539642870426178,
        -0.043316081166267395,
        0.017787517979741096,
        0.05421213433146477,
        -0.0020598347764462233,
        -0.05664984509348869,
        0.029696865007281303,
        -0.014752219431102276,
        -0.007190789561718702,
        -0.054494019597768784,
        0.046757958829402924,
        -0.05434539169073105,
        -0.009630046784877777,
        0.03882646933197975,
        0.0522003173828125,
        0.04191482812166214,
        0.05601297318935394,
        0.05387313663959503,
        -0.05303719639778137,
        0.053993288427591324,
        0.013060717843472958,
        0.015426424331963062,
        -0.04420324042439461,
        0.006239564623683691,
        -0.04928991571068764,
        0.0508284829556942,
        -0.05618966743350029,
        0.046602167189121246,
        -0.042929138988256454,
        0.033490221947431564,
        0.04300897568464279,
        0.008291492238640785,
        0.055503591895103455,
        -0.053560175001621246,
        -0.05476731061935425,
        0.05662398040294647,
        0.027855711057782173,
        -0.01863979548215866,
        0.042102403938770294,
        -0.012772795744240284,
        -0.0272428747266531,
        0.050475675612688065,
        0.053349658846855164,
        -0.05590572953224182,
        -0.053830020129680634,
        0.005073979496955872,
        0.05024505779147148,
        -0.05579112842679024,
        -0.052980370819568634,
        0.036888737231492996,
        0.03115830011665821,
        0.014369292184710503,
        -0.03589874878525734,
        0.05610347539186478,
        0.053975723683834076,
        -0.05664606764912605,
        0.001809480949304998,
        -0.054851043969392776,
        0.03626495227217674,
        -0.01799486018717289,
        0.05279703438282013,
        0.05390549078583717,
        -0.05209921672940254,
        0.034980058670043945,
        -0.05235541611909866,
        0.042953360825777054,
        0.04809264466166496,
        0.05661812797188759,
        -0.056439779698848724,
        -0.0005100087146274745,
        0.05655710771679878,
        -0.04263424873352051,
        0.05664413422346115,
        -0.021592911332845688,
        -0.002363724634051323,
        0.05650412291288376,
        -0.03532411903142929,
        -0.05357846990227699,
        0.05626903846859932,
        -0.022088797762989998,
        0.046566229313611984,
        -0.05665011703968048,
        0.056041691452264786,
        -0.054772861301898956,
        -0.018116137012839317,
        -0.052528418600559235,
        -0.033883705735206604,
        0.04679235443472862,
        -0.03580813109874725,
        -0.05597510561347008,
        0.05587787181138992,
        -0.0472593754529953,
        0.05221828073263168,
        0.05651187524199486,
        -0.04577673599123955,
        -0.028411418199539185,
        0.035638853907585144,
        -0.05164150148630142,
        0.04836703836917877,
        -0.032886989414691925,
        0.0511666014790535,
        0.04348175227642059,
        0.05577800050377846,
        -0.024169767275452614,
        -0.043955523520708084,
        -0.05245154723525047,
        -0.05431654304265976,
        -0.056155409663915634,
        -0.02910001203417778,
        0.05433439835906029,
        -0.045074377208948135,
        -0.012593687511980534,
        0.05310794711112976,
        -0.0449615940451622,
        -0.05364734306931496,
        -0.008814469911158085,
        0.05377056822180748,
        0.052084144204854965,
        0.009231755509972572,
        0.05287255346775055,
        0.05657517537474632,
        0.05665099248290062,
        0.022163312882184982,
        0.0536576509475708,
        0.05370680242776871,
        -0.05276915431022644,
        0.03282856196165085,
        -0.05335714668035507,
        -0.020896339789032936,
        0.05532536655664444,
        -0.0552016906440258,
        -0.052153974771499634,
        0.034022629261016846,
        -0.04552453011274338,
        -0.055400505661964417,
        0.054942406713962555,
        -0.012472002767026424,
        -0.0560905821621418,
        0.05608224496245384,
        -0.046918876469135284,
        -0.02023124322295189,
        0.017382768914103508,
        -0.015202675014734268,
        0.018625162541866302,
        -0.04336908087134361,
        0.05298284813761711,
        -0.0006013159872964025,
        -0.04487485811114311,
        0.05605668947100639,
        -0.05479983612895012,
        0.05629172548651695,
        0.009138576686382294,
        0.05437261611223221,
        0.054503608494997025,
        0.056606508791446686,
        -0.04255479574203491,
        0.03372260183095932,
        0.0486878864467144,
        -0.02859886735677719,
        -0.034937236458063126,
        0.014733545482158661,
        0.04105895012617111,
        0.03492421656847,
        -0.048817217350006104,
        -0.05304797738790512,
        0.0380757711827755,
        0.047330573201179504,
        0.05164114385843277,
        0.008152946829795837,
        -0.05660593509674072,
        -0.026883520185947418,
        -0.022891776636242867,
        0.05047883838415146,
        -0.026816170662641525,
        -0.052150908857584,
        -0.056485384702682495,
        -0.0347784161567688,
        0.011802931316196918,
        0.05639328062534332,
        -0.009180867113173008,
        0.004614497069269419,
        -0.004472856409847736,
        0.02005230262875557,
        -0.056223198771476746,
        0.04437358304858208,
        0.014880985021591187,
        -0.030166709795594215,
        -0.054607734084129333,
        0.05599018186330795,
        0.0543772429227829,
        -0.05622219294309616,
        0.011428942903876305,
        -0.012788062915205956,
        0.05038633197546005,
        -0.004169833846390247,
        0.012915778905153275,
        -0.05249326676130295,
        -0.041131094098091125,
        0.05649278685450554,
        0.05618775635957718,
        0.055228352546691895,
        0.0563097707927227,
        -0.05068565905094147,
        0.045671142637729645,
        0.0529613122344017,
        0.05554863438010216,
        -0.028668247163295746,
        -0.055798567831516266,
        0.05477827787399292,
        0.018037106841802597,
        -0.056650448590517044,
        -0.055430810898542404,
        -0.028331954032182693,
        -0.04140233248472214,
        0.053229108452796936,
        0.056095536798238754,
        0.008614296093583107,
        -0.03885655850172043,
        0.04530485346913338,
        -0.05610554292798042,
        0.05513066425919533,
        -0.01536560244858265,
        -0.046953149139881134,
        -0.04984978586435318,
        -0.05194699391722679,
        -0.05546353757381439,
        -0.05095650255680084,
        0.04953970015048981,
        0.04788989573717117,
        0.056528352200984955,
        -0.055206049233675,
        0.056018758565187454,
        0.056117989122867584,
        0.05584277957677841,
        -0.050748664885759354,
        0.054116781800985336,
        -0.056432612240314484,
        -0.028409311547875404,
        0.0546390563249588,
        -0.0512889139354229,
        -0.05626169592142105,
        -0.025173766538500786,
        -0.04766818881034851,
        0.031439803540706635,
        -0.045344412326812744,
        -0.056610364466905594,
        -0.05226169154047966,
        -0.04391193389892578,
        0.05582817643880844,
        0.05408908426761627,
        0.050326600670814514,
        -0.04674213379621506,
        -0.05058320239186287,
        -0.05581754818558693,
        -0.00026348797837272286,
        0.05475911125540733,
        0.008391578681766987,
        -0.05077997222542763,
        -0.04586898535490036,
        -0.02004401944577694,
        0.041980065405368805,
        -0.04724467173218727,
        0.056359101086854935,
        0.009888679720461369,
        -0.035649411380290985,
        -0.000040998405893333256,
        -0.055147551000118256,
        -0.033747296780347824,
        0.05057648569345474,
        -0.034678034484386444,
        0.015502462163567543,
        -0.05655558034777641,
        0.05256271734833717,
        0.04096003994345665,
        0.056120410561561584,
        -0.04783901944756508,
        -0.04933493956923485,
        -0.05659717693924904,
        -0.026960689574480057,
        -0.05168053135275841,
        -0.04968884587287903,
        0.04215983673930168,
        0.008701452054083347,
        -0.05610807612538338,
        0.05519209802150726,
        0.03553088754415512,
        0.005609358195215464,
        -0.012849107384681702,
        -0.05024377256631851,
        -0.05272690951824188,
        -0.05548376962542534,
        0.0561528243124485,
        0.046558208763599396,
        -0.05653921887278557,
        -0.048975419253110886,
        0.05521932244300842,
        -0.024695541709661484,
        0.055509746074676514,
        -0.00797912385314703,
        -0.009959809482097626,
        0.05267315357923508,
        -0.05622837692499161,
        -0.017380498349666595,
        0.05249664932489395,
        0.0319996140897274,
        0.04144173115491867,
        0.03535601869225502,
        0.020011233165860176,
        0.045150045305490494,
        -0.05377835035324097,
        -0.01958177424967289,
        -0.055382851511240005,
        0.02260180562734604,
        0.05461394786834717,
        0.05529380962252617,
        -0.0009716328931972384,
        -0.05597296357154846,
        0.05663728341460228,
        0.056013867259025574,
        0.0565132275223732,
        -0.056388549506664276,
        0.05568915605545044,
        0.05385017395019531,
        -0.055627770721912384,
        0.010476892814040184,
        0.050210196524858475,
        0.05211088806390762,
        0.037834618240594864,
        -0.045132510364055634,
        0.01756901852786541,
        0.020190205425024033,
        0.024205248802900314,
        -0.045757047832012177,
        -0.03914724290370941,
        -0.054472293704748154,
        -0.00029310022364370525,
        0.03967829793691635,
        0.001472913776524365,
        0.04923044517636299,
        0.05069597065448761,
        0.05487191677093506,
        0.037615224719047546,
        -0.03308580070734024,
        0.000787946511991322,
        0.005910584237426519,
        -0.05651575326919556,
        -0.023422308266162872,
        -0.006554708816111088,
        -0.037595607340335846,
        0.03805093094706535,
        0.051709312945604324,
        -0.04408995807170868,
        0.05295519903302193,
        0.03729480877518654,
        -0.05474812537431717,
        0.014172914437949657,
        -0.052451420575380325,
        0.026836654171347618,
        0.05660906061530113,
        0.035198647528886795,
        0.05629397928714752,
        -0.012742865830659866,
        -0.00019903661450371146,
        -0.0566546656191349,
        0.05325104668736458,
        0.05650664120912552
    ],
    [
        -0.028978224843740463,
        0.03664940968155861,
        0.058518361300230026,
        -0.011155154556035995,
        0.040690552443265915,
        0.02305147610604763,
        0.06019672006368637,
        0.002055903198197484,
        -0.007974413223564625,
        0.051120974123477936,
        0.043158214539289474,
        0.047521792352199554,
        -0.05734895542263985,
        -0.012385569512844086,
        -0.05907251685857773,
        0.00041887580300681293,
        -0.054784778505563736,
        0.0587892048060894,
        -0.0390922985970974,
        -0.0545281358063221,
        -0.05602514371275902,
        0.011150581762194633,
        0.04488721489906311,
        0.0012532471446320415,
        0.03442005440592766,
        0.043321482837200165,
        0.02973143756389618,
        -0.011599664576351643,
        0.008209161460399628,
        0.01627056486904621,
        0.054334331303834915,
        -0.057984065264463425,
        -0.053154993802309036,
        -0.05896318703889847,
        0.04387195408344269,
        -0.03641144558787346,
        -0.053399134427309036,
        -0.00007331273081945255,
        0.04168318212032318,
        0.04309571161866188,
        0.02244262583553791,
        0.039149630814790726,
        0.0582604706287384,
        -0.04380002245306969,
        0.05889406427741051,
        0.0460766963660717,
        0.04942350089550018,
        0.06021123751997948,
        0.05918268859386444,
        0.045471902936697006,
        0.054569460451602936,
        0.04804597795009613,
        0.04380958527326584,
        0.0161836389452219,
        -0.03853514790534973,
        -0.055685997009277344,
        0.023630637675523758,
        -0.04335490241646767,
        -0.04426462575793266,
        -0.010685893706977367,
        -0.05767906829714775,
        -0.041664499789476395,
        -0.05524652451276779,
        -0.02531019039452076,
        0.039799805730581284,
        -0.018067089840769768,
        0.03147176280617714,
        0.02655605413019657,
        0.033691298216581345,
        -0.06037302315235138,
        -0.05973941460251808,
        -0.04609312117099762,
        0.00707973213866353,
        0.026774071156978607,
        -0.03320980817079544,
        -0.005285157822072506,
        0.014679704792797565,
        0.05433638021349907,
        0.05057268589735031,
        -0.05776096135377884,
        -0.04147828742861748,
        0.03652695193886757,
        -0.04595298692584038,
        0.006998211611062288,
        -0.0208806823939085,
        0.03547690808773041,
        -0.013484960421919823,
        -0.04933618754148483,
        -0.05143575370311737,
        0.054942723363637924,
        -0.05931691452860832,
        -0.043747495859861374,
        0.041231587529182434,
        -0.03930911421775818,
        -0.010736974887549877,
        -0.0005202422617003322,
        -0.041102051734924316,
        -0.05886210501194,
        -0.0307961143553257,
        -0.03826727718114853,
        -0.059830065816640854,
        -0.03336746618151665,
        0.05023230239748955,
        0.05942201241850853,
        0.03574204072356224,
        -0.05981789901852608,
        -0.05752314627170563,
        0.025196192786097527,
        -0.03738555312156677,
        -0.05572809651494026,
        -0.05297354608774185,
        -0.059864819049835205,
        0.004555084742605686,
        -0.012110027484595776,
        0.05679459869861603,
        0.0037785230670124292,
        0.03933418169617653,
        -0.055270206183195114,
        -0.05728735402226448,
        -0.012797754257917404,
        -0.05557610094547272,
        -0.006278085056692362,
        -0.05003058910369873,
        0.04468836635351181,
        -0.05982173979282379,
        -0.031065192073583603,
        -0.026776514947414398,
        -0.041326239705085754,
        0.0577087365090847,
        0.047888610512018204,
        0.05992898717522621,
        -0.04057523235678673,
        -0.04983911290764809,
        0.053667858242988586,
        -0.052749041467905045,
        -0.04869898036122322,
        0.04616423323750496,
        -0.058992836624383926,
        0.04805988445878029,
        0.05812019482254982,
        -0.02735789492726326,
        0.023684484884142876,
        0.05667981877923012,
        0.0525963194668293,
        -0.05776980519294739,
        -0.024453233927488327,
        0.055844634771347046,
        -0.04253264144062996,
        -0.05109773203730583,
        0.029595766216516495,
        -0.05907908082008362,
        -0.05989198386669159,
        -0.05073055252432823,
        0.02133330889046192,
        0.0025380877777934074,
        -0.05784051865339279,
        0.04966207593679428,
        -0.004326738882809877,
        0.04967418685555458,
        -0.03381410613656044,
        -0.004376675933599472,
        -0.047530967742204666,
        0.0561160147190094,
        -0.031128492206335068,
        0.029096156358718872,
        0.04562634602189064,
        -0.008673492819070816,
        -0.032214440405368805,
        0.0489518865942955,
        0.05344732105731964,
        -0.05211705341935158,
        0.04908675327897072,
        -0.04878642410039902,
        -0.046387284994125366,
        -0.029243776574730873,
        0.028830215334892273,
        -0.008433320559561253,
        0.05952164903283119,
        0.05979546904563904,
        -0.04270082339644432,
        0.02786148339509964,
        0.056348785758018494,
        0.0574994757771492,
        0.0058463625609874725,
        -0.04488631710410118,
        -0.048760805279016495,
        -0.049076393246650696,
        0.05878448113799095,
        -0.03613949567079544,
        0.02398492395877838,
        -0.024747973307967186,
        -0.02281675674021244,
        -0.042184337973594666,
        -0.052165430039167404,
        -0.046243853867053986,
        0.050129782408475876,
        0.052278049290180206,
        -0.039619505405426025,
        0.025582684203982353,
        0.013449971564114094,
        0.05631883069872856,
        0.03836408630013466,
        -0.0016055485466495156,
        -0.045003168284893036,
        0.05718746781349182,
        0.01623290404677391,
        0.04147326573729515,
        0.04706985875964165,
        -0.014501565136015415,
        0.053223032504320145,
        -0.05576900765299797,
        0.012823502533137798,
        -0.041640087962150574,
        0.007290140260010958,
        0.04617325961589813,
        -0.030408702790737152,
        0.039946019649505615,
        0.01830175146460533,
        0.05491730943322182,
        -0.03812732547521591,
        0.0259335208684206,
        -0.04732286557555199,
        -0.02560567855834961,
        -0.05747438594698906,
        0.052277419716119766,
        -0.05149851366877556,
        -0.03576662763953209,
        -0.03314210847020149,
        0.04602169990539551,
        -0.060271184891462326,
        -0.040044575929641724,
        0.013389053754508495,
        -0.05634370818734169,
        0.048459865152835846,
        0.042051054537296295,
        -0.014885703101754189,
        0.05950826406478882,
        0.01992727629840374,
        -0.020731385797262192,
        0.05806029587984085,
        0.05539616197347641,
        -0.04122535511851311,
        -0.013659111224114895,
        -0.04617805406451225,
        0.007468731142580509,
        0.01244207751005888,
        0.048436157405376434,
        0.05131663382053375,
        -0.05979466810822487,
        -0.027461713179945946,
        -0.04911735653877258,
        -0.05871841683983803,
        -0.05330176651477814,
        -0.05822109058499336,
        0.04663889855146408,
        0.05913111940026283,
        0.0428946278989315,
        0.00607227673754096,
        0.05864095687866211,
        0.03815019503235817,
        0.02121848613023758,
        0.056620482355356216,
        0.05874044448137283,
        -0.012597638182342052,
        0.04984631389379501,
        0.05728374421596527,
        0.009576095268130302,
        0.0463833250105381,
        0.02784930355846882,
        -0.05918938294053078,
        -0.014402853325009346,
        -0.05823951214551926,
        0.05614810809493065,
        -0.054524488747119904,
        -0.030821632593870163,
        0.056541673839092255,
        -0.05757348984479904,
        0.021403606981039047,
        -0.0026592642534524202,
        -0.056852057576179504,
        -0.059421785175800323,
        0.03571435809135437,
        -0.04515492916107178,
        -0.019137920811772346,
        0.004509589169174433,
        -0.03728985786437988,
        0.055846959352493286,
        -0.04144849255681038,
        -0.05153515189886093,
        -0.01936299540102482,
        -0.0562204085290432,
        0.026535674929618835,
        -0.00476442975923419,
        -0.04716632142663002,
        0.04941250756382942,
        -0.05734988674521446,
        0.06034356355667114,
        -0.05407921224832535,
        -0.03556431084871292,
        0.05579683557152748,
        -0.02585337683558464,
        0.049087971448898315,
        -0.04108226299285889,
        0.041192833334207535,
        0.025911372154951096,
        -0.018348898738622665,
        -0.018609220162034035,
        0.056893158704042435,
        0.05772998183965683,
        -0.05718672648072243,
        0.05116821080446243,
        -0.03256557509303093,
        -0.027496013790369034,
        0.05950574576854706,
        -0.054998140782117844,
        -0.05767813324928284,
        0.03012297861278057,
        -0.017147256061434746,
        -0.004870851524174213,
        -0.059024326503276825,
        0.03639981150627136,
        -0.05493364855647087,
        0.04878901317715645,
        -0.013939857482910156,
        0.058325499296188354,
        0.05976938456296921,
        0.03362436592578888,
        0.03297324478626251,
        0.056160617619752884,
        -0.05751945450901985,
        -0.040580421686172485,
        -0.017771361395716667,
        0.05021879822015762,
        0.008866378106176853,
        0.0598633699119091,
        -0.02587725780904293,
        -0.053016047924757004,
        0.06005265936255455,
        -0.05868799239397049,
        0.057244427502155304,
        0.005749877076596022,
        -0.023607948794960976,
        -0.05805686116218567,
        -0.038822419941425323,
        0.06035406515002251,
        -0.004946961533278227,
        0.04347854480147362,
        0.04763854295015335,
        0.02939174696803093,
        0.05572172626852989,
        -0.060245007276535034,
        0.03683799132704735,
        -0.047072891145944595,
        -0.0602320097386837,
        -0.058753326535224915,
        0.05350170657038689,
        -0.050866398960351944,
        -0.009323637932538986,
        -0.042566847056150436,
        -0.052201785147190094,
        0.05840590223670006,
        0.05332403630018234,
        -0.05284656211733818,
        -0.05038653686642647,
        0.027862483635544777,
        -0.05663701519370079,
        0.06041181460022926,
        0.05526336655020714,
        0.02710774913430214,
        -0.06011875346302986,
        -0.004729589447379112,
        -0.05799369513988495,
        0.056981757283210754,
        0.041304636746644974,
        0.05010268837213516,
        0.05009789764881134,
        0.02546132169663906,
        0.013175555504858494,
        0.009099511429667473,
        -0.012348449788987637,
        -0.05664978176355362,
        0.047703854739665985,
        -0.058663420379161835,
        -0.05658880993723869,
        0.06027906388044357,
        -0.05065946653485298,
        0.018079305067658424,
        -0.058516521006822586,
        -0.053563594818115234,
        -0.05374510958790779,
        0.028208136558532715,
        0.056695740669965744,
        0.034736547619104385,
        -0.044657569378614426,
        -0.03187965229153633,
        0.05796367675065994,
        0.04719829931855202,
        -0.058510880917310715,
        -0.029942791908979416,
        -0.04928568750619888,
        0.0034493249841034412,
        0.04275514557957649,
        0.024424953386187553,
        0.05770242586731911,
        -0.056259993463754654,
        -0.034297551959753036,
        0.036117855459451675,
        0.020062997937202454,
        0.05972547084093094,
        -0.05179911479353905,
        0.0027538887225091457,
        -0.057228934019804,
        -0.04209629073739052,
        0.0394144207239151,
        0.056950874626636505,
        -0.0074394154362380505,
        0.0556388795375824,
        0.023193784058094025,
        0.008344024419784546,
        0.04951022192835808,
        0.037144411355257034,
        -0.05606985092163086,
        -0.04073967784643173,
        -0.05999230220913887,
        -0.020515061914920807,
        -0.038802873343229294,
        -0.05425918847322464,
        -0.05143088102340698,
        -0.059799835085868835,
        -0.027514828369021416,
        0.057448677718639374,
        -0.008337379433214664,
        0.01782071217894554,
        0.056435052305459976,
        -0.023310860618948936,
        -0.04387053847312927,
        -0.006644485518336296,
        -0.019982734695076942,
        0.05948107689619064,
        -0.0579313300549984,
        -0.031076353043317795,
        0.048665601760149,
        0.022884072735905647,
        0.05697090923786163,
        -0.02154560200870037,
        -0.0320529080927372,
        0.06039317697286606,
        -0.05986161157488823,
        0.05628226324915886,
        -0.057739611715078354,
        -0.057042594999074936,
        0.03818616271018982,
        0.05140247195959091,
        -0.042581185698509216,
        -0.051475588232278824,
        -0.0357343889772892,
        -0.050890207290649414,
        0.04129831865429878,
        0.013731719925999641,
        0.03759327530860901,
        0.0513496994972229,
        0.04056219756603241,
        -0.05657268688082695,
        0.046994734555482864,
        0.04676606506109238,
        0.06021733582019806,
        -0.06029403582215309,
        0.059938207268714905,
        -0.009953091852366924,
        -0.05992564931511879,
        0.0362715907394886,
        0.052300333976745605,
        0.050908513367176056,
        0.04944160208106041,
        0.0411241389811039,
        0.05740634351968765,
        -0.04522116109728813,
        0.007017901167273521,
        0.02102549932897091,
        -0.02013954147696495,
        -0.05875183269381523,
        0.04158817604184151,
        0.04119750112295151,
        0.04488212987780571,
        0.003809344721958041,
        0.0584869422018528,
        0.05536210909485817,
        -0.006084765773266554,
        -0.05276470631361008,
        -0.04302448034286499,
        0.0006980028701946139,
        -0.05382277071475983,
        -0.02483006939291954,
        0.05916542187333107,
        0.055598050355911255,
        0.04632291570305824,
        -0.042820125818252563,
        0.023375889286398888,
        0.0593377910554409,
        0.04507166147232056,
        -0.05809344723820686,
        -0.0314740426838398,
        -0.057844918221235275,
        -0.014064875431358814,
        0.05603961646556854,
        0.001562265446409583,
        0.05911342054605484,
        -0.007996685802936554,
        -0.04454907029867172,
        -0.05829296261072159,
        0.05934092774987221,
        0.05287215858697891
    ],
    [
        -0.037020936608314514,
        0.03447485715150833,
        0.04835490137338638,
        -0.0029373103752732277,
        0.05486065894365311,
        -0.030275659635663033,
        0.06051930412650108,
        0.00782463513314724,
        0.021607886999845505,
        0.04696743190288544,
        0.041682127863168716,
        0.056775808334350586,
        -0.05718643218278885,
        -0.007745766546577215,
        -0.05888867378234863,
        -0.005033174529671669,
        -0.049829453229904175,
        0.058450739830732346,
        -0.05523442476987839,
        -0.03065483085811138,
        -0.04798956215381622,
        0.010722079314291477,
        0.043770913034677505,
        0.04613785073161125,
        0.03759041428565979,
        -0.021082542836666107,
        0.03769291564822197,
        0.027178877964615822,
        -0.033394791185855865,
        0.007041072007268667,
        0.05970162898302078,
        -0.04409901425242424,
        -0.045309413224458694,
        -0.058364640921354294,
        0.02436925657093525,
        -0.05152340978384018,
        -0.05354545637965202,
        -0.031680963933467865,
        0.026010658591985703,
        0.0524635836482048,
        0.019810855388641357,
        0.02453884296119213,
        0.057243164628744125,
        -0.05368740111589432,
        0.05535408481955528,
        0.04766717553138733,
        0.03100133128464222,
        0.05958758294582367,
        0.0596165731549263,
        0.04085856303572655,
        0.0563415102660656,
        -0.0252032782882452,
        0.052366290241479874,
        -0.052615344524383545,
        -0.03576359152793884,
        -0.04332008212804794,
        0.020449776202440262,
        -0.01147641334682703,
        -0.014714328572154045,
        0.019691752269864082,
        -0.05754885822534561,
        -0.0003448271891102195,
        -0.04860672727227211,
        0.011449524201452732,
        0.0021496035624295473,
        0.045558564364910126,
        -0.03631400316953659,
        -0.015995541587471962,
        0.047085803002119064,
        -0.06075633317232132,
        -0.0603473000228405,
        -0.06037262827157974,
        0.05367167666554451,
        0.0424477681517601,
        -0.03523918241262436,
        0.00981992669403553,
        0.03287174925208092,
        0.05911708623170853,
        0.04474340379238129,
        -0.05580060929059982,
        -0.04366219416260719,
        0.02705119363963604,
        -0.0476919561624527,
        0.009820050559937954,
        0.027705468237400055,
        0.028481977060437202,
        -0.011836525052785873,
        -0.04206275939941406,
        -0.028172820806503296,
        0.05662707984447479,
        -0.05583176761865616,
        0.015159453265368938,
        0.0371394008398056,
        -0.020214345306158066,
        -0.02001749724149704,
        -0.029890934005379677,
        -0.058284059166908264,
        -0.059878818690776825,
        -0.05598790571093559,
        -0.044995393604040146,
        -0.06051206216216087,
        -0.02030770853161812,
        0.05498940870165825,
        0.06059885397553444,
        0.03777281939983368,
        -0.05952325463294983,
        -0.057206589728593826,
        0.04440676420927048,
        -0.028934268280863762,
        -0.047628793865442276,
        -0.06019413843750954,
        -0.058744676411151886,
        -0.04545803368091583,
        -0.04583485424518585,
        0.05635964870452881,
        -0.027686093002557755,
        0.054177068173885345,
        -0.05650797486305237,
        -0.05760078504681587,
        -0.004201814997941256,
        -0.05935176834464073,
        -0.028230542317032814,
        -0.04751305654644966,
        0.005603468511253595,
        -0.058981820940971375,
        0.00841777864843607,
        -0.04636216536164284,
        0.005038599018007517,
        0.059330299496650696,
        0.0500832162797451,
        0.05826509743928909,
        -0.048214640468358994,
        -0.05159054696559906,
        0.048190947622060776,
        -0.05471502244472504,
        -0.05255439877510071,
        0.035952936857938766,
        -0.06032607704401016,
        0.002442349912598729,
        0.058337971568107605,
        0.02840861678123474,
        0.03824622184038162,
        0.041766464710235596,
        0.0535113662481308,
        -0.0031621723901480436,
        -0.014933716505765915,
        0.05949690565466881,
        -0.05347802862524986,
        -0.05227174609899521,
        0.05826614052057266,
        -0.05717802047729492,
        -0.06043766438961029,
        -0.05330907553434372,
        0.0014515959192067385,
        0.03749365359544754,
        -0.057864267379045486,
        0.05750318989157677,
        0.026755981147289276,
        0.0008604134782217443,
        -0.040485743433237076,
        0.045319460332393646,
        -0.00043830773211084306,
        0.03514006361365318,
        -0.040351610630750656,
        0.011717970483005047,
        0.03904150053858757,
        0.02624000795185566,
        -0.0459287166595459,
        0.038370873779058456,
        0.05649562180042267,
        -0.05265481024980545,
        0.04004738852381706,
        -0.04787275195121765,
        -0.0049025812186300755,
        -0.0545450784265995,
        0.0352836437523365,
        -0.05440675467252731,
        0.057767678052186966,
        0.05963679403066635,
        -0.01916409656405449,
        0.05207304283976555,
        0.02567799761891365,
        0.05980965122580528,
        0.05517318844795227,
        0.0018465310567989945,
        -0.0509486123919487,
        -0.024083249270915985,
        0.05949697270989418,
        -0.04296376183629036,
        -0.045362185686826706,
        -0.04883929714560509,
        -0.025782857090234756,
        -0.00881524384021759,
        -0.024149660021066666,
        -0.04359491914510727,
        0.0406990572810173,
        0.05486983805894852,
        -0.03333824872970581,
        -0.0037034591659903526,
        0.03092416748404503,
        0.04595302417874336,
        0.055323731154203415,
        -0.03665003925561905,
        0.01863115094602108,
        0.04451748728752136,
        0.05195869132876396,
        0.038332898169755936,
        0.02292376384139061,
        -0.021566392853856087,
        -0.005806247238069773,
        -0.05655374377965927,
        0.030645666643977165,
        -0.053068894892930984,
        0.017734026536345482,
        0.05180882290005684,
        -0.02058405429124832,
        0.060137197375297546,
        0.023099716752767563,
        0.057821061462163925,
        -0.011119442991912365,
        0.012521765194833279,
        -0.05458933860063553,
        -0.03607786074280739,
        -0.06045392528176308,
        0.05752868950366974,
        -0.05147233605384827,
        0.03145979344844818,
        0.023746155202388763,
        0.05192236974835396,
        -0.0604560412466526,
        -0.009421369060873985,
        -0.04070005938410759,
        -0.05975157022476196,
        0.05156012997031212,
        0.05411339923739433,
        0.022530652582645416,
        0.05912834033370018,
        0.05712828040122986,
        -0.03287748247385025,
        0.05702481046319008,
        0.058939378708601,
        0.028170960023999214,
        -0.002102235797792673,
        -0.02857627160847187,
        0.04733593761920929,
        0.010296497493982315,
        0.04349830374121666,
        0.05435199290513992,
        -0.05990733578801155,
        -0.046297792345285416,
        -0.022165650501847267,
        -0.05983293056488037,
        0.005538823548704386,
        -0.059106770902872086,
        0.04876061528921127,
        0.06041719391942024,
        -0.01697627641260624,
        -0.017204832285642624,
        0.05991334840655327,
        0.05812607333064079,
        0.05630502477288246,
        0.05798715725541115,
        0.057483281940221786,
        -0.0378824919462204,
        0.006324129644781351,
        0.04799305275082588,
        0.04462096095085144,
        0.007031452376395464,
        0.05397846922278404,
        -0.05069190636277199,
        -0.015212793834507465,
        -0.06029926985502243,
        0.052962299436330795,
        -0.058513447642326355,
        0.010437850840389729,
        0.0411691851913929,
        -0.059139084070920944,
        0.050835613161325455,
        -0.04324978590011597,
        -0.06017244979739189,
        -0.05892264097929001,
        -0.04417172819375992,
        0.0035678716376423836,
        0.026851464062929153,
        -0.0035773657727986574,
        -0.015275171957910061,
        0.03486129269003868,
        -0.05719764530658722,
        -0.053481344133615494,
        -0.02213471755385399,
        -0.003909220453351736,
        0.01576262339949608,
        0.0353359580039978,
        -0.04466687887907028,
        0.024694927036762238,
        -0.05697311460971832,
        0.060716841369867325,
        0.012544268742203712,
        -0.029715510085225105,
        0.05551084503531456,
        0.007953763008117676,
        0.0575302392244339,
        -0.015192702412605286,
        0.05512649938464165,
        -0.005439502187073231,
        0.014869826845824718,
        -0.029493259266018867,
        0.05528588593006134,
        0.052055831998586655,
        -0.06058894842863083,
        0.05044150725007057,
        -0.037886250764131546,
        -0.028888195753097534,
        0.059984926134347916,
        -0.05902271345257759,
        -0.057277292013168335,
        0.02143792435526848,
        0.016752246767282486,
        0.009699389338493347,
        -0.05602502450346947,
        -0.024338526651263237,
        0.014640239998698235,
        0.055657122284173965,
        -0.037038013339042664,
        0.06013643369078636,
        0.053418293595314026,
        0.047525372356176376,
        -0.021787794306874275,
        0.030195757746696472,
        -0.06010386720299721,
        -0.040021445602178574,
        0.04224325343966484,
        0.04728182777762413,
        -0.035445988178253174,
        0.05971697345376015,
        -0.015792248770594597,
        -0.04323551431298256,
        0.0606619231402874,
        -0.049444831907749176,
        0.05832810327410698,
        0.02562469057738781,
        -0.021757718175649643,
        -0.04560654237866402,
        -0.0358022041618824,
        0.060775090008974075,
        0.010034692473709583,
        0.0034229846205562353,
        0.02996688336133957,
        -0.036720212548971176,
        0.056735165417194366,
        -0.06044793874025345,
        0.03870485723018646,
        -0.040165144950151443,
        -0.060612089931964874,
        -0.05998954921960831,
        0.053070325404405594,
        -0.05330537259578705,
        -0.03799821436405182,
        -0.024303792044520378,
        -0.051447395235300064,
        0.05706743896007538,
        0.04837267845869064,
        -0.059434372931718826,
        -0.03625750541687012,
        0.03460478410124779,
        -0.05636874586343765,
        0.0606902539730072,
        0.042677268385887146,
        0.04489930719137192,
        -0.06038688123226166,
        -0.018793709576129913,
        -0.0599648654460907,
        0.05723889172077179,
        -0.0209779255092144,
        0.039257969707250595,
        0.059850022196769714,
        0.02677147090435028,
        0.055357493460178375,
        -0.036469023674726486,
        -0.02790662832558155,
        -0.05749243497848511,
        0.053050898015499115,
        -0.057797353714704514,
        -0.013934734277427197,
        0.06054382771253586,
        -0.046501949429512024,
        -0.001158594386652112,
        -0.059903889894485474,
        -0.05452106148004532,
        -0.060317087918519974,
        0.026992088183760643,
        0.01965107023715973,
        0.01115717925131321,
        -0.03232387825846672,
        -0.05047127604484558,
        0.058099184185266495,
        0.05438175052404404,
        -0.05975760146975517,
        -0.025574535131454468,
        -0.05814971774816513,
        -0.0008503291755914688,
        0.057922884821891785,
        0.05360853299498558,
        0.05733738839626312,
        -0.055968791246414185,
        -0.02943030185997486,
        0.051933158189058304,
        0.011172744445502758,
        0.05799340829253197,
        -0.03646491840481758,
        -0.00970448274165392,
        -0.05391314998269081,
        -0.013023910112679005,
        0.04854154586791992,
        0.05689484626054764,
        -0.022639211267232895,
        0.01632341556251049,
        0.04134099557995796,
        0.03773488849401474,
        0.05117027834057808,
        -0.044568102806806564,
        -0.05594511702656746,
        -0.03769974038004875,
        -0.06058834493160248,
        -0.022419970482587814,
        0.002240602858364582,
        -0.009889906272292137,
        -0.052973635494709015,
        -0.05958357825875282,
        -0.04249140992760658,
        0.054830871522426605,
        -0.03333931416273117,
        0.031141677871346474,
        0.05782369151711464,
        -0.01009464543312788,
        -0.004047426860779524,
        -0.01206014771014452,
        0.04469718411564827,
        0.057889293879270554,
        -0.056563373655080795,
        -0.02631358802318573,
        0.056264545768499374,
        0.0405241884291172,
        0.05921603739261627,
        -0.044682253152132034,
        0.019130852073431015,
        0.060424428433179855,
        -0.060061290860176086,
        0.05906612053513527,
        -0.047330427914857864,
        -0.04923160374164581,
        0.046422943472862244,
        0.043775297701358795,
        -0.05612493306398392,
        0.007632737047970295,
        -0.017245106399059296,
        -0.05616127699613571,
        0.017833858728408813,
        -0.027941374108195305,
        0.0563269704580307,
        0.030952727422118187,
        -0.03395050764083862,
        -0.05995224043726921,
        -0.04562844708561897,
        0.05863676965236664,
        0.06054731085896492,
        -0.060546986758708954,
        0.060470979660749435,
        -0.038018614053726196,
        -0.060559578239917755,
        0.04263197258114815,
        0.04911414533853531,
        0.042755354195833206,
        0.045484427362680435,
        0.05053427442908287,
        0.045908521860837936,
        0.0017964289290830493,
        -0.012743194587528706,
        -0.03237428516149521,
        -0.0077295247465372086,
        -0.054398417472839355,
        0.05169354006648064,
        0.04908781871199608,
        0.042791444808244705,
        -0.004750158172100782,
        0.05890808254480362,
        0.05998952314257622,
        -0.012872871942818165,
        -0.054297659546136856,
        -0.04163157194852829,
        -0.04835319519042969,
        -0.04690210521221161,
        -0.0495329312980175,
        0.05850132927298546,
        0.05687588080763817,
        0.019152633845806122,
        -0.009907533414661884,
        -0.008505850099027157,
        0.059279099106788635,
        0.055528674274683,
        -0.0599362887442112,
        -0.051589589565992355,
        -0.059216953814029694,
        -0.015163753181695938,
        0.050900403410196304,
        0.04000822827219963,
        0.05936642363667488,
        0.02566622756421566,
        -0.05622215196490288,
        -0.05755486711859703,
        0.05963417515158653,
        0.06054019182920456
    ],
    [
        -0.029541410505771637,
        0.053485311567783356,
        0.05957085266709328,
        0.020723232999444008,
        0.04661393165588379,
        0.019527960568666458,
        0.061811842024326324,
        0.02815813198685646,
        0.03454095125198364,
        0.04520202800631523,
        0.02755499817430973,
        0.031010152772068977,
        -0.05951963737607002,
        -0.017666226252913475,
        -0.06198204308748245,
        0.029200861230492592,
        -0.035632822662591934,
        0.06089887395501137,
        -0.02412547543644905,
        -0.03840313479304314,
        0.006658837199211121,
        -0.03306876868009567,
        0.03479929640889168,
        0.019238311797380447,
        0.025736715644598007,
        0.03348734974861145,
        0.042471565306186676,
        0.004609666299074888,
        -0.015345597639679909,
        -0.005944682285189629,
        0.043295737355947495,
        0.0018903585150837898,
        -0.04175209254026413,
        -0.054396852850914,
        0.04531862959265709,
        -0.05032386630773544,
        -0.053267255425453186,
        -0.05702769011259079,
        0.03928598761558533,
        0.02461862377822399,
        0.030902782455086708,
        0.004835232626646757,
        0.055281661450862885,
        -0.025915883481502533,
        0.05879710987210274,
        0.02579326555132866,
        0.033207058906555176,
        0.061976633965969086,
        0.06174474209547043,
        0.03732564300298691,
        0.05772347375750542,
        0.01014530286192894,
        0.052058055996894836,
        -0.03840798884630203,
        -0.014753592200577259,
        -0.0510517843067646,
        0.009627901017665863,
        -0.03623029589653015,
        -0.049920257180929184,
        0.01827951893210411,
        -0.06060146912932396,
        -0.01339423656463623,
        -0.049212682992219925,
        0.018245451152324677,
        0.037652645260095596,
        0.03138549253344536,
        -0.02077549695968628,
        0.01399051584303379,
        0.016978414729237556,
        -0.06259709596633911,
        -0.062148068100214005,
        -0.061200156807899475,
        0.04558849707245827,
        -0.014065954834222794,
        -0.028028206899762154,
        0.012707468122243881,
        -0.0013824602356180549,
        0.061372194439172745,
        0.05968700349330902,
        -0.06066436320543289,
        -0.047931838780641556,
        0.020812714472413063,
        -0.04256926849484444,
        0.04577009007334709,
        -0.012690175324678421,
        0.051459621638059616,
        -0.008944200351834297,
        -0.05667584761977196,
        -0.024256834760308266,
        0.05805802345275879,
        -0.05485258623957634,
        -0.029236959293484688,
        -0.008092910051345825,
        -0.03211545944213867,
        0.007256628014147282,
        -0.018977463245391846,
        -0.05125591158866882,
        -0.06235896795988083,
        -0.045560963451862335,
        -0.05191969498991966,
        -0.0625222846865654,
        0.009187278337776661,
        0.040027257055044174,
        0.06182025745511055,
        0.019818630069494247,
        -0.06224494427442551,
        -0.06199265271425247,
        0.04247879236936569,
        -0.021317800506949425,
        -0.03485483676195145,
        -0.04730590432882309,
        -0.06009680777788162,
        -0.04955585300922394,
        -0.011713402345776558,
        0.0506657175719738,
        0.00732203247025609,
        0.057877399027347565,
        -0.05158451572060585,
        -0.05889999493956566,
        -0.024024300277233124,
        -0.05945242941379547,
        -0.009176544845104218,
        -0.01644570752978325,
        0.030552128329873085,
        -0.061042990535497665,
        0.019689688459038734,
        -0.002528059296309948,
        0.018807778134942055,
        0.060640349984169006,
        0.03837373107671738,
        0.04899859428405762,
        -0.04555712267756462,
        -0.041361454874277115,
        0.018794618546962738,
        -0.05750337243080139,
        -0.04912006855010986,
        0.05046498030424118,
        -0.060464829206466675,
        0.041338324546813965,
        0.05876154825091362,
        0.009366033598780632,
        0.0018722364911809564,
        0.05506215989589691,
        0.057661157101392746,
        -0.020684728398919106,
        -0.050987064838409424,
        0.06024548038840294,
        -0.0601215697824955,
        -0.05013406276702881,
        0.05224374681711197,
        -0.05999657139182091,
        -0.062431253492832184,
        -0.05504979193210602,
        -0.04797978326678276,
        0.014789958484470844,
        -0.057967741042375565,
        0.058549705892801285,
        0.011015775613486767,
        0.047004371881484985,
        -0.028063731268048286,
        0.05844951793551445,
        0.028263280168175697,
        0.05179334431886673,
        -0.0014860909432172775,
        -0.017480550333857536,
        0.033669691532850266,
        0.042120084166526794,
        -0.026095565408468246,
        0.00045714894076809287,
        0.05904748663306236,
        -0.05547981336712837,
        0.042349040508270264,
        -0.049992743879556656,
        0.02524798922240734,
        -0.052201177924871445,
        0.04725327715277672,
        -0.03802243247628212,
        0.04904787242412567,
        0.06201603636145592,
        -0.019290883094072342,
        0.049039095640182495,
        0.052286166697740555,
        0.05851014703512192,
        0.03011995740234852,
        0.006128069944679737,
        -0.059930864721536636,
        -0.023032600060105324,
        0.06079951673746109,
        -0.04716905951499939,
        -0.020438192412257195,
        -0.024696143344044685,
        -0.04471166804432869,
        -0.03858114406466484,
        -0.03740345686674118,
        -0.051296480000019073,
        0.05660596117377281,
        0.0573013611137867,
        -0.025935649871826172,
        0.004667194560170174,
        -0.01182202436029911,
        0.041734762489795685,
        0.0425681509077549,
        0.019648082554340363,
        -0.009622666984796524,
        0.04325550049543381,
        0.003956612199544907,
        0.049019452184438705,
        -0.020359700545668602,
        -0.036725543439388275,
        0.03559618815779686,
        -0.057817600667476654,
        0.030623137950897217,
        -0.04846438765525818,
        0.016919946298003197,
        0.05080445855855942,
        -0.02134629152715206,
        0.058693889528512955,
        0.025459695607423782,
        0.05964674800634384,
        -0.031914349645376205,
        0.042547404766082764,
        -0.04490529000759125,
        -0.013779778964817524,
        -0.058382485061883926,
        0.06060250103473663,
        -0.04833880439400673,
        -0.01130835060030222,
        -0.0275912843644619,
        0.06029278412461281,
        -0.062442563474178314,
        -0.02818569727241993,
        -0.0426262803375721,
        -0.061936888843774796,
        0.05393705517053604,
        0.043884970247745514,
        -0.0009745006682351232,
        0.06141917034983635,
        0.04890068247914314,
        -0.014267895370721817,
        0.061514418572187424,
        0.05952315405011177,
        -0.006079284008592367,
        0.01873495988547802,
        -0.04644516482949257,
        0.03473939374089241,
        -0.0037360754795372486,
        0.052286576479673386,
        0.05618702620267868,
        -0.06063500791788101,
        -0.05251796916127205,
        -0.029519634321331978,
        -0.061792969703674316,
        0.0008345801034010947,
        -0.05871494114398956,
        0.015286660753190517,
        0.05760536342859268,
        0.02322866953909397,
        -0.023628689348697662,
        0.06167672574520111,
        0.03510004281997681,
        0.04894409328699112,
        0.05762092024087906,
        0.05374407023191452,
        -0.053224630653858185,
        0.0371578186750412,
        0.040727440267801285,
        0.05642490088939667,
        0.018285369500517845,
        0.0348099060356617,
        -0.05432822182774544,
        -0.017617754638195038,
        -0.061745185405015945,
        0.05734003335237503,
        -0.056723713874816895,
        0.01741788350045681,
        0.047249164432287216,
        -0.06173667684197426,
        0.024694813415408134,
        -0.043534643948078156,
        -0.0622626431286335,
        -0.060948703438043594,
        -0.010361810214817524,
        -0.027750330045819283,
        0.034667350351810455,
        -0.0037848332431167364,
        0.004531763028353453,
        0.05365373194217682,
        -0.050091564655303955,
        -0.047090329229831696,
        0.026318589225411415,
        -0.015055915340781212,
        0.0255652517080307,
        -0.027135225012898445,
        -0.0421062596142292,
        0.03648323565721512,
        -0.058144934475421906,
        0.06266168504953384,
        -0.016569139435887337,
        -0.04136889800429344,
        0.05880299583077431,
        -0.045969195663928986,
        0.05565778911113739,
        -0.017119156196713448,
        0.05306660383939743,
        -0.05056276544928551,
        0.016117379069328308,
        -0.023314058780670166,
        0.05828474462032318,
        0.045084256678819656,
        -0.060230206698179245,
        0.030936049297451973,
        -0.05413862317800522,
        -0.04387499764561653,
        0.057703059166669846,
        -0.057739365845918655,
        -0.05961456522345543,
        0.03709539398550987,
        0.016711333766579628,
        -0.0065283095464110374,
        -0.05687708407640457,
        0.021408770233392715,
        -0.045905422419309616,
        0.055767714977264404,
        -0.017588837072253227,
        0.06185902655124664,
        0.053375668823719025,
        0.030703091993927956,
        -0.03712458908557892,
        0.03364124894142151,
        -0.0531468465924263,
        -0.023914052173495293,
        0.017919551581144333,
        0.049419280141592026,
        -0.036406733095645905,
        0.06135416403412819,
        -0.04760878533124924,
        -0.022830000147223473,
        0.06221969425678253,
        -0.04678132012486458,
        0.05776549503207207,
        0.01150346640497446,
        -0.020538778975605965,
        -0.039963118731975555,
        0.009926863946020603,
        0.06276187300682068,
        0.01292505580931902,
        0.01472257450222969,
        0.050539832562208176,
        -0.00795110035687685,
        0.055037323385477066,
        -0.062081098556518555,
        0.024986015632748604,
        -0.04593499377369881,
        -0.06256943196058273,
        -0.055381327867507935,
        0.04798652604222298,
        -0.06005280837416649,
        -0.03274618089199066,
        -0.0429890900850296,
        -0.05776694789528847,
        0.05707209184765816,
        0.011730490252375603,
        -0.041542939841747284,
        -0.04660917818546295,
        -0.025441044941544533,
        -0.060133196413517,
        0.06267587095499039,
        0.030033238232135773,
        0.04463604465126991,
        -0.06228778883814812,
        -0.04124189913272858,
        -0.05964208394289017,
        0.06202038750052452,
        0.03423423320055008,
        0.04703139141201973,
        0.05618491396307945,
        -0.003552942303940654,
        0.061470773071050644,
        -0.016384657472372055,
        -0.010415508411824703,
        -0.05484890565276146,
        0.05781625956296921,
        -0.061629392206668854,
        -0.05120892822742462,
        0.062306880950927734,
        -0.054545119404792786,
        0.01744856871664524,
        -0.06230337172746658,
        -0.06010402739048004,
        -0.05810850113630295,
        -0.013975035399198532,
        0.045523229986429214,
        0.053490038961172104,
        -0.05886254087090492,
        -0.006535654421895742,
        0.05949357897043228,
        0.05091762915253639,
        -0.05973241850733757,
        -0.051110267639160156,
        -0.05520163103938103,
        -0.00015173324209172279,
        0.059101782739162445,
        0.034232739359140396,
        0.05185692757368088,
        -0.057229120284318924,
        -0.029983794316649437,
        0.04340565204620361,
        -0.02374279499053955,
        0.05937541276216507,
        -0.014106295071542263,
        -0.02175862155854702,
        -0.05026477947831154,
        0.024836206808686256,
        0.04980875551700592,
        0.0603027418255806,
        -0.006482960190623999,
        -0.0005313742440193892,
        0.04422292113304138,
        0.05424625054001808,
        0.04633339121937752,
        -0.03581085056066513,
        -0.053679972887039185,
        -0.0453648678958416,
        -0.061937544494867325,
        -0.04789309203624725,
        -0.014216640964150429,
        -0.05456807464361191,
        -0.05798663944005966,
        -0.06206562742590904,
        -0.01029705349355936,
        0.04348466172814369,
        -0.027696998789906502,
        0.011070323176681995,
        0.06147586926817894,
        -0.01773783564567566,
        -0.03351818025112152,
        -0.004137395415455103,
        0.02257899008691311,
        0.06009335815906525,
        -0.05384877324104309,
        0.001496614539064467,
        0.05520658195018768,
        0.04080894589424133,
        0.06154652684926987,
        -0.04638100415468216,
        0.01664629392325878,
        0.06241537258028984,
        -0.06042662262916565,
        0.05983279272913933,
        -0.04897170141339302,
        -0.05477403476834297,
        0.04677785560488701,
        0.04915338754653931,
        -0.03863320127129555,
        -0.02695038728415966,
        -0.04372569918632507,
        -0.048814307898283005,
        0.055323779582977295,
        -0.02537151426076889,
        0.058682914823293686,
        0.010692819021642208,
        -0.024390501901507378,
        -0.06174258887767792,
        -0.017097869887948036,
        0.04688210412859917,
        0.0626312792301178,
        -0.06246604025363922,
        0.06137366220355034,
        -0.03887747600674629,
        -0.062346018850803375,
        0.05003053694963455,
        0.045431941747665405,
        0.04807986319065094,
        0.050122182816267014,
        0.043281812220811844,
        0.0536971241235733,
        -0.056706707924604416,
        0.009462401270866394,
        -0.03600667417049408,
        0.009922114200890064,
        -0.05698822811245918,
        0.04551495611667633,
        0.03272814303636551,
        0.04833604767918587,
        0.0033329464495182037,
        0.06001746654510498,
        0.06004290282726288,
        0.007519740145653486,
        -0.05323587357997894,
        -0.050598807632923126,
        -0.048802655190229416,
        -0.0590403750538826,
        -0.0387972891330719,
        0.05328841507434845,
        0.056193869560956955,
        0.004931109491735697,
        0.00766099663451314,
        0.03275012969970703,
        0.06062772497534752,
        0.05990311875939369,
        -0.06215710937976837,
        -0.0600154809653759,
        -0.058749064803123474,
        0.013295257464051247,
        0.059536393731832504,
        0.010564557276666164,
        0.061825335025787354,
        -0.017800386995077133,
        -0.03987899795174599,
        -0.06111465021967888,
        0.062243200838565826,
        0.06212647259235382
    ],
    [
        0.018757449463009834,
        0.054370954632759094,
        0.056932441890239716,
        -0.004509469028562307,
        0.0493374839425087,
        0.02798020839691162,
        0.06005202606320381,
        0.02377866394817829,
        0.041128333657979965,
        0.044925615191459656,
        0.04381582513451576,
        0.03449519723653793,
        -0.057768676429986954,
        -0.0061525036580860615,
        -0.05738624185323715,
        -0.0026088824961334467,
        -0.02469749189913273,
        0.05920521169900894,
        -0.03620464354753494,
        -0.05972587689757347,
        -0.05335463583469391,
        0.04032234847545624,
        0.014703884720802307,
        0.023464806377887726,
        0.05118387192487717,
        0.0519295334815979,
        0.046241432428359985,
        -0.04596051201224327,
        0.018848376348614693,
        0.03548499569296837,
        0.059244949370622635,
        -0.04472777992486954,
        -0.060212571173906326,
        -0.059371959418058395,
        0.046450600028038025,
        -0.037082258611917496,
        -0.054337434470653534,
        -0.0031303917057812214,
        0.05943938344717026,
        0.04938181862235069,
        0.04486648365855217,
        0.042453207075595856,
        0.0584578700363636,
        -0.054169658571481705,
        0.05822855979204178,
        0.05587856099009514,
        0.04831158369779587,
        0.06022738665342331,
        0.05932559818029404,
        0.05229899287223816,
        0.05697314813733101,
        0.04182171821594238,
        0.05181986466050148,
        -0.03905134275555611,
        0.0009968478698283434,
        -0.05770011991262436,
        0.04892553761601448,
        -0.03967748582363129,
        -0.015131245367228985,
        -0.008683242835104465,
        -0.059029724448919296,
        -0.04463624209165573,
        -0.04289492592215538,
        0.022223489359021187,
        0.031278934329748154,
        0.006490391679108143,
        -0.0020366620738059282,
        0.006297634914517403,
        0.03323030099272728,
        -0.060346219688653946,
        -0.05982116609811783,
        -0.05456937104463577,
        0.030524160712957382,
        0.00485488586127758,
        0.012480909004807472,
        0.01595982164144516,
        0.0027893257793039083,
        0.057736530900001526,
        0.028942173346877098,
        -0.05465281382203102,
        -0.04316721856594086,
        0.05605880171060562,
        -0.04119838774204254,
        0.009193848818540573,
        0.0046568699181079865,
        0.04822685196995735,
        0.047808099538087845,
        -0.03258546069264412,
        -0.05551348626613617,
        0.039200033992528915,
        -0.059620726853609085,
        -0.030368288978934288,
        -0.025363946333527565,
        -0.03825214505195618,
        -0.01652560941874981,
        -0.027037953957915306,
        -0.025350390002131462,
        -0.05963216722011566,
        -0.052862390875816345,
        -0.05514633283019066,
        -0.059017956256866455,
        0.04435640946030617,
        0.05226191133260727,
        0.05996887385845184,
        0.041054945439100266,
        -0.05975862964987755,
        -0.05811223387718201,
        0.012309121899306774,
        -0.012716809287667274,
        -0.007083845790475607,
        -0.045014988631010056,
        -0.059452660381793976,
        -0.019084610044956207,
        -0.038088906556367874,
        0.03860367089509964,
        0.04128570482134819,
        0.02868068218231201,
        -0.05500541254878044,
        -0.05591679364442825,
        -0.00006411301728803664,
        -0.06007722020149231,
        0.00006536837463499978,
        -0.03651876375079155,
        0.051367487758398056,
        -0.056890495121479034,
        -0.0009244293323718011,
        -0.03295055776834488,
        -0.04370349273085594,
        0.05452314764261246,
        0.0557694248855114,
        0.057417768985033035,
        -0.02991446666419506,
        -0.047737643122673035,
        0.05028846859931946,
        -0.05515621230006218,
        -0.05232620984315872,
        0.051459696143865585,
        -0.058926813304424286,
        0.03322095796465874,
        0.056329648941755295,
        -0.03135659918189049,
        0.010519559495151043,
        0.04340171813964844,
        0.04307061806321144,
        -0.042286187410354614,
        -0.03506171703338623,
        0.06011946126818657,
        -0.03533473238348961,
        -0.05446901172399521,
        0.04434191808104515,
        -0.05506942793726921,
        -0.05973684415221214,
        -0.028744643554091454,
        -0.013584250584244728,
        -0.03808845579624176,
        -0.05357537791132927,
        0.03012942522764206,
        -0.0023969016037881374,
        0.05361824482679367,
        0.004221940413117409,
        0.04199060797691345,
        -0.05039677396416664,
        0.05516577139496803,
        -0.009709884412586689,
        -0.0060656191781163216,
        0.052238594740629196,
        0.0014028808800503612,
        -0.033465538173913956,
        0.05564173683524132,
        0.05550441890954971,
        -0.05226990953087807,
        0.052532222121953964,
        -0.03690183535218239,
        -0.057107001543045044,
        -0.044775232672691345,
        0.01978876255452633,
        0.029276305809617043,
        0.05269952490925789,
        0.06029647961258888,
        -0.05123737081885338,
        0.007255909964442253,
        0.05988222733139992,
        0.05670670047402382,
        0.04813099279999733,
        -0.014513608999550343,
        -0.044047582894563675,
        -0.05307941138744354,
        0.058429963886737823,
        -0.03102377988398075,
        -0.02112247794866562,
        -0.016854871064424515,
        0.014389471150934696,
        -0.03705201297998428,
        -0.05675554648041725,
        -0.016376348212361336,
        0.04875532537698746,
        0.014986286871135235,
        -0.05998605489730835,
        -0.003445131704211235,
        0.02174931950867176,
        0.055968478322029114,
        0.026896310970187187,
        0.03578118234872818,
        -0.026855813339352608,
        0.05239344760775566,
        0.010133650153875351,
        0.028509607538580894,
        0.03972793370485306,
        -0.04436779022216797,
        0.04557541757822037,
        -0.05316662788391113,
        0.02279416099190712,
        -0.04756989702582359,
        0.025334825739264488,
        0.03822895884513855,
        0.005735186859965324,
        0.0461830236017704,
        0.0024192193523049355,
        0.0457906611263752,
        -0.055421363562345505,
        0.03114180825650692,
        -0.05274828523397446,
        -0.016412220895290375,
        -0.04917120561003685,
        0.056377481669187546,
        -0.05523551627993584,
        -0.014630027115345001,
        -0.042924150824546814,
        0.0480726920068264,
        -0.060064852237701416,
        -0.03375581279397011,
        -0.0008958918042480946,
        -0.05823696404695511,
        0.04472250118851662,
        0.023798085749149323,
        -0.03602419048547745,
        0.05830920860171318,
        0.03756803274154663,
        -0.04407048597931862,
        0.05787261202931404,
        0.054484788328409195,
        0.005649214144796133,
        -0.038561880588531494,
        -0.05495717003941536,
        0.03556293249130249,
        -0.0058523258194327354,
        0.055187080055475235,
        0.05401601642370224,
        -0.06037074327468872,
        -0.04791698232293129,
        -0.0476708859205246,
        -0.05772837996482849,
        -0.03782761096954346,
        -0.05747126042842865,
        0.0108478469774127,
        0.05699034407734871,
        -0.022033412009477615,
        -0.02407926321029663,
        0.057719502598047256,
        0.04345470294356346,
        0.05764937400817871,
        0.05550246313214302,
        0.05948752537369728,
        -0.029838519170880318,
        0.05708273500204086,
        0.05313708633184433,
        0.013491196557879448,
        0.04228879138827324,
        0.04824560880661011,
        -0.059093356132507324,
        0.007466447539627552,
        -0.060208212584257126,
        0.05863650515675545,
        -0.052975643426179886,
        -0.00012651589349843562,
        0.05773278698325157,
        -0.05845184996724129,
        0.04389087110757828,
        0.042180728167295456,
        -0.05920615419745445,
        -0.059800587594509125,
        0.01726646162569523,
        -0.04870593920350075,
        -0.03523312881588936,
        -0.03563595190644264,
        -0.04363328218460083,
        0.05622049793601036,
        -0.03391566500067711,
        -0.05307772010564804,
        -0.003845062106847763,
        -0.04941083863377571,
        -0.009055680595338345,
        0.01999448426067829,
        -0.052916109561920166,
        0.04188801720738411,
        -0.05649636685848236,
        0.06028236821293831,
        -0.05274537578225136,
        -0.04108639433979988,
        0.05582895874977112,
        -0.009283484891057014,
        0.05094915255904198,
        -0.03312612697482109,
        0.04668595641851425,
        -0.020973095670342445,
        0.02249997667968273,
        -0.0014897083165124059,
        0.05678185448050499,
        0.05264165624976158,
        -0.058412451297044754,
        0.04468616098165512,
        -0.05297734588384628,
        -0.006394042633473873,
        0.05837380886077881,
        -0.05048283934593201,
        -0.05839402601122856,
        0.01121444720774889,
        0.005098327994346619,
        0.026045791804790497,
        -0.05795683711767197,
        0.04369935393333435,
        -0.05820278823375702,
        0.04636909440159798,
        -0.030928943306207657,
        0.0594029426574707,
        0.05632398650050163,
        0.03977734223008156,
        0.026072287932038307,
        0.0547594353556633,
        -0.04617789387702942,
        -0.03846155107021332,
        0.0033306137192994356,
        0.053195688873529434,
        -0.04812176525592804,
        0.059894151985645294,
        -0.03776535019278526,
        -0.044662244617938995,
        0.060075242072343826,
        -0.05876045301556587,
        0.058490853756666183,
        0.003685285337269306,
        0.004853131715208292,
        -0.053975608199834824,
        -0.04984292387962341,
        0.06021566316485405,
        -0.008765778504312038,
        0.02750004641711712,
        0.04642245173454285,
        0.03591982275247574,
        0.05366968363523483,
        -0.06007813662290573,
        0.04896505922079086,
        -0.03100094199180603,
        -0.06004611775279045,
        -0.05991833657026291,
        0.04704824090003967,
        -0.03491044417023659,
        -0.029298624023795128,
        -0.03590797260403633,
        -0.04903753846883774,
        0.05412336438894272,
        0.015477681532502174,
        -0.006633526645600796,
        -0.051211025565862656,
        0.011632349342107773,
        -0.057070691138505936,
        0.060441095381975174,
        0.04342752322554588,
        0.024184750393033028,
        -0.06038011610507965,
        0.005892775021493435,
        -0.05771740898489952,
        0.0549006424844265,
        0.02460685558617115,
        0.04664955288171768,
        0.04628367722034454,
        0.005254815332591534,
        0.047791581600904465,
        0.015020636841654778,
        0.0452057383954525,
        -0.05443712696433067,
        0.05049963667988777,
        -0.058145955204963684,
        -0.05495234951376915,
        0.060410767793655396,
        -0.05082981660962105,
        0.03097718395292759,
        -0.05861577391624451,
        -0.0484955832362175,
        -0.05514262989163399,
        -0.005371387116611004,
        0.04615616053342819,
        0.03782562166452408,
        -0.05710100010037422,
        -0.03143882006406784,
        0.055021725594997406,
        0.027666844427585602,
        -0.06014907732605934,
        -0.005045583937317133,
        -0.03357226029038429,
        0.0053456928580999374,
        0.04787709191441536,
        0.035402812063694,
        0.030494198203086853,
        -0.05877961590886116,
        -0.0169978104531765,
        0.025900375097990036,
        0.010316861793398857,
        0.05753583461046219,
        -0.05387672781944275,
        -0.00046420725993812084,
        -0.05613967403769493,
        0.01951351761817932,
        0.057890068739652634,
        0.05741887912154198,
        0.005114439409226179,
        0.0513811819255352,
        0.03473026677966118,
        0.04850611835718155,
        0.0550246424973011,
        0.04233227297663689,
        -0.05845799669623375,
        0.022126656025648117,
        -0.060205910354852676,
        0.007049883250147104,
        -0.05315384268760681,
        -0.05703728646039963,
        -0.025264834985136986,
        -0.059986844658851624,
        -0.01752510294318199,
        0.054117195308208466,
        -0.029723158106207848,
        0.03703346103429794,
        0.05915228649973869,
        -0.022209759801626205,
        -0.03836757689714432,
        -0.0009920117445290089,
        -0.00269978865981102,
        0.05888275429606438,
        -0.056250639259815216,
        -0.0031658478546887636,
        0.03694740682840347,
        0.05419617518782616,
        0.05771574005484581,
        -0.04968921095132828,
        -0.006881495006382465,
        0.06025225669145584,
        -0.058311402797698975,
        0.05339989438652992,
        -0.056847888976335526,
        -0.0549527145922184,
        0.03400750458240509,
        0.04563307389616966,
        -0.031093057245016098,
        -0.04796135053038597,
        -0.01625906489789486,
        -0.05313902348279953,
        0.044103339314460754,
        0.03538423404097557,
        0.05937472730875015,
        0.011836015619337559,
        0.0493459552526474,
        -0.05896667018532753,
        0.04511532187461853,
        0.05389823019504547,
        0.06020807847380638,
        -0.06029171869158745,
        0.060129981487989426,
        -0.026735203340649605,
        -0.059939682483673096,
        0.018027491867542267,
        0.05814152956008911,
        0.04792289063334465,
        0.03890780732035637,
        -0.009021081030368805,
        0.056316494941711426,
        -0.05496989190578461,
        0.017465807497501373,
        0.042486097663640976,
        -0.04203405976295471,
        -0.05927083268761635,
        0.04087986797094345,
        0.018128428608179092,
        0.038145847618579865,
        -0.005490944720804691,
        0.05809697508811951,
        0.05657456815242767,
        0.008006392978131771,
        -0.051720377057790756,
        -0.05364352837204933,
        -0.03051890805363655,
        -0.04734945669770241,
        -0.030948352068662643,
        0.05734608694911003,
        0.05683404579758644,
        0.003520416561514139,
        0.018481522798538208,
        0.050744589418172836,
        0.058497071266174316,
        0.051335062831640244,
        -0.06016019359230995,
        -0.05769447237253189,
        -0.058220457285642624,
        -0.04202987626194954,
        0.04972122609615326,
        0.0024006247986108065,
        0.06037375330924988,
        -0.005731548648327589,
        -0.05234488844871521,
        -0.05713452026247978,
        0.059115003794431686,
        0.054141540080308914
    ],
    [
        0.011156635358929634,
        0.046419303864240646,
        0.06095561385154724,
        0.030366765335202217,
        0.05512208864092827,
        -0.018365584313869476,
        0.062149856239557266,
        0.043590396642684937,
        0.011653255671262741,
        0.04741440340876579,
        0.020564144477248192,
        -0.014695238322019577,
        -0.0488731674849987,
        0.04288429766893387,
        -0.06035710126161575,
        -0.05528387799859047,
        0.019598521292209625,
        0.061099156737327576,
        0.012303255498409271,
        -0.050843000411987305,
        -0.03681204095482826,
        -0.027247536927461624,
        0.021236082538962364,
        0.009025005623698235,
        0.0595816969871521,
        0.03524048998951912,
        0.029427457600831985,
        -0.020266277715563774,
        -0.004427518229931593,
        -0.018535107374191284,
        0.059315025806427,
        -0.0420779287815094,
        -0.058151163160800934,
        -0.061057381331920624,
        0.04116779565811157,
        -0.053037408739328384,
        -0.058145616203546524,
        -0.05337191000580788,
        -0.050414618104696274,
        0.04649624973535538,
        0.027768652886152267,
        0.026810746639966965,
        0.06080557778477669,
        -0.03416374698281288,
        0.05882997065782547,
        0.05612790584564209,
        0.029365189373493195,
        0.04138701409101486,
        0.05717882886528969,
        0.036318421363830566,
        0.06160961091518402,
        -0.012897850014269352,
        0.034427423030138016,
        -0.0377175472676754,
        -0.05736300349235535,
        -0.023040270432829857,
        0.01297354232519865,
        0.001661553862504661,
        -0.04832659661769867,
        -0.029585503041744232,
        -0.05942102521657944,
        -0.03553072735667229,
        -0.044809482991695404,
        0.05267849192023277,
        0.0030578537844121456,
        -0.010216540656983852,
        -0.0021402272395789623,
        0.008830800652503967,
        0.032647259533405304,
        -0.05992474779486656,
        -0.06154245883226395,
        -0.054354023188352585,
        0.04133255407214165,
        -0.021993836387991905,
        0.05015455186367035,
        -0.0096723148599267,
        0.03159665688872337,
        0.06180901080369949,
        0.006520756054669619,
        -0.03655713424086571,
        -0.037607189267873764,
        -0.03619726002216339,
        -0.06046454235911369,
        0.05822403356432915,
        -0.060414690524339676,
        0.03982562571763992,
        0.05425184220075607,
        -0.05070868879556656,
        0.013813722878694534,
        0.05016263574361801,
        -0.060045138001441956,
        -0.05992850661277771,
        -0.05179520696401596,
        -0.03406883403658867,
        0.02041591703891754,
        -0.04089106246829033,
        -0.04208943992853165,
        -0.05962066352367401,
        -0.051439568400382996,
        -0.013704165816307068,
        -0.061655040830373764,
        -0.019389046356081963,
        0.05831468477845192,
        0.06047271937131882,
        0.0006068400107324123,
        -0.06014392152428627,
        -0.06087612733244896,
        0.027515072375535965,
        0.03915543109178543,
        -0.056609589606523514,
        0.01379355974495411,
        -0.057922814041376114,
        -0.039665546268224716,
        -0.058536261320114136,
        0.05365455150604248,
        0.04844600334763527,
        0.03484528884291649,
        -0.04702261835336685,
        -0.010405146516859531,
        -0.015299700200557709,
        -0.0047958362847566605,
        0.036298513412475586,
        -0.049839965999126434,
        -0.03557291254401207,
        -0.06174033507704735,
        0.05789602920413017,
        0.00998506136238575,
        -0.04698023945093155,
        0.049308013170957565,
        0.059126097708940506,
        0.02324928343296051,
        -0.030240150168538094,
        -0.058052029460668564,
        0.053689464926719666,
        -0.05580339953303337,
        -0.05803605169057846,
        0.05176108703017235,
        -0.05571179464459419,
        0.03974325954914093,
        0.062132664024829865,
        -0.0036772661842405796,
        -0.04461202397942543,
        0.045203231275081635,
        -0.0008001826936379075,
        0.023589985445141792,
        -0.06149492785334587,
        0.05364369601011276,
        -0.05494319647550583,
        -0.060842741280794144,
        -0.04132033884525299,
        -0.05920178443193436,
        -0.06030355766415596,
        0.03133218735456467,
        -0.020977335050702095,
        -0.02867840975522995,
        0.010963951237499714,
        0.016347814351320267,
        -0.05435294285416603,
        -0.02169596590101719,
        0.023092295974493027,
        0.0543302521109581,
        0.04227118194103241,
        0.05778408423066139,
        -0.03240804746747017,
        -0.05112776160240173,
        -0.03337114676833153,
        -0.05371615290641785,
        -0.05092640221118927,
        0.04652894288301468,
        0.05103722959756851,
        -0.028236715123057365,
        0.054432760924100876,
        -0.05957692116498947,
        -0.01425617653876543,
        0.059422820806503296,
        0.021237604320049286,
        0.028550054877996445,
        0.04863077402114868,
        0.06138010323047638,
        0.02067517302930355,
        -0.015482022427022457,
        0.05796205252408981,
        0.05846100673079491,
        0.03786823898553848,
        -0.024809787049889565,
        -0.04502130299806595,
        -0.05488969758152962,
        0.05854175612330437,
        0.001980866538360715,
        -0.03679274767637253,
        0.03263680264353752,
        -0.030820680782198906,
        -0.0019456206355243921,
        -0.0317641980946064,
        -0.05151015892624855,
        0.05421547219157219,
        0.03646397218108177,
        -0.06064803898334503,
        -0.058283936232328415,
        0.025327982380986214,
        0.05557230859994888,
        0.05779612064361572,
        -0.0539347305893898,
        -0.04771389067173004,
        0.04517759382724762,
        -0.03601079061627388,
        0.06024183705449104,
        0.058042459189891815,
        -0.05183392018079758,
        0.047067709267139435,
        -0.04759811609983444,
        0.05468473583459854,
        -0.021835701540112495,
        0.06003184616565704,
        -0.03531225770711899,
        -0.05052633956074715,
        0.03345300257205963,
        0.02354208379983902,
        0.05984152853488922,
        -0.04272722825407982,
        0.0391368493437767,
        -0.05477682873606682,
        0.024371681734919548,
        -0.05450199171900749,
        -0.0534575991332531,
        0.05730877444148064,
        0.005147912073880434,
        0.03400511294603348,
        0.010521202348172665,
        -0.056939925998449326,
        -0.06017788127064705,
        0.05126387998461723,
        -0.06027091667056084,
        0.04480123519897461,
        0.06039993464946747,
        -0.05687493085861206,
        0.057576145976781845,
        0.04999871924519539,
        -0.03025716356933117,
        0.05649547651410103,
        -0.008286291733384132,
        -0.027797210961580276,
        -0.02915113978087902,
        -0.044926565140485764,
        0.02703992649912834,
        0.016849089413881302,
        -0.043528858572244644,
        -0.0332983136177063,
        -0.03610961139202118,
        -0.039949119091033936,
        -0.03263840079307556,
        -0.05978386476635933,
        -0.06026281788945198,
        -0.051863234490156174,
        0.011251067742705345,
        0.05373687669634819,
        0.02968018129467964,
        0.038182102143764496,
        0.05759128928184509,
        0.0276494137942791,
        -0.029513048008084297,
        -0.027508268132805824,
        0.06161680445075035,
        0.03486151993274689,
        -0.05785302072763443,
        0.0497099906206131,
        0.011961725540459156,
        0.044706087559461594,
        0.03763178735971451,
        -0.06162424385547638,
        -0.006970467511564493,
        -0.06123435124754906,
        0.061906833201646805,
        -0.055299628525972366,
        -0.051879774779081345,
        0.05899056792259216,
        -0.058814842253923416,
        0.057427797466516495,
        -0.027313485741615295,
        0.04306275025010109,
        -0.059113599359989166,
        0.019288653507828712,
        -0.026073461398482323,
        0.01477265078574419,
        -0.020724914968013763,
        0.011544600129127502,
        0.0410294346511364,
        -0.003182863350957632,
        -0.044427793473005295,
        0.048934780061244965,
        -0.02255428209900856,
        0.034129876643419266,
        -0.059376657009124756,
        0.0387113094329834,
        0.007682355586439371,
        -0.04683598503470421,
        0.06218259781599045,
        -0.028266998007893562,
        -0.056342918425798416,
        0.057258427143096924,
        -0.05610836297273636,
        0.04077700525522232,
        -0.014897068962454796,
        0.05876404419541359,
        0.0026739933528006077,
        -0.051485296338796616,
        -0.020659545436501503,
        -0.0009947663638740778,
        0.05227860063314438,
        -0.05225156992673874,
        0.03624842315912247,
        -0.057492323219776154,
        -0.04434153065085411,
        0.01757064275443554,
        -0.0536230243742466,
        -0.035971347242593765,
        0.007579691708087921,
        -0.04673761874437332,
        0.037989355623722076,
        -0.04606151953339577,
        0.015720825642347336,
        -0.04460592940449715,
        0.0028349782805889845,
        -0.05849761143326759,
        0.060240428894758224,
        0.05435173586010933,
        0.05217206850647926,
        -0.04151659458875656,
        0.06213896721601486,
        -0.029265964403748512,
        -0.01545205432921648,
        0.05226118117570877,
        0.006816740147769451,
        0.016116442158818245,
        0.05997321009635925,
        -0.03180839493870735,
        -0.017278240993618965,
        0.0594581738114357,
        -0.0236828550696373,
        0.060184914618730545,
        0.03557673096656799,
        -0.03792386129498482,
        -0.0468684621155262,
        0.01647021993994713,
        0.06157207861542702,
        0.030169153586030006,
        -0.04491586238145828,
        0.033744290471076965,
        0.02474958263337612,
        0.05129803344607353,
        -0.059796497225761414,
        0.04673741012811661,
        -0.021902570500969887,
        -0.05753291770815849,
        -0.06172872707247734,
        0.05934533476829529,
        -0.05792737379670143,
        -0.02896440401673317,
        -0.03338757902383804,
        -0.05898458883166313,
        0.0541267991065979,
        0.03918430954217911,
        0.034528698772192,
        -0.03923347592353821,
        0.030197206884622574,
        -0.06047651171684265,
        0.06224854663014412,
        0.050142690539360046,
        0.05782857537269592,
        -0.060346443206071854,
        -0.04349377378821373,
        -0.04567611590027809,
        0.032879188656806946,
        0.04531257227063179,
        0.056622136384248734,
        0.022804738953709602,
        0.05815166234970093,
        0.031768232583999634,
        0.02815346233546734,
        0.031423214823007584,
        -0.043331488966941833,
        0.058737773448228836,
        -0.05823159217834473,
        -0.028771597892045975,
        0.061939358711242676,
        -0.06074785441160202,
        -0.0032670714426785707,
        -0.050465282052755356,
        -0.057808347046375275,
        -0.05298797786235809,
        0.025429395958781242,
        0.05135633796453476,
        0.04336316138505936,
        -0.04523474723100662,
        -0.006078469567000866,
        0.059928230941295624,
        0.0621357224881649,
        -0.06123397499322891,
        -0.03927120938897133,
        -0.049713268876075745,
        -0.04555816948413849,
        0.05824098736047745,
        0.05745432525873184,
        0.04769828915596008,
        -0.043083854019641876,
        0.018792372196912766,
        0.051582299172878265,
        0.01816215179860592,
        0.06037349998950958,
        -0.044344183057546616,
        0.021488772705197334,
        -0.03047022968530655,
        -0.01700456812977791,
        0.04443420469760895,
        0.05491359159350395,
        -0.00007221966370707378,
        0.03749682381749153,
        0.04106401279568672,
        0.05965434014797211,
        0.02830508165061474,
        0.03645387291908264,
        -0.025136673822999,
        -0.042968880385160446,
        -0.06064758077263832,
        0.006416460033506155,
        0.005148705095052719,
        -0.06145701929926872,
        0.014026656746864319,
        -0.04995191469788551,
        0.029079120606184006,
        0.046959392726421356,
        0.017993155866861343,
        -0.03799085691571236,
        0.06205497309565544,
        -0.028946027159690857,
        -0.032532889395952225,
        -0.04540729522705078,
        -0.0028045126236975193,
        0.062323033809661865,
        -0.05283920839428902,
        -0.04163563251495361,
        0.055191751569509506,
        0.05101202800869942,
        0.0568307600915432,
        0.05076735466718674,
        0.01052301749587059,
        0.06094508245587349,
        -0.058342598378658295,
        0.05103806406259537,
        -0.0075720506720244884,
        -0.02099151723086834,
        0.009812488220632076,
        0.027997290715575218,
        0.00021472004300449044,
        0.05203681066632271,
        -0.04693754389882088,
        -0.03827307000756264,
        0.01259851548820734,
        0.0007720154244452715,
        0.06206775829195976,
        0.01978808455169201,
        -0.03378968685865402,
        -0.05757478252053261,
        -0.03992965817451477,
        0.0535358265042305,
        0.06205807626247406,
        -0.06211094185709953,
        0.061739180237054825,
        -0.05202291160821915,
        -0.061280377209186554,
        0.05960652604699135,
        0.05127649009227753,
        0.057151827961206436,
        -0.030816446989774704,
        0.040510475635528564,
        -0.011854882352054119,
        -0.061647314578294754,
        0.05148704722523689,
        0.0027206935919821262,
        0.024825412780046463,
        -0.05604826658964157,
        0.026529943570494652,
        0.05259734019637108,
        0.02637649141252041,
        -0.054795291274785995,
        0.06082606315612793,
        0.013567865826189518,
        0.009220406413078308,
        -0.053876325488090515,
        -0.03545370697975159,
        -0.04815870523452759,
        0.016829466447234154,
        0.059658233076334,
        0.05777972936630249,
        0.038757115602493286,
        -0.010832707397639751,
        -0.06093890219926834,
        0.009809293784201145,
        0.052178967744112015,
        0.008961338549852371,
        -0.06185523793101311,
        -0.02616424672305584,
        -0.05843063443899155,
        -0.002992016728967428,
        0.0603545643389225,
        -0.0015166376251727343,
        0.061410363763570786,
        -0.0018429717747494578,
        -0.02912985347211361,
        -0.044900186359882355,
        0.027135569602251053,
        0.06187210604548454
    ],
    [
        -0.060709625482559204,
        0.02585464157164097,
        0.057798780500888824,
        0.007899526506662369,
        0.044603414833545685,
        -0.01954590156674385,
        0.029873177409172058,
        -0.045115549117326736,
        -0.04919518530368805,
        0.057208914309740067,
        -0.03766168653964996,
        0.05258061736822128,
        -0.05497417971491814,
        -0.012426800094544888,
        -0.05399646610021591,
        0.0217093788087368,
        0.04417313262820244,
        0.03667261451482773,
        -0.034621965140104294,
        -0.03324027732014656,
        -0.050427764654159546,
        0.0375855378806591,
        0.02875848300755024,
        0.002140918280929327,
        0.05382091552019119,
        0.018384655937552452,
        -0.001048965728841722,
        0.05737874656915665,
        -0.04847293719649315,
        -0.040407586842775345,
        0.061139196157455444,
        0.031688157469034195,
        -0.012199241667985916,
        -0.05725187435746193,
        0.05609568953514099,
        -0.057185959070920944,
        -0.06103484332561493,
        -0.055150359869003296,
        0.05567013472318649,
        -0.007755816448479891,
        -0.011515266261994839,
        0.04464207589626312,
        0.06153858080506325,
        -0.03216229006648064,
        0.05384146794676781,
        0.05614078417420387,
        -0.04900901019573212,
        0.0635608658194542,
        0.060248199850320816,
        0.05788755789399147,
        0.06169861555099487,
        0.03696434944868088,
        0.042878832668066025,
        -0.061447933316230774,
        0.008981469087302685,
        -0.04788213223218918,
        0.0033556409180164337,
        -0.023420089855790138,
        -0.06047837808728218,
        0.025705764070153236,
        -0.06198565289378166,
        -0.03557683899998665,
        -0.06029016152024269,
        -0.005959168076515198,
        0.03502310812473297,
        0.0472915917634964,
        0.04824386164546013,
        -0.028609663248062134,
        0.031111111864447594,
        -0.06110643595457077,
        -0.0637921690940857,
        -0.05179283767938614,
        -0.018912311643362045,
        -0.03677406162023544,
        -0.049313511699438095,
        -0.0314815379679203,
        0.04604686051607132,
        0.06680303812026978,
        0.047894932329654694,
        -0.04924709349870682,
        -0.06042986363172531,
        0.020767996087670326,
        0.014208312146365643,
        0.027325257658958435,
        0.014816309325397015,
        0.035237766802310944,
        -0.016670864075422287,
        0.00938087422400713,
        -0.06479774415493011,
        0.03149395063519478,
        -0.037385135889053345,
        -0.014205862767994404,
        -0.03156641870737076,
        -0.05854710936546326,
        -0.013488939963281155,
        0.05729871988296509,
        0.004598340950906277,
        -0.06201857700943947,
        0.0436674989759922,
        0.015413117595016956,
        -0.06648489087820053,
        -0.010791710577905178,
        0.062274444848299026,
        0.05849732831120491,
        0.011596951633691788,
        -0.03239986672997475,
        -0.052348583936691284,
        0.05942732095718384,
        -0.04727071151137352,
        -0.03634512796998024,
        -0.003076562425121665,
        -0.061896178871393204,
        -0.05182228609919548,
        -0.004021712578833103,
        0.046479687094688416,
        -0.011873479932546616,
        0.046253178268671036,
        0.011917298659682274,
        -0.06343543529510498,
        0.006345283705741167,
        -0.01529967226088047,
        -0.019375016912817955,
        0.024720042943954468,
        0.03460683673620224,
        -0.045781396329402924,
        -0.03536022827029228,
        -0.01574961096048355,
        0.020275350660085678,
        0.010632071644067764,
        0.05026702955365181,
        0.0636066421866417,
        -0.04743340611457825,
        -0.048038773238658905,
        0.014828987419605255,
        -0.041884053498506546,
        -0.062404680997133255,
        0.06593110412359238,
        -0.06317716836929321,
        -0.01772843860089779,
        0.010428634472191334,
        0.0055214217863976955,
        -0.05286450311541557,
        0.05651464685797691,
        -0.05394234508275986,
        -0.016244105994701385,
        -0.012145349755883217,
        0.06230144202709198,
        -0.04583042487502098,
        0.008370734751224518,
        0.002471073530614376,
        -0.05838610604405403,
        -0.06533946841955185,
        -0.04463684931397438,
        0.012501475401222706,
        -0.025231361389160156,
        -0.05388864874839783,
        0.05641845613718033,
        -0.01485436875373125,
        0.036035437136888504,
        -0.0255067627876997,
        0.057639461010694504,
        -0.02284960262477398,
        0.05723434314131737,
        0.012496561743319035,
        -0.019442593678832054,
        -0.0436086542904377,
        0.0663783997297287,
        -0.038984958082437515,
        0.006822843104600906,
        0.06269393116235733,
        -0.028854157775640488,
        0.05215262621641159,
        -0.06067701429128647,
        0.04034452512860298,
        -0.04545208439230919,
        0.04238280653953552,
        -0.01278584823012352,
        0.045298583805561066,
        0.06489363312721252,
        -0.04762635380029678,
        -0.011945170350372791,
        0.062195293605327606,
        0.05414213612675667,
        0.004055928438901901,
        -0.02344357594847679,
        0.011955698020756245,
        -0.058896809816360474,
        0.06050005182623863,
        0.04130641371011734,
        -0.048775725066661835,
        -0.03677942231297493,
        0.013712518848478794,
        -0.05991307646036148,
        0.02525632455945015,
        -0.05959056317806244,
        0.06163206696510315,
        0.03793107718229294,
        -0.047540564090013504,
        -0.00018076399283017963,
        0.04548327624797821,
        0.06275541335344315,
        0.06353066861629486,
        0.047321658581495285,
        -0.012365336529910564,
        -0.020405380055308342,
        0.052125852555036545,
        0.04864449054002762,
        0.0478113628923893,
        -0.04084702208638191,
        0.05337144806981087,
        -0.04429592564702034,
        0.01773001253604889,
        -0.031244419515132904,
        0.04929221794009209,
        0.06192027032375336,
        -0.05875266715884209,
        0.019723007455468178,
        0.04009673371911049,
        0.052901122719049454,
        -0.0559251643717289,
        -0.0026755742728710175,
        -0.050588108599185944,
        -0.02885546162724495,
        -0.05066670849919319,
        -0.0014702507760375738,
        -0.023142002522945404,
        0.01966007985174656,
        0.05051925405859947,
        0.017727995291352272,
        -0.0662054568529129,
        0.0392298549413681,
        0.011797619983553886,
        -0.06481259316205978,
        0.05883924663066864,
        0.06364581733942032,
        0.051499322056770325,
        0.06571568548679352,
        -0.02710007317364216,
        -0.022017134353518486,
        0.06511741131544113,
        0.06426160782575607,
        -0.03590228781104088,
        0.015687474980950356,
        -0.0164782777428627,
        0.04980994015932083,
        0.05919181555509567,
        0.06357362866401672,
        -0.011095660738646984,
        0.0041222283616662025,
        -0.010592876002192497,
        -0.06378719210624695,
        -0.06374569237232208,
        -0.03627989441156387,
        -0.06526844203472137,
        0.016831202432513237,
        0.03703790903091431,
        0.013437947258353233,
        -0.015150343999266624,
        0.06436799466609955,
        0.0325617641210556,
        0.05824388563632965,
        0.049940310418605804,
        0.022067474201321602,
        -0.061034202575683594,
        -0.035757750272750854,
        0.04147978499531746,
        0.03894626349210739,
        -0.029687844216823578,
        0.005052002612501383,
        -0.06467636674642563,
        -0.038349561393260956,
        -0.060536567121744156,
        0.012644976377487183,
        -0.0036066132597625256,
        -0.03665726259350777,
        0.05520118400454521,
        -0.05741877853870392,
        0.025776071473956108,
        -0.022123347967863083,
        -0.01641123741865158,
        0.03169693052768707,
        0.024899551644921303,
        -0.008587912656366825,
        -0.025900907814502716,
        -0.015964800491929054,
        -0.04907974600791931,
        0.06295768916606903,
        0.05563056468963623,
        -0.04788826406002045,
        -0.013464829884469509,
        -0.02574821747839451,
        0.0005705379880964756,
        0.00794378574937582,
        -0.04972412809729576,
        0.0424608513712883,
        -0.04803258925676346,
        0.0657421201467514,
        -0.06435417383909225,
        -0.0613764151930809,
        0.05944883078336716,
        0.025112226605415344,
        -0.013689685612916946,
        -0.04855950549244881,
        0.039633363485336304,
        0.04765031859278679,
        -0.010433729737997055,
        -0.01397266797721386,
        0.03863658010959625,
        -0.025378607213497162,
        -0.06033707410097122,
        0.04492601379752159,
        -0.014895123429596424,
        0.04146687313914299,
        0.05890295282006264,
        -0.03809020668268204,
        -0.06554511934518814,
        0.0338217131793499,
        -0.04717342555522919,
        0.033154260367155075,
        -0.06429500132799149,
        -0.04080667719244957,
        -0.012687534093856812,
        0.05182554945349693,
        -0.05818714201450348,
        0.06504085659980774,
        0.05404141917824745,
        0.06234188750386238,
        -0.016582101583480835,
        -0.01218775287270546,
        -0.014164692722260952,
        0.006546197459101677,
        -0.025635046884417534,
        0.03464736416935921,
        -0.03907395899295807,
        0.06499173492193222,
        -0.06301199644804001,
        0.027190685272216797,
        0.0651678517460823,
        -0.05788111686706543,
        0.06006387621164322,
        -0.013155044056475163,
        -0.0061568873934447765,
        0.032492224127054214,
        -0.04087470471858978,
        0.06719794869422913,
        0.020535627380013466,
        -0.02004416473209858,
        0.04856136068701744,
        0.014997703954577446,
        0.04853426665067673,
        -0.06295961141586304,
        0.016215484589338303,
        -0.02488798461854458,
        -0.06312164664268494,
        -0.06559731811285019,
        0.056129083037376404,
        -0.061263687908649445,
        -0.03416076675057411,
        0.034395892173051834,
        -0.042844898998737335,
        0.05023453012108803,
        0.03607455641031265,
        -0.028740238398313522,
        -0.05836482346057892,
        0.019805487245321274,
        -0.06417415291070938,
        0.06193483620882034,
        -0.047318316996097565,
        -0.04541551321744919,
        -0.06635177135467529,
        -0.024653449654579163,
        -0.06327592581510544,
        0.06459919363260269,
        -0.02216491848230362,
        0.06520099192857742,
        -0.0020258845761418343,
        -0.0010482779471203685,
        0.014447636902332306,
        0.044201090931892395,
        -0.06268380582332611,
        0.008451526053249836,
        0.0501306913793087,
        -0.06701786816120148,
        0.02272023819386959,
        0.06495072692632675,
        -0.03547533228993416,
        0.04320690780878067,
        -0.058123327791690826,
        -0.010418098419904709,
        -0.04823244735598564,
        0.04854108765721321,
        0.03475332632660866,
        -0.03765082359313965,
        -0.023787586018443108,
        0.033019185066223145,
        0.06023355945944786,
        0.0639292374253273,
        -0.06509582698345184,
        -0.0595281682908535,
        -0.05276058614253998,
        0.0028837649151682854,
        0.05579368397593498,
        -0.003827559994533658,
        0.05999894067645073,
        -0.035097502171993256,
        -0.024871826171875,
        0.007194692734628916,
        0.021687205880880356,
        0.05864663049578667,
        -0.0452725850045681,
        -0.004742316901683807,
        -0.03593960404396057,
        0.024139821529388428,
        0.045173775404691696,
        0.049930982291698456,
        -0.02022022195160389,
        0.06367598474025726,
        0.05775314196944237,
        0.014250841923058033,
        0.05892493575811386,
        -0.041975121945142746,
        -0.06626016646623611,
        -0.009705351665616035,
        -0.06604479253292084,
        -0.04207891970872879,
        -0.00018624479707796127,
        -0.06266237050294876,
        -0.05360832437872887,
        -0.041135113686323166,
        0.012775166891515255,
        0.019649706780910492,
        -0.04645083472132683,
        0.021700551733374596,
        0.05913408845663071,
        0.01208404265344143,
        -0.04405742511153221,
        -0.023344874382019043,
        0.055468443781137466,
        0.06245845556259155,
        -0.05086003988981247,
        0.0018559315940365195,
        0.05816548690199852,
        0.03312132880091667,
        0.06222749128937721,
        -0.055336833000183105,
        0.036110155284404755,
        0.06556615233421326,
        -0.06165247783064842,
        0.056371286511421204,
        -0.053006138652563095,
        -0.010642939247190952,
        -0.012762022204697132,
        0.03978220745921135,
        -0.046859174966812134,
        -0.03498386964201927,
        -0.0405251644551754,
        -0.018971895799040794,
        -0.03694033995270729,
        0.010581555776298046,
        -0.005545991472899914,
        0.0451003760099411,
        -0.05519021302461624,
        -0.047963038086891174,
        -0.015521375462412834,
        0.03263117000460625,
        0.06713080406188965,
        -0.06524330377578735,
        0.0420689657330513,
        -0.061872925609350204,
        -0.06647951155900955,
        0.05528299883008003,
        0.029196223244071007,
        0.062708280980587,
        0.046934355050325394,
        0.06443394720554352,
        0.04456280171871185,
        -0.05182201787829399,
        -0.04920848459005356,
        -0.03720919415354729,
        0.011999142356216908,
        -0.06178264319896698,
        -0.04515186324715614,
        0.045698344707489014,
        0.042244236916303635,
        0.03587278723716736,
        0.06156992167234421,
        0.03939256817102432,
        0.03317820280790329,
        -0.05340397357940674,
        -0.03655587136745453,
        -0.02978154830634594,
        -0.023040637373924255,
        0.037056680768728256,
        0.04655233770608902,
        0.018871599808335304,
        0.052913274616003036,
        -0.05451775714755058,
        -0.01392151415348053,
        0.06072790548205376,
        -0.016241610050201416,
        -0.06652963161468506,
        -0.058314353227615356,
        -0.05556204915046692,
        -0.023187994956970215,
        0.06467962265014648,
        -0.06430777162313461,
        0.05439336597919464,
        -0.006559734232723713,
        -0.04370439052581787,
        -0.029378073289990425,
        0.0634578987956047,
        0.06189893186092377
    ],
    [
        -0.012096584774553776,
        0.06377368420362473,
        0.05329401046037674,
        0.03549100458621979,
        0.05472373589873314,
        -0.0609864704310894,
        0.06451031565666199,
        -0.004330380819737911,
        0.04518493264913559,
        -0.05184393376111984,
        0.02402973547577858,
        0.024107201024889946,
        -0.02725089341402054,
        -0.02600460685789585,
        -0.0611242949962616,
        -0.0008579691057093441,
        -0.0042137145064771175,
        0.05324853956699371,
        -0.00597285246476531,
        -0.05992089956998825,
        -0.06140272319316864,
        -0.051163654774427414,
        0.0616392157971859,
        0.00788260530680418,
        -0.024325277656316757,
        0.057218413800001144,
        0.05996701493859291,
        -0.003521787468343973,
        -0.039453208446502686,
        -0.057734739035367966,
        0.04963124170899391,
        0.026929153129458427,
        -0.045706674456596375,
        -0.062322620302438736,
        0.029868369922041893,
        -0.050119008868932724,
        -0.060146626085042953,
        -0.06396661698818207,
        -0.06147332862019539,
        -0.044265538454055786,
        0.04390520229935646,
        0.005198169033974409,
        0.0415155366063118,
        -0.0569990836083889,
        0.05927541106939316,
        0.02002835087478161,
        0.02519327402114868,
        0.05997518450021744,
        0.028553754091262817,
        -0.06407301127910614,
        0.06365625560283661,
        -0.059831660240888596,
        -0.012145880609750748,
        0.04914256930351257,
        -0.06379366666078568,
        -0.0050486051477491856,
        0.028550317510962486,
        0.025499828159809113,
        -0.020601816475391388,
        -0.06348015367984772,
        -0.054055940359830856,
        -0.02068648301064968,
        -0.045286763459444046,
        -0.050871651619672775,
        0.018107367679476738,
        0.04308421537280083,
        0.018277013674378395,
        0.028566667810082436,
        0.04919450357556343,
        -0.06441006064414978,
        -0.056416429579257965,
        -0.06427224725484848,
        0.05243571847677231,
        -0.024527451023459435,
        0.06273597478866577,
        -0.042648784816265106,
        0.017844464629888535,
        0.061522889882326126,
        -0.021761547774076462,
        -0.0013526309048756957,
        0.00037191269802860916,
        -0.028540533035993576,
        -0.04815419018268585,
        0.007093636319041252,
        -0.0554959662258625,
        0.02091657742857933,
        -0.0023589518386870623,
        -0.05779872462153435,
        0.0607222281396389,
        -0.04606453701853752,
        -0.038957901298999786,
        0.02455504797399044,
        -0.03833894059062004,
        0.01329066976904869,
        0.016208874061703682,
        0.01656157709658146,
        -0.02412501536309719,
        -0.027269665151834488,
        -0.05801406875252724,
        -0.05011257156729698,
        -0.06402797996997833,
        0.02365107461810112,
        0.06336066126823425,
        0.039880670607089996,
        -0.06114591285586357,
        -0.05493495985865593,
        -0.06080690026283264,
        -0.02927270159125328,
        -0.055569395422935486,
        -0.05233460292220116,
        0.026113362982869148,
        -0.05958971008658409,
        -0.01612173020839691,
        -0.04108348488807678,
        0.06328526884317398,
        0.006014373619109392,
        0.04096492379903793,
        -0.019926147535443306,
        0.021168923005461693,
        -0.05039195343852043,
        -0.02587270177900791,
        0.05530610308051109,
        -0.042003192007541656,
        -0.024588676169514656,
        -0.061265960335731506,
        -0.03611006215214729,
        0.034649934619665146,
        -0.03498313948512077,
        0.06291451305150986,
        0.023652903735637665,
        0.058357540518045425,
        -0.059463925659656525,
        -0.035905517637729645,
        0.05160882696509361,
        -0.04623471572995186,
        -0.06155526265501976,
        0.007270587608218193,
        0.037268947809934616,
        0.057061709463596344,
        0.06155802309513092,
        0.04511149600148201,
        -0.05910422280430794,
        0.02921631559729576,
        -0.03418870270252228,
        -0.029248831793665886,
        -0.025274138897657394,
        0.059366531670093536,
        -0.03561867028474808,
        -0.045462049543857574,
        -0.044331569224596024,
        -0.061256665736436844,
        -0.05545182153582573,
        -0.059789519757032394,
        -0.02526087313890457,
        -0.03340619057416916,
        -0.0022985527757555246,
        0.001047752215526998,
        -0.06190849840641022,
        0.013024911284446716,
        0.0037205591797828674,
        0.0540136955678463,
        0.056977253407239914,
        0.061026837676763535,
        0.053066376596689224,
        -0.05986618250608444,
        -0.06267441809177399,
        0.053816795349121094,
        -0.05206367000937462,
        0.04145192727446556,
        0.04480593279004097,
        -0.05582990124821663,
        0.03888385370373726,
        -0.061989665031433105,
        0.011286982335150242,
        0.012533146888017654,
        0.03530808165669441,
        0.030574040487408638,
        0.007960815913975239,
        0.06383005529642105,
        -0.010158233344554901,
        -0.019289830699563026,
        0.03148500621318817,
        0.053567830473184586,
        0.030415112152695656,
        -0.02445255219936371,
        0.002655645599588752,
        -0.06432922929525375,
        0.06287459284067154,
        -0.010094111785292625,
        0.012492586858570576,
        -0.021030735224485397,
        -0.018664494156837463,
        -0.011859794147312641,
        -0.037824444472789764,
        -0.0378420427441597,
        0.05923084914684296,
        0.015934141352772713,
        -0.05066240206360817,
        -0.060435354709625244,
        0.025842569768428802,
        -0.007344228681176901,
        0.018951497972011566,
        0.05594895780086517,
        -0.022882528603076935,
        0.04742350056767464,
        0.005289117339998484,
        0.057549700140953064,
        0.05565653741359711,
        -0.022162269800901413,
        -0.0595230758190155,
        -0.0206514373421669,
        0.06263285130262375,
        0.014350893907248974,
        0.02245202846825123,
        -0.05256868898868561,
        -0.044905588030815125,
        -0.03847916051745415,
        0.024945277720689774,
        0.06088508665561676,
        -0.05672308802604675,
        0.05427996814250946,
        0.006960403174161911,
        -0.04001349210739136,
        -0.023189464583992958,
        0.045852065086364746,
        0.010167043656110764,
        -0.014501732774078846,
        0.018782252445816994,
        0.00005059135582996532,
        -0.05420459061861038,
        -0.03256446123123169,
        0.02101345919072628,
        -0.0623321533203125,
        0.028257349506020546,
        0.039678905159235,
        0.04459041357040405,
        0.04582330957055092,
        -0.021488595753908157,
        -0.0510094128549099,
        0.05474429577589035,
        0.005487827118486166,
        0.017279578372836113,
        -0.01952720433473587,
        -0.05949093773961067,
        0.04875773936510086,
        0.03831443935632706,
        -0.034791816025972366,
        -0.03607646003365517,
        -0.05478394031524658,
        -0.03007417917251587,
        0.026971958577632904,
        -0.05831865221261978,
        -0.05548088625073433,
        -0.05611038953065872,
        -0.024761788547039032,
        0.03520571440458298,
        -0.03526217117905617,
        0.039231400936841965,
        0.04370920732617378,
        0.02162979170680046,
        0.025695769116282463,
        -0.012522629462182522,
        -0.029128728434443474,
        -0.001372009632177651,
        -0.061437126249074936,
        0.03262164071202278,
        0.027007760480046272,
        -0.034702688455581665,
        -0.014310449361801147,
        -0.06350452452898026,
        -0.027573399245738983,
        -0.027502473443746567,
        0.06393996626138687,
        -0.05073516070842743,
        -0.04552025347948074,
        0.0523049458861351,
        -0.05555715411901474,
        0.05724721774458885,
        0.021210961043834686,
        0.05584486946463585,
        -0.05598832666873932,
        -0.056831005960702896,
        0.05351854860782623,
        0.00814090110361576,
        -0.019549798220396042,
        -0.004911536350846291,
        0.05869525671005249,
        -0.02515704743564129,
        -0.002603843342512846,
        0.048399657011032104,
        -0.008058376610279083,
        0.040873099118471146,
        -0.05034726485610008,
        -0.014391688629984856,
        0.05731232091784477,
        -0.05115079879760742,
        0.06042169779539108,
        -0.027723683044314384,
        -0.053943682461977005,
        0.056348685175180435,
        0.05300075560808182,
        0.060391973704099655,
        -0.02331695519387722,
        0.053541868925094604,
        -0.005564668215811253,
        -0.007559305988252163,
        0.02492940053343773,
        0.03854527696967125,
        0.04337119311094284,
        -0.024077488109469414,
        0.03241315484046936,
        -0.059925489127635956,
        -0.020085850730538368,
        -0.045843705534935,
        -0.05729237198829651,
        -0.04248549044132233,
        0.055438052862882614,
        -0.0307864211499691,
        0.03910892829298973,
        -0.005309870466589928,
        0.022053521126508713,
        -0.061842452734708786,
        0.054980356246232986,
        0.0007041484350338578,
        0.05595630034804344,
        0.05898112803697586,
        0.06314720958471298,
        -0.0506211556494236,
        0.05329980328679085,
        -0.05315249413251877,
        -0.038706813007593155,
        0.05338229984045029,
        -0.026035653427243233,
        0.000807172036729753,
        0.058999426662921906,
        -0.05218478664755821,
        -0.05812360346317291,
        0.05073845386505127,
        -0.056158676743507385,
        0.06235549971461296,
        0.0032522885594516993,
        0.03471051901578903,
        -0.03425376117229462,
        0.026138165965676308,
        0.06456120312213898,
        0.006490563042461872,
        0.021108897402882576,
        0.03564862534403801,
        0.0639665350317955,
        0.05884011089801788,
        -0.027926061302423477,
        0.041205838322639465,
        -0.043094370514154434,
        -0.060751404613256454,
        -0.0644533559679985,
        0.01461684238165617,
        -0.05886087194085121,
        -0.017211882397532463,
        -0.04868433251976967,
        -0.06410650163888931,
        0.05933587998151779,
        0.04678584635257721,
        0.03429241105914116,
        -0.020533384755253792,
        0.05640069767832756,
        -0.02780468761920929,
        0.06434404104948044,
        -0.008112942799925804,
        0.062423404306173325,
        -0.05499076098203659,
        -0.04508398100733757,
        -0.018943484872579575,
        0.03222883865237236,
        0.06158895790576935,
        0.06425492465496063,
        0.06103476509451866,
        0.04262514039874077,
        0.03021690435707569,
        -0.01271026860922575,
        0.04457642138004303,
        0.0007728856289759278,
        0.061320241540670395,
        -0.05767117440700531,
        -0.033479299396276474,
        0.06368439644575119,
        -0.05504703149199486,
        0.0491572804749012,
        -0.05548026040196419,
        -0.028578052297234535,
        -0.060328081250190735,
        -0.06324619799852371,
        0.06279150396585464,
        0.04270200803875923,
        -0.0602756142616272,
        -0.057860035449266434,
        0.05258730426430702,
        0.06216931715607643,
        -0.04696207121014595,
        -0.05049573630094528,
        -0.06351965665817261,
        -0.03906107693910599,
        0.051216430962085724,
        0.051153406500816345,
        0.051773346960544586,
        -0.05384417623281479,
        0.040530093014240265,
        0.009491486474871635,
        -0.020009737461805344,
        0.052854858338832855,
        0.036841392517089844,
        0.057478614151477814,
        -0.054679639637470245,
        -0.027024539187550545,
        -0.014439425431191921,
        0.05556374788284302,
        -0.05963663384318352,
        0.06368649005889893,
        -0.006437419448047876,
        -0.015850571915507317,
        0.01582260988652706,
        0.024937892332673073,
        -0.06312987953424454,
        -0.05371532216668129,
        -0.04522141441702843,
        -0.018794164061546326,
        -0.058628398925065994,
        -0.02555883303284645,
        0.02375905029475689,
        -0.0388726070523262,
        0.03594120219349861,
        0.055547717958688736,
        -0.002656466094776988,
        -0.0534987673163414,
        0.06397095322608948,
        0.048942018300294876,
        -0.0412907637655735,
        0.008504397235810757,
        -0.06286872178316116,
        0.06442883610725403,
        0.052088506519794464,
        0.0071400487795472145,
        0.048266373574733734,
        0.038180503994226456,
        0.051689472049474716,
        0.06244690343737602,
        0.06171529367566109,
        0.056880269199609756,
        -0.002096284879371524,
        0.062147174030542374,
        0.006195937283337116,
        -0.06324491649866104,
        0.037796780467033386,
        0.047474779188632965,
        0.05802278220653534,
        -0.047677915543317795,
        -0.028503766283392906,
        0.03289593756198883,
        0.02068430371582508,
        -0.055147066712379456,
        0.055061303079128265,
        0.046498604118824005,
        0.025455597788095474,
        -0.00043280961108393967,
        -0.015470600686967373,
        0.06299236416816711,
        0.0640910342335701,
        -0.06117742136120796,
        0.06352712213993073,
        -0.04626281186938286,
        -0.06207582727074623,
        0.06363154947757721,
        -0.03443282097578049,
        0.045932505279779434,
        0.016593674197793007,
        -0.03952699154615402,
        0.04472704231739044,
        -0.05252380296587944,
        0.02785807102918625,
        -0.019178448244929314,
        0.0500316396355629,
        0.05010228604078293,
        -0.03950374200940132,
        0.059771500527858734,
        0.04124659672379494,
        -0.06312885880470276,
        0.05399385094642639,
        0.03412443399429321,
        -0.022528406232595444,
        -0.0570075549185276,
        -0.021042125299572945,
        -0.008427605964243412,
        -0.058339886367321014,
        0.025738893076777458,
        0.03790615499019623,
        -0.03730298951268196,
        0.03257816284894943,
        -0.06404506415128708,
        -0.007734606973826885,
        0.03583946451544762,
        0.04285186901688576,
        -0.03419332951307297,
        -0.062166184186935425,
        0.018480870872735977,
        0.0016117444029077888,
        0.048101939260959625,
        -0.020488662645220757,
        0.05428897589445114,
        0.028028732165694237,
        0.04128692299127579,
        -0.06079789996147156,
        0.02898060902953148,
        0.06441237032413483
    ],
    [
        -0.054152440279722214,
        0.046069834381341934,
        0.04110468924045563,
        0.006667335517704487,
        0.06067925691604614,
        -0.041341833770275116,
        0.062297385185956955,
        0.024557996541261673,
        -0.03832384571433067,
        0.05827416852116585,
        0.03805137798190117,
        0.04221111163496971,
        -0.05659075081348419,
        0.006208289414644241,
        -0.05921119824051857,
        0.04784981161355972,
        -0.05812646448612213,
        0.044079653918743134,
        -0.05380648374557495,
        0.011129876598715782,
        -0.05839988589286804,
        -0.028347352519631386,
        0.054810456931591034,
        0.04286796599626541,
        0.04185588285326958,
        -0.010730252601206303,
        0.027196770533919334,
        0.04511518031358719,
        -0.04646962136030197,
        -0.04082270339131355,
        0.05253135412931442,
        -0.029339971020817757,
        -0.0006239138310775161,
        -0.05695218965411186,
        -0.030004093423485756,
        -0.007433504331856966,
        -0.05411636456847191,
        -0.06208517774939537,
        -0.06204413250088692,
        0.048145592212677,
        0.023242531344294548,
        0.04172027111053467,
        0.054154541343450546,
        -0.05021659657359123,
        -0.01087410468608141,
        0.012836883775889874,
        0.060296379029750824,
        0.05663354694843292,
        0.05847382918000221,
        0.04411148652434349,
        -0.01794409193098545,
        0.014439865946769714,
        0.060244396328926086,
        0.03579549863934517,
        -0.0024023675359785557,
        -0.046870894730091095,
        -0.015817847102880478,
        -0.05501922592520714,
        -0.02113274671137333,
        0.03601932153105736,
        -0.04707547649741173,
        -0.011424941010773182,
        -0.05498121678829193,
        0.013349724933505058,
        -0.030491041019558907,
        0.0501970537006855,
        0.058706995099782944,
        -0.0500611774623394,
        0.041949573904275894,
        -0.06270242482423782,
        -0.056952282786369324,
        -0.06259427964687347,
        0.03700752556324005,
        -0.020317986607551575,
        -0.0031345838215202093,
        0.029403546825051308,
        0.05261804535984993,
        -0.0046678874641656876,
        0.042453583329916,
        -0.04933753237128258,
        -0.03767360746860504,
        0.0622929185628891,
        -0.0286929439753294,
        0.016690094023942947,
        -0.0342404842376709,
        -0.0051282793283462524,
        -0.02114901877939701,
        -0.02688421495258808,
        0.007717097643762827,
        0.05924775078892708,
        -0.05524572357535362,
        0.03202410787343979,
        0.0018361338879913092,
        -0.0525621622800827,
        0.0151627566665411,
        -0.039739008992910385,
        -0.009012444876134396,
        -0.0206708125770092,
        -0.06013607978820801,
        -0.02907462976872921,
        -0.061964794993400574,
        -0.012456555850803852,
        0.058938708156347275,
        0.0544566847383976,
        -0.022562813013792038,
        -0.03076804243028164,
        -0.01590571179986,
        -0.01197945699095726,
        -0.04731009900569916,
        -0.04418705776333809,
        -0.02921641804277897,
        -0.06267273426055908,
        -0.005608541425317526,
        -0.03146056830883026,
        0.05532846227288246,
        -0.03624537214636803,
        -0.0437462292611599,
        -0.05683869123458862,
        -0.0580090768635273,
        -0.0016037567984312773,
        -0.052932385355234146,
        0.05353332310914993,
        -0.061613500118255615,
        0.053116604685783386,
        -0.06266161054372787,
        0.01179390400648117,
        -0.0561474934220314,
        -0.005340303294360638,
        0.05469706282019615,
        -0.0035338648594915867,
        0.01984930969774723,
        -0.0391591414809227,
        -0.04452440142631531,
        0.04327746108174324,
        -0.04971856623888016,
        -0.03271391615271568,
        0.034845009446144104,
        -0.04439074918627739,
        -0.024678897112607956,
        0.059743355959653854,
        0.05368427187204361,
        -0.006765627767890692,
        0.05310353264212608,
        -0.019255025312304497,
        -0.02466651424765587,
        0.04230036213994026,
        0.057847701013088226,
        -0.05628768354654312,
        -0.057467784732580185,
        0.05968744307756424,
        0.002259880071505904,
        -0.06227501109242439,
        -0.056688953191041946,
        0.0183296799659729,
        -0.04275859147310257,
        -0.054058484733104706,
        0.06274575740098953,
        -0.036822300404310226,
        -0.028733236715197563,
        0.014104584231972694,
        0.05898711830377579,
        0.005896922666579485,
        0.0359305664896965,
        0.007193629164248705,
        -0.057473134249448776,
        -0.036554548889398575,
        -0.04379940778017044,
        -0.0616755485534668,
        -0.022440409287810326,
        0.04062226414680481,
        -0.045780837535858154,
        -0.009036841802299023,
        -0.04666557535529137,
        -0.03812503442168236,
        -0.04022516310214996,
        0.060135651379823685,
        -0.06268985569477081,
        0.015101187862455845,
        0.05159100890159607,
        0.030143164098262787,
        0.05962800979614258,
        0.041528716683387756,
        0.05659785494208336,
        0.0582033134996891,
        0.03534264490008354,
        -0.011363335885107517,
        -0.0620269738137722,
        0.05927181616425514,
        -0.061116985976696014,
        -0.014955791644752026,
        0.02177780494093895,
        0.006179581396281719,
        -0.04932905733585358,
        -0.003660727757960558,
        -0.014853588305413723,
        0.007747820112854242,
        0.05263238400220871,
        -0.05722668394446373,
        0.023781392723321915,
        0.00390875618904829,
        -0.02531677484512329,
        0.02389572001993656,
        0.037136271595954895,
        0.01312499400228262,
        0.0014708939706906676,
        0.061796050518751144,
        0.04574152082204819,
        0.053197141736745834,
        -0.03186618909239769,
        -0.035427700728178024,
        -0.046740833669900894,
        0.046058669686317444,
        -0.044049303978681564,
        -0.04617714136838913,
        0.03352741524577141,
        -0.0036142354365438223,
        0.06196438521146774,
        0.054865285754203796,
        0.0609443336725235,
        -0.059738315641880035,
        -0.03995119035243988,
        0.010957206599414349,
        -0.04309483990073204,
        -0.06258852779865265,
        0.0601462796330452,
        -0.05446978658437729,
        -0.03517574816942215,
        0.024537689983844757,
        0.011470876634120941,
        -0.05075173079967499,
        -0.0558059997856617,
        -0.0592707134783268,
        -0.04104481637477875,
        0.01067668478935957,
        -0.041764866560697556,
        -0.05407167971134186,
        0.06008383259177208,
        -0.03266206011176109,
        0.010601158253848553,
        0.04539227485656738,
        0.05902229994535446,
        0.044554635882377625,
        -0.04101854935288429,
        0.03611264005303383,
        -0.04356861114501953,
        -0.027290571480989456,
        -0.0025614916812628508,
        0.01867094449698925,
        -0.05477868393063545,
        -0.029009604826569557,
        0.015337875112891197,
        -0.06147051602602005,
        -0.05000285804271698,
        -0.056855980306863785,
        0.03709480166435242,
        0.06160701811313629,
        -0.05716719850897789,
        0.030355416238307953,
        0.06197437644004822,
        0.05828467011451721,
        0.06185074895620346,
        0.05705184116959572,
        0.02734840288758278,
        0.049460478127002716,
        -0.03807896375656128,
        0.01774081587791443,
        0.01673910953104496,
        0.05695262923836708,
        0.04890943691134453,
        -0.03749898076057434,
        -0.04570057615637779,
        -0.06144161522388458,
        -0.05077742412686348,
        -0.06048106774687767,
        -0.035262059420347214,
        0.03822300210595131,
        -0.05987431854009628,
        0.05732708424329758,
        0.008815070614218712,
        -0.05418211966753006,
        -0.060148850083351135,
        -0.0454646572470665,
        0.04035945609211922,
        -0.02207901142537594,
        0.009720731526613235,
        -0.05736008286476135,
        0.015674035996198654,
        -0.05915337800979614,
        -0.061943791806697845,
        0.057144951075315475,
        -0.05351696535944939,
        0.04702780023217201,
        0.01028196606785059,
        0.006981473881751299,
        0.055640168488025665,
        -0.04542485997080803,
        0.06223949044942856,
        0.014776403084397316,
        0.0000073825285653583705,
        0.04732571169734001,
        -0.025297515094280243,
        0.05723821371793747,
        -0.0003658547066152096,
        0.023638155311346054,
        -0.005523935426026583,
        -0.060455065220594406,
        -0.032738253474235535,
        -0.021314172074198723,
        0.061133790761232376,
        -0.06025177240371704,
        0.0362548790872097,
        -0.04165766388177872,
        -0.05371361970901489,
        0.05172000452876091,
        -0.06059574335813522,
        -0.05701490864157677,
        0.062050554901361465,
        -0.04084881767630577,
        -0.010706719011068344,
        -0.05502192676067352,
        -0.06077739968895912,
        0.0303178820759058,
        0.06148992478847504,
        -0.032049115747213364,
        0.05008142068982124,
        0.058959122747182846,
        0.05518433824181557,
        0.02046799473464489,
        0.00842297449707985,
        0.009916814044117928,
        -0.059365034103393555,
        0.05225563049316406,
        0.023550692945718765,
        -0.04976823925971985,
        0.060427479445934296,
        -0.010605758056044579,
        -0.05417627841234207,
        0.06019970774650574,
        -0.059147946536540985,
        0.04866262152791023,
        0.05316946282982826,
        -0.02664085663855076,
        -0.06067793816328049,
        -0.061385661363601685,
        0.06290951371192932,
        -0.016833942383527756,
        0.026373138651251793,
        0.01755022257566452,
        0.03905392438173294,
        0.061256926506757736,
        -0.06285404413938522,
        -0.01578264869749546,
        -0.05686717852950096,
        -0.062214311212301254,
        -0.06180277839303017,
        0.05700809508562088,
        -0.03351297974586487,
        -0.027453124523162842,
        -0.05397674813866615,
        -0.03937787562608719,
        0.058076996356248856,
        0.055260151624679565,
        -0.05481503903865814,
        -0.030716225504875183,
        0.020993776619434357,
        -0.058095745742321014,
        0.06284382194280624,
        0.009366803802549839,
        0.03225768357515335,
        -0.06273547559976578,
        -0.04958576709032059,
        -0.06083202362060547,
        0.05209033936262131,
        0.04041413217782974,
        0.059731677174568176,
        0.060616832226514816,
        0.042344383895397186,
        0.015232562087476254,
        -0.05214599519968033,
        -0.04262828454375267,
        -0.010848631151020527,
        0.042524777352809906,
        -0.05843653529882431,
        -0.020071376115083694,
        0.06099069118499756,
        -0.05067155882716179,
        0.050879817456007004,
        -0.056288499385118484,
        -0.041944343596696854,
        -0.059370558708906174,
        -0.028872907161712646,
        0.039345864206552505,
        0.04708610475063324,
        0.04326017573475838,
        -0.05497637391090393,
        0.05368572846055031,
        0.05460052937269211,
        -0.022959118708968163,
        -0.012184331193566322,
        -0.053381163626909256,
        -0.007268558721989393,
        -0.007270298898220062,
        0.061971087008714676,
        0.046358849853277206,
        -0.04804004728794098,
        0.05547700822353363,
        0.03958113119006157,
        0.011278899386525154,
        0.05480579286813736,
        -0.05510096251964569,
        0.0503825880587101,
        -0.025350315496325493,
        -0.053445372730493546,
        -0.02257440984249115,
        0.05099873244762421,
        0.025334669277071953,
        -0.0024307689163833857,
        0.004157761577516794,
        0.00825443398207426,
        -0.004625935107469559,
        -0.05301094427704811,
        -0.051402270793914795,
        -0.05793153494596481,
        -0.045014575123786926,
        0.057889923453330994,
        0.03303566575050354,
        -0.026182109490036964,
        0.051401469856500626,
        -0.06273438036441803,
        -0.009604672901332378,
        0.05561961606144905,
        -0.033529553562402725,
        0.003284018486738205,
        0.05255848914384842,
        0.06154244393110275,
        0.0009816171368584037,
        -0.03257041797041893,
        -0.008142267353832722,
        0.055687081068754196,
        -0.051488254219293594,
        0.04644318297505379,
        0.013997896574437618,
        0.055863454937934875,
        0.050264421850442886,
        0.026128116995096207,
        -0.008098941296339035,
        0.06129589304327965,
        -0.058929990977048874,
        0.056269146502017975,
        -0.061706457287073135,
        -0.05455627664923668,
        0.061183273792266846,
        0.06157449260354042,
        0.008558560162782669,
        0.04236249998211861,
        -0.01724650152027607,
        0.03990772366523743,
        0.04772048816084862,
        -0.059867966920137405,
        0.05818520486354828,
        -0.0013874550350010395,
        0.015600033104419708,
        -0.05901183933019638,
        -0.02761654183268547,
        0.056941110640764236,
        0.06191699951887131,
        -0.06256148219108582,
        0.05927691236138344,
        -0.05831320583820343,
        -0.06077980250120163,
        0.03899482637643814,
        0.045856889337301254,
        0.0420793853700161,
        0.049351975321769714,
        0.034945305436849594,
        0.05222629755735397,
        -0.04719298332929611,
        0.04134496673941612,
        -0.013954234309494495,
        -0.005411066580563784,
        -0.001241407124325633,
        -0.04930391535162926,
        0.05300802364945412,
        0.05233550816774368,
        0.033476244658231735,
        -0.008389015682041645,
        0.052207496017217636,
        -0.06020203232765198,
        -0.034411877393722534,
        -0.05115892365574837,
        -0.02144039422273636,
        -0.05365057662129402,
        -0.015896204859018326,
        0.0408322736620903,
        0.06216373294591904,
        0.0075968303717672825,
        -0.060136228799819946,
        -0.0017798364860937,
        0.060235146433115005,
        0.04669242724776268,
        -0.01956137828528881,
        -0.06086224690079689,
        0.029268253594636917,
        0.008608895353972912,
        0.03548309579491615,
        0.025440024212002754,
        0.009187998250126839,
        0.0014753341674804688,
        -0.051003746688365936,
        -0.05514124780893326,
        0.06089482456445694,
        0.061433542519807816
    ],
    [
        0.031022343784570694,
        0.021774478256702423,
        -0.03340977802872658,
        0.00425858236849308,
        -0.03400310501456261,
        -0.03065936267375946,
        -0.04103964939713478,
        -0.05701959505677223,
        0.05734781175851822,
        -0.01285642758011818,
        0.05743362009525299,
        0.03549029305577278,
        -0.0571725107729435,
        0.026428477838635445,
        -0.05739295110106468,
        0.052677642554044724,
        0.025528648868203163,
        0.05382940173149109,
        0.05253863334655762,
        0.0445021353662014,
        -0.006334703881293535,
        -0.054016850888729095,
        0.056239526718854904,
        0.05425120145082474,
        -0.05577698349952698,
        0.04514596238732338,
        -0.03340931981801987,
        -0.023884771391749382,
        -0.05223458632826805,
        -0.021888116374611855,
        -0.055983252823352814,
        -0.05650307238101959,
        -0.050462402403354645,
        -0.05679894983768463,
        0.014264392666518688,
        -0.010047009214758873,
        -0.05136405676603317,
        -0.051748525351285934,
        0.04398727044463158,
        -0.012460274621844292,
        0.04702514782547951,
        -0.010259440168738365,
        0.050857461988925934,
        0.03959835693240166,
        0.052968580275774,
        -0.023938560858368874,
        -0.043969541788101196,
        0.0568360760807991,
        0.05776115879416466,
        0.05252993479371071,
        0.056374941021203995,
        0.05636368691921234,
        -0.05047175660729408,
        -0.04691464081406593,
        -0.0449872650206089,
        -0.03742039576172829,
        -0.049306221306324005,
        -0.03126215934753418,
        -0.05455829203128815,
        -0.05034458637237549,
        -0.05684858188033104,
        -0.01690712384879589,
        -0.056237101554870605,
        -0.014882482588291168,
        0.013595668599009514,
        -0.05630945786833763,
        -0.05683082342147827,
        -0.027999598532915115,
        -0.02594698965549469,
        -0.05659406632184982,
        -0.02505485899746418,
        -0.018130246549844742,
        0.05768799036741257,
        0.0520743690431118,
        0.020307546481490135,
        -0.04175155982375145,
        0.026556815952062607,
        0.05777363479137421,
        -0.01973978988826275,
        0.0012239302741363645,
        -0.04437963292002678,
        -0.026127444580197334,
        -0.05626402422785759,
        0.049543239176273346,
        -0.012829693965613842,
        0.008084746077656746,
        0.03429294750094414,
        -0.03410191833972931,
        0.03630461543798447,
        0.0498494878411293,
        -0.037245478481054306,
        0.039961133152246475,
        -0.032515641301870346,
        -0.05061465501785278,
        -0.05514264106750488,
        -0.01191549003124237,
        -0.053482018411159515,
        -0.050501544028520584,
        -0.0043603163212537766,
        -0.020144721493124962,
        -0.05753028392791748,
        0.05745943635702133,
        0.05452686548233032,
        0.05751955136656761,
        -0.05083262175321579,
        -0.056358154863119125,
        0.05480855703353882,
        0.056228093802928925,
        -0.04781986400485039,
        -0.04700277000665665,
        -0.039292581379413605,
        -0.05442171171307564,
        0.004180161748081446,
        -0.02248547226190567,
        0.056747931987047195,
        0.006489732302725315,
        0.009836098179221153,
        -0.051404282450675964,
        0.057510651648044586,
        -0.037642162293195724,
        -0.057113081216812134,
        -0.049127247184515,
        0.057335205376148224,
        -0.057502876967191696,
        -0.041339211165905,
        -0.05302289128303528,
        0.05705665796995163,
        0.05687745660543442,
        0.0577254518866539,
        0.008480764925479889,
        0.04705139622092247,
        -0.04179643467068672,
        -0.05546741560101509,
        -0.012560738250613213,
        -0.042041677981615067,
        -0.05607961490750313,
        0.05693326145410538,
        0.021340789273381233,
        -0.003873085603117943,
        0.04227244853973389,
        0.005396735388785601,
        0.05709650367498398,
        0.05606328323483467,
        0.05764676257967949,
        -0.03659309446811676,
        0.05637764185667038,
        -0.0030646859668195248,
        -0.039198100566864014,
        -0.051285672932863235,
        0.020903760567307472,
        -0.04488421976566315,
        -0.057710252702236176,
        -0.05065914988517761,
        0.05372485890984535,
        0.057760175317525864,
        -0.05755291506648064,
        -0.057641059160232544,
        -0.05611025169491768,
        -0.027205223217606544,
        -0.045666974037885666,
        0.015389088541269302,
        0.008714002557098866,
        0.047042958438396454,
        0.05571438744664192,
        -0.026888689026236534,
        0.05105111002922058,
        0.056096743792295456,
        -0.024615243077278137,
        -0.027081631124019623,
        0.022640977054834366,
        -0.040040332823991776,
        -0.018640654161572456,
        -0.04906130209565163,
        0.01831981912255287,
        -0.005983022972941399,
        0.054768215864896774,
        0.0015599785838276148,
        0.012904847972095013,
        0.0502283051609993,
        -0.04610475152730942,
        -0.018239647150039673,
        0.03514977544546127,
        0.019960740581154823,
        -0.05536545440554619,
        0.0434112511575222,
        0.05526589974761009,
        -0.0360117182135582,
        0.0544939748942852,
        -0.05538588762283325,
        -0.05158751457929611,
        -0.057692643254995346,
        0.017684027552604675,
        0.056222930550575256,
        0.005515431985259056,
        -0.047967880964279175,
        -0.050188954919576645,
        -0.029515646398067474,
        0.055758122354745865,
        0.05656606703996658,
        0.04683883488178253,
        0.05430152639746666,
        -0.03800724819302559,
        -0.05659931153059006,
        -0.05290016159415245,
        0.0032954781781882048,
        0.03030727431178093,
        0.0521005317568779,
        0.02637673355638981,
        -0.05569807440042496,
        0.05765645578503609,
        -0.05657409504055977,
        -0.0007736383122391999,
        0.003120616776868701,
        0.041960522532463074,
        0.050445955246686935,
        -0.05745474994182587,
        0.0005058778333477676,
        -0.019923046231269836,
        0.057728640735149384,
        0.05586814135313034,
        -0.037048403173685074,
        -0.03418168053030968,
        0.053881820291280746,
        -0.05618376284837723,
        0.03663744032382965,
        -0.05051819607615471,
        0.05638522654771805,
        -0.05075790733098984,
        0.0573565810918808,
        -0.04628662019968033,
        -0.05574704706668854,
        -0.05741012468934059,
        -0.05209529027342796,
        0.05185926333069801,
        -0.006641386076807976,
        0.05754060670733452,
        0.05582302808761597,
        -0.030484749004244804,
        -0.009020336903631687,
        0.05777286738157272,
        -0.024244418367743492,
        0.047653280198574066,
        0.03992803394794464,
        -0.03750893101096153,
        0.007796205580234528,
        -0.007674215827137232,
        -0.05685921013355255,
        0.0553537979722023,
        0.03792663663625717,
        0.028442053124308586,
        -0.05772650986909866,
        -0.056425709277391434,
        0.04953767731785774,
        -0.054462090134620667,
        0.054497428238391876,
        0.053327787667512894,
        -0.013097976334393024,
        -0.030017253011465073,
        0.056771524250507355,
        -0.027978986501693726,
        -0.05706752464175224,
        0.04298339784145355,
        0.013417810201644897,
        0.05716390162706375,
        0.05452337861061096,
        0.045122984796762466,
        0.03985332325100899,
        0.05270843580365181,
        -0.05035136640071869,
        0.043971430510282516,
        -0.03842928633093834,
        -0.046271637082099915,
        0.05726421996951103,
        -0.055182259529829025,
        -0.03036821447312832,
        0.03347152844071388,
        -0.04604904726147652,
        0.05737777799367905,
        0.04577021300792694,
        -0.05775314196944237,
        -0.0029226336628198624,
        0.020775718614459038,
        -0.03908644989132881,
        -0.0457858182489872,
        0.02199125848710537,
        0.03515569120645523,
        -0.011960966512560844,
        0.057203877717256546,
        -0.04704834148287773,
        -0.05002797767519951,
        -0.05468890815973282,
        0.00732466159388423,
        -0.042123038321733475,
        -0.028574494644999504,
        0.057217396795749664,
        -0.05702060088515282,
        0.05769151449203491,
        0.05358227714896202,
        0.010230743326246738,
        0.0574699267745018,
        0.03808499500155449,
        -0.004751857835799456,
        -0.03866535797715187,
        0.05467052012681961,
        -0.03882494568824768,
        -0.008036691695451736,
        -0.02593155950307846,
        0.05039435997605324,
        0.05240994691848755,
        0.02136012725532055,
        0.049877434968948364,
        -0.028311118483543396,
        0.05498938262462616,
        -0.02142324671149254,
        -0.028084462508559227,
        -0.05722002685070038,
        -0.01800686866044998,
        -0.04304281994700432,
        0.05699985846877098,
        -0.04133470728993416,
        -0.03692072257399559,
        -0.013638831675052643,
        0.05057266354560852,
        0.045980777591466904,
        0.055801358073949814,
        -0.04047258943319321,
        0.057612884789705276,
        -0.0574384443461895,
        -0.012298102490603924,
        -0.05059445649385452,
        -0.05280312895774841,
        -0.05075106769800186,
        0.04358689859509468,
        -0.04732857272028923,
        0.05763766169548035,
        0.05355263873934746,
        0.0072517781518399715,
        0.03475881740450859,
        -0.024635225534439087,
        0.057012949138879776,
        -0.05672762915492058,
        -0.02574756368994713,
        0.0053722430020570755,
        0.0236649326980114,
        -0.005611421540379524,
        0.05502689629793167,
        0.037779975682497025,
        0.057507868856191635,
        0.03147115930914879,
        0.05309143289923668,
        0.057706281542778015,
        0.03609349951148033,
        0.01803327538073063,
        -0.04606349766254425,
        0.031491123139858246,
        0.00017973891226574779,
        -0.042301081120967865,
        -0.05730627849698067,
        0.03440195694565773,
        -0.05512021481990814,
        0.053102366626262665,
        0.04123002290725708,
        -0.05606997385621071,
        -0.046279098838567734,
        -0.030168822035193443,
        -0.05715787410736084,
        0.05721065402030945,
        0.04941435158252716,
        -0.0008686355431564152,
        -0.057097334414720535,
        -0.022235842421650887,
        -0.046133656054735184,
        0.03387502208352089,
        -0.05772467330098152,
        0.04711088538169861,
        0.057008709758520126,
        -0.03151216357946396,
        0.05742393434047699,
        0.05537775903940201,
        -0.05107900872826576,
        -0.0028179106302559376,
        0.03707115724682808,
        -0.05772044137120247,
        0.04934476688504219,
        0.057399898767471313,
        -0.05019063502550125,
        -0.020379213616251945,
        -0.05297783389687538,
        -0.0560239814221859,
        -0.057473957538604736,
        -0.05427602678537369,
        -0.05062064528465271,
        -0.047734130173921585,
        -0.017295707017183304,
        0.03258223831653595,
        0.054603345692157745,
        0.01580696739256382,
        -0.0023269718512892723,
        -0.010693274438381195,
        -0.05727351829409599,
        -0.05144553259015083,
        0.057606276124715805,
        0.019757794216275215,
        0.05662879720330238,
        -0.057062290608882904,
        -0.005260718520730734,
        0.05453262850642204,
        -0.008834486827254295,
        0.05718739330768585,
        -0.04633201286196709,
        -0.049720849841833115,
        -0.05116473510861397,
        0.05650680512189865,
        -0.006929065566509962,
        0.045603301376104355,
        -0.03908805921673775,
        -0.03198301047086716,
        -0.024631807580590248,
        0.05197831988334656,
        0.04686915874481201,
        -0.02787557803094387,
        -0.03850758075714111,
        -0.04040401801466942,
        -0.05759582668542862,
        0.045665767043828964,
        -0.021765392273664474,
        0.018666772171854973,
        0.054350193589925766,
        -0.0455450601875782,
        -0.056712016463279724,
        0.04824700579047203,
        -0.04619162157177925,
        0.033348649740219116,
        -0.05756676197052002,
        0.03676295280456543,
        -0.05482109636068344,
        -0.05725695565342903,
        0.053266510367393494,
        -0.043493300676345825,
        -0.045422106981277466,
        -0.053423091769218445,
        0.05423504486680031,
        0.023958703503012657,
        0.057048726826906204,
        0.04717613011598587,
        0.04125608131289482,
        0.05357908457517624,
        -0.016840072348713875,
        0.0418362133204937,
        0.057466503232717514,
        0.05028567463159561,
        0.01938336342573166,
        -0.004473869688808918,
        0.05742830038070679,
        -0.006410492118448019,
        -0.05598931014537811,
        -0.013614502735435963,
        -0.04896209016442299,
        0.05260368809103966,
        0.038770370185375214,
        0.05466989800333977,
        -0.056666240096092224,
        0.009748010896146297,
        0.05008267983794212,
        0.05737356096506119,
        0.05769151449203491,
        -0.055772606283426285,
        0.056995999068021774,
        -0.0373636893928051,
        -0.05699404329061508,
        0.044811490923166275,
        0.05437349155545235,
        0.052939195185899734,
        -0.02533518522977829,
        -0.04731551557779312,
        -0.016110045835375786,
        0.04177495464682579,
        0.03204470872879028,
        -0.0004099346697330475,
        -0.055889397859573364,
        0.055057115852832794,
        0.015762314200401306,
        -0.05755804851651192,
        0.029907364398241043,
        -0.050880465656518936,
        0.04814963415265083,
        0.05520353838801384,
        0.05085271969437599,
        -0.04277830943465233,
        0.006256084889173508,
        -0.04941695183515549,
        0.013917145319283009,
        -0.030753104016184807,
        -0.04575958102941513,
        -0.014705401845276356,
        0.011915386654436588,
        0.05774807929992676,
        0.027740560472011566,
        0.0561220608651638,
        0.05442235246300697,
        0.05198264494538307,
        0.04776482656598091,
        -0.027653038501739502,
        0.045208640396595,
        0.03282778710126877,
        0.04762620851397514,
        0.008606691844761372,
        -0.02808394655585289,
        -0.04761727526783943,
        -0.057604338973760605,
        0.05306140333414078,
        -0.05556229129433632
    ],
    [
        0.053516048938035965,
        -0.04701044037938118,
        -0.01125648245215416,
        -0.01578868366777897,
        0.050824932754039764,
        -0.05433773621916771,
        0.05523376911878586,
        0.054996855556964874,
        -0.05531918257474899,
        -0.03095964342355728,
        -0.048113755881786346,
        0.009904546663165092,
        -0.04195645824074745,
        0.014764096587896347,
        -0.05374353751540184,
        0.024928070604801178,
        0.04605032503604889,
        0.0344085730612278,
        0.05438993498682976,
        0.052947793155908585,
        0.050846491008996964,
        -0.05254604294896126,
        -0.03929973393678665,
        0.0477963350713253,
        -0.05540492385625839,
        0.05010618269443512,
        -0.052513010799884796,
        -0.04061947762966156,
        0.014002818614244461,
        -0.02631184831261635,
        -0.026207124814391136,
        0.03786047548055649,
        0.011503851041197777,
        0.05168447270989418,
        0.0547783263027668,
        -0.040786586701869965,
        -0.05454828962683678,
        -0.04771001264452934,
        0.05538187921047211,
        -0.05166996642947197,
        0.021597430109977722,
        0.03555063158273697,
        0.055291470140218735,
        -0.017813172191381454,
        -0.04506072774529457,
        0.028614340350031853,
        -0.042772408574819565,
        0.05551069974899292,
        0.05281057581305504,
        -0.009553699754178524,
        0.0547124408185482,
        0.05535605177283287,
        0.014050302095711231,
        -0.04893320053815842,
        -0.000015676312614232302,
        0.004115046933293343,
        -0.02802426926791668,
        -0.04271551966667175,
        -0.054182782769203186,
        0.05424901098012924,
        -0.055344775319099426,
        -0.015100161544978619,
        -0.0446845144033432,
        -0.05111593380570412,
        -0.013817070052027702,
        0.055108554661273956,
        -0.05368078127503395,
        0.0307085532695055,
        0.04535757750272751,
        0.021704066544771194,
        -0.052780259400606155,
        0.055498138070106506,
        0.05458828806877136,
        0.05548680201172829,
        0.025636855512857437,
        -0.053154412657022476,
        0.054598964750766754,
        0.05551108345389366,
        -0.005514062475413084,
        -0.0516800656914711,
        -0.05383031815290451,
        -0.027239125221967697,
        -0.0378074087202549,
        -0.013946778140962124,
        0.001664813025854528,
        0.051585759967565536,
        0.042958978563547134,
        -0.05548049509525299,
        -0.05475554242730141,
        0.00219517620280385,
        -0.05543268099427223,
        -0.03859029337763786,
        -0.05408716946840286,
        0.04361112788319588,
        -0.025343753397464752,
        -0.020832207053899765,
        0.004466583952307701,
        -0.05255201831459999,
        -0.019263850525021553,
        0.052582997828722,
        0.035638853907585144,
        0.01497997622936964,
        0.05412210151553154,
        0.05540088191628456,
        -0.04017442464828491,
        -0.05364130064845085,
        -0.05394573509693146,
        -0.007038152776658535,
        0.02781415916979313,
        -0.05544117093086243,
        0.0485244020819664,
        0.03320804238319397,
        -0.0554976724088192,
        -0.04359591379761696,
        0.028442589566111565,
        0.05545902997255325,
        0.04425971582531929,
        0.010615100152790546,
        -0.05179549753665924,
        0.042068250477313995,
        -0.03347621485590935,
        -0.051905810832977295,
        0.053317729383707047,
        -0.03141806647181511,
        -0.055085841566324234,
        0.04402020201086998,
        0.051166974008083344,
        -0.0026865790132433176,
        0.05349893867969513,
        -0.010802491568028927,
        0.05436297133564949,
        -0.051453884690999985,
        -0.03292541578412056,
        0.055428873747587204,
        -0.05138769373297691,
        -0.05393330380320549,
        0.009938552044332027,
        0.021635018289089203,
        -0.0022417129948735237,
        0.05525582283735275,
        -0.04563667252659798,
        -0.04969846084713936,
        0.05511540174484253,
        0.048735056072473526,
        -0.0552881620824337,
        0.030820028856396675,
        -0.01632917858660221,
        -0.052916016429662704,
        0.05230027809739113,
        -0.0025594178587198257,
        -0.051404956728219986,
        -0.054031696170568466,
        -0.0508267767727375,
        -0.05528022721409798,
        0.044100817292928696,
        -0.05058855563402176,
        -0.05291575938463211,
        -0.046823494136333466,
        0.04693654924631119,
        0.03296615183353424,
        -0.0014735986478626728,
        0.030683608725667,
        0.05548164248466492,
        0.045564763247966766,
        -0.007395117077976465,
        0.050905779004096985,
        -0.05057777091860771,
        -0.028435152024030685,
        0.014892148785293102,
        0.049755699932575226,
        -0.04337173327803612,
        0.045576855540275574,
        -0.05502214655280113,
        0.05089309811592102,
        -0.050346579402685165,
        -0.054771360009908676,
        0.0038396958261728287,
        -0.05123218148946762,
        0.05356740951538086,
        0.041487596929073334,
        0.039982181042432785,
        0.0534108504652977,
        0.03367466479539871,
        -0.05458403006196022,
        -0.055463533848524094,
        -0.05106665566563606,
        0.00951058603823185,
        0.05378716439008713,
        0.051920074969530106,
        0.01141875796020031,
        0.018360471352934837,
        -0.05067325755953789,
        0.053297873586416245,
        0.04931215941905975,
        -0.04520943760871887,
        -0.04200611263513565,
        0.05550859123468399,
        0.048571109771728516,
        0.05261998996138573,
        0.008337713778018951,
        0.05548682063817978,
        0.03827119991183281,
        0.039913635700941086,
        -0.05267506092786789,
        0.051926739513874054,
        -0.03495723381638527,
        0.055283065885305405,
        0.054906945675611496,
        0.05241909995675087,
        0.03407125174999237,
        -0.030545666813850403,
        -0.055509455502033234,
        0.052974943071603775,
        0.014405920170247555,
        -0.055259834975004196,
        -0.0498560331761837,
        0.05462931841611862,
        0.050900623202323914,
        0.05541395768523216,
        0.028345530852675438,
        0.049810461699962616,
        0.043721236288547516,
        -0.05168614163994789,
        -0.05511924624443054,
        0.055350326001644135,
        0.024286048486828804,
        -0.026905179023742676,
        -0.055459164083004,
        0.05300100892782211,
        -0.05542603135108948,
        0.02772805280983448,
        0.04962391406297684,
        -0.054043643176555634,
        0.04609784111380577,
        0.028715020045638084,
        0.05548008158802986,
        0.049685899168252945,
        0.018072934821248055,
        0.014233898371458054,
        0.0555058978497982,
        0.04732512682676315,
        0.043723300099372864,
        -0.04597105458378792,
        -0.054525405168533325,
        0.05398271977901459,
        0.025570860132575035,
        0.05544725060462952,
        -0.05482710525393486,
        0.04481447860598564,
        0.014434734359383583,
        -0.053084127604961395,
        -0.05393402650952339,
        -0.03800114616751671,
        -0.05408720299601555,
        -0.012767201289534569,
        -0.05277078598737717,
        0.038075000047683716,
        0.013872945681214333,
        0.05494065582752228,
        -0.020111950114369392,
        -0.010447044856846333,
        0.03638412430882454,
        0.027340935543179512,
        -0.05551157146692276,
        -0.024384059011936188,
        -0.0371708981692791,
        0.044648636132478714,
        0.03290577232837677,
        0.04253155365586281,
        -0.03656262904405594,
        0.05545022338628769,
        -0.05514293909072876,
        0.05438019335269928,
        0.04060035198926926,
        -0.02372898906469345,
        0.04732539877295494,
        -0.053692545741796494,
        0.04962152615189552,
        0.025274652987718582,
        0.05490921065211296,
        -0.055138278752565384,
        0.05504189431667328,
        -0.05413822457194328,
        0.03764638304710388,
        0.040438685566186905,
        -0.009648934006690979,
        0.054597318172454834,
        0.05500621721148491,
        0.054191723465919495,
        0.04039718955755234,
        -0.05511821061372757,
        0.055356185883283615,
        0.0051146638579666615,
        0.009647047147154808,
        0.05536463484168053,
        -0.054420314729213715,
        0.05530709773302078,
        -0.0029601636342704296,
        -0.052226096391677856,
        0.04900222271680832,
        -0.03227948024868965,
        -0.035151831805706024,
        0.02128586918115616,
        0.04953477531671524,
        -0.03006010502576828,
        0.007105131167918444,
        0.053162045776844025,
        0.05537095665931702,
        -0.05218806117773056,
        -0.04465358331799507,
        0.055106256157159805,
        -0.0554579459130764,
        0.05535073205828667,
        0.05518440902233124,
        0.028794534504413605,
        -0.04794500023126602,
        0.011787815019488335,
        -0.05529843643307686,
        0.053927212953567505,
        0.027516383677721024,
        0.05433377996087074,
        -0.04645262286067009,
        0.05547673627734184,
        0.01567886397242546,
        0.052198272198438644,
        0.04210776090621948,
        0.006770291365683079,
        0.00332914968021214,
        -0.053854674100875854,
        -0.055155713111162186,
        0.006967267021536827,
        -0.055440787225961685,
        -0.05263204127550125,
        -0.05010928586125374,
        0.054843220859766006,
        0.026581566780805588,
        0.05052104592323303,
        0.04220622405409813,
        0.03188887611031532,
        0.053579673171043396,
        -0.04821983724832535,
        -0.005658453330397606,
        -0.0006090247770771384,
        0.02960854582488537,
        0.0478980652987957,
        0.02448306605219841,
        0.05439133569598198,
        0.05364779755473137,
        0.053603705018758774,
        -0.004065654706209898,
        0.05535203218460083,
        0.03540974110364914,
        -0.04163096100091934,
        -0.05425763130187988,
        -0.0009517159778624773,
        0.03276168555021286,
        0.04886726662516594,
        -0.05001576989889145,
        0.05466560646891594,
        -0.036933451890945435,
        0.054911453276872635,
        0.003943510819226503,
        0.03828401863574982,
        -0.05432794243097305,
        -0.05457533150911331,
        -0.0318882018327713,
        0.055509086698293686,
        -0.05400611087679863,
        -0.04320073872804642,
        -0.05532854422926903,
        -0.034268561750650406,
        -0.05507231503725052,
        -0.025689005851745605,
        0.05287308990955353,
        0.022745970636606216,
        0.055370479822158813,
        0.05026322603225708,
        -0.05261174961924553,
        0.05002913624048233,
        -0.05550401285290718,
        -0.05408954992890358,
        -0.016401488333940506,
        -0.05402480810880661,
        0.04299946501851082,
        -0.005762966349720955,
        -0.05517410486936569,
        -0.021691521629691124,
        -0.042350105941295624,
        -0.04601481556892395,
        -0.04595275968313217,
        0.04353516921401024,
        -0.01959381438791752,
        -0.03369033336639404,
        -0.05518275126814842,
        0.054748859256505966,
        0.054891083389520645,
        0.05546093359589577,
        -0.05549639090895653,
        -0.05550036206841469,
        0.05285730957984924,
        0.024933865293860435,
        0.05475209653377533,
        -0.0035169203765690327,
        0.055290985852479935,
        0.011334426701068878,
        -0.0128733916208148,
        0.053467150777578354,
        -0.048282355070114136,
        0.05328745394945145,
        0.051840681582689285,
        -0.01600373350083828,
        0.026805495843291283,
        0.02481599897146225,
        0.025553077459335327,
        0.05547739565372467,
        -0.05522608757019043,
        -0.04126498103141785,
        0.0481514111161232,
        -0.0213308222591877,
        0.054632216691970825,
        0.054731570184230804,
        0.01197014469653368,
        -0.05332124978303909,
        -0.05496218055486679,
        -0.05543609336018562,
        -0.04214233160018921,
        0.050576649606227875,
        -0.0467291995882988,
        -0.01589353196322918,
        -0.03900449723005295,
        0.04573213681578636,
        -0.05127235874533653,
        0.022766677662730217,
        -0.04429329186677933,
        -0.05247257649898529,
        -0.03226413205265999,
        -0.049098942428827286,
        -0.05473014712333679,
        -0.0014107425231486559,
        -0.055439285933971405,
        -0.045513302087783813,
        0.05534495413303375,
        0.02515389211475849,
        0.05479162931442261,
        -0.051199059933423996,
        0.045429691672325134,
        0.054808005690574646,
        -0.055111996829509735,
        0.03670944646000862,
        -0.01699659787118435,
        -0.04738608002662659,
        0.040061142295598984,
        0.0541045106947422,
        0.05078231915831566,
        0.04163161665201187,
        -0.022201593965291977,
        0.0522715225815773,
        -0.05461740866303444,
        0.05412984639406204,
        -0.037930507212877274,
        0.02352892793715,
        -0.03841370716691017,
        -0.04670798406004906,
        -0.0511714331805706,
        0.044749483466148376,
        0.05547313392162323,
        -0.050039444118738174,
        0.0548318587243557,
        0.008826147764921188,
        -0.04793902114033699,
        0.013947428204119205,
        0.024664821103215218,
        0.0533413365483284,
        -0.05246669799089432,
        0.03601836413145065,
        -0.008514870889484882,
        0.049088019877672195,
        -0.05022646486759186,
        -0.03778267279267311,
        0.05398476496338844,
        -0.054089974611997604,
        -0.03939847648143768,
        -0.054907482117414474,
        -0.020833242684602737,
        0.01553613692522049,
        -0.020246680825948715,
        0.016257354989647865,
        0.053070586174726486,
        -0.05521334335207939,
        0.03632260486483574,
        -0.05149776116013527,
        0.04030382260680199,
        0.05133439600467682,
        0.031313207000494,
        0.05324440822005272,
        0.04825831204652786,
        -0.055062226951122284,
        0.05479373410344124,
        0.01834705099463463,
        -0.013130602426826954,
        0.0003323783166706562,
        0.03233341500163078,
        -0.055443067103624344,
        -0.05498572066426277,
        0.05397404357790947,
        -0.05342453718185425,
        0.055489253252744675,
        -0.002134055132046342,
        0.05545223876833916,
        -0.05505788326263428,
        0.029903952032327652,
        0.05533759295940399
    ],
    [
        0.047747112810611725,
        0.05230434983968735,
        -0.005752429831773043,
        -0.03357600048184395,
        0.00041907536797225475,
        -0.049575235694646835,
        0.05095258727669716,
        -0.053225476294755936,
        0.052845947444438934,
        -0.0531708225607872,
        -0.03252626955509186,
        0.030145753175020218,
        -0.053153153508901596,
        0.007307711523026228,
        -0.052946723997592926,
        -0.012200522236526012,
        0.024260932579636574,
        0.05226689577102661,
        -0.040991898626089096,
        0.052180562168359756,
        0.0396994985640049,
        -0.05254208296537399,
        0.053233444690704346,
        0.012800301425158978,
        0.024672724306583405,
        -0.025047985836863518,
        0.05064460635185242,
        0.05209050700068474,
        -0.03223908320069313,
        -0.0005106091848574579,
        -0.0508962944149971,
        0.03653815761208534,
        -0.052707161754369736,
        -0.053221795707941055,
        0.05245126783847809,
        -0.05323024466633797,
        -0.05315900966525078,
        -0.053032346069812775,
        0.049860261380672455,
        -0.023704838007688522,
        0.051624272018671036,
        0.021115843206644058,
        0.0431925430893898,
        -0.03861016780138016,
        0.04415551945567131,
        -0.01178014650940895,
        0.05043322965502739,
        0.05322975292801857,
        0.05297553911805153,
        -0.053210582584142685,
        0.052544932812452316,
        0.051896605640649796,
        -0.041865069419145584,
        -0.05169123411178589,
        -0.052394431084394455,
        0.029014524072408676,
        -0.05320155993103981,
        0.02439958043396473,
        -0.05238238722085953,
        -0.05297917500138283,
        -0.05198853462934494,
        -0.008303063921630383,
        -0.05298791825771332,
        -0.042018674314022064,
        0.04478162154555321,
        0.01238236203789711,
        -0.05323203280568123,
        -0.051842983812093735,
        0.028267810121178627,
        -0.04226851835846901,
        0.002484411932528019,
        -0.05181129649281502,
        0.053079187870025635,
        0.04375355690717697,
        0.053223866969347,
        -0.050258804112672806,
        0.05323364585638046,
        0.053234029561281204,
        0.05319007486104965,
        -0.009539952501654625,
        0.002842827932909131,
        -0.05301950126886368,
        -0.05294802784919739,
        0.051343418657779694,
        0.05180026590824127,
        0.044425297528505325,
        -0.04274192824959755,
        -0.05291430279612541,
        -0.03544444590806961,
        -0.019214898347854614,
        -0.053212471306324005,
        -0.03654986247420311,
        -0.04747324436903,
        -0.053054891526699066,
        -0.044183116406202316,
        0.05262308567762375,
        0.006110381335020065,
        -0.05210443586111069,
        0.02784915827214718,
        0.015673264861106873,
        -0.05314760282635689,
        0.051154881715774536,
        0.05071881040930748,
        0.05312328785657883,
        0.018070565536618233,
        -0.0497460700571537,
        0.026889842003583908,
        -0.03394117206335068,
        -0.051756683737039566,
        -0.043324217200279236,
        -0.0523623526096344,
        0.05317769572138786,
        -0.044289298355579376,
        -0.05259465053677559,
        0.05323052778840065,
        -0.05299452692270279,
        -0.031929243355989456,
        -0.05204227939248085,
        0.042949747294187546,
        -0.050706855952739716,
        -0.031970810145139694,
        -0.0038124816492199898,
        0.040532879531383514,
        -0.05317690968513489,
        -0.049929603934288025,
        0.05317641422152519,
        -0.044109586626291275,
        0.043443337082862854,
        0.05316690728068352,
        0.03573652729392052,
        -0.00406373618170619,
        -0.05266360938549042,
        -0.029475271701812744,
        0.05298370122909546,
        -0.04448627308011055,
        -0.0532270222902298,
        0.05052902549505234,
        0.029010044410824776,
        -0.050503164529800415,
        0.04657621309161186,
        0.031283821910619736,
        0.00040432540117762983,
        0.0478440523147583,
        0.051447607576847076,
        0.053008291870355606,
        -0.02004489302635193,
        0.05323142558336258,
        -0.009154180996119976,
        0.01080347876995802,
        0.048792991787195206,
        -0.03990574926137924,
        -0.053168315440416336,
        -0.050441134721040726,
        -0.03924398496747017,
        0.0523330383002758,
        -0.028077779337763786,
        -0.05169842019677162,
        -0.0492556132376194,
        -0.050254564732313156,
        0.05090567097067833,
        0.024959024041891098,
        0.05312172695994377,
        0.052492059767246246,
        0.05317869409918785,
        -0.051121920347213745,
        -0.045169975608587265,
        0.053213153034448624,
        0.013363001868128777,
        -0.051801666617393494,
        0.0481833852827549,
        0.04455219581723213,
        -0.02120419405400753,
        -0.05317622423171997,
        0.05316619575023651,
        -0.04945792257785797,
        0.027426280081272125,
        -0.006185239180922508,
        -0.028034429997205734,
        0.05304739251732826,
        -0.05160614848136902,
        -0.053149498999118805,
        0.021246356889605522,
        0.05291236564517021,
        -0.050375595688819885,
        -0.03815491870045662,
        -0.034256234765052795,
        0.04453334957361221,
        0.051711417734622955,
        0.041299644857645035,
        -0.053090862929821014,
        -0.0532294400036335,
        -0.03312743455171585,
        -0.047874972224235535,
        0.047550927847623825,
        -0.028605245053768158,
        0.039051156491041183,
        0.05321716144680977,
        0.03879598528146744,
        -0.050212547183036804,
        -0.003693251172080636,
        0.045800287276506424,
        0.04552827775478363,
        0.047520507127046585,
        -0.04719657078385353,
        -0.052954159677028656,
        0.01319573912769556,
        0.05163655802607536,
        0.023349730297923088,
        0.01932838000357151,
        -0.05238795652985573,
        -0.050688568502664566,
        0.04972793161869049,
        -0.026054244488477707,
        0.05323297902941704,
        -0.053230270743370056,
        -0.05321194604039192,
        0.0532306507229805,
        0.047583963721990585,
        0.05322849377989769,
        -0.0050194100476801395,
        -0.02008538506925106,
        0.04951288178563118,
        0.034318823367357254,
        0.0029665199108421803,
        0.05282030627131462,
        -0.051317159086465836,
        0.04580865427851677,
        0.05318056046962738,
        -0.047545816749334335,
        -0.05308325216174126,
        -0.04534480720758438,
        -0.05275546386837959,
        0.007392751052975655,
        0.04888812080025673,
        -0.053077101707458496,
        0.05323290824890137,
        0.05254199355840683,
        0.017634129151701927,
        0.04557962343096733,
        0.05323335528373718,
        -0.018778253346681595,
        0.013724244199693203,
        0.014344899915158749,
        0.0037716806400567293,
        0.002275556093081832,
        -0.05265793949365616,
        0.03431103378534317,
        0.05193542689085007,
        -0.045085079967975616,
        0.049705132842063904,
        -0.05316425859928131,
        -0.049012333154678345,
        -0.05279630422592163,
        -0.05255075916647911,
        -0.026513712480664253,
        -0.03666435554623604,
        -0.05106017366051674,
        -0.052058588713407516,
        0.0484314002096653,
        0.048149224370718,
        -0.048654764890670776,
        -0.01725584827363491,
        -0.05319841578602791,
        -0.018810158595442772,
        -0.05302577093243599,
        0.053232111036777496,
        0.000058795423683477566,
        -0.05234791338443756,
        0.044101376086473465,
        0.022923855111002922,
        -0.01967787928879261,
        -0.05174238234758377,
        0.047930337488651276,
        -0.053208015859127045,
        -0.05319688096642494,
        0.044581782072782516,
        -0.047440726310014725,
        0.0509796105325222,
        -0.05290981009602547,
        0.0502600334584713,
        -0.05303758755326271,
        0.05233215168118477,
        -0.011580265127122402,
        0.051555030047893524,
        0.04668787866830826,
        0.04755934700369835,
        -0.012639541178941727,
        0.0527716688811779,
        -0.05236735939979553,
        0.05007047578692436,
        -0.05157950147986412,
        0.05225824937224388,
        -0.0514475479722023,
        -0.05321264639496803,
        0.03645343333482742,
        -0.052782200276851654,
        0.052738215774297714,
        0.04061949998140335,
        -0.05323294922709465,
        0.05122436583042145,
        0.02333974279463291,
        -0.00005007567961001769,
        -0.044750113040208817,
        0.051388636231422424,
        0.04260340332984924,
        0.028180517256259918,
        -0.04143780097365379,
        0.053221795707941055,
        -0.003964122850447893,
        -0.04386257752776146,
        0.053208958357572556,
        -0.05298621580004692,
        0.05100162327289581,
        0.04701569676399231,
        0.004132005386054516,
        -0.05301911011338234,
        -0.04029945656657219,
        -0.052639834582805634,
        0.05286337435245514,
        0.05011856555938721,
        0.051414087414741516,
        -0.0037417567800730467,
        -0.042083919048309326,
        -0.023349694907665253,
        0.05315162241458893,
        -0.009395767003297806,
        0.038960050791502,
        -0.05083126202225685,
        -0.03568632900714874,
        -0.04860489070415497,
        0.05322609841823578,
        -0.04330097511410713,
        -0.04843179136514664,
        -0.05259764567017555,
        0.04536418616771698,
        -0.03794604167342186,
        0.016196370124816895,
        0.045890968292951584,
        -0.02417438104748726,
        0.05019085109233856,
        -0.04773365333676338,
        -0.039354659616947174,
        -0.029094187542796135,
        -0.03790569305419922,
        0.0037593182642012835,
        0.04779147729277611,
        0.044441141188144684,
        0.05193304643034935,
        0.052415408194065094,
        0.053172994405031204,
        0.05315839871764183,
        -0.043051254004240036,
        0.025438552722334862,
        -0.04966403916478157,
        -0.0527091920375824,
        -0.014563213102519512,
        -0.049021292477846146,
        -0.04710612818598747,
        -0.047195397317409515,
        -0.003310668282210827,
        0.05032943934202194,
        -0.0515156164765358,
        0.050984520465135574,
        -0.047115396708250046,
        0.05046611279249191,
        -0.053058408200740814,
        0.053154852241277695,
        -0.053146589547395706,
        0.04495048150420189,
        -0.05319875478744507,
        -0.01495466846972704,
        -0.04038883373141289,
        0.052680931985378265,
        -0.007568450644612312,
        0.044588275253772736,
        0.053230829536914825,
        -0.016531608998775482,
        -0.008651193231344223,
        0.050312139093875885,
        -0.04647916182875633,
        -0.0491219237446785,
        0.0011767206015065312,
        -0.048143547028303146,
        0.04516690969467163,
        0.02816510573029518,
        -0.053123775869607925,
        0.01573709212243557,
        -0.05275823920965195,
        -0.04655382037162781,
        -0.05001030117273331,
        -0.05301350727677345,
        0.04987817257642746,
        -0.05237498879432678,
        -0.04564511775970459,
        0.045553214848041534,
        0.05316226929426193,
        0.05293643847107887,
        -0.05293279513716698,
        -0.041977573186159134,
        -0.02563442848622799,
        0.004206356126815081,
        0.05291372165083885,
        -0.036712758243083954,
        0.05044853314757347,
        -0.05086381360888481,
        -0.05309094488620758,
        0.05249059945344925,
        -0.05238127335906029,
        0.0528980977833271,
        0.020590191707015038,
        0.04231731593608856,
        -0.04723869264125824,
        -0.0428524985909462,
        0.048265695571899414,
        0.0467362105846405,
        -0.05310656130313873,
        -0.0248209647834301,
        0.041215408593416214,
        0.04662866145372391,
        0.033896494656801224,
        -0.033531829714775085,
        -0.053068533539772034,
        -0.0505107045173645,
        -0.05322469025850296,
        0.04360680282115936,
        -0.04771006852388382,
        0.019165871664881706,
        0.04661259055137634,
        -0.05041522532701492,
        -0.05321330204606056,
        0.053213778883218765,
        -0.01929149031639099,
        0.04601861909031868,
        0.0378861278295517,
        -0.05136234313249588,
        -0.053153038024902344,
        -0.051415156573057175,
        0.05030760169029236,
        -0.023971155285835266,
        -0.052499234676361084,
        -0.04998215287923813,
        0.0531582236289978,
        0.02696431241929531,
        0.052900005131959915,
        -0.05316183343529701,
        -0.05019095540046692,
        0.05032649263739586,
        -0.05313156545162201,
        -0.03463951498270035,
        0.05303769186139107,
        -0.05258489400148392,
        -0.00994871649891138,
        0.051030732691287994,
        0.01822276972234249,
        -0.014775566756725311,
        -0.045429106801748276,
        -0.04572766646742821,
        -0.03889771178364754,
        -0.0505012683570385,
        0.05319793522357941,
        0.03903763368725777,
        -0.028637470677495003,
        -0.04891511797904968,
        -0.05278293043375015,
        0.052835945039987564,
        0.05311516299843788,
        -0.05320780351758003,
        0.05322756990790367,
        0.05062369629740715,
        -0.05080345273017883,
        0.04695020243525505,
        -0.05205787345767021,
        0.05260675400495529,
        0.04819013923406601,
        0.02847984805703163,
        0.047405924648046494,
        0.030058683827519417,
        -0.05321328341960907,
        -0.02816656231880188,
        -0.032886434346437454,
        -0.006112449802458286,
        -0.02281421609222889,
        -0.05315149202942848,
        0.04661009833216667,
        -0.05048918351531029,
        0.052977029234170914,
        0.011290284804999828,
        0.040091052651405334,
        -0.05304383859038353,
        -0.053147897124290466,
        0.036130137741565704,
        -0.024210281670093536,
        -0.01442586351186037,
        -0.03396259620785713,
        -0.05211006477475166,
        -0.010334262624382973,
        -0.05287351831793785,
        -0.05063321813941002,
        0.044625815004110336,
        -0.019380692392587662,
        -0.043748702853918076,
        0.01592399924993515,
        -0.046661701053380966,
        -0.034799572080373764,
        0.05204746127128601,
        0.051791202276945114,
        0.05308694392442703,
        -0.041638508439064026,
        0.0518985353410244,
        -0.04849248379468918,
        0.02672485262155533,
        0.05266701430082321
    ],
    [
        0.022605178877711296,
        -0.047946084290742874,
        -0.05250471085309982,
        -0.008288118988275528,
        0.05125909671187401,
        -0.05358794704079628,
        0.05095341429114342,
        -0.05345563963055611,
        0.04975367337465286,
        0.021322892978787422,
        0.039855245500802994,
        -0.05049806088209152,
        -0.0537189282476902,
        -0.03188181668519974,
        -0.05314920097589493,
        0.05205737054347992,
        0.02243928425014019,
        0.011556143872439861,
        0.00812776479870081,
        0.031777869910001755,
        0.045097555965185165,
        -0.01681608147919178,
        -0.004838649649173021,
        0.010581559501588345,
        0.015876881778240204,
        0.05301675945520401,
        0.004883312154561281,
        0.05147337540984154,
        -0.051677051931619644,
        0.015149358659982681,
        -0.04981740564107895,
        0.037837401032447815,
        0.05187896266579628,
        -0.053719960153102875,
        0.05333492159843445,
        -0.0531989187002182,
        -0.05359812080860138,
        -0.051248349249362946,
        0.05139121413230896,
        -0.036778524518013,
        -0.030446259304881096,
        -0.03671032935380936,
        0.052696891129016876,
        -0.05230730026960373,
        -0.03764602914452553,
        0.021740179508924484,
        0.052886128425598145,
        0.05261451005935669,
        0.053723640739917755,
        -0.029703836888074875,
        0.05243712291121483,
        0.05309193581342697,
        0.053298164159059525,
        -0.05273481830954552,
        -0.03683441877365112,
        0.044684335589408875,
        -0.05358028784394264,
        0.04643915593624115,
        -0.05274438112974167,
        -0.05299612507224083,
        -0.053415678441524506,
        0.03732956573367119,
        -0.052288156002759933,
        0.02755679190158844,
        0.049176156520843506,
        -0.04908121004700661,
        -0.05350230634212494,
        -0.04890608787536621,
        -0.005420645698904991,
        0.022640127688646317,
        -0.03308267891407013,
        -0.05350368097424507,
        0.05372634530067444,
        0.05291435495018959,
        0.05334315076470375,
        -0.047663066536188126,
        0.05341891944408417,
        0.053732339292764664,
        -0.030339783057570457,
        0.051706138998270035,
        0.02917906828224659,
        -0.05057767406105995,
        -0.04604919254779816,
        0.04318128526210785,
        0.048782601952552795,
        0.04076714068651199,
        -0.0074332221411168575,
        -0.04695652052760124,
        -0.05345099791884422,
        0.05303926393389702,
        -0.04812949523329735,
        -0.040575187653303146,
        -0.05307070165872574,
        -0.053236134350299835,
        -0.05267412215471268,
        0.04095523804426193,
        -0.047683198004961014,
        -0.051606595516204834,
        0.03976831212639809,
        0.03895014151930809,
        -0.0537090040743351,
        0.040516197681427,
        0.04455147683620453,
        0.05201634019613266,
        -0.023973239585757256,
        -0.04085442051291466,
        -0.04557541757822037,
        0.048823609948158264,
        -0.052554626017808914,
        0.033188626170158386,
        0.05287974327802658,
        -0.05372419208288193,
        -0.051575738936662674,
        0.012111584655940533,
        0.052944038063287735,
        -0.05330155789852142,
        -0.04882848635315895,
        -0.049849651753902435,
        0.05271713435649872,
        -0.05157586932182312,
        -0.053525447845458984,
        -0.050021182745695114,
        0.037348583340644836,
        -0.053662680089473724,
        -0.053701356053352356,
        0.04963015392422676,
        -0.05051090195775032,
        0.0137252202257514,
        0.05084452033042908,
        -0.024959877133369446,
        -0.01928580179810524,
        -0.052084192633628845,
        -0.053674377501010895,
        0.05245862901210785,
        -0.04801826924085617,
        -0.0528574213385582,
        0.05372610315680504,
        -0.014207806438207626,
        0.01826130412518978,
        0.05295511335134506,
        0.049734849482774734,
        -0.04492359235882759,
        0.008843814954161644,
        0.05314444378018379,
        0.03409910574555397,
        0.05033232271671295,
        0.002516530454158783,
        0.011727966368198395,
        0.05020305514335632,
        0.037122514098882675,
        -0.05294866859912872,
        -0.05373157933354378,
        -0.053651805967092514,
        -0.05217244476079941,
        0.05313647910952568,
        -0.016179002821445465,
        -0.02029529958963394,
        -0.012075278908014297,
        0.02153443545103073,
        0.05356624722480774,
        0.02152550034224987,
        0.05038299039006233,
        0.05328966677188873,
        0.05372893437743187,
        0.05054406449198723,
        0.01572464406490326,
        0.05288651958107948,
        0.016897274181246758,
        -0.053023096174001694,
        0.05304805561900139,
        -0.04112850874662399,
        0.033179543912410736,
        -0.05245192348957062,
        -0.005853945855051279,
        -0.05351699888706207,
        -0.04183938726782799,
        0.0315127968788147,
        -0.052013054490089417,
        0.052966274321079254,
        -0.04413662478327751,
        -0.012920375913381577,
        0.05362172797322273,
        0.053325355052948,
        -0.049999773502349854,
        0.0030074473470449448,
        -0.03189646825194359,
        0.05296008661389351,
        0.05350514501333237,
        0.04768102988600731,
        -0.052426792681217194,
        -0.053667102009058,
        0.004871781915426254,
        -0.023248817771673203,
        -0.043141305446624756,
        -0.04196324944496155,
        0.0483800545334816,
        0.02879040129482746,
        -0.04175790026783943,
        -0.0016364268958568573,
        0.013998943381011486,
        0.052644163370132446,
        0.037067968398332596,
        0.045565854758024216,
        -0.04956964775919914,
        -0.009943632408976555,
        -0.0180046409368515,
        0.024028846994042397,
        0.05290203168988228,
        -0.016240568831562996,
        0.05236343666911125,
        -0.05294685438275337,
        -0.04277846962213516,
        0.042739175260066986,
        0.05235898122191429,
        0.04581603780388832,
        -0.04686962440609932,
        0.053580913692712784,
        0.036186445504426956,
        0.05372485890984535,
        0.014326631091535091,
        0.023039711639285088,
        0.049875590950250626,
        0.030211729928851128,
        -0.05116961896419525,
        0.05367070436477661,
        -0.053703710436820984,
        0.0013366304337978363,
        -0.049644507467746735,
        0.03823152929544449,
        -0.05371767282485962,
        -0.05360272154211998,
        -0.04210934787988663,
        -0.04970220848917961,
        0.0516800656914711,
        -0.04329501837491989,
        0.05310438945889473,
        0.05064099654555321,
        0.05235065147280693,
        0.0009848460322245955,
        0.05373154208064079,
        -0.04296921566128731,
        0.04286285117268562,
        -0.0445505827665329,
        -0.052924200892448425,
        0.052456993609666824,
        0.03261198848485947,
        -0.03733496740460396,
        -0.012374651618301868,
        -0.04565391317009926,
        0.017400039359927177,
        -0.05067496374249458,
        -0.052246030420064926,
        0.05341411754488945,
        -0.05368407443165779,
        -0.04387597367167473,
        0.05219472944736481,
        -0.04492776468396187,
        -0.047203339636325836,
        0.047757476568222046,
        0.053109344094991684,
        -0.03556311875581741,
        0.04980829730629921,
        -0.03661626949906349,
        0.05182763189077377,
        -0.004051855765283108,
        0.05368509888648987,
        0.04011223837733269,
        0.048714544624090195,
        0.012399964034557343,
        -0.03194935992360115,
        0.049017056822776794,
        -0.05363605171442032,
        0.05302741006016731,
        -0.05342854931950569,
        -0.051396533846855164,
        0.051201123744249344,
        -0.04926276579499245,
        0.0507800467312336,
        0.05204791948199272,
        -0.04970631003379822,
        -0.05362594872713089,
        0.05009499564766884,
        -0.05038602277636528,
        -0.039811816066503525,
        0.05346288904547691,
        0.050328049808740616,
        -0.04873980954289436,
        0.05222496762871742,
        -0.04574524238705635,
        0.011585261672735214,
        -0.052798133343458176,
        0.02072214148938656,
        -0.03225873410701752,
        -0.049984127283096313,
        0.049142301082611084,
        -0.05313640460371971,
        0.05361437425017357,
        0.03558318316936493,
        -0.050332821905612946,
        0.053724996745586395,
        0.02626624144613743,
        0.031586527824401855,
        -0.04575183987617493,
        0.053657446056604385,
        0.027659157291054726,
        0.009252795949578285,
        0.02963205799460411,
        -0.007331399247050285,
        0.04680607095360756,
        -0.05022013187408447,
        -0.00800345465540886,
        0.019736971706151962,
        0.05318136885762215,
        0.053711727261543274,
        0.00776981096714735,
        -0.053666017949581146,
        0.02612091600894928,
        0.02865804359316826,
        0.052920207381248474,
        -0.03820423409342766,
        0.0212961807847023,
        -0.030205875635147095,
        0.048971232026815414,
        -0.03985918313264847,
        0.05343473702669144,
        0.04200689122080803,
        0.053730327636003494,
        -0.053699154406785965,
        -0.052765510976314545,
        -0.052060507237911224,
        0.05340458080172539,
        0.052564989775419235,
        -0.032250165939331055,
        -0.043666210025548935,
        0.052982550114393234,
        0.045534081757068634,
        0.05039336159825325,
        0.050932832062244415,
        -0.01041904091835022,
        0.05172717943787575,
        -0.05285309627652168,
        -0.047793492674827576,
        0.002878702012822032,
        0.03451471030712128,
        0.05277836322784424,
        0.05042349174618721,
        0.05320107564330101,
        0.05309067294001579,
        0.03668481484055519,
        0.053595129400491714,
        0.049244966357946396,
        0.048489801585674286,
        -0.02589574083685875,
        -0.05011286213994026,
        -0.04563596099615097,
        0.04602481424808502,
        -0.05016634613275528,
        -0.019023125991225243,
        -0.04476312920451164,
        -0.026248103007674217,
        0.050111789256334305,
        0.028796376660466194,
        -0.04988354817032814,
        -0.0008176144910976291,
        -0.03219141438603401,
        -0.05354057624936104,
        0.053686726838350296,
        -0.05019379407167435,
        -0.020507164299488068,
        -0.053700800985097885,
        -0.035118669271469116,
        -0.047931164503097534,
        0.0532434843480587,
        -0.05334867164492607,
        0.053516656160354614,
        -0.016961123794317245,
        -0.02890734374523163,
        -0.045328930020332336,
        0.05228385701775551,
        -0.033822786062955856,
        0.051364410668611526,
        0.018713796511292458,
        -0.05111856013536453,
        -0.03084946610033512,
        0.04683668911457062,
        -0.04693615809082985,
        0.03905457630753517,
        -0.04117636755108833,
        -0.04644917696714401,
        0.02472115494310856,
        -0.037203989923000336,
        0.02994558773934841,
        -0.050528693944215775,
        -0.03684963285923004,
        0.053580962121486664,
        0.051898710429668427,
        0.04980362206697464,
        -0.0535232312977314,
        -0.04825998842716217,
        -0.052483316510915756,
        -0.01864873431622982,
        0.05299612507224083,
        -0.05340533331036568,
        0.028933096677064896,
        -0.017594322562217712,
        -0.052172496914863586,
        -0.04483279213309288,
        0.004081972874701023,
        0.053678158670663834,
        -0.0018216365715488791,
        -0.011267617344856262,
        -0.05236088111996651,
        -0.0012227521510794759,
        0.02360234037041664,
        0.01765034720301628,
        -0.05270035192370415,
        0.05274231731891632,
        -0.05327703058719635,
        0.05184998735785484,
        0.05337250232696533,
        0.053494106978178024,
        -0.05352472513914108,
        -0.045282427221536636,
        -0.0537274070084095,
        0.051901206374168396,
        0.03786328807473183,
        -0.050439611077308655,
        -0.003286347258836031,
        -0.05258144065737724,
        -0.04318273067474365,
        0.02534235082566738,
        -0.05022893100976944,
        -0.009960826486349106,
        0.04433034360408783,
        -0.05353974178433418,
        -0.05339169129729271,
        -0.053716566413640976,
        -0.025315696373581886,
        -0.0534762367606163,
        -0.05269084498286247,
        -0.05280504748225212,
        0.05367317423224449,
        0.05370650440454483,
        0.05371709540486336,
        -0.052406471222639084,
        -0.04338601231575012,
        -0.01567445509135723,
        -0.05371318757534027,
        0.03622136637568474,
        0.053256791085004807,
        0.03649410977959633,
        0.03172861412167549,
        0.018900178372859955,
        -0.005156827159225941,
        0.020096415653824806,
        -0.05365214869379997,
        -0.01462637446820736,
        -0.05258897319436073,
        0.030230330303311348,
        0.053013887256383896,
        0.04556950554251671,
        -0.05346493050456047,
        0.004041009116917849,
        -0.05344563350081444,
        0.050895459949970245,
        0.05366320535540581,
        -0.05196982994675636,
        0.05357920005917549,
        -0.04656525328755379,
        -0.05339955911040306,
        0.008539760485291481,
        0.053014520555734634,
        0.053175993263721466,
        0.0497516393661499,
        0.052913881838321686,
        -0.030987482517957687,
        -0.05312707647681236,
        0.05363128334283829,
        -0.052432723343372345,
        -0.05287032574415207,
        -0.05370321497321129,
        -0.012687708251178265,
        -0.053656842559576035,
        0.004158657044172287,
        0.02274942211806774,
        0.0533011369407177,
        0.043569449335336685,
        0.041878197342157364,
        -0.05359500274062157,
        -0.04885783791542053,
        0.03316650912165642,
        -0.027322476729750633,
        0.04591302201151848,
        -0.05304022505879402,
        -0.010390257462859154,
        -0.05354470759630203,
        0.04848445579409599,
        -0.03527155518531799,
        0.05359009653329849,
        -0.014219489879906178,
        -0.04644322022795677,
        -0.051843367516994476,
        -0.03443990275263786,
        -0.02058841660618782,
        0.049623142927885056,
        0.05300595611333847,
        0.05338675528764725,
        -0.044530488550662994,
        0.053386084735393524,
        -0.05372915044426918,
        0.05089530348777771,
        0.05073058232665062
    ],
    [
        -0.04651260003447533,
        -0.02163710817694664,
        0.0009656304027885199,
        -0.05060657486319542,
        -0.05076981708407402,
        -0.04794185981154442,
        0.047324489802122116,
        -0.053137294948101044,
        0.029667651280760765,
        -0.054166197776794434,
        -0.02119547128677368,
        0.014222374185919762,
        -0.05430758744478226,
        -0.022782674059271812,
        -0.05433588847517967,
        0.049281228333711624,
        0.01947540044784546,
        0.05356953293085098,
        -0.004363220185041428,
        0.05432865396142006,
        0.04845436289906502,
        0.04635106399655342,
        0.051904741674661636,
        -0.02731279842555523,
        -0.051890578120946884,
        0.05180089920759201,
        0.027888009324669838,
        0.053588926792144775,
        -0.0372653529047966,
        -0.05051425099372864,
        0.04401267692446709,
        0.05007585510611534,
        0.05401425063610077,
        -0.05258007347583771,
        0.052140865474939346,
        -0.05460633337497711,
        -0.05441894754767418,
        -0.025747904554009438,
        0.04603228718042374,
        -0.03483137860894203,
        0.01694360189139843,
        0.05394132435321808,
        0.053352609276771545,
        -0.04795604199171066,
        0.010890722274780273,
        -0.03732018917798996,
        0.05134459584951401,
        0.05448336526751518,
        0.05427176505327225,
        -0.05330871045589447,
        0.05112019553780556,
        0.05457030236721039,
        0.05273948237299919,
        -0.02155391313135624,
        0.05312906205654144,
        0.0012163869105279446,
        -0.054396528750658035,
        0.021244099363684654,
        -0.05446784943342209,
        -0.05208297073841095,
        -0.05265088379383087,
        0.04919058084487915,
        -0.054174695163965225,
        -0.05338546261191368,
        0.0390767902135849,
        0.00798415020108223,
        -0.03957325592637062,
        -0.050984565168619156,
        0.045103009790182114,
        0.03885810449719429,
        0.023674611002206802,
        -0.023101992905139923,
        0.054579634219408035,
        0.018780885264277458,
        0.054570067673921585,
        -0.040116824209690094,
        0.05455038323998451,
        0.05464999005198479,
        0.05390229821205139,
        -0.023166688159108162,
        -0.020716996863484383,
        -0.05013815313577652,
        -0.05397450178861618,
        0.03274577111005783,
        0.020810307934880257,
        0.011615260504186153,
        -0.040276918560266495,
        -0.05437134951353073,
        -0.05407150462269783,
        0.054069649428129196,
        -0.053562965244054794,
        -0.04504597187042236,
        -0.04545355960726738,
        -0.054239120334386826,
        0.03198416903614998,
        0.04927507042884827,
        0.053246062248945236,
        -0.05356299877166748,
        -0.0107515137642622,
        0.052190739661455154,
        -0.05456098914146423,
        -0.046403609216213226,
        0.050353359431028366,
        0.05426337942481041,
        0.022439684718847275,
        -0.049685653299093246,
        -0.03626924753189087,
        0.03368038684129715,
        -0.05217325687408447,
        0.029867205768823624,
        -0.04253029823303223,
        -0.05087237060070038,
        -0.0496864877641201,
        -0.020636964589357376,
        0.05461173504590988,
        -0.0530523881316185,
        -0.04421132802963257,
        -0.0436643585562706,
        0.05404934659600258,
        -0.04062989726662636,
        -0.052475303411483765,
        -0.03238101303577423,
        0.03327024728059769,
        -0.054091598838567734,
        -0.0545346662402153,
        0.05441197380423546,
        -0.050587572157382965,
        0.05406599119305611,
        0.04673169553279877,
        0.04835578799247742,
        -0.00029410517890937626,
        -0.05379272252321243,
        -0.04054153710603714,
        0.05454624071717262,
        -0.0383385568857193,
        -0.05390569567680359,
        0.053393829613924026,
        0.026378478854894638,
        0.003645566524937749,
        0.02902304008603096,
        -0.04926382005214691,
        -0.02263868972659111,
        0.0531601756811142,
        -0.0014578005066141486,
        -0.02659866400063038,
        -0.020542815327644348,
        0.04751667007803917,
        -0.009733714163303375,
        0.02457321435213089,
        -0.002095491625368595,
        -0.05307835713028908,
        -0.05463289096951485,
        -0.054345518350601196,
        -0.024845324456691742,
        0.04630320891737938,
        -0.05201397091150284,
        -0.051479537039995193,
        0.008338314481079578,
        0.05364286154508591,
        -0.009208006784319878,
        -0.018583089113235474,
        0.043304167687892914,
        0.054592400789260864,
        0.05169238895177841,
        0.05373677983880043,
        0.0007539170910604298,
        0.05097021907567978,
        0.054020922631025314,
        -0.05200660973787308,
        0.038267869502305984,
        0.03317156434059143,
        0.004548778757452965,
        -0.05449838936328888,
        0.05008093640208244,
        -0.04384907707571983,
        0.03702421858906746,
        0.03458300232887268,
        -0.0450640469789505,
        0.054503314197063446,
        -0.004227383062243462,
        -0.05311558023095131,
        0.05442586913704872,
        0.03899691253900528,
        -0.05097717419266701,
        -0.02781505696475506,
        -0.05359659343957901,
        0.0536096952855587,
        0.0524788573384285,
        0.053218718618154526,
        -0.01623561978340149,
        -0.04748893901705742,
        0.051350902765989304,
        0.031615957617759705,
        0.04569780081510544,
        -0.028160549700260162,
        0.04025479406118393,
        0.05182746425271034,
        0.044442884624004364,
        -0.01201790850609541,
        0.045292727649211884,
        0.054369740188121796,
        0.03342124819755554,
        -0.015548656694591045,
        -0.054413504898548126,
        -0.01157686859369278,
        -0.046574585139751434,
        0.05121498554944992,
        0.05147755146026611,
        -0.02159256860613823,
        0.052779655903577805,
        -0.054469551891088486,
        0.039797522127628326,
        0.05113360658288002,
        0.0521547831594944,
        -0.04567134380340576,
        -0.04060528427362442,
        0.0517878420650959,
        0.048366203904151917,
        0.054647237062454224,
        0.05068443343043327,
        0.048411719501018524,
        0.04968968778848648,
        0.03131956607103348,
        -0.05200488492846489,
        0.05456335097551346,
        -0.04844573885202408,
        -0.00973315816372633,
        -0.051631104201078415,
        0.025634238496422768,
        -0.05454888567328453,
        -0.049823787063360214,
        -0.04421916976571083,
        0.04858524724841118,
        0.05356578156352043,
        -0.029581688344478607,
        0.054634641855955124,
        0.05366211757063866,
        0.0038679386489093304,
        -0.0048240176402032375,
        0.05460424721240997,
        -0.05312786623835564,
        0.0033064621966332197,
        -0.04589230567216873,
        0.038892343640327454,
        -0.017781434580683708,
        -0.03837987035512924,
        -0.04139750078320503,
        0.0038038415368646383,
        -0.034595929086208344,
        0.04015880823135376,
        -0.05440210923552513,
        -0.053319983184337616,
        0.0415925458073616,
        -0.054199978709220886,
        0.028517084196209908,
        0.034777186810970306,
        0.04739784076809883,
        -0.053280774503946304,
        0.05324423685669899,
        0.043999411165714264,
        -0.05404192954301834,
        -0.031652212142944336,
        -0.054154641926288605,
        0.05033145099878311,
        -0.0542527511715889,
        0.05464959517121315,
        0.032469943165779114,
        0.016252433881163597,
        -0.030185766518115997,
        -0.015250961296260357,
        0.05160700902342796,
        -0.054620977491140366,
        0.05264757573604584,
        -0.05121457204222679,
        -0.053288012742996216,
        0.038706835359334946,
        -0.05156371742486954,
        -0.006760723888874054,
        0.023995280265808105,
        0.046128276735544205,
        -0.05456176772713661,
        0.05459556356072426,
        -0.0526447519659996,
        -0.02496909163892269,
        0.012704255059361458,
        -0.048177458345890045,
        -0.04883754998445511,
        0.05458221957087517,
        0.02452540211379528,
        0.0542030856013298,
        -0.054636772722005844,
        0.02538357675075531,
        -0.03701690211892128,
        -0.012449987232685089,
        0.051341816782951355,
        -0.05420126020908356,
        0.05429508537054062,
        0.0451933816075325,
        -0.05337255820631981,
        0.05402949079871178,
        -0.0372978113591671,
        -0.038190972059965134,
        -0.04540051892399788,
        0.051030054688453674,
        0.05028850957751274,
        0.015650292858481407,
        -0.0516597144305706,
        0.054536331444978714,
        0.03726011887192726,
        -0.05323368310928345,
        0.05450207740068436,
        0.013869916088879108,
        0.05384165048599243,
        0.054135147482156754,
        0.050574660301208496,
        -0.05453123152256012,
        -0.033043574541807175,
        -0.05272618681192398,
        0.050030793994665146,
        -0.015233923681080341,
        0.032125331461429596,
        0.0046095456928014755,
        0.05384888872504234,
        -0.04439447820186615,
        0.054274968802928925,
        -0.04270989075303078,
        -0.009989420883357525,
        -0.053693026304244995,
        -0.042405422776937485,
        -0.05452369526028633,
        0.0546153262257576,
        -0.054555367678403854,
        -0.05061168223619461,
        -0.05453786253929138,
        0.05041687935590744,
        0.001962978160008788,
        -0.053455453366041183,
        0.05272607132792473,
        -0.04518141970038414,
        0.0067678410559892654,
        -0.04292607679963112,
        -0.040400296449661255,
        -0.032561399042606354,
        -0.04274103417992592,
        0.042738176882267,
        0.05063994228839874,
        0.05390201508998871,
        0.05424738675355911,
        0.04853922501206398,
        0.05312783271074295,
        0.05376950651407242,
        0.04302538186311722,
        -0.015424231067299843,
        -0.05116141587495804,
        -0.0368538573384285,
        0.044575560837984085,
        -0.018342899158596992,
        0.031109614297747612,
        -0.04510384052991867,
        -0.02772180363535881,
        0.05114934220910072,
        0.05302886292338371,
        -0.04069990664720535,
        -0.043135493993759155,
        0.013804224319756031,
        -0.052117105573415756,
        0.05394977703690529,
        -0.053007468581199646,
        -0.0486639179289341,
        -0.05280650779604912,
        -0.036045536398887634,
        -0.05121473968029022,
        0.053295448422431946,
        0.04500411078333855,
        0.054253920912742615,
        0.05454816669225693,
        -0.03679382801055908,
        -0.027331674471497536,
        0.05431648716330528,
        -0.05304623767733574,
        -0.05336539074778557,
        -0.027368558570742607,
        -0.049301471561193466,
        -0.048257164657115936,
        0.028789477422833443,
        -0.05408787354826927,
        0.008714281022548676,
        -0.04361899197101593,
        -0.03951318934559822,
        0.03411079943180084,
        -0.04723808541893959,
        0.011889916844666004,
        -0.05304965376853943,
        -0.05274743586778641,
        0.0543641522526741,
        0.054482799023389816,
        0.05256049707531929,
        -0.04371915012598038,
        -0.05452923849225044,
        0.0002961285354103893,
        0.05126183107495308,
        0.044090062379837036,
        -0.05389034375548363,
        0.054420288652181625,
        -0.04851588234305382,
        0.011987488716840744,
        0.02666327729821205,
        -0.04870415851473808,
        0.05463353171944618,
        0.051572974771261215,
        -0.051340170204639435,
        -0.05364473536610603,
        0.04289798066020012,
        -0.05117679014801979,
        0.053570833057165146,
        -0.054329581558704376,
        0.011672255583107471,
        -0.00040824440657161176,
        0.05334765836596489,
        0.05320826917886734,
        0.054131582379341125,
        -0.05372123792767525,
        -0.05204275995492935,
        -0.05455601587891579,
        -0.04647133871912956,
        -0.0424027219414711,
        -0.0530204214155674,
        -0.053228314965963364,
        -0.05158081278204918,
        -0.05433157831430435,
        0.051714859902858734,
        -0.05389467999339104,
        0.047847647219896317,
        0.04588460922241211,
        -0.05158241465687752,
        -0.027922429144382477,
        -0.05460613593459129,
        -0.03510887920856476,
        -0.05220169946551323,
        -0.052780281752347946,
        -0.024147702381014824,
        0.05460678040981293,
        0.049410801380872726,
        0.054074812680482864,
        -0.010833595879375935,
        -0.05062994733452797,
        0.03731939196586609,
        -0.04659483581781387,
        0.025314969941973686,
        0.05422428250312805,
        0.027588119730353355,
        -0.006759134121239185,
        0.05401737242937088,
        0.05189269036054611,
        -0.005728928372263908,
        -0.04633267596364021,
        -0.02537076734006405,
        -0.0526568628847599,
        0.04089996591210365,
        0.013129672966897488,
        0.04283474385738373,
        -0.04070115089416504,
        -0.03239457309246063,
        -0.04303745552897453,
        0.008072887547314167,
        0.054583292454481125,
        -0.05383993312716484,
        0.054424259811639786,
        0.04659509286284447,
        -0.054557330906391144,
        -0.018110647797584534,
        -0.02986018918454647,
        0.046153031289577484,
        -0.005510098300874233,
        0.03430645540356636,
        0.04742215573787689,
        0.03461836278438568,
        0.05417298153042793,
        -0.05459943786263466,
        -0.03017484024167061,
        -0.04792550578713417,
        -0.0457887277007103,
        -0.028665993362665176,
        -0.029942533001303673,
        -0.02161746472120285,
        0.04333610087633133,
        0.04978630691766739,
        0.047562260180711746,
        -0.0545206181704998,
        0.01329424325376749,
        -0.03673732653260231,
        0.006651922129094601,
        0.04998330399394035,
        -0.0036885584704577923,
        -0.049661312252283096,
        -0.012616360560059547,
        -0.04014334827661514,
        -0.05272792652249336,
        0.04462403431534767,
        -0.02897368185222149,
        -0.05205807462334633,
        -0.018772052600979805,
        -0.053505923599004745,
        -0.023972364142537117,
        0.04429711028933525,
        0.0205822866410017,
        0.054431524127721786,
        -0.0423823818564415,
        0.05442165210843086,
        -0.05463233217597008,
        0.048919569700956345,
        0.03327840939164162
    ],
    [
        -0.012125284411013126,
        0.05690832436084747,
        -0.01699894480407238,
        0.005402856040745974,
        0.04294159263372421,
        0.009808781556785107,
        0.0587138757109642,
        0.028652342036366463,
        -0.05461573600769043,
        0.05256224796175957,
        0.0490957610309124,
        0.04943842440843582,
        -0.05619126930832863,
        -0.04823718219995499,
        -0.056049101054668427,
        0.02492712438106537,
        0.009663699194788933,
        0.003030357649549842,
        -0.042521294206380844,
        0.05431263521313667,
        0.02661016769707203,
        0.033821091055870056,
        0.02730250358581543,
        0.020454661920666695,
        0.058813855051994324,
        0.05428473278880119,
        0.05567657947540283,
        0.0008535347878932953,
        -0.05701269581913948,
        -0.057553987950086594,
        0.05748831108212471,
        -0.043465204536914825,
        0.043947555124759674,
        -0.05827563628554344,
        0.0377006009221077,
        -0.05806228145956993,
        -0.052759379148483276,
        0.01818380132317543,
        -0.0468788743019104,
        0.05577993765473366,
        -0.02389533817768097,
        0.040053464472293854,
        0.056152526289224625,
        -0.05904187634587288,
        -0.030474742874503136,
        0.0523698627948761,
        0.0564364455640316,
        0.058487847447395325,
        0.048398952931165695,
        0.030888644978404045,
        0.04875275120139122,
        0.04087332263588905,
        0.05497092381119728,
        -0.04696787893772125,
        0.025967247784137726,
        -0.03504282236099243,
        -0.04732157289981842,
        -0.043634798377752304,
        -0.05883701518177986,
        -0.04017114266753197,
        -0.05657397210597992,
        0.0035806784871965647,
        -0.05273984745144844,
        0.040150634944438934,
        -0.0062075951136648655,
        0.05739361792802811,
        0.03934238478541374,
        -0.058177776634693146,
        -0.04232261702418327,
        -0.05699427053332329,
        -0.05876626819372177,
        -0.05921578034758568,
        0.057062920182943344,
        0.0053397598676383495,
        -0.02373695746064186,
        -0.025563934817910194,
        0.045205164700746536,
        0.05880657583475113,
        -0.017291339114308357,
        -0.05353950709104538,
        -0.027712441980838776,
        0.05146295204758644,
        -0.029963023960590363,
        0.028947193175554276,
        0.004183290060609579,
        0.0174065250903368,
        0.017470821738243103,
        -0.05661069229245186,
        -0.009663215838372707,
        0.05914515256881714,
        -0.03659777715802193,
        0.05292891338467598,
        -0.04111864045262337,
        -0.029306426644325256,
        0.0014249038649722934,
        0.023531164973974228,
        0.01672952063381672,
        -0.053291305899620056,
        0.044993896037340164,
        -0.00699976459145546,
        -0.05590718612074852,
        -0.03310241550207138,
        0.04694123938679695,
        0.058637671172618866,
        0.011938823387026787,
        -0.04721573740243912,
        -0.04460397735238075,
        0.04309290647506714,
        -0.058436498045921326,
        0.04501526802778244,
        -0.054605886340141296,
        -0.024833429604768753,
        -0.05748932436108589,
        -0.05499495193362236,
        0.04645845293998718,
        -0.052398089319467545,
        0.0042863707058131695,
        -0.019267164170742035,
        0.04676305875182152,
        0.032928716391325,
        0.009777605533599854,
        0.04527267441153526,
        -0.05347788333892822,
        -0.05558178946375847,
        -0.05841262266039848,
        -0.015621975064277649,
        -0.05875187739729881,
        0.020990999415516853,
        0.00023497130314353853,
        0.042096782475709915,
        -0.03244125843048096,
        -0.05316070467233658,
        -0.03685135394334793,
        0.055782902985811234,
        -0.04870724305510521,
        -0.040033090859651566,
        0.043417636305093765,
        -0.05601411312818527,
        -0.056005146354436874,
        0.05896660313010216,
        0.0585380494594574,
        0.03872822970151901,
        0.024598663672804832,
        0.021552475169301033,
        0.03877045214176178,
        0.05354520305991173,
        0.05839180201292038,
        -0.057628415524959564,
        -0.04205786809325218,
        0.05880897864699364,
        0.02586512640118599,
        -0.05870632827281952,
        -0.05615204945206642,
        0.009644982405006886,
        0.011863259598612785,
        -0.055502720177173615,
        0.052895285189151764,
        0.005824083928018808,
        0.010469247587025166,
        -0.0006906424532644451,
        -0.018070122227072716,
        -0.0405934602022171,
        0.032269712537527084,
        0.008236980065703392,
        -0.035904884338378906,
        0.008716492913663387,
        -0.03034484200179577,
        -0.05459299311041832,
        -0.03457494080066681,
        0.045916542410850525,
        -0.05194559320807457,
        0.0330238863825798,
        -0.05729157477617264,
        -0.030394600704312325,
        -0.04190479964017868,
        -0.013746662996709347,
        -0.057889897376298904,
        -0.0066164517775177956,
        0.058245982974767685,
        -0.05506701022386551,
        0.03711816295981407,
        0.05512183904647827,
        0.05814557522535324,
        0.056462809443473816,
        0.04948734864592552,
        0.0037706159055233,
        0.006499933078885078,
        0.05669334530830383,
        -0.03839762881398201,
        -0.054525986313819885,
        -0.04231809452176094,
        0.020100289955735207,
        -0.03194431588053703,
        -0.05306781828403473,
        0.010330462828278542,
        -0.014929227530956268,
        0.04545414447784424,
        -0.02635759674012661,
        -0.04063209891319275,
        0.016215084120631218,
        -0.05787767097353935,
        0.05661207437515259,
        -0.020017266273498535,
        -0.012211255729198456,
        -0.05751408264040947,
        0.007671486586332321,
        0.036432478576898575,
        0.02454981580376625,
        -0.0007938414928503335,
        -0.025589637458324432,
        -0.026616761460900307,
        0.05469764769077301,
        0.031882911920547485,
        0.04998025298118591,
        0.052260495722293854,
        -0.018936336040496826,
        0.05845242738723755,
        0.038042131811380386,
        0.05909107252955437,
        -0.03555110841989517,
        -0.008936911821365356,
        0.05618458613753319,
        -0.057657498866319656,
        -0.05866028368473053,
        0.05918086692690849,
        0.011351956985890865,
        -0.008849488571286201,
        0.0566813163459301,
        0.0014389143325388432,
        -0.05856974050402641,
        0.050318241119384766,
        -0.05420761555433273,
        -0.05686105787754059,
        0.041590504348278046,
        -0.02206527628004551,
        0.05638658255338669,
        0.05637433007359505,
        0.04883215203881264,
        0.03828829526901245,
        0.05772345885634422,
        0.05693536996841431,
        0.058235008269548416,
        -0.05593312904238701,
        0.04868120327591896,
        0.03245214372873306,
        -0.022254927083849907,
        -0.041961200535297394,
        -0.04282756149768829,
        -0.002195419277995825,
        -0.05884740874171257,
        -0.045877255499362946,
        -0.05845063179731369,
        -0.0296341422945261,
        -0.055142223834991455,
        -0.04158357158303261,
        0.05910782516002655,
        -0.05823761969804764,
        -0.0005716703599318862,
        0.05850536376237869,
        0.055529333651065826,
        0.059060364961624146,
        0.05656960606575012,
        0.03922973573207855,
        -0.025180455297231674,
        -0.05368170514702797,
        0.054965294897556305,
        0.029011394828557968,
        0.05561439320445061,
        0.05797126889228821,
        -0.011179682798683643,
        -0.030134400352835655,
        -0.05722044035792351,
        0.018376749008893967,
        -0.04809298366308212,
        0.03887031599879265,
        0.03488883748650551,
        -0.05753106251358986,
        0.039195336401462555,
        -0.043321721255779266,
        -0.04918283596634865,
        -0.057352010160684586,
        -0.04754013195633888,
        0.044365543872117996,
        0.024663718417286873,
        -0.019578808918595314,
        -0.05569785460829735,
        0.04163704439997673,
        -0.05681171640753746,
        -0.05558837950229645,
        0.04765089973807335,
        -0.04459686577320099,
        0.05595755577087402,
        -0.008991873823106289,
        0.011627576313912868,
        0.053082309663295746,
        -0.052166443318128586,
        0.05402609333395958,
        -0.00566993048414588,
        -0.0009616349125280976,
        0.05306633189320564,
        0.013424605131149292,
        0.05335795879364014,
        -0.04682806879281998,
        0.05231120064854622,
        0.018367009237408638,
        0.009583381935954094,
        -0.008207679726183414,
        0.021832261234521866,
        -0.009786076843738556,
        -0.0589076392352581,
        0.057335421442985535,
        0.031115155667066574,
        -0.03987632319331169,
        0.05842515826225281,
        -0.0579085610806942,
        -0.055508021265268326,
        0.05229541286826134,
        -0.03271380811929703,
        0.013282518833875656,
        -0.046977028250694275,
        -0.049457039684057236,
        0.05723642557859421,
        0.05217953026294708,
        -0.0076523772440850735,
        0.05437947064638138,
        0.03741760179400444,
        -0.03596789017319679,
        -0.05740276724100113,
        0.016490831971168518,
        -0.05689779296517372,
        -0.0116907749325037,
        0.020778333768248558,
        -0.03741845116019249,
        -0.05030873790383339,
        0.05849398300051689,
        0.031226428225636482,
        0.028457414358854294,
        0.05842868983745575,
        -0.04815469682216644,
        0.04928172007203102,
        0.05317610502243042,
        0.04923456534743309,
        -0.052965279668569565,
        -0.02159593626856804,
        0.05923319235444069,
        -0.009465144015848637,
        0.012715479359030724,
        0.04693526029586792,
        -0.014013147912919521,
        0.058517247438430786,
        -0.05841023847460747,
        -0.012842864729464054,
        -0.03954105079174042,
        -0.05831657722592354,
        0.013780114240944386,
        0.036267075687646866,
        0.01854546181857586,
        -0.053532667458057404,
        0.04952016472816467,
        -0.03699949011206627,
        0.05463917925953865,
        0.03653153404593468,
        -0.056477148085832596,
        -0.05248869210481644,
        -0.01843586191534996,
        -0.0449056513607502,
        0.05875236168503761,
        -0.05632470175623894,
        0.03937109187245369,
        -0.059217147529125214,
        -0.04633362218737602,
        -0.05177897587418556,
        0.04978715255856514,
        0.05728939175605774,
        0.056130897253751755,
        0.05868563801050186,
        0.05033494904637337,
        0.04280975088477135,
        -0.03835317865014076,
        -0.03464047238230705,
        -0.05139285698533058,
        -0.04645124822854996,
        -0.056944526731967926,
        0.048894003033638,
        0.059015803039073944,
        -0.005987097043544054,
        0.0391581766307354,
        -0.056491669267416,
        -0.04352496936917305,
        0.008870393969118595,
        -0.057148467749357224,
        0.047242406755685806,
        -0.02530253678560257,
        0.03492976352572441,
        -0.05383851006627083,
        0.05785779282450676,
        0.04969993978738785,
        -0.04575870931148529,
        -0.019141705706715584,
        -0.05538848415017128,
        -0.027432158589363098,
        0.056160543113946915,
        0.05007293075323105,
        0.04644234851002693,
        0.0010812368709594011,
        0.0541321225464344,
        0.051044974476099014,
        -0.04968731105327606,
        0.038705360144376755,
        -0.023486778140068054,
        0.04387679323554039,
        -0.03905429318547249,
        0.05655829980969429,
        0.04914513975381851,
        0.05677100643515587,
        -0.025149626657366753,
        0.011071989312767982,
        -0.02955767884850502,
        -0.016008509323000908,
        0.04375789687037468,
        -0.054699793457984924,
        -0.053936414420604706,
        -0.055074624717235565,
        -0.0584302693605423,
        -0.006994700990617275,
        -0.03344188630580902,
        -0.004159777890890837,
        -0.04906793683767319,
        -0.057327039539813995,
        -0.055510781705379486,
        0.05904928222298622,
        -0.042429469525814056,
        0.00717365276068449,
        -0.05644313618540764,
        0.0071233841590583324,
        -0.04368361830711365,
        -0.05817122384905815,
        0.05386984720826149,
        -0.04169357568025589,
        -0.044628553092479706,
        0.05321672558784485,
        0.05913137272000313,
        0.058272585272789,
        0.057604577392339706,
        0.020795628428459167,
        0.036132410168647766,
        0.05598778650164604,
        -0.058351822197437286,
        0.050365593284368515,
        -0.021076412871479988,
        0.020136455073952675,
        0.03636367246508598,
        0.05869008228182793,
        0.03239649161696434,
        -0.0359855592250824,
        -0.051710691303014755,
        0.04143273085355759,
        -0.030874285846948624,
        0.01462531741708517,
        0.05836972966790199,
        0.02731105126440525,
        -0.05531202256679535,
        -0.05874167010188103,
        -0.057853177189826965,
        0.057621270418167114,
        0.05909445881843567,
        -0.05814628675580025,
        0.057095881551504135,
        -0.05774796009063721,
        -0.059097591787576675,
        0.03763094171881676,
        0.054479312151670456,
        0.03029252216219902,
        0.0004113245231565088,
        0.039572060108184814,
        -0.04394847899675369,
        0.05583474785089493,
        0.05541589483618736,
        -0.053687989711761475,
        0.05515766516327858,
        0.04987242817878723,
        -0.04955441877245903,
        -0.04738731309771538,
        0.027267158031463623,
        0.04075304791331291,
        0.04930933564901352,
        0.05909925699234009,
        0.0067497799172997475,
        -0.05610267072916031,
        -0.04370271787047386,
        0.03722783178091049,
        -0.04224550351500511,
        0.0004080060462001711,
        0.014827446080744267,
        0.0570155531167984,
        -0.039300862699747086,
        0.04852309077978134,
        0.05636027827858925,
        0.053062088787555695,
        0.0576864555478096,
        -0.05656256526708603,
        -0.04565562307834625,
        -0.03955234959721565,
        -0.03882971405982971,
        -0.013692544773221016,
        -0.004164115525782108,
        0.051171064376831055,
        0.034268807619810104,
        -0.03713720664381981,
        -0.05804871767759323,
        0.009640158154070377,
        0.05809536576271057
    ],
    [
        -0.048442188650369644,
        0.04002588242292404,
        -0.05575596168637276,
        0.01770150288939476,
        -0.007582894526422024,
        0.02851123921573162,
        0.052074480801820755,
        -0.0573524534702301,
        -0.009678917936980724,
        -0.057670630514621735,
        -0.00812629796564579,
        0.057850439101457596,
        -0.057592976838350296,
        -0.051377177238464355,
        -0.057234808802604675,
        -0.04349641501903534,
        0.0546378493309021,
        0.057930175215005875,
        0.0053222933784127235,
        0.05554917827248573,
        -0.01294026616960764,
        -0.023145735263824463,
        0.0579436793923378,
        0.05517132207751274,
        0.04728568717837334,
        0.049623582512140274,
        -0.051774315536022186,
        0.05662195384502411,
        -0.05634668096899986,
        -0.042490389198064804,
        0.013297772035002708,
        -0.04644827917218208,
        0.05569618195295334,
        -0.01169494166970253,
        0.05495145171880722,
        -0.05725504457950592,
        -0.05313907563686371,
        -0.016019728034734726,
        0.05647343397140503,
        0.0122782988473773,
        0.011148303747177124,
        0.034474801272153854,
        0.05399312451481819,
        -0.055853649973869324,
        0.04249199479818344,
        0.02771357260644436,
        0.0017986928578466177,
        0.046638328582048416,
        0.021459735929965973,
        0.017842497676610947,
        0.057267483323812485,
        0.05554734542965889,
        -0.041533999145030975,
        0.03121902607381344,
        0.027506792917847633,
        -0.04488317668437958,
        -0.055765558034181595,
        -0.03181679919362068,
        -0.057473327964544296,
        -0.057373449206352234,
        -0.0521170012652874,
        0.04032187536358833,
        -0.05663150176405907,
        -0.05575275793671608,
        0.03840347006917,
        -0.04119041934609413,
        -0.05336659029126167,
        -0.05731663852930069,
        0.05445723980665207,
        0.016218047589063644,
        -0.05728880688548088,
        -0.055633608251810074,
        0.05588853731751442,
        0.04084065183997154,
        0.030332472175359726,
        -0.05393475294113159,
        0.05154678598046303,
        0.05795738473534584,
        0.032639775425195694,
        -0.00678202835842967,
        -0.03695354983210564,
        -0.04986276477575302,
        -0.04608851671218872,
        0.03017064929008484,
        -0.049757540225982666,
        0.04709164798259735,
        -0.0555589459836483,
        -0.012366586364805698,
        -0.0325549952685833,
        0.049075644463300705,
        0.022431490942835808,
        -0.022965388372540474,
        -0.028274061158299446,
        -0.04164106398820877,
        -0.051034435629844666,
        0.032165225595235825,
        -0.05274956673383713,
        -0.05729913339018822,
        0.041893355548381805,
        -0.014934719540178776,
        -0.057464513927698135,
        0.057175394147634506,
        0.04495011642575264,
        0.056710727512836456,
        0.0006248080171644688,
        -0.05790628120303154,
        0.041245054453611374,
        -0.02412533201277256,
        -0.05789848044514656,
        0.00008027767762541771,
        -0.05341276898980141,
        -0.05577851086854935,
        -0.057540204375982285,
        -0.029019461944699287,
        0.04410465806722641,
        0.039270393550395966,
        -0.03196212649345398,
        -0.0452381931245327,
        0.056447532027959824,
        -0.05757274106144905,
        -0.04211907088756561,
        0.027378639206290245,
        -0.05717867240309715,
        0.02423037588596344,
        -0.05025600269436836,
        0.04413754120469093,
        -0.021728515625,
        0.05738252028822899,
        0.057824887335300446,
        -0.019688203930854797,
        0.02400270476937294,
        -0.05516301840543747,
        -0.023643871769309044,
        0.04956194385886192,
        -0.048641517758369446,
        -0.05743015930056572,
        0.054952848702669144,
        0.016332492232322693,
        -0.05198569968342781,
        0.052895110100507736,
        0.00075931177707389,
        0.05692807957530022,
        0.04738204553723335,
        0.049694549292325974,
        -0.05708324536681175,
        0.043906018137931824,
        -0.04080404341220856,
        0.01407657004892826,
        -0.05793365463614464,
        0.024138126522302628,
        -0.024810075759887695,
        -0.057898055762052536,
        -0.030194945633411407,
        0.015948249027132988,
        0.027267668396234512,
        -0.05668744817376137,
        0.0445990115404129,
        -0.0012145857326686382,
        0.05644465237855911,
        -0.05194813758134842,
        0.03385094925761223,
        -0.01855236478149891,
        0.05653936043381691,
        0.05575317144393921,
        -0.021424461156129837,
        -0.05679018795490265,
        0.05338253453373909,
        -0.009071954526007175,
        0.011080658994615078,
        0.032018452882766724,
        -0.05782025307416916,
        0.05546317249536514,
        -0.05653791129589081,
        0.013750627636909485,
        -0.054627496749162674,
        -0.048228293657302856,
        0.006696984171867371,
        0.02616901881992817,
        0.0561617948114872,
        -0.05576454475522041,
        -0.05221037194132805,
        -0.017120027914643288,
        0.052759625017642975,
        -0.023462697863578796,
        -0.05431240051984787,
        0.03185904771089554,
        0.009154661558568478,
        0.0547591857612133,
        -0.031891562044620514,
        -0.0577281191945076,
        -0.05634449049830437,
        0.03847397863864899,
        -0.054015304893255234,
        -0.03371068835258484,
        -0.04676093906164169,
        0.05407269299030304,
        0.05319860950112343,
        -0.057740725576877594,
        0.03570602089166641,
        0.02262454479932785,
        0.053237441927194595,
        -0.056179631501436234,
        0.02468634769320488,
        -0.04403356835246086,
        0.024802574887871742,
        0.0036969562061131,
        0.01985008642077446,
        0.0568174347281456,
        -0.05719117075204849,
        0.054426953196525574,
        -0.056873202323913574,
        0.010326643474400043,
        0.056668929755687714,
        0.05691464617848396,
        0.029005348682403564,
        -0.05305646359920502,
        0.05368975177407265,
        0.024852240458130836,
        0.057161539793014526,
        -0.0393095463514328,
        -0.05714353546500206,
        0.03582555055618286,
        -0.00635770196095109,
        -0.0509256049990654,
        0.05781978368759155,
        -0.05783004313707352,
        0.023242920637130737,
        0.02265305072069168,
        0.0532369427382946,
        -0.057265754789114,
        -0.015686072409152985,
        0.020798347890377045,
        -0.02607354149222374,
        0.04876530542969704,
        -0.04427754506468773,
        0.05707090348005295,
        0.05709683150053024,
        -0.046137843281030655,
        0.04533012956380844,
        0.0579049177467823,
        0.052372001111507416,
        -0.049024708569049835,
        0.01029061246663332,
        0.036570530384778976,
        0.010600298643112183,
        -0.045668259263038635,
        0.05737447366118431,
        -0.05552353337407112,
        -0.05704744532704353,
        -0.03999002277851105,
        -0.017318280413746834,
        -0.05403405427932739,
        0.053657591342926025,
        -0.05630618333816528,
        0.05704754590988159,
        0.049908433109521866,
        -0.05661126226186752,
        -0.006818288937211037,
        0.057069066911935806,
        -0.018302151933312416,
        -0.015957418829202652,
        0.05567483976483345,
        0.0022038493771106005,
        -0.03454345092177391,
        -0.05466620251536369,
        0.05787793919444084,
        0.05417802557349205,
        0.023473821580410004,
        -0.021514974534511566,
        -0.035299669951200485,
        0.0029318290762603283,
        -0.03470258414745331,
        0.030943801626563072,
        0.01575286127626896,
        -0.03519931808114052,
        -0.011335624381899834,
        -0.05074668303132057,
        0.051995899528265,
        0.05470363795757294,
        -0.04941631481051445,
        -0.057916346937417984,
        -0.010876939631998539,
        0.03262433037161827,
        -0.055082302540540695,
        -0.04939841851592064,
        0.0573178268969059,
        0.05579408258199692,
        0.04095035046339035,
        -0.051213666796684265,
        0.05590374395251274,
        -0.010871702805161476,
        0.008473129943013191,
        -0.054801832884550095,
        -0.053459372371435165,
        0.056832410395145416,
        -0.0577891506254673,
        0.056889571249485016,
        -0.00022033447748981416,
        -0.022464826703071594,
        0.05715200677514076,
        -0.028664810582995415,
        0.03317980840802193,
        -0.037195805460214615,
        0.052896711975336075,
        0.00024198266328312457,
        -0.022681141272187233,
        0.05369143187999725,
        0.03874073550105095,
        -0.040125008672475815,
        -0.05298725515604019,
        -0.01921911910176277,
        0.05591185390949249,
        0.011770644225180149,
        0.0567752942442894,
        0.007310416083782911,
        -0.052883170545101166,
        -0.01860593445599079,
        -0.008606698364019394,
        0.05600869283080101,
        0.01034215372055769,
        0.050954315811395645,
        0.05691593885421753,
        0.057056885212659836,
        0.04139849916100502,
        0.053797584027051926,
        0.05716784670948982,
        0.05718468129634857,
        -0.055893540382385254,
        -0.02795858308672905,
        -0.011577497236430645,
        0.008399289101362228,
        0.003080657683312893,
        0.03890501707792282,
        -0.022090498358011246,
        0.05722019821405411,
        0.025072263553738594,
        -0.01618688367307186,
        0.035472188144922256,
        -0.042755383998155594,
        0.047081414610147476,
        -0.05208205431699753,
        0.03580611199140549,
        0.02465623803436756,
        0.05220542475581169,
        0.05726633965969086,
        -0.015775613486766815,
        0.03387352079153061,
        0.05753418430685997,
        0.057624734938144684,
        0.055904991924762726,
        0.053956761956214905,
        -0.04323219135403633,
        0.005332280416041613,
        -0.0329132005572319,
        -0.057587407529354095,
        -0.011533020995557308,
        0.05569641292095184,
        -0.055420298129320145,
        -0.0073733143508434296,
        -0.025823695585131645,
        0.04566552862524986,
        0.048343636095523834,
        -0.057829976081848145,
        -0.051566459238529205,
        0.02052222192287445,
        -0.05726112425327301,
        0.05542474240064621,
        -0.045458294451236725,
        0.015520475804805756,
        -0.05621287599205971,
        -0.05366009101271629,
        -0.054366711527109146,
        0.05422046408057213,
        0.001572926645167172,
        0.05628900229930878,
        0.05792003497481346,
        0.057753417640924454,
        0.05765289440751076,
        0.04978463426232338,
        -0.04501194879412651,
        -0.05552671104669571,
        0.011067291721701622,
        -0.04830353334546089,
        0.015496877953410149,
        0.04125165194272995,
        -0.03543545678257942,
        0.048026129603385925,
        -0.05450720712542534,
        -0.019965771585702896,
        0.05562214180827141,
        -0.05627775564789772,
        -0.045085299760103226,
        -0.026912985369563103,
        -0.04483821615576744,
        0.02462758496403694,
        0.05735694244503975,
        -0.025138432160019875,
        0.03923165425658226,
        -0.055164702236652374,
        -0.04227069020271301,
        -0.03645007312297821,
        0.057092055678367615,
        0.014459551312029362,
        0.04623357951641083,
        0.03896411508321762,
        0.0374998115003109,
        0.05761478841304779,
        0.010481573641300201,
        0.057801928371191025,
        0.026187006384134293,
        0.05130133777856827,
        -0.03555189073085785,
        0.057660676538944244,
        0.037874363362789154,
        0.05779948830604553,
        -0.057549990713596344,
        -0.04715947434306145,
        -0.055356465280056,
        0.02312864363193512,
        0.05785258114337921,
        -0.04860441014170647,
        -0.05739540979266167,
        -0.056813593953847885,
        -0.05793432891368866,
        0.009086866863071918,
        -0.04407951980829239,
        -0.01901649497449398,
        -0.020676737651228905,
        -0.05481717735528946,
        -0.04997784271836281,
        0.04713554307818413,
        -0.03976006060838699,
        0.05307412147521973,
        -0.04912550374865532,
        0.009222407825291157,
        -0.05731190741062164,
        -0.057885702699422836,
        0.05522910878062248,
        -0.05709555745124817,
        -0.021889833733439445,
        -0.05017217621207237,
        0.03319910913705826,
        0.0561632439494133,
        0.057885993272066116,
        0.013225134462118149,
        0.03465909883379936,
        0.056682053953409195,
        -0.057292044162750244,
        0.042052462697029114,
        0.002621413441374898,
        0.013357032090425491,
        -0.012329582124948502,
        0.053881075233221054,
        0.037205442786216736,
        0.011641426011919975,
        -0.049766939133405685,
        0.013013100251555443,
        -0.053198885172605515,
        0.027880681678652763,
        0.01623930037021637,
        0.045901283621788025,
        0.0038825871888548136,
        -0.034179411828517914,
        -0.025715580210089684,
        0.05658441781997681,
        0.057930801063776016,
        -0.05223693698644638,
        0.057946544140577316,
        -0.05409608781337738,
        -0.04891205206513405,
        0.043741755187511444,
        -0.0007677897228859365,
        0.055014096200466156,
        -0.02449685148894787,
        0.03941750153899193,
        -0.018927184864878654,
        0.056968312710523605,
        0.0568179115653038,
        -0.012144297361373901,
        -0.04533035680651665,
        0.053979258984327316,
        -0.05730888992547989,
        -0.03541053086519241,
        0.021455202251672745,
        -0.017106758430600166,
        0.05582599714398384,
        0.05195377767086029,
        0.045008525252342224,
        -0.05715338513255119,
        0.020824577659368515,
        0.05633898451924324,
        -0.03257754072546959,
        0.05780091881752014,
        -0.0480303056538105,
        -0.030869681388139725,
        0.05618145316839218,
        0.04198180139064789,
        -0.05448521301150322,
        0.04515176638960838,
        0.01220804825425148,
        0.045112378895282745,
        0.04750778153538704,
        0.053189389407634735,
        -0.053459566086530685,
        0.053014494478702545,
        -0.05289646238088608,
        0.005018599331378937,
        0.0005759920459240675,
        0.057895172387361526,
        -0.05769884213805199,
        0.030362218618392944,
        0.05630090832710266
    ],
    [
        -0.027500784024596214,
        -0.03258341923356056,
        0.054869018495082855,
        -0.009085236117243767,
        0.030428966507315636,
        0.04713514447212219,
        0.05501171201467514,
        -0.05135885998606682,
        0.04013286530971527,
        0.042776696383953094,
        0.008793111890554428,
        0.0038736516144126654,
        -0.054534684866666794,
        0.05406072735786438,
        -0.05288933217525482,
        0.05510483682155609,
        -0.04800671339035034,
        0.055117346346378326,
        -0.045423369854688644,
        0.05482270568609238,
        0.051585692912340164,
        -0.0549360029399395,
        0.030341075733304024,
        -0.04750232398509979,
        -0.05480034649372101,
        0.034536492079496384,
        -0.03519361838698387,
        0.052418820559978485,
        -0.05511080101132393,
        -0.0031905362848192453,
        -0.018519258126616478,
        0.05421857163310051,
        0.05436288192868233,
        -0.05267493054270744,
        0.05157503858208656,
        -0.05483517423272133,
        -0.054710522294044495,
        -0.05457279831171036,
        -0.04106761887669563,
        -0.0013982596574351192,
        -0.041439495980739594,
        -0.03099014237523079,
        0.05286756530404091,
        -0.03355478495359421,
        0.051724404096603394,
        -0.029063768684864044,
        0.05443140119314194,
        0.046060942113399506,
        0.05425110086798668,
        -0.009720946662127972,
        0.03565432131290436,
        0.05491118133068085,
        0.006935877725481987,
        -0.04792137071490288,
        0.04967621713876724,
        -0.01879630982875824,
        -0.05510862171649933,
        -0.04753071442246437,
        -0.052847571671009064,
        -0.03058631159365177,
        -0.054776109755039215,
        0.03859512507915497,
        -0.05415787175297737,
        -0.051978372037410736,
        -0.0024738055653870106,
        -0.054978519678115845,
        -0.05508765950798988,
        0.05415073782205582,
        -0.05409831553697586,
        -0.05448342487215996,
        0.037228792905807495,
        -0.053129445761442184,
        0.055119387805461884,
        0.05146768316626549,
        0.03136216849088669,
        -0.02477067895233631,
        0.05407635122537613,
        0.05512150749564171,
        0.050308339297771454,
        0.05259234830737114,
        -0.01574127748608589,
        -0.054998867213726044,
        -0.04746970534324646,
        0.05478528141975403,
        0.054366759955883026,
        0.05312158912420273,
        -0.0510077029466629,
        -0.01796850562095642,
        -0.028167789801955223,
        0.05265151336789131,
        -0.050880320370197296,
        -0.054782211780548096,
        -0.03926633670926094,
        -0.055051855742931366,
        -0.03620028868317604,
        -0.03332892805337906,
        -0.04379098489880562,
        -0.05424956977367401,
        0.013694148510694504,
        0.05194644629955292,
        -0.0551036074757576,
        0.03858214616775513,
        0.03317490220069885,
        0.051886387169361115,
        0.05412037670612335,
        -0.014753867872059345,
        -0.052395276725292206,
        0.03140615299344063,
        -0.04537351056933403,
        -0.04394475743174553,
        0.05420021340250969,
        -0.010507800616323948,
        0.022726286202669144,
        0.01792309433221817,
        -0.0035665053874254227,
        -0.05427940562367439,
        -0.017141466960310936,
        -0.05141954496502876,
        0.020080450922250748,
        -0.029183417558670044,
        0.0004920781357213855,
        -0.03879325091838837,
        -0.015135560184717178,
        -0.0497518815100193,
        -0.0512395016849041,
        0.0336824469268322,
        0.05076174810528755,
        -0.026128670200705528,
        0.05455297604203224,
        -0.002680977573618293,
        0.005686953663825989,
        -0.0375492163002491,
        -0.05492614582180977,
        0.04867776483297348,
        -0.03123541548848152,
        -0.05420166999101639,
        0.05251706764101982,
        -0.0500066913664341,
        -0.021111879497766495,
        0.04490339010953903,
        0.05341622978448868,
        0.04964849725365639,
        0.04745626449584961,
        0.05505441129207611,
        0.024619629606604576,
        0.049391329288482666,
        0.021037384867668152,
        -0.03873184323310852,
        -0.01653037779033184,
        -0.04822269082069397,
        0.05275687947869301,
        -0.0548752062022686,
        -0.0548073910176754,
        0.013886694796383381,
        0.055108677595853806,
        -0.01156227383762598,
        -0.015487703494727612,
        0.020185094326734543,
        0.05090446025133133,
        -0.05455498769879341,
        0.04112101346254349,
        -0.010416352190077305,
        0.054904717952013016,
        0.05500639230012894,
        0.0349249467253685,
        0.050839781761169434,
        -0.021542565897107124,
        0.05247193202376366,
        -0.021946532651782036,
        0.021848101168870926,
        -0.05123923718929291,
        0.028674039989709854,
        -0.05478322133421898,
        0.055065348744392395,
        -0.054991576820611954,
        0.020418642088770866,
        -0.05495388060808182,
        -0.045080482959747314,
        0.052905939519405365,
        0.021522274240851402,
        -0.03853543475270271,
        0.022471126168966293,
        0.017259884625673294,
        -0.033965274691581726,
        0.02797645330429077,
        0.05117492750287056,
        0.05454718694090843,
        0.05481301248073578,
        0.0252466332167387,
        -0.050816312432289124,
        -0.05479581281542778,
        -0.03722282499074936,
        -0.04438433423638344,
        -0.05511806905269623,
        -0.0536193884909153,
        0.036778200417757034,
        0.053095828741788864,
        -0.0007794229313731194,
        -0.005801098421216011,
        -0.013747179880738258,
        0.05482190102338791,
        -0.050498127937316895,
        -0.011242098174989223,
        -0.00007786281639710069,
        0.054536961019039154,
        0.05420226603746414,
        0.051489751785993576,
        -0.005927655380219221,
        -0.05356397479772568,
        0.04790199175477028,
        -0.05375036969780922,
        -0.009591036476194859,
        0.05493218079209328,
        0.051312413066625595,
        -0.053801096975803375,
        -0.04786491021513939,
        0.055121514946222305,
        -0.0075330534018576145,
        0.05511954054236412,
        0.002371068811044097,
        -0.005018613766878843,
        0.05049204081296921,
        0.04580017179250717,
        -0.04516580328345299,
        0.05486147478222847,
        -0.0546797513961792,
        0.020704396069049835,
        -0.05322933569550514,
        0.054524391889572144,
        -0.05499597266316414,
        0.03356480970978737,
        -0.054732561111450195,
        -0.05293342098593712,
        0.03917825222015381,
        -0.0036887438036501408,
        0.05148833990097046,
        0.05400459095835686,
        0.029942534863948822,
        -0.03867974132299423,
        0.055121179670095444,
        -0.02703351527452469,
        -0.04962705820798874,
        -0.051110997796058655,
        0.03727394714951515,
        0.04470427334308624,
        -0.018589477986097336,
        0.04100027680397034,
        0.053395334631204605,
        -0.01538686640560627,
        -0.053951047360897064,
        0.021829649806022644,
        -0.05028107762336731,
        -0.02490994706749916,
        -0.05465898662805557,
        -0.05228819325566292,
        0.054501403123140335,
        -0.006736389361321926,
        -0.04412918537855148,
        0.05259100720286369,
        0.007738372310996056,
        -0.05317201465368271,
        -0.010533401742577553,
        -0.033179182559251785,
        0.05411005765199661,
        0.05507628992199898,
        0.05449574813246727,
        0.05499228462576866,
        0.0525972954928875,
        -0.041723936796188354,
        0.029478875920176506,
        0.02125091850757599,
        -0.05158430337905884,
        0.053639717400074005,
        -0.05163133889436722,
        -0.04174970090389252,
        0.05119016766548157,
        -0.052592236548662186,
        0.027115602046251297,
        0.05143449082970619,
        -0.05164842680096626,
        -0.05404290556907654,
        0.04509703814983368,
        -0.05498114228248596,
        0.0020353738218545914,
        0.054741278290748596,
        0.05480057746171951,
        -0.028028199449181557,
        -0.023225674405694008,
        0.05434422194957733,
        0.0526849664747715,
        -0.048714350908994675,
        0.04484865814447403,
        -0.0384884811937809,
        0.02639690414071083,
        0.05303848162293434,
        -0.05482815206050873,
        0.05500832200050354,
        -0.02666081301867962,
        0.04765474423766136,
        0.0548723004758358,
        0.036135997623205185,
        0.052092861384153366,
        -0.011019604280591011,
        0.0533861443400383,
        -0.054066020995378494,
        0.021961750462651253,
        -0.046322520822286606,
        0.05448149889707565,
        0.053444355726242065,
        -0.05505521968007088,
        -0.043904755264520645,
        -0.020646600052714348,
        0.054061226546764374,
        0.05497456341981888,
        0.053742915391922,
        -0.055059462785720825,
        -0.025503011420369148,
        0.05136941745877266,
        0.049348730593919754,
        0.02607746236026287,
        -0.04246840253472328,
        -0.02335418201982975,
        0.0529298260807991,
        0.05156929790973663,
        0.04980270564556122,
        0.039601411670446396,
        -0.009525620378553867,
        -0.05370486527681351,
        -0.046262022107839584,
        -0.04459818825125694,
        -0.051512960344552994,
        -0.05301152914762497,
        -0.033547721803188324,
        -0.04511069133877754,
        0.051899880170822144,
        -0.028093751519918442,
        -0.005172336474061012,
        0.04219596087932587,
        0.0478188656270504,
        -0.03705928847193718,
        -0.05204889550805092,
        -0.022601811215281487,
        -0.04080681502819061,
        -0.033023107796907425,
        0.055073633790016174,
        0.01590181142091751,
        0.039602771401405334,
        0.055047955363988876,
        0.04752647131681442,
        0.04594897851347923,
        0.05496484041213989,
        0.054912157356739044,
        0.04303453490138054,
        -0.053096573799848557,
        0.05475986376404762,
        -0.03197111561894417,
        -0.03397628292441368,
        -0.05423792451620102,
        -0.04024570435285568,
        -0.04399408772587776,
        0.05177696794271469,
        -0.053414326161146164,
        -0.05383934825658798,
        -0.04243126139044762,
        0.0026661865413188934,
        -0.05506123974919319,
        0.054654620587825775,
        0.02883172407746315,
        0.03191234916448593,
        -0.05455037206411362,
        0.005256112664937973,
        -0.05434736609458923,
        0.05506287142634392,
        -0.055023130029439926,
        0.054736193269491196,
        0.031974177807569504,
        -0.045186568051576614,
        -0.023186825215816498,
        0.05409160256385803,
        -0.0030422979034483433,
        -0.002279339823871851,
        0.033342160284519196,
        -0.045966748148202896,
        0.053688619285821915,
        0.05423969030380249,
        -0.051446471363306046,
        -0.017682231962680817,
        -0.05496332049369812,
        -0.04980604723095894,
        -0.05367344617843628,
        -0.05307921767234802,
        -0.053345438092947006,
        -0.03782358765602112,
        -0.05099289119243622,
        0.054185785353183746,
        0.05374694615602493,
        0.05478323623538017,
        -0.049834996461868286,
        -0.054841019213199615,
        -0.054691214114427567,
        0.009147071279585361,
        0.03613491728901863,
        -0.03500673919916153,
        0.0402597039937973,
        -0.048627644777297974,
        0.0011921757832169533,
        0.041026219725608826,
        -0.047858111560344696,
        0.05441256985068321,
        0.038254350423812866,
        0.02612190507352352,
        -0.054136596620082855,
        -0.04407883808016777,
        -0.008290168829262257,
        0.051908113062381744,
        -0.05102778226137161,
        -0.037386033684015274,
        0.04955350235104561,
        0.04718233272433281,
        0.046338003128767014,
        0.045225948095321655,
        -0.05112241953611374,
        -0.026883309707045555,
        -0.05507151782512665,
        -0.022150173783302307,
        -0.033520862460136414,
        0.026262259110808372,
        -0.011615224182605743,
        -0.054661259055137634,
        -0.05427071079611778,
        0.025520410388708115,
        -0.04789835214614868,
        -0.04721515253186226,
        -0.05343654006719589,
        -0.05509894713759422,
        -0.05346116051077843,
        -0.053727757185697556,
        0.0011674059787765145,
        0.005529491696506739,
        -0.05242180824279785,
        -0.04505913332104683,
        0.054876599460840225,
        0.052726808935403824,
        0.052960898727178574,
        -0.05341664329171181,
        -0.03685109317302704,
        0.054259881377220154,
        -0.055115628987550735,
        0.040216751396656036,
        0.050482068210840225,
        -0.0349261574447155,
        0.05241409316658974,
        0.05306122079491615,
        -0.04386933147907257,
        0.042001187801361084,
        -0.054758042097091675,
        -0.036983877420425415,
        -0.0495043583214283,
        -0.049490783363580704,
        -0.015506429597735405,
        0.05378802865743637,
        -0.054668791592121124,
        -0.05339464917778969,
        0.053465601056814194,
        0.05375188589096069,
        0.05497175455093384,
        -0.05407163128256798,
        0.0549795962870121,
        0.04873998463153839,
        -0.05434780940413475,
        -0.0006586982635781169,
        0.05340325087308884,
        0.053609151393175125,
        0.05511190742254257,
        0.048760317265987396,
        0.03796334192156792,
        0.05171830952167511,
        -0.03700103983283043,
        -0.05485543981194496,
        -0.049569081515073776,
        -0.05151563882827759,
        -0.017126012593507767,
        -0.023304035887122154,
        0.02129954658448696,
        0.03373588249087334,
        0.054558709263801575,
        0.008070842362940311,
        0.05269400402903557,
        -0.052117422223091125,
        -0.039186980575323105,
        -0.03689003363251686,
        -0.054515473544597626,
        -0.006180561147630215,
        0.01385912299156189,
        0.013048621825873852,
        0.03573346510529518,
        0.04213951155543327,
        -0.023495139554142952,
        0.05159113556146622,
        -0.023553958162665367,
        0.01034612488001585,
        0.021849611774086952,
        -0.0463220477104187,
        0.04733170568943024,
        0.05505623668432236,
        0.051605045795440674,
        0.007898141629993916,
        0.03452124074101448,
        0.04764347895979881,
        -0.055103402584791183,
        0.05474484711885452,
        0.054637569934129715
    ],
    [
        0.05461260676383972,
        -0.041297487914562225,
        0.05522296205163002,
        -0.022071953862905502,
        -0.04019664600491524,
        -0.05776555836200714,
        0.0551387295126915,
        -0.05738162621855736,
        0.05718139931559563,
        0.05624030530452728,
        0.030210604891180992,
        0.039984799921512604,
        -0.05628775805234909,
        -0.014248163439333439,
        -0.057543862611055374,
        0.00617647310718894,
        -0.015114580281078815,
        0.05529317259788513,
        0.048584237694740295,
        0.01052221842110157,
        0.04268082231283188,
        0.05595361813902855,
        0.029480459168553352,
        -0.05179467052221298,
        -0.052294790744781494,
        0.039218682795763016,
        0.05277068540453911,
        0.015872104093432426,
        -0.03235667198896408,
        0.042068250477313995,
        0.05154995247721672,
        0.052111029624938965,
        0.014590120874345303,
        -0.05715927481651306,
        0.05702255666255951,
        -0.05163075029850006,
        -0.05780642107129097,
        -0.05606553703546524,
        0.056555066257715225,
        0.05323389172554016,
        0.0355587899684906,
        0.02612144500017166,
        0.053304675966501236,
        -0.05131896212697029,
        -0.007082908879965544,
        0.0430765338242054,
        0.04431740194559097,
        0.055205393582582474,
        0.057795874774456024,
        -0.057476919144392014,
        0.05781968683004379,
        0.024617737159132957,
        -0.035119011998176575,
        -0.052262771874666214,
        0.025844423100352287,
        -0.002679219236597419,
        -0.0487150177359581,
        0.015003588981926441,
        -0.05458030849695206,
        0.001671238336712122,
        -0.05156316980719566,
        0.0523480549454689,
        -0.057342685759067535,
        0.05754004046320915,
        0.04606793820858002,
        -0.03916088864207268,
        -0.05771131440997124,
        0.04343993216753006,
        0.01523197628557682,
        -0.03348639979958534,
        0.017139358446002007,
        -0.05718611925840378,
        0.055826615542173386,
        0.056763194501399994,
        -0.042821481823921204,
        -0.05309586971998215,
        0.03807653859257698,
        0.057920876890420914,
        0.03296513855457306,
        0.05378296598792076,
        -0.052742231637239456,
        -0.05179063230752945,
        -0.05520099774003029,
        0.057001180946826935,
        0.03620697185397148,
        0.050544749945402145,
        -0.02271689474582672,
        -0.05661877244710922,
        -0.046294115483760834,
        0.057343386113643646,
        -0.057820793241262436,
        -0.043431706726551056,
        -0.05783255025744438,
        -0.056943636387586594,
        -0.04263819381594658,
        -0.02842537872493267,
        -0.037907011806964874,
        -0.057112548500299454,
        0.0023498856462538242,
        0.0307304710149765,
        -0.05785033851861954,
        0.05356721580028534,
        0.05567774921655655,
        0.054337382316589355,
        0.026333410292863846,
        -0.056720245629549026,
        -0.047551628202199936,
        0.01921762526035309,
        -0.03971518576145172,
        0.05680491030216217,
        0.019687257707118988,
        -0.001486766035668552,
        -0.02983161248266697,
        0.010935318656265736,
        0.0020140374545007944,
        -0.05420137941837311,
        0.0019295060774311423,
        -0.05746900290250778,
        -0.04169401153922081,
        0.05135894566774368,
        -0.03064810112118721,
        0.0017871471354737878,
        0.04198134317994118,
        -0.0020519793033599854,
        -0.055024825036525726,
        0.05655426159501076,
        0.0512375645339489,
        0.05038808286190033,
        0.044533662497997284,
        0.04381609335541725,
        0.021857893094420433,
        -0.05010567605495453,
        0.0033925543539226055,
        0.03632301464676857,
        -0.05265440419316292,
        -0.05782081559300423,
        0.0579063706099987,
        -0.043536122888326645,
        -0.003885104553773999,
        0.053271763026714325,
        -0.03122510015964508,
        -0.038122765719890594,
        0.013012219220399857,
        0.029657242819666862,
        0.05553792417049408,
        -0.02505911886692047,
        0.019295264035463333,
        -0.05504649132490158,
        0.002248423872515559,
        -0.0035449774004518986,
        -0.017588458955287933,
        -0.05717209354043007,
        0.050858188420534134,
        0.04536181315779686,
        -0.0019236104562878609,
        0.039749544113874435,
        0.0019394161645323038,
        0.02378879114985466,
        0.05341938138008118,
        -0.0023945749271661043,
        0.0023458655923604965,
        0.0025644078850746155,
        0.05632873997092247,
        0.056962404400110245,
        0.02173813246190548,
        0.04270612448453903,
        0.04106650501489639,
        0.04872544854879379,
        -0.019465304911136627,
        0.05038243904709816,
        -0.017003847286105156,
        0.0029238180723041296,
        -0.049270641058683395,
        0.05171167850494385,
        -0.05054863914847374,
        -0.011805661953985691,
        -0.02311522886157036,
        0.031460631638765335,
        0.05546364560723305,
        -0.05544274300336838,
        -0.040715187788009644,
        0.046371959149837494,
        0.04922331124544144,
        -0.048384930938482285,
        0.05551479011774063,
        -0.02938569150865078,
        0.012080038897693157,
        0.057420991361141205,
        -0.020586732774972916,
        -0.014818147756159306,
        -0.04704675078392029,
        0.020147092640399933,
        -0.016268683597445488,
        0.01864340901374817,
        -0.05762887001037598,
        0.05692598968744278,
        -0.030063405632972717,
        -0.005938887596130371,
        -0.04035918414592743,
        0.055062856525182724,
        0.05716612935066223,
        0.05733909457921982,
        -0.05707672983407974,
        -0.05524292588233948,
        -0.006716768257319927,
        0.05465855076909065,
        0.05731635168194771,
        0.04566482454538345,
        -0.05444180220365524,
        0.04886331781744957,
        -0.042793501168489456,
        0.05688564106822014,
        0.04920544475317001,
        0.05754401534795761,
        -0.05250134319067001,
        -0.025705095380544662,
        0.057456016540527344,
        0.020450087264180183,
        0.05706670135259628,
        0.05369224026799202,
        0.04704958572983742,
        -0.03160971775650978,
        -0.04374459758400917,
        0.026675764471292496,
        0.05632452294230461,
        -0.04982389882206917,
        0.04440825805068016,
        -0.007949278689920902,
        -0.05438061058521271,
        -0.05734024569392204,
        -0.055904898792505264,
        -0.05752001330256462,
        -0.013005213811993599,
        0.050327181816101074,
        0.02218865416944027,
        0.057436566799879074,
        0.052849940955638885,
        0.04410700500011444,
        -0.0502689927816391,
        0.05791578069329262,
        -0.004347308073192835,
        0.05373338237404823,
        -0.05540941655635834,
        -0.04627712070941925,
        0.05008510872721672,
        -0.05495306849479675,
        0.050373565405607224,
        0.05745285376906395,
        -0.05167904496192932,
        -0.050784483551979065,
        -0.0542147196829319,
        -0.0575718879699707,
        -0.0022069269325584173,
        -0.05499275028705597,
        0.02576959878206253,
        0.049904998391866684,
        0.05609789118170738,
        -0.018308738246560097,
        0.05190635845065117,
        0.03452656418085098,
        -0.02437666803598404,
        -0.010468925349414349,
        0.01923840120434761,
        0.05682233348488808,
        0.05440465360879898,
        0.056390080600976944,
        0.05451851338148117,
        0.05282851308584213,
        -0.041517600417137146,
        -0.025202114135026932,
        0.02227400429546833,
        -0.05723957344889641,
        0.051793813705444336,
        -0.05722522363066673,
        -0.0037432627286762,
        0.01200708094984293,
        -0.050278473645448685,
        0.0037966512609273195,
        -0.038828905671834946,
        -0.03481859341263771,
        -0.05673893541097641,
        0.04913239926099777,
        -0.05718867853283882,
        -0.0379440113902092,
        0.05358031019568443,
        0.02112562209367752,
        0.031045082956552505,
        0.0555824413895607,
        0.0022966524120420218,
        0.05033734813332558,
        -0.02749684453010559,
        0.03819701820611954,
        -0.0441606342792511,
        -0.010256546549499035,
        0.02153605967760086,
        -0.05217697098851204,
        0.0571686327457428,
        0.048035215586423874,
        -0.056897711008787155,
        0.05466977879405022,
        -0.03980785608291626,
        0.03597255051136017,
        -0.03696305677294731,
        0.055901579558849335,
        -0.002448886865749955,
        -0.012178828939795494,
        -0.05674111098051071,
        0.05040421336889267,
        0.0337611623108387,
        0.006940866820514202,
        0.05559708923101425,
        -0.01770680956542492,
        0.007305204402655363,
        0.048900555819272995,
        -0.014790668152272701,
        -0.056609127670526505,
        0.0010737328557297587,
        0.004450769163668156,
        0.05576048791408539,
        0.007358005736023188,
        0.05064721405506134,
        0.03394712880253792,
        0.035522326827049255,
        0.006653576157987118,
        0.05783218890428543,
        -0.016928965225815773,
        0.05226393789052963,
        -0.05627048760652542,
        0.026013609021902084,
        -0.05031841620802879,
        0.05240404233336449,
        0.00894058309495449,
        0.03473394364118576,
        0.04530451446771622,
        0.05245402827858925,
        0.05490826815366745,
        -0.05600856617093086,
        0.023796705529093742,
        -0.027660062536597252,
        -0.021232319995760918,
        -0.02705625258386135,
        -0.05566756799817085,
        0.05403931066393852,
        -0.04792007431387901,
        0.049577746540308,
        -0.00045766233233734965,
        0.04354586824774742,
        0.052662886679172516,
        -0.05134746804833412,
        0.03852274268865585,
        0.05656016245484352,
        0.04669404402375221,
        -0.02854047901928425,
        -0.05741031840443611,
        -0.056051112711429596,
        0.016745885834097862,
        -0.05731354281306267,
        -0.04853294417262077,
        -0.05153355374932289,
        -0.04847860708832741,
        0.05491257086396217,
        0.04837927594780922,
        0.0071638538502156734,
        -0.01039881817996502,
        -0.05603109300136566,
        -0.05779767036437988,
        0.05696585774421692,
        -0.03525521606206894,
        0.04892197996377945,
        -0.057331014424562454,
        -0.04881712794303894,
        -0.036560866981744766,
        0.049234744161367416,
        -0.04497283324599266,
        0.05371252819895744,
        0.044851843267679214,
        -0.05169818550348282,
        -0.05524836853146553,
        0.056770265102386475,
        -0.021931152790784836,
        -0.033235661685466766,
        0.05344085767865181,
        -0.038686417043209076,
        0.030441898852586746,
        0.05485616624355316,
        -0.05406879261136055,
        0.026887912303209305,
        -0.0469539500772953,
        -0.05760093033313751,
        -0.049877963960170746,
        -0.055200863629579544,
        0.0432559959590435,
        -0.05491200089454651,
        0.02863314002752304,
        0.028113970533013344,
        0.05678515136241913,
        -0.03404730185866356,
        -0.05619417130947113,
        -0.009657463990151882,
        -0.05779680609703064,
        -0.03277300298213959,
        0.05739279463887215,
        0.01950077898800373,
        0.039968617260456085,
        -0.03668467327952385,
        -0.029595263302326202,
        0.02194429747760296,
        -0.025806870311498642,
        0.057651836425065994,
        -0.04372827336192131,
        -0.004074286203831434,
        -0.05235620215535164,
        -0.024468829855322838,
        -0.038135427981615067,
        0.05371516942977905,
        -0.04627662152051926,
        -0.04662023484706879,
        0.05321728438138962,
        0.054414767771959305,
        0.033087294548749924,
        0.04090595617890358,
        -0.022990936413407326,
        -0.038963720202445984,
        -0.05787619203329086,
        0.0303184911608696,
        0.05407795310020447,
        -0.047482144087553024,
        -0.054120779037475586,
        -0.04383661225438118,
        -0.05744951218366623,
        0.005410282872617245,
        0.03308800235390663,
        -0.025285888463258743,
        -0.012524975463747978,
        -0.05727864429354668,
        -0.05396251007914543,
        -0.050981614738702774,
        -0.006684686057269573,
        -0.008041007444262505,
        -0.048251356929540634,
        -0.05785083770751953,
        0.0567525289952755,
        0.05301599204540253,
        0.05653127282857895,
        -0.01103112380951643,
        -0.05505070462822914,
        0.05369611829519272,
        -0.04859080910682678,
        0.051653437316417694,
        0.05199882388114929,
        0.031003037467598915,
        0.028703929856419563,
        0.03118784911930561,
        -0.007412279956042767,
        0.049501460045576096,
        -0.05754012241959572,
        -0.052713192999362946,
        -0.04829375073313713,
        0.048769786953926086,
        0.057620570063591,
        0.05661654472351074,
        -0.05696917325258255,
        0.027581792324781418,
        -0.051471151411533356,
        0.05740996450185776,
        0.057592250406742096,
        -0.05741918087005615,
        0.05767814815044403,
        0.043229199945926666,
        -0.053497545421123505,
        0.052592355757951736,
        0.005774936638772488,
        0.05752784386277199,
        0.05548005551099777,
        -0.018531551584601402,
        -0.027053847908973694,
        -0.05207500234246254,
        0.05631966143846512,
        -0.05690407752990723,
        -0.027485432103276253,
        -0.04832078889012337,
        0.030668748542666435,
        0.0253146905452013,
        0.05288660526275635,
        -0.03804997354745865,
        0.0557718500494957,
        0.056131478399038315,
        0.05562014505267143,
        -0.05766751244664192,
        -0.05569205433130264,
        -0.04916492477059364,
        -0.028902655467391014,
        0.022378752008080482,
        0.04456456005573273,
        -0.020437300205230713,
        0.015737751498818398,
        -0.05685610696673393,
        0.01557624526321888,
        0.05729512497782707,
        0.00727695832028985,
        -0.057695288211107254,
        -0.048372965306043625,
        -0.03388962522149086,
        -0.01602097414433956,
        0.05705229192972183,
        0.048662230372428894,
        0.05756340175867081,
        -0.03764652833342552,
        0.055094826966524124,
        -0.03643764555454254,
        0.05663442611694336,
        0.024087386205792427
    ],
    [
        0.002619305858388543,
        0.026383444666862488,
        0.04779499024152756,
        0.031138965860009193,
        0.00868173222988844,
        -0.0006814208463765681,
        -0.03878118842840195,
        -0.0627240240573883,
        0.06246709078550339,
        0.055446937680244446,
        0.03355933353304863,
        0.008794919587671757,
        -0.05709526315331459,
        -0.026305638253688812,
        -0.056125324219465256,
        0.051714058965444565,
        0.040316496044397354,
        0.058479901403188705,
        -0.047759752720594406,
        -0.0538349412381649,
        0.0630473792552948,
        0.05115664005279541,
        -0.019936885684728622,
        0.012084610760211945,
        0.05459248274564743,
        0.05914662033319473,
        0.0330565869808197,
        -0.05386632680892944,
        -0.010543089359998703,
        0.0031284342985600233,
        0.0463225319981575,
        -0.02069631777703762,
        -0.03538642078638077,
        -0.0628923550248146,
        0.04587334021925926,
        0.03885931521654129,
        -0.060939185321331024,
        -0.03321273252367973,
        0.01699800230562687,
        -0.027223840355873108,
        0.026625774800777435,
        0.015357729978859425,
        0.00819332804530859,
        -0.06354283541440964,
        0.000026200918000540696,
        0.0629362016916275,
        0.03088831901550293,
        0.022056372836232185,
        0.05943922698497772,
        0.017157601192593575,
        0.04322799667716026,
        0.06391029804944992,
        -0.058949436992406845,
        0.007760083768516779,
        0.01901324838399887,
        -0.015417280606925488,
        0.04715026170015335,
        -0.02649041824042797,
        0.003330022329464555,
        -0.052279625087976456,
        -0.06373529881238937,
        0.02671598084270954,
        -0.05354701727628708,
        0.0629270151257515,
        -0.054720647633075714,
        -0.03793228790163994,
        -0.03275490179657936,
        0.017798567190766335,
        -0.054504673928022385,
        0.023039033636450768,
        -0.05828240141272545,
        -0.06484410166740417,
        0.06384976953268051,
        0.02314077317714691,
        0.04285102337598801,
        -0.0280408076941967,
        0.024462440982460976,
        0.06500028818845749,
        -0.02481696382164955,
        -0.006906906608492136,
        0.0018425935413688421,
        -0.012626159936189651,
        -0.007732848636806011,
        0.05474436283111572,
        0.05810052156448364,
        0.05231582000851631,
        -0.005591193679720163,
        -0.058259837329387665,
        0.04907523840665817,
        0.03966966271400452,
        0.05283287540078163,
        0.02468819171190262,
        -0.06336791813373566,
        -0.04898620769381523,
        -0.035595156252384186,
        0.06320963054895401,
        0.01927163638174534,
        -0.05622123181819916,
        0.05926360562443733,
        0.004631111864000559,
        -0.06428873538970947,
        0.01794503629207611,
        0.012914913706481457,
        0.028531638905405998,
        0.056271087378263474,
        -0.01628754660487175,
        -0.03537031635642052,
        0.05846988037228584,
        -0.049367185682058334,
        0.04053635522723198,
        -0.05850081145763397,
        0.05211590602993965,
        -0.058008089661598206,
        -0.006952737923711538,
        0.01814962923526764,
        -0.015544350259006023,
        0.016907230019569397,
        -0.04457561671733856,
        0.06498843431472778,
        0.02367584779858589,
        -0.013046380132436752,
        0.06280916929244995,
        -0.01729665696620941,
        -0.053923558443784714,
        -0.05369780585169792,
        -0.06053883954882622,
        -0.04354867339134216,
        -0.05896518751978874,
        0.05563395470380783,
        0.048385508358478546,
        0.0511813685297966,
        -0.0547453798353672,
        -0.05808621644973755,
        -0.020526856184005737,
        -0.06088439002633095,
        -0.059803497046232224,
        0.046006351709365845,
        0.05795721337199211,
        -0.06402985006570816,
        0.04584763944149017,
        0.06296886503696442,
        -0.051759060472249985,
        0.056070663034915924,
        -0.023080704733729362,
        0.059202149510383606,
        0.04026900604367256,
        0.05138792097568512,
        -0.0008983480511233211,
        0.0482134073972702,
        0.05812354013323784,
        0.0628039538860321,
        -0.06436596065759659,
        0.01181887835264206,
        -0.0633508712053299,
        0.04474819824099541,
        -0.06239451467990875,
        -0.008818639442324638,
        0.0005000761011615396,
        0.051753126084804535,
        0.020587902516126633,
        0.027193469926714897,
        0.05441081151366234,
        0.05808163061738014,
        0.05018524453043938,
        0.05405031517148018,
        0.019039636477828026,
        0.009404782205820084,
        0.053211987018585205,
        0.016270902007818222,
        0.031420499086380005,
        -0.04770035669207573,
        0.05229666084051132,
        -0.057555969804525375,
        -0.05669661611318588,
        0.035737548023462296,
        0.06085933372378349,
        -0.06425836682319641,
        -0.007799746934324503,
        0.03635668754577637,
        -0.03738400340080261,
        -0.0019394155824556947,
        0.044854536652565,
        0.05056983232498169,
        0.05057072639465332,
        0.007326910737901926,
        -0.003005653852596879,
        -0.05630716681480408,
        0.06030026078224182,
        -0.04784247279167175,
        -0.04834436625242233,
        -0.056139491498470306,
        0.02505762130022049,
        -0.0363059937953949,
        -0.0005673858104273677,
        -0.013632472604513168,
        0.0324803851544857,
        -0.00431460328400135,
        -0.013653944246470928,
        -0.04969290271401405,
        -0.00405278243124485,
        0.03397972881793976,
        0.0012602264760062099,
        0.04668537154793739,
        -0.02423946186900139,
        -0.06176847219467163,
        0.04808894544839859,
        0.038430217653512955,
        0.05347684770822525,
        -0.04057624563574791,
        0.026081115007400513,
        -0.058119338005781174,
        0.057750266045331955,
        0.054900433868169785,
        0.04284645989537239,
        -0.028470035642385483,
        -0.018019819632172585,
        0.04448636248707771,
        -0.027276089414954185,
        0.0643073245882988,
        -0.020023539662361145,
        -0.012882144190371037,
        0.059265751391649246,
        -0.05227920040488243,
        -0.011767780408263206,
        0.06358309835195541,
        0.000990377739071846,
        0.011389206163585186,
        0.021616769954562187,
        0.026905614882707596,
        -0.05753055959939957,
        0.0029745546635240316,
        -0.05561929568648338,
        -0.035431504249572754,
        0.039883747696876526,
        -0.053790200501680374,
        0.06484993547201157,
        0.061298977583646774,
        -0.02072693407535553,
        -0.014465820044279099,
        0.06473609060049057,
        -0.03125312179327011,
        0.06424348801374435,
        -0.06490451842546463,
        0.05543289706110954,
        0.03538580238819122,
        0.0005968578043393791,
        -0.061194755136966705,
        -0.024300983175635338,
        -0.020220080390572548,
        -0.03736572712659836,
        -0.05646306276321411,
        -0.06192959100008011,
        0.0053543271496891975,
        -0.061851631850004196,
        -0.06154792755842209,
        0.06274542212486267,
        -0.04665860906243324,
        -0.0581931509077549,
        -0.0035080905072391033,
        -0.025309646502137184,
        0.06159655377268791,
        -0.05672509968280792,
        -0.03710136190056801,
        0.054131511598825455,
        0.054179079830646515,
        0.056464336812496185,
        -0.06270363926887512,
        -0.018103061243891716,
        -0.05391358584165573,
        0.03841332718729973,
        0.05890842527151108,
        -0.03821011260151863,
        0.06413279473781586,
        -0.04814734682440758,
        -0.06394660472869873,
        0.0315147303044796,
        -0.035571977496147156,
        0.015255330130457878,
        -0.03655841201543808,
        -0.010194466449320316,
        -0.06272880733013153,
        -0.04028289392590523,
        -0.06465306878089905,
        0.05631297454237938,
        0.023952104151248932,
        -0.013940834440290928,
        0.012188414111733437,
        0.06013449653983116,
        0.04469093680381775,
        0.058103516697883606,
        -0.026001622900366783,
        0.04346161708235741,
        -0.0028571959119290113,
        0.038805533200502396,
        0.06367076188325882,
        -0.06183471903204918,
        0.05413896590471268,
        0.0012470160145312548,
        -0.06222246214747429,
        0.06128528341650963,
        0.04697885364294052,
        0.05660364404320717,
        -0.02357453480362892,
        0.03241152688860893,
        0.04552526772022247,
        -0.03611559048295021,
        -0.02260950766503811,
        0.05872694402933121,
        0.03254973515868187,
        -0.056863315403461456,
        0.024899983778595924,
        0.035389792174100876,
        0.032439183443784714,
        0.0427701435983181,
        0.05466136708855629,
        -0.058888692408800125,
        0.002660180674865842,
        -0.029055902734398842,
        0.03827143833041191,
        -0.05100370571017265,
        -0.016529671847820282,
        -0.02556510642170906,
        0.04466193541884422,
        0.06142140552401543,
        0.05682186782360077,
        -0.053358953446149826,
        0.04876188188791275,
        -0.004458927549421787,
        0.029161103069782257,
        -0.04483213275671005,
        0.02896476536989212,
        -0.0491924062371254,
        -0.014155866578221321,
        -0.05793621391057968,
        0.0635744035243988,
        0.002748542232438922,
        0.014106729999184608,
        0.052478548139333725,
        -0.019581953063607216,
        0.0449325367808342,
        -0.028293631970882416,
        0.05881384387612343,
        -0.05919618532061577,
        -0.04497110843658447,
        0.014105180278420448,
        0.04712928459048271,
        0.05443326756358147,
        0.06077081337571144,
        -0.0613289549946785,
        0.057129696011543274,
        0.05397571623325348,
        0.011683803051710129,
        -0.029132938012480736,
        0.004512874409556389,
        -0.025306833907961845,
        0.02566184103488922,
        -0.05054051801562309,
        -0.04478474706411362,
        -0.0097676245495677,
        -0.062331423163414,
        0.05278719216585159,
        -0.04867377132177353,
        -0.015176467597484589,
        0.0004036991740576923,
        0.029873650521039963,
        -0.036161158233881,
        0.05601906403899193,
        -0.04789501801133156,
        0.021715765818953514,
        -0.05679197609424591,
        -0.004405416548252106,
        -0.003904378041625023,
        0.05818028375506401,
        0.030689137056469917,
        0.04742731899023056,
        0.045204028487205505,
        -0.03495735675096512,
        0.002060085069388151,
        0.06310056149959564,
        0.0571269728243351,
        0.04394889622926712,
        -0.06462211161851883,
        -0.06452450901269913,
        0.05730987712740898,
        0.05642735958099365,
        -0.053792424499988556,
        -0.024132225662469864,
        -0.05995523929595947,
        -0.02661200426518917,
        -0.004566799849271774,
        -0.060452308505773544,
        0.0012850797502323985,
        0.017803391441702843,
        -0.0052428399212658405,
        -0.016744300723075867,
        0.06289183348417282,
        0.053904108703136444,
        0.055737052112817764,
        0.04137535020709038,
        -0.061865128576755524,
        -0.02244746871292591,
        0.04977711662650108,
        0.011723210103809834,
        -0.05718020349740982,
        -0.050362564623355865,
        0.03811568394303322,
        0.010628472082316875,
        -0.057297516614198685,
        0.051266927272081375,
        -0.007707288023084402,
        -0.049149397760629654,
        -0.060461439192295074,
        0.0625406801700592,
        -0.007559268269687891,
        0.008217197842895985,
        -0.059951502829790115,
        -0.028835751116275787,
        0.03965107724070549,
        0.03566928580403328,
        0.06220773234963417,
        0.05233272165060043,
        -0.058300744742155075,
        0.009970610029995441,
        -0.05974898114800453,
        0.03916400298476219,
        0.0024478009436279535,
        -0.05369136855006218,
        -0.050302840769290924,
        0.031468767672777176,
        -0.056881487369537354,
        0.06295127421617508,
        -0.025209086015820503,
        -0.0592208094894886,
        -0.017499232664704323,
        -0.023322930559515953,
        -0.06080690026283264,
        -0.043878380209207535,
        -0.024040281772613525,
        0.008835550397634506,
        0.01030592992901802,
        0.05732354521751404,
        0.053826332092285156,
        0.04587119445204735,
        0.062283921986818314,
        0.057523924857378006,
        -0.02571254037320614,
        0.06230636313557625,
        -0.03131583705544472,
        0.059610169380903244,
        0.05137976258993149,
        -0.05837276205420494,
        0.04916737228631973,
        0.030537789687514305,
        0.04412451386451721,
        0.03264933452010155,
        -0.06174377724528313,
        0.024702252820134163,
        -0.03392905741930008,
        0.02205505408346653,
        0.06151110306382179,
        0.0006766990991309285,
        -0.04230979457497597,
        -0.05527658760547638,
        0.0005487848538905382,
        0.019287632778286934,
        0.06390155106782913,
        -0.05913162603974342,
        0.044113777577877045,
        -0.05915979668498039,
        -0.05946921929717064,
        0.04755769670009613,
        0.06222285330295563,
        0.0022439092863351107,
        0.007244467735290527,
        -0.0619627982378006,
        -0.007974933832883835,
        0.060806404799222946,
        0.0029261235613375902,
        -0.059517357498407364,
        -0.046575095504522324,
        -0.031708087772130966,
        -0.05531680956482887,
        -0.04441384598612785,
        0.027545887976884842,
        0.051340799778699875,
        0.048698849976062775,
        0.04100454971194267,
        0.052718985825777054,
        -0.05238359048962593,
        -0.015209643170237541,
        -0.05842740088701248,
        0.03994404897093773,
        0.03178664669394493,
        0.019805684685707092,
        -0.02353113517165184,
        0.02921956032514572,
        -0.026033272966742516,
        0.052392881363630295,
        0.051015861332416534,
        0.056520167738199234,
        -0.05763036012649536,
        -0.05117154121398926,
        -0.02525494620203972,
        0.023675965145230293,
        -0.029481520876288414,
        -0.04908568412065506,
        0.054871637374162674,
        0.0036987606436014175,
        -0.012403891421854496,
        -0.06072569638490677,
        0.026980698108673096,
        0.062650665640831
    ],
    [
        -0.004830564372241497,
        0.01858355477452278,
        0.04675566777586937,
        0.03187696635723114,
        0.02277076058089733,
        0.007006902247667313,
        -0.04628781974315643,
        -0.0619899183511734,
        0.062266018241643906,
        0.05454842001199722,
        0.03071890026330948,
        0.020370304584503174,
        -0.058144278824329376,
        -0.03468979522585869,
        -0.05643212050199509,
        0.05149070546030998,
        0.038281459361314774,
        0.058021459728479385,
        -0.03282660245895386,
        -0.054741647094488144,
        0.06280660629272461,
        0.055490970611572266,
        -0.016945531591773033,
        0.0007983907707966864,
        0.05462511628866196,
        0.05387772247195244,
        0.03184743598103523,
        -0.056544072926044464,
        -0.0185083094984293,
        0.0037047562655061483,
        0.036010947078466415,
        -0.031133072450757027,
        -0.03420626372098923,
        -0.06234556809067726,
        0.046156372874975204,
        0.026836225762963295,
        -0.061215415596961975,
        -0.0476127564907074,
        0.05157758295536041,
        -0.03703195974230766,
        0.027907971292734146,
        0.015706244856119156,
        0.0022470196709036827,
        -0.06259717047214508,
        0.014168002642691135,
        0.06310688704252243,
        0.03422439843416214,
        0.0006069643422961235,
        0.056591376662254333,
        0.03130152076482773,
        0.04179326817393303,
        0.06309109181165695,
        -0.056104738265275955,
        -0.012529184110462666,
        0.028977015987038612,
        -0.028963323682546616,
        0.04637277126312256,
        -0.022514929994940758,
        0.0025007373187690973,
        -0.053782109171152115,
        -0.06296949088573456,
        0.04101957380771637,
        -0.05481269955635071,
        0.06284686923027039,
        -0.05065658688545227,
        -0.04730268195271492,
        -0.03229980543255806,
        0.010788459330797195,
        -0.05606216937303543,
        0.035830575972795486,
        -0.055856265127658844,
        -0.06445250660181046,
        0.06376956403255463,
        0.02155606634914875,
        0.04476330056786537,
        -0.034803152084350586,
        0.015424937009811401,
        0.06459705531597137,
        -0.015257379971444607,
        -0.00460374029353261,
        0.021948296576738358,
        -0.001863769139163196,
        0.005666038952767849,
        0.05810270830988884,
        0.05562214553356171,
        0.053120408207178116,
        0.007440574001520872,
        -0.0545947402715683,
        0.03808407858014107,
        0.05024399235844612,
        0.048141319304704666,
        0.007716680411249399,
        -0.06348121911287308,
        -0.048679985105991364,
        -0.03819495067000389,
        0.06322892010211945,
        0.008811782114207745,
        -0.05878901481628418,
        0.05831482261419296,
        0.011134205386042595,
        -0.06405914574861526,
        0.026205897331237793,
        0.011283625848591328,
        0.01747138984501362,
        0.05965528264641762,
        -0.01989665813744068,
        -0.04479074105620384,
        0.05809425935149193,
        -0.053717710077762604,
        0.04541923850774765,
        -0.05926956981420517,
        0.021958263590931892,
        -0.05749281495809555,
        -0.016887634992599487,
        -0.03259534761309624,
        -0.006287809927016497,
        0.0199746023863554,
        -0.03339025378227234,
        0.06458309292793274,
        0.005859000608325005,
        -0.015392830595374107,
        0.06230057403445244,
        -0.02392939105629921,
        -0.04915392026305199,
        -0.05530513450503349,
        -0.06093195453286171,
        -0.037372078746557236,
        -0.06003596633672714,
        0.055414702743291855,
        0.04177875816822052,
        0.02855917438864708,
        -0.056970447301864624,
        -0.056574661284685135,
        -0.004481696058064699,
        -0.06122324615716934,
        -0.05889144539833069,
        0.03772621229290962,
        0.05842606723308563,
        -0.06381507217884064,
        0.048937056213617325,
        0.060945894569158554,
        -0.05193424969911575,
        0.05469255521893501,
        -0.033458225429058075,
        0.058461517095565796,
        0.053653471171855927,
        0.051719218492507935,
        0.003345824545249343,
        0.0502910353243351,
        0.059441741555929184,
        0.06357228755950928,
        -0.06405062228441238,
        -0.013493366539478302,
        -0.06277286261320114,
        0.04588646441698074,
        -0.06168540567159653,
        -0.02813527174293995,
        0.01684465818107128,
        0.051508694887161255,
        0.010573778301477432,
        0.0434904582798481,
        0.05346241593360901,
        0.0567629411816597,
        0.05606062710285187,
        0.057894788682460785,
        0.026732616126537323,
        -0.001819473342038691,
        0.0521482490003109,
        0.02452181652188301,
        0.027871115133166313,
        -0.045246221125125885,
        0.0550314299762249,
        -0.059728436172008514,
        -0.05741245672106743,
        0.028288505971431732,
        0.056478820741176605,
        -0.0634283572435379,
        -0.008849935606122017,
        0.02780100330710411,
        -0.03504228591918945,
        -0.010139886289834976,
        0.05553845316171646,
        0.05028754472732544,
        0.04817776009440422,
        0.020539309829473495,
        0.0028313142247498035,
        -0.05261692404747009,
        0.0597340427339077,
        -0.050183314830064774,
        -0.05711729824542999,
        -0.05815199390053749,
        0.016825931146740913,
        -0.02856544964015484,
        -0.0006638341001234949,
        0.0045428588055074215,
        0.042478013783693314,
        -0.009255237877368927,
        -0.007666831836104393,
        -0.04869682714343071,
        -0.01489946898072958,
        0.03866079822182655,
        -0.008754629641771317,
        0.03723626211285591,
        -0.028805775567889214,
        -0.06073235347867012,
        0.04769694060087204,
        0.04296644404530525,
        0.058614280074834824,
        -0.04608398675918579,
        0.05157811939716339,
        -0.058955080807209015,
        0.05817490071058273,
        0.05806639790534973,
        0.04591009020805359,
        -0.039789505302906036,
        0.011364227160811424,
        0.04008094221353531,
        -0.03559419885277748,
        0.06371793895959854,
        -0.029844019562005997,
        -0.021200543269515038,
        0.05937807261943817,
        -0.0530574768781662,
        -0.018242575228214264,
        0.06353360414505005,
        -0.013601516373455524,
        0.01786956563591957,
        -0.022564727813005447,
        0.025230929255485535,
        -0.059400174766778946,
        0.015220998786389828,
        -0.05694442242383957,
        -0.03325386717915535,
        0.04327097907662392,
        -0.05380162224173546,
        0.06443808972835541,
        0.061572834849357605,
        -0.0007419050671160221,
        -0.01826360635459423,
        0.06435365974903107,
        -0.037672653794288635,
        0.06378039717674255,
        -0.06453388929367065,
        0.04451865702867508,
        0.035159043967723846,
        0.004101977217942476,
        -0.05333435907959938,
        -0.01549027394503355,
        -0.01364489272236824,
        -0.03442689776420593,
        -0.05468408763408661,
        -0.06231841444969177,
        0.025053098797798157,
        -0.062231406569480896,
        -0.06049680709838867,
        0.06237905099987984,
        -0.0443534329533577,
        -0.0605483315885067,
        -0.004683323670178652,
        -0.016622520983219147,
        0.059932272881269455,
        -0.05721917003393173,
        -0.022146040573716164,
        0.05103263631463051,
        0.053554635494947433,
        0.05573349446058273,
        -0.050401750952005386,
        0.0031127510592341423,
        -0.05359895899891853,
        0.033944111317396164,
        0.057562585920095444,
        -0.0423252247273922,
        0.06376764178276062,
        -0.041266974061727524,
        -0.0629936158657074,
        0.026974674314260483,
        -0.039432235062122345,
        0.003056015120819211,
        -0.008803291246294975,
        -0.005552405957132578,
        -0.061336614191532135,
        -0.0414721854031086,
        -0.06436597555875778,
        0.05315660685300827,
        0.02339528314769268,
        0.011377247050404549,
        0.0020231795497238636,
        0.05732467770576477,
        0.047711778432130814,
        0.05691758915781975,
        -0.009627864696085453,
        0.04707024246454239,
        0.008665073662996292,
        0.04171827435493469,
        0.06339798122644424,
        -0.06270269304513931,
        0.0561758317053318,
        -0.005473186261951923,
        -0.05863717570900917,
        0.061052531003952026,
        0.037321265786886215,
        0.05744331330060959,
        -0.022037025541067123,
        0.03731536492705345,
        0.02284071408212185,
        -0.03809233754873276,
        -0.02662336826324463,
        0.053327351808547974,
        0.015718333423137665,
        -0.05609344691038132,
        0.024847865104675293,
        0.03427095711231232,
        0.035343337804079056,
        0.05187089368700981,
        0.052299514412879944,
        -0.05888712778687477,
        0.008136007934808731,
        -0.008189991116523743,
        0.04705366864800453,
        -0.0500861220061779,
        -0.0021579612512141466,
        -0.028348715975880623,
        0.04148073121905327,
        0.06167156621813774,
        0.05737774819135666,
        -0.054761528968811035,
        0.0378611721098423,
        -0.0031779641285538673,
        0.03040354512631893,
        -0.047179918736219406,
        0.0312696173787117,
        -0.045941032469272614,
        -0.016805076971650124,
        -0.059638671576976776,
        0.06293216347694397,
        0.0027679388877004385,
        0.02222682163119316,
        0.05575775355100632,
        -0.018310630694031715,
        0.0469379760324955,
        -0.023549240082502365,
        0.058364663273096085,
        -0.05893467366695404,
        -0.04759197309613228,
        0.02672974392771721,
        0.04802608862519264,
        0.04934560880064964,
        0.06115647405385971,
        -0.05833537131547928,
        0.060491520911455154,
        0.051893655210733414,
        0.0030434890650212765,
        -0.020935460925102234,
        -0.004103279206901789,
        -0.0015524770133197308,
        0.012788110412657261,
        -0.052598435431718826,
        -0.036980148404836655,
        -0.0016143704997375607,
        -0.06302140653133392,
        0.05373748764395714,
        -0.03481128811836243,
        -0.023124590516090393,
        0.006738354917615652,
        0.012424045242369175,
        -0.039398107677698135,
        0.0540337935090065,
        -0.05513380467891693,
        0.007813909091055393,
        -0.05520208179950714,
        -0.0067542740143835545,
        -0.012499966658651829,
        0.0566435232758522,
        0.041098326444625854,
        0.04480590671300888,
        0.027565577998757362,
        -0.04261116683483124,
        0.016019539907574654,
        0.06340210139751434,
        0.059695687144994736,
        0.03659087419509888,
        -0.06433422118425369,
        -0.06417578458786011,
        0.058251578360795975,
        0.05613221228122711,
        -0.05574091151356697,
        -0.030411237850785255,
        -0.05929842218756676,
        -0.018869629129767418,
        0.005649825092405081,
        -0.06095005199313164,
        0.004236992914229631,
        0.02882281318306923,
        -0.022857554256916046,
        0.007637061178684235,
        0.062094397842884064,
        0.0549517422914505,
        0.05382038280367851,
        0.035378966480493546,
        -0.06309251487255096,
        -0.013901783153414726,
        0.04817073792219162,
        0.008992250077426434,
        -0.05996188893914223,
        -0.04566214233636856,
        0.040443096309900284,
        0.008298559114336967,
        -0.05854176729917526,
        0.050362490117549896,
        0.005523121450096369,
        -0.04593706130981445,
        -0.05775851756334305,
        0.06318235397338867,
        0.0042947507463395596,
        -0.0019987921696156263,
        -0.06275667995214462,
        -0.036981001496315,
        0.03663131594657898,
        0.043848916888237,
        0.06339096277952194,
        0.0483914390206337,
        -0.04701699689030647,
        0.02965807355940342,
        -0.05910678207874298,
        0.03975607827305794,
        -0.016603801399469376,
        -0.05249787122011185,
        -0.049953825771808624,
        0.019746083766222,
        -0.053961433470249176,
        0.06312087178230286,
        -0.02107141725718975,
        -0.06083374843001366,
        -0.026514599099755287,
        -0.041505616158246994,
        -0.05836332216858864,
        -0.06022739037871361,
        -0.024098578840494156,
        -0.012022523209452629,
        0.031586337834596634,
        0.05780120939016342,
        0.05092962458729744,
        0.0543363094329834,
        0.06271368265151978,
        0.0543549507856369,
        -0.021009746938943863,
        0.061961472034454346,
        -0.03690813481807709,
        0.059861794114112854,
        0.0497211292386055,
        -0.058430977165699005,
        0.040580108761787415,
        0.029334781691432,
        0.0448724739253521,
        0.03453226760029793,
        -0.06240813061594963,
        0.022185122594237328,
        -0.034896984696388245,
        0.03450458124279976,
        0.06073076277971268,
        -0.014016727916896343,
        -0.043975815176963806,
        -0.0574716180562973,
        0.018172182142734528,
        0.02479764260351658,
        0.06371447443962097,
        -0.0591726154088974,
        0.03438432514667511,
        -0.05864088609814644,
        -0.0611192025244236,
        0.047448690980672836,
        0.06263034790754318,
        0.0005779589409939945,
        -0.0017794283339753747,
        -0.05787641927599907,
        0.0008239467279054224,
        0.05833637714385986,
        0.025266913697123528,
        -0.05705723911523819,
        -0.050608132034540176,
        -0.049797605723142624,
        -0.054856594651937485,
        -0.038908734917640686,
        0.02381160296499729,
        0.04807494208216667,
        0.05396650359034538,
        0.04801759496331215,
        0.04762604832649231,
        -0.053279753774404526,
        -0.013853169046342373,
        -0.059710197150707245,
        0.023819871246814728,
        0.029935240745544434,
        0.006637378595769405,
        -0.008720043115317822,
        0.035613540560007095,
        -0.015638815239071846,
        0.05282794311642647,
        0.055471379309892654,
        0.05325017869472504,
        -0.06033580005168915,
        -0.04206802323460579,
        -0.021445482969284058,
        0.025248205289244652,
        -0.02148936130106449,
        -0.04787025973200798,
        0.059420112520456314,
        0.01570425182580948,
        -0.011036943644285202,
        -0.06256275624036789,
        0.041713275015354156,
        0.06020614132285118
    ],
    [
        -0.004569273442029953,
        0.03113763965666294,
        0.04557916149497032,
        0.04386502504348755,
        -0.0024082749150693417,
        -0.00547275273129344,
        -0.045650891959667206,
        -0.06272181868553162,
        0.06318794190883636,
        0.05659826099872589,
        0.02958391234278679,
        0.030639195814728737,
        -0.05749165639281273,
        -0.012508982792496681,
        -0.055887896567583084,
        0.056033335626125336,
        0.027971575036644936,
        0.05808549374341965,
        -0.04318838566541672,
        -0.04699740186333656,
        0.06284064799547195,
        0.05049024894833565,
        -0.01169360987842083,
        0.012781697325408459,
        0.054998043924570084,
        0.06046576052904129,
        0.05277710035443306,
        -0.04527723789215088,
        -0.016548538580536842,
        -0.014001511968672276,
        0.052514202892780304,
        -0.013163331896066666,
        -0.025265129283070564,
        -0.06232064217329025,
        0.046288054436445236,
        0.041065216064453125,
        -0.061052098870277405,
        -0.03890758752822876,
        -0.041118063032627106,
        -0.0282413512468338,
        0.02037942036986351,
        0.030128441751003265,
        0.002743558259680867,
        -0.06315706670284271,
        0.0247013159096241,
        0.061755742877721786,
        0.03324755281209946,
        0.008142063394188881,
        0.062047865241765976,
        0.006314918864518404,
        0.04643329232931137,
        0.0636104866862297,
        -0.0470338836312294,
        0.009291724301874638,
        0.04892744868993759,
        -0.014983032830059528,
        0.037897318601608276,
        -0.011233281344175339,
        -0.025934752076864243,
        -0.052216242998838425,
        -0.06302887946367264,
        0.027605731040239334,
        -0.05170503631234169,
        0.06316868215799332,
        -0.05544620379805565,
        -0.037675891071558,
        -0.0332614965736866,
        0.030506556853652,
        -0.05355308949947357,
        0.03129127621650696,
        -0.053487133234739304,
        -0.06380266696214676,
        0.06341582536697388,
        0.015042625367641449,
        0.04243452474474907,
        -0.02268122136592865,
        0.020489195361733437,
        0.0642828643321991,
        0.012456865049898624,
        -0.013935081660747528,
        0.020508117973804474,
        -0.015784895047545433,
        -0.01724269799888134,
        0.05464088171720505,
        0.03875117748975754,
        0.05363328009843826,
        -0.0015007701003924012,
        -0.0549585185945034,
        0.05294293165206909,
        0.04779977723956108,
        0.05022965744137764,
        0.027271917089819908,
        -0.06223762035369873,
        -0.0532805435359478,
        -0.03962397202849388,
        0.06257303804159164,
        0.01702103577554226,
        -0.05785126984119415,
        0.059761177748441696,
        0.019354388117790222,
        -0.06350435316562653,
        0.005758852697908878,
        0.018293173983693123,
        0.03016631491482258,
        0.0587264746427536,
        -0.03280679136514664,
        -0.04737185686826706,
        0.05971158668398857,
        -0.053142569959163666,
        0.04195845127105713,
        -0.06117850914597511,
        0.02547811158001423,
        -0.05784754827618599,
        0.014998386614024639,
        0.0067138103768229485,
        0.017068859189748764,
        -0.00039194623241201043,
        -0.03314888849854469,
        0.06421495229005814,
        0.007929598912596703,
        -0.025325091555714607,
        0.06185665354132652,
        -0.01590787060558796,
        -0.04953744262456894,
        -0.054294705390930176,
        -0.05988239124417305,
        -0.04337694123387337,
        -0.058392271399497986,
        0.05700629949569702,
        0.05082898214459419,
        0.04730883613228798,
        -0.05313477665185928,
        -0.053123608231544495,
        -0.015066338703036308,
        -0.06030005216598511,
        -0.05824598670005798,
        0.03858550637960434,
        0.059986189007759094,
        -0.0632375180721283,
        0.04066110774874687,
        0.06062480807304382,
        -0.04575259983539581,
        0.05391158163547516,
        -0.015343625098466873,
        0.05525932088494301,
        0.05652874335646629,
        0.035354133695364,
        0.001440992928110063,
        0.048397693783044815,
        0.05859515815973282,
        0.06169265881180763,
        -0.0637003481388092,
        0.03429948166012764,
        -0.06360853463411331,
        0.03741550073027611,
        -0.06255972385406494,
        -0.00786654558032751,
        0.010327284224331379,
        0.058059122413396835,
        0.037881914526224136,
        0.03319866582751274,
        0.044551920145750046,
        0.056139931082725525,
        0.04786286503076553,
        0.057766929268836975,
        0.030013874173164368,
        0.012937250547111034,
        0.05188565328717232,
        0.03464924916625023,
        0.024231337010860443,
        -0.03862655535340309,
        0.05634508281946182,
        -0.06115996465086937,
        -0.05025365203619003,
        0.04514229670166969,
        0.06107744574546814,
        -0.06360271573066711,
        -0.02100231684744358,
        0.0409805066883564,
        -0.043858807533979416,
        -0.017947543412446976,
        0.04912690445780754,
        0.04503624886274338,
        0.04480744153261185,
        0.0006430763169191778,
        -0.012950049713253975,
        -0.05394721031188965,
        0.05872379615902901,
        -0.041904985904693604,
        -0.04315764829516411,
        -0.05352800339460373,
        0.0052004531025886536,
        -0.045717108994722366,
        -0.01949257217347622,
        -0.03514391928911209,
        0.03895680978894234,
        -0.014488381333649158,
        0.00011275818542344496,
        -0.0354706346988678,
        0.008955162018537521,
        0.0410582572221756,
        -0.01873825490474701,
        0.0443679541349411,
        -0.012223378755152225,
        -0.05566326156258583,
        0.03429819270968437,
        0.03951770067214966,
        0.055621691048145294,
        -0.046236053109169006,
        0.03472321107983589,
        -0.060726381838321686,
        0.05777640640735626,
        0.054291341453790665,
        0.03891204297542572,
        -0.038158118724823,
        0.024775991216301918,
        0.054712265729904175,
        -0.02511174976825714,
        0.06310872733592987,
        -0.012051749043166637,
        -0.01147936750203371,
        0.060650963336229324,
        -0.06006845086812973,
        -0.03401642665266991,
        0.060981132090091705,
        0.018343858420848846,
        0.016226131469011307,
        0.004694958683103323,
        0.036603495478630066,
        -0.05926410108804703,
        0.0036456366069614887,
        -0.03976491093635559,
        -0.020845552906394005,
        0.04535770043730736,
        -0.04037993773818016,
        0.06418522447347641,
        0.06172088906168938,
        -0.027866099029779434,
        -0.001161243417300284,
        0.06416811794042587,
        -0.03701881319284439,
        0.06372386962175369,
        -0.06410759687423706,
        0.049228645861148834,
        0.03406692296266556,
        0.015990110114216805,
        -0.060795143246650696,
        -0.018620401620864868,
        -0.021413065493106842,
        -0.02664860151708126,
        -0.05509708821773529,
        -0.06240431219339371,
        0.0070451307110488415,
        -0.06201473996043205,
        -0.06162548437714577,
        0.0629616230726242,
        -0.05375422164797783,
        -0.05658924952149391,
        0.008925845846533775,
        0.003944938536733389,
        0.05799771100282669,
        -0.05794776231050491,
        -0.03674193099141121,
        0.05613739788532257,
        0.04650910943746567,
        0.05620085075497627,
        -0.04378770291805267,
        -0.010627171956002712,
        -0.05501032620668411,
        0.039152659475803375,
        0.05995107442140579,
        -0.041948530822992325,
        0.06355324387550354,
        -0.04150381311774254,
        -0.06280097365379333,
        0.0277987252920866,
        -0.044525835663080215,
        -0.0030824821442365646,
        0.018393272534012794,
        0.0011756934691220522,
        -0.06125793978571892,
        -0.03620211035013199,
        -0.06409601122140884,
        0.05768761783838272,
        0.013957574032247066,
        -0.005724255461245775,
        0.02227335050702095,
        0.05385082960128784,
        0.05354419723153114,
        0.05643218010663986,
        -0.03323362022638321,
        0.047356460243463516,
        -0.011607186868786812,
        0.04249270260334015,
        0.06278234720230103,
        -0.062160324305295944,
        0.05768684297800064,
        0.022614620625972748,
        -0.05760229006409645,
        0.061004746705293655,
        0.047096896916627884,
        0.04186493903398514,
        -0.03217357397079468,
        0.03883823752403259,
        0.04816199094057083,
        -0.041371241211891174,
        -0.03881986811757088,
        0.05075966566801071,
        0.022931424900889397,
        -0.056648138910532,
        0.006994553375989199,
        0.02778155729174614,
        0.03718073293566704,
        0.042089756578207016,
        0.0441056564450264,
        -0.0600678026676178,
        -0.007800179999321699,
        -0.009064996615052223,
        0.04581327363848686,
        -0.052702076733112335,
        -0.0194964911788702,
        -0.035371702164411545,
        0.02946382947266102,
        0.05291743949055672,
        0.059461988508701324,
        -0.05542440339922905,
        0.0492599681019783,
        0.019531749188899994,
        0.031683437526226044,
        -0.05549387261271477,
        0.02379971742630005,
        -0.055768270045518875,
        -0.0026288353838026524,
        -0.05985096096992493,
        0.06198721006512642,
        -0.003914586268365383,
        -0.0008623191388323903,
        0.05175883695483208,
        -0.016587143763899803,
        0.05194997042417526,
        -0.03986532986164093,
        0.05705089494585991,
        -0.0595489963889122,
        -0.041286323219537735,
        0.043726902455091476,
        0.04350447654724121,
        0.05015983060002327,
        0.0618186853826046,
        -0.06012696400284767,
        0.06078261137008667,
        0.049386393278837204,
        0.02289886213839054,
        -0.02693742886185646,
        -0.023346392437815666,
        -0.00037460855673998594,
        0.026921743527054787,
        -0.052970193326473236,
        -0.03571457415819168,
        -0.01607445813715458,
        -0.06086514890193939,
        0.050528790801763535,
        -0.042804837226867676,
        -0.025329990312457085,
        0.0006494311382994056,
        0.008655337616801262,
        -0.041709594428539276,
        0.05979231372475624,
        -0.03284808248281479,
        0.002201665658503771,
        -0.059491340070962906,
        -0.007410102058202028,
        -0.014680683612823486,
        0.0592595674097538,
        0.042329706251621246,
        0.050431735813617706,
        0.04181647673249245,
        -0.05259103327989578,
        0.023696037009358406,
        0.06225382164120674,
        0.05054507032036781,
        0.03474846109747887,
        -0.06306180357933044,
        -0.06389445811510086,
        0.05505451560020447,
        0.050917211920022964,
        -0.05309603735804558,
        -0.029842719435691833,
        -0.052500173449516296,
        -0.02623695880174637,
        0.0016042084898799658,
        -0.0609283521771431,
        -0.01532750017940998,
        -0.006556334439665079,
        -0.008636143058538437,
        -0.01727019064128399,
        0.06249415501952171,
        0.04660773277282715,
        0.05544779449701309,
        0.04860246926546097,
        -0.06165776401758194,
        -0.014362713322043419,
        0.049220550805330276,
        0.03953902795910835,
        -0.056042175740003586,
        -0.04593838006258011,
        0.0368395671248436,
        0.004761409014463425,
        -0.058824051171541214,
        0.05109669640660286,
        -0.0006850630743429065,
        -0.05294661596417427,
        -0.0566420778632164,
        0.06271947920322418,
        -0.023914983496069908,
        -0.011862475425004959,
        -0.06080397963523865,
        -0.03303288668394089,
        0.021728014573454857,
        0.028078390285372734,
        0.059520941227674484,
        0.05216939002275467,
        -0.04774457961320877,
        0.005199455190449953,
        -0.058782994747161865,
        0.035714082419872284,
        -0.020502205938100815,
        -0.0575871504843235,
        -0.0509694442152977,
        0.03625975921750069,
        -0.05859646573662758,
        0.06267435848712921,
        -0.02482619136571884,
        -0.058731816709041595,
        -0.012329415418207645,
        -0.03469236567616463,
        -0.060699090361595154,
        -0.05364347621798515,
        -0.024518197402358055,
        0.01768713817000389,
        0.01717732474207878,
        0.06276415288448334,
        0.05072131007909775,
        0.04228087514638901,
        0.06169712170958519,
        0.06290079653263092,
        -0.01844870112836361,
        0.061552949249744415,
        -0.042395979166030884,
        0.05776886269450188,
        0.05715065449476242,
        -0.05843189358711243,
        0.05238424614071846,
        0.018800022080540657,
        0.054462362080812454,
        0.024237219244241714,
        -0.061492908746004105,
        0.024165796115994453,
        -0.024494977667927742,
        0.023218384012579918,
        0.05637439712882042,
        0.02059781178832054,
        -0.04487140104174614,
        -0.05801970511674881,
        0.04006081447005272,
        0.0042442260310053825,
        0.06327904760837555,
        -0.05777692049741745,
        0.039041876792907715,
        -0.05923951417207718,
        -0.06171007081866264,
        0.040044624358415604,
        0.05982765182852745,
        0.0009428615448996425,
        0.021656276658177376,
        -0.054031290113925934,
        -0.0028858857695013285,
        0.05806560814380646,
        0.03310032933950424,
        -0.05624262988567352,
        -0.05145645886659622,
        -0.04063480719923973,
        -0.05907522886991501,
        -0.024230172857642174,
        0.02028489299118519,
        0.05989024415612221,
        0.047064442187547684,
        0.059292446821928024,
        0.052391596138477325,
        -0.0502074733376503,
        -0.0033490166533738375,
        -0.06100134924054146,
        0.01039790827780962,
        0.05218693986535072,
        0.01916307769715786,
        -0.03905443102121353,
        0.011603395454585552,
        -0.031082160770893097,
        0.04987088218331337,
        0.05380132421851158,
        0.055558230727910995,
        -0.05908383056521416,
        -0.04472602903842926,
        -0.02296580746769905,
        0.02313740737736225,
        -0.017207007855176926,
        -0.04471486806869507,
        0.055846523493528366,
        0.012293278239667416,
        0.02859547734260559,
        -0.06231793761253357,
        0.016752200201153755,
        0.06099181994795799
    ],
    [
        -0.01593036577105522,
        0.023120271041989326,
        0.04380299896001816,
        0.031641241163015366,
        -0.003387654200196266,
        -0.004817951936274767,
        -0.05193345993757248,
        -0.06348525732755661,
        0.06361773610115051,
        0.059183813631534576,
        0.030967019498348236,
        0.028686923906207085,
        -0.05681613087654114,
        -0.03904256224632263,
        -0.05469749867916107,
        0.05956581234931946,
        0.030410580337047577,
        0.05964293330907822,
        -0.038992464542388916,
        -0.050618261098861694,
        0.06257963180541992,
        0.05350488796830177,
        -0.0015945426421239972,
        0.01592927984893322,
        0.04986497759819031,
        0.05928739532828331,
        0.052590612322092056,
        -0.05706363916397095,
        -0.014531638473272324,
        -0.012240591458976269,
        0.03166981041431427,
        -0.03513602167367935,
        -0.015525242313742638,
        -0.06028953194618225,
        0.04370037093758583,
        0.03934219107031822,
        -0.06064517796039581,
        -0.02917095646262169,
        -0.04762069508433342,
        -0.02709708921611309,
        0.02351686730980873,
        0.013136845082044601,
        0.003685929346829653,
        -0.06414932757616043,
        0.034006647765636444,
        0.06273163855075836,
        0.03975740820169449,
        0.0282363872975111,
        0.059767015278339386,
        0.03750864788889885,
        0.05551287904381752,
        0.06442112475633621,
        -0.052395500242710114,
        0.01779928058385849,
        0.043245743960142136,
        -0.020591245964169502,
        0.044515252113342285,
        -0.002768064383417368,
        -0.02114427648484707,
        -0.05799131095409393,
        -0.0638665035367012,
        0.021006986498832703,
        -0.052942484617233276,
        0.06441254168748856,
        -0.05494071915745735,
        -0.02633395977318287,
        -0.027382997795939445,
        0.005629762075841427,
        -0.058326251804828644,
        0.0379827655851841,
        -0.05906473100185394,
        -0.06348578631877899,
        0.06416305899620056,
        0.02451448142528534,
        0.032778672873973846,
        -0.02590787038207054,
        0.017791103571653366,
        0.06499594449996948,
        -0.017227698117494583,
        -0.0303073450922966,
        0.010777798481285572,
        -0.013985857367515564,
        -0.00561339920386672,
        0.051393773406744,
        0.04720338433980942,
        0.056159138679504395,
        -0.011184021830558777,
        -0.05085962638258934,
        0.051759108901023865,
        0.04813648760318756,
        0.051410868763923645,
        0.021495653316378593,
        -0.06348411738872528,
        -0.053924016654491425,
        -0.04275185614824295,
        0.0634608268737793,
        0.001444351626560092,
        -0.057641226798295975,
        0.062121741473674774,
        0.011242824606597424,
        -0.06462379544973373,
        0.020656347274780273,
        0.017841197550296783,
        0.03907637298107147,
        0.060806240886449814,
        -0.049165889620780945,
        -0.033056292682886124,
        0.06207767501473427,
        -0.04921337217092514,
        0.04733869805932045,
        -0.06269793957471848,
        0.03219471499323845,
        -0.058438338339328766,
        0.002461267402395606,
        0.016671283170580864,
        0.017533795908093452,
        0.006234080530703068,
        -0.04319419711828232,
        0.0649418979883194,
        0.017680557444691658,
        -0.010833420790731907,
        0.06123698875308037,
        -0.0010301389265805483,
        -0.052854783833026886,
        -0.05616896599531174,
        -0.06057869642972946,
        -0.0346275232732296,
        -0.061357077211141586,
        0.05764181911945343,
        0.055356599390506744,
        0.0578141063451767,
        -0.05380682647228241,
        -0.054331958293914795,
        -0.02557147666811943,
        -0.06016300618648529,
        -0.059131789952516556,
        0.04209964722394943,
        0.06034614518284798,
        -0.06459447741508484,
        0.04470963776111603,
        0.06031883880496025,
        -0.03143846243619919,
        0.05573121830821037,
        -0.011249909177422523,
        0.051904916763305664,
        0.05670147389173508,
        0.039612822234630585,
        -0.010178969241678715,
        0.045794062316417694,
        0.059658024460077286,
        0.06028633192181587,
        -0.06415385007858276,
        0.05625360831618309,
        -0.0643029659986496,
        0.04072947055101395,
        -0.06282398104667664,
        -0.0307241752743721,
        0.015189419500529766,
        0.05925298109650612,
        0.02183464914560318,
        0.037229448556900024,
        0.03472208231687546,
        0.05784901976585388,
        0.03974189981818199,
        0.05904940515756607,
        0.03833402320742607,
        0.010909322649240494,
        0.04766354709863663,
        0.018445035442709923,
        0.023495037108659744,
        -0.016605354845523834,
        0.05214046314358711,
        -0.059086207300424576,
        -0.05445406958460808,
        0.05478491634130478,
        0.060297537595033646,
        -0.06264010071754456,
        -0.002284569200128317,
        0.03415898233652115,
        -0.05034434422850609,
        -0.01726503111422062,
        0.056723129004240036,
        0.0548272505402565,
        0.02683212235569954,
        -0.00036262956564314663,
        -0.020830290392041206,
        -0.04910929873585701,
        0.06081191077828407,
        -0.04997965693473816,
        -0.03437879681587219,
        -0.04942730814218521,
        0.00508541613817215,
        -0.031737007200717926,
        -0.0037364852614700794,
        -0.03180257976055145,
        0.022540641948580742,
        -0.011327840387821198,
        0.002035783836618066,
        -0.044097114354372025,
        0.01741330511868,
        0.02642001025378704,
        -0.0012128162197768688,
        0.014327975921332836,
        -0.03477835655212402,
        -0.06089002266526222,
        0.030379418283700943,
        0.03423561155796051,
        0.04908779636025429,
        -0.03427761048078537,
        0.04913020133972168,
        -0.060164932161569595,
        0.05938945710659027,
        0.05973814055323601,
        0.05214022472500801,
        -0.01239815168082714,
        0.004372123628854752,
        0.026079317554831505,
        -0.0362553671002388,
        0.06324835121631622,
        -0.02177744358778,
        -0.009571789763867855,
        0.06186966598033905,
        -0.05876399576663971,
        -0.025053055956959724,
        0.05871294438838959,
        0.041008464992046356,
        0.027889417484402657,
        0.014348072931170464,
        0.03356458991765976,
        -0.05750398337841034,
        -0.006992041133344173,
        -0.018614981323480606,
        -0.03321540728211403,
        0.050449077039957047,
        -0.03794229403138161,
        0.0648215115070343,
        0.06277918815612793,
        -0.020758584141731262,
        -0.004963204264640808,
        0.06484352052211761,
        -0.02597125619649887,
        0.06429031491279602,
        -0.06480433791875839,
        0.05297529324889183,
        0.037163276225328445,
        0.0146111398935318,
        -0.06224534288048744,
        -0.02895856462419033,
        -0.008575680665671825,
        -0.024309029802680016,
        -0.05757756158709526,
        -0.06298361718654633,
        -0.013873709365725517,
        -0.061559047549963,
        -0.061784375458955765,
        0.06379405409097672,
        -0.0578799806535244,
        -0.05474574491381645,
        0.01826303079724312,
        -0.010501433163881302,
        0.056820422410964966,
        -0.05848417058587074,
        -0.04081801697611809,
        0.05232946574687958,
        0.035499341785907745,
        0.05521557107567787,
        -0.04486821964383125,
        0.013787972740828991,
        -0.05323547497391701,
        0.03854351118206978,
        0.0583634078502655,
        -0.035607896745204926,
        0.06385508179664612,
        -0.025149255990982056,
        -0.06376821547746658,
        0.027631988748908043,
        -0.046042460948228836,
        0.005653957836329937,
        0.0017847100971266627,
        0.00679215881973505,
        -0.061276376247406006,
        -0.027607552707195282,
        -0.0646357536315918,
        0.057862065732479095,
        0.022104015573859215,
        -0.017942342907190323,
        0.029558880254626274,
        0.05826365947723389,
        0.04956049099564552,
        0.05163704603910446,
        -0.01823287457227707,
        0.04491201043128967,
        -0.011966400779783726,
        0.03940308839082718,
        0.06355275958776474,
        -0.06277324259281158,
        0.05970717594027519,
        0.02574363350868225,
        -0.06013333797454834,
        0.06015747785568237,
        0.039267636835575104,
        0.044750723987817764,
        -0.029543578624725342,
        0.032423242926597595,
        0.05128413066267967,
        -0.042486321181058884,
        -0.04304422810673714,
        0.04187333583831787,
        0.022078411653637886,
        -0.03971150889992714,
        0.006653991527855396,
        0.04017742723226547,
        0.015982486307621002,
        0.030761029571294785,
        0.04593336954712868,
        -0.059106629341840744,
        -0.006221907213330269,
        -0.02275644615292549,
        0.04291537031531334,
        -0.055429838597774506,
        -0.0097412820905447,
        -0.03314569219946861,
        0.021453920751810074,
        0.051413822919130325,
        0.058258458971977234,
        -0.05263513699173927,
        0.04821394011378288,
        0.02442638762295246,
        0.04461531341075897,
        -0.0487527996301651,
        0.025850921869277954,
        -0.05701940134167671,
        -0.029687486588954926,
        -0.060516003519296646,
        0.0627337321639061,
        0.006172141060233116,
        0.017653150483965874,
        0.04983575642108917,
        -0.0179730337113142,
        0.054941195994615555,
        -0.04473558068275452,
        0.05617939308285713,
        -0.06081775948405266,
        -0.030179396271705627,
        0.002275527687743306,
        0.046830493956804276,
        0.0491165816783905,
        0.06123080849647522,
        -0.06108780577778816,
        0.059205684810876846,
        0.040800876915454865,
        0.014151415787637234,
        -0.02970810793340206,
        -0.02799389511346817,
        0.015680454671382904,
        0.010552269406616688,
        -0.05626656487584114,
        -0.03473656624555588,
        0.0027939851861447096,
        -0.06121973320841789,
        0.05087560415267944,
        -0.03865920379757881,
        -0.02863728441298008,
        -0.008356879465281963,
        0.03782270848751068,
        -0.048477787524461746,
        0.058376163244247437,
        -0.03970968350768089,
        -0.011561461724340916,
        -0.060656141489744186,
        -0.008253157138824463,
        -0.015102853067219257,
        0.05708184838294983,
        0.04399282857775688,
        0.04195905104279518,
        0.034359175711870193,
        -0.03961256518959999,
        0.035256970673799515,
        0.06331897526979446,
        0.05230886489152908,
        0.01335266511887312,
        -0.06378432363271713,
        -0.06470756232738495,
        0.05534648895263672,
        0.05041040852665901,
        -0.05005117505788803,
        -0.04030097648501396,
        -0.058010734617710114,
        -0.03878224268555641,
        0.02291174791753292,
        -0.05931665375828743,
        -0.023923657834529877,
        0.006577994674444199,
        0.008466687053442001,
        -0.0318068265914917,
        0.06264771521091461,
        0.04324278235435486,
        0.052651818841695786,
        0.05459729954600334,
        -0.06255368143320084,
        -0.016105065122246742,
        0.04642018303275108,
        0.03813427314162254,
        -0.058163680136203766,
        -0.03838903829455376,
        0.03600152209401131,
        0.005887545645236969,
        -0.059749070554971695,
        0.04873548820614815,
        -0.003391312900930643,
        -0.053772926330566406,
        -0.05540526285767555,
        0.06390117853879929,
        -0.02859537862241268,
        -0.0044388547539711,
        -0.05963088199496269,
        -0.04613108187913895,
        -0.018587281927466393,
        0.02755548804998398,
        0.05734030902385712,
        0.046087563037872314,
        -0.05019128695130348,
        0.01399077195674181,
        -0.0573846660554409,
        0.015620188787579536,
        0.0037749854382127523,
        -0.060040876269340515,
        -0.04581918939948082,
        0.03977289795875549,
        -0.05549998953938484,
        0.0624116025865078,
        -0.009860643185675144,
        -0.05569145828485489,
        -0.022990329191088676,
        -0.015796683728694916,
        -0.06167969852685928,
        -0.03965957090258598,
        0.007748656440526247,
        0.02094399183988571,
        0.0029523090925067663,
        0.06126605346798897,
        0.05345970392227173,
        0.045754317194223404,
        0.06190299615263939,
        0.06425245106220245,
        -0.027998004108667374,
        0.06249687820672989,
        -0.03704480081796646,
        0.060022953897714615,
        0.061027709394693375,
        -0.057442668825387955,
        0.04193287715315819,
        0.012958240695297718,
        0.05017644166946411,
        0.024178415536880493,
        -0.06268507987260818,
        0.026670368388295174,
        -0.03347057104110718,
        0.04963470995426178,
        0.05703640356659889,
        0.03498658910393715,
        -0.04957651346921921,
        -0.05091039836406708,
        0.03926080837845802,
        0.008481040596961975,
        0.06381970643997192,
        -0.0573028065264225,
        0.04623866826295853,
        -0.06075025722384453,
        -0.060811638832092285,
        0.0311399195343256,
        0.051212016493082047,
        0.003104135859757662,
        0.002984969411045313,
        -0.0615159273147583,
        -0.026740562170743942,
        0.06121532991528511,
        0.02479889988899231,
        -0.054168395698070526,
        -0.05223535746335983,
        -0.03996168449521065,
        -0.05599966272711754,
        -0.04692957177758217,
        0.02911679446697235,
        0.05973212420940399,
        0.05068081244826317,
        0.0618293322622776,
        0.05260251834988594,
        -0.04765328764915466,
        -0.0017046182183548808,
        -0.05912639945745468,
        0.026716366410255432,
        0.04815730080008507,
        0.013172384351491928,
        -0.030756374821066856,
        0.01670265756547451,
        -0.02106894738972187,
        0.05579989030957222,
        0.055407457053661346,
        0.05409236252307892,
        -0.062201470136642456,
        -0.045184146612882614,
        -0.03905438631772995,
        0.028064778074622154,
        -0.0007326499908231199,
        -0.04801442101597786,
        0.05315599963068962,
        0.011240489780902863,
        0.01955263875424862,
        -0.06344646215438843,
        -0.008976669982075691,
        0.05501389130949974
    ],
    [
        -0.014298785477876663,
        0.02416432835161686,
        0.05183805525302887,
        0.02623702958226204,
        -0.00023771749692969024,
        0.027507200837135315,
        -0.041645508259534836,
        -0.06406283378601074,
        0.06399994343519211,
        0.05760424584150314,
        0.045657020062208176,
        0.000559064676053822,
        -0.05665985122323036,
        -0.0027475387323647738,
        -0.05618374049663544,
        0.041936490684747696,
        0.021357020363211632,
        0.06179220974445343,
        -0.03850739076733589,
        -0.0489468090236187,
        0.06316331028938293,
        0.0599256306886673,
        -0.012650158256292343,
        0.013362504541873932,
        0.05915433168411255,
        0.05785807594656944,
        0.04169177636504173,
        -0.05106718838214874,
        -0.006002140697091818,
        0.000304205110296607,
        0.05441127344965935,
        -0.004607744049280882,
        -0.03579720854759216,
        -0.06241864711046219,
        0.050139445811510086,
        0.04283122718334198,
        -0.06193175166845322,
        -0.03593747690320015,
        0.020228419452905655,
        -0.03327328339219093,
        0.014131193049252033,
        0.015521837398409843,
        -0.0006223300588317215,
        -0.06216082721948624,
        -0.01903148740530014,
        0.06336382031440735,
        0.004270878154784441,
        0.009471273981034756,
        0.060495998710393906,
        0.03392767533659935,
        0.04735192283987999,
        0.06377548724412918,
        -0.05570841580629349,
        0.002759292023256421,
        0.05105507746338844,
        -0.012947755865752697,
        0.030752794817090034,
        0.00038425365346483886,
        0.008857791312038898,
        -0.05126793682575226,
        -0.06385817378759384,
        0.02774643898010254,
        -0.05390194430947304,
        0.06383541971445084,
        -0.054123662412166595,
        -0.05338168889284134,
        -0.042213764041662216,
        0.0017260626191273332,
        -0.057243071496486664,
        0.032598383724689484,
        -0.056127533316612244,
        -0.06509624421596527,
        0.06469191610813141,
        0.02552749775350094,
        0.034559134393930435,
        -0.018014483153820038,
        0.013737209141254425,
        0.06530033051967621,
        -0.000828869640827179,
        -0.007401696406304836,
        0.003980876412242651,
        -0.013930014334619045,
        -0.010577530600130558,
        0.05068020150065422,
        0.05205033719539642,
        0.05447698011994362,
        -0.01257096603512764,
        -0.05756085738539696,
        0.052041828632354736,
        0.049210552126169205,
        0.05297229066491127,
        0.01295207068324089,
        -0.06385073065757751,
        -0.041332658380270004,
        -0.03241848945617676,
        0.06345115602016449,
        -0.0037538576871156693,
        -0.05853455513715744,
        0.060940276831388474,
        0.021276600658893585,
        -0.06483323127031326,
        0.01920187845826149,
        0.008027276024222374,
        0.021303439512848854,
        0.057935334742069244,
        -0.020055823028087616,
        -0.028518658131361008,
        0.05734609067440033,
        -0.0529545433819294,
        0.054373595863580704,
        -0.060574837028980255,
        0.04730392247438431,
        -0.05956846475601196,
        -0.0022873247507959604,
        -0.017119303345680237,
        -0.02014007791876793,
        0.008527728728950024,
        -0.04582013562321663,
        0.06526044756174088,
        0.021601853892207146,
        -0.02495444566011429,
        0.06271931529045105,
        -0.018731407821178436,
        -0.04416726902127266,
        -0.053729452192783356,
        -0.05752430111169815,
        -0.04942925274372101,
        -0.05521652102470398,
        0.054761797189712524,
        0.04562903940677643,
        0.04503713175654411,
        -0.0569172278046608,
        -0.04767291620373726,
        -0.03548841550946236,
        -0.060014184564352036,
        -0.060497719794511795,
        0.033373620361089706,
        0.057253625243902206,
        -0.06401092559099197,
        0.0484941266477108,
        0.06338056176900864,
        -0.04944761469960213,
        0.05528973415493965,
        -0.01656245067715645,
        0.05968215689063072,
        0.04469653591513634,
        0.04774991422891617,
        0.0030761228408664465,
        0.03528476506471634,
        0.057572927325963974,
        0.06384000182151794,
        -0.06426290422677994,
        0.006522987969219685,
        -0.06352429836988449,
        0.05944191664457321,
        -0.06315995752811432,
        -0.01359653938561678,
        -0.002705321414396167,
        0.05098523572087288,
        0.023953445255756378,
        0.041252147406339645,
        0.05361587554216385,
        0.05689442902803421,
        0.047716930508613586,
        0.058713845908641815,
        0.0021933598909527063,
        -0.004193350672721863,
        0.05340702086687088,
        0.031211957335472107,
        0.03168398514389992,
        -0.049116190522909164,
        0.05637297406792641,
        -0.060630571097135544,
        -0.049755439162254333,
        0.02497502788901329,
        0.05940461531281471,
        -0.06368191540241241,
        -0.0065748910419642925,
        0.0354166105389595,
        -0.04290882498025894,
        -0.013059294782578945,
        0.048644017428159714,
        0.038925085216760635,
        0.048763010650873184,
        0.019738895818591118,
        -0.002630483591929078,
        -0.04885859042406082,
        0.061207570135593414,
        -0.0508221797645092,
        -0.05710047855973244,
        -0.0535692498087883,
        -0.000001047136493070866,
        -0.04767617583274841,
        0.0065239956602454185,
        -0.026073791086673737,
        0.040705036371946335,
        -0.000788038014434278,
        -0.0147878872230649,
        -0.050667520612478256,
        0.0010241339914500713,
        0.0471494160592556,
        0.005534538067877293,
        0.04070906341075897,
        -0.012893921695649624,
        -0.059037066996097565,
        0.027800804004073143,
        0.04846884310245514,
        0.050676241517066956,
        -0.04957728460431099,
        0.042809728533029556,
        -0.0598040409386158,
        0.057092420756816864,
        0.05388088524341583,
        0.0382750928401947,
        -0.03664720430970192,
        0.01972903497517109,
        0.047872815281152725,
        -0.026225853711366653,
        0.06421592831611633,
        -0.002197094028815627,
        -0.026992326602339745,
        0.05874757096171379,
        -0.05735398828983307,
        -0.010249842889606953,
        0.06415578722953796,
        -0.030678236857056618,
        0.023578496649861336,
        0.010128913447260857,
        0.02674875408411026,
        -0.058651991188526154,
        0.008510468527674675,
        -0.05370432510972023,
        -0.00898813083767891,
        0.048242948949337006,
        -0.04182666540145874,
        0.06516724079847336,
        0.0629633292555809,
        -0.015821538865566254,
        0.02054067887365818,
        0.06519381701946259,
        -0.03952251374721527,
        0.06417174637317657,
        -0.06518231332302094,
        0.0561162568628788,
        0.02313898503780365,
        -0.009724787436425686,
        -0.05929131805896759,
        -0.024620508775115013,
        -0.010092529468238354,
        -0.029957149177789688,
        -0.05216770991683006,
        -0.06359328329563141,
        0.0011848746798932552,
        -0.06222861632704735,
        -0.05971517041325569,
        0.06308192759752274,
        -0.04644761607050896,
        -0.06015263497829437,
        0.001713267294690013,
        -0.005046595819294453,
        0.055870771408081055,
        -0.06015341356396675,
        -0.02321133390069008,
        0.056938767433166504,
        0.04510383680462837,
        0.05940007418394089,
        -0.05778587609529495,
        -0.03456936031579971,
        -0.054824285209178925,
        0.04163993149995804,
        0.05641566962003708,
        -0.03814181312918663,
        0.0647946149110794,
        -0.05011937394738197,
        -0.06438743323087692,
        0.032669514417648315,
        -0.04267919808626175,
        0.007431198842823505,
        -0.02226046659052372,
        -0.015350548550486565,
        -0.06180128455162048,
        -0.054203376173973083,
        -0.065103679895401,
        0.058265890926122665,
        0.005368038080632687,
        -0.0011055371724069118,
        0.018216995522379875,
        0.059652507305145264,
        0.05302301421761513,
        0.056490130722522736,
        -0.025835733860731125,
        0.03892502933740616,
        -0.019321259111166,
        0.044311173260211945,
        0.0633939653635025,
        -0.0625452771782875,
        0.05480654910206795,
        0.016371529549360275,
        -0.0600704699754715,
        0.06157878786325455,
        0.028824010863900185,
        0.052045054733753204,
        -0.02382856234908104,
        0.044427115470170975,
        0.028905322775244713,
        -0.05065251141786575,
        -0.037648722529411316,
        0.054464224725961685,
        0.03049345314502716,
        -0.06119384616613388,
        0.013994655571877956,
        0.042150579392910004,
        0.015590628609061241,
        0.040419869124889374,
        0.054183389991521835,
        -0.05959281697869301,
        0.0035873986780643463,
        -0.014345057308673859,
        0.04819487780332565,
        -0.04939114302396774,
        -0.008168602362275124,
        -0.030340049415826797,
        0.02152385748922825,
        0.06004277244210243,
        0.06053830310702324,
        -0.05773225054144859,
        0.03273562341928482,
        -0.00012181724741822109,
        0.0325913168489933,
        -0.047518040984869,
        0.03716203570365906,
        -0.048097312450408936,
        -0.00441544596105814,
        -0.05471226945519447,
        0.06289474666118622,
        -0.011682680808007717,
        -0.001439909334294498,
        0.05663716793060303,
        -0.019707052037119865,
        0.047614168375730515,
        -0.03484637290239334,
        0.06059465557336807,
        -0.05627542361617088,
        -0.03966998681426048,
        0.01440852228552103,
        0.05073860287666321,
        0.05539214611053467,
        0.061315663158893585,
        -0.060303717851638794,
        0.059573233127593994,
        0.04949701577425003,
        0.01047318335622549,
        -0.026995327323675156,
        -0.007005604449659586,
        -0.00522856879979372,
        0.034566882997751236,
        -0.04207620397210121,
        -0.03462245687842369,
        -0.016641557216644287,
        -0.0631212666630745,
        0.05453331768512726,
        -0.04378392919898033,
        -0.005747324321419001,
        0.013365993276238441,
        0.024530671536922455,
        -0.038913071155548096,
        0.057978611439466476,
        -0.04455671086907387,
        0.008795877918601036,
        -0.05850657448172569,
        -0.003271227004006505,
        -0.016241300851106644,
        0.05967015400528908,
        0.030298007652163506,
        0.04230661317706108,
        0.04580548778176308,
        -0.042621660977602005,
        0.02206580899655819,
        0.06314392387866974,
        0.05790554732084274,
        0.02860519289970398,
        -0.0645621120929718,
        -0.06469323486089706,
        0.057757481932640076,
        0.04319937899708748,
        -0.04633476585149765,
        -0.02749587967991829,
        -0.05936386063694954,
        -0.02858174778521061,
        0.024622036144137383,
        -0.06216752901673317,
        -0.010832145810127258,
        0.005385172553360462,
        -0.015757810324430466,
        -0.021445436403155327,
        0.06345419585704803,
        0.045173779129981995,
        0.05866625905036926,
        0.05694110319018364,
        -0.06271665543317795,
        -0.025164375081658363,
        0.047885116189718246,
        0.024902457371354103,
        -0.05294220149517059,
        -0.052879754453897476,
        0.028704997152090073,
        0.004828777629882097,
        -0.058513227850198746,
        0.04611635580658913,
        -0.009789185598492622,
        -0.048590611666440964,
        -0.059774916619062424,
        0.06435605883598328,
        -0.0020388371776789427,
        0.013461267575621605,
        -0.06070757284760475,
        -0.04967781901359558,
        0.04496139660477638,
        0.041588447988033295,
        0.06316947937011719,
        0.05018313229084015,
        -0.048151981085538864,
        0.011396883986890316,
        -0.05799438804388046,
        0.038957469165325165,
        -0.027022074908018112,
        -0.05504840239882469,
        -0.051618337631225586,
        0.0178532712161541,
        -0.05654053017497063,
        0.06377462297677994,
        -0.006668680347502232,
        -0.05999946594238281,
        -0.022969547659158707,
        -0.00063334871083498,
        -0.06172751262784004,
        -0.05117252096533775,
        -0.015172535553574562,
        0.01093650795519352,
        0.01112128235399723,
        0.06071538105607033,
        0.042355749756097794,
        0.054883718490600586,
        0.06307128071784973,
        0.05782720074057579,
        -0.030153824016451836,
        0.06131727248430252,
        -0.03252314776182175,
        0.06004742160439491,
        0.0500708669424057,
        -0.05251563340425491,
        0.041326381266117096,
        0.023229161277413368,
        0.045321401208639145,
        0.018621616065502167,
        -0.06295070052146912,
        0.008929276838898659,
        -0.0321861170232296,
        0.03619753196835518,
        0.05698820948600769,
        -0.02529001608490944,
        -0.04523535817861557,
        -0.057522956281900406,
        0.003916320390999317,
        0.010646432638168335,
        0.0642935261130333,
        -0.051979292184114456,
        0.038965944200754166,
        -0.061913613229990005,
        -0.05970318987965584,
        0.04750401899218559,
        0.06370868533849716,
        -0.0014870228478685021,
        0.013240901753306389,
        -0.05984273925423622,
        -0.012736121192574501,
        0.05874072015285492,
        0.01665865257382393,
        -0.04894714802503586,
        -0.053406551480293274,
        -0.02590871974825859,
        -0.05637476220726967,
        -0.047041673213243484,
        0.02260441891849041,
        0.04338778182864189,
        0.047011371701955795,
        0.05287712812423706,
        0.0564756877720356,
        -0.05242608115077019,
        -0.027874628081917763,
        -0.062280986458063126,
        0.04218381643295288,
        0.04886380583047867,
        0.006201833486557007,
        -0.02450638823211193,
        0.02503795363008976,
        -0.02108689583837986,
        0.05622263252735138,
        0.05510859191417694,
        0.059584423899650574,
        -0.05195190757513046,
        -0.04298274219036102,
        -0.00946709606796503,
        0.024434315040707588,
        -0.025030041113495827,
        -0.04291694611310959,
        0.05240673944354057,
        0.0018407782772555947,
        -0.004286383744329214,
        -0.06169885769486427,
        0.03665903955698013,
        0.06179209426045418
    ],
    [
        -0.012785441242158413,
        -0.021466543897986412,
        0.036100830882787704,
        -0.00877344235777855,
        0.0026019292417913675,
        0.0016964811366051435,
        0.03865687921643257,
        -0.05373852699995041,
        0.05018841102719307,
        0.029088493436574936,
        0.05439146235585213,
        -0.004347667563706636,
        -0.05447603017091751,
        -0.037935562431812286,
        -0.053802281618118286,
        0.04118637368083,
        -0.006036975421011448,
        0.04939945414662361,
        -0.006414478644728661,
        -0.024379853159189224,
        0.054643213748931885,
        0.03961554169654846,
        0.04245072975754738,
        0.02687646821141243,
        -0.048630185425281525,
        0.05273984745144844,
        -0.03157256916165352,
        0.022134283557534218,
        -0.054962702095508575,
        -0.029637020081281662,
        0.04420968145132065,
        0.04232737049460411,
        0.03970148414373398,
        -0.05483512952923775,
        0.04272830858826637,
        -0.0217111948877573,
        -0.054452575743198395,
        -0.02494928054511547,
        0.05484623834490776,
        -0.025803901255130768,
        0.023460838943719864,
        0.039602164179086685,
        0.02968282625079155,
        -0.05308178812265396,
        -0.041391611099243164,
        0.033791881054639816,
        0.04536356404423714,
        0.05487954616546631,
        0.05488070473074913,
        -0.045817770063877106,
        0.05096923187375069,
        0.0539691187441349,
        -0.03460555896162987,
        0.0034463503398001194,
        0.05348333343863487,
        0.032943326979875565,
        0.052796050906181335,
        -0.05086575075984001,
        -0.02326785959303379,
        -0.04912804439663887,
        -0.0525735504925251,
        0.028586216270923615,
        -0.054076239466667175,
        0.03646259009838104,
        0.03221815079450607,
        -0.05333300307393074,
        -0.05439436435699463,
        0.03361205756664276,
        0.039299774914979935,
        0.0470854826271534,
        -0.0356280542910099,
        -0.054695867002010345,
        0.05503567308187485,
        0.05472998693585396,
        0.047664523124694824,
        -0.009773788042366505,
        0.05473829805850983,
        0.055042564868927,
        0.04813745617866516,
        -0.0174243226647377,
        -0.0375077910721302,
        -0.05405374616384506,
        -0.05441410467028618,
        0.05222311615943909,
        0.04774770513176918,
        0.052557770162820816,
        -0.04171926900744438,
        -0.05320768803358078,
        0.0178587194532156,
        0.054679229855537415,
        -0.0018831280758604407,
        -0.051149945706129074,
        -0.005476217716932297,
        -0.05157769098877907,
        -0.050011713057756424,
        0.05381309613585472,
        0.03774717450141907,
        -0.04398447275161743,
        0.03459655120968819,
        0.05365055799484253,
        -0.055026814341545105,
        0.008473992347717285,
        0.051526349037885666,
        0.05367311090230942,
        0.04865023121237755,
        0.024937862530350685,
        -0.052947014570236206,
        0.04926625266671181,
        -0.035401441156864166,
        0.011539098806679249,
        -0.05472330003976822,
        -0.052334558218717575,
        -0.029354657977819443,
        0.01843385025858879,
        0.042736779898405075,
        -0.03582989051938057,
        -0.05100136995315552,
        -0.04977038502693176,
        0.05499555170536041,
        -0.043137241154909134,
        -0.05115034803748131,
        -0.007051168009638786,
        -0.024316392838954926,
        -0.04583169147372246,
        -0.053917285054922104,
        -0.0382445864379406,
        -0.05188308656215668,
        -0.05355919525027275,
        0.04804065451025963,
        0.03718239441514015,
        0.048910435289144516,
        -0.0548175685107708,
        -0.05237705633044243,
        0.05113145709037781,
        -0.03212321549654007,
        -0.05169665440917015,
        0.05392543599009514,
        0.04000573977828026,
        -0.05318019166588783,
        0.054520491510629654,
        0.05095972120761871,
        -0.05164346471428871,
        0.050458021461963654,
        0.041629258543252945,
        0.02514433115720749,
        0.052261751145124435,
        -0.005773571319878101,
        0.008165884763002396,
        0.01394947711378336,
        0.05481753125786781,
        0.054006610065698624,
        -0.055012959986925125,
        -0.04929742589592934,
        -0.05434639751911163,
        0.015844842419028282,
        -0.05396261066198349,
        0.034982021898031235,
        0.04767224192619324,
        0.054510679095983505,
        -0.04127019643783569,
        0.027230463922023773,
        0.05198627710342407,
        0.05427413061261177,
        0.054332487285137177,
        0.054336100816726685,
        0.04990077763795853,
        -0.025336146354675293,
        0.02038397081196308,
        -0.03201756626367569,
        0.03375353291630745,
        -0.05397281423211098,
        0.04048416391015053,
        -0.05493636056780815,
        -0.0032332909759134054,
        -0.05420174449682236,
        -0.004648400470614433,
        -0.05319805070757866,
        0.025980092585086823,
        0.03801612928509712,
        -0.0482148751616478,
        -0.0448320135474205,
        0.04680854454636574,
        0.051217589527368546,
        0.0022762708831578493,
        0.03790595009922981,
        0.004014821257442236,
        -0.01753343641757965,
        0.05430413410067558,
        -0.0376817025244236,
        -0.05467909947037697,
        -0.05485322326421738,
        0.02754385396838188,
        -0.03189226984977722,
        -0.011184630915522575,
        -0.04981674626469612,
        0.02712307497859001,
        0.03453833982348442,
        0.023704687133431435,
        0.02991185523569584,
        0.04756933078169823,
        0.05367349833250046,
        0.04879307001829147,
        0.00653638131916523,
        -0.054622482508420944,
        0.0028863362967967987,
        0.031735144555568695,
        0.05436675623059273,
        0.05167137086391449,
        -0.02010154165327549,
        0.05046619847416878,
        -0.05101173743605614,
        0.052987560629844666,
        0.054072305560112,
        0.04943564534187317,
        -0.05480252951383591,
        -0.041180435568094254,
        0.053073037415742874,
        -0.042187076061964035,
        0.05455911532044411,
        -0.04933589696884155,
        -0.0033297345507889986,
        0.05423659831285477,
        -0.01178260613232851,
        -0.05273660644888878,
        0.055007290095090866,
        -0.04795972630381584,
        0.05432293936610222,
        -0.05473516881465912,
        0.03668581321835518,
        -0.05457646772265434,
        -0.04325644671916962,
        -0.03792322427034378,
        -0.050742316991090775,
        0.05309241637587547,
        -0.05277540534734726,
        0.054753392934799194,
        0.05254868045449257,
        0.018365396186709404,
        -0.005164031405001879,
        0.05504252761602402,
        0.05222657322883606,
        0.052076373249292374,
        -0.054979827255010605,
        -0.008406017906963825,
        0.04059548303484917,
        -0.045540522783994675,
        0.045012712478637695,
        -0.03583046793937683,
        0.05146091431379318,
        -0.054615482687950134,
        0.012415158562362194,
        -0.054564736783504486,
        -0.018625136464834213,
        -0.05312597006559372,
        -0.016626257449388504,
        0.054723914712667465,
        -0.04918356239795685,
        -0.05022716894745827,
        0.01951144076883793,
        -0.02482803910970688,
        0.045788705348968506,
        -0.04795125126838684,
        -0.03202362358570099,
        0.0549389086663723,
        0.0512956827878952,
        0.054963599890470505,
        0.02707575634121895,
        0.002727433806285262,
        0.0037393514066934586,
        0.042030345648527145,
        0.05442260950803757,
        -0.04214360937476158,
        0.054636672139167786,
        -0.05336442217230797,
        -0.05434450879693031,
        0.019051987677812576,
        -0.04976033419370651,
        -0.000383919250452891,
        0.04906560108065605,
        -0.03532462567090988,
        -0.054892126470804214,
        0.026475822553038597,
        -0.055017679929733276,
        -0.007104033138602972,
        0.014890131540596485,
        0.053866900503635406,
        0.008731458336114883,
        0.048714954406023026,
        0.029299506917595863,
        0.04165409877896309,
        -0.03768765181303024,
        0.054315295070409775,
        0.04342448338866234,
        0.03518875315785408,
        0.05471862852573395,
        -0.05477489158511162,
        0.05473126098513603,
        -0.0543505884706974,
        -0.0501137301325798,
        0.05428874120116234,
        0.05042383447289467,
        0.05009964853525162,
        0.048603542149066925,
        0.03911919891834259,
        0.05130899325013161,
        -0.04372067376971245,
        -0.006872096564620733,
        0.05423314869403839,
        0.054127246141433716,
        -0.05009263753890991,
        0.0351414754986763,
        0.05438954010605812,
        0.054105523973703384,
        0.05480671674013138,
        0.036077599972486496,
        -0.0529618039727211,
        0.045128148049116135,
        0.05203069746494293,
        0.05436945706605911,
        -0.046220939606428146,
        0.01555105485022068,
        -0.01863294467329979,
        0.05483498051762581,
        0.054619744420051575,
        0.05222490057349205,
        -0.0030610959511250257,
        0.052930474281311035,
        -0.04554735869169235,
        -0.05074632912874222,
        -0.054786305874586105,
        0.04413175955414772,
        -0.0528549924492836,
        -0.03810938820242882,
        -0.05379563570022583,
        0.054623231291770935,
        0.01583235338330269,
        -0.021966492757201195,
        0.00908353365957737,
        -0.04928477853536606,
        0.020392928272485733,
        -0.03445613756775856,
        0.04962228611111641,
        -0.01860705576837063,
        -0.04571264237165451,
        0.038248252123594284,
        0.007133333943784237,
        0.05449619144201279,
        0.05480116978287697,
        -0.0542706735432148,
        0.009776970371603966,
        0.04486920312047005,
        0.04529281705617905,
        0.011619205586612225,
        -0.05372126027941704,
        0.03493199497461319,
        -0.05264298617839813,
        -0.03827483952045441,
        -0.04462084174156189,
        -0.05378393828868866,
        -0.04831981286406517,
        0.05146811902523041,
        0.048497091978788376,
        -0.05469374731183052,
        -0.04997887834906578,
        -0.004309448879212141,
        -0.05020654946565628,
        0.05466805025935173,
        -0.05417686700820923,
        -0.038592200726270676,
        -0.05205835402011871,
        -0.048955801874399185,
        -0.04363154247403145,
        -0.012420054525136948,
        -0.00917999166995287,
        0.05154596269130707,
        0.053722165524959564,
        0.04360273480415344,
        -0.04180619493126869,
        0.054914847016334534,
        0.05145490914583206,
        0.02259020321071148,
        -0.05487964302301407,
        -0.05265763774514198,
        0.05360206216573715,
        0.05079461261630058,
        -0.05498650297522545,
        -0.05045325681567192,
        -0.050126127898693085,
        -0.05274173989892006,
        -0.048886265605688095,
        -0.03879084810614586,
        -0.0380767285823822,
        -0.021388890221714973,
        -0.04060042276978493,
        0.03820428624749184,
        0.05465603247284889,
        0.05300135165452957,
        0.030237799510359764,
        -0.042781103402376175,
        -0.054517962038517,
        -0.020799899473786354,
        0.05450225621461868,
        -0.038535431027412415,
        -0.041788581758737564,
        -0.0419946014881134,
        0.05292089283466339,
        0.021001355722546577,
        -0.05358772352337837,
        0.054683979600667953,
        -0.04062860086560249,
        -0.054274484515190125,
        -0.05397197976708412,
        0.05167192593216896,
        -0.021748380735516548,
        0.050061341375112534,
        -0.055005621165037155,
        -0.05014646053314209,
        -0.021772384643554688,
        0.05077963322401047,
        0.05504201725125313,
        0.054837919771671295,
        -0.05379926785826683,
        0.018321074545383453,
        -0.054646167904138565,
        -0.04263455793261528,
        -0.01773190312087536,
        -0.04509799927473068,
        -0.04929507523775101,
        -0.02716943435370922,
        -0.054950255900621414,
        0.05386851727962494,
        -0.03779732435941696,
        0.04919673502445221,
        -0.02675098180770874,
        -0.05253034830093384,
        -0.05447833612561226,
        -0.054762665182352066,
        0.008868944831192493,
        -0.04252919554710388,
        -0.031093809753656387,
        0.03765096515417099,
        0.05228349566459656,
        0.054739560931921005,
        0.05497816950082779,
        -0.05393703281879425,
        0.028583833947777748,
        0.05234161764383316,
        -0.04013115167617798,
        0.04968083277344704,
        0.018463490530848503,
        -0.052148591727018356,
        0.05120973661541939,
        0.031312473118305206,
        0.023192092776298523,
        0.03770577162504196,
        -0.05481286719441414,
        0.03563820943236351,
        -0.053227748721838,
        0.052234258502721786,
        0.05502090975642204,
        0.041068390011787415,
        -0.05405017361044884,
        -0.04954436421394348,
        -0.04716996103525162,
        0.05434556305408478,
        0.05502866581082344,
        -0.054187335073947906,
        0.05397411808371544,
        -0.03709918633103371,
        -0.053182777017354965,
        0.042279329150915146,
        0.002883434295654297,
        0.054127052426338196,
        0.051461972296237946,
        -0.0024706050753593445,
        0.03560131788253784,
        0.053213104605674744,
        0.04688146337866783,
        -0.054578449577093124,
        -0.015318221412599087,
        -0.04582250863313675,
        0.02469729073345661,
        0.030804535374045372,
        0.014506020583212376,
        0.054242849349975586,
        0.04501637816429138,
        0.05051284283399582,
        0.03284257650375366,
        -0.05427880585193634,
        0.05301913246512413,
        -0.048637427389621735,
        0.014301784336566925,
        0.05406186357140541,
        0.04190615564584732,
        -0.02110920287668705,
        0.041391149163246155,
        -0.0547216422855854,
        -0.050240349024534225,
        0.02121409960091114,
        0.044192250818014145,
        -0.05326098948717117,
        -0.05268913879990578,
        -0.05034532770514488,
        0.047160327434539795,
        0.04207883030176163,
        0.05212201178073883,
        0.05503438785672188,
        -0.04414879158139229,
        0.053882211446762085,
        -0.05500498041510582,
        0.05245891213417053,
        0.051766905933618546
    ],
    [
        -0.005578073672950268,
        0.011450456455349922,
        -0.026837196201086044,
        0.032981835305690765,
        -0.04437106102705002,
        -0.05352067947387695,
        0.012128889560699463,
        -0.018449945375323296,
        -0.014836253598332405,
        0.005633542314171791,
        -0.051218144595623016,
        0.04539034888148308,
        -0.056601058691740036,
        -0.013858243823051453,
        -0.05639040097594261,
        -0.050890788435935974,
        -0.03553072735667229,
        0.04990950599312782,
        0.003056130139157176,
        -0.04991238936781883,
        0.05580157786607742,
        -0.04990740492939949,
        0.03766534477472305,
        0.04898649826645851,
        -0.05300358682870865,
        0.05520821735262871,
        -0.025952082127332687,
        0.008623622357845306,
        -0.042153552174568176,
        0.022752670571208,
        0.005363913252949715,
        -0.05530489608645439,
        0.05032860115170479,
        -0.05496172606945038,
        0.054305948317050934,
        -0.05681866034865379,
        -0.055607475340366364,
        -0.05021335929632187,
        0.05666846036911011,
        -0.043581441044807434,
        -0.041963864117860794,
        0.029421191662549973,
        0.056730784475803375,
        -0.05511244758963585,
        0.040563326328992844,
        -0.004602342378348112,
        0.010289730504155159,
        0.05508899688720703,
        0.05688302591443062,
        -0.03130118548870087,
        0.056887175887823105,
        0.05584583431482315,
        0.042387329041957855,
        0.018706103786826134,
        -0.028596317395567894,
        -0.03919459506869316,
        -0.02267719805240631,
        0.0031228861771523952,
        -0.056852955371141434,
        -0.05598361790180206,
        -0.05642016604542732,
        -0.02121519297361374,
        -0.05658862739801407,
        -0.02717459946870804,
        0.048569489270448685,
        0.054274216294288635,
        -0.05675205960869789,
        -0.04377903416752815,
        0.003564119338989258,
        -0.00016390271775890142,
        0.028229359537363052,
        -0.05681363493204117,
        0.05633910745382309,
        0.041537508368492126,
        0.05431324243545532,
        -0.055511053651571274,
        0.040334511548280716,
        0.05689828470349312,
        -0.020602308213710785,
        -0.03170877695083618,
        -0.037047967314720154,
        -0.05350424349308014,
        -0.05094362422823906,
        0.05225144326686859,
        0.05468463897705078,
        0.0506785623729229,
        -0.018436411395668983,
        -0.04760141298174858,
        -0.05296500027179718,
        -0.003023921512067318,
        -0.055912502110004425,
        0.0075640445575118065,
        0.03186693415045738,
        -0.020857904106378555,
        -0.03329748660326004,
        0.0351652093231678,
        -0.01449587196111679,
        -0.05680955946445465,
        0.055884189903736115,
        0.0333564318716526,
        -0.05667801573872566,
        0.05362197011709213,
        0.05655957758426666,
        0.05639462545514107,
        -0.0004267307522241026,
        0.046962641179561615,
        0.05015583336353302,
        0.04638074338436127,
        -0.056797005236148834,
        0.053693730384111404,
        0.03534374758601189,
        -0.05659380927681923,
        -0.05549725890159607,
        0.0006238638306967914,
        0.05220456421375275,
        0.013545802794396877,
        0.033578597009181976,
        -0.0561424195766449,
        0.03793922811746597,
        -0.05203673988580704,
        0.0494801327586174,
        0.04951755702495575,
        0.04021938517689705,
        -0.04807307943701744,
        -0.052691586315631866,
        0.0338851623237133,
        -0.047946907579898834,
        -0.012742839753627777,
        0.05589378625154495,
        0.049461908638477325,
        -0.035072073340415955,
        -0.05677612125873566,
        -0.05686883628368378,
        0.054556090384721756,
        -0.05036550760269165,
        -0.05602332949638367,
        0.056892670691013336,
        0.025224989280104637,
        -0.03418818861246109,
        0.05357079580426216,
        -0.05607237666845322,
        0.011171205900609493,
        0.055204980075359344,
        0.04826116934418678,
        0.0038734981790184975,
        -0.04617534205317497,
        -0.036940596997737885,
        0.056731581687927246,
        -0.05321459099650383,
        -0.04942416772246361,
        0.05541742220520973,
        -0.0568363256752491,
        -0.05682753771543503,
        -0.05571895092725754,
        0.051719456911087036,
        -0.00948062352836132,
        0.01314662117511034,
        0.02109597437083721,
        -0.03647822514176369,
        0.03472461551427841,
        -0.055287133902311325,
        0.03905963525176048,
        0.055465031415224075,
        0.025071391835808754,
        0.03848697990179062,
        0.005759627558290958,
        0.049436651170253754,
        -0.035682350397109985,
        0.012507705017924309,
        0.026989780366420746,
        -0.03878422826528549,
        -0.019507069140672684,
        -0.011995993554592133,
        -0.01870114728808403,
        -0.04523475840687752,
        0.014909693039953709,
        0.05253760889172554,
        -0.04855389520525932,
        0.056306205689907074,
        -0.01534423790872097,
        -0.01941598951816559,
        -0.03284132480621338,
        0.05357041582465172,
        -0.0545010082423687,
        0.03466922417283058,
        0.04004211723804474,
        -0.0314711295068264,
        0.056162767112255096,
        -0.017233019694685936,
        -0.03367726504802704,
        0.029190819710493088,
        0.04914262518286705,
        -0.035177625715732574,
        0.04207194969058037,
        -0.0561579205095768,
        0.0016629229066893458,
        0.0515335313975811,
        0.0538642480969429,
        0.05329306423664093,
        0.008855702355504036,
        0.052551086992025375,
        0.03364085406064987,
        -0.006923574022948742,
        -0.05679839104413986,
        0.054182909429073334,
        0.0023157454561442137,
        0.05628640577197075,
        0.056174151599407196,
        -0.056844256818294525,
        0.05586881935596466,
        -0.04383392259478569,
        -0.04358186572790146,
        -0.027374520897865295,
        0.014704550616443157,
        -0.015881922096014023,
        -0.05578582361340523,
        0.050553154200315475,
        0.05420223996043205,
        0.056862011551856995,
        0.0530814528465271,
        0.017060987651348114,
        0.046258293092250824,
        0.02051454782485962,
        0.004219575319439173,
        0.05613747239112854,
        0.017011160030961037,
        0.025662699714303017,
        -0.045915864408016205,
        0.04925781115889549,
        -0.056832872331142426,
        -0.021038267761468887,
        -0.04220827668905258,
        -0.02251330018043518,
        0.044392507523298264,
        0.03879733756184578,
        0.05650530010461807,
        0.05647401884198189,
        0.013421854935586452,
        0.04420933499932289,
        0.05682075396180153,
        0.008272325620055199,
        0.05224914476275444,
        -0.05634242296218872,
        -0.004626440349966288,
        -0.04280230402946472,
        -0.05222826078534126,
        0.05680745095014572,
        -0.011007820256054401,
        -0.025809599086642265,
        0.034473106265068054,
        -0.0568053312599659,
        -0.05400228127837181,
        0.05456260219216347,
        -0.052299611270427704,
        0.04105082154273987,
        0.03582127392292023,
        0.05681050568819046,
        -0.04662163928151131,
        0.05644722655415535,
        0.04853822663426399,
        -0.0567907840013504,
        0.031480032950639725,
        -0.03563011810183525,
        0.03132936358451843,
        0.05630529299378395,
        0.05678484961390495,
        0.024799035862088203,
        -0.05289693549275398,
        0.03338168188929558,
        -0.052250370383262634,
        0.030828924849629402,
        -0.05415267497301102,
        0.02513454295694828,
        -0.04382294788956642,
        -0.044751618057489395,
        0.01823725737631321,
        -0.043838195502758026,
        0.04925999417901039,
        0.012827913276851177,
        0.02826722152531147,
        -0.05658498406410217,
        0.0323394350707531,
        -0.053825195878744125,
        -0.052847862243652344,
        0.005882494617253542,
        -0.04049728438258171,
        -0.020058652386069298,
        0.055027589201927185,
        -0.04461176320910454,
        0.056197743862867355,
        -0.04787973314523697,
        0.0479436032474041,
        -0.05527012050151825,
        -0.04961839318275452,
        0.05685801059007645,
        -0.056579988449811935,
        0.05678541958332062,
        0.03270726650953293,
        -0.04049389809370041,
        0.055323321372270584,
        -0.009161642752587795,
        -0.03316900134086609,
        -0.04718945920467377,
        0.05448339506983757,
        0.039882905781269073,
        -0.052969709038734436,
        -0.003215359291061759,
        0.052688904106616974,
        -0.02317248098552227,
        -0.04125869274139404,
        0.05605759099125862,
        0.0508560873568058,
        0.05628913268446922,
        0.05680658295750618,
        0.054804686456918716,
        -0.05660610273480415,
        0.02519756741821766,
        0.0562572106719017,
        0.036563728004693985,
        -0.046947117894887924,
        -0.005169031675904989,
        0.05280954763293266,
        0.04639251530170441,
        0.05582636594772339,
        0.05674334615468979,
        -0.05330704525113106,
        0.041631873697042465,
        -0.05641745403409004,
        -0.052641067653894424,
        -0.05569868162274361,
        0.04317662864923477,
        -0.05492869392037392,
        0.03947102278470993,
        -0.05525599792599678,
        0.05274999886751175,
        0.037871476262807846,
        -0.025681566447019577,
        0.02048647217452526,
        -0.000256265455391258,
        0.05648999661207199,
        -0.05635225772857666,
        0.001853891066275537,
        0.028671232983469963,
        -0.049223992973566055,
        0.03380543738603592,
        -0.002996645634993911,
        0.0239535104483366,
        0.0559578612446785,
        -0.024716675281524658,
        0.05482270196080208,
        0.034902673214673996,
        -0.008074638433754444,
        0.04688508063554764,
        -0.056852661073207855,
        -0.04136190563440323,
        0.039927467703819275,
        -0.025928093120455742,
        -0.05402475968003273,
        0.033140070736408234,
        -0.05482081323862076,
        0.054609254002571106,
        0.049547646194696426,
        -0.05528363212943077,
        -0.05343133211135864,
        0.023499080911278725,
        -0.05553489923477173,
        0.05657445266842842,
        -0.05285342410206795,
        -0.05245102569460869,
        -0.0562213696539402,
        -0.031094564124941826,
        -0.05554267019033432,
        0.051742393523454666,
        -0.05334451422095299,
        0.041262559592723846,
        0.04552597180008888,
        -0.05044542998075485,
        -0.052942678332328796,
        0.05660703778266907,
        -0.04278646036982536,
        -0.055795881897211075,
        -0.04726557806134224,
        0.005436250474303961,
        0.012150786817073822,
        0.05675913766026497,
        -0.0474715530872345,
        0.03202512487769127,
        -0.05656658858060837,
        -0.04849638789892197,
        0.02929568849503994,
        -0.054669201374053955,
        -0.03453080356121063,
        -0.05534380301833153,
        -0.04921538010239601,
        0.056520022451877594,
        0.055499669164419174,
        0.056410402059555054,
        0.012070083990693092,
        -0.05687554180622101,
        -0.02661421149969101,
        0.02424519695341587,
        0.0543358288705349,
        -0.05106538161635399,
        0.014040054753422737,
        -0.04212700575590134,
        0.0009326168801635504,
        0.05606206879019737,
        -0.032404664903879166,
        0.05685647204518318,
        0.031595174223184586,
        -0.04563950374722481,
        -0.043959274888038635,
        0.05491338297724724,
        0.05210815370082855,
        0.05567825585603714,
        -0.03481829911470413,
        0.008582244627177715,
        0.010917505249381065,
        -0.013558527454733849,
        0.05563242360949516,
        0.04088151827454567,
        -0.05520617961883545,
        -0.026362260803580284,
        -0.056895218789577484,
        -0.05282457917928696,
        -0.03407782316207886,
        0.03650575131177902,
        -0.0035640252754092216,
        -0.01624133065342903,
        -0.048019908368587494,
        0.05681962892413139,
        -0.05412178114056587,
        0.043164752423763275,
        -0.0545959509909153,
        -0.0563497431576252,
        -0.05674025043845177,
        -0.056858718395233154,
        -0.03536098450422287,
        -0.05625060200691223,
        -0.05049067735671997,
        -0.053439073264598846,
        0.056187283247709274,
        0.00884235929697752,
        0.05604855343699455,
        -0.053719762712717056,
        -0.04464602470397949,
        0.052492428570985794,
        -0.05655484274029732,
        0.054352302104234695,
        0.05658019706606865,
        0.020169829949736595,
        -0.0405510738492012,
        0.053158149123191833,
        -0.013846071437001228,
        -0.04141740873456001,
        -0.05668604373931885,
        0.04810033738613129,
        -0.05076853185892105,
        -0.0024752726312726736,
        0.0534173846244812,
        0.05660012736916542,
        -0.028307005763053894,
        -0.05549084395170212,
        -0.03261677548289299,
        0.05230202525854111,
        0.05641845241189003,
        -0.051221441477537155,
        0.05625789240002632,
        0.000886537425685674,
        -0.007695458363741636,
        -0.009094031527638435,
        0.04242296516895294,
        0.0543927326798439,
        0.05353570356965065,
        0.056358616799116135,
        0.05259682238101959,
        0.056584518402814865,
        0.04861069098114967,
        -0.05684676766395569,
        0.009122655726969242,
        0.041374895721673965,
        -0.0565287284553051,
        -0.023370148614048958,
        0.0021051508374512196,
        0.021150000393390656,
        0.043601781129837036,
        -0.04272417724132538,
        0.05248529836535454,
        -0.05640804395079613,
        0.028706548735499382,
        -0.031523749232292175,
        0.00005657067595166154,
        -0.03822613134980202,
        0.014700524508953094,
        0.049505606293678284,
        0.04721806198358536,
        -0.011566738598048687,
        0.002456340938806534,
        0.0530819371342659,
        -0.04486743360757828,
        -0.05588311329483986,
        -0.005864912178367376,
        -0.05308941751718521,
        0.0468808114528656,
        0.055556442588567734,
        -0.05411479249596596,
        0.05332643911242485,
        -0.006903913337737322,
        0.0534820631146431,
        -0.05686207860708237,
        0.05525244399905205,
        0.056760165840387344
    ],
    [
        0.03914954513311386,
        -0.010231134481728077,
        -0.030763918533921242,
        0.018126782029867172,
        0.047188062220811844,
        -0.051864445209503174,
        0.06373193860054016,
        -0.048828233033418655,
        -0.02984815090894699,
        0.014364095404744148,
        -0.04247745871543884,
        0.06323223561048508,
        -0.048733096569776535,
        -0.05355146527290344,
        -0.06285656243562698,
        -0.03426305949687958,
        -0.057109273970127106,
        0.04862038418650627,
        -0.001856083981692791,
        0.05412453040480614,
        -0.0527852438390255,
        0.028516463935375214,
        0.06407280266284943,
        -0.04981584846973419,
        0.05299234390258789,
        0.010593853890895844,
        0.011848454363644123,
        0.02315436489880085,
        -0.05433639511466026,
        0.0047714198008179665,
        0.05419035255908966,
        -0.062484901398420334,
        0.04692703112959862,
        -0.06376761198043823,
        0.004303715657442808,
        -0.03559500351548195,
        -0.052089277654886246,
        -0.014410038478672504,
        0.047920938581228256,
        -0.02176351472735405,
        0.05062012001872063,
        0.03570939227938652,
        0.06376456469297409,
        -0.04181096330285072,
        0.03500363975763321,
        0.043144695460796356,
        0.043925147503614426,
        0.023846739903092384,
        0.06298990547657013,
        0.03686433658003807,
        0.04417552053928375,
        -0.01642533205449581,
        0.061965640634298325,
        0.027572911232709885,
        -0.055137213319540024,
        -0.03193029761314392,
        -0.051196228712797165,
        0.03902691602706909,
        -0.013728083111345768,
        -0.052983853965997696,
        -0.05470378324389458,
        -0.0045330822467803955,
        -0.06046115979552269,
        -0.01755903661251068,
        0.03998032584786415,
        0.04228881001472473,
        0.04755787551403046,
        -0.06043528392910957,
        0.0468633733689785,
        -0.0641523227095604,
        -0.0031938047613948584,
        -0.061988189816474915,
        -0.04610880836844444,
        -0.050441399216651917,
        0.04117405042052269,
        0.009372618980705738,
        0.06088574230670929,
        0.0640614852309227,
        0.003028973937034607,
        -0.01800948940217495,
        -0.008231244049966335,
        0.002234603278338909,
        -0.062096696346998215,
        0.03417573869228363,
        0.04303307458758354,
        0.0256179366260767,
        -0.044679660350084305,
        -0.0025047732051461935,
        -0.044216256588697433,
        0.06329239159822464,
        -0.05941413715481758,
        -0.05431002750992775,
        -0.04174649342894554,
        -0.05769912526011467,
        -0.0068792980164289474,
        0.04650115966796875,
        -0.05673680454492569,
        -0.05749835819005966,
        -0.04940912872552872,
        -0.050072889775037766,
        -0.06409517675638199,
        0.007069708313792944,
        0.055995289236307144,
        0.06336379796266556,
        -0.012960377149283886,
        0.022039135918021202,
        0.052023883908987045,
        0.058935366570949554,
        -0.011223076842725277,
        0.047256648540496826,
        -0.05417613685131073,
        -0.061443474143743515,
        -0.058622781187295914,
        -0.020069170743227005,
        0.03855997323989868,
        -0.034738507121801376,
        -0.0407954640686512,
        -0.051544468849897385,
        -0.0606377013027668,
        0.045881692320108414,
        -0.016578640788793564,
        -0.041151899844408035,
        -0.00878421775996685,
        0.0554979145526886,
        -0.06159701198339462,
        -0.021829426288604736,
        -0.03945435211062431,
        0.03319285437464714,
        0.06031667813658714,
        -0.003988407086580992,
        -0.01798713393509388,
        -0.051387231796979904,
        0.0275692380964756,
        0.035772331058979034,
        -0.05393814668059349,
        -0.06298097223043442,
        0.05584610626101494,
        0.015787720680236816,
        -0.034512776881456375,
        0.04289444535970688,
        0.02831658534705639,
        0.018309516832232475,
        0.035662949085235596,
        0.051971133798360825,
        -0.01676948368549347,
        0.03025779128074646,
        0.061038240790367126,
        -0.00917814951390028,
        -0.04672422632575035,
        0.02442852221429348,
        -0.0605657659471035,
        -0.059261322021484375,
        -0.038666609674692154,
        -0.0045584398321807384,
        -0.021508611738681793,
        -0.015673520043492317,
        0.06310281157493591,
        -0.017498189583420753,
        -0.036826975643634796,
        -0.007773443590849638,
        0.013238402083516121,
        0.0017784684896469116,
        0.06053229793906212,
        -0.04181358218193054,
        -0.007686799392104149,
        -0.02522481419146061,
        0.03438234701752663,
        -0.05560493841767311,
        0.04946524277329445,
        0.028910622000694275,
        0.013463877141475677,
        -0.026780152693390846,
        -0.05889182165265083,
        0.050523947924375534,
        -0.036962345242500305,
        0.06219252198934555,
        -0.0633380115032196,
        0.05939590185880661,
        0.053444795310497284,
        0.05849108099937439,
        -0.0022762336302548647,
        0.022933829575777054,
        0.06102967634797096,
        -0.04016515985131264,
        -0.010727756656706333,
        0.010556650348007679,
        -0.03263397887349129,
        0.06120588630437851,
        -0.029129954054951668,
        -0.06363609433174133,
        -0.01340772956609726,
        0.02066679112613201,
        -0.02076398767530918,
        0.014582300558686256,
        -0.04476165026426315,
        -0.000680101802572608,
        0.058474671095609665,
        -0.01107848808169365,
        -0.0548100508749485,
        0.015112868510186672,
        0.019100479781627655,
        -0.05168011784553528,
        0.00015623230137862265,
        -0.029711490496993065,
        0.034761693328619,
        -0.041065335273742676,
        0.005015547852963209,
        0.04268622025847435,
        -0.05770367011427879,
        -0.003942814189940691,
        -0.058106034994125366,
        0.06039031967520714,
        0.04657648876309395,
        0.03577858954668045,
        0.058452315628528595,
        0.050372086465358734,
        0.0167510025203228,
        0.04418567195534706,
        0.06420978903770447,
        0.05925954133272171,
        0.049844127148389816,
        -0.034109462052583694,
        -0.06253275275230408,
        -0.06314821541309357,
        0.047696951776742935,
        -0.0642932578921318,
        0.01725905016064644,
        0.042258281260728836,
        -0.03441713750362396,
        -0.036148931831121445,
        -0.055515360087156296,
        0.028448494151234627,
        -0.0028823192697018385,
        0.05453622713685036,
        -0.01683802343904972,
        -0.029065046459436417,
        0.05918121710419655,
        -0.0023289916571229696,
        0.05095317214727402,
        0.05957036837935448,
        -0.018460847437381744,
        0.061538249254226685,
        -0.0009598531760275364,
        0.04704932123422623,
        0.030541159212589264,
        -0.04709101468324661,
        0.04212091863155365,
        -0.00896088033914566,
        0.03841087222099304,
        -0.043241046369075775,
        -0.062169503420591354,
        -0.05207492411136627,
        -0.03235117346048355,
        -0.061604250222444534,
        0.058528557419776917,
        0.05281681939959526,
        0.017141561955213547,
        -0.0586019866168499,
        0.06117432564496994,
        0.06422863155603409,
        -0.030592728406190872,
        0.021814240142703056,
        -0.043265558779239655,
        0.06351292878389359,
        -0.014700238592922688,
        0.061418432742357254,
        0.05302337929606438,
        0.02879532426595688,
        -0.03592761605978012,
        -0.06429440528154373,
        -0.02348530851304531,
        -0.06428894400596619,
        0.010528956539928913,
        -0.0547158345580101,
        0.0024311246816068888,
        0.031856562942266464,
        -0.05111921951174736,
        0.03951583802700043,
        -0.05792001262307167,
        0.037970952689647675,
        -0.06248485669493675,
        0.05136461183428764,
        0.021776344627141953,
        0.024587010964751244,
        -0.031023364514112473,
        -0.05791926011443138,
        0.021145906299352646,
        -0.03457464650273323,
        0.002858848311007023,
        0.03942561894655228,
        -0.025592105463147163,
        0.016454944387078285,
        -0.017721880227327347,
        -0.015611138194799423,
        0.030594833195209503,
        -0.05700254067778587,
        0.06319185346364975,
        0.05952328443527222,
        -0.032302528619766235,
        0.04832374304533005,
        -0.043187215924263,
        0.05930342897772789,
        -0.03230547904968262,
        -0.012417979538440704,
        -0.0586056113243103,
        -0.013850273564457893,
        0.026511332020163536,
        0.06313211470842361,
        -0.00012842730211559683,
        -0.04185474291443825,
        0.05577056109905243,
        0.029345158487558365,
        -0.0015186399687081575,
        0.06035732850432396,
        -0.060758788138628006,
        -0.058900948613882065,
        0.06255429238080978,
        0.0026454010512679815,
        -0.03851061314344406,
        -0.059573981910943985,
        -0.06371636688709259,
        0.05071980506181717,
        0.02271949127316475,
        -0.03601326420903206,
        0.05935366824269295,
        -0.02632734179496765,
        0.0470486618578434,
        -0.01040575560182333,
        0.013050239533185959,
        0.014675892889499664,
        0.009765474125742912,
        0.03980237618088722,
        0.05920266732573509,
        -0.04809442535042763,
        0.054907940328121185,
        0.05370311811566353,
        0.0013553458265960217,
        0.06267356127500534,
        -0.05457516759634018,
        0.0547880083322525,
        0.051523707807064056,
        0.026847118511795998,
        -0.012215614318847656,
        0.012980270199477673,
        0.06417540460824966,
        0.051968831568956375,
        0.01586698368191719,
        0.05272476747632027,
        0.03529328852891922,
        0.04558347910642624,
        -0.013372845016419888,
        -0.0417989119887352,
        -0.06239667162299156,
        -0.06105189025402069,
        -0.05782411992549896,
        0.05347125232219696,
        -0.04732314869761467,
        -0.028923898935317993,
        0.0026692794635891914,
        -0.06347314268350601,
        0.05480479821562767,
        0.06264480948448181,
        -0.06265226751565933,
        0.0009135091095231473,
        0.06383980810642242,
        0.011337386444211006,
        0.06389812380075455,
        -0.05910848081111908,
        0.06395429372787476,
        -0.0642290934920311,
        -0.05852386727929115,
        -0.05910177156329155,
        0.05709577351808548,
        0.0500153973698616,
        0.0438501238822937,
        0.056277137249708176,
        0.0596478097140789,
        -0.013165430165827274,
        -0.02291584387421608,
        0.0547209158539772,
        -0.036429282277822495,
        0.05282717943191528,
        -0.061507947742938995,
        -0.04207181930541992,
        0.06131459027528763,
        -0.040314365178346634,
        0.06398547440767288,
        -0.05665890872478485,
        -0.06119000166654587,
        -0.06148655712604523,
        -0.002387707820162177,
        -0.014419701881706715,
        0.02972033992409706,
        0.03041822463274002,
        0.055494438856840134,
        0.06273648142814636,
        0.06249813735485077,
        -0.042797937989234924,
        -0.06208542361855507,
        -0.0643894374370575,
        -0.016769958660006523,
        0.060470715165138245,
        0.010771804489195347,
        0.05676255747675896,
        -0.04658286273479462,
        -0.028986340388655663,
        0.011080533266067505,
        0.038706738501787186,
        0.06291227042675018,
        0.016988327726721764,
        0.05977741256356239,
        0.0361923910677433,
        0.0234065018594265,
        0.02101295068860054,
        0.06138582527637482,
        0.010142862796783447,
        0.017938749864697456,
        0.0535588301718235,
        0.06398604810237885,
        0.060052599757909775,
        -0.061295684427022934,
        -0.03778884932398796,
        0.010149849578738213,
        -0.06186714395880699,
        0.042646463960409164,
        0.006946324836462736,
        -0.055785831063985825,
        0.032756052911281586,
        -0.04036811739206314,
        0.033838558942079544,
        0.04474060982465744,
        -0.0298960842192173,
        -0.04577343910932541,
        -0.05923684313893318,
        0.04172859713435173,
        -0.058132629841566086,
        -0.009399457834661007,
        0.021890802308917046,
        0.03247249498963356,
        -0.006952975410968065,
        0.028138956055045128,
        0.055921610444784164,
        -0.025808876380324364,
        0.05553100258111954,
        -0.030643869191408157,
        0.006229343358427286,
        0.06278204917907715,
        -0.05358050391077995,
        0.06221061199903488,
        0.010198715142905712,
        -0.061539534479379654,
        0.020468566566705704,
        0.06224928796291351,
        -0.05250202864408493,
        0.05497949942946434,
        -0.03841066733002663,
        -0.0010046560782939196,
        0.03267044201493263,
        -0.008783141151070595,
        0.06275530159473419,
        0.06009213253855705,
        -0.02134171687066555,
        -0.05245184525847435,
        0.0036810431629419327,
        0.05390424281358719,
        0.0628436952829361,
        -0.06394821405410767,
        0.060065969824790955,
        -0.021945856511592865,
        -0.06395360827445984,
        0.06118747219443321,
        -0.008657623082399368,
        -0.00020605364989023656,
        -0.026037484407424927,
        0.054302580654621124,
        0.04804529994726181,
        -0.012786569073796272,
        -0.01589352823793888,
        -0.05898764356970787,
        -0.05073876678943634,
        -0.05818834900856018,
        -0.016141243278980255,
        -0.021629879251122475,
        0.015413546934723854,
        -0.03858797997236252,
        0.04905318841338158,
        0.03360649570822716,
        -0.019773533567786217,
        -0.04955939203500748,
        0.03348561003804207,
        -0.016171753406524658,
        -0.032386377453804016,
        0.05557561665773392,
        -0.03993573412299156,
        0.055409517139196396,
        0.028769725933670998,
        -0.05167713016271591,
        -0.060534920543432236,
        0.05452963709831238,
        0.008139743469655514,
        -0.05695483833551407,
        0.02246256172657013,
        -0.04290324077010155,
        -0.04821258410811424,
        -0.04641875624656677,
        0.002206170931458473,
        0.04290302097797394,
        0.03012191504240036,
        -0.04771361127495766,
        -0.06434646993875504,
        0.06339767575263977,
        0.06014786660671234
    ],
    [
        0.0009825093438848853,
        0.0325540266931057,
        0.04138891026377678,
        0.02730582281947136,
        0.06022944673895836,
        -0.018031202256679535,
        0.06345707178115845,
        -0.043267883360385895,
        -0.02976292558014393,
        0.04816998913884163,
        -0.0352192148566246,
        0.06291362643241882,
        -0.02329954318702221,
        -0.06306428462266922,
        -0.06239277496933937,
        -0.039954159408807755,
        -0.06344308704137802,
        0.0568968765437603,
        -0.017664635553956032,
        0.0406915545463562,
        0.024625468999147415,
        0.05575494468212128,
        0.06223635748028755,
        -0.0145433833822608,
        0.014919549226760864,
        0.021564116701483727,
        0.04525131359696388,
        0.04442330077290535,
        0.016473032534122467,
        0.0008678557933308184,
        0.018864307552576065,
        -0.06341854482889175,
        0.048247095197439194,
        -0.061602313071489334,
        0.0044730487279593945,
        -0.048318538814783096,
        -0.03533511981368065,
        0.02392122521996498,
        0.0564659982919693,
        -0.03770953789353371,
        0.047141898423433304,
        0.03911363705992699,
        0.06018161401152611,
        -0.005748336669057608,
        0.029605647549033165,
        0.05418865755200386,
        0.05974861979484558,
        0.05554933845996857,
        -0.018812648952007294,
        0.03975210711359978,
        0.058813244104385376,
        0.0009560210164636374,
        0.04666565731167793,
        0.010643805377185345,
        -0.05569148808717728,
        -0.034915193915367126,
        -0.05968107283115387,
        0.051479656249284744,
        -0.03843534737825394,
        -0.04768109694123268,
        -0.05065090209245682,
        0.010268844664096832,
        -0.059755850583314896,
        0.0164958368986845,
        0.04793160408735275,
        0.055920615792274475,
        0.042700402438640594,
        -0.06023705005645752,
        0.05185004696249962,
        -0.06375934928655624,
        -0.033377163112163544,
        -0.051502108573913574,
        -0.053188521414995193,
        0.0025147858541458845,
        -0.02622353844344616,
        0.010585264302790165,
        0.05980892851948738,
        0.061854951083660126,
        0.050843946635723114,
        -0.059313591569662094,
        0.0317511111497879,
        0.0025852483231574297,
        -0.018817231059074402,
        0.01903761550784111,
        0.039975058287382126,
        0.05291764438152313,
        -0.01230772864073515,
        -0.05982552096247673,
        -0.05307670682668686,
        0.02065540850162506,
        -0.06275086104869843,
        -0.047401078045368195,
        -0.037182506173849106,
        -0.059206876903772354,
        0.01943913847208023,
        0.036442678421735764,
        -0.0496206134557724,
        -0.046354759484529495,
        -0.01883075386285782,
        -0.01173432357609272,
        -0.06330575048923492,
        0.027275631204247475,
        0.05958741903305054,
        0.05586700141429901,
        -0.0019690317567437887,
        0.05421124026179314,
        0.028849411755800247,
        0.042654212564229965,
        -0.059465277940034866,
        0.04906759038567543,
        -0.05835139378905296,
        -0.044335924088954926,
        -0.05453730374574661,
        -0.026502573862671852,
        -0.0165755283087492,
        -0.05302886292338371,
        -0.050655581057071686,
        -0.04132756590843201,
        -0.0392182320356369,
        0.04681581258773804,
        0.0036014437209814787,
        -0.009663921780884266,
        0.004367323592305183,
        0.041010189801454544,
        -0.06341923028230667,
        0.05601244047284126,
        -0.05013720691204071,
        0.029174137860536575,
        0.051674555987119675,
        0.04902394115924835,
        -0.04892446845769882,
        -0.03991975262761116,
        -0.00829335767775774,
        0.018065478652715683,
        -0.05637095868587494,
        -0.0605606772005558,
        0.04378208518028259,
        0.010026754811406136,
        -0.05300170183181763,
        0.04138888791203499,
        0.024244636297225952,
        0.03686891868710518,
        0.03345860168337822,
        0.056920092552900314,
        -0.03383009135723114,
        -0.005634320434182882,
        0.04411748796701431,
        -0.05908159539103508,
        -0.03164807707071304,
        0.006509061902761459,
        -0.05991855636239052,
        -0.06329900026321411,
        -0.05886124446988106,
        0.003739994950592518,
        0.04757001996040344,
        0.03713727369904518,
        0.04807747155427933,
        -0.029782315716147423,
        -0.03386886045336723,
        0.04298684373497963,
        0.03708091750741005,
        -0.0007517255144193769,
        0.05437860265374184,
        0.006213379558175802,
        -0.06275185197591782,
        0.015578802675008774,
        0.05370793119072914,
        -0.029470177367329597,
        0.036370713263750076,
        0.04315992072224617,
        -0.047122832387685776,
        -0.03007598966360092,
        0.015495846047997475,
        0.04837959632277489,
        0.010105251334607601,
        0.05976374074816704,
        -0.05990764498710632,
        0.06061720848083496,
        0.06191961467266083,
        0.05150535702705383,
        -0.020431391894817352,
        0.02367679961025715,
        0.05598771944642067,
        -0.03147473558783531,
        -0.049345310777425766,
        0.0566263422369957,
        -0.023939624428749084,
        0.062852643430233,
        -0.04875638335943222,
        -0.06388437747955322,
        0.02830066904425621,
        -0.021473556756973267,
        -0.0483848974108696,
        0.04410025477409363,
        -0.0582704059779644,
        0.028887322172522545,
        0.056851815432310104,
        0.0028352367226034403,
        -0.040366727858781815,
        -0.017707571387290955,
        -0.03460603207349777,
        -0.06057090312242508,
        -0.017802787944674492,
        -0.05374704673886299,
        0.03426434472203255,
        0.013907413929700851,
        0.01738157868385315,
        0.05940482020378113,
        -0.0633138120174408,
        -0.012903821654617786,
        -0.06094764173030853,
        0.05272141098976135,
        0.03194347396492958,
        0.05179104954004288,
        0.025005478411912918,
        0.061721719801425934,
        0.05979621782898903,
        0.052502892911434174,
        0.06397645175457001,
        0.03396536409854889,
        0.007907815277576447,
        0.004252093378454447,
        -0.06326425820589066,
        -0.060801029205322266,
        0.034773536026477814,
        -0.06400217860937119,
        -0.002924534725025296,
        0.02486737072467804,
        -0.05435088276863098,
        -0.058694660663604736,
        -0.039939481765031815,
        -0.029187297448515892,
        0.04399256408214569,
        0.0554562509059906,
        -0.04488030821084976,
        0.04713817313313484,
        0.06107296794652939,
        -0.04097854346036911,
        0.03293495997786522,
        0.06254655867815018,
        0.05171635001897812,
        0.06185457482933998,
        -0.0032656181138008833,
        0.0556168258190155,
        -0.0437626913189888,
        0.006311395671218634,
        0.058150406926870346,
        0.026986371725797653,
        0.03381465747952461,
        0.003663982730358839,
        -0.013285157270729542,
        -0.06288474798202515,
        -0.05440039560198784,
        -0.06302066892385483,
        0.05302279070019722,
        0.05302272364497185,
        -0.04115627706050873,
        0.04321467876434326,
        0.06376984715461731,
        0.06047737970948219,
        0.002576486673206091,
        0.058719076216220856,
        0.05609080195426941,
        0.06332724541425705,
        0.013047239743173122,
        0.0572940967977047,
        0.0604773610830307,
        0.014253807254135609,
        -0.04162422940135002,
        -0.06305345892906189,
        -0.06299606710672379,
        -0.062311287969350815,
        0.05363072454929352,
        -0.03716619685292244,
        -0.02409832552075386,
        -0.011921154335141182,
        -0.0585496686398983,
        0.03321850672364235,
        -0.05470849946141243,
        0.03187381848692894,
        -0.06126749515533447,
        0.03482424467802048,
        0.03519761934876442,
        0.027396349236369133,
        0.006228252779692411,
        -0.05613058805465698,
        0.022609757259488106,
        -0.01745503954589367,
        -0.046636395156383514,
        -0.02039826475083828,
        -0.03376976400613785,
        0.04299203306436539,
        -0.015660634264349937,
        -0.024265190586447716,
        0.04639338329434395,
        -0.061076655983924866,
        0.061737917363643646,
        -0.029420414939522743,
        -0.014096277765929699,
        0.04054000973701477,
        -0.04811821132898331,
        -0.010355738922953606,
        -0.054557811468839645,
        0.05318521335721016,
        -0.02519603818655014,
        0.02495095320045948,
        -0.02757841907441616,
        0.06348259001970291,
        0.007098059635609388,
        -0.004064686130732298,
        0.05980690196156502,
        0.04404067248106003,
        0.011291315779089928,
        0.06338950246572495,
        -0.06324870139360428,
        -0.06125025823712349,
        0.05237826704978943,
        -0.002274270635098219,
        0.03029242530465126,
        -0.051460955291986465,
        -0.03463916853070259,
        -0.01791214942932129,
        0.053838033229112625,
        -0.04429572820663452,
        0.012097052298486233,
        0.03402247279882431,
        0.03842823952436447,
        0.034884192049503326,
        0.006310941651463509,
        -0.007159379776567221,
        0.01439572311937809,
        0.019296875223517418,
        0.06027395278215408,
        -0.028686074540019035,
        0.05462212860584259,
        0.014297142624855042,
        -0.031961336731910706,
        0.062364496290683746,
        -0.053804490715265274,
        0.033158376812934875,
        0.006570953875780106,
        -0.039252862334251404,
        -0.05221330001950264,
        -0.026131613180041313,
        0.0634535551071167,
        0.02635471150279045,
        0.04808616265654564,
        0.0591006837785244,
        0.058768611401319504,
        0.06239277124404907,
        0.011159967631101608,
        -0.03848499432206154,
        -0.05007399991154671,
        -0.060658905655145645,
        -0.03983701765537262,
        0.06131577864289284,
        -0.05594965070486069,
        -0.007639785762876272,
        -0.012482678517699242,
        -0.051266882568597794,
        0.060202471911907196,
        0.05638282746076584,
        -0.06180303171277046,
        -0.012721292674541473,
        0.05736400559544563,
        -0.017965244129300117,
        0.06392982602119446,
        -0.012492387555539608,
        0.059301164001226425,
        -0.0625380352139473,
        -0.05385483428835869,
        -0.06098053976893425,
        0.059965889900922775,
        0.06021350622177124,
        0.052396368235349655,
        0.06195206567645073,
        0.05649709701538086,
        -0.006514298263937235,
        0.04181860759854317,
        -0.018366439267992973,
        -0.059755317866802216,
        0.04619097337126732,
        -0.058110110461711884,
        -0.05525366961956024,
        0.05512327700853348,
        -0.016727518290281296,
        0.06405965983867645,
        -0.06311238557100296,
        -0.05646789073944092,
        -0.039082493633031845,
        -0.019198952242732048,
        0.014987349510192871,
        0.007497353479266167,
        0.012342240661382675,
        0.04983469843864441,
        0.06268614530563354,
        0.05787926912307739,
        -0.05743604525923729,
        -0.06164579465985298,
        -0.06348835676908493,
        0.03896542638540268,
        0.003482166910544038,
        0.0118666747584939,
        0.06319987773895264,
        0.005602211691439152,
        -0.009736264124512672,
        0.041364844888448715,
        0.05840757116675377,
        0.0527324341237545,
        -0.017572496086359024,
        0.05429917573928833,
        -0.034801919013261795,
        -0.06058695912361145,
        0.04146149009466171,
        0.06207394599914551,
        -0.029900595545768738,
        0.04349192604422569,
        0.0168868787586689,
        0.036616068333387375,
        0.061732325702905655,
        -0.06129824370145798,
        0.006093283649533987,
        -0.008763770572841167,
        -0.05584075674414635,
        0.05843176320195198,
        -0.038242846727371216,
        -0.041654232889413834,
        -0.05330833047628403,
        -0.06319354474544525,
        -0.03803865611553192,
        0.046277470886707306,
        -0.00360616622492671,
        -0.002024821937084198,
        -0.056427787989377975,
        0.013179218396544456,
        -0.057442158460617065,
        -0.021527165547013283,
        0.03755316138267517,
        0.039284344762563705,
        -0.05340636894106865,
        0.05861319601535797,
        0.005292367190122604,
        0.028038203716278076,
        0.05499148368835449,
        -0.052027177065610886,
        0.02593887411057949,
        0.06296718865633011,
        -0.0615127794444561,
        0.06266685575246811,
        -0.008710259571671486,
        -0.059779614210128784,
        -0.0462702177464962,
        0.059295520186424255,
        -0.055027998983860016,
        -0.036706455051898956,
        -0.030901936814188957,
        0.015064004808664322,
        -0.022574592381715775,
        -0.021057873964309692,
        0.06251194328069687,
        0.03951002284884453,
        0.042389873415231705,
        -0.05715083330869675,
        -0.004675292409956455,
        0.051334112882614136,
        0.06248397007584572,
        -0.05300465226173401,
        0.062392815947532654,
        0.0017078709788620472,
        -0.06102484092116356,
        0.051735490560531616,
        0.04172192141413689,
        0.03326356038451195,
        0.03597479686141014,
        0.031628698110580444,
        0.044174350798130035,
        -0.04266231879591942,
        -0.021170824766159058,
        -0.03701440989971161,
        -0.03066558763384819,
        -0.021950101479887962,
        -0.053777799010276794,
        -0.034980788826942444,
        -0.02082672342658043,
        -0.04104912653565407,
        0.014583083800971508,
        -0.027563005685806274,
        0.0021916700061410666,
        -0.05177406594157219,
        -0.017540298402309418,
        -0.02217080071568489,
        0.042570605874061584,
        0.008220167830586433,
        -0.045310262590646744,
        0.056706104427576065,
        0.04877208545804024,
        -0.045449066907167435,
        -0.06230878829956055,
        0.05435965210199356,
        0.06318613886833191,
        -0.04537053778767586,
        -0.025573819875717163,
        -0.04466443881392479,
        -0.055348508059978485,
        -0.012693813070654869,
        -0.04353269189596176,
        0.019486578181385994,
        -0.008749878033995628,
        -0.0004228815087117255,
        -0.06371099501848221,
        0.060677289962768555,
        0.059078942984342575
    ],
    [
        -0.05005175620317459,
        0.05715068802237511,
        -0.038158874958753586,
        0.0005974902305752039,
        0.036629725247621536,
        -0.024222616106271744,
        0.055176734924316406,
        -0.046312592923641205,
        0.025622285902500153,
        -0.054880291223526,
        0.03868645057082176,
        0.05231370031833649,
        -0.043072279542684555,
        -0.05758680775761604,
        -0.05866839364171028,
        0.02727150358259678,
        -0.0579456090927124,
        0.04918257147073746,
        0.005769950337707996,
        -0.05924370884895325,
        -0.03252681717276573,
        -0.031189551576972008,
        0.043670326471328735,
        0.015092780813574791,
        0.017671756446361542,
        0.056453634053468704,
        0.0383477509021759,
        0.0399412140250206,
        -0.05145580321550369,
        -0.008218209259212017,
        0.059143368154764175,
        -0.058184150606393814,
        -0.0574532113969326,
        -0.05920497700572014,
        0.0043988036923110485,
        0.025203309953212738,
        -0.049498818814754486,
        -0.03402888774871826,
        0.05005999654531479,
        0.008054360747337341,
        0.03963588550686836,
        0.05002604052424431,
        0.051855966448783875,
        -0.058052580803632736,
        0.030253484845161438,
        0.04067420959472656,
        -0.022436292842030525,
        0.05851283296942711,
        0.05939502641558647,
        -0.027522699907422066,
        0.05045755207538605,
        0.00706207612529397,
        0.023075353354215622,
        0.019073698669672012,
        -0.0170038640499115,
        -0.05802037566900253,
        0.01959710381925106,
        -0.03962911292910576,
        0.02355114370584488,
        -0.025509487837553024,
        -0.058068111538887024,
        -0.012059054337441921,
        -0.048739440739154816,
        0.04924701899290085,
        -0.05849621817469597,
        -0.03668646514415741,
        0.04808275029063225,
        -0.043996769934892654,
        0.045941054821014404,
        -0.05834256857633591,
        -0.05796574801206589,
        -0.05922060087323189,
        -0.011980717070400715,
        0.010575159452855587,
        -0.054657015949487686,
        0.007887838408350945,
        -0.026374150067567825,
        0.05762515962123871,
        -0.028600970283150673,
        -0.05795041844248772,
        -0.04666122421622276,
        0.053363289684057236,
        -0.057844482362270355,
        0.04142918810248375,
        0.019078746438026428,
        0.009354512207210064,
        0.04532862827181816,
        -0.014101426117122173,
        -0.05238115042448044,
        0.055591829121112823,
        -0.016798723489046097,
        -0.04898706451058388,
        -0.040035296231508255,
        -0.011974412016570568,
        -0.019502442330121994,
        -0.059247009456157684,
        -0.05410125479102135,
        -0.05165180191397667,
        -0.0523495078086853,
        -0.04228087142109871,
        -0.057668931782245636,
        0.04973834380507469,
        0.05901971086859703,
        0.05737185850739479,
        0.017420796677470207,
        -0.058714501559734344,
        -0.052703067660331726,
        0.04024748131632805,
        -0.03754580765962601,
        -0.01888692006468773,
        -0.05670503154397011,
        -0.05588425695896149,
        -0.05477520078420639,
        -0.0399581715464592,
        0.057818975299596786,
        -0.05516722425818443,
        0.058346379548311234,
        -0.053637173026800156,
        -0.020077461376786232,
        -0.03348032385110855,
        -0.05892219394445419,
        -0.03597988933324814,
        0.048373062163591385,
        0.04810330271720886,
        -0.05417793244123459,
        0.04071753844618797,
        0.04964230954647064,
        0.05913836136460304,
        0.05059518665075302,
        0.05475221574306488,
        -0.004022762645035982,
        -0.041905082762241364,
        -0.0463237538933754,
        -0.04637089744210243,
        -0.036383967846632004,
        -0.04460523650050163,
        0.05324460193514824,
        0.017858611419796944,
        -0.04016489535570145,
        0.058886196464300156,
        -0.0494990199804306,
        0.05942091345787048,
        0.05052044615149498,
        0.05806039273738861,
        -0.04376964271068573,
        -0.0104753989726305,
        0.014203046448528767,
        -0.03278137370944023,
        -0.04709793999791145,
        0.058583009988069534,
        -0.001354697858914733,
        -0.05875676870346069,
        -0.050119977444410324,
        0.05833136662840843,
        -0.037801969796419144,
        -0.04782888665795326,
        0.05577799677848816,
        -0.024300966411828995,
        -0.02539551816880703,
        -0.014973940327763557,
        0.05748295038938522,
        -0.052810296416282654,
        0.03283027186989784,
        -0.008981269784271717,
        -0.053436752408742905,
        0.041427433490753174,
        0.018794512376189232,
        -0.051362521946430206,
        0.04088766127824783,
        0.046222224831581116,
        -0.05316641181707382,
        -0.057606570422649384,
        -0.010014270432293415,
        -0.05823162943124771,
        -0.056862395256757736,
        0.057248298078775406,
        0.0493132509291172,
        0.0443311408162117,
        0.04786262288689613,
        -0.034238528460264206,
        0.05763374641537666,
        0.023907287046313286,
        0.04303940385580063,
        -0.026614604517817497,
        0.04691736772656441,
        0.04594481736421585,
        -0.05332304909825325,
        0.053714681416749954,
        -0.0490737147629261,
        -0.005274054128676653,
        -0.04849042370915413,
        0.040192507207393646,
        -0.006236668676137924,
        -0.05633014440536499,
        -0.05185164883732796,
        0.012583049945533276,
        -0.04520271345973015,
        -0.045246757566928864,
        0.05907822772860527,
        0.03502960130572319,
        -0.01864878460764885,
        -0.05133260414004326,
        -0.03581540286540985,
        -0.04048265516757965,
        -0.04636003449559212,
        0.040698714554309845,
        0.019887834787368774,
        -0.011902246624231339,
        -0.047229986637830734,
        0.051122210919857025,
        -0.05457695946097374,
        0.05130166560411453,
        -0.0009818334365263581,
        -0.004424273502081633,
        -0.002321467502042651,
        -0.009264130145311356,
        -0.04013684764504433,
        -0.031884562224149704,
        0.050557512789964676,
        -0.05152841657400131,
        -0.05537049472332001,
        -0.05911931395530701,
        0.0009129809332080185,
        -0.050708070397377014,
        0.05699183791875839,
        0.03536438196897507,
        -0.013012224808335304,
        -0.050382647663354874,
        -0.006310867145657539,
        -0.057028792798519135,
        -0.05287943035364151,
        -0.05853274092078209,
        -0.05933757126331329,
        0.006728222593665123,
        -0.027536900714039803,
        -0.050820693373680115,
        0.055997565388679504,
        -0.042598869651556015,
        0.040722038596868515,
        0.05745953693985939,
        0.056713566184043884,
        0.0559769831597805,
        0.02533564157783985,
        -0.058169782161712646,
        0.015670662745833397,
        -0.033485494554042816,
        0.04304882138967514,
        0.052800122648477554,
        -0.05886330083012581,
        -0.05858607217669487,
        -0.05306656286120415,
        -0.054034728556871414,
        0.0304601788520813,
        -0.05257148668169975,
        0.05121370777487755,
        0.05626942962408066,
        -0.05795188248157501,
        0.007723057642579079,
        0.05694235861301422,
        0.05119074136018753,
        0.05905984342098236,
        0.05895375460386276,
        0.01910334825515747,
        0.05775114893913269,
        0.04978644847869873,
        0.01564745232462883,
        0.04163990914821625,
        0.03023902140557766,
        0.057304587215185165,
        0.0426117479801178,
        0.027825694531202316,
        -0.058495037257671356,
        0.04952239617705345,
        -0.05317847803235054,
        0.054239265620708466,
        0.027787424623966217,
        -0.048869192600250244,
        0.02824222482740879,
        -0.028591042384505272,
        -0.058358557522296906,
        -0.05285383015871048,
        0.028729531913995743,
        0.03166140243411064,
        0.0031978280749171972,
        0.04697808623313904,
        -0.0023649847134947777,
        0.05812351405620575,
        0.042603567242622375,
        -0.058780137449502945,
        0.030223187059164047,
        0.016256198287010193,
        0.04654652997851372,
        -0.020312732085585594,
        -0.018428368493914604,
        0.04312978684902191,
        -0.05152439698576927,
        0.05907902494072914,
        0.04397134110331535,
        0.04870821163058281,
        0.054536815732717514,
        -0.003932028543204069,
        0.035687100142240524,
        0.021811213344335556,
        0.0369759202003479,
        0.024384764954447746,
        -0.021842386573553085,
        -0.04294513165950775,
        0.02488585188984871,
        0.03183256834745407,
        -0.05916300788521767,
        0.002764887874945998,
        -0.05851262807846069,
        -0.05253366380929947,
        0.029359135776758194,
        -0.03878984972834587,
        -0.05282174423336983,
        0.04423598200082779,
        0.05558093264698982,
        -0.005266909021884203,
        -0.05650775134563446,
        0.033027857542037964,
        0.00064814742654562,
        0.05817490443587303,
        0.005507384892553091,
        0.049928195774555206,
        0.05889630317687988,
        -0.005559322889894247,
        -0.05640220269560814,
        0.04724310338497162,
        -0.041960638016462326,
        -0.010849948041141033,
        0.05278408154845238,
        0.05554951727390289,
        0.040554121136665344,
        0.05827938765287399,
        0.05841950327157974,
        -0.0344061553478241,
        0.058527782559394836,
        -0.05907663330435753,
        -0.043665144592523575,
        -0.014674155972898006,
        -0.053026046603918076,
        -0.04285421967506409,
        -0.009558982215821743,
        0.05133279412984848,
        -0.007940092124044895,
        -0.043831124901771545,
        0.04155917838215828,
        0.057269301265478134,
        0.050725217908620834,
        -0.05454428493976593,
        0.057485729455947876,
        -0.052759695798158646,
        -0.05283259227871895,
        -0.05786106735467911,
        0.057573068886995316,
        -0.0406997948884964,
        -0.03432057425379753,
        -0.045507270842790604,
        -0.048265330493450165,
        0.05220231041312218,
        0.05670759454369545,
        -0.04975476488471031,
        -0.03800042346119881,
        -0.050462059676647186,
        -0.050286754965782166,
        0.05889483168721199,
        0.05071377754211426,
        0.026438012719154358,
        -0.059269845485687256,
        -0.05664364621043205,
        -0.05902734398841858,
        0.04355749487876892,
        -0.007042746525257826,
        0.04419157654047012,
        0.057204410433769226,
        -0.05700840801000595,
        0.05919260159134865,
        0.014433762058615685,
        -0.05120887607336044,
        0.0017623864114284515,
        0.039029188454151154,
        -0.054087087512016296,
        -0.03162415325641632,
        0.05509763956069946,
        -0.026454422622919083,
        0.013764717616140842,
        -0.05488286912441254,
        -0.05678603798151016,
        -0.042124900966882706,
        -0.02021169476211071,
        -0.015078513883054256,
        0.02473519928753376,
        0.0339369960129261,
        -0.025143440812826157,
        0.05151565000414848,
        -0.026171816512942314,
        -0.05163619667291641,
        -0.013241957873106003,
        -0.05858868360519409,
        -0.046132877469062805,
        0.05733044445514679,
        0.05505720525979996,
        0.02201809734106064,
        -0.05201001837849617,
        0.042128629982471466,
        0.0340338870882988,
        -0.04142625257372856,
        0.058328777551651,
        -0.058170825242996216,
        0.05469084158539772,
        -0.057390592992305756,
        0.0592837929725647,
        0.051660072058439255,
        0.058958977460861206,
        0.011982865631580353,
        0.01793665438890457,
        -0.039433035999536514,
        0.028628062456846237,
        0.058759965002536774,
        -0.05853259190917015,
        0.020348047837615013,
        0.05881129950284958,
        -0.05850423127412796,
        0.034485165029764175,
        -0.03992059826850891,
        -0.03340351581573486,
        0.027646023780107498,
        -0.05640760436654091,
        -0.05490494892001152,
        0.059437789022922516,
        -0.033252015709877014,
        0.03575814515352249,
        0.03557736799120903,
        -0.03430524468421936,
        -0.010813494212925434,
        -0.015348128974437714,
        -0.03403812274336815,
        0.01763242855668068,
        -0.059142567217350006,
        -0.049230799078941345,
        0.041959017515182495,
        0.014017507433891296,
        0.04107019677758217,
        -0.0157635360956192,
        -0.0027389253955334425,
        0.05880442261695862,
        -0.05348934233188629,
        0.05262681841850281,
        -0.03562520816922188,
        -0.055753178894519806,
        0.0027425987645983696,
        0.040767282247543335,
        0.0008945188601501286,
        -0.016594665125012398,
        -0.02300202287733555,
        0.03708287701010704,
        0.030634526163339615,
        -0.03361445665359497,
        0.05547453835606575,
        -0.0008939506369642913,
        -0.02132214419543743,
        -0.056962575763463974,
        0.025046851485967636,
        0.039241861552000046,
        0.0591123104095459,
        -0.059182509779930115,
        0.053251542150974274,
        -0.000634700758382678,
        -0.05887419357895851,
        0.05530359968543053,
        0.005444711539894342,
        0.006594351027160883,
        0.05694453418254852,
        0.003701983718201518,
        0.04332933574914932,
        0.024995632469654083,
        0.056705575436353683,
        -0.03951893001794815,
        -0.05137551948428154,
        -0.03259746730327606,
        0.05398460850119591,
        -0.056171245872974396,
        0.051621437072753906,
        0.035268258303403854,
        0.031393807381391525,
        0.04343777149915695,
        -0.004275429993867874,
        -0.05166294053196907,
        -0.05694423243403435,
        -0.025620169937610626,
        -0.05022488906979561,
        -0.05943531170487404,
        0.05630818009376526,
        0.05279425159096718,
        -0.042790528386831284,
        0.050187695771455765,
        0.037166494876146317,
        0.0512986034154892,
        0.05873705819249153,
        -0.05874757096171379,
        -0.014518237672746181,
        -0.04166116565465927,
        -0.051981370896101,
        0.055599067360162735,
        -0.02097051404416561,
        0.054413050413131714,
        -0.02824273891746998,
        -0.056389220058918,
        -0.05946596711874008,
        0.05286668241024017,
        0.04089691489934921
    ],
    [
        -0.007003439590334892,
        0.044579457491636276,
        0.003793422831222415,
        0.04190071299672127,
        -0.04010739177465439,
        -0.0510481633245945,
        0.05074590817093849,
        -0.05118025466799736,
        0.051330335438251495,
        -0.04649015888571739,
        0.050507381558418274,
        -0.04961442947387695,
        -0.05156839266419411,
        0.03634275868535042,
        -0.05156640708446503,
        0.04013793170452118,
        -0.008209817111492157,
        0.042540598660707474,
        0.04741939157247543,
        0.03971833735704422,
        -0.01369491871446371,
        0.033592745661735535,
        0.051564786583185196,
        -0.005433376412838697,
        -0.02081790380179882,
        0.05144834518432617,
        0.05144296586513519,
        0.04919238016009331,
        -0.05157100781798363,
        -0.04567226022481918,
        -0.017252502962946892,
        0.05153634399175644,
        0.012685040943324566,
        -0.04094067960977554,
        0.05156271904706955,
        0.0504232756793499,
        -0.05147109925746918,
        -0.046245694160461426,
        -0.04600289463996887,
        -0.04535948485136032,
        0.012035761959850788,
        0.05146707966923714,
        0.05130797624588013,
        -0.05071644112467766,
        0.029370266944169998,
        -0.027573589235544205,
        0.047206055372953415,
        0.05128444731235504,
        0.05157268047332764,
        -0.049040909856557846,
        0.05156917870044708,
        0.04664698243141174,
        0.05121005326509476,
        0.048171963542699814,
        0.04655963554978371,
        0.04892221838235855,
        -0.051108427345752716,
        0.051568660885095596,
        -0.03080998919904232,
        -0.05155491828918457,
        -0.05157261714339256,
        0.029953572899103165,
        -0.048592790961265564,
        -0.011219236068427563,
        0.0506313219666481,
        -0.05132316052913666,
        -0.05157183110713959,
        -0.04957928508520126,
        0.05020737275481224,
        -0.013470050878822803,
        -0.044488921761512756,
        -0.04984342306852341,
        0.05156795680522919,
        0.05156966298818588,
        0.051125288009643555,
        0.05141909420490265,
        0.051272761076688766,
        0.0515727661550045,
        0.05149800330400467,
        -0.026407413184642792,
        -0.05137744918465614,
        -0.05107126757502556,
        -0.05157257989048958,
        0.03314365819096565,
        -0.05120909586548805,
        -0.04806912690401077,
        -0.03520087152719498,
        -0.04997914284467697,
        -0.04220537841320038,
        0.042070820927619934,
        -0.04998503625392914,
        -0.04879005253314972,
        -0.007616699673235416,
        -0.04986261948943138,
        -0.04130929335951805,
        -0.04493699222803116,
        -0.050790369510650635,
        -0.05003951117396355,
        0.03303546458482742,
        0.050383761525154114,
        -0.05157272145152092,
        0.05152864009141922,
        0.03878704085946083,
        0.050316598266363144,
        -0.05113092064857483,
        -0.03168110176920891,
        -0.04448600113391876,
        0.051533784717321396,
        -0.048514652997255325,
        0.016515152528882027,
        -0.05141850933432579,
        -0.047844018787145615,
        -0.04096392169594765,
        -0.04230911657214165,
        0.0514681339263916,
        -0.05153234302997589,
        0.037597429007291794,
        -0.046127356588840485,
        -0.046937283128499985,
        -0.04971287027001381,
        -0.05157100781798363,
        0.04330768808722496,
        0.051264915615320206,
        -0.04882122203707695,
        -0.05145696923136711,
        -0.05115330219268799,
        -0.0509672649204731,
        0.04796217754483223,
        0.0514204166829586,
        -0.05113561451435089,
        -0.05019358545541763,
        -0.04652995243668556,
        -0.049021050333976746,
        0.014311322942376137,
        -0.05093391612172127,
        -0.0510232113301754,
        0.051130495965480804,
        -0.04832461476325989,
        -0.05155833065509796,
        0.05155949667096138,
        -0.04855135455727577,
        -0.042804013937711716,
        0.05026375129818916,
        0.045311056077480316,
        0.04549119248986244,
        0.05084041878581047,
        0.05155590921640396,
        -0.03876211494207382,
        0.008153521455824375,
        0.0003365787270013243,
        -0.05124688893556595,
        -0.05149094760417938,
        -0.04164651781320572,
        -0.05136866495013237,
        0.03466217592358589,
        -0.05155445635318756,
        -0.05101587250828743,
        -0.0509280301630497,
        0.050616305321455,
        0.04652328044176102,
        -0.05153239890933037,
        0.039462894201278687,
        0.049762435257434845,
        0.047718197107315063,
        0.04610087722539902,
        -0.05137841776013374,
        0.03385723754763603,
        -0.026591461151838303,
        0.038752973079681396,
        0.0074622491374611855,
        -0.05124121904373169,
        0.051421698182821274,
        -0.05154838785529137,
        0.04944910854101181,
        0.006546275690197945,
        -0.02063554897904396,
        -0.027107268571853638,
        -0.03297378122806549,
        0.051570676267147064,
        -0.04683682695031166,
        0.031906940042972565,
        0.049862369894981384,
        0.05037067085504532,
        -0.044283315539360046,
        0.050624970346689224,
        -0.0016837994335219264,
        0.05097312107682228,
        0.0515587143599987,
        0.039953265339136124,
        -0.02782970480620861,
        -0.051557958126068115,
        -0.05154390260577202,
        0.0467870756983757,
        -0.0324489064514637,
        -0.05148335546255112,
        0.04815332591533661,
        0.05148909613490105,
        0.051357630640268326,
        0.05149044096469879,
        0.05156823992729187,
        0.05141422525048256,
        0.007308834698051214,
        -0.051307134330272675,
        -0.016066277399659157,
        0.04654340445995331,
        -0.051565177738666534,
        0.051571473479270935,
        0.04928026720881462,
        -0.04861108586192131,
        -0.01003927644342184,
        -0.05150952935218811,
        0.05153437703847885,
        -0.042925745248794556,
        0.05133114382624626,
        -0.04132755100727081,
        -0.04915546625852585,
        0.05157071352005005,
        -0.015159498900175095,
        0.051565077155828476,
        0.03820141777396202,
        0.01593581959605217,
        0.05115106701850891,
        -0.04789772257208824,
        -0.04951326921582222,
        0.05156781151890755,
        0.03934386745095253,
        0.03189084678888321,
        -0.048805441707372665,
        -0.015447166748344898,
        -0.051568858325481415,
        -0.05157233402132988,
        0.04555724188685417,
        0.04658927395939827,
        -0.043904390186071396,
        0.04977419972419739,
        0.05156414210796356,
        0.0515037477016449,
        -0.03582672029733658,
        -0.046286869794130325,
        0.05157238617539406,
        -0.04341461509466171,
        0.04568718001246452,
        0.029885554686188698,
        -0.05105309560894966,
        0.04786961153149605,
        0.02291562408208847,
        -0.05157261714339256,
        0.013984130695462227,
        -0.010913046076893806,
        -0.04724626988172531,
        -0.05157242342829704,
        -0.05122436583042145,
        0.045121725648641586,
        -0.047737523913383484,
        0.03037308156490326,
        0.042560599744319916,
        -0.04988116770982742,
        -0.04730922356247902,
        0.05150000751018524,
        -0.0034833690151572227,
        -0.016280878335237503,
        -0.05082816630601883,
        -0.051537953317165375,
        0.051563866436481476,
        -0.0015006059547886252,
        0.05092797055840492,
        0.04767153412103653,
        -0.012297495268285275,
        0.05017614737153053,
        -0.0013746927725151181,
        0.04791415482759476,
        -0.027455506846308708,
        0.05134023353457451,
        -0.051448702812194824,
        -0.05142888426780701,
        0.04962784796953201,
        -0.020518144592642784,
        -0.019005779176950455,
        0.04791302978992462,
        0.010198238305747509,
        -0.05064001679420471,
        0.04818982258439064,
        -0.05154445394873619,
        0.05148300155997276,
        -0.003895255271345377,
        0.0013288388727232814,
        -0.051237836480140686,
        0.048820484429597855,
        0.013660498894751072,
        0.0016426843358203769,
        -0.051141127943992615,
        0.04246516525745392,
        0.0515412837266922,
        0.05002869293093681,
        0.05117543786764145,
        -0.042164143174886703,
        0.050732627511024475,
        0.0513656921684742,
        -0.03889930248260498,
        0.05134257301688194,
        0.05114720016717911,
        -0.00839673075824976,
        0.04029088094830513,
        0.02044210396707058,
        -0.05019110441207886,
        0.04981725290417671,
        -0.019298521801829338,
        0.051417361944913864,
        0.04736461117863655,
        -0.05157216638326645,
        0.050542961806058884,
        0.04476343095302582,
        -0.020697982981801033,
        0.04964655265212059,
        -0.04070279374718666,
        -0.051570046693086624,
        0.038844507187604904,
        -0.015501272864639759,
        0.03989144787192345,
        -0.04007299989461899,
        0.02449367195367813,
        -0.04656178504228592,
        -0.050853196531534195,
        -0.015789469704031944,
        0.05157005041837692,
        -0.050460074096918106,
        -0.04647517576813698,
        -0.051488716155290604,
        -0.049138519912958145,
        -0.05155985429883003,
        0.04679510369896889,
        -0.0010061300126835704,
        -0.05111612007021904,
        -0.05011575669050217,
        0.033407002687454224,
        0.04733147844672203,
        -0.04657909646630287,
        0.05129668116569519,
        0.037746112793684006,
        0.05147353932261467,
        -0.0507003515958786,
        0.010578865185379982,
        -0.03824605792760849,
        0.031088100746273994,
        0.05157117545604706,
        0.046471498906612396,
        0.014592613093554974,
        0.05086294934153557,
        -0.026605717837810516,
        0.05100448802113533,
        0.05132337287068367,
        -0.022023379802703857,
        0.05094097554683685,
        -0.051455121487379074,
        -0.02911478281021118,
        0.05103304982185364,
        -0.05090240761637688,
        -0.0032607968896627426,
        -0.05131096392869949,
        -0.006828607060015202,
        0.05132249742746353,
        0.05141233652830124,
        -0.05056793615221977,
        0.043194327503442764,
        -0.021297773346304893,
        -0.05139395222067833,
        0.05114476755261421,
        0.03628433495759964,
        -0.05127016827464104,
        -0.05155003443360329,
        -0.04538428783416748,
        -0.05157177150249481,
        0.005981788504868746,
        -0.05042155086994171,
        0.05157050862908363,
        0.05121317878365517,
        0.03786133602261543,
        0.017497103661298752,
        0.04795272275805473,
        0.04936040937900543,
        -0.03993630409240723,
        0.051552701741456985,
        -0.03770608827471733,
        0.05065058916807175,
        0.05157165974378586,
        -0.019038747996091843,
        -0.04205561801791191,
        -0.05145465210080147,
        -0.05156773328781128,
        -0.045958179980516434,
        0.0006210129940882325,
        0.04905788600444794,
        -0.05157274007797241,
        0.015745138749480247,
        -0.051037464290857315,
        0.051284316927194595,
        0.018196264281868935,
        -0.047254037111997604,
        0.018624750897288322,
        -0.05156881734728813,
        0.04060469940304756,
        0.05157245695590973,
        0.05011765658855438,
        0.05156034603714943,
        -0.05138847976922989,
        0.044893741607666016,
        0.05147114396095276,
        -0.051234710961580276,
        0.05152439698576927,
        0.0252531711012125,
        -0.007268141955137253,
        -0.05154672637581825,
        0.048404473811388016,
        -0.0187888965010643,
        0.03286353498697281,
        0.013854685239493847,
        -0.023183703422546387,
        0.03895857557654381,
        0.03427434340119362,
        0.049686234444379807,
        0.051491230726242065,
        -0.05148017033934593,
        -0.05152418464422226,
        -0.05136822536587715,
        -0.050819773226976395,
        -0.0451958030462265,
        0.017458876594901085,
        -0.05126497521996498,
        -0.04926484078168869,
        -0.05157153308391571,
        0.045635201036930084,
        0.021412914618849754,
        0.04430384933948517,
        0.05080471187829971,
        0.051563311368227005,
        -0.04579516127705574,
        -0.0512818805873394,
        -0.024291593581438065,
        0.039172589778900146,
        -0.051311735063791275,
        0.017384842038154602,
        0.04246724396944046,
        0.048629648983478546,
        0.025121372193098068,
        0.044227514415979385,
        0.033636003732681274,
        0.045348767191171646,
        -0.05122236907482147,
        0.025369467213749886,
        0.05157093331217766,
        0.04255768284201622,
        0.04618468135595322,
        -0.0024035645183175802,
        0.03481346741318703,
        -0.05135655403137207,
        -0.03172765299677849,
        -0.05155305936932564,
        0.05154063552618027,
        -0.017057163640856743,
        -0.05015570670366287,
        0.05100756511092186,
        -0.050819482654333115,
        -0.04477028176188469,
        -0.03726233169436455,
        -0.02496788278222084,
        0.051544662564992905,
        -0.05139060690999031,
        0.05141498148441315,
        0.05047021433711052,
        -0.051558710634708405,
        0.05138719081878662,
        0.0507737472653389,
        0.05152279511094093,
        0.050441306084394455,
        0.051297351717948914,
        0.0179036445915699,
        0.05115918815135956,
        0.0512482188642025,
        -0.03542523458600044,
        -0.05149795114994049,
        -0.05105729028582573,
        0.0044956449419260025,
        0.05053212493658066,
        -0.02644982375204563,
        -0.013114264234900475,
        0.034634027630090714,
        0.05157184228301048,
        0.051371507346630096,
        -0.05152277275919914,
        -0.04966283589601517,
        -0.030981311574578285,
        -0.04767333343625069,
        0.04840736463665962,
        0.05156750604510307,
        -0.051570452749729156,
        -0.04916738346219063,
        -0.051522452384233475,
        0.015052017755806446,
        0.05155278369784355,
        0.044122450053691864,
        -0.05139127001166344,
        -0.04967353492975235,
        0.05118371546268463,
        -0.034481484442949295,
        0.050683993846178055,
        0.05157032981514931,
        0.04234789311885834,
        0.03184891119599342,
        0.05031314492225647,
        -0.0515676774084568,
        0.05154554918408394,
        0.04983213543891907
    ],
    [
        0.05368075892329216,
        0.046931564807891846,
        0.05585140362381935,
        0.04390425980091095,
        -0.043687447905540466,
        -0.003688671626150608,
        0.046689722687006,
        -0.05276201665401459,
        0.05232410505414009,
        -0.009788710623979568,
        0.05236209183931351,
        0.0330624021589756,
        -0.036983609199523926,
        -0.02618364803493023,
        -0.061333540827035904,
        0.00016434438293799758,
        0.052417561411857605,
        0.0111600486561656,
        0.05075075477361679,
        0.04707441106438637,
        0.06148800998926163,
        -0.03193230554461479,
        0.05599912256002426,
        -0.03321727365255356,
        0.00275077554397285,
        0.06082215532660484,
        0.058128077536821365,
        0.035187218338251114,
        -0.04941948503255844,
        -0.057837795466184616,
        0.040930017828941345,
        0.05889078229665756,
        0.05816592648625374,
        -0.0521855466067791,
        0.019477741792798042,
        -0.059166379272937775,
        -0.0593259334564209,
        -0.05794191360473633,
        -0.06154157593846321,
        -0.04590974375605583,
        0.039164215326309204,
        0.028226731345057487,
        0.05170278251171112,
        -0.002139804419130087,
        0.04496966674923897,
        0.005417921114712954,
        0.04182611405849457,
        0.061965592205524445,
        0.06080729886889458,
        0.031120730563998222,
        0.04807626083493233,
        0.06130378320813179,
        -0.03918051719665527,
        0.012305336073040962,
        0.038961272686719894,
        0.029704898595809937,
        -0.06111535802483559,
        -0.01918327994644642,
        -0.05873556062579155,
        -0.03909996896982193,
        -0.05104316771030426,
        0.010643831454217434,
        -0.05669315904378891,
        0.03041645884513855,
        -0.03213762864470482,
        -0.048693202435970306,
        -0.06114889681339264,
        0.046810418367385864,
        0.02668391354382038,
        -0.014171752147376537,
        0.011856130324304104,
        0.05901399999856949,
        0.0595257505774498,
        0.06072500720620155,
        0.04868543893098831,
        -0.05103341490030289,
        0.06016091629862785,
        0.06197268143296242,
        0.03452431410551071,
        -0.001609823782928288,
        -0.03559498116374016,
        -0.04621979966759682,
        -0.05335037037730217,
        0.05743870511651039,
        -0.05009959265589714,
        0.0608166940510273,
        0.029298903420567513,
        -0.05404561758041382,
        0.002228716155514121,
        0.05524485185742378,
        -0.060871947556734085,
        -0.03228241577744484,
        -0.03821844607591629,
        -0.05673348903656006,
        -0.03466362506151199,
        0.027544504031538963,
        0.04484861344099045,
        -0.05727574601769447,
        -0.01865282468497753,
        0.02748808264732361,
        -0.05502013489603996,
        0.009922502562403679,
        0.002901464467868209,
        0.059800244867801666,
        0.04689721390604973,
        -0.055448342114686966,
        -0.03054972179234028,
        0.010652280412614346,
        0.026950350031256676,
        -0.03033856861293316,
        -0.027498414739966393,
        0.012643566355109215,
        -0.03765268623828888,
        0.038738541305065155,
        0.06109992787241936,
        0.047374557703733444,
        -0.06143001466989517,
        0.020766839385032654,
        0.003569249529391527,
        -0.04828295484185219,
        0.014702006243169308,
        -0.052271150052547455,
        0.019023852422833443,
        -0.0619153194129467,
        -0.0556710883975029,
        0.0012004051823168993,
        -0.06099023297429085,
        0.0057111443020403385,
        0.06144949421286583,
        0.05870477482676506,
        0.04515965282917023,
        -0.04618636891245842,
        -0.020552128553390503,
        0.061459604650735855,
        -0.04498137906193733,
        -0.05158943310379982,
        0.0128431161865592,
        0.033634837716817856,
        -0.04832228273153305,
        0.01936398260295391,
        -0.015368578024208546,
        -0.025497466325759888,
        0.03349551558494568,
        0.05981528013944626,
        -0.04404788091778755,
        0.024565670639276505,
        0.03323731571435928,
        -0.055037811398506165,
        -0.011494845151901245,
        0.0011424330295994878,
        -0.01117502897977829,
        -0.055889517068862915,
        0.06099185720086098,
        -0.05836818367242813,
        -0.05785349756479263,
        -0.049233101308345795,
        -0.06124956160783768,
        -0.02471432462334633,
        0.06168889254331589,
        0.018101003021001816,
        -0.04566758871078491,
        -0.034783538430929184,
        0.061179690062999725,
        -0.037565749138593674,
        -0.009470723569393158,
        0.044526003301143646,
        -0.04429217055439949,
        -0.03408129885792732,
        -0.04974750429391861,
        0.036938589066267014,
        -0.0002872220939025283,
        0.02327892743051052,
        -0.05701293423771858,
        0.060814499855041504,
        0.061975933611392975,
        -0.0378405824303627,
        0.04699819162487984,
        -0.023295728489756584,
        0.060712676495313644,
        -0.03511896729469299,
        -0.05074672773480415,
        0.05899089574813843,
        0.0365254245698452,
        -0.02884356491267681,
        -0.057701848447322845,
        -0.008940736763179302,
        0.03855586796998978,
        0.05914681777358055,
        0.018330968916416168,
        0.05527234077453613,
        -0.02984125167131424,
        -0.02121642790734768,
        0.02395172417163849,
        0.01593571901321411,
        -0.06125964596867561,
        0.03702724352478981,
        0.031963806599378586,
        -0.034092843532562256,
        -0.04540759325027466,
        -0.023782892152667046,
        0.04326995089650154,
        0.034988466650247574,
        -0.05124562978744507,
        -0.061230286955833435,
        0.05190841108560562,
        -0.016610855236649513,
        0.05329177901148796,
        0.059879034757614136,
        -0.004055309575051069,
        0.05904192104935646,
        -0.03276528790593147,
        0.04670784994959831,
        0.029109247028827667,
        0.05034845322370529,
        -0.04256250709295273,
        -0.05677857622504234,
        0.048267822712659836,
        -0.0419231541454792,
        0.057205189019441605,
        -0.03910364583134651,
        0.05365785211324692,
        0.061686255037784576,
        -0.03926673159003258,
        -0.015444640070199966,
        -0.0012614898150786757,
        0.06023794785141945,
        0.02561921812593937,
        -0.041593506932258606,
        0.05424288660287857,
        -0.059668876230716705,
        -0.060770824551582336,
        0.05445883423089981,
        0.03171411156654358,
        0.06008556857705116,
        -0.0452236644923687,
        0.06101084500551224,
        0.04872281476855278,
        -0.024038203060626984,
        -0.04999012500047684,
        0.061967745423316956,
        0.03954730182886124,
        0.06112043932080269,
        -0.038441602140665054,
        -0.027527904137969017,
        0.030999604612588882,
        -0.02438287064433098,
        -0.06156867370009422,
        -0.04110608249902725,
        0.027449894696474075,
        -0.048897337168455124,
        -0.061083074659109116,
        -0.05719788372516632,
        -0.018370604142546654,
        -0.05249606445431709,
        -0.016975002363324165,
        0.054831184446811676,
        -0.01344398595392704,
        0.059615593403577805,
        0.040764112025499344,
        0.017415441572666168,
        0.017224999144673347,
        -0.06090725585818291,
        -0.023586146533489227,
        -0.01737045869231224,
        -0.0501556433737278,
        0.06159616634249687,
        0.0598965585231781,
        0.05881659686565399,
        -0.01020662672817707,
        -0.04545857384800911,
        0.032708484679460526,
        -0.04801337048411369,
        0.05518937110900879,
        -0.04702213779091835,
        -0.05779343843460083,
        0.001363624818623066,
        -0.06116873025894165,
        -0.0005245085922069848,
        0.044588420540094376,
        0.019087407737970352,
        -0.0615554004907608,
        0.061345405876636505,
        -0.031914737075567245,
        0.009824602864682674,
        0.019717132672667503,
        -0.011036190204322338,
        0.010591580532491207,
        0.050188228487968445,
        0.047133613377809525,
        0.05018400773406029,
        -0.05687715485692024,
        0.041984859853982925,
        -0.05149067938327789,
        0.006512537132948637,
        0.04919250309467316,
        -0.059259891510009766,
        0.06094418466091156,
        0.0476963147521019,
        -0.029346134513616562,
        0.048345450311899185,
        0.0013787264470010996,
        0.023165853694081306,
        -0.03934626281261444,
        0.059247225522994995,
        0.04752516746520996,
        -0.018557563424110413,
        0.052147120237350464,
        0.03883422166109085,
        0.05334366858005524,
        0.006584938149899244,
        0.058604296296834946,
        -0.04072173312306404,
        0.05501708388328552,
        0.0317421481013298,
        -0.0475349985063076,
        -0.05840570479631424,
        0.03394921496510506,
        -0.05283452197909355,
        0.059024274349212646,
        0.0390605591237545,
        -0.015578013844788074,
        0.03078431449830532,
        0.026566140353679657,
        -0.061851873993873596,
        0.052355993539094925,
        -0.054569464176893234,
        0.015186816453933716,
        0.021319083869457245,
        0.05445375666022301,
        -0.06016216054558754,
        0.010551521554589272,
        -0.06093468517065048,
        -0.05738404393196106,
        -0.03702645003795624,
        0.051281098276376724,
        0.038425784558057785,
        -0.014131148345768452,
        -0.021024320274591446,
        0.006063413806259632,
        -0.012160221114754677,
        -0.043521441519260406,
        -0.04765031486749649,
        -0.04304319620132446,
        -0.04004736244678497,
        -0.011986270546913147,
        -0.007393543142825365,
        0.0603155642747879,
        0.0605408176779747,
        -0.02556638978421688,
        0.05794259533286095,
        0.05965324491262436,
        0.043665383011102676,
        0.0028681254480034113,
        -0.06123891472816467,
        0.05841066315770149,
        -0.0431387759745121,
        -0.04014512896537781,
        -0.040543776005506516,
        -0.055690668523311615,
        0.01452555786818266,
        0.047316066920757294,
        0.0023677852004766464,
        -0.058127835392951965,
        -0.0525759719312191,
        0.013737430796027184,
        -0.0471683107316494,
        0.06116460636258125,
        0.06013094633817673,
        0.024023596197366714,
        -0.05638976767659187,
        -0.005634298548102379,
        -0.008558486588299274,
        0.03673559054732323,
        -0.006768755614757538,
        0.056797172874212265,
        0.03219856694340706,
        0.055597759783267975,
        -0.03692435845732689,
        0.05930028110742569,
        -0.05402439087629318,
        -0.050204794853925705,
        0.03620130196213722,
        -0.059351179748773575,
        0.024509474635124207,
        0.024203244596719742,
        -0.06125134229660034,
        -0.02249237336218357,
        -0.04453026130795479,
        -0.04552039876580238,
        -0.03754613175988197,
        -0.05371765419840813,
        0.02068496309220791,
        0.013427458703517914,
        -0.03896572068333626,
        -0.034332744777202606,
        0.05995377153158188,
        0.0444653294980526,
        -0.03633399307727814,
        -0.04843946918845177,
        -0.013906273990869522,
        0.03785406053066254,
        0.058139950037002563,
        0.047690413892269135,
        0.053869470953941345,
        -0.015919342637062073,
        0.044268034398555756,
        0.05554662272334099,
        -0.057224370539188385,
        0.055598184466362,
        -0.042447175830602646,
        0.030803048983216286,
        -0.0071987672708928585,
        0.051372967660427094,
        -0.05872681364417076,
        0.04585927724838257,
        -0.057180095463991165,
        0.03677981346845627,
        0.0035023519303649664,
        0.01974211446940899,
        -0.0584818571805954,
        0.061440225690603256,
        -0.0332777313888073,
        -0.06106632575392723,
        -0.057667266577482224,
        -0.03329870104789734,
        -0.03202679753303528,
        -0.042241647839546204,
        -0.030276915058493614,
        -0.012774613685905933,
        -0.06064387410879135,
        -0.05297498404979706,
        0.006366095505654812,
        0.056952543556690216,
        -0.013448569923639297,
        -0.056933097541332245,
        -0.058184582740068436,
        -0.04015425965189934,
        0.02543654479086399,
        0.01609591767191887,
        -0.056953977793455124,
        0.05904248729348183,
        0.06047144532203674,
        0.05912511795759201,
        0.058601271361112595,
        0.061941083520650864,
        -0.03837689012289047,
        0.057697754353284836,
        -0.043504755944013596,
        0.04200928285717964,
        0.059144217520952225,
        -0.00696489168331027,
        0.05987071245908737,
        -0.03758352994918823,
        0.06039680168032646,
        -0.0020718432497233152,
        -0.05930139496922493,
        0.027917688712477684,
        -0.028975563123822212,
        0.05661031976342201,
        0.05955533683300018,
        0.06193488463759422,
        -0.0560092031955719,
        -0.014553273096680641,
        -0.03476543352007866,
        0.04610146954655647,
        0.06123843044042587,
        -0.04527157545089722,
        0.0538078211247921,
        0.013279994949698448,
        -0.057114407420158386,
        -0.03494429215788841,
        -0.06156158819794655,
        0.0586220882833004,
        -0.013330715708434582,
        0.04566192254424095,
        0.018962087109684944,
        -0.025330008938908577,
        0.0543459877371788,
        -0.05165703594684601,
        0.004273703787475824,
        0.028972217813134193,
        -0.0461629219353199,
        -0.015197742730379105,
        0.010280925780534744,
        0.06023756042122841,
        0.007246206048876047,
        0.06157490238547325,
        0.05916774645447731,
        -0.051513250917196274,
        -0.0020955654326826334,
        -0.009233376011252403,
        -0.04820575192570686,
        0.05876145139336586,
        0.03353576734662056,
        -0.025267673656344414,
        0.04360759258270264,
        0.007252258248627186,
        -0.017425643280148506,
        -0.008765471167862415,
        0.05701049789786339,
        -0.06005597114562988,
        -0.0007210278417915106,
        -0.05960431694984436,
        -0.022684136405587196,
        0.04150928184390068,
        -0.035967517644166946,
        0.05566766858100891,
        0.031396400183439255,
        0.06131172552704811,
        -0.06010662019252777,
        -0.06161912903189659,
        -0.035174302756786346
    ],
    [
        0.013543304055929184,
        0.03667603060603142,
        0.055377375334501266,
        0.017850620672106743,
        0.035648416727781296,
        -0.056964945048093796,
        0.05756396800279617,
        -0.04965553060173988,
        0.02169491909444332,
        0.05177014321088791,
        -0.001194918411783874,
        0.05257689952850342,
        -0.044839758425951004,
        -0.05178673565387726,
        -0.056959327310323715,
        0.03812200203537941,
        -0.03863651677966118,
        0.04050864279270172,
        -0.042855724692344666,
        0.044379375874996185,
        0.04030429944396019,
        0.03887480869889259,
        0.0555306114256382,
        -0.04416612908244133,
        0.04438086599111557,
        0.03875989839434624,
        0.052498865872621536,
        -0.019086139276623726,
        -0.058088745921850204,
        -0.05561751872301102,
        0.05209580808877945,
        0.0018512583337724209,
        0.020110508427023888,
        -0.0571594312787056,
        0.016706474125385284,
        -0.01817375048995018,
        -0.05463435873389244,
        -0.05174817144870758,
        -0.05822904407978058,
        -0.05508055165410042,
        0.028304079547524452,
        0.014069339260458946,
        0.051331255584955215,
        -0.015980074182152748,
        0.02889087051153183,
        -0.01335864420980215,
        0.014778658747673035,
        0.0211753211915493,
        0.05781355872750282,
        0.0440838485956192,
        0.04997728392481804,
        0.05160927027463913,
        -0.011746608652174473,
        0.04012230411171913,
        -0.05072704330086708,
        0.030498959124088287,
        -0.05168639123439789,
        -0.05324571952223778,
        -0.029072709381580353,
        -0.046734098345041275,
        -0.05542049929499626,
        -0.04597809910774231,
        -0.044152453541755676,
        0.05704586207866669,
        -0.055558111518621445,
        -0.013761231675744057,
        -0.024978632107377052,
        0.036845117807388306,
        0.05819441005587578,
        -0.045234519988298416,
        -0.05453726276755333,
        -0.036932431161403656,
        -0.004295438528060913,
        -0.009627790190279484,
        0.023562023416161537,
        -0.0031496607698500156,
        0.043591633439064026,
        0.05768018215894699,
        -0.025272736325860023,
        -0.020105630159378052,
        -0.03702499344944954,
        0.03801034763455391,
        -0.04355428367853165,
        0.04044577106833458,
        -0.05406301096081734,
        0.041526440531015396,
        0.029282210394740105,
        -0.05656411871314049,
        0.0513603538274765,
        0.05741085484623909,
        0.04241584986448288,
        0.04801363870501518,
        -0.04635404795408249,
        -0.055115677416324615,
        -0.022462623193860054,
        0.056471940129995346,
        -0.05185506120324135,
        -0.05188700556755066,
        -0.05757378414273262,
        0.0026533284690231085,
        -0.05488523095846176,
        0.012825761921703815,
        0.05270513892173767,
        0.002282088855281472,
        0.05522119626402855,
        -0.05770750716328621,
        -0.057169947773218155,
        0.049660295248031616,
        -0.057445280253887177,
        0.0004511715960688889,
        -0.04523623362183571,
        -0.05575523152947426,
        0.006761827971786261,
        -0.027161337435245514,
        0.058167826384305954,
        -0.03361775353550911,
        0.005236593075096607,
        0.015402384102344513,
        0.01760558784008026,
        0.012920484878122807,
        -0.04235003516077995,
        -0.03485400602221489,
        -0.010378592647612095,
        -0.04815969616174698,
        -0.057657722383737564,
        -0.039517078548669815,
        -0.057123780250549316,
        0.027708768844604492,
        0.05581891909241676,
        0.04123493283987045,
        0.03249457851052284,
        -0.055433984845876694,
        0.015648508444428444,
        0.05748288705945015,
        -0.05591706186532974,
        -0.051364313811063766,
        0.03430897742509842,
        -0.03203430399298668,
        -0.0579492561519146,
        0.05208661034703255,
        0.05667273700237274,
        -0.04759738966822624,
        0.05287359282374382,
        0.04015626013278961,
        0.00012016447726637125,
        0.016001418232917786,
        0.0453026182949543,
        -0.012373208068311214,
        -0.022562967613339424,
        0.05583864077925682,
        -0.05582614615559578,
        -0.05774321407079697,
        0.04015008732676506,
        0.004805692471563816,
        -0.05762746185064316,
        -0.04686751216650009,
        0.04914186894893646,
        -0.05400056391954422,
        0.057362642139196396,
        -0.037371065467596054,
        -0.026197709143161774,
        0.025050457566976547,
        0.05147988349199295,
        0.012066347524523735,
        -0.05290570855140686,
        -0.055399466305971146,
        -0.017359623685479164,
        -0.055363044142723083,
        0.0377686433494091,
        0.017180435359477997,
        0.002657555742189288,
        -0.05727876350283623,
        -0.05608879774808884,
        0.0539616160094738,
        0.05822575464844704,
        0.0570133775472641,
        -0.05748271197080612,
        0.048173028975725174,
        0.057825829833745956,
        0.04203727841377258,
        -0.009636606089770794,
        -0.04567348212003708,
        0.05756969749927521,
        -0.03610372915863991,
        0.03382711485028267,
        0.010960336774587631,
        -0.02902362495660782,
        0.05579499900341034,
        -0.05421435832977295,
        0.049747347831726074,
        0.03277495503425598,
        -0.02033325470983982,
        0.00006714388291584328,
        0.028162691742181778,
        -0.046650588512420654,
        -0.021662099286913872,
        -0.047875434160232544,
        0.05352594330906868,
        0.05219186469912529,
        0.021347135305404663,
        -0.007420830428600311,
        0.05457926169037819,
        -0.04293295368552208,
        -0.00041162624256685376,
        0.033062320202589035,
        -0.002001304179430008,
        0.029758619144558907,
        0.03445754945278168,
        -0.049371350556612015,
        0.03912387415766716,
        -0.053927212953567505,
        0.05793244391679764,
        -0.04044214263558388,
        0.006955754477530718,
        0.05190720036625862,
        0.00645882124081254,
        0.03513847291469574,
        0.038847748190164566,
        0.0582011342048645,
        -0.05308498069643974,
        0.035136811435222626,
        0.05699777975678444,
        -0.057276319712400436,
        -0.05683683604001999,
        -0.02829802967607975,
        0.05793621391057968,
        -0.030437104403972626,
        0.05640222504734993,
        -0.02807350642979145,
        -0.043673448264598846,
        -0.05814693123102188,
        0.055034637451171875,
        -0.046831704676151276,
        0.04797251150012016,
        -0.009952058084309101,
        0.04909103363752365,
        0.053664229810237885,
        -0.04004736617207527,
        -0.035899724811315536,
        0.05134095624089241,
        0.04617816582322121,
        0.05571562424302101,
        0.024406673386693,
        -0.056271992623806,
        0.038606878370046616,
        -0.03681934252381325,
        -0.05688990280032158,
        -0.008940497413277626,
        -0.05515771731734276,
        -0.0567450113594532,
        -0.05671144276857376,
        -0.04388091713190079,
        -0.0465201772749424,
        -0.052307382225990295,
        -0.022448955103754997,
        0.057527873665094376,
        -0.04273262247443199,
        0.026895062997937202,
        0.05569895729422569,
        0.05423746630549431,
        0.0526890903711319,
        -0.04811837896704674,
        -0.05350171774625778,
        0.05675066262483597,
        -0.032347869127988815,
        0.048481687903404236,
        0.052203018218278885,
        0.05604354292154312,
        -0.014226431027054787,
        -0.05801630765199661,
        0.007448161486536264,
        -0.05783061310648918,
        0.057862844318151474,
        -0.05467183515429497,
        -0.05507149174809456,
        0.0523160845041275,
        -0.05678375065326691,
        -0.0461275540292263,
        -0.05801653861999512,
        0.05672892555594444,
        -0.05673849210143089,
        -0.02913803607225418,
        0.01490730606019497,
        0.021806875243782997,
        0.056179895997047424,
        -0.05593445152044296,
        0.039345718920230865,
        -0.04555904120206833,
        -0.03687923401594162,
        0.041916485875844955,
        -0.03856534883379936,
        0.05440589040517807,
        -0.05229000747203827,
        0.04969342425465584,
        0.03560476005077362,
        -0.05384628847241402,
        0.05816400796175003,
        -0.051138971000909805,
        0.023160085082054138,
        0.042376745492219925,
        -0.05664203688502312,
        0.05387604236602783,
        -0.006835289765149355,
        0.0474090613424778,
        0.029003432020545006,
        0.04044800624251366,
        0.032293323427438736,
        0.031852416694164276,
        0.009097297675907612,
        -0.055094800889492035,
        0.04433929920196533,
        0.003750431817024946,
        -0.04518667608499527,
        0.0036278932821005583,
        -0.05822449177503586,
        -0.05544064939022064,
        0.05576810613274574,
        -0.05731220170855522,
        0.015899570658802986,
        -0.055497702211141586,
        -0.04824288934469223,
        0.023653779178857803,
        0.03905416280031204,
        -0.057929039001464844,
        0.0491945743560791,
        -0.010548427700996399,
        0.05629332736134529,
        0.018194355070590973,
        0.057725463062524796,
        -0.042425721883773804,
        -0.029929403215646744,
        0.03804557025432587,
        -0.017585473135113716,
        -0.03263323754072189,
        0.05584347993135452,
        0.052273835986852646,
        -0.04390387982130051,
        0.0534050278365612,
        -0.05395791307091713,
        0.03888295590877533,
        0.03196088969707489,
        -0.02973240800201893,
        0.04676459729671478,
        -0.006013845093548298,
        0.05822761729359627,
        0.026218391954898834,
        -0.010555747896432877,
        0.04563964903354645,
        0.053157031536102295,
        0.042700186371803284,
        -0.053913552314043045,
        -0.039912547916173935,
        -0.05043228715658188,
        -0.051309142261743546,
        -0.05513879284262657,
        0.056577056646347046,
        -0.05821385234594345,
        -0.03325188159942627,
        -0.052036821842193604,
        -0.054581355303525925,
        0.055536624044179916,
        0.0547962412238121,
        -0.03521984815597534,
        -0.02542474865913391,
        -0.02386736124753952,
        -0.031702447682619095,
        0.057904258370399475,
        0.04925813525915146,
        -0.02999480627477169,
        -0.05760650336742401,
        -0.04294024035334587,
        -0.01134198997169733,
        0.04811534658074379,
        0.05475509166717529,
        0.055279433727264404,
        0.052037402987480164,
        0.056191012263298035,
        -0.025331085547804832,
        -0.037650804966688156,
        -0.05033145472407341,
        0.020842626690864563,
        0.011361942626535892,
        -0.04728258028626442,
        0.009374510496854782,
        0.057836391031742096,
        -0.05120402202010155,
        0.038494862616062164,
        -0.04066827520728111,
        -0.05754096433520317,
        -0.010511736385524273,
        0.01600147783756256,
        0.01993444375693798,
        0.008016923442482948,
        -0.04896014183759689,
        0.01471412181854248,
        0.05538227781653404,
        0.05750041827559471,
        0.054889533668756485,
        -0.054646220058202744,
        -0.05745990201830864,
        -0.047535207122564316,
        0.053427111357450485,
        0.05680569261312485,
        0.05752519145607948,
        -0.043633636087179184,
        -0.026021359488368034,
        0.0432288683950901,
        -0.032207466661930084,
        0.05481049045920372,
        0.026265639811754227,
        0.050318896770477295,
        -0.05475431680679321,
        -0.04294120892882347,
        -0.0497438982129097,
        0.053384482860565186,
        0.03319259360432625,
        0.030389679595828056,
        0.048244658857584,
        0.025837430730462074,
        -0.04539695754647255,
        0.017989134415984154,
        -0.052087172865867615,
        -0.057223327457904816,
        -0.05666385963559151,
        -0.0018996509024873376,
        0.050457343459129333,
        -0.05808355286717415,
        -0.02074073813855648,
        0.034611381590366364,
        -0.04865473136305809,
        -0.043963588774204254,
        -0.027491077780723572,
        -0.05015327036380768,
        0.05023491755127907,
        0.032812222838401794,
        -0.042566124349832535,
        -0.05095228552818298,
        0.011565720662474632,
        0.04658832401037216,
        -0.04653726890683174,
        0.054634034633636475,
        0.05803804099559784,
        -0.04213397204875946,
        0.04926000535488129,
        0.05822739005088806,
        -0.05566396191716194,
        0.05746873468160629,
        -0.04820389673113823,
        0.04123229905962944,
        -0.05185128003358841,
        -0.044141534715890884,
        0.05154591426253319,
        -0.002096668118610978,
        0.05587588623166084,
        -0.016186287626624107,
        -0.045343067497015,
        -0.029611585661768913,
        0.0008440660894848406,
        -0.04925510287284851,
        0.0567258857190609,
        0.05822573974728584,
        -0.03565910831093788,
        -0.050187818706035614,
        -0.05410012602806091,
        0.04586942493915558,
        0.05795435979962349,
        -0.05068062245845795,
        0.05761145427823067,
        -0.006068922113627195,
        -0.05703612044453621,
        0.054840393364429474,
        -0.05818481370806694,
        0.03757530823349953,
        0.053136903792619705,
        0.057102449238300323,
        0.04020141810178757,
        -0.05467019975185394,
        0.053982049226760864,
        -0.05378960818052292,
        -0.05259585753083229,
        -0.04740222543478012,
        -0.005206901580095291,
        0.050523750483989716,
        -0.0031018180307000875,
        0.026777809485793114,
        0.05382241681218147,
        0.057500675320625305,
        0.031176310032606125,
        -0.054842472076416016,
        0.05572465434670448,
        -0.04210826754570007,
        -0.05305466055870056,
        0.03036966733634472,
        0.036702729761600494,
        -0.03287138789892197,
        0.032228365540504456,
        -0.051992323249578476,
        -0.022619454190135002,
        0.04954593628644943,
        0.031361717730760574,
        -0.057054657489061356,
        -0.05485698953270912,
        0.04673964902758598,
        0.03248186782002449,
        -0.009997549466788769,
        -0.0076299868524074554,
        -0.05090905725955963,
        0.019456712529063225,
        0.05731159821152687,
        -0.05805709585547447,
        -0.043289780616760254,
        0.04958543926477432
    ],
    [
        -0.01121620275080204,
        0.03820401802659035,
        0.04664551839232445,
        -0.0033691818825900555,
        0.04368912801146507,
        -0.038040291517972946,
        0.061103738844394684,
        -0.03917471691966057,
        0.026895757764577866,
        0.05922483280301094,
        0.042456600815057755,
        0.056250281631946564,
        -0.05660953372716904,
        0.015906786546111107,
        -0.05999693647027016,
        0.0038172113709151745,
        -0.061555758118629456,
        0.024667546153068542,
        -0.03177332505583763,
        0.0524669848382473,
        -0.015233965590596199,
        -0.025331169366836548,
        0.047025710344314575,
        0.04155898839235306,
        0.06074124202132225,
        0.04579788073897362,
        0.04637162387371063,
        0.0505012609064579,
        -0.055557820945978165,
        -0.06147618219256401,
        0.05404392629861832,
        -0.027056731283664703,
        0.04128871113061905,
        -0.0397784523665905,
        0.006677050609141588,
        -0.016089366748929024,
        -0.04991507530212402,
        -0.02446124702692032,
        -0.06192237511277199,
        0.0253705233335495,
        0.018422042950987816,
        0.005324236117303371,
        0.054297443479299545,
        -0.05913335829973221,
        0.028661660850048065,
        0.04217677190899849,
        0.048680324107408524,
        0.0553738996386528,
        0.05539223924279213,
        0.02544081024825573,
        0.04440157860517502,
        0.0034357125405222178,
        0.03163178265094757,
        0.022141005843877792,
        -0.055433239787817,
        -0.00332299480214715,
        -0.04619150608778,
        -0.012082539498806,
        -0.04108082503080368,
        0.039298973977565765,
        -0.05501823127269745,
        -0.02455863729119301,
        -0.05698558688163757,
        0.05574227124452591,
        -0.04681003838777542,
        0.021310320124030113,
        0.05375479906797409,
        -0.027117876335978508,
        0.045433904975652695,
        -0.061202555894851685,
        -0.05201170966029167,
        -0.058682411909103394,
        0.03909078985452652,
        0.00742029445245862,
        -0.03243754804134369,
        0.007551065180450678,
        0.057613834738731384,
        0.053932663053274155,
        0.0036311731673777103,
        -0.05437213554978371,
        -0.027722634375095367,
        0.0584489107131958,
        -0.05464380607008934,
        0.014259486459195614,
        -0.046814240515232086,
        0.03847181797027588,
        -0.05477812513709068,
        -0.04997842758893967,
        0.04347868263721466,
        0.061666589230298996,
        -0.02369849942624569,
        0.03761070966720581,
        -0.038533493876457214,
        -0.05037335306406021,
        0.0022821135353296995,
        0.009580280631780624,
        -0.04474838450551033,
        -0.0086915148422122,
        -0.03526896983385086,
        -0.012570313178002834,
        -0.061351168900728226,
        -0.04325464367866516,
        0.05568964034318924,
        0.05387873575091362,
        -0.004105070605874062,
        -0.013282985426485538,
        -0.03709837794303894,
        0.03710472211241722,
        -0.05819704383611679,
        -0.05521722137928009,
        -0.06074213236570358,
        -0.04285911098122597,
        -0.04448444023728371,
        0.012803819961845875,
        0.06156501546502113,
        -0.0018693694146350026,
        -0.05296309292316437,
        -0.053976625204086304,
        -0.055318184196949005,
        -0.01826084405183792,
        -0.04495459422469139,
        0.04479226469993591,
        -0.05565030127763748,
        0.00877689104527235,
        -0.0609559565782547,
        0.019684582948684692,
        -0.042681820690631866,
        -0.02669587917625904,
        0.06030871346592903,
        0.020555926486849785,
        0.051452960819005966,
        -0.05186090245842934,
        -0.03402542322874069,
        0.0370519757270813,
        -0.04664657264947891,
        -0.04869233816862106,
        0.04487183317542076,
        -0.009347441606223583,
        -0.05067586153745651,
        0.05434751510620117,
        0.04529751464724541,
        0.028950443491339684,
        0.030360523611307144,
        0.0037879194132983685,
        0.011757048778235912,
        0.03873469680547714,
        0.060803525149822235,
        -0.05413185805082321,
        -0.05801195651292801,
        0.03785376623272896,
        -0.044151872396469116,
        -0.061353929340839386,
        0.02372770942747593,
        -0.004338239319622517,
        -0.05191999673843384,
        -0.05988498032093048,
        0.06155868247151375,
        -0.0196350309997797,
        0.03611380234360695,
        0.00604167440906167,
        0.02783684805035591,
        0.009283894672989845,
        0.03898279741406441,
        -0.00975118950009346,
        -0.05326719954609871,
        -0.049728360027074814,
        -0.03199160099029541,
        -0.05876772478222847,
        -0.0252238642424345,
        0.033241841942071915,
        -0.0140380859375,
        -0.04191037267446518,
        -0.05876593291759491,
        0.04874978959560394,
        0.06107556074857712,
        0.060094352811574936,
        -0.06023939326405525,
        -0.02226969040930271,
        0.060898732393980026,
        0.052585162222385406,
        0.05159078165888786,
        -0.03406580537557602,
        0.06055498495697975,
        0.03529845178127289,
        0.027584869414567947,
        -0.049664247781038284,
        -0.05739573761820793,
        0.05651727318763733,
        -0.04688452184200287,
        -0.023200517520308495,
        0.014968972653150558,
        -0.04283779859542847,
        -0.05185965448617935,
        0.030111365020275116,
        -0.03392842784523964,
        -0.0014983521541580558,
        0.04616444557905197,
        0.039163973182439804,
        0.01988282799720764,
        -0.0008244060445576906,
        -0.048379797488451004,
        0.05980249494314194,
        0.04408366605639458,
        0.04784126579761505,
        0.01692161336541176,
        0.011936536990106106,
        0.036338768899440765,
        0.03000437468290329,
        -0.014704953879117966,
        -0.03101413883268833,
        -0.05364555865526199,
        0.056413743644952774,
        -0.024784883484244347,
        -0.04220268875360489,
        0.04852278158068657,
        0.03445354849100113,
        0.054811324924230576,
        0.05632539838552475,
        0.059965282678604126,
        -0.020218638703227043,
        0.03487205132842064,
        0.03189859539270401,
        -0.06018654629588127,
        -0.061116766184568405,
        0.04673435911536217,
        -0.012136352248489857,
        0.004032266791909933,
        0.058479562401771545,
        -0.048203881829977036,
        -0.05968800559639931,
        -0.060038838535547256,
        -0.03558063879609108,
        -0.015122024342417717,
        0.042364951223134995,
        0.03070744127035141,
        -0.015757275745272636,
        0.058354832231998444,
        -0.03485092148184776,
        0.040234941989183426,
        0.06056896597146988,
        0.05291770398616791,
        0.03331483155488968,
        0.002499674679711461,
        0.04522164911031723,
        0.008134178817272186,
        -0.019539691507816315,
        -0.053558364510536194,
        -0.03929402679204941,
        -0.03246934711933136,
        -0.02543427236378193,
        -0.03498680144548416,
        -0.05947576463222504,
        -0.04629724472761154,
        -0.05883893743157387,
        -0.0435473695397377,
        0.0609089620411396,
        -0.03999123349785805,
        0.049888622015714645,
        0.059974778443574905,
        0.06124022975564003,
        0.017788484692573547,
        0.002168336883187294,
        -0.03926223888993263,
        0.05006667971611023,
        -0.04758249968290329,
        0.040891967713832855,
        -0.039856765419244766,
        -0.010203559882938862,
        -0.024795176461338997,
        -0.05989055708050728,
        -0.035137273371219635,
        -0.06126067414879799,
        0.046431541442871094,
        -0.05916367098689079,
        -0.058482952415943146,
        0.03273414075374603,
        -0.060848962515592575,
        0.03374168276786804,
        -0.04897918552160263,
        -0.02600511908531189,
        -0.059736218303442,
        -0.032483864575624466,
        -0.008564637042582035,
        0.037309013307094574,
        -0.01521220337599516,
        -0.05898716673254967,
        0.02248310297727585,
        -0.05493152514100075,
        -0.051102541387081146,
        0.05346739664673805,
        -0.04464321210980415,
        0.0569026917219162,
        -0.03166385740041733,
        0.006957688368856907,
        0.05895557999610901,
        -0.055689942091703415,
        0.061562273651361465,
        0.04295773804187775,
        -0.05211131274700165,
        0.053434405475854874,
        -0.013724664226174355,
        0.051742080599069595,
        -0.027439305558800697,
        0.05528785288333893,
        0.04321245476603508,
        -0.03676876798272133,
        -0.02942052111029625,
        0.03953222557902336,
        0.05787054821848869,
        -0.06105155870318413,
        0.058286458253860474,
        0.002584038535133004,
        -0.058073483407497406,
        0.04056644067168236,
        -0.060194846242666245,
        -0.05731619521975517,
        0.06027880683541298,
        -0.03806380555033684,
        -0.0012952222023159266,
        -0.053180448710918427,
        -0.05744783952832222,
        0.029775025323033333,
        0.05993551015853882,
        -0.0354979932308197,
        0.056599486619234085,
        0.04200145974755287,
        0.03779398277401924,
        -0.01192193292081356,
        0.055497728288173676,
        -0.02467626892030239,
        -0.03777427226305008,
        0.039140667766332626,
        0.01292053610086441,
        -0.05456766486167908,
        0.05741025134921074,
        0.022280581295490265,
        -0.055446233600378036,
        0.060483165085315704,
        -0.058311354368925095,
        0.056502532213926315,
        0.012784215621650219,
        -0.03326160088181496,
        -0.03444148972630501,
        -0.026288028806447983,
        0.06194637343287468,
        0.03892761096358299,
        0.037503067404031754,
        0.045872729271650314,
        0.00568123534321785,
        0.057825587689876556,
        -0.057921167463064194,
        0.036120571196079254,
        -0.05313922464847565,
        -0.061162423342466354,
        -0.060311634093523026,
        0.037473324686288834,
        -0.029610561206936836,
        -0.041945163160562515,
        -0.05764113366603851,
        -0.057003460824489594,
        0.05913068354129791,
        0.01556316763162613,
        -0.06063957139849663,
        -0.047545503824949265,
        0.04797327145934105,
        -0.047303520143032074,
        0.061705633997917175,
        0.04341872036457062,
        0.054900068789720535,
        -0.061709605157375336,
        -0.035689860582351685,
        -0.059938181191682816,
        0.05950683727860451,
        0.013109822757542133,
        0.052587419748306274,
        0.06013699993491173,
        0.05031000077724457,
        -0.03457159176468849,
        0.0034896039869636297,
        -0.030147532001137733,
        -0.05107990652322769,
        0.042724497616291046,
        -0.05934587121009827,
        0.037719760090112686,
        0.06022009626030922,
        -0.04917057603597641,
        -0.0069319019094109535,
        -0.055505696684122086,
        -0.043210577219724655,
        -0.055037129670381546,
        -0.048726096749305725,
        0.028008567169308662,
        0.019148673862218857,
        0.025652993470430374,
        -0.018317095935344696,
        0.055900491774082184,
        0.050612762570381165,
        -0.023259814828634262,
        0.01782148890197277,
        -0.05948634818196297,
        -0.03020004741847515,
        0.04065483435988426,
        0.061579298228025436,
        0.061182159930467606,
        -0.038450319319963455,
        0.043230731040239334,
        0.030306370928883553,
        0.020081521943211555,
        0.05560155212879181,
        -0.03687329590320587,
        0.05113312602043152,
        -0.0031710173934698105,
        0.02136005088686943,
        -0.03814390301704407,
        0.03881554678082466,
        0.005493772216141224,
        0.04630390927195549,
        0.03353084623813629,
        0.057847898453474045,
        -0.029682893306016922,
        -0.05621236935257912,
        -0.0558040551841259,
        -0.06111171469092369,
        -0.05968092754483223,
        0.04359941929578781,
        0.03796008229255676,
        -0.05770202726125717,
        -0.0470643974840641,
        -0.0607466958463192,
        -0.052577268332242966,
        0.05058743804693222,
        0.0058936201967298985,
        -0.055917784571647644,
        0.012782773934304714,
        0.05324766784906387,
        -0.02864978089928627,
        -0.05035042390227318,
        0.04794231057167053,
        0.05858347937464714,
        -0.05221788212656975,
        0.053076643496751785,
        0.05053574591875076,
        0.02274962328374386,
        0.05064534768462181,
        0.06112542375922203,
        0.006948388647288084,
        0.06009345501661301,
        -0.022682104259729385,
        0.05513712018728256,
        -0.022548943758010864,
        -0.059690993279218674,
        0.06131426990032196,
        0.04085814952850342,
        0.04485849291086197,
        0.04498428478837013,
        -0.04958733171224594,
        0.05353165790438652,
        0.027736250311136246,
        -0.03885467350482941,
        0.05145171284675598,
        0.06059806048870087,
        -0.035302892327308655,
        -0.05243758112192154,
        -0.04903120547533035,
        0.05285444110631943,
        0.061507027596235275,
        -0.060781724750995636,
        0.06046503782272339,
        -0.06088126823306084,
        -0.06095018982887268,
        0.04136834293603897,
        -0.045915111899375916,
        0.0011811235453933477,
        0.003355365479364991,
        0.06074918061494827,
        0.05186619609594345,
        -0.019063323736190796,
        -0.00019405530474614352,
        -0.05842811241745949,
        -0.036985576152801514,
        -0.012384364381432533,
        -0.038751568645238876,
        0.06011241301894188,
        0.052726779133081436,
        0.05479081720113754,
        0.03574409335851669,
        0.061347510665655136,
        -0.0501786507666111,
        -0.05528264492750168,
        -0.03711122274398804,
        -0.0463506281375885,
        -0.04623096436262131,
        0.03744548559188843,
        0.051040466874837875,
        0.03767777606844902,
        0.03663640841841698,
        -0.06169603765010834,
        -0.039628155529499054,
        0.053977563977241516,
        0.029451025649905205,
        -0.03623992204666138,
        -0.04752986878156662,
        0.035785168409347534,
        0.029999012127518654,
        -0.016068091616034508,
        0.032461561262607574,
        -0.020039644092321396,
        0.02564207650721073,
        -0.028654271736741066,
        -0.0374186672270298,
        0.049201447516679764,
        0.05749915540218353
    ],
    [
        -0.03232662379741669,
        -0.004615705460309982,
        0.046899110078811646,
        0.014773344621062279,
        -0.015828780829906464,
        -0.03652865067124367,
        0.058670107275247574,
        -0.03480442240834236,
        -0.04948520287871361,
        0.05637292191386223,
        0.05921916663646698,
        0.057706914842128754,
        -0.01799403689801693,
        0.04981537535786629,
        -0.058833472430706024,
        -0.054913707077503204,
        -0.05975576862692833,
        -0.01615453138947487,
        -0.05997033417224884,
        0.05689321458339691,
        0.05647027865052223,
        -0.05355735868215561,
        0.053756795823574066,
        0.043330319225788116,
        0.01622200384736061,
        0.0298615749925375,
        -0.008650928735733032,
        0.059870392084121704,
        -0.060855742543935776,
        0.01855216734111309,
        0.013605996966362,
        0.03632790595293045,
        0.006376654375344515,
        -0.06042129918932915,
        0.014767282642424107,
        -0.015548634342849255,
        -0.05611192435026169,
        -0.02701384946703911,
        -0.06095840036869049,
        -0.03553881123661995,
        -0.013613138347864151,
        0.0427856408059597,
        0.057961612939834595,
        0.03908749669790268,
        0.008676953613758087,
        0.0027455908711999655,
        -0.04383062943816185,
        0.05915232375264168,
        0.05917636305093765,
        0.023767175152897835,
        0.05739164724946022,
        0.05087731033563614,
        0.060527168214321136,
        -0.01897585391998291,
        -0.05689549818634987,
        0.020928502082824707,
        -0.06003246456384659,
        -0.056663766503334045,
        0.026487061753869057,
        -0.009265691041946411,
        -0.04683229699730873,
        -0.03705611079931259,
        -0.05653873831033707,
        -0.02277938276529312,
        -0.04478476196527481,
        -0.05631154403090477,
        -0.001011134241707623,
        -0.043091949075460434,
        0.06084205210208893,
        -0.059370338916778564,
        -0.01824970543384552,
        -0.05409901961684227,
        -0.031196922063827515,
        -0.040165748447179794,
        0.03915240988135338,
        -0.01975974813103676,
        0.03963812440633774,
        0.058474861085414886,
        0.01177193596959114,
        -0.06004907563328743,
        -0.03461926057934761,
        0.013127532787621021,
        -0.058709029108285904,
        0.056727536022663116,
        -0.05011322349309921,
        0.03838279843330383,
        -0.05410423502326012,
        -0.005405758507549763,
        0.003289948683232069,
        0.057816676795482635,
        -0.05874890089035034,
        -0.01786120980978012,
        0.03762537240982056,
        -0.03744874894618988,
        0.023977139964699745,
        0.007047968916594982,
        -0.04999365285038948,
        -0.055646903812885284,
        -0.05686536431312561,
        -0.05896368995308876,
        -0.059701476246118546,
        0.044786084443330765,
        0.05817053094506264,
        0.03747822716832161,
        0.032607320696115494,
        -0.04538195580244064,
        -0.057380758225917816,
        0.05484624579548836,
        -0.035394951701164246,
        -0.03956064581871033,
        -0.045606471598148346,
        0.05345383659005165,
        -0.040941059589385986,
        -0.042214054614305496,
        0.03331493213772774,
        -0.05068284645676613,
        0.029549643397331238,
        -0.04201596975326538,
        -0.057540904730558395,
        -0.050489168614149094,
        0.03761720284819603,
        -0.01543238665908575,
        -0.058218128979206085,
        0.01027769222855568,
        -0.05987931415438652,
        0.053018659353256226,
        -0.045109573751688004,
        0.00402963487431407,
        0.056669142097234726,
        0.04017428681254387,
        0.025910837575793266,
        -0.030373651534318924,
        -0.00867694616317749,
        0.035364121198654175,
        -0.01592146046459675,
        -0.02682008221745491,
        0.053497109562158585,
        0.03864002972841263,
        -0.03850091993808746,
        0.06058114022016525,
        0.05355456471443176,
        0.05190260335803032,
        0.060195546597242355,
        0.059522584080696106,
        0.012142825871706009,
        -0.022488629445433617,
        0.03851703926920891,
        -0.012303054332733154,
        -0.0522320456802845,
        0.042753804475069046,
        -0.0600489005446434,
        -0.05922824144363403,
        0.05499640852212906,
        0.06041330099105835,
        -0.053855717182159424,
        -0.060158904641866684,
        -0.050416674464941025,
        -0.05726591870188713,
        0.039440885186195374,
        -0.012158735655248165,
        0.056429579854011536,
        0.029519852250814438,
        0.04813448339700699,
        -0.05133507028222084,
        -0.06018999218940735,
        -0.05336923524737358,
        -0.0537920705974102,
        -0.05547522380948067,
        0.059646666049957275,
        0.034076400101184845,
        0.040846019983291626,
        0.050726015120744705,
        -0.05197073519229889,
        0.04898231849074364,
        0.05975748226046562,
        0.05269428342580795,
        -0.036958273500204086,
        0.04866930842399597,
        0.035748645663261414,
        0.04405095428228378,
        0.05115896835923195,
        0.017827387899160385,
        0.05561201274394989,
        -0.04054908826947212,
        0.04879368841648102,
        -0.019083242863416672,
        -0.05661355331540108,
        0.0575641393661499,
        -0.053080540150403976,
        -0.011531397700309753,
        -0.022849775850772858,
        0.007266021333634853,
        -0.04517943412065506,
        0.0533209890127182,
        -0.05041108280420303,
        -0.027439115568995476,
        0.05690237134695053,
        0.034461282193660736,
        0.05868770182132721,
        -0.04429831728339195,
        0.050847139209508896,
        -0.03576643392443657,
        -0.05901648849248886,
        -0.05539846047759056,
        0.042131323367357254,
        -0.04848204925656319,
        0.006635141093283892,
        0.03994504734873772,
        -0.02236188016831875,
        0.03688826784491539,
        -0.05094891041517258,
        0.04805431887507439,
        -0.05863442271947861,
        0.0190146341919899,
        0.04579855501651764,
        -0.048569921404123306,
        0.04983820021152496,
        0.03686518967151642,
        0.060891736298799515,
        -0.015254837460815907,
        0.03970790281891823,
        0.04959677904844284,
        -0.028017206117510796,
        -0.06099696829915047,
        0.03631846234202385,
        -0.046298347413539886,
        -0.0011955423979088664,
        -0.05019330978393555,
        -0.057590268552303314,
        -0.05644569918513298,
        -0.05731743946671486,
        -0.010760439559817314,
        -0.0078089130111038685,
        0.056177545338869095,
        0.00008698499004822224,
        -0.03455658257007599,
        0.056409452110528946,
        -0.021772125735878944,
        0.06067558005452156,
        0.031142007559537888,
        0.05791990086436272,
        -0.04353826120495796,
        0.048975177109241486,
        0.030032379552721977,
        -0.014388732612133026,
        -0.05163601040840149,
        0.04024976119399071,
        0.010928151197731495,
        -0.06018805131316185,
        -0.013428601436316967,
        -0.04764572158455849,
        -0.05165516585111618,
        -0.055663809180259705,
        -0.04931585118174553,
        -0.056773796677589417,
        0.059120360761880875,
        0.05713532492518425,
        -0.010444346815347672,
        0.060430075973272324,
        0.05417182669043541,
        0.023870369419455528,
        0.0014455100754275918,
        0.007058598101139069,
        0.04450022429227829,
        -0.016129285097122192,
        0.05878853052854538,
        0.04481661692261696,
        0.009331641718745232,
        -0.012981289997696877,
        -0.042599599808454514,
        -0.046235889196395874,
        -0.05986662581562996,
        -0.043116260319948196,
        -0.05152270942926407,
        -0.06100792437791824,
        0.050848498940467834,
        -0.054121315479278564,
        -0.026281503960490227,
        -0.059988509863615036,
        -0.030379418283700943,
        -0.05531316250562668,
        0.008497395552694798,
        0.046867042779922485,
        0.059756726026535034,
        0.012747494503855705,
        0.036126330494880676,
        0.053010426461696625,
        -0.03976137936115265,
        -0.05232870578765869,
        0.058760203421115875,
        -0.016118373721837997,
        0.011586680077016354,
        -0.05932837724685669,
        0.03438444435596466,
        -0.007007946260273457,
        -0.0425531342625618,
        0.061023253947496414,
        -0.03407125174999237,
        -0.009103345684707165,
        0.04796145111322403,
        -0.05992596596479416,
        0.05793043598532677,
        0.007853712886571884,
        0.03408675268292427,
        -0.02131955511868,
        -0.05887618660926819,
        -0.0030769959557801485,
        -0.03898658975958824,
        0.015038365498185158,
        -0.05188927426934242,
        -0.03277226537466049,
        0.05083100497722626,
        -0.03879546746611595,
        -0.031022202223539352,
        -0.059832703322172165,
        -0.052019283175468445,
        0.059740711003541946,
        -0.043937798589468,
        0.029057638719677925,
        -0.053362395614385605,
        0.05967750772833824,
        -0.036801137030124664,
        0.034866005182266235,
        -0.059441860765218735,
        0.01947127841413021,
        0.017823847010731697,
        0.023436449468135834,
        -0.02027183771133423,
        0.043061718344688416,
        -0.04529374837875366,
        0.0365002304315567,
        0.0349864587187767,
        0.05094030871987343,
        -0.056422844529151917,
        0.05076059326529503,
        0.04896118491888046,
        -0.03548324480652809,
        0.055692851543426514,
        -0.04270663112401962,
        0.051981065422296524,
        0.021439077332615852,
        -0.050128497183322906,
        -0.009723037481307983,
        -0.05435595288872719,
        0.06102980673313141,
        0.054702967405319214,
        -0.002534698462113738,
        0.04583653807640076,
        -0.008860873058438301,
        0.031147677451372147,
        -0.059926196932792664,
        -0.0594877190887928,
        -0.05296360328793526,
        -0.05706029012799263,
        -0.038054320961236954,
        0.05899961665272713,
        0.02635466493666172,
        -0.022311534732580185,
        -0.05845747888088226,
        -0.05563773587346077,
        0.054609209299087524,
        0.03453812748193741,
        -0.05088856816291809,
        -0.0472424142062664,
        -0.03704645484685898,
        -0.02018141746520996,
        0.060935843735933304,
        0.04081406444311142,
        0.022734778001904488,
        -0.06037150323390961,
        -0.045984141528606415,
        -0.060736846178770065,
        0.060789912939071655,
        0.024392178282141685,
        0.05326016619801521,
        0.005435760132968426,
        0.04161524400115013,
        -0.04665132611989975,
        -0.01888452284038067,
        -0.05696042999625206,
        -0.05706806108355522,
        0.05563492700457573,
        -0.03249599412083626,
        -0.03621065244078636,
        0.059944599866867065,
        -0.05780192092061043,
        -0.007323517929762602,
        -0.057057809084653854,
        -0.05806984379887581,
        -0.046935878694057465,
        -0.039425354450941086,
        0.05514978989958763,
        0.005592839792370796,
        0.03913571313023567,
        -0.038836199790239334,
        0.05843990668654442,
        0.059935539960861206,
        -0.011702245101332664,
        -0.05701642856001854,
        -0.060565829277038574,
        -0.0568704716861248,
        0.05504864826798439,
        0.05308983847498894,
        0.058058787137269974,
        -0.01720365136861801,
        0.039419032633304596,
        0.051828328520059586,
        -0.05453094467520714,
        0.059691064059734344,
        -0.01739734783768654,
        0.05928807705640793,
        -0.023068049922585487,
        0.027872901409864426,
        0.0014506436418741941,
        0.06048556789755821,
        0.048219308257102966,
        -0.031176425516605377,
        0.06066284701228142,
        -0.01368784811347723,
        -0.0034352538641542196,
        -0.05599838122725487,
        -0.05693935602903366,
        0.04549763724207878,
        -0.06005460396409035,
        -0.009875897318124771,
        0.052894022315740585,
        -0.05497978255152702,
        -0.05707250535488129,
        -0.059589482843875885,
        -0.005830710753798485,
        -0.00689559755846858,
        -0.015485716052353382,
        0.006574677769094706,
        0.025467319414019585,
        0.02894698828458786,
        -0.0323796309530735,
        0.0007964724209159613,
        -0.009378199465572834,
        0.03649839758872986,
        -0.05821825563907623,
        -0.027897238731384277,
        0.05592777952551842,
        0.018099075183272362,
        0.05940200015902519,
        -0.01191664393991232,
        -0.057969529181718826,
        0.0446133129298687,
        -0.0589553564786911,
        0.03844544664025307,
        -0.05339909717440605,
        -0.025674454867839813,
        0.054617989808321,
        0.053340788930654526,
        0.016607623547315598,
        -0.04019680246710777,
        -0.043493859469890594,
        -0.004673348274081945,
        -0.020557964220643044,
        0.011481150053441525,
        0.05723366141319275,
        0.0565023235976696,
        0.026661397889256477,
        -0.02115359902381897,
        -0.030319590121507645,
        0.03166215121746063,
        0.0607689693570137,
        -0.059845201671123505,
        0.06026599928736687,
        -0.006529329344630241,
        -0.057840146124362946,
        -0.019360847771167755,
        -0.059627506881952286,
        0.05600203946232796,
        0.05824986472725868,
        0.054777637124061584,
        0.02403636835515499,
        -0.04736170545220375,
        -0.0011919031385332346,
        -0.037626367062330246,
        -0.05956346541643143,
        -0.05200617015361786,
        0.034306272864341736,
        0.03421179950237274,
        -0.02954515442252159,
        0.04114019498229027,
        0.057254329323768616,
        0.047677941620349884,
        0.021624574437737465,
        -0.05952196940779686,
        -0.005898658186197281,
        -0.03545558452606201,
        -0.025431068614125252,
        0.01975950412452221,
        0.05086812376976013,
        0.04018380865454674,
        0.04531275853514671,
        -0.06041625887155533,
        -0.05063846334815025,
        0.030908439308404922,
        0.05503755062818527,
        -0.052040547132492065,
        0.014359034597873688,
        -0.052065372467041016,
        0.05765865743160248,
        0.015336483716964722,
        -0.0029858772177249193,
        -0.01657121442258358,
        -0.04886952042579651,
        0.011963137425482273,
        -0.049250394105911255,
        0.05871104449033737,
        0.05269605666399002
    ],
    [
        0.05241616442799568,
        -0.051174093037843704,
        -0.008387680165469646,
        0.000575570622459054,
        0.05178729444742203,
        -0.052339665591716766,
        0.005187987815588713,
        -0.05083286017179489,
        0.049264825880527496,
        -0.05241609364748001,
        0.052371520549058914,
        0.04929075762629509,
        -0.052391648292541504,
        0.052343130111694336,
        -0.05213158205151558,
        0.025333605706691742,
        0.0495842806994915,
        0.05229257419705391,
        -0.02803180180490017,
        0.051706064492464066,
        0.05241549760103226,
        -0.05042266845703125,
        0.05237719044089317,
        -0.04340868443250656,
        -0.04865230992436409,
        -0.0343051478266716,
        0.03907549008727074,
        0.04157045856118202,
        -0.05240749940276146,
        0.052413955330848694,
        0.036251384764909744,
        0.05241132527589798,
        0.05241083726286888,
        -0.05240924283862114,
        0.05157981812953949,
        0.05236918479204178,
        -0.04967309907078743,
        -0.042337145656347275,
        0.048686299473047256,
        0.05217258259654045,
        0.05232365429401398,
        0.0006898918654769659,
        0.05179747939109802,
        0.04106323421001434,
        0.009050370194017887,
        0.007386054843664169,
        0.051963113248348236,
        -0.019341034814715385,
        0.05241158604621887,
        -0.052416130900382996,
        0.05240219458937645,
        0.049898236989974976,
        -0.031820666044950485,
        -0.04987487569451332,
        0.05152050033211708,
        0.052263833582401276,
        0.038235343992710114,
        0.014319002628326416,
        -0.0523829311132431,
        -0.014605605974793434,
        -0.05236000940203667,
        0.04911036789417267,
        -0.04416288807988167,
        -0.04812322556972504,
        -0.04889356717467308,
        -0.04563301056623459,
        -0.05239732190966606,
        -0.043913863599300385,
        0.05241228640079498,
        0.0452113039791584,
        -0.04741622135043144,
        -0.05234773829579353,
        0.05141335353255272,
        0.05241604894399643,
        0.052177317440509796,
        -0.03796019032597542,
        0.03743075951933861,
        0.05241616442799568,
        0.0524149015545845,
        0.05146415904164314,
        -0.03133410960435867,
        -0.052341073751449585,
        -0.05237795040011406,
        0.05228705331683159,
        -0.019485244527459145,
        0.02863728441298008,
        0.013470896519720554,
        -0.011393936350941658,
        -0.050646308809518814,
        -0.010063422843813896,
        -0.05163542926311493,
        -0.05186251923441887,
        -0.052378248423337936,
        -0.05241616070270538,
        -0.025644754990935326,
        0.023377668112516403,
        -0.05187520012259483,
        -0.05193956568837166,
        0.01059786882251501,
        0.05126196891069412,
        -0.05236729979515076,
        0.04563573747873306,
        0.05070538446307182,
        0.04297848790884018,
        -0.03932815045118332,
        0.04505014792084694,
        -0.05235496535897255,
        0.05139081925153732,
        -0.0496414490044117,
        0.05216001346707344,
        0.05173107236623764,
        0.05241524800658226,
        -0.05206996202468872,
        -0.04984989017248154,
        0.047195643186569214,
        -0.05241590365767479,
        0.0523521713912487,
        -0.05240459367632866,
        -0.041178520768880844,
        -0.04192878678441048,
        0.040442269295454025,
        0.0012101681204512715,
        0.03920924663543701,
        -0.052406951785087585,
        -0.05223732814192772,
        0.05241607874631882,
        -0.051794569939374924,
        -0.049322567880153656,
        -0.051857031881809235,
        0.0384676568210125,
        0.04738789424300194,
        -0.0522504523396492,
        0.013136147521436214,
        -0.02849729359149933,
        -0.04809402674436569,
        -0.05238880589604378,
        0.05227715149521828,
        -0.043785080313682556,
        -0.03832247480750084,
        0.05170733481645584,
        0.029829533770680428,
        0.05159228667616844,
        -0.01824827864766121,
        0.025845462456345558,
        0.03604326397180557,
        0.014445430599153042,
        0.0413251593708992,
        -0.02878091111779213,
        0.05241614207625389,
        0.05241616442799568,
        -0.014483400620520115,
        -0.05237923562526703,
        -0.0508827306330204,
        0.05194821581244469,
        0.039229102432727814,
        -0.02563861571252346,
        0.048122305423021317,
        0.02204234153032303,
        0.05191751942038536,
        -0.050696805119514465,
        -0.0037936964072287083,
        -0.048408448696136475,
        0.051733702421188354,
        0.005223674699664116,
        0.025943726301193237,
        -0.05022290721535683,
        0.050074804574251175,
        0.051685284823179245,
        0.027063388377428055,
        0.051210734993219376,
        -0.052318327128887177,
        0.05141383782029152,
        -0.052057523280382156,
        -0.02106480486690998,
        -0.05225313827395439,
        0.028314512223005295,
        -0.0524161271750927,
        0.039889391511678696,
        0.05199781060218811,
        -0.05178983882069588,
        -0.04905683919787407,
        0.03383072838187218,
        0.049570322036743164,
        0.02708028070628643,
        0.05225168913602829,
        0.05226819962263107,
        -0.043656397610902786,
        0.05201464146375656,
        0.05191789194941521,
        0.019316144287586212,
        -0.052402738481760025,
        0.0001734711549943313,
        0.04987887293100357,
        -0.052416153252124786,
        -0.050218161195516586,
        0.04949953407049179,
        -0.02300896681845188,
        0.03170331194996834,
        0.03428395465016365,
        0.051705535501241684,
        0.05241616070270538,
        -0.05241616442799568,
        0.04515676572918892,
        0.01929338090121746,
        -0.04762191325426102,
        0.036255739629268646,
        0.05239998921751976,
        0.04776235669851303,
        -0.051981206983327866,
        -0.051966145634651184,
        -0.051610782742500305,
        0.05223722755908966,
        0.05187486857175827,
        0.05191756412386894,
        -0.052416153252124786,
        -0.051567401736974716,
        0.05241616442799568,
        0.043428532779216766,
        0.05240670219063759,
        -0.05240846797823906,
        -0.014300722628831863,
        -0.051892779767513275,
        0.005692466627806425,
        0.03958721458911896,
        0.048322632908821106,
        -0.035620495676994324,
        0.032749343663454056,
        -0.05239636078476906,
        0.04902064800262451,
        -0.0512101948261261,
        -0.05118773877620697,
        -0.051190417259931564,
        -0.03536078333854675,
        0.042528145015239716,
        0.02608078345656395,
        0.052205704152584076,
        0.05171635001897812,
        0.015280724503099918,
        0.04084392264485359,
        0.052416130900382996,
        0.052349187433719635,
        0.052405524998903275,
        0.022790666669607162,
        -0.04625987261533737,
        -0.01656010001897812,
        -0.03761141374707222,
        0.051978595554828644,
        0.044958554208278656,
        -0.05080651119351387,
        -0.05241535231471062,
        -0.04939590021967888,
        -0.05174972116947174,
        -0.05241608992218971,
        -0.05213551223278046,
        -0.04791933670639992,
        0.05087265372276306,
        -0.03189849853515625,
        -0.05049070715904236,
        0.009895178489387035,
        -0.03755740076303482,
        -0.03867186978459358,
        -0.05139961466193199,
        0.020012419670820236,
        0.05239143595099449,
        0.05241422727704048,
        0.046733662486076355,
        0.0524137020111084,
        0.05189954489469528,
        0.02402159571647644,
        -0.02986251749098301,
        0.04569193720817566,
        -0.05226369947195053,
        0.00628040824085474,
        -0.05203896388411522,
        0.03280549868941307,
        -0.017575660720467567,
        -0.0486309751868248,
        -0.049721188843250275,
        -0.052416060119867325,
        0.05163092166185379,
        -0.05241316556930542,
        0.04969744011759758,
        -0.04758203402161598,
        0.05225726217031479,
        0.05241616442799568,
        0.049612417817115784,
        -0.010881701484322548,
        0.052238430827856064,
        0.05096166208386421,
        0.0068285814486444,
        0.027834655717015266,
        0.05203431472182274,
        -0.022662609815597534,
        -0.04297306761145592,
        0.05218000337481499,
        -0.051888398826122284,
        0.05221023038029671,
        0.0026062761899083853,
        0.05241489037871361,
        0.052082039415836334,
        0.05204881355166435,
        0.051270417869091034,
        -0.005329628475010395,
        0.027999969199299812,
        0.05166085436940193,
        0.02720273658633232,
        -0.044201772660017014,
        0.052346985787153244,
        -0.03545139729976654,
        -0.025254206731915474,
        -0.052326787263154984,
        -0.0032150072511285543,
        -0.0463126040995121,
        0.008564503863453865,
        0.05240560322999954,
        -0.05241476371884346,
        0.033804792910814285,
        -0.05104164406657219,
        0.04971623420715332,
        0.01995955966413021,
        0.05241585150361061,
        -0.05241142958402634,
        0.024242939427495003,
        -0.002326059387996793,
        0.0521843284368515,
        -0.04628146067261696,
        -0.015636781230568886,
        -0.04501946270465851,
        -0.012119528837502003,
        -0.027509143576025963,
        0.05241252854466438,
        0.042687397450208664,
        -0.030488306656479836,
        -0.0035407082177698612,
        0.035656895488500595,
        0.05123580992221832,
        -0.052350498735904694,
        0.0519435778260231,
        0.0510789193212986,
        -0.019792309030890465,
        0.009188706986606121,
        0.03363246098160744,
        -0.0347481369972229,
        0.05025128647685051,
        0.04623568430542946,
        -0.03327926993370056,
        0.0462053045630455,
        0.051437780261039734,
        -0.04868389293551445,
        0.04700168967247009,
        0.05239080265164375,
        -0.044718362390995026,
        0.020189208909869194,
        -0.03023557923734188,
        -0.03982403874397278,
        0.0394168347120285,
        -0.052396971732378006,
        -0.04512839764356613,
        -0.05175049230456352,
        -0.024457188323140144,
        0.05010291934013367,
        0.052140407264232635,
        0.0397338941693306,
        -0.04983992874622345,
        -0.05241243168711662,
        -0.0519137904047966,
        0.05229324847459793,
        -0.023488884791731834,
        0.052405331283807755,
        -0.052396442741155624,
        -0.02237929031252861,
        0.03207316994667053,
        0.05228445306420326,
        -0.02543809823691845,
        0.05144617334008217,
        0.05175444483757019,
        0.052194274961948395,
        0.04597409814596176,
        0.05169238522648811,
        0.05142968147993088,
        -0.049630943685770035,
        0.04114573076367378,
        -0.05211790278553963,
        0.05230007320642471,
        0.027325697243213654,
        0.029720734804868698,
        0.051526397466659546,
        -0.051308345049619675,
        -0.05207415297627449,
        -0.036939430981874466,
        -0.04425147920846939,
        0.008380752988159657,
        -0.020616333931684494,
        -0.03591198846697807,
        0.03268617391586304,
        0.0523449070751667,
        0.0524161122739315,
        -0.01595284417271614,
        -0.05241616442799568,
        -0.05220617353916168,
        -0.02286452427506447,
        0.051756490021944046,
        -0.04798106476664543,
        -0.044973134994506836,
        0.044523291289806366,
        0.003784176893532276,
        0.00044358675950206816,
        -0.05197659507393837,
        0.005621243268251419,
        -0.03409751132130623,
        0.01822197623550892,
        -0.05241559073328972,
        -0.05241045355796814,
        -0.008065248839557171,
        0.05080890655517578,
        -0.05028602108359337,
        -0.04982912167906761,
        0.049825698137283325,
        0.026300035417079926,
        0.05145101621747017,
        0.052302539348602295,
        0.05240901559591293,
        0.03776641562581062,
        -0.05239512398838997,
        0.012730819173157215,
        0.04865012690424919,
        -0.049915362149477005,
        0.03993277996778488,
        0.04808397218585014,
        -0.0513678640127182,
        -0.04785431548953056,
        -0.03779483214020729,
        0.026430469006299973,
        -0.005706524942070246,
        -0.02170032262802124,
        -0.052308063954114914,
        0.04234759882092476,
        0.02109171822667122,
        -0.029559336602687836,
        -0.050212521106004715,
        -0.033034972846508026,
        0.046823833137750626,
        0.04732454940676689,
        0.05196015536785126,
        -0.027770858258008957,
        -0.04988556727766991,
        0.02118360996246338,
        -0.052078600972890854,
        0.046008288860321045,
        0.017123015597462654,
        -0.030341841280460358,
        0.03949432075023651,
        0.03077327460050583,
        -0.030049962922930717,
        0.052318181842565536,
        -0.052117329090833664,
        -0.05239285156130791,
        -0.03535962849855423,
        -0.04785685986280441,
        0.0524132139980793,
        0.05241299793124199,
        -0.05222036689519882,
        -0.0520075261592865,
        0.05239284038543701,
        0.05212586745619774,
        0.05210833624005318,
        -0.051369305700063705,
        0.051609013229608536,
        0.052411749958992004,
        -0.04987969622015953,
        0.052415765821933746,
        0.051326025277376175,
        0.04898319020867348,
        0.05241616442799568,
        0.014339265413582325,
        0.02791018784046173,
        0.017651433125138283,
        0.05133702605962753,
        -0.0407455712556839,
        -0.039540309458971024,
        -0.052357159554958344,
        -0.039669301360845566,
        -0.042167846113443375,
        0.050553154200315475,
        -0.019814036786556244,
        0.04684825986623764,
        0.04976833239197731,
        -0.015082706697285175,
        -0.05235407501459122,
        -0.04556530341506004,
        0.010552256368100643,
        0.052069175988435745,
        -0.05103889852762222,
        0.052395086735486984,
        -0.04233308881521225,
        0.02452598325908184,
        -0.05189936235547066,
        0.052132122218608856,
        0.05194106698036194,
        -0.03378194943070412,
        -0.052315328270196915,
        -0.04797449707984924,
        0.04952286183834076,
        0.04959506168961525,
        0.04949552193284035,
        0.04189787432551384,
        0.030945884063839912,
        -0.05203168839216232,
        0.051494572311639786,
        -0.05241616442799568,
        0.05198085680603981,
        0.05241616442799568
    ],
    [
        0.053891371935606,
        0.021206898614764214,
        0.05345083773136139,
        0.020718775689601898,
        -0.046683065593242645,
        -0.05380840599536896,
        0.05218539386987686,
        -0.05295002833008766,
        0.051600825041532516,
        0.04309655725955963,
        0.053083643317222595,
        0.03354750946164131,
        -0.05201607197523117,
        -0.053267624229192734,
        -0.04914357513189316,
        0.04177948087453842,
        -0.034317340701818466,
        0.05391521379351616,
        0.05165387690067291,
        0.04691208526492119,
        0.05016376078128815,
        0.0306930523365736,
        0.053954582661390305,
        -0.04815841093659401,
        -0.05349782854318619,
        0.04483255743980408,
        0.052119288593530655,
        -0.020539280027151108,
        -0.053979359567165375,
        -0.05207681655883789,
        0.0481920950114727,
        -0.04359891638159752,
        0.05280328169465065,
        -0.05389424040913582,
        0.053886182606220245,
        -0.04157519340515137,
        -0.05385978892445564,
        -0.049474120140075684,
        -0.053983353078365326,
        -0.04713435098528862,
        0.04840533435344696,
        0.04615491256117821,
        0.047373756766319275,
        0.02183474414050579,
        -0.009783675894141197,
        0.022031668573617935,
        0.052824802696704865,
        0.05396401509642601,
        0.0536017082631588,
        -0.05048723146319389,
        0.05351687967777252,
        0.05368712171912193,
        -0.04168634116649628,
        0.051633190363645554,
        0.01825140416622162,
        0.0424390509724617,
        -0.05388908088207245,
        0.015646519139409065,
        -0.05396285653114319,
        -0.046184659004211426,
        -0.04647110402584076,
        0.031275033950805664,
        -0.05045027285814285,
        0.04523557424545288,
        0.044829849153757095,
        0.01570766605436802,
        -0.05397710204124451,
        -0.024343261495232582,
        0.05150165036320686,
        0.05176467448472977,
        0.019583994522690773,
        0.052832867950201035,
        0.053925998508930206,
        0.05345984548330307,
        0.050178926438093185,
        -0.00389935914427042,
        0.053005918860435486,
        0.05398515984416008,
        0.012145563028752804,
        0.047961242496967316,
        -0.0390673503279686,
        -0.04896070063114166,
        -0.05351390317082405,
        0.01855110563337803,
        -0.017656153067946434,
        0.03804954141378403,
        0.036221399903297424,
        -0.05387163162231445,
        -0.0005352466250769794,
        0.01472258847206831,
        -0.05386985465884209,
        -0.009807348251342773,
        -0.051891621202230453,
        -0.0538371279835701,
        0.03194809332489967,
        0.04581534489989281,
        -0.031374409794807434,
        -0.05320727825164795,
        -0.04380515217781067,
        0.021777428686618805,
        -0.05314302444458008,
        0.04763907939195633,
        -0.020380280911922455,
        0.047540511935949326,
        0.053374338895082474,
        -0.05316183343529701,
        -0.05130146071314812,
        0.050165388733148575,
        -0.053549475967884064,
        -0.007064828649163246,
        0.03942717984318733,
        -0.05305562913417816,
        -0.05210699513554573,
        -0.04269775003194809,
        0.052883330732584,
        -0.00597873330116272,
        -0.02553570084273815,
        -0.049236785620450974,
        0.04701749235391617,
        -0.05178329721093178,
        -0.006948675960302353,
        0.03501660004258156,
        0.0497354157269001,
        -0.0532933846116066,
        -0.053169555962085724,
        0.0468854196369648,
        -0.0530414879322052,
        0.04837925732135773,
        0.0530412457883358,
        -0.008984450250864029,
        0.012631485238671303,
        -0.05180945247411728,
        -0.04375280812382698,
        0.05397145077586174,
        0.017489004880189896,
        -0.053130313754081726,
        -0.02607894130051136,
        0.0199540127068758,
        -0.05003993213176727,
        0.05390593037009239,
        -0.03809889405965805,
        -0.03277585655450821,
        0.04918450489640236,
        0.05390837416052818,
        -0.03785386681556702,
        0.04036407917737961,
        0.030411701649427414,
        -0.017145667225122452,
        -0.05201362073421478,
        -0.0416422002017498,
        -0.049430374056100845,
        -0.05397942289710045,
        0.053967420011758804,
        -0.03546937182545662,
        0.004087044391781092,
        -0.05235337093472481,
        -0.053047824651002884,
        -0.03439590707421303,
        0.05386292189359665,
        -0.03645959869027138,
        -0.04573750123381615,
        -0.0413583368062973,
        0.05317622050642967,
        0.03970153629779816,
        0.01318848505616188,
        -0.045075222849845886,
        0.03926117718219757,
        0.019663942977786064,
        -0.01637144759297371,
        0.005225842352956533,
        -0.016511140391230583,
        -0.05189092829823494,
        -0.053907498717308044,
        0.011117525398731232,
        0.053984709084033966,
        0.022625893354415894,
        0.052641529589891434,
        0.042413339018821716,
        0.053790438920259476,
        -0.05349705368280411,
        -0.050310853868722916,
        0.05164245888590813,
        0.04209942743182182,
        -0.053911976516246796,
        -0.016258634626865387,
        -0.04405321180820465,
        0.04765504226088524,
        0.05361628904938698,
        -0.04914006590843201,
        0.05395432561635971,
        -0.053129229694604874,
        -0.034098442643880844,
        0.053901560604572296,
        -0.05364232510328293,
        -0.052593834698200226,
        0.0050947461277246475,
        -0.03472254425287247,
        0.04788617044687271,
        -0.004486694000661373,
        -0.025378400459885597,
        0.05246686190366745,
        0.05079127103090286,
        -0.010054130107164383,
        -0.05329568684101105,
        0.053727418184280396,
        -0.051575858145952225,
        0.038460761308670044,
        0.04618615284562111,
        -0.04171589016914368,
        0.053959641605615616,
        -0.04293494671583176,
        0.046933941543102264,
        0.052877236157655716,
        0.053696539252996445,
        0.0234938133507967,
        -0.048359837383031845,
        0.004651121329516172,
        -0.05147352069616318,
        0.053983960300683975,
        0.0358119010925293,
        0.048539262264966965,
        0.052714597433805466,
        -0.041692525148391724,
        0.0332547202706337,
        -0.0031177669297903776,
        0.053984399884939194,
        0.05058016628026962,
        -0.05106749385595322,
        0.05300816148519516,
        -0.053959351032972336,
        -0.0539831668138504,
        0.05389336496591568,
        0.046011194586753845,
        0.0513434000313282,
        -0.049751050770282745,
        0.05380728095769882,
        0.05286499857902527,
        -0.029354754835367203,
        -0.015104257501661777,
        0.05398496612906456,
        0.053867850452661514,
        -0.01658686250448227,
        0.02754756435751915,
        -0.04995362088084221,
        0.002786919241771102,
        -0.030156832188367844,
        -0.05396905541419983,
        -0.03346073627471924,
        -0.04564131423830986,
        -0.03589946776628494,
        -0.0539613701403141,
        -0.05332040414214134,
        -0.04165997728705406,
        -0.05377469211816788,
        -0.04166030138731003,
        0.05380440875887871,
        0.013170844875276089,
        0.05374055728316307,
        -0.004234324209392071,
        -0.03663872927427292,
        -0.051269836723804474,
        -0.052610620856285095,
        -0.05268247798085213,
        0.051806673407554626,
        -0.006270278710871935,
        0.05397096648812294,
        0.05356062948703766,
        0.0539836511015892,
        0.03475203365087509,
        -0.04370419308543205,
        0.04386381059885025,
        -0.05394166707992554,
        0.05382203310728073,
        -0.05360408499836922,
        -0.047493308782577515,
        -0.0491272434592247,
        -0.04775451496243477,
        -0.05246376246213913,
        0.030347507447004318,
        0.021047202870249748,
        -0.053955692797899246,
        0.03895782306790352,
        -0.05358002334833145,
        -0.03892313316464424,
        0.051507338881492615,
        -0.04601077735424042,
        0.007360000628978014,
        0.05055295303463936,
        0.003177736885845661,
        0.05311425030231476,
        -0.05386488884687424,
        0.052071575075387955,
        -0.04672408103942871,
        0.0014646670315414667,
        0.05386776104569435,
        -0.05389871075749397,
        0.05344661325216293,
        0.049962177872657776,
        0.004920723382383585,
        0.0519542433321476,
        -0.0521463006734848,
        -0.0499277226626873,
        -0.008664950728416443,
        0.053799696266651154,
        -0.003391511971130967,
        0.010165550746023655,
        0.03769556060433388,
        0.0412474162876606,
        0.02981424890458584,
        0.00573180615901947,
        0.05391727015376091,
        -0.03924573212862015,
        -0.05371810123324394,
        -0.03169411048293114,
        -0.051983948796987534,
        -0.05393248796463013,
        0.015562688000500202,
        -0.011214341036975384,
        0.053458429872989655,
        0.0007274214294739068,
        0.019152935594320297,
        -0.04982294142246246,
        -0.024662485346198082,
        -0.0539664663374424,
        0.053887661546468735,
        -0.04485541954636574,
        0.047248754650354385,
        0.04799675568938255,
        0.05296403914690018,
        -0.05356711149215698,
        0.05216336250305176,
        -0.05351826921105385,
        -0.05354442447423935,
        0.04790269210934639,
        0.04989403113722801,
        0.05188780650496483,
        -0.05162303149700165,
        0.043055497109889984,
        -0.02425159513950348,
        -0.05081547424197197,
        -0.04938182979822159,
        -0.05372319743037224,
        -0.01848876290023327,
        -0.02290899120271206,
        0.0528365820646286,
        0.04073944315314293,
        0.016576822847127914,
        0.05367838218808174,
        0.04467552527785301,
        0.049094099551439285,
        0.053970836102962494,
        -0.05192825570702553,
        0.02438010834157467,
        -0.0539228618144989,
        -0.031308382749557495,
        -0.036329902708530426,
        -0.016306748613715172,
        -0.033793166279792786,
        -0.053395017981529236,
        0.0040720440447330475,
        0.04647403210401535,
        0.05240922421216965,
        0.04786849394440651,
        -0.04608931019902229,
        -0.05149674788117409,
        -0.05245404317975044,
        0.05397593230009079,
        0.052120279520750046,
        -0.004131953231990337,
        -0.0534634031355381,
        -0.049533646553754807,
        -0.03691035509109497,
        -0.03265620768070221,
        0.03236844018101692,
        0.05301541090011597,
        0.035406969487667084,
        0.0053630731999874115,
        -0.049114763736724854,
        0.05304009094834328,
        0.0364905521273613,
        -0.048546649515628815,
        -0.02330568991601467,
        -0.04692584648728371,
        0.012933983467519283,
        0.05314641818404198,
        -0.05242743715643883,
        0.000011414221262384672,
        -0.02774842269718647,
        0.001736785750836134,
        0.016416972503066063,
        -0.05376139655709267,
        0.00031768387998454273,
        -0.05228475481271744,
        -0.05134850740432739,
        0.05052470415830612,
        0.05367060750722885,
        -0.052348967641592026,
        -0.04732539504766464,
        0.008788226172327995,
        -0.028726890683174133,
        0.047359976917505264,
        0.0530381053686142,
        0.020408527925610542,
        0.04956591874361038,
        -0.05014685541391373,
        -0.04940636456012726,
        0.048913780599832535,
        -0.04891279339790344,
        0.05165333300828934,
        0.05318169295787811,
        -0.041997458785772324,
        -0.02769956737756729,
        -0.02860419452190399,
        -0.05384789779782295,
        0.05374770984053612,
        -0.05389976501464844,
        0.009886866435408592,
        0.03305130451917648,
        0.05317860469222069,
        -0.043109528720378876,
        0.05386665090918541,
        -0.053241707384586334,
        -0.05292019248008728,
        -0.053962331265211105,
        -0.04160028323531151,
        0.04583519324660301,
        -0.05393461138010025,
        0.045999713242053986,
        0.034543491899967194,
        -0.048934876918792725,
        0.04494553431868553,
        -0.050067685544490814,
        0.014606882818043232,
        0.013965633697807789,
        -0.03674820065498352,
        -0.05397837236523628,
        -0.05196059122681618,
        -0.05256424471735954,
        0.038596488535404205,
        -0.042681675404310226,
        0.053669560700654984,
        0.053039029240608215,
        -0.02644137479364872,
        0.0536004863679409,
        0.05398145690560341,
        -0.046840254217386246,
        0.03961986303329468,
        -0.0536281056702137,
        0.04344384744763374,
        0.053931720554828644,
        -0.03774138540029526,
        0.050241243094205856,
        -0.018085341900587082,
        0.05385822057723999,
        0.007399987429380417,
        -0.05167942866683006,
        -0.006961667910218239,
        0.024728424847126007,
        0.050947390496730804,
        -0.04880289360880852,
        0.05398507043719292,
        -0.05354377627372742,
        0.03889615461230278,
        -0.05293548107147217,
        0.05392792448401451,
        0.0539739616215229,
        -0.042570438235998154,
        0.05389242246747017,
        0.05105142295360565,
        -0.05382967367768288,
        -0.05274425819516182,
        -0.05396731570363045,
        0.05234621465206146,
        0.053792018443346024,
        0.05168396234512329,
        0.053674716502428055,
        0.038155462592840195,
        0.05304686352610588,
        -0.016817618161439896,
        -0.04514547437429428,
        -0.05211082100868225,
        -0.04826801270246506,
        0.012647958472371101,
        -0.029784198850393295,
        -0.01200686115771532,
        0.042485494166612625,
        0.05398465692996979,
        -0.03622482344508171,
        -0.05367635563015938,
        -0.05187304690480232,
        0.05102322995662689,
        -0.04742516949772835,
        0.04087429866194725,
        0.04762541130185127,
        -0.05132666602730751,
        -0.011836941353976727,
        0.006845432333648205,
        0.046494681388139725,
        0.036544494330883026,
        0.00956466794013977,
        -0.05395965278148651,
        -0.0447147898375988,
        -0.029741071164608,
        -0.002300422638654709,
        0.053818438202142715,
        0.051969390362501144,
        0.041977960616350174,
        0.0020640678703784943,
        0.053984686732292175,
        -0.05398428440093994,
        -0.05384909361600876,
        0.021510954946279526
    ],
    [
        -0.04416179284453392,
        -0.02113204076886177,
        -0.016162950545549393,
        0.045805685222148895,
        -0.03629608079791069,
        -0.050176702439785004,
        0.052065618336200714,
        -0.0524023063480854,
        0.05080363154411316,
        0.05226045474410057,
        0.03532905876636505,
        0.04126564785838127,
        -0.05244799703359604,
        0.02952706068754196,
        -0.04924846440553665,
        0.035411287099123,
        0.05032454431056976,
        0.04485176503658295,
        0.04270601645112038,
        -0.014318549074232578,
        0.04496169090270996,
        0.03542666137218475,
        -0.05028906092047691,
        0.049952611327171326,
        -0.052023738622665405,
        0.05246420577168465,
        0.0405576191842556,
        -0.014617683365941048,
        -0.05245449021458626,
        -0.05209612846374512,
        -0.05230756476521492,
        0.05243287235498428,
        0.052442993968725204,
        -0.05245598033070564,
        0.04907403141260147,
        0.015724102035164833,
        -0.051718227565288544,
        -0.05204980447888374,
        -0.052471913397312164,
        -0.05040846765041351,
        -0.051712650805711746,
        0.012831577099859715,
        0.035173479467630386,
        0.024217108264565468,
        -0.04652832821011543,
        0.022438041865825653,
        0.05187321826815605,
        0.051937285810709,
        0.052474457770586014,
        -0.04065168276429176,
        0.05230038985610008,
        0.0523916594684124,
        -0.04269209876656532,
        -0.015877237543463707,
        -0.041827235370874405,
        0.04918653890490532,
        -0.0521724671125412,
        0.029931314289569855,
        -0.052311401814222336,
        -0.0522523857653141,
        -0.05244653299450874,
        0.04034113511443138,
        -0.05109688639640808,
        -0.0072848801501095295,
        0.051677968353033066,
        -0.030576596036553383,
        -0.05243914574384689,
        -0.005116662476211786,
        0.030888058245182037,
        0.050237126648426056,
        -0.05246853828430176,
        0.014193159528076649,
        0.0524747297167778,
        0.049092769622802734,
        0.051078952848911285,
        -0.011344929225742817,
        0.045842211693525314,
        0.05247490480542183,
        0.041026175022125244,
        0.03795355558395386,
        -0.002621237188577652,
        -0.052126869559288025,
        -0.05227133259177208,
        0.050894275307655334,
        0.05201634764671326,
        0.05203930661082268,
        0.05190011486411095,
        0.004610482137650251,
        -0.025334352627396584,
        0.024429675191640854,
        -0.034137092530727386,
        -0.015404490754008293,
        -0.007414889056235552,
        0.036529541015625,
        -0.05133520066738129,
        0.04948686435818672,
        0.03878513723611832,
        -0.051696520298719406,
        0.04043341055512428,
        0.0077848006039857864,
        -0.0523216687142849,
        0.05213944986462593,
        0.00417954521253705,
        -0.05247477442026138,
        0.04889480024576187,
        -0.052467361092567444,
        -0.05228379741311073,
        0.0524742566049099,
        -0.052473366260528564,
        -0.0511636920273304,
        -0.0516170896589756,
        -0.0510467067360878,
        -0.05067791789770126,
        -0.0336700901389122,
        0.052412811666727066,
        0.04966135695576668,
        0.05245932936668396,
        -0.02786889858543873,
        0.04191724210977554,
        -0.052466873079538345,
        -0.004033495672047138,
        0.027410758659243584,
        0.00551179051399231,
        -0.052461374551057816,
        -0.01698811911046505,
        -0.05247460678219795,
        -0.046826351433992386,
        -0.01085050031542778,
        0.04281558468937874,
        0.033286526799201965,
        -0.03248590975999832,
        -0.05184504762291908,
        -0.05242053419351578,
        0.05243620648980141,
        -0.05053066834807396,
        -0.051040831953287125,
        0.0524580143392086,
        -0.04917237162590027,
        -0.052190955728292465,
        0.04758957400918007,
        0.025833791121840477,
        -0.052442871034145355,
        0.034113187342882156,
        0.05237799510359764,
        0.028844796121120453,
        -0.05007314309477806,
        0.05172732472419739,
        -0.0447128489613533,
        0.05236966907978058,
        0.011667377315461636,
        -0.037809111177921295,
        -0.052474915981292725,
        -0.026768971234560013,
        -0.052264608442783356,
        -0.021201636642217636,
        -0.05244503170251846,
        -0.04311300441622734,
        -0.05171794444322586,
        0.052467308938503265,
        0.0521957129240036,
        -0.047639939934015274,
        0.052474863827228546,
        0.05095229670405388,
        0.0358557403087616,
        -0.052321869879961014,
        -0.04841192066669464,
        0.05220600962638855,
        -0.04650571197271347,
        0.052364371716976166,
        0.03729543462395668,
        0.039596423506736755,
        -0.052429504692554474,
        -0.05244193226099014,
        0.05220050737261772,
        0.05245870351791382,
        0.013953525573015213,
        0.04748382419347763,
        -0.04864098131656647,
        0.050560444593429565,
        -0.05227780342102051,
        -0.04802819341421127,
        0.01578509993851185,
        0.052218593657016754,
        -0.05186436325311661,
        0.032415878027677536,
        0.043800488114356995,
        -0.03778010234236717,
        0.04728323966264725,
        0.04797479510307312,
        0.028749126940965652,
        -0.05234488844871521,
        -0.039340291172266006,
        0.05246514081954956,
        0.0524732805788517,
        0.017913607880473137,
        0.05102503299713135,
        0.04979727044701576,
        0.05247097462415695,
        0.05087098479270935,
        0.041646044701337814,
        -0.04176376014947891,
        0.027387799695134163,
        0.029673131182789803,
        0.05123841390013695,
        0.05241575837135315,
        -0.005416445899754763,
        0.05143953859806061,
        -0.04622511565685272,
        -0.05218721553683281,
        0.052424971014261246,
        -0.03422514721751213,
        0.05034298449754715,
        0.017839165404438972,
        0.05205424129962921,
        -0.0518539622426033,
        -0.037930119782686234,
        0.03752114996314049,
        0.009695308282971382,
        0.0524483285844326,
        -0.050758812576532364,
        0.026994502171874046,
        0.052474915981292725,
        -0.052328720688819885,
        0.017000405117869377,
        0.05167127400636673,
        0.05210237577557564,
        0.03553381934762001,
        -0.04957830533385277,
        0.05246436968445778,
        -0.052401185035705566,
        -0.05246328189969063,
        0.048481617122888565,
        -0.017549103125929832,
        0.0513029620051384,
        -0.051315415650606155,
        0.0524740144610405,
        0.0483209490776062,
        0.004692703951150179,
        -0.04376403987407684,
        0.052470751106739044,
        0.012701001018285751,
        0.04625469818711281,
        0.0328662283718586,
        0.04894896224141121,
        0.05077097937464714,
        0.05232280120253563,
        -0.052460458129644394,
        0.05143013596534729,
        -0.05242328718304634,
        -0.048461586236953735,
        -0.052474670112133026,
        -0.05146210268139839,
        -0.024363191798329353,
        -0.05229959636926651,
        0.048815589398145676,
        0.051839351654052734,
        0.04475871101021767,
        -0.033361975103616714,
        0.05184830352663994,
        -0.02918127551674843,
        0.008138583973050117,
        0.047383446246385574,
        -0.05241600424051285,
        0.05016929656267166,
        0.0406159833073616,
        0.05242825672030449,
        0.04952651262283325,
        0.052437759935855865,
        0.042502082884311676,
        -0.052350256592035294,
        -0.029717115685343742,
        -0.05178108066320419,
        0.05239741504192352,
        -0.04072759672999382,
        -0.05237957462668419,
        -0.0521785207092762,
        -0.047982070595026016,
        -0.028939375653862953,
        -0.052474915981292725,
        0.050487540662288666,
        -0.052453622221946716,
        0.00500462856143713,
        -0.05227825790643692,
        -0.04220578819513321,
        0.052474915981292725,
        0.0442701056599617,
        0.009319930337369442,
        0.04545395448803902,
        -0.02736314758658409,
        0.05227446183562279,
        0.013036838732659817,
        0.052406422793865204,
        -0.04240056499838829,
        0.05240980535745621,
        -0.05128004029393196,
        -0.05242886021733284,
        0.052461881190538406,
        -0.037690430879592896,
        -0.011746073141694069,
        0.05244078114628792,
        0.005606450140476227,
        -0.010969049297273159,
        0.005190429277718067,
        0.05058448761701584,
        0.0215024221688509,
        0.035576026886701584,
        0.021496279165148735,
        -0.04575687646865845,
        0.02178802341222763,
        -0.05199725180864334,
        0.04685232788324356,
        0.0005476771621033549,
        0.03732331469655037,
        -0.051509514451026917,
        -0.04599444195628166,
        -0.05242921784520149,
        0.02196904830634594,
        -0.04977633059024811,
        0.0523500069975853,
        -0.04434759542346001,
        0.0031268226448446512,
        -0.050906501710414886,
        -0.04583670198917389,
        -0.052455149590969086,
        0.05241018533706665,
        -0.03823374584317207,
        0.05247422307729721,
        -0.03653885796666145,
        0.0383894219994545,
        -0.05227743089199066,
        0.05247361585497856,
        0.04069345444440842,
        -0.05238194018602371,
        -0.04675845429301262,
        0.051488686352968216,
        0.051519472151994705,
        -0.02377178519964218,
        0.050777021795511246,
        -0.05197538807988167,
        0.021942289546132088,
        -0.05233806371688843,
        0.03641890734434128,
        0.05225364863872528,
        -0.05142516642808914,
        0.05247338488698006,
        -0.034798022359609604,
        0.024693116545677185,
        0.05194893851876259,
        -0.04641232267022133,
        0.05243377014994621,
        0.05223149433732033,
        0.047691792249679565,
        0.05114195868372917,
        -0.051326438784599304,
        0.04952048510313034,
        0.05216607451438904,
        -0.052474915981292725,
        -0.049542151391506195,
        -0.05228891968727112,
        0.024587946012616158,
        0.05241261050105095,
        -0.008915768004953861,
        -0.0011365879327058792,
        -0.04577689990401268,
        0.002063154010102153,
        -0.05182027071714401,
        0.052431900054216385,
        -0.0486450269818306,
        -0.04847389832139015,
        -0.05181387439370155,
        -0.04834045097231865,
        0.023310817778110504,
        0.05240527167916298,
        0.001408948446623981,
        0.011009390465915203,
        -0.05157182365655899,
        0.05241738632321358,
        -0.052462387830019,
        0.05235040560364723,
        -0.02637973614037037,
        0.052460603415966034,
        0.04471281170845032,
        -0.052462413907051086,
        0.049905188381671906,
        0.05072474479675293,
        0.02335433103144169,
        -0.051947951316833496,
        0.015819434076547623,
        -0.05233924463391304,
        0.04552024230360985,
        -0.041674621403217316,
        -0.018157988786697388,
        -0.05097130686044693,
        -0.05232998728752136,
        0.010105415247380733,
        0.04616934806108475,
        0.05055147781968117,
        0.051825929433107376,
        -0.052474915981292725,
        -0.051714420318603516,
        0.04513053223490715,
        0.05216779559850693,
        0.0016414776910096407,
        0.052211131900548935,
        -0.051179785281419754,
        0.020759299397468567,
        0.05094103887677193,
        -0.05235602334141731,
        0.052241966128349304,
        0.04775627702474594,
        -0.01764632761478424,
        -0.0521426796913147,
        -0.05247461795806885,
        0.031816110014915466,
        -0.04634128883481026,
        -0.04724011942744255,
        -0.05236849933862686,
        -0.04223626106977463,
        -0.011345178820192814,
        -0.03879241272807121,
        0.052214402705430984,
        -0.05247444286942482,
        0.008215592242777348,
        -0.052461590617895126,
        0.019132006913423538,
        0.05198630690574646,
        -0.05140428617596626,
        0.000010281361028319225,
        0.0405571423470974,
        -0.04688958451151848,
        0.04148003086447716,
        -0.008914101868867874,
        0.037876807153224945,
        0.041124824434518814,
        -0.04958276450634003,
        -0.05244815722107887,
        -0.052139416337013245,
        0.05018872022628784,
        -0.04581071063876152,
        -0.05226552486419678,
        0.046767376363277435,
        0.05244308337569237,
        0.03930553048849106,
        0.050868455320596695,
        0.05185486003756523,
        -0.038046129047870636,
        0.04162754490971565,
        -0.04975226894021034,
        0.013711118139326572,
        0.0497775599360466,
        0.052170585840940475,
        0.05231836065649986,
        -0.0021361398976296186,
        0.05223775655031204,
        -0.040301285684108734,
        -0.0465211383998394,
        0.013028156012296677,
        -0.04858514666557312,
        -0.049328118562698364,
        0.03633676841855049,
        0.05240584537386894,
        -0.052449386566877365,
        0.05243346840143204,
        -0.01175039354711771,
        0.0431499183177948,
        0.05246983468532562,
        -0.05179016292095184,
        0.052062083035707474,
        -0.02226055972278118,
        -0.051818057894706726,
        0.05247462913393974,
        -0.05247389152646065,
        0.050533879548311234,
        0.052474427968263626,
        0.052460189908742905,
        0.005959178786724806,
        -0.04438135027885437,
        0.052355531603097916,
        -0.01560986042022705,
        -0.049276843667030334,
        -0.045976679772138596,
        -0.04672720655798912,
        0.05176658555865288,
        0.05160287395119667,
        0.044944506138563156,
        0.0072479406371712685,
        0.05247314274311066,
        0.0012012106599286199,
        -0.052359823137521744,
        0.04037259519100189,
        -0.021909311413764954,
        -0.04680471867322922,
        0.017764778807759285,
        0.048994529992341995,
        -0.050090331584215164,
        0.04697654768824577,
        -0.05201373249292374,
        -0.0017255221027880907,
        0.052112020552158356,
        -0.05226860195398331,
        -0.05239032208919525,
        -0.052357278764247894,
        0.015663478523492813,
        0.034819845110177994,
        -0.03448827937245369,
        0.049390070140361786,
        0.00004603702836902812,
        0.0015909457579255104,
        0.05243281275033951,
        -0.052433595061302185,
        0.04686274379491806,
        0.014207767322659492
    ],
    [
        0.05050579085946083,
        0.050933148711919785,
        0.047971952706575394,
        0.01625075191259384,
        -0.047385074198246,
        -0.014821859076619148,
        0.05129566043615341,
        -0.050878070294857025,
        0.007496433798223734,
        -0.04390878230333328,
        -0.0173935703933239,
        -0.04856191575527191,
        -0.05135228857398033,
        -0.051099903881549835,
        -0.051497362554073334,
        0.04724115878343582,
        -0.03950086608529091,
        0.051648832857608795,
        0.04683997854590416,
        0.020168980583548546,
        0.05144859105348587,
        -0.05026749148964882,
        0.023462634533643723,
        -0.015749257057905197,
        -0.03048117831349373,
        0.048539552837610245,
        0.05152885243296623,
        0.051146961748600006,
        -0.05076277256011963,
        -0.05156775191426277,
        0.0486493855714798,
        0.03105376847088337,
        0.05152511969208717,
        -0.050337281078100204,
        0.05082261562347412,
        -0.04868398606777191,
        -0.05157812684774399,
        -0.021581457927823067,
        0.043377358466386795,
        0.0481371134519577,
        -0.035237062722444534,
        0.046534325927495956,
        0.04827499762177467,
        -0.05132518336176872,
        -0.005109730176627636,
        -0.03376885503530502,
        0.030771825462579727,
        0.051666148006916046,
        0.0510232113301754,
        -0.0513417012989521,
        0.051656678318977356,
        0.051428042352199554,
        0.007058784365653992,
        0.05166226625442505,
        0.046382781118154526,
        0.03142297640442848,
        -0.048985205590724945,
        0.051674652844667435,
        -0.04975690692663193,
        -0.051601387560367584,
        -0.05144042521715164,
        0.030574310570955276,
        -0.051429204642772675,
        0.051194850355386734,
        -0.020698290318250656,
        -0.021550364792346954,
        -0.05155264213681221,
        0.010403887368738651,
        0.006640254519879818,
        0.05152460187673569,
        0.04692639037966728,
        -0.032304469496011734,
        0.05167681723833084,
        0.05165298283100128,
        0.04699552804231644,
        -0.04965881630778313,
        0.051633235067129135,
        0.05167681351304054,
        0.021218426525592804,
        0.04974439740180969,
        -0.03034120425581932,
        -0.05022994056344032,
        -0.051225826144218445,
        0.04376260191202164,
        -0.04993415251374245,
        0.04978039488196373,
        -0.04625730589032173,
        -0.051613494753837585,
        -0.0009556272998452187,
        0.04980616644024849,
        -0.048122093081474304,
        -0.04859859496355057,
        0.036804359406232834,
        -0.020956020802259445,
        -0.050574615597724915,
        -0.03285474330186844,
        -0.04987214878201485,
        -0.051585014909505844,
        0.051645390689373016,
        0.05146351456642151,
        -0.05165766924619675,
        -0.0021694833412766457,
        0.05117795988917351,
        0.05089373141527176,
        0.04832271486520767,
        -0.04996312037110329,
        -0.05161895602941513,
        0.03659103810787201,
        -0.0469898022711277,
        -0.04354534670710564,
        -0.0422038659453392,
        -0.046749014407396317,
        -0.05061166360974312,
        -0.05072513222694397,
        0.050154443830251694,
        -0.050566695630550385,
        -0.04980005696415901,
        -0.05078189820051193,
        0.050159260630607605,
        -0.050390925258398056,
        -0.044175345450639725,
        0.03995393589138985,
        0.04610475152730942,
        -0.04118328168988228,
        -0.05163810774683952,
        0.023451777175068855,
        -0.05117970332503319,
        -0.05034011974930763,
        0.05069176107645035,
        -0.002965354360640049,
        -0.04786162078380585,
        -0.05163467675447464,
        0.0018942445749416947,
        0.051565803587436676,
        -0.0273369699716568,
        -0.0511581227183342,
        0.049956317991018295,
        -0.044640444219112396,
        -0.05010875687003136,
        0.05164637044072151,
        -0.05122167244553566,
        -0.02648511528968811,
        0.049726106226444244,
        0.05149499326944351,
        -0.04442653805017471,
        -0.04582574591040611,
        0.03339618444442749,
        0.044427111744880676,
        -0.05159773677587509,
        0.03868313506245613,
        0.05166081711649895,
        -0.051674071699380875,
        -0.04207710176706314,
        -0.05167583376169205,
        -0.043821778148412704,
        -0.05073782056570053,
        -0.0506742000579834,
        -0.049865953624248505,
        0.05076558142900467,
        0.04796033725142479,
        -0.050862278789281845,
        0.051172126084566116,
        0.048624489456415176,
        0.04692189395427704,
        0.05149136856198311,
        -0.05153857171535492,
        0.019588660448789597,
        -0.010895187966525555,
        -0.03073650412261486,
        0.000001530894110146619,
        -0.033853646367788315,
        -0.02074095979332924,
        -0.05027005076408386,
        0.05100737884640694,
        0.05158480629324913,
        -0.030359117314219475,
        0.05158299580216408,
        -0.016733931377530098,
        0.051356878131628036,
        -0.04937154799699783,
        -0.050134699791669846,
        0.02155238576233387,
        0.005547929089516401,
        -0.04772287607192993,
        0.042614903301000595,
        -0.051302071660757065,
        0.05157165974378586,
        0.051616016775369644,
        -0.0500577837228775,
        -0.04842769354581833,
        -0.05166102573275566,
        -0.0428207702934742,
        0.049174875020980835,
        0.05138837546110153,
        -0.05153559893369675,
        0.014077439904212952,
        0.051467832177877426,
        0.05075310170650482,
        0.037705808877944946,
        0.04745343327522278,
        -0.009490170516073704,
        0.051176272332668304,
        0.04261620715260506,
        -0.05166678875684738,
        0.051195621490478516,
        -0.04998051002621651,
        0.051366571336984634,
        0.049925461411476135,
        -0.04330648481845856,
        0.046381060034036636,
        -0.05109170079231262,
        0.051597896963357925,
        0.043125882744789124,
        0.051646411418914795,
        -0.04705243930220604,
        -0.0046912082470953465,
        -0.0069516547955572605,
        0.04533972591161728,
        0.05121489614248276,
        0.03967335447669029,
        0.00872928649187088,
        0.05167677253484726,
        -0.05148414149880409,
        -0.03612050414085388,
        0.05089230090379715,
        0.05048613250255585,
        0.035140544176101685,
        -0.049400217831134796,
        0.05029101297259331,
        -0.05167386680841446,
        -0.05166461318731308,
        0.04493456706404686,
        0.045523449778556824,
        0.043002836406230927,
        -0.042802561074495316,
        0.050402648746967316,
        0.05153795704245567,
        -0.002889329567551613,
        0.005256190896034241,
        0.05167252942919731,
        0.03808458521962166,
        0.041152473539114,
        -0.05161575973033905,
        -0.019207464531064034,
        0.008202805183827877,
        -0.05143122747540474,
        -0.051383208483457565,
        -0.04341735318303108,
        -0.014330553822219372,
        -0.0503750815987587,
        -0.039332643151283264,
        -0.05163446441292763,
        0.04925551265478134,
        -0.04967900365591049,
        0.049693748354911804,
        0.05155114457011223,
        -0.027292750775814056,
        0.04943322390317917,
        0.051584336906671524,
        0.041651349514722824,
        -0.008504737168550491,
        -0.05104465410113335,
        -0.05104539543390274,
        -0.008025141432881355,
        0.05117110535502434,
        0.05150274187326431,
        0.05099606141448021,
        -0.05097028613090515,
        0.05116410180926323,
        0.050863951444625854,
        0.03074605204164982,
        0.034573789685964584,
        0.05162219703197479,
        -0.048787184059619904,
        -0.04889881983399391,
        -0.0003856474068015814,
        -0.050951771438121796,
        -0.049958735704422,
        -0.03816617652773857,
        0.04273097217082977,
        -0.05164844170212746,
        0.034123532474040985,
        -0.051664143800735474,
        0.047403935343027115,
        0.05049438774585724,
        0.03348645567893982,
        -0.03433816879987717,
        0.012227577157318592,
        -0.0406918078660965,
        0.05136040970683098,
        -0.03595877066254616,
        0.051477573812007904,
        -0.042701542377471924,
        -0.012668706476688385,
        0.051529303193092346,
        -0.05153791606426239,
        0.05147039517760277,
        0.051631975919008255,
        -0.008564712479710579,
        0.050787489861249924,
        0.05088861659169197,
        0.032136380672454834,
        0.04200180247426033,
        0.04947720840573311,
        -0.04688549041748047,
        -0.0318317748606205,
        0.05011243745684624,
        -0.017973661422729492,
        0.020881332457065582,
        -0.05133894830942154,
        0.050132494419813156,
        0.04990217834711075,
        -0.006253841333091259,
        0.05154163017868996,
        0.012878336012363434,
        -0.05056190490722656,
        0.03509325161576271,
        0.05167620629072189,
        0.0516621470451355,
        -0.002683741506189108,
        -0.03510938957333565,
        -0.018499258905649185,
        0.02624034322798252,
        -0.031149322167038918,
        0.04991893842816353,
        0.0332062654197216,
        -0.05126243084669113,
        -0.04806642234325409,
        0.0283223707228899,
        -0.051671575754880905,
        0.02276694029569626,
        -0.043796949088573456,
        -0.049432989209890366,
        -0.042834166437387466,
        0.05025051161646843,
        0.04334947094321251,
        -0.05138227716088295,
        0.048459380865097046,
        -0.009574620984494686,
        0.03880138322710991,
        -0.051234424114227295,
        -0.04339858144521713,
        -0.020858680829405785,
        -0.04177584499120712,
        -0.04466927796602249,
        -0.00965035893023014,
        0.04796842858195305,
        0.051616888493299484,
        -0.05082831531763077,
        0.05151806026697159,
        0.04955499246716499,
        0.008702908642590046,
        -0.036049213260412216,
        -0.05154365673661232,
        0.0038948720321059227,
        -0.04498608037829399,
        -0.04797392711043358,
        -0.040298230946063995,
        -0.05165804550051689,
        -0.03532605990767479,
        0.051090534776449203,
        0.025354990735650063,
        -0.0513005256652832,
        -0.04301462322473526,
        -0.051550257951021194,
        -0.051519740372896194,
        0.0516645573079586,
        -0.05136336386203766,
        0.042446695268154144,
        -0.05166567116975784,
        -0.04575667902827263,
        -0.051615022122859955,
        -0.038104865700006485,
        0.0507611408829689,
        0.05024275556206703,
        0.04502386227250099,
        0.04220777750015259,
        -0.04128715395927429,
        0.051668692380189896,
        0.022698212414979935,
        -0.05120792239904404,
        -0.043861471116542816,
        -0.05028722435235977,
        0.05078516900539398,
        -0.007148840464651585,
        -0.050058357417583466,
        -0.05165528878569603,
        0.03548276051878929,
        -0.051027435809373856,
        -0.03693990781903267,
        -0.045616231858730316,
        0.05032312124967575,
        -0.050336673855781555,
        -0.048883840441703796,
        -0.044517163187265396,
        0.05012226104736328,
        -0.024863041937351227,
        0.007874224334955215,
        -0.05078735202550888,
        -0.04904932528734207,
        0.009881583042442799,
        0.05154041200876236,
        0.05018856003880501,
        0.04260016605257988,
        0.05164400860667229,
        0.05141756683588028,
        0.05078599229454994,
        -0.051580388098955154,
        0.05165658891201019,
        0.05159853398799896,
        0.026328302919864655,
        -0.040156085044145584,
        0.043866775929927826,
        -0.006097367964684963,
        0.05100702494382858,
        -0.051481056958436966,
        -0.05142673850059509,
        -0.024881789460778236,
        -0.03815878927707672,
        0.04828842356801033,
        0.05111916735768318,
        -0.05119064822793007,
        -0.05167096480727196,
        -0.05164625123143196,
        -0.05147640407085419,
        0.05163497477769852,
        -0.038799986243247986,
        -0.051654405891895294,
        -0.05161133036017418,
        -0.051668036729097366,
        0.05167638882994652,
        -0.049522507935762405,
        0.04744505137205124,
        0.04153207689523697,
        -0.0301018338650465,
        -0.051008082926273346,
        -0.05164140835404396,
        -0.03914255648851395,
        -0.02910826914012432,
        -0.051216788589954376,
        0.04450717195868492,
        0.05117688328027725,
        0.05051235482096672,
        0.051621343940496445,
        0.02143494039773941,
        -0.04216112568974495,
        -0.0282648466527462,
        -0.05034120753407478,
        -0.019230326637625694,
        0.051412973552942276,
        0.00681484118103981,
        0.05159315839409828,
        -0.0073351673781871796,
        0.04834591597318649,
        0.04439184069633484,
        -0.05127192288637161,
        0.03701655566692352,
        -0.051405809819698334,
        0.051654987037181854,
        -0.013180648908019066,
        0.05163826793432236,
        -0.05150780826807022,
        0.017091361805796623,
        -0.0474216565489769,
        0.036786265671253204,
        0.05165109783411026,
        -0.05148492753505707,
        0.05165252834558487,
        0.0403476282954216,
        -0.0513809472322464,
        0.044686079025268555,
        -0.04600120708346367,
        0.05137832090258598,
        0.0310114324092865,
        0.012646891176700592,
        0.019050171598792076,
        0.051661837846040726,
        0.05093587189912796,
        -0.022170329466462135,
        0.03280779346823692,
        0.05131954327225685,
        -0.047734811902046204,
        0.05141061171889305,
        -0.0211019366979599,
        0.05165696144104004,
        0.05052410066127777,
        0.051673680543899536,
        0.01904298923909664,
        -0.05150268226861954,
        -0.02385403960943222,
        -0.051451683044433594,
        -0.03447553142905235,
        0.03860078006982803,
        0.05122634023427963,
        -0.051438771188259125,
        -0.0441022664308548,
        -0.05167147517204285,
        0.04488882049918175,
        0.051361579447984695,
        0.051383353769779205,
        -0.05123487859964371,
        -0.04031761363148689,
        0.0031223753467202187,
        -0.046840518712997437,
        0.04891490936279297,
        0.051356375217437744,
        0.05167647451162338,
        -0.03864545002579689,
        0.05167674273252487,
        -0.051539693027734756,
        -0.035912226885557175,
        0.04611475393176079
    ],
    [
        0.02394283562898636,
        0.053782928735017776,
        0.06086387485265732,
        -0.015559898689389229,
        0.0367390401661396,
        -0.007754180580377579,
        0.06031253561377525,
        0.01923377625644207,
        -0.035402100533246994,
        0.06152917444705963,
        0.007615573704242706,
        0.01746731624007225,
        -0.053143229335546494,
        0.0321960486471653,
        -0.057829659432172775,
        0.030431371182203293,
        -0.062081318348646164,
        -0.03281155601143837,
        -0.04528706520795822,
        0.05187191441655159,
        -0.04842384159564972,
        -0.00638964818790555,
        0.018881749361753464,
        0.05208849534392357,
        0.04664933681488037,
        0.04012290760874748,
        0.02781248465180397,
        0.060394011437892914,
        -0.049226485192775726,
        -0.05713376775383949,
        0.06214737519621849,
        0.02549228072166443,
        0.03930473327636719,
        -0.05603018403053284,
        -0.013838411308825016,
        -0.018552090972661972,
        -0.04640963301062584,
        -0.022586876526474953,
        -0.06316698342561722,
        0.04029543697834015,
        -0.011367655359208584,
        0.018282782286405563,
        0.05993092805147171,
        -0.045696698129177094,
        0.004957732278853655,
        0.011536619625985622,
        0.044918011873960495,
        0.06258551776409149,
        0.047642361372709274,
        0.05206652358174324,
        -0.008779224939644337,
        0.05869436636567116,
        0.060044750571250916,
        0.01074102520942688,
        -0.050934143364429474,
        0.01749824546277523,
        -0.05892707407474518,
        -0.037632279098033905,
        -0.058775920420885086,
        0.04717530682682991,
        -0.061258118599653244,
        -0.01900162175297737,
        -0.04206116870045662,
        0.012257294729351997,
        -0.040696881711483,
        0.03034353256225586,
        0.040764663368463516,
        0.003426122013479471,
        0.05887294188141823,
        -0.06319442391395569,
        -0.06168109551072121,
        -0.05477544665336609,
        0.052443474531173706,
        -0.02436990849673748,
        -0.06262770295143127,
        0.03281884640455246,
        0.061311475932598114,
        0.05919924005866051,
        0.05874689295887947,
        -0.04809626191854477,
        -0.0547889806330204,
        0.041097793728113174,
        -0.050065625458955765,
        -0.027206435799598694,
        -0.009852283634245396,
        0.027992254123091698,
        -0.00503162108361721,
        -0.05804614722728729,
        0.0011386696714907885,
        0.05565077066421509,
        -0.059219829738140106,
        0.01194845512509346,
        0.0001628877071198076,
        -0.061989765614271164,
        0.04057115688920021,
        0.04320092126727104,
        -0.03595408424735069,
        -0.04601467773318291,
        -0.05729750171303749,
        0.03447509929537773,
        -0.0600583553314209,
        -0.028585586696863174,
        0.06057477742433548,
        0.058587271720170975,
        0.006869207601994276,
        -0.026010245084762573,
        -0.023751668632030487,
        0.0036893184296786785,
        -0.01987263560295105,
        -0.05562129244208336,
        -0.02115042321383953,
        -0.054953742772340775,
        -0.04168469086289406,
        0.000832729390822351,
        0.06305418163537979,
        -0.0486287921667099,
        -0.035460978746414185,
        -0.028791217133402824,
        -0.06148674711585045,
        -0.007730287965387106,
        0.02492368407547474,
        -0.02150362730026245,
        -0.06263352930545807,
        0.005448176525533199,
        -0.06304958462715149,
        0.04569238796830177,
        -0.06147775053977966,
        0.05326925963163376,
        0.06201687827706337,
        0.04347320273518562,
        0.050233032554388046,
        -0.036462437361478806,
        -0.045137085020542145,
        0.037310417741537094,
        -0.045507948845624924,
        -0.047702357172966,
        0.01609298586845398,
        -0.04959362372756004,
        -0.04925484582781792,
        0.057317886501550674,
        0.06196609511971474,
        -0.014720488339662552,
        0.04641914367675781,
        0.05155660957098007,
        0.028587333858013153,
        -0.0464613176882267,
        0.052772197872400284,
        -0.06093486770987511,
        -0.055692318826913834,
        0.05148685351014137,
        -0.05952034518122673,
        -0.06288401782512665,
        0.0018847269238904119,
        0.007904003374278545,
        0.00785384513437748,
        -0.01591935195028782,
        0.060714222490787506,
        -0.055004119873046875,
        0.0000439722825831268,
        0.0006313015474006534,
        0.04760356619954109,
        -0.005157133098691702,
        0.05390151962637901,
        -0.035417065024375916,
        -0.04085506871342659,
        -0.03288549557328224,
        0.006249088793992996,
        -0.061321403831243515,
        -0.010382733307778835,
        0.047517791390419006,
        -0.04264603555202484,
        -0.0464644692838192,
        -0.0518559068441391,
        0.061045896261930466,
        0.06239348649978638,
        0.055722981691360474,
        -0.057692889124155045,
        0.03219275921583176,
        0.059774816036224365,
        -0.012955884449183941,
        0.05514170601963997,
        -0.003076485125347972,
        0.0605967678129673,
        0.03832684084773064,
        0.019450092688202858,
        0.00908722635358572,
        -0.05405711382627487,
        0.058347608894109726,
        -0.043692074716091156,
        0.057801954448223114,
        0.005554317496716976,
        -0.05041167140007019,
        -0.04279213398694992,
        0.048715148121118546,
        -0.06084221601486206,
        -0.019695047289133072,
        0.04692331701517105,
        -0.007235831581056118,
        -0.03226521611213684,
        0.03941648080945015,
        -0.015174753963947296,
        0.060911040753126144,
        0.02316828817129135,
        -0.04748881980776787,
        0.0248502716422081,
        0.016535839065909386,
        0.018802311271429062,
        -0.0003741253458429128,
        -0.04280838370323181,
        0.002675086259841919,
        -0.010087241418659687,
        0.05495058372616768,
        -0.04958224296569824,
        -0.019171111285686493,
        0.05617497116327286,
        -0.05710512399673462,
        0.05114145949482918,
        -0.010364724323153496,
        0.062114324420690536,
        -0.05696699768304825,
        0.02058698609471321,
        0.04064656049013138,
        -0.057286337018013,
        -0.06285708397626877,
        0.03889836370944977,
        0.017554273828864098,
        -0.0370485894382,
        0.04488096013665199,
        -0.023267481476068497,
        -0.060023583471775055,
        -0.06283669173717499,
        0.006515210960060358,
        -0.015572663396596909,
        0.05445177108049393,
        0.03730080649256706,
        0.0264306478202343,
        0.060740768909454346,
        -0.048023771494627,
        -0.031016839668154716,
        0.05810283124446869,
        0.059546831995248795,
        0.05422358959913254,
        0.016430959105491638,
        0.022231731563806534,
        -0.033639635890722275,
        0.0033262562938034534,
        -0.04160941392183304,
        -0.03685633838176727,
        -0.01309604849666357,
        -0.013213273137807846,
        -0.04371219128370285,
        -0.062272343784570694,
        -0.03921923041343689,
        -0.058205459266901016,
        0.013793906196951866,
        0.060891442000865936,
        -0.02393735758960247,
        0.05673755705356598,
        0.0628957599401474,
        0.05720968917012215,
        0.028359707444906235,
        0.023195965215563774,
        0.022484835237264633,
        0.060547295957803726,
        -0.04454415664076805,
        0.03439394012093544,
        0.032722216099500656,
        0.0607890821993351,
        -0.04924357309937477,
        -0.011197471991181374,
        -0.0483522042632103,
        -0.06211380660533905,
        0.054467663168907166,
        -0.062208566814661026,
        -0.05913245305418968,
        0.05435718968510628,
        -0.0618736632168293,
        -0.0063887713477015495,
        -0.06121883541345596,
        -0.0347110740840435,
        0.03934238851070404,
        -0.0333428755402565,
        0.02172934077680111,
        0.03418522700667381,
        0.018838483840227127,
        -0.04848293215036392,
        0.05574840307235718,
        -0.044459737837314606,
        -0.0562828853726387,
        0.05339213088154793,
        -0.057823169976472855,
        -0.0000636161130387336,
        -0.03918689489364624,
        0.03388472646474838,
        0.059892427176237106,
        -0.031744152307510376,
        0.06292615085840225,
        -0.03376391530036926,
        -0.04993799701333046,
        0.032967135310173035,
        0.009270955808460712,
        -0.018020229414105415,
        -0.04505084827542305,
        0.059595342725515366,
        0.023223748430609703,
        -0.04685487598180771,
        -0.0533747635781765,
        0.032916028052568436,
        0.04433382675051689,
        -0.058823827654123306,
        0.05676320195198059,
        -0.03399527072906494,
        -0.0446263924241066,
        0.04558121785521507,
        -0.05974385887384415,
        -0.055041663348674774,
        0.0622493177652359,
        -0.03807402774691582,
        0.02152642048895359,
        -0.048341501504182816,
        -0.062249138951301575,
        0.03698453679680824,
        0.06011935696005821,
        -0.06294597685337067,
        0.022716857492923737,
        0.057335853576660156,
        0.039472632110118866,
        0.0549892894923687,
        -0.04765956103801727,
        -0.035978447645902634,
        -0.03776523470878601,
        0.004979143850505352,
        0.02742256596684456,
        0.047053225338459015,
        0.059889186173677444,
        0.032239485532045364,
        0.010855009779334068,
        0.05883888900279999,
        -0.028190486133098602,
        0.025514381006360054,
        0.0031866077333688736,
        -0.057471491396427155,
        -0.05010654404759407,
        -0.05605391785502434,
        0.06318961828947067,
        0.020918816328048706,
        0.03232042118906975,
        0.035449400544166565,
        -0.03890451788902283,
        0.05443980172276497,
        -0.0623067282140255,
        0.06084073707461357,
        -0.04434650018811226,
        -0.06312596797943115,
        -0.06162435933947563,
        0.05536933243274689,
        -0.05613301694393158,
        0.0165195781737566,
        -0.06216176599264145,
        -0.04190010577440262,
        0.060744304209947586,
        0.01768304780125618,
        -0.04684479534626007,
        -0.05578850954771042,
        0.05248507857322693,
        -0.051194846630096436,
        0.06315305829048157,
        0.061630673706531525,
        -0.036854296922683716,
        -0.06277842074632645,
        0.0018297892529517412,
        -0.060288362205028534,
        0.06019309535622597,
        0.00745087955147028,
        0.05671674758195877,
        0.06250671297311783,
        0.06033788621425629,
        -0.03505146875977516,
        -0.04700512811541557,
        -0.055793166160583496,
        -0.023974163457751274,
        0.05980823561549187,
        -0.0524417944252491,
        -0.002308217342942953,
        0.05512841418385506,
        -0.04728902503848076,
        -0.00634035374969244,
        -0.0620744489133358,
        -0.05819965898990631,
        -0.05989222601056099,
        -0.006406467407941818,
        0.012801135890185833,
        0.012243184261023998,
        0.05966959521174431,
        -0.042766593396663666,
        0.05932438373565674,
        0.06099840626120567,
        -0.033624324947595596,
        -0.023410383611917496,
        -0.05746961385011673,
        -0.006587428972125053,
        0.01917552761733532,
        0.05889499932527542,
        0.06261060386896133,
        -0.024714943021535873,
        -0.013660663738846779,
        0.053081899881362915,
        0.013132123276591301,
        0.0536782443523407,
        -0.045770253986120224,
        0.0523398220539093,
        -0.05168699473142624,
        -0.05534803867340088,
        -0.05818401277065277,
        0.05669988691806793,
        -0.012080932036042213,
        -0.005276422016322613,
        0.02625034935772419,
        0.02826346457004547,
        -0.054505955427885056,
        -0.048387277871370316,
        -0.032329365611076355,
        -0.06295017153024673,
        -0.05152508616447449,
        -0.02819281630218029,
        0.05805996432900429,
        -0.009654175490140915,
        -0.02366391196846962,
        -0.059940025210380554,
        -0.016853081062436104,
        -0.0251089408993721,
        0.011267393827438354,
        0.003532646456733346,
        0.020261194556951523,
        0.06098008155822754,
        -0.04090266674757004,
        0.027274738997220993,
        0.043097347021102905,
        0.057744234800338745,
        -0.060573138296604156,
        0.04770346358418465,
        0.059509046375751495,
        0.03139152750372887,
        0.053108569234609604,
        0.062216684222221375,
        0.018500246107578278,
        0.061762623488903046,
        -0.011304761283099651,
        0.05317426472902298,
        -0.0441497303545475,
        -0.045592933893203735,
        0.05811100825667381,
        0.05530639365315437,
        0.010066473856568336,
        0.05383474752306938,
        -0.011761480011045933,
        0.04665958881378174,
        0.0012308107689023018,
        -0.05561957508325577,
        0.048732057213783264,
        0.06278631091117859,
        -0.0010747488122433424,
        -0.05664604529738426,
        -0.05385242775082588,
        0.06236444041132927,
        0.06276986002922058,
        -0.05800288915634155,
        0.061745308339595795,
        -0.026644939556717873,
        -0.05902547389268875,
        0.035055920481681824,
        -0.06090658903121948,
        0.061464257538318634,
        0.03710386902093887,
        0.047355856746435165,
        0.02492695301771164,
        -0.05970003083348274,
        -0.03018312342464924,
        -0.047256696969270706,
        0.013306289911270142,
        0.05023042857646942,
        -0.027996329590678215,
        -0.025988981127738953,
        -0.009574285708367825,
        0.026231711730360985,
        0.02958335168659687,
        0.03542467951774597,
        0.027978116646409035,
        -0.049559492617845535,
        -0.031204253435134888,
        -0.032815948128700256,
        -0.029674960300326347,
        -0.020833365619182587,
        0.055445946753025055,
        0.04401375353336334,
        0.03147122636437416,
        -0.061889566481113434,
        -0.032268792390823364,
        0.03067230060696602,
        0.0420968160033226,
        -0.0420532152056694,
        -0.048071905970573425,
        -0.021734608337283134,
        0.04694676399230957,
        0.02574986219406128,
        0.010481401346623898,
        0.016287649050354958,
        -0.017399867996573448,
        -0.04245081916451454,
        -0.020536180585622787,
        0.0051085203886032104,
        0.06033597141504288
    ],
    [
        0.03593628108501434,
        -0.052163008600473404,
        0.05191398784518242,
        0.05069556459784508,
        -0.014368551783263683,
        0.011343871243298054,
        0.0211480725556612,
        -0.05212835595011711,
        0.04857252910733223,
        -0.05035262554883957,
        -0.02641032077372074,
        -0.05215577781200409,
        -0.04407114535570145,
        -0.03954542428255081,
        -0.048594068735837936,
        0.004774659406393766,
        -0.05216725915670395,
        0.051647383719682693,
        -0.052068229764699936,
        0.05187506601214409,
        0.05211464315652847,
        -0.040698274970054626,
        0.04645698517560959,
        -0.04954710602760315,
        0.04466596618294716,
        0.0452030673623085,
        -0.042515721172094345,
        0.05137298256158829,
        -0.05216117948293686,
        -0.04534878954291344,
        0.029116246849298477,
        0.04757096618413925,
        0.04993245005607605,
        -0.020943058654665947,
        -0.020947247743606567,
        0.023894434794783592,
        -0.05199713259935379,
        0.035653505474328995,
        0.032121285796165466,
        -0.052000053226947784,
        0.03334043174982071,
        0.05158253014087677,
        0.05093471333384514,
        -0.04301750659942627,
        -0.05161077156662941,
        0.04447922110557556,
        -0.05178844556212425,
        0.051952287554740906,
        0.05194564908742905,
        0.05012879520654678,
        0.05217308551073074,
        0.048135995864868164,
        0.03613240644335747,
        0.05087495595216751,
        -0.045962415635585785,
        0.05173478648066521,
        0.04860794171690941,
        -0.04489530622959137,
        0.011090170592069626,
        -0.05217226594686508,
        -0.0514276959002018,
        -0.0502094067633152,
        -0.04495667293667793,
        0.031184470281004906,
        -0.046028390526771545,
        -0.04394827038049698,
        0.01186524797230959,
        0.02699381299316883,
        0.05217320844531059,
        0.05214725807309151,
        0.042738523334264755,
        -0.050723444670438766,
        -0.04389260336756706,
        0.051898691803216934,
        0.05019811913371086,
        -0.004504711367189884,
        0.05041629076004028,
        0.0521731935441494,
        0.04925951361656189,
        -0.04127579927444458,
        -0.04867996275424957,
        0.008127875626087189,
        -0.05214156210422516,
        -0.02471652440726757,
        -0.052166931331157684,
        0.05034777522087097,
        0.05206209421157837,
        0.050051528960466385,
        -0.03449451923370361,
        -0.0036285545211285353,
        0.050902243703603745,
        -0.0326935239136219,
        -0.00986996665596962,
        0.047155577689409256,
        -0.04647278040647507,
        0.051536738872528076,
        -0.02859610505402088,
        -0.052173011004924774,
        -0.01671101711690426,
        0.01136819738894701,
        -0.05215180292725563,
        -0.046802207827568054,
        0.05217047035694122,
        -0.005286951083689928,
        0.021283915266394615,
        -0.05195595696568489,
        -0.051584500819444656,
        0.051329053938388824,
        -0.05212251469492912,
        -0.05057702213525772,
        -0.051084037870168686,
        -0.050667744129896164,
        -0.05217289924621582,
        0.0006186785758472979,
        0.052161719650030136,
        -0.052079442888498306,
        -0.04587675258517265,
        -0.03318764641880989,
        0.03826682269573212,
        -0.05182036757469177,
        0.051099926233291626,
        -0.05216047167778015,
        0.05211900547146797,
        -0.03802552446722984,
        -0.04700706899166107,
        -0.03182108700275421,
        -0.05215427652001381,
        0.04702320322394371,
        0.05216183513402939,
        -0.05213456228375435,
        -0.05208343639969826,
        -0.05196237564086914,
        -0.05088222026824951,
        0.050550710409879684,
        -0.05189746990799904,
        -0.0519057996571064,
        0.052022237330675125,
        0.05192969739437103,
        -0.052084021270275116,
        0.05145420879125595,
        0.050838980823755264,
        0.030656971037387848,
        0.045707426965236664,
        0.05152413621544838,
        -0.051392875611782074,
        -0.00831365492194891,
        -0.049238864332437515,
        -0.0520491786301136,
        -0.051091887056827545,
        -0.0477425791323185,
        -0.05217147618532181,
        -0.05216703563928604,
        -0.05208362266421318,
        -0.04977330565452576,
        0.052058834582567215,
        -0.047706715762615204,
        -0.04467220604419708,
        -0.052024032920598984,
        0.051460448652505875,
        -0.05212899670004845,
        -0.018772723153233528,
        -0.01256661955267191,
        0.05175056308507919,
        0.00262395734898746,
        0.04603057727217674,
        -0.05215456336736679,
        0.04895780235528946,
        -0.04725988954305649,
        0.03301406651735306,
        0.01677926443517208,
        0.005126665811985731,
        0.0513230636715889,
        0.052071042358875275,
        -0.02295532450079918,
        0.04083652049303055,
        -0.013704808428883553,
        -0.022906575351953506,
        0.008568057790398598,
        0.05093700811266899,
        0.05162736773490906,
        0.05211593210697174,
        0.0483640655875206,
        0.05205617472529411,
        -0.05164799839258194,
        -0.03227193281054497,
        0.05203535407781601,
        -0.05216584727168083,
        0.05140269547700882,
        -0.04690667986869812,
        -0.04967327043414116,
        -0.04523322731256485,
        0.043429382145404816,
        -0.050167448818683624,
        0.04871917888522148,
        -0.05210500955581665,
        -0.03298048675060272,
        0.052106089890003204,
        0.052134837955236435,
        0.05217176675796509,
        0.05165307596325874,
        0.05211058259010315,
        -0.04055676609277725,
        -0.03110283613204956,
        -0.051216356456279755,
        0.034070130437612534,
        -0.05215839669108391,
        0.05217297375202179,
        0.05216022953391075,
        -0.052142105996608734,
        0.05205236002802849,
        -0.04701373726129532,
        0.04397564381361008,
        -0.05197010189294815,
        0.03228386491537094,
        -0.012869685888290405,
        -0.05211019143462181,
        0.05045657232403755,
        -0.05216463655233383,
        0.05217241495847702,
        0.05097857490181923,
        0.010768039152026176,
        0.051984935998916626,
        0.049198947846889496,
        -0.03041376918554306,
        0.051884543150663376,
        0.0010704188607633114,
        0.016136031597852707,
        0.022868912667036057,
        -0.043162159621715546,
        -0.05124635249376297,
        -0.05217264965176582,
        0.051999613642692566,
        -0.04872860386967659,
        0.0497194342315197,
        0.029522540047764778,
        0.052166569977998734,
        0.05061681568622589,
        -0.052140019834041595,
        0.05210712552070618,
        0.051127128303050995,
        0.05114831030368805,
        0.011037716642022133,
        -0.041445646435022354,
        -0.05217169225215912,
        -0.021945757791399956,
        0.010190659202635288,
        -0.00726865092292428,
        -0.03416167199611664,
        0.0521337129175663,
        0.0442461222410202,
        0.043056655675172806,
        -0.05165036767721176,
        -0.047677263617515564,
        -0.035463739186525345,
        -0.052108824253082275,
        -0.009323444217443466,
        0.0030437158420681953,
        0.04571639746427536,
        0.02396099455654621,
        0.020335491746664047,
        -0.04857660457491875,
        -0.05215037241578102,
        0.018534021452069283,
        0.05118642374873161,
        0.05055924504995346,
        0.04913961514830589,
        0.05191168934106827,
        0.03576410934329033,
        0.05147486552596092,
        -0.027856390923261642,
        0.051313165575265884,
        -0.05213376507163048,
        0.05138483643531799,
        -0.05019744858145714,
        -0.052171990275382996,
        -0.004543577320873737,
        -0.04819611832499504,
        -0.04879523813724518,
        -0.039295829832553864,
        0.03801095858216286,
        0.03434934839606285,
        0.04912249371409416,
        -0.04906138777732849,
        0.03228973224759102,
        -0.05215058848261833,
        0.048566509038209915,
        -0.04042412340641022,
        0.046199917793273926,
        0.001750615076161921,
        0.04954991117119789,
        -0.004429685417562723,
        0.03862524777650833,
        0.05202271416783333,
        0.05200406163930893,
        0.05216177925467491,
        -0.05212943255901337,
        0.05217309668660164,
        -0.051970772445201874,
        -0.05116304010152817,
        0.030026011168956757,
        0.051328495144844055,
        0.011835742741823196,
        0.004005300346761942,
        0.05210435017943382,
        -0.04477014020085335,
        -0.015516799874603748,
        0.05107308179140091,
        0.05149509012699127,
        -0.04991781339049339,
        -0.02604399062693119,
        -0.05169219896197319,
        0.05216033384203911,
        0.01808059774339199,
        0.05189258232712746,
        -0.05192911624908447,
        -0.05128273740410805,
        0.046277955174446106,
        -0.04035429656505585,
        -0.011411404237151146,
        0.05150018259882927,
        0.05185331776738167,
        -0.05216914042830467,
        0.013022540137171745,
        0.00034643991966731846,
        0.05196157470345497,
        -0.04759842902421951,
        -0.05204780772328377,
        0.04244273528456688,
        -0.049590956419706345,
        -0.05214826017618179,
        0.0509491041302681,
        -0.05130840837955475,
        -0.05183933302760124,
        0.00012031375808874145,
        0.04403659328818321,
        0.05119621381163597,
        0.049491360783576965,
        0.05217240750789642,
        -0.005126815754920244,
        0.05217156931757927,
        0.0192967988550663,
        -0.05211743712425232,
        -0.003815780393779278,
        0.02842305228114128,
        0.05216006934642792,
        0.03120087832212448,
        -0.048539575189352036,
        0.051642972975969315,
        0.040335919708013535,
        0.051193803548812866,
        0.03941204771399498,
        -0.04635365679860115,
        -0.0074764275923371315,
        -0.03387310728430748,
        -0.05152237415313721,
        0.052082572132349014,
        -0.05128970369696617,
        -0.05020998418331146,
        -0.04676220566034317,
        0.03803117945790291,
        0.04993421584367752,
        0.05101901665329933,
        -0.05216217786073685,
        -0.014594634994864464,
        0.015533041208982468,
        0.0006849688361398876,
        0.050325602293014526,
        -0.047846727073192596,
        -0.05172652751207352,
        -0.05208831652998924,
        -0.050277091562747955,
        -0.024211861193180084,
        0.0447889044880867,
        0.05210009217262268,
        0.029185675084590912,
        0.051922596991062164,
        0.04563851282000542,
        0.03739611804485321,
        0.05214063823223114,
        -0.006766986567527056,
        -0.0349278524518013,
        0.050824474543333054,
        -0.05039895325899124,
        -0.049650099128484726,
        0.026318524032831192,
        0.027479996904730797,
        0.045576512813568115,
        -0.0521659180521965,
        -0.052139561623334885,
        -0.04605468362569809,
        0.021577058359980583,
        0.007439981214702129,
        -0.052128273993730545,
        -0.003796611214056611,
        0.05197247862815857,
        0.05167585238814354,
        0.05209515988826752,
        0.04840412735939026,
        -0.05205940827727318,
        -0.052150603383779526,
        0.04923323541879654,
        0.05216217786073685,
        0.04091884195804596,
        0.052112482488155365,
        0.04014311358332634,
        -0.04026132449507713,
        0.04798043519258499,
        -0.05102963373064995,
        0.05214536190032959,
        0.022913537919521332,
        0.0057165976613759995,
        -0.05212957784533501,
        0.02794184908270836,
        -0.05168657377362251,
        0.052111562341451645,
        -0.04434625431895256,
        0.0520918108522892,
        0.05186944454908371,
        -0.03630426898598671,
        0.05214759334921837,
        0.006972073577344418,
        -0.05216051638126373,
        -0.04482882097363472,
        -0.052135664969682693,
        -0.05134518817067146,
        -0.05186711251735687,
        0.042467691004276276,
        -0.02036401256918907,
        0.010418061167001724,
        -0.052096836268901825,
        -0.02591264620423317,
        -0.024091286584734917,
        -0.04598196595907211,
        0.041559696197509766,
        0.05210825428366661,
        -0.012418646365404129,
        -0.05215303972363472,
        -0.046771373599767685,
        -0.04633607715368271,
        -0.05174749344587326,
        0.05146894231438637,
        -0.007916664704680443,
        0.0518808513879776,
        0.050707243382930756,
        0.05207602679729462,
        0.05155640468001366,
        0.05204789713025093,
        -0.04929579421877861,
        -0.040054626762866974,
        0.042691636830568314,
        -0.029426604509353638,
        -0.05180908739566803,
        -0.03120528534054756,
        0.04671745374798775,
        -0.027993598952889442,
        -0.050251204520463943,
        -0.03093205764889717,
        0.04884135723114014,
        0.04994581639766693,
        0.0045167128555476665,
        0.0493764691054821,
        -0.029056230559945107,
        -0.05164036899805069,
        0.0209228303283453,
        -0.04914385452866554,
        0.05213693156838417,
        -0.04398707300424576,
        0.05208507925271988,
        0.04131772369146347,
        0.028121287003159523,
        -0.024771610274910927,
        -0.052114516496658325,
        0.05210580304265022,
        0.04483854025602341,
        0.05167873576283455,
        0.04603046923875809,
        0.05214781314134598,
        0.052167464047670364,
        0.041868675500154495,
        -0.009079262614250183,
        -0.04863208904862404,
        -0.049670230597257614,
        -0.0520448237657547,
        -0.017872700467705727,
        0.05116806924343109,
        -0.05187898501753807,
        -0.01604514755308628,
        0.047384653240442276,
        -0.052066802978515625,
        0.05180760473012924,
        0.0425821915268898,
        0.050971318036317825,
        0.05060332268476486,
        0.01918119378387928,
        -0.0506296306848526,
        0.03719501197338104,
        0.009268601424992085,
        -0.05106521397829056,
        0.05106906220316887,
        0.05201894789934158,
        -0.04242861643433571,
        -0.05023106187582016,
        0.05213196203112602,
        -0.04346010461449623,
        -0.008088712580502033,
        0.05045408383011818,
        -0.008116979151964188,
        -0.051243290305137634,
        0.00793053861707449,
        -0.05197516083717346,
        0.0519745871424675,
        0.049375347793102264
    ],
    [
        0.04128533601760864,
        0.010560913942754269,
        0.052708085626363754,
        0.010028278455138206,
        0.013460807502269745,
        -0.0244915708899498,
        0.05310119315981865,
        -0.05303403362631798,
        0.05231492593884468,
        0.025663018226623535,
        0.052901897579431534,
        0.051353149116039276,
        -0.053130827844142914,
        0.04873254895210266,
        -0.05297727510333061,
        0.049786198884248734,
        0.015071781352162361,
        0.04745443910360336,
        0.04568664729595184,
        -0.04095090925693512,
        -0.023520706221461296,
        0.052693940699100494,
        0.0468837209045887,
        0.04731043800711632,
        -0.04859349504113197,
        0.04314706474542618,
        0.05076562613248825,
        0.0472821444272995,
        -0.053052790462970734,
        -0.04534512758255005,
        0.04747456684708595,
        0.0499231219291687,
        0.05264347791671753,
        -0.053092990070581436,
        0.05188138782978058,
        -0.04191364347934723,
        -0.052141305059194565,
        0.02191109210252762,
        0.05302222818136215,
        0.029297342523932457,
        0.008187309838831425,
        -0.014128237031400204,
        0.050442904233932495,
        0.00140576611738652,
        -0.012398273684084415,
        -0.018316755071282387,
        0.02897433564066887,
        0.05247105285525322,
        0.053137313574552536,
        -0.04799380898475647,
        0.0526081807911396,
        0.048217013478279114,
        0.029079247266054153,
        -0.04939158260822296,
        0.004223361145704985,
        -0.0018081513699144125,
        -0.036914579570293427,
        0.036681968718767166,
        -0.053118061274290085,
        -0.05025099590420723,
        -0.05310497060418129,
        0.05162123218178749,
        -0.052388738840818405,
        -0.05306878685951233,
        0.0519147589802742,
        -0.010193766094744205,
        -0.05277957394719124,
        -0.004344789776951075,
        0.04936842992901802,
        -0.04722683131694794,
        -0.047134481370449066,
        -0.028612196445465088,
        0.053129833191633224,
        0.05313330888748169,
        0.04241618514060974,
        0.03784797713160515,
        0.041703931987285614,
        0.05313936620950699,
        0.05311241000890732,
        0.05089224874973297,
        0.04924081265926361,
        -0.05014362931251526,
        -0.05065919831395149,
        0.05219387635588646,
        0.042339012026786804,
        0.03743285685777664,
        -0.006384669337421656,
        0.03500814735889435,
        -0.0484626330435276,
        0.052235495299100876,
        -0.05160218104720116,
        -0.04907723143696785,
        0.04606090858578682,
        -0.05312953516840935,
        -0.038178302347660065,
        0.049584437161684036,
        0.032962094992399216,
        0.04836457222700119,
        0.04425370320677757,
        0.05311480537056923,
        -0.05313916131854057,
        0.053020402789115906,
        0.014687004499137402,
        0.05301002040505409,
        0.012762957252562046,
        -0.052004601806402206,
        -0.05029584467411041,
        0.047323230654001236,
        0.0027846565935760736,
        0.05287441611289978,
        -0.05294165387749672,
        -0.05302884802222252,
        -0.040825776755809784,
        -0.04844425618648529,
        0.053130291402339935,
        -0.049265287816524506,
        -0.04855646938085556,
        -0.05312541499733925,
        -0.010231725871562958,
        -0.053131282329559326,
        -0.05293865501880646,
        -0.02921653725206852,
        0.03779081627726555,
        0.0010363926412537694,
        -0.046309083700180054,
        0.03544459864497185,
        -0.05312812328338623,
        0.04337878152728081,
        0.05274959281086922,
        -0.052318550646305084,
        0.03807449713349342,
        -0.04904939606785774,
        -0.04997074976563454,
        0.049359001219272614,
        -0.05276767909526825,
        -0.05081074312329292,
        0.05312952399253845,
        -0.05065478757023811,
        0.04359384998679161,
        0.05146335810422897,
        0.048576947301626205,
        -0.05213048681616783,
        0.04874726012349129,
        0.052597373723983765,
        -0.01205196138471365,
        0.038990896195173264,
        0.05303017795085907,
        -0.0529170036315918,
        -0.04909920692443848,
        0.04572206735610962,
        0.026223279535770416,
        -0.05216367542743683,
        -0.0518031120300293,
        -0.01767793297767639,
        0.053094662725925446,
        -0.05312228575348854,
        -0.04598795995116234,
        0.04145919904112816,
        0.0426473543047905,
        -0.04989699274301529,
        0.008249489590525627,
        0.03999597206711769,
        0.050213564187288284,
        0.05214088410139084,
        -0.029813315719366074,
        -0.051352161914110184,
        0.008075765334069729,
        -0.04444161802530289,
        -0.017567500472068787,
        0.04518917575478554,
        -0.03152267634868622,
        0.05292842537164688,
        -0.05313060060143471,
        0.053105056285858154,
        -0.03555714339017868,
        0.012943349778652191,
        0.016845587641000748,
        -0.05287541449069977,
        0.05305168777704239,
        -0.05256099998950958,
        -0.045556094497442245,
        0.05061938986182213,
        0.0526227131485939,
        -0.03153800591826439,
        0.05254220589995384,
        0.00021305328118614852,
        0.04856434091925621,
        0.05229423940181732,
        0.04934297874569893,
        -0.053065527230501175,
        -0.05311485752463341,
        -0.05200629681348801,
        0.052722055464982986,
        0.0242276880890131,
        -0.013336684554815292,
        0.030775222927331924,
        0.05308570712804794,
        -0.047673314809799194,
        0.03840252012014389,
        0.05286812409758568,
        0.05197497084736824,
        0.04710466414690018,
        0.05111684277653694,
        -0.04256759211421013,
        0.05170825123786926,
        0.012504932470619678,
        0.05310883745551109,
        0.009990818798542023,
        -0.050734762102365494,
        0.053034041076898575,
        -0.051843397319316864,
        -0.004845796152949333,
        0.04429139196872711,
        0.05293446406722069,
        -0.052776187658309937,
        -0.050313062965869904,
        0.05298423022031784,
        0.046153582632541656,
        0.052730802446603775,
        0.04995179548859596,
        0.053021568804979324,
        0.021731941029429436,
        -0.036930084228515625,
        -0.02369033731520176,
        0.05312919244170189,
        -0.052981920540332794,
        0.051559388637542725,
        -0.05286610871553421,
        0.03796837478876114,
        -0.05267627537250519,
        -0.03744923323392868,
        -0.05304614081978798,
        -0.04950985312461853,
        0.04920836538076401,
        0.04682738706469536,
        0.053127679973840714,
        0.052912626415491104,
        0.036617014557123184,
        0.04941956326365471,
        0.052976373583078384,
        0.03189802169799805,
        -0.04500814154744148,
        -0.052381232380867004,
        -0.04751432687044144,
        0.053016502410173416,
        -0.03943096101284027,
        -0.024108508601784706,
        0.04789822921156883,
        -0.027583342045545578,
        -0.04652627930045128,
        0.024091146886348724,
        -0.0501784086227417,
        0.040995027869939804,
        -0.05288224294781685,
        0.02403649315237999,
        0.05313841253519058,
        0.010905667208135128,
        -0.039604246616363525,
        0.017654038965702057,
        0.037000108510255814,
        0.04337020590901375,
        -0.047442905604839325,
        -0.02662057615816593,
        0.05312487483024597,
        0.014626399613916874,
        0.05307850241661072,
        0.05078674107789993,
        0.052476122975349426,
        -0.0010172341717407107,
        -0.011121701449155807,
        0.046888887882232666,
        -0.048967912793159485,
        0.04935762658715248,
        -0.05230387672781944,
        -0.04563724994659424,
        0.05265067145228386,
        -0.04213884100317955,
        -0.0282114390283823,
        -0.002168216509744525,
        -0.05273260548710823,
        -0.05305291712284088,
        0.05131762847304344,
        0.014745195396244526,
        -0.043239861726760864,
        0.035984717309474945,
        0.035291824489831924,
        -0.03789873421192169,
        0.05258534848690033,
        0.040047988295555115,
        -0.013065190054476261,
        -0.046877048909664154,
        -0.05093969404697418,
        0.029036995023489,
        0.010622654110193253,
        0.04973991960287094,
        -0.053048908710479736,
        0.05312800034880638,
        0.020107287913560867,
        0.00041756773134693503,
        0.053108833730220795,
        0.05243495851755142,
        0.014578680507838726,
        -0.05091230198740959,
        0.053047843277454376,
        0.031770072877407074,
        0.05040383338928223,
        -0.04682691767811775,
        0.051908399909734726,
        0.044616956263780594,
        -0.052921418100595474,
        0.047067854553461075,
        -0.021971380338072777,
        0.05126478895545006,
        0.0409238263964653,
        0.03368900343775749,
        -0.05294639617204666,
        0.04828602820634842,
        0.036142747849226,
        0.047711849212646484,
        0.046357475221157074,
        0.03920570760965347,
        -0.04948049783706665,
        0.05140133574604988,
        -0.03902093321084976,
        0.053118329495191574,
        -0.04933074861764908,
        0.0387115441262722,
        -0.0436173677444458,
        -0.05242210999131203,
        -0.05310029163956642,
        0.02655344270169735,
        -0.03350077196955681,
        -0.016706392168998718,
        0.005056440830230713,
        0.04736405611038208,
        0.03917843475937843,
        -0.051020801067352295,
        0.03358887881040573,
        -0.04039130359888077,
        0.05236755311489105,
        -0.011954559944570065,
        0.017766674980521202,
        0.05211751163005829,
        0.019647367298603058,
        0.04884908348321915,
        0.05304437503218651,
        -0.0004562262911349535,
        0.05195916071534157,
        -0.052433039993047714,
        0.05313010886311531,
        0.05306501314043999,
        0.05298883095383644,
        0.04512879624962807,
        -0.052722398191690445,
        -0.030360696837306023,
        0.05243740975856781,
        -0.05294045805931091,
        -0.045773640275001526,
        -0.04962973669171333,
        -0.053091518580913544,
        0.051642678678035736,
        0.038257941603660583,
        -0.053127020597457886,
        -0.041468337178230286,
        0.008649439550936222,
        -0.0473763532936573,
        0.052876949310302734,
        -0.04750952869653702,
        -0.0407131128013134,
        -0.05293821170926094,
        -0.05085667595267296,
        -0.03609997406601906,
        0.04808783158659935,
        -0.049089331179857254,
        0.034335654228925705,
        0.05312637984752655,
        0.03396929055452347,
        0.04619259014725685,
        0.04924113675951958,
        0.018108278512954712,
        -0.05304962769150734,
        0.052901096642017365,
        -0.05306311696767807,
        0.04352467134594917,
        0.052785925567150116,
        -0.04497760161757469,
        -0.053068943321704865,
        -0.05053798481822014,
        -0.052972834557294846,
        -0.05301762372255325,
        -0.03205716237425804,
        -0.05313260480761528,
        -0.05212702229619026,
        -0.051676396280527115,
        -0.016513658687472343,
        0.05278177931904793,
        0.05259717255830765,
        -0.048926230520009995,
        -0.05310318246483803,
        -0.0531393438577652,
        -0.03581180423498154,
        0.05313771963119507,
        0.0051691532135009766,
        0.05217566713690758,
        -0.051777321845293045,
        0.03424091264605522,
        0.04477681964635849,
        0.02902466431260109,
        0.05311937630176544,
        -0.03937328979372978,
        -0.05273940786719322,
        -0.049737948924303055,
        0.013469199649989605,
        0.005841427482664585,
        0.04006458818912506,
        -0.03121756576001644,
        -0.05162132903933525,
        -0.03623851016163826,
        0.05309879779815674,
        0.04887216538190842,
        0.05304635316133499,
        -0.05286705493927002,
        -0.050741538405418396,
        -0.05312913656234741,
        0.03327997028827667,
        0.02115960605442524,
        0.039628367871046066,
        -0.0349256657063961,
        -0.05305381491780281,
        -0.05279866233468056,
        0.04948658496141434,
        0.04812638461589813,
        0.05143091082572937,
        -0.05011112242937088,
        -0.05123281851410866,
        -0.03356894478201866,
        -0.05312900245189667,
        0.011449363082647324,
        -0.03474370390176773,
        -0.05272301658987999,
        -0.05268752947449684,
        0.05312312766909599,
        0.05205344781279564,
        0.05298345908522606,
        -0.03815672919154167,
        0.03928558528423309,
        0.052123021334409714,
        0.03228583559393883,
        -0.012177404016256332,
        0.05114826187491417,
        0.043791208416223526,
        0.0529460534453392,
        0.04636538028717041,
        0.04245864227414131,
        0.05218929424881935,
        -0.03913386911153793,
        -0.043182097375392914,
        -0.02248379774391651,
        0.028607971966266632,
        0.0528285875916481,
        0.05310220643877983,
        -0.05166121944785118,
        0.006113638170063496,
        0.04137243703007698,
        0.053124185651540756,
        0.05311862751841545,
        -0.05313871428370476,
        0.05254039168357849,
        -0.028511563315987587,
        -0.05221352353692055,
        0.053131792694330215,
        0.009781925939023495,
        0.05162710323929787,
        0.05183162912726402,
        0.0025539807975292206,
        -0.04440145194530487,
        0.04415106773376465,
        0.05295518413186073,
        -0.0511351116001606,
        -0.05000710114836693,
        -0.025046074762940407,
        -0.03131479024887085,
        0.019291337579488754,
        0.03101915493607521,
        -0.042870063334703445,
        0.052863359451293945,
        0.05117347836494446,
        0.011640538461506367,
        -0.05297813564538956,
        0.039157114923000336,
        -0.03533368185162544,
        -0.03494539111852646,
        0.04677430912852287,
        0.04649513587355614,
        0.0011642153840512037,
        -0.04703554883599281,
        -0.05305209010839462,
        0.033936407417058945,
        0.0528605692088604,
        0.007031421642750502,
        -0.05023178458213806,
        -0.01632825657725334,
        -0.04934779182076454,
        -0.0234318058937788,
        0.02103376016020775,
        0.05304911732673645,
        0.053093332797288895,
        -0.025833794847130775,
        -0.0003433373640291393,
        -0.05117793753743172,
        0.05313706398010254,
        -0.03508256375789642
    ],
    [
        -0.05305217579007149,
        -0.054373860359191895,
        -0.05186436325311661,
        0.020695151761174202,
        -0.04147486016154289,
        -0.05302274599671364,
        0.054108764976263046,
        -0.053218550980091095,
        0.02859288454055786,
        0.0011320874327793717,
        0.05431867763400078,
        -0.051289089024066925,
        -0.05408627167344093,
        0.029459262266755104,
        -0.052896127104759216,
        -0.05351676791906357,
        -0.046049814671278,
        0.054256413131952286,
        -0.054373759776353836,
        0.05263805389404297,
        -0.053460799157619476,
        -0.05400468036532402,
        0.05284876376390457,
        -0.04068433493375778,
        -0.05428752303123474,
        0.05164031684398651,
        0.029514765366911888,
        0.05360657349228859,
        -0.04743672534823418,
        -0.024730678647756577,
        0.05394287779927254,
        0.0466570220887661,
        0.05139416828751564,
        -0.05437258258461952,
        0.05329360067844391,
        -0.021050045266747475,
        -0.052085064351558685,
        -0.045634925365448,
        -0.05435434356331825,
        -0.04411568120121956,
        0.047382719814777374,
        0.040945325046777725,
        0.03845406323671341,
        -0.05437374487519264,
        0.03923666477203369,
        -0.00909801758825779,
        0.04850613325834274,
        0.054005060344934464,
        0.054369498044252396,
        -0.054258983582258224,
        0.04106447473168373,
        0.052301522344350815,
        0.005894356872886419,
        0.032127745449543,
        -0.027194447815418243,
        0.054206445813179016,
        -0.05311373248696327,
        0.01843985542654991,
        0.006456025410443544,
        -0.03637205809354782,
        -0.05331394821405411,
        0.04928120970726013,
        -0.05270257964730263,
        -0.048128847032785416,
        -0.009562628343701363,
        0.046855732798576355,
        -0.05433227866888046,
        -0.04666806012392044,
        0.05435898154973984,
        -0.054373838007450104,
        -0.03578388690948486,
        -0.054205477237701416,
        0.05434294790029526,
        0.05389656871557236,
        0.0541154108941555,
        0.04556049033999443,
        -0.05092424526810646,
        0.054373838007450104,
        -0.022702684625983238,
        -0.04968562349677086,
        -0.04940023645758629,
        -0.054303061217069626,
        -0.05311308056116104,
        0.05338073521852493,
        0.053859956562519073,
        0.022436849772930145,
        -0.05234190821647644,
        0.054291609674692154,
        0.03964171186089516,
        0.04354523867368698,
        -0.05171685665845871,
        0.024787260219454765,
        0.04890071228146553,
        -0.05431754142045975,
        -0.029645079746842384,
        -0.04596426337957382,
        0.03706548362970352,
        -0.04713505133986473,
        -0.025931334123015404,
        0.031901828944683075,
        -0.05427907034754753,
        0.05415850877761841,
        0.049489282071590424,
        0.05199815705418587,
        0.03410862758755684,
        -0.05357073247432709,
        -0.03422219306230545,
        0.03729097545146942,
        -0.05389309301972389,
        0.0540190227329731,
        -0.03799520060420036,
        -0.053679049015045166,
        0.03678690642118454,
        0.037214893847703934,
        0.05210317671298981,
        -0.05394580960273743,
        -0.0543719083070755,
        -0.030844300985336304,
        -0.01715611107647419,
        -0.02981766313314438,
        -0.007288704626262188,
        -0.0307521540671587,
        0.052599359303712845,
        -0.05434379354119301,
        -0.04992750287055969,
        0.038627032190561295,
        0.03310328349471092,
        0.0005529887857846916,
        0.04730653017759323,
        0.03555377572774887,
        0.05433271825313568,
        -0.047313231974840164,
        -0.024778930470347404,
        0.05016491562128067,
        -0.038226619362831116,
        -0.05254942923784256,
        0.04992220550775528,
        -0.054041750729084015,
        -0.05399579927325249,
        0.05429974943399429,
        -0.015750214457511902,
        -0.011586065404117107,
        0.027135636657476425,
        0.05389070883393288,
        -0.053785208612680435,
        0.052814457565546036,
        -0.053930677473545074,
        -0.00974911730736494,
        -0.05253373086452484,
        0.04675330966711044,
        -0.05431635305285454,
        -0.05331116542220116,
        0.034360360354185104,
        -0.01877741515636444,
        0.05420706793665886,
        -0.05430852621793747,
        -0.03079552762210369,
        -0.052942052483558655,
        0.05140939727425575,
        0.051388755440711975,
        0.050485771149396896,
        0.033048518002033234,
        0.05309990420937538,
        -0.05053018778562546,
        0.017366923391819,
        0.047338902950286865,
        -0.007543263025581837,
        -0.054198309779167175,
        0.03556589409708977,
        0.015271509997546673,
        0.0503079891204834,
        0.03509022295475006,
        -0.05425608530640602,
        0.053799934685230255,
        -0.0542474240064621,
        0.04816164821386337,
        -0.05437343940138817,
        -0.04857391119003296,
        0.04835468530654907,
        -0.052183847874403,
        -0.05420738086104393,
        0.0532618872821331,
        0.05230167880654335,
        -0.05008978396654129,
        -0.05067827180027962,
        0.04113256558775902,
        0.05111299827694893,
        0.05388779565691948,
        0.054364994168281555,
        -0.01210279855877161,
        -0.05268515646457672,
        -0.03691405430436134,
        0.05375609174370766,
        0.051161959767341614,
        -0.04026287794113159,
        -0.05319710820913315,
        0.054021693766117096,
        0.051148995757102966,
        -0.053391557186841965,
        0.05432844161987305,
        0.053927429020404816,
        0.0376804918050766,
        0.004496874287724495,
        -0.0452672615647316,
        0.049702972173690796,
        -0.05011632665991783,
        -0.028978770598769188,
        -0.00201448705047369,
        -0.03602549433708191,
        0.05388711020350456,
        -0.05175507068634033,
        0.034776125103235245,
        0.0202290341258049,
        0.054297011345624924,
        -0.016456449404358864,
        -0.05215290188789368,
        0.05034349486231804,
        -0.046975526958703995,
        0.05434475094079971,
        0.05374925956130028,
        0.054328449070453644,
        -0.05166810750961304,
        0.020031634718179703,
        -0.05412942171096802,
        0.0538049079477787,
        -0.054187700152397156,
        0.053360361605882645,
        0.03233267366886139,
        0.01973547600209713,
        -0.04926510900259018,
        0.022384297102689743,
        -0.04616224765777588,
        0.033531952649354935,
        0.051311299204826355,
        -0.046577658504247665,
        0.05436247959733009,
        0.04933597147464752,
        0.04318414255976677,
        -0.05318693444132805,
        0.05437382683157921,
        0.014190423302352428,
        0.04745786637067795,
        0.01941678673028946,
        -0.054210491478443146,
        0.010604350827634335,
        -0.05098588019609451,
        -0.029401294887065887,
        0.04829489067196846,
        -0.02925410307943821,
        -0.024846946820616722,
        -0.05279220640659332,
        -0.04374038428068161,
        -0.047093458473682404,
        -0.04928191751241684,
        0.05330665037035942,
        0.054357338696718216,
        0.014361105859279633,
        -0.02503870613873005,
        -0.03338247537612915,
        0.05417162925004959,
        -0.05427513271570206,
        -0.05061471089720726,
        0.030747689306735992,
        0.05435117706656456,
        -0.027852937579154968,
        0.05435864254832268,
        -0.05314190313220024,
        0.047473933547735214,
        0.05029979720711708,
        0.017215611413121223,
        0.006423152517527342,
        -0.05433695390820503,
        0.053424056619405746,
        -0.05431108549237251,
        -0.05430275574326515,
        0.01625688001513481,
        -0.04688229039311409,
        0.021976644173264503,
        0.050671786069869995,
        0.047956328839063644,
        -0.05409109592437744,
        0.05130669102072716,
        -0.04638916626572609,
        -0.01832409016788006,
        0.05097658559679985,
        0.02015555649995804,
        0.053490038961172104,
        -0.05433402583003044,
        -0.010869204998016357,
        0.03624853864312172,
        -0.05429493263363838,
        0.008184020407497883,
        0.021729806438088417,
        0.040685709565877914,
        0.054031599313020706,
        -0.053700365126132965,
        0.05407442897558212,
        -0.021507052704691887,
        -0.04415300861001015,
        0.05078975111246109,
        -0.0003103190683759749,
        -0.031281646341085434,
        0.01709729991853237,
        -0.011914531700313091,
        -0.04766624793410301,
        -0.0366722010076046,
        0.01349787600338459,
        0.0534646213054657,
        0.05416647717356682,
        0.051962751895189285,
        0.01760481670498848,
        0.029211800545454025,
        0.019332606345415115,
        0.0473356693983078,
        -0.04563726484775543,
        -0.051884401589632034,
        -0.04552885517477989,
        -0.04491933062672615,
        0.053740061819553375,
        -0.0377221517264843,
        0.025506051257252693,
        -0.053042855113744736,
        -0.01886640675365925,
        -0.045596640557050705,
        0.046717699617147446,
        0.05309523269534111,
        0.04958746209740639,
        0.044703833758831024,
        0.026719866320490837,
        -0.05421256273984909,
        -0.015239042229950428,
        -0.05188478156924248,
        -0.044274091720581055,
        -0.050109658390283585,
        0.04502761363983154,
        -0.03911681845784187,
        0.040014222264289856,
        -0.002863905392587185,
        -0.05188319832086563,
        0.042074427008628845,
        -0.05068426951766014,
        0.02349831722676754,
        -0.04137911647558212,
        0.0076080672442913055,
        0.0543738529086113,
        0.05222182348370552,
        0.05394331365823746,
        0.05407154560089111,
        -0.048012103885412216,
        0.034672364592552185,
        -0.05437386408448219,
        0.053636129945516586,
        0.035663094371557236,
        -0.05317165330052376,
        -0.006132808048278093,
        -0.040381595492362976,
        -0.054373178631067276,
        -0.04775862395763397,
        -0.047318242490291595,
        -0.01754724606871605,
        0.04093795269727707,
        0.047335051000118256,
        0.022963708266615868,
        -0.053743407130241394,
        -0.052580125629901886,
        -0.049679286777973175,
        0.054194770753383636,
        -0.0538703091442585,
        0.007584378123283386,
        -0.05341314896941185,
        -0.05027461796998978,
        -0.012602175585925579,
        -0.03190572187304497,
        -0.027984682470560074,
        0.05404612421989441,
        0.036716312170028687,
        -0.05112205445766449,
        -0.01565750315785408,
        0.02852451056241989,
        -0.03402121737599373,
        0.009003814309835434,
        0.05119933560490608,
        -0.049695856869220734,
        -0.048132095485925674,
        -0.002427174709737301,
        -0.05387376993894577,
        -0.038431551307439804,
        -0.02114868350327015,
        -0.029701929539442062,
        -0.0314367301762104,
        -0.04432567581534386,
        0.03292800113558769,
        -0.053373441100120544,
        0.03331250697374344,
        -0.034121040254831314,
        0.05366937071084976,
        0.05421924218535423,
        0.0028017638251185417,
        -0.047349683940410614,
        -0.053838156163692474,
        -0.048803362995386124,
        0.05412883311510086,
        -0.010937836952507496,
        0.054359059780836105,
        -0.05437295511364937,
        0.04601014032959938,
        0.034239981323480606,
        -0.04807552695274353,
        0.044165488332509995,
        -0.04671289026737213,
        0.052379485219717026,
        0.052182380110025406,
        0.028025170788168907,
        -0.03650100156664848,
        0.0444352962076664,
        -0.052929919213056564,
        -0.05198169872164726,
        -0.05081643909215927,
        0.0224524587392807,
        -0.05403702333569527,
        0.05285995081067085,
        -0.05367639288306236,
        0.009804217144846916,
        -0.054215844720602036,
        0.02489555813372135,
        0.001005347352474928,
        0.022164439782500267,
        0.04406151920557022,
        -0.0345996655523777,
        -0.054296888411045074,
        -0.009607955813407898,
        0.04864363372325897,
        -0.051050618290901184,
        -0.05209967494010925,
        -0.05259037762880325,
        -0.05428867042064667,
        -0.05419223755598068,
        0.051454465836286545,
        -0.04620618000626564,
        -0.05247518792748451,
        -0.03305996209383011,
        0.037901584059000015,
        -0.010970676317811012,
        0.022113002836704254,
        0.05436214804649353,
        0.029050113633275032,
        0.0509871244430542,
        -0.05437091737985611,
        0.0486551858484745,
        0.0456731803715229,
        0.051364120095968246,
        0.04746947064995766,
        0.05150577425956726,
        0.0012376238591969013,
        -0.054255545139312744,
        -0.018354304134845734,
        -0.00005134758248459548,
        -0.010396918281912804,
        -0.053099002689123154,
        0.05375776067376137,
        0.04477281495928764,
        -0.02522197924554348,
        0.0016336854314431548,
        0.0035816694144159555,
        0.054323021322488785,
        0.05435144901275635,
        -0.042882289737463,
        0.05433320999145508,
        0.054352954030036926,
        -0.054331645369529724,
        0.04770258814096451,
        0.04206661134958267,
        0.05348343774676323,
        -0.007055891212075949,
        0.045766089111566544,
        0.04262518882751465,
        0.05427132919430733,
        0.05423949658870697,
        -0.04036986082792282,
        -0.03147885575890541,
        -0.05436110496520996,
        0.04897265136241913,
        -0.052582379430532455,
        0.00716544222086668,
        -0.020265616476535797,
        0.028927750885486603,
        -0.018284445628523827,
        0.052533842623233795,
        -0.053513504564762115,
        0.05435938388109207,
        0.010459347628057003,
        -0.05434035137295723,
        0.05309167504310608,
        -0.05413128808140755,
        -0.04209812730550766,
        -0.04150799289345741,
        -0.05420259013772011,
        0.05434487387537956,
        0.029178088530898094,
        0.03471662849187851,
        0.05437246710062027,
        -0.02379506453871727,
        -0.018700316548347473,
        -0.011885153129696846,
        -0.03283325955271721,
        0.054272107779979706,
        0.018001766875386238,
        0.013369744643568993,
        0.05046868696808815,
        -0.04733899235725403,
        -0.012423696927726269,
        -0.05201590433716774
    ],
    [
        -0.031016234308481216,
        0.031863708049058914,
        0.05008663237094879,
        0.0072141382843256,
        -0.04360784962773323,
        -0.0512985996901989,
        0.05301650986075401,
        -0.055568184703588486,
        0.05500524491071701,
        -0.04140755534172058,
        0.05017539858818054,
        0.0001357964356429875,
        -0.04422840476036072,
        -0.05369056761264801,
        -0.055204134434461594,
        0.05541118234395981,
        0.042405568063259125,
        0.05546397343277931,
        0.05317659676074982,
        0.021645700559020042,
        -0.04172579199075699,
        0.041713863611221313,
        0.05496086925268173,
        -0.05097585543990135,
        0.04974986985325813,
        0.044730886816978455,
        0.05547500029206276,
        0.055403340607881546,
        -0.05487858131527901,
        0.024249354377388954,
        -0.05310909450054169,
        -0.04796767979860306,
        0.017167504876852036,
        -0.05544912815093994,
        0.05504089966416359,
        -0.0511283352971077,
        -0.055240631103515625,
        -0.05087076872587204,
        -0.024798214435577393,
        -0.05529320240020752,
        0.05260816216468811,
        0.034935738891363144,
        0.0542217381298542,
        0.049354881048202515,
        0.05520734563469887,
        0.04062846302986145,
        0.025590065866708755,
        0.05557390674948692,
        0.052537430077791214,
        -0.05449092015624046,
        0.05557801201939583,
        0.053876958787441254,
        -0.054336000233888626,
        -0.008067651651799679,
        -0.038348667323589325,
        0.052280254662036896,
        -0.05550701916217804,
        0.0018685593968257308,
        -0.0431613065302372,
        -0.02757011540234089,
        -0.055015288293361664,
        -0.00613725371658802,
        -0.052570417523384094,
        0.047774676233530045,
        0.05524656921625137,
        -0.046477463096380234,
        0.04622208699584007,
        -0.055149972438812256,
        -0.04630817472934723,
        0.05249330773949623,
        -0.03877789154648781,
        -0.05198816582560539,
        0.05490289628505707,
        0.04455256834626198,
        0.05558406934142113,
        -0.042424824088811874,
        0.05193116143345833,
        0.055564314126968384,
        -0.007057827897369862,
        0.013213817961513996,
        -0.04715987667441368,
        -0.038888633251190186,
        -0.04916571453213692,
        0.037415843456983566,
        0.014971485361456871,
        0.05319341644644737,
        0.05143318325281143,
        -0.053356822580099106,
        0.006983886007219553,
        0.05166765674948692,
        -0.05540831759572029,
        -0.010679320432245731,
        -0.055059026926755905,
        0.008512691594660282,
        -0.032503094524145126,
        0.0539676770567894,
        -0.04107436165213585,
        -0.05541660636663437,
        -0.052834898233413696,
        -0.05399365723133087,
        -0.05553750693798065,
        -0.01993471197783947,
        0.05544836446642876,
        0.023805046454072,
        0.02148422971367836,
        -0.05518462881445885,
        -0.02611999772489071,
        0.030282992869615555,
        -0.0518147237598896,
        -0.013932431116700172,
        -0.040834952145814896,
        0.036328963935375214,
        -0.02292894385755062,
        -0.0468096025288105,
        0.03249974176287651,
        -0.010039611719548702,
        -0.055280495434999466,
        -0.05006271228194237,
        0.055527687072753906,
        -0.05537266284227371,
        0.02606428600847721,
        0.05341363698244095,
        0.05442468822002411,
        -0.0552464984357357,
        -0.05301489308476448,
        0.023119254037737846,
        -0.05497800186276436,
        -0.029754964634776115,
        0.05398016795516014,
        0.01353814359754324,
        0.04970025271177292,
        -0.05481784790754318,
        -0.010732455179095268,
        0.05542636662721634,
        -0.05453125759959221,
        -0.05238616093993187,
        0.055586397647857666,
        -0.03311121091246605,
        -0.05388975143432617,
        0.04697345942258835,
        -0.05282994732260704,
        -0.020123351365327835,
        0.046362005174160004,
        0.03715250641107559,
        -0.05371809005737305,
        0.051307857036590576,
        0.054842520505189896,
        -0.05477675423026085,
        -0.03147396817803383,
        -0.03880050778388977,
        0.03145947307348251,
        -0.054854534566402435,
        0.04509589821100235,
        0.0023016883060336113,
        0.034724161028862,
        -0.04833897948265076,
        -0.055580612272024155,
        -0.04791606217622757,
        0.035346414893865585,
        -0.02111385576426983,
        -0.030002538114786148,
        0.04612307995557785,
        0.0552968867123127,
        -0.03696473315358162,
        -0.05540648102760315,
        -0.055094484239816666,
        0.047289635986089706,
        0.01194148138165474,
        -0.05015923082828522,
        0.04126943275332451,
        -0.032486677169799805,
        0.03352665528655052,
        -0.007365020923316479,
        0.0368172749876976,
        0.05506490170955658,
        0.026574863120913506,
        0.05308665707707405,
        0.016320878639817238,
        0.0551568977534771,
        -0.05341268703341484,
        -0.04454468935728073,
        0.04768965020775795,
        0.039861392229795456,
        -0.048136815428733826,
        -0.03650728985667229,
        0.0426679365336895,
        0.037170667201280594,
        0.05502685159444809,
        -0.05542346090078354,
        -0.053133297711610794,
        -0.010600060224533081,
        -0.014892376028001308,
        -0.004108856897801161,
        0.025722244754433632,
        -0.044646963477134705,
        -0.01097587589174509,
        0.05450492724776268,
        0.02841227315366268,
        -0.05359736829996109,
        0.04812737554311752,
        0.0060284133069217205,
        0.04003459960222244,
        0.046271830797195435,
        -0.030231693759560585,
        -0.018537893891334534,
        -0.0496114157140255,
        0.05477423965930939,
        0.05282222107052803,
        -0.05493805557489395,
        0.05357887223362923,
        -0.04205220192670822,
        0.05532960221171379,
        0.05134747177362442,
        0.055401623249053955,
        0.04345818608999252,
        0.036569155752658844,
        0.04713214188814163,
        0.01051686704158783,
        0.05548499897122383,
        -0.052581559866666794,
        -0.03638744354248047,
        0.05542254447937012,
        0.04959024488925934,
        0.0503588505089283,
        0.05554947629570961,
        -0.03016398847103119,
        -0.025082901120185852,
        0.04876644164323807,
        0.052501365542411804,
        -0.05354125425219536,
        -0.02614760771393776,
        -0.04592139273881912,
        0.01513584516942501,
        0.05109471082687378,
        -0.04526112973690033,
        0.05540064349770546,
        0.05428750440478325,
        0.021144988015294075,
        -0.05491403490304947,
        0.05270817503333092,
        0.023176660761237144,
        -0.01612916961312294,
        -0.05081801116466522,
        0.053796615451574326,
        0.05378613993525505,
        0.021834079176187515,
        -0.05156896635890007,
        0.024434683844447136,
        -0.015140743926167488,
        0.0210358127951622,
        0.0542697049677372,
        -0.052304793149232864,
        -0.05428493022918701,
        -0.05422302708029747,
        -0.04072274640202522,
        0.030064666643738747,
        -0.049626048654317856,
        -0.007353334221988916,
        0.05329480394721031,
        -0.054012175649404526,
        0.028286729007959366,
        -0.048346713185310364,
        -0.03946417570114136,
        -0.02129398286342621,
        -0.05360710620880127,
        0.05421023443341255,
        0.040711041539907455,
        -0.04463072493672371,
        -0.05204174667596817,
        -0.05495874583721161,
        -0.008665266446769238,
        -0.05204308032989502,
        0.05174156650900841,
        -0.05540715903043747,
        -0.050984788686037064,
        0.054146356880664825,
        -0.04779348522424698,
        -0.029182083904743195,
        -0.024155152961611748,
        -0.025427279993891716,
        -0.05547131225466728,
        0.04639061912894249,
        0.02542475238442421,
        0.05280562490224838,
        -0.001404531067237258,
        0.053133267909288406,
        0.031826019287109375,
        0.05022825300693512,
        -0.03916512802243233,
        0.05421381816267967,
        -0.05512658506631851,
        -0.04028258100152016,
        -0.05427210032939911,
        -0.016004204750061035,
        0.05177268385887146,
        -0.054692476987838745,
        0.05515972897410393,
        -0.0012774502392858267,
        -0.0553131029009819,
        0.05519585311412811,
        -0.0358455665409565,
        0.05460448935627937,
        -0.013110233470797539,
        0.04497813060879707,
        -0.042588118463754654,
        -0.04100853577256203,
        0.05386439710855484,
        0.05143273249268532,
        0.0543208122253418,
        0.04291338473558426,
        -0.0402335450053215,
        0.037700455635786057,
        -0.050241585820913315,
        0.023058300837874413,
        -0.05258699506521225,
        -0.055391501635313034,
        0.029166975989937782,
        -0.028057754039764404,
        0.04540902376174927,
        0.04785275459289551,
        0.021486854180693626,
        -0.05347391590476036,
        -0.0452493354678154,
        -0.05317561328411102,
        0.02770618349313736,
        -0.020885244011878967,
        0.05423371121287346,
        -0.0008318257168866694,
        0.05516927316784859,
        0.03250391036272049,
        0.03375358507037163,
        -0.05463280528783798,
        -0.05546380206942558,
        0.008471377193927765,
        0.055221859365701675,
        -0.043282199651002884,
        0.014532038010656834,
        0.044743869453668594,
        -0.04824088141322136,
        0.05177658423781395,
        -0.016455911099910736,
        -0.0403861366212368,
        -0.03482735902070999,
        -0.05522299185395241,
        -0.028037095442414284,
        0.053193043917417526,
        0.05190477892756462,
        0.05451593175530434,
        0.05350213870406151,
        -0.012457516975700855,
        0.05257860943675041,
        -0.05517973378300667,
        0.0339435338973999,
        -0.0489017553627491,
        0.05184972286224365,
        0.04393867775797844,
        -0.05082283914089203,
        -0.04916324466466904,
        -0.05058569833636284,
        -0.029133252799510956,
        0.049107469618320465,
        -0.00005017649527871981,
        0.03363178297877312,
        -0.05417322367429733,
        -0.04838971048593521,
        -0.05440318211913109,
        0.0553484670817852,
        -0.052840832620859146,
        -0.0153401093557477,
        -0.05498364940285683,
        -0.03857262060046196,
        -0.03002183325588703,
        0.05375862121582031,
        0.0509258508682251,
        0.051521241664886475,
        -0.01058212574571371,
        0.034244801849126816,
        -0.032164838165044785,
        0.05509553104639053,
        0.01437542773783207,
        -0.05484818294644356,
        0.05267200246453285,
        -0.05396750569343567,
        -0.04349585622549057,
        -0.015760544687509537,
        -0.054258525371551514,
        0.04108373448252678,
        -0.05133933201432228,
        -0.05460293963551521,
        0.027509674429893494,
        -0.05558686703443527,
        0.048934243619441986,
        -0.05531584843993187,
        -0.05452714115381241,
        0.03648966923356056,
        0.05472702533006668,
        0.05144652724266052,
        -0.03762570396065712,
        -0.0011877535143867135,
        -0.05303572118282318,
        -0.05263342708349228,
        0.055527329444885254,
        0.04321037605404854,
        -0.03848611190915108,
        -0.004992591682821512,
        -0.017349623143672943,
        -0.0032646607141941786,
        -0.04971998557448387,
        0.05510617420077324,
        -0.020466016605496407,
        -0.05478069558739662,
        0.01269825641065836,
        0.02621866948902607,
        -0.032430507242679596,
        0.05353387072682381,
        -0.033162277191877365,
        0.015272014774382114,
        -0.02473622001707554,
        0.019102642312645912,
        -0.0424051433801651,
        0.03361784666776657,
        -0.055377837270498276,
        0.03312016651034355,
        -0.05555688589811325,
        0.04860418289899826,
        0.05529971793293953,
        -0.05407894775271416,
        -0.027017800137400627,
        -0.05531011521816254,
        -0.054080527275800705,
        0.05486171320080757,
        0.04062468186020851,
        0.009025461040437222,
        0.047926973551511765,
        0.02280791848897934,
        -0.053979117423295975,
        -0.055369917303323746,
        -0.011693881824612617,
        -0.04878438636660576,
        -0.034505002200603485,
        -0.04805183410644531,
        0.04997371882200241,
        0.04319779574871063,
        0.052880141884088516,
        0.05499618500471115,
        0.023532232269644737,
        0.054610058665275574,
        -0.02922952175140381,
        0.04160779342055321,
        0.03617226704955101,
        -0.01255948469042778,
        0.017104094848036766,
        0.055157620459795,
        0.05440189316868782,
        0.0011327761458232999,
        -0.05202551558613777,
        -0.040037594735622406,
        -0.05127632990479469,
        0.05479023605585098,
        0.04447578266263008,
        -0.044458623975515366,
        -0.052524395287036896,
        0.05160669609904289,
        -0.04681062325835228,
        0.050364356487989426,
        0.05506255477666855,
        -0.029659703373908997,
        0.05442866310477257,
        -0.054569683969020844,
        -0.04762501269578934,
        0.00010732367081800476,
        -0.008731434121727943,
        0.05524037778377533,
        0.018617821857333183,
        -0.0540006086230278,
        0.026981376111507416,
        -0.03200489282608032,
        -0.022751864045858383,
        -0.02620484121143818,
        -0.03809918835759163,
        0.054462235420942307,
        -0.054404448717832565,
        -0.0085203992202878,
        0.04129837453365326,
        -0.055350396782159805,
        0.055116359144449234,
        0.015937112271785736,
        0.048678163439035416,
        -0.05383175611495972,
        -0.055175717920064926,
        -0.0011355640599504113,
        -0.04956507310271263,
        0.055468812584877014,
        -0.05500920116901398,
        0.022913172841072083,
        0.03757631406188011,
        -0.03213637322187424,
        0.05469408258795738,
        0.05181272327899933,
        0.055111564695835114,
        0.04396946728229523,
        0.03086046688258648,
        -0.049500465393066406,
        -0.033104684203863144,
        -0.022434795275330544,
        0.03465566411614418,
        0.04954775422811508,
        -0.042308904230594635,
        0.045471128076314926,
        -0.029476677998900414,
        -0.05478419363498688,
        -0.05268885940313339
    ],
    [
        -0.050709307193756104,
        -0.054676603525877,
        0.022841371595859528,
        -0.04171251505613327,
        -0.010173661634325981,
        -0.05151485279202461,
        0.04933549463748932,
        -0.05324365571141243,
        0.054494261741638184,
        -0.04870874434709549,
        0.053359001874923706,
        -0.009455936029553413,
        -0.052304308861494064,
        0.011294358409941196,
        -0.05444388836622238,
        -0.054650984704494476,
        0.04583586007356644,
        0.054603368043899536,
        0.022582806646823883,
        0.017809709534049034,
        0.053766556084156036,
        0.013269457966089249,
        0.05431925132870674,
        -0.054457105696201324,
        -0.01853216625750065,
        0.04574734717607498,
        0.03837904706597328,
        0.048683855682611465,
        0.04809106886386871,
        0.00893469899892807,
        0.053095992654561996,
        0.05358332768082619,
        0.05115100368857384,
        -0.05466161668300629,
        0.05402738228440285,
        -0.011128261685371399,
        -0.04279956966638565,
        -0.05311701446771622,
        -0.05467765033245087,
        -0.04704108089208603,
        0.051192861050367355,
        0.016485154628753662,
        0.04891985282301903,
        0.05455944687128067,
        -0.03212996572256088,
        0.047915682196617126,
        -0.027143994346261024,
        0.054537732154130936,
        0.05461554601788521,
        -0.05391843989491463,
        0.05448819324374199,
        -0.010439865291118622,
        -0.05464237555861473,
        -0.00810149498283863,
        -0.05114714056253433,
        0.053742099553346634,
        -0.05052493140101433,
        0.05322297289967537,
        -0.04919463023543358,
        0.05062897503376007,
        -0.054665908217430115,
        -0.03793333098292351,
        -0.04955150559544563,
        -0.012982657179236412,
        -0.04863419011235237,
        -0.053239285945892334,
        -0.052085019648075104,
        -0.03542671725153923,
        -0.04373826086521149,
        -0.05370816960930824,
        -0.036873478442430496,
        -0.05426754057407379,
        0.021693410351872444,
        0.027232427150011063,
        0.05451595038175583,
        -0.029617730528116226,
        -0.04739656299352646,
        0.05467469245195389,
        -0.04311027377843857,
        0.014547493308782578,
        -0.05376259982585907,
        -0.05391663685441017,
        -0.05104131996631622,
        0.05331132933497429,
        -0.0207385066896677,
        0.05261349678039551,
        0.005752768833190203,
        0.047432612627744675,
        0.05371962860226631,
        -0.051516562700271606,
        -0.052724242210388184,
        0.04909418150782585,
        -0.054646603763103485,
        -0.046334631741046906,
        -0.05269395560026169,
        0.018167614936828613,
        -0.010549774393439293,
        -0.05454316735267639,
        -0.01652957685291767,
        0.043728891760110855,
        -0.05363655090332031,
        0.047266870737075806,
        0.05449365824460983,
        0.049874503165483475,
        -0.053322721272706985,
        -0.0013975013280287385,
        0.047177862375974655,
        0.054644402116537094,
        -0.05430138483643532,
        0.04247935116291046,
        0.039303138852119446,
        0.05456588789820671,
        0.02695736102759838,
        0.05392979085445404,
        0.04344660043716431,
        -0.05332077667117119,
        -0.05279667675495148,
        -0.04934601113200188,
        0.03639063611626625,
        0.053123801946640015,
        0.04957779124379158,
        0.04881996661424637,
        0.04888768494129181,
        -0.05452978238463402,
        -0.03371886536478996,
        0.0458865612745285,
        -0.01419708039611578,
        0.03053954988718033,
        0.032004039734601974,
        -0.01109470147639513,
        0.046920448541641235,
        -0.037602346390485764,
        -0.03039528802037239,
        -0.0021843856666237116,
        -0.05125166103243828,
        -0.04642443731427193,
        0.05459859222173691,
        0.03483181446790695,
        0.009653489105403423,
        0.05388573557138443,
        0.017109660431742668,
        -0.05299545079469681,
        0.054129280149936676,
        -0.05467676743865013,
        -0.04626986384391785,
        -0.0003151845303364098,
        -0.05349244549870491,
        -0.04814387485384941,
        -0.03988632932305336,
        0.0031040203757584095,
        -0.01780618168413639,
        -0.05439935624599457,
        0.050610270351171494,
        0.006003253161907196,
        0.05465643107891083,
        -0.05308431386947632,
        -0.054654695093631744,
        -0.04821949079632759,
        0.046549588441848755,
        0.05456262454390526,
        -0.029798557981848717,
        -0.01364225149154663,
        0.054065361618995667,
        0.041913483291864395,
        -0.04282113537192345,
        -0.05460992082953453,
        0.053621988743543625,
        0.035098519176244736,
        0.006436021998524666,
        0.05005712807178497,
        -0.05088075250387192,
        0.05256924778223038,
        -0.0006003443850204349,
        -0.02002791501581669,
        0.04464033246040344,
        0.00036898331018164754,
        -0.04863220080733299,
        -0.05140924081206322,
        0.05452372506260872,
        -0.053932804614305496,
        -0.054459355771541595,
        0.016328997910022736,
        -0.05002335458993912,
        0.05310835316777229,
        0.02923964336514473,
        0.025845954194664955,
        -0.03894480690360069,
        0.05271131545305252,
        0.05464434251189232,
        -0.05387107655405998,
        -0.04481778293848038,
        0.015365514904260635,
        0.012900312431156635,
        0.020084021613001823,
        0.0010424986248835921,
        -0.013079875148832798,
        0.02717132866382599,
        0.05421583354473114,
        0.0047076791524887085,
        0.0539848767220974,
        0.054613590240478516,
        -0.0537312887609005,
        -0.04208645969629288,
        0.05343829095363617,
        0.03594823181629181,
        -0.05184829980134964,
        0.05382195860147476,
        0.047985561192035675,
        -0.053784120827913284,
        -0.0246986486017704,
        -0.03206324204802513,
        0.054342493414878845,
        0.05406911298632622,
        0.05463461950421333,
        -0.024572128430008888,
        0.04384182021021843,
        0.0546562597155571,
        0.040875740349292755,
        0.0546778067946434,
        -0.012821447104215622,
        -0.05067005008459091,
        -0.0513002835214138,
        -0.037885408848524094,
        0.04930993169546127,
        0.05463940277695656,
        -0.03154880180954933,
        0.03597761318087578,
        -0.05450558289885521,
        0.02658023126423359,
        -0.0528377927839756,
        0.019717959687113762,
        -0.05445234477519989,
        0.047343190759420395,
        0.050594039261341095,
        -0.05317685753107071,
        0.05373387411236763,
        0.053754109889268875,
        0.048830851912498474,
        0.05215705186128616,
        0.05448190122842789,
        -0.05086168646812439,
        -0.026998719200491905,
        0.019145404919981956,
        -0.03301600366830826,
        0.05428748205304146,
        0.006898659281432629,
        -0.053169749677181244,
        -0.004291255958378315,
        0.038155123591423035,
        0.038179121911525726,
        0.01823854073882103,
        -0.04331476613879204,
        -0.054444320499897,
        -0.05059914290904999,
        -0.05398710444569588,
        -0.051155395805835724,
        0.05438988655805588,
        -0.04972231760621071,
        0.05010102316737175,
        0.003109778743237257,
        -0.03577663376927376,
        -0.053967930376529694,
        0.044723741710186005,
        0.04193168133497238,
        -0.054243315011262894,
        0.029651962220668793,
        0.013736726716160774,
        0.034111134707927704,
        0.04032175987958908,
        -0.05419450253248215,
        0.023397626355290413,
        -0.05467529222369194,
        0.05467492714524269,
        -0.04288288950920105,
        -0.05017435550689697,
        0.05197107046842575,
        -0.04204903915524483,
        0.052309684455394745,
        -0.0005437022773548961,
        0.041859641671180725,
        -0.03196801245212555,
        0.036324601620435715,
        -0.025843147188425064,
        0.038899026811122894,
        -0.05122533440589905,
        0.028410915285348892,
        0.04896038770675659,
        0.054523296654224396,
        0.034976862370967865,
        0.04674205183982849,
        -0.025542041286826134,
        -0.03736114129424095,
        -0.0544993057847023,
        -0.039013978093862534,
        0.0530865304172039,
        -0.04405030608177185,
        0.05008772015571594,
        0.011000999249517918,
        -0.044109296053647995,
        0.05272461473941803,
        0.05237206816673279,
        0.03605407848954201,
        0.04439238831400871,
        0.04245210066437721,
        -0.05385605990886688,
        -0.04285700246691704,
        0.0025791432708501816,
        0.04854435846209526,
        0.05191218480467796,
        0.04613690823316574,
        -0.03441844880580902,
        -0.02095765806734562,
        0.05321374908089638,
        0.0022366789635270834,
        0.05278599262237549,
        -0.05053383484482765,
        0.050624195486307144,
        -0.04519020393490791,
        0.04805754870176315,
        0.0023953092750161886,
        0.053292710334062576,
        -0.03549490496516228,
        0.05314692482352257,
        -0.04254048690199852,
        0.053714998066425323,
        -0.03369896858930588,
        -0.03643528372049332,
        -0.0431424044072628,
        0.05417093262076378,
        -0.048821087926626205,
        0.03332161903381348,
        0.049575209617614746,
        -0.043262992054224014,
        0.0035730840172618628,
        0.053207553923130035,
        0.0012871382059529424,
        -0.047049812972545624,
        -0.022045839577913284,
        0.01778830587863922,
        0.05455128103494644,
        -0.0023300275206565857,
        -0.054275043308734894,
        -0.011760079301893711,
        -0.053854018449783325,
        0.05430997908115387,
        0.0347679927945137,
        -0.0422651469707489,
        0.049182236194610596,
        0.05426957085728645,
        0.005620196461677551,
        0.049397751688957214,
        -0.05436765030026436,
        -0.04447268694639206,
        -0.05454154312610626,
        -0.05000048875808716,
        -0.0184869896620512,
        -0.037001390010118484,
        -0.053520042449235916,
        0.013194882310926914,
        -0.036835912615060806,
        0.051219724118709564,
        0.052998561412096024,
        -0.008573186583817005,
        -0.048778414726257324,
        -0.053510457277297974,
        -0.053200338035821915,
        0.054216381162405014,
        0.03863402083516121,
        0.05455130338668823,
        -0.05423063784837723,
        -0.03897929936647415,
        -0.040217481553554535,
        0.053557176142930984,
        0.043102215975522995,
        0.05310877040028572,
        0.033820390701293945,
        -0.03325151652097702,
        -0.018618835136294365,
        0.050221409648656845,
        -0.051010917872190475,
        -0.05462720990180969,
        0.03972066566348076,
        -0.05039028823375702,
        0.0263003408908844,
        -0.05424771457910538,
        -0.03745415806770325,
        0.03103826381266117,
        -0.05374034494161606,
        -0.05387631431221962,
        -0.04428824782371521,
        -0.054675471037626266,
        -0.0031415680423378944,
        -0.052708275616168976,
        -0.04438016191124916,
        0.052180662751197815,
        0.05174971744418144,
        0.05045461654663086,
        0.0023519997484982014,
        -0.053967997431755066,
        -0.050795067101716995,
        -0.05202891677618027,
        0.05438673868775368,
        -0.04040410742163658,
        0.054447658360004425,
        -0.0524054616689682,
        0.05338389053940773,
        0.04761558398604393,
        -0.05216854438185692,
        0.05463768541812897,
        -0.04861780256032944,
        -0.05295553803443909,
        -0.05438326671719551,
        0.04492096230387688,
        -0.005279507953673601,
        0.04174813628196716,
        0.018311023712158203,
        0.05359313264489174,
        0.051183510571718216,
        0.021070653572678566,
        -0.054643914103507996,
        0.05321802571415901,
        -0.04330061003565788,
        -0.050981227308511734,
        -0.05464542284607887,
        0.013457461260259151,
        -0.0010227430611848831,
        -0.05378986522555351,
        -0.05344405770301819,
        0.04528367146849632,
        -0.04951530322432518,
        -0.006413606461137533,
        0.02541567198932171,
        -0.05467068403959274,
        -0.05393924191594124,
        -0.027036502957344055,
        -0.0526336133480072,
        -0.042235568165779114,
        -0.05325854942202568,
        -0.016124166548252106,
        -0.04501761496067047,
        0.02345304936170578,
        0.046242374926805496,
        -0.01771528273820877,
        0.05002830550074577,
        0.05467331036925316,
        0.04828883707523346,
        0.05199342966079712,
        -0.05140504240989685,
        0.03529924526810646,
        0.05289039388298988,
        0.051608115434646606,
        0.05405481532216072,
        0.04466493800282478,
        0.014059081673622131,
        -0.05010683089494705,
        -0.054533492773771286,
        0.0033320002257823944,
        -0.03094204142689705,
        0.030711369588971138,
        0.02296818606555462,
        -0.05123288929462433,
        -0.05321227014064789,
        0.05404920130968094,
        -0.05455735698342323,
        0.0476871058344841,
        0.05456605926156044,
        -0.015614929609000683,
        0.05461740121245384,
        -0.05003201588988304,
        -0.048769760876894,
        0.05411331728100777,
        0.05423064902424812,
        0.051410749554634094,
        0.025666160508990288,
        -0.05464206263422966,
        0.05106913670897484,
        0.0414016954600811,
        0.037647150456905365,
        0.031179264187812805,
        -0.052832894027233124,
        -0.05353051424026489,
        -0.030370336025953293,
        0.024476943537592888,
        0.03979555517435074,
        -0.04642432928085327,
        0.04775874316692352,
        0.012304794043302536,
        0.051863640546798706,
        -0.052885036915540695,
        -0.012278597801923752,
        0.0410543717443943,
        -0.002784981392323971,
        0.04761700704693794,
        -0.05408070236444473,
        -0.054649919271469116,
        -0.052945856004953384,
        0.05156373977661133,
        0.0544450618326664,
        0.045254386961460114,
        0.046310264617204666,
        0.051988981664180756,
        0.012410327792167664,
        0.052747100591659546,
        -0.05414726957678795,
        -0.04780031368136406,
        -0.022003045305609703,
        -0.03851613402366638,
        -0.050916850566864014,
        -0.04335523769259453,
        0.015746792778372765,
        -0.049335695803165436,
        -0.026891756802797318
    ],
    [
        -0.054180752485990524,
        -0.05446089431643486,
        0.05034662410616875,
        0.026169294491410255,
        0.0023347584065049887,
        -0.035201989114284515,
        0.05415421351790428,
        0.0015167358797043562,
        0.032937321811914444,
        0.053042154759168625,
        0.054398659616708755,
        0.037519507110118866,
        -0.05267148092389107,
        0.052668146789073944,
        -0.043220460414886475,
        -0.05445211008191109,
        -0.040546685457229614,
        0.054457925260066986,
        -0.0544610433280468,
        0.04108501970767975,
        -0.035059694200754166,
        -0.05050218105316162,
        0.03007139079272747,
        -0.05377880856394768,
        -0.042879946529865265,
        0.0492633581161499,
        -0.01225957553833723,
        0.05444326251745224,
        -0.013008889742195606,
        -0.04869488626718521,
        0.05432745814323425,
        0.014620124362409115,
        0.05426258221268654,
        -0.05443733558058739,
        0.03937911242246628,
        -0.02830556407570839,
        -0.05004497244954109,
        -0.042338982224464417,
        -0.05446123331785202,
        -0.00033207214437425137,
        -0.02774176374077797,
        -0.04919861629605293,
        0.05019981414079666,
        -0.054405663162469864,
        0.014827528037130833,
        0.05077602341771126,
        0.038021646440029144,
        0.05442647263407707,
        0.0544455386698246,
        -0.05284501984715462,
        0.04024349898099899,
        -0.045452531427145004,
        0.05334220454096794,
        -0.0509800910949707,
        -0.021752653643488884,
        0.04467936232686043,
        -0.05432180315256119,
        0.02339288964867592,
        0.01725233905017376,
        0.020742174237966537,
        -0.05114808678627014,
        -0.03561566770076752,
        -0.04148068651556969,
        -0.017692865803837776,
        -0.05379696562886238,
        -0.024369852617383003,
        -0.053954966366291046,
        -0.05403377488255501,
        0.0460331030189991,
        -0.05446123331785202,
        -0.050462014973163605,
        -0.05440858006477356,
        -0.04643179103732109,
        -0.052865415811538696,
        -0.015403561294078827,
        -0.04013773798942566,
        -0.054432518780231476,
        0.05443490669131279,
        -0.0509694404900074,
        -0.0216195210814476,
        -0.0348840169608593,
        -0.04597074165940285,
        -0.03862963244318962,
        0.04221009090542793,
        0.01037546806037426,
        0.04029197618365288,
        -0.04803746938705444,
        0.05446088686585426,
        0.05443807691335678,
        0.053301580250263214,
        -0.030533865094184875,
        0.0544571578502655,
        0.039474967867136,
        -0.05443821847438812,
        -0.044115662574768066,
        0.02527729980647564,
        -0.0468725822865963,
        -0.045458003878593445,
        -0.047772116959095,
        -0.034456413239240646,
        -0.05392593517899513,
        0.00033437396632507443,
        0.0019588901195675135,
        0.0012653290759772062,
        0.013555741868913174,
        0.005818013101816177,
        -0.04952707886695862,
        0.051408980041742325,
        -0.046609483659267426,
        0.039098016917705536,
        0.04061047360301018,
        -0.054356422275304794,
        0.041054390370845795,
        0.053521718829870224,
        -0.021886058151721954,
        -0.0539686419069767,
        -0.05405145511031151,
        -0.042058032006025314,
        -0.054454561322927475,
        0.029840074479579926,
        0.025335006415843964,
        0.04161699488759041,
        0.02112516388297081,
        -0.049148157238960266,
        -0.05198020488023758,
        0.044886719435453415,
        -0.052606724202632904,
        0.04068341478705406,
        -0.0070975786074995995,
        0.05390530079603195,
        0.054072681814432144,
        -0.04729476198554039,
        -0.053365230560302734,
        0.04517233744263649,
        -0.03910163789987564,
        -0.05171680450439453,
        0.04563586041331291,
        -0.054434724152088165,
        -0.054268959909677505,
        0.054185934364795685,
        0.03219829872250557,
        -0.04694830998778343,
        0.017200861126184464,
        0.039278946816921234,
        -0.05312914773821831,
        0.05184178054332733,
        -0.054446808993816376,
        -0.054152652621269226,
        -0.051800359040498734,
        -0.02465078979730606,
        -0.03918003663420677,
        -0.05440036952495575,
        -0.034391287714242935,
        -0.01977374590933323,
        0.054461151361465454,
        -0.04993748292326927,
        0.04912745952606201,
        -0.007595931179821491,
        -0.05289842560887337,
        0.052568938583135605,
        0.05437730252742767,
        0.03430146723985672,
        0.04202213138341904,
        -0.05291963741183281,
        -0.03463839739561081,
        -0.0513923242688179,
        0.037894099950790405,
        -0.05440947413444519,
        0.05397013947367668,
        0.052207816392183304,
        0.05333579331636429,
        0.041371267288923264,
        -0.05421716719865799,
        0.05438293144106865,
        -0.054257169365882874,
        0.05391894653439522,
        -0.05446118488907814,
        -0.050777167081832886,
        0.048665449023246765,
        -0.044543009251356125,
        -0.04798707365989685,
        0.053882330656051636,
        0.02979816310107708,
        -0.04659094288945198,
        -0.050148047506809235,
        0.05390398949384689,
        0.010176320560276508,
        0.0470842607319355,
        0.054460737854242325,
        -0.04926123097538948,
        -0.05339646339416504,
        -0.027232972905039787,
        -0.054219361394643784,
        -0.014257109723985195,
        -0.0009569682879373431,
        -0.05247840657830238,
        0.04268711060285568,
        0.035305652767419815,
        -0.05346783623099327,
        0.04861856624484062,
        0.05312152951955795,
        0.047917962074279785,
        0.053573258221149445,
        0.05416341498494148,
        0.053693246096372604,
        -0.014133382588624954,
        -0.001429099473170936,
        -0.009983019903302193,
        -0.053807102143764496,
        0.05444829910993576,
        -0.05220082029700279,
        0.03646962344646454,
        0.03595570847392082,
        0.04277140647172928,
        0.011610624380409718,
        -0.024646036326885223,
        0.040220487862825394,
        -0.0036083145532757044,
        0.0544603131711483,
        -0.051173046231269836,
        -0.012812631204724312,
        -0.054456520825624466,
        -0.05373843386769295,
        -0.053827036172151566,
        0.0532340407371521,
        -0.04980937018990517,
        0.003531523747369647,
        0.05168686434626579,
        -0.04904107004404068,
        -0.04896704480051994,
        0.05437436327338219,
        -0.05434226989746094,
        0.05367833375930786,
        0.038581930100917816,
        -0.046762607991695404,
        0.05444956198334694,
        0.05378599837422371,
        0.05416136607527733,
        0.009012407623231411,
        0.05437711626291275,
        0.003308037295937538,
        -0.04666776955127716,
        0.05320249870419502,
        -0.054398734122514725,
        0.04949825629591942,
        -0.029169296845793724,
        0.03653237968683243,
        0.054340533912181854,
        -0.05430431291460991,
        -0.028835898265242577,
        -0.024803634732961655,
        -0.05051776394248009,
        -0.052817683666944504,
        -0.052653778344392776,
        -0.03580417111515999,
        0.05358252674341202,
        -0.02465668134391308,
        -0.0510176420211792,
        0.0531235933303833,
        0.0544552206993103,
        0.03842593729496002,
        -0.04488515481352806,
        0.03240986540913582,
        0.0320562981069088,
        -0.05439840257167816,
        0.05437760055065155,
        -0.05353511497378349,
        -0.04258758947253227,
        0.05098237469792366,
        -0.012556717731058598,
        -0.03792610764503479,
        -0.054348934441804886,
        0.05445367470383644,
        -0.053057555109262466,
        -0.05435657501220703,
        0.012490840628743172,
        -0.05266803503036499,
        -0.015802549198269844,
        -0.017749475315213203,
        0.05106073617935181,
        -0.052179966121912,
        0.052425313740968704,
        0.013223881833255291,
        0.04773299768567085,
        -0.02633851394057274,
        -0.044135596603155136,
        0.026516297832131386,
        -0.054419439285993576,
        -0.0533294640481472,
        0.05158425495028496,
        -0.054178595542907715,
        -0.038018517196178436,
        -0.04114564508199692,
        -0.02508133463561535,
        0.02540314942598343,
        -0.049662500619888306,
        0.05433555319905281,
        0.024947527796030045,
        0.007712473161518574,
        0.05359160900115967,
        0.04260352998971939,
        0.051973383873701096,
        -0.0366474874317646,
        0.002658132230862975,
        -0.05445850268006325,
        -0.03361393138766289,
        -0.04511018842458725,
        0.05367842689156532,
        0.05438792705535889,
        0.05277195945382118,
        0.05435185879468918,
        0.052813153713941574,
        0.04393969103693962,
        0.05286693572998047,
        -0.016400685533881187,
        -0.05371692031621933,
        -0.005132047925144434,
        0.012465974316000938,
        0.03337511792778969,
        -0.05383200943470001,
        0.04038047417998314,
        -0.029683830216526985,
        0.04535340145230293,
        -0.050560906529426575,
        0.03544529527425766,
        0.05055487900972366,
        0.0426349975168705,
        0.030971314758062363,
        0.05397147312760353,
        -0.047975752502679825,
        -0.053690824657678604,
        -0.04223344847559929,
        0.05035947263240814,
        0.05018330365419388,
        0.0537186898291111,
        0.005664499010890722,
        -0.05296923965215683,
        0.02589077316224575,
        -0.028535673394799232,
        0.04937279224395752,
        -0.011700243689119816,
        -0.048704516142606735,
        -0.04933327063918114,
        -0.05445311963558197,
        0.05446123331785202,
        0.05185575410723686,
        0.04160841926932335,
        0.04154329001903534,
        0.04476775601506233,
        0.052321285009384155,
        -0.05446123331785202,
        0.04074430093169212,
        0.005238169804215431,
        -0.052133917808532715,
        -0.05201450362801552,
        0.051402170211076736,
        -0.05446011945605278,
        -0.054196059703826904,
        -0.03808143734931946,
        -0.0526692159473896,
        0.04596962407231331,
        -0.011537091806530952,
        -0.019211990758776665,
        -0.05246764421463013,
        -0.03300967812538147,
        -0.05437001585960388,
        0.05443864315748215,
        -0.03979692608118057,
        0.05421548709273338,
        -0.053849346935749054,
        -0.0441468320786953,
        -0.0525209903717041,
        0.05440231412649155,
        -0.027751589193940163,
        0.054331593215465546,
        -0.03292042389512062,
        -0.05443525314331055,
        -0.037604887038469315,
        0.05061553418636322,
        -0.0542667917907238,
        -0.009235305711627007,
        0.03483736515045166,
        -0.05393967404961586,
        0.013344584964215755,
        -0.04409348592162132,
        -0.054331425577402115,
        0.04496237635612488,
        -0.018062759190797806,
        -0.029276316985487938,
        -0.05349941551685333,
        -0.054258886724710464,
        0.05405258759856224,
        -0.0042236498557031155,
        0.019867029041051865,
        -0.05248545482754707,
        0.04848277568817139,
        -0.0252228993922472,
        0.03599926084280014,
        -0.030491851270198822,
        -0.05299268662929535,
        -0.04929795861244202,
        0.05321088433265686,
        0.0471825934946537,
        0.054461121559143066,
        -0.05432628095149994,
        0.048801545053720474,
        0.04491608589887619,
        -0.017766257748007774,
        0.051333267241716385,
        -0.0512692965567112,
        0.05445675179362297,
        0.00416033249348402,
        0.03178512305021286,
        -0.01276168879121542,
        0.025519445538520813,
        -0.03153302147984505,
        0.010061114095151424,
        0.04769185185432434,
        -0.02445993199944496,
        -0.05445823073387146,
        0.05077371746301651,
        -0.05438840016722679,
        -0.024180172011256218,
        -0.05345616489648819,
        0.04938311129808426,
        -0.053619127720594406,
        0.02435830608010292,
        -0.03778281807899475,
        -0.05188536271452904,
        0.011460033245384693,
        0.027926642447710037,
        -0.033470217138528824,
        -0.054447613656520844,
        -0.049274515360593796,
        -0.009402262978255749,
        0.018109764903783798,
        -0.05444518104195595,
        -0.04065851867198944,
        -0.04159340262413025,
        -0.03614676371216774,
        0.03001004084944725,
        0.05037052929401398,
        -0.05346100777387619,
        0.049838874489068985,
        0.05446120724081993,
        -0.04327695444226265,
        0.05211525782942772,
        -0.05446101352572441,
        0.051804009824991226,
        -0.009265869855880737,
        0.04340660944581032,
        0.054415442049503326,
        0.054269082844257355,
        -0.05129534378647804,
        -0.05442679673433304,
        -0.04558540880680084,
        0.012380694039165974,
        0.012288103811442852,
        -0.054316554218530655,
        0.03407948464155197,
        0.02444378472864628,
        -0.0410056933760643,
        -0.04866589233279228,
        -0.05295043811202049,
        0.047107141464948654,
        0.05406549945473671,
        -0.05416914075613022,
        0.05432236194610596,
        -0.042172741144895554,
        -0.05336949601769447,
        -0.013977671973407269,
        0.05439429357647896,
        0.006798715330660343,
        0.04087077081203461,
        0.0524703711271286,
        0.02920348197221756,
        -0.008199695497751236,
        0.04613165929913521,
        -0.052690573036670685,
        -0.0536140576004982,
        -0.05445742607116699,
        -0.04230325296521187,
        0.053754303604364395,
        0.040059007704257965,
        0.04493354633450508,
        0.038059476763010025,
        0.04984036460518837,
        -0.019372548907995224,
        -0.05228898674249649,
        0.05445273965597153,
        0.021582966670393944,
        -0.054461196064949036,
        0.02211231179535389,
        -0.05430791899561882,
        -0.00969559233635664,
        -0.04565243050456047,
        0.04069166257977486,
        0.054442305117845535,
        0.0508609339594841,
        -0.03697800263762474,
        0.05444320663809776,
        -0.047608792781829834,
        -0.04187602922320366,
        -0.054213155061006546,
        -0.04958129674196243,
        0.022742578759789467,
        -0.05131514370441437,
        0.01931227371096611,
        0.02365162968635559,
        0.029345102608203888,
        -0.00001047894147632178,
        -0.05361558496952057
    ],
    [
        -0.0026338002644479275,
        -0.05176170915365219,
        0.049217674881219864,
        0.037609394639730453,
        0.018152697011828423,
        0.027127016335725784,
        0.05199988931417465,
        -0.05167962610721588,
        0.05195195600390434,
        0.019504886120557785,
        0.05198070779442787,
        0.023836985230445862,
        -0.05188855156302452,
        0.05095507204532623,
        -0.05161852389574051,
        -0.052007053047418594,
        0.035410325974226,
        0.051994018256664276,
        -0.051231298595666885,
        0.02031395025551319,
        0.051380544900894165,
        -0.04999539256095886,
        0.051724039018154144,
        -0.04502979293465614,
        -0.05167923495173454,
        0.0509805791079998,
        -0.03586713597178459,
        0.018496602773666382,
        -0.026842419058084488,
        -0.05200311541557312,
        -0.026215163990855217,
        0.05153363198041916,
        -0.038194794207811356,
        -0.05200508236885071,
        0.051895804703235626,
        -0.051987383514642715,
        -0.05190825089812279,
        -0.04711258038878441,
        -0.0520058311522007,
        -0.04720291122794151,
        0.014388050884008408,
        -0.029295004904270172,
        0.04806751757860184,
        0.041467539966106415,
        0.0022200848907232285,
        0.050363436341285706,
        0.03504945710301399,
        0.05194765329360962,
        0.05200127512216568,
        0.04717278108000755,
        0.05175023525953293,
        0.039356429129838943,
        -0.05116068944334984,
        -0.05191059038043022,
        0.0019430721877142787,
        -0.0513077974319458,
        -0.051541220396757126,
        0.034053195267915726,
        -0.05173463374376297,
        -0.051821161061525345,
        -0.051985856145620346,
        -0.019755378365516663,
        -0.05182632431387901,
        -0.023730214685201645,
        -0.003788763890042901,
        -0.05103325471282005,
        -0.05193007364869118,
        0.01750532165169716,
        0.0499902106821537,
        -0.03306621313095093,
        -0.05094616860151291,
        -0.05075360834598541,
        0.05056298151612282,
        0.04982089623808861,
        0.05195225775241852,
        -0.05047101899981499,
        0.03529645875096321,
        0.05200714245438576,
        0.05183583125472069,
        0.047149188816547394,
        -0.020185139030218124,
        -0.05200604721903801,
        -0.05144313722848892,
        0.050769515335559845,
        0.044703952968120575,
        0.03024614229798317,
        -0.04140352085232735,
        0.03176124766469002,
        0.049528032541275024,
        0.03440123051404953,
        -0.029801230877637863,
        -0.051323600113391876,
        -0.051969729363918304,
        -0.0519089438021183,
        -0.04382534325122833,
        0.05088181048631668,
        0.03617614880204201,
        -0.018845858052372932,
        -0.028233801946043968,
        0.05049606412649155,
        -0.051988136023283005,
        0.05129900202155113,
        -0.03599220886826515,
        0.02370193414390087,
        -0.04357586055994034,
        -0.05059613659977913,
        0.027299795299768448,
        0.051774196326732635,
        -0.05200094357132912,
        0.028429247438907623,
        -0.048069778829813004,
        -0.025019971653819084,
        0.03865058720111847,
        0.010716595686972141,
        -0.0079361442476511,
        -0.042966604232788086,
        -0.04053504765033722,
        -0.048912934958934784,
        0.051041070371866226,
        -0.051826536655426025,
        -0.045800723135471344,
        0.0399671234190464,
        0.013723745942115784,
        -0.05196410417556763,
        -0.051963623613119125,
        0.043775591999292374,
        -0.051186684519052505,
        -0.050658438354730606,
        0.05172107741236687,
        -0.017606206238269806,
        0.0519707128405571,
        -0.048128895461559296,
        -0.050581108778715134,
        0.03043997846543789,
        -0.03139819577336311,
        -0.049927953630685806,
        0.0520053431391716,
        -0.02084009349346161,
        -0.013455746695399284,
        0.05195070058107376,
        0.05200705677270889,
        -0.05000026524066925,
        0.05059254914522171,
        -0.05169179290533066,
        0.03583729267120361,
        0.030879173427820206,
        -0.049916740506887436,
        0.051336806267499924,
        -0.05004296451807022,
        -0.040001630783081055,
        0.05173895135521889,
        -0.05192720144987106,
        -0.04996902495622635,
        0.04855559766292572,
        0.05200711265206337,
        -0.05198221653699875,
        -0.05073530226945877,
        -0.016703756526112556,
        0.04324473813176155,
        0.05085660517215729,
        0.025288106873631477,
        0.0133240120485425,
        0.05159325525164604,
        0.04061499983072281,
        0.051291052252054214,
        -0.04868911951780319,
        -0.03980705514550209,
        -0.04857369512319565,
        0.051481518894433975,
        0.05098020285367966,
        0.01953679695725441,
        0.051463354378938675,
        -0.05200495943427086,
        -0.03721706196665764,
        0.01093197986483574,
        0.024654585868120193,
        -0.05124756693840027,
        -0.05132724717259407,
        0.0519922636449337,
        -0.051940564066171646,
        -0.05180225521326065,
        0.05200650542974472,
        -0.02149440534412861,
        0.04911908134818077,
        0.016071835532784462,
        0.02436269074678421,
        0.05151156708598137,
        0.051080599427223206,
        0.052007149904966354,
        -0.05142218992114067,
        -0.051768433302640915,
        -0.047729942947626114,
        -0.0508924201130867,
        -0.04641754552721977,
        0.001966408221051097,
        -0.051040127873420715,
        0.02402719110250473,
        0.0424664206802845,
        -0.0499124601483345,
        0.040134675800800323,
        0.05198223888874054,
        0.050364505499601364,
        -0.045977260917425156,
        0.051903873682022095,
        0.05028620362281799,
        -0.051061127334833145,
        0.04088256508111954,
        0.0466865636408329,
        -0.04944300279021263,
        0.05169081687927246,
        -0.05170147866010666,
        0.03496731445193291,
        -0.051654472947120667,
        0.05157642439007759,
        -0.05192597582936287,
        -0.05101272836327553,
        0.0520063117146492,
        0.008734401315450668,
        0.052002135664224625,
        -0.04373830184340477,
        0.02392125502228737,
        0.008962342515587807,
        0.05149376019835472,
        -0.049759794026613235,
        0.051999252289533615,
        -0.049699604511260986,
        0.05179774388670921,
        -0.046315163373947144,
        0.015635836869478226,
        -0.04944697767496109,
        -0.021567590534687042,
        -0.04450133070349693,
        0.04761597514152527,
        0.034640368074178696,
        -0.00325135700404644,
        0.05151906982064247,
        0.05169006809592247,
        0.05143164470791817,
        0.04214209318161011,
        0.05199211463332176,
        -0.05098974332213402,
        -0.040659673511981964,
        -0.0010744285536929965,
        -0.05197606235742569,
        0.044185981154441833,
        0.03957739472389221,
        -0.013650179840624332,
        0.05112181603908539,
        -0.04552993178367615,
        0.009666663594543934,
        -0.0155059564858675,
        -0.01959959603846073,
        -0.05197915807366371,
        -0.05115222558379173,
        0.011796080507338047,
        -0.05015147849917412,
        -0.04700683802366257,
        -0.04922936111688614,
        0.016410017386078835,
        0.05200676992535591,
        -0.05018937587738037,
        -0.05191469565033913,
        -0.020918305963277817,
        0.051785703748464584,
        -0.05200284719467163,
        0.05200467258691788,
        -0.04704746976494789,
        0.05193628743290901,
        -0.038378600031137466,
        -0.051643006503582,
        -0.006313988007605076,
        -0.04796716570854187,
        0.05200715363025665,
        -0.05158185213804245,
        -0.05187394842505455,
        0.010792957618832588,
        -0.05053989961743355,
        0.05176318809390068,
        0.039983902126550674,
        0.0517067089676857,
        -0.051942866295576096,
        -0.009062284603714943,
        -0.0419524647295475,
        0.025594821199774742,
        0.051361847668886185,
        0.03989729657769203,
        0.045409563928842545,
        0.051845598965883255,
        0.0392766036093235,
        -0.0031921183690428734,
        -0.051792554557323456,
        -0.04987100884318352,
        -0.05147024989128113,
        0.01437192689627409,
        0.05096162110567093,
        -0.05116063728928566,
        0.0519852451980114,
        -0.05013556033372879,
        0.04951730743050575,
        0.051994938403367996,
        0.05197202414274216,
        0.043410833925008774,
        0.009148728102445602,
        0.052003856748342514,
        -0.026149215176701546,
        0.007029626984149218,
        -0.05032546445727348,
        0.0517023541033268,
        0.05003169924020767,
        -0.050588347017765045,
        -0.026697367429733276,
        0.051209062337875366,
        0.051870208233594894,
        0.05059301480650902,
        0.002840489149093628,
        -0.05198916420340538,
        -0.047024939209222794,
        -0.04895904287695885,
        0.04985063895583153,
        0.04423588141798973,
        0.05192926898598671,
        -0.04261958226561546,
        -0.05131978169083595,
        -0.048970919102430344,
        0.05200202390551567,
        0.015057839453220367,
        0.04776104912161827,
        -0.051095958799123764,
        0.04014963656663895,
        -0.052004374563694,
        0.03275272995233536,
        -0.018131999298930168,
        -0.050963662564754486,
        0.04760429635643959,
        0.04961802810430527,
        0.002535258186981082,
        -0.051774024963378906,
        -0.040093809366226196,
        -0.029298482462763786,
        0.051476117223501205,
        -0.05027317255735397,
        -0.02583617903292179,
        0.05051877349615097,
        -0.0517110712826252,
        0.05200309678912163,
        0.05099998414516449,
        0.05130701884627342,
        0.051595866680145264,
        0.051986340433359146,
        0.03913312405347824,
        0.051521722227334976,
        0.050880905240774155,
        0.05017196759581566,
        -0.05178678408265114,
        -0.045207735151052475,
        0.05098021775484085,
        -0.052005574107170105,
        -0.05195790156722069,
        -0.049127332866191864,
        -0.0472622849047184,
        0.05150306597352028,
        0.006516370922327042,
        0.05159744247794151,
        -0.05181276053190231,
        0.03296239301562309,
        -0.05199287086725235,
        0.05197560787200928,
        0.05149255692958832,
        -0.047351107001304626,
        -0.051767755299806595,
        0.012029976584017277,
        0.020065344870090485,
        0.05177950859069824,
        -0.05179248005151749,
        0.04984915256500244,
        0.05079471319913864,
        -0.01620604656636715,
        -0.051511917263269424,
        0.05171177163720131,
        -0.032123222947120667,
        -0.04992503300309181,
        0.05101769417524338,
        -0.03822169452905655,
        0.02692306973040104,
        0.05038802698254585,
        -0.05083564668893814,
        -0.04854905232787132,
        0.007172549609094858,
        -0.03928342089056969,
        -0.031151967123150826,
        -0.05199926719069481,
        0.0482948012650013,
        -0.05170373246073723,
        -0.051941197365522385,
        0.03853598237037659,
        0.050928995013237,
        0.05078737437725067,
        0.04886098951101303,
        -0.001396111212670803,
        -0.051894474774599075,
        -0.050711892545223236,
        0.04312654212117195,
        0.020387476310133934,
        0.05131952837109566,
        -0.052006036043167114,
        -0.0064957644790410995,
        0.048811253160238266,
        -0.0066057187505066395,
        0.05177152901887894,
        -0.0519413948059082,
        0.0007386789657175541,
        -0.051980726420879364,
        -0.022582771256566048,
        -0.04505215957760811,
        0.03566383570432663,
        -0.05126618966460228,
        0.048656243830919266,
        0.025793615728616714,
        0.05197390913963318,
        0.040403466671705246,
        0.05200701579451561,
        -0.05197663977742195,
        -0.05010553449392319,
        -0.05199994891881943,
        -0.02868921309709549,
        -0.05199992656707764,
        -0.041226815432310104,
        -0.01172939594835043,
        -0.010458189062774181,
        -0.05199370160698891,
        0.04630453884601593,
        0.012962199747562408,
        -0.0500863716006279,
        -0.05191013216972351,
        -0.05186945199966431,
        -0.051235415041446686,
        -0.052004624158144,
        -0.026195459067821503,
        -0.04193894937634468,
        -0.05093890056014061,
        -0.018350670114159584,
        0.05173690617084503,
        0.05195319652557373,
        0.0511741004884243,
        0.020611902698874474,
        0.04969347268342972,
        0.04279022291302681,
        -0.05200621113181114,
        0.044238168746232986,
        0.05200648680329323,
        -0.020272046327590942,
        0.04848402738571167,
        0.049868132919073105,
        0.02286950685083866,
        -0.05200709030032158,
        -0.0445437952876091,
        0.028884470462799072,
        -0.048948563635349274,
        -0.0516718365252018,
        0.04286735877394676,
        0.050638508051633835,
        -0.05197499692440033,
        -0.0394398532807827,
        -0.03262633830308914,
        0.051673874258995056,
        0.05187647417187691,
        -0.05199887230992317,
        0.051906757056713104,
        -0.051291707903146744,
        -0.05180031806230545,
        0.05047445744276047,
        0.051879703998565674,
        0.05107259750366211,
        0.05160892754793167,
        0.04412611201405525,
        -0.03188461810350418,
        -0.038971539586782455,
        -0.05021948739886284,
        -0.021954596042633057,
        -0.05198048800230026,
        -0.051992643624544144,
        0.039534714072942734,
        0.048781100660562515,
        0.027590133249759674,
        -0.05187623202800751,
        0.050917789340019226,
        0.030141182243824005,
        0.02575312927365303,
        -0.05199436843395233,
        0.01834511198103428,
        0.05192968621850014,
        -0.051991380751132965,
        -0.026215672492980957,
        -0.04936670884490013,
        -0.051887236535549164,
        0.04423234984278679,
        -0.0009691439918242395,
        -0.012355567887425423,
        0.04450860619544983,
        -0.04711932688951492,
        0.05185868218541145,
        0.024615399539470673,
        -0.031377069652080536,
        -0.05173903703689575,
        0.05145668983459473,
        0.051092229783535004,
        0.04199657216668129,
        -0.025236060842871666,
        0.04632004722952843,
        -0.04724384471774101,
        -0.033055379986763,
        -0.00888981856405735
    ],
    [
        -0.0467723049223423,
        -0.05402042716741562,
        -0.038060132414102554,
        0.012212689965963364,
        -0.0013116368791088462,
        -0.03855195268988609,
        0.05223863944411278,
        -0.05330824479460716,
        0.05401123687624931,
        0.04640421271324158,
        0.053931646049022675,
        -0.0039944336749613285,
        -0.05270303413271904,
        0.05107109993696213,
        -0.05388295277953148,
        -0.05379961431026459,
        0.05254390090703964,
        0.05399451032280922,
        -0.053844887763261795,
        0.05370522662997246,
        -0.015264280140399933,
        0.04587370529770851,
        0.00859762355685234,
        0.00047695290413685143,
        -0.017644939944148064,
        0.053041208535432816,
        -0.049100521951913834,
        0.05372662842273712,
        -0.05313362181186676,
        0.05378201603889465,
        0.05239833518862724,
        0.05309446528553963,
        -0.030494756996631622,
        -0.053894393146038055,
        0.053364623337984085,
        0.03914798051118851,
        -0.05377468094229698,
        -0.03860916569828987,
        -0.05357087031006813,
        0.05136830359697342,
        -0.01730908639729023,
        -0.05394971743226051,
        0.04459073022007942,
        -0.0008356404141522944,
        0.020471250638365746,
        0.05076131224632263,
        0.04968630149960518,
        0.05390792712569237,
        0.05401790514588356,
        -0.05381249263882637,
        0.048529352992773056,
        -0.03268231451511383,
        -0.044005826115608215,
        -0.05396465212106705,
        -0.013277570717036724,
        0.053582776337862015,
        -0.05363108962774277,
        0.04758797213435173,
        -0.053877439349889755,
        0.05391111969947815,
        -0.054017238318920135,
        -0.046515852212905884,
        -0.04889584705233574,
        -0.05222925916314125,
        -0.035395361483097076,
        -0.016737308353185654,
        -0.05400257930159569,
        -0.021214641630649567,
        -0.011295859701931477,
        -0.05361997336149216,
        -0.04927816241979599,
        -0.05024280026555061,
        -0.041469983756542206,
        0.05181202292442322,
        0.0538838654756546,
        -0.034785207360982895,
        -0.04560207948088646,
        0.0540204793214798,
        0.048703666776418686,
        0.040198132395744324,
        -0.029555246233940125,
        -0.05343302711844444,
        -0.05360235273838043,
        0.039035048335790634,
        0.04538554698228836,
        0.005306190345436335,
        -0.02293817140161991,
        0.05401907488703728,
        -0.051047295331954956,
        0.046615198254585266,
        -0.05392547696828842,
        -0.01000557653605938,
        -0.05401889979839325,
        -0.05234295502305031,
        -0.0373101681470871,
        0.041405897587537766,
        0.03537245839834213,
        -0.052759528160095215,
        -0.05005175620317459,
        0.047794174402952194,
        -0.053918905556201935,
        0.05165119469165802,
        0.04944401606917381,
        -0.03137577697634697,
        0.015168067067861557,
        -0.05305325239896774,
        0.053798217326402664,
        0.05397547408938408,
        -0.05332769826054573,
        -0.020564576610922813,
        -0.005789713468402624,
        0.05011369287967682,
        0.015179790556430817,
        0.036748114973306656,
        0.004052084404975176,
        -0.00772511214017868,
        -0.050396550446748734,
        -0.0489409863948822,
        -0.053636036813259125,
        -0.03889758884906769,
        0.05277746170759201,
        -0.02891591005027294,
        0.05376254394650459,
        -0.05392478033900261,
        -0.026853417977690697,
        0.01143969688564539,
        0.04197536036372185,
        0.012919829227030277,
        -0.04399459809064865,
        0.04495229944586754,
        0.053877346217632294,
        -0.01520841009914875,
        -0.05082231014966965,
        0.05215523764491081,
        -0.03902120143175125,
        -0.039438676089048386,
        0.05400557816028595,
        -0.04834647476673126,
        -0.008956048637628555,
        0.05197399854660034,
        -0.05294787511229515,
        -0.050638597458601,
        0.025200357660651207,
        -0.05393936112523079,
        -0.011787577532231808,
        0.027877217158675194,
        -0.026561377570033073,
        -0.05209527164697647,
        -0.05278180167078972,
        0.029430115595459938,
        -0.04669184982776642,
        -0.05060631036758423,
        0.05397643893957138,
        0.018243996426463127,
        0.0536748431622982,
        -0.05401589348912239,
        -0.052274007350206375,
        -0.04607367888092995,
        0.02832667902112007,
        0.034324761480093,
        0.006835087202489376,
        0.0022458175662904978,
        0.04982000216841698,
        0.0442252941429615,
        0.013533314689993858,
        -0.05274408683180809,
        0.016393108293414116,
        0.04848600924015045,
        0.05292094498872757,
        0.04962422698736191,
        0.052896711975336075,
        0.04787740856409073,
        -0.05400967597961426,
        -0.010962704196572304,
        -0.030500801280140877,
        0.023184217512607574,
        -0.052457984536886215,
        -0.051063716411590576,
        0.0539550818502903,
        -0.053730159997940063,
        -0.004343181382864714,
        0.05386336147785187,
        0.051211100071668625,
        0.04801512137055397,
        0.03009309060871601,
        0.037248119711875916,
        0.018294909968972206,
        0.05197984725236893,
        0.0540175698697567,
        -0.04395149275660515,
        -0.05288725346326828,
        -0.052095599472522736,
        0.04910643771290779,
        -0.009441371075809002,
        -0.047183383256196976,
        -0.04392823576927185,
        0.05312685668468475,
        0.05384531244635582,
        -0.05372270569205284,
        0.05271749943494797,
        0.053892605006694794,
        0.053799934685230255,
        -0.05401039496064186,
        0.053720880299806595,
        0.053796377032995224,
        -0.051942430436611176,
        0.04980560764670372,
        -0.042563244700431824,
        -0.050173111259937286,
        0.05400247499346733,
        -0.04938777536153793,
        0.012241926044225693,
        0.05334920063614845,
        0.04968467727303505,
        -0.05051069334149361,
        -0.05144602805376053,
        0.05401754751801491,
        -0.04786704480648041,
        0.05397971719503403,
        -0.03639994561672211,
        -0.04922225698828697,
        -0.05075383186340332,
        0.03490336611866951,
        0.007969764061272144,
        0.05398111045360565,
        0.049411386251449585,
        0.052206434309482574,
        -0.0240052230656147,
        -0.03617142140865326,
        -0.052722521126270294,
        0.05163395404815674,
        -0.053987015038728714,
        0.012528528459370136,
        0.049776747822761536,
        0.047104980796575546,
        0.05368207022547722,
        0.05141465738415718,
        0.054000284522771835,
        -0.0008734459988772869,
        0.05397799238562584,
        -0.03466544672846794,
        0.012333812192082405,
        -0.02757452242076397,
        -0.022054890170693398,
        0.05400700867176056,
        0.05391062796115875,
        0.01692180335521698,
        0.05152519792318344,
        -0.01588687300682068,
        0.03684120625257492,
        -0.03864867985248566,
        0.013878285884857178,
        -0.051574159413576126,
        -0.04547681659460068,
        -0.01209863368421793,
        -0.05265357717871666,
        0.024209238588809967,
        -0.05390476807951927,
        -0.024428876116871834,
        -0.03780350461602211,
        -0.05341556295752525,
        -0.05083958059549332,
        0.038157228380441666,
        0.0535903126001358,
        -0.020472485572099686,
        0.0530826598405838,
        -0.011330857872962952,
        0.0481230802834034,
        0.05250956118106842,
        -0.05375463888049126,
        -0.04360334947705269,
        -0.054020244628190994,
        0.05397382751107216,
        -0.051438260823488235,
        -0.05284145474433899,
        0.04068191349506378,
        -0.053429652005434036,
        0.026040371507406235,
        -0.026636123657226562,
        0.053404346108436584,
        -0.05260339006781578,
        0.05398845672607422,
        0.03195140138268471,
        -0.0022132506128400564,
        0.040413469076156616,
        0.01863146387040615,
        0.0017866677371785045,
        0.053912147879600525,
        0.005311732180416584,
        0.04027586430311203,
        -0.021419527009129524,
        -0.049976807087659836,
        -0.05341765284538269,
        0.04516156017780304,
        -0.015651650726795197,
        -0.05221997946500778,
        0.05365096032619476,
        -0.05252005532383919,
        -0.05322514474391937,
        0.053852010518312454,
        0.05396070331335068,
        0.04828967899084091,
        -0.04109727591276169,
        -0.008925024420022964,
        0.03150555491447449,
        -0.04982070252299309,
        -0.018971862271428108,
        0.05397878214716911,
        0.048889126628637314,
        0.009030519984662533,
        -0.01822591759264469,
        0.03969214856624603,
        0.05162479728460312,
        0.0509256012737751,
        0.05150143429636955,
        -0.05382508412003517,
        -0.053412262350320816,
        -0.05259012058377266,
        0.03843604773283005,
        0.046721506863832474,
        0.05388423427939415,
        0.0022066866513341665,
        -0.0272673349827528,
        -0.052918899804353714,
        0.05169188976287842,
        0.04804980009794235,
        0.0429782010614872,
        -0.047286923974752426,
        0.04016093164682388,
        -0.05394860729575157,
        0.04506075009703636,
        0.012509510852396488,
        -0.03569255769252777,
        0.04087651148438454,
        0.05309097841382027,
        0.05394826456904411,
        -0.004092049784958363,
        -0.007732900325208902,
        0.04858396202325821,
        0.04453247785568237,
        0.008807115256786346,
        -0.05380408093333244,
        0.028349289670586586,
        -0.05401482805609703,
        0.04666658490896225,
        0.05083904787898064,
        0.03119892068207264,
        0.05173192173242569,
        0.03265351429581642,
        0.014688988216221333,
        -0.04371827840805054,
        -0.03994453698396683,
        0.04938582330942154,
        -0.0531277172267437,
        -0.03975914046168327,
        0.04488576948642731,
        -0.05374554917216301,
        -0.05362489074468613,
        -0.030453935265541077,
        0.019415758550167084,
        -0.0005838130018673837,
        0.042328041046857834,
        0.03533194214105606,
        -0.0463576577603817,
        -0.05292707681655884,
        -0.05391917750239372,
        0.05392683297395706,
        0.05304606258869171,
        0.04545384272933006,
        -0.05167151987552643,
        -0.024286217987537384,
        -0.010437944903969765,
        0.05350884050130844,
        -0.03484426438808441,
        0.04781778156757355,
        -0.04344635084271431,
        -0.04369575157761574,
        -0.051955755800008774,
        0.05300849303603172,
        -0.05375305563211441,
        -0.048136040568351746,
        0.052858203649520874,
        -0.052457597106695175,
        0.05101566016674042,
        -0.049504354596138,
        -0.05344946309924126,
        0.034252166748046875,
        -0.02873716689646244,
        -0.05393575131893158,
        -0.04082340747117996,
        -0.0535639226436615,
        0.03676358237862587,
        -0.05392633378505707,
        0.011144168674945831,
        0.017568791285157204,
        0.049557220190763474,
        0.01657007820904255,
        0.019495021551847458,
        0.006032384000718594,
        -0.052928730845451355,
        -0.04969228804111481,
        0.053869836032390594,
        0.04484737664461136,
        0.0517352819442749,
        -0.05164329335093498,
        0.03950287029147148,
        0.050953760743141174,
        -0.05349227413535118,
        0.05305192247033119,
        -0.0506017729640007,
        0.030497239902615547,
        0.032185424119234085,
        0.01053476519882679,
        -0.05128254368901253,
        -0.02639707550406456,
        -0.03802470117807388,
        -0.013969669118523598,
        0.053033001720905304,
        0.02990775927901268,
        -0.05397401377558708,
        0.05350390449166298,
        -0.05151545628905296,
        0.013143403455615044,
        -0.053491804748773575,
        -0.010964828543365002,
        -0.049598515033721924,
        -0.05302555859088898,
        -0.0537414588034153,
        0.05146794393658638,
        -0.0506788045167923,
        0.04746127873659134,
        -0.02492777444422245,
        -0.054004035890102386,
        -0.021055009216070175,
        -0.03162621706724167,
        -0.04881298169493675,
        -0.04299277067184448,
        0.017470644786953926,
        0.013433766551315784,
        -0.051160912960767746,
        -0.03388126194477081,
        0.053838301450014114,
        -0.05258246883749962,
        0.05293910577893257,
        0.05366750434041023,
        0.05208991840481758,
        0.038289718329906464,
        -0.05394527688622475,
        0.002271413104608655,
        0.05393986403942108,
        0.05151329189538956,
        0.05107828974723816,
        0.05379350855946541,
        -0.005965288728475571,
        -0.007870655506849289,
        -0.05371017009019852,
        -0.03536761924624443,
        0.02235337346792221,
        0.04669533297419548,
        0.05355418846011162,
        0.04957450553774834,
        -0.053898025304079056,
        0.05187489092350006,
        -0.053986284881830215,
        0.05130230262875557,
        0.0539289228618145,
        -0.05390514060854912,
        0.05389104411005974,
        -0.053890105336904526,
        -0.053438249975442886,
        0.04090426489710808,
        0.05321722850203514,
        0.03605647757649422,
        0.04297345131635666,
        -0.03330794349312782,
        -0.051032230257987976,
        -0.028868792578577995,
        -0.034082915633916855,
        -0.05387037247419357,
        -0.05321209132671356,
        -0.05401834473013878,
        -0.022978296503424644,
        0.05029953271150589,
        0.03308776766061783,
        -0.02688836120069027,
        0.05339654162526131,
        0.05342547222971916,
        0.04067851975560188,
        -0.05143212899565697,
        0.05333389714360237,
        0.051707662642002106,
        -0.05332373455166817,
        0.05185571312904358,
        -0.05202341452240944,
        -0.05335398390889168,
        0.040428463369607925,
        -0.003497225698083639,
        0.05393148213624954,
        0.05120361968874931,
        -0.038956787437200546,
        -0.03939181938767433,
        0.0524718314409256,
        -0.043947216123342514,
        -0.051367778331041336,
        0.04527560994029045,
        0.03429696708917618,
        -0.03860744088888168,
        -0.044669948518276215,
        0.020119255408644676,
        0.01944250799715519,
        0.028105657547712326,
        -0.05397522449493408
    ],
    [
        -0.05494611710309982,
        -0.054947540163993835,
        0.0483398400247097,
        0.03498822823166847,
        0.034202832728624344,
        0.019679829478263855,
        0.0542338527739048,
        -0.054440975189208984,
        0.007686025463044643,
        -0.01019975170493126,
        0.04096483811736107,
        0.04588354378938675,
        -0.05431697890162468,
        -0.03246660530567169,
        -0.054844748228788376,
        -0.050324924290180206,
        -0.054632801562547684,
        0.05494757369160652,
        -0.05486335605382919,
        0.017614280804991722,
        -0.05419521406292915,
        -0.007273167371749878,
        0.030379198491573334,
        -0.040965039283037186,
        0.002664923435077071,
        0.0399501696228981,
        0.05282139033079147,
        0.05348470062017441,
        -0.042945463210344315,
        -0.036446426063776016,
        0.05408884584903717,
        0.051473651081323624,
        0.04191738739609718,
        -0.0549396313726902,
        0.039564214646816254,
        0.011229094117879868,
        -0.05288355425000191,
        -0.05227832868695259,
        -0.05494827777147293,
        -0.03779358044266701,
        0.0464763268828392,
        -0.04445787891745567,
        0.0489334873855114,
        -0.054807599633932114,
        0.018844634294509888,
        0.054746150970458984,
        -0.05077802389860153,
        0.03227972611784935,
        0.05486997961997986,
        -0.02666574902832508,
        0.0531817264854908,
        0.012060053646564484,
        0.045671138912439346,
        -0.01796017773449421,
        -0.04802215099334717,
        -0.045861367136240005,
        -0.054852697998285294,
        -0.008123624138534069,
        0.0037229133304208517,
        0.05247669667005539,
        -0.054399412125349045,
        0.004543834365904331,
        -0.052772946655750275,
        0.05032392218708992,
        -0.05429361015558243,
        -0.051075197756290436,
        0.044371817260980606,
        -0.049713991582393646,
        0.045452624559402466,
        -0.05494553968310356,
        -0.05239608511328697,
        -0.054943859577178955,
        -0.051392585039138794,
        -0.03239015117287636,
        0.051701828837394714,
        -0.021090691909193993,
        -0.052303776144981384,
        0.05300389975309372,
        0.012291778810322285,
        -0.02284669503569603,
        -0.03992868587374687,
        0.013075282797217369,
        -0.043851956725120544,
        0.04380258172750473,
        0.00046366461901925504,
        0.007937632501125336,
        0.021619610488414764,
        0.05494813248515129,
        0.054681889712810516,
        0.05464675650000572,
        0.011342357844114304,
        0.05307968705892563,
        -0.03595215827226639,
        -0.05339590832591057,
        -0.031079042702913284,
        0.026183202862739563,
        -0.05224384367465973,
        -0.0525938905775547,
        -0.05290083587169647,
        0.037541016936302185,
        -0.05448237806558609,
        0.04635663703083992,
        0.05410538986325264,
        0.0017088897293433547,
        0.049421705305576324,
        0.005846105981618166,
        -0.05047570541501045,
        0.040254782885313034,
        -0.053826939314603806,
        0.01550387404859066,
        -0.05211341381072998,
        -0.05454114452004433,
        0.0424620695412159,
        0.04603714868426323,
        0.006903153378516436,
        -0.052479337900877,
        -0.008659884333610535,
        -0.04920418933033943,
        -0.054941341280937195,
        -0.014403900131583214,
        0.028001612052321434,
        0.05259041115641594,
        0.05445421114563942,
        0.045786261558532715,
        -0.054582420736551285,
        0.01031683012843132,
        -0.04517507180571556,
        -0.0015734878834336996,
        0.05444679409265518,
        0.03342457115650177,
        0.054394982755184174,
        -0.04532196372747421,
        -0.05290761962532997,
        0.002907952293753624,
        -0.04865339398384094,
        -0.04045400768518448,
        0.05076414346694946,
        0.003808998968452215,
        -0.05409388989210129,
        0.05487651377916336,
        -0.03749910742044449,
        -0.02499157190322876,
        0.04967810958623886,
        0.05304417759180069,
        -0.04099661484360695,
        0.04845937341451645,
        -0.03721291944384575,
        -0.05261123180389404,
        -0.04696379601955414,
        0.0528581440448761,
        -0.024703165516257286,
        -0.05485427752137184,
        -0.0536113977432251,
        0.03826934099197388,
        0.054931819438934326,
        -0.053821250796318054,
        0.04598510265350342,
        0.014823210425674915,
        -0.05483344942331314,
        -0.05241888761520386,
        0.05492827296257019,
        0.00930516142398119,
        0.03636999800801277,
        -0.03952275216579437,
        -0.04843588545918465,
        -0.053101107478141785,
        -0.05435236543416977,
        -0.049609679728746414,
        0.048998016864061356,
        0.028090644627809525,
        0.051868095993995667,
        0.05392679199576378,
        -0.05097884684801102,
        0.05368463695049286,
        -0.054794661700725555,
        0.051820605993270874,
        -0.05494316667318344,
        -0.03999698907136917,
        0.050149183720350266,
        -0.018528055399656296,
        0.019256047904491425,
        0.053744181990623474,
        0.05199650302529335,
        -0.053032852709293365,
        0.034965839236974716,
        0.05355092138051987,
        -0.010417674668133259,
        0.053098518401384354,
        0.05449164658784866,
        -0.05183371528983116,
        -0.038334015756845474,
        -0.02342177741229534,
        -0.054179199039936066,
        -0.04048528894782066,
        0.02269580401480198,
        -0.04231298342347145,
        0.04948846250772476,
        0.018174488097429276,
        -0.05006860941648483,
        0.03895065560936928,
        0.04932268708944321,
        0.0542789064347744,
        -0.029276035726070404,
        0.054926659911870956,
        0.05293995141983032,
        0.007512693293392658,
        0.04218202829360962,
        0.052172694355249405,
        -0.05394855886697769,
        0.05493135005235672,
        -0.051706328988075256,
        0.023663965985178947,
        -0.03924437239766121,
        -0.020293520763516426,
        -0.03537466749548912,
        -0.038459282368421555,
        0.05336902290582657,
        -0.04332150146365166,
        0.05493408814072609,
        -0.047684021294116974,
        -0.041233789175748825,
        -0.05486210063099861,
        -0.021512676030397415,
        -0.05488375574350357,
        0.05489354580640793,
        -0.054885197430849075,
        0.02737794630229473,
        0.05475622043013573,
        -0.0547831654548645,
        -0.054147571325302124,
        0.05482083931565285,
        -0.05492057278752327,
        -0.009566891938447952,
        0.0281950943171978,
        -0.008206424303352833,
        0.03916331008076668,
        0.05404524505138397,
        0.05345813184976578,
        0.023157255724072456,
        0.04219632223248482,
        0.002882609609514475,
        -0.016671137884259224,
        -0.048132844269275665,
        -0.054856665432453156,
        0.047526195645332336,
        0.04490959271788597,
        0.05320623889565468,
        0.054779279977083206,
        -0.04544001817703247,
        -0.053996939212083817,
        0.03741072863340378,
        -0.05387251451611519,
        -0.04890851303935051,
        -0.051983542740345,
        -0.05277198180556297,
        0.051154784858226776,
        -0.054767027497291565,
        -0.043942082673311234,
        0.050324272364377975,
        0.05493559688329697,
        0.03664466366171837,
        -0.012707168236374855,
        0.035704366862773895,
        0.05353464186191559,
        -0.054578252136707306,
        0.03272666409611702,
        0.04052530229091644,
        -0.016441483050584793,
        -0.022281907498836517,
        -0.05470670759677887,
        0.04483885318040848,
        -0.05484680086374283,
        0.05493927374482155,
        -0.04860704392194748,
        -0.05492924898862839,
        0.01969091407954693,
        -0.04952454939484596,
        0.011442787945270538,
        -0.038999203592538834,
        0.052300360053777695,
        -0.053827688097953796,
        0.020028136670589447,
        -0.02903592772781849,
        0.05375983193516731,
        0.0392962209880352,
        0.008796678856015205,
        -0.00462153647094965,
        -0.05454690381884575,
        -0.03582010790705681,
        0.05322093144059181,
        0.010957361198961735,
        0.02388131059706211,
        -0.05332493782043457,
        0.04152877628803253,
        0.005060137715190649,
        -0.053859811276197433,
        0.05494526028633118,
        0.041130248457193375,
        0.03252655267715454,
        0.05352561175823212,
        0.02040817402303219,
        0.05302078276872635,
        0.037909895181655884,
        0.049899663776159286,
        -0.05493737384676933,
        -0.02747400850057602,
        -0.044154975563287735,
        0.0472792312502861,
        0.05486743897199631,
        -0.05391307920217514,
        0.05111309513449669,
        0.015949292108416557,
        0.024074947461485863,
        0.05459364131093025,
        -0.04817775636911392,
        -0.04948752000927925,
        0.054153718054294586,
        -0.019729837775230408,
        0.02171279862523079,
        -0.03747688606381416,
        0.04995136335492134,
        0.040506161749362946,
        0.04293956980109215,
        0.03867516666650772,
        0.036886900663375854,
        0.054059483110904694,
        -0.02808636985719204,
        0.009470349177718163,
        0.05277922749519348,
        -0.04496791586279869,
        -0.050203561782836914,
        0.050600968301296234,
        0.022908588871359825,
        0.034265656024217606,
        0.05121978744864464,
        0.029968591406941414,
        -0.04712541401386261,
        0.050225187093019485,
        -0.04465693235397339,
        0.04792044684290886,
        0.03684919700026512,
        -0.036793164908885956,
        -0.025781827047467232,
        -0.05486058443784714,
        0.05494864284992218,
        -0.007488710340112448,
        0.02345326542854309,
        0.017857251688838005,
        0.05487877130508423,
        0.05225778743624687,
        -0.0549485869705677,
        0.04633451625704765,
        -0.036921434104442596,
        -0.05009551718831062,
        -0.050017572939395905,
        0.04847631976008415,
        -0.05475723370909691,
        -0.05268345773220062,
        0.009893874637782574,
        -0.05463717505335808,
        0.052929509431123734,
        0.014477423392236233,
        -0.04607236012816429,
        -0.005925601348280907,
        -0.025139713659882545,
        -0.05433902144432068,
        0.05471472814679146,
        -0.031229782849550247,
        0.053856153041124344,
        -0.05436563491821289,
        -0.021938251331448555,
        -0.05488821491599083,
        0.05361436307430267,
        0.025143176317214966,
        0.052566368132829666,
        0.040276896208524704,
        -0.054925721138715744,
        0.051341038197278976,
        0.030598562210798264,
        -0.04910949245095253,
        -0.04986342787742615,
        0.010522784665226936,
        -0.049591127783060074,
        0.05017305910587311,
        0.000021510069927899167,
        -0.03291665390133858,
        0.02085038460791111,
        -0.052713219076395035,
        -0.044456034898757935,
        -0.052360378205776215,
        -0.05375146493315697,
        0.05235857889056206,
        -0.016208551824092865,
        -0.04108777269721031,
        -0.04211445152759552,
        0.04589366912841797,
        0.05472622811794281,
        0.05414234474301338,
        0.043389514088630676,
        -0.054921187460422516,
        -0.05343896523118019,
        0.05432957410812378,
        0.03210442140698433,
        0.054948482662439346,
        -0.053532641381025314,
        0.047540083527565,
        0.04736916720867157,
        0.028230838477611542,
        0.054904092103242874,
        -0.0531119778752327,
        0.054929450154304504,
        -0.04993682727217674,
        0.05492014065384865,
        0.024094650521874428,
        0.0511213093996048,
        -0.04000360518693924,
        0.006175218150019646,
        0.04816192388534546,
        0.03832602500915527,
        -0.05470730736851692,
        -0.04770684242248535,
        -0.010253493674099445,
        0.04426799342036247,
        -0.05435320734977722,
        0.029354093596339226,
        -0.054885052144527435,
        0.022112276405096054,
        -0.04635796323418617,
        -0.05011080950498581,
        -0.051813796162605286,
        0.04925741255283356,
        -0.011788587085902691,
        -0.05494707450270653,
        -0.052325475960969925,
        -0.04616766422986984,
        0.0009317080257460475,
        -0.05494648963212967,
        -0.05205061286687851,
        -0.021861815825104713,
        -0.017503835260868073,
        -0.047942761331796646,
        0.05131405219435692,
        -0.047158826142549515,
        0.042046260088682175,
        0.05494855344295502,
        -0.02374516800045967,
        0.050769537687301636,
        -0.05494088679552078,
        0.045576319098472595,
        0.010149230249226093,
        -0.05015498027205467,
        0.0545433945953846,
        0.05489080771803856,
        -0.051770493388175964,
        -0.054459553211927414,
        -0.048128362745046616,
        -0.016095925122499466,
        0.04044260457158089,
        -0.051784418523311615,
        0.05011679604649544,
        0.028676023706793785,
        -0.04314161092042923,
        -0.05488639697432518,
        -0.010126630775630474,
        0.05320979282259941,
        0.05482231080532074,
        -0.054854024201631546,
        0.05490607023239136,
        -0.02942529506981373,
        -0.053797028958797455,
        0.053316548466682434,
        0.05491762235760689,
        0.04848744720220566,
        0.03936091065406799,
        0.054400522261857986,
        0.04521305486559868,
        0.04439443349838257,
        -0.002113118302077055,
        -0.0466497540473938,
        -0.054669078439474106,
        -0.05494404211640358,
        -0.02671545371413231,
        0.010869311168789864,
        0.04193727672100067,
        -0.05150248110294342,
        0.031379248946905136,
        0.010504531674087048,
        -0.0010782441822811961,
        -0.050430215895175934,
        0.05493611842393875,
        0.03150562196969986,
        -0.05494847148656845,
        0.0540437214076519,
        -0.04401968792080879,
        0.04940612614154816,
        0.035750873386859894,
        -0.05478794500231743,
        0.0547601543366909,
        0.049879271537065506,
        -0.013760564848780632,
        0.054947707802057266,
        0.048459701240062714,
        -0.02856680192053318,
        -0.05421406403183937,
        -0.04627681151032448,
        -0.0017490839818492532,
        -0.05474421754479408,
        -0.034572992473840714,
        0.010798011906445026,
        -0.05235496908426285,
        0.04971475526690483,
        -0.049780409783124924
    ],
    [
        -0.05441223829984665,
        -0.05442316085100174,
        0.03680678457021713,
        0.03048568218946457,
        0.045877501368522644,
        0.02906988374888897,
        0.05422590300440788,
        -0.05238945409655571,
        -0.011787074618041515,
        -0.041681207716464996,
        0.043831393122673035,
        0.04157721996307373,
        -0.052161503583192825,
        0.0005773366428911686,
        -0.05402502045035362,
        -0.05249771103262901,
        -0.054196372628211975,
        0.054422568529844284,
        -0.05442306026816368,
        -0.03408278524875641,
        -0.05433313176035881,
        0.022053560242056847,
        0.01957681216299534,
        -0.03874755650758743,
        0.029136190190911293,
        0.04260522499680519,
        0.043932121247053146,
        0.054281141608953476,
        -0.03688216954469681,
        0.029107065871357918,
        0.05436797812581062,
        0.051526620984077454,
        0.04426995664834976,
        -0.054418787360191345,
        0.032530006021261215,
        0.014678905718028545,
        -0.051201459020376205,
        -0.040693748742341995,
        -0.054423242807388306,
        0.03266969323158264,
        0.049860965460538864,
        -0.05365326255559921,
        0.04869283735752106,
        -0.0544184111058712,
        0.04264942556619644,
        0.052562955766916275,
        -0.02734328806400299,
        0.050277888774871826,
        0.054265305399894714,
        -0.04437723755836487,
        0.053097303956747055,
        -0.035606417804956436,
        0.05401168018579483,
        0.04633885994553566,
        -0.04456719383597374,
        -0.033096376806497574,
        -0.053814418613910675,
        -0.0012312657199800014,
        0.028016913682222366,
        0.05352651700377464,
        -0.05130183324217796,
        -0.040429987013339996,
        -0.052485112100839615,
        0.025606058537960052,
        -0.04074439778923988,
        -0.017617342993617058,
        0.044814836233854294,
        -0.052965834736824036,
        0.04063352569937706,
        -0.0544232539832592,
        -0.05390622466802597,
        -0.05442201346158981,
        -0.05424907058477402,
        -0.052253514528274536,
        0.024469785392284393,
        -0.025328578427433968,
        -0.05431763455271721,
        0.051712360233068466,
        -0.0037806679029017687,
        -0.017162684351205826,
        -0.03088970109820366,
        0.01623934879899025,
        -0.04091416671872139,
        0.03232676535844803,
        0.03711314499378204,
        0.039438653737306595,
        0.0366947166621685,
        0.054423268884420395,
        0.05358413606882095,
        0.05237608775496483,
        0.019892456009984016,
        0.053798433393239975,
        -0.020157627761363983,
        -0.05055709555745125,
        -0.035196974873542786,
        0.014140782877802849,
        -0.052056800574064255,
        -0.053874533623456955,
        -0.05423581227660179,
        0.027991963550448418,
        -0.05410842224955559,
        -0.02856370247900486,
        0.050642091780900955,
        0.032658275216817856,
        0.027459723874926567,
        -0.03635047376155853,
        -0.04916902258992195,
        0.034642115235328674,
        -0.050396181643009186,
        0.004286022391170263,
        -0.05420662462711334,
        -0.05430832877755165,
        0.02132798545062542,
        0.04710359126329422,
        0.005314548499882221,
        -0.05039851367473602,
        -0.03667236492037773,
        -0.05210947245359421,
        -0.0544230118393898,
        -0.04156119376420975,
        0.02333582006394863,
        0.04893796145915985,
        0.053462132811546326,
        0.04788356274366379,
        -0.05401817709207535,
        -0.005391156300902367,
        -0.051943980157375336,
        -0.004722998011857271,
        0.04750654101371765,
        0.05109204724431038,
        0.04957108199596405,
        -0.045990440994501114,
        -0.05312582477927208,
        0.03775455802679062,
        -0.049426354467868805,
        -0.040206700563430786,
        0.04815194383263588,
        -0.04961322620511055,
        -0.053388722240924835,
        0.05440670996904373,
        -0.01646006852388382,
        0.01805664785206318,
        0.04939001053571701,
        0.049890950322151184,
        0.017534654587507248,
        0.03976236656308174,
        -0.03838997334241867,
        -0.054087840020656586,
        -0.03582093492150307,
        0.04623721167445183,
        -0.024029679596424103,
        -0.05440603569149971,
        -0.05390676110982895,
        0.02398589439690113,
        0.05440929904580116,
        -0.04856221005320549,
        0.0535866916179657,
        0.025837218388915062,
        -0.05441304296255112,
        -0.04690621420741081,
        0.054413486272096634,
        0.04275272786617279,
        0.030317090451717377,
        -0.0540735088288784,
        -0.047127947211265564,
        -0.05302182212471962,
        -0.053371891379356384,
        -0.05403919517993927,
        0.037045083940029144,
        0.04434392973780632,
        0.04966871812939644,
        0.04856061190366745,
        0.017184000462293625,
        0.05359901115298271,
        -0.05439678207039833,
        0.05431601032614708,
        -0.05442311614751816,
        0.008535602129995823,
        0.04617910459637642,
        -0.023806123062968254,
        0.04397312179207802,
        0.0520797036588192,
        0.05436752364039421,
        -0.05149560421705246,
        -0.013645736500620842,
        0.053928520530462265,
        -0.04796682670712471,
        0.052102748304605484,
        0.05436186119914055,
        -0.05371769517660141,
        -0.01233273558318615,
        0.030124641954898834,
        -0.05432678759098053,
        -0.0282585471868515,
        -0.00268353964202106,
        -0.053732819855213165,
        0.050094060599803925,
        0.02159193716943264,
        -0.05353875085711479,
        0.04177694395184517,
        0.038536813110113144,
        0.05405760556459427,
        0.023751817643642426,
        0.05442289263010025,
        0.05358288064599037,
        0.029613928869366646,
        0.044176582247018814,
        0.04461335390806198,
        -0.05432078242301941,
        0.05441543459892273,
        -0.04614531993865967,
        0.024629445746541023,
        -0.04988941550254822,
        -0.051655083894729614,
        -0.033661261200904846,
        -0.012997206300497055,
        0.04854768514633179,
        -0.007068993523716927,
        0.05439819395542145,
        -0.05370331555604935,
        -0.036870475858449936,
        -0.0544176921248436,
        -0.008342791348695755,
        -0.054392680525779724,
        0.054360054433345795,
        -0.05438710376620293,
        -0.019556816667318344,
        0.05439998582005501,
        -0.054056163877248764,
        -0.05342322960495949,
        0.05427266284823418,
        -0.05415918305516243,
        -0.03344931825995445,
        0.028238270431756973,
        0.04235728457570076,
        0.02300887368619442,
        0.052656471729278564,
        0.05420785769820213,
        -0.04231525585055351,
        0.04847870394587517,
        0.028387729078531265,
        0.016075117513537407,
        -0.004527609795331955,
        -0.05430057644844055,
        0.041748493909835815,
        0.04512699320912361,
        0.053960151970386505,
        0.05438750609755516,
        -0.05347492918372154,
        -0.03940175101161003,
        0.04141794517636299,
        -0.05415796861052513,
        -0.05012918636202812,
        -0.05049651488661766,
        -0.0531674399971962,
        0.05352827161550522,
        -0.05423378944396973,
        -0.03699202090501785,
        0.0533330962061882,
        0.05440763756632805,
        0.05305630713701248,
        0.049163635820150375,
        0.05255810171365738,
        0.042902469635009766,
        -0.05434684082865715,
        -0.007881347090005875,
        -0.022984182462096214,
        -0.013283905573189259,
        0.03915031626820564,
        -0.05409235879778862,
        0.013950000517070293,
        -0.054314617067575455,
        0.05440361425280571,
        -0.05198618397116661,
        -0.05441226437687874,
        -0.0009113001869991422,
        -0.048963092267513275,
        -0.016865743324160576,
        -0.0456228144466877,
        0.05149519443511963,
        -0.05345078930258751,
        0.027215519919991493,
        0.019303452223539352,
        0.053767841309309006,
        -0.02434176579117775,
        -0.002894507022574544,
        0.002132662571966648,
        -0.05442073941230774,
        -0.043363116681575775,
        0.051054447889328,
        -0.03765147551894188,
        0.01596028171479702,
        -0.05036839470267296,
        0.05399598181247711,
        0.01159498281776905,
        -0.053911205381155014,
        0.05441974848508835,
        0.0019288018811494112,
        -0.026914574205875397,
        0.052095282822847366,
        0.014955542050302029,
        0.05210982263088226,
        0.037244778126478195,
        0.036149341613054276,
        -0.05441964045166969,
        -0.04578660801053047,
        -0.03167535737156868,
        0.05207701399922371,
        0.054336026310920715,
        -0.04501502960920334,
        0.05156131461262703,
        0.04307243973016739,
        0.005463465116918087,
        0.05441387742757797,
        -0.05187392979860306,
        -0.03837819769978523,
        0.05347788706421852,
        -0.038609299808740616,
        0.003121556481346488,
        -0.01558253075927496,
        0.028066903352737427,
        0.03950679302215576,
        0.03457396477460861,
        -0.0028114840388298035,
        -0.030335191637277603,
        0.052802976220846176,
        -0.029055455699563026,
        0.03685924410820007,
        0.027011893689632416,
        -0.04591820016503334,
        -0.05220120772719383,
        0.051954735070466995,
        0.013449955731630325,
        0.038602862507104874,
        0.053140852600336075,
        -0.027366578578948975,
        -0.05239871144294739,
        0.05060068890452385,
        -0.04625586047768593,
        0.050360988825559616,
        0.04024661332368851,
        -0.0424889400601387,
        -0.04917103424668312,
        -0.05441243574023247,
        0.054423268884420395,
        -0.04256109148263931,
        0.01886097341775894,
        0.023600900545716286,
        0.05369658023118973,
        0.053017131984233856,
        -0.054423268884420395,
        0.04988755285739899,
        -0.038239218294620514,
        -0.026485780254006386,
        -0.052899956703186035,
        0.05313728004693985,
        -0.05430258810520172,
        -0.05384844169020653,
        -0.010824664495885372,
        -0.05041398108005524,
        0.05015307664871216,
        -0.025899076834321022,
        -0.05329602211713791,
        -0.014624117873609066,
        -0.016648873686790466,
        -0.05417352169752121,
        0.05434620380401611,
        -0.03235642611980438,
        0.05355950444936752,
        -0.054136890918016434,
        -0.05174195393919945,
        -0.05429019406437874,
        0.053954776376485825,
        -0.016553429886698723,
        0.05305633693933487,
        0.025606416165828705,
        -0.05441662669181824,
        0.04598658159375191,
        0.022729383781552315,
        -0.03721572831273079,
        -0.052202217280864716,
        0.030375313013792038,
        -0.05247719585895538,
        0.03781711310148239,
        -0.0223502479493618,
        -0.04355872422456741,
        0.04293809086084366,
        -0.053218916058540344,
        -0.05199890211224556,
        -0.04484766721725464,
        -0.05210471525788307,
        0.05264703556895256,
        0.018418967723846436,
        0.007978354580700397,
        -0.05301801860332489,
        0.03498164564371109,
        0.054160334169864655,
        0.05270377919077873,
        0.03141824156045914,
        -0.053953882306814194,
        -0.05376133695244789,
        0.052850861102342606,
        0.0512411966919899,
        0.05442315340042114,
        -0.04380739852786064,
        0.04550274461507797,
        0.047923021018505096,
        0.049521006643772125,
        0.05431239306926727,
        -0.053911108523607254,
        0.054421309381723404,
        -0.04044652730226517,
        0.05436871573328972,
        -0.010474630631506443,
        0.048222851008176804,
        -0.037533026188611984,
        0.012549394741654396,
        0.02444804273545742,
        0.012972892262041569,
        -0.05440403148531914,
        -0.046618811786174774,
        -0.050072330981492996,
        0.0336027666926384,
        -0.04921106621623039,
        0.05287322402000427,
        -0.054358743131160736,
        0.04692552611231804,
        -0.04409496486186981,
        -0.0542798675596714,
        -0.043094880878925323,
        0.048288244754076004,
        0.004072416108101606,
        -0.05440695956349373,
        -0.02358502335846424,
        -0.025990407913923264,
        0.025759894400835037,
        -0.05441589653491974,
        -0.04916704446077347,
        -0.04856816306710243,
        -0.01778239570558071,
        -0.049531880766153336,
        0.0536290667951107,
        -0.05069556459784508,
        0.0508975014090538,
        0.05442320555448532,
        0.006368817761540413,
        0.051273975521326065,
        -0.054412007331848145,
        0.04931311309337616,
        -0.048875175416469574,
        -0.05164789780974388,
        0.049091458320617676,
        0.054403576999902725,
        -0.054085683077573776,
        -0.05427079647779465,
        -0.048487402498722076,
        -0.03570707514882088,
        0.04145189747214317,
        -0.05387955904006958,
        0.052432216703891754,
        -0.002529868856072426,
        0.016804620623588562,
        -0.054419439285993576,
        -0.03861406818032265,
        0.0481012798845768,
        0.0543045699596405,
        -0.05436104163527489,
        0.054413340985774994,
        -0.03551580384373665,
        -0.05289565771818161,
        0.04974308982491493,
        0.054410528391599655,
        0.04398570582270622,
        0.00301200058311224,
        0.05432664975523949,
        0.05116122588515282,
        0.05081196501851082,
        -0.044433046132326126,
        -0.04215451702475548,
        -0.054289307445287704,
        -0.05441989749670029,
        -0.022991424426436424,
        0.023659683763980865,
        -0.014251542277634144,
        -0.050795067101716995,
        0.02295345440506935,
        0.03211497142910957,
        -0.05089108645915985,
        -0.05013691261410713,
        0.05441928282380104,
        0.04419070482254028,
        -0.054423216730356216,
        0.04983525723218918,
        -0.04359980672597885,
        0.05371734872460365,
        0.03464807569980621,
        -0.04653821885585785,
        0.054353151470422745,
        0.04746491461992264,
        -0.01659897342324257,
        0.054422881454229355,
        0.03468679264187813,
        -0.053059253841638565,
        -0.053906362503767014,
        -0.05269447714090347,
        0.028876004740595818,
        -0.05422677844762802,
        -0.035771507769823074,
        -0.05182185769081116,
        -0.013109903782606125,
        0.03612739220261574,
        -0.053398653864860535
    ],
    [
        -0.05832121893763542,
        -0.05448251962661743,
        0.021346770226955414,
        0.039038628339767456,
        0.015309257432818413,
        -0.00919986329972744,
        0.05769997462630272,
        -0.05094578489661217,
        -0.031213829293847084,
        0.054347142577171326,
        -0.04353652521967888,
        0.012601809576153755,
        -0.05803489685058594,
        0.015049327164888382,
        -0.058472953736782074,
        0.0432625375688076,
        -0.058792777359485626,
        0.05638360232114792,
        -0.05846588313579559,
        0.017768381163477898,
        -0.04654081538319588,
        -0.048445072025060654,
        0.02928602695465088,
        0.03253660723567009,
        0.050945013761520386,
        0.057065557688474655,
        0.03866136819124222,
        0.058631058782339096,
        -0.056661639362573624,
        0.0046561346389353275,
        0.05809313431382179,
        0.045188549906015396,
        0.012874848209321499,
        -0.05691743269562721,
        -0.00998038798570633,
        0.011971683241426945,
        -0.05526771396398544,
        -0.04548013582825661,
        -0.033015817403793335,
        0.032688990235328674,
        0.026409588754177094,
        -0.02666931226849556,
        0.05561064928770065,
        -0.058445196598768234,
        -0.006226165220141411,
        0.027780864387750626,
        0.013425120152533054,
        0.058357611298561096,
        0.05695563182234764,
        0.011042443104088306,
        0.053517188876867294,
        -0.02656896598637104,
        0.058138519525527954,
        0.04172194376587868,
        0.0358683206140995,
        -0.04770694300532341,
        -0.018215971067547798,
        -0.03924805670976639,
        0.02890750765800476,
        0.009641609154641628,
        -0.05712287873029709,
        -0.03444872051477432,
        -0.056617219001054764,
        0.05263149365782738,
        -0.046622343361377716,
        -0.044026874005794525,
        0.0584307461977005,
        -0.05778910964727402,
        0.058704476803541183,
        -0.05883048102259636,
        -0.05566040053963661,
        -0.058853209018707275,
        -0.02887117676436901,
        0.03309406712651253,
        -0.05607146769762039,
        -0.000787913566455245,
        0.05700178071856499,
        0.058040034025907516,
        0.027390608564019203,
        -0.054254889488220215,
        -0.035795554518699646,
        0.05614138022065163,
        -0.03429890051484108,
        -0.008225670084357262,
        -0.049717821180820465,
        0.007384654600173235,
        -0.04419241473078728,
        0.05780961737036705,
        0.0012205461971461773,
        0.05776708200573921,
        -0.05161520466208458,
        -0.02184458076953888,
        -0.047367677092552185,
        -0.04237295687198639,
        0.02740410901606083,
        0.02796917036175728,
        -0.050688087940216064,
        -0.05759260430932045,
        -0.03315041959285736,
        -0.005383857060223818,
        -0.05882590636610985,
        -0.00408145459368825,
        0.05880211666226387,
        0.04931206628680229,
        0.04814315587282181,
        -0.034006163477897644,
        -0.05817117542028427,
        0.015205692499876022,
        -0.05816886946558952,
        -0.03173612803220749,
        -0.04361545667052269,
        -0.04921714961528778,
        -0.03330031409859657,
        -0.03181297704577446,
        -0.028920115903019905,
        -0.05713407322764397,
        0.009835428558290005,
        -0.05451219156384468,
        -0.05884405970573425,
        -0.041436947882175446,
        0.029757024720311165,
        -0.04300166666507721,
        -0.02778901718556881,
        0.05617547035217285,
        -0.058813195675611496,
        0.043573860079050064,
        -0.04076509177684784,
        0.018044961616396904,
        0.05316320061683655,
        -0.03440830484032631,
        0.010695641860365868,
        -0.04122982919216156,
        -0.04920298606157303,
        -0.035828981548547745,
        -0.053209323436021805,
        -0.04765559360384941,
        0.057805027812719345,
        -0.05036716163158417,
        -0.051779359579086304,
        0.05706218257546425,
        -0.007058197632431984,
        0.03602277487516403,
        0.05493156984448433,
        0.058195456862449646,
        0.04473558068275452,
        0.0013398726005107164,
        0.026591649278998375,
        -0.05572563782334328,
        -0.0367671400308609,
        0.03277100622653961,
        -0.055761128664016724,
        -0.058567073196172714,
        -0.05292235687375069,
        0.05222470313310623,
        -0.04838265851140022,
        -0.045720286667346954,
        0.05781083554029465,
        -0.04439203068614006,
        0.023293763399124146,
        -0.055769678205251694,
        0.05833883583545685,
        -0.03852943703532219,
        0.05014771595597267,
        -0.023024706169962883,
        -0.05706391856074333,
        -0.006244265474379063,
        -0.022617913782596588,
        -0.04619917646050453,
        -0.007494254037737846,
        0.04119155555963516,
        0.017520952969789505,
        0.04882929101586342,
        -0.05019505321979523,
        0.031444162130355835,
        -0.05027230456471443,
        0.05635896697640419,
        -0.05877988040447235,
        -0.014713682234287262,
        0.030682262033224106,
        0.029882770031690598,
        0.0523122176527977,
        0.049951549619436264,
        0.05544078350067139,
        -0.04927641153335571,
        0.02659393660724163,
        -0.01938440278172493,
        -0.056483473628759384,
        0.05275930091738701,
        -0.04982466995716095,
        -0.05654625967144966,
        0.01709762029349804,
        -0.0394538938999176,
        -0.02670852281153202,
        -0.010425891727209091,
        -0.05393299460411072,
        0.03119896911084652,
        0.04545992985367775,
        0.006445282604545355,
        -0.047029588371515274,
        0.03675239905714989,
        0.029029615223407745,
        0.044560253620147705,
        0.04169083759188652,
        0.03643935173749924,
        0.045914843678474426,
        0.01878667064011097,
        0.058077529072761536,
        0.05547073855996132,
        -0.05550207570195198,
        0.05012001469731331,
        -0.05573274940252304,
        0.0545932874083519,
        0.04128602147102356,
        -0.03654786944389343,
        0.0527099072933197,
        -0.03902553766965866,
        0.04929038882255554,
        0.03942926228046417,
        0.05885078012943268,
        -0.05049017444252968,
        -0.037439096719026566,
        -0.05438891425728798,
        -0.05597444251179695,
        -0.057647645473480225,
        0.058266784995794296,
        -0.05865233764052391,
        -0.03737888112664223,
        -0.019656000658869743,
        -0.05370303615927696,
        -0.05735381692647934,
        -0.03177829086780548,
        -0.058193884789943695,
        -0.025520822033286095,
        0.049199428409338,
        -0.015674931928515434,
        -0.0528813898563385,
        0.05813004821538925,
        -0.04686085134744644,
        0.04275128245353699,
        0.052379269152879715,
        0.05650881677865982,
        -0.01698896288871765,
        -0.05114555358886719,
        -0.04292754828929901,
        -0.046709828078746796,
        0.0012917161220684648,
        0.057656798511743546,
        -0.01852537877857685,
        -0.030910199508070946,
        -0.006891035940498114,
        0.030123643577098846,
        -0.05866553634405136,
        -0.027113215997815132,
        -0.05654022470116615,
        0.024281561374664307,
        0.05766823887825012,
        0.021488884463906288,
        0.03537161275744438,
        0.058826789259910583,
        0.05848519131541252,
        -0.012482970021665096,
        0.05407112464308739,
        0.04252324253320694,
        0.05225790664553642,
        -0.03131413459777832,
        0.0407111719250679,
        0.05558145046234131,
        0.014772702008485794,
        -0.017996132373809814,
        -0.055767230689525604,
        -0.023903122171759605,
        -0.0588209442794323,
        0.04564816877245903,
        -0.05830195173621178,
        -0.05243843048810959,
        0.0559835359454155,
        -0.05534406378865242,
        -0.04893685132265091,
        -0.02241406962275505,
        -0.010445628315210342,
        -0.05836319923400879,
        0.022372210398316383,
        -0.039333127439022064,
        0.011159460991621017,
        -0.04341612756252289,
        -0.03066748008131981,
        0.018463123589754105,
        -0.0503142848610878,
        -0.05010240152478218,
        0.05382196605205536,
        -0.05591486021876335,
        0.018872052431106567,
        -0.03238918259739876,
        0.013922654092311859,
        0.039346665143966675,
        -0.04305873438715935,
        0.05885522440075874,
        0.0034052778501063585,
        -0.001402948866598308,
        0.051505252718925476,
        0.05509017035365105,
        0.04808040335774422,
        -0.018005412071943283,
        0.04185480251908302,
        -0.03725956007838249,
        -0.0563637912273407,
        -0.028719587251544,
        0.040492523461580276,
        0.05713972449302673,
        -0.058515578508377075,
        -0.04986779764294624,
        0.015445434488356113,
        -0.045924101024866104,
        0.05709591135382652,
        -0.057274967432022095,
        -0.050909608602523804,
        0.058179255574941635,
        0.02652653679251671,
        -0.004423430189490318,
        -0.05051388591527939,
        0.02062731608748436,
        -0.043856166303157806,
        0.04874391853809357,
        0.012036329135298729,
        0.04869433119893074,
        0.0588187575340271,
        -0.01494972687214613,
        0.028648171573877335,
        0.026029104366898537,
        0.0205524954944849,
        -0.052591416984796524,
        0.03173085302114487,
        0.04841270297765732,
        -0.0361388698220253,
        0.05442020669579506,
        0.019208228215575218,
        -0.04953566938638687,
        0.05752154067158699,
        -0.031916435807943344,
        0.053689125925302505,
        0.05459611490368843,
        -0.05464765429496765,
        0.03013589419424534,
        -0.04895620793104172,
        0.058860283344984055,
        0.021458247676491737,
        0.016839606687426567,
        0.05291637033224106,
        0.019688647240400314,
        0.05642261356115341,
        -0.05885936692357063,
        0.03493041917681694,
        -0.05708719417452812,
        -0.05810369551181793,
        -0.04469040036201477,
        0.0583590604364872,
        -0.050499334931373596,
        0.030197199434041977,
        -0.05377889797091484,
        -0.057425208389759064,
        0.057576872408390045,
        0.05544498190283775,
        -0.056121256202459335,
        -0.02648886851966381,
        0.029542386531829834,
        -0.051054082810878754,
        0.058808375149965286,
        -0.03907480835914612,
        0.01932651363313198,
        -0.058831993490457535,
        -0.05579552426934242,
        -0.05881732329726219,
        0.058417484164237976,
        0.04968639463186264,
        0.05785995349287987,
        0.052489977329969406,
        0.011121141724288464,
        0.030681904405355453,
        0.04088446870446205,
        -0.02025747112929821,
        -0.029721923172473907,
        0.04966655746102333,
        -0.0515618696808815,
        -0.007073315791785717,
        0.02880525216460228,
        -0.04964345693588257,
        0.05273948609828949,
        -0.05850830301642418,
        -0.05299306660890579,
        -0.0251296479254961,
        -0.04971112310886383,
        0.038365449756383896,
        0.03599608689546585,
        0.057302169501781464,
        -0.03000825084745884,
        0.05731289088726044,
        0.057053301483392715,
        0.03404770791530609,
        -0.00473948335275054,
        -0.058731816709041595,
        -0.03313961252570152,
        0.03742483630776405,
        0.05473543331027031,
        0.05869945511221886,
        -0.033230166882276535,
        0.03616493567824364,
        0.020291009917855263,
        0.03488542512059212,
        0.057203613221645355,
        -0.05212869122624397,
        0.05885280296206474,
        0.010558783076703548,
        0.04062332212924957,
        0.017862092703580856,
        0.04933161288499832,
        0.03683839738368988,
        0.03931474685668945,
        -0.006543154828250408,
        0.03085230477154255,
        0.05504107102751732,
        -0.05702671408653259,
        -0.039305076003074646,
        0.00260002538561821,
        -0.0537482351064682,
        0.011901854537427425,
        -0.05358930304646492,
        -0.03573600947856903,
        -0.055233217775821686,
        -0.05866521596908569,
        -0.025819206610322,
        0.04617350921034813,
        -0.04999035969376564,
        -0.05577722564339638,
        0.01597040519118309,
        0.030274899676442146,
        -0.021881043910980225,
        -0.0426725335419178,
        0.02965339832007885,
        0.04814239218831062,
        -0.05282745882868767,
        0.02912650816142559,
        0.044408347457647324,
        0.001746122958138585,
        0.05583643540740013,
        0.05525210127234459,
        0.04143649339675903,
        0.05650985613465309,
        -0.05873076617717743,
        0.05726804956793785,
        -0.05605112761259079,
        -0.03729923442006111,
        0.03390221297740936,
        0.058135148137807846,
        0.010460354387760162,
        0.02487015724182129,
        -0.015728482976555824,
        -0.039289239794015884,
        0.05745453014969826,
        -0.050105586647987366,
        0.045576903969049454,
        0.031851161271333694,
        -0.04271984472870827,
        -0.05846178159117699,
        0.05307259410619736,
        0.056255050003528595,
        0.05878348648548126,
        -0.05869581177830696,
        0.05779609829187393,
        -0.031525541096925735,
        -0.05700413137674332,
        0.05127936229109764,
        0.0578518845140934,
        0.05711846053600311,
        0.03205719590187073,
        0.053238559514284134,
        0.039616551250219345,
        -0.003636325476691127,
        -0.0021740328520536423,
        -0.044702790677547455,
        -0.05780882388353348,
        -0.055438779294490814,
        -0.05174034833908081,
        -0.020768582820892334,
        0.020001094788312912,
        -0.010526780970394611,
        0.05492332577705383,
        0.004261865746229887,
        -0.04554825276136398,
        -0.057211700826883316,
        -0.048573289066553116,
        -0.02454802766442299,
        -0.05876065790653229,
        0.027647320181131363,
        0.044333070516586304,
        0.05782897025346756,
        0.05287269502878189,
        -0.057851340621709824,
        -0.02269683964550495,
        0.05454849824309349,
        0.057252123951911926,
        0.016759531572461128,
        -0.03158057853579521,
        0.04505077376961708,
        -0.05079360678792,
        0.0012373178033158183,
        -0.030308986082673073,
        -0.051202889531850815,
        -0.04715545102953911,
        -0.03578457608819008,
        -0.05478368699550629,
        0.058789849281311035,
        0.050408557057380676
    ],
    [
        -0.05992596223950386,
        -0.057164404541254044,
        0.030439000576734543,
        -0.005030544009059668,
        0.042881082743406296,
        0.04072605445981026,
        0.058011848479509354,
        0.014061874710023403,
        -0.059822555631399155,
        0.055615413933992386,
        -0.034680984914302826,
        0.046039216220378876,
        -0.05075350031256676,
        -0.046603500843048096,
        -0.061811599880456924,
        0.05787651985883713,
        -0.06089038401842117,
        0.06015629693865776,
        -0.05929651856422424,
        -0.01705970987677574,
        -0.03730437159538269,
        -0.02421237714588642,
        -0.032644063234329224,
        0.04196972772479057,
        0.056072548031806946,
        0.05743638426065445,
        -0.00006840241985628381,
        0.058516621589660645,
        -0.0468643456697464,
        0.025299537926912308,
        0.06134546920657158,
        0.04675494506955147,
        0.033154454082250595,
        -0.05140634998679161,
        -0.01944672502577305,
        -0.018512777984142303,
        -0.0504656620323658,
        -0.04601322114467621,
        0.003294124035164714,
        0.03221975266933441,
        0.0343991257250309,
        -0.051835350692272186,
        0.056910235434770584,
        -0.06144307181239128,
        0.022365732118487358,
        0.045395415276288986,
        0.03808276355266571,
        0.056419625878334045,
        0.052578575909137726,
        -0.001974313287064433,
        0.05951661244034767,
        -0.013834898360073566,
        0.06096711382269859,
        0.034753669053316116,
        0.03896371275186539,
        -0.032618142664432526,
        0.04306603968143463,
        -0.04847100004553795,
        -0.0033357117790728807,
        -0.010455816052854061,
        -0.05796384438872337,
        -0.013209921307861805,
        -0.05948381870985031,
        0.0501435324549675,
        -0.01772998459637165,
        -0.02307998202741146,
        0.061389658600091934,
        -0.05351351946592331,
        0.06160976365208626,
        -0.06194637715816498,
        -0.060248807072639465,
        -0.06213345006108284,
        -0.02205226942896843,
        0.020549239590764046,
        -0.04924220219254494,
        -0.012837597168982029,
        0.031847625970840454,
        0.06079041585326195,
        -0.006813217885792255,
        -0.04975277557969093,
        -0.0461231954395771,
        0.05161864683032036,
        -0.01888887770473957,
        0.0276117492467165,
        -0.04179789498448372,
        0.008483223617076874,
        -0.05645369365811348,
        0.060272227972745895,
        -0.007080104202032089,
        0.05983839929103851,
        -0.0428527407348156,
        -0.04244391620159149,
        -0.0013485130621120334,
        -0.055570751428604126,
        0.03191940486431122,
        0.03695093095302582,
        -0.03419794142246246,
        -0.05296197906136513,
        0.024856653064489365,
        0.018865812569856644,
        -0.06212848797440529,
        -0.013332102447748184,
        0.06189325079321861,
        0.027097094804048538,
        0.0551130510866642,
        -0.017995072528719902,
        -0.060248423367738724,
        -0.03672970086336136,
        -0.06084968522191048,
        -0.023589318618178368,
        -0.0068490696139633656,
        -0.0525720939040184,
        -0.05386703461408615,
        -0.027976520359516144,
        -0.03081517480313778,
        -0.05946841463446617,
        0.012671001255512238,
        -0.04930274188518524,
        -0.06155070662498474,
        -0.03960586339235306,
        0.05648330599069595,
        0.03206767141819,
        -0.03906051069498062,
        0.061663974076509476,
        -0.061793792992830276,
        0.05195321515202522,
        -0.031716182827949524,
        -0.05068090558052063,
        0.03484245762228966,
        -0.03210656717419624,
        0.04419762268662453,
        -0.05769219249486923,
        -0.033795066177845,
        -0.02328023873269558,
        -0.05416401848196983,
        -0.039448581635951996,
        0.051110949367284775,
        -0.05121660232543945,
        -0.05770719796419144,
        0.060666535049676895,
        -0.04535474628210068,
        0.010443057864904404,
        0.05569051578640938,
        0.05820504575967789,
        0.020521046593785286,
        0.005293907131999731,
        0.036587849259376526,
        -0.014519864693284035,
        -0.048895176500082016,
        0.05361245200037956,
        -0.0029780911281704903,
        -0.06179278716444969,
        -0.043206460773944855,
        0.024030905216932297,
        -0.0607634000480175,
        -0.053233370184898376,
        0.060076672583818436,
        -0.020567798987030983,
        0.045704714953899384,
        -0.05747971683740616,
        0.06182360276579857,
        -0.0316447876393795,
        0.05639370530843735,
        -0.04403511807322502,
        -0.05836109444499016,
        0.0035678555723279715,
        -0.03516297787427902,
        -0.053536221385002136,
        0.0036788915749639273,
        0.04780237749218941,
        -0.013078007847070694,
        0.05149061605334282,
        -0.05491948127746582,
        -0.002082178369164467,
        -0.030445611104369164,
        0.05692032352089882,
        -0.06143128126859665,
        0.0254469383507967,
        0.017856815829873085,
        0.031411852687597275,
        0.05095319077372551,
        0.049575433135032654,
        0.057434339076280594,
        -0.0360991396009922,
        0.034665755927562714,
        -0.04585735872387886,
        -0.05753811448812485,
        0.06035521626472473,
        -0.049608420580625534,
        -0.05543971806764603,
        0.0374743714928627,
        -0.011606922373175621,
        -0.02270333096385002,
        0.0014657313004136086,
        -0.060519684106111526,
        0.04752189293503761,
        0.037005286663770676,
        -0.051860276609659195,
        -0.02774270996451378,
        0.026204247027635574,
        0.029657533392310143,
        0.051890380680561066,
        0.05196743458509445,
        -0.0038869830314069986,
        0.036545004695653915,
        0.0024884454905986786,
        0.060799647122621536,
        0.054449163377285004,
        -0.03812967613339424,
        0.06099904701113701,
        -0.059369929134845734,
        0.05482612922787666,
        0.043862562626600266,
        -0.037535786628723145,
        0.04606378450989723,
        -0.04591323435306549,
        -0.009721528738737106,
        0.02888389304280281,
        0.06075666472315788,
        -0.05646192654967308,
        -0.03152267634868622,
        -0.04173895716667175,
        -0.0489615835249424,
        -0.06083785742521286,
        0.05737064778804779,
        -0.060663316398859024,
        -0.05149664729833603,
        -0.02313893474638462,
        -0.05774218961596489,
        -0.052585702389478683,
        0.05236261710524559,
        -0.04289060831069946,
        -0.05592615157365799,
        0.05526670441031456,
        -0.0019403211772441864,
        -0.03333749249577522,
        0.06132735684514046,
        -0.03655011206865311,
        0.038767095655202866,
        0.05110873654484749,
        0.043301016092300415,
        0.016796832904219627,
        -0.060500867664813995,
        -0.048155758529901505,
        -0.026940779760479927,
        -0.030197037383913994,
        0.061134450137615204,
        -0.04110265523195267,
        -0.036698680371046066,
        -0.006309022195637226,
        0.02665739692747593,
        -0.06056566908955574,
        -0.040309250354766846,
        -0.06081078574061394,
        0.013216973282396793,
        0.0582219623029232,
        -0.0058260331861674786,
        0.03714765980839729,
        0.06212250515818596,
        0.060308463871479034,
        0.01596323773264885,
        0.04836338013410568,
        0.02329932153224945,
        0.031239209696650505,
        0.04726318642497063,
        0.0031726148445159197,
        0.05284879356622696,
        -0.022660518065094948,
        0.027167445048689842,
        -0.061054326593875885,
        -0.022759687155485153,
        -0.061597783118486404,
        0.04026496782898903,
        -0.047214049845933914,
        -0.05540751293301582,
        0.054516833275556564,
        -0.058146264404058456,
        -0.030667683109641075,
        0.04993104562163353,
        0.008101055398583412,
        -0.06195079907774925,
        -0.010412588715553284,
        -0.04698151722550392,
        -0.010452406480908394,
        -0.04178083688020706,
        -0.004818657413125038,
        0.04247574880719185,
        -0.05185231193900108,
        -0.045876771211624146,
        0.05692262947559357,
        -0.04955935850739479,
        0.035563889890909195,
        0.022915389388799667,
        0.04606431722640991,
        0.05973513796925545,
        -0.03526981174945831,
        0.06211062893271446,
        -0.030342675745487213,
        -0.010164027102291584,
        0.04744582250714302,
        0.05970999225974083,
        0.05705954506993294,
        -0.03817715495824814,
        0.012951529584825039,
        -0.017918985337018967,
        -0.05981384590268135,
        0.01203975360840559,
        0.047581400722265244,
        0.057224929332733154,
        -0.05979563295841217,
        -0.05016234517097473,
        0.04839415103197098,
        -0.03254523128271103,
        0.06155328452587128,
        -0.05059312283992767,
        -0.04907219111919403,
        0.05976460501551628,
        0.04997516795992851,
        0.01120801828801632,
        -0.05539878457784653,
        0.03617837652564049,
        -0.048196688294410706,
        0.029609443619847298,
        0.04221281036734581,
        0.013885444030165672,
        0.061991143971681595,
        -0.013577389530837536,
        0.014577355235815048,
        -0.027231834828853607,
        -0.0032473404426127672,
        -0.03983594477176666,
        0.038324132561683655,
        0.021097127348184586,
        -0.014564081095159054,
        0.05790731683373451,
        0.015683144330978394,
        -0.05123616009950638,
        0.05758368596434593,
        -0.03214740753173828,
        0.050834279507398605,
        0.03642032667994499,
        -0.03691119700670242,
        0.03057861141860485,
        -0.04390238597989082,
        0.06214313954114914,
        0.0038900889921933413,
        0.012699868530035019,
        0.051126208156347275,
        -0.028721386566758156,
        0.053628113120794296,
        -0.062151774764060974,
        0.03824674338102341,
        -0.05945755913853645,
        -0.059652701020240784,
        -0.03535666689276695,
        0.05899298936128616,
        -0.04766795411705971,
        0.03515344485640526,
        -0.035812217742204666,
        -0.06090443581342697,
        0.05708872899413109,
        0.05691628158092499,
        -0.061051417142152786,
        0.0031971829012036324,
        -0.011708973906934261,
        -0.05346527323126793,
        0.06142228841781616,
        -0.060655806213617325,
        0.019869152456521988,
        -0.06189928948879242,
        -0.0594373494386673,
        -0.06203875690698624,
        0.05635350942611694,
        0.05307845026254654,
        0.053084515035152435,
        0.034919966012239456,
        0.025901537388563156,
        0.028112895786762238,
        0.03875214606523514,
        0.014639278873801231,
        -0.03221946209669113,
        0.032864391803741455,
        -0.05748169124126434,
        0.010969117283821106,
        0.02572867088019848,
        0.005920936353504658,
        0.02723117358982563,
        -0.05969691276550293,
        -0.05061915144324303,
        -0.01926681399345398,
        -0.03319361060857773,
        -0.025226954370737076,
        0.04003042355179787,
        0.045221343636512756,
        -0.03479854762554169,
        0.05909446254372597,
        0.054537925869226456,
        0.0296503733843565,
        0.02169778384268284,
        -0.0605485700070858,
        -0.02384297549724579,
        0.04812042787671089,
        0.05528390407562256,
        0.06096123158931732,
        0.04444030672311783,
        0.05778820440173149,
        -0.0028596625197678804,
        0.018355796113610268,
        0.046863749623298645,
        -0.04122777283191681,
        0.062096912413835526,
        -0.025228669866919518,
        0.05972594395279884,
        0.01745610311627388,
        0.05463609844446182,
        0.008858357556164265,
        0.05568760260939598,
        -0.04880769923329353,
        -0.03829667344689369,
        0.05962445214390755,
        -0.05428288131952286,
        -0.05136880278587341,
        0.02736641839146614,
        -0.05208715423941612,
        -0.03307041525840759,
        -0.047795429825782776,
        -0.02098296955227852,
        -0.0608755499124527,
        -0.06013663113117218,
        -0.04793797433376312,
        0.053489625453948975,
        -0.03445373475551605,
        -0.054195281118154526,
        0.027007725089788437,
        0.0037774222437292337,
        -0.007269775029271841,
        -0.037832241505384445,
        -0.0014116322854533792,
        0.04391181096434593,
        -0.057067833840847015,
        -0.008998220786452293,
        0.0560871846973896,
        -0.000990973087027669,
        0.058054275810718536,
        0.03168223053216934,
        0.04498191922903061,
        0.060436930507421494,
        -0.060391899198293686,
        0.05621615797281265,
        -0.057980336248874664,
        -0.04774376004934311,
        0.02267259918153286,
        0.06171829625964165,
        0.01806778647005558,
        0.050473518669605255,
        -0.0298537015914917,
        0.033359747380018234,
        0.041970089077949524,
        -0.0008767229737713933,
        0.05652513727545738,
        0.048823703080415726,
        -0.04591524973511696,
        -0.06104258820414543,
        0.05893910303711891,
        0.04732711613178253,
        0.06193460151553154,
        -0.060669802129268646,
        0.06078027933835983,
        -0.02007516287267208,
        -0.05827055871486664,
        0.057125575840473175,
        0.06013138219714165,
        0.05634293705224991,
        0.017527518793940544,
        0.049996454268693924,
        -0.026209458708763123,
        0.03504139557480812,
        0.0118029760196805,
        -0.050097476691007614,
        -0.03849406912922859,
        -0.03307067230343819,
        -0.055794503539800644,
        0.033322274684906006,
        0.007212811149656773,
        0.016923602670431137,
        0.0524912104010582,
        0.012759165838360786,
        -0.037039417773485184,
        -0.060922782868146896,
        -0.003401209367439151,
        -0.02770170196890831,
        -0.06161108985543251,
        0.0444355346262455,
        0.03160649538040161,
        0.059451378881931305,
        0.04010084271430969,
        -0.061400704085826874,
        -0.010322585701942444,
        0.04464394226670265,
        0.04060227796435356,
        -0.012239736504852772,
        -0.05687132477760315,
        0.031396519392728806,
        -0.015653245151042938,
        0.010630838572978973,
        -0.04971158131957054,
        0.03584199398756027,
        -0.023095741868019104,
        -0.013827979564666748,
        -0.05603717640042305,
        0.05331370234489441,
        0.05956045165657997
    ],
    [
        -0.052129168063402176,
        -0.028408108279109,
        -0.02185218222439289,
        0.004044121131300926,
        -0.0010024583898484707,
        -0.023518580943346024,
        0.05054338648915291,
        -0.040464188903570175,
        -0.02290802076458931,
        0.052584849298000336,
        -0.02063930407166481,
        0.03063497319817543,
        -0.05255315452814102,
        -0.05164516344666481,
        -0.05164811387658119,
        0.04358036071062088,
        -0.05120854452252388,
        0.052624210715293884,
        -0.013452688232064247,
        -0.05198488384485245,
        0.029207810759544373,
        0.05235140770673752,
        0.0521714948117733,
        -0.01628374308347702,
        0.04595143720507622,
        0.044631779193878174,
        0.04783063754439354,
        0.050538450479507446,
        -0.052477046847343445,
        -0.046684011816978455,
        0.0014328176621347666,
        0.03851427510380745,
        0.049823254346847534,
        -0.05258350446820259,
        0.04589848965406418,
        0.044647347182035446,
        -0.05208795517683029,
        0.000258280080743134,
        0.051824864000082016,
        -0.0440014973282814,
        0.04400594159960747,
        0.05167930945754051,
        0.05173860490322113,
        0.047803718596696854,
        -0.03642372414469719,
        -0.03817939758300781,
        -0.03938659653067589,
        0.05060300976037979,
        0.052603237330913544,
        0.0010186064755544066,
        0.05216090381145477,
        0.05177024006843567,
        0.00024368506274186075,
        0.03769485652446747,
        -0.04923083633184433,
        0.0481681227684021,
        -0.04456203430891037,
        0.05252722650766373,
        0.04441869631409645,
        -0.052556879818439484,
        -0.05251767113804817,
        0.04851291701197624,
        -0.04906674474477768,
        0.049866411834955215,
        0.04377783462405205,
        -0.0485856719315052,
        -0.05240599066019058,
        0.03562239557504654,
        -0.03619730845093727,
        0.050139572471380234,
        -0.033904146403074265,
        0.012592777609825134,
        0.05261563882231712,
        0.05148118734359741,
        0.04459996148943901,
        0.052441228181123734,
        0.0070304833352565765,
        0.05262414738535881,
        0.05258098989725113,
        0.003785767126828432,
        -0.04074902459979057,
        -0.04960295185446739,
        -0.05260367691516876,
        -0.01603950373828411,
        0.023193007335066795,
        -0.04291583597660065,
        0.052602969110012054,
        0.05254695564508438,
        -0.02901299297809601,
        0.052180539816617966,
        -0.051435574889183044,
        -0.05261382460594177,
        -0.04649679735302925,
        -0.008869691751897335,
        0.011400813236832619,
        0.04490543156862259,
        -0.052600715309381485,
        -0.0038807056844234467,
        0.011178530752658844,
        0.04303324967622757,
        -0.05262117460370064,
        0.05261331796646118,
        0.05012921616435051,
        0.05106176435947418,
        0.05242672935128212,
        -0.0524456650018692,
        -0.05261995643377304,
        0.012296772561967373,
        0.03529905155301094,
        0.052575964480638504,
        -0.04961102455854416,
        -0.052129797637462616,
        -0.05251873657107353,
        -0.05260563641786575,
        -0.012852867133915424,
        -0.050039876252412796,
        -0.010895592160522938,
        -0.05260864272713661,
        -0.049890656024217606,
        -0.05171702057123184,
        -0.04904051497578621,
        -0.05168541893362999,
        0.052139583975076675,
        0.02577061578631401,
        -0.0511837899684906,
        -0.023508036509156227,
        -0.05222845450043678,
        0.052537836134433746,
        -0.03477143123745918,
        0.03875996544957161,
        -0.05217928811907768,
        -0.05185270681977272,
        -0.034360989928245544,
        0.05183645337820053,
        -0.04077520966529846,
        -0.05213238671422005,
        0.049874287098646164,
        0.0006794279906898737,
        -0.051810089498758316,
        0.05261775851249695,
        0.049532290548086166,
        -0.014983643777668476,
        -0.045352671295404434,
        0.05222845450043678,
        0.043639738112688065,
        -0.024708300828933716,
        0.052622828632593155,
        0.032779812812805176,
        -0.05239502340555191,
        0.021532021462917328,
        -0.050547171384096146,
        -0.052616894245147705,
        -0.0513145886361599,
        -0.049812521785497665,
        0.05243780091404915,
        -0.05128621682524681,
        -0.049057427793741226,
        0.04761459305882454,
        0.05124416574835777,
        -0.052255600690841675,
        0.05262370407581329,
        0.013920484110713005,
        0.05247808247804642,
        -0.02356305718421936,
        -0.05093280225992203,
        -0.05226806551218033,
        -0.05221855640411377,
        -0.04824730381369591,
        -0.04496106505393982,
        0.014149023219943047,
        -0.04759405553340912,
        0.05092604085803032,
        -0.05259820446372032,
        0.05184323340654373,
        0.05128829553723335,
        -0.034376002848148346,
        -0.01673969440162182,
        -0.05256642401218414,
        0.05214906856417656,
        -0.04493018612265587,
        -0.03518039733171463,
        0.052623145282268524,
        0.04828055202960968,
        -0.0454925000667572,
        -0.044625915586948395,
        0.05211414024233818,
        -0.047344788908958435,
        0.052604127675294876,
        -0.052031707018613815,
        -0.05138910189270973,
        -0.05257044732570648,
        -0.01823565736413002,
        0.05180928483605385,
        0.04193568974733353,
        -0.04473431035876274,
        0.04364648088812828,
        0.03422452136874199,
        -0.032451432198286057,
        -0.04666076600551605,
        0.04956378415226936,
        0.052434246987104416,
        0.051792360842227936,
        -0.05126519873738289,
        -0.049968965351581573,
        0.044879235327243805,
        -0.0487024262547493,
        0.05111991614103317,
        0.03534872457385063,
        -0.0525846891105175,
        0.05258861929178238,
        -0.05137854442000389,
        0.011101974174380302,
        0.010609705932438374,
        0.03737900033593178,
        0.0419197641313076,
        -0.050019003450870514,
        0.05173175409436226,
        0.05149368196725845,
        0.04994168132543564,
        -0.05198477953672409,
        -0.00682523287832737,
        0.05171972140669823,
        -0.0519120991230011,
        -0.04946351423859596,
        -0.05245791748166084,
        -0.04055645689368248,
        0.05255192518234253,
        -0.03208792209625244,
        -0.05185500904917717,
        -0.05262055993080139,
        -0.050725795328617096,
        0.050131503492593765,
        -0.05256206542253494,
        0.011518610641360283,
        0.05262346193194389,
        0.025820808485150337,
        0.047735393047332764,
        -0.02087070234119892,
        0.05180206894874573,
        0.0526229664683342,
        0.05261978507041931,
        -0.04540864750742912,
        0.05258532986044884,
        -0.052621953189373016,
        0.04383968561887741,
        0.04351472109556198,
        0.013722565956413746,
        -0.027489935979247093,
        -0.03478964790701866,
        -0.0398421436548233,
        -0.05259416624903679,
        -0.042738620191812515,
        0.03232453763484955,
        -0.0395938977599144,
        -0.045933015644550323,
        0.04731670767068863,
        -0.05133982375264168,
        -0.052322763949632645,
        0.047919005155563354,
        0.02888191118836403,
        0.05250393971800804,
        -0.05203012749552727,
        -0.047184403985738754,
        0.04152575135231018,
        -0.0386045016348362,
        0.05209578946232796,
        0.05198543518781662,
        0.051259156316518784,
        0.04983305186033249,
        0.04683803766965866,
        0.042469315230846405,
        -0.05187540873885155,
        -0.005696631036698818,
        -0.051312435418367386,
        -0.05219382792711258,
        -0.05020224303007126,
        0.029079733416438103,
        -0.007850319147109985,
        0.048614341765642166,
        -0.05244741216301918,
        -0.05258011445403099,
        0.051298171281814575,
        -0.05201258510351181,
        -0.0339922197163105,
        -0.016192274168133736,
        -0.02217485010623932,
        -0.04386884719133377,
        0.010249052196741104,
        -0.05261654779314995,
        0.04184357076883316,
        -0.04072967544198036,
        0.045417118817567825,
        0.04861580580472946,
        0.032676056027412415,
        0.00465229619294405,
        -0.05252540111541748,
        0.05260774865746498,
        0.05208268016576767,
        -0.049595367163419724,
        0.04587625339627266,
        -0.04837464913725853,
        0.005277473945170641,
        0.028488287702202797,
        0.05121052637696266,
        -0.010252098552882671,
        -0.04526488855481148,
        0.017403680831193924,
        -0.052529823035001755,
        0.048959698528051376,
        -0.05182173475623131,
        -0.04862138628959656,
        -0.04778195172548294,
        -0.052491143345832825,
        -0.04138991981744766,
        -0.05223894491791725,
        -0.05011441558599472,
        -0.018735375255346298,
        -0.04928581416606903,
        -0.03447810932993889,
        -0.0498017780482769,
        -0.04129965230822563,
        0.041183944791555405,
        -0.052621990442276,
        -0.050810202956199646,
        0.037004806101322174,
        0.023190362378954887,
        0.05004464089870453,
        -0.0436272993683815,
        -0.05015682056546211,
        -0.04958738759160042,
        0.05262402072548866,
        -0.016223877668380737,
        -0.05126412212848663,
        0.04421699047088623,
        0.04078660532832146,
        0.0491139180958271,
        0.04380768910050392,
        0.05262235179543495,
        0.04706105589866638,
        0.05240330100059509,
        0.016457436606287956,
        -0.03574252873659134,
        0.043180979788303375,
        0.048721931874752045,
        -0.014485188759863377,
        0.031587883830070496,
        0.04709453508257866,
        0.04471467807888985,
        -0.04595799744129181,
        0.0513823963701725,
        0.05201714113354683,
        -0.011079971678555012,
        0.03035793825984001,
        -0.03329964354634285,
        -0.05232682079076767,
        0.05051027610898018,
        -0.05064155161380768,
        -0.04867010563611984,
        0.04989641532301903,
        0.02326381392776966,
        0.051765114068984985,
        0.05238259583711624,
        -0.03336367383599281,
        0.0012975306017324328,
        -0.0523737408220768,
        -0.0521666556596756,
        0.05222911015152931,
        -0.04677300900220871,
        0.03616750240325928,
        -0.052588481456041336,
        -0.029276540502905846,
        -0.050668489187955856,
        0.027136320248246193,
        -0.05090003088116646,
        0.008538932539522648,
        0.04764917865395546,
        -0.05054429545998573,
        0.052620310336351395,
        0.015466556884348392,
        0.05250030755996704,
        -0.052221693098545074,
        0.04885381460189819,
        -0.037640780210494995,
        0.045811284333467484,
        0.05239221081137657,
        0.050880514085292816,
        0.04514773562550545,
        -0.052622389048337936,
        -0.052479784935712814,
        0.038847919553518295,
        0.0036505977623164654,
        -0.04500196501612663,
        -0.04869122803211212,
        -0.04283731058239937,
        0.04128008708357811,
        0.05215776339173317,
        0.052588049322366714,
        -0.05259339138865471,
        -0.04009959101676941,
        -0.05232785642147064,
        0.003665792988613248,
        0.05262058600783348,
        0.04040023311972618,
        -0.031476590782403946,
        0.03617642819881439,
        0.044343579560518265,
        0.05220721289515495,
        -0.05170265957713127,
        0.05102885887026787,
        -0.0370691679418087,
        -0.05252567678689957,
        -0.034606050699949265,
        0.0526176355779171,
        0.03129138797521591,
        0.052533723413944244,
        -0.049058668315410614,
        -0.05259445682168007,
        0.027744412422180176,
        0.052613504230976105,
        0.03171659633517265,
        -0.03448788821697235,
        -0.04714014008641243,
        -0.013541479595005512,
        -0.05250158905982971,
        0.05022810027003288,
        -0.04458726570010185,
        -0.052621688693761826,
        0.050284937024116516,
        -0.013749964535236359,
        0.00328334653750062,
        0.030500896275043488,
        0.012078327126801014,
        0.046295713633298874,
        0.05261138454079628,
        0.046893347054719925,
        -0.052615176886320114,
        0.0004940633079968393,
        0.052295438945293427,
        -0.05223147198557854,
        -0.05262317135930061,
        -0.05139537528157234,
        0.052535269409418106,
        0.052623871713876724,
        0.051832154393196106,
        -0.05251035466790199,
        0.03919655829668045,
        -0.015408111736178398,
        -0.050188954919576645,
        0.018016215413808823,
        0.049645617604255676,
        -0.05222567915916443,
        -0.05260573700070381,
        0.028145022690296173,
        -0.05242722108960152,
        -0.04480356723070145,
        0.00697763217613101,
        -0.05158982425928116,
        -0.017009010538458824,
        0.04733811691403389,
        0.051235344260931015,
        0.04605242237448692,
        -0.05261938273906708,
        -0.010917768813669682,
        -0.05024127662181854,
        -0.015335812233388424,
        0.05242877081036568,
        -0.05257784575223923,
        0.05262378603219986,
        -0.01023569144308567,
        -0.023709921166300774,
        0.044385623186826706,
        0.042882222682237625,
        0.052482664585113525,
        0.05042998120188713,
        0.05211879312992096,
        -0.04980906471610069,
        -0.0017605827888473868,
        0.05257364362478256,
        -0.000733701279386878,
        0.04633325710892677,
        -0.04731985926628113,
        0.04635442793369293,
        -0.03094751574099064,
        -0.04885609447956085,
        -0.05257290229201317,
        0.04942574352025986,
        0.01026267558336258,
        -0.04257269576191902,
        -0.04961410164833069,
        -0.041826967149972916,
        -0.0504252053797245,
        -0.04193577170372009,
        0.009150673635303974,
        0.05259884521365166,
        0.02462581731379032,
        0.05160694196820259,
        -0.0515039823949337,
        -0.0422770194709301,
        0.030100641772150993,
        -0.01381674874573946,
        -0.05240149423480034,
        -0.017709406092762947,
        -0.05193058028817177,
        0.015824586153030396,
        0.05199962481856346,
        0.050735168159008026,
        0.052620984613895416,
        -0.052476026117801666,
        0.030975183472037315,
        -0.05261389538645744,
        0.05261773616075516,
        0.05098143592476845
    ],
    [
        -0.0532696507871151,
        -0.053150009363889694,
        0.003952295985072851,
        0.04495919123291969,
        -0.0047820089384913445,
        -0.025813832879066467,
        0.050892505794763565,
        -0.05240892246365547,
        -0.02407046966254711,
        0.04826364666223526,
        0.047755345702171326,
        0.04250868409872055,
        -0.052479375153779984,
        -0.0226305965334177,
        -0.052899736911058426,
        0.002521929331123829,
        -0.051961299031972885,
        0.0529213473200798,
        -0.03981725126504898,
        0.052748627960681915,
        -0.023722002282738686,
        -0.05014488101005554,
        0.028436558321118355,
        -0.052854541689157486,
        0.05214594677090645,
        0.04966386407613754,
        0.053228966891765594,
        0.02331484481692314,
        -0.05293836444616318,
        -0.03349322825670242,
        0.04357646033167839,
        -0.05324243754148483,
        0.0499916635453701,
        -0.05328027904033661,
        0.047535449266433716,
        0.014245279133319855,
        -0.051837313920259476,
        -0.052998289465904236,
        0.05268567055463791,
        -0.05168655142188072,
        0.04296315088868141,
        0.05286369100213051,
        0.0526866652071476,
        -0.05180509388446808,
        -0.04375552758574486,
        0.023857224732637405,
        -0.04936307668685913,
        0.05286969617009163,
        0.053246885538101196,
        -0.03146219253540039,
        0.051815807819366455,
        0.026958243921399117,
        -0.028160201385617256,
        -0.05322106182575226,
        -0.03590220585465431,
        0.050270359963178635,
        -0.05303836986422539,
        0.04447304457426071,
        0.05153720825910568,
        -0.0532805435359478,
        -0.05212775990366936,
        0.019711008295416832,
        -0.051532402634620667,
        0.053253937512636185,
        -0.050990890711545944,
        0.044570501893758774,
        -0.041767969727516174,
        0.047662876546382904,
        0.05311667174100876,
        0.05132661014795303,
        0.0531766340136528,
        -0.047316182404756546,
        0.047646041959524155,
        0.0531248077750206,
        0.028932498767971992,
        0.05155208706855774,
        0.0482620894908905,
        0.053281549364328384,
        0.052291106432676315,
        -0.05096736550331116,
        -0.050051815807819366,
        -0.053249750286340714,
        -0.05322099104523659,
        0.05231095850467682,
        -0.03899824619293213,
        0.051449015736579895,
        0.05254121124744415,
        -0.03576305881142616,
        -0.03779564052820206,
        0.04662054777145386,
        -0.05278083309531212,
        0.011664281599223614,
        -0.053117960691452026,
        0.053257379680871964,
        -0.038713835179805756,
        0.04886743426322937,
        -0.047031477093696594,
        -0.052331987768411636,
        -0.005965224467217922,
        0.04114094749093056,
        -0.05325264483690262,
        0.0531594455242157,
        0.053243402391672134,
        0.04444336146116257,
        0.04276143014431,
        -0.05312296748161316,
        -0.05323728546500206,
        0.052526336163282394,
        -0.047520961612463,
        0.05327074974775314,
        0.04669405519962311,
        -0.04873991385102272,
        -0.05294176936149597,
        -0.05304145812988281,
        0.020431047305464745,
        -0.04876461997628212,
        -0.02360345795750618,
        -0.03752550482749939,
        -0.035682737827301025,
        0.0165134035050869,
        0.039509087800979614,
        -0.05169767886400223,
        0.05304422229528427,
        -0.04011904448270798,
        -0.05185239017009735,
        0.05153685063123703,
        -0.0373024120926857,
        0.043416593223810196,
        -0.05317876860499382,
        0.05133110657334328,
        -0.05058690160512924,
        -0.05270003899931908,
        -0.04928665980696678,
        0.05321323871612549,
        -0.04747897386550903,
        -0.05169407278299332,
        0.04704929515719414,
        -0.03458849713206291,
        -0.05309269204735756,
        0.053060710430145264,
        -0.029319757595658302,
        -0.002895302139222622,
        0.05210898071527481,
        0.05290437862277031,
        -0.035573091357946396,
        0.03604056313633919,
        -0.03406323492527008,
        0.049275826662778854,
        -0.022726554423570633,
        -0.007666395045816898,
        -0.05136873945593834,
        -0.052876122295856476,
        -0.04047035798430443,
        -0.03824213519692421,
        0.04694180563092232,
        -0.04707952216267586,
        -0.052453987300395966,
        -0.05275045707821846,
        0.05323014780879021,
        -0.05284691974520683,
        0.04387374222278595,
        0.028177177533507347,
        0.052456654608249664,
        0.031842928379774094,
        -0.04567164182662964,
        -0.015996957197785378,
        -0.010395684279501438,
        0.028005018830299377,
        0.05325822904706001,
        0.00990062952041626,
        0.020843762904405594,
        0.037555329501628876,
        -0.05314857140183449,
        -0.021897971630096436,
        0.051295164972543716,
        -0.014773077331483364,
        0.011339331977069378,
        -0.048798296600580215,
        0.0436260849237442,
        0.002087764674797654,
        -0.0489838607609272,
        0.051226548850536346,
        0.04663878679275513,
        -0.04672674834728241,
        -0.01862836256623268,
        0.053272515535354614,
        -0.05303170531988144,
        0.05306316539645195,
        -0.0532575324177742,
        -0.052543964236974716,
        -0.053045399487018585,
        -0.039093781262636185,
        0.04321533441543579,
        0.03023015893995762,
        -0.04914403334259987,
        -0.002504478208720684,
        0.03734080493450165,
        0.020932065322995186,
        -0.051853813230991364,
        0.031723376363515854,
        0.05248551443219185,
        0.0467865988612175,
        -0.05314033851027489,
        -0.05160797759890556,
        0.05287434533238411,
        -0.05327867716550827,
        0.010991476476192474,
        0.040834520012140274,
        -0.05325021967291832,
        0.05240696296095848,
        -0.05316321551799774,
        0.04867573827505112,
        -0.04334219917654991,
        0.05263429507613182,
        0.038881849497556686,
        -0.0287544634193182,
        0.05149180069565773,
        0.009053035639226437,
        0.05282400920987129,
        0.03703175485134125,
        0.04647627845406532,
        -0.016357162967324257,
        -0.05290038138628006,
        0.007187417708337307,
        -0.052199117839336395,
        -0.05238229036331177,
        0.053193461149930954,
        0.05306306108832359,
        -0.025150759145617485,
        -0.052522771060466766,
        0.04606107994914055,
        0.052228424698114395,
        -0.05125568062067032,
        0.04975281283259392,
        0.05324569717049599,
        0.04937368631362915,
        0.04821780323982239,
        -0.0507638156414032,
        0.043485525995492935,
        0.053207870572805405,
        0.04274936765432358,
        0.04850517585873604,
        0.051695480942726135,
        0.001623094198293984,
        0.04018678888678551,
        0.040498748421669006,
        0.04013995826244354,
        0.04690587520599365,
        0.0527619794011116,
        -0.0532066747546196,
        -0.02942882478237152,
        -0.05298793688416481,
        0.04625668004155159,
        -0.0512191504240036,
        0.0043449923396110535,
        0.05215322598814964,
        -0.052996814250946045,
        -0.0532270148396492,
        0.03783939406275749,
        -0.01445529144257307,
        -0.051828138530254364,
        -0.05151066929101944,
        -0.03546799719333649,
        0.05188130959868431,
        -0.012895380146801472,
        0.053234897553920746,
        0.052999868988990784,
        0.051246169954538345,
        0.016537092626094818,
        -0.040913764387369156,
        0.03011997602880001,
        -0.05327684059739113,
        -0.04125770181417465,
        -0.03941838815808296,
        -0.040905412286520004,
        0.041019830852746964,
        -0.01759074628353119,
        0.05231945589184761,
        0.05200299993157387,
        0.012898624874651432,
        -0.05297137796878815,
        0.051330823451280594,
        -0.018816933035850525,
        -0.017462080344557762,
        0.05154597386717796,
        0.049357231706380844,
        0.04818439483642578,
        0.00819299928843975,
        -0.05195104330778122,
        -0.027505885809659958,
        -0.05131218209862709,
        0.026778478175401688,
        0.010669617913663387,
        -0.045475397258996964,
        -0.05016665160655975,
        -0.052806463092565536,
        0.053281381726264954,
        0.03308994323015213,
        -0.03734777867794037,
        0.05254707112908363,
        0.04319169372320175,
        -0.0329650454223156,
        0.047810737043619156,
        0.05193116515874863,
        0.05269625037908554,
        0.04284616932272911,
        0.030962523072957993,
        -0.025147704407572746,
        -0.053100015968084335,
        0.013218691572546959,
        -0.05280822142958641,
        0.04391232505440712,
        0.046518947929143906,
        -0.0532311387360096,
        -0.05327804386615753,
        -0.05023308843374252,
        -0.04425441846251488,
        -0.03514225780963898,
        0.043205007910728455,
        -0.0519772507250309,
        0.0501360259950161,
        -0.051489874720573425,
        -0.05327985808253288,
        -0.05265597254037857,
        0.05010462924838066,
        0.027635393664240837,
        0.037523820996284485,
        -0.032177165150642395,
        0.01816467195749283,
        -0.023285169154405594,
        0.052312806248664856,
        -0.05159942805767059,
        -0.008085246197879314,
        -0.030048279091715813,
        0.04922064021229744,
        0.04740523174405098,
        0.0500456839799881,
        0.05323085933923721,
        0.048500608652830124,
        0.05301306024193764,
        -0.031587619334459305,
        -0.04672999307513237,
        0.05073201283812523,
        0.043856970965862274,
        0.051942646503448486,
        0.028953583911061287,
        0.02109418995678425,
        0.049868177622556686,
        0.021216915920376778,
        0.022744230926036835,
        -0.038016125559806824,
        0.0056896330788731575,
        0.012513073161244392,
        0.023766297847032547,
        -0.029363134875893593,
        0.008606876246631145,
        -0.05326950550079346,
        -0.051896192133426666,
        0.05304257944226265,
        -0.052691902965307236,
        0.03171633183956146,
        0.040199749171733856,
        -0.03397431969642639,
        -0.051384665071964264,
        -0.04314151406288147,
        -0.040996816009283066,
        0.053065575659275055,
        -0.047499340027570724,
        0.05289570987224579,
        -0.053271159529685974,
        -0.02583918161690235,
        -0.019292064011096954,
        0.052381452172994614,
        -0.014247606508433819,
        0.04133179783821106,
        0.05280471593141556,
        -0.0184403657913208,
        0.045307863503694534,
        0.05067406967282295,
        0.0523231066763401,
        0.04213739559054375,
        0.05122949928045273,
        -0.051493335515260696,
        0.05169910565018654,
        -0.013719412498176098,
        -0.052551545202732086,
        0.05320356786251068,
        -0.05293569713830948,
        -0.051358044147491455,
        -0.04358043894171715,
        -0.0013029610272496939,
        -0.0479421429336071,
        -0.03493723273277283,
        -0.004079516977071762,
        0.05088765546679497,
        0.05309201776981354,
        0.05318954214453697,
        -0.04714277759194374,
        -0.025422334671020508,
        -0.05326438695192337,
        -0.052979208528995514,
        0.05317923426628113,
        0.0031779150012880564,
        0.042836278676986694,
        0.04592893645167351,
        -0.047168631106615067,
        0.04889487475156784,
        0.0011414076434448361,
        0.050431735813617706,
        -0.042992591857910156,
        -0.028769897297024727,
        0.04920110106468201,
        0.052955616265535355,
        0.053219910711050034,
        0.05311707779765129,
        -0.05119157209992409,
        -0.05264380946755409,
        0.022812997922301292,
        0.040244393050670624,
        0.05061444640159607,
        -0.0508953258395195,
        0.04332887381315231,
        0.032432958483695984,
        -0.052247047424316406,
        0.049643613398075104,
        -0.05043558403849602,
        0.0018202097853645682,
        0.03912101313471794,
        0.05327280983328819,
        -0.04327075928449631,
        -0.05034003034234047,
        -0.03582878038287163,
        -0.04737653583288193,
        0.05261536315083504,
        -0.05216576159000397,
        -0.05321602523326874,
        0.02990388683974743,
        0.034854963421821594,
        0.013600309379398823,
        -0.05285845324397087,
        0.032523833215236664,
        0.049627676606178284,
        0.05296960845589638,
        0.05236193537712097,
        -0.021464021876454353,
        0.04967619106173515,
        0.05296235531568527,
        -0.040682632476091385,
        0.052241694182157516,
        0.05147350952029228,
        0.04280493035912514,
        -0.053280990570783615,
        -0.05284053459763527,
        -0.04882317781448364,
        0.047921471297740936,
        -0.05030824616551399,
        -0.05295174941420555,
        0.04617190361022949,
        0.05291463062167168,
        0.05257979780435562,
        0.0066481237299740314,
        -0.053278353065252304,
        -0.023797493427991867,
        0.0034806989133358,
        -0.04203053191304207,
        0.05320146307349205,
        0.024243874475359917,
        0.05314396321773529,
        -0.040101952850818634,
        -0.04795236885547638,
        0.05219671502709389,
        0.028376126661896706,
        0.0532357320189476,
        0.04628535732626915,
        0.04835343733429909,
        0.0381278395652771,
        -0.05121854692697525,
        0.05327048897743225,
        0.04837590083479881,
        -0.03416312113404274,
        -0.049910660833120346,
        -0.04776184633374214,
        -0.05320974811911583,
        -0.03257795795798302,
        -0.05251505598425865,
        0.0520886592566967,
        -0.02303772047162056,
        0.04347257316112518,
        -0.05306662619113922,
        -0.021446913480758667,
        -0.04527416452765465,
        -0.02154996618628502,
        0.053084224462509155,
        -0.05008012056350708,
        0.000915012788027525,
        0.019731655716896057,
        -0.04794710874557495,
        0.013308622874319553,
        -0.014181114733219147,
        0.03377914801239967,
        -0.052791494876146317,
        -0.02744211256504059,
        -0.04708440974354744,
        0.004177934490144253,
        -0.03629973903298378,
        0.04946804791688919,
        0.04886527359485626,
        -0.049147143959999084,
        0.029005061835050583,
        -0.04877720773220062,
        0.05256403237581253,
        -0.0279002096503973
    ],
    [
        -0.0524735264480114,
        -0.054817233234643936,
        -0.0003354593936819583,
        0.02138841524720192,
        0.02549520507454872,
        -0.009814984165132046,
        0.05534553527832031,
        -0.05471795052289963,
        -0.029741551727056503,
        0.03199896216392517,
        0.03949982300400734,
        0.05515537038445473,
        -0.052582379430532455,
        0.0029082652181386948,
        -0.055166423320770264,
        -0.05046381056308746,
        -0.05529975891113281,
        0.05246103182435036,
        -0.05526605248451233,
        0.045898064970970154,
        0.015756379812955856,
        -0.011035219766199589,
        0.03207124397158623,
        0.019839229062199593,
        0.03995105251669884,
        0.04885882884263992,
        -0.041350480169057846,
        0.05312243849039078,
        -0.05515209212899208,
        -0.038263849914073944,
        0.055396489799022675,
        0.05164208263158798,
        0.0018089419463649392,
        -0.05545268952846527,
        0.007896041497588158,
        -0.04524748772382736,
        -0.05439360439777374,
        -0.035353969782590866,
        -0.05549038574099541,
        0.008264351636171341,
        0.051806963980197906,
        0.005272725131362677,
        0.045050472021102905,
        -0.054580409079790115,
        0.05162297934293747,
        0.03108147345483303,
        -0.023101940751075745,
        0.0477919802069664,
        0.055436648428440094,
        -0.01838824711740017,
        0.050583116710186005,
        0.027452504262328148,
        0.05445832386612892,
        0.030473148450255394,
        -0.05506063997745514,
        0.01915101520717144,
        -0.04001152887940407,
        -0.04649509862065315,
        -0.039824195206165314,
        0.0016893282299861312,
        -0.03956911712884903,
        -0.016861528158187866,
        -0.05196420103311539,
        0.017467746511101723,
        -0.01632479578256607,
        0.02079119347035885,
        0.04806061461567879,
        0.04673486948013306,
        0.05547519773244858,
        -0.055484287440776825,
        -0.047201208770275116,
        -0.05547965690493584,
        -0.015831060707569122,
        -0.038506001234054565,
        -0.03427007421851158,
        -0.00003973492857767269,
        0.020236950367689133,
        0.0553741380572319,
        0.05477586016058922,
        -0.05425795167684555,
        -0.020972928032279015,
        0.020342901349067688,
        -0.0544956736266613,
        0.040719207376241684,
        -0.052562274038791656,
        0.047470271587371826,
        -0.03711913526058197,
        0.05151550471782684,
        0.05464312806725502,
        0.055107489228248596,
        -0.00501287030056119,
        0.012537769041955471,
        0.033810462802648544,
        0.04631945863366127,
        -0.04089761897921562,
        0.04891634359955788,
        -0.016430851072072983,
        -0.05522089824080467,
        -0.05548772215843201,
        -0.04986157268285751,
        -0.05547083914279938,
        0.0273043941706419,
        0.05536504462361336,
        0.02275899425148964,
        0.004680945537984371,
        -0.054307423532009125,
        -0.05543443560600281,
        0.05281009152531624,
        -0.05456036329269409,
        0.005332741420716047,
        -0.05527940392494202,
        -0.04337219148874283,
        -0.05108460411429405,
        -0.027634592726826668,
        0.05443736910820007,
        -0.014111954718828201,
        -0.006639930419623852,
        -0.04567033797502518,
        -0.0535581037402153,
        -0.05263683944940567,
        0.01892225258052349,
        -0.02905295044183731,
        -0.05539797246456146,
        0.05082441493868828,
        -0.05545554310083389,
        0.05422032251954079,
        -0.02984076552093029,
        0.05403759703040123,
        0.05342356115579605,
        -0.048980988562107086,
        -0.02637561596930027,
        -0.05402474105358124,
        -0.031008508056402206,
        0.05375687777996063,
        -0.054968200623989105,
        -0.04357641935348511,
        0.04404700919985771,
        0.017875736579298973,
        -0.02960072085261345,
        0.0547148734331131,
        0.055217210203409195,
        0.021043015643954277,
        0.05318532511591911,
        0.03665200248360634,
        -0.024312090128660202,
        -0.0070951166562736034,
        0.04534248262643814,
        -0.0360906720161438,
        -0.05491593852639198,
        0.05543169006705284,
        -0.05543021857738495,
        -0.05548084154725075,
        -0.049073029309511185,
        0.05117341876029968,
        -0.05517766624689102,
        -0.05546097829937935,
        0.055230945348739624,
        -0.03802434727549553,
        0.025082716718316078,
        -0.05355421453714371,
        0.05462883040308952,
        0.05516068637371063,
        0.028335673734545708,
        -0.007368512451648712,
        -0.05391047149896622,
        -0.05367404967546463,
        -0.014695711433887482,
        -0.05423150211572647,
        0.024693986400961876,
        0.046606291085481644,
        -0.03521490842103958,
        0.0495668426156044,
        -0.049402110278606415,
        0.038813572376966476,
        0.0531916581094265,
        0.055195678025484085,
        -0.05545875430107117,
        0.05080380663275719,
        0.0410892479121685,
        0.05124599114060402,
        0.03418475389480591,
        -0.04931177198886871,
        0.05520414933562279,
        0.01952660270035267,
        0.04540133848786354,
        0.021833578124642372,
        -0.0553705058991909,
        0.054458726197481155,
        -0.05402803421020508,
        0.008380541577935219,
        -0.038104090839624405,
        -0.021643130108714104,
        -0.0002056671364698559,
        0.04823547229170799,
        -0.04608817398548126,
        0.005481410771608353,
        0.055210985243320465,
        0.0520191490650177,
        0.0538095198571682,
        0.032778333872556686,
        0.04121701046824455,
        -0.028835752978920937,
        -0.032817546278238297,
        0.05436379089951515,
        0.051229238510131836,
        -0.05310909077525139,
        0.03218657895922661,
        0.04861530289053917,
        -0.05525975301861763,
        -0.0535421222448349,
        -0.05326848104596138,
        0.050867192447185516,
        -0.05539502203464508,
        -0.04957329109311104,
        0.022258656099438667,
        -0.0285845585167408,
        0.05547940358519554,
        -0.007351053413003683,
        0.055479273200035095,
        -0.05367095395922661,
        0.0413803793489933,
        0.053414128720760345,
        -0.04693644121289253,
        -0.055490557104349136,
        0.05255231261253357,
        -0.05534803867340088,
        0.004607194568961859,
        -0.0026839047204703093,
        -0.052831020206213,
        -0.05397934094071388,
        -0.046320609748363495,
        -0.051703501492738724,
        -0.055147115141153336,
        0.0518779382109642,
        -0.03743254393339157,
        0.0222841314971447,
        0.0545484721660614,
        0.0016464723739773035,
        0.045015376061201096,
        0.05514755845069885,
        0.05263174697756767,
        -0.028447244316339493,
        0.043024562299251556,
        -0.04956489056348801,
        0.03201610594987869,
        0.017649715766310692,
        0.04239844158291817,
        0.002827683463692665,
        -0.050960324704647064,
        -0.002618566621094942,
        -0.03512108698487282,
        -0.0543975830078125,
        0.04000195860862732,
        -0.04986632987856865,
        -0.053970348089933395,
        0.055248912423849106,
        0.055030710995197296,
        -0.0484975166618824,
        0.05528510734438896,
        0.05005583539605141,
        0.019125593826174736,
        0.04999588429927826,
        -0.014436347410082817,
        0.054422542452812195,
        -0.05226808786392212,
        0.036957357078790665,
        0.05524822324514389,
        0.048310600221157074,
        0.022923307493329048,
        -0.05482815206050873,
        -0.010070075280964375,
        -0.05548248812556267,
        0.015235883183777332,
        -0.054086145013570786,
        -0.05531030148267746,
        0.013045566156506538,
        -0.054571256041526794,
        0.0040866597555577755,
        -0.04922802001237869,
        -0.038357991725206375,
        -0.05422428250312805,
        -0.055277857929468155,
        0.013729431666433811,
        0.05040378496050835,
        -0.04820740222930908,
        -0.009595632553100586,
        0.03693604841828346,
        -0.05549004301428795,
        -0.05420283228158951,
        0.04597017914056778,
        0.018692389130592346,
        0.05253615230321884,
        0.008515468798577785,
        0.05479661747813225,
        0.03622368723154068,
        -0.0549255795776844,
        0.055488135665655136,
        0.008028830401599407,
        -0.04140348732471466,
        0.054567135870456696,
        -0.053456392139196396,
        0.05401531979441643,
        0.016397753730416298,
        0.04390909522771835,
        -0.03397410362958908,
        -0.03842686861753464,
        0.03758636489510536,
        0.05097715184092522,
        0.05119393765926361,
        -0.05535281449556351,
        -0.05108319967985153,
        -0.0025304334703832865,
        -0.053969018161296844,
        0.05465538427233696,
        -0.05542566254734993,
        -0.05107782036066055,
        0.05517290160059929,
        -0.04149536043405533,
        0.023554079234600067,
        -0.04976487532258034,
        0.016812492161989212,
        0.03203744813799858,
        0.054858312010765076,
        -0.038601815700531006,
        -0.03336777165532112,
        0.050853509455919266,
        0.034066978842020035,
        -0.007170053664594889,
        -0.019338054582476616,
        -0.04947831109166145,
        0.04232167825102806,
        0.04967331141233444,
        -0.026856042444705963,
        -0.050264932215213776,
        0.048911407589912415,
        0.054700177162885666,
        -0.05270287021994591,
        0.054659098386764526,
        -0.05347735434770584,
        0.05493505299091339,
        0.05048465356230736,
        -0.05243285372853279,
        0.052266426384449005,
        -0.05179131031036377,
        0.055490583181381226,
        0.0478285513818264,
        -0.024141507223248482,
        0.04940633103251457,
        0.04192999005317688,
        0.05416206270456314,
        -0.05543636530637741,
        0.029929177835583687,
        -0.051650505512952805,
        -0.0335482656955719,
        -0.055206917226314545,
        0.05463040992617607,
        -0.05240590497851372,
        -0.0492340512573719,
        -0.0470721460878849,
        -0.05109822005033493,
        0.04551701247692108,
        0.04465467110276222,
        -0.055407214909791946,
        -0.023273000493645668,
        -0.015327616594731808,
        -0.027714557945728302,
        0.05538473278284073,
        0.01722118631005287,
        0.035152681171894073,
        -0.05541469529271126,
        -0.048578426241874695,
        -0.05483080446720123,
        0.05471695587038994,
        0.05499832332134247,
        0.015401345677673817,
        0.05425851792097092,
        -0.022723443806171417,
        0.027292171493172646,
        -0.04028216004371643,
        -0.04739684239029884,
        -0.04929858073592186,
        0.054992642253637314,
        -0.05413748696446419,
        -0.026816459372639656,
        0.052654098719358444,
        -0.05134226009249687,
        0.03847336024045944,
        -0.05541462078690529,
        -0.055324453860521317,
        -0.03571350872516632,
        -0.052701186388731,
        0.04576423391699791,
        0.030705822631716728,
        0.008943232707679272,
        -0.008237242698669434,
        0.05264640972018242,
        0.05473113805055618,
        0.054562948644161224,
        -0.05469588562846184,
        -0.055486440658569336,
        -0.04860139638185501,
        0.05513940379023552,
        0.05501847714185715,
        0.05512940511107445,
        -0.01497122086584568,
        -0.03672560676932335,
        0.05343446880578995,
        -0.022944560274481773,
        0.0543564036488533,
        -0.008286398835480213,
        0.05541772022843361,
        0.030138636007905006,
        0.003970324527472258,
        -0.006434172857552767,
        0.05502129718661308,
        -0.008063926361501217,
        0.05195942893624306,
        0.053458020091056824,
        0.02648569829761982,
        0.04286358505487442,
        -0.0526406429708004,
        -0.04374507814645767,
        -0.05488977208733559,
        -0.05280738323926926,
        0.05421096459031105,
        -0.04621199518442154,
        -0.03190573677420616,
        -0.04912778362631798,
        -0.05521797761321068,
        -0.03678923845291138,
        -0.04066481441259384,
        -0.0058583407662808895,
        -0.054440081119537354,
        0.05410633608698845,
        0.021524658426642418,
        -0.013639996759593487,
        -0.05521147698163986,
        -0.05088457465171814,
        -0.010922383517026901,
        0.028377681970596313,
        -0.02360517531633377,
        0.0544583722949028,
        0.014944770373404026,
        0.05165766924619675,
        0.052599068731069565,
        0.017781736329197884,
        0.0551333911716938,
        -0.05329162999987602,
        0.0525815449655056,
        -0.05090547725558281,
        -0.051700904965400696,
        0.05011254921555519,
        0.052949532866477966,
        0.0072570499032735825,
        0.05210399627685547,
        -0.05442456901073456,
        0.051228903234004974,
        0.04393952712416649,
        -0.053689226508140564,
        0.055344752967357635,
        0.016712378710508347,
        0.05049372464418411,
        -0.05516783893108368,
        0.009823596104979515,
        0.0545857697725296,
        0.05543321743607521,
        -0.055403389036655426,
        0.0554383248090744,
        0.013682510703802109,
        -0.05518748238682747,
        0.05279148742556572,
        -0.012739283964037895,
        0.054873280227184296,
        -0.05278018116950989,
        0.05547511577606201,
        0.02559175342321396,
        -0.03592495620250702,
        0.02975781075656414,
        -0.05277646705508232,
        -0.05362432822585106,
        -0.05046137422323227,
        -0.021308090537786484,
        0.045810770243406296,
        -0.02670232765376568,
        -0.040092501789331436,
        0.04797443747520447,
        0.044557712972164154,
        -0.00947477389127016,
        -0.05185050144791603,
        0.0547884926199913,
        -0.04951789975166321,
        -0.05255482718348503,
        0.05255638062953949,
        0.05070756748318672,
        0.04629886895418167,
        0.020761465653777122,
        -0.05514459311962128,
        -0.04406803101301193,
        0.05116860568523407,
        0.05188732221722603,
        0.05385782942175865,
        -0.03088449314236641,
        -0.04270096868276596,
        0.03924992308020592,
        -0.0062753488309681416,
        0.030734237283468246,
        -0.05387812852859497,
        0.004102884326130152,
        -0.035756196826696396,
        0.0027777799405157566,
        0.055391546338796616,
        0.05409830063581467
    ],
    [
        -0.03994124010205269,
        -0.05027543753385544,
        0.05957571789622307,
        0.04026952385902405,
        0.05823246017098427,
        -0.0031437899451702833,
        0.05892813950777054,
        -0.024271495640277863,
        -0.05593771114945412,
        0.05552195757627487,
        -0.03739355877041817,
        -0.02080017514526844,
        -0.060539375990629196,
        -0.01814868487417698,
        -0.060568541288375854,
        0.040438804775476456,
        -0.06444994360208511,
        0.05452260002493858,
        -0.0636131539940834,
        0.023180877789855003,
        -0.01669737882912159,
        0.027044378221035004,
        0.006370307877659798,
        0.03199072554707527,
        0.04652344807982445,
        0.05388806387782097,
        -0.020307481288909912,
        0.059087540954351425,
        -0.054845377802848816,
        -0.051958244293928146,
        0.05840601027011871,
        -0.0159519761800766,
        0.04675443843007088,
        -0.0010095919715240598,
        -0.05011889711022377,
        0.02965933457016945,
        -0.05520964786410332,
        -0.008605975657701492,
        -0.017186222597956657,
        0.04541080445051193,
        0.02507777512073517,
        -0.0058757234364748,
        0.059065885841846466,
        -0.060428522527217865,
        -0.032439760863780975,
        0.03063884563744068,
        -0.027059491723775864,
        0.052558716386556625,
        0.04973812773823738,
        0.06118066608905792,
        0.04689989238977432,
        -0.019800899550318718,
        0.0640328973531723,
        0.01574540138244629,
        -0.0002546680043451488,
        0.02615009807050228,
        0.027117757126688957,
        -0.04160861298441887,
        0.0010955886682495475,
        -0.04180651158094406,
        -0.062872976064682,
        -0.030729738995432854,
        -0.03819509595632553,
        0.03709496557712555,
        -0.03717482462525368,
        0.011228746734559536,
        0.06417585164308548,
        -0.05076773837208748,
        0.06356314569711685,
        -0.06430111825466156,
        -0.049929309636354446,
        -0.06428573280572891,
        -0.04805358499288559,
        0.03213266283273697,
        -0.05208770930767059,
        0.02512635849416256,
        0.05594608187675476,
        0.0616178959608078,
        0.005584089085459709,
        -0.03375167027115822,
        -0.007475452031940222,
        0.06050056219100952,
        -0.01609749160706997,
        -0.04684537649154663,
        -0.05990270525217056,
        0.007852286100387573,
        0.02004859410226345,
        0.05685551092028618,
        -0.02628309279680252,
        0.06138363108038902,
        0.021234873682260513,
        0.030420823022723198,
        0.03634311258792877,
        -0.012928208336234093,
        -0.0019056657329201698,
        0.005881265737116337,
        -0.027758615091443062,
        -0.06022857502102852,
        -0.02817319892346859,
        -0.004739814437925816,
        -0.06458152830600739,
        -0.04975662752985954,
        0.06412263214588165,
        0.02013506181538105,
        0.023280484601855278,
        -0.02400892786681652,
        -0.06196384131908417,
        0.05087278410792351,
        -0.06085378676652908,
        -0.04194195196032524,
        -0.03183074668049812,
        -0.06268223375082016,
        -0.06141139566898346,
        -0.0480593740940094,
        0.04129067808389664,
        -0.06264984607696533,
        -0.01466524600982666,
        -0.05916628986597061,
        -0.05738123878836632,
        -0.041544776409864426,
        0.03183547034859657,
        -0.053564250469207764,
        -0.009812225587666035,
        0.0566486157476902,
        -0.06464733928442001,
        -0.026549262925982475,
        -0.06194411963224411,
        0.049021657556295395,
        0.05857234075665474,
        -0.03852049633860588,
        -0.046940699219703674,
        -0.046231504529714584,
        -0.027373358607292175,
        -0.007675545290112495,
        -0.062065351754426956,
        -0.04498286545276642,
        0.054100021719932556,
        -0.053492993116378784,
        -0.05719170719385147,
        0.0620906688272953,
        0.03408845141530037,
        0.05449590086936951,
        0.0481574647128582,
        0.05382116138935089,
        -0.009724143892526627,
        -0.03402568772435188,
        0.0018736922647804022,
        -0.06157394498586655,
        -0.03859519213438034,
        0.030245551839470863,
        -0.060542356222867966,
        -0.06447596102952957,
        -0.06251487135887146,
        -0.008286610245704651,
        -0.016540059819817543,
        -0.05082761123776436,
        0.06361159682273865,
        -0.024027854204177856,
        -0.026363670825958252,
        -0.058690834790468216,
        0.06359532475471497,
        -0.004530369304120541,
        0.04722074791789055,
        -0.014499571174383163,
        -0.02791758067905903,
        -0.058084886521101,
        -0.004466457758098841,
        -0.05691804364323616,
        0.037185270339250565,
        0.04299332946538925,
        0.03561962768435478,
        0.04634156823158264,
        0.012683290056884289,
        -0.023248957470059395,
        -0.03417661786079407,
        0.05816736817359924,
        -0.06376601755619049,
        0.022609202191233635,
        0.036437518894672394,
        0.061428554356098175,
        0.06198367848992348,
        0.05543428286910057,
        0.06349202990531921,
        -0.03638845309615135,
        0.025608649477362633,
        0.032057467848062515,
        -0.062229808419942856,
        0.058737386018037796,
        -0.059353411197662354,
        -0.04608405753970146,
        0.00029377115424722433,
        -0.029593851417303085,
        -0.04488692060112953,
        0.03919781371951103,
        -0.04450333118438721,
        0.02057120017707348,
        0.04177248850464821,
        0.0343128927052021,
        0.05782371759414673,
        0.007046793587505817,
        -0.009783241897821426,
        0.024985749274492264,
        0.01117219589650631,
        -0.02185472473502159,
        0.02274974249303341,
        -0.03252127021551132,
        0.06348468363285065,
        0.0599902905523777,
        -0.061426423490047455,
        0.058337412774562836,
        -0.05582757294178009,
        0.05883796513080597,
        -0.05332530289888382,
        -0.05355284363031387,
        0.05265561863780022,
        -0.0449998639523983,
        -0.036347925662994385,
        0.0027782749384641647,
        0.06358634680509567,
        -0.05200827866792679,
        -0.012716464698314667,
        -0.01048059668391943,
        -0.019555920735001564,
        -0.06311586499214172,
        0.05419598147273064,
        -0.052409689873456955,
        -0.023199358955025673,
        0.015292978845536709,
        -0.05921493470668793,
        -0.057331349700689316,
        -0.0628574788570404,
        0.01456519030034542,
        -0.05623188614845276,
        0.047848839312791824,
        0.036807090044021606,
        -0.011256206780672073,
        0.06173446401953697,
        -0.04672835394740105,
        0.04106457531452179,
        -0.009491883218288422,
        0.05844270437955856,
        -0.021503489464521408,
        0.038786210119724274,
        -0.018797840923070908,
        -0.05081496760249138,
        0.03143367916345596,
        0.010857895947992802,
        0.021896710619330406,
        0.0472482331097126,
        0.002560892142355442,
        0.019598642364144325,
        -0.06318347901105881,
        -0.036308664828538895,
        -0.05568031966686249,
        -0.012803173623979092,
        0.05788290128111839,
        0.0008591312216594815,
        0.049896977841854095,
        0.06456229835748672,
        0.062344834208488464,
        0.04600488021969795,
        0.036780912429094315,
        0.03545195981860161,
        0.061746060848236084,
        0.03420659154653549,
        -0.014486486092209816,
        0.05587826669216156,
        -0.026080548763275146,
        0.05561608448624611,
        -0.04991525039076805,
        -0.05624224245548248,
        -0.06354959309101105,
        0.03351203724741936,
        -0.05609828606247902,
        -0.06322593986988068,
        0.04843651130795479,
        -0.06236334517598152,
        -0.004868664778769016,
        -0.051893509924411774,
        -0.01898556388914585,
        -0.039396222680807114,
        -0.03403721749782562,
        -0.028383413329720497,
        0.007248749490827322,
        -0.05318182706832886,
        -0.01221044734120369,
        -0.008589208126068115,
        -0.05623413249850273,
        -0.0541866160929203,
        0.014274811372160912,
        -0.01387721672654152,
        0.03647909313440323,
        0.05213838443160057,
        0.05364809185266495,
        0.035997651517391205,
        -0.0506158322095871,
        0.0647001788020134,
        -0.0527670681476593,
        -0.006979856640100479,
        0.05373423546552658,
        0.030255669727921486,
        0.05998620018362999,
        -0.03142901137471199,
        0.05052512511610985,
        -0.048608697950839996,
        -0.025162937119603157,
        0.0011810403084382415,
        0.010459380224347115,
        0.04617980495095253,
        -0.060102205723524094,
        -0.02291691116988659,
        0.05669056251645088,
        -0.025177378207445145,
        0.0599503368139267,
        -0.06406503170728683,
        -0.04352230206131935,
        0.06129375845193863,
        -0.011587080545723438,
        0.0006845603929832578,
        -0.043755579739809036,
        -0.030052902176976204,
        -0.054744571447372437,
        0.05769496411085129,
        -0.055844586342573166,
        0.04601254686713219,
        0.03856664523482323,
        -0.02633565664291382,
        0.027740854769945145,
        -0.033169910311698914,
        -0.045183390378952026,
        -0.04561192914843559,
        0.03997313231229782,
        0.017910080030560493,
        -0.030664434656500816,
        0.06106875091791153,
        0.033049508929252625,
        0.002524610608816147,
        0.06460991501808167,
        -0.02365073561668396,
        0.06008318066596985,
        0.05958840996026993,
        -0.03576710447669029,
        -0.049740325659513474,
        -0.05057749152183533,
        0.06474368274211884,
        0.0070378719829022884,
        -0.03355991095304489,
        0.006936575286090374,
        -0.0023423831444233656,
        0.061724528670310974,
        -0.06472867727279663,
        -0.010733682662248611,
        -0.04279298335313797,
        -0.06097625568509102,
        -0.05675279721617699,
        0.06398060917854309,
        -0.04477231204509735,
        -0.010587952099740505,
        -0.01121405977755785,
        -0.06131761893630028,
        0.05969315767288208,
        0.05437355488538742,
        -0.06457460671663284,
        0.010276945307850838,
        0.05485868081450462,
        -0.04282965883612633,
        0.06299183517694473,
        0.015222259797155857,
        -0.026502929627895355,
        -0.06461203098297119,
        -0.04883560910820961,
        -0.0630367249250412,
        0.05732525512576103,
        0.0580388568341732,
        0.021524578332901,
        0.04900838062167168,
        0.059045370668172836,
        0.04174347221851349,
        -0.01421505119651556,
        -0.04208828881382942,
        -0.035283412784338,
        0.03932133689522743,
        -0.05769108980894089,
        -0.015465489588677883,
        0.06291145831346512,
        -0.00461080064997077,
        -0.0018081554444506764,
        -0.0636950433254242,
        -0.056230008602142334,
        -0.053202416747808456,
        0.04872334748506546,
        0.024267787113785744,
        0.03220343589782715,
        0.043010953813791275,
        -0.022763557732105255,
        0.05967211723327637,
        0.06198657676577568,
        0.05884693190455437,
        -0.05213082209229469,
        -0.06356877088546753,
        -0.027033047750592232,
        0.04239501804113388,
        0.04363694041967392,
        0.06163176894187927,
        -0.006178959738463163,
        0.022876542061567307,
        0.03902647644281387,
        0.03365923464298248,
        0.05897241085767746,
        -0.027157559990882874,
        0.062070611864328384,
        -0.05164023116230965,
        0.030191559344530106,
        -0.038032855838537216,
        0.05768689140677452,
        0.033964429050683975,
        0.06068224832415581,
        0.008823856711387634,
        0.01926352269947529,
        0.058993324637413025,
        -0.057699479162693024,
        -0.05641120672225952,
        -0.003207323607057333,
        -0.036772266030311584,
        0.002589988987892866,
        -0.020829210057854652,
        -0.009683086536824703,
        -0.013131239451467991,
        -0.06302258372306824,
        0.021120300516486168,
        0.006539333611726761,
        -0.027883879840373993,
        -0.025933148339390755,
        0.05037568882107735,
        0.06278035044670105,
        0.020138150081038475,
        -0.056345608085393906,
        0.040152374655008316,
        0.02868683822453022,
        -0.061212003231048584,
        0.04613513872027397,
        0.040172331035137177,
        0.05947775021195412,
        0.050971467047929764,
        0.06137409433722496,
        0.01786969043314457,
        0.06224188208580017,
        -0.058556344360113144,
        0.05935623124241829,
        -0.06382303684949875,
        -0.00891165342181921,
        -0.008107367902994156,
        0.019683292135596275,
        0.006106846034526825,
        0.01995544135570526,
        -0.041569627821445465,
        0.017650676891207695,
        0.05659942701458931,
        0.009694980457425117,
        0.044487059116363525,
        0.04670616239309311,
        0.0002974416420329362,
        -0.06429236382246017,
        0.015252345241606236,
        -0.033465974032878876,
        0.06415967643260956,
        -0.06429977715015411,
        0.06262174993753433,
        -0.032545559108257294,
        -0.05847860500216484,
        0.04292415827512741,
        -0.04832091182470322,
        0.05959975719451904,
        0.03928456827998161,
        0.06389657407999039,
        0.007236376870423555,
        -0.034341711550951004,
        0.051399268209934235,
        -0.007712121121585369,
        -0.04159349575638771,
        -0.048294998705387115,
        -0.056710753589868546,
        0.009845936670899391,
        -0.02396591752767563,
        0.028744395822286606,
        -0.006022640038281679,
        -0.0016244994476437569,
        -0.0415516123175621,
        -0.05488038808107376,
        0.032271236181259155,
        -0.039918914437294006,
        -0.04382001981139183,
        -0.00730173708871007,
        0.04211990162730217,
        0.06071101874113083,
        0.04262789711356163,
        -0.023285221308469772,
        -0.042975738644599915,
        0.05715208128094673,
        0.05200943350791931,
        -0.05405296012759209,
        -0.06225894019007683,
        0.04437181353569031,
        0.013302462175488472,
        0.012559042312204838,
        -0.0010458790929988027,
        -0.05703417956829071,
        -0.026731379330158234,
        -0.06357219815254211,
        -0.055394530296325684,
        0.06364366412162781,
        0.05341830477118492
    ],
    [
        -0.046089671552181244,
        -0.045308396220207214,
        0.06147242709994316,
        0.03896655514836311,
        0.05888475850224495,
        0.007920965552330017,
        0.056723643094301224,
        -0.039769697934389114,
        -0.05846957117319107,
        0.06093151867389679,
        -0.038098398596048355,
        -0.03763120248913765,
        -0.059370771050453186,
        -0.019857998937368393,
        -0.0595303550362587,
        0.025187721475958824,
        -0.06428077816963196,
        0.047467269003391266,
        -0.0634763166308403,
        0.03667736425995827,
        0.004639138467609882,
        0.007395239546895027,
        0.00616768142208457,
        0.024301040917634964,
        0.03739508241415024,
        0.03778256103396416,
        -0.03251861408352852,
        0.058854520320892334,
        -0.05399414151906967,
        -0.053411342203617096,
        0.05709773302078247,
        -0.003167747985571623,
        0.051842644810676575,
        0.009157082997262478,
        -0.04502354934811592,
        0.02392914891242981,
        -0.056422971189022064,
        -0.026021946221590042,
        -0.04513615742325783,
        0.04770229384303093,
        0.01064741425216198,
        0.018346969038248062,
        0.05835765600204468,
        -0.055369067937135696,
        -0.03915433585643768,
        0.025941073894500732,
        -0.01947030797600746,
        0.048248447477817535,
        0.05670339986681938,
        0.05883702263236046,
        0.04441183805465698,
        -0.013690650463104248,
        0.06407331675291061,
        0.027603069320321083,
        0.002723245183005929,
        0.030904220417141914,
        0.01034664548933506,
        -0.03853923827409744,
        -0.011147554032504559,
        -0.04735119268298149,
        -0.06350225955247879,
        -0.03836386650800705,
        -0.04492032155394554,
        0.02026795595884323,
        -0.040279000997543335,
        0.022347794845700264,
        0.06363989412784576,
        -0.04764588549733162,
        0.06350842863321304,
        -0.06412409991025925,
        -0.03948298469185829,
        -0.06418636441230774,
        -0.04891401156783104,
        0.031212380155920982,
        -0.054091423749923706,
        0.016485558822751045,
        0.05488089099526405,
        0.062000572681427,
        0.001795175950974226,
        -0.03919105976819992,
        -0.004635024815797806,
        0.06094373017549515,
        -0.03547948971390724,
        -0.04545006528496742,
        -0.05500943586230278,
        0.013729410246014595,
        0.013402036391198635,
        0.05554986372590065,
        -0.012000964023172855,
        0.05908411368727684,
        0.01866805925965309,
        0.04378892481327057,
        0.04160766303539276,
        -0.012090026400983334,
        -0.00542672211304307,
        0.0186636820435524,
        -0.04356943815946579,
        -0.06095873937010765,
        -0.033261630684137344,
        -0.016366517171263695,
        -0.06420012563467026,
        -0.05390232428908348,
        0.06371218711137772,
        0.02112397737801075,
        0.03687373548746109,
        -0.006881586741656065,
        -0.06108619272708893,
        0.052593741565942764,
        -0.06227349862456322,
        -0.04399716109037399,
        -0.027868326753377914,
        -0.061481330543756485,
        -0.06256911903619766,
        -0.046327102929353714,
        0.04500124603509903,
        -0.0629548728466034,
        -0.0019575392361730337,
        -0.057296838611364365,
        -0.056842051446437836,
        -0.03274412080645561,
        0.03319741412997246,
        -0.04822031036019325,
        -0.004250096157193184,
        0.0595155693590641,
        -0.06438204646110535,
        -0.026861606165766716,
        -0.062295541167259216,
        0.04595835134387016,
        0.056948672980070114,
        -0.04105834662914276,
        -0.050332773476839066,
        -0.048827677965164185,
        -0.03516627103090286,
        0.004186138976365328,
        -0.061319101601839066,
        -0.04998498409986496,
        0.04312141239643097,
        -0.05428178608417511,
        -0.058501601219177246,
        0.06239371374249458,
        0.04982577636837959,
        0.04706079140305519,
        0.04868803918361664,
        0.042914751917123795,
        -0.013519673608243465,
        0.0009120070608332753,
        0.013010160066187382,
        -0.060172878205776215,
        -0.04151645302772522,
        0.03635135293006897,
        -0.060994550585746765,
        -0.06397410482168198,
        -0.06298371404409409,
        -0.01790325529873371,
        0.006016993895173073,
        -0.0492049939930439,
        0.06300591677427292,
        -0.012326867319643497,
        -0.029212987050414085,
        -0.061073414981365204,
        0.06347130984067917,
        -0.0069323815405368805,
        0.04464380815625191,
        0.002162002492696047,
        -0.015326856635510921,
        -0.05863526836037636,
        0.00016832431720104069,
        -0.05573945865035057,
        0.02540254406630993,
        0.03961867094039917,
        0.03349696844816208,
        0.05057640001177788,
        0.016860807314515114,
        -0.022683734074234962,
        -0.02707715705037117,
        0.061407554894685745,
        -0.06362545490264893,
        0.022323986515402794,
        0.029938269406557083,
        0.06133051589131355,
        0.06253595650196075,
        0.05609670281410217,
        0.06322802603244781,
        -0.027490725740790367,
        0.001889226259663701,
        0.037296537309885025,
        -0.06182481348514557,
        0.0580548495054245,
        -0.05944665148854256,
        -0.03732113540172577,
        -0.013049757108092308,
        -0.02190122939646244,
        -0.05157105252146721,
        0.023674698546528816,
        -0.05256061628460884,
        0.0394902229309082,
        0.047196801751852036,
        0.044137004762887955,
        0.05823874846100807,
        0.010187258943915367,
        0.022083427757024765,
        0.03242966905236244,
        0.02740275114774704,
        -0.023403363302350044,
        0.02693701907992363,
        -0.03344963863492012,
        0.06343872845172882,
        0.06046495959162712,
        -0.0606997050344944,
        0.05635230988264084,
        -0.054995834827423096,
        0.05105080455541611,
        -0.05904478207230568,
        -0.05159470811486244,
        0.05081919580698013,
        -0.05213212966918945,
        -0.021968480199575424,
        0.000630719237960875,
        0.06303852796554565,
        -0.0464027002453804,
        -0.005059558432549238,
        -0.017029253765940666,
        -0.018119173124432564,
        -0.06270715594291687,
        0.05417235568165779,
        -0.05163782089948654,
        -0.01949198730289936,
        0.010963305830955505,
        -0.05443772301077843,
        -0.059639815241098404,
        -0.06229323148727417,
        -0.0036501691211014986,
        -0.05546737462282181,
        0.04554055258631706,
        0.04280523583292961,
        0.014919394627213478,
        0.06138944998383522,
        -0.053588636219501495,
        0.05032080411911011,
        0.011369166895747185,
        0.06113919988274574,
        -0.039595577865839005,
        0.024993235245347023,
        -0.024596748873591423,
        -0.046597521752119064,
        0.03328964486718178,
        0.024993110448122025,
        0.036595579236745834,
        0.04734077677130699,
        0.011130325496196747,
        0.012300294823944569,
        -0.06301074475049973,
        -0.04579866677522659,
        -0.056272923946380615,
        -0.019199853762984276,
        0.0591912642121315,
        0.0076479241251945496,
        0.04601273685693741,
        0.06428337842226028,
        0.06166594848036766,
        0.04033306613564491,
        0.03739689663052559,
        0.02091355063021183,
        0.05780447646975517,
        0.03134285286068916,
        0.008387811481952667,
        0.056199174374341965,
        -0.025533225387334824,
        0.056872837245464325,
        -0.04299403727054596,
        -0.0482788011431694,
        -0.0632195696234703,
        0.01485662441700697,
        -0.05594947934150696,
        -0.06373295187950134,
        0.0437038317322731,
        -0.06234714761376381,
        -0.0023406515829265118,
        -0.046376846730709076,
        -0.013674724847078323,
        -0.04455024376511574,
        -0.04249092563986778,
        -0.023620005697011948,
        -0.012502086348831654,
        -0.058080803602933884,
        -0.018891591578722,
        -0.014702347107231617,
        -0.05353266000747681,
        -0.05429033190011978,
        0.021702270954847336,
        -0.004022310022264719,
        0.04823848605155945,
        0.04112941771745682,
        0.05121682211756706,
        0.02694234624505043,
        -0.052144814282655716,
        0.06445547938346863,
        -0.049670957028865814,
        -0.01755465753376484,
        0.05261523649096489,
        0.024616215378046036,
        0.05934638902544975,
        -0.0253068208694458,
        0.04443783313035965,
        -0.053146012127399445,
        -0.03135596960783005,
        0.003451061900705099,
        -0.008779328316450119,
        0.04453585669398308,
        -0.06179581210017204,
        -0.0062355115078389645,
        0.05175544321537018,
        -0.04223069176077843,
        0.05905725061893463,
        -0.06384693086147308,
        -0.05278202146291733,
        0.062292493879795074,
        -0.009766693226993084,
        -0.01409115083515644,
        -0.050094328820705414,
        -0.011338147334754467,
        -0.05514222010970116,
        0.060347601771354675,
        -0.05151132866740227,
        0.05126175656914711,
        0.04228043556213379,
        -0.025165915489196777,
        0.036969415843486786,
        -0.015606440603733063,
        -0.03287328779697418,
        -0.04790644347667694,
        0.03738962113857269,
        0.014720985665917397,
        -0.04013487696647644,
        0.06046976521611214,
        0.03377199545502663,
        0.007499246392399073,
        0.06441499292850494,
        -0.0105684669688344,
        0.05989804491400719,
        0.05710243433713913,
        -0.03381277993321419,
        -0.05456157028675079,
        -0.03848851099610329,
        0.06449873000383377,
        0.0024402837734669447,
        -0.035931237041950226,
        -0.006409838795661926,
        -0.011617742478847504,
        0.06260358542203903,
        -0.06448901444673538,
        -0.017622359097003937,
        -0.052361927926540375,
        -0.061271652579307556,
        -0.05472991615533829,
        0.06367561966180801,
        -0.022931523621082306,
        0.00014671510143671185,
        -0.007654262240976095,
        -0.06102318316698074,
        0.05982339382171631,
        0.05205228552222252,
        -0.06423135846853256,
        0.010468809865415096,
        0.055979590862989426,
        -0.04432663321495056,
        0.06316956132650375,
        0.0029995448421686888,
        -0.023567264899611473,
        -0.06437866389751434,
        -0.05241511017084122,
        -0.06271930783987045,
        0.0567772351205349,
        0.05573907494544983,
        -0.00006367535388562828,
        0.0527086965739727,
        0.05746814236044884,
        0.04018519073724747,
        -0.027285220101475716,
        -0.04950973764061928,
        -0.04316583648324013,
        0.025858379900455475,
        -0.0570758618414402,
        -0.01077550370246172,
        0.06331957131624222,
        -0.016199324280023575,
        0.014836255460977554,
        -0.06356225907802582,
        -0.053615082055330276,
        -0.05346333608031273,
        0.044537872076034546,
        0.03000478260219097,
        0.023589273914694786,
        0.029652828350663185,
        -0.02156427688896656,
        0.05895848572254181,
        0.06175343692302704,
        0.05847150459885597,
        -0.04116038978099823,
        -0.06356942653656006,
        -0.02516118437051773,
        0.032064568251371384,
        0.0444893054664135,
        0.06146528944373131,
        -0.015158645808696747,
        -0.0041020456701517105,
        0.04526408761739731,
        0.028315596282482147,
        0.06218145787715912,
        -0.01005613524466753,
        0.06036592647433281,
        -0.05087578669190407,
        0.01638214662671089,
        -0.028878936544060707,
        0.05885356292128563,
        0.03284023329615593,
        0.0593612976372242,
        0.029432741925120354,
        0.020068619400262833,
        0.055086422711610794,
        -0.05948290973901749,
        -0.05802720785140991,
        -0.010819051414728165,
        -0.0381774865090847,
        0.008169513195753098,
        -0.014607948251068592,
        -0.02297406643629074,
        -0.012131836265325546,
        -0.062070563435554504,
        0.02272104099392891,
        0.006804249715059996,
        -0.03847753256559372,
        -0.02782631106674671,
        0.04822171479463577,
        0.06260233372449875,
        0.017387036234140396,
        -0.05359244719147682,
        0.029272930696606636,
        0.038705550134181976,
        -0.0613776259124279,
        0.049639396369457245,
        0.0399397611618042,
        0.06026484817266464,
        0.053337112069129944,
        0.06144379451870918,
        0.030744921416044235,
        0.0628138929605484,
        -0.060670461505651474,
        0.06026295945048332,
        -0.06357888132333755,
        -0.002559618791565299,
        -0.01805124059319496,
        0.023372448980808258,
        0.018226560205221176,
        0.02384188584983349,
        -0.04320724308490753,
        0.00812715757638216,
        0.05807153880596161,
        -0.004264351446181536,
        0.04595514014363289,
        0.050294339656829834,
        -0.0024532554671168327,
        -0.06383185088634491,
        0.00003063961412408389,
        -0.045808516442775726,
        0.06404511630535126,
        -0.06389116495847702,
        0.06199232116341591,
        -0.008465616032481194,
        -0.06056031957268715,
        0.022389031946659088,
        -0.054453108459711075,
        0.054269373416900635,
        0.04865244776010513,
        0.06389572471380234,
        0.01743757724761963,
        -0.039038609713315964,
        0.0542692095041275,
        -0.00864422507584095,
        -0.03590982034802437,
        -0.04515949636697769,
        -0.05848364159464836,
        0.0037589706480503082,
        -0.01660573109984398,
        0.03463989496231079,
        -0.020694337785243988,
        0.006159234791994095,
        -0.04957070201635361,
        -0.054899830371141434,
        0.022611740976572037,
        -0.0459967665374279,
        -0.04639187082648277,
        -0.02280687913298607,
        0.04529343172907829,
        0.06248040869832039,
        0.044988397508859634,
        -0.03247535601258278,
        -0.026706015691161156,
        0.058387380093336105,
        0.052258048206567764,
        -0.05529032647609711,
        -0.06056838855147362,
        0.04665082320570946,
        0.019252879545092583,
        0.025609351694583893,
        -0.012535669840872288,
        -0.06026507914066315,
        -0.028184272348880768,
        -0.06321602314710617,
        -0.054275985807180405,
        0.06380334496498108,
        0.05795104056596756
    ],
    [
        -0.04205575957894325,
        -0.05878182128071785,
        -0.012497481890022755,
        0.04313838854432106,
        0.05044730380177498,
        -0.04242892190814018,
        0.05913877114653587,
        -0.039200253784656525,
        -0.00795618537813425,
        0.05675638094544411,
        0.05533026531338692,
        0.05188721418380737,
        -0.05779483541846275,
        -0.03677310794591904,
        -0.05776846408843994,
        -0.037724632769823074,
        -0.05829079449176788,
        0.05653804913163185,
        -0.056825149804353714,
        0.029434608295559883,
        -0.030179759487509727,
        -0.03454048931598663,
        0.03872312605381012,
        0.029562657698988914,
        0.0275193452835083,
        0.045167744159698486,
        0.03286365792155266,
        0.05390573665499687,
        -0.05640852823853493,
        -0.055756162852048874,
        0.05170397832989693,
        0.04717874899506569,
        0.008481290191411972,
        -0.05465893819928169,
        -0.007508293259888887,
        0.048962853848934174,
        -0.05165679007768631,
        0.045132480561733246,
        -0.057579703629016876,
        0.05280661582946777,
        0.04963676631450653,
        -0.014291133731603622,
        0.05384274572134018,
        -0.05258435755968094,
        0.056803178042173386,
        0.03796539828181267,
        0.05409763380885124,
        0.058205585926771164,
        0.05897056683897972,
        -0.005503923632204533,
        0.04919443652033806,
        -0.015497456304728985,
        0.058522503823041916,
        0.043678440153598785,
        -0.03533516451716423,
        -0.041249729692935944,
        0.0069662900641560555,
        -0.03811521455645561,
        -0.05149316042661667,
        -0.03145746514201164,
        -0.05577168986201286,
        0.0029576446395367384,
        -0.05481722578406334,
        0.019305184483528137,
        -0.017951374873518944,
        0.04654369875788689,
        0.05439503490924835,
        -0.011348065920174122,
        0.042967651039361954,
        -0.05922126770019531,
        -0.05100911483168602,
        -0.058966610580682755,
        0.0292273610830307,
        0.05019361898303032,
        -0.04041219502687454,
        0.0089083481580019,
        0.002594025107100606,
        0.058970484882593155,
        0.05679558962583542,
        -0.038120485842227936,
        -0.0063831075094640255,
        0.03727012127637863,
        -0.056841082870960236,
        0.02783064916729927,
        -0.024157656356692314,
        0.043900713324546814,
        0.01619725674390793,
        0.053051576018333435,
        0.027634672820568085,
        0.05830636993050575,
        -0.03544009104371071,
        0.0387805737555027,
        0.031238414347171783,
        -0.01879187859594822,
        -0.04198291152715683,
        -0.035158682614564896,
        0.0012219614582136273,
        -0.05879603698849678,
        -0.058689508587121964,
        -0.03425634279847145,
        -0.05841854214668274,
        0.01450748648494482,
        0.05830987170338631,
        -0.014587871730327606,
        0.0475730262696743,
        -0.05717621371150017,
        -0.05802176520228386,
        0.0546887144446373,
        -0.057086020708084106,
        -0.005491761490702629,
        -0.05714630335569382,
        -0.05839427188038826,
        -0.05094550549983978,
        -0.05385855212807655,
        0.033965013921260834,
        -0.05365310609340668,
        -0.014440717175602913,
        -0.053332969546318054,
        -0.05848391354084015,
        -0.054780300706624985,
        0.0441230945289135,
        -0.03341612592339516,
        -0.04013426974415779,
        0.04951370880007744,
        -0.05881388485431671,
        -0.004257858265191317,
        -0.048602037131786346,
        0.05353833734989166,
        0.05529182776808739,
        -0.058254536241292953,
        -0.011777387000620365,
        -0.054182473570108414,
        -0.04542544111609459,
        0.05265083163976669,
        -0.05599689856171608,
        -0.04020099341869354,
        0.05509033426642418,
        -0.05361787602305412,
        -0.047945789992809296,
        0.05856240913271904,
        0.05606849119067192,
        0.01836654730141163,
        0.05180726572871208,
        0.05730903893709183,
        -0.0450737439095974,
        0.058029841631650925,
        0.045527588576078415,
        -0.05486663430929184,
        -0.05401819571852684,
        0.04009724035859108,
        -0.05647558346390724,
        -0.05916183814406395,
        -0.05734575539827347,
        0.01938246563076973,
        -0.03562191501259804,
        -0.05865606293082237,
        0.05410176143050194,
        -0.0130107207223773,
        -0.05427924543619156,
        -0.05082574486732483,
        0.05812946707010269,
        0.009436137042939663,
        0.03613853082060814,
        0.01692764274775982,
        0.006850987672805786,
        -0.04951326921582222,
        0.0206036027520895,
        -0.05248328298330307,
        0.0035183581057935953,
        0.04427243396639824,
        -0.01787848211824894,
        0.05479208007454872,
        -0.056775934994220734,
        -0.04774811118841171,
        -0.0292048379778862,
        0.056588418781757355,
        -0.05918180197477341,
        -0.03664154186844826,
        0.04331508278846741,
        0.05397109314799309,
        0.055923834443092346,
        -0.021933171898126602,
        0.05891168862581253,
        -0.040114790201187134,
        0.028661608695983887,
        -0.0052343434654176235,
        -0.058571960777044296,
        0.05539846420288086,
        -0.04578174650669098,
        -0.03158494085073471,
        -0.05603262409567833,
        -0.029440753161907196,
        -0.030907047912478447,
        0.00027188085368834436,
        -0.019225919619202614,
        0.023775072768330574,
        0.05884133279323578,
        0.044255249202251434,
        0.04880162701010704,
        0.04982588440179825,
        0.013288909569382668,
        0.05299174413084984,
        -0.031017815694212914,
        0.04705040156841278,
        0.011145840398967266,
        0.030935587361454964,
        0.041949231177568436,
        0.0447479747235775,
        -0.05709443613886833,
        0.035830847918987274,
        -0.0521002933382988,
        0.028498640283942223,
        -0.03715366870164871,
        -0.04310736805200577,
        0.021974684670567513,
        -0.050890594720840454,
        0.055146463215351105,
        -0.04222273454070091,
        0.05899269878864288,
        -0.0316765159368515,
        0.036359019577503204,
        -0.021460821852087975,
        -0.043938878923654556,
        -0.0583406537771225,
        0.05722387507557869,
        -0.05867759883403778,
        0.009152565151453018,
        0.021463047713041306,
        -0.019873030483722687,
        -0.056945737451314926,
        -0.04631420969963074,
        -0.01596299186348915,
        -0.05740763992071152,
        0.04551737755537033,
        0.010720837861299515,
        0.029769420623779297,
        0.05722610279917717,
        -0.025747235864400864,
        0.01785353012382984,
        0.05645418167114258,
        0.05787235498428345,
        0.021411167457699776,
        0.01213783212006092,
        -0.038478199392557144,
        0.020064812153577805,
        0.002982994308695197,
        0.0483931340277195,
        0.05061997473239899,
        0.02342722751200199,
        0.011934192851185799,
        0.033233892172575,
        -0.05828149616718292,
        -0.052976422011852264,
        -0.0571981780230999,
        -0.005152134690433741,
        0.05847811698913574,
        -0.007903655990958214,
        0.03324597328901291,
        0.05900739133358002,
        0.05695724114775658,
        0.030519425868988037,
        0.03976380079984665,
        0.05395380035042763,
        0.05791190639138222,
        -0.03734120354056358,
        0.049678683280944824,
        0.04053886607289314,
        -0.04054388403892517,
        0.046355657279491425,
        -0.01779993437230587,
        -0.05187489092350006,
        -0.05816449224948883,
        -0.035242240875959396,
        -0.058398328721523285,
        -0.05845697224140167,
        0.036765679717063904,
        -0.05781953036785126,
        -0.0003437774139456451,
        0.03611397743225098,
        -0.05446630343794823,
        -0.05701602250337601,
        -0.03404226526618004,
        -0.03300657123327255,
        -0.02209603041410446,
        0.004410996567457914,
        -0.034594181925058365,
        0.0164803434163332,
        -0.05886159464716911,
        -0.053215108811855316,
        0.04968016594648361,
        -0.03528028726577759,
        0.04532063379883766,
        0.038930267095565796,
        0.039690934121608734,
        0.030882837250828743,
        -0.0565391443669796,
        0.059163499623537064,
        -0.00672187888994813,
        -0.027832642197608948,
        0.05652492493391037,
        0.03202615678310394,
        0.05855236202478409,
        -0.008383648470044136,
        0.05130843073129654,
        0.02857385389506817,
        -0.04080991819500923,
        -0.047590870410203934,
        0.009036673232913017,
        0.05394158884882927,
        -0.05892174318432808,
        -0.02176913619041443,
        -0.01714354380965233,
        -0.022203683853149414,
        0.054929330945014954,
        -0.0589803010225296,
        -0.04883795604109764,
        0.05270465463399887,
        0.005660269409418106,
        0.02163035236299038,
        -0.0069436077028512955,
        -0.017535056918859482,
        0.009889243170619011,
        0.05651822313666344,
        -0.04253185912966728,
        0.05422332510352135,
        0.0567830353975296,
        0.052661363035440445,
        0.04005933180451393,
        0.003837116062641144,
        -0.05825364217162132,
        0.009755758568644524,
        0.04021729901432991,
        -0.008567656390368938,
        -0.05622835084795952,
        0.056648921221494675,
        0.03700191527605057,
        -0.044557832181453705,
        0.05896446853876114,
        -0.008418834768235683,
        0.04239002615213394,
        0.054239481687545776,
        -0.04035140946507454,
        0.03231250122189522,
        -0.05674612522125244,
        0.05924500152468681,
        0.043383389711380005,
        -0.01063914131373167,
        0.04436664283275604,
        -0.007395606022328138,
        0.05847812071442604,
        -0.05921930819749832,
        0.04596884176135063,
        -0.04461268335580826,
        -0.05690944194793701,
        -0.0343577116727829,
        0.053058039397001266,
        -0.05678950250148773,
        -0.04703216254711151,
        -0.05561193823814392,
        -0.05847078561782837,
        0.04829353094100952,
        0.05461009219288826,
        -0.05914251133799553,
        -0.030711887404322624,
        0.012901805341243744,
        -0.028865523636341095,
        0.058906275779008865,
        -0.016607625409960747,
        0.031955569982528687,
        -0.05904663726687431,
        -0.0502900704741478,
        -0.057685401290655136,
        0.05439455062150955,
        0.049837157130241394,
        0.0521659329533577,
        0.046032845973968506,
        -0.05256946384906769,
        0.020838677883148193,
        -0.005540287587791681,
        -0.04785008728504181,
        -0.0413912758231163,
        0.05826416611671448,
        -0.05781247466802597,
        -0.017393944784998894,
        0.05449482426047325,
        -0.024653267115354538,
        0.01100640557706356,
        -0.05881180614233017,
        -0.05233361944556236,
        -0.0560612827539444,
        -0.04866964370012283,
        0.051583293825387955,
        0.002840843517333269,
        0.04807488992810249,
        -0.05095558986067772,
        0.05708375200629234,
        0.052197445183992386,
        0.05274904519319534,
        -0.0234015304595232,
        -0.05917089805006981,
        -0.01917615346610546,
        0.05463206022977829,
        0.058180637657642365,
        0.058540139347314835,
        -0.017649076879024506,
        0.02566569298505783,
        0.03708101436495781,
        -0.0497298464179039,
        0.05779433995485306,
        -0.02719804272055626,
        0.05733807757496834,
        0.011821609921753407,
        0.05073840171098709,
        -0.02603483758866787,
        0.05155516415834427,
        -0.009011282585561275,
        0.013883842155337334,
        -0.023151367902755737,
        0.05310698598623276,
        0.0482230968773365,
        -0.020736094564199448,
        -0.052868906408548355,
        -0.04548206925392151,
        -0.0573052242398262,
        0.04211023822426796,
        -0.04622591286897659,
        0.011770704761147499,
        -0.04215947166085243,
        -0.05835798382759094,
        -0.043564122170209885,
        0.05157208442687988,
        -0.011949850246310234,
        -0.021683789789676666,
        0.027188390493392944,
        0.042103201150894165,
        -0.03916522115468979,
        -0.05873764306306839,
        0.04720798134803772,
        -0.00003151398414047435,
        -0.04333912208676338,
        -0.0027851019985973835,
        0.02406916953623295,
        0.053469218313694,
        0.057564627379179,
        0.04298816993832588,
        0.0518130324780941,
        0.05773262307047844,
        -0.050241176038980484,
        0.05530679225921631,
        0.003033117623999715,
        -0.053380586206912994,
        0.05715165659785271,
        0.0529385469853878,
        -0.03655380383133888,
        -0.05110696330666542,
        -0.054524485021829605,
        0.05189969390630722,
        0.05276035517454147,
        -0.011182391084730625,
        0.057731419801712036,
        0.008474484086036682,
        -0.005766334943473339,
        -0.05570049211382866,
        0.04467448592185974,
        0.05690363422036171,
        0.05897324159741402,
        -0.059188466519117355,
        0.05847758799791336,
        -0.04705597460269928,
        -0.058597683906555176,
        0.04295271635055542,
        -0.04597938805818558,
        0.04972746595740318,
        0.04806932434439659,
        0.059207066893577576,
        0.010104234330356121,
        0.048189111053943634,
        0.03902236744761467,
        -0.04003432020545006,
        -0.02794918604195118,
        -0.052790895104408264,
        -0.03741209954023361,
        0.05634160712361336,
        0.020575901493430138,
        -0.03225439786911011,
        0.014794139191508293,
        0.05720582976937294,
        0.004297983832657337,
        -0.05581321939826012,
        0.026975437998771667,
        -0.04767221584916115,
        -0.0565771609544754,
        -0.021690282970666885,
        0.0416974276304245,
        0.05277532339096069,
        0.01112146582454443,
        -0.05829230695962906,
        -0.01963934302330017,
        0.05580754578113556,
        0.056337788701057434,
        -0.004538897890597582,
        -0.04914652556180954,
        0.0001611704210517928,
        0.049828480929136276,
        0.05383399873971939,
        0.04731043055653572,
        -0.0401899628341198,
        -0.0099398298189044,
        -0.053989510983228683,
        -0.056241102516651154,
        0.05918773263692856,
        0.054211318492889404
    ],
    [
        -0.050766825675964355,
        -0.05497068539261818,
        0.014402737841010094,
        -0.0042228237725794315,
        -0.04846947267651558,
        -0.045039817690849304,
        0.052379172295331955,
        -0.056547291576862335,
        0.011633459478616714,
        -0.04495298117399216,
        0.044498324394226074,
        0.045097459107637405,
        -0.05509163439273834,
        -0.04515297710895538,
        -0.056942012161016464,
        -0.033243708312511444,
        -0.05595114082098007,
        0.0569777749478817,
        -0.002136038616299629,
        0.009459706023335457,
        -0.048468030989170074,
        0.008329455740749836,
        0.05568002536892891,
        -0.037442896515131,
        -0.010472539812326431,
        0.05449738726019859,
        0.04162084311246872,
        0.05194544792175293,
        -0.0045060692355036736,
        -0.051511719822883606,
        0.052793946117162704,
        -0.010643417946994305,
        0.048977650701999664,
        -0.05738658830523491,
        0.056024618446826935,
        -0.04799745976924896,
        -0.05193581059575081,
        -0.03134487196803093,
        -0.05196505784988403,
        -0.050530750304460526,
        0.05150993540883064,
        0.05094810947775841,
        0.05375254899263382,
        -0.05653560161590576,
        0.028192881494760513,
        0.050968561321496964,
        -0.05281423404812813,
        0.056831780821084976,
        0.0574224554002285,
        -0.04040338471531868,
        0.0360688716173172,
        0.0039522056467831135,
        -0.034150201827287674,
        0.029071025550365448,
        -0.05396106839179993,
        0.05584784969687462,
        -0.05371858924627304,
        0.02599366009235382,
        -0.04994756355881691,
        -0.0179709754884243,
        -0.056101541966199875,
        0.004638932179659605,
        -0.05709419772028923,
        0.012736978009343147,
        0.025098595768213272,
        -0.04753580316901207,
        -0.051419638097286224,
        -0.054930415004491806,
        0.02286534383893013,
        -0.039542268961668015,
        0.017602846026420593,
        -0.05340888351202011,
        0.057414304465055466,
        0.021602846682071686,
        0.050530046224594116,
        -0.0011623123427852988,
        -0.05005775764584541,
        0.05742949992418289,
        -0.030025366693735123,
        -0.04496729373931885,
        -0.0168604776263237,
        -0.015272906050086021,
        -0.05647532269358635,
        0.04635573551058769,
        -0.005369239952415228,
        0.041944462805986404,
        0.03247782215476036,
        -0.049578193575143814,
        0.04247952252626419,
        0.052260614931583405,
        -0.05593232065439224,
        0.030714217573404312,
        -0.04385828226804733,
        -0.01487989816814661,
        -0.03627289831638336,
        -0.02127320133149624,
        0.031694039702415466,
        -0.05624614283442497,
        -0.0563635379076004,
        -0.021411249414086342,
        -0.05723683163523674,
        0.04599737003445625,
        0.05697314068675041,
        0.05147620663046837,
        0.05095938593149185,
        -0.056951578706502914,
        -0.04662596434354782,
        0.043375901877880096,
        -0.054669927805662155,
        0.04655740037560463,
        -0.012153934687376022,
        -0.057256873697042465,
        -0.006251141894608736,
        0.01974712312221527,
        0.020321426913142204,
        -0.0173334963619709,
        -0.05112045630812645,
        -0.0032379585318267345,
        0.04960529878735542,
        -0.057063836604356766,
        -0.017372895032167435,
        -0.015096546150743961,
        -0.0031734921503812075,
        -0.05437092110514641,
        -0.055466294288635254,
        0.052671708166599274,
        -0.03635012358427048,
        0.0034922712948173285,
        0.05689796060323715,
        -0.04191968962550163,
        0.056202810257673264,
        -0.05419565364718437,
        0.018997354432940483,
        0.05430857464671135,
        -0.05565108358860016,
        -0.056555844843387604,
        0.05734497308731079,
        0.044752418994903564,
        -0.03777305781841278,
        0.054337721318006516,
        -0.03808970749378204,
        -0.04526175186038017,
        0.01770521141588688,
        0.03627173602581024,
        0.03289635479450226,
        0.05728952959179878,
        0.015722380951046944,
        -0.05318349599838257,
        -0.0564974807202816,
        0.018587546423077583,
        -0.050748925656080246,
        -0.05614972114562988,
        0.04582742974162102,
        0.00413683382794261,
        0.0565967820584774,
        -0.057380277663469315,
        -0.05498523265123367,
        -0.0558335967361927,
        -0.041980113834142685,
        -0.017605086788535118,
        0.03437386080622673,
        -0.040063127875328064,
        0.056439124047756195,
        -0.030301591381430626,
        -0.05585959553718567,
        -0.046560779213905334,
        0.04708268493413925,
        -0.017169566825032234,
        -0.05574541911482811,
        -0.01121021993458271,
        -0.019552553072571754,
        0.05531984940171242,
        -0.048861727118492126,
        0.05161190405488014,
        -0.05620158836245537,
        0.03340372070670128,
        0.056466516107320786,
        -0.056398630142211914,
        0.0510888434946537,
        -0.036449406296014786,
        -0.04501988738775253,
        0.04920393228530884,
        0.038223996758461,
        -0.021557170897722244,
        -0.011176634579896927,
        0.01830633357167244,
        0.01627200096845627,
        0.05132102966308594,
        0.036706142127513885,
        -0.042979951947927475,
        -0.054964806884527206,
        0.010048040188848972,
        0.04111288860440254,
        -0.05671016499400139,
        -0.04759227856993675,
        0.04813515394926071,
        0.05665268748998642,
        0.030666979029774666,
        -0.02620282769203186,
        0.04876464977860451,
        0.05690272897481918,
        0.05047137662768364,
        0.05663677677512169,
        -0.05683709308505058,
        0.033427976071834564,
        -0.054975833743810654,
        0.052578870207071304,
        0.052997078746557236,
        -0.056001365184783936,
        0.05739760026335716,
        -0.055769212543964386,
        0.03312242776155472,
        0.056421395391225815,
        0.055401433259248734,
        0.04055963456630707,
        -0.05441348999738693,
        -0.04198095574975014,
        -0.04381781443953514,
        0.05176563560962677,
        0.023898441344499588,
        0.03664114326238632,
        -0.054543912410736084,
        -0.0536196194589138,
        -0.056858792901039124,
        0.056885819882154465,
        -0.05581389740109444,
        0.043789613991975784,
        -0.023118790239095688,
        0.03504664823412895,
        -0.056486088782548904,
        0.05238118767738342,
        -0.05490717664361,
        -0.024598872289061546,
        0.05623651668429375,
        -0.055798888206481934,
        0.05707120895385742,
        0.05699697136878967,
        -0.00944302137941122,
        0.048046089708805084,
        0.05734511464834213,
        0.046864572912454605,
        0.04376865550875664,
        -0.056408487260341644,
        -0.05649832636117935,
        0.051380883902311325,
        -0.02621372602880001,
        -0.02810986153781414,
        -0.04189924895763397,
        -0.03548220917582512,
        -0.025820378214120865,
        -0.01839120127260685,
        -0.054370153695344925,
        -0.024064386263489723,
        -0.03505433350801468,
        -0.05063735321164131,
        0.05664153769612312,
        -0.04715527594089508,
        -0.05638512223958969,
        0.04177560657262802,
        -0.00895179994404316,
        -0.02707715518772602,
        0.032010748982429504,
        0.05384334176778793,
        0.050273071974515915,
        -0.05720376595854759,
        0.05682454630732536,
        0.054219502955675125,
        0.00829005241394043,
        -0.05581190064549446,
        -0.051276251673698425,
        0.05728954076766968,
        -0.05239029601216316,
        0.03934434801340103,
        -0.0541008859872818,
        -0.010609401389956474,
        0.056486908346414566,
        -0.046158317476511,
        -0.053331755101680756,
        0.0570208877325058,
        -0.05037436634302139,
        -0.05609738826751709,
        0.011568850837647915,
        0.04098841920495033,
        -0.020973768085241318,
        0.018373915925621986,
        0.04020899906754494,
        0.019700294360518456,
        0.010886863805353642,
        0.05595717206597328,
        0.039080359041690826,
        -0.047200556844472885,
        -0.005092312581837177,
        -0.05352826416492462,
        -0.047867704182863235,
        0.052836962044239044,
        -0.055075205862522125,
        0.0573713555932045,
        0.04008658230304718,
        -0.05656719580292702,
        0.05624314770102501,
        0.022652840241789818,
        -0.05324701964855194,
        -0.00825915951281786,
        0.03645682334899902,
        -0.04484518617391586,
        0.02977825328707695,
        0.042764730751514435,
        -0.028551919385790825,
        0.05668284371495247,
        0.005590764805674553,
        0.01867765747010708,
        0.010190777480602264,
        -0.0295256320387125,
        0.04882746562361717,
        -0.0229120422154665,
        -0.056553445756435394,
        0.03560088202357292,
        0.0480293408036232,
        0.054071277379989624,
        -0.04609820246696472,
        0.041561637073755264,
        -0.009231693111360073,
        0.056207943707704544,
        -0.021095464006066322,
        0.05704616755247116,
        0.055637139827013016,
        0.03455156460404396,
        -0.05695594847202301,
        0.054283514618873596,
        -0.05728587135672569,
        -0.01357881911098957,
        -0.0539434514939785,
        -0.03497130796313286,
        -0.04262303188443184,
        0.056818000972270966,
        0.05583876371383667,
        -0.05579361692070961,
        0.036405112594366074,
        0.002256244421005249,
        -0.017185768112540245,
        -0.041932009160518646,
        -0.011149733327329159,
        -0.0356089286506176,
        0.024890216067433357,
        0.0554833859205246,
        0.02403988689184189,
        0.04262109100818634,
        0.05697755143046379,
        -0.010771868750452995,
        0.055965524166822433,
        -0.048615772277116776,
        0.02416384592652321,
        -0.018189743161201477,
        -0.05637853220105171,
        0.04031451418995857,
        0.03023080714046955,
        0.05122680589556694,
        -0.028688941150903702,
        -0.04926056042313576,
        -0.0565648078918457,
        0.05450397729873657,
        0.030760930851101875,
        -0.05442925915122032,
        -0.05012963339686394,
        0.020383942872285843,
        -0.05182722583413124,
        0.05374017357826233,
        -0.051059987396001816,
        0.04739290848374367,
        -0.056942056864500046,
        0.006399838253855705,
        -0.05402911454439163,
        0.009863664396107197,
        0.0015089581720530987,
        0.05690935254096985,
        0.056519072502851486,
        -0.055798158049583435,
        0.047939009964466095,
        0.04229675978422165,
        -0.03949906677007675,
        0.01392367109656334,
        0.049494531005620956,
        -0.057316508144140244,
        0.046304456889629364,
        -0.006232029292732477,
        -0.057044319808483124,
        -0.05564778670668602,
        -0.05026394501328468,
        -0.03631008416414261,
        0.024950703606009483,
        -0.05549861863255501,
        -0.039065826684236526,
        -0.056113000959157944,
        0.0512547492980957,
        0.03146954998373985,
        0.05580035224556923,
        0.02588570863008499,
        -0.006612897384911776,
        0.05422741919755936,
        -0.05723316967487335,
        0.009397552348673344,
        0.05716242268681526,
        -0.038228411227464676,
        0.049312688410282135,
        -0.052839115262031555,
        -0.033860206604003906,
        -0.0020463508553802967,
        -0.0073259142227470875,
        0.0571482814848423,
        -0.018527377396821976,
        -0.007069717161357403,
        0.03135509043931961,
        0.05738571286201477,
        -0.04196401685476303,
        0.03512163460254669,
        -0.01093357801437378,
        0.05310805141925812,
        -0.04588327929377556,
        -0.016865212470293045,
        -0.004715729504823685,
        0.039844807237386703,
        -0.05567819997668266,
        0.011288542300462723,
        -0.05720563232898712,
        0.003340206341817975,
        -0.054053597152233124,
        0.0073328278958797455,
        -0.047291550785303116,
        -0.042314112186431885,
        -0.05550381913781166,
        0.03593225032091141,
        -0.011437519453465939,
        -0.04464961960911751,
        -0.05101105198264122,
        -0.056841250509023666,
        -0.05675346031785011,
        -0.05741594359278679,
        -0.019407687708735466,
        -0.04642780125141144,
        -0.04915870726108551,
        -0.05694946274161339,
        0.010825852863490582,
        0.0490996278822422,
        0.05232230946421623,
        0.05164944380521774,
        0.04254220426082611,
        0.042803600430488586,
        -0.004604197107255459,
        -0.013567076064646244,
        0.050642188638448715,
        0.015425141900777817,
        0.054666221141815186,
        0.055557262152433395,
        0.05429599806666374,
        -0.03259485214948654,
        -0.05615529417991638,
        0.050743453204631805,
        -0.029026012867689133,
        0.05654960498213768,
        0.04665781185030937,
        -0.0093571487814188,
        -0.05567137524485588,
        -0.04018627852201462,
        -0.05441330373287201,
        0.05667658522725105,
        0.057244837284088135,
        -0.055728066712617874,
        0.050271663814783096,
        -0.04041019827127457,
        -0.05637557432055473,
        -0.04874136671423912,
        -0.04095226153731346,
        0.05252627655863762,
        -0.04912355914711952,
        0.00778817618265748,
        0.046864937990903854,
        0.04505675286054611,
        0.05707908421754837,
        -0.03139697387814522,
        -0.05450038984417915,
        -0.02216922678053379,
        -0.05026146396994591,
        -0.05024434253573418,
        0.051164623349905014,
        0.033309608697891235,
        0.05464435741305351,
        0.011613333597779274,
        0.05523085594177246,
        -0.055903732776641846,
        0.02999272383749485,
        0.03608953207731247,
        -0.04024558514356613,
        0.05679275840520859,
        -0.0548773929476738,
        -0.016311513260006905,
        -0.054610759019851685,
        -0.05614307150244713,
        0.05693770945072174,
        0.05304541811347008,
        0.04406725987792015,
        0.05712668597698212,
        -0.0336095355451107,
        -0.04673028364777565,
        0.02741682343184948,
        0.05423907935619354,
        0.02699245698750019,
        0.041112422943115234,
        -0.029048502445220947,
        0.05499855801463127,
        -0.04519925266504288,
        0.020387763157486916,
        -0.050592005252838135
    ],
    [
        0.04934695363044739,
        0.05323534086346626,
        0.05384794622659683,
        0.0016722780419513583,
        -0.00419859541580081,
        -0.03923226520419121,
        0.05263739451766014,
        -0.05240634083747864,
        0.04356120154261589,
        0.04642230644822121,
        0.03879339247941971,
        0.035676274448633194,
        -0.05348304659128189,
        0.049464575946331024,
        -0.05340138077735901,
        0.0510668009519577,
        -0.011097394861280918,
        0.05368554964661598,
        0.04584348946809769,
        0.04323871433734894,
        0.05169267579913139,
        -0.03612421452999115,
        0.0044928728602826595,
        -0.05330945923924446,
        -0.04316721484065056,
        0.04645710438489914,
        -0.04823105409741402,
        0.035090573132038116,
        -0.052550144493579865,
        -0.053935106843709946,
        -0.0028175569605082273,
        0.05234580859541893,
        0.045617301017045975,
        -0.05134472995996475,
        0.0537814162671566,
        -0.05406409874558449,
        -0.05396270379424095,
        -0.04628917947411537,
        0.05120754987001419,
        -0.04315383359789848,
        0.017785649746656418,
        0.027858497574925423,
        0.050574157387018204,
        0.017046133056282997,
        0.04398250952363014,
        0.03339286521077156,
        0.037162743508815765,
        0.05355842784047127,
        0.054038070142269135,
        0.04318002238869667,
        0.053823553025722504,
        0.05398716777563095,
        0.036256562918424606,
        -0.019475208595395088,
        0.04952618107199669,
        -0.051297418773174286,
        -0.05387117341160774,
        -0.042824722826480865,
        -0.0540631078183651,
        -0.053169846534729004,
        -0.05375443398952484,
        -0.0035140267573297024,
        -0.053735893219709396,
        0.04839543625712395,
        0.053099241107702255,
        -0.04806000739336014,
        -0.054022789001464844,
        -0.025411011651158333,
        -0.022134115919470787,
        0.05088760331273079,
        0.049274347722530365,
        -0.05108082294464111,
        0.054063986986875534,
        0.030389027670025826,
        0.02433168701827526,
        -0.052504558116197586,
        0.05377359315752983,
        0.05406450852751732,
        0.05042547732591629,
        -0.006857937201857567,
        -0.03423402085900307,
        -0.05241495743393898,
        -0.010692774318158627,
        0.053793225437402725,
        0.0468832366168499,
        0.049122121185064316,
        -0.053568631410598755,
        -0.053465090692043304,
        -0.05266796797513962,
        0.052190396934747696,
        -0.0449717678129673,
        -0.0522804856300354,
        -0.052599601447582245,
        -0.05361705273389816,
        -0.04291662946343422,
        0.029493557289242744,
        -0.005924094468355179,
        -0.05328472703695297,
        0.03757907822728157,
        0.015901781618595123,
        -0.05390488728880882,
        0.04916941002011299,
        0.0009641620563343167,
        0.05177278444170952,
        0.03149764984846115,
        -0.05293061211705208,
        -0.032986026257276535,
        0.014733835123479366,
        -0.053302351385354996,
        0.028606818988919258,
        0.035473354160785675,
        -0.03592647984623909,
        -0.0487288273870945,
        0.010344267822802067,
        0.05329521372914314,
        -0.052644625306129456,
        -0.05400879308581352,
        -0.05309091508388519,
        0.05143527314066887,
        -0.05245408043265343,
        0.007543130312114954,
        0.022064853459596634,
        0.014032975770533085,
        -0.03764868900179863,
        -0.05374497175216675,
        0.053864676505327225,
        -0.05131542310118675,
        -0.0068815466947853565,
        0.04757494851946831,
        -0.005066107492893934,
        0.02583567425608635,
        -0.053300436586141586,
        -0.04281177371740341,
        0.05127938464283943,
        -0.0455152802169323,
        -0.05284964293241501,
        0.05363220348954201,
        0.026378564536571503,
        -0.021973202005028725,
        0.049737490713596344,
        0.04144515469670296,
        -0.03494013100862503,
        0.051461342722177505,
        -0.05155644193291664,
        -0.035766176879405975,
        0.05326802283525467,
        0.031888190656900406,
        0.051509078592061996,
        -0.05294043943285942,
        0.04060237109661102,
        0.0534430630505085,
        -0.05403290316462517,
        -0.05361553281545639,
        0.025522278621792793,
        0.05308689549565315,
        -0.0499197393655777,
        -0.054054975509643555,
        -0.011829708702862263,
        0.05381407216191292,
        -0.05313851684331894,
        0.042407646775245667,
        -0.0050858138129115105,
        0.05356878042221069,
        0.053964562714099884,
        0.030639328062534332,
        -0.003692259779199958,
        0.022543398663401604,
        0.0430651493370533,
        -0.04972775653004646,
        0.05198131129145622,
        -0.0536561980843544,
        0.05392002686858177,
        -0.053834352642297745,
        0.051662471145391464,
        0.047230955213308334,
        -0.038949668407440186,
        0.05173947662115097,
        0.0012620928464457393,
        0.052721019834280014,
        -0.040713291615247726,
        -0.0426788330078125,
        0.05397997796535492,
        -0.01555975154042244,
        -0.0452180877327919,
        0.009127727709710598,
        -0.000018593749700812623,
        -0.035807009786367416,
        0.05376880243420601,
        0.03641433268785477,
        -0.04437512904405594,
        -0.0529731884598732,
        0.044040098786354065,
        -0.05378866195678711,
        -0.0520731545984745,
        -0.054001275449991226,
        0.05241287499666214,
        0.05359378084540367,
        -0.047014303505420685,
        0.05335867777466774,
        -0.0019692592322826385,
        0.05384277552366257,
        0.0137016037479043,
        0.05300011858344078,
        -0.02849511429667473,
        -0.04588824883103371,
        0.05279061943292618,
        0.052486442029476166,
        0.05346502363681793,
        -0.052706822752952576,
        0.05400267243385315,
        -0.049644116312265396,
        0.020902762189507484,
        0.04805392399430275,
        0.05011401325464249,
        -0.037884458899497986,
        -0.05279786139726639,
        0.05405857786536217,
        -0.005818515550345182,
        0.054030634462833405,
        -0.043499164283275604,
        -0.04184515029191971,
        0.05360624939203262,
        -0.009614218957722187,
        -0.04778147488832474,
        0.05401896685361862,
        -0.046798232942819595,
        0.04456235468387604,
        -0.05396068096160889,
        0.05272360518574715,
        -0.05404825881123543,
        -0.04061237350106239,
        -0.05250723287463188,
        0.010404716245830059,
        0.0496596097946167,
        -0.03961453214287758,
        0.05401650443673134,
        0.05323231965303421,
        -0.046130698174238205,
        -0.033922821283340454,
        0.05405496433377266,
        0.04158559441566467,
        -0.03728736191987991,
        -0.05389413610100746,
        -0.0006297287764027715,
        0.029647964984178543,
        -0.05204473063349724,
        0.05236445367336273,
        0.04897335544228554,
        -0.048877693712711334,
        0.023160090669989586,
        -0.04194725677371025,
        -0.05091394856572151,
        -0.048284776508808136,
        -0.05360421910881996,
        0.006005271803587675,
        0.02516547590494156,
        0.0017486645374447107,
        -0.042944300919771194,
        0.053696293383836746,
        0.051794830709695816,
        0.03421369940042496,
        -0.050887223333120346,
        -0.04793167859315872,
        0.04060020670294762,
        0.049276500940322876,
        0.05379653722047806,
        0.050978951156139374,
        0.051529914140701294,
        -0.013371712528169155,
        0.04880327358841896,
        0.04970330372452736,
        -0.04769930988550186,
        0.04594626650214195,
        -0.05304257571697235,
        -0.05315513536334038,
        0.04244765266776085,
        -0.04890386387705803,
        0.04280931502580643,
        0.05401954427361488,
        -0.0048262858763337135,
        -0.05401355028152466,
        -0.02734392322599888,
        -0.05175530165433884,
        -0.046780262142419815,
        0.020577656105160713,
        -0.03097667545080185,
        0.03121696040034294,
        0.054051291197538376,
        -0.01871434412896633,
        0.05201798304915428,
        -0.05145403742790222,
        0.025748662650585175,
        -0.041862837970256805,
        -0.05352121219038963,
        0.05388328433036804,
        -0.05395371839404106,
        0.053378716111183167,
        -0.04075084626674652,
        -0.038645029067993164,
        0.05396222695708275,
        0.04860606789588928,
        0.028469432145357132,
        -0.03811861574649811,
        0.05386267602443695,
        0.05030829831957817,
        -0.03465640917420387,
        -0.046080298721790314,
        -0.005960389971733093,
        0.043106112629175186,
        -0.05405566468834877,
        0.038468170911073685,
        -0.03710810840129852,
        0.051988378167152405,
        0.053972069174051285,
        0.05161653831601143,
        -0.05371032655239105,
        -0.005477974656969309,
        0.04458869993686676,
        0.05384109169244766,
        -0.04057767987251282,
        0.037977490574121475,
        0.008637714199721813,
        0.05315219983458519,
        0.027106689289212227,
        0.05334123969078064,
        0.0483211874961853,
        0.015713050961494446,
        -0.053969573229551315,
        -0.05009652301669121,
        -0.052789103239774704,
        0.012173407711088657,
        -0.05363631993532181,
        -0.03993584215641022,
        -0.04206756502389908,
        0.0536319799721241,
        0.052202258259058,
        -0.05343884229660034,
        0.042835649102926254,
        -0.04380519315600395,
        -0.034310232847929,
        -0.04761427640914917,
        -0.04978791996836662,
        -0.006644286215305328,
        -0.05321952700614929,
        -0.04515211284160614,
        0.011077532544732094,
        0.05334170162677765,
        0.05394025519490242,
        0.04916803911328316,
        0.05348760634660721,
        0.05389980226755142,
        0.04339054226875305,
        0.04158881679177284,
        -0.05036475136876106,
        0.028959522023797035,
        0.00607808493077755,
        0.0007139986264519393,
        -0.03214402124285698,
        -0.0530833899974823,
        0.006780826020985842,
        0.05302056670188904,
        -0.047469910234212875,
        0.05170750617980957,
        -0.04865900054574013,
        -0.05139562860131264,
        -0.05386706441640854,
        0.053747694939374924,
        -0.02493618242442608,
        -0.021546103060245514,
        -0.052165161818265915,
        0.013978025875985622,
        -0.04909837990999222,
        0.05400116741657257,
        -0.05359769985079765,
        0.052884675562381744,
        0.049309756606817245,
        -0.049377068877220154,
        -0.049097977578639984,
        0.053819626569747925,
        -0.038783080875873566,
        -0.05294804275035858,
        -0.0032101585529744625,
        -0.052268136292696,
        0.022858094424009323,
        0.049874138087034225,
        -0.050021685659885406,
        -0.04569961875677109,
        -0.05385471135377884,
        -0.001572116743773222,
        -0.023400327190756798,
        -0.054005298763513565,
        -0.0413004606962204,
        -0.03351184353232384,
        -0.0008916329243220389,
        0.05404622107744217,
        0.05363042280077934,
        0.029107745736837387,
        -0.0500481016933918,
        0.028639156371355057,
        -0.03145715966820717,
        0.009222591295838356,
        0.05396151915192604,
        -0.014152194373309612,
        0.04657089710235596,
        0.016895964741706848,
        -0.017926722764968872,
        0.051183070987463,
        -0.01173089724034071,
        0.053621869534254074,
        0.030885353684425354,
        -0.0032624632585793734,
        -0.05178605020046234,
        0.05398041754961014,
        -0.05009450390934944,
        0.0529276505112648,
        -0.05330836400389671,
        0.015249094925820827,
        -0.027731969952583313,
        0.04678205028176308,
        0.05353150889277458,
        0.024277834221720695,
        -0.05397391319274902,
        -0.03272261470556259,
        -0.05404873937368393,
        -0.05245764181017876,
        -0.04085250571370125,
        -0.047644760459661484,
        0.04472796618938446,
        -0.050248026847839355,
        -0.05189066752791405,
        0.05265078693628311,
        -0.0409511923789978,
        -0.02195184864103794,
        -0.04865146055817604,
        -0.05391668900847435,
        -0.051798734813928604,
        -0.053864382207393646,
        -0.02023167535662651,
        -0.052464019507169724,
        -0.05021042376756668,
        -0.051580388098955154,
        0.05355367064476013,
        0.05405702814459801,
        0.053913846611976624,
        -0.050105590373277664,
        -0.046536680310964584,
        0.05386073514819145,
        -0.05404185131192207,
        0.045698367059230804,
        0.03460384160280228,
        0.001872072578407824,
        -0.01531551219522953,
        0.053883571177721024,
        0.04930103197693825,
        0.032475199550390244,
        -0.05324717238545418,
        0.04538450762629509,
        -0.04258905351161957,
        0.053323447704315186,
        0.0424046628177166,
        0.05363774672150612,
        -0.05397028475999832,
        -0.031180620193481445,
        0.050778578966856,
        0.04490950331091881,
        0.05373946577310562,
        -0.05391976982355118,
        0.054001595824956894,
        -0.04679591953754425,
        -0.05016530677676201,
        -0.050047826021909714,
        0.03218301758170128,
        0.0535508468747139,
        0.05239221826195717,
        0.05335276573896408,
        0.04600910097360611,
        0.027576778084039688,
        0.0198733638972044,
        -0.05072254687547684,
        0.038183990865945816,
        -0.03891192376613617,
        -0.05329599976539612,
        -0.023641197010874748,
        0.026579497382044792,
        0.04996245354413986,
        0.05385592207312584,
        0.0480501651763916,
        0.03685346245765686,
        -0.05386567860841751,
        -0.05311505123972893,
        -0.010972561314702034,
        -0.053233154118061066,
        -0.013704839162528515,
        0.009123739786446095,
        0.03748925402760506,
        -0.008075778372585773,
        -0.010452672839164734,
        0.024979883804917336,
        0.05179423838853836,
        -0.03721759840846062,
        0.03275250270962715,
        -0.049926139414310455,
        -0.04938102141022682,
        0.0074563054367899895,
        0.053921204060316086,
        -0.009527011774480343,
        0.04349411278963089,
        -0.047630537301301956,
        0.0525885745882988,
        -0.05363450199365616,
        -0.008202469907701015,
        0.03154144808650017
    ],
    [
        -0.04998258128762245,
        0.04887143895030022,
        0.04901565611362457,
        0.002815559506416321,
        0.04717877879738808,
        -0.03599027544260025,
        0.059233155101537704,
        -0.04635141044855118,
        -0.008230958133935928,
        0.027796581387519836,
        0.050305064767599106,
        0.056758854538202286,
        -0.055613335222005844,
        -0.03805796429514885,
        -0.05935477837920189,
        0.056643612682819366,
        -0.053975969552993774,
        0.037865668535232544,
        -0.05269605666399002,
        0.0101237827911973,
        -0.019181160256266594,
        -0.03884129226207733,
        0.052590880542993546,
        -0.03427493944764137,
        0.057723257690668106,
        0.05012723058462143,
        0.034129176288843155,
        0.058317843824625015,
        -0.04754819720983505,
        -0.04470977187156677,
        0.059281863272190094,
        -0.02357655204832554,
        0.04085886850953102,
        -0.05755307152867317,
        0.003928666934370995,
        -0.02628970891237259,
        -0.05614398792386055,
        -0.04301637038588524,
        -0.04675794392824173,
        0.03828258439898491,
        0.0005024966085329652,
        0.0033157921861857176,
        0.05430494248867035,
        -0.0500042550265789,
        0.05048423632979393,
        0.019674411043524742,
        0.03983457013964653,
        0.0585421584546566,
        0.05764633044600487,
        -0.012533432804048061,
        0.02657204680144787,
        -0.03594880551099777,
        0.03940049186348915,
        -0.023206939920783043,
        -0.04643221199512482,
        -0.0544888861477375,
        -0.056505072861909866,
        0.004647563677281141,
        -0.03415648266673088,
        -0.0258581954985857,
        -0.05491912364959717,
        -0.021117527037858963,
        -0.0547088198363781,
        0.05070141702890396,
        -0.003806029912084341,
        0.052751325070858,
        0.03785918653011322,
        -0.05803661793470383,
        0.03707795590162277,
        -0.05941101163625717,
        -0.043468501418828964,
        -0.05918090045452118,
        0.03315496817231178,
        -0.03610258549451828,
        -0.02850627526640892,
        -0.03174383565783501,
        0.05379877984523773,
        0.05928955599665642,
        0.05857390537858009,
        -0.03899417445063591,
        -0.045326028019189835,
        0.008165370672941208,
        -0.00501565495505929,
        0.009900016710162163,
        0.03173362463712692,
        0.025906015187501907,
        -0.053894855082035065,
        -0.03651339188218117,
        0.012919073924422264,
        0.05659347400069237,
        -0.05349528044462204,
        0.04837147518992424,
        -0.034256916493177414,
        -0.05357076972723007,
        0.022238824516534805,
        0.0015227148542180657,
        -0.048278000205755234,
        -0.056166429072618484,
        -0.033438604325056076,
        0.0013713804073631763,
        -0.058916449546813965,
        -0.016256898641586304,
        0.0581083782017231,
        0.052692972123622894,
        0.043634071946144104,
        0.04332771897315979,
        -0.04939831420779228,
        0.036286450922489166,
        -0.055146511644124985,
        0.02706582099199295,
        -0.05335643142461777,
        -0.05821942538022995,
        -0.05767152085900307,
        0.0026889543514698744,
        0.041777729988098145,
        -0.05581672489643097,
        -0.05163658410310745,
        -0.05860249698162079,
        -0.05913051590323448,
        -0.02456565573811531,
        -0.034359320998191833,
        0.014900894835591316,
        -0.04193788766860962,
        0.056172970682382584,
        -0.05913268402218819,
        0.04026179388165474,
        -0.052625905722379684,
        -0.013303416781127453,
        0.05173271894454956,
        -0.0038284356705844402,
        0.04373160004615784,
        -0.047348957508802414,
        -0.013583607971668243,
        0.054790250957012177,
        -0.04869751259684563,
        -0.056583158671855927,
        0.0244893878698349,
        -0.056093718856573105,
        -0.04907353222370148,
        0.05512114241719246,
        0.05797015130519867,
        0.001965552568435669,
        0.04863142967224121,
        0.005936695262789726,
        -0.027632523328065872,
        0.031162256374955177,
        0.05869225040078163,
        -0.04019283130764961,
        -0.04128605127334595,
        0.05834353342652321,
        -0.04921906068921089,
        -0.054365936666727066,
        -0.056907955557107925,
        0.012011364102363586,
        -0.040348153561353683,
        -0.05663993954658508,
        0.05910307914018631,
        -0.026302209123969078,
        0.027080770581960678,
        -0.03432174399495125,
        0.055306997150182724,
        0.03976798802614212,
        0.03910060599446297,
        -0.01852777972817421,
        -0.056673917919397354,
        -0.054050263017416,
        -0.000728259386960417,
        -0.018413303419947624,
        0.01546498853713274,
        0.052976906299591064,
        -0.042149242013692856,
        0.03774787485599518,
        -0.058120448142290115,
        0.01962532103061676,
        -0.0369565449655056,
        0.0569692961871624,
        -0.05949973687529564,
        0.021032430231571198,
        0.05140521004796028,
        -0.029203448444604874,
        0.04806085303425789,
        0.051999423652887344,
        0.055393755435943604,
        0.02386990189552307,
        0.018499163910746574,
        0.05495697259902954,
        -0.028908168897032738,
        0.05792197212576866,
        -0.05813390761613846,
        -0.05770159885287285,
        -0.042567718774080276,
        -0.049389783293008804,
        -0.057661272585392,
        -0.014125945046544075,
        -0.049657270312309265,
        0.035682376474142075,
        0.057214461266994476,
        -0.056108277291059494,
        -0.036209411919116974,
        0.002110917354002595,
        0.009541728533804417,
        0.04952402785420418,
        0.04369919002056122,
        0.04875040054321289,
        0.019127314910292625,
        0.058399852365255356,
        0.007516154553741217,
        0.011638635769486427,
        -0.049483977258205414,
        -0.0409407913684845,
        -0.056552160531282425,
        0.05639975890517235,
        -0.04040198773145676,
        -0.02062017098069191,
        0.05300313979387283,
        0.036361876875162125,
        0.05891241133213043,
        0.040233220905065536,
        0.059397559612989426,
        -0.055168069899082184,
        0.027389302849769592,
        0.039907779544591904,
        -0.05885748937726021,
        -0.05907033383846283,
        0.05765627324581146,
        -0.05916242673993111,
        0.03496379032731056,
        0.05699454993009567,
        -0.014089771546423435,
        -0.05842924863100052,
        -0.01129848975688219,
        -0.059024788439273834,
        0.0044502331875264645,
        0.04793211445212364,
        -0.03320193290710449,
        0.04169895127415657,
        0.057109978049993515,
        -0.02125277929008007,
        0.024557901546359062,
        0.05765799060463905,
        0.04491087794303894,
        0.057995688170194626,
        -0.0229682344943285,
        0.03917239233851433,
        -0.003434686688706279,
        -0.03474098816514015,
        0.048454318195581436,
        0.05508672446012497,
        -0.043762627989053726,
        -0.047713782638311386,
        0.007869545370340347,
        -0.059146031737327576,
        -0.03700247406959534,
        -0.056801892817020416,
        0.007545834872871637,
        0.05842513591051102,
        -0.046067014336586,
        -0.006658223457634449,
        0.05900411680340767,
        0.05217748135328293,
        0.05820402503013611,
        0.02457631379365921,
        -0.009613706730306149,
        0.043352968990802765,
        -0.044917844235897064,
        0.004383368883281946,
        0.04299602657556534,
        -0.010955081321299076,
        0.03197944164276123,
        -0.05659651383757591,
        -0.03138172999024391,
        -0.05874001234769821,
        -0.013070221059024334,
        -0.05859740078449249,
        -0.017948128283023834,
        0.001362245879136026,
        -0.05757754296064377,
        0.040081936866045,
        -0.0429961197078228,
        -0.050691694021224976,
        -0.05931250751018524,
        -0.044081054627895355,
        0.03672965243458748,
        0.057566385716199875,
        0.015074399299919605,
        -0.054502543061971664,
        0.03493807837367058,
        -0.050285305827856064,
        -0.05519993230700493,
        0.05007432401180267,
        -0.03517402336001396,
        0.04053378105163574,
        -0.018358880653977394,
        -0.03857950493693352,
        0.009216411970555782,
        -0.04840861260890961,
        0.058974698185920715,
        0.048442866653203964,
        -0.04121159762144089,
        0.05514105036854744,
        -0.031332675367593765,
        0.05366859585046768,
        0.04371005669236183,
        0.028942568227648735,
        0.03476136177778244,
        -0.01161220483481884,
        -0.05169796198606491,
        0.007494790945202112,
        0.053477320820093155,
        -0.059442054480314255,
        0.04270055145025253,
        -0.048559777438640594,
        -0.04419100284576416,
        0.057602930814027786,
        -0.059143729507923126,
        -0.057101909071207047,
        0.050525322556495667,
        -0.022850146517157555,
        0.03791069984436035,
        -0.05498373135924339,
        -0.05654207989573479,
        0.051204852759838104,
        0.04785654693841934,
        -0.039876069873571396,
        0.05165596678853035,
        0.0575590543448925,
        0.04988039657473564,
        0.016590356826782227,
        -0.008020306937396526,
        0.024329427629709244,
        -0.006427575834095478,
        0.046068597584962845,
        0.01385530550032854,
        -0.04472263157367706,
        0.05816173180937767,
        0.040237538516521454,
        -0.013259445317089558,
        0.056129928678274155,
        -0.03998563811182976,
        0.03455767035484314,
        0.04921627417206764,
        -0.03397764265537262,
        -0.05641705170273781,
        -0.059035371989011765,
        0.059594541788101196,
        0.04484238475561142,
        0.046661898493766785,
        0.05687960237264633,
        -0.005464460235089064,
        0.05447293817996979,
        -0.05581763759255409,
        0.05282292142510414,
        -0.053311072289943695,
        -0.05460385978221893,
        -0.05895831063389778,
        0.053441159427165985,
        -0.04738221317529678,
        -0.023658007383346558,
        -0.053863849490880966,
        -0.05489659309387207,
        0.0554804801940918,
        -0.015339058823883533,
        -0.05895501747727394,
        -0.003034863155335188,
        0.000562383618671447,
        -0.048239462077617645,
        0.05937103554606438,
        0.028804035857319832,
        0.05789860337972641,
        -0.05958002060651779,
        -0.046720221638679504,
        -0.059222862124443054,
        0.05939153954386711,
        0.03989949822425842,
        0.05721597000956535,
        0.058557309210300446,
        -0.012519259937107563,
        0.0355006568133831,
        -0.045186497271060944,
        -0.054931122809648514,
        -0.030041057616472244,
        0.043626364320516586,
        -0.058441147208213806,
        0.0329631082713604,
        0.05132536217570305,
        -0.0249684676527977,
        0.05330893397331238,
        -0.05925638973712921,
        -0.05263965204358101,
        -0.05394483357667923,
        -0.02490372210741043,
        0.047041524201631546,
        0.043022122234106064,
        0.024525389075279236,
        -0.04920505732297897,
        0.05601135268807411,
        0.05744844302535057,
        -0.01114737894386053,
        0.022298848256468773,
        -0.0590195506811142,
        -0.04436957836151123,
        0.05536947771906853,
        0.05308359116315842,
        0.058818161487579346,
        0.011499536223709583,
        0.03208165243268013,
        0.05638295039534569,
        0.019180327653884888,
        0.05423998460173607,
        -0.05705900117754936,
        0.05639268830418587,
        -0.028720924630761147,
        0.00914168730378151,
        -0.007534064818173647,
        0.05741419270634651,
        -0.02682598866522312,
        -0.002276500454172492,
        0.03737230971455574,
        0.012699241749942303,
        0.055830396711826324,
        -0.054791245609521866,
        -0.055525436997413635,
        -0.05359586700797081,
        -0.05819476768374443,
        0.03906654939055443,
        -0.004997608717530966,
        -0.04503612220287323,
        -0.055148255079984665,
        -0.05872255191206932,
        -0.028400078415870667,
        0.05038569122552872,
        0.023701252415776253,
        0.0032050483860075474,
        0.05189284309744835,
        0.04284993186593056,
        -0.03786926716566086,
        0.004443606361746788,
        0.033603936433792114,
        -0.008040765300393105,
        -0.040742602199316025,
        0.023475149646401405,
        0.013823682442307472,
        0.04929180070757866,
        0.05749477818608284,
        -0.03455186262726784,
        -0.0036932961083948612,
        0.05914933234453201,
        -0.0589480884373188,
        0.05623377859592438,
        -0.055767837911844254,
        -0.05807929486036301,
        0.05559946224093437,
        0.051852431148290634,
        -0.044450219720602036,
        -0.008432244881987572,
        -0.05462665110826492,
        0.02323327399790287,
        0.024502793326973915,
        -0.04306695982813835,
        0.04376382380723953,
        0.0313812680542469,
        -0.029708851128816605,
        -0.0580216646194458,
        -0.05758979916572571,
        0.05827667936682701,
        0.05945081263780594,
        -0.058535993099212646,
        0.05593791604042053,
        -0.05701815336942673,
        -0.05942857638001442,
        0.037711095064878464,
        0.003287848085165024,
        0.05727569758892059,
        0.04225292056798935,
        0.0569697730243206,
        0.041823528707027435,
        -0.011982066556811333,
        0.003954571671783924,
        -0.05049746111035347,
        -0.03152909874916077,
        0.006786119658499956,
        -0.038273878395557404,
        0.0514872744679451,
        0.04387233033776283,
        0.04109400138258934,
        0.054771848022937775,
        0.05681945011019707,
        -0.03215864673256874,
        -0.048747092485427856,
        -0.05176805704832077,
        -0.05315236747264862,
        -0.05766701325774193,
        0.02776345983147621,
        0.02562025375664234,
        0.05424318462610245,
        0.018085990101099014,
        -0.05935927852988243,
        -0.04615235701203346,
        0.05583291873335838,
        0.058708883821964264,
        -0.04759965464472771,
        -0.036940790712833405,
        -0.021492011845111847,
        -0.049018364399671555,
        -0.006487875711172819,
        -0.03499383479356766,
        0.021620145067572594,
        0.03476828709244728,
        -0.018519626930356026,
        -0.052509889006614685,
        0.05515040084719658,
        0.05940086022019386
    ],
    [
        -0.018878236413002014,
        -0.05598405376076698,
        0.011417393572628498,
        -0.019377248361706734,
        -0.021383188664913177,
        -0.05401969701051712,
        0.05331254377961159,
        -0.055483803153038025,
        0.04389394447207451,
        0.01699514500796795,
        0.012347163632512093,
        0.05637681111693382,
        -0.05392158031463623,
        -0.042852845042943954,
        -0.056444428861141205,
        -0.047506269067525864,
        -0.04726221784949303,
        0.05471836030483246,
        -0.02648986130952835,
        0.03476130962371826,
        0.022174915298819542,
        -0.026545554399490356,
        0.056488413363695145,
        -0.04229041561484337,
        0.03693424165248871,
        -0.017910201102495193,
        0.03673168271780014,
        0.05623593553900719,
        -0.048553433269262314,
        0.020786326378583908,
        0.04975483566522598,
        -0.006610616575926542,
        0.04267575964331627,
        -0.05644476041197777,
        0.055896759033203125,
        -0.05609181895852089,
        -0.05507289618253708,
        -0.05653013661503792,
        -0.0549011193215847,
        -0.041095755994319916,
        0.03267568722367287,
        -0.032455235719680786,
        0.051473166793584824,
        -0.0522201806306839,
        -0.030014287680387497,
        0.05596671625971794,
        -0.0251283198595047,
        0.05494797229766846,
        0.05660083517432213,
        -0.047147881239652634,
        0.05428878217935562,
        -0.01058067474514246,
        -0.05294365808367729,
        -0.0564517006278038,
        -0.052996158599853516,
        0.05236891284584999,
        -0.05618593841791153,
        -0.03616819530725479,
        0.029714925214648247,
        -0.05145694687962532,
        -0.056284137070178986,
        -0.0059428405947983265,
        -0.053514815866947174,
        0.05511027202010155,
        0.01322096586227417,
        -0.049216341227293015,
        -0.0564606636762619,
        -0.054239362478256226,
        0.05555412918329239,
        -0.04216771945357323,
        -0.029885239899158478,
        -0.05564121529459953,
        0.05336470901966095,
        0.015265327878296375,
        0.055374108254909515,
        -0.05148501321673393,
        0.0537547804415226,
        0.056618690490722656,
        -0.045663706958293915,
        0.025373125448822975,
        -0.053417500108480453,
        -0.012126601301133633,
        0.03937072306871414,
        0.024810384958982468,
        0.03327716514468193,
        0.052138302475214005,
        -0.05246240273118019,
        0.008738101460039616,
        -0.011868016794323921,
        0.015003789216279984,
        -0.05535042658448219,
        0.05056948587298393,
        -0.0544099397957325,
        -0.05599748343229294,
        -0.04757154732942581,
        0.04671158269047737,
        -0.05340452864766121,
        -0.05456766486167908,
        -0.05635525658726692,
        0.02186180278658867,
        -0.05628860369324684,
        0.05557345971465111,
        0.05649981647729874,
        0.0013080982025712729,
        0.031194493174552917,
        -0.00289385998621583,
        0.03888484090566635,
        0.052886202931404114,
        -0.056325607001781464,
        0.008838526904582977,
        0.01745789684355259,
        -0.028406087309122086,
        -0.02755836211144924,
        0.023604746907949448,
        0.02210763283073902,
        -0.05565392225980759,
        -0.019435575231909752,
        -0.029772823676466942,
        0.040285732597112656,
        -0.0375707782804966,
        -0.003535471623763442,
        -0.05348837375640869,
        -0.04381227493286133,
        -0.043900687247514725,
        -0.05370636656880379,
        0.05656510591506958,
        -0.028323020786046982,
        -0.05134856700897217,
        0.05410625785589218,
        -0.02775234915316105,
        0.055893562734127045,
        -0.04414838179945946,
        -0.042885638773441315,
        0.052468933165073395,
        -0.04448375478386879,
        -0.054002828896045685,
        0.05651938542723656,
        0.029749266803264618,
        -0.0563955083489418,
        0.05471012741327286,
        0.0012111187679693103,
        -0.052735425531864166,
        0.02556496113538742,
        0.0463595874607563,
        0.048325106501579285,
        0.034218937158584595,
        0.04930036514997482,
        0.007948679849505424,
        -0.05626625940203667,
        0.052963562309741974,
        -0.0014722523046657443,
        -0.05403881147503853,
        0.03386145830154419,
        0.0565824918448925,
        0.04222135618329048,
        -0.05644236132502556,
        -0.041552767157554626,
        -0.05272283777594566,
        -0.012419700622558594,
        -0.014216623269021511,
        0.03930487483739853,
        -0.019322624430060387,
        0.054747410118579865,
        0.05653820559382439,
        -0.056269969791173935,
        -0.011162291280925274,
        0.05071860924363136,
        -0.046837035566568375,
        -0.0325859859585762,
        0.05366945639252663,
        -0.049712758511304855,
        0.02689892053604126,
        -0.050435543060302734,
        0.04249640926718712,
        -0.001472993171773851,
        0.01531886588782072,
        -0.03341466188430786,
        -0.041654229164123535,
        0.05342670902609825,
        -0.0539003349840641,
        -0.008838788606226444,
        0.031783245503902435,
        -0.045829690992832184,
        -0.05556817352771759,
        0.0077442266047000885,
        0.05632760748267174,
        -0.0015689375577494502,
        0.05358467996120453,
        0.03715896233916283,
        -0.0557299442589283,
        -0.05428019165992737,
        -0.008938686922192574,
        0.042405031621456146,
        -0.0214900653809309,
        -0.05046622082591057,
        -0.008858896791934967,
        0.054571811109781265,
        -0.05293271318078041,
        -0.05399172753095627,
        0.04287375882267952,
        0.056546252220869064,
        0.016434336081147194,
        0.05468371883034706,
        -0.052443064749240875,
        -0.005723912734538317,
        0.055897057056427,
        0.04729348421096802,
        0.05588935688138008,
        -0.05135813727974892,
        0.05469392240047455,
        -0.052048418670892715,
        0.006311718840152025,
        0.05635060369968414,
        0.05646556615829468,
        0.004597924184054136,
        -0.05238882452249527,
        0.04673691838979721,
        -0.05132797732949257,
        0.05661405995488167,
        0.043617717921733856,
        -0.056369755417108536,
        -0.050658389925956726,
        -0.054220084100961685,
        -0.053240928798913956,
        0.05649653449654579,
        -0.055289313197135925,
        0.03269264101982117,
        0.049953073263168335,
        0.02895084023475647,
        -0.054303206503391266,
        0.05163658410310745,
        -0.056576237082481384,
        0.043901681900024414,
        0.047962579876184464,
        -0.05591902881860733,
        0.0565761961042881,
        0.05357290804386139,
        0.02375004068017006,
        0.037091150879859924,
        0.056615643203258514,
        0.011633562855422497,
        -0.027116520330309868,
        -0.056512583047151566,
        -0.03231751546263695,
        0.01642755977809429,
        -0.0525166392326355,
        -0.031750697642564774,
        0.04841122031211853,
        -0.05018778145313263,
        0.03625020757317543,
        0.053957000374794006,
        -0.055186446756124496,
        -0.030770067125558853,
        -0.05451309308409691,
        0.037916675209999084,
        0.0018906036857515574,
        -0.05246417224407196,
        -0.05215045437216759,
        0.052944786846637726,
        0.012677976861596107,
        -0.05573280528187752,
        -0.042009174823760986,
        -0.016701260581612587,
        0.05076542869210243,
        -0.012597412802278996,
        0.05555635690689087,
        0.05112190544605255,
        0.04966336116194725,
        -0.05080016702413559,
        -0.05220041424036026,
        -0.026977233588695526,
        -0.05638895928859711,
        0.05121351405978203,
        -0.0487520657479763,
        -0.05526277795433998,
        0.044988103210926056,
        -0.04577035456895828,
        0.05411386117339134,
        -0.03592526167631149,
        0.035999856889247894,
        -0.05459752306342125,
        0.05597291886806488,
        0.012051121331751347,
        0.03870776668190956,
        -0.03326224535703659,
        -0.036167897284030914,
        -0.02865634113550186,
        0.05282415822148323,
        0.00038761095493100584,
        0.05051296204328537,
        -0.05560547113418579,
        -0.04974324628710747,
        -0.04335140809416771,
        -0.05582133308053017,
        0.055528607219457626,
        -0.04105361923575401,
        0.0561348982155323,
        0.0073080831207334995,
        -0.05502890422940254,
        0.05642791464924812,
        -0.043243762105703354,
        0.030852921307086945,
        0.04559663310647011,
        0.056067727506160736,
        0.0063248369842767715,
        -0.01822618581354618,
        0.01992521993815899,
        0.02363532967865467,
        0.040775712579488754,
        0.0134400874376297,
        0.013854306191205978,
        0.04554267227649689,
        -0.01944073848426342,
        0.055389732122421265,
        0.027379488572478294,
        -0.041841838508844376,
        -0.05101018399000168,
        0.003154424484819174,
        0.045878469944000244,
        0.03547334671020508,
        0.02921442873775959,
        -0.010373893193900585,
        0.04724559932947159,
        -0.008008096367120743,
        0.04472019895911217,
        0.04724390059709549,
        0.046665892004966736,
        -0.05166896805167198,
        0.0228516086935997,
        -0.012547727674245834,
        -0.04102826490998268,
        -0.011959194205701351,
        -0.04678189754486084,
        0.03786301240324974,
        0.05303109064698219,
        0.05442731827497482,
        0.02385859005153179,
        -0.034726377576589584,
        0.021753914654254913,
        -0.04561757668852806,
        -0.05088064819574356,
        -0.05637150630354881,
        0.012996776029467583,
        -0.05331715941429138,
        0.05568333715200424,
        0.04486718028783798,
        0.04771179333329201,
        0.05589970946311951,
        0.055561743676662445,
        0.03367851674556732,
        0.054243892431259155,
        0.012865026481449604,
        -0.0034139135386794806,
        -0.04890842363238335,
        -0.05626891180872917,
        0.03823506832122803,
        -0.04009335860610008,
        -0.05567462742328644,
        -0.03467791527509689,
        -0.05604489520192146,
        0.03573330491781235,
        0.021220816299319267,
        -0.0023395598400384188,
        -0.039192162454128265,
        0.0126396669074893,
        -0.05420657992362976,
        0.05657802149653435,
        -0.006590218748897314,
        0.056329622864723206,
        -0.056002963334321976,
        -0.046267081052064896,
        -0.05535225197672844,
        0.05456235259771347,
        -0.04456242173910141,
        0.05593875050544739,
        0.02396206557750702,
        -0.04723311960697174,
        -0.04383924975991249,
        0.053229667246341705,
        -0.049682844430208206,
        -0.021398134529590607,
        0.055117424577474594,
        -0.053373705595731735,
        0.0030026433523744345,
        0.04435272514820099,
        -0.052771247923374176,
        0.0027494446840137243,
        -0.04890047013759613,
        -0.05266957730054855,
        -0.04687613621354103,
        -0.05613012611865997,
        -0.036105673760175705,
        -0.05611675605177879,
        0.014333351515233517,
        0.053974706679582596,
        0.05559079349040985,
        0.03690820932388306,
        -0.002824965165928006,
        0.040893275290727615,
        -0.05480913445353508,
        -0.04702726751565933,
        0.05660479888319969,
        0.027341647073626518,
        0.056072983890771866,
        -0.04856843128800392,
        -0.04668888822197914,
        0.033401861786842346,
        -0.054843612015247345,
        0.05649641901254654,
        -0.039290037006139755,
        0.05660419538617134,
        0.018222862854599953,
        -0.02172067016363144,
        -0.03416099771857262,
        0.039171453565359116,
        -0.028614569455385208,
        0.05509301275014877,
        0.05598410218954086,
        -0.0027623299974948168,
        0.02247615158557892,
        -0.044796716421842575,
        -0.045883167535066605,
        0.036518462002277374,
        -0.0563061498105526,
        0.055484380573034286,
        -0.007540034130215645,
        -0.011077875271439552,
        -0.05322695150971413,
        -0.0337287001311779,
        -0.055224865674972534,
        0.0561072938144207,
        -0.022520538419485092,
        -0.05607681721448898,
        -0.039938561618328094,
        -0.04293088614940643,
        -0.038912583142519,
        -0.05639052018523216,
        -0.045948922634124756,
        0.03354429453611374,
        -0.04276880994439125,
        -0.05656732618808746,
        0.0533653199672699,
        -0.05114373192191124,
        0.055897314101457596,
        0.053656913340091705,
        -0.011788539588451385,
        0.05645313858985901,
        -0.0566127747297287,
        0.013861848041415215,
        0.04941362887620926,
        -0.05391720309853554,
        0.054771412163972855,
        0.0563485249876976,
        0.05648931860923767,
        0.05239521712064743,
        -0.05587758123874664,
        0.0432601273059845,
        -0.05292998626828194,
        -0.03469650447368622,
        0.001168400514870882,
        0.05467607080936432,
        -0.05654827132821083,
        0.05612272769212723,
        -0.05646253377199173,
        0.05649092048406601,
        0.05602871626615524,
        -0.021749386563897133,
        0.056570205837488174,
        -0.02905193157494068,
        -0.056369367986917496,
        0.04789765179157257,
        0.053234826773405075,
        0.044136982411146164,
        0.0487278550863266,
        -0.054832011461257935,
        -0.005879892967641354,
        0.006694100797176361,
        -0.01928902603685856,
        -0.03949437662959099,
        -0.05054301768541336,
        -0.05511394143104553,
        -0.05587306618690491,
        -0.04963205009698868,
        0.04067258536815643,
        -0.05620874837040901,
        0.05645159259438515,
        -0.00793524831533432,
        0.05590296536684036,
        -0.05544596165418625,
        0.008406885899603367,
        0.05604696646332741,
        0.011804575100541115,
        0.05348459631204605,
        -0.056427448987960815,
        -0.03198055177927017,
        0.0163724385201931,
        -0.05627087876200676,
        0.03730878233909607,
        0.03895920142531395,
        0.03440682217478752,
        0.056565478444099426,
        0.04289848357439041,
        -0.02784130536019802,
        -0.05528352037072182,
        0.022821815684437752,
        0.03541206941008568,
        0.041349392384290695,
        -0.020016491413116455,
        0.05655030161142349,
        -0.05521571636199951,
        0.047388143837451935,
        -0.0408937931060791
    ],
    [
        -0.044837500900030136,
        0.037226833403110504,
        0.028556305915117264,
        0.027748407796025276,
        0.048952843993902206,
        -0.05772298574447632,
        0.05994241684675217,
        0.007558775134384632,
        0.009119820781052113,
        0.04783713072538376,
        0.05482075735926628,
        0.05830914154648781,
        -0.05805547907948494,
        0.018597224727272987,
        -0.05999385565519333,
        0.05227697640657425,
        -0.0550696887075901,
        0.05363146960735321,
        -0.058865901082754135,
        0.024992896243929863,
        -0.05677417293190956,
        -0.01811029203236103,
        0.05907350778579712,
        -0.0054656038992106915,
        0.02090817503631115,
        0.04256439581513405,
        -0.009923151694238186,
        0.05105792358517647,
        -0.044612765312194824,
        -0.052150629460811615,
        0.04227256029844284,
        0.005852863658219576,
        0.02215750142931938,
        -0.05678384006023407,
        0.04159846901893616,
        -0.05797584354877472,
        -0.05502193048596382,
        -0.020856788381934166,
        -0.06002448499202728,
        0.03005821257829666,
        -0.035321444272994995,
        -0.000902172178030014,
        0.05031749606132507,
        -0.04573629051446915,
        0.0194986704736948,
        0.01223366241902113,
        0.05932168290019035,
        0.06008439511060715,
        0.05819765850901604,
        0.05355435237288475,
        0.03492065519094467,
        0.03759852424263954,
        0.05570842698216438,
        -0.028004318475723267,
        0.0045927055180072784,
        -0.01374148204922676,
        -0.05399339273571968,
        -0.006640540901571512,
        -0.057085517793893814,
        0.05604133754968643,
        -0.05474930256605148,
        0.011248493567109108,
        -0.05609335005283356,
        -0.0163885448127985,
        -0.012937690131366253,
        -0.008783637546002865,
        0.047480739653110504,
        -0.055430084466934204,
        -0.04456894472241402,
        -0.060038596391677856,
        -0.05094505473971367,
        -0.05575549975037575,
        0.05973758548498154,
        -0.023532016202807426,
        0.0029502303805202246,
        0.019540341570973396,
        0.05750785768032074,
        0.054906077682971954,
        0.005752896424382925,
        -0.059285905212163925,
        -0.04914445802569389,
        0.05503798648715019,
        -0.04286588728427887,
        0.0411965474486351,
        -0.010882239788770676,
        0.02998613566160202,
        -0.049384962767362595,
        -0.05130145326256752,
        -0.020310109481215477,
        0.06012563407421112,
        -0.0172557532787323,
        0.022106314077973366,
        0.03613925352692604,
        -0.054396919906139374,
        -0.007572431582957506,
        -0.05859094858169556,
        -0.028486238792538643,
        -0.0553157776594162,
        -0.0507730133831501,
        0.04293351620435715,
        -0.05936667323112488,
        -0.0383547767996788,
        0.05823139846324921,
        0.058015838265419006,
        0.04039349779486656,
        -0.044877789914608,
        0.00534856365993619,
        0.03321360424160957,
        -0.049725890159606934,
        -0.033370211720466614,
        0.0035745424684137106,
        -0.06029484048485756,
        -0.047004781663417816,
        -0.042676158249378204,
        0.0596558041870594,
        -0.03831323981285095,
        -0.05325881391763687,
        -0.04787708818912506,
        -0.05930708721280098,
        -0.04616889730095863,
        -0.04841510206460953,
        -0.008167052641510963,
        -0.06006237864494324,
        0.030666755512356758,
        -0.060141317546367645,
        0.046170998364686966,
        -0.031037723645567894,
        -0.02342490665614605,
        0.051758356392383575,
        -0.021045824512839317,
        0.05967678874731064,
        -0.04262952506542206,
        -0.05343494936823845,
        0.027751348912715912,
        -0.05577383562922478,
        -0.04446170851588249,
        0.05873052403330803,
        -0.037952639162540436,
        0.02947474829852581,
        0.05861681327223778,
        0.058356717228889465,
        0.05389497056603432,
        0.0471770353615284,
        0.03219802677631378,
        -0.045561838895082474,
        0.02638138271868229,
        0.05884121358394623,
        -0.03332310542464256,
        -0.044540002942085266,
        0.055918723344802856,
        -0.05298705771565437,
        -0.06005245819687843,
        -0.05620965734124184,
        -0.029210656881332397,
        -0.0435800664126873,
        -0.046100422739982605,
        0.05916161462664604,
        -0.016078712418675423,
        -0.02466464228928089,
        0.012398768216371536,
        0.04274218529462814,
        -0.004170079715549946,
        0.05215570703148842,
        0.012980639934539795,
        -0.04814896360039711,
        -0.05182517692446709,
        0.0052286069840192795,
        -0.03421071171760559,
        0.006036485079675913,
        0.04721742495894432,
        -0.04022979363799095,
        0.018887260928750038,
        -0.054629575461149216,
        0.004564405884593725,
        -0.05601188912987709,
        0.027698414400219917,
        -0.05937807261943817,
        0.01590101234614849,
        0.05643577128648758,
        0.02001957967877388,
        0.05475170165300369,
        0.023076346144080162,
        0.059344321489334106,
        0.022226698696613312,
        0.019976817071437836,
        0.004914568271487951,
        -0.051797911524772644,
        0.05922742933034897,
        -0.0588839091360569,
        -0.033827535808086395,
        -0.043632786720991135,
        -0.01997610181570053,
        -0.047576889395713806,
        0.00016138190403580666,
        -0.04298323392868042,
        0.036988142877817154,
        0.06025077775120735,
        -0.044595833867788315,
        0.024216411635279655,
        0.03698267415165901,
        0.022531528025865555,
        0.004418064374476671,
        0.04863714799284935,
        0.03910593315958977,
        -0.03904678300023079,
        0.05644569173455238,
        0.04684846103191376,
        0.02685810811817646,
        -0.04831968992948532,
        -0.059052132070064545,
        -0.05256886035203934,
        0.012957517057657242,
        -0.03675910085439682,
        0.01289327535778284,
        0.05545882135629654,
        -0.029190046712756157,
        0.05956900119781494,
        -0.01444107573479414,
        0.05927358940243721,
        -0.057240717113018036,
        -0.03809170797467232,
        0.05908352881669998,
        -0.057595040649175644,
        -0.060054704546928406,
        0.0602729395031929,
        -0.06028992682695389,
        0.04413795471191406,
        0.04410078004002571,
        0.02876942977309227,
        -0.05834786593914032,
        -0.02391359768807888,
        -0.05769624933600426,
        -0.05979008227586746,
        0.043939221650362015,
        0.0017039693193510175,
        0.002349697984755039,
        0.0595846027135849,
        -0.0069441706873476505,
        0.020778490230441093,
        0.05737262964248657,
        0.05596129596233368,
        0.04040050879120827,
        -0.02594492770731449,
        0.059249553829431534,
        0.01025096233934164,
        0.0309734046459198,
        0.029474414885044098,
        -0.028497803956270218,
        0.03701482340693474,
        -0.02992044761776924,
        0.03665363788604736,
        -0.06003214418888092,
        -0.03594087436795235,
        -0.05851786211133003,
        -0.002918173559010029,
        0.059750333428382874,
        -0.037523552775382996,
        -0.024579450488090515,
        0.059220243245363235,
        0.049258165061473846,
        0.05980536341667175,
        0.049770012497901917,
        0.04034261777997017,
        0.03926605358719826,
        -0.04809774085879326,
        0.007479129359126091,
        -0.032697249203920364,
        0.03978346660733223,
        0.02477342262864113,
        -0.009725484065711498,
        0.010098743252456188,
        -0.05813112482428551,
        -0.03379471227526665,
        -0.05703726410865784,
        -0.04322423040866852,
        0.04718611389398575,
        -0.05930543318390846,
        -0.002940260572358966,
        -0.02622969262301922,
        -0.048316750675439835,
        -0.05808807164430618,
        -0.05789874866604805,
        0.044751811772584915,
        0.05428960919380188,
        0.034969694912433624,
        -0.05413557589054108,
        0.0401405431330204,
        -0.05243854224681854,
        -0.05961960181593895,
        0.030224643647670746,
        -0.0335557647049427,
        0.05331278219819069,
        -0.0036040947306901217,
        -0.031084371730685234,
        0.04500284045934677,
        -0.05406218767166138,
        0.06026894226670265,
        -0.027142014354467392,
        -0.05921003222465515,
        0.0578775517642498,
        -0.02497446909546852,
        0.04446757212281227,
        -0.021057629957795143,
        0.04740096256136894,
        0.011930352076888084,
        -0.04649997502565384,
        -0.04970205947756767,
        -0.02232082560658455,
        0.05927587300539017,
        -0.06027617305517197,
        0.002381374593824148,
        -0.05284038931131363,
        -0.01740645058453083,
        0.054474104195833206,
        -0.055703990161418915,
        -0.052300773561000824,
        0.049668408930301666,
        -0.012216692790389061,
        0.023799128830432892,
        -0.048762623220682144,
        -0.05779247730970383,
        -0.030450845137238503,
        0.05988818779587746,
        -0.007542184088379145,
        0.058147359639406204,
        0.05804520845413208,
        0.051674921065568924,
        -0.05267135426402092,
        -0.035222895443439484,
        -0.048081666231155396,
        -0.016524210572242737,
        0.015842869877815247,
        0.035498108714818954,
        -0.046300552785396576,
        0.060037221759557724,
        -0.001982460729777813,
        -0.05211874097585678,
        0.06029511243104935,
        -0.056105729192495346,
        0.002924395026639104,
        0.058062367141246796,
        -0.0027339248917996883,
        -0.05892425775527954,
        -0.059041496366262436,
        0.060420092195272446,
        0.00495715020224452,
        0.037079278379678726,
        0.05614297837018967,
        -0.009698756039142609,
        0.05579245835542679,
        -0.05829492211341858,
        0.052867840975522995,
        -0.03819521516561508,
        -0.060095563530921936,
        -0.059399429708719254,
        0.028081241995096207,
        -0.05716415494680405,
        -0.015460565686225891,
        -0.0579446479678154,
        -0.05502307415008545,
        0.04906419664621353,
        0.004029231145977974,
        -0.0585503987967968,
        0.0026485808193683624,
        0.05966272950172424,
        -0.05514157563447952,
        0.060212425887584686,
        0.03405867516994476,
        0.05041490122675896,
        -0.0599529966711998,
        -0.03615539148449898,
        -0.05625449866056442,
        0.058373671025037766,
        0.0337463915348053,
        0.059480566531419754,
        0.05843612551689148,
        0.05041594058275223,
        0.0034733281936496496,
        0.021035166457295418,
        -0.055274706333875656,
        -0.019628580659627914,
        0.057725679129362106,
        -0.05147906392812729,
        -0.00868347566574812,
        0.02075798436999321,
        -0.03043726086616516,
        -0.051798805594444275,
        -0.059798020869493484,
        -0.056652288883924484,
        -0.052888862788677216,
        0.005265568383038044,
        0.01939106173813343,
        0.03608941286802292,
        0.0101217245683074,
        -0.03477833792567253,
        0.0568302683532238,
        0.06006847694516182,
        -0.0192567165941,
        -0.03609221428632736,
        -0.0593789741396904,
        0.007862714119255543,
        0.04181062430143356,
        0.05855734273791313,
        0.059297624975442886,
        -0.007598959840834141,
        0.026458509266376495,
        0.044363658875226974,
        -0.005139975342899561,
        0.04843897745013237,
        -0.050886645913124084,
        -0.02055289037525654,
        0.02256949245929718,
        -0.041756171733140945,
        0.015557624399662018,
        0.057309605181217194,
        0.008042224682867527,
        0.03522959351539612,
        0.04560919478535652,
        0.029710939154028893,
        0.058871373534202576,
        0.025733964517712593,
        -0.05764336511492729,
        -0.05643489956855774,
        -0.0583232156932354,
        0.034084174782037735,
        0.04996028169989586,
        -0.0340791679918766,
        0.03590542450547218,
        -0.05998237058520317,
        -0.009246761910617352,
        0.0562722273170948,
        -0.03100370615720749,
        0.058406174182891846,
        0.05618095025420189,
        0.03928706794977188,
        -0.025057673454284668,
        -0.0509803332388401,
        0.059126224368810654,
        0.05248769745230675,
        -0.04783754050731659,
        -0.04094718396663666,
        -0.00497692683711648,
        0.06012209877371788,
        0.05661824718117714,
        -0.0565280057489872,
        -0.007331665139645338,
        0.05676399916410446,
        -0.05767214670777321,
        0.05348256975412369,
        -0.05681861191987991,
        -0.03839578479528427,
        0.03385312855243683,
        0.056018728762865067,
        -0.04960794001817703,
        0.0295169185847044,
        -0.03628821671009064,
        0.05029607564210892,
        0.01733642816543579,
        -0.05583508312702179,
        0.05023088678717613,
        -0.03160242363810539,
        -0.04598876088857651,
        -0.04444701224565506,
        -0.05490703880786896,
        0.048928502947092056,
        0.060208920389413834,
        -0.060117240995168686,
        0.055010829120874405,
        -0.06034470349550247,
        -0.059529222548007965,
        -0.013792748562991619,
        0.00862776767462492,
        0.0578567199409008,
        0.04521802067756653,
        0.05884755775332451,
        0.03295472264289856,
        -0.056478872895240784,
        -0.017039930447936058,
        -0.03906663507223129,
        -0.04376263916492462,
        -0.049359213560819626,
        0.041887007653713226,
        0.0018530357629060745,
        0.03186720609664917,
        0.052986904978752136,
        0.010841370560228825,
        0.05312804877758026,
        0.00803942047059536,
        -0.04996549338102341,
        -0.04487183317542076,
        -0.027607349678874016,
        0.02143682725727558,
        0.05238189920783043,
        0.03972619026899338,
        0.054231639951467514,
        0.006502751726657152,
        -0.05726953223347664,
        -0.041132956743240356,
        0.058129068464040756,
        0.043129947036504745,
        -0.045917410403490067,
        -0.05522100254893303,
        -0.05605345219373703,
        0.02098146080970764,
        0.057903263717889786,
        0.03830738365650177,
        -0.007817463018000126,
        0.020530225709080696,
        -0.053561244159936905,
        -0.052194222807884216,
        0.06009722873568535,
        0.06040969491004944
    ],
    [
        -0.05209564417600632,
        -0.0026698890142142773,
        0.05238774046301842,
        0.01752442866563797,
        0.051648881286382675,
        -0.05896107479929924,
        0.06041473522782326,
        -0.0614626370370388,
        0.05077337846159935,
        0.053954243659973145,
        0.037117306143045425,
        0.06134118139743805,
        -0.06196966394782066,
        -0.0353124737739563,
        -0.06129080802202225,
        0.005233822390437126,
        -0.06220254674553871,
        0.058801036328077316,
        -0.05433632805943489,
        0.027025436982512474,
        -0.011423840187489986,
        0.053188640624284744,
        0.06076550856232643,
        0.017264433205127716,
        0.030319558456540108,
        0.02939498983323574,
        0.03182007744908333,
        0.06181136146187782,
        -0.043260447680950165,
        -0.027316439896821976,
        0.02898007445037365,
        0.002120817778632045,
        0.03313855081796646,
        -0.06026254966855049,
        -0.016704928129911423,
        0.0166437067091465,
        -0.06040138006210327,
        -0.05995624139904976,
        0.007109116762876511,
        0.027381768450140953,
        0.00801959726959467,
        -0.006035150494426489,
        0.05477956682443619,
        -0.016253065317869186,
        0.054564885795116425,
        0.007158192340284586,
        0.008792293258011341,
        0.06212214007973671,
        0.061150629073381424,
        0.030906055122613907,
        0.0014949972974136472,
        -0.012599813751876354,
        0.01948837749660015,
        -0.008647206239402294,
        0.053379204124212265,
        0.04947929456830025,
        -0.0576966255903244,
        -0.021463189274072647,
        -0.004336274694651365,
        0.05230925604701042,
        -0.056773096323013306,
        -0.03571058064699173,
        -0.055041875690221786,
        0.02567925490438938,
        0.03805355727672577,
        0.04429137706756592,
        0.05592847988009453,
        -0.0587087981402874,
        0.059571534395217896,
        -0.06254426389932632,
        0.0244386438280344,
        -0.06091709062457085,
        0.025998828932642937,
        0.040857911109924316,
        -0.017358506098389626,
        -0.021883228793740273,
        0.05847931653261185,
        0.06239120289683342,
        0.04733073711395264,
        -0.04749612137675285,
        -0.02248721569776535,
        0.047190725803375244,
        -0.0386323407292366,
        0.012711755931377411,
        0.026015110313892365,
        0.040712106972932816,
        -0.03389071673154831,
        0.032406724989414215,
        -0.042312152683734894,
        0.061142697930336,
        -0.05029943957924843,
        0.006601229775696993,
        -0.012071464210748672,
        -0.05912339314818382,
        -0.025670696049928665,
        0.0017000336665660143,
        -0.0348106324672699,
        -0.032278865575790405,
        -0.014637406915426254,
        0.025794725865125656,
        -0.062431368976831436,
        -0.01893571764230728,
        0.06053249165415764,
        -0.029267795383930206,
        -0.047445833683013916,
        0.0112160574644804,
        0.008403154090046883,
        0.050857216119766235,
        -0.03986334800720215,
        -0.058975767344236374,
        -0.010195404291152954,
        -0.06181931495666504,
        -0.0499601736664772,
        -0.02056472934782505,
        0.05193089693784714,
        -0.05894438549876213,
        0.03045974113047123,
        -0.05700050666928291,
        -0.05894146487116814,
        -0.038383848965168,
        -0.002299901330843568,
        -0.014372461475431919,
        -0.05456911772489548,
        -0.005784585140645504,
        -0.06060878559947014,
        -0.011073198169469833,
        -0.054657381027936935,
        0.06092962995171547,
        0.05737898126244545,
        -0.033649176359176636,
        0.05752154067158699,
        -0.030675409361720085,
        0.011997075751423836,
        -0.01667655259370804,
        -0.055636804550886154,
        -0.05510755628347397,
        0.061147432774305344,
        -0.034581296145915985,
        -0.030874526128172874,
        0.048031002283096313,
        -0.029622897505760193,
        0.05280908942222595,
        0.013716577552258968,
        0.054890744388103485,
        -0.03155937045812607,
        0.013215121813118458,
        0.010548919439315796,
        -0.05728062987327576,
        -0.002528516110032797,
        0.03390908241271973,
        -0.054381515830755234,
        -0.062319185584783554,
        -0.06150586158037186,
        0.03696586564183235,
        -0.015882570296525955,
        -0.06068311259150505,
        0.0611114427447319,
        -0.05195470526814461,
        0.005582048557698727,
        -0.0541653111577034,
        0.06169869378209114,
        0.04964505881071091,
        0.03585357591509819,
        0.04840066283941269,
        -0.05223643407225609,
        -0.060230351984500885,
        0.06140884757041931,
        -0.058599404990673065,
        0.019300490617752075,
        0.04908345639705658,
        -0.014885378070175648,
        -0.013781208544969559,
        -0.056577417999506,
        -0.03864946961402893,
        -0.05453641340136528,
        0.0611119344830513,
        -0.06244553253054619,
        0.04496903717517853,
        0.056599561125040054,
        0.05467767268419266,
        0.050637852400541306,
        0.03127244487404823,
        0.06232580915093422,
        -0.029085788875818253,
        0.04664227366447449,
        0.020725451409816742,
        -0.05955373868346214,
        0.056101370602846146,
        -0.06013449653983116,
        -0.03172354772686958,
        -0.01446226891130209,
        -0.05281075835227966,
        -0.03907617926597595,
        0.03725215792655945,
        0.0017733871936798096,
        0.001785708125680685,
        0.0614062137901783,
        0.01516040600836277,
        0.022517239674925804,
        0.0033215731382369995,
        0.0299928467720747,
        -0.0009369596955366433,
        0.05877944454550743,
        0.03780890256166458,
        0.037615980952978134,
        0.03762874752283096,
        0.02597063034772873,
        0.03525438532233238,
        -0.06118261069059372,
        0.032650526612997055,
        -0.05972347781062126,
        0.047941651195287704,
        -0.01907953992486,
        -0.03983438387513161,
        0.05844968929886818,
        -0.035411566495895386,
        -0.03957406058907509,
        0.0280307624489069,
        0.06240496039390564,
        -0.05771741643548012,
        -0.047326598316431046,
        -0.0106400977820158,
        -0.06148887425661087,
        -0.06161867827177048,
        0.06081460416316986,
        -0.06254313141107559,
        -0.011509738862514496,
        0.003143669106066227,
        -0.017873330041766167,
        -0.058608029037714005,
        -0.04445263743400574,
        -0.06101137027144432,
        -0.02943710796535015,
        0.059464577585458755,
        0.023967670276761055,
        -0.04930403083562851,
        0.06162901595234871,
        -0.037401724606752396,
        0.031184351071715355,
        0.05779436230659485,
        0.04876593127846718,
        0.02560523897409439,
        0.023255301639437675,
        0.0616188645362854,
        -0.0014305772492662072,
        0.028999775648117065,
        0.060111019760370255,
        0.045163679867982864,
        -0.03380174934864044,
        0.003249661298468709,
        0.016394086182117462,
        -0.0622377023100853,
        -0.03947621211409569,
        -0.06100965291261673,
        0.005594961810857058,
        0.06233835965394974,
        0.011980597861111164,
        0.04904324933886528,
        0.06198804825544357,
        0.05562063679099083,
        0.061916425824165344,
        0.060651108622550964,
        0.02341301366686821,
        0.05831021070480347,
        0.02302650362253189,
        0.011025557294487953,
        0.043209150433540344,
        -0.016374096274375916,
        0.010217154398560524,
        0.011563350446522236,
        -0.047414470463991165,
        -0.062292467802762985,
        0.02017601579427719,
        -0.0610632561147213,
        -0.05876949429512024,
        0.053819797933101654,
        -0.061225708574056625,
        -0.029158951714634895,
        -0.053779274225234985,
        -0.05699453502893448,
        -0.05539572238922119,
        0.027612775564193726,
        -0.014004599303007126,
        0.016287418082356453,
        0.008686624467372894,
        -0.03443063423037529,
        0.047784361988306046,
        -0.05928073823451996,
        -0.057978615164756775,
        0.024561872705817223,
        -0.04816185310482979,
        0.008511603809893131,
        0.049371421337127686,
        -0.0441984124481678,
        0.05607889965176582,
        -0.05432906746864319,
        0.06249815970659256,
        -0.03664340823888779,
        -0.036521829664707184,
        0.06159693002700806,
        -0.04896878823637962,
        0.05371550843119621,
        -0.024077005684375763,
        0.05538846179842949,
        0.011984627693891525,
        -0.052949003875255585,
        -0.02920723147690296,
        0.025100918486714363,
        0.06029294431209564,
        -0.059804897755384445,
        -0.03966403007507324,
        -0.014901958405971527,
        -0.04827527701854706,
        0.035451490432024,
        -0.06095229834318161,
        -0.059127721935510635,
        0.037763163447380066,
        0.04114963114261627,
        0.04090845212340355,
        -0.05742970481514931,
        -0.016588415950536728,
        -0.054944947361946106,
        0.06237708404660225,
        -0.029702354222536087,
        0.04511136934161186,
        0.04803736135363579,
        0.061185020953416824,
        -0.006338032428175211,
        0.0021911337971687317,
        -0.024316757917404175,
        -0.047744642943143845,
        0.03146360069513321,
        0.05417056754231453,
        0.014887500554323196,
        0.061196230351924896,
        -0.04360749572515488,
        -0.05448545888066292,
        0.06251588463783264,
        -0.012723447754979134,
        0.017015811055898666,
        0.04874652996659279,
        -0.029941683635115623,
        0.04736817628145218,
        -0.05435194447636604,
        0.06256963312625885,
        0.044658176600933075,
        0.04279892519116402,
        0.0510806068778038,
        0.05235064774751663,
        0.06027301400899887,
        -0.06231049820780754,
        -0.03195660188794136,
        -0.019917797297239304,
        -0.05862446129322052,
        -0.04906614497303963,
        0.06115756928920746,
        -0.05674993619322777,
        -0.026824912056326866,
        -0.06225474923849106,
        -0.06213098019361496,
        0.05001278966665268,
        0.014947627671062946,
        -0.06095530092716217,
        0.030796458944678307,
        -0.044134341180324554,
        -0.03933274745941162,
        0.062331028282642365,
        0.038601119071245193,
        0.012963322922587395,
        -0.062376346439123154,
        -0.024118101224303246,
        -0.0613945834338665,
        0.059948164969682693,
        0.039450474083423615,
        0.06064309924840927,
        0.05643298849463463,
        0.04295302927494049,
        0.04444197565317154,
        0.04277288168668747,
        -0.060159798711538315,
        -0.00822028610855341,
        0.05590130761265755,
        -0.06055890768766403,
        0.02915015257894993,
        0.03440806269645691,
        0.019228730350732803,
        -0.028788721188902855,
        -0.05843806266784668,
        -0.05127193406224251,
        -0.03855380415916443,
        -0.03656471520662308,
        0.0097366813570261,
        0.036715082824230194,
        0.034690748900175095,
        -0.006449329666793346,
        0.06044531613588333,
        0.06125233694911003,
        -0.009582804515957832,
        -0.05087354779243469,
        -0.06225748360157013,
        -0.04661712795495987,
        0.05830644071102142,
        0.04272482916712761,
        0.06227068230509758,
        -0.050784263759851456,
        -0.021708987653255463,
        0.04941694810986519,
        0.053140509873628616,
        0.05982402712106705,
        -0.028381943702697754,
        0.039580632001161575,
        0.01351313479244709,
        0.029714593663811684,
        -0.03440346568822861,
        0.0016507122199982405,
        0.01959279365837574,
        0.04260136932134628,
        -0.016604024916887283,
        0.030884716659784317,
        0.05739651247859001,
        -0.05675313621759415,
        -0.006725931074470282,
        -0.05063396319746971,
        -0.05998506769537926,
        0.06056450679898262,
        0.004059848375618458,
        -0.040241822600364685,
        -0.026392487809062004,
        -0.06233969330787659,
        -0.04668044298887253,
        0.04544779285788536,
        -0.02050079219043255,
        0.03416541591286659,
        0.04633893445134163,
        0.056552063673734665,
        0.018856273964047432,
        -0.06056864932179451,
        -0.0015376604860648513,
        0.04491009563207626,
        -0.027318689972162247,
        -0.01862289197742939,
        0.014190946705639362,
        0.05983788147568703,
        0.0603160597383976,
        0.05347535386681557,
        0.05486072972416878,
        0.06149428337812424,
        -0.04368231073021889,
        0.06057159602642059,
        -0.056829143315553665,
        -0.022318299859762192,
        0.05229127034544945,
        0.05156327784061432,
        0.004374440759420395,
        -0.01832929253578186,
        -0.04163525998592377,
        0.033018745481967926,
        0.005748415365815163,
        0.0039519923739135265,
        0.013599405065178871,
        -0.009159081615507603,
        -0.048808179795742035,
        -0.020713327452540398,
        -0.015470290556550026,
        0.045225273817777634,
        0.06233057379722595,
        -0.062486227601766586,
        0.05941274017095566,
        -0.05426189303398132,
        -0.061857376247644424,
        0.05411744490265846,
        0.0538521334528923,
        0.04806824401021004,
        0.06048140674829483,
        0.061006899923086166,
        0.057515788823366165,
        -0.02990933135151863,
        -0.04367316886782646,
        -0.03017103113234043,
        -0.054252997040748596,
        -0.05371437221765518,
        -0.04323475807905197,
        0.05449520796537399,
        0.042820073664188385,
        0.012647920288145542,
        0.05359399691224098,
        0.049942828714847565,
        0.03790861368179321,
        -0.05262995883822441,
        -0.028561338782310486,
        -0.05153394117951393,
        -0.03889978677034378,
        0.004643358755856752,
        0.0054215132258832455,
        0.04863802716135979,
        0.05195589363574982,
        -0.04371808096766472,
        -0.030059659853577614,
        0.06057899072766304,
        0.056862663477659225,
        -0.004907427821308374,
        -0.006961819715797901,
        0.015576268546283245,
        0.05372507870197296,
        0.03498964011669159,
        0.03307691588997841,
        -0.028683509677648544,
        -0.022558584809303284,
        -0.05605638772249222,
        -0.05155729874968529,
        0.0613873116672039,
        0.04477023705840111
    ],
    [
        -0.05596017464995384,
        -0.056255411356687546,
        -0.03762499988079071,
        0.031074881553649902,
        -0.03172944486141205,
        -0.04609217867255211,
        0.05310520529747009,
        -0.04931727051734924,
        -0.042477481067180634,
        0.05476519092917442,
        0.017409421503543854,
        -0.04068279266357422,
        -0.05269413813948631,
        0.038693178445100784,
        -0.05472990870475769,
        0.007299839518964291,
        -0.0562056265771389,
        0.05584881827235222,
        -0.05626853182911873,
        0.03336575627326965,
        -0.03923261910676956,
        -0.05001349374651909,
        0.02606344036757946,
        0.035742104053497314,
        -0.003035902511328459,
        0.04938453435897827,
        -0.030905824154615402,
        0.0562199130654335,
        -0.023310227319598198,
        -0.013227266259491444,
        0.056087445467710495,
        0.053900282829999924,
        -0.05508142337203026,
        -0.056230295449495316,
        0.04212619736790657,
        -0.026054594665765762,
        -0.05532361567020416,
        -0.053391922265291214,
        -0.05624520778656006,
        0.01946437917649746,
        0.04514070600271225,
        -0.05048255994915962,
        0.04771432653069496,
        -0.05319729447364807,
        0.052670493721961975,
        0.034997276961803436,
        -0.014640215784311295,
        0.055764514952898026,
        0.05621601641178131,
        0.03224163129925728,
        0.01534322276711464,
        -0.0029878071509301662,
        0.056102171540260315,
        -0.037080369889736176,
        -0.03130222111940384,
        0.012845635414123535,
        -0.0034046792425215244,
        -0.03740546107292175,
        0.013428988866508007,
        -0.05375634506344795,
        -0.053150638937950134,
        -0.05368174612522125,
        -0.0542939119040966,
        0.009632495231926441,
        -0.042560137808322906,
        -0.04694118723273277,
        0.05133336782455444,
        -0.04921292886137962,
        0.05624333396553993,
        -0.05627162382006645,
        -0.05466480925679207,
        -0.05627039074897766,
        -0.033014342188835144,
        -0.045434098690748215,
        0.022985011339187622,
        -0.01599901355803013,
        -0.016114559024572372,
        0.05611493065953255,
        0.03076736256480217,
        0.013225275091826916,
        -0.0027240111958235502,
        -0.011740844696760178,
        -0.02074124477803707,
        0.05219755694270134,
        0.047993339598178864,
        0.052711352705955505,
        -0.013877605088055134,
        0.0562702976167202,
        0.016723284497857094,
        0.04943329840898514,
        -0.05186740681529045,
        0.054704807698726654,
        0.022115381434559822,
        -0.05556356534361839,
        -0.03933310881257057,
        0.017086224630475044,
        -0.05531618744134903,
        -0.05580606684088707,
        -0.05528782680630684,
        -0.0006054918048903346,
        -0.05381782352924347,
        0.010026475414633751,
        0.055920518934726715,
        -0.05314456298947334,
        0.05389843508601189,
        -0.001676091575063765,
        -0.05617399886250496,
        0.0472198985517025,
        -0.05579852685332298,
        -0.042609747499227524,
        -0.02944708801805973,
        -0.055576689541339874,
        0.02361852116882801,
        -0.020678771659731865,
        -0.02776636742055416,
        -0.05608677119016647,
        0.04489799216389656,
        0.0222872756421566,
        -0.05626869201660156,
        0.0012765044812113047,
        0.05584511533379555,
        0.03497887775301933,
        0.0332055389881134,
        0.021337104961276054,
        -0.055397771298885345,
        -0.000442833814304322,
        -0.01899545080959797,
        -0.045208271592855453,
        0.05444294214248657,
        -0.02443866804242134,
        0.05007508024573326,
        0.004558842163532972,
        -0.055157121270895004,
        0.04249359667301178,
        -0.05260373279452324,
        -0.05439763888716698,
        0.05377843603491783,
        -0.05622417852282524,
        -0.056133680045604706,
        0.05595624819397926,
        -0.030270257964730263,
        -0.01995236612856388,
        0.05091647431254387,
        0.049522824585437775,
        0.03658737987279892,
        0.04927912726998329,
        -0.052698634564876556,
        -0.05513186752796173,
        -0.027956604957580566,
        0.05347469821572304,
        -0.038571376353502274,
        -0.05600569769740105,
        -0.05536780133843422,
        0.05578361079096794,
        -0.035086363554000854,
        -0.05546663701534271,
        0.05607707425951958,
        0.0023449428845196962,
        -0.05490707978606224,
        -0.046584125608205795,
        0.05618638917803764,
        0.014432338997721672,
        0.04461800679564476,
        -0.009481140412390232,
        -0.027622954919934273,
        -0.05266926810145378,
        -0.03786792606115341,
        -0.055394385010004044,
        0.052919141948223114,
        0.050715092569589615,
        -0.043824464082717896,
        0.0479339174926281,
        -0.041490230709314346,
        -0.052308011800050735,
        -0.055723272264003754,
        0.05608179420232773,
        -0.05625801905989647,
        -0.0075856647454202175,
        -0.03490918502211571,
        0.037457842379808426,
        0.04586894065141678,
        0.05560096353292465,
        0.05542305111885071,
        -0.03781022131443024,
        0.038256824016571045,
        0.05546831712126732,
        -0.05534518137574196,
        0.04874412342905998,
        0.0512285940349102,
        -0.05194379761815071,
        -0.05427165701985359,
        -0.02247517742216587,
        -0.053754616528749466,
        0.04310120269656181,
        -0.043482664972543716,
        0.0006518300506286323,
        0.05394432321190834,
        0.05374050512909889,
        -0.055755969136953354,
        0.0529073067009449,
        0.02374408021569252,
        0.05621837452054024,
        0.038721270859241486,
        0.056239672005176544,
        0.05577804148197174,
        0.020043421536684036,
        0.05311564356088638,
        0.05569566413760185,
        -0.0561644472181797,
        0.0558532290160656,
        -0.05266346037387848,
        0.05558536574244499,
        -0.04618431627750397,
        -0.03928041830658913,
        -0.01063222624361515,
        -0.05612800270318985,
        0.0555744469165802,
        -0.0538485012948513,
        0.056228406727313995,
        -0.056135471910238266,
        -0.029693836346268654,
        -0.044217053800821304,
        -0.04198530688881874,
        -0.056196074932813644,
        0.05251726880669594,
        -0.055939819663763046,
        -0.0021484638564288616,
        0.05213765799999237,
        -0.049217116087675095,
        -0.0538637600839138,
        0.04718168452382088,
        -0.056071873754262924,
        0.018134543672204018,
        0.04728609696030617,
        0.04652181267738342,
        0.0308225154876709,
        0.055423393845558167,
        0.05488156899809837,
        -0.05198216065764427,
        0.05083897337317467,
        -0.037723466753959656,
        -0.003960233647376299,
        -0.05158426985144615,
        -0.03313635662198067,
        0.03040158934891224,
        0.054791808128356934,
        0.0561433807015419,
        0.056261174380779266,
        0.006960935890674591,
        0.04242194443941116,
        0.03491319343447685,
        -0.05421420559287071,
        -0.044620901346206665,
        -0.05057429522275925,
        -0.042551420629024506,
        0.05333186686038971,
        -0.04672327637672424,
        0.03967534750699997,
        0.0519254170358181,
        0.056248899549245834,
        -0.03868340328335762,
        0.0034098285250365734,
        0.037056438624858856,
        0.054153747856616974,
        -0.04324675351381302,
        0.011474773287773132,
        0.021377824246883392,
        -0.05139760300517082,
        0.05050848424434662,
        0.0013850042596459389,
        -0.020743781700730324,
        -0.05624796077609062,
        0.05586158111691475,
        -0.05595064535737038,
        -0.05622045695781708,
        0.05375806614756584,
        -0.0515621043741703,
        -0.009195051155984402,
        -0.022331614047288895,
        0.04651772975921631,
        -0.038585737347602844,
        -0.015840452164411545,
        -0.023743195459246635,
        0.007440981455147266,
        0.03005465865135193,
        -0.04525873810052872,
        0.04311151057481766,
        -0.055815089493989944,
        -0.054929591715335846,
        0.051811158657073975,
        -0.051400382071733475,
        -0.02672410197556019,
        0.019523967057466507,
        0.020688606426119804,
        -0.0054331254214048386,
        -0.054904669523239136,
        0.05626934394240379,
        -0.052832599729299545,
        -0.029930250719189644,
        0.055516090244054794,
        0.052212879061698914,
        0.04782171547412872,
        0.0016394135309383273,
        0.049532391130924225,
        -0.054020754992961884,
        -0.04833896830677986,
        -0.05379845201969147,
        0.052570048719644547,
        0.045905325561761856,
        -0.05077475681900978,
        0.0238010436296463,
        -0.0402313694357872,
        -0.02833164669573307,
        0.0559142529964447,
        -0.0561722032725811,
        -0.055402178317308426,
        0.050692908465862274,
        0.05507432669401169,
        0.01903066784143448,
        -0.05518011748790741,
        -0.03363896161317825,
        -0.03918932005763054,
        0.018999451771378517,
        -0.05158083140850067,
        0.011181111447513103,
        0.05352140590548515,
        0.05246467515826225,
        0.039706286042928696,
        0.03342276066541672,
        -0.0008580158464610577,
        -0.043154243379831314,
        0.055086661130189896,
        -0.005891193635761738,
        0.025190478190779686,
        0.05467045679688454,
        0.032491542398929596,
        -0.007642798125743866,
        0.05580798536539078,
        -0.03756028041243553,
        0.05485349893569946,
        0.03372868895530701,
        -0.055934201925992966,
        0.055916573852300644,
        -0.054525911808013916,
        0.05627170205116272,
        0.04166477918624878,
        -0.04038962721824646,
        0.04361363500356674,
        -0.023993859067559242,
        0.055247943848371506,
        -0.05627170205116272,
        0.05595221742987633,
        -0.04216765984892845,
        0.024713994935154915,
        -0.05419335886836052,
        0.05594849959015846,
        -0.056212857365608215,
        -0.05547863990068436,
        -0.05237722769379616,
        -0.05361712723970413,
        0.030350366607308388,
        -0.007744334638118744,
        -0.0543970949947834,
        -0.02215154655277729,
        0.017700064927339554,
        -0.04568895697593689,
        0.056234151124954224,
        0.0040939124301075935,
        -0.01983313448727131,
        -0.05603396147489548,
        -0.020629625767469406,
        -0.036142900586128235,
        0.05479920282959938,
        -0.049204032868146896,
        0.05563952028751373,
        0.033823803067207336,
        -0.05591542646288872,
        0.029754675924777985,
        -0.048483848571777344,
        -0.052140239626169205,
        0.0075238351710140705,
        0.03900616988539696,
        -0.05580226331949234,
        0.03578217700123787,
        0.039993610233068466,
        -0.05439196527004242,
        -0.007427432108670473,
        -0.05503372475504875,
        -0.054612744599580765,
        -0.05579734966158867,
        -0.05598762258887291,
        0.04592157155275345,
        -0.023263605311512947,
        0.05181200057268143,
        -0.04678044840693474,
        0.04499496892094612,
        0.05583282932639122,
        0.05126548185944557,
        -0.021098094061017036,
        -0.05575093254446983,
        -0.05204471945762634,
        0.05441657826304436,
        0.05569632351398468,
        0.056265249848365784,
        -0.051611147820949554,
        0.0014310277765616775,
        0.055199816823005676,
        0.018099244683980942,
        0.055959511548280716,
        -0.05426338315010071,
        0.056271567940711975,
        0.007376258261501789,
        0.052901823073625565,
        -0.000012476495612645522,
        -0.000814843166153878,
        0.030453436076641083,
        0.05304486304521561,
        -0.03082921728491783,
        0.005231474991887808,
        -0.0416179858148098,
        -0.005626062396913767,
        -0.0418512262403965,
        0.029990751296281815,
        -0.0559820681810379,
        0.03651167079806328,
        -0.05618414655327797,
        0.032444484531879425,
        -0.03122878633439541,
        -0.05368189513683319,
        -0.05406525731086731,
        0.034220099449157715,
        -0.003870014101266861,
        -0.05625203624367714,
        0.03208522871136665,
        -0.015842346474528313,
        0.02447454445064068,
        -0.05623118579387665,
        0.042199958115816116,
        -0.004296830389648676,
        -0.03126657381653786,
        -0.049936577677726746,
        0.05531914904713631,
        0.019866548478603363,
        0.051145341247320175,
        0.05617983266711235,
        0.05316104739904404,
        0.05466306209564209,
        -0.05625605955719948,
        0.03319252282381058,
        -0.055158887058496475,
        -0.040131546556949615,
        0.05472375079989433,
        0.05570781230926514,
        0.006206744350492954,
        -0.006608541589230299,
        -0.049228232353925705,
        -0.03973905369639397,
        0.04672665521502495,
        -0.04757178947329521,
        0.048838358372449875,
        0.034191910177469254,
        -0.02289310283958912,
        -0.05574942007660866,
        0.0469159334897995,
        0.05563810095191002,
        0.056091900914907455,
        -0.056161779910326004,
        0.05610683187842369,
        -0.0006712456815876067,
        -0.049591731280088425,
        0.023617153987288475,
        0.055934883654117584,
        0.04535898193717003,
        -0.027185088023543358,
        0.056216754019260406,
        0.0488169863820076,
        0.04838695004582405,
        -0.03400424122810364,
        -0.03164088726043701,
        -0.04717116430401802,
        -0.0561787448823452,
        -0.0445236898958683,
        -0.001926113967783749,
        0.025305865332484245,
        -0.054751936346292496,
        0.05619652569293976,
        -0.027919521555304527,
        -0.02991303987801075,
        -0.05304405838251114,
        0.05553542450070381,
        -0.03328659385442734,
        -0.0562710277736187,
        -0.019485479220747948,
        -0.010983514599502087,
        0.05560722202062607,
        -0.005578939337283373,
        -0.056081756949424744,
        0.05151316523551941,
        0.05448980629444122,
        0.021574947983026505,
        0.05581684410572052,
        0.004062372259795666,
        0.0004740391450468451,
        -0.05342162027955055,
        -0.050426144152879715,
        -0.030065109953284264,
        -0.05602288246154785,
        -0.033439166843891144,
        -0.01370068360120058,
        0.017890794202685356,
        0.05603459104895592,
        -0.04630269482731819
    ],
    [
        -0.05439866706728935,
        -0.010422797873616219,
        0.023096492514014244,
        0.003976066596806049,
        -0.044160109013319016,
        -0.05246174708008766,
        0.05419893190264702,
        -0.05443836376070976,
        0.05434449762105942,
        0.05394980311393738,
        0.05453580245375633,
        0.04532285034656525,
        -0.05336609110236168,
        -0.0080494973808527,
        -0.054437484592199326,
        0.04518522322177887,
        -0.02578175812959671,
        0.052757635712623596,
        0.005013934336602688,
        0.05198712274432182,
        0.04823858663439751,
        -0.04354196414351463,
        -0.04039449244737625,
        -0.05350036546587944,
        -0.036933980882167816,
        0.053402841091156006,
        -0.0015612159622833133,
        -0.009572405368089676,
        -0.054346345365047455,
        -0.0532083623111248,
        -0.047712113708257675,
        0.0007597613730467856,
        0.04911503940820694,
        -0.05440306290984154,
        0.052672289311885834,
        -0.009909804910421371,
        -0.054340507835149765,
        -0.04226156324148178,
        0.05092011019587517,
        -0.0056811003014445305,
        -0.054492756724357605,
        0.05293743312358856,
        0.03333381935954094,
        0.037780679762363434,
        0.013206802308559418,
        0.039568476378917694,
        0.04817282408475876,
        0.054174162447452545,
        0.05456578731536865,
        -0.038401246070861816,
        0.05443534627556801,
        0.05357977747917175,
        -0.04108894243836403,
        -0.04244230315089226,
        -0.04855569824576378,
        0.013232128694653511,
        -0.04328740015625954,
        -0.02307562157511711,
        -0.041715480387210846,
        -0.051611628383398056,
        -0.05384708195924759,
        0.02611224725842476,
        -0.05416253209114075,
        -0.042799659073352814,
        0.04310397431254387,
        -0.05434766784310341,
        -0.05382288992404938,
        -0.04683811217546463,
        0.05174988508224487,
        -0.024394286796450615,
        -0.05181503668427467,
        0.017755040898919106,
        0.05415378138422966,
        0.02819151245057583,
        -0.05198202654719353,
        0.036458175629377365,
        0.05331578478217125,
        0.05459856241941452,
        0.006128123961389065,
        0.043709903955459595,
        -0.05329226702451706,
        -0.04872725158929825,
        -0.011728822253644466,
        0.05412276089191437,
        -0.03089774027466774,
        0.04931913688778877,
        -0.05363386869430542,
        -0.022533144801855087,
        -0.044001899659633636,
        0.05458712950348854,
        0.01067701168358326,
        -0.03514019772410393,
        -0.045812126249074936,
        -0.038210999220609665,
        -0.04763897508382797,
        -0.016341546550393105,
        -0.034542515873909,
        -0.05203628912568092,
        0.04307449981570244,
        0.03818800672888756,
        -0.054592106491327286,
        0.05128856375813484,
        0.0499260313808918,
        -0.052704546600580215,
        0.02267247997224331,
        -0.04880671575665474,
        -0.051329124718904495,
        0.047926515340805054,
        -0.050985559821128845,
        0.04164789617061615,
        0.04864664748311043,
        -0.05449990555644035,
        -0.038393206894397736,
        0.025189217180013657,
        0.054394904524087906,
        -0.022548722103238106,
        0.035133372992277145,
        -0.05029059946537018,
        0.05178079009056091,
        -0.0431172139942646,
        -0.05100717023015022,
        -0.02499261125922203,
        -0.02446535974740982,
        -0.04291513189673424,
        -0.04605546593666077,
        -0.045924946665763855,
        0.03658227622509003,
        0.03823718801140785,
        0.04225786775350571,
        0.03849340230226517,
        0.05450756847858429,
        -0.03705392777919769,
        -0.05440714955329895,
        0.009383318945765495,
        -0.04945510998368263,
        -0.05435118079185486,
        0.05433418229222298,
        -0.05448643118143082,
        -0.054562121629714966,
        -0.020368123427033424,
        -0.029276754707098007,
        -0.05458955466747284,
        0.02445855364203453,
        0.054590120911598206,
        -0.0251460000872612,
        0.05220174789428711,
        -0.02003946155309677,
        -0.036711543798446655,
        0.05259503796696663,
        -0.052348922938108444,
        -0.039044152945280075,
        -0.05459504574537277,
        -0.04289516434073448,
        -0.054107919335365295,
        0.04647352173924446,
        -0.05145883932709694,
        0.050755180418491364,
        0.020026879385113716,
        0.050474487245082855,
        0.0365632027387619,
        0.0003034809196833521,
        0.053898561745882034,
        0.05439072847366333,
        0.05157242342829704,
        -0.031007729470729828,
        -0.040238454937934875,
        0.04804248735308647,
        -0.040252916514873505,
        -0.03176006302237511,
        0.04459553584456444,
        -0.05075465887784958,
        -0.05398810654878616,
        -0.05226922780275345,
        0.053641706705093384,
        -0.042007386684417725,
        0.024345746263861656,
        0.007350758649408817,
        -0.04383956640958786,
        0.05436503514647484,
        -0.0524991974234581,
        -0.053929053246974945,
        0.02201322838664055,
        0.05380602553486824,
        -0.05457432568073273,
        0.048745155334472656,
        0.05401063710451126,
        0.005185252521187067,
        0.04838695377111435,
        -0.03713249787688255,
        -0.04761810973286629,
        -0.04888725280761719,
        0.036648619920015335,
        -0.05366195738315582,
        0.05453227460384369,
        -0.05210863798856735,
        0.050594620406627655,
        0.046955738216638565,
        0.05032353103160858,
        0.015790602192282677,
        0.044935427606105804,
        0.04482913389801979,
        0.051491763442754745,
        -0.0412072129547596,
        -0.053203240036964417,
        -0.04453880339860916,
        0.05301094800233841,
        0.05427330732345581,
        0.024219416081905365,
        -0.04790791496634483,
        0.05448593571782112,
        -0.05306193605065346,
        0.04012320935726166,
        0.051502760499715805,
        0.05277981236577034,
        0.053582657128572464,
        -0.05459442734718323,
        0.032508306205272675,
        -0.02079678140580654,
        0.054515764117240906,
        -0.05425229296088219,
        -0.016500025987625122,
        0.053536903113126755,
        -0.050781749188899994,
        0.03709135949611664,
        0.00232712016440928,
        -0.05351971089839935,
        0.052721764892339706,
        0.04792409390211105,
        0.053354497998952866,
        -0.05364007130265236,
        -0.050431057810783386,
        -0.001911526545882225,
        -0.008572428487241268,
        0.051370684057474136,
        0.020557809621095657,
        0.04933761805295944,
        0.05450429022312164,
        0.007069987244904041,
        -0.05429058149456978,
        0.05459748953580856,
        0.03971687704324722,
        -0.052931178361177444,
        0.052849188446998596,
        0.052931640297174454,
        0.05231044068932533,
        0.04888695850968361,
        0.04887503758072853,
        0.051876250654459,
        0.042324624955654144,
        -0.05423234775662422,
        -0.04998229816555977,
        -0.053810760378837585,
        -0.04893972724676132,
        -0.054165106266736984,
        0.03945411369204521,
        0.054183848202228546,
        0.053033456206321716,
        -0.053219448775053024,
        0.048376403748989105,
        0.009340097196400166,
        0.008005647920072079,
        -0.0013052895665168762,
        -0.023140154778957367,
        0.05350027605891228,
        -0.017945051193237305,
        0.054509684443473816,
        0.04652983695268631,
        -0.01604357175529003,
        -0.014088669791817665,
        -0.05318437144160271,
        0.017096705734729767,
        -0.052798207849264145,
        0.05339077115058899,
        -0.054300613701343536,
        -0.04772915318608284,
        0.036929599940776825,
        -0.051480550318956375,
        0.049918532371520996,
        -0.05451105162501335,
        -0.05068961903452873,
        -0.054325103759765625,
        0.05440886318683624,
        -0.030013587325811386,
        -0.040226519107818604,
        0.05457339435815811,
        0.011964445002377033,
        0.05076092854142189,
        0.05446925386786461,
        -0.04965529590845108,
        0.04322606697678566,
        -0.05091153085231781,
        0.035686347633600235,
        -0.03641039505600929,
        -0.024251071736216545,
        0.05024290829896927,
        -0.05414682999253273,
        0.0539289154112339,
        -0.039017777889966965,
        -0.05315530300140381,
        0.054453156888484955,
        -0.054128605872392654,
        0.04683922231197357,
        -0.03183304890990257,
        0.04211216792464256,
        -0.006032888777554035,
        -0.012013232335448265,
        -0.030694076791405678,
        0.054499171674251556,
        0.054404452443122864,
        -0.031627047806978226,
        0.02010161243379116,
        -0.003668384160846472,
        0.04647134989500046,
        0.009737418964505196,
        0.04525457322597504,
        -0.05407552793622017,
        0.018028011545538902,
        -0.05362769216299057,
        0.05413751304149628,
        -0.05416277423501015,
        -0.0074458494782447815,
        -0.04753294959664345,
        0.033760279417037964,
        0.03915635868906975,
        0.05338352546095848,
        -0.020988650619983673,
        0.0545983649790287,
        -0.04850246012210846,
        0.049889322370290756,
        -0.028062451630830765,
        0.036109477281570435,
        -0.04151532053947449,
        -0.049650199711322784,
        -0.04948154836893082,
        0.05398954823613167,
        -0.05196547880768776,
        0.044817037880420685,
        0.047638654708862305,
        0.005096976179629564,
        0.0135777797549963,
        -0.045556701719760895,
        -0.008146494626998901,
        0.05455527454614639,
        -0.053807247430086136,
        0.021882807835936546,
        0.054452113807201385,
        0.053887464106082916,
        0.05447405204176903,
        -0.04413245990872383,
        0.04964754357933998,
        0.054030247032642365,
        0.05021033063530922,
        0.007160386070609093,
        -0.05371367186307907,
        -0.009115302935242653,
        0.0251537524163723,
        -0.05459832400083542,
        -0.0400395430624485,
        -0.0495368055999279,
        -0.04909151419997215,
        0.049432091414928436,
        0.050023578107357025,
        -0.005726055707782507,
        -0.053570665419101715,
        -0.0025938902981579304,
        -0.05103280022740364,
        0.0534236766397953,
        -0.05425308644771576,
        0.04942409321665764,
        -0.05390280485153198,
        -0.022316424176096916,
        0.053708743304014206,
        0.05389643460512161,
        -0.05403685197234154,
        0.05380761995911598,
        -0.04828577861189842,
        0.05427197739481926,
        -0.050836432725191116,
        0.05454513803124428,
        -0.054069701582193375,
        0.05410093069076538,
        0.044816020876169205,
        -0.05452479049563408,
        0.006187702529132366,
        -0.01152048446238041,
        -0.04382273554801941,
        0.048770271241664886,
        -0.01348657812923193,
        -0.05285144969820976,
        0.014721870422363281,
        -0.020861512050032616,
        -0.04873669892549515,
        0.023406321182847023,
        0.00975851807743311,
        0.045360781252384186,
        0.05425616353750229,
        0.008132332935929298,
        -0.0369945652782917,
        -0.05454076826572418,
        -0.05451696366071701,
        -0.002909650094807148,
        0.05228644981980324,
        0.04957783967256546,
        0.0522855781018734,
        -0.02535002864897251,
        0.04165443032979965,
        0.0089045949280262,
        0.022393813356757164,
        0.054501961916685104,
        -0.05302196741104126,
        -0.013147733174264431,
        0.011660249903798103,
        -0.05228891968727112,
        -0.006308012641966343,
        0.04866013675928116,
        -0.046623095870018005,
        -0.05422159284353256,
        -0.03394133597612381,
        0.05153509974479675,
        0.05178277939558029,
        0.053973689675331116,
        -0.05458424240350723,
        -0.05034817382693291,
        -0.05459388718008995,
        0.00929360929876566,
        0.04813263192772865,
        -0.051633574068546295,
        -0.02760469913482666,
        -0.029577219858765602,
        -0.051447149366140366,
        0.04877234622836113,
        -0.02381432242691517,
        0.05415709316730499,
        0.02952236868441105,
        0.01486540399491787,
        -0.04856881871819496,
        -0.05448196828365326,
        0.05384378135204315,
        0.007367776241153479,
        -0.04880664125084877,
        -0.04643941670656204,
        0.046356625854969025,
        0.04552444443106651,
        0.05413113906979561,
        0.03735985979437828,
        -0.023880954831838608,
        0.053926367312669754,
        0.047074366360902786,
        0.051623668521642685,
        0.053394295275211334,
        0.05435660853981972,
        0.023824695497751236,
        -0.03889136016368866,
        0.015592275187373161,
        0.051875755190849304,
        -0.05136754736304283,
        -0.04473525658249855,
        -0.05416407436132431,
        -0.03905598074197769,
        0.05456928536295891,
        0.026793859899044037,
        -0.05457991734147072,
        0.0544399656355381,
        -0.0536455437541008,
        0.04855771362781525,
        0.05423338711261749,
        -0.05445437133312225,
        0.048301734030246735,
        -0.049875374883413315,
        -0.054422903805971146,
        0.054375339299440384,
        -0.012914101593196392,
        0.030678387731313705,
        0.05281466618180275,
        0.038166627287864685,
        0.01276740338653326,
        -0.04432749003171921,
        0.04731351509690285,
        -0.048594459891319275,
        0.009242061525583267,
        -0.045226652175188065,
        -0.0022283399011939764,
        0.05219198763370514,
        0.05455069988965988,
        0.04951533302664757,
        0.03675828501582146,
        0.05446070060133934,
        0.05101152881979942,
        -0.054333366453647614,
        0.007811082061380148,
        -0.039413291960954666,
        -0.0065717739053070545,
        0.054141487926244736,
        0.0024370579048991203,
        -0.03972842916846275,
        0.01403831597417593,
        -0.05317506566643715,
        -0.05449790880084038,
        0.05024801939725876,
        -0.02085856720805168,
        -0.054294295608997345,
        -0.05405057221651077,
        -0.016955800354480743,
        -0.03822915256023407,
        0.05444174259901047,
        0.0017598099075257778,
        0.04288280010223389,
        -0.0357077531516552,
        0.0535726323723793,
        -0.05232325568795204,
        0.0528748445212841,
        0.03149215131998062
    ],
    [
        -0.010637212544679642,
        -0.05394398048520088,
        -0.04441128671169281,
        0.03692249581217766,
        -0.04022311046719551,
        -0.04835556074976921,
        0.05322960764169693,
        -0.051963310688734055,
        -0.04336145892739296,
        0.04943238943815231,
        0.05387116223573685,
        0.05382145196199417,
        -0.05365985631942749,
        -0.039572976529598236,
        -0.05392974615097046,
        -0.052868008613586426,
        0.03610335662961006,
        0.05394323542714119,
        0.0512617789208889,
        -0.05163699761033058,
        0.026892976835370064,
        -0.04210171476006508,
        0.049378689378499985,
        -0.03705860301852226,
        0.026733702048659325,
        0.05173396319150925,
        0.04504368081688881,
        0.002183024538680911,
        -0.053016144782304764,
        0.051416799426078796,
        0.03940266743302345,
        0.038638029247522354,
        0.05272972211241722,
        -0.05394836515188217,
        0.05342636629939079,
        0.05202526971697807,
        -0.05193726718425751,
        -0.0534517765045166,
        -0.01486976258456707,
        -0.05240887030959129,
        0.05321185663342476,
        -0.007905351929366589,
        0.0535009503364563,
        0.008236387744545937,
        0.0010520414216443896,
        0.05362078920006752,
        -0.04904842749238014,
        0.051457080990076065,
        0.05391969159245491,
        -0.05385119095444679,
        0.05384526029229164,
        0.03198431059718132,
        -0.037397537380456924,
        -0.053585153073072433,
        -0.05394750460982323,
        0.051702652126550674,
        -0.01099588442593813,
        0.05113589018583298,
        -0.04809575900435448,
        -0.046434398740530014,
        -0.053925905376672745,
        0.024119434878230095,
        -0.05159211903810501,
        -0.03641534969210625,
        0.0258957426995039,
        -0.05201493948698044,
        -0.04753323644399643,
        0.012327822856605053,
        0.052567169070243835,
        -0.017575116828083992,
        -0.05385648086667061,
        -0.05340813845396042,
        0.05375637114048004,
        0.050672177225351334,
        0.05350286513566971,
        -0.038223475217819214,
        -0.03683224692940712,
        0.0539499893784523,
        -0.05336679890751839,
        -0.004952762275934219,
        -0.05276123806834221,
        -0.05375943332910538,
        -0.0536416694521904,
        0.05392495542764664,
        -0.050564587116241455,
        0.052690014243125916,
        0.037108417600393295,
        0.05383976548910141,
        0.047148387879133224,
        -0.017940696328878403,
        -0.05387832596898079,
        -0.04986286908388138,
        -0.053809091448783875,
        -0.049941468983888626,
        -0.02568512037396431,
        0.02193026803433895,
        -0.04264287278056145,
        -0.053860295563936234,
        -0.044308509677648544,
        -0.0039080725982785225,
        -0.05393983796238899,
        0.05383668839931488,
        0.053934141993522644,
        0.004266893025487661,
        0.019175037741661072,
        0.03973545879125595,
        -0.05262400954961777,
        0.05326695367693901,
        -0.05335512012243271,
        0.05016621574759483,
        -0.041508402675390244,
        0.049489229917526245,
        -0.052974384278059006,
        0.053099505603313446,
        -0.01872972771525383,
        -0.05174434930086136,
        -0.04078260809183121,
        -0.04643836244940758,
        -0.008838191628456116,
        -0.04626983031630516,
        0.04869450256228447,
        0.045509837567806244,
        0.0053123836405575275,
        -0.05383429676294327,
        -0.052426211535930634,
        0.05376772582530975,
        -0.03608616441488266,
        -0.05384631082415581,
        0.03674853593111038,
        -0.05127513408660889,
        0.053940970450639725,
        -0.05268431082367897,
        -0.05307328328490257,
        0.04524412378668785,
        -0.053455278277397156,
        -0.05389709770679474,
        0.05394617095589638,
        0.05378479138016701,
        -0.052875667810440063,
        0.053943995386362076,
        0.008794063702225685,
        -0.05277061462402344,
        0.05023781582713127,
        0.013207238167524338,
        0.0035831094719469547,
        -0.008616534061729908,
        0.002669483656063676,
        -0.04282984137535095,
        -0.044981636106967926,
        0.050327230244874954,
        -0.05330449342727661,
        -0.05389901623129845,
        0.05181616172194481,
        0.049177441745996475,
        -0.0359681062400341,
        -0.05385219678282738,
        -0.053600940853357315,
        -0.04211326688528061,
        0.05349358171224594,
        -0.04463119059801102,
        0.05225270614027977,
        0.041491955518722534,
        0.053727153688669205,
        0.04128152132034302,
        -0.05391903221607208,
        -0.04757356271147728,
        0.024850143119692802,
        -0.03635689616203308,
        -0.042999330908060074,
        0.051261287182569504,
        -0.03473132103681564,
        0.05392834171652794,
        -0.03739708289504051,
        0.05385701358318329,
        -0.051035523414611816,
        -0.04375116527080536,
        -0.03861343115568161,
        0.02352984808385372,
        0.05233841761946678,
        -0.05373157188296318,
        -0.0489158034324646,
        0.05392073094844818,
        0.0036644963547587395,
        0.040690191090106964,
        -0.0041772834956645966,
        0.052832137793302536,
        -0.03993833810091019,
        0.05242941156029701,
        -0.013707529753446579,
        -0.05212850496172905,
        -0.05356796830892563,
        -0.020375896245241165,
        0.05010528117418289,
        -0.03328331187367439,
        -0.039023857563734055,
        0.022901635617017746,
        0.05271701142191887,
        -0.05137346684932709,
        -0.04656284302473068,
        0.046310584992170334,
        0.053295254707336426,
        -0.026237728074193,
        -0.052575189620256424,
        -0.05389225110411644,
        -0.041853539645671844,
        -0.04949604719877243,
        0.04487210139632225,
        0.053945910185575485,
        -0.026808107271790504,
        0.05391645058989525,
        -0.05364249646663666,
        0.05361815541982651,
        0.05394101142883301,
        0.05390392988920212,
        -0.053378041833639145,
        -0.03470508009195328,
        0.05391724035143852,
        -0.052721187472343445,
        0.053945962339639664,
        -0.05230207368731499,
        -0.04075683280825615,
        -0.04746672883629799,
        -0.05303899198770523,
        -0.05364426597952843,
        0.05392089858651161,
        -0.03889981284737587,
        0.05266382917761803,
        -0.05357695370912552,
        -0.0007732340600341558,
        -0.050844691693782806,
        0.05267108604311943,
        -0.04877566173672676,
        0.0486849769949913,
        0.05070963874459267,
        -0.0001593278138898313,
        0.053496092557907104,
        0.05304621160030365,
        0.05193953216075897,
        0.0416390635073185,
        0.05225164070725441,
        -0.021525925025343895,
        0.04562963545322418,
        -0.011501377448439598,
        -0.05393867939710617,
        0.05090179294347763,
        0.04210938140749931,
        0.02785772830247879,
        0.021021315827965736,
        0.05095012113451958,
        -0.02869153954088688,
        0.04246652498841286,
        -0.05386147275567055,
        -0.04769781231880188,
        -0.053495634347200394,
        0.03515643998980522,
        0.021437816321849823,
        -0.05277157947421074,
        -0.015475915744900703,
        0.05115235969424248,
        -0.010355304926633835,
        -0.004467685241252184,
        -0.017643257975578308,
        0.05140988901257515,
        0.05362814664840698,
        -0.053832899779081345,
        0.03294525295495987,
        0.05347625911235809,
        0.03257853165268898,
        -0.02671709656715393,
        -0.050833746790885925,
        0.05049555376172066,
        -0.049001868814229965,
        0.047854021191596985,
        -0.03678230941295624,
        -0.05382930114865303,
        0.035649947822093964,
        -0.05293683335185051,
        0.046240150928497314,
        0.047739047557115555,
        0.01701924204826355,
        -0.05352281033992767,
        0.05279436334967613,
        0.028598831966519356,
        0.050805822014808655,
        0.053187672048807144,
        0.026958849281072617,
        0.038278259336948395,
        0.053619783371686935,
        -0.05237206444144249,
        0.04277940094470978,
        0.038116566836833954,
        0.025941770523786545,
        -0.05354657024145126,
        -0.04582216590642929,
        0.05391957238316536,
        -0.038409266620874405,
        0.053808171302080154,
        0.0018030751962214708,
        -0.04444873332977295,
        0.053923625499010086,
        -0.045198965817689896,
        -0.0187206007540226,
        0.03554049879312515,
        0.04314376041293144,
        0.016818340867757797,
        -0.05394115298986435,
        0.05320550873875618,
        -0.007932114414870739,
        0.025608954951167107,
        0.05289117991924286,
        -0.008631883189082146,
        0.0501970537006855,
        0.017901645973324776,
        0.051604367792606354,
        0.034108102321624756,
        -0.04472356662154198,
        -0.030870556831359863,
        -0.047044314444065094,
        0.022007958963513374,
        0.0014886787394061685,
        0.051167842000722885,
        -0.019091371446847916,
        -0.045384541153907776,
        0.04771112650632858,
        0.05359742045402527,
        0.05262162163853645,
        0.05324317514896393,
        -0.04466961696743965,
        0.048775628209114075,
        -0.042604800313711166,
        0.0470474474132061,
        0.05016035959124565,
        -0.0533834844827652,
        0.019936000928282738,
        0.05079056695103645,
        0.04976959526538849,
        0.043502867221832275,
        -0.0413266085088253,
        -0.022899312898516655,
        -0.035410813987255096,
        -0.052561063319444656,
        -0.05179012566804886,
        -0.0013394452398642898,
        -0.005000742617994547,
        -0.006115890108048916,
        0.04656127840280533,
        -0.001043484779074788,
        0.05377937853336334,
        0.011668127030134201,
        -0.031143618747591972,
        -0.004787324462085962,
        0.04453955963253975,
        0.03592728450894356,
        -0.052218131721019745,
        -0.020074384286999702,
        0.024928290396928787,
        -0.05384119972586632,
        -0.04612082615494728,
        -0.05057927593588829,
        -0.053830284625291824,
        0.052935391664505005,
        0.0418565534055233,
        0.051265306770801544,
        -0.05188962072134018,
        0.006792247761040926,
        -0.05392734333872795,
        0.05357763171195984,
        -0.042635150253772736,
        0.03134775534272194,
        -0.05390562117099762,
        -0.04801996424794197,
        -0.04730412736535072,
        0.026757527142763138,
        -0.044847648590803146,
        0.053000546991825104,
        -0.025830578058958054,
        -0.0494212880730629,
        0.045254241675138474,
        0.05178944393992424,
        0.019912613555788994,
        -0.047070909291505814,
        0.03192770108580589,
        -0.052748195827007294,
        0.05225815996527672,
        -0.022752389311790466,
        -0.05381476879119873,
        0.04226710647344589,
        -0.008020739071071148,
        -0.05340936407446861,
        -0.04735865071415901,
        -0.05362018570303917,
        -0.052293047308921814,
        -0.05232365429401398,
        0.05340351536870003,
        0.05358612537384033,
        0.05285315960645676,
        0.0537804439663887,
        -0.04207870364189148,
        -0.020230421796441078,
        -0.053932640701532364,
        -0.05238879472017288,
        0.05392758920788765,
        0.0493713915348053,
        0.008974842727184296,
        -0.046980101615190506,
        0.05178244411945343,
        0.05340694636106491,
        -0.052622731775045395,
        0.053924452513456345,
        -0.05011109635233879,
        0.05390927568078041,
        0.0181116946041584,
        0.028418954461812973,
        0.02380269579589367,
        0.04596904292702675,
        -0.013455122709274292,
        0.05282259359955788,
        -0.009719877503812313,
        -0.006211124826222658,
        0.04249901324510574,
        -0.015072382055222988,
        0.018366670235991478,
        0.05324726179242134,
        -0.05394809693098068,
        0.0038177408277988434,
        0.04347381740808487,
        -0.052644237875938416,
        -0.0330803319811821,
        0.05191940441727638,
        -0.041322555392980576,
        -0.030991483479738235,
        0.05326486751437187,
        -0.05391831323504448,
        -0.016461841762065887,
        -0.05005098879337311,
        -0.051121655851602554,
        -0.0537678524851799,
        -0.0050204056315124035,
        -0.04508190229535103,
        -0.026368672028183937,
        -0.05389555171132088,
        0.0026968594174832106,
        0.05240006372332573,
        0.05028805509209633,
        0.049717921763658524,
        -0.0025244196876883507,
        0.05394282191991806,
        -0.053271058946847916,
        0.05302485451102257,
        0.047684963792562485,
        -0.028552033007144928,
        0.04207582399249077,
        0.05312487855553627,
        0.022426828742027283,
        0.04672540724277496,
        -0.053853850811719894,
        0.049934130162000656,
        -0.05306245759129524,
        0.04770948365330696,
        0.04142516478896141,
        -0.04584852233529091,
        -0.053939905017614365,
        0.04672624543309212,
        -0.016824860125780106,
        0.05379517748951912,
        0.053916141390800476,
        -0.04083685204386711,
        0.05385873466730118,
        0.011163205839693546,
        -0.05374959856271744,
        0.053890328854322433,
        0.05394721031188965,
        0.04690727964043617,
        -0.009094304405152798,
        -0.05372397229075432,
        0.0001597143564140424,
        0.0373184010386467,
        0.05386628583073616,
        -0.018284723162651062,
        -0.015146804973483086,
        -0.05380651354789734,
        -0.0512394979596138,
        -0.040506403893232346,
        0.05167776718735695,
        -0.043146561831235886,
        0.05356166884303093,
        0.025346539914608,
        0.051991626620292664,
        -0.0533285029232502,
        -0.043894391506910324,
        0.022130100056529045,
        0.038313109427690506,
        0.022555045783519745,
        -0.037047237157821655,
        -0.03855271264910698,
        -0.03089253045618534,
        -0.053947530686855316,
        0.05330685153603554,
        0.05280639976263046,
        -0.024493498727679253,
        0.026328079402446747,
        -0.003141387365758419,
        -0.04629925638437271,
        -0.026157166808843613,
        0.03250310197472572,
        -0.012191281653940678,
        0.052819233387708664,
        -0.02174864336848259,
        0.05066473037004471,
        -0.05389487370848656,
        0.04519334435462952,
        0.05166606605052948
    ],
    [
        -0.022647544741630554,
        -0.03302840515971184,
        0.014774403534829617,
        0.010733157396316528,
        0.05909214913845062,
        -0.052557915449142456,
        0.06477879732847214,
        -0.014751797541975975,
        -0.04942835122346878,
        0.04066851735115051,
        0.04871538653969765,
        0.04610545560717583,
        -0.05827206373214722,
        -0.007125115022063255,
        -0.06414604187011719,
        -0.02999734692275524,
        -0.06414714455604553,
        0.05339222401380539,
        -0.012937398627400398,
        -0.012812201865017414,
        -0.042230527848005295,
        -0.03576631844043732,
        0.049469683319330215,
        0.013313785195350647,
        0.04030086100101471,
        0.05326845869421959,
        0.015514249913394451,
        0.0538630336523056,
        -0.03085416927933693,
        -0.019456107169389725,
        0.05528615787625313,
        -0.014931660145521164,
        0.03100436180830002,
        -0.05967729538679123,
        -0.009753807447850704,
        0.051715947687625885,
        -0.05258943885564804,
        -0.06024385988712311,
        -0.053667936474084854,
        -0.01765352115035057,
        0.04899369925260544,
        -0.020962661132216454,
        0.05865175649523735,
        -0.059784892946481705,
        0.021593309938907623,
        0.04371195659041405,
        -0.0417991541326046,
        0.06306679546833038,
        0.05609682574868202,
        -0.034522417932748795,
        0.04210944473743439,
        -0.03967631608247757,
        0.052483346313238144,
        -0.02667575515806675,
        -0.06441771984100342,
        0.0031166530679911375,
        -0.047864314168691635,
        0.04947223141789436,
        0.006093624047935009,
        -0.026566708460450172,
        -0.061569344252347946,
        -0.003301856340840459,
        -0.056775905191898346,
        -0.01815974712371826,
        0.03260313719511032,
        0.02373352088034153,
        0.06435489654541016,
        -0.05051863193511963,
        0.05966392531991005,
        -0.06531680375337601,
        -0.062149252742528915,
        -0.06357616186141968,
        0.008991035632789135,
        0.054101038724184036,
        0.007423543371260166,
        0.0027066278271377087,
        0.029168562963604927,
        0.05513956770300865,
        -0.03402388095855713,
        -0.05518767610192299,
        -0.022172216325998306,
        0.03936460241675377,
        -0.04287712648510933,
        0.014959237538278103,
        -0.051877059042453766,
        0.022506995126605034,
        -0.04710659012198448,
        0.053092967718839645,
        0.040014736354351044,
        0.06203925609588623,
        -0.059987396001815796,
        0.008166071958839893,
        -0.051284730434417725,
        -0.032796330749988556,
        0.03897261247038841,
        -0.03531553968787193,
        -0.04510783404111862,
        -0.034148089587688446,
        -0.06354258954524994,
        -0.03284318000078201,
        -0.06531242281198502,
        0.0023173964582383633,
        0.06372427195310593,
        0.03872883319854736,
        -0.05332360416650772,
        0.013131501153111458,
        -0.05674358457326889,
        0.024497300386428833,
        -0.05745365470647812,
        -0.00979165080934763,
        -0.037991002202034,
        -0.029687663540244102,
        -0.053017839789390564,
        0.026033621281385422,
        0.04112613573670387,
        -0.05974376201629639,
        -0.05374589189887047,
        -0.052193909883499146,
        -0.06354933232069016,
        -0.04850194230675697,
        -0.031529415398836136,
        -0.00027639101608656347,
        -0.023857567459344864,
        0.006647813133895397,
        -0.0650448352098465,
        0.04872534051537514,
        -0.045400217175483704,
        -0.0010312849190086126,
        0.05615643039345741,
        -0.03331078216433525,
        0.05609453096985817,
        -0.05808519944548607,
        0.03485563024878502,
        0.004341385792940855,
        -0.05524500831961632,
        -0.053664062172174454,
        0.055729299783706665,
        -0.014872833155095577,
        -0.02505713701248169,
        0.06467004865407944,
        -0.009560766629874706,
        -0.005483850836753845,
        0.015705008059740067,
        0.049129340797662735,
        0.0036459164693951607,
        -0.004706146661192179,
        0.06212290748953819,
        -0.027333715930581093,
        -0.0322008840739727,
        0.053099337965250015,
        -0.061077967286109924,
        -0.06099151447415352,
        -0.02489825338125229,
        0.039229925721883774,
        -0.0397956557571888,
        -0.05803699791431427,
        0.03182311728596687,
        -0.04270749166607857,
        0.012068217620253563,
        -0.028932765126228333,
        0.06418009847402573,
        0.04962572082877159,
        0.045846786350011826,
        -0.044243842363357544,
        -0.06393057107925415,
        -0.04713453724980354,
        0.015172229148447514,
        -0.06331948935985565,
        -0.01875586248934269,
        0.05552219599485397,
        0.052799321711063385,
        0.04020683467388153,
        -0.05450627580285072,
        0.043199315667152405,
        -0.015071049332618713,
        0.06040261685848236,
        -0.06271490454673767,
        -0.011957423761487007,
        0.05562147870659828,
        0.05016777664422989,
        0.04697457700967789,
        0.06199786439538002,
        0.061124347150325775,
        -0.021313972771167755,
        -0.025923535227775574,
        0.013576323166489601,
        -0.05616036802530289,
        0.059788256883621216,
        -0.03323394060134888,
        -0.04317261278629303,
        0.0176701582968235,
        -0.0553414449095726,
        -0.026033516973257065,
        0.013054776936769485,
        -0.0007516831974498928,
        0.0064933509565889835,
        0.05559452623128891,
        -0.015142802149057388,
        -0.028413016349077225,
        0.020001376047730446,
        0.007692443206906319,
        0.03196531534194946,
        0.0205523781478405,
        -0.05504565313458443,
        -0.0010982496896758676,
        -0.009998295456171036,
        0.05079222843050957,
        0.05585820972919464,
        -0.02319220080971718,
        0.06325063109397888,
        -0.05697270855307579,
        0.05244751274585724,
        0.048093315213918686,
        -0.03057251311838627,
        -0.010495689697563648,
        0.03619827330112457,
        -0.03937143087387085,
        0.045278411358594894,
        0.06461286544799805,
        -0.06082741171121597,
        0.035707417875528336,
        -0.062164515256881714,
        -0.05761067941784859,
        -0.0650344267487526,
        0.06244406849145889,
        -0.050007499754428864,
        0.012066167779266834,
        -0.05079346522688866,
        -0.056640416383743286,
        -0.05454571545124054,
        -0.05244532600045204,
        -0.05191907659173012,
        -0.034334033727645874,
        0.044652875512838364,
        0.0035335298161953688,
        -0.015011973679065704,
        0.062082961201667786,
        -0.0004665411252062768,
        -0.008152706548571587,
        0.016211315989494324,
        0.036313366144895554,
        0.039207179099321365,
        0.05769508704543114,
        -0.04418865963816643,
        -0.0031795331742614508,
        0.0013385938946157694,
        0.03216777741909027,
        -0.011794772930443287,
        -0.055699266493320465,
        0.03637145832180977,
        0.01099745649844408,
        -0.06477674841880798,
        -0.05385377258062363,
        -0.06278589367866516,
        0.0382971465587616,
        0.05796929448843002,
        -0.02881944738328457,
        0.01669147238135338,
        0.06443830579519272,
        0.06501484662294388,
        0.024899670854210854,
        0.05521230399608612,
        0.024768780916929245,
        0.05874462053179741,
        -0.06252560019493103,
        0.013230660930275917,
        0.03142201527953148,
        -0.02510669268667698,
        -0.008275647647678852,
        -0.06423645466566086,
        -0.04304906725883484,
        -0.062091391533613205,
        0.0195156242698431,
        -0.057973239570856094,
        -0.06130761653184891,
        0.055654093623161316,
        -0.06122941896319389,
        0.049910493195056915,
        0.010741054080426693,
        -0.009443484246730804,
        -0.06412988901138306,
        0.02441251277923584,
        0.05248262360692024,
        0.044419027864933014,
        -0.025782153010368347,
        -0.038682837039232254,
        0.03896309807896614,
        0.013157738372683525,
        -0.0631021186709404,
        0.026179203763604164,
        -0.03799150884151459,
        0.03393275663256645,
        -0.008977822959423065,
        -0.0381186306476593,
        0.0607694573700428,
        -0.034595075994729996,
        0.06460575014352798,
        0.022344162687659264,
        -0.013670495711266994,
        0.06081388518214226,
        -0.035667091608047485,
        0.05826151743531227,
        -0.014243916608393192,
        0.041682593524456024,
        -0.02833062969148159,
        -0.06392917037010193,
        0.012471883557736874,
        -0.03807305544614792,
        0.062001217156648636,
        -0.01519531849771738,
        0.006184678990393877,
        0.014952591620385647,
        -0.05447913706302643,
        0.052836041897535324,
        -0.06363575160503387,
        -0.0453927256166935,
        0.06402792036533356,
        -0.04361554607748985,
        0.0017691629473119974,
        -0.055375825613737106,
        0.013282520696520805,
        -0.02344014309346676,
        0.05483277142047882,
        -0.04125523194670677,
        0.04479115456342697,
        0.06351344287395477,
        0.0493924617767334,
        -0.036356840282678604,
        0.01993841677904129,
        -0.014958193525671959,
        -0.04137489199638367,
        0.052044451236724854,
        -0.028219422325491905,
        -0.042685579508543015,
        0.058268848806619644,
        0.039393242448568344,
        -0.04060046747326851,
        0.0597887746989727,
        -0.03556210920214653,
        0.054647862911224365,
        0.02159595489501953,
        -0.05501404404640198,
        -0.031124137341976166,
        -0.0448698028922081,
        0.0647369846701622,
        0.02481270767748356,
        0.03356471657752991,
        0.04763288423418999,
        0.03715843707323074,
        0.05758610740303993,
        -0.06531821936368942,
        -0.0016203029081225395,
        -0.04935803264379501,
        -0.0635538399219513,
        -0.057171013206243515,
        0.057504959404468536,
        -0.03017398715019226,
        0.011927916668355465,
        -0.05967097729444504,
        -0.0649397224187851,
        0.06287074089050293,
        0.0372433103621006,
        -0.05276379734277725,
        -0.033281076699495316,
        0.03285069763660431,
        -0.058587271720170975,
        0.06500077992677689,
        0.01570933498442173,
        0.012279712595045567,
        -0.06496673822402954,
        -0.05056588724255562,
        -0.06349346786737442,
        0.06047136336565018,
        0.014757451601326466,
        0.048903025686740875,
        0.024187397211790085,
        0.001467851921916008,
        0.004578077234327793,
        -0.04518909007310867,
        -0.033009398728609085,
        -0.05468923971056938,
        0.056626636534929276,
        -0.06291893124580383,
        0.01729513145983219,
        0.05872245132923126,
        -0.051262594759464264,
        0.045723140239715576,
        -0.06118670105934143,
        -0.05224542319774628,
        -0.03467521071434021,
        -0.045432571321725845,
        0.033676519989967346,
        0.029915761202573776,
        0.06088811159133911,
        0.014415272511541843,
        0.059746935963630676,
        0.05956209450960159,
        -0.022080445662140846,
        -0.0013438130263239145,
        -0.06489405781030655,
        -0.053117815405130386,
        0.049888644367456436,
        0.055259931832551956,
        0.06285914033651352,
        -0.057515814900398254,
        0.04796094819903374,
        0.043221477419137955,
        0.04258877784013748,
        0.05987695977091789,
        -0.04726536199450493,
        0.06446754187345505,
        0.052793949842453,
        0.04132456332445145,
        -0.037102121859788895,
        0.04248981550335884,
        0.01803530380129814,
        0.06314419209957123,
        -0.0333343967795372,
        0.030871348455548286,
        -0.01949334889650345,
        -0.05561023950576782,
        -0.040027473121881485,
        0.006553746294230223,
        -0.056942883878946304,
        0.04264339804649353,
        0.026546524837613106,
        -0.05956847593188286,
        -0.052126627415418625,
        -0.06432873755693436,
        -0.009612997062504292,
        -0.016751684248447418,
        0.03442489728331566,
        -0.04550972208380699,
        0.04798136278986931,
        0.04362552613019943,
        0.010725008323788643,
        -0.057491011917591095,
        -0.05223528668284416,
        0.009540792554616928,
        -0.033699143677949905,
        -0.040448784828186035,
        -0.00532013364136219,
        0.04250321909785271,
        0.05350091680884361,
        0.05853944271802902,
        0.009289909154176712,
        0.06456098705530167,
        -0.05042637512087822,
        0.06469202041625977,
        -0.05400953069329262,
        -0.05862806364893913,
        0.0564635694026947,
        0.06394980102777481,
        0.016384968534111977,
        -0.007658700924366713,
        -0.03420058637857437,
        0.05281229689717293,
        0.03573374077677727,
        -0.03393356874585152,
        0.04023817554116249,
        -0.00968234520405531,
        -0.03829316422343254,
        -0.0394703634083271,
        -0.02366774156689644,
        0.057538148015737534,
        0.0650559589266777,
        -0.06471355259418488,
        0.06233099102973938,
        -0.025466404855251312,
        -0.06457190215587616,
        0.06153060495853424,
        0.05020422860980034,
        0.0534394234418869,
        0.009681833907961845,
        0.0354972742497921,
        -0.0004250263446010649,
        -0.052583321928977966,
        0.03488222882151604,
        -0.0238754004240036,
        -0.05406391993165016,
        -0.03933700546622276,
        -0.04334886744618416,
        0.026347680017352104,
        0.05271882191300392,
        -0.015676705166697502,
        0.03983099386096001,
        0.01014215499162674,
        -0.04863104969263077,
        -0.05903557315468788,
        -0.0317300520837307,
        -0.038302455097436905,
        -0.05707431584596634,
        -0.057468581944704056,
        0.026412997394800186,
        0.0604182630777359,
        0.027079487219452858,
        -0.06429671496152878,
        0.05932571366429329,
        0.06140841543674469,
        0.04456386715173721,
        0.02477274090051651,
        -0.05719097703695297,
        -0.0006372243515215814,
        -0.006955756340175867,
        -0.004161686170846224,
        0.02450430952012539,
        0.02213076315820217,
        -0.020178308710455894,
        -0.06271099299192429,
        -0.050706081092357635,
        0.05642813816666603,
        0.022747227922081947
    ],
    [
        -0.05865852162241936,
        -0.05912930518388748,
        0.050085701048374176,
        0.04457903653383255,
        0.0242302343249321,
        -0.018932348117232323,
        0.05882744491100311,
        -0.02746918424963951,
        -0.04977266862988472,
        0.04967093840241432,
        0.05500805377960205,
        0.05195015296339989,
        -0.05665506795048714,
        -0.05451148748397827,
        -0.05967196822166443,
        -0.018256526440382004,
        -0.0327705554664135,
        0.05831381678581238,
        -0.05582646280527115,
        0.013652635738253593,
        0.0057695540599524975,
        -0.0582292303442955,
        0.016627758741378784,
        0.02385353296995163,
        0.05273881554603577,
        0.054460931569337845,
        0.024275194853544235,
        0.05775035545229912,
        -0.03497597947716713,
        -0.04683103784918785,
        0.05227787047624588,
        0.057232920080423355,
        0.025228215381503105,
        -0.059572771191596985,
        0.016032295301556587,
        0.0034106934908777475,
        -0.0554756261408329,
        -0.055770061910152435,
        0.017695235088467598,
        0.016408603638410568,
        0.05511707812547684,
        -0.0058677224442362785,
        0.05815592408180237,
        -0.04873529449105263,
        -0.05366527661681175,
        0.046635229140520096,
        0.006737630348652601,
        0.05980687588453293,
        0.05961315333843231,
        -0.05804179236292839,
        0.045576803386211395,
        -0.035863131284713745,
        0.05512825772166252,
        -0.05486668273806572,
        -0.056545112282037735,
        -0.006404547952115536,
        0.050625137984752655,
        0.0038893045857548714,
        0.041598640382289886,
        -0.020207859575748444,
        -0.05798531323671341,
        0.03539528325200081,
        -0.05758111551403999,
        -0.04518350958824158,
        -0.012529855594038963,
        -0.058793775737285614,
        0.0530034676194191,
        -0.04280858486890793,
        0.059959571808576584,
        -0.05967816337943077,
        -0.0577346533536911,
        -0.05977162718772888,
        0.015483993105590343,
        0.03669985383749008,
        -0.030539197847247124,
        0.03232197463512421,
        -0.044396914541721344,
        0.059807322919368744,
        0.029803287237882614,
        -0.04647137597203255,
        -0.045618388801813126,
        0.0001759761944413185,
        -0.05360705032944679,
        0.05381232500076294,
        -0.05243469774723053,
        0.02594667486846447,
        -0.02357330732047558,
        0.05992211773991585,
        -0.020089875906705856,
        0.058137279003858566,
        -0.05374160781502724,
        -0.04626902565360069,
        -0.04661128297448158,
        -0.05872868001461029,
        0.03974824771285057,
        -0.027902252972126007,
        -0.05230306088924408,
        -0.052231915295124054,
        -0.05891352519392967,
        0.011522932909429073,
        -0.05964377894997597,
        0.03075050748884678,
        0.05873512476682663,
        0.03972623869776726,
        0.04928332194685936,
        -0.04663675278425217,
        -0.0599973089993,
        0.043226126581430435,
        -0.059015903621912,
        0.040604591369628906,
        -0.04287112504243851,
        -0.03654060140252113,
        -0.053719595074653625,
        0.009901498444378376,
        0.04111624136567116,
        -0.05417969077825546,
        -0.008274924010038376,
        -0.05777181684970856,
        -0.051584113389253616,
        -0.01492307148873806,
        0.03452637419104576,
        -0.05661381036043167,
        0.0433470793068409,
        0.03463234379887581,
        -0.05962096154689789,
        0.05958122760057449,
        -0.047907616943120956,
        -0.04159114137291908,
        -0.027090905234217644,
        -0.053649067878723145,
        0.0493738166987896,
        -0.012965505942702293,
        0.019635386765003204,
        -0.049118317663669586,
        -0.049376294016838074,
        -0.024010777473449707,
        0.05901828408241272,
        -0.0476890429854393,
        -0.014866691082715988,
        0.05943646281957626,
        -0.053957197815179825,
        -0.034270044416189194,
        0.033275652676820755,
        0.059858858585357666,
        -0.032981496304273605,
        0.006162346340715885,
        0.05781526863574982,
        -0.05339789390563965,
        -0.047834333032369614,
        0.05701510235667229,
        -0.05662529170513153,
        -0.060016512870788574,
        0.0031422381289303303,
        0.055049922317266464,
        -0.05524790659546852,
        -0.05987264961004257,
        0.05357464775443077,
        0.012289107777178288,
        0.012163973413407803,
        -0.03422476351261139,
        0.057015907019376755,
        0.04912213608622551,
        0.053448498249053955,
        -0.05590229481458664,
        -0.05842828005552292,
        -0.05855609476566315,
        0.02946077473461628,
        -0.051042232662439346,
        0.009867384098470211,
        0.028009291738271713,
        0.03579188883304596,
        0.008070374839007854,
        -0.045870184898376465,
        0.041098035871982574,
        -0.03846481069922447,
        0.0552247017621994,
        -0.047412458807229996,
        -0.054958537220954895,
        0.044397350400686264,
        0.04319974407553673,
        0.04712805151939392,
        0.03814852982759476,
        0.05767809972167015,
        -0.02729695476591587,
        -0.0314737893640995,
        -0.056893542408943176,
        0.03120211325585842,
        0.05861245095729828,
        0.05107611417770386,
        -0.059889305382966995,
        -0.05495958775281906,
        -0.036008041352033615,
        -0.02380078285932541,
        0.009958184324204922,
        -0.0326266847550869,
        0.005955573171377182,
        0.059728819876909256,
        -0.0027748076245188713,
        -0.05478683486580849,
        0.046342119574546814,
        0.010297023691236973,
        0.02704705484211445,
        0.05030684918165207,
        -0.039254218339920044,
        -0.015674663707613945,
        0.04219460487365723,
        0.03287043422460556,
        0.008678171783685684,
        -0.04620084911584854,
        0.05231860280036926,
        -0.05677975341677666,
        -0.030760474503040314,
        0.05686984956264496,
        0.03816884383559227,
        -0.014835206791758537,
        -0.05361311510205269,
        -0.043172381818294525,
        -0.005560288671404123,
        0.05877825990319252,
        -0.05638723820447922,
        -0.02126576006412506,
        -0.0499107651412487,
        -0.05861637741327286,
        -0.05742170289158821,
        0.05998774617910385,
        -0.05879255756735802,
        0.05577000603079796,
        -0.0016822285251691937,
        -0.027994520962238312,
        -0.05574946105480194,
        0.026386836543679237,
        -0.057605087757110596,
        -0.058020420372486115,
        0.024992195889353752,
        0.003810408292338252,
        -0.011758005246520042,
        0.05836040899157524,
        -0.02415536902844906,
        0.03855762630701065,
        0.05685482174158096,
        0.054634831845760345,
        -0.02275800332427025,
        0.04983336851000786,
        -0.05740080773830414,
        0.05256563425064087,
        0.004132941830903292,
        0.0569053553044796,
        0.04754506051540375,
        0.010437754914164543,
        -0.04976010322570801,
        0.05667094886302948,
        -0.05741913244128227,
        -0.005283760838210583,
        -0.05488286912441254,
        0.051153142005205154,
        0.011331778950989246,
        -0.036885641515254974,
        -0.03495520353317261,
        0.059075728058815,
        0.05829768627882004,
        0.056587476283311844,
        0.04153061285614967,
        0.047537077218294144,
        0.020358804613351822,
        -0.05923622101545334,
        0.025818854570388794,
        0.0464157797396183,
        -0.02519926428794861,
        0.02310529164969921,
        0.006828231271356344,
        0.01635563187301159,
        -0.05987344682216644,
        0.05341875180602074,
        -0.048967793583869934,
        -0.04503609612584114,
        0.007940510287880898,
        -0.05812907963991165,
        -0.04151708260178566,
        -0.012820808216929436,
        0.042611487209796906,
        -0.05990374833345413,
        0.05873187631368637,
        0.04696432128548622,
        0.04247446358203888,
        0.03791779279708862,
        -0.022834191098809242,
        0.02561596781015396,
        0.019949357956647873,
        -0.05358841270208359,
        0.04923032224178314,
        -0.017390573397278786,
        0.02167036384344101,
        -0.02414088137447834,
        0.00764795858412981,
        0.05154822766780853,
        -0.05401858687400818,
        0.059990398585796356,
        -0.02387881465256214,
        -0.013461300171911716,
        0.05383108928799629,
        0.029476957395672798,
        -0.010284754447638988,
        -0.026482144370675087,
        -0.005422279238700867,
        -0.0365261510014534,
        -0.059060752391815186,
        0.03050755336880684,
        0.05152525380253792,
        0.0363118015229702,
        0.027473807334899902,
        0.018152538686990738,
        0.05409211665391922,
        0.021059900522232056,
        0.058076899498701096,
        -0.035526398569345474,
        -0.05235609412193298,
        0.057879962027072906,
        -0.020627349615097046,
        -0.012900169938802719,
        -0.05471371114253998,
        0.043341729789972305,
        0.0008788848062977195,
        -0.024840936064720154,
        -0.03757847845554352,
        0.0569220632314682,
        0.058595944195985794,
        0.05499483272433281,
        -0.056924864649772644,
        0.022464679554104805,
        -0.012897713109850883,
        0.032003484666347504,
        0.05021192878484726,
        -0.014445689506828785,
        -0.042678102850914,
        0.05401647463440895,
        0.029700174927711487,
        -0.05793967843055725,
        0.04671387001872063,
        -0.03951572999358177,
        0.03177294507622719,
        -0.02062566764652729,
        -0.057433709502220154,
        0.05974673852324486,
        0.03507194295525551,
        0.059970222413539886,
        0.04712258279323578,
        0.0568588562309742,
        0.05160192772746086,
        -0.059210583567619324,
        0.03493116796016693,
        -0.05994207784533501,
        -0.006660188548266888,
        -0.05722342059016228,
        -0.05871295556426048,
        -0.05329519882798195,
        0.049380894750356674,
        -0.059506624937057495,
        -0.016724051907658577,
        -0.021438851952552795,
        -0.05988593026995659,
        0.05272149667143822,
        0.0516364723443985,
        -0.042577777057886124,
        -0.034994591027498245,
        0.035125426948070526,
        -0.04249639809131622,
        0.05913623049855232,
        -0.05695156008005142,
        0.048155903816223145,
        -0.057665180414915085,
        -0.054173387587070465,
        -0.056095827370882034,
        0.055150002241134644,
        0.031000986695289612,
        0.05939341336488724,
        0.05851483717560768,
        0.03428949788212776,
        0.051166608929634094,
        0.013528688810765743,
        0.0067899408750236034,
        -0.04524608328938484,
        0.04976393282413483,
        -0.055065177381038666,
        -0.05322510749101639,
        -0.029245980083942413,
        -0.0560368075966835,
        0.025144265964627266,
        -0.05502207949757576,
        -0.05854799970984459,
        -0.041162095963954926,
        0.01548904087394476,
        -0.014941336587071419,
        -0.04144265502691269,
        0.05370975658297539,
        -0.006763938814401627,
        0.05251019448041916,
        0.0358133390545845,
        0.03797590732574463,
        -0.017560148611664772,
        -0.05837499722838402,
        -0.03832633048295975,
        0.05573558434844017,
        0.0461498461663723,
        0.05948125571012497,
        -0.051005177199840546,
        0.032793205231428146,
        -0.03817044571042061,
        -0.010654836893081665,
        0.0561894066631794,
        -0.05783000588417053,
        0.05994245037436485,
        0.03256889805197716,
        0.05950740724802017,
        0.04394855350255966,
        0.05713178217411041,
        0.006440712604671717,
        -0.006118053570389748,
        -0.041653431951999664,
        0.0022381183225661516,
        0.048428867012262344,
        -0.04084864258766174,
        -0.04983576387166977,
        -0.013018331490457058,
        -0.055792175233364105,
        0.028390388935804367,
        -0.01568201184272766,
        -0.058558039367198944,
        -0.05986344441771507,
        -0.052625641226768494,
        0.056553129106760025,
        0.04830651730298996,
        -0.00302414339967072,
        -0.05887245386838913,
        0.05662419646978378,
        -0.05161374807357788,
        -0.050628624856472015,
        -0.04579947516322136,
        0.023844387382268906,
        -0.00643963972106576,
        -0.04749729111790657,
        -0.024573128670454025,
        0.05938486382365227,
        0.03341696783900261,
        0.05146012082695961,
        -0.0007870785775594413,
        0.03333405405282974,
        0.013134951703250408,
        -0.04504053667187691,
        0.05680852383375168,
        -0.015743110328912735,
        0.0442051887512207,
        0.03967634215950966,
        0.05981087684631348,
        -0.01740191876888275,
        0.059663061052560806,
        -0.00853193923830986,
        0.03264668956398964,
        0.03530700504779816,
        -0.004429448861628771,
        0.04211137816309929,
        -0.02916862443089485,
        -0.05902838334441185,
        0.010048236697912216,
        -0.02449817582964897,
        0.058584168553352356,
        0.05997428297996521,
        -0.059918422251939774,
        0.05937722697854042,
        -0.058187086135149,
        -0.0557684600353241,
        0.057334862649440765,
        0.05484551563858986,
        0.05569358915090561,
        -0.05514431372284889,
        0.0251406729221344,
        -0.048132799565792084,
        -0.004123680293560028,
        0.015759658068418503,
        -0.048031099140644073,
        -0.05504744127392769,
        -0.05845008045434952,
        -0.012260142713785172,
        -0.058309491723775864,
        -0.011980616487562656,
        0.0001923928939504549,
        0.028562355786561966,
        0.045283351093530655,
        -0.01892499253153801,
        -0.05908844247460365,
        0.03168331831693649,
        0.0075049870647490025,
        -0.04338094964623451,
        0.03835627809166908,
        -0.02315601147711277,
        0.05318713188171387,
        0.008073048666119576,
        -0.059794843196868896,
        -0.004305290523916483,
        0.0501888245344162,
        0.05420298129320145,
        -0.058673057705163956,
        -0.059560999274253845,
        -0.0560002475976944,
        -0.05730631202459335,
        0.009121879935264587,
        0.017168859019875526,
        0.02703244425356388,
        -0.036927636712789536,
        -0.025821199640631676,
        -0.045781996101140976,
        0.059043996036052704,
        0.026282303035259247
    ],
    [
        -0.047718435525894165,
        -0.05312987044453621,
        0.0530342161655426,
        0.046516381204128265,
        -0.04629349708557129,
        -0.046029407531023026,
        0.01002886425703764,
        -0.053133316338062286,
        0.05256763473153114,
        0.03032487817108631,
        0.05278034880757332,
        -0.04330843687057495,
        -0.053136419504880905,
        -0.032319825142621994,
        -0.05091184750199318,
        0.05087459832429886,
        0.01005334872752428,
        0.05310968682169914,
        0.015040948987007141,
        0.05290360748767853,
        0.052692610770463943,
        -0.0508333295583725,
        -0.050279196351766586,
        -0.0033373588230460882,
        0.05092914402484894,
        0.05305969715118408,
        -0.01551161240786314,
        0.0530901663005352,
        -0.053131431341171265,
        0.0049539306201040745,
        -0.0384439118206501,
        0.0531318373978138,
        0.044237010180950165,
        -0.05309600383043289,
        0.05223299562931061,
        0.047200094908475876,
        -0.04648367315530777,
        -0.04983355104923248,
        0.05152876302599907,
        -0.04590708762407303,
        0.05056385323405266,
        0.00509802857413888,
        0.04812386631965637,
        -0.018095823004841805,
        -0.052927032113075256,
        0.05050351470708847,
        0.015017295256257057,
        0.05085965245962143,
        0.053140025585889816,
        -0.05314449593424797,
        0.04455310478806496,
        0.006485987454652786,
        -0.02923135831952095,
        -0.05313946306705475,
        -0.043927595019340515,
        0.04769563302397728,
        0.051448892802000046,
        -0.00883552897721529,
        0.03207291662693024,
        -0.05292261019349098,
        -0.03177803382277489,
        0.04989098757505417,
        -0.052121710032224655,
        -0.051172830164432526,
        0.031264517456293106,
        -0.05270463973283768,
        -0.043738145381212234,
        -0.030470391735434532,
        0.05314575135707855,
        0.035840705037117004,
        -0.049029991030693054,
        -0.049867384135723114,
        0.05308355763554573,
        0.05313880741596222,
        0.05278954654932022,
        0.04373234882950783,
        0.045992668718099594,
        0.053145769983530045,
        0.05311915650963783,
        -0.00524995056912303,
        0.05180977284908295,
        -0.051911674439907074,
        -0.05314517393708229,
        0.05018853023648262,
        -0.05312497913837433,
        -0.05203397944569588,
        0.0512467622756958,
        0.053106218576431274,
        -0.05252695083618164,
        0.04705064743757248,
        -0.0526040643453598,
        -0.05313734710216522,
        -0.05313229560852051,
        -0.04911067336797714,
        0.05011138319969177,
        0.04687437415122986,
        0.0051709250546991825,
        -0.0464792437851429,
        -0.038937196135520935,
        0.019380273297429085,
        -0.05303670093417168,
        0.05307059735059738,
        0.05292562022805214,
        0.00033156672725453973,
        0.04812884330749512,
        -0.047094035893678665,
        -0.053144846111536026,
        0.05059058219194412,
        -0.0530627965927124,
        0.048494357615709305,
        -0.048089079558849335,
        -0.001304654753766954,
        -0.014606972225010395,
        0.04715370014309883,
        0.0415177196264267,
        -0.04976285621523857,
        -0.052254270762205124,
        -0.05261622369289398,
        -0.05238056555390358,
        -0.052308447659015656,
        -0.01926078461110592,
        -0.0531369149684906,
        0.045356571674346924,
        -0.05249815061688423,
        0.012337533757090569,
        0.05213049426674843,
        -0.05301966145634651,
        -0.05243688076734543,
        -0.02667243592441082,
        -0.05291379243135452,
        0.021323207765817642,
        -0.052637841552495956,
        -0.011545582674443722,
        0.0419771783053875,
        -0.04478719085454941,
        -0.05162323638796806,
        0.0517602302134037,
        0.03055141679942608,
        -0.05299496278166771,
        0.04617936536669731,
        -0.04462019354104996,
        -0.05314001813530922,
        -0.021922891959547997,
        0.05284563824534416,
        -0.03214243799448013,
        0.006179718766361475,
        0.04781297966837883,
        -0.053057558834552765,
        -0.05296459421515465,
        0.03326350077986717,
        -0.05309877544641495,
        -0.05310184881091118,
        0.051353584975004196,
        0.04358844459056854,
        -0.017626048997044563,
        -0.05314301326870918,
        0.029786227270960808,
        0.002688840962946415,
        0.05313396453857422,
        -0.017604917287826538,
        0.0506933368742466,
        0.04855043441057205,
        0.042355574667453766,
        -0.01166632492095232,
        -0.050924912095069885,
        -0.050436217337846756,
        0.05228220298886299,
        0.02953835017979145,
        -0.04237654060125351,
        -0.023359104990959167,
        0.033356085419654846,
        0.013294891454279423,
        -0.05314163118600845,
        0.0522475503385067,
        -0.05244006589055061,
        0.021113034337759018,
        -0.0004541252856142819,
        -0.053097859025001526,
        0.05311840772628784,
        -0.025714166462421417,
        -0.039693307131528854,
        0.05279017612338066,
        -0.014717389829456806,
        -0.028232047334313393,
        -0.006218312308192253,
        0.013864508830010891,
        0.04107285663485527,
        0.050777021795511246,
        0.05265248939394951,
        -0.05314217135310173,
        -0.05314556136727333,
        -0.03731568902730942,
        0.05119306221604347,
        -0.020569154992699623,
        -0.05283753201365471,
        0.03835884481668472,
        0.053054340183734894,
        0.046732500195503235,
        -0.04295339062809944,
        0.04517567530274391,
        0.05310458317399025,
        0.05279241502285004,
        -0.004079663660377264,
        -0.05123114585876465,
        0.04821666330099106,
        -0.050042539834976196,
        0.052460625767707825,
        0.05194610357284546,
        -0.05217279493808746,
        0.05313640832901001,
        -0.05313946679234505,
        -0.002956085605546832,
        0.05313659831881523,
        0.05135597661137581,
        -0.05308878794312477,
        -0.05307924002408981,
        0.05313669890165329,
        -0.051882173866033554,
        0.053076889365911484,
        0.016170168295502663,
        0.03951903432607651,
        -0.04469170793890953,
        -0.03419593349099159,
        -0.017695551738142967,
        0.05305420607328415,
        -0.030317671597003937,
        0.05207131803035736,
        -0.05278366059064865,
        0.013336864300072193,
        -0.05311186611652374,
        -0.052406881004571915,
        0.017573010176420212,
        0.018166055902838707,
        0.04254841431975365,
        -0.03196317330002785,
        0.05296168103814125,
        0.051907990127801895,
        -0.04789196327328682,
        0.04367389902472496,
        0.05227694287896156,
        0.0274001844227314,
        0.0032201947178691626,
        0.05027582496404648,
        -0.05314508080482483,
        0.050292421132326126,
        -0.018690960481762886,
        0.05112243816256523,
        0.04701048880815506,
        0.04918599873781204,
        -0.015645820647478104,
        0.05118652805685997,
        -0.05260554328560829,
        0.04507068172097206,
        -0.05304029583930969,
        0.043914854526519775,
        -0.04549291729927063,
        -0.031954482197761536,
        -0.05206901580095291,
        0.006676204968243837,
        0.024996958673000336,
        -0.04529355466365814,
        -0.04957636445760727,
        0.028495844453573227,
        0.05310947075486183,
        -0.052618350833654404,
        0.05284005403518677,
        0.05308617651462555,
        0.03149840608239174,
        -0.012412755750119686,
        0.0028759611304849386,
        0.04466996714472771,
        -0.05314275622367859,
        0.05252937227487564,
        -0.05304897576570511,
        -0.05126174911856651,
        -0.030419200658798218,
        -0.0021357617806643248,
        -0.05227365344762802,
        0.05123797804117203,
        0.04799425229430199,
        -0.05314388498663902,
        0.053145378828048706,
        -0.049998167902231216,
        -0.021308129653334618,
        0.046072810888290405,
        0.03747289255261421,
        -0.04962920770049095,
        0.053140707314014435,
        -0.023032773286104202,
        0.0428786464035511,
        -0.039975713938474655,
        -0.04641334339976311,
        -0.051846690475940704,
        -0.04014401510357857,
        0.052926428616046906,
        -0.05289078876376152,
        0.05314391478896141,
        0.020400647073984146,
        -0.04272514954209328,
        0.05304576829075813,
        0.052971579134464264,
        -0.04448660835623741,
        -0.053133875131607056,
        0.050972066819667816,
        0.022097673267126083,
        -0.050842273980379105,
        0.05277901887893677,
        0.05313621833920479,
        -0.02671736106276512,
        -0.007092226762324572,
        -0.004481569863855839,
        0.05260547250509262,
        0.030275357887148857,
        0.05267835408449173,
        -0.004930116701871157,
        -0.05304911732673645,
        0.0068596238270401955,
        -0.04552040621638298,
        0.05052992329001427,
        0.038148339837789536,
        0.04943636432290077,
        -0.047213539481163025,
        -0.046142734587192535,
        -0.05134902521967888,
        0.05314464867115021,
        0.030479509383440018,
        0.04766436666250229,
        -0.038155730813741684,
        -0.03791511803865433,
        -0.05303073301911354,
        0.05307626724243164,
        0.006040876731276512,
        -0.052892304956912994,
        -0.037319365888834,
        0.04522217437624931,
        0.053010813891887665,
        -0.04054594412446022,
        0.031064018607139587,
        0.04946397617459297,
        0.028181148692965508,
        -0.042875297367572784,
        -0.05303134396672249,
        0.05313920974731445,
        -0.017038460820913315,
        0.04465487226843834,
        0.05190294235944748,
        -0.0025286520831286907,
        0.04410787299275398,
        -0.05305539071559906,
        0.05312156304717064,
        0.05150360241532326,
        0.028320174664258957,
        0.05085207149386406,
        -0.053136833012104034,
        -0.0425722636282444,
        0.01790492795407772,
        -0.05313461273908615,
        -0.00945022888481617,
        -0.05239596590399742,
        -0.03551298752427101,
        0.04524901509284973,
        0.0529702790081501,
        0.051586855202913284,
        0.036411698907613754,
        -0.04745153710246086,
        -0.049295056611299515,
        0.05196681246161461,
        -0.04508931189775467,
        -0.04540768265724182,
        -0.051406025886535645,
        -0.012842390686273575,
        -0.007248262409120798,
        0.051309097558259964,
        -0.050825487822294235,
        0.04690071940422058,
        0.053049396723508835,
        -0.04143485054373741,
        0.013296875171363354,
        0.0531059093773365,
        0.028321344405412674,
        -0.027779294177889824,
        0.04747643694281578,
        -0.052177660167217255,
        0.009946415200829506,
        0.011218135245144367,
        -0.05309693515300751,
        0.05295097827911377,
        -0.012020416557788849,
        -0.051171593368053436,
        -0.051280293613672256,
        -0.027161864563822746,
        -0.05303223431110382,
        -0.0530521534383297,
        0.043514739722013474,
        0.05314493551850319,
        0.05309426784515381,
        0.0192582868039608,
        -0.05046005919575691,
        -0.04337688907980919,
        -0.053048983216285706,
        0.03204654902219772,
        0.05314507707953453,
        0.004661851096898317,
        0.03945887088775635,
        -0.0501922108232975,
        -0.03766075521707535,
        0.04997561126947403,
        -0.052179496735334396,
        0.051665451377630234,
        -0.021210139617323875,
        0.05295545607805252,
        0.016957329586148262,
        0.028511034324765205,
        -0.011916575953364372,
        0.03412521630525589,
        -0.04619063064455986,
        0.014749734662473202,
        -0.040270909667015076,
        0.04668820649385452,
        0.05240100249648094,
        0.05141843110322952,
        -0.05082680657505989,
        -0.02648756466805935,
        -0.05314281955361366,
        -0.051228586584329605,
        -0.031062914058566093,
        -0.052894167602062225,
        -0.05294537916779518,
        0.052088815718889236,
        0.005117597058415413,
        0.04692327603697777,
        -0.02636348456144333,
        -0.05306656286120415,
        0.04937269911170006,
        -0.05262534320354462,
        -0.052570462226867676,
        -0.05250698700547218,
        0.037195317447185516,
        -0.05304615944623947,
        -0.052769582718610764,
        -0.04906581714749336,
        0.03139222040772438,
        0.01821293495595455,
        0.05109567940235138,
        -0.02128554880619049,
        0.05271877720952034,
        0.032083239406347275,
        -0.037683457136154175,
        0.04470982402563095,
        0.05313907191157341,
        0.0529961884021759,
        -0.0037650628946721554,
        0.05063745006918907,
        -0.0057979472912848,
        0.05314060300588608,
        0.024243447929620743,
        0.001591191627085209,
        0.02756626345217228,
        0.05261674523353577,
        0.03575942665338516,
        0.04056866094470024,
        -0.05314091593027115,
        0.04718852415680885,
        0.04084891453385353,
        0.05307965353131294,
        0.05313785746693611,
        -0.05306673049926758,
        0.052140556275844574,
        -0.05061749368906021,
        -0.053106822073459625,
        0.05267032980918884,
        0.018253996968269348,
        0.052627548575401306,
        -0.040869489312171936,
        0.013379828073084354,
        0.05019177868962288,
        -0.03952494263648987,
        0.05313786864280701,
        -0.0472688190639019,
        -0.04965571314096451,
        -0.052981629967689514,
        -0.05237577110528946,
        -0.052883654832839966,
        -0.042931415140628815,
        -0.04461316764354706,
        0.051374729722738266,
        0.05132925510406494,
        0.04077550768852234,
        -0.05297335237264633,
        -0.009950109757483006,
        0.003940713591873646,
        0.0393531508743763,
        0.05154174193739891,
        0.011181938461959362,
        -0.0523320771753788,
        0.03372765704989433,
        -0.05314427614212036,
        0.0011685440549626946,
        0.05311877653002739,
        0.02685891091823578,
        -0.05313568934798241,
        -0.05303586646914482,
        -0.042725786566734314,
        -0.05095779523253441,
        0.05256197974085808,
        0.05215178430080414,
        0.053117476403713226,
        -0.04828743264079094,
        0.052493900060653687,
        -0.052837587893009186,
        0.05289648845791817,
        0.03221893310546875
    ],
    [
        -0.05684274062514305,
        -0.05791182070970535,
        0.05097336322069168,
        0.04826429858803749,
        -0.011347178369760513,
        -0.03542226925492287,
        0.05745778605341911,
        -0.04334704950451851,
        0.02464485354721546,
        0.05405396595597267,
        0.05596350505948067,
        0.056853462010622025,
        -0.05629780888557434,
        -0.052079033106565475,
        -0.057616595178842545,
        0.017121849581599236,
        -0.050615448504686356,
        0.05428178980946541,
        -0.05520426109433174,
        0.052291058003902435,
        -0.010480365715920925,
        -0.05379779636859894,
        -0.003880678443238139,
        0.026375943794846535,
        0.04266072437167168,
        0.05029653012752533,
        0.05213025212287903,
        0.05640619993209839,
        -0.043093811720609665,
        -0.04347844049334526,
        0.056785039603710175,
        0.031812477856874466,
        0.04093736410140991,
        -0.05764748901128769,
        0.015350605361163616,
        0.005678712856024504,
        -0.05312883481383324,
        -0.032434333115816116,
        -0.04849443584680557,
        0.036868900060653687,
        0.033621303737163544,
        0.034892868250608444,
        0.053905632346868515,
        -0.05704282224178314,
        -0.03700966387987137,
        0.05170879885554314,
        0.02599291317164898,
        0.05803724750876427,
        0.057149000465869904,
        -0.0579586885869503,
        0.04238911718130112,
        -0.052631016820669174,
        0.0443999283015728,
        -0.04507226124405861,
        -0.05526486784219742,
        -0.04599757865071297,
        0.04539388045668602,
        0.03622005134820938,
        0.047424085438251495,
        -0.00522700184956193,
        -0.044198326766490936,
        0.03488742560148239,
        -0.05026102066040039,
        -0.0354316271841526,
        -0.03433418273925781,
        0.0285276398062706,
        -0.002075374824926257,
        -0.006383590865880251,
        0.0580894872546196,
        -0.05810019373893738,
        -0.05800493061542511,
        -0.05792364105582237,
        0.048521894961595535,
        0.037776488810777664,
        -0.054698605090379715,
        0.004192298278212547,
        -0.04853298142552376,
        0.05795486271381378,
        0.049814242869615555,
        -0.05655284598469734,
        -0.021902335807681084,
        0.034911707043647766,
        -0.05735526978969574,
        0.03531283512711525,
        -0.035796575248241425,
        0.0013137096539139748,
        -0.016252649948000908,
        0.05755733698606491,
        -0.03806905075907707,
        0.05608518794178963,
        -0.05457108095288277,
        0.012900437228381634,
        -0.043232791125774384,
        -0.05632022023200989,
        -0.003751160344108939,
        -0.013740798458456993,
        -0.053113967180252075,
        -0.05675750598311424,
        -0.05751556530594826,
        -0.04044623672962189,
        -0.0576876662671566,
        0.05010291561484337,
        0.05778778716921806,
        0.05319005250930786,
        0.045723091810941696,
        -0.046668924391269684,
        -0.05808686837553978,
        0.042338501662015915,
        -0.05767586827278137,
        0.02899874560534954,
        -0.057614170014858246,
        -0.029840201139450073,
        -0.054276756942272186,
        0.01864263042807579,
        0.05218183621764183,
        -0.0550030842423439,
        -0.013499680906534195,
        -0.04820092394948006,
        -0.05706024542450905,
        0.03948042914271355,
        -0.021215951070189476,
        -0.05479304865002632,
        -0.011712830513715744,
        -0.024491241201758385,
        -0.05785316973924637,
        0.05296545475721359,
        -0.053794264793395996,
        0.013303849846124649,
        0.03483422100543976,
        -0.04129721224308014,
        0.04663912206888199,
        -0.04848940670490265,
        -0.030458824709057808,
        0.021022573113441467,
        -0.051428720355033875,
        -0.036182306706905365,
        0.05222023278474808,
        -0.05666908621788025,
        -0.053723543882369995,
        0.05779799818992615,
        -0.003328193910419941,
        -0.02865232527256012,
        0.044160839170217514,
        0.0567634217441082,
        -0.02602124959230423,
        0.008471879176795483,
        0.05718858912587166,
        -0.05580279603600502,
        -0.057414278388023376,
        0.05775734409689903,
        -0.05746637284755707,
        -0.05809570476412773,
        0.01585056260228157,
        0.04643892124295235,
        -0.033382002264261246,
        -0.05777112394571304,
        0.05377836152911186,
        0.04221004620194435,
        0.032213807106018066,
        0.010467566549777985,
        0.0535326711833477,
        0.021514369174838066,
        0.00940582063049078,
        -0.054886143654584885,
        -0.05629045143723488,
        -0.01635943166911602,
        0.04786014184355736,
        -0.04822045937180519,
        0.01698419637978077,
        0.0477062352001667,
        0.03207913041114807,
        0.027130043134093285,
        -0.028865136206150055,
        0.04888603463768959,
        -0.03593754768371582,
        0.05600113794207573,
        -0.05652543529868126,
        0.0017752280691638589,
        0.054472915828228,
        -0.0168800987303257,
        0.048220064491033554,
        0.03936145082116127,
        0.049414414912462234,
        0.05444716662168503,
        0.0339115634560585,
        -0.048287395387887955,
        -0.03384264186024666,
        0.05672214925289154,
        0.0451929047703743,
        -0.05717837065458298,
        -0.05664796754717827,
        -0.036218009889125824,
        0.05268988758325577,
        0.02251298725605011,
        -0.026045873761177063,
        0.0036015675868839025,
        0.05529576912522316,
        0.04426996409893036,
        -0.0276417788118124,
        0.03996756672859192,
        -0.03157448396086693,
        0.05541399493813515,
        -0.01857684552669525,
        0.019254038110375404,
        -0.016389062628149986,
        0.05412111058831215,
        0.04693644866347313,
        -0.011880084872245789,
        -0.025346599519252777,
        0.02919464372098446,
        -0.055106282234191895,
        0.054031867533922195,
        0.05220755189657211,
        0.026448091492056847,
        0.04052455350756645,
        -0.05316019058227539,
        0.05794825777411461,
        -0.01548864133656025,
        0.05798044055700302,
        -0.046163443475961685,
        -0.006443630903959274,
        -0.05742412060499191,
        -0.05748472735285759,
        -0.05797513574361801,
        0.05790216848254204,
        -0.04634493589401245,
        0.029468029737472534,
        0.007459939457476139,
        0.013778234831988811,
        -0.05726480484008789,
        0.0019341431325301528,
        -0.05705257132649422,
        -0.05231194570660591,
        0.0518014021217823,
        -0.04528094828128815,
        0.04974858835339546,
        0.05736013874411583,
        -0.01865929178893566,
        0.03317498043179512,
        0.05397770181298256,
        0.05768329277634621,
        0.043253473937511444,
        0.050879012793302536,
        -0.057129960507154465,
        0.05271226167678833,
        -0.031315483152866364,
        0.008473559282720089,
        0.048692528158426285,
        -0.022639790549874306,
        -0.040473394095897675,
        0.045337848365306854,
        -0.057365287095308304,
        0.05041270703077316,
        -0.05655911937355995,
        0.04872727021574974,
        0.0571899376809597,
        -0.028998536989092827,
        -0.04065968096256256,
        0.05779071897268295,
        0.05768463760614395,
        0.05384033918380737,
        0.04979369416832924,
        0.04825032502412796,
        0.05630957707762718,
        -0.05394001305103302,
        0.001359123270958662,
        0.055437419563531876,
        0.046841803938150406,
        0.052957743406295776,
        0.035635706037282944,
        -0.009637350216507912,
        -0.058104559779167175,
        0.05675724521279335,
        -0.05694189295172691,
        0.008061577565968037,
        0.015325562097132206,
        -0.057227637618780136,
        0.01171464379876852,
        -0.036481358110904694,
        -0.04802105575799942,
        -0.05684281885623932,
        0.04301919415593147,
        0.015125990845263004,
        0.027437256649136543,
        0.03896307945251465,
        -0.048543110489845276,
        -0.01661308854818344,
        -0.041289906948804855,
        -0.054224684834480286,
        0.03682476654648781,
        -0.016464071348309517,
        0.026948189362883568,
        -0.014772540889680386,
        -0.04742954671382904,
        0.04171629250049591,
        -0.05572184920310974,
        0.0580902099609375,
        0.030856771394610405,
        0.013160038739442825,
        0.052906837314367294,
        0.056873757392168045,
        0.039160825312137604,
        -0.029814746230840683,
        0.050686001777648926,
        -0.048485346138477325,
        -0.007655580528080463,
        -0.0143620315939188,
        0.05692552775144577,
        0.03303378075361252,
        -0.034521255642175674,
        0.049656711518764496,
        0.03788033872842789,
        -0.02695230208337307,
        0.05655547231435776,
        -0.05770353227853775,
        -0.05463026463985443,
        0.05223846808075905,
        -0.03153583034873009,
        0.025575023144483566,
        -0.053205739706754684,
        -0.016028229147195816,
        0.033294495195150375,
        0.03507659211754799,
        -0.05650300905108452,
        0.05653221532702446,
        0.05449801683425903,
        0.04644634947180748,
        -0.008548515848815441,
        0.04033629596233368,
        -0.05713088437914848,
        -0.02886589802801609,
        0.05229789763689041,
        0.0036917550023645163,
        -0.04632363095879555,
        0.0546717531979084,
        0.0527128167450428,
        -0.05243298411369324,
        0.05783498287200928,
        -0.048092134296894073,
        0.01321460958570242,
        0.009173342026770115,
        -0.05343511328101158,
        0.05292342230677605,
        -0.026056721806526184,
        0.05811033770442009,
        0.017168182879686356,
        0.03423340246081352,
        0.05353526026010513,
        -0.05737050622701645,
        0.05420009046792984,
        -0.05767778307199478,
        0.047318942844867706,
        -0.04786593094468117,
        -0.058027684688568115,
        -0.056355927139520645,
        0.048538971692323685,
        -0.057783376425504684,
        -0.011296405456960201,
        -0.023636458441615105,
        -0.05185103043913841,
        0.05584091693162918,
        0.05627769976854324,
        -0.047184355556964874,
        -0.020674925297498703,
        0.02135266363620758,
        -0.039147645235061646,
        0.058026548475027084,
        -0.005157351493835449,
        0.05330749601125717,
        -0.057687435299158096,
        -0.04890229180455208,
        -0.053264960646629333,
        0.049999676644802094,
        0.024483969435095787,
        0.05680760368704796,
        0.05730457976460457,
        -0.0378321148455143,
        0.05528617277741432,
        -0.01893727481365204,
        0.01472543366253376,
        -0.047393616288900375,
        0.049721717834472656,
        -0.05602362006902695,
        -0.006199663504958153,
        0.00788854155689478,
        -0.05556938424706459,
        0.05030425637960434,
        -0.05564134940505028,
        -0.05644810199737549,
        -0.05790112540125847,
        -0.006368318572640419,
        -0.047782111912965775,
        -0.024405568838119507,
        0.057046640664339066,
        -0.004845445044338703,
        0.054285719990730286,
        0.04236926883459091,
        -0.03915557637810707,
        -0.006528139114379883,
        -0.05774996057152748,
        -0.02089928835630417,
        0.055160749703645706,
        0.05422616004943848,
        0.058017320930957794,
        -0.054395727813243866,
        0.03175172582268715,
        0.04445609822869301,
        -0.0038374753203243017,
        0.05220523104071617,
        -0.05386762320995331,
        0.05716075375676155,
        -0.007485612761229277,
        0.006876931991428137,
        0.0374973900616169,
        0.05534621700644493,
        -0.030184952542185783,
        0.03361700475215912,
        -0.036694034934043884,
        0.01997830718755722,
        0.007044810336083174,
        -0.0559777207672596,
        -0.041012417525053024,
        -0.05305230990052223,
        -0.057494617998600006,
        -0.02432452328503132,
        0.01474030315876007,
        -0.041624169796705246,
        -0.05784381181001663,
        0.002675598720088601,
        0.006735996343195438,
        0.048705119639635086,
        -0.03196641430258751,
        -0.05785010755062103,
        0.0453462153673172,
        -0.012727894820272923,
        -0.03551645949482918,
        0.03199731186032295,
        0.030394019559025764,
        -0.0010240561095997691,
        -0.051542360335588455,
        0.034008949995040894,
        0.04575413092970848,
        -0.005863996222615242,
        0.0554354265332222,
        0.049978941679000854,
        0.04604427516460419,
        0.054240889847278595,
        -0.05650100111961365,
        0.05656149983406067,
        -0.016531331464648247,
        0.03489958122372627,
        0.05064816027879715,
        0.05304960161447525,
        -0.037035536020994186,
        0.05792591720819473,
        -0.024136532098054886,
        0.012717615813016891,
        0.04602852836251259,
        0.0028942024800926447,
        0.04400902986526489,
        -0.003816016484051943,
        -0.05653458833694458,
        -0.04593449831008911,
        -0.050881292670965195,
        0.05790751427412033,
        0.05797012522816658,
        -0.057462867349386215,
        0.05788154900074005,
        -0.05273938924074173,
        -0.05774947255849838,
        0.028057949617505074,
        0.04665641486644745,
        0.04721171036362648,
        -0.048335667699575424,
        0.021105853840708733,
        0.019378678873181343,
        0.04796720668673515,
        0.055166758596897125,
        -0.05093178153038025,
        -0.032823238521814346,
        -0.05632094293832779,
        0.01729767583310604,
        -0.019052838906645775,
        0.029306218028068542,
        0.027005862444639206,
        0.04960636422038078,
        0.057464297860860825,
        -0.043224312365055084,
        -0.05549510195851326,
        0.03739011287689209,
        -0.037374939769506454,
        -0.053886767476797104,
        -0.05527081340551376,
        0.03721120208501816,
        0.02456396073102951,
        -0.03590192273259163,
        -0.05578460544347763,
        -0.008122021332383156,
        0.056199319660663605,
        0.05637195333838463,
        -0.05770961940288544,
        -0.057222574949264526,
        -0.04564215987920761,
        -0.056926071643829346,
        0.026410464197397232,
        0.03112899512052536,
        0.045233141630887985,
        -0.011795383878052235,
        -0.03246297687292099,
        -0.0550135001540184,
        0.05724747106432915,
        0.054890844970941544
    ],
    [
        -0.058031897991895676,
        -0.05875866860151291,
        0.05694372206926346,
        0.04614952579140663,
        -0.018446044996380806,
        -0.02201022021472454,
        0.0576997734606266,
        -0.049052465707063675,
        0.009957059286534786,
        0.05789748951792717,
        0.05796767771244049,
        0.03983742743730545,
        -0.057632483541965485,
        -0.05440610647201538,
        -0.05829014256596565,
        -0.02948162518441677,
        -0.05552715063095093,
        0.05816647410392761,
        -0.05544879660010338,
        0.044595375657081604,
        -0.01617630571126938,
        -0.054995521903038025,
        0.022769173607230186,
        -0.009774456731975079,
        0.05278522148728371,
        0.05694778263568878,
        0.03650682047009468,
        0.04438865929841995,
        -0.034810688346624374,
        -0.025141986086964607,
        0.05093695968389511,
        0.009047246538102627,
        -0.021410949528217316,
        -0.0585813969373703,
        0.03849611431360245,
        0.051022160798311234,
        -0.05278702452778816,
        -0.01467506866902113,
        -0.050047799944877625,
        -0.0053146048448979855,
        0.04198961332440376,
        0.029508182778954506,
        0.056391920894384384,
        -0.05004221573472023,
        -0.016580717638134956,
        0.056444160640239716,
        0.045613665133714676,
        0.05863151699304581,
        0.05812492221593857,
        -0.05543248727917671,
        0.05220455303788185,
        -0.031950149685144424,
        0.04806146025657654,
        -0.00356103852391243,
        -0.05716200917959213,
        -0.04159192368388176,
        0.050515737384557724,
        0.005176141392439604,
        0.05293542891740799,
        -0.036751534789800644,
        -0.05592523142695427,
        -0.014240449294447899,
        -0.05299947410821915,
        -0.04943199083209038,
        -0.0120905926451087,
        -0.055275872349739075,
        0.052477266639471054,
        0.021546579897403717,
        0.05876076593995094,
        -0.058747291564941406,
        -0.058705493807792664,
        -0.050393350422382355,
        -0.04575250670313835,
        0.03575931489467621,
        -0.03805466368794441,
        -0.005150614771991968,
        -0.054814890027046204,
        0.05832260474562645,
        0.02107408083975315,
        -0.057623427361249924,
        -0.0294833704829216,
        0.03934328258037567,
        -0.05832608789205551,
        0.00791892595589161,
        -0.05787382274866104,
        0.0268483255058527,
        0.031393930315971375,
        0.05863514170050621,
        -0.05008750781416893,
        0.05462346971035004,
        -0.05725552514195442,
        -0.03109104558825493,
        -0.018952995538711548,
        -0.056432515382766724,
        0.022093728184700012,
        0.0161567535251379,
        -0.05358739569783211,
        -0.05786078795790672,
        -0.057247839868068695,
        -0.05087057873606682,
        -0.057536780834198,
        0.04845419526100159,
        0.05792008712887764,
        -0.0006170417764224112,
        0.05579433590173721,
        -0.04933417588472366,
        -0.058745648711919785,
        0.028107844293117523,
        -0.058613236993551254,
        -0.02160172164440155,
        -0.05411070957779884,
        -0.03671599552035332,
        -0.04611670598387718,
        0.031759098172187805,
        0.001812246860936284,
        -0.05431627109646797,
        -0.014779947698116302,
        -0.04941948130726814,
        -0.05722730979323387,
        0.04832618683576584,
        0.024797800928354263,
        -0.05602094158530235,
        0.013761055655777454,
        -0.005064982455223799,
        -0.05835670232772827,
        0.04350873455405235,
        -0.050398387014865875,
        -0.048136018216609955,
        0.033264532685279846,
        -0.053508661687374115,
        0.056669946759939194,
        -0.049664050340652466,
        -0.011615879833698273,
        0.015244551934301853,
        -0.05393916741013527,
        -0.04749634489417076,
        0.0554070807993412,
        -0.05705125629901886,
        -0.04474234580993652,
        0.05870325118303299,
        -0.05418634042143822,
        -0.052116990089416504,
        0.04870317131280899,
        0.05869109928607941,
        -0.05771502107381821,
        0.04731256142258644,
        0.05160032585263252,
        -0.05498636141419411,
        -0.05778083577752113,
        0.055364806205034256,
        -0.05874546617269516,
        -0.05863083153963089,
        0.033373795449733734,
        0.047399088740348816,
        -0.03445577993988991,
        -0.05849073827266693,
        0.03589751943945885,
        0.0124118747189641,
        0.05187267065048218,
        -0.020543014630675316,
        0.04025333747267723,
        -0.04237500578165054,
        0.044266488403081894,
        -0.05722798779606819,
        -0.05588299036026001,
        -0.014271000400185585,
        0.03780100494623184,
        -0.047557126730680466,
        0.05439711734652519,
        0.047452833503484726,
        0.04035530239343643,
        0.040046099573373795,
        0.005048752296715975,
        0.0022711618803441525,
        -0.009935532696545124,
        0.05554645508527756,
        -0.04300079867243767,
        0.03138018026947975,
        0.056309591978788376,
        -0.015473791398108006,
        0.0024785161949694157,
        0.0573389008641243,
        0.03504912555217743,
        -0.024383937940001488,
        -0.05502092093229294,
        -0.04219682142138481,
        -0.03555836156010628,
        0.05613400787115097,
        0.0549805611371994,
        -0.05636950209736824,
        -0.05331984534859657,
        -0.048150256276130676,
        0.020446931943297386,
        -0.019535468891263008,
        -0.04842815920710564,
        -0.007220350671559572,
        0.057653993368148804,
        0.042362429201602936,
        -0.04362010583281517,
        0.037095967680215836,
        0.03914932161569595,
        0.044392090290784836,
        -0.031116792932152748,
        -0.028320616111159325,
        0.05163351446390152,
        0.023549485951662064,
        0.04920655116438866,
        0.025306779891252518,
        0.015635373070836067,
        0.05844782292842865,
        -0.056633662432432175,
        0.04027305543422699,
        0.05690120905637741,
        0.02229415625333786,
        0.023034540936350822,
        -0.05252090469002724,
        0.015899594873189926,
        -0.02163063734769821,
        0.058533959090709686,
        -0.05573687702417374,
        0.03330216556787491,
        -0.058249812573194504,
        -0.05704346299171448,
        -0.05803222581744194,
        0.05851265788078308,
        -0.04220595210790634,
        -0.024202696979045868,
        -0.039869628846645355,
        -0.03668006509542465,
        -0.05827788636088371,
        -0.027162496000528336,
        -0.04575325548648834,
        -0.009033797308802605,
        0.05040581151843071,
        -0.05385684594511986,
        0.04439105466008186,
        0.05709794908761978,
        0.0006528689991682768,
        0.03260424733161926,
        0.0530533567070961,
        0.05549094080924988,
        -0.0387919619679451,
        0.05339278653264046,
        -0.0586346797645092,
        0.040734294801950455,
        0.012519396841526031,
        0.024593664333224297,
        0.05781973898410797,
        -0.007243678905069828,
        -0.029108023270964622,
        0.029126180335879326,
        -0.05689442530274391,
        -0.03849062696099281,
        -0.056607332080602646,
        0.04940035939216614,
        0.04176003113389015,
        0.036257416009902954,
        -0.0071024345234036446,
        0.05722210928797722,
        0.05740717053413391,
        -0.018964329734444618,
        0.046154316514730453,
        0.05785238742828369,
        0.057916488498449326,
        -0.04616287350654602,
        0.04558643698692322,
        0.05492320656776428,
        0.046095672994852066,
        0.04902007430791855,
        -0.03582596778869629,
        -0.01804540492594242,
        -0.058756325393915176,
        0.058667417615652084,
        -0.055510375648736954,
        -0.056843485683202744,
        0.027889424934983253,
        -0.05535751208662987,
        -0.04120207577943802,
        -0.01185387372970581,
        0.005014961119741201,
        -0.05847056955099106,
        0.05845005810260773,
        -0.025548039004206657,
        -0.003297247225418687,
        0.018331190571188927,
        -0.05222773924469948,
        0.022380512207746506,
        -0.029128672555088997,
        -0.05272733420133591,
        0.03379622846841812,
        -0.04735459387302399,
        -0.0054346672259271145,
        -0.03434980288147926,
        -0.030704988166689873,
        0.049087364226579666,
        -0.05594736710190773,
        0.05875876918435097,
        -0.042550377547740936,
        0.046047404408454895,
        0.05593598261475563,
        0.04765933379530907,
        -0.010087281465530396,
        -0.04764223471283913,
        0.022104458883404732,
        -0.04542537406086922,
        -0.03974693641066551,
        0.040839847177267075,
        0.058522164821624756,
        0.04748066887259483,
        0.019633153453469276,
        0.05347947031259537,
        0.05441374331712723,
        -0.04606172442436218,
        0.05706624686717987,
        -0.05696888267993927,
        -0.054005905985832214,
        0.05597446858882904,
        -0.03795047476887703,
        -0.026919063180685043,
        -0.05234808102250099,
        0.056429605931043625,
        -0.049087561666965485,
        -0.049506742507219315,
        -0.0549316368997097,
        0.055630721151828766,
        0.058059558272361755,
        0.02599097043275833,
        0.04285240173339844,
        0.05834946781396866,
        -0.05438796430826187,
        0.00364067405462265,
        0.02353203110396862,
        -0.0010374966077506542,
        -0.023788193240761757,
        0.05538142845034599,
        0.03309575468301773,
        -0.05386686325073242,
        0.0585777573287487,
        -0.055738963186740875,
        0.019655907526612282,
        -0.031019069254398346,
        -0.05788291618227959,
        0.05146726593375206,
        -0.00791445653885603,
        0.05875212326645851,
        -0.007574022281914949,
        0.05479084327816963,
        0.054117560386657715,
        -0.05727927386760712,
        0.04989098384976387,
        -0.058762744069099426,
        0.01233217865228653,
        -0.05183444172143936,
        -0.05794902145862579,
        -0.053207047283649445,
        0.0562233030796051,
        -0.058640800416469574,
        0.0073596416041255,
        -0.041719164699316025,
        -0.057341646403074265,
        0.05245903879404068,
        0.05795145407319069,
        0.002235536463558674,
        -0.03257244452834129,
        0.034127455204725266,
        -0.04297488182783127,
        0.05870795249938965,
        0.020003309473395348,
        0.022701440379023552,
        -0.05849852412939072,
        -0.053472261875867844,
        -0.046145785599946976,
        0.044139325618743896,
        0.050534654408693314,
        0.05094556510448456,
        0.055620383471250534,
        -0.0460406057536602,
        0.035585515201091766,
        -0.0014889356680214405,
        0.046810083091259,
        -0.05088138207793236,
        0.01787165179848671,
        -0.05808668211102486,
        -0.04889213293790817,
        0.0075350250117480755,
        -0.055904701352119446,
        0.054604239761829376,
        -0.047263387590646744,
        -0.058308571577072144,
        -0.05068682134151459,
        0.0005089924670755863,
        0.009374400600790977,
        -0.017582695931196213,
        0.05527566373348236,
        0.02774292416870594,
        0.05448077619075775,
        0.00680155586451292,
        -0.01160559430718422,
        -0.007037920877337456,
        -0.057687945663928986,
        -0.0313306525349617,
        0.04432162269949913,
        0.006631823722273111,
        0.05874296650290489,
        -0.057981573045253754,
        -0.0054651470854878426,
        0.03735356405377388,
        0.00643072510138154,
        0.05834758281707764,
        -0.05740289390087128,
        0.05875087529420853,
        0.02552976831793785,
        0.026332540437579155,
        0.012361953966319561,
        0.05512852594256401,
        -0.013668421655893326,
        0.05728207528591156,
        -0.03966730460524559,
        -0.01380840688943863,
        -0.018102440983057022,
        -0.007018900942057371,
        -0.04866829141974449,
        -0.007440938614308834,
        -0.05710526183247566,
        -0.02913987822830677,
        -0.04474601149559021,
        -0.05774041637778282,
        -0.05853241682052612,
        -0.004570630379021168,
        0.03574035316705704,
        0.04774627462029457,
        0.015774423256516457,
        -0.05874084681272507,
        0.024709582328796387,
        -0.04532705247402191,
        -0.05100307613611221,
        -0.006510360632091761,
        -0.0227449219673872,
        -0.02303473651409149,
        -0.05296074599027634,
        0.01781652681529522,
        0.04641719162464142,
        -0.04068886116147041,
        0.05141041427850723,
        0.05836218222975731,
        -0.006605701521039009,
        0.058178044855594635,
        -0.05840533226728439,
        0.05570198595523834,
        -0.0425463505089283,
        0.0294758602976799,
        0.037831034511327744,
        0.05551079288125038,
        0.00480033690109849,
        0.052270159125328064,
        -0.02922140061855316,
        0.0018097271677106619,
        0.04847269877791405,
        0.04404296725988388,
        -0.019606085494160652,
        0.03377130255103111,
        -0.05028378218412399,
        -0.003830675035715103,
        0.03660450130701065,
        0.04961904510855675,
        0.058499228209257126,
        -0.058508459478616714,
        0.058526113629341125,
        -0.013936139643192291,
        -0.058407433331012726,
        0.02389097400009632,
        0.053938351571559906,
        0.04068811982870102,
        -0.0497831329703331,
        0.021651117131114006,
        0.036742277443408966,
        0.026649298146367073,
        0.056565988808870316,
        -0.01654032990336418,
        -0.0502791590988636,
        -0.05868849158287048,
        -0.021967776119709015,
        -0.05427059903740883,
        0.04472550004720688,
        -0.009712383151054382,
        0.05268610641360283,
        0.05534157529473305,
        -0.04508162662386894,
        -0.054660163819789886,
        0.04876323789358139,
        0.0424281507730484,
        -0.052620530128479004,
        -0.0559433251619339,
        0.028592325747013092,
        0.045559074729681015,
        -0.019657643511891365,
        -0.05470888689160347,
        0.015478824265301228,
        0.05680203437805176,
        0.052857398986816406,
        -0.05436033755540848,
        -0.053416766226291656,
        -0.0492023266851902,
        -0.05844809487462044,
        0.008557016029953957,
        -0.0024536307901144028,
        -0.022321181371808052,
        -0.021789735183119774,
        -0.013026953674852848,
        -0.05470936745405197,
        0.05425330996513367,
        -0.03920913487672806
    ],
    [
        -0.0575663261115551,
        -0.0579831600189209,
        0.056063663214445114,
        0.04418094456195831,
        -0.03951076790690422,
        -0.039607975631952286,
        0.05766281113028526,
        -0.04094014689326286,
        0.05266276001930237,
        0.05757090821862221,
        0.05411073565483093,
        0.055599600076675415,
        -0.057988956570625305,
        -0.05555623024702072,
        -0.05800431966781616,
        0.0083778603002429,
        -0.048910681158304214,
        0.055462293326854706,
        -0.04852035641670227,
        -0.03623528778553009,
        0.0011268049711361527,
        -0.03961724787950516,
        0.010607619769871235,
        -0.01006552018225193,
        0.05272918939590454,
        0.0577206015586853,
        0.0513385534286499,
        0.04263986647129059,
        -0.018343975767493248,
        -0.024056153371930122,
        0.0496395118534565,
        0.05094634369015694,
        -0.02161155268549919,
        -0.057968053966760635,
        0.04361147806048393,
        0.02563616633415222,
        -0.05269107595086098,
        -0.0534210279583931,
        0.014499848708510399,
        -0.055323753505945206,
        0.029873941093683243,
        0.012495091184973717,
        0.04892682656645775,
        -0.05554835870862007,
        0.0058022611774504185,
        0.056097742170095444,
        0.048220351338386536,
        0.057903289794921875,
        0.05801929533481598,
        -0.057299911975860596,
        0.03778155893087387,
        -0.028181301429867744,
        0.04980132356286049,
        -0.04976154491305351,
        -0.0537196509540081,
        -0.047119442373514175,
        -0.019323628395795822,
        0.011822451837360859,
        0.021298548206686974,
        -0.009790890850126743,
        -0.05568897724151611,
        0.01989888586103916,
        -0.047552838921546936,
        -0.042684171348810196,
        0.020081529393792152,
        0.041966430842876434,
        0.004148578271269798,
        -0.028841067105531693,
        0.05816320702433586,
        -0.05815353989601135,
        -0.05819373577833176,
        -0.057697489857673645,
        0.02198280207812786,
        0.0322154276072979,
        -0.02254626899957657,
        0.023341698572039604,
        -0.016965540125966072,
        0.058198001235723495,
        0.019410515204072,
        -0.05582087114453316,
        -0.04213093966245651,
        0.0450793094933033,
        -0.05400088429450989,
        0.0344117246568203,
        -0.03468658775091171,
        0.005436515901237726,
        0.0344293937087059,
        0.058113910257816315,
        -0.043217092752456665,
        0.04126370698213577,
        -0.05802968144416809,
        0.02333012968301773,
        -0.05639685317873955,
        -0.05408140644431114,
        0.044804275035858154,
        -0.024637587368488312,
        -0.03297768905758858,
        -0.057326748967170715,
        -0.057288385927677155,
        -0.055450689047575,
        -0.058174680918455124,
        0.05771452188491821,
        0.05786265805363655,
        0.04565910995006561,
        0.05582091212272644,
        -0.032012104988098145,
        -0.058154765516519547,
        0.052531760185956955,
        -0.05815219134092331,
        0.001547201070934534,
        -0.049974843859672546,
        -0.04002171382308006,
        -0.03552645444869995,
        0.018221989274024963,
        0.022634539753198624,
        -0.0527849979698658,
        -0.03494875133037567,
        -0.04529546573758125,
        -0.05791216343641281,
        -0.005539165809750557,
        -0.010800796560943127,
        -0.05117059499025345,
        0.02065056748688221,
        -0.03225420042872429,
        -0.0550416074693203,
        0.011088003404438496,
        -0.04600615054368973,
        -0.03922406584024429,
        0.011753537692129612,
        -0.03870125114917755,
        0.054875630885362625,
        0.004848840180784464,
        0.02210075408220291,
        -0.0020381230860948563,
        -0.05445920675992966,
        -0.05412963777780533,
        0.05762844905257225,
        -0.056504540145397186,
        -0.05244986712932587,
        0.05685712397098541,
        -0.03389858826994896,
        -0.051749780774116516,
        0.024135680869221687,
        0.05635251849889755,
        -0.04733360558748245,
        0.03868823125958443,
        0.054562248289585114,
        -0.05548235774040222,
        -0.05421866104006767,
        0.036484889686107635,
        -0.05813180282711983,
        -0.0579732246696949,
        0.04381757602095604,
        0.04006987810134888,
        -0.045933883637189865,
        -0.05817493051290512,
        0.024835392832756042,
        -0.04010928422212601,
        0.051884494721889496,
        0.04884021356701851,
        0.05628635734319687,
        -0.011200478300452232,
        0.04309915006160736,
        -0.04525662586092949,
        -0.05799732357263565,
        -0.004529721103608608,
        0.05060311779379845,
        0.0019718045368790627,
        0.04933047667145729,
        0.04611889272928238,
        0.045221343636512756,
        0.04117158055305481,
        -0.03472406044602394,
        0.03747163340449333,
        -0.021513061597943306,
        0.05458161607384682,
        0.0021064679604023695,
        -0.030831996351480484,
        0.05580371990799904,
        -0.02573474869132042,
        -0.0337790921330452,
        0.05328371375799179,
        0.05116714537143707,
        0.040552981197834015,
        -0.03905195742845535,
        0.008064141497015953,
        -0.03780534490942955,
        0.04785624146461487,
        0.050204191356897354,
        -0.05764102190732956,
        -0.0460277758538723,
        -0.05566094443202019,
        0.05115427449345589,
        -0.0217047818005085,
        -0.032267000526189804,
        0.04658668488264084,
        0.05771931633353233,
        -0.0037902456242591143,
        -0.05236269533634186,
        0.024921000003814697,
        0.047104813158512115,
        0.04625501483678818,
        0.01817326806485653,
        0.003175674006342888,
        0.043680135160684586,
        0.04953502118587494,
        0.04377547279000282,
        0.03833586350083351,
        -0.0470440648496151,
        0.043487127870321274,
        -0.05671605467796326,
        0.04706142097711563,
        0.053746677935123444,
        0.030546339228749275,
        -0.002922947984188795,
        -0.020859919488430023,
        0.05800136923789978,
        -0.029493030160665512,
        0.058036886155605316,
        -0.05784527212381363,
        0.037772223353385925,
        -0.0543786957859993,
        -0.057992953807115555,
        -0.05709119513630867,
        0.05819462984800339,
        -0.05573400482535362,
        0.048849236220121384,
        -0.020328063517808914,
        0.020599521696567535,
        -0.056889064610004425,
        0.007454322651028633,
        -0.05568168684840202,
        -0.051953691989183426,
        0.0466422513127327,
        -0.05724162980914116,
        0.04956471174955368,
        0.055698029696941376,
        0.0027118283323943615,
        -0.0002186549681937322,
        0.05327051505446434,
        0.053130924701690674,
        0.01816098764538765,
        0.016015799716114998,
        -0.05553894117474556,
        0.0572378933429718,
        0.03125187009572983,
        0.03456630930304527,
        0.057549141347408295,
        -0.036050472408533096,
        -0.0480513833463192,
        -0.010464470833539963,
        -0.05752359703183174,
        0.007962122559547424,
        -0.05488955229520798,
        -0.014977924525737762,
        0.052963655441999435,
        -0.02955370396375656,
        -0.02639484591782093,
        0.05394604802131653,
        0.056901250034570694,
        0.046121153980493546,
        0.052526332437992096,
        0.05271081626415253,
        0.056784894317388535,
        -0.055769793689250946,
        -0.0015053910901769996,
        0.056395769119262695,
        0.04586195945739746,
        0.03476788103580475,
        -0.05033949017524719,
        -0.015037287026643753,
        -0.058239299803972244,
        0.05794709175825119,
        -0.05550693720579147,
        -0.04082847759127617,
        0.04269229620695114,
        -0.05357195809483528,
        -0.04594087228178978,
        -0.016951441764831543,
        -0.030662953853607178,
        -0.05803428962826729,
        0.05595642700791359,
        0.010033803060650826,
        0.027586501091718674,
        0.0038628883194178343,
        -0.05258375406265259,
        0.0032580995466560125,
        0.004694574046880007,
        -0.004777733236551285,
        0.05024212971329689,
        -0.019590556621551514,
        -0.01412036083638668,
        -0.04765726625919342,
        -0.055920787155628204,
        0.016648054122924805,
        -0.05376047268509865,
        0.058232713490724564,
        -0.02952791564166546,
        -0.026289599016308784,
        0.057462964206933975,
        0.04492182284593582,
        0.033835362643003464,
        -0.008642650209367275,
        0.0464969165623188,
        -0.057674966752529144,
        -0.008754357695579529,
        0.03849821910262108,
        0.057315390557050705,
        -0.010485800914466381,
        0.017237629741430283,
        0.04289984703063965,
        -0.019039694219827652,
        -0.033890124410390854,
        0.053128018975257874,
        -0.05455559864640236,
        -0.05579107999801636,
        0.03982805833220482,
        -0.01854557730257511,
        0.02084997668862343,
        -0.04595308005809784,
        0.052805788815021515,
        -0.05495617166161537,
        -0.004294739570468664,
        -0.054263871163129807,
        0.057580091059207916,
        0.05579539015889168,
        0.05722647160291672,
        0.03873983770608902,
        0.05634712800383568,
        -0.05240258947014809,
        -0.024680547416210175,
        0.05655483901500702,
        0.008941751904785633,
        -0.05462042987346649,
        0.05017446354031563,
        0.049603939056396484,
        -0.05544407665729523,
        0.05551466718316078,
        -0.05535276234149933,
        0.0368722602725029,
        -0.0002108384360326454,
        -0.05745133385062218,
        0.05413632467389107,
        -0.01858319714665413,
        0.058236248791217804,
        0.03804928809404373,
        0.014458577148616314,
        0.049200743436813354,
        -0.04906308650970459,
        0.05210751295089722,
        -0.058166228234767914,
        0.03967738151550293,
        -0.05115669593214989,
        -0.057778194546699524,
        -0.048858750611543655,
        0.053856249898672104,
        -0.05803369730710983,
        -0.0006886568735353649,
        -0.05344723165035248,
        -0.0569775253534317,
        0.05427515134215355,
        0.029099810868501663,
        -0.022783515974879265,
        0.023077022284269333,
        0.04091298207640648,
        -0.05044953152537346,
        0.058081816881895065,
        -0.021608158946037292,
        0.01871325448155403,
        -0.0577712208032608,
        -0.05609636753797531,
        -0.050810497254133224,
        0.05552591383457184,
        0.05189578980207443,
        0.05509693920612335,
        0.05710936710238457,
        -0.04945017397403717,
        0.04880797490477562,
        0.015073482878506184,
        0.017359953373670578,
        -0.029860006645321846,
        0.05428207665681839,
        -0.054409001022577286,
        -0.002284632995724678,
        -0.00129212299361825,
        -0.05741841718554497,
        0.057753097265958786,
        -0.055570535361766815,
        -0.05749005451798439,
        -0.052904289215803146,
        -0.030861275270581245,
        -0.040687426924705505,
        0.014353935606777668,
        0.05515502020716667,
        0.009005194529891014,
        0.052167415618896484,
        0.04386133328080177,
        -0.03180788457393646,
        0.012261836789548397,
        -0.057996924966573715,
        -0.027140352874994278,
        0.03619162738323212,
        0.04513924941420555,
        0.05807046219706535,
        -0.05651485174894333,
        -0.04830733686685562,
        0.024655934423208237,
        0.04383005201816559,
        0.05733257904648781,
        -0.05539141967892647,
        0.05813027173280716,
        0.05556539073586464,
        0.049205612391233444,
        0.047078803181648254,
        0.05020051449537277,
        0.012585126794874668,
        0.0577395036816597,
        -0.018466826528310776,
        0.014884166419506073,
        0.041293367743492126,
        -0.024386005476117134,
        -0.054466504603624344,
        -0.021502764895558357,
        -0.057575952261686325,
        0.026011882349848747,
        -0.03628738224506378,
        -0.057009007781744,
        -0.0577651746571064,
        -0.006395014468580484,
        -0.012042696587741375,
        0.04953760653734207,
        0.036023396998643875,
        -0.05450825393199921,
        0.05159839987754822,
        -0.04466010630130768,
        -0.0429295115172863,
        -0.03964380547404289,
        0.01787145994603634,
        -0.007860083132982254,
        -0.045036859810352325,
        -0.01997101493179798,
        0.05330641567707062,
        0.03423484414815903,
        0.05046352371573448,
        0.05666868016123772,
        0.04039731249213219,
        0.05273755267262459,
        -0.049275532364845276,
        0.055379655212163925,
        -0.004595636855810881,
        0.01655147597193718,
        0.04281517118215561,
        0.054355427622795105,
        -0.037689827382564545,
        0.05652029067277908,
        -0.016121895983815193,
        -0.011262496002018452,
        0.05057281255722046,
        0.017336715012788773,
        0.055097874253988266,
        -0.05347137898206711,
        -0.05766686424612999,
        -0.022515268996357918,
        0.0449216291308403,
        0.05792227014899254,
        0.05813571810722351,
        -0.058166321367025375,
        0.057357531040906906,
        -0.0561634860932827,
        -0.058170489966869354,
        0.0464106947183609,
        0.05416449159383774,
        0.02180274948477745,
        -0.04340324178338051,
        0.027251413092017174,
        0.02290920726954937,
        -0.040850527584552765,
        0.05612354353070259,
        -0.03190753608942032,
        -0.057169269770383835,
        -0.05674542486667633,
        0.02488068863749504,
        -0.05329061299562454,
        0.05733097344636917,
        0.00600252253934741,
        0.05547969415783882,
        0.054937370121479034,
        -0.025562653318047523,
        -0.05433644726872444,
        0.01246187649667263,
        -0.0487716868519783,
        -0.05422468110918999,
        -0.05238661915063858,
        0.010272864252328873,
        0.03477480262517929,
        -0.049391914159059525,
        -0.05351177603006363,
        0.028792276978492737,
        0.057070836424827576,
        0.055637653917074203,
        -0.058078255504369736,
        -0.05712093040347099,
        -0.04594334214925766,
        -0.058105748146772385,
        0.05086061730980873,
        0.019931482151150703,
        -0.02325287275016308,
        0.008183762431144714,
        -0.03599533066153526,
        -0.056310951709747314,
        0.05762352794408798,
        0.036952532827854156
    ],
    [
        -0.02014756388962269,
        -0.05547177419066429,
        0.05495178699493408,
        0.03396996110677719,
        -0.00762251066043973,
        -0.048905935138463974,
        -0.02836889959871769,
        -0.055468786507844925,
        0.04839826375246048,
        0.02556844800710678,
        0.05508752167224884,
        -0.030834985896945,
        -0.054978396743535995,
        -0.01711692288517952,
        -0.052013982087373734,
        0.016063490882515907,
        0.0545484721660614,
        0.012556797824800014,
        0.003985322546213865,
        0.04990254342556,
        0.05482973903417587,
        -0.0435168594121933,
        -0.03339977189898491,
        -0.019425293430685997,
        0.03543436527252197,
        0.05426977574825287,
        -0.01760171726346016,
        0.05544893816113472,
        -0.05551620200276375,
        -0.002489090897142887,
        0.030828259885311127,
        0.05487086996436119,
        0.027277173474431038,
        -0.05552719905972481,
        0.05510789528489113,
        0.0533994622528553,
        -0.050156738609075546,
        -0.05026642233133316,
        0.04399653524160385,
        -0.04994366690516472,
        0.046707287430763245,
        0.008044756948947906,
        0.05324333906173706,
        -0.019339894875884056,
        -0.05319497361779213,
        0.04490288719534874,
        -0.040948234498500824,
        0.05250948294997215,
        0.05551254749298096,
        -0.05553385242819786,
        0.006075871177017689,
        -0.025727013126015663,
        -0.04741160571575165,
        -0.05551793426275253,
        -0.047249484807252884,
        0.04660238325595856,
        0.05503600835800171,
        -0.035066310316324234,
        -0.01066941674798727,
        -0.053956981748342514,
        -0.03663255274295807,
        0.05352811887860298,
        -0.03743245080113411,
        -0.05303474888205528,
        -0.006311153061687946,
        -0.05223117023706436,
        -0.04968814179301262,
        0.0022473162971436977,
        0.05553852394223213,
        0.0016934755258262157,
        -0.054387543350458145,
        -0.014196159318089485,
        0.05505850911140442,
        0.05530434101819992,
        0.05393319949507713,
        0.04513169452548027,
        0.021020757034420967,
        0.055537983775138855,
        0.0489908792078495,
        -0.014113347046077251,
        -0.013453533872961998,
        -0.05550955981016159,
        -0.05548910051584244,
        0.04750927537679672,
        -0.05551055446267128,
        -0.000737956608645618,
        0.041093550622463226,
        0.05542956665158272,
        0.0016471213893964887,
        0.015565899200737476,
        -0.0536373145878315,
        -0.055024221539497375,
        -0.05548856779932976,
        -0.054098524153232574,
        0.01727627031505108,
        0.050280842930078506,
        -0.02062315307557583,
        -0.04303013160824776,
        -0.05235273763537407,
        0.04994017258286476,
        -0.05218781158328056,
        0.05530102550983429,
        0.05388731509447098,
        0.0020556184463202953,
        0.05280248075723648,
        0.01526355929672718,
        -0.05553581565618515,
        0.024982215836644173,
        -0.055026400834321976,
        0.05350232124328613,
        -0.052092354744672775,
        -0.020718654617667198,
        -0.003080979222431779,
        0.054667696356773376,
        0.05277358740568161,
        -0.0531209371984005,
        -0.052593156695365906,
        0.0069150375202298164,
        -0.04221215099096298,
        -0.03713620454072952,
        -0.03820221871137619,
        -0.05552351102232933,
        0.05277806147933006,
        -0.055503565818071365,
        -0.052012886852025986,
        0.0540292002260685,
        -0.05479495972394943,
        -0.054177992045879364,
        -0.005457185674458742,
        -0.054180413484573364,
        0.04836661368608475,
        -0.05119287222623825,
        -0.02683218941092491,
        0.035057757049798965,
        -0.03649406507611275,
        -0.038423970341682434,
        0.018854403868317604,
        -0.0285186767578125,
        -0.05456605553627014,
        0.04596162214875221,
        -0.009583958424627781,
        -0.05553650110960007,
        0.01503565814346075,
        0.05488060042262077,
        -0.00658154534175992,
        0.03449825942516327,
        -0.017397593706846237,
        -0.05360453203320503,
        -0.05476905032992363,
        0.0400548130273819,
        -0.05477893352508545,
        -0.05370388925075531,
        0.048275984823703766,
        0.05335376411676407,
        -0.02712188847362995,
        -0.05552336573600769,
        0.010842886753380299,
        -0.04124283790588379,
        0.054235391318798065,
        -0.0049926177598536015,
        0.05183669179677963,
        0.021732253953814507,
        0.05058671534061432,
        0.023845696821808815,
        -0.05377410724759102,
        -0.020397836342453957,
        0.048562660813331604,
        -0.04793046787381172,
        -0.013171893544495106,
        -0.018784254789352417,
        -0.016508037224411964,
        0.03783464431762695,
        -0.05553251504898071,
        0.048042282462120056,
        -0.054437074810266495,
        0.028990022838115692,
        -0.020130710676312447,
        -0.05462031066417694,
        0.0554565005004406,
        -0.04498782381415367,
        -0.05418005213141441,
        0.05513758957386017,
        -0.009724984876811504,
        -0.018341640010476112,
        0.05400989577174187,
        0.05435088649392128,
        0.03892206400632858,
        0.054956063628196716,
        0.054565250873565674,
        -0.05551930516958237,
        -0.05553807318210602,
        -0.05280325189232826,
        0.05465703830122948,
        0.031647853553295135,
        -0.047444287687540054,
        0.03468665853142738,
        0.05065934732556343,
        0.020281028002500534,
        0.005780437029898167,
        0.050980810075998306,
        0.05526788905262947,
        0.0536743625998497,
        -0.04405836760997772,
        -0.055505286902189255,
        0.022356830537319183,
        -0.0405692458152771,
        0.041582055389881134,
        0.05546286329627037,
        -0.04731188714504242,
        0.055435266345739365,
        -0.05547702684998512,
        0.052225373685359955,
        0.055475056171417236,
        0.05535663664340973,
        -0.0554623082280159,
        -0.05537363514304161,
        0.055466171354055405,
        -0.05269937217235565,
        0.05547425523400307,
        -0.028607772663235664,
        0.02873779647052288,
        -0.03819273039698601,
        -0.037730515003204346,
        -0.04552304744720459,
        0.05447840690612793,
        0.007912958040833473,
        0.04471652954816818,
        -0.05343196541070938,
        -0.040738288313150406,
        -0.05536112189292908,
        -0.03368707001209259,
        0.014265903271734715,
        -0.03252418711781502,
        0.04138914868235588,
        -0.0309092178940773,
        0.05531630665063858,
        0.019875427708029747,
        -0.049409061670303345,
        0.04871436208486557,
        0.055450160056352615,
        0.01613914780318737,
        0.013400694355368614,
        0.034361597150564194,
        -0.055537573993206024,
        0.050162047147750854,
        -0.03425125032663345,
        0.05173312872648239,
        0.012790665961802006,
        0.05549122020602226,
        -0.04784131050109863,
        0.05149101838469505,
        -0.04874873906373978,
        -0.028588606044650078,
        -0.05518609285354614,
        0.05165541172027588,
        0.011090636253356934,
        -0.03289404138922691,
        -0.03291069343686104,
        -0.022944821044802666,
        0.018377678468823433,
        -0.03930191695690155,
        -0.05436481162905693,
        0.03561615198850632,
        0.0554993562400341,
        -0.055255062878131866,
        0.05086555704474449,
        0.05411946401000023,
        0.05039381980895996,
        0.03552798181772232,
        -0.010194020345807076,
        0.040211040526628494,
        -0.05421121045947075,
        0.05531679093837738,
        -0.05521366372704506,
        -0.044274140149354935,
        0.050532713532447815,
        -0.021157128736376762,
        -0.03229652717709541,
        0.051770396530628204,
        0.05332695692777634,
        -0.05142112448811531,
        0.05552154779434204,
        -0.006705361418426037,
        -0.031106235459446907,
        0.0354541577398777,
        0.05172852799296379,
        0.015729522332549095,
        0.05550838261842728,
        -0.0017287415685132146,
        -0.021276986226439476,
        -0.03007027693092823,
        -0.04407534375786781,
        -0.053621597588062286,
        -0.05049530416727066,
        0.05496182665228844,
        -0.0499664805829525,
        0.05553749203681946,
        -0.054750457406044006,
        0.0015692682936787605,
        0.05548516660928726,
        0.055456582456827164,
        -0.034194979816675186,
        -0.04938003420829773,
        0.05170565843582153,
        0.02493978478014469,
        -0.03471026197075844,
        0.05380519479513168,
        0.05509548261761665,
        -0.051890067756175995,
        0.034778282046318054,
        0.04022613540291786,
        0.05441970005631447,
        0.05115102604031563,
        0.05333826690912247,
        -0.024903593584895134,
        -0.054691702127456665,
        0.028998248279094696,
        -0.049515921622514725,
        0.052354782819747925,
        0.016353664919734,
        0.05376238375902176,
        0.006693401373922825,
        -0.05292346328496933,
        -0.04977801442146301,
        0.05523259565234184,
        0.005453885532915592,
        0.050500743091106415,
        -0.040014151483774185,
        -0.03214080631732941,
        -0.055471792817115784,
        0.02178613655269146,
        0.01849132776260376,
        -0.05449489876627922,
        0.004220302682369947,
        0.05305226147174835,
        0.05352095514535904,
        0.04884392395615578,
        -0.030925871804356575,
        0.023350369185209274,
        -0.015424871817231178,
        -0.015145955607295036,
        -0.05166580528020859,
        0.05544707179069519,
        -0.05127440392971039,
        0.009611515328288078,
        0.052681758999824524,
        0.0019640636164695024,
        0.05483083054423332,
        -0.05496223270893097,
        0.05089183524250984,
        0.05315890908241272,
        0.04923310875892639,
        0.0311883632093668,
        -0.05535196512937546,
        -0.04061092436313629,
        -0.03486089035868645,
        -0.05553600937128067,
        -0.044106848537921906,
        -0.047224026173353195,
        -0.054905716329813004,
        0.04900473728775978,
        0.054867710918188095,
        0.01947774365544319,
        -0.023947445675730705,
        -0.050651997327804565,
        -0.013159873895347118,
        0.05338287353515625,
        -0.0468141995370388,
        -0.05507918447256088,
        -0.05385076254606247,
        -0.05279432237148285,
        0.04225032404065132,
        0.006382400635629892,
        -0.05469053238630295,
        0.051850590854883194,
        0.0548897311091423,
        -0.038111332803964615,
        0.02772745117545128,
        0.055096667259931564,
        0.03888566419482231,
        0.032156236469745636,
        0.05029679089784622,
        -0.054361093789339066,
        0.05370699614286423,
        -0.002626727567985654,
        -0.05544600635766983,
        0.05229462310671806,
        0.0427582785487175,
        -0.0412401519715786,
        -0.05531728267669678,
        -0.025221042335033417,
        -0.0553344301879406,
        -0.05434209480881691,
        0.04865807667374611,
        0.05545996129512787,
        0.05528251454234123,
        0.04806958884000778,
        -0.04099316895008087,
        -0.05445234477519989,
        -0.05549980327486992,
        -0.0194571353495121,
        0.055536698549985886,
        0.029240427538752556,
        0.007000484503805637,
        -0.05400120094418526,
        -0.0058991266414523125,
        0.04552563279867172,
        -0.04981718584895134,
        0.05327118560671806,
        -0.05517632141709328,
        0.055389404296875,
        0.00613755639642477,
        -0.02111152932047844,
        -0.03187956660985947,
        0.03855069726705551,
        -0.05343661829829216,
        -0.010781490243971348,
        -0.042977992445230484,
        0.04732256382703781,
        0.055096257477998734,
        0.055019959807395935,
        0.009210886433720589,
        -0.0445166677236557,
        -0.05547698587179184,
        -0.05183033272624016,
        0.04185442999005318,
        -0.05426570028066635,
        -0.044164661318063736,
        0.05537295341491699,
        -0.02749239094555378,
        0.025857117027044296,
        0.03955363854765892,
        -0.05247195437550545,
        0.02953983284533024,
        -0.05145246535539627,
        -0.05118074268102646,
        -0.05137242376804352,
        0.03828911483287811,
        -0.053197477012872696,
        -0.05546409264206886,
        -0.033935025334358215,
        -0.0074550616554915905,
        0.013288617134094238,
        0.052910998463630676,
        0.04510720074176788,
        0.05539325997233391,
        0.05501488223671913,
        0.007903513498604298,
        0.0047537703067064285,
        0.054294828325510025,
        0.052717894315719604,
        0.04884469509124756,
        0.030974656343460083,
        0.04151984676718712,
        0.05551651120185852,
        -0.025600429624319077,
        0.053904563188552856,
        -0.04196809232234955,
        0.045159127563238144,
        0.055343251675367355,
        0.04346967861056328,
        -0.055517807602882385,
        0.02130257338285446,
        -0.024343619123101234,
        0.05548045411705971,
        0.05550782382488251,
        -0.0548010878264904,
        0.022771455347537994,
        -0.0476963110268116,
        -0.05553102865815163,
        0.05552415922284126,
        0.051820311695337296,
        0.055025722831487656,
        -0.00020525460422504693,
        -0.02319214679300785,
        0.05328613892197609,
        0.014751212671399117,
        0.05546614155173302,
        -0.04077538102865219,
        -0.044116731733083725,
        -0.05340787395834923,
        -0.01412302628159523,
        -0.05530394986271858,
        -0.04454895108938217,
        -0.03641591593623161,
        0.055207591503858566,
        0.028508514165878296,
        -0.011110801249742508,
        -0.05479661747813225,
        0.043446242809295654,
        0.033854104578495026,
        0.047319743782281876,
        0.05030818656086922,
        -0.0465291291475296,
        -0.05018482357263565,
        -0.03658026084303856,
        -0.05552036687731743,
        0.008084284141659737,
        0.05383165180683136,
        0.044860679656267166,
        -0.055446088314056396,
        -0.05531733110547066,
        -0.04618149623274803,
        -0.0524822436273098,
        -0.0026566251181066036,
        0.051917608827352524,
        0.05550410598516464,
        -0.04997090622782707,
        0.04186786711215973,
        -0.05487019568681717,
        0.04792201146483421,
        0.039010219275951385
    ],
    [
        -0.04735106602311134,
        0.05757899582386017,
        0.05672526732087135,
        0.04470185935497284,
        0.05898281931877136,
        -0.03666802495718002,
        0.060553405433893204,
        -0.057552922517061234,
        0.052395809441804886,
        0.05366436392068863,
        0.04148993641138077,
        0.054516877979040146,
        -0.059823304414749146,
        0.04087308794260025,
        -0.060772065073251724,
        0.04388698935508728,
        -0.05697430297732353,
        0.04894975200295448,
        -0.053175926208496094,
        0.04205366224050522,
        0.0025877177249640226,
        0.01290126796811819,
        0.05099518597126007,
        -0.03586282208561897,
        0.05739488825201988,
        0.017559044063091278,
        0.03552716225385666,
        0.060146771371364594,
        -0.02857864275574684,
        -0.052688442170619965,
        0.03601645305752754,
        0.034577637910842896,
        0.057512205094099045,
        -0.04788883775472641,
        0.02407964877784252,
        -0.039693284779787064,
        -0.05859574303030968,
        -0.05854807421565056,
        -0.0338263101875782,
        0.049346715211868286,
        0.017639553174376488,
        0.0036832760088145733,
        0.050186771899461746,
        -0.05759077146649361,
        0.05615975707769394,
        -0.00849475059658289,
        0.04732639342546463,
        0.056572236120700836,
        0.060635920614004135,
        0.012587370350956917,
        0.05706486850976944,
        0.017799464985728264,
        0.020670248195528984,
        -0.05955050513148308,
        0.0489773266017437,
        -0.03391408920288086,
        -0.05948659032583237,
        -0.04548799991607666,
        -0.029954832047224045,
        0.029332969337701797,
        -0.05625564232468605,
        0.026072366163134575,
        -0.054297324270009995,
        0.05580766871571541,
        -0.020065242424607277,
        -0.020825251936912537,
        -0.0028500796761363745,
        0.03115314058959484,
        -0.0034650249872356653,
        -0.05945500731468201,
        -0.04737893491983414,
        -0.0606885626912117,
        0.05728762224316597,
        0.04229368641972542,
        -0.04756472259759903,
        -0.03258352726697922,
        0.059725821018218994,
        0.060885075479745865,
        0.032101742923259735,
        -0.041680220514535904,
        -0.028443381190299988,
        0.030276715755462646,
        -0.04574524611234665,
        0.0573013611137867,
        0.04752182215452194,
        0.05714224651455879,
        -0.03805036470293999,
        -0.006650235969573259,
        -0.028631288558244705,
        0.060843877494335175,
        -0.04170804098248482,
        -0.040190570056438446,
        -0.05245643109083176,
        -0.031836412847042084,
        0.016256598755717278,
        -0.047391559928655624,
        -0.05779220536351204,
        -0.03865909203886986,
        -0.018938351422548294,
        -0.003229820402339101,
        -0.060490045696496964,
        -0.047049567103385925,
        0.050584565848112106,
        -0.012147367000579834,
        0.05644312873482704,
        -0.056652650237083435,
        -0.05868701636791229,
        0.05115991085767746,
        -0.030359620228409767,
        0.038633767515420914,
        -0.05038293078541756,
        0.007039097603410482,
        -0.05188940837979317,
        -0.015710078179836273,
        0.05090867355465889,
        -0.015874359756708145,
        0.01699087768793106,
        -0.054204296320676804,
        -0.05612238124012947,
        -0.03491270914673805,
        -0.028822772204875946,
        0.007087548263370991,
        -0.05792294070124626,
        -0.04708253592252731,
        -0.06086258962750435,
        0.05397626757621765,
        -0.044650595635175705,
        0.05972025915980339,
        0.05725202336907387,
        0.015935026109218597,
        -0.03529258444905281,
        -0.04276647791266441,
        -0.003844636958092451,
        0.0023064552806317806,
        -0.05610973387956619,
        -0.051341235637664795,
        0.02278948575258255,
        -0.047466687858104706,
        -0.056009046733379364,
        0.055087894201278687,
        0.058648210018873215,
        0.052883002907037735,
        0.0563679076731205,
        -0.002775204600766301,
        -0.046336036175489426,
        0.006892351899296045,
        0.009811700321733952,
        -0.055449388921260834,
        -0.050434939563274384,
        -0.02661263756453991,
        -0.016859082505106926,
        -0.06083659082651138,
        -0.026526935398578644,
        0.0379948690533638,
        -0.039885278791189194,
        -0.05957593768835068,
        0.05768958106637001,
        -0.01176969800144434,
        0.04352668300271034,
        -0.030917948111891747,
        0.060130562633275986,
        0.03713848441839218,
        0.04200810566544533,
        -0.030170945450663567,
        -0.05694945529103279,
        -0.04795427620410919,
        -0.05100144445896149,
        -0.04093361273407936,
        0.04981935769319534,
        0.05659995228052139,
        -0.03713316097855568,
        0.01601445861160755,
        -0.06078662723302841,
        0.03978435695171356,
        -0.01960182376205921,
        0.04577189311385155,
        -0.057076726108789444,
        0.0405893474817276,
        0.05375424027442932,
        0.0586165226995945,
        0.05704367905855179,
        0.012274179607629776,
        0.054760921746492386,
        0.038277264684438705,
        0.012704838067293167,
        0.007959567941725254,
        0.02258390001952648,
        0.05507924035191536,
        -0.030923480167984962,
        -0.058615319430828094,
        -0.043572280555963516,
        -0.03138809651136398,
        -0.055309560149908066,
        -0.038035277277231216,
        -0.025767860934138298,
        0.02092643827199936,
        0.05929350107908249,
        -0.05481569096446037,
        0.022354578599333763,
        -0.007649779785424471,
        -0.018841616809368134,
        0.05723404139280319,
        0.019971739500761032,
        0.020990656688809395,
        0.023197833448648453,
        0.03906087204813957,
        0.04327535256743431,
        0.029651354998350143,
        -0.05689205601811409,
        -0.02253512293100357,
        -0.05660737678408623,
        0.03396080806851387,
        0.020758379250764847,
        0.015070879831910133,
        0.03776505962014198,
        0.017048131674528122,
        0.05456707626581192,
        0.01818946562707424,
        0.05847877264022827,
        -0.03375421091914177,
        0.011165183037519455,
        0.02924024686217308,
        -0.04745703935623169,
        -0.05867699161171913,
        0.06001252681016922,
        -0.061013899743556976,
        0.03720848634839058,
        0.01485174149274826,
        -0.03387521579861641,
        -0.05382464453577995,
        -0.04217834025621414,
        -0.057365916669368744,
        -0.041982002556324005,
        0.054662659764289856,
        0.038645852357149124,
        -0.04644712805747986,
        0.057918302714824677,
        -0.044721491634845734,
        0.03392810374498367,
        0.0594971626996994,
        0.05300525575876236,
        0.036865875124931335,
        -0.03363294154405594,
        0.0550624243915081,
        -0.05382034182548523,
        0.011110431514680386,
        0.047572724521160126,
        0.06008394807577133,
        -0.04357725754380226,
        -0.04322272539138794,
        -0.007159556727856398,
        -0.06044464558362961,
        -0.04456595331430435,
        -0.05693624168634415,
        -0.010345280170440674,
        0.06020871549844742,
        -0.034786637872457504,
        0.006784219294786453,
        0.06044798716902733,
        0.06113971397280693,
        0.055209796875715256,
        0.03342927247285843,
        0.007189788389950991,
        0.05446900054812431,
        -0.0393940731883049,
        0.057965364307165146,
        -0.004081989638507366,
        -0.04704328998923302,
        -0.017596326768398285,
        0.03344554081559181,
        -0.03709413483738899,
        -0.05966561287641525,
        -0.00868880096822977,
        -0.060571178793907166,
        -0.051101382821798325,
        0.006834512110799551,
        -0.06077970936894417,
        -0.007110707927495241,
        -0.04401605576276779,
        -0.059000492095947266,
        -0.056112803518772125,
        -0.024303004145622253,
        -0.016726605594158173,
        0.055102307349443436,
        0.0034824449103325605,
        -0.0011572408257052302,
        0.04531945288181305,
        -0.05119287595152855,
        -0.04576369747519493,
        0.04703418165445328,
        -0.01861160434782505,
        0.05109323561191559,
        0.028922511264681816,
        -0.0012618163600564003,
        0.021859722211956978,
        -0.05023479461669922,
        0.0606098547577858,
        0.015027019195258617,
        -0.054798465222120285,
        0.058491818606853485,
        -0.054941073060035706,
        0.058550648391246796,
        -0.013684247620403767,
        0.05528227612376213,
        0.017049385234713554,
        -0.03667011111974716,
        -0.05615619942545891,
        -0.01080173160880804,
        0.0421677865087986,
        -0.05977528914809227,
        0.027907973155379295,
        -0.03732449561357498,
        -0.038165800273418427,
        0.05083918198943138,
        -0.059751350432634354,
        -0.05565991252660751,
        0.057449523359537125,
        0.03403470292687416,
        0.027139639481902122,
        -0.04935172200202942,
        0.007070188410580158,
        0.03789635747671127,
        0.05787605419754982,
        -0.03872644156217575,
        0.06097448617219925,
        0.02113373950123787,
        0.04790474474430084,
        -0.055537816137075424,
        0.05161402374505997,
        -0.029148554429411888,
        -0.019184621050953865,
        0.024928392842411995,
        0.05290821194648743,
        -0.04067576676607132,
        0.05575672164559364,
        0.023637663573026657,
        -0.053050342947244644,
        0.06043926998972893,
        -0.03553957864642143,
        0.044952426105737686,
        0.013019921258091927,
        -0.05668164789676666,
        -0.00809557270258665,
        -0.018324635922908783,
        0.061212267726659775,
        0.05901007354259491,
        -0.011055358685553074,
        0.05554518848657608,
        -0.025297243148088455,
        0.05849996581673622,
        -0.060776326805353165,
        0.048085495829582214,
        0.00443060789257288,
        -0.06014464423060417,
        0.03018350526690483,
        0.05015726760029793,
        -0.03349671512842178,
        -0.03498531132936478,
        -0.059108976274728775,
        -0.057377807796001434,
        0.057980574667453766,
        -0.03432253748178482,
        -0.057055938988924026,
        -0.01475292444229126,
        0.040459658950567245,
        -0.05532153695821762,
        0.06098766624927521,
        0.0417039692401886,
        0.05465463176369667,
        -0.06100819632411003,
        -0.04503823071718216,
        -0.0608801543712616,
        0.06104138121008873,
        0.0010303575545549393,
        0.03860558941960335,
        0.05840544402599335,
        0.050886791199445724,
        -0.02291092649102211,
        0.03981715068221092,
        -0.05963641777634621,
        -0.05963475629687309,
        0.05745154991745949,
        -0.05763141065835953,
        0.04860168695449829,
        0.057749055325984955,
        -0.042827632278203964,
        0.029971225187182426,
        -0.059245072305202484,
        -0.04863189533352852,
        -0.05136105418205261,
        -0.05102107673883438,
        0.0005004663253203034,
        0.030150791630148888,
        0.006861790083348751,
        0.0057876030914485455,
        0.0573403425514698,
        0.010346920229494572,
        -0.034945350140333176,
        0.014111599884927273,
        -0.061206456273794174,
        -0.053795866668224335,
        0.05767133831977844,
        0.06050226092338562,
        0.05400526896119118,
        -0.04716275632381439,
        0.04781904071569443,
        0.04163092374801636,
        -0.035231806337833405,
        0.05487111583352089,
        -0.043098777532577515,
        0.021196188405156136,
        -0.033953506499528885,
        0.055112648755311966,
        -0.03096109814941883,
        0.03549028933048248,
        -0.04340237006545067,
        -0.02529900148510933,
        -0.021510029211640358,
        0.053151484578847885,
        0.057232897728681564,
        -0.05809219554066658,
        -0.05442376807332039,
        -0.047974202781915665,
        -0.057840436697006226,
        0.059698257595300674,
        -0.010663049295544624,
        -0.05752623453736305,
        -0.038736384361982346,
        -0.06106571853160858,
        -0.007302515208721161,
        -0.0061349752359092236,
        0.021291710436344147,
        0.012768223881721497,
        0.03092372417449951,
        -0.049501895904541016,
        -0.022568201646208763,
        -0.059794552624225616,
        0.05091771110892296,
        0.03869900479912758,
        -0.04283764585852623,
        -0.02923963963985443,
        0.031192809343338013,
        0.06001672148704529,
        0.05596781149506569,
        0.02126067876815796,
        -0.04443919658660889,
        0.060291532427072525,
        -0.05613032728433609,
        0.05648548901081085,
        -0.021739987656474113,
        0.0351174958050251,
        0.06084210425615311,
        -0.007326172199100256,
        -0.05152080953121185,
        0.007194248493760824,
        -0.032100263983011246,
        -0.008887694217264652,
        0.05477910861372948,
        -0.03498997911810875,
        0.060351863503456116,
        0.0003353828506078571,
        -0.04997999966144562,
        -0.05913702771067619,
        -0.0016689334297552705,
        0.050732750445604324,
        0.06074879318475723,
        -0.06095072254538536,
        0.06063000112771988,
        -0.05594487115740776,
        -0.05749233812093735,
        0.023708747699856758,
        0.011345681734383106,
        0.04568437486886978,
        0.04340207204222679,
        0.05988320708274841,
        0.05042516067624092,
        -0.035669226199388504,
        0.03849952295422554,
        -0.02424732595682144,
        -0.05386190861463547,
        0.034669749438762665,
        -0.01257081888616085,
        0.05726543441414833,
        0.041104596108198166,
        0.033860571682453156,
        0.016157783567905426,
        0.04791945219039917,
        0.0007679133559577167,
        -0.058078259229660034,
        -0.03287234157323837,
        -0.060897860676050186,
        -0.05268443375825882,
        0.05498729646205902,
        0.03483908995985985,
        0.059771303087472916,
        0.004702565725892782,
        -0.05716705322265625,
        -0.027661718428134918,
        0.058006495237350464,
        0.05691761523485184,
        -0.05456369370222092,
        -0.056568000465631485,
        -0.016641410067677498,
        0.05448492243885994,
        0.021934662014245987,
        0.03683130443096161,
        0.018785879015922546,
        -0.0008226064965128899,
        -0.025588830932974815,
        -0.03264252841472626,
        0.05993747338652611,
        0.054753076285123825
    ],
    [
        0.013694202527403831,
        -0.035192348062992096,
        -0.04467808082699776,
        -0.012286615557968616,
        0.005781449377536774,
        -0.053657662123441696,
        0.05176462233066559,
        -0.052025847136974335,
        0.052626460790634155,
        -0.012457885779440403,
        0.04457349330186844,
        -0.05254098400473595,
        -0.054754097014665604,
        0.015239754691720009,
        -0.0532597191631794,
        0.052485328167676926,
        -0.042939428240060806,
        0.054873187094926834,
        -0.050026070326566696,
        0.05428122356534004,
        0.05341995507478714,
        -0.026643674820661545,
        0.05319526046514511,
        0.04415219649672508,
        -0.054667480289936066,
        0.05250467360019684,
        0.03299181908369064,
        0.05281582102179527,
        -0.04202603921294212,
        -0.012050564400851727,
        0.039467327296733856,
        0.05461101233959198,
        -0.025973336771130562,
        -0.054078612476587296,
        0.05367351695895195,
        -0.049700114876031876,
        -0.05415720492601395,
        -0.054926980286836624,
        -0.004899532999843359,
        -0.02621248923242092,
        0.048920273780822754,
        -0.028705907985568047,
        0.047090932726860046,
        -0.012461717240512371,
        -0.009058605879545212,
        0.000875502300914377,
        0.051194388419389725,
        0.05487494543194771,
        0.054944928735494614,
        -0.0525628887116909,
        0.05496744066476822,
        0.04970259591937065,
        0.04139770567417145,
        -0.05332447960972786,
        0.054323919117450714,
        0.044714175164699554,
        -0.03664562478661537,
        -0.04097314924001694,
        -0.05425258353352547,
        -0.04380626231431961,
        -0.05496402084827423,
        0.03838535025715828,
        -0.05488964170217514,
        -0.05359106510877609,
        0.046386174857616425,
        -0.04746641963720322,
        -0.05407936871051788,
        -0.043008435517549515,
        0.05490826070308685,
        -0.054801374673843384,
        -0.005544381681829691,
        -0.051712989807128906,
        0.04346776753664017,
        0.049659132957458496,
        0.05442303046584129,
        -0.05390944704413414,
        0.04017117619514465,
        0.054973796010017395,
        0.0512312613427639,
        0.053987566381692886,
        -0.03379650041460991,
        -0.05426178500056267,
        -0.041097551584243774,
        0.04753571003675461,
        0.03686689957976341,
        0.027284568175673485,
        -0.05178205668926239,
        0.03188558295369148,
        -0.04358944669365883,
        0.037528228014707565,
        -0.03911273553967476,
        -0.04029259458184242,
        0.03736388310790062,
        -0.05490685626864433,
        -0.016112104058265686,
        0.021419735625386238,
        -0.053035613149404526,
        -0.054961591958999634,
        -0.05267823487520218,
        0.05078718438744545,
        -0.054491546005010605,
        0.04921060428023338,
        0.05124254524707794,
        0.05355096608400345,
        0.05374528467655182,
        0.034814607352018356,
        -0.05401204153895378,
        0.04349350929260254,
        -0.04996633157134056,
        0.05060537904500961,
        0.03211543336510658,
        -0.05321458354592323,
        -0.042240168899297714,
        -0.04752625524997711,
        0.039499614387750626,
        -0.05275419354438782,
        -0.009149954654276371,
        -0.051613736897706985,
        0.007388161029666662,
        -0.050832126289606094,
        -0.0028160896617919207,
        -0.030645854771137238,
        -0.017711304128170013,
        0.0038029972929507494,
        -0.05252079293131828,
        0.05073581635951996,
        -0.05052480101585388,
        0.009652717038989067,
        0.04881173372268677,
        0.0031597332563251257,
        0.019326595589518547,
        -0.05449778214097023,
        -0.049695856869220734,
        0.05412358418107033,
        -0.05421037971973419,
        -0.0541905015707016,
        0.054964203387498856,
        -0.05378318205475807,
        -0.04007362201809883,
        0.03881118819117546,
        -0.05022107809782028,
        -0.053199730813503265,
        0.04972179979085922,
        0.05477142333984375,
        0.045775700360536575,
        0.05451377481222153,
        -0.0431993193924427,
        -0.0378580279648304,
        0.035379476845264435,
        0.04686286300420761,
        -0.03304839879274368,
        -0.054295461624860764,
        -0.054723504930734634,
        0.01062578335404396,
        -0.006091895047575235,
        -0.051518503576517105,
        0.03987179324030876,
        0.024008577689528465,
        0.04357513412833214,
        0.01158141065388918,
        -0.005882913246750832,
        0.05256689712405205,
        0.054706424474716187,
        0.03615998476743698,
        -0.018836967647075653,
        0.030281884595751762,
        0.01940985396504402,
        0.051767632365226746,
        -0.04144415259361267,
        0.049653444439172745,
        -0.02730449289083481,
        0.05322953313589096,
        -0.03639926388859749,
        0.03766960650682449,
        -0.054214514791965485,
        -0.026832155883312225,
        -0.04148102551698685,
        -0.05260142683982849,
        0.054540324956178665,
        -0.05047983303666115,
        -0.052694860845804214,
        0.05370597913861275,
        -0.009783417917788029,
        -0.05368372052907944,
        0.002872197423130274,
        0.050784360617399216,
        0.051945142447948456,
        0.05401027575135231,
        0.05376323312520981,
        -0.04563109949231148,
        -0.053931716829538345,
        -0.017749188467860222,
        0.003968961536884308,
        0.043459806591272354,
        -0.05077098309993744,
        0.05148889124393463,
        0.05054335296154022,
        -0.012412731535732746,
        -0.05373845621943474,
        0.03893710672855377,
        0.054112762212753296,
        0.04890693351626396,
        0.042110808193683624,
        -0.038216520100831985,
        0.05247627943754196,
        0.034637611359357834,
        0.05495521053671837,
        0.05434036999940872,
        -0.054828934371471405,
        0.05279433727264404,
        -0.04913291335105896,
        -0.014188685454428196,
        0.04947657138109207,
        0.044057685881853104,
        -0.05477774515748024,
        -0.054167356342077255,
        0.054933805018663406,
        -0.04918094351887703,
        0.05495811998844147,
        -0.04971873760223389,
        0.03518963232636452,
        0.036353982985019684,
        0.02050241269171238,
        -0.01834447868168354,
        0.05495736002922058,
        -0.0468619205057621,
        0.04347090423107147,
        -0.04873594641685486,
        0.040689703077077866,
        -0.05475545674562454,
        -0.05393535643815994,
        -0.052514173090457916,
        0.028067616745829582,
        0.054540157318115234,
        -0.054763395339250565,
        0.05483873933553696,
        0.054042208939790726,
        0.03285631909966469,
        -0.014280576258897781,
        0.054363202303647995,
        -0.04940470680594444,
        0.048632364720106125,
        -0.05291500687599182,
        -0.04681718721985817,
        -0.019410664215683937,
        -0.023502415046095848,
        0.04953702539205551,
        0.05478901416063309,
        -0.03717977926135063,
        0.028217580169439316,
        0.006021725479513407,
        -0.05466964468359947,
        -0.01915789395570755,
        -0.05436968803405762,
        0.04909265786409378,
        0.03707164525985718,
        -0.04710451140999794,
        0.04434642195701599,
        0.05093276873230934,
        0.04642270505428314,
        -0.05488579347729683,
        -0.042382996529340744,
        0.01618567481637001,
        0.054519135504961014,
        -0.03850027546286583,
        0.05487506091594696,
        0.05495043471455574,
        0.03273396193981171,
        -0.024067694321274757,
        -0.01582803949713707,
        0.053990937769412994,
        -0.05491198971867561,
        0.05430711805820465,
        -0.05437431111931801,
        -0.04419964179396629,
        0.011200709268450737,
        -0.0536528006196022,
        -0.046762507408857346,
        0.04918668046593666,
        0.04069168493151665,
        -0.05312016233801842,
        0.04429798573255539,
        0.0012561536859720945,
        -0.0522395595908165,
        -0.026310604065656662,
        -0.016380202025175095,
        0.008004028350114822,
        0.023793188855051994,
        0.02659555710852146,
        -0.0033332149032503366,
        -0.04540770500898361,
        -0.032199177891016006,
        0.00328706088475883,
        0.028462674468755722,
        0.05115096643567085,
        -0.054716046899557114,
        0.054927561432123184,
        0.024326104670763016,
        0.023751813918352127,
        0.05417665094137192,
        -0.010312221013009548,
        -0.03862644359469414,
        -0.045990899205207825,
        0.05014706775546074,
        -0.031385697424411774,
        0.03533899039030075,
        -0.054330021142959595,
        0.05493277311325073,
        0.010617134161293507,
        -0.054539237171411514,
        0.05254312604665756,
        -0.03238330036401749,
        0.03533816710114479,
        0.05492822080850601,
        0.006667613983154297,
        -0.05485597997903824,
        -0.00815135333687067,
        0.048404887318611145,
        0.05311550199985504,
        -0.04704391211271286,
        0.04875917732715607,
        -0.05191950500011444,
        0.03813713788986206,
        -0.04963799938559532,
        0.05494097247719765,
        -0.05405650660395622,
        0.02341591939330101,
        0.02604239620268345,
        -0.03922523930668831,
        -0.05472651496529579,
        0.054034776985645294,
        -0.054128214716911316,
        -0.004032083787024021,
        -0.041761308908462524,
        0.05435847491025925,
        0.04029606655240059,
        -0.04863043501973152,
        0.051923446357250214,
        -0.025156592950224876,
        -0.018545672297477722,
        -0.05255671218037605,
        -0.043596554547548294,
        0.05479147285223007,
        -0.03472602739930153,
        0.054971739649772644,
        0.009401567280292511,
        0.03543020039796829,
        0.05494462698698044,
        -0.053015224635601044,
        0.053752657026052475,
        -0.00037834348040632904,
        0.05258537828922272,
        0.03688334673643112,
        -0.05324987694621086,
        -0.025417178869247437,
        0.04759833216667175,
        -0.054509177803993225,
        -0.0005412260070443153,
        -0.0534016378223896,
        -0.05068790167570114,
        0.05158040672540665,
        0.04729665443301201,
        -0.04542567953467369,
        0.008076643571257591,
        -0.028119724243879318,
        -0.054936058819293976,
        0.054891832172870636,
        0.014436479657888412,
        -0.05417145416140556,
        -0.05161882936954498,
        -0.04577697813510895,
        -0.02708180993795395,
        -0.011152662336826324,
        -0.03250262141227722,
        0.05398710444569588,
        0.05494515970349312,
        -0.05397340655326843,
        -0.00023952010087668896,
        0.054498277604579926,
        -0.02293434552848339,
        -0.05004851892590523,
        0.03442738205194473,
        -0.05486335977911949,
        0.05063142254948616,
        0.05454513058066368,
        -0.04783676937222481,
        -0.018444932997226715,
        -0.052924495190382004,
        -0.052240416407585144,
        0.04242220148444176,
        -0.04771953821182251,
        -0.05462424084544182,
        -0.053774818778038025,
        -0.053364500403404236,
        0.05384567752480507,
        0.053680989891290665,
        0.054018955677747726,
        -0.0037780858110636473,
        -0.05477606877684593,
        -0.054834265261888504,
        0.03114907443523407,
        0.05341154709458351,
        0.03112558275461197,
        0.04645564407110214,
        -0.05493195727467537,
        -0.021518396213650703,
        0.023403629660606384,
        -0.025939704850316048,
        0.0548657551407814,
        -0.04703999683260918,
        0.05180325359106064,
        0.05175734683871269,
        0.0361052043735981,
        -0.044979266822338104,
        0.05473191291093826,
        -0.028292393311858177,
        0.03672943264245987,
        -0.05205483362078667,
        0.053174953907728195,
        0.046314891427755356,
        0.05468158423900604,
        -0.045227836817502975,
        0.012367895804345608,
        -0.05459419637918472,
        -0.04113262519240379,
        -0.05484134703874588,
        -0.013744083233177662,
        -0.02941720187664032,
        -0.051152464002370834,
        -0.053773026913404465,
        0.020982110872864723,
        -0.046285711228847504,
        -0.05429520457983017,
        -0.04241146892309189,
        -0.054964832961559296,
        -0.05373667925596237,
        -0.053987063467502594,
        -0.045778729021549225,
        -0.054517704993486404,
        -0.05328259617090225,
        0.012661732733249664,
        0.052432429045438766,
        -0.010195793583989143,
        0.053539764136075974,
        0.03817092254757881,
        -0.022460447624325752,
        0.054786860942840576,
        -0.05458385497331619,
        0.004843981936573982,
        0.005706673488020897,
        0.04203305020928383,
        0.05154566094279289,
        0.05356571450829506,
        0.052170202136039734,
        0.032243967056274414,
        -0.05220271646976471,
        -0.053132325410842896,
        -0.04285800829529762,
        0.03346535190939903,
        0.019264020025730133,
        0.05387681722640991,
        -0.02881712280213833,
        -0.05496656149625778,
        0.050636306405067444,
        0.05339513346552849,
        0.054870836436748505,
        -0.053707268089056015,
        0.05345195531845093,
        0.05486418679356575,
        -0.05424890294671059,
        0.04244846850633621,
        0.030939921736717224,
        0.05325132608413696,
        -0.02879706397652626,
        0.05150693655014038,
        -0.04001555219292641,
        0.044823821634054184,
        0.03433490917086601,
        -0.05472291260957718,
        -0.051053237169981,
        -0.047429315745830536,
        -0.048287779092788696,
        -0.03382717818021774,
        0.0250648632645607,
        -0.035667091608047485,
        0.05052226781845093,
        0.033400632441043854,
        0.04498676583170891,
        -0.05428862199187279,
        0.035275280475616455,
        -0.029746053740382195,
        -0.05446235090494156,
        0.03733820840716362,
        -0.05174142122268677,
        0.03551564738154411,
        -0.0006618552724830806,
        -0.03269370645284653,
        0.007328830659389496,
        0.05334649980068207,
        0.006496279500424862,
        -0.03478863835334778,
        -0.016269108280539513,
        -0.05143798887729645,
        -0.04740019142627716,
        0.018680715933442116,
        0.05054839700460434,
        0.04017728939652443,
        -0.035998113453388214,
        0.03748882934451103,
        -0.053669076412916183,
        0.05379464849829674,
        -0.05336940288543701
    ],
    [
        -0.005290469620376825,
        -0.055030710995197296,
        -0.02616247907280922,
        0.054939378052949905,
        -0.03010362945497036,
        -0.053452059626579285,
        0.04101254418492317,
        -0.05653146281838417,
        0.05547222122550011,
        -0.05125834792852402,
        0.045455314218997955,
        0.05768371745944023,
        -0.05706537514925003,
        0.031121814623475075,
        -0.04577464237809181,
        -0.011075684800744057,
        0.003916723653674126,
        0.05674802139401436,
        -0.05389079824090004,
        0.0016886345110833645,
        0.0007738680578768253,
        0.04491894692182541,
        0.057398270815610886,
        -0.03820870816707611,
        0.04087996855378151,
        0.05726207420229912,
        0.018504103645682335,
        0.045419882982969284,
        -0.029156358912587166,
        -0.05400720611214638,
        -0.057675112038850784,
        0.05424095690250397,
        0.04997815191745758,
        -0.05595937371253967,
        0.03337495028972626,
        -0.057427894324064255,
        -0.05492807552218437,
        0.036027196794748306,
        0.04840325191617012,
        -0.04036441817879677,
        0.0560181625187397,
        0.009258137084543705,
        0.05415293946862221,
        -0.05011218413710594,
        0.04458720237016678,
        0.054756056517362595,
        0.04509395360946655,
        0.05764741823077202,
        0.05288802832365036,
        0.023328114300966263,
        0.026844291016459465,
        0.055415209382772446,
        0.008457358926534653,
        -0.05640074610710144,
        0.001667340169660747,
        0.045272309333086014,
        -0.05715729668736458,
        -0.011133984662592411,
        0.01710810512304306,
        -0.0553409606218338,
        0.030943116173148155,
        0.0018962628673762083,
        -0.056451935321092606,
        0.040803082287311554,
        -0.010957309044897556,
        -0.016971491277217865,
        -0.05700292810797691,
        -0.023365512490272522,
        0.05203356593847275,
        -0.04064318537712097,
        0.041534341871738434,
        -0.01610734313726425,
        0.05764071270823479,
        0.05690732225775719,
        0.05655776709318161,
        -0.008143962360918522,
        0.05759264528751373,
        0.05771684646606445,
        0.05675594136118889,
        -0.05176820978522301,
        0.04925050958991051,
        -0.05193781480193138,
        -0.05758693069219589,
        0.04570088908076286,
        0.014673212543129921,
        0.01980801671743393,
        -0.009150310419499874,
        0.055381033569574356,
        0.0329008549451828,
        0.0570787712931633,
        -0.016857396811246872,
        0.02822190895676613,
        -0.052429161965847015,
        -0.02974751591682434,
        -0.04870668053627014,
        0.03175735846161842,
        0.04633984714746475,
        -0.03674786165356636,
        -0.006957473233342171,
        0.031729716807603836,
        -0.05736876651644707,
        0.05489930883049965,
        0.056050948798656464,
        -0.003215022385120392,
        0.008475659415125847,
        -0.018989678472280502,
        -0.05232525244355202,
        0.04769621416926384,
        -0.05528881773352623,
        -0.017016254365444183,
        -0.05767363682389259,
        0.01241349894553423,
        0.03660111129283905,
        -0.03220134973526001,
        0.057594090700149536,
        -0.01854139380156994,
        0.023776859045028687,
        0.05535782873630524,
        -0.05153940990567207,
        -0.056101538240909576,
        0.028068438172340393,
        -0.05770280957221985,
        0.04505074396729469,
        -0.05755504220724106,
        -0.03244112432003021,
        -0.057021137326955795,
        -0.04320993274450302,
        0.012308220379054546,
        0.05079563707113266,
        0.05450137332081795,
        0.05741701275110245,
        -0.0561077855527401,
        -0.045447953045368195,
        0.05524444952607155,
        -0.03460798040032387,
        -0.05117228627204895,
        0.05744538828730583,
        0.05727570131421089,
        -0.048154786229133606,
        0.03584655746817589,
        0.030631350353360176,
        -0.0343562476336956,
        0.05443788319826126,
        0.0577021948993206,
        -0.027225805446505547,
        0.05629424378275871,
        0.056022994220256805,
        -0.04458543285727501,
        0.051732469350099564,
        0.024658789858222008,
        -0.05622214823961258,
        -0.05625925585627556,
        0.01473084744066,
        0.04621848091483116,
        -0.008430663496255875,
        -0.040177058428525925,
        -0.02410431206226349,
        -0.050650667399168015,
        0.021420864388346672,
        -0.056072913110256195,
        0.041502367705106735,
        -0.0403272844851017,
        0.053962402045726776,
        0.023495173081755638,
        -0.04299270361661911,
        0.023604853078722954,
        0.05481373146176338,
        -0.04303734377026558,
        0.04129777103662491,
        0.03883129358291626,
        0.0323721282184124,
        -0.028726710006594658,
        -0.057411566376686096,
        0.044773150235414505,
        -0.05655353143811226,
        -0.0533122792840004,
        0.034271299839019775,
        -0.03301523998379707,
        0.04267789423465729,
        0.05665222182869911,
        -0.057035304605960846,
        0.057633474469184875,
        0.013975873589515686,
        -0.04390333965420723,
        0.00981017667800188,
        -0.04788924753665924,
        -0.03157482296228409,
        0.019271986559033394,
        0.05586306378245354,
        -0.0011724154464900494,
        -0.057023920118808746,
        0.02455737255513668,
        -0.016713542863726616,
        -0.04986698925495148,
        -0.05184720456600189,
        -0.027586599811911583,
        0.05704332888126373,
        0.05564751848578453,
        -0.04613575339317322,
        0.03600909188389778,
        0.04852588474750519,
        0.04976534843444824,
        -0.05678902938961983,
        0.02267521619796753,
        -0.047213371843099594,
        -0.05113079026341438,
        0.03520829603075981,
        0.04011763259768486,
        0.015291552990674973,
        -0.02920401282608509,
        -0.05455538630485535,
        0.04530967399477959,
        0.03866569697856903,
        0.013661378994584084,
        -0.053076863288879395,
        -0.01832425594329834,
        -0.017569122835993767,
        0.055709101259708405,
        0.04093560948967934,
        -0.0422421470284462,
        -0.05674589052796364,
        0.04716184362769127,
        -0.0062808687798678875,
        -0.057460326701402664,
        0.0390670970082283,
        -0.05768914893269539,
        0.021214250475168228,
        0.04895398020744324,
        -0.04072559252381325,
        -0.057196009904146194,
        0.04229271411895752,
        0.019045669585466385,
        -0.047513578087091446,
        0.05359475314617157,
        -0.012411954812705517,
        0.05510227754712105,
        0.05314479395747185,
        -0.0252340380102396,
        0.056869931519031525,
        0.05771520733833313,
        0.052583806216716766,
        0.05587729066610336,
        -0.044772688299417496,
        0.021480143070220947,
        0.05694440379738808,
        0.04348626360297203,
        0.04899858683347702,
        0.011056695133447647,
        0.05562962591648102,
        0.013785157352685928,
        -0.0343342088162899,
        -0.05382007360458374,
        -0.04569235071539879,
        -0.05751499906182289,
        -0.03341817855834961,
        0.013819883577525616,
        0.05502168461680412,
        -0.04788510873913765,
        0.05673413723707199,
        0.057536568492650986,
        -0.024103684350848198,
        -0.045157842338085175,
        -0.05658640339970589,
        0.03733726963400841,
        -0.05761696770787239,
        0.05768648162484169,
        0.05570836365222931,
        -0.04462505131959915,
        -0.04293171688914299,
        0.019901089370250702,
        0.04747588187456131,
        -0.05668644607067108,
        0.04943116754293442,
        -0.04310469329357147,
        -0.054570652544498444,
        0.02776867337524891,
        -0.05097309499979019,
        -0.029270444065332413,
        -0.05751886963844299,
        0.03679502010345459,
        -0.05483131483197212,
        0.057695336639881134,
        -0.03201169893145561,
        0.03580305352807045,
        0.05769629403948784,
        0.05681042745709419,
        0.00014231217210181057,
        0.007900639437139034,
        0.05694277212023735,
        -0.03779074549674988,
        -0.02967686764895916,
        0.05033133178949356,
        0.032033052295446396,
        0.015980184078216553,
        0.021551256999373436,
        -0.05722147226333618,
        0.05771203339099884,
        -0.00800189096480608,
        -0.05759759992361069,
        0.0568949319422245,
        0.05677616596221924,
        0.02440188080072403,
        -0.054537318646907806,
        0.049366142600774765,
        0.025068780407309532,
        0.05421898141503334,
        0.029237445443868637,
        0.04710278660058975,
        -0.037272751331329346,
        -0.027553385123610497,
        0.03309142589569092,
        0.013630696572363377,
        0.05688713863492012,
        0.051081497222185135,
        -0.054918061941862106,
        -0.05750705301761627,
        -0.0569349080324173,
        -0.030438385903835297,
        0.05416160076856613,
        -0.03224482387304306,
        0.05614345148205757,
        -0.05423957109451294,
        0.05031272768974304,
        -0.049152519553899765,
        0.0386197529733181,
        0.04984063655138016,
        0.057171668857336044,
        -0.05725090205669403,
        0.011657542549073696,
        -0.05763902887701988,
        0.05719605088233948,
        -0.02210189960896969,
        0.03164154291152954,
        0.028042536228895187,
        0.05458999425172806,
        0.0551464706659317,
        -0.03851373866200447,
        0.0576200969517231,
        -0.044250451028347015,
        -0.0000878572536748834,
        -0.05651048198342323,
        0.056221283972263336,
        0.05734953284263611,
        -0.04038945212960243,
        0.05648311600089073,
        0.023562578484416008,
        0.05759704113006592,
        0.054188232868909836,
        -0.023424901068210602,
        0.05723939463496208,
        0.050982531160116196,
        -0.005371822044253349,
        0.045744940638542175,
        -0.02616322971880436,
        -0.011781546287238598,
        0.027977632358670235,
        -0.057533446699380875,
        -0.053055524826049805,
        0.052562616765499115,
        -0.05386100336909294,
        0.05479039251804352,
        0.054925695061683655,
        0.023012876510620117,
        -0.009455645456910133,
        0.050085365772247314,
        -0.04726162925362587,
        0.057157251983881,
        -0.05134480446577072,
        0.00023581086134072393,
        -0.01188568864017725,
        0.028858087956905365,
        0.003232668386772275,
        0.0573807954788208,
        -0.03882135450839996,
        0.045726675540208817,
        0.05158192291855812,
        -0.013818234205245972,
        -0.03827095404267311,
        0.05760857090353966,
        0.01737304963171482,
        0.03382234647870064,
        0.052054282277822495,
        -0.05434485897421837,
        0.02494588866829872,
        0.002801658818498254,
        -0.05193405598402023,
        -0.05173778906464577,
        0.038350287824869156,
        -0.024396376684308052,
        -0.05166442319750786,
        -0.04872585088014603,
        0.051808036863803864,
        -0.044017862528562546,
        0.042568791657686234,
        0.05217980965971947,
        0.056802354753017426,
        0.05612318590283394,
        -0.034971851855516434,
        -0.014965198934078217,
        -0.05708709731698036,
        -0.03041592426598072,
        0.057207778096199036,
        -0.05420684814453125,
        0.053890202194452286,
        -0.04322352260351181,
        -0.024603208526968956,
        0.050584957003593445,
        -0.0171651728451252,
        0.05758805572986603,
        -0.005524346139281988,
        -0.03651145100593567,
        -0.040110159665346146,
        0.0566808246076107,
        -0.008754651993513107,
        -0.02394164726138115,
        -0.05699821561574936,
        -0.03857879340648651,
        -0.04900975525379181,
        0.05770787596702576,
        0.05591614916920662,
        0.05185876041650772,
        -0.042202841490507126,
        -0.007158887572586536,
        -0.055516622960567474,
        -0.020801668986678123,
        -0.0310145765542984,
        -0.02363644912838936,
        -0.034459736198186874,
        -0.02051592618227005,
        -0.053523141890764236,
        0.04738026484847069,
        0.04353222995996475,
        0.01860128529369831,
        -0.04216957837343216,
        -0.05668371915817261,
        -0.053755830973386765,
        -0.057684265077114105,
        0.05621575564146042,
        -0.05723678693175316,
        -0.049613408744335175,
        0.010206694714725018,
        0.05672997236251831,
        0.049754563719034195,
        0.05720436945557594,
        -0.05007051303982735,
        0.006346100941300392,
        0.02661564201116562,
        -0.05557424947619438,
        0.03360138460993767,
        0.051693666726350784,
        -0.007690317463129759,
        -0.008364352397620678,
        0.017830295488238335,
        -0.022806737571954727,
        -0.05512215942144394,
        -0.05431358516216278,
        0.008375394158065319,
        -0.01164893340319395,
        0.03608671948313713,
        0.04267656058073044,
        0.029566597193479538,
        -0.05768948793411255,
        0.05289313942193985,
        0.00653186347335577,
        0.047626856714487076,
        0.05747375637292862,
        -0.05171434208750725,
        0.05702037736773491,
        -0.05761268734931946,
        -0.05717705562710762,
        -0.018480602651834488,
        -0.051628466695547104,
        -0.030555879697203636,
        0.0569581538438797,
        0.05739237368106842,
        -0.02434248849749565,
        0.04717002063989639,
        -0.051601335406303406,
        -0.04668489843606949,
        -0.053383924067020416,
        -0.057007867842912674,
        0.011013971641659737,
        -0.05513449013233185,
        0.03615878149867058,
        -0.010649992153048515,
        0.05434860661625862,
        0.03705088421702385,
        0.031125131994485855,
        -0.05590933933854103,
        0.05708892643451691,
        0.04597415775060654,
        -0.042920250445604324,
        0.05766589939594269,
        -0.03152947872877121,
        0.05515385419130325,
        0.03575138375163078,
        -0.05467648059129715,
        -0.021150264889001846,
        0.050295520573854446,
        -0.025178369134664536,
        -0.0435776486992836,
        0.04275030270218849,
        -0.05760593339800835,
        -0.03437966853380203,
        0.04609351232647896,
        -0.021830763667821884,
        0.03737334534525871,
        0.007012144662439823,
        0.05730251222848892,
        -0.05549761280417442,
        0.05656014010310173,
        0.05150767043232918
    ],
    [
        -0.06098310276865959,
        -0.055781036615371704,
        0.036984313279390335,
        0.021340301260352135,
        -0.011816781014204025,
        -0.03442946448922157,
        0.06144900247454643,
        -0.00707654096186161,
        0.00520008709281683,
        0.05304984003305435,
        0.05577123910188675,
        -0.03268755227327347,
        -0.04828622564673424,
        0.005863721016794443,
        -0.06165125593543053,
        0.05168160796165466,
        -0.050820812582969666,
        0.053536225110292435,
        -0.0622415617108345,
        -0.025948937982320786,
        -0.06124655157327652,
        0.038215141743421555,
        0.06032570078969002,
        -0.02062186785042286,
        -0.033545415848493576,
        -0.014736104756593704,
        0.02895585633814335,
        0.04270247742533684,
        0.03160511329770088,
        -0.016510479152202606,
        0.0024715426843613386,
        -0.011264282278716564,
        -0.046075429767370224,
        -0.06240784376859665,
        0.011241012252867222,
        -0.05362493917346001,
        -0.05540309101343155,
        -0.05891533941030502,
        -0.062417760491371155,
        0.0048569850623607635,
        0.044719498604536057,
        0.006190604530274868,
        0.05674983188509941,
        0.022135647013783455,
        0.050449810922145844,
        -0.051717597991228104,
        -0.005037226714193821,
        0.06252104789018631,
        0.06282395124435425,
        0.04607958346605301,
        0.051236528903245926,
        0.017945216968655586,
        0.06212534010410309,
        -0.01256110705435276,
        -0.049540694802999496,
        -0.04651794955134392,
        -0.01075858436524868,
        0.018640758469700813,
        0.01602555252611637,
        0.04642429202795029,
        -0.06054891645908356,
        -0.02428014762699604,
        -0.05121355503797531,
        0.008552664890885353,
        0.05454515665769577,
        -0.031523920595645905,
        0.06044013798236847,
        -0.05696316808462143,
        0.01898067817091942,
        -0.0629647895693779,
        -0.03474539890885353,
        -0.06265454739332199,
        0.02284516580402851,
        -0.00554366409778595,
        0.025953881442546844,
        -0.03125579655170441,
        -0.0029436168260872364,
        0.061695199459791183,
        0.022595105692744255,
        -0.019709771499037743,
        -0.01943236030638218,
        0.045954957604408264,
        -0.05979068577289581,
        0.05124649032950401,
        -0.061590202152729034,
        0.044584520161151886,
        -0.05573955178260803,
        0.04001680016517639,
        0.05045872554183006,
        0.062499210238456726,
        -0.06094929203391075,
        0.05957283452153206,
        0.024061579257249832,
        0.013529972173273563,
        -0.0032883707899600267,
        -0.05336993932723999,
        -0.054228924214839935,
        -0.03044838085770607,
        -0.044744737446308136,
        -0.016472026705741882,
        -0.0623270720243454,
        -0.026549749076366425,
        0.0537465400993824,
        -0.0229265708476305,
        0.0014178748242557049,
        -0.041078854352235794,
        0.024085067212581635,
        0.021540533751249313,
        -0.03444453328847885,
        -0.02611852064728737,
        -0.015367132611572742,
        -0.05836785212159157,
        0.013926680199801922,
        0.02820165641605854,
        0.011842269450426102,
        0.012389604933559895,
        -0.035037558525800705,
        -0.054675690829753876,
        -0.05724666267633438,
        -0.04325074329972267,
        -0.008319012820720673,
        0.027515005320310593,
        0.058831918984651566,
        0.007074544206261635,
        -0.060659199953079224,
        -0.05407783016562462,
        0.011795715428888798,
        0.059035833925008774,
        0.06191366910934448,
        -0.030822262167930603,
        0.056762613356113434,
        -0.031976230442523956,
        0.053313206881284714,
        0.041242796927690506,
        -0.056398674845695496,
        -0.030872488394379616,
        0.05958031117916107,
        -0.059800807386636734,
        -0.031822316348552704,
        0.0524037666618824,
        -0.051020506769418716,
        -0.04107954353094101,
        0.03346768021583557,
        0.030112549662590027,
        -0.05104992538690567,
        0.043982475996017456,
        0.05550478398799896,
        0.058515679091215134,
        -0.04736672714352608,
        -0.008909014984965324,
        -0.042298004031181335,
        -0.058420579880476,
        -0.0176759734749794,
        -0.0012625742238014936,
        0.05979021638631821,
        -0.05413931608200073,
        -0.016060521826148033,
        -0.02290554717183113,
        0.04237066209316254,
        0.041681066155433655,
        0.06167652830481529,
        0.004887389484792948,
        0.03404910862445831,
        -0.061661455780267715,
        -0.009871670976281166,
        -0.03724507987499237,
        -0.014986416324973106,
        -0.0622054822742939,
        -0.033011164516210556,
        0.03459903597831726,
        0.05257496237754822,
        -0.01585942506790161,
        -0.061418477445840836,
        0.04433797672390938,
        -0.05716048181056976,
        0.061855167150497437,
        -0.05923581123352051,
        -0.017057741060853004,
        0.03285586088895798,
        0.04882394149899483,
        0.05096996948122978,
        0.05697661265730858,
        0.05528132617473602,
        -0.051915157586336136,
        -0.02443171665072441,
        -0.04523024708032608,
        0.038861021399497986,
        0.05314674600958824,
        -0.045520979911088943,
        -0.059032272547483444,
        -0.04142846167087555,
        0.007394103333353996,
        -0.04919785261154175,
        0.046803247183561325,
        0.004638406448066235,
        -0.04780466482043266,
        0.06096523627638817,
        0.052300360053777695,
        0.028028739616274834,
        -0.0054325214587152,
        0.040868110954761505,
        0.05822456628084183,
        -0.022907806560397148,
        -0.020094173029065132,
        0.050233229994773865,
        -0.03223007544875145,
        0.05460674688220024,
        -0.04627238214015961,
        0.0038294640835374594,
        0.05295077711343765,
        -0.05495486781001091,
        0.05555761978030205,
        -0.061092559248209,
        -0.021388832479715347,
        0.05558489263057709,
        -0.010153556242585182,
        -0.05702619254589081,
        0.06076858192682266,
        0.06229894235730171,
        0.047193512320518494,
        0.06044522672891617,
        -0.019676417112350464,
        -0.04543126001954079,
        -0.06296906620264053,
        0.06012437865138054,
        -0.06229379028081894,
        -0.032889507710933685,
        -0.0022957241162657738,
        0.012218027375638485,
        -0.04173325374722481,
        -0.002090120455250144,
        -0.03127665817737579,
        -0.020497148856520653,
        0.058168280869722366,
        -0.011369013227522373,
        0.0420137420296669,
        0.055631544440984726,
        0.03696536272764206,
        0.04315542057156563,
        0.061385296285152435,
        0.020942164584994316,
        -0.037031665444374084,
        0.03787699341773987,
        0.03834579885005951,
        0.006875454448163509,
        -0.019200274720788002,
        0.018445391207933426,
        0.04687706008553505,
        -0.03598424792289734,
        0.03453528508543968,
        -0.04324379190802574,
        -0.058497704565525055,
        -0.051490090787410736,
        -0.052898768335580826,
        0.06041396036744118,
        0.05415305495262146,
        0.05529497191309929,
        -0.05283645540475845,
        0.05828287824988365,
        0.06303197145462036,
        -0.0013530212454497814,
        0.047785378992557526,
        -0.04451388493180275,
        0.037494976073503494,
        -0.06252928823232651,
        0.04355039820075035,
        0.05255841463804245,
        -0.05529071390628815,
        -0.03700084611773491,
        -0.0544341579079628,
        -0.016012271866202354,
        -0.056957945227622986,
        -0.036990948021411896,
        -0.059948839247226715,
        -0.05349786579608917,
        0.03764965012669563,
        -0.05769958347082138,
        0.04096570238471031,
        -0.059428296983242035,
        -0.04900852218270302,
        -0.06287440657615662,
        0.052496325224637985,
        0.058526549488306046,
        0.030626684427261353,
        -0.002632617484778166,
        -0.0617336742579937,
        0.029693666845560074,
        -0.018340960144996643,
        -0.04132036119699478,
        0.048258040100336075,
        -0.027263609692454338,
        0.050570350140333176,
        -0.01911955513060093,
        -0.030172059312462807,
        0.007590331137180328,
        -0.03522535040974617,
        0.06286152452230453,
        0.0499199815094471,
        -0.059654951095581055,
        0.0588967502117157,
        -0.059460148215293884,
        0.0526423454284668,
        -0.006445880513638258,
        -0.00986466184258461,
        -0.05913519859313965,
        -0.059202905744314194,
        -0.002690218621864915,
        0.009664862416684628,
        0.06021866574883461,
        0.016099750995635986,
        0.02556043490767479,
        -0.029030175879597664,
        -0.03795233368873596,
        -0.02322309836745262,
        -0.06284945458173752,
        -0.053495507687330246,
        0.047743890434503555,
        -0.0487472228705883,
        0.04434425011277199,
        -0.06259706616401672,
        0.035671476274728775,
        0.05581093952059746,
        0.02662140317261219,
        -0.057740893214941025,
        0.04634416848421097,
        0.019271837547421455,
        0.052687328308820724,
        -0.06136828660964966,
        0.011607902124524117,
        0.0001623633288545534,
        -0.02169235423207283,
        0.03790757805109024,
        0.015442751348018646,
        -0.04937678948044777,
        0.06174338981509209,
        -0.010049060918390751,
        -0.05906270444393158,
        0.059237945824861526,
        -0.036798443645238876,
        0.05828975513577461,
        0.03518817573785782,
        -0.012100806459784508,
        0.05710260197520256,
        -0.008208132348954678,
        0.06299416720867157,
        0.061232954263687134,
        0.034459393471479416,
        0.05598681420087814,
        -0.06166142597794533,
        0.05831116437911987,
        -0.0630512461066246,
        -0.05970828980207443,
        -0.051248420029878616,
        -0.04210514947772026,
        0.010796253569424152,
        0.06081090122461319,
        -0.059771981090307236,
        -0.049263302236795425,
        -0.008501834236085415,
        -0.059388916939496994,
        0.05203179642558098,
        0.03410198539495468,
        -0.06004519760608673,
        -0.02792808786034584,
        -0.019943861290812492,
        -0.05013030394911766,
        0.062444087117910385,
        -0.050834715366363525,
        0.018272269517183304,
        -0.06272046267986298,
        -0.057224925607442856,
        -0.06177385896444321,
        0.057550251483917236,
        -0.05577973276376724,
        0.04272641986608505,
        0.058076102286577225,
        -0.03485307842493057,
        0.06100885942578316,
        -0.057970575988292694,
        -0.010224252007901669,
        0.00815699715167284,
        0.05680639296770096,
        -0.06243036687374115,
        -0.011925430037081242,
        0.057193465530872345,
        -0.06043457239866257,
        0.03146681934595108,
        -0.02531062625348568,
        -0.04989437013864517,
        -0.05380700156092644,
        -0.05746966227889061,
        -0.004034958779811859,
        -0.004272198770195246,
        -0.03301413729786873,
        -0.045162081718444824,
        0.0553169772028923,
        0.04489398002624512,
        0.001325239660218358,
        -0.04275050014257431,
        -0.06157258525490761,
        -0.05261259898543358,
        0.06047634780406952,
        -0.03552250936627388,
        0.05985163897275925,
        -0.057174962013959885,
        0.035790130496025085,
        0.03964114189147949,
        -0.015261796303093433,
        0.06178745999932289,
        -0.03215321525931358,
        0.017978567630052567,
        0.057268109172582626,
        0.019275959581136703,
        -0.029990827664732933,
        0.035889264196157455,
        0.019676972180604935,
        0.024383502081036568,
        0.005585221108049154,
        0.04329988360404968,
        -0.05983756110072136,
        0.032401569187641144,
        -0.04806465655565262,
        -0.028373684734106064,
        -0.05290966108441353,
        0.04614385962486267,
        -0.01386531163007021,
        -0.05177488923072815,
        -0.024231212213635445,
        -0.06264456361532211,
        -0.03572110831737518,
        -0.009696250781416893,
        0.039287813007831573,
        -0.01800743117928505,
        0.036624111235141754,
        0.03685963898897171,
        -0.031455423682928085,
        -0.06277681142091751,
        0.059658803045749664,
        -0.050934288650751114,
        -0.016819197684526443,
        -0.027806302532553673,
        0.016239676624536514,
        -0.011454302817583084,
        0.04666084423661232,
        0.02101949416100979,
        -0.043717190623283386,
        0.047126591205596924,
        0.03490966185927391,
        0.04991244152188301,
        -0.03726063668727875,
        0.04959876462817192,
        0.061546552926301956,
        0.062138400971889496,
        0.016872137784957886,
        -0.031729556620121,
        -0.0510638989508152,
        0.025606563314795494,
        0.0018742517568171024,
        -0.040369536727666855,
        0.05252208933234215,
        -0.03845367208123207,
        -0.04058729112148285,
        0.04645746201276779,
        -0.01623060554265976,
        0.05845619738101959,
        0.06266812235116959,
        -0.06264154613018036,
        0.06197558343410492,
        -0.058420367538928986,
        -0.06240870803594589,
        0.059792410582304,
        -0.0238239374011755,
        0.01892390474677086,
        0.009065056219696999,
        0.02998511679470539,
        0.05477315932512283,
        -0.008284347131848335,
        -0.03096107952296734,
        -0.05199861526489258,
        -0.03929470479488373,
        0.03459899127483368,
        0.028173208236694336,
        0.04754464700818062,
        0.03770999237895012,
        -0.0014366831164807081,
        0.055785275995731354,
        0.0567043237388134,
        -0.038475073873996735,
        -0.05324416607618332,
        0.046293869614601135,
        -0.036915458738803864,
        -0.062285855412483215,
        0.06204469874501228,
        0.01521831750869751,
        0.06219864636659622,
        0.0470137894153595,
        -0.061950720846652985,
        -0.013090874068439007,
        0.059217941015958786,
        -0.030139077454805374,
        0.06147991865873337,
        -0.05744369328022003,
        -0.05135909840464592,
        0.05228876695036888,
        -0.004984806291759014,
        0.0020252892281860113,
        0.05337825417518616,
        -0.042477868497371674,
        0.022410642355680466,
        0.00787353515625,
        0.06250723451375961,
        -0.04031725227832794
    ],
    [
        -0.045536234974861145,
        -0.004904413130134344,
        -0.018169095739722252,
        0.005459120962768793,
        -0.052124883979558945,
        0.055179525166749954,
        0.05015139281749725,
        -0.02121381089091301,
        0.02929619885981083,
        0.05247247964143753,
        0.05489613115787506,
        -0.037064459174871445,
        -0.054963622242212296,
        -0.049072422087192535,
        -0.0559762679040432,
        -0.007843811064958572,
        -0.03893484175205231,
        0.0559951588511467,
        0.04528708755970001,
        -0.055921025574207306,
        0.019748056307435036,
        0.04520302638411522,
        -0.03444502130150795,
        -0.028199175372719765,
        -0.04990696534514427,
        0.054582711309194565,
        -0.03990425914525986,
        0.05389776825904846,
        -0.05151787027716637,
        0.05465910583734512,
        0.004539507906883955,
        0.05544404685497284,
        -0.024221505969762802,
        -0.03660740703344345,
        0.0543927364051342,
        -0.013896533288061619,
        -0.051088955253362656,
        -0.01886921562254429,
        0.055571358650922775,
        -0.04403495416045189,
        0.039253149181604385,
        -0.042947735637426376,
        0.04736093059182167,
        -0.05419819429516792,
        0.03095684014260769,
        -0.05180589109659195,
        -0.030589530244469643,
        0.05595901608467102,
        0.05501102656126022,
        -0.04418129101395607,
        0.05552142113447189,
        0.043275851756334305,
        -0.03364264592528343,
        -0.04724421724677086,
        -0.049399975687265396,
        0.05074751377105713,
        0.0263195913285017,
        0.019183581694960594,
        -0.0538431853055954,
        -0.015413996763527393,
        -0.05457335337996483,
        -0.028992922976613045,
        -0.055514801293611526,
        0.05389843136072159,
        0.019759252667427063,
        -0.053150683641433716,
        -0.05584011226892471,
        -0.055110931396484375,
        0.014493200927972794,
        0.05437270551919937,
        -0.051898859441280365,
        -0.05582897365093231,
        0.051485028117895126,
        0.05154843255877495,
        0.024221669882535934,
        -0.05279766023159027,
        0.054380275309085846,
        0.05601033940911293,
        0.048883989453315735,
        0.008004747331142426,
        -0.05423963814973831,
        -0.036397069692611694,
        -0.03426056355237961,
        0.05416843667626381,
        -0.026690363883972168,
        0.03316576033830643,
        -0.055884476751089096,
        0.049081187695264816,
        -0.015819797292351723,
        0.05459645390510559,
        -0.05584379658102989,
        -0.055952247232198715,
        -0.05360497161746025,
        0.0023755738511681557,
        -0.04440655931830406,
        -0.013440106064081192,
        -0.04462478309869766,
        -0.056005049496889114,
        0.055120643228292465,
        0.05305888503789902,
        -0.05593852698802948,
        0.05392321199178696,
        0.054634008556604385,
        0.018295008689165115,
        0.05567854270339012,
        -0.032160911709070206,
        -0.05392192304134369,
        0.05320153012871742,
        -0.055769484490156174,
        0.03553442656993866,
        -0.05372327193617821,
        0.0011657155118882656,
        -0.05576682835817337,
        0.043786510825157166,
        -0.05554219335317612,
        -0.04308580234646797,
        0.02674972265958786,
        -0.05269189551472664,
        -0.051562707871198654,
        -0.0550847053527832,
        -0.005219067446887493,
        0.021590236574411392,
        0.01047523133456707,
        -0.00860779918730259,
        -0.024147896096110344,
        0.05214634910225868,
        -0.029804954305291176,
        -0.05599190294742584,
        0.048477835953235626,
        -0.038420360535383224,
        0.054037805646657944,
        -0.043423302471637726,
        0.010923405177891254,
        -0.007031640503555536,
        -0.04435740411281586,
        -0.05129876360297203,
        0.05595908686518669,
        -0.011258441023528576,
        -0.051527321338653564,
        0.05501656234264374,
        -0.05077069625258446,
        -0.055818650871515274,
        0.03813960403203964,
        -0.04015066474676132,
        0.05321294069290161,
        -0.05312270298600197,
        -0.019609946757555008,
        -0.03486156463623047,
        -0.04832277446985245,
        -0.03422996401786804,
        0.04213052615523338,
        -0.05502105876803398,
        0.05600244924426079,
        0.04990505799651146,
        -0.054492250084877014,
        -0.05419227480888367,
        -0.05375388637185097,
        0.040410250425338745,
        0.05583137646317482,
        -0.04483172297477722,
        0.05487575754523277,
        -0.04990930110216141,
        0.055808793753385544,
        0.05463124439120293,
        -0.05593298003077507,
        0.03581622987985611,
        -0.030394168570637703,
        0.05230794474482536,
        -0.022622594609856606,
        0.04627721756696701,
        0.029789410531520844,
        0.03952264413237572,
        -0.050604406744241714,
        0.008192221634089947,
        0.00955977663397789,
        -0.048212818801403046,
        0.055992837995290756,
        -0.025261063128709793,
        0.05386022850871086,
        -0.05521441251039505,
        -0.04950245842337608,
        0.05583667755126953,
        -0.05004042014479637,
        -0.03860239312052727,
        0.045078542083501816,
        -0.03774043917655945,
        0.05484381318092346,
        0.05552515387535095,
        -0.03962402418255806,
        -0.05504199489951134,
        -0.05063025653362274,
        0.019721923395991325,
        0.039348769932985306,
        -0.055295661091804504,
        -0.055911168456077576,
        0.04649282246828079,
        0.050528544932603836,
        -0.054529089480638504,
        -0.055736564099788666,
        0.02670617215335369,
        0.055659424513578415,
        -0.01536464225500822,
        0.0000366607891919557,
        -0.0558730810880661,
        0.025823509320616722,
        -0.033189255744218826,
        0.055202435702085495,
        0.02345631644129753,
        -0.03105524741113186,
        0.055163055658340454,
        -0.035905953496694565,
        0.048608630895614624,
        0.04180388152599335,
        0.055975671857595444,
        -0.042916588485240936,
        -0.05425097793340683,
        -0.012818044982850552,
        -0.055474184453487396,
        0.05222615599632263,
        0.014652417972683907,
        0.04979293793439865,
        0.040835775434970856,
        -0.03944534435868263,
        0.007919291034340858,
        0.05578327924013138,
        -0.04553195834159851,
        0.03916680067777634,
        -0.02751844748854637,
        0.04095334932208061,
        -0.030369549989700317,
        -0.027410093694925308,
        -0.04168520122766495,
        0.05263103172183037,
        0.0518362894654274,
        -0.048781219869852066,
        -0.05472761392593384,
        0.05508852005004883,
        0.033272553235292435,
        0.05407848209142685,
        0.056003399193286896,
        0.05150243639945984,
        -0.05494892969727516,
        -0.05336117744445801,
        -0.02618432603776455,
        0.048957210034132004,
        -0.04784400016069412,
        0.055830489844083786,
        0.04861856997013092,
        0.046822689473629,
        0.021706214174628258,
        0.03515014052391052,
        -0.055875349789857864,
        -0.047706231474876404,
        -0.055487845093011856,
        -0.05413660407066345,
        0.042112868279218674,
        0.03734908625483513,
        0.030199933797121048,
        0.05397023633122444,
        -0.017360659316182137,
        -0.05576536804437637,
        -0.026380205526947975,
        0.009530034847557545,
        -0.04809240996837616,
        0.019227823242545128,
        0.052533991634845734,
        0.05593711882829666,
        0.05399826541543007,
        -0.035970769822597504,
        -0.05329088866710663,
        0.055080220103263855,
        -0.049917399883270264,
        0.03253363072872162,
        -0.01838509738445282,
        0.019276538863778114,
        0.0467260368168354,
        -0.050616707652807236,
        -0.05524038150906563,
        0.02919379062950611,
        -0.05589509755373001,
        -0.0559619665145874,
        0.053731974214315414,
        -0.05023537576198578,
        0.02484479732811451,
        0.006907670293003321,
        -0.003651691833510995,
        0.055088844150304794,
        0.055139388889074326,
        0.040264494717121124,
        0.0551740825176239,
        -0.020863892510533333,
        0.039541568607091904,
        -0.05234468728303909,
        -0.017189819365739822,
        0.05352054536342621,
        -0.05091467499732971,
        0.055941391736269,
        0.012590233236551285,
        -0.05577625706791878,
        0.05521997809410095,
        0.031016886234283447,
        -0.04921814799308777,
        -0.022866716608405113,
        0.052635930478572845,
        -0.053247835487127304,
        -0.0445580817759037,
        0.0234625693410635,
        0.017227759584784508,
        -0.019789403304457664,
        0.055476196110248566,
        -0.04315970838069916,
        0.04238668829202652,
        -0.014676365070044994,
        -0.01945454627275467,
        -0.04064500331878662,
        -0.054187923669815063,
        -0.02811133861541748,
        0.041186992079019547,
        0.05336611717939377,
        0.03841064125299454,
        0.05589281767606735,
        -0.048460230231285095,
        -0.050388265401124954,
        0.055010467767715454,
        0.05488698184490204,
        0.04616876691579819,
        0.0428997203707695,
        -0.0492589995265007,
        0.021093470975756645,
        0.00036647511296905577,
        0.04280849173665047,
        0.011494442820549011,
        -0.05307890474796295,
        -0.037497278302907944,
        0.04584673419594765,
        0.02149411290884018,
        -0.03314319625496864,
        -0.03314021974802017,
        0.042096734046936035,
        0.037287238985300064,
        -0.027173196896910667,
        -0.028773441910743713,
        0.043866220861673355,
        0.02702648751437664,
        -0.0519995354115963,
        0.04182420298457146,
        -0.027625782415270805,
        0.05587324872612953,
        -0.05485450103878975,
        0.02834171988070011,
        0.051087502390146255,
        0.009117892012000084,
        0.015652595087885857,
        -0.055915798991918564,
        0.004552348516881466,
        -0.04230594262480736,
        0.04247056320309639,
        -0.05341165140271187,
        -0.008356986567378044,
        -0.03421247750520706,
        0.03424198552966118,
        -0.02704703062772751,
        0.044573310762643814,
        -0.05487096682190895,
        0.027630718424916267,
        -0.05490836501121521,
        0.05576600506901741,
        -0.054802075028419495,
        0.04742361605167389,
        -0.05233524367213249,
        -0.05534013360738754,
        -0.055472634732723236,
        0.02339181862771511,
        0.01959906704723835,
        0.048363007605075836,
        0.04786035791039467,
        -0.051840174943208694,
        0.045808013528585434,
        0.05514353886246681,
        0.01741974800825119,
        -0.00793011486530304,
        0.05494680255651474,
        -0.05567529797554016,
        0.03984125331044197,
        0.055674295872449875,
        -0.033645547926425934,
        0.00044985456042923033,
        -0.048231374472379684,
        -0.05469215661287308,
        0.04617714881896973,
        -0.05329246446490288,
        -0.04257440194487572,
        0.0029634744860231876,
        0.042635273188352585,
        0.05386000871658325,
        0.05130889639258385,
        -0.055087022483348846,
        -0.019971605390310287,
        0.045659251511096954,
        -0.05420250818133354,
        -0.01127838995307684,
        0.05575450137257576,
        -0.040344759821891785,
        -0.028193244710564613,
        0.019315553829073906,
        0.01319043803960085,
        0.024823930114507675,
        -0.05238230526447296,
        0.05359424650669098,
        -0.055681031197309494,
        0.055732473731040955,
        0.047344762831926346,
        0.054287318140268326,
        -0.020255599170923233,
        0.055490605533123016,
        -0.03000408411026001,
        0.03583402931690216,
        -0.05494993180036545,
        -0.009716012515127659,
        0.052379705011844635,
        0.04712943732738495,
        -0.04471677541732788,
        0.028763987123966217,
        -0.0559593103826046,
        -0.05587726831436157,
        -0.05009401589632034,
        -0.018402613699436188,
        -0.055938731878995895,
        -0.05415186285972595,
        -0.050637368112802505,
        0.025656510144472122,
        -0.039855245500802994,
        0.054166704416275024,
        0.004253232851624489,
        -0.05451478809118271,
        -0.055607233196496964,
        -0.048249345272779465,
        -0.03524872288107872,
        -0.04061625152826309,
        -0.03153683617711067,
        -0.05455043911933899,
        0.005867828149348497,
        0.025258131325244904,
        0.05478803440928459,
        -0.05416908115148544,
        0.0062218401581048965,
        0.05569307506084442,
        -0.004201545380055904,
        0.055781908333301544,
        0.05581822991371155,
        -0.035581279546022415,
        0.03349323570728302,
        0.04606889188289642,
        -0.009788528084754944,
        -0.033364225178956985,
        -0.05424926057457924,
        -0.014920760877430439,
        0.017473699524998665,
        0.0552724227309227,
        -0.04097039997577667,
        0.03645957261323929,
        -0.055962059646844864,
        0.05570147931575775,
        0.053485020995140076,
        0.05597441643476486,
        0.05600351840257645,
        0.029133889824151993,
        0.052556362003088,
        0.04451019689440727,
        -0.05326026678085327,
        -0.05032196268439293,
        0.006632416509091854,
        0.05525369942188263,
        0.029581541195511818,
        -0.05398814380168915,
        -0.02631436102092266,
        0.014011659659445286,
        0.03438501060009003,
        0.05193868279457092,
        -0.04307985305786133,
        0.03989556059241295,
        0.03475647792220116,
        0.028880514204502106,
        0.05167967453598976,
        0.05499871447682381,
        0.054539263248443604,
        0.03680586442351341,
        0.05506520718336105,
        -0.05555462837219238,
        -0.05560712143778801,
        0.045267801731824875,
        -0.05596092343330383,
        0.05471864715218544,
        -0.04531509056687355,
        -0.038492441177368164,
        0.05129261314868927,
        -0.05600114166736603,
        -0.04923608899116516,
        0.054867424070835114,
        -0.011450603604316711,
        -0.045968588441610336,
        0.04569511115550995,
        0.012705427594482899,
        0.04969256743788719,
        0.055753469467163086,
        0.0015389666659757495,
        0.055977094918489456,
        -0.047795552760362625,
        0.05594814568758011,
        -0.03853093460202217,
        0.05407682806253433,
        -0.0454648956656456
    ],
    [
        -0.05161372572183609,
        -0.05169590190052986,
        0.01974532939493656,
        0.005710763856768608,
        -0.05101967975497246,
        -0.05162910372018814,
        -0.010006867349147797,
        -0.05154191702604294,
        0.05046119540929794,
        0.033559467643499374,
        0.05150419846177101,
        0.050689175724983215,
        -0.05161907151341438,
        0.006465889513492584,
        -0.050934016704559326,
        0.05067247152328491,
        -0.02083040028810501,
        0.051292888820171356,
        -0.051566559821367264,
        0.05153407156467438,
        -0.04985876381397247,
        0.018885251134634018,
        0.05137307941913605,
        0.049679145216941833,
        -0.03779856860637665,
        0.031759366393089294,
        0.05157376825809479,
        0.04768570512533188,
        -0.051690276712179184,
        -0.015895280987024307,
        -0.03934371471405029,
        0.05168996378779411,
        0.04950885474681854,
        -0.051687777042388916,
        0.04429655894637108,
        -0.05024437978863716,
        -0.05143837630748749,
        -0.0036432461347430944,
        -0.051694903522729874,
        0.0003090221725869924,
        -0.028561118990182877,
        0.043614812195301056,
        0.05149791017174721,
        -0.047543853521347046,
        -0.049337904900312424,
        0.03249942883849144,
        -0.05074306204915047,
        0.051695000380277634,
        0.05168132111430168,
        0.027307849377393723,
        0.051543910056352615,
        0.04632924497127533,
        -0.04964025691151619,
        -0.051676150411367416,
        -0.04261310026049614,
        0.0516740158200264,
        -0.051656804978847504,
        0.014867076650261879,
        0.04899541661143303,
        0.009309097193181515,
        -0.0513141043484211,
        -0.011782114394009113,
        -0.05070972815155983,
        0.0345100574195385,
        -0.05106699839234352,
        0.01580115035176277,
        -0.051687877625226974,
        -0.05155988410115242,
        0.05169284716248512,
        -0.03462987020611763,
        -0.015562215819954872,
        -0.04812973365187645,
        0.05169011652469635,
        0.044834427535533905,
        0.051620129495859146,
        -0.05057278648018837,
        0.02164343371987343,
        0.051695939153432846,
        -0.02270066738128662,
        -0.04279445856809616,
        -0.04622231051325798,
        0.04811963066458702,
        -0.050276365131139755,
        0.04800089821219444,
        0.05020719766616821,
        0.042633213102817535,
        -0.04819450154900551,
        0.05029068887233734,
        0.032285645604133606,
        0.05168125778436661,
        -0.036720339208841324,
        0.04500998184084892,
        -0.05144024267792702,
        0.002066816436126828,
        -0.04716669023036957,
        0.05112403258681297,
        -0.045478977262973785,
        -0.03136324882507324,
        -0.023605212569236755,
        0.03044884093105793,
        -0.05166034400463104,
        0.05167239531874657,
        0.050681035965681076,
        -0.030286401510238647,
        0.0493290089070797,
        -0.033108413219451904,
        -0.05108077824115753,
        0.04362840950489044,
        -0.05163370817899704,
        -0.0455557219684124,
        -0.036484021693468094,
        -0.04496310278773308,
        0.04233676567673683,
        0.03674782067537308,
        0.0025081606581807137,
        0.0515899658203125,
        -0.011952662840485573,
        -0.05150223895907402,
        -0.05149884149432182,
        -0.04107695445418358,
        -0.006546888034790754,
        -0.04061533883213997,
        0.05029549077153206,
        -0.05168256536126137,
        -0.047581449151039124,
        -0.009942840784788132,
        -0.044259581714868546,
        -0.011915032751858234,
        0.049793489277362823,
        0.05118709057569504,
        0.05169561877846718,
        -0.05007878690958023,
        -0.05159161239862442,
        0.051690343767404556,
        -0.04784683883190155,
        -0.051503200083971024,
        0.05154978111386299,
        -0.05004624277353287,
        -0.04890323802828789,
        0.043117690831422806,
        -0.04945522919297218,
        -0.051171496510505676,
        0.048551399260759354,
        0.051692571491003036,
        0.027010837569832802,
        0.0506339967250824,
        0.051680684089660645,
        -0.001114401500672102,
        -0.02959512360394001,
        0.05150323733687401,
        -0.05167722702026367,
        -0.0515763983130455,
        0.05166025459766388,
        0.04644788056612015,
        0.048525162041187286,
        -0.0513756200671196,
        -0.051615022122859955,
        -0.038897477090358734,
        0.05169287323951721,
        -0.03821542486548424,
        0.034966688603162766,
        -0.012220234610140324,
        0.05064646899700165,
        -0.048493847250938416,
        -0.04202251136302948,
        -0.029424836859107018,
        0.045255668461322784,
        0.019140582531690598,
        -0.035966578871011734,
        0.0262615829706192,
        0.05169481784105301,
        -0.017450587823987007,
        -0.05169357359409332,
        0.05168769136071205,
        0.011418100446462631,
        0.04725636914372444,
        -0.05167355388402939,
        -0.0511181615293026,
        0.05135883390903473,
        -0.03039597161114216,
        -0.0008199934964068234,
        0.05162592604756355,
        0.04106086865067482,
        -0.051392581313848495,
        0.047889403998851776,
        -0.05146845802664757,
        0.051325004547834396,
        0.0482071228325367,
        -0.004724880214780569,
        -0.03661171719431877,
        -0.05147760361433029,
        0.0494561493396759,
        0.04986390471458435,
        0.04829859733581543,
        0.0220094732940197,
        -0.04895687475800514,
        -0.019174354150891304,
        0.051621612161397934,
        -0.04804038628935814,
        0.04585347697138786,
        0.05155908316373825,
        0.05164226517081261,
        0.031325243413448334,
        0.03818408027291298,
        0.0505736880004406,
        -0.04800456389784813,
        -0.019702844321727753,
        -0.04681822285056114,
        0.012151858769357204,
        0.05169413983821869,
        -0.04952138289809227,
        0.03874180465936661,
        0.05040697753429413,
        0.04976363107562065,
        0.05156633257865906,
        0.005341708194464445,
        -0.0504918210208416,
        0.0515902154147625,
        0.05026455968618393,
        0.02789744734764099,
        0.04905468970537186,
        0.02872345596551895,
        -0.05112115666270256,
        -0.04796152561903,
        0.04664222523570061,
        -0.018987813964486122,
        0.04453457146883011,
        -0.03276873007416725,
        0.04894455894827843,
        -0.051573265343904495,
        0.0461864247918129,
        -0.041148412972688675,
        0.03171242028474808,
        0.04642469063401222,
        -0.0352579765021801,
        0.05137765780091286,
        0.050634585320949554,
        0.04805528372526169,
        0.05003517121076584,
        0.05167599394917488,
        0.021180961281061172,
        -0.04795869439840317,
        -0.03155377507209778,
        -0.05138444900512695,
        0.0494842603802681,
        -0.024992095306515694,
        0.03470434248447418,
        0.041716933250427246,
        -0.0502018965780735,
        0.03402413800358772,
        -0.042567070573568344,
        -0.029362551867961884,
        0.02603268250823021,
        -0.05004424601793289,
        -0.05079887807369232,
        0.051675908267498016,
        0.048130135983228683,
        -0.0516352616250515,
        0.0384753979742527,
        0.051665596663951874,
        -0.045341357588768005,
        -0.050993531942367554,
        -0.04595434293150902,
        -0.025482436642050743,
        -0.050189707428216934,
        0.05169079452753067,
        0.04464023560285568,
        0.04343269765377045,
        -0.048542410135269165,
        -0.051631860435009,
        0.050043899565935135,
        -0.051695432513952255,
        0.03461448475718498,
        -0.049971796572208405,
        -0.05155796930193901,
        0.050868257880210876,
        -0.04968702048063278,
        -0.0509992316365242,
        -0.05136660486459732,
        -0.04702530428767204,
        -0.05144497752189636,
        0.051058974117040634,
        0.029616622254252434,
        -0.013846400193870068,
        0.051052276045084,
        0.028393205255270004,
        -0.050889112055301666,
        -0.03941658139228821,
        0.04613248631358147,
        0.046676333993673325,
        -0.05167564004659653,
        -0.04982203617691994,
        -0.042978424578905106,
        0.0020192135125398636,
        -0.048766255378723145,
        -0.04790746793150902,
        0.051695566624403,
        0.040185585618019104,
        -0.049562834203243256,
        0.04920586571097374,
        0.0386529266834259,
        0.04064970463514328,
        -0.03293268382549286,
        0.04041747748851776,
        -0.02767249569296837,
        0.04762783274054527,
        -0.0009806777816265821,
        -0.030482497066259384,
        0.041955821216106415,
        -0.016316181048750877,
        -0.0039197830483317375,
        0.046912215650081635,
        0.04972269758582115,
        -0.04609917104244232,
        -0.051629938185214996,
        -0.05128409340977669,
        -0.0482851043343544,
        -0.05125836282968521,
        0.049111995846033096,
        -0.040893178433179855,
        0.039590898901224136,
        -0.04874231293797493,
        0.009711719118058681,
        -0.05124909058213234,
        0.0513155572116375,
        -0.015463783405721188,
        0.050979554653167725,
        -0.05154615640640259,
        0.04857068508863449,
        -0.05119352415204048,
        0.02124674618244171,
        -0.044646039605140686,
        -0.04800129681825638,
        -0.0513717457652092,
        0.05024583637714386,
        -0.03596183657646179,
        -0.026606565341353416,
        0.05055997520685196,
        -0.04592272266745567,
        0.05130567401647568,
        -0.04830367863178253,
        0.044357188045978546,
        0.051626596599817276,
        -0.051061466336250305,
        0.05169423669576645,
        0.05137613043189049,
        0.051031444221735,
        0.050931721925735474,
        -0.04956921935081482,
        0.04593931883573532,
        -0.05168449878692627,
        -0.051567018032073975,
        0.05059688538312912,
        -0.04915805906057358,
        -0.04744953662157059,
        0.038023579865694046,
        -0.05169492959976196,
        -0.05168194696307182,
        0.046994272619485855,
        -0.050040777772665024,
        0.03840099647641182,
        -0.04105890542268753,
        0.047057781368494034,
        -0.05152023956179619,
        -0.013194769620895386,
        -0.05118931457400322,
        0.051615454256534576,
        -0.05161342769861221,
        0.015816817060112953,
        -0.05166609212756157,
        -0.04176853224635124,
        -0.0191506277769804,
        0.0516187846660614,
        -0.03296906128525734,
        0.041604768484830856,
        -0.04737701639533043,
        -0.04035897180438042,
        -0.04366006702184677,
        0.0504157654941082,
        -0.012012669816613197,
        -0.011066618375480175,
        0.048535704612731934,
        -0.05079895257949829,
        0.042049385607242584,
        0.012261099182069302,
        -0.05059356614947319,
        -0.05164460465312004,
        0.0034623071551322937,
        -0.0494290255010128,
        0.034078631550073624,
        -0.05156826227903366,
        0.006528197322040796,
        -0.04819037392735481,
        -0.05069179832935333,
        0.03729192540049553,
        0.04953372851014137,
        0.045047685503959656,
        -0.04275106266140938,
        -0.03951181098818779,
        -0.028814243152737617,
        -0.03269157186150551,
        0.05092514678835869,
        -0.051511071622371674,
        0.05166171118617058,
        -0.051643047481775284,
        -0.019397933036088943,
        0.04772517830133438,
        -0.051488377153873444,
        0.05083037167787552,
        -0.04376232996582985,
        0.041011784225702286,
        0.050114795565605164,
        0.05003523454070091,
        -0.026000820100307465,
        0.028161391615867615,
        -0.038072455674409866,
        -0.04698462784290314,
        -0.004014232661575079,
        0.05036076903343201,
        -0.05167201906442642,
        0.05146075785160065,
        -0.05163108929991722,
        0.05113832280039787,
        -0.051695566624403,
        0.017101846635341644,
        0.00006313311314443126,
        -0.04884476587176323,
        -0.05150466039776802,
        -0.026953978464007378,
        -0.029758421704173088,
        -0.042551495134830475,
        -0.04855406656861305,
        -0.051237910985946655,
        0.046221889555454254,
        -0.05004270747303963,
        -0.04209786280989647,
        -0.05160574987530708,
        0.05108847841620445,
        -0.026239551603794098,
        -0.05152054503560066,
        -0.043576862663030624,
        0.0513986200094223,
        -0.051314640790224075,
        0.0513731874525547,
        0.05168070271611214,
        -0.04993345960974693,
        0.050552189350128174,
        -0.05169105902314186,
        0.04261445254087448,
        0.05058756843209267,
        0.04752727597951889,
        0.05161511152982712,
        0.04546903818845749,
        0.04048512503504753,
        -0.04006610065698624,
        -0.04900943115353584,
        -0.048028696328401566,
        -0.04844430088996887,
        0.05098910257220268,
        -0.013708449900150299,
        0.05002415552735329,
        -0.05169511213898659,
        0.05166228115558624,
        -0.051674824208021164,
        0.05148867517709732,
        0.0510222464799881,
        -0.0514303483068943,
        0.05155840888619423,
        -0.039418675005435944,
        -0.04862569272518158,
        -0.05017850920557976,
        -0.05166422203183174,
        0.05164763703942299,
        0.04942658543586731,
        0.030646784231066704,
        0.04960288479924202,
        -0.009264418855309486,
        0.03131316974759102,
        -0.05119423568248749,
        -0.05086749792098999,
        -0.05145995691418648,
        0.03269804269075394,
        0.05140715837478638,
        0.04005111753940582,
        0.03845414146780968,
        0.0512373186647892,
        0.051678817719221115,
        0.05035562440752983,
        -0.05153407156467438,
        0.05167374759912491,
        0.03851121664047241,
        -0.05169570446014404,
        0.05098521709442139,
        -0.0465519018471241,
        -0.05143994837999344,
        -0.0009400042472407222,
        -0.03711821511387825,
        0.031023195013403893,
        0.049176935106515884,
        0.007330233231186867,
        -0.03378817066550255,
        -0.04345789924263954,
        -0.05060267075896263,
        -0.04704093560576439,
        -0.033813200891017914,
        0.03831849619746208,
        0.02460232935845852,
        -0.022120146080851555,
        0.051689255982637405,
        -0.05047871544957161,
        0.04973761364817619,
        -0.05008603632450104
    ],
    [
        -0.00413324311375618,
        -0.050079990178346634,
        0.04792531952261925,
        -0.04886357858777046,
        -0.006989858113229275,
        -0.01080249436199665,
        0.04937013238668442,
        -0.04986303672194481,
        0.04455474391579628,
        -0.05007996782660484,
        0.047900184988975525,
        -0.05004778131842613,
        -0.04764161631464958,
        -0.04873279854655266,
        -0.049828700721263885,
        0.0035632974468171597,
        0.03932945430278778,
        0.05006284639239311,
        -0.05007980391383171,
        0.05006919801235199,
        -0.049505118280649185,
        0.03583982586860657,
        0.050078872591257095,
        0.04890399053692818,
        -0.04899867624044418,
        -0.010390931740403175,
        0.0331188403069973,
        0.04809390380978584,
        0.04944702237844467,
        0.05007939413189888,
        -0.010980373248457909,
        -0.04975620657205582,
        0.049988821148872375,
        -0.0500204861164093,
        0.04996498301625252,
        -0.04983990266919136,
        -0.04762277752161026,
        -0.04978381469845772,
        -0.04979751631617546,
        0.033858638256788254,
        0.04403739050030708,
        0.04684716835618019,
        0.024338198825716972,
        0.04763532802462578,
        -0.048668526113033295,
        -0.05003694072365761,
        0.028108322992920876,
        0.050035715103149414,
        0.05001518502831459,
        0.03621057793498039,
        0.049192849546670914,
        -0.012102600187063217,
        0.03417538106441498,
        -0.0299166701734066,
        -0.04880563169717789,
        0.050053004175424576,
        -0.04975342005491257,
        0.04255995899438858,
        -0.0500786118209362,
        -0.043994106352329254,
        -0.049927785992622375,
        0.038598380982875824,
        -0.04914691671729088,
        -0.050079524517059326,
        -0.02357363887131214,
        0.05007839575409889,
        -0.050016921013593674,
        -0.05003678798675537,
        -0.04994627460837364,
        -0.012759159319102764,
        0.036804720759391785,
        -0.05000061169266701,
        0.04971500113606453,
        0.04974033683538437,
        0.050079815089702606,
        0.006554534658789635,
        0.0012177986791357398,
        0.050079986453056335,
        -0.004754669964313507,
        0.02147424966096878,
        -0.04662367329001427,
        -0.04997410252690315,
        -0.050076548010110855,
        -0.002837694715708494,
        0.047547247260808945,
        0.044151511043310165,
        -0.04991145804524422,
        -0.03408472612500191,
        -0.035301677882671356,
        -0.04726120084524155,
        -0.05005171522498131,
        0.04855968803167343,
        -0.04895094782114029,
        0.03789252042770386,
        -0.04907681792974472,
        0.036575332283973694,
        -0.037295594811439514,
        -0.03596475347876549,
        0.0476532019674778,
        0.04879705607891083,
        -0.049342043697834015,
        -0.02960890717804432,
        0.04890406131744385,
        0.049741216003894806,
        -0.04990055784583092,
        -0.04126766324043274,
        0.05007999390363693,
        0.006970029324293137,
        -0.04987465590238571,
        -0.047130946069955826,
        -0.007972490973770618,
        -0.05007164925336838,
        -0.04962010309100151,
        0.035909682512283325,
        0.04976314678788185,
        -0.015257633291184902,
        -0.048855457454919815,
        -0.04901758208870888,
        0.04984355717897415,
        -0.050010502338409424,
        0.049405843019485474,
        0.05005903169512749,
        0.04998946562409401,
        -0.050079185515642166,
        -0.033019017428159714,
        -0.03875730186700821,
        -0.04430967941880226,
        0.04957422614097595,
        0.050073638558387756,
        -0.04955899342894554,
        0.05005267634987831,
        -0.04782582446932793,
        -0.04846162348985672,
        0.0500190444290638,
        -0.04803427308797836,
        -0.050031628459692,
        0.04931885376572609,
        0.04999256134033203,
        0.04335739091038704,
        0.046075452119112015,
        0.04384596273303032,
        0.02041913941502571,
        0.04994652420282364,
        -0.050018250942230225,
        -0.050027258694171906,
        -0.03213530778884888,
        -0.04975630342960358,
        -0.0073816804215312,
        -0.04903130233287811,
        -0.04697396978735924,
        -0.04968016967177391,
        -0.050053562968969345,
        0.044188354164361954,
        -0.04848519340157509,
        0.05007999390363693,
        -0.04982705041766167,
        -0.0500798374414444,
        -0.04987609386444092,
        0.0496562197804451,
        -0.021698426455259323,
        -0.047303084284067154,
        0.04627193510532379,
        0.04858503118157387,
        -0.01961500383913517,
        0.049849383533000946,
        -0.050034523010253906,
        0.05007872357964516,
        0.015403620898723602,
        -0.048229318112134933,
        0.01097919326275587,
        0.04960763081908226,
        -0.04989887401461601,
        -0.05003160238265991,
        -0.04702356085181236,
        -0.05006973817944527,
        0.04978581890463829,
        -0.049900200217962265,
        -0.04638392850756645,
        0.049358077347278595,
        -0.02127854898571968,
        0.04480929672718048,
        0.05007070675492287,
        0.050075430423021317,
        0.042011041194200516,
        -0.04845086857676506,
        -0.03890252858400345,
        0.04972251504659653,
        0.04898526147007942,
        -0.049975790083408356,
        -0.05000584200024605,
        -0.050079818814992905,
        0.002107379026710987,
        0.027077121660113335,
        0.05003463849425316,
        -0.04882614314556122,
        -0.04614434018731117,
        0.05007729306817055,
        0.049891915172338486,
        -0.04831642284989357,
        0.00822458229959011,
        0.049401555210351944,
        0.038056518882513046,
        -0.020937981083989143,
        -0.04985514655709267,
        0.050043050199747086,
        -0.03571721911430359,
        0.015250909142196178,
        -0.05006515607237816,
        -0.047584857791662216,
        0.0500711090862751,
        -0.04848261922597885,
        0.04940939322113991,
        -0.0500757098197937,
        0.050053730607032776,
        -0.03792080283164978,
        -0.04684565216302872,
        0.03707436844706535,
        0.04276857152581215,
        0.050069816410541534,
        0.05007999390363693,
        0.04990000277757645,
        -0.04957282915711403,
        0.03402126580476761,
        0.04963856562972069,
        0.05007327347993851,
        -0.05005854368209839,
        0.043532487004995346,
        0.049974508583545685,
        0.050079911947250366,
        -0.03987728804349899,
        0.013587653636932373,
        0.0184907428920269,
        0.05007786676287651,
        0.023249134421348572,
        -0.049769192934036255,
        0.05007951706647873,
        0.04835245758295059,
        -0.024875899776816368,
        0.0500716008245945,
        0.050079889595508575,
        0.02826106920838356,
        0.049996111541986465,
        -0.04997703433036804,
        0.050064828246831894,
        0.048033274710178375,
        -0.033115170896053314,
        -0.04935256019234657,
        0.03546435385942459,
        -0.04354415461421013,
        0.04667619243264198,
        -0.03951225057244301,
        -0.04884334281086922,
        0.05004158243536949,
        -0.046038802713155746,
        0.04908182844519615,
        0.04011603444814682,
        -0.04937219247221947,
        0.050010524690151215,
        0.04680290073156357,
        0.04973554238677025,
        -0.0499749630689621,
        0.02885301224887371,
        -0.04929858818650246,
        0.05002545565366745,
        -0.04890400916337967,
        0.050078995525836945,
        -0.0500718392431736,
        0.04447897896170616,
        -0.007657362148165703,
        0.0010936412727460265,
        0.04971849545836449,
        -0.050064828246831894,
        -0.04929758980870247,
        -0.050053007900714874,
        -0.05007599666714668,
        -0.03248293325304985,
        -0.03166096284985542,
        0.050047617405653,
        -0.05007961019873619,
        -0.050076212733983994,
        -0.0500764399766922,
        0.04985954612493515,
        0.050061360001564026,
        -0.04365760087966919,
        0.016898302361369133,
        0.04859349504113197,
        -0.0077366954647004604,
        0.0485481321811676,
        0.03813736140727997,
        0.050028905272483826,
        -0.027845673263072968,
        -0.0177918728441,
        -0.04985077306628227,
        -0.030295591801404953,
        -0.02439410798251629,
        -0.048621416091918945,
        0.05006091669201851,
        0.04844944179058075,
        -0.05007999390363693,
        0.049833815544843674,
        0.04844474047422409,
        0.029930636286735535,
        0.01718653365969658,
        -0.04848824068903923,
        -0.050045859068632126,
        0.012542365118861198,
        0.050066251307725906,
        0.04996195808053017,
        -0.03981504589319229,
        0.04639117792248726,
        0.043430931866168976,
        0.0401293970644474,
        0.020272094756364822,
        -0.049996212124824524,
        -0.049919258803129196,
        -0.04838746041059494,
        -0.04074423015117645,
        -0.05007815733551979,
        0.05004791170358658,
        0.0367080420255661,
        0.04756058752536774,
        0.04833836853504181,
        0.05005315691232681,
        -0.04436618834733963,
        0.05003713443875313,
        -0.04831325635313988,
        0.05004554241895676,
        -0.029282620176672935,
        -0.04886362701654434,
        -0.0076952483505010605,
        0.02204793505370617,
        -0.045602962374687195,
        -0.03509564325213432,
        0.04926394671201706,
        0.0492207333445549,
        0.02952345460653305,
        -0.04956527799367905,
        0.02990521676838398,
        0.04602251574397087,
        0.05007826164364815,
        -0.043812885880470276,
        0.04454874247312546,
        -0.05004443600773811,
        0.04991612210869789,
        0.04387970641255379,
        0.0396585687994957,
        0.04447124898433685,
        0.04955878481268883,
        -0.049952682107686996,
        0.04874978959560394,
        -0.05006656050682068,
        -0.05007973313331604,
        0.04560719430446625,
        -0.049681406468153,
        -0.050076257437467575,
        0.027505170553922653,
        0.04947567358613014,
        -0.04942586272954941,
        -0.04896711930632591,
        -0.04992472752928734,
        0.027158986777067184,
        -0.04121275618672371,
        -0.050079334527254105,
        -0.01597687043249607,
        0.04770931228995323,
        -0.04996111989021301,
        0.05007912963628769,
        -0.0489678680896759,
        0.04638257622718811,
        -0.05001044645905495,
        -0.04616158828139305,
        -0.0491306334733963,
        0.048911649733781815,
        -0.029512034729123116,
        0.04282626137137413,
        0.05007995665073395,
        0.03769920766353607,
        0.04325154423713684,
        0.03902829438447952,
        -0.048876553773880005,
        0.049779534339904785,
        -0.029140371829271317,
        -0.04970933124423027,
        0.04851313680410385,
        -0.046435486525297165,
        -0.04994874820113182,
        0.046788450330495834,
        -0.01616034284234047,
        -0.048676081001758575,
        -0.028972553089261055,
        -0.04987466707825661,
        -0.01680273748934269,
        -0.04964714124798775,
        -0.050075434148311615,
        -0.0499105378985405,
        0.04977405071258545,
        0.047791529446840286,
        0.04887494817376137,
        0.049208346754312515,
        -0.049701109528541565,
        -0.047468505799770355,
        0.050073906779289246,
        -0.0500757209956646,
        0.050076913088560104,
        -0.04742888733744621,
        -0.049665842205286026,
        0.04707955941557884,
        -0.025366559624671936,
        0.050009261816740036,
        0.048008013516664505,
        -0.0500718392431736,
        0.023522932082414627,
        0.0474892221391201,
        -0.04980788007378578,
        0.04994238168001175,
        -0.049271367490291595,
        -0.04218798130750656,
        0.05003735050559044,
        0.005224841646850109,
        -0.050040628761053085,
        0.049885284155607224,
        -0.04876600578427315,
        -0.05007348582148552,
        -0.04982683062553406,
        0.049789901822805405,
        -0.018099065870046616,
        0.05007292702794075,
        0.04974144697189331,
        -0.039311736822128296,
        -0.05007895454764366,
        -0.036473531275987625,
        -0.011085186153650284,
        -0.015384898521006107,
        -0.028874561190605164,
        0.050024647265672684,
        -0.049920402467250824,
        -0.049786634743213654,
        0.0459064282476902,
        -0.047757815569639206,
        -0.048766426742076874,
        0.03439357876777649,
        0.04901573807001114,
        -0.013604141771793365,
        0.049233172088861465,
        0.04996839538216591,
        0.04943433403968811,
        0.04915483668446541,
        0.049669716507196426,
        0.048071615397930145,
        0.049866605550050735,
        -0.01412360742688179,
        0.05001743510365486,
        0.048357438296079636,
        -0.04651355743408203,
        -0.04892715811729431,
        -0.030336154624819756,
        -0.04563223943114281,
        -0.040957190096378326,
        0.017827007919549942,
        0.03608732298016548,
        -0.015388255007565022,
        -0.014525489881634712,
        0.04985591769218445,
        -0.05007784441113472,
        0.050077132880687714,
        0.04999598115682602,
        -0.033198777586221695,
        0.050079960376024246,
        -0.04680319130420685,
        -0.05005892738699913,
        0.050074074417352676,
        -0.05006953328847885,
        0.04761092737317085,
        0.0491078719496727,
        -0.050042763352394104,
        0.03929143399000168,
        0.050079792737960815,
        0.04977456107735634,
        -0.04765140265226364,
        0.03547543287277222,
        0.04921646788716316,
        -0.04952739551663399,
        0.047236423939466476,
        0.046175260096788406,
        0.0002949721529148519,
        0.02980160154402256,
        0.04789330065250397,
        0.04963395372033119,
        -0.04927091300487518,
        0.04956589266657829,
        0.049828238785266876,
        -0.049495842307806015,
        0.0499747134745121,
        -0.03138972073793411,
        -0.046505678445100784,
        -0.049886513501405716,
        0.02819211222231388,
        0.030889026820659637,
        0.04981106147170067,
        -0.04991334304213524,
        -0.0022042817436158657,
        -0.022143999114632607,
        0.03164885193109512,
        0.05005278065800667,
        -0.04960387945175171,
        0.045038189738988876,
        0.002786489436402917,
        0.028224723413586617,
        0.05007059872150421,
        -0.03489723056554794,
        0.05006701126694679,
        -0.04977798089385033
    ],
    [
        0.04762595146894455,
        0.008036364801228046,
        0.015815837308764458,
        -0.02433326467871666,
        0.004971143323928118,
        -0.06358852237462997,
        0.030830079689621925,
        -0.01519694272428751,
        0.058681339025497437,
        -0.031810030341148376,
        -0.037360381335020065,
        -0.04490428790450096,
        -0.06395424902439117,
        0.04333288222551346,
        -0.060327570885419846,
        0.0552605502307415,
        -0.03211255371570587,
        0.041174668818712234,
        0.04196341335773468,
        0.058682188391685486,
        0.029308142140507698,
        -0.03305397927761078,
        0.06345217674970627,
        0.010159832425415516,
        -0.019584650173783302,
        0.017288971692323685,
        0.03398307040333748,
        -0.011532573029398918,
        0.020596453920006752,
        -0.022402917966246605,
        0.009675843641161919,
        0.05327055603265762,
        0.0570259690284729,
        -0.06299501657485962,
        0.05024990439414978,
        -0.036636997014284134,
        -0.060926325619220734,
        -0.054537102580070496,
        0.017276935279369354,
        -0.012195123359560966,
        -0.0014423644170165062,
        -0.0029663080349564552,
        0.06433017551898956,
        -0.00613680575042963,
        0.05665147677063942,
        0.01293512899428606,
        -0.0008737629395909607,
        0.05943198874592781,
        0.06471386551856995,
        -0.0199178084731102,
        0.041121430695056915,
        0.05548294633626938,
        0.036098726093769073,
        -0.06340130418539047,
        -0.029085449874401093,
        0.004638591781258583,
        -0.057162582874298096,
        -0.0081239715218544,
        -0.052098147571086884,
        0.0023350249975919724,
        -0.06607500463724136,
        0.0020546941086649895,
        -0.05559968575835228,
        -0.0544295534491539,
        0.048825301229953766,
        -0.02403366006910801,
        -0.06183280795812607,
        -0.048908717930316925,
        0.005020631942898035,
        -0.06085836514830589,
        -0.009199093095958233,
        -0.025849105790257454,
        0.06542222946882248,
        0.054258234798908234,
        0.03907375410199165,
        -0.05930448696017265,
        0.04901278764009476,
        0.06622780859470367,
        0.044929880648851395,
        -0.011682492680847645,
        -0.0002100431447615847,
        0.01991790160536766,
        -0.04733113572001457,
        0.026477858424186707,
        -0.044785451143980026,
        0.057924602180719376,
        -0.04781902953982353,
        -0.05016400292515755,
        -0.06442207843065262,
        0.06079370528459549,
        -0.045486580580472946,
        -0.046081893146038055,
        -0.06498274952173233,
        -0.05112262815237045,
        -0.048849187791347504,
        -0.018565397709608078,
        -0.02758997678756714,
        -0.06404139846563339,
        -0.0018866974860429764,
        0.06341041624546051,
        -0.06459512561559677,
        -0.049517858773469925,
        -0.006144235841929913,
        0.057493481785058975,
        -0.017246225848793983,
        0.04835037514567375,
        0.039625413715839386,
        0.06282700598239899,
        0.0015417940448969603,
        0.029552273452281952,
        -0.032571904361248016,
        0.023640379309654236,
        -0.051579929888248444,
        0.05516834929585457,
        0.06561527401208878,
        0.015620783902704716,
        -0.032591018825769424,
        -0.05271821469068527,
        -0.033795349299907684,
        -0.02312318980693817,
        -0.05148579925298691,
        -0.02631506323814392,
        0.019757671281695366,
        -0.03699697181582451,
        -0.05813102424144745,
        -0.042131923139095306,
        0.024996034801006317,
        0.06436852365732193,
        0.06592164933681488,
        0.01237117312848568,
        0.05868828296661377,
        -0.06386967748403549,
        0.039004527032375336,
        0.058131102472543716,
        -0.06447825580835342,
        -0.045308809727430344,
        0.01245197281241417,
        -0.031985022127628326,
        0.02616649866104126,
        0.02695586532354355,
        0.008573648519814014,
        -0.05629558488726616,
        0.054281216114759445,
        0.05029386654496193,
        -0.011619754135608673,
        0.05415012687444687,
        0.05790604650974274,
        0.05323611572384834,
        0.03862449526786804,
        -0.0019262550631538033,
        -0.020972169935703278,
        -0.0483245812356472,
        -0.05046731233596802,
        -0.012148413807153702,
        0.04599740728735924,
        -0.047121766954660416,
        0.0014345735544338822,
        0.05467364192008972,
        0.06250561773777008,
        0.03795689344406128,
        0.030696608126163483,
        -0.007282838225364685,
        0.060130439698696136,
        -0.015193578787147999,
        0.05700235068798065,
        0.04813110828399658,
        0.045449815690517426,
        -0.04526713117957115,
        -0.04713805019855499,
        0.057460956275463104,
        -0.012019294314086437,
        0.03759840503334999,
        -0.06223222240805626,
        0.06493128091096878,
        -0.061278969049453735,
        0.026568567380309105,
        -0.03633937984704971,
        -0.044543445110321045,
        0.04184212163090706,
        0.023826444521546364,
        0.04574256390333176,
        0.06058714911341667,
        0.04867527261376381,
        -0.04997457563877106,
        0.03595849126577377,
        -0.03911158815026283,
        0.04521174728870392,
        0.05985698848962784,
        -0.03194877877831459,
        -0.06106825917959213,
        -0.06526981294155121,
        0.04006855934858322,
        -0.04032079875469208,
        0.034661877900362015,
        -0.013097003102302551,
        0.04244784265756607,
        0.06432008743286133,
        0.039187315851449966,
        0.0018805640283972025,
        -0.030357427895069122,
        0.04033172130584717,
        0.05604201927781105,
        0.043656326830387115,
        -0.055604804307222366,
        -0.03475940600037575,
        -0.024630950763821602,
        0.05956273525953293,
        0.041007790714502335,
        0.034818705171346664,
        0.04603677988052368,
        -0.06191794574260712,
        -0.024066366255283356,
        0.059410322457551956,
        0.005121947266161442,
        -0.012046252377331257,
        -0.04264019802212715,
        0.057823240756988525,
        0.04751283675432205,
        0.06584304571151733,
        0.02193068154156208,
        0.05898662656545639,
        -0.0504906103014946,
        -0.00855014007538557,
        -0.06346942484378815,
        0.06086369976401329,
        -0.05102529749274254,
        0.0517110675573349,
        -0.05880967155098915,
        0.06276629865169525,
        -0.06530541181564331,
        -0.038316864520311356,
        -0.0431487038731575,
        0.00712240394204855,
        0.056716546416282654,
        -0.022151730954647064,
        0.06506377458572388,
        0.05563909560441971,
        0.05175545811653137,
        0.04055878892540932,
        0.0653277263045311,
        -0.050088174641132355,
        0.06083724647760391,
        -0.023477738723158836,
        -0.008435086347162724,
        0.0493636354804039,
        0.009436694905161858,
        -0.03847954422235489,
        -0.03475908190011978,
        0.04357016831636429,
        0.057105593383312225,
        -0.015931393951177597,
        -0.0645408034324646,
        -0.03868864104151726,
        -0.06505586206912994,
        0.052589960396289825,
        0.023826729506254196,
        0.05643235146999359,
        -0.054899945855140686,
        0.06099320948123932,
        0.06429219990968704,
        -0.03960127383470535,
        0.001040570205077529,
        -0.058231111615896225,
        -0.045822624117136,
        -0.029413361102342606,
        0.05611072853207588,
        0.04268941283226013,
        -0.035048604011535645,
        -0.063716359436512,
        -0.017734460532665253,
        0.03514300659298897,
        -0.04533325880765915,
        -0.022669412195682526,
        -0.01963687501847744,
        -0.046783383935689926,
        0.026599643751978874,
        -0.06041715294122696,
        0.01716947741806507,
        -0.01664808951318264,
        -0.04134918004274368,
        -0.046281423419713974,
        0.05920463800430298,
        0.0069594914093613625,
        0.030114147812128067,
        -0.06052521616220474,
        -0.06085994467139244,
        0.01974634639918804,
        0.05771617963910103,
        -0.026811478659510612,
        0.013961770571768284,
        -0.055478766560554504,
        0.005824984982609749,
        -0.04943973571062088,
        -0.056939952075481415,
        0.05110952630639076,
        -0.05992615967988968,
        0.05498333275318146,
        -0.006403372157365084,
        -0.05041230097413063,
        0.06486653536558151,
        -0.05509115010499954,
        0.02854420430958271,
        -0.025849681347608566,
        0.049930088222026825,
        0.026564985513687134,
        -0.018924327567219734,
        0.014534023590385914,
        0.04725908115506172,
        0.05293267220258713,
        -0.03365467116236687,
        0.055126920342445374,
        -0.06131349876523018,
        0.061313726007938385,
        0.027226312085986137,
        -0.005609182175248861,
        -0.059707459062337875,
        -0.03498333320021629,
        -0.06081444397568703,
        0.05119013413786888,
        -0.04337000101804733,
        -0.029933860525488853,
        0.006476129405200481,
        0.057545386254787445,
        -0.03936950862407684,
        0.06042373552918434,
        -0.06049302592873573,
        0.026425492018461227,
        -0.046307653188705444,
        -0.057156503200531006,
        -0.029727624729275703,
        0.05185538902878761,
        -0.05669340118765831,
        0.015230518765747547,
        -0.06165498122572899,
        0.06305500119924545,
        -0.015084197744727135,
        0.004352221265435219,
        0.06088923290371895,
        -0.047426365315914154,
        0.029316693544387817,
        -0.04637578874826431,
        -0.0036890811752527952,
        0.03131129965186119,
        -0.010983174666762352,
        0.026540113613009453,
        0.027230961248278618,
        0.04339597001671791,
        0.06186198070645332,
        -0.05459412559866905,
        0.06496234983205795,
        0.05083280801773071,
        -0.04091775789856911,
        0.027067702263593674,
        -0.058818794786930084,
        -0.03624185919761658,
        0.0032591905910521746,
        -0.06006774678826332,
        -0.03713750094175339,
        -0.036386556923389435,
        -0.03814789280295372,
        0.04891277104616165,
        -0.04069392383098602,
        -0.0499863475561142,
        -0.0484405942261219,
        -0.006506020203232765,
        -0.06234727427363396,
        0.06107645854353905,
        0.019810054451227188,
        -0.012168233282864094,
        -0.06501346826553345,
        0.02877669595181942,
        -0.022963615134358406,
        0.053302962332963943,
        -0.06460719555616379,
        0.051703982055187225,
        0.05567536503076553,
        0.027739299461245537,
        -0.012892892584204674,
        -0.00261505926027894,
        -0.04434872791171074,
        -0.06289342790842056,
        0.03080778568983078,
        -0.06164584308862686,
        0.04743066802620888,
        0.05568668618798256,
        -0.06133509427309036,
        0.006169362459331751,
        -0.047823186963796616,
        -0.049655377864837646,
        -0.04824194684624672,
        -0.04202652722597122,
        -0.029900310561060905,
        -0.0010306376498192549,
        -0.05629369989037514,
        0.06498119980096817,
        0.062491510063409805,
        0.05939497798681259,
        -0.061390019953250885,
        -0.05595112591981888,
        -0.05717732012271881,
        0.011741009540855885,
        0.05335570126771927,
        0.023451227694749832,
        0.055506106466054916,
        -0.021948903799057007,
        -0.035744596272706985,
        0.042456720024347305,
        -0.04754668474197388,
        0.062206972390413284,
        0.03721433877944946,
        -0.0389937125146389,
        0.029023079201579094,
        0.0451856330037117,
        0.0021160822361707687,
        0.05439629778265953,
        -0.05623291805386543,
        0.014047365635633469,
        0.042042266577482224,
        0.06551060825586319,
        -0.019947875291109085,
        0.02098316140472889,
        -0.053689539432525635,
        -0.019019968807697296,
        -0.06531592458486557,
        -0.02295803278684616,
        0.04799697920680046,
        -0.05622325465083122,
        0.009647522121667862,
        -0.031926002353429794,
        -0.061633408069610596,
        -0.025589274242520332,
        -0.02861136570572853,
        -0.04057256132364273,
        -0.0465095192193985,
        -0.044735874980688095,
        -0.0402490608394146,
        -0.051265884190797806,
        0.021307647228240967,
        -0.05804159864783287,
        -0.03200099617242813,
        0.012206143699586391,
        0.004460358060896397,
        0.04643812030553818,
        0.06392447650432587,
        -0.02723763696849346,
        -0.03555547446012497,
        0.06501258909702301,
        -0.008041911758482456,
        0.05904896929860115,
        0.014275897294282913,
        0.03538939356803894,
        0.04889988899230957,
        0.055494796484708786,
        0.04375653341412544,
        0.04342563822865486,
        -0.06565888971090317,
        0.04092958942055702,
        -0.05067380517721176,
        0.059216395020484924,
        -0.03466079756617546,
        0.04297272115945816,
        -0.05973755940794945,
        0.018759874626994133,
        -0.04915803298354149,
        0.04409090429544449,
        0.0637977123260498,
        -0.060393042862415314,
        0.046717461198568344,
        0.0005633147084154189,
        -0.056483250111341476,
        0.03243257477879524,
        0.011511006392538548,
        0.04826383292675018,
        0.039568521082401276,
        -0.027833547443151474,
        0.053150344640016556,
        0.027272112667560577,
        0.037326615303754807,
        -0.05416490510106087,
        -0.041000574827194214,
        -0.05197722092270851,
        0.0032313012052327394,
        -0.03861250355839729,
        0.03669189289212227,
        0.028893843293190002,
        0.04825195297598839,
        0.048838112503290176,
        0.05410638824105263,
        -0.06404032558202744,
        -0.0036721397191286087,
        -0.034569308161735535,
        -0.024708617478609085,
        0.013401556760072708,
        -0.03545204550027847,
        0.05884791165590286,
        0.011401531286537647,
        -0.03656812757253647,
        0.030498908832669258,
        0.0641603022813797,
        -0.04666116461157799,
        -0.0032379210460931063,
        -0.014782978221774101,
        -0.06249425187706947,
        0.004348342306911945,
        -0.006268252152949572,
        0.0039742570370435715,
        0.06283645331859589,
        0.02569911815226078,
        0.010996016673743725,
        -0.01886640675365925,
        0.05652010813355446,
        -0.02201133966445923
    ],
    [
        -0.03885557875037193,
        -0.050733864307403564,
        -0.05088476836681366,
        0.006989218760281801,
        -0.05137700214982033,
        -0.04723808914422989,
        -0.018937768414616585,
        -0.05146614834666252,
        0.051411814987659454,
        0.007848481647670269,
        -0.041559696197509766,
        0.05147267132997513,
        -0.051465973258018494,
        -0.039957016706466675,
        -0.051117412745952606,
        0.046842556446790695,
        0.05081409960985184,
        0.05141078680753708,
        -0.03033292479813099,
        -0.012749934569001198,
        0.05145927146077156,
        0.007214209530502558,
        0.051355548202991486,
        0.04920416697859764,
        -0.007564922329038382,
        0.05143268406391144,
        0.04941833019256592,
        0.04857669770717621,
        -0.046691909432411194,
        0.03213538974523544,
        -0.03739370405673981,
        -0.03342060372233391,
        0.05146650969982147,
        -0.004042884800583124,
        0.05129116401076317,
        -0.0485529750585556,
        -0.051257167011499405,
        -0.016399063169956207,
        -0.0511498898267746,
        -0.049320001155138016,
        0.03976723551750183,
        0.04682920500636101,
        0.04956728219985962,
        0.03898799791932106,
        0.030985750257968903,
        0.04904492199420929,
        0.051330290734767914,
        0.051347602158784866,
        0.051470354199409485,
        -0.047775235027074814,
        0.051422663033008575,
        0.043612539768218994,
        -0.031044017523527145,
        -0.05120566487312317,
        0.05129098892211914,
        0.0492391362786293,
        -0.050870705395936966,
        0.025784293189644814,
        -0.04962499812245369,
        -0.021382862702012062,
        -0.03933310508728027,
        0.02000417746603489,
        -0.051429759711027145,
        0.03914519026875496,
        0.00826245453208685,
        -0.038934383541345596,
        -0.05137406289577484,
        -0.0042520021088421345,
        -0.012081588618457317,
        -0.05133157968521118,
        -0.011462047696113586,
        -0.028174176812171936,
        -0.025560149922966957,
        0.05144427716732025,
        0.029655078426003456,
        -0.05073710158467293,
        0.04583027958869934,
        0.0514727383852005,
        0.048944223672151566,
        -0.002914518117904663,
        0.028054406866431236,
        -0.049604613333940506,
        -0.044662848114967346,
        0.049824994057416916,
        0.05067279934883118,
        0.04931385815143585,
        -0.0510408952832222,
        0.051409028470516205,
        0.011225137859582901,
        0.05049023777246475,
        -0.050120506435632706,
        0.004143028054386377,
        -0.04793958738446236,
        -0.050896432250738144,
        -0.05140586197376251,
        0.023286445066332817,
        -0.035337530076503754,
        -0.011549807153642178,
        0.05146917328238487,
        0.0509655587375164,
        -0.05146251246333122,
        0.04456792399287224,
        0.04897746816277504,
        0.04450736939907074,
        -0.01591956801712513,
        0.014060910791158676,
        -0.046249207109212875,
        0.048773474991321564,
        -0.05113118514418602,
        -0.04994111880660057,
        -0.051460810005664825,
        -0.05123206600546837,
        -0.03403295576572418,
        0.030767764896154404,
        0.03363526985049248,
        0.05086454749107361,
        0.051039330661296844,
        -0.0388873815536499,
        -0.036811936646699905,
        -0.050502099096775055,
        -0.05000882223248482,
        -0.0506046861410141,
        0.051118556410074234,
        -0.051451750099658966,
        -0.05128651112318039,
        -0.051384977996349335,
        0.0032605158630758524,
        0.05064086616039276,
        0.05096181854605675,
        0.04985371232032776,
        -0.04384012892842293,
        0.044639702886343,
        -0.048540424555540085,
        0.05065283179283142,
        -0.04694667086005211,
        -0.051413096487522125,
        0.05147272348403931,
        0.05146284028887749,
        -0.029402505606412888,
        -0.051007796078920364,
        -0.04304319620132446,
        0.051267098635435104,
        0.051030293107032776,
        0.05147213116288185,
        -0.0011233952827751637,
        0.02645583264529705,
        -0.04522230476140976,
        -0.03391410410404205,
        0.051455456763505936,
        -0.026403240859508514,
        -0.05116169527173042,
        -0.05146915838122368,
        0.006298079155385494,
        -0.04893020540475845,
        0.05146666243672371,
        -0.04167467728257179,
        -0.05068754404783249,
        -0.04856283590197563,
        0.05123370513319969,
        0.04939453676342964,
        0.0028176421765238047,
        0.013156804256141186,
        0.04670897498726845,
        0.05085016414523125,
        -0.02775178663432598,
        -0.04287432134151459,
        0.05147272348403931,
        0.039867814630270004,
        -0.011493304744362831,
        0.04261045530438423,
        0.04666660726070404,
        -0.05092110112309456,
        -0.051469262689352036,
        0.0514722540974617,
        -0.05122718960046768,
        0.03740288317203522,
        -0.051472701132297516,
        -0.043789252638816833,
        0.05114598944783211,
        -0.03904950991272926,
        -0.05115507170557976,
        0.05076977610588074,
        0.048896972090005875,
        -0.050908640027046204,
        -0.03928367421030998,
        -0.05134859308600426,
        0.05045579746365547,
        0.011972383596003056,
        0.0513879619538784,
        -0.05089851841330528,
        -0.05097546428442001,
        0.03158102184534073,
        0.04468532279133797,
        0.04662558063864708,
        0.04781569913029671,
        0.0457102507352829,
        0.012301255948841572,
        0.04929283261299133,
        0.049601905047893524,
        0.04863327369093895,
        0.046708930283784866,
        -0.051424361765384674,
        0.002837761538103223,
        0.05120408162474632,
        0.04848842695355415,
        -0.03010387346148491,
        0.04250205308198929,
        0.004133477341383696,
        -0.03152090311050415,
        0.04397404566407204,
        -0.05136719346046448,
        0.048705391585826874,
        0.05140719562768936,
        0.0510781854391098,
        -0.03416385501623154,
        0.051471028476953506,
        0.051403749734163284,
        0.051446277648210526,
        0.051470160484313965,
        0.05057748779654503,
        -0.04988854378461838,
        -0.02924164943397045,
        -0.05133919417858124,
        0.012096107006072998,
        0.04581602290272713,
        -0.05048505216836929,
        0.035492029041051865,
        -0.049447380006313324,
        -0.019816504791378975,
        -0.05123305693268776,
        0.02418520860373974,
        -0.04094645753502846,
        0.0340026430785656,
        0.04998793080449104,
        -0.05084565654397011,
        0.0512254424393177,
        0.05086442455649376,
        0.005912429187446833,
        0.05100920796394348,
        0.05147270858287811,
        -0.03643297404050827,
        0.051457345485687256,
        0.04864178225398064,
        0.02835838869214058,
        0.04763657599687576,
        0.05091797560453415,
        0.02333216369152069,
        0.047645553946495056,
        0.049882736057043076,
        0.00581790367141366,
        -0.051138151437044144,
        -0.05059394612908363,
        0.05146736279129982,
        -0.05138377100229263,
        0.04686465859413147,
        0.051083847880363464,
        0.048061054199934006,
        -0.01936505176126957,
        0.050898417830467224,
        0.05142392963171005,
        0.038948461413383484,
        0.046658504754304886,
        -0.05117395892739296,
        0.03408977761864662,
        0.0514143630862236,
        0.051457736641168594,
        0.05070548877120018,
        -0.0316755585372448,
        0.050337888300418854,
        -0.05081554502248764,
        0.05112363025546074,
        -0.05077528953552246,
        0.05140683799982071,
        -0.05063485726714134,
        -0.048668231815099716,
        0.043507952243089676,
        -0.05072711408138275,
        -0.0478593073785305,
        -0.05147266015410423,
        0.04688132554292679,
        -0.051287904381752014,
        0.05147278308868408,
        -0.041665155440568924,
        0.04077306017279625,
        0.0514727346599102,
        0.051285501569509506,
        -0.051170799881219864,
        0.021536491811275482,
        0.04525577276945114,
        0.04696827754378319,
        -0.04581928253173828,
        0.00018101224850397557,
        -0.05025837942957878,
        0.04358561336994171,
        -0.04176705330610275,
        -0.051040034741163254,
        0.05146769434213638,
        0.0406336709856987,
        -0.0044331313110888,
        0.05146603286266327,
        -0.04265009984374046,
        -0.03581568971276283,
        -0.03540116548538208,
        0.029345769435167313,
        -0.04624234884977341,
        0.049247778952121735,
        -0.04138042777776718,
        0.04426245391368866,
        0.018337083980441093,
        -0.05136418342590332,
        -0.031051848083734512,
        -0.03785284608602524,
        0.051463134586811066,
        0.04591976851224899,
        -0.049132559448480606,
        -0.05147077143192291,
        -0.0511895976960659,
        0.034029651433229446,
        0.05145122483372688,
        -0.02589484490454197,
        0.003797233570367098,
        -0.04978400096297264,
        0.05127682909369469,
        -0.027113759890198708,
        0.05144455283880234,
        -0.0029694808181375265,
        0.051470328122377396,
        -0.05144278332591057,
        0.05138029903173447,
        -0.051266297698020935,
        0.02995317615568638,
        0.014648065902292728,
        0.0065192184410989285,
        -0.0477096252143383,
        0.05105472356081009,
        0.02461059018969536,
        -0.047514908015728,
        0.036975789815187454,
        -0.05138665810227394,
        0.04837166890501976,
        -0.05117853358387947,
        0.05128645896911621,
        0.05145080015063286,
        -0.03710981830954552,
        0.0514710396528244,
        0.036944739520549774,
        0.050686560571193695,
        0.051421377807855606,
        0.020588845014572144,
        0.05146529898047447,
        0.051254842430353165,
        -0.04932020232081413,
        0.04164491593837738,
        -0.051419228315353394,
        0.047437869012355804,
        0.048009276390075684,
        -0.05143068730831146,
        -0.05130160227417946,
        -0.02827860787510872,
        -0.04822101816534996,
        0.05105581134557724,
        0.05085578188300133,
        -0.05036638677120209,
        -0.040324319154024124,
        -0.05139137804508209,
        -0.05146727338433266,
        0.051025617867708206,
        -0.05146224424242973,
        0.04779869318008423,
        -0.051108088344335556,
        -0.03752780333161354,
        -0.05006944760680199,
        0.051454097032547,
        0.0441177599132061,
        0.04852521792054176,
        -0.04793861135840416,
        0.01961955614387989,
        0.04981430992484093,
        0.05147251486778259,
        -0.04244798421859741,
        0.050599243491888046,
        -0.011697391048073769,
        -0.05091950297355652,
        0.041019052267074585,
        -0.014869295991957188,
        -0.04985787719488144,
        0.05116027966141701,
        0.051148876547813416,
        -0.04942469298839569,
        0.02491716481745243,
        -0.042475294321775436,
        0.045537061989307404,
        -0.04806423559784889,
        -0.05103102698922157,
        0.016944827511906624,
        0.050722748041152954,
        0.0486336424946785,
        -0.042311664670705795,
        -0.05147206783294678,
        -0.05060514807701111,
        -0.022177916020154953,
        0.05070040002465248,
        -0.05147123709321022,
        0.05112849175930023,
        -0.02539745904505253,
        0.020773299038410187,
        0.050906430929899216,
        -0.014562353491783142,
        0.05146418511867523,
        0.02905057929456234,
        -0.04974024370312691,
        -0.030758444219827652,
        -0.014576377347111702,
        0.0054792370647192,
        -0.051387134939432144,
        -0.05020854249596596,
        -0.051263753324747086,
        0.05064051225781441,
        0.05116879567503929,
        0.023745397105813026,
        0.049800530076026917,
        0.034646131098270416,
        0.04958270862698555,
        -0.05146993696689606,
        -0.05073212459683418,
        -0.0006224990938790143,
        -0.029353028163313866,
        -0.04396677017211914,
        -0.017843900248408318,
        -0.050239257514476776,
        0.04929795861244202,
        -0.050920866429805756,
        0.017004583030939102,
        -0.04937230795621872,
        -0.05094904825091362,
        -0.032154105603694916,
        -0.051472362130880356,
        -0.042547374963760376,
        0.01923482120037079,
        -0.051287323236465454,
        0.0453529953956604,
        0.04680948704481125,
        -0.0371055081486702,
        0.05052473768591881,
        -0.0018630503909662366,
        -0.03998557850718498,
        0.050153691321611404,
        -0.051472365856170654,
        0.04356098920106888,
        0.043262600898742676,
        -0.050694070756435394,
        0.0505027137696743,
        -0.04293375089764595,
        -0.021685726940631866,
        -0.03737437352538109,
        -0.05050627887248993,
        -0.04668625071644783,
        -0.048649802803993225,
        -0.0497882142663002,
        0.02427748031914234,
        0.04985956847667694,
        -0.05124864727258682,
        0.05093931406736374,
        -0.024184491485357285,
        0.05030519887804985,
        0.05128323659300804,
        -0.05147160217165947,
        0.0514422208070755,
        -0.050911832600831985,
        -0.05145137012004852,
        0.051433488726615906,
        0.04760504513978958,
        -0.032678019255399704,
        0.05147262290120125,
        0.05144314095377922,
        0.029515482485294342,
        0.03041870705783367,
        0.026020407676696777,
        -0.05135178565979004,
        -0.04934880882501602,
        -0.05145987123250961,
        -0.010939679108560085,
        0.05089294910430908,
        0.05112747102975845,
        0.04814416915178299,
        0.05143877491354942,
        0.05146990716457367,
        0.012039334513247013,
        -0.05120987445116043,
        0.04974408820271492,
        -0.042921751737594604,
        -0.05146560072898865,
        0.05128154903650284,
        -0.04838632792234421,
        -0.05042962729930878,
        -0.03922805190086365,
        0.04904166981577873,
        -0.05139673128724098,
        0.05143231153488159,
        -0.05102412775158882,
        -0.04751920700073242,
        0.04979377239942551,
        -0.0487864688038826,
        -0.050658710300922394,
        -0.04970589280128479,
        -0.027620067819952965,
        -0.029952505603432655,
        0.007759732194244862,
        0.0514708086848259,
        -0.05147256702184677,
        0.05147260054945946,
        0.04916326701641083
    ],
    [
        -0.027063647285103798,
        0.043343909084796906,
        0.03456774726510048,
        0.05334368720650673,
        0.04588066041469574,
        -0.0430477149784565,
        0.05856561288237572,
        -0.057998739182949066,
        0.0125892274081707,
        -0.02314939722418785,
        0.05413741618394852,
        0.05304192751646042,
        -0.05673025920987129,
        0.0020957046654075384,
        -0.05463852733373642,
        0.05155128985643387,
        -0.04920382425189018,
        0.05748876556754112,
        -0.059441938996315,
        0.04905673861503601,
        -0.055415745824575424,
        -0.03944433853030205,
        0.058311983942985535,
        0.015320367179811,
        -0.01658760942518711,
        0.04876953363418579,
        0.015476883389055729,
        0.059099871665239334,
        -0.03748501092195511,
        -0.057440947741270065,
        0.04427007585763931,
        0.0391855388879776,
        -0.019345544278621674,
        -0.05469565466046333,
        0.010092193260788918,
        -0.05938186123967171,
        -0.05569707602262497,
        -0.051066238433122635,
        -0.059542275965213776,
        0.05656058341264725,
        0.03987697511911392,
        -0.0270675215870142,
        0.05395156517624855,
        -0.02995067462325096,
        0.05573268607258797,
        0.04261069744825363,
        0.05729280039668083,
        0.059225745499134064,
        0.04811994731426239,
        0.026079965755343437,
        -0.019239330664277077,
        -0.04508601501584053,
        0.055231451988220215,
        -0.02631019428372383,
        -0.015586788766086102,
        0.0008880033856257796,
        -0.05822301283478737,
        -0.0076437280513346195,
        -0.04582739621400833,
        0.04689512029290199,
        -0.043925896286964417,
        -0.025565415620803833,
        -0.05051641911268234,
        0.04411642625927925,
        0.02644979953765869,
        -0.04240616038441658,
        0.025443552061915398,
        -0.05440997704863548,
        -0.017628828063607216,
        -0.05954810231924057,
        -0.04061764106154442,
        -0.059049464762210846,
        0.05935169756412506,
        0.04143208637833595,
        0.025700634345412254,
        0.0017499008681625128,
        0.05947070196270943,
        0.05950786918401718,
        0.05373449623584747,
        -0.03865465521812439,
        -0.014354350045323372,
        -0.03053445555269718,
        -0.023587830364704132,
        -0.039454877376556396,
        0.05570485070347786,
        0.016258353367447853,
        -0.055389031767845154,
        -0.027521561831235886,
        0.0159061998128891,
        0.05871718004345894,
        -0.053946353495121,
        -0.055334411561489105,
        -0.009877556003630161,
        -0.04953264445066452,
        -0.022137785330414772,
        -0.018450750038027763,
        -0.05346871167421341,
        -0.04010704904794693,
        -0.056145064532756805,
        -0.005164905916899443,
        -0.059374354779720306,
        -0.0056516630575060844,
        0.057883381843566895,
        0.057392336428165436,
        -0.023540273308753967,
        -0.05555124580860138,
        -0.006221073213964701,
        0.02724991738796234,
        -0.03943139314651489,
        -0.0507754348218441,
        -0.054993875324726105,
        -0.045123059302568436,
        -0.04818957671523094,
        0.0023163114674389362,
        0.053492471575737,
        -0.05141361057758331,
        -0.046369899064302444,
        -0.05845893919467926,
        -0.05517078936100006,
        -0.03691794350743294,
        0.0002826170530170202,
        0.014180535450577736,
        -0.047967854887247086,
        -0.02108486369252205,
        -0.058521635830402374,
        0.05014999955892563,
        0.03700827807188034,
        0.053645115345716476,
        0.05841999500989914,
        -0.018282873556017876,
        0.040303971618413925,
        -0.05111639201641083,
        -0.03658463805913925,
        0.047653988003730774,
        -0.05387401953339577,
        -0.05677172914147377,
        0.008054416626691818,
        -0.043864402920007706,
        0.03902421146631241,
        0.0496637262403965,
        0.05787312611937523,
        0.05412363260984421,
        0.02339785546064377,
        0.00594565412029624,
        0.0025891780387610197,
        -0.004359330050647259,
        0.03719630837440491,
        -0.04758704453706741,
        -0.05768439546227455,
        0.05490145832300186,
        -0.0575537383556366,
        -0.059396445751190186,
        -0.03770717233419418,
        0.054650064557790756,
        -0.004200662020593882,
        -0.05689697712659836,
        0.056274473667144775,
        -0.02413075976073742,
        -0.011828480288386345,
        -0.03301176801323891,
        0.05558956786990166,
        0.05262189358472824,
        0.05039532110095024,
        0.04823575168848038,
        -0.053190313279628754,
        -0.03645412251353264,
        0.02699722722172737,
        -0.0012147082015872002,
        0.03978943079710007,
        0.039119649678468704,
        -0.044981516897678375,
        0.05931149795651436,
        -0.058162301778793335,
        0.012861482799053192,
        -0.0322132371366024,
        0.05817009508609772,
        -0.05938200280070305,
        0.045224469155073166,
        0.05646054074168205,
        0.04448263719677925,
        0.04644197225570679,
        0.04447181150317192,
        0.05321625992655754,
        0.041807595640420914,
        0.05394188314676285,
        -0.03154844045639038,
        -0.007602665573358536,
        0.05784249305725098,
        -0.048571232706308365,
        -0.033324845135211945,
        -0.05669313669204712,
        -0.01422241609543562,
        -0.053161777555942535,
        -0.05830191820859909,
        -0.025295749306678772,
        0.03410107642412186,
        0.056678030639886856,
        -0.008327212184667587,
        -0.0467248409986496,
        -0.008494297042489052,
        -0.01162333507090807,
        0.022441819310188293,
        0.01367394533008337,
        0.059367284178733826,
        0.045414913445711136,
        0.03966644033789635,
        -0.019466787576675415,
        0.04923155531287193,
        -0.0377274714410305,
        -0.05893849581480026,
        -0.05333608016371727,
        0.05475763976573944,
        -0.018078424036502838,
        0.0377214178442955,
        0.0160005334764719,
        0.03558700531721115,
        0.059433214366436005,
        0.04993898794054985,
        0.05952785164117813,
        -0.04885517805814743,
        0.024610908702015877,
        0.03868236020207405,
        -0.03786316141486168,
        -0.05890699103474617,
        0.04627601057291031,
        -0.055206023156642914,
        0.015014163218438625,
        -0.01680494099855423,
        0.0445561483502388,
        -0.05489450693130493,
        -0.01920551434159279,
        -0.0582958459854126,
        -0.05770407244563103,
        0.052956100553274155,
        -0.01229948177933693,
        -0.04382603242993355,
        0.05766550451517105,
        -0.05406815931200981,
        0.05184740945696831,
        0.059484198689460754,
        0.04285021498799324,
        0.014781960286200047,
        -0.0007561800885014236,
        0.05852879211306572,
        0.0433475524187088,
        -0.00972606148570776,
        0.0013092425651848316,
        0.0349753312766552,
        0.005420849658548832,
        -0.02213631570339203,
        -0.02090580016374588,
        -0.05840405076742172,
        -0.044315941631793976,
        -0.05762743204832077,
        0.03480662405490875,
        0.05502010136842728,
        -0.001589733874425292,
        0.03169332444667816,
        0.05800845846533775,
        0.03290935605764389,
        0.05543353408575058,
        0.006657025311142206,
        -0.028867822140455246,
        0.02935364469885826,
        -0.0561092235147953,
        0.05006354674696922,
        0.03632655367255211,
        0.03620890527963638,
        0.03668743371963501,
        -0.03142848238348961,
        -0.057171206921339035,
        -0.059381790459156036,
        0.03845975548028946,
        -0.059038326144218445,
        -0.01745220273733139,
        0.020200831815600395,
        -0.05687447637319565,
        0.0478413887321949,
        0.001916958368383348,
        -0.05066932737827301,
        -0.05796187371015549,
        -0.05853867530822754,
        0.053143251687288284,
        0.05065906047821045,
        -0.0031924068462103605,
        -0.051623471081256866,
        -0.004627745598554611,
        -0.04412677884101868,
        -0.054011955857276917,
        0.05183892324566841,
        -0.038324885070323944,
        0.05102704465389252,
        -0.03915879875421524,
        -0.057960666716098785,
        -0.04142551124095917,
        -0.055816031992435455,
        0.05946324020624161,
        0.048777174204587936,
        -0.046238649636507034,
        0.05624677613377571,
        -0.0014304759679362178,
        0.058901261538267136,
        0.006136143580079079,
        0.05522562935948372,
        0.030154746025800705,
        -0.020512768998742104,
        -0.05798076093196869,
        -0.006668730638921261,
        0.05870180204510689,
        -0.059556711465120316,
        0.015284191817045212,
        -0.059077780693769455,
        -0.04400140792131424,
        0.057794682681560516,
        -0.059363845735788345,
        -0.05677787587046623,
        0.05504376441240311,
        -0.04470451548695564,
        0.048719413578510284,
        -0.05297889932990074,
        -0.05892244353890419,
        0.009075379930436611,
        0.05740939825773239,
        -0.05818608030676842,
        0.04810765013098717,
        0.0583517961204052,
        0.02101455256342888,
        -0.05703863874077797,
        0.03518081456422806,
        0.013851942494511604,
        -0.023484520614147186,
        -0.0015070451190695167,
        0.0013366562779992819,
        -0.03111913427710533,
        0.05800410360097885,
        0.030990328639745712,
        -0.03660012036561966,
        0.057607974857091904,
        -0.04428790137171745,
        0.05314552038908005,
        -0.015775563195347786,
        -0.05276131629943848,
        -0.05347336456179619,
        -0.05261556804180145,
        0.05956096947193146,
        0.04987455531954765,
        0.05330835282802582,
        0.0412047915160656,
        0.0566052682697773,
        0.05782463401556015,
        -0.058999478816986084,
        0.02802949771285057,
        0.00045531592331826687,
        -0.0440482422709465,
        -0.05026457831263542,
        0.05081450939178467,
        -0.03527086600661278,
        -0.05160864070057869,
        -0.013867883011698723,
        -0.04667399078607559,
        0.027597518637776375,
        0.0417085736989975,
        -0.04827221855521202,
        -0.029351234436035156,
        0.03170628845691681,
        0.03491802513599396,
        0.05932993441820145,
        0.05479990690946579,
        0.0587315708398819,
        -0.059506528079509735,
        -0.033031679689884186,
        -0.05645695701241493,
        0.0589878186583519,
        -0.02728171832859516,
        0.049019429832696915,
        0.058667223900556564,
        0.029193470254540443,
        -0.0008284550858661532,
        0.027957340702414513,
        -0.053977515548467636,
        -0.043844085186719894,
        0.058332283049821854,
        -0.05617566034197807,
        0.0034283041022717953,
        0.055559828877449036,
        -0.04146566614508629,
        -0.0014038145309314132,
        -0.05896622687578201,
        -0.02060501091182232,
        -0.047306228429079056,
        -0.058685172349214554,
        0.024549391120672226,
        0.03312667831778526,
        -0.0229289960116148,
        -0.05481531471014023,
        0.05600893124938011,
        0.0528935082256794,
        0.010535264387726784,
        -0.02270093373954296,
        -0.05753876268863678,
        -0.008946106769144535,
        0.058781303465366364,
        0.03730568289756775,
        0.05019122362136841,
        -0.043015945702791214,
        -0.04563271999359131,
        0.044583044946193695,
        0.05388333648443222,
        0.03305750712752342,
        -0.009769059717655182,
        0.024957377463579178,
        -0.04187322407960892,
        -0.014218945056200027,
        -0.02321888692677021,
        0.05745992064476013,
        -0.05199224874377251,
        -0.006998077966272831,
        -0.04430077224969864,
        0.05723848193883896,
        0.02328208088874817,
        -0.058805204927921295,
        -0.058211587369441986,
        -0.054365985095500946,
        -0.05465004965662956,
        0.01294640451669693,
        0.009243585169315338,
        -0.05722365155816078,
        0.015451939776539803,
        -0.059439461678266525,
        -0.02907111868262291,
        0.04691099002957344,
        0.0005379495560191572,
        -0.010990169830620289,
        0.05471096560359001,
        -0.049925416707992554,
        -0.05033755674958229,
        -0.01668086275458336,
        0.05477754771709442,
        0.05542999133467674,
        -0.052834440022706985,
        0.037414468824863434,
        0.05795411765575409,
        0.05231458321213722,
        0.0586920790374279,
        0.00978420302271843,
        0.05104539915919304,
        0.058600831776857376,
        -0.05953051894903183,
        0.05442099645733833,
        -0.05186326429247856,
        -0.001947917276993394,
        0.0527351051568985,
        0.05825551971793175,
        0.035591356456279755,
        -0.008991092443466187,
        -0.042474549263715744,
        0.0036836634390056133,
        0.04723041504621506,
        -0.05746480077505112,
        0.00662944046780467,
        0.0007175102364271879,
        -0.03775126487016678,
        -0.04680028557777405,
        -0.05822930857539177,
        0.0592045821249485,
        0.05932161211967468,
        -0.05942869931459427,
        0.05469825491309166,
        -0.057236116379499435,
        -0.05682853236794472,
        -0.00822695717215538,
        0.02931009791791439,
        0.0562903992831707,
        0.056236110627651215,
        0.058089908212423325,
        -0.007913527078926563,
        0.015920666977763176,
        -0.058207493275403976,
        -0.025333696976304054,
        -0.04529248923063278,
        -0.041935648769140244,
        -0.04131351411342621,
        0.052777569741010666,
        0.04647419974207878,
        -0.045146238058805466,
        0.057723432779312134,
        0.048341184854507446,
        -0.016619892790913582,
        -0.04811590537428856,
        -0.04626522958278656,
        -0.016820257529616356,
        -0.058284543454647064,
        0.05006106197834015,
        0.042215149849653244,
        0.05836644768714905,
        0.03983299434185028,
        0.016881100833415985,
        -0.04118462651968002,
        0.054725367575883865,
        0.05533353239297867,
        0.03552453592419624,
        0.03123236820101738,
        -0.04284994304180145,
        -0.018502546474337578,
        0.05650559067726135,
        0.0017814860912039876,
        -0.041470613330602646,
        0.002790476894006133,
        -0.04602112993597984,
        -0.05900876596570015,
        0.04780920594930649,
        0.05951838940382004
    ],
    [
        0.021108269691467285,
        -0.057233814150094986,
        0.046996425837278366,
        0.024707119911909103,
        0.05571884289383888,
        -0.044615473598241806,
        0.05551712587475777,
        -0.05034533888101578,
        -0.006805022247135639,
        0.03792697563767433,
        -0.006292888429015875,
        -0.015746422111988068,
        -0.055233776569366455,
        -0.05335050821304321,
        -0.0567181259393692,
        0.04965398833155632,
        -0.0577080063521862,
        0.04619419574737549,
        -0.05783262103796005,
        -0.05346372723579407,
        -0.047824665904045105,
        0.055259641259908676,
        0.023780548945069313,
        0.03198892995715141,
        0.057586509734392166,
        -0.05041595175862312,
        -0.03867996484041214,
        0.05431143939495087,
        0.034164879471063614,
        -0.028378553688526154,
        0.057737626135349274,
        0.02953914739191532,
        -0.045028772205114365,
        -0.05773404985666275,
        0.013636534102261066,
        -0.04193612560629845,
        -0.04806890711188316,
        -0.03339550271630287,
        -0.05693024769425392,
        0.05394750460982323,
        0.056124769151210785,
        -0.012141709215939045,
        0.05557248741388321,
        -0.057076532393693924,
        0.0566580668091774,
        0.039243023842573166,
        0.024793332442641258,
        0.05760922282934189,
        0.056644558906555176,
        0.010126241482794285,
        0.04013681039214134,
        -0.052840787917375565,
        0.057801682502031326,
        0.03889785706996918,
        -0.04451383650302887,
        0.05402788519859314,
        -0.021849144250154495,
        -0.017201760783791542,
        0.05757055804133415,
        -0.03487337380647659,
        -0.05464468523859978,
        -0.014202305115759373,
        -0.05394521728157997,
        0.05610324814915657,
        0.04900788888335228,
        -0.024798717349767685,
        0.025559382513165474,
        -0.05410531535744667,
        0.057384684681892395,
        -0.057837072759866714,
        -0.05776018276810646,
        -0.05737031623721123,
        -0.00781293399631977,
        -0.03142267465591431,
        -0.004957177210599184,
        -0.009643429890275002,
        0.05155478045344353,
        0.0577830970287323,
        0.0006771846092306077,
        0.023458484560251236,
        -0.026866771280765533,
        -0.021349137648940086,
        -0.04121607542037964,
        0.01817915216088295,
        -0.046430353075265884,
        0.04552222043275833,
        0.03732176870107651,
        0.057533182203769684,
        -0.013772550970315933,
        0.047480709850788116,
        -0.05726651847362518,
        0.0028303824365139008,
        0.018048742786049843,
        -0.04630642011761665,
        -0.031784556806087494,
        0.04715337976813316,
        -0.035592857748270035,
        -0.05647488683462143,
        -0.05727862939238548,
        -0.05172782018780708,
        -0.057820163667201996,
        0.0024824917782098055,
        0.05564370006322861,
        0.04406208172440529,
        0.03787587210536003,
        -0.05691327899694443,
        -0.057069893926382065,
        0.051889367401599884,
        0.014507874846458435,
        0.028849724680185318,
        -0.05747322365641594,
        -0.0490717887878418,
        -0.05508231371641159,
        -0.05437833443284035,
        0.04513011872768402,
        -0.010068435221910477,
        0.019526643678545952,
        -0.044976137578487396,
        -0.05708044394850731,
        -0.03270779177546501,
        -0.03971564769744873,
        -0.04059791564941406,
        0.02195986919105053,
        -0.023341123014688492,
        -0.056512996554374695,
        -0.009792814031243324,
        -0.011483825743198395,
        0.04017432779073715,
        0.05697484314441681,
        -0.05514984205365181,
        -0.05168900638818741,
        -0.018975546583533287,
        0.05491355061531067,
        0.056110408157110214,
        -0.032636675983667374,
        -0.05418753623962402,
        0.03200799971818924,
        -0.057644426822662354,
        0.014810113236308098,
        0.057820070534944534,
        -0.037542425096035004,
        0.04698307067155838,
        0.021326396614313126,
        0.017494654282927513,
        0.05087475851178169,
        0.007378007285296917,
        0.05759558454155922,
        -0.017231667414307594,
        -0.0473763570189476,
        0.020458536222577095,
        -0.05407196655869484,
        -0.05779756233096123,
        0.003937778994441032,
        -0.030676648020744324,
        -0.05565444380044937,
        -0.05146116390824318,
        0.05538813769817352,
        -0.03350037336349487,
        0.05064920708537102,
        0.032304320484399796,
        0.05780976638197899,
        0.05451707914471626,
        0.04940524697303772,
        0.02792271226644516,
        -0.0544140487909317,
        -0.051363345235586166,
        0.01594197005033493,
        -0.05775231495499611,
        0.04450232908129692,
        0.05374833568930626,
        -0.038573767989873886,
        0.05734623596072197,
        -0.04633956030011177,
        -0.04798201844096184,
        -0.020540745928883553,
        0.05773249268531799,
        -0.046125173568725586,
        0.051796805113554,
        0.049397457391023636,
        0.056597061455249786,
        0.018238995224237442,
        0.057770825922489166,
        0.05464974045753479,
        0.008106079883873463,
        0.01634298451244831,
        -0.05556216835975647,
        -0.041966088116168976,
        0.05275988206267357,
        -0.03728806599974632,
        -0.05647659674286842,
        -0.05612415075302124,
        0.01972424052655697,
        0.005239962600171566,
        -0.04398027062416077,
        0.02244812808930874,
        -0.03745507821440697,
        0.055180031806230545,
        -0.05151377618312836,
        -0.05131864547729492,
        0.037052810192108154,
        -0.014981981366872787,
        0.05733653903007507,
        0.026293067261576653,
        0.04236835613846779,
        0.04723580926656723,
        0.0327090360224247,
        -0.020099861547350883,
        0.05308178812265396,
        -0.01740322634577751,
        0.04371730983257294,
        -0.05229875072836876,
        0.05100679397583008,
        0.01931539550423622,
        0.04201113432645798,
        -0.04760796204209328,
        0.025119170546531677,
        0.055930741131305695,
        0.055535804480314255,
        0.05780249834060669,
        -0.056811556220054626,
        0.055725861340761185,
        -0.05524192750453949,
        -0.03389779105782509,
        -0.05783500149846077,
        -0.04330524802207947,
        -0.046120140701532364,
        0.03918283060193062,
        -0.04282829165458679,
        -0.04707620292901993,
        -0.045106884092092514,
        -0.056643709540367126,
        -0.01828913576900959,
        -0.05334480479359627,
        0.04572735354304314,
        0.049701254814863205,
        0.03646397590637207,
        0.03797106817364693,
        0.05165482684969902,
        0.012434322386980057,
        0.05659734457731247,
        -0.005653089378029108,
        0.005447383504360914,
        0.04875180497765541,
        -0.007151525467634201,
        0.045233312994241714,
        -0.04306305944919586,
        -0.05429209768772125,
        0.056540798395872116,
        0.010729899629950523,
        0.052618734538555145,
        -0.05659101530909538,
        -0.05696015805006027,
        -0.03799246996641159,
        -0.05530869588255882,
        -0.0011686563957482576,
        0.03917115926742554,
        0.010141681879758835,
        0.046558476984500885,
        0.0570392869412899,
        0.05772855505347252,
        0.057186782360076904,
        -0.0030362883117049932,
        -0.0006331190234050155,
        0.04424738138914108,
        -0.05068376660346985,
        0.012281636707484722,
        0.039575546979904175,
        0.016860654577612877,
        0.056829217821359634,
        0.013289752416312695,
        -0.05010513961315155,
        -0.057831745594739914,
        0.05513709411025047,
        -0.057107534259557724,
        -0.05578101798892021,
        -0.030619002878665924,
        -0.053228095173835754,
        0.036516107618808746,
        -0.005424003582447767,
        -0.002944272244349122,
        -0.038789670914411545,
        0.05197236314415932,
        0.048345547169446945,
        0.05750201269984245,
        -0.057349417358636856,
        -0.041600920259952545,
        0.052614353597164154,
        -0.03873547911643982,
        -0.049700088798999786,
        -0.013394118286669254,
        -0.005221731495112181,
        0.03044692985713482,
        0.05684991925954819,
        -0.033230893313884735,
        -0.03565184772014618,
        -0.04239917919039726,
        0.05783051624894142,
        0.00011689821985783055,
        -0.05658043920993805,
        0.055252786725759506,
        -0.057742293924093246,
        0.04513804614543915,
        0.046495866030454636,
        0.05670692026615143,
        -0.048757798969745636,
        -0.05499924346804619,
        -0.04486076161265373,
        0.024341491982340813,
        0.04552755132317543,
        -0.05493781343102455,
        0.04008346050977707,
        -0.057288236916065216,
        -0.05666578561067581,
        0.05725385993719101,
        -0.05779236927628517,
        -0.04667729511857033,
        0.04892469197511673,
        -0.025545509532094002,
        0.002470302162691951,
        -0.05568062514066696,
        -0.04940750449895859,
        -0.0032470962032675743,
        -0.052164092659950256,
        -0.05783684179186821,
        0.05316381901502609,
        0.05248703807592392,
        0.025762459263205528,
        -0.05615495517849922,
        0.0275277029722929,
        -0.00678223418071866,
        0.046844303607940674,
        0.056244879961013794,
        -0.023838965222239494,
        -0.04482942819595337,
        0.05293856933712959,
        0.014586679637432098,
        -0.01781994104385376,
        0.0578312985599041,
        -0.05271421745419502,
        0.05648251995444298,
        -0.0407358855009079,
        -0.057396288961172104,
        -0.0164021048694849,
        0.010360454209148884,
        0.05783802270889282,
        0.05167246237397194,
        -0.01344263181090355,
        0.03585594519972801,
        -0.026662791147828102,
        0.05376232787966728,
        -0.05783804878592491,
        0.04794676601886749,
        0.03261563181877136,
        0.015270461328327656,
        -0.05757774040102959,
        0.05680806562304497,
        -0.02032860554754734,
        -0.05648220703005791,
        -0.01017811056226492,
        -0.05383772403001785,
        -0.01191927120089531,
        0.040578801184892654,
        -0.0538141094148159,
        -0.02349044755101204,
        -0.03333994746208191,
        -0.016355378553271294,
        0.057587046176195145,
        0.026670366525650024,
        0.020371228456497192,
        -0.05783764645457268,
        -0.053777892142534256,
        -0.05687304958701134,
        0.044412385672330856,
        -0.048147425055503845,
        0.02078779973089695,
        0.05207894742488861,
        -0.01335117593407631,
        0.03163069486618042,
        -0.056083112955093384,
        0.03396562114357948,
        -0.04825558140873909,
        0.05652459338307381,
        -0.05084687098860741,
        0.048008993268013,
        0.05716347694396973,
        -0.048817556351423264,
        0.0006636854377575219,
        -0.0571066252887249,
        -0.05360448360443115,
        -0.03581107780337334,
        -0.05513109639286995,
        0.015681613236665726,
        0.05136194825172424,
        0.03035283461213112,
        -0.05223158746957779,
        0.04960479959845543,
        0.053426723927259445,
        -0.05715063586831093,
        -0.023664532229304314,
        -0.048396311700344086,
        -0.05192023888230324,
        0.056703776121139526,
        0.0568234845995903,
        -0.020021667703986168,
        -0.04544392600655556,
        -0.025874361395835876,
        0.05249008908867836,
        0.026870183646678925,
        0.05560016259551048,
        -0.022850660607218742,
        0.054954804480075836,
        0.04760616645216942,
        0.006981750018894672,
        0.008128715679049492,
        0.05209371820092201,
        0.0020705724600702524,
        0.05233808979392052,
        -0.021377526223659515,
        0.0577845424413681,
        -0.0048719048500061035,
        -0.056277159601449966,
        -0.05776505544781685,
        0.04770640656352043,
        -0.056637197732925415,
        0.057616204023361206,
        -0.033084139227867126,
        -0.05637989193201065,
        0.026699742302298546,
        -0.05753311142325401,
        -0.02873554266989231,
        0.040079567581415176,
        0.0548117496073246,
        -0.05672072991728783,
        0.0578271746635437,
        -0.03162471950054169,
        -0.05704331398010254,
        -0.04387601837515831,
        0.05673477426171303,
        0.015546930953860283,
        -0.05447091534733772,
        -0.053264714777469635,
        0.05756157264113426,
        0.05252685770392418,
        0.053620845079422,
        0.017189787700772285,
        0.01986801065504551,
        0.013974511995911598,
        -0.05777067318558693,
        0.034372538328170776,
        -0.05747925862669945,
        -0.035538192838430405,
        0.051299791783094406,
        0.05262879282236099,
        0.048264190554618835,
        0.055559635162353516,
        -0.004281879868358374,
        -0.040697019547224045,
        -0.026895372197031975,
        0.014169980771839619,
        0.055886074900627136,
        -0.02665199525654316,
        -0.030441513285040855,
        -0.0536794476211071,
        -0.031796760857105255,
        0.05571768805384636,
        0.05771786347031593,
        -0.05781850963830948,
        0.057626061141490936,
        -0.026356710121035576,
        -0.05729950591921806,
        0.029023034498095512,
        0.05575517937541008,
        0.05089731886982918,
        0.02401605434715748,
        0.057024020701646805,
        0.007403522729873657,
        0.02577216364443302,
        0.00676959753036499,
        -0.029142074286937714,
        -0.052760470658540726,
        -0.05380336940288544,
        0.046797528862953186,
        0.05237127095460892,
        0.009010317735373974,
        -0.04402802512049675,
        0.049837879836559296,
        0.05644873157143593,
        -0.0360710583627224,
        -0.04759077727794647,
        -0.018652962520718575,
        -0.02780812792479992,
        -0.05220218747854233,
        0.03733976557850838,
        0.0378631167113781,
        0.05782121419906616,
        -0.0398002564907074,
        -0.05475723743438721,
        -0.053599607199430466,
        0.02508181892335415,
        0.01849956251680851,
        -0.0541231632232666,
        -0.04771934077143669,
        -0.05750661715865135,
        -0.05353693664073944,
        -0.04793963581323624,
        0.04654250293970108,
        0.04086604341864586,
        0.011433894746005535,
        -0.056769389659166336,
        -0.0044275689870119095,
        -0.0232684388756752,
        0.05668097361922264
    ],
    [
        0.00793231651186943,
        -0.043593429028987885,
        0.054342739284038544,
        -0.010622816160321236,
        0.01811736449599266,
        0.04192907363176346,
        0.05424586310982704,
        -0.05382766202092171,
        0.030096126720309258,
        -0.05358720198273659,
        -0.009600114077329636,
        -0.04222404584288597,
        -0.05341949686408043,
        -0.047602735459804535,
        -0.04841742664575577,
        0.019269419834017754,
        -0.04731050878763199,
        0.05424606800079346,
        -0.054098211228847504,
        0.030962495133280754,
        -0.03485426679253578,
        0.02638750895857811,
        -0.019419504329562187,
        -0.05372617021203041,
        0.026990806683897972,
        0.053482625633478165,
        0.04952719807624817,
        0.054347001016139984,
        -0.05234406888484955,
        -0.05276374891400337,
        0.04009929299354553,
        0.03525230288505554,
        0.004768237937241793,
        -0.05430928245186806,
        0.042112234979867935,
        -0.05436870455741882,
        -0.05317603424191475,
        0.04698014259338379,
        -0.05437105894088745,
        -0.0017763915238901973,
        0.0433669276535511,
        0.03916573151946068,
        0.043344877660274506,
        -0.04962131381034851,
        -0.04435654729604721,
        0.0464758425951004,
        -0.018455011770129204,
        0.05386766791343689,
        0.05362589657306671,
        -0.04465288668870926,
        0.052428681403398514,
        0.046125296503305435,
        -0.05175149440765381,
        0.05226045474410057,
        -0.05310829356312752,
        0.046365298330783844,
        -0.05435442924499512,
        -0.01358773559331894,
        -0.025319375097751617,
        -0.05322979390621185,
        -0.05135979503393173,
        0.031882427632808685,
        -0.05309814587235451,
        0.05360587313771248,
        0.04542280733585358,
        -0.05262594670057297,
        -0.03744838386774063,
        -0.02584986202418804,
        0.02643146738409996,
        -0.05047000199556351,
        -0.05258549004793167,
        -0.04722379148006439,
        0.052873216569423676,
        0.053772084414958954,
        0.04787151515483856,
        -0.0038085984997451305,
        0.04341280460357666,
        0.054375503212213516,
        0.050960250198841095,
        -0.05309450253844261,
        -0.001678613480180502,
        -0.05286065489053726,
        -0.05302593111991882,
        0.053856104612350464,
        0.044878553599119186,
        0.01664605922996998,
        0.053717054426670074,
        0.01536048948764801,
        0.053797733038663864,
        0.050244398415088654,
        -0.03666157275438309,
        -0.05273525416851044,
        0.003800481790676713,
        -0.04102946072816849,
        -0.037338986992836,
        0.05078483372926712,
        0.04680890962481499,
        -0.049253884702920914,
        0.024761555716395378,
        0.04198566824197769,
        -0.05314105004072189,
        -0.05176227167248726,
        0.048250120133161545,
        0.05425652861595154,
        0.05413849279284477,
        -0.054362379014492035,
        -0.05437081679701805,
        0.050999391824007034,
        -0.048548854887485504,
        -0.04205665737390518,
        -0.054330769926309586,
        0.047160178422927856,
        -0.04651237279176712,
        -0.0357482023537159,
        0.05107249319553375,
        -0.045530617237091064,
        -0.023803530260920525,
        -0.03331495448946953,
        0.0434214323759079,
        -0.05371849983930588,
        0.0028886087238788605,
        -0.04067448899149895,
        0.04703861102461815,
        -0.05411257594823837,
        -0.05387642979621887,
        0.045428596436977386,
        -0.040311865508556366,
        -0.015501891262829304,
        0.05431663617491722,
        0.005821599625051022,
        -0.001984513131901622,
        -0.052000559866428375,
        -0.053547944873571396,
        0.054265186190605164,
        -0.024532821029424667,
        -0.050618190318346024,
        0.045213308185338974,
        -0.021066157147288322,
        -0.011474727652966976,
        0.05392468720674515,
        0.04992298781871796,
        0.016192365437746048,
        0.04794486239552498,
        0.05427664890885353,
        0.041796114295721054,
        -0.04687320813536644,
        0.053828634321689606,
        -0.0471591018140316,
        -0.05320410057902336,
        0.025950655341148376,
        -0.04850384220480919,
        -0.054371342062950134,
        0.04792692884802818,
        0.01743367128074169,
        0.05407843738794327,
        -0.03649790212512016,
        -0.05410829931497574,
        -0.044541191309690475,
        -0.012973500415682793,
        -0.04598626866936684,
        0.048246242105960846,
        0.04999111220240593,
        0.053232550621032715,
        0.031160224229097366,
        -0.005513421725481749,
        -0.008807324804365635,
        -0.02605300396680832,
        -0.052017536014318466,
        -0.03407248482108116,
        0.03755239024758339,
        0.05314379557967186,
        0.05070918798446655,
        -0.05370655655860901,
        0.05415099859237671,
        0.05268188938498497,
        0.03332573547959328,
        0.007515265606343746,
        -0.03238916024565697,
        0.05417098477482796,
        -0.04328402131795883,
        -0.051894623786211014,
        0.05372398719191551,
        0.046285711228847504,
        -0.05413945019245148,
        -0.05239809304475784,
        -0.053842075169086456,
        0.03645263612270355,
        0.04423339292407036,
        0.054262883961200714,
        -0.054222214967012405,
        -0.054011449217796326,
        -0.024770591408014297,
        0.03716710954904556,
        0.019718242809176445,
        -0.044835399836301804,
        -0.004735453054308891,
        0.04467064514756203,
        0.034480590373277664,
        -0.05022884160280228,
        0.03702437877655029,
        0.053623005747795105,
        0.052744388580322266,
        0.014996038749814034,
        -0.005942791700363159,
        0.007347973994910717,
        -0.054289810359478,
        0.04963521286845207,
        0.03598005324602127,
        -0.0541904978454113,
        0.050542134791612625,
        -0.051278602331876755,
        0.05005999282002449,
        0.05089930072426796,
        0.054161883890628815,
        -0.052556030452251434,
        -0.03466973826289177,
        0.05349939689040184,
        0.027659956365823746,
        0.054359789937734604,
        -0.03530973196029663,
        -0.01728011481463909,
        -0.000984843005426228,
        -0.040602315217256546,
        -0.05330625921487808,
        0.009564773179590702,
        0.042744413018226624,
        0.049735475331544876,
        0.04843985289335251,
        -0.05426933988928795,
        -0.05300597473978996,
        -0.05310460180044174,
        -0.048722948879003525,
        -0.05373328924179077,
        0.04537571594119072,
        -0.04699992015957832,
        0.038276128470897675,
        0.053883008658885956,
        -0.03644915297627449,
        0.05238603428006172,
        0.054308243095874786,
        0.005359690170735121,
        -0.043997883796691895,
        -0.042796459048986435,
        -0.04904036596417427,
        0.05354353040456772,
        -0.052538346499204636,
        -0.05165062099695206,
        -0.04728880897164345,
        -0.021156206727027893,
        0.008964006789028645,
        -0.028375163674354553,
        -0.04439195245504379,
        0.0036483085714280605,
        -0.053437020629644394,
        -0.05406248942017555,
        0.05215989425778389,
        -0.039195671677589417,
        0.033297810703516006,
        0.05190092697739601,
        0.05235917866230011,
        0.05184589698910713,
        -0.05427958816289902,
        -0.02587493695318699,
        0.05331507697701454,
        -0.05435989424586296,
        0.05422096699476242,
        -0.047098297625780106,
        0.05230436101555824,
        -0.0047727613709867,
        -0.051432110369205475,
        0.05167965218424797,
        -0.05411576107144356,
        0.054344017058610916,
        -0.05254460871219635,
        -0.054311927407979965,
        -0.0006722111138515174,
        -0.03007591888308525,
        -0.028616318479180336,
        -0.05413978919386864,
        0.04775118827819824,
        -0.05234723910689354,
        0.05117684230208397,
        -0.030947664752602577,
        0.05434523895382881,
        -0.00400489242747426,
        0.0359952338039875,
        -0.04657363146543503,
        0.044187892228364944,
        0.03404435142874718,
        0.047167420387268066,
        -0.020886920392513275,
        0.045145999640226364,
        -0.05292274057865143,
        0.031102601438760757,
        0.008799800649285316,
        -0.05420338734984398,
        0.05436904355883598,
        -0.01136014610528946,
        -0.054345060139894485,
        0.054008692502975464,
        0.05174880474805832,
        0.029274823144078255,
        -0.015975166112184525,
        0.054366063326597214,
        -0.05395697057247162,
        -0.014890254475176334,
        0.02359900064766407,
        0.05156341940164566,
        0.04309305176138878,
        -0.051662251353263855,
        0.05280446261167526,
        -0.0279600378125906,
        0.05368446931242943,
        0.05350937694311142,
        -0.05430186539888382,
        -0.05340905860066414,
        0.04756695777177811,
        -0.054246027022600174,
        0.05387294664978981,
        0.016463372856378555,
        0.05199489742517471,
        -0.010723765008151531,
        0.05379290506243706,
        -0.054316483438014984,
        0.047857049852609634,
        0.05387035384774208,
        -0.03274301812052727,
        -0.054006025195121765,
        0.05435091629624367,
        -0.05407974123954773,
        0.052905794233083725,
        -0.04698944464325905,
        -0.04763538017868996,
        0.04162055253982544,
        0.0539303794503212,
        0.05432324483990669,
        0.01096268743276596,
        0.0524413138628006,
        0.0005894601345062256,
        0.024586141109466553,
        -0.040409497916698456,
        -0.012556586414575577,
        -0.043728165328502655,
        -0.05289682745933533,
        0.054342009127140045,
        -0.0319322906434536,
        0.04101645201444626,
        0.0541844516992569,
        0.049859169870615005,
        0.05338165909051895,
        0.05152934789657593,
        0.005604475270956755,
        0.03341206908226013,
        -0.047714605927467346,
        -0.05410010367631912,
        0.0013091906439512968,
        -0.05297554284334183,
        -0.0440024808049202,
        -0.04434024915099144,
        -0.04313953220844269,
        0.051158275455236435,
        -0.006283235270529985,
        -0.05214116722345352,
        -0.030485505238175392,
        0.012481640093028545,
        -0.04736051708459854,
        0.0542684942483902,
        -0.053346388041973114,
        0.053465262055397034,
        -0.046251777559518814,
        -0.041897792369127274,
        -0.04742855578660965,
        0.04400864616036415,
        0.04229282587766647,
        -0.008549165911972523,
        0.053816817700862885,
        -0.05228964611887932,
        -0.045685261487960815,
        0.054229576140642166,
        -0.0438835583627224,
        -0.04724776744842529,
        0.03692960739135742,
        -0.05284399911761284,
        0.052551787346601486,
        0.015097272582352161,
        -0.05428366735577583,
        -0.052104704082012177,
        -0.049651503562927246,
        -0.04653055593371391,
        0.046784255653619766,
        -0.05418256297707558,
        0.04400916025042534,
        -0.04753531888127327,
        -0.05355124920606613,
        0.052718184888362885,
        0.053976915776729584,
        0.05374665930867195,
        0.01721206307411194,
        -0.030522674322128296,
        -0.05253392457962036,
        -0.0382518470287323,
        0.05424923822283745,
        -0.050094760954380035,
        0.054237280040979385,
        0.031471025198698044,
        -0.051890477538108826,
        0.049733661115169525,
        -0.04607177898287773,
        0.052634160965681076,
        0.02770153246819973,
        -0.030368594452738762,
        -0.03802323341369629,
        0.04480985179543495,
        0.04459084942936897,
        0.05201450735330582,
        -0.05390230566263199,
        0.011013936251401901,
        -0.009610028006136417,
        0.052526991814374924,
        -0.014811795204877853,
        -0.028373820707201958,
        -0.04100469499826431,
        -0.053079597651958466,
        -0.05419951304793358,
        -0.05123772472143173,
        -0.01566128060221672,
        -0.05410737916827202,
        -0.04976553097367287,
        -0.05128886178135872,
        -0.05430963262915611,
        0.05396082252264023,
        0.012071151286363602,
        -0.009879305958747864,
        0.03586458042263985,
        -0.05433541163802147,
        -0.052298322319984436,
        -0.05433151125907898,
        0.03934140503406525,
        0.035019926726818085,
        -0.05424632877111435,
        -0.05207240208983421,
        0.05418583005666733,
        -0.019902538508176804,
        0.054142702370882034,
        0.054348308593034744,
        0.020217442885041237,
        -0.0222349651157856,
        -0.054321467876434326,
        -0.010981610044836998,
        0.029087724164128304,
        -0.05205438658595085,
        0.04890885949134827,
        0.044437259435653687,
        0.05345204845070839,
        0.052504025399684906,
        -0.0040656025521457195,
        0.046550896018743515,
        -0.03530837595462799,
        0.0473121739923954,
        0.053290389478206635,
        0.04348868876695633,
        -0.05431602895259857,
        -0.04236186668276787,
        -0.054304737597703934,
        0.03193724900484085,
        0.05431854724884033,
        -0.05384242162108421,
        0.054309722036123276,
        -0.03882971033453941,
        -0.05394058674573898,
        -0.029603667557239532,
        -0.05129651352763176,
        0.053763002157211304,
        0.03317718952894211,
        0.04230530187487602,
        -0.028130244463682175,
        0.0073510124348104,
        0.049963850528001785,
        -0.030424969270825386,
        -0.04947385564446449,
        -0.04986129701137543,
        0.05355250462889671,
        0.020199552178382874,
        0.009663240984082222,
        -0.018678700551390648,
        0.053792115300893784,
        -0.04139520972967148,
        -0.025623464956879616,
        -0.05430000647902489,
        0.053205039352178574,
        -0.011865007691085339,
        -0.0541512593626976,
        0.051800552755594254,
        0.04915371537208557,
        -0.019091768190264702,
        -0.020219162106513977,
        -0.051378313452005386,
        0.02821224182844162,
        0.05145857483148575,
        0.02428760752081871,
        0.001662788214161992,
        0.050910308957099915,
        -0.053733937442302704,
        -0.054327186197042465,
        0.03084385395050049,
        0.05047977715730667,
        0.030598627403378487,
        0.0025593701284378767,
        0.04900948703289032,
        0.0010397160658612847,
        -0.04294073209166527,
        0.025454696267843246
    ],
    [
        -0.05121706426143646,
        -0.05617525428533554,
        0.04737034812569618,
        0.018322709947824478,
        -0.045347653329372406,
        -0.05549975857138634,
        -0.03437405452132225,
        -0.03634004667401314,
        0.05315155163407326,
        -0.004606425762176514,
        0.018361886963248253,
        -0.04989248886704445,
        -0.04115651547908783,
        -0.048696380108594894,
        -0.055468469858169556,
        0.0017432376043871045,
        -0.046267904341220856,
        0.05518513545393944,
        -0.05616722255945206,
        -0.024418992921710014,
        -0.0373203344643116,
        -0.04537156596779823,
        0.056068092584609985,
        0.0469258613884449,
        0.05466875806450844,
        0.006012805737555027,
        0.050315968692302704,
        -0.02943144366145134,
        -0.047182347625494,
        -0.010594211518764496,
        0.03537362441420555,
        0.052986983209848404,
        0.04334298521280289,
        -0.056159887462854385,
        0.05589602142572403,
        -0.05600256100296974,
        -0.04736602306365967,
        -0.04490649700164795,
        -0.056161973625421524,
        -0.05222909897565842,
        0.052578117698431015,
        -0.0043651205487549305,
        0.048859525471925735,
        0.002483923686668277,
        -0.011982717551290989,
        0.05003751069307327,
        -0.027590347453951836,
        0.05479195713996887,
        0.0555058978497982,
        0.05481690913438797,
        0.05570751056075096,
        0.05440536141395569,
        0.05078057944774628,
        -0.05536504089832306,
        -0.01941545680165291,
        0.05602145195007324,
        -0.054923899471759796,
        0.023606963455677032,
        0.055818840861320496,
        -0.051998820155858994,
        -0.054335497319698334,
        -0.0059886109083890915,
        -0.05437293276190758,
        0.05575110763311386,
        -0.049521297216415405,
        -0.05372432619333267,
        -0.05285065993666649,
        -0.054647915065288544,
        0.056170593947172165,
        -0.05554993078112602,
        -0.0531027615070343,
        -0.028490908443927765,
        0.021006394177675247,
        0.020810086280107498,
        0.05572967603802681,
        -0.00485949357971549,
        0.01867857202887535,
        0.05617421492934227,
        -0.05410746857523918,
        -0.055907461792230606,
        -0.027334420010447502,
        -0.04878097400069237,
        -0.03746962174773216,
        0.05328890308737755,
        -0.030870921909809113,
        0.05380183458328247,
        -0.039849184453487396,
        0.05615134909749031,
        -0.0239188801497221,
        0.0489225797355175,
        -0.05302278697490692,
        0.025026507675647736,
        -0.034767139703035355,
        -0.05319362133741379,
        -0.02008569799363613,
        0.041710831224918365,
        -0.03929244354367256,
        -0.05592205747961998,
        -0.05298076942563057,
        0.0261837188154459,
        -0.056168537586927414,
        0.054321929812431335,
        0.038678526878356934,
        0.024367917329072952,
        0.05013137683272362,
        -0.04977132007479668,
        0.013279036618769169,
        0.05244830995798111,
        0.0032955175265669823,
        0.03671826794743538,
        -0.02230801433324814,
        -0.055038999766111374,
        -0.05385372042655945,
        -0.05484228581190109,
        0.004498319700360298,
        0.015537711791694164,
        -0.005202875938266516,
        -0.02214905060827732,
        -0.05459284409880638,
        -0.025261571630835533,
        0.030112197622656822,
        -0.05462334677577019,
        0.051472634077072144,
        -0.04945000261068344,
        -0.05153312906622887,
        0.05244029685854912,
        -0.008726253174245358,
        -0.055666275322437286,
        0.05582110211253166,
        0.010197021998465061,
        0.05029915273189545,
        0.026214908808469772,
        -0.045802414417266846,
        0.04005210101604462,
        -0.055263452231884,
        -0.05497457832098007,
        0.05555802583694458,
        -0.05227426812052727,
        0.03725982457399368,
        0.05597473680973053,
        -0.04587532579898834,
        0.03994179144501686,
        0.0535406619310379,
        0.0559975802898407,
        0.03256603702902794,
        -0.009165210649371147,
        0.05578986555337906,
        0.01607552170753479,
        -0.01352924108505249,
        0.053971678018569946,
        -0.05348026752471924,
        -0.055717263370752335,
        0.0558597706258297,
        0.04808139055967331,
        0.027754049748182297,
        -0.03812197223305702,
        -0.0487075075507164,
        -0.017046770080924034,
        0.03604574501514435,
        0.0010823425836861134,
        0.05539682134985924,
        -0.034876637160778046,
        0.05458320304751396,
        -0.05499648302793503,
        -0.02296636439859867,
        -0.004043919034302235,
        0.039097320288419724,
        -0.039134785532951355,
        -0.03527480363845825,
        0.04421372339129448,
        0.05608884617686272,
        0.0340772345662117,
        -0.007464076392352581,
        0.054946426302194595,
        -0.04170955345034599,
        0.04761441797018051,
        -0.013471332378685474,
        0.024218551814556122,
        -0.011650835163891315,
        0.04801687225699425,
        0.0101102190092206,
        0.056161168962717056,
        -0.04965745285153389,
        -0.05531483143568039,
        0.016881436109542847,
        -0.051957324147224426,
        0.03599552810192108,
        0.055780213326215744,
        -0.014063849113881588,
        -0.044511523097753525,
        -0.04881332442164421,
        0.014066079631447792,
        0.01626366190612316,
        0.05340912193059921,
        -0.048611119389534,
        -0.04362506419420242,
        0.05475752800703049,
        0.005522375926375389,
        -0.05597205087542534,
        0.0475899837911129,
        0.05599099025130272,
        0.0551757737994194,
        -0.004428273998200893,
        0.03217621520161629,
        -0.0026387623511254787,
        -0.006327364128082991,
        0.05553434416651726,
        0.05434019863605499,
        0.05203165113925934,
        0.052884336560964584,
        -0.044466786086559296,
        0.03771621361374855,
        0.018528591841459274,
        0.05571628734469414,
        0.0016159915830940008,
        -0.054833557456731796,
        0.009868715889751911,
        0.02747500315308571,
        0.05472942441701889,
        -0.029371358454227448,
        -0.04549376666545868,
        -0.046589065343141556,
        0.015376568771898746,
        -0.05292433872818947,
        0.021877525374293327,
        -0.048703595995903015,
        0.05558514595031738,
        0.022578947246074677,
        0.032119572162628174,
        -0.055625371634960175,
        -0.05343106761574745,
        0.03368689864873886,
        -0.009303605183959007,
        0.053938813507556915,
        -0.02115001156926155,
        0.006697280798107386,
        0.0530584417283535,
        0.055039528757333755,
        0.0550522617995739,
        0.05541436746716499,
        -0.03235994651913643,
        -0.005964153446257114,
        0.05019071698188782,
        -0.015510404482483864,
        0.04778463765978813,
        -0.022287506610155106,
        0.048719990998506546,
        0.047992806881666183,
        0.04812361299991608,
        0.03511206433176994,
        -0.04143799841403961,
        -0.0542227141559124,
        0.033420663326978683,
        -0.05521278828382492,
        -0.03792103752493858,
        0.026128534227609634,
        0.019385529682040215,
        0.04444937780499458,
        0.05124480649828911,
        0.056146763265132904,
        -0.04592839628458023,
        -0.05566750466823578,
        0.03302382677793503,
        0.032624851912260056,
        -0.05417410656809807,
        0.05160272866487503,
        0.03792872279882431,
        0.05105171725153923,
        -0.017190122976899147,
        0.01496601291000843,
        -0.0050982204265892506,
        -0.05617339909076691,
        0.05401439592242241,
        0.002134413691237569,
        -0.05613547936081886,
        0.03800210729241371,
        -0.05358315631747246,
        -0.05451062321662903,
        -0.05529138073325157,
        -0.001458033686503768,
        -0.04910682141780853,
        0.05346288904547691,
        0.04608241841197014,
        0.05604002997279167,
        0.005854342598468065,
        -0.04313492029905319,
        0.0032638018019497395,
        -0.005681919865310192,
        -0.04811646789312363,
        -0.011539953760802746,
        -0.03617466613650322,
        -0.04535902664065361,
        0.05029428377747536,
        0.023536907508969307,
        -0.04723414406180382,
        -0.05321275442838669,
        0.05616558715701103,
        0.05377930775284767,
        -0.050621308386325836,
        0.05572493374347687,
        -0.04967716336250305,
        -0.051736220717430115,
        0.021762529388070107,
        0.040013160556554794,
        -0.056131478399038315,
        -0.03761514276266098,
        0.05530548095703125,
        -0.02532447688281536,
        0.025890590623021126,
        0.046436674892902374,
        -0.04772718995809555,
        0.043215904384851456,
        -0.05319707468152046,
        0.053712088614702225,
        -0.0529615618288517,
        -0.05544568970799446,
        -0.045783333480358124,
        -0.05069967359304428,
        0.05495360866189003,
        -0.036909863352775574,
        0.0538858026266098,
        -0.051667358726263046,
        -0.052007272839546204,
        -0.05616740882396698,
        0.055304549634456635,
        -0.044863201677799225,
        0.05416162684559822,
        -0.055232781916856766,
        0.012852281332015991,
        0.040819548070430756,
        0.04895520210266113,
        0.024771567434072495,
        -0.01898978464305401,
        0.037436723709106445,
        0.052424702793359756,
        0.0462920218706131,
        -0.012081709690392017,
        0.055892400443553925,
        -0.042667414993047714,
        0.05055287107825279,
        -0.051769331097602844,
        -0.05386772379279137,
        0.05505131557583809,
        -0.01095758005976677,
        0.056155599653720856,
        0.041184213012456894,
        0.04423585906624794,
        0.04886262118816376,
        -0.056148264557123184,
        0.05074828863143921,
        -0.05611787736415863,
        -0.04765883460640907,
        0.0488128624856472,
        0.04435700178146362,
        -0.054172106087207794,
        0.04002254083752632,
        -0.04981517046689987,
        -0.053317420184612274,
        0.0035534421913325787,
        -0.05476734787225723,
        0.047430701553821564,
        0.03755507990717888,
        -0.033614326268434525,
        0.00894860364496708,
        -0.011379411444067955,
        -0.05516422912478447,
        0.05588657036423683,
        -0.05277792736887932,
        -0.01317981444299221,
        -0.05581514537334442,
        -0.0508054755628109,
        -0.0282344501465559,
        0.039774928241968155,
        -0.05411515757441521,
        0.03987018018960953,
        -0.031902819871902466,
        -0.01354154385626316,
        0.034029021859169006,
        0.05315900221467018,
        0.04994802922010422,
        -0.044026877731084824,
        0.015859631821513176,
        -0.05499061197042465,
        0.015937339514493942,
        -0.03120538219809532,
        -0.05473913624882698,
        -0.05144181102514267,
        -0.05296316370368004,
        -0.054760780185461044,
        0.04575931653380394,
        0.021145649254322052,
        -0.02614923007786274,
        -0.047456637024879456,
        0.051229432225227356,
        -0.001605121186003089,
        0.053618282079696655,
        0.05580727010965347,
        -0.048576951026916504,
        -0.030070826411247253,
        -0.053283192217350006,
        -0.049081578850746155,
        0.054652806371450424,
        0.04813392460346222,
        0.05575350299477577,
        -0.05603368580341339,
        -0.055220749229192734,
        0.03248785436153412,
        -0.043722957372665405,
        0.05595749616622925,
        -0.035296838730573654,
        0.055549513548612595,
        0.056170884519815445,
        0.05444292724132538,
        -0.03393468260765076,
        0.038219861686229706,
        -0.0426073856651783,
        -0.03527243807911873,
        0.048118799924850464,
        0.05612611398100853,
        -0.043974850326776505,
        0.011915610171854496,
        -0.053929973393678665,
        0.05467682704329491,
        -0.05328749492764473,
        0.05032001808285713,
        0.02659340389072895,
        -0.05494419485330582,
        0.05515800788998604,
        -0.025879494845867157,
        -0.05593152344226837,
        -0.008407235145568848,
        0.02530490793287754,
        -0.05611162260174751,
        0.05515672639012337,
        -0.05510717257857323,
        -0.0513576902449131,
        -0.05137434974312782,
        0.05615202337503433,
        -0.03564031794667244,
        -0.0497216060757637,
        -0.04992799088358879,
        0.0560181625187397,
        0.017274366691708565,
        0.055517613887786865,
        0.05535833537578583,
        -0.05524661764502525,
        0.03097715973854065,
        -0.05615741014480591,
        0.029184171929955482,
        -0.0025959943886846304,
        -0.034025710076093674,
        0.05425099655985832,
        0.046383071690797806,
        -0.049142025411129,
        0.052667394280433655,
        -0.04942711070179939,
        -0.027420803904533386,
        -0.04127771034836769,
        0.022856267169117928,
        0.05483704432845116,
        -0.01814514584839344,
        -0.055789802223443985,
        0.012331790290772915,
        0.05309266969561577,
        0.05396750941872597,
        0.05603640154004097,
        -0.04008103907108307,
        0.0559418760240078,
        -0.024836625903844833,
        -0.05493072047829628,
        -0.04496753215789795,
        -0.021316971629858017,
        0.052572667598724365,
        -0.04190666601061821,
        0.03930122032761574,
        0.014376521110534668,
        -0.01810022071003914,
        0.05502288416028023,
        -0.050416093319654465,
        -0.05540518835186958,
        -0.05617217347025871,
        0.049893252551555634,
        -0.05592384561896324,
        0.037434808909893036,
        0.03401195630431175,
        0.05353096127510071,
        0.03920367360115051,
        0.05497197061777115,
        -0.04970051720738411,
        0.05444182828068733,
        0.051042910665273666,
        -0.04140785336494446,
        0.05553590878844261,
        -0.03497825935482979,
        0.055616509169340134,
        -0.05524158105254173,
        -0.055773474276065826,
        -0.03247050195932388,
        0.0464472621679306,
        -0.018333841115236282,
        -0.05580446124076843,
        -0.04496629908680916,
        -0.05611724406480789,
        -0.055810753256082535,
        -0.021120671182870865,
        -0.020661482587456703,
        -0.00032554109930060804,
        0.0443701408803463,
        0.05399288237094879,
        0.03871282935142517,
        -0.00013127562124282122,
        0.030804051086306572
    ],
    [
        -0.05974266305565834,
        0.025174904614686966,
        0.06878893077373505,
        0.029254034161567688,
        0.03192138671875,
        -0.05341434106230736,
        0.05248457193374634,
        -0.062238335609436035,
        0.006748082581907511,
        -0.0015565732028335333,
        0.0060958280228078365,
        0.0010113598546013236,
        -0.025722138583660126,
        -0.06412254273891449,
        -0.06613805145025253,
        0.038505423814058304,
        -0.07053171843290329,
        0.060323137789964676,
        -0.06794806569814682,
        -0.025131870061159134,
        -0.06592980027198792,
        0.05416698008775711,
        0.06311649829149246,
        0.01663174107670784,
        0.020399242639541626,
        -0.018537137657403946,
        0.02483331970870495,
        0.023938998579978943,
        0.0009599674958735704,
        0.02000034786760807,
        0.06026181951165199,
        0.014967110008001328,
        0.03923312574625015,
        -0.07067515701055527,
        0.03299698606133461,
        0.012863627634942532,
        -0.055281128734350204,
        -0.05813540518283844,
        -0.05130985006690025,
        0.04306704178452492,
        0.051588721573352814,
        0.038110580295324326,
        0.05331997573375702,
        -0.059176623821258545,
        0.0356312021613121,
        0.05999261885881424,
        -0.00014664790069218725,
        0.06244857609272003,
        0.05133242532610893,
        0.046563707292079926,
        0.04134099930524826,
        0.027151677757501602,
        0.06616804748773575,
        0.014571494422852993,
        -0.01963256672024727,
        0.0350857749581337,
        -0.04131591320037842,
        0.019329966977238655,
        0.04327208548784256,
        -0.035897474735975266,
        -0.05634082853794098,
        0.02343081124126911,
        -0.0657501220703125,
        0.021583877503871918,
        0.019083546474575996,
        -0.0021572995465248823,
        0.02966129034757614,
        -0.06623244285583496,
        0.04962565749883652,
        -0.0714704617857933,
        -0.059657927602529526,
        -0.06845013052225113,
        0.05952760949730873,
        0.032188571989536285,
        -0.02437734417617321,
        -0.02382640726864338,
        0.03856625780463219,
        0.06844140589237213,
        0.028603697195649147,
        -0.011287533678114414,
        -0.03514817729592323,
        -0.01811542734503746,
        -0.06154308095574379,
        0.018791526556015015,
        0.024096518754959106,
        0.02337878756225109,
        -0.0179684367030859,
        -0.0137301841750741,
        -0.02204776741564274,
        0.06565824151039124,
        -0.026596419513225555,
        -0.049239978194236755,
        0.0390411876142025,
        -0.0509117990732193,
        -0.008605604991316795,
        0.03569852188229561,
        -0.014919320121407509,
        -0.05663536489009857,
        -0.00799479614943266,
        0.05984524264931679,
        -0.0703684464097023,
        -0.004885604605078697,
        0.008862550370395184,
        0.03433063626289368,
        0.038120754063129425,
        -0.06663959473371506,
        0.0012616742169484496,
        0.03534531965851784,
        -0.032473623752593994,
        0.015259972773492336,
        -0.012790442444384098,
        -0.06533212959766388,
        -0.05276969075202942,
        0.0036478894762694836,
        0.06336229294538498,
        -0.043010491877794266,
        -0.0017845279071480036,
        -0.05764545872807503,
        -0.05925523117184639,
        -0.03581636771559715,
        -0.026495907455682755,
        0.04379306733608246,
        -0.03161562979221344,
        0.026791803538799286,
        -0.0635249987244606,
        -0.04003918170928955,
        -0.03266065567731857,
        0.0618218295276165,
        0.05340668931603432,
        -0.05935128778219223,
        0.05170236900448799,
        -0.055505018681287766,
        0.003794982098042965,
        0.04649679735302925,
        -0.06443466991186142,
        -0.06797364354133606,
        0.0060192011296749115,
        -0.05809619650244713,
        -0.026402641087770462,
        0.040973927825689316,
        0.056779779493808746,
        -0.0028910282999277115,
        0.002961030462756753,
        0.0021255086176097393,
        -0.013366742059588432,
        0.030046196654438972,
        0.06677080690860748,
        -0.026376590132713318,
        -0.0284077487885952,
        0.014885010197758675,
        -0.021810246631503105,
        -0.06086772307753563,
        -0.0323842316865921,
        0.03864673152565956,
        0.04679766669869423,
        -0.019540632143616676,
        0.07057192176580429,
        0.04017719626426697,
        0.058659400790929794,
        -0.0007531788432970643,
        0.05717889964580536,
        -0.022882964462041855,
        0.06544332206249237,
        -0.036185842007398605,
        0.0057775704190135,
        -0.05943910777568817,
        0.021987302228808403,
        -0.013990212231874466,
        -0.052617914974689484,
        0.05759952589869499,
        -0.05412737652659416,
        0.05812311917543411,
        -0.06773916631937027,
        0.043458327651023865,
        -0.02792963944375515,
        0.06095096096396446,
        -0.06667574495077133,
        -0.005815892480313778,
        0.059131309390068054,
        0.03965993598103523,
        0.0476619191467762,
        0.05085601285099983,
        0.06021164357662201,
        0.0014260533498600125,
        -0.018261076882481575,
        -0.03424691781401634,
        -0.033332180231809616,
        0.06521673500537872,
        -0.05681567266583443,
        -0.041306983679533005,
        -0.0634436160326004,
        0.047782253473997116,
        -0.04626815766096115,
        0.024568121880292892,
        -0.01956901140511036,
        0.0628558099269867,
        0.026071883738040924,
        0.043050989508628845,
        -0.011618226766586304,
        0.008337846025824547,
        -0.02552509680390358,
        0.05599144846200943,
        0.042777225375175476,
        0.0031514051370322704,
        -0.011749601922929287,
        -0.03706160560250282,
        0.042349670082330704,
        0.0631367638707161,
        -0.05516315624117851,
        -0.030140064656734467,
        -0.05036604776978493,
        0.0707511454820633,
        0.025379054248332977,
        -0.024253450334072113,
        0.04195164516568184,
        -0.040594432502985,
        0.056902579963207245,
        0.04623657837510109,
        0.06685391813516617,
        -0.06533312052488327,
        0.0580921471118927,
        -0.056496620178222656,
        -0.04906301200389862,
        -0.06914626061916351,
        0.044371023774147034,
        -0.06607196480035782,
        0.006831508129835129,
        0.007120615802705288,
        -0.034476421773433685,
        -0.06325232982635498,
        -0.023302707821130753,
        -0.025912268087267876,
        -0.04803915321826935,
        0.05907227098941803,
        0.056283868849277496,
        0.0016099257627502084,
        0.06708090752363205,
        -0.06664328277111053,
        0.00952153466641903,
        0.06391051411628723,
        0.06294982135295868,
        0.05832313373684883,
        0.04558734595775604,
        -0.06163934990763664,
        0.010244945995509624,
        0.03136288747191429,
        -0.02759714238345623,
        -0.019644349813461304,
        -0.04440293461084366,
        0.02925461158156395,
        -0.042707543820142746,
        -0.06810499727725983,
        0.03198084607720375,
        -0.06266702711582184,
        0.039425890892744064,
        0.061948034912347794,
        0.0038331090472638607,
        -0.02865178883075714,
        0.05144703388214111,
        0.06374998390674591,
        0.060387205332517624,
        0.026786252856254578,
        0.005125408060848713,
        0.06079443544149399,
        -0.06689506024122238,
        0.068125881254673,
        0.024460669606924057,
        0.04937078058719635,
        -0.0639495998620987,
        -0.018485132604837418,
        0.019644342362880707,
        -0.06592661887407303,
        -0.003896390087902546,
        -0.055061932653188705,
        -0.06456252187490463,
        0.03113914653658867,
        -0.05678518861532211,
        0.021696465089917183,
        -0.020641455426812172,
        -0.01929882913827896,
        -0.05718248337507248,
        0.04650074243545532,
        0.04709906876087189,
        0.05458921566605568,
        -0.06801145523786545,
        -0.05451914295554161,
        0.04660293832421303,
        0.006114928983151913,
        -0.04851384460926056,
        0.032692424952983856,
        -0.03772175684571266,
        -0.017621193081140518,
        -0.00990739744156599,
        -0.04234147071838379,
        -0.008209675550460815,
        -0.056299757212400436,
        0.06977993994951248,
        0.05702399089932442,
        -0.06372138112783432,
        0.06589014828205109,
        -0.059853725135326385,
        0.06578276306390762,
        -0.039664220064878464,
        0.03737195208668709,
        0.03749217092990875,
        -0.06603611260652542,
        0.00893558468669653,
        0.06362797319889069,
        0.06965508311986923,
        -0.02729341760277748,
        0.027191875502467155,
        0.01588650979101658,
        -0.04160755127668381,
        0.06134621053934097,
        -0.06459124386310577,
        -0.06310344487428665,
        0.054938796907663345,
        0.012705489993095398,
        0.04703575000166893,
        -0.04507562890648842,
        -0.05389793589711189,
        -0.04351361095905304,
        0.04431410878896713,
        -0.06720898300409317,
        0.0155895184725523,
        0.06422507762908936,
        0.059787947684526443,
        -0.04338394105434418,
        0.030038803815841675,
        -0.036848604679107666,
        0.010117235593497753,
        -0.04103630408644676,
        0.037792373448610306,
        0.035524703562259674,
        0.06549065560102463,
        0.04377011954784393,
        -0.059238892048597336,
        0.07047802954912186,
        -0.050487127155065536,
        0.023437367752194405,
        0.0138850761577487,
        -0.04469897598028183,
        -0.05043214187026024,
        -0.036884911358356476,
        0.0715373307466507,
        -0.023233838379383087,
        0.05516152083873749,
        0.05136607959866524,
        0.011495138518512249,
        0.06887187063694,
        -0.06636519730091095,
        0.00042678002500906587,
        -0.04811462014913559,
        -0.023995134979486465,
        -0.07049296051263809,
        0.054973941296339035,
        -0.07023751735687256,
        -0.007567211519926786,
        -0.003998450469225645,
        -0.04946315288543701,
        0.05611648038029671,
        0.06520296633243561,
        -0.045464564114809036,
        -0.007289281114935875,
        0.0467938594520092,
        -0.038644034415483475,
        0.06957288831472397,
        -0.03239564970135689,
        0.0497652068734169,
        -0.07111318409442902,
        -0.0010014692088589072,
        -0.053872499614953995,
        0.04525972157716751,
        -0.006170927081257105,
        0.05175625905394554,
        0.06992226094007492,
        0.00633464427664876,
        0.04341446980834007,
        0.017245247960090637,
        -0.015345008112490177,
        -0.05223597586154938,
        0.05131737142801285,
        -0.07090283185243607,
        -0.05953109636902809,
        0.06750299036502838,
        -0.05740071088075638,
        0.02674092724919319,
        -0.05974414944648743,
        -0.033371880650520325,
        -0.023478683084249496,
        -0.019957635551691055,
        -0.028821822255849838,
        0.006054706405848265,
        -0.0007113978499546647,
        -0.017301568761467934,
        0.050507351756095886,
        0.049064941704273224,
        -0.04709502309560776,
        -0.006130666006356478,
        -0.06312272697687149,
        0.026781566441059113,
        0.05425553768873215,
        0.0554438978433609,
        0.062111686915159225,
        -0.021136892959475517,
        -0.02082938142120838,
        0.02296331524848938,
        -0.028817657381296158,
        0.028569482266902924,
        -0.01639484614133835,
        -0.031233960762619972,
        -0.00881244707852602,
        0.01857210323214531,
        0.034295182675123215,
        0.057009678333997726,
        -0.022628864273428917,
        -0.004048893228173256,
        0.01446701679378748,
        0.06971615552902222,
        0.029409274458885193,
        -0.017919428646564484,
        -0.07069624215364456,
        -0.012145263142883778,
        -0.06024019420146942,
        0.06828653067350388,
        0.0036682880017906427,
        0.008593539707362652,
        -0.029109057039022446,
        -0.06521213054656982,
        -0.06034797430038452,
        0.004542086739093065,
        0.013888496905565262,
        -0.008296133950352669,
        0.06633418053388596,
        0.036033060401678085,
        -0.05291717126965523,
        -0.03572985902428627,
        0.034797802567481995,
        -0.011119233444333076,
        -0.056315891444683075,
        -0.027621302753686905,
        0.014888588339090347,
        0.03224986046552658,
        0.05180540680885315,
        -0.019443513825535774,
        -0.005907329265028238,
        0.06889370828866959,
        -0.02638293243944645,
        0.06422943621873856,
        0.008155593648552895,
        -0.057246848940849304,
        0.04530125856399536,
        0.05932580679655075,
        -0.0127507159486413,
        -0.04398396238684654,
        -0.022358525544404984,
        0.011110927909612656,
        -0.035102661699056625,
        -0.0128617649897933,
        -0.004659443162381649,
        -0.007885451428592205,
        -0.04448695480823517,
        -0.02210254967212677,
        -0.05982304364442825,
        0.0006537703447975218,
        0.07041553407907486,
        -0.06682086735963821,
        0.024018973112106323,
        -0.009098832495510578,
        -0.06347739696502686,
        0.025960829108953476,
        0.023455794900655746,
        0.032976184040308,
        0.05775749683380127,
        -0.004820375237613916,
        0.03858407959342003,
        -0.04367726296186447,
        0.033368565142154694,
        -0.038144178688526154,
        -0.04243412986397743,
        -0.04369727522134781,
        -0.019618414342403412,
        0.025235584005713463,
        0.04981132224202156,
        -0.026215068995952606,
        -0.007394860032945871,
        0.035547174513339996,
        0.04197608679533005,
        -0.0597597099840641,
        0.019147826358675957,
        -0.021268606185913086,
        -0.06328582763671875,
        0.03604753315448761,
        0.033475711941719055,
        0.05129333958029747,
        0.022591734305024147,
        -0.041966360062360764,
        -0.06054629385471344,
        0.061038680374622345,
        0.005150669254362583,
        -0.0515233539044857,
        -0.04317523166537285,
        -0.051822859793901443,
        -0.048276156187057495,
        0.010001891292631626,
        0.031168941408395767,
        0.002468121238052845,
        0.03473478555679321,
        -0.004014990758150816,
        -0.004682730883359909,
        0.06078584864735603,
        0.0033443314023315907
    ],
    [
        -0.05849531292915344,
        -0.057936009019613266,
        0.05859120562672615,
        0.012713364325463772,
        0.05841679126024246,
        -0.05213819816708565,
        0.05889417976140976,
        -0.0536503791809082,
        0.0016443039057776332,
        0.05277378112077713,
        0.0268645491451025,
        0.03576789051294327,
        -0.05878828838467598,
        -0.022870711982250214,
        -0.05699234828352928,
        0.058081891387701035,
        -0.05925313010811806,
        0.057703930884599686,
        -0.05900750309228897,
        0.009335319511592388,
        -0.05845870450139046,
        0.01208092737942934,
        0.05854607746005058,
        0.04063409939408302,
        0.05513223260641098,
        -0.022358255460858345,
        -0.007900780066847801,
        0.05411471053957939,
        -0.04816722124814987,
        -0.001899645198136568,
        0.04792114719748497,
        -0.051026299595832825,
        -0.054001517593860626,
        -0.04717022925615311,
        -0.015782857313752174,
        0.01483821403235197,
        -0.05526191368699074,
        -0.05755634978413582,
        -0.03886652737855911,
        0.009028191678225994,
        0.03413967415690422,
        -0.007606319151818752,
        0.057093843817710876,
        -0.05725371092557907,
        0.01820335164666176,
        -0.004264525603502989,
        0.038985442370176315,
        0.058590494096279144,
        0.05692964792251587,
        0.050203800201416016,
        0.036774542182683945,
        0.007588991429656744,
        0.05595571547746658,
        -0.052895113825798035,
        -0.0005132983205839992,
        -0.03841325268149376,
        -0.02295629121363163,
        0.00020063066040165722,
        0.052017152309417725,
        -0.0206221342086792,
        -0.05396761745214462,
        -0.0036914364900439978,
        -0.05391712859272957,
        0.026212114840745926,
        -0.016719546169042587,
        0.0007392673287540674,
        0.05779755860567093,
        -0.05341988056898117,
        0.05779964476823807,
        -0.05928688868880272,
        -0.0498788021504879,
        -0.05666838958859444,
        -0.016458773985505104,
        0.057668328285217285,
        -0.04648177698254585,
        -0.00048114676610566676,
        0.0531315840780735,
        0.05643589049577713,
        0.025203753262758255,
        -0.0312469694763422,
        -0.03070693276822567,
        0.057148560881614685,
        -0.047663163393735886,
        0.024667823687195778,
        -0.05229182541370392,
        0.02358618564903736,
        -0.05097917467355728,
        0.049743812531232834,
        -0.012703859247267246,
        0.05884186923503876,
        -0.0560799203813076,
        -0.02269577421247959,
        -0.0525377094745636,
        0.013606132939457893,
        -0.012894708663225174,
        -0.015337198041379452,
        -0.04585700109601021,
        -0.03864094614982605,
        -0.05029292032122612,
        -0.013607881031930447,
        -0.0591861754655838,
        0.02642389014363289,
        0.05535029619932175,
        0.024796510115265846,
        0.043645430356264114,
        -0.05874386802315712,
        -0.05438936874270439,
        0.041436418890953064,
        -0.03648881986737251,
        -0.049789607524871826,
        0.0016603280091658235,
        -0.05720606818795204,
        -0.04987626150250435,
        -0.05641179904341698,
        0.03298494964838028,
        -0.007818825542926788,
        0.04818318411707878,
        -0.0496697798371315,
        -0.05909931659698486,
        -0.04297559708356857,
        -0.04758428409695625,
        -0.05685460940003395,
        0.006322774104773998,
        0.02683871239423752,
        -0.05917147919535637,
        -0.023893654346466064,
        0.00677886139601469,
        0.05238548293709755,
        0.04727374017238617,
        -0.05698494240641594,
        0.05317807197570801,
        -0.042588867247104645,
        0.04826021566987038,
        0.04335768520832062,
        -0.05614224076271057,
        -0.04031642526388168,
        0.055247120559215546,
        -0.058868225663900375,
        -0.024852177128195763,
        0.058755725622177124,
        -0.004776827525347471,
        0.04194175451993942,
        0.03611680865287781,
        0.050835419446229935,
        -0.03645617142319679,
        0.04509173706173897,
        0.05906304344534874,
        -0.04514630511403084,
        -0.058426644653081894,
        -0.005532179493457079,
        -0.03163052350282669,
        -0.05921134725213051,
        -0.05232696607708931,
        0.05649618059396744,
        0.012826845049858093,
        -0.05739619582891464,
        0.05870538204908371,
        -0.043812111020088196,
        0.053260426968336105,
        -0.05297894403338432,
        0.05860551819205284,
        -0.056332848966121674,
        0.02084689401090145,
        -0.04332408681511879,
        -0.04971601441502571,
        -0.02841648831963539,
        0.04937205836176872,
        -0.05799947306513786,
        -0.0033337234053760767,
        0.0430787168443203,
        -0.011761780828237534,
        0.019846990704536438,
        -0.05918290466070175,
        -0.04474974051117897,
        -0.05303889140486717,
        0.05814993381500244,
        -0.058064356446266174,
        0.040442679077386856,
        0.02416776493191719,
        0.05890614166855812,
        0.04154635965824127,
        0.054226312786340714,
        0.05846180021762848,
        -0.01623290590941906,
        -0.0019718375988304615,
        -0.0537082701921463,
        -0.030199041590094566,
        0.054903965443372726,
        -0.05755963921546936,
        -0.05674353986978531,
        -0.04099004715681076,
        -0.05526112765073776,
        0.009407122619450092,
        0.03330666199326515,
        0.00949903018772602,
        -0.05247640609741211,
        0.05608009546995163,
        -0.0032777381129562855,
        -0.04726319760084152,
        -0.005653172731399536,
        0.04051986709237099,
        0.056040406227111816,
        0.0002829924051184207,
        0.049045998603105545,
        0.04011678695678711,
        0.026178229600191116,
        0.025637580081820488,
        0.05349131301045418,
        -0.03281175717711449,
        0.048574768006801605,
        -0.057333219796419144,
        0.04139840975403786,
        0.018868090584874153,
        -0.05839117243885994,
        0.05110876262187958,
        -0.014707894064486027,
        0.040872856974601746,
        0.05840802565217018,
        0.05908920243382454,
        -0.056117210537195206,
        -0.00337244407273829,
        -0.058164771646261215,
        -0.03669625520706177,
        -0.05923027917742729,
        0.05703189596533775,
        -0.05741249769926071,
        0.04086557403206825,
        0.031107760965824127,
        -0.0111427316442132,
        -0.05580831691622734,
        -0.04202619567513466,
        -0.02759522944688797,
        -0.02575647085905075,
        0.05075465142726898,
        0.053400132805109024,
        -0.04114936664700508,
        0.05749863013625145,
        -0.0009433587547391653,
        0.011791103519499302,
        0.05744057148694992,
        0.05758087337017059,
        -0.041773613542318344,
        0.04106166213750839,
        -0.025995055213570595,
        -0.02230234257876873,
        -0.0016437103040516376,
        0.028464145958423615,
        0.04122025519609451,
        -0.05240126699209213,
        0.03202654793858528,
        0.02397404983639717,
        -0.05876077339053154,
        0.01434633694589138,
        -0.05660213530063629,
        0.04304362088441849,
        0.05730552598834038,
        -0.012966816313564777,
        0.031789381057024,
        0.05913934111595154,
        0.0592736192047596,
        -0.006348290015012026,
        0.05552506446838379,
        -0.016536258161067963,
        0.049589499831199646,
        -0.058321721851825714,
        0.050165146589279175,
        0.03215799108147621,
        -0.016518225893378258,
        -0.009020411409437656,
        -0.04679308459162712,
        -0.04783335700631142,
        -0.05917848274111748,
        0.017592817544937134,
        -0.05685955658555031,
        -0.05616277828812599,
        0.013586347922682762,
        -0.056239042431116104,
        0.02017795294523239,
        -0.029773730784654617,
        -0.004094700329005718,
        -0.05853113904595375,
        0.052933938801288605,
        0.02140478976070881,
        0.012963463552296162,
        -0.04907135292887688,
        -0.037873804569244385,
        0.0059873792342841625,
        -0.045756805688142776,
        -0.04697355628013611,
        0.008523817174136639,
        -0.022121531888842583,
        0.002657612320035696,
        0.051570501178503036,
        -0.025988444685935974,
        0.04583049938082695,
        -0.05395828187465668,
        0.05926993116736412,
        0.02257688343524933,
        -0.05179665982723236,
        0.05643913149833679,
        -0.026248181238770485,
        0.053626492619514465,
        -0.010554039850831032,
        0.048925768584012985,
        -0.02738131769001484,
        -0.057939231395721436,
        0.002795651787891984,
        0.04752887412905693,
        0.05713237449526787,
        -0.0556928925216198,
        -0.041046567261219025,
        -0.028332950547337532,
        -0.047572698444128036,
        0.05166332423686981,
        -0.05922036990523338,
        -0.04935666173696518,
        0.03681435063481331,
        -0.027350062504410744,
        0.044576916843652725,
        -0.046751249581575394,
        -0.029328472912311554,
        -0.04387335851788521,
        0.045783475041389465,
        -0.05569510906934738,
        0.05573663488030434,
        0.05923524498939514,
        0.05801769718527794,
        -0.0178227536380291,
        0.0470099076628685,
        -0.044513143599033356,
        -0.05734458193182945,
        0.04695547744631767,
        0.052330564707517624,
        0.03794284537434578,
        0.05277524143457413,
        0.016906848177313805,
        -0.05790495127439499,
        0.059280432760715485,
        -0.037025708705186844,
        0.054475799202919006,
        0.04854538291692734,
        -0.03985881060361862,
        0.04579995945096016,
        -0.010444232262670994,
        0.05929821357131004,
        0.035809364169836044,
        0.040096018463373184,
        0.04458240792155266,
        -0.0015157650923356414,
        0.058583736419677734,
        -0.059300683438777924,
        -0.0067370375618338585,
        -0.03763857111334801,
        -0.05751865729689598,
        -0.05914244428277016,
        0.05442775785923004,
        -0.057585928589105606,
        -0.025845760479569435,
        -0.04604459926486015,
        -0.058949876576662064,
        0.056333668529987335,
        0.05712571740150452,
        -0.05759063735604286,
        -0.00882366206496954,
        -0.01596921868622303,
        -0.04646272584795952,
        0.05890430137515068,
        -0.03479760140180588,
        -0.05104679614305496,
        -0.05927097797393799,
        -0.04431270435452461,
        -0.05909765884280205,
        0.04076637700200081,
        0.007722033187747002,
        0.05635435879230499,
        0.05627112835645676,
        0.005478035658597946,
        0.04959925636649132,
        -0.019860761240124702,
        -0.054695043712854385,
        -0.054326366633176804,
        0.049387961626052856,
        -0.05668574199080467,
        -0.011189170181751251,
        0.05849161371588707,
        -0.05789182335138321,
        0.03217948228120804,
        -0.055624816566705704,
        -0.029662400484085083,
        -0.051765210926532745,
        -0.03253643214702606,
        -0.04506339877843857,
        0.04534061998128891,
        -0.008987465873360634,
        -0.04924684762954712,
        0.055092550814151764,
        -0.009392871521413326,
        -0.040995098650455475,
        0.04732188954949379,
        -0.05920065566897392,
        -0.037048712372779846,
        -0.0019366538617759943,
        0.05692225322127342,
        0.05863477289676666,
        -0.03887025639414787,
        -0.029958995059132576,
        0.038389913737773895,
        -0.03412480652332306,
        0.05599011853337288,
        -0.05134641006588936,
        0.05718246474862099,
        0.05600317567586899,
        0.034933045506477356,
        0.03014281578361988,
        0.0510467104613781,
        0.013729464262723923,
        0.05297187343239784,
        0.026141837239265442,
        0.058265745639801025,
        0.03388293832540512,
        -0.027897128835320473,
        -0.045722730457782745,
        -0.03257622569799423,
        -0.0556594543159008,
        0.05729608237743378,
        -0.021429380401968956,
        -0.04911200702190399,
        -0.0171989593654871,
        -0.059213344007730484,
        -0.04202470928430557,
        0.039132922887802124,
        -0.02080722339451313,
        -0.03431084752082825,
        0.05879586562514305,
        0.05647830292582512,
        0.03337502479553223,
        -0.058651722967624664,
        0.05349389463663101,
        0.03541434556245804,
        -0.057378239929676056,
        -0.03107345663011074,
        0.05478692054748535,
        0.012754319235682487,
        0.054126985371112823,
        0.05319216847419739,
        -0.03609532490372658,
        0.05174952745437622,
        -0.056760795414447784,
        0.0587349608540535,
        -0.051969707012176514,
        -0.029761245474219322,
        0.058134861290454865,
        0.05762111023068428,
        0.0357540026307106,
        0.007031769957393408,
        -0.009941399097442627,
        0.008486499078571796,
        0.050914015620946884,
        0.027820374816656113,
        -0.018497155979275703,
        -0.006563642993569374,
        -0.04073402285575867,
        -0.04941292107105255,
        0.043921906501054764,
        0.05535649508237839,
        0.05918995663523674,
        -0.05926748365163803,
        0.059088751673698425,
        -0.05772228166460991,
        -0.05857035145163536,
        0.05245761200785637,
        0.05728478729724884,
        0.04850935563445091,
        0.017287706956267357,
        0.05773729085922241,
        0.045791517943143845,
        -0.03669077157974243,
        -0.010327803902328014,
        -0.010153485462069511,
        -0.054178137332201004,
        -0.05541139841079712,
        0.004414824303239584,
        0.05194561928510666,
        0.01493795495480299,
        -0.05059931054711342,
        0.042961664497852325,
        0.048722442239522934,
        -0.052240822464227676,
        -0.05257010459899902,
        0.051377639174461365,
        -0.050083477050065994,
        -0.0588199682533741,
        -0.019664857536554337,
        0.03166172280907631,
        0.05905472859740257,
        0.05587652698159218,
        -0.05873415246605873,
        -0.0320529006421566,
        0.055903110653162,
        0.034694474190473557,
        -0.0023386962711811066,
        -0.011968279257416725,
        -0.046062882989645004,
        -0.03777574375271797,
        0.045275259763002396,
        0.03901475667953491,
        -0.01770401932299137,
        -0.011398897506296635,
        -0.04863889142870903,
        0.00276967347599566,
        0.05924149230122566,
        -0.03420579060912132
    ],
    [
        -0.05032635107636452,
        -0.05453282967209816,
        0.053191427141427994,
        -0.032524727284908295,
        0.049134742468595505,
        -0.04591616988182068,
        0.05663211643695831,
        -0.056006595492362976,
        -0.04764324426651001,
        -0.05442611500620842,
        0.011605554260313511,
        -0.033066097646951675,
        -0.04739442467689514,
        -0.046270281076431274,
        -0.054697081446647644,
        0.05623960867524147,
        -0.05574345961213112,
        0.04223877564072609,
        -0.0570240393280983,
        0.03996957466006279,
        -0.04809751361608505,
        -0.05308879166841507,
        0.051559992134571075,
        -0.049628354609012604,
        0.056245073676109314,
        0.04084908962249756,
        0.05331035330891609,
        0.05695633590221405,
        -0.033126045018434525,
        -0.056796811521053314,
        0.05684245750308037,
        -0.021509531885385513,
        -0.018575459718704224,
        -0.0569242462515831,
        0.04693816229701042,
        -0.055417634546756744,
        -0.05413714423775673,
        -0.04579693078994751,
        -0.0570935420691967,
        0.022314906120300293,
        -0.00425858935341239,
        -0.030205760151147842,
        0.0474364273250103,
        -0.052796002477407455,
        0.039771873503923416,
        -0.007140969857573509,
        0.021361486986279488,
        0.0465673990547657,
        0.05445275083184242,
        -0.03702931851148605,
        0.05113118886947632,
        -0.047379810363054276,
        0.05463153496384621,
        0.05297437310218811,
        -0.04568876326084137,
        -0.026340952143073082,
        -0.05501475930213928,
        0.044001251459121704,
        0.013385334983468056,
        -0.02475428767502308,
        -0.045084889978170395,
        -0.05032072961330414,
        -0.05455048382282257,
        0.052245721220970154,
        -0.022695738822221756,
        -0.033610451966524124,
        0.05384926125407219,
        -0.026878587901592255,
        0.05366398021578789,
        -0.057092756032943726,
        -0.019886896014213562,
        -0.05707651004195213,
        -0.04331286996603012,
        -0.05453427881002426,
        0.0316426083445549,
        -0.039160072803497314,
        -0.008536924608051777,
        0.05708242207765579,
        0.04529764875769615,
        -0.05632186681032181,
        -0.029015272855758667,
        -0.020092330873012543,
        -0.05163561552762985,
        0.0402647964656353,
        -0.014059692621231079,
        0.04533000662922859,
        -0.04480085149407387,
        0.029213668778538704,
        0.055973030626773834,
        0.04872598499059677,
        -0.03706859052181244,
        0.027035284787416458,
        0.04658873379230499,
        -0.037209272384643555,
        -0.041599877178668976,
        0.03493412956595421,
        -0.029120583087205887,
        -0.04279738664627075,
        -0.055646851658821106,
        -0.05056391656398773,
        -0.05697818100452423,
        -0.055752553045749664,
        0.05690198019146919,
        0.052996717393398285,
        -0.027999892830848694,
        -0.03742726519703865,
        -0.0564161017537117,
        -0.021904489025473595,
        -0.04319356754422188,
        0.00889530498534441,
        -0.052398864179849625,
        -0.05372963845729828,
        -0.05682568624615669,
        0.03307439386844635,
        0.05690288543701172,
        0.018200652673840523,
        0.008116010576486588,
        -0.056316062808036804,
        -0.05416393652558327,
        -0.016662757843732834,
        -0.002233711304143071,
        0.04142137989401817,
        0.042072225362062454,
        0.022598635405302048,
        -0.056895457208156586,
        0.05548524484038353,
        0.04666139557957649,
        0.05450240150094032,
        0.05685223266482353,
        -0.045792773365974426,
        0.04791434854269028,
        -0.026487812399864197,
        -0.022736821323633194,
        0.05251114070415497,
        -0.04889853671193123,
        -0.037981558591127396,
        0.049191925674676895,
        -0.01841648481786251,
        -0.03517782688140869,
        0.05682068690657616,
        0.019901951774954796,
        0.018406962975859642,
        0.037837039679288864,
        0.056288205087184906,
        0.02860412746667862,
        -0.015430537983775139,
        0.05440928414463997,
        -0.035258710384368896,
        -0.05555689334869385,
        0.04717806726694107,
        -0.055644690990448,
        -0.05588473752140999,
        -0.028640201315283775,
        0.03358982130885124,
        -0.03875818848609924,
        -0.040819525718688965,
        0.0334627702832222,
        -0.007571621332317591,
        0.04323814436793327,
        -0.0547579787671566,
        0.03283684328198433,
        0.04987899959087372,
        0.036401305347681046,
        -0.0549565888941288,
        -0.05472981184720993,
        -0.048088300973176956,
        0.04818008467555046,
        -0.055676575750112534,
        -0.04795117303729057,
        0.047957807779312134,
        0.047645557671785355,
        0.05102013051509857,
        0.013726082630455494,
        0.01872934028506279,
        0.010008438490331173,
        0.05708711966872215,
        -0.05677914246916771,
        0.029144376516342163,
        0.04637087881565094,
        0.03698207810521126,
        0.03678277134895325,
        0.05417054146528244,
        0.008628380484879017,
        -0.04780319705605507,
        0.034154634922742844,
        -0.05565425753593445,
        0.04867340624332428,
        0.055459748953580856,
        -0.044961873441934586,
        -0.0561373233795166,
        0.0329781249165535,
        0.030581971630454063,
        -0.05187242850661278,
        0.02046852558851242,
        -0.056413255631923676,
        -0.015988538041710854,
        0.05536838248372078,
        -0.008092805743217468,
        -0.04032529518008232,
        0.05146811157464981,
        0.02717527747154236,
        0.05656490847468376,
        0.04948408156633377,
        0.044066935777664185,
        -0.01581544429063797,
        -0.04120386391878128,
        0.05200443044304848,
        0.012071993201971054,
        -0.04934701696038246,
        0.011741077527403831,
        -0.05056341737508774,
        0.052558012306690216,
        -0.055576689541339874,
        -0.026386279612779617,
        0.05631064251065254,
        -0.017918100580573082,
        0.019898422062397003,
        0.05258500203490257,
        0.05694415792822838,
        -0.05363833159208298,
        -0.04528113082051277,
        -0.0543709322810173,
        -0.05084722116589546,
        -0.05707673355937004,
        0.019002504646778107,
        -0.05284271761775017,
        -0.032378870993852615,
        0.05647939816117287,
        0.006905599031597376,
        -0.016194496303796768,
        0.010871264152228832,
        -0.054385289549827576,
        -0.03360221907496452,
        0.05184614658355713,
        0.042676135897636414,
        -0.013541556894779205,
        0.05537435784935951,
        -0.04256897419691086,
        -0.017196454107761383,
        0.05637845769524574,
        -0.026785481721162796,
        0.023193234577775,
        0.02983159013092518,
        0.015864206477999687,
        0.03315214440226555,
        -0.050002533942461014,
        -0.003385918214917183,
        0.03648069500923157,
        -0.046098362654447556,
        0.04697481915354729,
        0.05229804292321205,
        -0.05325927585363388,
        -0.010927580296993256,
        -0.047084253281354904,
        -0.027701346203684807,
        0.05594147369265556,
        0.002148047322407365,
        0.001468040980398655,
        0.05701776593923569,
        0.057008933275938034,
        0.020608272403478622,
        0.0002627196372486651,
        -0.005196268670260906,
        0.04074547067284584,
        -0.05701976269483566,
        0.03985817730426788,
        -0.0012448724592104554,
        -0.051572103053331375,
        -0.014210626482963562,
        -0.056822359561920166,
        0.04961467534303665,
        -0.05705207213759422,
        0.032183267176151276,
        -0.04957064241170883,
        -0.05294832959771156,
        0.040801580995321274,
        -0.05216969922184944,
        0.053816791623830795,
        -0.05185339227318764,
        -0.04326234012842178,
        -0.05653944984078407,
        -0.05442824959754944,
        0.05685296282172203,
        0.05635105073451996,
        -0.055677272379398346,
        -0.05548040568828583,
        -0.03902667760848999,
        -0.054005466401576996,
        -0.05152255669236183,
        0.05244143679738045,
        0.035741787403821945,
        -0.03454113379120827,
        -0.053233373910188675,
        0.04784104973077774,
        -0.028832199051976204,
        -0.04023584723472595,
        0.05697920545935631,
        0.04933658987283707,
        -0.05497713014483452,
        0.055042773485183716,
        0.03103046864271164,
        0.05275677889585495,
        -0.030125340446829796,
        0.04565015807747841,
        -0.05608399212360382,
        -0.055893171578645706,
        0.05359219014644623,
        0.051349442452192307,
        0.05177577584981918,
        -0.05658480152487755,
        0.0040872972458601,
        -0.03215634077787399,
        -0.044589459896087646,
        0.009952189400792122,
        -0.05708938091993332,
        -0.04520716518163681,
        0.057050302624702454,
        -0.05309131741523743,
        0.039833199232816696,
        -0.05631733685731888,
        -0.02515709400177002,
        -0.028515929356217384,
        0.055638354271650314,
        -0.05638729780912399,
        -0.029395129531621933,
        0.04805859178304672,
        0.02631855197250843,
        -0.05251169949769974,
        0.056013934314250946,
        0.0543922558426857,
        -0.052103687077760696,
        0.009902049787342548,
        0.04935549199581146,
        -0.03451462835073471,
        0.053676389157772064,
        0.04961337521672249,
        0.008628522045910358,
        0.05091290920972824,
        -0.04330416023731232,
        0.03551541268825531,
        0.012499394826591015,
        -0.052606675773859024,
        -0.018432000651955605,
        -0.05567927658557892,
        0.057094890624284744,
        0.03233238682150841,
        -0.044249504804611206,
        0.04478320851922035,
        0.03798665478825569,
        0.05593136325478554,
        -0.05709318071603775,
        -0.01480318047106266,
        -0.04454444348812103,
        -0.05091821774840355,
        -0.056562647223472595,
        0.05631381645798683,
        -0.03613081946969032,
        -0.05189821869134903,
        0.009082697331905365,
        -0.05456269532442093,
        0.04973389208316803,
        0.019818341359496117,
        -0.05534866452217102,
        -0.010432208888232708,
        0.02514067478477955,
        -0.01784370467066765,
        0.057030823081731796,
        -0.055725209414958954,
        0.054421473294496536,
        -0.05623992159962654,
        -0.05236541107296944,
        -0.05675702914595604,
        0.036503132432699203,
        0.018075354397296906,
        -0.009586217813193798,
        0.05656956508755684,
        -0.036836255341768265,
        0.0553915910422802,
        -0.03779471293091774,
        -0.05457538366317749,
        -0.05671387538313866,
        0.055367931723594666,
        -0.055659886449575424,
        -0.04355505853891373,
        0.0018206287641078234,
        -0.05418992415070534,
        -0.05270407721400261,
        -0.05582692101597786,
        -0.051349785178899765,
        -0.054036352783441544,
        -0.056840214878320694,
        -0.019785234704613686,
        -0.025659626349806786,
        -0.035898707807064056,
        -0.03146858885884285,
        0.04540574178099632,
        0.04669976979494095,
        0.05409056693315506,
        0.029067974537611008,
        -0.056799519807100296,
        -0.05310511961579323,
        0.0569843165576458,
        0.0541265606880188,
        0.05684638023376465,
        -0.03732752799987793,
        -0.04595063254237175,
        0.052396200597286224,
        0.012778792530298233,
        0.056730177253484726,
        0.044283345341682434,
        0.05499153956770897,
        0.040474146604537964,
        0.018469536677002907,
        -0.027847763150930405,
        0.05531063303351402,
        0.01809186115860939,
        0.041835635900497437,
        -0.05077020078897476,
        0.042703744024038315,
        -0.05498483031988144,
        -0.056799277663230896,
        -0.05169297382235527,
        -0.04321494325995445,
        -0.05375783145427704,
        0.05339263007044792,
        -0.013974960893392563,
        -0.0547446571290493,
        -0.05407584831118584,
        -0.05705376714468002,
        -0.048269122838974,
        0.03770811855792999,
        0.013594149611890316,
        -0.053424153476953506,
        0.05662259832024574,
        -0.03673715516924858,
        -0.029479874297976494,
        -0.05423085018992424,
        0.022039053961634636,
        -0.0318809412419796,
        -0.004509173799306154,
        -0.05607828125357628,
        0.052397627383470535,
        -0.04324382543563843,
        0.055925287306308746,
        0.05694454535841942,
        0.01855936273932457,
        0.05523590371012688,
        -0.05424925312399864,
        0.050796061754226685,
        -0.05236480012536049,
        -0.024790510535240173,
        0.053665466606616974,
        0.05703149363398552,
        0.0537596270442009,
        0.056190125644207,
        -0.03966759145259857,
        0.04706866294145584,
        0.0067197224125266075,
        -0.015560803934931755,
        0.05631200596690178,
        -0.0005267312517389655,
        0.002666584914550185,
        -0.0543762743473053,
        -0.055567000061273575,
        -0.0012960594613105059,
        0.056922804564237595,
        -0.04972662404179573,
        0.05508939549326897,
        0.01665663719177246,
        -0.05626673996448517,
        0.03741836175322533,
        -0.04929933696985245,
        0.05359448119997978,
        -0.050513770431280136,
        0.05089429393410683,
        0.04055170714855194,
        -0.03504963219165802,
        0.012264249846339226,
        -0.03303146734833717,
        -0.047641392797231674,
        0.044874005019664764,
        -0.0386992022395134,
        0.04036739468574524,
        -0.005446223542094231,
        -0.020305318757891655,
        0.04643828049302101,
        -0.01787237636744976,
        0.025972992181777954,
        -0.05563919618725777,
        0.02447335049510002,
        -0.029032813385128975,
        -0.0570925772190094,
        0.01853599026799202,
        0.003041662508621812,
        0.05524152144789696,
        -0.03521842882037163,
        -0.055051788687705994,
        -0.03952479362487793,
        0.05533251911401749,
        0.05001434311270714,
        0.039418574422597885,
        -0.03970872610807419,
        -0.03709691762924194,
        -0.05583133175969124,
        -0.03680616617202759,
        -0.02539578080177307,
        -0.05213926360011101,
        0.0004467264807317406,
        -0.053436946123838425,
        0.031241361051797867,
        -0.03112247958779335,
        0.034081168472766876
    ],
    [
        -0.0508405864238739,
        -0.04742052033543587,
        0.054451171308755875,
        -0.05441723391413689,
        0.01854611374437809,
        -0.0525820255279541,
        0.05162767693400383,
        -0.05496474727988243,
        -0.021810999140143394,
        -0.054969027638435364,
        0.04103238508105278,
        -0.055031806230545044,
        -0.021603483706712723,
        -0.053682778030633926,
        -0.05044586583971977,
        0.05368557199835777,
        -0.026407727971673012,
        0.046867940574884415,
        -0.05314844846725464,
        0.016949599608778954,
        -0.016150863841176033,
        -0.05501047894358635,
        0.04733404144644737,
        -0.05415930226445198,
        0.04635057970881462,
        0.046092573553323746,
        0.05409959331154823,
        0.05510077252984047,
        -0.02014588750898838,
        -0.05463984236121178,
        0.05495353788137436,
        -0.025828946381807327,
        -0.053993109613657,
        -0.05512964352965355,
        0.054272621870040894,
        -0.054429445415735245,
        -0.05317842960357666,
        -0.04988697171211243,
        -0.055129680782556534,
        -0.042693842202425,
        0.041760992258787155,
        0.026598185300827026,
        0.028178833425045013,
        0.05201295018196106,
        0.04450840875506401,
        -0.03746814280748367,
        -0.05072811245918274,
        0.04245375841856003,
        0.05487656220793724,
        -0.02717326022684574,
        0.05352894589304924,
        -0.007539709098637104,
        -0.015521524474024773,
        0.049718935042619705,
        -0.0398467592895031,
        0.0318889357149601,
        -0.054078780114650726,
        0.05325846001505852,
        0.03481851518154144,
        -0.04865466058254242,
        -0.053567614406347275,
        -0.04927308112382889,
        -0.05075931176543236,
        0.053062230348587036,
        -0.05298938229680061,
        -0.05485117807984352,
        -0.036307383328676224,
        -0.046558961272239685,
        0.054721180349588394,
        -0.05488714203238487,
        0.01657768338918686,
        -0.05499426648020744,
        -0.04422904923558235,
        -0.03877217695116997,
        0.05464392527937889,
        -0.05180070921778679,
        -0.02337365597486496,
        0.05513136461377144,
        -0.03497929126024246,
        -0.05510920658707619,
        -0.05013083294034004,
        -0.05497879907488823,
        -0.054512202739715576,
        0.04853847622871399,
        -0.04954168573021889,
        0.04099884256720543,
        -0.04034581035375595,
        -0.01034533604979515,
        0.03720482438802719,
        -0.05191231146454811,
        -0.05379948765039444,
        -0.02742215059697628,
        0.04930577054619789,
        0.012917685322463512,
        0.01760273426771164,
        0.03132545202970505,
        0.010328858159482479,
        -0.048666439950466156,
        -0.047518569976091385,
        -0.051391635090112686,
        -0.054360583424568176,
        -0.05486083775758743,
        0.054979126900434494,
        0.052908845245838165,
        -0.03524131700396538,
        -0.051673635840415955,
        -0.0547017827630043,
        0.03160794824361801,
        -0.04883039370179176,
        -0.0405951626598835,
        -0.05229315906763077,
        0.018868595361709595,
        -0.05429720878601074,
        0.050372082740068436,
        0.05445536971092224,
        0.042714644223451614,
        0.04655122756958008,
        -0.049758054316043854,
        0.005908961407840252,
        -0.030625667423009872,
        0.0416593998670578,
        0.030513927340507507,
        0.055083632469177246,
        -0.05504409596323967,
        -0.053476519882678986,
        0.05419159308075905,
        0.05496557801961899,
        0.011367341503500938,
        0.05510836839675903,
        -0.04833885654807091,
        0.05024178326129913,
        0.019109737128019333,
        -0.024340065196156502,
        0.0515165738761425,
        -0.03729499131441116,
        -0.04976972937583923,
        0.053833842277526855,
        0.04425971955060959,
        0.0046377903781831264,
        0.05489932745695114,
        -0.054242487996816635,
        -0.02413993701338768,
        0.05294079706072807,
        0.05495208129286766,
        -0.018714187666773796,
        -0.04599566385149956,
        -0.0307440385222435,
        0.00029581162380054593,
        -0.053929828107357025,
        -0.016447704285383224,
        -0.05299472436308861,
        -0.04611343890428543,
        0.05438559874892235,
        0.00048036896623671055,
        -0.03131920471787453,
        -0.04301866143941879,
        -0.05510857328772545,
        -0.052772656083106995,
        0.05266712233424187,
        -0.05145116522908211,
        -0.04921310395002365,
        0.03572618216276169,
        0.051652759313583374,
        -0.04916528984904289,
        -0.05057883635163307,
        -0.04872608929872513,
        0.053517185151576996,
        -0.051545146852731705,
        -0.05234954506158829,
        0.02206486649811268,
        0.01632109098136425,
        0.04814876243472099,
        -0.024031000211834908,
        0.0009465574403293431,
        0.05107036605477333,
        0.05512244999408722,
        0.04770147427916527,
        0.020238086581230164,
        0.0440383143723011,
        0.020338088274002075,
        -0.03841874375939369,
        0.05508889630436897,
        -0.047320522367954254,
        -0.05491930991411209,
        -0.009498413652181625,
        -0.05452040210366249,
        0.054793402552604675,
        0.05484474077820778,
        -0.022367365658283234,
        -0.05475832149386406,
        -0.028429165482521057,
        0.043914247304201126,
        -0.04243990406394005,
        0.052089691162109375,
        -0.05511890724301338,
        -0.03298506513237953,
        0.05469578504562378,
        0.0341455452144146,
        -0.04598136246204376,
        0.0550384484231472,
        0.051998525857925415,
        0.053549543023109436,
        0.03865261748433113,
        -0.04896889626979828,
        0.02200111746788025,
        -0.05495285242795944,
        -0.012396953999996185,
        0.03304731100797653,
        -0.042734406888484955,
        0.03949982672929764,
        -0.037591397762298584,
        0.052075691521167755,
        -0.05415588989853859,
        0.05330781266093254,
        0.05173144489526749,
        -0.04326887056231499,
        -0.005130839068442583,
        -0.012539098039269447,
        0.054016973823308945,
        -0.04490998387336731,
        -0.03915523365139961,
        -0.05194692313671112,
        0.002607910428196192,
        -0.0529797337949276,
        0.02018178068101406,
        0.04100450128316879,
        0.044242508709430695,
        0.054432328790426254,
        0.04415944963693619,
        -0.022637994959950447,
        -0.0029791160486638546,
        -0.015290218405425549,
        0.009686211124062538,
        0.035687003284692764,
        -0.024103892967104912,
        0.04913313686847687,
        0.05043959245085716,
        -0.0031662185210734606,
        -0.005129456054419279,
        0.05512441322207451,
        -0.053589705377817154,
        -0.012598906643688679,
        0.025949835777282715,
        -0.005818372126668692,
        0.0532698854804039,
        -0.04883537441492081,
        -0.05036856606602669,
        0.05382506176829338,
        0.030090922489762306,
        0.053563494235277176,
        0.05243372917175293,
        -0.04862440377473831,
        -0.03668779879808426,
        -0.05403733626008034,
        0.0026040435768663883,
        0.013777706772089005,
        0.026862528175115585,
        0.05402614176273346,
        0.054163333028554916,
        0.05461113154888153,
        -0.04802286997437477,
        -0.05313348025083542,
        -0.010916246101260185,
        0.03202281892299652,
        -0.054884620010852814,
        0.05365793779492378,
        0.021043557673692703,
        -0.05417441204190254,
        0.04816660284996033,
        -0.054616693407297134,
        0.05447223410010338,
        -0.05508948862552643,
        0.05482223257422447,
        -0.05013751983642578,
        -0.054618950933218,
        0.02583262324333191,
        -0.023347100242972374,
        0.05392969399690628,
        -0.04668644815683365,
        0.010632874444127083,
        -0.05154069513082504,
        -0.02928938716650009,
        0.055109065026044846,
        0.05511508509516716,
        -0.05084998533129692,
        -0.008489285595715046,
        0.03305564820766449,
        0.05113793537020683,
        -0.04872990772128105,
        0.04391757771372795,
        0.039417654275894165,
        -0.051324792206287384,
        -0.05489792302250862,
        0.026102101430296898,
        -0.032329000532627106,
        -0.027065450325608253,
        0.05494676157832146,
        0.03968236967921257,
        -0.05200904235243797,
        0.05327197536826134,
        0.04585107043385506,
        -0.03330625966191292,
        -0.014538833871483803,
        0.0530785471200943,
        -0.05501926690340042,
        -0.05448196455836296,
        0.05510792136192322,
        0.05374111980199814,
        -0.03732755780220032,
        -0.049231722950935364,
        -0.02950882725417614,
        -0.04824461415410042,
        0.000459638045867905,
        -0.039645541459321976,
        -0.05511884018778801,
        -0.05212748795747757,
        0.05204136297106743,
        -0.018236130475997925,
        0.04642696678638458,
        -0.05473309010267258,
        0.05410101264715195,
        -0.05384701490402222,
        0.04544973745942116,
        -0.05501583591103554,
        0.0016038615722209215,
        0.014266479760408401,
        0.04395545646548271,
        -0.05465608835220337,
        0.055113960057497025,
        0.05392929166555405,
        -0.050907380878925323,
        -0.04183977469801903,
        0.04663379117846489,
        0.01026109792292118,
        0.05198591575026512,
        0.05209359526634216,
        0.05212954804301262,
        -0.013378570787608624,
        -0.040230415761470795,
        0.014385463669896126,
        -0.04401853308081627,
        -0.05473688989877701,
        0.00011715588334482163,
        -0.051531706005334854,
        0.052671167999506,
        0.0012511779787018895,
        -0.03410667926073074,
        0.04991082102060318,
        0.027978835627436638,
        0.026560701429843903,
        -0.054703935980796814,
        -0.04698823019862175,
        0.030199093744158745,
        -0.05017247423529625,
        -0.049259815365076065,
        0.022967033088207245,
        -0.049561697989702225,
        -0.05447854846715927,
        0.023128384724259377,
        -0.049254532903432846,
        0.05334866791963577,
        -0.005644222255796194,
        0.02142389491200447,
        -0.036818791180849075,
        0.00271506211720407,
        -0.03798987343907356,
        0.05511521175503731,
        -0.046006906777620316,
        0.04875003173947334,
        -0.03095419332385063,
        -0.032319456338882446,
        -0.054954830557107925,
        0.0053206542506814,
        -0.028331564739346504,
        -0.008941100910305977,
        0.050980523228645325,
        -0.05123896524310112,
        0.05468224734067917,
        0.0383547767996788,
        -0.053994566202163696,
        -0.054937105625867844,
        0.05159446969628334,
        -0.048669859766960144,
        -0.051126979291439056,
        -0.05331503599882126,
        -0.05512314289808273,
        -0.05494694411754608,
        0.00712994858622551,
        -0.03997643664479256,
        -0.04837625473737717,
        -0.055101748555898666,
        -0.03279692307114601,
        -0.05511894077062607,
        -0.03751576691865921,
        0.0028369403444230556,
        0.04998321831226349,
        -0.020414305850863457,
        0.053114306181669235,
        0.025495324283838272,
        -0.05451371148228645,
        -0.05480088293552399,
        0.05512939393520355,
        0.040147747844457626,
        0.0535447783768177,
        -0.042862679809331894,
        -0.053226158022880554,
        0.05467621237039566,
        -0.037401095032691956,
        0.05510887876152992,
        0.051771968603134155,
        0.05448939651250839,
        0.054528843611478806,
        -0.009234301745891571,
        -0.014029963873326778,
        0.04659130796790123,
        -0.027605218812823296,
        0.04575509950518608,
        -0.04197410121560097,
        0.04176053777337074,
        -0.05494406446814537,
        -0.053493283689022064,
        -0.04192588850855827,
        -0.02270813286304474,
        -0.054998137056827545,
        0.013104029931128025,
        -0.00500625092536211,
        -0.05310520902276039,
        -0.027922753244638443,
        -0.0547720305621624,
        -0.05470113083720207,
        0.029834870249032974,
        0.03207329660654068,
        -0.05501633882522583,
        0.05444130674004555,
        -0.05509525537490845,
        -0.054283127188682556,
        -0.05116980895400047,
        0.01768704131245613,
        0.011073209345340729,
        0.016556724905967712,
        -0.055015671998262405,
        0.05460719019174576,
        -0.05373850092291832,
        0.054450515657663345,
        0.055094387382268906,
        0.0016357359709218144,
        0.05120306834578514,
        -0.05450563505291939,
        0.036584168672561646,
        -0.018048418685793877,
        -0.001401199377141893,
        0.05329494923353195,
        0.05507779121398926,
        0.05500340461730957,
        0.05498184636235237,
        -0.023555275052785873,
        0.0502803772687912,
        -0.04876061901450157,
        0.049291860312223434,
        0.054875269532203674,
        0.03381878137588501,
        -0.04806043580174446,
        0.03496822342276573,
        -0.04753924906253815,
        0.02149183861911297,
        0.05508367344737053,
        0.05166052654385567,
        0.05458158627152443,
        0.05432061851024628,
        -0.05494407191872597,
        0.0306331105530262,
        -0.05286524444818497,
        0.05095262452960014,
        -0.0492323562502861,
        -0.022794516757130623,
        0.050121549516916275,
        -0.04192333668470383,
        0.002393692033365369,
        0.04584161564707756,
        -0.04556849226355553,
        0.01660059206187725,
        -0.04533856734633446,
        -0.02620610222220421,
        0.04740695282816887,
        -0.029845818877220154,
        0.050699807703495026,
        -0.04849787801504135,
        0.0550883524119854,
        -0.054385434836149216,
        0.010818878188729286,
        0.01875162124633789,
        -0.055120404809713364,
        0.040646716952323914,
        -0.04665259271860123,
        0.037307675927877426,
        -0.04019530862569809,
        -0.033794552087783813,
        -0.025660138577222824,
        0.04964800924062729,
        0.04996410757303238,
        0.03657018765807152,
        0.027683325111865997,
        -0.04629456624388695,
        -0.05509541556239128,
        -0.05047332122921944,
        -0.023654945194721222,
        -0.05010100081562996,
        0.016976280137896538,
        0.04016060009598732,
        0.051549315452575684,
        -0.05510718375444412,
        -0.04909982904791832
    ],
    [
        -0.05496280640363693,
        -0.0552823543548584,
        0.05545935034751892,
        -0.027419928461313248,
        0.04826812818646431,
        -0.052593205124139786,
        0.05559157580137253,
        -0.04997559264302254,
        -0.04476538673043251,
        -0.04602649807929993,
        -0.005996370688080788,
        -0.055164240300655365,
        -0.051531217992305756,
        -0.04187116026878357,
        -0.05346742272377014,
        0.0545891635119915,
        -0.056092534214258194,
        0.03517769277095795,
        -0.056220244616270065,
        -0.021673863753676414,
        -0.053379133343696594,
        -0.05314807966351509,
        0.053400661796331406,
        -0.027642328292131424,
        0.055436376482248306,
        0.027788877487182617,
        0.05159837752580643,
        0.055725958198308945,
        -0.03287959471344948,
        -0.05483411252498627,
        0.05602477118372917,
        0.019708584994077682,
        -0.04980200156569481,
        -0.056145865470170975,
        0.0237470343708992,
        -0.05407678708434105,
        -0.0500478595495224,
        -0.04495096206665039,
        -0.0562279149889946,
        0.04556547850370407,
        0.04815813899040222,
        -0.02461373060941696,
        0.05134521797299385,
        -0.044842567294836044,
        0.02820601500570774,
        -0.030952613800764084,
        0.024735242128372192,
        0.03901325911283493,
        0.055388644337654114,
        0.03940719738602638,
        0.010227571241557598,
        -0.026362139731645584,
        0.05571090430021286,
        0.04431076720356941,
        -0.0398486964404583,
        0.024813758209347725,
        -0.054647549986839294,
        0.036443497985601425,
        0.05477098748087883,
        -0.032523009926080704,
        -0.025854846462607384,
        -0.047996073961257935,
        -0.05107777938246727,
        0.0561298131942749,
        -0.038606107234954834,
        -0.04806724563241005,
        0.05525272712111473,
        -0.05392010509967804,
        0.05577878654003143,
        -0.0562262088060379,
        -0.05530374124646187,
        -0.056221943348646164,
        -0.049957841634750366,
        -0.029531333595514297,
        0.002108080079779029,
        -0.01242732722312212,
        0.03405611217021942,
        0.0555821992456913,
        0.0013950489228591323,
        -0.05617648363113403,
        -0.037458863109350204,
        -0.007452722638845444,
        -0.04990212991833687,
        0.02152341604232788,
        -0.04115597531199455,
        0.035061173141002655,
        -0.04726553335785866,
        0.05499535799026489,
        0.0558306984603405,
        0.0509587861597538,
        -0.042463935911655426,
        0.0071427542716264725,
        0.029353538528084755,
        -0.04441124200820923,
        0.02255886420607567,
        0.016023145988583565,
        -0.03479119390249252,
        -0.028225325047969818,
        -0.054460830986499786,
        -0.05163910239934921,
        -0.056179918348789215,
        -0.05461452528834343,
        0.05259628966450691,
        0.05244623124599457,
        -0.011032291688024998,
        -0.054233115166425705,
        -0.05594656616449356,
        0.014181307516992092,
        -0.025498660281300545,
        -0.01842397078871727,
        -0.05223095789551735,
        -0.04822138696908951,
        -0.05590646713972092,
        0.022602498531341553,
        0.040167827159166336,
        -0.01696418598294258,
        0.03372601047158241,
        -0.055033084005117416,
        -0.05602104589343071,
        0.0036685550585389137,
        -0.02178160659968853,
        0.03441829979419708,
        0.048847343772649765,
        0.013999017886817455,
        -0.05615421012043953,
        0.0555417574942112,
        0.02224787138402462,
        0.004501832649111748,
        0.05605384334921837,
        -0.04926184564828873,
        0.038715146481990814,
        -0.004425147082656622,
        -0.01241978257894516,
        0.024294782429933548,
        -0.0538131520152092,
        -0.0187226515263319,
        0.047443244606256485,
        -0.04413144290447235,
        -0.018452225252985954,
        0.05618402734398842,
        0.008663170039653778,
        0.04243376478552818,
        0.032760947942733765,
        0.055370673537254333,
        0.04761968553066254,
        -0.051293548196554184,
        0.0536540262401104,
        -0.005592293571680784,
        -0.05537598952651024,
        0.052302222698926926,
        -0.05589449778199196,
        -0.055075276643037796,
        0.032103270292282104,
        0.039092037826776505,
        -0.032714392989873886,
        -0.04814010113477707,
        0.03847881034016609,
        -0.04943389445543289,
        0.02704188972711563,
        -0.04931500554084778,
        0.05404241010546684,
        0.047488752752542496,
        0.024585124105215073,
        -0.05597719922661781,
        -0.05555909872055054,
        -0.03858916833996773,
        -0.03549320623278618,
        -0.05573533475399017,
        -0.04864868149161339,
        0.04005326330661774,
        0.053769245743751526,
        0.04142956808209419,
        -0.009782204404473305,
        0.0316837802529335,
        -0.010044640861451626,
        0.05622537061572075,
        -0.05604534223675728,
        0.02938644215464592,
        0.03166285157203674,
        0.054618820548057556,
        0.05299156531691551,
        0.055997274816036224,
        0.03313766419887543,
        -0.05064062774181366,
        0.0216712336987257,
        -0.05534622445702553,
        0.052703894674777985,
        0.05446169525384903,
        -0.05216022580862045,
        -0.05568242445588112,
        0.03125419467687607,
        -0.0054760584607720375,
        -0.0540560819208622,
        0.021480349823832512,
        -0.054277241230010986,
        -0.04338629171252251,
        0.05558834969997406,
        -0.033368706703186035,
        -0.0532471165060997,
        0.04074617475271225,
        0.031120244413614273,
        0.05591705068945885,
        0.04782445356249809,
        0.04406898841261864,
        0.03486987575888634,
        -0.04722066968679428,
        0.035547301173210144,
        0.04878164827823639,
        -0.03859596699476242,
        0.0206396896392107,
        -0.04260903596878052,
        0.05016268044710159,
        -0.055218327790498734,
        -0.03236599639058113,
        0.055099014192819595,
        -0.025426967069506645,
        0.0008446616702713072,
        0.05254058167338371,
        0.05597759783267975,
        -0.054637789726257324,
        -0.016417820006608963,
        -0.054553303867578506,
        -0.05068198963999748,
        -0.056226640939712524,
        0.004860382527112961,
        -0.053673941642045975,
        -0.016007626429200172,
        0.05535466969013214,
        -0.046305879950523376,
        -0.025005249306559563,
        -0.049318648874759674,
        -0.04271475970745087,
        -0.016473019495606422,
        0.04458290711045265,
        0.03184156492352486,
        -0.055875614285469055,
        0.04867028817534447,
        -0.036855313926935196,
        0.030618250370025635,
        0.0515344962477684,
        -0.008302681148052216,
        0.03126034140586853,
        0.05205383896827698,
        0.02909242734313011,
        -0.03624119982123375,
        -0.04885239526629448,
        -0.009117551147937775,
        0.050210531800985336,
        -0.04814695939421654,
        0.052241500467061996,
        0.03803814575076103,
        -0.054566867649555206,
        -0.04838181287050247,
        -0.04925107955932617,
        -0.011738596484065056,
        0.05530472472310066,
        0.013239556923508644,
        0.039634205400943756,
        0.05614026263356209,
        0.05622520297765732,
        0.03900395706295967,
        0.024495786055922508,
        0.008979455567896366,
        0.05196249112486839,
        -0.05619388818740845,
        0.02676619030535221,
        0.03518679738044739,
        -0.05150049552321434,
        0.009316381067037582,
        -0.05593034252524376,
        0.020497750490903854,
        -0.056210149079561234,
        0.04037166386842728,
        -0.05485760048031807,
        -0.05555625632405281,
        0.04434344172477722,
        -0.05158506706357002,
        0.03213457018136978,
        -0.05606177821755409,
        -0.036932818591594696,
        -0.05574490875005722,
        -0.05411762744188309,
        0.056057799607515335,
        0.05621088668704033,
        -0.05139336362481117,
        -0.05369308963418007,
        -0.026098143309354782,
        -0.05571194738149643,
        -0.055053163319826126,
        0.042385637760162354,
        0.027136199176311493,
        -0.016698027029633522,
        -0.04832597076892853,
        0.031663719564676285,
        -0.040139034390449524,
        -0.044229164719581604,
        0.05620446056127548,
        0.04961070418357849,
        -0.05407467484474182,
        0.0520130917429924,
        -0.03755272924900055,
        0.04167051613330841,
        -0.01307088602334261,
        0.04969431832432747,
        -0.056203726679086685,
        -0.055981989949941635,
        0.051425714045763016,
        0.016552958637475967,
        0.04503311216831207,
        -0.05553388595581055,
        -0.008957897312939167,
        -0.03636527061462402,
        -0.05525969713926315,
        0.04614858329296112,
        -0.05622756481170654,
        -0.0476292185485363,
        0.056156519800424576,
        -0.041566383093595505,
        0.024517172947525978,
        -0.055836062878370285,
        0.0301124919205904,
        -0.012317384593188763,
        0.04376717656850815,
        -0.05616799369454384,
        0.0036343359388411045,
        0.053984180092811584,
        0.03408593684434891,
        -0.05039983615279198,
        0.05597665160894394,
        0.05484412610530853,
        -0.04012444242835045,
        0.048928070813417435,
        0.05175904557108879,
        -0.014058547094464302,
        0.053314708173274994,
        0.05049378052353859,
        0.031159793958067894,
        0.05378474295139313,
        -0.043877411633729935,
        0.0472855307161808,
        0.02181391417980194,
        -0.05370446294546127,
        -0.004950434900820255,
        -0.05350685119628906,
        0.05622805655002594,
        0.007643077056854963,
        -0.027993667870759964,
        0.01600995473563671,
        -0.047424815595149994,
        0.05322064831852913,
        -0.05622804909944534,
        -0.036812007427215576,
        -0.04602700099349022,
        -0.0535171814262867,
        -0.05474385619163513,
        0.05556429922580719,
        -0.041047483682632446,
        -0.04911477118730545,
        -0.024479176849126816,
        -0.05579569190740585,
        0.05465220287442207,
        0.019313300028443336,
        -0.042279601097106934,
        -0.008970574475824833,
        0.022936658933758736,
        -0.03881128877401352,
        0.056209128350019455,
        -0.0495244562625885,
        0.048564501106739044,
        -0.05616199970245361,
        -0.05444746091961861,
        -0.05617084354162216,
        0.04958697035908699,
        -0.01813652366399765,
        -0.026791924610733986,
        0.05522078648209572,
        -0.046899840235710144,
        0.054638925939798355,
        -0.05343833193182945,
        -0.04921097308397293,
        -0.05564355105161667,
        0.05430292338132858,
        -0.05332110449671745,
        -0.041545651853084564,
        0.034864917397499084,
        -0.05613486468791962,
        -0.055054061114788055,
        -0.0553608164191246,
        -0.053808700293302536,
        -0.024457743391394615,
        -0.05221652239561081,
        0.0032739141024649143,
        -0.016462018713355064,
        0.05278334766626358,
        -0.05151243135333061,
        0.0488065741956234,
        0.04870924353599548,
        0.05058269575238228,
        0.04583299532532692,
        -0.05537848547101021,
        -0.05484083667397499,
        0.05577077716588974,
        0.050294436514377594,
        0.0544903390109539,
        -0.051974065601825714,
        -0.05371880158782005,
        0.05135445296764374,
        0.04619874432682991,
        0.05590153485536575,
        -0.02997315488755703,
        0.05612872168421745,
        0.0555482842028141,
        0.023826846852898598,
        -0.02331613376736641,
        0.046450719237327576,
        0.0021766573190689087,
        0.03564509376883507,
        -0.050871219485998154,
        0.055059004575014114,
        -0.05514667183160782,
        -0.05594473332166672,
        -0.05292296037077904,
        0.01719963736832142,
        -0.03979910537600517,
        0.05262450873851776,
        -0.007998364977538586,
        -0.056024763733148575,
        -0.013209638185799122,
        -0.056218359619379044,
        -0.047296810895204544,
        -0.00037646738928742707,
        0.021452635526657104,
        -0.05328996106982231,
        0.05620912089943886,
        -0.04653090238571167,
        -0.04245420917868614,
        -0.048451777547597885,
        0.052880529314279556,
        -0.009786597453057766,
        -0.04641971364617348,
        -0.05515270680189133,
        0.05429314821958542,
        -0.005996473599225283,
        0.05469394475221634,
        0.056168559938669205,
        -0.03249214589595795,
        0.043064508587121964,
        -0.05606627091765404,
        0.05188877508044243,
        -0.05553894862532616,
        -0.040307946503162384,
        0.055924996733665466,
        0.05619733780622482,
        0.030655253678560257,
        0.054283518344163895,
        0.03418309986591339,
        0.036690521985292435,
        0.041101083159446716,
        -0.04872119426727295,
        0.0559622198343277,
        -0.011385788209736347,
        -0.04031467065215111,
        -0.055746015161275864,
        -0.02617654763162136,
        0.024160755798220634,
        0.05617751553654671,
        -0.05561162903904915,
        0.0555478073656559,
        0.037612639367580414,
        -0.05589946731925011,
        0.03896855190396309,
        -0.04638362675905228,
        0.055023208260536194,
        -0.047414761036634445,
        0.05343284457921982,
        0.0473957322537899,
        -0.05087798833847046,
        -0.006860843859612942,
        0.01230438333004713,
        -0.055461954325437546,
        -0.03677497059106827,
        0.005210375413298607,
        0.005049365572631359,
        0.0003684698313008994,
        0.011389227584004402,
        0.05002614110708237,
        -0.010574666783213615,
        -0.03225870430469513,
        -0.04741071164608002,
        0.0202712994068861,
        -0.031094269827008247,
        -0.05622290074825287,
        0.011561795137822628,
        0.045345377177000046,
        0.05606623366475105,
        -0.025537312030792236,
        -0.055328160524368286,
        -0.04512794315814972,
        0.05388598144054413,
        0.04494832456111908,
        -0.013677038252353668,
        -0.05090118944644928,
        -0.05259609967470169,
        -0.055623166263103485,
        -0.03199119120836258,
        0.024114830419421196,
        -0.053055714815855026,
        -0.008760770782828331,
        -0.054081566631793976,
        0.04350927472114563,
        -0.03871021047234535,
        0.026420721784234047
    ],
    [
        -0.05531223490834236,
        -0.05585150793194771,
        0.05659106746315956,
        -0.02532702498137951,
        0.05563511326909065,
        -0.052710358053445816,
        0.05692744627594948,
        -0.04577973857522011,
        -0.05189380794763565,
        -0.04125814512372017,
        0.010510686784982681,
        -0.053506869822740555,
        -0.05216933786869049,
        -0.03740527480840683,
        -0.05241027846932411,
        0.05361520126461983,
        -0.057358626276254654,
        0.04196833819150925,
        -0.057444535195827484,
        -0.020181013271212578,
        -0.05334244295954704,
        -0.04859182983636856,
        0.05389712005853653,
        -0.015312219969928265,
        0.056867484003305435,
        0.024591075256466866,
        0.04689265042543411,
        0.057035114616155624,
        -0.027715547010302544,
        -0.053827639669179916,
        0.057116515934467316,
        -0.01105004083365202,
        -0.049519460648298264,
        -0.05734727904200554,
        -0.0017360768979415298,
        -0.05403115227818489,
        -0.05114368349313736,
        -0.045505374670028687,
        -0.057460885494947433,
        0.04640877991914749,
        0.043992090970277786,
        -0.040318407118320465,
        0.05087537690997124,
        -0.052231840789318085,
        0.020887667313218117,
        -0.0343036875128746,
        0.015630654990673065,
        0.04582856222987175,
        0.055499374866485596,
        0.04464063420891762,
        0.019048331305384636,
        -0.040460385382175446,
        0.05699997395277023,
        0.04253068566322327,
        -0.04525516554713249,
        0.00960526429116726,
        -0.05364556610584259,
        0.02690507285296917,
        0.055283330380916595,
        -0.01618524082005024,
        -0.03442610427737236,
        -0.04183005914092064,
        -0.05327106639742851,
        0.057211264967918396,
        -0.04207143932580948,
        -0.039619799703359604,
        0.05592590570449829,
        -0.05603216588497162,
        0.05676853284239769,
        -0.057459186762571335,
        -0.05614891275763512,
        -0.05745111033320427,
        -0.05264785885810852,
        -0.03346872702240944,
        -0.011407222598791122,
        -0.007584142964333296,
        0.01244242861866951,
        0.05603563040494919,
        0.004735398106276989,
        -0.05734594166278839,
        -0.028742481023073196,
        0.01445342693477869,
        -0.050174303352832794,
        0.02145702764391899,
        -0.04806825518608093,
        0.028902798891067505,
        -0.05173124000430107,
        0.05569378286600113,
        0.05609147623181343,
        0.05304134264588356,
        -0.04085154086351395,
        0.016438143327832222,
        0.011092882603406906,
        -0.04452810063958168,
        0.022626575082540512,
        0.017227955162525177,
        -0.03584185987710953,
        -0.022673336789011955,
        -0.055745143443346024,
        -0.05337931588292122,
        -0.057308439165353775,
        -0.056445542722940445,
        0.05257582664489746,
        0.05192577838897705,
        -0.005981443449854851,
        -0.050896067172288895,
        -0.05676954239606857,
        -0.003484695451334119,
        -0.031171653419733047,
        -0.02341083064675331,
        -0.05566414073109627,
        -0.052055034786462784,
        -0.05682815983891487,
        0.02395499497652054,
        0.041964564472436905,
        -0.00457047950476408,
        0.023493731394410133,
        -0.05628427863121033,
        -0.057234618812799454,
        -0.00888150092214346,
        -0.025368399918079376,
        0.03582309931516647,
        0.044943057000637054,
        0.01880694553256035,
        -0.057393237948417664,
        0.055954709649086,
        0.0026676051784306765,
        0.008032570593059063,
        0.057131972163915634,
        -0.037521522492170334,
        0.033875495195388794,
        -0.021177595481276512,
        -0.005605089478194714,
        0.02912617288529873,
        -0.05312831699848175,
        -0.03067173808813095,
        0.05061842128634453,
        -0.04348558187484741,
        -0.008616653271019459,
        0.05742039158940315,
        -0.011767340824007988,
        0.04203266650438309,
        0.02349221147596836,
        0.056719787418842316,
        0.04178312048316002,
        -0.047558803111314774,
        0.05530698969960213,
        -0.011780019849538803,
        -0.05636734887957573,
        0.05377715453505516,
        -0.05695311725139618,
        -0.05601957067847252,
        0.02028260938823223,
        0.027029743418097496,
        -0.03378039225935936,
        -0.04348207265138626,
        0.04715622216463089,
        -0.04875382035970688,
        0.014132180251181126,
        -0.047661859542131424,
        0.05599550902843475,
        0.0466281995177269,
        0.01720491796731949,
        -0.05720710754394531,
        -0.05637792497873306,
        -0.04262389615178108,
        -0.03215828910470009,
        -0.0573078878223896,
        -0.04770015552639961,
        0.045290857553482056,
        0.05477059260010719,
        0.030625538900494576,
        -0.021471666172146797,
        0.022624310106039047,
        -0.013327576220035553,
        0.05745692923665047,
        -0.05729028955101967,
        0.023826107382774353,
        0.022918397560715675,
        0.055161599069833755,
        0.053019989281892776,
        0.05707723647356033,
        0.043177686631679535,
        -0.04688767343759537,
        0.025022219866514206,
        -0.056658655405044556,
        0.04823687672615051,
        0.0553978867828846,
        -0.05309416726231575,
        -0.05696926265954971,
        0.029794931411743164,
        -0.01225101388990879,
        -0.05494154617190361,
        0.0220966599881649,
        -0.05277475342154503,
        -0.04856765642762184,
        0.05599270015954971,
        -0.0221380777657032,
        -0.05411948263645172,
        0.03957829996943474,
        0.022281186655163765,
        0.05687125772237778,
        0.04847468435764313,
        0.05031893402338028,
        0.0336800143122673,
        -0.04489433392882347,
        0.02187420427799225,
        0.03949955478310585,
        -0.03132270649075508,
        0.025916554033756256,
        -0.05031561106443405,
        0.037514735013246536,
        -0.056597333401441574,
        -0.04148220643401146,
        0.05567305162549019,
        -0.017663907259702682,
        0.004074689466506243,
        0.0559161901473999,
        0.05683985352516174,
        -0.05405351147055626,
        0.002607627771794796,
        -0.05657386779785156,
        -0.05123048275709152,
        -0.057459983974695206,
        0.02033817209303379,
        -0.053595948964357376,
        -0.013547182083129883,
        0.056533727794885635,
        -0.046026118099689484,
        -0.028836946934461594,
        -0.04959256574511528,
        -0.04784191772341728,
        -0.018412018194794655,
        0.033518414944410324,
        0.03248080983757973,
        -0.05651865899562836,
        0.050602834671735764,
        -0.007369757164269686,
        0.03319733217358589,
        0.05270884186029434,
        -0.004355961922556162,
        0.027485184371471405,
        0.053079959005117416,
        0.021279670298099518,
        -0.03128625825047493,
        -0.05227675661444664,
        -0.0074159326031804085,
        0.04967210814356804,
        -0.050723712891340256,
        0.05429117754101753,
        0.03454843908548355,
        -0.054954878985881805,
        -0.04708907753229141,
        -0.05041956901550293,
        -0.016732051968574524,
        0.05608765780925751,
        0.0050344388000667095,
        0.04321523755788803,
        0.05732808634638786,
        0.05745746195316315,
        0.041180215775966644,
        0.040333524346351624,
        0.006418763194233179,
        0.04691566154360771,
        -0.057400576770305634,
        0.02977815829217434,
        0.0312683992087841,
        -0.0478118360042572,
        0.030011173337697983,
        -0.056994885206222534,
        0.005775265395641327,
        -0.05741313844919205,
        0.03516022488474846,
        -0.05490683764219284,
        -0.05591852590441704,
        0.030978359282016754,
        -0.051473140716552734,
        0.02782675065100193,
        -0.05718420818448067,
        -0.03498399630188942,
        -0.05694597586989403,
        -0.055869732052087784,
        0.057247888296842575,
        0.05741491913795471,
        -0.050073787569999695,
        -0.05369893088936806,
        -0.014009690843522549,
        -0.056898076087236404,
        -0.05597193166613579,
        0.0446212962269783,
        0.01646040566265583,
        -0.014543567784130573,
        -0.048565033823251724,
        0.04257522150874138,
        -0.03283388912677765,
        -0.03979640454053879,
        0.0574350468814373,
        0.049891985952854156,
        -0.05381425470113754,
        0.05010393634438515,
        -0.03749607503414154,
        0.045051850378513336,
        -0.02128332294523716,
        0.04767205938696861,
        -0.05742605775594711,
        -0.057348765432834625,
        0.048523250967264175,
        0.019123444333672523,
        0.04149665683507919,
        -0.056805022060871124,
        0.01425035111606121,
        -0.029664497822523117,
        -0.056546587496995926,
        0.04225314408540726,
        -0.05746033787727356,
        -0.04287559539079666,
        0.05732595548033714,
        -0.04494631290435791,
        0.0035208403132855892,
        -0.056196946650743484,
        0.02438238263130188,
        -0.01439996063709259,
        0.04445909336209297,
        -0.05738009884953499,
        0.002847607946023345,
        0.052576448768377304,
        0.03574414551258087,
        -0.047508444637060165,
        0.05694327503442764,
        0.05328177660703659,
        -0.040851324796676636,
        0.05344509705901146,
        0.0498780831694603,
        -0.011151392944157124,
        0.0535903200507164,
        0.04644963517785072,
        0.0018997854785993695,
        0.056185830384492874,
        -0.04763004556298256,
        0.04574865475296974,
        0.013326763175427914,
        -0.05187647044658661,
        -0.012110588140785694,
        -0.05366190895438194,
        0.057461366057395935,
        0.025505777448415756,
        -0.01715090498328209,
        0.0023899420630186796,
        -0.04463081434369087,
        0.05386324226856232,
        -0.05746135488152504,
        -0.02595471404492855,
        -0.04904794692993164,
        -0.05224910005927086,
        -0.056551091372966766,
        0.05638953670859337,
        -0.041134174913167953,
        -0.045275501906871796,
        -0.02156873792409897,
        -0.056476373225450516,
        0.05417934060096741,
        0.025476397946476936,
        -0.041759371757507324,
        -0.018394678831100464,
        0.035278018563985825,
        -0.04535622149705887,
        0.05742916837334633,
        -0.04899640381336212,
        0.05315345898270607,
        -0.05731828510761261,
        -0.055284447968006134,
        -0.05741406977176666,
        0.050702180713415146,
        -0.02741643786430359,
        -0.030547738075256348,
        0.05653153732419014,
        -0.04395313188433647,
        0.05493219196796417,
        -0.0548778660595417,
        -0.04883609712123871,
        -0.056884463876485825,
        0.05495719239115715,
        -0.05064726620912552,
        -0.028431912884116173,
        0.0369478315114975,
        -0.05723301321268082,
        -0.05473744869232178,
        -0.0543799065053463,
        -0.054726969450712204,
        -0.030839456245303154,
        -0.05297123268246651,
        0.009503615088760853,
        0.005214473698288202,
        0.050324950367212296,
        -0.052159491926431656,
        0.047234389930963516,
        0.05086365342140198,
        0.04957086965441704,
        0.044659215956926346,
        -0.05558396503329277,
        -0.054331112653017044,
        0.053940292447805405,
        0.048329636454582214,
        0.05643994361162186,
        -0.052864111959934235,
        -0.051504261791706085,
        0.052341580390930176,
        0.043307553976774216,
        0.05688094720244408,
        -0.037742044776678085,
        0.057384658604860306,
        0.05627879500389099,
        0.019062506034970284,
        -0.020473802462220192,
        0.04945642128586769,
        0.007747321855276823,
        0.03825102001428604,
        -0.04865878075361252,
        0.05548660829663277,
        -0.056214068084955215,
        -0.05726029723882675,
        -0.05141449719667435,
        0.02239810861647129,
        -0.04109296202659607,
        0.055265411734580994,
        -0.011300650425255299,
        -0.056837260723114014,
        -0.014921736903488636,
        -0.057453762739896774,
        -0.03964809700846672,
        0.015596508979797363,
        0.009897639974951744,
        -0.05219118669629097,
        0.05741501227021217,
        -0.036725759506225586,
        -0.03038402460515499,
        -0.04647122323513031,
        0.0540568083524704,
        0.012584269978106022,
        -0.0499139279127121,
        -0.054987404495477676,
        0.055101681500673294,
        -0.01297694444656372,
        0.05438505485653877,
        0.057345032691955566,
        -0.034549325704574585,
        0.04059160128235817,
        -0.05711948499083519,
        0.05402714014053345,
        -0.056505899876356125,
        -0.04560980573296547,
        0.056866008788347244,
        0.05741177126765251,
        0.01858484372496605,
        0.05619575455784798,
        0.042641252279281616,
        0.032801948487758636,
        0.044882066547870636,
        -0.04839160665869713,
        0.05643525347113609,
        0.019615259021520615,
        -0.03292735293507576,
        -0.05694134533405304,
        -0.031086739152669907,
        0.01656162366271019,
        0.05734868347644806,
        -0.05687825754284859,
        0.0568431094288826,
        0.029297975823283195,
        -0.05692184716463089,
        0.04739562049508095,
        -0.04492954537272453,
        0.052637629210948944,
        -0.04578021168708801,
        0.05502939969301224,
        0.043633271008729935,
        -0.05003594234585762,
        -0.0191159937530756,
        0.0012052179081365466,
        -0.055729903280735016,
        -0.029213104397058487,
        0.016422182321548462,
        0.031199557706713676,
        0.0023642939049750566,
        0.005201379768550396,
        0.04802960902452469,
        0.00043580197962000966,
        -0.05213451758027077,
        -0.04870494455099106,
        0.02417493797838688,
        -0.005491245537996292,
        -0.05745180696249008,
        -0.001041075331158936,
        0.04526599869132042,
        0.0572810098528862,
        -0.014504308812320232,
        -0.05668935924768448,
        -0.0416560098528862,
        0.05492343753576279,
        0.04759259894490242,
        0.00006552742706844583,
        -0.05026853084564209,
        -0.05291682481765747,
        -0.05608616769313812,
        -0.03507944941520691,
        0.029496479779481888,
        -0.05280504375696182,
        -0.014224535785615444,
        -0.055750854313373566,
        0.04682348296046257,
        -0.023379383608698845,
        0.033633165061473846
    ],
    [
        -0.017513934522867203,
        -0.053911544382572174,
        0.05236579477787018,
        -0.034940194338560104,
        0.046805962920188904,
        -0.05364822596311569,
        0.05218638852238655,
        -0.054440077394247055,
        -0.029810162261128426,
        -0.05291588604450226,
        0.05246514081954956,
        -0.04229903221130371,
        -0.05109655112028122,
        -0.04628841578960419,
        -0.05066481605172157,
        -0.015294226817786694,
        0.011943807825446129,
        0.0281686894595623,
        -0.047007106244564056,
        -0.0006723164115101099,
        0.014706124551594257,
        -0.05372020974755287,
        0.0541512705385685,
        -0.05442928895354271,
        -0.046703536063432693,
        0.04602043703198433,
        0.05332264304161072,
        0.05445573478937149,
        0.04038240388035774,
        -0.05422530695796013,
        0.05366632714867592,
        -0.022260580211877823,
        -0.036502547562122345,
        -0.054471053183078766,
        0.04820572957396507,
        -0.054237764328718185,
        -0.05281238257884979,
        -0.04977913945913315,
        -0.054445531219244,
        -0.013375560753047466,
        0.05407841503620148,
        0.01486961916089058,
        0.025853857398033142,
        0.05382825806736946,
        0.016905970871448517,
        0.044849928468465805,
        -0.029050787910819054,
        0.051279958337545395,
        0.05444170907139778,
        -0.053868964314460754,
        0.0397762656211853,
        -0.017726201564073563,
        -0.05264756828546524,
        0.052517686039209366,
        -0.05147837847471237,
        0.05270516872406006,
        -0.054214831441640854,
        0.0540456622838974,
        -0.016396714374423027,
        -0.05254312977194786,
        -0.05131397023797035,
        -0.025789061561226845,
        -0.05384865030646324,
        0.04479914903640747,
        -0.03488323837518692,
        -0.05273343250155449,
        -0.04747035726904869,
        -0.022338951006531715,
        0.05339304357767105,
        -0.054336681962013245,
        0.04903651028871536,
        -0.05413182079792023,
        0.0371556393802166,
        0.014495763927698135,
        0.054342709481716156,
        -0.052043549716472626,
        0.046611011028289795,
        0.05447104573249817,
        -0.04101245477795601,
        -0.010152575559914112,
        0.006273953709751368,
        -0.05423981323838234,
        -0.05342791602015495,
        0.05385413393378258,
        0.05438411608338356,
        0.018991194665431976,
        0.04540184512734413,
        -0.023835795000195503,
        -0.0030856458470225334,
        -0.05230259522795677,
        -0.05418931692838669,
        0.0029429513961076736,
        -0.025943677872419357,
        0.007996505126357079,
        -0.036793939769268036,
        0.05077248066663742,
        0.048352524638175964,
        -0.05271850526332855,
        -0.05413161590695381,
        -0.037460632622241974,
        -0.054416391998529434,
        -0.010030534118413925,
        0.054376620799303055,
        0.05398308485746384,
        -0.05081645026803017,
        -0.05330200865864754,
        -0.045722633600234985,
        0.05445123091340065,
        -0.05031346157193184,
        -0.03501744568347931,
        -0.05409998074173927,
        0.04037611931562424,
        -0.04499846696853638,
        0.04680049791932106,
        0.05414489656686783,
        -0.038809340447187424,
        -0.025743788108229637,
        -0.05150781199336052,
        0.04827609285712242,
        -0.044457025825977325,
        0.041493505239486694,
        0.038909733295440674,
        0.054369017481803894,
        -0.05429091677069664,
        -0.05244571343064308,
        0.05178847908973694,
        0.047504767775535583,
        -0.04200951009988785,
        0.054441895335912704,
        -0.05133824422955513,
        0.04769540950655937,
        -0.008015945553779602,
        0.0064895483665168285,
        0.054310571402311325,
        -0.04293495789170265,
        -0.04579470679163933,
        0.05389847233891487,
        0.042877789586782455,
        -0.027501098811626434,
        0.05444304645061493,
        -0.03791072592139244,
        -0.04967415705323219,
        0.053928617388010025,
        -0.027199534699320793,
        -0.009168937802314758,
        -0.051250703632831573,
        -0.006053665652871132,
        -0.05245397239923477,
        -0.053989753127098083,
        -0.01757059432566166,
        -0.04426427185535431,
        -0.05146156996488571,
        0.028557414188981056,
        0.050909921526908875,
        -0.03461981937289238,
        -0.05444345250725746,
        -0.05439674109220505,
        -0.052138738334178925,
        0.04311230778694153,
        -0.05014454200863838,
        0.04006754979491234,
        0.038809116929769516,
        0.04631897434592247,
        0.047517552971839905,
        -0.02291613258421421,
        -0.05084198713302612,
        0.05164994299411774,
        -0.05052170902490616,
        0.021624615415930748,
        0.04909205809235573,
        -0.034011829644441605,
        0.04896596074104309,
        -0.04744873568415642,
        -0.04712233319878578,
        0.0527338981628418,
        0.054445989429950714,
        0.027048783376812935,
        0.02804817073047161,
        0.05270557478070259,
        -0.020293118432164192,
        -0.050503943115472794,
        0.054400309920310974,
        -0.031148061156272888,
        -0.04470532387495041,
        0.037908393889665604,
        -0.042724721133708954,
        0.03975773602724075,
        0.0535745806992054,
        0.03984194993972778,
        -0.050951819866895676,
        -0.054402921348810196,
        0.04875124618411064,
        0.022024625912308693,
        0.052910689264535904,
        -0.054169896990060806,
        0.05044277012348175,
        0.052715837955474854,
        0.04652174189686775,
        -0.05097942426800728,
        0.053814321756362915,
        0.04877437278628349,
        0.049456555396318436,
        0.029763108119368553,
        -0.027422994375228882,
        0.024621615186333656,
        -0.05417821928858757,
        0.009999542497098446,
        0.052504777908325195,
        -0.05436860769987106,
        0.04661545902490616,
        -0.04853590205311775,
        0.05045585706830025,
        0.02587306499481201,
        0.04907522350549698,
        -0.004857382737100124,
        -0.022022457793354988,
        0.05227900668978691,
        0.02907005324959755,
        0.054142072796821594,
        -0.03764748200774193,
        -0.01607273891568184,
        0.02347506769001484,
        0.03037281520664692,
        -0.04141583666205406,
        -0.03488711640238762,
        0.0493064820766449,
        0.054312825202941895,
        -0.013841967098414898,
        0.023159053176641464,
        -0.051813118159770966,
        -0.049098074436187744,
        -0.053127471357584,
        -0.03826878219842911,
        0.019352741539478302,
        -0.05135050788521767,
        0.054216016083955765,
        0.05296584218740463,
        -0.029490171000361443,
        0.048983100801706314,
        0.054372746497392654,
        -0.049612585455179214,
        -0.025246327742934227,
        -0.042689573019742966,
        0.03381730243563652,
        0.05432524159550667,
        -0.03189701586961746,
        -0.053030576556921005,
        0.05412261560559273,
        0.04969507083296776,
        0.05303703993558884,
        0.053436823189258575,
        -0.05409197881817818,
        -0.05357666313648224,
        -0.05414685606956482,
        0.01532910019159317,
        0.0450112409889698,
        0.018875721842050552,
        0.05377320945262909,
        0.05269908159971237,
        0.04498428851366043,
        -0.041265230625867844,
        -0.05442272499203682,
        -0.015534691512584686,
        0.048030219972133636,
        -0.05418166145682335,
        0.05416034907102585,
        0.02178092859685421,
        -0.04499475657939911,
        0.04745449498295784,
        -0.05401209369301796,
        0.014205515384674072,
        -0.054458409547805786,
        0.05435653030872345,
        -0.05278092622756958,
        -0.054007638245821,
        -0.03141683340072632,
        -0.044798191636800766,
        0.05327221378684044,
        0.020254671573638916,
        0.039678480476140976,
        -0.04117583483457565,
        0.04593605175614357,
        0.052691999822854996,
        0.05440020561218262,
        0.016306061297655106,
        0.050417810678482056,
        0.0016639234963804483,
        0.053776055574417114,
        -0.028827665373682976,
        0.022163379937410355,
        0.03924015164375305,
        -0.05271155387163162,
        -0.05125144496560097,
        -0.046833015978336334,
        0.02980812080204487,
        -0.050293561071157455,
        0.054192282259464264,
        -0.043368108570575714,
        -0.054010216146707535,
        0.05415153130888939,
        0.053355176001787186,
        0.02590646594762802,
        -0.012280049733817577,
        0.054388850927352905,
        -0.047133732587099075,
        -0.0373423732817173,
        0.05397524684667587,
        0.019313622266054153,
        -0.01258150115609169,
        -0.05281708762049675,
        0.02041601575911045,
        -0.05117598548531532,
        0.04906812310218811,
        0.04638587310910225,
        -0.054431043565273285,
        -0.05275052413344383,
        0.04442959651350975,
        0.04997289553284645,
        0.04917837679386139,
        -0.053315941244363785,
        -0.03266733139753342,
        -0.04987720027565956,
        0.03800877928733826,
        -0.05442354083061218,
        0.027853194624185562,
        0.040180906653404236,
        0.04237031936645508,
        -0.053910285234451294,
        0.05434390529990196,
        -0.006413363851606846,
        -0.02626202069222927,
        -0.005769446026533842,
        -0.012106304056942463,
        0.0032024949323385954,
        0.05317537486553192,
        0.052975282073020935,
        0.034595705568790436,
        0.01929887942969799,
        -0.007804268971085548,
        0.010134815238416195,
        -0.05192098021507263,
        -0.05444386973977089,
        -0.022276734933257103,
        -0.05300000682473183,
        0.04412717744708061,
        0.03034588322043419,
        0.00552468141540885,
        0.04958159103989601,
        0.045604459941387177,
        0.053413037210702896,
        -0.022848887369036674,
        -0.05313524231314659,
        0.053545620292425156,
        -0.049744490534067154,
        -0.053479086607694626,
        0.05324433743953705,
        -0.05052882805466652,
        -0.05346065014600754,
        0.03805391490459442,
        -0.049390505999326706,
        0.048472147434949875,
        0.028193620964884758,
        0.036480922251939774,
        -0.0535622276365757,
        -0.04563654586672783,
        -0.03611845150589943,
        0.054467204958200455,
        0.017082935199141502,
        0.04384378343820572,
        -0.05415116995573044,
        -0.02524418570101261,
        -0.03825081139802933,
        0.04568343237042427,
        0.0453939288854599,
        -0.03912020102143288,
        -0.026598485186696053,
        -0.031357284635305405,
        -0.040370214730501175,
        0.043585699051618576,
        -0.048072777688503265,
        -0.0538756437599659,
        0.052912238985300064,
        -0.05310942605137825,
        -0.015145953744649887,
        -0.04637422412633896,
        -0.05353245511651039,
        -0.05304763466119766,
        -0.028373561799526215,
        -0.04055008292198181,
        -0.053449515253305435,
        -0.0544695220887661,
        0.05190085619688034,
        -0.05418979749083519,
        -0.05142509564757347,
        0.04555346816778183,
        0.05243481695652008,
        0.0528767853975296,
        0.021455563604831696,
        -0.04416380822658539,
        -0.054424725472927094,
        -0.04270029440522194,
        0.054423738270998,
        -0.0075500719249248505,
        0.046656399965286255,
        -0.044770654290914536,
        -0.053434569388628006,
        0.05383723974227905,
        0.03812761977314949,
        0.05446240305900574,
        0.030655512586236,
        -0.012118988670408726,
        0.0043938057497143745,
        -0.018166445195674896,
        -0.052524372935295105,
        0.03953104466199875,
        -0.05173451080918312,
        0.048408392816782,
        -0.040097832679748535,
        0.05418067425489426,
        -0.05367237702012062,
        0.00258527765981853,
        -0.054351769387722015,
        -0.04500800743699074,
        -0.05443774163722992,
        0.04040376469492912,
        0.030398305505514145,
        -0.0486428327858448,
        0.03693297132849693,
        -0.05423854663968086,
        -0.05210265517234802,
        0.04287438467144966,
        0.04700865596532822,
        -0.05444254353642464,
        0.05422214791178703,
        -0.05437922477722168,
        -0.05433466285467148,
        -0.053241364657878876,
        -0.05239495262503624,
        0.013158425688743591,
        0.025508232414722443,
        -0.05101672187447548,
        0.05435327813029289,
        0.009306265972554684,
        0.051688965409994125,
        0.05381487309932709,
        0.05052722617983818,
        0.053583741188049316,
        -0.05433676019310951,
        0.053031016141176224,
        0.04416458308696747,
        -0.035222120583057404,
        0.05113648995757103,
        0.053694818168878555,
        0.05428292602300644,
        0.04160510003566742,
        -0.03195218741893768,
        0.030347321182489395,
        -0.00927406270056963,
        0.052656374871730804,
        0.051069583743810654,
        0.0046368069015443325,
        -0.05387731269001961,
        0.0506131574511528,
        -0.054460931569337845,
        0.04284660518169403,
        0.05443019047379494,
        -0.015890639275312424,
        0.05249949172139168,
        -0.01740497350692749,
        -0.054457757622003555,
        0.04541458189487457,
        0.041769206523895264,
        0.053792841732501984,
        0.016101397573947906,
        -0.04960937052965164,
        0.031881481409072876,
        0.006278148386627436,
        -0.0484829843044281,
        0.052479859441518784,
        0.006409488618373871,
        -0.03995518758893013,
        -0.05321146920323372,
        0.0262716356664896,
        0.049372825771570206,
        -0.05420747026801109,
        0.049341388046741486,
        -0.04892696812748909,
        0.05364559218287468,
        -0.05328039452433586,
        0.009242536500096321,
        0.0484553761780262,
        -0.04959896206855774,
        0.03464573621749878,
        -0.05218666419386864,
        0.04731069505214691,
        0.020726213231682777,
        -0.04119059443473816,
        0.03399574011564255,
        0.05380759388208389,
        0.05411752313375473,
        -0.029487060382962227,
        0.04103787988424301,
        -0.0536523312330246,
        -0.05445323884487152,
        0.006300515029579401,
        0.03266984224319458,
        -0.017214270308613777,
        -0.013445219956338406,
        -0.014907081611454487,
        -0.0180696789175272,
        -0.05443593114614487,
        -0.048940058797597885
    ],
    [
        -0.05521887540817261,
        -0.04735155031085014,
        0.05782721936702728,
        0.04661041870713234,
        0.008685098960995674,
        -0.046116240322589874,
        0.05832035467028618,
        -0.05398491397500038,
        0.050136275589466095,
        0.053397901356220245,
        0.05671391636133194,
        0.05200605466961861,
        -0.05291523411870003,
        -0.046462975442409515,
        -0.05451137572526932,
        0.022703053429722786,
        -0.055667944252491,
        0.017133774235844612,
        -0.05693015456199646,
        0.05392112955451012,
        -0.037238795310258865,
        0.0014585698954761028,
        0.05534696951508522,
        -0.03011162392795086,
        0.05823874846100807,
        0.04254681244492531,
        0.057803232222795486,
        0.011052634567022324,
        -0.036612194031476974,
        -0.057901058346033096,
        0.05668257549405098,
        -0.05169742554426193,
        -0.037990789860486984,
        -0.05840832740068436,
        0.04286269471049309,
        -0.046256426721811295,
        -0.05200978368520737,
        -0.024035073816776276,
        -0.058472514152526855,
        0.03305722028017044,
        0.026794027537107468,
        0.01320999301970005,
        0.04900044575333595,
        -0.04473806545138359,
        0.0432116873562336,
        0.01726219430565834,
        0.03976277634501457,
        0.0582612082362175,
        0.05829045549035072,
        0.04110217094421387,
        0.05185497924685478,
        -0.04117229953408241,
        -0.04386679455637932,
        -0.018377741798758507,
        0.009005025960505009,
        -0.05566287040710449,
        -0.04916801676154137,
        0.03389384225010872,
        0.01589958555996418,
        0.05436395853757858,
        -0.042455993592739105,
        -0.020596884191036224,
        -0.05726547911763191,
        0.05821690335869789,
        -0.04876890033483505,
        -0.002491852967068553,
        0.049045417457818985,
        -0.02887549065053463,
        0.05586814880371094,
        -0.05811893567442894,
        -0.044617149978876114,
        -0.05762272700667381,
        -0.05492309108376503,
        0.05542641133069992,
        -0.03634065017104149,
        -0.03390652313828468,
        0.03714851289987564,
        0.056885067373514175,
        0.023526838049292564,
        -0.05556109547615051,
        -0.04016178473830223,
        0.047405943274497986,
        -0.05657333880662918,
        0.044860199093818665,
        0.019579721614718437,
        0.051606111228466034,
        -0.0051894583739340305,
        0.009641779586672783,
        0.05371540039777756,
        0.058382097631692886,
        -0.05526120960712433,
        0.05307736620306969,
        -0.04949209839105606,
        -0.03996918350458145,
        -0.03601967543363571,
        -0.048052359372377396,
        0.03849468007683754,
        -0.012511364184319973,
        -0.02373291738331318,
        -0.05436946824193001,
        -0.05840292200446129,
        -0.034420888870954514,
        0.04843920096755028,
        0.05572514235973358,
        0.00363051681779325,
        -0.04791592061519623,
        -0.05554685369133949,
        0.0510021336376667,
        -0.04900062084197998,
        0.05126362293958664,
        -0.04628610983490944,
        0.029223766177892685,
        -0.0016932322178035975,
        0.03277197852730751,
        0.0528152734041214,
        -0.0008998197154141963,
        -0.04247267544269562,
        -0.04372929036617279,
        -0.055186811834573746,
        -0.004133156035095453,
        0.037112101912498474,
        -0.0397653691470623,
        -0.014794220216572285,
        -0.055352505296468735,
        -0.05590413138270378,
        0.015220766887068748,
        0.016142362728714943,
        0.050080958753824234,
        0.02424398995935917,
        -0.03877468407154083,
        0.03882122039794922,
        -0.04106656461954117,
        -0.03199373558163643,
        0.053329017013311386,
        -0.05485224723815918,
        -0.04455474019050598,
        0.050987232476472855,
        -0.00017366312386002392,
        -0.0570242702960968,
        0.0575881265103817,
        0.05518980324268341,
        -0.01641741394996643,
        0.05537651851773262,
        0.05482438579201698,
        -0.04899280145764351,
        -0.033809512853622437,
        0.058166008442640305,
        0.0018520783632993698,
        -0.05422540381550789,
        -0.0393889956176281,
        -0.05491399019956589,
        -0.05804908275604248,
        0.0543903112411499,
        0.05436263978481293,
        -0.04429472237825394,
        -0.058099377900362015,
        0.05415930598974228,
        -0.04400664567947388,
        0.05765914171934128,
        0.019546840339899063,
        0.04582595452666283,
        -0.055550716817379,
        0.04342450946569443,
        -0.05712702497839928,
        -0.057834357023239136,
        -0.020595859736204147,
        0.030734825879335403,
        -0.057568468153476715,
        0.046371057629585266,
        0.040073271840810776,
        0.008604518137872219,
        -0.0212478656321764,
        -0.05599413812160492,
        0.007255404721945524,
        0.05584101006388664,
        0.05826181918382645,
        -0.0579465851187706,
        -0.011199726723134518,
        0.053137585520744324,
        0.04996451735496521,
        0.046016283333301544,
        0.04669800400733948,
        0.05832601711153984,
        0.023167354986071587,
        0.04907023534178734,
        -0.041580937802791595,
        -0.02740774117410183,
        0.05403846129775047,
        -0.02027192898094654,
        -0.05783532187342644,
        -0.022431647405028343,
        0.017378799617290497,
        -0.03184923902153969,
        0.04977109283208847,
        -0.031586527824401855,
        -0.032732002437114716,
        0.05424073338508606,
        -0.05226069316267967,
        -0.033042892813682556,
        -0.004265179857611656,
        -0.016319580376148224,
        0.05738172307610512,
        0.03193660452961922,
        0.018079888075590134,
        0.03783534839749336,
        0.05050896853208542,
        0.007061040494590998,
        -0.04723484069108963,
        -0.04607744514942169,
        -0.02306659147143364,
        -0.0543767586350441,
        0.01311481837183237,
        -0.04018094390630722,
        -0.04730468988418579,
        0.058323562145233154,
        0.02032250352203846,
        0.0025952982250601053,
        0.04689338803291321,
        0.058416444808244705,
        -0.040206968784332275,
        0.0038471180014312267,
        -0.05224192515015602,
        -0.0295582115650177,
        -0.05830841138958931,
        -0.00712548615410924,
        -0.05664842575788498,
        0.047260209918022156,
        0.058398596942424774,
        -0.050098881125450134,
        -0.05154935270547867,
        -0.032245468348264694,
        -0.0261556264013052,
        -0.03778567910194397,
        0.05071749910712242,
        -0.002224348019808531,
        0.017775651067495346,
        0.05585642531514168,
        -0.05064769834280014,
        0.035017091780900955,
        0.0581892728805542,
        0.04705198109149933,
        0.047215525060892105,
        0.01105358824133873,
        0.05595440790057182,
        -0.0019440136384218931,
        -0.02942689321935177,
        -0.05642778426408768,
        -0.029114611446857452,
        -0.0012552624102681875,
        0.015516622923314571,
        -0.047120675444602966,
        -0.05715683475136757,
        -0.053119998425245285,
        -0.05484576150774956,
        0.003879092400893569,
        0.0581776462495327,
        -0.051499661058187485,
        0.01489379070699215,
        0.0571584478020668,
        0.05809128284454346,
        0.057250481098890305,
        0.02464461699128151,
        -0.008154548704624176,
        0.05729920417070389,
        -0.05527991056442261,
        0.04718806594610214,
        -0.055930692702531815,
        -0.049417801201343536,
        0.011870542541146278,
        -0.050062183290719986,
        0.019768396392464638,
        -0.058415818959474564,
        0.025230417028069496,
        -0.057487137615680695,
        -0.047304507344961166,
        0.03221067413687706,
        -0.054824937134981155,
        0.05300312489271164,
        -0.05715363472700119,
        -0.044924296438694,
        -0.05834902077913284,
        0.04164592921733856,
        0.011999459005892277,
        0.05560839921236038,
        0.021888496354222298,
        -0.0581466406583786,
        0.023939143866300583,
        -0.03419039770960808,
        -0.05308086425065994,
        0.03472420945763588,
        -0.039897698909044266,
        -0.006376700475811958,
        0.012028480879962444,
        -0.023767653852701187,
        0.017790894955396652,
        -0.036116309463977814,
        0.058345977216959,
        0.03898174315690994,
        -0.05097370967268944,
        0.056802842766046524,
        0.03687448054552078,
        0.04038430005311966,
        -0.0160814318805933,
        0.055358752608299255,
        0.030032364651560783,
        -0.05319928005337715,
        0.03321579843759537,
        0.038782935589551926,
        0.05167173594236374,
        -0.05070866644382477,
        0.019831964746117592,
        -0.024837682023644447,
        -0.0513547845184803,
        -0.039320677518844604,
        -0.05835777521133423,
        -0.05206797644495964,
        0.057887982577085495,
        -0.05843840911984444,
        0.04685520753264427,
        -0.05781904235482216,
        -0.0014817219926044345,
        -0.025336582213640213,
        0.035989418625831604,
        -0.05803794413805008,
        0.051774024963378906,
        0.053327906876802444,
        0.05761100351810455,
        0.015562738291919231,
        0.05827506259083748,
        -0.030649032443761826,
        -0.02802005410194397,
        0.052320852875709534,
        0.02515607886016369,
        -0.03989768028259277,
        0.05619360879063606,
        0.055522479116916656,
        -0.05117626115679741,
        0.0570034384727478,
        -0.051053062081336975,
        -0.03134549781680107,
        0.03629287704825401,
        -0.03704332187771797,
        0.04012233391404152,
        -0.05439041927456856,
        0.05848626419901848,
        0.03911177068948746,
        0.02730921469628811,
        0.048408232629299164,
        -0.032657671719789505,
        0.05069328472018242,
        -0.05687827616930008,
        -0.021911758929491043,
        -0.04168470948934555,
        -0.05711042508482933,
        -0.054387498646974564,
        0.05645949766039848,
        -0.053698066622018814,
        -0.053993936628103256,
        -0.05460391938686371,
        -0.054888445883989334,
        0.056070663034915924,
        0.019824862480163574,
        -0.029546421021223068,
        -0.05073695257306099,
        0.03553830832242966,
        -0.05503469705581665,
        0.05838717520236969,
        0.027407899498939514,
        0.0567423552274704,
        -0.05837376043200493,
        -0.0403604581952095,
        -0.05805915966629982,
        0.054711513221263885,
        0.004814255516976118,
        0.056250251829624176,
        0.05609957128763199,
        0.017821570858359337,
        0.05125834047794342,
        0.022576099261641502,
        0.007426720578223467,
        -0.03632409870624542,
        0.0523308590054512,
        -0.05727775767445564,
        0.04473166912794113,
        0.038772422820329666,
        -0.05723176151514053,
        0.044244684278964996,
        -0.033506520092487335,
        -0.044907089322805405,
        -0.04825478047132492,
        -0.056426696479320526,
        0.0339360386133194,
        0.02861885353922844,
        -0.01757899299263954,
        -0.04257466271519661,
        0.05445292592048645,
        0.05774896964430809,
        0.0038409593980759382,
        0.03520115837454796,
        -0.05776260420680046,
        -0.05441444739699364,
        0.05685455724596977,
        0.04994216188788414,
        0.058135006576776505,
        -0.055977437645196915,
        -0.041149359196424484,
        0.05004025250673294,
        0.0271301306784153,
        0.05751517787575722,
        -0.0522695928812027,
        -0.029787058010697365,
        0.030639804899692535,
        -0.033688634634017944,
        0.027857854962348938,
        0.022591477259993553,
        -0.014467067085206509,
        0.009540905244648457,
        -0.01303942408412695,
        0.05793655663728714,
        -0.03912127763032913,
        0.010193913243710995,
        -0.05566431209445,
        -0.04805704951286316,
        -0.057250652462244034,
        0.05804498493671417,
        0.037280526012182236,
        -0.05614623799920082,
        -0.03596678003668785,
        -0.054105088114738464,
        -0.028795000165700912,
        -0.028441447764635086,
        -0.048448558896780014,
        -0.030640173703432083,
        0.05077783763408661,
        -0.050411101430654526,
        -0.0520196259021759,
        -0.01816730760037899,
        0.05440739542245865,
        0.029776185750961304,
        0.012764343991875648,
        -0.040748290717601776,
        0.05613376572728157,
        0.047495003789663315,
        0.04906860366463661,
        0.05461738258600235,
        0.03199581429362297,
        0.057060714811086655,
        -0.03982215374708176,
        0.05821756273508072,
        -0.031867485493421555,
        0.05301836505532265,
        0.05351388454437256,
        0.037259142845869064,
        0.041579145938158035,
        0.0498504675924778,
        -0.04229369014501572,
        0.03905294090509415,
        0.05245266854763031,
        -0.020676258951425552,
        0.05839280039072037,
        0.025294166058301926,
        -0.05621273070573807,
        -0.026098107919096947,
        -0.05838377773761749,
        0.028974665328860283,
        0.05836695432662964,
        -0.055211685597896576,
        0.05196324363350868,
        -0.05842428654432297,
        -0.058333128690719604,
        0.054934099316596985,
        -0.053542133420705795,
        0.057085271924734116,
        -0.025298669934272766,
        0.04462067782878876,
        0.05722096934914589,
        -0.05482187122106552,
        -0.015198959968984127,
        0.027155164629220963,
        -0.05747606232762337,
        -0.011382443830370903,
        -0.04012526199221611,
        0.04550250619649887,
        0.031098397448658943,
        0.02006497047841549,
        0.04779452085494995,
        0.043391305953264236,
        -0.020216383039951324,
        -0.053404830396175385,
        -0.006965341977775097,
        -0.03706973418593407,
        -0.0497356615960598,
        0.057513654232025146,
        0.021437056362628937,
        0.05049735680222511,
        0.04251931235194206,
        -0.05301334336400032,
        -0.009822777472436428,
        0.05652738735079765,
        0.044646915048360825,
        -0.056380193680524826,
        -0.035817280411720276,
        -0.027710076421499252,
        0.013468939810991287,
        -0.049460750073194504,
        0.005335409194231033,
        -0.0061673508025705814,
        0.02123226411640644,
        -0.05245919153094292,
        0.04116091504693031,
        0.05538544803857803,
        0.005312036722898483
    ],
    [
        -0.050613708794116974,
        -0.05241644009947777,
        -0.032099343836307526,
        0.05270242691040039,
        -0.04874338582158089,
        0.017667176201939583,
        -0.049093082547187805,
        -0.052807170897722244,
        0.052645470947027206,
        0.037286192178726196,
        0.0523761548101902,
        0.033966682851314545,
        -0.052463363856077194,
        -0.05160213261842728,
        -0.05271972343325615,
        0.04074705019593239,
        -0.017932724207639694,
        0.04002640023827553,
        -0.02020142786204815,
        0.04756472632288933,
        0.051221899688243866,
        -0.04948419705033302,
        0.04985635355114937,
        0.042049162089824677,
        -0.03131872043013573,
        0.05264316499233246,
        0.05117206275463104,
        0.05221765488386154,
        -0.050981711596250534,
        -0.05122874677181244,
        -0.013198383152484894,
        0.03947664797306061,
        0.04105217382311821,
        -0.0527660958468914,
        0.05260954052209854,
        -0.0527467243373394,
        -0.052036136388778687,
        -0.04874778911471367,
        0.032321564853191376,
        -0.0519755519926548,
        -0.01641974225640297,
        0.05185483768582344,
        0.04847387596964836,
        0.05153154954314232,
        -0.038044512271881104,
        -0.03371153771877289,
        -0.02852797321975231,
        0.05280165746808052,
        0.05280987173318863,
        -0.04534076899290085,
        0.052036404609680176,
        -0.04434485733509064,
        -0.024775933474302292,
        -0.0526697151362896,
        -0.028942735865712166,
        0.026103710755705833,
        -0.01656673662364483,
        0.04145872965455055,
        0.04628380760550499,
        -0.031875129789114,
        -0.044057030230760574,
        -0.02787403203547001,
        -0.0520731545984745,
        -0.05171135440468788,
        -0.02774820104241371,
        -0.052713874727487564,
        -0.04608209431171417,
        -0.03382387012243271,
        0.052815962582826614,
        -0.04580625146627426,
        0.03933165967464447,
        0.014921589754521847,
        0.051793720573186874,
        0.05224635824561119,
        0.052319176495075226,
        0.014223317615687847,
        0.049551691859960556,
        0.05284854769706726,
        0.03590958192944527,
        -0.05148482695221901,
        -0.05153176933526993,
        0.03488115966320038,
        -0.05284440517425537,
        0.048486318439245224,
        -0.052639614790678024,
        0.01565493643283844,
        -0.00017664475308265537,
        0.049772441387176514,
        -0.04864334315061569,
        -0.015240378677845001,
        -0.05283157899975777,
        -0.0500219389796257,
        -0.021266551688313484,
        0.03745292127132416,
        -0.027600808069109917,
        0.016097694635391235,
        0.03319711610674858,
        -0.045463718473911285,
        -0.041297201067209244,
        0.04896070808172226,
        -0.0528477318584919,
        0.05276906490325928,
        0.05284100025892258,
        0.04658579081296921,
        -0.05067700147628784,
        -0.04846388101577759,
        -0.05229899287223816,
        0.05234718695282936,
        -0.05284101516008377,
        0.016508353874087334,
        -0.008063475601375103,
        -0.052612435072660446,
        -0.051811620593070984,
        0.035243920981884,
        0.042297642678022385,
        0.022468164563179016,
        0.04294734448194504,
        -0.049998681992292404,
        0.02959240972995758,
        -0.0512106791138649,
        0.040960848331451416,
        -0.052624091506004333,
        0.05099168047308922,
        -0.05281972512602806,
        -0.050505317747592926,
        0.05284004285931587,
        -0.02743186615407467,
        -0.05167388170957565,
        0.052067991346120834,
        -0.05246993899345398,
        -0.0007202275446616113,
        -0.04350709542632103,
        -0.03304531052708626,
        -0.040268074721097946,
        -0.05279453098773956,
        -0.05197368189692497,
        0.0528293140232563,
        -0.02663012221455574,
        -0.04713326692581177,
        0.05139295011758804,
        -0.051218703389167786,
        -0.05277896672487259,
        0.047793272882699966,
        0.05282417684793472,
        -0.013803232461214066,
        -0.047449741512537,
        0.04391360282897949,
        -0.0519494004547596,
        -0.014441186562180519,
        0.04207288846373558,
        -0.052342332899570465,
        -0.05281054973602295,
        0.04656970128417015,
        0.029544569551944733,
        0.00663251057267189,
        -0.05284050107002258,
        -0.05284171923995018,
        0.052502766251564026,
        0.03440497815608978,
        0.03393292427062988,
        -0.04778503254055977,
        -0.002424660138785839,
        0.05249291658401489,
        0.02234744466841221,
        -0.05278133600950241,
        0.00989837758243084,
        0.049224987626075745,
        0.020542964339256287,
        0.014495671726763248,
        0.024683162569999695,
        0.052059199661016464,
        0.042399775236845016,
        -0.018604684621095657,
        0.0480746328830719,
        0.035484347492456436,
        0.04878925532102585,
        0.04500313848257065,
        -0.05240774154663086,
        0.0453801192343235,
        -0.05165484547615051,
        -0.04261047765612602,
        0.05276383459568024,
        -0.05275517329573631,
        -0.05226432904601097,
        0.052051376551389694,
        -0.041232507675886154,
        0.05130946263670921,
        0.052642714232206345,
        -0.04491713270545006,
        -0.052475571632385254,
        -0.052457183599472046,
        -0.012017954140901566,
        0.049928974360227585,
        -0.00325111486017704,
        -0.052599430084228516,
        -0.03799097239971161,
        0.05011231079697609,
        0.04686473309993744,
        -0.04268122836947441,
        0.0524795763194561,
        0.052840180695056915,
        -0.021492647007107735,
        0.04432054981589317,
        -0.051633890718221664,
        0.01941576786339283,
        -0.04381081834435463,
        0.052848104387521744,
        -0.04848185181617737,
        -0.006431116256862879,
        0.050673313438892365,
        -0.05094753950834274,
        -0.04180382937192917,
        -0.04226217046380043,
        0.05275991931557655,
        -0.03349420800805092,
        -0.05249197781085968,
        -0.029319535940885544,
        -0.05069703236222267,
        0.052120815962553024,
        0.03462562337517738,
        0.026672111824154854,
        0.04328878968954086,
        -0.01843360811471939,
        0.030861184000968933,
        0.05284278094768524,
        -0.05080007016658783,
        0.05148044601082802,
        -0.051738251000642776,
        0.052802398800849915,
        -0.05170268937945366,
        0.03205815702676773,
        -0.05254379287362099,
        0.012304309755563736,
        0.048053234815597534,
        -0.052725568413734436,
        0.05214093625545502,
        0.05194113776087761,
        -0.05101221427321434,
        0.05161880701780319,
        0.052842624485492706,
        0.05018363147974014,
        -0.038795504719018936,
        -0.04083036631345749,
        -0.05168437212705612,
        0.052342694252729416,
        0.020354662090539932,
        0.03874381259083748,
        0.048977918922901154,
        0.05185052379965782,
        0.04655979573726654,
        0.050367750227451324,
        -0.052810169756412506,
        0.05007936432957649,
        -0.051457010209560394,
        0.050657231360673904,
        -0.0477139912545681,
        0.050531696528196335,
        -0.052207984030246735,
        0.03591042757034302,
        0.006764866877347231,
        -0.04967267066240311,
        -0.052082933485507965,
        -0.05201277136802673,
        0.05267024785280228,
        -0.05253460258245468,
        0.052789922803640366,
        0.052845701575279236,
        0.046641185879707336,
        0.01364927552640438,
        -0.03691242262721062,
        -0.024467255920171738,
        -0.05284469574689865,
        0.052752524614334106,
        -0.03786584734916687,
        -0.03577306866645813,
        0.00005166514893062413,
        -0.047877442091703415,
        -0.05092180147767067,
        -0.05138486623764038,
        -0.02992967516183853,
        -0.05277888849377632,
        0.05117393285036087,
        0.05009257048368454,
        0.0366629995405674,
        0.0012961608590558171,
        0.04750049114227295,
        -0.05230852589011192,
        0.05262373015284538,
        -0.0036968945059925318,
        0.03694981336593628,
        -0.05188246816396713,
        -0.05265360698103905,
        -0.05272332951426506,
        -0.042646944522857666,
        -0.0518207848072052,
        -0.051770176738500595,
        0.052847959101200104,
        0.010405016131699085,
        -0.0490889698266983,
        0.052689749747514725,
        0.052800897508859634,
        -0.05138838291168213,
        0.001518106902949512,
        0.05215909332036972,
        -0.05265752226114273,
        0.048507846891880035,
        0.05076315626502037,
        0.019957274198532104,
        -0.006748932413756847,
        -0.010560397058725357,
        0.04325081408023834,
        0.04752374812960625,
        0.015548212453722954,
        -0.051296770572662354,
        0.04425337538123131,
        -0.050881579518318176,
        0.03076065145432949,
        0.015918036922812462,
        0.05122096464037895,
        0.01172007154673338,
        0.052843157202005386,
        -0.052477914839982986,
        0.014349506236612797,
        -0.05263030156493187,
        0.052537817507982254,
        -0.0070498231798410416,
        -0.04781206697225571,
        -0.048217128962278366,
        -0.04952072352170944,
        -0.011604701168835163,
        0.051836851984262466,
        -0.051143404096364975,
        -0.04975337162613869,
        -0.05256643518805504,
        0.04949808120727539,
        0.050898656249046326,
        -0.02715054340660572,
        0.04968476668000221,
        0.03034818172454834,
        0.017873205244541168,
        -0.04967383295297623,
        -0.05245978757739067,
        0.05275111645460129,
        -0.033808473497629166,
        -0.01478731818497181,
        0.014473313465714455,
        0.02085798978805542,
        0.05100519582629204,
        -0.04944519326090813,
        0.04909238591790199,
        -0.047177527099847794,
        0.020384719595313072,
        -0.0030939229764044285,
        -0.05277535691857338,
        0.04965350404381752,
        0.04014602676033974,
        -0.05240175500512123,
        -0.0503079779446125,
        0.04820813238620758,
        -0.052097778767347336,
        0.04079510644078255,
        0.052369870245456696,
        -0.040253911167383194,
        -0.0005740238702856004,
        0.05144963786005974,
        -0.019356930628418922,
        0.052795033901929855,
        -0.05273493006825447,
        0.000540395500138402,
        -0.05083378776907921,
        -0.04198230057954788,
        -0.04503173381090164,
        0.05206933990120888,
        -0.04473208636045456,
        0.048177581280469894,
        0.026027271524071693,
        0.0410606786608696,
        0.0510522685945034,
        0.05269629508256912,
        -0.05045841261744499,
        -0.0006089952075853944,
        0.051594577729701996,
        -0.05225396156311035,
        -0.017293589189648628,
        -0.05092493072152138,
        -0.052725955843925476,
        -0.05204279348254204,
        -0.05248218774795532,
        -0.052305109798908234,
        -0.052125025540590286,
        -0.05098767578601837,
        -0.05284371227025986,
        -0.05284085497260094,
        0.016861991956830025,
        0.02291746437549591,
        0.05267765372991562,
        0.005479520186781883,
        0.016279973089694977,
        -0.05243057757616043,
        -0.05269920080900192,
        -0.015149145387113094,
        0.052848223596811295,
        -0.03227410465478897,
        0.05264081805944443,
        -0.051961950957775116,
        -0.012126748450100422,
        -0.04740704223513603,
        -0.051809415221214294,
        0.052824120968580246,
        -0.03140771761536598,
        0.05273892730474472,
        0.05283995717763901,
        0.008144930936396122,
        -0.03291985020041466,
        0.05063196271657944,
        0.0003794236108660698,
        0.04432909935712814,
        -0.0464903824031353,
        -0.016485057771205902,
        -0.005933634005486965,
        0.01596217229962349,
        -0.05186860263347626,
        0.05255056917667389,
        -0.05271010473370552,
        -0.036451470106840134,
        -0.04288079962134361,
        0.020910684019327164,
        -0.049553416669368744,
        0.04839710146188736,
        -0.05265449732542038,
        0.05248679593205452,
        -0.05031086504459381,
        -0.05193166807293892,
        -0.021550241857767105,
        -0.05249491706490517,
        -0.03400377556681633,
        -0.042300015687942505,
        0.02772226370871067,
        -0.040932416915893555,
        -0.04727451130747795,
        -0.04735427722334862,
        0.05232861638069153,
        -0.039128467440605164,
        0.05235663428902626,
        -0.037469714879989624,
        0.05281345918774605,
        0.05257539451122284,
        -0.044541340321302414,
        0.025252576917409897,
        0.05280439555644989,
        0.0528002493083477,
        0.04588821902871132,
        0.0517236590385437,
        0.05267789214849472,
        0.05270935222506523,
        -0.045104335993528366,
        0.04652196168899536,
        0.01989695616066456,
        0.050863903015851974,
        0.05267273262143135,
        0.03939557448029518,
        -0.05284326896071434,
        0.05283588171005249,
        0.025428587570786476,
        0.052820395678281784,
        0.05278719589114189,
        -0.05237484723329544,
        0.05278957635164261,
        -0.04835554584860802,
        -0.052414219826459885,
        0.05009836703538895,
        -0.052838340401649475,
        0.05277377739548683,
        -0.019540240988135338,
        -0.052136462181806564,
        0.04074554517865181,
        -0.045560870319604874,
        0.05260578915476799,
        0.0036627338267862797,
        -0.05253022536635399,
        -0.04909435659646988,
        -0.052690744400024414,
        -0.052807021886110306,
        0.04902956262230873,
        -0.004337188322097063,
        0.05090727657079697,
        0.03176763281226158,
        0.05244436115026474,
        -0.05144498497247696,
        0.04914021119475365,
        0.049736421555280685,
        0.031674761325120926,
        0.05137617513537407,
        -0.046045273542404175,
        -0.006279702764004469,
        -0.04858245700597763,
        -0.052838604897260666,
        -0.0017194269457831979,
        0.051645323634147644,
        0.05214698612689972,
        -0.04940170794725418,
        -0.026828743517398834,
        -0.05249514430761337,
        -0.05278145894408226,
        0.01648343913257122,
        -0.010030632838606834,
        0.043099887669086456,
        0.014682777225971222,
        0.05284273624420166,
        -0.049312710762023926,
        0.05219821631908417,
        -0.05240451544523239
    ],
    [
        -0.05307473614811897,
        -0.0533192902803421,
        -0.019349219277501106,
        -0.01960422657430172,
        -0.025344470515847206,
        -0.023554423823952675,
        0.04972578585147858,
        -0.05314330384135246,
        0.053125470876693726,
        -0.05208675190806389,
        0.05265099182724953,
        0.03955702483654022,
        -0.052117425948381424,
        -0.05165278911590576,
        -0.04244808852672577,
        0.04592803120613098,
        0.028709037229418755,
        0.053307127207517624,
        -0.0533016137778759,
        0.008784900419414043,
        -0.02716761827468872,
        0.0366358682513237,
        0.053277742117643356,
        0.03311111032962799,
        -0.05330388620495796,
        0.04037350416183472,
        -0.013880116865038872,
        0.05318779870867729,
        -0.0523218996822834,
        0.032279618084430695,
        -0.052940357476472855,
        0.051887787878513336,
        0.04192331060767174,
        -0.05315396189689636,
        0.04818255081772804,
        -0.05293475463986397,
        -0.05226242169737816,
        -0.05326274037361145,
        -0.05331915244460106,
        -0.05331893637776375,
        0.053223103284835815,
        -0.022088702768087387,
        0.03504399582743645,
        0.05318117514252663,
        0.02201707474887371,
        -0.05262456461787224,
        -0.04583187773823738,
        0.053292304277420044,
        0.05308540537953377,
        -0.050003454089164734,
        0.04641994461417198,
        0.0510757677257061,
        0.003246224718168378,
        -0.05323389917612076,
        0.05150043964385986,
        -0.04799604415893555,
        -0.053282566368579865,
        -0.043529920279979706,
        -0.009106527082622051,
        0.031982894986867905,
        -0.05318208783864975,
        0.04003922641277313,
        -0.05184809863567352,
        0.025140784680843353,
        -0.038195349276065826,
        -0.05078001320362091,
        -0.05144743621349335,
        -0.026659242808818817,
        -0.030188381671905518,
        -0.04758700728416443,
        -0.011749987490475178,
        0.02464786171913147,
        0.05330764502286911,
        0.05188455432653427,
        0.05331423133611679,
        -0.03377131000161171,
        -0.04697858914732933,
        0.05330636724829674,
        -0.04167618229985237,
        0.0006396514945663512,
        -0.04662426933646202,
        -0.052922941744327545,
        -0.05292490869760513,
        0.030486660078167915,
        -0.05327979475259781,
        -0.018107272684574127,
        -0.052659112960100174,
        0.0501369871199131,
        0.052751243114471436,
        0.0034811676014214754,
        -0.052963532507419586,
        0.045812077820301056,
        -0.050209321081638336,
        -0.053207263350486755,
        0.02738536335527897,
        0.006448487285524607,
        -0.046240463852882385,
        -0.05297935754060745,
        -0.05285973846912384,
        -0.04377572983503342,
        -0.05127330869436264,
        0.05325876548886299,
        0.035419952124357224,
        0.025142550468444824,
        -0.04597475752234459,
        -0.03234034404158592,
        0.03139837086200714,
        -0.04341977462172508,
        -0.05203545466065407,
        0.051948145031929016,
        -0.04298338666558266,
        -0.05327260494232178,
        0.02280544862151146,
        0.012389658018946648,
        -0.053139109164476395,
        0.01649930328130722,
        -0.05158603563904762,
        -0.049491144716739655,
        -0.043716318905353546,
        0.05304288491606712,
        0.03203080594539642,
        0.02464287541806698,
        0.053278807550668716,
        -0.052655044943094254,
        -0.039020370692014694,
        0.042695052921772,
        0.026849310845136642,
        0.05297916755080223,
        0.0529056079685688,
        0.035907745361328125,
        0.04787050932645798,
        -0.04437177628278732,
        0.05302681401371956,
        0.04420175775885582,
        -0.03562803193926811,
        -0.048101723194122314,
        0.04263365641236305,
        0.05327910929918289,
        0.026409078389406204,
        0.05050848424434662,
        0.007738783489912748,
        0.033745042979717255,
        0.05088499188423157,
        0.052781619131565094,
        -0.050327882170677185,
        0.04610336944460869,
        -0.048101313412189484,
        0.05211000144481659,
        -0.05003195255994797,
        0.053005095571279526,
        -0.04261937737464905,
        -0.05163196474313736,
        0.04886056110262871,
        0.0022158161737024784,
        0.0533192940056324,
        -0.04677577316761017,
        -0.0533192940056324,
        -0.05018649622797966,
        -0.026186171919107437,
        0.04414118826389313,
        0.05331919342279434,
        0.03908490017056465,
        0.05299196019768715,
        0.03521384671330452,
        -0.05029894411563873,
        0.04971249774098396,
        -0.04172435775399208,
        0.043721459805965424,
        0.03426783159375191,
        0.04701303690671921,
        0.052170492708683014,
        0.0384611152112484,
        -0.05330221727490425,
        0.05247073993086815,
        -0.05305323004722595,
        -0.01586698368191719,
        -0.053293418139219284,
        -0.026227355003356934,
        0.05172630399465561,
        -0.051371604204177856,
        -0.04977729544043541,
        0.0533192902803421,
        -0.05263150483369827,
        -0.05304783210158348,
        0.036591269075870514,
        0.03615082800388336,
        0.039785608649253845,
        0.051834892481565475,
        -0.0527239553630352,
        -0.0530540905892849,
        -0.04642724618315697,
        -0.049384795129299164,
        0.0528048500418663,
        0.02604321576654911,
        0.006989900954067707,
        -0.051766932010650635,
        0.05105997249484062,
        0.05127743259072304,
        -0.05192697420716286,
        0.050079986453056335,
        0.05107846483588219,
        -0.050766050815582275,
        -0.05327273905277252,
        -0.044205013662576675,
        0.04248281568288803,
        0.052769094705581665,
        -0.006920276675373316,
        0.006001762580126524,
        -0.008850383572280407,
        0.05330783873796463,
        -0.04764101281762123,
        -0.04209453612565994,
        -0.01716865412890911,
        0.053237006068229675,
        -0.053228870034217834,
        0.053312744945287704,
        -0.02604539506137371,
        -0.038452330976724625,
        0.053284235298633575,
        0.05331878364086151,
        0.03347523510456085,
        -0.05320383980870247,
        -0.052151232957839966,
        -0.052566107362508774,
        0.05330214649438858,
        -0.04506988450884819,
        0.0506138801574707,
        -0.05306316167116165,
        -0.05188808590173721,
        -0.04904649406671524,
        -0.01781279966235161,
        -0.05297069624066353,
        0.04008011147379875,
        0.04087112471461296,
        -0.03455168753862381,
        0.05292179435491562,
        0.052137505263090134,
        0.05298955366015434,
        0.0473710261285305,
        0.05329113081097603,
        -0.004858123138546944,
        0.053263306617736816,
        0.05182189121842384,
        -0.050802964717149734,
        0.05222146585583687,
        -0.020863516256213188,
        0.015278294682502747,
        0.05315840616822243,
        0.049885038286447525,
        -0.05314909666776657,
        0.022094085812568665,
        -0.03467949479818344,
        0.02139868214726448,
        -0.05088435113430023,
        0.0483812652528286,
        0.005846718791872263,
        -0.05184667930006981,
        -0.044910892844200134,
        0.05260568484663963,
        0.053303416818380356,
        -0.05264697223901749,
        -0.05314640700817108,
        -0.016580477356910706,
        0.04944842308759689,
        -0.053254853934049606,
        0.046644534915685654,
        0.053319238126277924,
        0.04270477965474129,
        -0.027652259916067123,
        0.046489566564559937,
        -0.024396033957600594,
        -0.05330280587077141,
        0.049405183643102646,
        -0.05315583944320679,
        -0.052821047604084015,
        0.02930140122771263,
        -0.04453638195991516,
        -0.04643448442220688,
        0.0513124093413353,
        0.01428708154708147,
        -0.04329174384474754,
        -0.019671330228447914,
        -0.029641704633831978,
        0.025872906669974327,
        0.053186435252428055,
        -0.04790171980857849,
        -0.010137463919818401,
        -0.04914695769548416,
        0.04275072365999222,
        0.0404663011431694,
        -0.051560524851083755,
        0.05324116721749306,
        -0.035625018179416656,
        -0.02234465442597866,
        0.0042192526161670685,
        -0.05290497839450836,
        0.05325647071003914,
        0.05331238731741905,
        0.014558087103068829,
        0.05178426206111908,
        -0.05328233540058136,
        0.04717079922556877,
        -0.02399289235472679,
        0.04460860416293144,
        -0.05322914198040962,
        0.012294240295886993,
        -0.02368268370628357,
        -0.04923001676797867,
        -0.0448186956346035,
        0.05313434824347496,
        0.046045172959566116,
        -0.05073429271578789,
        -0.009710902348160744,
        -0.042634230107069016,
        -0.05331926420331001,
        -0.05096528306603432,
        -0.01923179253935814,
        -0.052042681723833084,
        0.05119486525654793,
        0.01803944632411003,
        0.05271511152386665,
        0.053077131509780884,
        -0.04963792487978935,
        -0.05244957655668259,
        0.051350660622119904,
        -0.030885912477970123,
        0.05118611827492714,
        -0.030618807300925255,
        0.03879830613732338,
        -0.0357852429151535,
        -0.05095633119344711,
        -0.05071776360273361,
        -0.05032111331820488,
        -0.04993923380970955,
        0.029929177835583687,
        0.029036715626716614,
        -0.02319980040192604,
        0.05174275487661362,
        -0.025642575696110725,
        0.021247724071145058,
        -0.05026709660887718,
        0.031574685126543045,
        -0.03389153257012367,
        0.05090922862291336,
        0.05331927910447121,
        0.03233208879828453,
        0.03861042484641075,
        0.053008534014225006,
        0.008652856573462486,
        0.009389208629727364,
        -0.05331925302743912,
        -0.05175217613577843,
        -0.03645755723118782,
        0.035037510097026825,
        0.05329861491918564,
        -0.0031334576196968555,
        -0.049812063574790955,
        -0.05320898815989494,
        -0.049463123083114624,
        -0.05309464782476425,
        0.04488571360707283,
        0.05223163962364197,
        0.036564480513334274,
        -0.05059072747826576,
        -0.05329811945557594,
        -0.05307043716311455,
        0.053100887686014175,
        -0.031217338517308235,
        0.0013038706965744495,
        -0.04993004724383354,
        0.007484595291316509,
        -0.052908141165971756,
        -0.05307441204786301,
        -0.05255613103508949,
        0.05195174738764763,
        0.03287017345428467,
        -0.05331741273403168,
        0.05330588296055794,
        -0.0046288082376122475,
        -0.05113624781370163,
        0.018882960081100464,
        0.030181987211108208,
        -0.036627430468797684,
        0.05311020836234093,
        0.017122095450758934,
        -0.053281571716070175,
        -0.026577632874250412,
        -0.05215415731072426,
        -0.050594206899404526,
        -0.05302745848894119,
        -0.051450323313474655,
        -0.04912307858467102,
        -0.05303580313920975,
        -0.05120529234409332,
        -0.05300115421414375,
        0.047162339091300964,
        0.02977331168949604,
        -0.024611089378595352,
        0.046785619109869,
        -0.051506586372852325,
        -0.04562702402472496,
        0.05326621234416962,
        -0.05237605795264244,
        0.053295936435461044,
        -0.052313182502985,
        0.051750972867012024,
        0.03243448585271835,
        -0.05324246734380722,
        0.036674123257398605,
        -0.048068173229694366,
        0.020151440054178238,
        0.031590718775987625,
        0.05326004698872566,
        -0.005194252822548151,
        0.05295828729867935,
        -0.03181726485490799,
        -0.05323801189661026,
        0.04348647594451904,
        -0.04122161865234375,
        -0.05331902578473091,
        0.002191944280639291,
        0.05331842973828316,
        0.05305083096027374,
        -0.053288668394088745,
        0.04095260053873062,
        -0.036208443343639374,
        -0.051292650401592255,
        0.0524122416973114,
        0.02434411831200123,
        -0.05215537175536156,
        -0.05085558071732521,
        0.0169450081884861,
        -0.05326045677065849,
        -0.05284532159566879,
        -0.02516176365315914,
        -0.052742671221494675,
        -0.052285902202129364,
        -0.0015574328135699034,
        -0.012074883095920086,
        -0.052943624556064606,
        -0.04892465099692345,
        -0.048896338790655136,
        0.0016851563705131412,
        0.0503949373960495,
        0.053319282829761505,
        -0.019201790913939476,
        0.05009700357913971,
        -0.04855583608150482,
        0.04482470080256462,
        0.05142642930150032,
        0.03802134469151497,
        0.052891846746206284,
        0.05012524873018265,
        -0.053295135498046875,
        -0.05328819528222084,
        -0.04170888662338257,
        -0.052076149731874466,
        -0.04625415429472923,
        0.0031047984957695007,
        0.051261987537145615,
        0.023763606324791908,
        0.029342936351895332,
        0.024914590641856194,
        0.0532572865486145,
        0.053310196846723557,
        0.05308494716882706,
        -0.047674600034952164,
        0.05331728607416153,
        0.023340240120887756,
        -0.0520407073199749,
        -0.0485062375664711,
        -0.041870031505823135,
        -0.017275504767894745,
        0.053112369030714035,
        -0.025174297392368317,
        0.008737679570913315,
        -0.012823320925235748,
        0.05324462056159973,
        0.020999085158109665,
        0.015082880854606628,
        -0.05130113288760185,
        0.051098961383104324,
        -0.04297615960240364,
        0.046522144228219986,
        -0.0515301488339901,
        0.051977623254060745,
        0.0525645911693573,
        0.03666602820158005,
        -0.043996039777994156,
        0.05324288457632065,
        -0.04913371801376343,
        -0.05317116528749466,
        0.05241921916604042,
        -0.05237186700105667,
        -0.029826408252120018,
        0.015208258293569088,
        -0.049320288002491,
        0.01935502327978611,
        0.04802529886364937,
        -0.03701018914580345,
        0.05331891030073166,
        0.01974138803780079,
        0.041397448629140854,
        0.02066824585199356,
        0.05314343050122261,
        0.0415472649037838,
        -0.016019023954868317,
        -0.02906121499836445,
        0.05324311554431915,
        -0.0533192940056324,
        0.008270427584648132,
        -0.05331181734800339
    ],
    [
        -0.039420969784259796,
        -0.05389492213726044,
        -0.03408607840538025,
        0.03892926871776581,
        -0.049746327102184296,
        0.03877795487642288,
        0.03229928016662598,
        -0.05387458577752113,
        0.018540579825639725,
        -0.011337957344949245,
        0.05300890654325485,
        0.053786277770996094,
        -0.03954614698886871,
        -0.05316276475787163,
        -0.05327199772000313,
        0.05266512930393219,
        0.033655568957328796,
        0.053878337144851685,
        -0.04954872652888298,
        0.014412162825465202,
        -0.04565507546067238,
        0.05345214158296585,
        0.0445917472243309,
        0.0020915642380714417,
        -0.04718991369009018,
        0.0531332865357399,
        0.05387447401881218,
        0.05195595324039459,
        -0.052544984966516495,
        0.05386601388454437,
        0.004333936143666506,
        0.053610190749168396,
        -0.04794241115450859,
        -0.05387256667017937,
        0.05378449335694313,
        -0.042502254247665405,
        -0.053754113614559174,
        -0.05379881337285042,
        0.034118492156267166,
        -0.0505838468670845,
        -0.0003851126821245998,
        0.032764263451099396,
        0.04634224623441696,
        0.04998859763145447,
        -0.023409729823470116,
        0.016898922622203827,
        0.04850472882390022,
        0.053422961384058,
        0.053869426250457764,
        -0.04020771384239197,
        0.05365296080708504,
        0.05357363075017929,
        0.041334863752126694,
        0.04816090688109398,
        -0.0023889713920652866,
        -0.04628254100680351,
        -0.05247668921947479,
        0.04132964089512825,
        -0.05349353700876236,
        0.05389152839779854,
        -0.053818944841623306,
        0.047730278223752975,
        -0.05304696410894394,
        -0.04698023572564125,
        0.049327317625284195,
        -0.0538611114025116,
        0.025106025859713554,
        -0.024792088195681572,
        -0.052972421050071716,
        -0.053180593997240067,
        -0.04944095388054848,
        -0.0328877754509449,
        0.053895942866802216,
        -0.0006289966986514628,
        0.05372847244143486,
        -0.00356018147431314,
        0.03738277405500412,
        0.0538971945643425,
        0.03750500828027725,
        -0.04920899495482445,
        -0.04799159616231918,
        0.015398632735013962,
        -0.05368337407708168,
        0.05286471173167229,
        -0.05313774570822716,
        0.04165143519639969,
        0.05275656655430794,
        0.01616048999130726,
        0.050891995429992676,
        0.03298639878630638,
        -0.05368904024362564,
        -0.05145348235964775,
        0.025222495198249817,
        -0.026004759594798088,
        0.018160812556743622,
        0.04231058061122894,
        0.03025660663843155,
        -0.05294065922498703,
        -0.04581223428249359,
        -0.024927683174610138,
        -0.05389382690191269,
        0.05389780178666115,
        0.053897686302661896,
        0.049617260694503784,
        0.02036947011947632,
        -0.04158370941877365,
        0.05140645056962967,
        -0.04962660372257233,
        -0.045823756605386734,
        0.041478242725133896,
        0.00512646185234189,
        -0.04767654463648796,
        0.02175218239426613,
        -0.030694881454110146,
        -0.02451283298432827,
        0.002273994032293558,
        -0.033955223858356476,
        -0.05146180838346481,
        0.02691533975303173,
        -0.053015612065792084,
        0.050631992518901825,
        0.052909258753061295,
        0.05316201597452164,
        -0.052019648253917694,
        -0.01892894320189953,
        -0.04107796400785446,
        0.05260658636689186,
        0.05012454465031624,
        0.053735822439193726,
        -0.04230538010597229,
        0.05340108275413513,
        -0.05199861899018288,
        -0.05014171451330185,
        0.011377752758562565,
        -0.05004258081316948,
        -0.04837314411997795,
        0.05388841778039932,
        0.053479280322790146,
        0.05204200744628906,
        0.045987799763679504,
        -0.042145125567913055,
        0.006769292987883091,
        0.02421111986041069,
        0.0506187304854393,
        -0.04958270117640495,
        0.04847084730863571,
        0.05348430573940277,
        -0.048752330243587494,
        -0.053090739995241165,
        0.05329464003443718,
        -0.05146636441349983,
        -0.05370708182454109,
        0.023714812472462654,
        -0.005464896559715271,
        0.053654324263334274,
        -0.05334208160638809,
        -0.053868550807237625,
        -0.052349500358104706,
        0.021881844848394394,
        0.04637599363923073,
        0.05370123311877251,
        0.04587549343705177,
        0.05065048485994339,
        0.0515703409910202,
        -0.020129619166254997,
        0.03406427055597305,
        -0.04505341872572899,
        -0.0495477095246315,
        -0.04619020223617554,
        0.012591804377734661,
        0.034535184502601624,
        0.053740911185741425,
        0.051284998655319214,
        -0.04718068987131119,
        -0.05223977193236351,
        0.042342353612184525,
        0.0528002567589283,
        -0.04957067593932152,
        0.039062194526195526,
        -0.0538945347070694,
        -0.04831971973180771,
        0.053801264613866806,
        0.030159803107380867,
        -0.0534123070538044,
        0.05309661105275154,
        0.046760767698287964,
        -0.053693048655986786,
        0.05374175310134888,
        -0.047221992164850235,
        0.002855787519365549,
        -0.04295183718204498,
        0.05044988915324211,
        0.05363823100924492,
        0.052852973341941833,
        -0.05122053995728493,
        0.04321044310927391,
        0.03945000469684601,
        0.046428047120571136,
        -0.04802437126636505,
        0.053324684500694275,
        0.05247517302632332,
        0.022299641743302345,
        -0.053873609751462936,
        -0.053517475724220276,
        0.002868691924959421,
        0.05084802582859993,
        0.053146377205848694,
        0.05012034997344017,
        -0.05305340886116028,
        0.0538715198636055,
        -0.01040696632117033,
        0.05204751715064049,
        -0.03044741414487362,
        0.05380946397781372,
        -0.03871467337012291,
        -0.016021180897951126,
        -0.05012034997344017,
        0.01731848530471325,
        0.05386817827820778,
        0.04837781563401222,
        -0.05016764998435974,
        0.03720928728580475,
        0.0399845689535141,
        -0.051530029624700546,
        0.05386054515838623,
        -0.0538652129471302,
        0.05318109318614006,
        0.032272472977638245,
        0.04498414695262909,
        -0.05305883660912514,
        0.048761457204818726,
        -0.053849607706069946,
        -0.01863795705139637,
        0.05214495211839676,
        -0.0529589019715786,
        0.05001678317785263,
        0.05006435140967369,
        -0.015623295679688454,
        0.03576132282614708,
        0.05388860031962395,
        0.04228995367884636,
        0.05303708836436272,
        -0.05389762297272682,
        -0.04936232790350914,
        0.05341336131095886,
        0.02437479980289936,
        -0.04260837659239769,
        -0.05136188864707947,
        -0.022093290463089943,
        -0.052875105291604996,
        -0.02686888352036476,
        -0.05370150879025459,
        0.01384333148598671,
        -0.048461951315402985,
        -0.02282959595322609,
        -0.010881846770644188,
        -0.030186332762241364,
        -0.026830138638615608,
        0.031492505222558975,
        -0.04876115173101425,
        0.002618772443383932,
        -0.051169052720069885,
        0.052642494440078735,
        0.05338601395487785,
        -0.05358048900961876,
        0.04822459816932678,
        0.05388041213154793,
        0.053738776594400406,
        -0.05264229699969292,
        -0.04979320615530014,
        0.05166282877326012,
        -0.053877949714660645,
        -0.023535646498203278,
        -0.05205446854233742,
        -0.05339636653661728,
        -0.027302147820591927,
        -0.03457416966557503,
        0.030890051275491714,
        0.028757240623235703,
        -0.05280207097530365,
        -0.052338313311338425,
        0.030721180140972137,
        -0.007231374271214008,
        0.04673995077610016,
        0.0375300757586956,
        0.03205335512757301,
        0.05055839568376541,
        0.053286440670490265,
        0.05285120755434036,
        0.05361063778400421,
        0.04130461439490318,
        0.018558956682682037,
        -0.05370386689901352,
        0.042311396449804306,
        0.05032285302877426,
        -0.05371544510126114,
        0.05389674752950668,
        0.05375039204955101,
        -0.053812962025403976,
        0.05367906019091606,
        0.02559654787182808,
        0.018223147839307785,
        0.04911747947335243,
        -0.013577915728092194,
        -0.05321841314435005,
        -0.05228574573993683,
        -0.0011026911670342088,
        0.03158099576830864,
        0.053842853754758835,
        0.009778513573110104,
        0.014285581186413765,
        -0.004812521394342184,
        0.04605061188340187,
        0.038172103464603424,
        0.002089019864797592,
        -0.0524313822388649,
        0.0009868948254734278,
        -0.052301548421382904,
        0.05330174043774605,
        -0.027283400297164917,
        0.05289854854345322,
        0.03302127122879028,
        0.022387169301509857,
        0.017621785402297974,
        0.052615612745285034,
        0.03860170766711235,
        0.04837865009903908,
        0.03254925087094307,
        -0.05154666304588318,
        -0.04924032464623451,
        0.008286845870316029,
        0.01466277427971363,
        -0.03412830829620361,
        0.0016905185766518116,
        0.04717807099223137,
        0.05153254047036171,
        -0.047782182693481445,
        -0.050743430852890015,
        0.015816325321793556,
        0.039977479726076126,
        0.03731438145041466,
        0.028812279924750328,
        -0.052869535982608795,
        -0.050750359892845154,
        0.03302406519651413,
        0.03491871803998947,
        0.050265129655599594,
        0.051377784460783005,
        -0.049332812428474426,
        0.0488668791949749,
        -0.053789786994457245,
        -0.010906201787292957,
        -0.014097137376666069,
        -0.05155207961797714,
        0.02014843374490738,
        -0.025524627417325974,
        -0.0217503122985363,
        -0.052847590297460556,
        -0.04587467014789581,
        -0.05334478244185448,
        0.042868003249168396,
        0.043002404272556305,
        -0.041544076055288315,
        0.011423531919717789,
        -0.05367596074938774,
        -0.046890731900930405,
        0.053832340985536575,
        -0.05288621410727501,
        -0.03622712567448616,
        -0.038666464388370514,
        -0.045670103281736374,
        -0.0393313430249691,
        -0.04874911531805992,
        0.03606400266289711,
        0.046991609036922455,
        0.03866960480809212,
        -0.05228085443377495,
        0.04774598777294159,
        0.05356158688664436,
        0.041787657886743546,
        -0.017359808087348938,
        0.04658888652920723,
        -0.04973318427801132,
        0.03589098900556564,
        -0.016752460971474648,
        0.012357589788734913,
        0.001237242016941309,
        -0.051973190158605576,
        -0.04327017813920975,
        -0.03780741989612579,
        -0.053717296570539474,
        -0.026237936690449715,
        -0.05164698138833046,
        -0.051156073808670044,
        -0.04750308394432068,
        0.05217325687408447,
        0.05386137589812279,
        0.04278067126870155,
        -0.04716746136546135,
        -0.05380659177899361,
        -0.042161598801612854,
        0.05389714613556862,
        -0.04258487746119499,
        0.052003100514411926,
        -0.008201178163290024,
        0.05219648778438568,
        -0.018551703542470932,
        -0.05386245995759964,
        0.053831007331609726,
        -0.02826959267258644,
        -0.05148154869675636,
        -0.0315256267786026,
        0.04920186102390289,
        -0.006308299023658037,
        0.03431848809123039,
        -0.05339527130126953,
        0.051117438822984695,
        0.05326604098081589,
        -0.05022925138473511,
        -0.05247095227241516,
        0.0507158599793911,
        0.045193374156951904,
        0.05203944072127342,
        -0.0536235049366951,
        -0.02070290595293045,
        0.05227530002593994,
        0.03959381952881813,
        -0.0477614663541317,
        -0.050310928374528885,
        -0.04794367775321007,
        -0.028934434056282043,
        0.04405805096030235,
        -0.02116333693265915,
        -0.035039763897657394,
        0.02474961429834366,
        -0.05238786339759827,
        -0.053859710693359375,
        0.01940947398543358,
        0.027666037902235985,
        -0.044341228902339935,
        -0.053434304893016815,
        0.021592821925878525,
        -0.017417291179299355,
        0.05321413651108742,
        0.05344676226377487,
        -0.022992415353655815,
        0.04038195312023163,
        0.02570795826613903,
        -0.02576940506696701,
        0.0528838112950325,
        0.018895896151661873,
        0.05083383619785309,
        0.053766220808029175,
        0.053728993982076645,
        -0.05222611874341965,
        -0.05329970642924309,
        0.050289567559957504,
        -0.05376649275422096,
        0.05305931717157364,
        0.05353822559118271,
        -0.048185866326093674,
        -0.05276067554950714,
        0.053333114832639694,
        -0.04647330194711685,
        0.053882624953985214,
        0.05373033881187439,
        -0.05373576283454895,
        0.052858393639326096,
        -0.05359119176864624,
        -0.05359620228409767,
        0.05249125137925148,
        -0.03196444734930992,
        0.05281701683998108,
        0.051925938576459885,
        -0.05380285903811455,
        -0.03288695216178894,
        -0.03373025357723236,
        0.04983147978782654,
        -0.03697758913040161,
        -0.036243513226509094,
        0.0018997814040631056,
        0.04094122350215912,
        -0.04741230979561806,
        0.04389391466975212,
        -0.05267179757356644,
        0.05321904271841049,
        0.04821983352303505,
        0.052900057286024094,
        -0.05044836178421974,
        -0.0020549101755023003,
        0.05144728720188141,
        -0.04745713248848915,
        0.05380915477871895,
        -0.050151336938142776,
        0.0370962955057621,
        0.039554912596940994,
        -0.05338304489850998,
        0.0498737134039402,
        0.037932608276605606,
        -0.0045356894843280315,
        0.05377338454127312,
        0.0012441732687875628,
        -0.04799490049481392,
        0.05038495734333992,
        0.0536208376288414,
        -0.02769056335091591,
        0.03795003145933151,
        -0.032680097967386246,
        0.04960518330335617,
        -0.05388158559799194,
        0.05331192910671234,
        -0.038061708211898804
    ],
    [
        0.0553722009062767,
        0.0018931683152914047,
        -0.052238818258047104,
        -0.03835911676287651,
        0.059784311801195145,
        -0.05580323934555054,
        0.06190270930528641,
        -0.014338990673422813,
        -0.0018030006904155016,
        -0.03532099351286888,
        -0.045412689447402954,
        -0.03295866772532463,
        -0.05782582610845566,
        -0.026695236563682556,
        -0.05927248299121857,
        0.012620234861969948,
        -0.05764089152216911,
        0.05347832664847374,
        -0.03133015334606171,
        -0.021778207272291183,
        -0.057769548147916794,
        0.03837268799543381,
        0.06116950511932373,
        0.030143866315484047,
        -0.045395635068416595,
        0.01775279827415943,
        -0.023254169151186943,
        0.0026888905558735132,
        0.017264435067772865,
        -0.043242983520030975,
        -0.013745165430009365,
        0.0035771674010902643,
        0.0337628535926342,
        -0.054450999945402145,
        0.02649553120136261,
        -0.036384280771017075,
        -0.05747866630554199,
        -0.027827424928545952,
        0.05056871101260185,
        0.04094509035348892,
        -0.003843670478090644,
        0.010352288372814655,
        0.05741044878959656,
        -0.04348558932542801,
        0.05429348349571228,
        -0.0011351265711709857,
        0.016679884865880013,
        0.06230320408940315,
        0.04502347484230995,
        -0.0027648955583572388,
        0.0522332638502121,
        0.04467914253473282,
        0.060397278517484665,
        -0.0555456280708313,
        -0.03427822142839432,
        -0.041182395070791245,
        -0.057995185256004333,
        0.005898759700357914,
        -0.05197976157069206,
        -0.04131748154759407,
        -0.06159501150250435,
        0.040003761649131775,
        -0.05610277131199837,
        -0.048029497265815735,
        0.03732139244675636,
        0.008914208970963955,
        -0.045641303062438965,
        -0.059759341180324554,
        -0.005289294291287661,
        -0.06244580075144768,
        -0.03436203673481941,
        -0.06164815276861191,
        0.05479742959141731,
        0.04636361822485924,
        0.04580137878656387,
        -0.050185248255729675,
        0.0359349250793457,
        0.0612490177154541,
        0.03894387558102608,
        -0.033711981028318405,
        -0.0075765387155115604,
        0.03116043657064438,
        -0.05652444064617157,
        0.01303029339760542,
        -0.04583446681499481,
        0.012878526002168655,
        -0.06088817119598389,
        -0.051480863243341446,
        -0.056712035089731216,
        0.05204477533698082,
        -0.05626096948981285,
        0.008951208554208279,
        -0.05186863988637924,
        -0.023884641006588936,
        -0.0014319595647975802,
        -0.005612489301711321,
        -0.03155375272035599,
        -0.061328452080488205,
        -0.04953379929065704,
        0.042758431285619736,
        -0.06201685965061188,
        -0.0399034209549427,
        0.04601055383682251,
        0.02715948596596718,
        -0.06020618975162506,
        0.03646032139658928,
        0.05359619855880737,
        -0.013049447908997536,
        -0.05612211674451828,
        0.007696052081882954,
        0.001207917695865035,
        -0.05528045445680618,
        -0.06098213791847229,
        -0.026580898091197014,
        0.06177503988146782,
        0.008674951270222664,
        0.03122076578438282,
        -0.05993734672665596,
        -0.06072084978222847,
        -0.05523167923092842,
        -0.05038927122950554,
        0.031947411596775055,
        -0.038189034909009933,
        0.026176191866397858,
        -0.06088884919881821,
        -0.05680637061595917,
        -0.0012467438355088234,
        0.06033661216497421,
        0.061572350561618805,
        -0.05594315379858017,
        0.0520288422703743,
        -0.05110763758420944,
        0.04971836879849434,
        0.049939580261707306,
        -0.0528205968439579,
        -0.05025724694132805,
        -0.024839157238602638,
        -0.052852846682071686,
        0.03507542237639427,
        0.06196628510951996,
        0.05198892578482628,
        0.03606634587049484,
        0.04447057843208313,
        -0.0428897924721241,
        -0.03615940734744072,
        -0.032385434955358505,
        0.05979419872164726,
        0.05979115515947342,
        0.01202151644974947,
        -0.050439585000276566,
        -0.0011037137592211366,
        -0.05799340829253197,
        -0.059918567538261414,
        -0.028628826141357422,
        0.021715106442570686,
        -0.049020916223526,
        0.046565599739551544,
        0.03294142335653305,
        0.04594793915748596,
        0.03421841561794281,
        0.05507418140769005,
        0.016999416053295135,
        0.056520503014326096,
        -0.038785696029663086,
        0.017886536195874214,
        -0.03610176965594292,
        0.015457709319889545,
        -0.050562988966703415,
        -0.04434998705983162,
        0.05577169358730316,
        -0.013939081691205502,
        0.058782149106264114,
        -0.06138724461197853,
        -0.03228647634387016,
        -0.061239130795001984,
        0.03888588771224022,
        -0.0033900325652211905,
        -0.010296334512531757,
        0.012474513612687588,
        0.05709560588002205,
        0.0597878061234951,
        0.06246758997440338,
        0.06094999611377716,
        0.009647187776863575,
        0.045895423740148544,
        -0.056435294449329376,
        0.0023021693341434,
        0.06067735701799393,
        -0.025398308411240578,
        -0.05575947463512421,
        -0.04791690781712532,
        -0.015368529595434666,
        -0.030982250347733498,
        0.04091747850179672,
        -0.030525941401720047,
        0.03661981597542763,
        0.05385175719857216,
        0.042863015085458755,
        -0.03214261680841446,
        -0.030983133241534233,
        -0.04488883912563324,
        0.053071461617946625,
        0.0142219178378582,
        -0.03871961310505867,
        -0.03845905885100365,
        -0.04772140458226204,
        0.05037079378962517,
        0.046946387737989426,
        0.021900556981563568,
        0.04696526378393173,
        -0.04479293152689934,
        0.044350169599056244,
        -0.052829012274742126,
        -0.04026385769248009,
        0.025547407567501068,
        -0.04389095678925514,
        0.007136538624763489,
        0.05134136974811554,
        0.06130672246217728,
        0.02902347967028618,
        0.04622937738895416,
        -0.04515629634261131,
        0.005523663945496082,
        -0.06203465163707733,
        0.06227985769510269,
        -0.061637744307518005,
        0.02061718888580799,
        -0.05067936331033707,
        0.021811144426465034,
        -0.062157317996025085,
        -0.05072029307484627,
        -0.02529171295464039,
        -0.03193903714418411,
        0.056996941566467285,
        0.008464700542390347,
        0.0462508387863636,
        0.06041889265179634,
        0.04952239245176315,
        0.058542609214782715,
        0.062201693654060364,
        0.05485454946756363,
        0.054945506155490875,
        0.00027113372925668955,
        0.033196110278367996,
        -0.003818017663434148,
        0.0018520221346989274,
        0.03400711715221405,
        -0.012792154215276241,
        -0.03329084813594818,
        0.0385020449757576,
        -0.04966815561056137,
        -0.060088444501161575,
        -0.06087541952729225,
        -0.060323316603899,
        0.04719936102628708,
        -0.011652831919491291,
        0.0508870929479599,
        -0.03576513007283211,
        0.06214236095547676,
        0.0625222697854042,
        -0.024489454925060272,
        0.04478990659117699,
        -0.05211678147315979,
        0.037985909730196,
        -0.057448141276836395,
        0.05269233509898186,
        -0.018386581912636757,
        -0.008893141523003578,
        -0.03851303458213806,
        -0.05873102694749832,
        0.0027422746643424034,
        -0.060063961893320084,
        0.005406777374446392,
        -0.03387101739645004,
        -0.02866537496447563,
        -0.0018915183609351516,
        -0.053276993334293365,
        0.0357278436422348,
        -0.03892355039715767,
        -0.037019919604063034,
        -0.06123729050159454,
        -0.031275998800992966,
        0.03201339766383171,
        0.01652972586452961,
        -0.05857711285352707,
        -0.05748119205236435,
        0.05821078643202782,
        0.02260480634868145,
        -0.05637107789516449,
        -0.003085113363340497,
        -0.031127823516726494,
        0.0391625240445137,
        -0.0038576817605644464,
        -0.028072375804185867,
        0.05709035322070122,
        -0.05303695797920227,
        0.06066633015871048,
        0.025514770299196243,
        -0.05265923961997032,
        0.057938843965530396,
        -0.05353444442152977,
        0.053070057183504105,
        -0.02178848162293434,
        0.0499328151345253,
        -0.029443053528666496,
        -0.052989475429058075,
        -0.0260213203728199,
        0.042489249259233475,
        0.0553828664124012,
        -0.05153418704867363,
        0.04553936794400215,
        -0.0556253157556057,
        -0.0014112835051491857,
        0.031331177800893784,
        -0.059679899364709854,
        -0.05519689619541168,
        0.04105467349290848,
        -0.05244404077529907,
        0.0537450909614563,
        -0.059655431658029556,
        0.0300667192786932,
        -0.05229993164539337,
        0.05941680073738098,
        0.00040255740168504417,
        0.05978746712207794,
        0.008290919475257397,
        0.007834906689822674,
        -0.06018785387277603,
        -0.0580865852534771,
        0.017842764034867287,
        -0.025844145566225052,
        0.024034006521105766,
        0.05236905813217163,
        -0.046762820333242416,
        0.058080971240997314,
        0.040783487260341644,
        -0.04390551894903183,
        0.06097377836704254,
        -0.055654581636190414,
        0.04915935546159744,
        -0.021754058077931404,
        -0.029733533039689064,
        0.03896135464310646,
        -0.03262218087911606,
        0.06244291737675667,
        0.04735191538929939,
        0.001979599706828594,
        0.05404219403862953,
        0.045984163880348206,
        0.06115725636482239,
        -0.05928497761487961,
        0.027453508228063583,
        -0.05691062659025192,
        -0.05739450082182884,
        -0.05969605594873428,
        -0.015081871300935745,
        -0.020466964691877365,
        -0.014274953864514828,
        -0.02965034730732441,
        -0.06198450177907944,
        0.051835689693689346,
        0.03485172986984253,
        -0.043552059680223465,
        -0.038548316806554794,
        0.033603087067604065,
        -0.0444316565990448,
        0.062260471284389496,
        -0.0034085761290043592,
        -0.04393838718533516,
        -0.062482938170433044,
        0.025389254093170166,
        -0.06165032833814621,
        0.04846181347966194,
        0.02364196442067623,
        0.05222002789378166,
        0.05752364546060562,
        0.05256490409374237,
        -0.005909870378673077,
        -0.0431537888944149,
        -0.047925639897584915,
        -0.062008969485759735,
        0.05112380161881447,
        -0.03785226121544838,
        -0.03128229081630707,
        0.06125221401453018,
        0.03688789904117584,
        -0.02015809528529644,
        -0.06109295040369034,
        -0.05401777848601341,
        -0.04323141276836395,
        -0.060457173734903336,
        0.008449983783066273,
        0.03689861670136452,
        -0.0575970858335495,
        0.020918818190693855,
        0.05991249904036522,
        0.05422666296362877,
        -0.04483789950609207,
        -0.06038558855652809,
        -0.06061144173145294,
        -0.006292820908129215,
        0.056552398949861526,
        0.04523959383368492,
        0.05749168246984482,
        -0.018663855269551277,
        0.034852296113967896,
        0.056712519377470016,
        -0.00764296343550086,
        0.05858851596713066,
        0.024800613522529602,
        -0.03741369768977165,
        0.01584036275744438,
        0.05173017457127571,
        0.00114784378092736,
        0.05540771037340164,
        -0.04560733214020729,
        0.0481947623193264,
        -0.03256601095199585,
        0.062340639531612396,
        0.039973124861717224,
        0.015859877690672874,
        -0.06166043505072594,
        -0.026371948421001434,
        -0.06048934906721115,
        0.037367720156908035,
        -0.05309217795729637,
        -0.03484349697828293,
        0.05236131697893143,
        -0.0622967965900898,
        -0.02679203264415264,
        -0.004477526992559433,
        -0.02321547642350197,
        -0.02235541306436062,
        -0.004201664123684168,
        0.0468897745013237,
        -0.05044551566243172,
        -0.056320853531360626,
        0.0409623458981514,
        -0.04526948183774948,
        -0.0372886024415493,
        0.04448019340634346,
        0.04102213680744171,
        0.05727310851216316,
        0.06152878329157829,
        -0.034640345722436905,
        0.01157903391867876,
        0.057022299617528915,
        -0.0327780619263649,
        0.0595158115029335,
        0.0014821550576016307,
        -0.04294416680932045,
        0.05832234397530556,
        0.06238587573170662,
        0.000245332601480186,
        -0.060174666345119476,
        -0.03766334056854248,
        0.010092634707689285,
        -0.03196359798312187,
        -0.012477911077439785,
        0.05968407168984413,
        0.026918409392237663,
        0.02009367011487484,
        -0.054873984307050705,
        -0.0287482887506485,
        0.04725434258580208,
        0.062175408005714417,
        -0.0619676448404789,
        0.054375581443309784,
        -0.017995893955230713,
        -0.06179700791835785,
        0.05845395103096962,
        0.016200488433241844,
        0.057927802205085754,
        0.05555432289838791,
        0.004390310030430555,
        0.04477963224053383,
        -0.04057362303137779,
        0.04420371726155281,
        -0.0341634638607502,
        -0.05917387455701828,
        -0.057040125131607056,
        -0.00449561420828104,
        0.05224870890378952,
        0.04678353667259216,
        -0.006977578159421682,
        0.010332254692912102,
        0.05224493145942688,
        0.04351068288087845,
        -0.06121411547064781,
        -0.052071474492549896,
        0.03594762459397316,
        -0.06047571450471878,
        -0.0030334286857396364,
        0.03797267749905586,
        0.06198333203792572,
        0.017549388110637665,
        -0.057592570781707764,
        0.05210772901773453,
        0.05888637155294418,
        -0.044060882180929184,
        -0.04049317538738251,
        -0.06022363901138306,
        -0.03638880327343941,
        -0.006646307185292244,
        0.01172076165676117,
        0.02307557873427868,
        0.05636707320809364,
        0.02954207733273506,
        -0.05153611674904823,
        -0.03504839539527893,
        0.05614015832543373,
        0.05450008437037468
    ],
    [
        -0.004025020636618137,
        -0.05236971378326416,
        0.054714102298021317,
        0.03574400395154953,
        -0.020151177421212196,
        0.024260785430669785,
        0.053812626749277115,
        -0.05531057342886925,
        0.0008869728771969676,
        0.034943707287311554,
        0.05487760156393051,
        -0.01909414492547512,
        0.006916027516126633,
        -0.053236909210681915,
        -0.053549379110336304,
        0.046839386224746704,
        0.018770869821310043,
        0.05535520985722542,
        -0.04031827300786972,
        0.04011552035808563,
        0.05462782457470894,
        -0.05439044535160065,
        0.05431569740176201,
        0.04650961980223656,
        -0.024795137345790863,
        0.04859624058008194,
        -0.03577776625752449,
        -0.04198877140879631,
        -0.017008664086461067,
        -0.04072725027799606,
        -0.040420208126306534,
        -0.051568202674388885,
        -0.04126893728971481,
        -0.055360097438097,
        0.053774505853652954,
        -0.03342399746179581,
        -0.05500476807355881,
        -0.0380183644592762,
        -0.050656434148550034,
        -0.05526159331202507,
        0.05003981664776802,
        0.03961414843797684,
        0.05267028510570526,
        0.052206579595804214,
        0.01673264615237713,
        0.023119771853089333,
        -0.04172390326857567,
        0.05474572628736496,
        0.05526108294725418,
        0.03371866047382355,
        0.054885029792785645,
        0.05511099100112915,
        -0.014780323952436447,
        -0.04671003296971321,
        -0.0012593830469995737,
        -0.05533934012055397,
        -0.05105830356478691,
        0.051893677562475204,
        -0.054820697754621506,
        0.04112963005900383,
        -0.042281050235033035,
        0.035020314157009125,
        -0.050584807991981506,
        0.04869762435555458,
        0.005281665828078985,
        -0.054427534341812134,
        -0.025792522355914116,
        -0.0040955450385808945,
        -0.04965302348136902,
        -0.05446780100464821,
        0.0011378377676010132,
        -0.04600778967142105,
        0.05536651238799095,
        0.05512139946222305,
        0.05454355478286743,
        -0.0024255458265542984,
        0.010724367573857307,
        0.05536573752760887,
        0.036179352551698685,
        -0.053668830543756485,
        -0.03953281417489052,
        -0.05535481125116348,
        -0.055117297917604446,
        -0.0003304451238363981,
        -0.055213578045368195,
        0.054736461490392685,
        -0.028018023818731308,
        -0.052421245723962784,
        0.048496756702661514,
        0.011880575679242611,
        -0.05400796979665756,
        -0.009775892831385136,
        0.055241670459508896,
        -0.0489228181540966,
        -0.04667139798402786,
        0.004774949047714472,
        -0.04926355928182602,
        -0.03758610412478447,
        -0.006405397318303585,
        -0.05321621149778366,
        -0.05429944768548012,
        0.05176558345556259,
        0.05284282937645912,
        0.05530520901083946,
        -0.018811780959367752,
        0.0326802022755146,
        -0.02767268754541874,
        -0.05041128769516945,
        -0.053285662084817886,
        0.03492647781968117,
        -0.04399264231324196,
        0.055304113775491714,
        -0.05165566876530647,
        -0.04132596030831337,
        0.00864395871758461,
        0.04587852954864502,
        -0.054990749806165695,
        -0.05192440748214722,
        0.05364932119846344,
        -0.022492658346891403,
        -0.04625067487359047,
        0.049620386213064194,
        0.054662130773067474,
        -0.05525822937488556,
        -0.007996181026101112,
        -0.0304424948990345,
        0.054052893072366714,
        0.053759198635816574,
        0.055068742483854294,
        -0.042744219303131104,
        0.03077067621052265,
        -0.05472937226295471,
        0.05520104244351387,
        0.03947357088327408,
        0.008499174378812313,
        -0.046162199229002,
        0.04830168932676315,
        0.05532003939151764,
        -0.049830980598926544,
        0.0550420843064785,
        0.049352724105119705,
        0.04878387227654457,
        0.0532095804810524,
        0.05536184832453728,
        -0.05513947457075119,
        0.05524955317378044,
        0.03824877738952637,
        0.052432917058467865,
        0.009344740770757198,
        0.03302662447094917,
        0.013678109273314476,
        -0.054724372923374176,
        -0.04867643117904663,
        -0.05533067137002945,
        0.05536968261003494,
        -0.055146608501672745,
        -0.055369697511196136,
        -0.05341706424951553,
        0.0385446660220623,
        0.005254196003079414,
        0.05503299459815025,
        -0.028359202668070793,
        0.05204802751541138,
        -0.00679649505764246,
        -0.05237738415598869,
        -0.03212374076247215,
        -0.04652126133441925,
        -0.04457410052418709,
        -0.03432469069957733,
        -0.008242136798799038,
        -0.01645919866859913,
        0.05356853827834129,
        -0.054756756871938705,
        0.04170198366045952,
        -0.04617984592914581,
        0.024873577058315277,
        -0.05047351494431496,
        0.029236389324069023,
        0.05508677661418915,
        -0.04231807589530945,
        -0.051847193390131,
        0.054283544421195984,
        0.05371323227882385,
        -0.053532253950834274,
        -0.034194767475128174,
        0.053191520273685455,
        0.050894495099782944,
        0.05470283329486847,
        -0.04998840391635895,
        -0.05530674755573273,
        -0.04418770596385002,
        -0.04446631297469139,
        0.05009300634264946,
        -0.03126351907849312,
        -0.024285197257995605,
        -0.044753070920705795,
        0.05536195635795593,
        0.05486514791846275,
        0.052578553557395935,
        -0.040112484246492386,
        0.05497327446937561,
        -0.035366546362638474,
        -0.04638685658574104,
        -0.02905391715466976,
        -0.010741762816905975,
        -0.03544037789106369,
        0.048770420253276825,
        0.0522720143198967,
        0.010979637503623962,
        0.04817139357328415,
        -0.030869372189044952,
        0.01667392998933792,
        -0.03629308566451073,
        0.05500991269946098,
        -0.03535778447985649,
        0.04489223286509514,
        0.05308667570352554,
        -0.015821631997823715,
        0.055338818579912186,
        0.05534546449780464,
        0.05518084764480591,
        -0.045464254915714264,
        -0.01907290704548359,
        -0.05259528383612633,
        0.049633584916591644,
        -0.05279342085123062,
        0.035669632256031036,
        -0.04918399453163147,
        0.008160732686519623,
        -0.054808203130960464,
        0.05472072958946228,
        -0.0013602575054392219,
        -0.020292989909648895,
        0.05286848917603493,
        0.013130741193890572,
        0.05520184710621834,
        0.044163066893815994,
        0.03173545375466347,
        -0.020156841725111008,
        0.05535431578755379,
        0.05505266785621643,
        0.05525710806250572,
        0.017742689698934555,
        -0.05033239349722862,
        0.03707044944167137,
        0.022392142564058304,
        0.026000967249274254,
        0.04463789612054825,
        0.042790334671735764,
        -0.05086216703057289,
        -0.011570598930120468,
        -0.05401882901787758,
        -0.05487341806292534,
        -0.05230112746357918,
        0.03578052297234535,
        0.03304144740104675,
        -0.0453285351395607,
        -0.0450926311314106,
        0.04167744517326355,
        0.05283356085419655,
        0.005946524906903505,
        0.021165303885936737,
        0.04865618422627449,
        -0.034047551453113556,
        0.03759263828396797,
        0.054623596370220184,
        0.04606248065829277,
        -0.0543946772813797,
        0.039535101503133774,
        0.045369066298007965,
        0.04880375787615776,
        0.03849827125668526,
        0.05522827059030533,
        -0.04149917513132095,
        -0.05532998591661453,
        -0.015758506953716278,
        -0.037471331655979156,
        0.053963322192430496,
        0.054474979639053345,
        0.03142120689153671,
        -0.053773682564496994,
        -0.050122715532779694,
        -0.008938194252550602,
        0.05488825961947441,
        0.015720341354608536,
        0.04999183490872383,
        0.051511965692043304,
        -0.0025576846674084663,
        -0.030931644141674042,
        -0.020928502082824707,
        0.045041799545288086,
        0.04119354858994484,
        -0.04639648646116257,
        -0.005196993704885244,
        0.055199116468429565,
        -0.05512644350528717,
        0.05491986498236656,
        0.040468811988830566,
        0.04666372761130333,
        0.050193823873996735,
        -0.051537107676267624,
        0.013028008863329887,
        0.025361498817801476,
        0.03105248138308525,
        -0.05370712652802467,
        -0.01954377070069313,
        0.03214550390839577,
        0.04445552080869675,
        -0.011749295517802238,
        0.002702007070183754,
        0.012064201757311821,
        -0.05031773820519447,
        -0.008674517273902893,
        0.04551560804247856,
        -0.0548645555973053,
        -0.03776218742132187,
        -0.010445290245115757,
        -0.007789858616888523,
        0.051439665257930756,
        -0.017325039952993393,
        0.05521683767437935,
        0.027459774166345596,
        -0.0359957255423069,
        0.051114995032548904,
        0.051205605268478394,
        0.044090636074543,
        -0.009841418825089931,
        -0.04852621257305145,
        -0.04752228409051895,
        -0.05237305909395218,
        -0.05172407999634743,
        -0.029890626668930054,
        -0.01055130921304226,
        -0.05533759295940399,
        0.052724968641996384,
        -0.04143151640892029,
        0.04257085919380188,
        0.035906560719013214,
        -0.048427097499370575,
        0.050800781697034836,
        -0.04638903960585594,
        0.05444318428635597,
        -0.05518101155757904,
        -0.03289840742945671,
        0.052353385835886,
        0.04430486261844635,
        0.05500280484557152,
        0.054733630269765854,
        0.04903537780046463,
        0.04559047892689705,
        -0.05478556081652641,
        -0.054644759744405746,
        -0.00977808516472578,
        -0.00864922720938921,
        0.055357035249471664,
        -0.04556922987103462,
        0.046556126326322556,
        -0.05463770404458046,
        0.03477177768945694,
        -0.05405661463737488,
        0.051303476095199585,
        -0.050500065088272095,
        0.04065386578440666,
        -0.023433497175574303,
        -0.052966922521591187,
        -0.049533452838659286,
        0.054480426013469696,
        -0.05131114274263382,
        -0.03525177016854286,
        -0.05322704091668129,
        -0.05253886803984642,
        -0.055340468883514404,
        -0.03191143646836281,
        -0.029618818312883377,
        0.051886383444070816,
        0.04648526757955551,
        -0.05502324551343918,
        0.05531937628984451,
        0.05212148651480675,
        -0.02291782572865486,
        -0.055083226412534714,
        -0.0019968431442976,
        -0.05096869543194771,
        0.05061095207929611,
        0.04278432950377464,
        -0.05525214225053787,
        -0.024732070043683052,
        -0.04902080073952675,
        -0.0446651317179203,
        -0.05148104950785637,
        -0.04352631792426109,
        -0.05334430932998657,
        -0.040823232382535934,
        -0.051274143159389496,
        -0.03544384241104126,
        0.048838987946510315,
        0.055149808526039124,
        0.029605088755488396,
        0.05153798684477806,
        -0.055321723222732544,
        -0.04850492253899574,
        0.05535069480538368,
        -0.05359476059675217,
        0.054709646850824356,
        -0.053370747715234756,
        0.02731744945049286,
        0.05002347752451897,
        -0.04434211552143097,
        0.053233835846185684,
        0.034497927874326706,
        -0.05305134505033493,
        -0.04941393435001373,
        0.053726475685834885,
        0.04264151677489281,
        0.05378548055887222,
        -0.05500565841794014,
        -0.05205383151769638,
        0.04713217541575432,
        -0.008427554741501808,
        -0.05319886654615402,
        -0.04146970063447952,
        0.05445655435323715,
        0.055188294500112534,
        -0.05033682659268379,
        -0.05110310763120651,
        0.020729437470436096,
        0.013154909014701843,
        -0.011566996574401855,
        -0.04564876854419708,
        -0.05046656355261803,
        0.05245574936270714,
        0.04998880997300148,
        -0.03192555904388428,
        -0.054384153336286545,
        -0.05393476411700249,
        -0.054957065731287,
        -0.05506894364953041,
        0.02798219583928585,
        -0.05346387252211571,
        -0.037455786019563675,
        -0.053567301481962204,
        -0.00758023327216506,
        0.05123843252658844,
        0.05325143784284592,
        0.01691395789384842,
        -0.028822332620620728,
        0.05043046548962593,
        -0.04656098037958145,
        0.037081729620695114,
        0.05467892065644264,
        0.053320128470659256,
        -0.052108049392700195,
        0.05514822155237198,
        -0.004761404823511839,
        -0.05530552193522453,
        -0.04824766144156456,
        0.04873673990368843,
        -0.0541156567633152,
        0.010371068492531776,
        0.05532465875148773,
        -0.020086944103240967,
        -0.04960684850811958,
        -0.031542085111141205,
        0.03294898569583893,
        0.053829070180654526,
        0.05534849688410759,
        -0.0529073067009449,
        0.05516943708062172,
        0.05438142642378807,
        -0.0543912872672081,
        0.05411070957779884,
        0.04010619595646858,
        0.05416454002261162,
        0.005930813029408455,
        -0.010564880445599556,
        -0.0033148261718451977,
        0.05522226169705391,
        -0.03745473548769951,
        0.04520224407315254,
        0.01791185885667801,
        -0.044935449957847595,
        0.013176774606108665,
        -0.05513951554894447,
        -0.001432401011697948,
        -0.03286070004105568,
        0.05458001419901848,
        0.054636042565107346,
        0.03710285946726799,
        -0.04850327968597412,
        0.04864827170968056,
        -0.05527034029364586,
        0.011375303380191326,
        0.012543700635433197,
        -0.04235099256038666,
        -0.0007860840996727347,
        0.04253225401043892,
        -0.051070865243673325,
        0.05482542887330055,
        0.026224084198474884,
        0.03449352830648422,
        0.05535900965332985,
        0.03209049254655838,
        -0.054084695875644684,
        0.05508526787161827,
        -0.038379471749067307,
        0.04980679601430893,
        0.04679793491959572,
        -0.023350264877080917,
        0.029180817306041718,
        -0.055331870913505554,
        -0.04195588082075119,
        -0.04229790344834328
    ],
    [
        -0.056785814464092255,
        -0.02897575870156288,
        -0.026409251615405083,
        0.010351058095693588,
        0.05102962628006935,
        -0.05097740516066551,
        0.05995291471481323,
        -0.01392393559217453,
        -0.024632729589939117,
        -0.05611620470881462,
        0.00798793788999319,
        -0.022875946015119553,
        -0.05571043863892555,
        -0.04839455708861351,
        -0.05945444107055664,
        0.008703573606908321,
        0.0006596344755962491,
        0.05991872400045395,
        -0.0027942045126110315,
        0.05616945028305054,
        -0.05932045355439186,
        -0.036560818552970886,
        0.05945315584540367,
        0.015849793329834938,
        0.009083136916160583,
        -0.026455115526914597,
        0.016635701060295105,
        -0.022797413170337677,
        0.04786482825875282,
        -0.03708217665553093,
        -0.043630607426166534,
        -0.04355097562074661,
        0.032761432230472565,
        -0.05910801142454147,
        0.036428164690732956,
        -0.047596994787454605,
        -0.051677558571100235,
        -0.05659611523151398,
        -0.060160327702760696,
        0.027320528402924538,
        -0.01409826334565878,
        -0.04202884063124657,
        0.056550003588199615,
        -0.05252751708030701,
        0.05940373241901398,
        -0.0021999937016516924,
        0.0003057747380807996,
        0.05959685891866684,
        0.0548691488802433,
        -0.04352350905537605,
        0.03929080814123154,
        0.04411337897181511,
        0.04825456812977791,
        -0.028382200747728348,
        0.015475563704967499,
        -0.03447138890624046,
        -0.05530364438891411,
        0.021030116826295853,
        -0.05597950145602226,
        0.023443635553121567,
        -0.0593864805996418,
        -0.01013243943452835,
        -0.05215045437216759,
        -0.03151441738009453,
        0.03600339964032173,
        -0.00021846056915819645,
        0.05514882132411003,
        -0.05784298852086067,
        0.021331554278731346,
        -0.06015092507004738,
        -0.054940029978752136,
        -0.05999109148979187,
        0.056399498134851456,
        0.05636319890618324,
        -0.006103257182985544,
        -0.017542392015457153,
        -0.017012182623147964,
        0.05653586611151695,
        -0.029993612319231033,
        -0.04180312156677246,
        -0.050099339336156845,
        -0.04794830456376076,
        -0.059851814061403275,
        0.03677808493375778,
        -0.04173433035612106,
        0.04565709829330444,
        -0.05567454919219017,
        -0.03159873187541962,
        0.05873454362154007,
        0.034705374389886856,
        -0.009442687965929508,
        0.03755732998251915,
        0.02831980399787426,
        -0.049904096871614456,
        0.012467650696635246,
        -0.048079293221235275,
        -0.058647360652685165,
        -0.04969536140561104,
        -0.05037568882107735,
        -0.040732964873313904,
        -0.05992027372121811,
        -0.05772142857313156,
        0.053404610604047775,
        0.05085480958223343,
        -0.0537184402346611,
        -0.031850416213274,
        0.05983206629753113,
        -0.0019328838679939508,
        -0.05704513192176819,
        -0.05548876151442528,
        -0.05197518691420555,
        0.012879587709903717,
        -0.052570369094610214,
        0.012467811815440655,
        0.05973521247506142,
        -0.01590513437986374,
        -0.0011550962226465344,
        -0.054541587829589844,
        0.05847257375717163,
        0.012758245691657066,
        -0.0018365762662142515,
        0.05725311487913132,
        0.01570769213140011,
        -0.04035615921020508,
        -0.05925053730607033,
        -0.013189821504056454,
        0.03437269479036331,
        0.059022948145866394,
        0.059164050966501236,
        0.0275068711489439,
        0.05638936161994934,
        -0.05045761540532112,
        0.04955365136265755,
        -0.008852767758071423,
        -0.049918096512556076,
        -0.053427133709192276,
        0.052946802228689194,
        -0.05079897865653038,
        -0.036938462406396866,
        0.05908973142504692,
        0.039910729974508286,
        -0.012757476419210434,
        0.04604712128639221,
        0.03930378705263138,
        -0.038035422563552856,
        0.047954924404621124,
        0.03000004030764103,
        0.025366220623254776,
        0.030925024300813675,
        0.00473380833864212,
        -0.026184825226664543,
        -0.05931674316525459,
        -0.039724696427583694,
        -0.02816603146493435,
        0.06015561521053314,
        -0.05782105401158333,
        -0.004450294654816389,
        -0.016953973099589348,
        -0.05142386257648468,
        0.03850901499390602,
        0.03628014028072357,
        0.042601507157087326,
        0.05128397420048714,
        -0.05868920311331749,
        -0.052977126091718674,
        -0.039465103298425674,
        0.03912465646862984,
        -0.05809121951460838,
        -0.05381777137517929,
        0.042393896728754044,
        -0.007010490633547306,
        0.008872945792973042,
        -0.04843801259994507,
        0.04054708406329155,
        -0.059156328439712524,
        0.057276610285043716,
        -0.0584903322160244,
        0.04898357391357422,
        0.053985822945833206,
        -0.008350677788257599,
        0.04294416308403015,
        0.029734384268522263,
        0.05791984125971794,
        -0.01072610728442669,
        -0.03682129830121994,
        -0.01207183301448822,
        0.05078872665762901,
        0.057915087789297104,
        -0.05126228183507919,
        -0.05725953355431557,
        -0.022156670689582825,
        -0.04510951787233353,
        -0.045460667461156845,
        -0.016995564103126526,
        -0.004244918469339609,
        -0.015189738012850285,
        0.059096455574035645,
        0.05188509076833725,
        0.017140865325927734,
        0.005437514744699001,
        0.0017422852106392384,
        0.04650494456291199,
        0.03635347634553909,
        -0.04708193987607956,
        -0.0418848879635334,
        0.007313517853617668,
        0.03891376778483391,
        0.057310838252305984,
        -0.029234830290079117,
        0.05001680552959442,
        -0.04719744995236397,
        0.04059160128235817,
        -0.04763726890087128,
        0.04381521791219711,
        0.05513562262058258,
        -0.013781692832708359,
        0.012062854133546352,
        0.04801126569509506,
        0.060079723596572876,
        0.058452505618333817,
        0.03252333402633667,
        -0.05927621200680733,
        -0.0032592578791081905,
        -0.06011589616537094,
        0.059470538049936295,
        -0.05921944975852966,
        -0.0521814227104187,
        0.03830241411924362,
        -0.032146673649549484,
        -0.04823308438062668,
        0.03933276981115341,
        -0.045135509222745895,
        -0.03238637000322342,
        0.04321762174367905,
        -0.0413031168282032,
        0.0471927747130394,
        0.0546027272939682,
        0.024575700983405113,
        -0.01970093697309494,
        0.059518732130527496,
        0.04381094500422478,
        0.056643981486558914,
        -0.011851057410240173,
        0.05080631747841835,
        -0.021234391257166862,
        0.021389730274677277,
        -0.042890869081020355,
        -0.05692403018474579,
        -0.051693692803382874,
        0.04266989976167679,
        -0.05441031977534294,
        -0.0557086318731308,
        -0.05962712690234184,
        -0.056481070816516876,
        0.057939693331718445,
        0.054366689175367355,
        0.02725210040807724,
        -0.05422107130289078,
        0.05782000720500946,
        0.06000605225563049,
        0.010091317817568779,
        0.05984443426132202,
        0.03334832563996315,
        0.026259515434503555,
        -0.05917607620358467,
        0.04988972470164299,
        -0.04173913225531578,
        -0.04960319399833679,
        -0.03544763848185539,
        -0.03862079605460167,
        -0.01038361992686987,
        -0.01893526315689087,
        0.05291964113712311,
        -0.04314962029457092,
        -0.0590592622756958,
        0.035467829555273056,
        -0.05085936561226845,
        0.059066224843263626,
        -0.031799931079149246,
        -0.016450989991426468,
        -0.06001861393451691,
        -0.01796191930770874,
        0.05515022203326225,
        -0.027054689824581146,
        -0.05709794908761978,
        -0.05189627781510353,
        0.041271377354860306,
        -0.008913705125451088,
        -0.058517299592494965,
        -0.008879260160028934,
        -0.041573550552129745,
        0.055064376443624496,
        -0.05426974594593048,
        0.006136882584542036,
        0.05755652114748955,
        -0.054540496319532394,
        0.054877057671546936,
        0.053787197917699814,
        -0.051950205117464066,
        0.05514957383275032,
        -0.0552968829870224,
        0.046958938241004944,
        -0.008328969590365887,
        -0.04011312872171402,
        -0.057671163231134415,
        -0.04437694698572159,
        0.0294856745749712,
        0.015824096277356148,
        0.0583939328789711,
        -0.018458085134625435,
        0.027981407940387726,
        -0.008045416325330734,
        -0.04800202697515488,
        0.05727386102080345,
        -0.05915224179625511,
        -0.04870908334851265,
        0.0445614829659462,
        -0.052359439432621,
        0.04260697215795517,
        -0.05170869454741478,
        0.05512593314051628,
        0.05704624950885773,
        0.05759439244866371,
        0.05072101205587387,
        0.007364967372268438,
        0.04142209514975548,
        0.053691111505031586,
        -0.05592125281691551,
        -0.00003097770968452096,
        -0.009514039382338524,
        -0.055453892797231674,
        0.0013452874263748527,
        0.03685062751173973,
        -0.05989419296383858,
        0.05882731452584267,
        -0.054242510348558426,
        -0.04406249150633812,
        0.0066530159674584866,
        -0.056654419749975204,
        0.05786784738302231,
        -0.040974654257297516,
        0.051635824143886566,
        -0.055716026574373245,
        0.00741532351821661,
        0.06012730672955513,
        0.03866785019636154,
        0.057516708970069885,
        0.02578561007976532,
        0.0483810231089592,
        0.04567943140864372,
        -0.06010313704609871,
        -0.055633511394262314,
        -0.05744277313351631,
        -0.03740283474326134,
        0.03856942430138588,
        0.05560040846467018,
        0.0005029867170378566,
        -0.04713151231408119,
        -0.006962833926081657,
        -0.057003267109394073,
        0.057510487735271454,
        0.046510882675647736,
        -0.05519934743642807,
        -0.03811875730752945,
        0.003703281283378601,
        -0.053139690309762955,
        0.05965983122587204,
        -0.020617565140128136,
        0.046277277171611786,
        -0.059982024133205414,
        -0.05492573603987694,
        -0.0598360039293766,
        0.01917172037065029,
        -0.04308759421110153,
        0.05203067511320114,
        0.056071288883686066,
        0.049786072224378586,
        0.05123268440365791,
        -0.038083240389823914,
        -0.05931604653596878,
        -0.053954917937517166,
        0.0076779555529356,
        -0.05622466281056404,
        -0.025856539607048035,
        0.018323823809623718,
        -0.05857215076684952,
        0.022982515394687653,
        -0.03764975816011429,
        -0.05982641875743866,
        -0.036110326647758484,
        -0.05048784986138344,
        -0.004229192156344652,
        -0.004889176692813635,
        -0.00024585577193647623,
        -0.058009080588817596,
        0.058177027851343155,
        0.05086251720786095,
        0.05381796881556511,
        0.034963879734277725,
        -0.058142226189374924,
        -0.050761014223098755,
        0.05600852519273758,
        0.04946495592594147,
        0.06008708477020264,
        -0.05906623601913452,
        0.051749102771282196,
        0.04431680589914322,
        0.05602641403675079,
        0.05867672711610794,
        0.02162843570113182,
        0.01775573566555977,
        -0.05048653483390808,
        0.026456762105226517,
        0.012214837595820427,
        0.05577718839049339,
        -0.04938625916838646,
        -0.017014632001519203,
        -0.052335936576128006,
        -0.011442567221820354,
        -0.057889584451913834,
        -0.05963588505983353,
        -0.05652901157736778,
        -0.002269165124744177,
        -0.028271880000829697,
        0.048877276480197906,
        0.026180265471339226,
        -0.03965045139193535,
        -0.024579182267189026,
        -0.05566897243261337,
        -0.04935969412326813,
        0.05714945122599602,
        0.04205824434757233,
        -0.003212957875803113,
        -0.05392644926905632,
        -0.0011843977263197303,
        -0.05291608348488808,
        -0.05999506264925003,
        0.047810278832912445,
        -0.05864633247256279,
        -0.013017222285270691,
        -0.003828170709311962,
        0.04261286184191704,
        -0.01174922101199627,
        0.0497465655207634,
        0.058713220059871674,
        0.050192058086395264,
        0.05489170551300049,
        0.024958113208413124,
        0.04937495291233063,
        -0.03820180520415306,
        0.059345949441194534,
        -0.01701059751212597,
        0.06008005142211914,
        -0.030113568529486656,
        -0.05914827063679695,
        -0.05378849431872368,
        0.057578522711992264,
        -0.045299969613552094,
        -0.05711394175887108,
        0.05753672868013382,
        -0.040938302874565125,
        -0.03271414712071419,
        -0.052312977612018585,
        -0.010102677159011364,
        0.05124533176422119,
        0.05951329693198204,
        -0.05812207609415054,
        0.05969081073999405,
        0.01738804206252098,
        -0.059655774384737015,
        0.059304360300302505,
        0.05042411386966705,
        0.04791528731584549,
        -0.050750456750392914,
        -0.038167309015989304,
        -0.011242789216339588,
        0.0587109811604023,
        0.041728176176548004,
        0.0016510554123669863,
        -0.051358696073293686,
        -0.023757927119731903,
        -0.03450310602784157,
        -0.03979964181780815,
        0.027834046632051468,
        -0.012999397702515125,
        0.05192123353481293,
        0.04864247515797615,
        0.0021202987991273403,
        -0.05677415058016777,
        0.045052558183670044,
        -0.04846329241991043,
        -0.020541975274682045,
        0.049184903502464294,
        -0.03492601960897446,
        0.050132088363170624,
        -0.027341097593307495,
        -0.038366593420505524,
        0.0434320867061615,
        0.05578560382127762,
        0.0038580382242798805,
        0.06012358143925667,
        -0.02228548936545849,
        -0.023014644160866737,
        0.027781033888459206,
        -0.04964525252580643,
        -0.027280833572149277,
        -0.027563054114580154,
        -0.014125823974609375,
        -0.05700044333934784,
        0.022754037752747536,
        -0.03283780813217163,
        0.0324423611164093
    ],
    [
        -0.05025113373994827,
        0.03875042125582695,
        0.005927900783717632,
        0.015062876977026463,
        -0.05104479938745499,
        0.03747071325778961,
        0.02971382811665535,
        -0.05181495472788811,
        0.050963182002305984,
        -0.02061830647289753,
        0.051302023231983185,
        0.033209625631570816,
        -0.051849886775016785,
        -0.015234090387821198,
        -0.05113242194056511,
        0.051858000457286835,
        0.05148759111762047,
        0.05186280608177185,
        -0.0518658272922039,
        0.04544820263981819,
        -0.03498682379722595,
        0.051694899797439575,
        0.05186605826020241,
        0.04569437354803085,
        0.051853738725185394,
        0.05000805854797363,
        -0.01601724699139595,
        0.051860589534044266,
        -0.05176280811429024,
        -0.0500338040292263,
        0.05167547985911369,
        0.05181439220905304,
        -0.05186627805233002,
        -0.051860593259334564,
        0.05104173719882965,
        -0.049868471920490265,
        -0.05079744756221771,
        -0.04643974080681801,
        -0.04957890138030052,
        0.016406096518039703,
        0.04983831197023392,
        0.040970154106616974,
        0.050722382962703705,
        0.04276248812675476,
        0.0491088405251503,
        0.050578225404024124,
        0.018270913511514664,
        0.051586877554655075,
        0.05185612663626671,
        0.04720860719680786,
        0.04947255924344063,
        0.051750171929597855,
        -0.04263318330049515,
        -0.05171108990907669,
        0.05186592414975166,
        -0.05183727666735649,
        -0.051704809069633484,
        0.03968111053109169,
        -0.04848504438996315,
        0.05167524144053459,
        -0.05174926668405533,
        -0.0023908019065856934,
        -0.05158313736319542,
        -0.02861543372273445,
        -0.051048435270786285,
        -0.05186622962355614,
        -0.021516181528568268,
        -0.05142764374613762,
        0.02568516694009304,
        0.03360302746295929,
        0.0468820221722126,
        -0.05171671137213707,
        0.051439762115478516,
        0.05181393399834633,
        0.05186612159013748,
        -0.035856910049915314,
        0.051866315305233,
        0.051866330206394196,
        0.050345528870821,
        0.05011855438351631,
        -0.04779677465558052,
        -0.00741933798417449,
        -0.05131843686103821,
        0.009565928019583225,
        0.02570446766912937,
        0.03830593079328537,
        0.03614714741706848,
        0.05183263123035431,
        -0.027365179732441902,
        0.05146973207592964,
        -0.048232875764369965,
        -0.04593538120388985,
        0.020026113837957382,
        0.02456800825893879,
        -0.00766877131536603,
        -0.026936620473861694,
        -0.03112483210861683,
        -0.04906507208943367,
        -0.05113469436764717,
        0.0498514249920845,
        -0.0518357940018177,
        0.050409045070409775,
        0.05186472088098526,
        0.050002846866846085,
        0.051866061985492706,
        -0.005900602787733078,
        -0.051839254796504974,
        0.04917227476835251,
        -0.05181382596492767,
        0.03806008771061897,
        -0.05185452103614807,
        0.0518585741519928,
        -0.05050848424434662,
        0.03902003541588783,
        0.05182475969195366,
        -0.0373566709458828,
        -0.044604700058698654,
        -0.051818881183862686,
        0.05186626687645912,
        -0.051192283630371094,
        -0.04165756329894066,
        -0.04517149552702904,
        0.04708142951130867,
        -0.05153520032763481,
        -0.05070006847381592,
        0.051783472299575806,
        -0.044043608009815216,
        -0.0021434444934129715,
        0.05156715214252472,
        -0.04648881405591965,
        0.04147958755493164,
        -0.05154099687933922,
        0.049320876598358154,
        0.04434402659535408,
        -0.05023942515254021,
        -0.051753442734479904,
        0.05185499042272568,
        -0.031130624935030937,
        -0.039959318935871124,
        0.04869374632835388,
        -0.025286953896284103,
        0.0024820060934871435,
        -0.00023310289543587714,
        0.05186178907752037,
        0.007124926894903183,
        0.05178602784872055,
        0.051866158843040466,
        0.020101722329854965,
        0.034194037318229675,
        0.05040784552693367,
        -0.05099816620349884,
        -0.05186322331428528,
        0.012033234350383282,
        -0.004431969951838255,
        -0.05186479911208153,
        -0.05185909569263458,
        -0.04215763881802559,
        0.049636442214250565,
        0.05154428631067276,
        -0.051660411059856415,
        0.05065875127911568,
        -0.051863882690668106,
        0.05118595063686371,
        0.05185335502028465,
        -0.003613793756812811,
        -0.046846743673086166,
        -0.044463321566581726,
        0.0515596978366375,
        0.0450543574988842,
        0.03517305105924606,
        -0.04987878352403641,
        0.051838770508766174,
        -0.05163820460438728,
        -0.051843855530023575,
        -0.007513551507145166,
        -0.031065184623003006,
        0.01820020005106926,
        -0.05183473229408264,
        0.044656407088041306,
        -0.05178723856806755,
        -0.04273570701479912,
        0.05095769092440605,
        0.04805515334010124,
        -0.05082762986421585,
        -0.03981683403253555,
        -0.025453893467783928,
        0.051426444202661514,
        0.051594022661447525,
        -0.051376231014728546,
        -0.05186499282717705,
        -0.051799364387989044,
        0.00844911765307188,
        -0.038066331297159195,
        0.05150922015309334,
        -0.005439014174044132,
        0.024395860731601715,
        0.05186513066291809,
        -0.051865920424461365,
        -0.04861132428050041,
        0.0517471618950367,
        0.05186444893479347,
        0.01901859976351261,
        -0.030537433922290802,
        -0.046612441539764404,
        -0.04899462312459946,
        0.037788182497024536,
        0.04752350226044655,
        0.0518195815384388,
        -0.05038398876786232,
        -0.05186477303504944,
        -0.05085812136530876,
        0.03967537358403206,
        0.015550108626484871,
        0.05106688663363457,
        -0.05093915015459061,
        -0.05174003913998604,
        0.050678402185440063,
        -0.0037786560133099556,
        0.05186474695801735,
        -0.051292236894369125,
        0.04970713332295418,
        0.0488150455057621,
        0.05003085732460022,
        -0.05154325067996979,
        0.05177896469831467,
        -0.0518280528485775,
        0.051844917237758636,
        0.051866207271814346,
        -0.051835112273693085,
        -0.05169541761279106,
        -0.04801980033516884,
        -0.0513298436999321,
        -0.05184318870306015,
        0.04087134450674057,
        -0.04428922384977341,
        0.05175705626606941,
        0.05158442631363869,
        0.04590054973959923,
        -0.03507253900170326,
        0.051865920424461365,
        0.028653666377067566,
        -0.04684383422136307,
        -0.051866281777620316,
        -0.05062168091535568,
        -0.024212311953306198,
        0.05054762214422226,
        -0.051842186599969864,
        0.05049268156290054,
        0.05017377436161041,
        -0.04999496042728424,
        0.023089582100510597,
        -0.04686673730611801,
        -0.004384025931358337,
        -0.050160184502601624,
        0.039453715085983276,
        -0.024113062769174576,
        -0.05186550319194794,
        -0.003125897841528058,
        0.04421566054224968,
        0.029706822708249092,
        0.010030355304479599,
        -0.05185320973396301,
        -0.05184435099363327,
        0.05175931751728058,
        -0.05174475908279419,
        0.051853012293577194,
        -0.05182674527168274,
        -0.04766779765486717,
        0.04941105097532272,
        -0.049621354788541794,
        0.038297832012176514,
        -0.05119744688272476,
        0.051480866968631744,
        -0.05178193002939224,
        -0.05172720551490784,
        0.0012962444452568889,
        -0.04992606118321419,
        0.05124811455607414,
        0.04963627830147743,
        0.05161825940012932,
        -0.05186539143323898,
        0.04921750724315643,
        -0.05183099955320358,
        0.05086724832653999,
        0.03043518029153347,
        -0.027131741866469383,
        -0.0389159694314003,
        0.051833268254995346,
        0.03127364441752434,
        0.05090051516890526,
        -0.03577050939202309,
        0.017223473638296127,
        -0.051185399293899536,
        0.03579452261328697,
        0.03948797658085823,
        -0.051623377948999405,
        0.051865898072719574,
        -0.007024026475846767,
        -0.0518663227558136,
        0.051802508533000946,
        0.048883553594350815,
        0.021180877462029457,
        0.0247509628534317,
        0.05120038613677025,
        0.05185941979289055,
        0.024607835337519646,
        -0.027903566136956215,
        0.04866144061088562,
        0.0511593371629715,
        -0.051864612847566605,
        0.026545153930783272,
        -0.04807981476187706,
        0.05104780197143555,
        0.04852728918194771,
        -0.03348282352089882,
        -0.05185595527291298,
        -0.023976465687155724,
        -0.04006819427013397,
        0.04890121519565582,
        0.024320561438798904,
        0.030861321836709976,
        0.051785532385110855,
        -0.035921528935432434,
        -0.023930132389068604,
        0.051865216344594955,
        0.03086473047733307,
        0.051818326115608215,
        0.003828528104349971,
        -0.044225890189409256,
        -0.04844159260392189,
        0.02011115476489067,
        -0.0026651599910110235,
        -0.033120423555374146,
        -0.05184246227145195,
        0.0502418577671051,
        0.006394955795258284,
        0.042378019541502,
        0.0491069070994854,
        -0.03579007834196091,
        0.05020495504140854,
        -0.030792340636253357,
        -0.003936500288546085,
        -0.031391698867082596,
        -0.05186283215880394,
        0.04732206091284752,
        -0.009056072682142258,
        0.050882574170827866,
        0.051758237183094025,
        -0.05163472890853882,
        0.03937818855047226,
        0.051354482769966125,
        0.04489598423242569,
        -0.01563093811273575,
        0.03546067327260971,
        -0.05185472220182419,
        0.007897602394223213,
        -0.04333123937249184,
        -0.051358651369810104,
        0.031412865966558456,
        -0.05161644145846367,
        0.028807852417230606,
        -0.05183747410774231,
        -0.051822129637002945,
        0.017066320404410362,
        0.051865700632333755,
        -0.05154036357998848,
        0.03951197862625122,
        -0.04645800590515137,
        -0.048960693180561066,
        -0.051843397319316864,
        -0.029263081029057503,
        -0.051710791885852814,
        0.0517631396651268,
        -0.028199873864650726,
        0.05171941593289375,
        0.051866304129362106,
        0.05175583437085152,
        0.042643286287784576,
        0.04998810961842537,
        -0.04483223706483841,
        0.022676439955830574,
        -0.0037929273676127195,
        -0.05157441273331642,
        0.02580832503736019,
        0.0497703030705452,
        -0.04978213086724281,
        -0.05185627564787865,
        -0.048364292830228806,
        -0.04892173409461975,
        -0.049507103860378265,
        -0.05160756781697273,
        -0.04025380685925484,
        -0.050417348742485046,
        -0.05186592414975166,
        -0.03384634479880333,
        0.050200775265693665,
        0.048972439020872116,
        0.04819459095597267,
        0.051609743386507034,
        -0.05179953575134277,
        -0.03809203952550888,
        0.05185042694211006,
        -0.03941340371966362,
        0.05118382349610329,
        -0.051733702421188354,
        0.033862028270959854,
        0.04489229619503021,
        -0.011672496795654297,
        0.05175575613975525,
        -0.05147023871541023,
        0.05048473924398422,
        -0.046681590378284454,
        0.05049246549606323,
        0.010513518005609512,
        0.05027945339679718,
        -0.050843920558691025,
        0.05186378210783005,
        0.05151503160595894,
        -0.010478504933416843,
        0.05185852572321892,
        0.05109207704663277,
        -0.05101616308093071,
        -0.01761382818222046,
        -0.05171923711895943,
        -0.03548938035964966,
        -0.04583842679858208,
        -0.0518660806119442,
        0.03179926052689552,
        -0.03988174721598625,
        -0.051855407655239105,
        0.02058739960193634,
        -0.050332989543676376,
        0.013187293894588947,
        0.03136848285794258,
        -0.05185519903898239,
        0.030057931318879128,
        -0.05186580866575241,
        0.051351431757211685,
        -0.04741694778203964,
        -0.04222877696156502,
        0.043243657797575,
        0.04889974743127823,
        0.002115430776029825,
        0.04804280400276184,
        -0.04755537956953049,
        -0.05144533887505531,
        0.049086328595876694,
        -0.04819423332810402,
        0.007351674139499664,
        -0.002432846697047353,
        0.051249805837869644,
        0.03034691885113716,
        0.033351972699165344,
        0.05176008120179176,
        0.04689069464802742,
        -0.05088798329234123,
        -0.04071313142776489,
        0.005928927566856146,
        0.037798233330249786,
        0.05186508968472481,
        0.033499058336019516,
        -0.051865141838788986,
        -0.0362774133682251,
        -0.05185692384839058,
        0.05182543769478798,
        0.0518110990524292,
        -0.051820531487464905,
        0.05186029151082039,
        -0.05178934708237648,
        -0.051547423005104065,
        0.05155272036790848,
        0.04336366429924965,
        0.05181368812918663,
        -0.0488818921148777,
        0.043463125824928284,
        -0.0490289181470871,
        0.046366095542907715,
        -0.05104130506515503,
        -0.02251293510198593,
        -0.051851097494363785,
        0.04797342047095299,
        -0.008649462834000587,
        0.01736290752887726,
        0.03956078365445137,
        -0.05174272879958153,
        0.05173207074403763,
        0.050643667578697205,
        0.04296157509088516,
        -0.05050390958786011,
        0.010689510963857174,
        0.03385418653488159,
        -0.0038739012088626623,
        0.051866304129362106,
        -0.04474696144461632,
        -0.03337933495640755,
        -0.0357629731297493,
        -0.051688745617866516,
        -0.04464154317975044,
        0.05090088024735451,
        0.051503442227840424,
        0.05174221098423004,
        0.004637972917407751,
        -0.024591919034719467,
        -0.05182584747672081,
        -0.01612401381134987,
        0.02155693806707859,
        0.044655174016952515,
        0.027720192447304726,
        0.051506318151950836,
        0.03830390051007271,
        0.05172261595726013,
        0.04715626686811447
    ],
    [
        -0.057751260697841644,
        -0.039291299879550934,
        0.023374272510409355,
        0.008126910775899887,
        0.021945668384432793,
        -0.004426266066730022,
        0.057704973965883255,
        -0.05530847981572151,
        0.015363707207143307,
        0.05405624583363533,
        0.020978178828954697,
        0.0562715120613575,
        -0.05693856254220009,
        -0.03477606177330017,
        -0.057261865586042404,
        0.05574090778827667,
        -0.052907951176166534,
        0.05668986961245537,
        -0.057646721601486206,
        0.03276057913899422,
        -0.05724156275391579,
        0.048834171146154404,
        0.05733674019575119,
        0.02765524759888649,
        0.021957898512482643,
        0.03778751567006111,
        -0.00458989292383194,
        0.05566862225532532,
        -0.05087120831012726,
        -0.05744235962629318,
        0.05347263813018799,
        0.035560980439186096,
        -0.002615836914628744,
        -0.053126461803913116,
        0.03277936205267906,
        -0.05357099324464798,
        -0.05578154698014259,
        -0.047943226993083954,
        -0.05578317865729332,
        0.03167249262332916,
        -0.016690997406840324,
        0.007613626774400473,
        0.05574426054954529,
        -0.055245574563741684,
        0.05388294532895088,
        0.024847308173775673,
        0.051106762140989304,
        0.05568981170654297,
        0.057403191924095154,
        0.02785847894847393,
        -0.021511508151888847,
        0.017492972314357758,
        0.05470408499240875,
        -0.05351211875677109,
        -0.016326678916811943,
        -0.053892627358436584,
        -0.05732826516032219,
        0.003954223357141018,
        -0.05579020828008652,
        0.05510468780994415,
        -0.05625313147902489,
        0.026893245056271553,
        -0.056017737835645676,
        0.003147174371406436,
        -0.008065803907811642,
        0.008743549697101116,
        0.053045690059661865,
        -0.05198337510228157,
        -0.004617313388735056,
        -0.057948388159275055,
        -0.04968312382698059,
        -0.0578838586807251,
        0.05255204439163208,
        -0.008627093397080898,
        0.013680371455848217,
        -0.022492580115795135,
        0.0570441372692585,
        0.05737214908003807,
        0.05682158097624779,
        -0.052184972912073135,
        -0.03426385670900345,
        0.05575926601886749,
        -0.0431302972137928,
        0.012369903735816479,
        0.030197257176041603,
        0.042859237641096115,
        -0.0505632609128952,
        0.03733827918767929,
        0.04269113019108772,
        0.05660579726099968,
        -0.028935309499502182,
        0.04595740884542465,
        0.0251854807138443,
        -0.0295183714479208,
        0.012229517102241516,
        -0.02331712655723095,
        -0.038190871477127075,
        -0.056912463158369064,
        -0.047830697149038315,
        0.03979673236608505,
        -0.05784059688448906,
        0.003114482155069709,
        0.05767187476158142,
        0.036607056856155396,
        0.0564323253929615,
        -0.03820701315999031,
        -0.055711377412080765,
        0.05678696557879448,
        -0.05721944570541382,
        0.023001456633210182,
        -0.05751115083694458,
        -0.05635587126016617,
        -0.053776200860738754,
        -0.001503644627518952,
        0.052373409271240234,
        -0.03428932651877403,
        -0.03553856536746025,
        -0.05654195323586464,
        -0.05709078535437584,
        0.010958796367049217,
        -0.006243844050914049,
        -0.015117906033992767,
        -0.05616339296102524,
        0.05463039129972458,
        -0.057761479169130325,
        0.048467591404914856,
        0.02626122534275055,
        0.03460385277867317,
        0.055605217814445496,
        -0.03503000736236572,
        0.033000439405441284,
        -0.05029603838920593,
        0.013301965780556202,
        0.04448579624295235,
        -0.052638936787843704,
        -0.05309446156024933,
        0.04947603493928909,
        -0.053040761500597,
        -0.013802669011056423,
        0.05233621224761009,
        0.055894121527671814,
        0.007134481333196163,
        0.05095548927783966,
        -0.015764914453029633,
        -0.007545760367065668,
        0.012091591954231262,
        0.05793631449341774,
        -0.03245217353105545,
        -0.05644723400473595,
        0.05451200529932976,
        -0.056027378886938095,
        -0.057839181274175644,
        -0.05675584822893143,
        0.02455010637640953,
        -0.05275335907936096,
        -0.056010495871305466,
        0.057909704744815826,
        0.041476696729660034,
        -0.01887206733226776,
        -0.01025998592376709,
        0.05777771398425102,
        0.006569744553416967,
        0.05080995336174965,
        0.01472581084817648,
        -0.05741794779896736,
        -0.03218716010451317,
        -0.003340804483741522,
        -0.027092989534139633,
        0.04314950108528137,
        0.046371862292289734,
        -0.051888540387153625,
        0.01075571496039629,
        -0.0549217090010643,
        0.04299238324165344,
        -0.05301368981599808,
        0.044651713222265244,
        -0.05791338160634041,
        -0.03242158517241478,
        0.05302966386079788,
        -0.022348400205373764,
        0.05430860072374344,
        0.020816033706068993,
        0.057587239891290665,
        0.021194804459810257,
        0.04987156763672829,
        -0.006272871978580952,
        -0.03572149947285652,
        0.05709442123770714,
        -0.04328767955303192,
        -0.05737733095884323,
        0.0005025390419177711,
        -0.033498674631118774,
        -0.015566273592412472,
        0.055423542857170105,
        -0.02682478353381157,
        0.014274843968451023,
        0.05790478736162186,
        -0.048076849430799484,
        -0.03796494007110596,
        0.04114397242665291,
        -0.021724676713347435,
        0.04885957017540932,
        0.046017829328775406,
        0.05764693766832352,
        -0.024427639320492744,
        0.056648217141628265,
        0.03555787727236748,
        0.014258470386266708,
        -0.05614447221159935,
        -0.05488278344273567,
        -0.04122559726238251,
        0.010409761220216751,
        -0.03610914200544357,
        -0.011751890182495117,
        0.05091974139213562,
        -0.00039708049735054374,
        0.057881131768226624,
        -0.009504412300884724,
        0.057935502380132675,
        -0.04494500532746315,
        0.02541436068713665,
        0.0297587588429451,
        -0.05678405612707138,
        -0.057948581874370575,
        0.057759903371334076,
        -0.0579419769346714,
        0.029673686251044273,
        0.057080332189798355,
        -0.054360877722501755,
        -0.05598130449652672,
        0.0035015521571040154,
        -0.057692185044288635,
        -0.057740893214941025,
        0.05519900098443031,
        0.03366183117032051,
        0.036550458520650864,
        0.05745093151926994,
        -0.013028864748775959,
        -0.00415903190150857,
        0.05720633640885353,
        0.05173483490943909,
        0.012855293229222298,
        -0.05232234299182892,
        0.051053840667009354,
        -0.006409082096070051,
        0.04331519827246666,
        0.03459741175174713,
        0.008907764218747616,
        0.010364183224737644,
        -0.051982007920742035,
        0.0003744630957953632,
        -0.057270538061857224,
        -0.00449485844001174,
        -0.057118114084005356,
        -0.012323783710598946,
        0.057542674243450165,
        -0.038704898208379745,
        -0.04457953944802284,
        0.05784738436341286,
        0.05718539282679558,
        0.046699006110429764,
        0.05446900054812431,
        -0.027652136981487274,
        0.04804040119051933,
        -0.055960457772016525,
        0.021925583481788635,
        -0.00026584294391795993,
        0.03689993917942047,
        -0.014194220304489136,
        -0.04926995933055878,
        -0.03831241652369499,
        -0.05785131826996803,
        -0.03669946640729904,
        -0.05644089728593826,
        -0.02698601223528385,
        0.017727268859744072,
        -0.05744403600692749,
        0.041263703256845474,
        -0.04806065559387207,
        -0.027431903406977654,
        -0.05777560546994209,
        -0.04813171178102493,
        -0.007413714192807674,
        0.053905680775642395,
        -0.0041322787292301655,
        -0.0576290525496006,
        -0.007893695496022701,
        -0.05767183378338814,
        -0.05267420411109924,
        0.0506524033844471,
        0.01311411801725626,
        0.057029541581869125,
        0.00925104133784771,
        0.0035345242358744144,
        0.019589437171816826,
        -0.05618317425251007,
        0.05790230259299278,
        0.04995350539684296,
        -0.05788581073284149,
        0.050683941692113876,
        -0.013221677392721176,
        0.04925137385725975,
        -0.036815252155065536,
        0.05438803881406784,
        0.044307589530944824,
        0.0006864124443382025,
        -0.0568719245493412,
        0.03969619795680046,
        0.05452680587768555,
        -0.05786145478487015,
        0.048901189118623734,
        -0.05323882773518562,
        -0.01731838285923004,
        0.0570676289498806,
        -0.05697839334607124,
        -0.05580704286694527,
        0.05729089677333832,
        -0.03694768249988556,
        0.03198646008968353,
        -0.04368800297379494,
        -0.05723715201020241,
        0.05708635598421097,
        0.05616571754217148,
        0.03812756761908531,
        0.05745858699083328,
        0.05709218606352806,
        0.05367892608046532,
        -0.013678360730409622,
        -0.04989747330546379,
        -0.03386267274618149,
        0.0024372171610593796,
        0.04969662427902222,
        0.02690194547176361,
        -0.05729582533240318,
        0.053337112069129944,
        0.04337636008858681,
        -0.04383808746933937,
        0.057639528065919876,
        -0.056696221232414246,
        0.0548853725194931,
        0.04060439392924309,
        0.010306631214916706,
        -0.017497468739748,
        -0.05538289248943329,
        0.05796082690358162,
        0.02343045175075531,
        0.019930459558963776,
        0.054232656955718994,
        -0.022061873227357864,
        0.055626340210437775,
        -0.056521225720644,
        0.05752579867839813,
        -0.04867793619632721,
        -0.05718270316720009,
        -0.05742957070469856,
        0.04842552915215492,
        -0.05697733163833618,
        -0.03640797361731529,
        -0.01996155083179474,
        -0.056045472621917725,
        0.05664697289466858,
        0.005710194818675518,
        -0.057775430381298065,
        0.020762376487255096,
        0.05671117827296257,
        -0.0346430279314518,
        0.057800885289907455,
        -0.041099779307842255,
        0.053458258509635925,
        -0.05793903395533562,
        -0.04439616575837135,
        -0.057836055755615234,
        0.05754395201802254,
        0.050817541778087616,
        0.05543195456266403,
        0.05777149647474289,
        -0.00392780639231205,
        0.04324544221162796,
        -0.04012206941843033,
        -0.05419991537928581,
        -0.026168830692768097,
        0.05737227573990822,
        -0.05611247941851616,
        -0.006609875243157148,
        0.055524226278066635,
        0.010018788278102875,
        -0.02699049562215805,
        -0.057667627930641174,
        -0.05739172175526619,
        -0.05646611005067825,
        -0.043474867939949036,
        0.009915846399962902,
        0.042755983769893646,
        -0.04766570404171944,
        -0.027510320767760277,
        0.05683421343564987,
        0.05771305784583092,
        -0.012916245497763157,
        -0.03183193504810333,
        -0.057826001197099686,
        -0.03807760775089264,
        0.052461180835962296,
        0.05400270223617554,
        0.057951558381319046,
        -0.04690493270754814,
        0.013178780674934387,
        0.049041036516427994,
        0.016342176124453545,
        0.05166240409016609,
        -0.053755905479192734,
        0.041689954698085785,
        -0.025032106786966324,
        -0.0304315946996212,
        0.031082889065146446,
        0.05560304969549179,
        -0.02269989624619484,
        0.05354294925928116,
        0.04118916764855385,
        0.04720282182097435,
        0.05680273100733757,
        -0.05696200951933861,
        -0.05486435443162918,
        -0.05726901814341545,
        -0.054653577506542206,
        0.018462926149368286,
        -0.014156018383800983,
        -0.055172231048345566,
        -0.035632696002721786,
        -0.05741064250469208,
        -0.032454438507556915,
        0.02995791658759117,
        -0.05052044987678528,
        0.010528947226703167,
        0.032453503459692,
        0.008904661051928997,
        -0.02527160383760929,
        -0.056602489203214645,
        0.05731061100959778,
        0.03995243087410927,
        -0.03105439804494381,
        -0.003978171851485968,
        0.0294491034001112,
        0.04346316307783127,
        0.055895838886499405,
        -0.029444318264722824,
        0.03488524630665779,
        0.05577481910586357,
        -0.0536288283765316,
        0.05733437463641167,
        -0.0516238808631897,
        -0.02484223246574402,
        0.04504867270588875,
        0.05415000766515732,
        -0.040081240236759186,
        0.011213649064302444,
        -0.051795948296785355,
        0.04593512415885925,
        0.027849052101373672,
        -0.056759849190711975,
        0.053908511996269226,
        0.006628050003200769,
        -0.052500903606414795,
        -0.057234179228544235,
        -0.05669458210468292,
        0.05778862163424492,
        0.057795342057943344,
        -0.057672470808029175,
        0.05766052007675171,
        -0.05792531743645668,
        -0.057399265468120575,
        0.052200209349393845,
        0.006697501987218857,
        0.05474648252129555,
        -0.006132068112492561,
        0.05785718932747841,
        0.03783537819981575,
        -0.027238182723522186,
        -0.034374844282865524,
        -0.044578585773706436,
        -0.049515701830387115,
        0.007938938215374947,
        -0.030327290296554565,
        0.05318688601255417,
        0.01651512086391449,
        -0.0439811535179615,
        0.05329619720578194,
        0.03690100088715553,
        0.005443230737000704,
        -0.05362614616751671,
        0.03450082987546921,
        -0.05443042144179344,
        -0.054082222282886505,
        0.056896109133958817,
        0.02522650733590126,
        0.05535909906029701,
        0.04486268758773804,
        -0.05786685273051262,
        -0.05494870990514755,
        0.057492177933454514,
        0.035052862018346786,
        0.03819826617836952,
        -0.04326933994889259,
        -0.02629688009619713,
        -0.018960971385240555,
        0.02399427257478237,
        0.0375031903386116,
        -0.016570251435041428,
        0.023084532469511032,
        -0.04540227726101875,
        -0.025589583441615105,
        0.05795662850141525,
        0.05727926641702652
    ],
    [
        0.009610033594071865,
        -0.00872659869492054,
        0.030290817841887474,
        -0.001703415298834443,
        -0.01663675718009472,
        -0.029679488390684128,
        0.05181443691253662,
        -0.05536035820841789,
        0.05507832020521164,
        0.054402485489845276,
        0.05174143239855766,
        0.04130396246910095,
        -0.05529748275876045,
        0.0404360331594944,
        -0.05503802374005318,
        -0.02920958213508129,
        0.05434015393257141,
        0.05261159688234329,
        0.050687454640865326,
        -0.004081815015524626,
        -0.033674176782369614,
        0.03377055004239082,
        0.017142808064818382,
        0.04959627985954285,
        -0.05371499061584473,
        0.04880160093307495,
        0.054044459015131,
        0.009691091254353523,
        -0.02079419605433941,
        -0.03730820119380951,
        -0.01209635566920042,
        0.05483844876289368,
        -0.004107912536710501,
        -0.055034711956977844,
        0.04394637793302536,
        -0.05461661145091057,
        -0.050946179777383804,
        -0.019624577835202217,
        0.05218750983476639,
        -0.050528768450021744,
        -0.05387937277555466,
        0.044825296849012375,
        0.04392511397600174,
        -0.04832329973578453,
        0.02784937433898449,
        -0.05276420712471008,
        -0.05010446906089783,
        0.055358413606882095,
        0.0553739108145237,
        -0.05116169899702072,
        0.05534091219305992,
        -0.005941914860159159,
        0.01784450188279152,
        -0.05497387424111366,
        -0.016616443172097206,
        0.04421951249241829,
        -0.05299963057041168,
        0.033369261771440506,
        -0.051791682839393616,
        -0.05524161085486412,
        -0.054773733019828796,
        -0.006405127700418234,
        -0.05509521812200546,
        -0.017413388937711716,
        0.054496586322784424,
        -0.05310828238725662,
        -0.05520273745059967,
        -0.053419508039951324,
        -0.010306709446012974,
        0.044177211821079254,
        0.001105460338294506,
        -0.054785098880529404,
        0.05333181843161583,
        0.0415964350104332,
        0.033209215849637985,
        -0.03144869953393936,
        0.050632525235414505,
        0.055380865931510925,
        0.04306488484144211,
        0.05241479352116585,
        -0.04984293133020401,
        -0.0536041222512722,
        -0.054360032081604004,
        0.054236505180597305,
        -0.055036310106515884,
        0.0464482307434082,
        -0.047452669590711594,
        -0.04670945182442665,
        -0.04858081042766571,
        0.05455532297492027,
        -0.05536426231265068,
        -0.0542905256152153,
        -0.0552922785282135,
        0.010283193551003933,
        -0.05321560055017471,
        -0.026740340515971184,
        -0.051363106817007065,
        -0.05484326183795929,
        0.051833558827638626,
        0.04632583260536194,
        -0.055330995470285416,
        0.05526814982295036,
        0.04514462873339653,
        0.05308958888053894,
        -0.03384518250823021,
        -0.05429161339998245,
        -0.0373837910592556,
        0.03535514697432518,
        -0.052894432097673416,
        0.05344747379422188,
        -0.012054141610860825,
        -0.054772667586803436,
        -0.05371522158384323,
        0.04954693466424942,
        0.05104096606373787,
        0.049246691167354584,
        -0.025179443880915642,
        -0.055221233516931534,
        0.0478510856628418,
        -0.05520074814558029,
        -0.047482628375291824,
        0.021459607407450676,
        -0.025574248284101486,
        -0.009303350001573563,
        -0.04578498378396034,
        0.022057322785258293,
        0.021225078031420708,
        0.042234186083078384,
        0.0502726212143898,
        -0.0044904304668307304,
        0.0330549031496048,
        -0.05425672605633736,
        0.051606375724077225,
        0.04802308231592178,
        -0.011540035717189312,
        -0.05537591502070427,
        0.055333830416202545,
        -0.05256963521242142,
        0.03929883614182472,
        0.036299992352724075,
        0.02069961093366146,
        -0.055306002497673035,
        0.05112094432115555,
        0.021753795444965363,
        0.02339663729071617,
        0.05493868142366409,
        0.024935247376561165,
        0.054968103766441345,
        -0.02483004704117775,
        -0.05471835285425186,
        0.04065432772040367,
        -0.05532017722725868,
        0.04372905567288399,
        -0.03291104733943939,
        0.05202503874897957,
        -0.016836492344737053,
        -0.05228925868868828,
        0.006490478757768869,
        0.054791100323200226,
        0.04324394464492798,
        0.029173901304602623,
        -0.04556211456656456,
        0.054897572845220566,
        0.049787942320108414,
        -0.0524340495467186,
        -0.03717669099569321,
        0.05390530079603195,
        0.05527687072753906,
        -0.04174618050456047,
        0.052245210856199265,
        -0.04193462058901787,
        0.05459659919142723,
        -0.05536625534296036,
        0.05516110733151436,
        -0.04813520237803459,
        0.0329446867108345,
        0.05537477508187294,
        -0.019931869581341743,
        0.05492692440748215,
        -0.055115923285484314,
        -0.055142804980278015,
        0.05526484176516533,
        0.05361603572964668,
        0.009719764813780785,
        0.051975756883621216,
        0.007473841309547424,
        0.053298696875572205,
        0.05369621142745018,
        0.02496436983346939,
        -0.05529707670211792,
        -0.055193156003952026,
        0.031115621328353882,
        -0.05533187836408615,
        -0.02844036929309368,
        -0.027351295575499535,
        -0.0012148303212597966,
        -0.02229856140911579,
        -0.05405908450484276,
        0.042548734694719315,
        0.04892763867974281,
        0.04942700266838074,
        0.002806641161441803,
        0.05360016971826553,
        -0.04940709099173546,
        0.053257156163454056,
        -0.046329136937856674,
        0.054194603115320206,
        0.053524017333984375,
        -0.04624495655298233,
        0.012183194980025291,
        -0.05393192917108536,
        0.052865345031023026,
        0.05410715192556381,
        0.054356858134269714,
        -0.018290812149643898,
        -0.04038568213582039,
        0.053910501301288605,
        0.0021123369224369526,
        0.05525022745132446,
        -0.03817272186279297,
        0.05267048254609108,
        0.04497651010751724,
        -0.03667229041457176,
        0.038701992481946945,
        0.05537256971001625,
        -0.050185345113277435,
        0.011628629639744759,
        -0.05381906405091286,
        0.019037870690226555,
        -0.05427087843418121,
        -0.0485539473593235,
        -0.04772120341658592,
        0.03757944703102112,
        0.05477131903171539,
        -0.05157969146966934,
        0.054089855402708054,
        0.054548945277929306,
        -0.0018129871459677815,
        0.010165415704250336,
        0.05537688732147217,
        -0.030538592487573624,
        -0.0551895871758461,
        -0.023416463285684586,
        -0.05518285185098648,
        0.053438376635313034,
        -0.0050635188817977905,
        0.05523815006017685,
        0.04271961748600006,
        -0.03476208820939064,
        -0.05015966296195984,
        -0.0062578460201621056,
        -0.04499347507953644,
        0.027226530015468597,
        -0.05417073518037796,
        -0.050672419369220734,
        0.034941416233778,
        -0.021886995062232018,
        -0.0551626980304718,
        0.03867247328162193,
        0.05506770685315132,
        -0.05056048184633255,
        -0.01058314461261034,
        -0.02962494269013405,
        0.054560337215662,
        -0.041885219514369965,
        0.05514758825302124,
        0.05318094417452812,
        -0.02284334972500801,
        -0.05244790390133858,
        -0.05453382804989815,
        0.03999198600649834,
        -0.05472403019666672,
        0.053902264684438705,
        -0.05466770380735397,
        0.03569986671209335,
        0.05511965975165367,
        -0.05371735990047455,
        -0.029791543260216713,
        0.053825415670871735,
        -0.050058189779520035,
        -0.05534425750374794,
        0.05134081467986107,
        0.01691359281539917,
        -0.05426192656159401,
        -0.025925282388925552,
        0.027662985026836395,
        0.03485316038131714,
        0.04837353527545929,
        0.014355923980474472,
        -0.021778123453259468,
        -0.009370369836688042,
        0.05057927221059799,
        -0.05491556227207184,
        -0.05519520491361618,
        0.05360010638833046,
        -0.05073688551783562,
        0.048864591866731644,
        -0.051413264125585556,
        -0.05308375135064125,
        0.05506241321563721,
        -0.045916568487882614,
        -0.008252416737377644,
        0.017059234902262688,
        0.052684370428323746,
        -0.010711570270359516,
        0.047437816858291626,
        -0.028486546128988266,
        0.04758799076080322,
        0.014016366563737392,
        -0.031181486323475838,
        0.008446126244962215,
        0.04593891650438309,
        0.05493227019906044,
        -0.0065866392105817795,
        0.005433947313576937,
        -0.053933508694171906,
        -0.028962451964616776,
        0.020606081932783127,
        0.0528041310608387,
        -0.03951677307486534,
        0.05114883556962013,
        -0.03264029324054718,
        0.04710877686738968,
        0.037745676934719086,
        0.05537986755371094,
        0.038567136973142624,
        0.05501260235905647,
        -0.054404404014348984,
        0.02473527006804943,
        -0.05492277815937996,
        0.044239673763513565,
        -0.03473129868507385,
        -0.03264043480157852,
        -0.05510445311665535,
        0.0532391332089901,
        -0.028599245473742485,
        -0.054818179458379745,
        -0.005348736420273781,
        0.0025740996934473515,
        0.029415305703878403,
        0.04365444928407669,
        -0.010468373075127602,
        0.05536530166864395,
        0.04875151813030243,
        0.05445197969675064,
        0.04747571423649788,
        0.02191341109573841,
        0.05533112585544586,
        0.01157981064170599,
        0.04505079239606857,
        0.054312318563461304,
        0.02521749772131443,
        -0.028600459918379784,
        -0.055233582854270935,
        0.03942211717367172,
        0.028187613934278488,
        -0.041185230016708374,
        -0.05369883030653,
        -0.051292579621076584,
        -0.028079336509108543,
        0.04986809566617012,
        0.014821956865489483,
        -0.03562283143401146,
        -0.051122117787599564,
        -0.014719607308506966,
        -0.055296555161476135,
        0.05523936450481415,
        -0.017800550907850266,
        -0.03672517463564873,
        -0.05536765232682228,
        -0.013667619787156582,
        -0.0543958954513073,
        0.05514739453792572,
        -0.009634783491492271,
        0.04789416864514351,
        0.055361177772283554,
        -0.012521653436124325,
        -0.014328090474009514,
        0.05518554896116257,
        -0.04245303198695183,
        -0.04264400154352188,
        0.05515800043940544,
        -0.05124920234084129,
        0.054075367748737335,
        0.03523865342140198,
        -0.054796330630779266,
        0.050543319433927536,
        -0.000894228694960475,
        -0.04657586291432381,
        0.053325019776821136,
        -0.05533295124769211,
        -0.03535545989871025,
        -0.05445937067270279,
        -0.054194677621126175,
        0.04932348430156708,
        0.054087016731500626,
        -0.05528911203145981,
        -0.04390880838036537,
        -0.023243248462677002,
        -0.05518878623843193,
        0.015177140012383461,
        0.05360473692417145,
        -0.014245597645640373,
        0.04531304910778999,
        -0.05247264355421066,
        -0.035731323063373566,
        -0.006655140779912472,
        -0.04531820863485336,
        0.055244654417037964,
        -0.02590317465364933,
        -0.03595675528049469,
        0.012295964173972607,
        0.006020963657647371,
        -0.02114434912800789,
        0.05482437461614609,
        -0.01616215705871582,
        -0.042732857167720795,
        -0.04872097074985504,
        0.052984800189733505,
        0.052623435854911804,
        0.05534876510500908,
        -0.055343396961688995,
        -0.040379710495471954,
        -0.055379774421453476,
        0.02033226564526558,
        -0.055016160011291504,
        -0.054151516407728195,
        -0.015548117458820343,
        -0.053362730890512466,
        -0.055260565131902695,
        0.0027456420939415693,
        -0.0076056127436459064,
        0.054816536605358124,
        0.006283784285187721,
        -0.05210474506020546,
        -0.05323141813278198,
        -0.05356556549668312,
        0.05376636981964111,
        -0.04469038546085358,
        -0.05532066896557808,
        -0.034277524799108505,
        0.05424054339528084,
        0.05537157505750656,
        0.05506766587495804,
        -0.05132896080613136,
        0.025361977517604828,
        0.05518031120300293,
        0.020056696608662605,
        0.04934342950582504,
        0.05424117296934128,
        0.05456792190670967,
        0.0530315600335598,
        0.054662685841321945,
        0.02585563063621521,
        0.009320245124399662,
        -0.055113784968853,
        -0.052077509462833405,
        -0.05410771444439888,
        0.05498771741986275,
        0.05527549609541893,
        0.04028083384037018,
        -0.0545671246945858,
        0.0052942996844649315,
        0.005430982448160648,
        0.054883185774087906,
        0.05533066391944885,
        -0.055319543927907944,
        0.0547766238451004,
        -0.03811289370059967,
        -0.054149553179740906,
        0.01577693223953247,
        -0.007058406714349985,
        0.05481080338358879,
        -0.006363302003592253,
        0.007891512475907803,
        -0.0006119474419392645,
        -0.047359950840473175,
        0.055281221866607666,
        -0.05366566404700279,
        -0.03551832586526871,
        0.01952025294303894,
        0.030616680160164833,
        0.03765397518873215,
        0.05092982202768326,
        -0.04303358495235443,
        0.05278913676738739,
        0.05512707680463791,
        0.052774328738451004,
        -0.052807874977588654,
        -0.05479045957326889,
        -0.055222682654857635,
        -0.04883302375674248,
        0.05523107945919037,
        -0.051369938999414444,
        -0.04760631546378136,
        -0.00652976194396615,
        -0.046958353370428085,
        -0.0537700317800045,
        0.03674863651394844,
        -0.02402828447520733,
        -0.02998974919319153,
        -0.0550440177321434,
        0.00856665801256895,
        -0.007955827750265598,
        0.05276785418391228,
        0.055110640823841095,
        0.05524464696645737,
        -0.048513416200876236,
        0.051477715373039246,
        -0.04680514335632324,
        0.05510787293314934,
        0.015404362231492996
    ],
    [
        0.003933837171643972,
        -0.02843991108238697,
        0.03708139434456825,
        -0.020427076146006584,
        0.0414307527244091,
        -0.041251033544540405,
        0.06054458022117615,
        -0.008005131036043167,
        0.024448107928037643,
        0.0478520430624485,
        0.0562753789126873,
        0.0549464076757431,
        -0.0602664053440094,
        -0.003633177140727639,
        -0.06046268343925476,
        0.009914785623550415,
        -0.02349872887134552,
        0.05758143588900566,
        -0.01939188875257969,
        0.03190192952752113,
        0.05939958617091179,
        -0.020989174023270607,
        0.030127184465527534,
        0.049404922872781754,
        0.04523788392543793,
        0.04949953407049179,
        0.03030996210873127,
        0.04522855952382088,
        -0.03842959925532341,
        -0.05590498074889183,
        0.043738868087530136,
        -0.015206471085548401,
        0.04567941278219223,
        -0.05867386609315872,
        0.05197368189692497,
        -0.04080422595143318,
        -0.05431896448135376,
        -0.008089356124401093,
        0.06050955131649971,
        -0.010908863507211208,
        -0.03676892817020416,
        -0.032073963433504105,
        0.0502675361931324,
        -0.051817428320646286,
        -0.02239205315709114,
        0.010681021958589554,
        0.04897559806704521,
        0.060563474893569946,
        0.060521479696035385,
        -0.015495226718485355,
        0.06063099578022957,
        0.04827818647027016,
        0.05913330242037773,
        -0.05996434763073921,
        0.050367116928100586,
        -0.019750285893678665,
        0.030247552320361137,
        -0.04834906384348869,
        -0.05906551703810692,
        0.010872864164412022,
        -0.06007147207856178,
        0.0407392717897892,
        -0.059355031698942184,
        -0.012843425385653973,
        0.03851372003555298,
        -0.022052740678191185,
        -0.039147477596998215,
        -0.05752696841955185,
        0.05539838969707489,
        -0.05985710397362709,
        -0.057552874088287354,
        -0.06057959049940109,
        0.06023126840591431,
        0.0559319332242012,
        -0.02610364742577076,
        0.02817319706082344,
        0.02896680310368538,
        0.06066855043172836,
        0.05346228554844856,
        -0.04678152874112129,
        0.009400313720107079,
        -0.02834419533610344,
        -0.042183008044958115,
        0.051952943205833435,
        -0.05104370042681694,
        0.04493813216686249,
        -0.04479080066084862,
        -0.02083193138241768,
        -0.01605049893260002,
        0.060597874224185944,
        -0.05856693163514137,
        -0.030261382460594177,
        -0.05714290589094162,
        -0.05080166459083557,
        0.01995520479977131,
        -0.052330438047647476,
        0.04303792864084244,
        -0.05954102799296379,
        0.05584326386451721,
        0.03527536243200302,
        -0.059104882180690765,
        0.005470339674502611,
        0.05938920006155968,
        0.05656146630644798,
        -0.010031677782535553,
        -0.016025442630052567,
        -0.05775773897767067,
        0.058538805693387985,
        -0.055766135454177856,
        0.05494621396064758,
        -0.025074655190110207,
        -0.06030621752142906,
        -0.04009869322180748,
        -0.05365898832678795,
        0.04071856290102005,
        0.009081564843654633,
        0.013178455643355846,
        0.006694204173982143,
        -0.0444183386862278,
        -0.06025400012731552,
        -0.05839526280760765,
        -0.05358288809657097,
        -0.04864184930920601,
        -0.047841284424066544,
        -0.0605669841170311,
        0.024419115856289864,
        -0.05672634020447731,
        -0.017949238419532776,
        -0.01024150475859642,
        0.04437398910522461,
        0.010662039741873741,
        -0.016492942348122597,
        -0.03664363548159599,
        0.04158233478665352,
        0.015267315320670605,
        -0.049864448606967926,
        0.060677655041217804,
        -0.040161579847335815,
        0.03269333019852638,
        0.05970783904194832,
        -0.004763630218803883,
        -0.03208504989743233,
        0.04199831932783127,
        0.06057379022240639,
        -0.039705295115709305,
        0.013952760025858879,
        0.0559617318212986,
        -0.03798193857073784,
        0.00014417436614166945,
        0.030106237158179283,
        -0.04275405406951904,
        -0.060626205056905746,
        -0.06045060604810715,
        -0.017376398667693138,
        0.01963481865823269,
        -0.049724429845809937,
        0.005277993157505989,
        0.021515538915991783,
        0.03287668898701668,
        0.03569303825497627,
        0.056339800357818604,
        0.050649747252464294,
        0.04935770854353905,
        -0.05271593853831291,
        0.0009511035750620067,
        0.023348404094576836,
        0.04324525594711304,
        -0.04688267409801483,
        -0.03661699965596199,
        0.05746982991695404,
        0.05347951501607895,
        -0.042874183505773544,
        -0.06006702035665512,
        0.03430401161313057,
        -0.05714776739478111,
        0.03786678612232208,
        -0.034492332488298416,
        -0.05948818847537041,
        0.05805974081158638,
        -0.02798447571694851,
        0.016690129414200783,
        0.05928150564432144,
        0.0577772781252861,
        -0.03958910331130028,
        0.05179518833756447,
        -0.05392366647720337,
        0.03667584806680679,
        0.0017670205561444163,
        0.04569259285926819,
        -0.0602923184633255,
        -0.057649292051792145,
        -0.02233712561428547,
        0.012598623521625996,
        0.04714500904083252,
        -0.02605334110558033,
        0.044556476175785065,
        0.05478067323565483,
        0.01116988342255354,
        0.0012335107894614339,
        0.04321163147687912,
        0.026500320062041283,
        -0.006892556790262461,
        -0.017063403502106667,
        -0.05408904328942299,
        0.024577274918556213,
        0.05001285299658775,
        0.04068894311785698,
        0.04001863673329353,
        -0.0390477292239666,
        0.05940590053796768,
        -0.059643179178237915,
        -0.05646795406937599,
        0.033763986080884933,
        0.031363487243652344,
        -0.013689939863979816,
        0.02162279188632965,
        -0.015253012999892235,
        0.05377214029431343,
        0.06052413955330849,
        -0.02120674028992653,
        -0.04618057981133461,
        0.03800981864333153,
        0.01939661242067814,
        -0.060500290244817734,
        0.06067546829581261,
        -0.06048509106040001,
        0.05582563951611519,
        -0.05890203267335892,
        0.0489252433180809,
        -0.06059611588716507,
        -0.057002972811460495,
        -0.04317788407206535,
        -0.04383876174688339,
        0.0522584468126297,
        -0.0045402199029922485,
        0.05228215456008911,
        0.059180136770009995,
        0.049295976758003235,
        0.05203799903392792,
        0.060560643672943115,
        0.0013645978178828955,
        0.03662559762597084,
        0.01729992777109146,
        0.0037559191696345806,
        0.0018106296192854643,
        -0.040492136031389236,
        0.0565035417675972,
        0.013388446532189846,
        0.026906654238700867,
        0.01585620455443859,
        0.0009259649668820202,
        -0.05974220857024193,
        -0.0031448337249457836,
        -0.060396481305360794,
        0.0340762734413147,
        0.05835866928100586,
        0.04712207242846489,
        0.03584776818752289,
        0.060589857399463654,
        0.060075655579566956,
        0.00773869501426816,
        0.05690415948629379,
        0.05619743838906288,
        0.05461804196238518,
        0.014737620018422604,
        0.05886770039796829,
        0.056269560009241104,
        0.026519645005464554,
        0.040887217968702316,
        -0.03831915557384491,
        -0.03057960420846939,
        -0.05719004198908806,
        0.055953990668058395,
        -0.0554155558347702,
        -0.00003858195850625634,
        0.037569787353277206,
        -0.05848367139697075,
        0.00013143362593837082,
        -0.0530046783387661,
        -0.02463529258966446,
        -0.06054404005408287,
        0.03639448061585426,
        -0.050874777138233185,
        -0.01982206664979458,
        0.057656072080135345,
        -0.0019302831497043371,
        -0.010433379560709,
        -0.014553945511579514,
        -0.04638494551181793,
        0.01798274554312229,
        -0.05495607480406761,
        -0.0032077552750706673,
        -0.02718937397003174,
        0.05346919968724251,
        0.045863110572099686,
        -0.0584704726934433,
        0.06065909191966057,
        0.040392473340034485,
        -0.008453109301626682,
        0.058430809527635574,
        0.005086822900921106,
        0.001029354752972722,
        -0.03353317081928253,
        0.05820465832948685,
        0.024498138576745987,
        -0.002104628598317504,
        -0.047061871737241745,
        0.04227272793650627,
        0.029669076204299927,
        -0.05761721357703209,
        -0.00493173161521554,
        0.02821945585310459,
        0.026598919183015823,
        0.05993441864848137,
        -0.004290143959224224,
        -0.060004666447639465,
        0.03352031111717224,
        -0.0027617993764579296,
        0.0603102445602417,
        -0.017605511471629143,
        0.029221443459391594,
        -0.04765249416232109,
        0.059055376797914505,
        -0.040993500500917435,
        0.0597807802259922,
        0.04035896807909012,
        0.05941767618060112,
        -0.04315470531582832,
        0.02522270753979683,
        -0.06057058274745941,
        0.023176824674010277,
        0.05136987194418907,
        -0.022340640425682068,
        -0.024371227249503136,
        0.054875072091817856,
        0.031895678490400314,
        -0.05320421978831291,
        0.060326796025037766,
        -0.05524897202849388,
        0.04482707381248474,
        0.022783271968364716,
        0.054296936839818954,
        0.04815768823027611,
        -0.04292412847280502,
        0.06054387614130974,
        0.05197091028094292,
        0.037461020052433014,
        0.05763188749551773,
        -0.05520718917250633,
        0.0592392273247242,
        -0.041175100952386856,
        0.0507483072578907,
        0.006111034192144871,
        -0.060679443180561066,
        -0.035980477929115295,
        0.06004515662789345,
        -0.056942690163850784,
        0.03836145997047424,
        -0.054310113191604614,
        -0.06031497195363045,
        0.05945571884512901,
        0.05944254621863365,
        -0.058091454207897186,
        -0.02305052988231182,
        -0.050172898918390274,
        -0.06060304492712021,
        0.060653816908597946,
        -0.03459399566054344,
        -0.01238520909100771,
        -0.060563359409570694,
        -0.058899372816085815,
        -0.05803075060248375,
        0.06028682738542557,
        0.023494791239500046,
        0.05432751774787903,
        0.04315456748008728,
        0.03978173807263374,
        0.009731815196573734,
        0.05873437598347664,
        -0.036521609872579575,
        -0.05014628916978836,
        0.04578027129173279,
        -0.03850584477186203,
        0.0031077663879841566,
        0.05962619185447693,
        -0.0489487461745739,
        -0.010966626927256584,
        -0.023866044357419014,
        -0.058595579117536545,
        0.021418485790491104,
        -0.038541484624147415,
        0.04716537520289421,
        0.014492466114461422,
        -0.03642962872982025,
        0.020953325554728508,
        0.05976410582661629,
        0.036238327622413635,
        -0.02502543292939663,
        -0.059397757053375244,
        -0.057122666388750076,
        0.037572622299194336,
        0.032174959778785706,
        0.022855008020997047,
        0.059758495539426804,
        -0.04868069291114807,
        -0.00418058130890131,
        0.0403449684381485,
        -0.05586127191781998,
        0.05960347503423691,
        -0.05268193408846855,
        0.02536412701010704,
        -0.029711849987506866,
        -0.013779752887785435,
        0.04476257413625717,
        0.044306885451078415,
        -0.029716873541474342,
        -0.04097003489732742,
        0.023375043645501137,
        0.05880160257220268,
        0.060098592191934586,
        0.05932794138789177,
        -0.019716016948223114,
        -0.043631888926029205,
        -0.06054465100169182,
        0.019388966262340546,
        -0.02478080801665783,
        -0.048265498131513596,
        -0.054595351219177246,
        -0.06038147956132889,
        -0.011769755743443966,
        0.05800582095980644,
        -0.012087264098227024,
        0.054245881736278534,
        0.015399005264043808,
        -0.03565559908747673,
        -0.03404797986149788,
        -0.059487611055374146,
        0.020948130637407303,
        0.04755344241857529,
        -0.0581524558365345,
        0.0000876402118592523,
        0.059855274856090546,
        0.05789358913898468,
        0.05927376076579094,
        -0.05885506793856621,
        0.012418325059115887,
        0.04017568379640579,
        -0.05942881107330322,
        0.05985996127128601,
        0.022222941741347313,
        0.0516601987183094,
        0.051042698323726654,
        0.05631168931722641,
        -0.053992319852113724,
        0.014236957766115665,
        -0.05957014113664627,
        0.016684021800756454,
        -0.031118866056203842,
        0.02087188884615898,
        0.05302906781435013,
        -0.020171143114566803,
        -0.00450192391872406,
        -0.04341525584459305,
        -0.008879119530320168,
        0.05355516076087952,
        0.060639310628175735,
        -0.06047772243618965,
        0.05905485153198242,
        -0.060375846922397614,
        -0.060610271990299225,
        0.057414278388023376,
        0.042072396725416183,
        0.05841471627354622,
        0.05863416567444801,
        0.05973774194717407,
        -0.043313391506671906,
        -0.05419842526316643,
        0.05760800093412399,
        -0.050176575779914856,
        0.0025898024905472994,
        -0.05634697154164314,
        -0.002592596458271146,
        0.037315357476472855,
        0.035977087914943695,
        0.024113323539495468,
        0.05187547579407692,
        0.05865746736526489,
        0.014402060769498348,
        -0.0601881742477417,
        0.0025953773874789476,
        -0.03765777498483658,
        -0.02712995558977127,
        0.011877939105033875,
        -0.01581043004989624,
        0.03234211355447769,
        -0.026111040264368057,
        -0.054637592285871506,
        -0.03300540894269943,
        0.05920976772904396,
        0.0454694926738739,
        -0.059847813099622726,
        -0.0561089813709259,
        -0.05989052355289459,
        0.019588042050600052,
        0.0507994219660759,
        0.04963793605566025,
        0.05990389361977577,
        0.0070184385403990746,
        0.02534712664783001,
        -0.0599345825612545,
        0.060596488416194916,
        0.05589643120765686
    ],
    [
        -0.05338289588689804,
        -0.053665366023778915,
        0.002469049533829093,
        0.010907987132668495,
        -0.05039115622639656,
        -0.051346555352211,
        0.03730977326631546,
        -0.053600918501615524,
        0.013676265254616737,
        0.013246039859950542,
        0.04078534245491028,
        -0.05336322635412216,
        -0.04995181784033775,
        0.0014855979243293405,
        -0.052084337919950485,
        0.022779542952775955,
        -0.05348784849047661,
        0.052958760410547256,
        -0.05366414412856102,
        0.051771536469459534,
        0.047956936061382294,
        -0.048605240881443024,
        0.024667784571647644,
        0.02968100644648075,
        -0.052848804742097855,
        0.0349082350730896,
        -0.04741418734192848,
        0.053655318915843964,
        -0.04424865171313286,
        0.04200011119246483,
        0.053518399596214294,
        0.05360475182533264,
        -0.05263131111860275,
        -0.05366205796599388,
        0.05359765142202377,
        -0.03760138154029846,
        -0.053250283002853394,
        -0.05254419520497322,
        -0.05264710634946823,
        -0.04752342775464058,
        0.052913229912519455,
        -0.051615092903375626,
        0.04940294846892357,
        0.0041226837784051895,
        -0.013418106362223625,
        0.03681707754731178,
        -0.021665070205926895,
        0.05273069813847542,
        0.053471628576517105,
        0.01067978423088789,
        0.04701567068696022,
        0.04962049052119255,
        0.05350935459136963,
        -0.0521443746984005,
        0.029381627216935158,
        0.05050294101238251,
        0.001921217073686421,
        -0.022323649376630783,
        0.04289825260639191,
        -0.05318135395646095,
        -0.05354413017630577,
        -0.05271119624376297,
        -0.05311955139040947,
        -0.05274765193462372,
        -0.049273934215307236,
        -0.05267368629574776,
        -0.037900060415267944,
        -0.045444365590810776,
        0.053665660321712494,
        -0.053661856800317764,
        -0.04462817683815956,
        -0.053656790405511856,
        0.03183523938059807,
        0.013202725909650326,
        0.053636711090803146,
        -0.04674198105931282,
        0.003991421312093735,
        0.05366551876068115,
        -0.038753692060709,
        0.050367243587970734,
        0.02853618562221527,
        -0.04709920287132263,
        -0.04283895716071129,
        0.05348179116845131,
        0.0088280588388443,
        0.0516568124294281,
        0.037453312426805496,
        0.05366532504558563,
        -0.04604688659310341,
        -0.036203186959028244,
        -0.05315839499235153,
        0.046907536685466766,
        0.01627117581665516,
        -0.04934276267886162,
        -0.010305517353117466,
        -0.0010291833896189928,
        -0.052183885127305984,
        -0.05366507172584534,
        -0.05259554460644722,
        -0.016027765348553658,
        -0.03110169805586338,
        0.05014295503497124,
        0.053653206676244736,
        -0.05089753866195679,
        0.053626298904418945,
        0.004161073360592127,
        -0.05360618233680725,
        0.051941074430942535,
        -0.05366341769695282,
        -0.03771631419658661,
        0.045203715562820435,
        -0.0515761598944664,
        0.0011224979534745216,
        -0.026050202548503876,
        -0.040459033101797104,
        -0.053496863692998886,
        0.019940026104450226,
        0.04354516789317131,
        -0.053646985441446304,
        -0.04658199101686478,
        0.05365343019366264,
        0.037671156227588654,
        0.05312209576368332,
        -0.048130013048648834,
        -0.03737407550215721,
        0.0490850955247879,
        0.026556028053164482,
        -0.052382826805114746,
        0.0504198893904686,
        -0.050517018884420395,
        0.014009377919137478,
        0.014904709532856941,
        -0.05094987526535988,
        0.05332988500595093,
        -0.048113174736499786,
        -0.053600721061229706,
        0.053170591592788696,
        -0.05345003679394722,
        -0.05340614169836044,
        0.05215811729431152,
        -0.053602706640958786,
        -0.05087222903966904,
        0.04997057840228081,
        0.05304037779569626,
        -0.04234422370791435,
        0.05253077670931816,
        -0.0532652772963047,
        -0.05264110490679741,
        0.002505394397303462,
        0.051351893693208694,
        -0.04848964139819145,
        -0.05323264002799988,
        -0.044038884341716766,
        0.05061881244182587,
        -0.031217223033308983,
        -0.052837032824754715,
        -0.004032052122056484,
        0.046757958829402924,
        0.04625198617577553,
        -0.04315336048603058,
        0.05347021669149399,
        0.038691967725753784,
        0.05290866643190384,
        0.033379219472408295,
        -0.014146554283797741,
        -0.053440943360328674,
        -0.030990812927484512,
        -0.008335446007549763,
        0.05350545048713684,
        0.04727344959974289,
        -0.03932677581906319,
        0.050253380089998245,
        -0.01101015880703926,
        -0.052714183926582336,
        -0.053499456495046616,
        0.04701574146747589,
        -0.04503997415304184,
        -0.047173310071229935,
        -0.03862270712852478,
        -0.01702796295285225,
        0.011859086342155933,
        0.053640373051166534,
        0.052403174340724945,
        -0.05254065990447998,
        0.024366470053792,
        0.05336339399218559,
        -0.053418420255184174,
        0.04916343465447426,
        0.032991960644721985,
        -0.0528564378619194,
        -0.053655728697776794,
        0.03507755696773529,
        -0.02583962492644787,
        0.05196597799658775,
        -0.053135309368371964,
        0.046551115810871124,
        0.05269486829638481,
        0.053516488522291183,
        -0.05358954146504402,
        0.05340795964002609,
        0.05243021249771118,
        0.05350092425942421,
        -0.020110715180635452,
        0.04375039041042328,
        0.05364655330777168,
        0.0009894075337797403,
        0.05338117852807045,
        0.05364555865526199,
        -0.05359543114900589,
        0.05365189164876938,
        -0.05287126079201698,
        0.05320301279425621,
        -0.00954363588243723,
        0.0354357473552227,
        -0.053622737526893616,
        -0.05364855006337166,
        0.053620245307683945,
        -0.05362637713551521,
        0.053645357489585876,
        -0.0535515695810318,
        0.002019877778366208,
        -0.016614768654108047,
        0.04368840157985687,
        -0.04589404910802841,
        0.05347915366292,
        -0.050646159797906876,
        0.042365897446870804,
        -0.03850717470049858,
        -0.010686029680073261,
        -0.05357837677001953,
        -0.028105325996875763,
        -0.053363583981990814,
        0.03417633846402168,
        0.049981389194726944,
        -0.004451907239854336,
        0.05336731672286987,
        0.0495586059987545,
        0.053296707570552826,
        -0.04767872393131256,
        0.04712631180882454,
        -0.04936268553137779,
        -0.02983323112130165,
        -0.0527445524930954,
        -0.05108403041958809,
        0.050040505826473236,
        0.05355273559689522,
        0.05365917086601257,
        0.05366578325629234,
        0.04818040132522583,
        -0.001484379987232387,
        0.0498892143368721,
        -0.0524299331009388,
        0.021486051380634308,
        -0.048846468329429626,
        -0.043980009853839874,
        0.017908738926053047,
        -0.05125398933887482,
        0.04959920048713684,
        -0.007319530006498098,
        0.05333590880036354,
        -0.05342017859220505,
        -0.052453331649303436,
        0.038361284881830215,
        0.05127523094415665,
        0.04607629403471947,
        0.05300385132431984,
        0.053429014980793,
        -0.0481250174343586,
        0.053221751004457474,
        0.034028541296720505,
        0.03885914012789726,
        -0.053664930164813995,
        0.053574759513139725,
        -0.05338520184159279,
        -0.05366535484790802,
        0.028159458190202713,
        -0.03524693474173546,
        -0.04892866313457489,
        0.010946065187454224,
        0.052417151629924774,
        -0.05328544229269028,
        0.052281685173511505,
        0.0019223480485379696,
        0.02623499371111393,
        0.05186700448393822,
        -0.017189351841807365,
        0.04404950886964798,
        0.023179693147540092,
        -0.037097010761499405,
        0.05331367626786232,
        -0.014587842859327793,
        -0.027895409613847733,
        -0.021505896002054214,
        0.018021803349256516,
        -0.0486641451716423,
        -0.05291558802127838,
        0.053665775805711746,
        -0.04521564394235611,
        -0.05217425152659416,
        0.053220175206661224,
        0.052642740309238434,
        -0.04871156066656113,
        -0.045517534017562866,
        0.051609281450510025,
        -0.04686541482806206,
        -0.04035186395049095,
        -0.026516158133745193,
        0.05173313990235329,
        -0.03569875285029411,
        -0.03955884650349617,
        -0.0024532696697860956,
        -0.04004381224513054,
        -0.03497001528739929,
        0.05348751321434975,
        -0.05195671319961548,
        -0.053639329969882965,
        0.001105471863411367,
        0.05360690876841545,
        0.041473425924777985,
        -0.03402188792824745,
        0.05069691315293312,
        -0.05162230506539345,
        -0.050492215901613235,
        -0.05341628938913345,
        0.053070951253175735,
        0.007069837301969528,
        0.04885111376643181,
        0.0378815121948719,
        -0.025401398539543152,
        -0.027749288827180862,
        0.04886506497859955,
        0.05034142732620239,
        -0.03592121601104736,
        0.03387732803821564,
        0.044532712548971176,
        0.05316196382045746,
        -0.016207614913582802,
        0.05239904299378395,
        0.013442634604871273,
        0.05275195091962814,
        -0.005698269233107567,
        -0.05366496369242668,
        0.0534869022667408,
        -0.04950641095638275,
        0.053665824234485626,
        0.04957283288240433,
        -0.04470691829919815,
        0.05275793746113777,
        -0.053499896079301834,
        0.05315103381872177,
        -0.05366566777229309,
        0.05197283253073692,
        0.0014523654244840145,
        0.049982331693172455,
        -0.049595221877098083,
        0.05261417478322983,
        -0.053649768233299255,
        -0.05066493898630142,
        -0.04881788417696953,
        -0.05248703062534332,
        0.03589754179120064,
        -0.020684288814663887,
        -0.045737992972135544,
        -0.027492376044392586,
        -0.0057556480169296265,
        -0.04168041795492172,
        0.053612902760505676,
        -0.026764802634716034,
        -0.048242319375276566,
        -0.04897401109337807,
        0.0009957152651622891,
        -0.02362092025578022,
        0.053053118288517,
        -0.04854409769177437,
        0.049699023365974426,
        0.04849047213792801,
        -0.05169859901070595,
        -0.016934245824813843,
        0.03449006378650665,
        -0.019053205847740173,
        0.020756984129548073,
        0.04982556030154228,
        -0.05130869895219803,
        0.027131708338856697,
        -0.04236114025115967,
        -0.05338221788406372,
        -0.012145412154495716,
        -0.05176958814263344,
        -0.05349535122513771,
        -0.05214444175362587,
        -0.05347383767366409,
        -0.0008034973288886249,
        -0.05282975360751152,
        0.035970158874988556,
        0.02166900411248207,
        0.04967770725488663,
        0.05361125245690346,
        0.04162391647696495,
        -0.053298089653253555,
        -0.05356893315911293,
        -0.014192664064466953,
        0.05330340936779976,
        0.02154199592769146,
        0.05365366488695145,
        -0.05186028778553009,
        -0.041902780532836914,
        0.05351542308926582,
        -0.034413471817970276,
        0.053587377071380615,
        -0.0015257068444043398,
        0.053665708750486374,
        0.047595228999853134,
        0.04887203499674797,
        -0.027705727145075798,
        0.0225848276168108,
        0.015055891126394272,
        0.0527762807905674,
        0.03946433216333389,
        -0.014456355944275856,
        -0.04513390362262726,
        0.050271883606910706,
        -0.05332633852958679,
        0.05307683348655701,
        -0.05366309732198715,
        -0.017410758882761,
        -0.05359393730759621,
        0.05060318112373352,
        0.015437053516507149,
        0.033241983503103256,
        -0.0529809296131134,
        -0.017332013696432114,
        -0.03172710910439491,
        -0.05365997552871704,
        0.05170164629817009,
        -0.05346204712986946,
        -0.026528483256697655,
        -0.053659889847040176,
        0.04312955588102341,
        -0.04678404703736305,
        -0.020495500415563583,
        -0.05140036344528198,
        0.05285343900322914,
        0.00024170518736355007,
        0.04916396364569664,
        0.05364717170596123,
        0.04376734048128128,
        0.04773774370551109,
        -0.05364134907722473,
        -0.04053213447332382,
        -0.04244209825992584,
        0.04068979620933533,
        0.042022332549095154,
        0.0424446202814579,
        0.030530890449881554,
        0.053496766835451126,
        -0.050555597990751266,
        -0.05218746140599251,
        -0.03161057084798813,
        0.03853778541088104,
        0.05088912695646286,
        0.04791048914194107,
        -0.008359761908650398,
        -0.030117696151137352,
        0.046606846153736115,
        0.05319851636886597,
        0.05348688364028931,
        -0.053380418568849564,
        0.05348788946866989,
        0.033753883093595505,
        0.0025107739493250847,
        0.0004525782132986933,
        0.044519517570734024,
        0.04658723250031471,
        0.004378821700811386,
        0.05338336154818535,
        0.0514143668115139,
        0.05141719430685043,
        0.052276503294706345,
        -0.043622951954603195,
        -0.0471048541367054,
        -0.0536612905561924,
        -0.051859430968761444,
        -0.05323075130581856,
        -0.001857940573245287,
        -0.05259789898991585,
        0.05365573242306709,
        0.011909744702279568,
        0.03770991042256355,
        -0.05296606943011284,
        0.05346103757619858,
        0.038698598742485046,
        -0.05358963832259178,
        0.04622158035635948,
        -0.030237536877393723,
        0.052052903920412064,
        -0.0009577660239301622,
        -0.05352693423628807,
        0.03292090445756912,
        0.05321750044822693,
        0.011952077969908714,
        0.00362953613512218,
        -0.008786100894212723,
        -0.011394863948225975,
        -0.05350322276353836,
        -0.022072503343224525,
        -0.04345639795064926,
        -0.052186597138643265,
        -0.00966435857117176,
        0.051969919353723526,
        -0.04288207367062569,
        0.05266382917761803,
        -0.05167254060506821
    ],
    [
        -0.037251222878694534,
        -0.0514603927731514,
        0.051013216376304626,
        0.04231290891766548,
        0.048500124365091324,
        -0.049843404442071915,
        -0.04610340669751167,
        -0.05178426578640938,
        0.05177416652441025,
        -0.038389187306165695,
        -0.01919286884367466,
        -0.02355031669139862,
        -0.015466366894543171,
        -0.05171322822570801,
        -0.051748886704444885,
        -0.01340271346271038,
        -0.05173544958233833,
        0.051732808351516724,
        -0.05158781632781029,
        0.006197793409228325,
        0.049667246639728546,
        0.050090666860342026,
        -0.01807703636586666,
        0.05157172307372093,
        -0.04770144820213318,
        0.05177530273795128,
        -0.04847462475299835,
        0.05009542033076286,
        -0.037999410182237625,
        -0.04483402892947197,
        -0.0020240137819200754,
        0.049486950039863586,
        0.05007677152752876,
        -0.05177627131342888,
        0.051589611917734146,
        -0.05172448605298996,
        -0.0517328716814518,
        -0.017272595316171646,
        0.05178271234035492,
        -0.014433953911066055,
        0.04878327250480652,
        -0.02675052545964718,
        0.0409090518951416,
        -0.05174826458096504,
        0.051615189760923386,
        -0.01208527758717537,
        0.050165168941020966,
        0.05157036334276199,
        0.05176767706871033,
        -0.041673216968774796,
        0.05178432911634445,
        0.0503903292119503,
        0.05030868947505951,
        0.05154752358794212,
        0.018666893243789673,
        0.011683101765811443,
        -0.05038300156593323,
        -0.047118887305259705,
        -0.02098875306546688,
        -0.05047786980867386,
        -0.05072491616010666,
        0.03407632187008858,
        -0.0503883957862854,
        -0.045416925102472305,
        -0.012477599084377289,
        -0.051533281803131104,
        -0.05159212276339531,
        -0.04887077957391739,
        0.05169627442955971,
        0.03897659480571747,
        0.0095906350761652,
        -0.04694332182407379,
        0.048404399305582047,
        0.04968009516596794,
        -0.006402033381164074,
        -0.051367662847042084,
        0.049364399164915085,
        0.05178416892886162,
        0.04837082698941231,
        0.05178415775299072,
        -0.0379316508769989,
        -0.048772044479846954,
        -0.051693353801965714,
        0.05169513076543808,
        -0.04101616516709328,
        0.044201090931892395,
        -0.05072420835494995,
        0.05076243355870247,
        -0.0517839714884758,
        0.03943851217627525,
        -0.051639460027217865,
        -0.050905872136354446,
        0.05078251659870148,
        -0.05135776102542877,
        -0.04045313969254494,
        0.026732655242085457,
        -0.05178207904100418,
        -0.051783930510282516,
        -0.05011282116174698,
        0.04656441509723663,
        -0.05178432911634445,
        0.0024993382394313812,
        0.05112631618976593,
        0.051396775990724564,
        0.05071191489696503,
        -0.05177193135023117,
        -0.04519963636994362,
        0.030614901334047318,
        -0.050913602113723755,
        -0.05142240971326828,
        -0.04891596734523773,
        -0.05078950524330139,
        -0.030363868921995163,
        -0.04599740356206894,
        0.051507946103811264,
        -0.03604305163025856,
        0.051412977278232574,
        -0.05004461109638214,
        -0.04693415015935898,
        -0.05099756270647049,
        0.05178125947713852,
        -0.04033680260181427,
        0.049994152039289474,
        0.03588833659887314,
        -0.04898413270711899,
        0.04435728117823601,
        0.05171450972557068,
        0.051013924181461334,
        0.05153071507811546,
        -0.035922884941101074,
        -0.050565723329782486,
        -0.04912538081407547,
        -0.04627754166722298,
        0.024841642007231712,
        -0.04475981742143631,
        -0.0517612025141716,
        -0.033253058791160583,
        -0.05104760453104973,
        -0.05171598866581917,
        -0.032167091965675354,
        -0.05158570036292076,
        -0.018258240073919296,
        0.025719361379742622,
        0.04688289761543274,
        -0.01127074845135212,
        0.04933028295636177,
        -0.04934075102210045,
        -0.012705127708613873,
        0.04944729059934616,
        -0.05159884691238403,
        -0.03675563633441925,
        -0.05173337832093239,
        -0.051440995186567307,
        -0.05130676180124283,
        0.04707934707403183,
        0.01833256334066391,
        -0.01870504394173622,
        0.049806855618953705,
        0.05177999660372734,
        -0.03760909289121628,
        0.051775213330984116,
        -0.050055988132953644,
        0.05063895136117935,
        0.03252652287483215,
        -0.027963779866695404,
        -0.04673389345407486,
        -0.04958266764879227,
        -0.015320696868002415,
        0.04583803564310074,
        0.043819017708301544,
        -0.04312778636813164,
        0.049498457461595535,
        -0.05081488937139511,
        -0.037388935685157776,
        -0.05134718120098114,
        0.051783688366413116,
        0.05019885674118996,
        0.05152631923556328,
        0.002630850998684764,
        0.04923758655786514,
        -0.05165279656648636,
        0.05119292810559273,
        0.05177034065127373,
        -0.051683541387319565,
        0.0516645610332489,
        -0.008339829742908478,
        -0.051123570650815964,
        0.050057798624038696,
        0.03985273465514183,
        -0.051779817789793015,
        -0.051705509424209595,
        0.04116152599453926,
        0.05017201974987984,
        0.05087300390005112,
        -0.05177389830350876,
        0.05136295408010483,
        0.05177384987473488,
        0.051083534955978394,
        -0.043026119470596313,
        0.03301500901579857,
        0.050841398537158966,
        0.05080750584602356,
        -0.0491044856607914,
        -0.03160688653588295,
        0.05177156254649162,
        0.04723555967211723,
        0.05178419500589371,
        0.04926913604140282,
        -0.051775190979242325,
        0.05174900218844414,
        -0.05064648762345314,
        0.002958450699225068,
        0.04647282138466835,
        0.03263115510344505,
        -0.04890415817499161,
        -0.05157679691910744,
        0.05071520060300827,
        -0.05174720287322998,
        0.041787367314100266,
        0.012598972767591476,
        0.0018204973312094808,
        0.04025167599320412,
        0.032535821199417114,
        0.0516769140958786,
        0.051719605922698975,
        -0.05053462088108063,
        -0.03330329805612564,
        -0.046489037573337555,
        -0.025100117549300194,
        -0.051766082644462585,
        -0.021368427202105522,
        -0.05133157595992088,
        -0.051277901977300644,
        0.05134180188179016,
        -0.04973753169178963,
        0.051750339567661285,
        0.04401801526546478,
        -0.05152639001607895,
        -0.04668647423386574,
        0.05164631828665733,
        0.03408472612500191,
        -0.03779875487089157,
        -0.051229484379291534,
        0.04404749721288681,
        0.029988644644618034,
        0.050122346729040146,
        0.05167875066399574,
        0.051784317940473557,
        -0.05020075663924217,
        -0.0062113068997859955,
        -0.0515495203435421,
        -0.05176524817943573,
        -0.03733741119503975,
        -0.050028011202812195,
        -0.05022444203495979,
        0.02015683986246586,
        0.017655441537499428,
        0.03200690075755119,
        0.03217130899429321,
        0.04362526163458824,
        -0.045670367777347565,
        -0.03878822922706604,
        -0.01892317831516266,
        0.0515114925801754,
        0.048756495118141174,
        0.05175376310944557,
        0.04272715747356415,
        -0.01843738555908203,
        0.046847350895404816,
        0.030241340398788452,
        -0.05130862072110176,
        -0.05178394541144371,
        0.05171911418437958,
        -0.05177957937121391,
        -0.05175716057419777,
        -0.05174993351101875,
        0.04620721936225891,
        -0.051775913685560226,
        0.011730983853340149,
        0.04745089262723923,
        -0.05177883058786392,
        0.05155051499605179,
        -0.027806438505649567,
        -0.050949811935424805,
        0.050401464104652405,
        0.05109940096735954,
        -0.05152479186654091,
        0.0453784354031086,
        0.04294528067111969,
        0.05173274502158165,
        0.03917531296610832,
        -0.015733802691102028,
        0.013197913765907288,
        0.051688168197870255,
        0.016551055014133453,
        -0.0517798587679863,
        0.05178422853350639,
        -0.04419646039605141,
        -0.04177435487508774,
        0.05137835443019867,
        0.03680051118135452,
        0.05143864080309868,
        -0.031921401619911194,
        0.04936692863702774,
        0.0007287159678526223,
        0.04468660429120064,
        -0.04933413490653038,
        0.051224082708358765,
        -0.0495661236345768,
        -0.046813469380140305,
        0.04863198101520538,
        -0.04548060894012451,
        -0.05167267844080925,
        0.051726873964071274,
        0.026607511565089226,
        -0.05172698199748993,
        0.04810155928134918,
        -0.01708262600004673,
        0.04981428012251854,
        0.05071648210287094,
        0.05121258273720741,
        -0.03994687646627426,
        -0.048033248633146286,
        -0.04903519153594971,
        0.05178047716617584,
        -0.04123072326183319,
        -0.015348117798566818,
        0.047676268965005875,
        -0.01909724250435829,
        -0.05077862739562988,
        0.04918285459280014,
        -0.04245191812515259,
        -0.04201670363545418,
        0.03399057313799858,
        0.04736153408885002,
        0.029389550909399986,
        -0.050660647451877594,
        0.05178288370370865,
        -0.05130850151181221,
        0.05029897391796112,
        0.006142784375697374,
        -0.05178223177790642,
        -0.009396755136549473,
        0.013779627159237862,
        0.051777344197034836,
        0.049327969551086426,
        -0.04794890433549881,
        0.051226578652858734,
        -0.04327625036239624,
        0.050719551742076874,
        -0.0517653189599514,
        -0.01717928610742092,
        0.03508194535970688,
        0.028402337804436684,
        -0.050002582371234894,
        0.05178253725171089,
        -0.05174028500914574,
        -0.01878214068710804,
        -0.05045672878623009,
        0.025043435394763947,
        0.04756293073296547,
        -0.045452460646629333,
        0.019818905740976334,
        0.048309773206710815,
        0.03781534731388092,
        -0.04214586317539215,
        0.05176825448870659,
        -0.051598381251096725,
        0.04873746261000633,
        -0.05178288742899895,
        -0.051781851798295975,
        -0.045628949999809265,
        0.0517096184194088,
        -0.05119357630610466,
        -0.027621397748589516,
        0.05175749212503433,
        0.044314704835414886,
        0.02220200002193451,
        0.05178422853350639,
        0.048369452357292175,
        0.04285508394241333,
        0.015931040048599243,
        0.00015065347542986274,
        -0.03891988471150398,
        -0.04457423463463783,
        -0.022396879270672798,
        -0.05080556869506836,
        -0.0443902425467968,
        -0.05073932930827141,
        0.05124137923121452,
        -0.051158394664525986,
        -0.045880723744630814,
        0.05074625462293625,
        -0.05166065692901611,
        0.035420965403318405,
        0.051669590175151825,
        0.0515751913189888,
        -0.043019652366638184,
        -0.04902582988142967,
        -0.051783449947834015,
        -0.003832639893516898,
        0.049779318273067474,
        0.02160182222723961,
        0.05156504362821579,
        0.0029942572582513094,
        -0.021390587091445923,
        -0.008471534587442875,
        0.018613560125231743,
        0.05171448737382889,
        0.04162200167775154,
        0.011462830007076263,
        0.01904837228357792,
        0.04568132385611534,
        -0.05077715963125229,
        0.05158037319779396,
        -0.05156688764691353,
        0.010975913144648075,
        -0.04727351665496826,
        0.05143877491354942,
        0.04456765949726105,
        -0.0052296859212219715,
        -0.05173707380890846,
        0.04801463335752487,
        -0.05123034119606018,
        0.05167774483561516,
        -0.051733363419771194,
        -0.0007942389347590506,
        0.028667857870459557,
        -0.049942582845687866,
        -0.05100545659661293,
        0.051440272480249405,
        -0.036970749497413635,
        -0.050406988710165024,
        -0.0379069484770298,
        -0.05147699639201164,
        -0.05151132866740227,
        -0.05033324286341667,
        0.008903690613806248,
        -0.05111635476350784,
        -0.05167924240231514,
        -0.014800838194787502,
        0.05176754295825958,
        0.05125701054930687,
        0.04977220669388771,
        -0.03818538039922714,
        0.0508689358830452,
        0.051425494253635406,
        -0.05150538682937622,
        0.01536733191460371,
        -0.04460372030735016,
        0.045935992151498795,
        -0.03689344599843025,
        0.051165174692869186,
        0.04611246660351753,
        0.05165428668260574,
        -0.051726002246141434,
        -0.05177168920636177,
        0.05167089030146599,
        0.05127210542559624,
        0.051683977246284485,
        0.051498208194971085,
        -0.044959310442209244,
        -0.047257620841264725,
        0.008894028142094612,
        0.04995627701282501,
        0.051777180284261703,
        -0.05178380757570267,
        0.01348703633993864,
        0.03945203125476837,
        -0.051038336008787155,
        -0.03866861015558243,
        -0.049211494624614716,
        0.05129384621977806,
        -0.00522949593141675,
        0.05013812705874443,
        0.05130597949028015,
        0.05177154019474983,
        0.049767330288887024,
        0.035478319972753525,
        -0.050449687987565994,
        -0.05113212764263153,
        -0.05064385384321213,
        -0.017005233094096184,
        -0.005719499196857214,
        -0.05119793489575386,
        -0.05168665945529938,
        0.04832165688276291,
        -0.004457968287169933,
        -0.05174851417541504,
        0.03614308685064316,
        -0.028819553554058075,
        -0.017063887789845467,
        0.05098208412528038,
        0.021740693598985672,
        0.051768504083156586,
        -0.03858502209186554,
        -0.043502744287252426,
        -0.041382018476724625,
        0.008458159863948822,
        -0.008795173838734627,
        -0.045011524111032486,
        0.04973679780960083,
        -0.0516226552426815,
        -0.03275691345334053,
        0.05164242163300514,
        0.05064775422215462,
        0.021089203655719757,
        -0.04197642207145691,
        0.008953030221164227,
        -0.04657416045665741,
        0.05166595056653023,
        0.028186926618218422
    ],
    [
        -0.04309675097465515,
        -0.059226904064416885,
        0.05898513272404671,
        0.0064068324863910675,
        0.04665512219071388,
        -0.024931639432907104,
        0.060460563749074936,
        -0.046428028494119644,
        -0.033040549606084824,
        0.0375538133084774,
        0.05609144642949104,
        -0.05446631461381912,
        -0.040533553808927536,
        -0.023925799876451492,
        -0.059600118547677994,
        -0.0063281855545938015,
        -0.05414072051644325,
        0.05300259217619896,
        -0.05857252702116966,
        0.05604010075330734,
        -0.01094077993184328,
        -0.006207279860973358,
        0.05713016167283058,
        -0.05607645958662033,
        0.028307460248470306,
        -0.052337273955345154,
        -0.04453057423233986,
        0.018637677654623985,
        0.056738756597042084,
        -0.049337852746248245,
        0.05209964141249657,
        0.022509343922138214,
        0.0535757951438427,
        -0.06034461781382561,
        0.031769827008247375,
        -0.0327538326382637,
        -0.059668757021427155,
        -0.018381981179118156,
        -0.05020381510257721,
        -0.021392541006207466,
        0.020024826750159264,
        -0.05067376792430878,
        0.051613952964544296,
        0.027101265266537666,
        0.04363328963518143,
        0.03901495411992073,
        -0.012604407966136932,
        0.05400003120303154,
        0.06083201244473457,
        0.03968453034758568,
        0.038576677441596985,
        0.038537558168172836,
        0.05745160952210426,
        -0.05776480585336685,
        0.03358380123972893,
        -0.03773006796836853,
        -0.046709418296813965,
        -0.0242840014398098,
        -0.0549880675971508,
        0.004362731706351042,
        -0.059914641082286835,
        -0.0476122684776783,
        -0.051483869552612305,
        -0.015151547268033028,
        0.021769829094409943,
        -0.013237180188298225,
        0.017980189993977547,
        0.04428006336092949,
        0.029557522386312485,
        -0.061114441603422165,
        0.01442861557006836,
        -0.06099532172083855,
        0.05414613336324692,
        0.055766794830560684,
        -0.04819248616695404,
        0.009926123544573784,
        0.042327191680669785,
        0.06035752221941948,
        0.026395536959171295,
        -0.029170403257012367,
        0.0014848844148218632,
        -0.01589909754693508,
        -0.060501810163259506,
        0.043734997510910034,
        0.04717938229441643,
        -0.01730268821120262,
        -0.054641347378492355,
        0.05346587672829628,
        -0.018931923434138298,
        0.051025133579969406,
        -0.06079447641968727,
        0.03633315861225128,
        0.028991999104619026,
        -0.04842584952712059,
        -0.01714026741683483,
        -0.042723000049591064,
        -0.015195125713944435,
        -0.06089609116315842,
        -0.0603036992251873,
        -0.04398485645651817,
        -0.04508557915687561,
        -0.010329753160476685,
        0.04707521200180054,
        0.0018145766807720065,
        -0.04252973943948746,
        -0.01794375479221344,
        0.05401235446333885,
        0.020137343555688858,
        -0.051640916615724564,
        0.05117906257510185,
        -0.03493702784180641,
        -0.050569791346788406,
        -0.001007148064672947,
        -0.011626931838691235,
        0.06036246567964554,
        -0.023892110213637352,
        0.009037221781909466,
        -0.03660780191421509,
        -0.05895620584487915,
        0.04923754185438156,
        -0.0024638460017740726,
        0.014569755643606186,
        0.05273110046982765,
        0.03354453667998314,
        -0.060407452285289764,
        -0.009378134272992611,
        0.01209099031984806,
        0.055525898933410645,
        0.0572761595249176,
        -0.054403189569711685,
        0.030682247132062912,
        -0.032999977469444275,
        0.06016955524682999,
        0.05352140590548515,
        -0.05182250961661339,
        -0.03756781667470932,
        0.05703962594270706,
        -0.03089616261422634,
        -0.029986171051859856,
        0.060632623732089996,
        0.016536982730031013,
        -0.05584392696619034,
        0.049825914204120636,
        -0.007016260176897049,
        -0.05855405703186989,
        0.043814755976200104,
        0.05896993726491928,
        0.051353033632040024,
        -0.0550682470202446,
        -0.03359134867787361,
        0.02757810242474079,
        -0.05736169219017029,
        -0.06033148989081383,
        -0.02989703044295311,
        0.060837820172309875,
        -0.06050754711031914,
        0.038645483553409576,
        -0.008094937540590763,
        -0.02506251446902752,
        -0.02888832986354828,
        0.05351131409406662,
        -0.02693115547299385,
        0.05278843641281128,
        -0.05627014860510826,
        -0.02862931601703167,
        -0.017065558582544327,
        -0.05190075933933258,
        -0.029338212683796883,
        0.02224845439195633,
        0.025176241993904114,
        -0.025612948462367058,
        0.05085509270429611,
        -0.05763896182179451,
        -0.005650150589644909,
        -0.026884911581873894,
        0.05646374076604843,
        -0.058795265853405,
        0.0071128117851912975,
        0.03286139294505119,
        0.04863583296537399,
        0.051148075610399246,
        0.05892255902290344,
        0.05386914312839508,
        0.042891714721918106,
        0.04893459752202034,
        0.03607459366321564,
        -0.019477317109704018,
        0.05493585392832756,
        0.008398642763495445,
        -0.054654862731695175,
        -0.03263511881232262,
        -0.020901620388031006,
        -0.043918345123529434,
        0.04065766930580139,
        -0.026735572144389153,
        0.026568396016955376,
        0.059870436787605286,
        0.041047461330890656,
        -0.03048296459019184,
        0.0540008544921875,
        0.05221157148480415,
        0.0595848485827446,
        0.05226318910717964,
        0.041645798832178116,
        0.03543323278427124,
        0.04882558807730675,
        0.05849449709057808,
        0.02583460882306099,
        -0.009761258028447628,
        0.05445902794599533,
        -0.032993096858263016,
        -0.004278475418686867,
        -0.052593499422073364,
        -0.06045281141996384,
        0.032811496406793594,
        -0.050435200333595276,
        0.04680605232715607,
        -0.05121451988816261,
        0.05617824196815491,
        0.05524076521396637,
        0.058150842785835266,
        -0.046203166246414185,
        -0.011657084338366985,
        -0.05947230011224747,
        0.06094931811094284,
        -0.06084487587213516,
        0.005317289382219315,
        0.030588321387767792,
        -0.022495975717902184,
        -0.05120895430445671,
        0.01917136460542679,
        -0.04782038927078247,
        -0.03151553124189377,
        0.04496641829609871,
        -0.031341806054115295,
        0.053424250334501266,
        0.055643271654844284,
        0.047811832278966904,
        -0.04260602593421936,
        0.05282767862081528,
        0.02020377479493618,
        0.05725714936852455,
        -0.05626118928194046,
        0.025619348511099815,
        0.021319730207324028,
        -0.02965649589896202,
        0.03288750350475311,
        0.058588381856679916,
        -0.013346629217267036,
        0.03481753543019295,
        -0.0407540388405323,
        -0.057949382811784744,
        -0.060788411647081375,
        -0.058915186673402786,
        0.03398982435464859,
        0.05840960517525673,
        0.04477161169052124,
        -0.053167857229709625,
        0.05881316959857941,
        0.061104267835617065,
        -0.05289025977253914,
        0.054940447211265564,
        0.03929446265101433,
        0.05796266347169876,
        0.04113337770104408,
        0.046212922781705856,
        0.029392695054411888,
        -0.05407140776515007,
        -0.002811581129208207,
        -0.041462499648332596,
        0.004858601838350296,
        -0.059997960925102234,
        0.05602877959609032,
        -0.06056038290262222,
        -0.05753152072429657,
        0.044129759073257446,
        -0.05411920323967934,
        0.010319738648831844,
        -0.02925788052380085,
        -0.04181857779622078,
        -0.05649550259113312,
        -0.05520346015691757,
        -0.0000977721210801974,
        -0.05604465678334236,
        -0.0555623322725296,
        -0.05298708751797676,
        0.004199617076665163,
        -0.04416288062930107,
        0.023883385583758354,
        -0.029724452644586563,
        -0.02733461745083332,
        0.038721028715372086,
        -0.04116382449865341,
        0.017682110890746117,
        0.055880408734083176,
        -0.05215974524617195,
        0.0606800802052021,
        -0.049663741141557693,
        -0.034281592816114426,
        0.052164871245622635,
        -0.04882083460688591,
        0.04055917263031006,
        -0.007279573008418083,
        -0.02047908306121826,
        -0.06036081165075302,
        -0.040841300040483475,
        -0.002919327700510621,
        0.013896660879254341,
        0.038215361535549164,
        -0.06067999079823494,
        0.05522708594799042,
        -0.05856644734740257,
        -0.03450566902756691,
        0.05481206253170967,
        -0.06057916581630707,
        -0.05578320473432541,
        0.05915001779794693,
        0.028046000748872757,
        0.022352486848831177,
        -0.058828212320804596,
        -0.05376771464943886,
        -0.013926606625318527,
        0.06073755398392677,
        -0.020552387461066246,
        0.045568764209747314,
        0.03753260523080826,
        0.026149213314056396,
        -0.008555825799703598,
        -0.026091929525136948,
        -0.02808430790901184,
        -0.05831965059041977,
        0.027681659907102585,
        0.03953558951616287,
        -0.059446413069963455,
        0.057778868824243546,
        -0.0014243691693991423,
        -0.048729490488767624,
        0.054428692907094955,
        -0.05483435094356537,
        0.004212041851133108,
        -0.0017167867626994848,
        0.041516173630952835,
        0.04832150787115097,
        0.05133489519357681,
        0.061113305389881134,
        0.05397997796535492,
        0.0006170450360514224,
        0.02301987260580063,
        -0.054175782948732376,
        0.05362182483077049,
        -0.06106582283973694,
        -0.029764460399746895,
        -0.054198410362005234,
        -0.05679772421717644,
        0.042467858642339706,
        0.05588224530220032,
        -0.017823943868279457,
        -0.013972390443086624,
        -0.024338822811841965,
        -0.051729779690504074,
        0.056337811052799225,
        0.03702520579099655,
        -0.05711571127176285,
        -0.032220155000686646,
        0.02281990647315979,
        -0.03812459856271744,
        0.06092701479792595,
        -0.03498731553554535,
        0.03713684156537056,
        -0.06030448526144028,
        -0.04242195934057236,
        -0.05428525432944298,
        0.04808534309267998,
        -0.057326920330524445,
        0.044608455151319504,
        0.054778099060058594,
        -0.03735741600394249,
        0.03780636563897133,
        -0.05849022418260574,
        -0.05936267971992493,
        -0.040943603962659836,
        -0.0096200592815876,
        -0.05960056185722351,
        0.04438598453998566,
        0.05287977680563927,
        -0.04260579124093056,
        -0.0180695541203022,
        -0.05272296071052551,
        -0.056597139686346054,
        -0.060648951679468155,
        -0.04999730736017227,
        -0.020310649648308754,
        -0.009927768260240555,
        0.007979720830917358,
        -0.010388372465968132,
        0.0580049566924572,
        0.05284830555319786,
        0.043195951730012894,
        0.04511185735464096,
        -0.06053929775953293,
        -0.03514306619763374,
        0.05260877311229706,
        0.056016888469457626,
        0.060639407485723495,
        -0.06045959144830704,
        -0.033690571784973145,
        0.05354132875800133,
        0.05225445330142975,
        0.06093364953994751,
        -0.05441964790225029,
        0.0354142040014267,
        0.03483869135379791,
        0.05446239560842514,
        0.02976248413324356,
        0.05924844369292259,
        0.004601078573614359,
        -0.015264036133885384,
        0.009425457566976547,
        0.05890483036637306,
        -0.05237825587391853,
        -0.0078563392162323,
        -0.05963097885251045,
        0.025390813127160072,
        -0.05889006704092026,
        0.033479489386081696,
        -0.026203259825706482,
        -0.05007527023553848,
        -0.0066594695672392845,
        -0.04775417596101761,
        -0.023349754512310028,
        0.03565743938088417,
        0.02540890872478485,
        -0.0434134304523468,
        -0.05446043238043785,
        -0.053314853459596634,
        -0.04919125512242317,
        -0.05920538306236267,
        0.052310533821582794,
        -0.05891980975866318,
        -0.045061927288770676,
        -0.02313707396388054,
        0.05465138331055641,
        0.059541549533605576,
        0.05983685329556465,
        -0.04360445588827133,
        0.058650948107242584,
        0.05935649201273918,
        0.0014453761978074908,
        0.05224018916487694,
        -0.0333058163523674,
        0.05934668704867363,
        0.05440933629870415,
        0.05900184437632561,
        -0.03739236295223236,
        -0.040581248700618744,
        -0.05414644628763199,
        0.039032042026519775,
        0.05628688260912895,
        0.00040524592623114586,
        0.06091213971376419,
        -0.010920259170234203,
        -0.03430308401584625,
        -0.05767833814024925,
        0.008552756160497665,
        0.06000139191746712,
        0.06085582450032234,
        -0.05937222018837929,
        0.033815160393714905,
        -0.005106333177536726,
        -0.06067275255918503,
        0.05248086899518967,
        0.045850906521081924,
        0.041983067989349365,
        -0.0021653638686984777,
        0.0516870841383934,
        0.05371226370334625,
        0.025415943935513496,
        0.057191092520952225,
        0.01648607850074768,
        -0.029896138235926628,
        -0.02675767056643963,
        0.020363273099064827,
        -0.0005207994836382568,
        0.016962142661213875,
        -0.022391412407159805,
        0.048562437295913696,
        0.02277885004878044,
        0.006825825199484825,
        -0.057926248759031296,
        0.04844027757644653,
        -0.05858543887734413,
        -0.017121069133281708,
        0.02634911984205246,
        0.01454961858689785,
        0.06042900308966637,
        -0.04765230044722557,
        -0.054918915033340454,
        0.05864100158214569,
        0.058400195091962814,
        0.04834124445915222,
        0.05664042755961418,
        -0.052453748881816864,
        -0.0031735305674374104,
        0.016868455335497856,
        -0.02707514353096485,
        0.05005444958806038,
        0.039932459592819214,
        -0.03800894692540169,
        -0.05936194211244583,
        -0.04908798262476921,
        0.05971582233905792,
        -0.03453296795487404
    ],
    [
        0.019580403342843056,
        0.044314973056316376,
        0.053929369896650314,
        0.012156577780842781,
        -0.009326230734586716,
        -0.035691991448402405,
        0.038274042308330536,
        -0.04580317437648773,
        0.032580237835645676,
        -0.04871762916445732,
        0.052260447293519974,
        0.05243657901883125,
        -0.05427367985248566,
        0.03606276586651802,
        -0.05366396903991699,
        0.04096175730228424,
        -0.04411597177386284,
        0.03804197162389755,
        -0.05051345005631447,
        0.04878837242722511,
        0.053031619638204575,
        0.05172208696603775,
        0.051288846880197525,
        -0.03094053454697132,
        -0.05375076085329056,
        0.012676342390477657,
        0.02705952525138855,
        0.05405993387103081,
        -0.04795801267027855,
        -0.04557434096932411,
        0.04717293754220009,
        0.05369723588228226,
        0.05303264781832695,
        -0.054308246821165085,
        0.05394851788878441,
        -0.05430417135357857,
        -0.006488541606813669,
        -0.04506959766149521,
        0.05404867231845856,
        -0.04055703803896904,
        -0.028740912675857544,
        0.04681988060474396,
        0.04108839109539986,
        -0.05344595015048981,
        0.015293043106794357,
        -0.04270666465163231,
        0.05386143550276756,
        0.05415331572294235,
        0.054363999515771866,
        -0.05092189460992813,
        0.03161551058292389,
        0.0505649596452713,
        0.0488157793879509,
        -0.000576492864638567,
        0.051758669316768646,
        -0.02555563487112522,
        -0.032143980264663696,
        -0.04743553698062897,
        -0.053464438766241074,
        -0.05153230205178261,
        -0.04780828580260277,
        0.041801076382398605,
        -0.053579725325107574,
        -0.042879845947027206,
        0.05316353589296341,
        -0.001979238586500287,
        -0.054240576922893524,
        -0.0439811572432518,
        0.05204782634973526,
        0.040756575763225555,
        0.034780025482177734,
        -0.05242561921477318,
        0.054376181215047836,
        0.04789314419031143,
        0.05412529408931732,
        0.05342072993516922,
        0.05410583317279816,
        0.0543762743473053,
        0.040171243250370026,
        -0.029927700757980347,
        0.042302802205085754,
        -0.053885336965322495,
        -0.05151514336466789,
        0.052048806101083755,
        0.045129772275686264,
        0.05238957703113556,
        -0.04383396357297897,
        -0.052651990205049515,
        -0.04908129200339317,
        0.05289357900619507,
        -0.05362024903297424,
        0.03807692229747772,
        -0.013125928118824959,
        -0.0541294626891613,
        -0.04837018996477127,
        0.03900187462568283,
        -0.025152888149023056,
        -0.028436334803700447,
        0.03749462962150574,
        0.041783832013607025,
        -0.05426999181509018,
        0.04034120962023735,
        0.053825538605451584,
        0.05436015874147415,
        0.05428697168827057,
        -0.01814563199877739,
        -0.04969773441553116,
        0.005840993486344814,
        -0.04714402183890343,
        0.02828550897538662,
        -0.041343044489622116,
        -0.054204974323511124,
        0.0333702526986599,
        0.016221890226006508,
        0.04622035101056099,
        -0.04347015917301178,
        -0.046003397554159164,
        -0.017756855115294456,
        0.05000396445393562,
        -0.05339241027832031,
        -0.03687809035181999,
        0.01090177334845066,
        -0.0342014878988266,
        -0.05185212567448616,
        -0.052654415369033813,
        0.05291934311389923,
        -0.05356470122933388,
        -0.04336468502879143,
        0.022710135206580162,
        0.04215279966592789,
        0.03938538208603859,
        -0.052723731845617294,
        -0.05392587184906006,
        0.05425760895013809,
        0.05368635803461075,
        -0.05433724820613861,
        0.04822801053524017,
        -0.044713567942380905,
        -0.05055492743849754,
        0.05318496748805046,
        -0.026268653571605682,
        -0.04907407611608505,
        0.03205389529466629,
        0.04938608407974243,
        -0.05426519736647606,
        0.04871366545557976,
        0.026272788643836975,
        -0.04914961755275726,
        -0.04787875711917877,
        0.05288075655698776,
        0.010408690199255943,
        -0.054358549416065216,
        -0.05102919787168503,
        -0.03223786503076553,
        0.05044245347380638,
        0.03274243324995041,
        -0.049150340259075165,
        -0.006468620616942644,
        0.05434735491871834,
        -0.03671881556510925,
        0.048936035484075546,
        0.05254868417978287,
        0.054302725940942764,
        0.04918616637587547,
        0.0480421744287014,
        0.050561897456645966,
        -0.002095496514812112,
        -0.03185577318072319,
        -0.03699391335248947,
        0.05246002972126007,
        -0.047778088599443436,
        0.028455642983317375,
        -0.05424859747290611,
        0.05290753021836281,
        -0.05195262283086777,
        -0.034585725516080856,
        0.05242559313774109,
        0.03090331144630909,
        0.05428173020482063,
        -0.052059661597013474,
        -0.05407926067709923,
        0.05183881148695946,
        0.050032734870910645,
        0.011983000673353672,
        -0.033986303955316544,
        0.0012335206847637892,
        0.04320570454001427,
        -0.01424680557101965,
        0.045268453657627106,
        -0.044894397258758545,
        -0.05434239283204079,
        0.047548558562994,
        -0.011634556576609612,
        0.05040375143289566,
        -0.05436757206916809,
        0.047957535833120346,
        0.05257140100002289,
        -0.02316688746213913,
        -0.004222181160002947,
        0.05322409048676491,
        0.05364157631993294,
        0.053292881697416306,
        0.0383334718644619,
        -0.05420885235071182,
        0.03500472754240036,
        0.029184477403759956,
        0.04922143369913101,
        0.05063578858971596,
        -0.05378231406211853,
        0.054063983261585236,
        -0.053699735552072525,
        -0.005980097688734531,
        0.054166361689567566,
        0.05434174835681915,
        -0.0543668232858181,
        0.013197116553783417,
        0.046482451260089874,
        0.04556043818593025,
        0.0542496033012867,
        -0.027334969490766525,
        0.04629434645175934,
        0.05403396859765053,
        -0.026213660836219788,
        -0.05335450544953346,
        0.054285209625959396,
        -0.053654126822948456,
        0.022044502198696136,
        -0.047766897827386856,
        0.05366867408156395,
        -0.053479764610528946,
        0.017932608723640442,
        -0.03165999427437782,
        0.002881648251786828,
        0.009788287803530693,
        -0.02706167660653591,
        0.054347459226846695,
        0.054178714752197266,
        0.00452412199229002,
        -0.039256297051906586,
        0.054375793784856796,
        0.02140473574399948,
        -0.044735461473464966,
        -0.04938262701034546,
        -0.007287140004336834,
        0.03699656203389168,
        -0.04857445880770683,
        0.05033697187900543,
        0.037636417895555496,
        -0.045763786882162094,
        0.03624573349952698,
        -0.05350745841860771,
        -0.0525391511619091,
        -0.017003679648041725,
        -0.050267528742551804,
        -0.048273246735334396,
        0.05417758598923683,
        -0.042441464960575104,
        0.027389436960220337,
        0.03781863674521446,
        -0.01823590323328972,
        0.005829061381518841,
        -0.028465282171964645,
        0.011864084750413895,
        0.0008604424074292183,
        -0.05297039821743965,
        0.05435299873352051,
        0.054012175649404526,
        -0.029951278120279312,
        -0.03230719268321991,
        0.011507130227982998,
        0.054192595183849335,
        -0.035924457013607025,
        0.0484396331012249,
        -0.05135510489344597,
        -0.0025367289781570435,
        0.04314428195357323,
        -0.04922664538025856,
        0.037003688514232635,
        0.04651700332760811,
        0.014875606633722782,
        -0.053878989070653915,
        0.053700175136327744,
        -0.04393215849995613,
        -0.032678235322237015,
        0.025554370135068893,
        0.05343156307935715,
        0.035687342286109924,
        0.05360962823033333,
        0.05228520929813385,
        0.05317365750670433,
        -0.044243134558200836,
        0.04273363575339317,
        -0.025548113510012627,
        -0.025730961933732033,
        0.05321004241704941,
        -0.053914956748485565,
        0.05435642600059509,
        0.04871879518032074,
        -0.017737966030836105,
        0.05418616905808449,
        0.05364818125963211,
        0.0430220328271389,
        -0.010071824304759502,
        0.05361124500632286,
        0.0453266017138958,
        0.048015061765909195,
        -0.03990225866436958,
        0.0493190735578537,
        0.050076499581336975,
        -0.04332022741436958,
        0.05406176671385765,
        -0.05224635452032089,
        0.05388110876083374,
        0.05412836745381355,
        -0.004574873950332403,
        -0.05314769968390465,
        -0.02149728685617447,
        -0.04523368552327156,
        0.05417286977171898,
        0.032534778118133545,
        0.0538981631398201,
        -0.04781021177768707,
        0.05387183278799057,
        -0.037958625704050064,
        0.04791468754410744,
        0.03675433248281479,
        0.045893844217061996,
        -0.022251218557357788,
        -0.051911842077970505,
        -0.054261282086372375,
        0.04986835643649101,
        -0.05330450087785721,
        -0.048070624470710754,
        -0.03780221939086914,
        0.05195971205830574,
        0.05199690908193588,
        -0.050841301679611206,
        0.0014821673976257443,
        -0.04418521001935005,
        -0.01878160797059536,
        -0.054008159786462784,
        0.028825122863054276,
        -0.022332658991217613,
        -0.05122600123286247,
        0.0536743588745594,
        -0.02210167981684208,
        0.05224957689642906,
        0.05353439599275589,
        0.015184687450528145,
        0.0539105087518692,
        0.05389457195997238,
        0.05225497856736183,
        -0.033252280205488205,
        -0.05414847284555435,
        0.028415897861123085,
        0.022663330659270287,
        -0.026656102389097214,
        0.043454430997371674,
        -0.024429554119706154,
        0.025488903746008873,
        0.0501609705388546,
        0.013928442262113094,
        -0.04174594208598137,
        -0.04431018605828285,
        -0.04184146970510483,
        -0.05401196703314781,
        0.05436772108078003,
        -0.05430254340171814,
        -0.052664615213871,
        -0.054197221994400024,
        -0.054310694336891174,
        -0.045061446726322174,
        0.0484267994761467,
        -0.0398397296667099,
        0.05101172626018524,
        0.05427917465567589,
        -0.04315710440278053,
        -0.046216197311878204,
        0.05422167107462883,
        0.030562562867999077,
        -0.03525732830166817,
        0.048985667526721954,
        -0.053230322897434235,
        0.03124239295721054,
        0.037105392664670944,
        -0.05279203876852989,
        0.0423738919198513,
        -0.010890067555010319,
        0.014346410520374775,
        -0.02355644479393959,
        -0.04427711293101311,
        -0.0528176911175251,
        -0.039208196103572845,
        -0.0483374297618866,
        0.051646169275045395,
        0.05407599359750748,
        0.05428525432944298,
        -0.05367623642086983,
        -0.04933777078986168,
        -0.04056615009903908,
        0.04385406896471977,
        0.054325833916664124,
        -0.04573278874158859,
        0.052690405398607254,
        -0.05068323388695717,
        -0.03633292391896248,
        0.05275697261095047,
        -0.0519757904112339,
        0.054101936519145966,
        0.03620199114084244,
        -0.04591469466686249,
        -0.05434880405664444,
        0.0025895657017827034,
        0.05045834183692932,
        0.05363895744085312,
        -0.054263804107904434,
        -0.042657747864723206,
        -0.047371309250593185,
        0.04214536398649216,
        0.054033033549785614,
        0.05416395142674446,
        -0.033553387969732285,
        -0.039331644773483276,
        -0.05425058677792549,
        -0.013715378940105438,
        0.001651294413022697,
        0.035180456936359406,
        -0.05234822258353233,
        -0.05351993814110756,
        -0.03596707805991173,
        0.05224129185080528,
        0.043071139603853226,
        0.04787742719054222,
        0.01922338642179966,
        -0.05436505004763603,
        -0.04668416082859039,
        -0.054333701729774475,
        0.022113226354122162,
        -0.021608496084809303,
        -0.05358166620135307,
        -0.053705886006355286,
        0.053924329578876495,
        0.05381040275096893,
        0.05427398905158043,
        -0.053038664162158966,
        0.04732414335012436,
        0.03796070069074631,
        -0.05354192107915878,
        0.0410001315176487,
        0.0514431968331337,
        0.002233015839010477,
        -0.0043859416618943214,
        0.053865570574998856,
        0.05372775346040726,
        0.03318600729107857,
        -0.04860108718276024,
        -0.023022456094622612,
        -0.05245107412338257,
        0.024411486461758614,
        0.04886580631136894,
        0.04515928775072098,
        -0.05337151139974594,
        -0.0479179285466671,
        -0.04061711207032204,
        0.04919404909014702,
        0.054195765405893326,
        -0.05416462942957878,
        0.05404052510857582,
        -0.037815481424331665,
        -0.054268237203359604,
        0.01939031481742859,
        -0.04952370002865791,
        0.0502319261431694,
        0.052036575973033905,
        0.03958853706717491,
        0.040114328265190125,
        -0.02052677981555462,
        0.05389806255698204,
        -0.052051037549972534,
        0.02482185885310173,
        -0.0011955401860177517,
        -0.019340477883815765,
        0.009458975866436958,
        0.013827433809638023,
        0.03797009587287903,
        0.05216062441468239,
        0.053364306688308716,
        0.012708939611911774,
        -0.053263042122125626,
        -0.021754028275609016,
        -0.024664781987667084,
        -0.046042975038290024,
        0.043070532381534576,
        0.030573930591344833,
        0.010852858424186707,
        -0.05314631760120392,
        -0.04372090473771095,
        0.02618931420147419,
        0.04959861934185028,
        0.028905929997563362,
        -0.051248740404844284,
        -0.044408004730939865,
        -0.05260014906525612,
        0.03367982804775238,
        0.053899459540843964,
        0.05331193283200264,
        0.054372914135456085,
        0.0035350886173546314,
        0.054211996495723724,
        -0.05425111576914787,
        0.05309503152966499,
        0.049938052892684937
    ],
    [
        -0.023735500872135162,
        -0.04304785281419754,
        0.05445731058716774,
        0.035388387739658356,
        0.03518330678343773,
        -0.057601239532232285,
        0.05814807862043381,
        -0.05349773168563843,
        -0.010483902879059315,
        0.05784228444099426,
        0.035025984048843384,
        0.04507766291499138,
        -0.05407291278243065,
        0.02074672281742096,
        -0.0574328787624836,
        0.00014560302952304482,
        -0.05471323803067207,
        0.05057460069656372,
        -0.05726735666394234,
        0.05381019786000252,
        -0.03855840861797333,
        -0.05624808743596077,
        0.04417846351861954,
        -0.0488671250641346,
        0.050985317677259445,
        0.042869701981544495,
        0.037459373474121094,
        -0.02786756493151188,
        -0.04621512070298195,
        -0.053245481103658676,
        0.04470932111144066,
        0.023180052638053894,
        0.00522731663659215,
        -0.05609016492962837,
        0.015060446225106716,
        0.012254597619175911,
        -0.05510939285159111,
        -0.05703854560852051,
        -0.04489070549607277,
        0.05525499954819679,
        0.04622240364551544,
        0.04869701340794563,
        0.05625652149319649,
        -0.0576741062104702,
        0.036394864320755005,
        0.05585150048136711,
        0.016505364328622818,
        0.057764846831560135,
        0.05824464559555054,
        0.04449480399489403,
        0.04001728072762489,
        0.02133064903318882,
        0.04659007489681244,
        0.007195991929620504,
        -0.054844196885824203,
        0.021034784615039825,
        -0.0021443674340844154,
        -0.04223664849996567,
        0.020106444135308266,
        -0.047718584537506104,
        -0.05758798122406006,
        0.010878869332373142,
        -0.04864206537604332,
        0.055895477533340454,
        0.02085837349295616,
        -0.045191679149866104,
        0.03948168829083443,
        -0.04846229404211044,
        0.05777418985962868,
        -0.05836178734898567,
        -0.057568568736314774,
        -0.058053839951753616,
        0.05734744295477867,
        0.04713631048798561,
        -0.04390719532966614,
        -0.03917955607175827,
        0.03005732223391533,
        0.05810965225100517,
        -0.045149266719818115,
        -0.02590872161090374,
        -0.03512939438223839,
        0.05369018763303757,
        -0.04310685023665428,
        0.05225583165884018,
        -0.013152047991752625,
        0.04477686434984207,
        0.015543757006525993,
        0.02037939429283142,
        -0.03464497625827789,
        0.0582924447953701,
        -0.04017293453216553,
        0.05465207248926163,
        -0.025327378883957863,
        -0.05435045063495636,
        -0.02887902222573757,
        -0.013971764594316483,
        -0.044451531022787094,
        -0.03646189719438553,
        -0.05471212789416313,
        -0.03246631100773811,
        -0.05808108299970627,
        0.04143230617046356,
        0.057806406170129776,
        0.0026003681123256683,
        0.04798915237188339,
        -0.027622632682323456,
        -0.05738280341029167,
        -0.009292246773838997,
        -0.04819420725107193,
        -0.03468209132552147,
        -0.01899554766714573,
        -0.0485050231218338,
        -0.009403489530086517,
        -0.04395204409956932,
        0.05163794010877609,
        -0.05541861802339554,
        -0.021013697609305382,
        -0.04916343092918396,
        -0.003337505040690303,
        0.00915999710559845,
        -0.05059835687279701,
        0.01546861045062542,
        0.03500588983297348,
        0.02706458419561386,
        -0.05820130184292793,
        -0.042874421924352646,
        -0.014097800478339195,
        0.036726199090480804,
        0.0545012503862381,
        -0.028738969936966896,
        0.04971420392394066,
        -0.05079556629061699,
        -0.05587288737297058,
        0.04869389533996582,
        -0.042710382491350174,
        -0.0476791076362133,
        0.056852325797080994,
        -0.05563170462846756,
        -0.05710865557193756,
        0.0569574348628521,
        0.040051862597465515,
        -0.05063736066222191,
        0.05465032160282135,
        0.05818062275648117,
        0.02133404277265072,
        0.05800595134496689,
        0.057994294911623,
        0.017036734148859978,
        -0.049470771104097366,
        0.05503843352198601,
        -0.05132827162742615,
        -0.05821672081947327,
        -0.05749468877911568,
        0.05330175533890724,
        0.0011428880970925093,
        -0.05755259469151497,
        0.051463961601257324,
        -0.05322122201323509,
        0.02131609059870243,
        -0.05652815103530884,
        0.056870028376579285,
        -0.022001290693879128,
        0.049807026982307434,
        0.04787558689713478,
        -0.049591198563575745,
        -0.03776685893535614,
        0.02885076403617859,
        -0.05003853887319565,
        -0.044618427753448486,
        0.05333587899804115,
        -0.01232142187654972,
        0.05165242403745651,
        -0.03951873257756233,
        -0.013672766275703907,
        -0.052275389432907104,
        0.05268301069736481,
        -0.05835495889186859,
        -0.0005396149936132133,
        0.05678778886795044,
        -0.039548348635435104,
        0.04896056279540062,
        0.039226092398166656,
        0.05747118964791298,
        -0.042025793343782425,
        -0.0337333157658577,
        0.05641499161720276,
        -0.051629941910505295,
        0.04925355315208435,
        0.03238450735807419,
        -0.046840522438287735,
        -0.025290491059422493,
        -0.01278604194521904,
        -0.010873123072087765,
        -0.042047347873449326,
        -0.013363326899707317,
        0.009764100424945354,
        0.047876883298158646,
        0.0012772128684446216,
        0.04193783178925514,
        0.03938310593366623,
        0.03754405677318573,
        0.05529964715242386,
        0.03800805285573006,
        0.054926443845033646,
        -0.04763397201895714,
        0.0553632453083992,
        0.056838471442461014,
        0.05565522611141205,
        -0.0156897883862257,
        0.05782421678304672,
        -0.04616426303982735,
        0.053907983005046844,
        0.04869029298424721,
        0.04308151453733444,
        0.05546051263809204,
        -0.05756823718547821,
        -0.0083687799051404,
        0.03802124410867691,
        0.05833463370800018,
        -0.048483770340681076,
        -0.028659209609031677,
        -0.055310897529125214,
        -0.05338049679994583,
        -0.05752114951610565,
        0.05769801512360573,
        -0.05358143523335457,
        -0.04460293427109718,
        0.02869800291955471,
        0.0130565594881773,
        -0.05552319809794426,
        -0.02278115227818489,
        -0.03370112553238869,
        -0.05355757474899292,
        0.050061360001564026,
        -0.008882354013621807,
        0.04587665945291519,
        0.052613019943237305,
        -0.054622799158096313,
        -0.02188692055642605,
        0.05665145069360733,
        0.0481138601899147,
        0.00512231420725584,
        -0.05316147953271866,
        -0.05099356546998024,
        -0.015519119799137115,
        -0.023002270609140396,
        -0.012475134804844856,
        0.04497470334172249,
        -0.03629952296614647,
        -0.047224175184965134,
        -0.04803159832954407,
        -0.05684974417090416,
        -0.0574444979429245,
        -0.056773677468299866,
        0.05327436327934265,
        0.05812224745750427,
        0.013999982737004757,
        0.046502597630023956,
        0.0581984780728817,
        0.057730358093976974,
        0.05627823621034622,
        0.051799919456243515,
        0.03194152191281319,
        0.01973617635667324,
        0.007534959353506565,
        0.055693380534648895,
        0.02781132608652115,
        0.0031012441031634808,
        -0.03355296328663826,
        -0.05568898096680641,
        0.03169962763786316,
        -0.057967089116573334,
        -0.003780487459152937,
        -0.05788292735815048,
        -0.05623805522918701,
        0.04409702867269516,
        -0.05715789645910263,
        0.05810091644525528,
        -0.024338167160749435,
        -0.041082728654146194,
        -0.054389044642448425,
        0.03817635774612427,
        0.014892744831740856,
        -0.019768131896853447,
        0.021890776231884956,
        -0.05094468966126442,
        0.03486070781946182,
        -0.04910038411617279,
        -0.05582784116268158,
        0.040805064141750336,
        -0.0467938594520092,
        0.03382641822099686,
        -0.011201481334865093,
        -0.0377647802233696,
        0.0521138496696949,
        -0.05227743834257126,
        0.05832118168473244,
        0.019822152331471443,
        0.02309754490852356,
        0.05663425102829933,
        -0.05498873442411423,
        0.05302433669567108,
        0.005349170416593552,
        0.04165378957986832,
        0.006061443127691746,
        -0.048907142132520676,
        -0.04307646304368973,
        -0.04158412292599678,
        0.05805831775069237,
        -0.05825472250580788,
        -0.003546853316947818,
        -0.01448823418468237,
        -0.034853965044021606,
        0.05733516812324524,
        -0.054721586406230927,
        -0.053721603006124496,
        0.056199733167886734,
        -0.05504962429404259,
        0.037906862795352936,
        -0.05703261122107506,
        -0.03083821013569832,
        -0.037710994482040405,
        0.04921751469373703,
        -0.024819303303956985,
        0.03980588540434837,
        0.0566876120865345,
        0.05740215256810188,
        -0.056777410209178925,
        0.0546136312186718,
        -0.011053835973143578,
        -0.05235787108540535,
        0.030063897371292114,
        -0.037748511880636215,
        -0.055701497942209244,
        0.058072540909051895,
        -0.04841617867350578,
        0.050623249262571335,
        0.05777188017964363,
        -0.04786960408091545,
        0.028284411877393723,
        -0.028864160180091858,
        0.00814888533204794,
        -0.013403733260929585,
        -0.043303005397319794,
        0.05837082490324974,
        0.0331103540956974,
        0.04619769752025604,
        0.05060724914073944,
        0.019747687503695488,
        0.056721050292253494,
        -0.058360569179058075,
        -0.03249707818031311,
        -0.021180611103773117,
        -0.05557980760931969,
        -0.05615633353590965,
        0.05669765546917915,
        -0.05687500908970833,
        -0.04286254942417145,
        -0.052601899951696396,
        -0.054940544068813324,
        0.053180206567049026,
        0.0553617961704731,
        -0.014094054698944092,
        -0.04991023242473602,
        -0.04110860824584961,
        -0.05408472567796707,
        0.05830017477273941,
        -0.05536775290966034,
        0.05387547239661217,
        -0.05820136144757271,
        -0.04673811420798302,
        -0.050084274262189865,
        0.05110635608434677,
        -0.03203865885734558,
        0.05756238475441933,
        0.01711852476000786,
        -0.010219777934253216,
        0.006852786056697369,
        0.00828434620052576,
        -0.028415102511644363,
        0.034619737416505814,
        0.015373081900179386,
        -0.05501358211040497,
        0.012167321518063545,
        0.057834576815366745,
        -0.03753580525517464,
        -0.00015525901108048856,
        -0.04680811986327171,
        -0.03955961763858795,
        -0.046936605125665665,
        -0.044699471443891525,
        0.026305416598916054,
        0.03905666992068291,
        0.038630492985248566,
        -0.02765442244708538,
        0.057159923017024994,
        0.05783461406826973,
        0.0033041611313819885,
        -0.047611091285943985,
        -0.057950157672166824,
        -0.051164768636226654,
        0.056079838424921036,
        0.04970811307430267,
        0.058059096336364746,
        -0.05684572085738182,
        0.03644626587629318,
        0.04521613195538521,
        -0.016509737819433212,
        0.057868726551532745,
        -0.043935127556324005,
        0.057479020208120346,
        -0.02975740097463131,
        0.01685122586786747,
        0.02075744792819023,
        0.05506961792707443,
        -0.012709276750683784,
        -0.016844330355525017,
        0.03861255943775177,
        0.05461367592215538,
        0.043425701558589935,
        -0.05223695933818817,
        -0.055373966693878174,
        -0.028919223695993423,
        -0.05713730305433273,
        -0.007965615019202232,
        0.044697996228933334,
        -0.05459892377257347,
        -0.053145576268434525,
        -0.05659612640738487,
        -0.04721016064286232,
        0.04130396619439125,
        0.008414281532168388,
        -0.01912616193294525,
        0.01820787973701954,
        -0.031366124749183655,
        -0.04549599066376686,
        -0.05827454850077629,
        0.017444105818867683,
        0.02568848803639412,
        -0.052365660667419434,
        -0.028687622398138046,
        0.03510497510433197,
        0.043977681547403336,
        0.05025778338313103,
        0.05684364587068558,
        -0.04288637638092041,
        0.058134667575359344,
        -0.05822616443037987,
        0.057020481675863266,
        -0.05596574768424034,
        0.0047821165062487125,
        0.042435172945261,
        0.051381781697273254,
        0.05630698427557945,
        -0.033398132771253586,
        -0.03357986360788345,
        0.020367572084069252,
        -0.014759117737412453,
        -0.028313320130109787,
        0.05401095002889633,
        0.03525160625576973,
        -0.05793605372309685,
        -0.02484523318707943,
        -0.055592939257621765,
        0.05118982493877411,
        0.058052994310855865,
        -0.05797968804836273,
        0.05727827176451683,
        0.035152118653059006,
        -0.058025017380714417,
        0.04772443696856499,
        0.05554308369755745,
        0.053656067699193954,
        0.05078953132033348,
        0.056598249822854996,
        0.013820774853229523,
        -0.010787575505673885,
        0.04130356386303902,
        -0.03691983222961426,
        -0.000921487167943269,
        -0.0552276074886322,
        -0.05610433593392372,
        0.02940201200544834,
        0.039746690541505814,
        -0.029936954379081726,
        0.04597359523177147,
        0.05112528055906296,
        -0.009814457967877388,
        -0.05531678721308708,
        0.03999901935458183,
        -0.03296521678566933,
        -0.05502837151288986,
        0.012565897777676582,
        0.01820441707968712,
        0.05680954456329346,
        0.022733736783266068,
        -0.054245173931121826,
        0.0330132432281971,
        0.049977075308561325,
        0.047562289983034134,
        0.05384140461683273,
        -0.056194063276052475,
        0.02260509319603443,
        0.041841596364974976,
        0.037434715777635574,
        0.03655767813324928,
        -0.03857911378145218,
        -0.03811398893594742,
        -0.03207339346408844,
        -0.05576098710298538,
        0.056840188801288605,
        0.008951610885560513
    ],
    [
        -0.046797871589660645,
        0.05203994736075401,
        -0.05267952382564545,
        0.03915715962648392,
        -0.028822554275393486,
        -0.012546634301543236,
        0.04936356097459793,
        -0.05317239090800285,
        0.04523606225848198,
        -0.05348203331232071,
        0.0319598950445652,
        0.04601211100816727,
        -0.053084637969732285,
        0.004871103912591934,
        -0.0531061552464962,
        0.03433585539460182,
        0.04182729125022888,
        0.053151946514844894,
        0.006231936160475016,
        0.05304832383990288,
        0.003918404690921307,
        -0.03799917548894882,
        0.053466133773326874,
        0.052736230194568634,
        -0.03575965017080307,
        -0.03966037929058075,
        -0.0014854461187496781,
        0.0412752702832222,
        -0.05341053009033203,
        -0.047324419021606445,
        -0.04904582351446152,
        0.01086884643882513,
        -0.05306439474225044,
        -0.0535186231136322,
        0.05309952050447464,
        -0.05299755558371544,
        -0.05312570929527283,
        -0.05335723236203194,
        0.05325643718242645,
        -0.04806056618690491,
        0.04901271313428879,
        0.04134417697787285,
        0.047682300209999084,
        -0.045617926865816116,
        0.05301105976104736,
        -0.012346428819000721,
        0.0287645123898983,
        0.05348518118262291,
        0.05335668474435806,
        -0.053298961371183395,
        0.05327357351779938,
        0.048161931335926056,
        0.025617903098464012,
        -0.053211916238069534,
        0.048307109624147415,
        -0.053293175995349884,
        -0.05263996124267578,
        -0.026950420811772346,
        -0.052431680262088776,
        -0.04495929181575775,
        -0.051610082387924194,
        -0.03281428664922714,
        -0.052837055176496506,
        -0.04767049849033356,
        -0.04157101735472679,
        0.004637954290956259,
        -0.05232144892215729,
        -0.04834827780723572,
        0.0478086918592453,
        -0.0528998002409935,
        0.041628483682870865,
        -0.050961121916770935,
        0.05205714330077171,
        0.05271002650260925,
        0.053233999758958817,
        -0.025479383766651154,
        0.053500257432460785,
        0.05353390425443649,
        0.0466848760843277,
        -0.03313865512609482,
        -0.0161653570830822,
        -0.05001227185130119,
        -0.05351324379444122,
        0.051378075033426285,
        0.04468138888478279,
        0.04955779016017914,
        0.0020533627830445766,
        -0.03814389929175377,
        -0.04416516423225403,
        0.019534612074494362,
        -0.05011923238635063,
        -0.022824879735708237,
        -0.04992792755365372,
        -0.013776094652712345,
        -0.04777710884809494,
        0.01707044430077076,
        -0.03961426392197609,
        -0.05271897837519646,
        -0.04111724719405174,
        0.009788653813302517,
        -0.05346906557679176,
        0.04745909571647644,
        0.05345252528786659,
        0.05352698639035225,
        -0.021737679839134216,
        -0.052910950034856796,
        0.05305692180991173,
        0.014963551424443722,
        -0.05260821431875229,
        -0.04666246101260185,
        -0.05217314139008522,
        0.05341202765703201,
        -0.03834807500243187,
        -0.052280135452747345,
        0.053532082587480545,
        -0.007471858523786068,
        0.051344018429517746,
        -0.041737258434295654,
        0.052964355796575546,
        -0.02043803408741951,
        -0.05096009746193886,
        -0.045094601809978485,
        0.051143065094947815,
        -0.05343532934784889,
        -0.05126045271754265,
        0.04422760009765625,
        0.05064672976732254,
        0.05336206778883934,
        0.051600851118564606,
        0.050533100962638855,
        0.016029441729187965,
        -0.05322692170739174,
        -0.05049620196223259,
        0.052601054310798645,
        -0.049851756542921066,
        -0.052665695548057556,
        0.04731130227446556,
        -0.03937879949808121,
        -0.05173514038324356,
        0.038299210369586945,
        -0.04404005780816078,
        -0.006771556101739407,
        0.05265481024980545,
        0.05200306698679924,
        0.0054528373293578625,
        0.05297042429447174,
        0.053342875093221664,
        -0.042143288999795914,
        0.051326677203178406,
        0.05072668194770813,
        -0.02773483842611313,
        -0.053457796573638916,
        -0.05301189422607422,
        -0.01488806027919054,
        0.053198058158159256,
        -0.0505000576376915,
        -0.04815330728888512,
        -0.01757625862956047,
        -0.010164272971451283,
        0.009904216974973679,
        0.0197982806712389,
        0.017308933660387993,
        0.05269521474838257,
        0.05315825715661049,
        0.02079685591161251,
        0.05166954919695854,
        0.05218839645385742,
        0.02417631633579731,
        -0.04524139314889908,
        0.04180392995476723,
        -0.035979025065898895,
        0.04717379808425903,
        -0.05348995327949524,
        0.0531468540430069,
        -0.053452953696250916,
        0.048853740096092224,
        -0.008441710844635963,
        -0.007964483462274075,
        0.05276525765657425,
        -0.029247984290122986,
        -0.05304192379117012,
        -0.0003039277798961848,
        0.04572733864188194,
        -0.05236106365919113,
        -0.030692685395479202,
        -0.02452327497303486,
        0.041336048394441605,
        0.052703771740198135,
        0.004655863624066114,
        -0.05044952407479286,
        -0.05348290503025055,
        0.01864645630121231,
        -0.04235381260514259,
        -0.042986828833818436,
        0.0022805952467024326,
        -0.007658959832042456,
        0.050655923783779144,
        0.04245981574058533,
        -0.05102160945534706,
        0.04113246500492096,
        0.053365688771009445,
        0.051830191165208817,
        -0.019513282924890518,
        -0.04679657891392708,
        -0.047076962888240814,
        0.02823510952293873,
        0.04872892424464226,
        0.046624377369880676,
        -0.010924375616014004,
        -0.022748807445168495,
        -0.05163643881678581,
        -0.0005341606447473168,
        0.031801097095012665,
        0.0531078539788723,
        -0.05351956933736801,
        -0.05350056290626526,
        0.05352402478456497,
        0.01676715351641178,
        0.053530458360910416,
        -0.04473160579800606,
        -0.04461529850959778,
        -0.04736767336726189,
        0.053362201899290085,
        -0.038700055330991745,
        0.053226079791784286,
        -0.05200257897377014,
        0.002458747010678053,
        0.05120651796460152,
        0.040646992623806,
        -0.053460560739040375,
        0.047231126576662064,
        -0.052761759608983994,
        -0.05332097411155701,
        0.045129794627428055,
        -0.05173703655600548,
        0.05341257527470589,
        0.05277726799249649,
        0.007073993794620037,
        0.020155075937509537,
        0.053532734513282776,
        0.05042674019932747,
        -0.04491641744971275,
        0.03949383646249771,
        -0.04933817312121391,
        0.05234799161553383,
        -0.012001479044556618,
        0.050939299166202545,
        -0.02345309965312481,
        -0.052212636917829514,
        -0.033142827451229095,
        -0.05349439010024071,
        -0.041336022317409515,
        -0.04245362803339958,
        -0.049771491438150406,
        -0.0247072521597147,
        -0.022609058767557144,
        -0.05076365917921066,
        -0.053022246807813644,
        0.04820914939045906,
        -0.019181350246071815,
        -0.050543785095214844,
        0.045323602855205536,
        -0.05197054520249367,
        -0.05156273394823074,
        -0.05336553975939751,
        0.05352303013205528,
        0.05092676728963852,
        -0.03331714868545532,
        0.03561300411820412,
        0.01802714169025421,
        0.012107977643609047,
        -0.0522996261715889,
        0.052097853273153305,
        -0.050102800130844116,
        -0.043642591685056686,
        0.036768291145563126,
        -0.025201691314578056,
        0.053354647010564804,
        0.04777117446064949,
        0.04550207406282425,
        -0.052889347076416016,
        0.05094365030527115,
        -0.03594360128045082,
        0.010807646438479424,
        -0.03718047961592674,
        0.047820769250392914,
        -0.051622238010168076,
        0.05338003486394882,
        -0.0221067126840353,
        -0.03699004277586937,
        -0.04607762396335602,
        0.053444355726242065,
        -0.049775514751672745,
        -0.05012526363134384,
        0.04690643772482872,
        -0.05339020490646362,
        0.053459327667951584,
        0.05251917988061905,
        -0.048409633338451385,
        0.0529562272131443,
        0.051716938614845276,
        0.03787059709429741,
        -0.016357984393835068,
        0.03377401828765869,
        0.053290024399757385,
        0.04959066957235336,
        -0.0460386797785759,
        0.05346744507551193,
        -0.03921152278780937,
        -0.048461299389600754,
        0.04896313324570656,
        -0.05320893973112106,
        0.05010322481393814,
        0.05330608785152435,
        0.022287625819444656,
        -0.05308503657579422,
        -0.02369053103029728,
        -0.051945753395557404,
        0.05217445269227028,
        0.040926918387413025,
        0.05220378190279007,
        0.02256474643945694,
        0.052949242293834686,
        0.05200008675456047,
        0.05082852393388748,
        0.0429505817592144,
        0.04087253287434578,
        -0.052737750113010406,
        -0.052398931235075,
        -0.04680556058883667,
        0.04378034174442291,
        -0.053192730993032455,
        -0.004602446220815182,
        -0.053385742008686066,
        0.04595537856221199,
        -0.03048330917954445,
        0.05172659084200859,
        0.036273106932640076,
        -0.0012963995104655623,
        0.05223281309008598,
        -0.05190255120396614,
        0.005864984821528196,
        0.0455196313560009,
        -0.006925818044692278,
        0.008517036214470863,
        0.04408212751150131,
        0.052775751799345016,
        0.051737211644649506,
        0.05347345396876335,
        0.050190508365631104,
        0.05352860316634178,
        0.00935791153460741,
        0.0259561650454998,
        -0.039557840675115585,
        -0.04396277666091919,
        -0.03199463337659836,
        -0.04983944073319435,
        -0.0514647550880909,
        0.043442364782094955,
        0.04516682028770447,
        0.04089244827628136,
        0.04931154102087021,
        -0.02401002123951912,
        -0.04999854043126106,
        0.010073045268654823,
        -0.050312675535678864,
        0.05309857055544853,
        -0.05279772728681564,
        -0.023212812840938568,
        -0.05298808217048645,
        -0.03172844275832176,
        -0.053415682166814804,
        0.045358993113040924,
        -0.05310826376080513,
        0.04913710057735443,
        0.0535334050655365,
        0.0525890477001667,
        0.051166702061891556,
        0.053397130221128464,
        -0.051972873508930206,
        -0.05090775340795517,
        0.015588481910526752,
        -0.05186433717608452,
        0.02757631614804268,
        0.05097971111536026,
        -0.0534549281001091,
        0.046730030328035355,
        -0.05007201060652733,
        -0.05091026797890663,
        -0.05316835641860962,
        -0.05227356031537056,
        -0.025233719497919083,
        -0.010850073769688606,
        -0.051901064813137054,
        0.033593907952308655,
        0.05345913767814636,
        0.052853163331747055,
        -0.05045785754919052,
        -0.051065776497125626,
        -0.05312542989850044,
        -0.0007436301093548536,
        0.05349912494421005,
        0.004914246499538422,
        0.05305243656039238,
        -0.053191110491752625,
        -0.033119283616542816,
        0.041556090116500854,
        -0.05256788805127144,
        0.05254128575325012,
        0.04308827966451645,
        0.024991193786263466,
        -0.05132032185792923,
        0.03970901295542717,
        0.050651274621486664,
        0.04948723688721657,
        -0.05345010757446289,
        -0.05116312950849533,
        -0.04259873926639557,
        0.04987674951553345,
        0.05181799456477165,
        -0.05252078175544739,
        -0.039353080093860626,
        0.017618447542190552,
        -0.053479135036468506,
        -0.028385788202285767,
        -0.049638520926237106,
        -0.03205735981464386,
        0.03356688469648361,
        -0.04987924546003342,
        -0.05250855162739754,
        0.0535125806927681,
        -0.03594902157783508,
        -0.003559218952432275,
        -0.049854837357997894,
        -0.052661605179309845,
        -0.04982629418373108,
        -0.05347978696227074,
        0.05082004889845848,
        -0.008867179043591022,
        -0.04785263538360596,
        -0.039300065487623215,
        0.05351391062140465,
        0.03240637481212616,
        0.0533483549952507,
        -0.05215509235858917,
        0.0480031743645668,
        0.053184084594249725,
        -0.05304444953799248,
        0.006315668113529682,
        0.05262633040547371,
        0.009305260144174099,
        -0.05044977739453316,
        0.05126839876174927,
        0.03503794223070145,
        -0.0030300430953502655,
        -0.052281126379966736,
        -0.008672080002725124,
        -0.04386025667190552,
        -0.04605381190776825,
        0.05232953652739525,
        0.029809653759002686,
        -0.03463832288980484,
        -0.05165718495845795,
        0.001632189261727035,
        0.053305089473724365,
        0.05343920737504959,
        -0.05318932607769966,
        0.052895817905664444,
        0.05130792781710625,
        -0.05298515781760216,
        0.052221279591321945,
        0.03013659082353115,
        0.05230511724948883,
        0.036530837416648865,
        -0.014354941435158253,
        0.04167048633098602,
        0.053498752415180206,
        -0.05332637578248978,
        -0.03419501334428787,
        -0.05206163600087166,
        0.015823807567358017,
        -0.04947568476200104,
        -0.05353046953678131,
        0.046918295323848724,
        -0.051627017557621,
        0.05318591743707657,
        0.034521110355854034,
        0.027660561725497246,
        -0.053291719406843185,
        -0.050420574843883514,
        -0.0027781310491263866,
        0.011772616766393185,
        -0.015387646853923798,
        0.04524584114551544,
        -0.025499112904071808,
        0.042645104229450226,
        0.033668261021375656,
        -0.005692910868674517,
        0.04957021772861481,
        0.0438082180917263,
        0.049125660210847855,
        0.05244459584355354,
        -0.04921555891633034,
        -0.04110598564147949,
        0.05226562172174454,
        0.0383797287940979,
        0.05195038393139839,
        -0.035700663924217224,
        -0.006473320070654154,
        -0.052779849618673325,
        0.042761947959661484,
        0.01649877056479454
    ],
    [
        -0.007327794097363949,
        0.021513955667614937,
        0.05134965851902962,
        -0.007940278388559818,
        0.003781887935474515,
        -0.049435488879680634,
        0.05253541097044945,
        -0.052932027727365494,
        0.05238915607333183,
        -0.031418293714523315,
        0.05140001326799393,
        0.04999891668558121,
        -0.05228006839752197,
        -0.051734212785959244,
        -0.052021659910678864,
        0.05275608226656914,
        0.051775939762592316,
        0.05281165614724159,
        -0.050338443368673325,
        0.05291102081537247,
        -0.02310367301106453,
        0.04842210188508034,
        0.05296299234032631,
        -0.04525194317102432,
        0.04234543442726135,
        -0.010800959542393684,
        -0.02715948410332203,
        0.05295179411768913,
        -0.04844515770673752,
        -0.04489691182971001,
        0.04857504740357399,
        0.051314111799001694,
        -0.05296313762664795,
        -0.05295002460479736,
        0.05250178277492523,
        -0.015459065325558186,
        -0.052150070667266846,
        -0.05147787556052208,
        0.050049636512994766,
        -0.052822571247816086,
        0.05192865803837776,
        0.04540754482150078,
        0.04703635722398758,
        0.052210547029972076,
        0.05225038528442383,
        0.01852804236114025,
        0.04613889381289482,
        0.052955370396375656,
        0.05291924625635147,
        -0.052793581038713455,
        0.05288106948137283,
        0.03873128443956375,
        -0.05164039507508278,
        -0.049325961619615555,
        0.05290278419852257,
        -0.05296018719673157,
        -0.05273832753300667,
        0.03397620841860771,
        -0.037207335233688354,
        0.05278448387980461,
        -0.052256375551223755,
        0.04184860736131668,
        -0.05264509469270706,
        -0.03229907155036926,
        0.026595283299684525,
        -0.05274752900004387,
        -0.05258062109351158,
        0.049050670117139816,
        0.029017122462391853,
        -0.05226701498031616,
        0.048677872866392136,
        0.017269739881157875,
        0.05141232907772064,
        0.0528966449201107,
        0.052939143031835556,
        -0.04387526959180832,
        0.05296478420495987,
        0.05296381190419197,
        0.04894690588116646,
        0.02800196222960949,
        -0.04924149811267853,
        0.03948668763041496,
        -0.05255698412656784,
        0.05175289511680603,
        0.052766893059015274,
        0.0500851646065712,
        -0.05226609483361244,
        -0.04921695590019226,
        -0.04315516725182533,
        0.014468774199485779,
        -0.052886880934238434,
        -0.01821967400610447,
        -0.004285941831767559,
        -0.05179762467741966,
        -0.04708729311823845,
        0.031554318964481354,
        0.0022661620751023293,
        -0.046656183898448944,
        -0.0528789721429348,
        0.024507155641913414,
        -0.05292484164237976,
        0.05246423929929733,
        0.05286538973450661,
        0.052680764347314835,
        0.05289469286799431,
        -0.007330572232604027,
        -0.05257125198841095,
        0.023213401436805725,
        -0.043404772877693176,
        -0.005354218650609255,
        -0.052890345454216,
        0.05296492576599121,
        -0.044525522738695145,
        -0.04530930146574974,
        0.052855610847473145,
        0.006581698544323444,
        -0.04849925637245178,
        -0.050537727773189545,
        0.052884791046381,
        0.001112604048103094,
        -0.047837600111961365,
        -0.015936370939016342,
        0.036525703966617584,
        -0.05279763042926788,
        -0.04758361726999283,
        0.029630234465003014,
        0.04678419232368469,
        0.04861292988061905,
        0.05287977308034897,
        -0.014396978542208672,
        -0.031048139557242393,
        -0.051712002605199814,
        0.05241350457072258,
        0.05111285671591759,
        -0.04941390082240105,
        -0.052071601152420044,
        0.05262960121035576,
        0.0442216619849205,
        -0.04535767063498497,
        0.031294599175453186,
        -0.04595330357551575,
        -0.0015743732219561934,
        0.04283476993441582,
        -0.04374745115637779,
        0.0211454126983881,
        0.052089184522628784,
        0.05296462029218674,
        -0.007058506831526756,
        -0.031550902873277664,
        0.0300797950476408,
        -0.03838461637496948,
        -0.05286417901515961,
        -0.012536775320768356,
        0.04955753684043884,
        -0.05275313928723335,
        -0.052920255810022354,
        -0.05264788120985031,
        -0.009896804578602314,
        0.052939534187316895,
        0.04634370282292366,
        0.003916535060852766,
        -0.05278382450342178,
        0.05235300958156586,
        0.05294998362660408,
        -0.04969896003603935,
        0.03913486376404762,
        0.04133240133523941,
        0.004976940806955099,
        0.022106243297457695,
        0.007598987780511379,
        -0.04704313352704048,
        0.04945788532495499,
        -0.05287507548928261,
        -0.05251605063676834,
        -0.03776496276259422,
        0.03920528665184975,
        -0.006905633490532637,
        -0.030918676406145096,
        0.05280468240380287,
        -0.043409332633018494,
        -0.05229739099740982,
        -0.04009217396378517,
        -0.024034123867750168,
        -0.02910633757710457,
        -0.0527658574283123,
        0.003065648255869746,
        0.05138029158115387,
        0.052426986396312714,
        0.037543028593063354,
        -0.05294780060648918,
        -0.05282663553953171,
        -0.03676991909742355,
        0.052881695330142975,
        0.052054908126592636,
        -0.047346144914627075,
        -0.040611863136291504,
        0.05296441912651062,
        -0.05283859744668007,
        -0.05158878490328789,
        0.05270368605852127,
        0.05291752889752388,
        0.03783714026212692,
        -0.013836345635354519,
        -0.051864445209503174,
        0.002974094357341528,
        0.04428950697183609,
        -0.00971166230738163,
        0.05190941318869591,
        0.0004175982321612537,
        -0.052964914590120316,
        -0.05103414133191109,
        -0.026476074010133743,
        0.05009085312485695,
        0.05258505046367645,
        -0.0525842010974884,
        -0.039802245795726776,
        0.05293871462345123,
        0.02820286527276039,
        0.05296313390135765,
        -0.01397151593118906,
        0.05276379734277725,
        -0.03500646725296974,
        0.050697799772024155,
        -0.030490798875689507,
        0.05083953216671944,
        -0.05288481339812279,
        0.051536306738853455,
        0.0529644601047039,
        -0.05296160280704498,
        -0.05286264047026634,
        -0.05011369660496712,
        -0.05293087288737297,
        -0.028266167268157005,
        0.0500144436955452,
        -0.04559412971138954,
        0.05295134708285332,
        0.050291746854782104,
        0.03562343865633011,
        -0.05095818266272545,
        0.05296490341424942,
        0.043560147285461426,
        -0.04916456341743469,
        -0.052963629364967346,
        -0.05153869092464447,
        -0.013060986995697021,
        0.009483562782406807,
        -0.05289965122938156,
        0.03351867198944092,
        0.017802894115447998,
        -0.051860082894563675,
        -0.049804430454969406,
        -0.03593968600034714,
        0.0015728551661595702,
        -0.0520799346268177,
        0.05195377394556999,
        -0.03256457298994064,
        -0.05266550928354263,
        -0.033003661781549454,
        0.052487920969724655,
        -0.04214641824364662,
        0.03325558453798294,
        -0.04307504743337631,
        -0.05295983701944351,
        -0.02338363230228424,
        -0.05116220936179161,
        0.052953168749809265,
        -0.052933916449546814,
        -0.0450904443860054,
        0.02577822282910347,
        -0.04724128171801567,
        0.04603564739227295,
        -0.051198046654462814,
        0.04062722995877266,
        -0.05018950253725052,
        -0.03249823674559593,
        0.047883566468954086,
        -0.045340754091739655,
        0.0528765544295311,
        0.05185060575604439,
        0.05246173217892647,
        -0.05290163680911064,
        0.05295804515480995,
        -0.052849479019641876,
        0.0529484748840332,
        0.03727339580655098,
        -0.01489703543484211,
        -0.008893640711903572,
        0.05290884152054787,
        0.05232783406972885,
        0.051473215222358704,
        -0.041237667202949524,
        0.05162820592522621,
        -0.03831597790122032,
        -0.04778532311320305,
        0.05272138863801956,
        -0.05240490287542343,
        0.05284666270017624,
        -0.031755443662405014,
        -0.052964866161346436,
        0.05273187533020973,
        0.014075704850256443,
        0.04892083257436752,
        0.020560700446367264,
        0.03610235080122948,
        0.052962616086006165,
        0.04705740511417389,
        -0.02275971695780754,
        0.05296462029218674,
        0.040433622896671295,
        -0.015168862417340279,
        0.05295444279909134,
        -0.05292630195617676,
        0.048552121967077255,
        0.05103126913309097,
        -0.04687700793147087,
        -0.05275852605700493,
        -0.03217889368534088,
        -0.04778960719704628,
        0.051632143557071686,
        -0.010986929759383202,
        0.05228990688920021,
        0.05254977196455002,
        0.04067889600992203,
        -0.022360650822520256,
        0.05282440036535263,
        0.052780792117118835,
        0.052272479981184006,
        0.0033983758185058832,
        -0.045292846858501434,
        -0.052469611167907715,
        -0.019970940425992012,
        -0.04420154541730881,
        -0.04843595251441002,
        -0.04300380125641823,
        0.05079320818185806,
        -0.009508942253887653,
        0.047360245138406754,
        0.03459220007061958,
        -0.04154866188764572,
        -0.018285240978002548,
        -0.04567451402544975,
        -0.02842281572520733,
        -0.04904570430517197,
        -0.052714742720127106,
        -0.03320887312293053,
        0.04358823224902153,
        0.05252968147397041,
        0.05275857448577881,
        -0.014923110604286194,
        0.03446635976433754,
        0.05296017602086067,
        -0.030546752735972404,
        -0.009254362434148788,
        -0.025370635092258453,
        -0.052937328815460205,
        0.01879301108419895,
        -0.04783961921930313,
        -0.05159689858555794,
        -0.04811619222164154,
        -0.04241545870900154,
        0.050680872052907944,
        -0.049588799476623535,
        0.009248252026736736,
        -0.04956206679344177,
        0.05172266811132431,
        -0.052620869129896164,
        0.05284463241696358,
        -0.022431325167417526,
        -0.035768892616033554,
        -0.05157630145549774,
        -0.03442893549799919,
        -0.05296115204691887,
        -0.017366325482726097,
        -0.011874189600348473,
        0.04990585893392563,
        0.05296485126018524,
        0.050612371414899826,
        -0.023263167589902878,
        0.052816566079854965,
        -0.042834121733903885,
        -0.0390847846865654,
        0.024864528328180313,
        -0.046997807919979095,
        0.03307390958070755,
        0.05272897332906723,
        -0.052914489060640335,
        -0.017094498500227928,
        -0.0026098317466676235,
        -0.00564998434856534,
        -0.052268192172050476,
        -0.05233701318502426,
        0.01239074021577835,
        -0.052330151200294495,
        -0.052963774651288986,
        0.046933285892009735,
        0.0509285144507885,
        0.02711416967213154,
        -0.03115117736160755,
        0.052549462765455246,
        -0.052490510046482086,
        -0.032498519867658615,
        0.05296039208769798,
        -0.049842555075883865,
        0.051767367869615555,
        -0.05287026986479759,
        -0.011758528649806976,
        0.0029408957343548536,
        -0.02587071992456913,
        0.05249663069844246,
        0.026440735906362534,
        -0.05086066946387291,
        -0.0528462789952755,
        -0.04810497909784317,
        0.050261083990335464,
        0.05235547199845314,
        -0.05294337496161461,
        0.0525183230638504,
        0.05293805152177811,
        0.035090524703264236,
        0.0518207773566246,
        0.05215253308415413,
        -0.04656399041414261,
        -0.05274413526058197,
        -0.052954256534576416,
        0.02133670635521412,
        0.044608768075704575,
        -0.05262710154056549,
        -0.006816308945417404,
        -0.04982062429189682,
        -0.052922096103429794,
        -0.051066599786281586,
        0.048001646995544434,
        -0.04859688878059387,
        0.002507444703951478,
        -0.0529194176197052,
        -0.05166737735271454,
        -0.05230540409684181,
        0.03090175800025463,
        0.019178442656993866,
        -0.051261041313409805,
        -0.011837112717330456,
        0.044589150696992874,
        -0.024993659928441048,
        0.05251641198992729,
        -0.051969725638628006,
        -0.052048370242118835,
        0.05218559503555298,
        -0.03502432629466057,
        0.023575419560074806,
        0.0468384213745594,
        0.027430320158600807,
        0.05256564915180206,
        0.051208097487688065,
        0.05265561491250992,
        -0.014893320389091969,
        -0.03824888914823532,
        0.01243664138019085,
        -0.05172903463244438,
        -0.010236957110464573,
        0.05212225019931793,
        0.04254729300737381,
        -0.052019134163856506,
        0.04906875267624855,
        -0.05285760760307312,
        0.05291324853897095,
        0.05271901935338974,
        -0.05095507204532623,
        0.052959293127059937,
        -0.034320224076509476,
        -0.051374778151512146,
        0.052272602915763855,
        0.03406507894396782,
        0.05293267220258713,
        0.02501480095088482,
        -0.04889894649386406,
        0.022597679868340492,
        0.028091972693800926,
        -0.052962325513362885,
        -0.04283204302191734,
        -0.05151854082942009,
        0.052886076271533966,
        0.018178287893533707,
        0.05191079154610634,
        0.05039339140057564,
        -0.05295427888631821,
        0.052035652101039886,
        0.020345330238342285,
        0.035965994000434875,
        -0.051853589713573456,
        -0.0518634095788002,
        -0.05241972208023071,
        0.04081600904464722,
        0.0528903566300869,
        -0.030993416905403137,
        -0.04338906705379486,
        0.052203014492988586,
        -0.05290702357888222,
        -0.05172083154320717,
        0.03792143985629082,
        0.042679086327552795,
        0.052922915667295456,
        0.05028608813881874,
        -0.026334838941693306,
        -0.026110956445336342,
        -0.028418948873877525,
        0.052239324897527695,
        0.052912015467882156,
        0.022126801311969757,
        0.05282051861286163,
        -0.018215782940387726,
        0.052232641726732254,
        0.027839498594403267
    ],
    [
        -0.04854099452495575,
        -0.05670534819364548,
        -0.02921338938176632,
        0.01476213987916708,
        -0.04099079966545105,
        -0.05574750155210495,
        0.01085612177848816,
        -0.05660204961895943,
        0.05619596317410469,
        -0.045921213924884796,
        0.05214318260550499,
        -0.03371619060635567,
        -0.05070804804563522,
        0.04111052677035332,
        -0.04471233859658241,
        0.02844962291419506,
        0.028750352561473846,
        0.05270354449748993,
        -0.043712910264730453,
        -0.007575232069939375,
        -0.052046116441488266,
        0.0528942234814167,
        0.052907247096300125,
        0.052386876195669174,
        -0.030737854540348053,
        0.05682511627674103,
        0.056790176779031754,
        -0.006931236945092678,
        -0.043669190257787704,
        0.05038226395845413,
        0.048225801438093185,
        -0.002307792892679572,
        -0.045951638370752335,
        -0.058246154338121414,
        0.05704424902796745,
        0.0501953661441803,
        -0.054053597152233124,
        -0.026550156995654106,
        0.0007366890204139054,
        -0.04210366681218147,
        0.023980429396033287,
        0.05632426589727402,
        0.05556831508874893,
        -0.057656195014715195,
        -0.028487421572208405,
        0.058110594749450684,
        -0.022532423958182335,
        0.058004189282655716,
        0.05826445668935776,
        -0.014598461799323559,
        0.05793527141213417,
        -0.03192545101046562,
        -0.010018955916166306,
        0.05215693265199661,
        -0.046444810926914215,
        0.040421806275844574,
        0.03653719276189804,
        -0.008364350534975529,
        0.04556405544281006,
        -0.05499574542045593,
        -0.058280423283576965,
        -0.02320379950106144,
        -0.05299672111868858,
        -0.011698687449097633,
        0.04495308920741081,
        -0.04244700446724892,
        -0.01837201416492462,
        -0.05817625671625137,
        0.05138184502720833,
        -0.055199287831783295,
        -0.028937144204974174,
        -0.05802929401397705,
        0.058231428265571594,
        0.03610174357891083,
        0.05816756561398506,
        -0.010597681626677513,
        -0.02423882856965065,
        0.058359578251838684,
        -0.04543513059616089,
        0.020671412348747253,
        -0.05038720741868019,
        0.03578673675656319,
        -0.055885668843984604,
        0.04276324808597565,
        0.05062238872051239,
        0.056692663580179214,
        0.0390283428132534,
        -0.035444196313619614,
        0.023420928046107292,
        0.03132565692067146,
        -0.03224620968103409,
        -0.03076907992362976,
        -0.04942264035344124,
        -0.04074965417385101,
        -0.05688222125172615,
        0.03615454584360123,
        -0.018706632778048515,
        -0.057185202836990356,
        -0.05534299463033676,
        0.05159139633178711,
        -0.05642515793442726,
        0.05799390375614166,
        0.0582854188978672,
        0.056350428611040115,
        0.035075537860393524,
        0.019733423367142677,
        0.04113253951072693,
        0.03249441832304001,
        -0.04745110124349594,
        0.027029702439904213,
        -0.035044994205236435,
        -0.04452962055802345,
        0.02305358275771141,
        0.05519803613424301,
        0.04985250532627106,
        0.037188321352005005,
        -0.057864200323820114,
        -0.03255293890833855,
        0.05831674486398697,
        -0.023429999127984047,
        0.03832285478711128,
        0.032331421971321106,
        0.027060797438025475,
        0.030128812417387962,
        -0.03387796878814697,
        -0.05414213985204697,
        0.05707656964659691,
        -0.05175238102674484,
        0.0582532063126564,
        0.0053705996833741665,
        0.058113642036914825,
        -0.045960020273923874,
        -0.04343046620488167,
        0.05547004193067551,
        -0.054952073842287064,
        -0.0331643670797348,
        0.054753225296735764,
        -0.0030914940871298313,
        -0.023578541353344917,
        0.02994443103671074,
        -0.05179121717810631,
        -0.03323662281036377,
        0.05256971716880798,
        0.053281690925359726,
        0.042829129844903946,
        0.05810349062085152,
        0.04038131982088089,
        -0.05316341668367386,
        -0.017917852848768234,
        0.056892458349466324,
        -0.054211828857660294,
        -0.058299195021390915,
        0.03465747833251953,
        -0.0012213680893182755,
        0.05470661073923111,
        -0.056846119463443756,
        -0.006057330872863531,
        -0.0540408231317997,
        0.02662619762122631,
        0.01480929832905531,
        -0.013137915171682835,
        0.05319014564156532,
        0.05588916316628456,
        0.05747370421886444,
        0.012349613010883331,
        0.04300391674041748,
        0.01563132181763649,
        -0.02248728834092617,
        0.041899673640728,
        -0.01394596602767706,
        -0.03746757283806801,
        0.02701660990715027,
        -0.019341276958584785,
        -0.05568193644285202,
        -0.05205383896827698,
        0.03733420744538307,
        -0.012260135263204575,
        -0.014722151681780815,
        0.05528699979186058,
        -0.04857368394732475,
        -0.043848294764757156,
        -0.02880198322236538,
        0.03627019375562668,
        -0.05745854601264,
        -0.04102366790175438,
        0.04448277875781059,
        -0.044908516108989716,
        0.05729256570339203,
        -0.054380908608436584,
        -0.057284045964479446,
        -0.054394591599702835,
        0.054371412843465805,
        0.04015343263745308,
        0.029221881181001663,
        -0.05502733960747719,
        -0.03701172396540642,
        0.035834841430187225,
        0.011029087007045746,
        -0.04017135500907898,
        0.05769723653793335,
        0.057752955704927444,
        -0.04031301662325859,
        -0.017145678400993347,
        -0.05777866020798683,
        0.03875758871436119,
        0.036681678146123886,
        0.05503035709261894,
        0.0549514964222908,
        -0.05800522491335869,
        0.05731331929564476,
        -0.008724858984351158,
        0.057687994092702866,
        0.0566934235394001,
        0.056339263916015625,
        -0.009389686398208141,
        0.004159622825682163,
        -0.049651194363832474,
        -0.018420496955513954,
        0.05833990499377251,
        0.004394365940243006,
        0.0003167135873809457,
        -0.05353734269738197,
        -0.04336649179458618,
        -0.02855706587433815,
        0.04579975828528404,
        -0.05702291801571846,
        0.003940265625715256,
        -0.0363931804895401,
        0.0581720694899559,
        -0.052725546061992645,
        -0.05367245897650719,
        -0.05663304775953293,
        -0.012261327356100082,
        0.056315671652555466,
        -0.05734701827168465,
        0.05607420951128006,
        0.04555957391858101,
        -0.009537319652736187,
        0.014100194908678532,
        0.05835692211985588,
        -0.020851437002420425,
        0.05490400642156601,
        -0.05834605544805527,
        -0.01180189661681652,
        0.010436369106173515,
        0.004827518481761217,
        -0.051716405898332596,
        -0.0408966951072216,
        -0.049922794103622437,
        -0.05654339864850044,
        -0.036353837698698044,
        -0.057498566806316376,
        0.04594625160098076,
        -0.05672057345509529,
        -0.024605020880699158,
        0.05656445398926735,
        -0.01731974445283413,
        -0.024968646466732025,
        0.04897835850715637,
        -0.04319243133068085,
        -0.025089168921113014,
        -0.03637157380580902,
        0.04026014357805252,
        0.05828739330172539,
        -0.0298443753272295,
        0.056924909353256226,
        -0.033780068159103394,
        0.05755807086825371,
        -0.056581348180770874,
        -0.05605321377515793,
        0.0580575130879879,
        -0.05829858407378197,
        0.0387914776802063,
        -0.05716290697455406,
        -0.056884147226810455,
        0.009077893570065498,
        -0.03632432967424393,
        0.05607372522354126,
        0.04216894879937172,
        -0.02525150217115879,
        -0.056826770305633545,
        0.03298979625105858,
        -0.05569957569241524,
        0.04480953887104988,
        -0.05419745296239853,
        -0.0013723182491958141,
        0.033266279846429825,
        0.05501386895775795,
        0.04596613720059395,
        0.05705229565501213,
        -0.05670110136270523,
        -0.00815568957477808,
        -0.010270576924085617,
        0.008348766714334488,
        0.04984003305435181,
        -0.05802232399582863,
        0.05802399665117264,
        0.04558033496141434,
        -0.0523369275033474,
        0.0578325130045414,
        -0.010982350446283817,
        0.04316098988056183,
        0.009083820506930351,
        0.03068268857896328,
        0.016736706718802452,
        -0.04661621153354645,
        -0.004731312859803438,
        0.05507814139127731,
        0.058332692831754684,
        0.02773294411599636,
        -0.016919048503041267,
        0.0523262657225132,
        0.04715640842914581,
        0.03236955404281616,
        0.03995765000581741,
        -0.05664321780204773,
        0.041769612580537796,
        0.010788064450025558,
        0.05712093785405159,
        -0.0459030345082283,
        -0.030489875003695488,
        -0.00931817851960659,
        0.04768296703696251,
        0.037576209753751755,
        0.04782016575336456,
        0.04253591597080231,
        0.05113790184259415,
        0.018816811963915825,
        0.028229093179106712,
        0.005167861003428698,
        -0.03198651596903801,
        -0.055514562875032425,
        -0.033928029239177704,
        -0.051643308252096176,
        0.05733439326286316,
        0.041894104331731796,
        -0.04444359615445137,
        0.03156315162777901,
        -0.044769056141376495,
        0.058265961706638336,
        -0.052348047494888306,
        -0.036362241953611374,
        -0.04180263727903366,
        -0.05424344539642334,
        0.049384064972400665,
        0.021480288356542587,
        0.05827702209353447,
        0.0580328106880188,
        -0.03430015966296196,
        0.03163088858127594,
        0.04184078052639961,
        -0.045072752982378006,
        -0.017987769097089767,
        -0.02824423648416996,
        -0.05824670568108559,
        0.032137103378772736,
        0.007568840868771076,
        -0.05778541415929794,
        -0.029560264199972153,
        -0.054560329765081406,
        0.018051089718937874,
        0.04081083461642265,
        -0.054233141243457794,
        -0.05275903642177582,
        0.042914219200611115,
        0.006194783840328455,
        0.058267176151275635,
        -0.048994023352861404,
        0.044306766241788864,
        -0.055779580026865005,
        -0.027639271691441536,
        -0.04355889558792114,
        0.02496849186718464,
        0.02539539523422718,
        0.05754680186510086,
        0.056265972554683685,
        0.04268204793334007,
        -0.05530356615781784,
        0.049526751041412354,
        0.057258155196905136,
        0.05651275813579559,
        0.04014536738395691,
        -0.05823052302002907,
        -0.03703877329826355,
        0.043193984776735306,
        -0.03954659774899483,
        -0.018709320574998856,
        -0.05238617584109306,
        -0.05766284838318825,
        0.052244771271944046,
        -0.057961929589509964,
        0.04789213836193085,
        -0.05531105026602745,
        -0.014421538449823856,
        0.019291868433356285,
        0.054523926228284836,
        0.05827392265200615,
        0.018540438264608383,
        0.03088315948843956,
        -0.0565263107419014,
        -0.054892782121896744,
        0.05835162103176117,
        -0.054429203271865845,
        0.054481539875268936,
        -0.05669282749295235,
        0.03551721200346947,
        0.02757098712027073,
        0.025119973346590996,
        0.058085497468709946,
        -0.01985991559922695,
        -0.032879117876291275,
        -0.04327020049095154,
        0.053441356867551804,
        -0.009025970473885536,
        0.05382603034377098,
        -0.055990125983953476,
        0.04666152223944664,
        0.04714898020029068,
        -0.006244173273444176,
        0.008317174389958382,
        0.02321234531700611,
        -0.05774613097310066,
        0.03139515593647957,
        -0.05731669068336487,
        0.032707422971725464,
        0.005128887481987476,
        -0.051477737724781036,
        -0.013381184078752995,
        -0.03768101707100868,
        -0.058009445667266846,
        0.05813957378268242,
        0.044273629784584045,
        -0.05735742673277855,
        -0.026400765404105186,
        0.046959035098552704,
        -0.05804109200835228,
        -0.057447806000709534,
        -0.00877173338085413,
        0.02576325833797455,
        0.020899698138237,
        -0.04394018277525902,
        -0.05582559108734131,
        -0.048512909561395645,
        0.0574110671877861,
        0.05815863609313965,
        -0.051225971430540085,
        0.05493434518575668,
        -0.01034297700971365,
        0.027281606569886208,
        0.044912055134773254,
        0.002773704007267952,
        0.05793667584657669,
        0.0480988472700119,
        0.058256182819604874,
        0.055423129349946976,
        -0.044502340257167816,
        -0.01917736604809761,
        -0.0544927679002285,
        0.045812856405973434,
        0.041454609483480453,
        0.001627461169846356,
        -0.055599600076675415,
        0.032601598650217056,
        -0.052366890013217926,
        0.05778384953737259,
        0.058078475296497345,
        -0.042621295899152756,
        0.050939977169036865,
        -0.003342314623296261,
        -0.055074431002140045,
        0.0538150779902935,
        0.032963842153549194,
        0.05676241219043732,
        0.00017296404985245317,
        -0.058028656989336014,
        -0.014641817659139633,
        0.05672357603907585,
        0.0475245900452137,
        -0.044460561126470566,
        -0.056553155183792114,
        0.0016751994844526052,
        -0.05010433867573738,
        -0.04276599735021591,
        0.043004926294088364,
        -0.0553835965692997,
        0.04310601204633713,
        0.014529687352478504,
        0.0569198913872242,
        -0.053077585995197296,
        0.05293825641274452,
        0.0527377612888813,
        0.033378127962350845,
        0.05810191482305527,
        -0.04730980098247528,
        -0.01164010539650917,
        -0.029895266517996788,
        -0.030064431950449944,
        0.05341922119259834,
        0.054324809461832047,
        0.05748300999403,
        0.05536910891532898,
        -0.029718874022364616,
        0.01494661346077919,
        -0.05786581337451935,
        -0.04765572026371956,
        0.03249301016330719,
        0.05661510303616524,
        -0.038612250238657,
        0.057651106268167496,
        -0.055722616612911224,
        -0.044854238629341125,
        -0.04791535064578056
    ],
    [
        -0.050465043634176254,
        -0.05745506286621094,
        -0.021466277539730072,
        0.0029457334894686937,
        -0.03334054350852966,
        -0.02780219167470932,
        0.047014426440000534,
        -0.05757569149136543,
        0.03732382133603096,
        0.027036888524889946,
        0.04550221562385559,
        -0.0017936992226168513,
        -0.05577467009425163,
        -0.017951946705579758,
        -0.05770866945385933,
        0.026402665302157402,
        0.05709151178598404,
        0.0567287877202034,
        -0.030925171449780464,
        0.04995690658688545,
        -0.05717548355460167,
        0.04166107624769211,
        0.05681214481592178,
        0.04567835479974747,
        -0.05697629973292351,
        0.008703420870006084,
        0.057846035808324814,
        0.05461950972676277,
        -0.01023854874074459,
        0.03652927279472351,
        -0.023457510396838188,
        0.03181058540940285,
        -0.0537719763815403,
        -0.05739995837211609,
        0.05659952014684677,
        0.002786529017612338,
        -0.050036586821079254,
        -0.03784440457820892,
        0.028519900515675545,
        -0.054217010736465454,
        0.04760978743433952,
        0.03390401601791382,
        0.052040524780750275,
        0.04887358471751213,
        -0.05061197280883789,
        0.001318395952694118,
        0.009815038181841373,
        0.05612470954656601,
        0.058015383780002594,
        -0.04615820571780205,
        0.05797876417636871,
        -0.0032355235889554024,
        -0.010576683096587658,
        -0.02044987678527832,
        -0.038625963032245636,
        -0.014772428199648857,
        -0.051459889858961105,
        0.04206184297800064,
        -0.04928275942802429,
        -0.0042334431782364845,
        -0.05790869891643524,
        -0.028051048517227173,
        -0.057818058878183365,
        -0.05747857689857483,
        0.055293403565883636,
        0.010556097142398357,
        -0.03392766788601875,
        -0.04050286114215851,
        0.05427495762705803,
        -0.024943508207798004,
        -0.011275181546807289,
        -0.05784627050161362,
        0.05162676423788071,
        0.044162437319755554,
        0.057979609817266464,
        -0.027986599132418633,
        0.04701528698205948,
        0.058117642998695374,
        0.02652071788907051,
        0.05129564180970192,
        -0.04439288750290871,
        0.05439486727118492,
        -0.05776694044470787,
        0.05084819719195366,
        -0.025052161887288094,
        0.05159933492541313,
        0.046234264969825745,
        -0.04811904951930046,
        0.011851665563881397,
        0.040281765162944794,
        -0.05703587085008621,
        0.01883687451481819,
        -0.027333533391356468,
        0.005547866690903902,
        -0.043763790279626846,
        0.005150527227669954,
        -0.03016955405473709,
        -0.05735601484775543,
        -0.014805221930146217,
        0.05734448507428169,
        -0.053479935973882675,
        0.05801912024617195,
        0.058059509843587875,
        0.036149583756923676,
        -0.025922393426299095,
        -0.04790772125124931,
        0.05506599694490433,
        0.01569232903420925,
        -0.057913873344659805,
        -0.044035300612449646,
        0.04785427823662758,
        -0.04885999858379364,
        -0.026302946731448174,
        -0.021787889301776886,
        0.04270891472697258,
        -0.05088949203491211,
        -0.03251482546329498,
        -0.01159646175801754,
        0.057680919766426086,
        -0.05311238393187523,
        0.04412063583731651,
        0.0255621038377285,
        0.05510056018829346,
        -0.03944402188062668,
        -0.04164242744445801,
        -0.03703013062477112,
        0.04621381685137749,
        0.04521801322698593,
        0.05756988376379013,
        -0.051465101540088654,
        0.05586816743016243,
        -0.05345818027853966,
        -0.046778202056884766,
        0.055746663361787796,
        -0.05741338059306145,
        -0.05386178195476532,
        0.05712582543492317,
        -0.03818598762154579,
        -0.015192241407930851,
        0.03188614919781685,
        -0.00033980951411649585,
        -0.0570303276181221,
        0.05587144196033478,
        0.05373919755220413,
        -0.03625626489520073,
        0.05664747580885887,
        0.05308286473155022,
        -0.05197141692042351,
        -0.012793799862265587,
        -0.018721885979175568,
        -0.051469095051288605,
        -0.0579562783241272,
        -0.053467024117708206,
        0.023096324875950813,
        0.05807968229055405,
        -0.057838164269924164,
        0.02947632595896721,
        0.03635909780859947,
        -0.004909546114504337,
        0.01554885320365429,
        -0.01142191607505083,
        -0.05091796815395355,
        0.05629131197929382,
        0.023606186732649803,
        0.036186717450618744,
        -0.021143751218914986,
        0.04934927448630333,
        -0.007712670601904392,
        0.020227165892720222,
        -0.01291618775576353,
        -0.0464627742767334,
        0.01095147617161274,
        -0.05246589332818985,
        -0.0505218468606472,
        -0.0571153350174427,
        0.013719912618398666,
        -0.04355745390057564,
        -0.05013731122016907,
        0.05185623839497566,
        -0.05380267649888992,
        0.026235876604914665,
        -0.014726188033819199,
        0.057756856083869934,
        -0.054117463529109955,
        -0.022349515929818153,
        0.03028211183845997,
        -0.04307220131158829,
        0.05574160814285278,
        -0.008839016780257225,
        -0.05348827689886093,
        -0.05677773803472519,
        0.01915595307946205,
        0.03458372876048088,
        0.055565036833286285,
        -0.017893804237246513,
        -0.006073598749935627,
        0.050445038825273514,
        0.007530276197940111,
        -0.04362013190984726,
        0.051747143268585205,
        0.05625411495566368,
        0.020001055672764778,
        -0.04634261131286621,
        -0.05677377060055733,
        0.04974273964762688,
        0.04369768127799034,
        0.04726754128932953,
        0.055335305631160736,
        -0.04945764318108559,
        0.05697639286518097,
        -0.054035212844610214,
        0.05347882956266403,
        0.009137984365224838,
        0.044457968324422836,
        0.026087485253810883,
        -0.05621618777513504,
        -0.021265491843223572,
        0.029817283153533936,
        0.057884443551301956,
        0.05231945589184761,
        0.037231914699077606,
        -0.0413195975124836,
        0.05297432839870453,
        -0.04897404462099075,
        0.0579303614795208,
        -0.05792216584086418,
        -0.020893536508083344,
        0.056130506098270416,
        0.04076592996716499,
        -0.05726147070527077,
        -0.044974930584430695,
        -0.05623234808444977,
        -0.007133628707379103,
        0.047716740518808365,
        -0.04146631434559822,
        0.0574982613325119,
        0.057009391486644745,
        0.028762275353074074,
        0.053686078637838364,
        0.058065444231033325,
        -0.004893325734883547,
        -0.052507009357213974,
        -0.05807856097817421,
        -0.04296451434493065,
        0.05137528479099274,
        0.05113089084625244,
        -0.0054347277618944645,
        0.03533938527107239,
        0.0009578861645422876,
        -0.053263336420059204,
        0.02765866369009018,
        -0.05395679175853729,
        0.03889736160635948,
        -0.05706694349646568,
        0.007980353198945522,
        0.041297052055597305,
        -0.0546632781624794,
        -0.017342187464237213,
        0.05070733278989792,
        0.007668419275432825,
        -0.05690906569361687,
        -0.0115117896348238,
        0.0029054409824311733,
        0.05782361328601837,
        -0.049008939415216446,
        0.05724199488759041,
        -0.004872434306889772,
        0.05720987170934677,
        -0.05456314608454704,
        -0.0558871291577816,
        0.018900778144598007,
        -0.05716237798333168,
        0.0390758290886879,
        -0.05290555581450462,
        -0.05545453727245331,
        0.0392332524061203,
        -0.051637254655361176,
        0.05446448549628258,
        -0.04532289505004883,
        -0.05484437942504883,
        -0.0579003170132637,
        0.05587339028716087,
        -0.0511084608733654,
        -0.040751729160547256,
        0.034338854253292084,
        -0.03930368274450302,
        0.027151256799697876,
        0.0544433556497097,
        0.05694263055920601,
        0.05786508321762085,
        -0.03844498097896576,
        -0.04114321619272232,
        -0.05030086264014244,
        -0.0315219946205616,
        0.054638125002384186,
        -0.0576489195227623,
        0.05774104222655296,
        -0.0010215661022812128,
        -0.05796772241592407,
        0.057957328855991364,
        0.05262020230293274,
        0.0055159530602395535,
        -0.0004617622762452811,
        0.05357684940099716,
        0.04220861569046974,
        -0.019398454576730728,
        -0.04949735105037689,
        0.052966877818107605,
        0.05249112844467163,
        -0.007886280305683613,
        -0.010000362992286682,
        0.04778237268328667,
        0.03701959177851677,
        0.026886550709605217,
        0.026104062795639038,
        -0.05704573914408684,
        -0.00843180064111948,
        -0.05108044296503067,
        0.05748821794986725,
        -0.02507055550813675,
        -0.012086233124136925,
        0.030889322981238365,
        0.04496566578745842,
        0.020676378160715103,
        0.054764918982982635,
        0.0016204639105126262,
        0.055587202310562134,
        0.0539582222700119,
        -0.018915930762887,
        -0.054956238716840744,
        0.03839148208498955,
        -0.04663647338747978,
        -0.0421987920999527,
        0.04946458339691162,
        0.05781526863574982,
        0.0305162500590086,
        -0.010410115122795105,
        0.02122783288359642,
        0.05394330993294716,
        0.0575195848941803,
        -0.01740656979382038,
        -0.04314424470067024,
        0.024908259510993958,
        0.041840530931949615,
        0.05255543068051338,
        0.04818258807063103,
        0.05741766095161438,
        0.05793358385562897,
        -0.05509641766548157,
        0.05324186012148857,
        0.006257949396967888,
        -0.05253218114376068,
        0.038869086652994156,
        -0.035266607999801636,
        -0.057847000658512115,
        0.027593184262514114,
        -0.047666504979133606,
        -0.055963803082704544,
        -0.054886240512132645,
        -0.04677533730864525,
        0.03396770358085632,
        0.04105890169739723,
        -0.05221271514892578,
        -0.053240977227687836,
        0.022866303101181984,
        -0.054920848459005356,
        0.05776744708418846,
        -0.049116406589746475,
        0.012674801051616669,
        -0.05706043541431427,
        -0.0237980168312788,
        -0.02852552756667137,
        0.04939454421401024,
        0.05301928147673607,
        0.05573076009750366,
        0.05778978019952774,
        0.007921542972326279,
        -0.011845328845083714,
        0.04017604887485504,
        0.05647619813680649,
        0.053857214748859406,
        0.057355016469955444,
        -0.057714901864528656,
        -0.012096574530005455,
        0.04500860348343849,
        -0.0354556068778038,
        0.05017438158392906,
        0.012013303115963936,
        -0.05667987838387489,
        0.014390945434570312,
        -0.05800383538007736,
        0.04245411232113838,
        -0.0561370775103569,
        -0.05751357600092888,
        0.010854694992303848,
        0.05785461515188217,
        0.05811430886387825,
        -0.0005459811072796583,
        -0.048657145351171494,
        -0.05661151930689812,
        -0.03484772518277168,
        0.057969532907009125,
        -0.057988088577985764,
        0.055549170821905136,
        -0.052890051156282425,
        -0.023602453991770744,
        0.03736218437552452,
        -0.03130549564957619,
        0.05790744349360466,
        -0.019538599997758865,
        -0.054454974830150604,
        -0.046492159366607666,
        -0.012725439853966236,
        0.017040900886058807,
        0.05335202068090439,
        -0.05588887259364128,
        0.0526311919093132,
        0.04346601292490959,
        -0.01768912747502327,
        0.016538240015506744,
        0.05226362869143486,
        -0.05784425511956215,
        -0.05037079006433487,
        -0.05807369574904442,
        0.006447812542319298,
        -0.044572167098522186,
        -0.02180599793791771,
        0.004940901417285204,
        -0.02115306444466114,
        -0.05802444741129875,
        0.01573134772479534,
        0.015749746933579445,
        -0.04633156582713127,
        -0.048705752938985825,
        0.05589680373668671,
        0.00476237153634429,
        -0.05798393487930298,
        -0.00893099419772625,
        -0.05022871866822243,
        -0.03691912814974785,
        -0.05250134691596031,
        0.04941125959157944,
        0.014717045240104198,
        0.05711501091718674,
        0.053677093237638474,
        0.036858826875686646,
        0.05706604942679405,
        0.04110579937696457,
        0.0431397445499897,
        0.0577300563454628,
        0.04097649082541466,
        0.05681808292865753,
        0.0454823337495327,
        0.05716932192444801,
        -0.015876712277531624,
        -0.04627177119255066,
        -0.011572331190109253,
        -0.055470213294029236,
        0.028922762721776962,
        0.04804474487900734,
        -0.0023171482607722282,
        -0.052492428570985794,
        0.04553326219320297,
        -0.05649629607796669,
        0.05751194432377815,
        0.057990044355392456,
        -0.057000935077667236,
        0.05807705968618393,
        -0.05783242732286453,
        -0.05763459578156471,
        0.05796046927571297,
        0.03235674649477005,
        0.05644175782799721,
        0.04141155257821083,
        -0.05233057588338852,
        0.027681414037942886,
        0.04941724240779877,
        0.05091891065239906,
        -0.05515236780047417,
        -0.026577802374958992,
        0.05617525428533554,
        -0.013304177671670914,
        -0.03815379738807678,
        0.042035117745399475,
        -0.05802834406495094,
        0.05790397524833679,
        0.015616514720022678,
        0.029006008058786392,
        -0.05624004453420639,
        0.01904250495135784,
        0.05656319111585617,
        0.039471592754125595,
        0.05810009315609932,
        -0.0429920069873333,
        -0.020698200911283493,
        0.03152056038379669,
        -0.05390559136867523,
        -0.04190779849886894,
        0.05699322372674942,
        0.032834168523550034,
        0.0575934536755085,
        -0.0023155221715569496,
        0.018789641559123993,
        0.03421827405691147,
        0.046174220740795135,
        0.0005235478165559471,
        0.051998935639858246,
        -0.05557998642325401,
        0.05802376568317413,
        -0.057116035372018814,
        0.057158417999744415,
        0.024578770622611046
    ],
    [
        -0.047148510813713074,
        0.004584990441799164,
        -0.029640354216098785,
        0.04379376024007797,
        0.06000921502709389,
        -0.04882998764514923,
        0.06077092885971069,
        -0.05652410909533501,
        -0.006154351402074099,
        0.01880999468266964,
        0.0464395135641098,
        0.05973386764526367,
        -0.0585763156414032,
        -0.013118574395775795,
        -0.05948681756854057,
        -0.02556788921356201,
        -0.05510450899600983,
        0.019764261320233345,
        -0.05278325825929642,
        -0.04001791030168533,
        -0.03632011264562607,
        0.026754306629300117,
        0.060939643532037735,
        0.015922484919428825,
        0.013323304243385792,
        -0.01614987663924694,
        -0.029981590807437897,
        0.05104243755340576,
        -0.04322684556245804,
        0.006953525356948376,
        0.05865030363202095,
        -0.060155320912599564,
        -0.0016449682880192995,
        -0.06052963808178902,
        -0.02243785373866558,
        -0.05858907848596573,
        -0.050535403192043304,
        -0.048507656902074814,
        -0.05359183996915817,
        -0.016877533867955208,
        -0.03403761237859726,
        -0.010444846004247665,
        0.05014704540371895,
        -0.05886063352227211,
        0.053969986736774445,
        0.022844865918159485,
        0.0577048733830452,
        0.06046362966299057,
        0.059816956520080566,
        0.023890424519777298,
        -0.029016543179750443,
        -0.03897073119878769,
        0.06052129343152046,
        -0.0025572855956852436,
        -0.05593268945813179,
        0.024465151131153107,
        -0.058748796582221985,
        -0.016026465222239494,
        -0.053419314324855804,
        -0.03461400792002678,
        -0.05009126663208008,
        -0.0407627671957016,
        -0.05079219862818718,
        0.0049434988759458065,
        0.021393882110714912,
        0.05836135894060135,
        0.004838802386075258,
        -0.05623972415924072,
        0.023188002407550812,
        -0.06089949235320091,
        -0.00689913984388113,
        -0.06101930886507034,
        0.03555561229586601,
        -0.06001351401209831,
        0.05538279190659523,
        -0.017941413447260857,
        0.06033550202846527,
        0.05966337025165558,
        0.025914449244737625,
        -0.0324813649058342,
        -0.04674262925982475,
        -0.0160934217274189,
        -0.05682455748319626,
        0.0292634479701519,
        0.0522615984082222,
        0.0042512547224760056,
        -0.05318666622042656,
        -0.04114760458469391,
        -0.03994636610150337,
        0.027510054409503937,
        -0.05950193107128143,
        0.026225442066788673,
        0.038257937878370285,
        -0.044139765202999115,
        0.00272335740737617,
        0.0332643948495388,
        -0.027682997286319733,
        -0.05596577003598213,
        -0.05824209749698639,
        -0.03301644325256348,
        -0.06056566536426544,
        0.041854020208120346,
        0.05391242355108261,
        0.058563362807035446,
        0.010486993007361889,
        0.024570981040596962,
        0.05913134291768074,
        0.05042634159326553,
        -0.055259328335523605,
        0.01426553912460804,
        -0.045194361358881,
        -0.06039222329854965,
        -0.04144199937582016,
        0.03273749724030495,
        0.043965838849544525,
        -0.03611282631754875,
        -0.058790259063243866,
        -0.05769706144928932,
        -0.0598103329539299,
        -0.0034637395292520523,
        -0.02824372425675392,
        0.025228222832083702,
        -0.06036219373345375,
        0.044863756746053696,
        -0.060290802270174026,
        -0.01411511842161417,
        -0.023668896406888962,
        0.05262083187699318,
        0.05063355714082718,
        0.04429657384753227,
        -0.011918069794774055,
        -0.049034226685762405,
        -0.0053466977551579475,
        0.05443336069583893,
        -0.04081697389483452,
        -0.05180807784199715,
        0.042033035308122635,
        0.031652335077524185,
        -0.031024731695652008,
        0.05946074798703194,
        0.044142261147499084,
        0.05718933790922165,
        0.034967090934515,
        -0.06069466844201088,
        -0.046433351933956146,
        0.03158557415008545,
        0.022727234289050102,
        0.039599694311618805,
        -0.039889704436063766,
        0.051719825714826584,
        -0.02992679737508297,
        -0.05764096975326538,
        -0.05457639694213867,
        -0.05367507040500641,
        0.00675203837454319,
        -0.043862465769052505,
        0.06036146357655525,
        -0.051424283534288406,
        0.009442322887480259,
        -0.005356919020414352,
        0.060042835772037506,
        -0.002429491840302944,
        0.048417337238788605,
        0.0424596443772316,
        -0.06013898551464081,
        0.030094711109995842,
        0.05508521944284439,
        -0.017466232180595398,
        0.05203716456890106,
        0.05897321552038193,
        -0.05268483981490135,
        0.023106077685952187,
        -0.060821883380413055,
        -0.04015706107020378,
        -0.05376812815666199,
        0.058334384113550186,
        -0.05696853622794151,
        0.056690558791160583,
        0.054033104330301285,
        -0.0024122663307935,
        0.024025583639740944,
        -0.024682411924004555,
        0.0575040839612484,
        0.05874193087220192,
        0.0049745431169867516,
        0.04420324042439461,
        -0.048153556883335114,
        0.05904770642518997,
        -0.05609908699989319,
        -0.0006047135102562606,
        -0.03596572205424309,
        -0.03624875470995903,
        -0.05509089678525925,
        -0.05143335834145546,
        -0.018010281026363373,
        -0.03268690034747124,
        0.05751333758234978,
        -0.059436436742544174,
        -0.031907327473163605,
        -0.0035168523900210857,
        0.022606566548347473,
        -0.05839778855443001,
        0.033602599054574966,
        0.0478568971157074,
        0.013088098727166653,
        0.046778056770563126,
        -0.03372802957892418,
        0.04820971563458443,
        -0.05094621703028679,
        -0.06093428283929825,
        -0.055280596017837524,
        0.002299054991453886,
        -0.01159918587654829,
        0.008119913749396801,
        0.00047846834058873355,
        0.029950279742479324,
        0.06090635806322098,
        0.026539182290434837,
        0.060765352100133896,
        -0.019182618707418442,
        -0.011848744936287403,
        -0.017701607197523117,
        -0.031040791422128677,
        -0.060868099331855774,
        0.06005580350756645,
        -0.05914919450879097,
        0.03420240432024002,
        -0.0033153360709547997,
        0.03424479067325592,
        -0.038582976907491684,
        -0.04997539147734642,
        -0.045945487916469574,
        -0.017543846741318703,
        0.04636567085981369,
        -0.05925215035676956,
        0.04575209692120552,
        0.057884909212589264,
        -0.015531106851994991,
        0.049699801951646805,
        0.06099550798535347,
        0.009774893522262573,
        0.057936251163482666,
        -0.013897022232413292,
        0.035916030406951904,
        0.02307344228029251,
        -0.05881185829639435,
        0.05409197136759758,
        0.04192633926868439,
        -0.058510057628154755,
        -0.042973022907972336,
        -0.06008366867899895,
        -0.05872175842523575,
        -0.04941638559103012,
        -0.05745430663228035,
        0.021874956786632538,
        0.05507081374526024,
        -0.05453701317310333,
        -0.01938062720000744,
        0.059884484857320786,
        0.05359417572617531,
        -0.010758251883089542,
        0.055645860731601715,
        -0.032273758202791214,
        0.055730681866407394,
        -0.01794491894543171,
        0.05792165920138359,
        0.013316122815012932,
        0.0434034988284111,
        0.050190068781375885,
        -0.05991221219301224,
        -0.056443750858306885,
        -0.05910203978419304,
        -0.05507390946149826,
        -0.06078887730836868,
        -0.0017397081246599555,
        -0.010352895595133305,
        -0.05557038262486458,
        0.05501555651426315,
        0.04554951936006546,
        0.046043552458286285,
        -0.060824599117040634,
        -0.06070753186941147,
        0.04135339334607124,
        -0.0028955149464309216,
        0.03508073836565018,
        0.0028042958583682775,
        0.058770421892404556,
        -0.06088889390230179,
        -0.057023368775844574,
        0.04810614883899689,
        -0.04035615921020508,
        0.05790753662586212,
        -0.00928027555346489,
        -0.05227729305624962,
        0.043040595948696136,
        -0.05039309710264206,
        0.05924607068300247,
        0.020351359620690346,
        -0.020995447412133217,
        0.05275532603263855,
        -0.05328098684549332,
        0.0575626976788044,
        0.03407156839966774,
        0.052116405218839645,
        0.04035121947526932,
        -0.04574817046523094,
        -0.031955838203430176,
        0.03089955449104309,
        0.04933636635541916,
        -0.05488128960132599,
        0.04741427302360535,
        -0.044685449451208115,
        -0.04727677255868912,
        0.05717965215444565,
        -0.05641951411962509,
        -0.05655200406908989,
        0.05893446132540703,
        0.058744993060827255,
        0.04040573164820671,
        -0.05654062330722809,
        -0.059815943241119385,
        0.01352596003562212,
        0.05162839591503143,
        -0.004306212067604065,
        0.054496005177497864,
        0.05274232104420662,
        0.052529871463775635,
        0.010472993366420269,
        0.010873351246118546,
        0.032918207347393036,
        -0.013241944834589958,
        0.03374641388654709,
        0.01933065615594387,
        -0.060165274888277054,
        0.052154041826725006,
        0.02516140043735504,
        -0.0450127050280571,
        0.059048641473054886,
        -0.05749036371707916,
        0.05057496204972267,
        -0.0219979640096426,
        -0.01974470727145672,
        -0.056391071528196335,
        -0.0576971136033535,
        0.061065707355737686,
        0.048699162900447845,
        0.01260550320148468,
        0.0390671044588089,
        0.059596896171569824,
        0.043430157005786896,
        -0.05726421996951103,
        -0.02590275928378105,
        -0.040968939661979675,
        -0.053873658180236816,
        -0.05907078832387924,
        0.05326676368713379,
        0.02211027778685093,
        -0.016340719535946846,
        0.004163590259850025,
        -0.039784736931324005,
        0.052154507488012314,
        0.04084967449307442,
        -0.058284398168325424,
        -0.04753568023443222,
        0.058015815913677216,
        -0.021973932161927223,
        0.06098354980349541,
        0.03360103443264961,
        0.05172063410282135,
        -0.06095277890563011,
        -0.04189014807343483,
        -0.060556989163160324,
        0.060027677565813065,
        0.027503937482833862,
        0.05987980216741562,
        0.06073213368654251,
        0.01552894152700901,
        -0.002762445015832782,
        -0.02159971371293068,
        -0.03554946184158325,
        -0.014746255241334438,
        0.03970354050397873,
        -0.05678935721516609,
        -0.020054442808032036,
        0.04206788167357445,
        -0.016879336908459663,
        0.056607604026794434,
        -0.059946708381175995,
        0.03030693158507347,
        -0.038055505603551865,
        -0.05304226279258728,
        0.05386556684970856,
        0.010517562739551067,
        0.022233659401535988,
        -0.018486497923731804,
        0.05488091707229614,
        0.037860769778490067,
        -0.006453968584537506,
        0.02537890523672104,
        -0.060137927532196045,
        -0.00301698362454772,
        0.021118171513080597,
        0.056500889360904694,
        0.05183959752321243,
        -0.05954637750983238,
        -0.042152874171733856,
        0.058444876223802567,
        0.007102654781192541,
        0.043097686022520065,
        -0.047717563807964325,
        0.057292502373456955,
        -0.005648955702781677,
        -0.01502866018563509,
        -0.004559560678899288,
        0.06062754988670349,
        -0.05068039894104004,
        0.02347509004175663,
        0.03567466884851456,
        0.020972581580281258,
        0.05466124042868614,
        -0.0575406514108181,
        -0.058220624923706055,
        -0.022618452087044716,
        -0.057406503707170486,
        0.0595579519867897,
        -0.05700000002980232,
        -0.05576572194695473,
        0.05959894880652428,
        -0.0597173273563385,
        -0.030301736667752266,
        0.060539767146110535,
        -0.022389760240912437,
        -0.003939882852137089,
        0.002538879169151187,
        0.05046301707625389,
        -0.05156504362821579,
        -0.03234655782580376,
        0.0027702876832336187,
        -0.015632783994078636,
        -0.018562551587820053,
        0.0490419827401638,
        0.04986261948943138,
        0.05970288813114166,
        0.04700082540512085,
        -0.029525909572839737,
        -0.011972330510616302,
        0.0585506334900856,
        -0.05743632838129997,
        0.060028113424777985,
        -0.03788504749536514,
        -0.05905190482735634,
        0.055258095264434814,
        0.06094851717352867,
        -0.050758976489305496,
        0.018768519163131714,
        -0.0473954863846302,
        -0.02570357918739319,
        0.04536619037389755,
        -0.03485944867134094,
        0.05986254662275314,
        0.048854369670152664,
        0.05984324961900711,
        -0.060623377561569214,
        -0.05630304291844368,
        0.060813356190919876,
        0.06045565754175186,
        -0.06045931950211525,
        0.06048201024532318,
        -0.05418018996715546,
        -0.06082601472735405,
        -0.00934232585132122,
        -0.013842977583408356,
        0.040435709059238434,
        0.058472443372011185,
        0.05798441171646118,
        0.031154697760939598,
        0.010225426405668259,
        -0.024917185306549072,
        -0.054298728704452515,
        -0.053623266518116,
        -0.029809892177581787,
        -0.030281517654657364,
        -0.02919154055416584,
        0.05246267095208168,
        -0.03320250287652016,
        -0.0030824642162770033,
        0.041156478226184845,
        -0.04268794134259224,
        -0.04490434005856514,
        -0.026359817013144493,
        -0.007251709699630737,
        -0.013894349336624146,
        0.03771287575364113,
        -0.041595201939344406,
        0.060300786048173904,
        0.02864866331219673,
        -0.042907122522592545,
        0.025141192600131035,
        0.0566006600856781,
        0.041182417422533035,
        0.007435413543134928,
        -0.03862442821264267,
        0.012463943101465702,
        0.001528500928543508,
        -0.0006980896578170359,
        0.01357484795153141,
        -0.01614568568766117,
        -0.015645278617739677,
        -0.050374966114759445,
        -0.05973675474524498,
        0.05120614543557167,
        0.06020951643586159
    ],
    [
        -0.04361599683761597,
        -0.04799201712012291,
        0.05577428266406059,
        -0.0007519278442487121,
        0.05752908065915108,
        -0.05193531885743141,
        0.062302734702825546,
        -0.04038754850625992,
        0.005274598486721516,
        -0.0016511705471202731,
        0.02818836085498333,
        0.026259824633598328,
        -0.057159796357154846,
        -0.04446044936776161,
        -0.06042708456516266,
        0.040580347180366516,
        -0.06105189397931099,
        0.05136745423078537,
        -0.06137612834572792,
        0.05449746549129486,
        -0.05181349068880081,
        0.04721524566411972,
        0.05455493927001953,
        0.010923733003437519,
        0.027223927900195122,
        0.04248243570327759,
        -0.017025193199515343,
        0.06096547842025757,
        -0.008521664887666702,
        -0.04736004397273064,
        0.053084418177604675,
        -0.006590334232896566,
        0.026197144761681557,
        -0.03923357278108597,
        0.020319784060120583,
        0.014472628943622112,
        -0.05800072103738785,
        -0.039494387805461884,
        0.04748188331723213,
        0.05990000441670418,
        0.053542811423540115,
        -0.009165163151919842,
        0.06069004535675049,
        -0.06002538651227951,
        -0.03329536318778992,
        0.04142307862639427,
        0.03943801671266556,
        0.06234043091535568,
        0.016463203355669975,
        0.016859062016010284,
        0.03437025845050812,
        0.018492471426725388,
        0.051689159125089645,
        -0.006448667962104082,
        0.027229852974414825,
        -0.032828107476234436,
        -0.053508736193180084,
        0.013123242184519768,
        -0.024833692237734795,
        0.02497355081140995,
        -0.05606483295559883,
        0.052769605070352554,
        -0.042033933103084564,
        0.04144376143813133,
        0.04147346690297127,
        0.014916496351361275,
        0.05869737267494202,
        -0.060289472341537476,
        0.04999959096312523,
        -0.06235290318727493,
        -0.05477330461144447,
        -0.05471951887011528,
        0.03960913419723511,
        0.05115712806582451,
        -0.059805162250995636,
        0.04292730242013931,
        0.05695973336696625,
        0.05945464223623276,
        0.04832851514220238,
        -0.03953380137681961,
        -0.058002155274152756,
        0.02391146682202816,
        -0.046007897704839706,
        0.0031904769130051136,
        0.025284763425588608,
        -0.02523467130959034,
        -0.020045507699251175,
        0.007319221738725901,
        -0.06001626327633858,
        0.059769537299871445,
        -0.043590519577264786,
        -0.050264421850442886,
        0.011871671304106712,
        -0.059009622782468796,
        0.04746731370687485,
        -0.03859814256429672,
        -0.05484972149133682,
        -0.0558355413377285,
        -0.059048205614089966,
        -0.025135904550552368,
        -0.06177251413464546,
        -0.022777507081627846,
        0.05724366381764412,
        0.052738532423973083,
        -0.021839840337634087,
        -0.01705402508378029,
        -0.031730495393276215,
        0.0286865197122097,
        -0.04948117583990097,
        0.03165837749838829,
        -0.04623256251215935,
        -0.061781931668519974,
        -0.0476091206073761,
        -0.05303451046347618,
        0.04408569261431694,
        -0.060406941920518875,
        -0.04349634423851967,
        -0.055062342435121536,
        -0.061865728348493576,
        -0.024930117651820183,
        -0.04787514731287956,
        -0.05485096201300621,
        -0.020650628954172134,
        0.05931567773222923,
        -0.062270987778902054,
        -0.0327671580016613,
        -0.05830581113696098,
        0.059237077832221985,
        0.05218476057052612,
        -0.026435840874910355,
        -0.03689137101173401,
        -0.04646562784910202,
        0.011361018754541874,
        0.04700757935643196,
        -0.05555145442485809,
        -0.04135631397366524,
        0.03618971258401871,
        -0.03480864316225052,
        -0.05279522016644478,
        0.053041744977235794,
        -0.00006948986265342683,
        0.040526311844587326,
        0.03592943400144577,
        0.049244582653045654,
        -0.02094123512506485,
        0.02885863184928894,
        0.0616883784532547,
        -0.05323510989546776,
        -0.04354414343833923,
        0.045342545956373215,
        -0.05987260490655899,
        -0.0621439665555954,
        -0.05570248141884804,
        0.020105019211769104,
        0.038339126855134964,
        -0.06046725809574127,
        0.05847105383872986,
        -0.044047873467206955,
        -0.024641843512654305,
        -0.05861111357808113,
        0.05965788662433624,
        0.04247415065765381,
        0.05471285060048103,
        -0.02618168480694294,
        -0.05834626033902168,
        -0.048215799033641815,
        0.003178966697305441,
        -0.04316361993551254,
        0.008066337555646896,
        0.045123059302568436,
        -0.005248406436294317,
        0.02020149864256382,
        -0.05984527990221977,
        -0.01706123538315296,
        -0.03862537071108818,
        0.0490105077624321,
        -0.06018546596169472,
        0.027638642117381096,
        0.06203558295965195,
        0.053932931274175644,
        0.045808181166648865,
        0.03038172423839569,
        0.059938959777355194,
        -0.03584799915552139,
        0.028851181268692017,
        0.020340008661150932,
        -0.026632439345121384,
        0.06084569916129112,
        -0.05631551891565323,
        -0.038165513426065445,
        -0.04230561852455139,
        -0.053548578172922134,
        -0.03136815130710602,
        0.011302163824439049,
        0.045353882014751434,
        0.008025598712265491,
        0.055959202349185944,
        0.044605039060115814,
        0.004500323440879583,
        0.028758587315678596,
        -0.0440557561814785,
        0.055395517498254776,
        0.02926105074584484,
        -0.013559478335082531,
        0.019168339669704437,
        0.031131286174058914,
        0.0495992936193943,
        0.023658862337470055,
        -0.061191800981760025,
        0.017709126695990562,
        -0.06136739253997803,
        -0.0490608774125576,
        0.01696748472750187,
        -0.01629279926419258,
        0.056431129574775696,
        -0.023121168836951256,
        -0.010764092206954956,
        0.0241810604929924,
        0.06198452413082123,
        -0.028705254197120667,
        -0.040238089859485626,
        -0.05907146632671356,
        -0.04541316628456116,
        -0.061785582453012466,
        0.0601954460144043,
        -0.060702018439769745,
        0.04307949170470238,
        -0.017404280602931976,
        -0.06010802090167999,
        -0.058405764400959015,
        -0.062291596084833145,
        -0.049960341304540634,
        -0.02500065229833126,
        0.031555820256471634,
        -0.02986331097781658,
        -0.03429105877876282,
        0.056526124477386475,
        -0.03410946950316429,
        0.012839537113904953,
        0.05989532172679901,
        0.04190688952803612,
        0.044023334980010986,
        0.0559203214943409,
        0.02321000210940838,
        -0.037127785384655,
        -0.018508508801460266,
        0.015779633074998856,
        0.008564847521483898,
        -0.03200266510248184,
        0.049156323075294495,
        -0.0441473163664341,
        -0.061309006065130234,
        -0.020657407119870186,
        -0.05966360494494438,
        0.01304884348064661,
        0.06005358695983887,
        -0.0416768342256546,
        0.007765448652207851,
        0.06157678738236427,
        0.055323366075754166,
        0.03301404416561127,
        0.036959413439035416,
        0.012976106256246567,
        0.05075480788946152,
        -0.022657355293631554,
        0.04596427455544472,
        0.04551773518323898,
        0.010732946917414665,
        -0.042626526206731796,
        -0.03396575152873993,
        -0.03568047285079956,
        -0.06190827488899231,
        -0.0005130245117470622,
        -0.059979427605867386,
        -0.05644095689058304,
        0.05936722457408905,
        -0.06045183539390564,
        -0.034271009266376495,
        -0.0389728844165802,
        0.0036808184813708067,
        -0.057858627289533615,
        0.03539976477622986,
        -0.04903511330485344,
        0.03030470199882984,
        -0.03556875139474869,
        -0.017606735229492188,
        0.020836273208260536,
        -0.045190293341875076,
        -0.058349475264549255,
        -0.012751743197441101,
        -0.06102854385972023,
        0.024069413542747498,
        0.05494716018438339,
        -0.011742918752133846,
        0.05970398336648941,
        -0.05682506412267685,
        0.06231405958533287,
        0.05473395809531212,
        -0.051290422677993774,
        0.05635356903076172,
        -0.01940501667559147,
        0.05333175137639046,
        -0.03623592108488083,
        0.03438759595155716,
        -0.04405279457569122,
        0.012538550421595573,
        -0.05049724131822586,
        0.02447178214788437,
        0.06032085418701172,
        -0.059070754796266556,
        0.04564172029495239,
        0.027700362727046013,
        -0.05011028051376343,
        0.06106967106461525,
        -0.06185000389814377,
        -0.0585523284971714,
        0.06171860173344612,
        -0.011034870520234108,
        0.011225533671677113,
        -0.02980544976890087,
        -0.057030946016311646,
        -0.02314176969230175,
        0.05740316957235336,
        -0.04572886973619461,
        0.05713551118969917,
        0.0577615387737751,
        0.025563206523656845,
        0.03775700181722641,
        0.025655288249254227,
        -0.06083959713578224,
        -0.028361523523926735,
        -0.04288802295923233,
        -0.0026724243070930243,
        -0.039576273411512375,
        0.06141526997089386,
        0.028317317366600037,
        -0.056822940707206726,
        0.06229950115084648,
        0.02149019204080105,
        0.05398277938365936,
        0.020398691296577454,
        0.012486708350479603,
        -0.052206266671419144,
        -0.060309264808893204,
        0.06237328425049782,
        0.053623467683792114,
        0.032461922615766525,
        0.04425825923681259,
        0.020088430494070053,
        0.05070851743221283,
        -0.06159210577607155,
        -0.013441010378301144,
        -0.04489481449127197,
        -0.0617937333881855,
        -0.05403248965740204,
        0.04492710158228874,
        0.009056700393557549,
        0.015065766870975494,
        -0.06021291762590408,
        -0.06080978736281395,
        0.05697443336248398,
        0.05860999599099159,
        -0.05799221247434616,
        -0.03558517247438431,
        0.03899392858147621,
        -0.05443795397877693,
        0.06179675832390785,
        0.013718152418732643,
        0.007501743733882904,
        -0.0622740164399147,
        -0.048695433884859085,
        -0.06209298223257065,
        0.03844587132334709,
        0.05629909038543701,
        0.05644422024488449,
        0.060784149914979935,
        -0.006343863904476166,
        -0.024563293904066086,
        0.0256116334348917,
        -0.031276606023311615,
        -0.06023580953478813,
        0.06100011244416237,
        -0.057983290404081345,
        -0.004586890339851379,
        0.059860408306121826,
        0.0027515320107340813,
        -0.016454264521598816,
        -0.06196359544992447,
        -0.04925275593996048,
        -0.03225664421916008,
        0.031175529584288597,
        0.049826111644506454,
        0.019887588918209076,
        0.03212437778711319,
        -0.004987606778740883,
        0.059674378484487534,
        0.03700479865074158,
        -0.04792852699756622,
        0.0434851199388504,
        -0.06110469996929169,
        0.025167493149638176,
        0.029067037627100945,
        0.05503460764884949,
        0.062083784490823746,
        -0.052314262837171555,
        -0.03874322399497032,
        0.05313929542899132,
        0.013756483793258667,
        0.04915540665388107,
        -0.03417166322469711,
        0.04850763455033302,
        -0.050256840884685516,
        0.05669773742556572,
        -0.020261606201529503,
        0.05933443084359169,
        -0.014044875279068947,
        -0.030521046370267868,
        -0.03643404692411423,
        0.05758392810821533,
        0.05126842483878136,
        -0.05417700856924057,
        -0.04973137751221657,
        -0.05623693764209747,
        -0.04886636883020401,
        0.029621437191963196,
        -0.013356437906622887,
        -0.02825959399342537,
        -0.046824704855680466,
        -0.06179117411375046,
        0.02241266705095768,
        0.055183835327625275,
        0.013706172816455364,
        0.031372733414173126,
        0.04746058210730553,
        0.056300390511751175,
        -0.002646639244630933,
        -0.04858781769871712,
        -0.007670335937291384,
        0.010043888352811337,
        -0.0587715245783329,
        0.01797610893845558,
        -0.03658180683851242,
        0.054576147347688675,
        0.026936473324894905,
        0.021533174440264702,
        0.05174833536148071,
        0.05773860216140747,
        -0.04644104838371277,
        0.05928396061062813,
        -0.05128210410475731,
        -0.04072737693786621,
        0.017982739955186844,
        0.05789452791213989,
        -0.05125142261385918,
        0.033584896475076675,
        -0.01356321107596159,
        -0.008035429753363132,
        0.05903814733028412,
        0.00876024179160595,
        -0.040376581251621246,
        0.039480436593294144,
        0.04284528270363808,
        -0.06193147972226143,
        -0.058050818741321564,
        0.05666489526629448,
        0.0619887076318264,
        -0.06233280897140503,
        0.061732467263936996,
        -0.04385651275515556,
        -0.062059685587882996,
        0.007046104874461889,
        -0.03126027062535286,
        0.057127755135297775,
        0.03937895968556404,
        0.06154584884643555,
        0.049268584698438644,
        -0.011224974878132343,
        -0.04977724328637123,
        0.026700986549258232,
        -0.05903574079275131,
        -0.05633716657757759,
        -0.005907860584557056,
        -0.0067176599986851215,
        -0.011149308644235134,
        -0.03515010327100754,
        0.017648344859480858,
        0.04225596785545349,
        -0.04370836913585663,
        -0.05522008240222931,
        -0.03576941415667534,
        -0.05200713500380516,
        -0.014482649974524975,
        -0.014430093578994274,
        0.022034158930182457,
        0.04596079885959625,
        0.03988014906644821,
        -0.05181924253702164,
        0.015544848516583443,
        0.060356542468070984,
        0.043260104954242706,
        -0.038752298802137375,
        -0.044195059686899185,
        -0.025643115863204002,
        0.04982339218258858,
        0.042560093104839325,
        0.056398939341306686,
        0.004351763986051083,
        -0.027265718206763268,
        -0.061323728412389755,
        -0.057651303708553314,
        0.06024195998907089,
        0.032859139144420624
    ],
    [
        -0.04212874919176102,
        -0.04509635269641876,
        -0.049037057906389236,
        -0.04449386149644852,
        0.033473823219537735,
        -0.04489509016275406,
        0.05568762868642807,
        -0.04487204551696777,
        0.018925413489341736,
        0.04650869593024254,
        0.05702092498540878,
        0.05042858049273491,
        -0.04698502644896507,
        0.009125514887273312,
        -0.04012816399335861,
        0.04434875398874283,
        -0.008051469922065735,
        0.033892951905727386,
        -0.04053188115358353,
        0.05605201795697212,
        0.05642560496926308,
        -0.030011367052793503,
        0.05059928074479103,
        -0.02242516353726387,
        0.01139660645276308,
        0.035535044968128204,
        -0.055212847888469696,
        0.0492384172976017,
        -0.04118131101131439,
        -0.04597821831703186,
        0.0484955720603466,
        0.038802746683359146,
        -0.008029697462916374,
        -0.04192636162042618,
        0.05655596777796745,
        -0.048373639583587646,
        -0.03537438064813614,
        0.04805821552872658,
        -0.04046224430203438,
        0.020541159436106682,
        -0.05067063122987747,
        -0.041622746735811234,
        0.013207643292844296,
        -0.05407698079943657,
        0.016608253121376038,
        0.006315449718385935,
        0.04997503012418747,
        0.051923129707574844,
        0.05356404185295105,
        0.027803106233477592,
        0.05696411803364754,
        -0.0008676312863826752,
        0.034486204385757446,
        -0.050115324556827545,
        0.04907539114356041,
        -0.05087864771485329,
        -0.0034903984051197767,
        -0.049231186509132385,
        -0.055124204605817795,
        -0.05676082894206047,
        -0.05665335804224014,
        0.022575143724679947,
        -0.054544076323509216,
        0.04268132895231247,
        0.05668065324425697,
        0.004740700125694275,
        -0.05520918220281601,
        0.03422391787171364,
        0.016686461865901947,
        -0.05437391251325607,
        0.004462947603315115,
        -0.05650915950536728,
        0.0571163073182106,
        0.011474264785647392,
        0.003320276038721204,
        0.02057739533483982,
        0.0021687049884349108,
        0.05703538656234741,
        0.030566202476620674,
        -0.050904348492622375,
        -0.02387935295701027,
        -0.05690351128578186,
        -0.03972361236810684,
        0.05658305063843727,
        -0.0056574721820652485,
        0.029419025406241417,
        -0.056746892631053925,
        -0.05331137031316757,
        0.05395160987973213,
        0.057089004665613174,
        0.05468766391277313,
        -0.04987361282110214,
        0.04963545501232147,
        -0.05565140023827553,
        -0.05072101950645447,
        -0.055620577186346054,
        -0.05316412076354027,
        -0.05511173605918884,
        0.021193228662014008,
        0.05680966004729271,
        -0.023472385480999947,
        0.05100834742188454,
        0.05257574841380119,
        -0.023000488057732582,
        0.047692976891994476,
        0.023367734625935555,
        -0.05368615314364433,
        0.017459256574511528,
        -0.05664294958114624,
        0.03527285158634186,
        0.028561532497406006,
        0.003217546734958887,
        -0.048095423728227615,
        -0.0012088622897863388,
        0.01656229794025421,
        -0.054709553718566895,
        -0.051440365612506866,
        -0.02328483760356903,
        0.02623230405151844,
        -0.051881831139326096,
        0.04944370687007904,
        -0.05250248312950134,
        -0.05367579683661461,
        -0.05332285165786743,
        -0.04072260484099388,
        -0.028181826695799828,
        0.04535329341888428,
        0.004756505601108074,
        -0.01898210123181343,
        0.05656629800796509,
        0.020611396059393883,
        -0.034976325929164886,
        -0.031711313873529434,
        0.00951128639280796,
        0.00006382942956406623,
        -0.05510792136192322,
        0.04558870941400528,
        0.03717263042926788,
        -0.056626953184604645,
        0.05708516016602516,
        0.05608651787042618,
        0.051051974296569824,
        0.023172641173005104,
        -0.04611024260520935,
        -0.05291823670268059,
        0.056382663547992706,
        -0.055637747049331665,
        0.056564897298812866,
        -0.05290185660123825,
        0.048429060727357864,
        -0.002617150079458952,
        -0.05431412160396576,
        -0.045528095215559006,
        0.05410902574658394,
        0.03130003437399864,
        -0.005792209878563881,
        -0.03144708648324013,
        0.044491641223430634,
        0.05631144717335701,
        -0.05438711866736412,
        0.010643488727509975,
        0.03698938339948654,
        0.05328968167304993,
        0.055486734956502914,
        0.026467349380254745,
        0.033203188329935074,
        -0.017630601301789284,
        0.03185408562421799,
        0.05606060475111008,
        0.05532989650964737,
        -0.05661441385746002,
        0.05608072876930237,
        -0.05687354505062103,
        0.02609819732606411,
        -0.04723392799496651,
        -0.05707428604364395,
        -0.02376551367342472,
        0.056562524288892746,
        0.02896047942340374,
        -0.05173458904027939,
        -0.005610217805951834,
        -0.051572661846876144,
        -0.04188934341073036,
        0.0036277459003031254,
        0.021633798256516457,
        0.05580447241663933,
        0.016542432829737663,
        0.05631083995103836,
        0.02848816104233265,
        -0.03385935723781586,
        -0.056916918605566025,
        0.04587612301111221,
        -0.049755893647670746,
        0.00816294178366661,
        -0.05529202148318291,
        0.044341932982206345,
        0.05668066069483757,
        -0.0008016637293621898,
        -0.05220234766602516,
        -0.02472064085304737,
        0.05689306929707527,
        0.03448520600795746,
        -0.05566412955522537,
        -0.016649750992655754,
        -0.056540071964263916,
        0.056998856365680695,
        0.047072529792785645,
        0.05292549729347229,
        -0.04726860672235489,
        0.04395038262009621,
        -0.051023028790950775,
        0.05502445623278618,
        0.05541760101914406,
        0.056334059685468674,
        -0.03580046072602272,
        -0.04829932376742363,
        0.05668264999985695,
        -0.055767279118299484,
        0.05685354024171829,
        -0.053281351923942566,
        -0.05683258920907974,
        0.052554111927747726,
        -0.05429080128669739,
        -0.05697374790906906,
        0.055876344442367554,
        -0.05315643548965454,
        0.047038331627845764,
        -0.055731646716594696,
        -0.05332241579890251,
        -0.05242958664894104,
        -0.029419617727398872,
        -0.054149702191352844,
        -0.009539120830595493,
        0.04715511202812195,
        -0.0003669196739792824,
        0.04701664298772812,
        0.040329061448574066,
        0.056587450206279755,
        -0.05279289931058884,
        0.056558459997177124,
        0.01389242336153984,
        0.04475383460521698,
        -0.056962791830301285,
        0.017202122136950493,
        0.03843940421938896,
        -0.013926039449870586,
        0.055528219789266586,
        0.021948914974927902,
        0.05237236246466637,
        -0.05451907590031624,
        -0.0036117795389145613,
        -0.05368610471487045,
        -0.03496607020497322,
        -0.0564655177295208,
        0.057006169110536575,
        0.05261563882231712,
        -0.03227263689041138,
        -0.05362800881266594,
        0.015931924805045128,
        -0.04425494745373726,
        -0.045007817447185516,
        0.01265961118042469,
        0.04268064722418785,
        0.05673551559448242,
        0.05664350092411041,
        0.05518805980682373,
        0.05327460542321205,
        -0.043948784470558167,
        0.015232238918542862,
        0.04640607908368111,
        0.05278899148106575,
        0.029616612941026688,
        -0.03796081244945526,
        -0.056761033833026886,
        -0.038010839372873306,
        -0.018139537423849106,
        -0.047412022948265076,
        0.011099053546786308,
        0.05117293819785118,
        0.04505108669400215,
        -0.057096902281045914,
        -0.0559065043926239,
        -0.049400828778743744,
        -0.04354120418429375,
        0.05350808426737785,
        0.01578381471335888,
        0.044590409845113754,
        0.030925782397389412,
        0.007497737649828196,
        0.039643295109272,
        -0.04129359498620033,
        -0.010925727896392345,
        -0.0034957234747707844,
        -0.013619591481983662,
        0.054612427949905396,
        -0.053294915705919266,
        0.05655529722571373,
        -0.036456845700740814,
        0.029144128784537315,
        0.04458595812320709,
        -0.028225451707839966,
        0.05410538241267204,
        -0.007331643719226122,
        0.05605265498161316,
        0.05690426751971245,
        0.0006941603496670723,
        -0.05119161680340767,
        -0.016766438260674477,
        -0.023050133138895035,
        -0.057011958211660385,
        -0.056962866336107254,
        0.04532448947429657,
        0.04914872348308563,
        0.049989841878414154,
        0.052227359265089035,
        -0.05376472324132919,
        -0.012287478893995285,
        0.05671412870287895,
        0.05587920919060707,
        -0.03491692617535591,
        0.031223874539136887,
        0.05059407651424408,
        0.04832419380545616,
        0.04174496978521347,
        0.022829797118902206,
        0.040969692170619965,
        0.04919866472482681,
        -0.05693618953227997,
        -0.04716106131672859,
        -0.050009965896606445,
        0.0533493272960186,
        0.051887549459934235,
        0.003910580184310675,
        0.03220009058713913,
        0.052847906947135925,
        0.05691542476415634,
        -0.012448770925402641,
        0.014854292385280132,
        0.05434592440724373,
        -0.04269557073712349,
        -0.0038048839196562767,
        0.02185238152742386,
        0.028167573735117912,
        0.038048747926950455,
        0.05583567917346954,
        0.012949066236615181,
        0.034550998359918594,
        0.05695772171020508,
        -0.05208756774663925,
        0.050400059670209885,
        0.0440213680267334,
        0.05594349652528763,
        0.026303807273507118,
        0.025538166984915733,
        -0.023110343143343925,
        0.03906458988785744,
        -0.025771792978048325,
        -0.052234094589948654,
        -0.056410323828458786,
        -0.05466102436184883,
        0.019816897809505463,
        0.05292347073554993,
        -0.05558496341109276,
        -0.03583282604813576,
        -0.05533810332417488,
        -0.05588642507791519,
        0.055319249629974365,
        -0.050556033849716187,
        0.03506102040410042,
        -0.05385320261120796,
        0.037668243050575256,
        -0.005644508637487888,
        0.03191283345222473,
        -0.05278322100639343,
        0.05553026124835014,
        0.05711916834115982,
        0.014448507688939571,
        -0.04221045598387718,
        -0.029477495700120926,
        0.049896981567144394,
        -0.03247566148638725,
        0.055365849286317825,
        0.0052383895963430405,
        0.05506143718957901,
        0.03591303899884224,
        0.018490020185709,
        0.05332231894135475,
        0.026365306228399277,
        -0.05475452542304993,
        -0.045582666993141174,
        0.00530994264408946,
        0.03690360486507416,
        -0.04414158686995506,
        0.05687056854367256,
        0.04907337948679924,
        0.05621203035116196,
        0.054395295679569244,
        -0.046622227877378464,
        -0.053552474826574326,
        -0.05641951411962509,
        -0.054443396627902985,
        0.05713788792490959,
        0.0209308173507452,
        0.05530249699950218,
        -0.012967185117304325,
        0.056813210248947144,
        0.05704299360513687,
        -0.05613880977034569,
        0.038461435586214066,
        0.007555958814918995,
        0.056152258068323135,
        -0.05155911296606064,
        0.05578911677002907,
        -0.046943824738264084,
        0.05418633669614792,
        -0.0527212917804718,
        -0.006625915877521038,
        -0.03915000706911087,
        0.027377137914299965,
        0.0535258874297142,
        0.0046165804378688335,
        -0.045326266437768936,
        0.024368084967136383,
        -0.05702577158808708,
        -0.05663840100169182,
        0.0009822221472859383,
        -0.051070068031549454,
        -0.056922122836112976,
        0.054550331085920334,
        -0.04790579527616501,
        0.04450790956616402,
        0.04077561944723129,
        -0.014706586487591267,
        -0.05462309345602989,
        -0.03875313699245453,
        0.03286166116595268,
        -0.05601200833916664,
        0.04451476037502289,
        -0.05092114582657814,
        -0.02049504779279232,
        -0.054803065955638885,
        -0.04085585102438927,
        0.056574370712041855,
        0.05704725533723831,
        -0.05339035019278526,
        -0.026012903079390526,
        0.011816193349659443,
        -0.056863028556108475,
        0.0425141416490078,
        0.00799647532403469,
        0.03230035677552223,
        -0.012349704280495644,
        -0.030990179628133774,
        0.025779036805033684,
        0.05695200338959694,
        -0.02835032157599926,
        0.0032431231811642647,
        -0.047489460557699203,
        0.047481995075941086,
        -0.041989557445049286,
        0.0475291907787323,
        -0.056998346000909805,
        -0.006609366275370121,
        -0.056804023683071136,
        0.05607238784432411,
        0.05681220442056656,
        -0.029153985902667046,
        0.05705343559384346,
        -0.04856487363576889,
        -0.056190889328718185,
        0.01760740391910076,
        0.055774297565221786,
        0.05347668379545212,
        0.05609019473195076,
        0.05478733777999878,
        -0.007329677697271109,
        0.052814312279224396,
        0.053689852356910706,
        -0.053396277129650116,
        0.030972016975283623,
        0.05505066737532616,
        0.04512057080864906,
        -0.044252537190914154,
        0.03026394546031952,
        0.04792618006467819,
        0.05704548582434654,
        0.03724750876426697,
        -0.03816670551896095,
        -0.05560808628797531,
        0.0446476936340332,
        -0.021513251587748528,
        -0.054961759597063065,
        0.05413040518760681,
        -0.04908633977174759,
        0.023798683658242226,
        0.04722962900996208,
        -0.056908056139945984,
        -0.016618583351373672,
        0.03567169979214668,
        -0.010609730146825314,
        0.05709432438015938,
        0.04953914135694504,
        0.04301152005791664,
        0.04424254596233368,
        0.04446052387356758,
        0.053078945726156235,
        -0.020846517756581306,
        -0.05235752835869789,
        0.05692976713180542,
        -0.05651010945439339,
        0.05684538185596466,
        0.05702532082796097
    ],
    [
        -0.026480553671717644,
        0.04484745115041733,
        0.054522670805454254,
        0.037516187876462936,
        -0.05175333097577095,
        -0.0499984510242939,
        -0.0003696740895975381,
        -0.056143175810575485,
        0.05598228797316551,
        -0.03230493888258934,
        0.054976046085357666,
        0.03777365759015083,
        -0.04745004326105118,
        0.03342132642865181,
        -0.05581046640872955,
        0.03937951847910881,
        -0.029261687770485878,
        0.05542987957596779,
        0.05274345725774765,
        0.0537249892950058,
        -0.011044940911233425,
        -0.021015174686908722,
        0.05615108832716942,
        0.03699951246380806,
        0.029162626713514328,
        -0.036842890083789825,
        0.05269057676196098,
        -0.03386308625340462,
        -0.052797649055719376,
        -0.04873804748058319,
        -0.03316410630941391,
        0.029690774157643318,
        0.009258908219635487,
        -0.056086521595716476,
        0.051292840391397476,
        -0.0376521497964859,
        -0.056060563772916794,
        -0.054879676550626755,
        -0.04513286054134369,
        -0.05405048653483391,
        -0.053303081542253494,
        0.04716900363564491,
        0.054790716618299484,
        0.0513082817196846,
        0.016581527888774872,
        0.02328525297343731,
        -0.01619960181415081,
        0.05508992075920105,
        0.056146249175071716,
        -0.026056427508592606,
        0.05614769086241722,
        0.054696787148714066,
        -0.05072983354330063,
        -0.026090215891599655,
        0.014749206602573395,
        0.02799958363175392,
        -0.046492863446474075,
        0.019771577790379524,
        -0.05127663537859917,
        -0.027377618476748466,
        -0.05521281063556671,
        0.05135864019393921,
        -0.05479716137051582,
        -0.0022044724319130182,
        -0.0006492573884315789,
        -0.05377243831753731,
        -0.05540786683559418,
        -0.05250691995024681,
        0.04072839766740799,
        -0.05471782758831978,
        -0.0300358384847641,
        -0.05118700861930847,
        0.05515192449092865,
        0.055062077939510345,
        0.047681987285614014,
        -0.053185656666755676,
        0.05133969336748123,
        0.056164827197790146,
        0.052271246910095215,
        0.02970122918486595,
        -0.04618463292717934,
        -0.04248899221420288,
        -0.05608493834733963,
        0.05509111285209656,
        -0.053558290004730225,
        0.010412374511361122,
        0.04767157882452011,
        -0.04499797895550728,
        0.052412018179893494,
        0.054258596152067184,
        -0.004784552380442619,
        -0.024827217683196068,
        -0.046816688030958176,
        -0.04472190886735916,
        -0.04110011085867882,
        0.05327058583498001,
        -0.013630312867462635,
        -0.054856497794389725,
        0.05470811948180199,
        0.055718034505844116,
        -0.056160930544137955,
        0.054759226739406586,
        0.045534953474998474,
        0.05055869370698929,
        0.045191872864961624,
        -0.05591540411114693,
        -0.012229440733790398,
        0.055563509464263916,
        -0.05415176600217819,
        -0.03490453585982323,
        0.05218292027711868,
        0.04590706527233124,
        -0.041631363332271576,
        -0.053900763392448425,
        0.05590400472283363,
        -0.04131144657731056,
        0.03103611432015896,
        -0.05337400734424591,
        0.05580310896039009,
        -0.05277268588542938,
        -0.05581282079219818,
        0.029625574126839638,
        0.048034120351076126,
        -0.05224005505442619,
        -0.05429503321647644,
        -0.03631826490163803,
        0.001125540235079825,
        0.04885486513376236,
        0.05594949796795845,
        -0.0005960812559351325,
        0.04480985924601555,
        -0.052912697196006775,
        -0.02770664356648922,
        0.04454287141561508,
        -0.055439647287130356,
        -0.05604519695043564,
        0.054989513009786606,
        -0.054694902151823044,
        0.013467932119965553,
        0.04976047948002815,
        -0.02442488819360733,
        -0.0383562333881855,
        0.015466559678316116,
        0.056111838668584824,
        0.03083319403231144,
        0.030233586207032204,
        0.05239758640527725,
        -0.04576284438371658,
        -0.014593120664358139,
        -0.01929229125380516,
        0.0391126312315464,
        -0.055949024856090546,
        -0.04085537791252136,
        0.027905987575650215,
        0.05561477318406105,
        -0.054941631853580475,
        0.043638408184051514,
        0.0017215482657775283,
        0.008598700165748596,
        0.04827973619103432,
        0.00996509101241827,
        0.0023440616205334663,
        0.055378787219524384,
        0.055060725659132004,
        -0.0033246197272092104,
        -0.049760207533836365,
        0.056019749492406845,
        -0.013331802561879158,
        -0.04888981208205223,
        -0.028462549671530724,
        -0.05413570627570152,
        0.03353730961680412,
        -0.05584346130490303,
        0.05569933354854584,
        0.00915305782109499,
        0.05516573414206505,
        0.004045048262923956,
        -0.031380314379930496,
        0.056102026253938675,
        -0.04303647205233574,
        -0.05449863523244858,
        -0.05418790876865387,
        0.0284738652408123,
        -0.052988965064287186,
        0.022823316976428032,
        -0.0028916948940604925,
        0.01783493533730507,
        0.055189523845911026,
        -0.04412040859460831,
        -0.031702276319265366,
        -0.05154765769839287,
        0.033601049333810806,
        -0.03167593106627464,
        0.02982659451663494,
        0.024760179221630096,
        0.05477313697338104,
        0.04503844678401947,
        0.0503857396543026,
        0.004413163755089045,
        0.046720921993255615,
        0.05544951185584068,
        -0.020209599286317825,
        0.048729635775089264,
        0.000734736502636224,
        -0.05377117916941643,
        -0.05094289034605026,
        0.055557575076818466,
        0.051639802753925323,
        -0.03906916454434395,
        0.041959356516599655,
        -0.05404456704854965,
        0.03683120012283325,
        0.05456814542412758,
        0.05514569953083992,
        -0.02939506247639656,
        -0.05567803978919983,
        0.053392887115478516,
        0.012912076897919178,
        0.05616044998168945,
        -0.05265982076525688,
        -0.03426098823547363,
        0.05203961208462715,
        0.035951681435108185,
        -0.04522290825843811,
        0.048348285257816315,
        -0.022106900811195374,
        0.04987648129463196,
        -0.05146343633532524,
        0.05529686436057091,
        -0.05611902102828026,
        -0.05020783096551895,
        -0.0506877638399601,
        -0.045767925679683685,
        0.04835081845521927,
        -0.010262088850140572,
        0.055934298783540726,
        0.05571691691875458,
        -0.04244127869606018,
        0.04627086594700813,
        0.05616212263703346,
        0.010864084586501122,
        -0.054912932217121124,
        0.053985364735126495,
        -0.01389244757592678,
        0.04387892410159111,
        -0.04781200364232063,
        -0.03286345675587654,
        -0.05186808854341507,
        -0.0013549714349210262,
        -0.03699657693505287,
        -0.05532332509756088,
        -0.05575033649802208,
        -0.029831869527697563,
        -0.056056082248687744,
        0.03337179869413376,
        0.042693816125392914,
        0.054893020540475845,
        -0.02238939329981804,
        0.05262294411659241,
        -0.00962901022285223,
        -0.04326419159770012,
        -0.04716744273900986,
        -0.04455260932445526,
        0.014898146502673626,
        -0.048672936856746674,
        0.0561472587287426,
        0.05103592202067375,
        0.027003655210137367,
        -0.05433053523302078,
        0.0263900738209486,
        -0.02499767392873764,
        -0.047472577542066574,
        0.05579901486635208,
        -0.05378236994147301,
        -0.05136921629309654,
        0.04419594258069992,
        -0.05559653416275978,
        -0.025884967297315598,
        -0.05180831998586655,
        0.024249227717518806,
        0.012733513489365578,
        0.0324481800198555,
        -0.05427038297057152,
        0.053755082190036774,
        0.03574894741177559,
        -0.01019758265465498,
        -0.020266840234398842,
        0.056043751537799835,
        0.00686813285574317,
        -0.009519415907561779,
        -0.05426234006881714,
        -0.006066059228032827,
        -0.04777757450938225,
        -0.054659854620695114,
        0.04821757599711418,
        -0.05516279116272926,
        0.05543956533074379,
        0.05230344831943512,
        -0.01867673732340336,
        0.05592464283108711,
        -0.009552466683089733,
        0.03317638859152794,
        -0.026699820533394814,
        0.055676400661468506,
        0.01862342283129692,
        -0.0258803553879261,
        -0.05265761539340019,
        0.05604708567261696,
        0.05264761298894882,
        -0.055977411568164825,
        -0.04272283986210823,
        -0.04985857382416725,
        0.04486679285764694,
        0.022415099665522575,
        0.0548027865588665,
        -0.054456159472465515,
        -0.029938815161585808,
        -0.002851177006959915,
        0.054554495960474014,
        0.04447966441512108,
        -0.01685773767530918,
        -0.0392589196562767,
        0.03693616762757301,
        -0.036960337311029434,
        0.05575926974415779,
        -0.014707883819937706,
        0.05139046162366867,
        -0.054997704923152924,
        -0.03269440680742264,
        -0.055834051221609116,
        0.055981460958719254,
        -0.029776494950056076,
        0.004207361489534378,
        0.051572609692811966,
        0.04465916380286217,
        -0.003061146941035986,
        -0.05498957633972168,
        0.05497900769114494,
        -0.003681287867948413,
        0.05420014634728432,
        -0.037920992821455,
        -0.04296506568789482,
        0.05516576021909714,
        -0.005655030719935894,
        0.05415130779147148,
        0.054233431816101074,
        0.04606587812304497,
        0.05478959530591965,
        0.04600387439131737,
        0.054768383502960205,
        0.056103598326444626,
        0.0016797572607174516,
        0.049727216362953186,
        -0.055392779409885406,
        -0.055443499237298965,
        0.051949430257081985,
        -0.05581515654921532,
        -0.03718923404812813,
        -0.037052299827337265,
        -0.05489756539463997,
        0.04626712203025818,
        0.052431605756282806,
        -0.04464799538254738,
        -0.05203920602798462,
        -0.05455950275063515,
        -0.0474654845893383,
        0.0557732880115509,
        -0.010741612873971462,
        0.038055479526519775,
        -0.05536123737692833,
        -0.04933429881930351,
        -0.05181377753615379,
        0.05242598056793213,
        -0.04411550983786583,
        0.028802169486880302,
        0.05575452744960785,
        0.00414326461032033,
        0.048179805278778076,
        0.05572090297937393,
        -0.03668401390314102,
        -0.051190633326768875,
        0.03649343550205231,
        -0.0529947429895401,
        0.033787522464990616,
        0.007487392984330654,
        -0.04924234002828598,
        -0.040453165769577026,
        0.01595287211239338,
        -0.05232268199324608,
        -0.04268914833664894,
        -0.05529787391424179,
        -0.0532071590423584,
        -0.05548660457134247,
        -0.03995949774980545,
        0.025617890059947968,
        0.05569016560912132,
        0.04456571489572525,
        0.001298094168305397,
        -0.055374640971422195,
        -0.05564359575510025,
        0.016567382961511612,
        0.05598260462284088,
        0.04336090013384819,
        0.04852084442973137,
        -0.053791508078575134,
        -0.042729318141937256,
        0.047625813633203506,
        -0.05520593747496605,
        0.056105390191078186,
        0.049225013703107834,
        -0.051613714545965195,
        -0.042680270969867706,
        -0.003232487477362156,
        -0.027274951338768005,
        0.027360824868083,
        -0.05446731671690941,
        -0.046417832374572754,
        0.055748388171195984,
        0.055261239409446716,
        0.05539831891655922,
        0.01606353372335434,
        -0.0549992136657238,
        -0.05139270797371864,
        -0.05607984960079193,
        -0.012622220441699028,
        0.028924424201250076,
        -0.028888754546642303,
        0.02339990995824337,
        -0.03683502972126007,
        -0.05609757453203201,
        0.05603785067796707,
        0.006975013297051191,
        -0.03444071114063263,
        -0.019964395090937614,
        0.04748310148715973,
        -0.05140673369169235,
        -0.05611512437462807,
        0.05440668389201164,
        0.011998271569609642,
        -0.028477052226662636,
        -0.032599709928035736,
        0.05113998427987099,
        -0.01707731932401657,
        0.055861394852399826,
        0.03717426210641861,
        -0.04670262336730957,
        0.055303044617176056,
        -0.053787071257829666,
        0.038118939846754074,
        0.05571040138602257,
        0.036217108368873596,
        0.04611475393176079,
        0.04336012899875641,
        0.05392467603087425,
        0.03051700070500374,
        -0.05579244717955589,
        -0.02723544090986252,
        -0.05512561276555061,
        -0.008878437802195549,
        -0.024407420307397842,
        0.04286254569888115,
        -0.05474405735731125,
        0.011020895093679428,
        0.03636782616376877,
        0.05311520770192146,
        0.05601928383111954,
        -0.055402953177690506,
        0.03874807059764862,
        -0.027219144627451897,
        -0.05561688169836998,
        0.05591448396444321,
        0.03448088467121124,
        0.04344674572348595,
        0.056042127311229706,
        0.0021103769540786743,
        0.04815400764346123,
        0.04534371942281723,
        -0.04608599841594696,
        -0.05182274803519249,
        -0.05304500460624695,
        -0.044971466064453125,
        -0.049527719616889954,
        -0.05417001619935036,
        0.0475737638771534,
        -0.049039751291275024,
        0.05084662139415741,
        0.046654898673295975,
        0.05557996407151222,
        -0.05566386878490448,
        -0.04266156628727913,
        -0.04871583357453346,
        0.02413974329829216,
        0.04256230220198631,
        -0.036132123321294785,
        -0.0551583468914032,
        0.025501517578959465,
        0.04192163795232773,
        -0.021827509626746178,
        0.055195439606904984,
        0.05015430971980095,
        -0.045142773538827896,
        -0.013647647574543953,
        -0.05175080522894859,
        0.00374557520262897,
        0.0554334931075573,
        0.04909134283661842,
        0.009343947283923626,
        0.01755652390420437,
        0.041573964059352875,
        -0.03178393468260765,
        -0.005690910387784243,
        0.05552424117922783
    ],
    [
        -0.0550977922976017,
        -0.05479498952627182,
        0.027759410440921783,
        0.007587297819554806,
        0.056539662182331085,
        -0.03909088298678398,
        0.057614315301179886,
        -0.04962501674890518,
        0.02091485820710659,
        0.054774992167949677,
        0.029319284483790398,
        0.033104512840509415,
        -0.04837317019701004,
        -0.04030069336295128,
        -0.056784018874168396,
        0.050136543810367584,
        -0.057486750185489655,
        0.050855133682489395,
        -0.05757672339677811,
        0.05237922444939613,
        -0.05234934017062187,
        0.0189946498721838,
        0.052628472447395325,
        0.011362884193658829,
        0.047675807029008865,
        -0.03888140246272087,
        0.040071748197078705,
        0.04880103841423988,
        0.043983105570077896,
        -0.009332102723419666,
        0.05436156690120697,
        -0.021683990955352783,
        -0.0003089795645792037,
        -0.04944479838013649,
        0.010989777743816376,
        0.003300211625173688,
        -0.052371781319379807,
        -0.054310671985149384,
        -0.05735519528388977,
        0.04810447618365288,
        0.05139537528157234,
        -0.03595985844731331,
        0.05160987004637718,
        -0.05007683113217354,
        0.0563829205930233,
        0.04467839002609253,
        0.045835431665182114,
        0.05606995150446892,
        0.05758233368396759,
        0.048743851482868195,
        0.04042176902294159,
        0.005203152075409889,
        0.05690375715494156,
        0.003617081791162491,
        0.04490672051906586,
        -0.04754047840833664,
        -0.03611533343791962,
        -0.04872703552246094,
        -0.02249857224524021,
        0.04972473531961441,
        -0.054455485194921494,
        -0.047173935920000076,
        -0.04974890500307083,
        0.04594968631863594,
        0.052135273814201355,
        0.04052545875310898,
        0.05729985609650612,
        0.03337617963552475,
        0.05246758833527565,
        -0.057743240147829056,
        -0.056680191308259964,
        -0.057637494057416916,
        -0.0073708961717784405,
        0.043073203414678574,
        -0.056624941527843475,
        -0.005264744162559509,
        0.029872793704271317,
        0.007855755276978016,
        0.010092413984239101,
        -0.05578305572271347,
        -0.03168521821498871,
        0.055432479828596115,
        -0.029066044837236404,
        0.017066948115825653,
        0.04218597337603569,
        0.01942303031682968,
        -0.02660849504172802,
        0.04449807479977608,
        -0.01248467992991209,
        0.055653106421232224,
        -0.04860397428274155,
        0.056878428906202316,
        0.04909786581993103,
        -0.05430661141872406,
        -0.022523049265146255,
        -0.02606894075870514,
        -0.03658199682831764,
        -0.05522795394062996,
        -0.057646989822387695,
        -0.046456340700387955,
        -0.057670943439006805,
        0.01350040640681982,
        0.05480528995394707,
        0.026602230966091156,
        0.04321454092860222,
        -0.05478129908442497,
        -0.05324110388755798,
        0.040528204292058945,
        -0.023908507078886032,
        0.0139884939417243,
        -0.055498890578746796,
        -0.05634588375687599,
        -0.02742563933134079,
        -0.02053011953830719,
        0.03410670533776283,
        -0.05058789998292923,
        0.05002729594707489,
        -0.051713455468416214,
        -0.057634852826595306,
        0.03810638561844826,
        -0.0419192761182785,
        -0.019002003595232964,
        0.0409247949719429,
        0.019947972148656845,
        -0.05763593316078186,
        -0.011241510510444641,
        -0.030526040121912956,
        0.05677025765180588,
        0.056718047708272934,
        -0.04301781952381134,
        -0.046870213001966476,
        -0.03768543526530266,
        0.04750431701540947,
        -0.01016655471175909,
        -0.05290274694561958,
        -0.03784104809165001,
        0.025369973853230476,
        -0.052642352879047394,
        -0.04972754791378975,
        0.055871907621622086,
        0.047156963497400284,
        0.047905538231134415,
        0.05141567811369896,
        0.0428285114467144,
        -0.023848727345466614,
        0.05601411312818527,
        0.053461384028196335,
        -0.04055261239409447,
        -0.05573117360472679,
        0.054269660264253616,
        -0.054180897772312164,
        -0.057343412190675735,
        -0.056913577020168304,
        0.035268500447273254,
        -0.020720181986689568,
        -0.05767742171883583,
        0.057315435260534286,
        -0.03416518121957779,
        -0.011386284604668617,
        0.005833409260958433,
        0.05716624855995178,
        0.03087245486676693,
        0.015081817284226418,
        -0.03197541832923889,
        -0.054017938673496246,
        -0.027662113308906555,
        0.030467674136161804,
        -0.055090099573135376,
        0.04251330345869064,
        0.04743901640176773,
        -0.017525557428598404,
        0.05019363760948181,
        -0.04363067075610161,
        -0.04322812706232071,
        -0.04639668017625809,
        0.057429201900959015,
        -0.05773407220840454,
        0.05172935128211975,
        0.047510623931884766,
        0.05554048717021942,
        0.05546395853161812,
        0.04333258792757988,
        0.05686689540743828,
        0.03333210200071335,
        0.04390760883688927,
        -0.023740973323583603,
        -0.05722416937351227,
        0.05358511954545975,
        -0.04782595857977867,
        0.02081459015607834,
        -0.030275795608758926,
        -0.05363838002085686,
        -0.01280515268445015,
        -0.021455874666571617,
        0.034625500440597534,
        0.003987359814345837,
        0.05257793143391609,
        0.0015674922615289688,
        0.010943117551505566,
        -0.005925423931330442,
        -0.000997536233626306,
        0.056420084089040756,
        0.04024253413081169,
        0.0536615327000618,
        0.0508599616587162,
        0.054656099528074265,
        0.03364933654665947,
        0.03878083825111389,
        -0.04366055130958557,
        0.04538614675402641,
        -0.05010884627699852,
        0.040195006877183914,
        -0.04987666755914688,
        -0.05688454955816269,
        0.056673597544431686,
        0.001983397640287876,
        0.046636659651994705,
        0.03379569575190544,
        0.05749928206205368,
        -0.05036744847893715,
        0.045768387615680695,
        -0.05237429589033127,
        -0.05383584648370743,
        -0.05772063508629799,
        0.057187270373106,
        -0.05766836926341057,
        -0.009496841579675674,
        0.03833620995283127,
        -0.05655898153781891,
        -0.056366927921772,
        -0.018943512812256813,
        -0.05657915025949478,
        -0.05478617548942566,
        0.02657661773264408,
        0.008914291858673096,
        0.025638025254011154,
        0.05545040965080261,
        -0.008520392701029778,
        -0.04784287139773369,
        0.04367223009467125,
        0.05359372869133949,
        0.05352293699979782,
        -0.03269805386662483,
        0.012396776117384434,
        -0.0328233502805233,
        -0.010862551629543304,
        0.026291370391845703,
        0.05721500143408775,
        -0.052418697625398636,
        0.015001228079199791,
        -0.0495576485991478,
        -0.056733377277851105,
        -0.051115259528160095,
        -0.05483481287956238,
        0.01892479881644249,
        0.0572318509221077,
        -0.05410299450159073,
        -0.033379483968019485,
        0.05770263820886612,
        0.05738280341029167,
        0.05717921629548073,
        0.057654742151498795,
        0.03638327866792679,
        0.05054230988025665,
        0.039620913565158844,
        0.027575373649597168,
        0.05496132746338844,
        -0.04279560223221779,
        0.0182266216725111,
        -0.03930151090025902,
        -0.044901881366968155,
        -0.057660311460494995,
        0.014157085679471493,
        -0.05746300145983696,
        -0.05422937124967575,
        0.043746523559093475,
        -0.05584080144762993,
        0.015138239599764347,
        -0.016250958666205406,
        -0.056853730231523514,
        -0.05451468005776405,
        -0.05572189763188362,
        0.029944458976387978,
        -0.004094738978892565,
        -0.04909827932715416,
        -0.0575002022087574,
        0.04684047773480415,
        -0.0576852485537529,
        -0.0533834733068943,
        -0.0008968555484898388,
        0.0015400968259200454,
        0.03452390059828758,
        0.04481031373143196,
        -7.072934522511787e-7,
        0.0334070585668087,
        -0.048403311520814896,
        0.05773083493113518,
        -0.040118616074323654,
        0.016360104084014893,
        0.05311225354671478,
        -0.05487433448433876,
        0.05620306730270386,
        -0.007660571485757828,
        -0.012918209657073021,
        -0.052201345562934875,
        -0.004265366122126579,
        -0.0512663796544075,
        0.01104225404560566,
        0.05312790721654892,
        -0.05762623995542526,
        0.00972207635641098,
        -0.049340542405843735,
        -0.05497116222977638,
        0.05730045214295387,
        -0.057723816484212875,
        -0.056000545620918274,
        0.05697191134095192,
        0.008444647304713726,
        -0.03212504833936691,
        -0.05608544871211052,
        -0.05721402168273926,
        -0.006285509094595909,
        0.05281727388501167,
        -0.051996465772390366,
        0.055669307708740234,
        0.052664417773485184,
        0.04111912474036217,
        0.01782117411494255,
        0.03134438395500183,
        -0.04952070489525795,
        -0.055294085294008255,
        0.05392947420477867,
        0.0494515635073185,
        -0.05609812214970589,
        0.05465541034936905,
        -0.02212032675743103,
        -0.053832054138183594,
        0.05759473145008087,
        -0.05640723928809166,
        0.03807351738214493,
        0.04514508694410324,
        -0.02373708225786686,
        -0.030941052362322807,
        0.006061548367142677,
        0.05774340406060219,
        0.04134972766041756,
        0.03137114271521568,
        0.04147033765912056,
        -0.021858427673578262,
        0.05712059885263443,
        -0.05774334445595741,
        -0.00660225423052907,
        -0.04844704642891884,
        -0.05498562753200531,
        -0.01905657723546028,
        0.055259402841329575,
        -0.03723405674099922,
        0.02289416640996933,
        -0.0469033308327198,
        -0.05527405068278313,
        0.05209414288401604,
        0.05591729283332825,
        -0.05696855112910271,
        -0.046996161341667175,
        0.00887202937155962,
        -0.05108331888914108,
        0.05770605802536011,
        0.01881423033773899,
        0.016145892441272736,
        -0.05763082951307297,
        -0.039259880781173706,
        -0.05684931203722954,
        0.0365251824259758,
        0.04916156828403473,
        0.032747916877269745,
        0.048297442495822906,
        -0.05328868702054024,
        0.047296442091464996,
        -0.05218365788459778,
        -0.05613284185528755,
        -0.04091714322566986,
        0.02940274029970169,
        -0.0565006248652935,
        0.036076780408620834,
        0.05763276666402817,
        0.005803645588457584,
        0.05280498042702675,
        -0.0564119853079319,
        -0.04741452634334564,
        -0.056552983820438385,
        0.006221321877092123,
        0.045746106654405594,
        0.039530929177999496,
        0.05256494879722595,
        -0.05516383424401283,
        0.05536656826734543,
        0.042083095759153366,
        -0.03677241876721382,
        0.027488181367516518,
        -0.05755113810300827,
        -0.05015362426638603,
        0.01641082763671875,
        0.054743967950344086,
        0.05729514732956886,
        -0.05541204661130905,
        -0.02482239529490471,
        0.04887760803103447,
        0.03984741494059563,
        0.0555933378636837,
        -0.054825589060783386,
        0.057028498500585556,
        0.019150633364915848,
        0.053597014397382736,
        0.04598984867334366,
        0.05281255021691322,
        -0.0007515019387938082,
        0.04542016610503197,
        -0.01575256884098053,
        0.049214139580726624,
        -0.011450333520770073,
        -0.057382527738809586,
        -0.05404634773731232,
        -0.014277325011789799,
        -0.03189915418624878,
        0.04908465966582298,
        -0.019850078970193863,
        -0.055465325713157654,
        -0.03500334918498993,
        -0.057377882301807404,
        -0.027831969782710075,
        -0.007766437716782093,
        -0.03096633218228817,
        0.019611161202192307,
        0.03938112407922745,
        -0.0027296808548271656,
        0.0005297940806485713,
        -0.05726243183016777,
        0.04414655268192291,
        -0.03967840224504471,
        -0.05343308299779892,
        0.015725813806056976,
        0.039208680391311646,
        0.04894599691033363,
        0.05540149658918381,
        0.04580792784690857,
        0.05099072679877281,
        0.05678979307413101,
        -0.05312067270278931,
        0.05666998028755188,
        -0.05747373774647713,
        -0.023529333993792534,
        0.05279385671019554,
        0.04939752444624901,
        -0.005681297276169062,
        -0.05376752093434334,
        -0.04329335689544678,
        0.029960721731185913,
        0.047352515161037445,
        -0.04922564700245857,
        0.054592665284872055,
        0.011230549775063992,
        0.024106308817863464,
        -0.05768889561295509,
        -0.0016883197240531445,
        0.05273770913481712,
        0.057328447699546814,
        -0.05762748792767525,
        0.056875020265579224,
        -0.017797714099287987,
        -0.05736571550369263,
        0.0438234880566597,
        0.053839318454265594,
        0.04538485407829285,
        0.03030271641910076,
        0.05721187964081764,
        0.01842137984931469,
        -0.010436484590172768,
        0.02108096331357956,
        0.003712201490998268,
        -0.053353969007730484,
        -0.035887304693460464,
        0.013727919198572636,
        0.04868627339601517,
        0.025451913475990295,
        0.006345041561871767,
        0.04568609222769737,
        0.05515526607632637,
        -0.04416153207421303,
        -0.053029097616672516,
        0.04921619966626167,
        -0.057455360889434814,
        -0.056411273777484894,
        -0.03451797366142273,
        0.02826317586004734,
        0.05732173100113869,
        0.042298588901758194,
        -0.002959710080176592,
        0.012591122649610043,
        0.054363589733839035,
        0.05671089142560959,
        0.029213914647698402,
        -0.05657593160867691,
        0.0073150405660271645,
        0.045211371034383774,
        0.044627491384744644,
        0.048882726579904556,
        -0.04697581008076668,
        -0.044574152678251266,
        -0.05754030868411064,
        -0.05666033551096916,
        0.05765366554260254,
        0.04640757292509079
    ],
    [
        -0.051723215728998184,
        -0.047816451638936996,
        0.04222721606492996,
        0.018160047009587288,
        0.05674250051379204,
        -0.049111466854810715,
        0.0607401579618454,
        -0.039522234350442886,
        0.0279904305934906,
        0.058980993926525116,
        0.05129403993487358,
        0.011877793818712234,
        -0.029759181663393974,
        -0.04611797630786896,
        -0.05988425388932228,
        0.03579127416014671,
        -0.06004541367292404,
        0.05008059740066528,
        -0.05884409323334694,
        0.05127890780568123,
        -0.03495229780673981,
        0.03741656243801117,
        0.04987815022468567,
        -0.028267117217183113,
        0.0005335480091162026,
        -0.02878054603934288,
        0.044707346707582474,
        0.048752665519714355,
        0.028577595949172974,
        0.009896531701087952,
        0.052560705691576004,
        0.02243061549961567,
        0.044889818876981735,
        -0.03820475563406944,
        0.03474524989724159,
        0.03225652500987053,
        -0.05686930939555168,
        -0.05145466327667236,
        -0.0558011457324028,
        0.04496825858950615,
        0.05366158485412598,
        0.005453146528452635,
        0.05342783033847809,
        -0.04629266634583473,
        0.05125713720917702,
        0.05108661204576492,
        0.023257726803421974,
        0.05798656865954399,
        0.061048831790685654,
        0.01895899698138237,
        0.05105242878198624,
        -0.012272445484995842,
        0.05883261561393738,
        0.03083305060863495,
        0.04494849592447281,
        -0.020486323162913322,
        -0.03613981977105141,
        -0.05519082397222519,
        0.017789490520954132,
        0.023495815694332123,
        -0.059446241706609726,
        -0.04693930596113205,
        -0.048940107226371765,
        0.045125044882297516,
        0.04376765340566635,
        0.032368339598178864,
        0.05435549467802048,
        0.03537241742014885,
        0.05732393637299538,
        -0.06126999109983444,
        -0.05850842595100403,
        -0.060681141912937164,
        0.035756681114435196,
        0.05761329084634781,
        -0.05898541212081909,
        -0.03271370381116867,
        0.033397771418094635,
        0.03880970552563667,
        -0.01826602593064308,
        -0.05562085285782814,
        -0.027066363021731377,
        0.05892070755362511,
        -0.04967866092920303,
        -0.005049347411841154,
        0.030584486201405525,
        -0.00949092861264944,
        0.027679257094860077,
        0.038836006075143814,
        -0.04324617609381676,
        0.056896600872278214,
        -0.046440791338682175,
        0.05694713816046715,
        0.0343778133392334,
        -0.04747643321752548,
        0.0009864924941211939,
        -0.04668322205543518,
        -0.03425995633006096,
        -0.059048619121313095,
        -0.0610191635787487,
        -0.05717312544584274,
        -0.06088482216000557,
        0.02389836683869362,
        0.05742715671658516,
        0.0020142020657658577,
        0.04030033200979233,
        -0.058888982981443405,
        -0.053459618240594864,
        0.03998415917158127,
        -0.025656266137957573,
        -0.018827497959136963,
        -0.043583162128925323,
        -0.05805988982319832,
        -0.045353133231401443,
        0.003968242090195417,
        0.02559971809387207,
        -0.05206567794084549,
        0.05879081040620804,
        -0.05160757899284363,
        -0.05916857719421387,
        0.045291103422641754,
        -0.04742850363254547,
        0.005029990803450346,
        0.047471173107624054,
        -0.015536119230091572,
        -0.06081106886267662,
        -0.009765961207449436,
        -0.03705823794007301,
        0.05934279412031174,
        0.05714232102036476,
        -0.041861869394779205,
        -0.05470389127731323,
        -0.031135575845837593,
        0.041975680738687515,
        0.0030837601516395807,
        -0.053536564111709595,
        -0.045021846890449524,
        0.033392284065485,
        -0.05304906144738197,
        -0.05802212283015251,
        0.05921691656112671,
        -0.006241621449589729,
        0.02072388119995594,
        0.05154551938176155,
        0.04013655707240105,
        -0.05280473455786705,
        0.05371382087469101,
        0.05289333313703537,
        -0.051530856639146805,
        -0.050946302711963654,
        0.04971937835216522,
        -0.05355053395032883,
        -0.05798877030611038,
        -0.05546253174543381,
        0.04517563432455063,
        0.013630056753754616,
        -0.06115881726145744,
        0.0534118115901947,
        -0.018064269796013832,
        0.015821240842342377,
        0.006522723939269781,
        0.05948738008737564,
        0.013157470151782036,
        0.029393328353762627,
        -0.029104512184858322,
        -0.055287059396505356,
        0.013786404393613338,
        -0.0067485361360013485,
        -0.0509939007461071,
        0.02703399397432804,
        0.04662425443530083,
        -0.0019190117018297315,
        0.047159526497125626,
        -0.011400190182030201,
        -0.04419325292110443,
        -0.041946910321712494,
        0.06028411537408829,
        -0.061046384274959564,
        0.029129158705472946,
        0.03957173600792885,
        0.05689014866948128,
        0.060854557901620865,
        0.05165315419435501,
        0.05616411194205284,
        0.03238504379987717,
        0.01555035449564457,
        0.010198775678873062,
        -0.06025944650173187,
        0.05722128227353096,
        -0.04343114793300629,
        0.03104119375348091,
        -0.04685436934232712,
        -0.03147096559405327,
        0.03319549188017845,
        -0.04753633216023445,
        -0.017447959631681442,
        0.02645924687385559,
        0.03602943569421768,
        -0.008559639565646648,
        0.049663081765174866,
        0.014303237199783325,
        0.029437100514769554,
        0.05315897986292839,
        0.044243719428777695,
        0.025201158598065376,
        0.04620906710624695,
        0.05807282030582428,
        0.051142703741788864,
        0.036857616156339645,
        -0.03690388798713684,
        0.053399018943309784,
        -0.04876133054494858,
        0.05108406022191048,
        -0.020932093262672424,
        -0.058521319180727005,
        0.05079101398587227,
        -0.028390029445290565,
        0.01359086949378252,
        0.0228078905493021,
        0.051558319479227066,
        -0.015558185987174511,
        0.05418756604194641,
        -0.05016537755727768,
        -0.033407244831323624,
        -0.06090376153588295,
        0.060241181403398514,
        -0.05962049216032028,
        -0.032482944428920746,
        -0.026933977380394936,
        -0.05639495328068733,
        -0.0599677748978138,
        -0.03276627138257027,
        -0.05841144919395447,
        -0.05404390022158623,
        0.019248012453317642,
        -0.04449469596147537,
        0.0373135469853878,
        0.05582653731107712,
        -0.01919136568903923,
        -0.03268355876207352,
        0.03653803840279579,
        0.05392640084028244,
        0.05743730813264847,
        -0.048416636884212494,
        -4.2007609835081894e-8,
        -0.018651491031050682,
        -0.024568991735577583,
        -0.026235882192850113,
        0.060731615871191025,
        -0.055909980088472366,
        0.0022390985395759344,
        -0.053462546318769455,
        -0.05953682214021683,
        -0.05678321793675423,
        -0.059104666113853455,
        0.03681240975856781,
        0.060480330139398575,
        -0.0375547893345356,
        -0.005651386454701424,
        0.061129532754421234,
        0.05936729907989502,
        0.06062891706824303,
        0.060538288205862045,
        0.023479517549276352,
        0.059566400945186615,
        0.05418156459927559,
        0.04788890853524208,
        0.059849753975868225,
        -0.03363226726651192,
        0.03530063480138779,
        -0.029263556003570557,
        -0.008400664664804935,
        -0.06087091192603111,
        0.005183224566280842,
        -0.060652945190668106,
        -0.054933831095695496,
        0.04308974742889404,
        -0.056792572140693665,
        0.004616505466401577,
        -0.029818493872880936,
        -0.06056356802582741,
        -0.05624869093298912,
        -0.0348350964486599,
        0.017536334693431854,
        -0.029272502288222313,
        -0.025124022737145424,
        -0.058654818683862686,
        0.04317827895283699,
        -0.05999580770730972,
        -0.034472305327653885,
        0.014658492058515549,
        -0.043151404708623886,
        0.02081046625971794,
        0.04360795021057129,
        -0.006304947659373283,
        0.03909105435013771,
        -0.04157322645187378,
        0.061212025582790375,
        -0.014957576990127563,
        0.05167070031166077,
        0.05205234885215759,
        -0.056394584476947784,
        0.057198621332645416,
        -0.008757579140365124,
        -0.005728030111640692,
        -0.05426250770688057,
        -0.0015057014534249902,
        -0.04901855066418648,
        -0.04709353297948837,
        0.05370612442493439,
        -0.060758691281080246,
        0.03789668157696724,
        -0.055256444960832596,
        -0.056063782423734665,
        0.05779385566711426,
        -0.061053451150655746,
        -0.059816889464855194,
        0.0552113875746727,
        0.03424237295985222,
        -0.029187144711613655,
        -0.05785341188311577,
        -0.052247919142246246,
        -0.028666675090789795,
        0.058275990188121796,
        -0.05574125796556473,
        0.05472050979733467,
        0.053668174892663956,
        0.032816652208566666,
        -0.03240102156996727,
        0.027848029509186745,
        -0.05623582378029823,
        -0.05557139217853546,
        0.04796190932393074,
        0.03175830468535423,
        -0.05850919336080551,
        0.05943305045366287,
        0.03224929794669151,
        -0.03495727479457855,
        0.06082146614789963,
        -0.057381562888622284,
        -0.028953276574611664,
        0.03615950047969818,
        -0.01291042659431696,
        -0.017457162961363792,
        0.018951814621686935,
        0.06128334254026413,
        0.029413308948278427,
        0.019989075139164925,
        0.042977843433618546,
        -0.0450904555618763,
        0.05980299040675163,
        -0.06127947196364403,
        -0.016645273193717003,
        -0.04730691760778427,
        -0.048908770084381104,
        0.03126220032572746,
        0.059161245822906494,
        -0.01027234923094511,
        0.023173147812485695,
        -0.05628296360373497,
        -0.05558807775378227,
        0.049760594964027405,
        0.058188606053590775,
        -0.06023541837930679,
        -0.041458819061517715,
        -0.011298726312816143,
        -0.05740368366241455,
        0.061148084700107574,
        0.02507801726460457,
        -0.003947176970541477,
        -0.06098407879471779,
        -0.04764128476381302,
        -0.058413200080394745,
        0.039504457265138626,
        0.045638952404260635,
        -0.005872733891010284,
        -0.007632657885551453,
        -0.05417485535144806,
        0.04481247812509537,
        -0.054160118103027344,
        -0.058808885514736176,
        -0.005486974027007818,
        0.02633112110197544,
        -0.06038578972220421,
        0.03734173625707626,
        0.061086323112249374,
        -0.011706135235726833,
        0.04127306863665581,
        -0.058493535965681076,
        -0.04578724503517151,
        -0.057455677539110184,
        0.01645386777818203,
        0.022555673494935036,
        0.043117765337228775,
        0.053189169615507126,
        -0.05567556992173195,
        0.05678807199001312,
        -0.01402865257114172,
        -0.04872336983680725,
        0.012153450399637222,
        -0.05947107821702957,
        -0.03590807691216469,
        0.04111410304903984,
        0.05670921131968498,
        0.05828123912215233,
        -0.059925176203250885,
        -0.02005353569984436,
        0.03463063761591911,
        0.044465117156505585,
        0.060332249850034714,
        -0.05727839842438698,
        0.058504506945610046,
        -0.010552166029810905,
        0.05606629326939583,
        0.02599554881453514,
        0.05564037337899208,
        -0.005796478595584631,
        0.02308412827551365,
        -0.03642182797193527,
        0.050357967615127563,
        -0.00595115078613162,
        -0.05784968286752701,
        -0.057791873812675476,
        0.034541960805654526,
        -0.02978566475212574,
        0.04099166765809059,
        -0.01793193630874157,
        -0.02799474261701107,
        -0.01528890524059534,
        -0.059004802256822586,
        -0.04580152779817581,
        -0.03265344351530075,
        -0.03185240179300308,
        0.025705138221383095,
        0.01798389106988907,
        -0.03242916613817215,
        0.0024842056445777416,
        -0.05767618492245674,
        -0.008071734569966793,
        -0.04512687399983406,
        -0.057933516800403595,
        -0.02333325892686844,
        0.04911476746201515,
        0.052280887961387634,
        0.058395832777023315,
        0.046574629843235016,
        0.051513705402612686,
        0.060735590755939484,
        -0.05377963185310364,
        0.05725416541099548,
        -0.05912742391228676,
        0.006020681466907263,
        0.043206408619880676,
        0.036740005016326904,
        0.029440222308039665,
        -0.05568796396255493,
        -0.041394107043743134,
        0.025328896939754486,
        0.04336210712790489,
        -0.037105247378349304,
        0.05906267464160919,
        0.01630939543247223,
        -0.016794921830296516,
        -0.05968021601438522,
        0.01411676499992609,
        0.02275889553129673,
        0.06021278724074364,
        -0.06086428835988045,
        0.05536442622542381,
        0.022269386798143387,
        -0.06082957983016968,
        0.01259033102542162,
        0.0293814055621624,
        0.036800920963287354,
        0.055904220789670944,
        0.0550776906311512,
        -0.0015253598103299737,
        0.004200302995741367,
        0.06008123233914375,
        -0.03526736795902252,
        -0.03180284798145294,
        -0.03147011995315552,
        0.015786493197083473,
        0.048790231347084045,
        0.038148120045661926,
        0.028590742498636246,
        0.03222614526748657,
        0.05837303027510643,
        -0.025113103911280632,
        -0.057335782796144485,
        0.039028894156217575,
        -0.05981247499585152,
        -0.05715985968708992,
        -0.057305626571178436,
        0.047762829810380936,
        0.060458581894636154,
        -0.008899642154574394,
        0.015155104920268059,
        0.038204412907361984,
        0.056110624223947525,
        0.05929740145802498,
        0.013907326385378838,
        -0.057294320315122604,
        0.015903016552329063,
        0.03281692788004875,
        0.052216555923223495,
        0.029670706018805504,
        0.0063058785162866116,
        -0.04397403821349144,
        -0.05992012098431587,
        -0.06069442629814148,
        0.06059189513325691,
        -0.0009166345116682351
    ],
    [
        -0.04545383155345917,
        0.05160905793309212,
        0.04365049675107002,
        -0.01885240711271763,
        0.015492631122469902,
        0.008603225462138653,
        0.061920516192913055,
        -0.033386509865522385,
        0.004411976784467697,
        0.06166907399892807,
        0.032949093729257584,
        0.052746910601854324,
        -0.05522771179676056,
        -0.04610154405236244,
        -0.061675649136304855,
        0.02806103229522705,
        -0.05886707082390785,
        0.05294596031308174,
        -0.056636303663253784,
        0.03375128656625748,
        -0.01789422333240509,
        0.034707218408584595,
        0.00953921303153038,
        -0.024967089295387268,
        0.04009512439370155,
        0.015544148162007332,
        0.040339089930057526,
        0.061448708176612854,
        -0.02344588376581669,
        -0.04459279403090477,
        0.05692131817340851,
        -0.028000758960843086,
        -0.02026607096195221,
        -0.061603277921676636,
        0.01761200651526451,
        -0.04108048230409622,
        -0.05570519343018532,
        -0.05476050823926926,
        0.03186534717679024,
        0.04622958227992058,
        0.0479850247502327,
        0.009869617410004139,
        0.05896340310573578,
        -0.061272915452718735,
        0.03330520913004875,
        0.02206352911889553,
        0.04697773605585098,
        0.06220588833093643,
        0.062193527817726135,
        -0.056357353925704956,
        0.02113845944404602,
        -0.02255863882601261,
        0.04892725124955177,
        0.006698311772197485,
        0.03994788974523544,
        -0.011692019179463387,
        -0.06144164502620697,
        -0.024569788947701454,
        0.001422520843334496,
        0.051223766058683395,
        -0.05681920051574707,
        0.026804538443684578,
        -0.05743400752544403,
        0.01618720404803753,
        0.03958212211728096,
        0.03399314358830452,
        0.016402224078774452,
        -0.04346185922622681,
        0.027697309851646423,
        -0.062223102897405624,
        -0.05772677809000015,
        -0.062154579907655716,
        0.05717930197715759,
        0.0038338396698236465,
        -0.02663605660200119,
        -0.016274817287921906,
        0.05864120274782181,
        0.03593454137444496,
        0.06074200198054314,
        -0.05088525265455246,
        -0.04741246625781059,
        0.04672442004084587,
        -0.03039369359612465,
        -0.017402105033397675,
        0.060048919171094894,
        0.032511454075574875,
        -0.03162255883216858,
        -0.055523987859487534,
        -0.03538871556520462,
        0.061011698096990585,
        -0.06154070049524307,
        0.03367220610380173,
        -0.024540778249502182,
        -0.04563431069254875,
        -0.002792402869090438,
        0.003867118852213025,
        -0.039924077689647675,
        -0.05928415060043335,
        -0.04952327534556389,
        -0.038248974829912186,
        -0.05413477122783661,
        -0.0008324394002556801,
        0.06035513058304787,
        0.05994979292154312,
        0.0464618057012558,
        -0.030475899577140808,
        -0.034936174750328064,
        -0.014622395858168602,
        -0.0587986521422863,
        -0.050613030791282654,
        -0.057115860283374786,
        -0.04568514972925186,
        -0.03012191876769066,
        0.016441306099295616,
        0.04762163758277893,
        -0.05749029293656349,
        -0.009767199866473675,
        -0.058416176587343216,
        -0.059911031275987625,
        0.04530731216073036,
        -0.052673906087875366,
        0.0031134681776165962,
        -0.005871709436178207,
        0.051621973514556885,
        -0.06191320717334747,
        -0.045913487672805786,
        -0.016609719023108482,
        -0.014088140800595284,
        0.05974232032895088,
        0.02880849316716194,
        0.03809154033660889,
        -0.03736244887113571,
        -0.02158183790743351,
        0.05547688156366348,
        -0.054782968014478683,
        -0.05732182785868645,
        0.054825425148010254,
        -0.04774000123143196,
        -0.04749928042292595,
        0.05781574919819832,
        0.0112242940813303,
        -0.026038598269224167,
        0.0535842590034008,
        0.019159862771630287,
        0.03180985525250435,
        0.04331867769360542,
        0.056248459964990616,
        -0.05274031683802605,
        0.0189973097294569,
        0.026783818379044533,
        -0.03874475508928299,
        -0.06189985200762749,
        -0.05906421318650246,
        0.02532794326543808,
        -0.025309180840849876,
        -0.05759260430932045,
        0.06057759001851082,
        0.0006143419304862618,
        0.02837786078453064,
        -0.02631377801299095,
        0.05631181225180626,
        0.011828823946416378,
        0.043917156755924225,
        0.053414419293403625,
        -0.05638038367033005,
        0.03898050636053085,
        0.02606063149869442,
        -0.05519917234778404,
        -0.002681360812857747,
        0.042861711233854294,
        -0.05877233296632767,
        0.04827316850423813,
        -0.05448434501886368,
        0.048098694533109665,
        -0.05383307859301567,
        0.060420211404561996,
        -0.06170803681015968,
        -0.018279647454619408,
        0.05006621032953262,
        -0.023608770221471786,
        0.05875077098608017,
        0.035570189356803894,
        0.062162064015865326,
        0.046284910291433334,
        0.016366228461265564,
        0.037598926573991776,
        -0.04482818394899368,
        0.05920887365937233,
        -0.05810917168855667,
        -0.05974438041448593,
        -0.053462427109479904,
        -0.021526336669921875,
        0.0069108763709664345,
        0.05449088662862778,
        -0.0506674088537693,
        -0.015347449108958244,
        0.06216612458229065,
        0.0010531446896493435,
        -0.04338590055704117,
        -0.04255843907594681,
        0.00784770306199789,
        0.051509734243154526,
        0.04591609165072441,
        0.024925805628299713,
        0.0571361780166626,
        0.058708108961582184,
        0.00016145434346981347,
        0.010484415106475353,
        -0.05341842398047447,
        -0.0431777685880661,
        -0.054834600538015366,
        0.026991719380021095,
        -0.005502455402165651,
        -0.02743080072104931,
        0.01880810037255287,
        -0.03648240864276886,
        0.062037475407123566,
        -0.024538259953260422,
        0.06202154606580734,
        0.031732089817523956,
        0.018496248871088028,
        0.027409909293055534,
        -0.056684739887714386,
        -0.06163089722394943,
        0.061943620443344116,
        -0.06201113760471344,
        -0.019762154668569565,
        0.054323818534612656,
        -0.04810793325304985,
        -0.062090832740068436,
        -0.002778475871309638,
        -0.062137097120285034,
        -0.05727338790893555,
        0.03880038112401962,
        -0.02456706389784813,
        0.0026941162068396807,
        0.05730782449245453,
        0.018256591632962227,
        0.003137608291581273,
        0.06150078773498535,
        0.05885627120733261,
        0.05000660568475723,
        -0.05063384771347046,
        0.05225648730993271,
        -0.004716369789093733,
        0.01471826434135437,
        0.030056966468691826,
        0.06120407581329346,
        -0.051851313561201096,
        -0.03161188215017319,
        0.0015463602030649781,
        -0.06136031076312065,
        -0.015435694716870785,
        -0.057959794998168945,
        -0.02613990195095539,
        0.06030045822262764,
        -0.06093818321824074,
        -0.04536144807934761,
        0.06181655079126358,
        0.049920883029699326,
        0.05566949024796486,
        0.06081739813089371,
        0.027061784639954567,
        -0.013575449585914612,
        0.060962527990341187,
        0.03826703503727913,
        -0.040126021951436996,
        0.03401556983590126,
        0.018622485920786858,
        0.009360555559396744,
        -0.02922877110540867,
        -0.0554620660841465,
        0.01699250563979149,
        -0.06058143824338913,
        -0.04921969398856163,
        0.02114497683942318,
        -0.0566353015601635,
        0.017924007028341293,
        -0.04388072341680527,
        -0.06091725826263428,
        -0.05932329222559929,
        0.02233215421438217,
        -0.03042633645236492,
        0.04659754037857056,
        0.02639014460146427,
        -0.04404207691550255,
        0.04221253842115402,
        -0.058791156858205795,
        -0.0479440912604332,
        0.046726975589990616,
        -0.05147995427250862,
        0.05507652461528778,
        0.014466770924627781,
        -0.0355805829167366,
        0.01146487332880497,
        -0.059982288628816605,
        0.061948612332344055,
        0.010825468227267265,
        -0.05630723387002945,
        0.056956660002470016,
        -0.00928361713886261,
        0.04821363463997841,
        0.022578731179237366,
        0.033371634781360626,
        0.015039628371596336,
        -0.008177964016795158,
        -0.03030196577310562,
        0.05770460516214371,
        0.05193871632218361,
        -0.060129594057798386,
        0.056496210396289825,
        -0.05398048833012581,
        -0.046012863516807556,
        0.060356855392456055,
        -0.06209644675254822,
        -0.0614849254488945,
        0.05034663900732994,
        0.007433069404214621,
        0.011004037223756313,
        -0.03805247321724892,
        -0.04213570058345795,
        -0.019484534859657288,
        0.05882396548986435,
        -0.028475213795900345,
        0.06003814563155174,
        0.06036464124917984,
        0.028517335653305054,
        0.030886325985193253,
        0.013745138421654701,
        -0.05630715936422348,
        -0.05297611281275749,
        0.0027423882856965065,
        0.020390186458826065,
        -0.038594041019678116,
        0.06095648556947708,
        0.05690943822264671,
        -0.035926155745983124,
        0.060050833970308304,
        -0.047143157571554184,
        0.023092886433005333,
        0.060437917709350586,
        -0.02540362812578678,
        -0.04008718952536583,
        -0.058869682252407074,
        0.062332555651664734,
        0.006946084089577198,
        0.020467258989810944,
        0.05732538551092148,
        -0.01093261782079935,
        0.05604708567261696,
        -0.060390572994947433,
        0.028828080743551254,
        -0.039600130170583725,
        -0.06133415549993515,
        -0.053878623992204666,
        0.04314785450696945,
        -0.0502336248755455,
        -0.012374815531075,
        -0.050815220922231674,
        -0.05020996555685997,
        0.06083985045552254,
        -0.01564275659620762,
        -0.05327220633625984,
        -0.03162664547562599,
        0.010385112836956978,
        -0.05535263195633888,
        0.06224898621439934,
        0.028306588530540466,
        0.05150166526436806,
        -0.062304381281137466,
        -0.01902485452592373,
        -0.06098196655511856,
        0.05694492161273956,
        0.050664547830820084,
        0.057966046035289764,
        0.06082495301961899,
        -0.041343849152326584,
        0.022944267839193344,
        -0.040778402239084244,
        -0.022655677050352097,
        -0.0298113152384758,
        0.05031980946660042,
        -0.06064659729599953,
        0.006824831012636423,
        0.06192935258150101,
        -0.05546077713370323,
        0.056457675993442535,
        -0.05640551447868347,
        -0.05687979608774185,
        -0.05530957132577896,
        -0.04919615015387535,
        0.04101825878024101,
        0.03564683347940445,
        -0.003449319861829281,
        -0.013063277117908001,
        0.05504656955599785,
        0.05802874267101288,
        -0.04936704784631729,
        0.00023209517530631274,
        -0.05812520906329155,
        -0.015802958980202675,
        0.05727425217628479,
        0.04439735412597656,
        0.053097449243068695,
        -0.037275176495313644,
        -0.045485738664865494,
        0.05715785548090935,
        0.03662431612610817,
        0.05865361541509628,
        -0.002020471263676882,
        0.05299346148967743,
        -0.03674067556858063,
        -0.030005084350705147,
        -0.004887097515165806,
        0.06046144664287567,
        -0.03688070923089981,
        0.025410613045096397,
        0.003789895446971059,
        0.035627610981464386,
        0.013299286365509033,
        -0.05627523362636566,
        -0.03596711903810501,
        -0.060373011976480484,
        -0.05992426723241806,
        0.048446737229824066,
        -0.01662531867623329,
        0.026089459657669067,
        -0.014276206493377686,
        -0.061495520174503326,
        -0.030243948101997375,
        0.03804801404476166,
        -0.04261824116110802,
        -0.005635126959532499,
        0.03690028935670853,
        -0.004304537549614906,
        -0.033177100121974945,
        -0.022462273016572,
        0.0012381908018141985,
        0.012537481263279915,
        -0.057115860283374786,
        0.015440150164067745,
        0.060618456453084946,
        0.0557970255613327,
        0.05442264303565025,
        -0.0513211190700531,
        0.04527103900909424,
        0.06060776114463806,
        -0.057251837104558945,
        0.05797436460852623,
        -0.06081102043390274,
        -0.05816730856895447,
        0.055392492562532425,
        0.05722697824239731,
        0.04408441483974457,
        0.027583032846450806,
        -0.04726984724402428,
        0.024318309500813484,
        0.05589405819773674,
        -0.03485111892223358,
        0.06203825771808624,
        0.04337326064705849,
        -0.05113343894481659,
        -0.06104385107755661,
        -0.059597309678792953,
        0.05949174985289574,
        0.06164724379777908,
        -0.062142856419086456,
        0.062191884964704514,
        0.014145131222903728,
        -0.06209031492471695,
        0.05405446141958237,
        0.013795153237879276,
        0.052800461649894714,
        0.017572447657585144,
        0.02494812197983265,
        0.04727170243859291,
        -0.005520873703062534,
        0.04206303879618645,
        -0.05859429016709328,
        0.00479666143655777,
        0.014016175642609596,
        -0.004556884989142418,
        0.045761726796627045,
        0.000010858586392714642,
        -0.022334212437272072,
        0.05525324121117592,
        0.04555220156908035,
        -0.05479049310088158,
        -0.04709244892001152,
        -0.04739832505583763,
        -0.05537068098783493,
        -0.06090371683239937,
        -0.04074322432279587,
        -0.00489928899332881,
        0.058376628905534744,
        0.046663422137498856,
        -0.05029981583356857,
        0.003888564184308052,
        0.05374474078416824,
        0.05235396325588226,
        -0.01267829816788435,
        -0.01962164044380188,
        -0.05588582530617714,
        0.044810619205236435,
        -0.011803653091192245,
        9.102991072040822e-8,
        0.03957749903202057,
        -0.0019366840133443475,
        -0.019130641594529152,
        -0.05890679359436035,
        0.061863016337156296,
        0.05707762762904167
    ],
    [
        -0.05834300071001053,
        0.05716773867607117,
        0.017626361921429634,
        -0.027419021353125572,
        0.05886337161064148,
        0.0004947124980390072,
        0.06470752507448196,
        -0.025190096348524094,
        0.007938560098409653,
        0.062139496207237244,
        0.027313627302646637,
        0.04640268534421921,
        -0.05535558611154556,
        -0.04998615384101868,
        -0.06521642208099365,
        0.02716463804244995,
        -0.0660562664270401,
        0.05116572231054306,
        -0.04875916242599487,
        -0.018434323370456696,
        -0.05462535470724106,
        0.00761681841686368,
        0.033762067556381226,
        0.010701793245971203,
        0.010276422835886478,
        0.05656234547495842,
        0.046068400144577026,
        0.06371863186359406,
        -0.03551122546195984,
        -0.042469993233680725,
        0.03356195613741875,
        -0.060852669179439545,
        0.0023705975618213415,
        -0.05187757685780525,
        -0.0180495698004961,
        -0.02771766297519207,
        -0.05847467482089996,
        -0.049908582121133804,
        0.032188381999731064,
        0.04170016199350357,
        -0.007227917201817036,
        0.059870246797800064,
        0.059327132999897,
        -0.06365368515253067,
        0.02861315943300724,
        0.015487829223275185,
        0.052182506769895554,
        0.06509730964899063,
        0.06295832991600037,
        -0.012198558077216148,
        0.0005446411087177694,
        0.0009380851406604052,
        0.058394208550453186,
        0.05948871746659279,
        -0.003914425149559975,
        -0.03142319992184639,
        -0.056283138692379,
        -0.0009252471500076354,
        0.026608988642692566,
        0.00809497945010662,
        -0.059721287339925766,
        0.03534664586186409,
        -0.061539579182863235,
        -0.019058948382735252,
        0.008238078095018864,
        0.05132503807544708,
        0.0509834811091423,
        -0.05812870338559151,
        0.01533842645585537,
        -0.06659725308418274,
        -0.06157390773296356,
        -0.06319927424192429,
        -0.008965241722762585,
        0.046906985342502594,
        -0.03966855630278587,
        -0.004943457897752523,
        0.026650940999388695,
        0.057342734187841415,
        0.058394577354192734,
        -0.03677888587117195,
        -0.004419099073857069,
        0.06278576701879501,
        -0.0578179769217968,
        -0.017172545194625854,
        -0.008592047728598118,
        0.020096546038985252,
        0.002724792342633009,
        -0.061543725430965424,
        -0.03853842616081238,
        0.059297289699316025,
        -0.05401138216257095,
        0.052111100405454636,
        0.030606741085648537,
        -0.04799896851181984,
        0.039011530578136444,
        0.02032957598567009,
        -0.04284333437681198,
        0.0015046673361212015,
        -0.02553236111998558,
        -0.02501605823636055,
        -0.06556600332260132,
        0.012299002148211002,
        0.06170490011572838,
        0.06465169042348862,
        -0.01107941847294569,
        -0.001569000305607915,
        -0.039609406143426895,
        -0.010809168219566345,
        -0.06076838821172714,
        -0.06313112378120422,
        -0.02911205217242241,
        -0.0650750994682312,
        -0.04049275815486908,
        -0.03463156893849373,
        0.037966903299093246,
        -0.06267420947551727,
        -0.013634190894663334,
        -0.05711115524172783,
        -0.056947462260723114,
        -0.0031760232523083687,
        -0.05216268450021744,
        0.04655766487121582,
        -0.037008609622716904,
        0.06350087374448776,
        -0.06623239070177078,
        -0.061678700149059296,
        -0.05460217222571373,
        0.022430427372455597,
        0.06486568599939346,
        -0.025121206417679787,
        0.02203839085996151,
        -0.050373367965221405,
        -0.02305162511765957,
        0.02009998820722103,
        -0.053037285804748535,
        -0.050522878766059875,
        -0.01165950670838356,
        -0.05725507438182831,
        -0.03222457692027092,
        0.06219273805618286,
        0.03002943843603134,
        0.011701932176947594,
        0.05903128534555435,
        0.03741522878408432,
        -0.02511417120695114,
        0.04933920502662659,
        0.046825382858514786,
        -0.04535919800400734,
        -0.027477994561195374,
        0.00721213361248374,
        0.01072615198791027,
        -0.052569273859262466,
        -0.06251934170722961,
        0.035346902906894684,
        -0.007159429602324963,
        -0.06327678263187408,
        0.06485064327716827,
        -0.028482064604759216,
        0.002630589297041297,
        -0.0021329738665372133,
        0.04891573265194893,
        0.0374051071703434,
        0.046415966004133224,
        -0.028306441381573677,
        -0.05587378144264221,
        -0.05326147377490997,
        0.011055988259613514,
        -0.06275493651628494,
        -0.01463274098932743,
        0.05103138089179993,
        -0.051594946533441544,
        0.03415284305810928,
        -0.05150635913014412,
        -0.025867395102977753,
        -0.057733431458473206,
        0.055156923830509186,
        -0.0646585077047348,
        -0.004160360898822546,
        0.010510487481951714,
        0.011588984169065952,
        0.06499266624450684,
        0.04247288033366203,
        0.06060236319899559,
        0.04312461242079735,
        -0.01080816239118576,
        -0.017338955774903297,
        -0.05905098840594292,
        0.06262720376253128,
        -0.05742073804140091,
        -0.04150120168924332,
        -0.05036589503288269,
        -0.022965634241700172,
        -0.025041744112968445,
        -0.01847199723124504,
        -0.00429296400398016,
        0.010774796828627586,
        0.0505102165043354,
        0.020400015637278557,
        0.02094169706106186,
        -0.03240666538476944,
        -0.055965062230825424,
        0.0380929596722126,
        0.0532374233007431,
        -0.03228895366191864,
        0.03813211992383003,
        0.013804038055241108,
        0.04394747316837311,
        0.002237587934359908,
        -0.041412319988012314,
        0.008536217734217644,
        -0.0596589557826519,
        0.05304473266005516,
        -0.040789563208818436,
        0.009816667065024376,
        0.04256509989500046,
        0.024071544408798218,
        0.049595024436712265,
        0.04039149731397629,
        0.06214716285467148,
        0.019290192052721977,
        0.030438553541898727,
        -0.03359586000442505,
        -0.0602041594684124,
        -0.06608592718839645,
        0.06478723138570786,
        -0.06527969241142273,
        -0.018427623435854912,
        0.0069960057735443115,
        -0.04334140568971634,
        -0.06317951530218124,
        -0.05674542859196663,
        -0.06491599231958389,
        -0.03769787400960922,
        0.012940879911184311,
        -0.05184990540146828,
        0.02711161971092224,
        0.05817160755395889,
        -0.032469991594552994,
        0.020358595997095108,
        0.06460194289684296,
        0.06529369205236435,
        0.043361078947782516,
        -0.0516241081058979,
        0.04071446880698204,
        -0.00420921016484499,
        -0.010501774959266186,
        0.013540254905819893,
        0.05584283918142319,
        -0.037488337606191635,
        -0.038972243666648865,
        -0.00525690009817481,
        -0.06610596179962158,
        0.008279052563011646,
        -0.06302958726882935,
        0.0205165334045887,
        0.06558434665203094,
        -0.05381748080253601,
        0.012593526393175125,
        0.06516648083925247,
        0.0655132383108139,
        0.06391604244709015,
        0.051495205610990524,
        -0.022099928930401802,
        0.060449838638305664,
        0.038414422422647476,
        0.062162794172763824,
        -0.011740535497665405,
        0.021892813965678215,
        0.0067863790318369865,
        0.025610538199543953,
        -0.03784128278493881,
        -0.05569643899798393,
        0.019320467486977577,
        -0.06443369388580322,
        -0.014858273789286613,
        0.053924623876810074,
        -0.059324704110622406,
        -0.0008785930695012212,
        0.0026906353887170553,
        -0.0645897388458252,
        -0.06145305186510086,
        0.025608602911233902,
        -0.006147023756057024,
        0.006753555499017239,
        -0.023908013477921486,
        -0.05077517032623291,
        0.049965981394052505,
        -0.055485423654317856,
        -0.05961943417787552,
        0.05056612193584442,
        -0.03454308584332466,
        0.02721572108566761,
        0.04685370996594429,
        -0.02294846810400486,
        0.05817127227783203,
        -0.049615222960710526,
        0.06458323448896408,
        0.016992494463920593,
        -0.025250155478715897,
        0.0558650940656662,
        0.0005936142988502979,
        0.06339889019727707,
        0.000614339136518538,
        0.048352599143981934,
        -0.05764440819621086,
        -0.005687847267836332,
        -0.0420464426279068,
        0.05758380889892578,
        0.0655767098069191,
        -0.06231289729475975,
        0.05875341594219208,
        -0.019927317276597023,
        -0.03502601757645607,
        0.057917576283216476,
        -0.06538143008947372,
        -0.06040183827280998,
        0.06428305804729462,
        0.04156395047903061,
        -0.011213273741304874,
        -0.052104730159044266,
        -0.03908509388566017,
        -0.01850270666182041,
        0.06379677355289459,
        -0.03576723113656044,
        0.06362666189670563,
        0.06473871320486069,
        -0.018964406102895737,
        -0.03507208451628685,
        0.028693530708551407,
        -0.06238682568073273,
        -0.05013946443796158,
        0.0021268681157380342,
        0.04942259192466736,
        -0.029850495979189873,
        0.06522222608327866,
        0.0289975106716156,
        -0.061435550451278687,
        0.06614035367965698,
        -0.05466007441282272,
        0.012032129801809788,
        0.04132579639554024,
        0.006243182811886072,
        -0.05296715721487999,
        -0.04420752450823784,
        0.06659379601478577,
        -0.02064167894423008,
        0.02166745439171791,
        0.05135601758956909,
        0.005779684521257877,
        0.06443619728088379,
        -0.0665552094578743,
        -0.02277379296720028,
        -0.06486520916223526,
        -0.06619831174612045,
        -0.056379154324531555,
        0.01618240401148796,
        -0.007468136493116617,
        0.00068709283368662,
        -0.06401151418685913,
        -0.06303839385509491,
        0.06118534505367279,
        0.03558292239904404,
        -0.06204507499933243,
        -0.025137828662991524,
        -0.0057684690691530704,
        -0.04978909343481064,
        0.06611671298742294,
        0.009058263152837753,
        -0.00865571666508913,
        -0.06664450466632843,
        -0.0354253388941288,
        -0.06463457643985748,
        0.020340245217084885,
        0.061180878430604935,
        0.05461258441209793,
        0.06265927106142044,
        -0.007200065068900585,
        0.030685750767588615,
        -0.059507060796022415,
        -0.010438263416290283,
        -0.05080762133002281,
        0.018319835886359215,
        -0.06431343406438828,
        -0.032787226140499115,
        0.06525429338216782,
        -0.03283184766769409,
        0.047756873071193695,
        -0.06314224749803543,
        -0.019212793558835983,
        -0.04812386631965637,
        -0.013527102768421173,
        0.04594749957323074,
        0.01818448305130005,
        0.013738822191953659,
        -0.06122427433729172,
        0.06077937036752701,
        0.05160011351108551,
        -0.03553749620914459,
        0.009251844137907028,
        -0.06489506363868713,
        -0.01367274858057499,
        0.03237707540392876,
        0.05102616921067238,
        0.0644269585609436,
        -0.056581541895866394,
        0.01840325817465782,
        0.04030442610383034,
        0.04669144004583359,
        0.03130609542131424,
        -0.0226438045501709,
        0.017308078706264496,
        -0.047603316605091095,
        0.025330062955617905,
        0.0147639699280262,
        0.05753340944647789,
        -0.024967988952994347,
        0.03912802040576935,
        0.02050008997321129,
        0.01922561414539814,
        0.031220542266964912,
        -0.06069023162126541,
        -0.06132642552256584,
        -0.05149228870868683,
        -0.05462981015443802,
        0.015191234648227692,
        -0.007703552953898907,
        0.015549476258456707,
        -0.03312385082244873,
        -0.06541433185338974,
        -0.04445808753371239,
        0.058802280575037,
        -0.05135444551706314,
        0.023969611153006554,
        0.022010358050465584,
        0.058405254036188126,
        -0.025377880781888962,
        -0.03414793685078621,
        -0.04555734992027283,
        0.024901244789361954,
        -0.05718082934617996,
        0.0538562573492527,
        -0.030878644436597824,
        0.03391778841614723,
        0.04664958268404007,
        -0.025043779984116554,
        0.024455957114696503,
        0.06377045810222626,
        -0.059627827256917953,
        0.05952872335910797,
        -0.06462885439395905,
        -0.0647922232747078,
        0.06318919360637665,
        0.05716583505272865,
        0.00238236365839839,
        -0.05046601966023445,
        -0.021344801411032677,
        0.001361797214485705,
        0.0520879328250885,
        -0.04827648401260376,
        0.059064749628305435,
        0.0407428964972496,
        -0.008742124773561954,
        -0.0563107430934906,
        -0.004220528993755579,
        0.051012977957725525,
        0.06524751335382462,
        -0.06630486994981766,
        0.06547253578901291,
        -0.014077146537601948,
        -0.0661112517118454,
        0.04790182784199715,
        0.005712197627872229,
        0.026281161233782768,
        0.04883797839283943,
        0.03469972312450409,
        0.04610577970743179,
        -0.034412454813718796,
        0.02501624822616577,
        -0.04777239263057709,
        -0.03201887756586075,
        0.015105074271559715,
        0.02360197715461254,
        0.03877530246973038,
        0.028023917227983475,
        0.025484798476099968,
        -0.0377231128513813,
        0.06224581599235535,
        -0.06259850412607193,
        -0.05839317664504051,
        -0.03617004677653313,
        -0.0560312457382679,
        -0.0635366439819336,
        -0.06037651374936104,
        0.02747138775885105,
        0.05223151668906212,
        -0.002553063677623868,
        -0.051308877766132355,
        0.009916613809764385,
        0.05672715976834297,
        0.03477080538868904,
        -0.05097838118672371,
        -0.02855631522834301,
        0.043351806700229645,
        0.001607728423550725,
        0.015645116567611694,
        0.013060844503343105,
        0.0228279922157526,
        0.023803725838661194,
        -0.05529966950416565,
        -0.048858292400836945,
        0.06286492198705673,
        0.0576176643371582
    ],
    [
        -0.02817024104297161,
        0.05611785501241684,
        0.034314438700675964,
        -0.03549835458397865,
        0.057554684579372406,
        -0.042459886521101,
        0.061224859207868576,
        -0.009878642857074738,
        0.03201451152563095,
        0.05928725749254227,
        0.0474962443113327,
        -0.05577235668897629,
        -0.05854818969964981,
        -0.022469813004136086,
        -0.0565154068171978,
        0.036344412714242935,
        -0.05298851430416107,
        0.05495818704366684,
        -0.0581333190202713,
        -0.01087539829313755,
        -0.05542271211743355,
        -0.010707017034292221,
        0.05891069769859314,
        0.02244187332689762,
        0.04171100631356239,
        -0.014710571616888046,
        0.05767111852765083,
        0.05019533634185791,
        -0.055905021727085114,
        -0.05044116824865341,
        0.057355742901563644,
        -0.047979313880205154,
        -0.041772983968257904,
        -0.05135205388069153,
        -0.0013317967532202601,
        0.054713860154151917,
        -0.05516361817717552,
        -0.055918652564287186,
        -0.05413122847676277,
        0.050720229744911194,
        0.004092792514711618,
        0.057868730276823044,
        0.0500565841794014,
        -0.029813038185238838,
        -0.032295264303684235,
        0.026729822158813477,
        0.0442626029253006,
        0.05838684365153313,
        0.04099086672067642,
        -0.013268578797578812,
        0.030939986929297447,
        0.010275434702634811,
        0.05357441306114197,
        0.04088394716382027,
        0.01027410477399826,
        -0.05060461536049843,
        -0.028824636712670326,
        0.019507253542542458,
        0.0480927936732769,
        0.037935253232717514,
        -0.061655592173337936,
        0.031362634152173996,
        -0.05523721128702164,
        0.0066583422012627125,
        -0.03568270429968834,
        0.054533954709768295,
        0.042583778500556946,
        -0.04416313394904137,
        0.05372149124741554,
        -0.061622556298971176,
        -0.05756509676575661,
        -0.061592862010002136,
        0.010389032773673534,
        0.04014334827661514,
        0.012160730548202991,
        -0.012242242693901062,
        0.03289346769452095,
        0.05524774268269539,
        0.05289170891046524,
        -0.018436383455991745,
        -0.014813731424510479,
        0.0580514520406723,
        -0.057915132492780685,
        -0.015410318970680237,
        -0.04442708566784859,
        -0.013645901344716549,
        -0.006717530079185963,
        -0.05373696610331535,
        0.01838557794690132,
        0.05471789091825485,
        -0.011478944681584835,
        0.056543245911598206,
        -0.0010058231418952346,
        -0.024806424975395203,
        0.046776965260505676,
        -0.04693732038140297,
        -0.04248649254441261,
        -0.05520815774798393,
        -0.050484586507081985,
        -0.0015684285899624228,
        -0.060479991137981415,
        -0.018452828750014305,
        0.05494445562362671,
        0.056050918996334076,
        -0.00785935577005148,
        -0.01845284178853035,
        -0.05275924503803253,
        0.008964721113443375,
        -0.032400622963905334,
        -0.053155507892370224,
        -0.053728457540273666,
        -0.05837246775627136,
        -0.05951174348592758,
        -0.03975464776158333,
        0.06016869843006134,
        -0.051945749670267105,
        -0.04797615855932236,
        -0.05773534998297691,
        -0.0342419408261776,
        0.03581816330552101,
        -0.060483019798994064,
        0.04245623201131821,
        0.00781751424074173,
        0.05571584403514862,
        -0.06136927753686905,
        -0.04259738698601723,
        -0.05612210929393768,
        0.05437050387263298,
        0.05946437269449234,
        -0.03412771224975586,
        -0.026248153299093246,
        -0.04233582317829132,
        0.03425879776477814,
        0.03236846625804901,
        -0.03458284214138985,
        -0.05439943075180054,
        -0.014480313286185265,
        -0.05782104656100273,
        -0.01118031982332468,
        0.061335254460573196,
        0.045978669077157974,
        -0.011368672363460064,
        0.04340934753417969,
        0.03989887237548828,
        -0.03100917860865593,
        0.0218233410269022,
        0.061050280928611755,
        -0.05948430299758911,
        -0.05793900787830353,
        0.05238392949104309,
        -0.008467918261885643,
        -0.057503968477249146,
        -0.056414440274238586,
        -0.040917810052633286,
        -0.029219219461083412,
        -0.05821608379483223,
        0.060688965022563934,
        -0.05068570375442505,
        -0.008228462189435959,
        0.033770687878131866,
        0.04818003252148628,
        0.038384389132261276,
        0.03914788365364075,
        -0.027722062543034554,
        -0.05384715273976326,
        -0.061379313468933105,
        0.0067254165187478065,
        -0.04328173026442528,
        -0.006156495306640863,
        0.03208243101835251,
        -0.051032546907663345,
        -0.03778907284140587,
        -0.052513979375362396,
        -0.03262793645262718,
        -0.006493158172816038,
        0.05116370692849159,
        -0.061382025480270386,
        0.0031359440181404352,
        0.05467371270060539,
        0.03819648176431656,
        0.060509320348501205,
        0.017842616885900497,
        0.03736168146133423,
        0.04887143149971962,
        0.03636058792471886,
        -0.05510430783033371,
        -0.04970592260360718,
        0.05962790921330452,
        -0.06005312502384186,
        -0.03624561429023743,
        -0.0464322529733181,
        -0.013143045827746391,
        -0.0485389307141304,
        -0.014476780779659748,
        -0.0002616051060613245,
        0.023704860359430313,
        0.04562178999185562,
        -0.03467238321900368,
        0.03291895240545273,
        0.022138556465506554,
        -0.04398901015520096,
        0.03908928856253624,
        0.05189502239227295,
        -0.023639561608433723,
        -0.0010379282757639885,
        0.04423263669013977,
        0.029349064454436302,
        0.014390534721314907,
        0.01828811876475811,
        -0.013369913212954998,
        -0.04391772300004959,
        0.0531017892062664,
        -0.0550970695912838,
        0.017404843121767044,
        0.03757549449801445,
        0.03861217573285103,
        0.05835389718413353,
        0.04876597225666046,
        0.0610206164419651,
        -0.05138716474175453,
        0.036099739372730255,
        -0.019877761602401733,
        -0.055798884481191635,
        -0.061714548617601395,
        0.05958877131342888,
        -0.055271655321121216,
        -0.0007654938963241875,
        -0.015656020492315292,
        -0.007817504927515984,
        -0.05888755992054939,
        -0.06174447014927864,
        -0.05301941558718681,
        -0.03907021880149841,
        0.011235607787966728,
        -0.055813200771808624,
        -0.016827095299959183,
        0.057632796466350555,
        -0.010672954842448235,
        0.023561978712677956,
        0.06005247309803963,
        0.05959274619817734,
        0.04259851947426796,
        0.03648822009563446,
        0.0456005223095417,
        -0.03842886537313461,
        -0.03718354552984238,
        -0.04028894379734993,
        0.03197832778096199,
        -0.01741383969783783,
        -0.05740987882018089,
        0.023291857913136482,
        -0.061765145510435104,
        0.001413104124367237,
        -0.05841175094246864,
        0.013846139423549175,
        0.06140291690826416,
        -0.05914703384041786,
        0.04909294843673706,
        0.06060066446661949,
        0.06051357463002205,
        0.06178388372063637,
        0.03632195293903351,
        -0.012193599715828896,
        0.049921631813049316,
        -0.00883138831704855,
        0.03460223972797394,
        0.020941112190485,
        0.043631166219711304,
        0.0394926518201828,
        0.03377612307667732,
        -0.002499449299648404,
        -0.061051033437252045,
        -0.00525607680901885,
        -0.059932056814432144,
        -0.0038281346205621958,
        0.03204796463251114,
        -0.059155941009521484,
        0.028983311727643013,
        -0.057494085282087326,
        -0.060582276433706284,
        -0.05644795298576355,
        -0.007132301572710276,
        0.03678523376584053,
        0.021554237231612206,
        -0.03484806418418884,
        -0.06008613109588623,
        0.05227615311741829,
        -0.0490906685590744,
        -0.06157618388533592,
        0.057534899562597275,
        -0.010278567671775818,
        0.03289485722780228,
        0.030617251992225647,
        -0.012861347757279873,
        0.055531736463308334,
        -0.04768683388829231,
        0.060001011937856674,
        0.045781683176755905,
        0.013858061283826828,
        0.05173853412270546,
        -0.014675110578536987,
        0.054673343896865845,
        -0.019894443452358246,
        0.049979984760284424,
        -0.057344984263181686,
        -0.0470970943570137,
        -0.017897451296448708,
        0.03415803238749504,
        0.06004893779754639,
        -0.06110905110836029,
        0.02637963742017746,
        -0.03431113436818123,
        -0.04211421310901642,
        0.04077029600739479,
        -0.061605460941791534,
        -0.05657842755317688,
        0.06038534268736839,
        0.0009948074584826827,
        0.01925794593989849,
        -0.04990291967988014,
        -0.061255116015672684,
        -0.0037141700740903616,
        0.060838546603918076,
        -0.04882989823818207,
        0.060945723205804825,
        0.03183312341570854,
        -0.014160995371639729,
        -0.012107600457966328,
        0.04916303604841232,
        -0.045302458107471466,
        -0.05090199038386345,
        0.04537903517484665,
        0.031397607177495956,
        -0.05467546358704567,
        0.05457606166601181,
        0.008078579790890217,
        -0.05542239174246788,
        0.06205921992659569,
        -0.05987341329455376,
        0.03933897987008095,
        0.03663886711001396,
        -0.013577185571193695,
        -0.04244142025709152,
        -0.05727134272456169,
        0.062098242342472076,
        0.03544074296951294,
        0.022349458187818527,
        0.03917963430285454,
        0.013622676022350788,
        0.05991191044449806,
        -0.06091581657528877,
        -0.023369863629341125,
        -0.06100313737988472,
        -0.059525541961193085,
        -0.061297256499528885,
        0.03863903880119324,
        -0.03170245140790939,
        -0.021985484287142754,
        -0.059975944459438324,
        -0.0560494139790535,
        0.059163182973861694,
        0.022355778142809868,
        -0.04517444223165512,
        -0.008768915198743343,
        -0.04890025779604912,
        -0.04332326352596283,
        0.061840057373046875,
        0.0406944639980793,
        0.011849761009216309,
        -0.06207219138741493,
        -0.04404207691550255,
        -0.06118234619498253,
        0.04821085184812546,
        0.06002874672412872,
        0.05108918994665146,
        0.060515034943819046,
        0.02997959963977337,
        0.0543791726231575,
        -0.059557072818279266,
        -0.02433324232697487,
        -0.04088388383388519,
        0.036754027009010315,
        -0.05961909517645836,
        0.0021483046002686024,
        0.059979721903800964,
        -0.007745447102934122,
        0.05558395013213158,
        -0.057910166680812836,
        -0.03802718222141266,
        -0.05944955721497536,
        0.016398847103118896,
        0.025299223139882088,
        0.03928999975323677,
        -0.021024977788329124,
        -0.06099060922861099,
        0.055802345275878906,
        0.041755326092243195,
        -0.045615699142217636,
        -0.0028541411738842726,
        -0.06099874526262283,
        -0.03903242200613022,
        0.01712183654308319,
        0.060420356690883636,
        0.06086084991693497,
        -0.05959441512823105,
        0.022869382053613663,
        0.0422368124127388,
        -0.011619566939771175,
        0.053722020238637924,
        -0.026836542412638664,
        0.0025678693782538176,
        -0.010914377868175507,
        0.014211040921509266,
        0.024502776563167572,
        0.05226963758468628,
        -0.021409565582871437,
        -0.042758043855428696,
        0.03082623891532421,
        0.05466580018401146,
        0.007704795338213444,
        -0.05943804234266281,
        -0.05935284495353699,
        -0.06127327308058739,
        -0.05286616086959839,
        0.020433450117707253,
        0.044889092445373535,
        -0.05958453193306923,
        0.025284994393587112,
        -0.0614217109978199,
        -0.05150703340768814,
        0.058678966015577316,
        -0.02299189567565918,
        0.034449946135282516,
        0.05814965069293976,
        0.06157340109348297,
        -0.011615674942731857,
        -0.002186385914683342,
        -0.03950456157326698,
        0.02067224495112896,
        -0.05489712581038475,
        0.05955256521701813,
        -0.02606259472668171,
        0.023176848888397217,
        0.050170063972473145,
        0.04636893793940544,
        0.014403695240616798,
        0.05941735953092575,
        -0.05403296649456024,
        0.05741104483604431,
        -0.05944295972585678,
        -0.057963695377111435,
        0.06162888929247856,
        0.028045419603586197,
        -0.05071317404508591,
        0.006676237564533949,
        -0.034532222896814346,
        -0.03000994399189949,
        0.01962316781282425,
        -0.055278412997722626,
        0.054287880659103394,
        -0.011477246880531311,
        0.011625977233052254,
        -0.0555557906627655,
        -0.05190856754779816,
        0.04404169321060181,
        0.061356376856565475,
        -0.061677563935518265,
        0.05857210233807564,
        -0.0420239232480526,
        -0.061835385859012604,
        0.042545758187770844,
        0.017114238813519478,
        0.026395587250590324,
        0.044081926345825195,
        0.027060410007834435,
        0.038382843136787415,
        -0.015473714098334312,
        0.04346128925681114,
        -0.03824901580810547,
        -0.005870850291103125,
        0.03708222880959511,
        0.03602487966418266,
        0.05069328099489212,
        0.047423817217350006,
        0.050197992473840714,
        -0.037748195230960846,
        0.06155310198664665,
        -0.052751801908016205,
        -0.05732103809714317,
        -0.055483199656009674,
        -0.04501587525010109,
        -0.053643468767404556,
        -0.04639923572540283,
        0.04495106637477875,
        0.03611079603433609,
        -0.023879749700427055,
        -0.05483528971672058,
        0.003116352716460824,
        0.05803687870502472,
        0.061118993908166885,
        -0.06084323301911354,
        -0.061459243297576904,
        0.053994808346033096,
        -0.05141965672373772,
        0.021560370922088623,
        0.051035311073064804,
        0.01902712509036064,
        0.030168363824486732,
        -0.04771534726023674,
        -0.0428193174302578,
        0.05360734090209007,
        0.060328323394060135
    ],
    [
        -0.050751812756061554,
        0.05294697731733322,
        0.039633844047784805,
        -0.03347904980182648,
        0.05264006182551384,
        -0.018987761810421944,
        0.05965910851955414,
        -0.0024966427590698004,
        0.03973096236586571,
        0.05329790338873863,
        0.0506732277572155,
        -0.04010702297091484,
        -0.05229818448424339,
        -0.035697270184755325,
        -0.05739594250917435,
        0.05162524804472923,
        -0.05885319039225578,
        0.05286683514714241,
        -0.05622706934809685,
        -0.03165547922253609,
        -0.056383926421403885,
        -0.0020173792727291584,
        0.0548749640583992,
        0.026611967012286186,
        0.03469737991690636,
        0.016301622614264488,
        0.048164475709199905,
        0.05222101882100105,
        -0.057950157672166824,
        -0.05608268827199936,
        0.032869622111320496,
        -0.046407103538513184,
        0.0026683465112000704,
        -0.042142897844314575,
        -0.028926705941557884,
        0.037685610353946686,
        -0.04996263235807419,
        -0.048562828451395035,
        -0.055957019329071045,
        0.007178773637861013,
        -0.03571730852127075,
        0.056185174733400345,
        0.04863032326102257,
        -0.05513211712241173,
        -0.013401561416685581,
        0.003251986112445593,
        0.056360166519880295,
        0.05610394850373268,
        0.03976990282535553,
        -0.012679897248744965,
        0.021066632121801376,
        0.02455805614590645,
        0.05780933052301407,
        0.05493178963661194,
        0.006546568125486374,
        -0.05590209364891052,
        -0.04611021280288696,
        -0.0005573192029260099,
        0.02620982751250267,
        -0.0031324008014053106,
        -0.056826960295438766,
        0.028327234089374542,
        -0.05579066276550293,
        -0.00914203654974699,
        -0.03618216514587402,
        0.06011725962162018,
        0.011289182119071484,
        -0.044775981456041336,
        0.023378513753414154,
        -0.06090202182531357,
        -0.05887531489133835,
        -0.058496225625276566,
        0.0068911961279809475,
        0.04340571537613869,
        0.0006538342568092048,
        -0.012524869292974472,
        0.005753423552960157,
        0.054579395800828934,
        0.051741596311330795,
        -0.05469348281621933,
        -0.006872755941003561,
        0.05892271175980568,
        -0.05635290592908859,
        -0.021652664989233017,
        -0.04983644559979439,
        -0.008160365745425224,
        0.0411144383251667,
        -0.05620011314749718,
        -0.02729528211057186,
        0.055080119520425797,
        0.018998391926288605,
        0.0525483563542366,
        0.029704926535487175,
        -0.013262997381389141,
        0.04137509688735008,
        -0.034052103757858276,
        -0.031877487897872925,
        -0.017974255606532097,
        -0.03855646029114723,
        -0.02549152821302414,
        -0.05994324013590813,
        0.012808657251298428,
        0.04059150815010071,
        0.05899268016219139,
        -0.0289873369038105,
        -0.037466127425432205,
        -0.03877835348248482,
        -0.013124838471412659,
        -0.033661697059869766,
        -0.05728372558951378,
        -0.05364488437771797,
        -0.06006941571831703,
        -0.05867335945367813,
        -0.0419226698577404,
        0.05934147164225578,
        -0.0510542094707489,
        -0.04885260388255119,
        -0.05607961490750313,
        -0.04951341450214386,
        0.020753461867570877,
        -0.059357207268476486,
        0.04180712625384331,
        -0.040414053946733475,
        0.04691292345523834,
        -0.060352493077516556,
        -0.05970224365592003,
        -0.057936109602451324,
        0.04336238279938698,
        0.060040850192308426,
        -0.024693641811609268,
        -0.010715977288782597,
        -0.05365017428994179,
        0.01483975164592266,
        0.03576115518808365,
        -0.037928756326436996,
        -0.04183255508542061,
        -0.03998417779803276,
        -0.05553411319851875,
        -0.0023367495741695166,
        0.05862202122807503,
        0.059353869408369064,
        0.03328048437833786,
        0.04684334620833397,
        -0.027578039094805717,
        -0.0453508123755455,
        0.041999634355306625,
        0.055950481444597244,
        -0.05482642725110054,
        -0.05443117022514343,
        0.05431458353996277,
        -0.014128762297332287,
        -0.04198620095849037,
        -0.05850420519709587,
        -0.02345086634159088,
        0.0013298894045874476,
        -0.05879126116633415,
        0.060624025762081146,
        -0.05242419242858887,
        0.026056010276079178,
        0.03699871897697449,
        0.038952961564064026,
        0.045363180339336395,
        0.024713875725865364,
        -0.039531465619802475,
        -0.05252246558666229,
        -0.05969318002462387,
        0.016226712614297867,
        -0.05614577233791351,
        0.02252351865172386,
        0.04078414663672447,
        -0.051396243274211884,
        0.01917608641088009,
        -0.056475769728422165,
        -0.03287568688392639,
        -0.049617864191532135,
        0.03910377249121666,
        -0.06086264178156853,
        0.01764967106282711,
        0.039561036974191666,
        0.04491974040865898,
        0.059914134442806244,
        0.02621893584728241,
        0.02627597190439701,
        0.05642887204885483,
        0.02877960167825222,
        -0.045834094285964966,
        -0.0523216612637043,
        0.05718293413519859,
        -0.05646418035030365,
        0.007303825579583645,
        -0.047098349779844284,
        -0.03425361588597298,
        -0.04033394530415535,
        -0.04735817015171051,
        0.000008614541911811102,
        0.0451030395925045,
        0.0478091761469841,
        -0.04083995893597603,
        0.051523879170417786,
        -0.008019847795367241,
        -0.05385074391961098,
        0.018727922812104225,
        0.028348825871944427,
        -0.0022841861937195063,
        0.019425103440880775,
        0.048537157475948334,
        0.01971655711531639,
        0.01865144446492195,
        0.03210228681564331,
        -0.05530795827507973,
        -0.050759147852659225,
        0.04077116400003433,
        -0.059748824685811996,
        -0.004240680020302534,
        -0.015338988043367863,
        0.04457676783204079,
        0.06064768135547638,
        0.05176055058836937,
        0.05939610302448273,
        -0.047220561653375626,
        0.045325685292482376,
        -0.009840709157288074,
        -0.0498768649995327,
        -0.06095598265528679,
        0.054601505398750305,
        -0.05378612130880356,
        0.0012717614881694317,
        -0.013614516705274582,
        0.0007446978706866503,
        -0.05715769901871681,
        -0.06081455573439598,
        -0.051811907440423965,
        -0.03189384937286377,
        0.0009765903232619166,
        -0.056389566510915756,
        -0.02617865987122059,
        0.05444328114390373,
        -0.025611253455281258,
        -0.010744043625891209,
        0.060443080961704254,
        0.05905080586671829,
        0.05570792406797409,
        0.038160260766744614,
        0.051500387489795685,
        0.003461114363744855,
        -0.04019520431756973,
        -0.05068928003311157,
        0.050906479358673096,
        0.003622232936322689,
        -0.056442249566316605,
        -0.01321931928396225,
        -0.06063971295952797,
        0.026404112577438354,
        -0.05737857520580292,
        0.03884097933769226,
        0.06073538213968277,
        -0.058508142828941345,
        0.030917946249246597,
        0.06024234741926193,
        0.06046163663268089,
        0.060989364981651306,
        0.04876380413770676,
        -0.018731538206338882,
        0.05823014676570892,
        0.009598285891115665,
        0.05045563355088234,
        0.006440920289605856,
        0.048149678856134415,
        0.05297168344259262,
        0.0449727363884449,
        -0.031730856746435165,
        -0.05936609208583832,
        0.006564396899193525,
        -0.06012819707393646,
        0.018480882048606873,
        0.03391360118985176,
        -0.05657375231385231,
        0.01659069024026394,
        -0.03880370408296585,
        -0.059893228113651276,
        -0.05411672964692116,
        -0.034545715898275375,
        0.021059224382042885,
        0.015506117604672909,
        -0.021996302530169487,
        -0.05710316076874733,
        0.052055470645427704,
        -0.055830344557762146,
        -0.05978751182556152,
        0.05649585276842117,
        0.018490562215447426,
        0.0383259616792202,
        0.03757442161440849,
        -0.0019062785431742668,
        0.052608028054237366,
        -0.05116862431168556,
        0.05981038883328438,
        0.029110794886946678,
        0.011959218420088291,
        0.054442111402750015,
        0.004969337955117226,
        0.05770547688007355,
        -0.015135294757783413,
        0.04603324085474014,
        -0.05936192348599434,
        -0.025429178029298782,
        -0.013043309561908245,
        0.04751313477754593,
        0.05824613571166992,
        -0.06036161258816719,
        0.05079711228609085,
        -0.03444058075547218,
        -0.03522614762187004,
        0.048540301620960236,
        -0.06051282212138176,
        -0.05673259124159813,
        0.057816896587610245,
        -0.01567048579454422,
        0.0027908675838261843,
        -0.03523630648851395,
        -0.058888912200927734,
        0.03157006949186325,
        0.060109786689281464,
        -0.04977202042937279,
        0.05926685035228729,
        0.03431547433137894,
        -0.013190962374210358,
        -0.025131836533546448,
        0.04888779670000076,
        -0.0597304068505764,
        -0.056265782564878464,
        0.044812317937612534,
        0.03853435069322586,
        -0.05516340211033821,
        0.05547917261719704,
        0.000771589984651655,
        -0.05873541161417961,
        0.06103331968188286,
        -0.05966712534427643,
        0.019902369007468224,
        0.0471554696559906,
        0.027486827224493027,
        -0.05883323401212692,
        -0.050900958478450775,
        0.06109575554728508,
        0.02252749167382717,
        0.04204253479838371,
        0.03880484774708748,
        0.023496713489294052,
        0.05984516441822052,
        -0.060831859707832336,
        -0.03903115913271904,
        -0.06012461334466934,
        -0.060232702642679214,
        -0.05945031717419624,
        0.015661250799894333,
        -0.014910039491951466,
        -0.030957171693444252,
        -0.0590665340423584,
        -0.051717426627874374,
        0.054963234812021255,
        0.04008420184254646,
        -0.055975742638111115,
        -0.012207969091832638,
        -0.04121954366564751,
        -0.04775064066052437,
        0.06072957441210747,
        0.04573802649974823,
        -0.021646760404109955,
        -0.06104276329278946,
        -0.046399351209402084,
        -0.05865705758333206,
        -0.0033712119329720736,
        0.05795053392648697,
        0.04217144474387169,
        0.06031157076358795,
        0.000994153437204659,
        0.05622069165110588,
        -0.059209950268268585,
        -0.011862563900649548,
        -0.050643447786569595,
        -0.005023265723139048,
        -0.05761920288205147,
        -0.01326147373765707,
        0.060527168214321136,
        -0.0222080796957016,
        0.05443568155169487,
        -0.0559239462018013,
        -0.029826322570443153,
        -0.0586371086537838,
        0.03087962605059147,
        0.049650948494672775,
        0.03950164467096329,
        0.01010166760534048,
        -0.06053375452756882,
        0.05553772673010826,
        0.05062871053814888,
        -0.04203195869922638,
        -0.027299558743834496,
        -0.05977829918265343,
        -0.032488271594047546,
        0.027893317863345146,
        0.05905816704034805,
        0.05943082273006439,
        -0.05762425437569618,
        0.035704270005226135,
        0.05172877758741379,
        0.002719294046983123,
        0.014371288940310478,
        -0.036278173327445984,
        -0.010609563440084457,
        -0.029447024688124657,
        -0.02259405516088009,
        0.025524333119392395,
        0.05259892717003822,
        -0.02305995672941208,
        -0.03093678317964077,
        0.03635163605213165,
        0.0437234491109848,
        0.013339320197701454,
        -0.05986858904361725,
        -0.059846751391887665,
        -0.05915075168013573,
        -0.05009225010871887,
        -0.00027637931634671986,
        0.05237278714776039,
        -0.049022920429706573,
        0.04153232276439667,
        -0.05903687700629234,
        -0.047653231769800186,
        0.05787390097975731,
        -0.029160290956497192,
        0.025708181783556938,
        0.053528256714344025,
        0.06016009300947189,
        -0.016795087605714798,
        -0.0042449701577425,
        -0.037864215672016144,
        -0.025550920516252518,
        -0.05756598338484764,
        0.05529887601733208,
        -0.04744073376059532,
        0.052792444825172424,
        0.04125415161252022,
        0.015889769420027733,
        -0.007612626533955336,
        0.057430461049079895,
        -0.05632920190691948,
        0.05602183938026428,
        -0.059545520693063736,
        -0.05929877981543541,
        0.06069660186767578,
        0.0008084907894954085,
        -0.05605815351009369,
        -0.0247005857527256,
        -0.03321244567632675,
        -0.02086971513926983,
        0.022370029240846634,
        -0.05790725350379944,
        0.04017423093318939,
        0.0405581034719944,
        0.043572843074798584,
        -0.05675528198480606,
        0.01025137584656477,
        0.05173518881201744,
        0.060091372579336166,
        -0.060861438512802124,
        0.05942413955926895,
        -0.04591803625226021,
        -0.0608329176902771,
        0.04092712327837944,
        0.015577150508761406,
        0.01262120995670557,
        0.05478581041097641,
        0.04457312822341919,
        0.031718865036964417,
        -0.01955454610288143,
        0.056749969720840454,
        -0.033027637749910355,
        -0.040702737867832184,
        0.010017328895628452,
        0.05021187290549278,
        0.026502523571252823,
        0.0450679287314415,
        0.053358204662799835,
        -0.050631631165742874,
        0.06066923215985298,
        -0.057249296456575394,
        -0.05313200131058693,
        -0.044920530170202255,
        -0.05211910605430603,
        -0.05399975925683975,
        -0.05583168938755989,
        0.035251084715127945,
        0.014657587744295597,
        -0.02989288605749607,
        -0.048835355788469315,
        -0.010537974536418915,
        0.05722716823220253,
        0.05340144783258438,
        -0.0597759410738945,
        -0.058790531009435654,
        0.05118166655302048,
        -0.04295846074819565,
        0.0009065426420420408,
        0.051794618368148804,
        -0.006089961621910334,
        0.03944827988743782,
        -0.05362745374441147,
        -0.04848497360944748,
        0.05138121545314789,
        0.06081657484173775
    ],
    [
        -0.03992942348122597,
        0.051507167518138885,
        0.047809623181819916,
        -0.038263753056526184,
        0.058309078216552734,
        -0.05252489075064659,
        0.062026139348745346,
        0.014741460792720318,
        0.04205602779984474,
        0.056094441562891006,
        0.0530690997838974,
        -0.019198086112737656,
        -0.05380462110042572,
        -0.007555010728538036,
        -0.06027252972126007,
        0.04494317248463631,
        -0.06093596667051315,
        0.0432608500123024,
        -0.055662259459495544,
        0.03668079525232315,
        -0.052209336310625076,
        -0.02752869948744774,
        0.05841955542564392,
        0.008339500054717064,
        0.0447906032204628,
        -0.022565746679902077,
        0.052515558898448944,
        0.024956898763775826,
        -0.03286343812942505,
        -0.056223370134830475,
        0.053448937833309174,
        -0.04138739034533501,
        -0.0472748838365078,
        -0.05699701979756355,
        -0.0024662259966135025,
        0.04677390679717064,
        -0.05254555121064186,
        -0.059388600289821625,
        -0.05935797095298767,
        0.0027909616474062204,
        -0.0207201074808836,
        0.035585012286901474,
        0.05134449526667595,
        -0.04175235331058502,
        0.0027390015311539173,
        0.03637451305985451,
        0.04178435727953911,
        0.05344681814312935,
        0.04034307971596718,
        0.017914975062012672,
        0.05049890652298927,
        0.013838459737598896,
        0.05298873037099838,
        0.04811868071556091,
        -0.016501113772392273,
        -0.05865827947854996,
        -0.03473673388361931,
        -0.006402686703950167,
        0.043126072734594345,
        0.04267749562859535,
        -0.061673376709222794,
        0.00005050596155342646,
        -0.0560847669839859,
        0.03367123752832413,
        -0.043417539447546005,
        0.053724706172943115,
        0.04951608180999756,
        -0.013881958089768887,
        0.05239890515804291,
        -0.06239927187561989,
        -0.04986444115638733,
        -0.0620449036359787,
        0.00268178922124207,
        0.004224524367600679,
        0.00620872201398015,
        -0.009415549226105213,
        0.01915801130235195,
        0.04630659520626068,
        0.05160675570368767,
        -0.04860915616154671,
        -0.03894929587841034,
        0.06070093438029289,
        -0.05689382180571556,
        0.011119087226688862,
        -0.03934541717171669,
        -0.00577781954780221,
        -0.01543580275028944,
        -0.04804897680878639,
        0.0187869630753994,
        0.05635037645697594,
        -0.010279987007379532,
        0.05847960710525513,
        -0.03661536052823067,
        -0.030813131481409073,
        0.027937617152929306,
        -0.04279676452279091,
        -0.044947270303964615,
        -0.05897640809416771,
        -0.05676701292395592,
        -0.020671993494033813,
        -0.062310054898262024,
        0.018640276044607162,
        0.05934162810444832,
        0.05086797475814819,
        0.0033203691709786654,
        -0.029119858518242836,
        -0.052506450563669205,
        -0.01077606063336134,
        -0.04371299594640732,
        -0.03437235951423645,
        -0.0438339039683342,
        -0.05989990010857582,
        -0.055064938962459564,
        -0.0076350620947778225,
        0.060353074222803116,
        -0.024909961968660355,
        -0.05036124214529991,
        -0.06003046780824661,
        -0.05173011124134064,
        0.046891357749700546,
        -0.05525173991918564,
        0.043146051466464996,
        -0.02613825350999832,
        0.057375069707632065,
        -0.06255725771188736,
        -0.02010495588183403,
        -0.041086215525865555,
        0.04705303907394409,
        0.05885404720902443,
        -0.023363880813121796,
        -0.006502710282802582,
        -0.04836641252040863,
        -0.0019024593057110906,
        0.011858022771775723,
        -0.039880938827991486,
        -0.05036463961005211,
        0.013388303108513355,
        -0.04835028573870659,
        -0.029159046709537506,
        0.062240120023489,
        0.054870299994945526,
        -0.0120817506685853,
        0.05035799369215965,
        0.04372825846076012,
        -0.03434102609753609,
        0.029440987855196,
        0.06076628342270851,
        -0.05504099279642105,
        -0.055695731192827225,
        0.059194281697273254,
        -0.03586800768971443,
        -0.05896327644586563,
        -0.05789829045534134,
        -0.04262755811214447,
        -0.029189296066761017,
        -0.04946385696530342,
        0.06060091778635979,
        -0.04875044524669647,
        0.012628644704818726,
        0.018299594521522522,
        0.056497689336538315,
        0.03676008805632591,
        0.03516273573040962,
        -0.022410137578845024,
        -0.05320309102535248,
        -0.06002204865217209,
        -0.010020975023508072,
        -0.03764302283525467,
        0.019363783299922943,
        0.043514322489500046,
        -0.043950606137514114,
        -0.022704102098941803,
        -0.03235417604446411,
        -0.02813427336513996,
        -0.0029679785948246717,
        0.055253494530916214,
        -0.06215673312544823,
        0.017506437376141548,
        0.05963064357638359,
        0.04296496510505676,
        0.05896386876702309,
        0.02267264947295189,
        0.032270293682813644,
        0.01685210131108761,
        0.03795592486858368,
        -0.05004341900348663,
        -0.056677088141441345,
        0.060501813888549805,
        -0.06093103438615799,
        -0.021390993148088455,
        0.04371552914381027,
        -0.03990594670176506,
        -0.05849992483854294,
        -0.007016798947006464,
        -0.040044791996479034,
        0.04729457572102547,
        0.027697062119841576,
        -0.04498632624745369,
        0.017556210979819298,
        0.026257703080773354,
        -0.051212988793849945,
        0.01566266641020775,
        0.04420343413949013,
        0.02713216282427311,
        0.0026809859555214643,
        0.05839632451534271,
        0.05171949788928032,
        0.016526175662875175,
        -0.002649785717949271,
        -0.03469625115394592,
        -0.04690699651837349,
        0.04081180319190025,
        -0.05908073112368584,
        -0.025972791016101837,
        0.038771823048591614,
        0.03816293552517891,
        0.061551354825496674,
        0.055793147534132004,
        0.06175515428185463,
        -0.05472522974014282,
        0.03977777436375618,
        -0.003835125593468547,
        -0.05383256822824478,
        -0.06265170872211456,
        0.05790914595127106,
        -0.061226941645145416,
        -0.003224794752895832,
        -0.003960678819566965,
        -0.002869740594178438,
        -0.059729959815740585,
        -0.06081795692443848,
        -0.05347130075097084,
        -0.04453488439321518,
        0.04493899270892143,
        -0.050416555255651474,
        -0.005511545576155186,
        0.059795089066028595,
        -0.040220536291599274,
        -0.020444517955183983,
        0.05431308597326279,
        0.05713364854454994,
        0.0487791933119297,
        0.021025657653808594,
        0.05599465221166611,
        -0.02432994171977043,
        -0.049686893820762634,
        -0.016064219176769257,
        0.021802356466650963,
        -0.03590159863233566,
        -0.05103820189833641,
        0.026319095864892006,
        -0.062268711626529694,
        -0.014225994236767292,
        -0.057506266981363297,
        -0.013997937552630901,
        0.06159970164299011,
        -0.0552486777305603,
        0.029103511944413185,
        0.06265443563461304,
        0.061179306358098984,
        0.0620625801384449,
        0.050718292593955994,
        -0.003444261383265257,
        0.04935145378112793,
        -0.013241450302302837,
        -0.010664700530469418,
        0.04844749718904495,
        0.04926968365907669,
        0.011257299222052097,
        -0.017706701532006264,
        -0.012044730596244335,
        -0.06192431226372719,
        0.016043320298194885,
        -0.058209002017974854,
        -0.02809283696115017,
        0.035072389990091324,
        -0.060817550867795944,
        0.037310849875211716,
        -0.04536852240562439,
        -0.061082568019628525,
        -0.05826491490006447,
        -0.03996564820408821,
        0.032091859728097916,
        0.017861558124423027,
        -0.02496904321014881,
        -0.05889520421624184,
        0.05467784032225609,
        -0.04768937826156616,
        -0.06145495921373367,
        0.05281415581703186,
        0.012362717650830746,
        0.048426974564790726,
        -0.013921565376222134,
        0.009068517945706844,
        0.056711360812187195,
        -0.04322155937552452,
        0.06207532063126564,
        0.03161255642771721,
        0.011164410039782524,
        0.05397844687104225,
        -0.03485928475856781,
        0.057007092982530594,
        0.0017690213862806559,
        0.05026164650917053,
        -0.05996397137641907,
        -0.04277636483311653,
        -0.022937744855880737,
        0.016770193353295326,
        0.057632893323898315,
        -0.061588067561388016,
        0.0061447471380233765,
        -0.05298105254769325,
        -0.049427445977926254,
        0.03354561701416969,
        -0.06232517957687378,
        -0.057107824832201004,
        0.062358494848012924,
        -0.03533968701958656,
        0.023630445823073387,
        -0.05665157362818718,
        -0.057913243770599365,
        -0.012871713377535343,
        0.06137474253773689,
        -0.04766241833567619,
        0.0574442483484745,
        0.025329124182462692,
        0.020581765100359917,
        0.022789763286709785,
        0.033124979585409164,
        -0.022788628935813904,
        -0.04708853363990784,
        0.05637040361762047,
        0.03900326415896416,
        -0.059489160776138306,
        0.05278975889086723,
        -0.0017537253443151712,
        -0.0555720217525959,
        0.06278346478939056,
        -0.059609536081552505,
        0.03597588092088699,
        0.038872066885232925,
        0.001391728175804019,
        -0.03167801722884178,
        -0.05728280916810036,
        0.06290463358163834,
        0.04209960624575615,
        0.021161267533898354,
        0.04862043634057045,
        0.025446148589253426,
        0.05960458517074585,
        -0.06175040826201439,
        -0.0068495552986860275,
        -0.061734143644571304,
        -0.06016017496585846,
        -0.06128988787531853,
        0.05708512291312218,
        -0.043908920139074326,
        -0.008378766477108002,
        -0.05605281889438629,
        -0.04988855496048927,
        0.06074434891343117,
        0.0274101123213768,
        -0.04965135082602501,
        -0.00275537371635437,
        -0.03949156403541565,
        -0.046709172427654266,
        0.06274299323558807,
        0.05297311767935753,
        0.0231839157640934,
        -0.06279967725276947,
        -0.05248590558767319,
        -0.06258798390626907,
        0.054225824773311615,
        0.05716366320848465,
        0.054263897240161896,
        0.05540012568235397,
        0.029576098546385765,
        0.05651666224002838,
        -0.058560241013765335,
        -0.04225935786962509,
        -0.05084577947854996,
        0.052527859807014465,
        -0.05894540622830391,
        -0.005357249174267054,
        0.058482248336076736,
        0.00788105372339487,
        0.05901718512177467,
        -0.06093466281890869,
        -0.05102866515517235,
        -0.061326175928115845,
        -0.010462532751262188,
        0.004230859689414501,
        0.04556092619895935,
        0.010975521057844162,
        -0.05386203154921532,
        0.057605016976594925,
        0.04773431271314621,
        -0.031492821872234344,
        0.020533902570605278,
        -0.06148834899067879,
        -0.04357193037867546,
        0.02108652889728546,
        0.06071142852306366,
        0.061910230666399,
        -0.05970364436507225,
        0.035527393221855164,
        0.04006800428032875,
        0.025625217705965042,
        0.06029143184423447,
        -0.04302763193845749,
        0.016211815178394318,
        -0.02326774224638939,
        0.017690224573016167,
        0.010043635033071041,
        0.05800924450159073,
        0.0075737782754004,
        -0.03863799199461937,
        0.03991351276636124,
        0.05511006340384483,
        0.02644043229520321,
        -0.060166891664266586,
        -0.057673558592796326,
        -0.05889458581805229,
        -0.04242595657706261,
        0.022507520392537117,
        0.05002472549676895,
        -0.05744953826069832,
        0.028803300112485886,
        -0.062211617827415466,
        -0.03434591740369797,
        0.057191379368305206,
        -0.011514862067997456,
        0.011417255736887455,
        0.05469822511076927,
        0.05893522500991821,
        0.00036759633803740144,
        -0.013262968510389328,
        -0.035704780369997025,
        0.020715227350592613,
        -0.046945150941610336,
        0.057772181928157806,
        -0.015897512435913086,
        0.03250795230269432,
        0.05328604578971863,
        0.022309133782982826,
        0.029363734647631645,
        0.06154502183198929,
        -0.05323260277509689,
        0.059562209993600845,
        -0.058815427124500275,
        -0.05492693930864334,
        0.05826599523425102,
        0.03548925742506981,
        -0.04966197535395622,
        -0.004555102437734604,
        -0.04708174988627434,
        -0.013262973167002201,
        0.02195056527853012,
        -0.05902499705553055,
        0.05858781561255455,
        0.011510277166962624,
        0.028555147349834442,
        -0.05618082359433174,
        -0.039231956005096436,
        0.02844543382525444,
        0.06202637031674385,
        -0.06143994256854057,
        0.058429598808288574,
        -0.0496491976082325,
        -0.06259932368993759,
        0.04314693808555603,
        0.04209965467453003,
        0.029163243249058723,
        0.040396615862846375,
        0.018983731046319008,
        0.0463334321975708,
        -0.01886611618101597,
        0.03672057017683983,
        -0.026646029204130173,
        -0.04132219776511192,
        0.0003274712071288377,
        0.0004153269110247493,
        0.024253373965620995,
        0.04953383281826973,
        0.043018024414777756,
        -0.026348067447543144,
        0.057416580617427826,
        -0.05408695712685585,
        -0.05983263626694679,
        -0.04787135496735573,
        -0.04501023516058922,
        -0.045788202434778214,
        -0.03758343309164047,
        0.045200787484645844,
        0.049766525626182556,
        -0.017591970041394234,
        -0.05297306925058365,
        0.009493830613791943,
        0.058095451444387436,
        0.06235497072339058,
        -0.054319221526384354,
        -0.06248259171843529,
        0.0513937845826149,
        -0.03850672021508217,
        0.024740340188145638,
        0.03393382579088211,
        0.007374300621449947,
        0.01887238584458828,
        -0.055415473878383636,
        -0.05371776968240738,
        0.05706014111638069,
        0.06149686127901077
    ],
    [
        -0.05513923615217209,
        0.044031329452991486,
        0.04539935290813446,
        -0.037168972194194794,
        0.052872318774461746,
        -0.03609121963381767,
        0.05941960588097572,
        0.019443873316049576,
        0.04776535555720329,
        0.04395082965493202,
        0.05145832896232605,
        0.025319047272205353,
        -0.04316917434334755,
        -0.03150074556469917,
        -0.0589202344417572,
        0.05558155104517937,
        -0.060620930045843124,
        0.043972600251436234,
        -0.05463629215955734,
        0.01022122148424387,
        -0.054726630449295044,
        -0.014870823360979557,
        0.05098012462258339,
        0.014468282461166382,
        0.03863248974084854,
        0.017255352810025215,
        0.030490927398204803,
        0.03523872047662735,
        -0.045939505100250244,
        -0.05797750875353813,
        0.02837349846959114,
        -0.038815487176179886,
        -0.01234616618603468,
        -0.05137142911553383,
        -0.03520854189991951,
        0.019275814294815063,
        -0.04602200165390968,
        -0.05511656031012535,
        -0.05976574495434761,
        -0.04391926899552345,
        -0.04378398507833481,
        0.02907058782875538,
        0.050205763429403305,
        -0.05743794143199921,
        0.019513864070177078,
        0.012365213595330715,
        0.05489253252744675,
        0.048719849437475204,
        0.03697998449206352,
        0.016395876184105873,
        0.046034667640924454,
        0.023502551019191742,
        0.05756526440382004,
        0.05703479424118996,
        -0.02640392817556858,
        -0.05934649705886841,
        -0.04654308035969734,
        -0.022287163883447647,
        0.0180088859051466,
        0.00670291343703866,
        -0.05393996462225914,
        -0.006302083842456341,
        -0.055714674293994904,
        0.02291012369096279,
        -0.039319511502981186,
        0.06011297181248665,
        0.0305874552577734,
        -0.007422718685120344,
        0.021778982132673264,
        -0.06093411147594452,
        -0.0567539781332016,
        -0.058505941182374954,
        -0.0045179338194429874,
        0.009351380169391632,
        -0.014547470025718212,
        -0.009988617151975632,
        -0.0051564122550189495,
        0.04839743673801422,
        0.050851888954639435,
        -0.05827311798930168,
        -0.03042055107653141,
        0.05975547805428505,
        -0.0522463396191597,
        -0.0058873738162219524,
        -0.04323463886976242,
        0.004257492255419493,
        0.029030270874500275,
        -0.053588174283504486,
        -0.02915555238723755,
        0.05617043748497963,
        0.027516096830368042,
        0.05012333765625954,
        -0.006120301317423582,
        -0.014990435913205147,
        0.01882871985435486,
        -0.029856644570827484,
        -0.039897091686725616,
        -0.044983871281147,
        -0.05129675194621086,
        -0.03800512105226517,
        -0.06074056774377823,
        0.04062472656369209,
        0.0513632670044899,
        0.058079492300748825,
        -0.02934492938220501,
        -0.048379745334386826,
        -0.037079039961099625,
        -0.02599993348121643,
        -0.04600245878100395,
        -0.05102147161960602,
        -0.049849849194288254,
        -0.06040310487151146,
        -0.05464198440313339,
        0.0010321777081117034,
        0.059329453855752945,
        -0.028676731511950493,
        -0.04844292998313904,
        -0.059015609323978424,
        -0.056380338966846466,
        0.03530096262693405,
        -0.05270707979798317,
        0.04130491614341736,
        -0.053585801273584366,
        0.04914861544966698,
        -0.060739997774362564,
        -0.05527525022625923,
        -0.0462978221476078,
        0.03365373983979225,
        0.059710562229156494,
        -0.007349915336817503,
        0.013785536400973797,
        -0.055999305099248886,
        -0.025561125949025154,
        0.013624178245663643,
        -0.03687146306037903,
        -0.036488283425569534,
        -0.017360368743538857,
        -0.038899004459381104,
        -0.021635929122567177,
        0.05969648063182831,
        0.060675326734781265,
        0.036647602915763855,
        0.05182332918047905,
        -0.0380953811109066,
        -0.03655194118618965,
        0.044692859053611755,
        0.05059689283370972,
        -0.04933791980147362,
        -0.05237983167171478,
        0.058072518557310104,
        -0.0457630380988121,
        -0.045267503708601,
        -0.05746297910809517,
        -0.020360423251986504,
        -0.00347561645321548,
        -0.05377400293946266,
        0.060358889400959015,
        -0.04986002296209335,
        0.045532431453466415,
        0.020552458241581917,
        0.05054921656847,
        0.04333867132663727,
        0.015284037217497826,
        -0.039369869977235794,
        -0.05227617174386978,
        -0.056855134665966034,
        -0.013213962316513062,
        -0.05066303536295891,
        0.039331719279289246,
        0.04665203019976616,
        -0.04479159787297249,
        0.03356444463133812,
        -0.04197780415415764,
        -0.032899755984544754,
        -0.04408302530646324,
        0.04838383570313454,
        -0.06090758740901947,
        0.03767012804746628,
        0.05156572535634041,
        0.045932475477457047,
        0.05897167697548866,
        0.01766182668507099,
        0.02342604473233223,
        0.04999907314777374,
        0.034391626715660095,
        -0.034744665026664734,
        -0.0582682304084301,
        0.056543219834566116,
        -0.05816234275698662,
        0.029582524672150612,
        0.036132197827100754,
        -0.047338198870420456,
        -0.054519765079021454,
        -0.03939291834831238,
        -0.036854617297649384,
        0.05250360071659088,
        0.03626032918691635,
        -0.04683185741305351,
        0.04552245885133743,
        -0.015923378989100456,
        -0.056159067898988724,
        -0.0028525327797979116,
        0.00712399510666728,
        0.0411917120218277,
        0.023852014914155006,
        0.05777672305703163,
        0.041653551161289215,
        0.025513792410492897,
        0.012916261330246925,
        -0.05912307649850845,
        -0.05518646538257599,
        0.02506600320339203,
        -0.06056962534785271,
        -0.0409330315887928,
        0.010417809709906578,
        0.04380672425031662,
        0.06102337688207626,
        0.05393094941973686,
        0.05940769612789154,
        -0.047616735100746155,
        0.04492783173918724,
        0.012303824536502361,
        -0.05127919092774391,
        -0.06108958646655083,
        0.04831378534436226,
        -0.0593627467751503,
        -0.008796784095466137,
        -0.0030871897470206022,
        0.0014294733991846442,
        -0.057589948177337646,
        -0.05988413095474243,
        -0.05263376235961914,
        -0.040877070277929306,
        0.03720453381538391,
        -0.051689956337213516,
        -0.033920302987098694,
        0.055372901260852814,
        -0.04266474023461342,
        -0.046761926263570786,
        0.058749936521053314,
        0.05662228912115097,
        0.057900261133909225,
        0.019185615703463554,
        0.056834425777196884,
        0.01528187282383442,
        -0.04561774805188179,
        -0.041022803634405136,
        0.04912533238530159,
        -0.02784239500761032,
        -0.051581237465143204,
        -0.021563824266195297,
        -0.060357071459293365,
        0.020829079672694206,
        -0.052806902676820755,
        0.012865892611443996,
        0.06044437363743782,
        -0.05436814948916435,
        -0.012833453714847565,
        0.06094609200954437,
        0.06036728248000145,
        0.06087596341967583,
        0.05680831894278526,
        -0.007978525944054127,
        0.05707155168056488,
        -0.0059662326239049435,
        0.0111544169485569,
        0.036294981837272644,
        0.05418269336223602,
        0.02996825985610485,
        0.006651911418884993,
        -0.041843827813863754,
        -0.059364981949329376,
        0.016801325604319572,
        -0.05955790728330612,
        0.0015396011294797063,
        0.027872418984770775,
        -0.05674555525183678,
        0.03245295584201813,
        -0.014467092230916023,
        -0.059886328876018524,
        -0.05799196660518646,
        -0.056356631219387054,
        0.017168598249554634,
        0.011640466749668121,
        -0.02036149427294731,
        -0.05560802295804024,
        0.052177030593156815,
        -0.05654027312994003,
        -0.05786076560616493,
        0.05397111922502518,
        0.03930075466632843,
        0.047425784170627594,
        -0.0047805882059037685,
        0.020179815590381622,
        0.05132334679365158,
        -0.04877807945013046,
        0.060646623373031616,
        0.011843978427350521,
        -0.00287084118463099,
        0.05507370084524155,
        -0.022559432312846184,
        0.05885770544409752,
        0.013082308694720268,
        0.04299522936344147,
        -0.059817779809236526,
        -0.01765523850917816,
        -0.02004612796008587,
        0.03512093052268028,
        0.05625693500041962,
        -0.06033850461244583,
        0.035512782633304596,
        -0.04941428825259209,
        -0.04986398294568062,
        0.043487224727869034,
        -0.06052582710981369,
        -0.05565652996301651,
        0.05991026386618614,
        -0.04545435681939125,
        0.004513787571340799,
        -0.04784219712018967,
        -0.05193866416811943,
        0.03192756325006485,
        0.05995871126651764,
        -0.04700583219528198,
        0.05460865795612335,
        0.0122244693338871,
        0.00717530120164156,
        0.02011512592434883,
        0.03919512405991554,
        -0.057459790259599686,
        -0.054868146777153015,
        0.05563310161232948,
        0.040958911180496216,
        -0.05787349492311478,
        0.05246426537632942,
        0.0005502555868588388,
        -0.058742985129356384,
        0.0609685480594635,
        -0.05910797789692879,
        0.02637854963541031,
        0.05223308503627777,
        0.030227841809391975,
        -0.05810023844242096,
        -0.049027565866708755,
        0.06114129722118378,
        0.032959673553705215,
        0.034404583275318146,
        0.0437343530356884,
        0.030802739784121513,
        0.05939893424510956,
        -0.06091689318418503,
        -0.02187456749379635,
        -0.06059056520462036,
        -0.0599728561937809,
        -0.058701906353235245,
        0.050443753600120544,
        -0.03333250433206558,
        -0.02305706776678562,
        -0.05518124997615814,
        -0.030892839655280113,
        0.05683375895023346,
        0.04338619112968445,
        -0.056698933243751526,
        0.0029059455264359713,
        -0.025399135425686836,
        -0.047617923468351364,
        0.060930125415325165,
        0.055678315460681915,
        0.002338685793802142,
        -0.06108127906918526,
        -0.05453677847981453,
        -0.06031431257724762,
        0.025666004046797752,
        0.05010015144944191,
        0.04306619241833687,
        0.05886095017194748,
        0.003722946858033538,
        0.056084875017404556,
        -0.05894184112548828,
        -0.029841456562280655,
        -0.05552719905972481,
        0.026217060163617134,
        -0.055826663970947266,
        -0.018652377650141716,
        0.059984125196933746,
        -0.0010163653641939163,
        0.05824633315205574,
        -0.05951393395662308,
        -0.04829791933298111,
        -0.059662025421857834,
        0.01604669913649559,
        0.04080187529325485,
        0.04882407188415527,
        0.0389607809484005,
        -0.056118711829185486,
        0.055618710815906525,
        0.052135247737169266,
        -0.0286431722342968,
        0.003195704659447074,
        -0.05932961776852608,
        -0.03852219507098198,
        0.028636781498789787,
        0.05802883580327034,
        0.05988353490829468,
        -0.05666050687432289,
        0.041017185896635056,
        0.05146796256303787,
        0.034157078713178635,
        0.04724845662713051,
        -0.05015993118286133,
        0.005245236214250326,
        -0.04570436477661133,
        -0.020457811653614044,
        -0.0004065746907144785,
        0.05840433016419411,
        0.007507719565182924,
        -0.01088709570467472,
        0.04305192828178406,
        0.038833461701869965,
        0.02897561900317669,
        -0.06034936383366585,
        -0.059175748378038406,
        -0.053547680377960205,
        -0.035621870309114456,
        0.005618826486170292,
        0.054779618978500366,
        -0.04400830715894699,
        0.04059689864516258,
        -0.05907795578241348,
        -0.022086946293711662,
        0.05508195236325264,
        -0.026477769017219543,
        0.008237248286604881,
        0.05118301510810852,
        0.057295653969049454,
        -0.00402993056923151,
        -0.011325019411742687,
        -0.0406910702586174,
        -0.027912618592381477,
        -0.05358942970633507,
        0.051412079483270645,
        -0.039266977459192276,
        0.053970422595739365,
        0.04469655826687813,
        -0.013840868137776852,
        0.015576880425214767,
        0.059201084077358246,
        -0.05289152264595032,
        0.05730971321463585,
        -0.05767740309238434,
        -0.05799037963151932,
        0.0586380772292614,
        0.008994328789412975,
        -0.05486905947327614,
        -0.02510085143148899,
        -0.04665439575910568,
        0.0012225110549479723,
        0.033900029957294464,
        -0.05907728523015976,
        0.04788229614496231,
        0.04979580640792847,
        0.04926580563187599,
        -0.057401422411203384,
        0.020210634917020798,
        0.04440192133188248,
        0.06011982262134552,
        -0.060503847897052765,
        0.05913623049855232,
        -0.05084708333015442,
        -0.060842759907245636,
        0.04083880037069321,
        0.0338321328163147,
        0.010871829465031624,
        0.04728312790393829,
        0.035910118371248245,
        0.04426296800374985,
        -0.028433693572878838,
        0.054114796221256256,
        -0.01587028056383133,
        -0.05518646165728569,
        -0.03022615611553192,
        0.03632276505231857,
        -0.018622785806655884,
        0.043374329805374146,
        0.0448421835899353,
        -0.043266188353300095,
        0.05784646049141884,
        -0.058204133063554764,
        -0.054935354739427567,
        -0.03619188442826271,
        -0.05066927894949913,
        -0.048988569527864456,
        -0.05131429433822632,
        0.04170498251914978,
        0.02934223972260952,
        -0.02681817300617695,
        -0.050467830151319504,
        -0.013850968331098557,
        0.05623553320765495,
        0.05673965811729431,
        -0.05347764119505882,
        -0.05946287140250206,
        0.047818634659051895,
        -0.025044694542884827,
        0.006307495292276144,
        0.03235790133476257,
        -0.018209481611847878,
        0.031359679996967316,
        -0.05795701593160629,
        -0.05423801392316818,
        0.05558498576283455,
        0.060900669544935226
    ],
    [
        -0.04718068614602089,
        -0.01635371334850788,
        -0.0025196427013725042,
        -0.019788725301623344,
        0.06291704624891281,
        -0.053118329495191574,
        0.0643533393740654,
        -0.021049166098237038,
        -0.013036632910370827,
        0.0555422268807888,
        0.003466184251010418,
        0.06226672604680061,
        -0.05181172117590904,
        -0.04869883880019188,
        -0.06325933337211609,
        0.040042199194431305,
        -0.06415649503469467,
        0.03869134932756424,
        -0.058995746076107025,
        0.027119481936097145,
        -0.048993177711963654,
        0.008965541608631611,
        0.06386656314134598,
        0.017079224810004234,
        -0.008465010672807693,
        -0.00841626524925232,
        0.028993381187319756,
        0.05883859470486641,
        -0.012000299990177155,
        -0.022631922736763954,
        0.05569082126021385,
        0.022909410297870636,
        0.033893145620822906,
        -0.02369152195751667,
        -0.007269733585417271,
        -0.01688888855278492,
        -0.059208109974861145,
        -0.05910326540470123,
        -0.03579242527484894,
        0.032983262091875076,
        0.011079656891524792,
        0.01451735757291317,
        0.05868127569556236,
        -0.06036882847547531,
        -0.029067086055874825,
        -0.004730710759758949,
        0.058231573551893234,
        0.06364811211824417,
        0.051450155675411224,
        0.03187884762883186,
        0.03048110567033291,
        -0.037366315722465515,
        0.06435944139957428,
        0.059040095657110214,
        0.00513831852003932,
        -0.01820123940706253,
        -0.04861335828900337,
        -0.05310530588030815,
        0.04573748633265495,
        0.04497964680194855,
        -0.043440479785203934,
        -0.03567882999777794,
        -0.06064405292272568,
        0.012615623883903027,
        -0.01789013110101223,
        0.052158404141664505,
        0.051078613847494125,
        -0.054514989256858826,
        0.04177199676632881,
        -0.06537510454654694,
        -0.0447978600859642,
        -0.06515372544527054,
        0.010716143064200878,
        0.023541584610939026,
        0.0019693849608302116,
        -0.01155165582895279,
        0.052685074508190155,
        0.06417620182037354,
        0.05012889951467514,
        -0.010710298083722591,
        -0.03622714802622795,
        0.06377844512462616,
        -0.0321386493742466,
        -0.025711266323924065,
        -0.014035927131772041,
        0.017179742455482483,
        -0.04760589450597763,
        -0.03582341969013214,
        -0.02845410630106926,
        0.06165844574570656,
        -0.04807604104280472,
        0.05636557191610336,
        0.00756313931196928,
        -0.0486636757850647,
        0.000015100248674571048,
        0.04573016241192818,
        -0.05267564207315445,
        -0.043625231832265854,
        -0.05014082416892052,
        0.052338242530822754,
        -0.062265243381261826,
        -0.04889676719903946,
        0.06441352516412735,
        0.03265763074159622,
        -0.03330634534358978,
        0.03543820232152939,
        -0.015906039625406265,
        -0.044924285262823105,
        -0.061359286308288574,
        -0.04840472713112831,
        -0.051164038479328156,
        -0.06302226334810257,
        -0.054020658135414124,
        0.029883818700909615,
        0.05169367417693138,
        -0.0425245426595211,
        -0.020458262413740158,
        -0.059392329305410385,
        -0.0569145493209362,
        0.02127045765519142,
        -0.006907831411808729,
        0.036822523921728134,
        -0.05624377354979515,
        0.06502540409564972,
        -0.06527023762464523,
        0.03556620329618454,
        -0.04838419333100319,
        0.03142828866839409,
        0.06423674523830414,
        -0.05092300847172737,
        0.04018493369221687,
        -0.04990428313612938,
        0.017440004274249077,
        0.022160133346915245,
        -0.04539526626467705,
        -0.0471915602684021,
        0.019463572651147842,
        0.02011110633611679,
        -0.053955014795064926,
        0.06306352466344833,
        0.042350877076387405,
        -0.01425908599048853,
        0.056571248918771744,
        0.030858058482408524,
        0.01526626106351614,
        0.015548980794847012,
        0.06025908142328262,
        -0.021694960072636604,
        -0.05126082897186279,
        0.05489018186926842,
        -0.004212476313114166,
        -0.06169819459319115,
        -0.06092827022075653,
        0.001781721250154078,
        -0.023413805291056633,
        -0.05448650196194649,
        0.06306231766939163,
        -0.03429845720529556,
        0.009001476690173149,
        -0.048631224781274796,
        0.059620991349220276,
        0.05326833203434944,
        0.045769475400447845,
        0.001017379225231707,
        -0.05593818053603172,
        -0.05760380998253822,
        0.027436142787337303,
        -0.03343946859240532,
        -0.024145688861608505,
        0.05234113335609436,
        -0.05292878299951553,
        -0.013351463712751865,
        -0.04917998984456062,
        -0.023129750043153763,
        -0.049108464270830154,
        0.05582568049430847,
        -0.06434208899736404,
        0.007564145140349865,
        0.015835294499993324,
        0.0357283391058445,
        0.0639229416847229,
        0.03384457528591156,
        0.059375911951065063,
        -0.024524889886379242,
        0.015050617046654224,
        -0.04368732124567032,
        -0.05629531294107437,
        0.06370317935943604,
        -0.060168229043483734,
        -0.05931796133518219,
        0.04307960346341133,
        -0.023996397852897644,
        -0.041287072002887726,
        0.03530707210302353,
        -0.05208519846200943,
        -0.014645605348050594,
        0.057526253163814545,
        -0.016969267278909683,
        -0.0025653699412941933,
        -0.0025362358428537846,
        -0.018381768837571144,
        0.05131806433200836,
        0.06430558115243912,
        0.04094598442316055,
        -0.0020248922519385815,
        0.06201093643903732,
        0.049223288893699646,
        0.011003471910953522,
        -0.025092294439673424,
        0.038413211703300476,
        -0.06110820546746254,
        -0.02812388353049755,
        -0.03047177381813526,
        -0.019911184906959534,
        0.04394065588712692,
        0.0021766419522464275,
        0.016240663826465607,
        0.05169505998492241,
        0.06492350250482559,
        -0.04901164770126343,
        -0.050786010921001434,
        0.0040335641242563725,
        -0.06463165581226349,
        -0.06516414880752563,
        0.06459234654903412,
        -0.06530497223138809,
        -0.0423009917140007,
        0.01064226683229208,
        -0.04732678085565567,
        -0.060399461537599564,
        -0.05007137730717659,
        -0.0647478923201561,
        -0.04770444706082344,
        0.0489162877202034,
        -0.05362488701939583,
        0.020521333441138268,
        0.0588722825050354,
        -0.0527266301214695,
        0.036930713802576065,
        0.06312243640422821,
        0.06106667220592499,
        0.04255347326397896,
        -0.06452381610870361,
        0.06367954611778259,
        -0.04396095871925354,
        -0.04022466763854027,
        0.052124857902526855,
        0.021158406510949135,
        -0.052599355578422546,
        0.025036122649908066,
        0.06045326590538025,
        -0.06416864693164825,
        -0.03228549659252167,
        -0.05705292895436287,
        -0.013073276728391647,
        0.06382580101490021,
        -0.014901106245815754,
        0.009906300343573093,
        0.06525599211454391,
        0.06224263086915016,
        0.060624994337558746,
        0.059948116540908813,
        -0.04562325030565262,
        0.03424585983157158,
        0.007003464736044407,
        0.04308974742889404,
        0.029738184064626694,
        0.014734787866473198,
        -0.024903301149606705,
        -0.023696908727288246,
        -0.019576545804739,
        -0.06412848830223083,
        -0.02860283851623535,
        -0.05586078017950058,
        -0.061057060956954956,
        0.04098621755838394,
        -0.06282742321491241,
        -0.013304803520441055,
        -0.04494635760784149,
        -0.06257383525371552,
        -0.04770686477422714,
        -0.013933287002146244,
        0.023826146498322487,
        0.03241985663771629,
        -0.043719906359910965,
        -0.04804243519902229,
        0.0019392060348764062,
        -0.05417924001812935,
        -0.05195784941315651,
        0.05781278759241104,
        -0.039597198367118835,
        0.050399892032146454,
        0.05351652204990387,
        0.009097880683839321,
        0.05583547055721283,
        -0.050460316240787506,
        0.06318950653076172,
        -0.011094062589108944,
        -0.05153382942080498,
        0.0446293018758297,
        -0.055416613817214966,
        0.05650683492422104,
        0.005565641913563013,
        0.027433056384325027,
        -0.05204690620303154,
        -0.052871059626340866,
        -0.03726709261536598,
        0.03997734189033508,
        0.04432223364710808,
        -0.06276322901248932,
        0.031524911522865295,
        -0.013837287202477455,
        -0.024904705584049225,
        0.0511079803109169,
        -0.062188148498535156,
        -0.05486804246902466,
        0.06481536477804184,
        0.01762242801487446,
        0.017202965915203094,
        -0.047607727348804474,
        -0.04189032316207886,
        -0.007778094615787268,
        0.06465867906808853,
        0.0009065361809916794,
        0.04071804881095886,
        0.05062016844749451,
        0.043418653309345245,
        0.006703921593725681,
        -0.05570566654205322,
        -0.0265093594789505,
        -0.00007196664228104055,
        0.03842807188630104,
        0.044933974742889404,
        -0.0450093038380146,
        0.06161314621567726,
        -0.010826930403709412,
        -0.034560251981019974,
        0.0638900175690651,
        -0.057570189237594604,
        0.04018872603774071,
        -0.011347154155373573,
        -0.040589991956949234,
        -0.0038119161035865545,
        -0.0567365437746048,
        0.06543510407209396,
        -0.006543235387653112,
        -0.009555441327393055,
        0.04234599694609642,
        -0.015029116533696651,
        0.06055606156587601,
        -0.06526217609643936,
        0.025744417682290077,
        -0.06041404604911804,
        -0.062857486307621,
        -0.06231791898608208,
        0.040680062025785446,
        0.00915846973657608,
        -0.025571631267666817,
        -0.05779101327061653,
        -0.06373465061187744,
        0.05869513005018234,
        0.04064486175775528,
        -0.06353438645601273,
        -0.01939532533288002,
        -0.03568099066615105,
        -0.014008083380758762,
        0.06519447267055511,
        -0.03779912367463112,
        0.018292207270860672,
        -0.06532733887434006,
        -0.05649935454130173,
        -0.06528156250715256,
        0.05487652122974396,
        0.060452546924352646,
        0.06101372465491295,
        0.05978566035628319,
        0.027497613802552223,
        0.0021298725623637438,
        -0.025953885167837143,
        -0.06107641011476517,
        -0.053930751979351044,
        0.05908162519335747,
        -0.049490295350551605,
        -0.029924919828772545,
        0.0526246540248394,
        0.04631216824054718,
        0.026069916784763336,
        -0.0621742308139801,
        -0.053613074123859406,
        -0.048706866800785065,
        -0.05649402365088463,
        0.01617506332695484,
        0.02871197648346424,
        0.004365383181720972,
        -0.046716444194316864,
        0.060069333761930466,
        0.06198924779891968,
        0.03557785972952843,
        0.005243753083050251,
        -0.06385526806116104,
        -0.028454652056097984,
        0.059484999626874924,
        0.05993172898888588,
        0.06478311121463776,
        -0.039401862770318985,
        0.05097445845603943,
        0.03904969245195389,
        0.021942690014839172,
        0.06293315440416336,
        -0.028514625504612923,
        0.049799468368291855,
        -0.03212820366024971,
        0.01005455944687128,
        -0.036888450384140015,
        0.05873754620552063,
        -0.011335846967995167,
        -0.008701047860085964,
        0.007994427345693111,
        0.03259199112653732,
        0.012459925375878811,
        -0.05661967396736145,
        -0.055199481546878815,
        -0.056202154606580734,
        -0.0481421984732151,
        0.02655750699341297,
        0.0038922028616070747,
        -0.00825391337275505,
        -0.054334741085767746,
        -0.0653902068734169,
        -0.041946761310100555,
        0.06137737259268761,
        -0.052951354533433914,
        0.010662594810128212,
        0.043069470673799515,
        0.05290146917104721,
        0.022031119093298912,
        -0.03206276148557663,
        -0.05269555374979973,
        0.0033131283707916737,
        -0.043069250881671906,
        0.052195362746715546,
        0.0013637285446748137,
        0.01423556637018919,
        0.05953080207109451,
        0.030496161431074142,
        0.025131702423095703,
        0.06294965744018555,
        -0.06092716380953789,
        0.056793347001075745,
        -0.06070198118686676,
        -0.06224964186549187,
        0.060461852699518204,
        0.06470317393541336,
        0.018159186467528343,
        0.017488321289420128,
        -0.01620844192802906,
        0.0030444348230957985,
        0.03717231750488281,
        -0.03769361972808838,
        0.021861039102077484,
        0.016338113695383072,
        0.008187521249055862,
        -0.046274736523628235,
        -0.05735896900296211,
        0.034591782838106155,
        0.06480250507593155,
        -0.06272687762975693,
        0.0637299194931984,
        -0.033893339335918427,
        -0.06410715728998184,
        0.038821663707494736,
        0.023786624893546104,
        0.04580291733145714,
        0.050733186304569244,
        -0.013820400461554527,
        0.04943104833364487,
        0.00472113536670804,
        -0.007675121072679758,
        -0.046931661665439606,
        -0.02483617700636387,
        0.04249589890241623,
        -0.0397590771317482,
        0.05373360589146614,
        0.0008579891291446984,
        0.018276650458574295,
        0.004095733165740967,
        0.043437134474515915,
        -0.021300578489899635,
        -0.06083902716636658,
        -0.04916254058480263,
        -0.03277651593089104,
        -0.0583532452583313,
        0.0024263293016701937,
        0.03331885114312172,
        0.055366143584251404,
        0.0411166176199913,
        -0.06392530351877213,
        0.004925991874188185,
        0.052998192608356476,
        0.0629405751824379,
        0.039201512932777405,
        -0.05429169163107872,
        0.04648856446146965,
        0.0038694015238434076,
        0.004860016983002424,
        0.011316392570734024,
        0.007093324325978756,
        -0.019544197246432304,
        -0.03365855664014816,
        -0.04619785398244858,
        0.06346055865287781,
        0.04032142087817192
    ],
    [
        -0.055405449122190475,
        -0.022377192974090576,
        0.025053003802895546,
        -0.030381107702851295,
        0.06028270348906517,
        -0.046219225972890854,
        0.06059661880135536,
        -0.040046170353889465,
        -0.03425846993923187,
        0.05509279668331146,
        0.003534060437232256,
        0.05811694264411926,
        -0.04906177893280983,
        -0.04049622640013695,
        -0.05976894870400429,
        0.04866473749279976,
        -0.06102965399622917,
        0.04210422933101654,
        -0.06016525998711586,
        0.033194996416568756,
        -0.05318044126033783,
        0.012072237208485603,
        0.059362344443798065,
        0.006240949034690857,
        0.03470714017748833,
        -0.00700523192062974,
        0.008342930115759373,
        0.05945994704961777,
        0.013592942617833614,
        -0.035610832273960114,
        0.06028853356838226,
        -0.0024740686640143394,
        0.04247512295842171,
        -0.028889907523989677,
        -0.021468885242938995,
        -0.02910088747739792,
        -0.05286816507577896,
        -0.053866345435380936,
        -0.05854290723800659,
        0.054892562329769135,
        0.0027778218500316143,
        -0.006855165120214224,
        0.05480054020881653,
        -0.060850728303194046,
        -0.0018378179520368576,
        0.00659923255443573,
        0.05735181272029877,
        0.05886160582304001,
        0.05127367377281189,
        0.033503007143735886,
        0.006834350526332855,
        -0.03599461913108826,
        0.06099781021475792,
        0.05799153447151184,
        0.010775665752589703,
        -0.010816403664648533,
        -0.05139874666929245,
        -0.04664067551493645,
        0.02971925027668476,
        0.03959721699357033,
        -0.04501877352595329,
        -0.0239548496901989,
        -0.05818966031074524,
        0.03558500111103058,
        -0.019640449434518814,
        0.05417429655790329,
        0.059262193739414215,
        -0.0560583658516407,
        0.03317904472351074,
        -0.06126764416694641,
        -0.03949150815606117,
        -0.061225526034832,
        -0.026855718344449997,
        -0.006158915348351002,
        -0.05148737132549286,
        -0.002683600876480341,
        0.048953697085380554,
        0.055846940726041794,
        0.03919457644224167,
        -0.028343580663204193,
        -0.029144594445824623,
        0.0609084777534008,
        -0.02498338185250759,
        0.0006658901111222804,
        0.019193395972251892,
        0.028076831251382828,
        -0.05105530843138695,
        0.014466648921370506,
        -0.007238060235977173,
        0.05996425822377205,
        -0.038918573409318924,
        0.05707487091422081,
        0.014379163272678852,
        -0.041975803673267365,
        -0.005014429800212383,
        0.02447117492556572,
        -0.0461617149412632,
        -0.04372086375951767,
        -0.05329631641507149,
        0.034537285566329956,
        -0.060824040323495865,
        -0.05085444822907448,
        0.06011040881276131,
        0.03776270151138306,
        -0.01584818959236145,
        0.012379856780171394,
        -0.02987457625567913,
        -0.0242378581315279,
        -0.05653408542275429,
        -0.04234604164958,
        -0.055799469351768494,
        -0.05863763019442558,
        -0.05539751797914505,
        0.0226012971252203,
        0.04977697506546974,
        -0.040334463119506836,
        -0.021050725132226944,
        -0.057976964861154556,
        -0.0602237693965435,
        0.011121184565126896,
        -0.010555735789239407,
        0.030812814831733704,
        -0.05693883076310158,
        0.060880035161972046,
        -0.061180442571640015,
        0.013327457942068577,
        -0.04448467865586281,
        0.04531075805425644,
        0.05990828201174736,
        -0.041700612753629684,
        0.015606890432536602,
        -0.04864169657230377,
        0.031688183546066284,
        0.025091759860515594,
        -0.04536005109548569,
        -0.04558609798550606,
        -0.006371203809976578,
        -0.015291325747966766,
        -0.056497689336538315,
        0.06053196266293526,
        0.05181816220283508,
        0.03329161927103996,
        0.05191951245069504,
        -0.004152628593146801,
        0.023428896442055702,
        0.03988928347826004,
        0.05882657319307327,
        -0.014871126972138882,
        -0.05581725761294365,
        0.055628929287195206,
        -0.0229097418487072,
        -0.06040184572339058,
        -0.0590074323117733,
        -0.0028675037901848555,
        -0.046702273190021515,
        -0.056586358696222305,
        0.06105252355337143,
        -0.0379607155919075,
        0.011693640612065792,
        -0.051272206008434296,
        0.06030035763978958,
        0.04506240040063858,
        0.024414002895355225,
        -0.01865021139383316,
        -0.05434765666723251,
        -0.05373823642730713,
        0.016872312873601913,
        -0.05573893338441849,
        -0.016194818541407585,
        0.05171162262558937,
        -0.04263340309262276,
        0.011004303582012653,
        -0.052045658230781555,
        -0.03710358217358589,
        -0.04666702821850777,
        0.06007513403892517,
        -0.06116936728358269,
        0.01527000404894352,
        0.01723605953156948,
        0.047545384615659714,
        0.06050213798880577,
        0.022499918937683105,
        0.05984979495406151,
        -0.0015059199649840593,
        0.0073453462682664394,
        -0.03585068881511688,
        -0.058572154492139816,
        0.05967297777533531,
        -0.057162828743457794,
        -0.053454577922821045,
        0.03368772938847542,
        -0.029612205922603607,
        -0.05012834444642067,
        0.019541025161743164,
        -0.044769443571567535,
        -0.0244277473539114,
        0.05811005085706711,
        -0.025799846276640892,
        0.0035384325310587883,
        0.006614119280129671,
        -0.011939157731831074,
        0.055478788912296295,
        0.060743704438209534,
        0.054365672171115875,
        0.004457327537238598,
        0.05731721222400665,
        0.03322413191199303,
        0.017756782472133636,
        -0.03615986183285713,
        0.027079002931714058,
        -0.05795201659202576,
        -0.031094413250684738,
        -0.027920618653297424,
        -0.04735393077135086,
        0.05688502639532089,
        0.0015336829237639904,
        0.039357010275125504,
        0.054004210978746414,
        0.06104159355163574,
        -0.052647437900304794,
        -0.02581082098186016,
        -0.022512614727020264,
        -0.06070997565984726,
        -0.06123217195272446,
        0.05938813090324402,
        -0.06116034463047981,
        -0.040565501898527145,
        0.0381707102060318,
        -0.052500009536743164,
        -0.057704340666532516,
        -0.039290573447942734,
        -0.060817357152700424,
        -0.04834617301821709,
        0.043081384152173996,
        -0.022637465968728065,
        0.024498173967003822,
        0.05681968107819557,
        -0.03909039497375488,
        0.026018209755420685,
        0.05916522070765495,
        0.05752414092421532,
        0.03637690097093582,
        -0.0600598119199276,
        0.059684447944164276,
        -0.046973541378974915,
        -0.035430580377578735,
        0.04669490456581116,
        0.03140358254313469,
        -0.05711353197693825,
        0.03608336299657822,
        0.04589228332042694,
        -0.0605262853205204,
        -0.038193538784980774,
        -0.055390287190675735,
        -0.030248312279582024,
        0.06093144789338112,
        -0.031036077067255974,
        0.0043204836547374725,
        0.06120111048221588,
        0.060793306678533554,
        0.05936290696263313,
        0.06057628616690636,
        -0.04433212801814079,
        0.021955672651529312,
        -0.01807209849357605,
        0.03733682632446289,
        0.009822620078921318,
        0.010046545416116714,
        -0.0001452207943657413,
        -0.040983524173498154,
        -0.03813517466187477,
        -0.06051384657621384,
        -0.04697149991989136,
        -0.05714075267314911,
        -0.05773228779435158,
        0.03300352394580841,
        -0.05990641564130783,
        -0.001724770525470376,
        -0.02284705638885498,
        -0.05667461082339287,
        -0.05651754513382912,
        -0.04862562566995621,
        0.022341277450323105,
        0.035623062402009964,
        -0.056428298354148865,
        -0.05270978435873985,
        0.018126484006643295,
        -0.060312073677778244,
        -0.054899025708436966,
        0.05591634288430214,
        -0.02804798074066639,
        0.05300760269165039,
        0.04182533174753189,
        0.010161425918340683,
        0.05653580278158188,
        -0.04362493380904198,
        0.06033919006586075,
        -0.000816504645626992,
        -0.05243254080414772,
        0.04019191861152649,
        -0.053964048624038696,
        0.05795587971806526,
        0.010479544289410114,
        0.024179236963391304,
        -0.04885808378458023,
        -0.05699244886636734,
        -0.05086459964513779,
        0.025216279551386833,
        0.055179644376039505,
        -0.060236867517232895,
        0.024255480617284775,
        -0.02263057604432106,
        -0.05278906226158142,
        0.054990652948617935,
        -0.059737544506788254,
        -0.050168782472610474,
        0.061123140156269073,
        0.02423340268433094,
        -0.004969311412423849,
        -0.05452459305524826,
        -0.05128862336277962,
        0.012992480769753456,
        0.06089130416512489,
        -0.012491765432059765,
        0.04912329837679863,
        0.059463683515787125,
        0.019779851660132408,
        0.01447764690965414,
        -0.044103074818849564,
        -0.01598675735294819,
        -0.03740162029862404,
        0.051298100501298904,
        0.046437256038188934,
        -0.054797299206256866,
        0.05802593007683754,
        -0.022242840379476547,
        -0.05090644210577011,
        0.06087259575724602,
        -0.05575602501630783,
        0.05098172649741173,
        0.019070874899625778,
        -0.0409061498939991,
        -0.03282149136066437,
        -0.05301957577466965,
        0.061270661652088165,
        0.010193181224167347,
        -0.024617960676550865,
        0.029566235840320587,
        -0.0005529102054424584,
        0.05891795828938484,
        -0.06126905977725983,
        0.032658178359270096,
        -0.0586543083190918,
        -0.05867615342140198,
        -0.06012699753046036,
        0.05175057053565979,
        0.030406462028622627,
        -0.019544407725334167,
        -0.05165855586528778,
        -0.05979045853018761,
        0.05677167326211929,
        0.039627522230148315,
        -0.061008576303720474,
        -0.007773923221975565,
        -0.013194375671446323,
        -0.02814292721450329,
        0.06112521514296532,
        -0.03855865076184273,
        0.03777676448225975,
        -0.06123606860637665,
        -0.05735817924141884,
        -0.06120114400982857,
        0.05845245346426964,
        0.05613446608185768,
        0.05260676518082619,
        0.05682597681879997,
        0.020864149555563927,
        -0.000261634704656899,
        -0.049662455916404724,
        -0.05972517281770706,
        -0.05431103706359863,
        0.056429971009492874,
        -0.04675333946943283,
        0.009074075147509575,
        0.053853850811719894,
        0.0377071350812912,
        0.03940262645483017,
        -0.060413192957639694,
        -0.04859359189867973,
        -0.04653230682015419,
        -0.0525495782494545,
        0.03736477717757225,
        0.04659320041537285,
        0.02980240061879158,
        -0.05336824432015419,
        0.05684173107147217,
        0.05716188624501228,
        0.032786063849925995,
        0.030471526086330414,
        -0.060298625379800797,
        -0.03496566787362099,
        0.04222837835550308,
        0.058656226843595505,
        0.060979124158620834,
        -0.021259795874357224,
        0.052088428288698196,
        0.0510648712515831,
        0.0465577132999897,
        0.05946926772594452,
        -0.027309561148285866,
        0.05769994854927063,
        -0.013744057156145573,
        0.02318313531577587,
        -0.02534305490553379,
        0.055365730077028275,
        -0.012564552016556263,
        0.03255729004740715,
        0.0179295651614666,
        0.03380392864346504,
        0.01609770394861698,
        -0.0601232536137104,
        -0.0582735612988472,
        -0.05478714406490326,
        -0.04633790999650955,
        0.04288588836789131,
        -0.01876961812376976,
        -0.01744043081998825,
        -0.05685782432556152,
        -0.06124227121472359,
        -0.03245054930448532,
        0.05568554997444153,
        -0.04957647994160652,
        0.0020020906813442707,
        0.04797923192381859,
        0.0566026009619236,
        0.03837049379944801,
        -0.04951229318976402,
        -0.024779586121439934,
        0.01335956808179617,
        -0.04770921543240547,
        0.04039492830634117,
        0.015158018097281456,
        0.028812212869524956,
        0.05533560365438461,
        0.03962117061018944,
        0.03919404000043869,
        0.05930827185511589,
        -0.059860799461603165,
        0.05868623033165932,
        -0.06024662405252457,
        -0.05806201696395874,
        0.055938318371772766,
        0.06053594499826431,
        0.01650223694741726,
        0.0259573832154274,
        -0.013907814398407936,
        0.02652682177722454,
        0.05316855385899544,
        -0.04522909224033356,
        0.014492339454591274,
        0.025549747049808502,
        0.005755153018981218,
        -0.05901972949504852,
        -0.05312814190983772,
        0.042735952883958817,
        0.06096259877085686,
        -0.0608513318002224,
        0.060661785304546356,
        -0.042082078754901886,
        -0.06046687811613083,
        0.04690147191286087,
        0.03931518644094467,
        0.041956957429647446,
        0.04698236659169197,
        0.0490860678255558,
        0.047443728893995285,
        0.008094224147498608,
        -0.010595260187983513,
        -0.037291351705789566,
        -0.04420362785458565,
        0.025203801691532135,
        -0.0490923672914505,
        0.055054351687431335,
        0.012852436862885952,
        0.010532328858971596,
        0.014222808182239532,
        0.05298573151230812,
        -0.05419886112213135,
        -0.056777775287628174,
        -0.041591957211494446,
        -0.05071750655770302,
        -0.058329027146101,
        0.007208436261862516,
        0.04043367877602577,
        0.06042875349521637,
        0.04641612246632576,
        -0.059891510754823685,
        0.016252748668193817,
        0.04845528304576874,
        0.05876772478222847,
        0.04640491306781769,
        -0.05327906459569931,
        0.038436125963926315,
        0.013370629400014877,
        -0.0042288522236049175,
        0.02409319020807743,
        -0.04054659977555275,
        -0.02447470836341381,
        -0.0572158508002758,
        -0.02342834137380123,
        0.06029573082923889,
        0.0520031563937664
    ],
    [
        -0.059281688183546066,
        -0.060026124119758606,
        -0.034059830009937286,
        0.038542356342077255,
        0.05447642132639885,
        -0.0341336727142334,
        0.06140037253499031,
        -0.03868211433291435,
        -0.016802873462438583,
        0.05721064656972885,
        -0.013110022991895676,
        0.049979083240032196,
        -0.052865758538246155,
        -0.010972593910992146,
        -0.05921148881316185,
        0.040803078562021255,
        -0.0624208003282547,
        0.055175457149744034,
        -0.06274349242448807,
        0.05323474109172821,
        -0.06128119304776192,
        0.045767754316329956,
        0.06133979931473732,
        0.021360961720347404,
        0.0214607585221529,
        -0.01500866748392582,
        -0.024771489202976227,
        0.06225726008415222,
        -0.00789080560207367,
        0.0033108764328062534,
        0.0543130524456501,
        -0.054939620196819305,
        -0.055052176117897034,
        -0.03025270625948906,
        -0.031908050179481506,
        0.033481497317552567,
        -0.05606629699468613,
        -0.05667499080300331,
        -0.03888817876577377,
        0.0531277135014534,
        0.053861264139413834,
        -0.022847244516015053,
        0.055333059281110764,
        -0.05707533285021782,
        0.05432042106986046,
        -0.013869920745491982,
        0.009896824136376381,
        0.06228481978178024,
        0.0520390085875988,
        -0.01705404743552208,
        -0.019704774022102356,
        -0.016760652884840965,
        0.055165715515613556,
        0.00230534840375185,
        0.04840640351176262,
        -0.0026519878301769495,
        -0.025334728881716728,
        -0.034748420119285583,
        0.010065567679703236,
        0.05405506491661072,
        -0.02510594204068184,
        -0.0118569852784276,
        -0.05078995227813721,
        -0.03553680703043938,
        0.004954674281179905,
        -0.0026061839889734983,
        0.05741780996322632,
        -0.037416011095047,
        0.06201106682419777,
        -0.06308326870203018,
        -0.05891876667737961,
        -0.06267688423395157,
        -0.05235939100384712,
        0.05195464566349983,
        -0.03647458180785179,
        0.005132053513079882,
        0.049049682915210724,
        0.05981656536459923,
        0.05310997739434242,
        0.0015784899005666375,
        -0.039066385477781296,
        0.04681039974093437,
        -0.03869233652949333,
        -0.012696294113993645,
        0.0006004816386848688,
        0.04043728485703468,
        -0.04420286789536476,
        0.054181113839149475,
        -0.029911687597632408,
        0.06272927671670914,
        -0.06261539459228516,
        0.04480338841676712,
        0.040949419140815735,
        -0.056991927325725555,
        0.005817575380206108,
        -0.032793015241622925,
        -0.057056576013565063,
        -0.024128584191203117,
        -0.051764972507953644,
        0.04481329023838043,
        -0.05877489596605301,
        -0.004598996136337519,
        0.06080399826169014,
        -0.027146538719534874,
        0.05859902501106262,
        -0.037805188447237015,
        -0.05926705524325371,
        -0.031024742871522903,
        -0.05725456774234772,
        -0.05759238079190254,
        -0.03640365973114967,
        -0.024774683639407158,
        0.010807769373059273,
        -0.03681980073451996,
        0.021775590255856514,
        -0.02183271013200283,
        0.04678045213222504,
        -0.00691881962120533,
        -0.05853857472538948,
        -0.03698120266199112,
        -0.021092990413308144,
        -0.008404282853007317,
        -0.02879839576780796,
        0.05431284382939339,
        -0.06254994869232178,
        -0.010941334068775177,
        -0.05307536572217941,
        0.059622135013341904,
        0.047002196311950684,
        -0.050314467400312424,
        0.047417327761650085,
        -0.05082714930176735,
        0.01383710466325283,
        0.04893498122692108,
        -0.05398726835846901,
        -0.039587099105119705,
        0.04844323918223381,
        -0.062354933470487595,
        0.014952259138226509,
        0.055645182728767395,
        0.0051599289290606976,
        0.0014084050199016929,
        0.045329950749874115,
        0.04157201945781708,
        -0.05679227411746979,
        0.04608403146266937,
        0.03943166509270668,
        -0.05597560480237007,
        -0.0483395978808403,
        0.02680790424346924,
        -0.01737254112958908,
        -0.06286320835351944,
        -0.05001388490200043,
        0.06151829659938812,
        -0.045644789934158325,
        -0.052017100155353546,
        0.06281313300132751,
        -0.007704033516347408,
        0.041752465069293976,
        -0.0472954586148262,
        0.062120210379362106,
        -0.057492803782224655,
        0.047462090849876404,
        0.03994366154074669,
        -0.04517051577568054,
        0.016720499843358994,
        0.05950938165187836,
        -0.05464655160903931,
        0.000487989658722654,
        0.05443486571311951,
        -0.045250825583934784,
        0.0390620194375515,
        -0.06248272582888603,
        -0.04820326715707779,
        -0.04154641553759575,
        0.0517195425927639,
        -0.06285137683153152,
        0.05234382674098015,
        0.03907538577914238,
        0.05053267255425453,
        -0.008597102016210556,
        0.048112522810697556,
        0.05983327701687813,
        0.033360645174980164,
        -0.03496885299682617,
        -0.05791494995355606,
        -0.002348775276914239,
        0.05413948744535446,
        -0.03405237942934036,
        -0.05528085306286812,
        -0.00903808418661356,
        -0.05539761856198311,
        -0.023222649469971657,
        0.04437427595257759,
        0.03349114581942558,
        -0.03533582761883736,
        0.04740409553050995,
        -0.026854053139686584,
        -0.037372078746557236,
        0.015487798489630222,
        0.01483960822224617,
        0.025820154696702957,
        0.05277462303638458,
        0.03831646963953972,
        0.012740379199385643,
        0.040401846170425415,
        0.04298266023397446,
        0.04831497743725777,
        -0.014416326768696308,
        0.05273350328207016,
        -0.0618540495634079,
        0.05179869011044502,
        0.05833856761455536,
        -0.022794781252741814,
        0.04859214276075363,
        0.0050246394239366055,
        -0.03885200247168541,
        0.052800703793764114,
        0.06284759193658829,
        -0.057236894965171814,
        -0.008199676871299744,
        -0.060029029846191406,
        -0.04225018247961998,
        -0.06275691837072372,
        0.061851274222135544,
        -0.06209216266870499,
        -0.03688344359397888,
        0.014698727987706661,
        -0.05981690436601639,
        -0.01348651573061943,
        0.00876846443861723,
        -0.060865119099617004,
        0.007945559918880463,
        0.04678763076663017,
        -0.023117613047361374,
        -0.00971189420670271,
        0.057994622737169266,
        -0.022308053448796272,
        0.04668346419930458,
        0.05827871337532997,
        0.061350032687187195,
        -0.051214031875133514,
        -0.04619693011045456,
        0.019363611936569214,
        0.018440794199705124,
        0.027025995776057243,
        0.06073381006717682,
        0.012273616157472134,
        -0.03561675548553467,
        0.04674200713634491,
        0.005973794963210821,
        -0.060292065143585205,
        -0.04210813716053963,
        -0.05936972796916962,
        0.04964878782629967,
        0.05840999633073807,
        -0.028130218386650085,
        0.04152054339647293,
        0.06277051568031311,
        0.059277042746543884,
        -0.018116192892193794,
        0.05201682075858116,
        0.001610772917047143,
        0.03929203748703003,
        -0.044868890196084976,
        0.05644083023071289,
        -0.013596734963357449,
        -0.04321960359811783,
        0.04131241887807846,
        -0.031459297984838486,
        -0.04510732740163803,
        -0.06276071071624756,
        0.040853098034858704,
        -0.06102634593844414,
        -0.050846707075834274,
        0.046770110726356506,
        -0.06048990786075592,
        0.03435103967785835,
        -0.01670438051223755,
        -0.026302997022867203,
        -0.055119022727012634,
        0.007232502102851868,
        -0.05066385492682457,
        -0.020425835624337196,
        -0.02435958757996559,
        -0.01589212194085121,
        0.05365150421857834,
        -0.05509985238313675,
        -0.03742419183254242,
        -0.03334049507975578,
        -0.04928864166140556,
        0.022743742913007736,
        0.05521456152200699,
        -0.047479480504989624,
        0.03805161267518997,
        -0.03094148449599743,
        0.06256936490535736,
        0.019796958193182945,
        0.002878711558878422,
        0.056280460208654404,
        -0.02589697577059269,
        0.05307967588305473,
        0.002251754514873028,
        0.052117351442575455,
        0.049664173275232315,
        -0.05053524672985077,
        -0.05389083921909332,
        0.045797765254974365,
        0.0479266494512558,
        -0.04770069196820259,
        -0.022851597517728806,
        -0.04558761045336723,
        -0.012250439263880253,
        0.053786929696798325,
        -0.0626513808965683,
        -0.048740316182374954,
        0.05014883354306221,
        0.045234426856040955,
        0.007952806539833546,
        -0.0542982816696167,
        -0.03347013145685196,
        -0.039812225848436356,
        0.061397746205329895,
        -0.021104948595166206,
        0.0358952060341835,
        0.06306099891662598,
        0.0598677322268486,
        0.035933639854192734,
        0.04783208295702934,
        -0.04690620303153992,
        -0.05416375771164894,
        0.04830603674054146,
        0.06027664616703987,
        0.025341980159282684,
        0.05965143442153931,
        0.006783655844628811,
        -0.052670542150735855,
        0.06252068281173706,
        -0.022358780726790428,
        0.0038383679930120707,
        0.05285075306892395,
        -0.010547238402068615,
        0.04697175323963165,
        -0.01641874387860298,
        0.06308809667825699,
        -0.018368199467658997,
        -0.024863889440894127,
        0.04569380730390549,
        0.03913319110870361,
        0.0537368543446064,
        -0.06304516643285751,
        0.028415659442543983,
        -0.055611155927181244,
        -0.05642170459032059,
        -0.06177747622132301,
        0.03935227170586586,
        -0.051168836653232574,
        -0.053173795342445374,
        -0.05720057338476181,
        -0.06242010369896889,
        0.046232953667640686,
        0.0618208572268486,
        -0.06156815215945244,
        0.025575632229447365,
        -0.04311148077249527,
        -0.03661954402923584,
        0.06202105060219765,
        -0.026513703167438507,
        -0.0158202163875103,
        -0.0624987967312336,
        -0.0533590167760849,
        -0.0625436082482338,
        0.01368673425167799,
        0.06194261834025383,
        0.06083954870700836,
        0.05479174107313156,
        -0.014710879884660244,
        0.044790349900722504,
        -0.031630877405405045,
        -0.060112301260232925,
        -0.0519590862095356,
        0.019789263606071472,
        -0.04573976248502731,
        -0.030323434621095657,
        0.04063660278916359,
        -0.048202283680438995,
        0.037960365414619446,
        -0.028919637203216553,
        -0.002396747237071395,
        -0.06053485348820686,
        -0.05317717790603638,
        -0.008678202517330647,
        0.03924190253019333,
        -0.03814996778964996,
        -0.039204999804496765,
        0.059646930545568466,
        0.012930978089571,
        0.01162708830088377,
        -0.02932681515812874,
        -0.06081774830818176,
        -0.05222945287823677,
        0.055562082678079605,
        0.058362144976854324,
        0.06078597903251648,
        -0.04861665517091751,
        -0.0064589520916342735,
        0.039137061685323715,
        -0.007491260301321745,
        0.058009035885334015,
        -0.044942937791347504,
        0.06102221831679344,
        0.0004166508442722261,
        0.031054068356752396,
        0.015547549352049828,
        0.035890232771635056,
        0.020960789173841476,
        0.037114739418029785,
        -0.027285531163215637,
        0.03634648397564888,
        0.04750483110547066,
        -0.029737714678049088,
        0.029699474573135376,
        -0.04088694229722023,
        -0.05519668012857437,
        0.036545805633068085,
        -0.02936561219394207,
        -0.0583377331495285,
        -0.06132190674543381,
        -0.06063665822148323,
        -0.05879576504230499,
        0.05918731912970543,
        -0.05169590190052986,
        0.027804922312498093,
        -0.014332860708236694,
        0.02685636281967163,
        0.04230274632573128,
        -0.05948243290185928,
        0.013299542479217052,
        0.05197141692042351,
        -0.043006692081689835,
        0.04639512300491333,
        0.05386226251721382,
        -0.049519479274749756,
        0.03719630464911461,
        0.060233209282159805,
        0.013951621018350124,
        0.059958316385746,
        -0.06269460916519165,
        0.04717716574668884,
        -0.06262070685625076,
        -0.0479123517870903,
        0.0619165264070034,
        0.054036371409893036,
        0.05703061446547508,
        -0.01520791556686163,
        0.01339828409254551,
        0.02266520820558071,
        0.04427022859454155,
        -0.0042548105120658875,
        -0.01140715554356575,
        -0.005877843592315912,
        0.004314339254051447,
        -0.05228729918599129,
        -0.00242359540425241,
        0.05884780362248421,
        0.06280689686536789,
        -0.06091764196753502,
        0.06281936913728714,
        0.003665175521746278,
        -0.05919066444039345,
        0.049030374735593796,
        0.0549047514796257,
        0.02995077520608902,
        0.028157783672213554,
        0.05925184488296509,
        0.0393528938293457,
        0.03296129032969475,
        -0.010059885680675507,
        -0.05492975190281868,
        -0.03623100370168686,
        -0.01748243160545826,
        0.029155777767300606,
        0.05937637761235237,
        0.03684534877538681,
        -0.012728751637041569,
        0.05580207705497742,
        0.038436055183410645,
        -0.04291832819581032,
        -0.04665222764015198,
        0.044071368873119354,
        -0.054397083818912506,
        -0.06298921257257462,
        -0.0029738356824964285,
        -0.02410278469324112,
        0.05642404034733772,
        0.05215926840901375,
        -0.053178638219833374,
        -0.0029453153256326914,
        0.043354034423828125,
        0.054335009306669235,
        0.06090550869703293,
        0.06072547659277916,
        0.05575689300894737,
        -0.014465461485087872,
        -0.0009924238547682762,
        0.052370719611644745,
        -0.0468970388174057,
        -0.0003180109488312155,
        -0.002446588361635804,
        -0.040096383541822433,
        0.06273633241653442,
        0.0013002089690417051
    ],
    [
        -0.06020718067884445,
        -0.05945347249507904,
        -0.035593993961811066,
        0.04806888476014137,
        0.05749371275305748,
        -0.03561977297067642,
        0.0609714537858963,
        -0.05086847022175789,
        -0.01795690506696701,
        0.05847271531820297,
        -0.018898578360676765,
        0.04038149490952492,
        -0.05499643087387085,
        -0.012526809237897396,
        -0.058929070830345154,
        0.04164266958832741,
        -0.06062782555818558,
        0.05799575522542,
        -0.06188935041427612,
        0.056802891194820404,
        -0.060213569551706314,
        0.010949790477752686,
        0.06105193868279457,
        0.01408580131828785,
        0.024562064558267593,
        -0.02225451171398163,
        -0.03470607101917267,
        0.0615004263818264,
        0.0018186707748100162,
        -0.015284037217497826,
        0.05316953733563423,
        -0.0418877936899662,
        -0.05035367235541344,
        -0.01670248620212078,
        -0.036071985960006714,
        0.02659722976386547,
        -0.05627778172492981,
        -0.057132214307785034,
        -0.0588349886238575,
        0.05608038976788521,
        0.0446576327085495,
        -0.013605119660496712,
        0.05103670433163643,
        -0.056452687829732895,
        0.05657234415411949,
        -0.010854128748178482,
        0.010349885560572147,
        0.06057649105787277,
        0.056627895683050156,
        -0.037655334919691086,
        -0.014415423385798931,
        -0.0010617742082104087,
        0.0573449544608593,
        0.006343814078718424,
        0.049139272421598434,
        -0.00223343912512064,
        -0.03466643765568733,
        -0.03217215836048126,
        -0.013832668773829937,
        0.051659464836120605,
        -0.014049796387553215,
        -0.017033834010362625,
        -0.05321051552891731,
        -0.032507508993148804,
        -0.004337412770837545,
        0.01615867018699646,
        0.058572687208652496,
        -0.031980305910110474,
        0.059906307607889175,
        -0.062043458223342896,
        -0.05635453760623932,
        -0.061867352575063705,
        -0.048644017428159714,
        0.04713179171085358,
        -0.041317809373140335,
        -0.0043680667877197266,
        0.053051937371492386,
        0.054852649569511414,
        0.05024298280477524,
        -0.0031269320752471685,
        -0.035272762179374695,
        0.04672129452228546,
        -0.044619932770729065,
        0.00010450757690705359,
        0.02439558506011963,
        0.043217141181230545,
        -0.04961102455854416,
        0.05602401867508888,
        -0.01739860139787197,
        0.06160317733883858,
        -0.060748063027858734,
        0.0531306229531765,
        0.051158465445041656,
        -0.05449901893734932,
        -0.005639547016471624,
        -0.035960711538791656,
        -0.05907832458615303,
        -0.0351424477994442,
        -0.050977982580661774,
        0.03438863903284073,
        -0.05706712603569031,
        -0.009842765517532825,
        0.05961858108639717,
        -0.03732898086309433,
        0.05895855650305748,
        -0.03451782837510109,
        -0.05880424752831459,
        -0.0312024038285017,
        -0.06019679456949234,
        -0.0592389814555645,
        -0.04043218120932579,
        -0.009525545872747898,
        -0.013566944748163223,
        -0.02579045295715332,
        0.016418682411313057,
        -0.020293574780225754,
        0.05125948041677475,
        -0.015513935126364231,
        -0.05902452394366264,
        -0.026486117392778397,
        -0.009615719318389893,
        -0.012194679118692875,
        -0.02257654443383217,
        0.051715921610593796,
        -0.06125122681260109,
        -0.013422693125903606,
        -0.03881959617137909,
        0.05713542923331261,
        0.04847126826643944,
        -0.05123848468065262,
        0.034424543380737305,
        -0.05075925216078758,
        0.018785487860441208,
        0.0464622899889946,
        -0.05501260608434677,
        -0.043672673404216766,
        0.04368363320827484,
        -0.06107854098081589,
        -0.01543449331074953,
        0.05038595199584961,
        0.03183997794985771,
        0.00043783080764114857,
        0.04480251297354698,
        0.010127878747880459,
        -0.05270892754197121,
        0.056230198591947556,
        0.04438670724630356,
        -0.05673909932374954,
        -0.05121022090315819,
        0.038417961448431015,
        -0.03648523613810539,
        -0.06174560263752937,
        -0.05183154344558716,
        0.05929787456989288,
        -0.051423415541648865,
        -0.05865681171417236,
        0.06188365817070007,
        0.0003208612324669957,
        0.04096579924225807,
        -0.05184264853596687,
        0.06117634102702141,
        -0.05445842444896698,
        0.04535946249961853,
        0.04802999645471573,
        -0.05429057031869888,
        -0.0022602591197937727,
        0.06024180352687836,
        -0.04704781994223595,
        0.0111801503226161,
        0.052914656698703766,
        -0.04490143805742264,
        0.04879097640514374,
        -0.061634793877601624,
        -0.04993826895952225,
        -0.047519344836473465,
        0.056261513382196426,
        -0.06197137013077736,
        0.037202347069978714,
        0.029459862038493156,
        0.05090134218335152,
        0.018884489312767982,
        0.031789518892765045,
        0.05779936537146568,
        0.04185013473033905,
        -0.03872799500823021,
        -0.051688820123672485,
        -0.01633358746767044,
        0.05271545797586441,
        -0.02669597789645195,
        -0.04975568503141403,
        -0.02397710084915161,
        -0.05870356783270836,
        -0.026736745610833168,
        0.04672134295105934,
        0.030902108177542686,
        -0.011692628264427185,
        0.0578744076192379,
        -0.0086903041228652,
        -0.029075268656015396,
        0.0018101379973813891,
        0.03625957667827606,
        0.012134240008890629,
        0.057323187589645386,
        0.05591855198144913,
        0.0245750080794096,
        0.0456717349588871,
        0.041260696947574615,
        0.04938916862010956,
        -0.018641645088791847,
        0.011675459332764149,
        -0.0612228587269783,
        0.02803884446620941,
        0.05515514686703682,
        -0.03256312757730484,
        0.05266912654042244,
        -0.007391162682324648,
        0.01339320931583643,
        0.051340095698833466,
        0.061843108385801315,
        -0.05341261997818947,
        -0.008297812193632126,
        -0.055982042104005814,
        -0.05269055441021919,
        -0.06166539713740349,
        0.05995459109544754,
        -0.06129605695605278,
        -0.03001634031534195,
        0.03445947542786598,
        -0.05576840788125992,
        -0.028690220788121223,
        0.016530225053429604,
        -0.061179954558610916,
        0.006246289703994989,
        0.049586862325668335,
        -0.021234823390841484,
        -0.00021800515241920948,
        0.05709528550505638,
        -0.026960881426930428,
        0.04063113033771515,
        0.058085910975933075,
        0.06115697696805,
        -0.055226054042577744,
        -0.05208630487322807,
        0.030569730326533318,
        0.008274691179394722,
        0.03480958566069603,
        0.059924278408288956,
        0.02202785573899746,
        -0.04276017099618912,
        0.0420985221862793,
        -0.005102647002786398,
        -0.06005054712295532,
        -0.050306834280490875,
        -0.05744669958949089,
        0.0471978597342968,
        0.06032755598425865,
        -0.024669576436281204,
        0.03657461702823639,
        0.06176838278770447,
        0.058377958834171295,
        -0.008324166759848595,
        0.05805296078324318,
        -0.013028230518102646,
        0.004025375470519066,
        -0.045080821961164474,
        0.05609758570790291,
        -0.02069745771586895,
        -0.04068455100059509,
        0.03818646818399429,
        -0.02989218384027481,
        -0.04691020026803017,
        -0.06168380752205849,
        0.02595805935561657,
        -0.06020531430840492,
        -0.05347776412963867,
        0.03189772367477417,
        -0.06042877212166786,
        0.03347335383296013,
        -0.015386475250124931,
        -0.02298928238451481,
        -0.059170693159103394,
        -0.020088933408260345,
        -0.05398653820157051,
        -0.014049607329070568,
        -0.022037111222743988,
        -0.029019635170698166,
        0.0476166196167469,
        -0.05812497064471245,
        -0.03535296395421028,
        -0.016253706067800522,
        -0.04057475924491882,
        0.043370023369789124,
        0.042459726333618164,
        -0.04199424386024475,
        0.017402131110429764,
        -0.04610215872526169,
        0.06170076131820679,
        0.025245659053325653,
        -0.003349513513967395,
        0.05444192886352539,
        -0.027108434587717056,
        0.052729275077581406,
        0.011213532648980618,
        0.04252013936638832,
        0.041526563465595245,
        -0.04521503672003746,
        -0.05657321214675903,
        0.037986837327480316,
        0.04888300225138664,
        -0.05450042709708214,
        -0.032414212822914124,
        -0.048627354204654694,
        -0.04054712876677513,
        0.051271382719278336,
        -0.06143879145383835,
        -0.051924046128988266,
        0.05622290447354317,
        0.04690156504511833,
        -0.0012992924312129617,
        -0.0521932877600193,
        -0.029926059767603874,
        -0.031512778252363205,
        0.060810621827840805,
        0.012937450781464577,
        0.036600980907678604,
        0.062020499259233475,
        0.05911365896463394,
        0.04362928494811058,
        0.05729474872350693,
        -0.043747372925281525,
        -0.05903184786438942,
        0.0458655059337616,
        0.0584939606487751,
        -0.03107409179210663,
        0.05637529864907265,
        -0.011759456247091293,
        -0.05375637486577034,
        0.061351530253887177,
        -0.014079774729907513,
        0.031712062656879425,
        0.04989629238843918,
        -0.0004394548886921257,
        0.039869558066129684,
        0.009181903675198555,
        0.06204658001661301,
        -0.012638849206268787,
        -0.02316960319876671,
        0.04399864748120308,
        0.021270785480737686,
        0.05765148997306824,
        -0.06202762573957443,
        0.01359531469643116,
        -0.056864507496356964,
        -0.04463889077305794,
        -0.060865242034196854,
        0.04826650023460388,
        -0.045308083295822144,
        -0.05345005914568901,
        -0.051010288298130035,
        -0.06007445603609085,
        0.04671170562505722,
        0.060109447687864304,
        -0.060908976942300797,
        0.02138425037264824,
        -0.02225406840443611,
        -0.036423005163669586,
        0.0612897053360939,
        -0.03527554124593735,
        0.010918065905570984,
        -0.06144295632839203,
        -0.053230009973049164,
        -0.06111645698547363,
        0.02205338329076767,
        0.061430759727954865,
        0.05969474837183952,
        0.04704930633306503,
        -0.030232636258006096,
        0.0454447977244854,
        -0.03622956946492195,
        -0.06019854545593262,
        -0.050850383937358856,
        0.034798696637153625,
        -0.05256923660635948,
        -0.0027250961866229773,
        0.04950116574764252,
        -0.04663512855768204,
        0.04603632166981697,
        -0.031200040131807327,
        0.0015209403354674578,
        -0.05951935425400734,
        -0.05065726116299629,
        0.021374017000198364,
        0.035381291061639786,
        -0.04253945127129555,
        -0.04671485349535942,
        0.057335153222084045,
        0.01576933078467846,
        0.04131849855184555,
        -0.04035452380776405,
        -0.060430049896240234,
        -0.054400768131017685,
        0.05262627452611923,
        0.05886697396636009,
        0.06073538959026337,
        -0.04984782263636589,
        -0.02585003711283207,
        0.043920233845710754,
        -0.007300538010895252,
        0.058112483471632004,
        -0.0350174680352211,
        0.0611950159072876,
        0.011451655067503452,
        0.018494170159101486,
        0.017679236829280853,
        0.03324643522500992,
        0.006119278725236654,
        0.04483625292778015,
        -0.014148322865366936,
        0.017894066870212555,
        0.036689452826976776,
        -0.04549562186002731,
        0.02116602100431919,
        -0.05313107371330261,
        -0.0487089641392231,
        0.04429200664162636,
        -0.0304535161703825,
        -0.05915561318397522,
        -0.06049980968236923,
        -0.05883973464369774,
        -0.05454205349087715,
        0.05779080092906952,
        -0.05288021266460419,
        0.012378974817693233,
        -0.020470408722758293,
        0.031190460547804832,
        0.0442742295563221,
        -0.06007939204573631,
        0.007347497623413801,
        0.05195283889770508,
        -0.03941068425774574,
        0.05162247642874718,
        0.05296194925904274,
        -0.04977918788790703,
        0.03995571658015251,
        0.05838810279965401,
        0.037074778228998184,
        0.058655478060245514,
        -0.06169624626636505,
        0.052140332758426666,
        -0.06124071031808853,
        -0.04420787841081619,
        0.06081525608897209,
        0.051820263266563416,
        0.05415027588605881,
        -0.013962343335151672,
        0.0027274377644062042,
        0.03736791014671326,
        0.04924057051539421,
        -0.025950966402888298,
        -0.01483610738068819,
        0.008798176422715187,
        0.004403403028845787,
        -0.05081065744161606,
        -0.017834944650530815,
        0.058962784707546234,
        0.0617901086807251,
        -0.061450425535440445,
        0.06168726459145546,
        -0.0013273387448862195,
        -0.05940382927656174,
        0.04971879720687866,
        0.05488322302699089,
        0.007975300773978233,
        0.03379053249955177,
        0.0612136572599411,
        0.040774114429950714,
        0.042315397411584854,
        -0.016969559714198112,
        -0.055229369550943375,
        -0.032398074865341187,
        -0.023473497480154037,
        -0.0073715755715966225,
        0.05962589383125305,
        0.03836961090564728,
        -0.00920774508267641,
        0.053435344249010086,
        0.048430562019348145,
        -0.053117260336875916,
        -0.043439172208309174,
        0.039657313376665115,
        -0.059148188680410385,
        -0.06197339668869972,
        0.022174516692757607,
        -0.029966291040182114,
        0.05661044269800186,
        0.05546970292925835,
        -0.057205721735954285,
        0.004685200750827789,
        0.05038195848464966,
        0.05198302119970322,
        0.06102226674556732,
        0.058878034353256226,
        0.056785017251968384,
        -0.008015858009457588,
        -0.003973073326051235,
        0.04771943762898445,
        -0.05784998834133148,
        -0.009527111425995827,
        0.0031772335059940815,
        -0.04377114772796631,
        0.061970923095941544,
        0.031968895345926285
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        0.008564846590161324,
        0.05543268471956253,
        0.03213900327682495,
        0.024165945127606392,
        -0.04331085830926895,
        0.011576448567211628,
        -0.04593006521463394,
        -0.06393347680568695,
        0.06287714838981628,
        0.007313918322324753,
        0.023222418501973152,
        0.0234018936753273,
        -0.028808195143938065,
        -0.010148517787456512,
        -0.05740716680884361,
        0.06521231681108475,
        -0.0018976666033267975,
        0.06216632202267647,
        -0.060581788420677185,
        -0.02511429972946644,
        -0.030356906354427338,
        0.053035564720630646,
        -0.030278664082288742,
        0.005588829983025789,
        0.004078216850757599,
        0.05168558284640312,
        0.050151530653238297,
        -0.040628090500831604,
        0.02429983951151371,
        -0.037031710147857666,
        0.04516865685582161,
        -0.03919601067900658,
        -0.04491320624947548,
        -0.03450414165854454,
        0.05578137934207916,
        0.0003359958063811064,
        -0.06197693571448326,
        -0.03532717749476433,
        -0.0646471157670021,
        -0.03994118422269821,
        0.03517892211675644,
        0.010202154517173767,
        0.035111717879772186,
        -0.0605938695371151,
        0.024831991642713547,
        0.06041260063648224,
        0.024375025182962418,
        -0.006959646940231323,
        0.008923504501581192,
        0.0449485182762146,
        0.05688659846782684,
        0.06458374857902527,
        -0.04367022588849068,
        0.01841423101723194,
        0.023185482248663902,
        -0.0052063665352761745,
        0.029355501756072044,
        0.017826780676841736,
        -0.021260326728224754,
        -0.02541487105190754,
        -0.05218690633773804,
        0.033410217612981796,
        -0.05329229682683945,
        0.06334595382213593,
        -0.0626981109380722,
        -0.044052109122276306,
        0.032090041786432266,
        0.03818304091691971,
        -0.06351262331008911,
        0.03560167923569679,
        -0.05746079236268997,
        -0.06391430646181107,
        0.06481176614761353,
        0.032424330711364746,
        0.02732551097869873,
        -0.05137693136930466,
        -0.04088094085454941,
        0.06530453264713287,
        -0.03125147521495819,
        0.0017713619163259864,
        -0.0019817561842501163,
        0.0623074546456337,
        -0.04598985239863396,
        0.004443882033228874,
        0.04098089784383774,
        0.05126555636525154,
        0.06328331679105759,
        -0.049983300268650055,
        0.05042760819196701,
        0.009234128519892693,
        0.06460153311491013,
        0.059300459921360016,
        -0.05312587693333626,
        0.010765173472464085,
        -0.06159711256623268,
        0.05656469985842705,
        -0.02481316216289997,
        -0.06337738037109375,
        0.04868151992559433,
        -0.02912529744207859,
        -0.0644695907831192,
        -0.004101769533008337,
        0.061175648123025894,
        -0.0008146446198225021,
        0.04779357090592384,
        -0.0594358891248703,
        -0.05972127988934517,
        0.048437487334012985,
        -0.03886307775974274,
        0.013097005896270275,
        -0.06100171431899071,
        -0.01939314417541027,
        -0.06249215081334114,
        0.001678014057688415,
        -0.03294423595070839,
        0.03741942718625069,
        0.053218625485897064,
        0.004745109006762505,
        0.06502094119787216,
        0.04206845164299011,
        -0.005795342847704887,
        0.060565199702978134,
        0.023223428055644035,
        -0.0538712814450264,
        -0.062445688992738724,
        -0.05931944027543068,
        -0.03591688722372055,
        -0.04879537224769592,
        0.06361673027276993,
        0.011934434063732624,
        0.007672256790101528,
        -0.06137720122933388,
        -0.06322871893644333,
        0.0005882291588932276,
        -0.05506505072116852,
        -0.060056816786527634,
        0.0353657603263855,
        0.054009754210710526,
        -0.06425336748361588,
        0.017993008717894554,
        0.06489552557468414,
        0.010414984077215195,
        0.0631604790687561,
        0.00909093115478754,
        0.06328526884317398,
        0.005659415386617184,
        0.05322565510869026,
        -0.060876790434122086,
        -0.01153490785509348,
        0.06349412351846695,
        0.052351996302604675,
        -0.06509332358837128,
        -0.006005719304084778,
        -0.06197017431259155,
        0.04918013513088226,
        -0.0552964024245739,
        0.015124398283660412,
        0.030038537457585335,
        0.0343974344432354,
        0.022914333269000053,
        0.04491129890084267,
        0.06315875053405762,
        0.056288257241249084,
        0.050995804369449615,
        0.03146698325872421,
        -0.020174149423837662,
        -0.040094900876283646,
        0.03573189303278923,
        0.05150184407830238,
        0.02288900315761566,
        0.01926804706454277,
        0.045493487268686295,
        -0.0047343517653644085,
        -0.05048038065433502,
        0.02810817025601864,
        0.06079171225428581,
        -0.0639258623123169,
        0.025196334347128868,
        0.06207525357604027,
        -0.03130509331822395,
        0.00923299603164196,
        0.06336279958486557,
        0.011913575232028961,
        0.03289920091629028,
        0.041141267865896225,
        0.004943119361996651,
        -0.056749921292066574,
        0.05952417105436325,
        -0.05622836947441101,
        -0.0024420437403023243,
        -0.02457035891711712,
        -0.01304048765450716,
        0.029241343960165977,
        -0.03951805457472801,
        -0.02799583226442337,
        0.043166570365428925,
        -0.03468608856201172,
        -0.0005918341339565814,
        -0.032793860882520676,
        -0.018179023638367653,
        -0.0014400370419025421,
        -0.00894287321716547,
        0.026954026892781258,
        0.042046256363391876,
        -0.05936264619231224,
        0.027474844828248024,
        0.03785983473062515,
        0.006891000550240278,
        -0.05409151315689087,
        0.010499196127057076,
        -0.0538034662604332,
        0.057770516723394394,
        -0.011762639507651329,
        0.033338356763124466,
        -0.017208078876137733,
        0.05253070592880249,
        0.0629071444272995,
        -0.0017804773524403572,
        0.059906601905822754,
        0.01991976797580719,
        0.023759551346302032,
        0.06520167738199234,
        -0.056364115327596664,
        -0.055990029126405716,
        0.05399155989289284,
        0.006789790000766516,
        0.0131571339443326,
        0.0556732639670372,
        0.02614024095237255,
        -0.05263439565896988,
        0.012883510440587997,
        -0.04936074838042259,
        -0.046945344656705856,
        0.03450851887464523,
        -0.005927992984652519,
        0.06530328840017319,
        0.06086920574307442,
        -0.02876662276685238,
        -0.04044951871037483,
        0.06492634117603302,
        -0.02878817915916443,
        0.06411302834749222,
        -0.06474373489618301,
        0.0598837248980999,
        0.03937919810414314,
        -0.008899074047803879,
        -0.06517178565263748,
        -0.0019319377606734633,
        -0.01458079181611538,
        -0.057717423886060715,
        -0.03528590500354767,
        -0.0629265308380127,
        0.052244894206523895,
        -0.06136834993958473,
        -0.06137091666460037,
        0.06234414875507355,
        -0.05957591161131859,
        -0.05309155210852623,
        0.03613309562206268,
        -0.0113234156742692,
        0.06518972665071487,
        -0.04750172793865204,
        -0.05206802114844322,
        0.008648025803267956,
        0.024988405406475067,
        0.018744152039289474,
        -0.021876368671655655,
        0.03906140476465225,
        -0.04084064066410065,
        0.03562774136662483,
        0.053148187696933746,
        -0.029703551903367043,
        0.06371457874774933,
        -0.044147416949272156,
        -0.05784231796860695,
        0.04403267428278923,
        -0.04610558599233627,
        -0.0031299402471631765,
        -0.04442024603486061,
        -0.04499536380171776,
        -0.03865407407283783,
        -0.06483203172683716,
        -0.06305023282766342,
        0.06100941449403763,
        0.028336714953184128,
        -0.051737815141677856,
        0.01349045243114233,
        0.053166426718235016,
        0.01988239958882332,
        0.055489614605903625,
        0.052007269114255905,
        0.06006869673728943,
        -0.018109586089849472,
        0.060408975929021835,
        0.04495154321193695,
        -0.06366849690675735,
        0.06297041475772858,
        0.057340074330568314,
        -0.05882185697555542,
        0.058646444231271744,
        0.05634325370192528,
        0.04404154047369957,
        -0.04022854194045067,
        0.04827120527625084,
        -0.055566150695085526,
        0.05207677558064461,
        -0.059436116367578506,
        0.014347954653203487,
        -0.034674566239118576,
        -0.06444787234067917,
        0.0005788722191937268,
        -0.0024937381967902184,
        -0.039225805550813675,
        -0.01892682909965515,
        -0.03937745839357376,
        -0.060617830604314804,
        0.018148479983210564,
        -0.050684619694948196,
        0.04825783148407936,
        -0.039141058921813965,
        -0.06217578798532486,
        0.014929505996406078,
        0.025696929544210434,
        0.02115083485841751,
        0.058630507439374924,
        -0.061344441026449203,
        -0.015361879020929337,
        -0.03409123793244362,
        0.0007867065723985434,
        -0.031188907101750374,
        0.004583251662552357,
        -0.04848161339759827,
        -0.038601189851760864,
        -0.057779598981142044,
        0.060458920896053314,
        -0.005759686231613159,
        0.007140317466109991,
        0.0618775300681591,
        -0.04371542111039162,
        0.042847052216529846,
        -0.04242296889424324,
        0.06313742697238922,
        -0.06513872742652893,
        0.00236154580488801,
        0.06481637805700302,
        0.03308706730604172,
        0.02034105733036995,
        0.057343170046806335,
        -0.05006010830402374,
        0.06383253633975983,
        0.03857026621699333,
        0.0282197967171669,
        -0.01726715639233589,
        0.026308702304959297,
        -0.00930893886834383,
        0.0548519641160965,
        -0.05884983390569687,
        -0.04512917622923851,
        0.05515096336603165,
        -0.060401901602745056,
        0.05867038667201996,
        -0.020922964438796043,
        -0.02604989893734455,
        0.004393557086586952,
        0.04954352229833603,
        -0.05391521006822586,
        0.04162977263331413,
        -0.03049399144947529,
        -0.0009712942410260439,
        -0.05022815614938736,
        0.01879376545548439,
        0.0032330863177776337,
        0.036888979375362396,
        0.06008973345160484,
        -0.01324759516865015,
        0.011592019349336624,
        -0.06063394248485565,
        0.05856834352016449,
        0.053071487694978714,
        0.037844218313694,
        0.044635120779275894,
        -0.06292487680912018,
        -0.0644104927778244,
        0.06402979046106339,
        0.06173766776919365,
        -0.018584521487355232,
        -0.0522100031375885,
        -0.06331618130207062,
        -0.05693782866001129,
        -0.05232470855116844,
        -0.05881355702877045,
        -0.04684147238731384,
        -0.03527594730257988,
        -0.033626217395067215,
        -0.052957311272621155,
        0.060717180371284485,
        0.055522579699754715,
        0.06238997355103493,
        0.0011322797508910298,
        -0.058432064950466156,
        -0.03386379033327103,
        0.061275143176317215,
        -0.006519821006804705,
        -0.05809425935149193,
        -0.0045834933407604694,
        0.04456520453095436,
        -0.0027610426768660545,
        -0.038334157317876816,
        0.05193442478775978,
        0.017491396516561508,
        -0.0551949068903923,
        -0.0635494813323021,
        0.047340504825115204,
        -0.0031002007890492678,
        0.056034039705991745,
        -0.04500998184084892,
        -0.013457243330776691,
        0.04153989627957344,
        0.02973017655313015,
        0.04736356437206268,
        -0.04477611929178238,
        -0.054922185838222504,
        0.014035262167453766,
        -0.04521473869681358,
        -0.030719032511115074,
        0.03849112242460251,
        -0.020802227780222893,
        -0.003990042023360729,
        0.05380404740571976,
        -0.05532538518309593,
        0.057328496128320694,
        -0.03381713852286339,
        -0.06286406517028809,
        0.02202468179166317,
        0.03620138764381409,
        -0.04982843995094299,
        -0.0646674707531929,
        0.037085287272930145,
        -0.026468172669410706,
        0.013533733785152435,
        0.056330978870391846,
        0.04982280731201172,
        0.054052241146564484,
        0.052780698984861374,
        0.06332404911518097,
        0.02948073297739029,
        0.055386923253536224,
        -0.04212941974401474,
        0.060344938188791275,
        0.019126195460557938,
        -0.04070618003606796,
        0.01343176607042551,
        -0.043398983776569366,
        0.03699115291237831,
        -0.0015013051452115178,
        -0.06048356741666794,
        0.04789118468761444,
        -0.02115507982671261,
        -0.047400522977113724,
        0.04636351764202118,
        -0.02934934012591839,
        -0.01570550724864006,
        -0.06095085293054581,
        0.03969636932015419,
        -0.008578065782785416,
        0.062435444444417953,
        0.016384784132242203,
        0.05654914304614067,
        -0.05958602577447891,
        -0.0447537824511528,
        0.014660042710602283,
        0.05056503415107727,
        0.03423309326171875,
        -0.012520582415163517,
        -0.0535881482064724,
        -0.04555368050932884,
        0.05763659253716469,
        0.04047435149550438,
        -0.015609994530677795,
        -0.05261161923408508,
        -0.036194682121276855,
        -0.06007424741983414,
        -0.03451000154018402,
        -0.010432391427457333,
        0.03021286427974701,
        0.03919307515025139,
        0.053607530891895294,
        0.04864745959639549,
        -0.05761672556400299,
        0.05104956403374672,
        -0.06426198780536652,
        0.01500279363244772,
        0.006062757223844528,
        0.03906086087226868,
        -0.03690521419048309,
        0.024104764685034752,
        0.051745329052209854,
        0.021205635741353035,
        0.05916600674390793,
        0.05690493434667587,
        -0.05153150483965874,
        -0.05049114301800728,
        -0.04271898418664932,
        0.026205943897366524,
        -0.008070278912782669,
        -0.04784475639462471,
        0.04573344066739082,
        0.007422992493957281,
        0.004219570197165012,
        -0.065235935151577,
        0.03711907938122749,
        0.06510847806930542
    ],
    [
        -0.010581990703940392,
        0.0410459004342556,
        0.038178760558366776,
        0.037720806896686554,
        -0.04130148887634277,
        -0.025099510326981544,
        -0.04639023169875145,
        -0.06148923188447952,
        0.06124165281653404,
        0.021046342328190804,
        0.01728181354701519,
        0.032646119594573975,
        -0.02824566699564457,
        -0.012165206484496593,
        -0.05704609677195549,
        0.062283821403980255,
        -0.011450698599219322,
        0.05997486412525177,
        -0.05963892489671707,
        -0.04427577555179596,
        -0.004313794896006584,
        0.055406078696250916,
        0.006730637047439814,
        -0.007557341363281012,
        0.012331449426710606,
        0.05474264919757843,
        0.0563068650662899,
        -0.046577874571084976,
        0.021288691088557243,
        -0.015626195818185806,
        0.04306351765990257,
        -0.035507626831531525,
        -0.05114029720425606,
        -0.05692489817738533,
        0.054751213639974594,
        0.00028236123034730554,
        -0.06052546203136444,
        -0.04419337585568428,
        -0.06209249794483185,
        -0.049507152289152145,
        0.05053267255425453,
        0.024615516886115074,
        0.016513893380761147,
        -0.05778421089053154,
        0.024882134050130844,
        0.05985688790678978,
        0.029301980510354042,
        0.0025800373405218124,
        0.04795828089118004,
        0.047509558498859406,
        0.05999165400862694,
        0.061978019773960114,
        -0.050045233219861984,
        0.02823043242096901,
        0.021378718316555023,
        -0.03382810205221176,
        0.010891105979681015,
        0.01439391914755106,
        -0.008523610420525074,
        -0.02795478329062462,
        -0.053045909851789474,
        0.03798270970582962,
        -0.0515647791326046,
        0.06199575215578079,
        -0.05930057913064957,
        -0.039479810744524,
        0.025305218994617462,
        0.02432151511311531,
        -0.06078300625085831,
        0.0433092825114727,
        -0.05771666765213013,
        -0.06162887066602707,
        0.061796560883522034,
        0.009063230827450752,
        0.05334896221756935,
        -0.04620504379272461,
        -0.016307387501001358,
        0.06222765892744064,
        -0.03258542716503143,
        -0.004823806695640087,
        0.0011869472218677402,
        0.059939973056316376,
        -0.04695752635598183,
        0.02844236046075821,
        0.0439184196293354,
        0.05168186500668526,
        0.06123967841267586,
        -0.0472697950899601,
        0.05279386043548584,
        0.0013218686217442155,
        0.05658123642206192,
        0.0579620897769928,
        -0.05964602530002594,
        0.005655619781464338,
        -0.05839337408542633,
        0.055713627487421036,
        -0.008175305090844631,
        -0.061414822936058044,
        0.04333430528640747,
        -0.028945811092853546,
        -0.061274345964193344,
        0.021904807537794113,
        0.06023986265063286,
        0.0221266970038414,
        0.05112680047750473,
        -0.06017029657959938,
        -0.05468820035457611,
        0.055664096027612686,
        -0.04596559703350067,
        0.03692210838198662,
        -0.059770744293928146,
        -0.020671971142292023,
        -0.05928969383239746,
        0.02372307889163494,
        -0.045356348156929016,
        0.039331868290901184,
        0.05402939394116402,
        -0.0028156230691820383,
        0.06222115457057953,
        0.04973116144537926,
        -0.0006684620166197419,
        0.06007298454642296,
        0.035667240619659424,
        -0.04825703799724579,
        -0.05852118507027626,
        -0.05329965054988861,
        -0.03887297585606575,
        -0.057917892932891846,
        0.06068072095513344,
        0.03195372223854065,
        0.027861062437295914,
        -0.05645226314663887,
        -0.0594259649515152,
        0.015055970288813114,
        -0.05538018420338631,
        -0.05664397031068802,
        0.039751213043928146,
        0.05773305520415306,
        -0.06164275109767914,
        0.02990543469786644,
        0.061628565192222595,
        0.004089896101504564,
        0.059631817042827606,
        0.007985017262399197,
        0.06080415099859238,
        0.0082120131701231,
        0.05389991030097008,
        -0.05749858170747757,
        0.007395611610263586,
        0.06136550381779671,
        0.05455438420176506,
        -0.06213231012225151,
        0.019312797114253044,
        -0.059069257229566574,
        0.05228229612112045,
        -0.056188590824604034,
        -0.005237360019236803,
        0.032300759106874466,
        0.01848806068301201,
        0.03846333548426628,
        0.05432954430580139,
        0.05306169018149376,
        0.05230562016367912,
        0.04826889559626579,
        0.025045115500688553,
        0.0012692894088104367,
        -0.03597423434257507,
        0.050962530076503754,
        0.044565599411726,
        0.022117702290415764,
        0.014611802995204926,
        0.046088650822639465,
        -0.014762314036488533,
        -0.054391924291849136,
        0.030264366418123245,
        0.06103292480111122,
        -0.061878085136413574,
        0.02903725393116474,
        0.05953130125999451,
        -0.05099037289619446,
        -0.006321015767753124,
        0.06205131113529205,
        0.010032988153398037,
        0.02948629856109619,
        0.03854569047689438,
        0.019966760650277138,
        -0.05868510529398918,
        0.0568925105035305,
        -0.05753529816865921,
        -0.026668163016438484,
        -0.028556790202856064,
        0.007891136221587658,
        0.017299411818385124,
        -0.0390150211751461,
        -0.04582848772406578,
        0.033443473279476166,
        -0.043992914259433746,
        0.005259939469397068,
        -0.041913095861673355,
        -0.0012817918322980404,
        0.015481458976864815,
        -0.022283755242824554,
        0.009936785325407982,
        0.03940549120306969,
        -0.05414889380335808,
        0.041562676429748535,
        0.03881062939763069,
        0.03093097172677517,
        -0.05631183087825775,
        0.020046574994921684,
        -0.05397970601916313,
        0.0546482689678669,
        -0.017437035217881203,
        0.03139129653573036,
        -0.0326138474047184,
        0.05141046643257141,
        0.06073617562651634,
        -0.02663564495742321,
        0.06021909415721893,
        0.021036501973867416,
        0.010409235954284668,
        0.06202758103609085,
        -0.0568092055618763,
        -0.0474453829228878,
        0.05498649552464485,
        0.022457046434283257,
        0.0231404360383749,
        0.05023973807692528,
        0.03502222150564194,
        -0.05531046912074089,
        0.037648048251867294,
        -0.05465114116668701,
        -0.04743470251560211,
        0.03372413292527199,
        -0.022860970348119736,
        0.06232639402151108,
        0.06014815345406532,
        -0.03163471445441246,
        -0.04625905305147171,
        0.06175345554947853,
        -0.0274454727768898,
        0.06191254407167435,
        -0.06210411339998245,
        0.05844040587544441,
        0.04549774155020714,
        0.00012000490096397698,
        -0.062062595039606094,
        0.03497360646724701,
        0.001779027283191681,
        -0.05391475558280945,
        -0.04605880007147789,
        -0.06041242554783821,
        0.048391811549663544,
        -0.05982913449406624,
        -0.05996187776327133,
        0.059240974485874176,
        -0.059158194810152054,
        -0.0522671677172184,
        0.03628621622920036,
        -0.01229395903646946,
        0.062111835926771164,
        -0.05182640627026558,
        -0.04539324343204498,
        0.022764690220355988,
        0.006003565154969692,
        0.013035645708441734,
        0.01906737871468067,
        0.03864588961005211,
        -0.04235868901014328,
        0.035269975662231445,
        0.049471236765384674,
        -0.04798856005072594,
        0.06194883957505226,
        -0.04269101843237877,
        -0.05716864392161369,
        0.0374644473195076,
        -0.04923500120639801,
        -0.0016030519036576152,
        -0.04331771284341812,
        -0.03433973714709282,
        -0.04707637429237366,
        -0.061018578708171844,
        -0.061626970767974854,
        0.060839563608169556,
        0.04042154178023338,
        -0.05088760331273079,
        0.004431443754583597,
        0.04951523244380951,
        0.04716823250055313,
        0.045785706490278244,
        0.03511699289083481,
        0.05770014226436615,
        -0.03261329606175423,
        0.053727708756923676,
        0.052015893161296844,
        -0.060898877680301666,
        0.05933461710810661,
        0.05963370203971863,
        -0.05714941769838333,
        0.05697019025683403,
        0.05655841529369354,
        0.028918864205479622,
        -0.028453776612877846,
        0.05206930264830589,
        -0.04460221901535988,
        0.053731951862573624,
        -0.054942693561315536,
        0.01899034157395363,
        -0.03945159167051315,
        -0.06148570775985718,
        0.016786498948931694,
        -0.022827107459306717,
        -0.04170995578169823,
        0.004056212492287159,
        -0.03909633308649063,
        -0.058508314192295074,
        0.02145262621343136,
        -0.05350938066840172,
        0.04497655853629112,
        -0.043955106288194656,
        -0.05667119100689888,
        0.008179130963981152,
        0.02171126939356327,
        0.030522508546710014,
        0.05628898739814758,
        -0.05940590798854828,
        -0.024819618090987206,
        -0.015425194054841995,
        0.0012605306692421436,
        -0.04276737570762634,
        0.011003020219504833,
        -0.04975063353776932,
        -0.04410363733768463,
        -0.05597516521811485,
        0.058262478560209274,
        0.01757136732339859,
        -0.017306650057435036,
        0.0583721362054348,
        -0.03088195063173771,
        0.040339093655347824,
        -0.037326134741306305,
        0.05992839112877846,
        -0.06220296397805214,
        -0.02807925082743168,
        0.061982061713933945,
        0.01947215013206005,
        0.01689874194562435,
        0.057931795716285706,
        -0.04449373483657837,
        0.05999982729554176,
        0.04431099072098732,
        0.03084850125014782,
        -0.03023981675505638,
        -0.0237160362303257,
        -0.0015755004715174437,
        0.05120322108268738,
        -0.05781945586204529,
        -0.04776906594634056,
        0.058429595082998276,
        -0.059728801250457764,
        0.05339285731315613,
        -0.02073870785534382,
        -0.0005558821139857173,
        0.014174503274261951,
        0.04192495346069336,
        -0.05505053326487541,
        0.058193374425172806,
        -0.012278513051569462,
        0.028132684528827667,
        -0.056094225496053696,
        -0.003229909809306264,
        0.004154481925070286,
        0.04473646730184555,
        0.05561501160264015,
        0.021583089604973793,
        0.017666473984718323,
        -0.06071765720844269,
        0.05970531702041626,
        0.05805832892656326,
        0.044079896062612534,
        0.03807706758379936,
        -0.059656426310539246,
        -0.061585672199726105,
        0.061071861535310745,
        0.05853673443198204,
        -0.029442282393574715,
        -0.045830707997083664,
        -0.05986056476831436,
        -0.04798056185245514,
        -0.046843066811561584,
        -0.061355575919151306,
        -0.04998233914375305,
        -0.0421454943716526,
        -0.03351099416613579,
        -0.03740140050649643,
        0.058896493166685104,
        0.056224219501018524,
        0.05693583935499191,
        0.03279092162847519,
        -0.059808142483234406,
        -0.029024502262473106,
        0.06099503114819527,
        0.006671171635389328,
        -0.05768577381968498,
        -0.00670622056350112,
        0.031941767781972885,
        0.0020274261478334665,
        -0.04394473508000374,
        0.051042843610048294,
        0.007366139441728592,
        -0.05610835924744606,
        -0.060141053050756454,
        0.05442430451512337,
        0.009955255314707756,
        0.05339323356747627,
        -0.04868563637137413,
        -0.013091791421175003,
        0.04448116198182106,
        0.018584901466965675,
        0.04527250677347183,
        -0.006894961930811405,
        -0.031240487471222878,
        0.0334385521709919,
        -0.05185285955667496,
        0.015903586521744728,
        0.026024265214800835,
        -0.033736202865839005,
        0.02651282399892807,
        0.04955263435840607,
        -0.05776466429233551,
        0.05994671955704689,
        -0.03713231906294823,
        -0.06174305081367493,
        0.010472414083778858,
        0.013320905156433582,
        -0.055768586695194244,
        -0.06051565706729889,
        0.01827922835946083,
        0.0022751439828425646,
        0.024748610332608223,
        0.0524454265832901,
        0.054555900394916534,
        0.052384406328201294,
        0.05484851077198982,
        0.061952680349349976,
        0.030950704589486122,
        0.05740952864289284,
        -0.05115242302417755,
        0.05764957144856453,
        0.04245362430810928,
        -0.05041521042585373,
        0.020493971183896065,
        -0.027161119505763054,
        0.045795340090990067,
        -0.003155234968289733,
        -0.0588935986161232,
        0.036196932196617126,
        -0.010075469501316547,
        -0.04603850841522217,
        0.05722174420952797,
        -0.02655513770878315,
        -0.025410763919353485,
        -0.06054162234067917,
        0.04838430881500244,
        0.02567320503294468,
        0.06080154329538345,
        0.0007892355206422508,
        0.05599606782197952,
        -0.05918209254741669,
        -0.05332981050014496,
        0.017771603539586067,
        0.058680493384599686,
        0.04180161654949188,
        -0.0008374282042495906,
        -0.05854211002588272,
        -0.017075106501579285,
        0.053212717175483704,
        0.040389787405729294,
        -0.02204771153628826,
        -0.05252881348133087,
        -0.044192951172590256,
        -0.05998057499527931,
        -0.0475449338555336,
        -0.007797630969434977,
        0.022199170663952827,
        0.04492394998669624,
        0.05320416018366814,
        0.04619637504220009,
        -0.05180559679865837,
        0.042811911553144455,
        -0.0617099329829216,
        0.02249540574848652,
        0.023987900465726852,
        0.038472291082143784,
        -0.03349659964442253,
        0.014482002705335617,
        0.03791915997862816,
        0.0468093641102314,
        0.055782221257686615,
        0.06015906110405922,
        -0.05095640569925308,
        -0.042522381991147995,
        -0.05025419965386391,
        0.012239204719662666,
        0.014209751971065998,
        -0.04986623674631119,
        0.05271298810839653,
        0.005985694006085396,
        0.024447280913591385,
        -0.06233933940529823,
        0.021448587998747826,
        0.061940405517816544
    ],
    [
        0.002674101386219263,
        0.05034102126955986,
        0.034247614443302155,
        0.02271183207631111,
        -0.04255121946334839,
        -0.0028218983206897974,
        -0.051021624356508255,
        -0.06354112923145294,
        0.06199420616030693,
        0.00862167403101921,
        0.020811624825000763,
        0.01874127984046936,
        -0.029542697593569756,
        -0.0199994295835495,
        -0.05517023429274559,
        0.06462035328149796,
        0.007668153382837772,
        0.061712659895420074,
        -0.060562122613191605,
        -0.041286587715148926,
        -0.030436266213655472,
        0.054527413100004196,
        -0.022710924968123436,
        0.004413755144923925,
        -0.01945512555539608,
        0.05179388448596001,
        0.049786362797021866,
        -0.05066540837287903,
        0.016922330483794212,
        -0.03376669064164162,
        0.03673366457223892,
        -0.051515135914087296,
        -0.044552359730005264,
        -0.032081641256809235,
        0.05462445691227913,
        0.003972334787249565,
        -0.06147955358028412,
        -0.029779091477394104,
        -0.06453459709882736,
        -0.04034026339650154,
        0.04297476261854172,
        0.004196281544864178,
        0.04004739224910736,
        -0.061324503272771835,
        0.0380532331764698,
        0.0608314611017704,
        0.03711770847439766,
        0.0005442500114440918,
        -0.000058661538787418976,
        0.05114700645208359,
        0.059275608509778976,
        0.06409834325313568,
        -0.0475899912416935,
        0.023176776245236397,
        0.020777391269803047,
        -0.010826014913618565,
        0.03963461145758629,
        0.013429025188088417,
        -0.02517414465546608,
        -0.037411998957395554,
        -0.056050222367048264,
        0.02652539126574993,
        -0.053542912006378174,
        0.06349844485521317,
        -0.06237346678972244,
        -0.0339621864259243,
        0.036748554557561874,
        0.027797503396868706,
        -0.06292321532964706,
        0.04168708249926567,
        -0.06063137203454971,
        -0.061932601034641266,
        0.06384331732988358,
        0.03920648619532585,
        0.029669225215911865,
        -0.05037633702158928,
        -0.04526578262448311,
        0.06460700929164886,
        -0.04315156862139702,
        -0.011506620794534683,
        -0.011221787892282009,
        0.06150675192475319,
        -0.04552304372191429,
        0.0051399110816419125,
        0.04267255589365959,
        0.053744371980428696,
        0.06281622499227524,
        -0.03846485912799835,
        0.05583376809954643,
        0.004853886552155018,
        0.06391582638025284,
        0.0580408088862896,
        -0.05718984827399254,
        0.005177306476980448,
        -0.06185610592365265,
        0.05676431208848953,
        -0.01986284926533699,
        -0.06280092895030975,
        0.053468022495508194,
        -0.0334172286093235,
        -0.06403596699237823,
        0.0021021850407123566,
        0.06020345538854599,
        0.010982283391058445,
        0.05026121065020561,
        -0.061714209616184235,
        -0.05867043510079384,
        0.054960381239652634,
        -0.033164288848638535,
        0.019214240834116936,
        -0.060196876525878906,
        -0.021845536306500435,
        -0.06185616925358772,
        0.004285873845219612,
        -0.030418576672673225,
        0.04319223761558533,
        0.053439751267433167,
        -0.012488809414207935,
        0.06438761204481125,
        0.04640154913067818,
        0.008515789173543453,
        0.05971180647611618,
        0.03302319347858429,
        -0.057600781321525574,
        -0.062452130019664764,
        -0.06011977791786194,
        -0.028878111392259598,
        -0.05519955977797508,
        0.06303596496582031,
        0.016849510371685028,
        0.026632968336343765,
        -0.06113601475954056,
        -0.06272675842046738,
        0.006216494366526604,
        -0.05551249533891678,
        -0.05960320681333542,
        0.03676285594701767,
        0.05596553906798363,
        -0.06403771042823792,
        0.02315075881779194,
        0.06427755206823349,
        0.018948884680867195,
        0.06284482777118683,
        0.016609521582722664,
        0.061870232224464417,
        0.008432962000370026,
        0.051036037504673004,
        -0.06054195761680603,
        -0.006449433974921703,
        0.06250802427530289,
        0.04729652777314186,
        -0.06441937386989594,
        0.032255370169878006,
        -0.06140616908669472,
        0.04553975537419319,
        -0.051981113851070404,
        -0.0012384250294417143,
        0.03392611816525459,
        0.03575117141008377,
        0.007067912723869085,
        0.047046370804309845,
        0.061268363147974014,
        0.057517144829034805,
        0.04375564306974411,
        0.029777895659208298,
        -0.01385369524359703,
        -0.03770119696855545,
        0.03853960707783699,
        0.0456150621175766,
        0.021361220628023148,
        0.03319205716252327,
        0.041021667420864105,
        -0.003493120428174734,
        -0.054762981832027435,
        0.04305542632937431,
        0.059444181621074677,
        -0.06292367726564407,
        0.03225203603506088,
        0.06152763217687607,
        -0.03131202608346939,
        0.006324932910501957,
        0.06385242938995361,
        0.02415383607149124,
        0.01931416429579258,
        0.041079793125391006,
        0.004403380677103996,
        -0.0551752932369709,
        0.05976719409227371,
        -0.05653097853064537,
        0.015920128673315048,
        -0.012590141966938972,
        -0.00526726758107543,
        0.03506145998835564,
        -0.043633121997117996,
        -0.03470640629529953,
        0.03266969323158264,
        -0.04188840091228485,
        -0.005094039719551802,
        -0.034097831696271896,
        -0.009181109257042408,
        -0.01470974087715149,
        -0.007295402232557535,
        0.0025416086427867413,
        0.028484299778938293,
        -0.05922158807516098,
        0.03310457244515419,
        0.031134814023971558,
        0.014179999940097332,
        -0.05110454186797142,
        0.025897938758134842,
        -0.05266013741493225,
        0.05822760611772537,
        0.0013177854707464576,
        0.037383683025836945,
        -0.012409687973558903,
        0.04948229715228081,
        0.06021204590797424,
        -0.015041246078908443,
        0.06049303337931633,
        0.008760043419897556,
        0.03173619881272316,
        0.06449032574892044,
        -0.051868095993995667,
        -0.054820988327264786,
        0.045333947986364365,
        0.044240809977054596,
        0.013601496815681458,
        0.053801726549863815,
        0.022741110995411873,
        -0.05051017180085182,
        -0.0013311161892488599,
        -0.029235871508717537,
        -0.0523732528090477,
        0.04002830386161804,
        -0.001217750832438469,
        0.06457257270812988,
        0.06080494821071625,
        -0.026256315410137177,
        -0.04221408814191818,
        0.06417684257030487,
        -0.019411083310842514,
        0.06361078470945358,
        -0.06378653645515442,
        0.05846181511878967,
        0.03793059661984444,
        -0.003049249993637204,
        -0.06452669948339462,
        -0.007896131835877895,
        -0.010817169211804867,
        -0.056327205151319504,
        -0.04245458170771599,
        -0.06207262724637985,
        0.04668831452727318,
        -0.05939885601401329,
        -0.06169836223125458,
        0.062134239822626114,
        -0.059712957590818405,
        -0.04395424574613571,
        0.03887197747826576,
        -0.014140975661575794,
        0.06445272266864777,
        -0.04857359081506729,
        -0.05103056877851486,
        0.005065070930868387,
        0.010192266665399075,
        0.01725827157497406,
        -0.009435730054974556,
        0.0525953434407711,
        -0.03595024719834328,
        0.036465469747781754,
        0.05358836427330971,
        -0.03006349876523018,
        0.06306774914264679,
        -0.03747555986046791,
        -0.0578216053545475,
        0.04543433338403702,
        -0.04741162061691284,
        -0.005691946018487215,
        -0.03813176602125168,
        -0.034507375210523605,
        -0.03734283894300461,
        -0.06396324932575226,
        -0.06284881383180618,
        0.059438955038785934,
        0.029815489426255226,
        -0.05505506321787834,
        0.0215789545327425,
        0.05449777469038963,
        0.012941010296344757,
        0.05269072949886322,
        0.052795007824897766,
        0.05918778479099274,
        -0.020901083946228027,
        0.05866897478699684,
        0.048191845417022705,
        -0.06313028186559677,
        0.062321409583091736,
        0.05861387029290199,
        -0.05829408019781113,
        0.057724837213754654,
        0.05470898747444153,
        0.03965882956981659,
        -0.03948083892464638,
        0.0469583235681057,
        -0.05066406726837158,
        0.05086178332567215,
        -0.0593361034989357,
        -0.0002087522589135915,
        -0.03644341230392456,
        -0.06254173815250397,
        -0.0003570496628526598,
        -0.0005773697048425674,
        -0.04399363696575165,
        -0.018944691866636276,
        -0.0366220697760582,
        -0.060294944792985916,
        0.02278365194797516,
        -0.05545314773917198,
        0.04346499592065811,
        -0.03831516206264496,
        -0.06039907783269882,
        0.01819342002272606,
        0.017237601801753044,
        0.01599953882396221,
        0.05768314003944397,
        -0.05994392931461334,
        -0.012124852277338505,
        -0.020528467372059822,
        0.02401474118232727,
        -0.023238368332386017,
        -0.003783388761803508,
        -0.04874495789408684,
        -0.047588981688022614,
        -0.058269478380680084,
        0.06023620814085007,
        0.005008588079363108,
        0.00931201595813036,
        0.05948672816157341,
        -0.04473032429814339,
        0.04939434677362442,
        -0.04427363723516464,
        0.061745259910821915,
        -0.06446775048971176,
        0.007069075480103493,
        0.0638822391629219,
        0.03407365828752518,
        0.012899522669613361,
        0.05547138676047325,
        -0.049505479633808136,
        0.06245307996869087,
        0.032063134014606476,
        0.029147984459996223,
        -0.011295311152935028,
        0.018913784995675087,
        0.006682049483060837,
        0.051410604268312454,
        -0.05866696685552597,
        -0.0445762537419796,
        0.05787808075547218,
        -0.05866647884249687,
        0.05759848281741142,
        -0.007011204957962036,
        -0.02755190245807171,
        -0.004449633881449699,
        0.05177891254425049,
        -0.05627520754933357,
        0.036678362637758255,
        -0.024529380723834038,
        -0.017954697832465172,
        -0.054875265806913376,
        0.015595406293869019,
        0.004893713165074587,
        0.03172461315989494,
        0.05948805809020996,
        -0.012869752012193203,
        -0.004314167890697718,
        -0.060530535876750946,
        0.059495020657777786,
        0.056122008711099625,
        0.03855348750948906,
        0.035822544246912,
        -0.06234588101506233,
        -0.06392906606197357,
        0.06369856745004654,
        0.06182899698615074,
        -0.022498564794659615,
        -0.050578344613313675,
        -0.0630296841263771,
        -0.05838941037654877,
        -0.04401298984885216,
        -0.05776713043451309,
        -0.04912233352661133,
        -0.03232117369771004,
        -0.02487005479633808,
        -0.05331990867853165,
        0.05955606326460838,
        0.056137438863515854,
        0.06045370548963547,
        0.005063139367848635,
        -0.05734797194600105,
        -0.034671634435653687,
        0.06008424982428551,
        -0.0015709592262282968,
        -0.059445708990097046,
        -0.0024724837858229876,
        0.04341293126344681,
        0.005442038644105196,
        -0.038564980030059814,
        0.0528474859893322,
        0.012056620791554451,
        -0.05673738196492195,
        -0.06206506863236427,
        0.05062827467918396,
        -0.01835036277770996,
        0.05589409917593002,
        -0.04553338512778282,
        -0.015287047252058983,
        0.018788980320096016,
        0.025969548150897026,
        0.03616638854146004,
        -0.03961801156401634,
        -0.05490368977189064,
        0.013995517045259476,
        -0.04587351903319359,
        -0.041079290211200714,
        0.04515360668301582,
        -0.03422151505947113,
        0.01875199005007744,
        0.0554966926574707,
        -0.05485748499631882,
        0.05705031380057335,
        -0.03439465910196304,
        -0.06167677417397499,
        0.023477056995034218,
        0.035802971571683884,
        -0.05266387388110161,
        -0.06348966807126999,
        0.04181797429919243,
        -0.017836950719356537,
        0.00199885037727654,
        0.052037931978702545,
        0.05518246069550514,
        0.049233049154281616,
        0.04858233779668808,
        0.06417226046323776,
        0.027003386989235878,
        0.05794890969991684,
        -0.04272927716374397,
        0.06056271865963936,
        0.037736330181360245,
        -0.03858460858464241,
        0.006040646228939295,
        -0.04282480478286743,
        0.0312642902135849,
        -0.00038704200414940715,
        -0.060597918927669525,
        0.050523143261671066,
        -0.019576409831643105,
        -0.039029810577631,
        0.046503473073244095,
        -0.011303423903882504,
        -0.018319271504878998,
        -0.059983137995004654,
        0.05119829997420311,
        -0.0037710557226091623,
        0.06164579838514328,
        0.009991318918764591,
        0.05874016135931015,
        -0.05829266831278801,
        -0.0417054258286953,
        0.010671034455299377,
        0.044338636100292206,
        0.041340772062540054,
        -0.01985197141766548,
        -0.05607624724507332,
        -0.046794358640909195,
        0.06033078581094742,
        0.03966955468058586,
        -0.017507465556263924,
        -0.05395819619297981,
        -0.04377420246601105,
        -0.059365227818489075,
        -0.04174875095486641,
        -0.008674953132867813,
        0.032278113067150116,
        0.04353378713130951,
        0.05541231483221054,
        0.046758513897657394,
        -0.055372077971696854,
        0.05317099764943123,
        -0.06299787759780884,
        0.021298812702298164,
        -0.004227526020258665,
        0.04147598147392273,
        -0.03187296539545059,
        0.021396169438958168,
        0.05374230071902275,
        0.03658904880285263,
        0.05939934775233269,
        0.056213974952697754,
        -0.0590001717209816,
        -0.04856760799884796,
        -0.05033453553915024,
        0.025303799659013748,
        0.004650117363780737,
        -0.05250651761889458,
        0.04338577762246132,
        0.009561548940837383,
        0.005621911957859993,
        -0.06464283168315887,
        0.008101068437099457,
        0.06405270844697952
    ],
    [
        0.002674101386219263,
        0.05034102126955986,
        0.034247614443302155,
        0.02271183207631111,
        -0.04255121946334839,
        -0.0028218983206897974,
        -0.051021624356508255,
        -0.06354112923145294,
        0.06199420616030693,
        0.00862167403101921,
        0.020811624825000763,
        0.01874127984046936,
        -0.029542697593569756,
        -0.0199994295835495,
        -0.05517023429274559,
        0.06462035328149796,
        0.007668153382837772,
        0.061712659895420074,
        -0.060562122613191605,
        -0.041286587715148926,
        -0.030436266213655472,
        0.054527413100004196,
        -0.022710924968123436,
        0.004413755144923925,
        -0.01945512555539608,
        0.05179388448596001,
        0.049786362797021866,
        -0.05066540837287903,
        0.016922330483794212,
        -0.03376669064164162,
        0.03673366457223892,
        -0.051515135914087296,
        -0.044552359730005264,
        -0.032081641256809235,
        0.05462445691227913,
        0.003972334787249565,
        -0.06147955358028412,
        -0.029779091477394104,
        -0.06453459709882736,
        -0.04034026339650154,
        0.04297476261854172,
        0.004196281544864178,
        0.04004739224910736,
        -0.061324503272771835,
        0.0380532331764698,
        0.0608314611017704,
        0.03711770847439766,
        0.0005442500114440918,
        -0.000058661538787418976,
        0.05114700645208359,
        0.059275608509778976,
        0.06409834325313568,
        -0.0475899912416935,
        0.023176776245236397,
        0.020777391269803047,
        -0.010826014913618565,
        0.03963461145758629,
        0.013429025188088417,
        -0.02517414465546608,
        -0.037411998957395554,
        -0.056050222367048264,
        0.02652539126574993,
        -0.053542912006378174,
        0.06349844485521317,
        -0.06237346678972244,
        -0.0339621864259243,
        0.036748554557561874,
        0.027797503396868706,
        -0.06292321532964706,
        0.04168708249926567,
        -0.06063137203454971,
        -0.061932601034641266,
        0.06384331732988358,
        0.03920648619532585,
        0.029669225215911865,
        -0.05037633702158928,
        -0.04526578262448311,
        0.06460700929164886,
        -0.04315156862139702,
        -0.011506620794534683,
        -0.011221787892282009,
        0.06150675192475319,
        -0.04552304372191429,
        0.0051399110816419125,
        0.04267255589365959,
        0.053744371980428696,
        0.06281622499227524,
        -0.03846485912799835,
        0.05583376809954643,
        0.004853886552155018,
        0.06391582638025284,
        0.0580408088862896,
        -0.05718984827399254,
        0.005177306476980448,
        -0.06185610592365265,
        0.05676431208848953,
        -0.01986284926533699,
        -0.06280092895030975,
        0.053468022495508194,
        -0.0334172286093235,
        -0.06403596699237823,
        0.0021021850407123566,
        0.06020345538854599,
        0.010982283391058445,
        0.05026121065020561,
        -0.061714209616184235,
        -0.05867043510079384,
        0.054960381239652634,
        -0.033164288848638535,
        0.019214240834116936,
        -0.060196876525878906,
        -0.021845536306500435,
        -0.06185616925358772,
        0.004285873845219612,
        -0.030418576672673225,
        0.04319223761558533,
        0.053439751267433167,
        -0.012488809414207935,
        0.06438761204481125,
        0.04640154913067818,
        0.008515789173543453,
        0.05971180647611618,
        0.03302319347858429,
        -0.057600781321525574,
        -0.062452130019664764,
        -0.06011977791786194,
        -0.028878111392259598,
        -0.05519955977797508,
        0.06303596496582031,
        0.016849510371685028,
        0.026632968336343765,
        -0.06113601475954056,
        -0.06272675842046738,
        0.006216494366526604,
        -0.05551249533891678,
        -0.05960320681333542,
        0.03676285594701767,
        0.05596553906798363,
        -0.06403771042823792,
        0.02315075881779194,
        0.06427755206823349,
        0.018948884680867195,
        0.06284482777118683,
        0.016609521582722664,
        0.061870232224464417,
        0.008432962000370026,
        0.051036037504673004,
        -0.06054195761680603,
        -0.006449433974921703,
        0.06250802427530289,
        0.04729652777314186,
        -0.06441937386989594,
        0.032255370169878006,
        -0.06140616908669472,
        0.04553975537419319,
        -0.051981113851070404,
        -0.0012384250294417143,
        0.03392611816525459,
        0.03575117141008377,
        0.007067912723869085,
        0.047046370804309845,
        0.061268363147974014,
        0.057517144829034805,
        0.04375564306974411,
        0.029777895659208298,
        -0.01385369524359703,
        -0.03770119696855545,
        0.03853960707783699,
        0.0456150621175766,
        0.021361220628023148,
        0.03319205716252327,
        0.041021667420864105,
        -0.003493120428174734,
        -0.054762981832027435,
        0.04305542632937431,
        0.059444181621074677,
        -0.06292367726564407,
        0.03225203603506088,
        0.06152763217687607,
        -0.03131202608346939,
        0.006324932910501957,
        0.06385242938995361,
        0.02415383607149124,
        0.01931416429579258,
        0.041079793125391006,
        0.004403380677103996,
        -0.0551752932369709,
        0.05976719409227371,
        -0.05653097853064537,
        0.015920128673315048,
        -0.012590141966938972,
        -0.00526726758107543,
        0.03506145998835564,
        -0.043633121997117996,
        -0.03470640629529953,
        0.03266969323158264,
        -0.04188840091228485,
        -0.005094039719551802,
        -0.034097831696271896,
        -0.009181109257042408,
        -0.01470974087715149,
        -0.007295402232557535,
        0.0025416086427867413,
        0.028484299778938293,
        -0.05922158807516098,
        0.03310457244515419,
        0.031134814023971558,
        0.014179999940097332,
        -0.05110454186797142,
        0.025897938758134842,
        -0.05266013741493225,
        0.05822760611772537,
        0.0013177854707464576,
        0.037383683025836945,
        -0.012409687973558903,
        0.04948229715228081,
        0.06021204590797424,
        -0.015041246078908443,
        0.06049303337931633,
        0.008760043419897556,
        0.03173619881272316,
        0.06449032574892044,
        -0.051868095993995667,
        -0.054820988327264786,
        0.045333947986364365,
        0.044240809977054596,
        0.013601496815681458,
        0.053801726549863815,
        0.022741110995411873,
        -0.05051017180085182,
        -0.0013311161892488599,
        -0.029235871508717537,
        -0.0523732528090477,
        0.04002830386161804,
        -0.001217750832438469,
        0.06457257270812988,
        0.06080494821071625,
        -0.026256315410137177,
        -0.04221408814191818,
        0.06417684257030487,
        -0.019411083310842514,
        0.06361078470945358,
        -0.06378653645515442,
        0.05846181511878967,
        0.03793059661984444,
        -0.003049249993637204,
        -0.06452669948339462,
        -0.007896131835877895,
        -0.010817169211804867,
        -0.056327205151319504,
        -0.04245458170771599,
        -0.06207262724637985,
        0.04668831452727318,
        -0.05939885601401329,
        -0.06169836223125458,
        0.062134239822626114,
        -0.059712957590818405,
        -0.04395424574613571,
        0.03887197747826576,
        -0.014140975661575794,
        0.06445272266864777,
        -0.04857359081506729,
        -0.05103056877851486,
        0.005065070930868387,
        0.010192266665399075,
        0.01725827157497406,
        -0.009435730054974556,
        0.0525953434407711,
        -0.03595024719834328,
        0.036465469747781754,
        0.05358836427330971,
        -0.03006349876523018,
        0.06306774914264679,
        -0.03747555986046791,
        -0.0578216053545475,
        0.04543433338403702,
        -0.04741162061691284,
        -0.005691946018487215,
        -0.03813176602125168,
        -0.034507375210523605,
        -0.03734283894300461,
        -0.06396324932575226,
        -0.06284881383180618,
        0.059438955038785934,
        0.029815489426255226,
        -0.05505506321787834,
        0.0215789545327425,
        0.05449777469038963,
        0.012941010296344757,
        0.05269072949886322,
        0.052795007824897766,
        0.05918778479099274,
        -0.020901083946228027,
        0.05866897478699684,
        0.048191845417022705,
        -0.06313028186559677,
        0.062321409583091736,
        0.05861387029290199,
        -0.05829408019781113,
        0.057724837213754654,
        0.05470898747444153,
        0.03965882956981659,
        -0.03948083892464638,
        0.0469583235681057,
        -0.05066406726837158,
        0.05086178332567215,
        -0.0593361034989357,
        -0.0002087522589135915,
        -0.03644341230392456,
        -0.06254173815250397,
        -0.0003570496628526598,
        -0.0005773697048425674,
        -0.04399363696575165,
        -0.018944691866636276,
        -0.0366220697760582,
        -0.060294944792985916,
        0.02278365194797516,
        -0.05545314773917198,
        0.04346499592065811,
        -0.03831516206264496,
        -0.06039907783269882,
        0.01819342002272606,
        0.017237601801753044,
        0.01599953882396221,
        0.05768314003944397,
        -0.05994392931461334,
        -0.012124852277338505,
        -0.020528467372059822,
        0.02401474118232727,
        -0.023238368332386017,
        -0.003783388761803508,
        -0.04874495789408684,
        -0.047588981688022614,
        -0.058269478380680084,
        0.06023620814085007,
        0.005008588079363108,
        0.00931201595813036,
        0.05948672816157341,
        -0.04473032429814339,
        0.04939434677362442,
        -0.04427363723516464,
        0.061745259910821915,
        -0.06446775048971176,
        0.007069075480103493,
        0.0638822391629219,
        0.03407365828752518,
        0.012899522669613361,
        0.05547138676047325,
        -0.049505479633808136,
        0.06245307996869087,
        0.032063134014606476,
        0.029147984459996223,
        -0.011295311152935028,
        0.018913784995675087,
        0.006682049483060837,
        0.051410604268312454,
        -0.05866696685552597,
        -0.0445762537419796,
        0.05787808075547218,
        -0.05866647884249687,
        0.05759848281741142,
        -0.007011204957962036,
        -0.02755190245807171,
        -0.004449633881449699,
        0.05177891254425049,
        -0.05627520754933357,
        0.036678362637758255,
        -0.024529380723834038,
        -0.017954697832465172,
        -0.054875265806913376,
        0.015595406293869019,
        0.004893713165074587,
        0.03172461315989494,
        0.05948805809020996,
        -0.012869752012193203,
        -0.004314167890697718,
        -0.060530535876750946,
        0.059495020657777786,
        0.056122008711099625,
        0.03855348750948906,
        0.035822544246912,
        -0.06234588101506233,
        -0.06392906606197357,
        0.06369856745004654,
        0.06182899698615074,
        -0.022498564794659615,
        -0.050578344613313675,
        -0.0630296841263771,
        -0.05838941037654877,
        -0.04401298984885216,
        -0.05776713043451309,
        -0.04912233352661133,
        -0.03232117369771004,
        -0.02487005479633808,
        -0.05331990867853165,
        0.05955606326460838,
        0.056137438863515854,
        0.06045370548963547,
        0.005063139367848635,
        -0.05734797194600105,
        -0.034671634435653687,
        0.06008424982428551,
        -0.0015709592262282968,
        -0.059445708990097046,
        -0.0024724837858229876,
        0.04341293126344681,
        0.005442038644105196,
        -0.038564980030059814,
        0.0528474859893322,
        0.012056620791554451,
        -0.05673738196492195,
        -0.06206506863236427,
        0.05062827467918396,
        -0.01835036277770996,
        0.05589409917593002,
        -0.04553338512778282,
        -0.015287047252058983,
        0.018788980320096016,
        0.025969548150897026,
        0.03616638854146004,
        -0.03961801156401634,
        -0.05490368977189064,
        0.013995517045259476,
        -0.04587351903319359,
        -0.041079290211200714,
        0.04515360668301582,
        -0.03422151505947113,
        0.01875199005007744,
        0.0554966926574707,
        -0.05485748499631882,
        0.05705031380057335,
        -0.03439465910196304,
        -0.06167677417397499,
        0.023477056995034218,
        0.035802971571683884,
        -0.05266387388110161,
        -0.06348966807126999,
        0.04181797429919243,
        -0.017836950719356537,
        0.00199885037727654,
        0.052037931978702545,
        0.05518246069550514,
        0.049233049154281616,
        0.04858233779668808,
        0.06417226046323776,
        0.027003386989235878,
        0.05794890969991684,
        -0.04272927716374397,
        0.06056271865963936,
        0.037736330181360245,
        -0.03858460858464241,
        0.006040646228939295,
        -0.04282480478286743,
        0.0312642902135849,
        -0.00038704200414940715,
        -0.060597918927669525,
        0.050523143261671066,
        -0.019576409831643105,
        -0.039029810577631,
        0.046503473073244095,
        -0.011303423903882504,
        -0.018319271504878998,
        -0.059983137995004654,
        0.05119829997420311,
        -0.0037710557226091623,
        0.06164579838514328,
        0.009991318918764591,
        0.05874016135931015,
        -0.05829266831278801,
        -0.0417054258286953,
        0.010671034455299377,
        0.044338636100292206,
        0.041340772062540054,
        -0.01985197141766548,
        -0.05607624724507332,
        -0.046794358640909195,
        0.06033078581094742,
        0.03966955468058586,
        -0.017507465556263924,
        -0.05395819619297981,
        -0.04377420246601105,
        -0.059365227818489075,
        -0.04174875095486641,
        -0.008674953132867813,
        0.032278113067150116,
        0.04353378713130951,
        0.05541231483221054,
        0.046758513897657394,
        -0.055372077971696854,
        0.05317099764943123,
        -0.06299787759780884,
        0.021298812702298164,
        -0.004227526020258665,
        0.04147598147392273,
        -0.03187296539545059,
        0.021396169438958168,
        0.05374230071902275,
        0.03658904880285263,
        0.05939934775233269,
        0.056213974952697754,
        -0.0590001717209816,
        -0.04856760799884796,
        -0.05033453553915024,
        0.025303799659013748,
        0.004650117363780737,
        -0.05250651761889458,
        0.04338577762246132,
        0.009561548940837383,
        0.005621911957859993,
        -0.06464283168315887,
        0.008101068437099457,
        0.06405270844697952
    ],
    [
        -0.02050514705479145,
        0.03420272842049599,
        0.03474966064095497,
        0.027367549017071724,
        -0.04272336885333061,
        -0.02587321773171425,
        -0.051919322460889816,
        -0.06133350357413292,
        0.06081918999552727,
        0.021206624805927277,
        0.017556028440594673,
        0.027470069006085396,
        -0.030374357476830482,
        -0.028976520523428917,
        -0.05519138276576996,
        0.062083158642053604,
        -0.004480359610170126,
        0.059695374220609665,
        -0.05916669964790344,
        -0.04643094912171364,
        -0.015394306741654873,
        0.056320805102586746,
        0.011586983688175678,
        -0.0033943597227334976,
        -0.00658566877245903,
        0.051325973123311996,
        0.05682780593633652,
        -0.05522550642490387,
        0.021527474746108055,
        -0.014482694678008556,
        0.01861637830734253,
        -0.044809114187955856,
        -0.04634977877140045,
        -0.052029334008693695,
        0.0541837215423584,
        0.0005036485381424427,
        -0.06009577959775925,
        -0.037683941423892975,
        -0.062016528099775314,
        -0.04906325787305832,
        0.05026184394955635,
        0.01427752710878849,
        0.022763431072235107,
        -0.059275634586811066,
        0.03865664824843407,
        0.06003894284367561,
        0.038052186369895935,
        0.01682158373296261,
        0.039442744106054306,
        0.05450393259525299,
        0.06102020666003227,
        0.061822980642318726,
        -0.05067308619618416,
        0.02923574298620224,
        0.018406104296445847,
        -0.033298928290605545,
        0.026462428271770477,
        0.017324570566415787,
        -0.009773883037269115,
        -0.04016662761569023,
        -0.05534133315086365,
        0.03276810050010681,
        -0.05148240178823471,
        0.06189442425966263,
        -0.058313656598329544,
        -0.02581074647605419,
        0.03046444058418274,
        0.008031087927520275,
        -0.060879215598106384,
        0.044695887714624405,
        -0.05962856113910675,
        -0.060348618775606155,
        0.06165018305182457,
        0.018182745203375816,
        0.05204898864030838,
        -0.04688187688589096,
        -0.02554897777736187,
        0.061954740434885025,
        -0.04624504595994949,
        -0.02631213143467903,
        -0.003836622927337885,
        0.059763506054878235,
        -0.04696471989154816,
        0.027591831982135773,
        0.04706372693181038,
        0.05377843230962753,
        0.060730744153261185,
        -0.04049181193113327,
        0.05467353016138077,
        0.0019472483545541763,
        0.05796512961387634,
        0.056590914726257324,
        -0.060157410800457,
        0.0021043026354163885,
        -0.058986518532037735,
        0.056561507284641266,
        -0.012127725407481194,
        -0.061120420694351196,
        0.050844475626945496,
        -0.030115405097603798,
        -0.061531007289886475,
        0.029011357575654984,
        0.060210999101400375,
        0.02852618880569935,
        0.05287052318453789,
        -0.06122519075870514,
        -0.05053028464317322,
        0.05761721730232239,
        -0.04355307295918465,
        0.03749905899167061,
        -0.06009252369403839,
        -0.013874135911464691,
        -0.05925940349698067,
        0.01432576309889555,
        -0.03837469220161438,
        0.04474003240466118,
        0.055939413607120514,
        -0.018724624067544937,
        0.061991576105356216,
        0.05052480101585388,
        0.009684295393526554,
        0.05919954925775528,
        0.041403017938137054,
        -0.05295509472489357,
        -0.0593671053647995,
        -0.05463410168886185,
        -0.027751905843615532,
        -0.05912081152200699,
        0.0601966455578804,
        0.040069401264190674,
        0.04549712315201759,
        -0.056902047246694565,
        -0.059757571667432785,
        0.00916387140750885,
        -0.05613555759191513,
        -0.05675499513745308,
        0.04522417113184929,
        0.058063630014657974,
        -0.06176728382706642,
        0.033973824232816696,
        0.061398908495903015,
        0.023416802287101746,
        0.059960778802633286,
        0.01422037836164236,
        0.059470027685165405,
        0.006605212576687336,
        0.05352821201086044,
        -0.05884011462330818,
        0.010769408196210861,
        0.06112676113843918,
        0.049093425273895264,
        -0.06185455247759819,
        0.048652347177267075,
        -0.05916118994355202,
        0.051674772053956985,
        -0.05504188314080238,
        -0.02199113741517067,
        0.03648564592003822,
        0.02610933966934681,
        0.027401408180594444,
        0.05420810729265213,
        0.050967972725629807,
        0.0538158193230629,
        0.04042259231209755,
        0.031192347407341003,
        0.006272044964134693,
        -0.03064272552728653,
        0.04801798611879349,
        0.031977150589227676,
        0.02045113407075405,
        0.03471336141228676,
        0.04212240129709244,
        0.0002939997357316315,
        -0.0560394823551178,
        0.04477692395448685,
        0.06020084023475647,
        -0.06097176671028137,
        0.0385560505092144,
        0.0585879422724247,
        -0.05331389978528023,
        -0.008123937994241714,
        0.061961475759744644,
        0.024066932499408722,
        0.009674279019236565,
        0.03770554065704346,
        0.005620600190013647,
        -0.05591834336519241,
        0.05827007442712784,
        -0.05785803496837616,
        -0.0035507874563336372,
        -0.015151981264352798,
        0.014973022043704987,
        0.03305372595787048,
        -0.03029639832675457,
        -0.04640693590044975,
        0.02092904970049858,
        -0.04302487522363663,
        0.0070968917571008205,
        -0.0467347577214241,
        0.011391008272767067,
        -0.0013976199552416801,
        -0.013303235173225403,
        -0.022903520613908768,
        0.023618044331669807,
        -0.058368030935525894,
        0.0423700287938118,
        0.03590289503335953,
        0.02201041392982006,
        -0.050105251371860504,
        0.03750777244567871,
        -0.05280151590704918,
        0.0563574880361557,
        0.006140183657407761,
        0.043488238006830215,
        -0.019165923818945885,
        0.04492722824215889,
        0.05708148702979088,
        -0.03603319823741913,
        0.05993949994444847,
        0.008625575341284275,
        0.01284633670002222,
        0.06190270557999611,
        -0.05310682952404022,
        -0.047261252999305725,
        0.05098910257220268,
        0.04570470377802849,
        0.02727612480521202,
        0.0509595051407814,
        0.03833886981010437,
        -0.05286089703440666,
        0.028234200552105904,
        -0.04197344183921814,
        -0.050808995962142944,
        0.03961816057562828,
        -0.01706070452928543,
        0.06203675642609596,
        0.06023579090833664,
        -0.024953102692961693,
        -0.04731358215212822,
        0.06142294406890869,
        -0.017089540138840675,
        0.06159799546003342,
        -0.0618332140147686,
        0.05862393230199814,
        0.04658433794975281,
        0.0005003534024581313,
        -0.06190555915236473,
        0.021944263949990273,
        0.008278150111436844,
        -0.051649779081344604,
        -0.04844043031334877,
        -0.060350991785526276,
        0.03756141662597656,
        -0.05851171910762787,
        -0.059475209563970566,
        0.0593772754073143,
        -0.05961848422884941,
        -0.04818170890212059,
        0.04074803739786148,
        -0.02480015531182289,
        0.06177913025021553,
        -0.05146656930446625,
        -0.044718559831380844,
        0.012835090979933739,
        -0.00562766520306468,
        0.014666964299976826,
        0.019091978669166565,
        0.05260443687438965,
        -0.041895411908626556,
        0.03747520223259926,
        0.049189914017915726,
        -0.04625736549496651,
        0.061535630375146866,
        -0.03097616136074066,
        -0.058055952191352844,
        0.03438270464539528,
        -0.04952867701649666,
        0.000909725611563772,
        -0.04629337042570114,
        -0.028365302830934525,
        -0.04504907503724098,
        -0.06062331423163414,
        -0.061152420938014984,
        0.06023753806948662,
        0.04189533367753029,
        -0.05553336814045906,
        0.014893964864313602,
        0.05312877148389816,
        0.043261151760816574,
        0.04047178104519844,
        0.042142584919929504,
        0.056259769946336746,
        -0.033932875841856,
        0.05389230325818062,
        0.05326278135180473,
        -0.06078105792403221,
        0.05992484465241432,
        0.059730250388383865,
        -0.057706210762262344,
        0.05655808746814728,
        0.053990744054317474,
        0.028875498101115227,
        -0.030997559428215027,
        0.05060397461056709,
        -0.0383661650121212,
        0.04989512637257576,
        -0.056033410131931305,
        0.0027375572826713324,
        -0.03934299200773239,
        -0.059348855167627335,
        0.005439816974103451,
        -0.012070666067302227,
        -0.048781003803014755,
        -0.008369083516299725,
        -0.0331059992313385,
        -0.057773951441049576,
        0.02129787765443325,
        -0.05636348947882652,
        0.0427946038544178,
        -0.046960506588220596,
        -0.05469762161374092,
        0.0037332528736442327,
        0.015990231186151505,
        0.022960778325796127,
        0.054380953311920166,
        -0.058449022471904755,
        -0.025922447443008423,
        -0.011604745872318745,
        0.01996377669274807,
        -0.03383883461356163,
        0.00785657949745655,
        -0.049543969333171844,
        -0.05377919599413872,
        -0.05664637312293053,
        0.05797326937317848,
        0.024171413853764534,
        -0.008181232959032059,
        0.057624638080596924,
        -0.032942213118076324,
        0.04838097095489502,
        -0.04305252805352211,
        0.05911993980407715,
        -0.06196797266602516,
        -0.011214391328394413,
        0.06120273470878601,
        0.02805279940366745,
        0.0142131382599473,
        0.05663241073489189,
        -0.047170162200927734,
        0.059457603842020035,
        0.03713816776871681,
        0.030336612835526466,
        -0.02493756078183651,
        -0.025648370385169983,
        0.014878369867801666,
        0.04883386939764023,
        -0.05865279585123062,
        -0.04784037172794342,
        0.05940692499279976,
        -0.05948389321565628,
        0.05257638543844223,
        -0.009422113187611103,
        -0.004943107254803181,
        0.006416806019842625,
        0.05174354463815689,
        -0.05748489126563072,
        0.056209057569503784,
        -0.019798241555690765,
        0.0093931183218956,
        -0.05551505088806152,
        -0.0009496459970250726,
        0.004082747269421816,
        0.037971075624227524,
        0.0572330616414547,
        0.008827773854136467,
        0.009354012086987495,
        -0.059222351759672165,
        0.06022103130817413,
        0.05889024958014488,
        0.04442078620195389,
        0.01502044778317213,
        -0.059416331350803375,
        -0.061496343463659286,
        0.06057986244559288,
        0.05894960090517998,
        -0.023085439577698708,
        -0.05118955299258232,
        -0.060404691845178604,
        -0.0538465678691864,
        -0.03451279550790787,
        -0.06044922024011612,
        -0.05253395810723305,
        -0.03788060322403908,
        -0.021720806136727333,
        -0.04395288974046707,
        0.05779643729329109,
        0.05625502020120621,
        0.05576531961560249,
        0.036531902849674225,
        -0.059554990381002426,
        -0.028740474954247475,
        0.060428909957408905,
        0.010690818540751934,
        -0.05836506187915802,
        0.002359696663916111,
        0.031709518283605576,
        0.00638966029509902,
        -0.04962015897035599,
        0.052120134234428406,
        0.009397891350090504,
        -0.05698895454406738,
        -0.05913466215133667,
        0.05599276348948479,
        -0.0037157831247895956,
        0.05410101264715195,
        -0.044684045016765594,
        -0.02299170382320881,
        0.017767108976840973,
        0.017186421900987625,
        0.03332462161779404,
        -0.014565742574632168,
        -0.038548361510038376,
        0.03654249757528305,
        -0.050658125430345535,
        -0.010834156535565853,
        0.042353011667728424,
        -0.04365886002779007,
        0.030395204201340675,
        0.05062473937869072,
        -0.05564942583441734,
        0.05928141251206398,
        -0.02517380751669407,
        -0.06122802942991257,
        0.008613339625298977,
        0.021045926958322525,
        -0.05588589236140251,
        -0.05891004949808121,
        0.036186132580041885,
        0.007287365850061178,
        0.011765099130570889,
        0.04156781733036041,
        0.056737735867500305,
        0.051398877054452896,
        0.05440419539809227,
        0.06196177005767822,
        0.024605806916952133,
        0.058191731572151184,
        -0.04998799040913582,
        0.05843658000230789,
        0.05115870386362076,
        -0.04780520498752594,
        0.0009115691646002233,
        -0.02820589579641819,
        0.0420810729265213,
        0.0013840803876519203,
        -0.0591735802590847,
        0.03765583038330078,
        -0.023386089131236076,
        -0.029597267508506775,
        0.05578693374991417,
        -0.013959470205008984,
        -0.03236396610736847,
        -0.05849984288215637,
        0.05203109234571457,
        0.02593369223177433,
        0.060398660600185394,
        0.0008647420909255743,
        0.05758315324783325,
        -0.05911414325237274,
        -0.0489947646856308,
        0.011917064897716045,
        0.0521540604531765,
        0.042113445699214935,
        -0.016118301078677177,
        -0.06055552884936333,
        -0.03416706249117851,
        0.056746263056993484,
        0.0394120067358017,
        -0.021740561351180077,
        -0.05292497202754021,
        -0.04630099982023239,
        -0.058808233588933945,
        -0.0540192611515522,
        -0.0036329547874629498,
        0.024665378034114838,
        0.04728376120328903,
        0.05725069344043732,
        0.04489675536751747,
        -0.05073654279112816,
        0.04613455757498741,
        -0.06085989251732826,
        0.02748125232756138,
        0.017627714201807976,
        0.03825408220291138,
        -0.022293725982308388,
        0.016893485561013222,
        0.043862491846084595,
        0.05055743083357811,
        0.05619756877422333,
        0.058814771473407745,
        -0.05672749504446983,
        -0.04279102757573128,
        -0.055592212826013565,
        0.01709548570215702,
        0.026834987103939056,
        -0.052569784224033356,
        0.04697994142770767,
        0.005121886730194092,
        0.020283591002225876,
        -0.06209497153759003,
        -0.003231514012441039,
        0.06121230497956276
    ],
    [
        0.007222658954560757,
        0.057404953986406326,
        0.044319428503513336,
        0.017689798027276993,
        -0.04619121178984642,
        0.028957702219486237,
        -0.04639311507344246,
        -0.06429304927587509,
        0.0624358095228672,
        -0.004442492965608835,
        0.030452221632003784,
        -0.019899770617485046,
        -0.02099158801138401,
        -0.0069123366847634315,
        -0.05860971659421921,
        0.06509450078010559,
        -0.012229371815919876,
        0.06273871660232544,
        -0.05932992696762085,
        -0.02545989118516445,
        -0.040720053017139435,
        0.05731479078531265,
        -0.025696123018860817,
        -0.005372846033424139,
        0.007279580924659967,
        0.042802322655916214,
        0.049478091299533844,
        -0.046244461089372635,
        0.014912945218384266,
        -0.03160043805837631,
        0.04973879083991051,
        -0.037602297961711884,
        -0.05075013265013695,
        -0.04064328223466873,
        0.05819031968712807,
        0.007040143013000488,
        -0.06288018077611923,
        -0.0337703675031662,
        -0.06382021307945251,
        -0.03714413195848465,
        0.026242999359965324,
        0.013815830461680889,
        0.038010891526937485,
        -0.05897289514541626,
        0.004468055907636881,
        0.05978398397564888,
        0.014477348886430264,
        -0.003199850209057331,
        0.001656938693486154,
        0.051108218729496,
        0.055710673332214355,
        0.06430008262395859,
        -0.04612749069929123,
        0.0280592143535614,
        0.024412302300333977,
        -0.0012377770617604256,
        0.025433320552110672,
        0.027483420446515083,
        -0.006424854043871164,
        -0.027235625311732292,
        -0.054413504898548126,
        0.036704547703266144,
        -0.052803173661231995,
        0.06327084451913834,
        -0.061954207718372345,
        -0.050418831408023834,
        0.02349364198744297,
        0.031053585931658745,
        -0.06378566473722458,
        0.04439828544855118,
        -0.05708296224474907,
        -0.06463591009378433,
        0.0651426687836647,
        0.0405825711786747,
        0.025414209812879562,
        -0.04754946753382683,
        -0.04451762139797211,
        0.06540700048208237,
        -0.03391112759709358,
        0.009825699031352997,
        -0.011186494491994381,
        0.061667345464229584,
        -0.048401132225990295,
        0.0009570707334205508,
        0.04494258761405945,
        0.05158558487892151,
        0.06302526593208313,
        -0.05281459540128708,
        0.055477578192949295,
        0.002428818726912141,
        0.06482088565826416,
        0.05772833526134491,
        -0.0559857040643692,
        0.023446867242455482,
        -0.06103162094950676,
        0.05629342049360275,
        -0.032908711582422256,
        -0.06361290067434311,
        0.052248816937208176,
        -0.02201409824192524,
        -0.0646185353398323,
        -0.008234072476625443,
        0.06092445179820061,
        0.004779849201440811,
        0.04682840779423714,
        -0.058281365782022476,
        -0.05684806406497955,
        0.03801044076681137,
        -0.04078246280550957,
        0.02948826365172863,
        -0.05849190428853035,
        0.0034855783451348543,
        -0.06249330937862396,
        -0.01030217669904232,
        -0.03988233581185341,
        0.014016235247254372,
        0.04973465949296951,
        0.0014832009328529239,
        0.06516938656568527,
        0.047617532312870026,
        -0.0024879530537873507,
        0.06118975952267647,
        0.02004098892211914,
        -0.050413619726896286,
        -0.06320757418870926,
        -0.05852235108613968,
        -0.03800225630402565,
        -0.043353237211704254,
        0.06323719024658203,
        -0.003953150473535061,
        0.008262266404926777,
        -0.06265772879123688,
        -0.06258474290370941,
        -0.0018078944412991405,
        -0.05611352622509003,
        -0.06085098907351494,
        0.029783274978399277,
        0.048676759004592896,
        -0.06408967077732086,
        0.03303365781903267,
        0.06522981077432632,
        0.012757878750562668,
        0.06344429403543472,
        0.014799760654568672,
        0.06401146948337555,
        -0.012608573772013187,
        0.05654274299740791,
        -0.06071031093597412,
        -0.027379730716347694,
        0.06276923418045044,
        0.05414090305566788,
        -0.06507214158773422,
        -0.018399864435195923,
        -0.060524024069309235,
        0.05760343745350838,
        -0.05194135010242462,
        0.01705440692603588,
        0.021626895293593407,
        0.023557135835289955,
        0.012209530919790268,
        0.043636709451675415,
        0.06300350278615952,
        0.05705573037266731,
        0.049640096724033356,
        0.044230278581380844,
        -0.03294917941093445,
        -0.04478266462683678,
        0.03502822667360306,
        0.048155587166547775,
        0.021745607256889343,
        0.00957247894257307,
        0.04264938831329346,
        0.005534308962523937,
        -0.04964834451675415,
        0.013406878337264061,
        0.05916362628340721,
        -0.0633600503206253,
        0.025391243398189545,
        0.06277186423540115,
        -0.027794785797595978,
        0.004615291953086853,
        0.062248021364212036,
        0.0041268207132816315,
        0.03709046170115471,
        0.04469452053308487,
        0.011259096674621105,
        -0.053415264934301376,
        0.06169148534536362,
        -0.061177078634500504,
        -0.00951558817178011,
        -0.023235995322465897,
        -0.011323516257107258,
        0.027300968766212463,
        -0.04657062143087387,
        -0.03333573043346405,
        0.04416347295045853,
        -0.02842588722705841,
        -0.005961137358099222,
        -0.03910403326153755,
        -0.013041866943240166,
        0.0011309217661619186,
        0.0043690819293260574,
        0.01633402891457081,
        0.034299690276384354,
        -0.060639310628175735,
        0.008512372151017189,
        0.04756813123822212,
        0.006312123965471983,
        -0.054881442338228226,
        -0.005596805363893509,
        -0.05041246488690376,
        0.060022544115781784,
        -0.01371383760124445,
        0.03212973102927208,
        -0.02248968370258808,
        0.04909839481115341,
        0.06281277537345886,
        -0.0028277619276195765,
        0.05968344211578369,
        0.0250320415943861,
        0.021232809871435165,
        0.06517740339040756,
        -0.05037006735801697,
        -0.05080009996891022,
        0.05523359775543213,
        -0.0030392096377909184,
        0.009813595563173294,
        0.05762019753456116,
        0.017577743157744408,
        -0.04933440312743187,
        0.005809817463159561,
        -0.046149708330631256,
        -0.04552100598812103,
        0.035705626010894775,
        0.008578062988817692,
        0.06534292548894882,
        0.060832537710666656,
        -0.027018412947654724,
        -0.03253272548317909,
        0.06497746706008911,
        -0.027890067547559738,
        0.06345576047897339,
        -0.06467089802026749,
        0.05839240550994873,
        0.031366731971502304,
        -0.026524288579821587,
        -0.0652373805642128,
        -0.023336073383688927,
        0.011930219829082489,
        -0.056863538920879364,
        -0.03524817153811455,
        -0.0632706731557846,
        0.04555162042379379,
        -0.060593441128730774,
        -0.06016375124454498,
        0.06244330108165741,
        -0.05519202724099159,
        -0.05407274514436722,
        0.030595431104302406,
        -0.026408717036247253,
        0.06511656194925308,
        -0.054049160331487656,
        -0.046189192682504654,
        0.012352663092315197,
        0.019413068890571594,
        0.024809468537569046,
        -0.03619309887290001,
        0.030367422848939896,
        -0.037189509719610214,
        0.03420662507414818,
        0.05473034828901291,
        -0.017498955130577087,
        0.06397069245576859,
        -0.04844251647591591,
        -0.060146402567625046,
        0.05087987333536148,
        -0.044984832406044006,
        0.0033323396928608418,
        -0.04941404610872269,
        -0.04336007311940193,
        -0.025373315438628197,
        -0.06523264944553375,
        -0.06382470577955246,
        0.061269789934158325,
        0.009450153447687626,
        -0.047776732593774796,
        0.012753868475556374,
        0.055642783641815186,
        0.021328693255782127,
        0.05450015142560005,
        0.05111140385270119,
        0.05889664590358734,
        -0.022626962512731552,
        0.06047680601477623,
        0.046399712562561035,
        -0.06347370147705078,
        0.06257499009370804,
        0.05626595392823219,
        -0.06028509512543678,
        0.058885183185338974,
        0.05400939658284187,
        0.04405469447374344,
        -0.034756969660520554,
        0.04950052499771118,
        -0.059552308171987534,
        0.04161057993769646,
        -0.0566939152777195,
        0.018506811931729317,
        -0.03653150051832199,
        -0.06482461839914322,
        0.005414534360170364,
        0.010638204403221607,
        -0.0511578805744648,
        -0.021009305492043495,
        -0.02925054170191288,
        -0.05932255834341049,
        0.023857515305280685,
        -0.051428552716970444,
        0.04679932817816734,
        -0.03664350509643555,
        -0.05989623814821243,
        0.021720923483371735,
        0.008390774019062519,
        0.03272928670048714,
        0.05670673027634621,
        -0.06126382574439049,
        -0.03988756984472275,
        -0.04115329310297966,
        0.0020402148365974426,
        -0.028028316795825958,
        0.00757259875535965,
        -0.042501866817474365,
        -0.04427746310830116,
        -0.05350873991847038,
        0.06105508655309677,
        -0.013346296735107899,
        0.011953839100897312,
        0.06311292201280594,
        -0.04065319150686264,
        0.04053587093949318,
        -0.041085317730903625,
        0.06373316794633865,
        -0.06525672227144241,
        0.005908856634050608,
        0.06436087936162949,
        0.035909898579120636,
        0.024117320775985718,
        0.05670372396707535,
        -0.04419554024934769,
        0.06334046274423599,
        0.04191530868411064,
        0.017294293269515038,
        -0.024899376556277275,
        0.03978794440627098,
        -0.005273362621665001,
        0.05469074845314026,
        -0.055235009640455246,
        -0.04283050447702408,
        0.05626186355948448,
        -0.06078004464507103,
        0.059391457587480545,
        -0.014078141190111637,
        -0.020821720361709595,
        0.009901785291731358,
        0.0555778443813324,
        -0.05240591615438461,
        0.035844746977090836,
        -0.03522685170173645,
        -0.007194803096354008,
        -0.049363959580659866,
        0.012481183744966984,
        -0.000962582475040108,
        0.015837211161851883,
        0.058907970786094666,
        -0.016841955482959747,
        0.03089025616645813,
        -0.05960635095834732,
        0.05980759859085083,
        0.04948366433382034,
        0.04989772289991379,
        0.0387636236846447,
        -0.06319337338209152,
        -0.06416211277246475,
        0.06413110345602036,
        0.06158149987459183,
        -0.010328222066164017,
        -0.05366656929254532,
        -0.06395553052425385,
        -0.05830402672290802,
        -0.04208333417773247,
        -0.059216078370809555,
        -0.04789690673351288,
        -0.038512490689754486,
        -0.03484230488538742,
        -0.052902936935424805,
        0.0613752156496048,
        0.052362293004989624,
        0.06269925832748413,
        0.027748113498091698,
        -0.0578143447637558,
        -0.032857973128557205,
        0.06253471970558167,
        -0.010944562964141369,
        -0.05946798250079155,
        -0.005811410490423441,
        0.03609839454293251,
        -0.00217061466537416,
        -0.03831060603260994,
        0.05409983545541763,
        0.02201840654015541,
        -0.05755262449383736,
        -0.06393732875585556,
        0.049405328929424286,
        -0.0002514038060326129,
        0.06109440699219704,
        -0.038979433476924896,
        -0.024908829480409622,
        0.04814944043755531,
        0.03721769526600838,
        0.051822200417518616,
        -0.04039684310555458,
        -0.052083954215049744,
        0.005330254789441824,
        -0.04133252426981926,
        -0.036238767206668854,
        0.042012326419353485,
        -0.007764068897813559,
        0.004909674637019634,
        0.05404295772314072,
        -0.05674044415354729,
        0.05745020881295204,
        -0.022036321461200714,
        -0.062151599675416946,
        0.031170319765806198,
        0.0503031462430954,
        -0.052176207304000854,
        -0.06445584446191788,
        0.04058248922228813,
        -0.029497403651475906,
        0.007225790526717901,
        0.04942445456981659,
        0.04937775433063507,
        0.058222558349370956,
        0.053773071616888046,
        0.06223229318857193,
        0.022828541696071625,
        0.05226581543684006,
        -0.02923715114593506,
        0.06069272756576538,
        0.012815767899155617,
        -0.030018296092748642,
        -0.012925400398671627,
        -0.04634243622422218,
        0.03142739087343216,
        -0.0017730591353029013,
        -0.06094691902399063,
        0.0421031191945076,
        -0.024891626089811325,
        -0.04652386158704758,
        0.04542063549160957,
        -0.04534068703651428,
        -0.010416374541819096,
        -0.06180252134799957,
        0.026232169941067696,
        -0.01217326708137989,
        0.06279180943965912,
        0.0341893807053566,
        0.05779416859149933,
        -0.05684000253677368,
        -0.03840376436710358,
        0.01128661073744297,
        0.05272223800420761,
        0.03830545023083687,
        -0.021444501355290413,
        -0.055574655532836914,
        -0.04342257231473923,
        0.05975440889596939,
        0.03224224969744682,
        0.0005222757463343441,
        -0.05291001871228218,
        -0.022269180044531822,
        -0.05876172333955765,
        -0.04788801074028015,
        -0.02390352636575699,
        0.006129895802587271,
        0.03861798718571663,
        0.0418301559984684,
        0.048849575221538544,
        -0.05836394056677818,
        0.04189547523856163,
        -0.06419986486434937,
        0.03425097092986107,
        0.0004110363661311567,
        0.03651430457830429,
        -0.036228470504283905,
        0.026964927092194557,
        0.05234061926603317,
        0.03486811742186546,
        0.05869019776582718,
        0.05928126350045204,
        -0.04934849590063095,
        -0.046722251921892166,
        -0.034081894904375076,
        0.02793273515999317,
        -0.0024035570677369833,
        -0.04797561094164848,
        0.04398760199546814,
        0.004881706554442644,
        -0.004325884860008955,
        -0.06528381258249283,
        0.03222311660647392,
        0.06524378061294556
    ],
    [
        0.007222658954560757,
        0.057404953986406326,
        0.044319428503513336,
        0.017689798027276993,
        -0.04619121178984642,
        0.028957702219486237,
        -0.04639311507344246,
        -0.06429304927587509,
        0.0624358095228672,
        -0.004442492965608835,
        0.030452221632003784,
        -0.019899770617485046,
        -0.02099158801138401,
        -0.0069123366847634315,
        -0.05860971659421921,
        0.06509450078010559,
        -0.012229371815919876,
        0.06273871660232544,
        -0.05932992696762085,
        -0.02545989118516445,
        -0.040720053017139435,
        0.05731479078531265,
        -0.025696123018860817,
        -0.005372846033424139,
        0.007279580924659967,
        0.042802322655916214,
        0.049478091299533844,
        -0.046244461089372635,
        0.014912945218384266,
        -0.03160043805837631,
        0.04973879083991051,
        -0.037602297961711884,
        -0.05075013265013695,
        -0.04064328223466873,
        0.05819031968712807,
        0.007040143013000488,
        -0.06288018077611923,
        -0.0337703675031662,
        -0.06382021307945251,
        -0.03714413195848465,
        0.026242999359965324,
        0.013815830461680889,
        0.038010891526937485,
        -0.05897289514541626,
        0.004468055907636881,
        0.05978398397564888,
        0.014477348886430264,
        -0.003199850209057331,
        0.001656938693486154,
        0.051108218729496,
        0.055710673332214355,
        0.06430008262395859,
        -0.04612749069929123,
        0.0280592143535614,
        0.024412302300333977,
        -0.0012377770617604256,
        0.025433320552110672,
        0.027483420446515083,
        -0.006424854043871164,
        -0.027235625311732292,
        -0.054413504898548126,
        0.036704547703266144,
        -0.052803173661231995,
        0.06327084451913834,
        -0.061954207718372345,
        -0.050418831408023834,
        0.02349364198744297,
        0.031053585931658745,
        -0.06378566473722458,
        0.04439828544855118,
        -0.05708296224474907,
        -0.06463591009378433,
        0.0651426687836647,
        0.0405825711786747,
        0.025414209812879562,
        -0.04754946753382683,
        -0.04451762139797211,
        0.06540700048208237,
        -0.03391112759709358,
        0.009825699031352997,
        -0.011186494491994381,
        0.061667345464229584,
        -0.048401132225990295,
        0.0009570707334205508,
        0.04494258761405945,
        0.05158558487892151,
        0.06302526593208313,
        -0.05281459540128708,
        0.055477578192949295,
        0.002428818726912141,
        0.06482088565826416,
        0.05772833526134491,
        -0.0559857040643692,
        0.023446867242455482,
        -0.06103162094950676,
        0.05629342049360275,
        -0.032908711582422256,
        -0.06361290067434311,
        0.052248816937208176,
        -0.02201409824192524,
        -0.0646185353398323,
        -0.008234072476625443,
        0.06092445179820061,
        0.004779849201440811,
        0.04682840779423714,
        -0.058281365782022476,
        -0.05684806406497955,
        0.03801044076681137,
        -0.04078246280550957,
        0.02948826365172863,
        -0.05849190428853035,
        0.0034855783451348543,
        -0.06249330937862396,
        -0.01030217669904232,
        -0.03988233581185341,
        0.014016235247254372,
        0.04973465949296951,
        0.0014832009328529239,
        0.06516938656568527,
        0.047617532312870026,
        -0.0024879530537873507,
        0.06118975952267647,
        0.02004098892211914,
        -0.050413619726896286,
        -0.06320757418870926,
        -0.05852235108613968,
        -0.03800225630402565,
        -0.043353237211704254,
        0.06323719024658203,
        -0.003953150473535061,
        0.008262266404926777,
        -0.06265772879123688,
        -0.06258474290370941,
        -0.0018078944412991405,
        -0.05611352622509003,
        -0.06085098907351494,
        0.029783274978399277,
        0.048676759004592896,
        -0.06408967077732086,
        0.03303365781903267,
        0.06522981077432632,
        0.012757878750562668,
        0.06344429403543472,
        0.014799760654568672,
        0.06401146948337555,
        -0.012608573772013187,
        0.05654274299740791,
        -0.06071031093597412,
        -0.027379730716347694,
        0.06276923418045044,
        0.05414090305566788,
        -0.06507214158773422,
        -0.018399864435195923,
        -0.060524024069309235,
        0.05760343745350838,
        -0.05194135010242462,
        0.01705440692603588,
        0.021626895293593407,
        0.023557135835289955,
        0.012209530919790268,
        0.043636709451675415,
        0.06300350278615952,
        0.05705573037266731,
        0.049640096724033356,
        0.044230278581380844,
        -0.03294917941093445,
        -0.04478266462683678,
        0.03502822667360306,
        0.048155587166547775,
        0.021745607256889343,
        0.00957247894257307,
        0.04264938831329346,
        0.005534308962523937,
        -0.04964834451675415,
        0.013406878337264061,
        0.05916362628340721,
        -0.0633600503206253,
        0.025391243398189545,
        0.06277186423540115,
        -0.027794785797595978,
        0.004615291953086853,
        0.062248021364212036,
        0.0041268207132816315,
        0.03709046170115471,
        0.04469452053308487,
        0.011259096674621105,
        -0.053415264934301376,
        0.06169148534536362,
        -0.061177078634500504,
        -0.00951558817178011,
        -0.023235995322465897,
        -0.011323516257107258,
        0.027300968766212463,
        -0.04657062143087387,
        -0.03333573043346405,
        0.04416347295045853,
        -0.02842588722705841,
        -0.005961137358099222,
        -0.03910403326153755,
        -0.013041866943240166,
        0.0011309217661619186,
        0.0043690819293260574,
        0.01633402891457081,
        0.034299690276384354,
        -0.060639310628175735,
        0.008512372151017189,
        0.04756813123822212,
        0.006312123965471983,
        -0.054881442338228226,
        -0.005596805363893509,
        -0.05041246488690376,
        0.060022544115781784,
        -0.01371383760124445,
        0.03212973102927208,
        -0.02248968370258808,
        0.04909839481115341,
        0.06281277537345886,
        -0.0028277619276195765,
        0.05968344211578369,
        0.0250320415943861,
        0.021232809871435165,
        0.06517740339040756,
        -0.05037006735801697,
        -0.05080009996891022,
        0.05523359775543213,
        -0.0030392096377909184,
        0.009813595563173294,
        0.05762019753456116,
        0.017577743157744408,
        -0.04933440312743187,
        0.005809817463159561,
        -0.046149708330631256,
        -0.04552100598812103,
        0.035705626010894775,
        0.008578062988817692,
        0.06534292548894882,
        0.060832537710666656,
        -0.027018412947654724,
        -0.03253272548317909,
        0.06497746706008911,
        -0.027890067547559738,
        0.06345576047897339,
        -0.06467089802026749,
        0.05839240550994873,
        0.031366731971502304,
        -0.026524288579821587,
        -0.0652373805642128,
        -0.023336073383688927,
        0.011930219829082489,
        -0.056863538920879364,
        -0.03524817153811455,
        -0.0632706731557846,
        0.04555162042379379,
        -0.060593441128730774,
        -0.06016375124454498,
        0.06244330108165741,
        -0.05519202724099159,
        -0.05407274514436722,
        0.030595431104302406,
        -0.026408717036247253,
        0.06511656194925308,
        -0.054049160331487656,
        -0.046189192682504654,
        0.012352663092315197,
        0.019413068890571594,
        0.024809468537569046,
        -0.03619309887290001,
        0.030367422848939896,
        -0.037189509719610214,
        0.03420662507414818,
        0.05473034828901291,
        -0.017498955130577087,
        0.06397069245576859,
        -0.04844251647591591,
        -0.060146402567625046,
        0.05087987333536148,
        -0.044984832406044006,
        0.0033323396928608418,
        -0.04941404610872269,
        -0.04336007311940193,
        -0.025373315438628197,
        -0.06523264944553375,
        -0.06382470577955246,
        0.061269789934158325,
        0.009450153447687626,
        -0.047776732593774796,
        0.012753868475556374,
        0.055642783641815186,
        0.021328693255782127,
        0.05450015142560005,
        0.05111140385270119,
        0.05889664590358734,
        -0.022626962512731552,
        0.06047680601477623,
        0.046399712562561035,
        -0.06347370147705078,
        0.06257499009370804,
        0.05626595392823219,
        -0.06028509512543678,
        0.058885183185338974,
        0.05400939658284187,
        0.04405469447374344,
        -0.034756969660520554,
        0.04950052499771118,
        -0.059552308171987534,
        0.04161057993769646,
        -0.0566939152777195,
        0.018506811931729317,
        -0.03653150051832199,
        -0.06482461839914322,
        0.005414534360170364,
        0.010638204403221607,
        -0.0511578805744648,
        -0.021009305492043495,
        -0.02925054170191288,
        -0.05932255834341049,
        0.023857515305280685,
        -0.051428552716970444,
        0.04679932817816734,
        -0.03664350509643555,
        -0.05989623814821243,
        0.021720923483371735,
        0.008390774019062519,
        0.03272928670048714,
        0.05670673027634621,
        -0.06126382574439049,
        -0.03988756984472275,
        -0.04115329310297966,
        0.0020402148365974426,
        -0.028028316795825958,
        0.00757259875535965,
        -0.042501866817474365,
        -0.04427746310830116,
        -0.05350873991847038,
        0.06105508655309677,
        -0.013346296735107899,
        0.011953839100897312,
        0.06311292201280594,
        -0.04065319150686264,
        0.04053587093949318,
        -0.041085317730903625,
        0.06373316794633865,
        -0.06525672227144241,
        0.005908856634050608,
        0.06436087936162949,
        0.035909898579120636,
        0.024117320775985718,
        0.05670372396707535,
        -0.04419554024934769,
        0.06334046274423599,
        0.04191530868411064,
        0.017294293269515038,
        -0.024899376556277275,
        0.03978794440627098,
        -0.005273362621665001,
        0.05469074845314026,
        -0.055235009640455246,
        -0.04283050447702408,
        0.05626186355948448,
        -0.06078004464507103,
        0.059391457587480545,
        -0.014078141190111637,
        -0.020821720361709595,
        0.009901785291731358,
        0.0555778443813324,
        -0.05240591615438461,
        0.035844746977090836,
        -0.03522685170173645,
        -0.007194803096354008,
        -0.049363959580659866,
        0.012481183744966984,
        -0.000962582475040108,
        0.015837211161851883,
        0.058907970786094666,
        -0.016841955482959747,
        0.03089025616645813,
        -0.05960635095834732,
        0.05980759859085083,
        0.04948366433382034,
        0.04989772289991379,
        0.0387636236846447,
        -0.06319337338209152,
        -0.06416211277246475,
        0.06413110345602036,
        0.06158149987459183,
        -0.010328222066164017,
        -0.05366656929254532,
        -0.06395553052425385,
        -0.05830402672290802,
        -0.04208333417773247,
        -0.059216078370809555,
        -0.04789690673351288,
        -0.038512490689754486,
        -0.03484230488538742,
        -0.052902936935424805,
        0.0613752156496048,
        0.052362293004989624,
        0.06269925832748413,
        0.027748113498091698,
        -0.0578143447637558,
        -0.032857973128557205,
        0.06253471970558167,
        -0.010944562964141369,
        -0.05946798250079155,
        -0.005811410490423441,
        0.03609839454293251,
        -0.00217061466537416,
        -0.03831060603260994,
        0.05409983545541763,
        0.02201840654015541,
        -0.05755262449383736,
        -0.06393732875585556,
        0.049405328929424286,
        -0.0002514038060326129,
        0.06109440699219704,
        -0.038979433476924896,
        -0.024908829480409622,
        0.04814944043755531,
        0.03721769526600838,
        0.051822200417518616,
        -0.04039684310555458,
        -0.052083954215049744,
        0.005330254789441824,
        -0.04133252426981926,
        -0.036238767206668854,
        0.042012326419353485,
        -0.007764068897813559,
        0.004909674637019634,
        0.05404295772314072,
        -0.05674044415354729,
        0.05745020881295204,
        -0.022036321461200714,
        -0.062151599675416946,
        0.031170319765806198,
        0.0503031462430954,
        -0.052176207304000854,
        -0.06445584446191788,
        0.04058248922228813,
        -0.029497403651475906,
        0.007225790526717901,
        0.04942445456981659,
        0.04937775433063507,
        0.058222558349370956,
        0.053773071616888046,
        0.06223229318857193,
        0.022828541696071625,
        0.05226581543684006,
        -0.02923715114593506,
        0.06069272756576538,
        0.012815767899155617,
        -0.030018296092748642,
        -0.012925400398671627,
        -0.04634243622422218,
        0.03142739087343216,
        -0.0017730591353029013,
        -0.06094691902399063,
        0.0421031191945076,
        -0.024891626089811325,
        -0.04652386158704758,
        0.04542063549160957,
        -0.04534068703651428,
        -0.010416374541819096,
        -0.06180252134799957,
        0.026232169941067696,
        -0.01217326708137989,
        0.06279180943965912,
        0.0341893807053566,
        0.05779416859149933,
        -0.05684000253677368,
        -0.03840376436710358,
        0.01128661073744297,
        0.05272223800420761,
        0.03830545023083687,
        -0.021444501355290413,
        -0.055574655532836914,
        -0.04342257231473923,
        0.05975440889596939,
        0.03224224969744682,
        0.0005222757463343441,
        -0.05291001871228218,
        -0.022269180044531822,
        -0.05876172333955765,
        -0.04788801074028015,
        -0.02390352636575699,
        0.006129895802587271,
        0.03861798718571663,
        0.0418301559984684,
        0.048849575221538544,
        -0.05836394056677818,
        0.04189547523856163,
        -0.06419986486434937,
        0.03425097092986107,
        0.0004110363661311567,
        0.03651430457830429,
        -0.036228470504283905,
        0.026964927092194557,
        0.05234061926603317,
        0.03486811742186546,
        0.05869019776582718,
        0.05928126350045204,
        -0.04934849590063095,
        -0.046722251921892166,
        -0.034081894904375076,
        0.02793273515999317,
        -0.0024035570677369833,
        -0.04797561094164848,
        0.04398760199546814,
        0.004881706554442644,
        -0.004325884860008955,
        -0.06528381258249283,
        0.03222311660647392,
        0.06524378061294556
    ],
    [
        -0.009582324884831905,
        0.042413849383592606,
        0.045163754373788834,
        0.026253875344991684,
        -0.04584339261054993,
        0.007039128802716732,
        -0.04421399533748627,
        -0.061814527958631516,
        0.061271559447050095,
        0.018412526696920395,
        0.03063121624290943,
        0.0022878500167280436,
        -0.02306426502764225,
        -0.001300426316447556,
        -0.05719497799873352,
        0.06252814084291458,
        -0.012923630885779858,
        0.06134604290127754,
        -0.05906446650624275,
        -0.04759375751018524,
        -0.016158392652869225,
        0.05972104147076607,
        0.005458603613078594,
        -0.008749296888709068,
        0.02178679220378399,
        0.05135589465498924,
        0.05506708472967148,
        -0.05161161348223686,
        0.02623530477285385,
        -0.008147720247507095,
        0.03999004885554314,
        -0.02446608617901802,
        -0.054424140602350235,
        -0.05585060268640518,
        0.05687907710671425,
        0.0027286435943096876,
        -0.06116361916065216,
        -0.04343065619468689,
        -0.061689745634794235,
        -0.04913242161273956,
        0.047050271183252335,
        0.023208677768707275,
        0.021855808794498444,
        -0.05080651491880417,
        -0.006143984384834766,
        0.0605921670794487,
        0.004534851759672165,
        0.003562547732144594,
        0.04674379155039787,
        0.056603025645017624,
        0.0608295202255249,
        0.062348417937755585,
        -0.05316030979156494,
        0.021109670400619507,
        0.030697904527187347,
        -0.02989090606570244,
        -0.005093579646199942,
        0.028008295223116875,
        0.008957789279520512,
        -0.027308110147714615,
        -0.0517105795443058,
        0.04217583313584328,
        -0.051425810903310776,
        0.062161885201931,
        -0.05713760852813721,
        -0.051215868443250656,
        0.015049751847982407,
        0.0017614106182008982,
        -0.06155623123049736,
        0.046321261674165726,
        -0.05794258043169975,
        -0.06240401417016983,
        0.06243942677974701,
        0.02641976810991764,
        0.04835868626832962,
        -0.040404338389635086,
        -0.023238010704517365,
        0.06255801022052765,
        -0.034750934690237045,
        -0.009045891463756561,
        -0.004299139603972435,
        0.05968359857797623,
        -0.04903233051300049,
        0.02955445647239685,
        0.05241653323173523,
        0.05468079820275307,
        0.061408575624227524,
        -0.05023904889822006,
        0.05758724361658096,
        -0.0028072348795831203,
        0.0583915188908577,
        0.05622929707169533,
        -0.060152798891067505,
        0.022084107622504234,
        -0.05628162994980812,
        0.05668271332979202,
        -0.01953713409602642,
        -0.06174963340163231,
        0.04907635599374771,
        -0.02289901115000248,
        -0.061590541154146194,
        0.02718116156756878,
        0.05971774086356163,
        0.01623501442372799,
        0.04551557078957558,
        -0.060100339353084564,
        -0.04632953926920891,
        0.05074234679341316,
        -0.04758632555603981,
        0.04847242683172226,
        -0.057838160544633865,
        0.014336245134472847,
        -0.0602722093462944,
        0.002964882180094719,
        -0.0514296293258667,
        0.01633874699473381,
        0.05686432495713234,
        -0.01920500211417675,
        0.06265021115541458,
        0.05183475464582443,
        -0.004163100849837065,
        0.06078372150659561,
        0.036025166511535645,
        -0.045904017984867096,
        -0.057974085211753845,
        -0.05192888155579567,
        -0.0473930761218071,
        -0.05467742681503296,
        0.06049901619553566,
        0.019187267869710922,
        0.024569155648350716,
        -0.05829983949661255,
        -0.057792551815509796,
        -0.002662500599399209,
        -0.05539046600461006,
        -0.058391932398080826,
        0.04627915099263191,
        0.05584133416414261,
        -0.061351750046014786,
        0.03959488496184349,
        0.062434010207653046,
        0.008292218670248985,
        0.059737831354141235,
        0.016801776364445686,
        0.0615314356982708,
        -0.02048659510910511,
        0.05932058021426201,
        -0.05714840814471245,
        -0.010115701705217361,
        0.06127021834254265,
        0.05865347012877464,
        -0.0623474083840847,
        -0.0017454246990382671,
        -0.05635326728224754,
        0.059339720755815506,
        -0.05471716448664665,
        -0.012340232729911804,
        0.023607585579156876,
        0.008429291658103466,
        0.03208162263035774,
        0.05546253174543381,
        0.05681557208299637,
        0.0527103990316391,
        0.04789231717586517,
        0.02892833575606346,
        -0.028158118948340416,
        -0.03790143132209778,
        0.05328051373362541,
        0.039542365819215775,
        0.02624763920903206,
        -0.005374396685510874,
        0.04464185982942581,
        -0.00678889499977231,
        -0.05169794335961342,
        0.016813823953270912,
        0.06052962318062782,
        -0.061385881155729294,
        0.031893715262413025,
        0.059009164571762085,
        -0.0488918237388134,
        -0.00923266913741827,
        0.06232772767543793,
        -0.0036323177628219128,
        0.03466038033366203,
        0.04532899335026741,
        0.019388383254408836,
        -0.05606451258063316,
        0.05904102325439453,
        -0.05977305397391319,
        -0.03911728039383888,
        -0.027695458382368088,
        0.013079824857413769,
        0.01216182392090559,
        -0.024329466745257378,
        -0.04426638409495354,
        0.034533459693193436,
        -0.036265887320041656,
        -0.014499069191515446,
        -0.04964505136013031,
        -0.0013342341408133507,
        0.02246132679283619,
        -0.009462665766477585,
        0.009245404973626137,
        0.035160891711711884,
        -0.05696553736925125,
        0.030318962410092354,
        0.04873678833246231,
        0.02791912667453289,
        -0.05626875162124634,
        0.0252066683024168,
        -0.05293891206383705,
        0.05484333261847496,
        -0.017820224165916443,
        0.025898180902004242,
        -0.02908131666481495,
        0.05004815384745598,
        0.06001695618033409,
        -0.02489326521754265,
        0.060740526765584946,
        0.023366790264844894,
        -0.0012893549865111709,
        0.062356386333703995,
        -0.05203377082943916,
        -0.04066471755504608,
        0.05933590605854988,
        -0.013202458620071411,
        0.021815959364175797,
        0.050707653164863586,
        0.029505504295229912,
        -0.05421217158436775,
        0.034185729920864105,
        -0.05627937614917755,
        -0.042838241904973984,
        0.03514494374394417,
        -0.017389237880706787,
        0.06266051530838013,
        0.060345232486724854,
        -0.021639833226799965,
        -0.03310728818178177,
        0.06210152432322502,
        -0.028881389647722244,
        0.06187231093645096,
        -0.06248230114579201,
        0.05982758104801178,
        0.04500052332878113,
        -0.01822810061275959,
        -0.06232849508523941,
        0.018702015280723572,
        0.004625281784683466,
        -0.05403386056423187,
        -0.0468183234333992,
        -0.0609370656311512,
        0.04500559717416763,
        -0.05987484008073807,
        -0.058424510061740875,
        0.057055335491895676,
        -0.05761316791176796,
        -0.056198351085186005,
        0.02143213152885437,
        -0.02171613648533821,
        0.06242246553301811,
        -0.05417369306087494,
        -0.04150695726275444,
        0.015013158321380615,
        0.008843092247843742,
        0.029253946617245674,
        -0.00951477326452732,
        0.02513555996119976,
        -0.04581746831536293,
        0.03211480751633644,
        0.04744098335504532,
        -0.04064968600869179,
        0.06238410621881485,
        -0.04708581045269966,
        -0.05934084579348564,
        0.03684452921152115,
        -0.045284949243068695,
        0.004432155285030603,
        -0.055351849645376205,
        -0.04023812711238861,
        -0.04767753183841705,
        -0.06224343553185463,
        -0.06224970147013664,
        0.06131798028945923,
        0.036794599145650864,
        -0.05192004516720772,
        0.008287385106086731,
        0.056454598903656006,
        0.04700566828250885,
        0.04441748559474945,
        0.035486359149217606,
        0.054269954562187195,
        -0.038398198783397675,
        0.05440805107355118,
        0.05131338909268379,
        -0.06132130324840546,
        0.05911557003855705,
        0.06011359766125679,
        -0.058445487171411514,
        0.05783342570066452,
        0.05084715783596039,
        0.03722687438130379,
        -0.03177311643958092,
        0.05513454228639603,
        -0.054177023470401764,
        0.040679559111595154,
        -0.054999612271785736,
        0.021728618070483208,
        -0.03140074759721756,
        -0.062309570610523224,
        0.01244360487908125,
        -0.00668657012283802,
        -0.04710705578327179,
        -0.0009042421588674188,
        -0.019386056810617447,
        -0.05785859376192093,
        0.021574459969997406,
        -0.055792901664972305,
        0.04662376269698143,
        -0.04039546474814415,
        -0.055447306483983994,
        0.012585228309035301,
        0.006149535533040762,
        0.04320383071899414,
        0.05686216056346893,
        -0.05970543622970581,
        -0.035476844757795334,
        -0.0360780730843544,
        0.0022326167672872543,
        -0.030274752527475357,
        0.01918902061879635,
        -0.04123348742723465,
        -0.04410354793071747,
        -0.049567509442567825,
        0.05761414021253586,
        0.015314497984945774,
        -0.018011197447776794,
        0.060629960149526596,
        -0.03639725223183632,
        0.03934944421052933,
        -0.04274522885680199,
        0.06108125299215317,
        -0.062368087470531464,
        -0.025814048945903778,
        0.06125129759311676,
        0.023037157952785492,
        0.029651256278157234,
        0.056192558258771896,
        -0.03921240568161011,
        0.06001332402229309,
        0.051046229898929596,
        0.028501903638243675,
        -0.03176172822713852,
        -0.01282409392297268,
        -0.0005748497205786407,
        0.052202578634023666,
        -0.05297209694981575,
        -0.045403871685266495,
        0.058503877371549606,
        -0.061287421733140945,
        0.05533774569630623,
        -0.017091263085603714,
        0.019162334501743317,
        0.019214287400245667,
        0.046853262931108475,
        -0.054241329431533813,
        0.05711191147565842,
        -0.03232771158218384,
        0.027426982298493385,
        -0.05290573462843895,
        -0.006869540084153414,
        0.0050278897397220135,
        0.0462840236723423,
        0.05475077033042908,
        0.010019835084676743,
        0.02234543487429619,
        -0.059938233345746994,
        0.05975997820496559,
        0.05723685026168823,
        0.051543183624744415,
        0.0274964589625597,
        -0.06066850572824478,
        -0.06148793175816536,
        0.061174310743808746,
        0.0580018050968647,
        -0.014234834350645542,
        -0.04587299004197121,
        -0.060859523713588715,
        -0.048996735364198685,
        -0.030968813225626945,
        -0.061865661293268204,
        -0.04982069134712219,
        -0.038192275911569595,
        -0.04146087169647217,
        -0.04044608026742935,
        0.05933328717947006,
        0.05634421482682228,
        0.05804869905114174,
        0.04048383608460426,
        -0.06002506613731384,
        -0.034756388515233994,
        0.06097646802663803,
        -0.007361622527241707,
        -0.0572439469397068,
        -0.015679964795708656,
        0.023966511711478233,
        0.01009633019566536,
        -0.048019248992204666,
        0.04881589487195015,
        0.009694061242043972,
        -0.05517725646495819,
        -0.06096981465816498,
        0.05753093212842941,
        0.03315688297152519,
        0.05735228955745697,
        -0.04163021966814995,
        -0.03488781303167343,
        0.05326049029827118,
        0.03106692060828209,
        0.05253944173455238,
        -0.00469491071999073,
        -0.03964710235595703,
        0.03117007575929165,
        -0.05065520480275154,
        0.013369262218475342,
        0.0222433190792799,
        -0.0293369572609663,
        0.03075561299920082,
        0.04501524195075035,
        -0.056024301797151566,
        0.06025977432727814,
        -0.01944132149219513,
        -0.06194761395454407,
        0.01174288522452116,
        0.037670161575078964,
        -0.05764840170741081,
        -0.0609966404736042,
        0.02428077720105648,
        -0.007906637154519558,
        0.02097454108297825,
        0.043548066169023514,
        0.05372026562690735,
        0.056854695081710815,
        0.05665907263755798,
        0.061536066234111786,
        0.015472115948796272,
        0.053926967084407806,
        -0.045812953263521194,
        0.05937986448407173,
        0.028994077816605568,
        -0.04171770066022873,
        -0.0021330988965928555,
        -0.01613617315888405,
        0.041006509214639664,
        -0.008092700503766537,
        -0.05953380838036537,
        0.027772221714258194,
        -0.03212868049740791,
        -0.044815126806497574,
        0.05452226474881172,
        -0.0499601885676384,
        -0.029945630580186844,
        -0.060298290103673935,
        0.03888155147433281,
        0.02410886064171791,
        0.061381757259368896,
        0.024572091177105904,
        0.05767076835036278,
        -0.060877036303281784,
        -0.04405299201607704,
        0.03076794557273388,
        0.061109453439712524,
        0.03834977000951767,
        -0.0018359700916334987,
        -0.06036939099431038,
        -0.029238346964120865,
        0.05144938826560974,
        0.03244255483150482,
        -0.003312637098133564,
        -0.053073424845933914,
        -0.027140561491250992,
        -0.05922454595565796,
        -0.05552621930837631,
        -0.008531816303730011,
        -0.015486647374927998,
        0.041261907666921616,
        0.047500450164079666,
        0.04936560243368149,
        -0.052201565355062485,
        0.030385907739400864,
        -0.06196761131286621,
        0.04130571335554123,
        0.025050843134522438,
        0.0333285927772522,
        -0.02338106371462345,
        0.02727792039513588,
        0.04259530082345009,
        0.05286968871951103,
        0.056575924158096313,
        0.06062918156385422,
        -0.04149269312620163,
        -0.04051034897565842,
        -0.04760826751589775,
        0.01486055925488472,
        0.003474639495834708,
        -0.04959378391504288,
        0.051788829267024994,
        -0.009653111919760704,
        0.015382027253508568,
        -0.06264109164476395,
        0.025071240961551666,
        0.06240767240524292
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.0033379020169377327,
        0.04504193738102913,
        0.04449329897761345,
        0.031211422756314278,
        -0.03883262351155281,
        -0.021218502894043922,
        -0.03864474594593048,
        -0.06160144880414009,
        0.06084733456373215,
        0.012878410518169403,
        0.02034289389848709,
        0.01625124178826809,
        -0.02925199829041958,
        -0.019830740988254547,
        -0.05731905624270439,
        0.06263908743858337,
        -0.00033856407389976084,
        0.05935334041714668,
        -0.0606059730052948,
        -0.04813195392489433,
        -0.014610249549150467,
        0.0572391003370285,
        -0.002871026052162051,
        -0.009557589888572693,
        0.02068200521171093,
        0.053120654076337814,
        0.05145930126309395,
        -0.051368605345487595,
        0.028506344184279442,
        0.00047253977390937507,
        0.04019846022129059,
        -0.02693048305809498,
        -0.054880980402231216,
        -0.05754362791776657,
        0.05520843714475632,
        0.005543175619095564,
        -0.06057194620370865,
        -0.030062781646847725,
        -0.06109608709812164,
        -0.04742847755551338,
        0.04982028156518936,
        0.019162189215421677,
        0.01592409797012806,
        -0.05669647827744484,
        0.0065799253061413765,
        0.06082106754183769,
        0.021518012508749962,
        0.010639553889632225,
        0.03221455216407776,
        0.04996377229690552,
        0.060601234436035156,
        0.06225738674402237,
        -0.05837533250451088,
        0.025621866807341576,
        -0.019886193796992302,
        -0.027608608826994896,
        0.015013502910733223,
        0.003490084782242775,
        0.017867015674710274,
        -0.033489760011434555,
        -0.055221639573574066,
        0.03742964565753937,
        -0.052990980446338654,
        0.06206712871789932,
        -0.05800943449139595,
        -0.04107766970992088,
        0.020981451496481895,
        0.0158076249063015,
        -0.061103638261556625,
        0.038134098052978516,
        -0.060531772673130035,
        -0.06255842000246048,
        0.06221788004040718,
        0.01193705853074789,
        0.05126861855387688,
        -0.04765841364860535,
        -0.01068126130849123,
        0.06260721385478973,
        -0.047842152416706085,
        -0.006935471203178167,
        -0.00921210553497076,
        0.05960093066096306,
        -0.0440727099776268,
        0.03426305577158928,
        0.058211587369441986,
        0.05150661990046501,
        0.06133890524506569,
        -0.052749812602996826,
        0.05540626496076584,
        -0.009283237159252167,
        0.059471968561410904,
        0.05814918875694275,
        -0.06008080393075943,
        0.010423162020742893,
        -0.057033102959394455,
        0.057514041662216187,
        0.001733283745124936,
        -0.06145166605710983,
        0.04596060886979103,
        -0.039167389273643494,
        -0.06171877309679985,
        0.02858094684779644,
        0.06014614552259445,
        0.016814036294817924,
        0.04574645310640335,
        -0.05935954302549362,
        -0.05114272981882095,
        0.05538134649395943,
        -0.046593692153692245,
        0.034189727157354355,
        -0.057593923062086105,
        0.032096315175294876,
        -0.05903974920511246,
        0.009343480691313744,
        -0.029340500012040138,
        0.013439287431538105,
        0.05881732329726219,
        -0.02061040699481964,
        0.06273899227380753,
        0.05072994902729988,
        0.015728270635008812,
        0.06090755760669708,
        0.02438608556985855,
        -0.05429574474692345,
        -0.05837691202759743,
        -0.05418368801474571,
        -0.04178807511925697,
        -0.05450183525681496,
        0.06071791052818298,
        0.025887923315167427,
        0.0404830165207386,
        -0.05821127071976662,
        -0.06114445999264717,
        0.009286154061555862,
        -0.056487563997507095,
        -0.05776165798306465,
        0.04637301340699196,
        0.055706560611724854,
        -0.061848122626543045,
        0.0349721796810627,
        0.062454745173454285,
        -0.007144357077777386,
        0.06018855422735214,
        0.005562979727983475,
        0.061591602861881256,
        -0.030447592958807945,
        0.06001898646354675,
        -0.05789713189005852,
        0.012422305531799793,
        0.06152873858809471,
        0.053277019411325455,
        -0.0625099316239357,
        0.005737789440900087,
        -0.05558730289340019,
        0.05221092328429222,
        -0.054641902446746826,
        0.005860146135091782,
        0.024002639576792717,
        0.012768975459039211,
        0.02382070943713188,
        0.04989980161190033,
        0.056672174483537674,
        0.053805410861968994,
        0.047031302005052567,
        0.012017026543617249,
        -0.017312640324234962,
        -0.02421623282134533,
        0.05476044490933418,
        0.0333433523774147,
        0.03059515915811062,
        -0.012925948947668076,
        0.04173000156879425,
        0.008321275003254414,
        -0.05797296017408371,
        0.02509760670363903,
        0.06071225926280022,
        -0.062001783400774,
        0.036307428032159805,
        0.0592556856572628,
        -0.05003473162651062,
        0.002991714049130678,
        0.061973936855793,
        0.01713358238339424,
        0.03999107703566551,
        0.03924623131752014,
        0.02328420616686344,
        -0.059642691165208817,
        0.05809428542852402,
        -0.05820898711681366,
        -0.02378520369529724,
        -0.026969587430357933,
        0.026504091918468475,
        0.020895158872008324,
        -0.019917216151952744,
        -0.03297388553619385,
        0.034352730959653854,
        -0.037235189229249954,
        -0.014947018586099148,
        -0.0523771271109581,
        -0.0041168793104588985,
        0.001538734300993383,
        0.00022096902830526233,
        0.012886619195342064,
        0.031081359833478928,
        -0.059836529195308685,
        0.04862689971923828,
        0.03996214270591736,
        0.03001222014427185,
        -0.05338849127292633,
        0.005163867026567459,
        -0.04985000193119049,
        0.05464521795511246,
        -0.007086779922246933,
        0.02678830549120903,
        -0.01671241782605648,
        0.02017715759575367,
        0.05906980484724045,
        -0.03004170022904873,
        0.061694420874118805,
        0.002123662969097495,
        0.005272568669170141,
        0.06237022206187248,
        -0.04710241034626961,
        -0.04175843670964241,
        0.05958885699510574,
        0.006773124448955059,
        0.02246866188943386,
        0.05734504759311676,
        0.021806752309203148,
        -0.05312110111117363,
        0.030507409945130348,
        -0.05824916437268257,
        -0.05161665380001068,
        0.029970858246088028,
        -0.04133269563317299,
        0.0627044141292572,
        0.059488795697689056,
        -0.023036539554595947,
        -0.052526552230119705,
        0.0614316500723362,
        -0.020640108734369278,
        0.06215333938598633,
        -0.06263575702905655,
        0.060419902205467224,
        0.047717951238155365,
        -0.009862835519015789,
        -0.0625448226928711,
        0.021514026448130608,
        -0.008286133408546448,
        -0.05460018292069435,
        -0.052562735974788666,
        -0.059833019971847534,
        0.04592851921916008,
        -0.05959059298038483,
        -0.05991560220718384,
        0.058203428983688354,
        -0.055542465299367905,
        -0.05265793576836586,
        0.02538296766579151,
        -0.0393633171916008,
        0.06259867548942566,
        -0.05040185898542404,
        -0.04466472566127777,
        0.01718146726489067,
        0.016412122175097466,
        0.0233452208340168,
        -0.047750603407621384,
        0.030461417511105537,
        -0.045758139342069626,
        0.03120936080813408,
        0.04488126561045647,
        -0.04287232086062431,
        0.062364500015974045,
        -0.04903808608651161,
        -0.05982289835810661,
        0.03536092862486839,
        -0.044038381427526474,
        0.01937982067465782,
        -0.06022344157099724,
        -0.03990595042705536,
        -0.04877478629350662,
        -0.061675138771533966,
        -0.0616920068860054,
        0.06143295392394066,
        0.04763651266694069,
        -0.053559042513370514,
        -0.0017070913454517722,
        0.058434803038835526,
        0.0427248477935791,
        0.048121340572834015,
        0.039914753288030624,
        0.05358225479722023,
        -0.031545259058475494,
        0.05392587184906006,
        0.05437658727169037,
        -0.06102423369884491,
        0.05781428515911102,
        0.05659317597746849,
        -0.06152736023068428,
        0.05769312381744385,
        0.055705174803733826,
        0.04528365284204483,
        -0.024978777393698692,
        0.052725475281476974,
        -0.04029811546206474,
        0.05313052609562874,
        -0.05209798365831375,
        0.03508259356021881,
        -0.029109962284564972,
        -0.06204487010836601,
        0.026726534590125084,
        -0.017469018697738647,
        -0.0412382110953331,
        0.00111301033757627,
        -0.011780713684856892,
        -0.057280078530311584,
        0.028797699138522148,
        -0.058111488819122314,
        0.04201890155673027,
        -0.03814404085278511,
        -0.05528884753584862,
        0.016631247475743294,
        0.038647253066301346,
        0.04573962464928627,
        0.053664784878492355,
        -0.05811479315161705,
        -0.013565384782850742,
        -0.035624343901872635,
        0.0020307544618844986,
        -0.03774204105138779,
        0.026982774958014488,
        -0.03910021483898163,
        -0.045894868671894073,
        -0.04995489493012428,
        0.059477921575307846,
        0.027117663994431496,
        -0.004664460197091103,
        0.05869511514902115,
        -0.02798338420689106,
        0.03238594904541969,
        -0.03593949228525162,
        0.060537274926900864,
        -0.06252621114253998,
        -0.032965436577796936,
        0.06103893369436264,
        0.019827721640467644,
        0.02921522781252861,
        0.056031472980976105,
        -0.04643423855304718,
        0.059134483337402344,
        0.0544021911919117,
        0.02997826598584652,
        -0.030685771256685257,
        -0.008459468372166157,
        -0.03044111840426922,
        0.05121120065450668,
        -0.057898566126823425,
        -0.05230364203453064,
        0.057622455060482025,
        -0.06076056882739067,
        0.054749540984630585,
        -0.034136928617954254,
        0.015123597346246243,
        0.013693947345018387,
        0.052820928394794464,
        -0.05366228520870209,
        0.0544934906065464,
        -0.03305358439683914,
        0.04521588608622551,
        -0.04974871873855591,
        -0.0013980072690173984,
        0.01703878864645958,
        0.047017741948366165,
        0.052895449101924896,
        0.019794205203652382,
        0.032896365970373154,
        -0.05756135284900665,
        0.0554625503718853,
        0.05890815332531929,
        0.049931589514017105,
        0.04151234030723572,
        -0.06138850003480911,
        -0.06160922721028328,
        0.06114548072218895,
        0.05994317680597305,
        -0.01959887333214283,
        -0.04572005942463875,
        -0.06143400818109512,
        -0.04551338031888008,
        -0.046742718666791916,
        -0.061781108379364014,
        -0.03942042216658592,
        -0.02677920088171959,
        -0.029071390628814697,
        -0.035431038588285446,
        0.05907821282744408,
        0.059666454792022705,
        0.05746360123157501,
        0.020328430458903313,
        -0.05963277816772461,
        -0.03609854727983475,
        0.06126368045806885,
        -0.024802733212709427,
        -0.05697844922542572,
        -0.014107320457696915,
        0.030894674360752106,
        0.01271219365298748,
        -0.046176739037036896,
        0.05039943754673004,
        0.004947195760905743,
        -0.05554382875561714,
        -0.06147955358028412,
        0.05063686892390251,
        0.022426903247833252,
        0.056365545839071274,
        -0.04639512300491333,
        -0.0017257410800084472,
        0.05245853587985039,
        0.021410342305898666,
        0.05298130214214325,
        0.000016619427697150968,
        -0.052259597927331924,
        0.03265121206641197,
        -0.05209421366453171,
        0.004632357973605394,
        0.04417254403233528,
        -0.03189728409051895,
        0.014107831753790379,
        0.049478109925985336,
        -0.056832436472177505,
        0.05879012867808342,
        -0.0336616225540638,
        -0.06209573522210121,
        0.016965433955192566,
        0.020107386633753777,
        -0.057179417461156845,
        -0.05957888811826706,
        0.012349748983979225,
        -0.007041971664875746,
        0.024950843304395676,
        0.028955379500985146,
        0.0572926290333271,
        0.05416133999824524,
        0.05381927266716957,
        0.061186615377664566,
        0.01960531435906887,
        0.05642535537481308,
        -0.042581576853990555,
        0.05851457267999649,
        0.03451472893357277,
        -0.04724062234163284,
        0.013754429295659065,
        -0.01964598335325718,
        0.036689192056655884,
        0.012012111023068428,
        -0.05821341648697853,
        0.035319238901138306,
        -0.020110489800572395,
        -0.04733284190297127,
        0.05864808335900307,
        -0.038926661014556885,
        -0.029316460713744164,
        -0.059067174792289734,
        0.01944979652762413,
        0.03245745226740837,
        0.06119269132614136,
        0.0008178903954103589,
        0.05896977335214615,
        -0.05986199900507927,
        -0.04720215126872063,
        0.03444928675889969,
        0.06037158891558647,
        0.0427064374089241,
        -0.008347518742084503,
        -0.06144842505455017,
        -0.025272700935602188,
        0.05465912073850632,
        0.02487899176776409,
        -0.03229350224137306,
        -0.048432815819978714,
        -0.023048164322972298,
        -0.05825512856245041,
        -0.05548162758350372,
        -0.0007923438097350299,
        -0.010887141339480877,
        0.04515111446380615,
        0.030366068705916405,
        0.04514813795685768,
        -0.05437421426177025,
        0.02886812388896942,
        -0.061198122799396515,
        0.044732145965099335,
        0.0012623026268556714,
        0.041116517037153244,
        -0.03027200885117054,
        0.02406005561351776,
        0.028503626585006714,
        0.044539403170347214,
        0.054227374494075775,
        0.06043700501322746,
        -0.04561862722039223,
        -0.04641154780983925,
        -0.047732941806316376,
        0.010558693669736385,
        -0.0016406748909503222,
        -0.05270865559577942,
        0.047921620309352875,
        0.00029207245097495615,
        0.0029988037422299385,
        -0.06260785460472107,
        0.020610161125659943,
        0.06259094178676605
    ],
    [
        0.002780728507786989,
        0.038156017661094666,
        0.04630409553647041,
        0.03213273361325264,
        -0.02951650694012642,
        -0.014926948584616184,
        -0.04438262805342674,
        -0.0607658289372921,
        0.059285979717969894,
        0.009665186516940594,
        0.01851959154009819,
        0.026706762611865997,
        -0.03368813544511795,
        -0.026948153972625732,
        -0.0574173666536808,
        0.06221597269177437,
        0.0023954021744430065,
        0.059612780809402466,
        -0.05680689215660095,
        -0.04946650564670563,
        0.006436736788600683,
        0.05772625282406807,
        -0.009363256394863129,
        -0.014857259579002857,
        0.011441497132182121,
        0.04510136693716049,
        0.05253788083791733,
        -0.05515177547931671,
        0.024799713864922523,
        0.0012544693890959024,
        0.028940394520759583,
        -0.04509532451629639,
        -0.04609984904527664,
        -0.056775618344545364,
        0.05510644614696503,
        -0.01330068614333868,
        -0.0605260394513607,
        -0.046967487782239914,
        -0.055719561874866486,
        -0.04951544851064682,
        0.049592308700084686,
        0.011130629107356071,
        0.023637818172574043,
        -0.057194892317056656,
        0.006137049291282892,
        0.06075822561979294,
        0.032316554337739944,
        -0.0038551767356693745,
        0.034594543278217316,
        0.05387191101908684,
        0.060047488659620285,
        0.06172805652022362,
        -0.05237528309226036,
        0.011315316893160343,
        -0.0021417366806417704,
        -0.024833088740706444,
        0.02762284316122532,
        0.00930832326412201,
        0.005930689163506031,
        -0.03540988638997078,
        -0.05118201673030853,
        0.04784845560789108,
        -0.05340957269072533,
        0.06175795570015907,
        -0.05672340840101242,
        -0.042782261967659,
        0.025409912690520287,
        0.008892282843589783,
        -0.06035194173455238,
        0.04198902100324631,
        -0.0586569644510746,
        -0.062163036316633224,
        0.0618642121553421,
        0.016498003154993057,
        0.050983041524887085,
        -0.04948584735393524,
        -0.028403107076883316,
        0.06216232851147652,
        -0.045215826481580734,
        -0.011820110492408276,
        0.00978885032236576,
        0.0598626472055912,
        -0.042835041880607605,
        0.043261442333459854,
        0.05579517409205437,
        0.052595507353544235,
        0.06142502278089523,
        -0.051247771829366684,
        0.04800019413232803,
        0.008059023879468441,
        0.05712876468896866,
        0.05401961877942085,
        -0.06018245220184326,
        0.0041322712786495686,
        -0.05714906007051468,
        0.057640936225652695,
        -0.00270089041441679,
        -0.061319638043642044,
        0.0474480502307415,
        -0.034439537674188614,
        -0.06144929677248001,
        0.03448478505015373,
        0.05957333371043205,
        0.013159606605768204,
        0.0472264401614666,
        -0.059268124401569366,
        -0.05262024328112602,
        0.05229589343070984,
        -0.04929742589592934,
        0.035895440727472305,
        -0.056648850440979004,
        -0.019777262583374977,
        -0.058811549097299576,
        -0.004262590780854225,
        -0.053923849016427994,
        0.026493513956665993,
        0.05840691551566124,
        -0.005697811488062143,
        0.06232801452279091,
        0.04676554352045059,
        0.008179180324077606,
        0.06062263622879982,
        0.028392715379595757,
        -0.05066977068781853,
        -0.05964431166648865,
        -0.056395482271909714,
        -0.03449904918670654,
        -0.057091306895017624,
        0.05997906252741814,
        0.022923558950424194,
        0.012120392173528671,
        -0.05774659663438797,
        -0.06064640358090401,
        0.02493933215737343,
        -0.05748158320784569,
        -0.05677158758044243,
        0.042472101747989655,
        0.05881623923778534,
        -0.06177029386162758,
        0.03792719170451164,
        0.0617230162024498,
        0.005429667420685291,
        0.05981883034110069,
        0.012432878836989403,
        0.06086525693535805,
        -0.0034183815587311983,
        0.058142997324466705,
        -0.05828399956226349,
        0.014919910579919815,
        0.06137188524007797,
        0.05794389173388481,
        -0.06214664876461029,
        -0.021507268771529198,
        -0.055347930639982224,
        0.057199396193027496,
        -0.05250466614961624,
        -0.012830672785639763,
        0.025478480383753777,
        0.006036019884049892,
        0.009640421718358994,
        0.05537043511867523,
        0.05716610327363014,
        0.05192992836236954,
        0.05024141073226929,
        0.02253877744078636,
        -0.007235649973154068,
        -0.03497222438454628,
        0.0522182360291481,
        0.03951910883188248,
        0.025277402251958847,
        0.011277113109827042,
        0.04195917025208473,
        -0.011318819597363472,
        -0.0569220669567585,
        0.011329098604619503,
        0.05987081676721573,
        -0.06160765513777733,
        0.03152088820934296,
        0.0580085925757885,
        -0.04533293470740318,
        0.0037908577360212803,
        0.062063101679086685,
        0.028552988544106483,
        0.03126997500658035,
        0.045889582484960556,
        0.02625315822660923,
        -0.058274444192647934,
        0.05824046954512596,
        -0.058096256107091904,
        -0.03366588428616524,
        -0.03937927633523941,
        0.01909121312201023,
        0.03210197016596794,
        -0.0320085771381855,
        -0.03437366709113121,
        0.037364933639764786,
        -0.04137176647782326,
        0.0008788282866589725,
        -0.05081390589475632,
        -0.022480543702840805,
        0.008102119900286198,
        -0.020568273961544037,
        0.006019900552928448,
        0.026400895789265633,
        -0.057499222457408905,
        0.048361510038375854,
        0.041789278388023376,
        0.03785959631204605,
        -0.05558142438530922,
        0.047286879271268845,
        -0.053083065897226334,
        0.053797874599695206,
        0.007643030025064945,
        0.033942434936761856,
        -0.030394360423088074,
        0.04626442492008209,
        0.056695710867643356,
        -0.030977915972471237,
        0.06080390512943268,
        0.008352844975888729,
        -0.002465254859998822,
        0.06193298101425171,
        -0.05049481987953186,
        -0.044616036117076874,
        0.05898610129952431,
        -0.0016087497351691127,
        0.020409394055604935,
        0.03632471337914467,
        0.02952941134572029,
        -0.056077904999256134,
        0.04150272533297539,
        -0.057816244661808014,
        -0.05139778554439545,
        0.032151542603969574,
        -0.032064154744148254,
        0.06229430437088013,
        0.06013000011444092,
        -0.014253435656428337,
        -0.049636874347925186,
        0.06100582331418991,
        -0.025110436603426933,
        0.061914604157209396,
        -0.06224280595779419,
        0.057143695652484894,
        0.045926712453365326,
        -0.011465920135378838,
        -0.06142221763730049,
        0.025567304342985153,
        -0.008281059563159943,
        -0.05552414059638977,
        -0.047605011612176895,
        -0.059834688901901245,
        0.05201514810323715,
        -0.05995091050863266,
        -0.05964658781886101,
        0.05877299606800079,
        -0.05737782642245293,
        -0.05414186790585518,
        0.028839435428380966,
        -0.028038501739501953,
        0.06218786910176277,
        -0.04746511951088905,
        -0.031282030045986176,
        0.005495870020240545,
        0.03149343281984329,
        0.022683557122945786,
        0.007529155816882849,
        0.04887465015053749,
        -0.04408487677574158,
        0.029500240460038185,
        0.04562336206436157,
        -0.04325597733259201,
        0.06189107149839401,
        -0.03833192214369774,
        -0.057364363223314285,
        0.03455379605293274,
        -0.045173440128564835,
        -0.0001721531298244372,
        -0.05591960623860359,
        -0.038166530430316925,
        -0.0491393581032753,
        -0.06139548122882843,
        -0.0616590790450573,
        0.059854939579963684,
        0.04803522676229477,
        -0.04256398230791092,
        -0.00263992534019053,
        0.0531717911362648,
        0.04558253660798073,
        0.0469282865524292,
        0.04391349107027054,
        0.05680648982524872,
        -0.020719533786177635,
        0.05531208962202072,
        0.054925039410591125,
        -0.06117675453424454,
        0.059095825999975204,
        0.055756714195013046,
        -0.05655080825090408,
        0.05838970094919205,
        0.053492967039346695,
        0.04776579514145851,
        -0.034345973283052444,
        0.05297568067908287,
        -0.05457357317209244,
        0.04970278590917587,
        -0.05004669353365898,
        0.025435062125325203,
        -0.04040350392460823,
        -0.061440300196409225,
        0.020780717954039574,
        -0.005999143701046705,
        -0.03631629794836044,
        0.013790053315460682,
        -0.017104115337133408,
        -0.05793927237391472,
        0.028824711218476295,
        -0.051821671426296234,
        0.04657795652747154,
        -0.04195893555879593,
        -0.05386127904057503,
        0.0002320815110579133,
        0.03518126532435417,
        0.048256538808345795,
        0.05168440192937851,
        -0.058555591851472855,
        -0.030300114303827286,
        -0.0355655699968338,
        0.013850538060069084,
        -0.033463042229413986,
        0.016792364418506622,
        -0.039614807814359665,
        -0.04492882639169693,
        -0.0520828440785408,
        0.05884286016225815,
        0.02964390069246292,
        -0.0015670198481529951,
        0.059445686638355255,
        -0.030520103871822357,
        0.03639060631394386,
        -0.030294647440314293,
        0.05985759198665619,
        -0.06216531991958618,
        -0.02453717775642872,
        0.061470650136470795,
        0.023875419050455093,
        0.023218045011162758,
        0.057197459042072296,
        -0.03983030095696449,
        0.060362886637449265,
        0.04804925248026848,
        0.017066793516278267,
        -0.016619449481368065,
        -0.0166636910289526,
        0.0008583065937273204,
        0.04912218824028969,
        -0.057425178587436676,
        -0.045023445039987564,
        0.05953282490372658,
        -0.06128302216529846,
        0.055558666586875916,
        -0.012346423231065273,
        -0.00007877961616031826,
        0.01062602736055851,
        0.037135690450668335,
        -0.05493337661027908,
        0.05714995786547661,
        -0.043111398816108704,
        0.03429490700364113,
        -0.05083227530121803,
        0.00271233101375401,
        0.0032488692086189985,
        0.0431894026696682,
        0.0575539693236351,
        0.015604221262037754,
        0.007208380848169327,
        -0.05888930335640907,
        0.058269187808036804,
        0.060066185891628265,
        0.05503971502184868,
        0.03291025757789612,
        -0.06133449450135231,
        -0.06139190495014191,
        0.06108836084604263,
        0.05971416085958481,
        -0.027652263641357422,
        -0.04752786457538605,
        -0.060793548822402954,
        -0.044417329132556915,
        -0.042792364954948425,
        -0.061065174639225006,
        -0.037955790758132935,
        -0.01630929484963417,
        -0.03825800493359566,
        -0.025465762242674828,
        0.058343105018138885,
        0.05918736383318901,
        0.05677654966711998,
        0.006891918368637562,
        -0.06021233648061752,
        -0.029321474954485893,
        0.06008148565888405,
        -0.02981533855199814,
        -0.058191683143377304,
        -0.0037939876783639193,
        0.03254546597599983,
        0.00967799499630928,
        -0.04671366140246391,
        0.049683086574077606,
        0.017998233437538147,
        -0.05301831662654877,
        -0.06080092117190361,
        0.05551322549581528,
        0.027051003649830818,
        0.05247371271252632,
        -0.05193459242582321,
        -0.026453735306859016,
        0.049653127789497375,
        0.029548538848757744,
        0.05614621937274933,
        -0.014495357871055603,
        -0.03763251751661301,
        0.0434640571475029,
        -0.05087476596236229,
        0.011223162524402142,
        0.03161858022212982,
        -0.013759725727140903,
        0.019449686631560326,
        0.037508293986320496,
        -0.05177055299282074,
        0.060180891305208206,
        -0.0299711711704731,
        -0.061735451221466064,
        0.0034923625644296408,
        0.003268588799983263,
        -0.05467037111520767,
        -0.06178763881325722,
        0.010313709266483784,
        -0.01795818656682968,
        0.033570460975170135,
        0.03470875695347786,
        0.05734964832663536,
        0.05766985937952995,
        0.05612910911440849,
        0.06068200618028641,
        0.02899923361837864,
        0.05634789913892746,
        -0.048193082213401794,
        0.05938190221786499,
        0.035045918077230453,
        -0.05140383169054985,
        0.006388818845152855,
        -0.010018548928201199,
        0.03990034759044647,
        0.009345603175461292,
        -0.058130957186222076,
        0.039081405848264694,
        -0.033420905470848083,
        -0.03847648575901985,
        0.05808144435286522,
        -0.04189610481262207,
        -0.024802954867482185,
        -0.06017427518963814,
        0.03756651654839516,
        0.025025762617588043,
        0.06081476807594299,
        -0.007818284444510937,
        0.05554191395640373,
        -0.058400724083185196,
        -0.04976027086377144,
        0.03350469097495079,
        0.06010952219367027,
        0.041282638907432556,
        -0.0043042972683906555,
        -0.059865567833185196,
        -0.023048320785164833,
        0.05339708551764488,
        0.04322264716029167,
        -0.02861493080854416,
        -0.04843457415699959,
        -0.049060553312301636,
        -0.05904917046427727,
        -0.0555148608982563,
        -0.010944473557174206,
        -0.005658481270074844,
        0.04563969001173973,
        0.04075104743242264,
        0.0405443012714386,
        -0.05427449196577072,
        0.03964865580201149,
        -0.060970794409513474,
        0.033095359802246094,
        -0.006303264293819666,
        0.03654557466506958,
        -0.011614471673965454,
        0.03282690420746803,
        0.047521114349365234,
        0.05009392648935318,
        0.05675903707742691,
        0.05929943174123764,
        -0.05525829270482063,
        -0.0453425794839859,
        -0.05136977881193161,
        0.023368582129478455,
        0.006526811979711056,
        -0.05252082645893097,
        0.05437696725130081,
        0.005007864907383919,
        -0.0007313445094041526,
        -0.06234189495444298,
        0.031001698225736618,
        0.06201482564210892
    ],
    [
        -0.010581990703940392,
        0.0410459004342556,
        0.038178760558366776,
        0.037720806896686554,
        -0.04130148887634277,
        -0.025099510326981544,
        -0.04639023169875145,
        -0.06148923188447952,
        0.06124165281653404,
        0.021046342328190804,
        0.01728181354701519,
        0.032646119594573975,
        -0.02824566699564457,
        -0.012165206484496593,
        -0.05704609677195549,
        0.062283821403980255,
        -0.011450698599219322,
        0.05997486412525177,
        -0.05963892489671707,
        -0.04427577555179596,
        -0.004313794896006584,
        0.055406078696250916,
        0.006730637047439814,
        -0.007557341363281012,
        0.012331449426710606,
        0.05474264919757843,
        0.0563068650662899,
        -0.046577874571084976,
        0.021288691088557243,
        -0.015626195818185806,
        0.04306351765990257,
        -0.035507626831531525,
        -0.05114029720425606,
        -0.05692489817738533,
        0.054751213639974594,
        0.00028236123034730554,
        -0.06052546203136444,
        -0.04419337585568428,
        -0.06209249794483185,
        -0.049507152289152145,
        0.05053267255425453,
        0.024615516886115074,
        0.016513893380761147,
        -0.05778421089053154,
        0.024882134050130844,
        0.05985688790678978,
        0.029301980510354042,
        0.0025800373405218124,
        0.04795828089118004,
        0.047509558498859406,
        0.05999165400862694,
        0.061978019773960114,
        -0.050045233219861984,
        0.02823043242096901,
        0.021378718316555023,
        -0.03382810205221176,
        0.010891105979681015,
        0.01439391914755106,
        -0.008523610420525074,
        -0.02795478329062462,
        -0.053045909851789474,
        0.03798270970582962,
        -0.0515647791326046,
        0.06199575215578079,
        -0.05930057913064957,
        -0.039479810744524,
        0.025305218994617462,
        0.02432151511311531,
        -0.06078300625085831,
        0.0433092825114727,
        -0.05771666765213013,
        -0.06162887066602707,
        0.061796560883522034,
        0.009063230827450752,
        0.05334896221756935,
        -0.04620504379272461,
        -0.016307387501001358,
        0.06222765892744064,
        -0.03258542716503143,
        -0.004823806695640087,
        0.0011869472218677402,
        0.059939973056316376,
        -0.04695752635598183,
        0.02844236046075821,
        0.0439184196293354,
        0.05168186500668526,
        0.06123967841267586,
        -0.0472697950899601,
        0.05279386043548584,
        0.0013218686217442155,
        0.05658123642206192,
        0.0579620897769928,
        -0.05964602530002594,
        0.005655619781464338,
        -0.05839337408542633,
        0.055713627487421036,
        -0.008175305090844631,
        -0.061414822936058044,
        0.04333430528640747,
        -0.028945811092853546,
        -0.061274345964193344,
        0.021904807537794113,
        0.06023986265063286,
        0.0221266970038414,
        0.05112680047750473,
        -0.06017029657959938,
        -0.05468820035457611,
        0.055664096027612686,
        -0.04596559703350067,
        0.03692210838198662,
        -0.059770744293928146,
        -0.020671971142292023,
        -0.05928969383239746,
        0.02372307889163494,
        -0.045356348156929016,
        0.039331868290901184,
        0.05402939394116402,
        -0.0028156230691820383,
        0.06222115457057953,
        0.04973116144537926,
        -0.0006684620166197419,
        0.06007298454642296,
        0.035667240619659424,
        -0.04825703799724579,
        -0.05852118507027626,
        -0.05329965054988861,
        -0.03887297585606575,
        -0.057917892932891846,
        0.06068072095513344,
        0.03195372223854065,
        0.027861062437295914,
        -0.05645226314663887,
        -0.0594259649515152,
        0.015055970288813114,
        -0.05538018420338631,
        -0.05664397031068802,
        0.039751213043928146,
        0.05773305520415306,
        -0.06164275109767914,
        0.02990543469786644,
        0.061628565192222595,
        0.004089896101504564,
        0.059631817042827606,
        0.007985017262399197,
        0.06080415099859238,
        0.0082120131701231,
        0.05389991030097008,
        -0.05749858170747757,
        0.007395611610263586,
        0.06136550381779671,
        0.05455438420176506,
        -0.06213231012225151,
        0.019312797114253044,
        -0.059069257229566574,
        0.05228229612112045,
        -0.056188590824604034,
        -0.005237360019236803,
        0.032300759106874466,
        0.01848806068301201,
        0.03846333548426628,
        0.05432954430580139,
        0.05306169018149376,
        0.05230562016367912,
        0.04826889559626579,
        0.025045115500688553,
        0.0012692894088104367,
        -0.03597423434257507,
        0.050962530076503754,
        0.044565599411726,
        0.022117702290415764,
        0.014611802995204926,
        0.046088650822639465,
        -0.014762314036488533,
        -0.054391924291849136,
        0.030264366418123245,
        0.06103292480111122,
        -0.061878085136413574,
        0.02903725393116474,
        0.05953130125999451,
        -0.05099037289619446,
        -0.006321015767753124,
        0.06205131113529205,
        0.010032988153398037,
        0.02948629856109619,
        0.03854569047689438,
        0.019966760650277138,
        -0.05868510529398918,
        0.0568925105035305,
        -0.05753529816865921,
        -0.026668163016438484,
        -0.028556790202856064,
        0.007891136221587658,
        0.017299411818385124,
        -0.0390150211751461,
        -0.04582848772406578,
        0.033443473279476166,
        -0.043992914259433746,
        0.005259939469397068,
        -0.041913095861673355,
        -0.0012817918322980404,
        0.015481458976864815,
        -0.022283755242824554,
        0.009936785325407982,
        0.03940549120306969,
        -0.05414889380335808,
        0.041562676429748535,
        0.03881062939763069,
        0.03093097172677517,
        -0.05631183087825775,
        0.020046574994921684,
        -0.05397970601916313,
        0.0546482689678669,
        -0.017437035217881203,
        0.03139129653573036,
        -0.0326138474047184,
        0.05141046643257141,
        0.06073617562651634,
        -0.02663564495742321,
        0.06021909415721893,
        0.021036501973867416,
        0.010409235954284668,
        0.06202758103609085,
        -0.0568092055618763,
        -0.0474453829228878,
        0.05498649552464485,
        0.022457046434283257,
        0.0231404360383749,
        0.05023973807692528,
        0.03502222150564194,
        -0.05531046912074089,
        0.037648048251867294,
        -0.05465114116668701,
        -0.04743470251560211,
        0.03372413292527199,
        -0.022860970348119736,
        0.06232639402151108,
        0.06014815345406532,
        -0.03163471445441246,
        -0.04625905305147171,
        0.06175345554947853,
        -0.0274454727768898,
        0.06191254407167435,
        -0.06210411339998245,
        0.05844040587544441,
        0.04549774155020714,
        0.00012000490096397698,
        -0.062062595039606094,
        0.03497360646724701,
        0.001779027283191681,
        -0.05391475558280945,
        -0.04605880007147789,
        -0.06041242554783821,
        0.048391811549663544,
        -0.05982913449406624,
        -0.05996187776327133,
        0.059240974485874176,
        -0.059158194810152054,
        -0.0522671677172184,
        0.03628621622920036,
        -0.01229395903646946,
        0.062111835926771164,
        -0.05182640627026558,
        -0.04539324343204498,
        0.022764690220355988,
        0.006003565154969692,
        0.013035645708441734,
        0.01906737871468067,
        0.03864588961005211,
        -0.04235868901014328,
        0.035269975662231445,
        0.049471236765384674,
        -0.04798856005072594,
        0.06194883957505226,
        -0.04269101843237877,
        -0.05716864392161369,
        0.0374644473195076,
        -0.04923500120639801,
        -0.0016030519036576152,
        -0.04331771284341812,
        -0.03433973714709282,
        -0.04707637429237366,
        -0.061018578708171844,
        -0.061626970767974854,
        0.060839563608169556,
        0.04042154178023338,
        -0.05088760331273079,
        0.004431443754583597,
        0.04951523244380951,
        0.04716823250055313,
        0.045785706490278244,
        0.03511699289083481,
        0.05770014226436615,
        -0.03261329606175423,
        0.053727708756923676,
        0.052015893161296844,
        -0.060898877680301666,
        0.05933461710810661,
        0.05963370203971863,
        -0.05714941769838333,
        0.05697019025683403,
        0.05655841529369354,
        0.028918864205479622,
        -0.028453776612877846,
        0.05206930264830589,
        -0.04460221901535988,
        0.053731951862573624,
        -0.054942693561315536,
        0.01899034157395363,
        -0.03945159167051315,
        -0.06148570775985718,
        0.016786498948931694,
        -0.022827107459306717,
        -0.04170995578169823,
        0.004056212492287159,
        -0.03909633308649063,
        -0.058508314192295074,
        0.02145262621343136,
        -0.05350938066840172,
        0.04497655853629112,
        -0.043955106288194656,
        -0.05667119100689888,
        0.008179130963981152,
        0.02171126939356327,
        0.030522508546710014,
        0.05628898739814758,
        -0.05940590798854828,
        -0.024819618090987206,
        -0.015425194054841995,
        0.0012605306692421436,
        -0.04276737570762634,
        0.011003020219504833,
        -0.04975063353776932,
        -0.04410363733768463,
        -0.05597516521811485,
        0.058262478560209274,
        0.01757136732339859,
        -0.017306650057435036,
        0.0583721362054348,
        -0.03088195063173771,
        0.040339093655347824,
        -0.037326134741306305,
        0.05992839112877846,
        -0.06220296397805214,
        -0.02807925082743168,
        0.061982061713933945,
        0.01947215013206005,
        0.01689874194562435,
        0.057931795716285706,
        -0.04449373483657837,
        0.05999982729554176,
        0.04431099072098732,
        0.03084850125014782,
        -0.03023981675505638,
        -0.0237160362303257,
        -0.0015755004715174437,
        0.05120322108268738,
        -0.05781945586204529,
        -0.04776906594634056,
        0.058429595082998276,
        -0.059728801250457764,
        0.05339285731315613,
        -0.02073870785534382,
        -0.0005558821139857173,
        0.014174503274261951,
        0.04192495346069336,
        -0.05505053326487541,
        0.058193374425172806,
        -0.012278513051569462,
        0.028132684528827667,
        -0.056094225496053696,
        -0.003229909809306264,
        0.004154481925070286,
        0.04473646730184555,
        0.05561501160264015,
        0.021583089604973793,
        0.017666473984718323,
        -0.06071765720844269,
        0.05970531702041626,
        0.05805832892656326,
        0.044079896062612534,
        0.03807706758379936,
        -0.059656426310539246,
        -0.061585672199726105,
        0.061071861535310745,
        0.05853673443198204,
        -0.029442282393574715,
        -0.045830707997083664,
        -0.05986056476831436,
        -0.04798056185245514,
        -0.046843066811561584,
        -0.061355575919151306,
        -0.04998233914375305,
        -0.0421454943716526,
        -0.03351099416613579,
        -0.03740140050649643,
        0.058896493166685104,
        0.056224219501018524,
        0.05693583935499191,
        0.03279092162847519,
        -0.059808142483234406,
        -0.029024502262473106,
        0.06099503114819527,
        0.006671171635389328,
        -0.05768577381968498,
        -0.00670622056350112,
        0.031941767781972885,
        0.0020274261478334665,
        -0.04394473508000374,
        0.051042843610048294,
        0.007366139441728592,
        -0.05610835924744606,
        -0.060141053050756454,
        0.05442430451512337,
        0.009955255314707756,
        0.05339323356747627,
        -0.04868563637137413,
        -0.013091791421175003,
        0.04448116198182106,
        0.018584901466965675,
        0.04527250677347183,
        -0.006894961930811405,
        -0.031240487471222878,
        0.0334385521709919,
        -0.05185285955667496,
        0.015903586521744728,
        0.026024265214800835,
        -0.033736202865839005,
        0.02651282399892807,
        0.04955263435840607,
        -0.05776466429233551,
        0.05994671955704689,
        -0.03713231906294823,
        -0.06174305081367493,
        0.010472414083778858,
        0.013320905156433582,
        -0.055768586695194244,
        -0.06051565706729889,
        0.01827922835946083,
        0.0022751439828425646,
        0.024748610332608223,
        0.0524454265832901,
        0.054555900394916534,
        0.052384406328201294,
        0.05484851077198982,
        0.061952680349349976,
        0.030950704589486122,
        0.05740952864289284,
        -0.05115242302417755,
        0.05764957144856453,
        0.04245362430810928,
        -0.05041521042585373,
        0.020493971183896065,
        -0.027161119505763054,
        0.045795340090990067,
        -0.003155234968289733,
        -0.0588935986161232,
        0.036196932196617126,
        -0.010075469501316547,
        -0.04603850841522217,
        0.05722174420952797,
        -0.02655513770878315,
        -0.025410763919353485,
        -0.06054162234067917,
        0.04838430881500244,
        0.02567320503294468,
        0.06080154329538345,
        0.0007892355206422508,
        0.05599606782197952,
        -0.05918209254741669,
        -0.05332981050014496,
        0.017771603539586067,
        0.058680493384599686,
        0.04180161654949188,
        -0.0008374282042495906,
        -0.05854211002588272,
        -0.017075106501579285,
        0.053212717175483704,
        0.040389787405729294,
        -0.02204771153628826,
        -0.05252881348133087,
        -0.044192951172590256,
        -0.05998057499527931,
        -0.0475449338555336,
        -0.007797630969434977,
        0.022199170663952827,
        0.04492394998669624,
        0.05320416018366814,
        0.04619637504220009,
        -0.05180559679865837,
        0.042811911553144455,
        -0.0617099329829216,
        0.02249540574848652,
        0.023987900465726852,
        0.038472291082143784,
        -0.03349659964442253,
        0.014482002705335617,
        0.03791915997862816,
        0.0468093641102314,
        0.055782221257686615,
        0.06015906110405922,
        -0.05095640569925308,
        -0.042522381991147995,
        -0.05025419965386391,
        0.012239204719662666,
        0.014209751971065998,
        -0.04986623674631119,
        0.05271298810839653,
        0.005985694006085396,
        0.024447280913591385,
        -0.06233933940529823,
        0.021448587998747826,
        0.061940405517816544
    ],
    [
        -0.02050514705479145,
        0.03420272842049599,
        0.03474966064095497,
        0.027367549017071724,
        -0.04272336885333061,
        -0.02587321773171425,
        -0.051919322460889816,
        -0.06133350357413292,
        0.06081918999552727,
        0.021206624805927277,
        0.017556028440594673,
        0.027470069006085396,
        -0.030374357476830482,
        -0.028976520523428917,
        -0.05519138276576996,
        0.062083158642053604,
        -0.004480359610170126,
        0.059695374220609665,
        -0.05916669964790344,
        -0.04643094912171364,
        -0.015394306741654873,
        0.056320805102586746,
        0.011586983688175678,
        -0.0033943597227334976,
        -0.00658566877245903,
        0.051325973123311996,
        0.05682780593633652,
        -0.05522550642490387,
        0.021527474746108055,
        -0.014482694678008556,
        0.01861637830734253,
        -0.044809114187955856,
        -0.04634977877140045,
        -0.052029334008693695,
        0.0541837215423584,
        0.0005036485381424427,
        -0.06009577959775925,
        -0.037683941423892975,
        -0.062016528099775314,
        -0.04906325787305832,
        0.05026184394955635,
        0.01427752710878849,
        0.022763431072235107,
        -0.059275634586811066,
        0.03865664824843407,
        0.06003894284367561,
        0.038052186369895935,
        0.01682158373296261,
        0.039442744106054306,
        0.05450393259525299,
        0.06102020666003227,
        0.061822980642318726,
        -0.05067308619618416,
        0.02923574298620224,
        0.018406104296445847,
        -0.033298928290605545,
        0.026462428271770477,
        0.017324570566415787,
        -0.009773883037269115,
        -0.04016662761569023,
        -0.05534133315086365,
        0.03276810050010681,
        -0.05148240178823471,
        0.06189442425966263,
        -0.058313656598329544,
        -0.02581074647605419,
        0.03046444058418274,
        0.008031087927520275,
        -0.060879215598106384,
        0.044695887714624405,
        -0.05962856113910675,
        -0.060348618775606155,
        0.06165018305182457,
        0.018182745203375816,
        0.05204898864030838,
        -0.04688187688589096,
        -0.02554897777736187,
        0.061954740434885025,
        -0.04624504595994949,
        -0.02631213143467903,
        -0.003836622927337885,
        0.059763506054878235,
        -0.04696471989154816,
        0.027591831982135773,
        0.04706372693181038,
        0.05377843230962753,
        0.060730744153261185,
        -0.04049181193113327,
        0.05467353016138077,
        0.0019472483545541763,
        0.05796512961387634,
        0.056590914726257324,
        -0.060157410800457,
        0.0021043026354163885,
        -0.058986518532037735,
        0.056561507284641266,
        -0.012127725407481194,
        -0.061120420694351196,
        0.050844475626945496,
        -0.030115405097603798,
        -0.061531007289886475,
        0.029011357575654984,
        0.060210999101400375,
        0.02852618880569935,
        0.05287052318453789,
        -0.06122519075870514,
        -0.05053028464317322,
        0.05761721730232239,
        -0.04355307295918465,
        0.03749905899167061,
        -0.06009252369403839,
        -0.013874135911464691,
        -0.05925940349698067,
        0.01432576309889555,
        -0.03837469220161438,
        0.04474003240466118,
        0.055939413607120514,
        -0.018724624067544937,
        0.061991576105356216,
        0.05052480101585388,
        0.009684295393526554,
        0.05919954925775528,
        0.041403017938137054,
        -0.05295509472489357,
        -0.0593671053647995,
        -0.05463410168886185,
        -0.027751905843615532,
        -0.05912081152200699,
        0.0601966455578804,
        0.040069401264190674,
        0.04549712315201759,
        -0.056902047246694565,
        -0.059757571667432785,
        0.00916387140750885,
        -0.05613555759191513,
        -0.05675499513745308,
        0.04522417113184929,
        0.058063630014657974,
        -0.06176728382706642,
        0.033973824232816696,
        0.061398908495903015,
        0.023416802287101746,
        0.059960778802633286,
        0.01422037836164236,
        0.059470027685165405,
        0.006605212576687336,
        0.05352821201086044,
        -0.05884011462330818,
        0.010769408196210861,
        0.06112676113843918,
        0.049093425273895264,
        -0.06185455247759819,
        0.048652347177267075,
        -0.05916118994355202,
        0.051674772053956985,
        -0.05504188314080238,
        -0.02199113741517067,
        0.03648564592003822,
        0.02610933966934681,
        0.027401408180594444,
        0.05420810729265213,
        0.050967972725629807,
        0.0538158193230629,
        0.04042259231209755,
        0.031192347407341003,
        0.006272044964134693,
        -0.03064272552728653,
        0.04801798611879349,
        0.031977150589227676,
        0.02045113407075405,
        0.03471336141228676,
        0.04212240129709244,
        0.0002939997357316315,
        -0.0560394823551178,
        0.04477692395448685,
        0.06020084023475647,
        -0.06097176671028137,
        0.0385560505092144,
        0.0585879422724247,
        -0.05331389978528023,
        -0.008123937994241714,
        0.061961475759744644,
        0.024066932499408722,
        0.009674279019236565,
        0.03770554065704346,
        0.005620600190013647,
        -0.05591834336519241,
        0.05827007442712784,
        -0.05785803496837616,
        -0.0035507874563336372,
        -0.015151981264352798,
        0.014973022043704987,
        0.03305372595787048,
        -0.03029639832675457,
        -0.04640693590044975,
        0.02092904970049858,
        -0.04302487522363663,
        0.0070968917571008205,
        -0.0467347577214241,
        0.011391008272767067,
        -0.0013976199552416801,
        -0.013303235173225403,
        -0.022903520613908768,
        0.023618044331669807,
        -0.058368030935525894,
        0.0423700287938118,
        0.03590289503335953,
        0.02201041392982006,
        -0.050105251371860504,
        0.03750777244567871,
        -0.05280151590704918,
        0.0563574880361557,
        0.006140183657407761,
        0.043488238006830215,
        -0.019165923818945885,
        0.04492722824215889,
        0.05708148702979088,
        -0.03603319823741913,
        0.05993949994444847,
        0.008625575341284275,
        0.01284633670002222,
        0.06190270557999611,
        -0.05310682952404022,
        -0.047261252999305725,
        0.05098910257220268,
        0.04570470377802849,
        0.02727612480521202,
        0.0509595051407814,
        0.03833886981010437,
        -0.05286089703440666,
        0.028234200552105904,
        -0.04197344183921814,
        -0.050808995962142944,
        0.03961816057562828,
        -0.01706070452928543,
        0.06203675642609596,
        0.06023579090833664,
        -0.024953102692961693,
        -0.04731358215212822,
        0.06142294406890869,
        -0.017089540138840675,
        0.06159799546003342,
        -0.0618332140147686,
        0.05862393230199814,
        0.04658433794975281,
        0.0005003534024581313,
        -0.06190555915236473,
        0.021944263949990273,
        0.008278150111436844,
        -0.051649779081344604,
        -0.04844043031334877,
        -0.060350991785526276,
        0.03756141662597656,
        -0.05851171910762787,
        -0.059475209563970566,
        0.0593772754073143,
        -0.05961848422884941,
        -0.04818170890212059,
        0.04074803739786148,
        -0.02480015531182289,
        0.06177913025021553,
        -0.05146656930446625,
        -0.044718559831380844,
        0.012835090979933739,
        -0.00562766520306468,
        0.014666964299976826,
        0.019091978669166565,
        0.05260443687438965,
        -0.041895411908626556,
        0.03747520223259926,
        0.049189914017915726,
        -0.04625736549496651,
        0.061535630375146866,
        -0.03097616136074066,
        -0.058055952191352844,
        0.03438270464539528,
        -0.04952867701649666,
        0.000909725611563772,
        -0.04629337042570114,
        -0.028365302830934525,
        -0.04504907503724098,
        -0.06062331423163414,
        -0.061152420938014984,
        0.06023753806948662,
        0.04189533367753029,
        -0.05553336814045906,
        0.014893964864313602,
        0.05312877148389816,
        0.043261151760816574,
        0.04047178104519844,
        0.042142584919929504,
        0.056259769946336746,
        -0.033932875841856,
        0.05389230325818062,
        0.05326278135180473,
        -0.06078105792403221,
        0.05992484465241432,
        0.059730250388383865,
        -0.057706210762262344,
        0.05655808746814728,
        0.053990744054317474,
        0.028875498101115227,
        -0.030997559428215027,
        0.05060397461056709,
        -0.0383661650121212,
        0.04989512637257576,
        -0.056033410131931305,
        0.0027375572826713324,
        -0.03934299200773239,
        -0.059348855167627335,
        0.005439816974103451,
        -0.012070666067302227,
        -0.048781003803014755,
        -0.008369083516299725,
        -0.0331059992313385,
        -0.057773951441049576,
        0.02129787765443325,
        -0.05636348947882652,
        0.0427946038544178,
        -0.046960506588220596,
        -0.05469762161374092,
        0.0037332528736442327,
        0.015990231186151505,
        0.022960778325796127,
        0.054380953311920166,
        -0.058449022471904755,
        -0.025922447443008423,
        -0.011604745872318745,
        0.01996377669274807,
        -0.03383883461356163,
        0.00785657949745655,
        -0.049543969333171844,
        -0.05377919599413872,
        -0.05664637312293053,
        0.05797326937317848,
        0.024171413853764534,
        -0.008181232959032059,
        0.057624638080596924,
        -0.032942213118076324,
        0.04838097095489502,
        -0.04305252805352211,
        0.05911993980407715,
        -0.06196797266602516,
        -0.011214391328394413,
        0.06120273470878601,
        0.02805279940366745,
        0.0142131382599473,
        0.05663241073489189,
        -0.047170162200927734,
        0.059457603842020035,
        0.03713816776871681,
        0.030336612835526466,
        -0.02493756078183651,
        -0.025648370385169983,
        0.014878369867801666,
        0.04883386939764023,
        -0.05865279585123062,
        -0.04784037172794342,
        0.05940692499279976,
        -0.05948389321565628,
        0.05257638543844223,
        -0.009422113187611103,
        -0.004943107254803181,
        0.006416806019842625,
        0.05174354463815689,
        -0.05748489126563072,
        0.056209057569503784,
        -0.019798241555690765,
        0.0093931183218956,
        -0.05551505088806152,
        -0.0009496459970250726,
        0.004082747269421816,
        0.037971075624227524,
        0.0572330616414547,
        0.008827773854136467,
        0.009354012086987495,
        -0.059222351759672165,
        0.06022103130817413,
        0.05889024958014488,
        0.04442078620195389,
        0.01502044778317213,
        -0.059416331350803375,
        -0.061496343463659286,
        0.06057986244559288,
        0.05894960090517998,
        -0.023085439577698708,
        -0.05118955299258232,
        -0.060404691845178604,
        -0.0538465678691864,
        -0.03451279550790787,
        -0.06044922024011612,
        -0.05253395810723305,
        -0.03788060322403908,
        -0.021720806136727333,
        -0.04395288974046707,
        0.05779643729329109,
        0.05625502020120621,
        0.05576531961560249,
        0.036531902849674225,
        -0.059554990381002426,
        -0.028740474954247475,
        0.060428909957408905,
        0.010690818540751934,
        -0.05836506187915802,
        0.002359696663916111,
        0.031709518283605576,
        0.00638966029509902,
        -0.04962015897035599,
        0.052120134234428406,
        0.009397891350090504,
        -0.05698895454406738,
        -0.05913466215133667,
        0.05599276348948479,
        -0.0037157831247895956,
        0.05410101264715195,
        -0.044684045016765594,
        -0.02299170382320881,
        0.017767108976840973,
        0.017186421900987625,
        0.03332462161779404,
        -0.014565742574632168,
        -0.038548361510038376,
        0.03654249757528305,
        -0.050658125430345535,
        -0.010834156535565853,
        0.042353011667728424,
        -0.04365886002779007,
        0.030395204201340675,
        0.05062473937869072,
        -0.05564942583441734,
        0.05928141251206398,
        -0.02517380751669407,
        -0.06122802942991257,
        0.008613339625298977,
        0.021045926958322525,
        -0.05588589236140251,
        -0.05891004949808121,
        0.036186132580041885,
        0.007287365850061178,
        0.011765099130570889,
        0.04156781733036041,
        0.056737735867500305,
        0.051398877054452896,
        0.05440419539809227,
        0.06196177005767822,
        0.024605806916952133,
        0.058191731572151184,
        -0.04998799040913582,
        0.05843658000230789,
        0.05115870386362076,
        -0.04780520498752594,
        0.0009115691646002233,
        -0.02820589579641819,
        0.0420810729265213,
        0.0013840803876519203,
        -0.0591735802590847,
        0.03765583038330078,
        -0.023386089131236076,
        -0.029597267508506775,
        0.05578693374991417,
        -0.013959470205008984,
        -0.03236396610736847,
        -0.05849984288215637,
        0.05203109234571457,
        0.02593369223177433,
        0.060398660600185394,
        0.0008647420909255743,
        0.05758315324783325,
        -0.05911414325237274,
        -0.0489947646856308,
        0.011917064897716045,
        0.0521540604531765,
        0.042113445699214935,
        -0.016118301078677177,
        -0.06055552884936333,
        -0.03416706249117851,
        0.056746263056993484,
        0.0394120067358017,
        -0.021740561351180077,
        -0.05292497202754021,
        -0.04630099982023239,
        -0.058808233588933945,
        -0.0540192611515522,
        -0.0036329547874629498,
        0.024665378034114838,
        0.04728376120328903,
        0.05725069344043732,
        0.04489675536751747,
        -0.05073654279112816,
        0.04613455757498741,
        -0.06085989251732826,
        0.02748125232756138,
        0.017627714201807976,
        0.03825408220291138,
        -0.022293725982308388,
        0.016893485561013222,
        0.043862491846084595,
        0.05055743083357811,
        0.05619756877422333,
        0.058814771473407745,
        -0.05672749504446983,
        -0.04279102757573128,
        -0.055592212826013565,
        0.01709548570215702,
        0.026834987103939056,
        -0.052569784224033356,
        0.04697994142770767,
        0.005121886730194092,
        0.020283591002225876,
        -0.06209497153759003,
        -0.003231514012441039,
        0.06121230497956276
    ],
    [
        -0.009582324884831905,
        0.042413849383592606,
        0.045163754373788834,
        0.026253875344991684,
        -0.04584339261054993,
        0.007039128802716732,
        -0.04421399533748627,
        -0.061814527958631516,
        0.061271559447050095,
        0.018412526696920395,
        0.03063121624290943,
        0.0022878500167280436,
        -0.02306426502764225,
        -0.001300426316447556,
        -0.05719497799873352,
        0.06252814084291458,
        -0.012923630885779858,
        0.06134604290127754,
        -0.05906446650624275,
        -0.04759375751018524,
        -0.016158392652869225,
        0.05972104147076607,
        0.005458603613078594,
        -0.008749296888709068,
        0.02178679220378399,
        0.05135589465498924,
        0.05506708472967148,
        -0.05161161348223686,
        0.02623530477285385,
        -0.008147720247507095,
        0.03999004885554314,
        -0.02446608617901802,
        -0.054424140602350235,
        -0.05585060268640518,
        0.05687907710671425,
        0.0027286435943096876,
        -0.06116361916065216,
        -0.04343065619468689,
        -0.061689745634794235,
        -0.04913242161273956,
        0.047050271183252335,
        0.023208677768707275,
        0.021855808794498444,
        -0.05080651491880417,
        -0.006143984384834766,
        0.0605921670794487,
        0.004534851759672165,
        0.003562547732144594,
        0.04674379155039787,
        0.056603025645017624,
        0.0608295202255249,
        0.062348417937755585,
        -0.05316030979156494,
        0.021109670400619507,
        0.030697904527187347,
        -0.02989090606570244,
        -0.005093579646199942,
        0.028008295223116875,
        0.008957789279520512,
        -0.027308110147714615,
        -0.0517105795443058,
        0.04217583313584328,
        -0.051425810903310776,
        0.062161885201931,
        -0.05713760852813721,
        -0.051215868443250656,
        0.015049751847982407,
        0.0017614106182008982,
        -0.06155623123049736,
        0.046321261674165726,
        -0.05794258043169975,
        -0.06240401417016983,
        0.06243942677974701,
        0.02641976810991764,
        0.04835868626832962,
        -0.040404338389635086,
        -0.023238010704517365,
        0.06255801022052765,
        -0.034750934690237045,
        -0.009045891463756561,
        -0.004299139603972435,
        0.05968359857797623,
        -0.04903233051300049,
        0.02955445647239685,
        0.05241653323173523,
        0.05468079820275307,
        0.061408575624227524,
        -0.05023904889822006,
        0.05758724361658096,
        -0.0028072348795831203,
        0.0583915188908577,
        0.05622929707169533,
        -0.060152798891067505,
        0.022084107622504234,
        -0.05628162994980812,
        0.05668271332979202,
        -0.01953713409602642,
        -0.06174963340163231,
        0.04907635599374771,
        -0.02289901115000248,
        -0.061590541154146194,
        0.02718116156756878,
        0.05971774086356163,
        0.01623501442372799,
        0.04551557078957558,
        -0.060100339353084564,
        -0.04632953926920891,
        0.05074234679341316,
        -0.04758632555603981,
        0.04847242683172226,
        -0.057838160544633865,
        0.014336245134472847,
        -0.0602722093462944,
        0.002964882180094719,
        -0.0514296293258667,
        0.01633874699473381,
        0.05686432495713234,
        -0.01920500211417675,
        0.06265021115541458,
        0.05183475464582443,
        -0.004163100849837065,
        0.06078372150659561,
        0.036025166511535645,
        -0.045904017984867096,
        -0.057974085211753845,
        -0.05192888155579567,
        -0.0473930761218071,
        -0.05467742681503296,
        0.06049901619553566,
        0.019187267869710922,
        0.024569155648350716,
        -0.05829983949661255,
        -0.057792551815509796,
        -0.002662500599399209,
        -0.05539046600461006,
        -0.058391932398080826,
        0.04627915099263191,
        0.05584133416414261,
        -0.061351750046014786,
        0.03959488496184349,
        0.062434010207653046,
        0.008292218670248985,
        0.059737831354141235,
        0.016801776364445686,
        0.0615314356982708,
        -0.02048659510910511,
        0.05932058021426201,
        -0.05714840814471245,
        -0.010115701705217361,
        0.06127021834254265,
        0.05865347012877464,
        -0.0623474083840847,
        -0.0017454246990382671,
        -0.05635326728224754,
        0.059339720755815506,
        -0.05471716448664665,
        -0.012340232729911804,
        0.023607585579156876,
        0.008429291658103466,
        0.03208162263035774,
        0.05546253174543381,
        0.05681557208299637,
        0.0527103990316391,
        0.04789231717586517,
        0.02892833575606346,
        -0.028158118948340416,
        -0.03790143132209778,
        0.05328051373362541,
        0.039542365819215775,
        0.02624763920903206,
        -0.005374396685510874,
        0.04464185982942581,
        -0.00678889499977231,
        -0.05169794335961342,
        0.016813823953270912,
        0.06052962318062782,
        -0.061385881155729294,
        0.031893715262413025,
        0.059009164571762085,
        -0.0488918237388134,
        -0.00923266913741827,
        0.06232772767543793,
        -0.0036323177628219128,
        0.03466038033366203,
        0.04532899335026741,
        0.019388383254408836,
        -0.05606451258063316,
        0.05904102325439453,
        -0.05977305397391319,
        -0.03911728039383888,
        -0.027695458382368088,
        0.013079824857413769,
        0.01216182392090559,
        -0.024329466745257378,
        -0.04426638409495354,
        0.034533459693193436,
        -0.036265887320041656,
        -0.014499069191515446,
        -0.04964505136013031,
        -0.0013342341408133507,
        0.02246132679283619,
        -0.009462665766477585,
        0.009245404973626137,
        0.035160891711711884,
        -0.05696553736925125,
        0.030318962410092354,
        0.04873678833246231,
        0.02791912667453289,
        -0.05626875162124634,
        0.0252066683024168,
        -0.05293891206383705,
        0.05484333261847496,
        -0.017820224165916443,
        0.025898180902004242,
        -0.02908131666481495,
        0.05004815384745598,
        0.06001695618033409,
        -0.02489326521754265,
        0.060740526765584946,
        0.023366790264844894,
        -0.0012893549865111709,
        0.062356386333703995,
        -0.05203377082943916,
        -0.04066471755504608,
        0.05933590605854988,
        -0.013202458620071411,
        0.021815959364175797,
        0.050707653164863586,
        0.029505504295229912,
        -0.05421217158436775,
        0.034185729920864105,
        -0.05627937614917755,
        -0.042838241904973984,
        0.03514494374394417,
        -0.017389237880706787,
        0.06266051530838013,
        0.060345232486724854,
        -0.021639833226799965,
        -0.03310728818178177,
        0.06210152432322502,
        -0.028881389647722244,
        0.06187231093645096,
        -0.06248230114579201,
        0.05982758104801178,
        0.04500052332878113,
        -0.01822810061275959,
        -0.06232849508523941,
        0.018702015280723572,
        0.004625281784683466,
        -0.05403386056423187,
        -0.0468183234333992,
        -0.0609370656311512,
        0.04500559717416763,
        -0.05987484008073807,
        -0.058424510061740875,
        0.057055335491895676,
        -0.05761316791176796,
        -0.056198351085186005,
        0.02143213152885437,
        -0.02171613648533821,
        0.06242246553301811,
        -0.05417369306087494,
        -0.04150695726275444,
        0.015013158321380615,
        0.008843092247843742,
        0.029253946617245674,
        -0.00951477326452732,
        0.02513555996119976,
        -0.04581746831536293,
        0.03211480751633644,
        0.04744098335504532,
        -0.04064968600869179,
        0.06238410621881485,
        -0.04708581045269966,
        -0.05934084579348564,
        0.03684452921152115,
        -0.045284949243068695,
        0.004432155285030603,
        -0.055351849645376205,
        -0.04023812711238861,
        -0.04767753183841705,
        -0.06224343553185463,
        -0.06224970147013664,
        0.06131798028945923,
        0.036794599145650864,
        -0.05192004516720772,
        0.008287385106086731,
        0.056454598903656006,
        0.04700566828250885,
        0.04441748559474945,
        0.035486359149217606,
        0.054269954562187195,
        -0.038398198783397675,
        0.05440805107355118,
        0.05131338909268379,
        -0.06132130324840546,
        0.05911557003855705,
        0.06011359766125679,
        -0.058445487171411514,
        0.05783342570066452,
        0.05084715783596039,
        0.03722687438130379,
        -0.03177311643958092,
        0.05513454228639603,
        -0.054177023470401764,
        0.040679559111595154,
        -0.054999612271785736,
        0.021728618070483208,
        -0.03140074759721756,
        -0.062309570610523224,
        0.01244360487908125,
        -0.00668657012283802,
        -0.04710705578327179,
        -0.0009042421588674188,
        -0.019386056810617447,
        -0.05785859376192093,
        0.021574459969997406,
        -0.055792901664972305,
        0.04662376269698143,
        -0.04039546474814415,
        -0.055447306483983994,
        0.012585228309035301,
        0.006149535533040762,
        0.04320383071899414,
        0.05686216056346893,
        -0.05970543622970581,
        -0.035476844757795334,
        -0.0360780730843544,
        0.0022326167672872543,
        -0.030274752527475357,
        0.01918902061879635,
        -0.04123348742723465,
        -0.04410354793071747,
        -0.049567509442567825,
        0.05761414021253586,
        0.015314497984945774,
        -0.018011197447776794,
        0.060629960149526596,
        -0.03639725223183632,
        0.03934944421052933,
        -0.04274522885680199,
        0.06108125299215317,
        -0.062368087470531464,
        -0.025814048945903778,
        0.06125129759311676,
        0.023037157952785492,
        0.029651256278157234,
        0.056192558258771896,
        -0.03921240568161011,
        0.06001332402229309,
        0.051046229898929596,
        0.028501903638243675,
        -0.03176172822713852,
        -0.01282409392297268,
        -0.0005748497205786407,
        0.052202578634023666,
        -0.05297209694981575,
        -0.045403871685266495,
        0.058503877371549606,
        -0.061287421733140945,
        0.05533774569630623,
        -0.017091263085603714,
        0.019162334501743317,
        0.019214287400245667,
        0.046853262931108475,
        -0.054241329431533813,
        0.05711191147565842,
        -0.03232771158218384,
        0.027426982298493385,
        -0.05290573462843895,
        -0.006869540084153414,
        0.0050278897397220135,
        0.0462840236723423,
        0.05475077033042908,
        0.010019835084676743,
        0.02234543487429619,
        -0.059938233345746994,
        0.05975997820496559,
        0.05723685026168823,
        0.051543183624744415,
        0.0274964589625597,
        -0.06066850572824478,
        -0.06148793175816536,
        0.061174310743808746,
        0.0580018050968647,
        -0.014234834350645542,
        -0.04587299004197121,
        -0.060859523713588715,
        -0.048996735364198685,
        -0.030968813225626945,
        -0.061865661293268204,
        -0.04982069134712219,
        -0.038192275911569595,
        -0.04146087169647217,
        -0.04044608026742935,
        0.05933328717947006,
        0.05634421482682228,
        0.05804869905114174,
        0.04048383608460426,
        -0.06002506613731384,
        -0.034756388515233994,
        0.06097646802663803,
        -0.007361622527241707,
        -0.0572439469397068,
        -0.015679964795708656,
        0.023966511711478233,
        0.01009633019566536,
        -0.048019248992204666,
        0.04881589487195015,
        0.009694061242043972,
        -0.05517725646495819,
        -0.06096981465816498,
        0.05753093212842941,
        0.03315688297152519,
        0.05735228955745697,
        -0.04163021966814995,
        -0.03488781303167343,
        0.05326049029827118,
        0.03106692060828209,
        0.05253944173455238,
        -0.00469491071999073,
        -0.03964710235595703,
        0.03117007575929165,
        -0.05065520480275154,
        0.013369262218475342,
        0.0222433190792799,
        -0.0293369572609663,
        0.03075561299920082,
        0.04501524195075035,
        -0.056024301797151566,
        0.06025977432727814,
        -0.01944132149219513,
        -0.06194761395454407,
        0.01174288522452116,
        0.037670161575078964,
        -0.05764840170741081,
        -0.0609966404736042,
        0.02428077720105648,
        -0.007906637154519558,
        0.02097454108297825,
        0.043548066169023514,
        0.05372026562690735,
        0.056854695081710815,
        0.05665907263755798,
        0.061536066234111786,
        0.015472115948796272,
        0.053926967084407806,
        -0.045812953263521194,
        0.05937986448407173,
        0.028994077816605568,
        -0.04171770066022873,
        -0.0021330988965928555,
        -0.01613617315888405,
        0.041006509214639664,
        -0.008092700503766537,
        -0.05953380838036537,
        0.027772221714258194,
        -0.03212868049740791,
        -0.044815126806497574,
        0.05452226474881172,
        -0.0499601885676384,
        -0.029945630580186844,
        -0.060298290103673935,
        0.03888155147433281,
        0.02410886064171791,
        0.061381757259368896,
        0.024572091177105904,
        0.05767076835036278,
        -0.060877036303281784,
        -0.04405299201607704,
        0.03076794557273388,
        0.061109453439712524,
        0.03834977000951767,
        -0.0018359700916334987,
        -0.06036939099431038,
        -0.029238346964120865,
        0.05144938826560974,
        0.03244255483150482,
        -0.003312637098133564,
        -0.053073424845933914,
        -0.027140561491250992,
        -0.05922454595565796,
        -0.05552621930837631,
        -0.008531816303730011,
        -0.015486647374927998,
        0.041261907666921616,
        0.047500450164079666,
        0.04936560243368149,
        -0.052201565355062485,
        0.030385907739400864,
        -0.06196761131286621,
        0.04130571335554123,
        0.025050843134522438,
        0.0333285927772522,
        -0.02338106371462345,
        0.02727792039513588,
        0.04259530082345009,
        0.05286968871951103,
        0.056575924158096313,
        0.06062918156385422,
        -0.04149269312620163,
        -0.04051034897565842,
        -0.04760826751589775,
        0.01486055925488472,
        0.003474639495834708,
        -0.04959378391504288,
        0.051788829267024994,
        -0.009653111919760704,
        0.015382027253508568,
        -0.06264109164476395,
        0.025071240961551666,
        0.06240767240524292
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.011066634207963943,
        0.018131844699382782,
        0.05589551478624344,
        -0.010547846555709839,
        -0.050713248550891876,
        -0.052551813423633575,
        0.05611569434404373,
        -0.022821171209216118,
        -0.028285957872867584,
        -0.04956585541367531,
        0.024861296638846397,
        -0.039304349571466446,
        -0.05563802272081375,
        0.041739996522665024,
        -0.05359679460525513,
        0.03658225014805794,
        0.040944524109363556,
        0.05435140058398247,
        0.03984954208135605,
        -0.039694566279649734,
        -0.029201675206422806,
        0.04200569540262222,
        0.020169729366898537,
        0.010308432392776012,
        -0.054664529860019684,
        0.051611363887786865,
        -0.04109082743525505,
        0.028547236695885658,
        -0.051685601472854614,
        0.04991874098777771,
        0.05533572658896446,
        0.014532488770782948,
        0.014122240245342255,
        -0.056167371571063995,
        0.055682405829429626,
        -0.05204949155449867,
        -0.056093230843544006,
        -0.05432448536157608,
        0.052366964519023895,
        -0.05263374373316765,
        0.03675791621208191,
        -0.02120760828256607,
        0.0542769730091095,
        -0.053380418568849564,
        0.04110363870859146,
        -0.026086589321494102,
        0.04793984070420265,
        0.0562921017408371,
        0.05608974024653435,
        -0.04702792689204216,
        0.055416692048311234,
        0.05445804074406624,
        0.013823948800563812,
        -0.040652163326740265,
        0.014468788169324398,
        0.019745048135519028,
        -0.0039011144544929266,
        -0.04665999487042427,
        -0.05615727975964546,
        -0.053099263459444046,
        -0.056172993034124374,
        -0.02738511562347412,
        -0.042156048119068146,
        -0.04702982306480408,
        0.05520640313625336,
        0.04522065818309784,
        -0.04530073329806328,
        0.037237513810396194,
        0.053138650953769684,
        -0.03538775071501732,
        -0.0470227487385273,
        -0.05140646547079086,
        0.05620257928967476,
        0.01856641098856926,
        0.04976017773151398,
        -0.03814796730875969,
        -0.006492175627499819,
        0.05630304291844368,
        0.022956710308790207,
        -0.03874051198363304,
        -0.04220760613679886,
        -0.05608736723661423,
        -0.013238546438515186,
        -0.015024355612695217,
        -0.053257618099451065,
        0.05326254293322563,
        0.027841566130518913,
        -0.05544876307249069,
        -0.04220064356923103,
        -0.02468959055840969,
        -0.05412331968545914,
        -0.03339916095137596,
        -0.04920397698879242,
        -0.03685393184423447,
        0.03313533589243889,
        0.05088821426033974,
        0.053879402577877045,
        -0.05458442494273186,
        -0.00819528941065073,
        0.05402583256363869,
        -0.0511443056166172,
        0.0236480925232172,
        0.05615098401904106,
        0.05516916513442993,
        0.025820892304182053,
        -0.053487006574869156,
        -0.055897921323776245,
        -0.02595427818596363,
        -0.016086235642433167,
        0.033533453941345215,
        0.05476925149559975,
        -0.055169448256492615,
        -0.04787353798747063,
        -0.05018598958849907,
        0.05492809787392616,
        0.02182697504758835,
        -0.030426092445850372,
        0.051184650510549545,
        -0.03231582045555115,
        -0.0005660957540385425,
        -0.04297908395528793,
        -0.055628273636102676,
        0.03886428847908974,
        -0.04279283806681633,
        -0.05611979216337204,
        -0.02710387483239174,
        0.05366356670856476,
        0.0035337419249117374,
        0.0394105389714241,
        0.04978420212864876,
        0.01522736344486475,
        -0.04943512752652168,
        -0.05481352657079697,
        0.056226521730422974,
        -0.04626411572098732,
        -0.052245792001485825,
        0.046615004539489746,
        -0.05454658344388008,
        0.05586295574903488,
        0.05598672851920128,
        0.013639742508530617,
        -0.052535563707351685,
        0.03606204316020012,
        0.05334532633423805,
        0.04990899935364723,
        -0.00976430345326662,
        0.019851848483085632,
        -0.028551345691084862,
        0.0006256198976188898,
        -0.04272440820932388,
        -0.05596890673041344,
        -0.056272026151418686,
        -0.055702585726976395,
        -0.05238126590847969,
        0.05210940167307854,
        0.046983275562524796,
        -0.04822202026844025,
        -0.053983140736818314,
        0.04272204637527466,
        0.01853589154779911,
        0.048240095376968384,
        0.024662859737873077,
        0.05605264753103256,
        0.04999760165810585,
        -0.04708253964781761,
        0.04788462817668915,
        0.006215195171535015,
        0.04291536659002304,
        -0.02904052846133709,
        0.05379960685968399,
        -0.0520201101899147,
        0.055860500782728195,
        -0.05607157573103905,
        0.0555509477853775,
        -0.00012764640268869698,
        -0.011678365059196949,
        0.03078959695994854,
        0.007958215661346912,
        0.05621777102351189,
        -0.05194058641791344,
        -0.05115107446908951,
        0.046903014183044434,
        0.05193791911005974,
        -0.05418526753783226,
        -0.014948775060474873,
        -0.0005415436462499201,
        -0.05518351495265961,
        0.05350329726934433,
        0.027498258277773857,
        0.004794847685843706,
        -0.055756811052560806,
        0.03843633830547333,
        0.05505179986357689,
        0.00851021520793438,
        -0.051902249455451965,
        0.03431503102183342,
        0.04071220010519028,
        0.00797571986913681,
        -0.02355138771235943,
        0.006170375272631645,
        0.05616259574890137,
        0.03111400082707405,
        -0.03506334125995636,
        -0.053032491356134415,
        0.055026378482580185,
        0.005693385377526283,
        0.0562211349606514,
        0.052289143204689026,
        -0.04115747660398483,
        0.04749717190861702,
        -0.03686654567718506,
        0.055812738835811615,
        -0.010106150060892105,
        0.0490497387945652,
        -0.0556178018450737,
        -0.05409660562872887,
        0.05469360202550888,
        0.011233128607273102,
        0.056266799569129944,
        0.05173814296722412,
        0.05057846009731293,
        0.022506393492221832,
        -0.018580788746476173,
        -0.013784807175397873,
        0.0504581518471241,
        -0.007412577047944069,
        0.03706829994916916,
        -0.0539841391146183,
        0.015581094659864902,
        -0.054820284247398376,
        0.0361863449215889,
        0.04332524910569191,
        -0.055910900235176086,
        0.04669782146811485,
        0.054742481559515,
        0.05624697357416153,
        0.054105211049318314,
        -0.03796182945370674,
        -0.05180228874087334,
        0.056279394775629044,
        -0.052788786590099335,
        0.05593720078468323,
        -0.04535982757806778,
        -0.05626195669174194,
        0.04460256174206734,
        -0.04477358236908913,
        0.007444668095558882,
        -0.05617488548159599,
        0.04577897861599922,
        -0.054992541670799255,
        -0.05617792531847954,
        -0.05456048622727394,
        0.015102933160960674,
        -0.055309053510427475,
        0.040395352989435196,
        0.054706793278455734,
        0.05485125258564949,
        0.03269900381565094,
        0.055796124041080475,
        -0.04845119267702103,
        -0.04986317828297615,
        0.003743179142475128,
        0.018181437626481056,
        0.019549259915947914,
        0.020774824544787407,
        0.03448985144495964,
        0.047434572130441666,
        0.05624714493751526,
        0.01947983168065548,
        -0.0535660944879055,
        0.052203092724084854,
        -0.05437973141670227,
        0.05552147328853607,
        -0.054201748222112656,
        -0.005203184671700001,
        0.051941197365522385,
        -0.05485605075955391,
        0.036008697003126144,
        0.04771869629621506,
        0.03967032954096794,
        -0.05613735318183899,
        0.05208806321024895,
        -0.05331965163350105,
        -0.024466415867209435,
        -0.027126872912049294,
        -0.013226550072431564,
        0.0536489374935627,
        0.05295243486762047,
        0.04467519372701645,
        -0.0024388504680246115,
        -0.056021954864263535,
        0.05241050198674202,
        0.010208900086581707,
        0.050903692841529846,
        0.05500997230410576,
        -0.05472757667303085,
        0.05629097670316696,
        0.025459637865424156,
        -0.05591357871890068,
        0.034910839051008224,
        0.055849041789770126,
        -0.04198002070188522,
        -0.000022768070266465656,
        0.05283476039767265,
        0.012590186670422554,
        0.012604295276105404,
        -0.03932179883122444,
        0.055717725306749344,
        0.002204702701419592,
        -0.05602901056408882,
        0.041127752512693405,
        -0.05600474774837494,
        0.05293981358408928,
        0.05279569700360298,
        -0.008897974155843258,
        -0.056181780993938446,
        -0.02541220746934414,
        -0.05455900728702545,
        0.05555691197514534,
        -0.019779494032263756,
        0.044320445507764816,
        -0.048633892089128494,
        0.04652685672044754,
        -0.04767731577157974,
        0.05624442547559738,
        0.02725263126194477,
        -0.025891080498695374,
        0.008379746228456497,
        -0.05498683080077171,
        -0.05613784119486809,
        0.0458083376288414,
        -0.053987979888916016,
        -0.05416787415742874,
        0.04936561733484268,
        0.04851755499839783,
        -0.039670344442129135,
        -0.03995559364557266,
        0.055086780339479446,
        -0.03761287406086922,
        0.05613543093204498,
        0.011253069154918194,
        0.049177657812833786,
        -0.04378943145275116,
        0.015506020747125149,
        0.055635638535022736,
        0.01164911687374115,
        0.009551119059324265,
        0.05539490282535553,
        0.007568967528641224,
        0.05482553690671921,
        0.05488842353224754,
        0.05421113222837448,
        0.03312254324555397,
        -0.05628206953406334,
        -0.04225793853402138,
        0.03237598016858101,
        -0.05630030110478401,
        -0.03270881623029709,
        0.009095094166696072,
        -0.051324088126420975,
        0.050526849925518036,
        0.04926601052284241,
        -0.054214928299188614,
        -0.026586001738905907,
        0.034487489610910416,
        -0.05104849115014076,
        0.05627533048391342,
        -0.02024390920996666,
        -0.04248257353901863,
        -0.05626931041479111,
        -0.020608454942703247,
        -0.044240325689315796,
        -0.029033826664090157,
        0.004515978507697582,
        0.05584007874131203,
        0.05621982738375664,
        0.018085600808262825,
        -0.02400137297809124,
        0.05417676642537117,
        0.05554825812578201,
        -0.0001754086115397513,
        0.05485133081674576,
        -0.041252829134464264,
        0.055515654385089874,
        0.054870739579200745,
        -0.052636757493019104,
        0.040886204689741135,
        -0.056035544723272324,
        -0.006229753140360117,
        -0.05208921432495117,
        -0.03381487727165222,
        0.02004866860806942,
        -0.05403652414679527,
        -0.03571062535047531,
        0.0554974265396595,
        0.055967170745134354,
        0.056276656687259674,
        -0.05630190297961235,
        -0.05624144896864891,
        -0.04002342373132706,
        0.029252316802740097,
        0.052108023315668106,
        -0.051222726702690125,
        0.05620037764310837,
        -0.04807741194963455,
        -0.008471294306218624,
        0.053561143577098846,
        0.01073960680514574,
        0.05516163632273674,
        -0.015724072232842445,
        -0.05246962234377861,
        -0.03881024569272995,
        -0.03774616867303848,
        0.02997332438826561,
        0.056195300072431564,
        -0.05534244328737259,
        0.056192465126514435,
        0.05411317199468613,
        0.05373086780309677,
        0.05572155490517616,
        0.05625145509839058,
        -0.052655454725027084,
        -0.04430164024233818,
        -0.05626985430717468,
        -0.0558185800909996,
        -0.05396332964301109,
        0.003344395663589239,
        0.03172215446829796,
        0.03032008558511734,
        -0.05604607239365578,
        0.025108369067311287,
        -0.02081129513680935,
        0.038391321897506714,
        0.0004488145641516894,
        -0.0038518907967954874,
        -0.054806940257549286,
        -0.05418156087398529,
        -0.01765560358762741,
        0.05561307817697525,
        -0.0560641810297966,
        -0.042561303824186325,
        0.0535256564617157,
        0.05609001964330673,
        0.05609835311770439,
        -0.03615391254425049,
        0.051290322095155716,
        0.04937221109867096,
        -0.052897341549396515,
        0.03521192818880081,
        0.009408875368535519,
        -0.020238438621163368,
        0.027821341529488564,
        0.025378305464982986,
        0.04380778968334198,
        0.055141933262348175,
        -0.03869633749127388,
        0.031796492636203766,
        -0.051885347813367844,
        0.04673037305474281,
        0.05589405447244644,
        0.055487584322690964,
        0.0216684453189373,
        -0.05605423450469971,
        0.028445109724998474,
        0.056249719113111496,
        0.056214723736047745,
        -0.048500217497348785,
        0.05446438118815422,
        -0.0025309366174042225,
        -0.045716289430856705,
        0.05572644621133804,
        0.04846467077732086,
        0.05495535954833031,
        0.038288820534944534,
        0.009461707435548306,
        0.05072377249598503,
        0.05220327153801918,
        0.046576570719480515,
        -0.05533314123749733,
        0.05173160880804062,
        -0.037823937833309174,
        0.007853519171476364,
        -0.04742860049009323,
        -0.042039018124341965,
        -0.05484218895435333,
        0.03961073234677315,
        -0.04807358235120773,
        0.008788836188614368,
        -0.05597469583153725,
        0.020357873290777206,
        0.015278208069503307,
        -0.026956629008054733,
        0.05594246834516525,
        0.054023027420043945,
        0.04492291063070297,
        0.04482421278953552,
        -0.053362440317869186,
        0.01202988438308239,
        0.020113907754421234,
        0.013646209612488747,
        -0.05550568178296089,
        -0.04362550377845764,
        -0.05579933524131775,
        0.04464264214038849,
        0.0312957726418972,
        0.01932978443801403,
        0.05627390742301941,
        -0.045563485473394394,
        0.05587518587708473,
        -0.05624154210090637,
        0.055659931153059006,
        0.05609271675348282
    ],
    [
        0.02688910998404026,
        -0.049821317195892334,
        0.053322695195674896,
        -0.01636510342359543,
        -0.03186909854412079,
        0.00009732298349263147,
        0.04986967146396637,
        -0.05248577147722244,
        0.027227697893977165,
        0.055487606674432755,
        0.050775300711393356,
        -0.0008612617384642363,
        -0.052683815360069275,
        -0.03838855400681496,
        -0.04902210831642151,
        0.040121786296367645,
        -0.019028322771191597,
        0.04911472648382187,
        0.03554563224315643,
        -0.05557510256767273,
        -0.030550679191946983,
        0.052015263587236404,
        0.008169140666723251,
        0.018715323880314827,
        -0.05447852239012718,
        0.049760911613702774,
        0.002772290725260973,
        -0.02519475296139717,
        -0.05570077523589134,
        0.054955895990133286,
        0.05546829104423523,
        0.013531481847167015,
        -0.007630149833858013,
        -0.04987528175115585,
        0.05275098979473114,
        -0.05516617000102997,
        -0.05396726727485657,
        -0.05005224794149399,
        0.05514641851186752,
        -0.055672336369752884,
        -0.04016632214188576,
        -0.04225471615791321,
        0.04181155189871788,
        -0.0442618690431118,
        0.054290615022182465,
        -0.0060974545776844025,
        -0.047405045479536057,
        0.05578962713479996,
        0.05504493787884712,
        0.03183476999402046,
        0.05310959741473198,
        0.05321851745247841,
        0.029362304136157036,
        0.0013002343475818634,
        -0.055647559463977814,
        0.009968046098947525,
        -0.04094240069389343,
        -0.05025216564536095,
        0.0014124619774520397,
        -0.03078327514231205,
        -0.05510852113366127,
        -0.0027897940017282963,
        -0.05298331007361412,
        0.002998161595314741,
        0.05232918635010719,
        -0.008028868585824966,
        -0.05554620921611786,
        0.04391053318977356,
        0.053397633135318756,
        -0.00494921812787652,
        -0.055622000247240067,
        -0.02458345703780651,
        0.05520082637667656,
        -0.03275415673851967,
        0.025775663554668427,
        -0.028832057490944862,
        -0.0381392203271389,
        0.05579095706343651,
        0.036696262657642365,
        0.015310728922486305,
        -0.02421107143163681,
        0.055290766060352325,
        -0.04806044325232506,
        -0.0011387995909899473,
        -0.05527205020189285,
        0.02427339181303978,
        0.045902371406555176,
        0.05426563695073128,
        -0.03600062429904938,
        0.05480846017599106,
        -0.055750660598278046,
        -0.05151321738958359,
        -0.05151937156915665,
        0.03257434070110321,
        -0.014408797957003117,
        0.0397898331284523,
        0.040821485221385956,
        -0.04199781268835068,
        -0.035111598670482635,
        0.03954559564590454,
        -0.05579100176692009,
        0.055743489414453506,
        0.05557424947619438,
        0.025150896981358528,
        0.05491194128990173,
        -0.04678112640976906,
        -0.05578920990228653,
        -0.014523454941809177,
        0.012636718340218067,
        -0.012155313976109028,
        -0.031208010390400887,
        -0.054247211664915085,
        -0.053683631122112274,
        -0.0514138899743557,
        -0.04925307631492615,
        0.0005527273751795292,
        -0.03841478005051613,
        0.015233982354402542,
        -0.05498204380273819,
        0.008401547558605671,
        -0.052554547786712646,
        -0.05494816228747368,
        0.05452610179781914,
        -0.0499127134680748,
        -0.049870189279317856,
        0.05515294894576073,
        0.0440165139734745,
        -0.02803785912692547,
        0.04903561249375343,
        -0.035941965878009796,
        0.018671192228794098,
        -0.053934261202812195,
        -0.0533636175096035,
        0.0530368946492672,
        -0.021804869174957275,
        -0.05531968176364899,
        0.05166269838809967,
        -0.055721551179885864,
        0.04697813093662262,
        0.05441340431571007,
        -0.004258786793798208,
        -0.053282659500837326,
        -0.0071802386082708836,
        0.05539312586188316,
        0.054057687520980835,
        -0.05528678372502327,
        0.05571368336677551,
        -0.04185514152050018,
        -0.05578400567173958,
        0.039195794612169266,
        -0.05564465746283531,
        -0.05574057623744011,
        -0.052256930619478226,
        0.03918277472257614,
        0.05046215280890465,
        -0.011214670725166798,
        -0.04936201497912407,
        -0.0398453064262867,
        0.05169905349612236,
        0.053717609494924545,
        0.05578886345028877,
        -0.04470634460449219,
        0.05505584552884102,
        0.05556691065430641,
        -0.05190354585647583,
        0.052752573043107986,
        -0.010110401548445225,
        -0.006760785356163979,
        -0.007696095854043961,
        0.05476962774991989,
        -0.03937102481722832,
        0.05075505003333092,
        -0.055292386561632156,
        0.055693600326776505,
        0.053572144359350204,
        0.014986406080424786,
        0.04344953969120979,
        0.032135725021362305,
        0.05306345969438553,
        -0.04631907865405083,
        -0.05440115928649902,
        0.0557122677564621,
        0.05046047642827034,
        -0.042717598378658295,
        0.052248045802116394,
        -0.019351454451680183,
        -0.051028572022914886,
        0.046361133456230164,
        -0.03941412642598152,
        -0.05127297341823578,
        -0.05072508752346039,
        0.03137721121311188,
        0.05150996893644333,
        0.0008129961206577718,
        -0.05470865219831467,
        -0.0014589435886591673,
        -0.04023810476064682,
        -0.01243561040610075,
        -0.004878425970673561,
        -0.019571887329220772,
        0.05541164055466652,
        0.005621262826025486,
        -0.04676803946495056,
        -0.035915739834308624,
        0.04651074856519699,
        -0.008882122114300728,
        0.055617574602365494,
        0.030521484091877937,
        -0.04829796031117439,
        0.05389083921909332,
        -0.051991160959005356,
        0.055073920637369156,
        -0.03920002654194832,
        0.05111085996031761,
        -0.05149496719241142,
        0.03332390636205673,
        0.054520659148693085,
        -0.012046699412167072,
        0.055665090680122375,
        0.040425971150398254,
        0.055612388998270035,
        0.05468720942735672,
        -0.05181180313229561,
        -0.05160515382885933,
        0.05577583983540535,
        -0.025351181626319885,
        0.04383685439825058,
        -0.031435780227184296,
        0.027806513011455536,
        -0.05331752076745033,
        0.04117468744516373,
        -0.032125554978847504,
        -0.05026322230696678,
        0.053089987486600876,
        -0.045229293406009674,
        0.05530587583780289,
        0.04762684553861618,
        0.00853479653596878,
        0.00525638135150075,
        0.055791981518268585,
        0.017375174909830093,
        -0.04816577956080437,
        -0.051764704287052155,
        -0.05578257143497467,
        0.0381794273853302,
        -0.04232547804713249,
        0.05281437560915947,
        0.01704959198832512,
        -0.0013735868269577622,
        -0.05158580467104912,
        -0.05444569140672684,
        -0.054883405566215515,
        0.03233921155333519,
        -0.055209409445524216,
        -0.05136140063405037,
        0.04847574606537819,
        0.05574467033147812,
        0.04227244481444359,
        0.048442594707012177,
        0.05374589562416077,
        -0.037857819348573685,
        -0.0001892318541649729,
        0.015663020312786102,
        0.04725392535328865,
        -0.012058191001415253,
        0.043421100825071335,
        0.05567162111401558,
        0.05561666935682297,
        0.05414609611034393,
        -0.055123262107372284,
        -0.042594052851200104,
        -0.055763620883226395,
        0.05530388653278351,
        -0.03920911252498627,
        -0.051771149039268494,
        -0.006797770969569683,
        -0.05460200086236,
        0.0091897277161479,
        -0.004963455256074667,
        -0.055331628769636154,
        -0.053999461233615875,
        0.05255836620926857,
        -0.03613300248980522,
        -0.045096900314092636,
        0.011201953515410423,
        -0.052938301116228104,
        0.0360567644238472,
        -0.013009955175220966,
        0.03270868957042694,
        0.05576040595769882,
        -0.051173944026231766,
        0.054652027785778046,
        0.0519336462020874,
        0.04975641891360283,
        0.04572504386305809,
        -0.052283622324466705,
        0.05575653165578842,
        0.02109086886048317,
        -0.028285866603255272,
        0.04723827540874481,
        -0.010722966864705086,
        0.026091722771525383,
        0.024871429428458214,
        0.05541394650936127,
        -0.05112889036536217,
        0.05269980803132057,
        0.04522654041647911,
        0.04098280519247055,
        -0.011950011365115643,
        -0.05494042858481407,
        0.05049317702651024,
        -0.046076588332653046,
        -0.008358236402273178,
        0.055681563913822174,
        -0.050199784338474274,
        -0.05456201732158661,
        -0.02321830578148365,
        -0.05214037746191025,
        0.054563917219638824,
        0.03470885008573532,
        0.03520357981324196,
        0.0027367952279746532,
        0.023135056719183922,
        0.0029961399268358946,
        0.05552764609456062,
        -0.040870293974876404,
        -0.03779909014701843,
        -0.026508865877985954,
        -0.04761778935790062,
        -0.05473446473479271,
        0.054778099060058594,
        -0.01150300819426775,
        -0.05563948303461075,
        0.038522813469171524,
        0.04943446069955826,
        -0.008625531569123268,
        -0.04408634081482887,
        0.05523529648780823,
        -0.04876069352030754,
        0.045405369251966476,
        0.053431395441293716,
        0.00505144614726305,
        0.024613482877612114,
        0.029721137136220932,
        0.055124081671237946,
        0.05358137935400009,
        0.024102428928017616,
        0.05285895988345146,
        -0.01706448197364807,
        0.04934397712349892,
        0.015410256572067738,
        0.04814189299941063,
        -0.009281647391617298,
        -0.055755238980054855,
        -0.001238226774148643,
        0.047904450446367264,
        -0.05578135326504707,
        -0.055138375610113144,
        -0.05448568984866142,
        -0.028815720230340958,
        0.0545366145670414,
        0.04990004748106003,
        -0.054215192794799805,
        0.03503164276480675,
        -0.04188849404454231,
        -0.04449804499745369,
        0.05573916807770729,
        -0.03428739681839943,
        -0.04853713884949684,
        -0.055392973124980927,
        -0.04961588978767395,
        -0.048463575541973114,
        0.05362341180443764,
        -0.03942391648888588,
        0.03968152403831482,
        0.027025356888771057,
        -0.055427271872758865,
        0.04361378028988838,
        0.05524784326553345,
        0.05364973470568657,
        0.013041040860116482,
        -0.01978287287056446,
        -0.03876791149377823,
        0.04902564734220505,
        0.052646081894636154,
        -0.050867728888988495,
        0.03877568617463112,
        -0.05207004398107529,
        -0.01861537992954254,
        -0.055165864527225494,
        -0.05485054478049278,
        -0.04974743351340294,
        0.011447812430560589,
        -0.01709635742008686,
        0.052637096494436264,
        0.05368085205554962,
        -0.005782301537692547,
        -0.05564642697572708,
        -0.05557331442832947,
        -0.053401898592710495,
        0.010796567425131798,
        0.05577728897333145,
        -0.014739246107637882,
        0.04336302727460861,
        -0.03870773687958717,
        -0.0077927447855472565,
        0.05117911100387573,
        -0.05492840334773064,
        0.04472758620977402,
        -0.04297472536563873,
        0.05216679722070694,
        0.05131296068429947,
        -0.05184604972600937,
        -0.0345628447830677,
        0.05540035292506218,
        -0.05553062632679939,
        0.05551031604409218,
        0.05574558675289154,
        0.054023463279008865,
        0.05331335589289665,
        0.017545849084854126,
        0.011218328028917313,
        0.041355349123477936,
        -0.055284541100263596,
        -0.054716769605875015,
        -0.031869567930698395,
        0.0013519789790734649,
        -0.05283863842487335,
        -0.053445495665073395,
        -0.05578276142477989,
        -0.053148433566093445,
        -0.042254768311977386,
        0.00793443527072668,
        -0.037568192929029465,
        0.045398179441690445,
        -0.03827574476599693,
        -0.04976779595017433,
        0.04928424581885338,
        0.005234795156866312,
        -0.05557796731591225,
        -0.05156096816062927,
        0.05572177469730377,
        0.05374469980597496,
        0.05422649532556534,
        -0.05439913645386696,
        -0.044755350798368454,
        0.047417353838682175,
        -0.05548804625868797,
        0.05111416056752205,
        0.012588590383529663,
        -0.050581853836774826,
        0.0532846599817276,
        0.053905192762613297,
        0.0037782592698931694,
        0.05075444281101227,
        -0.049182064831256866,
        0.009659475646913052,
        0.0019512245198711753,
        -0.025592628866434097,
        0.05576787143945694,
        0.0537608377635479,
        0.034633152186870575,
        0.03492323309183121,
        0.04423707351088524,
        0.05579119175672531,
        0.055763207376003265,
        -0.05366713926196098,
        0.05573917180299759,
        -0.03797638416290283,
        -0.05276044085621834,
        0.04062364995479584,
        0.03991473838686943,
        0.05348489060997963,
        0.013605225831270218,
        -0.03396521136164665,
        0.027624838054180145,
        0.02359161153435707,
        0.055580127984285355,
        -0.0555519163608551,
        0.050299644470214844,
        -0.052956074476242065,
        0.05525211617350578,
        0.04067717865109444,
        -0.0032907570712268353,
        -0.04553210362792015,
        0.04881199076771736,
        0.05469878762960434,
        0.04635406285524368,
        -0.05436034873127937,
        0.04302738234400749,
        -0.049155618995428085,
        -0.055518168956041336,
        -0.0156356543302536,
        0.04757607728242874,
        0.052379310131073,
        0.0430746003985405,
        -0.054788313806056976,
        0.019726937636733055,
        0.05205819383263588,
        0.0489250048995018,
        -0.03419407829642296,
        -0.04768366366624832,
        -0.05494344234466553,
        0.048548124730587006,
        0.05497113987803459,
        0.016118448227643967,
        0.055687207728624344,
        0.04421622306108475,
        0.05092616379261017,
        -0.055763158947229385,
        0.055791713297367096,
        0.05354147404432297
    ],
    [
        0.05279908701777458,
        -0.014035201631486416,
        0.04572553187608719,
        -0.019620664417743683,
        -0.04092874005436897,
        0.002882604254409671,
        0.0518207848072052,
        -0.051516950130462646,
        -0.007625283673405647,
        0.020884543657302856,
        0.008917910046875477,
        0.039360832422971725,
        -0.05323508009314537,
        -0.03015849180519581,
        -0.052932366728782654,
        0.05173618346452713,
        0.047918811440467834,
        0.047780778259038925,
        0.05143967643380165,
        -0.05327540263533592,
        -0.04588344320654869,
        0.04798087477684021,
        0.026839841157197952,
        0.018162420019507408,
        -0.053028590977191925,
        -0.029237832874059677,
        0.03770453482866287,
        0.01249160896986723,
        -0.05304081365466118,
        0.053248532116413116,
        0.0496012419462204,
        0.05151874199509621,
        0.0003498169535305351,
        -0.053075797855854034,
        0.05135062336921692,
        -0.04990876466035843,
        -0.05151093751192093,
        -0.05006949603557587,
        0.05319932848215103,
        -0.049604758620262146,
        0.0512939877808094,
        0.04100392758846283,
        0.04896021634340286,
        -0.04828530550003052,
        0.04885692149400711,
        -0.005729310214519501,
        -0.04395509138703346,
        0.05327816680073738,
        0.05313200131058693,
        -0.038093190640211105,
        0.053254976868629456,
        0.05324535816907883,
        0.04436074569821358,
        0.04276292398571968,
        -0.053067877888679504,
        0.03737152740359306,
        0.015887992456555367,
        -0.03891385719180107,
        -0.03485037386417389,
        -0.050779882818460464,
        -0.053266990929841995,
        0.022652599960565567,
        -0.05246596038341522,
        0.00016017004963941872,
        0.05299365893006325,
        -0.039270706474781036,
        -0.052741639316082,
        0.041173867881298065,
        0.00678734527900815,
        0.04965802654623985,
        -0.05206809192895889,
        -0.040037158876657486,
        0.05253652483224869,
        -0.043271880596876144,
        0.03368305414915085,
        -0.025477876886725426,
        -0.01835440844297409,
        0.053278278559446335,
        0.023133961483836174,
        0.021342283114790916,
        -0.05214656516909599,
        0.05053400248289108,
        -0.05165921151638031,
        0.048115767538547516,
        -0.05270927771925926,
        0.03136001527309418,
        0.046985648572444916,
        0.0012134368298575282,
        -0.04986518248915672,
        0.03856293112039566,
        -0.053245432674884796,
        -0.04926728084683418,
        -0.05177448317408562,
        0.005727267358452082,
        0.013117052614688873,
        0.053041476756334305,
        -0.04626411199569702,
        -0.05298575013875961,
        -0.02570275217294693,
        0.04934130609035492,
        -0.053275104612112045,
        0.053260017186403275,
        0.05323045328259468,
        0.05323052778840065,
        0.048972226679325104,
        -0.052619386464357376,
        -0.05321161821484566,
        -0.011974013410508633,
        0.05246422439813614,
        -0.038889408111572266,
        -0.043536312878131866,
        -0.05312461778521538,
        -0.047745201736688614,
        -0.04899150878190994,
        0.016185125336050987,
        0.017024358734488487,
        -0.022569023072719574,
        0.013467518612742424,
        -0.04974588751792908,
        -0.04483867436647415,
        -0.052846502512693405,
        -0.007448785938322544,
        0.053219668567180634,
        -0.0036343690007925034,
        -0.05294167995452881,
        0.0522649884223938,
        0.027747565880417824,
        0.01570303924381733,
        0.05071743205189705,
        0.041140295565128326,
        0.05186077952384949,
        -0.053054820746183395,
        -0.052882399410009384,
        0.0528407096862793,
        -0.026582535356283188,
        -0.05070493742823601,
        0.05244576930999756,
        -0.04699460789561272,
        -0.00041130089084617794,
        0.052910156548023224,
        -0.039540309458971024,
        -0.05095137655735016,
        0.04097100347280502,
        0.05211186408996582,
        0.046417560428380966,
        -0.04630076512694359,
        0.051168836653232574,
        -0.04974709823727608,
        -0.052875764667987823,
        0.01649857684969902,
        -0.05005951225757599,
        -0.05326171591877937,
        -0.03650140017271042,
        -0.04062160104513168,
        -0.04136163741350174,
        0.019399164244532585,
        -0.04715179651975632,
        0.0038697111885994673,
        0.0512000136077404,
        0.04630105197429657,
        0.053236912935972214,
        -0.04817260801792145,
        0.053125083446502686,
        0.049627602100372314,
        0.038226787000894547,
        0.04953072965145111,
        -0.02448154054582119,
        -0.034542687237262726,
        -0.05312200263142586,
        0.052870914340019226,
        -0.047100502997636795,
        0.05011274665594101,
        -0.05075777694582939,
        0.053163476288318634,
        0.004541677888482809,
        -0.03952142596244812,
        0.048271454870700836,
        0.05137895792722702,
        0.05248183384537697,
        -0.05324983596801758,
        -0.04538911208510399,
        0.05325024202466011,
        0.048434965312480927,
        -0.05263080447912216,
        0.024339847266674042,
        -0.05191871151328087,
        -0.04938142001628876,
        0.051476746797561646,
        -0.04654157534241676,
        -0.04108668118715286,
        -0.05175756290555,
        0.04929722845554352,
        0.0434170737862587,
        0.030640624463558197,
        -0.052704326808452606,
        0.04172713682055473,
        -0.05229727551341057,
        -0.05294022709131241,
        -0.04327378422021866,
        0.04307084158062935,
        0.053204845637083054,
        -0.011162547394633293,
        -0.05263494327664375,
        -0.05209489166736603,
        0.01671966351568699,
        -0.043423574417829514,
        0.053267549723386765,
        0.052610915154218674,
        -0.03952573984861374,
        0.05305640026926994,
        -0.048155635595321655,
        0.053258150815963745,
        0.05021987855434418,
        0.05212457478046417,
        -0.05312467738986015,
        -0.05273975431919098,
        0.03332211449742317,
        0.029612354934215546,
        0.05145999416708946,
        0.03198390081524849,
        0.03589615225791931,
        0.052133869379758835,
        -0.023472873494029045,
        -0.02399200014770031,
        0.05327608436346054,
        -0.018619120121002197,
        0.05174469202756882,
        -0.04471135139465332,
        0.05026368424296379,
        -0.05198776721954346,
        0.03578272834420204,
        0.03535798192024231,
        -0.051537808030843735,
        0.04823444038629532,
        0.006382918916642666,
        0.05202546343207359,
        0.05241847783327103,
        0.005809984169900417,
        -0.03147945553064346,
        0.05327789857983589,
        0.03737572208046913,
        -0.04149940237402916,
        -0.05314785987138748,
        -0.053276944905519485,
        0.05305427312850952,
        -0.05231558158993721,
        0.03402610123157501,
        -0.05319429561495781,
        -0.030134549364447594,
        -0.05319802463054657,
        -0.04931968078017235,
        -0.053141120821237564,
        -0.046617984771728516,
        -0.05234989896416664,
        -0.028548479080200195,
        0.05254462733864784,
        0.01672804355621338,
        0.026654882356524467,
        0.047058336436748505,
        0.008714575320482254,
        0.038823459297418594,
        -0.043113913387060165,
        0.041436705738306046,
        0.022752754390239716,
        -0.037777379155159,
        0.03158217668533325,
        0.053263042122125626,
        0.05321980640292168,
        -0.023035230115056038,
        -0.05053379014134407,
        0.04453736171126366,
        -0.05072730407118797,
        0.052847735583782196,
        -0.04580492898821831,
        -0.012725414708256721,
        0.04367286339402199,
        -0.04816148802638054,
        0.035235531628131866,
        0.05123249813914299,
        -0.05105144903063774,
        -0.05292268469929695,
        0.05314876511693001,
        -0.049879688769578934,
        -0.012113518081605434,
        0.010619614273309708,
        0.04539334401488304,
        0.05192355439066887,
        0.042802296578884125,
        0.052973028272390366,
        0.047800105065107346,
        -0.031030256301164627,
        0.05129297077655792,
        0.0180952288210392,
        0.052576031535863876,
        0.05323825404047966,
        -0.05211705341935158,
        0.05326969549059868,
        0.035246338695287704,
        -0.050759002566337585,
        0.05172038823366165,
        0.01232047937810421,
        -0.04747626185417175,
        0.03828218951821327,
        0.051211126148700714,
        -0.04535632207989693,
        0.02665039896965027,
        0.04646891728043556,
        0.04883324354887009,
        0.007327874656766653,
        -0.045318812131881714,
        0.052693724632263184,
        -0.04937369003891945,
        0.04682435095310211,
        0.053185638040304184,
        0.039549604058265686,
        -0.05297776311635971,
        0.014327013865113258,
        -0.0422130785882473,
        0.05246300995349884,
        0.008230605162680149,
        0.051463913172483444,
        -0.025696612894535065,
        0.05109767988324165,
        0.0010225730948150158,
        0.053257305175065994,
        -0.02878345362842083,
        0.03920155018568039,
        -0.020952997729182243,
        -0.05085567757487297,
        -0.05313299596309662,
        0.043591029942035675,
        -0.04396628215909004,
        -0.05313169211149216,
        0.017448877915740013,
        0.050102490931749344,
        -0.04485567659139633,
        -0.02726828306913376,
        0.020787477493286133,
        0.006260124035179615,
        0.05088202655315399,
        0.027479777112603188,
        0.04482525959610939,
        -0.017690537497401237,
        0.052885353565216064,
        0.05055614188313484,
        0.047217581421136856,
        0.04993075132369995,
        0.05314653366804123,
        -0.00600090529769659,
        0.03884302079677582,
        0.05310185253620148,
        -0.03344542533159256,
        -0.03814658895134926,
        -0.053247176110744476,
        0.007720754481852055,
        0.042886074632406235,
        -0.05322403088212013,
        -0.05260324850678444,
        -0.026476094499230385,
        -0.02006840892136097,
        0.051070794463157654,
        0.0517168864607811,
        -0.04833494499325752,
        -0.03355146571993828,
        -0.027165114879608154,
        -0.05231187865138054,
        0.05327388644218445,
        -0.053045522421598434,
        -0.042035430669784546,
        -0.053123652935028076,
        -0.053033675998449326,
        -0.05167090520262718,
        -0.021845296025276184,
        -0.042696401476860046,
        0.04956598952412605,
        0.05200612545013428,
        -0.03725433349609375,
        0.044478196650743484,
        0.053020816296339035,
        0.053261883556842804,
        -0.04612980782985687,
        0.04644646868109703,
        -0.052405115216970444,
        0.04949653521180153,
        0.05266282707452774,
        -0.05233787000179291,
        0.014921027235686779,
        -0.05109948664903641,
        -0.05086703971028328,
        -0.05319780111312866,
        -0.050973840057849884,
        -0.04129653051495552,
        -0.028599029406905174,
        -0.028867162764072418,
        0.05269115790724754,
        0.0527215451002121,
        0.05154888331890106,
        -0.0532754547894001,
        -0.05309545248746872,
        -0.04051593318581581,
        0.034497473388910294,
        0.05325708165764809,
        0.0112320426851511,
        -0.011099422350525856,
        0.03856828436255455,
        0.04829958453774452,
        0.027109859511256218,
        -0.05315820127725601,
        0.05302558094263077,
        -0.012226101942360401,
        -0.03111080639064312,
        -0.0036738396156579256,
        0.03062284179031849,
        -0.0418093316257,
        0.05322238802909851,
        -0.053111374378204346,
        0.04855984076857567,
        0.05231272801756859,
        0.052786897867918015,
        0.05327072739601135,
        0.05293746292591095,
        -0.02964196726679802,
        0.04881124943494797,
        -0.05326024442911148,
        -0.05325374752283096,
        -0.006062960717827082,
        -0.034584157168865204,
        0.0487879179418087,
        -0.05286035314202309,
        -0.05322809889912605,
        -0.03899683803319931,
        -0.010940903797745705,
        0.04511674493551254,
        -0.029899438843131065,
        0.05112038180232048,
        -0.05139894410967827,
        -0.053119927644729614,
        -0.02186490222811699,
        0.052957382053136826,
        -0.05275411531329155,
        -0.00552904698997736,
        0.053191833198070526,
        0.0532364584505558,
        0.053068406879901886,
        -0.05176195874810219,
        0.004461419768631458,
        0.049410849809646606,
        -0.04679805412888527,
        0.024371672421693802,
        0.05197920277714729,
        -0.05322737246751785,
        0.046383198350667953,
        0.05212952196598053,
        0.050209324806928635,
        0.05220058560371399,
        -0.050432853400707245,
        0.003384134965017438,
        -0.052969858050346375,
        0.03128305822610855,
        0.05327504500746727,
        0.05219712480902672,
        -0.010926254093647003,
        -0.01654515415430069,
        0.040020719170570374,
        0.05327661707997322,
        0.05324853956699371,
        -0.05207968130707741,
        0.05327461287379265,
        -0.015892906114459038,
        -0.05240334942936897,
        0.052699532359838486,
        0.038018934428691864,
        0.051850996911525726,
        0.03765183687210083,
        -0.0532250814139843,
        0.027240943163633347,
        0.05055934563279152,
        0.053249578922986984,
        -0.053199976682662964,
        0.050740353763103485,
        -0.029012229293584824,
        0.04814974218606949,
        0.002396472031250596,
        0.024624692276120186,
        -0.021638302132487297,
        0.052837882190942764,
        0.05322014540433884,
        -0.02459438145160675,
        -0.05280017852783203,
        -0.042355358600616455,
        -0.018227703869342804,
        -0.05279514566063881,
        0.05319415032863617,
        0.05267797037959099,
        0.04652731493115425,
        0.03812287002801895,
        -0.05326123535633087,
        0.041068173944950104,
        0.05074964836239815,
        0.049366749823093414,
        -0.05097022280097008,
        -0.0531427264213562,
        -0.05315770208835602,
        0.05284838005900383,
        0.05308521166443825,
        0.04210102930665016,
        0.05327810347080231,
        -0.029918856918811798,
        0.052696313709020615,
        -0.05327749624848366,
        0.053271688520908356,
        0.05303948000073433
    ],
    [
        0.006286670919507742,
        0.06246742978692055,
        0.049814749509096146,
        0.024625424295663834,
        0.02967836521565914,
        0.04532401263713837,
        0.06417630612850189,
        0.05124596878886223,
        -0.027522297576069832,
        0.04493800550699234,
        0.05947461351752281,
        0.009023340418934822,
        -0.05879829078912735,
        -0.016037890687584877,
        -0.06295788288116455,
        0.045066434890031815,
        -0.03507237136363983,
        0.037843141704797745,
        0.02297247014939785,
        -0.05501561611890793,
        0.003562148194760084,
        0.007853600196540356,
        0.005332662258297205,
        0.00657130079343915,
        0.048572417348623276,
        0.06125595420598984,
        0.05958589166402817,
        -0.043843723833560944,
        -0.05268255993723869,
        -0.0357595756649971,
        0.05471179634332657,
        -0.05326281115412712,
        0.02396422065794468,
        -0.056949298828840256,
        0.037639372050762177,
        -0.045563362538814545,
        -0.06020945683121681,
        -0.005890403408557177,
        0.051344532519578934,
        0.04262007400393486,
        -0.05602322518825531,
        0.034390609711408615,
        0.05584562197327614,
        -0.06326979398727417,
        0.038293883204460144,
        0.05563366040587425,
        0.013567715883255005,
        0.06326264888048172,
        -0.0009888213826343417,
        0.018764695152640343,
        0.06313638389110565,
        0.047475218772888184,
        0.05420523136854172,
        -0.03930256515741348,
        -0.02351987175643444,
        0.013395116664469242,
        0.019729146733880043,
        -0.013756475411355495,
        -0.05924880877137184,
        -0.041398026049137115,
        -0.06371884047985077,
        -0.00712732644751668,
        -0.02864491194486618,
        0.048629071563482285,
        -0.024779079481959343,
        0.038449931889772415,
        0.03258339315652847,
        -0.04722246155142784,
        -0.051264438778162,
        -0.0403735488653183,
        -0.06168648600578308,
        -0.06480596959590912,
        0.06022154539823532,
        0.03351128101348877,
        0.04052583500742912,
        -0.04805696755647659,
        -0.007149603683501482,
        0.06445794552564621,
        -0.03649739548563957,
        -0.04584677889943123,
        -0.0008513776119798422,
        0.05514173582196236,
        -0.04449005797505379,
        -0.0005051610060036182,
        -0.05332241952419281,
        0.026028087362647057,
        0.03024890273809433,
        -0.06122634932398796,
        0.0025947862304747105,
        0.0643547847867012,
        -0.06070069223642349,
        -0.004518549423664808,
        -0.046498458832502365,
        0.02676725573837757,
        0.012605779804289341,
        0.0008068332099355757,
        0.010054082609713078,
        -0.043309226632118225,
        0.06082930043339729,
        0.013931323774158955,
        -0.06123745068907738,
        -0.03300025314092636,
        0.056493546813726425,
        0.05891242250800133,
        0.008580118417739868,
        0.02380705066025257,
        -0.05344100296497345,
        -0.017859743908047676,
        -0.03263471648097038,
        -0.016348043456673622,
        -0.005982772447168827,
        -0.04545024782419205,
        -0.054524559527635574,
        -0.05587029457092285,
        0.009151467122137547,
        0.012667686678469181,
        0.0008085985318757594,
        -0.05055229738354683,
        0.027256613597273827,
        -0.002007097704336047,
        -0.02922108769416809,
        0.04676178842782974,
        0.0404026061296463,
        -0.05739470571279526,
        -0.0637279748916626,
        -0.04120291769504547,
        -0.05800601840019226,
        -0.050542768090963364,
        0.048712097108364105,
        0.022358380258083344,
        0.04669084772467613,
        -0.05420316383242607,
        -0.05596359819173813,
        0.04557221382856369,
        -0.05907340720295906,
        -0.05310600996017456,
        0.03262238949537277,
        -0.05220379680395126,
        -0.0324222669005394,
        0.0643814280629158,
        0.03672343119978905,
        -0.011968296021223068,
        0.026978932321071625,
        0.05954565107822418,
        0.01723482459783554,
        -0.004903027322143316,
        0.059890445321798325,
        -0.0630774050951004,
        0.017071299254894257,
        0.04278140887618065,
        -0.03151058033108711,
        -0.0610542818903923,
        -0.06116195768117905,
        -0.05296742171049118,
        0.011398234404623508,
        -0.03711356222629547,
        0.009747585281729698,
        -0.003432286437600851,
        0.03537925332784653,
        0.04647854343056679,
        0.04259059205651283,
        -0.022491905838251114,
        0.058007314801216125,
        -0.054230593144893646,
        -0.022161832079291344,
        0.004767993465065956,
        -0.05558498948812485,
        -0.029521087184548378,
        -0.03660881519317627,
        0.053861964493989944,
        -0.033832333981990814,
        0.02546328864991665,
        -0.061299409717321396,
        0.0010082743829116225,
        -0.03615230694413185,
        -0.052777376025915146,
        -0.039322979748249054,
        -0.009738505817949772,
        0.060381874442100525,
        -0.060876842588186264,
        0.05843314155936241,
        0.064246267080307,
        0.0572175607085228,
        0.016703983768820763,
        0.013086512684822083,
        -0.020535707473754883,
        0.018007034435868263,
        0.06291481107473373,
        -0.03833625838160515,
        -0.02541167661547661,
        -0.025451229885220528,
        -0.022594347596168518,
        -0.0337977260351181,
        -0.06178469583392143,
        -0.01421346515417099,
        0.03676122426986694,
        0.007775920908898115,
        -0.05663091316819191,
        -0.055409595370292664,
        0.018590036779642105,
        -0.05513885244727135,
        0.03790004551410675,
        0.01034512184560299,
        -0.05292627960443497,
        -0.04547698795795441,
        -0.05539122223854065,
        0.06029205396771431,
        0.02083381824195385,
        0.046319954097270966,
        0.01655079424381256,
        -0.05002060905098915,
        0.06104334071278572,
        0.012020930647850037,
        0.04756097123026848,
        0.009085342288017273,
        -0.05471988767385483,
        -0.0010809792438521981,
        0.0219244547188282,
        0.055325571447610855,
        0.025686830282211304,
        0.0588410310447216,
        0.05816708505153656,
        -0.037422630935907364,
        -0.03491641581058502,
        0.06492959707975388,
        0.025700004771351814,
        -0.01912328600883484,
        0.01912902668118477,
        0.03997161239385605,
        -0.06314820051193237,
        0.046470701694488525,
        -0.049522195011377335,
        -0.06104063615202904,
        0.04501255974173546,
        0.017177093774080276,
        0.05739248916506767,
        0.0504932701587677,
        0.05464059114456177,
        0.043303001672029495,
        0.06308283656835556,
        0.06000463664531708,
        0.059730492532253265,
        -0.06174759194254875,
        0.0070918044075369835,
        0.04151119664311409,
        0.009924397803843021,
        -0.04735831543803215,
        -0.048270657658576965,
        0.021725164726376534,
        -0.06463927775621414,
        0.004873508121818304,
        -0.061490852385759354,
        -0.055578261613845825,
        -0.05728565901517868,
        -0.057871319353580475,
        0.0639914721250534,
        -0.028738070279359818,
        0.01143019087612629,
        0.06356275826692581,
        0.05852775275707245,
        0.06254887580871582,
        0.041943203657865524,
        0.04766899719834328,
        -0.041248176246881485,
        -0.02429768443107605,
        0.048436615616083145,
        0.04947037994861603,
        0.0420340932905674,
        0.06063359975814819,
        -0.054370980709791183,
        -0.014959929510951042,
        -0.04961426928639412,
        0.056890737265348434,
        -0.041616275906562805,
        0.054874490946531296,
        0.05235104635357857,
        -0.06292774528265,
        0.01131762657314539,
        -0.017532529309391975,
        -0.05928180366754532,
        -0.06442961096763611,
        -0.025419017300009727,
        -0.023591799661517143,
        0.01558773498982191,
        -0.008802776224911213,
        -0.042652878910303116,
        0.05046672746539116,
        -0.027046365663409233,
        -0.009345628321170807,
        0.05537079647183418,
        -0.03917428478598595,
        0.0542762391269207,
        -0.030208835378289223,
        0.04564827308058739,
        0.05520044267177582,
        -0.042499419301748276,
        0.05863015726208687,
        0.029064606875181198,
        -0.011013236828148365,
        0.04220380261540413,
        0.0009075801935978234,
        0.05185332149267197,
        -0.05862047150731087,
        0.0559098981320858,
        -0.03965320065617561,
        -0.020052822306752205,
        0.041511863470077515,
        0.04896865785121918,
        0.03006226383149624,
        -0.05474600940942764,
        0.05172174423933029,
        -0.006633368320763111,
        -0.027375254780054092,
        0.03977484628558159,
        -0.05568821728229523,
        -0.056149017065763474,
        0.025828780606389046,
        -0.013841729611158371,
        0.021184640005230904,
        -0.05091548711061478,
        0.008250654675066471,
        -0.00995323620736599,
        0.033329982310533524,
        0.04666818305850029,
        0.06302797794342041,
        0.023007618263363838,
        -0.026194393634796143,
        -0.05730148404836655,
        0.03602883964776993,
        -0.05592723190784454,
        0.004853970371186733,
        0.02297397516667843,
        -0.014404445886611938,
        -0.04818442091345787,
        0.06317204236984253,
        -0.0018693163292482495,
        0.01708975061774254,
        0.0620591938495636,
        -0.02887360006570816,
        0.05557407811284065,
        0.022962411865592003,
        0.056363947689533234,
        -0.050573550164699554,
        0.034095052629709244,
        0.05952972546219826,
        0.025035498663783073,
        0.014798835851252079,
        0.05534679815173149,
        -0.04883848503232002,
        0.06205764785408974,
        -0.06056506186723709,
        0.01691497676074505,
        -0.05500207468867302,
        -0.0639539584517479,
        0.029016105458140373,
        0.048158299177885056,
        -0.02694612182676792,
        -0.04338931664824486,
        0.04386058449745178,
        -0.06139669567346573,
        0.05762975662946701,
        -0.02424987033009529,
        -0.054584044963121414,
        -0.0478583425283432,
        -0.012569166719913483,
        -0.05602399259805679,
        0.06392618268728256,
        -0.04591790959239006,
        0.0462457574903965,
        -0.06437382847070694,
        -0.023139486089348793,
        -0.05726974457502365,
        0.05200023576617241,
        0.05821545422077179,
        0.050723496824502945,
        0.050421491265296936,
        0.05397868901491165,
        0.06106577813625336,
        -0.027275381609797478,
        0.03651615232229233,
        -0.04884599521756172,
        -0.024419717490673065,
        -0.06030537560582161,
        0.025001903995871544,
        0.06457705050706863,
        -0.014294271357357502,
        -0.025297150015830994,
        -0.06257758289575577,
        -0.05476953461766243,
        -0.0314064584672451,
        -0.052674293518066406,
        0.057449012994766235,
        0.01061083935201168,
        -0.027461454272270203,
        -0.05200386419892311,
        0.0607650987803936,
        0.017831381410360336,
        -0.03944683447480202,
        -0.017567193135619164,
        -0.056065939366817474,
        -0.0031364636961370707,
        0.05591197311878204,
        -0.0015836728271096945,
        -0.0008903422276489437,
        -0.03148747235536575,
        0.060706231743097305,
        0.04591970518231392,
        -0.053838036954402924,
        0.05707240849733353,
        -0.04670492932200432,
        -0.009158625267446041,
        -0.04872874915599823,
        0.05642136558890343,
        0.033079348504543304,
        0.05683167278766632,
        -0.021106095984578133,
        0.04081057384610176,
        0.007916991598904133,
        -0.014712563715875149,
        0.0586325079202652,
        -0.017171740531921387,
        -0.059063076972961426,
        -0.0033381653483957052,
        -0.06244925037026405,
        -0.04386039823293686,
        -0.03347690775990486,
        -0.013523824512958527,
        -0.05203231796622276,
        -0.0639226958155632,
        -0.03766091912984848,
        0.06419428437948227,
        -0.04337619990110397,
        0.01963057741522789,
        -0.03939906880259514,
        0.012943990528583527,
        -0.04595436528325081,
        -0.059141311794519424,
        -0.005104562267661095,
        0.053599219769239426,
        -0.03772889822721481,
        0.05318988859653473,
        0.05979223549365997,
        0.060687024146318436,
        0.05763817951083183,
        -0.023158740252256393,
        0.056910280138254166,
        0.05888177454471588,
        -0.045347411185503006,
        0.029864808544516563,
        -0.0031761678401380777,
        -0.017603781074285507,
        0.02605273388326168,
        0.059777647256851196,
        0.007275142706930637,
        -0.05536777898669243,
        -0.04572425037622452,
        0.01626821793615818,
        -0.03160751610994339,
        0.03719180077314377,
        0.06232292205095291,
        -0.003278914373368025,
        -0.05127592757344246,
        -0.058825165033340454,
        -0.041408687829971313,
        0.054701998829841614,
        0.06444773077964783,
        -0.060944393277168274,
        0.04977733641862869,
        -0.0524207204580307,
        -0.06451861560344696,
        0.046239301562309265,
        0.04514267295598984,
        0.006100923754274845,
        0.048184946179389954,
        -0.053620778024196625,
        -0.00683283107355237,
        0.03962887078523636,
        0.05303574353456497,
        -0.0552862249314785,
        0.06211855262517929,
        0.011207233183085918,
        -0.04219167307019234,
        -0.045741770416498184,
        0.016582535579800606,
        0.05215173959732056,
        0.04856548830866814,
        0.06471791118383408,
        0.016353856772184372,
        -0.05559941753745079,
        -0.046363409608602524,
        0.03487676382064819,
        -0.048427876085042953,
        0.03588559478521347,
        0.058176878839731216,
        0.06019967794418335,
        -0.011145420372486115,
        0.01866745948791504,
        0.06170998886227608,
        0.0600765123963356,
        0.06323082000017166,
        -0.06430378556251526,
        -0.05892486870288849,
        -0.0613580085337162,
        0.006392114795744419,
        0.03575149551033974,
        -0.03858890011906624,
        0.06412656605243683,
        0.05301160365343094,
        -0.0222903024405241,
        -0.06080968677997589,
        0.049013152718544006,
        0.0637352392077446
    ],
    [
        -0.0014559945557266474,
        -0.003361819311976433,
        0.04786050692200661,
        -0.0450039841234684,
        -0.04558354988694191,
        -0.01650622859597206,
        0.052334535866975784,
        0.02381940186023712,
        -0.05300351232290268,
        0.034980930387973785,
        -0.03001680225133896,
        0.05316401645541191,
        -0.04058690741658211,
        -0.048678889870643616,
        -0.04814157634973526,
        -0.02448124997317791,
        0.03873677924275398,
        0.05334962531924248,
        0.05207796022295952,
        -0.054087746888399124,
        -0.03819747269153595,
        0.05408173054456711,
        -0.004858478903770447,
        -0.016186652705073357,
        -0.045338016003370285,
        0.04378890246152878,
        -0.04161806404590607,
        -0.019031958654522896,
        -0.052384521812200546,
        0.05297299847006798,
        0.05009406805038452,
        0.05084940791130066,
        0.0524974949657917,
        -0.054014258086681366,
        0.04883793368935585,
        -0.04620421305298805,
        -0.05348456650972366,
        -0.05392055958509445,
        0.0540718249976635,
        0.010335469618439674,
        0.03867625072598457,
        0.01217986736446619,
        0.053851302713155746,
        -0.05240306630730629,
        -0.0014695257414132357,
        0.026810500770807266,
        -0.010407542809844017,
        0.0540107823908329,
        0.05401691794395447,
        -0.04344269260764122,
        0.053833600133657455,
        0.019414003938436508,
        0.04548200964927673,
        -0.0456659160554409,
        -0.05398067086935043,
        0.052503813058137894,
        -0.05341926962137222,
        -0.04222908616065979,
        -0.029799379408359528,
        -0.05352181941270828,
        -0.05138862878084183,
        0.053160410374403,
        -0.044391315430402756,
        0.03319261968135834,
        0.05189970135688782,
        -0.025089746341109276,
        -0.05402611941099167,
        -0.01963617466390133,
        -0.021824613213539124,
        0.022000007331371307,
        0.03444867208600044,
        -0.05308312922716141,
        0.05152600258588791,
        -0.050888583064079285,
        0.03708420693874359,
        -0.03528117761015892,
        0.0016723107546567917,
        0.054087817668914795,
        0.025506585836410522,
        0.0519079715013504,
        -0.053811874240636826,
        -0.04544752091169357,
        -0.04895612969994545,
        0.03222848102450371,
        0.017313925549387932,
        0.02814367786049843,
        0.05384470149874687,
        0.05388333275914192,
        -0.013641435652971268,
        0.049971215426921844,
        -0.053642820566892624,
        -0.05387306585907936,
        -0.0516023226082325,
        -0.03202316164970398,
        -0.0017522670095786452,
        0.05324827879667282,
        0.02256625145673752,
        -0.04698501527309418,
        -0.05304877832531929,
        0.05290034040808678,
        -0.05397258698940277,
        0.05404376983642578,
        0.05076071247458458,
        0.015206205658614635,
        0.052716370671987534,
        0.005566084291785955,
        -0.022707141935825348,
        -0.03137388825416565,
        0.05113981291651726,
        0.05350729823112488,
        0.05215027555823326,
        -0.0532473549246788,
        -0.03682636469602585,
        -0.015441871248185635,
        0.003942550625652075,
        -0.053759798407554626,
        -0.039692606776952744,
        0.03407435864210129,
        -0.033625103533267975,
        -0.052384838461875916,
        0.01192188635468483,
        -0.006943299900740385,
        0.027834584936499596,
        0.05353761836886406,
        -0.052066292613744736,
        -0.02510802447795868,
        -0.051866818219423294,
        -0.04810663312673569,
        0.01722898706793785,
        0.04671572148799896,
        0.0217780452221632,
        -0.053090814501047134,
        -0.04699837416410446,
        0.05408484861254692,
        -0.04512951523065567,
        -0.029923953115940094,
        0.048453621566295624,
        -0.04596998170018196,
        0.008646288886666298,
        0.052137602120637894,
        -0.05125487595796585,
        -0.054022643715143204,
        0.03444145992398262,
        -0.03397879749536514,
        0.0540631003677845,
        -0.051350805908441544,
        0.05398225411772728,
        0.05393615737557411,
        -0.04496413469314575,
        -0.021253254264593124,
        0.04509565606713295,
        -0.05371962487697601,
        -0.053417760878801346,
        0.028355935588479042,
        -0.04717794060707092,
        -0.031055931001901627,
        0.04276280850172043,
        0.010568052530288696,
        0.008975046686828136,
        -0.053696680814027786,
        0.05408727377653122,
        0.03727054223418236,
        0.05119094252586365,
        0.05325564742088318,
        0.004627618473023176,
        0.05273255333304405,
        -0.03484092280268669,
        -0.05159775912761688,
        -0.05132110044360161,
        0.04076039046049118,
        -0.042335763573646545,
        0.053809016942977905,
        -0.05013716593384743,
        0.053646113723516464,
        -0.05355791375041008,
        0.04883374646306038,
        0.05285217612981796,
        -0.018573859706521034,
        0.05373528227210045,
        -0.051323603838682175,
        -0.04738343507051468,
        0.05408671125769615,
        0.03598405793309212,
        -0.038102492690086365,
        0.012120897881686687,
        0.035433828830718994,
        0.009153568185865879,
        0.05401179566979408,
        0.04483221843838692,
        -0.019824080169200897,
        -0.05345653370022774,
        0.046703994274139404,
        -0.04690812528133392,
        -0.05397455766797066,
        -0.03525173291563988,
        -0.05177653580904007,
        -0.04860426485538483,
        -0.054085928946733475,
        -0.04753514751791954,
        0.028715744614601135,
        0.054061152040958405,
        0.03700152784585953,
        0.015985684469342232,
        -0.05407946929335594,
        0.03943437710404396,
        -0.04120764881372452,
        0.047241050750017166,
        0.05223873257637024,
        -0.05279252678155899,
        0.04920411482453346,
        -0.04907861724495888,
        0.035185057669878006,
        -0.013951796106994152,
        0.04876616969704628,
        -0.054026972502470016,
        -0.05384551361203194,
        0.04016764834523201,
        -0.034556794911623,
        0.02908957563340664,
        -0.024314558133482933,
        -0.004346685484051704,
        -0.013487324118614197,
        -0.0058595119044184685,
        -0.0540483184158802,
        0.05371475964784622,
        -0.03859379142522812,
        0.052768006920814514,
        -0.046604059636592865,
        -0.04732927307486534,
        -0.05314404144883156,
        0.053938958793878555,
        -0.04717840254306793,
        -0.05317705497145653,
        0.05213642120361328,
        -0.005006368272006512,
        0.05293413624167442,
        0.04867549613118172,
        0.0041015008464455605,
        0.05040574446320534,
        0.054087210446596146,
        -0.031864721328020096,
        -0.015623172745108604,
        -0.04891751706600189,
        -0.05408746749162674,
        0.053703151643276215,
        -0.05020857974886894,
        0.04706169292330742,
        -0.04873284325003624,
        -0.053275369107723236,
        -0.054044827818870544,
        -0.053347643464803696,
        -0.044332314282655716,
        0.03016030229628086,
        -0.05353261157870293,
        -0.045694272965192795,
        0.012744325213134289,
        -0.021292977035045624,
        -0.048617005348205566,
        0.04911402612924576,
        -0.028192643076181412,
        0.054021626710891724,
        0.0035619123373180628,
        -0.04443522170186043,
        -0.026115041226148605,
        -0.05384163185954094,
        0.04553341120481491,
        0.054038334637880325,
        0.05404205992817879,
        -0.017385192215442657,
        -0.051399923861026764,
        0.014858301728963852,
        0.045026469975709915,
        0.02204650454223156,
        0.011902202852070332,
        0.01902293972671032,
        -0.00423502316698432,
        -0.042189620435237885,
        -0.05316682159900665,
        0.05252622440457344,
        0.042619459331035614,
        -0.05342040956020355,
        0.05408531427383423,
        -0.02397768385708332,
        0.004939225502312183,
        -0.00436759926378727,
        0.0376601368188858,
        0.028018614277243614,
        0.050853267312049866,
        -0.030879030004143715,
        0.03573308512568474,
        -0.045443058013916016,
        0.05382297560572624,
        0.05391187220811844,
        0.050565965473651886,
        0.05405604466795921,
        -0.037388287484645844,
        0.05405982583761215,
        0.05181550234556198,
        -0.04933549463748932,
        0.048687644302845,
        -0.05003485828638077,
        0.0008130972273647785,
        0.04011005535721779,
        -0.020378144457936287,
        -0.052828844636678696,
        -0.03775935620069504,
        0.00041688900091685355,
        0.02807997167110443,
        0.053702134639024734,
        -0.010146275162696838,
        0.05238506197929382,
        -0.049815259873867035,
        0.05015597864985466,
        0.05379169434309006,
        0.023289067670702934,
        -0.052960705012083054,
        -0.019593823701143265,
        -0.03760957345366478,
        0.052724823355674744,
        -0.01577363908290863,
        0.04600675404071808,
        0.053134139627218246,
        -0.0143969701603055,
        0.05327749252319336,
        0.05002022907137871,
        0.044336240738630295,
        0.005995715502649546,
        -0.053724050521850586,
        -0.05407042056322098,
        -0.05161124840378761,
        0.053872860968112946,
        -0.04154215753078461,
        -0.05342357978224754,
        0.05402388423681259,
        0.04944339022040367,
        0.05398409068584442,
        -0.049828171730041504,
        -0.04542902112007141,
        0.0488402284681797,
        -0.0283186137676239,
        -0.05189754441380501,
        -0.023481644690036774,
        0.046820782124996185,
        -0.05314887315034866,
        -0.05054924637079239,
        0.00502846110612154,
        0.05222722887992859,
        0.05396752431988716,
        0.05029323697090149,
        -0.0026924563571810722,
        0.05298212915658951,
        0.053010907024145126,
        0.01788262464106083,
        -0.05395613610744476,
        -0.05342346802353859,
        -0.05195988342165947,
        -0.053080324083566666,
        -0.053164996206760406,
        -0.0328717939555645,
        -0.05371461063623428,
        0.03749352693557739,
        -0.011120332404971123,
        0.023961016908288002,
        -0.052170418202877045,
        -0.051399800926446915,
        -0.05293042212724686,
        0.05054042115807533,
        -0.05147501453757286,
        -0.02034330554306507,
        -0.053857605904340744,
        -0.05363783985376358,
        -0.05405191704630852,
        -0.04787653684616089,
        -0.052349358797073364,
        0.05339369550347328,
        0.04992680996656418,
        -0.05318915471434593,
        0.008211057633161545,
        0.03697667270898819,
        0.05270880088210106,
        -0.03981379419565201,
        0.032616306096315384,
        -0.05100037530064583,
        0.05150653049349785,
        0.05402194708585739,
        0.015064114704728127,
        0.05088470131158829,
        -0.04443784058094025,
        0.01947462558746338,
        -0.0504414327442646,
        -0.053906165063381195,
        0.05072241276502609,
        -0.051480647176504135,
        -0.051695410162210464,
        0.0539986751973629,
        0.05087239667773247,
        0.05071835592389107,
        -0.05394402891397476,
        -0.05040231719613075,
        -0.04181712493300438,
        -0.052386123687028885,
        0.05397167056798935,
        -0.0354045107960701,
        -0.05109647661447525,
        -0.040184348821640015,
        0.04182390868663788,
        0.05355584993958473,
        -0.04901588335633278,
        0.028705952689051628,
        0.029803164303302765,
        0.015129881910979748,
        -0.05075046792626381,
        0.030982110649347305,
        0.01607188768684864,
        0.0535472072660923,
        -0.05406524986028671,
        0.051904961466789246,
        0.0540834441781044,
        0.053321484476327896,
        0.054076094180345535,
        0.04327698424458504,
        -0.05393882095813751,
        0.047517742961645126,
        -0.05342582240700722,
        -0.051729317754507065,
        -0.03639665246009827,
        -0.03419255092740059,
        0.05405208095908165,
        -0.050536639988422394,
        -0.05317555367946625,
        -0.04688091203570366,
        0.0003559258475434035,
        0.006796605885028839,
        0.04841141402721405,
        -0.040468569844961166,
        -0.05331278592348099,
        -0.05208747833967209,
        -0.050843000411987305,
        0.034461747854948044,
        -0.04825950786471367,
        -0.05249520018696785,
        0.053674641996622086,
        0.05405237898230553,
        0.05338015407323837,
        -0.05393292009830475,
        -0.02962535433471203,
        0.05306931212544441,
        -0.05169082060456276,
        0.051857706159353256,
        -0.024006929248571396,
        -0.05393904075026512,
        -0.007542151026427746,
        0.05408434942364693,
        0.03811686858534813,
        -0.04906152933835983,
        -0.05068852752447128,
        0.041610099375247955,
        -0.04718465730547905,
        -0.049765776842832565,
        0.03664898872375488,
        0.04741194099187851,
        -0.04120606929063797,
        -0.011497367173433304,
        -0.04231315106153488,
        0.054015662521123886,
        0.05402020737528801,
        -0.05212373286485672,
        0.05400005728006363,
        0.046727072447538376,
        -0.05402765050530434,
        0.053060468286275864,
        0.04977303370833397,
        0.024616150185465813,
        0.054079003632068634,
        -0.05177651718258858,
        0.04989684373140335,
        -0.01328093558549881,
        0.013300915248692036,
        -0.05347788706421852,
        -0.05208447575569153,
        -0.05343956872820854,
        0.04644538462162018,
        -0.04649651423096657,
        -0.044847406446933746,
        -0.05229569971561432,
        0.03954233229160309,
        -0.01146023627370596,
        0.0387866385281086,
        -0.05302664637565613,
        -0.04678674042224884,
        0.05334322899580002,
        -0.051706425845623016,
        0.05385195463895798,
        0.002352237468585372,
        0.045436710119247437,
        0.05141574516892433,
        -0.05370500683784485,
        0.05397560074925423,
        0.04542923718690872,
        -0.05211151763796806,
        -0.050532709807157516,
        -0.051265180110931396,
        -0.05406969413161278,
        0.04484432190656662,
        0.05272797867655754,
        0.0400409959256649,
        0.054087817668914795,
        -0.047569263726472855,
        0.004963563289493322,
        0.01895223744213581,
        0.012902051210403442,
        0.04686787724494934
    ],
    [
        -0.023711688816547394,
        -0.057119086384773254,
        0.036891963332891464,
        0.00012034738756483421,
        0.06350074708461761,
        -0.05711403489112854,
        0.06427018344402313,
        -0.0027659935876727104,
        -0.038086600601673126,
        0.04277852922677994,
        0.05741211771965027,
        0.034991905093193054,
        -0.037637047469615936,
        -0.027600036934018135,
        -0.0635029748082161,
        0.05653589963912964,
        -0.0647866353392601,
        0.06101291626691818,
        -0.06204931437969208,
        0.028099531307816505,
        -0.05297086760401726,
        0.04339952394366264,
        0.06332135945558548,
        0.0018068351782858372,
        0.03412245213985443,
        0.035784006118774414,
        -0.003965426702052355,
        0.03278570994734764,
        -0.02606315352022648,
        -0.021572796627879143,
        0.06050995737314224,
        -0.02823292836546898,
        0.047059316188097,
        -0.05642632022500038,
        0.017684129998087883,
        -0.006252960301935673,
        -0.05483502522110939,
        -0.02827632613480091,
        -0.06070909649133682,
        0.0550013929605484,
        -0.0022184557747095823,
        -0.037586018443107605,
        0.055241819471120834,
        -0.06358993053436279,
        0.01871882751584053,
        0.025155365467071533,
        0.05268635228276253,
        0.058198682963848114,
        0.060787785798311234,
        -0.013898201286792755,
        0.049276646226644516,
        -0.022028258070349693,
        0.06433846056461334,
        0.01404532603919506,
        -0.03119506686925888,
        -0.04510192945599556,
        -0.016263093799352646,
        0.003756984369829297,
        -0.04714759811758995,
        0.024118201807141304,
        -0.04468081146478653,
        -0.02939014509320259,
        -0.0619780533015728,
        -0.01959729939699173,
        0.018024520948529243,
        0.02742457389831543,
        0.05283292755484581,
        -0.056943781673908234,
        -0.0028399555012583733,
        -0.06634046137332916,
        -0.05089300125837326,
        -0.06592386960983276,
        -0.010905913077294827,
        -0.017072632908821106,
        -0.0467311292886734,
        -0.0006443146849051118,
        0.002941302489489317,
        0.052424997091293335,
        0.04714230075478554,
        -0.02813723497092724,
        -0.024939153343439102,
        0.05733812600374222,
        -0.04338449239730835,
        0.0436171218752861,
        -0.03238719329237938,
        0.011334195733070374,
        -0.06044349446892738,
        0.044921956956386566,
        0.02504153549671173,
        0.06553364545106888,
        -0.052502695471048355,
        0.021123213693499565,
        -0.0397326685488224,
        -0.059659842401742935,
        0.027388516813516617,
        -0.000010429370377096348,
        -0.04901392385363579,
        -0.05216243118047714,
        -0.057349372655153275,
        -0.032979536801576614,
        -0.06349748373031616,
        -0.04134747385978699,
        0.05138605460524559,
        0.040047697722911835,
        -0.022600559517741203,
        -0.010063962079584599,
        -0.03429543226957321,
        -0.021299662068486214,
        -0.05550433322787285,
        0.0174383707344532,
        -0.03818379342556,
        -0.06527858227491379,
        -0.062478404492139816,
        0.023087989538908005,
        0.04138234257698059,
        -0.005234749987721443,
        -0.010938547551631927,
        -0.060972362756729126,
        -0.06384576112031937,
        0.0016705605667084455,
        -0.04413915053009987,
        0.04143611341714859,
        -0.01751723513007164,
        0.058108411729335785,
        -0.0654340535402298,
        -0.026741374284029007,
        0.024726219475269318,
        0.04561089724302292,
        0.05335482209920883,
        -0.008608856238424778,
        0.03580835834145546,
        -0.05693249776959419,
        0.026069659739732742,
        0.03813357278704643,
        -0.052049536257982254,
        -0.04802800342440605,
        0.04414423927664757,
        -0.052445728331804276,
        -0.01375635713338852,
        0.06530734896659851,
        -0.03317315876483917,
        0.0002632064570207149,
        0.04860822856426239,
        0.02432376518845558,
        0.002053466159850359,
        0.03724125772714615,
        0.06427638232707977,
        -0.015493471175432205,
        -0.029757758602499962,
        0.04906700178980827,
        -0.039628587663173676,
        -0.06233426555991173,
        -0.05707086995244026,
        0.020678967237472534,
        0.0018887940095737576,
        -0.05923539400100708,
        0.06578986346721649,
        0.04656480625271797,
        0.017811279743909836,
        -0.023704420775175095,
        0.051590558141469955,
        -0.002151686465367675,
        0.043182987719774246,
        -0.058431707322597504,
        -0.051336899399757385,
        -0.011704250238835812,
        -0.02636842429637909,
        -0.06445197761058807,
        -0.05166485160589218,
        0.04599945619702339,
        -0.021045204252004623,
        0.021059127524495125,
        -0.044679880142211914,
        -0.021553654223680496,
        -0.0598800927400589,
        0.0628475770354271,
        -0.06588621437549591,
        -0.019199375063180923,
        0.01637978106737137,
        0.0304407961666584,
        0.05976556986570358,
        0.058107640594244,
        0.06323712319135666,
        0.023010656237602234,
        0.026199065148830414,
        -0.016714030876755714,
        -0.024747518822550774,
        0.06238305941224098,
        0.00491547305136919,
        -0.05199861899018288,
        -0.010981127619743347,
        0.02437104657292366,
        -0.02924147993326187,
        -0.01049019955098629,
        -0.034320682287216187,
        -0.022051552310585976,
        0.055932242423295975,
        0.019176380708813667,
        -0.02753172628581524,
        0.0323331244289875,
        0.018811631947755814,
        0.05631169304251671,
        0.0561416894197464,
        0.044436078518629074,
        -0.01909485273063183,
        0.05348709970712662,
        0.05948776751756668,
        -0.0004023090295959264,
        -0.02616865746676922,
        0.052489086985588074,
        -0.061148177832365036,
        -0.000845322385430336,
        0.030389392748475075,
        -0.05521218851208687,
        0.05857039615511894,
        -0.016565872356295586,
        0.05166583135724068,
        0.05399925261735916,
        0.06519590318202972,
        -0.018930645659565926,
        0.05839039012789726,
        -0.05821843445301056,
        -0.04348522797226906,
        -0.06612091511487961,
        0.06377091258764267,
        -0.061111073940992355,
        -0.027929289266467094,
        0.0030597082804888487,
        -0.027697186917066574,
        -0.06103336811065674,
        0.018593426793813705,
        -0.0643652006983757,
        -0.018622986972332,
        0.02028479054570198,
        0.038357775658369064,
        0.0019243848510086536,
        0.062211453914642334,
        0.04927992820739746,
        -0.012365218251943588,
        0.06089212745428085,
        0.032463766634464264,
        0.041003063321113586,
        -0.01306607760488987,
        -0.0024208412505686283,
        0.00014715826546307653,
        -0.03522389754652977,
        0.04424656182527542,
        0.04489627853035927,
        -0.05810663849115372,
        0.0509217195212841,
        -0.020600415766239166,
        -0.06175651401281357,
        -0.04858049005270004,
        -0.060437072068452835,
        0.020584624260663986,
        0.06330935657024384,
        0.03283247351646423,
        -0.03823716938495636,
        0.06440256536006927,
        0.0656801238656044,
        0.036030177026987076,
        0.06411798298358917,
        0.023111797869205475,
        0.021871615201234818,
        -0.052137985825538635,
        0.028379136696457863,
        0.03238207846879959,
        0.03885367140173912,
        0.015581021085381508,
        -0.06313265115022659,
        0.010131850838661194,
        -0.06481313705444336,
        0.001082848641090095,
        -0.05423299968242645,
        -0.02847909927368164,
        0.03435344994068146,
        -0.05929245427250862,
        0.017794014886021614,
        -0.006633432116359472,
        -0.03668404370546341,
        -0.06284509599208832,
        0.023060817271471024,
        0.03998498618602753,
        0.0009630445274524391,
        -0.05604086071252823,
        -0.05566601827740669,
        -0.02678101696074009,
        -0.058709364384412766,
        -0.0344248004257679,
        0.05224362015724182,
        -0.04952555522322655,
        0.01568973995745182,
        -0.004587437957525253,
        -0.0036338248755782843,
        0.0625305250287056,
        -0.046422079205513,
        0.06358198821544647,
        0.019301459193229675,
        -0.02974606119096279,
        0.038711220026016235,
        -0.05209716036915779,
        0.05352182313799858,
        -0.0221069548279047,
        -0.02022579312324524,
        -0.06341629475355148,
        -0.06377381831407547,
        -0.05546003207564354,
        0.03608070686459541,
        0.06364317983388901,
        -0.06164444610476494,
        0.05193657800555229,
        -0.03626331686973572,
        -0.04669681936502457,
        0.05968375504016876,
        -0.06314921379089355,
        -0.04878467693924904,
        0.06558948010206223,
        -0.009474746882915497,
        -0.024891119450330734,
        -0.06085473671555519,
        -0.06015317142009735,
        0.030445920303463936,
        0.06459344178438187,
        -0.01138987299054861,
        0.053272780030965805,
        0.04592256620526314,
        0.022730307653546333,
        -0.0271853506565094,
        -0.04542270302772522,
        -0.039215680211782455,
        -0.05042793229222298,
        0.048979368060827255,
        0.027092233300209045,
        -0.05661763995885849,
        0.059837959706783295,
        0.008256355300545692,
        -0.046805378049612045,
        0.06131129339337349,
        -0.05207567289471626,
        0.05781896784901619,
        0.03485369682312012,
        0.005022183991968632,
        -0.04282927140593529,
        -0.02176535502076149,
        0.06635123491287231,
        0.050637464970350266,
        0.025634562596678734,
        0.0368831604719162,
        -0.026117004454135895,
        0.06123243272304535,
        -0.06634678691625595,
        0.0158891212195158,
        -0.06270116567611694,
        -0.06026177108287811,
        -0.06394202262163162,
        0.05254702270030975,
        -0.02341178059577942,
        -0.038355860859155655,
        -0.050793688744306564,
        -0.05676588788628578,
        0.057111915200948715,
        0.049765653908252716,
        -0.06212485581636429,
        -0.030633866786956787,
        -0.013673637993633747,
        -0.05880758538842201,
        0.06589046865701675,
        -0.03984786197543144,
        0.000980634824372828,
        -0.06517596542835236,
        -0.048036325722932816,
        -0.061936572194099426,
        0.055957213044166565,
        -0.009415032342076302,
        0.05049101263284683,
        0.06142231449484825,
        -0.04719169810414314,
        0.04460625723004341,
        -0.061754658818244934,
        -0.05095840245485306,
        -0.06259719282388687,
        0.03982047364115715,
        -0.05857697129249573,
        -0.011346532963216305,
        0.06315173953771591,
        -0.03849403187632561,
        0.035925768315792084,
        -0.06333183497190475,
        -0.055595763027668,
        -0.045168861746788025,
        -0.05797380581498146,
        0.028447473421692848,
        0.0471189022064209,
        0.004288163036108017,
        -0.025144299492239952,
        0.05609479546546936,
        0.05798589810729027,
        0.003012188011780381,
        -0.028001897037029266,
        -0.053891416639089584,
        -0.03914593905210495,
        0.0414075143635273,
        0.0529073029756546,
        0.06377799063920975,
        -0.05746707692742348,
        0.049382153898477554,
        0.046407606452703476,
        0.018473166972398758,
        0.05851154029369354,
        -0.03838187828660011,
        0.05546187981963158,
        -0.00539556797593832,
        0.030691862106323242,
        -0.004040772095322609,
        0.05975392088294029,
        0.039240285754203796,
        0.032713085412979126,
        -0.011725576594471931,
        0.048251789063215256,
        -0.042865730822086334,
        -0.04078201204538345,
        -0.059843335300683975,
        -0.023143652826547623,
        -0.058023709803819656,
        0.037585094571113586,
        -0.0453866608440876,
        -0.03773331642150879,
        -0.04992963746190071,
        -0.06593139469623566,
        0.03187982738018036,
        -0.0004160490061622113,
        0.03314105048775673,
        -0.04983852803707123,
        0.01490222942084074,
        0.04390418156981468,
        0.013678536750376225,
        -0.06341470777988434,
        0.025584882125258446,
        -0.05388295277953148,
        -0.01653808169066906,
        -0.043904755264520645,
        0.04640861228108406,
        -0.025007370859384537,
        0.050832804292440414,
        0.0368666797876358,
        -0.0048447949811816216,
        0.06272897869348526,
        -0.05370527133345604,
        0.061781685799360275,
        -0.06047290563583374,
        -0.04777401685714722,
        0.054635364562273026,
        0.0660046637058258,
        -0.004034915007650852,
        -0.04920876771211624,
        -0.040038466453552246,
        0.03414338454604149,
        0.051699522882699966,
        -0.0454397089779377,
        0.04278062656521797,
        0.04261134937405586,
        -0.00864454172551632,
        -0.06270818412303925,
        -0.03207314759492874,
        0.056121066212654114,
        0.06589628010988235,
        -0.06576508283615112,
        0.06465400010347366,
        -0.04974563047289848,
        -0.061460450291633606,
        0.05411572754383087,
        0.059183210134506226,
        0.041019320487976074,
        0.0013868464156985283,
        0.060244034975767136,
        0.044556669890880585,
        0.041104260832071304,
        0.031111713498830795,
        -0.05579661205410957,
        -0.0313706248998642,
        -0.04781005531549454,
        -0.017775550484657288,
        0.05515621602535248,
        0.030533282086253166,
        -0.005487747956067324,
        0.027788303792476654,
        0.04704107716679573,
        -0.04022642597556114,
        -0.05630850791931152,
        -0.0072721075266599655,
        -0.02842627838253975,
        -0.06595747172832489,
        -0.02222304604947567,
        0.02263123355805874,
        0.06575383991003036,
        0.003497833153232932,
        -0.05802562087774277,
        0.04040838032960892,
        0.06094371899962425,
        0.008354607038199902,
        0.032902028411626816,
        -0.05096261948347092,
        -0.03334492817521095,
        -0.01145104318857193,
        -0.0047483923844993114,
        0.01283369492739439,
        0.026656264439225197,
        -0.02825145795941353,
        -0.06571894139051437,
        -0.03420431166887283,
        0.0639525055885315,
        0.05001671239733696
    ],
    [
        0.03805968165397644,
        0.05161641165614128,
        0.04757336527109146,
        -0.0530964732170105,
        -0.048123590648174286,
        -0.05390864238142967,
        0.05380050837993622,
        -0.053559187799692154,
        0.04474147409200668,
        -0.0448882095515728,
        0.037983059883117676,
        -0.023963678628206253,
        -0.049119383096694946,
        -0.050918664783239365,
        -0.05410389602184296,
        0.05407916381955147,
        0.0496608167886734,
        0.05073145031929016,
        0.04269213229417801,
        0.05308886244893074,
        -0.04468557983636856,
        0.036376748234033585,
        0.017410552129149437,
        0.0511954165995121,
        -0.02504277415573597,
        0.05274374037981033,
        0.05415555089712143,
        0.05243195220828056,
        -0.050047919154167175,
        -0.03692450001835823,
        0.05248185992240906,
        0.03577825427055359,
        -0.008171038702130318,
        -0.05433613434433937,
        0.052298642694950104,
        -0.05341145023703575,
        -0.05382314324378967,
        0.0033004991710186005,
        0.05365834757685661,
        -0.023317629471421242,
        -0.02367488667368889,
        0.04229756072163582,
        0.052399277687072754,
        -0.04398563876748085,
        0.04380190372467041,
        -0.019592346623539925,
        0.05149400234222412,
        0.0542919747531414,
        0.05411539226770401,
        -0.05169831961393356,
        0.05440088361501694,
        0.03199461102485657,
        -0.015246337279677391,
        -0.04974875971674919,
        0.02811325155198574,
        -0.05177851393818855,
        0.01090177521109581,
        0.009473856538534164,
        -0.05184036120772362,
        -0.0494905523955822,
        -0.05424828827381134,
        0.05224984139204025,
        -0.053543366491794586,
        -0.046215541660785675,
        0.05404713377356529,
        -0.018471961840987206,
        -0.05339134484529495,
        -0.05349818617105484,
        0.044350516051054,
        -0.0022663972340524197,
        0.029181290417909622,
        -0.05233975872397423,
        0.054372962564229965,
        0.001278880168683827,
        0.04234994575381279,
        -0.02573288045823574,
        0.045013438910245895,
        0.054401323199272156,
        0.005130915902554989,
        0.029440682381391525,
        -0.0415068194270134,
        -0.0518583208322525,
        -0.041701540350914,
        0.04915989562869072,
        0.022320393472909927,
        0.053023822605609894,
        -0.03600357100367546,
        -0.04702432081103325,
        -0.05439020320773125,
        0.03357631713151932,
        -0.05360734462738037,
        -0.05002628639340401,
        -0.05269888415932655,
        -0.038646820932626724,
        -0.05420096591114998,
        0.0015198527835309505,
        0.039018090814352036,
        -0.054277002811431885,
        0.031406283378601074,
        0.01866789534687996,
        -0.05440114066004753,
        0.05400875210762024,
        0.05122365057468414,
        0.05435861274600029,
        0.031267598271369934,
        -0.03378730267286301,
        -0.054145827889442444,
        0.03749387338757515,
        -0.03846070542931557,
        0.05388696864247322,
        0.0359778106212616,
        -0.053583260625600815,
        -0.051732681691646576,
        -0.05298731476068497,
        0.04269874468445778,
        0.05409439653158188,
        -0.05159136652946472,
        -0.054387208074331284,
        0.03285938873887062,
        -0.053245659917593,
        0.024699363857507706,
        -0.050803665071725845,
        0.0485425628721714,
        -0.053127121180295944,
        -0.05368896573781967,
        -0.018313128501176834,
        0.05157633498311043,
        0.05288498103618622,
        0.053307417780160904,
        0.03588219732046127,
        -0.010624977760016918,
        -0.05421018972992897,
        -0.051372237503528595,
        0.053737327456474304,
        -0.04016542062163353,
        -0.053887296468019485,
        0.05214082449674606,
        -0.05159624665975571,
        -0.02904447540640831,
        0.0513211265206337,
        -0.0445140115916729,
        -0.052391380071640015,
        0.052498094737529755,
        0.05372930318117142,
        0.020735258236527443,
        0.011774073354899883,
        0.054357655346393585,
        0.0018015658715739846,
        -0.0535854808986187,
        -0.023459237068891525,
        -0.049056392163038254,
        -0.05397048220038414,
        -0.011020229198038578,
        -0.017177501693367958,
        0.002675357274711132,
        0.033117178827524185,
        -0.0024972741957753897,
        0.0487992949783802,
        0.05204884335398674,
        -0.0008025206625461578,
        -0.03776877000927925,
        -0.05135972425341606,
        0.054145436733961105,
        0.04594428464770317,
        0.01194219570606947,
        0.009800445288419724,
        0.04429503157734871,
        0.05388129875063896,
        -0.04289465770125389,
        0.052595242857933044,
        -0.047669801861047745,
        0.05344004929065704,
        -0.046237509697675705,
        0.041521623730659485,
        -0.048683151602745056,
        0.01624700240790844,
        0.050785697996616364,
        -0.049618542194366455,
        0.054371945559978485,
        -0.05381382256746292,
        -0.04967759549617767,
        0.052608612924814224,
        0.049592275172472,
        -0.030919678509235382,
        -0.008851337246596813,
        -0.050910599529743195,
        0.051097773015499115,
        0.05409248173236847,
        0.006009827833622694,
        -0.04765418916940689,
        -0.051837582141160965,
        0.04994844272732735,
        -0.0015168976970016956,
        -0.02680205926299095,
        -0.0333457887172699,
        0.05391818657517433,
        0.033982764929533005,
        0.027727769687771797,
        -0.053403060883283615,
        0.05225539579987526,
        -0.010883733630180359,
        0.054029155522584915,
        0.03668344393372536,
        -0.03564876317977905,
        0.050863612443208694,
        0.046600017696619034,
        0.05398598313331604,
        0.052002597600221634,
        -0.04854889586567879,
        0.05002514645457268,
        -0.021445585414767265,
        0.03631513565778732,
        0.054246820509433746,
        0.0543748177587986,
        0.05266830697655678,
        -0.04277794808149338,
        0.0543067492544651,
        0.04600061848759651,
        0.05404873564839363,
        -0.04028800129890442,
        0.05216998979449272,
        -0.010649681091308594,
        0.005785677116364241,
        0.02329018898308277,
        0.05141051486134529,
        -0.01227819174528122,
        0.044974204152822495,
        -0.0524437241256237,
        0.05420009791851044,
        -0.054345306009054184,
        0.003531369846314192,
        -0.02857774682343006,
        -0.046305425465106964,
        0.053673047572374344,
        0.05042076110839844,
        0.05412319675087929,
        0.05392630398273468,
        0.047443658113479614,
        -0.05164622142910957,
        0.0543985590338707,
        0.019886063411831856,
        -0.035352177917957306,
        -0.03882474824786186,
        -0.054304998368024826,
        0.05281988903880119,
        -0.04660379886627197,
        0.05393785238265991,
        -0.05338925123214722,
        -0.04851481691002846,
        0.021083416417241096,
        0.008106785826385021,
        -0.006596244405955076,
        0.054342444986104965,
        -0.05411500856280327,
        -0.0012787578161805868,
        0.05218791961669922,
        0.04011623561382294,
        0.034310873597860336,
        0.054113198071718216,
        0.01996489427983761,
        -0.020510928705334663,
        0.05242073908448219,
        0.047571372240781784,
        0.0022107018157839775,
        -0.05391798913478851,
        0.05416087433695793,
        0.05404267832636833,
        0.05433765426278114,
        0.01235250011086464,
        -0.027045125141739845,
        0.054272811859846115,
        -0.05354499816894531,
        0.05170109495520592,
        -0.04864634573459625,
        0.05268546938896179,
        0.05149373412132263,
        -0.04817035794258118,
        -0.033962033689022064,
        0.05302191153168678,
        -0.016433026641607285,
        -0.05423871800303459,
        0.05425342544913292,
        -0.0442383736371994,
        -0.04966055974364281,
        -0.05307319387793541,
        0.0033589894883334637,
        -0.04353217035531998,
        0.053313467651605606,
        0.05099233239889145,
        0.05426216498017311,
        -0.02726564183831215,
        -0.012813611887395382,
        -0.04715937748551369,
        -0.05196493864059448,
        0.05162743106484413,
        -0.05399284511804581,
        0.053204748779535294,
        0.005846664309501648,
        -0.05394550412893295,
        0.05394385755062103,
        0.05256957188248634,
        0.0253901369869709,
        -0.01584615930914879,
        0.019173014909029007,
        0.03701391443610191,
        0.05286610499024391,
        0.02586986869573593,
        0.054268643260002136,
        0.011400715447962284,
        -0.05421515181660652,
        0.03846697881817818,
        -0.03380201756954193,
        0.05196858197450638,
        0.05230889096856117,
        0.04634914919734001,
        -0.05359525606036186,
        0.01741809956729412,
        0.033531252294778824,
        0.05423188582062721,
        -0.03221376985311508,
        0.007149087265133858,
        0.041209783405065536,
        0.05328262969851494,
        -0.006224151235073805,
        0.05243181064724922,
        0.04748903959989548,
        0.039334122091531754,
        -0.04707976430654526,
        -0.053690169006586075,
        -0.04876549914479256,
        0.042292580008506775,
        -0.0514572337269783,
        -0.0049811797216534615,
        0.005205538589507341,
        0.05432286113500595,
        0.04925215616822243,
        -0.030924636870622635,
        0.048561010509729385,
        0.04424469545483589,
        0.045984718948602676,
        -0.052634574472904205,
        -0.048825882375240326,
        -0.019599268212914467,
        0.01598278433084488,
        0.05107326805591583,
        0.05418786406517029,
        0.053533848375082016,
        0.05426754429936409,
        0.0258755125105381,
        0.05146808922290802,
        0.05432101711630821,
        0.04949989169836044,
        0.05156227573752403,
        -0.054324567317962646,
        -0.05405499413609505,
        0.046414051204919815,
        -0.052965663373470306,
        -0.012130172923207283,
        -0.05062573030591011,
        0.026265783235430717,
        0.053297560662031174,
        0.024036338552832603,
        -0.01660132221877575,
        -0.050045765936374664,
        0.01799512468278408,
        -0.05374455451965332,
        0.0542011559009552,
        -0.052162349224090576,
        0.004275583662092686,
        -0.029714547097682953,
        -0.022364314645528793,
        -0.05348536744713783,
        0.0239950492978096,
        -0.020787667483091354,
        0.027815604582428932,
        0.0542132668197155,
        -0.00803692452609539,
        0.0013085398823022842,
        0.05435895174741745,
        0.02212688885629177,
        -0.05095605179667473,
        0.05398845672607422,
        -0.04976746812462807,
        -0.048956118524074554,
        0.05141766741871834,
        -0.05001957714557648,
        -0.004027707036584616,
        -0.05318108946084976,
        -0.04668928682804108,
        -0.030276115983724594,
        -0.05336250737309456,
        -0.04908130690455437,
        -0.05125026777386665,
        -0.05340723693370819,
        0.052513498812913895,
        0.05430908873677254,
        0.017818661406636238,
        -0.05422251299023628,
        -0.054138243198394775,
        -0.050256818532943726,
        0.019223639741539955,
        0.05419890955090523,
        -0.05395001918077469,
        0.05027187243103981,
        0.01700354740023613,
        0.017795901745557785,
        0.04833924397826195,
        -0.05257128179073334,
        0.05426829680800438,
        0.04960758239030838,
        -0.04824436828494072,
        -0.013129723258316517,
        0.017197806388139725,
        0.04963179677724838,
        0.053572796285152435,
        -0.05404491350054741,
        0.023244131356477737,
        -0.04216305911540985,
        0.053477104753255844,
        0.05386187881231308,
        0.04906477779150009,
        -0.05398138612508774,
        -0.018855489790439606,
        -0.05432901531457901,
        -0.0524439811706543,
        -0.013588391244411469,
        -0.04889516904950142,
        -0.05357715114951134,
        -0.0528288409113884,
        0.03697863593697548,
        -0.03740520775318146,
        -0.009324545972049236,
        0.0467376671731472,
        0.053039249032735825,
        -0.05150958523154259,
        -0.05288311466574669,
        -0.05197989195585251,
        0.05291173607110977,
        -0.04928627610206604,
        -0.054195623844861984,
        -0.05083082616329193,
        0.050216514617204666,
        0.052139829844236374,
        0.05435880646109581,
        -0.05263155698776245,
        0.03263874351978302,
        0.053393714129924774,
        -0.03242442011833191,
        0.05281756818294525,
        0.0543534941971302,
        0.052451103925704956,
        0.014269620180130005,
        0.04826994985342026,
        0.034871656447649,
        0.01433568261563778,
        -0.054045043885707855,
        -0.05123945698142052,
        -0.05436929315328598,
        0.04869820922613144,
        0.04720206931233406,
        0.051692139357328415,
        -0.05210807919502258,
        -0.018103206530213356,
        -0.04886304587125778,
        0.053997334092855453,
        0.05354417860507965,
        -0.05418960750102997,
        0.04891156777739525,
        -0.03195875510573387,
        -0.05321267619729042,
        0.04979833588004112,
        0.015649180859327316,
        0.05366281047463417,
        0.003060746705159545,
        -0.052276767790317535,
        -0.0258193239569664,
        0.030158881098031998,
        0.05165836215019226,
        -0.04617096856236458,
        -0.025912411510944366,
        -0.01458763424307108,
        -0.0017205749172717333,
        0.023021062836050987,
        -0.006093907169997692,
        -0.05168929323554039,
        0.0540081150829792,
        0.041416235268116,
        0.013520735315978527,
        -0.05204038321971893,
        -0.053804926574230194,
        0.013239501044154167,
        -0.0535399466753006,
        0.05409401282668114,
        0.034362103790044785,
        0.003977909684181213,
        0.02259991504251957,
        0.03191418945789337,
        -0.044286005198955536,
        0.053394757211208344,
        0.019330771639943123,
        -0.054382141679525375,
        -0.05186302587389946,
        -0.053435295820236206,
        -0.05321766436100006,
        0.054258204996585846,
        0.014513371512293816,
        0.054393284022808075,
        -0.029053522273898125,
        0.05433637648820877,
        -0.05422450974583626,
        0.05008349195122719,
        0.0394866056740284
    ],
    [
        -0.03239896148443222,
        -0.03910738602280617,
        0.04987537860870361,
        0.039639391005039215,
        -0.01560970302671194,
        -0.05200773477554321,
        0.04869818687438965,
        -0.05314464867115021,
        0.04761836305260658,
        -0.051521264016628265,
        0.05118779465556145,
        0.027570486068725586,
        -0.05132042244076729,
        -0.050548993051052094,
        -0.05294577777385712,
        0.05314556136727333,
        -0.0529220849275589,
        0.053140345960855484,
        -0.04653565213084221,
        0.053099047392606735,
        -0.05106152966618538,
        0.009967440739274025,
        0.04666134715080261,
        0.02688080631196499,
        -0.048458028584718704,
        0.050487905740737915,
        0.016889255493879318,
        0.04896354675292969,
        -0.05311444029211998,
        -0.05260169133543968,
        0.03194187209010124,
        0.04825352132320404,
        0.05002133548259735,
        -0.047544583678245544,
        0.05156351625919342,
        -0.04920649901032448,
        -0.053120993077754974,
        -0.03973766416311264,
        -0.053109075874090195,
        -0.05266260355710983,
        0.005091177299618721,
        0.04514170438051224,
        0.04818347468972206,
        -0.05297484248876572,
        0.05228560045361519,
        0.048947524279356,
        -0.04248299077153206,
        0.05310508981347084,
        0.053072042763233185,
        -0.04702861234545708,
        0.053138915449380875,
        0.0529060997068882,
        -0.009342493489384651,
        -0.04454992339015007,
        -0.01909044198691845,
        0.048099976032972336,
        -0.05201819911599159,
        0.012163100764155388,
        -0.05314663052558899,
        -0.047842059284448624,
        -0.05297540873289108,
        0.04780237376689911,
        -0.05278843268752098,
        0.04996315762400627,
        0.030587468296289444,
        0.02116357907652855,
        -0.04590567946434021,
        -0.03877375274896622,
        0.052522238343954086,
        -0.04098685085773468,
        -0.03297103941440582,
        -0.03636986017227173,
        0.05313669890165329,
        0.0531369149684906,
        0.032460734248161316,
        -0.049185290932655334,
        0.03417722508311272,
        0.05314859747886658,
        0.04764893278479576,
        0.026384862139821053,
        -0.04228353500366211,
        -0.04701586440205574,
        -0.05312268063426018,
        0.035028811544179916,
        -0.051918864250183105,
        0.01912694238126278,
        -0.005715631414204836,
        -0.013917653821408749,
        -0.0485939122736454,
        0.051390115171670914,
        0.04497629031538963,
        -0.032070521265268326,
        0.03690093383193016,
        0.021518835797905922,
        -0.05232276767492294,
        -0.015803590416908264,
        -0.030125195160508156,
        -0.053145881742239,
        0.02710076980292797,
        0.05074213072657585,
        -0.053144123405218124,
        0.010388877242803574,
        0.05308619141578674,
        0.027680443599820137,
        0.051943566650152206,
        -0.053102076053619385,
        -0.05311141163110733,
        0.00013340040459297597,
        -0.053023647516965866,
        -0.00425210315734148,
        -0.04017164558172226,
        -0.052983127534389496,
        -0.05283937603235245,
        -0.05086442083120346,
        0.053135596215724945,
        0.017990589141845703,
        -0.04412925988435745,
        0.027254467830061913,
        -0.05192648619413376,
        -0.05304620787501335,
        -0.05124611407518387,
        -0.053069304674863815,
        0.0489659309387207,
        -0.05065290257334709,
        -0.05297546833753586,
        -0.05223917216062546,
        0.04665643721818924,
        0.052782364189624786,
        0.053122833371162415,
        -0.034261275082826614,
        0.04386286064982414,
        -0.05313088372349739,
        -0.04521733149886131,
        0.052386317402124405,
        -0.0408921092748642,
        -0.052676811814308167,
        0.04999915510416031,
        -0.052853260189294815,
        -0.04561600461602211,
        0.052991174161434174,
        -0.011621412821114063,
        -0.04908314347267151,
        0.04786024987697601,
        0.053126025944948196,
        -0.04579559713602066,
        0.051588501781225204,
        0.05240383744239807,
        -0.051929403096437454,
        -0.038643211126327515,
        0.034728117287158966,
        -0.052703630179166794,
        -0.05312805995345116,
        -0.04587570205330849,
        -0.03414829820394516,
        0.04741133376955986,
        -0.05284491926431656,
        -0.00459024403244257,
        0.011802778579294682,
        0.05249738693237305,
        -0.05180696025490761,
        0.05211745202541351,
        0.02690526284277439,
        0.0530252568423748,
        0.044938962906599045,
        -0.04625485837459564,
        -0.039071060717105865,
        0.0372549332678318,
        -0.041518982499837875,
        -0.04995869845151901,
        0.05158484727144241,
        -0.012078925967216492,
        0.052929431200027466,
        -0.0530797503888607,
        0.053118038922548294,
        -0.04709876701235771,
        0.009557834826409817,
        -0.027789680287241936,
        0.0229477621614933,
        0.053114891052246094,
        -0.04200439155101776,
        -0.043927423655986786,
        0.04962978884577751,
        0.052832216024398804,
        -0.053112585097551346,
        0.042575493454933167,
        0.0181579552590847,
        0.03220801800489426,
        0.052971407771110535,
        -0.04828028008341789,
        0.049994297325611115,
        -0.053128913044929504,
        -0.045107632875442505,
        0.0436200350522995,
        0.03907158225774765,
        -0.051231153309345245,
        0.05309493839740753,
        0.05297193303704262,
        0.053093716502189636,
        -0.027361055836081505,
        0.05214715749025345,
        0.04929794743657112,
        0.053083837032318115,
        -0.003679792396724224,
        0.03763575851917267,
        -0.023629454895853996,
        -0.024496112018823624,
        0.0530514158308506,
        0.0522957369685173,
        -0.04999562352895737,
        0.052937254309654236,
        -0.050401970744132996,
        0.052634622901678085,
        0.03911944851279259,
        0.03164047375321388,
        0.04022485017776489,
        -0.0523206852376461,
        0.053064074367284775,
        -0.042797189205884933,
        0.0531243272125721,
        0.015722651034593582,
        0.05193872004747391,
        0.05053805559873581,
        0.013309367932379246,
        -0.053070101886987686,
        0.05258297175168991,
        -0.01925276406109333,
        0.050845082849264145,
        0.04446966201066971,
        0.041501808911561966,
        -0.05311988666653633,
        -0.052828531712293625,
        0.0483543835580349,
        -0.053120438009500504,
        0.05045933648943901,
        0.015051553025841713,
        0.053024716675281525,
        0.053072839975357056,
        -0.04755602777004242,
        -0.009010810405015945,
        0.053114525973796844,
        0.05275711044669151,
        0.03605703264474869,
        0.04730568081140518,
        -0.05041314288973808,
        0.05229320749640465,
        0.02540208399295807,
        -0.03149335831403732,
        -0.04808191955089569,
        0.014912309125065804,
        -0.03704536333680153,
        -0.03604435548186302,
        -0.05305315554141998,
        0.03906412422657013,
        -0.05036426708102226,
        -0.011810178868472576,
        0.05304941162467003,
        0.03589603677392006,
        0.016299787908792496,
        0.04475971683859825,
        0.050422582775354385,
        -0.0060232109390199184,
        -0.032431334257125854,
        -0.04066537693142891,
        0.05132625252008438,
        -0.04347476363182068,
        0.05262843891978264,
        0.052660271525382996,
        0.04489539563655853,
        -0.051053985953330994,
        -0.04795340448617935,
        0.05234203487634659,
        -0.049962300807237625,
        0.023377101868391037,
        -0.052466265857219696,
        -0.04733634367585182,
        0.04463563486933708,
        -0.04604830592870712,
        -0.05060627683997154,
        -0.024837208911776543,
        -0.024438999593257904,
        -0.011354079470038414,
        -0.019422974437475204,
        -0.05191764980554581,
        0.04686228185892105,
        -0.04701819270849228,
        0.022109050303697586,
        0.008602460846304893,
        0.032856445759534836,
        -0.025523290038108826,
        0.02968243509531021,
        0.04008873179554939,
        0.05089704692363739,
        -0.022247102111577988,
        0.0027227106038480997,
        0.03644662722945213,
        -0.05310199782252312,
        0.0531465969979763,
        0.050676386803388596,
        -0.05278646573424339,
        0.052838996052742004,
        0.04659649357199669,
        0.03277399018406868,
        -0.03680146485567093,
        0.05252564698457718,
        -0.04198867455124855,
        0.03553842380642891,
        -0.03838440030813217,
        0.04846818000078201,
        0.03454771265387535,
        -0.053141288459300995,
        -0.05014539510011673,
        -0.034227240830659866,
        0.018046582117676735,
        0.0530756339430809,
        -0.052273452281951904,
        -0.0530492328107357,
        0.03446025028824806,
        -0.041917093098163605,
        0.04733849689364433,
        0.037152599543333054,
        -0.0404973030090332,
        -0.020209981128573418,
        0.05219447612762451,
        -0.051145829260349274,
        0.053125303238630295,
        0.03767481446266174,
        0.04978315159678459,
        -0.05287190526723862,
        -0.04883211478590965,
        -0.05299028754234314,
        -0.027501678094267845,
        -0.05242794379591942,
        -0.05207283794879913,
        -0.018317127600312233,
        0.05193581432104111,
        0.050233837217092514,
        0.02242768555879593,
        0.05314188078045845,
        -0.04488055780529976,
        0.052725132554769516,
        -0.04564623534679413,
        0.009471450932323933,
        0.036024291068315506,
        0.00851637963205576,
        0.05314863473176956,
        0.04987945780158043,
        -0.0074796355329453945,
        0.05308748409152031,
        -0.04484836012125015,
        0.05312458053231239,
        0.04483120143413544,
        0.030586525797843933,
        0.05178700014948845,
        -0.05225988104939461,
        -0.05149635300040245,
        -0.02201404981315136,
        -0.05293625220656395,
        0.023437535390257835,
        -0.04463215544819832,
        0.02008156292140484,
        0.0518072284758091,
        0.05216316133737564,
        -0.0531010739505291,
        -0.034874316304922104,
        -0.045478276908397675,
        -0.0487961620092392,
        0.052622631192207336,
        -0.04796263575553894,
        -0.05246300995349884,
        -0.05178720876574516,
        -0.03640619292855263,
        -0.02217100001871586,
        0.03363538160920143,
        0.01032944768667221,
        0.04702899232506752,
        0.051463782787323,
        -0.0520181730389595,
        0.05180909484624863,
        0.05176077410578728,
        -0.03666428476572037,
        -0.003911075182259083,
        0.052651531994342804,
        -0.05287705734372139,
        0.05272950977087021,
        0.05302537605166435,
        0.006222566589713097,
        -0.048471227288246155,
        -0.053048182278871536,
        -0.05267138406634331,
        -0.04591452702879906,
        -0.046218302100896835,
        -0.04432801902294159,
        -0.04986751452088356,
        -0.01403062790632248,
        0.04697165638208389,
        0.05299296975135803,
        0.053099166601896286,
        0.00019021271145902574,
        -0.05299348384141922,
        -0.05297166109085083,
        0.052757374942302704,
        0.05234929546713829,
        0.03284290432929993,
        0.05314610153436661,
        -0.001630821148864925,
        0.04068741574883461,
        0.05118964985013008,
        -0.05239522084593773,
        0.052898772060871124,
        -0.01433542463928461,
        -0.04961785674095154,
        -0.04526505991816521,
        0.03095022588968277,
        -0.05281352996826172,
        0.05302410572767258,
        -0.047857314348220825,
        -0.013013401068747044,
        -0.03117505833506584,
        0.053006209433078766,
        0.024207673966884613,
        -0.040594879537820816,
        -0.05306493863463402,
        -0.04908125847578049,
        -0.053058262914419174,
        -0.05049947276711464,
        0.051142968237400055,
        0.01575288735330105,
        -0.05102553218603134,
        -0.05141877382993698,
        -0.05200129747390747,
        0.04043092951178551,
        -0.007740129716694355,
        -0.006604289636015892,
        0.04411429166793823,
        0.004525250289589167,
        -0.050297681242227554,
        -0.05314865708351135,
        0.04940111190080643,
        -0.052701711654663086,
        -0.052933841943740845,
        0.0022479204926639795,
        0.04195433855056763,
        0.0432177372276783,
        0.0525408498942852,
        0.05251622945070267,
        0.04438005015254021,
        0.05300706624984741,
        -0.04405267909169197,
        0.05050799250602722,
        0.0528804175555706,
        -0.03635108098387718,
        0.04731994867324829,
        0.0421181358397007,
        0.03741243854165077,
        0.013415667228400707,
        -0.052404023706912994,
        0.051795925945043564,
        -0.02800610475242138,
        0.0507974773645401,
        0.04548066109418869,
        0.05157225579023361,
        -0.05308026447892189,
        -0.04383370280265808,
        -0.024902215227484703,
        0.05154711380600929,
        0.05281795933842659,
        -0.05288294330239296,
        0.051921114325523376,
        0.02628730982542038,
        -0.05153682455420494,
        0.04879835247993469,
        -0.052863266319036484,
        0.05263441428542137,
        0.025103822350502014,
        0.05312689021229744,
        -0.029368318617343903,
        0.05308083817362785,
        0.050275303423404694,
        -0.044118043035268784,
        -0.04098692908883095,
        -0.05108562111854553,
        -0.008685579523444176,
        -0.02837998792529106,
        0.034716445952653885,
        -0.021706609055399895,
        -0.008560332469642162,
        0.052935294806957245,
        0.04716041311621666,
        -0.0527467280626297,
        0.05270325765013695,
        -0.051673773676157,
        -0.05304636433720589,
        0.05151345208287239,
        0.04334969446063042,
        -0.027008727192878723,
        -0.00199069082736969,
        -0.052885692566633224,
        -0.03516875579953194,
        0.05292710289359093,
        0.023763621225953102,
        0.0007217784877866507,
        -0.04031015932559967,
        -0.052642934024333954,
        0.04873647913336754,
        0.0475601963698864,
        0.05294650420546532,
        0.00477990135550499,
        0.015436528250575066,
        0.029987482354044914,
        -0.052728164941072464,
        0.052176982164382935,
        0.04929384961724281
    ],
    [
        -0.02234199084341526,
        0.047163113951683044,
        0.016584770753979683,
        0.014783966355025768,
        0.007526868488639593,
        -0.02326803281903267,
        0.026855312287807465,
        -0.04382956027984619,
        0.05017710477113724,
        -0.0026363201905041933,
        0.049352582544088364,
        0.022571876645088196,
        -0.054778803139925,
        -0.021592821925878525,
        -0.0636523887515068,
        0.0663762092590332,
        0.022263772785663605,
        0.04812018573284149,
        -0.060926198959350586,
        -0.04085369408130646,
        -0.0016216047806665301,
        0.0654764324426651,
        -0.014056013897061348,
        0.0008624417241662741,
        0.03870660066604614,
        0.03079594485461712,
        0.05862650275230408,
        -0.008229282684624195,
        0.013172471895813942,
        0.0326952300965786,
        0.03868049383163452,
        -0.011475913226604462,
        -0.0587330088019371,
        -0.06363508105278015,
        0.060318250209093094,
        0.017489520832896233,
        -0.05628954619169235,
        0.015298381447792053,
        0.009153119288384914,
        -0.03485820069909096,
        0.03381979465484619,
        0.017749495804309845,
        0.02142067439854145,
        -0.06298275291919708,
        -0.0023696848656982183,
        0.05647311359643936,
        0.008207439444959164,
        0.026858096942305565,
        -0.023534364998340607,
        0.053566597402095795,
        0.039933476597070694,
        0.06669649481773376,
        -0.024366552010178566,
        0.017724892124533653,
        0.02723756432533264,
        -0.03343573957681656,
        -0.0010564662516117096,
        -0.03457287698984146,
        0.03023347444832325,
        0.012441773898899555,
        -0.04918111115694046,
        0.0382220633327961,
        -0.05078743025660515,
        0.06209157407283783,
        -0.06261692941188812,
        -0.023928944021463394,
        0.01128228846937418,
        -0.03289686515927315,
        -0.06314725428819656,
        -0.00799847673624754,
        -0.06619744747877121,
        -0.069891557097435,
        0.06519712507724762,
        -0.016010718420147896,
        0.032116107642650604,
        0.008540354669094086,
        0.007231056224554777,
        0.06976035237312317,
        -0.029080213978886604,
        -0.04734298586845398,
        0.018403498455882072,
        0.06863207370042801,
        -0.015621992759406567,
        0.04348602518439293,
        0.057559363543987274,
        0.046376779675483704,
        0.06837047636508942,
        -0.04382837563753128,
        0.04866931587457657,
        0.05123460665345192,
        0.054902881383895874,
        0.04938272386789322,
        -0.06927937269210815,
        0.0048722573556005955,
        0.018146943300962448,
        0.007631735876202583,
        0.03614696487784386,
        -0.06472797691822052,
        0.04322008788585663,
        0.0008723539649508893,
        -0.05251263827085495,
        0.007074909750372171,
        0.06268810480833054,
        0.05223395675420761,
        0.034997131675481796,
        -0.06442537903785706,
        -0.045632585883140564,
        0.04444430395960808,
        -0.04557652398943901,
        0.021650901064276695,
        -0.06089543551206589,
        -0.030338097363710403,
        -0.05719144269824028,
        -0.021274376660585403,
        -0.016842713579535484,
        -0.014769881032407284,
        0.05912281572818756,
        -0.015182632952928543,
        0.06878280639648438,
        0.029167070984840393,
        -0.04276669770479202,
        0.06001647561788559,
        0.02168641984462738,
        -0.06115223467350006,
        -0.0658995732665062,
        -0.04584157094359398,
        -0.05787159129977226,
        -0.04959016665816307,
        0.05139095336198807,
        0.04759964346885681,
        0.011285506188869476,
        -0.05502965673804283,
        -0.05953873693943024,
        0.026430567726492882,
        -0.04087988659739494,
        -0.06347335129976273,
        0.020052066072821617,
        0.020907923579216003,
        -0.0609101839363575,
        0.06397459656000137,
        0.0670500248670578,
        0.010983605869114399,
        0.0577065534889698,
        0.041899628937244415,
        0.05502248555421829,
        -0.056910425424575806,
        0.067695252597332,
        -0.06124003231525421,
        0.04705868288874626,
        0.06671173870563507,
        0.039318669587373734,
        -0.06796041131019592,
        -0.034023065119981766,
        -0.06136775761842728,
        0.0399436391890049,
        0.008105061016976833,
        0.023831985890865326,
        0.03655552119016647,
        -0.036856427788734436,
        0.033308520913124084,
        0.06157917156815529,
        0.060980718582868576,
        0.05649833753705025,
        -0.033887654542922974,
        0.044471416622400284,
        0.011726637370884418,
        -0.020897382870316505,
        0.031022651121020317,
        0.019891265779733658,
        0.033514175564050674,
        0.030111730098724365,
        0.006886443588882685,
        -0.02381330356001854,
        -0.06083232909440994,
        0.025511950254440308,
        0.03800709918141365,
        -0.06567706167697906,
        -0.036986324936151505,
        0.048658426851034164,
        -0.06386643648147583,
        0.04759928956627846,
        0.06989870220422745,
        0.0037161700893193483,
        0.054404910653829575,
        0.04918128624558449,
        -0.056054092943668365,
        -0.056626368314027786,
        0.05120055750012398,
        -0.05329040437936783,
        -0.040840376168489456,
        -0.058638796210289,
        0.04793597757816315,
        0.0012682683300226927,
        0.006053435616195202,
        0.00558148929849267,
        -0.018585536628961563,
        -0.05787434056401253,
        -0.036311909556388855,
        -0.06312399357557297,
        0.0172915980219841,
        -0.028888674452900887,
        -0.028020476922392845,
        -0.01680195890367031,
        -0.03010660596191883,
        -0.06624921411275864,
        0.0602199025452137,
        0.003150296863168478,
        0.017366865649819374,
        -0.004465334117412567,
        0.036331791430711746,
        -0.051864732056856155,
        0.012204606086015701,
        -0.01099430862814188,
        0.022566528990864754,
        -0.033526428043842316,
        0.020427612587809563,
        0.03565589338541031,
        -0.028775304555892944,
        0.06569010764360428,
        -0.01994059421122074,
        0.001142079709097743,
        0.06793765723705292,
        -0.027217959985136986,
        -0.06189468502998352,
        0.06659024953842163,
        -0.0335254967212677,
        0.016864534467458725,
        0.029730860143899918,
        -0.034858908504247665,
        -0.05986034870147705,
        -0.035006918013095856,
        -0.061224594712257385,
        -0.044178929179906845,
        0.030877158045768738,
        -0.05162108317017555,
        0.06911318749189377,
        0.061865463852882385,
        0.02904031239449978,
        -0.04463459923863411,
        0.06829803436994553,
        0.013026781380176544,
        0.06918208301067352,
        -0.06936819106340408,
        0.058617737144231796,
        0.037585627287626266,
        -0.03958558663725853,
        -0.06674019992351532,
        0.007575281895697117,
        -0.004427220206707716,
        -0.04631141945719719,
        -0.05459123104810715,
        -0.06569871306419373,
        0.031217211857438087,
        -0.06538639962673187,
        -0.06428899616003036,
        0.06243663281202316,
        -0.061234720051288605,
        -0.03827768936753273,
        0.056351516395807266,
        0.029486780986189842,
        0.06854569911956787,
        -0.03352468088269234,
        -0.0028213041368871927,
        0.019851740449666977,
        0.006373666692525148,
        0.040580619126558304,
        -0.005657339934259653,
        0.05484756454825401,
        0.02804059162735939,
        0.01890624314546585,
        0.012556301429867744,
        -0.05409245193004608,
        0.06830530613660812,
        -0.06082483008503914,
        -0.040774233639240265,
        0.021357590332627296,
        -0.046346258372068405,
        0.02015806920826435,
        -0.06240072846412659,
        -0.041164226830005646,
        -0.06515086442232132,
        -0.05971578508615494,
        -0.06555603444576263,
        0.057578641921281815,
        0.062999427318573,
        -0.047342754900455475,
        0.01152348704636097,
        0.054803669452667236,
        0.036912184208631516,
        0.03397534042596817,
        -0.01842147670686245,
        0.028471125289797783,
        -0.020038822665810585,
        0.05805226415395737,
        0.055988334119319916,
        -0.062471937388181686,
        0.05626577511429787,
        0.06567734479904175,
        -0.05428661033511162,
        0.035108666867017746,
        0.06421466171741486,
        0.05187566205859184,
        -0.049652837216854095,
        0.06110907346010208,
        -0.024776319041848183,
        0.06021144613623619,
        -0.06246577948331833,
        0.009647420607507229,
        -0.0266552921384573,
        -0.06888318061828613,
        0.014474639669060707,
        -0.042858779430389404,
        -0.014732230454683304,
        0.0463978536427021,
        -0.021387284621596336,
        -0.04960862174630165,
        0.04185163602232933,
        -0.060858339071273804,
        0.047708429396152496,
        0.015001782216131687,
        -0.04713049530982971,
        0.001950792851857841,
        0.044585391879081726,
        0.026371359825134277,
        0.055355411022901535,
        -0.04032502323389053,
        -0.02851036563515663,
        0.004685880616307259,
        0.0017719839233905077,
        -0.06416388601064682,
        0.03805368393659592,
        0.009552414529025555,
        -0.0528658963739872,
        -0.05670300871133804,
        0.05617126077413559,
        0.02597900666296482,
        -0.021052682772278786,
        0.06406287848949432,
        -0.0301929023116827,
        0.012916290201246738,
        0.016686394810676575,
        0.06914272904396057,
        -0.06826211512088776,
        -0.035867832601070404,
        0.05668831244111061,
        0.012809471227228642,
        0.02202758379280567,
        0.05289875715970993,
        -0.049548469483852386,
        0.05904020741581917,
        0.04236696660518646,
        0.028661519289016724,
        -0.04603990912437439,
        -0.05880960449576378,
        -0.029073981568217278,
        0.054624252021312714,
        -0.06594660878181458,
        -0.018710190430283546,
        0.05263838544487953,
        -0.06750170886516571,
        0.06331216543912888,
        -0.0057815248146653175,
        0.012655125930905342,
        0.0051063839346170425,
        -0.004121322650462389,
        -0.060365691781044006,
        0.06691032648086548,
        -0.013555456884205341,
        0.04944099113345146,
        -0.05733537673950195,
        -0.017967138439416885,
        0.027214819565415382,
        0.057128723710775375,
        0.0600665807723999,
        0.031981222331523895,
        0.0401558093726635,
        -0.04440087080001831,
        0.04452007636427879,
        0.057030681520700455,
        0.04959609732031822,
        0.013616190291941166,
        -0.06440964341163635,
        -0.05387812852859497,
        0.04729921743273735,
        0.061169300228357315,
        0.03628731146454811,
        -0.016679244115948677,
        -0.060160212218761444,
        -0.027901500463485718,
        -0.026998396962881088,
        -0.06806100159883499,
        0.011123289354145527,
        0.018375014886260033,
        -0.0003038301947526634,
        -0.04959855601191521,
        0.06507965922355652,
        0.05803544819355011,
        0.030732950195670128,
        -0.009232535026967525,
        -0.048830028623342514,
        -0.012649200856685638,
        0.04733741283416748,
        -0.001414110534824431,
        -0.03473549708724022,
        -0.01922411099076271,
        0.004429226275533438,
        0.044419754296541214,
        -0.04486723244190216,
        -0.02983066439628601,
        -0.01883544586598873,
        -0.01834399811923504,
        -0.06355168670415878,
        0.057626768946647644,
        0.018345845863223076,
        0.053994130343198776,
        -0.06382229179143906,
        -0.015143600292503834,
        0.03465253487229347,
        0.03702583536505699,
        0.05645187571644783,
        0.039326321333646774,
        -0.0151794059202075,
        0.0476759597659111,
        -0.05786820873618126,
        -0.04009467735886574,
        0.033163949847221375,
        -0.0483001284301281,
        0.018781913444399834,
        0.005751737859100103,
        -0.05175560712814331,
        0.059788160026073456,
        -0.05182644724845886,
        -0.06329628825187683,
        -0.03076951764523983,
        0.02555909939110279,
        -0.04771358147263527,
        -0.04279059171676636,
        -0.002404408995062113,
        0.037819862365722656,
        -0.004519493784755468,
        0.05969098582863808,
        0.06250517815351486,
        0.059327367693185806,
        0.05225231498479843,
        0.05301130935549736,
        0.025609416887164116,
        0.014566028490662575,
        -0.06409025192260742,
        0.06662392616271973,
        0.037420742213726044,
        -0.050624147057533264,
        -0.016777418553829193,
        -0.0037297438830137253,
        -0.022352218627929688,
        0.019913222640752792,
        -0.04876226931810379,
        -0.02825629711151123,
        -0.0017627866473048925,
        -0.05030865594744682,
        0.034175753593444824,
        -0.034571584314107895,
        0.027040675282478333,
        -0.067747563123703,
        0.01886013336479664,
        0.059109706431627274,
        0.06837421655654907,
        -0.03293536975979805,
        0.05751768872141838,
        -0.06391216814517975,
        -0.06344760954380035,
        0.02264019288122654,
        0.06366591900587082,
        0.013953621499240398,
        0.04885187745094299,
        -0.06632920354604721,
        -0.041171275079250336,
        0.023538116365671158,
        0.05442069098353386,
        -0.011989637278020382,
        -0.05710570886731148,
        -0.051400844007730484,
        -0.04155261069536209,
        -0.06173021346330643,
        -0.0018368855817243457,
        0.03846241533756256,
        0.059531066566705704,
        0.05720464885234833,
        -0.005158388987183571,
        -0.0520302839577198,
        -0.04372229427099228,
        -0.03783724084496498,
        0.01676628366112709,
        -0.05383335053920746,
        0.020021531730890274,
        -0.01829398237168789,
        0.004442790523171425,
        0.05817904695868492,
        0.04735228791832924,
        0.05566639453172684,
        0.06948845833539963,
        -0.062409888952970505,
        -0.03431721404194832,
        -0.003902572439983487,
        -0.025558102875947952,
        0.015259542502462864,
        -0.015908172354102135,
        0.04951225966215134,
        0.01127699576318264,
        -0.02313055470585823,
        -0.06977764517068863,
        0.008008163422346115,
        0.06888123601675034
    ],
    [
        -0.0048622144386172295,
        0.055546607822179794,
        0.020546598359942436,
        0.028683055192232132,
        0.008184011094272137,
        -0.005148426163941622,
        0.012726540677249432,
        -0.04393250122666359,
        0.05074158310890198,
        -0.012236225418746471,
        0.045162383466959,
        0.025020139291882515,
        -0.054776132106781006,
        -0.01827440969645977,
        -0.0660882443189621,
        0.0706256628036499,
        0.017363673076033592,
        0.052490029484033585,
        -0.05720492824912071,
        -0.0045297215692698956,
        -0.01877397671341896,
        0.06448911130428314,
        -0.023077793419361115,
        -0.007711857091635466,
        0.04232596978545189,
        0.019763702526688576,
        0.05467050522565842,
        -0.008642726577818394,
        0.003945373930037022,
        0.0116659514605999,
        0.04208014905452728,
        -0.024135449901223183,
        -0.0538523867726326,
        -0.05820949375629425,
        0.06049877405166626,
        0.00873705092817545,
        -0.05889248102903366,
        -0.008622465655207634,
        0.0029318910092115402,
        -0.03453734889626503,
        0.02347259595990181,
        0.014325698837637901,
        0.029833460226655006,
        -0.06855400651693344,
        0.02007121779024601,
        0.059125401079654694,
        0.009618070907890797,
        0.009859121404588223,
        -0.04470469057559967,
        0.05658486858010292,
        0.027391141280531883,
        0.06961609423160553,
        -0.0006810562335886061,
        0.018627023324370384,
        0.037226274609565735,
        -0.02230324037373066,
        0.009721233509480953,
        -0.024795079603791237,
        0.00884085614234209,
        0.031446848064661026,
        -0.03866621479392052,
        0.04522768408060074,
        -0.05208751931786537,
        0.05885271355509758,
        -0.06220635026693344,
        -0.04133404418826103,
        0.031025147065520287,
        -0.02231854386627674,
        -0.06542495638132095,
        -0.03489688038825989,
        -0.06336607784032822,
        -0.07317960262298584,
        0.0686795711517334,
        -0.006557903718203306,
        -0.0017321329796686769,
        -0.00535598723217845,
        -0.017680054530501366,
        0.07298348098993301,
        -0.022341515868902206,
        -0.03636479377746582,
        0.019534580409526825,
        0.07244783639907837,
        -0.026606082916259766,
        0.03063712827861309,
        0.04324769973754883,
        0.052276648581027985,
        0.0718078464269638,
        -0.04336904361844063,
        0.03836793825030327,
        0.0608120858669281,
        0.06550893932580948,
        0.044937923550605774,
        -0.07034570723772049,
        0.005944684147834778,
        -0.000936719065066427,
        0.0054316772148013115,
        0.022351769730448723,
        -0.06831666827201843,
        0.030863214284181595,
        0.008253763429820538,
        -0.060666441917419434,
        -0.0010444259969517589,
        0.0675847977399826,
        0.047885436564683914,
        0.04110827296972275,
        -0.06498689949512482,
        -0.059012435376644135,
        0.03043501079082489,
        -0.040612973272800446,
        0.000786431657616049,
        -0.060277365148067474,
        -0.044499948620796204,
        -0.05815780162811279,
        -0.033155519515275955,
        -0.017438029870390892,
        0.00424966448917985,
        0.0528649277985096,
        0.01952863670885563,
        0.0705198422074318,
        0.017230838537216187,
        -0.05365221947431564,
        0.05584125965833664,
        0.017888545989990234,
        -0.06165705621242523,
        -0.07014045864343643,
        -0.059985607862472534,
        -0.05157756432890892,
        -0.031205592676997185,
        0.06056840345263481,
        0.03060268796980381,
        -0.014077112078666687,
        -0.06215145066380501,
        -0.06344317644834518,
        0.032803408801555634,
        -0.051756810396909714,
        -0.06633840501308441,
        -0.0031854137778282166,
        0.0027378154918551445,
        -0.06439336389303207,
        0.06190759688615799,
        0.06891524791717529,
        0.018549494445323944,
        0.06165424361824989,
        0.0535179004073143,
        0.06048785522580147,
        -0.01817457564175129,
        0.06968902796506882,
        -0.06542913615703583,
        0.04098521173000336,
        0.06924334168434143,
        0.0339537113904953,
        -0.07038333266973495,
        -0.04609401896595955,
        -0.06322050094604492,
        0.02422529086470604,
        0.007704840041697025,
        0.020211653783917427,
        0.04317918419837952,
        -0.02671690098941326,
        0.019440822303295135,
        0.057615578174591064,
        0.06429513543844223,
        0.06027046963572502,
        -0.004829041659832001,
        0.05386517941951752,
        0.008906669914722443,
        -0.038620591163635254,
        0.018727634102106094,
        0.027425339445471764,
        0.02535953000187874,
        0.041994858533144,
        0.02780573070049286,
        -0.014946192502975464,
        -0.056996967643499374,
        0.017054332420229912,
        0.030816700309515,
        -0.06873110681772232,
        -0.036944273859262466,
        0.05288148671388626,
        -0.0604705810546875,
        0.04915530979633331,
        0.07315532118082047,
        0.009583081118762493,
        0.03936627879738808,
        0.05229847878217697,
        -0.05121546983718872,
        -0.04745829477906227,
        0.052895449101924896,
        -0.05726756900548935,
        -0.03892192617058754,
        -0.058543987572193146,
        0.03478410094976425,
        0.005513133946806192,
        -0.01922200247645378,
        0.01999123953282833,
        0.016847848892211914,
        -0.05170436203479767,
        -0.022270001471042633,
        -0.0566285103559494,
        0.008612412959337234,
        -0.032428596168756485,
        -0.02530665509402752,
        -0.01625625230371952,
        -0.011355612426996231,
        -0.068064384162426,
        0.056728873401880264,
        0.012920884415507317,
        0.0102515434846282,
        -0.018779702484607697,
        0.034419525414705276,
        -0.05289372429251671,
        0.012279330752789974,
        0.005030459258705378,
        0.032795410603284836,
        -0.022577542811632156,
        0.04107527807354927,
        0.051775965839624405,
        -0.012522944249212742,
        0.06662272661924362,
        -0.014926053583621979,
        0.024882083758711815,
        0.07068035751581192,
        -0.0262758769094944,
        -0.06787052005529404,
        0.06701148301362991,
        -0.03829377517104149,
        0.010319208726286888,
        0.024657070636749268,
        -0.034060534089803696,
        -0.06326056271791458,
        -0.035671915858983994,
        -0.056720733642578125,
        -0.04764477163553238,
        0.03441029414534569,
        -0.035889796912670135,
        0.07210297882556915,
        0.06270980834960938,
        0.023266475647687912,
        -0.045408401638269424,
        0.0716736689209938,
        0.01578485034406185,
        0.07222078740596771,
        -0.0725109726190567,
        0.05021362006664276,
        0.03121347352862358,
        -0.03494756296277046,
        -0.06993479281663895,
        -0.018099870532751083,
        0.00408825371414423,
        -0.04727734252810478,
        -0.046466004103422165,
        -0.06968914717435837,
        0.042883943766355515,
        -0.06775377690792084,
        -0.06594712287187576,
        0.06662492454051971,
        -0.06190788373351097,
        -0.03625081107020378,
        0.058497827500104904,
        0.030151523649692535,
        0.07203521579504013,
        -0.039365850389003754,
        0.0011522273998707533,
        0.0007286075269803405,
        0.017352459952235222,
        0.04253169521689415,
        0.020227201282978058,
        0.059476129710674286,
        0.00407346710562706,
        0.011859415099024773,
        0.023825431242585182,
        -0.046611238270998,
        0.0684751570224762,
        -0.05859711393713951,
        -0.0401366725564003,
        0.03337046876549721,
        -0.04926100745797157,
        0.009712614119052887,
        -0.04160153493285179,
        -0.044885050505399704,
        -0.04983564838767052,
        -0.06576132029294968,
        -0.0659869834780693,
        0.05684347450733185,
        0.046732883900403976,
        -0.0390259213745594,
        0.01136478316038847,
        0.04606343060731888,
        0.0371616929769516,
        0.04056483507156372,
        0.001924194977618754,
        0.04394390434026718,
        -0.018409375101327896,
        0.06276264041662216,
        0.04868801310658455,
        -0.06770650297403336,
        0.06310265511274338,
        0.06140543892979622,
        -0.050702545791864395,
        0.04075333848595619,
        0.05758332088589668,
        0.05076916888356209,
        -0.05914868786931038,
        0.058788396418094635,
        -0.051244959235191345,
        0.06273096799850464,
        -0.0684364065527916,
        0.0055724685080349445,
        -0.023004068061709404,
        -0.07274796813726425,
        0.008179287426173687,
        -0.031350746750831604,
        -0.011075740680098534,
        0.045234885066747665,
        -0.04177112504839897,
        -0.045477237552404404,
        0.05180893838405609,
        -0.05819880962371826,
        0.05084795132279396,
        0.0023677388671785593,
        -0.05709361284971237,
        0.02095043659210205,
        0.047430649399757385,
        0.004826417192816734,
        0.05498693883419037,
        -0.03718554601073265,
        -0.030858905985951424,
        -0.017757365480065346,
        0.0060364301316440105,
        -0.06264904886484146,
        0.014791016466915607,
        -0.013383544981479645,
        -0.049730535596609116,
        -0.06497932225465775,
        0.06109621748328209,
        -0.0005707750096917152,
        0.004793302621692419,
        0.0688551738858223,
        -0.03462119773030281,
        0.02567232958972454,
        0.019048692658543587,
        0.07251020520925522,
        -0.07163383811712265,
        -0.015780821442604065,
        0.0664052814245224,
        0.023704983294010162,
        0.008962294086813927,
        0.05074300244450569,
        -0.04518692567944527,
        0.06837116926908493,
        0.015489206649363041,
        0.009069414809346199,
        -0.02873951941728592,
        -0.041124749928712845,
        -0.014382390305399895,
        0.05339883640408516,
        -0.06639690697193146,
        -0.0022540693171322346,
        0.05230795592069626,
        -0.0688723623752594,
        0.06574904173612595,
        0.0051675820723176,
        -0.016917690634727478,
        0.0017949825851246715,
        -0.006997643504291773,
        -0.06000206992030144,
        0.06505240499973297,
        -0.01373213715851307,
        0.01496084127575159,
        -0.05848902463912964,
        0.005324294324964285,
        0.0225076861679554,
        0.05071989446878433,
        0.06341597437858582,
        0.02140161581337452,
        0.021648388355970383,
        -0.04675877466797829,
        0.044694527983665466,
        0.055161815136671066,
        0.04158381372690201,
        0.019946986809372902,
        -0.06581054627895355,
        -0.06310243904590607,
        0.05600913614034653,
        0.06949492543935776,
        0.029287897050380707,
        -0.02112383022904396,
        -0.06740226596593857,
        -0.029727447777986526,
        -0.04080821946263313,
        -0.06811276078224182,
        -0.005441823974251747,
        0.0261235274374485,
        -0.004761772230267525,
        -0.05501454696059227,
        0.0671088695526123,
        0.05781885236501694,
        0.04229311645030975,
        -0.019454361870884895,
        -0.05489096790552139,
        0.0037460671737790108,
        0.040751125663518906,
        0.008017522282898426,
        -0.03374005854129791,
        -0.012483421713113785,
        0.02891564555466175,
        0.03855670243501663,
        -0.04197292402386665,
        -0.03632360324263573,
        0.0035318275913596153,
        -0.02503071166574955,
        -0.06750395894050598,
        0.061799898743629456,
        0.003483734093606472,
        0.05518237128853798,
        -0.06686893850564957,
        -0.017029941082000732,
        0.04179280251264572,
        0.04060729965567589,
        0.055522989481687546,
        -0.0014461452374234796,
        -0.0064715854823589325,
        0.04316747561097145,
        -0.05420684814453125,
        -0.05498862639069557,
        0.038052331656217575,
        -0.038372814655303955,
        0.008179662749171257,
        0.0007328724022954702,
        -0.05255960673093796,
        0.05056389421224594,
        -0.03863644227385521,
        -0.06374459713697433,
        -0.02659904956817627,
        0.034563057124614716,
        -0.039217423647642136,
        -0.06752680242061615,
        0.01604851521551609,
        0.008130188100039959,
        0.012566507793962955,
        0.0673770159482956,
        0.05816212296485901,
        0.054213106632232666,
        0.05170237645506859,
        0.05402039736509323,
        0.03665553033351898,
        0.02665373496711254,
        -0.06368114054203033,
        0.07037753611803055,
        0.02247794158756733,
        -0.04396265372633934,
        -0.009509787894785404,
        -0.021279960870742798,
        -0.015580002218484879,
        0.010616697371006012,
        -0.053101807832717896,
        -0.0040906150825321674,
        -0.014416040852665901,
        -0.04432849586009979,
        0.013582535088062286,
        -0.035008177161216736,
        0.03595329448580742,
        -0.07101377844810486,
        0.028887126594781876,
        0.055923767387866974,
        0.07075002789497375,
        -0.03510412573814392,
        0.048936545848846436,
        -0.06079358235001564,
        -0.0640537366271019,
        0.0036066551692783833,
        0.05719350650906563,
        0.019478099420666695,
        0.027360348030924797,
        -0.0571613572537899,
        -0.04779570922255516,
        0.037885598838329315,
        0.0520196408033371,
        -0.011412542313337326,
        -0.061655525118112564,
        -0.05706971511244774,
        -0.03946610167622566,
        -0.05556810274720192,
        -0.0021020337007939816,
        0.04194929078221321,
        0.053712259978055954,
        0.06327763944864273,
        0.007791547104716301,
        -0.05661090463399887,
        -0.016711825504899025,
        -0.052914656698703766,
        0.008907657116651535,
        -0.05033523589372635,
        0.005850812420248985,
        -0.02012375369668007,
        0.007876056246459484,
        0.06540824472904205,
        0.03450850769877434,
        0.06272919476032257,
        0.07259602844715118,
        -0.061599668115377426,
        -0.032268740236759186,
        -0.008415699936449528,
        0.0006020648870617151,
        0.0034410441294312477,
        -0.011125465855002403,
        0.03712420165538788,
        0.03365209326148033,
        -0.03786269947886467,
        -0.07360624521970749,
        0.019865622743964195,
        0.07222950458526611
    ],
    [
        -0.0048622144386172295,
        0.055546607822179794,
        0.020546598359942436,
        0.028683055192232132,
        0.008184011094272137,
        -0.005148426163941622,
        0.012726540677249432,
        -0.04393250122666359,
        0.05074158310890198,
        -0.012236225418746471,
        0.045162383466959,
        0.025020139291882515,
        -0.054776132106781006,
        -0.01827440969645977,
        -0.0660882443189621,
        0.0706256628036499,
        0.017363673076033592,
        0.052490029484033585,
        -0.05720492824912071,
        -0.0045297215692698956,
        -0.01877397671341896,
        0.06448911130428314,
        -0.023077793419361115,
        -0.007711857091635466,
        0.04232596978545189,
        0.019763702526688576,
        0.05467050522565842,
        -0.008642726577818394,
        0.003945373930037022,
        0.0116659514605999,
        0.04208014905452728,
        -0.024135449901223183,
        -0.0538523867726326,
        -0.05820949375629425,
        0.06049877405166626,
        0.00873705092817545,
        -0.05889248102903366,
        -0.008622465655207634,
        0.0029318910092115402,
        -0.03453734889626503,
        0.02347259595990181,
        0.014325698837637901,
        0.029833460226655006,
        -0.06855400651693344,
        0.02007121779024601,
        0.059125401079654694,
        0.009618070907890797,
        0.009859121404588223,
        -0.04470469057559967,
        0.05658486858010292,
        0.027391141280531883,
        0.06961609423160553,
        -0.0006810562335886061,
        0.018627023324370384,
        0.037226274609565735,
        -0.02230324037373066,
        0.009721233509480953,
        -0.024795079603791237,
        0.00884085614234209,
        0.031446848064661026,
        -0.03866621479392052,
        0.04522768408060074,
        -0.05208751931786537,
        0.05885271355509758,
        -0.06220635026693344,
        -0.04133404418826103,
        0.031025147065520287,
        -0.02231854386627674,
        -0.06542495638132095,
        -0.03489688038825989,
        -0.06336607784032822,
        -0.07317960262298584,
        0.0686795711517334,
        -0.006557903718203306,
        -0.0017321329796686769,
        -0.00535598723217845,
        -0.017680054530501366,
        0.07298348098993301,
        -0.022341515868902206,
        -0.03636479377746582,
        0.019534580409526825,
        0.07244783639907837,
        -0.026606082916259766,
        0.03063712827861309,
        0.04324769973754883,
        0.052276648581027985,
        0.0718078464269638,
        -0.04336904361844063,
        0.03836793825030327,
        0.0608120858669281,
        0.06550893932580948,
        0.044937923550605774,
        -0.07034570723772049,
        0.005944684147834778,
        -0.000936719065066427,
        0.0054316772148013115,
        0.022351769730448723,
        -0.06831666827201843,
        0.030863214284181595,
        0.008253763429820538,
        -0.060666441917419434,
        -0.0010444259969517589,
        0.0675847977399826,
        0.047885436564683914,
        0.04110827296972275,
        -0.06498689949512482,
        -0.059012435376644135,
        0.03043501079082489,
        -0.040612973272800446,
        0.000786431657616049,
        -0.060277365148067474,
        -0.044499948620796204,
        -0.05815780162811279,
        -0.033155519515275955,
        -0.017438029870390892,
        0.00424966448917985,
        0.0528649277985096,
        0.01952863670885563,
        0.0705198422074318,
        0.017230838537216187,
        -0.05365221947431564,
        0.05584125965833664,
        0.017888545989990234,
        -0.06165705621242523,
        -0.07014045864343643,
        -0.059985607862472534,
        -0.05157756432890892,
        -0.031205592676997185,
        0.06056840345263481,
        0.03060268796980381,
        -0.014077112078666687,
        -0.06215145066380501,
        -0.06344317644834518,
        0.032803408801555634,
        -0.051756810396909714,
        -0.06633840501308441,
        -0.0031854137778282166,
        0.0027378154918551445,
        -0.06439336389303207,
        0.06190759688615799,
        0.06891524791717529,
        0.018549494445323944,
        0.06165424361824989,
        0.0535179004073143,
        0.06048785522580147,
        -0.01817457564175129,
        0.06968902796506882,
        -0.06542913615703583,
        0.04098521173000336,
        0.06924334168434143,
        0.0339537113904953,
        -0.07038333266973495,
        -0.04609401896595955,
        -0.06322050094604492,
        0.02422529086470604,
        0.007704840041697025,
        0.020211653783917427,
        0.04317918419837952,
        -0.02671690098941326,
        0.019440822303295135,
        0.057615578174591064,
        0.06429513543844223,
        0.06027046963572502,
        -0.004829041659832001,
        0.05386517941951752,
        0.008906669914722443,
        -0.038620591163635254,
        0.018727634102106094,
        0.027425339445471764,
        0.02535953000187874,
        0.041994858533144,
        0.02780573070049286,
        -0.014946192502975464,
        -0.056996967643499374,
        0.017054332420229912,
        0.030816700309515,
        -0.06873110681772232,
        -0.036944273859262466,
        0.05288148671388626,
        -0.0604705810546875,
        0.04915530979633331,
        0.07315532118082047,
        0.009583081118762493,
        0.03936627879738808,
        0.05229847878217697,
        -0.05121546983718872,
        -0.04745829477906227,
        0.052895449101924896,
        -0.05726756900548935,
        -0.03892192617058754,
        -0.058543987572193146,
        0.03478410094976425,
        0.005513133946806192,
        -0.01922200247645378,
        0.01999123953282833,
        0.016847848892211914,
        -0.05170436203479767,
        -0.022270001471042633,
        -0.0566285103559494,
        0.008612412959337234,
        -0.032428596168756485,
        -0.02530665509402752,
        -0.01625625230371952,
        -0.011355612426996231,
        -0.068064384162426,
        0.056728873401880264,
        0.012920884415507317,
        0.0102515434846282,
        -0.018779702484607697,
        0.034419525414705276,
        -0.05289372429251671,
        0.012279330752789974,
        0.005030459258705378,
        0.032795410603284836,
        -0.022577542811632156,
        0.04107527807354927,
        0.051775965839624405,
        -0.012522944249212742,
        0.06662272661924362,
        -0.014926053583621979,
        0.024882083758711815,
        0.07068035751581192,
        -0.0262758769094944,
        -0.06787052005529404,
        0.06701148301362991,
        -0.03829377517104149,
        0.010319208726286888,
        0.024657070636749268,
        -0.034060534089803696,
        -0.06326056271791458,
        -0.035671915858983994,
        -0.056720733642578125,
        -0.04764477163553238,
        0.03441029414534569,
        -0.035889796912670135,
        0.07210297882556915,
        0.06270980834960938,
        0.023266475647687912,
        -0.045408401638269424,
        0.0716736689209938,
        0.01578485034406185,
        0.07222078740596771,
        -0.0725109726190567,
        0.05021362006664276,
        0.03121347352862358,
        -0.03494756296277046,
        -0.06993479281663895,
        -0.018099870532751083,
        0.00408825371414423,
        -0.04727734252810478,
        -0.046466004103422165,
        -0.06968914717435837,
        0.042883943766355515,
        -0.06775377690792084,
        -0.06594712287187576,
        0.06662492454051971,
        -0.06190788373351097,
        -0.03625081107020378,
        0.058497827500104904,
        0.030151523649692535,
        0.07203521579504013,
        -0.039365850389003754,
        0.0011522273998707533,
        0.0007286075269803405,
        0.017352459952235222,
        0.04253169521689415,
        0.020227201282978058,
        0.059476129710674286,
        0.00407346710562706,
        0.011859415099024773,
        0.023825431242585182,
        -0.046611238270998,
        0.0684751570224762,
        -0.05859711393713951,
        -0.0401366725564003,
        0.03337046876549721,
        -0.04926100745797157,
        0.009712614119052887,
        -0.04160153493285179,
        -0.044885050505399704,
        -0.04983564838767052,
        -0.06576132029294968,
        -0.0659869834780693,
        0.05684347450733185,
        0.046732883900403976,
        -0.0390259213745594,
        0.01136478316038847,
        0.04606343060731888,
        0.0371616929769516,
        0.04056483507156372,
        0.001924194977618754,
        0.04394390434026718,
        -0.018409375101327896,
        0.06276264041662216,
        0.04868801310658455,
        -0.06770650297403336,
        0.06310265511274338,
        0.06140543892979622,
        -0.050702545791864395,
        0.04075333848595619,
        0.05758332088589668,
        0.05076916888356209,
        -0.05914868786931038,
        0.058788396418094635,
        -0.051244959235191345,
        0.06273096799850464,
        -0.0684364065527916,
        0.0055724685080349445,
        -0.023004068061709404,
        -0.07274796813726425,
        0.008179287426173687,
        -0.031350746750831604,
        -0.011075740680098534,
        0.045234885066747665,
        -0.04177112504839897,
        -0.045477237552404404,
        0.05180893838405609,
        -0.05819880962371826,
        0.05084795132279396,
        0.0023677388671785593,
        -0.05709361284971237,
        0.02095043659210205,
        0.047430649399757385,
        0.004826417192816734,
        0.05498693883419037,
        -0.03718554601073265,
        -0.030858905985951424,
        -0.017757365480065346,
        0.0060364301316440105,
        -0.06264904886484146,
        0.014791016466915607,
        -0.013383544981479645,
        -0.049730535596609116,
        -0.06497932225465775,
        0.06109621748328209,
        -0.0005707750096917152,
        0.004793302621692419,
        0.0688551738858223,
        -0.03462119773030281,
        0.02567232958972454,
        0.019048692658543587,
        0.07251020520925522,
        -0.07163383811712265,
        -0.015780821442604065,
        0.0664052814245224,
        0.023704983294010162,
        0.008962294086813927,
        0.05074300244450569,
        -0.04518692567944527,
        0.06837116926908493,
        0.015489206649363041,
        0.009069414809346199,
        -0.02873951941728592,
        -0.041124749928712845,
        -0.014382390305399895,
        0.05339883640408516,
        -0.06639690697193146,
        -0.0022540693171322346,
        0.05230795592069626,
        -0.0688723623752594,
        0.06574904173612595,
        0.0051675820723176,
        -0.016917690634727478,
        0.0017949825851246715,
        -0.006997643504291773,
        -0.06000206992030144,
        0.06505240499973297,
        -0.01373213715851307,
        0.01496084127575159,
        -0.05848902463912964,
        0.005324294324964285,
        0.0225076861679554,
        0.05071989446878433,
        0.06341597437858582,
        0.02140161581337452,
        0.021648388355970383,
        -0.04675877466797829,
        0.044694527983665466,
        0.055161815136671066,
        0.04158381372690201,
        0.019946986809372902,
        -0.06581054627895355,
        -0.06310243904590607,
        0.05600913614034653,
        0.06949492543935776,
        0.029287897050380707,
        -0.02112383022904396,
        -0.06740226596593857,
        -0.029727447777986526,
        -0.04080821946263313,
        -0.06811276078224182,
        -0.005441823974251747,
        0.0261235274374485,
        -0.004761772230267525,
        -0.05501454696059227,
        0.0671088695526123,
        0.05781885236501694,
        0.04229311645030975,
        -0.019454361870884895,
        -0.05489096790552139,
        0.0037460671737790108,
        0.040751125663518906,
        0.008017522282898426,
        -0.03374005854129791,
        -0.012483421713113785,
        0.02891564555466175,
        0.03855670243501663,
        -0.04197292402386665,
        -0.03632360324263573,
        0.0035318275913596153,
        -0.02503071166574955,
        -0.06750395894050598,
        0.061799898743629456,
        0.003483734093606472,
        0.05518237128853798,
        -0.06686893850564957,
        -0.017029941082000732,
        0.04179280251264572,
        0.04060729965567589,
        0.055522989481687546,
        -0.0014461452374234796,
        -0.0064715854823589325,
        0.04316747561097145,
        -0.05420684814453125,
        -0.05498862639069557,
        0.038052331656217575,
        -0.038372814655303955,
        0.008179662749171257,
        0.0007328724022954702,
        -0.05255960673093796,
        0.05056389421224594,
        -0.03863644227385521,
        -0.06374459713697433,
        -0.02659904956817627,
        0.034563057124614716,
        -0.039217423647642136,
        -0.06752680242061615,
        0.01604851521551609,
        0.008130188100039959,
        0.012566507793962955,
        0.0673770159482956,
        0.05816212296485901,
        0.054213106632232666,
        0.05170237645506859,
        0.05402039736509323,
        0.03665553033351898,
        0.02665373496711254,
        -0.06368114054203033,
        0.07037753611803055,
        0.02247794158756733,
        -0.04396265372633934,
        -0.009509787894785404,
        -0.021279960870742798,
        -0.015580002218484879,
        0.010616697371006012,
        -0.053101807832717896,
        -0.0040906150825321674,
        -0.014416040852665901,
        -0.04432849586009979,
        0.013582535088062286,
        -0.035008177161216736,
        0.03595329448580742,
        -0.07101377844810486,
        0.028887126594781876,
        0.055923767387866974,
        0.07075002789497375,
        -0.03510412573814392,
        0.048936545848846436,
        -0.06079358235001564,
        -0.0640537366271019,
        0.0036066551692783833,
        0.05719350650906563,
        0.019478099420666695,
        0.027360348030924797,
        -0.0571613572537899,
        -0.04779570922255516,
        0.037885598838329315,
        0.0520196408033371,
        -0.011412542313337326,
        -0.061655525118112564,
        -0.05706971511244774,
        -0.03946610167622566,
        -0.05556810274720192,
        -0.0021020337007939816,
        0.04194929078221321,
        0.053712259978055954,
        0.06327763944864273,
        0.007791547104716301,
        -0.05661090463399887,
        -0.016711825504899025,
        -0.052914656698703766,
        0.008907657116651535,
        -0.05033523589372635,
        0.005850812420248985,
        -0.02012375369668007,
        0.007876056246459484,
        0.06540824472904205,
        0.03450850769877434,
        0.06272919476032257,
        0.07259602844715118,
        -0.061599668115377426,
        -0.032268740236759186,
        -0.008415699936449528,
        0.0006020648870617151,
        0.0034410441294312477,
        -0.011125465855002403,
        0.03712420165538788,
        0.03365209326148033,
        -0.03786269947886467,
        -0.07360624521970749,
        0.019865622743964195,
        0.07222950458526611
    ],
    [
        -0.014705006964504719,
        0.03767254203557968,
        0.015574540942907333,
        0.02462434023618698,
        0.016149714589118958,
        -0.020831938832998276,
        0.018505563959479332,
        -0.04269189387559891,
        0.05254172906279564,
        -0.007717057131230831,
        0.049103714525699615,
        0.0327993780374527,
        -0.054264649748802185,
        -0.02507184073328972,
        -0.06406394392251968,
        0.06654674559831619,
        0.023861879482865334,
        0.05046061798930168,
        -0.05355304852128029,
        -0.0433446429669857,
        0.00252682832069695,
        0.06436999142169952,
        -0.008272274397313595,
        -0.015587586909532547,
        0.03555105999112129,
        0.02166440524160862,
        0.05874042585492134,
        -0.014025590382516384,
        -0.0013184979325160384,
        0.03711485117673874,
        0.025822222232818604,
        -0.023117316886782646,
        -0.055353954434394836,
        -0.06286799907684326,
        0.05977857857942581,
        0.00931230466812849,
        -0.05838925018906593,
        -0.006835767067968845,
        0.01673070527613163,
        -0.038380566984415054,
        0.038160402327775955,
        0.016698893159627914,
        0.02772427909076214,
        -0.06154328212141991,
        0.008053752593696117,
        0.05949481204152107,
        0.012269076891243458,
        0.013795627281069756,
        -0.02539859339594841,
        0.05485672876238823,
        0.043957557529211044,
        0.06636064499616623,
        -0.006442496087402105,
        0.024058856070041656,
        0.039725933223962784,
        -0.042483508586883545,
        0.004861852154135704,
        -0.02763998880982399,
        0.02771630883216858,
        0.01575631834566593,
        -0.04201151058077812,
        0.0453125461935997,
        -0.0526709221303463,
        0.0627877339720726,
        -0.060086339712142944,
        -0.035569287836551666,
        0.0184415802359581,
        -0.03675885125994682,
        -0.06220019608736038,
        -0.009796567261219025,
        -0.06309492886066437,
        -0.06912169605493546,
        0.06432870030403137,
        -0.010142596438527107,
        0.02844666875898838,
        -0.0017171551007777452,
        -0.0025560292415320873,
        0.06889715790748596,
        -0.02337036095559597,
        -0.0410107746720314,
        0.026901530101895332,
        0.06844145804643631,
        -0.01678612269461155,
        0.048114266246557236,
        0.04952031001448631,
        0.050182703882455826,
        0.06824207305908203,
        -0.03593045473098755,
        0.041724398732185364,
        0.05682450905442238,
        0.04682190343737602,
        0.036488570272922516,
        -0.06877399981021881,
        -0.005403478629887104,
        0.011296933516860008,
        0.009888065047562122,
        0.025863271206617355,
        -0.06594860553741455,
        0.034062113612890244,
        0.00866327341645956,
        -0.05739457532763481,
        0.021357066929340363,
        0.0641290545463562,
        0.05110141634941101,
        0.037652887403964996,
        -0.0648411363363266,
        -0.04994402453303337,
        0.03789592161774635,
        -0.049944885075092316,
        0.024707993492484093,
        -0.06011123210191727,
        -0.049699898809194565,
        -0.057408466935157776,
        -0.02372250147163868,
        -0.03959932178258896,
        -0.007945640943944454,
        0.057159192860126495,
        0.00012438154953997582,
        0.06796687841415405,
        0.02117692120373249,
        -0.04823516309261322,
        0.05957701429724693,
        0.025824474170804024,
        -0.0550590343773365,
        -0.06583581864833832,
        -0.050664301961660385,
        -0.053708191961050034,
        -0.04851742088794708,
        0.052983324974775314,
        0.04188292473554611,
        -0.011260509490966797,
        -0.0563322938978672,
        -0.05374407395720482,
        0.03401213884353638,
        -0.04320291429758072,
        -0.06201915815472603,
        0.012946332804858685,
        0.025390978902578354,
        -0.061878424137830734,
        0.0645250752568245,
        0.06409522145986557,
        0.01818319968879223,
        0.05769257992506027,
        0.04361746832728386,
        0.05390891432762146,
        -0.03960726037621498,
        0.06637417525053024,
        -0.0609288327395916,
        0.05087297409772873,
        0.06640268117189407,
        0.049437373876571655,
        -0.06733522564172745,
        -0.04376857355237007,
        -0.06025596335530281,
        0.038066402077674866,
        0.014916056767106056,
        0.0072769662365317345,
        0.04279037564992905,
        -0.03673949092626572,
        0.03110312670469284,
        0.06454716622829437,
        0.05534389242529869,
        0.05396418273448944,
        -0.021651016548275948,
        0.048867713660001755,
        0.01473053265362978,
        -0.02812121994793415,
        0.027224896475672722,
        0.025110678747296333,
        0.03086932934820652,
        0.035768091678619385,
        0.016645433381199837,
        -0.025484362617135048,
        -0.06124548986554146,
        0.018893789499998093,
        0.03448251262307167,
        -0.06488043814897537,
        -0.03665546700358391,
        0.043694548308849335,
        -0.06408397108316422,
        0.04672933742403984,
        0.06931455433368683,
        0.0002565564354881644,
        0.04883844777941704,
        0.05308186262845993,
        -0.053448427468538284,
        -0.053579751402139664,
        0.05074315518140793,
        -0.0570681057870388,
        -0.047566693276166916,
        -0.05959068238735199,
        0.041766319423913956,
        0.005849720444530249,
        -0.00892123207449913,
        0.006218413356691599,
        -0.006556966342031956,
        -0.05971773713827133,
        -0.03378601744771004,
        -0.06107638031244278,
        0.009947392158210278,
        -0.021873949095606804,
        -0.04052327945828438,
        -0.026973038911819458,
        -0.031154310330748558,
        -0.06416511535644531,
        0.06093689426779747,
        0.01389278657734394,
        0.027559194713830948,
        -0.010479476302862167,
        0.0529189370572567,
        -0.05196736007928848,
        0.018630772829055786,
        0.005397064611315727,
        0.027381807565689087,
        -0.03998049721121788,
        0.04031935706734657,
        0.03906964138150215,
        -0.03190833702683449,
        0.06581299751996994,
        -0.019544262439012527,
        0.005396691616624594,
        0.06699047982692719,
        -0.02775418944656849,
        -0.06089070439338684,
        0.06633532047271729,
        -0.03552190959453583,
        0.008419540710747242,
        -0.009133799001574516,
        -0.0328851118683815,
        -0.06212558597326279,
        -0.03516177460551262,
        -0.06101316958665848,
        -0.04459715262055397,
        0.03700656816363335,
        -0.05070674419403076,
        0.06811203807592392,
        0.06252380460500717,
        0.035627588629722595,
        -0.045055266469717026,
        0.06739631295204163,
        0.02086435817182064,
        0.06856020539999008,
        -0.06868394464254379,
        0.04876759275794029,
        0.03602388873696327,
        -0.039915964007377625,
        -0.061873603612184525,
        0.008788519538939,
        -0.002150096232071519,
        -0.04794084280729294,
        -0.0490465983748436,
        -0.06579988449811935,
        0.03706488758325577,
        -0.06532732397317886,
        -0.06202276051044464,
        0.06277334690093994,
        -0.06231872737407684,
        -0.044583555310964584,
        0.05879846587777138,
        0.03658076375722885,
        0.06765343993902206,
        -0.0342002734541893,
        0.009219634346663952,
        0.01894497498869896,
        0.017390884459018707,
        0.04208012670278549,
        0.04367658123373985,
        0.061284665018320084,
        0.02785648964345455,
        0.014836208894848824,
        0.00499889999628067,
        -0.056677889078855515,
        0.06780189275741577,
        -0.05873926728963852,
        -0.028454823419451714,
        0.018717855215072632,
        -0.04733414575457573,
        0.009008320979773998,
        -0.04834015294909477,
        -0.03839071840047836,
        -0.06404595077037811,
        -0.05813315510749817,
        -0.06650018692016602,
        0.05054190009832382,
        0.061522506177425385,
        -0.04032785817980766,
        0.013782852329313755,
        0.049716539680957794,
        0.04269877076148987,
        0.035791199654340744,
        -0.012657799758017063,
        0.04114244505763054,
        -0.01972763054072857,
        0.058452729135751724,
        0.058206722140312195,
        -0.06291515380144119,
        0.05813043937087059,
        0.06433670967817307,
        -0.03577470779418945,
        0.03393349424004555,
        0.06068512424826622,
        0.05293578281998634,
        -0.050973206758499146,
        0.06021358445286751,
        -0.043084461241960526,
        0.05897117406129837,
        -0.061815448105335236,
        0.004895498510450125,
        -0.0308977197855711,
        -0.06824761629104614,
        0.005557581782341003,
        -0.0418972373008728,
        -0.015665370970964432,
        0.05122361704707146,
        -0.028778398409485817,
        -0.047244809567928314,
        0.04712016507983208,
        -0.05570009723305702,
        0.04825768992304802,
        0.008656788617372513,
        -0.047627560794353485,
        0.0052494145929813385,
        0.042129870504140854,
        0.025654291734099388,
        0.05420393869280815,
        -0.04158935695886612,
        -0.037771258503198624,
        0.00833316519856453,
        0.011536314152181149,
        -0.06336167454719543,
        0.03096373938024044,
        0.005757907405495644,
        -0.05062408372759819,
        -0.05977043882012367,
        0.05406254157423973,
        0.020211853086948395,
        -0.01863880828022957,
        0.0646340474486351,
        -0.029981134459376335,
        0.013831309974193573,
        0.023865411058068275,
        0.06797825545072556,
        -0.06779161095619202,
        -0.0318245105445385,
        0.057424455881118774,
        0.015005707740783691,
        0.015833653509616852,
        0.054549720138311386,
        -0.04208255931735039,
        0.06166337803006172,
        0.026856528595089912,
        0.011526746675372124,
        -0.041887544095516205,
        -0.060385189950466156,
        -0.011881081387400627,
        0.052798446267843246,
        -0.06519228219985962,
        -0.007277237251400948,
        0.05301735922694206,
        -0.0671190470457077,
        0.06291095912456512,
        0.013663386926054955,
        0.009955592453479767,
        0.0027720099315047264,
        -0.03184942901134491,
        -0.06085002422332764,
        0.06697021424770355,
        -0.01776396855711937,
        0.03998349606990814,
        -0.059441421180963516,
        -0.02445342391729355,
        0.01846030354499817,
        0.05536719039082527,
        0.06186017021536827,
        0.031277596950531006,
        0.023465663194656372,
        -0.049955133348703384,
        0.05246129259467125,
        0.0608358159661293,
        0.05550568550825119,
        0.0047702970914542675,
        -0.06516207754611969,
        -0.05309809371829033,
        0.04584886506199837,
        0.06250368058681488,
        0.029911113902926445,
        -0.005887535400688648,
        -0.05751151219010353,
        -0.027748534455895424,
        -0.023804297670722008,
        -0.06768026947975159,
        0.00344786886125803,
        0.03252892568707466,
        -0.010881501249969006,
        -0.040016938000917435,
        0.06418938934803009,
        0.059407033026218414,
        0.030347658321261406,
        -0.019159842282533646,
        -0.05597550794482231,
        -0.004181991331279278,
        0.04218393191695213,
        -0.0010337658459320664,
        -0.043521441519260406,
        -0.015396795235574245,
        0.014985894784331322,
        0.03973793238401413,
        -0.04455728456377983,
        -0.03032609447836876,
        -0.005459228530526161,
        -0.0172868724912405,
        -0.06271974742412567,
        0.06133692339062691,
        0.019142892211675644,
        0.049807868897914886,
        -0.0652279406785965,
        -0.02687421441078186,
        0.033268965780735016,
        0.03903021663427353,
        0.056383710354566574,
        0.026516912505030632,
        0.012829829938709736,
        0.052827540785074234,
        -0.05553053319454193,
        -0.036992933601140976,
        0.028904927894473076,
        -0.05006425082683563,
        0.028032822534441948,
        -0.009015189483761787,
        -0.049767062067985535,
        0.06041277199983597,
        -0.048729538917541504,
        -0.06325054913759232,
        -0.042011115700006485,
        0.016244368627667427,
        -0.043064311146736145,
        -0.06000160798430443,
        -0.010889651253819466,
        0.022349383682012558,
        0.00975382886826992,
        0.06310064345598221,
        0.05969582870602608,
        0.05695253610610962,
        0.05591921508312225,
        0.057477597147226334,
        0.0283270962536335,
        0.019332872703671455,
        -0.06491436064243317,
        0.06637796759605408,
        0.03645068779587746,
        -0.05040857940912247,
        -0.01556459255516529,
        -0.005126230884343386,
        -0.01266226265579462,
        0.013414028100669384,
        -0.05143166705965996,
        -0.02504541166126728,
        -0.010523813776671886,
        -0.046916790306568146,
        0.03219739347696304,
        -0.036601472645998,
        0.03429289907217026,
        -0.0678795874118805,
        0.039559632539749146,
        0.05757696554064751,
        0.06775946915149689,
        -0.03743438795208931,
        0.05064522102475166,
        -0.06094841659069061,
        -0.06270286440849304,
        0.021454354748129845,
        0.06476816534996033,
        0.017543818801641464,
        0.045844916254282,
        -0.06419878453016281,
        -0.035328663885593414,
        0.022248832508921623,
        0.05838879570364952,
        -0.007179821375757456,
        -0.06011222302913666,
        -0.060023147612810135,
        -0.045800983905792236,
        -0.06086394190788269,
        -0.010793416760861874,
        0.03837987780570984,
        0.059101641178131104,
        0.0624944344162941,
        -0.01021142303943634,
        -0.05250971019268036,
        -0.04416806623339653,
        -0.04068342223763466,
        0.0051046498119831085,
        -0.051931049674749374,
        0.007061535492539406,
        -0.005030483473092318,
        0.008337912149727345,
        0.06173035129904747,
        0.0520840585231781,
        0.05789982154965401,
        0.06886817514896393,
        -0.06241048499941826,
        -0.03152601420879364,
        -0.003335722954943776,
        -0.024082431569695473,
        0.028982535004615784,
        -0.01638828217983246,
        0.053208936005830765,
        0.020884962752461433,
        -0.021116437390446663,
        -0.06932637095451355,
        0.008867409080266953,
        0.0677030012011528
    ],
    [
        -0.00904287863522768,
        0.056845635175704956,
        0.011168580502271652,
        0.02799629420042038,
        0.0009636454051360488,
        -0.0005311556160449982,
        0.008037392981350422,
        -0.04619516432285309,
        0.05338159203529358,
        -0.002068921457976103,
        0.047893572598695755,
        0.03392927348613739,
        -0.053599514067173004,
        -0.016959208995103836,
        -0.06630892306566238,
        0.07109371572732925,
        0.014787116087973118,
        0.053901031613349915,
        -0.05786561965942383,
        -0.008111866191029549,
        -0.017452102154493332,
        0.06321261078119278,
        -0.02804771438241005,
        0.004631896503269672,
        0.037582989782094955,
        0.03085288405418396,
        0.05705936998128891,
        -0.0015331265749409795,
        -0.0010157740907743573,
        -0.003159602638334036,
        0.04416082426905632,
        -0.01930413208901882,
        -0.05165746435523033,
        -0.052897099405527115,
        0.059775497764348984,
        0.01951809972524643,
        -0.0564887635409832,
        -0.005720309913158417,
        -0.036696016788482666,
        -0.03246385604143143,
        0.02525174245238304,
        0.017978504300117493,
        0.025676291435956955,
        -0.0686490461230278,
        0.021747374907135963,
        0.056140121072530746,
        -0.0049759806133806705,
        0.01484267134219408,
        -0.036708734929561615,
        0.05445924773812294,
        0.02790074422955513,
        0.0705784484744072,
        0.003790489863604307,
        0.019513968378305435,
        0.04618392139673233,
        -0.026701541617512703,
        0.010516603477299213,
        -0.016429349780082703,
        -0.008748095482587814,
        0.028699537739157677,
        -0.04714791476726532,
        0.04459688812494278,
        -0.05134216696023941,
        0.06188086047768593,
        -0.06648409366607666,
        -0.03942376747727394,
        0.02770477905869484,
        -0.020953727886080742,
        -0.06764806807041168,
        -0.023090822622179985,
        -0.062481850385665894,
        -0.07306373864412308,
        0.0690467357635498,
        -0.004228517413139343,
        0.0062196701765060425,
        -0.0014424725668504834,
        -0.016713878139853477,
        0.07354911416769028,
        -0.013197843916714191,
        -0.03779749572277069,
        0.01791965961456299,
        0.07270753383636475,
        -0.02854655496776104,
        0.021388141438364983,
        0.022786367684602737,
        0.04839247465133667,
        0.07176365703344345,
        -0.045184869319200516,
        0.04528418555855751,
        0.05826457589864731,
        0.06667567789554596,
        0.05180078744888306,
        -0.06940761208534241,
        0.008651098236441612,
        -0.002327917842194438,
        -0.0018677348271012306,
        0.01861187070608139,
        -0.06828489899635315,
        0.035352837294340134,
        0.012222583405673504,
        -0.058193497359752655,
        -0.0025230853352695704,
        0.06890008598566055,
        0.049106236547231674,
        0.03695030137896538,
        -0.06477382779121399,
        -0.05946502462029457,
        0.031685005873441696,
        -0.04282879829406738,
        0.000056902237702161074,
        -0.06313590705394745,
        -0.04508211463689804,
        -0.05936019495129585,
        -0.023227905854582787,
        -0.011125295422971249,
        0.008121652528643608,
        0.04901730641722679,
        0.0191815085709095,
        0.07022210955619812,
        0.00916682742536068,
        -0.05743742734193802,
        0.05435632914304733,
        0.02444322593510151,
        -0.05946588143706322,
        -0.07033918052911758,
        -0.05896177515387535,
        -0.056538570672273636,
        -0.03605932369828224,
        0.06169483810663223,
        0.03772145137190819,
        -0.009646497666835785,
        -0.061477988958358765,
        -0.05914595350623131,
        0.02020753175020218,
        -0.04499634727835655,
        -0.06513483822345734,
        -0.0076616909354925156,
        0.006589207332581282,
        -0.06465587764978409,
        0.06368153542280197,
        0.06907939910888672,
        0.017175957560539246,
        0.06113393232226372,
        0.04921601712703705,
        0.05935995653271675,
        -0.018169689923524857,
        0.06777020543813705,
        -0.06440827995538712,
        0.040888264775276184,
        0.06972736865282059,
        0.03764210268855095,
        -0.07080648094415665,
        -0.030060650780797005,
        -0.06743226200342178,
        0.026996418833732605,
        -0.006896790582686663,
        0.01262251939624548,
        0.04510520398616791,
        -0.026725955307483673,
        0.023141926154494286,
        0.06165275350213051,
        0.06287067383527756,
        0.05928686261177063,
        -0.009160014800727367,
        0.05603766068816185,
        0.008290030993521214,
        -0.03897533193230629,
        0.023034311830997467,
        0.028730664402246475,
        0.027066104114055634,
        0.04967024177312851,
        0.03633438050746918,
        -0.025779856368899345,
        -0.05556493625044823,
        0.031067365780472755,
        0.03395365551114082,
        -0.06789541244506836,
        -0.040572043508291245,
        0.054573092609643936,
        -0.06532581150531769,
        0.04896366968750954,
        0.07361485809087753,
        -0.005398080218583345,
        0.038563601672649384,
        0.04855895787477493,
        -0.0546342134475708,
        -0.046942275017499924,
        0.0518149808049202,
        -0.060143884271383286,
        -0.027531547471880913,
        -0.06080976501107216,
        0.027248889207839966,
        0.0025824776384979486,
        -0.019667714834213257,
        0.014882971532642841,
        0.012706792913377285,
        -0.05185655131936073,
        -0.020323265343904495,
        -0.04982355982065201,
        0.014733719639480114,
        -0.028191598132252693,
        -0.03210195526480675,
        -0.016899552196264267,
        -0.019052036106586456,
        -0.0684652104973793,
        0.05100417509675026,
        0.004853925667703152,
        0.005853667389601469,
        -0.012582403607666492,
        0.03380993381142616,
        -0.05353045463562012,
        0.02220582403242588,
        -0.006236467510461807,
        0.03616616129875183,
        -0.019423115998506546,
        0.0421169176697731,
        0.049453917890787125,
        -0.015638932585716248,
        0.06417901068925858,
        -0.006306684575974941,
        0.02052070014178753,
        0.07157859951257706,
        -0.03902198001742363,
        -0.0681440457701683,
        0.0673854649066925,
        -0.021798860281705856,
        0.016906777396798134,
        0.02155596949160099,
        -0.02398320659995079,
        -0.06141603738069534,
        -0.044458258897066116,
        -0.050128959119319916,
        -0.0420096293091774,
        0.03224692493677139,
        -0.031907904893159866,
        0.0730426162481308,
        0.06319229304790497,
        0.0237873587757349,
        -0.03810037299990654,
        0.0726616233587265,
        0.025021368637681007,
        0.07205107808113098,
        -0.0714917927980423,
        0.04900836944580078,
        0.028202449902892113,
        -0.03185996413230896,
        -0.07159074395895004,
        -0.011568253859877586,
        0.004103528801351786,
        -0.05159463360905647,
        -0.0444357767701149,
        -0.07028122991323471,
        0.039746176451444626,
        -0.06845870614051819,
        -0.06525193154811859,
        0.06731919944286346,
        -0.06532303243875504,
        -0.03499254956841469,
        0.061048489063978195,
        0.04225440323352814,
        0.07199794799089432,
        -0.04511168971657753,
        -0.01237325370311737,
        0.010930323973298073,
        0.011480874381959438,
        0.039479680359363556,
        0.02675608918070793,
        0.05539536103606224,
        0.005797118414193392,
        0.020310532301664352,
        0.019628552719950676,
        -0.04059860110282898,
        0.06884443014860153,
        -0.06025290861725807,
        -0.03636353835463524,
        0.03405572474002838,
        -0.05146288126707077,
        0.0064536407589912415,
        -0.028530854731798172,
        -0.04245767742395401,
        -0.054338663816452026,
        -0.06803780794143677,
        -0.06772557646036148,
        0.057046130299568176,
        0.04956750199198723,
        -0.04041479900479317,
        0.02433297038078308,
        0.04498303309082985,
        0.029431359842419624,
        0.03822708874940872,
        0.0010810232488438487,
        0.04455452039837837,
        -0.02808026596903801,
        0.06077967584133148,
        0.045535773038864136,
        -0.06631666421890259,
        0.06213926151394844,
        0.06674239039421082,
        -0.04016396030783653,
        0.0400322861969471,
        0.06185954064130783,
        0.043756499886512756,
        -0.05838117375969887,
        0.0597710907459259,
        -0.04386858269572258,
        0.06020321696996689,
        -0.06928763538599014,
        -0.01161202136427164,
        -0.022876936942338943,
        -0.07300423830747604,
        -0.009796849451959133,
        -0.03248060122132301,
        -0.015156966634094715,
        0.03558117151260376,
        -0.043349992483854294,
        -0.049461059272289276,
        0.04932490736246109,
        -0.05257211998105049,
        0.053158484399318695,
        0.00047446967801079154,
        -0.05977301672101021,
        0.021209722384810448,
        0.0379817858338356,
        -0.0012448013294488192,
        0.06098441034555435,
        -0.04009479656815529,
        -0.03312327712774277,
        -0.007110795006155968,
        -0.010996432974934578,
        -0.06300090998411179,
        0.012489775195717812,
        -0.017884356901049614,
        -0.048725321888923645,
        -0.06555022299289703,
        0.060018982738256454,
        -0.004134457558393478,
        -0.003507797373458743,
        0.0682632327079773,
        -0.03172598034143448,
        0.024676397442817688,
        0.010863369330763817,
        0.07284215837717056,
        -0.07182322442531586,
        -0.01557882223278284,
        0.0666441097855568,
        0.016739537939429283,
        0.0045059011317789555,
        0.05060037970542908,
        -0.05085742101073265,
        0.06886585056781769,
        0.011495830491185188,
        0.015217564068734646,
        -0.0337592214345932,
        -0.05199595168232918,
        -0.009412356652319431,
        0.04807388037443161,
        -0.0649227648973465,
        -0.00045483073336072266,
        0.04629259556531906,
        -0.07009896636009216,
        0.06566914916038513,
        0.009662240743637085,
        -0.019793173298239708,
        0.006213443819433451,
        -0.008936428464949131,
        -0.06166629120707512,
        0.06550222635269165,
        0.0019815301056951284,
        0.01070790272206068,
        -0.06102144718170166,
        0.00997110828757286,
        0.01641695760190487,
        0.050631482154130936,
        0.06469392031431198,
        0.0156712643802166,
        0.020974790677428246,
        -0.05080700293183327,
        0.049605593085289,
        0.045415282249450684,
        0.0383908711373806,
        0.018221482634544373,
        -0.0642123594880104,
        -0.06363499909639359,
        0.05756004527211189,
        0.06880434602499008,
        0.03537532687187195,
        -0.03162992373108864,
        -0.06668467074632645,
        -0.02784006856381893,
        -0.03950556740164757,
        -0.06818269193172455,
        -0.013849720358848572,
        0.013051963411271572,
        -0.005445592105388641,
        -0.061735741794109344,
        0.06810365617275238,
        0.05250082165002823,
        0.05100744590163231,
        -0.007877851836383343,
        -0.052778150886297226,
        -0.0002922299026977271,
        0.03990049660205841,
        0.01858528144657612,
        -0.03840583935379982,
        -0.013381967321038246,
        0.029036106541752815,
        0.03196185454726219,
        -0.04381377249956131,
        -0.03288882225751877,
        -0.00281384470872581,
        -0.03236348181962967,
        -0.06669581681489944,
        0.06345418095588684,
        -0.0090261809527874,
        0.05737282335758209,
        -0.06711473315954208,
        -0.018054211512207985,
        0.03756878525018692,
        0.03458581119775772,
        0.04898975044488907,
        0.002549942582845688,
        -0.005330773536115885,
        0.04653606191277504,
        -0.05406450107693672,
        -0.055395785719156265,
        0.037387944757938385,
        -0.04607195779681206,
        0.008997801691293716,
        0.013115033507347107,
        -0.05274677276611328,
        0.05515751242637634,
        -0.04335326701402664,
        -0.062419258058071136,
        -0.03058694675564766,
        0.04404754564166069,
        -0.039396464824676514,
        -0.06405562162399292,
        0.01721557416021824,
        0.016075879335403442,
        0.0030031625647097826,
        0.07040078938007355,
        0.05067501589655876,
        0.054607000201940536,
        0.04940051585435867,
        0.06335935741662979,
        0.03225378692150116,
        0.021147679537534714,
        -0.06414736807346344,
        0.0699387937784195,
        0.033767085522413254,
        -0.04505988582968712,
        0.0009907801868394017,
        -0.028449999168515205,
        -0.013476099818944931,
        0.0023636852856725454,
        -0.05134204402565956,
        -0.003802876453846693,
        -0.01053253561258316,
        -0.04459485039114952,
        0.008471443317830563,
        -0.02313835360109806,
        0.03022722154855728,
        -0.07085113972425461,
        0.037950191646814346,
        0.054648857563734055,
        0.07135891169309616,
        -0.025292320176959038,
        0.05058012902736664,
        -0.06118351221084595,
        -0.06752932071685791,
        0.00041202979627996683,
        0.04911964759230614,
        0.01563957892358303,
        0.036000482738018036,
        -0.05662974342703819,
        -0.051009099930524826,
        0.031146863475441933,
        0.05584356188774109,
        -0.005179218947887421,
        -0.06375012546777725,
        -0.05718100070953369,
        -0.043710581958293915,
        -0.05120325833559036,
        0.0037689039018005133,
        0.05192909389734268,
        0.052105605602264404,
        0.06901493668556213,
        0.015443523414433002,
        -0.0548502616584301,
        -0.014665745198726654,
        -0.056848492473363876,
        0.003018885850906372,
        -0.04579435661435127,
        0.010195951908826828,
        -0.035282839089632034,
        -0.0009525004425086081,
        0.06308063864707947,
        0.03298239782452583,
        0.06164348125457764,
        0.07289793342351913,
        -0.06060992553830147,
        -0.03201870620250702,
        -0.0019577096682041883,
        0.00835409015417099,
        0.011103036813437939,
        -0.0013991311425343156,
        0.03814394772052765,
        0.027772536501288414,
        -0.024684574455022812,
        -0.07396341115236282,
        0.015632253140211105,
        0.07209759205579758
    ],
    [
        -0.00904287863522768,
        0.056845635175704956,
        0.011168580502271652,
        0.02799629420042038,
        0.0009636454051360488,
        -0.0005311556160449982,
        0.008037392981350422,
        -0.04619516432285309,
        0.05338159203529358,
        -0.002068921457976103,
        0.047893572598695755,
        0.03392927348613739,
        -0.053599514067173004,
        -0.016959208995103836,
        -0.06630892306566238,
        0.07109371572732925,
        0.014787116087973118,
        0.053901031613349915,
        -0.05786561965942383,
        -0.008111866191029549,
        -0.017452102154493332,
        0.06321261078119278,
        -0.02804771438241005,
        0.004631896503269672,
        0.037582989782094955,
        0.03085288405418396,
        0.05705936998128891,
        -0.0015331265749409795,
        -0.0010157740907743573,
        -0.003159602638334036,
        0.04416082426905632,
        -0.01930413208901882,
        -0.05165746435523033,
        -0.052897099405527115,
        0.059775497764348984,
        0.01951809972524643,
        -0.0564887635409832,
        -0.005720309913158417,
        -0.036696016788482666,
        -0.03246385604143143,
        0.02525174245238304,
        0.017978504300117493,
        0.025676291435956955,
        -0.0686490461230278,
        0.021747374907135963,
        0.056140121072530746,
        -0.0049759806133806705,
        0.01484267134219408,
        -0.036708734929561615,
        0.05445924773812294,
        0.02790074422955513,
        0.0705784484744072,
        0.003790489863604307,
        0.019513968378305435,
        0.04618392139673233,
        -0.026701541617512703,
        0.010516603477299213,
        -0.016429349780082703,
        -0.008748095482587814,
        0.028699537739157677,
        -0.04714791476726532,
        0.04459688812494278,
        -0.05134216696023941,
        0.06188086047768593,
        -0.06648409366607666,
        -0.03942376747727394,
        0.02770477905869484,
        -0.020953727886080742,
        -0.06764806807041168,
        -0.023090822622179985,
        -0.062481850385665894,
        -0.07306373864412308,
        0.0690467357635498,
        -0.004228517413139343,
        0.0062196701765060425,
        -0.0014424725668504834,
        -0.016713878139853477,
        0.07354911416769028,
        -0.013197843916714191,
        -0.03779749572277069,
        0.01791965961456299,
        0.07270753383636475,
        -0.02854655496776104,
        0.021388141438364983,
        0.022786367684602737,
        0.04839247465133667,
        0.07176365703344345,
        -0.045184869319200516,
        0.04528418555855751,
        0.05826457589864731,
        0.06667567789554596,
        0.05180078744888306,
        -0.06940761208534241,
        0.008651098236441612,
        -0.002327917842194438,
        -0.0018677348271012306,
        0.01861187070608139,
        -0.06828489899635315,
        0.035352837294340134,
        0.012222583405673504,
        -0.058193497359752655,
        -0.0025230853352695704,
        0.06890008598566055,
        0.049106236547231674,
        0.03695030137896538,
        -0.06477382779121399,
        -0.05946502462029457,
        0.031685005873441696,
        -0.04282879829406738,
        0.000056902237702161074,
        -0.06313590705394745,
        -0.04508211463689804,
        -0.05936019495129585,
        -0.023227905854582787,
        -0.011125295422971249,
        0.008121652528643608,
        0.04901730641722679,
        0.0191815085709095,
        0.07022210955619812,
        0.00916682742536068,
        -0.05743742734193802,
        0.05435632914304733,
        0.02444322593510151,
        -0.05946588143706322,
        -0.07033918052911758,
        -0.05896177515387535,
        -0.056538570672273636,
        -0.03605932369828224,
        0.06169483810663223,
        0.03772145137190819,
        -0.009646497666835785,
        -0.061477988958358765,
        -0.05914595350623131,
        0.02020753175020218,
        -0.04499634727835655,
        -0.06513483822345734,
        -0.0076616909354925156,
        0.006589207332581282,
        -0.06465587764978409,
        0.06368153542280197,
        0.06907939910888672,
        0.017175957560539246,
        0.06113393232226372,
        0.04921601712703705,
        0.05935995653271675,
        -0.018169689923524857,
        0.06777020543813705,
        -0.06440827995538712,
        0.040888264775276184,
        0.06972736865282059,
        0.03764210268855095,
        -0.07080648094415665,
        -0.030060650780797005,
        -0.06743226200342178,
        0.026996418833732605,
        -0.006896790582686663,
        0.01262251939624548,
        0.04510520398616791,
        -0.026725955307483673,
        0.023141926154494286,
        0.06165275350213051,
        0.06287067383527756,
        0.05928686261177063,
        -0.009160014800727367,
        0.05603766068816185,
        0.008290030993521214,
        -0.03897533193230629,
        0.023034311830997467,
        0.028730664402246475,
        0.027066104114055634,
        0.04967024177312851,
        0.03633438050746918,
        -0.025779856368899345,
        -0.05556493625044823,
        0.031067365780472755,
        0.03395365551114082,
        -0.06789541244506836,
        -0.040572043508291245,
        0.054573092609643936,
        -0.06532581150531769,
        0.04896366968750954,
        0.07361485809087753,
        -0.005398080218583345,
        0.038563601672649384,
        0.04855895787477493,
        -0.0546342134475708,
        -0.046942275017499924,
        0.0518149808049202,
        -0.060143884271383286,
        -0.027531547471880913,
        -0.06080976501107216,
        0.027248889207839966,
        0.0025824776384979486,
        -0.019667714834213257,
        0.014882971532642841,
        0.012706792913377285,
        -0.05185655131936073,
        -0.020323265343904495,
        -0.04982355982065201,
        0.014733719639480114,
        -0.028191598132252693,
        -0.03210195526480675,
        -0.016899552196264267,
        -0.019052036106586456,
        -0.0684652104973793,
        0.05100417509675026,
        0.004853925667703152,
        0.005853667389601469,
        -0.012582403607666492,
        0.03380993381142616,
        -0.05353045463562012,
        0.02220582403242588,
        -0.006236467510461807,
        0.03616616129875183,
        -0.019423115998506546,
        0.0421169176697731,
        0.049453917890787125,
        -0.015638932585716248,
        0.06417901068925858,
        -0.006306684575974941,
        0.02052070014178753,
        0.07157859951257706,
        -0.03902198001742363,
        -0.0681440457701683,
        0.0673854649066925,
        -0.021798860281705856,
        0.016906777396798134,
        0.02155596949160099,
        -0.02398320659995079,
        -0.06141603738069534,
        -0.044458258897066116,
        -0.050128959119319916,
        -0.0420096293091774,
        0.03224692493677139,
        -0.031907904893159866,
        0.0730426162481308,
        0.06319229304790497,
        0.0237873587757349,
        -0.03810037299990654,
        0.0726616233587265,
        0.025021368637681007,
        0.07205107808113098,
        -0.0714917927980423,
        0.04900836944580078,
        0.028202449902892113,
        -0.03185996413230896,
        -0.07159074395895004,
        -0.011568253859877586,
        0.004103528801351786,
        -0.05159463360905647,
        -0.0444357767701149,
        -0.07028122991323471,
        0.039746176451444626,
        -0.06845870614051819,
        -0.06525193154811859,
        0.06731919944286346,
        -0.06532303243875504,
        -0.03499254956841469,
        0.061048489063978195,
        0.04225440323352814,
        0.07199794799089432,
        -0.04511168971657753,
        -0.01237325370311737,
        0.010930323973298073,
        0.011480874381959438,
        0.039479680359363556,
        0.02675608918070793,
        0.05539536103606224,
        0.005797118414193392,
        0.020310532301664352,
        0.019628552719950676,
        -0.04059860110282898,
        0.06884443014860153,
        -0.06025290861725807,
        -0.03636353835463524,
        0.03405572474002838,
        -0.05146288126707077,
        0.0064536407589912415,
        -0.028530854731798172,
        -0.04245767742395401,
        -0.054338663816452026,
        -0.06803780794143677,
        -0.06772557646036148,
        0.057046130299568176,
        0.04956750199198723,
        -0.04041479900479317,
        0.02433297038078308,
        0.04498303309082985,
        0.029431359842419624,
        0.03822708874940872,
        0.0010810232488438487,
        0.04455452039837837,
        -0.02808026596903801,
        0.06077967584133148,
        0.045535773038864136,
        -0.06631666421890259,
        0.06213926151394844,
        0.06674239039421082,
        -0.04016396030783653,
        0.0400322861969471,
        0.06185954064130783,
        0.043756499886512756,
        -0.05838117375969887,
        0.0597710907459259,
        -0.04386858269572258,
        0.06020321696996689,
        -0.06928763538599014,
        -0.01161202136427164,
        -0.022876936942338943,
        -0.07300423830747604,
        -0.009796849451959133,
        -0.03248060122132301,
        -0.015156966634094715,
        0.03558117151260376,
        -0.043349992483854294,
        -0.049461059272289276,
        0.04932490736246109,
        -0.05257211998105049,
        0.053158484399318695,
        0.00047446967801079154,
        -0.05977301672101021,
        0.021209722384810448,
        0.0379817858338356,
        -0.0012448013294488192,
        0.06098441034555435,
        -0.04009479656815529,
        -0.03312327712774277,
        -0.007110795006155968,
        -0.010996432974934578,
        -0.06300090998411179,
        0.012489775195717812,
        -0.017884356901049614,
        -0.048725321888923645,
        -0.06555022299289703,
        0.060018982738256454,
        -0.004134457558393478,
        -0.003507797373458743,
        0.0682632327079773,
        -0.03172598034143448,
        0.024676397442817688,
        0.010863369330763817,
        0.07284215837717056,
        -0.07182322442531586,
        -0.01557882223278284,
        0.0666441097855568,
        0.016739537939429283,
        0.0045059011317789555,
        0.05060037970542908,
        -0.05085742101073265,
        0.06886585056781769,
        0.011495830491185188,
        0.015217564068734646,
        -0.0337592214345932,
        -0.05199595168232918,
        -0.009412356652319431,
        0.04807388037443161,
        -0.0649227648973465,
        -0.00045483073336072266,
        0.04629259556531906,
        -0.07009896636009216,
        0.06566914916038513,
        0.009662240743637085,
        -0.019793173298239708,
        0.006213443819433451,
        -0.008936428464949131,
        -0.06166629120707512,
        0.06550222635269165,
        0.0019815301056951284,
        0.01070790272206068,
        -0.06102144718170166,
        0.00997110828757286,
        0.01641695760190487,
        0.050631482154130936,
        0.06469392031431198,
        0.0156712643802166,
        0.020974790677428246,
        -0.05080700293183327,
        0.049605593085289,
        0.045415282249450684,
        0.0383908711373806,
        0.018221482634544373,
        -0.0642123594880104,
        -0.06363499909639359,
        0.05756004527211189,
        0.06880434602499008,
        0.03537532687187195,
        -0.03162992373108864,
        -0.06668467074632645,
        -0.02784006856381893,
        -0.03950556740164757,
        -0.06818269193172455,
        -0.013849720358848572,
        0.013051963411271572,
        -0.005445592105388641,
        -0.061735741794109344,
        0.06810365617275238,
        0.05250082165002823,
        0.05100744590163231,
        -0.007877851836383343,
        -0.052778150886297226,
        -0.0002922299026977271,
        0.03990049660205841,
        0.01858528144657612,
        -0.03840583935379982,
        -0.013381967321038246,
        0.029036106541752815,
        0.03196185454726219,
        -0.04381377249956131,
        -0.03288882225751877,
        -0.00281384470872581,
        -0.03236348181962967,
        -0.06669581681489944,
        0.06345418095588684,
        -0.0090261809527874,
        0.05737282335758209,
        -0.06711473315954208,
        -0.018054211512207985,
        0.03756878525018692,
        0.03458581119775772,
        0.04898975044488907,
        0.002549942582845688,
        -0.005330773536115885,
        0.04653606191277504,
        -0.05406450107693672,
        -0.055395785719156265,
        0.037387944757938385,
        -0.04607195779681206,
        0.008997801691293716,
        0.013115033507347107,
        -0.05274677276611328,
        0.05515751242637634,
        -0.04335326701402664,
        -0.062419258058071136,
        -0.03058694675564766,
        0.04404754564166069,
        -0.039396464824676514,
        -0.06405562162399292,
        0.01721557416021824,
        0.016075879335403442,
        0.0030031625647097826,
        0.07040078938007355,
        0.05067501589655876,
        0.054607000201940536,
        0.04940051585435867,
        0.06335935741662979,
        0.03225378692150116,
        0.021147679537534714,
        -0.06414736807346344,
        0.0699387937784195,
        0.033767085522413254,
        -0.04505988582968712,
        0.0009907801868394017,
        -0.028449999168515205,
        -0.013476099818944931,
        0.0023636852856725454,
        -0.05134204402565956,
        -0.003802876453846693,
        -0.01053253561258316,
        -0.04459485039114952,
        0.008471443317830563,
        -0.02313835360109806,
        0.03022722154855728,
        -0.07085113972425461,
        0.037950191646814346,
        0.054648857563734055,
        0.07135891169309616,
        -0.025292320176959038,
        0.05058012902736664,
        -0.06118351221084595,
        -0.06752932071685791,
        0.00041202979627996683,
        0.04911964759230614,
        0.01563957892358303,
        0.036000482738018036,
        -0.05662974342703819,
        -0.051009099930524826,
        0.031146863475441933,
        0.05584356188774109,
        -0.005179218947887421,
        -0.06375012546777725,
        -0.05718100070953369,
        -0.043710581958293915,
        -0.05120325833559036,
        0.0037689039018005133,
        0.05192909389734268,
        0.052105605602264404,
        0.06901493668556213,
        0.015443523414433002,
        -0.0548502616584301,
        -0.014665745198726654,
        -0.056848492473363876,
        0.003018885850906372,
        -0.04579435661435127,
        0.010195951908826828,
        -0.035282839089632034,
        -0.0009525004425086081,
        0.06308063864707947,
        0.03298239782452583,
        0.06164348125457764,
        0.07289793342351913,
        -0.06060992553830147,
        -0.03201870620250702,
        -0.0019577096682041883,
        0.00835409015417099,
        0.011103036813437939,
        -0.0013991311425343156,
        0.03814394772052765,
        0.027772536501288414,
        -0.024684574455022812,
        -0.07396341115236282,
        0.015632253140211105,
        0.07209759205579758
    ],
    [
        -0.026453891769051552,
        0.04082935303449631,
        0.008861582726240158,
        0.024946147575974464,
        0.010284789837896824,
        -0.02769594080746174,
        0.019153550267219543,
        -0.0455622635781765,
        0.05632249638438225,
        0.004117103759199381,
        0.05075090378522873,
        0.03770315647125244,
        -0.052871111780405045,
        -0.019605284556746483,
        -0.0639452263712883,
        0.06741466373205185,
        0.01942211575806141,
        0.04965677112340927,
        -0.05631641298532486,
        -0.0433126762509346,
        0.006549057550728321,
        0.06421931833028793,
        -0.007310005370527506,
        -0.0052096303552389145,
        0.03177079185843468,
        0.035150978714227676,
        0.06264422833919525,
        -0.005306171718984842,
        -0.0035399594344198704,
        0.021677793934941292,
        0.03888744115829468,
        -0.015301402658224106,
        -0.055768780410289764,
        -0.06346018612384796,
        0.060514673590660095,
        0.023451033979654312,
        -0.057763442397117615,
        -0.009827541187405586,
        -0.032227788120508194,
        -0.037306249141693115,
        0.041525885462760925,
        0.024594521149992943,
        0.02364070527255535,
        -0.06353171169757843,
        0.014616993255913258,
        0.05543931573629379,
        0.013217969797551632,
        0.019414855167269707,
        -0.008833853527903557,
        0.04734554886817932,
        0.03577328473329544,
        0.06697363406419754,
        -0.0016092900186777115,
        0.021427752450108528,
        0.04896185174584389,
        -0.038293082267045975,
        0.0005321413045749068,
        -0.024841725826263428,
        0.012418240308761597,
        0.016379639506340027,
        -0.04957598075270653,
        0.042449288070201874,
        -0.04961708188056946,
        0.06370019912719727,
        -0.06524817645549774,
        -0.02608386054635048,
        0.01361297257244587,
        -0.02853969670832157,
        -0.06315464526414871,
        0.0007086036494001746,
        -0.06310531497001648,
        -0.06942234933376312,
        0.06363409012556076,
        -0.008464476093649864,
        0.03812285140156746,
        0.0038905423134565353,
        -0.0009113687556236982,
        0.069712333381176,
        -0.008901434019207954,
        -0.04338565468788147,
        0.024541903287172318,
        0.06892402470111847,
        -0.016432296484708786,
        0.03943348303437233,
        0.030516142025589943,
        0.04680757224559784,
        0.0687544196844101,
        -0.03818391263484955,
        0.05084565281867981,
        0.05453130230307579,
        0.048688922077417374,
        0.048822030425071716,
        -0.0691138207912445,
        0.0001290541113121435,
        0.0121461171656847,
        -0.004160996526479721,
        0.02867715246975422,
        -0.06611010432243347,
        0.03553403541445732,
        0.008004364557564259,
        -0.052032895386219025,
        0.014004312455654144,
        0.0637642964720726,
        0.05573941767215729,
        0.04005857929587364,
        -0.06624635308980942,
        -0.05431748181581497,
        0.043328795582056046,
        -0.046682216227054596,
        0.02732919715344906,
        -0.06128907948732376,
        -0.05212841182947159,
        -0.058081306517124176,
        -0.01444623339921236,
        -0.03170810267329216,
        0.005800674669444561,
        0.05186377465724945,
        0.003127871546894312,
        0.06698985397815704,
        0.02394241653382778,
        -0.053500376641750336,
        0.05770042538642883,
        0.02990759164094925,
        -0.05483728647232056,
        -0.0662708654999733,
        -0.04869779944419861,
        -0.057545192539691925,
        -0.05399547144770622,
        0.054160766303539276,
        0.04681478068232536,
        -0.003502829000353813,
        -0.05328073725104332,
        -0.04980388283729553,
        0.029741479083895683,
        -0.03846070170402527,
        -0.06192992627620697,
        0.0030256384052336216,
        0.023504136130213737,
        -0.0606391541659832,
        0.0645788386464119,
        0.06431221216917038,
        0.008010840974748135,
        0.05695958435535431,
        0.041567519307136536,
        0.05284905061125755,
        -0.030613241717219353,
        0.06522275507450104,
        -0.06076408922672272,
        0.049016665667295456,
        0.06686657667160034,
        0.046973928809165955,
        -0.06739459931850433,
        -0.025037948042154312,
        -0.06400839239358902,
        0.03672754764556885,
        -0.00005097081157146022,
        0.01695345528423786,
        0.04642600566148758,
        -0.03141917288303375,
        0.041153255850076675,
        0.06475242972373962,
        0.05181100592017174,
        0.05522528663277626,
        -0.029417600482702255,
        0.04826349765062332,
        0.019207745790481567,
        -0.031423453241586685,
        0.027624573558568954,
        0.029474830254912376,
        0.028583405539393425,
        0.043485794216394424,
        0.027697792276740074,
        -0.0372714027762413,
        -0.059059903025627136,
        0.027122600004076958,
        0.04074668139219284,
        -0.06635444611310959,
        -0.04536580666899681,
        0.0498259998857975,
        -0.06524438410997391,
        0.04297957941889763,
        0.06996481865644455,
        -0.008437284268438816,
        0.05016360804438591,
        0.04587419703602791,
        -0.05446338653564453,
        -0.053269509226083755,
        0.04726571589708328,
        -0.05501455441117287,
        -0.040537673979997635,
        -0.05961894243955612,
        0.033754803240299225,
        -0.004958033561706543,
        -0.026172326877713203,
        0.002255555707961321,
        -0.013565927743911743,
        -0.06081710755825043,
        -0.027450766414403915,
        -0.056549444794654846,
        0.024123437702655792,
        -0.015259696170687675,
        -0.04599315673112869,
        -0.02135314606130123,
        -0.02769291028380394,
        -0.062214989215135574,
        0.05829285830259323,
        -0.0002445646678097546,
        0.026498479768633842,
        -0.008496897295117378,
        0.03835296630859375,
        -0.054385844618082047,
        0.016610872000455856,
        -0.011768300086259842,
        0.02688020095229149,
        -0.045294389128685,
        0.04332731291651726,
        0.05044668912887573,
        -0.027561040595173836,
        0.06406175345182419,
        -0.013067525811493397,
        0.009108555503189564,
        0.06765695661306381,
        -0.04229991137981415,
        -0.06235567480325699,
        0.06555666029453278,
        -0.01699008420109749,
        0.016416706144809723,
        0.000600718951318413,
        -0.019557412713766098,
        -0.061512529850006104,
        -0.03446001932024956,
        -0.0572584867477417,
        -0.04251768812537193,
        0.03292829543352127,
        -0.04720823094248772,
        0.06920252740383148,
        0.06171860545873642,
        0.031420059502124786,
        -0.03609560430049896,
        0.06886600703001022,
        0.017160285264253616,
        0.06914099305868149,
        -0.06861799210309982,
        0.04332496225833893,
        0.036791082471609116,
        -0.03712211549282074,
        -0.06524961441755295,
        0.021595748141407967,
        -0.004150215536355972,
        -0.05094807595014572,
        -0.045259956270456314,
        -0.06662555783987045,
        0.035441942512989044,
        -0.06629321724176407,
        -0.06478683650493622,
        0.0646161288022995,
        -0.06370286643505096,
        -0.038970593363046646,
        0.060362059623003006,
        0.04835962876677513,
        0.06789599359035492,
        -0.04022242873907089,
        -0.0005382520030252635,
        0.02078685723245144,
        -0.0010267409961670637,
        0.03651420399546623,
        0.052353378385305405,
        0.05890912190079689,
        0.028509149327874184,
        0.021893294528126717,
        0.012483615428209305,
        -0.055691469460725784,
        0.06785887479782104,
        -0.05843258276581764,
        -0.026727089658379555,
        0.026293568313121796,
        -0.05065362527966499,
        -0.00003776269659283571,
        -0.029089421033859253,
        -0.03791270777583122,
        -0.06459685415029526,
        -0.05897369608283043,
        -0.06670687347650528,
        0.05405144393444061,
        0.05979089066386223,
        -0.04088946431875229,
        0.02290312387049198,
        0.03855639696121216,
        0.0434066541492939,
        0.02738286554813385,
        -0.018939020112156868,
        0.042491622269153595,
        -0.025644635781645775,
        0.05525458604097366,
        0.05517541617155075,
        -0.06181993708014488,
        0.057707589119672775,
        0.06729179620742798,
        -0.023821506649255753,
        0.02982925996184349,
        0.06436540186405182,
        0.042829014360904694,
        -0.0486861914396286,
        0.060444366186857224,
        -0.02944294922053814,
        0.06003939360380173,
        -0.06433262676000595,
        -0.0051900711841881275,
        -0.032755233347415924,
        -0.06884897500276566,
        -0.0030516537372022867,
        -0.0449853353202343,
        -0.015620258636772633,
        0.04976581782102585,
        -0.036733150482177734,
        -0.05180427059531212,
        0.03944095969200134,
        -0.05184810236096382,
        0.04861029237508774,
        0.010836263187229633,
        -0.048976849764585495,
        -0.0013999363873153925,
        0.03585365042090416,
        0.013127928599715233,
        0.05929344519972801,
        -0.04263357073068619,
        -0.03404767066240311,
        0.019912192597985268,
        0.0007773081888444722,
        -0.06537357717752457,
        0.026820886880159378,
        -0.0055466326884925365,
        -0.05056598782539368,
        -0.06077831983566284,
        0.05442357435822487,
        0.011840577237308025,
        -0.029902970418334007,
        0.06303457170724869,
        -0.0292257871478796,
        0.024610934779047966,
        0.02004229836165905,
        0.06872342526912689,
        -0.06795597821474075,
        -0.03283509984612465,
        0.0640643835067749,
        0.009857065044343472,
        0.011178607121109962,
        0.055098049342632294,
        -0.04919397085905075,
        0.06127683445811272,
        0.02228321135044098,
        0.02242675982415676,
        -0.04536118730902672,
        -0.062337737530469894,
        -0.008589018136262894,
        0.051364947110414505,
        -0.0662180557847023,
        -0.005343986675143242,
        0.052184004336595535,
        -0.06734557449817657,
        0.06261711567640305,
        0.013291855342686176,
        0.005508287809789181,
        0.006763956509530544,
        -0.029700299724936485,
        -0.06119886040687561,
        0.06739745289087296,
        0.014594652689993382,
        0.032487522810697556,
        -0.06290683895349503,
        -0.021976422518491745,
        0.017180319875478745,
        0.056402917951345444,
        0.06350427865982056,
        0.03293517976999283,
        0.029319312423467636,
        -0.05829188600182533,
        0.05722013860940933,
        0.0546809621155262,
        0.04943635314702988,
        0.0051253861747682095,
        -0.06134072318673134,
        -0.0549810566008091,
        0.048357509076595306,
        0.06142331287264824,
        0.030810607597231865,
        -0.012697849422693253,
        -0.055146925151348114,
        -0.027205193415284157,
        -0.026141904294490814,
        -0.06828947365283966,
        -0.007166889496147633,
        0.01295248419046402,
        -0.012788069434463978,
        -0.05116673931479454,
        0.06570044159889221,
        0.053828880190849304,
        0.03410433605313301,
        -0.002297324826940894,
        -0.054800014942884445,
        -0.0038985710125416517,
        0.045533645898103714,
        0.024375375360250473,
        -0.04503517970442772,
        -0.020495763048529625,
        0.013364452868700027,
        0.03451337665319443,
        -0.04718507453799248,
        -0.029884938150644302,
        -0.018693294376134872,
        -0.02519052103161812,
        -0.05994700640439987,
        0.061364900320768356,
        0.00583936832845211,
        0.051472991704940796,
        -0.06530217081308365,
        -0.022546594962477684,
        0.02828650176525116,
        0.03561042249202728,
        0.04757105931639671,
        0.04148585721850395,
        0.015541189350187778,
        0.04846532270312309,
        -0.05773760378360748,
        -0.02759096398949623,
        0.011455955915153027,
        -0.05290690436959267,
        0.0326397679746151,
        0.002579271327704191,
        -0.05428716167807579,
        0.06182335689663887,
        -0.05097474530339241,
        -0.06245794519782066,
        -0.03431098163127899,
        0.02388821914792061,
        -0.0447026789188385,
        -0.051495764404535294,
        -0.007654385641217232,
        0.034535471349954605,
        -0.0011135188397020102,
        0.06705942749977112,
        0.05854758247733116,
        0.05637676268815994,
        0.05322359874844551,
        0.06418802589178085,
        0.025962864980101585,
        0.020545678213238716,
        -0.06583429127931595,
        0.06627196073532104,
        0.04499160125851631,
        -0.053774602711200714,
        0.0032337976153939962,
        -0.011126592755317688,
        -0.009967494755983353,
        0.0037025234196335077,
        -0.05283277854323387,
        -0.023375777527689934,
        0.007739569991827011,
        -0.049847375601530075,
        0.02915581502020359,
        -0.025714965537190437,
        0.032354746013879776,
        -0.06857950985431671,
        0.050938352942466736,
        0.0586489774286747,
        0.06853492558002472,
        -0.032800715416669846,
        0.05087260156869888,
        -0.061726927757263184,
        -0.06573719531297684,
        0.013409208506345749,
        0.06183749809861183,
        0.012100821360945702,
        0.051757682114839554,
        -0.06315977871417999,
        -0.034575268626213074,
        0.017252245917916298,
        0.06000906974077225,
        -0.007803046610206366,
        -0.06126738712191582,
        -0.05992935225367546,
        -0.04890168458223343,
        -0.05679559335112572,
        -0.007736873812973499,
        0.049961771816015244,
        0.0576111376285553,
        0.06591027975082397,
        0.003003657329827547,
        -0.04772484675049782,
        -0.03524543717503548,
        -0.05011487007141113,
        -0.00762749370187521,
        -0.045992158353328705,
        0.015052836388349533,
        -0.024406682699918747,
        -0.0040786308236420155,
        0.06029278784990311,
        0.05028284713625908,
        0.05658498778939247,
        0.06962443888187408,
        -0.06414815038442612,
        -0.0284463819116354,
        -0.004811432678252459,
        -0.022346381098031998,
        0.03055858053267002,
        -0.00950215756893158,
        0.05474185571074486,
        0.019794868305325508,
        -0.0067884172312915325,
        -0.06998375803232193,
        0.00164554372895509,
        0.06771282106637955
    ],
    [
        -0.014515199698507786,
        0.05365646630525589,
        0.007173038553446531,
        0.01842583902180195,
        -0.0004954064497724175,
        -0.008161702193319798,
        0.001632937230169773,
        -0.04706692323088646,
        0.0521792471408844,
        -0.00872223824262619,
        0.04461321607232094,
        0.026910865679383278,
        -0.05523117631673813,
        -0.021767618134617805,
        -0.06522317230701447,
        0.07125645130872726,
        0.013726620934903622,
        0.05373210832476616,
        -0.059315744787454605,
        -0.018370725214481354,
        -0.020910289138555527,
        0.0645238608121872,
        -0.026666048914194107,
        0.0046980977058410645,
        0.02093440294265747,
        0.026822775602340698,
        0.05776895582675934,
        -0.009182649664580822,
        -0.009038416668772697,
        0.007826711051166058,
        0.03886324539780617,
        -0.02839777059853077,
        -0.04839106649160385,
        -0.049386732280254364,
        0.05821484699845314,
        0.02369864098727703,
        -0.057063013315200806,
        0.001974542625248432,
        -0.05069313198328018,
        -0.03283877670764923,
        0.03167364373803139,
        0.010762355290353298,
        0.030114352703094482,
        -0.06915207952260971,
        0.03298969194293022,
        0.055769480764865875,
        0.014601089060306549,
        0.02180580422282219,
        -0.0442454069852829,
        0.056203678250312805,
        0.03504548221826553,
        0.07073769718408585,
        0.005960471928119659,
        0.031109895557165146,
        0.04656103625893593,
        -0.024831190705299377,
        0.01721835322678089,
        -0.016658039763569832,
        -0.01274966448545456,
        0.025527164340019226,
        -0.05009191855788231,
        0.04032798856496811,
        -0.05368553474545479,
        0.063011035323143,
        -0.06689464300870895,
        -0.02643308974802494,
        0.03232738375663757,
        -0.029689408838748932,
        -0.06646506488323212,
        -0.021682187914848328,
        -0.06607791036367416,
        -0.07175759971141815,
        0.06714027374982834,
        0.0060669053345918655,
        0.006096527446061373,
        0.00577113451436162,
        -0.016684090718626976,
        0.07311733812093735,
        -0.024782782420516014,
        -0.04442774876952171,
        0.011292314156889915,
        0.07241407781839371,
        -0.027688832953572273,
        0.019015206024050713,
        0.02746257185935974,
        0.05120271444320679,
        0.07146091759204865,
        -0.03069154918193817,
        0.04748104140162468,
        0.05595451965928078,
        0.0652301236987114,
        0.047498516738414764,
        -0.07017152011394501,
        0.003437170060351491,
        -0.007960042916238308,
        0.0031792016234248877,
        0.023662768304347992,
        -0.06777063757181168,
        0.04296579957008362,
        0.015702754259109497,
        -0.06029816344380379,
        -0.004373601172119379,
        0.06879502534866333,
        0.05494948849081993,
        0.040960438549518585,
        -0.06779699772596359,
        -0.05641157925128937,
        0.037407681345939636,
        -0.035836704075336456,
        0.006585575174540281,
        -0.061248257756233215,
        -0.04611622914671898,
        -0.056044869124889374,
        -0.023064039647579193,
        -0.004244999028742313,
        0.007448411546647549,
        0.050840869545936584,
        0.01102752797305584,
        0.06928586959838867,
        0.01278225053101778,
        -0.05174369737505913,
        0.054550331085920334,
        0.026387663558125496,
        -0.06138487160205841,
        -0.07082739472389221,
        -0.060591064393520355,
        -0.054059937596321106,
        -0.04330563172698021,
        0.06106390431523323,
        0.040911026298999786,
        0.006077250931411982,
        -0.06256232410669327,
        -0.06063404679298401,
        0.025240376591682434,
        -0.04661703482270241,
        -0.06439874321222305,
        -0.005100255832076073,
        0.009731832891702652,
        -0.06732184439897537,
        0.06234864890575409,
        0.06847356259822845,
        0.029306823387742043,
        0.06145168095827103,
        0.049217939376831055,
        0.05589864403009415,
        -0.019396508112549782,
        0.06554324924945831,
        -0.06615713238716125,
        0.04038208723068237,
        0.06801731139421463,
        0.02732413075864315,
        -0.07057025283575058,
        -0.004034924320876598,
        -0.06661038845777512,
        0.022711358964443207,
        0.00794654805213213,
        0.013063672930002213,
        0.04520389437675476,
        -0.02182275429368019,
        0.012922046706080437,
        0.06223871558904648,
        0.05806538835167885,
        0.061911556869745255,
        -0.017720995470881462,
        0.058649010956287384,
        0.011488717049360275,
        -0.037020910531282425,
        0.018051672726869583,
        0.02244659699499607,
        0.02408296801149845,
        0.05387675389647484,
        0.025731122121214867,
        -0.028158672153949738,
        -0.05777425318956375,
        0.043023765087127686,
        0.03241150081157684,
        -0.06603822857141495,
        -0.035042114555835724,
        0.05550335347652435,
        -0.06505617499351501,
        0.042544886469841,
        0.07333434373140335,
        0.014634489081799984,
        0.02890484780073166,
        0.0468439981341362,
        -0.057591285556554794,
        -0.04470118135213852,
        0.053818464279174805,
        -0.05877018719911575,
        -0.006315226200968027,
        -0.05686987191438675,
        0.03367247432470322,
        0.014512176625430584,
        -0.015404612757265568,
        0.0019600428640842438,
        0.0005154900718480349,
        -0.05576300993561745,
        -0.02567192353308201,
        -0.052014391869306564,
        0.021993549540638924,
        -0.03805279731750488,
        -0.026475850492715836,
        -0.03330470249056816,
        -0.029818059876561165,
        -0.06745912879705429,
        0.05318832024931908,
        -0.003260309575125575,
        0.003950690384954214,
        -0.011306318454444408,
        0.041010789573192596,
        -0.05382620915770531,
        0.02226029336452484,
        0.002853366080671549,
        0.03858768567442894,
        -0.020581422373652458,
        0.036714013665914536,
        0.040454793721437454,
        -0.021688207983970642,
        0.06456778943538666,
        -0.021144727244973183,
        0.023576736450195312,
        0.07080593705177307,
        -0.02668205089867115,
        -0.06673461943864822,
        0.061625875532627106,
        0.0029845163226127625,
        0.022845376282930374,
        0.021369878202676773,
        -0.03180566802620888,
        -0.06068044900894165,
        -0.053018663078546524,
        -0.03086318075656891,
        -0.04791375994682312,
        0.0362071692943573,
        -0.023693565279245377,
        0.07221348583698273,
        0.06473153829574585,
        0.024629097431898117,
        -0.04239390417933464,
        0.0720740556716919,
        0.026417667046189308,
        0.07161613553762436,
        -0.07082207500934601,
        0.04827160760760307,
        0.03132728859782219,
        -0.03418857231736183,
        -0.07172926515340805,
        -0.027090558782219887,
        0.010043374262750149,
        -0.04642457142472267,
        -0.051422517746686935,
        -0.06955263763666153,
        0.032331112772226334,
        -0.06655478477478027,
        -0.06647670269012451,
        0.06781996786594391,
        -0.06487122178077698,
        -0.01590844988822937,
        0.06270896643400192,
        0.03558051958680153,
        0.07080963999032974,
        -0.043803274631500244,
        -0.011733833700418472,
        0.010394946672022343,
        0.004698318894952536,
        0.041227132081985474,
        0.03304513171315193,
        0.06362256407737732,
        0.012218303978443146,
        0.021477110683918,
        0.020474320277571678,
        -0.046159300953149796,
        0.06844497472047806,
        -0.05631735175848007,
        -0.037578266113996506,
        0.037557367235422134,
        -0.0530414842069149,
        0.0011672565015032887,
        -0.024650370702147484,
        -0.03262317553162575,
        -0.05160130187869072,
        -0.06471730023622513,
        -0.06844262033700943,
        0.05113689601421356,
        0.04820970445871353,
        -0.041743651032447815,
        0.031057458370923996,
        0.04770597442984581,
        0.02896631695330143,
        0.03586830198764801,
        -0.006356275640428066,
        0.046135131269693375,
        -0.0264210756868124,
        0.06050938740372658,
        0.04974082484841347,
        -0.06731715053319931,
        0.06248399615287781,
        0.06654705107212067,
        -0.04490062594413757,
        0.0392003133893013,
        0.06115070357918739,
        0.04173128679394722,
        -0.05801200494170189,
        0.05647702142596245,
        -0.03812142834067345,
        0.05731276050209999,
        -0.0690489187836647,
        -0.01232652273029089,
        -0.02453688159584999,
        -0.07101767510175705,
        -0.007428756449371576,
        -0.03455737978219986,
        -0.019244754686951637,
        0.036874305456876755,
        -0.0396520160138607,
        -0.05129857361316681,
        0.0502614751458168,
        -0.05782182514667511,
        0.05136669799685478,
        0.004934596363455057,
        -0.05536394938826561,
        0.017311381176114082,
        0.03875141590833664,
        -0.005935399793088436,
        0.05664065107703209,
        -0.0381479375064373,
        -0.029395628720521927,
        0.014235853217542171,
        0.0031443890184164047,
        -0.062444113194942474,
        0.006589618511497974,
        -0.02054782398045063,
        -0.053984735161066055,
        -0.06452171504497528,
        0.060382116585969925,
        0.0005875469651073217,
        -0.007928659208118916,
        0.06682027876377106,
        -0.03169425204396248,
        0.03331342339515686,
        0.0013161307433620095,
        0.0720762386918068,
        -0.07172483950853348,
        -0.011720007285475731,
        0.06502921879291534,
        0.019248977303504944,
        -0.0009454506798647344,
        0.04780558869242668,
        -0.04970170930027962,
        0.06660212576389313,
        0.007424278184771538,
        0.016097823157906532,
        -0.03385059908032417,
        -0.05653243511915207,
        -0.005130566656589508,
        0.04759742692112923,
        -0.06576301902532578,
        -0.0007082471274770796,
        0.048298582434654236,
        -0.06732793152332306,
        0.06469389796257019,
        0.01894257590174675,
        -0.024390388280153275,
        -0.002749197417870164,
        0.000332594703650102,
        -0.06380295753479004,
        0.06524962931871414,
        0.0035870636347681284,
        0.000457781832665205,
        -0.06316620111465454,
        0.007489840034395456,
        0.016489770263433456,
        0.040034785866737366,
        0.0642143189907074,
        0.01736379787325859,
        0.01573283039033413,
        -0.048238132148981094,
        0.050825152546167374,
        0.053440798074007034,
        0.03945060446858406,
        0.002699611708521843,
        -0.06326641887426376,
        -0.06256957352161407,
        0.05696187540888786,
        0.06859547644853592,
        0.03463244065642357,
        -0.028902845457196236,
        -0.06648583710193634,
        -0.034665074199438095,
        -0.026428649201989174,
        -0.06702092289924622,
        -0.017158525064587593,
        0.014182619750499725,
        0.002457992872223258,
        -0.06121902912855148,
        0.06749772280454636,
        0.055232156068086624,
        0.045828983187675476,
        -0.005103315692394972,
        -0.051163580268621445,
        -0.0015686603728681803,
        0.032705243676900864,
        0.024359041824936867,
        -0.03912212699651718,
        -0.0025321769062429667,
        0.02615685574710369,
        0.038019295781850815,
        -0.045574627816677094,
        -0.035929642617702484,
        -0.00045468960888683796,
        -0.03714226186275482,
        -0.06640353053808212,
        0.06242154911160469,
        -0.01569708250463009,
        0.0559452548623085,
        -0.06638193875551224,
        -0.021044673398137093,
        0.018301349133253098,
        0.03344016522169113,
        0.03931962326169014,
        0.012933013960719109,
        -0.008389734663069248,
        0.0408373698592186,
        -0.056458503007888794,
        -0.060176875442266464,
        0.04576222971081734,
        -0.048602137714624405,
        0.0240264143794775,
        0.009881189092993736,
        -0.05304922163486481,
        0.05433821678161621,
        -0.041692040860652924,
        -0.06030033528804779,
        -0.03134637326002121,
        0.04422134533524513,
        -0.04253757745027542,
        -0.058266982436180115,
        0.01953386329114437,
        0.027812819927930832,
        -0.01466868445277214,
        0.06852181255817413,
        0.05736434832215309,
        0.05167882889509201,
        0.046364713460206985,
        0.06763240694999695,
        0.0315636545419693,
        0.025048481300473213,
        -0.06234344094991684,
        0.07014032453298569,
        0.04558657482266426,
        -0.04325715824961662,
        -0.008850780315697193,
        -0.029542850330471992,
        -0.016641147434711456,
        0.0032410735730081797,
        -0.053149957209825516,
        0.0018972230609506369,
        -0.01754864677786827,
        -0.03717289865016937,
        0.009918873198330402,
        -0.007236239966005087,
        0.03397195786237717,
        -0.07048410922288895,
        0.048064764589071274,
        0.05548134446144104,
        0.07068514078855515,
        -0.027606690302491188,
        0.0553046278655529,
        -0.05820807069540024,
        -0.06535159796476364,
        -0.001790928072296083,
        0.03813478723168373,
        0.020083770155906677,
        0.036356646567583084,
        -0.0611121729016304,
        -0.05363836884498596,
        0.040137335658073425,
        0.054555743932724,
        -0.0006619800115004182,
        -0.06500503420829773,
        -0.06012183055281639,
        -0.03896579518914223,
        -0.054596077650785446,
        -0.00012255967885721475,
        0.05152188241481781,
        0.054338518530130386,
        0.06910225003957748,
        0.010121489875018597,
        -0.05380875617265701,
        -0.01605956070125103,
        -0.04989383742213249,
        0.006378112826496363,
        -0.05029181391000748,
        0.012106562964618206,
        -0.031431712210178375,
        0.0015411191852763295,
        0.06530746817588806,
        0.0432305745780468,
        0.06274630129337311,
        0.07220492511987686,
        -0.06698247790336609,
        -0.027703799307346344,
        -0.013388033956289291,
        0.008790149353444576,
        0.023839101195335388,
        -0.0053642988204956055,
        0.035703789442777634,
        0.026026524603366852,
        -0.021174030378460884,
        -0.0735379010438919,
        -0.0020845269318670034,
        0.06995846331119537
    ],
    [
        -0.014515199698507786,
        0.05365646630525589,
        0.007173038553446531,
        0.01842583902180195,
        -0.0004954064497724175,
        -0.008161702193319798,
        0.001632937230169773,
        -0.04706692323088646,
        0.0521792471408844,
        -0.00872223824262619,
        0.04461321607232094,
        0.026910865679383278,
        -0.05523117631673813,
        -0.021767618134617805,
        -0.06522317230701447,
        0.07125645130872726,
        0.013726620934903622,
        0.05373210832476616,
        -0.059315744787454605,
        -0.018370725214481354,
        -0.020910289138555527,
        0.0645238608121872,
        -0.026666048914194107,
        0.0046980977058410645,
        0.02093440294265747,
        0.026822775602340698,
        0.05776895582675934,
        -0.009182649664580822,
        -0.009038416668772697,
        0.007826711051166058,
        0.03886324539780617,
        -0.02839777059853077,
        -0.04839106649160385,
        -0.049386732280254364,
        0.05821484699845314,
        0.02369864098727703,
        -0.057063013315200806,
        0.001974542625248432,
        -0.05069313198328018,
        -0.03283877670764923,
        0.03167364373803139,
        0.010762355290353298,
        0.030114352703094482,
        -0.06915207952260971,
        0.03298969194293022,
        0.055769480764865875,
        0.014601089060306549,
        0.02180580422282219,
        -0.0442454069852829,
        0.056203678250312805,
        0.03504548221826553,
        0.07073769718408585,
        0.005960471928119659,
        0.031109895557165146,
        0.04656103625893593,
        -0.024831190705299377,
        0.01721835322678089,
        -0.016658039763569832,
        -0.01274966448545456,
        0.025527164340019226,
        -0.05009191855788231,
        0.04032798856496811,
        -0.05368553474545479,
        0.063011035323143,
        -0.06689464300870895,
        -0.02643308974802494,
        0.03232738375663757,
        -0.029689408838748932,
        -0.06646506488323212,
        -0.021682187914848328,
        -0.06607791036367416,
        -0.07175759971141815,
        0.06714027374982834,
        0.0060669053345918655,
        0.006096527446061373,
        0.00577113451436162,
        -0.016684090718626976,
        0.07311733812093735,
        -0.024782782420516014,
        -0.04442774876952171,
        0.011292314156889915,
        0.07241407781839371,
        -0.027688832953572273,
        0.019015206024050713,
        0.02746257185935974,
        0.05120271444320679,
        0.07146091759204865,
        -0.03069154918193817,
        0.04748104140162468,
        0.05595451965928078,
        0.0652301236987114,
        0.047498516738414764,
        -0.07017152011394501,
        0.003437170060351491,
        -0.007960042916238308,
        0.0031792016234248877,
        0.023662768304347992,
        -0.06777063757181168,
        0.04296579957008362,
        0.015702754259109497,
        -0.06029816344380379,
        -0.004373601172119379,
        0.06879502534866333,
        0.05494948849081993,
        0.040960438549518585,
        -0.06779699772596359,
        -0.05641157925128937,
        0.037407681345939636,
        -0.035836704075336456,
        0.006585575174540281,
        -0.061248257756233215,
        -0.04611622914671898,
        -0.056044869124889374,
        -0.023064039647579193,
        -0.004244999028742313,
        0.007448411546647549,
        0.050840869545936584,
        0.01102752797305584,
        0.06928586959838867,
        0.01278225053101778,
        -0.05174369737505913,
        0.054550331085920334,
        0.026387663558125496,
        -0.06138487160205841,
        -0.07082739472389221,
        -0.060591064393520355,
        -0.054059937596321106,
        -0.04330563172698021,
        0.06106390431523323,
        0.040911026298999786,
        0.006077250931411982,
        -0.06256232410669327,
        -0.06063404679298401,
        0.025240376591682434,
        -0.04661703482270241,
        -0.06439874321222305,
        -0.005100255832076073,
        0.009731832891702652,
        -0.06732184439897537,
        0.06234864890575409,
        0.06847356259822845,
        0.029306823387742043,
        0.06145168095827103,
        0.049217939376831055,
        0.05589864403009415,
        -0.019396508112549782,
        0.06554324924945831,
        -0.06615713238716125,
        0.04038208723068237,
        0.06801731139421463,
        0.02732413075864315,
        -0.07057025283575058,
        -0.004034924320876598,
        -0.06661038845777512,
        0.022711358964443207,
        0.00794654805213213,
        0.013063672930002213,
        0.04520389437675476,
        -0.02182275429368019,
        0.012922046706080437,
        0.06223871558904648,
        0.05806538835167885,
        0.061911556869745255,
        -0.017720995470881462,
        0.058649010956287384,
        0.011488717049360275,
        -0.037020910531282425,
        0.018051672726869583,
        0.02244659699499607,
        0.02408296801149845,
        0.05387675389647484,
        0.025731122121214867,
        -0.028158672153949738,
        -0.05777425318956375,
        0.043023765087127686,
        0.03241150081157684,
        -0.06603822857141495,
        -0.035042114555835724,
        0.05550335347652435,
        -0.06505617499351501,
        0.042544886469841,
        0.07333434373140335,
        0.014634489081799984,
        0.02890484780073166,
        0.0468439981341362,
        -0.057591285556554794,
        -0.04470118135213852,
        0.053818464279174805,
        -0.05877018719911575,
        -0.006315226200968027,
        -0.05686987191438675,
        0.03367247432470322,
        0.014512176625430584,
        -0.015404612757265568,
        0.0019600428640842438,
        0.0005154900718480349,
        -0.05576300993561745,
        -0.02567192353308201,
        -0.052014391869306564,
        0.021993549540638924,
        -0.03805279731750488,
        -0.026475850492715836,
        -0.03330470249056816,
        -0.029818059876561165,
        -0.06745912879705429,
        0.05318832024931908,
        -0.003260309575125575,
        0.003950690384954214,
        -0.011306318454444408,
        0.041010789573192596,
        -0.05382620915770531,
        0.02226029336452484,
        0.002853366080671549,
        0.03858768567442894,
        -0.020581422373652458,
        0.036714013665914536,
        0.040454793721437454,
        -0.021688207983970642,
        0.06456778943538666,
        -0.021144727244973183,
        0.023576736450195312,
        0.07080593705177307,
        -0.02668205089867115,
        -0.06673461943864822,
        0.061625875532627106,
        0.0029845163226127625,
        0.022845376282930374,
        0.021369878202676773,
        -0.03180566802620888,
        -0.06068044900894165,
        -0.053018663078546524,
        -0.03086318075656891,
        -0.04791375994682312,
        0.0362071692943573,
        -0.023693565279245377,
        0.07221348583698273,
        0.06473153829574585,
        0.024629097431898117,
        -0.04239390417933464,
        0.0720740556716919,
        0.026417667046189308,
        0.07161613553762436,
        -0.07082207500934601,
        0.04827160760760307,
        0.03132728859782219,
        -0.03418857231736183,
        -0.07172926515340805,
        -0.027090558782219887,
        0.010043374262750149,
        -0.04642457142472267,
        -0.051422517746686935,
        -0.06955263763666153,
        0.032331112772226334,
        -0.06655478477478027,
        -0.06647670269012451,
        0.06781996786594391,
        -0.06487122178077698,
        -0.01590844988822937,
        0.06270896643400192,
        0.03558051958680153,
        0.07080963999032974,
        -0.043803274631500244,
        -0.011733833700418472,
        0.010394946672022343,
        0.004698318894952536,
        0.041227132081985474,
        0.03304513171315193,
        0.06362256407737732,
        0.012218303978443146,
        0.021477110683918,
        0.020474320277571678,
        -0.046159300953149796,
        0.06844497472047806,
        -0.05631735175848007,
        -0.037578266113996506,
        0.037557367235422134,
        -0.0530414842069149,
        0.0011672565015032887,
        -0.024650370702147484,
        -0.03262317553162575,
        -0.05160130187869072,
        -0.06471730023622513,
        -0.06844262033700943,
        0.05113689601421356,
        0.04820970445871353,
        -0.041743651032447815,
        0.031057458370923996,
        0.04770597442984581,
        0.02896631695330143,
        0.03586830198764801,
        -0.006356275640428066,
        0.046135131269693375,
        -0.0264210756868124,
        0.06050938740372658,
        0.04974082484841347,
        -0.06731715053319931,
        0.06248399615287781,
        0.06654705107212067,
        -0.04490062594413757,
        0.0392003133893013,
        0.06115070357918739,
        0.04173128679394722,
        -0.05801200494170189,
        0.05647702142596245,
        -0.03812142834067345,
        0.05731276050209999,
        -0.0690489187836647,
        -0.01232652273029089,
        -0.02453688159584999,
        -0.07101767510175705,
        -0.007428756449371576,
        -0.03455737978219986,
        -0.019244754686951637,
        0.036874305456876755,
        -0.0396520160138607,
        -0.05129857361316681,
        0.0502614751458168,
        -0.05782182514667511,
        0.05136669799685478,
        0.004934596363455057,
        -0.05536394938826561,
        0.017311381176114082,
        0.03875141590833664,
        -0.005935399793088436,
        0.05664065107703209,
        -0.0381479375064373,
        -0.029395628720521927,
        0.014235853217542171,
        0.0031443890184164047,
        -0.062444113194942474,
        0.006589618511497974,
        -0.02054782398045063,
        -0.053984735161066055,
        -0.06452171504497528,
        0.060382116585969925,
        0.0005875469651073217,
        -0.007928659208118916,
        0.06682027876377106,
        -0.03169425204396248,
        0.03331342339515686,
        0.0013161307433620095,
        0.0720762386918068,
        -0.07172483950853348,
        -0.011720007285475731,
        0.06502921879291534,
        0.019248977303504944,
        -0.0009454506798647344,
        0.04780558869242668,
        -0.04970170930027962,
        0.06660212576389313,
        0.007424278184771538,
        0.016097823157906532,
        -0.03385059908032417,
        -0.05653243511915207,
        -0.005130566656589508,
        0.04759742692112923,
        -0.06576301902532578,
        -0.0007082471274770796,
        0.048298582434654236,
        -0.06732793152332306,
        0.06469389796257019,
        0.01894257590174675,
        -0.024390388280153275,
        -0.002749197417870164,
        0.000332594703650102,
        -0.06380295753479004,
        0.06524962931871414,
        0.0035870636347681284,
        0.000457781832665205,
        -0.06316620111465454,
        0.007489840034395456,
        0.016489770263433456,
        0.040034785866737366,
        0.0642143189907074,
        0.01736379787325859,
        0.01573283039033413,
        -0.048238132148981094,
        0.050825152546167374,
        0.053440798074007034,
        0.03945060446858406,
        0.002699611708521843,
        -0.06326641887426376,
        -0.06256957352161407,
        0.05696187540888786,
        0.06859547644853592,
        0.03463244065642357,
        -0.028902845457196236,
        -0.06648583710193634,
        -0.034665074199438095,
        -0.026428649201989174,
        -0.06702092289924622,
        -0.017158525064587593,
        0.014182619750499725,
        0.002457992872223258,
        -0.06121902912855148,
        0.06749772280454636,
        0.055232156068086624,
        0.045828983187675476,
        -0.005103315692394972,
        -0.051163580268621445,
        -0.0015686603728681803,
        0.032705243676900864,
        0.024359041824936867,
        -0.03912212699651718,
        -0.0025321769062429667,
        0.02615685574710369,
        0.038019295781850815,
        -0.045574627816677094,
        -0.035929642617702484,
        -0.00045468960888683796,
        -0.03714226186275482,
        -0.06640353053808212,
        0.06242154911160469,
        -0.01569708250463009,
        0.0559452548623085,
        -0.06638193875551224,
        -0.021044673398137093,
        0.018301349133253098,
        0.03344016522169113,
        0.03931962326169014,
        0.012933013960719109,
        -0.008389734663069248,
        0.0408373698592186,
        -0.056458503007888794,
        -0.060176875442266464,
        0.04576222971081734,
        -0.048602137714624405,
        0.0240264143794775,
        0.009881189092993736,
        -0.05304922163486481,
        0.05433821678161621,
        -0.041692040860652924,
        -0.06030033528804779,
        -0.03134637326002121,
        0.04422134533524513,
        -0.04253757745027542,
        -0.058266982436180115,
        0.01953386329114437,
        0.027812819927930832,
        -0.01466868445277214,
        0.06852181255817413,
        0.05736434832215309,
        0.05167882889509201,
        0.046364713460206985,
        0.06763240694999695,
        0.0315636545419693,
        0.025048481300473213,
        -0.06234344094991684,
        0.07014032453298569,
        0.04558657482266426,
        -0.04325715824961662,
        -0.008850780315697193,
        -0.029542850330471992,
        -0.016641147434711456,
        0.0032410735730081797,
        -0.053149957209825516,
        0.0018972230609506369,
        -0.01754864677786827,
        -0.03717289865016937,
        0.009918873198330402,
        -0.007236239966005087,
        0.03397195786237717,
        -0.07048410922288895,
        0.048064764589071274,
        0.05548134446144104,
        0.07068514078855515,
        -0.027606690302491188,
        0.0553046278655529,
        -0.05820807069540024,
        -0.06535159796476364,
        -0.001790928072296083,
        0.03813478723168373,
        0.020083770155906677,
        0.036356646567583084,
        -0.0611121729016304,
        -0.05363836884498596,
        0.040137335658073425,
        0.054555743932724,
        -0.0006619800115004182,
        -0.06500503420829773,
        -0.06012183055281639,
        -0.03896579518914223,
        -0.054596077650785446,
        -0.00012255967885721475,
        0.05152188241481781,
        0.054338518530130386,
        0.06910225003957748,
        0.010121489875018597,
        -0.05380875617265701,
        -0.01605956070125103,
        -0.04989383742213249,
        0.006378112826496363,
        -0.05029181391000748,
        0.012106562964618206,
        -0.031431712210178375,
        0.0015411191852763295,
        0.06530746817588806,
        0.0432305745780468,
        0.06274630129337311,
        0.07220492511987686,
        -0.06698247790336609,
        -0.027703799307346344,
        -0.013388033956289291,
        0.008790149353444576,
        0.023839101195335388,
        -0.0053642988204956055,
        0.035703789442777634,
        0.026026524603366852,
        -0.021174030378460884,
        -0.0735379010438919,
        -0.0020845269318670034,
        0.06995846331119537
    ],
    [
        -0.03404824063181877,
        0.043703995645046234,
        0.005786275956779718,
        0.011648804880678654,
        0.008307190611958504,
        -0.026314323768019676,
        0.014555052854120731,
        -0.046030573546886444,
        0.053359221667051315,
        0.0019473681459203362,
        0.048786625266075134,
        0.031206348910927773,
        -0.054788652807474136,
        -0.02717614732682705,
        -0.06311919540166855,
        0.06805185973644257,
        0.018265532329678535,
        0.05172617733478546,
        -0.05756157636642456,
        -0.043562229722738266,
        -0.0022043902426958084,
        0.06455330550670624,
        -0.0048219128511846066,
        -0.0060563101433217525,
        0.020202921703457832,
        0.022264845669269562,
        0.06239260360598564,
        -0.019212158396840096,
        0.000055535590945510194,
        0.027246005833148956,
        0.019127318635582924,
        -0.01858970895409584,
        -0.05296368896961212,
        -0.05863427370786667,
        0.05934163182973862,
        0.020287292078137398,
        -0.05773283168673515,
        -0.00022932265710551292,
        -0.03856097534298897,
        -0.0357651561498642,
        0.03923448920249939,
        0.01429059635847807,
        0.030111728236079216,
        -0.06447888165712357,
        0.021774442866444588,
        0.05510951206088066,
        0.029560638591647148,
        0.02616879902780056,
        -0.028476214036345482,
        0.056119658052921295,
        0.04799195006489754,
        0.06759674102067947,
        0.0003319506358820945,
        0.03345543518662453,
        0.04939666762948036,
        -0.0374152697622776,
        0.0070168874226510525,
        -0.022788461297750473,
        0.014294520951807499,
        0.009509637951850891,
        -0.05233548954129219,
        0.03843601047992706,
        -0.05302140861749649,
        0.06491459161043167,
        -0.06437871605157852,
        -0.016246555373072624,
        0.01905013620853424,
        -0.03853221237659454,
        -0.06428245455026627,
        0.0021250739227980375,
        -0.06486731767654419,
        -0.06887644529342651,
        0.06400267779827118,
        0.00276465923525393,
        0.03461809456348419,
        0.006416852120310068,
        0.004506510216742754,
        0.0696084275841713,
        -0.021655486896634102,
        -0.049961477518081665,
        0.01842726208269596,
        0.06915755569934845,
        -0.014459357596933842,
        0.03751348331570625,
        0.040715720504522324,
        0.047734249383211136,
        0.0686006024479866,
        -0.03127869963645935,
        0.0516267828643322,
        0.05448015034198761,
        0.05120597034692764,
        0.0406976081430912,
        -0.06917271763086319,
        0.0000363411963917315,
        0.005064492113888264,
        0.004358955658972263,
        0.02558668702840805,
        -0.06581134349107742,
        0.04589316248893738,
        0.010173644870519638,
        -0.05682319402694702,
        0.01752108335494995,
        0.06518466025590897,
        0.05962791666388512,
        0.047854240983724594,
        -0.0674348771572113,
        -0.04858098924160004,
        0.04762481153011322,
        -0.04170802980661392,
        0.0251723974943161,
        -0.062374088913202286,
        -0.05049562081694603,
        -0.05643517151474953,
        -0.022255640476942062,
        -0.019434234127402306,
        -0.0024653999134898186,
        0.05301143229007721,
        -0.010220666415989399,
        0.06722365319728851,
        0.02470983751118183,
        -0.04703722521662712,
        0.05704504996538162,
        0.032506976276636124,
        -0.05551917478442192,
        -0.06727831810712814,
        -0.04932939633727074,
        -0.05571998283267021,
        -0.05928562581539154,
        0.053234606981277466,
        0.04812932759523392,
        0.013552593067288399,
        -0.05543262138962746,
        -0.055598143488168716,
        0.02649412304162979,
        -0.04114193096756935,
        -0.06230901926755905,
        0.012756633572280407,
        0.021947970613837242,
        -0.06487134099006653,
        0.06380389630794525,
        0.06484577059745789,
        0.027935585007071495,
        0.05782356485724449,
        0.042961396276950836,
        0.049504321068525314,
        -0.03324028477072716,
        0.06533784419298172,
        -0.06338807195425034,
        0.047110386192798615,
        0.06626886129379272,
        0.038525376468896866,
        -0.0672781690955162,
        -0.0023674380499869585,
        -0.06434905529022217,
        0.029028229415416718,
        0.009455950930714607,
        0.010648222640156746,
        0.04480592533946037,
        -0.027731603011488914,
        0.032174646854400635,
        0.06409088522195816,
        0.05040106177330017,
        0.05754741281270981,
        -0.03668450564146042,
        0.05361105501651764,
        0.019199753180146217,
        -0.028259214013814926,
        0.01929718442261219,
        0.02008124068379402,
        0.0220344215631485,
        0.051060378551483154,
        0.019076960161328316,
        -0.027958838269114494,
        -0.06066535785794258,
        0.03864658623933792,
        0.036192797124385834,
        -0.0631072148680687,
        -0.0392540879547596,
        0.046558160334825516,
        -0.06585151702165604,
        0.041049450635910034,
        0.069953054189682,
        0.01085897721350193,
        0.04164088889956474,
        0.042929552495479584,
        -0.0592934787273407,
        -0.05044391006231308,
        0.052268996834754944,
        -0.05543327331542969,
        -0.027181247249245644,
        -0.0561133548617363,
        0.03950927034020424,
        0.013273308984935284,
        -0.006715681403875351,
        -0.005247643683105707,
        -0.022616855800151825,
        -0.060014598071575165,
        -0.03337851166725159,
        -0.059520117938518524,
        0.03143693134188652,
        -0.029744787141680717,
        -0.035820700228214264,
        -0.041130803525447845,
        -0.039759621024131775,
        -0.06462131440639496,
        0.05747546628117561,
        -0.0036806436255574226,
        0.015811897814273834,
        -0.00506946537643671,
        0.04048740491271019,
        -0.051727619022130966,
        0.026375627145171165,
        -0.0021690926514565945,
        0.03980511426925659,
        -0.04070043936371803,
        0.03027845174074173,
        0.03701331466436386,
        -0.03488055616617203,
        0.06365031003952026,
        -0.02910647913813591,
        0.0032026951666921377,
        0.06856095790863037,
        -0.033386893570423126,
        -0.06154276058077812,
        0.06328333169221878,
        -0.0023916875943541527,
        0.023739878088235855,
        0.009450213983654976,
        -0.02640482410788536,
        -0.05966748669743538,
        -0.045314475893974304,
        -0.04789939150214195,
        -0.046892255544662476,
        0.037092506885528564,
        -0.04267663508653641,
        0.06882312893867493,
        0.06362888216972351,
        0.028071314096450806,
        -0.04225941747426987,
        0.06857382506132126,
        0.022108322009444237,
        0.06895913183689117,
        -0.06902217119932175,
        0.04656512662768364,
        0.03188902139663696,
        -0.040131911635398865,
        -0.0666583999991417,
        0.002473978092893958,
        0.010492932982742786,
        -0.04811151325702667,
        -0.052297741174697876,
        -0.0664948895573616,
        0.02200702391564846,
        -0.06473352015018463,
        -0.06434740871191025,
        0.06488887965679169,
        -0.06432779133319855,
        -0.029082149267196655,
        0.06189668923616409,
        0.03632891923189163,
        0.06771252304315567,
        -0.040039367973804474,
        -0.005710783880203962,
        0.01895831525325775,
        -0.010924703441560268,
        0.03813663870096207,
        0.0462837740778923,
        0.06232912093400955,
        0.03390650078654289,
        0.027695946395397186,
        0.00893488060683012,
        -0.05624525621533394,
        0.0676426887512207,
        -0.05336809903383255,
        -0.036845818161964417,
        0.020912621170282364,
        -0.05094051733613014,
        0.00048670111573301256,
        -0.03562169894576073,
        -0.03296045586466789,
        -0.06365562975406647,
        -0.0574297159910202,
        -0.06617022305727005,
        0.05158594623208046,
        0.05955785885453224,
        -0.04533988982439041,
        0.026383398100733757,
        0.047291096299886703,
        0.03762419894337654,
        0.02251395396888256,
        -0.010043391957879066,
        0.043047089129686356,
        -0.02289392426609993,
        0.0581115260720253,
        0.05636996030807495,
        -0.06310070306062698,
        0.0603022463619709,
        0.06715109944343567,
        -0.0387997068464756,
        0.028831856325268745,
        0.0633903294801712,
        0.04321391507983208,
        -0.04943911358714104,
        0.05664869770407677,
        -0.024344060570001602,
        0.05592687427997589,
        -0.06440021097660065,
        -0.012557953596115112,
        -0.032458480447530746,
        -0.06741466373205185,
        0.0023383141960948706,
        -0.0406217984855175,
        -0.02641242742538452,
        0.04338986799120903,
        -0.026662595570087433,
        -0.05141846090555191,
        0.04158749431371689,
        -0.05723056569695473,
        0.04694439470767975,
        0.011556626297533512,
        -0.04399696737527847,
        -0.006618360057473183,
        0.033518675714731216,
        0.008324841968715191,
        0.05821787193417549,
        -0.04022037610411644,
        -0.03509053215384483,
        0.029240505769848824,
        0.006861417088657618,
        -0.06412848830223083,
        0.03026198409497738,
        -0.009557015262544155,
        -0.05618983879685402,
        -0.06094972789287567,
        0.053378187119960785,
        0.01641870103776455,
        -0.0267340075224638,
        0.06178240478038788,
        -0.029467517510056496,
        0.02847077138721943,
        0.014855838380753994,
        0.06867730617523193,
        -0.06845327466726303,
        -0.02549665980041027,
        0.058532122522592545,
        0.014020681381225586,
        0.011369318701326847,
        0.05149105563759804,
        -0.05149116739630699,
        0.05901871994137764,
        0.017642315477132797,
        0.02351011335849762,
        -0.04925219342112541,
        -0.06328461319208145,
        -0.005226914305239916,
        0.04916119948029518,
        -0.0668824091553688,
        -0.006439779885113239,
        0.054207202047109604,
        -0.06688327342271805,
        0.06255639344453812,
        0.02126733586192131,
        -0.0014139736304059625,
        -0.002192815998569131,
        -0.011265132576227188,
        -0.06302046775817871,
        0.06708994507789612,
        0.00033807186991907656,
        0.023190278559923172,
        -0.06264061480760574,
        -0.023759806528687477,
        0.010376370511949062,
        0.047284048050642014,
        0.06452104449272156,
        0.02587304264307022,
        0.030949508771300316,
        -0.04652748256921768,
        0.05602888762950897,
        0.05906597524881363,
        0.05060828477144241,
        -0.01509014144539833,
        -0.06167363002896309,
        -0.05569545552134514,
        0.04132641851902008,
        0.06234749034047127,
        0.03769901394844055,
        -0.0237785205245018,
        -0.05765969678759575,
        -0.0369158573448658,
        -0.011408157646656036,
        -0.06713695079088211,
        -0.009281252510845661,
        0.01674872823059559,
        0.0006881242734380066,
        -0.05284041166305542,
        0.06490248441696167,
        0.05735085904598236,
        0.03123241290450096,
        0.0043050977401435375,
        -0.05445995181798935,
        -0.002370452508330345,
        0.03935437649488449,
        0.026366211473941803,
        -0.04817113280296326,
        -0.010720805265009403,
        0.013417045585811138,
        0.03711457550525665,
        -0.046572428196668625,
        -0.02962481789290905,
        -0.013950495980679989,
        -0.03321262449026108,
        -0.060204990208148956,
        0.06169796735048294,
        -0.0003223764942958951,
        0.052873216569423676,
        -0.06495026499032974,
        -0.029276100918650627,
        0.007470365613698959,
        0.03566393256187439,
        0.04648087918758392,
        0.041921939700841904,
        0.0004738595453090966,
        0.04682157561182976,
        -0.05561814829707146,
        -0.04547382891178131,
        0.030999798327684402,
        -0.05379202589392662,
        0.03958382457494736,
        -0.0022893771529197693,
        -0.05130608379840851,
        0.06106848269701004,
        -0.04862695187330246,
        -0.05925114452838898,
        -0.031301435083150864,
        0.028096871450543404,
        -0.04601850360631943,
        -0.042042262852191925,
        0.012033329345285892,
        0.040614061057567596,
        -0.012158594094216824,
        0.06498774886131287,
        0.06114216148853302,
        0.058542460203170776,
        0.05204536393284798,
        0.06418503075838089,
        0.020698871463537216,
        0.015781553462147713,
        -0.06495067477226257,
        0.06681659072637558,
        0.04921237751841545,
        -0.049893781542778015,
        -0.021892623975872993,
        -0.014432620257139206,
        -0.012977469712495804,
        0.01059052161872387,
        -0.05314948409795761,
        -0.024778064340353012,
        -0.006427086424082518,
        -0.041337646543979645,
        0.030041774734854698,
        -0.017988761886954308,
        0.030455254018306732,
        -0.06781482696533203,
        0.04960703104734421,
        0.05713130161166191,
        0.068278007209301,
        -0.033632878214120865,
        0.05365345999598503,
        -0.061427827924489975,
        -0.06338675320148468,
        0.01152016967535019,
        0.053530629724264145,
        0.014475670643150806,
        0.045235760509967804,
        -0.06621772050857544,
        -0.04465736076235771,
        0.02505715750157833,
        0.05700327083468437,
        -0.0038843636866658926,
        -0.06157589331269264,
        -0.05939806252717972,
        -0.04342043399810791,
        -0.06151789054274559,
        -0.006375833414494991,
        0.05072665214538574,
        0.05928811803460121,
        0.06646803021430969,
        -0.0007758201682008803,
        -0.049348436295986176,
        -0.037587784230709076,
        -0.04319879412651062,
        0.002401256002485752,
        -0.0497259795665741,
        0.018329141661524773,
        -0.018947158008813858,
        -0.00028608384309336543,
        0.0623561330139637,
        0.05124872922897339,
        0.059289369732141495,
        0.06949598342180252,
        -0.06745322793722153,
        -0.023717636242508888,
        -0.01753874309360981,
        -0.019666247069835663,
        0.04369620606303215,
        -0.015910010784864426,
        0.052851203829050064,
        0.02085595391690731,
        -0.01487290021032095,
        -0.0699000284075737,
        -0.00950718019157648,
        0.06709739565849304
    ],
    [
        -0.012703088112175465,
        0.056825872510671616,
        0.0193015206605196,
        0.018680091947317123,
        0.002495616441592574,
        0.007552662864327431,
        0.013900000602006912,
        -0.05127694457769394,
        0.047942277044057846,
        -0.005339960101991892,
        0.050328079611063004,
        0.0027944929897785187,
        -0.05281203240156174,
        -0.010131332091987133,
        -0.06614405661821365,
        0.06940726190805435,
        0.006201958283782005,
        0.055027466267347336,
        -0.055981069803237915,
        -0.007357012014836073,
        -0.029965847730636597,
        0.06648582965135574,
        -0.03057825192809105,
        0.0034037635196000338,
        0.03937182202935219,
        0.018651556223630905,
        0.05786047875881195,
        0.0020230154041200876,
        -0.005612262524664402,
        0.0029435502365231514,
        0.051039356738328934,
        -0.014793072827160358,
        -0.050324272364377975,
        -0.05714946240186691,
        0.06047128513455391,
        0.03120291605591774,
        -0.05926109850406647,
        0.0001085701587726362,
        -0.023266682401299477,
        -0.032942526042461395,
        0.013837622478604317,
        0.024861721321940422,
        0.02684728242456913,
        -0.06760207563638687,
        0.00009170235716737807,
        0.05668046325445175,
        -0.007377589587122202,
        0.01954827643930912,
        -0.03800731524825096,
        0.05725608393549919,
        0.026453988626599312,
        0.06984022259712219,
        0.0006511069368571043,
        0.026607565581798553,
        0.04724448546767235,
        -0.01817139983177185,
        0.003199795028194785,
        -0.01309926901012659,
        0.0002343643136555329,
        0.02607830800116062,
        -0.04340358451008797,
        0.04584108665585518,
        -0.052100200206041336,
        0.06118175387382507,
        -0.06567493826150894,
        -0.03914085403084755,
        0.020960213616490364,
        -0.02557375282049179,
        -0.0678187757730484,
        -0.016307266429066658,
        -0.06584154814481735,
        -0.0739799216389656,
        0.0711192712187767,
        0.0031133026350289583,
        -0.0012384036090224981,
        0.010390841402113438,
        -0.01437213271856308,
        0.07399173080921173,
        -0.01803758554160595,
        -0.036453727632761,
        0.012846441008150578,
        0.07247406989336014,
        -0.03177403658628464,
        0.020056283101439476,
        0.03861025720834732,
        0.04799409210681915,
        0.0721033439040184,
        -0.04658559709787369,
        0.04792439937591553,
        0.05279119312763214,
        0.0670316219329834,
        0.04883025959134102,
        -0.07028768211603165,
        0.0188912283629179,
        -0.0010843995260074735,
        0.010634424164891243,
        0.01244852039963007,
        -0.0682116448879242,
        0.04321349039673805,
        0.01475320290774107,
        -0.05910574272274971,
        -0.013210300356149673,
        0.06854482740163803,
        0.05097464844584465,
        0.03959304094314575,
        -0.06405923515558243,
        -0.059328172355890274,
        0.03169354051351547,
        -0.046199552714824677,
        0.01422587875276804,
        -0.06164862588047981,
        -0.0315646231174469,
        -0.06290191411972046,
        -0.035275813192129135,
        -0.015130262821912766,
        -0.007972298189997673,
        0.05132770538330078,
        0.0038251164369285107,
        0.07052095234394073,
        0.017711063846945763,
        -0.05688435956835747,
        0.05988537147641182,
        0.018285799771547318,
        -0.057002563029527664,
        -0.07166819274425507,
        -0.059902388602495193,
        -0.055169928818941116,
        -0.027673963457345963,
        0.05917663872241974,
        0.021805427968502045,
        -0.010012537240982056,
        -0.06205075606703758,
        -0.05705909803509712,
        0.024298319593071938,
        -0.04490277171134949,
        -0.0659537985920906,
        -0.004074279218912125,
        0.003774174489080906,
        -0.0648985207080841,
        0.06523238867521286,
        0.0715465173125267,
        0.0281401127576828,
        0.06230417266488075,
        0.04866747558116913,
        0.06186505779623985,
        -0.03096841275691986,
        0.06899408996105194,
        -0.06533761322498322,
        0.03352091461420059,
        0.06865531206130981,
        0.03561918064951897,
        -0.07136812061071396,
        -0.041998203843832016,
        -0.06750351935625076,
        0.04206056892871857,
        -0.00258076679892838,
        0.01960739493370056,
        0.02953585423529148,
        -0.026497632265090942,
        0.0195623692125082,
        0.060151148587465286,
        0.06589160859584808,
        0.0611150860786438,
        -0.007807211019098759,
        0.062260016798973083,
        -0.010562348179519176,
        -0.030679725110530853,
        0.017550796270370483,
        0.029113298282027245,
        0.027366474270820618,
        0.041548144072294235,
        0.024374675005674362,
        -0.03227430582046509,
        -0.05463689938187599,
        0.019087674096226692,
        0.029247483238577843,
        -0.06655071675777435,
        -0.04294919595122337,
        0.056239884346723557,
        -0.06431302428245544,
        0.04852980747818947,
        0.07341306656599045,
        0.008799983188509941,
        0.04357825964689255,
        0.048666514456272125,
        -0.0561099499464035,
        -0.045236967504024506,
        0.05603272467851639,
        -0.06169311702251434,
        -0.031145382672548294,
        -0.06280359625816345,
        0.028137382119894028,
        0.0172662902623415,
        -0.01947404257953167,
        0.01718628779053688,
        0.011840881779789925,
        -0.04272865504026413,
        -0.01969151757657528,
        -0.055075109004974365,
        0.024028170853853226,
        -0.030298596248030663,
        -0.026127176359295845,
        -0.013616123236715794,
        -0.013988547027111053,
        -0.06793057918548584,
        0.03314318135380745,
        0.009607048705220222,
        -0.0008238591835834086,
        -0.021122870966792107,
        0.014465893618762493,
        -0.05233565717935562,
        0.020780198276042938,
        -0.006260489579290152,
        0.03249061480164528,
        -0.02800569124519825,
        0.04181034490466118,
        0.049630530178546906,
        -0.012236816808581352,
        0.06435514241456985,
        -0.00425716070458293,
        0.019522501155734062,
        0.07067926973104477,
        -0.03433258458971977,
        -0.06499142944812775,
        0.06632047146558762,
        -0.03532741963863373,
        0.025099745020270348,
        0.026914626359939575,
        -0.030832253396511078,
        -0.05984652414917946,
        -0.048419442027807236,
        -0.04709110036492348,
        -0.042432691901922226,
        0.030404068529605865,
        -0.016479920595884323,
        0.07308311760425568,
        0.06355424225330353,
        0.024330763146281242,
        -0.03276941552758217,
        0.07307301461696625,
        0.02181077189743519,
        0.07221497595310211,
        -0.07120522111654282,
        0.04806013032793999,
        0.021221715956926346,
        -0.03706027567386627,
        -0.07238015532493591,
        -0.025702254846692085,
        0.0200254637748003,
        -0.04839000105857849,
        -0.04463571310043335,
        -0.07087963819503784,
        0.02827249839901924,
        -0.06780096888542175,
        -0.06527063250541687,
        0.06899155676364899,
        -0.06590845435857773,
        -0.03322703763842583,
        0.05899864435195923,
        0.037545353174209595,
        0.07152020931243896,
        -0.04416116699576378,
        -0.0058209095150232315,
        0.004988946486264467,
        0.008354540914297104,
        0.04194879159331322,
        0.010434488765895367,
        0.05618780478835106,
        0.013007675297558308,
        0.019984440878033638,
        0.027603473514318466,
        -0.04053940996527672,
        0.06935498118400574,
        -0.06220291182398796,
        -0.0444730706512928,
        0.03494371101260185,
        -0.049669232219457626,
        0.013593432493507862,
        -0.04417242482304573,
        -0.038484424352645874,
        -0.042661771178245544,
        -0.06967012584209442,
        -0.06860671937465668,
        0.05871131643652916,
        0.04735664650797844,
        -0.029331104829907417,
        0.023095984011888504,
        0.046849679201841354,
        0.02657059207558632,
        0.040118586272001266,
        -0.0006630230927839875,
        0.04426033794879913,
        -0.027147814631462097,
        0.0643417090177536,
        0.045684006065130234,
        -0.06728487461805344,
        0.061499789357185364,
        0.06221504881978035,
        -0.047273341566324234,
        0.043794184923172,
        0.05914580449461937,
        0.04116780310869217,
        -0.05545841157436371,
        0.06128757819533348,
        -0.05067076534032822,
        0.05303588882088661,
        -0.06881256401538849,
        -0.002527861390262842,
        -0.02860991656780243,
        -0.07348613440990448,
        -0.0021805590949952602,
        -0.029879027977585793,
        -0.020750893279910088,
        0.03463559225201607,
        -0.040446795523166656,
        -0.052346888929605484,
        0.05054532736539841,
        -0.050251156091690063,
        0.05267539620399475,
        0.004268364515155554,
        -0.05596444010734558,
        0.010951275937259197,
        0.029199758544564247,
        0.007534137926995754,
        0.06198285520076752,
        -0.03860556334257126,
        -0.037027861922979355,
        -0.008556688204407692,
        -0.003886326914653182,
        -0.06472089141607285,
        0.016395987942814827,
        -0.008708144538104534,
        -0.049411576241254807,
        -0.06543253362178802,
        0.06010118126869202,
        -0.005959351547062397,
        -0.0019564940594136715,
        0.06999021768569946,
        -0.03729131072759628,
        0.021625105291604996,
        0.009056048467755318,
        0.07346297800540924,
        -0.07222623378038406,
        -0.010174836963415146,
        0.06739752739667892,
        0.024386117234826088,
        0.004594384226948023,
        0.044394150376319885,
        -0.044529370963573456,
        0.06807760894298553,
        0.014499127864837646,
        0.009338945150375366,
        -0.0421052910387516,
        -0.05051487311720848,
        -0.00874834880232811,
        0.050824277102947235,
        -0.06553921103477478,
        -0.003010456683114171,
        0.04627729952335358,
        -0.0695691853761673,
        0.06700070202350616,
        0.0045324829407036304,
        -0.013285327702760696,
        0.00002406131534371525,
        0.013051937334239483,
        -0.061552420258522034,
        0.06591565161943436,
        -0.016816429793834686,
        0.004922672174870968,
        -0.062153540551662445,
        0.00393866328522563,
        0.013610808178782463,
        0.04003288224339485,
        0.06481894105672836,
        0.01190069317817688,
        0.03766073286533356,
        -0.045914433896541595,
        0.048055343329906464,
        0.04610498994588852,
        0.0492347776889801,
        0.010489287786185741,
        -0.06478368490934372,
        -0.062357645481824875,
        0.05945783853530884,
        0.06690416485071182,
        0.036474138498306274,
        -0.033520475029945374,
        -0.06751078367233276,
        -0.031215431168675423,
        -0.024006403982639313,
        -0.06873349100351334,
        -0.009245689027011395,
        0.006072063930332661,
        -0.0055437539704144,
        -0.061796557158231735,
        0.06766017526388168,
        0.05017895624041557,
        0.054824378341436386,
        0.00674950797110796,
        -0.0513286367058754,
        -0.001516721211373806,
        0.04070819914340973,
        0.015004033222794533,
        -0.03324783593416214,
        -0.004100457299500704,
        0.013212250545620918,
        0.034345775842666626,
        -0.04366337135434151,
        -0.03360433876514435,
        0.003467980306595564,
        -0.04020475596189499,
        -0.06892330944538116,
        0.061789557337760925,
        0.00246492144651711,
        0.06112270429730415,
        -0.06676021218299866,
        -0.030285723507404327,
        0.04325389117002487,
        0.0412214994430542,
        0.05257716774940491,
        0.0076645719818770885,
        -0.004269561264663935,
        0.03564509376883507,
        -0.05445303022861481,
        -0.05432877317070961,
        0.041757870465517044,
        -0.03911914676427841,
        0.019101159647107124,
        0.008733817376196384,
        -0.04895343631505966,
        0.05321468040347099,
        -0.03883424028754234,
        -0.06251996010541916,
        -0.019578270614147186,
        0.053317561745643616,
        -0.04616183042526245,
        -0.06315766274929047,
        0.0246018934994936,
        0.013171365484595299,
        -0.014396975748240948,
        0.06814246624708176,
        0.052141204476356506,
        0.06245055049657822,
        0.04695635661482811,
        0.05806303769350052,
        0.02975200116634369,
        0.0031351617071777582,
        -0.05729279667139053,
        0.0701959952712059,
        0.03204341605305672,
        -0.04028361663222313,
        -0.015077169053256512,
        -0.029946183785796165,
        -0.025177137926220894,
        0.006781560834497213,
        -0.05096360296010971,
        -0.014737303368747234,
        -0.009969575330615044,
        -0.0409172847867012,
        0.011017821729183197,
        -0.041283637285232544,
        0.03171554580330849,
        -0.0711342915892601,
        0.0317324660718441,
        0.05309857800602913,
        0.0718076229095459,
        -0.022815553471446037,
        0.05593734607100487,
        -0.05939733609557152,
        -0.06538182497024536,
        0.005418640095740557,
        0.046491656452417374,
        0.0157310850918293,
        0.03844069689512253,
        -0.05820922926068306,
        -0.04973766580224037,
        0.03387634828686714,
        0.05374389886856079,
        0.01743662729859352,
        -0.0635421872138977,
        -0.05365088954567909,
        -0.037804629653692245,
        -0.05486958101391792,
        -0.0009175505256280303,
        0.035567935556173325,
        0.051315274089574814,
        0.06695915758609772,
        0.011933872476220131,
        -0.0570044107735157,
        -0.0323004350066185,
        -0.059085141867399216,
        0.010549645870923996,
        -0.04679872468113899,
        0.00660583283752203,
        -0.03395679220557213,
        0.0024684863165020943,
        0.06300400197505951,
        0.043851133435964584,
        0.06264067441225052,
        0.07323449105024338,
        -0.06092488393187523,
        -0.03565577417612076,
        0.007793103344738483,
        0.002435507718473673,
        0.0038287288043648005,
        0.00583535386249423,
        0.03385761007666588,
        0.02727912738919258,
        -0.036551427096128464,
        -0.0742730051279068,
        0.02486829273402691,
        0.07284610718488693
    ],
    [
        -0.012703088112175465,
        0.056825872510671616,
        0.0193015206605196,
        0.018680091947317123,
        0.002495616441592574,
        0.007552662864327431,
        0.013900000602006912,
        -0.05127694457769394,
        0.047942277044057846,
        -0.005339960101991892,
        0.050328079611063004,
        0.0027944929897785187,
        -0.05281203240156174,
        -0.010131332091987133,
        -0.06614405661821365,
        0.06940726190805435,
        0.006201958283782005,
        0.055027466267347336,
        -0.055981069803237915,
        -0.007357012014836073,
        -0.029965847730636597,
        0.06648582965135574,
        -0.03057825192809105,
        0.0034037635196000338,
        0.03937182202935219,
        0.018651556223630905,
        0.05786047875881195,
        0.0020230154041200876,
        -0.005612262524664402,
        0.0029435502365231514,
        0.051039356738328934,
        -0.014793072827160358,
        -0.050324272364377975,
        -0.05714946240186691,
        0.06047128513455391,
        0.03120291605591774,
        -0.05926109850406647,
        0.0001085701587726362,
        -0.023266682401299477,
        -0.032942526042461395,
        0.013837622478604317,
        0.024861721321940422,
        0.02684728242456913,
        -0.06760207563638687,
        0.00009170235716737807,
        0.05668046325445175,
        -0.007377589587122202,
        0.01954827643930912,
        -0.03800731524825096,
        0.05725608393549919,
        0.026453988626599312,
        0.06984022259712219,
        0.0006511069368571043,
        0.026607565581798553,
        0.04724448546767235,
        -0.01817139983177185,
        0.003199795028194785,
        -0.01309926901012659,
        0.0002343643136555329,
        0.02607830800116062,
        -0.04340358451008797,
        0.04584108665585518,
        -0.052100200206041336,
        0.06118175387382507,
        -0.06567493826150894,
        -0.03914085403084755,
        0.020960213616490364,
        -0.02557375282049179,
        -0.0678187757730484,
        -0.016307266429066658,
        -0.06584154814481735,
        -0.0739799216389656,
        0.0711192712187767,
        0.0031133026350289583,
        -0.0012384036090224981,
        0.010390841402113438,
        -0.01437213271856308,
        0.07399173080921173,
        -0.01803758554160595,
        -0.036453727632761,
        0.012846441008150578,
        0.07247406989336014,
        -0.03177403658628464,
        0.020056283101439476,
        0.03861025720834732,
        0.04799409210681915,
        0.0721033439040184,
        -0.04658559709787369,
        0.04792439937591553,
        0.05279119312763214,
        0.0670316219329834,
        0.04883025959134102,
        -0.07028768211603165,
        0.0188912283629179,
        -0.0010843995260074735,
        0.010634424164891243,
        0.01244852039963007,
        -0.0682116448879242,
        0.04321349039673805,
        0.01475320290774107,
        -0.05910574272274971,
        -0.013210300356149673,
        0.06854482740163803,
        0.05097464844584465,
        0.03959304094314575,
        -0.06405923515558243,
        -0.059328172355890274,
        0.03169354051351547,
        -0.046199552714824677,
        0.01422587875276804,
        -0.06164862588047981,
        -0.0315646231174469,
        -0.06290191411972046,
        -0.035275813192129135,
        -0.015130262821912766,
        -0.007972298189997673,
        0.05132770538330078,
        0.0038251164369285107,
        0.07052095234394073,
        0.017711063846945763,
        -0.05688435956835747,
        0.05988537147641182,
        0.018285799771547318,
        -0.057002563029527664,
        -0.07166819274425507,
        -0.059902388602495193,
        -0.055169928818941116,
        -0.027673963457345963,
        0.05917663872241974,
        0.021805427968502045,
        -0.010012537240982056,
        -0.06205075606703758,
        -0.05705909803509712,
        0.024298319593071938,
        -0.04490277171134949,
        -0.0659537985920906,
        -0.004074279218912125,
        0.003774174489080906,
        -0.0648985207080841,
        0.06523238867521286,
        0.0715465173125267,
        0.0281401127576828,
        0.06230417266488075,
        0.04866747558116913,
        0.06186505779623985,
        -0.03096841275691986,
        0.06899408996105194,
        -0.06533761322498322,
        0.03352091461420059,
        0.06865531206130981,
        0.03561918064951897,
        -0.07136812061071396,
        -0.041998203843832016,
        -0.06750351935625076,
        0.04206056892871857,
        -0.00258076679892838,
        0.01960739493370056,
        0.02953585423529148,
        -0.026497632265090942,
        0.0195623692125082,
        0.060151148587465286,
        0.06589160859584808,
        0.0611150860786438,
        -0.007807211019098759,
        0.062260016798973083,
        -0.010562348179519176,
        -0.030679725110530853,
        0.017550796270370483,
        0.029113298282027245,
        0.027366474270820618,
        0.041548144072294235,
        0.024374675005674362,
        -0.03227430582046509,
        -0.05463689938187599,
        0.019087674096226692,
        0.029247483238577843,
        -0.06655071675777435,
        -0.04294919595122337,
        0.056239884346723557,
        -0.06431302428245544,
        0.04852980747818947,
        0.07341306656599045,
        0.008799983188509941,
        0.04357825964689255,
        0.048666514456272125,
        -0.0561099499464035,
        -0.045236967504024506,
        0.05603272467851639,
        -0.06169311702251434,
        -0.031145382672548294,
        -0.06280359625816345,
        0.028137382119894028,
        0.0172662902623415,
        -0.01947404257953167,
        0.01718628779053688,
        0.011840881779789925,
        -0.04272865504026413,
        -0.01969151757657528,
        -0.055075109004974365,
        0.024028170853853226,
        -0.030298596248030663,
        -0.026127176359295845,
        -0.013616123236715794,
        -0.013988547027111053,
        -0.06793057918548584,
        0.03314318135380745,
        0.009607048705220222,
        -0.0008238591835834086,
        -0.021122870966792107,
        0.014465893618762493,
        -0.05233565717935562,
        0.020780198276042938,
        -0.006260489579290152,
        0.03249061480164528,
        -0.02800569124519825,
        0.04181034490466118,
        0.049630530178546906,
        -0.012236816808581352,
        0.06435514241456985,
        -0.00425716070458293,
        0.019522501155734062,
        0.07067926973104477,
        -0.03433258458971977,
        -0.06499142944812775,
        0.06632047146558762,
        -0.03532741963863373,
        0.025099745020270348,
        0.026914626359939575,
        -0.030832253396511078,
        -0.05984652414917946,
        -0.048419442027807236,
        -0.04709110036492348,
        -0.042432691901922226,
        0.030404068529605865,
        -0.016479920595884323,
        0.07308311760425568,
        0.06355424225330353,
        0.024330763146281242,
        -0.03276941552758217,
        0.07307301461696625,
        0.02181077189743519,
        0.07221497595310211,
        -0.07120522111654282,
        0.04806013032793999,
        0.021221715956926346,
        -0.03706027567386627,
        -0.07238015532493591,
        -0.025702254846692085,
        0.0200254637748003,
        -0.04839000105857849,
        -0.04463571310043335,
        -0.07087963819503784,
        0.02827249839901924,
        -0.06780096888542175,
        -0.06527063250541687,
        0.06899155676364899,
        -0.06590845435857773,
        -0.03322703763842583,
        0.05899864435195923,
        0.037545353174209595,
        0.07152020931243896,
        -0.04416116699576378,
        -0.0058209095150232315,
        0.004988946486264467,
        0.008354540914297104,
        0.04194879159331322,
        0.010434488765895367,
        0.05618780478835106,
        0.013007675297558308,
        0.019984440878033638,
        0.027603473514318466,
        -0.04053940996527672,
        0.06935498118400574,
        -0.06220291182398796,
        -0.0444730706512928,
        0.03494371101260185,
        -0.049669232219457626,
        0.013593432493507862,
        -0.04417242482304573,
        -0.038484424352645874,
        -0.042661771178245544,
        -0.06967012584209442,
        -0.06860671937465668,
        0.05871131643652916,
        0.04735664650797844,
        -0.029331104829907417,
        0.023095984011888504,
        0.046849679201841354,
        0.02657059207558632,
        0.040118586272001266,
        -0.0006630230927839875,
        0.04426033794879913,
        -0.027147814631462097,
        0.0643417090177536,
        0.045684006065130234,
        -0.06728487461805344,
        0.061499789357185364,
        0.06221504881978035,
        -0.047273341566324234,
        0.043794184923172,
        0.05914580449461937,
        0.04116780310869217,
        -0.05545841157436371,
        0.06128757819533348,
        -0.05067076534032822,
        0.05303588882088661,
        -0.06881256401538849,
        -0.002527861390262842,
        -0.02860991656780243,
        -0.07348613440990448,
        -0.0021805590949952602,
        -0.029879027977585793,
        -0.020750893279910088,
        0.03463559225201607,
        -0.040446795523166656,
        -0.052346888929605484,
        0.05054532736539841,
        -0.050251156091690063,
        0.05267539620399475,
        0.004268364515155554,
        -0.05596444010734558,
        0.010951275937259197,
        0.029199758544564247,
        0.007534137926995754,
        0.06198285520076752,
        -0.03860556334257126,
        -0.037027861922979355,
        -0.008556688204407692,
        -0.003886326914653182,
        -0.06472089141607285,
        0.016395987942814827,
        -0.008708144538104534,
        -0.049411576241254807,
        -0.06543253362178802,
        0.06010118126869202,
        -0.005959351547062397,
        -0.0019564940594136715,
        0.06999021768569946,
        -0.03729131072759628,
        0.021625105291604996,
        0.009056048467755318,
        0.07346297800540924,
        -0.07222623378038406,
        -0.010174836963415146,
        0.06739752739667892,
        0.024386117234826088,
        0.004594384226948023,
        0.044394150376319885,
        -0.044529370963573456,
        0.06807760894298553,
        0.014499127864837646,
        0.009338945150375366,
        -0.0421052910387516,
        -0.05051487311720848,
        -0.00874834880232811,
        0.050824277102947235,
        -0.06553921103477478,
        -0.003010456683114171,
        0.04627729952335358,
        -0.0695691853761673,
        0.06700070202350616,
        0.0045324829407036304,
        -0.013285327702760696,
        0.00002406131534371525,
        0.013051937334239483,
        -0.061552420258522034,
        0.06591565161943436,
        -0.016816429793834686,
        0.004922672174870968,
        -0.062153540551662445,
        0.00393866328522563,
        0.013610808178782463,
        0.04003288224339485,
        0.06481894105672836,
        0.01190069317817688,
        0.03766073286533356,
        -0.045914433896541595,
        0.048055343329906464,
        0.04610498994588852,
        0.0492347776889801,
        0.010489287786185741,
        -0.06478368490934372,
        -0.062357645481824875,
        0.05945783853530884,
        0.06690416485071182,
        0.036474138498306274,
        -0.033520475029945374,
        -0.06751078367233276,
        -0.031215431168675423,
        -0.024006403982639313,
        -0.06873349100351334,
        -0.009245689027011395,
        0.006072063930332661,
        -0.0055437539704144,
        -0.061796557158231735,
        0.06766017526388168,
        0.05017895624041557,
        0.054824378341436386,
        0.00674950797110796,
        -0.0513286367058754,
        -0.001516721211373806,
        0.04070819914340973,
        0.015004033222794533,
        -0.03324783593416214,
        -0.004100457299500704,
        0.013212250545620918,
        0.034345775842666626,
        -0.04366337135434151,
        -0.03360433876514435,
        0.003467980306595564,
        -0.04020475596189499,
        -0.06892330944538116,
        0.061789557337760925,
        0.00246492144651711,
        0.06112270429730415,
        -0.06676021218299866,
        -0.030285723507404327,
        0.04325389117002487,
        0.0412214994430542,
        0.05257716774940491,
        0.0076645719818770885,
        -0.004269561264663935,
        0.03564509376883507,
        -0.05445303022861481,
        -0.05432877317070961,
        0.041757870465517044,
        -0.03911914676427841,
        0.019101159647107124,
        0.008733817376196384,
        -0.04895343631505966,
        0.05321468040347099,
        -0.03883424028754234,
        -0.06251996010541916,
        -0.019578270614147186,
        0.053317561745643616,
        -0.04616183042526245,
        -0.06315766274929047,
        0.0246018934994936,
        0.013171365484595299,
        -0.014396975748240948,
        0.06814246624708176,
        0.052141204476356506,
        0.06245055049657822,
        0.04695635661482811,
        0.05806303769350052,
        0.02975200116634369,
        0.0031351617071777582,
        -0.05729279667139053,
        0.0701959952712059,
        0.03204341605305672,
        -0.04028361663222313,
        -0.015077169053256512,
        -0.029946183785796165,
        -0.025177137926220894,
        0.006781560834497213,
        -0.05096360296010971,
        -0.014737303368747234,
        -0.009969575330615044,
        -0.0409172847867012,
        0.011017821729183197,
        -0.041283637285232544,
        0.03171554580330849,
        -0.0711342915892601,
        0.0317324660718441,
        0.05309857800602913,
        0.0718076229095459,
        -0.022815553471446037,
        0.05593734607100487,
        -0.05939733609557152,
        -0.06538182497024536,
        0.005418640095740557,
        0.046491656452417374,
        0.0157310850918293,
        0.03844069689512253,
        -0.05820922926068306,
        -0.04973766580224037,
        0.03387634828686714,
        0.05374389886856079,
        0.01743662729859352,
        -0.0635421872138977,
        -0.05365088954567909,
        -0.037804629653692245,
        -0.05486958101391792,
        -0.0009175505256280303,
        0.035567935556173325,
        0.051315274089574814,
        0.06695915758609772,
        0.011933872476220131,
        -0.0570044107735157,
        -0.0323004350066185,
        -0.059085141867399216,
        0.010549645870923996,
        -0.04679872468113899,
        0.00660583283752203,
        -0.03395679220557213,
        0.0024684863165020943,
        0.06300400197505951,
        0.043851133435964584,
        0.06264067441225052,
        0.07323449105024338,
        -0.06092488393187523,
        -0.03565577417612076,
        0.007793103344738483,
        0.002435507718473673,
        0.0038287288043648005,
        0.00583535386249423,
        0.03385761007666588,
        0.02727912738919258,
        -0.036551427096128464,
        -0.0742730051279068,
        0.02486829273402691,
        0.07284610718488693
    ],
    [
        -0.029392989352345467,
        0.03743039816617966,
        0.012493104673922062,
        0.020685192197561264,
        0.010672149248421192,
        -0.010583383962512016,
        0.026248492300510406,
        -0.0481850691139698,
        0.05469600856304169,
        0.00921716634184122,
        0.05310254544019699,
        0.011284161359071732,
        -0.051198240369558334,
        -0.004002436529844999,
        -0.0634702518582344,
        0.06602711230516434,
        0.01895507611334324,
        0.05502691492438316,
        -0.057255715131759644,
        -0.03947504982352257,
        -0.004434412345290184,
        0.06608955562114716,
        -0.010243397206068039,
        -0.010383980348706245,
        0.04012014716863632,
        0.02309945970773697,
        0.06098320707678795,
        -0.00116365822032094,
        0.003798635210841894,
        0.030315378680825233,
        0.0405874103307724,
        -0.0038625947199761868,
        -0.0565519817173481,
        -0.0632760226726532,
        0.060856547206640244,
        0.02445320226252079,
        -0.05887163430452347,
        -0.0032261782325804234,
        -0.01619543880224228,
        -0.03817872703075409,
        0.035151224583387375,
        0.02236596681177616,
        0.02694515325129032,
        -0.05913722887635231,
        -0.008153095841407776,
        0.05605058744549751,
        0.007299194112420082,
        0.017661917954683304,
        -0.012352711521089077,
        0.056787267327308655,
        0.04483656957745552,
        0.06675156950950623,
        -0.006308000534772873,
        0.02705845981836319,
        0.05473313108086586,
        -0.03824325278401375,
        -0.006678570061922073,
        -0.02455510012805462,
        0.025639912113547325,
        0.015574014745652676,
        -0.04694054648280144,
        0.040535036474466324,
        -0.05022558942437172,
        0.06321051716804504,
        -0.06161421909928322,
        -0.033719807863235474,
        0.008042687550187111,
        -0.03579927980899811,
        -0.06434018909931183,
        0.0007345593185164034,
        -0.06455688178539276,
        -0.06970756500959396,
        0.06557701528072357,
        -0.000993349589407444,
        0.03174389526247978,
        0.016204578801989555,
        0.0016849592793732882,
        0.06962208449840546,
        -0.010290482081472874,
        -0.03883109986782074,
        0.01982206664979458,
        0.0686071440577507,
        -0.024372395128011703,
        0.043094053864479065,
        0.046210601925849915,
        0.04709145426750183,
        0.06847760081291199,
        -0.03607243672013283,
        0.05290527641773224,
        0.05420619621872902,
        0.05177917703986168,
        0.04051727429032326,
        -0.06916190683841705,
        0.009398289956152439,
        0.018497588112950325,
        0.00661014998331666,
        0.022008731961250305,
        -0.06621284782886505,
        0.040727514773607254,
        0.009472168050706387,
        -0.05212267115712166,
        0.013945258222520351,
        0.06262495368719101,
        0.05237282067537308,
        0.03902409225702286,
        -0.06555085629224777,
        -0.05089087411761284,
        0.039392754435539246,
        -0.048448700457811356,
        0.03457510098814964,
        -0.05956511199474335,
        -0.04432075470685959,
        -0.06104103475809097,
        -0.024837767705321312,
        -0.03882564976811409,
        -0.013367710635066032,
        0.0558781772851944,
        -0.019194234162569046,
        0.0678955465555191,
        0.031053975224494934,
        -0.05227523297071457,
        0.060772381722927094,
        0.02691652812063694,
        -0.05257066339254379,
        -0.06621915847063065,
        -0.04561251774430275,
        -0.05913252383470535,
        -0.049959998577833176,
        0.052371688187122345,
        0.03709561750292778,
        -0.009909510612487793,
        -0.054152023047208786,
        -0.047017648816108704,
        0.02621237374842167,
        -0.03694603592157364,
        -0.06279291212558746,
        0.019096877425909042,
        0.01818397268652916,
        -0.05987773463129997,
        0.06522199511528015,
        0.06754230707883835,
        0.01838354580104351,
        0.057831455022096634,
        0.039567332714796066,
        0.05445842817425728,
        -0.044360775500535965,
        0.06617829948663712,
        -0.06072921305894852,
        0.04315512627363205,
        0.06577885150909424,
        0.05151033028960228,
        -0.06767065823078156,
        -0.0377083420753479,
        -0.06384322047233582,
        0.046859681606292725,
        0.005496860481798649,
        0.01567263901233673,
        0.03593676909804344,
        -0.028674311935901642,
        0.03828873857855797,
        0.06410747766494751,
        0.059301622211933136,
        0.05509958043694496,
        -0.026013929396867752,
        0.05296705290675163,
        -0.003815385280176997,
        -0.029084432870149612,
        0.030049823224544525,
        0.025677308440208435,
        0.027569711208343506,
        0.031353745609521866,
        0.02275286801159382,
        -0.036277949810028076,
        -0.059053901582956314,
        0.017835870385169983,
        0.032723527401685715,
        -0.06443841755390167,
        -0.044811394065618515,
        0.04562271758913994,
        -0.06450451910495758,
        0.04715333133935928,
        0.06987857818603516,
        -0.010318649932742119,
        0.054833587259054184,
        0.048578087240457535,
        -0.05617680773139,
        -0.052450668066740036,
        0.051156334578990936,
        -0.05681850016117096,
        -0.04951966181397438,
        -0.05987364053726196,
        0.037561628967523575,
        -0.00017230746743734926,
        -0.006341173313558102,
        0.0038437412586063147,
        -0.013471970334649086,
        -0.054961517453193665,
        -0.040195707231760025,
        -0.06129494309425354,
        0.02721436694264412,
        -0.017361091449856758,
        -0.03857601433992386,
        -0.017404837533831596,
        -0.02352914959192276,
        -0.06299840658903122,
        0.05392657592892647,
        0.011341260746121407,
        0.016224758699536324,
        -0.017653975635766983,
        0.036349959671497345,
        -0.05207546055316925,
        0.015906931832432747,
        -0.006683083716779947,
        0.022192388772964478,
        -0.044800471514463425,
        0.04158104211091995,
        0.048048485070466995,
        -0.026911504566669464,
        0.0643921047449112,
        -0.013449789956212044,
        0.0034818239510059357,
        0.06783290952444077,
        -0.03479344770312309,
        -0.05895667523145676,
        0.06710854917764664,
        -0.041775282472372055,
        0.010400685481727123,
        0.0014586736215278506,
        -0.026733538135886192,
        -0.06080755591392517,
        -0.039586883038282394,
        -0.05911189690232277,
        -0.033692166209220886,
        0.030912203714251518,
        -0.04121381416916847,
        0.06894908845424652,
        0.06155192479491234,
        0.03561055660247803,
        -0.03246629238128662,
        0.06880661845207214,
        0.012361849658191204,
        0.06894685328006744,
        -0.06897957623004913,
        0.04986526072025299,
        0.024673355743288994,
        -0.04433644935488701,
        -0.06536925584077835,
        0.010901334695518017,
        0.0077872928231954575,
        -0.05098016560077667,
        -0.04754826799035072,
        -0.06653331220149994,
        0.024333007633686066,
        -0.0651627853512764,
        -0.06225286424160004,
        0.06307703256607056,
        -0.06355787068605423,
        -0.04074854031205177,
        0.05634629726409912,
        0.046592820435762405,
        0.06757564842700958,
        -0.040244363248348236,
        0.005093269050121307,
        0.02126440964639187,
        -0.0009970644023269415,
        0.04222474247217178,
        0.03956259414553642,
        0.056627918034791946,
        0.029164869338274002,
        0.023845504969358444,
        0.015963228419423103,
        -0.05666615068912506,
        0.06845437735319138,
        -0.06202421337366104,
        -0.037266649305820465,
        0.01765989512205124,
        -0.04716559872031212,
        0.010017553344368935,
        -0.04540976136922836,
        -0.04020608961582184,
        -0.06461338698863983,
        -0.06318696588277817,
        -0.06744279712438583,
        0.05323139950633049,
        0.06138455867767334,
        -0.040105871856212616,
        0.022085517644882202,
        0.0468110591173172,
        0.041828837245702744,
        0.03351268917322159,
        -0.020392928272485733,
        0.035847920924425125,
        -0.028319483622908592,
        0.058911290019750595,
        0.05362895503640175,
        -0.06300195306539536,
        0.05617478862404823,
        0.06630955636501312,
        -0.030074603855609894,
        0.03263698145747185,
        0.0613924004137516,
        0.044872019439935684,
        -0.047256018966436386,
        0.0628165677189827,
        -0.04069199785590172,
        0.054381001740694046,
        -0.06407328695058823,
        -0.000950342626310885,
        -0.030337320640683174,
        -0.06924887001514435,
        0.00047963703400455415,
        -0.04048537462949753,
        -0.02043275535106659,
        0.04355315491557121,
        -0.026247894391417503,
        -0.05163345858454704,
        0.045359980314970016,
        -0.05418306216597557,
        0.048718713223934174,
        0.01207052543759346,
        -0.04641955718398094,
        -0.0027776297647506,
        0.024444343522191048,
        0.022589070722460747,
        0.061612509191036224,
        -0.04476149007678032,
        -0.03752922639250755,
        0.013578522950410843,
        0.004006960894912481,
        -0.06384700536727905,
        0.034515462815761566,
        0.008531296625733376,
        -0.048378586769104004,
        -0.05991242825984955,
        0.05175885558128357,
        0.010335579514503479,
        -0.031699035316705704,
        0.06538103520870209,
        -0.03362840786576271,
        0.018266653642058372,
        0.01842229813337326,
        0.06899925321340561,
        -0.06717526167631149,
        -0.027008894830942154,
        0.06189531087875366,
        0.014749274589121342,
        0.01338280364871025,
        0.05010372772812843,
        -0.04855744168162346,
        0.05970027670264244,
        0.02245212160050869,
        0.01900322362780571,
        -0.05120322108268738,
        -0.061435673385858536,
        -0.010362592525780201,
        0.05425919592380524,
        -0.0653611421585083,
        -0.009893608279526234,
        0.04947144165635109,
        -0.06737824529409409,
        0.06359105557203293,
        0.013166440650820732,
        0.018669836223125458,
        0.007456321734935045,
        -0.015817852690815926,
        -0.06096382811665535,
        0.0675833523273468,
        -0.0143279442563653,
        0.033373285084962845,
        -0.06312210857868195,
        -0.02663753181695938,
        0.016511110588908195,
        0.05528898909687996,
        0.06257231533527374,
        0.023585526272654533,
        0.03446384146809578,
        -0.050459302961826324,
        0.053101446479558945,
        0.0559690035879612,
        0.0547998882830143,
        0.000652136979624629,
        -0.06381681561470032,
        -0.05243375897407532,
        0.04959692060947418,
        0.05827591195702553,
        0.03538402169942856,
        -0.008427491411566734,
        -0.05799358710646629,
        -0.03100576624274254,
        -0.009490556083619595,
        -0.06833542883396149,
        -0.0033679043408483267,
        0.008287498727440834,
        -0.01205042190849781,
        -0.05112670734524727,
        0.06501349806785583,
        0.05363564193248749,
        0.0394345261156559,
        0.005201440770179033,
        -0.05391647666692734,
        -0.009655244648456573,
        0.044050347059965134,
        0.01941138692200184,
        -0.04161350429058075,
        -0.02471965178847313,
        0.004931974224746227,
        0.04026971012353897,
        -0.044633649289608,
        -0.025528263300657272,
        -0.014489821158349514,
        -0.021446997299790382,
        -0.06204412505030632,
        0.06178940460085869,
        0.020312828943133354,
        0.05616193264722824,
        -0.0648229792714119,
        -0.036545202136039734,
        0.03449290990829468,
        0.0415562242269516,
        0.053730376064777374,
        0.04262859746813774,
        0.010542675852775574,
        0.04267570376396179,
        -0.05679779872298241,
        -0.026770057156682014,
        0.009998833760619164,
        -0.05168287083506584,
        0.038836024701595306,
        -0.004758319351822138,
        -0.0503070205450058,
        0.0609046146273613,
        -0.046682897955179214,
        -0.06373442709445953,
        -0.0362081341445446,
        0.03841991722583771,
        -0.05001994967460632,
        -0.051940497010946274,
        0.004676588345319033,
        0.02896101400256157,
        -0.0049758180975914,
        0.06543724983930588,
        0.05828207731246948,
        0.06161557883024216,
        0.052069179713726044,
        0.05806407332420349,
        0.020724525675177574,
        0.0033094792161136866,
        -0.06453263014554977,
        0.06615079194307327,
        0.035661570727825165,
        -0.04466693848371506,
        -0.010206867009401321,
        -0.006419840734452009,
        -0.020604334771633148,
        0.0076165152713656425,
        -0.05285539850592613,
        -0.03646363690495491,
        0.0012269089929759502,
        -0.047755949199199677,
        0.032158177345991135,
        -0.044774435460567474,
        0.02866770327091217,
        -0.06831017136573792,
        0.04689289629459381,
        0.058587122708559036,
        0.06851140409708023,
        -0.030312765389680862,
        0.056718721985816956,
        -0.06353417038917542,
        -0.06245262175798416,
        0.019005654379725456,
        0.0645686686038971,
        0.014493430033326149,
        0.04881004989147186,
        -0.06458691507577896,
        -0.038098424673080444,
        0.01661320962011814,
        0.057930536568164825,
        0.00413675419986248,
        -0.05972645804286003,
        -0.056474361568689346,
        -0.04664995148777962,
        -0.05910228192806244,
        -0.004742112476378679,
        0.03497892618179321,
        0.05737427622079849,
        0.06397901475429535,
        0.00038332794792950153,
        -0.048929255455732346,
        -0.04847067967057228,
        -0.05260114744305611,
        -0.0038062497042119503,
        -0.04347320646047592,
        0.0036712046712636948,
        -0.010642861016094685,
        0.0032329324167221785,
        0.060032058507204056,
        0.05347723141312599,
        0.058145858347415924,
        0.06950361281633377,
        -0.06176156923174858,
        -0.029726780951023102,
        0.009532305411994457,
        -0.026151645928621292,
        0.025324111804366112,
        -0.007553155533969402,
        0.05177382007241249,
        0.011856949888169765,
        -0.01724085584282875,
        -0.06985120475292206,
        0.014856402762234211,
        0.06857173889875412
    ],
    [
        0.03457646444439888,
        -0.006934804376214743,
        0.030196035280823708,
        0.006643723696470261,
        0.03433060646057129,
        -0.04223663732409477,
        0.06540454924106598,
        -0.03517197072505951,
        0.0034120704513043165,
        -0.04884038120508194,
        0.0570075549185276,
        -0.021578166633844376,
        -0.059116430580616,
        -0.015518860891461372,
        -0.06387840211391449,
        0.03896765038371086,
        -0.006012743338942528,
        0.03728898987174034,
        -0.05046761408448219,
        0.06439868360757828,
        -0.01980418898165226,
        0.0361289419233799,
        0.03128965198993683,
        -0.012555521912872791,
        0.06048709154129028,
        0.040792547166347504,
        0.006816304288804531,
        0.06357081234455109,
        -0.05898021534085274,
        -0.05422291159629822,
        0.04033909738063812,
        -0.028524234890937805,
        -0.003980238921940327,
        -0.05719589814543724,
        0.056006722152233124,
        -0.038868315517902374,
        -0.05307437852025032,
        0.02940831519663334,
        0.04790549725294113,
        -0.03633110597729683,
        0.00975553598254919,
        0.012853650376200676,
        0.025519153103232384,
        -0.06179879978299141,
        0.033392030745744705,
        0.01425001211464405,
        -0.012233209796249866,
        0.06205076351761818,
        -0.044910307973623276,
        -0.030359655618667603,
        0.056564491242170334,
        0.03582930564880371,
        0.06003488227725029,
        -0.029527725651860237,
        -0.016406504437327385,
        0.03277824819087982,
        -0.03763626143336296,
        -0.04049628600478172,
        -0.05959014222025871,
        0.04796794429421425,
        -0.0202025156468153,
        0.04793470725417137,
        -0.052702438086271286,
        -0.007851483300328255,
        0.0207834355533123,
        -0.04650348424911499,
        -0.01314990408718586,
        -0.062417592853307724,
        0.05703539028763771,
        -0.06583187729120255,
        -0.054330069571733475,
        -0.0645017996430397,
        0.0663084015250206,
        -0.010660015977919102,
        -0.017829127609729767,
        0.0007317686104215682,
        0.035633593797683716,
        0.0666765347123146,
        0.04648589715361595,
        -0.044594768434762955,
        0.020206332206726074,
        -0.02787269465625286,
        -0.05326823517680168,
        0.023534949868917465,
        -0.059429191052913666,
        0.03874216228723526,
        0.05604448914527893,
        -0.050682492554187775,
        -0.057857949286699295,
        0.06294922530651093,
        -0.0036737036425620317,
        -0.04685891047120094,
        0.008780236355960369,
        0.03758934512734413,
        0.05442136526107788,
        -0.0562652051448822,
        0.04255431517958641,
        -0.0642167329788208,
        -0.05081988871097565,
        0.008601708337664604,
        -0.0533815436065197,
        -0.036308128386735916,
        0.06509067118167877,
        0.06118634343147278,
        -0.03444688022136688,
        -0.06065831705927849,
        -0.06636891514062881,
        0.016277609393000603,
        -0.06174125149846077,
        -0.006782623939216137,
        -0.03324860334396362,
        -0.05466976389288902,
        -0.03726720064878464,
        -0.06377051025629044,
        0.06531451642513275,
        0.04046875610947609,
        -0.02988380566239357,
        0.054503485560417175,
        0.020860088989138603,
        -0.06556239724159241,
        -0.06425142288208008,
        -0.03994050621986389,
        -0.031372684985399246,
        -0.06544139236211777,
        -0.0654762014746666,
        -0.0302517581731081,
        -0.0066109937615692616,
        0.06316430866718292,
        0.05733602121472359,
        0.024930350482463837,
        -0.04746527224779129,
        -0.059833627194166183,
        -0.020800935104489326,
        0.06281223148107529,
        -0.03719499334692955,
        -0.05937660112977028,
        0.03816879540681839,
        -0.06064259633421898,
        0.04505039006471634,
        0.06430626660585403,
        0.044986482709646225,
        -0.026244156062602997,
        0.03873452544212341,
        0.06451711058616638,
        -0.030268225818872452,
        -0.0220908485352993,
        0.06563079357147217,
        -0.05433902144432068,
        0.01524259801954031,
        0.008613897487521172,
        -0.06048835813999176,
        -0.06556059420108795,
        -0.06487487256526947,
        -0.02694050222635269,
        0.04182133823633194,
        0.019401822239160538,
        0.020297829061746597,
        0.03398388624191284,
        0.0018347690347582102,
        -0.03990922123193741,
        0.060230109840631485,
        0.051237497478723526,
        0.05724450945854187,
        -0.02561715617775917,
        0.03315499424934387,
        0.02034052088856697,
        -0.021953903138637543,
        -0.044702865183353424,
        -0.04861340671777725,
        0.06091926991939545,
        0.020760340616106987,
        0.024964839220046997,
        -0.06069887802004814,
        0.036360178142786026,
        -0.03644951060414314,
        -0.04191746190190315,
        -0.019257545471191406,
        -0.010875877924263477,
        0.06387879699468613,
        -0.03352602943778038,
        0.044888634234666824,
        0.05425851047039032,
        0.056355930864810944,
        -0.03660324588418007,
        0.06211446225643158,
        -0.06204047054052353,
        0.047776445746421814,
        0.04031006991863251,
        -0.060064513236284256,
        -0.05912213772535324,
        -0.06603996455669403,
        0.008319907821714878,
        0.004153009038418531,
        0.033646684139966965,
        -0.00042957908590324223,
        0.0526372492313385,
        0.0582849346101284,
        0.0624660849571228,
        0.014288668520748615,
        0.04962664842605591,
        -0.03584776818752289,
        0.02661946229636669,
        -0.025925559923052788,
        0.011947065591812134,
        -0.058412469923496246,
        -0.00847453810274601,
        0.05805829167366028,
        0.025820938870310783,
        0.023749617859721184,
        0.0020384476520121098,
        -0.05231272056698799,
        -0.052825894206762314,
        -0.015417259186506271,
        0.03893342614173889,
        0.023025667294859886,
        0.015292062424123287,
        0.05504614859819412,
        0.033623769879341125,
        0.06650875508785248,
        0.022750649601221085,
        0.05514770373702049,
        0.014281733892858028,
        0.052119333297014236,
        -0.06685959547758102,
        0.066022589802742,
        -0.06324976682662964,
        0.029202628880739212,
        -0.025349438190460205,
        -0.05134429410099983,
        -0.06625065952539444,
        -0.06678298115730286,
        -0.00174801517277956,
        -0.05014137923717499,
        0.03553212061524391,
        -0.046596914529800415,
        0.044363558292388916,
        0.05097757652401924,
        0.02729194611310959,
        -0.020331038162112236,
        0.066133052110672,
        0.04172198474407196,
        0.046804316341876984,
        0.0262313112616539,
        -0.0321061834692955,
        0.017920861020684242,
        -0.031666018068790436,
        -0.004387553781270981,
        -0.042609941214323044,
        -0.0009517463622614741,
        -0.002627966459840536,
        -0.01664860174059868,
        -0.06619253754615784,
        0.010048008523881435,
        -0.06402173638343811,
        -0.0360780730843544,
        0.056932106614112854,
        0.03373219817876816,
        0.03267771378159523,
        0.0640670657157898,
        0.061310507357120514,
        -0.023746604099869728,
        -0.032280053943395615,
        0.03386992588639259,
        0.008319533430039883,
        -0.03872617706656456,
        0.06176033616065979,
        0.01885695941746235,
        0.034620482474565506,
        -0.015597909688949585,
        -0.05732519552111626,
        -0.03704936057329178,
        -0.050533492118120193,
        0.04726171866059303,
        -0.061943672597408295,
        -0.022396251559257507,
        0.01853334717452526,
        -0.056877605617046356,
        -0.005551900714635849,
        -0.04433092847466469,
        0.006522896699607372,
        -0.015050106681883335,
        -0.05431268364191055,
        -0.05121932551264763,
        0.06035095453262329,
        0.04403279721736908,
        0.03216436505317688,
        0.03280607983469963,
        -0.03317315876483917,
        -0.0005894684582017362,
        0.01106221042573452,
        -0.05505477264523506,
        0.03464091569185257,
        -0.03823530673980713,
        0.06003185734152794,
        -0.01455342024564743,
        -0.0653085708618164,
        0.06647279858589172,
        0.030514352023601532,
        -0.06283528357744217,
        0.05588685721158981,
        -0.03161541372537613,
        0.02768322266638279,
        -0.06414742767810822,
        0.06537886708974838,
        -0.015610067173838615,
        0.05720717832446098,
        -0.06492096930742264,
        0.040883149951696396,
        0.004148803185671568,
        -0.0668550431728363,
        -0.0032823358196765184,
        -0.04009019583463669,
        0.030882615596055984,
        0.0651078149676323,
        -0.06526707857847214,
        -0.05536472797393799,
        0.0605926550924778,
        -0.036197926849126816,
        0.06515876203775406,
        0.04192972555756569,
        -0.010127807036042213,
        0.026982923969626427,
        0.06388735771179199,
        -0.05724430829286575,
        0.0420064851641655,
        0.05834907665848732,
        0.0003512797993607819,
        -0.06425114721059799,
        -0.01261467207223177,
        -0.06629098206758499,
        -0.004508997779339552,
        0.020176667720079422,
        -0.03601401671767235,
        -0.05001026764512062,
        0.06286709755659103,
        0.02528589963912964,
        0.017550962045788765,
        0.06643351167440414,
        -0.042839616537094116,
        0.05026489868760109,
        0.021477341651916504,
        0.06044810637831688,
        -0.0340571328997612,
        -0.053743649274110794,
        0.06705425679683685,
        0.04314092919230461,
        -0.056425824761390686,
        0.05991068854928017,
        -0.038127150386571884,
        0.06473420560359955,
        -0.043165359646081924,
        0.021705130115151405,
        0.0454309806227684,
        -0.06600935757160187,
        -0.047881949692964554,
        0.057667020708322525,
        -0.05047363415360451,
        0.03787299618124962,
        -0.04398978501558304,
        0.0015251507284119725,
        0.06418453902006149,
        0.042475491762161255,
        -0.05381625145673752,
        -0.017921674996614456,
        -0.02655908092856407,
        -0.05734449252486229,
        0.0658956915140152,
        -0.009689746424555779,
        0.004890606738626957,
        -0.05882484093308449,
        0.017295565456151962,
        -0.007590189576148987,
        0.06504926830530167,
        0.0524129644036293,
        0.03171131759881973,
        0.06031448394060135,
        0.004387257155030966,
        -0.03506620600819588,
        0.053007885813713074,
        0.003934602718800306,
        -0.04956497997045517,
        0.03276539593935013,
        -0.028735516592860222,
        0.02093362994492054,
        0.06303871423006058,
        -0.0001394696009811014,
        -0.009989237412810326,
        -0.061108823865652084,
        -0.047098468989133835,
        -0.04211079701781273,
        -0.05455035716295242,
        0.04904527962207794,
        0.03568548336625099,
        -0.019853921607136726,
        -0.03600647673010826,
        0.06399671733379364,
        0.054503973573446274,
        0.0037945813965052366,
        -0.0598745197057724,
        -0.05295788496732712,
        0.04749760404229164,
        0.04273189604282379,
        0.018277591094374657,
        0.06610134989023209,
        -0.032697971910238266,
        -0.018871992826461792,
        0.05895378068089485,
        -0.059119898825883865,
        -0.05575042590498924,
        0.013416421599686146,
        -0.033967845141887665,
        -0.06424124538898468,
        -0.0100466413423419,
        -0.03534315526485443,
        0.05978435277938843,
        -0.06324820965528488,
        -0.01968536153435707,
        0.0209703017026186,
        0.058529507368803024,
        0.06110024452209473,
        -0.01729520782828331,
        -0.0328156016767025,
        -0.021631279960274696,
        -0.06486885994672775,
        -0.06332135945558548,
        0.060974303632974625,
        -0.019075734540820122,
        -0.05990792065858841,
        -0.06601221859455109,
        -0.017752787098288536,
        0.015942666679620743,
        0.012473862618207932,
        0.0196874737739563,
        -0.009380039758980274,
        0.022140227258205414,
        0.0010825751814991236,
        -0.06622640788555145,
        0.0037901250179857016,
        -0.01137736439704895,
        -0.018856346607208252,
        0.044183678925037384,
        0.04270321875810623,
        0.05381771922111511,
        0.04054166004061699,
        -0.03218920901417732,
        0.029439184814691544,
        0.0057457126677036285,
        -0.061721138656139374,
        0.06468727439641953,
        -0.009332762099802494,
        0.02457551471889019,
        0.008568651974201202,
        0.05258973315358162,
        -0.029547259211540222,
        0.04042627662420273,
        -0.04262930899858475,
        0.011214193888008595,
        -0.015259954147040844,
        0.037385452538728714,
        0.014386431314051151,
        -0.030190959572792053,
        0.053702257573604584,
        -0.0547124519944191,
        -0.05170068517327309,
        0.06438062340021133,
        0.06577371060848236,
        -0.06369052082300186,
        0.05874689295887947,
        -0.062415629625320435,
        -0.06647305935621262,
        -0.04264678806066513,
        -0.056613191962242126,
        0.06360787898302078,
        0.04372956603765488,
        0.06416040658950806,
        -0.022219756618142128,
        -0.0287316907197237,
        0.04538487643003464,
        0.01846448890864849,
        -0.04414082318544388,
        -0.06284243613481522,
        0.05459068343043327,
        -0.004133600275963545,
        0.009086834266781807,
        -0.04033493623137474,
        0.05694441497325897,
        0.06299910694360733,
        -0.010475998744368553,
        -0.06328728049993515,
        -0.02665562368929386,
        -0.00872284546494484,
        -0.03087349236011505,
        0.00039309231215156615,
        -0.015524215064942837,
        0.015145119279623032,
        0.003131341189146042,
        -0.05050085857510567,
        -0.03175857663154602,
        0.06363119184970856,
        0.05959232896566391,
        0.021506084129214287,
        -0.013349507935345173,
        -0.045293085277080536,
        0.054975397884845734,
        0.010020316578447819,
        0.06088105961680412,
        -0.015714410692453384,
        0.0058310567401349545,
        -0.005664373282343149,
        -0.06088637188076973,
        0.05037084221839905,
        0.06071699783205986
    ],
    [
        0.03457646444439888,
        -0.006934804376214743,
        0.030196035280823708,
        0.006643723696470261,
        0.03433060646057129,
        -0.04223663732409477,
        0.06540454924106598,
        -0.03517197072505951,
        0.0034120704513043165,
        -0.04884038120508194,
        0.0570075549185276,
        -0.021578166633844376,
        -0.059116430580616,
        -0.015518860891461372,
        -0.06387840211391449,
        0.03896765038371086,
        -0.006012743338942528,
        0.03728898987174034,
        -0.05046761408448219,
        0.06439868360757828,
        -0.01980418898165226,
        0.0361289419233799,
        0.03128965198993683,
        -0.012555521912872791,
        0.06048709154129028,
        0.040792547166347504,
        0.006816304288804531,
        0.06357081234455109,
        -0.05898021534085274,
        -0.05422291159629822,
        0.04033909738063812,
        -0.028524234890937805,
        -0.003980238921940327,
        -0.05719589814543724,
        0.056006722152233124,
        -0.038868315517902374,
        -0.05307437852025032,
        0.02940831519663334,
        0.04790549725294113,
        -0.03633110597729683,
        0.00975553598254919,
        0.012853650376200676,
        0.025519153103232384,
        -0.06179879978299141,
        0.033392030745744705,
        0.01425001211464405,
        -0.012233209796249866,
        0.06205076351761818,
        -0.044910307973623276,
        -0.030359655618667603,
        0.056564491242170334,
        0.03582930564880371,
        0.06003488227725029,
        -0.029527725651860237,
        -0.016406504437327385,
        0.03277824819087982,
        -0.03763626143336296,
        -0.04049628600478172,
        -0.05959014222025871,
        0.04796794429421425,
        -0.0202025156468153,
        0.04793470725417137,
        -0.052702438086271286,
        -0.007851483300328255,
        0.0207834355533123,
        -0.04650348424911499,
        -0.01314990408718586,
        -0.062417592853307724,
        0.05703539028763771,
        -0.06583187729120255,
        -0.054330069571733475,
        -0.0645017996430397,
        0.0663084015250206,
        -0.010660015977919102,
        -0.017829127609729767,
        0.0007317686104215682,
        0.035633593797683716,
        0.0666765347123146,
        0.04648589715361595,
        -0.044594768434762955,
        0.020206332206726074,
        -0.02787269465625286,
        -0.05326823517680168,
        0.023534949868917465,
        -0.059429191052913666,
        0.03874216228723526,
        0.05604448914527893,
        -0.050682492554187775,
        -0.057857949286699295,
        0.06294922530651093,
        -0.0036737036425620317,
        -0.04685891047120094,
        0.008780236355960369,
        0.03758934512734413,
        0.05442136526107788,
        -0.0562652051448822,
        0.04255431517958641,
        -0.0642167329788208,
        -0.05081988871097565,
        0.008601708337664604,
        -0.0533815436065197,
        -0.036308128386735916,
        0.06509067118167877,
        0.06118634343147278,
        -0.03444688022136688,
        -0.06065831705927849,
        -0.06636891514062881,
        0.016277609393000603,
        -0.06174125149846077,
        -0.006782623939216137,
        -0.03324860334396362,
        -0.05466976389288902,
        -0.03726720064878464,
        -0.06377051025629044,
        0.06531451642513275,
        0.04046875610947609,
        -0.02988380566239357,
        0.054503485560417175,
        0.020860088989138603,
        -0.06556239724159241,
        -0.06425142288208008,
        -0.03994050621986389,
        -0.031372684985399246,
        -0.06544139236211777,
        -0.0654762014746666,
        -0.0302517581731081,
        -0.0066109937615692616,
        0.06316430866718292,
        0.05733602121472359,
        0.024930350482463837,
        -0.04746527224779129,
        -0.059833627194166183,
        -0.020800935104489326,
        0.06281223148107529,
        -0.03719499334692955,
        -0.05937660112977028,
        0.03816879540681839,
        -0.06064259633421898,
        0.04505039006471634,
        0.06430626660585403,
        0.044986482709646225,
        -0.026244156062602997,
        0.03873452544212341,
        0.06451711058616638,
        -0.030268225818872452,
        -0.0220908485352993,
        0.06563079357147217,
        -0.05433902144432068,
        0.01524259801954031,
        0.008613897487521172,
        -0.06048835813999176,
        -0.06556059420108795,
        -0.06487487256526947,
        -0.02694050222635269,
        0.04182133823633194,
        0.019401822239160538,
        0.020297829061746597,
        0.03398388624191284,
        0.0018347690347582102,
        -0.03990922123193741,
        0.060230109840631485,
        0.051237497478723526,
        0.05724450945854187,
        -0.02561715617775917,
        0.03315499424934387,
        0.02034052088856697,
        -0.021953903138637543,
        -0.044702865183353424,
        -0.04861340671777725,
        0.06091926991939545,
        0.020760340616106987,
        0.024964839220046997,
        -0.06069887802004814,
        0.036360178142786026,
        -0.03644951060414314,
        -0.04191746190190315,
        -0.019257545471191406,
        -0.010875877924263477,
        0.06387879699468613,
        -0.03352602943778038,
        0.044888634234666824,
        0.05425851047039032,
        0.056355930864810944,
        -0.03660324588418007,
        0.06211446225643158,
        -0.06204047054052353,
        0.047776445746421814,
        0.04031006991863251,
        -0.060064513236284256,
        -0.05912213772535324,
        -0.06603996455669403,
        0.008319907821714878,
        0.004153009038418531,
        0.033646684139966965,
        -0.00042957908590324223,
        0.0526372492313385,
        0.0582849346101284,
        0.0624660849571228,
        0.014288668520748615,
        0.04962664842605591,
        -0.03584776818752289,
        0.02661946229636669,
        -0.025925559923052788,
        0.011947065591812134,
        -0.058412469923496246,
        -0.00847453810274601,
        0.05805829167366028,
        0.025820938870310783,
        0.023749617859721184,
        0.0020384476520121098,
        -0.05231272056698799,
        -0.052825894206762314,
        -0.015417259186506271,
        0.03893342614173889,
        0.023025667294859886,
        0.015292062424123287,
        0.05504614859819412,
        0.033623769879341125,
        0.06650875508785248,
        0.022750649601221085,
        0.05514770373702049,
        0.014281733892858028,
        0.052119333297014236,
        -0.06685959547758102,
        0.066022589802742,
        -0.06324976682662964,
        0.029202628880739212,
        -0.025349438190460205,
        -0.05134429410099983,
        -0.06625065952539444,
        -0.06678298115730286,
        -0.00174801517277956,
        -0.05014137923717499,
        0.03553212061524391,
        -0.046596914529800415,
        0.044363558292388916,
        0.05097757652401924,
        0.02729194611310959,
        -0.020331038162112236,
        0.066133052110672,
        0.04172198474407196,
        0.046804316341876984,
        0.0262313112616539,
        -0.0321061834692955,
        0.017920861020684242,
        -0.031666018068790436,
        -0.004387553781270981,
        -0.042609941214323044,
        -0.0009517463622614741,
        -0.002627966459840536,
        -0.01664860174059868,
        -0.06619253754615784,
        0.010048008523881435,
        -0.06402173638343811,
        -0.0360780730843544,
        0.056932106614112854,
        0.03373219817876816,
        0.03267771378159523,
        0.0640670657157898,
        0.061310507357120514,
        -0.023746604099869728,
        -0.032280053943395615,
        0.03386992588639259,
        0.008319533430039883,
        -0.03872617706656456,
        0.06176033616065979,
        0.01885695941746235,
        0.034620482474565506,
        -0.015597909688949585,
        -0.05732519552111626,
        -0.03704936057329178,
        -0.050533492118120193,
        0.04726171866059303,
        -0.061943672597408295,
        -0.022396251559257507,
        0.01853334717452526,
        -0.056877605617046356,
        -0.005551900714635849,
        -0.04433092847466469,
        0.006522896699607372,
        -0.015050106681883335,
        -0.05431268364191055,
        -0.05121932551264763,
        0.06035095453262329,
        0.04403279721736908,
        0.03216436505317688,
        0.03280607983469963,
        -0.03317315876483917,
        -0.0005894684582017362,
        0.01106221042573452,
        -0.05505477264523506,
        0.03464091569185257,
        -0.03823530673980713,
        0.06003185734152794,
        -0.01455342024564743,
        -0.0653085708618164,
        0.06647279858589172,
        0.030514352023601532,
        -0.06283528357744217,
        0.05588685721158981,
        -0.03161541372537613,
        0.02768322266638279,
        -0.06414742767810822,
        0.06537886708974838,
        -0.015610067173838615,
        0.05720717832446098,
        -0.06492096930742264,
        0.040883149951696396,
        0.004148803185671568,
        -0.0668550431728363,
        -0.0032823358196765184,
        -0.04009019583463669,
        0.030882615596055984,
        0.0651078149676323,
        -0.06526707857847214,
        -0.05536472797393799,
        0.0605926550924778,
        -0.036197926849126816,
        0.06515876203775406,
        0.04192972555756569,
        -0.010127807036042213,
        0.026982923969626427,
        0.06388735771179199,
        -0.05724430829286575,
        0.0420064851641655,
        0.05834907665848732,
        0.0003512797993607819,
        -0.06425114721059799,
        -0.01261467207223177,
        -0.06629098206758499,
        -0.004508997779339552,
        0.020176667720079422,
        -0.03601401671767235,
        -0.05001026764512062,
        0.06286709755659103,
        0.02528589963912964,
        0.017550962045788765,
        0.06643351167440414,
        -0.042839616537094116,
        0.05026489868760109,
        0.021477341651916504,
        0.06044810637831688,
        -0.0340571328997612,
        -0.053743649274110794,
        0.06705425679683685,
        0.04314092919230461,
        -0.056425824761390686,
        0.05991068854928017,
        -0.038127150386571884,
        0.06473420560359955,
        -0.043165359646081924,
        0.021705130115151405,
        0.0454309806227684,
        -0.06600935757160187,
        -0.047881949692964554,
        0.057667020708322525,
        -0.05047363415360451,
        0.03787299618124962,
        -0.04398978501558304,
        0.0015251507284119725,
        0.06418453902006149,
        0.042475491762161255,
        -0.05381625145673752,
        -0.017921674996614456,
        -0.02655908092856407,
        -0.05734449252486229,
        0.0658956915140152,
        -0.009689746424555779,
        0.004890606738626957,
        -0.05882484093308449,
        0.017295565456151962,
        -0.007590189576148987,
        0.06504926830530167,
        0.0524129644036293,
        0.03171131759881973,
        0.06031448394060135,
        0.004387257155030966,
        -0.03506620600819588,
        0.053007885813713074,
        0.003934602718800306,
        -0.04956497997045517,
        0.03276539593935013,
        -0.028735516592860222,
        0.02093362994492054,
        0.06303871423006058,
        -0.0001394696009811014,
        -0.009989237412810326,
        -0.061108823865652084,
        -0.047098468989133835,
        -0.04211079701781273,
        -0.05455035716295242,
        0.04904527962207794,
        0.03568548336625099,
        -0.019853921607136726,
        -0.03600647673010826,
        0.06399671733379364,
        0.054503973573446274,
        0.0037945813965052366,
        -0.0598745197057724,
        -0.05295788496732712,
        0.04749760404229164,
        0.04273189604282379,
        0.018277591094374657,
        0.06610134989023209,
        -0.032697971910238266,
        -0.018871992826461792,
        0.05895378068089485,
        -0.059119898825883865,
        -0.05575042590498924,
        0.013416421599686146,
        -0.033967845141887665,
        -0.06424124538898468,
        -0.0100466413423419,
        -0.03534315526485443,
        0.05978435277938843,
        -0.06324820965528488,
        -0.01968536153435707,
        0.0209703017026186,
        0.058529507368803024,
        0.06110024452209473,
        -0.01729520782828331,
        -0.0328156016767025,
        -0.021631279960274696,
        -0.06486885994672775,
        -0.06332135945558548,
        0.060974303632974625,
        -0.019075734540820122,
        -0.05990792065858841,
        -0.06601221859455109,
        -0.017752787098288536,
        0.015942666679620743,
        0.012473862618207932,
        0.0196874737739563,
        -0.009380039758980274,
        0.022140227258205414,
        0.0010825751814991236,
        -0.06622640788555145,
        0.0037901250179857016,
        -0.01137736439704895,
        -0.018856346607208252,
        0.044183678925037384,
        0.04270321875810623,
        0.05381771922111511,
        0.04054166004061699,
        -0.03218920901417732,
        0.029439184814691544,
        0.0057457126677036285,
        -0.061721138656139374,
        0.06468727439641953,
        -0.009332762099802494,
        0.02457551471889019,
        0.008568651974201202,
        0.05258973315358162,
        -0.029547259211540222,
        0.04042627662420273,
        -0.04262930899858475,
        0.011214193888008595,
        -0.015259954147040844,
        0.037385452538728714,
        0.014386431314051151,
        -0.030190959572792053,
        0.053702257573604584,
        -0.0547124519944191,
        -0.05170068517327309,
        0.06438062340021133,
        0.06577371060848236,
        -0.06369052082300186,
        0.05874689295887947,
        -0.062415629625320435,
        -0.06647305935621262,
        -0.04264678806066513,
        -0.056613191962242126,
        0.06360787898302078,
        0.04372956603765488,
        0.06416040658950806,
        -0.022219756618142128,
        -0.0287316907197237,
        0.04538487643003464,
        0.01846448890864849,
        -0.04414082318544388,
        -0.06284243613481522,
        0.05459068343043327,
        -0.004133600275963545,
        0.009086834266781807,
        -0.04033493623137474,
        0.05694441497325897,
        0.06299910694360733,
        -0.010475998744368553,
        -0.06328728049993515,
        -0.02665562368929386,
        -0.00872284546494484,
        -0.03087349236011505,
        0.00039309231215156615,
        -0.015524215064942837,
        0.015145119279623032,
        0.003131341189146042,
        -0.05050085857510567,
        -0.03175857663154602,
        0.06363119184970856,
        0.05959232896566391,
        0.021506084129214287,
        -0.013349507935345173,
        -0.045293085277080536,
        0.054975397884845734,
        0.010020316578447819,
        0.06088105961680412,
        -0.015714410692453384,
        0.0058310567401349545,
        -0.005664373282343149,
        -0.06088637188076973,
        0.05037084221839905,
        0.06071699783205986
    ],
    [
        -0.004238581750541925,
        -0.02657933346927166,
        0.021690020337700844,
        -0.040557146072387695,
        0.052133671939373016,
        -0.06076984107494354,
        0.06152797117829323,
        -0.01703036203980446,
        -0.01008031889796257,
        -0.038648273795843124,
        0.04811565577983856,
        0.016318246722221375,
        -0.0594811774790287,
        0.0009179152548313141,
        -0.06054855138063431,
        0.039049409329891205,
        0.04078495129942894,
        0.052879929542541504,
        -0.014330488629639149,
        0.05605534836649895,
        -0.016616834327578545,
        -0.003048354061320424,
        0.031149668619036674,
        -0.00644210260361433,
        -0.00015187510871328413,
        0.0384412445127964,
        -0.021621281281113625,
        0.057933833450078964,
        -0.06071529537439346,
        0.00774821313098073,
        -0.04490650072693825,
        0.024057747796177864,
        0.013963321223855019,
        -0.02356662042438984,
        0.05653193220496178,
        -0.052842237055301666,
        -0.056450121104717255,
        0.03172145038843155,
        0.05785365402698517,
        -0.030116422101855278,
        -0.021824726834893227,
        0.01198761910200119,
        0.029367538169026375,
        -0.053087711334228516,
        0.030361955985426903,
        -0.02368355356156826,
        0.04824787750840187,
        0.05876561999320984,
        -0.047223422676324844,
        -0.04011473432183266,
        0.05760003626346588,
        0.023058287799358368,
        0.060805972665548325,
        -0.03150610625743866,
        0.0077738771215081215,
        -0.021137943491339684,
        -0.04760865122079849,
        -0.05925576016306877,
        -0.037876907736063004,
        0.010525600984692574,
        -0.008040066808462143,
        0.058408960700035095,
        -0.05782811716198921,
        -0.011597503907978535,
        0.05215531960129738,
        0.002575585851445794,
        -0.05404822900891304,
        -0.06084461882710457,
        0.05358504131436348,
        -0.05974798649549484,
        -0.03038920648396015,
        -0.060772981494665146,
        0.060835905373096466,
        0.03665781393647194,
        -0.02729354426264763,
        -0.004067896865308285,
        0.05068470165133476,
        0.06170878931879997,
        0.05202781781554222,
        -0.03436407446861267,
        0.02689322829246521,
        0.02565089799463749,
        -0.011038040742278099,
        0.028901277109980583,
        -0.057365503162145615,
        0.025768794119358063,
        -0.018823981285095215,
        -0.057452622801065445,
        -0.05757296830415726,
        0.054560042917728424,
        -0.03217865526676178,
        -0.04553675279021263,
        -0.053134698420763016,
        -0.03561537712812424,
        0.04875572398304939,
        -0.05207524448633194,
        -0.026308782398700714,
        -0.061485182493925095,
        0.040844034403562546,
        0.013723475858569145,
        -0.05771617591381073,
        -0.046187613159418106,
        0.055931586772203445,
        0.06113457679748535,
        0.010240340605378151,
        -0.05483802407979965,
        -0.0613117590546608,
        -0.017947770655155182,
        -0.05792723968625069,
        -0.021133463829755783,
        -0.04111481085419655,
        -0.055568162351846695,
        -0.05485313758254051,
        -0.05948773771524429,
        0.053131695836782455,
        -0.0025717136450111866,
        -0.03620311990380287,
        -0.03911759331822395,
        -0.055057961493730545,
        -0.06039486080408096,
        -0.06034289300441742,
        -0.04675932601094246,
        -0.04832379147410393,
        -0.05234923213720322,
        -0.06141161546111107,
        -0.023184120655059814,
        0.006367065012454987,
        -0.00634798826649785,
        0.05709957703948021,
        0.054917171597480774,
        -0.03310395032167435,
        -0.055475614964962006,
        -0.031000295653939247,
        0.058639056980609894,
        0.03379528224468231,
        -0.057585492730140686,
        0.05325781926512718,
        -0.05935533344745636,
        0.013291846960783005,
        0.061422497034072876,
        0.01985265128314495,
        -0.02080233208835125,
        0.026032693684101105,
        0.06052291765809059,
        0.014009756036102772,
        -0.021037966012954712,
        0.06151541322469711,
        -0.05704887956380844,
        0.03611713647842407,
        -0.009761557914316654,
        -0.06114489585161209,
        -0.05928705632686615,
        -0.06108945608139038,
        -0.05443713068962097,
        0.04017207399010658,
        0.06068921089172363,
        -0.03817090764641762,
        0.01679779402911663,
        -0.04695617035031319,
        0.023240569978952408,
        0.05797272548079491,
        0.05859238654375076,
        0.05321183800697327,
        -0.03090500459074974,
        0.0182383693754673,
        0.017885340377688408,
        0.03452593833208084,
        -0.015891961753368378,
        -0.04193297401070595,
        0.05121380090713501,
        0.0440739244222641,
        0.013134225271642208,
        -0.05933292582631111,
        0.05892496928572655,
        -0.05706791579723358,
        -0.057408176362514496,
        -0.04217027500271797,
        -0.03196118026971817,
        0.061082761734724045,
        -0.05399535968899727,
        0.01176389865577221,
        0.05883510783314705,
        0.040308013558387756,
        -0.05135812982916832,
        0.006354899145662785,
        -0.06128828227519989,
        -0.0019709591288119555,
        0.05294722691178322,
        -0.04504580795764923,
        -0.05824742466211319,
        -0.06018081307411194,
        0.04654240235686302,
        0.017969997599720955,
        0.027968335896730423,
        -0.026194240897893906,
        0.027121078222990036,
        0.03959149867296219,
        0.03958533704280853,
        -0.051100075244903564,
        0.05213731527328491,
        0.03397340700030327,
        0.008990667760372162,
        -0.00036300483043305576,
        -0.013430704362690449,
        -0.037872981280088425,
        0.027685513719916344,
        0.060907382518053055,
        0.056762583553791046,
        0.044669777154922485,
        0.01775393821299076,
        -0.05235125496983528,
        -0.04371867701411247,
        0.021973393857479095,
        0.05811178311705589,
        0.02559460885822773,
        -0.014429319649934769,
        0.057480763643980026,
        -0.016482282429933548,
        0.061665263026952744,
        0.018406158313155174,
        0.033717840909957886,
        -0.003155795857310295,
        -0.01741415075957775,
        -0.06148270517587662,
        0.06011773645877838,
        -0.06006312370300293,
        0.025851810351014137,
        -0.05829937756061554,
        -0.04868302866816521,
        -0.06144872307777405,
        -0.06131162866950035,
        -0.005531306844204664,
        -0.04714275896549225,
        0.057314299046993256,
        -0.027344370260834694,
        0.06039039045572281,
        0.058384645730257034,
        0.049959976226091385,
        -0.054669663310050964,
        0.06165426969528198,
        0.055584367364645004,
        0.05049829185009003,
        0.022738123312592506,
        -0.04368007555603981,
        0.011070732027292252,
        -0.047113675624132156,
        0.04348312318325043,
        -0.05580298230051994,
        -0.04113125428557396,
        0.0006890342338010669,
        -0.03635082393884659,
        -0.059866514056921005,
        0.018336962908506393,
        -0.06096051260828972,
        -0.009829038754105568,
        0.0601278692483902,
        0.04380153864622116,
        -0.019574008882045746,
        0.06136691942811012,
        0.05757295712828636,
        -0.019594019278883934,
        0.013195394538342953,
        0.04079626128077507,
        -0.032189782708883286,
        -0.037499815225601196,
        0.055583856999874115,
        0.05769506096839905,
        0.046794675290584564,
        0.045777130872011185,
        -0.0434778593480587,
        -0.04964842647314072,
        -0.03801789507269859,
        0.01335993967950344,
        -0.0611315593123436,
        0.013225090689957142,
        -0.01434095948934555,
        -0.049109090119600296,
        -0.02661525085568428,
        -0.03539128229022026,
        0.011709576472640038,
        -0.06149221956729889,
        0.013299288228154182,
        -0.0418887697160244,
        0.02913823164999485,
        0.03935689479112625,
        0.020848285406827927,
        -0.04869470000267029,
        -0.025831975042819977,
        -0.05544550344347954,
        0.011913241818547249,
        -0.05850718170404434,
        0.01872839592397213,
        0.005656618624925613,
        0.03634634241461754,
        0.04947340488433838,
        -0.05882703885436058,
        0.06017669662833214,
        0.05938606336712837,
        -0.05312160402536392,
        -0.006080359686166048,
        0.03176521137356758,
        0.05374008044600487,
        -0.057337213307619095,
        0.06052848696708679,
        -0.02402392216026783,
        0.027022087946534157,
        -0.05205328390002251,
        0.05689355731010437,
        0.007954143919050694,
        -0.0608714260160923,
        0.012550557032227516,
        -0.04393965005874634,
        0.03666246309876442,
        0.06132347881793976,
        -0.017618050798773766,
        -0.05741965025663376,
        0.04597849398851395,
        -0.019562233239412308,
        0.06092161312699318,
        0.05560251697897911,
        0.044860005378723145,
        -0.017603756859898567,
        0.059593625366687775,
        0.01057664304971695,
        0.05933738127350807,
        0.05271302908658981,
        0.03722050040960312,
        -0.056477539241313934,
        -0.029106419533491135,
        -0.06100858002901077,
        0.00046882336027920246,
        0.010725072585046291,
        -0.0352524034678936,
        -0.06041077896952629,
        0.053863946348428726,
        -0.03068520687520504,
        -0.041108619421720505,
        0.061619121581315994,
        -0.05224718898534775,
        0.04287941753864288,
        0.03407399728894234,
        0.0591573566198349,
        -0.023000568151474,
        -0.05067369341850281,
        0.061696119606494904,
        0.009159157983958721,
        0.0021527374628931284,
        0.0593566820025444,
        -0.0080559141933918,
        0.060549259185791016,
        0.022714372724294662,
        0.030981849879026413,
        -0.03683934360742569,
        -0.06163497269153595,
        -0.042259469628334045,
        0.056533440947532654,
        -0.06048106402158737,
        0.04774582386016846,
        -0.05587219446897507,
        -0.023005133494734764,
        0.060829952359199524,
        0.05544962361454964,
        -0.05164654552936554,
        0.017662884667515755,
        -0.05592881888151169,
        -0.06009672209620476,
        0.061580512672662735,
        -0.02405727468430996,
        0.045333296060562134,
        -0.05836563929915428,
        -0.059256572276353836,
        -0.05116568133234978,
        0.060010626912117004,
        0.009935103356838226,
        0.06008213385939598,
        0.05823088437318802,
        0.049104027450084686,
        -0.04481889307498932,
        0.05574049800634384,
        0.05232160910964012,
        -0.04646769538521767,
        0.04898146167397499,
        0.01095124427229166,
        -0.03347550332546234,
        0.05431484803557396,
        -0.009046904742717743,
        0.046228691935539246,
        -0.05493989959359169,
        -0.05815869942307472,
        -0.017902517691254616,
        -0.044874124228954315,
        0.0595434345304966,
        0.03493755683302879,
        -0.0016260688425973058,
        0.01869838684797287,
        0.060008980333805084,
        0.05903705209493637,
        -0.05652831867337227,
        -0.059135694056749344,
        0.022025490179657936,
        0.04582829773426056,
        0.002351744333282113,
        -0.03466925397515297,
        0.06156495586037636,
        0.01926558092236519,
        -0.013289093971252441,
        0.05873437598347664,
        -0.04069574177265167,
        -0.05108062922954559,
        0.00846875924617052,
        0.002423640340566635,
        -0.055341124534606934,
        -0.03388289362192154,
        0.017168518155813217,
        0.05242307856678963,
        -0.06078912317752838,
        -0.01364186778664589,
        0.018840378150343895,
        0.05942311882972717,
        0.05668925866484642,
        0.02743963897228241,
        -0.05203922092914581,
        -0.021193642169237137,
        -0.06021483987569809,
        -0.06010862812399864,
        0.0426095649600029,
        -0.039217814803123474,
        -0.03811625763773918,
        -0.06135581061244011,
        0.0377412848174572,
        0.02636636048555374,
        -0.06081428751349449,
        0.052703388035297394,
        -0.004158573690801859,
        0.04071614891290665,
        -0.047748953104019165,
        -0.04484831169247627,
        0.04584774374961853,
        0.05373338609933853,
        -0.057521648705005646,
        0.023497357964515686,
        0.023321690037846565,
        0.05595651641488075,
        0.06067163497209549,
        -0.04773798957467079,
        0.021616695448756218,
        -0.04858153313398361,
        -0.061584312468767166,
        0.059717874974012375,
        0.04635273665189743,
        -0.020772568881511688,
        -0.0526701919734478,
        0.05035984143614769,
        -0.057450804859399796,
        0.04250194504857063,
        -0.03748813271522522,
        -0.05729251354932785,
        -0.022868767380714417,
        -0.003901081159710884,
        -0.025196518748998642,
        0.0316501185297966,
        0.028884438797831535,
        -0.052531223744153976,
        -0.03227345645427704,
        0.060508958995342255,
        0.06157933920621872,
        -0.05887891724705696,
        0.05808695778250694,
        -0.0452326275408268,
        -0.061392076313495636,
        -0.020170388743281364,
        -0.012225914746522903,
        0.04008232429623604,
        0.06030021235346794,
        0.05467141792178154,
        0.04221269488334656,
        -0.04141493886709213,
        0.01705116778612137,
        0.031244805082678795,
        -0.04860219359397888,
        -0.060048069804906845,
        0.011819138191640377,
        0.005011756904423237,
        -0.027459029108285904,
        -0.009621625766158104,
        0.06082447990775108,
        0.060520268976688385,
        0.0018505684565752745,
        -0.05886828899383545,
        -0.059037886559963226,
        -0.006191495805978775,
        -0.054021596908569336,
        -0.03331087529659271,
        0.05080299451947212,
        -0.016776079311966896,
        0.03969324752688408,
        -0.025481704622507095,
        -0.029203256592154503,
        0.058008939027786255,
        0.055796269327402115,
        -0.05318353697657585,
        -0.051520004868507385,
        -0.014715760014951229,
        -0.02204413153231144,
        0.05367834120988846,
        0.05310869216918945,
        0.05705691874027252,
        -0.012037115171551704,
        0.04176896810531616,
        -0.06011723354458809,
        0.05889562889933586,
        0.05894047021865845
    ],
    [
        -0.03051714040338993,
        0.06496024131774902,
        0.009771051816642284,
        0.03845323994755745,
        0.01636485755443573,
        0.0700182244181633,
        0.0207675788551569,
        -0.05212083458900452,
        0.06251449882984161,
        0.025031523779034615,
        0.03775345906615257,
        -0.03135368227958679,
        -0.03253474459052086,
        -0.018471205607056618,
        -0.03908044844865799,
        0.06973627954721451,
        -0.03360125795006752,
        0.06953554600477219,
        -0.06048768386244774,
        -0.07246784120798111,
        -0.03293530270457268,
        0.06488829851150513,
        0.019738029688596725,
        0.021977443248033524,
        0.047885142266750336,
        0.044691525399684906,
        0.056347936391830444,
        -0.023438407108187675,
        0.01448052003979683,
        0.020022882148623466,
        0.038569796830415726,
        -0.032800912857055664,
        -0.05769507959485054,
        -0.06625715643167496,
        0.05336269363760948,
        -0.015305718407034874,
        -0.06384030729532242,
        0.007570828311145306,
        0.0009456661064177752,
        0.003435613354668021,
        0.06353303045034409,
        0.013914783485233784,
        0.0196564681828022,
        -0.0709158405661583,
        0.06628219783306122,
        0.035205814987421036,
        -0.005745254456996918,
        0.04740959778428078,
        0.028096040710806847,
        0.049183547496795654,
        0.04509672895073891,
        0.06952939927577972,
        -0.03828125074505806,
        0.05907721072435379,
        0.028121914714574814,
        -0.007943334989249706,
        0.031723637133836746,
        0.06401286274194717,
        -0.022613566368818283,
        0.00494538526982069,
        -0.05591541901230812,
        0.06257389485836029,
        -0.039562635123729706,
        0.0652066320180893,
        -0.06702302396297455,
        -0.06002644822001457,
        0.032757144421339035,
        -0.008874391205608845,
        -0.06191517412662506,
        -0.018567677587270737,
        -0.0669957622885704,
        -0.06946954876184464,
        0.06328566372394562,
        0.04156077280640602,
        -0.015889806672930717,
        -0.022023912519216537,
        -0.0310331042855978,
        0.07198917865753174,
        0.06381329894065857,
        -0.007571427617222071,
        0.031119780614972115,
        0.0702553242444992,
        -0.05341487377882004,
        0.03818950057029724,
        0.056871525943279266,
        0.011205730959773064,
        0.07233438640832901,
        0.06235089898109436,
        0.04173508286476135,
        0.01702536828815937,
        0.057890649884939194,
        -0.03973143920302391,
        -0.0693618431687355,
        -0.028072502464056015,
        -0.02693510428071022,
        -0.013994304463267326,
        -0.007631767075508833,
        -0.06470384448766708,
        -0.02313561551272869,
        -0.009253408759832382,
        -0.06750348955392838,
        -0.0002209516242146492,
        0.059126585721969604,
        -0.0046889884397387505,
        0.05238926038146019,
        -0.06680002063512802,
        -0.05500315502285957,
        0.007213616743683815,
        0.024755094200372696,
        -0.0012975068530067801,
        -0.060717880725860596,
        -0.049723125994205475,
        -0.06388440728187561,
        0.0018069421639665961,
        -0.018389439210295677,
        0.014296813867986202,
        0.027367398142814636,
        -0.021514954045414925,
        0.07005757838487625,
        0.025165028870105743,
        -0.0558922216296196,
        0.04894450306892395,
        0.036195605993270874,
        -0.052347052842378616,
        -0.06240520998835564,
        -0.011009369976818562,
        -0.06209023296833038,
        -0.016673201695084572,
        0.06811009347438812,
        0.04272580146789551,
        -0.028531216084957123,
        -0.051763229072093964,
        -0.05208200961351395,
        0.017778005450963974,
        -0.04788374528288841,
        -0.047506608068943024,
        0.004328331910073757,
        -0.007980210706591606,
        -0.062040627002716064,
        0.038991715759038925,
        0.06249238923192024,
        0.005521785002201796,
        0.05991191416978836,
        0.06285808235406876,
        0.054330013692379,
        -0.07155779004096985,
        0.0655607357621193,
        -0.06459388136863708,
        -0.0005987109034322202,
        0.06164038926362991,
        0.03825564309954643,
        -0.06642124056816101,
        -0.009250572882592678,
        -0.06835316121578217,
        -0.0010050397831946611,
        -0.023757047951221466,
        0.02487124875187874,
        0.03558832406997681,
        -0.01844710297882557,
        0.046914830803871155,
        0.07294069975614548,
        0.03912010416388512,
        0.04653659835457802,
        0.0057020410895347595,
        -0.0005529042100533843,
        0.03656656667590141,
        -0.05667744576931,
        -0.034886475652456284,
        0.03947015479207039,
        0.009456923231482506,
        0.04756864160299301,
        0.0017142686992883682,
        -0.028858300298452377,
        -0.05177712440490723,
        0.014200238510966301,
        0.05924854055047035,
        -0.06337592005729675,
        -0.02362438663840294,
        0.06095137074589729,
        -0.035135526210069656,
        -0.004313027020543814,
        0.07300388813018799,
        -0.007913026958703995,
        0.032530877739191055,
        0.04645431786775589,
        -0.044652942568063736,
        -0.021425234153866768,
        0.06275475770235062,
        -0.03755471855401993,
        -0.0339529812335968,
        -0.04793425649404526,
        0.018426112830638885,
        0.05053739994764328,
        0.006148229818791151,
        -0.006253507453948259,
        0.026030873879790306,
        -0.04398243501782417,
        -0.07215740531682968,
        -0.01609949953854084,
        -0.014831592328846455,
        0.029795587062835693,
        -0.05641739442944527,
        0.029300469905138016,
        -0.04969600588083267,
        -0.07048501819372177,
        0.047253359109163284,
        -0.037989791482686996,
        -0.04242192208766937,
        -0.04227156937122345,
        0.06715887784957886,
        -0.06101968511939049,
        0.02851150743663311,
        0.009708533063530922,
        0.05668575316667557,
        -0.023264260962605476,
        0.038930077105760574,
        -0.013211158104240894,
        -0.017840249463915825,
        0.05899123102426529,
        -0.025252128019928932,
        0.04555351287126541,
        0.07001900672912598,
        0.04497150704264641,
        -0.05613774061203003,
        0.05636981502175331,
        -0.015740152448415756,
        -0.00716807134449482,
        0.015417180024087429,
        -0.04377497360110283,
        -0.04481503739953041,
        -0.041639503091573715,
        -0.04222341999411583,
        -0.058769069612026215,
        -0.009889114648103714,
        -0.042165279388427734,
        0.05021743103861809,
        0.0546252503991127,
        -0.03329020366072655,
        -0.04647891968488693,
        0.06254032254219055,
        -0.037275493144989014,
        0.0665353462100029,
        -0.06793306022882462,
        0.055061496794223785,
        0.034800849854946136,
        -0.01006721705198288,
        -0.07080254703760147,
        -0.04085589945316315,
        0.010886874049901962,
        -0.016856810078024864,
        -0.051486313343048096,
        -0.06154157966375351,
        -0.007473642937839031,
        -0.07136987149715424,
        -0.04315441846847534,
        0.052274931222200394,
        -0.046165987849235535,
        -0.050269972532987595,
        0.03827333450317383,
        0.013754701241850853,
        0.07222262024879456,
        -0.019672609865665436,
        -0.02270018495619297,
        0.05169910937547684,
        0.01785113662481308,
        0.05725666508078575,
        -0.04105178266763687,
        0.05007324367761612,
        -0.0012791064800694585,
        0.05350061133503914,
        0.040834661573171616,
        -0.05140058323740959,
        0.07076902687549591,
        -0.05583563819527626,
        -0.0517699271440506,
        0.03702039271593094,
        -0.0379180833697319,
        -0.018924782052636147,
        -0.03404927998781204,
        -0.04053160175681114,
        -0.05412598326802254,
        -0.024618932977318764,
        -0.06760281324386597,
        0.049109384417533875,
        0.05462952330708504,
        -0.06466526538133621,
        0.027721421793103218,
        0.06235310062766075,
        0.031824253499507904,
        0.054156456142663956,
        -0.04313597083091736,
        -0.007615875452756882,
        0.045484770089387894,
        0.051327142864465714,
        0.060143448412418365,
        -0.06205294653773308,
        0.0569193996489048,
        0.05953109636902809,
        -0.060723867267370224,
        0.05210987478494644,
        0.0646636113524437,
        -0.010106844827532768,
        -0.06775384396314621,
        0.036876432597637177,
        -0.06423796713352203,
        0.04345208778977394,
        -0.06659131497144699,
        0.030248494818806648,
        -0.02295728027820587,
        -0.06021597236394882,
        0.02873830497264862,
        -0.03758027404546738,
        -0.023162109777331352,
        0.012665772810578346,
        -0.028839990496635437,
        -0.054978497326374054,
        0.03092501126229763,
        -0.06204437464475632,
        0.03684491291642189,
        -0.03834683448076248,
        -0.038680050522089005,
        0.031139159575104713,
        0.04319039732217789,
        0.0030556784477084875,
        0.05801158770918846,
        -0.04109705984592438,
        -0.028755834326148033,
        -0.030832402408123016,
        -0.04137590155005455,
        -0.025934258475899696,
        -0.008130320347845554,
        0.053192004561424255,
        -0.0530814565718174,
        -0.06570086628198624,
        0.06673280149698257,
        0.0030856369994580746,
        -0.01978917233645916,
        0.06394432485103607,
        -0.04689329117536545,
        0.05269452929496765,
        -0.03249143809080124,
        0.06936581432819366,
        -0.07021653652191162,
        -0.018413325771689415,
        0.009716127999126911,
        0.042885005474090576,
        0.00385669176466763,
        0.03214395418763161,
        -0.06494119763374329,
        0.06902751326560974,
        0.02753804810345173,
        0.004027001094073057,
        -0.003974230028688908,
        -0.017696281895041466,
        -0.03464117273688316,
        0.053733669221401215,
        -0.05820538103580475,
        0.0030847990419715643,
        0.04453466832637787,
        -0.06495846062898636,
        0.056197766214609146,
        0.004188659600913525,
        0.0010511279106140137,
        0.010637865401804447,
        -0.007745213806629181,
        -0.05614800006151199,
        0.0660439282655716,
        -0.037195511162281036,
        -0.008088203147053719,
        -0.06631158292293549,
        0.027676789090037346,
        0.041536539793014526,
        0.014988454058766365,
        0.04727315902709961,
        -0.04697492718696594,
        -0.015463615767657757,
        -0.04470580443739891,
        0.07283153384923935,
        0.05208732560276985,
        0.030729878693819046,
        0.016618914902210236,
        -0.0637345016002655,
        -0.06442385911941528,
        0.044273171573877335,
        0.0717238038778305,
        -0.003669377416372299,
        -0.03131875768303871,
        -0.06961921602487564,
        -0.015926044434309006,
        -0.04434049129486084,
        -0.0651305690407753,
        -0.024370141327381134,
        -0.026399021968245506,
        -0.02405741810798645,
        -0.04116728529334068,
        0.053854044526815414,
        0.06285588443279266,
        0.021140458062291145,
        -0.010909934528172016,
        -0.05714689567685127,
        0.028228826820850372,
        0.008551755920052528,
        0.008955545723438263,
        -0.07106051594018936,
        -0.048520974814891815,
        0.050713911652565,
        0.009289861656725407,
        -0.035484328866004944,
        0.023334208875894547,
        0.0013233172940090299,
        -0.031800732016563416,
        -0.06315136700868607,
        0.035983603447675705,
        -0.04903349652886391,
        0.05822120979428291,
        -0.04299887269735336,
        -0.033940281718969345,
        -0.061263132840394974,
        0.04428783431649208,
        0.019081437960267067,
        -0.010767950676381588,
        -0.015117243863642216,
        0.037332501262426376,
        -0.03792830556631088,
        0.03346005827188492,
        0.05683314427733421,
        0.019018350169062614,
        -0.005840708501636982,
        -0.025263097137212753,
        -0.056799910962581635,
        0.06418971717357635,
        -0.03691011667251587,
        -0.04987441003322601,
        0.011497232131659985,
        0.039231885224580765,
        -0.04330567643046379,
        -0.04752518609166145,
        0.004424089565873146,
        -0.020314568653702736,
        -0.03745177388191223,
        0.0459408313035965,
        0.059602152556180954,
        0.050087347626686096,
        0.015613222494721413,
        0.03194671869277954,
        0.0048653194680809975,
        0.03288809210062027,
        -0.021095963194966316,
        0.06622184813022614,
        0.02027619071304798,
        -0.05096711963415146,
        0.007290739566087723,
        0.0051065534353256226,
        -0.031251825392246246,
        0.03192415460944176,
        -0.03746231645345688,
        0.05737445503473282,
        0.027087673544883728,
        -0.022650351747870445,
        0.05401439592242241,
        -0.035899560898542404,
        -0.016928531229496002,
        -0.06681079417467117,
        0.0010984275722876191,
        0.028535468503832817,
        0.06890582293272018,
        -0.0055651795119047165,
        0.05103841796517372,
        -0.05672842636704445,
        -0.06399708986282349,
        0.02640606090426445,
        0.06029577925801277,
        -0.025994393974542618,
        -0.04214238002896309,
        -0.06676412373781204,
        -0.05706912279129028,
        0.052460864186286926,
        0.03127056732773781,
        0.011253549717366695,
        -0.043872032314538956,
        -0.03019026853144169,
        -0.010993655771017075,
        -0.04564332589507103,
        0.014207380823791027,
        0.056916020810604095,
        -0.018054423853754997,
        0.03437744453549385,
        0.03848303481936455,
        -0.05649653822183609,
        0.014031908474862576,
        -0.062150921672582626,
        -0.022805899381637573,
        -0.03260796517133713,
        -0.007605888415127993,
        -0.0058354577049613,
        -0.01820686087012291,
        0.05163649097084999,
        -0.01030837744474411,
        0.052093300968408585,
        0.07051707059144974,
        -0.061778221279382706,
        -0.04033501446247101,
        -0.04718384891748428,
        -0.00004020073538413271,
        0.013134309090673923,
        -0.00968126766383648,
        0.07092314213514328,
        0.028842387720942497,
        -0.056779466569423676,
        -0.06655140221118927,
        0.027651170268654823,
        0.06382635235786438
    ],
    [
        -0.03051714040338993,
        0.06496024131774902,
        0.009771051816642284,
        0.03845323994755745,
        0.01636485755443573,
        0.0700182244181633,
        0.0207675788551569,
        -0.05212083458900452,
        0.06251449882984161,
        0.025031523779034615,
        0.03775345906615257,
        -0.03135368227958679,
        -0.03253474459052086,
        -0.018471205607056618,
        -0.03908044844865799,
        0.06973627954721451,
        -0.03360125795006752,
        0.06953554600477219,
        -0.06048768386244774,
        -0.07246784120798111,
        -0.03293530270457268,
        0.06488829851150513,
        0.019738029688596725,
        0.021977443248033524,
        0.047885142266750336,
        0.044691525399684906,
        0.056347936391830444,
        -0.023438407108187675,
        0.01448052003979683,
        0.020022882148623466,
        0.038569796830415726,
        -0.032800912857055664,
        -0.05769507959485054,
        -0.06625715643167496,
        0.05336269363760948,
        -0.015305718407034874,
        -0.06384030729532242,
        0.007570828311145306,
        0.0009456661064177752,
        0.003435613354668021,
        0.06353303045034409,
        0.013914783485233784,
        0.0196564681828022,
        -0.0709158405661583,
        0.06628219783306122,
        0.035205814987421036,
        -0.005745254456996918,
        0.04740959778428078,
        0.028096040710806847,
        0.049183547496795654,
        0.04509672895073891,
        0.06952939927577972,
        -0.03828125074505806,
        0.05907721072435379,
        0.028121914714574814,
        -0.007943334989249706,
        0.031723637133836746,
        0.06401286274194717,
        -0.022613566368818283,
        0.00494538526982069,
        -0.05591541901230812,
        0.06257389485836029,
        -0.039562635123729706,
        0.0652066320180893,
        -0.06702302396297455,
        -0.06002644822001457,
        0.032757144421339035,
        -0.008874391205608845,
        -0.06191517412662506,
        -0.018567677587270737,
        -0.0669957622885704,
        -0.06946954876184464,
        0.06328566372394562,
        0.04156077280640602,
        -0.015889806672930717,
        -0.022023912519216537,
        -0.0310331042855978,
        0.07198917865753174,
        0.06381329894065857,
        -0.007571427617222071,
        0.031119780614972115,
        0.0702553242444992,
        -0.05341487377882004,
        0.03818950057029724,
        0.056871525943279266,
        0.011205730959773064,
        0.07233438640832901,
        0.06235089898109436,
        0.04173508286476135,
        0.01702536828815937,
        0.057890649884939194,
        -0.03973143920302391,
        -0.0693618431687355,
        -0.028072502464056015,
        -0.02693510428071022,
        -0.013994304463267326,
        -0.007631767075508833,
        -0.06470384448766708,
        -0.02313561551272869,
        -0.009253408759832382,
        -0.06750348955392838,
        -0.0002209516242146492,
        0.059126585721969604,
        -0.0046889884397387505,
        0.05238926038146019,
        -0.06680002063512802,
        -0.05500315502285957,
        0.007213616743683815,
        0.024755094200372696,
        -0.0012975068530067801,
        -0.060717880725860596,
        -0.049723125994205475,
        -0.06388440728187561,
        0.0018069421639665961,
        -0.018389439210295677,
        0.014296813867986202,
        0.027367398142814636,
        -0.021514954045414925,
        0.07005757838487625,
        0.025165028870105743,
        -0.0558922216296196,
        0.04894450306892395,
        0.036195605993270874,
        -0.052347052842378616,
        -0.06240520998835564,
        -0.011009369976818562,
        -0.06209023296833038,
        -0.016673201695084572,
        0.06811009347438812,
        0.04272580146789551,
        -0.028531216084957123,
        -0.051763229072093964,
        -0.05208200961351395,
        0.017778005450963974,
        -0.04788374528288841,
        -0.047506608068943024,
        0.004328331910073757,
        -0.007980210706591606,
        -0.062040627002716064,
        0.038991715759038925,
        0.06249238923192024,
        0.005521785002201796,
        0.05991191416978836,
        0.06285808235406876,
        0.054330013692379,
        -0.07155779004096985,
        0.0655607357621193,
        -0.06459388136863708,
        -0.0005987109034322202,
        0.06164038926362991,
        0.03825564309954643,
        -0.06642124056816101,
        -0.009250572882592678,
        -0.06835316121578217,
        -0.0010050397831946611,
        -0.023757047951221466,
        0.02487124875187874,
        0.03558832406997681,
        -0.01844710297882557,
        0.046914830803871155,
        0.07294069975614548,
        0.03912010416388512,
        0.04653659835457802,
        0.0057020410895347595,
        -0.0005529042100533843,
        0.03656656667590141,
        -0.05667744576931,
        -0.034886475652456284,
        0.03947015479207039,
        0.009456923231482506,
        0.04756864160299301,
        0.0017142686992883682,
        -0.028858300298452377,
        -0.05177712440490723,
        0.014200238510966301,
        0.05924854055047035,
        -0.06337592005729675,
        -0.02362438663840294,
        0.06095137074589729,
        -0.035135526210069656,
        -0.004313027020543814,
        0.07300388813018799,
        -0.007913026958703995,
        0.032530877739191055,
        0.04645431786775589,
        -0.044652942568063736,
        -0.021425234153866768,
        0.06275475770235062,
        -0.03755471855401993,
        -0.0339529812335968,
        -0.04793425649404526,
        0.018426112830638885,
        0.05053739994764328,
        0.006148229818791151,
        -0.006253507453948259,
        0.026030873879790306,
        -0.04398243501782417,
        -0.07215740531682968,
        -0.01609949953854084,
        -0.014831592328846455,
        0.029795587062835693,
        -0.05641739442944527,
        0.029300469905138016,
        -0.04969600588083267,
        -0.07048501819372177,
        0.047253359109163284,
        -0.037989791482686996,
        -0.04242192208766937,
        -0.04227156937122345,
        0.06715887784957886,
        -0.06101968511939049,
        0.02851150743663311,
        0.009708533063530922,
        0.05668575316667557,
        -0.023264260962605476,
        0.038930077105760574,
        -0.013211158104240894,
        -0.017840249463915825,
        0.05899123102426529,
        -0.025252128019928932,
        0.04555351287126541,
        0.07001900672912598,
        0.04497150704264641,
        -0.05613774061203003,
        0.05636981502175331,
        -0.015740152448415756,
        -0.00716807134449482,
        0.015417180024087429,
        -0.04377497360110283,
        -0.04481503739953041,
        -0.041639503091573715,
        -0.04222341999411583,
        -0.058769069612026215,
        -0.009889114648103714,
        -0.042165279388427734,
        0.05021743103861809,
        0.0546252503991127,
        -0.03329020366072655,
        -0.04647891968488693,
        0.06254032254219055,
        -0.037275493144989014,
        0.0665353462100029,
        -0.06793306022882462,
        0.055061496794223785,
        0.034800849854946136,
        -0.01006721705198288,
        -0.07080254703760147,
        -0.04085589945316315,
        0.010886874049901962,
        -0.016856810078024864,
        -0.051486313343048096,
        -0.06154157966375351,
        -0.007473642937839031,
        -0.07136987149715424,
        -0.04315441846847534,
        0.052274931222200394,
        -0.046165987849235535,
        -0.050269972532987595,
        0.03827333450317383,
        0.013754701241850853,
        0.07222262024879456,
        -0.019672609865665436,
        -0.02270018495619297,
        0.05169910937547684,
        0.01785113662481308,
        0.05725666508078575,
        -0.04105178266763687,
        0.05007324367761612,
        -0.0012791064800694585,
        0.05350061133503914,
        0.040834661573171616,
        -0.05140058323740959,
        0.07076902687549591,
        -0.05583563819527626,
        -0.0517699271440506,
        0.03702039271593094,
        -0.0379180833697319,
        -0.018924782052636147,
        -0.03404927998781204,
        -0.04053160175681114,
        -0.05412598326802254,
        -0.024618932977318764,
        -0.06760281324386597,
        0.049109384417533875,
        0.05462952330708504,
        -0.06466526538133621,
        0.027721421793103218,
        0.06235310062766075,
        0.031824253499507904,
        0.054156456142663956,
        -0.04313597083091736,
        -0.007615875452756882,
        0.045484770089387894,
        0.051327142864465714,
        0.060143448412418365,
        -0.06205294653773308,
        0.0569193996489048,
        0.05953109636902809,
        -0.060723867267370224,
        0.05210987478494644,
        0.0646636113524437,
        -0.010106844827532768,
        -0.06775384396314621,
        0.036876432597637177,
        -0.06423796713352203,
        0.04345208778977394,
        -0.06659131497144699,
        0.030248494818806648,
        -0.02295728027820587,
        -0.06021597236394882,
        0.02873830497264862,
        -0.03758027404546738,
        -0.023162109777331352,
        0.012665772810578346,
        -0.028839990496635437,
        -0.054978497326374054,
        0.03092501126229763,
        -0.06204437464475632,
        0.03684491291642189,
        -0.03834683448076248,
        -0.038680050522089005,
        0.031139159575104713,
        0.04319039732217789,
        0.0030556784477084875,
        0.05801158770918846,
        -0.04109705984592438,
        -0.028755834326148033,
        -0.030832402408123016,
        -0.04137590155005455,
        -0.025934258475899696,
        -0.008130320347845554,
        0.053192004561424255,
        -0.0530814565718174,
        -0.06570086628198624,
        0.06673280149698257,
        0.0030856369994580746,
        -0.01978917233645916,
        0.06394432485103607,
        -0.04689329117536545,
        0.05269452929496765,
        -0.03249143809080124,
        0.06936581432819366,
        -0.07021653652191162,
        -0.018413325771689415,
        0.009716127999126911,
        0.042885005474090576,
        0.00385669176466763,
        0.03214395418763161,
        -0.06494119763374329,
        0.06902751326560974,
        0.02753804810345173,
        0.004027001094073057,
        -0.003974230028688908,
        -0.017696281895041466,
        -0.03464117273688316,
        0.053733669221401215,
        -0.05820538103580475,
        0.0030847990419715643,
        0.04453466832637787,
        -0.06495846062898636,
        0.056197766214609146,
        0.004188659600913525,
        0.0010511279106140137,
        0.010637865401804447,
        -0.007745213806629181,
        -0.05614800006151199,
        0.0660439282655716,
        -0.037195511162281036,
        -0.008088203147053719,
        -0.06631158292293549,
        0.027676789090037346,
        0.041536539793014526,
        0.014988454058766365,
        0.04727315902709961,
        -0.04697492718696594,
        -0.015463615767657757,
        -0.04470580443739891,
        0.07283153384923935,
        0.05208732560276985,
        0.030729878693819046,
        0.016618914902210236,
        -0.0637345016002655,
        -0.06442385911941528,
        0.044273171573877335,
        0.0717238038778305,
        -0.003669377416372299,
        -0.03131875768303871,
        -0.06961921602487564,
        -0.015926044434309006,
        -0.04434049129486084,
        -0.0651305690407753,
        -0.024370141327381134,
        -0.026399021968245506,
        -0.02405741810798645,
        -0.04116728529334068,
        0.053854044526815414,
        0.06285588443279266,
        0.021140458062291145,
        -0.010909934528172016,
        -0.05714689567685127,
        0.028228826820850372,
        0.008551755920052528,
        0.008955545723438263,
        -0.07106051594018936,
        -0.048520974814891815,
        0.050713911652565,
        0.009289861656725407,
        -0.035484328866004944,
        0.023334208875894547,
        0.0013233172940090299,
        -0.031800732016563416,
        -0.06315136700868607,
        0.035983603447675705,
        -0.04903349652886391,
        0.05822120979428291,
        -0.04299887269735336,
        -0.033940281718969345,
        -0.061263132840394974,
        0.04428783431649208,
        0.019081437960267067,
        -0.010767950676381588,
        -0.015117243863642216,
        0.037332501262426376,
        -0.03792830556631088,
        0.03346005827188492,
        0.05683314427733421,
        0.019018350169062614,
        -0.005840708501636982,
        -0.025263097137212753,
        -0.056799910962581635,
        0.06418971717357635,
        -0.03691011667251587,
        -0.04987441003322601,
        0.011497232131659985,
        0.039231885224580765,
        -0.04330567643046379,
        -0.04752518609166145,
        0.004424089565873146,
        -0.020314568653702736,
        -0.03745177388191223,
        0.0459408313035965,
        0.059602152556180954,
        0.050087347626686096,
        0.015613222494721413,
        0.03194671869277954,
        0.0048653194680809975,
        0.03288809210062027,
        -0.021095963194966316,
        0.06622184813022614,
        0.02027619071304798,
        -0.05096711963415146,
        0.007290739566087723,
        0.0051065534353256226,
        -0.031251825392246246,
        0.03192415460944176,
        -0.03746231645345688,
        0.05737445503473282,
        0.027087673544883728,
        -0.022650351747870445,
        0.05401439592242241,
        -0.035899560898542404,
        -0.016928531229496002,
        -0.06681079417467117,
        0.0010984275722876191,
        0.028535468503832817,
        0.06890582293272018,
        -0.0055651795119047165,
        0.05103841796517372,
        -0.05672842636704445,
        -0.06399708986282349,
        0.02640606090426445,
        0.06029577925801277,
        -0.025994393974542618,
        -0.04214238002896309,
        -0.06676412373781204,
        -0.05706912279129028,
        0.052460864186286926,
        0.03127056732773781,
        0.011253549717366695,
        -0.043872032314538956,
        -0.03019026853144169,
        -0.010993655771017075,
        -0.04564332589507103,
        0.014207380823791027,
        0.056916020810604095,
        -0.018054423853754997,
        0.03437744453549385,
        0.03848303481936455,
        -0.05649653822183609,
        0.014031908474862576,
        -0.062150921672582626,
        -0.022805899381637573,
        -0.03260796517133713,
        -0.007605888415127993,
        -0.0058354577049613,
        -0.01820686087012291,
        0.05163649097084999,
        -0.01030837744474411,
        0.052093300968408585,
        0.07051707059144974,
        -0.061778221279382706,
        -0.04033501446247101,
        -0.04718384891748428,
        -0.00004020073538413271,
        0.013134309090673923,
        -0.00968126766383648,
        0.07092314213514328,
        0.028842387720942497,
        -0.056779466569423676,
        -0.06655140221118927,
        0.027651170268654823,
        0.06382635235786438
    ],
    [
        -0.038977354764938354,
        0.05942307785153389,
        0.005166784394532442,
        0.038179077208042145,
        0.02159065753221512,
        0.06574852764606476,
        0.027539290487766266,
        -0.053934600204229355,
        0.06111478433012962,
        0.04175109043717384,
        0.043049406260252,
        -0.025325246155261993,
        -0.035372015088796616,
        -0.01465331669896841,
        -0.03636037930846214,
        0.06767250597476959,
        -0.0362006314098835,
        0.06720138341188431,
        -0.059470828622579575,
        -0.07018517702817917,
        -0.020709125325083733,
        0.06580475717782974,
        0.028988495469093323,
        0.02345726452767849,
        0.04792839661240578,
        0.045806556940078735,
        0.06164735183119774,
        -0.03311748430132866,
        0.02188125252723694,
        0.03991016373038292,
        0.028244107961654663,
        -0.021961908787488937,
        -0.058382563292980194,
        -0.0681348517537117,
        0.05459515377879143,
        -0.021350974217057228,
        -0.06378979235887527,
        0.009023144841194153,
        0.017181850969791412,
        0.004211199935525656,
        0.061994753777980804,
        0.013121063821017742,
        0.01117668580263853,
        -0.06701941788196564,
        0.05994705110788345,
        0.037714097648859024,
        0.0013643634738400578,
        0.04681425541639328,
        0.050237733870744705,
        0.042207278311252594,
        0.05557112395763397,
        0.0670306533575058,
        -0.033428650349378586,
        0.05869828164577484,
        0.02192850224673748,
        -0.016303563490509987,
        0.014543721452355385,
        0.06320615112781525,
        -0.002528632991015911,
        -0.006949493661522865,
        -0.05869201198220253,
        0.06271246820688248,
        -0.04382719099521637,
        0.06551097333431244,
        -0.06416288763284683,
        -0.05841383337974548,
        0.02476973459124565,
        -0.01769563928246498,
        -0.06298297643661499,
        -0.011313077062368393,
        -0.06709007918834686,
        -0.06904883682727814,
        0.062091097235679626,
        0.031371284276247025,
        -0.0061895777471363544,
        -0.016217287629842758,
        -0.010514644905924797,
        0.06971216946840286,
        0.06224032863974571,
        -0.017385337501764297,
        0.035478632897138596,
        0.06827648729085922,
        -0.04834778979420662,
        0.053174860775470734,
        0.06287110596895218,
        0.015605403110384941,
        0.06989923119544983,
        0.061903420835733414,
        0.045712146908044815,
        0.017940478399395943,
        0.03438565880060196,
        -0.04568157345056534,
        -0.06947364658117294,
        -0.03560097515583038,
        -0.01802781969308853,
        -0.006810974329710007,
        -0.00962216779589653,
        -0.06447046250104904,
        -0.017338711768388748,
        -0.012864257209002972,
        -0.0663708969950676,
        0.014899080619215965,
        0.05439624935388565,
        -0.0032976828515529633,
        0.05309603735804558,
        -0.06614257395267487,
        -0.04954482987523079,
        0.024444565176963806,
        0.014013983309268951,
        0.020467931404709816,
        -0.06120726466178894,
        -0.04480419680476189,
        -0.0641099363565445,
        0.012162657454609871,
        -0.03074682503938675,
        0.006837985944002867,
        0.045682720839977264,
        -0.0430239774286747,
        0.06826215237379074,
        0.031434882432222366,
        -0.04493462294340134,
        0.05686422064900398,
        0.04117933288216591,
        -0.04336710646748543,
        -0.06001453101634979,
        0.010205984115600586,
        -0.06104869395494461,
        -0.03596539795398712,
        0.0641162097454071,
        0.046282798051834106,
        -0.022092504426836967,
        -0.04377949237823486,
        -0.05122479423880577,
        0.015166491270065308,
        -0.04980127140879631,
        -0.04747709631919861,
        0.021351752802729607,
        0.000732098997104913,
        -0.0621182844042778,
        0.04806077852845192,
        0.06037819758057594,
        0.0006446359329856932,
        0.06007901206612587,
        0.05953917279839516,
        0.05637147277593613,
        -0.06962031871080399,
        0.065982885658741,
        -0.06270450353622437,
        0.02500998228788376,
        0.06274519860744476,
        0.03938471898436546,
        -0.06576407700777054,
        -0.011032888665795326,
        -0.06607820838689804,
        0.01070066075772047,
        -0.02438587322831154,
        0.026670923456549644,
        0.03512592613697052,
        -0.03201409429311752,
        0.05283903703093529,
        0.07036424428224564,
        0.03665829449892044,
        0.03925961256027222,
        -0.012926021590828896,
        -0.01431636605411768,
        0.03599706292152405,
        -0.05089910700917244,
        -0.02340742200613022,
        0.04132736846804619,
        0.012643463909626007,
        0.042230136692523956,
        0.004181978292763233,
        -0.029330505058169365,
        -0.05323126167058945,
        0.001763944048434496,
        0.06368038803339005,
        -0.0626082792878151,
        -0.027569912374019623,
        0.04893018305301666,
        -0.04629217088222504,
        -0.0054066856391727924,
        0.07042911648750305,
        -0.01151853334158659,
        0.04097477346658707,
        0.047230686992406845,
        -0.04525162652134895,
        -0.036027874797582626,
        0.06288117170333862,
        -0.03334607183933258,
        -0.05028402432799339,
        -0.0481405146420002,
        0.02179030142724514,
        0.04907189682126045,
        0.01968926005065441,
        -0.012935406528413296,
        0.012264044024050236,
        -0.04992437735199928,
        -0.06972987949848175,
        -0.030859902501106262,
        -0.018040088936686516,
        0.02838427573442459,
        -0.05664519965648651,
        0.022782854735851288,
        -0.046668313443660736,
        -0.06761268526315689,
        0.05020764470100403,
        -0.038829270750284195,
        -0.03758237510919571,
        -0.04183673858642578,
        0.06671486794948578,
        -0.062363095581531525,
        0.025158856064081192,
        0.009567655622959137,
        0.051889169961214066,
        -0.031878769397735596,
        0.02569187432527542,
        -0.009857776574790478,
        -0.03159763664007187,
        0.06165815517306328,
        -0.035773519426584244,
        0.033319324254989624,
        0.06845559179782867,
        0.038829460740089417,
        -0.05259598046541214,
        0.060897599905729294,
        -0.027869608253240585,
        0.0023260496091097593,
        0.011861342936754227,
        -0.044246140867471695,
        -0.0428728424012661,
        -0.018834132701158524,
        -0.0586477629840374,
        -0.05767418444156647,
        -0.002166781108826399,
        -0.04847157001495361,
        0.05315246433019638,
        0.05611991137266159,
        -0.031397778540849686,
        -0.04478365182876587,
        0.060872968286275864,
        -0.03662729263305664,
        0.06481467932462692,
        -0.06812626123428345,
        0.062157806009054184,
        0.041589219123125076,
        -0.0004642726853489876,
        -0.06747163087129593,
        -0.017979448661208153,
        0.013667406514286995,
        -0.013669954612851143,
        -0.05352109670639038,
        -0.05803823471069336,
        -0.01249872986227274,
        -0.06914974004030228,
        -0.04675234109163284,
        0.04750844091176987,
        -0.045151788741350174,
        -0.058248814195394516,
        0.038297753781080246,
        0.015193285420536995,
        0.06985428929328918,
        -0.012236102484166622,
        -0.02374418079853058,
        0.056334927678108215,
        0.009800841100513935,
        0.05295831710100174,
        -0.030070239678025246,
        0.05187848210334778,
        0.017489569261670113,
        0.055153924971818924,
        0.033252838999032974,
        -0.059404514729976654,
        0.06939763575792313,
        -0.05598057806491852,
        -0.05076341703534126,
        0.01560899056494236,
        -0.03741351142525673,
        -0.017434680834412575,
        -0.051370467990636826,
        -0.0369100421667099,
        -0.06409047544002533,
        -0.013453678227961063,
        -0.06652714312076569,
        0.05482399836182594,
        0.0631825178861618,
        -0.0638461783528328,
        0.019660916179418564,
        0.0632266104221344,
        0.04147624224424362,
        0.05048581212759018,
        -0.045110296458005905,
        -0.013461083173751831,
        0.04601752385497093,
        0.04965147376060486,
        0.060993172228336334,
        -0.060359712690114975,
        0.050282374024391174,
        0.06204334273934364,
        -0.06035364419221878,
        0.05413637310266495,
        0.06509179621934891,
        -0.0018491116352379322,
        -0.06451676785945892,
        0.03774513304233551,
        -0.05975262448191643,
        0.03784867376089096,
        -0.06472166627645493,
        0.030309071764349937,
        -0.02983408421278,
        -0.0577489510178566,
        0.034596484154462814,
        -0.045766592025756836,
        -0.03013535588979721,
        0.017228713259100914,
        -0.017054341733455658,
        -0.05591550096869469,
        0.023948172107338905,
        -0.061242006719112396,
        0.03589445352554321,
        -0.04507916048169136,
        -0.0282107163220644,
        0.015613301657140255,
        0.04043642804026604,
        0.015205628238618374,
        0.058249738067388535,
        -0.04308216646313667,
        -0.02249247021973133,
        -0.01993989758193493,
        -0.03802427649497986,
        -0.03254614397883415,
        0.019866250455379486,
        0.06010382995009422,
        -0.05413373187184334,
        -0.06092270463705063,
        0.06452716886997223,
        0.01643982157111168,
        -0.03814210370182991,
        0.06108250841498375,
        -0.032981034368276596,
        0.04657307639718056,
        -0.028733976185321808,
        0.06609352678060532,
        -0.0671868547797203,
        -0.027222800999879837,
        0.00875186175107956,
        0.043268751353025436,
        0.012126955203711987,
        0.03674286603927612,
        -0.0656423568725586,
        0.06499800086021423,
        0.03928590938448906,
        0.01273493655025959,
        -0.022141534835100174,
        -0.03541845083236694,
        -0.03572285920381546,
        0.056997161358594894,
        -0.06199013814330101,
        -0.019168276339769363,
        0.05336661636829376,
        -0.06484085321426392,
        0.05522149056196213,
        -0.004334575962275267,
        0.025092309340834618,
        0.01695406436920166,
        -0.01595102995634079,
        -0.057816263288259506,
        0.06684229522943497,
        -0.040888454765081406,
        0.0223592147231102,
        -0.06589214503765106,
        0.008371667005121708,
        0.0431189090013504,
        0.0369747169315815,
        0.048198625445365906,
        -0.04681434482336044,
        -0.009600318968296051,
        -0.0459463968873024,
        0.07023944705724716,
        0.055275898426771164,
        0.04159986227750778,
        0.008868043310940266,
        -0.06379874795675278,
        -0.06003982946276665,
        0.034858956933021545,
        0.06810714304447174,
        -0.011548778042197227,
        -0.027524035423994064,
        -0.06548844277858734,
        -0.018223803490400314,
        -0.03533050790429115,
        -0.06796573847532272,
        -0.018678881227970123,
        -0.027406182140111923,
        -0.019698038697242737,
        -0.028525378555059433,
        0.05208651348948479,
        0.06308560073375702,
        0.013581611216068268,
        -0.0076132058165967464,
        -0.05941193178296089,
        0.01629684865474701,
        0.014492846094071865,
        0.008910093456506729,
        -0.0691232681274414,
        -0.04751604422926903,
        0.039927445352077484,
        0.0155515531077981,
        -0.03402417153120041,
        0.034898389130830765,
        -0.008360534906387329,
        -0.024426614865660667,
        -0.05701491981744766,
        0.03343489393591881,
        -0.0341016985476017,
        0.05517514795064926,
        -0.043658897280693054,
        -0.03922351449728012,
        -0.05730020999908447,
        0.041300345212221146,
        0.027632834389805794,
        0.010628284886479378,
        -0.012228582985699177,
        0.04649396985769272,
        -0.03883154317736626,
        0.051278114318847656,
        0.04885555058717728,
        0.005595470778644085,
        0.00862366333603859,
        -0.026702282950282097,
        -0.05262448638677597,
        0.0654415562748909,
        -0.0378267839550972,
        -0.05686982348561287,
        0.00741505017504096,
        0.025670822709798813,
        -0.05008072033524513,
        -0.02211485616862774,
        0.004815308377146721,
        -0.0051889787428081036,
        -0.03458893299102783,
        0.038036592304706573,
        0.06305594742298126,
        0.057391002774238586,
        0.025967219844460487,
        0.02080223709344864,
        -0.0016159717924892902,
        0.028786249458789825,
        -0.036029670387506485,
        0.06388964504003525,
        0.02504030615091324,
        -0.051227569580078125,
        -0.007063558325171471,
        0.025118380784988403,
        -0.02814992517232895,
        0.04214518144726753,
        -0.041620612144470215,
        0.044639185070991516,
        0.03222325071692467,
        -0.026208054274320602,
        0.06081874296069145,
        -0.04562966525554657,
        -0.026030970737338066,
        -0.06646401435136795,
        -0.0010955154430121183,
        0.032766468822956085,
        0.06762108951807022,
        -0.01291279774159193,
        0.051966726779937744,
        -0.06078619137406349,
        -0.0639510229229927,
        0.03793678805232048,
        0.06612352281808853,
        -0.026935523375868797,
        -0.0334668792784214,
        -0.06801652163267136,
        -0.05188168212771416,
        0.04011484235525131,
        0.03656376525759697,
        0.00655215373262763,
        -0.04653327539563179,
        -0.041401010006666183,
        -0.01811300218105316,
        -0.054563309997320175,
        0.010184871964156628,
        0.05092757195234299,
        0.000009748212505655829,
        0.03223321586847305,
        0.025622494518756866,
        -0.052858877927064896,
        -0.016426658257842064,
        -0.058430980890989304,
        -0.021923935040831566,
        -0.03071136400103569,
        -0.0038115086499601603,
        0.014911799691617489,
        -0.012581574730575085,
        0.047770027071237564,
        0.0059167128056287766,
        0.050034888088703156,
        0.06909359991550446,
        -0.0617692805826664,
        -0.041917163878679276,
        -0.04681113362312317,
        -0.026715867221355438,
        0.028549889102578163,
        -0.01599797233939171,
        0.06905727088451385,
        0.02028306946158409,
        -0.05552015081048012,
        -0.06416745483875275,
        0.03872138634324074,
        0.06321018189191818
    ],
    [
        -0.03235303983092308,
        0.06422679871320724,
        0.00849546492099762,
        0.04305848851799965,
        0.023842161521315575,
        0.06980325281620026,
        0.022638900205492973,
        -0.05099467560648918,
        0.06214064732193947,
        0.0328763946890831,
        0.038816969841718674,
        -0.02863091416656971,
        -0.03468049317598343,
        -0.019621461629867554,
        -0.04262857511639595,
        0.06985979527235031,
        -0.037117913365364075,
        0.06842105835676193,
        -0.057527728378772736,
        -0.071927011013031,
        -0.03345629200339317,
        0.06452539563179016,
        0.02378365956246853,
        0.012100402265787125,
        0.04913511499762535,
        0.042400892823934555,
        0.056537140160799026,
        -0.02741910330951214,
        0.00886441208422184,
        0.02442859299480915,
        0.025339528918266296,
        -0.04134969413280487,
        -0.05454947426915169,
        -0.06579829007387161,
        0.053502295166254044,
        -0.027311336249113083,
        -0.0642731711268425,
        -0.0010980069637298584,
        0.03303658962249756,
        0.0027563353069126606,
        0.059246551245450974,
        0.018270913511514664,
        0.02478126250207424,
        -0.07022592425346375,
        0.0646904930472374,
        0.03484518080949783,
        0.01721521094441414,
        0.039703235030174255,
        0.018745537847280502,
        0.049929179251194,
        0.04441094398498535,
        0.0679650604724884,
        -0.0274675190448761,
        0.06141137331724167,
        0.034373074769973755,
        -0.012160452082753181,
        0.025669706985354424,
        0.06565302610397339,
        -0.024318145588040352,
        0.00953802652657032,
        -0.0542607381939888,
        0.06394980102777481,
        -0.04097186401486397,
        0.06316369026899338,
        -0.06529056280851364,
        -0.05952620133757591,
        0.03320001810789108,
        -0.017629534006118774,
        -0.0632781907916069,
        -0.01505060214549303,
        -0.06284318119287491,
        -0.0688633918762207,
        0.06443823128938675,
        0.046824827790260315,
        -0.017188824713230133,
        -0.0267796628177166,
        -0.023815101012587547,
        0.07166212797164917,
        0.06556615978479385,
        -0.002261378103867173,
        0.0391765758395195,
        0.07056804746389389,
        -0.05113693326711655,
        0.04166337475180626,
        0.05688082054257393,
        0.01635633409023285,
        0.07210998237133026,
        0.0623992420732975,
        0.029777780175209045,
        0.025731803849339485,
        0.0488915741443634,
        -0.048898663371801376,
        -0.06923653930425644,
        -0.028464000672101974,
        -0.029768699780106544,
        -0.01121379155665636,
        -0.016539080068469048,
        -0.06556776911020279,
        -0.021253060549497604,
        -0.004306753631681204,
        -0.06887891888618469,
        0.006377969868481159,
        0.0591239295899868,
        -0.00042696582386270165,
        0.06011504679918289,
        -0.06534473598003387,
        -0.05729558691382408,
        0.0021015231031924486,
        0.026272719725966454,
        -0.006445166189223528,
        -0.058556728065013885,
        -0.05460251867771149,
        -0.06520690023899078,
        -0.007962143048644066,
        -0.034598127007484436,
        0.010006061755120754,
        0.02098187990486622,
        -0.021210236474871635,
        0.0690513476729393,
        0.017612755298614502,
        -0.05017247423529625,
        0.04991729557514191,
        0.02792847901582718,
        -0.04882383719086647,
        -0.06351480633020401,
        -0.01591619849205017,
        -0.060629960149526596,
        -0.021498795598745346,
        0.06742726266384125,
        0.03251947835087776,
        -0.04079538956284523,
        -0.05223101004958153,
        -0.057345639914274216,
        0.0329730287194252,
        -0.05329650267958641,
        -0.0496235154569149,
        -0.007471360266208649,
        -0.012704204767942429,
        -0.06334290653467178,
        0.03877866268157959,
        0.05958648398518562,
        0.009174435399472713,
        0.06101482734084129,
        0.06049351394176483,
        0.05322972312569618,
        -0.07040160149335861,
        0.06590158492326736,
        -0.06570432335138321,
        0.005759968888014555,
        0.059115711599588394,
        0.03831033781170845,
        -0.06461019814014435,
        -0.022639015689492226,
        -0.06830838322639465,
        -0.01580783724784851,
        -0.015095758251845837,
        0.024841701611876488,
        0.03609372302889824,
        -0.01070423237979412,
        0.04311496391892433,
        0.07248108834028244,
        0.03909920156002045,
        0.047383662313222885,
        0.016582265496253967,
        0.001468571019358933,
        0.04003247618675232,
        -0.059976670891046524,
        -0.03979146108031273,
        0.041589170694351196,
        0.008509780280292034,
        0.04524773359298706,
        0.014456299133598804,
        -0.023696335032582283,
        -0.05162719264626503,
        -0.00020314806897658855,
        0.0527532733976841,
        -0.06092135235667229,
        -0.0218815915286541,
        0.057919941842556,
        -0.03472503647208214,
        -0.00004908199844066985,
        0.07261546701192856,
        -0.0020921044051647186,
        0.03649189695715904,
        0.046120867133140564,
        -0.043546486645936966,
        -0.022764025256037712,
        0.06311334669589996,
        -0.03878401219844818,
        -0.043964825570583344,
        -0.04622451961040497,
        0.007322350516915321,
        0.05411401018500328,
        -0.004071920178830624,
        -0.0004421509220264852,
        0.030140940099954605,
        -0.036567606031894684,
        -0.07191677391529083,
        -0.016689347103238106,
        -0.021984899416565895,
        0.030575677752494812,
        -0.05851421505212784,
        0.025522595271468163,
        -0.049331553280353546,
        -0.06966706365346909,
        0.04222128912806511,
        -0.02868119440972805,
        -0.035159360617399216,
        -0.045247748494148254,
        0.06584054976701736,
        -0.06105386093258858,
        0.0307915061712265,
        0.02380780130624771,
        0.05944794788956642,
        -0.024146541953086853,
        0.044897060841321945,
        0.005436355713754892,
        -0.01917584054172039,
        0.05946142226457596,
        -0.03449664264917374,
        0.04620109498500824,
        0.07013586163520813,
        0.04303966835141182,
        -0.055772699415683746,
        0.05524517968297005,
        -0.028793642297387123,
        -0.009245540015399456,
        0.004454583860933781,
        -0.044710882008075714,
        -0.047244295477867126,
        -0.03604695573449135,
        -0.04783013463020325,
        -0.056037332862615585,
        -0.00295065063983202,
        -0.0394914448261261,
        0.04689561203122139,
        0.05626392364501953,
        -0.0366668738424778,
        -0.044912535697221756,
        0.06558037549257278,
        -0.03299618139863014,
        0.06496712565422058,
        -0.06962786614894867,
        0.05376406013965607,
        0.03113281913101673,
        -0.009650291875004768,
        -0.06953573226928711,
        -0.04177068918943405,
        0.018024297431111336,
        -0.020820869132876396,
        -0.046284377574920654,
        -0.0633743554353714,
        0.0028310210909694433,
        -0.07126101106405258,
        -0.04146957769989967,
        0.05309855192899704,
        -0.049710437655448914,
        -0.053627900779247284,
        0.0377730168402195,
        0.0008518251706846058,
        0.0719505250453949,
        -0.024343540892004967,
        -0.02126016467809677,
        0.04896862059831619,
        0.017076628282666206,
        0.05646069347858429,
        -0.025573469698429108,
        0.05668826773762703,
        0.005353317596018314,
        0.05661671236157417,
        0.04564916342496872,
        -0.05572632700204849,
        0.06910204887390137,
        -0.05464714393019676,
        -0.04472767189145088,
        0.037590526044368744,
        -0.04181084781885147,
        -0.025099502876400948,
        -0.014002698473632336,
        -0.04063912108540535,
        -0.05406331643462181,
        -0.019600288942456245,
        -0.0667165070772171,
        0.049717385321855545,
        0.05288636311888695,
        -0.0634065568447113,
        0.0153837651014328,
        0.05885183811187744,
        0.03403743728995323,
        0.05460362136363983,
        -0.039143867790699005,
        0.0007567913271486759,
        0.049933284521102905,
        0.05248323082923889,
        0.060753289610147476,
        -0.06298137456178665,
        0.05730915442109108,
        0.05706273019313812,
        -0.05695197358727455,
        0.053574368357658386,
        0.06139233708381653,
        -0.004517558496445417,
        -0.06781864911317825,
        0.03427862003445625,
        -0.06560411304235458,
        0.03888585790991783,
        -0.06703072041273117,
        0.029400363564491272,
        -0.033030495047569275,
        -0.06045227125287056,
        0.030046625062823296,
        -0.040304455906152725,
        -0.026964610442519188,
        0.017628557980060577,
        -0.031154057011008263,
        -0.05290142074227333,
        0.03770938143134117,
        -0.059159476310014725,
        0.03896605968475342,
        -0.03979707881808281,
        -0.03936021775007248,
        0.02578679844737053,
        0.04157056659460068,
        0.001118308980949223,
        0.05726517364382744,
        -0.031059248372912407,
        -0.037265557795763016,
        -0.028105594217777252,
        -0.03876957669854164,
        -0.02742043323814869,
        -0.002791304374113679,
        0.0513472817838192,
        -0.0531424842774868,
        -0.06561793386936188,
        0.06582963466644287,
        0.003395014675334096,
        -0.008448460139334202,
        0.06534590572118759,
        -0.042192891240119934,
        0.04857616871595383,
        -0.028120556846261024,
        0.06859681755304337,
        -0.0704197883605957,
        -0.015859438106417656,
        0.014563455246388912,
        0.042841341346502304,
        0.00033210060792043805,
        0.038258783519268036,
        -0.06401688605546951,
        0.06960940361022949,
        0.021242137998342514,
        0.0004956321208737791,
        -0.006113618146628141,
        -0.009275517426431179,
        -0.02837402932345867,
        0.053216371685266495,
        -0.05928913131356239,
        0.0066102915443480015,
        0.0445026196539402,
        -0.06334298104047775,
        0.05750972405076027,
        0.004202303942292929,
        -0.005682629533112049,
        0.012362481094896793,
        -0.014082388952374458,
        -0.0538390688598156,
        0.06523630023002625,
        -0.04443211108446121,
        -0.01926039718091488,
        -0.06625869870185852,
        0.02399859018623829,
        0.0407472588121891,
        0.010813865810632706,
        0.054684143513441086,
        -0.04558718577027321,
        -0.01206696406006813,
        -0.041616082191467285,
        0.07243014872074127,
        0.05610012263059616,
        0.040005993098020554,
        0.014444304630160332,
        -0.06487920135259628,
        -0.06413683295249939,
        0.042838793247938156,
        0.07105455547571182,
        -0.01690019853413105,
        -0.03070424310863018,
        -0.06951577961444855,
        -0.012846337631344795,
        -0.03876893222332001,
        -0.06485888361930847,
        -0.031181735917925835,
        -0.022519975900650024,
        -0.02515232190489769,
        -0.031553782522678375,
        0.052371472120285034,
        0.06301998347043991,
        0.016482122242450714,
        -0.01260471809655428,
        -0.059605471789836884,
        0.032164908945560455,
        0.003455319907516241,
        0.015501248650252819,
        -0.07126536220312119,
        -0.04118766263127327,
        0.05210219323635101,
        0.006690407637506723,
        -0.026906216517090797,
        0.024667790159583092,
        0.016793588176369667,
        -0.03309780731797218,
        -0.06174509972333908,
        0.03731447458267212,
        -0.042966779321432114,
        0.05531797185540199,
        -0.0467473529279232,
        -0.04033106565475464,
        -0.06250018626451492,
        0.047796327620744705,
        0.03482959792017937,
        -0.02562948688864708,
        -0.009836806915700436,
        0.03597435727715492,
        -0.033907487988471985,
        0.025682350620627403,
        0.05540822818875313,
        0.01977132074534893,
        -0.0012311349855735898,
        -0.03266960754990578,
        -0.05498339235782623,
        0.06269074976444244,
        -0.02922525443136692,
        -0.04864249750971794,
        0.00664884876459837,
        0.0322783887386322,
        -0.0417427159845829,
        -0.05528159439563751,
        0.017372867092490196,
        -0.03136804699897766,
        -0.03468930348753929,
        0.0462263859808445,
        0.0561811625957489,
        0.05478241667151451,
        0.02429652400314808,
        0.018676521256566048,
        0.006470695603638887,
        0.036628011614084244,
        -0.0345810167491436,
        0.06549040973186493,
        0.001680697314441204,
        -0.04703080654144287,
        -0.013501288369297981,
        0.0063422610983252525,
        -0.02294883504509926,
        0.03961792215704918,
        -0.04217774420976639,
        0.053314127027988434,
        0.02469521202147007,
        -0.015483420342206955,
        0.051961928606033325,
        -0.04229377210140228,
        -0.018274130299687386,
        -0.06778377294540405,
        0.0005821710801683366,
        0.024023443460464478,
        0.06819174438714981,
        -0.006493387743830681,
        0.04196694865822792,
        -0.05321475490927696,
        -0.06188381090760231,
        0.016025379300117493,
        0.06128533184528351,
        -0.02144748531281948,
        -0.0457737110555172,
        -0.06460580974817276,
        -0.054186686873435974,
        0.051381807774305344,
        0.036722518503665924,
        0.014385148882865906,
        -0.044629231095314026,
        -0.037572041153907776,
        -0.009127850644290447,
        -0.04814448207616806,
        0.00930930208414793,
        0.05716772377490997,
        -0.010707697831094265,
        0.03611181676387787,
        0.034477829933166504,
        -0.05906588211655617,
        -0.004845574498176575,
        -0.061664145439863205,
        -0.029999636113643646,
        -0.032677438110113144,
        -0.010792684741318226,
        0.01345396600663662,
        -0.010357777588069439,
        0.0556187741458416,
        -0.009660483337938786,
        0.055404312908649445,
        0.07025275379419327,
        -0.0658116489648819,
        -0.03234586492180824,
        -0.042684875428676605,
        -0.00937808770686388,
        0.015482774935662746,
        -0.010161617770791054,
        0.07069365680217743,
        0.03643551468849182,
        -0.05886108800768852,
        -0.06662857532501221,
        0.039078619331121445,
        0.06477842479944229
    ],
    [
        -0.03235303983092308,
        0.06422679871320724,
        0.00849546492099762,
        0.04305848851799965,
        0.023842161521315575,
        0.06980325281620026,
        0.022638900205492973,
        -0.05099467560648918,
        0.06214064732193947,
        0.0328763946890831,
        0.038816969841718674,
        -0.02863091416656971,
        -0.03468049317598343,
        -0.019621461629867554,
        -0.04262857511639595,
        0.06985979527235031,
        -0.037117913365364075,
        0.06842105835676193,
        -0.057527728378772736,
        -0.071927011013031,
        -0.03345629200339317,
        0.06452539563179016,
        0.02378365956246853,
        0.012100402265787125,
        0.04913511499762535,
        0.042400892823934555,
        0.056537140160799026,
        -0.02741910330951214,
        0.00886441208422184,
        0.02442859299480915,
        0.025339528918266296,
        -0.04134969413280487,
        -0.05454947426915169,
        -0.06579829007387161,
        0.053502295166254044,
        -0.027311336249113083,
        -0.0642731711268425,
        -0.0010980069637298584,
        0.03303658962249756,
        0.0027563353069126606,
        0.059246551245450974,
        0.018270913511514664,
        0.02478126250207424,
        -0.07022592425346375,
        0.0646904930472374,
        0.03484518080949783,
        0.01721521094441414,
        0.039703235030174255,
        0.018745537847280502,
        0.049929179251194,
        0.04441094398498535,
        0.0679650604724884,
        -0.0274675190448761,
        0.06141137331724167,
        0.034373074769973755,
        -0.012160452082753181,
        0.025669706985354424,
        0.06565302610397339,
        -0.024318145588040352,
        0.00953802652657032,
        -0.0542607381939888,
        0.06394980102777481,
        -0.04097186401486397,
        0.06316369026899338,
        -0.06529056280851364,
        -0.05952620133757591,
        0.03320001810789108,
        -0.017629534006118774,
        -0.0632781907916069,
        -0.01505060214549303,
        -0.06284318119287491,
        -0.0688633918762207,
        0.06443823128938675,
        0.046824827790260315,
        -0.017188824713230133,
        -0.0267796628177166,
        -0.023815101012587547,
        0.07166212797164917,
        0.06556615978479385,
        -0.002261378103867173,
        0.0391765758395195,
        0.07056804746389389,
        -0.05113693326711655,
        0.04166337475180626,
        0.05688082054257393,
        0.01635633409023285,
        0.07210998237133026,
        0.0623992420732975,
        0.029777780175209045,
        0.025731803849339485,
        0.0488915741443634,
        -0.048898663371801376,
        -0.06923653930425644,
        -0.028464000672101974,
        -0.029768699780106544,
        -0.01121379155665636,
        -0.016539080068469048,
        -0.06556776911020279,
        -0.021253060549497604,
        -0.004306753631681204,
        -0.06887891888618469,
        0.006377969868481159,
        0.0591239295899868,
        -0.00042696582386270165,
        0.06011504679918289,
        -0.06534473598003387,
        -0.05729558691382408,
        0.0021015231031924486,
        0.026272719725966454,
        -0.006445166189223528,
        -0.058556728065013885,
        -0.05460251867771149,
        -0.06520690023899078,
        -0.007962143048644066,
        -0.034598127007484436,
        0.010006061755120754,
        0.02098187990486622,
        -0.021210236474871635,
        0.0690513476729393,
        0.017612755298614502,
        -0.05017247423529625,
        0.04991729557514191,
        0.02792847901582718,
        -0.04882383719086647,
        -0.06351480633020401,
        -0.01591619849205017,
        -0.060629960149526596,
        -0.021498795598745346,
        0.06742726266384125,
        0.03251947835087776,
        -0.04079538956284523,
        -0.05223101004958153,
        -0.057345639914274216,
        0.0329730287194252,
        -0.05329650267958641,
        -0.0496235154569149,
        -0.007471360266208649,
        -0.012704204767942429,
        -0.06334290653467178,
        0.03877866268157959,
        0.05958648398518562,
        0.009174435399472713,
        0.06101482734084129,
        0.06049351394176483,
        0.05322972312569618,
        -0.07040160149335861,
        0.06590158492326736,
        -0.06570432335138321,
        0.005759968888014555,
        0.059115711599588394,
        0.03831033781170845,
        -0.06461019814014435,
        -0.022639015689492226,
        -0.06830838322639465,
        -0.01580783724784851,
        -0.015095758251845837,
        0.024841701611876488,
        0.03609372302889824,
        -0.01070423237979412,
        0.04311496391892433,
        0.07248108834028244,
        0.03909920156002045,
        0.047383662313222885,
        0.016582265496253967,
        0.001468571019358933,
        0.04003247618675232,
        -0.059976670891046524,
        -0.03979146108031273,
        0.041589170694351196,
        0.008509780280292034,
        0.04524773359298706,
        0.014456299133598804,
        -0.023696335032582283,
        -0.05162719264626503,
        -0.00020314806897658855,
        0.0527532733976841,
        -0.06092135235667229,
        -0.0218815915286541,
        0.057919941842556,
        -0.03472503647208214,
        -0.00004908199844066985,
        0.07261546701192856,
        -0.0020921044051647186,
        0.03649189695715904,
        0.046120867133140564,
        -0.043546486645936966,
        -0.022764025256037712,
        0.06311334669589996,
        -0.03878401219844818,
        -0.043964825570583344,
        -0.04622451961040497,
        0.007322350516915321,
        0.05411401018500328,
        -0.004071920178830624,
        -0.0004421509220264852,
        0.030140940099954605,
        -0.036567606031894684,
        -0.07191677391529083,
        -0.016689347103238106,
        -0.021984899416565895,
        0.030575677752494812,
        -0.05851421505212784,
        0.025522595271468163,
        -0.049331553280353546,
        -0.06966706365346909,
        0.04222128912806511,
        -0.02868119440972805,
        -0.035159360617399216,
        -0.045247748494148254,
        0.06584054976701736,
        -0.06105386093258858,
        0.0307915061712265,
        0.02380780130624771,
        0.05944794788956642,
        -0.024146541953086853,
        0.044897060841321945,
        0.005436355713754892,
        -0.01917584054172039,
        0.05946142226457596,
        -0.03449664264917374,
        0.04620109498500824,
        0.07013586163520813,
        0.04303966835141182,
        -0.055772699415683746,
        0.05524517968297005,
        -0.028793642297387123,
        -0.009245540015399456,
        0.004454583860933781,
        -0.044710882008075714,
        -0.047244295477867126,
        -0.03604695573449135,
        -0.04783013463020325,
        -0.056037332862615585,
        -0.00295065063983202,
        -0.0394914448261261,
        0.04689561203122139,
        0.05626392364501953,
        -0.0366668738424778,
        -0.044912535697221756,
        0.06558037549257278,
        -0.03299618139863014,
        0.06496712565422058,
        -0.06962786614894867,
        0.05376406013965607,
        0.03113281913101673,
        -0.009650291875004768,
        -0.06953573226928711,
        -0.04177068918943405,
        0.018024297431111336,
        -0.020820869132876396,
        -0.046284377574920654,
        -0.0633743554353714,
        0.0028310210909694433,
        -0.07126101106405258,
        -0.04146957769989967,
        0.05309855192899704,
        -0.049710437655448914,
        -0.053627900779247284,
        0.0377730168402195,
        0.0008518251706846058,
        0.0719505250453949,
        -0.024343540892004967,
        -0.02126016467809677,
        0.04896862059831619,
        0.017076628282666206,
        0.05646069347858429,
        -0.025573469698429108,
        0.05668826773762703,
        0.005353317596018314,
        0.05661671236157417,
        0.04564916342496872,
        -0.05572632700204849,
        0.06910204887390137,
        -0.05464714393019676,
        -0.04472767189145088,
        0.037590526044368744,
        -0.04181084781885147,
        -0.025099502876400948,
        -0.014002698473632336,
        -0.04063912108540535,
        -0.05406331643462181,
        -0.019600288942456245,
        -0.0667165070772171,
        0.049717385321855545,
        0.05288636311888695,
        -0.0634065568447113,
        0.0153837651014328,
        0.05885183811187744,
        0.03403743728995323,
        0.05460362136363983,
        -0.039143867790699005,
        0.0007567913271486759,
        0.049933284521102905,
        0.05248323082923889,
        0.060753289610147476,
        -0.06298137456178665,
        0.05730915442109108,
        0.05706273019313812,
        -0.05695197358727455,
        0.053574368357658386,
        0.06139233708381653,
        -0.004517558496445417,
        -0.06781864911317825,
        0.03427862003445625,
        -0.06560411304235458,
        0.03888585790991783,
        -0.06703072041273117,
        0.029400363564491272,
        -0.033030495047569275,
        -0.06045227125287056,
        0.030046625062823296,
        -0.040304455906152725,
        -0.026964610442519188,
        0.017628557980060577,
        -0.031154057011008263,
        -0.05290142074227333,
        0.03770938143134117,
        -0.059159476310014725,
        0.03896605968475342,
        -0.03979707881808281,
        -0.03936021775007248,
        0.02578679844737053,
        0.04157056659460068,
        0.001118308980949223,
        0.05726517364382744,
        -0.031059248372912407,
        -0.037265557795763016,
        -0.028105594217777252,
        -0.03876957669854164,
        -0.02742043323814869,
        -0.002791304374113679,
        0.0513472817838192,
        -0.0531424842774868,
        -0.06561793386936188,
        0.06582963466644287,
        0.003395014675334096,
        -0.008448460139334202,
        0.06534590572118759,
        -0.042192891240119934,
        0.04857616871595383,
        -0.028120556846261024,
        0.06859681755304337,
        -0.0704197883605957,
        -0.015859438106417656,
        0.014563455246388912,
        0.042841341346502304,
        0.00033210060792043805,
        0.038258783519268036,
        -0.06401688605546951,
        0.06960940361022949,
        0.021242137998342514,
        0.0004956321208737791,
        -0.006113618146628141,
        -0.009275517426431179,
        -0.02837402932345867,
        0.053216371685266495,
        -0.05928913131356239,
        0.0066102915443480015,
        0.0445026196539402,
        -0.06334298104047775,
        0.05750972405076027,
        0.004202303942292929,
        -0.005682629533112049,
        0.012362481094896793,
        -0.014082388952374458,
        -0.0538390688598156,
        0.06523630023002625,
        -0.04443211108446121,
        -0.01926039718091488,
        -0.06625869870185852,
        0.02399859018623829,
        0.0407472588121891,
        0.010813865810632706,
        0.054684143513441086,
        -0.04558718577027321,
        -0.01206696406006813,
        -0.041616082191467285,
        0.07243014872074127,
        0.05610012263059616,
        0.040005993098020554,
        0.014444304630160332,
        -0.06487920135259628,
        -0.06413683295249939,
        0.042838793247938156,
        0.07105455547571182,
        -0.01690019853413105,
        -0.03070424310863018,
        -0.06951577961444855,
        -0.012846337631344795,
        -0.03876893222332001,
        -0.06485888361930847,
        -0.031181735917925835,
        -0.022519975900650024,
        -0.02515232190489769,
        -0.031553782522678375,
        0.052371472120285034,
        0.06301998347043991,
        0.016482122242450714,
        -0.01260471809655428,
        -0.059605471789836884,
        0.032164908945560455,
        0.003455319907516241,
        0.015501248650252819,
        -0.07126536220312119,
        -0.04118766263127327,
        0.05210219323635101,
        0.006690407637506723,
        -0.026906216517090797,
        0.024667790159583092,
        0.016793588176369667,
        -0.03309780731797218,
        -0.06174509972333908,
        0.03731447458267212,
        -0.042966779321432114,
        0.05531797185540199,
        -0.0467473529279232,
        -0.04033106565475464,
        -0.06250018626451492,
        0.047796327620744705,
        0.03482959792017937,
        -0.02562948688864708,
        -0.009836806915700436,
        0.03597435727715492,
        -0.033907487988471985,
        0.025682350620627403,
        0.05540822818875313,
        0.01977132074534893,
        -0.0012311349855735898,
        -0.03266960754990578,
        -0.05498339235782623,
        0.06269074976444244,
        -0.02922525443136692,
        -0.04864249750971794,
        0.00664884876459837,
        0.0322783887386322,
        -0.0417427159845829,
        -0.05528159439563751,
        0.017372867092490196,
        -0.03136804699897766,
        -0.03468930348753929,
        0.0462263859808445,
        0.0561811625957489,
        0.05478241667151451,
        0.02429652400314808,
        0.018676521256566048,
        0.006470695603638887,
        0.036628011614084244,
        -0.0345810167491436,
        0.06549040973186493,
        0.001680697314441204,
        -0.04703080654144287,
        -0.013501288369297981,
        0.0063422610983252525,
        -0.02294883504509926,
        0.03961792215704918,
        -0.04217774420976639,
        0.053314127027988434,
        0.02469521202147007,
        -0.015483420342206955,
        0.051961928606033325,
        -0.04229377210140228,
        -0.018274130299687386,
        -0.06778377294540405,
        0.0005821710801683366,
        0.024023443460464478,
        0.06819174438714981,
        -0.006493387743830681,
        0.04196694865822792,
        -0.05321475490927696,
        -0.06188381090760231,
        0.016025379300117493,
        0.06128533184528351,
        -0.02144748531281948,
        -0.0457737110555172,
        -0.06460580974817276,
        -0.054186686873435974,
        0.051381807774305344,
        0.036722518503665924,
        0.014385148882865906,
        -0.044629231095314026,
        -0.037572041153907776,
        -0.009127850644290447,
        -0.04814448207616806,
        0.00930930208414793,
        0.05716772377490997,
        -0.010707697831094265,
        0.03611181676387787,
        0.034477829933166504,
        -0.05906588211655617,
        -0.004845574498176575,
        -0.061664145439863205,
        -0.029999636113643646,
        -0.032677438110113144,
        -0.010792684741318226,
        0.01345396600663662,
        -0.010357777588069439,
        0.0556187741458416,
        -0.009660483337938786,
        0.055404312908649445,
        0.07025275379419327,
        -0.0658116489648819,
        -0.03234586492180824,
        -0.042684875428676605,
        -0.00937808770686388,
        0.015482774935662746,
        -0.010161617770791054,
        0.07069365680217743,
        0.03643551468849182,
        -0.05886108800768852,
        -0.06662857532501221,
        0.039078619331121445,
        0.06477842479944229
    ],
    [
        -0.029918037354946136,
        0.05622892826795578,
        0.005947972182184458,
        0.03877587616443634,
        0.02946343831717968,
        0.06559954583644867,
        0.02066188119351864,
        -0.05046948790550232,
        0.0607624389231205,
        0.041848454624414444,
        0.04368553310632706,
        -0.016603317111730576,
        -0.03597728908061981,
        -0.022456681355834007,
        -0.04090998321771622,
        0.06718970835208893,
        -0.03975546360015869,
        0.06606882810592651,
        -0.05413401871919632,
        -0.06926030665636063,
        -0.016031332314014435,
        0.0653473436832428,
        0.032186493277549744,
        0.011138785630464554,
        0.04709697887301445,
        0.04116015136241913,
        0.061475228518247604,
        -0.036575622856616974,
        0.01352954562753439,
        0.044192809611558914,
        0.016611218452453613,
        -0.03913825750350952,
        -0.05513261258602142,
        -0.06692686676979065,
        0.05444016680121422,
        -0.031955234706401825,
        -0.06363559514284134,
        -0.006839390844106674,
        0.03445587307214737,
        0.0028354281093925238,
        0.06146557256579399,
        0.009249899536371231,
        0.017136266455054283,
        -0.0664198026061058,
        0.0614725798368454,
        0.04143223911523819,
        0.015332121402025223,
        0.04274564981460571,
        0.048836324363946915,
        0.045528311282396317,
        0.05534997582435608,
        0.06618372350931168,
        -0.017423158511519432,
        0.05967632308602333,
        0.03257045894861221,
        -0.021341560408473015,
        0.01316082663834095,
        0.06378687173128128,
        -0.012679344043135643,
        0.0019927320536226034,
        -0.05671650916337967,
        0.0634150505065918,
        -0.044287871569395065,
        0.06455693393945694,
        -0.06243814155459404,
        -0.05945450812578201,
        0.026509512215852737,
        -0.021701788529753685,
        -0.06212592497467995,
        -0.015145199373364449,
        -0.06358074396848679,
        -0.06772086024284363,
        0.060684192925691605,
        0.0381946824491024,
        -0.00968663115054369,
        -0.02088797651231289,
        -0.013579901307821274,
        0.06871680170297623,
        0.06318673491477966,
        -0.014719242230057716,
        0.04035629332065582,
        0.06815358251333237,
        -0.04817100986838341,
        0.05505039170384407,
        0.060490936040878296,
        0.019951339811086655,
        0.06905262172222137,
        0.062279846519231796,
        0.0392550565302372,
        0.02681305818259716,
        0.018136199563741684,
        -0.052200984209775925,
        -0.06865278631448746,
        -0.041820332407951355,
        -0.021449014544487,
        -0.003020995995029807,
        -0.014317646622657776,
        -0.06542645394802094,
        -0.026292787864804268,
        -0.002540864981710911,
        -0.06674695014953613,
        0.022377315908670425,
        0.056651510298252106,
        -0.0019792786333709955,
        0.05870641767978668,
        -0.06548620760440826,
        -0.04946466535329819,
        0.01666908524930477,
        0.015983743593096733,
        0.02330239862203598,
        -0.06030084937810898,
        -0.05540008470416069,
        -0.06346093863248825,
        0.008344022557139397,
        -0.04414710775017738,
        0.009608983993530273,
        0.03769907355308533,
        -0.03172985091805458,
        0.06735730171203613,
        0.025782255455851555,
        -0.04418407753109932,
        0.05683942511677742,
        0.03685881197452545,
        -0.034481488168239594,
        -0.061145879328250885,
        0.0037922151386737823,
        -0.0591481514275074,
        -0.037752266973257065,
        0.06348317861557007,
        0.04012543335556984,
        -0.03469594195485115,
        -0.04535600543022156,
        -0.049530576914548874,
        0.02887457050383091,
        -0.05289040133357048,
        -0.043515756726264954,
        0.01118521485477686,
        0.0025245947763323784,
        -0.06274712830781937,
        0.05006813257932663,
        0.05334003269672394,
        0.007421319372951984,
        0.05931279808282852,
        0.05879322811961174,
        0.05382398143410683,
        -0.06801614165306091,
        0.0647292509675026,
        -0.06146552041172981,
        0.023448757827281952,
        0.061832770705223083,
        0.04787509888410568,
        -0.06508028507232666,
        -0.02032339945435524,
        -0.06492695212364197,
        -0.0019487171666696668,
        -0.02027570642530918,
        0.02104298770427704,
        0.03745374083518982,
        -0.03173264116048813,
        0.05120716616511345,
        0.06942512094974518,
        0.029169470071792603,
        0.03762305900454521,
        0.003254691371694207,
        -0.0026672715321183205,
        0.0427059680223465,
        -0.053851861506700516,
        -0.03427727892994881,
        0.04333001747727394,
        0.009536023251712322,
        0.04575888812541962,
        0.005793356336653233,
        -0.025951705873012543,
        -0.05486888438463211,
        0.0041569313034415245,
        0.06154391169548035,
        -0.060348160564899445,
        -0.025963975116610527,
        0.04792948439717293,
        -0.047852471470832825,
        -0.002887770999222994,
        0.06947676092386246,
        -0.007723939139395952,
        0.03581862896680832,
        0.049850743263959885,
        -0.04584496468305588,
        -0.03223302960395813,
        0.06253519654273987,
        -0.03898587450385094,
        -0.05303692817687988,
        -0.049519121646881104,
        0.011827881447970867,
        0.05263113975524902,
        0.0142500139772892,
        -0.01631273701786995,
        0.015207162126898766,
        -0.04956645891070366,
        -0.06882337480783463,
        -0.028245901688933372,
        -0.025062181055545807,
        0.03591315075755119,
        -0.057815175503492355,
        0.016080845147371292,
        -0.05404025688767433,
        -0.06614366918802261,
        0.05123888701200485,
        -0.03322489559650421,
        -0.03648249804973602,
        -0.045982372015714645,
        0.06726845353841782,
        -0.061808641999959946,
        0.029565943405032158,
        0.02345590852200985,
        0.05459461733698845,
        -0.03813987225294113,
        0.040022533386945724,
        -0.007364354562014341,
        -0.032419122755527496,
        0.06203525513410568,
        -0.03559304028749466,
        0.03677485138177872,
        0.0674237385392189,
        0.04004909470677376,
        -0.053325358778238297,
        0.06151154637336731,
        -0.030065933242440224,
        -0.003573559457436204,
        -0.010380089282989502,
        -0.044330477714538574,
        -0.047875694930553436,
        -0.016231650486588478,
        -0.05892344191670418,
        -0.05562528222799301,
        0.0019719982519745827,
        -0.04802550747990608,
        0.052205249667167664,
        0.05802707001566887,
        -0.027419842779636383,
        -0.039773598313331604,
        0.06144161522388458,
        -0.03598472476005554,
        0.06417644768953323,
        -0.0679171234369278,
        0.057877834886312485,
        0.04067305102944374,
        -0.00932009331882,
        -0.06457950174808502,
        -0.024080485105514526,
        0.017233174294233322,
        -0.008355501107871532,
        -0.04878808185458183,
        -0.059102222323417664,
        0.0004470849235076457,
        -0.06851636618375778,
        -0.042787324637174606,
        0.04963085800409317,
        -0.04807794466614723,
        -0.05711665004491806,
        0.04295259714126587,
        0.009838296100497246,
        0.06888312101364136,
        -0.014387660659849644,
        -0.017024990171194077,
        0.05671094357967377,
        0.017922285944223404,
        0.05440249666571617,
        -0.002312541240826249,
        0.05812760069966316,
        0.017737850546836853,
        0.05464201048016548,
        0.034624870866537094,
        -0.061442308127880096,
        0.06829409301280975,
        -0.053926024585962296,
        -0.04384315386414528,
        0.01957474835216999,
        -0.0430898480117321,
        -0.030768347904086113,
        -0.029841119423508644,
        -0.03800942748785019,
        -0.06219852343201637,
        -0.0032693087123334408,
        -0.0668306052684784,
        0.048787664622068405,
        0.059035465121269226,
        -0.06254597753286362,
        0.01940716989338398,
        0.059869349002838135,
        0.04118695482611656,
        0.051961615681648254,
        -0.04642445594072342,
        0.0006365801091305912,
        0.049109891057014465,
        0.05073356255888939,
        0.06240720674395561,
        -0.060287486761808395,
        0.05418931320309639,
        0.05935721844434738,
        -0.05579054355621338,
        0.05275822430849075,
        0.06238448619842529,
        -0.0029771977569907904,
        -0.06478670984506607,
        0.03470839932560921,
        -0.06262615323066711,
        0.03239063173532486,
        -0.06378588080406189,
        0.03311517834663391,
        -0.03574518859386444,
        -0.055127646774053574,
        0.03960159420967102,
        -0.04706144705414772,
        -0.02985442616045475,
        0.02602482959628105,
        -0.02184271439909935,
        -0.054081473499536514,
        0.03155440837144852,
        -0.05780988559126854,
        0.039127565920352936,
        -0.04781205579638481,
        -0.031945791095495224,
        0.01707824505865574,
        0.0416015088558197,
        0.014955362305045128,
        0.055041223764419556,
        -0.04046909883618355,
        -0.03388991206884384,
        -0.00976273138076067,
        -0.03689982742071152,
        -0.030711550265550613,
        0.01673761196434498,
        0.05678911134600639,
        -0.0525679849088192,
        -0.061756160110235214,
        0.06306736171245575,
        0.019671641290187836,
        -0.03391881287097931,
        0.06197599694132805,
        -0.033526841551065445,
        0.04212380573153496,
        -0.02879791334271431,
        0.06369498372077942,
        -0.06728613376617432,
        -0.02511318027973175,
        0.0009742734837345779,
        0.04526616632938385,
        0.007851152680814266,
        0.04056317359209061,
        -0.06352045387029648,
        0.06535644084215164,
        0.02881627529859543,
        0.0016304189339280128,
        -0.01635044626891613,
        -0.04188056290149689,
        -0.032356299459934235,
        0.05653906986117363,
        -0.05947202071547508,
        -0.004828169476240873,
        0.05175010859966278,
        -0.06526660174131393,
        0.05586542934179306,
        0.007267641369253397,
        0.015168673358857632,
        0.014160715974867344,
        -0.03236401081085205,
        -0.05712441727519035,
        0.0671284943819046,
        -0.04581846296787262,
        0.004102271515876055,
        -0.06554082781076431,
        0.002417950425297022,
        0.0369233600795269,
        0.028408585116267204,
        0.05279552564024925,
        -0.044134411960840225,
        -0.01713196560740471,
        -0.050905145704746246,
        0.06931593269109726,
        0.057950835675001144,
        0.04401455819606781,
        0.0004172932240180671,
        -0.06439728289842606,
        -0.059355296194553375,
        0.03549916297197342,
        0.06759247183799744,
        -0.024790901690721512,
        -0.0231767725199461,
        -0.06449269503355026,
        -0.019090844318270683,
        -0.032166384160518646,
        -0.06733835488557816,
        -0.024034816771745682,
        -0.021628042683005333,
        -0.025229088962078094,
        -0.017015943303704262,
        0.0497436486184597,
        0.06229836121201515,
        0.011804339475929737,
        -0.008849051780998707,
        -0.06105811893939972,
        0.0239160917699337,
        0.006574050057679415,
        0.01725904829800129,
        -0.06858055293560028,
        -0.042627498507499695,
        0.04378240555524826,
        0.010604755021631718,
        -0.03480204567313194,
        0.03653702884912491,
        0.008975720033049583,
        -0.02282215654850006,
        -0.05303966999053955,
        0.04002458229660988,
        -0.031529758125543594,
        0.05234692245721817,
        -0.046782512217760086,
        -0.04407511651515961,
        -0.05699709430336952,
        0.04433491453528404,
        0.034059055149555206,
        -0.004696868825703859,
        0.006804797798395157,
        0.04525069519877434,
        -0.03746208921074867,
        0.05136962607502937,
        0.04740327596664429,
        0.0060605560429394245,
        0.01198598276823759,
        -0.0379939042031765,
        -0.052297648042440414,
        0.06547852605581284,
        -0.03272964060306549,
        -0.05570615082979202,
        -0.005727816838771105,
        0.012580356560647488,
        -0.0464291013777256,
        -0.04610324651002884,
        0.006540107075124979,
        -0.018699316307902336,
        -0.029538612812757492,
        0.04544027894735336,
        0.061563413590192795,
        0.05392427369952202,
        0.03721732273697853,
        0.026271697133779526,
        0.000650934234727174,
        0.03589151054620743,
        -0.04133734107017517,
        0.06418054550886154,
        0.014536255970597267,
        -0.05320451036095619,
        -0.00987196248024702,
        0.02713317796587944,
        -0.015850495547056198,
        0.03980410099029541,
        -0.044502079486846924,
        0.047074608504772186,
        0.025898348540067673,
        -0.01888945885002613,
        0.05938161909580231,
        -0.044649746268987656,
        -0.02093161828815937,
        -0.06676491349935532,
        0.007944826036691666,
        0.02785499393939972,
        0.06681137531995773,
        -0.00787296611815691,
        0.046211645007133484,
        -0.057656195014715195,
        -0.06328248977661133,
        0.031754713505506516,
        0.0654110386967659,
        -0.022872865200042725,
        -0.03809861093759537,
        -0.06668779253959656,
        -0.05015870928764343,
        0.04301641881465912,
        0.042261410504579544,
        0.006891784258186817,
        -0.05028161406517029,
        -0.04639603570103645,
        -0.021652745082974434,
        -0.05224308371543884,
        0.000046206907427404076,
        0.0537797287106514,
        0.0023458560463041067,
        0.039399757981300354,
        0.024555401876568794,
        -0.054724086076021194,
        -0.018562547862529755,
        -0.05829370394349098,
        -0.02725747413933277,
        -0.030974458903074265,
        -0.014572235755622387,
        0.030500633642077446,
        -0.010399988852441311,
        0.05220445990562439,
        0.015479430556297302,
        0.05438781902194023,
        0.06830857694149017,
        -0.06419145315885544,
        -0.0374150276184082,
        -0.04700359329581261,
        -0.024077139794826508,
        0.035867851227521896,
        -0.0131736621260643,
        0.0685599073767662,
        0.02795383892953396,
        -0.055055394768714905,
        -0.065042644739151,
        0.03872299939393997,
        0.059948764741420746
    ],
    [
        -0.03481774777173996,
        0.06523927301168442,
        0.004701825324445963,
        0.045779190957546234,
        0.02079593390226364,
        0.06994853913784027,
        0.022027812898159027,
        -0.05321469530463219,
        0.06340096890926361,
        0.03883569687604904,
        0.04258164390921593,
        -0.021261969581246376,
        -0.034306399524211884,
        -0.019166581332683563,
        -0.04277333989739418,
        0.06944912672042847,
        -0.040740612894296646,
        0.06890268623828888,
        -0.05685014650225639,
        -0.07197631895542145,
        -0.03139840066432953,
        0.06365642696619034,
        0.01953352801501751,
        0.013840324245393276,
        0.049092236906290054,
        0.046485353261232376,
        0.060142096132040024,
        -0.020841622725129128,
        0.003265709150582552,
        0.009653810411691666,
        0.03182625398039818,
        -0.03761136531829834,
        -0.052648961544036865,
        -0.06327545642852783,
        0.05172998830676079,
        -0.01630026288330555,
        -0.06350603699684143,
        0.0019440798787400126,
        0.013006413355469704,
        0.01045957114547491,
        0.059605151414871216,
        0.019078878685832024,
        0.021368607878684998,
        -0.07037878036499023,
        0.06495694816112518,
        0.033989474177360535,
        0.005861964076757431,
        0.041243378072977066,
        0.025579053908586502,
        0.046510785818099976,
        0.045919325202703476,
        0.06784094870090485,
        -0.023530660197138786,
        0.06269680708646774,
        0.04384901002049446,
        -0.0167761892080307,
        0.02872360125184059,
        0.06760973483324051,
        -0.03067232109606266,
        0.007444111164659262,
        -0.05853017419576645,
        0.06314673274755478,
        -0.0392790250480175,
        0.06493460386991501,
        -0.06756886839866638,
        -0.06032494455575943,
        0.030969789251685143,
        -0.014755263924598694,
        -0.06386606395244598,
        -0.009956348687410355,
        -0.06313043087720871,
        -0.06802958995103836,
        0.06449750810861588,
        0.04924021661281586,
        -0.015276952646672726,
        -0.026738082990050316,
        -0.022083699703216553,
        0.07193053513765335,
        0.06680696457624435,
        -0.0009215351892635226,
        0.040990643203258514,
        0.07086052745580673,
        -0.05206910893321037,
        0.035051822662353516,
        0.04838474094867706,
        0.009807669557631016,
        0.07224871963262558,
        0.061796627938747406,
        0.03174988180398941,
        0.026057057082653046,
        0.05258555710315704,
        -0.04420151188969612,
        -0.06867637485265732,
        -0.02721831388771534,
        -0.030214296653866768,
        -0.016418511047959328,
        -0.018565943464636803,
        -0.06495817005634308,
        -0.019984589889645576,
        -0.004358265548944473,
        -0.06882374733686447,
        0.005367575213313103,
        0.06108379364013672,
        0.002304078545421362,
        0.05912654474377632,
        -0.0650152862071991,
        -0.05871361494064331,
        0.0070428792387247086,
        0.026470715180039406,
        -0.0036884730216115713,
        -0.06147846579551697,
        -0.05610671266913414,
        -0.06588500738143921,
        -0.00028405323973856866,
        -0.031150639057159424,
        0.012370205484330654,
        0.015440315008163452,
        -0.023025447502732277,
        0.06855262815952301,
        0.011685931123793125,
        -0.054534368216991425,
        0.04842795059084892,
        0.03366733714938164,
        -0.04248940572142601,
        -0.06359785050153732,
        -0.012535137124359608,
        -0.06357988715171814,
        -0.022717991843819618,
        0.06801988929510117,
        0.033174119889736176,
        -0.04275500774383545,
        -0.05178193375468254,
        -0.05151798948645592,
        0.022801518440246582,
        -0.04917324706912041,
        -0.04714435338973999,
        -0.010662462562322617,
        -0.014236146584153175,
        -0.06430041044950485,
        0.040330663323402405,
        0.0592791847884655,
        0.008932952769100666,
        0.06120852753520012,
        0.06005324423313141,
        0.050423748791217804,
        -0.0701596587896347,
        0.06327411532402039,
        -0.06586896628141403,
        -0.0012154151918366551,
        0.05919625237584114,
        0.041914451867341995,
        -0.06467010080814362,
        -0.013324094004929066,
        -0.06979692727327347,
        -0.0113581083714962,
        -0.02765459194779396,
        0.023086169734597206,
        0.03781573474407196,
        -0.012450775131583214,
        0.04409554973244667,
        0.07265796512365341,
        0.035905782133340836,
        0.04586560279130936,
        0.017149902880191803,
        0.009242008440196514,
        0.03809497505426407,
        -0.06018161401152611,
        -0.040712881833314896,
        0.04446282982826233,
        0.008868938311934471,
        0.05081390589475632,
        0.02226770855486393,
        -0.03197303041815758,
        -0.04967475309967995,
        0.0056456997990608215,
        0.05377526208758354,
        -0.0584077313542366,
        -0.03179333359003067,
        0.05888379365205765,
        -0.042915672063827515,
        0.0005693222628906369,
        0.07276153564453125,
        -0.0109372204169631,
        0.0356827937066555,
        0.044552285224199295,
        -0.04578366503119469,
        -0.019977275282144547,
        0.06245753914117813,
        -0.038441743701696396,
        -0.04269513487815857,
        -0.049035314470529556,
        -0.0027269821148365736,
        0.0534936897456646,
        -0.005873911548405886,
        -0.0028515332378447056,
        0.02903624065220356,
        -0.03363692760467529,
        -0.07196167856454849,
        -0.0028127187397331,
        -0.01494387537240982,
        0.03243154659867287,
        -0.05947021022439003,
        0.02815154381096363,
        -0.0507778599858284,
        -0.06967750936746597,
        0.031393226236104965,
        -0.03177512437105179,
        -0.04008352756500244,
        -0.044588763266801834,
        0.06592636555433273,
        -0.061700087040662766,
        0.03537149354815483,
        0.020383840426802635,
        0.060034506022930145,
        -0.02303142659366131,
        0.04521309956908226,
        -0.0013788643991574645,
        -0.019190561026334763,
        0.056272830814123154,
        -0.03233074024319649,
        0.04382607340812683,
        0.07039301842451096,
        0.029665280133485794,
        -0.056196343153715134,
        0.05653485283255577,
        -0.025135468691587448,
        0.0011666702339425683,
        0.003686541458591819,
        -0.04090102016925812,
        -0.046135131269693375,
        -0.04166681692004204,
        -0.04138801619410515,
        -0.05352037772536278,
        -0.004409225657582283,
        -0.03316618502140045,
        0.051277048885822296,
        0.057702843099832535,
        -0.03799217566847801,
        -0.03859081119298935,
        0.06627552956342697,
        -0.02885211445391178,
        0.06307066231966019,
        -0.06820186972618103,
        0.05171622335910797,
        0.02633562497794628,
        -0.006085504777729511,
        -0.07027129828929901,
        -0.03903111442923546,
        0.022885989397764206,
        -0.02786836214363575,
        -0.040084026753902435,
        -0.06414478272199631,
        -0.0030261327046900988,
        -0.07135798037052155,
        -0.039263587445020676,
        0.05552084743976593,
        -0.0542055182158947,
        -0.054685868322849274,
        0.043287888169288635,
        0.01539504062384367,
        0.07203284651041031,
        -0.031288646161556244,
        -0.028928350657224655,
        0.05280325934290886,
        0.013008030131459236,
        0.05455853417515755,
        -0.018655357882380486,
        0.05114581435918808,
        0.007397536654025316,
        0.05783262848854065,
        0.04354780167341232,
        -0.05425744876265526,
        0.06974832713603973,
        -0.0541648231446743,
        -0.04050646349787712,
        0.03649790212512016,
        -0.04408165439963341,
        -0.02551405504345894,
        -0.0010000920156016946,
        -0.03730279952287674,
        -0.05457136780023575,
        -0.023200195282697678,
        -0.06763947755098343,
        0.05033380538225174,
        0.052345193922519684,
        -0.06300307810306549,
        0.021683786064386368,
        0.059262413531541824,
        0.02931402437388897,
        0.053563863039016724,
        -0.038798101246356964,
        -0.0013424567878246307,
        0.04767449200153351,
        0.05100620537996292,
        0.06058332696557045,
        -0.06248769164085388,
        0.05587439239025116,
        0.061323922127485275,
        -0.04947008192539215,
        0.05396311357617378,
        0.06417723745107651,
        -0.01047628652304411,
        -0.0679352730512619,
        0.03717014566063881,
        -0.0646442398428917,
        0.038174573332071304,
        -0.0679374560713768,
        0.02040099911391735,
        -0.028892051428556442,
        -0.06041410192847252,
        0.02020491287112236,
        -0.04012267664074898,
        -0.028292810544371605,
        0.012146863155066967,
        -0.0326424203813076,
        -0.05512946471571922,
        0.03664566949009895,
        -0.05382794514298439,
        0.04060171917080879,
        -0.03958168253302574,
        -0.042560044676065445,
        0.023520510643720627,
        0.03226182609796524,
        -0.0035109659656882286,
        0.06098364666104317,
        -0.03457481041550636,
        -0.03995872288942337,
        -0.020833617076277733,
        -0.044577449560165405,
        -0.031569939106702805,
        -0.007053531240671873,
        0.04671888053417206,
        -0.05176716297864914,
        -0.06635119765996933,
        0.06539182364940643,
        -0.0037039825692772865,
        -0.013208847492933273,
        0.06479401141405106,
        -0.039897605776786804,
        0.048382822424173355,
        -0.02990730293095112,
        0.06876519322395325,
        -0.07036258280277252,
        -0.01819535531103611,
        0.014672963880002499,
        0.04097346216440201,
        -0.005657990463078022,
        0.03824787214398384,
        -0.06495285034179688,
        0.07006706297397614,
        0.02109169401228428,
        0.0030877324752509594,
        -0.011194479651749134,
        -0.02345362678170204,
        -0.02567439153790474,
        0.049406759440898895,
        -0.057999543845653534,
        0.008237541653215885,
        0.04086204245686531,
        -0.06502139568328857,
        0.05732240155339241,
        0.00808735005557537,
        -0.008881873451173306,
        0.013230990618467331,
        -0.015194862149655819,
        -0.05452121049165726,
        0.06553687900304794,
        -0.038297586143016815,
        -0.01824360154569149,
        -0.06754469871520996,
        0.028875822201371193,
        0.036096930503845215,
        0.009447894990444183,
        0.05758080258965492,
        -0.04992814362049103,
        -0.01108076237142086,
        -0.04365747049450874,
        0.07259488105773926,
        0.05143914371728897,
        0.037609513849020004,
        0.007921169511973858,
        -0.06346102803945541,
        -0.06510105729103088,
        0.044303134083747864,
        0.07062221318483353,
        -0.009234589524567127,
        -0.03773064911365509,
        -0.06944148987531662,
        -0.012284643016755581,
        -0.03955010697245598,
        -0.0651305541396141,
        -0.036015138030052185,
        -0.029787320643663406,
        -0.026307877153158188,
        -0.04112669453024864,
        0.05433996394276619,
        0.06063196808099747,
        0.0277683287858963,
        -0.005902212578803301,
        -0.06060074642300606,
        0.03008986823260784,
        0.004637457430362701,
        0.024846766144037247,
        -0.07151371240615845,
        -0.04220370948314667,
        0.05107257887721062,
        -0.004205306991934776,
        -0.026050733402371407,
        0.028026657178997993,
        0.014025148935616016,
        -0.039514340460300446,
        -0.06167314574122429,
        0.04078781232237816,
        -0.048342347145080566,
        0.055877622216939926,
        -0.04664245992898941,
        -0.04481380432844162,
        -0.06303215026855469,
        0.045175399631261826,
        0.03320140764117241,
        -0.022769488394260406,
        -0.010385708883404732,
        0.036951445043087006,
        -0.032179806381464005,
        0.025840306654572487,
        0.05378754064440727,
        0.015725648030638695,
        -0.0026550372131168842,
        -0.02861185371875763,
        -0.05405747890472412,
        0.06476948410272598,
        -0.03164450824260712,
        -0.04798000678420067,
        -0.00022741638531442732,
        0.03827439993619919,
        -0.04332607612013817,
        -0.05070306733250618,
        0.016057508066296577,
        -0.025425216183066368,
        -0.03487931191921234,
        0.054052554070949554,
        0.05084814503788948,
        0.05664251744747162,
        0.019019847735762596,
        0.03002549521625042,
        0.003678556764498353,
        0.0298276636749506,
        -0.03433580696582794,
        0.06527400761842728,
        0.010744265280663967,
        -0.04620591551065445,
        -0.004260247107595205,
        -0.0009779713582247496,
        -0.022670868784189224,
        0.036217108368873596,
        -0.03826543688774109,
        0.053789615631103516,
        0.03068297728896141,
        -0.012441782280802727,
        0.05072333663702011,
        -0.040240272879600525,
        -0.02583611011505127,
        -0.06716696172952652,
        0.004388086497783661,
        0.019276047125458717,
        0.06860046088695526,
        -0.007610775064677,
        0.0421069860458374,
        -0.054352279752492905,
        -0.06491018086671829,
        0.015612252987921238,
        0.05774884298443794,
        -0.022608285769820213,
        -0.041201114654541016,
        -0.0647420659661293,
        -0.057294197380542755,
        0.049022287130355835,
        0.04204864054918289,
        0.020535919815301895,
        -0.04870058596134186,
        -0.039792198687791824,
        -0.015893083065748215,
        -0.04130322486162186,
        0.015277632512152195,
        0.06015348806977272,
        -0.013163529336452484,
        0.04848458245396614,
        0.0390380322933197,
        -0.05765167623758316,
        0.0006735865608789027,
        -0.06432925909757614,
        -0.03275982663035393,
        -0.027096206322312355,
        -0.012922183610498905,
        0.0003767331945709884,
        -0.01869663968682289,
        0.05498868227005005,
        -0.012602048926055431,
        0.055286623537540436,
        0.07077104598283768,
        -0.06579266488552094,
        -0.030185099691152573,
        -0.03787980601191521,
        -0.009174538776278496,
        0.01918650045990944,
        -0.0011756926542147994,
        0.07094386219978333,
        0.03344906494021416,
        -0.05659909546375275,
        -0.0674523338675499,
        0.039830710738897324,
        0.06325717270374298
    ],
    [
        -0.03481774777173996,
        0.06523927301168442,
        0.004701825324445963,
        0.045779190957546234,
        0.02079593390226364,
        0.06994853913784027,
        0.022027812898159027,
        -0.05321469530463219,
        0.06340096890926361,
        0.03883569687604904,
        0.04258164390921593,
        -0.021261969581246376,
        -0.034306399524211884,
        -0.019166581332683563,
        -0.04277333989739418,
        0.06944912672042847,
        -0.040740612894296646,
        0.06890268623828888,
        -0.05685014650225639,
        -0.07197631895542145,
        -0.03139840066432953,
        0.06365642696619034,
        0.01953352801501751,
        0.013840324245393276,
        0.049092236906290054,
        0.046485353261232376,
        0.060142096132040024,
        -0.020841622725129128,
        0.003265709150582552,
        0.009653810411691666,
        0.03182625398039818,
        -0.03761136531829834,
        -0.052648961544036865,
        -0.06327545642852783,
        0.05172998830676079,
        -0.01630026288330555,
        -0.06350603699684143,
        0.0019440798787400126,
        0.013006413355469704,
        0.01045957114547491,
        0.059605151414871216,
        0.019078878685832024,
        0.021368607878684998,
        -0.07037878036499023,
        0.06495694816112518,
        0.033989474177360535,
        0.005861964076757431,
        0.041243378072977066,
        0.025579053908586502,
        0.046510785818099976,
        0.045919325202703476,
        0.06784094870090485,
        -0.023530660197138786,
        0.06269680708646774,
        0.04384901002049446,
        -0.0167761892080307,
        0.02872360125184059,
        0.06760973483324051,
        -0.03067232109606266,
        0.007444111164659262,
        -0.05853017419576645,
        0.06314673274755478,
        -0.0392790250480175,
        0.06493460386991501,
        -0.06756886839866638,
        -0.06032494455575943,
        0.030969789251685143,
        -0.014755263924598694,
        -0.06386606395244598,
        -0.009956348687410355,
        -0.06313043087720871,
        -0.06802958995103836,
        0.06449750810861588,
        0.04924021661281586,
        -0.015276952646672726,
        -0.026738082990050316,
        -0.022083699703216553,
        0.07193053513765335,
        0.06680696457624435,
        -0.0009215351892635226,
        0.040990643203258514,
        0.07086052745580673,
        -0.05206910893321037,
        0.035051822662353516,
        0.04838474094867706,
        0.009807669557631016,
        0.07224871963262558,
        0.061796627938747406,
        0.03174988180398941,
        0.026057057082653046,
        0.05258555710315704,
        -0.04420151188969612,
        -0.06867637485265732,
        -0.02721831388771534,
        -0.030214296653866768,
        -0.016418511047959328,
        -0.018565943464636803,
        -0.06495817005634308,
        -0.019984589889645576,
        -0.004358265548944473,
        -0.06882374733686447,
        0.005367575213313103,
        0.06108379364013672,
        0.002304078545421362,
        0.05912654474377632,
        -0.0650152862071991,
        -0.05871361494064331,
        0.0070428792387247086,
        0.026470715180039406,
        -0.0036884730216115713,
        -0.06147846579551697,
        -0.05610671266913414,
        -0.06588500738143921,
        -0.00028405323973856866,
        -0.031150639057159424,
        0.012370205484330654,
        0.015440315008163452,
        -0.023025447502732277,
        0.06855262815952301,
        0.011685931123793125,
        -0.054534368216991425,
        0.04842795059084892,
        0.03366733714938164,
        -0.04248940572142601,
        -0.06359785050153732,
        -0.012535137124359608,
        -0.06357988715171814,
        -0.022717991843819618,
        0.06801988929510117,
        0.033174119889736176,
        -0.04275500774383545,
        -0.05178193375468254,
        -0.05151798948645592,
        0.022801518440246582,
        -0.04917324706912041,
        -0.04714435338973999,
        -0.010662462562322617,
        -0.014236146584153175,
        -0.06430041044950485,
        0.040330663323402405,
        0.0592791847884655,
        0.008932952769100666,
        0.06120852753520012,
        0.06005324423313141,
        0.050423748791217804,
        -0.0701596587896347,
        0.06327411532402039,
        -0.06586896628141403,
        -0.0012154151918366551,
        0.05919625237584114,
        0.041914451867341995,
        -0.06467010080814362,
        -0.013324094004929066,
        -0.06979692727327347,
        -0.0113581083714962,
        -0.02765459194779396,
        0.023086169734597206,
        0.03781573474407196,
        -0.012450775131583214,
        0.04409554973244667,
        0.07265796512365341,
        0.035905782133340836,
        0.04586560279130936,
        0.017149902880191803,
        0.009242008440196514,
        0.03809497505426407,
        -0.06018161401152611,
        -0.040712881833314896,
        0.04446282982826233,
        0.008868938311934471,
        0.05081390589475632,
        0.02226770855486393,
        -0.03197303041815758,
        -0.04967475309967995,
        0.0056456997990608215,
        0.05377526208758354,
        -0.0584077313542366,
        -0.03179333359003067,
        0.05888379365205765,
        -0.042915672063827515,
        0.0005693222628906369,
        0.07276153564453125,
        -0.0109372204169631,
        0.0356827937066555,
        0.044552285224199295,
        -0.04578366503119469,
        -0.019977275282144547,
        0.06245753914117813,
        -0.038441743701696396,
        -0.04269513487815857,
        -0.049035314470529556,
        -0.0027269821148365736,
        0.0534936897456646,
        -0.005873911548405886,
        -0.0028515332378447056,
        0.02903624065220356,
        -0.03363692760467529,
        -0.07196167856454849,
        -0.0028127187397331,
        -0.01494387537240982,
        0.03243154659867287,
        -0.05947021022439003,
        0.02815154381096363,
        -0.0507778599858284,
        -0.06967750936746597,
        0.031393226236104965,
        -0.03177512437105179,
        -0.04008352756500244,
        -0.044588763266801834,
        0.06592636555433273,
        -0.061700087040662766,
        0.03537149354815483,
        0.020383840426802635,
        0.060034506022930145,
        -0.02303142659366131,
        0.04521309956908226,
        -0.0013788643991574645,
        -0.019190561026334763,
        0.056272830814123154,
        -0.03233074024319649,
        0.04382607340812683,
        0.07039301842451096,
        0.029665280133485794,
        -0.056196343153715134,
        0.05653485283255577,
        -0.025135468691587448,
        0.0011666702339425683,
        0.003686541458591819,
        -0.04090102016925812,
        -0.046135131269693375,
        -0.04166681692004204,
        -0.04138801619410515,
        -0.05352037772536278,
        -0.004409225657582283,
        -0.03316618502140045,
        0.051277048885822296,
        0.057702843099832535,
        -0.03799217566847801,
        -0.03859081119298935,
        0.06627552956342697,
        -0.02885211445391178,
        0.06307066231966019,
        -0.06820186972618103,
        0.05171622335910797,
        0.02633562497794628,
        -0.006085504777729511,
        -0.07027129828929901,
        -0.03903111442923546,
        0.022885989397764206,
        -0.02786836214363575,
        -0.040084026753902435,
        -0.06414478272199631,
        -0.0030261327046900988,
        -0.07135798037052155,
        -0.039263587445020676,
        0.05552084743976593,
        -0.0542055182158947,
        -0.054685868322849274,
        0.043287888169288635,
        0.01539504062384367,
        0.07203284651041031,
        -0.031288646161556244,
        -0.028928350657224655,
        0.05280325934290886,
        0.013008030131459236,
        0.05455853417515755,
        -0.018655357882380486,
        0.05114581435918808,
        0.007397536654025316,
        0.05783262848854065,
        0.04354780167341232,
        -0.05425744876265526,
        0.06974832713603973,
        -0.0541648231446743,
        -0.04050646349787712,
        0.03649790212512016,
        -0.04408165439963341,
        -0.02551405504345894,
        -0.0010000920156016946,
        -0.03730279952287674,
        -0.05457136780023575,
        -0.023200195282697678,
        -0.06763947755098343,
        0.05033380538225174,
        0.052345193922519684,
        -0.06300307810306549,
        0.021683786064386368,
        0.059262413531541824,
        0.02931402437388897,
        0.053563863039016724,
        -0.038798101246356964,
        -0.0013424567878246307,
        0.04767449200153351,
        0.05100620537996292,
        0.06058332696557045,
        -0.06248769164085388,
        0.05587439239025116,
        0.061323922127485275,
        -0.04947008192539215,
        0.05396311357617378,
        0.06417723745107651,
        -0.01047628652304411,
        -0.0679352730512619,
        0.03717014566063881,
        -0.0646442398428917,
        0.038174573332071304,
        -0.0679374560713768,
        0.02040099911391735,
        -0.028892051428556442,
        -0.06041410192847252,
        0.02020491287112236,
        -0.04012267664074898,
        -0.028292810544371605,
        0.012146863155066967,
        -0.0326424203813076,
        -0.05512946471571922,
        0.03664566949009895,
        -0.05382794514298439,
        0.04060171917080879,
        -0.03958168253302574,
        -0.042560044676065445,
        0.023520510643720627,
        0.03226182609796524,
        -0.0035109659656882286,
        0.06098364666104317,
        -0.03457481041550636,
        -0.03995872288942337,
        -0.020833617076277733,
        -0.044577449560165405,
        -0.031569939106702805,
        -0.007053531240671873,
        0.04671888053417206,
        -0.05176716297864914,
        -0.06635119765996933,
        0.06539182364940643,
        -0.0037039825692772865,
        -0.013208847492933273,
        0.06479401141405106,
        -0.039897605776786804,
        0.048382822424173355,
        -0.02990730293095112,
        0.06876519322395325,
        -0.07036258280277252,
        -0.01819535531103611,
        0.014672963880002499,
        0.04097346216440201,
        -0.005657990463078022,
        0.03824787214398384,
        -0.06495285034179688,
        0.07006706297397614,
        0.02109169401228428,
        0.0030877324752509594,
        -0.011194479651749134,
        -0.02345362678170204,
        -0.02567439153790474,
        0.049406759440898895,
        -0.057999543845653534,
        0.008237541653215885,
        0.04086204245686531,
        -0.06502139568328857,
        0.05732240155339241,
        0.00808735005557537,
        -0.008881873451173306,
        0.013230990618467331,
        -0.015194862149655819,
        -0.05452121049165726,
        0.06553687900304794,
        -0.038297586143016815,
        -0.01824360154569149,
        -0.06754469871520996,
        0.028875822201371193,
        0.036096930503845215,
        0.009447894990444183,
        0.05758080258965492,
        -0.04992814362049103,
        -0.01108076237142086,
        -0.04365747049450874,
        0.07259488105773926,
        0.05143914371728897,
        0.037609513849020004,
        0.007921169511973858,
        -0.06346102803945541,
        -0.06510105729103088,
        0.044303134083747864,
        0.07062221318483353,
        -0.009234589524567127,
        -0.03773064911365509,
        -0.06944148987531662,
        -0.012284643016755581,
        -0.03955010697245598,
        -0.0651305541396141,
        -0.036015138030052185,
        -0.029787320643663406,
        -0.026307877153158188,
        -0.04112669453024864,
        0.05433996394276619,
        0.06063196808099747,
        0.0277683287858963,
        -0.005902212578803301,
        -0.06060074642300606,
        0.03008986823260784,
        0.004637457430362701,
        0.024846766144037247,
        -0.07151371240615845,
        -0.04220370948314667,
        0.05107257887721062,
        -0.004205306991934776,
        -0.026050733402371407,
        0.028026657178997993,
        0.014025148935616016,
        -0.039514340460300446,
        -0.06167314574122429,
        0.04078781232237816,
        -0.048342347145080566,
        0.055877622216939926,
        -0.04664245992898941,
        -0.04481380432844162,
        -0.06303215026855469,
        0.045175399631261826,
        0.03320140764117241,
        -0.022769488394260406,
        -0.010385708883404732,
        0.036951445043087006,
        -0.032179806381464005,
        0.025840306654572487,
        0.05378754064440727,
        0.015725648030638695,
        -0.0026550372131168842,
        -0.02861185371875763,
        -0.05405747890472412,
        0.06476948410272598,
        -0.03164450824260712,
        -0.04798000678420067,
        -0.00022741638531442732,
        0.03827439993619919,
        -0.04332607612013817,
        -0.05070306733250618,
        0.016057508066296577,
        -0.025425216183066368,
        -0.03487931191921234,
        0.054052554070949554,
        0.05084814503788948,
        0.05664251744747162,
        0.019019847735762596,
        0.03002549521625042,
        0.003678556764498353,
        0.0298276636749506,
        -0.03433580696582794,
        0.06527400761842728,
        0.010744265280663967,
        -0.04620591551065445,
        -0.004260247107595205,
        -0.0009779713582247496,
        -0.022670868784189224,
        0.036217108368873596,
        -0.03826543688774109,
        0.053789615631103516,
        0.03068297728896141,
        -0.012441782280802727,
        0.05072333663702011,
        -0.040240272879600525,
        -0.02583611011505127,
        -0.06716696172952652,
        0.004388086497783661,
        0.019276047125458717,
        0.06860046088695526,
        -0.007610775064677,
        0.0421069860458374,
        -0.054352279752492905,
        -0.06491018086671829,
        0.015612252987921238,
        0.05774884298443794,
        -0.022608285769820213,
        -0.041201114654541016,
        -0.0647420659661293,
        -0.057294197380542755,
        0.049022287130355835,
        0.04204864054918289,
        0.020535919815301895,
        -0.04870058596134186,
        -0.039792198687791824,
        -0.015893083065748215,
        -0.04130322486162186,
        0.015277632512152195,
        0.06015348806977272,
        -0.013163529336452484,
        0.04848458245396614,
        0.0390380322933197,
        -0.05765167623758316,
        0.0006735865608789027,
        -0.06432925909757614,
        -0.03275982663035393,
        -0.027096206322312355,
        -0.012922183610498905,
        0.0003767331945709884,
        -0.01869663968682289,
        0.05498868227005005,
        -0.012602048926055431,
        0.055286623537540436,
        0.07077104598283768,
        -0.06579266488552094,
        -0.030185099691152573,
        -0.03787980601191521,
        -0.009174538776278496,
        0.01918650045990944,
        -0.0011756926542147994,
        0.07094386219978333,
        0.03344906494021416,
        -0.05659909546375275,
        -0.0674523338675499,
        0.039830710738897324,
        0.06325717270374298
    ],
    [
        -0.040369369089603424,
        0.05774393305182457,
        0.0018850556807592511,
        0.04493464156985283,
        0.022257879376411438,
        0.06515566259622574,
        0.023888982832431793,
        -0.05157170072197914,
        0.06325294822454453,
        0.04697095602750778,
        0.04741111025214195,
        -0.014595347456634045,
        -0.03106110170483589,
        -0.014651210978627205,
        -0.038669805973768234,
        0.06719934940338135,
        -0.04450890049338341,
        0.06604089587926865,
        -0.0575869120657444,
        -0.06941159069538116,
        -0.014860662631690502,
        0.06456216424703598,
        0.03396774083375931,
        0.011795012280344963,
        0.046816613525152206,
        0.04988807812333107,
        0.06444104760885239,
        -0.028230590745806694,
        0.013723849318921566,
        0.03278208151459694,
        0.03128102049231529,
        -0.029250076040625572,
        -0.05508149042725563,
        -0.06699735671281815,
        0.05509142205119133,
        -0.02524792216718197,
        -0.0636063814163208,
        -0.0037322170101106167,
        -0.0024343174882233143,
        0.012103378772735596,
        0.0633297935128212,
        0.015686992555856705,
        0.0178659837692976,
        -0.06706877052783966,
        0.06209105625748634,
        0.035833656787872314,
        0.01393448282033205,
        0.04527328908443451,
        0.05528108403086662,
        0.036966193467378616,
        0.05352626368403435,
        0.06532077491283417,
        -0.010771248489618301,
        0.06106565520167351,
        0.046589892357587814,
        -0.01754598878324032,
        0.012476688250899315,
        0.06463611871004105,
        -0.016526110470294952,
        0.0031900685280561447,
        -0.05754724144935608,
        0.06199946254491806,
        -0.03993888571858406,
        0.06513647735118866,
        -0.06502902507781982,
        -0.05841981619596481,
        0.02573022060096264,
        -0.013724347576498985,
        -0.06122003123164177,
        -0.01424252800643444,
        -0.06360774487257004,
        -0.06679368764162064,
        0.061035122722387314,
        0.04082069545984268,
        -0.0069661010056734085,
        -0.013606312684714794,
        -0.007845315150916576,
        0.06899715960025787,
        0.0652436688542366,
        -0.015439603477716446,
        0.040800221264362335,
        0.06833288073539734,
        -0.05025043711066246,
        0.04771450534462929,
        0.05345049500465393,
        0.013662048615515232,
        0.06932671368122101,
        0.061839476227760315,
        0.04380505532026291,
        0.02764684334397316,
        0.021396908909082413,
        -0.047917645424604416,
        -0.06856728345155716,
        -0.03876031935214996,
        -0.025154801085591316,
        -0.01630024053156376,
        -0.015391620807349682,
        -0.06561670452356339,
        -0.03180796280503273,
        -0.005229045171290636,
        -0.06579882651567459,
        0.018021950498223305,
        0.057778630405664444,
        0.00873134657740593,
        0.05812995880842209,
        -0.06671014428138733,
        -0.05306952819228172,
        0.022247392684221268,
        0.024722615256905556,
        0.0316832959651947,
        -0.06191284954547882,
        -0.058418646454811096,
        -0.06403733044862747,
        0.022562159225344658,
        -0.039295267313718796,
        0.01750323735177517,
        0.028656983748078346,
        -0.030451135709881783,
        0.06558343023061752,
        0.028775103390216827,
        -0.05032297223806381,
        0.054116349667310715,
        0.036774154752492905,
        -0.03003220073878765,
        -0.06108845770359039,
        0.013421994633972645,
        -0.06218916177749634,
        -0.041588105261325836,
        0.06374826282262802,
        0.04227502644062042,
        -0.0372588224709034,
        -0.041492778807878494,
        -0.043452948331832886,
        0.02349102310836315,
        -0.048390451818704605,
        -0.04068160057067871,
        0.0038251816295087337,
        -0.0051119690760970116,
        -0.06234724819660187,
        0.048429425805807114,
        0.053430747240781784,
        0.0035189299378544092,
        0.05896585062146187,
        0.05978903919458389,
        0.05086034908890724,
        -0.0677722916007042,
        0.06202644854784012,
        -0.06331820785999298,
        0.01406713668256998,
        0.06121467426419258,
        0.04618299752473831,
        -0.06414243578910828,
        -0.006133972201496363,
        -0.0662219449877739,
        0.000973521382547915,
        -0.03158239647746086,
        0.03350166976451874,
        0.04398524761199951,
        -0.029753129929304123,
        0.05608410760760307,
        0.06964759528636932,
        0.020869893953204155,
        0.03919319063425064,
        -0.0055012148804962635,
        -0.005872037261724472,
        0.046226732432842255,
        -0.05758574604988098,
        -0.034671466797590256,
        0.04656039923429489,
        0.008849485777318478,
        0.049756940454244614,
        0.01961279660463333,
        -0.03231990709900856,
        -0.05292215198278427,
        0.00031774313538335264,
        0.06311056017875671,
        -0.06100993975996971,
        -0.03781552612781525,
        0.050926364958286285,
        -0.050689518451690674,
        -0.006382628809660673,
        0.06970633566379547,
        -0.017509961500763893,
        0.03812407702207565,
        0.04626568406820297,
        -0.04509752243757248,
        -0.03240499272942543,
        0.06145698204636574,
        -0.03081950545310974,
        -0.05271982401609421,
        -0.04799006134271622,
        0.004561496898531914,
        0.04939334839582443,
        -0.0055702077224850655,
        -0.019659191370010376,
        0.010992423631250858,
        -0.0512654185295105,
        -0.0689530298113823,
        -0.01654530130326748,
        -0.016446085646748543,
        0.04096290096640587,
        -0.05929096043109894,
        0.022188235074281693,
        -0.04835270345211029,
        -0.06512606143951416,
        0.04227481037378311,
        -0.03575071692466736,
        -0.04220186546444893,
        -0.04798687621951103,
        0.06491436809301376,
        -0.06308045983314514,
        0.02981828898191452,
        0.011298702098429203,
        0.05271322652697563,
        -0.041375622153282166,
        0.0436517708003521,
        0.011536022648215294,
        -0.02947187051177025,
        0.05648239329457283,
        -0.03367973119020462,
        0.03660685569047928,
        0.0676398053765297,
        0.027026411145925522,
        -0.05614296719431877,
        0.05993087589740753,
        -0.027902083471417427,
        0.004668470937758684,
        -0.0027852775529026985,
        -0.035925816744565964,
        -0.04739747568964958,
        -0.015101657249033451,
        -0.055415067821741104,
        -0.054126426577568054,
        -0.002932764822617173,
        -0.043113503605127335,
        0.05406564474105835,
        0.05746372044086456,
        -0.0284324549138546,
        -0.03105962462723255,
        0.06211350858211517,
        -0.039526984095573425,
        0.06407563388347626,
        -0.06712712347507477,
        0.054087430238723755,
        0.03922298923134804,
        -0.003485456807538867,
        -0.06611111015081406,
        -0.010952160693705082,
        0.019973069429397583,
        -0.016465680673718452,
        -0.04100508615374565,
        -0.05978401005268097,
        -0.005000425036996603,
        -0.06861349940299988,
        -0.04336448386311531,
        0.052574049681425095,
        -0.050803910940885544,
        -0.05793162062764168,
        0.04659765586256981,
        0.025186581537127495,
        0.06907474249601364,
        -0.02256721816956997,
        -0.021677160635590553,
        0.05974500626325607,
        0.0037193389143794775,
        0.050829868763685226,
        0.012259172275662422,
        0.0535934641957283,
        0.01982130855321884,
        0.057266078889369965,
        0.03726722672581673,
        -0.062025971710681915,
        0.06847288459539413,
        -0.05500379577279091,
        -0.03797661140561104,
        0.02442174404859543,
        -0.04691241681575775,
        -0.03135738521814346,
        -0.008569085970520973,
        -0.039070840924978256,
        -0.06254502385854721,
        -0.009213988669216633,
        -0.06665337830781937,
        0.05253734812140465,
        0.05610579624772072,
        -0.06138414889574051,
        0.025569740682840347,
        0.05398992449045181,
        0.04286948963999748,
        0.045632895082235336,
        -0.04979117587208748,
        -0.0059477719478309155,
        0.047807250171899796,
        0.048484478145837784,
        0.0602305643260479,
        -0.06013026461005211,
        0.05358745902776718,
        0.06341217458248138,
        -0.04892483726143837,
        0.05185994505882263,
        0.06476853787899017,
        -0.013428430072963238,
        -0.06431238353252411,
        0.03703194856643677,
        -0.061255939304828644,
        0.03786318004131317,
        -0.06528754532337189,
        0.02471831440925598,
        -0.030367020517587662,
        -0.05629074573516846,
        0.03156987577676773,
        -0.04869394749403,
        -0.031319525092840195,
        0.024317633360624313,
        -0.029950454831123352,
        -0.05618848279118538,
        0.024967020377516747,
        -0.05365588515996933,
        0.03937177360057831,
        -0.046674344688653946,
        -0.03493601456284523,
        0.01068536564707756,
        0.03084971010684967,
        -0.002871623495593667,
        0.05869418755173683,
        -0.04333372414112091,
        -0.03445306420326233,
        -0.0023235115222632885,
        -0.04091433808207512,
        -0.039592791348695755,
        0.011392957530915737,
        0.0534682422876358,
        -0.05186575651168823,
        -0.06252925097942352,
        0.06281628459692001,
        0.009243976324796677,
        -0.04063086584210396,
        0.060727670788764954,
        -0.03483111411333084,
        0.04711928218603134,
        -0.028565121814608574,
        0.06454814225435257,
        -0.06711681932210922,
        -0.027544552460312843,
        0.02600531466305256,
        0.04217797890305519,
        0.0032770810648798943,
        0.041280943900346756,
        -0.06515149027109146,
        0.06548049300909042,
        0.024853002279996872,
        0.009045432321727276,
        -0.02322997897863388,
        -0.04560059309005737,
        -0.026607753708958626,
        0.05624549090862274,
        -0.05970662087202072,
        -0.005011344328522682,
        0.05226655676960945,
        -0.06435425579547882,
        0.053488589823246,
        0.008615738712251186,
        0.013216694816946983,
        0.015179536305367947,
        -0.028232324868440628,
        -0.05673820897936821,
        0.0675409808754921,
        -0.029225725680589676,
        0.004520416259765625,
        -0.0669604241847992,
        0.006590546108782291,
        0.039006609469652176,
        0.03144177421927452,
        0.0542541965842247,
        -0.04493057355284691,
        -0.010791345499455929,
        -0.053764790296554565,
        0.06957744061946869,
        0.051306456327438354,
        0.03941516578197479,
        0.002685192506760359,
        -0.06048024445772171,
        -0.05974763259291649,
        0.034461796283721924,
        0.06699342280626297,
        -0.021988460794091225,
        -0.028846891596913338,
        -0.06445977836847305,
        -0.019505733624100685,
        -0.03521627187728882,
        -0.06763047724962234,
        -0.0322590172290802,
        -0.03110419027507305,
        -0.024599503725767136,
        -0.02977483533322811,
        0.05267259478569031,
        0.059529948979616165,
        0.012500174343585968,
        0.0027784386184066534,
        -0.06137875095009804,
        0.025672152638435364,
        0.015497391112148762,
        0.0384090393781662,
        -0.06892908364534378,
        -0.04879138991236687,
        0.042357832193374634,
        -0.0007048530969768763,
        -0.02903648093342781,
        0.03448086977005005,
        -0.005486790556460619,
        -0.030839664861559868,
        -0.05222609266638756,
        0.039070405066013336,
        -0.03941955417394638,
        0.055419471114873886,
        -0.041821252554655075,
        -0.047078993171453476,
        -0.060484979301691055,
        0.04497760534286499,
        0.021667758002877235,
        0.01078123226761818,
        0.003942999057471752,
        0.04468730092048645,
        -0.036605704575777054,
        0.055450189858675,
        0.03485019505023956,
        0.007138424552977085,
        0.02006472460925579,
        -0.03098474070429802,
        -0.05501565337181091,
        0.06582353264093399,
        -0.03484427556395531,
        -0.05591217800974846,
        -0.0011638058349490166,
        0.017724931240081787,
        -0.04796736314892769,
        -0.027045967057347298,
        0.00627279793843627,
        -0.008086921647191048,
        -0.031839024275541306,
        0.05649947002530098,
        0.060048386454582214,
        0.05550363287329674,
        0.029010597616434097,
        0.03372446820139885,
        0.003359953872859478,
        0.033099330961704254,
        -0.04397295415401459,
        0.06388938426971436,
        0.019023839384317398,
        -0.05183859542012215,
        0.00420616427436471,
        0.01888180524110794,
        -0.013572217896580696,
        0.03514249995350838,
        -0.04273458570241928,
        0.04591543227434158,
        0.042447734624147415,
        -0.02380836009979248,
        0.06014073267579079,
        -0.04392050951719284,
        -0.02561037987470627,
        -0.06751813739538193,
        0.016558056697249413,
        0.030130978673696518,
        0.06709082424640656,
        -0.010164663195610046,
        0.0459568165242672,
        -0.05902811884880066,
        -0.06469281762838364,
        0.023724861443042755,
        0.06399773806333542,
        -0.022834988310933113,
        -0.03502083942294121,
        -0.0664713904261589,
        -0.05068265646696091,
        0.03864512965083122,
        0.04587257653474808,
        0.010135742835700512,
        -0.05176498740911484,
        -0.04677436128258705,
        -0.02588547021150589,
        -0.04570913314819336,
        0.001411711797118187,
        0.05803259462118149,
        -0.005611588712781668,
        0.0463896170258522,
        0.031228652223944664,
        -0.0504525788128376,
        -0.004575901664793491,
        -0.06172898784279823,
        -0.03460589796304703,
        -0.015858687460422516,
        -0.01329487469047308,
        0.020192598924040794,
        -0.023280544206500053,
        0.053302258253097534,
        0.006627158261835575,
        0.052744507789611816,
        0.06890831887722015,
        -0.0646626278758049,
        -0.03546376898884773,
        -0.04828110337257385,
        -0.028529440984129906,
        0.034671857953071594,
        -0.004589921794831753,
        0.06897646933794022,
        0.02384093403816223,
        -0.0508652999997139,
        -0.06553356349468231,
        0.03841501474380493,
        0.059209130704402924
    ],
    [
        -0.03859512135386467,
        0.06276005506515503,
        0.002330926712602377,
        0.041105642914772034,
        0.01896422728896141,
        0.06879233568906784,
        0.016579778864979744,
        -0.053600385785102844,
        0.06327876448631287,
        0.03738598898053169,
        0.0405181422829628,
        -0.024556254968047142,
        -0.03542359173297882,
        -0.02421778067946434,
        -0.039946604520082474,
        0.07061594724655151,
        -0.037461381405591965,
        0.06882866472005844,
        -0.05976760759949684,
        -0.07212668657302856,
        -0.03150634467601776,
        0.06505081802606583,
        0.022082414478063583,
        0.013191674835979939,
        0.039988722652196884,
        0.04274258017539978,
        0.060975752770900726,
        -0.02762574516236782,
        -0.004571374971419573,
        0.018866589292883873,
        0.025481846183538437,
        -0.04398617893457413,
        -0.05151762813329697,
        -0.06262916326522827,
        0.04949134215712547,
        -0.011387609876692295,
        -0.06379672139883041,
        0.007733457256108522,
        -0.01726243458688259,
        0.0033626477234065533,
        0.06136748567223549,
        0.01406821608543396,
        0.02129560336470604,
        -0.07022286206483841,
        0.06559819728136063,
        0.03337857127189636,
        0.02375619485974312,
        0.04175673425197601,
        0.013812807388603687,
        0.04935367405414581,
        0.049368418753147125,
        0.06856555491685867,
        -0.02152303420007229,
        0.06492085754871368,
        0.04436461627483368,
        -0.020815176889300346,
        0.034097980707883835,
        0.06710680574178696,
        -0.028796060010790825,
        0.003872975939884782,
        -0.0581171028316021,
        0.0623861588537693,
        -0.04235170781612396,
        0.06556079536676407,
        -0.06806278973817825,
        -0.055895693600177765,
        0.035360123962163925,
        -0.01924639381468296,
        -0.06334210187196732,
        -0.004692643415182829,
        -0.06562057882547379,
        -0.06592510640621185,
        0.06200309097766876,
        0.0511736124753952,
        -0.01171159278601408,
        -0.019574228674173355,
        -0.023112012073397636,
        0.07195069640874863,
        0.06530645489692688,
        -0.012492380104959011,
        0.037489622831344604,
        0.07094084471464157,
        -0.050460085272789,
        0.03505665436387062,
        0.04862947016954422,
        0.01243649609386921,
        0.0722157284617424,
        0.06460624933242798,
        0.043760817497968674,
        0.023465020582079887,
        0.05173869431018829,
        -0.04722866043448448,
        -0.0695975124835968,
        -0.03010307438671589,
        -0.03508279100060463,
        -0.012832156382501125,
        -0.01776554062962532,
        -0.06500298529863358,
        -0.010337983258068562,
        -0.004556328523904085,
        -0.06891708076000214,
        0.006714265793561935,
        0.06169058382511139,
        0.01142739225178957,
        0.0607023760676384,
        -0.06776280701160431,
        -0.05709737166762352,
        0.010545920580625534,
        0.029048383235931396,
        0.007356190588325262,
        -0.061480503529310226,
        -0.055390067398548126,
        -0.06479833275079727,
        0.0021157513838261366,
        -0.029365945607423782,
        0.012926981784403324,
        0.016934752464294434,
        -0.029574023559689522,
        0.06831613928079605,
        0.017896434292197227,
        -0.04928730055689812,
        0.04912091791629791,
        0.03750807046890259,
        -0.04408709704875946,
        -0.06555312126874924,
        -0.01925782673060894,
        -0.06241744011640549,
        -0.031246021389961243,
        0.06733416765928268,
        0.03921297565102577,
        -0.030737703666090965,
        -0.05358199402689934,
        -0.052874304354190826,
        0.0239506047219038,
        -0.04920976981520653,
        -0.04598504677414894,
        -0.01050976850092411,
        -0.0075300633907318115,
        -0.06653014570474625,
        0.041864097118377686,
        0.06004892289638519,
        0.019491352140903473,
        0.06226041540503502,
        0.061352647840976715,
        0.04529646039009094,
        -0.06972096860408783,
        0.06205381080508232,
        -0.06697437912225723,
        0.0031483208294957876,
        0.05866820737719536,
        0.035819634795188904,
        -0.06452823430299759,
        0.00901584979146719,
        -0.06968515366315842,
        -0.0137318791821599,
        -0.016928786411881447,
        0.021509652957320213,
        0.0401281900703907,
        -0.010048695839941502,
        0.039427801966667175,
        0.07264835387468338,
        0.030770303681492805,
        0.048562053591012955,
        0.004158547148108482,
        0.012300269678235054,
        0.035754553973674774,
        -0.06069232150912285,
        -0.041768357157707214,
        0.04416023939847946,
        0.005011599510908127,
        0.05411152541637421,
        0.012073799967765808,
        -0.030936380848288536,
        -0.05210992321372032,
        0.01464839931577444,
        0.05298415198922157,
        -0.05934881791472435,
        -0.025697538629174232,
        0.058070018887519836,
        -0.045369792729616165,
        -0.005680363159626722,
        0.0727677121758461,
        -0.0026395919267088175,
        0.027560392394661903,
        0.04258124530315399,
        -0.046970635652542114,
        -0.018615975975990295,
        0.0635659322142601,
        -0.04001050069928169,
        -0.031003179028630257,
        -0.0454530343413353,
        0.0031420509330928326,
        0.05527413636445999,
        -0.0024691179860383272,
        -0.013926859945058823,
        0.020567381754517555,
        -0.040578559041023254,
        -0.07199911773204803,
        -0.010575545020401478,
        -0.011371237225830555,
        0.02320796065032482,
        -0.057811133563518524,
        0.007078199647367001,
        -0.05406884849071503,
        -0.06931217014789581,
        0.03960058465600014,
        -0.03656959533691406,
        -0.03890689089894295,
        -0.04043098911643028,
        0.06671804189682007,
        -0.0628681555390358,
        0.03580338880419731,
        0.023036472499370575,
        0.06104519963264465,
        -0.027034437283873558,
        0.044283803552389145,
        -0.00015548984811175615,
        -0.025991855189204216,
        0.0599367618560791,
        -0.04128440469503403,
        0.044444113969802856,
        0.07052552700042725,
        0.037929583340883255,
        -0.05536719411611557,
        0.04702555015683174,
        -0.00028153613675385714,
        0.006094132550060749,
        0.003513951553031802,
        -0.044435374438762665,
        -0.04486412554979324,
        -0.046140749007463455,
        -0.023000214248895645,
        -0.056578394025564194,
        0.0002737054892349988,
        -0.02615066058933735,
        0.04997476935386658,
        0.058875590562820435,
        -0.03568248823285103,
        -0.04476538673043251,
        0.06596258282661438,
        -0.02480139024555683,
        0.06454959511756897,
        -0.06742089986801147,
        0.05262121930718422,
        0.032115764915943146,
        -0.0071693058125674725,
        -0.07055971026420593,
        -0.04257795214653015,
        0.026619000360369682,
        -0.024942493066191673,
        -0.045366719365119934,
        -0.06301473826169968,
        -0.008338847197592258,
        -0.07107093185186386,
        -0.04212483391165733,
        0.05641646683216095,
        -0.056669916957616806,
        -0.048395294696092606,
        0.04649604484438896,
        0.005967181641608477,
        0.0719372034072876,
        -0.03205246478319168,
        -0.03012637421488762,
        0.0516192689538002,
        0.005510289687663317,
        0.05351056531071663,
        -0.007388572208583355,
        0.05831650272011757,
        0.012499252334237099,
        0.05858031287789345,
        0.041934601962566376,
        -0.055698540061712265,
        0.06951358914375305,
        -0.050118040293455124,
        -0.04187462851405144,
        0.03537311032414436,
        -0.04396933689713478,
        -0.02874206379055977,
        0.0015610142145305872,
        -0.029625190421938896,
        -0.05572257563471794,
        -0.01782160811126232,
        -0.06785662472248077,
        0.04763369634747505,
        0.05466049909591675,
        -0.06469573825597763,
        0.03052283264696598,
        0.06044933199882507,
        0.028300229460000992,
        0.05121093988418579,
        -0.03733448311686516,
        0.0009149019024334848,
        0.046460215002298355,
        0.05020017921924591,
        0.06237281113862991,
        -0.06392892450094223,
        0.05450183525681496,
        0.0619809590280056,
        -0.05212240293622017,
        0.05241469293832779,
        0.06425701081752777,
        -0.01222321204841137,
        -0.06778541207313538,
        0.03360334783792496,
        -0.06309549510478973,
        0.0326213501393795,
        -0.06789522618055344,
        0.02404981479048729,
        -0.032329194247722626,
        -0.05562034249305725,
        0.021876290440559387,
        -0.04301825910806656,
        -0.03535265475511551,
        0.0128471152856946,
        -0.03126567229628563,
        -0.05488765239715576,
        0.03584608435630798,
        -0.060567792505025864,
        0.040123648941516876,
        -0.037927623838186264,
        -0.03859146684408188,
        0.02266567014157772,
        0.028646808117628098,
        -0.009585521183907986,
        0.05828477814793587,
        -0.03633546084165573,
        -0.036944665014743805,
        -0.0026522837579250336,
        -0.03608371317386627,
        -0.024879122152924538,
        -0.0058510443195700645,
        0.047320976853370667,
        -0.05714774504303932,
        -0.06605880707502365,
        0.06548520922660828,
        0.0001602339034434408,
        -0.018161097541451454,
        0.06252311915159225,
        -0.04189980775117874,
        0.05172225832939148,
        -0.0325869657099247,
        0.06783419847488403,
        -0.07063314318656921,
        -0.014152579009532928,
        0.012275866232812405,
        0.04241252318024635,
        -0.01142335869371891,
        0.033434901386499405,
        -0.06527172774076462,
        0.06941638886928558,
        0.012850276194512844,
        0.004050561226904392,
        -0.011257556267082691,
        -0.027665920555591583,
        -0.019115764647722244,
        0.04784397780895233,
        -0.05993198603391647,
        0.011768833734095097,
        0.04652408882975578,
        -0.0618579164147377,
        0.0560251884162426,
        0.01871582493185997,
        -0.0059018186293542385,
        0.008121349848806858,
        -0.013844890519976616,
        -0.05686010792851448,
        0.06486937403678894,
        -0.03513793647289276,
        -0.02822568640112877,
        -0.06779985874891281,
        0.028603220358490944,
        0.03945013880729675,
        -0.002489664126187563,
        0.05731042101979256,
        -0.04890188202261925,
        -0.012560480274260044,
        -0.043024711310863495,
        0.07259759306907654,
        0.054390836507081985,
        0.04027291759848595,
        -0.000779836205765605,
        -0.06335277855396271,
        -0.06373238563537598,
        0.045221105217933655,
        0.0706288293004036,
        -0.009104734286665916,
        -0.026473913341760635,
        -0.06945043057203293,
        -0.0179336816072464,
        -0.028072496876120567,
        -0.06459526717662811,
        -0.0392330177128315,
        -0.029450228437781334,
        -0.020188843831419945,
        -0.04164526239037514,
        0.05464892461895943,
        0.06360915303230286,
        0.01868215948343277,
        -0.0009180335327982903,
        -0.060111287981271744,
        0.027697918936610222,
        -0.0029461353551596403,
        0.030408412218093872,
        -0.07163218408823013,
        -0.036750245839357376,
        0.050265561789274216,
        0.0031826079357415438,
        -0.02997981756925583,
        0.02285030484199524,
        0.01637786440551281,
        -0.04292989894747734,
        -0.060622502118349075,
        0.03621344640851021,
        -0.0510902963578701,
        0.05589061230421066,
        -0.04861617460846901,
        -0.04853516444563866,
        -0.06629901379346848,
        0.042800288647413254,
        0.019119128584861755,
        -0.011929760687053204,
        -0.01014747004956007,
        0.035019826143980026,
        -0.03496411815285683,
        0.01838354393839836,
        0.058167316019535065,
        0.008143981918692589,
        0.014218529686331749,
        -0.024877605959773064,
        -0.054394643753767014,
        0.06418205052614212,
        -0.029929691925644875,
        -0.047580841928720474,
        0.005353202577680349,
        0.0411912240087986,
        -0.04555744677782059,
        -0.039534084498882294,
        0.023365382105112076,
        -0.01846550963819027,
        -0.04198775067925453,
        0.05115323141217232,
        0.055953558534383774,
        0.052459731698036194,
        0.01735072024166584,
        0.04558708891272545,
        -0.0006783535936847329,
        0.03181692212820053,
        -0.034333158284425735,
        0.06573865562677383,
        0.02305048331618309,
        -0.04505603387951851,
        -0.012544313445687294,
        -0.003091635415330529,
        -0.028644345700740814,
        0.037563033401966095,
        -0.040260229259729385,
        0.054279882460832596,
        0.023916129022836685,
        -0.009410585276782513,
        0.050055235624313354,
        -0.030028093606233597,
        -0.01965043507516384,
        -0.0674065425992012,
        0.019613465294241905,
        0.02195221371948719,
        0.06846365332603455,
        -0.005998402368277311,
        0.04626983031630516,
        -0.05339872092008591,
        -0.06353268772363663,
        0.015620172023773193,
        0.05524210259318352,
        -0.018631910905241966,
        -0.04183044284582138,
        -0.06675876677036285,
        -0.05852417275309563,
        0.05309150367975235,
        0.039095401763916016,
        0.020369522273540497,
        -0.0516958087682724,
        -0.0412055179476738,
        -0.013697125017642975,
        -0.048362381756305695,
        0.01109223160892725,
        0.059708867222070694,
        -0.008294184692203999,
        0.050621990114450455,
        0.03754844143986702,
        -0.05672435835003853,
        -0.00037274861824698746,
        -0.06192869320511818,
        -0.026750730350613594,
        -0.0308209415525198,
        -0.007594617083668709,
        0.004431868437677622,
        -0.015835266560316086,
        0.056828372180461884,
        -0.0036850273609161377,
        0.055102668702602386,
        0.07088872045278549,
        -0.0687762200832367,
        -0.031673088669776917,
        -0.04381241276860237,
        -0.012538046576082706,
        0.03083896078169346,
        -0.007296440191566944,
        0.07063958793878555,
        0.03584102541208267,
        -0.05341017246246338,
        -0.06902485340833664,
        0.025540081784129143,
        0.06175616383552551
    ],
    [
        -0.03859512135386467,
        0.06276005506515503,
        0.002330926712602377,
        0.041105642914772034,
        0.01896422728896141,
        0.06879233568906784,
        0.016579778864979744,
        -0.053600385785102844,
        0.06327876448631287,
        0.03738598898053169,
        0.0405181422829628,
        -0.024556254968047142,
        -0.03542359173297882,
        -0.02421778067946434,
        -0.039946604520082474,
        0.07061594724655151,
        -0.037461381405591965,
        0.06882866472005844,
        -0.05976760759949684,
        -0.07212668657302856,
        -0.03150634467601776,
        0.06505081802606583,
        0.022082414478063583,
        0.013191674835979939,
        0.039988722652196884,
        0.04274258017539978,
        0.060975752770900726,
        -0.02762574516236782,
        -0.004571374971419573,
        0.018866589292883873,
        0.025481846183538437,
        -0.04398617893457413,
        -0.05151762813329697,
        -0.06262916326522827,
        0.04949134215712547,
        -0.011387609876692295,
        -0.06379672139883041,
        0.007733457256108522,
        -0.01726243458688259,
        0.0033626477234065533,
        0.06136748567223549,
        0.01406821608543396,
        0.02129560336470604,
        -0.07022286206483841,
        0.06559819728136063,
        0.03337857127189636,
        0.02375619485974312,
        0.04175673425197601,
        0.013812807388603687,
        0.04935367405414581,
        0.049368418753147125,
        0.06856555491685867,
        -0.02152303420007229,
        0.06492085754871368,
        0.04436461627483368,
        -0.020815176889300346,
        0.034097980707883835,
        0.06710680574178696,
        -0.028796060010790825,
        0.003872975939884782,
        -0.0581171028316021,
        0.0623861588537693,
        -0.04235170781612396,
        0.06556079536676407,
        -0.06806278973817825,
        -0.055895693600177765,
        0.035360123962163925,
        -0.01924639381468296,
        -0.06334210187196732,
        -0.004692643415182829,
        -0.06562057882547379,
        -0.06592510640621185,
        0.06200309097766876,
        0.0511736124753952,
        -0.01171159278601408,
        -0.019574228674173355,
        -0.023112012073397636,
        0.07195069640874863,
        0.06530645489692688,
        -0.012492380104959011,
        0.037489622831344604,
        0.07094084471464157,
        -0.050460085272789,
        0.03505665436387062,
        0.04862947016954422,
        0.01243649609386921,
        0.0722157284617424,
        0.06460624933242798,
        0.043760817497968674,
        0.023465020582079887,
        0.05173869431018829,
        -0.04722866043448448,
        -0.0695975124835968,
        -0.03010307438671589,
        -0.03508279100060463,
        -0.012832156382501125,
        -0.01776554062962532,
        -0.06500298529863358,
        -0.010337983258068562,
        -0.004556328523904085,
        -0.06891708076000214,
        0.006714265793561935,
        0.06169058382511139,
        0.01142739225178957,
        0.0607023760676384,
        -0.06776280701160431,
        -0.05709737166762352,
        0.010545920580625534,
        0.029048383235931396,
        0.007356190588325262,
        -0.061480503529310226,
        -0.055390067398548126,
        -0.06479833275079727,
        0.0021157513838261366,
        -0.029365945607423782,
        0.012926981784403324,
        0.016934752464294434,
        -0.029574023559689522,
        0.06831613928079605,
        0.017896434292197227,
        -0.04928730055689812,
        0.04912091791629791,
        0.03750807046890259,
        -0.04408709704875946,
        -0.06555312126874924,
        -0.01925782673060894,
        -0.06241744011640549,
        -0.031246021389961243,
        0.06733416765928268,
        0.03921297565102577,
        -0.030737703666090965,
        -0.05358199402689934,
        -0.052874304354190826,
        0.0239506047219038,
        -0.04920976981520653,
        -0.04598504677414894,
        -0.01050976850092411,
        -0.0075300633907318115,
        -0.06653014570474625,
        0.041864097118377686,
        0.06004892289638519,
        0.019491352140903473,
        0.06226041540503502,
        0.061352647840976715,
        0.04529646039009094,
        -0.06972096860408783,
        0.06205381080508232,
        -0.06697437912225723,
        0.0031483208294957876,
        0.05866820737719536,
        0.035819634795188904,
        -0.06452823430299759,
        0.00901584979146719,
        -0.06968515366315842,
        -0.0137318791821599,
        -0.016928786411881447,
        0.021509652957320213,
        0.0401281900703907,
        -0.010048695839941502,
        0.039427801966667175,
        0.07264835387468338,
        0.030770303681492805,
        0.048562053591012955,
        0.004158547148108482,
        0.012300269678235054,
        0.035754553973674774,
        -0.06069232150912285,
        -0.041768357157707214,
        0.04416023939847946,
        0.005011599510908127,
        0.05411152541637421,
        0.012073799967765808,
        -0.030936380848288536,
        -0.05210992321372032,
        0.01464839931577444,
        0.05298415198922157,
        -0.05934881791472435,
        -0.025697538629174232,
        0.058070018887519836,
        -0.045369792729616165,
        -0.005680363159626722,
        0.0727677121758461,
        -0.0026395919267088175,
        0.027560392394661903,
        0.04258124530315399,
        -0.046970635652542114,
        -0.018615975975990295,
        0.0635659322142601,
        -0.04001050069928169,
        -0.031003179028630257,
        -0.0454530343413353,
        0.0031420509330928326,
        0.05527413636445999,
        -0.0024691179860383272,
        -0.013926859945058823,
        0.020567381754517555,
        -0.040578559041023254,
        -0.07199911773204803,
        -0.010575545020401478,
        -0.011371237225830555,
        0.02320796065032482,
        -0.057811133563518524,
        0.007078199647367001,
        -0.05406884849071503,
        -0.06931217014789581,
        0.03960058465600014,
        -0.03656959533691406,
        -0.03890689089894295,
        -0.04043098911643028,
        0.06671804189682007,
        -0.0628681555390358,
        0.03580338880419731,
        0.023036472499370575,
        0.06104519963264465,
        -0.027034437283873558,
        0.044283803552389145,
        -0.00015548984811175615,
        -0.025991855189204216,
        0.0599367618560791,
        -0.04128440469503403,
        0.044444113969802856,
        0.07052552700042725,
        0.037929583340883255,
        -0.05536719411611557,
        0.04702555015683174,
        -0.00028153613675385714,
        0.006094132550060749,
        0.003513951553031802,
        -0.044435374438762665,
        -0.04486412554979324,
        -0.046140749007463455,
        -0.023000214248895645,
        -0.056578394025564194,
        0.0002737054892349988,
        -0.02615066058933735,
        0.04997476935386658,
        0.058875590562820435,
        -0.03568248823285103,
        -0.04476538673043251,
        0.06596258282661438,
        -0.02480139024555683,
        0.06454959511756897,
        -0.06742089986801147,
        0.05262121930718422,
        0.032115764915943146,
        -0.0071693058125674725,
        -0.07055971026420593,
        -0.04257795214653015,
        0.026619000360369682,
        -0.024942493066191673,
        -0.045366719365119934,
        -0.06301473826169968,
        -0.008338847197592258,
        -0.07107093185186386,
        -0.04212483391165733,
        0.05641646683216095,
        -0.056669916957616806,
        -0.048395294696092606,
        0.04649604484438896,
        0.005967181641608477,
        0.0719372034072876,
        -0.03205246478319168,
        -0.03012637421488762,
        0.0516192689538002,
        0.005510289687663317,
        0.05351056531071663,
        -0.007388572208583355,
        0.05831650272011757,
        0.012499252334237099,
        0.05858031287789345,
        0.041934601962566376,
        -0.055698540061712265,
        0.06951358914375305,
        -0.050118040293455124,
        -0.04187462851405144,
        0.03537311032414436,
        -0.04396933689713478,
        -0.02874206379055977,
        0.0015610142145305872,
        -0.029625190421938896,
        -0.05572257563471794,
        -0.01782160811126232,
        -0.06785662472248077,
        0.04763369634747505,
        0.05466049909591675,
        -0.06469573825597763,
        0.03052283264696598,
        0.06044933199882507,
        0.028300229460000992,
        0.05121093988418579,
        -0.03733448311686516,
        0.0009149019024334848,
        0.046460215002298355,
        0.05020017921924591,
        0.06237281113862991,
        -0.06392892450094223,
        0.05450183525681496,
        0.0619809590280056,
        -0.05212240293622017,
        0.05241469293832779,
        0.06425701081752777,
        -0.01222321204841137,
        -0.06778541207313538,
        0.03360334783792496,
        -0.06309549510478973,
        0.0326213501393795,
        -0.06789522618055344,
        0.02404981479048729,
        -0.032329194247722626,
        -0.05562034249305725,
        0.021876290440559387,
        -0.04301825910806656,
        -0.03535265475511551,
        0.0128471152856946,
        -0.03126567229628563,
        -0.05488765239715576,
        0.03584608435630798,
        -0.060567792505025864,
        0.040123648941516876,
        -0.037927623838186264,
        -0.03859146684408188,
        0.02266567014157772,
        0.028646808117628098,
        -0.009585521183907986,
        0.05828477814793587,
        -0.03633546084165573,
        -0.036944665014743805,
        -0.0026522837579250336,
        -0.03608371317386627,
        -0.024879122152924538,
        -0.0058510443195700645,
        0.047320976853370667,
        -0.05714774504303932,
        -0.06605880707502365,
        0.06548520922660828,
        0.0001602339034434408,
        -0.018161097541451454,
        0.06252311915159225,
        -0.04189980775117874,
        0.05172225832939148,
        -0.0325869657099247,
        0.06783419847488403,
        -0.07063314318656921,
        -0.014152579009532928,
        0.012275866232812405,
        0.04241252318024635,
        -0.01142335869371891,
        0.033434901386499405,
        -0.06527172774076462,
        0.06941638886928558,
        0.012850276194512844,
        0.004050561226904392,
        -0.011257556267082691,
        -0.027665920555591583,
        -0.019115764647722244,
        0.04784397780895233,
        -0.05993198603391647,
        0.011768833734095097,
        0.04652408882975578,
        -0.0618579164147377,
        0.0560251884162426,
        0.01871582493185997,
        -0.0059018186293542385,
        0.008121349848806858,
        -0.013844890519976616,
        -0.05686010792851448,
        0.06486937403678894,
        -0.03513793647289276,
        -0.02822568640112877,
        -0.06779985874891281,
        0.028603220358490944,
        0.03945013880729675,
        -0.002489664126187563,
        0.05731042101979256,
        -0.04890188202261925,
        -0.012560480274260044,
        -0.043024711310863495,
        0.07259759306907654,
        0.054390836507081985,
        0.04027291759848595,
        -0.000779836205765605,
        -0.06335277855396271,
        -0.06373238563537598,
        0.045221105217933655,
        0.0706288293004036,
        -0.009104734286665916,
        -0.026473913341760635,
        -0.06945043057203293,
        -0.0179336816072464,
        -0.028072496876120567,
        -0.06459526717662811,
        -0.0392330177128315,
        -0.029450228437781334,
        -0.020188843831419945,
        -0.04164526239037514,
        0.05464892461895943,
        0.06360915303230286,
        0.01868215948343277,
        -0.0009180335327982903,
        -0.060111287981271744,
        0.027697918936610222,
        -0.0029461353551596403,
        0.030408412218093872,
        -0.07163218408823013,
        -0.036750245839357376,
        0.050265561789274216,
        0.0031826079357415438,
        -0.02997981756925583,
        0.02285030484199524,
        0.01637786440551281,
        -0.04292989894747734,
        -0.060622502118349075,
        0.03621344640851021,
        -0.0510902963578701,
        0.05589061230421066,
        -0.04861617460846901,
        -0.04853516444563866,
        -0.06629901379346848,
        0.042800288647413254,
        0.019119128584861755,
        -0.011929760687053204,
        -0.01014747004956007,
        0.035019826143980026,
        -0.03496411815285683,
        0.01838354393839836,
        0.058167316019535065,
        0.008143981918692589,
        0.014218529686331749,
        -0.024877605959773064,
        -0.054394643753767014,
        0.06418205052614212,
        -0.029929691925644875,
        -0.047580841928720474,
        0.005353202577680349,
        0.0411912240087986,
        -0.04555744677782059,
        -0.039534084498882294,
        0.023365382105112076,
        -0.01846550963819027,
        -0.04198775067925453,
        0.05115323141217232,
        0.055953558534383774,
        0.052459731698036194,
        0.01735072024166584,
        0.04558708891272545,
        -0.0006783535936847329,
        0.03181692212820053,
        -0.034333158284425735,
        0.06573865562677383,
        0.02305048331618309,
        -0.04505603387951851,
        -0.012544313445687294,
        -0.003091635415330529,
        -0.028644345700740814,
        0.037563033401966095,
        -0.040260229259729385,
        0.054279882460832596,
        0.023916129022836685,
        -0.009410585276782513,
        0.050055235624313354,
        -0.030028093606233597,
        -0.01965043507516384,
        -0.0674065425992012,
        0.019613465294241905,
        0.02195221371948719,
        0.06846365332603455,
        -0.005998402368277311,
        0.04626983031630516,
        -0.05339872092008591,
        -0.06353268772363663,
        0.015620172023773193,
        0.05524210259318352,
        -0.018631910905241966,
        -0.04183044284582138,
        -0.06675876677036285,
        -0.05852417275309563,
        0.05309150367975235,
        0.039095401763916016,
        0.020369522273540497,
        -0.0516958087682724,
        -0.0412055179476738,
        -0.013697125017642975,
        -0.048362381756305695,
        0.01109223160892725,
        0.059708867222070694,
        -0.008294184692203999,
        0.050621990114450455,
        0.03754844143986702,
        -0.05672435835003853,
        -0.00037274861824698746,
        -0.06192869320511818,
        -0.026750730350613594,
        -0.0308209415525198,
        -0.007594617083668709,
        0.004431868437677622,
        -0.015835266560316086,
        0.056828372180461884,
        -0.0036850273609161377,
        0.055102668702602386,
        0.07088872045278549,
        -0.0687762200832367,
        -0.031673088669776917,
        -0.04381241276860237,
        -0.012538046576082706,
        0.03083896078169346,
        -0.007296440191566944,
        0.07063958793878555,
        0.03584102541208267,
        -0.05341017246246338,
        -0.06902485340833664,
        0.025540081784129143,
        0.06175616383552551
    ],
    [
        -0.04712492972612381,
        0.0567161962389946,
        -0.003238111501559615,
        0.03740393742918968,
        0.024435099214315414,
        0.06404228508472443,
        0.016955915838479996,
        -0.05338265746831894,
        0.06225762516260147,
        0.04586305469274521,
        0.04524873569607735,
        -0.018955200910568237,
        -0.034342821687459946,
        -0.0240897499024868,
        -0.036331262439489365,
        0.06794087588787079,
        -0.03960047662258148,
        0.06642670184373856,
        -0.05751926079392433,
        -0.06950941681861877,
        -0.02106783352792263,
        0.06551804393529892,
        0.03505224734544754,
        0.011047489009797573,
        0.03953273594379425,
        0.039392370730638504,
        0.0642748549580574,
        -0.040182262659072876,
        0.006596203427761793,
        0.036826685070991516,
        0.013837507925927639,
        -0.03572746738791466,
        -0.053478095680475235,
        -0.06572368741035461,
        0.05270792171359062,
        -0.020696260035037994,
        -0.06332016736268997,
        -0.0006724967388436198,
        -0.010622072033584118,
        0.0051320199854671955,
        0.06310725957155228,
        0.010736610740423203,
        0.019362343475222588,
        -0.06707876920700073,
        0.06304353475570679,
        0.03726842254400253,
        0.02711978554725647,
        0.04763193428516388,
        0.04401982203125954,
        0.046697650104761124,
        0.05849432200193405,
        0.06597461551427841,
        -0.010669343173503876,
        0.06277616322040558,
        0.04692927002906799,
        -0.026057342067360878,
        0.024000192061066628,
        0.06415382027626038,
        -0.010012648068368435,
        -0.003585553029552102,
        -0.05890838801860809,
        0.06100744754076004,
        -0.04471948742866516,
        0.06603452563285828,
        -0.0647987350821495,
        -0.055716373026371,
        0.03082212246954441,
        -0.02373943291604519,
        -0.061021722853183746,
        -0.004569823853671551,
        -0.06520064920186996,
        -0.0660119280219078,
        0.061308447271585464,
        0.04234629124403,
        -0.0032732959371060133,
        -0.013634308241307735,
        -0.012525654397904873,
        0.06889625638723373,
        0.06285065412521362,
        -0.026360778138041496,
        0.0384989008307457,
        0.06860136985778809,
        -0.046345122158527374,
        0.04830804467201233,
        0.05521418899297714,
        0.014943084679543972,
        0.06932242959737778,
        0.06367172300815582,
        0.047974251210689545,
        0.028396854177117348,
        0.029210355132818222,
        -0.05211849883198738,
        -0.06881184130907059,
        -0.03909127786755562,
        -0.030677301809191704,
        -0.010432692244648933,
        -0.02025466226041317,
        -0.06553150713443756,
        -0.02164478413760662,
        -0.006920593325048685,
        -0.06613475829362869,
        0.0241660438477993,
        0.05925103276968002,
        0.014721282757818699,
        0.06050501763820648,
        -0.06749416142702103,
        -0.04946690425276756,
        0.025419680401682854,
        0.024378351867198944,
        0.033792365342378616,
        -0.06194772943854332,
        -0.059192657470703125,
        -0.06241830810904503,
        0.012005600146949291,
        -0.03826233744621277,
        0.015607058070600033,
        0.030797800049185753,
        -0.03797579184174538,
        0.06652436405420303,
        0.030616220086812973,
        -0.045910730957984924,
        0.0549146831035614,
        0.04306730628013611,
        -0.030077600851655006,
        -0.06306789815425873,
        0.005398563574999571,
        -0.06080939248204231,
        -0.04646361991763115,
        0.06326500326395035,
        0.04610458388924599,
        -0.02504773437976837,
        -0.04610089212656021,
        -0.048764970153570175,
        0.02063680626451969,
        -0.04877975583076477,
        -0.04337555542588234,
        0.009950819425284863,
        -0.001512629329226911,
        -0.06516056507825851,
        0.048400454223155975,
        0.054433226585388184,
        0.016089292243123055,
        0.06080164760351181,
        0.05993697792291641,
        0.04714648798108101,
        -0.06730513274669647,
        0.061903249472379684,
        -0.06439670920372009,
        0.01707957498729229,
        0.06139272823929787,
        0.04282773658633232,
        -0.06381813436746597,
        0.011773643083870411,
        -0.06612823903560638,
        -0.0032217756379395723,
        -0.02286202646791935,
        0.02424134686589241,
        0.04329384118318558,
        -0.02661786787211895,
        0.04906301572918892,
        0.06969191133975983,
        0.018235953524708748,
        0.04289519041776657,
        -0.012724027968943119,
        0.005681056994944811,
        0.04295274242758751,
        -0.05611715093255043,
        -0.03482816368341446,
        0.0420692004263401,
        0.0016357812564820051,
        0.05245542153716087,
        0.0108321662992239,
        -0.02369404211640358,
        -0.055885542184114456,
        0.009196804836392403,
        0.061222631484270096,
        -0.05791367590427399,
        -0.031308744102716446,
        0.04681621491909027,
        -0.0519016794860363,
        -0.008469750173389912,
        0.06975331157445908,
        -0.005427181720733643,
        0.030983302742242813,
        0.045623134821653366,
        -0.048045963048934937,
        -0.026876265183091164,
        0.06263679265975952,
        -0.036930061876773834,
        -0.04527043551206589,
        -0.04363662749528885,
        0.014459151774644852,
        0.054126713424921036,
        0.009216303005814552,
        -0.022476274520158768,
        0.0016937883337959647,
        -0.0500759482383728,
        -0.06907433271408081,
        -0.0223874319344759,
        -0.0049889227375388145,
        0.02968072146177292,
        -0.0568469874560833,
        -0.004334393888711929,
        -0.05465163663029671,
        -0.06634090095758438,
        0.04951644316315651,
        -0.03798267990350723,
        -0.040366433560848236,
        -0.04140137508511543,
        0.0665566474199295,
        -0.06279873102903366,
        0.033277809619903564,
        0.020164519548416138,
        0.05760616436600685,
        -0.03757321462035179,
        0.03574761748313904,
        -0.010966697707772255,
        -0.03627427667379379,
        0.05892770737409592,
        -0.0444832444190979,
        0.03122893162071705,
        0.06820780783891678,
        0.036323584616184235,
        -0.05376829206943512,
        0.05629745498299599,
        -0.009742943570017815,
        0.008929774165153503,
        -0.0076079899445176125,
        -0.0391574390232563,
        -0.044212792068719864,
        -0.02696577087044716,
        -0.046504948288202286,
        -0.057704851031303406,
        0.002936028642579913,
        -0.038530346006155014,
        0.050852444022893906,
        0.059502385556697845,
        -0.030224263668060303,
        -0.041867587715387344,
        0.06118018925189972,
        -0.03428937867283821,
        0.06417660415172577,
        -0.06753711402416229,
        0.05387549847364426,
        0.03744778782129288,
        -0.00811775866895914,
        -0.06644932180643082,
        -0.020615514367818832,
        0.027256278321146965,
        -0.017889734357595444,
        -0.04680969566106796,
        -0.059121016412973404,
        -0.012267672456800938,
        -0.06838848441839218,
        -0.04117097333073616,
        0.053776584565639496,
        -0.053499672561883926,
        -0.054313432425260544,
        0.049889493733644485,
        0.010690205730497837,
        0.06912031769752502,
        -0.02152879163622856,
        -0.021870510652661324,
        0.05597280338406563,
        -0.0020170239731669426,
        0.049750715494155884,
        0.0137435058131814,
        0.05970364063978195,
        0.022329451516270638,
        0.058409471064805984,
        0.032847657799720764,
        -0.06145294010639191,
        0.06832360476255417,
        -0.047906842082738876,
        -0.044083885848522186,
        0.019111646339297295,
        -0.04562358558177948,
        -0.0314127542078495,
        -0.010632702149450779,
        -0.032548412680625916,
        -0.06109150871634483,
        -0.008161978796124458,
        -0.06651702523231506,
        0.047931015491485596,
        0.05835708975791931,
        -0.0628204494714737,
        0.030545422807335854,
        0.05800708755850792,
        0.0394013375043869,
        0.04325791448354721,
        -0.04343794286251068,
        -0.005320226773619652,
        0.04885302111506462,
        0.04930683970451355,
        0.06203005835413933,
        -0.061603717505931854,
        0.05423114448785782,
        0.06377186626195908,
        -0.05050153285264969,
        0.049914661794900894,
        0.06336676329374313,
        -0.013256789185106754,
        -0.0643056109547615,
        0.029074423015117645,
        -0.05977531522512436,
        0.02810409851372242,
        -0.06563399732112885,
        0.016054891049861908,
        -0.03245876729488373,
        -0.04950035363435745,
        0.030392877757549286,
        -0.04605022817850113,
        -0.03975055739283562,
        0.02110183984041214,
        -0.02208380028605461,
        -0.05580413341522217,
        0.026186227798461914,
        -0.05694347247481346,
        0.03699925169348717,
        -0.046938180923461914,
        -0.027119861915707588,
        0.010836957953870296,
        0.030355287715792656,
        0.0017779446206986904,
        0.057738203555345535,
        -0.04332718253135681,
        -0.03511245176196098,
        0.009081725962460041,
        -0.03407640382647514,
        -0.03150131553411484,
        0.010020787827670574,
        0.0527086965739727,
        -0.05784636735916138,
        -0.06271466612815857,
        0.0628621056675911,
        0.008959619328379631,
        -0.037309493869543076,
        0.05808350071310997,
        -0.03826108202338219,
        0.0514727421104908,
        -0.030698444694280624,
        0.06384121626615524,
        -0.06726924329996109,
        -0.02433972992002964,
        0.01293268520385027,
        0.04477043077349663,
        -0.0028918879106640816,
        0.035007745027542114,
        -0.0652732327580452,
        0.06486822664737701,
        0.01627996750175953,
        0.008845287375152111,
        -0.02840951643884182,
        -0.04721776023507118,
        -0.021786252036690712,
        0.052132245153188705,
        -0.0625012069940567,
        -0.006237306632101536,
        0.05484694242477417,
        -0.06401082873344421,
        0.05333544686436653,
        0.024832993745803833,
        0.012989498674869537,
        0.007814277894794941,
        -0.020964346826076508,
        -0.05851975455880165,
        0.06684709340333939,
        -0.03586442023515701,
        -0.008675575256347656,
        -0.06655693799257278,
        0.0075299362652003765,
        0.034944549202919006,
        0.01719236746430397,
        0.05566276237368584,
        -0.04685584455728531,
        -0.01365939062088728,
        -0.046996790915727615,
        0.06962922215461731,
        0.05576561391353607,
        0.04361535236239433,
        -0.0138940941542387,
        -0.06161072105169296,
        -0.059810079634189606,
        0.028911886736750603,
        0.06726046651601791,
        -0.01471235603094101,
        -0.029486218467354774,
        -0.0650147944688797,
        -0.028060633689165115,
        -0.022059565410017967,
        -0.06673525273799896,
        -0.03307802975177765,
        -0.030961187556385994,
        -0.01966577023267746,
        -0.03188205137848854,
        0.05220760032534599,
        0.06247572973370552,
        0.007936002686619759,
        0.005093920975923538,
        -0.06114349886775017,
        0.022751497104763985,
        0.00677131162956357,
        0.03813615441322327,
        -0.0690355896949768,
        -0.04382392391562462,
        0.044181328266859055,
        0.0008768433472141623,
        -0.033781059086322784,
        0.03569483384490013,
        0.0015795014332979918,
        -0.035197000950574875,
        -0.052306659519672394,
        0.04264483228325844,
        -0.041700176894664764,
        0.055680353194475174,
        -0.04263920336961746,
        -0.05316615104675293,
        -0.06365298479795456,
        0.04261508956551552,
        0.02065623737871647,
        0.014072720892727375,
        0.001565966522321105,
        0.044641461223363876,
        -0.03663363680243492,
        0.048649754375219345,
        0.04576385021209717,
        -0.000812794198282063,
        0.029347334057092667,
        -0.03474889323115349,
        -0.05117682367563248,
        0.06551562994718552,
        -0.031400278210639954,
        -0.05340740084648132,
        0.004550801124423742,
        0.02505573444068432,
        -0.04971952363848686,
        -0.02266167849302292,
        0.019160043448209763,
        -0.006515025161206722,
        -0.03533036634325981,
        0.04968930408358574,
        0.0618399940431118,
        0.057430122047662735,
        0.03013881854712963,
        0.04304049164056778,
        -0.0063246809877455235,
        0.030177714303135872,
        -0.03949710354208946,
        0.06392814964056015,
        0.028689410537481308,
        -0.04786510393023491,
        -0.012052273377776146,
        0.018336856737732887,
        -0.021207736805081367,
        0.03924700990319252,
        -0.04420604184269905,
        0.044960737228393555,
        0.03244262561202049,
        -0.011233669705688953,
        0.05919133126735687,
        -0.037730470299720764,
        -0.022642889991402626,
        -0.0665813758969307,
        0.02539546974003315,
        0.030939318239688873,
        0.06708312034606934,
        -0.01219208724796772,
        0.049575451761484146,
        -0.05818726122379303,
        -0.06259454786777496,
        0.026149220764636993,
        0.061283767223358154,
        -0.01969170942902565,
        -0.04071793332695961,
        -0.06779292970895767,
        -0.053854525089263916,
        0.04523594304919243,
        0.043367911130189896,
        0.010487856343388557,
        -0.053666066378355026,
        -0.04902871325612068,
        -0.02037207782268524,
        -0.05294307321310043,
        0.0022160853259265423,
        0.055629976093769073,
        0.001780133810825646,
        0.050268907099962234,
        0.028426427394151688,
        -0.05130462720990181,
        -0.008638309314846992,
        -0.059831857681274414,
        -0.030287567526102066,
        -0.02572593092918396,
        -0.010249330662190914,
        0.024367226287722588,
        -0.01682918146252632,
        0.05537604168057442,
        0.012654203921556473,
        0.05432666465640068,
        0.0689498782157898,
        -0.06700830906629562,
        -0.03396863117814064,
        -0.05228599160909653,
        -0.026702241972088814,
        0.04560670629143715,
        -0.014808941632509232,
        0.06888855993747711,
        0.027253609150648117,
        -0.05172622576355934,
        -0.06671963632106781,
        0.029203517362475395,
        0.055861685425043106
    ],
    [
        -0.032121144235134125,
        0.0633014589548111,
        0.011486350558698177,
        0.04259863868355751,
        0.026054473593831062,
        0.07023721188306808,
        0.022907566279172897,
        -0.05673208460211754,
        0.06285060942173004,
        0.03637996315956116,
        0.04158739000558853,
        -0.039177343249320984,
        -0.031311068683862686,
        -0.016805244609713554,
        -0.04239002615213394,
        0.06856382638216019,
        -0.03998607024550438,
        0.06969216465950012,
        -0.05875316262245178,
        -0.07232314348220825,
        -0.03820997476577759,
        0.06542622298002243,
        0.024491671472787857,
        0.01216741930693388,
        0.050267066806554794,
        0.03888816013932228,
        0.05855843424797058,
        -0.018146725371479988,
        -0.0027985372580587864,
        0.014367002993822098,
        0.038018837571144104,
        -0.04226842150092125,
        -0.053402625024318695,
        -0.06498195976018906,
        0.0522778257727623,
        -0.0036970258224755526,
        -0.0646497905254364,
        0.0031309258192777634,
        0.014924610033631325,
        0.0071103014051914215,
        0.057126596570014954,
        0.02521606907248497,
        0.02004188485443592,
        -0.07002116739749908,
        0.061525728553533554,
        0.036158010363578796,
        0.0017643136670812964,
        0.042692963033914566,
        0.023320917040109634,
        0.04945790395140648,
        0.045631200075149536,
        0.06825341284275055,
        -0.032024797052145004,
        0.06431864947080612,
        0.042529523372650146,
        -0.019256358966231346,
        0.023763742297887802,
        0.06756516546010971,
        -0.020542845129966736,
        0.00033572191023267806,
        -0.05768237262964249,
        0.06393168121576309,
        -0.03998501971364021,
        0.06414202600717545,
        -0.06740856170654297,
        -0.060836371034383774,
        0.02531925030052662,
        -0.02013353444635868,
        -0.06446520984172821,
        -0.0022335017565637827,
        -0.0641360804438591,
        -0.0691247284412384,
        0.06595344096422195,
        0.050088562071323395,
        -0.015482237562537193,
        -0.019686000421643257,
        -0.025594590231776237,
        0.07234610617160797,
        0.06614240258932114,
        0.00659876549616456,
        0.03590472787618637,
        0.07047096639871597,
        -0.05178501829504967,
        0.033166900277137756,
        0.050890062004327774,
        0.009567171335220337,
        0.07255478948354721,
        0.0612434521317482,
        0.03899945318698883,
        0.023224767297506332,
        0.05472954362630844,
        -0.044462427496910095,
        -0.0696917325258255,
        -0.023846257477998734,
        -0.030127504840493202,
        -0.012888524681329727,
        -0.02281363308429718,
        -0.0643441379070282,
        -0.011510992422699928,
        -0.0038664748426526785,
        -0.06873436272144318,
        -0.0000695078560966067,
        0.06014315411448479,
        0.00638709869235754,
        0.05925595387816429,
        -0.06407953053712845,
        -0.05741874501109123,
        0.008375889621675014,
        0.022518469020724297,
        0.008953463286161423,
        -0.06030756235122681,
        -0.05253086984157562,
        -0.06734049320220947,
        -0.014498173259198666,
        -0.03385253995656967,
        -0.0025398239959031343,
        0.013770507648587227,
        -0.031082309782505035,
        0.06921248883008957,
        0.020668938755989075,
        -0.05508957430720329,
        0.05193706229329109,
        0.03326214477419853,
        -0.039565201848745346,
        -0.06442612409591675,
        -0.017775114625692368,
        -0.06409367173910141,
        -0.01808927394449711,
        0.06842619180679321,
        0.02419515885412693,
        -0.04171799495816231,
        -0.053760796785354614,
        -0.04771003872156143,
        0.02312004752457142,
        -0.04743746668100357,
        -0.05017175152897835,
        -0.010532943531870842,
        -0.016947463154792786,
        -0.062926284968853,
        0.046865109354257584,
        0.06445006281137466,
        0.01226757001131773,
        0.06251896172761917,
        0.05953152850270271,
        0.05355900898575783,
        -0.07039179652929306,
        0.06542237848043442,
        -0.06534888595342636,
        -0.008722418919205666,
        0.05805296078324318,
        0.04361863061785698,
        -0.0646180585026741,
        -0.020761046558618546,
        -0.069727323949337,
        0.0023080934770405293,
        -0.028894813731312752,
        0.018944362178444862,
        0.028753524646162987,
        -0.012698735110461712,
        0.04013120383024216,
        0.07298637181520462,
        0.037467680871486664,
        0.04818502068519592,
        0.015708941966295242,
        0.02422812581062317,
        0.023957163095474243,
        -0.05883924290537834,
        -0.03930869698524475,
        0.044533077627420425,
        0.006879293825477362,
        0.04463175684213638,
        0.020045528188347816,
        -0.035012971609830856,
        -0.05214681476354599,
        -0.0019790129736065865,
        0.05111305043101311,
        -0.059261929243803024,
        -0.027768336236476898,
        0.057873956859111786,
        -0.03798097372055054,
        0.0004713374364655465,
        0.07313350588083267,
        -0.013428077101707458,
        0.04166910797357559,
        0.046178244054317474,
        -0.04529974237084389,
        -0.01691269315779209,
        0.06416720151901245,
        -0.044548071920871735,
        -0.046305686235427856,
        -0.05247659981250763,
        -0.0028746179305016994,
        0.054760660976171494,
        -0.00942330900579691,
        -0.00406224187463522,
        0.030469220131635666,
        -0.02847079187631607,
        -0.07230542600154877,
        -0.007511007599532604,
        -0.004311924800276756,
        0.030699456110596657,
        -0.05675186216831207,
        0.023881837725639343,
        -0.05095542594790459,
        -0.0698462575674057,
        0.023079151287674904,
        -0.029936984181404114,
        -0.03540748357772827,
        -0.04657917097210884,
        0.06559771299362183,
        -0.061586376279592514,
        0.03701306879520416,
        0.02089596912264824,
        0.059992287307977676,
        -0.026892879977822304,
        0.04434864968061447,
        0.009389621205627918,
        -0.016751831397414207,
        0.05948078632354736,
        -0.023729389533400536,
        0.04291801154613495,
        0.06991206854581833,
        0.035801857709884644,
        -0.05169308930635452,
        0.05356680974364281,
        -0.026217253878712654,
        0.006261246278882027,
        0.0037691451143473387,
        -0.042587652802467346,
        -0.04311148822307587,
        -0.046247754245996475,
        -0.03535342216491699,
        -0.05372443422675133,
        -0.007527642417699099,
        -0.023427827283740044,
        0.051811471581459045,
        0.055182307958602905,
        -0.037183310836553574,
        -0.032669275999069214,
        0.06666772812604904,
        -0.02941904403269291,
        0.06259778141975403,
        -0.06726931780576706,
        0.05074869841337204,
        0.021510537713766098,
        -0.011293652467429638,
        -0.07094986736774445,
        -0.045281898230314255,
        0.03393544629216194,
        -0.02277977764606476,
        -0.042901407927274704,
        -0.06485181301832199,
        -0.015575955621898174,
        -0.07135450839996338,
        -0.038056839257478714,
        0.056047309190034866,
        -0.05525292828679085,
        -0.052859362214803696,
        0.03755531087517738,
        0.013229221105575562,
        0.07211696356534958,
        -0.03842535614967346,
        -0.023901022970676422,
        0.053970303386449814,
        0.005389669444411993,
        0.05618574842810631,
        -0.02442767471075058,
        0.05161241441965103,
        0.00908530130982399,
        0.05735762044787407,
        0.04620642215013504,
        -0.051924970000982285,
        0.0700295940041542,
        -0.055785171687603,
        -0.045171547681093216,
        0.03480467200279236,
        -0.038451943546533585,
        -0.01780061610043049,
        -0.007382952142506838,
        -0.035202328115701675,
        -0.044585954397916794,
        -0.029479285702109337,
        -0.0681210309267044,
        0.052289512008428574,
        0.04822952300310135,
        -0.061398740857839584,
        0.024582846090197563,
        0.060428522527217865,
        0.027539663016796112,
        0.05388077720999718,
        -0.037347450852394104,
        -0.009746483527123928,
        0.047822073101997375,
        0.052972905337810516,
        0.061961423605680466,
        -0.0633615255355835,
        0.055340006947517395,
        0.058735672384500504,
        -0.052095264196395874,
        0.054020076990127563,
        0.06348362565040588,
        -0.015497298911213875,
        -0.06734415143728256,
        0.04189136251807213,
        -0.06612679362297058,
        0.029277265071868896,
        -0.06800337880849838,
        0.021768787875771523,
        -0.029949607327580452,
        -0.06307978928089142,
        0.023394081741571426,
        -0.034747254103422165,
        -0.031190164387226105,
        0.010386858135461807,
        -0.03088843636214733,
        -0.055153198540210724,
        0.03519501909613609,
        -0.05643707513809204,
        0.03726550191640854,
        -0.0348157100379467,
        -0.038119927048683167,
        0.02184934914112091,
        0.022571103647351265,
        0.0014252198161557317,
        0.06317360699176788,
        -0.039041515439748764,
        -0.04434410482645035,
        -0.021468879655003548,
        -0.038050614297389984,
        -0.03424954041838646,
        -0.01063171960413456,
        0.04935085028409958,
        -0.05253221094608307,
        -0.06611907482147217,
        0.06589067727327347,
        -0.005425761919468641,
        -0.013664832338690758,
        0.06645192205905914,
        -0.04184684902429581,
        0.050141721963882446,
        -0.026322219520807266,
        0.06934239715337753,
        -0.07083671540021896,
        -0.014885236509144306,
        0.010986368171870708,
        0.04393072798848152,
        -0.008161568082869053,
        0.03164300322532654,
        -0.06310001760721207,
        0.07013777643442154,
        0.018644466996192932,
        -0.007450279779732227,
        -0.017607562243938446,
        -0.016086556017398834,
        -0.024739151820540428,
        0.047821301966905594,
        -0.05563464015722275,
        0.007603867445141077,
        0.03816549479961395,
        -0.06380938738584518,
        0.05741927772760391,
        0.012742696329951286,
        -0.0036464424338191748,
        0.009327875450253487,
        -0.012736411765217781,
        -0.05503600463271141,
        0.06498683243989944,
        -0.04169933497905731,
        -0.028755463659763336,
        -0.06800883263349533,
        0.02553519234061241,
        0.03792401775717735,
        -0.0022025275975465775,
        0.05664869397878647,
        -0.05076894164085388,
        -0.006011537276208401,
        -0.03942864015698433,
        0.07293333858251572,
        0.04928096756339073,
        0.04408983141183853,
        0.002540882444009185,
        -0.06344075500965118,
        -0.0647975355386734,
        0.04899751767516136,
        0.0707036629319191,
        -0.004642097745090723,
        -0.030039558187127113,
        -0.06987354904413223,
        -0.009920592419803143,
        -0.03224504739046097,
        -0.06565558910369873,
        -0.03485869988799095,
        -0.0365900881588459,
        -0.027221055701375008,
        -0.043359655886888504,
        0.05522599816322327,
        0.06088273599743843,
        0.026197470724582672,
        0.005641364958137274,
        -0.05963163077831268,
        0.03103850968182087,
        0.007428039330989122,
        0.024810144677758217,
        -0.0718749612569809,
        -0.0411020889878273,
        0.04768061265349388,
        0.00400229636579752,
        -0.029531044885516167,
        0.02517683245241642,
        0.015060549601912498,
        -0.04194383695721626,
        -0.06311478465795517,
        0.0368049331009388,
        -0.04584891349077225,
        0.059710968285799026,
        -0.04768107458949089,
        -0.04882192611694336,
        -0.06059062108397484,
        0.04892411455512047,
        0.030142467468976974,
        -0.016577232629060745,
        -0.0010007268283516169,
        0.02837800793349743,
        -0.031151412054896355,
        0.030298437923192978,
        0.05570733919739723,
        0.0159442201256752,
        0.010409798473119736,
        -0.028283050283789635,
        -0.053730934858322144,
        0.06405209749937057,
        -0.025348126888275146,
        -0.05063660442829132,
        0.00957612693309784,
        0.05053894594311714,
        -0.04798778519034386,
        -0.0487762987613678,
        0.0179978609085083,
        -0.026810958981513977,
        -0.04221151024103165,
        0.0500892773270607,
        0.05071704462170601,
        0.058899521827697754,
        0.014915389940142632,
        0.030983516946434975,
        -0.000002268231128255138,
        0.02386542782187462,
        -0.029090963304042816,
        0.06387169659137726,
        0.012973804958164692,
        -0.04413079842925072,
        -0.011277753859758377,
        -0.00014101697888690978,
        -0.0293164923787117,
        0.03519253805279732,
        -0.03922375291585922,
        0.04948287457227707,
        0.03152449056506157,
        -0.010886024683713913,
        0.04794304445385933,
        -0.04580041021108627,
        -0.020953485742211342,
        -0.0678788349032402,
        0.004426241386681795,
        0.019852958619594574,
        0.06917482614517212,
        -0.006428097374737263,
        0.04819813370704651,
        -0.053015533834695816,
        -0.06275061517953873,
        0.01924377866089344,
        0.06059580296278,
        -0.02365538664162159,
        -0.039800699800252914,
        -0.06516265124082565,
        -0.05679306015372276,
        0.05123177170753479,
        0.04210590943694115,
        0.0344172865152359,
        -0.04796762019395828,
        -0.032382965087890625,
        -0.00907875970005989,
        -0.043876927345991135,
        0.0137558588758111,
        0.053814712911844254,
        -0.019737983122467995,
        0.042991895228624344,
        0.04010343924164772,
        -0.05862924084067345,
        -0.011393237859010696,
        -0.06525026261806488,
        -0.02442270517349243,
        -0.024371923878788948,
        -0.020312361419200897,
        -0.00043658254435285926,
        -0.019047005102038383,
        0.0515470989048481,
        -0.002348570851609111,
        0.05454582720994949,
        0.07120881974697113,
        -0.06629330664873123,
        -0.033964604139328,
        -0.027065835893154144,
        -0.014043164439499378,
        0.01347512099891901,
        0.0015095010166987777,
        0.07094965130090714,
        0.0358877032995224,
        -0.06058534234762192,
        -0.0679314136505127,
        0.038055457174777985,
        0.065132737159729
    ],
    [
        -0.032121144235134125,
        0.0633014589548111,
        0.011486350558698177,
        0.04259863868355751,
        0.026054473593831062,
        0.07023721188306808,
        0.022907566279172897,
        -0.05673208460211754,
        0.06285060942173004,
        0.03637996315956116,
        0.04158739000558853,
        -0.039177343249320984,
        -0.031311068683862686,
        -0.016805244609713554,
        -0.04239002615213394,
        0.06856382638216019,
        -0.03998607024550438,
        0.06969216465950012,
        -0.05875316262245178,
        -0.07232314348220825,
        -0.03820997476577759,
        0.06542622298002243,
        0.024491671472787857,
        0.01216741930693388,
        0.050267066806554794,
        0.03888816013932228,
        0.05855843424797058,
        -0.018146725371479988,
        -0.0027985372580587864,
        0.014367002993822098,
        0.038018837571144104,
        -0.04226842150092125,
        -0.053402625024318695,
        -0.06498195976018906,
        0.0522778257727623,
        -0.0036970258224755526,
        -0.0646497905254364,
        0.0031309258192777634,
        0.014924610033631325,
        0.0071103014051914215,
        0.057126596570014954,
        0.02521606907248497,
        0.02004188485443592,
        -0.07002116739749908,
        0.061525728553533554,
        0.036158010363578796,
        0.0017643136670812964,
        0.042692963033914566,
        0.023320917040109634,
        0.04945790395140648,
        0.045631200075149536,
        0.06825341284275055,
        -0.032024797052145004,
        0.06431864947080612,
        0.042529523372650146,
        -0.019256358966231346,
        0.023763742297887802,
        0.06756516546010971,
        -0.020542845129966736,
        0.00033572191023267806,
        -0.05768237262964249,
        0.06393168121576309,
        -0.03998501971364021,
        0.06414202600717545,
        -0.06740856170654297,
        -0.060836371034383774,
        0.02531925030052662,
        -0.02013353444635868,
        -0.06446520984172821,
        -0.0022335017565637827,
        -0.0641360804438591,
        -0.0691247284412384,
        0.06595344096422195,
        0.050088562071323395,
        -0.015482237562537193,
        -0.019686000421643257,
        -0.025594590231776237,
        0.07234610617160797,
        0.06614240258932114,
        0.00659876549616456,
        0.03590472787618637,
        0.07047096639871597,
        -0.05178501829504967,
        0.033166900277137756,
        0.050890062004327774,
        0.009567171335220337,
        0.07255478948354721,
        0.0612434521317482,
        0.03899945318698883,
        0.023224767297506332,
        0.05472954362630844,
        -0.044462427496910095,
        -0.0696917325258255,
        -0.023846257477998734,
        -0.030127504840493202,
        -0.012888524681329727,
        -0.02281363308429718,
        -0.0643441379070282,
        -0.011510992422699928,
        -0.0038664748426526785,
        -0.06873436272144318,
        -0.0000695078560966067,
        0.06014315411448479,
        0.00638709869235754,
        0.05925595387816429,
        -0.06407953053712845,
        -0.05741874501109123,
        0.008375889621675014,
        0.022518469020724297,
        0.008953463286161423,
        -0.06030756235122681,
        -0.05253086984157562,
        -0.06734049320220947,
        -0.014498173259198666,
        -0.03385253995656967,
        -0.0025398239959031343,
        0.013770507648587227,
        -0.031082309782505035,
        0.06921248883008957,
        0.020668938755989075,
        -0.05508957430720329,
        0.05193706229329109,
        0.03326214477419853,
        -0.039565201848745346,
        -0.06442612409591675,
        -0.017775114625692368,
        -0.06409367173910141,
        -0.01808927394449711,
        0.06842619180679321,
        0.02419515885412693,
        -0.04171799495816231,
        -0.053760796785354614,
        -0.04771003872156143,
        0.02312004752457142,
        -0.04743746668100357,
        -0.05017175152897835,
        -0.010532943531870842,
        -0.016947463154792786,
        -0.062926284968853,
        0.046865109354257584,
        0.06445006281137466,
        0.01226757001131773,
        0.06251896172761917,
        0.05953152850270271,
        0.05355900898575783,
        -0.07039179652929306,
        0.06542237848043442,
        -0.06534888595342636,
        -0.008722418919205666,
        0.05805296078324318,
        0.04361863061785698,
        -0.0646180585026741,
        -0.020761046558618546,
        -0.069727323949337,
        0.0023080934770405293,
        -0.028894813731312752,
        0.018944362178444862,
        0.028753524646162987,
        -0.012698735110461712,
        0.04013120383024216,
        0.07298637181520462,
        0.037467680871486664,
        0.04818502068519592,
        0.015708941966295242,
        0.02422812581062317,
        0.023957163095474243,
        -0.05883924290537834,
        -0.03930869698524475,
        0.044533077627420425,
        0.006879293825477362,
        0.04463175684213638,
        0.020045528188347816,
        -0.035012971609830856,
        -0.05214681476354599,
        -0.0019790129736065865,
        0.05111305043101311,
        -0.059261929243803024,
        -0.027768336236476898,
        0.057873956859111786,
        -0.03798097372055054,
        0.0004713374364655465,
        0.07313350588083267,
        -0.013428077101707458,
        0.04166910797357559,
        0.046178244054317474,
        -0.04529974237084389,
        -0.01691269315779209,
        0.06416720151901245,
        -0.044548071920871735,
        -0.046305686235427856,
        -0.05247659981250763,
        -0.0028746179305016994,
        0.054760660976171494,
        -0.00942330900579691,
        -0.00406224187463522,
        0.030469220131635666,
        -0.02847079187631607,
        -0.07230542600154877,
        -0.007511007599532604,
        -0.004311924800276756,
        0.030699456110596657,
        -0.05675186216831207,
        0.023881837725639343,
        -0.05095542594790459,
        -0.0698462575674057,
        0.023079151287674904,
        -0.029936984181404114,
        -0.03540748357772827,
        -0.04657917097210884,
        0.06559771299362183,
        -0.061586376279592514,
        0.03701306879520416,
        0.02089596912264824,
        0.059992287307977676,
        -0.026892879977822304,
        0.04434864968061447,
        0.009389621205627918,
        -0.016751831397414207,
        0.05948078632354736,
        -0.023729389533400536,
        0.04291801154613495,
        0.06991206854581833,
        0.035801857709884644,
        -0.05169308930635452,
        0.05356680974364281,
        -0.026217253878712654,
        0.006261246278882027,
        0.0037691451143473387,
        -0.042587652802467346,
        -0.04311148822307587,
        -0.046247754245996475,
        -0.03535342216491699,
        -0.05372443422675133,
        -0.007527642417699099,
        -0.023427827283740044,
        0.051811471581459045,
        0.055182307958602905,
        -0.037183310836553574,
        -0.032669275999069214,
        0.06666772812604904,
        -0.02941904403269291,
        0.06259778141975403,
        -0.06726931780576706,
        0.05074869841337204,
        0.021510537713766098,
        -0.011293652467429638,
        -0.07094986736774445,
        -0.045281898230314255,
        0.03393544629216194,
        -0.02277977764606476,
        -0.042901407927274704,
        -0.06485181301832199,
        -0.015575955621898174,
        -0.07135450839996338,
        -0.038056839257478714,
        0.056047309190034866,
        -0.05525292828679085,
        -0.052859362214803696,
        0.03755531087517738,
        0.013229221105575562,
        0.07211696356534958,
        -0.03842535614967346,
        -0.023901022970676422,
        0.053970303386449814,
        0.005389669444411993,
        0.05618574842810631,
        -0.02442767471075058,
        0.05161241441965103,
        0.00908530130982399,
        0.05735762044787407,
        0.04620642215013504,
        -0.051924970000982285,
        0.0700295940041542,
        -0.055785171687603,
        -0.045171547681093216,
        0.03480467200279236,
        -0.038451943546533585,
        -0.01780061610043049,
        -0.007382952142506838,
        -0.035202328115701675,
        -0.044585954397916794,
        -0.029479285702109337,
        -0.0681210309267044,
        0.052289512008428574,
        0.04822952300310135,
        -0.061398740857839584,
        0.024582846090197563,
        0.060428522527217865,
        0.027539663016796112,
        0.05388077720999718,
        -0.037347450852394104,
        -0.009746483527123928,
        0.047822073101997375,
        0.052972905337810516,
        0.061961423605680466,
        -0.0633615255355835,
        0.055340006947517395,
        0.058735672384500504,
        -0.052095264196395874,
        0.054020076990127563,
        0.06348362565040588,
        -0.015497298911213875,
        -0.06734415143728256,
        0.04189136251807213,
        -0.06612679362297058,
        0.029277265071868896,
        -0.06800337880849838,
        0.021768787875771523,
        -0.029949607327580452,
        -0.06307978928089142,
        0.023394081741571426,
        -0.034747254103422165,
        -0.031190164387226105,
        0.010386858135461807,
        -0.03088843636214733,
        -0.055153198540210724,
        0.03519501909613609,
        -0.05643707513809204,
        0.03726550191640854,
        -0.0348157100379467,
        -0.038119927048683167,
        0.02184934914112091,
        0.022571103647351265,
        0.0014252198161557317,
        0.06317360699176788,
        -0.039041515439748764,
        -0.04434410482645035,
        -0.021468879655003548,
        -0.038050614297389984,
        -0.03424954041838646,
        -0.01063171960413456,
        0.04935085028409958,
        -0.05253221094608307,
        -0.06611907482147217,
        0.06589067727327347,
        -0.005425761919468641,
        -0.013664832338690758,
        0.06645192205905914,
        -0.04184684902429581,
        0.050141721963882446,
        -0.026322219520807266,
        0.06934239715337753,
        -0.07083671540021896,
        -0.014885236509144306,
        0.010986368171870708,
        0.04393072798848152,
        -0.008161568082869053,
        0.03164300322532654,
        -0.06310001760721207,
        0.07013777643442154,
        0.018644466996192932,
        -0.007450279779732227,
        -0.017607562243938446,
        -0.016086556017398834,
        -0.024739151820540428,
        0.047821301966905594,
        -0.05563464015722275,
        0.007603867445141077,
        0.03816549479961395,
        -0.06380938738584518,
        0.05741927772760391,
        0.012742696329951286,
        -0.0036464424338191748,
        0.009327875450253487,
        -0.012736411765217781,
        -0.05503600463271141,
        0.06498683243989944,
        -0.04169933497905731,
        -0.028755463659763336,
        -0.06800883263349533,
        0.02553519234061241,
        0.03792401775717735,
        -0.0022025275975465775,
        0.05664869397878647,
        -0.05076894164085388,
        -0.006011537276208401,
        -0.03942864015698433,
        0.07293333858251572,
        0.04928096756339073,
        0.04408983141183853,
        0.002540882444009185,
        -0.06344075500965118,
        -0.0647975355386734,
        0.04899751767516136,
        0.0707036629319191,
        -0.004642097745090723,
        -0.030039558187127113,
        -0.06987354904413223,
        -0.009920592419803143,
        -0.03224504739046097,
        -0.06565558910369873,
        -0.03485869988799095,
        -0.0365900881588459,
        -0.027221055701375008,
        -0.043359655886888504,
        0.05522599816322327,
        0.06088273599743843,
        0.026197470724582672,
        0.005641364958137274,
        -0.05963163077831268,
        0.03103850968182087,
        0.007428039330989122,
        0.024810144677758217,
        -0.0718749612569809,
        -0.0411020889878273,
        0.04768061265349388,
        0.00400229636579752,
        -0.029531044885516167,
        0.02517683245241642,
        0.015060549601912498,
        -0.04194383695721626,
        -0.06311478465795517,
        0.0368049331009388,
        -0.04584891349077225,
        0.059710968285799026,
        -0.04768107458949089,
        -0.04882192611694336,
        -0.06059062108397484,
        0.04892411455512047,
        0.030142467468976974,
        -0.016577232629060745,
        -0.0010007268283516169,
        0.02837800793349743,
        -0.031151412054896355,
        0.030298437923192978,
        0.05570733919739723,
        0.0159442201256752,
        0.010409798473119736,
        -0.028283050283789635,
        -0.053730934858322144,
        0.06405209749937057,
        -0.025348126888275146,
        -0.05063660442829132,
        0.00957612693309784,
        0.05053894594311714,
        -0.04798778519034386,
        -0.0487762987613678,
        0.0179978609085083,
        -0.026810958981513977,
        -0.04221151024103165,
        0.0500892773270607,
        0.05071704462170601,
        0.058899521827697754,
        0.014915389940142632,
        0.030983516946434975,
        -0.000002268231128255138,
        0.02386542782187462,
        -0.029090963304042816,
        0.06387169659137726,
        0.012973804958164692,
        -0.04413079842925072,
        -0.011277753859758377,
        -0.00014101697888690978,
        -0.0293164923787117,
        0.03519253805279732,
        -0.03922375291585922,
        0.04948287457227707,
        0.03152449056506157,
        -0.010886024683713913,
        0.04794304445385933,
        -0.04580041021108627,
        -0.020953485742211342,
        -0.0678788349032402,
        0.004426241386681795,
        0.019852958619594574,
        0.06917482614517212,
        -0.006428097374737263,
        0.04819813370704651,
        -0.053015533834695816,
        -0.06275061517953873,
        0.01924377866089344,
        0.06059580296278,
        -0.02365538664162159,
        -0.039800699800252914,
        -0.06516265124082565,
        -0.05679306015372276,
        0.05123177170753479,
        0.04210590943694115,
        0.0344172865152359,
        -0.04796762019395828,
        -0.032382965087890625,
        -0.00907875970005989,
        -0.043876927345991135,
        0.0137558588758111,
        0.053814712911844254,
        -0.019737983122467995,
        0.042991895228624344,
        0.04010343924164772,
        -0.05862924084067345,
        -0.011393237859010696,
        -0.06525026261806488,
        -0.02442270517349243,
        -0.024371923878788948,
        -0.020312361419200897,
        -0.00043658254435285926,
        -0.019047005102038383,
        0.0515470989048481,
        -0.002348570851609111,
        0.05454582720994949,
        0.07120881974697113,
        -0.06629330664873123,
        -0.033964604139328,
        -0.027065835893154144,
        -0.014043164439499378,
        0.01347512099891901,
        0.0015095010166987777,
        0.07094965130090714,
        0.0358877032995224,
        -0.06058534234762192,
        -0.0679314136505127,
        0.038055457174777985,
        0.065132737159729
    ],
    [
        -0.03945173695683479,
        0.05443822219967842,
        0.00025804268079809844,
        0.0406227707862854,
        0.025342391803860664,
        0.06625799834728241,
        0.028096554800868034,
        -0.0552140474319458,
        0.06303728371858597,
        0.04984484985470772,
        0.04863208904862404,
        -0.03248842805624008,
        -0.02692093513906002,
        -0.007034037262201309,
        -0.037764906883239746,
        0.06627295911312103,
        -0.038888558745384216,
        0.06705828756093979,
        -0.057427842170000076,
        -0.06991470605134964,
        -0.02478458359837532,
        0.06668087840080261,
        0.035545989871025085,
        0.00855643954128027,
        0.049573689699172974,
        0.04259815439581871,
        0.06378687173128128,
        -0.026796232908964157,
        0.013018985278904438,
        0.040583714842796326,
        0.028962409123778343,
        -0.029407847672700882,
        -0.056273769587278366,
        -0.06725312024354935,
        0.055500783026218414,
        -0.021762097254395485,
        -0.06402789056301117,
        -0.005105289164930582,
        0.013686725869774818,
        0.008855447173118591,
        0.06294935196638107,
        0.016024230048060417,
        0.015792300924658775,
        -0.06569752842187881,
        0.058793630450963974,
        0.039090596139431,
        0.007240437436848879,
        0.046894412487745285,
        0.05435839667916298,
        0.045904889702796936,
        0.057072196155786514,
        0.06607328355312347,
        -0.017260614782571793,
        0.061833176761865616,
        0.04874751716852188,
        -0.01746688410639763,
        0.004653097130358219,
        0.06469970941543579,
        -0.005010036751627922,
        0.0009125345968641341,
        -0.05742848664522171,
        0.06290970742702484,
        -0.040856409817934036,
        0.06512462347745895,
        -0.06351140141487122,
        -0.060030195862054825,
        0.02205069549381733,
        -0.022703418508172035,
        -0.06257564574480057,
        -0.005461397115141153,
        -0.06461901217699051,
        -0.06814312934875488,
        0.06188959628343582,
        0.04299801215529442,
        -0.008400145918130875,
        -0.006454659625887871,
        -0.011094795539975166,
        0.0693090483546257,
        0.06437104195356369,
        -0.011375393718481064,
        0.03763730823993683,
        0.06860943883657455,
        -0.050339292734861374,
        0.04981192201375961,
        0.05791822075843811,
        0.018665220588445663,
        0.06975512206554413,
        0.06328317523002625,
        0.04509684070944786,
        0.02777951769530773,
        0.026104804128408432,
        -0.04942438751459122,
        -0.06927335262298584,
        -0.03421850502490997,
        -0.018337247893214226,
        -0.00998180266469717,
        -0.021100535988807678,
        -0.06555916368961334,
        -0.028319617733359337,
        -0.004605398979038,
        -0.06623893231153488,
        0.02193429134786129,
        0.054672036319971085,
        0.004912502132356167,
        0.056246429681777954,
        -0.06663165241479874,
        -0.04835399240255356,
        0.021221516653895378,
        0.0216599702835083,
        0.03817553445696831,
        -0.06033279746770859,
        -0.05655612424015999,
        -0.0649881586432457,
        0.010406598448753357,
        -0.044837046414613724,
        0.005737392231822014,
        0.03240222483873367,
        -0.04240501672029495,
        0.06699392199516296,
        0.03602103143930435,
        -0.04988457262516022,
        0.05727042630314827,
        0.04005428031086922,
        -0.029709672555327415,
        -0.05972626060247421,
        0.011774299666285515,
        -0.06359042972326279,
        -0.03686493635177612,
        0.063593789935112,
        0.038542598485946655,
        -0.03837999328970909,
        -0.04343162477016449,
        -0.03831901773810387,
        0.021134106442332268,
        -0.04604867100715637,
        -0.043134380131959915,
        0.01592612825334072,
        -0.007149614859372377,
        -0.06118867173790932,
        0.04990100860595703,
        0.05897781252861023,
        0.008056026883423328,
        0.06040029972791672,
        0.05580797418951988,
        0.05261597782373428,
        -0.06838184595108032,
        0.06350938230752945,
        -0.062308814376592636,
        0.0067786830477416515,
        0.05994052067399025,
        0.05069049820303917,
        -0.0642257034778595,
        -0.013252303935587406,
        -0.06591418385505676,
        0.009512498043477535,
        -0.028235679492354393,
        0.025917857885360718,
        0.03670215234160423,
        -0.02726638689637184,
        0.05306113138794899,
        0.07013009488582611,
        0.026072928681969643,
        0.03870341181755066,
        -0.0012895840918645263,
        0.0035534773487597704,
        0.03609612211585045,
        -0.055772386491298676,
        -0.028027987107634544,
        0.04338778927922249,
        0.006672418676316738,
        0.03964051976799965,
        0.017656449228525162,
        -0.030296798795461655,
        -0.05441145598888397,
        -0.0020775911398231983,
        0.0617324560880661,
        -0.058152105659246445,
        -0.0343865305185318,
        0.04710598289966583,
        -0.04621906578540802,
        -0.0024046548642218113,
        0.07020477950572968,
        -0.02078743278980255,
        0.044816117733716965,
        0.048414554446935654,
        -0.0454816110432148,
        -0.029351182281970978,
        0.06255592405796051,
        -0.03638311102986336,
        -0.05612792447209358,
        -0.04830194637179375,
        0.01205142680555582,
        0.04957331717014313,
        0.007191097363829613,
        -0.01750202663242817,
        0.0074115474708378315,
        -0.04510784521698952,
        -0.06964424252510071,
        -0.025192320346832275,
        -0.008977130986750126,
        0.03902130946516991,
        -0.05643191933631897,
        0.019022909924387932,
        -0.049519509077072144,
        -0.06557824462652206,
        0.043595682829618454,
        -0.03320153057575226,
        -0.03892427682876587,
        -0.04850878566503525,
        0.066171795129776,
        -0.06254575401544571,
        0.02365935780107975,
        0.016250809654593468,
        0.05184955894947052,
        -0.03914465010166168,
        0.04234214872121811,
        0.0051951962523162365,
        -0.02698526345193386,
        0.0578065849840641,
        -0.030755234882235527,
        0.0329158678650856,
        0.06783135235309601,
        0.034276433289051056,
        -0.04972380772233009,
        0.06173936650156975,
        -0.03530598804354668,
        0.001761161140166223,
        -0.008426851592957973,
        -0.04099141061306,
        -0.045825980603694916,
        -0.02192268893122673,
        -0.055962927639484406,
        -0.0527862049639225,
        -0.005395837593823671,
        -0.037708308547735214,
        0.05425157770514488,
        0.05538635700941086,
        -0.026453454047441483,
        -0.02940976247191429,
        0.0631590336561203,
        -0.03917631879448891,
        0.06330352276563644,
        -0.06795698404312134,
        0.056476980447769165,
        0.03570837154984474,
        -0.009999856352806091,
        -0.06628472357988358,
        -0.02025376260280609,
        0.025782058015465736,
        -0.01513280812650919,
        -0.044522810727357864,
        -0.05948243662714958,
        -0.014497670345008373,
        -0.06885791569948196,
        -0.039854325354099274,
        0.049306292086839676,
        -0.05128670856356621,
        -0.056768111884593964,
        0.03729917109012604,
        0.02382923848927021,
        0.06942670792341232,
        -0.022941382601857185,
        -0.017678935080766678,
        0.05852055177092552,
        0.0020045943092554808,
        0.05350039526820183,
        0.0024945163168013096,
        0.05389567092061043,
        0.01967804506421089,
        0.05682054162025452,
        0.03651060163974762,
        -0.062307339161634445,
        0.0690101832151413,
        -0.0565154030919075,
        -0.04383260756731033,
        0.01588974893093109,
        -0.04190736636519432,
        -0.025934478268027306,
        -0.015577653422951698,
        -0.03861936926841736,
        -0.06251166760921478,
        -0.013573974370956421,
        -0.06746525317430496,
        0.04990825057029724,
        0.058455996215343475,
        -0.0616794116795063,
        0.02715875953435898,
        0.058069732040166855,
        0.04205070436000824,
        0.04868275299668312,
        -0.0492156483232975,
        -0.012590662576258183,
        0.04802248254418373,
        0.04903396964073181,
        0.06007654219865799,
        -0.061427824199199677,
        0.051787119358778,
        0.0628027468919754,
        -0.04978387430310249,
        0.051693208515644073,
        0.06280972063541412,
        -0.01364164985716343,
        -0.06455457210540771,
        0.04119220748543739,
        -0.06289590895175934,
        0.03024430386722088,
        -0.06549341231584549,
        0.021098146215081215,
        -0.030312206596136093,
        -0.059299107640981674,
        0.03367270529270172,
        -0.04417740926146507,
        -0.029891392216086388,
        0.021376028656959534,
        -0.019132213667035103,
        -0.05645463243126869,
        0.02807845175266266,
        -0.05504397675395012,
        0.037511005997657776,
        -0.046757232397794724,
        -0.029696932062506676,
        0.014886231161653996,
        0.02546447515487671,
        0.011934169568121433,
        0.061384666711091995,
        -0.04597439244389534,
        -0.034733857959508896,
        -0.00686216913163662,
        -0.03628093749284744,
        -0.03452898561954498,
        0.007745120674371719,
        0.05673697590827942,
        -0.04973780736327171,
        -0.06190318241715431,
        0.06266941130161285,
        0.009125265292823315,
        -0.04187314212322235,
        0.06268355995416641,
        -0.038908351212739944,
        0.04788019508123398,
        -0.03113403357565403,
        0.06499277055263519,
        -0.06658151000738144,
        -0.023911606520414352,
        0.013175614178180695,
        0.0438602939248085,
        0.004016792867332697,
        0.035580579191446304,
        -0.06472207605838776,
        0.06471624970436096,
        0.024414317682385445,
        0.0017468875739723444,
        -0.028316231444478035,
        -0.041789330542087555,
        -0.02761228010058403,
        0.055498912930488586,
        -0.05654595419764519,
        -0.011265222914516926,
        0.051394011825323105,
        -0.06472641229629517,
        0.0552862286567688,
        0.014198355376720428,
        0.02368229441344738,
        0.017419660463929176,
        -0.02444504201412201,
        -0.05731421709060669,
        0.06776061654090881,
        -0.04066235199570656,
        -0.0011224342742934823,
        -0.06730268895626068,
        -0.0010120782535523176,
        0.0380372516810894,
        0.027767708525061607,
        0.05139072611927986,
        -0.048379600048065186,
        -0.015870939940214157,
        -0.04999266937375069,
        0.07001037150621414,
        0.053578611463308334,
        0.0431666225194931,
        -0.005270620808005333,
        -0.06307084858417511,
        -0.059415169060230255,
        0.03627607226371765,
        0.06714531034231186,
        -0.015899578109383583,
        -0.02083924412727356,
        -0.06516998261213303,
        -0.019828619435429573,
        -0.024182109162211418,
        -0.0682019591331482,
        -0.02658115327358246,
        -0.037340570241212845,
        -0.028137868270277977,
        -0.030487060546875,
        0.05275414511561394,
        0.05986940488219261,
        0.012651000171899796,
        0.008698083460330963,
        -0.06099642068147659,
        0.01582665555179119,
        0.013903390616178513,
        0.03411208465695381,
        -0.06926589459180832,
        -0.04896363243460655,
        0.03879088908433914,
        0.009647944010794163,
        -0.030184082686901093,
        0.034244604408741,
        -0.002721248660236597,
        -0.025085777044296265,
        -0.052606381475925446,
        0.04232427477836609,
        -0.031619951128959656,
        0.05794040486216545,
        -0.043126776814460754,
        -0.05106686055660248,
        -0.0578904002904892,
        0.046728357672691345,
        0.026496514678001404,
        0.013450197875499725,
        0.006319737993180752,
        0.03816407173871994,
        -0.036433834582567215,
        0.058204106986522675,
        0.03348376601934433,
        -0.0006899983272887766,
        0.029773009940981865,
        -0.0367237888276577,
        -0.05261940136551857,
        0.06549186259508133,
        -0.031548406928777695,
        -0.05688720569014549,
        -0.004073465242981911,
        0.03207416832447052,
        -0.0527736097574234,
        -0.03048737347126007,
        0.010995707474648952,
        -0.013872141018509865,
        -0.03467918559908867,
        0.052604034543037415,
        0.060841020196676254,
        0.05972859635949135,
        0.028374595567584038,
        0.03038734197616577,
        -0.0026403663214296103,
        0.022985324263572693,
        -0.040601249784231186,
        0.06431062519550323,
        0.014780255034565926,
        -0.04656831920146942,
        -0.00026514276396483183,
        0.026462044566869736,
        -0.022033818066120148,
        0.035696376115083694,
        -0.044137269258499146,
        0.037444788962602615,
        0.035663168877363205,
        -0.016006631776690483,
        0.05950647592544556,
        -0.04984075948596001,
        -0.026225384324789047,
        -0.06743147224187851,
        0.017794055864214897,
        0.03175109997391701,
        0.06784523278474808,
        -0.009896434843540192,
        0.053554050624370575,
        -0.06167780980467796,
        -0.06358835846185684,
        0.026222141459584236,
        0.06584335118532181,
        -0.021295392885804176,
        -0.03707513585686684,
        -0.0674402192234993,
        -0.0526452399790287,
        0.03875795751810074,
        0.0452239066362381,
        0.01901734620332718,
        -0.051271744072437286,
        -0.04494209960103035,
        -0.021806521341204643,
        -0.048760075122117996,
        0.006141848862171173,
        0.051552943885326385,
        -0.007384296506643295,
        0.04484767094254494,
        0.029979048296809196,
        -0.05134940519928932,
        -0.01929338648915291,
        -0.06117726117372513,
        -0.031241416931152344,
        -0.015825394541025162,
        -0.02437412366271019,
        0.030121352523565292,
        -0.01578589901328087,
        0.05157200247049332,
        0.019170613959431648,
        0.05333884060382843,
        0.06918292492628098,
        -0.06429857760667801,
        -0.03743116557598114,
        -0.042562250047922134,
        -0.03232915326952934,
        0.03493073210120201,
        -0.005150243174284697,
        0.069280706346035,
        0.01803002320230007,
        -0.05375859886407852,
        -0.06539926677942276,
        0.03975481167435646,
        0.05859394744038582
    ],
    [
        -0.03737682104110718,
        0.045661281794309616,
        0.02006412111222744,
        0.04793066531419754,
        0.0281070563942194,
        0.0653473436832428,
        0.06250254809856415,
        -0.02667880803346634,
        0.04498141631484032,
        0.016869831830263138,
        0.03754837438464165,
        -0.04730120673775673,
        -0.047905802726745605,
        -0.0039121778681874275,
        -0.04569224640727043,
        0.05088748037815094,
        -0.0463743656873703,
        0.06549981236457825,
        -0.046610817313194275,
        -0.06485112011432648,
        -0.03262372687458992,
        0.046981245279312134,
        0.05816777050495148,
        0.055433452129364014,
        0.05768304690718651,
        0.05663448944687843,
        0.01804978772997856,
        0.05220590531826019,
        -0.04439681023359299,
        -0.037090495228767395,
        -0.005652097053825855,
        -0.00633650179952383,
        0.008642477914690971,
        -0.06121675670146942,
        0.05362609401345253,
        -0.05890628695487976,
        -0.06393454968929291,
        -0.011348477564752102,
        0.05412444844841957,
        -0.0004827269585803151,
        0.0619305744767189,
        0.008095749653875828,
        0.0462360680103302,
        -0.0637814998626709,
        0.06147174909710884,
        -0.03171025589108467,
        0.0012424653396010399,
        0.06438872963190079,
        0.052535828202962875,
        -0.0227972324937582,
        0.06210929527878761,
        0.048924557864665985,
        0.03354939445853233,
        0.030720578506588936,
        0.04515032842755318,
        0.0009048942010849714,
        -0.026137324050068855,
        0.06127775460481644,
        -0.06495290249586105,
        0.02024061419069767,
        -0.0633300393819809,
        0.05878831073641777,
        -0.05634192377328873,
        0.011361341923475266,
        -0.015972677618265152,
        -0.053719162940979004,
        0.018820706754922867,
        -0.05742049217224121,
        0.037439022213220596,
        -0.057758428156375885,
        -0.05973942205309868,
        -0.046823933720588684,
        0.06516236811876297,
        0.06117570027709007,
        -0.032663434743881226,
        -0.01450288761407137,
        0.015488109551370144,
        0.06678351759910583,
        0.06671160459518433,
        -0.009050297550857067,
        0.01376874465495348,
        0.0012193317525088787,
        -0.057399600744247437,
        0.04877815395593643,
        -0.0346260704100132,
        0.0005260977195575833,
        0.06107732281088829,
        0.06599479168653488,
        -0.04722914844751358,
        0.04423839971423149,
        -0.018916480243206024,
        -0.06353810429573059,
        -0.026583371683955193,
        -0.05568401888012886,
        0.011793607845902443,
        -0.05684873089194298,
        -0.040963802486658096,
        -0.061502449214458466,
        -0.05501215159893036,
        0.023897403851151466,
        -0.0665733814239502,
        -0.04713214188814163,
        0.058986905962228775,
        0.02426595613360405,
        0.04130103811621666,
        -0.05116191506385803,
        -0.0636632889509201,
        -0.03229498863220215,
        -0.029804067686200142,
        -0.035413917154073715,
        -0.028066353872418404,
        -0.05873926356434822,
        -0.05568929761648178,
        -0.033910904079675674,
        0.04875531792640686,
        -0.019049977883696556,
        -0.05019785463809967,
        -0.009106431156396866,
        -0.02399868704378605,
        -0.0564769022166729,
        -0.05945204570889473,
        -0.04389844089746475,
        0.025427231565117836,
        -0.04991810396313667,
        -0.06395670026540756,
        0.04828083887696266,
        -0.058529410511255264,
        0.06069415807723999,
        0.06507474929094315,
        0.02264094166457653,
        -0.059946078807115555,
        -0.0439457930624485,
        -0.020480314269661903,
        0.05842910706996918,
        -0.03912163898348808,
        -0.04509551078081131,
        0.05325772240757942,
        -0.05616714060306549,
        0.027398183941841125,
        0.046735797077417374,
        -0.007501933723688126,
        -0.010754111222922802,
        0.058762941509485245,
        0.065980926156044,
        -0.00020086859876755625,
        -0.06670129299163818,
        0.06500010192394257,
        -0.03380414843559265,
        0.003526711370795965,
        0.03338336944580078,
        -0.04141607880592346,
        -0.06258894503116608,
        -0.048223432153463364,
        -0.05798768997192383,
        0.04310537129640579,
        -0.0018895964603871107,
        -0.0013535348698496819,
        0.015114645473659039,
        -0.019647348672151566,
        0.014644288457930088,
        0.0672236904501915,
        -0.00920194759964943,
        0.048848699778318405,
        -0.0017256606370210648,
        -0.05095350742340088,
        0.01631593517959118,
        -0.04528413340449333,
        -0.06074652448296547,
        0.02562783472239971,
        0.04280952364206314,
        0.05006811395287514,
        0.01667190156877041,
        -0.06173396483063698,
        0.05708412081003189,
        -0.06290486454963684,
        -0.00037983167567290366,
        -0.02620832622051239,
        -0.006394387688487768,
        0.06566258519887924,
        0.023064441978931427,
        0.006725947838276625,
        0.06713125109672546,
        0.04949730634689331,
        -0.032777830958366394,
        0.05866711959242821,
        -0.05549713224172592,
        0.0438469722867012,
        0.05706958845257759,
        -0.00007755633123451844,
        -0.05445213243365288,
        -0.054274559020996094,
        -0.034758925437927246,
        0.05321377515792847,
        0.023842401802539825,
        0.00211399351246655,
        0.059728920459747314,
        0.045560769736766815,
        -0.06656695157289505,
        0.049401286989450455,
        0.007762988097965717,
        0.035398758947849274,
        -0.005295389331877232,
        0.032438959926366806,
        -0.0539930984377861,
        -0.05963081493973732,
        0.018057353794574738,
        0.029093677178025246,
        -0.014339908957481384,
        -0.036633700132369995,
        0.06642273813486099,
        -0.061727866530418396,
        0.031200537458062172,
        0.03961237147450447,
        0.06391920894384384,
        -0.02470550872385502,
        0.030156364664435387,
        -0.024617906659841537,
        0.022685319185256958,
        0.06492646038532257,
        -0.03661978617310524,
        0.0409146212041378,
        0.01641308329999447,
        0.0647449865937233,
        -0.0631231740117073,
        0.054414667189121246,
        -0.049315936863422394,
        -0.033616337925195694,
        -0.049546562135219574,
        -0.06344049423933029,
        -0.0579964704811573,
        -0.06587725132703781,
        -0.014919260516762733,
        -0.06317723542451859,
        -0.0189152043312788,
        -0.03985108435153961,
        -0.04414748027920723,
        0.0581829771399498,
        -0.032352764159440994,
        -0.001858144300058484,
        0.06264439970254898,
        0.00023048905131872743,
        0.022661935538053513,
        0.016976285725831985,
        0.021959533914923668,
        0.0010945966932922602,
        0.008457932621240616,
        -0.018894508481025696,
        -0.058233413845300674,
        0.019864557310938835,
        -0.02503793314099312,
        -0.03277093917131424,
        -0.06179710105061531,
        -0.03900475427508354,
        -0.06693170219659805,
        0.05433264374732971,
        0.015284539200365543,
        -0.0001537134958198294,
        -0.03302135691046715,
        0.05796365439891815,
        0.05569441616535187,
        0.061766527593135834,
        -0.003458926687017083,
        -0.016400247812271118,
        0.059440404176712036,
        -0.010225228033959866,
        0.06333091855049133,
        -0.0377533994615078,
        0.024747882038354874,
        0.00897996872663498,
        0.03977944329380989,
        0.025235075503587723,
        -0.04838339239358902,
        0.05452399328351021,
        -0.06107421964406967,
        -0.04962260276079178,
        0.049088310450315475,
        -0.05948689207434654,
        -0.04147644340991974,
        -0.008335155434906483,
        -0.0014808418927714229,
        -0.043304115533828735,
        0.04974474757909775,
        -0.04605074226856232,
        0.05035759136080742,
        0.05178447440266609,
        -0.060777124017477036,
        0.018559066578745842,
        0.06026584282517433,
        -0.031489454209804535,
        0.04399174079298973,
        -0.060792580246925354,
        -0.006800210103392601,
        0.04545820876955986,
        0.0399140864610672,
        0.02985629066824913,
        -0.060966696590185165,
        0.06620395183563232,
        0.028140133246779442,
        -0.056584782898426056,
        0.05921967327594757,
        0.027300037443637848,
        -0.029534190893173218,
        -0.06571928411722183,
        0.02890915423631668,
        -0.04653908312320709,
        -0.016858458518981934,
        -0.06370195001363754,
        0.008192718029022217,
        0.006354730110615492,
        -0.058381013572216034,
        0.028042728081345558,
        -0.04811185970902443,
        -0.008625476621091366,
        0.05136675760149956,
        -0.06074508652091026,
        -0.062328219413757324,
        0.03088047355413437,
        -0.05418191850185394,
        0.05642017722129822,
        -0.029534628614783287,
        0.013650789856910706,
        0.05663255602121353,
        0.04282551258802414,
        -0.050409331917762756,
        0.060187287628650665,
        0.048968471586704254,
        0.02101944014430046,
        -0.06648615002632141,
        -0.05229625105857849,
        -0.052412282675504684,
        -0.028051935136318207,
        0.061620231717824936,
        -0.030553217977285385,
        -0.052490588277578354,
        0.06506139039993286,
        0.003939336631447077,
        -0.005910709500312805,
        0.0655667632818222,
        -0.04445134103298187,
        0.05988228693604469,
        -0.04106840118765831,
        0.05408777296543121,
        -0.04263201355934143,
        -0.03603944554924965,
        0.031154919415712357,
        0.025904793292284012,
        0.0013806931674480438,
        0.0520276315510273,
        -0.0632687360048294,
        0.06464552134275436,
        -0.04525098204612732,
        -0.01987427845597267,
        0.027132779359817505,
        -0.05456788092851639,
        -0.03971346467733383,
        0.062022749334573746,
        -0.048825427889823914,
        0.0475042499601841,
        -0.05568588525056839,
        -0.03844793885946274,
        0.061141546815633774,
        0.046710334718227386,
        -0.029330089688301086,
        -0.030833957716822624,
        -0.05709219351410866,
        -0.056010521948337555,
        0.06559447199106216,
        -0.048637304455041885,
        -0.036504097282886505,
        -0.06592267751693726,
        0.03235793113708496,
        0.022093847393989563,
        0.029448499903082848,
        0.008726991713047028,
        -0.04505472257733345,
        -0.00645346799865365,
        0.00613001361489296,
        0.06709970533847809,
        0.05787983164191246,
        0.0013718284899368882,
        -0.022471731528639793,
        0.03376105800271034,
        -0.03787684440612793,
        0.0008536259410902858,
        0.06636863946914673,
        -0.04727451875805855,
        -0.02696431800723076,
        -0.06160340458154678,
        -0.05510319396853447,
        -0.04005948826670647,
        -0.020477745682001114,
        0.016120729967951775,
        -0.04790731146931648,
        -0.01450720801949501,
        -0.010285492986440659,
        0.05544523522257805,
        0.06551934033632278,
        -0.03282216936349869,
        -0.055548008531332016,
        -0.05784725025296211,
        0.058526888489723206,
        -0.02790237031877041,
        -0.0044282712042331696,
        -0.0057695843279361725,
        -0.04538126289844513,
        0.0591546855866909,
        0.011762400157749653,
        -0.027897417545318604,
        0.04023120924830437,
        0.0006332406192086637,
        -0.0394565686583519,
        -0.057193491607904434,
        -0.013479995541274548,
        -0.059118594974279404,
        0.055365897715091705,
        -0.012910442426800728,
        -0.05534109100699425,
        -0.06587106734514236,
        0.061435677111148834,
        0.025592315942049026,
        -0.05375940352678299,
        0.004858748055994511,
        0.024610796943306923,
        -0.054800357669591904,
        -0.026636023074388504,
        0.06533566862344742,
        -0.006986022926867008,
        -0.03439151868224144,
        -0.06384924799203873,
        -0.04843328520655632,
        0.055751945823431015,
        -0.002476784633472562,
        0.05468248203396797,
        0.05101962760090828,
        0.021191483363509178,
        -0.01600399985909462,
        -0.05773085728287697,
        0.007558024954050779,
        -0.029189009219408035,
        -0.060799963772296906,
        -0.007365646306425333,
        0.017584003508090973,
        0.06013651564717293,
        0.005449420306831598,
        -0.04663151502609253,
        -0.01933080516755581,
        0.04548884928226471,
        -0.05369190126657486,
        0.05740911513566971,
        -0.0605599544942379,
        0.009767694398760796,
        -0.0385974757373333,
        0.057128340005874634,
        -0.05370720103383064,
        0.04522079601883888,
        -0.03413483127951622,
        0.05373553931713104,
        0.029573222622275352,
        0.027629157528281212,
        0.05602899566292763,
        -0.042450498789548874,
        -0.05391978472471237,
        -0.04011402651667595,
        -0.05526883527636528,
        0.012748397886753082,
        0.06521719694137573,
        -0.05316576734185219,
        0.04851445183157921,
        -0.058998823165893555,
        -0.06319110840559006,
        0.020342199131846428,
        0.03792206943035126,
        0.007018154952675104,
        -0.005004206206649542,
        0.04568430408835411,
        -0.04451988637447357,
        0.012083921581506729,
        0.03175992891192436,
        0.03085688129067421,
        -0.01061260886490345,
        -0.031420424580574036,
        0.05138044059276581,
        -0.020691625773906708,
        0.044225092977285385,
        0.040240224450826645,
        0.004899635910987854,
        0.016911109909415245,
        0.01987876184284687,
        -0.06510473787784576,
        0.018079906702041626,
        -0.050273019820451736,
        -0.05232475697994232,
        0.030456053093075752,
        0.011320105753839016,
        0.019807809963822365,
        -0.036694157868623734,
        -0.03462860733270645,
        -0.05660843104124069,
        0.06051746383309364,
        0.0384654700756073,
        -0.031041165813803673,
        -0.004398443270474672,
        -0.05665327608585358,
        0.0007701533031649888,
        0.021397490054368973,
        0.04869411885738373,
        0.06229564920067787,
        0.02872278355062008,
        -0.040835190564394,
        -0.039213404059410095,
        0.05815469101071358,
        0.04842037707567215
    ],
    [
        -0.03737682104110718,
        0.045661281794309616,
        0.02006412111222744,
        0.04793066531419754,
        0.0281070563942194,
        0.0653473436832428,
        0.06250254809856415,
        -0.02667880803346634,
        0.04498141631484032,
        0.016869831830263138,
        0.03754837438464165,
        -0.04730120673775673,
        -0.047905802726745605,
        -0.0039121778681874275,
        -0.04569224640727043,
        0.05088748037815094,
        -0.0463743656873703,
        0.06549981236457825,
        -0.046610817313194275,
        -0.06485112011432648,
        -0.03262372687458992,
        0.046981245279312134,
        0.05816777050495148,
        0.055433452129364014,
        0.05768304690718651,
        0.05663448944687843,
        0.01804978772997856,
        0.05220590531826019,
        -0.04439681023359299,
        -0.037090495228767395,
        -0.005652097053825855,
        -0.00633650179952383,
        0.008642477914690971,
        -0.06121675670146942,
        0.05362609401345253,
        -0.05890628695487976,
        -0.06393454968929291,
        -0.011348477564752102,
        0.05412444844841957,
        -0.0004827269585803151,
        0.0619305744767189,
        0.008095749653875828,
        0.0462360680103302,
        -0.0637814998626709,
        0.06147174909710884,
        -0.03171025589108467,
        0.0012424653396010399,
        0.06438872963190079,
        0.052535828202962875,
        -0.0227972324937582,
        0.06210929527878761,
        0.048924557864665985,
        0.03354939445853233,
        0.030720578506588936,
        0.04515032842755318,
        0.0009048942010849714,
        -0.026137324050068855,
        0.06127775460481644,
        -0.06495290249586105,
        0.02024061419069767,
        -0.0633300393819809,
        0.05878831073641777,
        -0.05634192377328873,
        0.011361341923475266,
        -0.015972677618265152,
        -0.053719162940979004,
        0.018820706754922867,
        -0.05742049217224121,
        0.037439022213220596,
        -0.057758428156375885,
        -0.05973942205309868,
        -0.046823933720588684,
        0.06516236811876297,
        0.06117570027709007,
        -0.032663434743881226,
        -0.01450288761407137,
        0.015488109551370144,
        0.06678351759910583,
        0.06671160459518433,
        -0.009050297550857067,
        0.01376874465495348,
        0.0012193317525088787,
        -0.057399600744247437,
        0.04877815395593643,
        -0.0346260704100132,
        0.0005260977195575833,
        0.06107732281088829,
        0.06599479168653488,
        -0.04722914844751358,
        0.04423839971423149,
        -0.018916480243206024,
        -0.06353810429573059,
        -0.026583371683955193,
        -0.05568401888012886,
        0.011793607845902443,
        -0.05684873089194298,
        -0.040963802486658096,
        -0.061502449214458466,
        -0.05501215159893036,
        0.023897403851151466,
        -0.0665733814239502,
        -0.04713214188814163,
        0.058986905962228775,
        0.02426595613360405,
        0.04130103811621666,
        -0.05116191506385803,
        -0.0636632889509201,
        -0.03229498863220215,
        -0.029804067686200142,
        -0.035413917154073715,
        -0.028066353872418404,
        -0.05873926356434822,
        -0.05568929761648178,
        -0.033910904079675674,
        0.04875531792640686,
        -0.019049977883696556,
        -0.05019785463809967,
        -0.009106431156396866,
        -0.02399868704378605,
        -0.0564769022166729,
        -0.05945204570889473,
        -0.04389844089746475,
        0.025427231565117836,
        -0.04991810396313667,
        -0.06395670026540756,
        0.04828083887696266,
        -0.058529410511255264,
        0.06069415807723999,
        0.06507474929094315,
        0.02264094166457653,
        -0.059946078807115555,
        -0.0439457930624485,
        -0.020480314269661903,
        0.05842910706996918,
        -0.03912163898348808,
        -0.04509551078081131,
        0.05325772240757942,
        -0.05616714060306549,
        0.027398183941841125,
        0.046735797077417374,
        -0.007501933723688126,
        -0.010754111222922802,
        0.058762941509485245,
        0.065980926156044,
        -0.00020086859876755625,
        -0.06670129299163818,
        0.06500010192394257,
        -0.03380414843559265,
        0.003526711370795965,
        0.03338336944580078,
        -0.04141607880592346,
        -0.06258894503116608,
        -0.048223432153463364,
        -0.05798768997192383,
        0.04310537129640579,
        -0.0018895964603871107,
        -0.0013535348698496819,
        0.015114645473659039,
        -0.019647348672151566,
        0.014644288457930088,
        0.0672236904501915,
        -0.00920194759964943,
        0.048848699778318405,
        -0.0017256606370210648,
        -0.05095350742340088,
        0.01631593517959118,
        -0.04528413340449333,
        -0.06074652448296547,
        0.02562783472239971,
        0.04280952364206314,
        0.05006811395287514,
        0.01667190156877041,
        -0.06173396483063698,
        0.05708412081003189,
        -0.06290486454963684,
        -0.00037983167567290366,
        -0.02620832622051239,
        -0.006394387688487768,
        0.06566258519887924,
        0.023064441978931427,
        0.006725947838276625,
        0.06713125109672546,
        0.04949730634689331,
        -0.032777830958366394,
        0.05866711959242821,
        -0.05549713224172592,
        0.0438469722867012,
        0.05706958845257759,
        -0.00007755633123451844,
        -0.05445213243365288,
        -0.054274559020996094,
        -0.034758925437927246,
        0.05321377515792847,
        0.023842401802539825,
        0.00211399351246655,
        0.059728920459747314,
        0.045560769736766815,
        -0.06656695157289505,
        0.049401286989450455,
        0.007762988097965717,
        0.035398758947849274,
        -0.005295389331877232,
        0.032438959926366806,
        -0.0539930984377861,
        -0.05963081493973732,
        0.018057353794574738,
        0.029093677178025246,
        -0.014339908957481384,
        -0.036633700132369995,
        0.06642273813486099,
        -0.061727866530418396,
        0.031200537458062172,
        0.03961237147450447,
        0.06391920894384384,
        -0.02470550872385502,
        0.030156364664435387,
        -0.024617906659841537,
        0.022685319185256958,
        0.06492646038532257,
        -0.03661978617310524,
        0.0409146212041378,
        0.01641308329999447,
        0.0647449865937233,
        -0.0631231740117073,
        0.054414667189121246,
        -0.049315936863422394,
        -0.033616337925195694,
        -0.049546562135219574,
        -0.06344049423933029,
        -0.0579964704811573,
        -0.06587725132703781,
        -0.014919260516762733,
        -0.06317723542451859,
        -0.0189152043312788,
        -0.03985108435153961,
        -0.04414748027920723,
        0.0581829771399498,
        -0.032352764159440994,
        -0.001858144300058484,
        0.06264439970254898,
        0.00023048905131872743,
        0.022661935538053513,
        0.016976285725831985,
        0.021959533914923668,
        0.0010945966932922602,
        0.008457932621240616,
        -0.018894508481025696,
        -0.058233413845300674,
        0.019864557310938835,
        -0.02503793314099312,
        -0.03277093917131424,
        -0.06179710105061531,
        -0.03900475427508354,
        -0.06693170219659805,
        0.05433264374732971,
        0.015284539200365543,
        -0.0001537134958198294,
        -0.03302135691046715,
        0.05796365439891815,
        0.05569441616535187,
        0.061766527593135834,
        -0.003458926687017083,
        -0.016400247812271118,
        0.059440404176712036,
        -0.010225228033959866,
        0.06333091855049133,
        -0.0377533994615078,
        0.024747882038354874,
        0.00897996872663498,
        0.03977944329380989,
        0.025235075503587723,
        -0.04838339239358902,
        0.05452399328351021,
        -0.06107421964406967,
        -0.04962260276079178,
        0.049088310450315475,
        -0.05948689207434654,
        -0.04147644340991974,
        -0.008335155434906483,
        -0.0014808418927714229,
        -0.043304115533828735,
        0.04974474757909775,
        -0.04605074226856232,
        0.05035759136080742,
        0.05178447440266609,
        -0.060777124017477036,
        0.018559066578745842,
        0.06026584282517433,
        -0.031489454209804535,
        0.04399174079298973,
        -0.060792580246925354,
        -0.006800210103392601,
        0.04545820876955986,
        0.0399140864610672,
        0.02985629066824913,
        -0.060966696590185165,
        0.06620395183563232,
        0.028140133246779442,
        -0.056584782898426056,
        0.05921967327594757,
        0.027300037443637848,
        -0.029534190893173218,
        -0.06571928411722183,
        0.02890915423631668,
        -0.04653908312320709,
        -0.016858458518981934,
        -0.06370195001363754,
        0.008192718029022217,
        0.006354730110615492,
        -0.058381013572216034,
        0.028042728081345558,
        -0.04811185970902443,
        -0.008625476621091366,
        0.05136675760149956,
        -0.06074508652091026,
        -0.062328219413757324,
        0.03088047355413437,
        -0.05418191850185394,
        0.05642017722129822,
        -0.029534628614783287,
        0.013650789856910706,
        0.05663255602121353,
        0.04282551258802414,
        -0.050409331917762756,
        0.060187287628650665,
        0.048968471586704254,
        0.02101944014430046,
        -0.06648615002632141,
        -0.05229625105857849,
        -0.052412282675504684,
        -0.028051935136318207,
        0.061620231717824936,
        -0.030553217977285385,
        -0.052490588277578354,
        0.06506139039993286,
        0.003939336631447077,
        -0.005910709500312805,
        0.0655667632818222,
        -0.04445134103298187,
        0.05988228693604469,
        -0.04106840118765831,
        0.05408777296543121,
        -0.04263201355934143,
        -0.03603944554924965,
        0.031154919415712357,
        0.025904793292284012,
        0.0013806931674480438,
        0.0520276315510273,
        -0.0632687360048294,
        0.06464552134275436,
        -0.04525098204612732,
        -0.01987427845597267,
        0.027132779359817505,
        -0.05456788092851639,
        -0.03971346467733383,
        0.062022749334573746,
        -0.048825427889823914,
        0.0475042499601841,
        -0.05568588525056839,
        -0.03844793885946274,
        0.061141546815633774,
        0.046710334718227386,
        -0.029330089688301086,
        -0.030833957716822624,
        -0.05709219351410866,
        -0.056010521948337555,
        0.06559447199106216,
        -0.048637304455041885,
        -0.036504097282886505,
        -0.06592267751693726,
        0.03235793113708496,
        0.022093847393989563,
        0.029448499903082848,
        0.008726991713047028,
        -0.04505472257733345,
        -0.00645346799865365,
        0.00613001361489296,
        0.06709970533847809,
        0.05787983164191246,
        0.0013718284899368882,
        -0.022471731528639793,
        0.03376105800271034,
        -0.03787684440612793,
        0.0008536259410902858,
        0.06636863946914673,
        -0.04727451875805855,
        -0.02696431800723076,
        -0.06160340458154678,
        -0.05510319396853447,
        -0.04005948826670647,
        -0.020477745682001114,
        0.016120729967951775,
        -0.04790731146931648,
        -0.01450720801949501,
        -0.010285492986440659,
        0.05544523522257805,
        0.06551934033632278,
        -0.03282216936349869,
        -0.055548008531332016,
        -0.05784725025296211,
        0.058526888489723206,
        -0.02790237031877041,
        -0.0044282712042331696,
        -0.0057695843279361725,
        -0.04538126289844513,
        0.0591546855866909,
        0.011762400157749653,
        -0.027897417545318604,
        0.04023120924830437,
        0.0006332406192086637,
        -0.0394565686583519,
        -0.057193491607904434,
        -0.013479995541274548,
        -0.059118594974279404,
        0.055365897715091705,
        -0.012910442426800728,
        -0.05534109100699425,
        -0.06587106734514236,
        0.061435677111148834,
        0.025592315942049026,
        -0.05375940352678299,
        0.004858748055994511,
        0.024610796943306923,
        -0.054800357669591904,
        -0.026636023074388504,
        0.06533566862344742,
        -0.006986022926867008,
        -0.03439151868224144,
        -0.06384924799203873,
        -0.04843328520655632,
        0.055751945823431015,
        -0.002476784633472562,
        0.05468248203396797,
        0.05101962760090828,
        0.021191483363509178,
        -0.01600399985909462,
        -0.05773085728287697,
        0.007558024954050779,
        -0.029189009219408035,
        -0.060799963772296906,
        -0.007365646306425333,
        0.017584003508090973,
        0.06013651564717293,
        0.005449420306831598,
        -0.04663151502609253,
        -0.01933080516755581,
        0.04548884928226471,
        -0.05369190126657486,
        0.05740911513566971,
        -0.0605599544942379,
        0.009767694398760796,
        -0.0385974757373333,
        0.057128340005874634,
        -0.05370720103383064,
        0.04522079601883888,
        -0.03413483127951622,
        0.05373553931713104,
        0.029573222622275352,
        0.027629157528281212,
        0.05602899566292763,
        -0.042450498789548874,
        -0.05391978472471237,
        -0.04011402651667595,
        -0.05526883527636528,
        0.012748397886753082,
        0.06521719694137573,
        -0.05316576734185219,
        0.04851445183157921,
        -0.058998823165893555,
        -0.06319110840559006,
        0.020342199131846428,
        0.03792206943035126,
        0.007018154952675104,
        -0.005004206206649542,
        0.04568430408835411,
        -0.04451988637447357,
        0.012083921581506729,
        0.03175992891192436,
        0.03085688129067421,
        -0.01061260886490345,
        -0.031420424580574036,
        0.05138044059276581,
        -0.020691625773906708,
        0.044225092977285385,
        0.040240224450826645,
        0.004899635910987854,
        0.016911109909415245,
        0.01987876184284687,
        -0.06510473787784576,
        0.018079906702041626,
        -0.050273019820451736,
        -0.05232475697994232,
        0.030456053093075752,
        0.011320105753839016,
        0.019807809963822365,
        -0.036694157868623734,
        -0.03462860733270645,
        -0.05660843104124069,
        0.06051746383309364,
        0.0384654700756073,
        -0.031041165813803673,
        -0.004398443270474672,
        -0.05665327608585358,
        0.0007701533031649888,
        0.021397490054368973,
        0.04869411885738373,
        0.06229564920067787,
        0.02872278355062008,
        -0.040835190564394,
        -0.039213404059410095,
        0.05815469101071358,
        0.04842037707567215
    ],
    [
        -0.03179774805903435,
        0.012207061983644962,
        0.023453017696738243,
        0.046241652220487595,
        0.057113174349069595,
        0.05736536160111427,
        0.06295938044786453,
        -0.04838106408715248,
        0.04205094277858734,
        0.05661483108997345,
        0.06068282201886177,
        -0.024553081020712852,
        -0.053695157170295715,
        -0.008258108049631119,
        -0.03845684602856636,
        0.04274594783782959,
        -0.04221084713935852,
        0.060159049928188324,
        -0.03775046020746231,
        -0.062025781720876694,
        -0.027086475864052773,
        0.04503849521279335,
        0.06014976277947426,
        0.05252474173903465,
        0.055021826177835464,
        0.048806145787239075,
        0.048771463334560394,
        0.02642333135008812,
        -0.05909152328968048,
        -0.004452548921108246,
        -0.021460862830281258,
        0.0034474527928978205,
        -0.0024664942175149918,
        -0.0626075267791748,
        0.0515073798596859,
        -0.060185160487890244,
        -0.06147139146924019,
        0.0018699413631111383,
        0.03783554956316948,
        0.03851759061217308,
        0.05377988889813423,
        -0.029660223051905632,
        0.038541294634342194,
        -0.061135243624448776,
        0.053409744054079056,
        -0.017448244616389275,
        0.02188306674361229,
        0.0591341108083725,
        0.06158333644270897,
        -0.0290028415620327,
        0.057821739464998245,
        0.016129400581121445,
        0.05459021404385567,
        0.009303053840994835,
        0.040515363216400146,
        0.026557557284832,
        -0.02777736634016037,
        0.059958089143037796,
        -0.06040317192673683,
        -0.0001833190326578915,
        -0.060318026691675186,
        0.06183977052569389,
        -0.04679252952337265,
        0.01934291049838066,
        0.008820573799312115,
        -0.0404270775616169,
        -0.027476966381072998,
        -0.04058632627129555,
        0.04230603203177452,
        -0.05371503904461861,
        -0.0580214262008667,
        -0.0583549439907074,
        0.06215059757232666,
        0.06026306003332138,
        -0.029148729518055916,
        -0.009881113655865192,
        0.006437766831368208,
        0.06317257881164551,
        0.06223757937550545,
        -0.005315262824296951,
        0.01095342542976141,
        -0.03097550757229328,
        -0.048158206045627594,
        0.04657465219497681,
        -0.03610462322831154,
        0.012269485741853714,
        0.05219900980591774,
        0.060199905186891556,
        -0.044067081063985825,
        0.043117180466651917,
        -0.04473652318120003,
        -0.048097316175699234,
        -0.053690388798713684,
        -0.060689136385917664,
        0.014196882955729961,
        -0.03563142567873001,
        -0.03854820132255554,
        -0.06215668469667435,
        -0.052849236875772476,
        0.01260182075202465,
        -0.06309397518634796,
        -0.05135165527462959,
        0.05709973722696304,
        0.029920918866991997,
        0.05570979788899422,
        -0.054203715175390244,
        -0.06110560521483421,
        0.03710582107305527,
        -0.05569294095039368,
        -0.02805347740650177,
        -0.05160481110215187,
        -0.05698348209261894,
        -0.05940760672092438,
        -0.02154405601322651,
        0.04832155629992485,
        -0.001427386305294931,
        -0.05580343306064606,
        -0.028679391369223595,
        -0.0420730859041214,
        -0.05061257258057594,
        -0.052893929183483124,
        -0.04204307496547699,
        0.05094518885016441,
        -0.053092096000909805,
        -0.06035173311829567,
        0.061270684003829956,
        -0.05609065666794777,
        0.05177122727036476,
        0.052648868411779404,
        0.028242573142051697,
        -0.05189420282840729,
        -0.0476132370531559,
        -0.02316456474363804,
        0.052849046885967255,
        -0.039833370596170425,
        -0.03770991042256355,
        0.053707052022218704,
        -0.054135847836732864,
        0.02043941058218479,
        0.059560272842645645,
        -0.009386744350194931,
        0.017115255817770958,
        0.04976765066385269,
        0.06316814571619034,
        0.0057946243323385715,
        -0.06107734143733978,
        0.06144170090556145,
        -0.046121638268232346,
        -0.0004545331175904721,
        0.0353362113237381,
        -0.0469735786318779,
        -0.06133192405104637,
        -0.05064542591571808,
        -0.0613899864256382,
        0.04913143441081047,
        0.011788701638579369,
        -0.022457804530858994,
        0.0023970974143594503,
        0.0051070875488221645,
        0.03400805965065956,
        0.06322528421878815,
        -0.005924778990447521,
        0.0372854620218277,
        -0.029389793053269386,
        -0.059186775237321854,
        0.012931001372635365,
        -0.012730855494737625,
        -0.05409718304872513,
        0.027907293289899826,
        0.04635343700647354,
        0.049216657876968384,
        0.027845298871397972,
        -0.06169917806982994,
        0.059647053480148315,
        -0.06146359443664551,
        0.03576573729515076,
        -0.010581976734101772,
        -0.029909763485193253,
        0.06057169288396835,
        0.015254695899784565,
        -0.01353728212416172,
        0.06320139765739441,
        0.051841069012880325,
        -0.04663247615098953,
        0.054328128695487976,
        -0.05986044928431511,
        0.044868044555187225,
        0.05715186521410942,
        -0.01015547662973404,
        -0.06134747341275215,
        -0.05976584181189537,
        -0.03402514010667801,
        0.054882314056158066,
        -0.00001430848988093203,
        -0.005271624308079481,
        0.04624897614121437,
        0.03304794058203697,
        -0.06267882883548737,
        0.044249583035707474,
        0.002496765460819006,
        0.05441633239388466,
        0.0048224241472780704,
        0.02493559755384922,
        -0.058417536318302155,
        -0.052179090678691864,
        0.018366942182183266,
        -0.03180107846856117,
        -0.015216296538710594,
        -0.041830532252788544,
        0.06302385777235031,
        -0.06184450909495354,
        0.03187299892306328,
        0.04953215271234512,
        0.062201905995607376,
        -0.02881229668855667,
        0.03512372076511383,
        0.02154950238764286,
        -0.019401637837290764,
        0.0627141073346138,
        -0.03906714916229248,
        0.020582672208547592,
        0.021439630538225174,
        0.05032410845160484,
        -0.06181168556213379,
        0.06054212525486946,
        -0.05229298397898674,
        0.021216601133346558,
        -0.051645342260599136,
        -0.06058570742607117,
        -0.0609658882021904,
        -0.05829188972711563,
        -0.0395096018910408,
        -0.05695478245615959,
        0.00984363816678524,
        -0.03346817195415497,
        0.05342484265565872,
        0.04946603998541832,
        0.022633224725723267,
        -0.013346252031624317,
        0.05499262362718582,
        -0.008413156494498253,
        0.025686150416731834,
        0.019692610949277878,
        0.006835786160081625,
        0.03218885511159897,
        0.030291102826595306,
        0.009075331501662731,
        -0.041987016797065735,
        0.018180789425969124,
        -0.011757479049265385,
        0.018913039937615395,
        -0.05887765809893608,
        -0.051976658403873444,
        -0.06313247233629227,
        0.042718514800071716,
        0.043709803372621536,
        -0.02417459338903427,
        -0.04788540303707123,
        0.060031305998563766,
        0.0556769035756588,
        0.05700714513659477,
        0.023486938327550888,
        0.019832080230116844,
        0.06149114668369293,
        -0.027034375816583633,
        0.06085359677672386,
        -0.023648574948310852,
        0.029753265902400017,
        0.05513101816177368,
        0.03633279725909233,
        0.0017400014912709594,
        -0.05719137564301491,
        0.060949504375457764,
        -0.059326205402612686,
        -0.05483894795179367,
        -0.00013104222307447344,
        -0.05691039189696312,
        -0.04259607568383217,
        0.013739327900111675,
        0.00533333420753479,
        -0.06246810778975487,
        0.03752583637833595,
        -0.042080506682395935,
        0.0326608270406723,
        0.05808116868138313,
        -0.060874585062265396,
        -0.0019044384825974703,
        0.060036949813365936,
        -0.04190119355916977,
        0.05006362497806549,
        -0.051562272012233734,
        0.026321355253458023,
        0.054984983056783676,
        0.033424071967601776,
        0.038334865123033524,
        -0.05333641171455383,
        0.06197741627693176,
        0.02495761588215828,
        -0.05401000380516052,
        0.060646139085292816,
        0.03027038834989071,
        -0.0010283488081768155,
        -0.06068897247314453,
        0.05342769995331764,
        -0.021489020437002182,
        -0.013930496759712696,
        -0.0590687096118927,
        0.018253076821565628,
        0.0065906536765396595,
        -0.03508995473384857,
        0.05081043764948845,
        -0.051996201276779175,
        0.011176022700965405,
        0.05192428454756737,
        -0.05119948461651802,
        -0.06099195405840874,
        0.03139718621969223,
        -0.04428815841674805,
        0.047844476997852325,
        -0.030163636431097984,
        0.017538469284772873,
        0.015750467777252197,
        0.034410908818244934,
        -0.051302991807460785,
        0.060981784015893936,
        0.0417807437479496,
        0.053200818598270416,
        -0.05918681249022484,
        -0.05508732423186302,
        -0.059555478394031525,
        0.01621396467089653,
        0.06283196806907654,
        -0.045787446200847626,
        -0.05620309337973595,
        0.06192086637020111,
        -0.005939225200563669,
        -0.04675082117319107,
        0.06283780932426453,
        -0.04253263399004936,
        0.053744055330753326,
        -0.03418832644820213,
        0.026182198897004128,
        -0.04727059230208397,
        -0.030086703598499298,
        0.04961753264069557,
        0.0476895235478878,
        0.026965200901031494,
        0.049323149025440216,
        -0.06142546609044075,
        0.059551287442445755,
        -0.036295827478170395,
        0.0028813073877245188,
        0.02815854921936989,
        -0.05435663089156151,
        -0.023251505568623543,
        0.05698178708553314,
        -0.03724474459886551,
        0.03195951506495476,
        -0.050210289657115936,
        -0.04751377925276756,
        0.05837779864668846,
        0.018784115090966225,
        -0.01717386581003666,
        -0.0013347638305276632,
        -0.059430889785289764,
        -0.05931558459997177,
        0.06259828060865402,
        -0.0506819486618042,
        -0.015664633363485336,
        -0.06290113925933838,
        -0.007432194892317057,
        -0.03904033079743385,
        0.049455590546131134,
        0.006776215508580208,
        -0.037315234541893005,
        0.029972191900014877,
        0.021667346358299255,
        0.06310064345598221,
        0.053039371967315674,
        -0.0026522851549088955,
        -0.04655386880040169,
        0.0188930481672287,
        -0.02489825151860714,
        0.005561481229960918,
        0.05986166372895241,
        -0.059995464980602264,
        -0.006736623123288155,
        -0.05669974908232689,
        -0.05122138932347298,
        -0.0407518595457077,
        -0.01786857284605503,
        0.010493298061192036,
        -0.0477043017745018,
        -0.026645276695489883,
        0.0013077479088678956,
        0.05154023692011833,
        0.06208846718072891,
        -0.03727690130472183,
        -0.03240082040429115,
        -0.0616665855050087,
        0.042347438633441925,
        -0.03380228206515312,
        0.02052348107099533,
        0.0014176355907693505,
        -0.058292921632528305,
        0.036965858191251755,
        0.014899134635925293,
        -0.02643822506070137,
        0.04999914765357971,
        -0.025300858542323112,
        -0.020313415676355362,
        -0.04543377831578255,
        -0.030417095869779587,
        -0.05868560075759888,
        0.05341627821326256,
        -0.04577219486236572,
        -0.050036121159791946,
        -0.061035141348838806,
        0.05386076867580414,
        0.018518947064876556,
        -0.016424817964434624,
        -0.040331561118364334,
        0.046945299953222275,
        -0.05566369742155075,
        0.05025294050574303,
        0.04421659931540489,
        -0.03537975624203682,
        0.0074315546080470085,
        -0.05996072292327881,
        -0.055681437253952026,
        0.05984615534543991,
        -0.0278388112783432,
        0.05226559191942215,
        0.05280201509594917,
        -0.017751382663846016,
        -0.036795761436223984,
        -0.035773564130067825,
        0.02876323275268078,
        -0.0050585949793457985,
        -0.05633235350251198,
        0.009467823430895805,
        0.054144568741321564,
        0.061452146619558334,
        0.050270747393369675,
        -0.0399363674223423,
        -0.02816084213554859,
        0.012497401796281338,
        -0.05573219805955887,
        0.0588756687939167,
        -0.03958061710000038,
        0.013502266258001328,
        0.022675199434161186,
        0.0593203529715538,
        -0.06123530864715576,
        0.05731743946671486,
        -0.04683683440089226,
        0.01637994311749935,
        0.02130797691643238,
        0.04797033593058586,
        0.06250784546136856,
        -0.0416397787630558,
        -0.05638764426112175,
        -0.05067108944058418,
        -0.056882210075855255,
        0.0526311993598938,
        0.06281755864620209,
        -0.05799806863069534,
        0.05253567546606064,
        -0.05977802351117134,
        -0.06289288401603699,
        0.016291093081235886,
        0.04943937435746193,
        -0.021977797150611877,
        -0.043193139135837555,
        0.04106641933321953,
        -0.03976140916347504,
        0.013723679818212986,
        0.048539333045482635,
        -0.015050167217850685,
        0.015716658905148506,
        -0.057985007762908936,
        0.04642019048333168,
        -0.017605748027563095,
        0.03404418006539345,
        0.0488884337246418,
        0.01987074501812458,
        0.05644075945019722,
        0.031362809240818024,
        -0.06126336753368378,
        0.010329145938158035,
        -0.034902166575193405,
        -0.05215035378932953,
        0.04155736789107323,
        -0.004693182650953531,
        0.028248658403754234,
        -0.026820996776223183,
        -0.039699990302324295,
        -0.05977366119623184,
        0.05828311666846275,
        0.045772768557071686,
        -0.05549159646034241,
        -0.052787311375141144,
        -0.05802595615386963,
        -0.031245093792676926,
        0.05535861477255821,
        0.06052654609084129,
        0.06090357527136803,
        0.010472696274518967,
        -0.04040433466434479,
        -0.02888641320168972,
        0.05905910208821297,
        0.03757389262318611
    ],
    [
        0.0030185554642230272,
        0.05854979157447815,
        0.03528955951333046,
        0.027727043256163597,
        -0.007150889839977026,
        0.02619699388742447,
        -0.010440414771437645,
        -0.0660666972398758,
        0.06110639125108719,
        -0.04400625452399254,
        0.014907421544194221,
        0.031036779284477234,
        -0.0422242097556591,
        -0.05902167409658432,
        -0.05801020562648773,
        0.06432262808084488,
        0.015779150649905205,
        0.061492919921875,
        -0.05479765310883522,
        0.008961872197687626,
        0.044220924377441406,
        0.06365124136209488,
        0.04804563522338867,
        0.03233622387051582,
        0.039249811321496964,
        0.05293111503124237,
        0.026456935331225395,
        0.004860830493271351,
        0.029332494363188744,
        0.02127404324710369,
        0.0661667138338089,
        -0.01562401745468378,
        -0.061684709042310715,
        -0.059211067855358124,
        0.05039244890213013,
        -0.007814419455826283,
        -0.06334160268306732,
        -0.022072916850447655,
        -0.05116532742977142,
        -0.010875621810555458,
        0.012137438170611858,
        0.017435697838664055,
        0.025143606588244438,
        -0.06262820959091187,
        0.05725300684571266,
        0.04687485098838806,
        -0.0566411130130291,
        0.04421868175268173,
        0.04100244492292404,
        0.04003119841217995,
        0.04614802449941635,
        0.06584429740905762,
        -0.050968270748853683,
        -0.004214096814393997,
        0.056224893778562546,
        -0.022520774975419044,
        -0.0007843144121579826,
        0.011445801705121994,
        -0.03834627568721771,
        -0.014002514071762562,
        -0.06284044682979584,
        0.022641794756054878,
        -0.047124505043029785,
        0.06270092725753784,
        -0.06490392237901688,
        -0.06208100542426109,
        -0.006985274609178305,
        -0.03445930778980255,
        -0.0535859540104866,
        -0.04795462265610695,
        -0.053701162338256836,
        -0.0655691996216774,
        0.059094227850437164,
        0.0480840690433979,
        -0.025186879560351372,
        -0.03512613847851753,
        -0.0020613325759768486,
        0.06755739450454712,
        0.008720532059669495,
        0.0390961579978466,
        -0.002988731022924185,
        0.060821112245321274,
        -0.029951412230730057,
        0.022907258942723274,
        -0.029581531882286072,
        0.03101525269448757,
        0.02210962399840355,
        -0.041044171899557114,
        0.0487993024289608,
        0.057429395616054535,
        0.062351349741220474,
        0.033095840364694595,
        -0.0632752999663353,
        -0.039227329194545746,
        -0.040440741926431656,
        0.05728554353117943,
        0.016560915857553482,
        -0.0592447891831398,
        -0.002195097506046295,
        -0.014818510971963406,
        -0.06490366905927658,
        0.01823858730494976,
        0.06080687418580055,
        0.017900612205266953,
        0.04486053064465523,
        -0.051240235567092896,
        -0.013601797632873058,
        0.043171267956495285,
        -0.01760455220937729,
        0.04967639222741127,
        -0.03509141132235527,
        0.0499332919716835,
        -0.05077275633811951,
        -0.037759121507406235,
        0.03209752216935158,
        0.0151043850928545,
        0.04771365225315094,
        0.008890541270375252,
        0.06710782647132874,
        0.025686005130410194,
        -0.012606428936123848,
        0.06100462004542351,
        0.01166121382266283,
        -0.05259769782423973,
        -0.05056576430797577,
        0.006303485482931137,
        -0.05168543756008148,
        0.05213520675897598,
        0.0601586252450943,
        0.04581823945045471,
        -0.01592358946800232,
        -0.06281132251024246,
        -0.003226641332730651,
        0.010427461005747318,
        -0.05642198398709297,
        -0.0650232583284378,
        0.03991437703371048,
        0.021090809255838394,
        -0.0646696537733078,
        0.021599533036351204,
        0.05980867147445679,
        -0.03630363568663597,
        0.052413638681173325,
        0.05902228504419327,
        0.021025171503424644,
        0.014198423363268375,
        0.031347472220659256,
        -0.02324693463742733,
        -0.041739970445632935,
        0.06153099611401558,
        0.06259162724018097,
        -0.06427208334207535,
        0.006884509231895208,
        -0.04666217789053917,
        0.04242325574159622,
        -0.059823643416166306,
        0.029632674530148506,
        0.008217244409024715,
        0.03595984727144241,
        0.026629310101270676,
        0.05528030917048454,
        0.050584498792886734,
        0.05826911702752113,
        0.04295331984758377,
        0.050144731998443604,
        0.03255375847220421,
        -0.05153866857290268,
        0.03670117259025574,
        -0.00265910429880023,
        0.03398595377802849,
        -0.05246669799089432,
        0.05747099220752716,
        -0.05466507002711296,
        -0.059317998588085175,
        0.06149904802441597,
        0.06154051050543785,
        -0.06117895245552063,
        -0.0007180238026194274,
        0.05789359658956528,
        -0.03850613534450531,
        -0.03120962344110012,
        0.05447927862405777,
        -0.008235001005232334,
        0.03160407021641731,
        0.05449924245476723,
        -0.009478687308728695,
        -0.012273099273443222,
        0.0600370317697525,
        -0.0517549030482769,
        -0.023809390142560005,
        -0.03266734257340431,
        -0.006578459870070219,
        -0.031855303794145584,
        0.010398228652775288,
        -0.038051292300224304,
        0.05153234675526619,
        -0.04098709672689438,
        -0.05205874517560005,
        -0.05760006979107857,
        -0.013226303271949291,
        0.05404281243681908,
        0.006762149278074503,
        0.02009645663201809,
        0.021564148366451263,
        -0.06359343975782394,
        0.04105951637029648,
        0.021563803777098656,
        0.03280835598707199,
        -0.04420650377869606,
        0.016512615606188774,
        -0.05624934285879135,
        0.059209439903497696,
        0.04700038582086563,
        0.042590633034706116,
        0.011450358666479588,
        0.0009906175546348095,
        0.062240272760391235,
        0.010929497890174389,
        0.06622221320867538,
        0.011380186304450035,
        0.01146655436605215,
        0.06461857259273529,
        -0.050326861441135406,
        -0.055315494537353516,
        0.0645490363240242,
        -0.029036151245236397,
        -0.028354773297905922,
        0.06362777948379517,
        0.044755496084690094,
        -0.05639934539794922,
        0.011757931672036648,
        -0.04364868998527527,
        -0.03172944858670235,
        0.030895909294486046,
        -0.059329889714717865,
        0.06741534173488617,
        0.0616011768579483,
        -0.02037094533443451,
        0.009181085042655468,
        0.06732471287250519,
        -0.033170685172080994,
        0.05533793568611145,
        -0.06748660653829575,
        0.05553897097706795,
        0.026408815756440163,
        -0.032802097499370575,
        -0.06727432459592819,
        -0.05344589054584503,
        -0.06434938311576843,
        -0.04742373526096344,
        -0.03427443280816078,
        -0.06150666996836662,
        0.0014886177377775311,
        -0.0644850805401802,
        -0.048658452928066254,
        0.06329868733882904,
        -0.04609813913702965,
        -0.03920507803559303,
        0.010661020874977112,
        -0.004450483247637749,
        0.06273151189088821,
        -0.03751974180340767,
        -0.058380719274282455,
        0.049455467611551285,
        -0.002118108794093132,
        0.06314471364021301,
        -0.00594977755099535,
        -0.005655320826917887,
        -0.06450823694467545,
        -0.0009205401293002069,
        0.050444621592760086,
        -0.043159231543540955,
        0.06650705635547638,
        -0.04761994257569313,
        -0.02968529984354973,
        0.03442687541246414,
        -0.03397463634610176,
        -0.021805336698889732,
        -0.041526515036821365,
        -0.026826800778508186,
        -0.04842078685760498,
        -0.05870617926120758,
        -0.0589573048055172,
        0.05423976480960846,
        -0.015594634227454662,
        -0.05765444040298462,
        0.03029586933553219,
        0.061258476227521896,
        0.0631842389702797,
        0.05618458241224289,
        -0.03582078590989113,
        0.055479321628808975,
        -0.01977236568927765,
        0.04768386855721474,
        0.06466414034366608,
        -0.062669537961483,
        0.06322620064020157,
        0.06339585036039352,
        -0.05449899658560753,
        0.05931495875120163,
        0.022712131962180138,
        0.05450322851538658,
        -0.05286569520831108,
        0.05365193635225296,
        -0.03291596844792366,
        0.022643571719527245,
        -0.055912647396326065,
        0.059909187257289886,
        0.04169068485498428,
        -0.06619828194379807,
        -0.04180019348859787,
        -0.0454387404024601,
        -0.028516585007309914,
        0.03273556008934975,
        0.0032002271618694067,
        -0.05480634421110153,
        0.02308029495179653,
        -0.05255250632762909,
        0.04942600801587105,
        -0.045724738389253616,
        -0.024156371131539345,
        0.04298356920480728,
        0.05963735282421112,
        0.021552907302975655,
        0.04702519625425339,
        -0.02955520525574684,
        -0.00845057237893343,
        -0.013176139444112778,
        0.020782189443707466,
        -0.030131524428725243,
        0.008901217021048069,
        -0.047070953994989395,
        -0.03721189871430397,
        0.0001834410213632509,
        0.064161017537117,
        0.00953509658575058,
        -0.037323448807001114,
        0.05593615770339966,
        -0.030995747074484825,
        0.02521267533302307,
        -0.0386640690267086,
        0.0559283085167408,
        0.011821846477687359,
        0.044472940266132355,
        0.04946887120604515,
        0.05096252262592316,
        0.024977540597319603,
        0.05701156705617905,
        -0.04932279512286186,
        0.06564255803823471,
        0.060705143958330154,
        0.00013031670823693275,
        -0.011938437819480896,
        0.0006006233743391931,
        -0.0548255518078804,
        0.03735733404755592,
        -0.04542267695069313,
        -0.04312807321548462,
        -0.007290322333574295,
        -0.06382246315479279,
        0.04406667500734329,
        -0.006316300481557846,
        -0.05217994004487991,
        0.026061756536364555,
        -0.02043643593788147,
        -0.02731350250542164,
        0.05299283191561699,
        -0.014574751257896423,
        0.013651733286678791,
        -0.05672082304954529,
        0.04157302901148796,
        0.00751887122169137,
        0.054752152413129807,
        0.04700414091348648,
        0.04067346826195717,
        0.0616387203335762,
        -0.04017083719372749,
        0.06267745792865753,
        0.062107834964990616,
        -0.04128323867917061,
        0.0007707203039899468,
        -0.05958753451704979,
        -0.06649824976921082,
        0.06334680318832397,
        0.06177940219640732,
        -0.020048506557941437,
        0.026980282738804817,
        -0.0654696375131607,
        -0.045420628041028976,
        -0.03891420364379883,
        -0.0661531537771225,
        -0.03857867419719696,
        0.0111480001360178,
        -0.04201781377196312,
        -0.05438882112503052,
        0.06244963780045509,
        -0.03747560456395149,
        0.060136303305625916,
        -0.017188142985105515,
        -0.060608308762311935,
        0.0013971993466839194,
        0.05625022202730179,
        0.04909416660666466,
        -0.04418081045150757,
        -0.05133184790611267,
        0.03308673948049545,
        0.03965892642736435,
        -0.05386769399046898,
        0.0215548574924469,
        0.030285146087408066,
        -0.009989293292164803,
        -0.06234070286154747,
        0.05794863775372505,
        -0.019768690690398216,
        0.055472586303949356,
        -0.050397977232933044,
        0.03839299827814102,
        0.04694017767906189,
        0.04198131710290909,
        0.046476397663354874,
        0.03741038590669632,
        -0.009362601675093174,
        -0.030442971736192703,
        -0.061072900891304016,
        0.013246453367173672,
        0.01420268788933754,
        -0.05190122872591019,
        -0.06347724050283432,
        0.02511526644229889,
        -0.06401251256465912,
        0.03541022911667824,
        -0.045927006751298904,
        -0.0616837777197361,
        -0.027246303856372833,
        0.010103913024067879,
        -0.047079846262931824,
        -0.06374362856149673,
        -0.008029387332499027,
        0.033559273928403854,
        -0.006676669232547283,
        0.05800855532288551,
        0.06126108393073082,
        -0.028223920613527298,
        0.05477936193346977,
        0.0626639872789383,
        0.001667702803388238,
        0.06482669711112976,
        -0.02077452838420868,
        0.05970144271850586,
        0.05076653137803078,
        0.009723433293402195,
        0.057971250265836716,
        0.02402985654771328,
        0.06194031983613968,
        0.008347830735147,
        -0.05911200866103172,
        0.04614797234535217,
        0.0025953478179872036,
        0.008688526228070259,
        0.009419014677405357,
        0.03496316075325012,
        -0.02744312956929207,
        -0.06453916430473328,
        0.016052300110459328,
        0.05850197747349739,
        0.06416349112987518,
        -0.023495346307754517,
        0.06046445667743683,
        -0.04422478377819061,
        -0.05532369017601013,
        0.053159527480602264,
        0.06220793351531029,
        -0.008094577118754387,
        -0.006576841697096825,
        -0.06328339874744415,
        -0.05502856895327568,
        0.05123912915587425,
        -0.01759815216064453,
        -0.05922266095876694,
        -0.054880596697330475,
        0.03418130800127983,
        -0.05736269801855087,
        0.00838188175112009,
        0.0264477226883173,
        0.00432133674621582,
        0.051232144236564636,
        0.0496085062623024,
        0.06430140882730484,
        -0.05081116780638695,
        0.022379402071237564,
        -0.05753200873732567,
        0.02970818616449833,
        0.05840979889035225,
        0.012001372873783112,
        -0.03725862503051758,
        -0.02097637951374054,
        0.055979467928409576,
        0.04675500467419624,
        0.0610758401453495,
        0.06333807110786438,
        0.03731117025017738,
        -0.0121312802657485,
        0.00887654535472393,
        0.04088398814201355,
        -0.0023321325425058603,
        -0.05643775314092636,
        0.03497866168618202,
        0.016074195504188538,
        0.022038180381059647,
        -0.06103590875864029,
        0.03573313727974892,
        0.06221158057451248
    ],
    [
        0.0030185554642230272,
        0.05854979157447815,
        0.03528955951333046,
        0.027727043256163597,
        -0.007150889839977026,
        0.02619699388742447,
        -0.010440414771437645,
        -0.0660666972398758,
        0.06110639125108719,
        -0.04400625452399254,
        0.014907421544194221,
        0.031036779284477234,
        -0.0422242097556591,
        -0.05902167409658432,
        -0.05801020562648773,
        0.06432262808084488,
        0.015779150649905205,
        0.061492919921875,
        -0.05479765310883522,
        0.008961872197687626,
        0.044220924377441406,
        0.06365124136209488,
        0.04804563522338867,
        0.03233622387051582,
        0.039249811321496964,
        0.05293111503124237,
        0.026456935331225395,
        0.004860830493271351,
        0.029332494363188744,
        0.02127404324710369,
        0.0661667138338089,
        -0.01562401745468378,
        -0.061684709042310715,
        -0.059211067855358124,
        0.05039244890213013,
        -0.007814419455826283,
        -0.06334160268306732,
        -0.022072916850447655,
        -0.05116532742977142,
        -0.010875621810555458,
        0.012137438170611858,
        0.017435697838664055,
        0.025143606588244438,
        -0.06262820959091187,
        0.05725300684571266,
        0.04687485098838806,
        -0.0566411130130291,
        0.04421868175268173,
        0.04100244492292404,
        0.04003119841217995,
        0.04614802449941635,
        0.06584429740905762,
        -0.050968270748853683,
        -0.004214096814393997,
        0.056224893778562546,
        -0.022520774975419044,
        -0.0007843144121579826,
        0.011445801705121994,
        -0.03834627568721771,
        -0.014002514071762562,
        -0.06284044682979584,
        0.022641794756054878,
        -0.047124505043029785,
        0.06270092725753784,
        -0.06490392237901688,
        -0.06208100542426109,
        -0.006985274609178305,
        -0.03445930778980255,
        -0.0535859540104866,
        -0.04795462265610695,
        -0.053701162338256836,
        -0.0655691996216774,
        0.059094227850437164,
        0.0480840690433979,
        -0.025186879560351372,
        -0.03512613847851753,
        -0.0020613325759768486,
        0.06755739450454712,
        0.008720532059669495,
        0.0390961579978466,
        -0.002988731022924185,
        0.060821112245321274,
        -0.029951412230730057,
        0.022907258942723274,
        -0.029581531882286072,
        0.03101525269448757,
        0.02210962399840355,
        -0.041044171899557114,
        0.0487993024289608,
        0.057429395616054535,
        0.062351349741220474,
        0.033095840364694595,
        -0.0632752999663353,
        -0.039227329194545746,
        -0.040440741926431656,
        0.05728554353117943,
        0.016560915857553482,
        -0.0592447891831398,
        -0.002195097506046295,
        -0.014818510971963406,
        -0.06490366905927658,
        0.01823858730494976,
        0.06080687418580055,
        0.017900612205266953,
        0.04486053064465523,
        -0.051240235567092896,
        -0.013601797632873058,
        0.043171267956495285,
        -0.01760455220937729,
        0.04967639222741127,
        -0.03509141132235527,
        0.0499332919716835,
        -0.05077275633811951,
        -0.037759121507406235,
        0.03209752216935158,
        0.0151043850928545,
        0.04771365225315094,
        0.008890541270375252,
        0.06710782647132874,
        0.025686005130410194,
        -0.012606428936123848,
        0.06100462004542351,
        0.01166121382266283,
        -0.05259769782423973,
        -0.05056576430797577,
        0.006303485482931137,
        -0.05168543756008148,
        0.05213520675897598,
        0.0601586252450943,
        0.04581823945045471,
        -0.01592358946800232,
        -0.06281132251024246,
        -0.003226641332730651,
        0.010427461005747318,
        -0.05642198398709297,
        -0.0650232583284378,
        0.03991437703371048,
        0.021090809255838394,
        -0.0646696537733078,
        0.021599533036351204,
        0.05980867147445679,
        -0.03630363568663597,
        0.052413638681173325,
        0.05902228504419327,
        0.021025171503424644,
        0.014198423363268375,
        0.031347472220659256,
        -0.02324693463742733,
        -0.041739970445632935,
        0.06153099611401558,
        0.06259162724018097,
        -0.06427208334207535,
        0.006884509231895208,
        -0.04666217789053917,
        0.04242325574159622,
        -0.059823643416166306,
        0.029632674530148506,
        0.008217244409024715,
        0.03595984727144241,
        0.026629310101270676,
        0.05528030917048454,
        0.050584498792886734,
        0.05826911702752113,
        0.04295331984758377,
        0.050144731998443604,
        0.03255375847220421,
        -0.05153866857290268,
        0.03670117259025574,
        -0.00265910429880023,
        0.03398595377802849,
        -0.05246669799089432,
        0.05747099220752716,
        -0.05466507002711296,
        -0.059317998588085175,
        0.06149904802441597,
        0.06154051050543785,
        -0.06117895245552063,
        -0.0007180238026194274,
        0.05789359658956528,
        -0.03850613534450531,
        -0.03120962344110012,
        0.05447927862405777,
        -0.008235001005232334,
        0.03160407021641731,
        0.05449924245476723,
        -0.009478687308728695,
        -0.012273099273443222,
        0.0600370317697525,
        -0.0517549030482769,
        -0.023809390142560005,
        -0.03266734257340431,
        -0.006578459870070219,
        -0.031855303794145584,
        0.010398228652775288,
        -0.038051292300224304,
        0.05153234675526619,
        -0.04098709672689438,
        -0.05205874517560005,
        -0.05760006979107857,
        -0.013226303271949291,
        0.05404281243681908,
        0.006762149278074503,
        0.02009645663201809,
        0.021564148366451263,
        -0.06359343975782394,
        0.04105951637029648,
        0.021563803777098656,
        0.03280835598707199,
        -0.04420650377869606,
        0.016512615606188774,
        -0.05624934285879135,
        0.059209439903497696,
        0.04700038582086563,
        0.042590633034706116,
        0.011450358666479588,
        0.0009906175546348095,
        0.062240272760391235,
        0.010929497890174389,
        0.06622221320867538,
        0.011380186304450035,
        0.01146655436605215,
        0.06461857259273529,
        -0.050326861441135406,
        -0.055315494537353516,
        0.0645490363240242,
        -0.029036151245236397,
        -0.028354773297905922,
        0.06362777948379517,
        0.044755496084690094,
        -0.05639934539794922,
        0.011757931672036648,
        -0.04364868998527527,
        -0.03172944858670235,
        0.030895909294486046,
        -0.059329889714717865,
        0.06741534173488617,
        0.0616011768579483,
        -0.02037094533443451,
        0.009181085042655468,
        0.06732471287250519,
        -0.033170685172080994,
        0.05533793568611145,
        -0.06748660653829575,
        0.05553897097706795,
        0.026408815756440163,
        -0.032802097499370575,
        -0.06727432459592819,
        -0.05344589054584503,
        -0.06434938311576843,
        -0.04742373526096344,
        -0.03427443280816078,
        -0.06150666996836662,
        0.0014886177377775311,
        -0.0644850805401802,
        -0.048658452928066254,
        0.06329868733882904,
        -0.04609813913702965,
        -0.03920507803559303,
        0.010661020874977112,
        -0.004450483247637749,
        0.06273151189088821,
        -0.03751974180340767,
        -0.058380719274282455,
        0.049455467611551285,
        -0.002118108794093132,
        0.06314471364021301,
        -0.00594977755099535,
        -0.005655320826917887,
        -0.06450823694467545,
        -0.0009205401293002069,
        0.050444621592760086,
        -0.043159231543540955,
        0.06650705635547638,
        -0.04761994257569313,
        -0.02968529984354973,
        0.03442687541246414,
        -0.03397463634610176,
        -0.021805336698889732,
        -0.041526515036821365,
        -0.026826800778508186,
        -0.04842078685760498,
        -0.05870617926120758,
        -0.0589573048055172,
        0.05423976480960846,
        -0.015594634227454662,
        -0.05765444040298462,
        0.03029586933553219,
        0.061258476227521896,
        0.0631842389702797,
        0.05618458241224289,
        -0.03582078590989113,
        0.055479321628808975,
        -0.01977236568927765,
        0.04768386855721474,
        0.06466414034366608,
        -0.062669537961483,
        0.06322620064020157,
        0.06339585036039352,
        -0.05449899658560753,
        0.05931495875120163,
        0.022712131962180138,
        0.05450322851538658,
        -0.05286569520831108,
        0.05365193635225296,
        -0.03291596844792366,
        0.022643571719527245,
        -0.055912647396326065,
        0.059909187257289886,
        0.04169068485498428,
        -0.06619828194379807,
        -0.04180019348859787,
        -0.0454387404024601,
        -0.028516585007309914,
        0.03273556008934975,
        0.0032002271618694067,
        -0.05480634421110153,
        0.02308029495179653,
        -0.05255250632762909,
        0.04942600801587105,
        -0.045724738389253616,
        -0.024156371131539345,
        0.04298356920480728,
        0.05963735282421112,
        0.021552907302975655,
        0.04702519625425339,
        -0.02955520525574684,
        -0.00845057237893343,
        -0.013176139444112778,
        0.020782189443707466,
        -0.030131524428725243,
        0.008901217021048069,
        -0.047070953994989395,
        -0.03721189871430397,
        0.0001834410213632509,
        0.064161017537117,
        0.00953509658575058,
        -0.037323448807001114,
        0.05593615770339966,
        -0.030995747074484825,
        0.02521267533302307,
        -0.0386640690267086,
        0.0559283085167408,
        0.011821846477687359,
        0.044472940266132355,
        0.04946887120604515,
        0.05096252262592316,
        0.024977540597319603,
        0.05701156705617905,
        -0.04932279512286186,
        0.06564255803823471,
        0.060705143958330154,
        0.00013031670823693275,
        -0.011938437819480896,
        0.0006006233743391931,
        -0.0548255518078804,
        0.03735733404755592,
        -0.04542267695069313,
        -0.04312807321548462,
        -0.007290322333574295,
        -0.06382246315479279,
        0.04406667500734329,
        -0.006316300481557846,
        -0.05217994004487991,
        0.026061756536364555,
        -0.02043643593788147,
        -0.02731350250542164,
        0.05299283191561699,
        -0.014574751257896423,
        0.013651733286678791,
        -0.05672082304954529,
        0.04157302901148796,
        0.00751887122169137,
        0.054752152413129807,
        0.04700414091348648,
        0.04067346826195717,
        0.0616387203335762,
        -0.04017083719372749,
        0.06267745792865753,
        0.062107834964990616,
        -0.04128323867917061,
        0.0007707203039899468,
        -0.05958753451704979,
        -0.06649824976921082,
        0.06334680318832397,
        0.06177940219640732,
        -0.020048506557941437,
        0.026980282738804817,
        -0.0654696375131607,
        -0.045420628041028976,
        -0.03891420364379883,
        -0.0661531537771225,
        -0.03857867419719696,
        0.0111480001360178,
        -0.04201781377196312,
        -0.05438882112503052,
        0.06244963780045509,
        -0.03747560456395149,
        0.060136303305625916,
        -0.017188142985105515,
        -0.060608308762311935,
        0.0013971993466839194,
        0.05625022202730179,
        0.04909416660666466,
        -0.04418081045150757,
        -0.05133184790611267,
        0.03308673948049545,
        0.03965892642736435,
        -0.05386769399046898,
        0.0215548574924469,
        0.030285146087408066,
        -0.009989293292164803,
        -0.06234070286154747,
        0.05794863775372505,
        -0.019768690690398216,
        0.055472586303949356,
        -0.050397977232933044,
        0.03839299827814102,
        0.04694017767906189,
        0.04198131710290909,
        0.046476397663354874,
        0.03741038590669632,
        -0.009362601675093174,
        -0.030442971736192703,
        -0.061072900891304016,
        0.013246453367173672,
        0.01420268788933754,
        -0.05190122872591019,
        -0.06347724050283432,
        0.02511526644229889,
        -0.06401251256465912,
        0.03541022911667824,
        -0.045927006751298904,
        -0.0616837777197361,
        -0.027246303856372833,
        0.010103913024067879,
        -0.047079846262931824,
        -0.06374362856149673,
        -0.008029387332499027,
        0.033559273928403854,
        -0.006676669232547283,
        0.05800855532288551,
        0.06126108393073082,
        -0.028223920613527298,
        0.05477936193346977,
        0.0626639872789383,
        0.001667702803388238,
        0.06482669711112976,
        -0.02077452838420868,
        0.05970144271850586,
        0.05076653137803078,
        0.009723433293402195,
        0.057971250265836716,
        0.02402985654771328,
        0.06194031983613968,
        0.008347830735147,
        -0.05911200866103172,
        0.04614797234535217,
        0.0025953478179872036,
        0.008688526228070259,
        0.009419014677405357,
        0.03496316075325012,
        -0.02744312956929207,
        -0.06453916430473328,
        0.016052300110459328,
        0.05850197747349739,
        0.06416349112987518,
        -0.023495346307754517,
        0.06046445667743683,
        -0.04422478377819061,
        -0.05532369017601013,
        0.053159527480602264,
        0.06220793351531029,
        -0.008094577118754387,
        -0.006576841697096825,
        -0.06328339874744415,
        -0.05502856895327568,
        0.05123912915587425,
        -0.01759815216064453,
        -0.05922266095876694,
        -0.054880596697330475,
        0.03418130800127983,
        -0.05736269801855087,
        0.00838188175112009,
        0.0264477226883173,
        0.00432133674621582,
        0.051232144236564636,
        0.0496085062623024,
        0.06430140882730484,
        -0.05081116780638695,
        0.022379402071237564,
        -0.05753200873732567,
        0.02970818616449833,
        0.05840979889035225,
        0.012001372873783112,
        -0.03725862503051758,
        -0.02097637951374054,
        0.055979467928409576,
        0.04675500467419624,
        0.0610758401453495,
        0.06333807110786438,
        0.03731117025017738,
        -0.0121312802657485,
        0.00887654535472393,
        0.04088398814201355,
        -0.0023321325425058603,
        -0.05643775314092636,
        0.03497866168618202,
        0.016074195504188538,
        0.022038180381059647,
        -0.06103590875864029,
        0.03573313727974892,
        0.06221158057451248
    ],
    [
        -0.050446152687072754,
        0.04158218204975128,
        -0.019241970032453537,
        0.010552859865128994,
        0.056989170610904694,
        -0.06215782091021538,
        0.06749050319194794,
        0.03716633468866348,
        -0.041078872978687286,
        -0.023059606552124023,
        0.03941432014107704,
        -0.0036914520896971226,
        -0.024875177070498466,
        -0.039885230362415314,
        -0.05934282764792442,
        0.021541303023695946,
        -0.0542374812066555,
        0.05480639263987541,
        -0.04867375269532204,
        0.04067188873887062,
        -0.023991601541638374,
        -0.055752627551555634,
        0.05090382322669029,
        0.004459425341337919,
        0.014710751362144947,
        0.04410061240196228,
        0.008843199349939823,
        0.038565170019865036,
        0.009323407895863056,
        0.014908496290445328,
        0.007715974003076553,
        0.03403950482606888,
        -0.04372740909457207,
        -0.06626802682876587,
        0.010781989432871342,
        -0.029582371935248375,
        -0.05573161318898201,
        -0.053074583411216736,
        -0.02026456408202648,
        0.004789144732058048,
        -0.0057891965843737125,
        -0.015453907661139965,
        0.04554526507854462,
        -0.06156836822628975,
        0.0626022070646286,
        0.029700713232159615,
        0.058468468487262726,
        0.06611713021993637,
        0.029889505356550217,
        -0.014251984655857086,
        0.03196154162287712,
        -0.007744404952973127,
        0.06137895956635475,
        -0.03640410304069519,
        0.012644663453102112,
        -0.049177318811416626,
        0.0013958526542410254,
        -0.02379380725324154,
        -0.047181133180856705,
        0.06124985218048096,
        -0.0489562526345253,
        0.037253011018037796,
        -0.0579577274620533,
        0.0035226999316364527,
        -0.0029034328181296587,
        0.03435967490077019,
        -0.00257987342774868,
        -0.06324245035648346,
        0.04098697751760483,
        -0.06775899231433868,
        -0.04709411785006523,
        -0.06452906876802444,
        -0.012882697395980358,
        0.03235529735684395,
        -0.03093593753874302,
        -0.023028844967484474,
        0.06207599118351936,
        0.06086662411689758,
        0.05713506042957306,
        -0.06091798096895218,
        -0.017738206312060356,
        0.03537638485431671,
        0.0023362538777291775,
        -0.02495752088725567,
        -0.02786373905837536,
        0.005344781093299389,
        -0.05554676428437233,
        -0.045371830463409424,
        -0.059473827481269836,
        0.053286727517843246,
        -0.056506384164094925,
        -0.03279747813940048,
        -0.05710244178771973,
        -0.058731772005558014,
        0.03245427459478378,
        -0.04155094921588898,
        -0.051278866827487946,
        -0.0594760924577713,
        -0.05610707402229309,
        0.023984994739294052,
        -0.06508918106555939,
        -0.04958244785666466,
        0.06514535844326019,
        0.06486020982265472,
        0.009525885805487633,
        0.041578251868486404,
        -0.03901473432779312,
        -0.003768883878365159,
        -0.05508480221033096,
        -0.014498654752969742,
        -0.040199268609285355,
        0.03262293338775635,
        -0.033690981566905975,
        -0.046830303966999054,
        0.061958882957696915,
        -0.05663245916366577,
        -0.05023808777332306,
        -0.043370869010686874,
        -0.06357909739017487,
        0.023095576092600822,
        -0.03935391455888748,
        -0.005287088453769684,
        0.018851889297366142,
        0.056389786303043365,
        -0.06741145253181458,
        -0.006835774052888155,
        0.02845473401248455,
        0.010985249653458595,
        0.0639723464846611,
        0.05674508586525917,
        -0.019324669614434242,
        -0.051258403807878494,
        -0.03208739310503006,
        0.020947901532053947,
        -0.04312019422650337,
        -0.05417967587709427,
        0.0034875741694122553,
        -0.0454438142478466,
        0.013916587457060814,
        0.06038491055369377,
        0.019357187673449516,
        -0.002176908776164055,
        0.03721676394343376,
        0.06417364627122879,
        0.05118931084871292,
        0.04449447616934776,
        0.06496866792440414,
        -0.03994034603238106,
        0.02933952771127224,
        0.0009260397055186331,
        -0.0642489641904831,
        -0.06543778628110886,
        -0.027219213545322418,
        0.0036274089943617582,
        -0.032313842326402664,
        0.018024763092398643,
        -0.016854193061590195,
        -0.00776586402207613,
        -0.061374321579933167,
        0.02498362958431244,
        0.06201925128698349,
        0.04087875783443451,
        0.04581161588430405,
        -0.050355516374111176,
        -0.06348136067390442,
        0.018287083134055138,
        -0.0188163872808218,
        -0.04397257789969444,
        -0.008153462782502174,
        0.054232869297266006,
        0.0497799851000309,
        0.04790602996945381,
        -0.05353633686900139,
        0.0557953417301178,
        -0.062212295830249786,
        0.06597926467657089,
        -0.05694365128874779,
        0.0073912120424211025,
        0.06510413438081741,
        0.008092952892184258,
        0.05130959674715996,
        0.06245230883359909,
        0.06457268446683884,
        -0.024758227169513702,
        0.029030609875917435,
        -0.02992432937026024,
        -0.013920699246227741,
        0.0634516179561615,
        -0.04635975509881973,
        -0.051944926381111145,
        -0.02924058400094509,
        -0.02988366223871708,
        -0.06178611144423485,
        -0.0009642077493481338,
        -0.029412459582090378,
        -0.006948319263756275,
        0.06329687684774399,
        0.056144457310438156,
        -0.04806438833475113,
        -0.02122657746076584,
        -0.04468199983239174,
        0.06069290265440941,
        -0.04069189354777336,
        -0.027894405648112297,
        -0.005051236134022474,
        0.06003444641828537,
        0.010696278885006905,
        0.023855874314904213,
        0.041561271995306015,
        -0.0471438430249691,
        -0.061012569814920425,
        -0.003920078743249178,
        -0.03877997025847435,
        0.055549319833517075,
        0.051938947290182114,
        0.02514648251235485,
        0.05780765414237976,
        0.03154153376817703,
        0.06728611141443253,
        -0.029490333050489426,
        0.03229038789868355,
        -0.05299645662307739,
        -0.04534892365336418,
        -0.06741558760404587,
        0.0657225251197815,
        -0.06540392339229584,
        -0.031226208433508873,
        0.041379768401384354,
        -0.022759240120649338,
        -0.06547081470489502,
        -0.0038030564319342375,
        -0.03853853419423103,
        -0.062463823705911636,
        0.04589030519127846,
        -0.0358586385846138,
        0.004146660212427378,
        0.06290238350629807,
        0.025574641302227974,
        -0.03514333814382553,
        0.0638962909579277,
        0.06144395470619202,
        0.04841911420226097,
        -0.0036442142445594072,
        -0.011552402749657631,
        -0.003329045604914427,
        0.013890407048165798,
        0.06340698897838593,
        -0.034015953540802,
        -0.04320012032985687,
        0.03169040009379387,
        -0.02117343433201313,
        -0.06579038500785828,
        -0.05648505687713623,
        -0.0648062452673912,
        -0.026175588369369507,
        0.028193648904561996,
        0.02156033366918564,
        0.014362191781401634,
        0.067696712911129,
        0.060599248856306076,
        0.05973002687096596,
        0.06265103071928024,
        -0.029048562049865723,
        -0.06020137667655945,
        -0.05033927783370018,
        0.039075691252946854,
        0.011527163907885551,
        0.005965896882116795,
        -0.028500894084572792,
        -0.04398232325911522,
        -0.061440519988536835,
        -0.06307491660118103,
        0.02446793206036091,
        -0.03948614001274109,
        -0.0385412760078907,
        0.015611611306667328,
        -0.0597149059176445,
        -0.0203471127897501,
        0.006040086969733238,
        -0.03079715557396412,
        -0.06373106688261032,
        -0.021175671368837357,
        -0.009562937542796135,
        0.054060060530900955,
        -0.06292735040187836,
        -0.04623692110180855,
        0.050009701400995255,
        -0.019415460526943207,
        -0.06573095917701721,
        0.013978571631014347,
        -0.03144596144556999,
        0.06421679258346558,
        -0.031521040946245193,
        -0.048396963626146317,
        -0.00742169888690114,
        -0.05262001231312752,
        0.06748627126216888,
        0.019238371402025223,
        -0.06472700834274292,
        0.06040214002132416,
        0.01788659580051899,
        0.06152289733290672,
        -0.044209036976099014,
        -0.03756703808903694,
        -0.019993465393781662,
        -0.012270499020814896,
        -0.050920918583869934,
        0.05565857142210007,
        0.04539418965578079,
        -0.06276959180831909,
        0.043829064816236496,
        -0.05990048870444298,
        -0.05117611959576607,
        0.06573454290628433,
        -0.06340833008289337,
        -0.0573883131146431,
        0.05094357952475548,
        -0.062039729207754135,
        0.009680121205747128,
        -0.04343999922275543,
        0.02197500877082348,
        -0.05036649480462074,
        0.05743594095110893,
        -0.00626382976770401,
        0.04366561770439148,
        0.05813087150454521,
        -0.00015210155106615275,
        0.036088861525058746,
        -0.029581595212221146,
        -0.0004244517476763576,
        -0.006050644908100367,
        0.0346737764775753,
        -0.03198033571243286,
        -0.06433632224798203,
        0.06641604751348495,
        -0.0654316321015358,
        -0.030927345156669617,
        0.06455966085195541,
        -0.060247063636779785,
        0.05744488537311554,
        0.0557214729487896,
        -0.027772318571805954,
        -0.04955470189452171,
        -0.06210196763277054,
        0.06780406087636948,
        0.03149116784334183,
        0.02531871199607849,
        0.05256554111838341,
        -0.024273786693811417,
        0.06340440362691879,
        -0.06728047877550125,
        0.02292119339108467,
        -0.043036673218011856,
        -0.06477727741003036,
        -0.03173532709479332,
        0.04257836937904358,
        -0.05452411621809006,
        0.03318219631910324,
        -0.010828441940248013,
        -0.04848305135965347,
        0.05777663737535477,
        0.03655729442834854,
        -0.04680235683917999,
        -0.007918997667729855,
        0.005371278151869774,
        -0.06439462304115295,
        0.066490039229393,
        0.04597559943795204,
        0.04933399707078934,
        -0.06740725785493851,
        -0.010882824659347534,
        -0.06753972172737122,
        0.060168929398059845,
        0.056695666164159775,
        0.054949015378952026,
        0.057877980172634125,
        0.0006768589955754578,
        -0.03310423716902733,
        0.0007455844897776842,
        -0.0291675366461277,
        -0.05064259096980095,
        0.05552244931459427,
        -0.04823466017842293,
        -0.048851363360881805,
        0.06379537284374237,
        -0.04026862233877182,
        0.04192643240094185,
        -0.056854497641325,
        -0.012059789150953293,
        -0.040660299360752106,
        0.0167575404047966,
        0.014244131743907928,
        0.05029808357357979,
        0.047211986035108566,
        0.010577496141195297,
        0.06425110250711441,
        0.05568714067339897,
        -0.04756433144211769,
        0.024535667151212692,
        -0.062215253710746765,
        0.018713241443037987,
        0.032671473920345306,
        0.062062013894319534,
        0.06614739447832108,
        -0.024907169863581657,
        0.021523281931877136,
        0.02999807335436344,
        0.03298585116863251,
        -0.0050774370320141315,
        -0.025385165587067604,
        0.04296192526817322,
        -0.04162921383976936,
        0.0451829768717289,
        -0.02845829725265503,
        0.06403078138828278,
        -0.051812198013067245,
        0.027864350005984306,
        -0.004278418608009815,
        0.05648282915353775,
        0.03486642986536026,
        -0.0302319023758173,
        -0.05208292603492737,
        -0.015267742797732353,
        -0.037723708897829056,
        0.04250374808907509,
        0.0026298619341105223,
        -0.024442147463560104,
        -0.058043889701366425,
        -0.06762499362230301,
        -0.0196636151522398,
        0.05171343684196472,
        -0.01562914438545704,
        0.029331931844353676,
        0.04312644526362419,
        -0.0061310045421123505,
        -0.05283018946647644,
        0.014532939530909061,
        0.052215754985809326,
        0.05545543134212494,
        -0.06001170352101326,
        0.06462369114160538,
        0.04881017282605171,
        -0.019356166943907738,
        0.0607277974486351,
        -0.045171696692705154,
        0.0364595390856266,
        0.06265629827976227,
        -0.04726691171526909,
        0.06148854270577431,
        -0.02451525442302227,
        -0.012647664174437523,
        0.0004493515589274466,
        0.06641074270009995,
        -0.06493338197469711,
        -0.06160205602645874,
        -0.019685018807649612,
        0.007309977896511555,
        0.0025578169152140617,
        -0.04058457538485527,
        0.05555589869618416,
        0.008999372832477093,
        0.02153615467250347,
        -0.06532036513090134,
        -0.05022209510207176,
        0.06450653076171875,
        0.06706613302230835,
        -0.06526236981153488,
        0.06607701629400253,
        0.007736719213426113,
        -0.06721962243318558,
        0.03840629383921623,
        -0.019064320251345634,
        0.018536755815148354,
        0.05064304545521736,
        0.03367067500948906,
        0.03211267292499542,
        -0.009561190381646156,
        -0.06791011244058609,
        -0.020861497148871422,
        -0.04236825928092003,
        -0.06477243453264236,
        0.0032793215941637754,
        0.041967932134866714,
        0.04599205404520035,
        0.00037079534376971424,
        0.0307632964104414,
        0.03346332162618637,
        -0.008083459921181202,
        -0.05907502770423889,
        -0.027096886187791824,
        -0.058620307594537735,
        -0.040950942784547806,
        -0.02991381846368313,
        0.04894830659031868,
        0.05436569079756737,
        -0.019822239875793457,
        -0.04469875991344452,
        0.009360842406749725,
        0.059312012046575546,
        0.06077471002936363,
        -0.06161189079284668,
        -0.04421214386820793,
        -0.05692344158887863,
        0.0007263377192430198,
        0.030048836022615433,
        0.006761458702385426,
        0.044134944677352905,
        0.019349386915564537,
        -0.04810531437397003,
        -0.015353293158113956,
        0.048048920929431915,
        0.0543450303375721
    ],
    [
        -0.025770878419280052,
        0.02174513228237629,
        -0.04437645524740219,
        -0.007092340383678675,
        0.057797692716121674,
        -0.06393134593963623,
        0.06818719208240509,
        0.009968463331460953,
        0.00704211974516511,
        0.014395661652088165,
        0.030349746346473694,
        0.027486862614750862,
        -0.022308051586151123,
        0.00559611152857542,
        -0.06518697738647461,
        -0.0017178646521642804,
        -0.05944759398698807,
        0.04689415916800499,
        -0.006016652565449476,
        0.027524268254637718,
        0.0029660058207809925,
        -0.05775059014558792,
        0.047453105449676514,
        -0.028262855485081673,
        0.027872074395418167,
        0.04265996068716049,
        0.015051823109388351,
        0.010575070045888424,
        -0.01091558113694191,
        0.014326877892017365,
        -0.005886956583708525,
        0.02958158776164055,
        -0.050166547298431396,
        -0.06474227458238602,
        0.007578406948596239,
        -0.004016141872853041,
        -0.059503357857465744,
        -0.05871778354048729,
        0.04345489293336868,
        -0.019213708117604256,
        0.017654167488217354,
        -0.00868907943367958,
        0.057678062468767166,
        -0.04474163055419922,
        0.06409427523612976,
        0.0014507388696074486,
        0.05653461441397667,
        0.06558290123939514,
        0.05667724832892418,
        0.015608363784849644,
        0.043459270149469376,
        -0.03603094443678856,
        0.054362352937459946,
        -0.042009543627500534,
        -0.010950116440653801,
        -0.031188976019620895,
        0.002977921161800623,
        -0.012699520215392113,
        -0.028628729283809662,
        0.053391069173812866,
        -0.06203144043684006,
        0.013716822490096092,
        -0.062344759702682495,
        0.017384564504027367,
        -0.021718349307775497,
        0.0131465382874012,
        -0.014503558166325092,
        -0.044414691627025604,
        0.04306383803486824,
        -0.0688796415925026,
        -0.06365210562944412,
        -0.06805582344532013,
        0.014688266441226006,
        0.05442942678928375,
        -0.017404621466994286,
        -0.0077753872610628605,
        0.06427748501300812,
        0.05805032327771187,
        0.046848028898239136,
        -0.05980784446001053,
        -0.018436966463923454,
        0.035758160054683685,
        -0.01679510436952114,
        -0.010349594987928867,
        0.01112065277993679,
        0.02993360348045826,
        -0.06333008408546448,
        -0.04655393585562706,
        -0.06041524186730385,
        0.05880223214626312,
        -0.056331586092710495,
        -0.03536083176732063,
        -0.0626189187169075,
        -0.05757875740528107,
        0.037143364548683167,
        -0.05080654099583626,
        -0.05208160728216171,
        -0.06306828558444977,
        -0.04368596896529198,
        0.03773244842886925,
        -0.06595981866121292,
        -0.05339320749044418,
        0.06670083850622177,
        0.056218523532152176,
        0.03260035812854767,
        0.022247755900025368,
        -0.060596857219934464,
        0.0030308307614177465,
        -0.058826446533203125,
        -0.020006177946925163,
        -0.03854549303650856,
        0.04243486002087593,
        -0.029937157407402992,
        -0.037585265934467316,
        0.05014172941446304,
        -0.05816883593797684,
        -0.03342659771442413,
        -0.058008112013339996,
        -0.06363619118928909,
        -0.014024048112332821,
        -0.05219919607043266,
        -0.02323055826127529,
        0.00006196640606503934,
        0.025218365713953972,
        -0.06759138405323029,
        -0.04895920678973198,
        0.03248223289847374,
        0.015407105907797813,
        0.06787429749965668,
        0.04916340857744217,
        0.0037483028136193752,
        -0.047146402299404144,
        -0.0011410615406930447,
        0.0014398017665371299,
        -0.05622492730617523,
        -0.05644094571471214,
        0.04613151773810387,
        -0.039924412965774536,
        0.012259101495146751,
        0.058692868798971176,
        0.00650879368185997,
        0.009364919736981392,
        0.05007253587245941,
        0.059712667018175125,
        0.05706191435456276,
        0.03304160013794899,
        0.06523054838180542,
        -0.021124450489878654,
        0.06246385723352432,
        0.023879602551460266,
        -0.06384169310331345,
        -0.06343188136816025,
        -0.028409868478775024,
        0.03870926424860954,
        -0.05591969192028046,
        0.023130616173148155,
        0.010178648866713047,
        -0.01979679800570011,
        -0.0570683591067791,
        0.03821377828717232,
        0.06335951387882233,
        0.017474714666604996,
        0.05771331861615181,
        -0.04788614436984062,
        -0.056457020342350006,
        -0.01872938498854637,
        0.012986122630536556,
        -0.03572508692741394,
        -0.027344519272446632,
        0.0478266216814518,
        0.04041425138711929,
        -0.006463344674557447,
        -0.05642537772655487,
        0.036677245050668716,
        -0.04162631928920746,
        0.06475003808736801,
        -0.06057363376021385,
        0.028464149683713913,
        0.05667507275938988,
        0.0053753238171339035,
        0.027473540976643562,
        0.0591002032160759,
        0.06673869490623474,
        -0.014117313548922539,
        0.0030046168249100447,
        -0.05604110285639763,
        -0.01659935899078846,
        0.06482277065515518,
        -0.04248678311705589,
        -0.05104511231184006,
        -0.020372221246361732,
        -0.039531808346509933,
        -0.024930598214268684,
        0.012524131685495377,
        -0.03182445466518402,
        0.008528534322977066,
        0.05864308029413223,
        0.06168382614850998,
        -0.05226787179708481,
        0.0016240733675658703,
        -0.04664076864719391,
        0.0534478984773159,
        -0.022420290857553482,
        -0.018182244151830673,
        -0.02542087808251381,
        0.055502817034721375,
        0.009110558778047562,
        0.007623528130352497,
        0.04097908362746239,
        -0.05514644458889961,
        -0.05553445592522621,
        0.02007615938782692,
        -0.031150447204709053,
        0.060325317084789276,
        0.04944213852286339,
        -0.006637400947511196,
        0.007859761826694012,
        0.014843619428575039,
        0.06592809408903122,
        -0.052216578274965286,
        0.021733775734901428,
        -0.05790410190820694,
        -0.05059599131345749,
        -0.06596418470144272,
        0.0685267299413681,
        -0.0658850446343422,
        -0.03049592114984989,
        0.0029183905571699142,
        -0.022821340709924698,
        -0.06352018564939499,
        -0.041599735617637634,
        -0.04714760184288025,
        -0.06349451094865799,
        0.05534326285123825,
        -0.03881993889808655,
        -0.02438320219516754,
        0.06443697959184647,
        -0.008856488391757011,
        -0.04342738538980484,
        0.0578385628759861,
        0.05407005548477173,
        0.04698538780212402,
        0.034291401505470276,
        0.012895678170025349,
        0.031076380982995033,
        0.03689497336745262,
        0.0655314102768898,
        -0.018379637971520424,
        -0.01278602797538042,
        0.03946502506732941,
        -0.008801952935755253,
        -0.06618670374155045,
        -0.05819357931613922,
        -0.06522823125123978,
        -0.019900262355804443,
        0.05116942152380943,
        0.058790773153305054,
        0.034148626029491425,
        0.0680064931511879,
        0.062306132167577744,
        0.04625649377703667,
        0.06313039362430573,
        0.0038646161556243896,
        -0.0567517913877964,
        -0.00678868405520916,
        0.03372260183095932,
        0.013849569484591484,
        0.008257998153567314,
        0.02135615050792694,
        -0.050834622234106064,
        -0.04895474389195442,
        -0.06643606722354889,
        0.013098015449941158,
        -0.05405054986476898,
        -0.027435101568698883,
        0.04714073985815048,
        -0.06326980888843536,
        -0.028391985222697258,
        -0.0459522046148777,
        -0.0545184388756752,
        -0.06533940136432648,
        -0.040190864354372025,
        -0.04019477218389511,
        0.046956900507211685,
        -0.02713010646402836,
        -0.011158418841660023,
        0.0418449342250824,
        -0.026473838835954666,
        -0.06063739210367203,
        0.0002464063873048872,
        -0.030988913029432297,
        0.06000297889113426,
        0.002388359745964408,
        -0.055773407220840454,
        0.010887217707931995,
        -0.043718576431274414,
        0.06844650208950043,
        0.020442359149456024,
        -0.05886735022068024,
        0.0578644685447216,
        -0.03347490355372429,
        0.06115293502807617,
        -0.02059883065521717,
        -0.029867997393012047,
        -0.01835031248629093,
        -0.021880080923438072,
        -0.043644458055496216,
        0.020841244608163834,
        -0.008063048124313354,
        -0.06382784992456436,
        0.00007285574974957854,
        -0.06599046289920807,
        -0.03848572447896004,
        0.06476928293704987,
        -0.06480912864208221,
        -0.060574717819690704,
        0.05891886726021767,
        -0.03472546115517616,
        -0.027697307989001274,
        -0.03978486731648445,
        0.029077773913741112,
        -0.06184956803917885,
        0.05462989956140518,
        0.01382861752063036,
        0.05913841351866722,
        0.04910540580749512,
        0.052831653505563736,
        0.03543563932180405,
        -0.0054841008968651295,
        0.005489089991897345,
        0.03970324993133545,
        0.06207048520445824,
        -0.035809408873319626,
        -0.061472129076719284,
        0.06673911958932877,
        -0.04482707381248474,
        0.0014407222624868155,
        0.0673772543668747,
        -0.04429974406957626,
        0.062062326818704605,
        0.04622911289334297,
        -0.007589092943817377,
        -0.005784339737147093,
        -0.04211881011724472,
        0.06870193779468536,
        0.044834479689598083,
        -0.016734696924686432,
        0.05331156402826309,
        -0.03601715713739395,
        0.05780991539359093,
        -0.06805369257926941,
        0.012811386957764626,
        -0.057476647198200226,
        -0.06848924607038498,
        0.0028835192788392305,
        0.04381619393825531,
        -0.0616479218006134,
        0.02017122134566307,
        -0.05366867035627365,
        -0.05960739031434059,
        0.05994020402431488,
        0.05034938082098961,
        -0.04588185250759125,
        0.004501793533563614,
        -0.010288534685969353,
        -0.06590032577514648,
        0.06849943101406097,
        0.016117149963974953,
        0.05138736590743065,
        -0.0687340646982193,
        -0.03783649951219559,
        -0.06819985806941986,
        0.065045066177845,
        0.05451542139053345,
        0.05036790296435356,
        0.04452089965343475,
        0.02845638059079647,
        -0.011696198023855686,
        0.005072625819593668,
        -0.033552974462509155,
        -0.05118254944682121,
        0.04679713398218155,
        -0.05203184485435486,
        -0.04213394597172737,
        0.05623773857951164,
        -0.045058056712150574,
        0.009658308699727058,
        -0.05047217383980751,
        -0.026331158354878426,
        -0.005289858672767878,
        0.001928063458763063,
        0.052308790385723114,
        0.05659499391913414,
        0.04696948453783989,
        0.03529231622815132,
        0.06417275220155716,
        0.061198413372039795,
        -0.034729231148958206,
        0.016600728034973145,
        -0.06606201082468033,
        -0.01824239455163479,
        0.019943716004490852,
        0.056916337460279465,
        0.06618348509073257,
        -0.03422411531209946,
        0.0033915184903889894,
        0.001159390085376799,
        0.04475630819797516,
        0.044634319841861725,
        -0.03066186234354973,
        0.04831733927130699,
        0.009252749383449554,
        0.05825328826904297,
        -0.016247449442744255,
        0.05840335413813591,
        -0.013977187685668468,
        0.019002720713615417,
        0.011614479124546051,
        0.05975593253970146,
        0.05467701703310013,
        -0.00791038665920496,
        -0.030785132199525833,
        -0.029391562566161156,
        -0.06442239135503769,
        0.02519667148590088,
        0.026385661214590073,
        -0.05059480294585228,
        -0.05447351932525635,
        -0.06858962774276733,
        -0.0197068452835083,
        0.04634828492999077,
        -0.005159919150173664,
        0.030672123655676842,
        0.04803474247455597,
        -0.038644950836896896,
        -0.051462866365909576,
        0.010677946731448174,
        0.031050221994519234,
        0.06288187205791473,
        -0.06372625380754471,
        0.061277613043785095,
        0.033219389617443085,
        0.006386805325746536,
        0.058737773448228836,
        -0.045429255813360214,
        0.024576246738433838,
        0.06766404956579208,
        -0.013557272031903267,
        0.060440175235271454,
        -0.02871309220790863,
        -0.025030629709362984,
        0.04464865103363991,
        0.06109698861837387,
        -0.06707378476858139,
        -0.05895651876926422,
        -0.024117479100823402,
        -0.017841186374425888,
        0.03175355866551399,
        -0.04811215028166771,
        0.05175391584634781,
        -0.006889746990054846,
        -0.03439858928322792,
        -0.06301640719175339,
        -0.01757878251373768,
        0.06387384235858917,
        0.06808667629957199,
        -0.06207459047436714,
        0.06214669719338417,
        0.004473710898309946,
        -0.0674465000629425,
        0.054031047970056534,
        -0.0027791005559265614,
        -0.004593882244080305,
        0.046888317912817,
        0.044805146753787994,
        0.05091448128223419,
        -0.037742797285318375,
        -0.06896145641803741,
        -0.01672382652759552,
        -0.04184504598379135,
        -0.06480565667152405,
        0.050252515822649,
        0.04040670022368431,
        0.06167656555771828,
        0.009635934606194496,
        0.05382682755589485,
        0.025580259039998055,
        -0.01874866522848606,
        -0.057633984833955765,
        -0.05847271904349327,
        -0.06126769632101059,
        -0.058393802493810654,
        -0.05794241279363632,
        0.05439404025673866,
        0.04714931547641754,
        -0.0014910477912053466,
        -0.05024738982319832,
        0.04035118967294693,
        0.06144262105226517,
        0.057848621159791946,
        -0.06438251584768295,
        -0.028540542349219322,
        -0.028741084039211273,
        -0.011692674830555916,
        0.013607281260192394,
        0.026582539081573486,
        0.05208725854754448,
        0.023346243426203728,
        -0.06025891751050949,
        -0.011740332469344139,
        0.06061982363462448,
        0.047166671603918076
    ],
    [
        -0.02501836232841015,
        0.014990102499723434,
        0.01173427514731884,
        0.020573614165186882,
        0.027833081781864166,
        -0.01343440730124712,
        0.04679027944803238,
        -0.05019758269190788,
        0.044526055455207825,
        0.013276929967105389,
        -0.009110558778047562,
        0.0556914284825325,
        0.001182211679406464,
        -0.04177990183234215,
        -0.06873465329408646,
        0.054719746112823486,
        -0.030065953731536865,
        0.06329049170017242,
        -0.0516357347369194,
        -0.011006457731127739,
        -0.03686397150158882,
        0.03851272165775299,
        0.02368309162557125,
        -0.05309915542602539,
        0.035186491906642914,
        0.02526465430855751,
        0.04580773040652275,
        -0.027776405215263367,
        0.009094160050153732,
        -0.018176216632127762,
        0.07104280591011047,
        -0.02349051833152771,
        -0.011771579273045063,
        -0.05544785410165787,
        0.04178546741604805,
        0.017345158383250237,
        -0.06698445230722427,
        -0.04452577978372574,
        -0.037417780607938766,
        -0.015835518017411232,
        0.023377396166324615,
        0.032822124660015106,
        0.044823307543992996,
        -0.07502768933773041,
        0.04670366644859314,
        0.06927191466093063,
        0.01293428149074316,
        0.04232093691825867,
        0.029463177546858788,
        0.03871810808777809,
        0.02739485539495945,
        0.07018857449293137,
        -0.020278770476579666,
        0.03241376206278801,
        -0.009595674462616444,
        -0.04033932462334633,
        -0.00473881047219038,
        -0.009560887701809406,
        -0.000013591111382993404,
        -0.009217815473675728,
        -0.06842725723981857,
        0.02078068070113659,
        -0.044367387890815735,
        0.06360509991645813,
        -0.05588241294026375,
        -0.011828877031803131,
        0.050007812678813934,
        0.026380009949207306,
        -0.05386386439204216,
        -0.06947913020849228,
        -0.06416166573762894,
        -0.07597118616104126,
        0.061147935688495636,
        -0.0098183061927557,
        -0.02879459410905838,
        -0.0028467336669564247,
        0.010622960515320301,
        0.07441435009241104,
        -0.0014754852745682001,
        -0.015066445805132389,
        -0.026385879144072533,
        0.07165966928005219,
        -0.049440592527389526,
        0.015776755288243294,
        0.021373236551880836,
        0.026894494891166687,
        0.056326188147068024,
        -0.057535964995622635,
        0.029835568740963936,
        0.07171766459941864,
        0.05836040899157524,
        0.01647263579070568,
        -0.05506930500268936,
        -0.028178850188851357,
        -0.017341887578368187,
        0.025950787588953972,
        -0.0070165302604436874,
        -0.06546421349048615,
        -0.04120734706521034,
        0.011594751849770546,
        -0.06709244847297668,
        0.009601649828255177,
        0.07546110451221466,
        0.018531208857893944,
        0.04470499977469444,
        -0.03124619834125042,
        -0.03956234082579613,
        0.02387860231101513,
        -0.012434063479304314,
        0.020136751234531403,
        -0.0454254075884819,
        -0.03798672556877136,
        -0.06022606045007706,
        -0.012976260855793953,
        0.05084383487701416,
        -0.057901252061128616,
        0.034405697137117386,
        0.010662390850484371,
        0.06429584324359894,
        0.061732951551675797,
        -0.026200274005532265,
        0.059033360332250595,
        -0.042544592171907425,
        0.024912897497415543,
        -0.07011698931455612,
        -0.03926205635070801,
        -0.040355704724788666,
        -0.006303226575255394,
        0.06839804351329803,
        0.024602023884654045,
        0.012832879088819027,
        -0.06324176490306854,
        -0.04682342708110809,
        0.012281641364097595,
        -0.06144474446773529,
        -0.06501802057027817,
        0.001058978377841413,
        0.0068306028842926025,
        -0.06412041187286377,
        0.05590582638978958,
        0.07067471742630005,
        -0.020313551649451256,
        0.0612746998667717,
        0.0064989374950528145,
        0.050128355622291565,
        0.005627915263175964,
        0.06602312624454498,
        -0.04272155463695526,
        -0.008044658228754997,
        0.06382617354393005,
        0.04869769513607025,
        -0.07387848198413849,
        -0.035006146878004074,
        -0.04672379419207573,
        -0.01767740584909916,
        -0.0425531342625618,
        0.062410302460193634,
        0.022142941132187843,
        -0.017450010403990746,
        0.004111550748348236,
        0.05768946185708046,
        0.0614718459546566,
        0.062258776277303696,
        0.02859334461390972,
        0.00901085790246725,
        0.0034992871806025505,
        -0.05942234769463539,
        -0.018795203417539597,
        0.029151398688554764,
        0.03223691135644913,
        -0.0372130312025547,
        0.02635110542178154,
        0.001802854472771287,
        -0.04392434284090996,
        0.018497329205274582,
        0.06575945019721985,
        -0.07476705312728882,
        0.04507355019450188,
        0.0574825182557106,
        -0.02999188005924225,
        0.041240956634283066,
        0.06673504412174225,
        0.01095209363847971,
        0.0440218560397625,
        0.04773830249905586,
        0.02716984786093235,
        -0.0676003247499466,
        0.07205996662378311,
        -0.05812010169029236,
        -0.028049897402524948,
        -0.010824042372405529,
        0.02314598485827446,
        0.00003911548265023157,
        -0.016928790137171745,
        -0.049227919429540634,
        0.04476604610681534,
        0.01435609720647335,
        -0.04727645218372345,
        -0.03465414419770241,
        0.006676947232335806,
        0.02258746698498726,
        -0.014353903941810131,
        0.015987684950232506,
        -0.000864331959746778,
        -0.07449650764465332,
        0.06938513368368149,
        0.03108406439423561,
        0.005038261413574219,
        -0.05166472867131233,
        -0.0014825231628492475,
        -0.05381404981017113,
        0.07193086296319962,
        0.024210147559642792,
        0.03580606356263161,
        -0.0043314010836184025,
        0.021608835086226463,
        0.048766423016786575,
        -0.0025593091268092394,
        0.07087638974189758,
        -0.007611062377691269,
        0.014863444492220879,
        0.0539669394493103,
        -0.057936158031225204,
        -0.07257568091154099,
        0.07071902602910995,
        -0.05741516500711441,
        -0.03937044367194176,
        0.052764613181352615,
        -0.014338724315166473,
        -0.05947959050536156,
        0.013631981797516346,
        -0.05301334708929062,
        -0.058578018099069595,
        0.0217239111661911,
        -0.04459463432431221,
        0.0705592930316925,
        0.06299594789743423,
        -0.027736125513911247,
        -0.047912463545799255,
        0.0726551041007042,
        -0.011305801570415497,
        0.06831054389476776,
        -0.07582449913024902,
        0.04668654501438141,
        0.045097678899765015,
        -0.003946937620639801,
        -0.07303255796432495,
        -0.04572019353508949,
        -0.0174317117780447,
        -0.061546970158815384,
        -0.037994321435689926,
        -0.07014677673578262,
        -0.022626139223575592,
        -0.07045183330774307,
        -0.04512682184576988,
        0.06971245259046555,
        -0.0484854020178318,
        -0.047183264046907425,
        0.058948248624801636,
        0.03431783989071846,
        0.07441320270299911,
        -0.02676873654127121,
        -0.031363293528556824,
        0.05697481334209442,
        0.014557628892362118,
        0.02450399473309517,
        -0.05051637068390846,
        0.03992297500371933,
        -0.05884430930018425,
        -0.03629760444164276,
        0.046031419187784195,
        -0.05258018523454666,
        0.05888379365205765,
        -0.04198567941784859,
        -0.03616315871477127,
        0.05372967571020126,
        -0.0516185499727726,
        0.03192778676748276,
        -0.02353406697511673,
        -0.025611048564314842,
        -0.050427548587322235,
        -0.043850094079971313,
        -0.06038818508386612,
        0.06649340689182281,
        -0.012315589934587479,
        -0.059158869087696075,
        0.047306034713983536,
        0.02858932502567768,
        0.0000014930415090930182,
        0.05567127838730812,
        -0.03787781670689583,
        0.04986289143562317,
        0.025233853608369827,
        0.05530310422182083,
        0.07148019969463348,
        -0.06646686792373657,
        0.06995122879743576,
        0.059598129242658615,
        -0.05686682462692261,
        0.0416397787630558,
        0.04431924596428871,
        0.04800816997885704,
        -0.049680445343256,
        0.04479802027344704,
        -0.027326000854372978,
        0.017300942912697792,
        -0.05848550796508789,
        0.019907359033823013,
        0.035235483199357986,
        -0.07216484844684601,
        0.02527015097439289,
        -0.048069633543491364,
        -0.02316485531628132,
        0.019951747730374336,
        -0.01940375752747059,
        -0.05244568735361099,
        0.04572499170899391,
        -0.05615479499101639,
        0.026174424216151237,
        -0.04933740198612213,
        -0.06458862125873566,
        0.037871479988098145,
        0.052102088928222656,
        0.025311453267931938,
        0.03507562354207039,
        -0.0048815845511853695,
        -0.030170738697052002,
        -0.026664992794394493,
        -0.026701634749770164,
        0.015268852934241295,
        -0.010072100907564163,
        -0.0023758576717227697,
        -0.04958978295326233,
        -0.06627323478460312,
        0.07006341218948364,
        0.02114947885274887,
        -0.018300868570804596,
        0.06598810106515884,
        -0.03823234513401985,
        0.029802029952406883,
        0.04670927673578262,
        0.06326092034578323,
        -0.06963604688644409,
        -0.045902401208877563,
        0.0753689780831337,
        0.016840388998389244,
        -0.014361049048602581,
        0.05049560219049454,
        -0.024093829095363617,
        0.06926189363002777,
        0.003442849265411496,
        0.019066091626882553,
        -0.05507790669798851,
        -0.035514380782842636,
        -0.0635310560464859,
        0.06445879489183426,
        -0.047692153602838516,
        -0.010864567011594772,
        0.004881429485976696,
        -0.07398894429206848,
        0.061060987412929535,
        0.028852369636297226,
        -0.058755166828632355,
        0.008885337971150875,
        0.036709897220134735,
        -0.04484877735376358,
        0.06842794269323349,
        -0.016750331968069077,
        0.045721571892499924,
        -0.06752708554267883,
        0.00984214898198843,
        -0.02244483307003975,
        0.03525291010737419,
        0.06586334854364395,
        0.0326651930809021,
        0.03476976603269577,
        -0.028046026825904846,
        0.014108390547335148,
        0.004619262181222439,
        0.024823417887091637,
        0.01963423751294613,
        -0.0288260355591774,
        -0.0760301947593689,
        0.05640576034784317,
        0.05961019918322563,
        0.01969691924750805,
        -0.004157631658017635,
        -0.07415329664945602,
        -0.05336897820234299,
        -0.05899181216955185,
        -0.05964227765798569,
        -0.015027233399450779,
        0.021081747487187386,
        0.04055867716670036,
        -0.03965850919485092,
        0.06861940026283264,
        0.06143948808312416,
        0.029263122007250786,
        -0.011275623925030231,
        -0.0639924630522728,
        -0.015442769974470139,
        0.06519679725170135,
        0.06639990955591202,
        -0.008277866058051586,
        -0.038169410079717636,
        0.05411982163786888,
        0.03130548074841499,
        -0.007209554314613342,
        0.04093300923705101,
        0.01438861433416605,
        0.008706829510629177,
        -0.07041535526514053,
        0.03843832015991211,
        -0.02647208608686924,
        0.06516732275485992,
        -0.046520233154296875,
        0.0393371656537056,
        0.05185607075691223,
        0.041514527052640915,
        0.04719623178243637,
        -0.02948492392897606,
        -0.04474462941288948,
        -0.035002321004867554,
        -0.045854996889829636,
        -0.011364639736711979,
        0.04557333141565323,
        0.030151037499308586,
        -0.032626863569021225,
        0.015129520557820797,
        -0.059612520039081573,
        0.04631544277071953,
        -0.020726151764392853,
        -0.0698447972536087,
        -0.0035008429549634457,
        0.042582716792821884,
        -0.039307136088609695,
        -0.059100113809108734,
        -0.050229512155056,
        0.04601258039474487,
        0.008973845280706882,
        0.043710336089134216,
        0.04907454922795296,
        0.03734865039587021,
        0.046308018267154694,
        0.02217910625040531,
        0.01887083612382412,
        0.07444313913583755,
        -0.017554795369505882,
        0.06513585150241852,
        -0.014845418743789196,
        -0.04813311994075775,
        0.01735987886786461,
        0.0287026260048151,
        -0.013500440865755081,
        0.0271315835416317,
        -0.05376550182700157,
        0.05505134537816048,
        -0.004039025865495205,
        -0.05250692367553711,
        0.040710028260946274,
        0.013175084255635738,
        -0.008727882988750935,
        -0.07161731272935867,
        -0.008755234070122242,
        0.05470805615186691,
        0.07399722933769226,
        -0.044684574007987976,
        0.05953553691506386,
        -0.053132154047489166,
        -0.07055001705884933,
        0.044732093811035156,
        0.06047208234667778,
        0.017999492585659027,
        0.000577277154661715,
        -0.05966692417860031,
        -0.03770393878221512,
        0.0373966246843338,
        0.010982577688992023,
        -0.05330146104097366,
        -0.028519898653030396,
        -0.021876797080039978,
        -0.050547923892736435,
        0.004603899084031582,
        0.026853492483496666,
        0.029799839481711388,
        0.031147664412856102,
        0.016423150897026062,
        0.05061987414956093,
        -0.05999966710805893,
        0.013621727004647255,
        -0.0671592429280281,
        0.01847597025334835,
        0.007281139958649874,
        0.02054571732878685,
        0.024496223777532578,
        -0.004305895417928696,
        -0.010905173607170582,
        0.04055012762546539,
        0.06427010148763657,
        0.06719644367694855,
        -0.03844355791807175,
        -0.05380496382713318,
        -0.010735269635915756,
        0.01772465370595455,
        -0.027468353509902954,
        -0.03843770548701286,
        0.06214653700590134,
        0.04191874712705612,
        -0.05116845294833183,
        -0.06965836882591248,
        0.048622388392686844,
        0.07578525692224503
    ],
    [
        -0.02501836232841015,
        0.014990102499723434,
        0.01173427514731884,
        0.020573614165186882,
        0.027833081781864166,
        -0.01343440730124712,
        0.04679027944803238,
        -0.05019758269190788,
        0.044526055455207825,
        0.013276929967105389,
        -0.009110558778047562,
        0.0556914284825325,
        0.001182211679406464,
        -0.04177990183234215,
        -0.06873465329408646,
        0.054719746112823486,
        -0.030065953731536865,
        0.06329049170017242,
        -0.0516357347369194,
        -0.011006457731127739,
        -0.03686397150158882,
        0.03851272165775299,
        0.02368309162557125,
        -0.05309915542602539,
        0.035186491906642914,
        0.02526465430855751,
        0.04580773040652275,
        -0.027776405215263367,
        0.009094160050153732,
        -0.018176216632127762,
        0.07104280591011047,
        -0.02349051833152771,
        -0.011771579273045063,
        -0.05544785410165787,
        0.04178546741604805,
        0.017345158383250237,
        -0.06698445230722427,
        -0.04452577978372574,
        -0.037417780607938766,
        -0.015835518017411232,
        0.023377396166324615,
        0.032822124660015106,
        0.044823307543992996,
        -0.07502768933773041,
        0.04670366644859314,
        0.06927191466093063,
        0.01293428149074316,
        0.04232093691825867,
        0.029463177546858788,
        0.03871810808777809,
        0.02739485539495945,
        0.07018857449293137,
        -0.020278770476579666,
        0.03241376206278801,
        -0.009595674462616444,
        -0.04033932462334633,
        -0.00473881047219038,
        -0.009560887701809406,
        -0.000013591111382993404,
        -0.009217815473675728,
        -0.06842725723981857,
        0.02078068070113659,
        -0.044367387890815735,
        0.06360509991645813,
        -0.05588241294026375,
        -0.011828877031803131,
        0.050007812678813934,
        0.026380009949207306,
        -0.05386386439204216,
        -0.06947913020849228,
        -0.06416166573762894,
        -0.07597118616104126,
        0.061147935688495636,
        -0.0098183061927557,
        -0.02879459410905838,
        -0.0028467336669564247,
        0.010622960515320301,
        0.07441435009241104,
        -0.0014754852745682001,
        -0.015066445805132389,
        -0.026385879144072533,
        0.07165966928005219,
        -0.049440592527389526,
        0.015776755288243294,
        0.021373236551880836,
        0.026894494891166687,
        0.056326188147068024,
        -0.057535964995622635,
        0.029835568740963936,
        0.07171766459941864,
        0.05836040899157524,
        0.01647263579070568,
        -0.05506930500268936,
        -0.028178850188851357,
        -0.017341887578368187,
        0.025950787588953972,
        -0.0070165302604436874,
        -0.06546421349048615,
        -0.04120734706521034,
        0.011594751849770546,
        -0.06709244847297668,
        0.009601649828255177,
        0.07546110451221466,
        0.018531208857893944,
        0.04470499977469444,
        -0.03124619834125042,
        -0.03956234082579613,
        0.02387860231101513,
        -0.012434063479304314,
        0.020136751234531403,
        -0.0454254075884819,
        -0.03798672556877136,
        -0.06022606045007706,
        -0.012976260855793953,
        0.05084383487701416,
        -0.057901252061128616,
        0.034405697137117386,
        0.010662390850484371,
        0.06429584324359894,
        0.061732951551675797,
        -0.026200274005532265,
        0.059033360332250595,
        -0.042544592171907425,
        0.024912897497415543,
        -0.07011698931455612,
        -0.03926205635070801,
        -0.040355704724788666,
        -0.006303226575255394,
        0.06839804351329803,
        0.024602023884654045,
        0.012832879088819027,
        -0.06324176490306854,
        -0.04682342708110809,
        0.012281641364097595,
        -0.06144474446773529,
        -0.06501802057027817,
        0.001058978377841413,
        0.0068306028842926025,
        -0.06412041187286377,
        0.05590582638978958,
        0.07067471742630005,
        -0.020313551649451256,
        0.0612746998667717,
        0.0064989374950528145,
        0.050128355622291565,
        0.005627915263175964,
        0.06602312624454498,
        -0.04272155463695526,
        -0.008044658228754997,
        0.06382617354393005,
        0.04869769513607025,
        -0.07387848198413849,
        -0.035006146878004074,
        -0.04672379419207573,
        -0.01767740584909916,
        -0.0425531342625618,
        0.062410302460193634,
        0.022142941132187843,
        -0.017450010403990746,
        0.004111550748348236,
        0.05768946185708046,
        0.0614718459546566,
        0.062258776277303696,
        0.02859334461390972,
        0.00901085790246725,
        0.0034992871806025505,
        -0.05942234769463539,
        -0.018795203417539597,
        0.029151398688554764,
        0.03223691135644913,
        -0.0372130312025547,
        0.02635110542178154,
        0.001802854472771287,
        -0.04392434284090996,
        0.018497329205274582,
        0.06575945019721985,
        -0.07476705312728882,
        0.04507355019450188,
        0.0574825182557106,
        -0.02999188005924225,
        0.041240956634283066,
        0.06673504412174225,
        0.01095209363847971,
        0.0440218560397625,
        0.04773830249905586,
        0.02716984786093235,
        -0.0676003247499466,
        0.07205996662378311,
        -0.05812010169029236,
        -0.028049897402524948,
        -0.010824042372405529,
        0.02314598485827446,
        0.00003911548265023157,
        -0.016928790137171745,
        -0.049227919429540634,
        0.04476604610681534,
        0.01435609720647335,
        -0.04727645218372345,
        -0.03465414419770241,
        0.006676947232335806,
        0.02258746698498726,
        -0.014353903941810131,
        0.015987684950232506,
        -0.000864331959746778,
        -0.07449650764465332,
        0.06938513368368149,
        0.03108406439423561,
        0.005038261413574219,
        -0.05166472867131233,
        -0.0014825231628492475,
        -0.05381404981017113,
        0.07193086296319962,
        0.024210147559642792,
        0.03580606356263161,
        -0.0043314010836184025,
        0.021608835086226463,
        0.048766423016786575,
        -0.0025593091268092394,
        0.07087638974189758,
        -0.007611062377691269,
        0.014863444492220879,
        0.0539669394493103,
        -0.057936158031225204,
        -0.07257568091154099,
        0.07071902602910995,
        -0.05741516500711441,
        -0.03937044367194176,
        0.052764613181352615,
        -0.014338724315166473,
        -0.05947959050536156,
        0.013631981797516346,
        -0.05301334708929062,
        -0.058578018099069595,
        0.0217239111661911,
        -0.04459463432431221,
        0.0705592930316925,
        0.06299594789743423,
        -0.027736125513911247,
        -0.047912463545799255,
        0.0726551041007042,
        -0.011305801570415497,
        0.06831054389476776,
        -0.07582449913024902,
        0.04668654501438141,
        0.045097678899765015,
        -0.003946937620639801,
        -0.07303255796432495,
        -0.04572019353508949,
        -0.0174317117780447,
        -0.061546970158815384,
        -0.037994321435689926,
        -0.07014677673578262,
        -0.022626139223575592,
        -0.07045183330774307,
        -0.04512682184576988,
        0.06971245259046555,
        -0.0484854020178318,
        -0.047183264046907425,
        0.058948248624801636,
        0.03431783989071846,
        0.07441320270299911,
        -0.02676873654127121,
        -0.031363293528556824,
        0.05697481334209442,
        0.014557628892362118,
        0.02450399473309517,
        -0.05051637068390846,
        0.03992297500371933,
        -0.05884430930018425,
        -0.03629760444164276,
        0.046031419187784195,
        -0.05258018523454666,
        0.05888379365205765,
        -0.04198567941784859,
        -0.03616315871477127,
        0.05372967571020126,
        -0.0516185499727726,
        0.03192778676748276,
        -0.02353406697511673,
        -0.025611048564314842,
        -0.050427548587322235,
        -0.043850094079971313,
        -0.06038818508386612,
        0.06649340689182281,
        -0.012315589934587479,
        -0.059158869087696075,
        0.047306034713983536,
        0.02858932502567768,
        0.0000014930415090930182,
        0.05567127838730812,
        -0.03787781670689583,
        0.04986289143562317,
        0.025233853608369827,
        0.05530310422182083,
        0.07148019969463348,
        -0.06646686792373657,
        0.06995122879743576,
        0.059598129242658615,
        -0.05686682462692261,
        0.0416397787630558,
        0.04431924596428871,
        0.04800816997885704,
        -0.049680445343256,
        0.04479802027344704,
        -0.027326000854372978,
        0.017300942912697792,
        -0.05848550796508789,
        0.019907359033823013,
        0.035235483199357986,
        -0.07216484844684601,
        0.02527015097439289,
        -0.048069633543491364,
        -0.02316485531628132,
        0.019951747730374336,
        -0.01940375752747059,
        -0.05244568735361099,
        0.04572499170899391,
        -0.05615479499101639,
        0.026174424216151237,
        -0.04933740198612213,
        -0.06458862125873566,
        0.037871479988098145,
        0.052102088928222656,
        0.025311453267931938,
        0.03507562354207039,
        -0.0048815845511853695,
        -0.030170738697052002,
        -0.026664992794394493,
        -0.026701634749770164,
        0.015268852934241295,
        -0.010072100907564163,
        -0.0023758576717227697,
        -0.04958978295326233,
        -0.06627323478460312,
        0.07006341218948364,
        0.02114947885274887,
        -0.018300868570804596,
        0.06598810106515884,
        -0.03823234513401985,
        0.029802029952406883,
        0.04670927673578262,
        0.06326092034578323,
        -0.06963604688644409,
        -0.045902401208877563,
        0.0753689780831337,
        0.016840388998389244,
        -0.014361049048602581,
        0.05049560219049454,
        -0.024093829095363617,
        0.06926189363002777,
        0.003442849265411496,
        0.019066091626882553,
        -0.05507790669798851,
        -0.035514380782842636,
        -0.0635310560464859,
        0.06445879489183426,
        -0.047692153602838516,
        -0.010864567011594772,
        0.004881429485976696,
        -0.07398894429206848,
        0.061060987412929535,
        0.028852369636297226,
        -0.058755166828632355,
        0.008885337971150875,
        0.036709897220134735,
        -0.04484877735376358,
        0.06842794269323349,
        -0.016750331968069077,
        0.045721571892499924,
        -0.06752708554267883,
        0.00984214898198843,
        -0.02244483307003975,
        0.03525291010737419,
        0.06586334854364395,
        0.0326651930809021,
        0.03476976603269577,
        -0.028046026825904846,
        0.014108390547335148,
        0.004619262181222439,
        0.024823417887091637,
        0.01963423751294613,
        -0.0288260355591774,
        -0.0760301947593689,
        0.05640576034784317,
        0.05961019918322563,
        0.01969691924750805,
        -0.004157631658017635,
        -0.07415329664945602,
        -0.05336897820234299,
        -0.05899181216955185,
        -0.05964227765798569,
        -0.015027233399450779,
        0.021081747487187386,
        0.04055867716670036,
        -0.03965850919485092,
        0.06861940026283264,
        0.06143948808312416,
        0.029263122007250786,
        -0.011275623925030231,
        -0.0639924630522728,
        -0.015442769974470139,
        0.06519679725170135,
        0.06639990955591202,
        -0.008277866058051586,
        -0.038169410079717636,
        0.05411982163786888,
        0.03130548074841499,
        -0.007209554314613342,
        0.04093300923705101,
        0.01438861433416605,
        0.008706829510629177,
        -0.07041535526514053,
        0.03843832015991211,
        -0.02647208608686924,
        0.06516732275485992,
        -0.046520233154296875,
        0.0393371656537056,
        0.05185607075691223,
        0.041514527052640915,
        0.04719623178243637,
        -0.02948492392897606,
        -0.04474462941288948,
        -0.035002321004867554,
        -0.045854996889829636,
        -0.011364639736711979,
        0.04557333141565323,
        0.030151037499308586,
        -0.032626863569021225,
        0.015129520557820797,
        -0.059612520039081573,
        0.04631544277071953,
        -0.020726151764392853,
        -0.0698447972536087,
        -0.0035008429549634457,
        0.042582716792821884,
        -0.039307136088609695,
        -0.059100113809108734,
        -0.050229512155056,
        0.04601258039474487,
        0.008973845280706882,
        0.043710336089134216,
        0.04907454922795296,
        0.03734865039587021,
        0.046308018267154694,
        0.02217910625040531,
        0.01887083612382412,
        0.07444313913583755,
        -0.017554795369505882,
        0.06513585150241852,
        -0.014845418743789196,
        -0.04813311994075775,
        0.01735987886786461,
        0.0287026260048151,
        -0.013500440865755081,
        0.0271315835416317,
        -0.05376550182700157,
        0.05505134537816048,
        -0.004039025865495205,
        -0.05250692367553711,
        0.040710028260946274,
        0.013175084255635738,
        -0.008727882988750935,
        -0.07161731272935867,
        -0.008755234070122242,
        0.05470805615186691,
        0.07399722933769226,
        -0.044684574007987976,
        0.05953553691506386,
        -0.053132154047489166,
        -0.07055001705884933,
        0.044732093811035156,
        0.06047208234667778,
        0.017999492585659027,
        0.000577277154661715,
        -0.05966692417860031,
        -0.03770393878221512,
        0.0373966246843338,
        0.010982577688992023,
        -0.05330146104097366,
        -0.028519898653030396,
        -0.021876797080039978,
        -0.050547923892736435,
        0.004603899084031582,
        0.026853492483496666,
        0.029799839481711388,
        0.031147664412856102,
        0.016423150897026062,
        0.05061987414956093,
        -0.05999966710805893,
        0.013621727004647255,
        -0.0671592429280281,
        0.01847597025334835,
        0.007281139958649874,
        0.02054571732878685,
        0.024496223777532578,
        -0.004305895417928696,
        -0.010905173607170582,
        0.04055012762546539,
        0.06427010148763657,
        0.06719644367694855,
        -0.03844355791807175,
        -0.05380496382713318,
        -0.010735269635915756,
        0.01772465370595455,
        -0.027468353509902954,
        -0.03843770548701286,
        0.06214653700590134,
        0.04191874712705612,
        -0.05116845294833183,
        -0.06965836882591248,
        0.048622388392686844,
        0.07578525692224503
    ],
    [
        -0.03799612820148468,
        0.007922027260065079,
        0.005400017369538546,
        0.026247547939419746,
        0.026459837332367897,
        -0.02926730550825596,
        0.051284272223711014,
        -0.05120252072811127,
        0.040495239198207855,
        0.03151814639568329,
        -0.0022225906141102314,
        0.05714133754372597,
        0.0011080430122092366,
        -0.03447408601641655,
        -0.06772475689649582,
        0.04880310595035553,
        -0.03930417075753212,
        0.06165805831551552,
        -0.04904869943857193,
        -0.03328191116452217,
        -0.02236993983387947,
        0.03449944779276848,
        0.033374011516571045,
        -0.052165694534778595,
        0.04291803017258644,
        0.03184723109006882,
        0.05632453411817551,
        -0.03205190598964691,
        0.005773686803877354,
        -0.004657619632780552,
        0.06722274422645569,
        -0.021301519125699997,
        -0.013926289044320583,
        -0.06466083228588104,
        0.04053639620542526,
        0.010382847860455513,
        -0.06609012931585312,
        -0.039480507373809814,
        -0.02334674261510372,
        -0.012037914246320724,
        0.0119346147403121,
        0.03489934280514717,
        0.039314813911914825,
        -0.06927590072154999,
        0.03676498681306839,
        0.06759138405323029,
        0.028789078816771507,
        0.04262063652276993,
        0.05131056159734726,
        0.02734135277569294,
        0.05078510195016861,
        0.06512115895748138,
        -0.01238219253718853,
        0.043210774660110474,
        -0.020727811381220818,
        -0.04910050332546234,
        -0.030748579651117325,
        0.00422871857881546,
        0.01636827550828457,
        -0.012053577229380608,
        -0.06831692159175873,
        0.02091015689074993,
        -0.04301367700099945,
        0.06618198752403259,
        -0.04841669276356697,
        -0.011891614645719528,
        0.04548093304038048,
        0.0058112493716180325,
        -0.05879906192421913,
        -0.06836489588022232,
        -0.0651940181851387,
        -0.07315114885568619,
        0.06082276254892349,
        -0.032626550644636154,
        -0.006603223271667957,
        0.0017573607619851828,
        0.036534231156110764,
        0.07051878422498703,
        0.001814797054976225,
        -0.033061567693948746,
        -0.016384728252887726,
        0.06805025786161423,
        -0.054207898676395416,
        0.03915400058031082,
        0.03370601683855057,
        0.030378708615899086,
        0.05393436551094055,
        -0.05902519449591637,
        0.03802209347486496,
        0.06846987456083298,
        0.01890525594353676,
        -0.00427690614014864,
        -0.06435264647006989,
        -0.036258265376091,
        0.0023961642291396856,
        0.030171267688274384,
        -0.006598388776183128,
        -0.0636342316865921,
        -0.034752968698740005,
        0.012996340170502663,
        -0.06629543751478195,
        0.028331244364380836,
        0.07216238975524902,
        0.035314079374074936,
        0.04317491874098778,
        -0.026734696701169014,
        -0.02472441829741001,
        0.03448392078280449,
        -0.018091807141900063,
        0.03564877808094025,
        -0.04419190064072609,
        -0.03280101716518402,
        -0.06136839836835861,
        -0.002245037816464901,
        0.04392096772789955,
        -0.06227589771151543,
        0.04599051922559738,
        -0.02453487180173397,
        0.06206634268164635,
        0.061184000223875046,
        0.006200430449098349,
        0.06545311957597733,
        -0.04776759445667267,
        0.03961878642439842,
        -0.0663648173213005,
        -0.01966087706387043,
        -0.037435226142406464,
        -0.03384855389595032,
        0.06340587884187698,
        0.037474535405635834,
        0.02747868373990059,
        -0.054519444704055786,
        -0.04891624301671982,
        0.007122658658772707,
        -0.06364743411540985,
        -0.06271268427371979,
        0.020156750455498695,
        0.020047776401042938,
        -0.06222217157483101,
        0.061322201043367386,
        0.06647636741399765,
        -0.01844455860555172,
        0.05549453943967819,
        -0.016478411853313446,
        0.0522780604660511,
        -0.022292593494057655,
        0.0645579919219017,
        -0.037934836000204086,
        0.0028452975675463676,
        0.06077701225876808,
        0.04871870204806328,
        -0.07082045823335648,
        -0.03383317589759827,
        -0.04503112658858299,
        -0.01482631266117096,
        -0.038865238428115845,
        0.06077301874756813,
        0.009811234660446644,
        -0.022041182965040207,
        0.015056262724101543,
        0.06171383708715439,
        0.0544646680355072,
        0.05623661354184151,
        0.020903190597891808,
        -0.008357125334441662,
        0.017253268510103226,
        -0.05615699663758278,
        -0.015623423270881176,
        0.032182421535253525,
        0.03827828913927078,
        -0.046356797218322754,
        0.026689136400818825,
        0.001937826513312757,
        -0.041838351637125015,
        0.014241274446249008,
        0.06726158410310745,
        -0.07109028100967407,
        0.04620673507452011,
        0.04639201611280441,
        -0.05242924019694328,
        0.04089733213186264,
        0.0695076733827591,
        0.009044915437698364,
        0.05544757470488548,
        0.04870244860649109,
        0.02535717748105526,
        -0.06886133551597595,
        0.07023162394762039,
        -0.05795201659202576,
        -0.04107531160116196,
        -0.005539480596780777,
        0.027233639732003212,
        -0.012535786256194115,
        -0.0036175381392240524,
        -0.05838831886649132,
        0.03536728024482727,
        0.011065435595810413,
        -0.055247075855731964,
        -0.04773695021867752,
        0.009114020504057407,
        0.016121195629239082,
        -0.024311060085892677,
        0.0012999860337004066,
        -0.01208294928073883,
        -0.07111718505620956,
        0.06722031533718109,
        0.04102247953414917,
        0.013135088607668877,
        -0.05179080739617348,
        -0.005740809254348278,
        -0.05249226465821266,
        0.06921543926000595,
        0.01590399257838726,
        0.03393165394663811,
        -0.008205467835068703,
        0.007927848026156425,
        0.046435434371232986,
        -0.013405618257820606,
        0.06988491117954254,
        -0.024532124400138855,
        -0.007116824388504028,
        0.0489896759390831,
        -0.062087416648864746,
        -0.0684107095003128,
        0.07028965651988983,
        -0.061521273106336594,
        -0.03129762411117554,
        0.04604344442486763,
        -0.014665343798696995,
        -0.058531880378723145,
        0.026763997972011566,
        -0.06412514299154282,
        -0.052646540105342865,
        0.033503249287605286,
        -0.04982556775212288,
        0.06231195107102394,
        0.06523114442825317,
        -0.03321562334895134,
        -0.0441986508667469,
        0.06779353320598602,
        -0.013112769462168217,
        0.06645442545413971,
        -0.07303625345230103,
        0.05581090971827507,
        0.05022261291742325,
        -0.0043897018767893314,
        -0.06780510395765305,
        -0.03370615839958191,
        -0.004049698356539011,
        -0.06235536187887192,
        -0.04379204660654068,
        -0.06909061223268509,
        -0.0329887792468071,
        -0.06787681579589844,
        -0.036968763917684555,
        0.0651606023311615,
        -0.046361979097127914,
        -0.05053776130080223,
        0.06359649449586868,
        0.02816861681640148,
        0.07104431092739105,
        -0.024367576465010643,
        -0.025896845385432243,
        0.062247879803180695,
        -0.0018104861956089735,
        0.01964527741074562,
        -0.041794583201408386,
        0.04327716305851936,
        -0.04935172572731972,
        -0.037818726152181625,
        0.039236195385456085,
        -0.05986824631690979,
        0.06323470920324326,
        -0.04351508244872093,
        -0.022529233247041702,
        0.04226474091410637,
        -0.05395872890949249,
        0.03290873393416405,
        -0.04073764756321907,
        -0.014888299629092216,
        -0.06302502751350403,
        -0.037126895040273666,
        -0.06008407473564148,
        0.06702396273612976,
        0.02173471450805664,
        -0.056455038487911224,
        0.038877490907907486,
        0.029358794912695885,
        0.008450947701931,
        0.05315852910280228,
        -0.04707563295960426,
        0.04763813689351082,
        0.01034329179674387,
        0.05180920660495758,
        0.07031069695949554,
        -0.06212328374385834,
        0.06605590134859085,
        0.06272194534540176,
        -0.05523737892508507,
        0.045724596828222275,
        0.04869646579027176,
        0.04963197559118271,
        -0.04241247847676277,
        0.04125194624066353,
        -0.013747207820415497,
        -0.0021287333220243454,
        -0.05416272580623627,
        0.022726403549313545,
        0.03439384326338768,
        -0.06759566068649292,
        0.03392868861556053,
        -0.05887748673558235,
        -0.030078720301389694,
        0.00863893236964941,
        -0.010346122086048126,
        -0.05253276973962784,
        0.04420116916298866,
        -0.055800337344408035,
        0.0251170601695776,
        -0.05701063573360443,
        -0.0557868592441082,
        0.028009286150336266,
        0.047102220356464386,
        0.03553202748298645,
        0.03351608291268349,
        0.005784805864095688,
        -0.03051432967185974,
        -0.011510034091770649,
        -0.013547174632549286,
        0.01205996610224247,
        0.008084218949079514,
        0.018435295671224594,
        -0.03953978791832924,
        -0.060269828885793686,
        0.06778445094823837,
        0.040526676923036575,
        -0.031074553728103638,
        0.06007996201515198,
        -0.014312393963336945,
        0.014813964255154133,
        0.04883625730872154,
        0.05901280790567398,
        -0.06713244318962097,
        -0.05963600426912308,
        0.0718502402305603,
        0.008689085021615028,
        -0.004520880524069071,
        0.052891433238983154,
        -0.027791308239102364,
        0.06381591409444809,
        0.013494232669472694,
        0.02758960984647274,
        -0.06276204437017441,
        -0.05706807225942612,
        -0.0640852078795433,
        0.06438553333282471,
        -0.05510050058364868,
        -0.027542779222130775,
        0.012745575048029423,
        -0.07177865505218506,
        0.060796111822128296,
        0.021886980161070824,
        -0.04562249779701233,
        0.015490569174289703,
        0.036508865654468536,
        -0.04901490733027458,
        0.07112041860818863,
        -0.02322353608906269,
        0.06399662792682648,
        -0.06823992729187012,
        -0.009308133274316788,
        -0.035209640860557556,
        0.04276447743177414,
        0.06321205943822861,
        0.044743917882442474,
        0.04695754498243332,
        -0.015771184116601944,
        0.017426488921046257,
        0.015656229108572006,
        0.03947925567626953,
        0.0037442240864038467,
        -0.028511378914117813,
        -0.07236859947443008,
        0.04002263396978378,
        0.043652113527059555,
        0.012519989162683487,
        -0.012457570992410183,
        -0.06908110529184341,
        -0.048940252512693405,
        -0.05016740784049034,
        -0.0665946826338768,
        -0.00872707273811102,
        0.02547755092382431,
        0.04517022520303726,
        -0.02488851174712181,
        0.06718814373016357,
        0.06332026422023773,
        0.012868433259427547,
        -0.0011740511981770396,
        -0.06566725671291351,
        -0.024560431018471718,
        0.0646696612238884,
        0.06538763642311096,
        -0.014274987392127514,
        -0.034962572157382965,
        0.04966229200363159,
        0.03681133687496185,
        -0.005610155873000622,
        0.043088704347610474,
        0.014558118768036366,
        0.01849953830242157,
        -0.067259281873703,
        0.03404965251684189,
        0.003818981582298875,
        0.06138093024492264,
        -0.04447256401181221,
        0.035741135478019714,
        0.05170677602291107,
        0.030644070357084274,
        0.05419725552201271,
        -0.023716162890195847,
        -0.03835129365324974,
        -0.02736896462738514,
        -0.05070333555340767,
        0.006661010906100273,
        0.04290599003434181,
        0.022252177819609642,
        -0.01900346390902996,
        0.014290428720414639,
        -0.05158126354217529,
        0.055038999766111374,
        -0.01790725067257881,
        -0.06956999003887177,
        -0.013555383309721947,
        0.028524521738290787,
        -0.04918457567691803,
        -0.028192726895213127,
        -0.050449930131435394,
        0.05957825109362602,
        0.0035129233729094267,
        0.026999127119779587,
        0.05069554224610329,
        0.04851926490664482,
        0.05427918583154678,
        0.008924992755055428,
        0.010060171596705914,
        0.07073774933815002,
        -0.028914200142025948,
        0.06225423514842987,
        -0.010878198780119419,
        -0.04718499630689621,
        0.0020267292857170105,
        0.05045921355485916,
        -0.0022416063584387302,
        0.03842413052916527,
        -0.05267176777124405,
        0.044389501214027405,
        -0.011208496987819672,
        -0.051520563662052155,
        0.05845477059483528,
        0.0050883544608950615,
        -0.018391093239188194,
        -0.06947848945856094,
        -0.02322245202958584,
        0.05828128755092621,
        0.07134152948856354,
        -0.05409575626254082,
        0.058136045932769775,
        -0.05603911727666855,
        -0.06923028081655502,
        0.05155421420931816,
        0.06585350632667542,
        0.026739029213786125,
        0.024587152525782585,
        -0.06728722900152206,
        -0.0220057163387537,
        0.015405461192131042,
        0.015673885121941566,
        -0.0528358556330204,
        -0.02617315761744976,
        -0.030779002234339714,
        -0.05088202655315399,
        -0.0132305221632123,
        0.025232069194316864,
        0.020965272560715675,
        0.047269824892282486,
        0.011285015381872654,
        0.040907904505729675,
        -0.05609000846743584,
        -0.030343931168317795,
        -0.06207401677966118,
        0.015339418314397335,
        0.010519948787987232,
        0.019028469920158386,
        0.04099085554480553,
        -0.0003222546074539423,
        -0.028012387454509735,
        0.054653316736221313,
        0.060753095895051956,
        0.06644777208566666,
        -0.04638077691197395,
        -0.04687591269612312,
        -0.0062925247475504875,
        -0.0009094078559428453,
        -0.005157046485692263,
        -0.045744650065898895,
        0.06526976823806763,
        0.03420328348875046,
        -0.048378538340330124,
        -0.06519116461277008,
        0.05148834362626076,
        0.07244342565536499
    ],
    [
        -0.020352832973003387,
        0.006553829647600651,
        0.01170763187110424,
        0.026021743193268776,
        0.033264707773923874,
        -0.012001373805105686,
        0.04745157063007355,
        -0.048467762768268585,
        0.04622819274663925,
        0.018017606809735298,
        -0.013728078454732895,
        0.05963493511080742,
        -0.008446012623608112,
        -0.038973040878772736,
        -0.0693005844950676,
        0.05703768879175186,
        -0.036530863493680954,
        0.062456704676151276,
        -0.05044769123196602,
        -0.003534352872520685,
        -0.03791795298457146,
        0.04327286407351494,
        0.031956207007169724,
        -0.05682136490941048,
        0.04118955880403519,
        0.013374031521379948,
        0.046416204422712326,
        -0.031160496175289154,
        0.005199247971177101,
        -0.01571088470518589,
        0.06770394742488861,
        -0.03199480473995209,
        -0.0022379544097930193,
        -0.05517878010869026,
        0.04133255407214165,
        -0.0039169881492853165,
        -0.0670066773891449,
        -0.04919525980949402,
        -0.025244250893592834,
        -0.019397050142288208,
        0.008432227186858654,
        0.03693862631917,
        0.0495680533349514,
        -0.07342485338449478,
        0.042076390236616135,
        0.06954135745763779,
        0.026740437373518944,
        0.030313972383737564,
        0.02074715681374073,
        0.04098581150174141,
        0.02632010355591774,
        0.06743624061346054,
        -0.0017152425134554505,
        0.03746257349848747,
        -0.003682300914078951,
        -0.045396387577056885,
        -0.016418732702732086,
        -0.0018481689039617777,
        0.0050691766664385796,
        -0.003294057911261916,
        -0.06262745708227158,
        0.02675771340727806,
        -0.04473203420639038,
        0.06303505599498749,
        -0.04982932284474373,
        -0.021046772599220276,
        0.051272403448820114,
        0.014672977849841118,
        -0.058450981974601746,
        -0.06884833425283432,
        -0.056967735290527344,
        -0.07466190308332443,
        0.06487935781478882,
        -0.01035227533429861,
        -0.03060886263847351,
        -0.013258593156933784,
        0.020058104768395424,
        0.07310375571250916,
        -0.0000598903679929208,
        -0.013491791673004627,
        -0.01317575667053461,
        0.07184606790542603,
        -0.0432266891002655,
        0.018953636288642883,
        0.022608237341046333,
        0.036365047097206116,
        0.057468537241220474,
        -0.05842481926083565,
        0.019164126366376877,
        0.07261448353528976,
        0.04760623723268509,
        0.007367752492427826,
        -0.0571790486574173,
        -0.029634667560458183,
        -0.01870262809097767,
        0.03308914601802826,
        -0.01566271483898163,
        -0.0662982165813446,
        -0.040377479046583176,
        0.01666710525751114,
        -0.07084457576274872,
        0.015326263383030891,
        0.07435659319162369,
        0.018310699611902237,
        0.05427722632884979,
        -0.02463834173977375,
        -0.03999632969498634,
        0.019897932186722755,
        -0.01319624949246645,
        0.016208361834287643,
        -0.0483693890273571,
        -0.04461895674467087,
        -0.06416638940572739,
        -0.025980789214372635,
        0.03545813634991646,
        -0.056315500289201736,
        0.03426302596926689,
        0.00504915090277791,
        0.06250254064798355,
        0.056708015501499176,
        -0.011012941598892212,
        0.05963868275284767,
        -0.047845445573329926,
        0.031791090965270996,
        -0.07081199437379837,
        -0.04381944239139557,
        -0.03612399846315384,
        -0.008731327950954437,
        0.06907684355974197,
        0.01016431488096714,
        -0.0024001342244446278,
        -0.06555137038230896,
        -0.049412649124860764,
        0.026007313281297684,
        -0.06529449671506882,
        -0.06668759882450104,
        -0.013124021701514721,
        0.009674753993749619,
        -0.06692381948232651,
        0.0563693530857563,
        0.06977133452892303,
        -0.01351888757199049,
        0.06291539967060089,
        0.009601674973964691,
        0.05362421274185181,
        0.024734197184443474,
        0.06844954192638397,
        -0.04355153068900108,
        -0.005094535648822784,
        0.06197093054652214,
        0.05072937160730362,
        -0.07280032336711884,
        -0.046246133744716644,
        -0.04804180562496185,
        -0.01901777647435665,
        -0.03924747183918953,
        0.05889321118593216,
        0.020108124241232872,
        -0.013744429685175419,
        -0.0004925991524942219,
        0.05741230398416519,
        0.06051643192768097,
        0.06185663491487503,
        0.03573189675807953,
        0.01233671884983778,
        0.004342828411608934,
        -0.06234350800514221,
        -0.025504199787974358,
        0.03857586905360222,
        0.032763876020908356,
        -0.03482583910226822,
        0.039114177227020264,
        0.002107633277773857,
        -0.040505774319171906,
        0.0064709982834756374,
        0.06124342978000641,
        -0.0735846757888794,
        0.04909013211727142,
        0.04734481871128082,
        -0.025430265814065933,
        0.04241926968097687,
        0.06875667721033096,
        0.015589984133839607,
        0.043061211705207825,
        0.0499255508184433,
        0.03201446682214737,
        -0.06716640293598175,
        0.07166710495948792,
        -0.06061684712767601,
        -0.04326146841049194,
        -0.009445465169847012,
        0.005730648059397936,
        0.004931584000587463,
        -0.019243469461798668,
        -0.04516099765896797,
        0.04734170064330101,
        0.024657169356942177,
        -0.04924849420785904,
        -0.03610961139202118,
        -0.00551022170111537,
        0.023829448968172073,
        -0.017262278124690056,
        0.012055148370563984,
        0.0015817200765013695,
        -0.07302191853523254,
        0.06732896715402603,
        0.04201376065611839,
        0.018381478264927864,
        -0.05340281501412392,
        0.0003422308654990047,
        -0.05673128366470337,
        0.07091964781284332,
        0.03591633960604668,
        0.038336947560310364,
        -0.009368482045829296,
        0.035107214003801346,
        0.05738826096057892,
        0.003640799317508936,
        0.07179119437932968,
        -0.0074593983590602875,
        0.01381408516317606,
        0.05556837096810341,
        -0.061680156737565994,
        -0.0721786767244339,
        0.06894857436418533,
        -0.062496498227119446,
        -0.039757512509822845,
        0.04604882001876831,
        -0.01784004643559456,
        -0.061760466545820236,
        0.02445690706372261,
        -0.058291494846343994,
        -0.05285483971238136,
        0.031213009729981422,
        -0.03854450583457947,
        0.06904097646474838,
        0.06315237283706665,
        -0.02374286577105522,
        -0.041050102561712265,
        0.07289664447307587,
        -0.005092423874884844,
        0.06653841584920883,
        -0.07552863657474518,
        0.048391301184892654,
        0.037450920790433884,
        -0.0046089887619018555,
        -0.0697215348482132,
        -0.04482533037662506,
        -0.009726416319608688,
        -0.05971527472138405,
        -0.027655137702822685,
        -0.07037743180990219,
        -0.019535839557647705,
        -0.06973717361688614,
        -0.043094586580991745,
        0.0681982934474945,
        -0.053246863186359406,
        -0.05486031249165535,
        0.06063785403966904,
        0.03114730678498745,
        0.07360697537660599,
        -0.03505077585577965,
        -0.027597615495324135,
        0.049724217504262924,
        0.010142318904399872,
        0.019460327923297882,
        -0.03130946308374405,
        0.04694787412881851,
        -0.05501662567257881,
        -0.03468449041247368,
        0.04644474759697914,
        -0.0577700212597847,
        0.0551094152033329,
        -0.040820665657520294,
        -0.03702948987483978,
        0.0516946017742157,
        -0.054447103291749954,
        0.025667162612080574,
        -0.007746384013444185,
        -0.02100374549627304,
        -0.047774046659469604,
        -0.04722173139452934,
        -0.058342866599559784,
        0.0674605667591095,
        -0.02309613861143589,
        -0.05722462013363838,
        0.037636786699295044,
        0.021586673334240913,
        0.004823195748031139,
        0.05773249641060829,
        -0.025158716365695,
        0.056089136749506,
        0.03215032070875168,
        0.05786509811878204,
        0.07075968384742737,
        -0.06749585270881653,
        0.07022357732057571,
        0.055929750204086304,
        -0.053245577961206436,
        0.04534094035625458,
        0.023371871560811996,
        0.05282478407025337,
        -0.051165830343961716,
        0.045220356434583664,
        -0.03894835337996483,
        0.007533538620918989,
        -0.06063146889209747,
        0.013733314350247383,
        0.028658084571361542,
        -0.07241018116474152,
        0.02662705071270466,
        -0.047883495688438416,
        -0.029554517939686775,
        0.02601679600775242,
        -0.0266457237303257,
        -0.048511434346437454,
        0.04787527397274971,
        -0.05539030209183693,
        0.028782082721590996,
        -0.04736277833580971,
        -0.0661642998456955,
        0.03693532198667526,
        0.04710591211915016,
        0.02191825397312641,
        0.035475894808769226,
        -0.000991980079561472,
        -0.04061776027083397,
        -0.025090662762522697,
        -0.020655557513237,
        0.01013247761875391,
        -0.01058707945048809,
        -0.0048971413634717464,
        -0.04774495214223862,
        -0.06606902927160263,
        0.06854227930307388,
        0.017799654975533485,
        -0.007890978828072548,
        0.06789137423038483,
        -0.029294991865754128,
        0.027573497965931892,
        0.05033266544342041,
        0.06094448268413544,
        -0.07095931470394135,
        -0.04337930306792259,
        0.07507246732711792,
        0.02100500836968422,
        -0.012669946067035198,
        0.05316842719912529,
        -0.018759166821837425,
        0.07138016819953918,
        -0.0153079554438591,
        0.01012588944286108,
        -0.053862251341342926,
        -0.024209000170230865,
        -0.06063313037157059,
        0.0645001158118248,
        -0.047228243201971054,
        -0.013283736072480679,
        0.008071504533290863,
        -0.07294559478759766,
        0.06226851046085358,
        0.024297555908560753,
        -0.06032836064696312,
        0.007899224758148193,
        0.030441168695688248,
        -0.04356610029935837,
        0.06659344583749771,
        -0.030196567997336388,
        0.039062052965164185,
        -0.06879591196775436,
        0.009940983727574348,
        -0.0257316455245018,
        0.03175107389688492,
        0.06917284429073334,
        0.03078444115817547,
        0.034141864627599716,
        -0.021307533606886864,
        0.010312546975910664,
        0.01903722807765007,
        0.03372488170862198,
        0.012850533239543438,
        -0.032691001892089844,
        -0.07481686025857925,
        0.05586852878332138,
        0.05628965049982071,
        0.008098771795630455,
        -0.0026986454613506794,
        -0.07330507785081863,
        -0.05005843937397003,
        -0.05546194687485695,
        -0.061380572617053986,
        -0.018667416647076607,
        0.03588329255580902,
        0.036506813019514084,
        -0.03226090222597122,
        0.06692928820848465,
        0.06368407607078552,
        0.025350145995616913,
        -0.011095796711742878,
        -0.06637265533208847,
        -0.013070318847894669,
        0.0633905753493309,
        0.06608264893293381,
        -0.013056652620434761,
        -0.02910398133099079,
        0.055540770292282104,
        0.03530450910329819,
        -0.00397568428888917,
        0.041616041213274,
        0.02855451963841915,
        0.012851035222411156,
        -0.06805599480867386,
        0.04186568409204483,
        -0.018991395831108093,
        0.06177474185824394,
        -0.0532381534576416,
        0.028494440019130707,
        0.05684000626206398,
        0.044405438005924225,
        0.0545206218957901,
        -0.048583950847387314,
        -0.03741319105029106,
        -0.02652003988623619,
        -0.040076278150081635,
        -0.018463945016264915,
        0.04516683146357536,
        0.027054019272327423,
        -0.024760382249951363,
        0.002478003269061446,
        -0.05764557793736458,
        0.046297091990709305,
        -0.005762555170804262,
        -0.0695323646068573,
        -0.009078758768737316,
        0.038019128143787384,
        -0.03552638366818428,
        -0.06687559187412262,
        -0.03924766927957535,
        0.03569098562002182,
        0.013914154842495918,
        0.04230402782559395,
        0.044345106929540634,
        0.04174140840768814,
        0.05161329731345177,
        0.017748212441802025,
        0.017728418111801147,
        0.07370393723249435,
        -0.033829186111688614,
        0.06337485462427139,
        -0.03309788554906845,
        -0.045734748244285583,
        -0.0019204735290259123,
        0.030637254938483238,
        0.0004556537314783782,
        0.03222217038273811,
        -0.05764579400420189,
        0.05131402611732483,
        -0.00694248965010047,
        -0.051092907786369324,
        0.03867344558238983,
        0.0011112653883174062,
        -0.009431085549294949,
        -0.07235092669725418,
        -0.0157820675522089,
        0.05138643458485603,
        0.07233891636133194,
        -0.05088045075535774,
        0.053884465247392654,
        -0.05186476558446884,
        -0.06718331575393677,
        0.04099971801042557,
        0.06381592154502869,
        0.02357739955186844,
        -0.004361073952168226,
        -0.05447447672486305,
        -0.03392200544476509,
        0.03756125643849373,
        0.0148078054189682,
        -0.049389343708753586,
        -0.03145505115389824,
        -0.030802642926573753,
        -0.050673261284828186,
        0.0005136731779202819,
        0.019628025591373444,
        0.02381233684718609,
        0.03836806118488312,
        0.026005549356341362,
        0.046314023435115814,
        -0.06158847734332085,
        0.00007496542093576863,
        -0.06799779087305069,
        0.019551940262317657,
        0.017165981233119965,
        0.014783481135964394,
        0.04534132033586502,
        0.00674106040969491,
        -0.005383538082242012,
        0.038434263318777084,
        0.064906544983387,
        0.06614716351032257,
        -0.04309961572289467,
        -0.047374092042446136,
        -0.010824516415596008,
        0.01065378449857235,
        -0.02492518164217472,
        -0.04140688106417656,
        0.061243731528520584,
        0.04805833846330643,
        -0.054740216583013535,
        -0.0706396996974945,
        0.05328669026494026,
        0.07534951716661453
    ],
    [
        -0.03284178674221039,
        -0.008622107096016407,
        0.008297968655824661,
        0.029874805361032486,
        0.03402009978890419,
        -0.0236970167607069,
        0.048239272087812424,
        -0.05140593647956848,
        0.044881563633680344,
        0.028617380186915398,
        -0.008700140751898289,
        0.06098061800003052,
        -0.0030623050406575203,
        -0.037620022892951965,
        -0.06833218038082123,
        0.05289651080965996,
        -0.04262762889266014,
        0.062256913632154465,
        -0.044185396283864975,
        -0.033854030072689056,
        -0.024347176775336266,
        0.04215738922357559,
        0.03704547882080078,
        -0.05761667713522911,
        0.03963996469974518,
        0.017981817945837975,
        0.055585093796253204,
        -0.03794584050774574,
        -0.003379408037289977,
        0.0013050725683569908,
        0.06345885992050171,
        -0.03434400632977486,
        -0.010925058275461197,
        -0.06425225734710693,
        0.038652203977108,
        -0.004677692893892527,
        -0.06624746322631836,
        -0.04970718175172806,
        -0.014924908988177776,
        -0.01972832717001438,
        0.01802915893495083,
        0.032925352454185486,
        0.042340002954006195,
        -0.06826335936784744,
        0.0423584058880806,
        0.06819327175617218,
        0.03255514055490494,
        0.03384865075349808,
        0.05059671401977539,
        0.03960660099983215,
        0.04851442947983742,
        0.06506720930337906,
        0.00693081459030509,
        0.04107888787984848,
        -0.010531584732234478,
        -0.05475740134716034,
        -0.028758907690644264,
        0.0054113189689815044,
        0.01594417169690132,
        -0.008457023650407791,
        -0.0654437392950058,
        0.029309015721082687,
        -0.043906860053539276,
        0.0665399357676506,
        -0.043429408222436905,
        -0.021492408588528633,
        0.04744330048561096,
        0.0006986180669628084,
        -0.05750681087374687,
        -0.06754879653453827,
        -0.06024380028247833,
        -0.07237675040960312,
        0.06090833619236946,
        -0.027702854946255684,
        -0.003300257958471775,
        -0.008488642983138561,
        0.033306002616882324,
        0.06956376135349274,
        0.004295747261494398,
        -0.029120387509465218,
        -0.001376846106722951,
        0.06922716647386551,
        -0.052342601120471954,
        0.04087641090154648,
        0.025731272995471954,
        0.03532137721776962,
        0.05658000335097313,
        -0.055789120495319366,
        0.02672913670539856,
        0.06971600651741028,
        0.0031268394086509943,
        -0.01491208653897047,
        -0.06457982957363129,
        -0.038945555686950684,
        0.001428218325600028,
        0.03472892567515373,
        -0.008650940842926502,
        -0.06437147408723831,
        -0.040775902569293976,
        0.018347831442952156,
        -0.06762305647134781,
        0.03623715043067932,
        0.07206213474273682,
        0.029033852741122246,
        0.04961419850587845,
        -0.031692713499069214,
        -0.023642918094992638,
        0.025350475683808327,
        -0.028384290635585785,
        0.03438086062669754,
        -0.04578723385930061,
        -0.051712602376937866,
        -0.061264052987098694,
        -0.010636384598910809,
        0.02332194894552231,
        -0.05949193239212036,
        0.04514407366514206,
        -0.01677996851503849,
        0.06291837990283966,
        0.05741781368851662,
        0.005502813961356878,
        0.06471708416938782,
        -0.04661931097507477,
        0.04618236422538757,
        -0.0672692358493805,
        -0.030481191352009773,
        -0.02986850030720234,
        -0.030715005472302437,
        0.06447771191596985,
        0.025992613285779953,
        0.0099313510581851,
        -0.05738890543580055,
        -0.04227949678897858,
        0.018753230571746826,
        -0.06482570618391037,
        -0.06136864051222801,
        0.011464446783065796,
        0.03196755796670914,
        -0.06367068737745285,
        0.06080775707960129,
        0.06486823409795761,
        -0.012190493755042553,
        0.05514455959200859,
        -0.009434509091079235,
        0.04939740151166916,
        0.004756498616188765,
        0.06430415064096451,
        -0.035909440368413925,
        0.006964860018342733,
        0.06093030795454979,
        0.05573394522070885,
        -0.07073908299207687,
        -0.04380611330270767,
        -0.041161324828863144,
        -0.0077289678156375885,
        -0.03968757763504982,
        0.052931755781173706,
        0.016309650614857674,
        -0.022137533873319626,
        0.007004960905760527,
        0.06599144637584686,
        0.04824593663215637,
        0.0540202371776104,
        0.03392031788825989,
        0.002657403005287051,
        0.0152288768440485,
        -0.05741267651319504,
        -0.01885034516453743,
        0.03791743144392967,
        0.03561574965715408,
        -0.03837831690907478,
        0.029324477538466454,
        -0.005881957244127989,
        -0.04566507413983345,
        0.00934477150440216,
        0.06569528579711914,
        -0.07068568468093872,
        0.046859659254550934,
        0.03780229762196541,
        -0.05073447525501251,
        0.0435522086918354,
        0.07067599892616272,
        0.016980664804577827,
        0.04822291061282158,
        0.05382021516561508,
        0.028149159625172615,
        -0.0682307705283165,
        0.06977122277021408,
        -0.06243308633565903,
        -0.04890216886997223,
        -0.017215216532349586,
        0.019506797194480896,
        -0.009841174818575382,
        -0.0031805909238755703,
        -0.057283297181129456,
        0.04057789966464043,
        0.00798728410154581,
        -0.050848446786403656,
        -0.04524925351142883,
        0.0017522161360830069,
        0.01860653981566429,
        -0.03331681713461876,
        -0.006952949799597263,
        -0.020162325352430344,
        -0.06999483704566956,
        0.06800404191017151,
        0.043903470039367676,
        0.018090659752488136,
        -0.054880596697330475,
        0.02276405319571495,
        -0.053900573402643204,
        0.06919541209936142,
        0.022735802456736565,
        0.035892218351364136,
        -0.018638713285326958,
        0.0298941507935524,
        0.041624948382377625,
        -0.015858415514230728,
        0.07005362212657928,
        -0.01310832891613245,
        -0.004761404823511839,
        0.0467228889465332,
        -0.06254520267248154,
        -0.06780999898910522,
        0.0704369992017746,
        -0.06371857970952988,
        -0.035366687923669815,
        0.024980036541819572,
        -0.015697283670306206,
        -0.06158420071005821,
        0.03059808723628521,
        -0.06428417563438416,
        -0.05024117976427078,
        0.037244826555252075,
        -0.04937795549631119,
        0.0614679791033268,
        0.0660528838634491,
        -0.02779429219663143,
        -0.037913110107183456,
        0.06728606671094894,
        -0.004705780651420355,
        0.06610225886106491,
        -0.07253199815750122,
        0.048186834901571274,
        0.04951399564743042,
        -0.005925122182816267,
        -0.06046976149082184,
        -0.02910248562693596,
        0.0002768054837360978,
        -0.060663897544145584,
        -0.03447141498327255,
        -0.06890338659286499,
        -0.024901222437620163,
        -0.06783034652471542,
        -0.03216858208179474,
        0.06489000469446182,
        -0.052186913788318634,
        -0.052490122616291046,
        0.06477834284305573,
        0.03596123680472374,
        0.07036621123552322,
        -0.026622967794537544,
        -0.017538083717226982,
        0.061069831252098083,
        0.008350935764610767,
        0.024072717875242233,
        -0.00616017309948802,
        0.05552402883768082,
        -0.04897692799568176,
        -0.03918638825416565,
        0.035557813942432404,
        -0.06266625970602036,
        0.06326157599687576,
        -0.03922245651483536,
        -0.025310354307293892,
        0.03751262277364731,
        -0.05545260012149811,
        0.022818543016910553,
        -0.020332248881459236,
        -0.016326330602169037,
        -0.06182263046503067,
        -0.0356992743909359,
        -0.06327073276042938,
        0.06248806416988373,
        0.01671726629137993,
        -0.055111516267061234,
        0.03861857205629349,
        0.021239086985588074,
        0.01080397330224514,
        0.053376052528619766,
        -0.039950478821992874,
        0.05445017293095589,
        0.01522794272750616,
        0.05319790914654732,
        0.06992930918931961,
        -0.06385201960802078,
        0.06782810389995575,
        0.06036210432648659,
        -0.045998990535736084,
        0.046314701437950134,
        0.036024659872055054,
        0.05123845860362053,
        -0.04726065322756767,
        0.04553567245602608,
        -0.026146961376070976,
        -0.009321875870227814,
        -0.055080775171518326,
        0.01619667373597622,
        0.029598552733659744,
        -0.0676397830247879,
        0.030218107625842094,
        -0.05835578590631485,
        -0.02923681028187275,
        0.01758374460041523,
        -0.021290509030222893,
        -0.05151055380702019,
        0.04696002975106239,
        -0.05529986694455147,
        0.02910785563290119,
        -0.05703986436128616,
        -0.0576167032122612,
        0.031258609145879745,
        0.04617360234260559,
        0.037491608411073685,
        0.029428359121084213,
        0.001695592189207673,
        -0.038064613938331604,
        -0.005063638091087341,
        -0.011005211621522903,
        0.012724971398711205,
        0.0038044943939894438,
        0.01315281167626381,
        -0.03760088235139847,
        -0.06262326240539551,
        0.06623952835798264,
        0.04015999287366867,
        -0.027834303677082062,
        0.06302698701620102,
        -0.014986406080424786,
        0.01816178485751152,
        0.05140583589673042,
        0.055032406002283096,
        -0.06750188022851944,
        -0.05692492052912712,
        0.0716288611292839,
        0.012069690972566605,
        -0.005002017132937908,
        0.05581781640648842,
        -0.014153610914945602,
        0.06690016388893127,
        -0.006269576493650675,
        0.011115934699773788,
        -0.059866420924663544,
        -0.059466827660799026,
        -0.060746993869543076,
        0.06369110941886902,
        -0.052489761263132095,
        -0.018092306330800056,
        0.01354980655014515,
        -0.07182015478610992,
        0.06055799499154091,
        0.03419318050146103,
        -0.048813894391059875,
        0.015303242020308971,
        0.027208460494875908,
        -0.04791109263896942,
        0.07111546397209167,
        -0.03251174837350845,
        0.05773994326591492,
        -0.06787463277578354,
        -0.00478231580927968,
        -0.039841242134571075,
        0.03986767306923866,
        0.06576371192932129,
        0.045257166028022766,
        0.03659530356526375,
        -0.024014538154006004,
        0.026328561827540398,
        0.030435096472501755,
        0.04544243961572647,
        -0.0023729067761451006,
        -0.03520531952381134,
        -0.07157887518405914,
        0.039196647703647614,
        0.046287789940834045,
        0.0031300371047109365,
        -0.005155135877430439,
        -0.06831897050142288,
        -0.046664778143167496,
        -0.046600062400102615,
        -0.06704467535018921,
        -0.018044892698526382,
        0.03832776099443436,
        0.03733512759208679,
        -0.014235512353479862,
        0.0664539709687233,
        0.06387221813201904,
        0.012423187494277954,
        -0.002391360467299819,
        -0.06799285113811493,
        -0.01817450486123562,
        0.06289611011743546,
        0.06442183256149292,
        -0.016131846234202385,
        -0.03156662359833717,
        0.049340810626745224,
        0.03581691160798073,
        -0.012013460509479046,
        0.04104110971093178,
        0.026036279276013374,
        0.022654132917523384,
        -0.06608225405216217,
        0.04616497829556465,
        0.005058453883975744,
        0.05734826251864433,
        -0.05170249566435814,
        0.024334242567420006,
        0.05447131022810936,
        0.03525419905781746,
        0.05611133575439453,
        -0.04001307487487793,
        -0.017900291830301285,
        -0.016386695206165314,
        -0.04925502836704254,
        0.012384910136461258,
        0.034777116030454636,
        0.022493205964565277,
        -0.006926253437995911,
        -0.0014887151774019003,
        -0.051306240260601044,
        0.05741037055850029,
        -0.007563568651676178,
        -0.06934842467308044,
        -0.026579737663269043,
        0.020659375935792923,
        -0.04569005221128464,
        -0.055296458303928375,
        -0.05170939862728119,
        0.05216982960700989,
        0.018531182780861855,
        0.034670233726501465,
        0.0501268208026886,
        0.04632299020886421,
        0.058625806123018265,
        0.02297026664018631,
        0.014887268655002117,
        0.0708879753947258,
        -0.03490082919597626,
        0.062059078365564346,
        -0.01754755526781082,
        -0.04918103665113449,
        -0.0038903297390788794,
        0.05068323761224747,
        0.006245940458029509,
        0.03194620832800865,
        -0.053873658180236816,
        0.046862296760082245,
        -0.018711043521761894,
        -0.048944130539894104,
        0.05878448858857155,
        0.0035607093013823032,
        -0.011105598881840706,
        -0.06972068548202515,
        -0.011645562946796417,
        0.05855180695652962,
        0.07066942006349564,
        -0.055836502462625504,
        0.053938861936330795,
        -0.054492585361003876,
        -0.06832122057676315,
        0.050669293850660324,
        0.06539569795131683,
        0.030284373089671135,
        0.023722855374217033,
        -0.06434176862239838,
        -0.017740759998559952,
        0.019666606560349464,
        0.02423737198114395,
        -0.05216888710856438,
        -0.03308577463030815,
        -0.0465887188911438,
        -0.05367372930049896,
        -0.013499574735760689,
        0.016643527895212173,
        0.01805684342980385,
        0.046865735203027725,
        0.02873138152062893,
        0.038876499980688095,
        -0.058025944977998734,
        -0.02469312585890293,
        -0.06240925192832947,
        0.00893950741738081,
        0.013789229094982147,
        0.007538991514593363,
        0.05317138507962227,
        0.009775588288903236,
        -0.017963428050279617,
        0.05625500530004501,
        0.06271889060735703,
        0.0653005912899971,
        -0.04763215407729149,
        -0.04276417940855026,
        -0.009265181608498096,
        0.009614169597625732,
        0.0017720410833135247,
        -0.048900630325078964,
        0.06558983027935028,
        0.039788633584976196,
        -0.047238532453775406,
        -0.06910324841737747,
        0.05378570780158043,
        0.07134809345006943
    ],
    [
        -0.0275419931858778,
        0.012394839897751808,
        0.00422773789614439,
        0.028199678286910057,
        0.028199171647429466,
        -0.009027072228491306,
        0.045462459325790405,
        -0.050244223326444626,
        0.0442902110517025,
        0.02026267908513546,
        -0.010909256525337696,
        0.06438363343477249,
        -0.008178872056305408,
        -0.04061250016093254,
        -0.07006131112575531,
        0.05779482051730156,
        -0.03561102971434593,
        0.0623537115752697,
        -0.05048840492963791,
        -0.011447395198047161,
        -0.0358184352517128,
        0.04160401225090027,
        0.029898185282945633,
        -0.053098030388355255,
        0.04293416813015938,
        0.024559717625379562,
        0.04878012090921402,
        -0.02660123072564602,
        0.006965319626033306,
        -0.026738498359918594,
        0.0692356675863266,
        -0.030325429514050484,
        -0.0026429377030581236,
        -0.04923231154680252,
        0.03920075297355652,
        0.00031477113952860236,
        -0.06591064482927322,
        -0.048256948590278625,
        -0.041850343346595764,
        -0.021215979009866714,
        0.01085121650248766,
        0.03852386400103569,
        0.048916611820459366,
        -0.07371184229850769,
        0.040382154285907745,
        0.06842831522226334,
        0.01878904178738594,
        0.029933476820588112,
        0.02875421568751335,
        0.039316169917583466,
        0.0278477780520916,
        0.06600559502840042,
        0.007840863429009914,
        0.03654594346880913,
        0.006086322944611311,
        -0.051374759525060654,
        -0.010552861727774143,
        0.003776327706873417,
        -0.003473573597148061,
        -0.00392793258652091,
        -0.06540893018245697,
        0.02245800569653511,
        -0.046215664595365524,
        0.06645915657281876,
        -0.05330245569348335,
        -0.020147433504462242,
        0.05217254161834717,
        0.014583941549062729,
        -0.06066668778657913,
        -0.06746530532836914,
        -0.05649581551551819,
        -0.07432476431131363,
        0.06476157158613205,
        -0.015679001808166504,
        -0.02129555679857731,
        -0.011788971722126007,
        0.022977789863944054,
        0.07443375140428543,
        0.008903797715902328,
        -0.013545994646847248,
        -0.016711249947547913,
        0.07151078432798386,
        -0.04498729482293129,
        0.012293631210923195,
        0.002369722118601203,
        0.030120374634861946,
        0.05703406408429146,
        -0.058706898242235184,
        0.024616003036499023,
        0.07210909575223923,
        0.05002156272530556,
        0.01073815394192934,
        -0.05235864222049713,
        -0.026061924174427986,
        -0.02064076066017151,
        0.027474569156765938,
        -0.020416706800460815,
        -0.06606671959161758,
        -0.039150454103946686,
        0.013179104775190353,
        -0.07057537883520126,
        0.015301743522286415,
        0.0750873014330864,
        0.02747114934027195,
        0.05167379602789879,
        -0.025192946195602417,
        -0.041836146265268326,
        0.019634513184428215,
        -0.012609199620783329,
        0.021500732749700546,
        -0.051398005336523056,
        -0.049278032034635544,
        -0.06451120227575302,
        -0.01691029965877533,
        0.034387268126010895,
        -0.0551033653318882,
        0.02675095945596695,
        0.001587027683854103,
        0.05974148213863373,
        0.054699935019016266,
        -0.022174812853336334,
        0.05955763906240463,
        -0.04569025710225105,
        0.04302937909960747,
        -0.07166372239589691,
        -0.03971676900982857,
        -0.037185780704021454,
        -0.014118778519332409,
        0.06899308413267136,
        0.019109545275568962,
        -0.0033729695715010166,
        -0.06407409906387329,
        -0.039164233952760696,
        0.01600656658411026,
        -0.06270938366651535,
        -0.06571599096059799,
        -0.010890472680330276,
        0.015232853591442108,
        -0.06656187027692795,
        0.05840331315994263,
        0.06935043632984161,
        -0.014001021161675453,
        0.06293340772390366,
        0.0020323682110756636,
        0.05137070268392563,
        0.0238470658659935,
        0.06556441634893417,
        -0.03852424770593643,
        -0.0042055705562233925,
        0.0627184808254242,
        0.0535828061401844,
        -0.07278066128492355,
        -0.039912495762109756,
        -0.05337323993444443,
        -0.012976689264178276,
        -0.04311884194612503,
        0.05758703127503395,
        0.019999975338578224,
        -0.017132649198174477,
        0.0015128778759390116,
        0.06384900957345963,
        0.06097793951630592,
        0.06065406650304794,
        0.03488172963261604,
        0.012982537969946861,
        0.01008595246821642,
        -0.06480593234300613,
        -0.01965571753680706,
        0.041273921728134155,
        0.03456446900963783,
        -0.026107506826519966,
        0.04711448401212692,
        -0.003071144223213196,
        -0.04098346456885338,
        0.007761952001601458,
        0.06295023113489151,
        -0.07305213809013367,
        0.049054548144340515,
        0.05168583244085312,
        -0.03619451820850372,
        0.04198288172483444,
        0.07001534849405289,
        0.007638956420123577,
        0.043701399117708206,
        0.04683829843997955,
        0.02859475463628769,
        -0.0670393630862236,
        0.07137609273195267,
        -0.062460970133543015,
        -0.041715461760759354,
        -0.006880954839289188,
        -0.00005752376819145866,
        -0.0005895262584090233,
        -0.026071961969137192,
        -0.04827491566538811,
        0.04711416736245155,
        0.025680670514702797,
        -0.05124462768435478,
        -0.031583841890096664,
        -0.0009708146681077778,
        0.03185193985700607,
        -0.030205484479665756,
        0.012790197506546974,
        -0.001467324560508132,
        -0.07315614819526672,
        0.06701712310314178,
        0.04289335012435913,
        0.013012650422751904,
        -0.05102896690368652,
        -0.009331382811069489,
        -0.057555779814720154,
        0.07161438465118408,
        0.028906216844916344,
        0.04404497146606445,
        -0.008386004716157913,
        0.044308923184871674,
        0.05331388860940933,
        0.008573608472943306,
        0.06967349350452423,
        -0.00261215353384614,
        0.008129621855914593,
        0.056936115026474,
        -0.06673986464738846,
        -0.0727163627743721,
        0.06883761286735535,
        -0.061662621796131134,
        -0.03577248752117157,
        0.043263260275125504,
        -0.004262636415660381,
        -0.05897348001599312,
        0.02471204102039337,
        -0.05643803998827934,
        -0.05287836492061615,
        0.029934516176581383,
        -0.03250359371304512,
        0.06994745135307312,
        0.06490565091371536,
        -0.02929207682609558,
        -0.035509005188941956,
        0.07420258224010468,
        0.0017247707583010197,
        0.06511343270540237,
        -0.0748610720038414,
        0.04621371999382973,
        0.034950464963912964,
        -0.0033297494519501925,
        -0.070335753262043,
        -0.0380287766456604,
        -0.013826928101480007,
        -0.06401532143354416,
        -0.01947387121617794,
        -0.07101377844810486,
        -0.02288702130317688,
        -0.0703960582613945,
        -0.03769763559103012,
        0.06925921142101288,
        -0.05969955399632454,
        -0.05497175082564354,
        0.06394661217927933,
        0.0417744442820549,
        0.07393807172775269,
        -0.03664281219244003,
        -0.03522220626473427,
        0.051147013902664185,
        0.0008212107350118458,
        0.011617375537753105,
        -0.020754404366016388,
        0.04166719689965248,
        -0.05480064079165459,
        -0.03101680427789688,
        0.04401585832238197,
        -0.05187597870826721,
        0.05457306653261185,
        -0.0427544005215168,
        -0.029847659170627594,
        0.052166763693094254,
        -0.056722987443208694,
        0.02355365827679634,
        0.005851469933986664,
        -0.01733194664120674,
        -0.0545380562543869,
        -0.05267918482422829,
        -0.05838257446885109,
        0.06821183860301971,
        -0.01832340657711029,
        -0.05548015981912613,
        0.04123052954673767,
        0.014339754357933998,
        0.001132806995883584,
        0.05550814047455788,
        -0.019823769107460976,
        0.05846381187438965,
        0.02268150821328163,
        0.05508538335561752,
        0.06928860396146774,
        -0.0655333399772644,
        0.0700865164399147,
        0.06268665939569473,
        -0.04249083250761032,
        0.044664300978183746,
        0.028450630605220795,
        0.04864855110645294,
        -0.04768164083361626,
        0.04455767199397087,
        -0.033654358237981796,
        0.007976673543453217,
        -0.06219189241528511,
        -0.0026737009175121784,
        0.03196683153510094,
        -0.07281012833118439,
        0.014253154397010803,
        -0.05049698427319527,
        -0.03153957426548004,
        0.01767890900373459,
        -0.0313287116587162,
        -0.0510227270424366,
        0.05008489638566971,
        -0.046599604189395905,
        0.03087672032415867,
        -0.05035942420363426,
        -0.06657763570547104,
        0.037263721227645874,
        0.03796856850385666,
        0.022041311487555504,
        0.04499951750040054,
        0.004426389466971159,
        -0.04080295190215111,
        -0.02219964750111103,
        -0.028946442529559135,
        0.014078396372497082,
        -0.01297596376389265,
        -0.003459205850958824,
        -0.04584275558590889,
        -0.06805254518985748,
        0.06745568662881851,
        0.012885074131190777,
        -0.01837284117937088,
        0.06728862971067429,
        -0.031280215829610825,
        0.026190917938947678,
        0.048485685139894485,
        0.062356941401958466,
        -0.07067898660898209,
        -0.04060354083776474,
        0.07566767185926437,
        0.010733650997281075,
        -0.016662048175930977,
        0.054021839052438736,
        -0.026497704908251762,
        0.0714913010597229,
        -0.02533682808279991,
        0.015912851318717003,
        -0.05922141298651695,
        -0.03434128686785698,
        -0.058807410299777985,
        0.06441709399223328,
        -0.0431499108672142,
        -0.008055429905653,
        0.00445421552285552,
        -0.074131540954113,
        0.06214902549982071,
        0.028104227036237717,
        -0.062221866101026535,
        0.014003348536789417,
        0.03079123981297016,
        -0.04286079481244087,
        0.06671761721372604,
        -0.022044658660888672,
        0.039435792714357376,
        -0.07086008787155151,
        0.005988165736198425,
        -0.03358886390924454,
        0.03371473401784897,
        0.06985321640968323,
        0.027281934395432472,
        0.03939782828092575,
        -0.02507898584008217,
        0.021911729127168655,
        0.0016319906571879983,
        0.03324083983898163,
        0.010487048886716366,
        -0.026148822158575058,
        -0.07522567361593246,
        0.05663825944066048,
        0.052482448518276215,
        0.014042348600924015,
        -0.013427826575934887,
        -0.0733814612030983,
        -0.05189000070095062,
        -0.05621974542737007,
        -0.05925607681274414,
        -0.02185484580695629,
        0.025367839261889458,
        0.034779127687215805,
        -0.0454777292907238,
        0.06733822077512741,
        0.059845272451639175,
        0.03632201626896858,
        0.0013613050105050206,
        -0.06481610238552094,
        -0.016636094078421593,
        0.06479824334383011,
        0.06809932738542557,
        -0.018150152638554573,
        -0.02939354069530964,
        0.05552963539958,
        0.02368033304810524,
        0.0015827618772163987,
        0.04664251208305359,
        0.01897834613919258,
        0.005109078250825405,
        -0.06907542794942856,
        0.04542145878076553,
        -0.02484659105539322,
        0.06388205289840698,
        -0.05021074786782265,
        0.026501094922423363,
        0.053963158279657364,
        0.03963843733072281,
        0.05493695288896561,
        -0.045864079147577286,
        -0.03390568494796753,
        -0.01844540238380432,
        -0.0351053886115551,
        -0.018622737377882004,
        0.043378956615924835,
        0.01728532649576664,
        -0.027888961136341095,
        0.00488577364012599,
        -0.054486148059368134,
        0.051398664712905884,
        -0.014615409076213837,
        -0.0673658698797226,
        -0.006293555721640587,
        0.04269363731145859,
        -0.03586116433143616,
        -0.06299786269664764,
        -0.03666912019252777,
        0.04376724362373352,
        0.004205368924885988,
        0.04794147610664368,
        0.03470940887928009,
        0.04674283787608147,
        0.04990928992629051,
        0.019993856549263,
        0.015702078118920326,
        0.07280170172452927,
        -0.03136775642633438,
        0.06279856711626053,
        -0.02868473529815674,
        -0.0456404872238636,
        0.0027945598121732473,
        0.02761090360581875,
        0.000022658663510810584,
        0.023989534005522728,
        -0.05678722262382507,
        0.04759540781378746,
        0.001795602380298078,
        -0.05135135352611542,
        0.039014339447021484,
        0.003704712726175785,
        -0.011625041253864765,
        -0.07275991141796112,
        -0.005059901159256697,
        0.050329554826021194,
        0.07299576699733734,
        -0.050478529185056686,
        0.05584970861673355,
        -0.053633905947208405,
        -0.06975913792848587,
        0.041240524500608444,
        0.05926220118999481,
        0.023532254621386528,
        0.0028145904652774334,
        -0.05505678430199623,
        -0.03829346224665642,
        0.030463119968771935,
        0.01630350761115551,
        -0.04630155861377716,
        -0.035284124314785004,
        -0.03598189726471901,
        -0.05079711228609085,
        0.007495067548006773,
        0.024979857727885246,
        0.03120509348809719,
        0.03973431512713432,
        0.037161342799663544,
        0.04740225523710251,
        -0.06078950688242912,
        0.001903412165120244,
        -0.07049435377120972,
        0.01094641163945198,
        0.023549940437078476,
        0.020087886601686478,
        0.03902618587017059,
        -0.0013857450103387237,
        -0.010616538114845753,
        0.03537871688604355,
        0.06500295549631119,
        0.06621754169464111,
        -0.04233420267701149,
        -0.048281699419021606,
        -0.00932112243026495,
        0.015455379150807858,
        -0.01818864233791828,
        -0.03811565786600113,
        0.0634654089808464,
        0.042824916541576385,
        -0.051459863781929016,
        -0.07210913300514221,
        0.05515463650226593,
        0.07530220597982407
    ],
    [
        -0.0275419931858778,
        0.012394839897751808,
        0.00422773789614439,
        0.028199678286910057,
        0.028199171647429466,
        -0.009027072228491306,
        0.045462459325790405,
        -0.050244223326444626,
        0.0442902110517025,
        0.02026267908513546,
        -0.010909256525337696,
        0.06438363343477249,
        -0.008178872056305408,
        -0.04061250016093254,
        -0.07006131112575531,
        0.05779482051730156,
        -0.03561102971434593,
        0.0623537115752697,
        -0.05048840492963791,
        -0.011447395198047161,
        -0.0358184352517128,
        0.04160401225090027,
        0.029898185282945633,
        -0.053098030388355255,
        0.04293416813015938,
        0.024559717625379562,
        0.04878012090921402,
        -0.02660123072564602,
        0.006965319626033306,
        -0.026738498359918594,
        0.0692356675863266,
        -0.030325429514050484,
        -0.0026429377030581236,
        -0.04923231154680252,
        0.03920075297355652,
        0.00031477113952860236,
        -0.06591064482927322,
        -0.048256948590278625,
        -0.041850343346595764,
        -0.021215979009866714,
        0.01085121650248766,
        0.03852386400103569,
        0.048916611820459366,
        -0.07371184229850769,
        0.040382154285907745,
        0.06842831522226334,
        0.01878904178738594,
        0.029933476820588112,
        0.02875421568751335,
        0.039316169917583466,
        0.0278477780520916,
        0.06600559502840042,
        0.007840863429009914,
        0.03654594346880913,
        0.006086322944611311,
        -0.051374759525060654,
        -0.010552861727774143,
        0.003776327706873417,
        -0.003473573597148061,
        -0.00392793258652091,
        -0.06540893018245697,
        0.02245800569653511,
        -0.046215664595365524,
        0.06645915657281876,
        -0.05330245569348335,
        -0.020147433504462242,
        0.05217254161834717,
        0.014583941549062729,
        -0.06066668778657913,
        -0.06746530532836914,
        -0.05649581551551819,
        -0.07432476431131363,
        0.06476157158613205,
        -0.015679001808166504,
        -0.02129555679857731,
        -0.011788971722126007,
        0.022977789863944054,
        0.07443375140428543,
        0.008903797715902328,
        -0.013545994646847248,
        -0.016711249947547913,
        0.07151078432798386,
        -0.04498729482293129,
        0.012293631210923195,
        0.002369722118601203,
        0.030120374634861946,
        0.05703406408429146,
        -0.058706898242235184,
        0.024616003036499023,
        0.07210909575223923,
        0.05002156272530556,
        0.01073815394192934,
        -0.05235864222049713,
        -0.026061924174427986,
        -0.02064076066017151,
        0.027474569156765938,
        -0.020416706800460815,
        -0.06606671959161758,
        -0.039150454103946686,
        0.013179104775190353,
        -0.07057537883520126,
        0.015301743522286415,
        0.0750873014330864,
        0.02747114934027195,
        0.05167379602789879,
        -0.025192946195602417,
        -0.041836146265268326,
        0.019634513184428215,
        -0.012609199620783329,
        0.021500732749700546,
        -0.051398005336523056,
        -0.049278032034635544,
        -0.06451120227575302,
        -0.01691029965877533,
        0.034387268126010895,
        -0.0551033653318882,
        0.02675095945596695,
        0.001587027683854103,
        0.05974148213863373,
        0.054699935019016266,
        -0.022174812853336334,
        0.05955763906240463,
        -0.04569025710225105,
        0.04302937909960747,
        -0.07166372239589691,
        -0.03971676900982857,
        -0.037185780704021454,
        -0.014118778519332409,
        0.06899308413267136,
        0.019109545275568962,
        -0.0033729695715010166,
        -0.06407409906387329,
        -0.039164233952760696,
        0.01600656658411026,
        -0.06270938366651535,
        -0.06571599096059799,
        -0.010890472680330276,
        0.015232853591442108,
        -0.06656187027692795,
        0.05840331315994263,
        0.06935043632984161,
        -0.014001021161675453,
        0.06293340772390366,
        0.0020323682110756636,
        0.05137070268392563,
        0.0238470658659935,
        0.06556441634893417,
        -0.03852424770593643,
        -0.0042055705562233925,
        0.0627184808254242,
        0.0535828061401844,
        -0.07278066128492355,
        -0.039912495762109756,
        -0.05337323993444443,
        -0.012976689264178276,
        -0.04311884194612503,
        0.05758703127503395,
        0.019999975338578224,
        -0.017132649198174477,
        0.0015128778759390116,
        0.06384900957345963,
        0.06097793951630592,
        0.06065406650304794,
        0.03488172963261604,
        0.012982537969946861,
        0.01008595246821642,
        -0.06480593234300613,
        -0.01965571753680706,
        0.041273921728134155,
        0.03456446900963783,
        -0.026107506826519966,
        0.04711448401212692,
        -0.003071144223213196,
        -0.04098346456885338,
        0.007761952001601458,
        0.06295023113489151,
        -0.07305213809013367,
        0.049054548144340515,
        0.05168583244085312,
        -0.03619451820850372,
        0.04198288172483444,
        0.07001534849405289,
        0.007638956420123577,
        0.043701399117708206,
        0.04683829843997955,
        0.02859475463628769,
        -0.0670393630862236,
        0.07137609273195267,
        -0.062460970133543015,
        -0.041715461760759354,
        -0.006880954839289188,
        -0.00005752376819145866,
        -0.0005895262584090233,
        -0.026071961969137192,
        -0.04827491566538811,
        0.04711416736245155,
        0.025680670514702797,
        -0.05124462768435478,
        -0.031583841890096664,
        -0.0009708146681077778,
        0.03185193985700607,
        -0.030205484479665756,
        0.012790197506546974,
        -0.001467324560508132,
        -0.07315614819526672,
        0.06701712310314178,
        0.04289335012435913,
        0.013012650422751904,
        -0.05102896690368652,
        -0.009331382811069489,
        -0.057555779814720154,
        0.07161438465118408,
        0.028906216844916344,
        0.04404497146606445,
        -0.008386004716157913,
        0.044308923184871674,
        0.05331388860940933,
        0.008573608472943306,
        0.06967349350452423,
        -0.00261215353384614,
        0.008129621855914593,
        0.056936115026474,
        -0.06673986464738846,
        -0.0727163627743721,
        0.06883761286735535,
        -0.061662621796131134,
        -0.03577248752117157,
        0.043263260275125504,
        -0.004262636415660381,
        -0.05897348001599312,
        0.02471204102039337,
        -0.05643803998827934,
        -0.05287836492061615,
        0.029934516176581383,
        -0.03250359371304512,
        0.06994745135307312,
        0.06490565091371536,
        -0.02929207682609558,
        -0.035509005188941956,
        0.07420258224010468,
        0.0017247707583010197,
        0.06511343270540237,
        -0.0748610720038414,
        0.04621371999382973,
        0.034950464963912964,
        -0.0033297494519501925,
        -0.070335753262043,
        -0.0380287766456604,
        -0.013826928101480007,
        -0.06401532143354416,
        -0.01947387121617794,
        -0.07101377844810486,
        -0.02288702130317688,
        -0.0703960582613945,
        -0.03769763559103012,
        0.06925921142101288,
        -0.05969955399632454,
        -0.05497175082564354,
        0.06394661217927933,
        0.0417744442820549,
        0.07393807172775269,
        -0.03664281219244003,
        -0.03522220626473427,
        0.051147013902664185,
        0.0008212107350118458,
        0.011617375537753105,
        -0.020754404366016388,
        0.04166719689965248,
        -0.05480064079165459,
        -0.03101680427789688,
        0.04401585832238197,
        -0.05187597870826721,
        0.05457306653261185,
        -0.0427544005215168,
        -0.029847659170627594,
        0.052166763693094254,
        -0.056722987443208694,
        0.02355365827679634,
        0.005851469933986664,
        -0.01733194664120674,
        -0.0545380562543869,
        -0.05267918482422829,
        -0.05838257446885109,
        0.06821183860301971,
        -0.01832340657711029,
        -0.05548015981912613,
        0.04123052954673767,
        0.014339754357933998,
        0.001132806995883584,
        0.05550814047455788,
        -0.019823769107460976,
        0.05846381187438965,
        0.02268150821328163,
        0.05508538335561752,
        0.06928860396146774,
        -0.0655333399772644,
        0.0700865164399147,
        0.06268665939569473,
        -0.04249083250761032,
        0.044664300978183746,
        0.028450630605220795,
        0.04864855110645294,
        -0.04768164083361626,
        0.04455767199397087,
        -0.033654358237981796,
        0.007976673543453217,
        -0.06219189241528511,
        -0.0026737009175121784,
        0.03196683153510094,
        -0.07281012833118439,
        0.014253154397010803,
        -0.05049698427319527,
        -0.03153957426548004,
        0.01767890900373459,
        -0.0313287116587162,
        -0.0510227270424366,
        0.05008489638566971,
        -0.046599604189395905,
        0.03087672032415867,
        -0.05035942420363426,
        -0.06657763570547104,
        0.037263721227645874,
        0.03796856850385666,
        0.022041311487555504,
        0.04499951750040054,
        0.004426389466971159,
        -0.04080295190215111,
        -0.02219964750111103,
        -0.028946442529559135,
        0.014078396372497082,
        -0.01297596376389265,
        -0.003459205850958824,
        -0.04584275558590889,
        -0.06805254518985748,
        0.06745568662881851,
        0.012885074131190777,
        -0.01837284117937088,
        0.06728862971067429,
        -0.031280215829610825,
        0.026190917938947678,
        0.048485685139894485,
        0.062356941401958466,
        -0.07067898660898209,
        -0.04060354083776474,
        0.07566767185926437,
        0.010733650997281075,
        -0.016662048175930977,
        0.054021839052438736,
        -0.026497704908251762,
        0.0714913010597229,
        -0.02533682808279991,
        0.015912851318717003,
        -0.05922141298651695,
        -0.03434128686785698,
        -0.058807410299777985,
        0.06441709399223328,
        -0.0431499108672142,
        -0.008055429905653,
        0.00445421552285552,
        -0.074131540954113,
        0.06214902549982071,
        0.028104227036237717,
        -0.062221866101026535,
        0.014003348536789417,
        0.03079123981297016,
        -0.04286079481244087,
        0.06671761721372604,
        -0.022044658660888672,
        0.039435792714357376,
        -0.07086008787155151,
        0.005988165736198425,
        -0.03358886390924454,
        0.03371473401784897,
        0.06985321640968323,
        0.027281934395432472,
        0.03939782828092575,
        -0.02507898584008217,
        0.021911729127168655,
        0.0016319906571879983,
        0.03324083983898163,
        0.010487048886716366,
        -0.026148822158575058,
        -0.07522567361593246,
        0.05663825944066048,
        0.052482448518276215,
        0.014042348600924015,
        -0.013427826575934887,
        -0.0733814612030983,
        -0.05189000070095062,
        -0.05621974542737007,
        -0.05925607681274414,
        -0.02185484580695629,
        0.025367839261889458,
        0.034779127687215805,
        -0.0454777292907238,
        0.06733822077512741,
        0.059845272451639175,
        0.03632201626896858,
        0.0013613050105050206,
        -0.06481610238552094,
        -0.016636094078421593,
        0.06479824334383011,
        0.06809932738542557,
        -0.018150152638554573,
        -0.02939354069530964,
        0.05552963539958,
        0.02368033304810524,
        0.0015827618772163987,
        0.04664251208305359,
        0.01897834613919258,
        0.005109078250825405,
        -0.06907542794942856,
        0.04542145878076553,
        -0.02484659105539322,
        0.06388205289840698,
        -0.05021074786782265,
        0.026501094922423363,
        0.053963158279657364,
        0.03963843733072281,
        0.05493695288896561,
        -0.045864079147577286,
        -0.03390568494796753,
        -0.01844540238380432,
        -0.0351053886115551,
        -0.018622737377882004,
        0.043378956615924835,
        0.01728532649576664,
        -0.027888961136341095,
        0.00488577364012599,
        -0.054486148059368134,
        0.051398664712905884,
        -0.014615409076213837,
        -0.0673658698797226,
        -0.006293555721640587,
        0.04269363731145859,
        -0.03586116433143616,
        -0.06299786269664764,
        -0.03666912019252777,
        0.04376724362373352,
        0.004205368924885988,
        0.04794147610664368,
        0.03470940887928009,
        0.04674283787608147,
        0.04990928992629051,
        0.019993856549263,
        0.015702078118920326,
        0.07280170172452927,
        -0.03136775642633438,
        0.06279856711626053,
        -0.02868473529815674,
        -0.0456404872238636,
        0.0027945598121732473,
        0.02761090360581875,
        0.000022658663510810584,
        0.023989534005522728,
        -0.05678722262382507,
        0.04759540781378746,
        0.001795602380298078,
        -0.05135135352611542,
        0.039014339447021484,
        0.003704712726175785,
        -0.011625041253864765,
        -0.07275991141796112,
        -0.005059901159256697,
        0.050329554826021194,
        0.07299576699733734,
        -0.050478529185056686,
        0.05584970861673355,
        -0.053633905947208405,
        -0.06975913792848587,
        0.041240524500608444,
        0.05926220118999481,
        0.023532254621386528,
        0.0028145904652774334,
        -0.05505678430199623,
        -0.03829346224665642,
        0.030463119968771935,
        0.01630350761115551,
        -0.04630155861377716,
        -0.035284124314785004,
        -0.03598189726471901,
        -0.05079711228609085,
        0.007495067548006773,
        0.024979857727885246,
        0.03120509348809719,
        0.03973431512713432,
        0.037161342799663544,
        0.04740225523710251,
        -0.06078950688242912,
        0.001903412165120244,
        -0.07049435377120972,
        0.01094641163945198,
        0.023549940437078476,
        0.020087886601686478,
        0.03902618587017059,
        -0.0013857450103387237,
        -0.010616538114845753,
        0.03537871688604355,
        0.06500295549631119,
        0.06621754169464111,
        -0.04233420267701149,
        -0.048281699419021606,
        -0.00932112243026495,
        0.015455379150807858,
        -0.01818864233791828,
        -0.03811565786600113,
        0.0634654089808464,
        0.042824916541576385,
        -0.051459863781929016,
        -0.07210913300514221,
        0.05515463650226593,
        0.07530220597982407
    ],
    [
        -0.04036854952573776,
        -0.0017475582426413894,
        -0.0000030214869184419513,
        0.03513064235448837,
        0.02013598196208477,
        -0.028942078351974487,
        0.047836530953645706,
        -0.04983602091670036,
        0.04694818705320358,
        0.03656992316246033,
        -0.003993126563727856,
        0.06371167302131653,
        0.005084134638309479,
        -0.02979474700987339,
        -0.06830193102359772,
        0.05340981483459473,
        -0.043715085834264755,
        0.062371350824832916,
        -0.04979044198989868,
        -0.03992728516459465,
        -0.022191785275936127,
        0.03198440000414848,
        0.03990107774734497,
        -0.05405016615986824,
        0.039601173251867294,
        0.0338435135781765,
        0.05993836373090744,
        -0.031169293448328972,
        0.0015759882517158985,
        -0.009513738565146923,
        0.06689891219139099,
        -0.031693097203969955,
        -0.014542737044394016,
        -0.06271129101514816,
        0.040715694427490234,
        0.0027805662248283625,
        -0.06606810539960861,
        -0.05199562758207321,
        -0.049463216215372086,
        -0.012550708837807178,
        0.025407811626791954,
        0.04019680991768837,
        0.04166658967733383,
        -0.06925187259912491,
        0.04597881808876991,
        0.06699800491333008,
        0.03224656730890274,
        0.03601619228720665,
        0.05682797729969025,
        0.030122263357043266,
        0.047304827719926834,
        0.0641980767250061,
        0.01859460584819317,
        0.04313753917813301,
        0.0032706386409699917,
        -0.05352431535720825,
        -0.028302639722824097,
        0.008935162797570229,
        0.007761105895042419,
        -0.0030795251950621605,
        -0.06596209853887558,
        0.02067248895764351,
        -0.04045119509100914,
        0.0685298964381218,
        -0.04999025911092758,
        -0.020157376304268837,
        0.049112286418676376,
        0.008391390554606915,
        -0.056184154003858566,
        -0.06830911338329315,
        -0.05825658142566681,
        -0.07206078618764877,
        0.05989083647727966,
        -0.0324474535882473,
        0.00589746655896306,
        -0.0012625346425920725,
        0.03773653507232666,
        0.07009260356426239,
        0.01965264417231083,
        -0.03264551982283592,
        -0.007975549437105656,
        0.06929543614387512,
        -0.05436977371573448,
        0.03156362846493721,
        -0.0030318780336529016,
        0.031125294044613838,
        0.05701088532805443,
        -0.05656227841973305,
        0.04137951508164406,
        0.0691738873720169,
        -0.00027740938821807504,
        -0.009905034676194191,
        -0.06320159882307053,
        -0.03568437695503235,
        0.000004785781584359938,
        0.020603658631443977,
        -0.013949374668300152,
        -0.06387077271938324,
        -0.04583803564310074,
        0.013532189652323723,
        -0.0654975175857544,
        0.03465793654322624,
        0.07240209728479385,
        0.04110671207308769,
        0.04724283888936043,
        -0.03492497280240059,
        -0.03088674508035183,
        0.027427198365330696,
        -0.013881404884159565,
        0.03890126198530197,
        -0.04718862101435661,
        -0.056310102343559265,
        -0.06132235750555992,
        0.007413187064230442,
        0.023304356262087822,
        -0.055136825889348984,
        0.03459515422582626,
        -0.012093262746930122,
        0.05449065938591957,
        0.05973152816295624,
        -0.009287389926612377,
        0.06333530694246292,
        -0.044477012008428574,
        0.052059587091207504,
        -0.06770951300859451,
        -0.020190447568893433,
        -0.03497932478785515,
        -0.041476860642433167,
        0.06507191807031631,
        0.03668810427188873,
        0.01064386684447527,
        -0.05211227014660835,
        -0.03545491024851799,
        0.010837331414222717,
        -0.0625532791018486,
        -0.06063738837838173,
        0.007734233513474464,
        0.029354434460401535,
        -0.06178626790642738,
        0.061299752444028854,
        0.06372605264186859,
        -0.01622004248201847,
        0.055011242628097534,
        -0.007323381491005421,
        0.05127853527665138,
        0.008692623116075993,
        0.05893673747777939,
        -0.03188681602478027,
        0.0013806959614157677,
        0.06069086864590645,
        0.05436137318611145,
        -0.06989329308271408,
        -0.029054155573248863,
        -0.04643543064594269,
        -0.01121815200895071,
        -0.04379316791892052,
        0.057360436767339706,
        0.017584217712283134,
        -0.024159396067261696,
        0.01879192516207695,
        0.06753410398960114,
        0.044159382581710815,
        0.05323827266693115,
        0.02684832364320755,
        -0.0014146442990750074,
        0.02707812935113907,
        -0.061689093708992004,
        -0.0178239643573761,
        0.04115646332502365,
        0.03626661002635956,
        -0.030980462208390236,
        0.03894030302762985,
        -0.0029590055346488953,
        -0.04206283763051033,
        0.010301490314304829,
        0.06769078969955444,
        -0.07127375155687332,
        0.042123135179281235,
        0.044844623655080795,
        -0.05386250838637352,
        0.04244597628712654,
        0.07092240452766418,
        0.006058848462998867,
        0.05002594366669655,
        0.0485798604786396,
        0.029225599020719528,
        -0.06867480278015137,
        0.06903985887765884,
        -0.0616912916302681,
        -0.04510447010397911,
        -0.0017705629579722881,
        0.012347979471087456,
        -0.01999467797577381,
        -0.027767011895775795,
        -0.060849811881780624,
        0.0361182875931263,
        0.002577932085841894,
        -0.05364169180393219,
        -0.036407094448804855,
        0.0101237827911973,
        0.02963833697140217,
        -0.04500317573547363,
        -0.0028436167631298304,
        -0.016700996086001396,
        -0.06878049671649933,
        0.0669180229306221,
        0.045647505670785904,
        0.01605020835995674,
        -0.05473034083843231,
        -0.00504906103014946,
        -0.05588332936167717,
        0.06923148781061172,
        0.005031869746744633,
        0.03766369819641113,
        -0.019809143617749214,
        0.03847669064998627,
        0.04938722401857376,
        -0.006268509663641453,
        0.0677596777677536,
        -0.0011655698763206601,
        -0.006089115981012583,
        0.045037172734737396,
        -0.06651955097913742,
        -0.06937529891729355,
        0.06957210600376129,
        -0.061239711940288544,
        -0.03122657723724842,
        0.02815721184015274,
        0.002483961870893836,
        -0.0588369145989418,
        0.031748153269290924,
        -0.06323469430208206,
        -0.05058397725224495,
        0.035870473831892014,
        -0.04406742379069328,
        0.06072915717959404,
        0.06614836305379868,
        -0.0360582210123539,
        -0.030853865668177605,
        0.06935923546552658,
        -0.008698720484972,
        0.06600337475538254,
        -0.0722430869936943,
        0.04227280989289284,
        0.046849045902490616,
        -0.0026409756392240524,
        -0.06291752308607101,
        -0.01586727797985077,
        -0.00294173089787364,
        -0.06448937952518463,
        -0.023856261745095253,
        -0.06960531324148178,
        -0.02572866901755333,
        -0.06815747171640396,
        -0.03352603316307068,
        0.06614407151937485,
        -0.05351957306265831,
        -0.05164450407028198,
        0.06611696630716324,
        0.04665318503975868,
        0.07015447318553925,
        -0.03169684857130051,
        -0.024763887748122215,
        0.061141133308410645,
        -0.007060530129820108,
        0.013193076476454735,
        0.01097026001662016,
        0.04852961003780365,
        -0.051340095698833466,
        -0.035095762461423874,
        0.03844556584954262,
        -0.0610225647687912,
        0.06210074573755264,
        -0.04150981828570366,
        -0.01240846049040556,
        0.046305954456329346,
        -0.05956902354955673,
        0.016806459054350853,
        0.006593657657504082,
        -0.013881201855838299,
        -0.06324037909507751,
        -0.040306173264980316,
        -0.06252031773328781,
        0.06553930789232254,
        0.008202502503991127,
        -0.05294414609670639,
        0.04596702754497528,
        -0.0023368813563138247,
        0.016182906925678253,
        0.05092277750372887,
        -0.04379357025027275,
        0.05534305050969124,
        0.004737646318972111,
        0.05062123015522957,
        0.06923104077577591,
        -0.06164851784706116,
        0.06794659048318863,
        0.0662507489323616,
        -0.034335292875766754,
        0.04372316226363182,
        0.043193213641643524,
        0.040890973061323166,
        -0.04209131747484207,
        0.041417934000492096,
        -0.0225231871008873,
        -0.004144398495554924,
        -0.057100776582956314,
        0.004785018507391214,
        0.034779056906700134,
        -0.0679040476679802,
        0.01799776777625084,
        -0.059364769607782364,
        -0.032627951353788376,
        0.013306187465786934,
        -0.02812655083835125,
        -0.053569722920656204,
        0.045881323516368866,
        -0.043878182768821716,
        0.03129611536860466,
        -0.05909156799316406,
        -0.05844014510512352,
        0.027678905054926872,
        0.03821122646331787,
        0.028106581419706345,
        0.0411335714161396,
        0.00516807846724987,
        -0.03498708829283714,
        -0.00003070475941058248,
        -0.014586592093110085,
        0.014519324526190758,
        -0.007597878575325012,
        0.009800535626709461,
        -0.03163633123040199,
        -0.063998743891716,
        0.0660765990614891,
        0.03201670199632645,
        -0.04083289951086044,
        0.060199346393346786,
        -0.014746210537850857,
        0.024764975532889366,
        0.04919437691569328,
        0.05825367197394371,
        -0.06672391295433044,
        -0.056627530604600906,
        0.07261932641267776,
        0.002828499535098672,
        -0.012435071170330048,
        0.05616903677582741,
        -0.02913227304816246,
        0.0662418007850647,
        -0.02182888798415661,
        0.020273253321647644,
        -0.06386860460042953,
        -0.06158600375056267,
        -0.059211257845163345,
        0.06433160603046417,
        -0.04927867278456688,
        -0.016961509361863136,
        0.013530682772397995,
        -0.0720357596874237,
        0.05869133025407791,
        0.03507886081933975,
        -0.05105861276388168,
        0.01950634829699993,
        0.022309070453047752,
        -0.04728424921631813,
        0.07143387943506241,
        -0.010411829687654972,
        0.057726748287677765,
        -0.07037199288606644,
        -0.013607731088995934,
        -0.04354016110301018,
        0.04257810860872269,
        0.06563056260347366,
        0.046898797154426575,
        0.03917238488793373,
        -0.03565872088074684,
        0.03569503501057625,
        0.007143503054976463,
        0.042159661650657654,
        0.003978678025305271,
        -0.01729915663599968,
        -0.07210064679384232,
        0.04195518419146538,
        0.04225636273622513,
        0.002733726752921939,
        -0.009532522410154343,
        -0.06789834052324295,
        -0.0494069941341877,
        -0.050233833491802216,
        -0.0670522153377533,
        -0.02200193889439106,
        0.021979844197630882,
        0.039387159049510956,
        -0.03339641913771629,
        0.06703796982765198,
        0.05945349112153053,
        0.015494991093873978,
        0.01492200419306755,
        -0.06704960763454437,
        -0.01911851018667221,
        0.06559981405735016,
        0.06813883781433105,
        -0.028346853330731392,
        -0.035908110439777374,
        0.05143939331173897,
        0.022507311776280403,
        -0.0038403798826038837,
        0.044809285551309586,
        0.009791599586606026,
        0.013039441779255867,
        -0.0640425980091095,
        0.04623667150735855,
        -0.0020485699642449617,
        0.06039326265454292,
        -0.04462390020489693,
        0.023105217143893242,
        0.05249008908867836,
        0.030019177123904228,
        0.05046316236257553,
        -0.02696775086224079,
        -0.012971402145922184,
        -0.01500727143138647,
        -0.0446675568819046,
        0.019466549158096313,
        0.024353062734007835,
        0.017326191067695618,
        -0.002737604547291994,
        -0.0005056291702203453,
        -0.05427321419119835,
        0.05932611972093582,
        -0.019375288859009743,
        -0.0683707743883133,
        -0.01620693877339363,
        0.02804632857441902,
        -0.044418372213840485,
        -0.043367650359869,
        -0.05049920827150345,
        0.06050996109843254,
        0.013009089976549149,
        0.048876747488975525,
        0.04436865076422691,
        0.04331221431493759,
        0.05593595281243324,
        0.029978159815073013,
        0.01758979819715023,
        0.07077618688344955,
        -0.03734312206506729,
        0.06203065067529678,
        -0.01158890500664711,
        -0.050557587295770645,
        0.00774223729968071,
        0.04871226102113724,
        0.009940925985574722,
        0.02315320074558258,
        -0.05491631478071213,
        0.04550228267908096,
        0.00444458145648241,
        -0.055053919553756714,
        0.060051411390304565,
        0.005523629020899534,
        -0.012040117755532265,
        -0.07071791589260101,
        0.004107762593775988,
        0.05829562619328499,
        0.07113222032785416,
        -0.056418951600790024,
        0.05442329868674278,
        -0.05453448370099068,
        -0.07023993134498596,
        0.04550066962838173,
        0.06424294412136078,
        0.031523726880550385,
        0.026300063356757164,
        -0.06366807222366333,
        -0.015296386554837227,
        0.010567731224000454,
        0.021545805037021637,
        -0.051083169877529144,
        -0.036223866045475006,
        -0.04652610793709755,
        -0.05462295934557915,
        -0.0024699128698557615,
        0.018480945378541946,
        0.029480496421456337,
        0.04594668373465538,
        0.03726161643862724,
        0.044598616659641266,
        -0.053752750158309937,
        -0.015319937840104103,
        -0.06731079518795013,
        -0.002062371466308832,
        0.027478476986289024,
        0.015317021869122982,
        0.04605109989643097,
        -0.003117896616458893,
        -0.021775923669338226,
        0.05606645718216896,
        0.06133906543254852,
        0.06832204014062881,
        -0.04840384051203728,
        -0.04271186888217926,
        -0.015969673171639442,
        0.01094831433147192,
        0.011012133210897446,
        -0.046473562717437744,
        0.06807367503643036,
        0.03507537394762039,
        -0.038200244307518005,
        -0.06970524042844772,
        0.053352318704128265,
        0.07141217589378357
    ],
    [
        -0.030424844473600388,
        0.01628333330154419,
        0.0015225278912112117,
        0.024305803701281548,
        0.023561501875519753,
        -0.012308184988796711,
        0.04178139939904213,
        -0.05042671784758568,
        0.04517456144094467,
        0.014489740133285522,
        -0.011766279116272926,
        0.06161624565720558,
        -0.0029619354754686356,
        -0.04452093690633774,
        -0.06947565823793411,
        0.06167317554354668,
        -0.03430556133389473,
        0.06238137185573578,
        -0.0508737675845623,
        -0.019013602286577225,
        -0.03352537006139755,
        0.0427846722304821,
        0.03188781812787056,
        -0.05363938584923744,
        0.028817635029554367,
        0.022937776520848274,
        0.052949097007513046,
        -0.03267274424433708,
        -0.00009927951032295823,
        -0.021993393078446388,
        0.06861584633588791,
        -0.03292005881667137,
        0.00029111988260410726,
        -0.044294074177742004,
        0.037392839789390564,
        0.003612946020439267,
        -0.06690245866775513,
        -0.047339145094156265,
        -0.05527704581618309,
        -0.019684288650751114,
        0.015795418992638588,
        0.03434717282652855,
        0.050882741808891296,
        -0.07367030531167984,
        0.04927099496126175,
        0.06889093667268753,
        0.03559878468513489,
        0.03774639219045639,
        0.016848303377628326,
        0.04013804718852043,
        0.037417296320199966,
        0.06761042028665543,
        0.013005146756768227,
        0.04492628946900368,
        0.008798123337328434,
        -0.04944800212979317,
        -0.0046965591609478,
        0.006135602481663227,
        -0.006539481692016125,
        -0.004663272760808468,
        -0.06665874272584915,
        0.019659914076328278,
        -0.04750651493668556,
        0.06657615303993225,
        -0.05545169115066528,
        -0.01302406843751669,
        0.05207309499382973,
        0.009975716471672058,
        -0.05787259712815285,
        -0.0686502531170845,
        -0.060426853597164154,
        -0.07257414609193802,
        0.062057238072156906,
        -0.009768732823431492,
        -0.02125678025186062,
        -0.004376255441457033,
        0.024046432226896286,
        0.07464524358510971,
        0.0043413457460701466,
        -0.020775848999619484,
        -0.020314563065767288,
        0.07245176285505295,
        -0.04779108613729477,
        0.009509713388979435,
        0.00021549583470914513,
        0.033264659345149994,
        0.05843428522348404,
        -0.05510459467768669,
        0.03937224671244621,
        0.07161712646484375,
        0.049592338502407074,
        0.0008575750980526209,
        -0.05798353999853134,
        -0.031210239976644516,
        -0.028125377371907234,
        0.028162602335214615,
        -0.01666337065398693,
        -0.06647306680679321,
        -0.03611705079674721,
        0.020453346893191338,
        -0.07167619466781616,
        0.014753025025129318,
        0.07552924752235413,
        0.03645661473274231,
        0.0515766479074955,
        -0.03449723497033119,
        -0.03824225068092346,
        0.021496782079339027,
        -0.0038577273953706026,
        0.023519238457083702,
        -0.047362953424453735,
        -0.04981270059943199,
        -0.06337428838014603,
        -0.012996368110179901,
        0.04609452933073044,
        -0.05685961991548538,
        0.02970004267990589,
        -0.0027651034761220217,
        0.059791531413793564,
        0.056966885924339294,
        -0.015580653212964535,
        0.058768998831510544,
        -0.04035556688904762,
        0.03597300872206688,
        -0.0729454904794693,
        -0.042601846158504486,
        -0.035394277423620224,
        -0.020608769729733467,
        0.06891720741987228,
        0.02887585572898388,
        0.012215699069201946,
        -0.0659758672118187,
        -0.044718872755765915,
        0.016573159024119377,
        -0.06433112174272537,
        -0.06451314687728882,
        -0.00879761204123497,
        0.014618237502872944,
        -0.06850796937942505,
        0.05853935703635216,
        0.06822259724140167,
        -0.006700948346406221,
        0.06342881917953491,
        0.010472966358065605,
        0.04986637085676193,
        0.0219087116420269,
        0.06265703588724136,
        -0.04640090838074684,
        -0.005434818100184202,
        0.05945185199379921,
        0.04644943028688431,
        -0.07304979115724564,
        -0.021372215822339058,
        -0.05289369821548462,
        -0.019469691440463066,
        -0.03264368698000908,
        0.05715222656726837,
        0.022888999432325363,
        -0.013584570959210396,
        -0.005733529105782509,
        0.06419126689434052,
        0.05721600726246834,
        0.06260392814874649,
        0.03286752477288246,
        0.013533111661672592,
        0.01229949202388525,
        -0.06421025842428207,
        -0.02591162733733654,
        0.0391741544008255,
        0.030802356079220772,
        -0.021121082827448845,
        0.04074128717184067,
        -0.0007478644838556647,
        -0.04244858771562576,
        0.018938085064291954,
        0.06180010363459587,
        -0.07231695204973221,
        0.0508267842233181,
        0.0530371330678463,
        -0.03775326535105705,
        0.03548869863152504,
        0.07178881764411926,
        0.012008223682641983,
        0.03794126957654953,
        0.04673240706324577,
        0.0235492754727602,
        -0.06511612981557846,
        0.07271838188171387,
        -0.06230475381016731,
        -0.022751446813344955,
        -0.0006350735202431679,
        0.005996685940772295,
        0.006375536788254976,
        -0.02573203295469284,
        -0.05775262042880058,
        0.044029686599969864,
        0.01591522991657257,
        -0.05491488426923752,
        -0.03290342167019844,
        0.00037398881977424026,
        0.02329234592616558,
        -0.02165859192609787,
        -0.005660363007336855,
        -0.015047225169837475,
        -0.0730796530842781,
        0.06870601326227188,
        0.03994250297546387,
        0.013211854733526707,
        -0.04790188744664192,
        -0.004955136217176914,
        -0.0590471513569355,
        0.07235241681337357,
        0.02953357622027397,
        0.04603924974799156,
        -0.011917988769710064,
        0.03650582209229469,
        0.05231902748346329,
        -0.0036060858983546495,
        0.0713408961892128,
        -0.017278792336583138,
        0.010007442906498909,
        0.057109806686639786,
        -0.06214578449726105,
        -0.07253878563642502,
        0.06578410416841507,
        -0.05010058730840683,
        -0.036353904753923416,
        0.040887750685214996,
        -0.009536013007164001,
        -0.06103481724858284,
        0.00846630334854126,
        -0.04277784377336502,
        -0.05707226321101189,
        0.034365780651569366,
        -0.028455154970288277,
        0.06840629130601883,
        0.06635366380214691,
        -0.0299075897783041,
        -0.03908317908644676,
        0.07425825297832489,
        0.006496121175587177,
        0.066730797290802,
        -0.075046606361866,
        0.04202011972665787,
        0.03857136890292168,
        -0.0075346073135733604,
        -0.07217297703027725,
        -0.04798051714897156,
        -0.0061092134565114975,
        -0.0622672438621521,
        -0.03041478618979454,
        -0.0704834833741188,
        -0.026541784405708313,
        -0.06880201399326324,
        -0.037761859595775604,
        0.06917554140090942,
        -0.05770605430006981,
        -0.04229455068707466,
        0.06733007729053497,
        0.030804282054305077,
        0.0735917016863823,
        -0.03685854747891426,
        -0.038092561066150665,
        0.05406307056546211,
        -0.004905413370579481,
        0.015362663194537163,
        -0.011375205591320992,
        0.052096664905548096,
        -0.050948403775691986,
        -0.029477855190634727,
        0.04381575435400009,
        -0.05489816144108772,
        0.058843404054641724,
        -0.03604802489280701,
        -0.027718232944607735,
        0.05386131629347801,
        -0.05887196958065033,
        0.022131482139229774,
        0.012801977805793285,
        -0.007484275847673416,
        -0.05375831946730614,
        -0.046727877110242844,
        -0.05958109349012375,
        0.06571219116449356,
        -0.022293493151664734,
        -0.05736931413412094,
        0.044655125588178635,
        0.023690881207585335,
        0.0015759890666231513,
        0.055116668343544006,
        -0.024420004338026047,
        0.05962122604250908,
        0.015220669098198414,
        0.052181992679834366,
        0.07050790637731552,
        -0.06731609255075455,
        0.0707751139998436,
        0.061586182564496994,
        -0.04806793853640556,
        0.04442993924021721,
        0.032355401664972305,
        0.04724087566137314,
        -0.05034876987338066,
        0.04364698752760887,
        -0.030307697132229805,
        0.003125745104625821,
        -0.06164049729704857,
        0.005446238908916712,
        0.03150249645113945,
        -0.07118827849626541,
        0.019999133422970772,
        -0.0532991848886013,
        -0.03664079308509827,
        0.01701711304485798,
        -0.026467809453606606,
        -0.050654031336307526,
        0.0503120981156826,
        -0.053986988961696625,
        0.030529674142599106,
        -0.04881207272410393,
        -0.06404613703489304,
        0.035170964896678925,
        0.03852739930152893,
        0.01367019023746252,
        0.035784486681222916,
        0.0014897718792781234,
        -0.03858232498168945,
        -0.008691294118762016,
        -0.019826790317893028,
        0.01862318255007267,
        -0.01596309058368206,
        -0.007954664528369904,
        -0.048803284764289856,
        -0.06639496237039566,
        0.06836971640586853,
        0.013805177062749863,
        -0.01861007884144783,
        0.06615392118692398,
        -0.029344219714403152,
        0.03203405812382698,
        0.04312177747488022,
        0.059187646955251694,
        -0.07108411192893982,
        -0.04194621741771698,
        0.07527722418308258,
        0.012418853119015694,
        -0.020871635526418686,
        0.052656691521406174,
        -0.02521270327270031,
        0.07114619761705399,
        -0.02666543424129486,
        0.01945328339934349,
        -0.057418566197156906,
        -0.040177252143621445,
        -0.05922427028417587,
        0.06251483410596848,
        -0.047565072774887085,
        -0.0013690779451280832,
        0.0043145762756466866,
        -0.07351107895374298,
        0.06107400730252266,
        0.036568623036146164,
        -0.06303161382675171,
        0.007594210095703602,
        0.032719749957323074,
        -0.04836247116327286,
        0.06677469611167908,
        -0.01306283101439476,
        0.031774066388607025,
        -0.07168097048997879,
        0.009867103770375252,
        -0.03359495475888252,
        0.022089194506406784,
        0.06939581036567688,
        0.03002006933093071,
        0.04028397053480148,
        -0.018184877932071686,
        0.025813380256295204,
        0.015311303548514843,
        0.03297029808163643,
        0.0010130683658644557,
        -0.025042414665222168,
        -0.07534872740507126,
        0.05462220683693886,
        0.054744359105825424,
        0.011568471789360046,
        -0.007524710148572922,
        -0.0735410526394844,
        -0.05558202788233757,
        -0.04976741224527359,
        -0.05754026770591736,
        -0.027910012751817703,
        0.03009132295846939,
        0.03739560768008232,
        -0.04437284544110298,
        0.06820796430110931,
        0.06141766160726547,
        0.029812408611178398,
        0.0015845737652853131,
        -0.06536366045475006,
        -0.015979446470737457,
        0.06066223606467247,
        0.06906168162822723,
        -0.01973036862909794,
        -0.023641470819711685,
        0.05410832166671753,
        0.03043530508875847,
        0.003402293659746647,
        0.0448249988257885,
        0.02442074753344059,
        0.0021460955031216145,
        -0.06976006180047989,
        0.03970980644226074,
        -0.03297245129942894,
        0.0641644075512886,
        -0.05152885243296623,
        0.026875559240579605,
        0.042107898741960526,
        0.03904825448989868,
        0.047962479293346405,
        -0.037091564387083054,
        -0.039613302797079086,
        -0.028308041393756866,
        -0.03708815202116966,
        -0.028023431077599525,
        0.048830557614564896,
        0.018419617787003517,
        -0.01658906601369381,
        0.002715538488700986,
        -0.05721817910671234,
        0.0483098179101944,
        -0.014636955223977566,
        -0.0669986754655838,
        -0.003074382431805134,
        0.04247685521841049,
        -0.03734990209341049,
        -0.05551644042134285,
        -0.036708299070596695,
        0.05007719248533249,
        -0.006985703017562628,
        0.048316843807697296,
        0.04587208852171898,
        0.03923016041517258,
        0.05113857612013817,
        0.036726925522089005,
        0.016007263213396072,
        0.07339392602443695,
        -0.02442656084895134,
        0.06439761072397232,
        -0.014701512642204762,
        -0.040603749454021454,
        -0.0026479128282517195,
        0.025444814935326576,
        0.004676380660384893,
        0.026259901002049446,
        -0.059164490550756454,
        0.05358429625630379,
        -0.009079362265765667,
        -0.04967803135514259,
        0.03888917341828346,
        0.019583970308303833,
        -0.010695692151784897,
        -0.07335355877876282,
        0.002711688634008169,
        0.05241118744015694,
        0.07309640944004059,
        -0.04925667122006416,
        0.05651833489537239,
        -0.04735727235674858,
        -0.06811148673295975,
        0.04127170145511627,
        0.053966835141181946,
        0.03219767287373543,
        0.003279575612396002,
        -0.06091802939772606,
        -0.043792419135570526,
        0.03465795889496803,
        0.007709764409810305,
        -0.046708621084690094,
        -0.04066723957657814,
        -0.03369167447090149,
        -0.049342080950737,
        0.00012804538710042834,
        0.017845967784523964,
        0.03279969096183777,
        0.040861889719963074,
        0.041209954768419266,
        0.04806070774793625,
        -0.058568038046360016,
        0.001983378315344453,
        -0.06841282546520233,
        0.014716707170009613,
        0.018152914941310883,
        0.02328117936849594,
        0.04085560142993927,
        -0.004000325221568346,
        0.00166846823412925,
        0.03917902708053589,
        0.06557347625494003,
        0.06753470748662949,
        -0.05698017403483391,
        -0.04656465724110603,
        -0.021319346502423286,
        0.011252895928919315,
        -0.0003720200911629945,
        -0.04662960395216942,
        0.06443164497613907,
        0.0419628880918026,
        -0.045378413051366806,
        -0.07270344346761703,
        0.04251033440232277,
        0.07496536523103714
    ],
    [
        -0.030424844473600388,
        0.01628333330154419,
        0.0015225278912112117,
        0.024305803701281548,
        0.023561501875519753,
        -0.012308184988796711,
        0.04178139939904213,
        -0.05042671784758568,
        0.04517456144094467,
        0.014489740133285522,
        -0.011766279116272926,
        0.06161624565720558,
        -0.0029619354754686356,
        -0.04452093690633774,
        -0.06947565823793411,
        0.06167317554354668,
        -0.03430556133389473,
        0.06238137185573578,
        -0.0508737675845623,
        -0.019013602286577225,
        -0.03352537006139755,
        0.0427846722304821,
        0.03188781812787056,
        -0.05363938584923744,
        0.028817635029554367,
        0.022937776520848274,
        0.052949097007513046,
        -0.03267274424433708,
        -0.00009927951032295823,
        -0.021993393078446388,
        0.06861584633588791,
        -0.03292005881667137,
        0.00029111988260410726,
        -0.044294074177742004,
        0.037392839789390564,
        0.003612946020439267,
        -0.06690245866775513,
        -0.047339145094156265,
        -0.05527704581618309,
        -0.019684288650751114,
        0.015795418992638588,
        0.03434717282652855,
        0.050882741808891296,
        -0.07367030531167984,
        0.04927099496126175,
        0.06889093667268753,
        0.03559878468513489,
        0.03774639219045639,
        0.016848303377628326,
        0.04013804718852043,
        0.037417296320199966,
        0.06761042028665543,
        0.013005146756768227,
        0.04492628946900368,
        0.008798123337328434,
        -0.04944800212979317,
        -0.0046965591609478,
        0.006135602481663227,
        -0.006539481692016125,
        -0.004663272760808468,
        -0.06665874272584915,
        0.019659914076328278,
        -0.04750651493668556,
        0.06657615303993225,
        -0.05545169115066528,
        -0.01302406843751669,
        0.05207309499382973,
        0.009975716471672058,
        -0.05787259712815285,
        -0.0686502531170845,
        -0.060426853597164154,
        -0.07257414609193802,
        0.062057238072156906,
        -0.009768732823431492,
        -0.02125678025186062,
        -0.004376255441457033,
        0.024046432226896286,
        0.07464524358510971,
        0.0043413457460701466,
        -0.020775848999619484,
        -0.020314563065767288,
        0.07245176285505295,
        -0.04779108613729477,
        0.009509713388979435,
        0.00021549583470914513,
        0.033264659345149994,
        0.05843428522348404,
        -0.05510459467768669,
        0.03937224671244621,
        0.07161712646484375,
        0.049592338502407074,
        0.0008575750980526209,
        -0.05798353999853134,
        -0.031210239976644516,
        -0.028125377371907234,
        0.028162602335214615,
        -0.01666337065398693,
        -0.06647306680679321,
        -0.03611705079674721,
        0.020453346893191338,
        -0.07167619466781616,
        0.014753025025129318,
        0.07552924752235413,
        0.03645661473274231,
        0.0515766479074955,
        -0.03449723497033119,
        -0.03824225068092346,
        0.021496782079339027,
        -0.0038577273953706026,
        0.023519238457083702,
        -0.047362953424453735,
        -0.04981270059943199,
        -0.06337428838014603,
        -0.012996368110179901,
        0.04609452933073044,
        -0.05685961991548538,
        0.02970004267990589,
        -0.0027651034761220217,
        0.059791531413793564,
        0.056966885924339294,
        -0.015580653212964535,
        0.058768998831510544,
        -0.04035556688904762,
        0.03597300872206688,
        -0.0729454904794693,
        -0.042601846158504486,
        -0.035394277423620224,
        -0.020608769729733467,
        0.06891720741987228,
        0.02887585572898388,
        0.012215699069201946,
        -0.0659758672118187,
        -0.044718872755765915,
        0.016573159024119377,
        -0.06433112174272537,
        -0.06451314687728882,
        -0.00879761204123497,
        0.014618237502872944,
        -0.06850796937942505,
        0.05853935703635216,
        0.06822259724140167,
        -0.006700948346406221,
        0.06342881917953491,
        0.010472966358065605,
        0.04986637085676193,
        0.0219087116420269,
        0.06265703588724136,
        -0.04640090838074684,
        -0.005434818100184202,
        0.05945185199379921,
        0.04644943028688431,
        -0.07304979115724564,
        -0.021372215822339058,
        -0.05289369821548462,
        -0.019469691440463066,
        -0.03264368698000908,
        0.05715222656726837,
        0.022888999432325363,
        -0.013584570959210396,
        -0.005733529105782509,
        0.06419126689434052,
        0.05721600726246834,
        0.06260392814874649,
        0.03286752477288246,
        0.013533111661672592,
        0.01229949202388525,
        -0.06421025842428207,
        -0.02591162733733654,
        0.0391741544008255,
        0.030802356079220772,
        -0.021121082827448845,
        0.04074128717184067,
        -0.0007478644838556647,
        -0.04244858771562576,
        0.018938085064291954,
        0.06180010363459587,
        -0.07231695204973221,
        0.0508267842233181,
        0.0530371330678463,
        -0.03775326535105705,
        0.03548869863152504,
        0.07178881764411926,
        0.012008223682641983,
        0.03794126957654953,
        0.04673240706324577,
        0.0235492754727602,
        -0.06511612981557846,
        0.07271838188171387,
        -0.06230475381016731,
        -0.022751446813344955,
        -0.0006350735202431679,
        0.005996685940772295,
        0.006375536788254976,
        -0.02573203295469284,
        -0.05775262042880058,
        0.044029686599969864,
        0.01591522991657257,
        -0.05491488426923752,
        -0.03290342167019844,
        0.00037398881977424026,
        0.02329234592616558,
        -0.02165859192609787,
        -0.005660363007336855,
        -0.015047225169837475,
        -0.0730796530842781,
        0.06870601326227188,
        0.03994250297546387,
        0.013211854733526707,
        -0.04790188744664192,
        -0.004955136217176914,
        -0.0590471513569355,
        0.07235241681337357,
        0.02953357622027397,
        0.04603924974799156,
        -0.011917988769710064,
        0.03650582209229469,
        0.05231902748346329,
        -0.0036060858983546495,
        0.0713408961892128,
        -0.017278792336583138,
        0.010007442906498909,
        0.057109806686639786,
        -0.06214578449726105,
        -0.07253878563642502,
        0.06578410416841507,
        -0.05010058730840683,
        -0.036353904753923416,
        0.040887750685214996,
        -0.009536013007164001,
        -0.06103481724858284,
        0.00846630334854126,
        -0.04277784377336502,
        -0.05707226321101189,
        0.034365780651569366,
        -0.028455154970288277,
        0.06840629130601883,
        0.06635366380214691,
        -0.0299075897783041,
        -0.03908317908644676,
        0.07425825297832489,
        0.006496121175587177,
        0.066730797290802,
        -0.075046606361866,
        0.04202011972665787,
        0.03857136890292168,
        -0.0075346073135733604,
        -0.07217297703027725,
        -0.04798051714897156,
        -0.0061092134565114975,
        -0.0622672438621521,
        -0.03041478618979454,
        -0.0704834833741188,
        -0.026541784405708313,
        -0.06880201399326324,
        -0.037761859595775604,
        0.06917554140090942,
        -0.05770605430006981,
        -0.04229455068707466,
        0.06733007729053497,
        0.030804282054305077,
        0.0735917016863823,
        -0.03685854747891426,
        -0.038092561066150665,
        0.05406307056546211,
        -0.004905413370579481,
        0.015362663194537163,
        -0.011375205591320992,
        0.052096664905548096,
        -0.050948403775691986,
        -0.029477855190634727,
        0.04381575435400009,
        -0.05489816144108772,
        0.058843404054641724,
        -0.03604802489280701,
        -0.027718232944607735,
        0.05386131629347801,
        -0.05887196958065033,
        0.022131482139229774,
        0.012801977805793285,
        -0.007484275847673416,
        -0.05375831946730614,
        -0.046727877110242844,
        -0.05958109349012375,
        0.06571219116449356,
        -0.022293493151664734,
        -0.05736931413412094,
        0.044655125588178635,
        0.023690881207585335,
        0.0015759890666231513,
        0.055116668343544006,
        -0.024420004338026047,
        0.05962122604250908,
        0.015220669098198414,
        0.052181992679834366,
        0.07050790637731552,
        -0.06731609255075455,
        0.0707751139998436,
        0.061586182564496994,
        -0.04806793853640556,
        0.04442993924021721,
        0.032355401664972305,
        0.04724087566137314,
        -0.05034876987338066,
        0.04364698752760887,
        -0.030307697132229805,
        0.003125745104625821,
        -0.06164049729704857,
        0.005446238908916712,
        0.03150249645113945,
        -0.07118827849626541,
        0.019999133422970772,
        -0.0532991848886013,
        -0.03664079308509827,
        0.01701711304485798,
        -0.026467809453606606,
        -0.050654031336307526,
        0.0503120981156826,
        -0.053986988961696625,
        0.030529674142599106,
        -0.04881207272410393,
        -0.06404613703489304,
        0.035170964896678925,
        0.03852739930152893,
        0.01367019023746252,
        0.035784486681222916,
        0.0014897718792781234,
        -0.03858232498168945,
        -0.008691294118762016,
        -0.019826790317893028,
        0.01862318255007267,
        -0.01596309058368206,
        -0.007954664528369904,
        -0.048803284764289856,
        -0.06639496237039566,
        0.06836971640586853,
        0.013805177062749863,
        -0.01861007884144783,
        0.06615392118692398,
        -0.029344219714403152,
        0.03203405812382698,
        0.04312177747488022,
        0.059187646955251694,
        -0.07108411192893982,
        -0.04194621741771698,
        0.07527722418308258,
        0.012418853119015694,
        -0.020871635526418686,
        0.052656691521406174,
        -0.02521270327270031,
        0.07114619761705399,
        -0.02666543424129486,
        0.01945328339934349,
        -0.057418566197156906,
        -0.040177252143621445,
        -0.05922427028417587,
        0.06251483410596848,
        -0.047565072774887085,
        -0.0013690779451280832,
        0.0043145762756466866,
        -0.07351107895374298,
        0.06107400730252266,
        0.036568623036146164,
        -0.06303161382675171,
        0.007594210095703602,
        0.032719749957323074,
        -0.04836247116327286,
        0.06677469611167908,
        -0.01306283101439476,
        0.031774066388607025,
        -0.07168097048997879,
        0.009867103770375252,
        -0.03359495475888252,
        0.022089194506406784,
        0.06939581036567688,
        0.03002006933093071,
        0.04028397053480148,
        -0.018184877932071686,
        0.025813380256295204,
        0.015311303548514843,
        0.03297029808163643,
        0.0010130683658644557,
        -0.025042414665222168,
        -0.07534872740507126,
        0.05462220683693886,
        0.054744359105825424,
        0.011568471789360046,
        -0.007524710148572922,
        -0.0735410526394844,
        -0.05558202788233757,
        -0.04976741224527359,
        -0.05754026770591736,
        -0.027910012751817703,
        0.03009132295846939,
        0.03739560768008232,
        -0.04437284544110298,
        0.06820796430110931,
        0.06141766160726547,
        0.029812408611178398,
        0.0015845737652853131,
        -0.06536366045475006,
        -0.015979446470737457,
        0.06066223606467247,
        0.06906168162822723,
        -0.01973036862909794,
        -0.023641470819711685,
        0.05410832166671753,
        0.03043530508875847,
        0.003402293659746647,
        0.0448249988257885,
        0.02442074753344059,
        0.0021460955031216145,
        -0.06976006180047989,
        0.03970980644226074,
        -0.03297245129942894,
        0.0641644075512886,
        -0.05152885243296623,
        0.026875559240579605,
        0.042107898741960526,
        0.03904825448989868,
        0.047962479293346405,
        -0.037091564387083054,
        -0.039613302797079086,
        -0.028308041393756866,
        -0.03708815202116966,
        -0.028023431077599525,
        0.048830557614564896,
        0.018419617787003517,
        -0.01658906601369381,
        0.002715538488700986,
        -0.05721817910671234,
        0.0483098179101944,
        -0.014636955223977566,
        -0.0669986754655838,
        -0.003074382431805134,
        0.04247685521841049,
        -0.03734990209341049,
        -0.05551644042134285,
        -0.036708299070596695,
        0.05007719248533249,
        -0.006985703017562628,
        0.048316843807697296,
        0.04587208852171898,
        0.03923016041517258,
        0.05113857612013817,
        0.036726925522089005,
        0.016007263213396072,
        0.07339392602443695,
        -0.02442656084895134,
        0.06439761072397232,
        -0.014701512642204762,
        -0.040603749454021454,
        -0.0026479128282517195,
        0.025444814935326576,
        0.004676380660384893,
        0.026259901002049446,
        -0.059164490550756454,
        0.05358429625630379,
        -0.009079362265765667,
        -0.04967803135514259,
        0.03888917341828346,
        0.019583970308303833,
        -0.010695692151784897,
        -0.07335355877876282,
        0.002711688634008169,
        0.05241118744015694,
        0.07309640944004059,
        -0.04925667122006416,
        0.05651833489537239,
        -0.04735727235674858,
        -0.06811148673295975,
        0.04127170145511627,
        0.053966835141181946,
        0.03219767287373543,
        0.003279575612396002,
        -0.06091802939772606,
        -0.043792419135570526,
        0.03465795889496803,
        0.007709764409810305,
        -0.046708621084690094,
        -0.04066723957657814,
        -0.03369167447090149,
        -0.049342080950737,
        0.00012804538710042834,
        0.017845967784523964,
        0.03279969096183777,
        0.040861889719963074,
        0.041209954768419266,
        0.04806070774793625,
        -0.058568038046360016,
        0.001983378315344453,
        -0.06841282546520233,
        0.014716707170009613,
        0.018152914941310883,
        0.02328117936849594,
        0.04085560142993927,
        -0.004000325221568346,
        0.00166846823412925,
        0.03917902708053589,
        0.06557347625494003,
        0.06753470748662949,
        -0.05698017403483391,
        -0.04656465724110603,
        -0.021319346502423286,
        0.011252895928919315,
        -0.0003720200911629945,
        -0.04662960395216942,
        0.06443164497613907,
        0.0419628880918026,
        -0.045378413051366806,
        -0.07270344346761703,
        0.04251033440232277,
        0.07496536523103714
    ],
    [
        -0.04681016877293587,
        0.008699292317032814,
        -0.0076714432798326015,
        0.02748999185860157,
        0.017455486580729485,
        -0.027112888172268867,
        0.04273802042007446,
        -0.051687270402908325,
        0.04543435946106911,
        0.03175416961312294,
        -0.006299267522990704,
        0.06249653920531273,
        0.008735784329473972,
        -0.03815101087093353,
        -0.06783273816108704,
        0.056043025106191635,
        -0.04279413819313049,
        0.06171269714832306,
        -0.045484352856874466,
        -0.036376699805259705,
        -0.01811697706580162,
        0.03378161042928696,
        0.04267945885658264,
        -0.054840393364429474,
        0.031547028571367264,
        0.02387278340756893,
        0.06140775978565216,
        -0.0418948233127594,
        -0.0004429671971593052,
        -0.012325425632297993,
        0.0641842633485794,
        -0.028113439679145813,
        -0.0071897744201123714,
        -0.05595101788640022,
        0.03753988817334175,
        0.003225981490686536,
        -0.06621816754341125,
        -0.04922132566571236,
        -0.04956459254026413,
        -0.01604551076889038,
        0.023934217169880867,
        0.03601807728409767,
        0.043501030653715134,
        -0.06943655014038086,
        0.050633594393730164,
        0.06722857058048248,
        0.04351530224084854,
        0.04405316337943077,
        0.04813169315457344,
        0.03992510214447975,
        0.054947443306446075,
        0.06574878096580505,
        0.02142866887152195,
        0.04887979105114937,
        0.011352023109793663,
        -0.053021978586912155,
        -0.01802458055317402,
        0.015088913030922413,
        0.005937119014561176,
        -0.010178986936807632,
        -0.06775444000959396,
        0.017262455075979233,
        -0.044847819954156876,
        0.06892365217208862,
        -0.04801143333315849,
        -0.015070563182234764,
        0.04955953359603882,
        0.00021555503190029413,
        -0.05614311620593071,
        -0.0681668147444725,
        -0.06126416474580765,
        -0.07092561572790146,
        0.060603704303503036,
        -0.023335756734013557,
        0.003624970093369484,
        0.002291586250066757,
        0.03952275589108467,
        0.07074043154716492,
        0.012540518306195736,
        -0.03894118592143059,
        -0.012919741682708263,
        0.07049335539340973,
        -0.05495397746562958,
        0.028790827840566635,
        0.000535406987182796,
        0.03165856748819351,
        0.05711612477898598,
        -0.054499365389347076,
        0.04149631783366203,
        0.06945390999317169,
        0.006410687696188688,
        -0.022894838824868202,
        -0.06488312035799026,
        -0.03753306716680527,
        -0.010084455832839012,
        0.023360293358564377,
        -0.019032470881938934,
        -0.06312047690153122,
        -0.040181372314691544,
        0.020955340936779976,
        -0.06803353875875473,
        0.03792356327176094,
        0.0730346143245697,
        0.04737018421292305,
        0.050914645195007324,
        -0.043274614959955215,
        -0.023994076997041702,
        0.03154977038502693,
        -0.007187399547547102,
        0.03723302483558655,
        -0.047189243137836456,
        -0.05672703683376312,
        -0.0601356141269207,
        -0.0006017685518600047,
        0.04045867174863815,
        -0.058309223502874374,
        0.03942451626062393,
        -0.020167067646980286,
        0.057271383702754974,
        0.05998395383358002,
        -0.0024642811622470617,
        0.06233195587992668,
        -0.03838135302066803,
        0.047282975167036057,
        -0.06892459094524384,
        -0.022189045324921608,
        -0.03367873281240463,
        -0.04783996194601059,
        0.06426762044429779,
        0.044301941990852356,
        0.024035915732383728,
        -0.056660424917936325,
        -0.04141423478722572,
        0.0038134001661092043,
        -0.06385930627584457,
        -0.061102449893951416,
        0.014519955962896347,
        0.028475824743509293,
        -0.06613200157880783,
        0.06088060513138771,
        0.06107012555003166,
        -0.0023366145323961973,
        0.056422676891088486,
        -0.001159752020612359,
        0.046568866819143295,
        0.011284269392490387,
        0.05642038211226463,
        -0.04318415746092796,
        0.0015898010460659862,
        0.05867664888501167,
        0.047763530164957047,
        -0.07054375857114792,
        -0.011118141934275627,
        -0.049409300088882446,
        -0.020742643624544144,
        -0.041291069239377975,
        0.05464741215109825,
        0.019410312175750732,
        -0.017155136913061142,
        0.008238855749368668,
        0.06693742424249649,
        0.04339722543954849,
        0.05670493468642235,
        0.02576875314116478,
        0.0069724153727293015,
        0.02511570230126381,
        -0.06115740165114403,
        -0.024885548278689384,
        0.03752956911921501,
        0.028841165825724602,
        -0.023566560819745064,
        0.03312411531805992,
        0.007770904805511236,
        -0.04517490789294243,
        0.019560933113098145,
        0.06650920957326889,
        -0.06918483227491379,
        0.04568915441632271,
        0.04188414290547371,
        -0.055528007447719574,
        0.03863709419965744,
        0.07198621332645416,
        0.014842095784842968,
        0.04558775946497917,
        0.047359537333250046,
        0.022808685898780823,
        -0.06753955781459808,
        0.07063224911689758,
        -0.061468422412872314,
        -0.031297679990530014,
        -0.0017041779356077313,
        0.018303893506526947,
        -0.005547054577618837,
        -0.015995480120182037,
        -0.06384721398353577,
        0.030444517731666565,
        0.0068695819936692715,
        -0.05756932869553566,
        -0.03756403550505638,
        0.01865544356405735,
        0.02092345990240574,
        -0.035678137093782425,
        -0.02415846846997738,
        -0.03302755951881409,
        -0.07052139192819595,
        0.06793083995580673,
        0.04433072730898857,
        0.00805592630058527,
        -0.04966225102543831,
        0.004830023739486933,
        -0.055056940764188766,
        0.07058148086071014,
        0.014301722869277,
        0.04655667394399643,
        -0.017924275249242783,
        0.0244988352060318,
        0.03731665015220642,
        -0.017396623268723488,
        0.06785864382982254,
        -0.023041116073727608,
        -0.012023366056382656,
        0.05373448505997658,
        -0.06422989815473557,
        -0.06897825747728348,
        0.06850261241197586,
        -0.0547490119934082,
        -0.028396788984537125,
        0.02619592659175396,
        0.0005511403433047235,
        -0.06008746102452278,
        0.01691540516912937,
        -0.054337359964847565,
        -0.05375782027840614,
        0.03948120400309563,
        -0.040010493248701096,
        0.058122266083955765,
        0.06756959110498428,
        -0.039423901587724686,
        -0.036965981125831604,
        0.0697093978524208,
        0.00116553099360317,
        0.0662037804722786,
        -0.07304342836141586,
        0.0431939922273159,
        0.04848472774028778,
        -0.00948351714760065,
        -0.06688281148672104,
        -0.029756812378764153,
        0.008165808394551277,
        -0.06453987956047058,
        -0.034944020211696625,
        -0.06966479122638702,
        -0.035509947687387466,
        -0.0671619400382042,
        -0.029592791572213173,
        0.06627209484577179,
        -0.056634094566106796,
        -0.04186376929283142,
        0.06865144520998001,
        0.03300788998603821,
        0.07078256458044052,
        -0.030396997928619385,
        -0.031437020748853683,
        0.06065353378653526,
        -0.014395455829799175,
        0.013597622513771057,
        0.010171281173825264,
        0.05482066050171852,
        -0.04715833440423012,
        -0.03003145381808281,
        0.03779527172446251,
        -0.06079915538430214,
        0.06311960518360138,
        -0.029469113796949387,
        -0.015179524198174477,
        0.04261576011776924,
        -0.05992165580391884,
        0.018110768869519234,
        0.0067529440857470036,
        -0.007583405822515488,
        -0.06189458444714546,
        -0.03260492533445358,
        -0.06043694540858269,
        0.0635123997926712,
        0.009044324979186058,
        -0.05498085543513298,
        0.04661833867430687,
        0.01541919820010662,
        0.01328062079846859,
        0.04707589000463486,
        -0.037366095930337906,
        0.056154217571020126,
        0.0014294409193098545,
        0.048813346773386,
        0.07006286084651947,
        -0.06354812532663345,
        0.06935501098632812,
        0.06537266820669174,
        -0.040721021592617035,
        0.043668270111083984,
        0.041721928864717484,
        0.03935489431023598,
        -0.04303792491555214,
        0.038005731999874115,
        -0.01533213909715414,
        -0.010745583102107048,
        -0.057971253991127014,
        0.0012289610458537936,
        0.03179722651839256,
        -0.0641462653875351,
        0.02199842408299446,
        -0.05980892479419708,
        -0.04070131480693817,
        0.0019616177305579185,
        -0.01684890314936638,
        -0.05331343412399292,
        0.04817109555006027,
        -0.0503975935280323,
        0.029872136190533638,
        -0.06117509678006172,
        -0.05307711288332939,
        0.023635977879166603,
        0.0359283909201622,
        0.02142612263560295,
        0.03368891030550003,
        0.0027067672926932573,
        -0.03899800777435303,
        0.006180393509566784,
        -0.013169627636671066,
        0.019244806841015816,
        -0.005677974782884121,
        0.00404090341180563,
        -0.04201770946383476,
        -0.0636073425412178,
        0.06680912524461746,
        0.03266685828566551,
        -0.03587755933403969,
        0.058984074741601944,
        -0.014368264004588127,
        0.031074324622750282,
        0.04425674304366112,
        0.057384051382541656,
        -0.0667559877038002,
        -0.05404964089393616,
        0.07197501510381699,
        0.004541854374110699,
        -0.015237456187605858,
        0.05542154610157013,
        -0.03318696469068527,
        0.06638415157794952,
        -0.023310301825404167,
        0.022840246558189392,
        -0.06418608129024506,
        -0.06234029680490494,
        -0.058986544609069824,
        0.062259942293167114,
        -0.054003484547138214,
        -0.012275577522814274,
        0.013481458649039268,
        -0.07220879197120667,
        0.05832677334547043,
        0.0426417700946331,
        -0.05712608993053436,
        0.013508847914636135,
        0.035640899091959,
        -0.05315065011382103,
        0.07130086421966553,
        -0.013811035081744194,
        0.05177313834428787,
        -0.07018358260393143,
        -0.00798490084707737,
        -0.04588790237903595,
        0.02865763008594513,
        0.06671995669603348,
        0.04358084872364998,
        0.043452952057123184,
        -0.010203451849520206,
        0.03843079134821892,
        0.02391691878437996,
        0.04230143874883652,
        -0.013206743635237217,
        -0.017859485000371933,
        -0.07265101373195648,
        0.03603800758719444,
        0.0433131568133831,
        0.009617585688829422,
        -0.018657764419913292,
        -0.06895066797733307,
        -0.055660102516412735,
        -0.04188733175396919,
        -0.0637940913438797,
        -0.02798120118677616,
        0.021209942176938057,
        0.04377485811710358,
        -0.035820674151182175,
        0.06730742007493973,
        0.059283968061208725,
        0.0136470478028059,
        0.014479451812803745,
        -0.06767159700393677,
        -0.019484490156173706,
        0.06400993466377258,
        0.0689435750246048,
        -0.028382103890180588,
        -0.026822492480278015,
        0.0521552599966526,
        0.02491515874862671,
        0.0014431944582611322,
        0.04595651850104332,
        0.020679136738181114,
        0.009435703977942467,
        -0.0660155639052391,
        0.04690030217170715,
        -0.011695492081344128,
        0.06094442680478096,
        -0.046593762934207916,
        0.02012348733842373,
        0.03646272420883179,
        0.02758852206170559,
        0.050951723009347916,
        -0.020598528906702995,
        -0.025483552366495132,
        -0.023812320083379745,
        -0.04341021180152893,
        -0.0009588642278686166,
        0.03777838870882988,
        0.018643230199813843,
        0.0011495125945657492,
        -0.0021682651713490486,
        -0.05207660794258118,
        0.05593575909733772,
        -0.01190053392201662,
        -0.0671154335141182,
        -0.015967026352882385,
        0.028922703117132187,
        -0.045974548906087875,
        -0.034144625067710876,
        -0.044028181582689285,
        0.0621967688202858,
        0.0026471512392163277,
        0.042586833238601685,
        0.05138417333364487,
        0.047663748264312744,
        0.057441022247076035,
        0.03263593092560768,
        0.013474998064339161,
        0.0708393007516861,
        -0.024682139977812767,
        0.06290987879037857,
        -0.00020705201313830912,
        -0.039234425872564316,
        -0.006641804240643978,
        0.045442793518304825,
        0.013224109075963497,
        0.030081378296017647,
        -0.056772664189338684,
        0.04894956201314926,
        -0.01633971370756626,
        -0.044731564819812775,
        0.05841260403394699,
        0.019040487706661224,
        -0.020825590938329697,
        -0.07031827419996262,
        0.005396522581577301,
        0.05861077457666397,
        0.07153918594121933,
        -0.05505550280213356,
        0.05546330288052559,
        -0.05125656723976135,
        -0.06913909316062927,
        0.045264825224876404,
        0.05726903676986694,
        0.03913724049925804,
        0.019301235675811768,
        -0.06793886423110962,
        -0.02985941804945469,
        0.01590694859623909,
        0.013658230192959309,
        -0.048131123185157776,
        -0.038909949362277985,
        -0.04451850801706314,
        -0.05161989480257034,
        -0.014841480180621147,
        0.01706162467598915,
        0.03215638920664787,
        0.04935920238494873,
        0.04589449614286423,
        0.043487049639225006,
        -0.054673124104738235,
        -0.015840737149119377,
        -0.06547144055366516,
        0.007387205027043819,
        0.0223341453820467,
        0.019870130345225334,
        0.047360263764858246,
        -0.005207101348787546,
        -0.009836098179221153,
        0.055280204862356186,
        0.0627240538597107,
        0.06787357479333878,
        -0.061084650456905365,
        -0.04204275459051132,
        -0.0273276399821043,
        0.011234658770263195,
        0.027331244200468063,
        -0.052327174693346024,
        0.06877986341714859,
        0.03404286131262779,
        -0.03673907369375229,
        -0.0702127069234848,
        0.045253802090883255,
        0.0707932636141777
    ],
    [
        -0.025179468095302582,
        0.014341089874505997,
        0.012767824344336987,
        0.02670358680188656,
        0.029669353738427162,
        -0.005376722663640976,
        0.04834612086415291,
        -0.05454524978995323,
        0.04391356185078621,
        0.020531831309199333,
        -0.007572443690150976,
        0.053223226219415665,
        -0.005102900788187981,
        -0.03660628944635391,
        -0.0698293000459671,
        0.05256279557943344,
        -0.02978650853037834,
        0.06307188421487808,
        -0.04981892928481102,
        -0.008769066073000431,
        -0.03973240777850151,
        0.046775396913290024,
        0.028840376064181328,
        -0.05470428615808487,
        0.04284990578889847,
        0.01671835035085678,
        0.04945734515786171,
        -0.02619762159883976,
        0.005694414488971233,
        -0.02462724968791008,
        0.07076409459114075,
        -0.031158501282334328,
        -0.001729196752421558,
        -0.04976455867290497,
        0.043250102549791336,
        0.006759430281817913,
        -0.06759177893400192,
        -0.04585698992013931,
        -0.04279926419258118,
        -0.017793018370866776,
        0.00402255030348897,
        0.04185612499713898,
        0.0490163154900074,
        -0.0737411230802536,
        0.03182058408856392,
        0.06966385990381241,
        0.017895663157105446,
        0.03481972962617874,
        0.025636153295636177,
        0.0402105338871479,
        0.030187515541911125,
        0.06792393326759338,
        -0.0009473195532336831,
        0.04345131292939186,
        0.011435003951191902,
        -0.04850172623991966,
        -0.016030393540859222,
        -0.000014482333426713012,
        -0.0007478243205696344,
        -0.005151375662535429,
        -0.06600610166788101,
        0.024420451372861862,
        -0.04590173065662384,
        0.06637024879455566,
        -0.051155880093574524,
        -0.024208785966038704,
        0.046894993633031845,
        0.012446839362382889,
        -0.0609002560377121,
        -0.06688565015792847,
        -0.0573415569961071,
        -0.07521887123584747,
        0.06735359877347946,
        -0.00973864272236824,
        -0.030514497309923172,
        -0.005509845446795225,
        0.019754968583583832,
        0.07531706988811493,
        0.004675284028053284,
        -0.011740665882825851,
        -0.022664837539196014,
        0.07084479928016663,
        -0.04454248771071434,
        0.012269878759980202,
        0.013601873070001602,
        0.030990753322839737,
        0.056429482996463776,
        -0.06076851487159729,
        0.034894611686468124,
        0.0712142065167427,
        0.055940642952919006,
        0.005311828106641769,
        -0.056405600160360336,
        -0.02125854231417179,
        -0.018924511969089508,
        0.029429223388433456,
        -0.024018000811338425,
        -0.06699497252702713,
        -0.0346059575676918,
        0.012982655316591263,
        -0.07036767899990082,
        0.007781516760587692,
        0.07529173046350479,
        0.03330544754862785,
        0.04878999665379524,
        -0.02810492552816868,
        -0.040727321058511734,
        0.021093009039759636,
        -0.013600616715848446,
        0.03221678361296654,
        -0.04771149903535843,
        -0.041125353425741196,
        -0.06689891219139099,
        -0.028637720271945,
        0.04021192342042923,
        -0.05867244303226471,
        0.02500765398144722,
        -0.008343011140823364,
        0.06414978206157684,
        0.05972958728671074,
        -0.0209177415817976,
        0.0632258877158165,
        -0.04782646521925926,
        0.03987187519669533,
        -0.07246141135692596,
        -0.039929285645484924,
        -0.038458868861198425,
        -0.0025355105753988028,
        0.0683969259262085,
        0.01344861090183258,
        -0.003454777179285884,
        -0.06674467027187347,
        -0.03595733642578125,
        0.016847383230924606,
        -0.06274809688329697,
        -0.06668246537446976,
        -0.004656012170016766,
        0.009457550942897797,
        -0.06628765910863876,
        0.06290928274393082,
        0.07286415249109268,
        -0.010131449438631535,
        0.06379389762878418,
        0.005894751287996769,
        0.05557618662714958,
        0.016560159623622894,
        0.06629514694213867,
        -0.04039320722222328,
        -0.016001205891370773,
        0.0590815395116806,
        0.05114225670695305,
        -0.07285144180059433,
        -0.04023648053407669,
        -0.05359359830617905,
        -0.00008414700278081,
        -0.042137522250413895,
        0.055812153965234756,
        0.010985803790390491,
        -0.018187617883086205,
        -0.004737708251923323,
        0.05859877169132233,
        0.06391157954931259,
        0.062526635825634,
        0.03242871165275574,
        0.024686595425009727,
        -0.0014029474696144462,
        -0.06463143974542618,
        -0.019875939935445786,
        0.0398702435195446,
        0.03490036725997925,
        -0.031876008957624435,
        0.04460739716887474,
        -0.001108250580728054,
        -0.040350291877985,
        0.007847914472222328,
        0.060160357505083084,
        -0.07322030514478683,
        0.0487772561609745,
        0.05228046700358391,
        -0.0307831559330225,
        0.041151631623506546,
        0.06859618425369263,
        0.005224712658673525,
        0.04766039177775383,
        0.046849433332681656,
        0.027918823063373566,
        -0.06601206213235855,
        0.07296985387802124,
        -0.06464150547981262,
        -0.04141328111290932,
        -0.010531402193009853,
        0.008993889205157757,
        0.0031277432572096586,
        -0.0304681658744812,
        -0.051045872271060944,
        0.04929265379905701,
        0.030936814844608307,
        -0.05256624147295952,
        -0.033654503524303436,
        0.008185507729649544,
        0.03135889768600464,
        -0.016617005690932274,
        0.010208340361714363,
        -0.006909028626978397,
        -0.0739608108997345,
        0.06400041282176971,
        0.044674985110759735,
        0.016652947291731834,
        -0.05282364413142204,
        -0.00911569595336914,
        -0.05642610043287277,
        0.0715060606598854,
        0.028046851977705956,
        0.04240904375910759,
        -0.009083352982997894,
        0.042585231363773346,
        0.056271784007549286,
        0.011359717696905136,
        0.0703372061252594,
        0.006531529128551483,
        0.008134378120303154,
        0.053020019084215164,
        -0.06440392881631851,
        -0.0723012238740921,
        0.06754988431930542,
        -0.06207786500453949,
        -0.03155555948615074,
        0.044153712689876556,
        -0.0050344085320830345,
        -0.05979152396321297,
        0.016497589647769928,
        -0.05152198299765587,
        -0.05398781970143318,
        0.026869114488363266,
        -0.021432118490338326,
        0.0703495442867279,
        0.06292513012886047,
        -0.02793158032000065,
        -0.03168048709630966,
        0.07486084848642349,
        -0.0010471576824784279,
        0.06605395674705505,
        -0.07529912889003754,
        0.04557487741112709,
        0.032046832144260406,
        -0.008554610423743725,
        -0.0724039301276207,
        -0.04918157309293747,
        -0.006399739999324083,
        -0.06000853702425957,
        -0.022478653118014336,
        -0.07144008576869965,
        -0.03028264082968235,
        -0.06941588222980499,
        -0.03669508919119835,
        0.0701485425233841,
        -0.05635768920183182,
        -0.05310351774096489,
        0.06043390557169914,
        0.03390421345829964,
        0.07378078997135162,
        -0.03847113251686096,
        -0.031110553070902824,
        0.05033533647656441,
        -0.005615634843707085,
        0.017699861899018288,
        -0.030448738485574722,
        0.04284333810210228,
        -0.0542336031794548,
        -0.03140304237604141,
        0.052121277898550034,
        -0.048584822565317154,
        0.05717487633228302,
        -0.04322937875986099,
        -0.03430303931236267,
        0.052067797631025314,
        -0.05449562519788742,
        0.02937372773885727,
        0.004639308899641037,
        -0.01451034564524889,
        -0.04880886524915695,
        -0.05446106195449829,
        -0.06050335243344307,
        0.06816746294498444,
        -0.02936985157430172,
        -0.05311090499162674,
        0.04126466065645218,
        0.02241135574877262,
        0.0007814913988113403,
        0.056028641760349274,
        -0.02605975978076458,
        0.055688582360744476,
        0.017704913392663002,
        0.057952962815761566,
        0.07087644189596176,
        -0.06702093034982681,
        0.07009726762771606,
        0.05871594324707985,
        -0.05182994529604912,
        0.04609820246696472,
        0.028720660135149956,
        0.04669409990310669,
        -0.04648081958293915,
        0.04791472479701042,
        -0.038370776921510696,
        -0.00254051829688251,
        -0.061810173094272614,
        0.0007300322176888585,
        0.03281703218817711,
        -0.07418373227119446,
        0.01899120956659317,
        -0.04615798220038414,
        -0.03167644143104553,
        0.01543163601309061,
        -0.024705415591597557,
        -0.050086796283721924,
        0.048990827053785324,
        -0.049643803387880325,
        0.027705814689397812,
        -0.0475911982357502,
        -0.06427455693483353,
        0.03386624529957771,
        0.033830516040325165,
        0.027095802128314972,
        0.047498270869255066,
        -0.000018133936464437284,
        -0.0444527231156826,
        -0.02487788535654545,
        -0.020977789536118507,
        0.009968439117074013,
        -0.01907256245613098,
        -0.003340221242979169,
        -0.04442749172449112,
        -0.06793387234210968,
        0.06860239803791046,
        0.013822120614349842,
        -0.020086098462343216,
        0.06920319050550461,
        -0.03230356425046921,
        0.029073305428028107,
        0.045793574303388596,
        0.06394479423761368,
        -0.07132925093173981,
        -0.038497861474752426,
        0.07555805891752243,
        0.013907345943152905,
        -0.016223572194576263,
        0.050534430891275406,
        -0.02038666233420372,
        0.0709836333990097,
        -0.021925106644630432,
        0.010071861557662487,
        -0.060725025832653046,
        -0.0277544017881155,
        -0.057715073227882385,
        0.06447015702724457,
        -0.03837152197957039,
        -0.004598443396389484,
        0.004099578596651554,
        -0.07411997020244598,
        0.06283365935087204,
        0.02585243433713913,
        -0.06057572364807129,
        0.009370368905365467,
        0.03335527703166008,
        -0.0436517670750618,
        0.06613138318061829,
        -0.025389065966010094,
        0.03518573194742203,
        -0.07113677263259888,
        0.0003924285701941699,
        -0.027334896847605705,
        0.024120135232806206,
        0.0691259577870369,
        0.01954001560807228,
        0.042719706892967224,
        -0.017065661028027534,
        0.01312037743628025,
        0.002013909863308072,
        0.036815136671066284,
        0.0031850587110966444,
        -0.02539503015577793,
        -0.07574743777513504,
        0.05856964737176895,
        0.05013985186815262,
        0.016969772055745125,
        -0.010317374020814896,
        -0.07450603693723679,
        -0.05303794890642166,
        -0.04965851828455925,
        -0.06141030788421631,
        -0.02102646976709366,
        0.017270751297473907,
        0.037600092589855194,
        -0.046020571142435074,
        0.067679263651371,
        0.057776693254709244,
        0.036882977932691574,
        0.014140786603093147,
        -0.06211404129862785,
        -0.013138535432517529,
        0.06522604823112488,
        0.0683259516954422,
        -0.018820753321051598,
        -0.02671690285205841,
        0.050998177379369736,
        0.03203932195901871,
        0.0051281629130244255,
        0.046558935195207596,
        0.024487582966685295,
        -0.0031503525096923113,
        -0.07032999396324158,
        0.043927088379859924,
        -0.024475019425153732,
        0.06604824960231781,
        -0.049024730920791626,
        0.016772259026765823,
        0.05576670914888382,
        0.04306013882160187,
        0.05420339107513428,
        -0.04388458654284477,
        -0.03744897618889809,
        -0.030434636399149895,
        -0.03442596271634102,
        -0.017263265326619148,
        0.04763609543442726,
        0.020171724259853363,
        -0.021881142631173134,
        0.004815335385501385,
        -0.053873319178819656,
        0.04829227924346924,
        -0.012403443455696106,
        -0.06866881251335144,
        0.00034132032305933535,
        0.04886835813522339,
        -0.042571939527988434,
        -0.05963071808218956,
        -0.03812204301357269,
        0.0423571951687336,
        -0.0042956131510436535,
        0.04362105205655098,
        0.038958724588155746,
        0.04899754002690315,
        0.04821307584643364,
        0.020314443856477737,
        0.017807474359869957,
        0.07229877263307571,
        -0.020980196073651314,
        0.06250425428152084,
        -0.02331574074923992,
        -0.034369565546512604,
        -0.007152948062866926,
        0.029989536851644516,
        -0.003901024116203189,
        0.029102927073836327,
        -0.0579761266708374,
        0.04392305761575699,
        0.002630814677104354,
        -0.04960596188902855,
        0.03588876873254776,
        -0.004979680757969618,
        -0.011918324045836926,
        -0.07343266904354095,
        -0.01035778783261776,
        0.04673728719353676,
        0.07362234592437744,
        -0.0480654202401638,
        0.059466298669576645,
        -0.054662417620420456,
        -0.06745780259370804,
        0.04164331033825874,
        0.060870103538036346,
        0.024480488151311874,
        0.0016678052488714457,
        -0.057170163840055466,
        -0.04078639671206474,
        0.033133428543806076,
        0.011842138133943081,
        -0.033227674663066864,
        -0.0356757827103138,
        -0.025400353595614433,
        -0.050435397773981094,
        0.001612515770830214,
        0.022145327180624008,
        0.02086305245757103,
        0.03752409666776657,
        0.03225613757967949,
        0.050009287893772125,
        -0.061775799840688705,
        -0.007292077876627445,
        -0.07093974947929382,
        0.021761413663625717,
        0.027417659759521484,
        0.01502927951514721,
        0.04054463654756546,
        -0.00260200840421021,
        -0.010831044055521488,
        0.04525306820869446,
        0.06555996090173721,
        0.0676865354180336,
        -0.04547443240880966,
        -0.05151272937655449,
        -0.004882580600678921,
        0.008746800944209099,
        -0.01964864507317543,
        -0.04096098616719246,
        0.06379708647727966,
        0.0432744100689888,
        -0.05407697707414627,
        -0.07121213525533676,
        0.0503767691552639,
        0.07598593086004257
    ],
    [
        -0.025179468095302582,
        0.014341089874505997,
        0.012767824344336987,
        0.02670358680188656,
        0.029669353738427162,
        -0.005376722663640976,
        0.04834612086415291,
        -0.05454524978995323,
        0.04391356185078621,
        0.020531831309199333,
        -0.007572443690150976,
        0.053223226219415665,
        -0.005102900788187981,
        -0.03660628944635391,
        -0.0698293000459671,
        0.05256279557943344,
        -0.02978650853037834,
        0.06307188421487808,
        -0.04981892928481102,
        -0.008769066073000431,
        -0.03973240777850151,
        0.046775396913290024,
        0.028840376064181328,
        -0.05470428615808487,
        0.04284990578889847,
        0.01671835035085678,
        0.04945734515786171,
        -0.02619762159883976,
        0.005694414488971233,
        -0.02462724968791008,
        0.07076409459114075,
        -0.031158501282334328,
        -0.001729196752421558,
        -0.04976455867290497,
        0.043250102549791336,
        0.006759430281817913,
        -0.06759177893400192,
        -0.04585698992013931,
        -0.04279926419258118,
        -0.017793018370866776,
        0.00402255030348897,
        0.04185612499713898,
        0.0490163154900074,
        -0.0737411230802536,
        0.03182058408856392,
        0.06966385990381241,
        0.017895663157105446,
        0.03481972962617874,
        0.025636153295636177,
        0.0402105338871479,
        0.030187515541911125,
        0.06792393326759338,
        -0.0009473195532336831,
        0.04345131292939186,
        0.011435003951191902,
        -0.04850172623991966,
        -0.016030393540859222,
        -0.000014482333426713012,
        -0.0007478243205696344,
        -0.005151375662535429,
        -0.06600610166788101,
        0.024420451372861862,
        -0.04590173065662384,
        0.06637024879455566,
        -0.051155880093574524,
        -0.024208785966038704,
        0.046894993633031845,
        0.012446839362382889,
        -0.0609002560377121,
        -0.06688565015792847,
        -0.0573415569961071,
        -0.07521887123584747,
        0.06735359877347946,
        -0.00973864272236824,
        -0.030514497309923172,
        -0.005509845446795225,
        0.019754968583583832,
        0.07531706988811493,
        0.004675284028053284,
        -0.011740665882825851,
        -0.022664837539196014,
        0.07084479928016663,
        -0.04454248771071434,
        0.012269878759980202,
        0.013601873070001602,
        0.030990753322839737,
        0.056429482996463776,
        -0.06076851487159729,
        0.034894611686468124,
        0.0712142065167427,
        0.055940642952919006,
        0.005311828106641769,
        -0.056405600160360336,
        -0.02125854231417179,
        -0.018924511969089508,
        0.029429223388433456,
        -0.024018000811338425,
        -0.06699497252702713,
        -0.0346059575676918,
        0.012982655316591263,
        -0.07036767899990082,
        0.007781516760587692,
        0.07529173046350479,
        0.03330544754862785,
        0.04878999665379524,
        -0.02810492552816868,
        -0.040727321058511734,
        0.021093009039759636,
        -0.013600616715848446,
        0.03221678361296654,
        -0.04771149903535843,
        -0.041125353425741196,
        -0.06689891219139099,
        -0.028637720271945,
        0.04021192342042923,
        -0.05867244303226471,
        0.02500765398144722,
        -0.008343011140823364,
        0.06414978206157684,
        0.05972958728671074,
        -0.0209177415817976,
        0.0632258877158165,
        -0.04782646521925926,
        0.03987187519669533,
        -0.07246141135692596,
        -0.039929285645484924,
        -0.038458868861198425,
        -0.0025355105753988028,
        0.0683969259262085,
        0.01344861090183258,
        -0.003454777179285884,
        -0.06674467027187347,
        -0.03595733642578125,
        0.016847383230924606,
        -0.06274809688329697,
        -0.06668246537446976,
        -0.004656012170016766,
        0.009457550942897797,
        -0.06628765910863876,
        0.06290928274393082,
        0.07286415249109268,
        -0.010131449438631535,
        0.06379389762878418,
        0.005894751287996769,
        0.05557618662714958,
        0.016560159623622894,
        0.06629514694213867,
        -0.04039320722222328,
        -0.016001205891370773,
        0.0590815395116806,
        0.05114225670695305,
        -0.07285144180059433,
        -0.04023648053407669,
        -0.05359359830617905,
        -0.00008414700278081,
        -0.042137522250413895,
        0.055812153965234756,
        0.010985803790390491,
        -0.018187617883086205,
        -0.004737708251923323,
        0.05859877169132233,
        0.06391157954931259,
        0.062526635825634,
        0.03242871165275574,
        0.024686595425009727,
        -0.0014029474696144462,
        -0.06463143974542618,
        -0.019875939935445786,
        0.0398702435195446,
        0.03490036725997925,
        -0.031876008957624435,
        0.04460739716887474,
        -0.001108250580728054,
        -0.040350291877985,
        0.007847914472222328,
        0.060160357505083084,
        -0.07322030514478683,
        0.0487772561609745,
        0.05228046700358391,
        -0.0307831559330225,
        0.041151631623506546,
        0.06859618425369263,
        0.005224712658673525,
        0.04766039177775383,
        0.046849433332681656,
        0.027918823063373566,
        -0.06601206213235855,
        0.07296985387802124,
        -0.06464150547981262,
        -0.04141328111290932,
        -0.010531402193009853,
        0.008993889205157757,
        0.0031277432572096586,
        -0.0304681658744812,
        -0.051045872271060944,
        0.04929265379905701,
        0.030936814844608307,
        -0.05256624147295952,
        -0.033654503524303436,
        0.008185507729649544,
        0.03135889768600464,
        -0.016617005690932274,
        0.010208340361714363,
        -0.006909028626978397,
        -0.0739608108997345,
        0.06400041282176971,
        0.044674985110759735,
        0.016652947291731834,
        -0.05282364413142204,
        -0.00911569595336914,
        -0.05642610043287277,
        0.0715060606598854,
        0.028046851977705956,
        0.04240904375910759,
        -0.009083352982997894,
        0.042585231363773346,
        0.056271784007549286,
        0.011359717696905136,
        0.0703372061252594,
        0.006531529128551483,
        0.008134378120303154,
        0.053020019084215164,
        -0.06440392881631851,
        -0.0723012238740921,
        0.06754988431930542,
        -0.06207786500453949,
        -0.03155555948615074,
        0.044153712689876556,
        -0.0050344085320830345,
        -0.05979152396321297,
        0.016497589647769928,
        -0.05152198299765587,
        -0.05398781970143318,
        0.026869114488363266,
        -0.021432118490338326,
        0.0703495442867279,
        0.06292513012886047,
        -0.02793158032000065,
        -0.03168048709630966,
        0.07486084848642349,
        -0.0010471576824784279,
        0.06605395674705505,
        -0.07529912889003754,
        0.04557487741112709,
        0.032046832144260406,
        -0.008554610423743725,
        -0.0724039301276207,
        -0.04918157309293747,
        -0.006399739999324083,
        -0.06000853702425957,
        -0.022478653118014336,
        -0.07144008576869965,
        -0.03028264082968235,
        -0.06941588222980499,
        -0.03669508919119835,
        0.0701485425233841,
        -0.05635768920183182,
        -0.05310351774096489,
        0.06043390557169914,
        0.03390421345829964,
        0.07378078997135162,
        -0.03847113251686096,
        -0.031110553070902824,
        0.05033533647656441,
        -0.005615634843707085,
        0.017699861899018288,
        -0.030448738485574722,
        0.04284333810210228,
        -0.0542336031794548,
        -0.03140304237604141,
        0.052121277898550034,
        -0.048584822565317154,
        0.05717487633228302,
        -0.04322937875986099,
        -0.03430303931236267,
        0.052067797631025314,
        -0.05449562519788742,
        0.02937372773885727,
        0.004639308899641037,
        -0.01451034564524889,
        -0.04880886524915695,
        -0.05446106195449829,
        -0.06050335243344307,
        0.06816746294498444,
        -0.02936985157430172,
        -0.05311090499162674,
        0.04126466065645218,
        0.02241135574877262,
        0.0007814913988113403,
        0.056028641760349274,
        -0.02605975978076458,
        0.055688582360744476,
        0.017704913392663002,
        0.057952962815761566,
        0.07087644189596176,
        -0.06702093034982681,
        0.07009726762771606,
        0.05871594324707985,
        -0.05182994529604912,
        0.04609820246696472,
        0.028720660135149956,
        0.04669409990310669,
        -0.04648081958293915,
        0.04791472479701042,
        -0.038370776921510696,
        -0.00254051829688251,
        -0.061810173094272614,
        0.0007300322176888585,
        0.03281703218817711,
        -0.07418373227119446,
        0.01899120956659317,
        -0.04615798220038414,
        -0.03167644143104553,
        0.01543163601309061,
        -0.024705415591597557,
        -0.050086796283721924,
        0.048990827053785324,
        -0.049643803387880325,
        0.027705814689397812,
        -0.0475911982357502,
        -0.06427455693483353,
        0.03386624529957771,
        0.033830516040325165,
        0.027095802128314972,
        0.047498270869255066,
        -0.000018133936464437284,
        -0.0444527231156826,
        -0.02487788535654545,
        -0.020977789536118507,
        0.009968439117074013,
        -0.01907256245613098,
        -0.003340221242979169,
        -0.04442749172449112,
        -0.06793387234210968,
        0.06860239803791046,
        0.013822120614349842,
        -0.020086098462343216,
        0.06920319050550461,
        -0.03230356425046921,
        0.029073305428028107,
        0.045793574303388596,
        0.06394479423761368,
        -0.07132925093173981,
        -0.038497861474752426,
        0.07555805891752243,
        0.013907345943152905,
        -0.016223572194576263,
        0.050534430891275406,
        -0.02038666233420372,
        0.0709836333990097,
        -0.021925106644630432,
        0.010071861557662487,
        -0.060725025832653046,
        -0.0277544017881155,
        -0.057715073227882385,
        0.06447015702724457,
        -0.03837152197957039,
        -0.004598443396389484,
        0.004099578596651554,
        -0.07411997020244598,
        0.06283365935087204,
        0.02585243433713913,
        -0.06057572364807129,
        0.009370368905365467,
        0.03335527703166008,
        -0.0436517670750618,
        0.06613138318061829,
        -0.025389065966010094,
        0.03518573194742203,
        -0.07113677263259888,
        0.0003924285701941699,
        -0.027334896847605705,
        0.024120135232806206,
        0.0691259577870369,
        0.01954001560807228,
        0.042719706892967224,
        -0.017065661028027534,
        0.01312037743628025,
        0.002013909863308072,
        0.036815136671066284,
        0.0031850587110966444,
        -0.02539503015577793,
        -0.07574743777513504,
        0.05856964737176895,
        0.05013985186815262,
        0.016969772055745125,
        -0.010317374020814896,
        -0.07450603693723679,
        -0.05303794890642166,
        -0.04965851828455925,
        -0.06141030788421631,
        -0.02102646976709366,
        0.017270751297473907,
        0.037600092589855194,
        -0.046020571142435074,
        0.067679263651371,
        0.057776693254709244,
        0.036882977932691574,
        0.014140786603093147,
        -0.06211404129862785,
        -0.013138535432517529,
        0.06522604823112488,
        0.0683259516954422,
        -0.018820753321051598,
        -0.02671690285205841,
        0.050998177379369736,
        0.03203932195901871,
        0.0051281629130244255,
        0.046558935195207596,
        0.024487582966685295,
        -0.0031503525096923113,
        -0.07032999396324158,
        0.043927088379859924,
        -0.024475019425153732,
        0.06604824960231781,
        -0.049024730920791626,
        0.016772259026765823,
        0.05576670914888382,
        0.04306013882160187,
        0.05420339107513428,
        -0.04388458654284477,
        -0.03744897618889809,
        -0.030434636399149895,
        -0.03442596271634102,
        -0.017263265326619148,
        0.04763609543442726,
        0.020171724259853363,
        -0.021881142631173134,
        0.004815335385501385,
        -0.053873319178819656,
        0.04829227924346924,
        -0.012403443455696106,
        -0.06866881251335144,
        0.00034132032305933535,
        0.04886835813522339,
        -0.042571939527988434,
        -0.05963071808218956,
        -0.03812204301357269,
        0.0423571951687336,
        -0.0042956131510436535,
        0.04362105205655098,
        0.038958724588155746,
        0.04899754002690315,
        0.04821307584643364,
        0.020314443856477737,
        0.017807474359869957,
        0.07229877263307571,
        -0.020980196073651314,
        0.06250425428152084,
        -0.02331574074923992,
        -0.034369565546512604,
        -0.007152948062866926,
        0.029989536851644516,
        -0.003901024116203189,
        0.029102927073836327,
        -0.0579761266708374,
        0.04392305761575699,
        0.002630814677104354,
        -0.04960596188902855,
        0.03588876873254776,
        -0.004979680757969618,
        -0.011918324045836926,
        -0.07343266904354095,
        -0.01035778783261776,
        0.04673728719353676,
        0.07362234592437744,
        -0.0480654202401638,
        0.059466298669576645,
        -0.054662417620420456,
        -0.06745780259370804,
        0.04164331033825874,
        0.060870103538036346,
        0.024480488151311874,
        0.0016678052488714457,
        -0.057170163840055466,
        -0.04078639671206474,
        0.033133428543806076,
        0.011842138133943081,
        -0.033227674663066864,
        -0.0356757827103138,
        -0.025400353595614433,
        -0.050435397773981094,
        0.001612515770830214,
        0.022145327180624008,
        0.02086305245757103,
        0.03752409666776657,
        0.03225613757967949,
        0.050009287893772125,
        -0.061775799840688705,
        -0.007292077876627445,
        -0.07093974947929382,
        0.021761413663625717,
        0.027417659759521484,
        0.01502927951514721,
        0.04054463654756546,
        -0.00260200840421021,
        -0.010831044055521488,
        0.04525306820869446,
        0.06555996090173721,
        0.0676865354180336,
        -0.04547443240880966,
        -0.05151272937655449,
        -0.004882580600678921,
        0.008746800944209099,
        -0.01964864507317543,
        -0.04096098616719246,
        0.06379708647727966,
        0.0432744100689888,
        -0.05407697707414627,
        -0.07121213525533676,
        0.0503767691552639,
        0.07598593086004257
    ],
    [
        -0.03966343402862549,
        -0.0012392682256177068,
        0.0029740682803094387,
        0.03174768015742302,
        0.021446023136377335,
        -0.01612868346273899,
        0.05041591823101044,
        -0.053888898342847824,
        0.045461904257535934,
        0.03931523114442825,
        -0.0005162535235285759,
        0.05507072061300278,
        0.00692563783377409,
        -0.019383445382118225,
        -0.06841666996479034,
        0.044345635920763016,
        -0.03145795688033104,
        0.06326932460069656,
        -0.048176996409893036,
        -0.037410590797662735,
        -0.025782518088817596,
        0.04356328397989273,
        0.03777827322483063,
        -0.05696438252925873,
        0.04683896526694298,
        0.02507399581372738,
        0.05925961956381798,
        -0.027776392176747322,
        0.009136448614299297,
        -0.0023255255073308945,
        0.06793326139450073,
        -0.025917289778590202,
        -0.017936477437615395,
        -0.0625055655837059,
        0.04538338631391525,
        0.0008513854118064046,
        -0.06676448881626129,
        -0.049813657999038696,
        -0.040857069194316864,
        -0.014112705364823341,
        0.02089625969529152,
        0.03798266127705574,
        0.04227593541145325,
        -0.06709206104278564,
        0.03543209284543991,
        0.06798411160707474,
        0.028903525322675705,
        0.03730790317058563,
        0.05725608766078949,
        0.039148133248090744,
        0.051295626908540726,
        0.06631980091333389,
        0.010997544042766094,
        0.04540363326668739,
        0.01746608316898346,
        -0.051381777971982956,
        -0.03597595915198326,
        0.008832727558910847,
        0.016058623790740967,
        -0.0016114001628011465,
        -0.06650329381227493,
        0.02225935459136963,
        -0.03886745870113373,
        0.0684020146727562,
        -0.04291431978344917,
        -0.028896834701299667,
        0.04539448395371437,
        -0.0013434115098789334,
        -0.05915660783648491,
        -0.06743701547384262,
        -0.05996612831950188,
        -0.07298506796360016,
        0.06202995404601097,
        -0.02851014956831932,
        -0.003699990687891841,
        0.005564739927649498,
        0.03580912947654724,
        0.07067814469337463,
        0.015553854405879974,
        -0.03228260204195976,
        -0.015964923426508904,
        0.06846437603235245,
        -0.05525554344058037,
        0.03423088416457176,
        0.014012075029313564,
        0.035784780979156494,
        0.05504150688648224,
        -0.05573951452970505,
        0.04235554859042168,
        0.0686563104391098,
        0.01119527593255043,
        -0.018276477232575417,
        -0.06378951668739319,
        -0.029024982824921608,
        0.007420525886118412,
        0.023912737146019936,
        -0.0221168901771307,
        -0.06489831954240799,
        -0.04118053615093231,
        0.015161361545324326,
        -0.06501777470111847,
        0.03538838028907776,
        0.07229787111282349,
        0.04400098696351051,
        0.0414714552462101,
        -0.03645964711904526,
        -0.02332845889031887,
        0.028589246794581413,
        -0.014001439325511456,
        0.046409036964178085,
        -0.04311433807015419,
        -0.0507664680480957,
        -0.06280365586280823,
        -0.0076885218732059,
        0.025430861860513687,
        -0.05975252017378807,
        0.037550944834947586,
        -0.025316132232546806,
        0.06107274815440178,
        0.06356123089790344,
        -0.009502719156444073,
        0.06560060381889343,
        -0.04542602226138115,
        0.048056673258543015,
        -0.0667957216501236,
        -0.019211286678910255,
        -0.03825564309954643,
        -0.03393952548503876,
        0.06370056420564651,
        0.032956309616565704,
        0.008383054286241531,
        -0.05455254763364792,
        -0.02380722388625145,
        0.0060269576497375965,
        -0.061978694051504135,
        -0.061700593680143356,
        0.023490339517593384,
        0.026719955727458,
        -0.05937039107084274,
        0.0623517781496048,
        0.06834150105714798,
        -0.012331238947808743,
        0.055596593767404556,
        -0.01599314622581005,
        0.05345416069030762,
        -0.003328853752464056,
        0.060416437685489655,
        -0.03031710721552372,
        -0.01142455730587244,
        0.05793416500091553,
        0.05762554705142975,
        -0.06998882442712784,
        -0.032924678176641464,
        -0.04530639573931694,
        -0.0027524682227522135,
        -0.04233114793896675,
        0.05557320639491081,
        0.008410590700805187,
        -0.016341466456651688,
        0.015539433807134628,
        0.06563480198383331,
        0.049413248896598816,
        0.05481056869029999,
        0.029905973002314568,
        0.0064039756543934345,
        0.015290585346519947,
        -0.06080140918493271,
        -0.011758481152355671,
        0.03752731904387474,
        0.036728259176015854,
        -0.04608840495347977,
        0.037264633923769,
        -0.0019432107219472528,
        -0.04339471086859703,
        0.012407613918185234,
        0.0665602833032608,
        -0.07054289430379868,
        0.045800626277923584,
        0.041078586131334305,
        -0.05084128677845001,
        0.04488053545355797,
        0.07137572765350342,
        -0.0003009269421454519,
        0.05650060996413231,
        0.051222700625658035,
        0.02329789102077484,
        -0.06815589964389801,
        0.07044633477926254,
        -0.06240396946668625,
        -0.05217224732041359,
        -0.007622644770890474,
        0.025755660608410835,
        -0.018882958218455315,
        -0.016149939969182014,
        -0.06042108312249184,
        0.032822784036397934,
        0.0167388916015625,
        -0.06056370958685875,
        -0.045359548181295395,
        0.016256501898169518,
        0.02785417251288891,
        -0.03541305661201477,
        -0.002699068747460842,
        -0.01684287004172802,
        -0.06993096321821213,
        0.06695466488599777,
        0.04661104455590248,
        0.012095822021365166,
        -0.0550626739859581,
        -0.005649229045957327,
        -0.05225113779306412,
        0.06873313337564468,
        0.009003696963191032,
        0.034424103796482086,
        -0.015928927809000015,
        0.04223811253905296,
        0.04733673110604286,
        -0.0009948103688657284,
        0.06774881482124329,
        0.002660381840541959,
        -0.006105484906584024,
        0.04477051645517349,
        -0.06468779593706131,
        -0.06808185577392578,
        0.07062112540006638,
        -0.06440863013267517,
        -0.033727820962667465,
        0.027734382078051567,
        -0.0006918747094459832,
        -0.060070477426052094,
        0.02636895328760147,
        -0.06304707378149033,
        -0.04855743423104286,
        0.031310323625802994,
        -0.03883570432662964,
        0.06118996441364288,
        0.06422090530395508,
        -0.03155561164021492,
        -0.026359841227531433,
        0.07031481713056564,
        -0.01165646780282259,
        0.06609237194061279,
        -0.0731220617890358,
        0.0492694228887558,
        0.04722875356674194,
        -0.009896887466311455,
        -0.06456989794969559,
        -0.03020464815199375,
        -0.0011600959114730358,
        -0.06442554295063019,
        -0.028758371248841286,
        -0.06979726254940033,
        -0.031266313046216965,
        -0.06753189861774445,
        -0.027163028717041016,
        0.06457994133234024,
        -0.052995774894952774,
        -0.04993070662021637,
        0.060857854783535004,
        0.04431695118546486,
        0.07057477533817291,
        -0.029263827949762344,
        -0.02398274652659893,
        0.059852372854948044,
        -0.009190719574689865,
        0.02329276129603386,
        -0.0051405406557023525,
        0.04669881612062454,
        -0.04931795224547386,
        -0.03471274673938751,
        0.043782174587249756,
        -0.06037917733192444,
        0.06435868889093399,
        -0.044123195111751556,
        -0.01757046766579151,
        0.0393519401550293,
        -0.05679353326559067,
        0.024206550791859627,
        -0.001226416090503335,
        -0.015419208444654942,
        -0.06373591721057892,
        -0.044134847819805145,
        -0.06408939510583878,
        0.06427396088838577,
        0.011453069746494293,
        -0.05374312773346901,
        0.048626601696014404,
        0.011491481214761734,
        0.015636611729860306,
        0.052880287170410156,
        -0.04505942761898041,
        0.050876252353191376,
        -0.0009061041637323797,
        0.05014302581548691,
        0.06933827698230743,
        -0.06359365582466125,
        0.06764262169599533,
        0.06543739885091782,
        -0.041185036301612854,
        0.043892115354537964,
        0.0382651649415493,
        0.04096028953790665,
        -0.04159320890903473,
        0.045762140303850174,
        -0.026737863197922707,
        -0.012532802298665047,
        -0.05625836178660393,
        0.005506115034222603,
        0.034410178661346436,
        -0.07026549428701401,
        0.021966084837913513,
        -0.05730821564793587,
        -0.027926279231905937,
        0.004779774229973555,
        -0.016097189858555794,
        -0.05327843129634857,
        0.047203123569488525,
        -0.04648230969905853,
        0.028296565636992455,
        -0.05927925556898117,
        -0.05564641207456589,
        0.03267177939414978,
        0.03360266238451004,
        0.03767869994044304,
        0.04610317945480347,
        0.002176770241931081,
        -0.03774179518222809,
        -0.008590304292738438,
        -0.009460815228521824,
        0.01996082253754139,
        -0.013693089596927166,
        0.016873296350240707,
        -0.024251094087958336,
        -0.06301693618297577,
        0.06624523550271988,
        0.034460052847862244,
        -0.04170042648911476,
        0.06259486079216003,
        -0.02316397987306118,
        0.024805186316370964,
        0.04317208006978035,
        0.0601314939558506,
        -0.06495974212884903,
        -0.05339192971587181,
        0.07241510599851608,
        0.003360902424901724,
        -0.008549432270228863,
        0.05159692093729973,
        -0.026537533849477768,
        0.06377574801445007,
        -0.014276844449341297,
        0.014727681875228882,
        -0.06484322249889374,
        -0.05850353091955185,
        -0.0598793588578701,
        0.0643683597445488,
        -0.04277456924319267,
        -0.020114723592996597,
        0.01389734074473381,
        -0.07249511778354645,
        0.06092267110943794,
        0.0344812273979187,
        -0.04413129389286041,
        0.020222965627908707,
        0.031188204884529114,
        -0.04802488163113594,
        0.07179111242294312,
        -0.025728752836585045,
        0.05780122056603432,
        -0.07034919410943985,
        -0.02098420076072216,
        -0.03977537155151367,
        0.04305227845907211,
        0.06426467001438141,
        0.03944183886051178,
        0.04082252085208893,
        -0.02481827139854431,
        0.026342028751969337,
        0.013319474644958973,
        0.04336074739694595,
        -0.005708834156394005,
        -0.02766353450715542,
        -0.07264528423547745,
        0.042681735008955,
        0.03701126202940941,
        0.007828110828995705,
        -0.006318105850368738,
        -0.06854362040758133,
        -0.050961706787347794,
        -0.041544537991285324,
        -0.06797557324171066,
        -0.016630856320261955,
        0.012843644246459007,
        0.03684890270233154,
        -0.03529276326298714,
        0.06703753769397736,
        0.056672386825084686,
        0.017272818833589554,
        0.022067755460739136,
        -0.0654110237956047,
        -0.02846767194569111,
        0.06563898175954819,
        0.06788555532693863,
        -0.022927559912204742,
        -0.03574349358677864,
        0.047603707760572433,
        0.0364898182451725,
        0.0021320986561477184,
        0.04330437630414963,
        0.015049821697175503,
        0.019806072115898132,
        -0.06531956791877747,
        0.04782046005129814,
        0.009268000721931458,
        0.06295274198055267,
        -0.04546324908733368,
        0.01499115489423275,
        0.05550523102283478,
        0.03336191549897194,
        0.054466139525175095,
        -0.026699861511588097,
        -0.0168232973664999,
        -0.029283180832862854,
        -0.04338695853948593,
        0.022380029782652855,
        0.024884585291147232,
        0.011612064205110073,
        0.002999186748638749,
        -0.00009038511052494869,
        -0.05078573897480965,
        0.056838374584913254,
        -0.017564447596669197,
        -0.06954941153526306,
        -0.01946835219860077,
        0.03538956120610237,
        -0.05124633386731148,
        -0.041335128247737885,
        -0.04919708892703056,
        0.05875328555703163,
        0.008837088011205196,
        0.04564160481095314,
        0.04936867207288742,
        0.050225600600242615,
        0.05681348219513893,
        0.0228893980383873,
        0.015117914415895939,
        0.06972677260637283,
        -0.030489793047308922,
        0.06338498741388321,
        -0.018266621977090836,
        -0.03769591823220253,
        0.0035912608727812767,
        0.05416968837380409,
        0.007967287674546242,
        0.030236421152949333,
        -0.05519162490963936,
        0.038064535707235336,
        -0.0067382995039224625,
        -0.04989999905228615,
        0.05903496965765953,
        -0.008190960623323917,
        -0.018441583961248398,
        -0.07064938545227051,
        0.0006815375527366996,
        0.05893448367714882,
        0.07180388271808624,
        -0.052459683269262314,
        0.06164892390370369,
        -0.06196652352809906,
        -0.06935728341341019,
        0.048060618340969086,
        0.06640495359897614,
        0.03161922097206116,
        0.02231554500758648,
        -0.06621365249156952,
        -0.025193868204951286,
        0.010986827313899994,
        0.018381362780928612,
        -0.04130695015192032,
        -0.031188422814011574,
        -0.04232502356171608,
        -0.05228926241397858,
        -0.007445210125297308,
        0.021591627970337868,
        0.018156081438064575,
        0.04695269092917442,
        0.03574002534151077,
        0.04164911434054375,
        -0.053026553243398666,
        -0.02547980472445488,
        -0.06707147508859634,
        0.0057229045778512955,
        0.03173251077532768,
        0.004331863950937986,
        0.05253223702311516,
        0.00025853069382719696,
        -0.02146451734006405,
        0.06162092834711075,
        0.06287730485200882,
        0.06726136803627014,
        -0.04614093899726868,
        -0.0438656285405159,
        -0.009540226310491562,
        0.0017197553534060717,
        0.004430476576089859,
        -0.048541683703660965,
        0.06831524521112442,
        0.02575836516916752,
        -0.03937482461333275,
        -0.06842972338199615,
        0.050323840230703354,
        0.07176998257637024
    ],
    [
        -0.04416500777006149,
        0.0374644435942173,
        0.025795750319957733,
        0.04332621023058891,
        0.04124879464507103,
        -0.024642275646328926,
        0.046792030334472656,
        -0.011609777808189392,
        0.04917183890938759,
        -0.004530598875135183,
        0.04014476761221886,
        0.02065807208418846,
        -0.03294208645820618,
        -0.03367304429411888,
        -0.0688987523317337,
        0.07112904638051987,
        -0.04660664498806,
        0.04726383090019226,
        -0.06100507080554962,
        -0.03696813806891441,
        -0.02015397883951664,
        0.04784723371267319,
        0.029655173420906067,
        -0.0524163581430912,
        0.03778301179409027,
        0.011107081547379494,
        0.06429769843816757,
        -0.05702422931790352,
        0.019229993224143982,
        0.016911927610635757,
        0.022836806252598763,
        -0.015101614408195019,
        -0.047414880245923996,
        -0.07083508372306824,
        0.01642170548439026,
        -0.012343397364020348,
        -0.06430819630622864,
        -0.05598562955856323,
        -0.06280349940061569,
        -0.02215566113591194,
        0.03141508623957634,
        0.0272524394094944,
        0.04652170464396477,
        -0.06749163568019867,
        0.0342068076133728,
        0.05046909675002098,
        0.04610532894730568,
        0.008683368563652039,
        0.05936320498585701,
        0.026704847812652588,
        0.046872541308403015,
        0.05981172248721123,
        -0.005418244283646345,
        0.04618802294135094,
        0.018969710916280746,
        -0.059242796152830124,
        0.034680698066949844,
        -0.0028186622075736523,
        0.034270476549863815,
        0.019333306699991226,
        -0.06663957983255386,
        0.03138634189963341,
        -0.03867383301258087,
        0.0689539685845375,
        -0.06358823925256729,
        -0.0006323637790046632,
        0.04726390540599823,
        0.009269609116017818,
        -0.06933461129665375,
        -0.04595022648572922,
        -0.05141825973987579,
        -0.07184338569641113,
        0.052740879356861115,
        0.028435146436095238,
        -0.03250393643975258,
        -0.006088969297707081,
        0.00980419758707285,
        0.07240782678127289,
        -0.0017226818017661572,
        -0.04200964793562889,
        -0.028557872399687767,
        0.07084771990776062,
        -0.0533582977950573,
        0.0622837170958519,
        0.05605543404817581,
        0.0016325297765433788,
        0.06797464936971664,
        -0.043687816709280014,
        0.06067531183362007,
        0.07098818570375443,
        0.02605251409113407,
        -0.020308343693614006,
        -0.07003454118967056,
        -0.0009604266379028559,
        -0.0199732668697834,
        -0.033036500215530396,
        -0.025493519380688667,
        -0.07045834511518478,
        -0.023441117256879807,
        -0.038191914558410645,
        -0.05991600453853607,
        0.0014222965110093355,
        0.07038485258817673,
        0.05199301242828369,
        0.063331238925457,
        -0.06280393898487091,
        -0.033733587712049484,
        0.0358843132853508,
        -0.004892343655228615,
        0.030981706455349922,
        -0.06517612189054489,
        -0.018268100917339325,
        -0.06034107133746147,
        0.009178251959383488,
        0.0025765160098671913,
        -0.0033844471909105778,
        0.04723349213600159,
        -0.024922015145421028,
        0.06832047551870346,
        0.06580895185470581,
        -0.031013399362564087,
        0.06489713490009308,
        0.02100062370300293,
        0.03556676208972931,
        -0.06667708605527878,
        -0.0441775880753994,
        0.01914374716579914,
        -0.045103173702955246,
        0.06595317274332047,
        0.0020106532610952854,
        0.04606705158948898,
        -0.05585787072777748,
        -0.06436038017272949,
        0.026141762733459473,
        -0.06387142837047577,
        -0.036074861884117126,
        0.013875581324100494,
        0.05746243894100189,
        -0.06392187625169754,
        0.06032494455575943,
        0.06601216644048691,
        -0.0015202286886051297,
        0.050317805260419846,
        -0.007207648828625679,
        0.05982092022895813,
        0.023792773485183716,
        0.06621496379375458,
        -0.06435607373714447,
        -0.002444432582706213,
        0.0676601454615593,
        0.04061887785792351,
        -0.06968679279088974,
        -0.024449605494737625,
        -0.03233950957655907,
        -0.008674179203808308,
        -0.03598889708518982,
        0.05367147549986839,
        0.04614481329917908,
        0.008847996592521667,
        0.03653764724731445,
        0.05575484782457352,
        0.030809104442596436,
        0.047158315777778625,
        -0.021294506266713142,
        0.010597903281450272,
        0.038157492876052856,
        -0.0625142976641655,
        -0.011075152084231377,
        0.04734518751502037,
        0.02727636881172657,
        0.008031711913645267,
        0.06360230594873428,
        0.018035579472780228,
        -0.03892473503947258,
        -0.019987909123301506,
        0.06536610424518585,
        -0.07246638089418411,
        -0.0006137671880424023,
        0.06011827290058136,
        -0.05372829735279083,
        0.033846091479063034,
        0.07311632484197617,
        0.00835050456225872,
        0.05712731182575226,
        -0.0014171568909659982,
        0.0022268653847277164,
        -0.057516418397426605,
        0.0688512846827507,
        -0.05225652456283569,
        -0.04369067773222923,
        0.0065379273146390915,
        0.01234589982777834,
        -0.012309566140174866,
        -0.041232697665691376,
        -0.03300248086452484,
        0.02605925314128399,
        -0.012245850637555122,
        -0.036293189972639084,
        -0.03986145555973053,
        0.0001446726091671735,
        -0.010899212211370468,
        -0.0035705198533833027,
        -0.023324761539697647,
        -0.031194359064102173,
        -0.06093223765492439,
        0.06379257887601852,
        0.018570121377706528,
        0.0088759521022439,
        -0.03317112475633621,
        0.02690782956779003,
        -0.0633854791522026,
        0.028864331543445587,
        -0.011820376850664616,
        0.002401765901595354,
        -0.007504299283027649,
        0.052181798964738846,
        0.055656515061855316,
        -0.02279973030090332,
        0.06709464639425278,
        -0.024056755006313324,
        0.01678340695798397,
        0.0625699833035469,
        -0.05597732216119766,
        -0.06884197890758514,
        0.053731825202703476,
        0.005270359106361866,
        0.005668672267347574,
        0.025822412222623825,
        -0.022109035402536392,
        -0.03317951038479805,
        0.04765786975622177,
        -0.06065095588564873,
        -0.035751983523368835,
        0.04708760976791382,
        -0.022405222058296204,
        0.041735269129276276,
        0.0650000050663948,
        -0.056123871356248856,
        -0.0320093147456646,
        0.05839075893163681,
        0.02424328587949276,
        0.07082977890968323,
        -0.0719171017408371,
        0.014399479143321514,
        0.024081552401185036,
        -0.035303596407175064,
        -0.06426183879375458,
        -0.02707757242023945,
        -0.011993344873189926,
        -0.025369269773364067,
        -0.01753801293671131,
        -0.07028761506080627,
        -0.020138848572969437,
        -0.067531518638134,
        -0.05054658278822899,
        0.06828735023736954,
        -0.06105048954486847,
        -0.037857647985219955,
        0.0654790848493576,
        0.03760496899485588,
        0.07382960617542267,
        0.020835960283875465,
        0.0033035832457244396,
        0.01051604375243187,
        -0.049265358597040176,
        -0.009657329879701138,
        0.027765953913331032,
        0.050792910158634186,
        0.02181323990225792,
        0.03377743810415268,
        0.05655398219823837,
        -0.06874115020036697,
        0.060685403645038605,
        -0.04174494743347168,
        -0.030463727191090584,
        0.016718264669179916,
        -0.06252266466617584,
        0.03286083787679672,
        -0.004411179106682539,
        -0.06090187281370163,
        -0.046761076897382736,
        -0.037729647010564804,
        -0.06344486773014069,
        0.06042380630970001,
        -0.01026864256709814,
        -0.05392669513821602,
        0.03354028984904289,
        0.019582543522119522,
        -0.010245637968182564,
        0.03606681153178215,
        0.013762034475803375,
        0.06864307075738907,
        0.0061729419976472855,
        0.027638182044029236,
        0.06664274632930756,
        -0.055877186357975006,
        0.053365036845207214,
        0.06671901047229767,
        -0.05511542409658432,
        0.05494236946105957,
        0.05020834878087044,
        0.04791565611958504,
        -0.0017827233532443643,
        0.012665661051869392,
        -0.0316401906311512,
        0.009623161517083645,
        -0.05684218183159828,
        -0.0048968917690217495,
        0.026356955990195274,
        -0.06884128600358963,
        0.052904389798641205,
        -0.059381768107414246,
        -0.05813414603471756,
        0.02649439312517643,
        -0.06402354687452316,
        -0.0595569983124733,
        0.04223177954554558,
        -0.06639762222766876,
        0.02154707722365856,
        -0.05611908435821533,
        -0.05781349539756775,
        0.007187606301158667,
        0.048158083111047745,
        0.014194529503583908,
        0.056432049721479416,
        -0.02336149662733078,
        -0.016085166484117508,
        0.040196869522333145,
        0.004696717020124197,
        -0.04356007277965546,
        0.03212064504623413,
        -0.011425294913351536,
        -0.021382195875048637,
        -0.06703396886587143,
        0.06347614526748657,
        -0.022511988878250122,
        -0.02710116282105446,
        0.05783550813794136,
        -0.033487193286418915,
        0.05100193992257118,
        0.027456574141979218,
        0.05993044748902321,
        -0.07380673289299011,
        -0.004001345485448837,
        0.06990526616573334,
        -0.005731705576181412,
        0.011136350221931934,
        0.051955610513687134,
        -0.05115966871380806,
        0.055239010602235794,
        -0.02558043785393238,
        0.011892992071807384,
        -0.05627823993563652,
        -0.05786018818616867,
        -0.0330994687974453,
        0.06284556537866592,
        -0.05654100701212883,
        -0.018709108233451843,
        0.04013573378324509,
        -0.0647987574338913,
        0.059285279363393784,
        0.027385970577597618,
        0.02044866234064102,
        -0.0041490038856863976,
        0.001181195373646915,
        -0.04697955772280693,
        0.06951231509447098,
        0.024342307820916176,
        0.034980133175849915,
        -0.06413073092699051,
        -0.04832342267036438,
        -0.05139505863189697,
        -0.006711479276418686,
        0.06308382004499435,
        0.024950899183750153,
        0.04710298776626587,
        -0.049634017050266266,
        0.05727767571806908,
        0.013986887410283089,
        -0.024759456515312195,
        0.015849804505705833,
        -0.0448836050927639,
        -0.06818006187677383,
        0.020506247878074646,
        0.0698794573545456,
        -0.03175598382949829,
        -0.014345228672027588,
        -0.06343980133533478,
        -0.018062740564346313,
        -0.06601537019014359,
        -0.06971123069524765,
        0.000862281711306423,
        0.026204155758023262,
        0.01431566197425127,
        -0.013549664989113808,
        0.06773938238620758,
        0.06165526434779167,
        0.01867689937353134,
        0.027548309415578842,
        -0.07213450223207474,
        -0.053480274975299835,
        0.05974702537059784,
        0.0681171789765358,
        -0.05305321887135506,
        -0.03229576721787453,
        0.06482287496328354,
        0.009859233163297176,
        -0.054488297551870346,
        0.05853002145886421,
        -0.039718806743621826,
        -0.030850451439619064,
        -0.06370186805725098,
        0.05725378915667534,
        0.044404663145542145,
        0.06308858841657639,
        -0.030464529991149902,
        -0.0051887002773582935,
        0.007823326624929905,
        0.0267088133841753,
        0.011815384961664677,
        -0.04632032662630081,
        0.03229758143424988,
        0.020110098645091057,
        -0.06317803263664246,
        0.012723785825073719,
        0.019669631496071815,
        -0.05124594271183014,
        0.0029682728927582502,
        -0.03940710425376892,
        -0.042448367923498154,
        0.06487982720136642,
        -0.03074256144464016,
        -0.06514834612607956,
        0.011597863398492336,
        -0.022567516192793846,
        -0.0487867072224617,
        -0.02537650242447853,
        0.014562212862074375,
        -0.004012946039438248,
        0.012982107698917389,
        0.06572074443101883,
        0.035987891256809235,
        0.06130288168787956,
        0.03675950691103935,
        0.042304627597332,
        0.0167863667011261,
        0.06833802163600922,
        -0.049660246819257736,
        0.057315435260534286,
        -0.005294932518154383,
        -0.0671347975730896,
        0.01722307875752449,
        -0.005512980744242668,
        -0.0030512569937855005,
        -0.038532186299562454,
        -0.053422488272190094,
        0.03992665186524391,
        0.04118786007165909,
        -0.04623986408114433,
        0.04281424731016159,
        -0.04189702123403549,
        0.030753277242183685,
        -0.07038819044828415,
        0.04284906014800072,
        0.04024362936615944,
        0.07166635990142822,
        -0.025153525173664093,
        0.046295538544654846,
        -0.051870133727788925,
        -0.07194928824901581,
        0.017355002462863922,
        0.05124060437083244,
        0.016971666365861893,
        0.005522036924958229,
        -0.06768293678760529,
        -0.013345572166144848,
        0.061910632997751236,
        0.04134193807840347,
        -0.007908214814960957,
        -0.012571295723319054,
        -0.048545315861701965,
        -0.038070227950811386,
        0.009927142411470413,
        -0.019513582810759544,
        0.062000956386327744,
        0.014101287350058556,
        0.029062146320939064,
        0.022280490025877953,
        -0.041761670261621475,
        -0.035648196935653687,
        -0.0683634802699089,
        -0.017631256952881813,
        -0.01200785767287016,
        0.04853755608201027,
        0.0475863516330719,
        0.007033180445432663,
        0.009875484742224216,
        0.00949624739587307,
        0.055141910910606384,
        0.07128897309303284,
        -0.06803110986948013,
        -0.008433329872786999,
        -0.03660775348544121,
        0.021046236157417297,
        0.04179736226797104,
        -0.024799508973956108,
        0.0714457556605339,
        0.019716359674930573,
        -0.05497302487492561,
        -0.07211556285619736,
        0.04924602061510086,
        0.07293248921632767
    ],
    [
        -0.023873168975114822,
        0.013974270783364773,
        0.04122544825077057,
        0.026846442371606827,
        0.06457685679197311,
        -0.033094312995672226,
        0.053079281002283096,
        -0.0007383977645076811,
        0.010107983835041523,
        0.0483415350317955,
        0.03864561393857002,
        0.039868567138910294,
        -0.04267849028110504,
        -0.03747596591711044,
        -0.062084734439849854,
        0.05807945504784584,
        -0.06584824621677399,
        0.009079007431864738,
        -0.06866241991519928,
        -0.054307419806718826,
        -0.037591926753520966,
        0.02470039576292038,
        -0.0006814271328039467,
        -1.2200435151044076e-7,
        0.03941269591450691,
        0.05719154328107834,
        0.05503854528069496,
        -0.013297036290168762,
        0.01105675008147955,
        -0.008020858280360699,
        0.04191101714968681,
        -0.042072001844644547,
        -0.026657553389668465,
        -0.008908061310648918,
        -0.029843997210264206,
        0.007662306539714336,
        -0.051716141402721405,
        -0.02768274024128914,
        -0.03738360479474068,
        0.038064613938331604,
        0.028716150671243668,
        0.004099704325199127,
        0.0520998053252697,
        -0.06516887247562408,
        0.03368008881807327,
        0.05709739774465561,
        0.04388826712965965,
        0.06329946964979172,
        0.03715015575289726,
        -0.016287408769130707,
        0.010227921418845654,
        0.048060160130262375,
        0.056429069489240646,
        0.037021487951278687,
        -0.04244653508067131,
        -0.024355053901672363,
        -0.03672675043344498,
        0.00710680428892374,
        0.019920481368899345,
        0.01701604388654232,
        -0.06396075338125229,
        0.003588818246498704,
        -0.04677929729223251,
        0.022577965632081032,
        -0.04874805733561516,
        0.025395849719643593,
        0.0698496624827385,
        -0.016252826899290085,
        -0.05461671203374863,
        -0.07212378084659576,
        -0.0674205794930458,
        -0.06239305064082146,
        0.06055678054690361,
        0.051021166145801544,
        -0.05677391216158867,
        0.026748912408947945,
        0.029516639187932014,
        0.06822600960731506,
        0.03371074050664902,
        -0.047051284462213516,
        -0.012271705083549023,
        0.07207070291042328,
        -0.0564969964325428,
        0.033693645149469376,
        -0.02508946694433689,
        -0.009459332562983036,
        0.042705975472927094,
        -0.00544892018660903,
        -0.0008597192936576903,
        0.07021176815032959,
        -0.04232390224933624,
        0.0020765168592333794,
        -0.049573514610528946,
        0.0070307906717062,
        0.02510114200413227,
        -0.039706528186798096,
        -0.022816592827439308,
        -0.05530043691396713,
        -0.05308964103460312,
        -0.03425946459174156,
        -0.06903417408466339,
        0.013654151000082493,
        0.07010353356599808,
        0.061125412583351135,
        0.05084807053208351,
        -0.0361417680978775,
        -0.0272610355168581,
        0.01228395663201809,
        0.03321373090147972,
        -0.021094664931297302,
        -0.061721134930849075,
        -0.05647457018494606,
        -0.052125174552202225,
        -0.029939597472548485,
        0.015319585800170898,
        -0.04351456090807915,
        -0.0251667108386755,
        -0.04722387343645096,
        -0.06316499412059784,
        0.05373641848564148,
        -0.02841305546462536,
        0.03890560194849968,
        0.01389266923069954,
        0.05827336385846138,
        -0.07072148472070694,
        -0.027583543211221695,
        -0.0043834964744746685,
        -0.01459889579564333,
        0.05233849212527275,
        -0.013282926753163338,
        0.0037148494739085436,
        -0.06048154458403587,
        -0.05320398136973381,
        0.017964188009500504,
        -0.05069398507475853,
        -0.05002658814191818,
        -0.009049361571669579,
        -0.050002675503492355,
        -0.03672090545296669,
        0.0646306574344635,
        0.04533405229449272,
        -0.013712999410927296,
        0.04417268931865692,
        0.051110491156578064,
        0.03727613016963005,
        0.022561578080058098,
        0.07149800658226013,
        -0.04443869739770889,
        -0.05338463559746742,
        0.05493529140949249,
        -0.03140438720583916,
        -0.06116827204823494,
        -0.05862143263220787,
        -0.04342476278543472,
        -0.0027352834586054087,
        -0.05836692079901695,
        0.055433448404073715,
        0.03382217139005661,
        -0.06082533672451973,
        0.04508253559470177,
        0.0690961629152298,
        0.039432063698768616,
        0.04152886942028999,
        -0.05822337418794632,
        -0.03525412827730179,
        0.0226488895714283,
        -0.05953739583492279,
        -0.05737941712141037,
        -0.0015399691183120012,
        0.04672103375196457,
        0.036022454500198364,
        0.04077163338661194,
        -0.006040816195309162,
        -0.021799515932798386,
        0.004816002212464809,
        0.06261502206325531,
        -0.07175886631011963,
        -0.02486417070031166,
        0.04928617924451828,
        -0.017616501078009605,
        0.05964408814907074,
        0.06919679045677185,
        0.05004093423485756,
        0.023165162652730942,
        0.026861868798732758,
        0.013183072209358215,
        -0.06929534673690796,
        0.06392315775156021,
        -0.049624454230070114,
        -0.05372132733464241,
        0.01705947145819664,
        -0.04231217876076698,
        0.019088847562670708,
        -0.024257110431790352,
        0.0019950559362769127,
        0.002054566750302911,
        0.03297281637787819,
        0.014699305407702923,
        -0.0037626465782523155,
        0.001506349304690957,
        0.005705696996301413,
        0.04275907576084137,
        -0.021538952365517616,
        -0.0008965156157501042,
        -0.047217682003974915,
        0.06591968238353729,
        0.059281520545482635,
        0.0007958728820085526,
        -0.03925464302301407,
        0.035180654376745224,
        -0.05294904112815857,
        0.01869269460439682,
        -0.02704070694744587,
        -0.03274630382657051,
        0.0020431559532880783,
        0.02520359680056572,
        0.04937578737735748,
        0.02703508920967579,
        0.062394607812166214,
        0.008680674247443676,
        0.04806717112660408,
        0.01529014389961958,
        -0.061865340918302536,
        -0.07191430032253265,
        0.0692095085978508,
        -0.005926385056227446,
        0.01642846316099167,
        0.021552449092268944,
        -0.04010239988565445,
        -0.05119585990905762,
        -0.02486272342503071,
        -0.06384824961423874,
        -0.06372775882482529,
        0.03622274100780487,
        -0.037868790328502655,
        0.004850954748690128,
        0.06610167026519775,
        -0.05012165382504463,
        0.035998355597257614,
        0.03673842176795006,
        0.03103742003440857,
        0.06534197926521301,
        -0.05110381543636322,
        0.04810658469796181,
        0.033360764384269714,
        -0.0021273859310895205,
        -0.03639034181833267,
        -0.02224624529480934,
        -0.024256505072116852,
        -0.006974459625780582,
        0.005325714126229286,
        -0.07173142582178116,
        -0.02522759884595871,
        -0.06507066637277603,
        -0.05375533923506737,
        0.0675847977399826,
        -0.001939863315783441,
        -0.024076176807284355,
        0.06874486058950424,
        0.0605887770652771,
        0.0712294802069664,
        0.0454631969332695,
        0.030991243198513985,
        0.015757422894239426,
        -0.06490153819322586,
        -0.04050501808524132,
        0.006700349040329456,
        0.02732827328145504,
        0.00945169199258089,
        -0.03390901908278465,
        -0.012901397421956062,
        -0.07134018838405609,
        0.03168048709630966,
        -0.061457559466362,
        -0.0473269522190094,
        0.034883636981248856,
        -0.06099313497543335,
        -0.031525444239377975,
        0.0007739099091850221,
        -0.06723682582378387,
        -0.04992418363690376,
        -0.03551696985960007,
        0.00632864236831665,
        0.029743336141109467,
        -0.041638411581516266,
        -0.06466298550367355,
        -0.0027598922606557608,
        -0.03370814770460129,
        -0.04235859960317612,
        0.04452408105134964,
        -0.03638025000691414,
        0.06308811902999878,
        0.030595015734434128,
        0.04677855223417282,
        0.051408763974905014,
        -0.057594023644924164,
        0.07002831995487213,
        0.06135985255241394,
        -0.04352271929383278,
        0.0496850349009037,
        0.05227193608880043,
        0.06034742668271065,
        -0.03567512705922127,
        0.016254689544439316,
        -0.054295238107442856,
        -0.03846397623419762,
        -0.05509275570511818,
        -0.02064012549817562,
        0.05040469393134117,
        -0.06815295666456223,
        0.05132359638810158,
        -0.06655203551054001,
        -0.04463361203670502,
        0.06169053167104721,
        -0.06750600039958954,
        -0.056821227073669434,
        0.07119663804769516,
        -0.051283057779073715,
        0.00957575161010027,
        -0.03941357135772705,
        -0.06848200410604477,
        0.010552575811743736,
        0.051552705466747284,
        -0.04202137514948845,
        0.058503858745098114,
        0.00011734035069821402,
        -0.047818467020988464,
        0.05436530336737633,
        -0.013037780299782753,
        -0.04094083607196808,
        -0.025117898359894753,
        -0.006381620652973652,
        -0.05044020339846611,
        -0.06869907677173615,
        0.06542598456144333,
        0.0032682628370821476,
        -0.029219340533018112,
        0.06912311911582947,
        -0.030136603862047195,
        0.0645969957113266,
        0.04524253308773041,
        0.03901444375514984,
        -0.07162832468748093,
        -0.04502982273697853,
        0.07278142869472504,
        0.009163180366158485,
        0.007570306770503521,
        0.04296225309371948,
        -0.06382276117801666,
        0.06979073584079742,
        -0.07213040441274643,
        0.026913389563560486,
        -0.0556059256196022,
        -0.06454205513000488,
        -0.05648383870720863,
        0.0615108385682106,
        0.012308922596275806,
        0.028396693989634514,
        -0.024691401049494743,
        -0.05937691405415535,
        0.06509675830602646,
        0.03351908177137375,
        -0.06052538752555847,
        -0.00731648737564683,
        0.029039381071925163,
        -0.03504861891269684,
        0.07131867110729218,
        -0.00406530499458313,
        0.025852754712104797,
        -0.06867387890815735,
        -0.03435231372714043,
        -0.0557706318795681,
        0.04039347544312477,
        0.028666503727436066,
        -0.0037719649262726307,
        0.040144070982933044,
        -0.05577901378273964,
        0.04372916370630264,
        -0.05294801667332649,
        -0.040675804018974304,
        -0.004566237796097994,
        0.004862870089709759,
        -0.06534294039011002,
        0.04457710310816765,
        0.067887082695961,
        -0.0364132784307003,
        -0.0005125326570123434,
        -0.06970174610614777,
        -0.03862030804157257,
        -0.06404530256986618,
        -0.04345104843378067,
        0.01359131745994091,
        0.040904801338911057,
        0.047249991446733475,
        -0.054860882461071014,
        0.06077505275607109,
        0.06545738875865936,
        -0.049104075878858566,
        0.01652432419359684,
        -0.0694529339671135,
        -0.015261676162481308,
        0.048982225358486176,
        0.0650961846113205,
        -0.013540800660848618,
        -0.000361798593075946,
        0.059975747019052505,
        0.010215521790087223,
        -0.011369694955646992,
        0.04343198612332344,
        -0.04734514281153679,
        0.022834189236164093,
        0.027960600331425667,
        0.04254714399576187,
        0.027444887906312943,
        0.05480038747191429,
        -0.009359965100884438,
        0.026138370856642723,
        0.024382367730140686,
        0.030947625637054443,
        0.010431861504912376,
        -0.0587792843580246,
        -0.03839598223567009,
        -0.03792114183306694,
        -0.02450464479625225,
        -0.018841493874788284,
        0.03258591145277023,
        -0.04930868744850159,
        -0.04215065389871597,
        -0.06722710281610489,
        -0.04312151297926903,
        0.058506205677986145,
        -0.021698882803320885,
        0.02714078687131405,
        0.02974480576813221,
        0.0479721799492836,
        -0.014833192341029644,
        -0.04698516055941582,
        0.029573312029242516,
        -0.018580015748739243,
        -0.028391892090439796,
        0.03919469565153122,
        0.02331487089395523,
        0.04701894894242287,
        0.03442835062742233,
        0.019002456218004227,
        0.047031618654727936,
        0.06642626971006393,
        -0.053403340280056,
        0.06205396354198456,
        -0.06284396350383759,
        -0.05451814830303192,
        0.036016859114170074,
        0.0397702120244503,
        -0.0365220345556736,
        0.008462346158921719,
        -0.02450142800807953,
        0.03916923701763153,
        0.05837155506014824,
        -0.03224358335137367,
        0.04410395398736,
        -0.03330164775252342,
        -0.02136610634624958,
        -0.07004225999116898,
        -0.012300299480557442,
        0.06118785962462425,
        0.07090159505605698,
        -0.07071733474731445,
        0.05276234820485115,
        -0.05847121402621269,
        -0.06964585930109024,
        0.009071375243365765,
        0.019450103864073753,
        0.020516857504844666,
        -0.02661341242492199,
        0.0049696727655828,
        -0.03752546012401581,
        0.032099734991788864,
        0.03499893471598625,
        -0.029151489958167076,
        -0.027848239988088608,
        -0.021618446335196495,
        -0.0267423614859581,
        0.025372836738824844,
        0.0268147774040699,
        0.04680372402071953,
        0.022475916892290115,
        0.030566755682229996,
        -0.041672877967357635,
        -0.057598959654569626,
        -0.014267778024077415,
        -0.06951068341732025,
        -0.016660569235682487,
        -0.05400582402944565,
        0.06444654613733292,
        0.07026074826717377,
        0.0034488216042518616,
        -0.04348606988787651,
        0.046780552715063095,
        0.062463629990816116,
        0.0703972801566124,
        -0.06382330507040024,
        -0.048700302839279175,
        -0.027997015044093132,
        0.013164570555090904,
        0.06856200098991394,
        -0.001737299608066678,
        0.052739374339580536,
        0.012488026171922684,
        -0.07249726355075836,
        -0.06245194002985954,
        0.06785648316144943,
        0.06592477858066559
    ],
    [
        -0.044951677322387695,
        -0.04692433774471283,
        0.05928874388337135,
        0.02718682959675789,
        0.04666934162378311,
        -0.012781442143023014,
        0.06000599265098572,
        -0.043513745069503784,
        -0.03047596849501133,
        0.04169145226478577,
        0.0562959648668766,
        -0.02016104757785797,
        -0.03995667025446892,
        -0.052137572318315506,
        -0.057210471481084824,
        0.04204573109745979,
        -0.057919926941394806,
        0.057312335819005966,
        -0.06113003194332123,
        0.05222296342253685,
        -0.05781799182295799,
        -0.04590458795428276,
        0.055314354598522186,
        0.052128858864307404,
        0.038352712988853455,
        -0.013169174082577229,
        -0.008707878179848194,
        0.011119203642010689,
        -0.024576613679528236,
        -0.0599733367562294,
        0.024096572771668434,
        0.0057074702344834805,
        0.051175009459257126,
        -0.059704702347517014,
        0.0025164205580949783,
        -0.043356794863939285,
        -0.04472442716360092,
        -0.04332710802555084,
        -0.061476998031139374,
        0.04827357828617096,
        -0.019739273935556412,
        -0.01998273842036724,
        0.05642570182681084,
        -0.061104558408260345,
        0.056727997958660126,
        0.05754091963171959,
        0.06087423861026764,
        0.06006370112299919,
        0.04424496740102768,
        -0.04879949986934662,
        0.05253403261303902,
        0.011828332208096981,
        0.055585820227861404,
        -0.043885815888643265,
        -0.03673437610268593,
        -0.014326512813568115,
        -0.0597979910671711,
        -0.0067311362363398075,
        -0.032656364142894745,
        -0.02501825802028179,
        -0.04988032951951027,
        -0.04312385618686676,
        -0.04290974512696266,
        0.015074649825692177,
        -0.01627453789114952,
        0.06010022014379501,
        0.05186941474676132,
        0.010691884905099869,
        0.043690670281648636,
        -0.061469294130802155,
        -0.058999642729759216,
        -0.05885109677910805,
        0.046511296182870865,
        -0.03858577087521553,
        0.049901265650987625,
        -0.012728075496852398,
        0.0461360327899456,
        0.061251427978277206,
        0.04063739627599716,
        -0.03335641697049141,
        0.04039468243718147,
        -0.010844369418919086,
        -0.058279991149902344,
        -0.015062476508319378,
        -0.03673714026808739,
        0.04415462166070938,
        -0.028478069230914116,
        -0.051975712180137634,
        0.05767384544014931,
        0.058885615319013596,
        -0.03310481086373329,
        0.04494684189558029,
        0.056629810482263565,
        -0.057916417717933655,
        -0.037116438150405884,
        0.022318536415696144,
        -0.010771055705845356,
        -0.041805729269981384,
        -0.046588048338890076,
        -0.03672702983021736,
        -0.06105839088559151,
        -0.05002254620194435,
        0.05714533478021622,
        0.037943631410598755,
        0.04924842715263367,
        -0.0031022445764392614,
        -0.034068748354911804,
        0.05108660086989403,
        -0.04101492464542389,
        -0.05041554197669029,
        -0.05339149758219719,
        0.03737811744213104,
        -0.05425141379237175,
        -0.04077603667974472,
        0.06114332005381584,
        0.005535333417356014,
        -0.05878804251551628,
        -0.05218406021595001,
        -0.055046144872903824,
        0.04237385466694832,
        -0.05129667744040489,
        0.01510105561465025,
        -0.05918869748711586,
        -0.02336815372109413,
        -0.06036660820245743,
        -0.029972262680530548,
        -0.0050223092548549175,
        0.0010626214789226651,
        0.0419326052069664,
        -0.0015748845180496573,
        0.05071684718132019,
        -0.05653983727097511,
        -0.04087819159030914,
        0.059639330953359604,
        -0.05862940102815628,
        -0.05560937523841858,
        0.03652367368340492,
        -0.06073795631527901,
        -0.025728771463036537,
        0.052372150123119354,
        0.06133950129151344,
        -0.022549306973814964,
        0.037525031715631485,
        -0.04453121870756149,
        -0.008970278315246105,
        0.041127681732177734,
        0.06126967817544937,
        0.020888550207018852,
        -0.04488743841648102,
        0.055523715913295746,
        -0.02416646108031273,
        -0.061243731528520584,
        -0.041233379393815994,
        -0.051448848098516464,
        0.008513910695910454,
        -0.03515627607703209,
        0.061398379504680634,
        -0.004382686223834753,
        -0.026370856910943985,
        0.05097884684801102,
        0.014652594923973083,
        0.04620245471596718,
        0.044592369347810745,
        -0.05147797241806984,
        -0.05293066054582596,
        0.024073120206594467,
        0.054587122052907944,
        -0.06056149676442146,
        0.005064711906015873,
        0.043953411281108856,
        0.004272061865776777,
        0.032818734645843506,
        -0.057895977050065994,
        0.04798705875873566,
        -0.022769421339035034,
        0.06056523323059082,
        -0.06145315244793892,
        0.0404752716422081,
        0.05895727127790451,
        0.030808398500084877,
        0.032922327518463135,
        -0.04263182729482651,
        0.05803345516324043,
        0.05644381791353226,
        -0.010181433521211147,
        -0.037140198051929474,
        -0.047503676265478134,
        0.05460307002067566,
        0.027740349993109703,
        -0.016028448939323425,
        0.018936047330498695,
        -0.029339220374822617,
        0.0006538473535329103,
        -0.04648770019412041,
        0.023459170013666153,
        -0.004720786586403847,
        0.04598258063197136,
        0.05233656242489815,
        -0.032143477350473404,
        -0.031232228502631187,
        -0.008441535755991936,
        0.05828145518898964,
        0.053917448967695236,
        0.054361168295145035,
        -0.019753675907850266,
        0.03421942517161369,
        0.02443828247487545,
        0.029558705165982246,
        0.0026855228934437037,
        -0.061426855623722076,
        -0.031033985316753387,
        0.061312247067689896,
        -0.029076429083943367,
        0.05056570842862129,
        -0.03007558546960354,
        -0.026624049991369247,
        0.061441823840141296,
        0.0554339773952961,
        0.061460793018341064,
        -0.04734160006046295,
        0.058879539370536804,
        -0.05371876060962677,
        -0.0163131020963192,
        -0.06127259135246277,
        0.033604945987463,
        -0.02752568945288658,
        -0.053302206099033356,
        0.06109101325273514,
        -0.03896008059382439,
        -0.03819413110613823,
        0.0036843386478722095,
        -0.0412759967148304,
        -0.05816780403256416,
        0.04466693475842476,
        -0.022908320650458336,
        0.04957275837659836,
        0.0516837015748024,
        -0.03474672883749008,
        0.042803291231393814,
        0.0554104708135128,
        0.04219847917556763,
        0.027895092964172363,
        0.04622158035635948,
        0.04115879163146019,
        -0.023622557520866394,
        -0.03963638097047806,
        -0.03446472808718681,
        -0.0381365567445755,
        -0.05840999633073807,
        -0.055004701018333435,
        -0.045028846710920334,
        -0.057628083974123,
        -0.0541241392493248,
        -0.05817855894565582,
        -0.005715994164347649,
        0.06077369302511215,
        -0.01918674074113369,
        -0.01618848368525505,
        0.06038816273212433,
        0.05756612867116928,
        0.024270694702863693,
        0.04432561993598938,
        0.04184383153915405,
        0.05284785106778145,
        -0.046592917293310165,
        0.025859050452709198,
        -0.059447139501571655,
        0.014281277544796467,
        0.04413075000047684,
        -0.04061305895447731,
        -0.02621222659945488,
        -0.06065914407372475,
        0.052145183086395264,
        -0.05253016576170921,
        -0.05974123254418373,
        0.044394996017217636,
        -0.060404546558856964,
        0.045638326555490494,
        -0.030988642945885658,
        0.03779727593064308,
        -0.05453234538435936,
        -0.05963607132434845,
        0.00038731491076759994,
        0.055515218526124954,
        -0.053470075130462646,
        -0.059887081384658813,
        -0.0008239923627115786,
        -0.06138746067881584,
        -0.057126522064208984,
        0.02276618964970112,
        -0.021594982594251633,
        0.05673287436366081,
        -0.0062267775647342205,
        0.031997449696063995,
        0.015490910038352013,
        -0.05234966799616814,
        0.06139310821890831,
        -0.019739652052521706,
        -0.04438280686736107,
        0.058189887553453445,
        -0.0352216400206089,
        0.05692377686500549,
        -0.05512450635433197,
        0.028098003938794136,
        0.04159300774335861,
        -0.028767529875040054,
        0.005962460767477751,
        0.057034481316804886,
        0.0495280846953392,
        -0.048426561057567596,
        0.04472513496875763,
        -0.0533331036567688,
        -0.026949765160679817,
        0.06086840108036995,
        -0.06084108352661133,
        -0.041390273720026016,
        0.04815457761287689,
        -0.04771183803677559,
        0.037107910960912704,
        -0.04724154993891716,
        -0.06078337877988815,
        -0.018067289143800735,
        0.04245377331972122,
        -0.061084747314453125,
        0.02863502688705921,
        0.054452329874038696,
        0.032675471156835556,
        -0.04390411451458931,
        0.049999259412288666,
        -0.02101851813495159,
        -0.012201416306197643,
        0.00983726792037487,
        -0.015361412428319454,
        -0.059686895459890366,
        0.059496209025382996,
        -0.05328976735472679,
        0.021846439689397812,
        0.06018367409706116,
        -0.051791198551654816,
        0.02898194082081318,
        0.030351905152201653,
        0.02528528682887554,
        -0.06086008623242378,
        -0.04914636164903641,
        0.06147722899913788,
        0.059297725558280945,
        0.05805204063653946,
        0.04803958907723427,
        0.053994953632354736,
        0.058515746146440506,
        -0.061387572437524796,
        0.04919210448861122,
        -0.0062673441134393215,
        -0.031678684055805206,
        -0.06015092879533768,
        0.057163137942552567,
        -0.060039058327674866,
        -0.04538073390722275,
        -0.029778020456433296,
        -0.05182262137532234,
        0.05467904731631279,
        -0.030556853860616684,
        -0.057520411908626556,
        -0.028168747201561928,
        0.03943668305873871,
        0.015495662577450275,
        0.0609358586370945,
        -0.019374724477529526,
        0.018061675131320953,
        -0.0609595961868763,
        -0.05159905552864075,
        -0.059621792286634445,
        0.0486300066113472,
        -0.03085234761238098,
        0.05304728075861931,
        0.05985085666179657,
        -0.010753590613603592,
        -0.025605686008930206,
        -0.01765182800590992,
        -0.04577653110027313,
        -0.006859065964818001,
        0.0015038148267194629,
        -0.061083026230335236,
        0.03314046934247017,
        0.059477727860212326,
        -0.05513007193803787,
        -0.01535236556082964,
        -0.048890866339206696,
        -0.04382799565792084,
        -0.05869345739483833,
        0.024361098185181618,
        0.03920397907495499,
        0.018383407965302467,
        -0.0012014161329716444,
        -0.05488727241754532,
        0.05585801228880882,
        0.06093050539493561,
        -0.04583299532532692,
        -0.05916040018200874,
        -0.053529273718595505,
        -0.015908382833003998,
        0.05976297706365585,
        0.057380691170692444,
        0.06099405139684677,
        -0.057509951293468475,
        0.01812802441418171,
        0.026569180190563202,
        0.025837304070591927,
        0.04888583719730377,
        -0.012760290876030922,
        -0.0034119789488613605,
        -0.03535298630595207,
        -0.059787966310977936,
        0.015214468352496624,
        0.05246184766292572,
        0.004163891077041626,
        0.05562567710876465,
        0.04136223345994949,
        0.05124233290553093,
        -0.049595169723033905,
        -0.06053448095917702,
        -0.06073170155286789,
        -0.057652365416288376,
        -0.05022773891687393,
        0.027995163574814796,
        0.0283371452242136,
        -0.05979573726654053,
        -0.03997481241822243,
        -0.047946806997060776,
        -0.04408333823084831,
        0.04721129685640335,
        0.040287189185619354,
        -0.042823947966098785,
        0.05538949742913246,
        0.009479446336627007,
        -0.036519233137369156,
        -0.051850296556949615,
        0.05838660150766373,
        -0.013224717229604721,
        -0.0544574111700058,
        -0.02387920208275318,
        0.042780801653862,
        -0.031244980171322823,
        0.05323323607444763,
        0.05906469002366066,
        -0.02755885198712349,
        0.05592441186308861,
        -0.05947796255350113,
        0.050035472959280014,
        -0.060455478727817535,
        -0.04301953688263893,
        0.04751460626721382,
        0.05671903118491173,
        -0.00853384006768465,
        -0.04436193406581879,
        -0.03600173816084862,
        0.008351228199899197,
        0.0007044466328807175,
        -0.06121857464313507,
        0.05520904064178467,
        -0.008505789563059807,
        0.028148632496595383,
        -0.06016610562801361,
        -0.0433385968208313,
        0.05207715928554535,
        0.061031073331832886,
        -0.05651751905679703,
        0.026292679831385612,
        0.0010892950231209397,
        -0.058633897453546524,
        0.022097807377576828,
        -0.025870181620121002,
        0.03982609510421753,
        -0.007052094209939241,
        0.059465665370225906,
        0.0446191243827343,
        0.03770072013139725,
        0.04608031362295151,
        -0.06019984558224678,
        -0.040854472666978836,
        -0.00781993754208088,
        0.02727336250245571,
        0.05032609775662422,
        0.013844096101820469,
        0.04853392764925957,
        0.053586382418870926,
        0.030110228806734085,
        -0.0069354684092104435,
        -0.05412495136260986,
        0.02530588023364544,
        -0.050024718046188354,
        -0.034779392182826996,
        0.015679141506552696,
        0.05077357962727547,
        0.04993543028831482,
        -0.003011200577020645,
        -0.05768129229545593,
        -0.04462384060025215,
        0.0584399476647377,
        0.011576024815440178,
        0.016331473365426064,
        -0.04485971853137016,
        -0.03432339429855347,
        0.006322020199149847,
        0.037475842982530594,
        0.029043080285191536,
        -0.04456862434744835,
        0.018638096749782562,
        -0.06024795025587082,
        0.03107493370771408,
        0.011145519092679024,
        0.06145894154906273
    ],
    [
        -0.05031659081578255,
        0.04545155167579651,
        0.05709623172879219,
        -0.0010931824799627066,
        0.054374322295188904,
        0.05668426677584648,
        0.05381571128964424,
        -0.047055646777153015,
        0.03835102543234825,
        0.056065380573272705,
        0.04261615127325058,
        0.05403005704283714,
        -0.051184482872486115,
        -0.046816568821668625,
        -0.05485778674483299,
        0.05623743310570717,
        -0.05274694785475731,
        -0.032479990273714066,
        -0.05510130152106285,
        0.0484890341758728,
        -0.04230301454663277,
        -0.036945637315511703,
        -0.051819734275341034,
        0.004972661845386028,
        0.01016590278595686,
        -0.020508309826254845,
        0.024867018684744835,
        0.05526627600193024,
        -0.037045881152153015,
        -0.056144434958696365,
        0.046193867921829224,
        0.011922601610422134,
        0.03129003569483757,
        -0.05707104876637459,
        -0.0198681578040123,
        -0.04334690794348717,
        -0.04811248183250427,
        -0.00786545965820551,
        -0.05693665146827698,
        0.05216371640563011,
        -0.04242099076509476,
        -0.005681745707988739,
        0.04831062629818916,
        -0.029192814603447914,
        0.05534648895263672,
        -0.04246678203344345,
        0.002598104067146778,
        0.05696290731430054,
        0.05699048191308975,
        0.0488421693444252,
        0.030747830867767334,
        0.037042923271656036,
        0.05336828902363777,
        -0.017099959775805473,
        -0.008959991857409477,
        -0.05718911811709404,
        -0.053595997393131256,
        -0.05199357494711876,
        -0.0009050663793459535,
        -0.020436592400074005,
        -0.05073722451925278,
        -0.024424508213996887,
        -0.054265256971120834,
        -0.04208869859576225,
        0.054889015853405,
        0.054128099232912064,
        -0.014437409117817879,
        0.05091775953769684,
        0.038554660975933075,
        -0.057189714163541794,
        -0.05648404732346535,
        -0.05683804675936699,
        0.045524559915065765,
        0.0022663103882223368,
        -0.05717135965824127,
        0.049663983285427094,
        -0.0044287219643592834,
        0.049502622336149216,
        0.05274296551942825,
        -0.04747660458087921,
        -0.03255095332860947,
        -0.012246761471033096,
        -0.052904002368450165,
        0.05430303514003754,
        0.03909306973218918,
        0.04808422923088074,
        -0.05563339218497276,
        -0.013373606838285923,
        0.04764934256672859,
        0.055394791066646576,
        -0.05635398253798485,
        0.05685367062687874,
        0.024655435234308243,
        -0.05530853569507599,
        -0.04898173734545708,
        0.05404563248157501,
        -0.03856488689780235,
        -0.04822997376322746,
        -0.05455292388796806,
        0.031248273327946663,
        -0.05698035657405853,
        0.03919435665011406,
        0.0546383261680603,
        -0.01573917828500271,
        0.02741451933979988,
        -0.05246158316731453,
        -0.03396924212574959,
        0.04108243063092232,
        -0.05412295460700989,
        0.04566625505685806,
        -0.05664747580885887,
        0.0540432333946228,
        -0.026499249041080475,
        0.04457078129053116,
        0.05688267573714256,
        0.03969070687890053,
        -0.0053594778291881084,
        -0.041111625730991364,
        -0.056063223630189896,
        -0.021297674626111984,
        0.0516134537756443,
        0.031783103942871094,
        -0.0032229970674961805,
        0.056174568831920624,
        -0.05639692395925522,
        -0.006166556384414434,
        -0.040242988616228104,
        0.05699659883975983,
        0.054364267736673355,
        -0.041594281792640686,
        0.05290143936872482,
        0.005099419038742781,
        0.025138510391116142,
        -0.041274651885032654,
        -0.04411430284380913,
        -0.053398143500089645,
        0.05460171401500702,
        -0.04987534508109093,
        -0.053309209644794464,
        0.05054770037531853,
        0.05718133598566055,
        0.007403879426419735,
        0.049569614231586456,
        -0.0036295964382588863,
        -0.054261162877082825,
        0.01928103156387806,
        0.05710316821932793,
        0.050959959626197815,
        -0.04919959977269173,
        0.05440247803926468,
        0.03459664434194565,
        -0.05714412033557892,
        -0.009860054589807987,
        0.027923956513404846,
        0.05688655003905296,
        -0.056019242852926254,
        -0.015655744820833206,
        0.010503388941287994,
        0.05457458272576332,
        0.04002438858151436,
        0.05040336772799492,
        0.006993848364800215,
        0.016956467181444168,
        -0.05355945602059364,
        -0.016376685351133347,
        -0.014287854544818401,
        0.05613537132740021,
        -0.05671460181474686,
        0.033398933708667755,
        0.03914474695920944,
        -0.0033473779913038015,
        0.012311441823840141,
        -0.05686768889427185,
        0.05656483396887779,
        0.056928448379039764,
        0.05688617005944252,
        -0.05658793821930885,
        0.05019513517618179,
        0.035142961889505386,
        -0.03834494575858116,
        -0.003992435522377491,
        -0.028450626879930496,
        0.05484399572014809,
        0.043084047734737396,
        0.05531137064099312,
        -0.024653200060129166,
        -0.0038215129170566797,
        0.05493759736418724,
        -0.045826204121112823,
        -0.05144533887505531,
        -0.004383317194879055,
        -0.04541262611746788,
        -0.05359547212719917,
        0.05622398108243942,
        -0.05030032619833946,
        -0.04840920493006706,
        0.05554339662194252,
        0.0411732941865921,
        0.0473426878452301,
        0.02766440436244011,
        0.016513299196958542,
        0.056594956666231155,
        0.04801139235496521,
        -0.034215252846479416,
        0.04216073080897331,
        0.05389552190899849,
        0.002330322749912739,
        -0.05635557323694229,
        -0.034075185656547546,
        0.04476207494735718,
        -0.055719997733831406,
        0.050749704241752625,
        -0.055693984031677246,
        -0.013635166920721531,
        0.053441911935806274,
        -0.03735750541090965,
        0.05113689601421356,
        -0.011329865083098412,
        0.05708646401762962,
        -0.05640667676925659,
        0.049669645726680756,
        0.02335347607731819,
        -0.057036396116018295,
        -0.05676361173391342,
        0.055228833109140396,
        -0.056929901242256165,
        -0.014550404623150826,
        0.041404642164707184,
        -0.05551018565893173,
        0.0037275308277457952,
        0.013715480454266071,
        -0.05243609845638275,
        -0.0014666290953755379,
        0.05525531247258186,
        -0.03073558211326599,
        0.04618123173713684,
        0.0562627948820591,
        -0.05278104543685913,
        -0.032197561115026474,
        0.05616394430398941,
        0.04476478695869446,
        0.05116964131593704,
        -0.02756066992878914,
        0.05412009358406067,
        0.045426737517118454,
        -0.04686541110277176,
        0.05252545699477196,
        -0.03469942882657051,
        -0.055508341640233994,
        0.004963637329638004,
        -0.04450497031211853,
        -0.053977735340595245,
        -0.04567331820726395,
        -0.052109770476818085,
        0.05403045937418938,
        0.056774038821458817,
        -0.04851329326629639,
        -0.047977786511182785,
        0.056572411209344864,
        0.044180694967508316,
        0.05203995481133461,
        0.05494290590286255,
        0.016862671822309494,
        0.056171562522649765,
        0.048612672835588455,
        0.030122775584459305,
        0.031066376715898514,
        0.05157965421676636,
        -0.05101253464818001,
        -0.018553219735622406,
        -0.05696143954992294,
        -0.056795429438352585,
        0.04602577164769173,
        -0.05561685562133789,
        -0.056575626134872437,
        0.01589653268456459,
        -0.05380664020776749,
        0.056227874010801315,
        -0.057178519666194916,
        -0.05660759285092354,
        -0.03441135212779045,
        -0.034982066601514816,
        0.03777050971984863,
        0.017186693847179413,
        -0.044177066534757614,
        -0.05629511550068855,
        0.05491643399000168,
        -0.01967763341963291,
        -0.03176366537809372,
        0.0064079053699970245,
        -0.014050631783902645,
        0.022530434653162956,
        0.0010073956800624728,
        -0.025094110518693924,
        0.03686939924955368,
        -0.0183117538690567,
        0.057064834982156754,
        -0.05548226833343506,
        -0.003671100130304694,
        0.054504767060279846,
        -0.040141746401786804,
        0.05572111904621124,
        0.04096516594290733,
        0.020879780873656273,
        -0.0022763428278267384,
        -0.02146678790450096,
        -0.04651244729757309,
        0.04352862015366554,
        0.030007509514689445,
        -0.05286633223295212,
        0.051353830844163895,
        -0.01879577711224556,
        -0.04568473994731903,
        0.014813780784606934,
        -0.05642130970954895,
        -0.05161266773939133,
        0.055130574852228165,
        -0.05677065625786781,
        0.02812412939965725,
        -0.05693545565009117,
        -0.0002859565429389477,
        0.03552517294883728,
        0.05300505459308624,
        -0.05559130385518074,
        0.028199385851621628,
        0.05600174516439438,
        0.023686187341809273,
        0.05328846350312233,
        -0.006367282010614872,
        0.02686499059200287,
        -0.04648064076900482,
        0.010034051723778248,
        0.034436047077178955,
        -0.05654576048254967,
        0.05341595411300659,
        0.013340897858142853,
        -0.04168964549899101,
        0.04714680090546608,
        -0.05463084951043129,
        0.04294333979487419,
        0.04378092288970947,
        -0.0334508940577507,
        0.004283282905817032,
        -0.042763322591781616,
        0.056775130331516266,
        0.05266088619828224,
        -0.03648189827799797,
        0.051193028688430786,
        0.018745049834251404,
        0.055170800536870956,
        -0.05653680860996246,
        0.056573715060949326,
        -0.04145856946706772,
        -0.05512700229883194,
        0.03090745024383068,
        0.050175055861473083,
        -0.05701606348156929,
        -0.036026064306497574,
        -0.05307023227214813,
        -0.056055162101984024,
        0.05506497621536255,
        -0.04328015446662903,
        -0.052045196294784546,
        -0.013684753328561783,
        0.042322881519794464,
        -0.04741936922073364,
        0.05717609077692032,
        -0.0059238001704216,
        0.04094609245657921,
        -0.057154331356287,
        -0.050876133143901825,
        -0.05697843059897423,
        0.056227244436740875,
        -0.052788298577070236,
        0.056774694472551346,
        0.05051940307021141,
        0.030531074851751328,
        0.03632145747542381,
        -0.02503865398466587,
        -0.05582652986049652,
        -0.05070870742201805,
        0.05568443238735199,
        -0.05542335659265518,
        -0.009338414296507835,
        0.055763572454452515,
        -0.03628842905163765,
        0.0550149604678154,
        -0.056275561451911926,
        -0.04950482398271561,
        -0.056893039494752884,
        -0.0476323626935482,
        -0.056598812341690063,
        0.0018461559666320682,
        0.026906929910182953,
        -0.05203782021999359,
        0.05537167191505432,
        0.055874936282634735,
        0.004651225637644529,
        -0.056144729256629944,
        -0.0571741983294487,
        -0.04316387698054314,
        0.05410022661089897,
        0.056504104286432266,
        0.0571475587785244,
        -0.0362626314163208,
        0.01664789952337742,
        0.02478567510843277,
        0.00003009612555615604,
        0.05582917854189873,
        -0.026481902226805687,
        0.04629269614815712,
        -0.006441442295908928,
        -0.04452482983469963,
        0.009966476820409298,
        0.04324272647500038,
        0.02480938471853733,
        -0.029829028993844986,
        -0.006358657032251358,
        0.03673080727458,
        -0.054246049374341965,
        -0.05683819577097893,
        -0.04437263682484627,
        -0.04330736771225929,
        -0.05602327734231949,
        0.04216735437512398,
        0.01549680344760418,
        -0.056036707013845444,
        -0.056151341646909714,
        -0.03589347377419472,
        -0.05144764855504036,
        0.052267830818891525,
        0.024830536916851997,
        0.03904620558023453,
        -0.04829792305827141,
        0.008315378800034523,
        -0.025976618751883507,
        0.010806441307067871,
        0.053427260369062424,
        0.00026292804977856576,
        -0.0357913002371788,
        0.026842469349503517,
        0.048409637063741684,
        0.03745921328663826,
        0.04428050294518471,
        0.054997578263282776,
        0.046378474682569504,
        0.05669362097978592,
        0.045553289353847504,
        0.05010347068309784,
        -0.03402421995997429,
        -0.03670109435915947,
        0.0565279945731163,
        0.05601199343800545,
        0.012836914509534836,
        0.03233921155333519,
        -0.04346097260713577,
        0.05526579171419144,
        0.04355444386601448,
        -0.05112907290458679,
        0.056139297783374786,
        0.051939625293016434,
        0.05017770826816559,
        0.00008094496297417209,
        0.055232252925634384,
        0.0571293942630291,
        0.05711723491549492,
        -0.0566561184823513,
        0.05559643730521202,
        -0.054874394088983536,
        -0.05706832930445671,
        0.05717357248067856,
        -0.0515054352581501,
        0.04447491839528084,
        0.038114145398139954,
        -0.008947647176682949,
        0.05342112109065056,
        0.010134606622159481,
        -0.003632362699136138,
        -0.052684348076581955,
        -0.044679347425699234,
        0.05710583180189133,
        0.02313578687608242,
        0.04995330050587654,
        0.038565799593925476,
        0.03228291869163513,
        0.05453186482191086,
        0.05345698818564415,
        -0.029663074761629105,
        -0.05014071613550186,
        -0.04042918607592583,
        -0.055517155677080154,
        -0.05308013781905174,
        0.02590200863778591,
        0.03652772679924965,
        0.048210322856903076,
        0.04537196829915047,
        -0.05717303603887558,
        -0.01988532580435276,
        0.05152949318289757,
        0.05107620730996132,
        0.054646190255880356,
        -0.03170144185423851,
        0.005838428623974323,
        -0.002162276767194271,
        -0.048476532101631165,
        0.0037111067213118076,
        -0.037464410066604614,
        -0.004452286288142204,
        -0.03564492240548134,
        -0.045707907527685165,
        0.0568707138299942,
        0.04253481701016426
    ],
    [
        -0.05028076097369194,
        0.012216023169457912,
        0.05736493691802025,
        -0.006413806229829788,
        0.04764847084879875,
        0.04997687041759491,
        0.05783945694565773,
        -0.055993638932704926,
        -0.005716345272958279,
        0.013243080116808414,
        0.007619175594300032,
        0.05190844088792801,
        -0.05347537621855736,
        -0.01161819789558649,
        -0.05548412352800369,
        0.05785272642970085,
        -0.054921239614486694,
        0.05734958127140999,
        -0.057827092707157135,
        0.056590162217617035,
        -0.05326605960726738,
        -0.042743802070617676,
        0.033184200525283813,
        -0.020160198211669922,
        0.05678931996226311,
        0.04226114600896835,
        -0.02019232138991356,
        0.057840727269649506,
        -0.04062614589929581,
        -0.04947059229016304,
        0.057794950902462006,
        0.05421815812587738,
        -0.05421639233827591,
        -0.05715060606598854,
        -0.006953302770853043,
        -0.03414088115096092,
        -0.05213995650410652,
        -0.01162407360970974,
        -0.056688856333494186,
        0.04791397973895073,
        0.03672042489051819,
        -0.005571899935603142,
        0.05133375898003578,
        -0.04355849325656891,
        0.051361583173274994,
        0.04134668782353401,
        0.027239816263318062,
        0.05784619227051735,
        0.04970662295818329,
        -0.024016952142119408,
        -0.007742592133581638,
        0.05225350335240364,
        0.051857881247997284,
        0.007178004365414381,
        0.03557894378900528,
        -0.05798053368926048,
        -0.0506904236972332,
        0.010702935047447681,
        -0.03434273973107338,
        0.05626065656542778,
        -0.0454263798892498,
        -0.04053007438778877,
        -0.053818609565496445,
        -0.048157721757888794,
        0.040961507707834244,
        -0.023738980293273926,
        0.05451856926083565,
        0.03735420107841492,
        -0.013974498957395554,
        -0.05798636004328728,
        -0.056056004017591476,
        -0.05787470564246178,
        0.02920297160744667,
        0.04070964828133583,
        -0.056842219084501266,
        -0.014301219955086708,
        0.05783240497112274,
        0.05758551135659218,
        0.05045168846845627,
        -0.04605478420853615,
        -0.03716369345784187,
        0.043108195066452026,
        -0.055462758988142014,
        0.021338697522878647,
        0.056785646826028824,
        0.021644573658704758,
        -0.05692572891712189,
        0.03993615880608559,
        0.020080404356122017,
        0.05551399663090706,
        -0.05598286911845207,
        0.019580794498324394,
        0.04862954467535019,
        -0.052204668521881104,
        -0.01112496480345726,
        0.011907928623259068,
        -0.016697846353054047,
        -0.050194814801216125,
        -0.039099954068660736,
        -0.002065349603071809,
        -0.055186137557029724,
        -0.053904641419649124,
        0.057862330228090286,
        0.04040561988949776,
        0.03721543401479721,
        0.014352355152368546,
        -0.05633886158466339,
        -0.041747771203517914,
        -0.05713477358222008,
        0.002324963454157114,
        -0.057285744696855545,
        0.057935237884521484,
        -0.047666776925325394,
        0.015358293429017067,
        0.05614180490374565,
        -0.03828863427042961,
        -0.027621664106845856,
        -0.055360861122608185,
        -0.055312976241111755,
        -0.02661046013236046,
        0.0011287577217444777,
        0.03432387113571167,
        -0.031362563371658325,
        0.056508876383304596,
        -0.05760769918560982,
        0.02238258346915245,
        -0.011660987511277199,
        0.05565430968999863,
        0.05183088779449463,
        -0.05439436435699463,
        0.05506368726491928,
        -0.0541844442486763,
        0.043392300605773926,
        0.040771178901195526,
        -0.05139951407909393,
        -0.0533846952021122,
        0.047081358730793,
        -0.02144523523747921,
        -0.05112190544605255,
        0.052208613604307175,
        0.056758370250463486,
        0.05470971390604973,
        0.04928984493017197,
        -0.010544474236667156,
        0.02438746951520443,
        0.04160352796316147,
        0.05799516662955284,
        -0.05340162292122841,
        -0.054156702011823654,
        0.055769242346286774,
        -0.04553285613656044,
        -0.057608965784311295,
        -0.05445651337504387,
        0.0006876622792333364,
        0.01802847906947136,
        -0.05333997681736946,
        0.05378960445523262,
        0.03637504577636719,
        0.05645253509283066,
        -0.0324857272207737,
        0.05460185557603836,
        -0.046801481395959854,
        0.05003160238265991,
        0.018422037363052368,
        -0.05387275293469429,
        -0.04183787852525711,
        0.04484764486551285,
        -0.018317438662052155,
        0.01780034974217415,
        0.05022963881492615,
        -0.0023211135994642973,
        0.05138329043984413,
        -0.05687389150261879,
        0.0002012354088947177,
        -0.009246706031262875,
        0.05675366520881653,
        -0.05793695151805878,
        -0.03113611601293087,
        0.051968637853860855,
        -0.04628841578960419,
        -0.00026303084450773895,
        -0.020823286846280098,
        0.05459621548652649,
        0.007706064265221357,
        0.015730982646346092,
        -0.03231019526720047,
        -0.049141816794872284,
        0.05592526122927666,
        -0.05407804995775223,
        -0.05624278262257576,
        -0.049596156924963,
        0.004675995092839003,
        -0.02212059684097767,
        0.04344535991549492,
        -0.04321839287877083,
        -0.03216840699315071,
        0.057976484298706055,
        -0.009846904315054417,
        -0.038374751806259155,
        0.041312430053949356,
        -0.01286996342241764,
        0.05293905362486839,
        0.05024457350373268,
        0.05699669569730759,
        -0.002133820438757539,
        0.053553834557533264,
        0.02070365846157074,
        0.017713118344545364,
        -0.051864538341760635,
        -0.055328045040369034,
        -0.05676409602165222,
        0.049999505281448364,
        -0.020075982436537743,
        -0.05188388004899025,
        0.043133694678545,
        0.010227163322269917,
        0.057337988168001175,
        0.04949570447206497,
        0.05796113237738609,
        -0.057676028460264206,
        0.03520788997411728,
        -0.049990974366664886,
        -0.056005679070949554,
        -0.057416412979364395,
        0.057070761919021606,
        -0.05793941020965576,
        -0.027711598202586174,
        0.05484853684902191,
        -0.05748097598552704,
        -0.049088649451732635,
        0.015014746226370335,
        -0.05238467827439308,
        -0.04899609461426735,
        0.053510989993810654,
        0.046463701874017715,
        0.022062599658966064,
        0.05633634701371193,
        -0.04211109131574631,
        -0.055017925798892975,
        0.05735201761126518,
        0.0554751455783844,
        -0.015874676406383514,
        -0.045118995010852814,
        0.03943504020571709,
        -0.004472556058317423,
        -0.0017760818591341376,
        0.03622792661190033,
        -0.011137960478663445,
        -0.046677764505147934,
        0.011931653134524822,
        -0.03444017842411995,
        -0.05719390884041786,
        -0.04524274915456772,
        -0.05381200835108757,
        0.030638668686151505,
        0.054660215973854065,
        -0.04970555007457733,
        -0.028331110253930092,
        0.05780506134033203,
        0.055679842829704285,
        0.05214032530784607,
        0.054653871804475784,
        -0.040114354342222214,
        -0.020113736391067505,
        0.02672366239130497,
        0.04655534774065018,
        -0.05581507086753845,
        -0.05115709453821182,
        0.03227229043841362,
        -0.05562323331832886,
        -0.04897870868444443,
        -0.05687946826219559,
        0.015759361907839775,
        -0.05391082912683487,
        -0.05727756768465042,
        0.03966285288333893,
        -0.05252331867814064,
        0.0578928142786026,
        -0.04360068589448929,
        -0.04719293862581253,
        -0.05783502385020256,
        -0.012446335516870022,
        -0.053833942860364914,
        0.05529734119772911,
        -0.03946343809366226,
        -0.051005322486162186,
        0.0495758019387722,
        -0.03876754641532898,
        -0.045242369174957275,
        0.03983746096491814,
        -0.04245691001415253,
        0.05070776864886284,
        0.011971588246524334,
        -0.04092413932085037,
        0.04346958175301552,
        -0.055661335587501526,
        0.05641839653253555,
        -0.003930363804101944,
        -0.05790494382381439,
        0.05153146758675575,
        0.03918386250734329,
        0.05671682208776474,
        -0.03740730881690979,
        0.019662780687212944,
        0.05325883626937866,
        -0.04407511651515961,
        -0.039038900285959244,
        0.05774529650807381,
        0.05639418587088585,
        -0.05718464031815529,
        0.041424769908189774,
        -0.04816490039229393,
        -0.04462555795907974,
        0.05554426461458206,
        -0.05273064598441124,
        -0.051631391048431396,
        0.05693645402789116,
        -0.04703230410814285,
        0.04093533381819725,
        -0.048009440302848816,
        -0.0458453968167305,
        0.016928834840655327,
        0.05243935063481331,
        0.007907411083579063,
        0.0289676021784544,
        0.057826731353998184,
        0.02161528170108795,
        0.05316443741321564,
        -0.03613921254873276,
        0.03710784763097763,
        -0.05267494544386864,
        0.0003433850361034274,
        0.006942791398614645,
        -0.05275857076048851,
        0.056981828063726425,
        -0.037822626531124115,
        0.0018270836444571614,
        0.05583778768777847,
        -0.05239583179354668,
        0.05359264090657234,
        0.041887424886226654,
        0.016477342694997787,
        -0.05661175772547722,
        -0.05749967694282532,
        0.05799847096204758,
        -0.005000680219382048,
        -0.03018372505903244,
        0.046427007764577866,
        0.038604702800512314,
        0.053642891347408295,
        -0.05643237382173538,
        0.047846753150224686,
        -0.0515957847237587,
        -0.05229181796312332,
        -0.05528245121240616,
        0.04913339391350746,
        -0.04751649498939514,
        -0.049462687224149704,
        -0.04635216295719147,
        -0.025748593732714653,
        0.05389423668384552,
        -0.052897289395332336,
        -0.05292568355798721,
        -0.033901650458574295,
        0.056849975138902664,
        -0.045599620789289474,
        0.05792646110057831,
        -0.04887165129184723,
        0.05628693103790283,
        -0.057888902723789215,
        -0.00843039434403181,
        -0.05765771120786667,
        0.05572310462594032,
        0.012198728509247303,
        0.05608513578772545,
        0.056816939264535904,
        -0.0224764421582222,
        0.009481796994805336,
        0.02707020193338394,
        -0.042659565806388855,
        -0.05050463601946831,
        0.05446358397603035,
        -0.05476494878530502,
        -0.005739547312259674,
        0.05507056415081024,
        -0.018245069310069084,
        0.040595054626464844,
        -0.057282526046037674,
        -0.05170978233218193,
        -0.05726158991456032,
        -0.04676530137658119,
        -0.03244231641292572,
        0.04029231518507004,
        -0.04711662977933884,
        -0.008982881903648376,
        0.05524688959121704,
        0.052146151661872864,
        0.024117521941661835,
        0.012381915003061295,
        -0.05435223504900932,
        -0.04655144736170769,
        0.057320304214954376,
        0.010737144388258457,
        0.057891182601451874,
        -0.011464400216937065,
        -0.0020850026048719883,
        0.053949691355228424,
        0.016294917091727257,
        0.055457986891269684,
        -0.04216183349490166,
        0.013550915755331516,
        -0.0477425642311573,
        0.014036254025995731,
        0.04544569551944733,
        0.05429286137223244,
        -0.0007136288331821561,
        0.05551283434033394,
        0.028014032170176506,
        0.017513105645775795,
        -0.004123084247112274,
        -0.050998982042074203,
        -0.055260658264160156,
        -0.04716085270047188,
        -0.05427616834640503,
        0.04136614874005318,
        -0.03354828059673309,
        -0.05054471641778946,
        -0.05438699573278427,
        -0.057589709758758545,
        -0.05231070891022682,
        0.0226413793861866,
        -0.011472460813820362,
        -0.053177572786808014,
        0.03602868691086769,
        0.026577439159154892,
        -0.05189460888504982,
        -0.045327078551054,
        0.02936113253235817,
        -0.03056269697844982,
        -0.014014068059623241,
        0.029996799305081367,
        0.03525937721133232,
        0.006771869026124477,
        0.049939557909965515,
        0.03533041104674339,
        -0.00283275474794209,
        0.056959524750709534,
        -0.03532124683260918,
        0.05492011085152626,
        -0.03282875567674637,
        -0.05430527403950691,
        0.04677868261933327,
        0.05714387074112892,
        -0.03792678937315941,
        -0.004816141445189714,
        -0.023468203842639923,
        0.05554831400513649,
        0.04303770884871483,
        -0.03376464918255806,
        0.056762177497148514,
        -0.02773725427687168,
        0.04206142947077751,
        -0.03793688118457794,
        -0.0195755697786808,
        0.056785695254802704,
        0.05760518088936806,
        -0.05747540667653084,
        0.05785375088453293,
        -0.05416220426559448,
        -0.057714421302080154,
        0.052658095955848694,
        0.04111094027757645,
        0.055103402584791183,
        0.005501406267285347,
        0.056486766785383224,
        0.0467403382062912,
        0.028926078230142593,
        -0.05777812376618385,
        -0.02265360951423645,
        -0.011643024161458015,
        0.05660107731819153,
        -0.04328589513897896,
        0.05662049353122711,
        0.02063966915011406,
        -0.050181832164525986,
        0.05301402881741524,
        0.02995266020298004,
        0.0033033231738954782,
        -0.051056381314992905,
        -0.033222995698451996,
        -0.046746280044317245,
        -0.05580776184797287,
        0.005346485413610935,
        0.04401911422610283,
        0.03339580073952675,
        0.0508512482047081,
        -0.05780039355158806,
        -0.028102297335863113,
        0.05419071763753891,
        0.038422200828790665,
        0.057650696486234665,
        -0.02711084485054016,
        0.03315719962120056,
        -0.014368338510394096,
        -0.030300600454211235,
        -0.009286117739975452,
        0.02870442345738411,
        -0.010280792601406574,
        -0.024967068806290627,
        -0.047215282917022705,
        0.0577729158103466,
        0.05673614889383316
    ],
    [
        -0.05093611776828766,
        -0.030796879902482033,
        0.056672368198633194,
        0.0019061414059251547,
        0.05123919993638992,
        0.047283757477998734,
        0.057192664593458176,
        -0.05597876384854317,
        0.001118950662203133,
        -0.007061277981847525,
        -0.004793824627995491,
        0.05460522323846817,
        -0.05288360267877579,
        -0.045011334121227264,
        -0.05200229957699776,
        0.05716945603489876,
        -0.04909657686948776,
        0.05673768371343613,
        -0.05723473057150841,
        0.056426819413900375,
        -0.05354248359799385,
        -0.04600793123245239,
        0.041907910257577896,
        -0.02023831568658352,
        0.05693305656313896,
        0.033739279955625534,
        0.008044364862143993,
        0.05702665075659752,
        -0.0456877201795578,
        -0.0548216737806797,
        0.056622933596372604,
        0.046349234879016876,
        -0.050211403518915176,
        -0.05533715337514877,
        -0.02744433283805847,
        -0.040179502218961716,
        -0.05182099714875221,
        -0.0017999797128140926,
        -0.05727885663509369,
        0.05192958936095238,
        0.043183375149965286,
        -0.005374128930270672,
        0.05264037847518921,
        -0.05506046488881111,
        0.05473851040005684,
        0.04801652953028679,
        0.053134623914957047,
        0.05715334042906761,
        0.04318155348300934,
        -0.05246064066886902,
        -0.010114280506968498,
        0.031868934631347656,
        0.05022948980331421,
        0.016043851152062416,
        0.027685051783919334,
        -0.05727813392877579,
        -0.05424914509057999,
        0.03591645136475563,
        -0.027895474806427956,
        0.05466696247458458,
        -0.04340085759758949,
        -0.03667602315545082,
        -0.05229521915316582,
        -0.030401872470974922,
        0.03269631788134575,
        0.025172363966703415,
        0.05586784705519676,
        0.05249398201704025,
        -0.008776921778917313,
        -0.05730194225907326,
        -0.054205697029829025,
        -0.0570312961935997,
        0.01893651857972145,
        0.032415155321359634,
        -0.05637393891811371,
        -0.01160102616995573,
        0.05704999715089798,
        0.056875694543123245,
        0.05516478419303894,
        -0.05440083146095276,
        -0.03257150948047638,
        0.02890642173588276,
        -0.05589091032743454,
        0.02619798667728901,
        0.05646543204784393,
        0.024023912847042084,
        -0.05651161074638367,
        0.009746260941028595,
        0.04823130741715431,
        0.055173784494400024,
        -0.050079233944416046,
        0.006830534432083368,
        0.05082377418875694,
        -0.047044865787029266,
        -0.00731305917724967,
        0.012281125411391258,
        -0.0177783016115427,
        -0.029105601832270622,
        -0.04510258510708809,
        -0.019210809841752052,
        -0.05515044555068016,
        -0.05511171743273735,
        0.05710664018988609,
        0.038285642862319946,
        0.02439972013235092,
        -0.01766751892864704,
        -0.05581105127930641,
        -0.03994422405958176,
        -0.05603107810020447,
        -0.007877455092966557,
        -0.05702618882060051,
        0.05727767199277878,
        -0.05343656241893768,
        -0.019139926880598068,
        0.056455399841070175,
        -0.010503862053155899,
        -0.022406036034226418,
        -0.055906567722558975,
        -0.056147485971450806,
        0.012761657126247883,
        -0.010188018903136253,
        0.029440930113196373,
        -0.05130671709775925,
        0.05226995795965195,
        -0.05678906664252281,
        0.0030427505262196064,
        0.03348536416888237,
        0.05626645311713219,
        0.044891245663166046,
        -0.053028225898742676,
        0.054351456463336945,
        -0.05597729608416557,
        0.03470587730407715,
        0.03821035847067833,
        -0.05218176543712616,
        -0.05392880365252495,
        0.03787754103541374,
        0.0027112672105431557,
        -0.05213223025202751,
        0.05396890640258789,
        0.0571596659719944,
        0.05565859377384186,
        0.042508821934461594,
        -0.04269666597247124,
        0.02342594414949417,
        0.04641111567616463,
        0.05730479210615158,
        -0.047181736677885056,
        -0.05241692066192627,
        0.0527164451777935,
        -0.050348151475191116,
        -0.057070378214120865,
        -0.052700821310281754,
        -0.022294925525784492,
        -0.018248425796628,
        -0.054749585688114166,
        0.05639292299747467,
        0.03380213677883148,
        0.05593011900782585,
        -0.015237203799188137,
        0.051434095948934555,
        -0.042733099311590195,
        0.050121504813432693,
        -0.0030519699212163687,
        -0.05216364562511444,
        -0.04606128856539726,
        0.05358431488275528,
        -0.02893163077533245,
        0.034841105341911316,
        0.04878551512956619,
        -0.005524361971765757,
        0.05370775982737541,
        -0.05704587697982788,
        -0.011789074167609215,
        -0.000032320382160833105,
        0.05692935734987259,
        -0.057301461696624756,
        -0.02594117447733879,
        0.05224662274122238,
        -0.00551206897944212,
        0.024038301780819893,
        -0.04520634934306145,
        0.05618779733777046,
        0.022533681243658066,
        -0.009606016799807549,
        -0.04540572687983513,
        -0.046567559242248535,
        0.05489065870642662,
        -0.04527634382247925,
        -0.054486796259880066,
        -0.04639187827706337,
        -0.0036389525048434734,
        -0.02521020732820034,
        0.049174290150403976,
        -0.0036580837331712246,
        -0.03151422366499901,
        0.057285357266664505,
        -0.019085435196757317,
        -0.04753483831882477,
        0.04071059823036194,
        -0.012182368896901608,
        0.05452097952365875,
        0.05269337818026543,
        0.0569339394569397,
        -0.014572536572813988,
        0.0554015077650547,
        -0.023272356018424034,
        0.001793252769857645,
        -0.04356085881590843,
        -0.05721013993024826,
        -0.05616048723459244,
        0.032649923115968704,
        -0.021454954519867897,
        -0.05026442930102348,
        0.03812515363097191,
        0.002466138219460845,
        0.057102154940366745,
        0.04892559349536896,
        0.057291429489851,
        -0.05713162571191788,
        0.010416283272206783,
        -0.054642204195261,
        -0.05483122915029526,
        -0.05709926038980484,
        0.052607711404561996,
        -0.05725698173046112,
        0.01005041878670454,
        0.057132918387651443,
        -0.057228948920965195,
        -0.051370199769735336,
        0.0008064139983616769,
        -0.04936213791370392,
        -0.0529569610953331,
        0.049725741147994995,
        0.03986944630742073,
        -0.006315711420029402,
        0.05513828992843628,
        -0.03503238782286644,
        -0.05481322109699249,
        0.05694367364048958,
        0.0529930479824543,
        0.025587715208530426,
        -0.03010990284383297,
        0.047776345163583755,
        0.015486839227378368,
        -0.014933641068637371,
        0.009396678768098354,
        -0.014330674894154072,
        -0.04886068031191826,
        -0.011262883432209492,
        -0.028652813285589218,
        -0.05629735067486763,
        -0.042863667011260986,
        -0.0550755150616169,
        0.025767607614398003,
        0.05519866570830345,
        -0.05438034608960152,
        -0.04472523555159569,
        0.05710895359516144,
        0.05473797768354416,
        0.05238637700676918,
        0.05377824231982231,
        -0.03813953697681427,
        -0.005467479582875967,
        -0.00674891471862793,
        0.051242563873529434,
        -0.05681772530078888,
        -0.050009600818157196,
        0.02184334211051464,
        -0.05077425390481949,
        -0.04657018184661865,
        -0.056863170117139816,
        0.002259668195620179,
        -0.05013787001371384,
        -0.056863803416490555,
        0.047445543110370636,
        -0.0529850535094738,
        0.057160280644893646,
        -0.036770980805158615,
        -0.0334610641002655,
        -0.0571821853518486,
        -0.05147053673863411,
        -0.05517705902457237,
        0.05678607523441315,
        -0.04110698774456978,
        -0.05135967582464218,
        0.03715083748102188,
        -0.055471546947956085,
        -0.042771413922309875,
        0.04621952772140503,
        -0.043399348855018616,
        0.05562968552112579,
        0.03727685660123825,
        -0.04804673790931702,
        0.04140723869204521,
        -0.053048573434352875,
        0.055744048207998276,
        0.0004765210032928735,
        -0.057273443788290024,
        0.05157875269651413,
        0.019460491836071014,
        0.056515276432037354,
        -0.03678885102272034,
        0.004288476426154375,
        0.05526299774646759,
        -0.041205305606126785,
        -0.03797784820199013,
        0.05716947838664055,
        0.053601205348968506,
        -0.055132269859313965,
        0.05427815020084381,
        -0.0513419508934021,
        -0.022758672013878822,
        0.05606764182448387,
        -0.056590933352708817,
        -0.05028727650642395,
        0.05525140091776848,
        -0.05393455550074577,
        0.031448639929294586,
        -0.04376639798283577,
        -0.046168044209480286,
        0.02950183115899563,
        0.05347166210412979,
        -0.009924985468387604,
        0.039373867213726044,
        0.05716443434357643,
        0.04925555735826492,
        0.04994438216090202,
        0.0010133067844435573,
        0.016016213223338127,
        -0.04957175999879837,
        -0.01041028555482626,
        0.004351822193711996,
        -0.05659688264131546,
        0.05624212324619293,
        -0.043738845735788345,
        0.00785299576818943,
        0.05669611319899559,
        -0.041165247559547424,
        0.051170915365219116,
        0.04127059131860733,
        0.03100922331213951,
        -0.05664083734154701,
        -0.056364987045526505,
        0.05730573460459709,
        0.01728867180645466,
        0.011435339227318764,
        0.04359308257699013,
        0.0436290018260479,
        0.05093955993652344,
        -0.05591709539294243,
        0.04767307639122009,
        -0.04888005927205086,
        -0.03217260167002678,
        -0.05627899989485741,
        0.03586997091770172,
        -0.03839360550045967,
        -0.05554472655057907,
        -0.05180525407195091,
        -0.020232565701007843,
        0.05427965521812439,
        -0.0504838265478611,
        -0.05322526395320892,
        -0.03167137876152992,
        0.05603104829788208,
        -0.04644715413451195,
        0.05709526687860489,
        -0.051371704787015915,
        0.056512460112571716,
        -0.05707549303770065,
        0.004917780868709087,
        -0.05709781125187874,
        0.05404694750905037,
        0.042411964386701584,
        0.05457447096705437,
        0.05713993310928345,
        -0.03862818330526352,
        0.020376170054078102,
        0.005819079931825399,
        -0.04877811670303345,
        -0.05210624262690544,
        0.04815639927983284,
        -0.054388221353292465,
        0.015299957245588303,
        0.056613944470882416,
        -0.0426853708922863,
        0.04879603534936905,
        -0.05653631314635277,
        -0.04993513226509094,
        -0.05653952434659004,
        -0.03516194224357605,
        0.04425518587231636,
        0.04209933802485466,
        -0.045366205275058746,
        -0.01821831613779068,
        0.054781775921583176,
        0.051913078874349594,
        0.016558369621634483,
        0.025648294016718864,
        -0.05454783886671066,
        -0.05042493715882301,
        0.056822944432497025,
        0.04651111364364624,
        0.05727077275514603,
        0.014507605694234371,
        -0.01760745979845524,
        0.05303265526890755,
        -0.0027807673905044794,
        0.05324843153357506,
        -0.04337919130921364,
        0.01334186177700758,
        -0.04590234532952309,
        0.005579987540841103,
        0.03561888262629509,
        0.05422051623463631,
        -0.00808838289231062,
        0.052739083766937256,
        0.024816365912556648,
        0.029955748468637466,
        -0.02008962258696556,
        -0.05436087027192116,
        -0.054475970566272736,
        -0.056663963943719864,
        -0.05542097985744476,
        0.03965441882610321,
        -0.02750982530415058,
        -0.056242190301418304,
        -0.05589758977293968,
        -0.055821944028139114,
        -0.05193808302283287,
        0.03643282130360603,
        -0.012635747902095318,
        -0.05256003513932228,
        0.04692370444536209,
        0.012948389165103436,
        -0.04999114200472832,
        -0.04122019559144974,
        0.039527177810668945,
        -0.01480622123926878,
        -0.005529747344553471,
        0.035775549709796906,
        0.02980382926762104,
        0.014556197449564934,
        0.05154307186603546,
        0.043393585830926895,
        -0.004723396617919207,
        0.05683073401451111,
        -0.0408630408346653,
        0.05491390824317932,
        -0.02075698971748352,
        -0.05634436011314392,
        0.04643625766038895,
        0.054307982325553894,
        -0.05162016302347183,
        0.007293300237506628,
        -0.02617068961262703,
        0.05479231849312782,
        0.01994527503848076,
        -0.04630551114678383,
        0.05572248622775078,
        -0.024507377296686172,
        0.046106792986392975,
        -0.04815400764346123,
        -0.04397789388895035,
        0.05413409695029259,
        0.05682749301195145,
        -0.05697692185640335,
        0.057260408997535706,
        -0.05629769712686539,
        -0.057191528379917145,
        0.05343161150813103,
        0.008056103251874447,
        0.05504358187317848,
        -0.015150744467973709,
        0.056726958602666855,
        0.026113606989383698,
        0.03773145377635956,
        -0.05688202753663063,
        -0.03200507536530495,
        -0.03133294731378555,
        0.0542379692196846,
        -0.03687586635351181,
        0.05692484974861145,
        0.007455498445779085,
        -0.04526738077402115,
        0.05196616053581238,
        0.03709862381219864,
        -0.014579473994672298,
        -0.0522090345621109,
        -0.0356137715280056,
        -0.04293806478381157,
        -0.05447322875261307,
        0.02195010520517826,
        0.04508752375841141,
        0.026697851717472076,
        0.053879447281360626,
        -0.05723639577627182,
        -0.05251580476760864,
        0.05371086299419403,
        0.027744384482502937,
        0.056048620492219925,
        -0.023106392472982407,
        0.019293133169412613,
        -0.02004077471792698,
        -0.03304121643304825,
        0.014486263506114483,
        -0.010288846679031849,
        -0.014545527286827564,
        -0.03853573277592659,
        -0.03174052760004997,
        0.05699852481484413,
        0.05699903890490532
    ],
    [
        -0.036608073860406876,
        0.054018594324588776,
        0.0543060265481472,
        -0.03915288299322128,
        -0.04086369276046753,
        0.05422517657279968,
        0.053694531321525574,
        -0.05349172651767731,
        0.009341370314359665,
        0.04312761127948761,
        0.05375618487596512,
        0.05360264703631401,
        -0.05009068176150322,
        -0.018998073413968086,
        -0.05425611138343811,
        0.051854200661182404,
        -0.020034071058034897,
        0.04667530208826065,
        -0.03366391360759735,
        0.017766473814845085,
        0.053957726806402206,
        0.03902053460478783,
        -0.053886495530605316,
        -0.01818801276385784,
        0.005646974314004183,
        0.051228445023298264,
        0.004861947149038315,
        0.052882108837366104,
        -0.05431390181183815,
        -0.005166218616068363,
        -0.022198133170604706,
        -0.005863718688488007,
        0.049308937042951584,
        -0.053645059466362,
        0.053670261055231094,
        0.016408992931246758,
        -0.053648050874471664,
        0.052166614681482315,
        0.03639836609363556,
        -0.010728368535637856,
        -0.021851377561688423,
        0.03750067949295044,
        0.03096706233918667,
        0.04897981137037277,
        -0.02592080645263195,
        -0.04810650646686554,
        0.008328042924404144,
        0.049485333263874054,
        0.05424590781331062,
        -0.04373291879892349,
        0.05424658954143524,
        0.05314318835735321,
        0.046568211168050766,
        -0.049592066556215286,
        -0.050280142575502396,
        -0.051676660776138306,
        -0.05285102501511574,
        0.008089425973594189,
        0.008167687803506851,
        -0.05428192764520645,
        -0.05428124964237213,
        -0.028271444141864777,
        -0.05371421203017235,
        -0.050525397062301636,
        0.05429501831531525,
        -0.03281367942690849,
        -0.05421203002333641,
        -0.03347484767436981,
        0.017130790278315544,
        -0.022069817408919334,
        -0.053172722458839417,
        -0.053535498678684235,
        0.05381554365158081,
        0.04360712319612503,
        -0.0537077933549881,
        -0.03147657960653305,
        0.05111951753497124,
        0.05432567372918129,
        0.05209778994321823,
        -0.04502592980861664,
        -0.032830338925123215,
        -0.05428217351436615,
        -0.05172581225633621,
        0.05184466391801834,
        0.023944102227687836,
        0.052488621324300766,
        -0.0543229915201664,
        -0.05420885980129242,
        -0.049214668571949005,
        0.05161571502685547,
        -0.037448979914188385,
        -0.046004731208086014,
        0.05156167969107628,
        -0.039557818323373795,
        -0.009624834172427654,
        0.044891342520713806,
        0.03455047309398651,
        -0.05205158144235611,
        0.054303038865327835,
        0.03099411353468895,
        -0.054322898387908936,
        -0.03876529633998871,
        -0.02207818813621998,
        0.04518704116344452,
        -0.04020754620432854,
        0.0030605592764914036,
        -0.0527711883187294,
        0.03020850569009781,
        -0.053796347230672836,
        0.053648147732019424,
        -0.054259318858385086,
        0.05426662787795067,
        -0.049959857016801834,
        0.044332534074783325,
        0.015223384834825993,
        -0.03875676915049553,
        0.05400965362787247,
        -0.05423334240913391,
        -0.04324060305953026,
        -0.051137737929821014,
        0.022715352475643158,
        0.04934404045343399,
        -0.033375028520822525,
        0.03319958969950676,
        -0.04947897046804428,
        -0.027278609573841095,
        -0.043734241276979446,
        0.04623386263847351,
        0.04971686005592346,
        -0.05257715657353401,
        0.05343886837363243,
        -0.044519778341054916,
        -0.014299332164227962,
        0.0348680354654789,
        -0.051148250699043274,
        -0.05401158332824707,
        0.04647098481655121,
        -0.04114018380641937,
        -0.054294902831315994,
        0.037805404514074326,
        0.05420593544840813,
        -0.034967537969350815,
        0.053380336612463,
        -0.018899204209446907,
        0.024705056101083755,
        -0.011050260625779629,
        0.054211992770433426,
        0.05418422818183899,
        0.05362028628587723,
        0.04706932604312897,
        -0.014618629589676857,
        -0.05414885655045509,
        0.0307427067309618,
        -0.05002792924642563,
        0.05432368814945221,
        -0.05327444523572922,
        -0.052109647542238235,
        0.0016592906322330236,
        0.05332593247294426,
        -0.03319435939192772,
        -0.0539836511015892,
        0.03566565364599228,
        0.04396916180849075,
        -0.031045518815517426,
        -0.015954094007611275,
        -0.03929924592375755,
        0.05405496060848236,
        0.04088587686419487,
        0.018393859267234802,
        0.01725825108587742,
        -0.05294647812843323,
        0.049254003912210464,
        -0.05431400239467621,
        0.0542895644903183,
        0.044090379029512405,
        0.03791056200861931,
        -0.05409965664148331,
        0.05408043414354324,
        0.054196909070014954,
        -0.05249946191906929,
        -0.02903462015092373,
        0.00429402245208621,
        0.0536801815032959,
        -0.05186702311038971,
        0.04839207977056503,
        -0.034755535423755646,
        0.054082829505205154,
        0.05318992957472801,
        0.007648025173693895,
        -0.05420053005218506,
        -0.05398426204919815,
        0.04718687757849693,
        -0.05366560071706772,
        -0.0340789332985878,
        -0.050848014652729034,
        0.03183666244149208,
        0.051983460783958435,
        0.05313529819250107,
        0.054195474833250046,
        0.041489943861961365,
        0.0500524677336216,
        -0.00033156300196424127,
        0.00438949977979064,
        0.028394827619194984,
        0.05128513649106026,
        -0.023749573156237602,
        0.042170844972133636,
        -0.03165644034743309,
        -0.051996078342199326,
        0.050652697682380676,
        -0.05147325620055199,
        0.05299278721213341,
        -0.05208851024508476,
        -0.03234601765871048,
        -0.053005997091531754,
        0.00010959790961351246,
        0.05417323857545853,
        -0.04789497330784798,
        0.053189460188150406,
        -0.053139302879571915,
        0.03793119639158249,
        -0.00893289502710104,
        -0.05428558215498924,
        -0.047489527612924576,
        0.052289918065071106,
        -0.0492989718914032,
        0.04781908541917801,
        -0.04513261467218399,
        -0.0519624687731266,
        -0.04132114350795746,
        0.02979757823050022,
        0.05079759657382965,
        0.052150484174489975,
        0.045892275869846344,
        -0.015325617045164108,
        0.053818561136722565,
        0.05401460826396942,
        -0.05097956955432892,
        0.04668158292770386,
        0.05431676283478737,
        0.0009435019455850124,
        0.05413781479001045,
        0.04780147597193718,
        0.05340421572327614,
        0.03072291985154152,
        -0.03337012976408005,
        0.048545680940151215,
        0.047817815095186234,
        -0.04724085330963135,
        -0.01891493983566761,
        -0.05429590120911598,
        -0.05317232757806778,
        -0.04909750819206238,
        -0.05416522175073624,
        0.03298715502023697,
        0.0541362501680851,
        -0.03005249798297882,
        -0.05389120802283287,
        0.04756781831383705,
        0.03763686865568161,
        -0.037753086537122726,
        0.019904205575585365,
        0.03263572230935097,
        0.054311007261276245,
        0.05429481714963913,
        0.05409508943557739,
        0.05117391422390938,
        -0.042030684649944305,
        0.05289432033896446,
        -0.05131757631897926,
        0.03549259528517723,
        -0.04832105338573456,
        0.005316905211657286,
        -0.05425499379634857,
        -0.054300885647535324,
        0.005228852853178978,
        -0.03506464883685112,
        0.05231330171227455,
        -0.05432546138763428,
        -0.053589217364788055,
        -0.054320309311151505,
        -0.05354534462094307,
        0.045824747532606125,
        0.045664895325899124,
        0.04985780641436577,
        0.05044541880488396,
        -0.021967630833387375,
        0.032685186713933945,
        0.04395487904548645,
        -0.031362127512693405,
        -0.016032258048653603,
        0.02138497866690159,
        0.013226516544818878,
        -0.031887464225292206,
        -0.03278879448771477,
        -0.0513414591550827,
        0.05392758175730705,
        -0.05195328965783119,
        0.0050065359100699425,
        0.05283334106206894,
        -0.052843064069747925,
        0.001452046912163496,
        -0.032212574034929276,
        0.04889214038848877,
        0.044728051871061325,
        -0.05158603936433792,
        -0.020453600212931633,
        0.05415170267224312,
        -0.03781614825129509,
        -0.05412745103240013,
        0.04717318341135979,
        0.054170604795217514,
        0.03688707947731018,
        0.053215499967336655,
        -0.054216835647821426,
        -0.05410648509860039,
        0.03548668697476387,
        -0.019502706825733185,
        0.05424437299370766,
        -0.054267145693302155,
        0.05346401780843735,
        -0.053821247071027756,
        0.046350374817848206,
        -0.028658542782068253,
        0.021866781637072563,
        0.04136285558342934,
        0.05354667454957962,
        -0.05385478213429451,
        -0.021217625588178635,
        -0.05301893502473831,
        0.045863281935453415,
        -0.033026982098817825,
        0.028457673266530037,
        -0.052656881511211395,
        0.045984357595443726,
        0.051997069269418716,
        -0.042777229100465775,
        0.050777651369571686,
        0.04772604629397392,
        -0.034959279000759125,
        -0.031857166439294815,
        0.05317586660385132,
        0.03836815804243088,
        -0.017318470403552055,
        0.053526442497968674,
        0.05247431993484497,
        0.014108276925981045,
        0.05419115722179413,
        -0.025907743722200394,
        0.05238083750009537,
        0.03841809928417206,
        0.039883337914943695,
        0.04503189027309418,
        -0.03215347230434418,
        0.054278623312711716,
        0.05149412527680397,
        -0.0529802106320858,
        -0.054053522646427155,
        -0.053136032074689865,
        -0.02698070928454399,
        0.05351007357239723,
        -0.05394953489303589,
        -0.048822153359651566,
        -0.04920952022075653,
        -0.009924786165356636,
        -0.05277283117175102,
        0.05383175611495972,
        -0.04898164048790932,
        0.05036504939198494,
        -0.0541580431163311,
        -0.04381966218352318,
        -0.05246607959270477,
        0.05431217700242996,
        -0.053957488387823105,
        0.04236439988017082,
        -0.0005658894078806043,
        0.005566626787185669,
        -0.047043733298778534,
        0.046388935297727585,
        -0.017465971410274506,
        -0.016839563846588135,
        0.03844728693366051,
        -0.05403391644358635,
        0.044989947229623795,
        0.05401081219315529,
        -0.05333781614899635,
        0.04021270573139191,
        -0.03140423819422722,
        -0.053598396480083466,
        -0.0045782127417624,
        -0.019287917762994766,
        -0.05336912348866463,
        -0.054118357598781586,
        -0.04052429273724556,
        -0.03643985465168953,
        0.053782541304826736,
        0.05417024344205856,
        -0.012657182291150093,
        -0.05399928614497185,
        -0.05261846259236336,
        -0.04906289651989937,
        0.05428500100970268,
        -0.053562164306640625,
        0.0523732453584671,
        0.03843594714999199,
        -0.05397617071866989,
        0.052011970430612564,
        -0.029654480516910553,
        0.05368167534470558,
        -0.03761151432991028,
        0.04409120976924896,
        -0.02043294534087181,
        -0.05067676678299904,
        0.044921424239873886,
        0.03843985125422478,
        -0.039793968200683594,
        -0.03865227848291397,
        -0.022935733199119568,
        0.01273257378488779,
        0.013187005184590816,
        0.020571883767843246,
        -0.049668554216623306,
        0.05382435768842697,
        -0.054251089692115784,
        -0.05061877891421318,
        -0.04905126616358757,
        -0.04314534366130829,
        -0.05411948636174202,
        -0.02657189965248108,
        -0.051861703395843506,
        0.029127830639481544,
        0.02138146199285984,
        0.05117521435022354,
        -0.046000052243471146,
        -0.05331617593765259,
        -0.054209377616643906,
        -0.032591599971055984,
        0.0445021353662014,
        -0.01710968092083931,
        -0.043579068034887314,
        -0.054242294281721115,
        0.022499920800328255,
        0.05292472615838051,
        0.05312024801969528,
        -0.04983736202120781,
        -0.0417451336979866,
        0.0540727823972702,
        -0.053128696978092194,
        0.051068585366010666,
        -0.018839867785573006,
        -0.053722575306892395,
        -0.023737825453281403,
        0.04010967165231705,
        -0.0063133942894637585,
        -0.020168466493487358,
        -0.03626127541065216,
        -0.052511315792798996,
        -0.034290846437215805,
        0.0409734845161438,
        0.05379581078886986,
        0.020522864535450935,
        0.023655062541365623,
        -0.008017843589186668,
        0.042357273399829865,
        0.023831909522414207,
        0.05416155979037285,
        -0.054187506437301636,
        0.04930153861641884,
        0.05251268669962883,
        -0.05422975495457649,
        0.054265059530735016,
        -0.05379149317741394,
        0.047952719032764435,
        0.05431504175066948,
        0.040582600980997086,
        0.04668547958135605,
        -0.04804597049951553,
        0.05423431843519211,
        0.005973402410745621,
        -0.0501483678817749,
        0.052638646215200424,
        0.011957954615354538,
        0.05342964082956314,
        0.0468733049929142,
        0.05306347832083702,
        0.05374287813901901,
        0.05398184433579445,
        0.040965087711811066,
        -0.053798943758010864,
        -0.05222579836845398,
        -0.01947093941271305,
        -0.03737112134695053,
        0.005421845708042383,
        0.05067030340433121,
        -0.017594896256923676,
        0.03341040760278702,
        -0.054312173277139664,
        -0.05300327017903328,
        0.05416233837604523,
        -0.05377171188592911,
        0.05223721265792847,
        -0.029707683250308037,
        0.0287794042378664,
        0.046340759843587875,
        -0.033865075558423996,
        0.022602900862693787,
        -0.045017924159765244,
        -0.0522746667265892,
        0.05294322222471237,
        -0.054278258234262466,
        0.05417153611779213,
        -0.011274667456746101
    ],
    [
        -0.048904333263635635,
        0.021744471043348312,
        -0.017601439729332924,
        0.046793628484010696,
        -0.0499507337808609,
        0.050295308232307434,
        0.050518762320280075,
        -0.05057508125901222,
        0.050569452345371246,
        0.050576645880937576,
        0.046604450792074203,
        0.05057889223098755,
        -0.050552722066640854,
        0.04406154900789261,
        -0.05051906034350395,
        0.04405291751027107,
        0.049960892647504807,
        0.05048725754022598,
        -0.012084177695214748,
        -0.03874644637107849,
        0.04769923910498619,
        -0.04161960259079933,
        -0.01065762061625719,
        0.04066929593682289,
        -0.012973436154425144,
        0.05022186040878296,
        0.050561800599098206,
        0.05053338780999184,
        -0.043509114533662796,
        -0.05051618814468384,
        -0.050579678267240524,
        0.0505792461335659,
        -0.01203856896609068,
        -0.04683301970362663,
        0.05047370493412018,
        -0.048770368099212646,
        -0.050210192799568176,
        -0.04403034597635269,
        -0.016606910154223442,
        -0.04773280397057533,
        0.047416843473911285,
        0.04868762940168381,
        0.022459950298070908,
        0.05052005872130394,
        0.038485608994960785,
        -0.047838348895311356,
        -0.037544604390859604,
        0.05056155472993851,
        0.050579652190208435,
        -0.04309196397662163,
        0.050504013895988464,
        0.01127797830849886,
        -0.01582622155547142,
        -0.050504785031080246,
        -0.03838609904050827,
        -0.04980193078517914,
        -0.0498746819794178,
        0.008050904609262943,
        -0.05056462809443474,
        -0.04790954664349556,
        -0.05057678371667862,
        0.035970158874988556,
        -0.05057580769062042,
        -0.02332119829952717,
        0.05057375505566597,
        0.04263667017221451,
        -0.05045142397284508,
        0.050411634147167206,
        -0.050531595945358276,
        -0.044940005987882614,
        -0.05056116729974747,
        -0.04418271780014038,
        0.05047192424535751,
        0.05054684355854988,
        0.034381408244371414,
        -0.049302179366350174,
        0.035217732191085815,
        0.050579242408275604,
        0.05057726800441742,
        -0.049581967294216156,
        -0.04527171328663826,
        -0.0478791780769825,
        -0.04851962625980377,
        0.05057097226381302,
        0.04911027103662491,
        0.0499749630689621,
        -0.0488634929060936,
        0.05056583508849144,
        0.05033191293478012,
        0.050085876137018204,
        -0.050484973937273026,
        0.05057835206389427,
        -0.012705902568995953,
        -0.04452032968401909,
        -0.05057292804121971,
        0.02101985551416874,
        -0.02902589552104473,
        -0.050185270607471466,
        0.050579506903886795,
        0.05045492202043533,
        -0.05057942122220993,
        0.05057067796587944,
        0.0505223274230957,
        -0.049928221851587296,
        0.041052885353565216,
        -0.009698666632175446,
        -0.050570324063301086,
        0.04992116242647171,
        -0.05057930201292038,
        -0.049586981534957886,
        -0.050579555332660675,
        0.007978235371410847,
        -0.0450335368514061,
        0.042438190430402756,
        0.043876439332962036,
        0.03362865000963211,
        0.05057627335190773,
        -0.01710703782737255,
        -0.05042542144656181,
        -0.050563085824251175,
        0.03155580908060074,
        -0.04458604380488396,
        0.050562627613544464,
        -0.050470639020204544,
        -0.036386046558618546,
        -0.05057954043149948,
        -0.04855887591838837,
        0.05028025805950165,
        0.050267159938812256,
        -0.04555763304233551,
        0.05039680749177933,
        0.04942386597394943,
        -0.050437383353710175,
        -0.016877636313438416,
        -0.050243087112903595,
        -0.04977109283208847,
        0.05057968944311142,
        0.047093044966459274,
        -0.031117679551243782,
        -0.04689394310116768,
        0.043566856533288956,
        0.04439694061875343,
        0.047503646463155746,
        0.050558529794216156,
        -0.049854110926389694,
        0.04972681775689125,
        0.05050981417298317,
        -0.05022304505109787,
        0.0502421073615551,
        0.022417157888412476,
        -0.04808362200856209,
        -0.05057399719953537,
        -0.02966446615755558,
        -0.03440626338124275,
        0.050552334636449814,
        -0.05057908594608307,
        -0.05057865381240845,
        0.014947539195418358,
        0.028454259037971497,
        0.05044825002551079,
        0.02802763134241104,
        -0.046125609427690506,
        0.04648704454302788,
        0.05029972642660141,
        -0.050119075924158096,
        -0.04766860604286194,
        0.05057968199253082,
        -0.04086977615952492,
        -0.031975481659173965,
        0.04868156835436821,
        0.04962226375937462,
        -0.041960567235946655,
        -0.05057765915989876,
        0.050563544034957886,
        -0.0020382616203278303,
        -0.007882419973611832,
        -0.04680749028921127,
        -0.050381146371364594,
        0.04923199862241745,
        -0.05050601810216904,
        -0.04981556907296181,
        0.04876459017395973,
        0.050472527742385864,
        -0.036871157586574554,
        0.00794568657875061,
        -0.050190288573503494,
        0.046943970024585724,
        0.026319894939661026,
        0.05057745799422264,
        -0.05051462724804878,
        -0.05044398829340935,
        -0.03800043836236,
        0.0357845202088356,
        0.045543137937784195,
        0.04881904274225235,
        0.05009279400110245,
        0.0504932776093483,
        0.05055216699838638,
        0.04958036169409752,
        0.023412620648741722,
        0.013677924871444702,
        0.04184596613049507,
        -0.01450867298990488,
        0.05046745017170906,
        0.048584457486867905,
        0.04101325199007988,
        0.04960734397172928,
        -0.026973938569426537,
        -0.05031654238700867,
        0.04965171590447426,
        -0.04912028834223747,
        0.050362300127744675,
        -0.023256169632077217,
        0.05018734186887741,
        -0.048896271735429764,
        -0.02456735074520111,
        -0.042870018631219864,
        0.0271112322807312,
        0.05024297535419464,
        -0.026006199419498444,
        -0.03904356062412262,
        0.0502220094203949,
        -0.04991493374109268,
        0.024992190301418304,
        0.05056456848978996,
        -0.050344180315732956,
        0.04385553300380707,
        0.027285294607281685,
        -0.043745897710323334,
        -0.049898695200681686,
        0.042621057480573654,
        -0.042549505829811096,
        0.046629346907138824,
        0.05008537322282791,
        -0.04059772193431854,
        0.04907285422086716,
        0.05057048052549362,
        0.023805590346455574,
        0.05056462809443474,
        0.05054307356476784,
        0.04532952234148979,
        0.047286104410886765,
        0.038101498037576675,
        0.03367702662944794,
        0.050575949251651764,
        0.050577711313962936,
        0.011269615031778812,
        0.050525739789009094,
        0.05055021122097969,
        0.02944042719900608,
        -0.050210487097501755,
        -0.05052896961569786,
        -0.04897229000926018,
        -0.05057590454816818,
        -0.021767934784293175,
        0.04476238414645195,
        -0.0011770394630730152,
        -0.05010951682925224,
        0.05051363632082939,
        0.05055151507258415,
        0.031673189252614975,
        0.047791171818971634,
        -0.04952479153871536,
        0.05007140710949898,
        -0.03893810510635376,
        0.050536222755908966,
        0.05041356012225151,
        -0.05047864094376564,
        0.034930747002363205,
        -0.042549826204776764,
        0.044209517538547516,
        -0.04403093829751015,
        0.05008020997047424,
        -0.049765247851610184,
        -0.05049899220466614,
        0.05051810294389725,
        -0.04996923357248306,
        0.05017666518688202,
        -0.05057968571782112,
        -0.0505419597029686,
        -0.05057119205594063,
        0.050579629838466644,
        0.03144757077097893,
        -0.025165816769003868,
        0.05057968944311142,
        -0.03644251078367233,
        0.00902598723769188,
        0.049444545060396194,
        0.05038774013519287,
        0.043108269572257996,
        0.044504377990961075,
        -0.007482066284865141,
        -0.05045636370778084,
        0.032740090042352676,
        -0.0378120131790638,
        -0.049708474427461624,
        0.05055129528045654,
        0.03456716984510422,
        -0.045109815895557404,
        0.05057966709136963,
        0.05057196319103241,
        0.03105897270143032,
        0.03327200561761856,
        0.050564952194690704,
        0.012360299937427044,
        0.04643208906054497,
        -0.04931974038481712,
        -0.036165982484817505,
        -0.04163398593664169,
        -0.04313815385103226,
        0.03686904534697533,
        -0.041959527879953384,
        0.05057068169116974,
        -0.014857867732644081,
        -0.05054989829659462,
        -0.05056183412671089,
        -0.05052231624722481,
        -0.04932839423418045,
        0.050509434193372726,
        -0.014938538894057274,
        0.050249774008989334,
        -0.05049519240856171,
        0.045731279999017715,
        -0.04826659709215164,
        0.050579655915498734,
        -0.043670300394296646,
        0.05057963728904724,
        -0.0505666583776474,
        0.04968072474002838,
        -0.050578512251377106,
        0.04878366366028786,
        0.04536059871315956,
        -0.04971912130713463,
        -0.04898738116025925,
        0.0505671501159668,
        0.04779087379574776,
        -0.02865619584918022,
        0.04074439778923988,
        -0.017440279945731163,
        0.0406532920897007,
        0.002520378679037094,
        0.050355397164821625,
        0.05057552456855774,
        -0.020342858508229256,
        0.049176882952451706,
        0.04520820453763008,
        0.032372765243053436,
        0.05057847499847412,
        -0.01644264906644821,
        0.050569798797369,
        0.05016995593905449,
        -0.017553240060806274,
        0.03942321613430977,
        -0.05050457641482353,
        0.050576794892549515,
        0.05019091069698334,
        -0.050577543675899506,
        -0.05055740848183632,
        -0.04954930394887924,
        -0.04925868660211563,
        0.050126995891332626,
        -0.021992243826389313,
        -0.023687224835157394,
        -0.03226286172866821,
        0.048739392310380936,
        -0.05056983232498169,
        0.050287723541259766,
        -0.05057899281382561,
        0.04269661381840706,
        -0.04981008917093277,
        -0.04986617714166641,
        -0.04689137637615204,
        0.05045432224869728,
        0.008953338488936424,
        0.04717540740966797,
        -0.04865002632141113,
        -0.04965659976005554,
        0.050543125718832016,
        0.05057311803102493,
        -0.003510724753141403,
        0.049388013780117035,
        0.05057346448302269,
        -0.050530388951301575,
        0.024817703291773796,
        0.0505681112408638,
        0.0020723326597362757,
        -0.04464630410075188,
        0.05047471448779106,
        -0.05038050189614296,
        -0.042804084718227386,
        -0.04915602505207062,
        -0.03250633925199509,
        -0.05013477802276611,
        -0.04970892146229744,
        0.040926333516836166,
        0.04999135807156563,
        0.050491541624069214,
        0.011748718097805977,
        -0.050483353435993195,
        -0.05057953670620918,
        -0.04985256865620613,
        0.05057871714234352,
        -0.05056590586900711,
        0.05057565122842789,
        -0.049545302987098694,
        -0.028331870213150978,
        0.04051407426595688,
        -0.05035107582807541,
        0.05057961493730545,
        -0.03872038424015045,
        -0.00989192258566618,
        0.03112749196588993,
        0.03987237811088562,
        0.04778637737035751,
        -0.050571341067552567,
        -0.03438517823815346,
        -0.006006365641951561,
        -0.050553396344184875,
        0.049474477767944336,
        -0.03464740514755249,
        0.05017666518688202,
        0.043415069580078125,
        -0.04757913574576378,
        -0.05057891830801964,
        -0.044617507606744766,
        -0.05032661184668541,
        -0.00882813148200512,
        -0.049935776740312576,
        0.002859043888747692,
        -0.05056142434477806,
        0.0476919524371624,
        0.043461937457323074,
        0.048926521092653275,
        -0.050274889916181564,
        -0.048846401274204254,
        0.0074653299525380135,
        -0.05057922750711441,
        0.04794067144393921,
        -0.04311931133270264,
        -0.04618757218122482,
        -0.02157757245004177,
        0.029172854498028755,
        0.04790373519062996,
        0.04994828626513481,
        0.022564785555005074,
        -0.026536308228969574,
        0.050392996519804,
        -0.04848960414528847,
        0.04740593954920769,
        0.050510670989751816,
        -0.050107259303331375,
        0.05057194456458092,
        -0.048448752611875534,
        0.04386584833264351,
        -0.050508592277765274,
        -0.0505516454577446,
        -0.0015691702719777822,
        -0.04900282248854637,
        -0.04443750157952309,
        0.03981851413846016,
        0.04627947881817818,
        -0.050579678267240524,
        0.05057865381240845,
        0.049469348043203354,
        0.0497436486184597,
        0.05057226121425629,
        -0.050579678267240524,
        0.05054911598563194,
        -0.050579339265823364,
        -0.05057908594608307,
        0.050577327609062195,
        -0.05004812404513359,
        -0.042452141642570496,
        0.050579480826854706,
        0.05056566745042801,
        0.031800419092178345,
        -0.04458239674568176,
        0.038898635655641556,
        -0.04991042613983154,
        0.016931436955928802,
        0.05055934563279152,
        0.026811765506863594,
        0.05056736245751381,
        0.050579674541950226,
        0.050079096108675,
        0.05057918280363083,
        0.050579458475112915,
        -0.028473855927586555,
        -0.04997919499874115,
        -0.03835105895996094,
        0.03337348997592926,
        -0.05057968944311142,
        0.008147316053509712,
        -0.016126081347465515,
        -0.05040115490555763,
        -0.016693271696567535,
        -0.04912005364894867,
        -0.050500739365816116,
        0.050577569752931595,
        -0.050562284886837006,
        0.021694526076316833,
        -0.025177571922540665,
        0.021836839616298676,
        -0.0024672846775501966,
        0.04895831272006035,
        0.04258640855550766,
        -0.037300534546375275,
        -0.033380091190338135,
        0.05057620629668236,
        -0.050579484552145004,
        0.05057968944311142,
        -0.02003500983119011
    ],
    [
        -0.05626361444592476,
        0.02056148461997509,
        0.05678972601890564,
        -0.0026845685206353664,
        0.04929053783416748,
        0.056690897792577744,
        0.05681529641151428,
        -0.054798584431409836,
        -0.03506660833954811,
        0.055545203387737274,
        -0.00874030590057373,
        0.009379993192851543,
        -0.052169833332300186,
        -0.01960016041994095,
        -0.05613384395837784,
        0.05611449107527733,
        -0.05426605045795441,
        -0.013382264412939548,
        -0.031493429094552994,
        0.05556691065430641,
        -0.056431226432323456,
        -0.05504852905869484,
        -0.056539103388786316,
        0.02665749005973339,
        0.015757400542497635,
        -0.04598641395568848,
        0.04489920288324356,
        0.04931988939642906,
        -0.05402931571006775,
        -0.047050684690475464,
        0.011585123836994171,
        0.036247704178094864,
        0.0421689972281456,
        -0.054195113480091095,
        -0.012093656696379185,
        -0.04472468048334122,
        -0.046831049025058746,
        -0.006529669277369976,
        0.014872249215841293,
        0.04716864973306656,
        0.010940225794911385,
        -0.0076650348491966724,
        0.05013490095734596,
        -0.017881343141198158,
        0.03951586037874222,
        -0.05577966198325157,
        -0.036578334867954254,
        0.05668451637029648,
        0.05640452355146408,
        0.05267086625099182,
        0.04096421226859093,
        0.018182143568992615,
        0.05668829008936882,
        0.013928929343819618,
        -0.05121311545372009,
        -0.056775059551000595,
        -0.05645056068897247,
        -0.03495936095714569,
        -0.05417237430810928,
        0.02968946099281311,
        -0.04888896644115448,
        -0.012574153020977974,
        -0.05400731787085533,
        -0.05667625367641449,
        0.056576743721961975,
        0.05424583703279495,
        0.0541008822619915,
        0.020637424662709236,
        0.022760961204767227,
        -0.05682452395558357,
        -0.05570683628320694,
        -0.05675967037677765,
        0.05597321316599846,
        -0.008519723080098629,
        -0.05680783465504646,
        0.02778719738125801,
        -0.020466502755880356,
        0.027993042021989822,
        0.053203996270895004,
        -0.05467885732650757,
        -0.04457452893257141,
        0.04905521497130394,
        -0.02560708113014698,
        0.02429322339594364,
        0.018688587471842766,
        0.03175412863492966,
        -0.05192769318819046,
        -0.026121994480490685,
        0.03525179252028465,
        0.05340268090367317,
        -0.05461479723453522,
        0.05465013161301613,
        0.045130010694265366,
        -0.03990968316793442,
        0.02599346823990345,
        -0.0049305506981909275,
        -0.04253825917840004,
        -0.05439399927854538,
        -0.03993251547217369,
        -0.028020279482007027,
        -0.05668622627854347,
        -0.019379673525691032,
        0.0567019097507,
        0.055222298949956894,
        -0.008403327316045761,
        -0.0377657413482666,
        -0.04026814177632332,
        -0.03759307786822319,
        -0.056043438613414764,
        -0.04733826592564583,
        0.016045542433857918,
        0.0266403928399086,
        -0.02340378798544407,
        -0.0548333041369915,
        0.054281339049339294,
        -0.048533763736486435,
        -0.027434509247541428,
        -0.05180146545171738,
        -0.05659594386816025,
        -0.04953235015273094,
        0.04676169902086258,
        0.04584301635622978,
        -0.030475059524178505,
        0.053925320506095886,
        -0.056674569845199585,
        -0.001209976035170257,
        -0.010663872584700584,
        0.05677493289113045,
        0.05391458794474602,
        -0.05566057935357094,
        0.019978515803813934,
        -0.04736078158020973,
        -0.05038382112979889,
        -0.004467951599508524,
        -0.053405970335006714,
        -0.0459379181265831,
        0.03387328237295151,
        -0.054564218968153,
        -0.03989654406905174,
        0.05415366217494011,
        0.056770361959934235,
        0.05169147253036499,
        0.03881141170859337,
        0.04814743623137474,
        -0.04752204194664955,
        0.03686464950442314,
        0.0566265732049942,
        0.030706271529197693,
        -0.05295153334736824,
        0.007227982394397259,
        -0.028284680098295212,
        -0.05617676302790642,
        -0.05666406452655792,
        0.03873874619603157,
        0.056825149804353714,
        -0.0464884415268898,
        -0.03495948016643524,
        -0.043395936489105225,
        0.022828085348010063,
        -0.019749676808714867,
        0.04512840136885643,
        0.010859325528144836,
        0.01801629550755024,
        -0.005199547857046127,
        -0.05561504885554314,
        -0.042193058878183365,
        0.05597732588648796,
        -0.051223669201135635,
        -0.01955869235098362,
        0.05222612991929054,
        -0.030009137466549873,
        0.05246572196483612,
        -0.05416836589574814,
        0.05653490871191025,
        -0.006622820161283016,
        0.05635575205087662,
        -0.053472261875867844,
        0.021538816392421722,
        0.043790604919195175,
        0.027931679040193558,
        0.05304667726159096,
        -0.04917231202125549,
        0.05680973455309868,
        -0.01778220571577549,
        0.03930918499827385,
        0.02568805404007435,
        -0.055975690484046936,
        0.05570941045880318,
        -0.05664277449250221,
        -0.05537358671426773,
        0.0008045312133617699,
        -0.036706604063510895,
        -0.04339548200368881,
        0.04638601839542389,
        -0.024055683985352516,
        -0.031265854835510254,
        0.05665836110711098,
        0.05624621734023094,
        0.039785236120224,
        0.008221657946705818,
        -0.02795402705669403,
        0.04954693093895912,
        0.033731404691934586,
        -0.03405369445681572,
        0.054808489978313446,
        0.05122942477464676,
        0.017136259004473686,
        -0.05497245490550995,
        -0.04734344407916069,
        0.05564313009381294,
        -0.046180371195077896,
        0.010760671459138393,
        -0.05564107373356819,
        -0.04476703330874443,
        0.04752390831708908,
        -0.043729785829782486,
        0.01716102473437786,
        -0.025197884067893028,
        0.05677362531423569,
        -0.055694520473480225,
        -0.044621847569942474,
        -0.0343625545501709,
        -0.05625190585851669,
        -0.05614802986383438,
        0.05668116733431816,
        -0.05682503059506416,
        0.02420252375304699,
        0.006766314618289471,
        -0.006567815784364939,
        -0.05433836206793785,
        0.03173181414604187,
        -0.05203083157539368,
        -0.054619260132312775,
        0.053385574370622635,
        0.003147814190015197,
        0.015620904974639416,
        0.05299895256757736,
        -0.012528915889561176,
        0.005782459396868944,
        0.049523983150720596,
        0.05643554404377937,
        0.026789655908942223,
        -0.041309915482997894,
        0.049112748354673386,
        0.0041897050105035305,
        -0.046112872660160065,
        0.05646718665957451,
        0.018608422949910164,
        -0.05530975013971329,
        -0.04273815453052521,
        -0.039410289376974106,
        -0.051714155822992325,
        0.011334840208292007,
        -0.04762767255306244,
        0.024881696328520775,
        0.05672864988446236,
        -0.05485517904162407,
        -0.036449577659368515,
        0.05666175112128258,
        0.056019376963377,
        0.05112577974796295,
        0.05679129436612129,
        0.055916476994752884,
        0.033164460211992264,
        0.0567590594291687,
        0.03127792477607727,
        0.04545027017593384,
        0.04832090437412262,
        -0.01369777973741293,
        0.007882102392613888,
        -0.055624380707740784,
        -0.05676842853426933,
        -0.049569450318813324,
        -0.05600254237651825,
        -0.05679198354482651,
        0.04392575845122337,
        -0.054934270679950714,
        0.05663619562983513,
        -0.05350610241293907,
        -0.0568121038377285,
        -0.054090049117803574,
        -0.056415583938360214,
        0.05148685723543167,
        -0.04247799888253212,
        -0.05147552490234375,
        -0.056500427424907684,
        0.04295859485864639,
        -0.051699329167604446,
        -0.044895611703395844,
        0.007253131829202175,
        0.03943437710404396,
        0.053046051412820816,
        -0.031085239723324776,
        -0.044164028018713,
        -0.026676524430513382,
        -0.052771396934986115,
        0.056801024824380875,
        0.005261980462819338,
        0.03479284048080444,
        0.05434668809175491,
        -0.019102897495031357,
        0.05395021662116051,
        0.013313064351677895,
        0.0468754805624485,
        0.0019030880648642778,
        -0.04318583756685257,
        -0.05596116557717323,
        0.05071703717112541,
        0.03522633761167526,
        -0.05658191815018654,
        -0.024631094187498093,
        -0.03494787588715553,
        -0.01625244691967964,
        0.05604828894138336,
        -0.05603253096342087,
        -0.04602334648370743,
        0.05612201616168022,
        -0.055146723985672,
        0.03448661044239998,
        -0.054280836135149,
        -0.01004251092672348,
        -0.039072565734386444,
        0.055900271981954575,
        0.019431976601481438,
        0.03897750750184059,
        0.056579940021038055,
        0.041941434144973755,
        -0.007886760868132114,
        -0.033538784831762314,
        -0.041733693331480026,
        -0.05643657594919205,
        0.030015114694833755,
        0.03058338351547718,
        -0.05574006587266922,
        0.055014465004205704,
        -0.008015737868845463,
        -0.052209626883268356,
        0.05586184933781624,
        -0.046690329909324646,
        0.056201450526714325,
        0.04684855416417122,
        0.007560917641967535,
        -0.04309077933430672,
        -0.02732851542532444,
        0.05681861564517021,
        0.04780280217528343,
        -0.03448844328522682,
        0.04512530565261841,
        0.05240204185247421,
        0.05483098700642586,
        -0.05679512023925781,
        0.05259593576192856,
        -0.0532953143119812,
        -0.055505380034446716,
        0.01706688478589058,
        0.05222102627158165,
        -0.052476830780506134,
        -0.039559852331876755,
        -0.05414659529924393,
        -0.05502811074256897,
        0.055713824927806854,
        -0.05287681147456169,
        -0.056609559804201126,
        -0.01885255053639412,
        0.04183102399110794,
        -0.017155438661575317,
        0.056806888431310654,
        -0.014834729954600334,
        0.04502018168568611,
        -0.05681619793176651,
        -0.02775713987648487,
        -0.056373320519924164,
        0.05649852007627487,
        -0.044682975858449936,
        0.0556003712117672,
        0.05494608357548714,
        -0.000398119940655306,
        0.048522546887397766,
        -0.029807904735207558,
        0.008819395676255226,
        -0.03681867569684982,
        0.05664762854576111,
        -0.04539429396390915,
        0.018191518262028694,
        0.051250915974378586,
        -0.014596953056752682,
        0.055496182292699814,
        -0.05661844089627266,
        -0.04973112791776657,
        -0.05508628860116005,
        -0.0517587848007679,
        -0.056365836411714554,
        0.04619376361370087,
        0.020003313198685646,
        -0.04598619416356087,
        0.05597040057182312,
        0.056147485971450806,
        -0.01333640981465578,
        -0.050547946244478226,
        -0.056793347001075745,
        -0.04639415815472603,
        0.054957542568445206,
        0.04247645288705826,
        0.05682332068681717,
        0.026113200932741165,
        -0.030068302527070045,
        0.04722313582897186,
        -0.02934877574443817,
        0.05323253199458122,
        -0.050266917794942856,
        0.0540359802544117,
        -0.03589724004268646,
        0.005698573309928179,
        0.030887242406606674,
        0.05541708692908287,
        -0.00027654136647470295,
        0.017985256388783455,
        0.03951653093099594,
        0.019414784386754036,
        -0.04641784727573395,
        -0.05647200718522072,
        -0.030397921800613403,
        -0.04895269125699997,
        -0.053670160472393036,
        0.03026016615331173,
        -0.05110650882124901,
        0.020900094881653786,
        -0.04715486988425255,
        -0.05623319745063782,
        -0.00020286516519263387,
        -0.0009944791672751307,
        -0.010190630331635475,
        0.04199571907520294,
        -0.045581091195344925,
        0.05308385193347931,
        -0.03862372413277626,
        -0.01707901805639267,
        0.047998879104852676,
        0.047113899141550064,
        -0.051390618085861206,
        0.013436410576105118,
        0.05139538273215294,
        0.05605798214673996,
        0.0526227131485939,
        0.04959680885076523,
        0.032806601375341415,
        0.05590147525072098,
        0.035546232014894485,
        0.05430310219526291,
        -0.05003451183438301,
        -0.04721711948513985,
        0.044561825692653656,
        0.05668997764587402,
        -0.02182767726480961,
        0.046918440610170364,
        -0.017807980999350548,
        0.043879810720682144,
        0.0007226933958008885,
        -0.008020777255296707,
        0.050398796796798706,
        0.030372293666005135,
        0.05560225248336792,
        0.00976397655904293,
        0.0545753613114357,
        0.056126926094293594,
        0.056694578379392624,
        -0.05670854076743126,
        0.056565865874290466,
        -0.047540757805109024,
        -0.05625176057219505,
        0.05613803490996361,
        -0.054151587188243866,
        0.05410407483577728,
        0.04692666232585907,
        0.04291936382651329,
        0.05289805307984352,
        -0.002284437417984009,
        -0.005660668481141329,
        -0.036092884838581085,
        -0.04566055163741112,
        0.056818488985300064,
        -0.04071115329861641,
        0.030287569388747215,
        0.001848109532147646,
        0.012483223341405392,
        0.05541339889168739,
        0.037422336637973785,
        -0.04500073194503784,
        -0.053106725215911865,
        -0.05211208388209343,
        -0.04891006276011467,
        -0.05613113194704056,
        -0.055946022272109985,
        0.01670258119702339,
        0.05535445362329483,
        0.05294397845864296,
        -0.05261867493391037,
        0.009197401814162731,
        0.04141148179769516,
        0.04232453182339668,
        0.056724969297647476,
        -0.04452608525753021,
        0.03857119381427765,
        0.055525582283735275,
        -0.04960675165057182,
        0.0178825780749321,
        -0.054675113409757614,
        -0.04799870774149895,
        -0.05531744286417961,
        -0.05520150065422058,
        0.05681876838207245,
        0.041037701070308685
    ],
    [
        0.04503105580806732,
        -0.05219992622733116,
        0.05365343764424324,
        0.033668842166662216,
        0.04918639361858368,
        -0.03432026877999306,
        0.052702080458402634,
        -0.05297236889600754,
        0.053320299834012985,
        -0.051213283091783524,
        0.05362781509757042,
        -0.0004217976238578558,
        -0.05314689502120018,
        -0.05289740115404129,
        -0.050707895308732986,
        0.051455043256282806,
        0.004575696308165789,
        0.05335844308137894,
        -0.05291333422064781,
        0.05246846005320549,
        -0.030171124264597893,
        0.00957567524164915,
        0.05328340828418732,
        -0.0029204976744949818,
        -0.05358981341123581,
        -0.0463387630879879,
        0.05354152247309685,
        0.05181421712040901,
        -0.015599282458424568,
        -0.0409262478351593,
        -0.04988446459174156,
        0.04835245758295059,
        0.052553690969944,
        -0.053647417575120926,
        0.052409861236810684,
        -0.053335532546043396,
        -0.05058858171105385,
        0.022674551233649254,
        -0.05363982915878296,
        0.05292193964123726,
        -0.051286108791828156,
        0.0351933129131794,
        0.01785408891737461,
        -0.03603888675570488,
        0.04415721818804741,
        -0.037325356155633926,
        -0.03348010405898094,
        0.045259106904268265,
        0.05368295684456825,
        -0.053175345063209534,
        0.05175688490271568,
        -0.04290124028921127,
        -0.04305972158908844,
        0.001655258354730904,
        0.01807263493537903,
        0.03537990152835846,
        -0.05197844281792641,
        0.00879545696079731,
        -0.05284915119409561,
        -0.053662750869989395,
        -0.0534483902156353,
        0.050747767090797424,
        -0.052877236157655716,
        -0.039382725954055786,
        0.05284014716744423,
        0.053423285484313965,
        -0.053633298724889755,
        -0.012511377222836018,
        0.04626334458589554,
        -0.039843302220106125,
        0.021279675886034966,
        -0.052640337496995926,
        0.051473043859004974,
        0.053673069924116135,
        0.05325724557042122,
        0.0224758367985487,
        -0.05214204266667366,
        0.05368001386523247,
        0.05358058959245682,
        -0.04713837057352066,
        -0.04331466928124428,
        -0.05366393178701401,
        -0.05353306233882904,
        0.053495973348617554,
        0.02295026369392872,
        0.024975812062621117,
        -0.024978691712021828,
        -0.05336933955550194,
        0.045169949531555176,
        -0.013953249901533127,
        -0.05322821065783501,
        0.030024373903870583,
        -0.017326384782791138,
        -0.017517155036330223,
        -0.053357068449258804,
        0.04000169411301613,
        0.049067821353673935,
        -0.053482964634895325,
        -0.019695011898875237,
        0.03806517645716667,
        -0.053591202944517136,
        0.048152707517147064,
        0.04416968673467636,
        0.05330817028880119,
        0.00884037371724844,
        -0.053611066192388535,
        -0.01794390194118023,
        0.03309362381696701,
        -0.05305330455303192,
        0.05189722776412964,
        -0.05109375715255737,
        0.0011844856198877096,
        -0.052386511117219925,
        0.05201442167162895,
        0.05354506894946098,
        0.04263543337583542,
        -0.016435911878943443,
        -0.05217551440000534,
        -0.003657354973256588,
        -0.05362818017601967,
        0.0281118955463171,
        0.04034668207168579,
        0.042858995497226715,
        -0.05358791723847389,
        -0.05182027071714401,
        0.00760181387886405,
        0.02269062213599682,
        0.053094591945409775,
        0.053501661866903305,
        -0.05212843418121338,
        0.05214101821184158,
        -0.052610792219638824,
        -0.04208935424685478,
        0.05263097956776619,
        -0.05185879021883011,
        -0.05025166645646095,
        0.0526796355843544,
        -0.051321759819984436,
        -0.03053293377161026,
        0.053419359028339386,
        0.05368075892329216,
        -0.031323160976171494,
        0.039728064090013504,
        0.053431399166584015,
        -0.05358705297112465,
        0.05177581310272217,
        0.04818570241332054,
        0.04789869114756584,
        -0.04835040867328644,
        0.01162104681134224,
        -0.039115503430366516,
        -0.05319568142294884,
        0.0014984634472057223,
        -0.053263209760189056,
        0.05366365611553192,
        -0.05365173518657684,
        -0.05363962799310684,
        0.008685251697897911,
        0.047325748950242996,
        -0.0009490427328273654,
        -0.016299299895763397,
        -0.032863929867744446,
        0.048733193427324295,
        -0.05216925963759422,
        0.007240916136652231,
        -0.05051029846072197,
        0.053543705493211746,
        -0.016050854697823524,
        -0.05272660031914711,
        -0.010427926667034626,
        -0.03924247622489929,
        0.05359198525547981,
        -0.0536811389029026,
        0.040123049169778824,
        -0.03292936086654663,
        0.05341792106628418,
        -0.049632728099823,
        -0.026112129911780357,
        0.04064644128084183,
        -0.03413830325007439,
        -0.046905916184186935,
        0.05248435586690903,
        0.05334699898958206,
        -0.022870970889925957,
        0.05115966871380806,
        -0.05025699362158775,
        0.05366164818406105,
        0.05334509164094925,
        0.0444738045334816,
        -0.053555577993392944,
        -0.05363879352807999,
        0.021211590617895126,
        -0.016296083107590675,
        0.038036201149225235,
        0.010714713484048843,
        0.05216124653816223,
        0.051015060395002365,
        0.052726272493600845,
        0.046361010521650314,
        0.05030297115445137,
        0.05303889513015747,
        0.0531725138425827,
        0.04989519342780113,
        -0.03878801688551903,
        0.04804364591836929,
        -0.050849806517362595,
        0.04755682870745659,
        -0.05330098792910576,
        -0.05165475234389305,
        -0.002726723439991474,
        -0.05306461453437805,
        0.053304899483919144,
        0.035321805626153946,
        0.04868311807513237,
        -0.014042082242667675,
        -0.05359392613172531,
        0.05366221442818642,
        -0.015467316843569279,
        0.053640078753232956,
        0.04947548732161522,
        0.05356600880622864,
        0.011518617160618305,
        0.013347458094358444,
        -0.036024946719408035,
        0.05362217128276825,
        -0.05350632220506668,
        0.052891042083501816,
        0.025619300082325935,
        0.05308762565255165,
        -0.05261295288801193,
        0.043981462717056274,
        0.006889769807457924,
        0.005485000088810921,
        0.03851648420095444,
        -0.052349869161844254,
        0.05368287116289139,
        0.050013188272714615,
        0.007423847913742065,
        0.05365835502743721,
        0.05367496982216835,
        0.005171499215066433,
        0.03483361378312111,
        -0.04388268291950226,
        -0.04213269427418709,
        0.053255222737789154,
        -0.04908087104558945,
        0.0346713587641716,
        -0.017586693167686462,
        -0.03977886587381363,
        0.051645420491695404,
        0.04490559175610542,
        -0.04990552365779877,
        -0.0527467355132103,
        -0.05255945026874542,
        0.03749752417206764,
        0.050853293389081955,
        -0.047772347927093506,
        -0.04943440854549408,
        0.0408359058201313,
        0.0350785106420517,
        0.0009811405325308442,
        -0.05262831598520279,
        -0.003757036989554763,
        0.05368155241012573,
        -0.021690919995307922,
        0.05353853106498718,
        0.05108385905623436,
        -0.0331135168671608,
        -0.009449736215174198,
        -0.05215592682361603,
        0.0496821366250515,
        -0.05136566981673241,
        0.035965610295534134,
        -0.05250661447644234,
        -0.047341685742139816,
        -0.05072714388370514,
        -0.04985694959759712,
        0.04291463643312454,
        -0.04942989721894264,
        -0.051650650799274445,
        -0.05362160876393318,
        -0.05017899349331856,
        0.049977514892816544,
        0.0021617321763187647,
        -0.05194750800728798,
        0.022632505744695663,
        0.016824940219521523,
        0.037431422621011734,
        0.04506892338395119,
        0.013309214264154434,
        0.051082778722047806,
        -0.05363810434937477,
        -0.045719072222709656,
        0.005965132731944323,
        -0.04984107241034508,
        -0.05337904766201973,
        0.053389083594083786,
        -0.05316951125860214,
        -0.022826245054602623,
        0.05352524295449257,
        0.05351586267352104,
        0.05013326555490494,
        0.04820782691240311,
        0.05363968759775162,
        -0.051535531878471375,
        0.014440559782087803,
        -0.013804024085402489,
        0.03703027963638306,
        -0.006511705927550793,
        -0.053601182997226715,
        -0.0026569499168545008,
        0.003774582641199231,
        0.053237710148096085,
        -0.051653094589710236,
        -0.05135020986199379,
        -0.0513840988278389,
        -0.031688518822193146,
        -0.05367123335599899,
        0.050979986786842346,
        -0.03945591300725937,
        0.048684846609830856,
        -0.04549650847911835,
        0.041574034839868546,
        -0.05361547693610191,
        0.052982792258262634,
        -0.038834813982248306,
        -0.017947614192962646,
        -0.052914995700120926,
        0.01768834888935089,
        -0.05240772292017937,
        0.0493621788918972,
        -0.0320807583630085,
        -0.02701888605952263,
        -0.053414493799209595,
        0.05297597125172615,
        0.05302514135837555,
        -0.048067476600408554,
        0.05144469812512398,
        -0.02791936881840229,
        -0.0038731207605451345,
        0.041171252727508545,
        0.039668623358011246,
        -0.013519750908017159,
        -0.03646433353424072,
        0.051950450986623764,
        0.052441712468862534,
        0.008886603638529778,
        0.05348866432905197,
        -0.0021951377857476473,
        0.05351564288139343,
        -0.030809232965111732,
        -0.053251348435878754,
        0.04430623725056648,
        -0.05341985449194908,
        -0.024896202608942986,
        0.04000069200992584,
        -0.0530698224902153,
        -0.04048175737261772,
        -0.04135992377996445,
        -0.05227205529808998,
        -0.003863763064146042,
        0.019087117165327072,
        -0.05319960415363312,
        -0.0022652712650597095,
        -0.03556828573346138,
        -0.04839414358139038,
        0.05357113853096962,
        -0.050672151148319244,
        -0.03812889754772186,
        -0.004876403138041496,
        -0.05310075357556343,
        -0.052099090069532394,
        0.04622713476419449,
        -0.02242215722799301,
        0.05276094749569893,
        0.052442438900470734,
        -0.05264947935938835,
        0.05228671804070473,
        0.017023548483848572,
        -0.04521463066339493,
        -0.053287748247385025,
        0.05363737791776657,
        -0.051360055804252625,
        0.04484032839536667,
        0.022027092054486275,
        -0.043929699808359146,
        0.004381002392619848,
        -0.04845084249973297,
        -0.050762563943862915,
        0.038899268954992294,
        -0.053652793169021606,
        -0.05186730995774269,
        -0.05271436646580696,
        -0.05320080742239952,
        -0.050239648669958115,
        0.05239371210336685,
        0.051961228251457214,
        -0.01860569603741169,
        -0.053353291004896164,
        -0.053683530539274216,
        0.025615843012928963,
        0.0536261610686779,
        -0.048410192131996155,
        0.051261305809020996,
        -0.0533469021320343,
        -0.0446029007434845,
        0.04949580505490303,
        -0.0397639125585556,
        0.053681258112192154,
        0.04142767935991287,
        -0.0535476878285408,
        0.051871296018362045,
        -0.0071883792988955975,
        -0.04834100231528282,
        0.05240904912352562,
        -0.05230167880654335,
        -0.05273737385869026,
        -0.050895996391773224,
        0.04554363712668419,
        -0.05120623856782913,
        0.03951328620314598,
        -0.05365884304046631,
        -0.05345594137907028,
        -0.05340896546840668,
        0.03547324240207672,
        -0.052526917308568954,
        -0.037636660039424896,
        -0.013174385763704777,
        -0.02133503369987011,
        -0.05356170982122421,
        0.052292875945568085,
        0.05229772999882698,
        -0.00009616745956009254,
        0.04089588671922684,
        -0.05279918760061264,
        0.010287064127624035,
        -0.037855952978134155,
        0.05069657787680626,
        -0.05190468207001686,
        0.017232997342944145,
        -0.053305353969335556,
        0.053359419107437134,
        -0.0026013129390776157,
        0.05321191996335983,
        0.042644865810871124,
        0.05343146622180939,
        0.05336323752999306,
        -0.04866531491279602,
        0.013696977868676186,
        0.05249285697937012,
        0.007380329538136721,
        0.05338578671216965,
        0.05142025649547577,
        0.053432922810316086,
        -0.031224794685840607,
        -0.045206815004348755,
        -0.04904066398739815,
        -0.04407529905438423,
        -0.0021540706511586905,
        0.0536796860396862,
        0.05158718302845955,
        -0.05275646597146988,
        -0.0055483123287558556,
        -0.053389959037303925,
        0.05314009636640549,
        0.05364673584699631,
        -0.053564462810754776,
        0.05367525294423103,
        0.006512800231575966,
        -0.0536310151219368,
        0.053666435182094574,
        -0.05367303639650345,
        0.05216757953166962,
        0.043230801820755005,
        0.024344507604837418,
        0.0398094542324543,
        0.04036131128668785,
        0.05362595617771149,
        -0.05360032618045807,
        -0.03281591087579727,
        0.032304685562849045,
        -0.03964368999004364,
        0.047799866646528244,
        0.05219423398375511,
        0.04829220846295357,
        0.052076783031225204,
        0.0403786264359951,
        0.02984759956598282,
        -0.048584792762994766,
        0.05159132555127144,
        0.04873098433017731,
        -0.05283801630139351,
        0.05364279821515083,
        -0.049101803451776505,
        -0.004298117943108082,
        -0.019214585423469543,
        -0.023785507306456566,
        0.045791398733854294,
        0.05296580493450165,
        0.052443042397499084,
        -0.020625770092010498,
        -0.04986095428466797,
        -0.027071209624409676,
        0.0064383517019450665,
        0.027331726625561714,
        0.0522536002099514,
        0.0022076524328440428,
        0.022344384342432022,
        0.050535522401332855,
        -0.05123201757669449,
        0.02697416953742504,
        0.051597531884908676
    ],
    [
        -0.055670157074928284,
        0.05570990592241287,
        0.05487821623682976,
        0.0077147106640040874,
        -0.03335496410727501,
        0.03859887644648552,
        0.04155999794602394,
        -0.05566459521651268,
        0.05248313024640083,
        0.03923587501049042,
        0.03059476986527443,
        0.053036246448755264,
        -0.05606888607144356,
        -0.029164312407374382,
        -0.05589699372649193,
        0.05301007255911827,
        0.049682192504405975,
        -0.015199979767203331,
        0.04092802479863167,
        -0.003960761241614819,
        -0.038417283445596695,
        -0.03360120579600334,
        -0.04732966050505638,
        -0.008651799522340298,
        -0.0529039166867733,
        0.055663857609033585,
        0.05153462663292885,
        0.05507667362689972,
        -0.05453037470579147,
        -0.05605673789978027,
        -0.05595050007104874,
        0.04256542772054672,
        0.035467639565467834,
        -0.05507662147283554,
        0.048014409840106964,
        -0.05593026801943779,
        -0.04628356918692589,
        -0.043193694204092026,
        0.052557528018951416,
        -0.038674503564834595,
        -0.023898666724562645,
        0.052919402718544006,
        0.054559458047151566,
        0.037884827703237534,
        0.03641336411237717,
        -0.045810479670763016,
        -0.044625237584114075,
        0.055727533996105194,
        0.05610617622733116,
        -0.050043605268001556,
        0.05605670064687729,
        0.05601402372121811,
        0.01997370645403862,
        -0.05107346922159195,
        -0.031943656504154205,
        -0.0555630624294281,
        -0.04577400162816048,
        -0.018774475902318954,
        -0.0493118055164814,
        -0.05607069283723831,
        -0.05582987144589424,
        0.048169441521167755,
        -0.054011862725019455,
        -0.05565335229039192,
        0.05579342320561409,
        0.035865094512701035,
        -0.017691828310489655,
        -0.03550456836819649,
        -0.0014352044090628624,
        -0.014053015038371086,
        -0.05411513149738312,
        -0.03845518082380295,
        0.0557846799492836,
        0.04775024577975273,
        -0.030038969591259956,
        -0.008745352737605572,
        0.03127574920654297,
        0.05602458491921425,
        0.03810302913188934,
        0.04304724931716919,
        -0.050603244453668594,
        -0.052352722734212875,
        -0.05440562590956688,
        0.055976320058107376,
        -0.03139694407582283,
        0.051979292184114456,
        -0.02184929884970188,
        -0.026810988783836365,
        -0.030957376584410667,
        0.051249902695417404,
        -0.055782198905944824,
        0.04877810180187225,
        0.032335035502910614,
        -0.03225896880030632,
        -0.05109938606619835,
        0.05490224063396454,
        -0.01892334222793579,
        -0.055664584040641785,
        0.05420646816492081,
        0.0551997572183609,
        -0.056110505014657974,
        0.05514206737279892,
        0.037106987088918686,
        0.03492751717567444,
        -0.027376944199204445,
        -0.0556865856051445,
        -0.05588552728295326,
        0.05497487261891365,
        -0.056123439222574234,
        -0.026386398822069168,
        0.0471075102686882,
        -0.052520524710416794,
        -0.026050712913274765,
        0.027497589588165283,
        0.03399081155657768,
        0.05421490967273712,
        0.04366133734583855,
        -0.0532558336853981,
        -0.028406815603375435,
        -0.05575668439269066,
        0.030755415558815002,
        -0.036900121718645096,
        0.03376620635390282,
        0.020631633698940277,
        -0.054131459444761276,
        -0.00982198491692543,
        0.010987932793796062,
        0.052240677177906036,
        0.05500763654708862,
        -0.031751323491334915,
        0.0533846952021122,
        -0.028644448146224022,
        -0.05235648900270462,
        0.02533530630171299,
        -0.046725623309612274,
        -0.05495881661772728,
        0.05611773952841759,
        -0.05600094050168991,
        -0.04120003059506416,
        0.03599332273006439,
        0.029942907392978668,
        -0.05526794493198395,
        0.05549003556370735,
        0.05532112717628479,
        -0.028672177344560623,
        0.0005887579172849655,
        0.028354741632938385,
        -0.010571910068392754,
        0.048420097678899765,
        -0.011362889781594276,
        -0.03952128440141678,
        -0.05602400004863739,
        -0.05258936062455177,
        0.03590533137321472,
        0.05611497908830643,
        -0.05578107014298439,
        -0.0544600784778595,
        0.04375315457582474,
        0.05574696138501167,
        0.05094403401017189,
        -0.004494626075029373,
        0.019057508558034897,
        0.05560695379972458,
        0.048172324895858765,
        -0.013605528511106968,
        -0.0550934299826622,
        0.05612324923276901,
        0.02550896629691124,
        0.0003467148926574737,
        0.04037269949913025,
        -0.03352033346891403,
        0.000010530282452236861,
        -0.05613057315349579,
        0.05612347647547722,
        -0.0026354300789535046,
        0.013079044409096241,
        0.05333409830927849,
        -0.03258868679404259,
        0.05526455491781235,
        -0.05588269978761673,
        -0.05504738539457321,
        0.051958952099084854,
        0.05538156256079674,
        -0.05478697270154953,
        -0.015806404873728752,
        -0.036019157618284225,
        0.04001053795218468,
        0.05124618113040924,
        0.035380713641643524,
        -0.055900223553180695,
        -0.05508892610669136,
        0.03630302846431732,
        0.018555978313088417,
        0.055938515812158585,
        0.004049362149089575,
        0.03097868710756302,
        0.04396093264222145,
        0.0415596142411232,
        0.013470688834786415,
        0.05380609259009361,
        0.003871643217280507,
        0.025037502869963646,
        0.0549059621989727,
        -0.015990642830729485,
        0.05577952414751053,
        -0.019018305465579033,
        0.04260063171386719,
        -0.042885128408670425,
        -0.05583198368549347,
        0.05606129765510559,
        -0.055104464292526245,
        0.049084872007369995,
        -0.0017807547701522708,
        0.054077960550785065,
        0.02597120963037014,
        -0.05611439421772957,
        0.05105386674404144,
        -0.018576813861727715,
        0.05492750555276871,
        -0.038494911044836044,
        0.0423552580177784,
        0.05599968507885933,
        -0.04450681433081627,
        0.02899770624935627,
        0.05605943873524666,
        -0.05490318685770035,
        -0.0005125185125507414,
        0.004513531923294067,
        0.026119278743863106,
        -0.054295264184474945,
        0.007404624950140715,
        -0.001684279995970428,
        -0.02930317260324955,
        0.04847906529903412,
        -0.029116105288267136,
        0.056051790714263916,
        0.055336035788059235,
        -0.04491206258535385,
        0.024363376200199127,
        0.05590768903493881,
        0.007857219316065311,
        -0.044283557683229446,
        0.0165476743131876,
        0.04008089378476143,
        0.05570007488131523,
        -0.029201827943325043,
        0.03869748115539551,
        0.04166116192936897,
        0.0033479437697678804,
        -0.0537625327706337,
        -0.011516154743731022,
        -0.046862032264471054,
        0.024956563487648964,
        -0.055716946721076965,
        0.052563611418008804,
        0.05331221595406532,
        0.02555525116622448,
        -0.05582175403833389,
        0.040936145931482315,
        0.0277271568775177,
        -0.019699977710843086,
        0.030087530612945557,
        -0.003290398046374321,
        0.050942301750183105,
        -0.03355870395898819,
        0.0560031421482563,
        0.056109603494405746,
        0.05116207152605057,
        -0.023778198286890984,
        -0.05460881069302559,
        -0.05486930161714554,
        -0.05438615009188652,
        0.0505446158349514,
        -0.02940915897488594,
        -0.052863527089357376,
        0.0437372662127018,
        -0.03340255841612816,
        0.03584788367152214,
        -0.05612057074904442,
        -0.05507319048047066,
        -0.050156161189079285,
        0.053838636726140976,
        0.04928131029009819,
        -0.05384596437215805,
        0.05608837306499481,
        0.01617777906358242,
        -0.0031001376919448376,
        0.05573080852627754,
        -0.038283322006464005,
        0.055316101759672165,
        -0.025470571592450142,
        -0.0015290288720279932,
        -0.040626801550388336,
        -0.04855845496058464,
        0.0034247408621013165,
        -0.05402158573269844,
        0.05598906800150871,
        -0.030539285391569138,
        -0.016269773244857788,
        0.05499836429953575,
        0.04197583720088005,
        0.009263288229703903,
        0.03881223127245903,
        0.05396689474582672,
        -0.0324762798845768,
        0.05006329342722893,
        -0.03825189545750618,
        0.054195336997509,
        -0.054148558527231216,
        -0.037989865988492966,
        -0.03637925162911415,
        0.05288821831345558,
        0.05577722191810608,
        -0.008260378614068031,
        -0.038960788398981094,
        -0.05370913818478584,
        -0.043672483414411545,
        -0.014377125538885593,
        0.055618710815906525,
        -0.04661128669977188,
        0.0482400618493557,
        -0.013750641606748104,
        0.055456679314374924,
        -0.053426362574100494,
        0.055689189583063126,
        -0.006427403539419174,
        0.056069109588861465,
        -0.055583637207746506,
        -0.026884669438004494,
        -0.052843108773231506,
        0.05058949813246727,
        -0.050831496715545654,
        -0.02773735299706459,
        -0.047812335193157196,
        0.05441940575838089,
        -0.04899139702320099,
        -0.04465826228260994,
        0.04405774548649788,
        -0.0007417337619699538,
        0.05409569665789604,
        -0.04741677641868591,
        -0.011915809474885464,
        0.05606476962566376,
        -0.04248281940817833,
        0.026113633066415787,
        -0.021120525896549225,
        -0.011104452423751354,
        0.055376384407281876,
        0.024271680042147636,
        0.02674899809062481,
        0.05609212443232536,
        0.002586955204606056,
        -0.033213719725608826,
        -0.05515451729297638,
        0.04557627812027931,
        0.02551134116947651,
        -0.05613664910197258,
        -0.055307161062955856,
        -0.03328590840101242,
        -0.05303589627146721,
        0.05382925644516945,
        -0.02343001402914524,
        -0.024857288226485252,
        -0.051717087626457214,
        0.04873545840382576,
        -0.04820775240659714,
        0.05567633733153343,
        -0.052914928644895554,
        0.055420346558094025,
        -0.05458380654454231,
        -0.049430303275585175,
        -0.016528261825442314,
        0.05582341551780701,
        -0.0511295385658741,
        0.0505644865334034,
        0.03073255717754364,
        0.04623621702194214,
        0.033215802162885666,
        0.05601273477077484,
        -0.03153901919722557,
        0.02701180800795555,
        0.05599718540906906,
        -0.05476230010390282,
        0.03253990784287453,
        0.055374693125486374,
        -0.05567758530378342,
        -0.024016212671995163,
        0.034849122166633606,
        -0.055098049342632294,
        -0.0412624329328537,
        -0.04457365721464157,
        -0.05246112123131752,
        -0.053792208433151245,
        -0.05502880737185478,
        0.04664180427789688,
        0.05484054237604141,
        0.05599852278828621,
        0.0151093490421772,
        -0.056135937571525574,
        -0.05595450475811958,
        -0.03279192000627518,
        0.05600961670279503,
        -0.05545952171087265,
        0.054749149829149246,
        -0.0267624668776989,
        0.001523984014056623,
        -0.03339780494570732,
        -0.051715459674596786,
        0.0561235137283802,
        0.006980809848755598,
        0.05461226776242256,
        -0.015308008529245853,
        -0.047424450516700745,
        0.0490407794713974,
        0.018433326855301857,
        -0.03798271343111992,
        -0.04641391336917877,
        -0.052176620811223984,
        0.04104073718190193,
        -0.03249911218881607,
        -0.028486525639891624,
        -0.055760134011507034,
        0.020626984536647797,
        -0.05611273646354675,
        -0.044123731553554535,
        -0.053772054612636566,
        -0.042973604053258896,
        -0.045684605836868286,
        -0.04241206869482994,
        -0.044156264513731,
        0.04209402576088905,
        -0.019783368334174156,
        0.04811187833547592,
        0.010839232243597507,
        -0.053693369030952454,
        -0.04752430319786072,
        -0.05148893967270851,
        0.02693229541182518,
        -0.02366914041340351,
        -0.043740466237068176,
        -0.0007638451643288136,
        0.05356335639953613,
        0.05420548468828201,
        0.053172264248132706,
        -0.025538889691233635,
        0.05470053106546402,
        0.05570989474654198,
        -0.04463227838277817,
        0.03212970867753029,
        0.05200589820742607,
        0.01025115605443716,
        0.05330752208828926,
        0.041289109736680984,
        0.049374472349882126,
        0.05083051323890686,
        -0.055412787944078445,
        -0.030111929401755333,
        -0.04784916341304779,
        0.053956467658281326,
        0.04311005398631096,
        0.01888873800635338,
        -0.05531259998679161,
        0.0556461326777935,
        0.0479489341378212,
        0.05558308959007263,
        0.05602240934967995,
        -0.05577012151479721,
        0.05564587190747261,
        -0.05575493350625038,
        -0.055118829011917114,
        0.05603719875216484,
        -0.04732247069478035,
        0.02414892055094242,
        0.045761577785015106,
        -0.00027435258380137384,
        0.04109044745564461,
        -0.049146998673677444,
        0.05611289665102959,
        -0.027962250635027885,
        0.04437235742807388,
        0.05240597575902939,
        -0.02593638189136982,
        0.027381395921111107,
        0.03306892141699791,
        -0.03279929608106613,
        0.05593615770339966,
        0.05612689256668091,
        0.04024219140410423,
        -0.053731802850961685,
        -0.032494671642780304,
        0.020716188475489616,
        -0.056022826582193375,
        0.045762620866298676,
        -0.04524379223585129,
        -0.052752748131752014,
        0.024412699043750763,
        -0.05507613345980644,
        -0.05470258370041847,
        0.05355767905712128,
        -0.0554102398455143,
        0.016426991671323776,
        -0.04868441820144653,
        -0.043755169957876205,
        -0.05135101079940796,
        0.05514984205365181,
        0.004506498109549284,
        0.05153881013393402,
        -0.04198414832353592,
        0.05595782771706581,
        -0.05605091154575348,
        0.056110184639692307,
        -0.053555477410554886
    ],
    [
        0.05094180256128311,
        0.052495915442705154,
        0.0526813268661499,
        0.019039036706089973,
        -0.03000001609325409,
        0.052424874156713486,
        0.052449751645326614,
        -0.052374958992004395,
        -0.017169173806905746,
        0.027131862938404083,
        0.051958706229925156,
        0.04548221826553345,
        -0.05237638205289841,
        -0.0525430403649807,
        -0.05111602321267128,
        0.052316419780254364,
        -0.0036414307542145252,
        0.05104070156812668,
        -0.031971026211977005,
        0.049428075551986694,
        0.05152711644768715,
        0.030240030959248543,
        -0.05108126252889633,
        0.04357345774769783,
        -0.03698292002081871,
        0.04642099514603615,
        0.04838448390364647,
        0.04967007040977478,
        -0.0526122972369194,
        -0.028712160885334015,
        -0.05171903222799301,
        -0.01887143962085247,
        0.0500226765871048,
        -0.05130648985505104,
        0.052101898938417435,
        -0.051512397825717926,
        -0.05235729739069939,
        0.050172217190265656,
        0.050879426300525665,
        0.04239613190293312,
        -0.04708187282085419,
        0.04494047537446022,
        0.011492947116494179,
        0.04090554267168045,
        -0.044484853744506836,
        -0.052027031779289246,
        0.009036032482981682,
        0.05260283872485161,
        0.05259240046143532,
        -0.05152628943324089,
        0.05261142924427986,
        0.0524970144033432,
        0.04141722619533539,
        -0.04844798520207405,
        -0.029522042721509933,
        -0.04706371948122978,
        -0.04577454924583435,
        -0.036856673657894135,
        0.028556417673826218,
        -0.05268007516860962,
        -0.05237893387675285,
        0.003549829823896289,
        -0.05165404826402664,
        -0.05237385630607605,
        0.052681416273117065,
        0.031047852709889412,
        -0.05268003046512604,
        -0.045728471130132675,
        0.050192493945360184,
        -0.01577967032790184,
        -0.05155648663640022,
        -0.04332247003912926,
        0.05231265351176262,
        0.052528928965330124,
        -0.04852403327822685,
        0.023351052775979042,
        0.043502043932676315,
        0.05268293246626854,
        0.05246339738368988,
        -0.048556603491306305,
        -0.015578112564980984,
        -0.052680209279060364,
        -0.049926336854696274,
        -0.01530155073851347,
        -0.04660212993621826,
        0.05213923379778862,
        -0.0526675246655941,
        -0.052679285407066345,
        -0.0455196276307106,
        0.047723591327667236,
        -0.04982178285717964,
        -0.050424251705408096,
        0.05229860544204712,
        -0.0519556924700737,
        -0.004168991465121508,
        0.05198622867465019,
        0.046206284314394,
        -0.022490287199616432,
        0.05214087292551994,
        0.029795147478580475,
        -0.05267488211393356,
        0.034545015543699265,
        -0.04655880481004715,
        0.03756547346711159,
        -0.04497388005256653,
        -0.035232048481702805,
        -0.05264978110790253,
        -0.050222836434841156,
        -0.051534298807382584,
        0.05177903547883034,
        -0.016811836510896683,
        0.05068667605519295,
        -0.052064165472984314,
        0.05034800246357918,
        0.049460284411907196,
        0.05068114027380943,
        0.04992116987705231,
        -0.052664123475551605,
        -0.031600646674633026,
        -0.05232371762394905,
        0.04721398279070854,
        0.029042799025774002,
        -0.03529210388660431,
        0.03585895895957947,
        -0.024875648319721222,
        0.05137154832482338,
        -0.04862615466117859,
        0.043486181646585464,
        0.05163812264800072,
        -0.016291406005620956,
        0.052597831934690475,
        -0.052431948482990265,
        -0.02860380709171295,
        0.05134280398488045,
        -0.008933058008551598,
        -0.052568595856428146,
        0.050687313079833984,
        -0.05216408148407936,
        -0.05252924561500549,
        -0.00315876305103302,
        0.05245190113782883,
        -0.05243712663650513,
        0.04850292578339577,
        0.04834805056452751,
        -0.02300439402461052,
        0.034226901829242706,
        0.05192078277468681,
        0.05267895385622978,
        0.042015887796878815,
        0.04437997192144394,
        -0.027321627363562584,
        -0.05262506380677223,
        0.017764028161764145,
        -0.05182301998138428,
        0.05268194526433945,
        -0.05224248766899109,
        -0.05258109048008919,
        0.04057016223669052,
        0.052664585411548615,
        0.03475727513432503,
        -0.05248521268367767,
        0.03359920158982277,
        0.051287297159433365,
        -0.0008298506727442145,
        -0.0167652890086174,
        0.04664447158575058,
        0.05255630984902382,
        0.030057886615395546,
        -0.049021415412425995,
        0.030063096433877945,
        -0.03433246165513992,
        0.05144903436303139,
        -0.052674055099487305,
        0.052660781890153885,
        0.031135961413383484,
        0.010189804248511791,
        -0.03509864583611488,
        0.05121171846985817,
        0.05261477455496788,
        -0.05145491287112236,
        -0.04583116993308067,
        0.0343056283891201,
        0.05236341059207916,
        -0.050892315804958344,
        0.05150523781776428,
        -0.0488797202706337,
        0.052675437182188034,
        0.048957210034132004,
        0.03995233029127121,
        -0.05264250189065933,
        -0.05264990031719208,
        0.022595351561903954,
        -0.05055029317736626,
        -0.004809611011296511,
        -0.04561644047498703,
        0.0030208902899175882,
        0.05222988873720169,
        0.050670232623815536,
        0.05267413333058357,
        0.027537822723388672,
        0.051264844834804535,
        0.05093008279800415,
        0.05255954712629318,
        -0.04805230721831322,
        0.05228176340460777,
        -0.024471011012792587,
        0.047865789383649826,
        -0.0434427373111248,
        -0.051237136125564575,
        0.05201886221766472,
        -0.05164729803800583,
        0.02750169299542904,
        -0.031321872025728226,
        0.04306843876838684,
        -0.04102801904082298,
        -0.0517500564455986,
        0.0524713359773159,
        -0.0075967442244291306,
        0.0522940494120121,
        -0.04157247021794319,
        0.04278750717639923,
        0.04392028972506523,
        -0.05268063768744469,
        -0.04447013884782791,
        0.05125792697072029,
        -0.04892917722463608,
        0.0019605697598308325,
        -0.04774666950106621,
        -0.048644762486219406,
        -0.04225730150938034,
        0.018478401005268097,
        0.0522991344332695,
        0.05046168342232704,
        0.051118530333042145,
        -0.05208183079957962,
        0.05253199115395546,
        0.05017828568816185,
        -0.05075497180223465,
        0.026005078107118607,
        0.052682727575302124,
        -0.010336389765143394,
        0.049306146800518036,
        -0.03324352577328682,
        0.028729084879159927,
        -0.008976751007139683,
        -0.052460480481386185,
        0.052618399262428284,
        -0.020196711644530296,
        -0.05100110173225403,
        -0.002669726265594363,
        -0.05225420743227005,
        -0.04342043772339821,
        -0.05236358940601349,
        -0.05051223933696747,
        0.05095105990767479,
        0.052584242075681686,
        -0.027404464781284332,
        -0.05210750550031662,
        0.010989807546138763,
        0.04612390697002411,
        -0.020944712683558464,
        0.00893025379627943,
        0.025365181267261505,
        0.05265146493911743,
        0.05262116342782974,
        0.05266221612691879,
        0.05258912593126297,
        -0.03129320591688156,
        0.011310520581901073,
        -0.052016615867614746,
        0.017863884568214417,
        -0.046445783227682114,
        -0.016361664980649948,
        -0.05260031297802925,
        -0.05261394754052162,
        -0.018477056175470352,
        -0.02578428015112877,
        0.03432599827647209,
        -0.05268074572086334,
        -0.0522981733083725,
        -0.0526689775288105,
        -0.0511968620121479,
        0.05204415321350098,
        -0.04022934287786484,
        0.043210916221141815,
        0.03808583319187164,
        -0.0030159633606672287,
        0.04312368109822273,
        0.0484042763710022,
        -0.034492749720811844,
        -0.01858765259385109,
        0.0040400391444563866,
        0.02540777623653412,
        -0.05205459147691727,
        -0.02302328310906887,
        -0.050826918333768845,
        0.052667636424303055,
        -0.052640825510025024,
        0.04121740534901619,
        0.05197133123874664,
        -0.0522758811712265,
        -0.04469950497150421,
        -0.02596713788807392,
        0.04410618543624878,
        0.002858751220628619,
        -0.0026841522194445133,
        -0.041825007647275925,
        0.052635323256254196,
        -0.04426072537899017,
        -0.0484561026096344,
        0.052174415439367294,
        0.05265585333108902,
        0.05024935305118561,
        0.026277823373675346,
        -0.05264304578304291,
        -0.051762353628873825,
        0.018406067043542862,
        -0.031140336766839027,
        0.052659306675195694,
        -0.05263594165444374,
        0.05257163941860199,
        -0.0519242025911808,
        0.052581559866666794,
        -0.04199792444705963,
        -0.0055077350698411465,
        0.040165793150663376,
        0.05225642770528793,
        -0.05180200934410095,
        -0.04854580760002136,
        -0.05257117375731468,
        0.048215195536613464,
        -0.05245117470622063,
        0.019736364483833313,
        -0.048225048929452896,
        0.03883294016122818,
        0.05111966282129288,
        -0.03740087151527405,
        0.019365178421139717,
        0.04869070276618004,
        -0.042071014642715454,
        -0.04328739270567894,
        0.052345722913742065,
        0.05191792920231819,
        0.04184533655643463,
        0.04114463925361633,
        0.04381442442536354,
        0.050759196281433105,
        0.051959287375211716,
        -0.030126802623271942,
        0.051676906645298004,
        0.044626206159591675,
        -0.02265981398522854,
        0.04541027173399925,
        -0.040385693311691284,
        0.05248625576496124,
        0.008899864740669727,
        -0.05261821299791336,
        -0.052454739809036255,
        -0.05200345441699028,
        -0.021481463685631752,
        0.04949076473712921,
        -0.05220858380198479,
        -0.018196476623415947,
        -0.05040980875492096,
        -0.03942427039146423,
        -0.04422337934374809,
        0.05261152982711792,
        -0.05235946923494339,
        0.030805153772234917,
        -0.05122799426317215,
        -0.0033390435855835676,
        -0.05116625502705574,
        0.04159451648592949,
        -0.05216861516237259,
        0.04200950264930725,
        0.04962078109383583,
        -0.018047427758574486,
        -0.03205066919326782,
        0.0522000789642334,
        -0.03097684122622013,
        -0.03888298571109772,
        0.03347425535321236,
        -0.05213577672839165,
        0.04700753837823868,
        0.05245761573314667,
        -0.052675001323223114,
        0.045712463557720184,
        0.027531785890460014,
        -0.04944349080324173,
        0.022010741755366325,
        -0.02890004776418209,
        -0.0525917150080204,
        -0.0522531121969223,
        -0.0506381094455719,
        0.02739473059773445,
        0.052187010645866394,
        0.05256703123450279,
        -0.025906849652528763,
        -0.05268267169594765,
        -0.039093684405088425,
        -0.03777296841144562,
        0.05267590284347534,
        -0.052665095776319504,
        0.04057180508971214,
        0.006238387431949377,
        -0.05252138152718544,
        -0.010530581697821617,
        -0.039078738540410995,
        0.051575176417827606,
        0.009175332263112068,
        0.035890668630599976,
        0.017091121524572372,
        -0.0521744005382061,
        0.037557203322649,
        0.05160896107554436,
        -0.05037504807114601,
        -0.050688281655311584,
        -0.05258840322494507,
        -0.01562504842877388,
        -0.016679542139172554,
        0.04262653738260269,
        -0.05214312672615051,
        0.05183258652687073,
        -0.05266077443957329,
        -0.04934372752904892,
        -0.05246778577566147,
        -0.05072978883981705,
        -0.052322953939437866,
        -0.030711175873875618,
        -0.05218459293246269,
        0.04040971025824547,
        0.050752293318510056,
        0.05260103940963745,
        -0.024661848321557045,
        -0.05245061591267586,
        -0.05267598107457161,
        0.027952339500188828,
        0.04618186876177788,
        -0.04377241060137749,
        -0.05176156759262085,
        -0.05260153487324715,
        0.03679586201906204,
        0.050090447068214417,
        0.05208178237080574,
        -0.0503709614276886,
        0.015717964619398117,
        0.05195540562272072,
        -0.050429873168468475,
        -0.00850016437470913,
        0.03619975224137306,
        -0.050056103616952896,
        0.047652989625930786,
        0.05038118734955788,
        0.043779902160167694,
        0.04055749997496605,
        -0.03126821666955948,
        -0.03732768073678017,
        -0.049439020454883575,
        0.05178440734744072,
        0.05268128961324692,
        0.05102143809199333,
        0.04471315070986748,
        0.028995344415307045,
        0.01107838936150074,
        0.05044977366924286,
        0.0522601418197155,
        -0.05265393108129501,
        0.050720978528261185,
        0.051663290709257126,
        -0.051788050681352615,
        0.052348364144563675,
        -0.05268239602446556,
        0.05204712226986885,
        0.05114942416548729,
        -0.03923104703426361,
        0.048331212252378464,
        -0.03398946300148964,
        0.0526469349861145,
        -0.03876473754644394,
        0.02401079423725605,
        0.052510857582092285,
        0.033231887966394424,
        0.05050552263855934,
        0.013743225485086441,
        0.05192742124199867,
        0.052557919174432755,
        0.05193832889199257,
        0.043552905321121216,
        -0.05225839465856552,
        -0.009110098704695702,
        0.011288829147815704,
        -0.05196480080485344,
        0.052410420030355453,
        -0.016511445865035057,
        -0.011558283120393753,
        0.021327689290046692,
        -0.052681706845760345,
        -0.05258107930421829,
        0.0507759265601635,
        -0.05166630074381828,
        0.050998084247112274,
        -0.05248738452792168,
        -0.04779735952615738,
        0.017722252756357193,
        0.028653457760810852,
        0.04224621504545212,
        0.04505294933915138,
        -0.05164714902639389,
        0.05267641320824623,
        -0.05267618969082832,
        0.05201767012476921,
        -0.04710271954536438
    ],
    [
        0.027291473001241684,
        0.03761749342083931,
        0.05342479422688484,
        -0.02024690806865692,
        -0.05124868452548981,
        0.02918279357254505,
        0.05764823034405708,
        -0.007943196222186089,
        0.03675016015768051,
        -0.0014989390037953854,
        0.05490595102310181,
        -0.02608315274119377,
        -0.022088369354605675,
        0.060221023857593536,
        -0.05868668109178543,
        0.0595504492521286,
        -0.05148899182677269,
        0.059984561055898666,
        -0.02289656177163124,
        0.003699289867654443,
        -0.059140630066394806,
        -0.05183449387550354,
        0.006546611897647381,
        0.028959181159734726,
        -0.059061720967292786,
        0.05351492390036583,
        -0.05785765498876572,
        -0.007561119273304939,
        -0.05950237810611725,
        -0.05602463334798813,
        -0.05881404131650925,
        0.04975462332367897,
        -0.04456029087305069,
        -0.058648910373449326,
        0.03681138902902603,
        -0.009402645751833916,
        -0.057320717722177505,
        0.0355994813144207,
        -0.013049449771642685,
        0.028264664113521576,
        -0.0016771776136010885,
        0.026563797146081924,
        0.056857723742723465,
        -0.05743638053536415,
        0.06021549180150032,
        -0.059759873896837234,
        -0.05948067456483841,
        0.0604584626853466,
        0.05936751887202263,
        -0.0016631954349577427,
        0.06016567721962929,
        0.05954696610569954,
        -0.043099064379930496,
        -0.020194977521896362,
        -0.05547429621219635,
        -0.017076825723052025,
        0.05152324587106705,
        -0.019968943670392036,
        -0.05601684749126434,
        -0.06019840016961098,
        -0.059988610446453094,
        -0.022187475115060806,
        -0.035985853523015976,
        -0.05859466642141342,
        0.05660412833094597,
        -0.027175433933734894,
        -0.00880980771034956,
        -0.008395310491323471,
        -0.054327815771102905,
        -0.0502411313354969,
        -0.020094072446227074,
        -0.05275896564126015,
        0.06046745553612709,
        0.05963096767663956,
        0.04657573625445366,
        -0.01114595215767622,
        -0.007262453902512789,
        0.06045395880937576,
        0.055003825575113297,
        -0.04627104848623276,
        -0.03990395739674568,
        -0.05620461329817772,
        -0.05952777341008186,
        0.0595235750079155,
        0.013614185154438019,
        0.04266416281461716,
        0.010523734614253044,
        0.02044127695262432,
        0.034406352788209915,
        0.035143401473760605,
        -0.04707609489560127,
        0.007724438328295946,
        0.024257473647594452,
        0.05692025274038315,
        -0.03628796711564064,
        -0.008783632889389992,
        -0.053221870213747025,
        -0.05068827047944069,
        0.05197026580572128,
        0.055406440049409866,
        -0.05995757505297661,
        0.04576215520501137,
        0.058546073734760284,
        0.020579004660248756,
        -0.050400879234075546,
        -0.06045868992805481,
        -0.054535362869501114,
        0.03946484625339508,
        -0.04302287474274635,
        -0.05898787081241608,
        -0.007422395516186953,
        0.049450334161520004,
        -0.02799202874302864,
        0.014291640371084213,
        0.0006731591420248151,
        0.06024123728275299,
        0.05197600647807121,
        -0.052307311445474625,
        -0.040284112095832825,
        -0.06006177142262459,
        0.02947796694934368,
        -0.004866407252848148,
        0.018136609345674515,
        -0.05797228962182999,
        -0.03447382524609566,
        -0.06000663712620735,
        0.05661549046635628,
        -0.04378201439976692,
        0.058572087436914444,
        -0.041906144469976425,
        0.05638318136334419,
        -0.04989684745669365,
        -0.008458804339170456,
        0.0457170270383358,
        -0.04729137197136879,
        -0.03940808027982712,
        0.0557912178337574,
        -0.058446407318115234,
        0.005648483522236347,
        0.0556352436542511,
        0.052998099476099014,
        0.009241634979844093,
        0.04398427531123161,
        0.018900331109762192,
        0.010620790533721447,
        0.004790924489498138,
        0.04518474265933037,
        -0.02711581066250801,
        -0.05639095976948738,
        -0.05464208498597145,
        -0.025067660957574844,
        -0.006008383817970753,
        0.0016812443500384688,
        0.058122992515563965,
        0.05415717139840126,
        -0.055877435952425,
        -0.06016908586025238,
        0.04057205840945244,
        0.060140565037727356,
        0.03370090201497078,
        -0.00366034428589046,
        0.028752826154232025,
        0.059532471001148224,
        -0.01930595561861992,
        0.045843638479709625,
        -0.048918064683675766,
        -0.03746620938181877,
        -0.05862240493297577,
        0.028235210105776787,
        0.01836322620511055,
        0.030742039903998375,
        0.04406924918293953,
        -0.0599372573196888,
        0.04446941241621971,
        0.021933145821094513,
        0.012973404489457607,
        0.018339384347200394,
        -0.024682873860001564,
        0.059216853231191635,
        -0.04255891218781471,
        -0.04504578188061714,
        0.04184988513588905,
        0.006381541956216097,
        -0.03646524250507355,
        -0.04609600454568863,
        0.05140846595168114,
        0.0011817974736914039,
        0.05960384011268616,
        0.02121264673769474,
        0.045513201504945755,
        -0.057670675218105316,
        0.013864671811461449,
        0.03103155829012394,
        0.048660822212696075,
        -0.030026044696569443,
        -0.05671187862753868,
        0.05750321224331856,
        0.057982027530670166,
        0.016991235315799713,
        0.05607231333851814,
        0.029588809236884117,
        0.021439239382743835,
        -0.041148435324430466,
        -0.05730389803647995,
        0.05567021295428276,
        -0.049233563244342804,
        0.0569995641708374,
        0.03298363834619522,
        -0.02539597637951374,
        0.05990687757730484,
        -0.02751227840781212,
        0.04333798587322235,
        0.037570975720882416,
        0.023159444332122803,
        -0.034642089158296585,
        -0.05984211713075638,
        0.022276882082223892,
        0.04610651731491089,
        0.05759168043732643,
        0.01675708219408989,
        0.05307459458708763,
        0.0594782717525959,
        -0.008180143311619759,
        -0.05233161523938179,
        0.0026044356636703014,
        0.03639882802963257,
        0.013032333925366402,
        -0.051634371280670166,
        0.058097146451473236,
        -0.05304359272122383,
        -0.002551689511165023,
        0.051162634044885635,
        -0.048040956258773804,
        0.056482817977666855,
        0.05996163934469223,
        0.049117181450128555,
        0.059504322707653046,
        -0.05394580960273743,
        -0.05220997333526611,
        0.060291316360235214,
        0.04584665969014168,
        -0.055434491485357285,
        -0.027221495285630226,
        0.052710406482219696,
        0.04156563803553581,
        -0.012690234929323196,
        -0.03297468647360802,
        -0.03435992822051048,
        0.057456597685813904,
        -0.003474400844424963,
        -0.014592478983104229,
        -0.05654638260602951,
        -0.05946672335267067,
        -0.058016493916511536,
        0.011498328298330307,
        0.052243370562791824,
        0.060381755232810974,
        -0.05075160413980484,
        0.030200496315956116,
        0.02820265293121338,
        -0.0585491843521595,
        0.011594881303608418,
        -0.04535965621471405,
        0.011872675269842148,
        -0.04502786695957184,
        0.05811343714594841,
        0.038570113480091095,
        0.0406649224460125,
        -0.05957222357392311,
        0.033303894102573395,
        0.04301603138446808,
        -0.028501063585281372,
        -0.04819384962320328,
        -0.050847381353378296,
        -0.0410681776702404,
        0.037137046456336975,
        -0.051612064242362976,
        0.056744541972875595,
        -0.020845970138907433,
        -0.026789618656039238,
        -0.05700612813234329,
        0.04688422754406929,
        -0.04684964567422867,
        -0.052465129643678665,
        0.033012039959430695,
        -0.04668504744768143,
        0.010910319164395332,
        0.04367975518107414,
        0.05623243749141693,
        -0.0027333737816661596,
        -0.02670876309275627,
        0.02390376664698124,
        -0.051868148148059845,
        -0.04988134279847145,
        -0.02369076944887638,
        -0.05350237712264061,
        0.057495538145303726,
        -0.007729832082986832,
        -0.052372753620147705,
        0.05620921775698662,
        0.050775375217199326,
        0.0490574985742569,
        0.03509759530425072,
        0.03997816890478134,
        -0.023148946464061737,
        -0.010141574777662754,
        -0.05985650047659874,
        0.055962592363357544,
        0.017321422696113586,
        0.05188727751374245,
        -0.022134464234113693,
        0.02024897187948227,
        -0.0034471778199076653,
        -0.018541395664215088,
        -0.01015983521938324,
        -0.055093660950660706,
        0.00663489755243063,
        -0.05591611564159393,
        0.05579927936196327,
        -0.02134331688284874,
        0.03302837163209915,
        -0.0509147010743618,
        -0.0008751983405090868,
        -0.0326416939496994,
        0.05559152737259865,
        -0.05361475422978401,
        0.009453087113797665,
        -0.04528035223484039,
        -0.04657471552491188,
        -0.05623675882816315,
        -0.040546610951423645,
        -0.057550568133592606,
        -0.05832963436841965,
        -0.02990472875535488,
        0.05862141400575638,
        -0.05187271907925606,
        -0.04359668493270874,
        0.045687898993492126,
        -0.04785099998116493,
        0.05986625328660011,
        -0.04017322510480881,
        0.05974235013127327,
        0.02015962265431881,
        0.0381343774497509,
        0.05710255354642868,
        0.03380408510565758,
        -0.0010603464907035232,
        0.05012855678796768,
        -0.04618782922625542,
        0.03164967522025108,
        0.05446378514170647,
        0.012505894526839256,
        0.04785570874810219,
        -0.04521150887012482,
        0.04232243075966835,
        0.013118828646838665,
        -0.05808325484395027,
        -0.05848338454961777,
        0.050505440682172775,
        -0.041417405009269714,
        0.03166110813617706,
        -0.03444111719727516,
        -0.007104732561856508,
        -0.055696118623018265,
        0.044605329632759094,
        -0.01512951124459505,
        0.059919267892837524,
        0.015866903588175774,
        0.029573408886790276,
        -0.049807433038949966,
        0.008345948532223701,
        0.05066584050655365,
        0.01846088096499443,
        -0.059638384729623795,
        0.03564146161079407,
        0.028065791353583336,
        0.04601191356778145,
        0.030060650780797005,
        0.02672603726387024,
        0.040684040635824203,
        -0.04546539857983589,
        0.060391489416360855,
        -0.054356031119823456,
        0.0460381954908371,
        0.06046086177229881,
        -0.0564143992960453,
        -0.05617746338248253,
        -0.0586450919508934,
        -0.04370073229074478,
        -0.05482393503189087,
        -0.0578753836452961,
        0.001414010883308947,
        0.006394620053470135,
        -0.02827455662190914,
        -0.027275675907731056,
        0.05709947645664215,
        0.060388848185539246,
        -0.04016084596514702,
        -0.06007586047053337,
        -0.058623965829610825,
        -0.04740216210484505,
        0.059340961277484894,
        -0.024260854348540306,
        0.03430207818746567,
        -0.015437359921634197,
        -0.011319220066070557,
        0.05753485485911369,
        -0.05945921316742897,
        0.05194641649723053,
        0.0356825515627861,
        -0.03750311955809593,
        0.02205740474164486,
        -0.028159432113170624,
        0.015092466026544571,
        0.05891804397106171,
        -0.0009359770338051021,
        -0.026818858459591866,
        -0.027921173721551895,
        0.03970446437597275,
        -0.05676448717713356,
        -0.016484010964632034,
        -0.05400410667061806,
        -0.0326596163213253,
        -0.06023276224732399,
        -0.033676646649837494,
        0.002324771834537387,
        0.04878295958042145,
        -0.03808950260281563,
        -0.045939985662698746,
        -0.04494882747530937,
        0.05848100781440735,
        0.05821217969059944,
        0.0451541431248188,
        -0.04044843465089798,
        0.010538814589381218,
        -0.05017957463860512,
        -0.06000569090247154,
        0.053881749510765076,
        -0.04727693274617195,
        -0.06003614515066147,
        -0.05910235270857811,
        -0.027859682217240334,
        0.04185900464653969,
        0.05961943417787552,
        -0.007385258097201586,
        0.03222113847732544,
        0.050171174108982086,
        0.04421621561050415,
        0.026362260803580284,
        0.05407310649752617,
        -0.04501836374402046,
        0.050314635038375854,
        0.04172002896666527,
        0.03232914209365845,
        -0.01851862482726574,
        -0.04420211538672447,
        -0.002937581157311797,
        -0.05965382978320122,
        0.05884740129113197,
        -0.04406747967004776,
        0.04939325153827667,
        -0.06006500869989395,
        0.060095950961112976,
        0.05782625451683998,
        0.05991842597723007,
        0.059381790459156036,
        -0.03595927357673645,
        0.059808239340782166,
        -0.05401844531297684,
        -0.04967900738120079,
        0.05541149526834488,
        -0.024059200659394264,
        0.05273669958114624,
        0.05916938558220863,
        0.013182741589844227,
        0.013124370016157627,
        0.06036528944969177,
        -0.0415983684360981,
        0.039258163422346115,
        0.052496541291475296,
        0.05278071388602257,
        0.018376203253865242,
        -0.006125900894403458,
        -0.019716257229447365,
        -0.046263717114925385,
        0.05543702468276024,
        0.041471876204013824,
        0.05794135108590126,
        -0.02668781206011772,
        -0.0410848893225193,
        0.020854847505688667,
        -0.059328772127628326,
        0.037656400352716446,
        0.05483504757285118,
        0.0387626513838768,
        0.05773601680994034,
        -0.05993125960230827,
        0.044302720576524734,
        0.03728858008980751,
        -0.06021968647837639,
        0.05922335386276245,
        0.03619040921330452,
        -0.05418889969587326,
        0.05833331495523453,
        -0.03572575002908707,
        0.04596950113773346,
        0.05875290930271149,
        -0.05210614204406738,
        0.0567898191511631,
        -0.02489759773015976,
        0.05985339730978012,
        -0.024949368089437485
    ],
    [
        -0.05656974017620087,
        -0.028995566070079803,
        0.048380497843027115,
        0.005729119759052992,
        0.047212619334459305,
        0.056142356246709824,
        0.05647031590342522,
        -0.053168438374996185,
        -0.013224637135863304,
        0.04353773593902588,
        0.001682931324467063,
        0.041926588863134384,
        -0.04258299991488457,
        0.025339726358652115,
        -0.05526747927069664,
        0.04967164993286133,
        -0.05157609283924103,
        0.05452346429228783,
        -0.05613794922828674,
        0.05561891570687294,
        -0.04631312936544418,
        -0.05134354904294014,
        0.007283999118953943,
        0.04053589329123497,
        0.05355745181441307,
        -0.00412811478599906,
        0.011739149689674377,
        0.05543512850999832,
        -0.05230499431490898,
        -0.04100755229592323,
        0.04400944337248802,
        0.030894281342625618,
        0.05448605492711067,
        -0.056324392557144165,
        0.03154120221734047,
        -0.05143053084611893,
        -0.0528559684753418,
        -0.05312107503414154,
        -0.0566415898501873,
        0.04486072435975075,
        0.01871921494603157,
        0.03070562332868576,
        0.051495686173439026,
        -0.05513620004057884,
        0.05354602634906769,
        -0.00017856925842352211,
        0.05391760542988777,
        0.05600867047905922,
        0.05347924306988716,
        -0.050546202808618546,
        0.04287009686231613,
        0.044357988983392715,
        0.05410822480916977,
        0.017535466700792313,
        0.015638502314686775,
        -0.05546895042061806,
        -0.05649445950984955,
        -0.016128823161125183,
        -0.05335444584488869,
        0.039728838950395584,
        -0.048209790140390396,
        -0.043266572058200836,
        -0.05250684916973114,
        -0.023732921108603477,
        0.007811571937054396,
        0.053855475038290024,
        0.05574909225106239,
        -0.017288047820329666,
        -0.03502018377184868,
        -0.05665592849254608,
        -0.05427487939596176,
        -0.056580010801553726,
        0.0380319319665432,
        -0.04956960305571556,
        -0.0541917122900486,
        0.007167444098740816,
        0.05227118358016014,
        0.048651471734046936,
        0.053235236555337906,
        -0.05254150182008743,
        -0.023645825684070587,
        -0.041351430118083954,
        -0.054495345801115036,
        -0.008492754772305489,
        0.05491459742188454,
        0.04574180766940117,
        -0.05405550077557564,
        -0.03671722859144211,
        0.056448325514793396,
        0.02789507992565632,
        -0.044584088027477264,
        0.05212989076972008,
        0.05426019802689552,
        -0.05622782185673714,
        -0.013050958514213562,
        0.02811805158853531,
        -0.02755887433886528,
        -0.04974045231938362,
        0.01931600458920002,
        -0.0013714134693145752,
        -0.05583220347762108,
        -0.05010908097028732,
        0.05608638375997543,
        0.05243179574608803,
        -0.005039987154304981,
        0.0002450477331876755,
        0.03394100069999695,
        0.016670502722263336,
        -0.056444451212882996,
        0.013433001935482025,
        -0.054743193089962006,
        0.05139733850955963,
        -0.03926786035299301,
        -0.041518568992614746,
        0.05658784136176109,
        -0.05428287759423256,
        0.004869017284363508,
        -0.04590070992708206,
        -0.04557977244257927,
        0.05226650461554527,
        0.032604169100522995,
        0.049045540392398834,
        -0.05582322180271149,
        0.053821150213479996,
        -0.055593859404325485,
        0.039756596088409424,
        -0.0030972654931247234,
        0.05564546957612038,
        0.049325548112392426,
        0.035895947366952896,
        0.05481412261724472,
        -0.02770380489528179,
        -0.045170821249485016,
        0.021122127771377563,
        -0.052324119955301285,
        -0.04949914664030075,
        0.044667091220617294,
        -0.05531003326177597,
        -0.04047216475009918,
        0.031241901218891144,
        0.05633346736431122,
        0.045634675770998,
        0.04528290033340454,
        0.014051122590899467,
        -0.02200230397284031,
        0.006583162117749453,
        0.0564890094101429,
        -0.054999954998493195,
        -0.002227738732472062,
        0.0358879417181015,
        -0.032244741916656494,
        -0.0563194565474987,
        -0.05569463223218918,
        -0.031000223010778427,
        0.05644798278808594,
        -0.04785235971212387,
        0.056541383266448975,
        0.028183428570628166,
        -0.04857366159558296,
        0.04499243199825287,
        0.04568808898329735,
        0.03561120852828026,
        0.047455575317144394,
        0.014811853878200054,
        -0.04698052257299423,
        0.013115296140313148,
        0.03970795124769211,
        -0.04534650593996048,
        -0.005482404492795467,
        0.045876823365688324,
        -0.0529244989156723,
        -0.004042155109345913,
        -0.05407438054680824,
        0.05308303236961365,
        -0.0520312562584877,
        0.0530630424618721,
        -0.056638333946466446,
        0.03903580829501152,
        0.05367308482527733,
        -0.020079145208001137,
        -0.004070434253662825,
        -0.05379948765039444,
        0.0515931062400341,
        0.028018485754728317,
        0.0022229531314224005,
        -0.01779143698513508,
        -0.04387573152780533,
        0.05473761260509491,
        -0.04944799467921257,
        -0.04153681546449661,
        0.00880411732941866,
        -0.025899935513734818,
        -0.0530584454536438,
        0.04347118362784386,
        -0.05000923573970795,
        0.03952554240822792,
        0.05597509443759918,
        0.03574815019965172,
        -0.054826769977808,
        0.012613976374268532,
        0.001705499249510467,
        0.05552377551794052,
        0.011439602822065353,
        -0.0005144186434336007,
        -0.020461417734622955,
        0.0549156628549099,
        0.03751350939273834,
        -0.030322780832648277,
        -0.04881017655134201,
        -0.05605408921837807,
        -0.043402474373579025,
        0.05490586534142494,
        -0.04960242658853531,
        0.005491381511092186,
        -0.03331434726715088,
        -0.04971366748213768,
        0.05661121383309364,
        0.024254869669675827,
        0.05665384232997894,
        -0.03764602169394493,
        -0.04402666911482811,
        -0.04880887269973755,
        -0.0231974795460701,
        -0.05618485435843468,
        0.053901661187410355,
        -0.05659745633602142,
        -0.027466872707009315,
        0.0566466823220253,
        -0.055981893092393875,
        -0.05324336886405945,
        0.026037532836198807,
        -0.05620208755135536,
        -0.05450363829731941,
        0.04726485162973404,
        0.036822497844696045,
        -0.01895543374121189,
        0.0551840141415596,
        -0.041469793766736984,
        -0.00691437441855669,
        0.05575016140937805,
        0.05326322093605995,
        0.04427504539489746,
        0.01463885698467493,
        0.05540568754076958,
        -0.037796325981616974,
        0.026636525988578796,
        0.04665282741189003,
        -0.04425431415438652,
        -0.046586450189352036,
        -0.050843093544244766,
        -0.05448007583618164,
        -0.05551348999142647,
        -0.042102325707674026,
        -0.053523000329732895,
        0.0002251724508823827,
        0.05580299720168114,
        -0.047008875757455826,
        -0.05363569036126137,
        0.05625056102871895,
        0.048008810728788376,
        -0.03093300759792328,
        0.05418987199664116,
        0.023366859182715416,
        0.05334855243563652,
        -0.019825786352157593,
        0.05413041636347771,
        -0.054522912949323654,
        -0.02876899763941765,
        -0.016024325042963028,
        0.04352642595767975,
        -0.04709261283278465,
        -0.056361012160778046,
        0.0027194104623049498,
        -0.05559743940830231,
        -0.056213945150375366,
        0.05142093822360039,
        -0.05512112006545067,
        0.05659539997577667,
        -0.055609483271837234,
        -0.037511296570301056,
        -0.05566024407744408,
        -0.05508091300725937,
        -0.047853898257017136,
        0.04336072504520416,
        -0.044051047414541245,
        -0.05557875707745552,
        0.015506569296121597,
        -0.05634176358580589,
        -0.04962825030088425,
        0.027889108285307884,
        -0.049288515001535416,
        0.05459943786263466,
        -0.03551698103547096,
        0.0029310479294508696,
        0.0004944251850247383,
        -0.05528184771537781,
        0.056203052401542664,
        0.055280521512031555,
        -0.05615391209721565,
        0.04556019604206085,
        -0.03167380020022392,
        0.04431915655732155,
        -0.047529708594083786,
        0.022008830681443214,
        0.0389176644384861,
        -0.030638806521892548,
        -0.05617748945951462,
        0.05485081300139427,
        0.055415164679288864,
        -0.0549001470208168,
        0.050132572650909424,
        -0.014912014827132225,
        -0.03894602507352829,
        0.056451186537742615,
        -0.05024353787302971,
        -0.050522785633802414,
        0.05509668216109276,
        -0.055306900292634964,
        0.03374696150422096,
        -0.046697430312633514,
        -0.04163218289613724,
        0.0390317440032959,
        0.05603552237153053,
        0.01240809727460146,
        0.04768408462405205,
        0.056141652166843414,
        0.054752107709646225,
        0.020368296653032303,
        -0.008739489130675793,
        0.014693320728838444,
        -0.034978173673152924,
        0.0003924520860891789,
        0.03929286450147629,
        -0.04420343041419983,
        0.05569932237267494,
        -0.04206031933426857,
        -0.00570535147562623,
        0.052866168320178986,
        -0.04322709143161774,
        0.054290078580379486,
        0.037189140915870667,
        0.03627580776810646,
        -0.05542430281639099,
        -0.04674174264073372,
        0.05665603280067444,
        0.0395139679312706,
        0.03958979249000549,
        0.03759108856320381,
        0.03925129026174545,
        0.055047355592250824,
        -0.050315793603658676,
        0.05063841491937637,
        -0.05234426259994507,
        -0.05431080609560013,
        -0.05063212290406227,
        0.04516775906085968,
        -0.050543755292892456,
        -0.04323021322488785,
        0.024247143417596817,
        -0.04968567565083504,
        0.054288364946842194,
        -0.021370694041252136,
        -0.05462440475821495,
        -0.028902482241392136,
        0.049340445548295975,
        -0.04783105477690697,
        0.05656393989920616,
        -0.04032474756240845,
        0.054266490042209625,
        -0.05659215524792671,
        -0.023235203698277473,
        -0.054908107966184616,
        0.05588020756840706,
        0.008216854184865952,
        0.054985176771879196,
        0.05661424994468689,
        0.037506621330976486,
        0.02808159776031971,
        0.00305276014842093,
        -0.053976744413375854,
        -0.037796806544065475,
        0.023071736097335815,
        -0.05477607622742653,
        -0.00962132029235363,
        0.04882862791419029,
        -0.03378539904952049,
        0.05284995585680008,
        -0.056223127990961075,
        -0.0517374649643898,
        -0.05611935257911682,
        -0.0449487641453743,
        0.05503508821129799,
        0.026287099346518517,
        0.03462422639131546,
        -0.043754104524850845,
        0.05521468073129654,
        0.05642286688089371,
        -0.017569513991475105,
        0.023408109322190285,
        -0.056336045265197754,
        -0.0438544899225235,
        0.053567998111248016,
        0.056243158876895905,
        0.05665137991309166,
        -0.05468457564711571,
        0.016446387395262718,
        0.052286576479673386,
        -0.00727821933105588,
        0.03896215558052063,
        -0.024058112874627113,
        0.043856579810380936,
        -0.04995430260896683,
        -0.04831100255250931,
        0.03214951604604721,
        0.05351586639881134,
        -0.017444226890802383,
        -0.02518337406218052,
        0.041101109236478806,
        -0.00022331910440698266,
        -0.05441785231232643,
        -0.056501004844903946,
        -0.03870019689202309,
        -0.056513190269470215,
        -0.04748862609267235,
        0.04992300271987915,
        0.03903445601463318,
        -0.036202430725097656,
        -0.05181417614221573,
        -0.049510657787323,
        -0.027904128655791283,
        0.05614185333251953,
        0.011503358371555805,
        -0.050661224871873856,
        -0.04576650634407997,
        0.05225348472595215,
        -0.05485113337635994,
        -0.03558434545993805,
        0.05213876813650131,
        0.04526963829994202,
        -0.04214587062597275,
        0.048210762441158295,
        0.03376976028084755,
        -0.016671573743224144,
        0.04908761382102966,
        0.05105864629149437,
        -0.03592745214700699,
        0.0547226145863533,
        -0.02054923214018345,
        0.05438787490129471,
        -0.03521399199962616,
        -0.05449807271361351,
        0.009402802214026451,
        0.05606035888195038,
        -0.04012443870306015,
        -0.009531189687550068,
        -0.035737358033657074,
        0.04747970402240753,
        0.005378422327339649,
        -0.056597817689180374,
        0.05353466048836708,
        -0.017435340210795403,
        0.019093846902251244,
        -0.053351134061813354,
        -0.04099787771701813,
        0.055135030299425125,
        0.05629303306341171,
        -0.055924057960510254,
        0.05618280917406082,
        -0.03496131673455238,
        -0.056071989238262177,
        0.054708074778318405,
        0.04981526359915733,
        0.05468100309371948,
        -0.0017231078818440437,
        0.04900269955396652,
        0.0510210283100605,
        0.04167095944285393,
        -0.04475957155227661,
        -0.05451453477144241,
        -0.014316231943666935,
        0.055947475135326385,
        -0.040616318583488464,
        -0.019765010103583336,
        0.0334428995847702,
        -0.0031556952744722366,
        0.052168767899274826,
        0.0337689183652401,
        -0.039611563086509705,
        -0.051173239946365356,
        -0.04053136333823204,
        -0.03221595659852028,
        -0.02068144641816616,
        0.03185829892754555,
        0.04139845818281174,
        0.050838444381952286,
        0.028928076848387718,
        -0.056569647043943405,
        -0.045681245625019073,
        0.044155701994895935,
        0.05090997368097305,
        0.055812761187553406,
        -0.03890170902013779,
        0.04486425220966339,
        0.009222522377967834,
        -0.04788084700703621,
        -0.02257765643298626,
        -0.05365615710616112,
        -0.03546832501888275,
        -0.05443738400936127,
        0.03305140882730484,
        0.05628400668501854,
        0.056459009647369385
    ],
    [
        0.03060811571776867,
        0.03246995434165001,
        0.05891714245080948,
        -0.021912360563874245,
        0.005883312318474054,
        0.05926482751965523,
        0.058225687593221664,
        -0.03682498261332512,
        0.042032934725284576,
        0.057187169790267944,
        0.04853237792849541,
        0.03322566673159599,
        -0.02569619193673134,
        0.01030920259654522,
        -0.05696180835366249,
        0.04624134674668312,
        0.010403716005384922,
        -0.007092185318470001,
        -0.03237662464380264,
        0.005135057028383017,
        -0.05219438299536705,
        -0.050168778747320175,
        -0.05648627132177353,
        -0.0451335683465004,
        0.013949903659522533,
        0.01812857575714588,
        0.03813909366726875,
        0.05800013989210129,
        -0.05160188674926758,
        -0.029267558827996254,
        -0.053794797509908676,
        0.053734056651592255,
        -0.058419737964868546,
        -0.05886531621217728,
        0.025579482316970825,
        0.01089554838836193,
        -0.04875735938549042,
        0.019871756434440613,
        0.01936393789947033,
        0.009502309374511242,
        0.028783239424228668,
        0.011468209326267242,
        0.049603093415498734,
        0.05749092996120453,
        0.059075187891721725,
        -0.02894464321434498,
        0.01736213080585003,
        0.05937131494283676,
        0.05920606479048729,
        0.04927578195929527,
        0.02294781431555748,
        0.052060388028621674,
        0.048097092658281326,
        -0.034407131373882294,
        0.034685470163822174,
        -0.05938873440027237,
        -0.059022702276706696,
        -0.05184843763709068,
        0.0006492435350082815,
        0.05936915799975395,
        -0.05435163900256157,
        -0.017925623804330826,
        -0.0534532256424427,
        -0.058846134692430496,
        0.03513672575354576,
        -0.051001399755477905,
        -0.036801427602767944,
        0.059170473366975784,
        -0.05562682822346687,
        -0.058739446103572845,
        -0.054787635803222656,
        0.03242840990424156,
        0.05936502292752266,
        0.04056921973824501,
        -0.05893272906541824,
        0.024334844201803207,
        0.0569181814789772,
        0.056278493255376816,
        0.03622020035982132,
        -0.054224807769060135,
        -0.03995438665151596,
        -0.004179064650088549,
        -0.059131961315870285,
        0.05221142992377281,
        0.05351613834500313,
        0.03406315669417381,
        -0.014537466689944267,
        -0.011101658456027508,
        -0.013905537314713001,
        0.025025436654686928,
        -0.0579298697412014,
        -0.013438313268125057,
        0.01833663135766983,
        -0.04988837614655495,
        0.020815987139940262,
        -0.012462039478123188,
        -0.04824855178594589,
        -0.04488715901970863,
        -0.05757218226790428,
        -0.013738205656409264,
        -0.05294947698712349,
        0.014242922887206078,
        0.05815820023417473,
        0.05304452031850815,
        0.054305966943502426,
        -0.02751348912715912,
        -0.05756295844912529,
        0.04450945556163788,
        -0.0456341914832592,
        0.026064584031701088,
        -0.05895299091935158,
        0.05938824266195297,
        0.020767614245414734,
        -0.04259416460990906,
        0.05292517691850662,
        -0.03908777981996536,
        -0.04450986906886101,
        -0.023187968879938126,
        -0.0550013966858387,
        -0.006539652589708567,
        0.02024785988032818,
        -0.0036719117779284716,
        0.048713669180870056,
        -0.02134925127029419,
        -0.055947910994291306,
        -0.053236111998558044,
        -0.045200929045677185,
        0.05818217247724533,
        0.05777402222156525,
        -0.04320821166038513,
        0.03779859468340874,
        -0.02195083163678646,
        0.02822892554104328,
        -0.050447430461645126,
        -0.03859272599220276,
        -0.04930112510919571,
        0.05807112902402878,
        -0.037341248244047165,
        -0.03525317832827568,
        0.040918201208114624,
        0.05711009353399277,
        0.04231958091259003,
        0.055055394768714905,
        0.0524987056851387,
        -0.005132065620273352,
        -0.042331721633672714,
        0.05938607454299927,
        -0.026884350925683975,
        -0.042675722390413284,
        0.0003450517833698541,
        0.03666509687900543,
        -0.05853937938809395,
        0.048680566251277924,
        0.04179783910512924,
        0.05353889986872673,
        -0.04080582410097122,
        -0.05937341973185539,
        0.027599317952990532,
        0.016747821122407913,
        0.059180814772844315,
        -0.02730073593556881,
        0.006538934074342251,
        0.04696444794535637,
        -0.058259449899196625,
        -0.0434647873044014,
        0.031085927039384842,
        -0.029326464980840683,
        -0.055462151765823364,
        0.011885762214660645,
        0.044284746050834656,
        0.02973172999918461,
        0.0255925003439188,
        -0.014941683039069176,
        0.05927706137299538,
        0.058910805732011795,
        0.05542602017521858,
        0.02445991151034832,
        0.036450471729040146,
        0.04422250762581825,
        -0.04216965287923813,
        0.011131561361253262,
        -0.0021876401733607054,
        0.02286776714026928,
        0.007792136166244745,
        0.04340990260243416,
        -0.04619007930159569,
        0.048835571855306625,
        0.05635176599025726,
        -0.04446008801460266,
        -0.057023223489522934,
        -0.02896861545741558,
        -0.009998690336942673,
        0.04684804379940033,
        0.02407759241759777,
        -0.031988244503736496,
        -0.05710756778717041,
        0.059197798371315,
        0.05838489159941673,
        0.0493820458650589,
        0.029874013736844063,
        0.021062716841697693,
        0.05692027136683464,
        -0.05656580999493599,
        -0.057589150965213776,
        -0.03287132829427719,
        0.009639627300202847,
        -0.007785106543451548,
        -0.05798577889800072,
        -0.03942687064409256,
        -0.00642448291182518,
        -0.0468849278986454,
        0.03567321226000786,
        -0.05805802345275879,
        0.045890968292951584,
        0.020604129880666733,
        0.0414637066423893,
        -0.025646552443504333,
        0.01168675348162651,
        0.05749017745256424,
        -0.05385761335492134,
        0.05295184627175331,
        -0.01749848946928978,
        -0.04173288866877556,
        -0.05762732774019241,
        0.05887100473046303,
        -0.05869078263640404,
        0.011447892524302006,
        -0.04903227090835571,
        -0.05139552056789398,
        0.005825401749461889,
        0.04510226100683212,
        -0.04994560405611992,
        -0.054695699363946915,
        0.04890492185950279,
        -0.00040422441088594496,
        -0.012900282628834248,
        0.05556802451610565,
        -0.036731768399477005,
        -0.018269145861268044,
        0.05497181415557861,
        0.05684879794716835,
        0.045831162482500076,
        -0.02209276147186756,
        -0.02748321369290352,
        0.05718988552689552,
        0.022218763828277588,
        -0.03478817641735077,
        -0.05724859982728958,
        -0.04831002652645111,
        -0.033293839544057846,
        -0.04724425822496414,
        -0.05027308687567711,
        -0.057157743722200394,
        -0.05502822995185852,
        0.025726381689310074,
        0.021226773038506508,
        0.029301466420292854,
        -0.031692273914813995,
        0.057465653866529465,
        0.03609330207109451,
        0.0538647323846817,
        -0.0008445393177680671,
        0.03156272694468498,
        0.009435722604393959,
        0.04264037311077118,
        0.03696625679731369,
        -0.043959058821201324,
        0.017248574644327164,
        -0.05489346757531166,
        0.0068956101313233376,
        -0.05207621306180954,
        -0.05756396800279617,
        0.057767096906900406,
        -0.0548626147210598,
        -0.057556815445423126,
        -0.006144511513411999,
        -0.05330004170536995,
        0.057320091873407364,
        -0.05500529333949089,
        -0.05922617390751839,
        -0.028456149622797966,
        0.05472292751073837,
        -0.04158024489879608,
        0.05889507010579109,
        -0.04786313325166702,
        -0.033224280923604965,
        0.056426919996738434,
        0.038524966686964035,
        0.05029426887631416,
        -0.010902068577706814,
        0.006284167058765888,
        0.012902453541755676,
        -0.04495382681488991,
        -0.0017501021502539515,
        -0.025889066979289055,
        -0.05696476623415947,
        0.05888907611370087,
        -0.024061797186732292,
        -0.05481935292482376,
        0.04902258515357971,
        0.051798805594444275,
        0.05774771794676781,
        -0.007906245067715645,
        -0.021193625405430794,
        -0.03080090321600437,
        -0.006114546209573746,
        -0.04397648200392723,
        0.05653214454650879,
        -0.02261613868176937,
        -0.04876573383808136,
        0.059282369911670685,
        -0.05179290845990181,
        0.049918558448553085,
        0.05413578450679779,
        -0.0547359324991703,
        -0.05729767680168152,
        0.05179823189973831,
        -0.05549450218677521,
        0.026805812492966652,
        -0.01804567128419876,
        0.051413360983133316,
        -0.01652098447084427,
        -0.05210719630122185,
        -0.05630217120051384,
        0.05657626688480377,
        0.05072517693042755,
        -0.020036747679114342,
        0.039194099605083466,
        -0.045490678399801254,
        0.0077176508493721485,
        -0.05850197747349739,
        -0.010276353918015957,
        0.011237467639148235,
        -0.05935855209827423,
        0.0546221099793911,
        0.04615643247961998,
        0.015231308527290821,
        0.04521363228559494,
        -0.058611392974853516,
        0.049667563289403915,
        -0.02783011645078659,
        -0.010476221330463886,
        -0.05692370980978012,
        -0.058148093521595,
        -0.01675400882959366,
        0.04070961847901344,
        0.03399959206581116,
        0.048372864723205566,
        -0.01725035160779953,
        0.04324866458773613,
        -0.027639303356409073,
        0.0579243041574955,
        -0.027003122493624687,
        -0.05595151707530022,
        0.05937878042459488,
        0.048464082181453705,
        -0.04769236594438553,
        -0.03932148218154907,
        -0.05550067871809006,
        -0.05728784576058388,
        0.057496994733810425,
        -0.05878685787320137,
        -0.0038161042612046003,
        -0.01602804660797119,
        0.057178813964128494,
        -0.055330079048871994,
        0.0592389814555645,
        0.028548460453748703,
        -0.04999246448278427,
        -0.05925438553094864,
        -0.031040813773870468,
        -0.059339895844459534,
        0.05554861202836037,
        -0.05628778785467148,
        0.052292607724666595,
        -0.012417219579219818,
        0.005159938242286444,
        -0.051688048988580704,
        0.03093014284968376,
        -0.04161475971341133,
        -0.05485517531633377,
        0.059103816747665405,
        -0.05562324449419975,
        -0.041050784289836884,
        0.05848310887813568,
        -0.038328781723976135,
        -0.05231041833758354,
        -0.0578741580247879,
        -0.055681247264146805,
        -0.05937277525663376,
        -0.034361839294433594,
        -0.05854193493723869,
        -0.04513144493103027,
        -0.04577616602182388,
        -0.052766475826501846,
        0.05443738400936127,
        0.0583101250231266,
        0.03917785733938217,
        0.018741179257631302,
        -0.0591621994972229,
        -0.05288780480623245,
        0.04884487763047218,
        0.058225732296705246,
        0.05920521542429924,
        -0.02470000647008419,
        0.035463523119688034,
        -0.007016077637672424,
        -0.04200370982289314,
        0.022690681740641594,
        -0.054555993527173996,
        0.004937993362545967,
        -0.04987720400094986,
        -0.020996537059545517,
        0.04079509526491165,
        0.05853254720568657,
        0.014359796419739723,
        0.025176962837576866,
        0.03460042551159859,
        0.03463949263095856,
        -0.05738944187760353,
        0.006704872474074364,
        0.0027264910750091076,
        -0.021275345236063004,
        -0.05758828669786453,
        0.04891974478960037,
        0.04395981505513191,
        -0.024702757596969604,
        -0.058875709772109985,
        -0.05709867551922798,
        -0.04072003439068794,
        -0.028676562011241913,
        0.024463307112455368,
        -0.004136532545089722,
        -0.05831833556294441,
        -0.054493363946676254,
        -0.043508339673280716,
        0.039997607469558716,
        0.05527951195836067,
        -0.046382591128349304,
        -0.054837945848703384,
        0.049836184829473495,
        0.045668840408325195,
        0.03601127117872238,
        0.04384402558207512,
        -0.03891377151012421,
        -0.010622765868902206,
        0.047937169671058655,
        0.05891990661621094,
        0.04541512206196785,
        0.04991360381245613,
        -0.03386852890253067,
        0.05071311071515083,
        0.05891280621290207,
        -0.04205484688282013,
        -0.05807855352759361,
        -0.030131619423627853,
        0.03897784277796745,
        -0.02174876444041729,
        -0.02921188622713089,
        0.0577845498919487,
        -0.04405118525028229,
        0.0013555813347920775,
        0.030698562040925026,
        0.05757049098610878,
        0.05877141281962395,
        0.058751728385686874,
        -0.05598049983382225,
        0.05905855447053909,
        -0.056326307356357574,
        -0.05878318101167679,
        0.05795418843626976,
        -0.0592251680791378,
        0.053050696849823,
        0.05873892456293106,
        -0.05536655709147453,
        0.025886191055178642,
        -0.03717028349637985,
        -0.05932636931538582,
        -0.0006487424252554774,
        -0.05812670290470123,
        0.059343110769987106,
        0.053458210080862045,
        0.056554727256298065,
        0.05004838854074478,
        0.05073852837085724,
        0.03721538558602333,
        -0.01389836985617876,
        0.03911149501800537,
        -0.057001449167728424,
        -0.018574079498648643,
        -0.05907842516899109,
        -0.04583726450800896,
        -0.05577246472239494,
        0.05476865544915199,
        0.053804196417331696,
        0.05756078660488129,
        -0.05665796995162964,
        0.03940839692950249,
        0.04949621856212616,
        0.048862118273973465,
        0.034410204738378525,
        -0.012258288450539112,
        -0.057267703115940094,
        0.03766706585884094,
        -0.03224293887615204,
        0.02425328455865383,
        0.054501499980688095,
        -0.022788546979427338,
        -0.05732521787285805,
        -0.05132897198200226,
        0.05763732269406319,
        -0.0475088506937027
    ],
    [
        -0.02947336994111538,
        0.059696611016988754,
        0.06320822983980179,
        0.016863318160176277,
        0.02740369737148285,
        0.061906568706035614,
        0.05595792829990387,
        -0.008653870783746243,
        0.010079900734126568,
        0.05971957743167877,
        0.015290506184101105,
        0.04979701712727547,
        -0.05449216440320015,
        0.0041364636272192,
        -0.05996972322463989,
        0.06040729582309723,
        -0.0620642751455307,
        0.025058738887310028,
        -0.06067231297492981,
        0.005828476045280695,
        -0.0630820244550705,
        -0.04959656670689583,
        -0.04973189905285835,
        0.011702554300427437,
        0.02517426572740078,
        0.052095718681812286,
        0.035593852400779724,
        0.055171042680740356,
        0.008971567265689373,
        -0.020021280273795128,
        -0.02815145067870617,
        0.020473288372159004,
        -0.03795800358057022,
        -0.05927177146077156,
        0.017355259507894516,
        0.001716681756079197,
        -0.050427354872226715,
        -0.049138955771923065,
        0.0283059049397707,
        0.019206127151846886,
        0.03159622102975845,
        0.029234537854790688,
        0.055876463651657104,
        -0.021023113280534744,
        0.05905781686306,
        -0.0003756296937353909,
        0.0036241316702216864,
        0.06415480375289917,
        0.06180533394217491,
        0.018819546326994896,
        0.006780658848583698,
        0.03597905859351158,
        0.06189028173685074,
        0.030154401436448097,
        -0.017166199162602425,
        -0.0635605901479721,
        -0.0397554486989975,
        -0.014417139813303947,
        0.007529750932008028,
        0.06355346739292145,
        -0.055610813200473785,
        0.006836845073848963,
        -0.054774265736341476,
        -0.058863747864961624,
        0.0504705049097538,
        0.010310627520084381,
        0.0037506967782974243,
        0.0467086136341095,
        -0.03294648602604866,
        -0.06435137987136841,
        -0.06096358597278595,
        -0.00581815280020237,
        0.062167830765247345,
        0.05719020590186119,
        -0.06238987296819687,
        0.0375392809510231,
        0.052244506776332855,
        0.06394712626934052,
        0.034531909972429276,
        -0.06311049312353134,
        -0.025418700650334358,
        0.019313592463731766,
        -0.053884007036685944,
        -0.004560811445116997,
        0.05628993362188339,
        0.035651661455631256,
        -0.026683161035180092,
        0.00429116003215313,
        -0.05451318249106407,
        0.062099259346723557,
        -0.062379878014326096,
        -0.042829569429159164,
        0.040193215012550354,
        -0.026629341766238213,
        0.017022335901856422,
        0.008469723165035248,
        -0.027337538078427315,
        -0.009673512540757656,
        -0.056034598499536514,
        0.05251764506101608,
        -0.0641220211982727,
        -0.018865767866373062,
        0.060112833976745605,
        0.047724399715662,
        0.048914168030023575,
        -0.044337254017591476,
        -0.056348077952861786,
        0.0015444791642948985,
        0.040750902146101,
        -0.033068057149648666,
        -0.054625991731882095,
        0.06417790800333023,
        -0.01872171089053154,
        -0.03488251194357872,
        0.06103334575891495,
        -0.04280120134353638,
        -0.04855794087052345,
        -0.05646631494164467,
        -0.06308013200759888,
        -0.03535552695393562,
        0.04316028207540512,
        -0.0019164766417816281,
        0.008560598827898502,
        -0.0320744551718235,
        -0.06377068907022476,
        -0.056850023567676544,
        -0.008180786855518818,
        0.060575395822525024,
        0.032872140407562256,
        -0.0465555302798748,
        0.02827553264796734,
        -0.03511187061667442,
        0.02895655296742916,
        -0.011046971194446087,
        -0.04913613572716713,
        -0.043448422104120255,
        0.04406863451004028,
        -0.05007847025990486,
        -0.052930574864149094,
        0.050029922276735306,
        0.04122760519385338,
        0.04033873975276947,
        0.05136140435934067,
        0.018120530992746353,
        0.0008275360451079905,
        -0.00484599731862545,
        0.06421372294425964,
        0.0035101466346532106,
        -0.044500596821308136,
        -0.008680356666445732,
        0.0387595035135746,
        -0.05997678264975548,
        0.01713167317211628,
        0.04231865331530571,
        0.0403934009373188,
        -0.05973182991147041,
        -0.02557533048093319,
        -0.033598948270082474,
        0.02423732914030552,
        0.05735451728105545,
        0.048705458641052246,
        -0.025085709989070892,
        0.04547298699617386,
        -0.06143178790807724,
        -0.05238407850265503,
        0.02596096321940422,
        -0.00011902540427399799,
        -0.05218011513352394,
        -0.0021027519833296537,
        0.05509898439049721,
        -0.0022202080581337214,
        0.028486324474215508,
        -0.06271883845329285,
        0.057894427329301834,
        0.05718619376420975,
        0.06274961680173874,
        -0.05652330443263054,
        0.030438363552093506,
        0.05516667291522026,
        0.026471653953194618,
        0.05674635246396065,
        0.021617181599140167,
        0.06030267849564552,
        0.014599393121898174,
        0.013121805153787136,
        -0.030221018940210342,
        -0.029894106090068817,
        0.06008826941251755,
        -0.011989215388894081,
        -0.05672150105237961,
        0.009227021597325802,
        -0.044725120067596436,
        0.01268102042376995,
        -0.025351570919156075,
        -0.0050668311305344105,
        -0.05206116661429405,
        0.06322351098060608,
        0.05573336407542229,
        0.03839005529880524,
        -0.02396356873214245,
        -0.020599395036697388,
        0.057102784514427185,
        0.017682816833257675,
        -0.008183415047824383,
        0.013239370658993721,
        -0.029550569131970406,
        -0.023734930902719498,
        -0.04968250170350075,
        -0.0494782030582428,
        -0.03736438974738121,
        -0.05454032123088837,
        0.05693424493074417,
        -0.04930597171187401,
        -0.001428123563528061,
        0.02158201113343239,
        0.018850980326533318,
        0.014230516739189625,
        0.06037883087992668,
        0.05038660764694214,
        -0.061184704303741455,
        0.057612381875514984,
        -0.056496039032936096,
        -0.04998927563428879,
        -0.06108953431248665,
        0.054402437061071396,
        -0.055082712322473526,
        -0.034750062972307205,
        -0.052319470793008804,
        -0.0599459633231163,
        -0.04701147601008415,
        0.04606993496417999,
        -0.057752665132284164,
        -0.06113610416650772,
        0.04958418011665344,
        0.02326614409685135,
        -0.048409704118967056,
        0.05494678020477295,
        -0.05904332175850868,
        0.021612636744976044,
        0.06361005455255508,
        0.05934549868106842,
        0.05878884717822075,
        -0.015995720401406288,
        0.03667100518941879,
        0.02557828649878502,
        -0.00046662066597491503,
        0.0023088117595762014,
        -0.04934273660182953,
        -0.042715322226285934,
        0.017975034192204475,
        -0.043987810611724854,
        -0.04771096631884575,
        -0.05715562030673027,
        -0.06004921346902847,
        0.03932792693376541,
        0.00917365588247776,
        -0.04166543483734131,
        -0.008647572249174118,
        0.06388065963983536,
        0.054658662527799606,
        0.061949294060468674,
        0.052189357578754425,
        -0.01459760032594204,
        -0.027371365576982498,
        0.04902755096554756,
        0.02150089666247368,
        -0.05251539126038551,
        0.017063705250620842,
        0.01791771687567234,
        -0.007754110731184483,
        -0.05667359009385109,
        -0.06334266811609268,
        0.007883543148636818,
        -0.06094350293278694,
        -0.06319041550159454,
        0.05364406108856201,
        -0.06106087192893028,
        0.061823561787605286,
        -0.0073125334456563,
        -0.058218974620103836,
        -0.05552487447857857,
        0.04978512227535248,
        0.026649272069334984,
        0.062411487102508545,
        -0.05438939481973648,
        -0.040270838886499405,
        0.0460972897708416,
        0.04363160580396652,
        -0.0015573971904814243,
        0.01699778251349926,
        -0.02188768796622753,
        0.05668754503130913,
        -0.01583053171634674,
        -0.051535893231630325,
        -0.04345380514860153,
        -0.01817835122346878,
        0.0636787936091423,
        -0.04549300670623779,
        -0.060520097613334656,
        0.05854428559541702,
        -0.04554734006524086,
        0.06309640407562256,
        0.01748674362897873,
        -0.03904623165726662,
        0.040862999856472015,
        -0.03396771475672722,
        -0.03434624895453453,
        0.05943065136671066,
        0.048519622534513474,
        -0.05431569740176201,
        0.05593206733465195,
        -0.057213932275772095,
        -0.015889132395386696,
        0.06129191443324089,
        -0.06401872634887695,
        -0.05595435947179794,
        0.05764203146100044,
        -0.0571686252951622,
        0.01420739945024252,
        -0.05772867426276207,
        -0.03319143131375313,
        -0.05094873160123825,
        -0.018115796148777008,
        -0.06089203432202339,
        0.006523032207041979,
        0.06102690473198891,
        0.009931237436830997,
        -0.0001946489210240543,
        0.010048411786556244,
        -0.04984177649021149,
        -0.04797382280230522,
        -0.004400575067847967,
        0.017998594790697098,
        -0.06144697591662407,
        0.05908888950943947,
        0.052072007209062576,
        0.014803126454353333,
        0.061460208147764206,
        -0.05324515327811241,
        0.021211976185441017,
        0.03208470717072487,
        -0.03136395290493965,
        -0.059759002178907394,
        -0.053620245307683945,
        0.04627504572272301,
        -0.019680539146065712,
        0.054968077689409256,
        0.05176215246319771,
        0.002841012552380562,
        0.059020329266786575,
        -0.064265176653862,
        0.05109208822250366,
        -0.04855625331401825,
        -0.021038904786109924,
        0.05399806424975395,
        0.061443790793418884,
        -0.05679144710302353,
        -0.03008253127336502,
        -0.05306452885270119,
        -0.05484994873404503,
        0.05562618747353554,
        -0.05931824445724487,
        0.015234746970236301,
        -0.03968602418899536,
        0.05619785189628601,
        -0.058032918721437454,
        0.06425034254789352,
        0.0062239039689302444,
        0.002157419454306364,
        -0.064124196767807,
        -0.001215274096466601,
        -0.06425920128822327,
        0.06051180884242058,
        -0.028843922540545464,
        0.046379659324884415,
        0.04675730690360069,
        0.030016470700502396,
        -0.026022283360362053,
        0.0425817146897316,
        -0.04922822117805481,
        -0.03844759240746498,
        0.06123123690485954,
        -0.0618194043636322,
        -0.0523831844329834,
        0.06420163810253143,
        -0.057026591151952744,
        -0.021599402651190758,
        -0.06056058034300804,
        -0.05697152018547058,
        -0.06362833082675934,
        0.011011607944965363,
        -0.060784805566072464,
        0.040314339101314545,
        -0.016483895480632782,
        -0.02841838262975216,
        0.05856325849890709,
        0.051077257841825485,
        -0.0029680472798645496,
        -0.0014048784505575895,
        -0.061152685433626175,
        -0.05277017503976822,
        0.05193125829100609,
        0.0535663440823555,
        0.06270059198141098,
        0.03635150566697121,
        0.00759986462071538,
        0.043161407113075256,
        0.006020877510309219,
        0.042964521795511246,
        -0.04080270975828171,
        -0.022770358249545097,
        -0.03467004746198654,
        0.007997674867510796,
        0.036473337560892105,
        0.05633699893951416,
        -0.019930806010961533,
        0.006414539180696011,
        0.017823902890086174,
        0.04927283152937889,
        -0.0399925671517849,
        -0.05548784136772156,
        -0.05356673151254654,
        0.0011154848616570234,
        -0.05726815387606621,
        0.02764393575489521,
        0.051369693130254745,
        -0.05688899755477905,
        -0.0627075806260109,
        -0.06354433298110962,
        -0.04916461929678917,
        0.0262579545378685,
        0.014724893495440483,
        0.032891400158405304,
        0.02175195887684822,
        -0.02630031667649746,
        -0.027173509821295738,
        0.04874292388558388,
        0.041841454803943634,
        0.024119503796100616,
        -0.062466368079185486,
        0.04705767333507538,
        -0.00003222837403882295,
        0.010439976118505001,
        0.05363874509930611,
        -0.06084732338786125,
        -0.015548326075077057,
        0.061729710549116135,
        0.019830459728837013,
        0.05645454302430153,
        -0.039026688784360886,
        -0.04302481189370155,
        0.04605710506439209,
        0.06175889074802399,
        0.025977367535233498,
        -0.055260069668293,
        -0.01940052956342697,
        0.03249053657054901,
        -0.020391659811139107,
        -0.03831418603658676,
        0.05099886283278465,
        -0.01317147072404623,
        -0.009067378006875515,
        -0.04957187920808792,
        0.03676159679889679,
        0.05126112699508667,
        0.06333272904157639,
        -0.05891428142786026,
        0.0633532702922821,
        -0.046541959047317505,
        -0.06192243844270706,
        0.02374114654958248,
        -0.05333949998021126,
        0.04553736373782158,
        0.05837845057249069,
        -0.017883334308862686,
        0.02034877799451351,
        -0.04568759724497795,
        -0.06425371021032333,
        -0.034615226089954376,
        -0.048979420214891434,
        0.06369888782501221,
        -0.005216947756707668,
        0.060358185321092606,
        0.03989306092262268,
        0.02011425606906414,
        0.055621933192014694,
        -0.004788465332239866,
        -0.03492509573698044,
        -0.0569431446492672,
        -0.020039018243551254,
        -0.062104370445013046,
        -0.05290604755282402,
        -0.03413728252053261,
        0.058555617928504944,
        0.061626989394426346,
        0.05951855704188347,
        -0.06258535385131836,
        0.00691932113841176,
        0.05186738073825836,
        0.050719503313302994,
        0.044087547808885574,
        0.003465247806161642,
        -0.05994052439928055,
        -0.023913100361824036,
        0.00480292085558176,
        0.009215831756591797,
        0.030701564624905586,
        -0.04910286143422127,
        -0.05836937949061394,
        -0.018545610830187798,
        0.0624239407479763,
        0.004006215371191502
    ],
    [
        -0.03838089853525162,
        0.042976099997758865,
        0.0484175980091095,
        0.048220351338386536,
        -0.022012129426002502,
        0.05552724003791809,
        0.05238446593284607,
        -0.04148316755890846,
        0.046917784959077835,
        0.05531078577041626,
        0.05532393604516983,
        0.0547507144510746,
        -0.055112868547439575,
        0.051352161914110184,
        -0.05067611485719681,
        0.05412657558917999,
        -0.02364000305533409,
        0.05507015809416771,
        0.02125752717256546,
        -0.04739033430814743,
        -0.014103910885751247,
        0.02599240280687809,
        -0.049206338822841644,
        0.05321308970451355,
        -0.053788695484399796,
        0.05029318109154701,
        0.018484828993678093,
        0.04991302266716957,
        -0.055305834859609604,
        0.02482934668660164,
        -0.051599159836769104,
        0.05532388389110565,
        0.045580215752124786,
        -0.051427390426397324,
        0.05211390554904938,
        -0.03697804734110832,
        -0.05236084386706352,
        0.03142346069216728,
        -0.041956208646297455,
        -0.036695633083581924,
        0.02281218022108078,
        -0.008382976986467838,
        0.045339252799749374,
        0.0553409568965435,
        0.05053921788930893,
        -0.05143580213189125,
        -0.055491652339696884,
        0.05550438538193703,
        0.05549435317516327,
        -0.01326843537390232,
        0.028113514184951782,
        0.03673703968524933,
        0.005627636797726154,
        -0.051116518676280975,
        -0.0530724972486496,
        -0.0013853235868737102,
        -0.0554182343184948,
        -0.0513080395758152,
        -0.05506974086165428,
        -0.030905723571777344,
        -0.05515076965093613,
        0.03568493574857712,
        -0.050105612725019455,
        -0.054880738258361816,
        0.046210967004299164,
        -0.014749221503734589,
        -0.055387694388628006,
        0.054248545318841934,
        -0.044679224491119385,
        -0.034219250082969666,
        -0.055501095950603485,
        0.053586456924676895,
        0.05554715171456337,
        0.045359574258327484,
        -0.05331077054142952,
        -0.04083118960261345,
        -0.045270804315805435,
        0.055533360689878464,
        0.02649693936109543,
        -0.05160064250230789,
        -0.032521069049835205,
        -0.0440218523144722,
        -0.05525633320212364,
        0.04465639591217041,
        -0.03223083168268204,
        0.03912346437573433,
        -0.030663855373859406,
        0.04322345182299614,
        0.03993047773838043,
        0.05471021682024002,
        -0.054819297045469284,
        -0.0436222217977047,
        0.019705897197127342,
        -0.049341488629579544,
        -0.046354617923498154,
        0.05301272124052048,
        -0.04072313755750656,
        -0.03955262154340744,
        0.028706399723887444,
        0.04199071601033211,
        -0.055199772119522095,
        0.02897876873612404,
        0.04874202609062195,
        0.00869891606271267,
        0.050917308777570724,
        -0.0501147136092186,
        -0.05274390056729317,
        0.044009871780872345,
        -0.04661554470658302,
        0.02460307627916336,
        -0.05532849580049515,
        0.03962109610438347,
        0.022885220125317574,
        0.0352836437523365,
        -0.0038004128728061914,
        -0.03008168376982212,
        -0.03158280625939369,
        -0.038298118859529495,
        -0.05531366914510727,
        -0.055015645921230316,
        0.010882051661610603,
        -0.05379557982087135,
        0.011614633724093437,
        -0.05476965382695198,
        -0.03026183880865574,
        -0.029416929930448532,
        -0.03970056399703026,
        0.039210591465234756,
        0.0238430704921484,
        -0.015910537913441658,
        0.05517644062638283,
        -0.016001515090465546,
        -0.05223686248064041,
        0.04410989582538605,
        -0.05201592296361923,
        -0.053486406803131104,
        0.05508866161108017,
        -0.052954480051994324,
        0.04128159582614899,
        0.02831513062119484,
        0.046934567391872406,
        -0.03615012392401695,
        0.048549965023994446,
        0.05180416628718376,
        -0.036081086844205856,
        -0.044795453548431396,
        0.05553928017616272,
        0.04204561188817024,
        -0.023230038583278656,
        0.05326816812157631,
        0.02852715738117695,
        -0.05498894676566124,
        0.050354551523923874,
        0.011641480959951878,
        0.055300913751125336,
        -0.054850902408361435,
        -0.055531591176986694,
        0.02593887969851494,
        0.025430461391806602,
        0.04737526550889015,
        0.053452443331480026,
        0.04466124251484871,
        0.05346183106303215,
        -0.0526190809905529,
        -0.053766168653964996,
        0.03896227851510048,
        0.048720452934503555,
        -0.015222982503473759,
        -0.05359330400824547,
        0.03451898694038391,
        -0.03192787244915962,
        -0.046169016510248184,
        -0.05551498383283615,
        0.055548131465911865,
        0.05552896484732628,
        -0.012299304828047752,
        0.034974128007888794,
        -0.03845568373799324,
        0.053828392177820206,
        -0.05409228801727295,
        -0.0406431145966053,
        0.011888698674738407,
        0.04276248440146446,
        -0.048712246119976044,
        0.008899603970348835,
        0.016714924946427345,
        0.009620022028684616,
        0.05420529469847679,
        0.019868997856974602,
        -0.043873436748981476,
        -0.05296977981925011,
        -0.016883276402950287,
        0.05069274082779884,
        0.05259908363223076,
        -0.048350781202316284,
        -0.05359518527984619,
        -0.017967594787478447,
        0.05525703728199005,
        0.05137304216623306,
        -0.011735808104276657,
        0.05501261726021767,
        0.05409791320562363,
        -0.049256227910518646,
        -0.05344032123684883,
        0.04102534428238869,
        0.013733492232859135,
        0.03449111059308052,
        -0.05507628992199898,
        -0.05363406240940094,
        0.055537961423397064,
        -0.04959839954972267,
        0.03167850524187088,
        -0.016292573884129524,
        0.04656587913632393,
        -0.014343809336423874,
        -0.05361402779817581,
        0.04497433826327324,
        -0.04531249403953552,
        0.04891829565167427,
        -0.05101640522480011,
        0.035254087299108505,
        0.054083310067653656,
        -0.02281998284161091,
        -0.05452118441462517,
        0.03591716289520264,
        0.05252901092171669,
        0.05390986427664757,
        -0.05021665617823601,
        -0.008728859946131706,
        -0.033254269510507584,
        0.03826799988746643,
        -0.04217832162976265,
        -0.049676306545734406,
        0.050319574773311615,
        -0.009305119514465332,
        0.05519349128007889,
        0.048471562564373016,
        0.005323920864611864,
        0.049119263887405396,
        0.055527348071336746,
        0.048253387212753296,
        0.0457909069955349,
        0.053551387041807175,
        -0.02086441032588482,
        0.05503542721271515,
        0.0504855215549469,
        -0.04224633798003197,
        -0.03430526331067085,
        -0.006305153016000986,
        0.016542745754122734,
        -0.055123984813690186,
        -0.051369596272706985,
        -0.031126560643315315,
        -0.05316478759050369,
        0.041196033358573914,
        0.051325246691703796,
        0.05035291239619255,
        -0.052194949239492416,
        0.0521993488073349,
        -0.0422445647418499,
        -0.0458056703209877,
        -0.046265117824077606,
        -0.038035355508327484,
        0.053930968046188354,
        0.034315284341573715,
        0.05361388251185417,
        0.04571749269962311,
        0.032275810837745667,
        -0.005042914301156998,
        -0.04819986969232559,
        -0.02287362329661846,
        -0.04116622358560562,
        0.03560050204396248,
        -0.05392249673604965,
        -0.054491180926561356,
        0.012308593839406967,
        -0.05192200839519501,
        0.050148140639066696,
        -0.05552508309483528,
        -0.05550258234143257,
        -0.05519203096628189,
        0.05408279597759247,
        0.024126917123794556,
        0.05333029478788376,
        0.055305931717157364,
        -0.047961607575416565,
        -0.016423311084508896,
        0.043557122349739075,
        -0.040548890829086304,
        -0.010670501738786697,
        -0.050996601581573486,
        0.037166982889175415,
        -0.0038439184427261353,
        0.0056017073802649975,
        0.022867221385240555,
        -0.04034966602921486,
        0.05537392944097519,
        0.052417125552892685,
        -0.04779671132564545,
        0.0533895380795002,
        0.04860047623515129,
        0.02003922127187252,
        -0.028490183874964714,
        0.03912200778722763,
        0.028994031250476837,
        -0.013481141068041325,
        0.024486271664500237,
        -0.027785642072558403,
        0.021877948194742203,
        0.02005147375166416,
        0.04555242881178856,
        -0.00428002281114459,
        0.05372587963938713,
        -0.02915041893720627,
        -0.05473022907972336,
        -0.052864041179418564,
        -0.04106266051530838,
        -0.029947487637400627,
        0.019836178049445152,
        -0.046592388302087784,
        -0.013998963870108128,
        0.02200699783861637,
        -0.03442263603210449,
        -0.05525699257850647,
        0.05520680919289589,
        -0.03362163528800011,
        0.055336348712444305,
        -0.021785344928503036,
        -0.019235756248235703,
        -0.05493024364113808,
        0.051812320947647095,
        -0.05014793202280998,
        -0.05254421383142471,
        -0.04381875693798065,
        0.052529387176036835,
        0.05533270910382271,
        -0.043596051633358,
        0.009238321334123611,
        -0.033991675823926926,
        0.05197351798415184,
        -0.038302041590213776,
        -0.03390710800886154,
        0.03652282431721687,
        -0.04486116021871567,
        -0.01948418654501438,
        0.0489141047000885,
        0.04482574015855789,
        0.05514996871352196,
        -0.05534663423895836,
        0.04561151564121246,
        0.05507054924964905,
        0.05538062006235123,
        0.050098974257707596,
        -0.052972547709941864,
        0.05540916696190834,
        -0.010030620731413364,
        -0.05546627938747406,
        -0.055497877299785614,
        -0.04935532435774803,
        -0.029878675937652588,
        0.03762004151940346,
        -0.05066315829753876,
        0.029135221615433693,
        -0.049744848161935806,
        -0.04092380404472351,
        -0.05277106165885925,
        0.05537564307451248,
        0.0006831276114098728,
        -0.03433787077665329,
        -0.054553162306547165,
        -0.04639001563191414,
        -0.02192467264831066,
        0.054754506796598434,
        -0.055376987904310226,
        0.0527116060256958,
        -0.04826124757528305,
        -0.05495142564177513,
        0.04924771189689636,
        0.04837585985660553,
        -0.04973479360342026,
        0.03496934473514557,
        0.05543248727917671,
        -0.05538016930222511,
        0.05002616345882416,
        0.05538513883948326,
        -0.03196708485484123,
        -0.044353265315294266,
        -0.05170659348368645,
        -0.049478933215141296,
        -0.05462731793522835,
        -0.04908224195241928,
        -0.051009438931941986,
        -0.03951986879110336,
        -0.05420268326997757,
        0.04042975232005119,
        0.05400633439421654,
        0.05533360689878464,
        -0.04788770526647568,
        -0.05500420555472374,
        -0.055203355848789215,
        -0.04412255436182022,
        0.05518363043665886,
        0.02963661588728428,
        0.05541607737541199,
        0.006992603652179241,
        0.052113376557826996,
        0.04824516922235489,
        -0.05023300647735596,
        0.04751244932413101,
        -0.012800175696611404,
        -0.032947275787591934,
        0.04302569478750229,
        -0.04547834396362305,
        -0.035940758883953094,
        0.05185427516698837,
        -0.016784513369202614,
        -0.0020629805512726307,
        0.050541091710329056,
        0.04293646290898323,
        -0.05536491051316261,
        0.03426346927881241,
        -0.03837515413761139,
        -0.053728166967630386,
        -0.05553483963012695,
        -0.023647181689739227,
        -0.008202619850635529,
        -0.05013694614171982,
        -0.05495566502213478,
        0.030486682429909706,
        -0.05528740957379341,
        0.01955794356763363,
        -0.049883730709552765,
        0.04556829854846001,
        -0.008981214836239815,
        -0.03303220495581627,
        -0.04820620268583298,
        -0.035805705934762955,
        0.05279500409960747,
        -0.0300839114934206,
        -0.05375390127301216,
        -0.041453178972005844,
        0.049805887043476105,
        0.04164794459939003,
        0.05493524670600891,
        0.005772037897258997,
        -0.009831896983087063,
        0.05326952785253525,
        0.04969580098986626,
        0.00344777200371027,
        0.04324712976813316,
        -0.05496622249484062,
        0.054085440933704376,
        0.04346718266606331,
        0.045507848262786865,
        0.049655765295028687,
        -0.04533972218632698,
        0.03333325684070587,
        -0.00339667871594429,
        0.04980321228504181,
        0.05014031007885933,
        0.055141352117061615,
        -0.050248127430677414,
        0.04672832041978836,
        0.054073791950941086,
        0.05544337257742882,
        0.055006660521030426,
        -0.0515529029071331,
        0.05535170063376427,
        -0.05387237295508385,
        -0.054914675652980804,
        0.045010149478912354,
        -0.055472273379564285,
        0.051921870559453964,
        0.03085319511592388,
        0.03972003608942032,
        0.0010354110272601247,
        -0.048554662615060806,
        0.04762164503335953,
        -0.0554720014333725,
        0.046167366206645966,
        0.05551253259181976,
        0.027352938428521156,
        0.04705755040049553,
        0.05025162920355797,
        0.053608160465955734,
        0.048056673258543015,
        0.054643455892801285,
        0.054660480469465256,
        -0.035788148641586304,
        0.03284556791186333,
        -0.048342105001211166,
        -0.055226098746061325,
        -0.017408061772584915,
        0.05509018525481224,
        -0.05073327198624611,
        0.054850462824106216,
        -0.055524978786706924,
        -0.01480015367269516,
        0.05084431171417236,
        -0.02195226028561592,
        0.01216537319123745,
        0.025211960077285767,
        -0.05278091877698898,
        0.05241004750132561,
        0.05410207062959671,
        0.04124382510781288,
        0.04200047254562378,
        0.03621603175997734,
        0.037213075906038284,
        -0.04394206032156944,
        0.05542279779911041,
        -0.05156664922833443
    ],
    [
        -0.030742553994059563,
        0.03974701836705208,
        0.06445418298244476,
        0.018602779135107994,
        0.01595005951821804,
        0.05808510258793831,
        0.05932191014289856,
        -0.05282696709036827,
        0.03459654003381729,
        0.06526008993387222,
        -0.006580588407814503,
        0.04785759001970291,
        -0.051787666976451874,
        0.03808226063847542,
        -0.05989094451069832,
        0.06209500879049301,
        -0.062262095510959625,
        0.0310403760522604,
        -0.05724030360579491,
        0.0445735938847065,
        -0.05567833036184311,
        0.010758936405181885,
        -0.04975280910730362,
        0.04211775213479996,
        -0.02901109680533409,
        0.061594363301992416,
        -0.0015510637313127518,
        0.0636463388800621,
        -0.05918923392891884,
        -0.0006704458501189947,
        -0.0072720590978860855,
        0.055958088487386703,
        0.05210429057478905,
        -0.06225471571087837,
        0.03099510632455349,
        0.02412041462957859,
        -0.057333096861839294,
        -0.054236240684986115,
        -0.0610794872045517,
        -0.030987191945314407,
        -0.005240568425506353,
        0.04312983900308609,
        0.05218346789479256,
        0.013089735992252827,
        0.05087091401219368,
        -0.012835151515901089,
        -0.05930405482649803,
        0.06488588452339172,
        0.06357484310865402,
        0.0018018643604591489,
        0.05977901816368103,
        0.05900302156805992,
        0.058432769030332565,
        0.02722829394042492,
        -0.054130151867866516,
        -0.007091573439538479,
        -0.042773209512233734,
        -0.012674749828875065,
        -0.03798147290945053,
        -0.009603159502148628,
        -0.06556597352027893,
        -0.013286449015140533,
        -0.055869895964860916,
        -0.029361087828874588,
        0.04474261775612831,
        -0.025418130680918694,
        0.009437134489417076,
        0.03124159760773182,
        0.04299113526940346,
        -0.06465190649032593,
        -0.06432266533374786,
        -0.0044333441182971,
        0.06315511465072632,
        0.0403994545340538,
        -0.057556506246328354,
        0.029586946591734886,
        0.03284572437405586,
        0.06721018254756927,
        0.028851354494690895,
        -0.05897429585456848,
        -0.033974386751651764,
        -0.025122961029410362,
        -0.047364242374897,
        -0.03566543757915497,
        0.05432216823101044,
        0.056022923439741135,
        -0.05629659444093704,
        0.030809272080659866,
        -0.0647331029176712,
        0.06475211679935455,
        -0.056693099439144135,
        -0.0019677120726555586,
        0.03455878421664238,
        -0.05649102106690407,
        -0.0007206934387795627,
        0.05807969719171524,
        -0.029459340497851372,
        -0.04101250693202019,
        0.03479069098830223,
        0.05365312844514847,
        -0.06724921613931656,
        -0.014927725307643414,
        0.06150012090802193,
        -0.012638568878173828,
        0.03293604031205177,
        -0.04670921340584755,
        -0.053746771067380905,
        0.023385455831885338,
        -0.0540575310587883,
        -0.024821804836392403,
        -0.056145090609788895,
        0.026984285563230515,
        -0.05983281880617142,
        0.014215633273124695,
        0.0640549585223198,
        -0.023006083443760872,
        0.017843762412667274,
        -0.06659264862537384,
        -0.06050118803977966,
        -0.045020945370197296,
        0.04953040927648544,
        -0.00921301357448101,
        -0.007278943434357643,
        0.021228715777397156,
        -0.0656132772564888,
        -0.02305554784834385,
        -0.031058069318532944,
        0.06040520593523979,
        0.03861847519874573,
        -0.019214948639273643,
        0.06500719487667084,
        -0.02772703394293785,
        -0.0473124124109745,
        -0.025444692000746727,
        -0.052791666239500046,
        -0.060104429721832275,
        0.05495116114616394,
        -0.030253544449806213,
        -0.058863453567028046,
        0.004905455745756626,
        0.019523026421666145,
        -0.0009303837432526052,
        0.015231836587190628,
        0.014083501882851124,
        0.007401544135063887,
        -0.04845514893531799,
        0.0648244097828865,
        -0.04315236955881119,
        -0.013128691352903843,
        0.03773004561662674,
        -0.02885478176176548,
        -0.0633479654788971,
        0.05461031198501587,
        0.016843782737851143,
        0.04270492494106293,
        -0.06077924370765686,
        0.027787556871771812,
        0.012946654111146927,
        0.05003761127591133,
        -0.000020025599951623008,
        0.04777328297495842,
        0.020578652620315552,
        0.060747429728507996,
        -0.04874716326594353,
        -0.05842634662985802,
        -0.030068289488554,
        0.00803571566939354,
        0.004696966614574194,
        0.003664288902655244,
        0.056782130151987076,
        0.007616937626153231,
        -0.015349123626947403,
        -0.06187882646918297,
        0.06605851650238037,
        0.06620921194553375,
        0.05964967608451843,
        -0.0633748471736908,
        0.011977240443229675,
        0.061013903468847275,
        -0.022652745246887207,
        0.033299271017313004,
        0.020449979230761528,
        0.045915719121694565,
        -0.021387074142694473,
        0.02460271678864956,
        -0.007622600067406893,
        -0.05101809650659561,
        0.06225934624671936,
        -0.03820231184363365,
        0.017882389947772026,
        -0.03032970242202282,
        -0.016113754361867905,
        -0.03292487561702728,
        0.04147627204656601,
        -0.050152488052845,
        0.02432391420006752,
        0.012817460112273693,
        0.06342919170856476,
        0.02799404039978981,
        0.013639429584145546,
        -0.007525521330535412,
        0.043718982487916946,
        0.038674529641866684,
        -0.01416784431785345,
        0.040103837847709656,
        0.0010991474846377969,
        0.04213379696011543,
        0.031045960262417793,
        -0.05703231692314148,
        0.03584161400794983,
        -0.04896831512451172,
        0.06125788763165474,
        -0.038222964853048325,
        -0.007339339703321457,
        0.022111371159553528,
        -0.03843444213271141,
        0.028975388035178185,
        0.05909294635057449,
        0.04971504583954811,
        -0.009185335598886013,
        0.05834272503852844,
        -0.00957799144089222,
        -0.05952052026987076,
        -0.043211404234170914,
        -0.02405763976275921,
        0.01166329812258482,
        -0.04594195634126663,
        -0.05490322411060333,
        -0.05904345214366913,
        -0.04944993183016777,
        -0.05999063700437546,
        -0.03259498253464699,
        -0.006625047884881496,
        0.058846455067396164,
        0.007245724089443684,
        -0.03514327481389046,
        0.05939692631363869,
        -0.06532935053110123,
        0.0335526280105114,
        0.0664389580488205,
        0.06236414611339569,
        0.05647357925772667,
        0.0438590832054615,
        0.048778731375932693,
        0.03561142832040787,
        0.024596072733402252,
        -0.016339898109436035,
        0.010824041441082954,
        -0.050341252237558365,
        0.03310743719339371,
        -0.06343730539083481,
        -0.0540984570980072,
        -0.05256906896829605,
        -0.06084330007433891,
        0.04379425570368767,
        0.05801459029316902,
        -0.01084170676767826,
        -0.013562795706093311,
        0.059004928916692734,
        0.05314464867115021,
        0.01782744936645031,
        -0.0029295594431459904,
        -0.002730105072259903,
        0.0635521411895752,
        0.049038924276828766,
        0.04663196578621864,
        0.007549488451331854,
        0.05810512974858284,
        -0.04518885537981987,
        -0.02268967777490616,
        -0.028994036838412285,
        -0.0659247487783432,
        0.02947099693119526,
        -0.06364527344703674,
        -0.06669661402702332,
        0.04681454226374626,
        -0.05699244514107704,
        0.05707739666104317,
        -0.06336483359336853,
        -0.051279596984386444,
        -0.06553074717521667,
        0.05520104989409447,
        0.014700722880661488,
        -0.023604009300470352,
        0.05466323345899582,
        -0.06222698837518692,
        0.04809728264808655,
        0.053856704384088516,
        0.01815860904753208,
        0.037080299109220505,
        -0.034651804715394974,
        0.04093486815690994,
        -0.04498239979147911,
        -0.0038328426890075207,
        0.0016698891995474696,
        -0.04556627199053764,
        0.06706175953149796,
        0.030867604538798332,
        -0.051656629890203476,
        0.06312863528728485,
        -0.052126701921224594,
        0.054534971714019775,
        -0.004744162783026695,
        0.04498963803052902,
        0.047638848423957825,
        -0.014845249243080616,
        -0.05380585789680481,
        0.029049530625343323,
        0.05888945236802101,
        -0.038610611110925674,
        0.031168367713689804,
        0.00822525005787611,
        -0.039897408336400986,
        0.04983995109796524,
        -0.06675972789525986,
        -0.06365043669939041,
        0.05886479467153549,
        -0.05479319393634796,
        0.021254947409033775,
        -0.06355558335781097,
        -0.035559702664613724,
        -0.05745844915509224,
        0.053668051958084106,
        -0.06533846259117126,
        -0.027044760063290596,
        0.009530442766845226,
        0.05897209793329239,
        -0.0075470637530088425,
        0.040863439440727234,
        -0.019890673458576202,
        -0.016850613057613373,
        -0.044425491243600845,
        0.0038457917980849743,
        -0.042742352932691574,
        0.06330417841672897,
        0.06041736900806427,
        -0.024412648752331734,
        0.06596802920103073,
        -0.01635643094778061,
        0.04674912244081497,
        0.01799137517809868,
        -0.010445487685501575,
        0.018365303054451942,
        -0.012765566818416119,
        0.06334186345338821,
        -0.004511972889304161,
        0.047155797481536865,
        0.04982145130634308,
        -0.050159573554992676,
        0.05903560295701027,
        -0.05818433314561844,
        0.051116812974214554,
        -0.02987547591328621,
        0.004711451940238476,
        0.03979215770959854,
        0.06626112014055252,
        -0.06502840667963028,
        -0.01649155654013157,
        -0.05801602080464363,
        0.013422589749097824,
        0.05600380897521973,
        -0.038434870541095734,
        -0.017620118334889412,
        -0.02769063226878643,
        0.06258071213960648,
        -0.05018210411071777,
        0.06715322285890579,
        0.05267561972141266,
        0.038171544671058655,
        -0.06576106697320938,
        0.011960925534367561,
        -0.0544065497815609,
        0.06399765610694885,
        -0.05711057409644127,
        0.0550360307097435,
        0.009399834088981152,
        0.030971648171544075,
        0.010550426319241524,
        0.061339911073446274,
        -0.033493321388959885,
        0.02334173396229744,
        0.05610312521457672,
        -0.06686989217996597,
        -0.038440167903900146,
        0.06709449738264084,
        -0.034660208970308304,
        0.025396542623639107,
        -0.06162845343351364,
        -0.06365453451871872,
        -0.05247681960463524,
        0.024754395708441734,
        -0.06417473405599594,
        0.008552891202270985,
        -0.0222985427826643,
        0.027105771005153656,
        0.06270923465490341,
        0.050770774483680725,
        0.022074753418564796,
        -0.046397510915994644,
        -0.06409777700901031,
        -0.04039075970649719,
        0.06209299713373184,
        0.0383167490363121,
        0.0668409913778305,
        -0.010203744284808636,
        -0.01966194249689579,
        0.008450820110738277,
        -0.018105870112776756,
        0.05111120268702507,
        -0.035841204226017,
        -0.006069414783269167,
        -0.055450621992349625,
        -0.03136568143963814,
        -0.022196287289261818,
        0.03862690180540085,
        -0.035501085221767426,
        -0.00897197425365448,
        -0.02859431691467762,
        0.046967100352048874,
        -0.051192473620176315,
        -0.055091213434934616,
        -0.0582658126950264,
        -0.019805217161774635,
        -0.06503212451934814,
        0.023444175720214844,
        0.046005282551050186,
        -0.06578909605741501,
        -0.060425397008657455,
        -0.05266227573156357,
        -0.05282045900821686,
        -0.025571823120117188,
        -0.019401703029870987,
        0.01114524994045496,
        -0.0017967396415770054,
        0.023679645732045174,
        -0.05320611223578453,
        0.016390061005949974,
        -0.024388771504163742,
        0.033098090440034866,
        -0.06618151068687439,
        0.027944376692175865,
        0.00477946549654007,
        0.022244466468691826,
        0.05346047133207321,
        0.04710594564676285,
        -0.0034011260140687227,
        0.06591463834047318,
        0.013311713002622128,
        0.039671387523412704,
        0.0032072358299046755,
        -0.051984675228595734,
        0.0578434132039547,
        0.04661036655306816,
        0.044572863727808,
        0.03173205256462097,
        -0.04581510275602341,
        -0.0010505454847589135,
        -0.0432421900331974,
        0.004090685397386551,
        0.03536500409245491,
        0.0666532963514328,
        -0.042209748178720474,
        0.010011781007051468,
        0.05632748082280159,
        0.054564375430345535,
        0.06593424826860428,
        -0.06523118168115616,
        0.06251063942909241,
        -0.012355518527328968,
        -0.06472871452569962,
        0.03290664777159691,
        -0.05992160737514496,
        0.038285885006189346,
        0.06316248327493668,
        0.04104934260249138,
        0.01634315773844719,
        -0.05465826764702797,
        -0.00046656583435833454,
        -0.05990016087889671,
        -0.03742031753063202,
        0.04504217952489853,
        -0.047250475734472275,
        0.04606529697775841,
        0.04441983625292778,
        0.04688457399606705,
        0.060478515923023224,
        0.06362605094909668,
        -0.0052574397996068,
        -0.058439113199710846,
        -0.029811948537826538,
        -0.03144201636314392,
        -0.052962109446525574,
        -0.0033694009762257338,
        0.06310714036226273,
        0.03934646397829056,
        0.051187023520469666,
        -0.06644367426633835,
        -0.049015242606401443,
        0.06061895191669464,
        -0.04187559708952904,
        0.02533441223204136,
        -0.044369008392095566,
        -0.015139163471758366,
        0.01788221299648285,
        -0.011169643141329288,
        -0.04363913834095001,
        -0.0415969155728817,
        -0.024592814967036247,
        0.0234735868871212,
        -0.06173263117671013,
        0.06627154350280762,
        -0.03338325023651123
    ]
]