import { BsPerson } from "react-icons/bs";
import { BsGear } from "react-icons/bs";
import React from "react";
import StatsImg from "./statskoodos.png"
import './Common.css'
class TopBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            windowSize: 500
        }
    }

    async componentDidMount() {
        this.setState({ windowSize: window.screen.width })
    }

    handleRouting() {
        window.open("http://stats.koodos.com/", '_blank');
    }
    logoutEvent() {
        window.open("http://stats.koodos.com/", '_blank');
    }
    discordEvent() {
        window.open("https://share.koodos.com/3discord", '_blank');

    }
    render() {
        //if (true) {
        if (this.state.windowSize < 576) {
            return (
                <div className={'topbar'}>
                    <div className={'section_container top-bar-container alignment'}>
                        <div className="statswrap">
                            <img className="stat-mob cursor-pointer" src={StatsImg} alt="Logo" onClick={() => this.handleRouting()} />
                        </div>
                        <div className="problem-wrap">
                            <button className="twinbtns-loggedin-mob cursor-pointer" onClick={() => this.discordEvent()}>
                                <a className="twin01 font-semibold">join our<br />discord</a>
                            </button>
                            &nbsp;&nbsp;
                            {window.location.href.indexOf('profile') > -1 ?
                                <BsPerson className={`cursor-pointer bs-person-mob person-lit font-size-36`} onClick={() => this.handleRouting()} />
                                :
                                <BsPerson className={`cursor-pointer bs-person-mob top-bar-tab font-size-36`} onClick={() => this.handleRouting()} />
                            }
                            &nbsp;&nbsp;
                            <a className={"cursor-pointer top-bar-tab log-out-mob font-size-16"} onClick={() => this.logoutEvent()} >logout</a>
                            &nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            )
        } else
            return (
                <div className={'topbar'}>
                    <div className={'section_container top-bar-container'}>
                        <div className={'col'}>
                            <img className="stat cursor-pointer" src={StatsImg} alt="Logo" width="200px" onClick={() => this.handleRouting()} />
                        </div>
                        <div className={'col display-flex'}>
                            <button className="twinbtns-loggedin cursor-pointer" onClick={() => this.discordEvent()}>
                                <a className="twin01 font-semibold">join our discord</a>
                            </button>
                            &nbsp;&nbsp;
                            {window.location.href.indexOf('profile') > -1 ?
                                <BsPerson className={`cursor-pointer bs-person person-lit font-size-20`} onClick={() => this.handleRouting()} />
                                :
                                <BsPerson className={`cursor-pointer bs-person top-bar-tab font-size-20`} onClick={() => this.handleRouting()} />
                            }
                            &nbsp;&nbsp;

                            <a className={"cursor-pointer top-bar-tab log-out font-size-16"} onClick={() => this.logoutEvent()} >log out</a>
                            &nbsp;&nbsp;


                        </div>
                    </div>
                </div>
            )
    }
}

export default TopBar;