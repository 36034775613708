import React, { useState, useEffect } from "react";
import "./App.css";
import { useMediaQuery } from 'react-responsive'
import emojisKeywords, { filter } from "emojis-keywords";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Audio } from 'react-loader-spinner'
import { supabase } from './supabaseClient'
import hash from "./hash";
import { embedds } from "./arrFile";
import { FaTiktok, FaInstagramSquare, FaInstagram } from 'react-icons/fa'
import { IconContext } from "react-icons";

export default function Account({ session, update, wavingToken }) {
    const clientId = "e06a8e4468034056a35e699e61e7b0cb";
    //test
    //const redirectUri = "http://localhost:3000";
    const redirectUri = "https://user-visual.vercel.app/callback";
    const scopes = [
        "user-top-read",
        "user-read-currently-playing",
        "user-read-playback-state",
    ];
    const axios = require("axios");



    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [phrase1, setPhrase1] = useState("");
    const [loading, setLoading] = useState(false);
    const [topTracks, setTopTracks] = useState([]);
    const [topArtists, setTopArtists] = useState([]);
    const [fiveEmojis, setFiveEmojis] = useState([]);
    const [stringedTopTracks, setStringedTopTracks] = useState("");


    const emojis = require('emojis-list')
    var initialEmojiKeywords = require('emojis-keywords');
    const unicodeEmojis = require('emojis-unicode');
    require('@tensorflow/tfjs');
    const use = require('@tensorflow-models/universal-sentence-encoder');
    var similarity = require('compute-cosine-similarity');
    var emojiText = require("emoji-text");

    var filteredEmojis = [];
    var emojiKeywordsUnSliced = initialEmojiKeywords.filter((word, index) => {
        const passed = !word.includes("tone") && !word.includes("flag") && !word.includes("♂️") && !word.includes("♀️")
        if (passed) (
            filteredEmojis.push(emojis[index])
        )
        return passed;
    }
    )

    // var slice0 = emojiKeywordsUnSliced.slice(193, 401);
    // var slice1 = emojiKeywordsUnSliced.slice(462, 530);
    // var slice2 = emojiKeywordsUnSliced.slice(600, 745);
    // var slice3 = emojiKeywordsUnSliced.slice(800, 899);
    // var slice4 = emojiKeywordsUnSliced.slice(950, 983);
    // var slice5 = emojiKeywordsUnSliced.slice(1000, 1025);
    // var slice6 = emojiKeywordsUnSliced.slice(1116, 1139);

    // var slicedEmojiKeywords = slice0.concat(slice1, slice2, slice3, slice4, slice5, slice6);

    var eslice0 = filteredEmojis.slice(193, 401);
    var eslice1 = filteredEmojis.slice(462, 530);
    var eslice2 = filteredEmojis.slice(600, 745);
    var eslice3 = filteredEmojis.slice(800, 899);
    var eslice4 = filteredEmojis.slice(950, 983);
    var eslice5 = filteredEmojis.slice(1000, 1025);
    var eslice6 = filteredEmojis.slice(1116, 1139);
    var slicedEmojis = eslice0.concat(eslice1, eslice2, eslice3, eslice4, eslice5, eslice6).slice(0, 520);
    var convertedTextEmojis = slicedEmojis.map((emoji) => {
        return emojiText.convert(emoji, {
            before: ' ', // character to use before field
            after: '',
        })
    })
    const [seshToken, setSeshToken] = useState(null);
    useEffect(() => {
        const getTopTracks = async (token) => {
            const headers = {
                "Authorization": "Bearer " + token
            };
            await axios.get("https://api.spotify.com/v1/me/top/tracks", { headers, params: { limit: 8, time_range: "short_term" } }).then((response) => {
                //setTopTracks(response.data.items);
                var currTops = [];
                var currArtists = [];
                response.data.items.map((item) => {
                    currTops.push(item);
                    if (item.artists.length == 1) {
                        currArtists.push(item.artists[0].name)
                    }
                    else {
                        const artistsArr = item.artists.map((obj) => {
                            return obj.name;
                        })
                        currArtists.push(artistsArr.join(', '))
                    }
                })
                setTopTracks(currTops);
                setTopArtists(currArtists);

                //use currTops Array in the model
                var trackArr = currTops.map((top) => {
                    return top.name;
                })
                var str = trackArr.join(', ')
                setStringedTopTracks(str);
                checkSimilarity(str);
                update();
            })
        }

        //call function
        var mToken = wavingToken?wavingToken:hash.access_token;
        if (mToken) {
            setSeshToken(mToken);
            setLoading(true);
            getTopTracks(mToken);

        }

        //reload emojis
        setLoading(false);



    }, [seshToken])

    // useEffect(() => {
    //     //console.log(slicedEmojiKeywords.length)
    //     setLoading(true);
    //     use.load().then(model => {
    //         model.embed(convertedTextEmojis).then(async embeddings => {
    //             // `embeddings` is a 2D tensor consisting of the 512-dimensional embeddings for each sentence.
    //             // So in this example `embeddings` has the shape [2, 512].
    //             //embeddings.print(true /* verbose */);
    //             setEmojiEmbeddings(await embeddings.array());
    //             console.log(await embeddings.array());
    //             setLoading(false);
    //         });
    //     });
    //   }, [])

    const checkSimilarity = async (str) => {
        //e.preventDefault();
        setLoading(true);
        await use.load().then(model => {
            model.embed([str]).then(async embeddings => {
                const tempArr = await embeddings.array()

                var similarities = embedds.map((currVector, index) => {
                    var currSimilarity = similarity(tempArr[0], currVector);

                    return currSimilarity;
                })
                var sortedSimilarities = [...similarities].sort((a, b) => b - a).slice(0, 5);
                var topEmojis = [];
                sortedSimilarities.map((score) => {
                    var currIndex = similarities.indexOf(score);

                    topEmojis.push(slicedEmojis[currIndex]);
                })
                setFiveEmojis(topEmojis);
                setLoading(false);
            });
        });
    }
    return !loading ? (
        <div className="acount-background" >
            <div className="getemojiswrap">
                {fiveEmojis.length == 0 && (
                    <div>
                        <a
                            className="btnn btn--loginApp-link"
                            href={`https://accounts.spotify.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
                                "%20"
                            )}&response_type=token&show_dialog=true`}
                        >
                            Get Emojis
                        </a>
                    </div>
                )}
            </div>
            <div className="emojiswrap">
                {fiveEmojis.length != 0 &&
                    (
                        fiveEmojis.map((emoji) => {
                            return (
                                <b className="emojicss">{emoji}</b>
                            )
                        })
                    )
                }
            </div>
            {topTracks.length != 0 && (
                <div className="resultswrap">
                    <table className="img-table">
                        <tr className="img-row">
                            <td className="tableimg" background={topTracks[0].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[0].name}
                                </div>
                            </td>
                            <td className="tableimg" background={topTracks[1].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[1].name}
                                </div>                        </td>
                        </tr>
                        <tr className="img-row">
                            <td className="tableimg" background={topTracks[2].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[2].name}
                                </div>                        </td>
                            <td className="tableimg" background={topTracks[3].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[3].name}
                                </div>                        </td>
                        </tr>
                        <tr className="img-row">
                            <td className="tableimg" background={topTracks[4].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[4].name}
                                </div>                        </td>
                            <td className="tableimg" background={topTracks[5].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[5].name}
                                </div>                        </td>
                        </tr>
                        <tr className="img-row">
                            <td className="tableimg" background={topTracks[6].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[6].name}
                                </div>                        </td>
                            <td className="tableimg" background={topTracks[7].album.images[0].url}>
                                <div className="trackwrap">
                                    {topTracks[7].name}
                                </div>                        </td>
                        </tr>
                    </table>
                </div>
            )}
            <br></br>
            <br></br>
            {topTracks.length != 0 && (
                <IconContext.Provider value={{ color: "#9AFEE0", className: "global-class-name" }}>

                    <div className="bottom-wrap">
                        <a className="watermarkcss" href='https://share.koodos.com/emojify'>
                            @koodos
                        </a>
                        <br></br>
                        <div className="igtiktokwrap">
                            <a href="https://share.koodos.com/emojify_koodos_ig">
                                <FaInstagram className="icons0" border />
                            </a>
                            <a href="https://share.koodos.com/emojify_koodos_tiktok">
                                <FaTiktok className="icons" border />
                            </a>
                        </div>
                    </div>
                </IconContext.Provider>

            )}
        </div>
    ) : (
        <Audio
            height="100"
            width="100"
            color='grey'
            ariaLabel='loading'
        />
    );

}

