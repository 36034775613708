import React, { useState, useEffect } from "react";
import axios from 'axios';
import { authEndpoint, clientId, redirectUri, scopes } from "./config_example";
import "./App.css";
import './index.css'
import { supabase } from './supabaseClient'
import Auth from './Auth'
import Account from './Account'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Audio } from 'react-loader-spinner'
import { MetaTags } from "react-meta-tags";
import TopLogo from './toplogo.png'
import TopBar from './TopBar'
export default function App() {
  const [session, setSession] = useState(null)
  const [resultsActive, setResultsActive] = useState(false);

  const queryParams = new URLSearchParams(window.location.search)
  const authToken = queryParams.get("authToken")
  console.log("token", authToken)

  const updateResults = () => {
    setResultsActive(true);
    updateProfile();
  }
  async function updateProfile() {
    console.log("auth user", supabase.auth.user())
    try {
      const user = supabase.auth.user();
      const updates2 = {
        email: user.email,
        id: user.id,
        visual: "emojify",
      }

      let { error2 } = await supabase.from('profiles').upsert(updates2);
      console.log("called error2")
      if (error2) {
        throw error2;
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    setSession(supabase.auth.session())

    supabase.auth.onAuthStateChange((event, session) => {
      setSession(session)
    })
  }, [])
  if (authToken) {
    return (
      <div className="background">
        <TopBar />

        <header className="header1">
          Emojify
        </header>
        {!resultsActive && !session ?
          <header className="frontstatement">
            How healthy is your music taste?
          </header> : null}
        {!resultsActive && session ?
          <header className="frontstatement">
            Get your musical nutrition breakdown in <br />
            1 click
          </header> : null}

        <body className="container">
          <Account update={updateResults} session={session} wavingToken={authToken} />
        </body>
      </div>
    );
  } else
    return (
      <div className="background">
        <TopBar/>


        <header className="header1">
          Emojify
        </header>
        {!resultsActive && !session ?
          <header className="frontstatement">
            What is your emoji identity?
          </header> : null}
        {!resultsActive && session ?
          <header className="frontstatement">
            Get your emoji identity!
          </header> : null}
        <body className="container">
          {!session ? <Auth /> : <Account update={updateResults} key={session.user.id} session={session} />}
        </body>
      </div>
    );

}

